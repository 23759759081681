import { combineReducers } from "redux";
import { userAccountReducer } from "./userAccountReducer"
import { songsReducer } from "./songReducer";

const createRootReducer = () => {
    return combineReducers({
        userAccount: userAccountReducer,
        song: songsReducer
    });
};

export const rootReducer = createRootReducer();
export type ReduxAppState = ReturnType<typeof rootReducer>;
