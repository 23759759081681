import React, { useState } from "react";
import { IconButton, Modal } from "@fluentui/react";
import { BibleVerseRange } from "../../../../store/actions/songActions";
import BibleVerseRangeView from "./BibleVerseRangeView";

export const BibleVerseRangesEditView = (props:
    {
        enableDelete?: boolean,
        songBibleVerses: BibleVerseRange[],
        onDelete?: (index: number) => void
    }) => {
    const [bibleVerseRangeToShow, setBibleVerseRangeToShow] = useState<BibleVerseRange>();

    const deleteVerseRange = (index: number) => {
        if (props.onDelete) {
            props.onDelete(index);
        }
    }

    return <div>
        { props.songBibleVerses && props.songBibleVerses.map((item, index) => {
            if (item.startVerse === item.endVerse) {
                return <>
                    <span style={ { cursor: "pointer", color: "rgb(0, 120, 212)" } } key={ index } onClick={ () => { setBibleVerseRangeToShow(item) } }>
                        { item.volumn + " " + item.chapter + " " + item.startVerse }
                    </span>
                    { props.enableDelete &&
                        <IconButton
                            onClick={ () => { deleteVerseRange(index) } }
                            iconProps={ { iconName: "Delete" } } /> }
                </>
            } else {
                return <>
                    <span style={ { cursor: "pointer", color: "rgb(0, 120, 212)" } } key={ index } onClick={ () => { setBibleVerseRangeToShow(item) } }>
                        { item.volumn + " " + item.chapter + " " + item.startVerse + ":" + item.endVerse }
                    </span>
                    { props.enableDelete &&
                        <IconButton
                            onClick={ () => { deleteVerseRange(index) } }
                            iconProps={ { iconName: "Delete" } } /> }
                </>
            }
        }) }
        <Modal isOpen={ bibleVerseRangeToShow ? true : false } onDismiss={ () => { setBibleVerseRangeToShow(undefined) } } isBlocking={ false }>
            { bibleVerseRangeToShow && <BibleVerseRangeView bibleVerseRange={ bibleVerseRangeToShow } /> }
        </Modal>
    </div>
}