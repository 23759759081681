import React from "react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { Song } from "../../../store/actions/songActions";
import SongDetailView from "./SongDetailView";
import { SongCardView } from "./SongCardView";

export const SongView = (props: { song: Song }) => {
    const activeSong = useSelector((state: ReduxAppState) => { return state.song.activeSong });

    return <div>
        { props.song && <SongCardView song={ props.song } /> }
        { activeSong && props.song && activeSong.partitionKey === props.song.partitionKey && activeSong.id === props.song.id && (
            <div style={ { display: "inline-block", width: "100%" } }>
                <SongDetailView></SongDetailView>
            </div>
        ) }
    </div>
}