import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

export const SongsViewCommandBarView = (props: { refreshDisabled: boolean, playDisabled: boolean, onPlay: () => void, onRefresh: () => void }) => {
  const _items: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      disabled: props.refreshDisabled,
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        if (props.onRefresh) {
          props.onRefresh();
        }
      },
    },
    {
      key: 'play',
      text: 'Play',
      disabled: props.playDisabled,
      iconProps: { iconName: 'Play' },
      onClick: () => {
        if (props.onPlay) {
          props.onPlay();
        }
      },
    },
  ];

  return (
    <CommandBar style={ { display: "inline-block" } }
      items={ _items }
    />
  );
};

