import React, { Fragment, useEffect, useState } from "react";
import styles from "../Songs.module.css";
import {
  setActiveUserPlaylist,
  setSongs,
  Song
} from "../../../store/actions/songActions";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { CommandButton, ContextualMenu, IContextualMenuItem, Modal } from "@fluentui/react";
import SongSheetMusicsListView from "../sheetMusic/SongSheetMusicsListView";
import SongDetailView from "../song/SongDetailView";
import { PlaylistUtility } from "../../../utility/v2/playlistUtility";
import { SongUtilityV2 } from "../../../utility/v2/songUtility";
import { SheetMusicFileUtility } from "../../../utility/v2/sheetMusicFileUtilty";
import YoutubePlayerArea from "../../shared/YoutubePlayerArea";
import { SongBibleVersesView } from "../song/SongBibleVersesView";
import SongEditView from "../song/edit/SongEditView";
import { SongLyricsView } from "../song/SongLyricsView";
import SongSheetMusicsView from "../sheetMusic/SongSheetMusicsView";

declare interface SongViewProp {
  songTitle: string;
  enableDelete?: boolean;
}

const SongView = (props: SongViewProp) => {
  const [song, setSong] = useState(undefined as any);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showSheetMusicAdmin, setShowSheetMusicAdmin] = useState(false);
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const [showLyrics, setShowLyrics] = useState(false);

  const cmRef = React.useRef(null);
  const dispatch = useDispatch();
  const songs = useSelector((state: ReduxAppState) => { return state.song.songs });
  const activeSong = useSelector((state: ReduxAppState) => {
    return state.song.activeSong;
  });
  const activeUserPlaylist = useSelector((state: ReduxAppState) => {
    return state.song.activeUserPlaylist;
  });

  const menuItems: IContextualMenuItem[] = [
    {
      key: '1',
      text: '修改',
      onClick: () => setIsEditMode(true)
    },
    {
      key: '3',
      text: '歌谱管理',
      onClick: () => setShowSheetMusicAdmin(true)
    }
  ];

  const updateSheetMusic = (songTitle: string) => {
    SongUtilityV2.getSong(songTitle).then((result: any) => {
      if (result) {
        try {
          SheetMusicFileUtility.getSongSheetMusicFileSasUrls(props.songTitle).then((result1: any) => {
            result.sheetMusicFiles = result1;
            setSong(result);
          });
        } catch {
          setSong(result);
        }
      }
    });
  }

  useEffect(() => {
    if (songs && songs.length > 0) {
      const song = songs.filter((x: Song) => x.title1 === props.songTitle);
      if (song && song.length > 0) {
        if (!song[0].sheetMusicFiles) {
          updateSheetMusic(props.songTitle);
        }
        setSong(song[0]);
      }
      return;
    }
    else {
      updateSheetMusic(props.songTitle);
    }
  }, [props.songTitle]);

  const deleteSongTitleFromList = () => {
    if (window.confirm("确定把这首歌从歌单删除？")) {
      if (activeUserPlaylist && activeUserPlaylist.songTitles) {
        const index = activeUserPlaylist.songTitles.indexOf(props.songTitle);
        if (index > -1) {
          activeUserPlaylist.songTitles.splice(index, 1);
          PlaylistUtility.UpsertPlaylist(
            activeUserPlaylist
          );
          dispatch(setActiveUserPlaylist({ ...activeUserPlaylist }));
        }
      }
    }
  };

  const moveUp = () => {
    if (activeUserPlaylist && activeUserPlaylist.songTitles) {
      const index = activeUserPlaylist.songTitles.indexOf(props.songTitle);
      if (index > 0) {
        const temp = activeUserPlaylist.songTitles[index];
        activeUserPlaylist.songTitles[index] = activeUserPlaylist.songTitles[index - 1];
        activeUserPlaylist.songTitles[index - 1] = temp;

        PlaylistUtility.UpsertPlaylist(
          activeUserPlaylist
        );
        dispatch(setActiveUserPlaylist({ ...activeUserPlaylist }));
      }
    }
  };

  const moveDown = () => {
    if (activeUserPlaylist && activeUserPlaylist.songTitles) {
      const index = activeUserPlaylist.songTitles.indexOf(props.songTitle);
      if (index < activeUserPlaylist.songTitles.length - 1) {
        const temp = activeUserPlaylist.songTitles[index];
        activeUserPlaylist.songTitles[index] = activeUserPlaylist.songTitles[index + 1];
        activeUserPlaylist.songTitles[index + 1] = temp;

        PlaylistUtility.UpsertPlaylist(
          activeUserPlaylist
        );
        dispatch(setActiveUserPlaylist({ ...activeUserPlaylist }));
      }
    }
  };

  const onHideContextualMenu = () => {
    setShowContextualMenu(false);
  }

  const onSongUpdated = (newSong: Song) => {
    const matchedIndex = songs.findIndex((item: any) => item.id === newSong.id);
    songs[matchedIndex] = newSong;
    dispatch(setSongs([...songs]));
  }

  return (
    <Fragment>
      <div style={ { height: "100%" } }>
        { song && (
          <div className={ styles.searchresult } style={ { overflow: "auto" } }>
            <div
              style={ { marginBottom: 2, backgroundColor: "white", padding: 10 } }
            >
              <div
                style={ {
                  marginBottom: 1,
                  paddingBottom: 10,
                  display: "inline-block",
                  width: "100%"
                } }
              >
                <div
                  style={ {
                    display: "inline-block",
                    width: "calc(100% - 160px)"
                  } }
                >
                  <div style={ { width: "100%" } }>
                    <span onClick={ () => { setShowLyrics(true) } } style={ { fontSize: 18, color: "#4007a2", cursor: "pointer", display: "inline-block", width: "350px", fontFamily: "sans-serif" } }>{ song.title1 }</span>
                    { props.enableDelete && <CommandButton
                      title={ "Move up" }
                      onClick={ () => moveUp() }
                      iconProps={ { iconName: "Up" } }
                    ></CommandButton> }
                    { props.enableDelete &&
                      <CommandButton
                        title={ "Move down" }
                        onClick={ () => moveDown() }
                        iconProps={ { iconName: "Down" } }
                      ></CommandButton> }
                    { props.enableDelete && (
                      <CommandButton
                        title={ "从歌单删除" }
                        onClick={ () => deleteSongTitleFromList() }
                        iconProps={ { iconName: "Delete" } }
                      ></CommandButton>
                    ) }
                    <span
                      style={ {
                        float: "right",
                        paddingLeft: 5,
                        paddingRight: 5
                      } }
                    >
                      <span>{ song.tags }</span>
                    </span>
                  </div>
                  { !(activeSong && activeSong.id === song.id) && (
                    <>
                      <div style={ { color: "gray" } }>
                        { song.lyrics.substring(0, 100) + "..." }
                      </div>
                      <div style={ { color: "gray" } }>
                        关键词:<span>{ song.tags }</span>
                      </div>
                      <div style={ { color: "gray" } }>
                        <span>唱法: </span><span>{ song.sequence }</span>
                      </div>
                      <div>
                        <SongSheetMusicsListView sheetMusicFiles={ song.sheetMusicFiles }></SongSheetMusicsListView>
                      </div>
                      <div><SongBibleVersesView songBibleVerses={ song.verses } /></div>
                    </>
                  ) }
                </div>
                <div
                  style={ {
                    display: "inline-block",
                    minWidth: 160,
                    alignItems: "top",
                    float: "right"
                  } }
                >
                  <div style={ { display: "inline-block" } }>
                    <YoutubePlayerArea youtubeUrls={ song.youtubeUrls } />
                  </div>
                  <div style={ { display: "inline-block", float: "right" } }>
                    { " " }
                    <span ref={ cmRef }
                      style={ { color: "rgb(16, 110, 190)", cursor: "pointer", float: "right", marginRight: "3px" } }
                      onClick={ () => { setShowContextualMenu(true) } }>...</span>

                    <ContextualMenu
                      items={ menuItems }
                      hidden={ !showContextualMenu }
                      target={ cmRef }
                      onItemClick={ onHideContextualMenu }
                      onDismiss={ onHideContextualMenu }
                    />
                  </div>
                </div>
              </div>
              { activeSong && activeSong.id === song.id && (
                <div style={ { display: "inline-block", width: "100%" } }>
                  <SongDetailView></SongDetailView>
                </div>
              ) }
            </div>
          </div>
        ) }
      </div>
      { song && <>
        <Modal isOpen={ isEditMode } styles={ { root: { padding: 10 } } }>
          <SongEditView onUpate={ onSongUpdated } songId={ song.id } onClose={ () => { setIsEditMode(false); } } />
        </Modal>
        <Modal isOpen={ showLyrics } isBlocking={ false } onDismiss={ () => { setShowLyrics(false); } }>
          <SongLyricsView sequence={ song.sequence } lyrics={ song.lyrics } onClose={ () => { setShowLyrics(false); } } />
        </Modal>
        <Modal isOpen={ showSheetMusicAdmin } styles={ { root: { padding: 10 } } }>
          <div style={ { padding: 10 } }>
            <SongSheetMusicsView
              song={ song }
              onClose={ () => {
                setShowSheetMusicAdmin(false);
              } }
            ></SongSheetMusicsView>
          </div>
        </Modal></> }
    </Fragment>
  );
};

export default SongView;
