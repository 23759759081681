import React, { useEffect, useState } from "react";
import { setActiveUserPlaylist } from "../../../store/actions/songActions";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { EasySlidesScheduleUtilty } from "../../../utility/easySlidesScheduleUtilty";
import { UserRolesEnum, UserService } from "../../../utility/userService";

const EasyslidesSchedulesView = () => {
    const [scheduledPlayLists, setScheduledPlaylists] = useState(
        undefined as any
    );
    const userRoles = useSelector((state: ReduxAppState) => {
        return state.userAccount.userProfile?.roles;
    });

    const currentUser = useSelector((state: ReduxAppState) => {
        return state.userAccount.currentUser;
    });

    const activeUserPlaylist = useSelector((state: ReduxAppState) => {
        return state.song.activeUserPlaylist;
    });

    const dispatch = useDispatch();

    const loadUserPlaylists = async () => {
        if (currentUser && currentUser.email && UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Read)) {
            const schedules = await EasySlidesScheduleUtilty.getchedules() as any;
            setScheduledPlaylists(schedules);
        }
    };

    useEffect(() => {
        loadUserPlaylists();
    }, [currentUser]);


    const renderList = () => {
        const renderedPlaylist = [] as any;
        if (scheduledPlayLists) {
            for (let index = 0; index < scheduledPlayLists.length; index++) {
                const data1 = scheduledPlayLists[index];
                renderedPlaylist.push(
                    <span style={ { fontSize: 13 } } key={ index }>
                        <span
                            style={ {
                                color: "blue",
                                cursor: "pointer",
                                width: '140px',
                                display: 'inline-block',
                                marginLeft: 10,
                                fontWeight:
                                    activeUserPlaylist && activeUserPlaylist.rowKey === data1.rowKey
                                        ? "bold"
                                        : "normal"
                            } }
                            onClick={ () => {
                                dispatch(setActiveUserPlaylist(data1));
                            } }
                        >
                            { data1.rowKey }
                        </span>
                        <span style={ { marginLeft: 10, color: "gray", display: 'none' } }>{ "|" }</span>
                    </span>
                );
            }
        }
        return renderedPlaylist;
    }

    return <>{ renderList() }</>;
};

export default EasyslidesSchedulesView;