export const ChineseBible = [
{volumn:         1,chapter:         1,versenumber:         1,verse:"起初，神创造天地。"},
{volumn:         1,chapter:         1,versenumber:         2,verse:"地是空虚混沌，渊面黑暗；　神的灵运行在水面上。"},
{volumn:         1,chapter:         1,versenumber:         3,verse:"神说：“要有光”，就有了光。"},
{volumn:         1,chapter:         1,versenumber:         4,verse:"神看光是好的，就把光暗分开了。"},
{volumn:         1,chapter:         1,versenumber:         5,verse:"神称光为“昼”，称暗为“夜”。有晚上，有早晨，这是头一日。"},
{volumn:         1,chapter:         1,versenumber:         6,verse:"神说：“诸水之间要有空气，将水分为上下。”"},
{volumn:         1,chapter:         1,versenumber:         7,verse:"神就造出空气，将空气以下的水、空气以上的水分开了。事就这样成了。"},
{volumn:         1,chapter:         1,versenumber:         8,verse:"神称空气为“天”。有晚上，有早晨，是第二日。"},
{volumn:         1,chapter:         1,versenumber:         9,verse:"神说：“天下的水要聚在一处，使旱地露出来。”事就这样成了。"},
{volumn:         1,chapter:         1,versenumber:        10,verse:"神称旱地为“地”，称水的聚处为“海”。　神看着是好的。"},
{volumn:         1,chapter:         1,versenumber:        11,verse:"神说：“地要发生青草和结种子的菜蔬，并结果子的树木，各从其类，果子都包着核。”事就这样成了。"},
{volumn:         1,chapter:         1,versenumber:        12,verse:"于是地发生了青草和结种子的菜蔬，各从其类；并结果子的树木，各从其类；果子都包着核。　神看着是好的。"},
{volumn:         1,chapter:         1,versenumber:        13,verse:"有晚上，有早晨，是第三日。"},
{volumn:         1,chapter:         1,versenumber:        14,verse:"神说：“天上要有光体，可以分昼夜，作记号，定节令、日子、年岁，"},
{volumn:         1,chapter:         1,versenumber:        15,verse:"并要发光在天空，普照在地上。”事就这样成了。"},
{volumn:         1,chapter:         1,versenumber:        16,verse:"于是　神造了两个大光，大的管昼，小的管夜，又造众星，"},
{volumn:         1,chapter:         1,versenumber:        17,verse:"就把这些光摆列在天空，普照在地上，"},
{volumn:         1,chapter:         1,versenumber:        18,verse:"管理昼夜，分别明暗。　神看着是好的。"},
{volumn:         1,chapter:         1,versenumber:        19,verse:"有晚上，有早晨，是第四日。"},
{volumn:         1,chapter:         1,versenumber:        20,verse:"神说：“水要多多滋生有生命的物；要有雀鸟飞在地面以上，天空之中。”"},
{volumn:         1,chapter:         1,versenumber:        21,verse:"神就造出大鱼和水中所滋生各样有生命的动物，各从其类；又造出各样飞鸟，各从其类。　神看着是好的。"},
{volumn:         1,chapter:         1,versenumber:        22,verse:"神就赐福给这一切，说：“滋生繁多，充满海中的水；雀鸟也要多生在地上。”"},
{volumn:         1,chapter:         1,versenumber:        23,verse:"有晚上，有早晨，是第五日。"},
{volumn:         1,chapter:         1,versenumber:        24,verse:"神说：“地要生出活物来，各从其类；牲畜、昆虫、野兽，各从其类。”事就这样成了。"},
{volumn:         1,chapter:         1,versenumber:        25,verse:"于是　神造出野兽，各从其类；牲畜，各从其类；地上一切昆虫，各从其类。　神看着是好的。"},
{volumn:         1,chapter:         1,versenumber:        26,verse:"神说：“我们要照着我们的形像、按着我们的样式造人，使他们管理海里的鱼、空中的鸟、地上的牲畜，和全地，并地上所爬的一切昆虫。”"},
{volumn:         1,chapter:         1,versenumber:        27,verse:"神就照着自己的形像造人，乃是照着他的形像造男造女。"},
{volumn:         1,chapter:         1,versenumber:        28,verse:"神就赐福给他们，又对他们说：“要生养众多，遍满地面，治理这地，也要管理海里的鱼、空中的鸟，和地上各样行动的活物。”"},
{volumn:         1,chapter:         1,versenumber:        29,verse:"神说：“看哪，我将遍地上一切结种子的菜蔬和一切树上所结有核的果子全赐给你们作食物。"},
{volumn:         1,chapter:         1,versenumber:        30,verse:"至于地上的走兽和空中的飞鸟，并各样爬在地上有生命的物，我将青草赐给它们作食物。”事就这样成了。"},
{volumn:         1,chapter:         1,versenumber:        31,verse:"神看着一切所造的都甚好。有晚上，有早晨，是第六日。"},
{volumn:         1,chapter:         2,versenumber:         1,verse:"天地万物都造齐了。"},
{volumn:         1,chapter:         2,versenumber:         2,verse:"到第七日，　神造物的工已经完毕，就在第七日歇了他一切的工，安息了。"},
{volumn:         1,chapter:         2,versenumber:         3,verse:"神赐福给第七日，定为圣日；因为在这日，　神歇了他一切创造的工，就安息了。"},
{volumn:         1,chapter:         2,versenumber:         4,verse:"创造天地的来历，在耶和华　神造天地的日子，乃是这样，"},
{volumn:         1,chapter:         2,versenumber:         5,verse:"野地还没有草木，田间的菜蔬还没有长起来；因为耶和华　神还没有降雨在地上，也没有人耕地，"},
{volumn:         1,chapter:         2,versenumber:         6,verse:"但有雾气从地上腾，滋润遍地。"},
{volumn:         1,chapter:         2,versenumber:         7,verse:"耶和华　神用地上的尘土造人，将生气吹在他鼻孔里，他就成了有灵的活人，名叫亚当。"},
{volumn:         1,chapter:         2,versenumber:         8,verse:"耶和华　神在东方的伊甸立了一个园子，把所造的人安置在那里。"},
{volumn:         1,chapter:         2,versenumber:         9,verse:"耶和华　神使各样的树从地里长出来，可以悦人的眼目，其上的果子好作食物。园子当中又有生命树和分别善恶的树。"},
{volumn:         1,chapter:         2,versenumber:        10,verse:"有河从伊甸流出来，滋润那园子，从那里分为四道："},
{volumn:         1,chapter:         2,versenumber:        11,verse:"第一道名叫比逊，就是环绕哈腓拉全地的。在那里有金子，"},
{volumn:         1,chapter:         2,versenumber:        12,verse:"并且那地的金子是好的；在那里又有珍珠和红玛瑙。"},
{volumn:         1,chapter:         2,versenumber:        13,verse:"第二道河名叫基训，就是环绕古实全地的。"},
{volumn:         1,chapter:         2,versenumber:        14,verse:"第三道河名叫底格里斯，流在亚述的东边。第四道河就是幼发拉底河。"},
{volumn:         1,chapter:         2,versenumber:        15,verse:"耶和华　神将那人安置在伊甸园，使他修理，看守。"},
{volumn:         1,chapter:         2,versenumber:        16,verse:"耶和华　神吩咐他说：“园中各样树上的果子，你可以随意吃，"},
{volumn:         1,chapter:         2,versenumber:        17,verse:"只是分别善恶树上的果子，你不可吃，因为你吃的日子必定死！”"},
{volumn:         1,chapter:         2,versenumber:        18,verse:"耶和华　神说：“那人独居不好，我要为他造一个配偶帮助他。”"},
{volumn:         1,chapter:         2,versenumber:        19,verse:"耶和华　神用土所造成的野地各样走兽和空中各样飞鸟都带到那人面前，看他叫什么。那人怎样叫各样的活物，那就是它的名字。"},
{volumn:         1,chapter:         2,versenumber:        20,verse:"那人便给一切牲畜和空中飞鸟、野地走兽都起了名；只是那人没有遇见配偶帮助他。"},
{volumn:         1,chapter:         2,versenumber:        21,verse:"耶和华　神使他沉睡，他就睡了；于是取下他的一条肋骨，又把肉合起来。"},
{volumn:         1,chapter:         2,versenumber:        22,verse:"耶和华　神就用那人身上所取的肋骨造成一个女人，领她到那人跟前。"},
{volumn:         1,chapter:         2,versenumber:        23,verse:"那人说：“这是我骨中的骨，肉中的肉，可以称她为“女人”，因为她是从“男人”身上取出来的。”"},
{volumn:         1,chapter:         2,versenumber:        24,verse:"因此，人要离开父母，与妻子连合，二人成为一体。"},
{volumn:         1,chapter:         2,versenumber:        25,verse:"当时夫妻二人赤身露体，并不羞耻。"},
{volumn:         1,chapter:         3,versenumber:         1,verse:"耶和华　神所造的，惟有蛇比田野一切的活物更狡猾。蛇对女人说：“　神岂是真说不许你们吃园中所有树上的果子吗？”"},
{volumn:         1,chapter:         3,versenumber:         2,verse:"女人对蛇说：“园中树上的果子，我们可以吃，"},
{volumn:         1,chapter:         3,versenumber:         3,verse:"惟有园当中那棵树上的果子，　神曾说：‘你们不可吃，也不可摸，免得你们死。’”"},
{volumn:         1,chapter:         3,versenumber:         4,verse:"蛇对女人说：“你们不一定死；"},
{volumn:         1,chapter:         3,versenumber:         5,verse:"因为　神知道，你们吃的日子眼睛就明亮了，你们便如　神能知道善恶。”"},
{volumn:         1,chapter:         3,versenumber:         6,verse:"于是女人见那棵树的果子好作食物，也悦人的眼目，且是可喜爱的，能使人有智慧，就摘下果子来吃了，又给她丈夫，她丈夫也吃了。"},
{volumn:         1,chapter:         3,versenumber:         7,verse:"他们二人的眼睛就明亮了，才知道自己是赤身露体，便拿无花果树的叶子为自己编做裙子。"},
{volumn:         1,chapter:         3,versenumber:         8,verse:"天起了凉风，耶和华　神在园中行走。那人和他妻子听见　神的声音，就藏在园里的树木中，躲避耶和华　神的面。"},
{volumn:         1,chapter:         3,versenumber:         9,verse:"耶和华　神呼唤那人，对他说：“你在哪里？”"},
{volumn:         1,chapter:         3,versenumber:        10,verse:"他说：“我在园中听见你的声音，我就害怕；因为我赤身露体，我便藏了。”"},
{volumn:         1,chapter:         3,versenumber:        11,verse:"耶和华说：“谁告诉你赤身露体呢？莫非你吃了我吩咐你不可吃的那树上的果子吗？”"},
{volumn:         1,chapter:         3,versenumber:        12,verse:"那人说：“你所赐给我、与我同居的女人，她把那树上的果子给我，我就吃了。”"},
{volumn:         1,chapter:         3,versenumber:        13,verse:"耶和华　神对女人说：“你做的是什么事呢？”女人说：“那蛇引诱我，我就吃了。”"},
{volumn:         1,chapter:         3,versenumber:        14,verse:"耶和华　神对蛇说：“你既做了这事，就必受咒诅，比一切的牲畜野兽更甚；你必用肚子行走，终身吃土。"},
{volumn:         1,chapter:         3,versenumber:        15,verse:"我又要叫你和女人彼此为仇；你的后裔和女人的后裔也彼此为仇。女人的后裔要伤你的头；你要伤他的脚跟。”"},
{volumn:         1,chapter:         3,versenumber:        16,verse:"又对女人说：“ 我必多多加增你怀胎的苦楚；你生产儿女必多受苦楚。你必恋慕你丈夫；你丈夫必管辖你。”"},
{volumn:         1,chapter:         3,versenumber:        17,verse:"又对亚当说：“你既听从妻子的话，吃了我所吩咐你不可吃的那树上的果子，地必为你的缘故受咒诅；你必终身劳苦才能从地里得吃的。"},
{volumn:         1,chapter:         3,versenumber:        18,verse:"地必给你长出荆棘和蒺藜来；你也要吃田间的菜蔬。"},
{volumn:         1,chapter:         3,versenumber:        19,verse:"你必汗流满面才得糊口，直到你归了土，因为你是从土而出的。你本是尘土，仍要归于尘土。”"},
{volumn:         1,chapter:         3,versenumber:        20,verse:"亚当给他妻子起名叫夏娃，因为她是众生之母。"},
{volumn:         1,chapter:         3,versenumber:        21,verse:"耶和华　神为亚当和他妻子用皮子做衣服给他们穿。"},
{volumn:         1,chapter:         3,versenumber:        22,verse:"耶和华　神说：“那人已经与我们相似，能知道善恶；现在恐怕他伸手又摘生命树的果子吃，就永远活着。”"},
{volumn:         1,chapter:         3,versenumber:        23,verse:"耶和华　神便打发他出伊甸园去，耕种他所自出之土。"},
{volumn:         1,chapter:         3,versenumber:        24,verse:"于是把他赶出去了；又在伊甸园的东边安设基路伯和四面转动发火焰的剑，要把守生命树的道路。"},
{volumn:         1,chapter:         4,versenumber:         1,verse:"有一日，那人和他妻子夏娃同房，夏娃就怀孕，生了该隐（就是得的意思），便说：“耶和华使我得了一个男子。”"},
{volumn:         1,chapter:         4,versenumber:         2,verse:"又生了该隐的兄弟亚伯。亚伯是牧羊的；该隐是种地的。"},
{volumn:         1,chapter:         4,versenumber:         3,verse:"有一日，该隐拿地里的出产为供物献给耶和华；"},
{volumn:         1,chapter:         4,versenumber:         4,verse:"亚伯也将他羊群中头生的和羊的脂油献上。耶和华看中了亚伯和他的供物，"},
{volumn:         1,chapter:         4,versenumber:         5,verse:"只是看不中该隐和他的供物。该隐就大大地发怒，变了脸色。"},
{volumn:         1,chapter:         4,versenumber:         6,verse:"耶和华对该隐说：“你为什么发怒呢？你为什么变了脸色呢？"},
{volumn:         1,chapter:         4,versenumber:         7,verse:"你若行得好，岂不蒙悦纳？你若行得不好，罪就伏在门前。它必恋慕你，你却要制伏它。”"},
{volumn:         1,chapter:         4,versenumber:         8,verse:"该隐与他兄弟亚伯说话；二人正在田间。该隐起来打他兄弟亚伯，把他杀了。"},
{volumn:         1,chapter:         4,versenumber:         9,verse:"耶和华对该隐说：“你兄弟亚伯在哪里？”他说：“我不知道！我岂是看守我兄弟的吗？”"},
{volumn:         1,chapter:         4,versenumber:        10,verse:"耶和华说：“你做了什么事呢？你兄弟的血有声音从地里向我哀告。"},
{volumn:         1,chapter:         4,versenumber:        11,verse:"地开了口，从你手里接受你兄弟的血。现在你必从这地受咒诅。"},
{volumn:         1,chapter:         4,versenumber:        12,verse:"你种地，地不再给你效力；你必流离飘荡在地上。”"},
{volumn:         1,chapter:         4,versenumber:        13,verse:"该隐对耶和华说：“我的刑罚太重，过于我所能当的。"},
{volumn:         1,chapter:         4,versenumber:        14,verse:"你如今赶逐我离开这地，以致不见你面；我必流离飘荡在地上，凡遇见我的必杀我。”"},
{volumn:         1,chapter:         4,versenumber:        15,verse:"耶和华对他说：“凡杀该隐的，必遭报七倍。”耶和华就给该隐立一个记号，免得人遇见他就杀他。"},
{volumn:         1,chapter:         4,versenumber:        16,verse:"于是该隐离开耶和华的面，去住在伊甸东边挪得之地。"},
{volumn:         1,chapter:         4,versenumber:        17,verse:"该隐与妻子同房，他妻子就怀孕，生了以诺。该隐建造了一座城，就按着他儿子的名将那城叫做以诺。"},
{volumn:         1,chapter:         4,versenumber:        18,verse:"以诺生以拿；以拿生米户雅利；米户雅利生玛土撒利；玛土撒利生拉麦。"},
{volumn:         1,chapter:         4,versenumber:        19,verse:"拉麦娶了两个妻：一个名叫亚大，一个名叫洗拉。"},
{volumn:         1,chapter:         4,versenumber:        20,verse:"亚大生雅八；雅八就是住帐棚、牧养牲畜之人的祖师。"},
{volumn:         1,chapter:         4,versenumber:        21,verse:"雅八的兄弟名叫犹八；他是一切弹琴吹箫之人的祖师。"},
{volumn:         1,chapter:         4,versenumber:        22,verse:"洗拉又生了土八·该隐；他是打造各样铜铁利器的（或译：是铜匠铁匠的祖师）。土八·该隐的妹子是拿玛。"},
{volumn:         1,chapter:         4,versenumber:        23,verse:"拉麦对他两个妻子说：“亚大、洗拉，听我的声音；拉麦的妻子，细听我的话语：壮年人伤我，我把他杀了；少年人损我，我把他害了（或译：“ 我杀壮士却伤自己，我害幼童却损本身”）。"},
{volumn:         1,chapter:         4,versenumber:        24,verse:"若杀该隐，遭报七倍，杀拉麦，必遭报七十七倍。”"},
{volumn:         1,chapter:         4,versenumber:        25,verse:"亚当又与妻子同房，她就生了一个儿子，起名叫塞特，意思说：“　神另给我立了一个儿子代替亚伯，因为该隐杀了他。”"},
{volumn:         1,chapter:         4,versenumber:        26,verse:"塞特也生了一个儿子，起名叫以挪士。那时候，人才求告耶和华的名。"},
{volumn:         1,chapter:         5,versenumber:         1,verse:"亚当的后代记在下面。当　神造人的日子，是照着自己的样式造的，"},
{volumn:         1,chapter:         5,versenumber:         2,verse:"并且造男造女。在他们被造的日子，　神赐福给他们，称他们为“人”。"},
{volumn:         1,chapter:         5,versenumber:         3,verse:"亚当活到一百三十岁，生了一个儿子，形像样式和自己相似，就给他起名叫塞特。"},
{volumn:         1,chapter:         5,versenumber:         4,verse:"亚当生塞特之后，又在世八百年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:         5,verse:"亚当共活了九百三十岁就死了。"},
{volumn:         1,chapter:         5,versenumber:         6,verse:"塞特活到一百零五岁，生了以挪士。"},
{volumn:         1,chapter:         5,versenumber:         7,verse:"塞特生以挪士之后，又活了八百零七年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:         8,verse:"塞特共活了九百一十二岁就死了。"},
{volumn:         1,chapter:         5,versenumber:         9,verse:"以挪士活到九十岁，生了该南。"},
{volumn:         1,chapter:         5,versenumber:        10,verse:"以挪士生该南之后，又活了八百一十五年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        11,verse:"以挪士共活了九百零五岁就死了。"},
{volumn:         1,chapter:         5,versenumber:        12,verse:"该南活到七十岁，生了玛勒列。"},
{volumn:         1,chapter:         5,versenumber:        13,verse:"该南生玛勒列之后，又活了八百四十年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        14,verse:"该南共活了九百一十岁就死了。"},
{volumn:         1,chapter:         5,versenumber:        15,verse:"玛勒列活到六十五岁，生了雅列。"},
{volumn:         1,chapter:         5,versenumber:        16,verse:"玛勒列生雅列之后，又活了八百三十年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        17,verse:"玛勒列共活了八百九十五岁就死了。"},
{volumn:         1,chapter:         5,versenumber:        18,verse:"雅列活到一百六十二岁，生了以诺。"},
{volumn:         1,chapter:         5,versenumber:        19,verse:"雅列生以诺之后，又活了八百年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        20,verse:"雅列共活了九百六十二岁就死了。"},
{volumn:         1,chapter:         5,versenumber:        21,verse:"以诺活到六十五岁，生了玛土撒拉。"},
{volumn:         1,chapter:         5,versenumber:        22,verse:"以诺生玛土撒拉之后，与　神同行三百年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        23,verse:"以诺共活了三百六十五岁。"},
{volumn:         1,chapter:         5,versenumber:        24,verse:"以诺与　神同行，　神将他取去，他就不在世了。"},
{volumn:         1,chapter:         5,versenumber:        25,verse:"玛土撒拉活到一百八十七岁，生了拉麦。"},
{volumn:         1,chapter:         5,versenumber:        26,verse:"玛土撒拉生拉麦之后，又活了七百八十二年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        27,verse:"玛土撒拉共活了九百六十九岁就死了。"},
{volumn:         1,chapter:         5,versenumber:        28,verse:"拉麦活到一百八十二岁，生了一个儿子，"},
{volumn:         1,chapter:         5,versenumber:        29,verse:"给他起名叫挪亚，说：“这个儿子必为我们的操作和手中的劳苦安慰我们；这操作劳苦是因为耶和华咒诅地。”"},
{volumn:         1,chapter:         5,versenumber:        30,verse:"拉麦生挪亚之后，又活了五百九十五年，并且生儿养女。"},
{volumn:         1,chapter:         5,versenumber:        31,verse:"拉麦共活了七百七十七岁就死了。"},
{volumn:         1,chapter:         5,versenumber:        32,verse:"挪亚五百岁生了闪、含、雅弗。"},
{volumn:         1,chapter:         6,versenumber:         1,verse:"当人在世上多起来、又生女儿的时候，"},
{volumn:         1,chapter:         6,versenumber:         2,verse:"神的儿子们看见人的女子美貌，就随意挑选，娶来为妻。"},
{volumn:         1,chapter:         6,versenumber:         3,verse:"耶和华说：“人既属乎血气，我的灵就不永远住在他里面；然而他的日子还可到一百二十年。”"},
{volumn:         1,chapter:         6,versenumber:         4,verse:"那时候有伟人在地上，后来　神的儿子们和人的女子们交合生子；那就是上古英武有名的人。"},
{volumn:         1,chapter:         6,versenumber:         5,verse:"耶和华见人在地上罪恶很大，终日所思想的尽都是恶，"},
{volumn:         1,chapter:         6,versenumber:         6,verse:"耶和华就后悔造人在地上，心中忧伤。"},
{volumn:         1,chapter:         6,versenumber:         7,verse:"耶和华说：“我要将所造的人和走兽，并昆虫，以及空中的飞鸟，都从地上除灭，因为我造他们后悔了。”"},
{volumn:         1,chapter:         6,versenumber:         8,verse:"惟有挪亚在耶和华眼前蒙恩。"},
{volumn:         1,chapter:         6,versenumber:         9,verse:"挪亚的后代记在下面。挪亚是个义人，在当时的世代是个完全人。挪亚与　神同行。"},
{volumn:         1,chapter:         6,versenumber:        10,verse:"挪亚生了三个儿子，就是闪、含、雅弗。"},
{volumn:         1,chapter:         6,versenumber:        11,verse:"世界在　神面前败坏，地上满了强暴。"},
{volumn:         1,chapter:         6,versenumber:        12,verse:"神观看世界，见是败坏了；凡有血气的人在地上都败坏了行为。"},
{volumn:         1,chapter:         6,versenumber:        13,verse:"神就对挪亚说：“凡有血气的人，他的尽头已经来到我面前；因为地上满了他们的强暴，我要把他们和地一并毁灭。"},
{volumn:         1,chapter:         6,versenumber:        14,verse:"你要用歌斐木造一只方舟，分一间一间地造，里外抹上松香。"},
{volumn:         1,chapter:         6,versenumber:        15,verse:"方舟的造法乃是这样：要长三百肘，宽五十肘，高三十肘。"},
{volumn:         1,chapter:         6,versenumber:        16,verse:"方舟上边要留透光处，高一肘。方舟的门要开在旁边。方舟要分上、中、下三层。"},
{volumn:         1,chapter:         6,versenumber:        17,verse:"看哪，我要使洪水泛滥在地上，毁灭天下；凡地上有血肉、有气息的活物，无一不死。"},
{volumn:         1,chapter:         6,versenumber:        18,verse:"我却要与你立约；你同你的妻，与儿子儿妇，都要进入方舟。"},
{volumn:         1,chapter:         6,versenumber:        19,verse:"凡有血肉的活物，每样两个，一公一母，你要带进方舟，好在你那里保全生命。"},
{volumn:         1,chapter:         6,versenumber:        20,verse:"飞鸟各从其类，牲畜各从其类，地上的昆虫各从其类，每样两个，要到你那里，好保全生命。"},
{volumn:         1,chapter:         6,versenumber:        21,verse:"你要拿各样食物积蓄起来，好作你和它们的食物。”"},
{volumn:         1,chapter:         6,versenumber:        22,verse:"挪亚就这样行。凡　神所吩咐的，他都照样行了。"},
{volumn:         1,chapter:         7,versenumber:         1,verse:"耶和华对挪亚说：“你和你的全家都要进入方舟；因为在这世代中，我见你在我面前是义人。"},
{volumn:         1,chapter:         7,versenumber:         2,verse:"凡洁净的畜类，你要带七公七母；不洁净的畜类，你要带一公一母；"},
{volumn:         1,chapter:         7,versenumber:         3,verse:"空中的飞鸟也要带七公七母，可以留种，活在全地上；"},
{volumn:         1,chapter:         7,versenumber:         4,verse:"因为再过七天，我要降雨在地上四十昼夜，把我所造的各种活物都从地上除灭。”"},
{volumn:         1,chapter:         7,versenumber:         5,verse:"挪亚就遵着耶和华所吩咐的行了。"},
{volumn:         1,chapter:         7,versenumber:         6,verse:"当洪水泛滥在地上的时候，挪亚整六百岁。"},
{volumn:         1,chapter:         7,versenumber:         7,verse:"挪亚就同他的妻和儿子儿妇都进入方舟，躲避洪水。"},
{volumn:         1,chapter:         7,versenumber:         8,verse:"洁净的畜类和不洁净的畜类，飞鸟并地上一切的昆虫，"},
{volumn:         1,chapter:         7,versenumber:         9,verse:"都是一对一对地，有公有母，到挪亚那里进入方舟，正如　神所吩咐挪亚的。"},
{volumn:         1,chapter:         7,versenumber:        10,verse:"过了那七天，洪水泛滥在地上。"},
{volumn:         1,chapter:         7,versenumber:        11,verse:"当挪亚六百岁，二月十七日那一天，大渊的泉源都裂开了，天上的窗户也敞开了，"},
{volumn:         1,chapter:         7,versenumber:        12,verse:"四十昼夜降大雨在地上。"},
{volumn:         1,chapter:         7,versenumber:        13,verse:"正当那日，挪亚和他三个儿子闪、含、雅弗，并挪亚的妻子和三个儿妇，都进入方舟。"},
{volumn:         1,chapter:         7,versenumber:        14,verse:"他们和百兽，各从其类，一切牲畜，各从其类，爬在地上的昆虫，各从其类，一切禽鸟，各从其类，都进入方舟。"},
{volumn:         1,chapter:         7,versenumber:        15,verse:"凡有血肉、有气息的活物，都一对一对地到挪亚那里，进入方舟。"},
{volumn:         1,chapter:         7,versenumber:        16,verse:"凡有血肉进入方舟的，都是有公有母，正如　神所吩咐挪亚的。耶和华就把他关在方舟里头。"},
{volumn:         1,chapter:         7,versenumber:        17,verse:"洪水泛滥在地上四十天，水往上长，把方舟从地上漂起。"},
{volumn:         1,chapter:         7,versenumber:        18,verse:"水势浩大，在地上大大地往上长，方舟在水面上漂来漂去。"},
{volumn:         1,chapter:         7,versenumber:        19,verse:"水势在地上极其浩大，天下的高山都淹没了。"},
{volumn:         1,chapter:         7,versenumber:        20,verse:"水势比山高过十五肘，山岭都淹没了。"},
{volumn:         1,chapter:         7,versenumber:        21,verse:"凡在地上有血肉的动物，就是飞鸟、牲畜、走兽，和爬在地上的昆虫，以及所有的人，都死了。"},
{volumn:         1,chapter:         7,versenumber:        22,verse:"凡在旱地上、鼻孔有气息的生灵都死了。"},
{volumn:         1,chapter:         7,versenumber:        23,verse:"凡地上各类的活物，连人带牲畜、昆虫，以及空中的飞鸟，都从地上除灭了，只留下挪亚和那些与他同在方舟里的。"},
{volumn:         1,chapter:         7,versenumber:        24,verse:"水势浩大，在地上共一百五十天。"},
{volumn:         1,chapter:         8,versenumber:         1,verse:"神记念挪亚和挪亚方舟里的一切走兽牲畜。　神叫风吹地，水势渐落。"},
{volumn:         1,chapter:         8,versenumber:         2,verse:"渊源和天上的窗户都闭塞了，天上的大雨也止住了。"},
{volumn:         1,chapter:         8,versenumber:         3,verse:"水从地上渐退。过了一百五十天，水就渐消。"},
{volumn:         1,chapter:         8,versenumber:         4,verse:"七月十七日，方舟停在亚拉腊山上。"},
{volumn:         1,chapter:         8,versenumber:         5,verse:"水又渐消，到十月初一日，山顶都现出来了。"},
{volumn:         1,chapter:         8,versenumber:         6,verse:"过了四十天，挪亚开了方舟的窗户，"},
{volumn:         1,chapter:         8,versenumber:         7,verse:"放出一只乌鸦去；那乌鸦飞来飞去，直到地上的水都干了。"},
{volumn:         1,chapter:         8,versenumber:         8,verse:"他又放出一只鸽子去，要看看水从地上退了没有。"},
{volumn:         1,chapter:         8,versenumber:         9,verse:"但遍地上都是水，鸽子找不着落脚之地，就回到方舟挪亚那里，挪亚伸手把鸽子接进方舟来。"},
{volumn:         1,chapter:         8,versenumber:        10,verse:"他又等了七天，再把鸽子从方舟放出去。"},
{volumn:         1,chapter:         8,versenumber:        11,verse:"到了晚上，鸽子回到他那里，嘴里叼着一个新拧下来的橄榄叶子，挪亚就知道地上的水退了。"},
{volumn:         1,chapter:         8,versenumber:        12,verse:"他又等了七天，放出鸽子去，鸽子就不再回来了。"},
{volumn:         1,chapter:         8,versenumber:        13,verse:"到挪亚六百零一岁，正月初一日，地上的水都干了。挪亚撤去方舟的盖观看，便见地面上干了。"},
{volumn:         1,chapter:         8,versenumber:        14,verse:"到了二月二十七日，地就都干了。"},
{volumn:         1,chapter:         8,versenumber:        15,verse:"神对挪亚说："},
{volumn:         1,chapter:         8,versenumber:        16,verse:"“你和你的妻子、儿子、儿妇都可以出方舟。"},
{volumn:         1,chapter:         8,versenumber:        17,verse:"在你那里凡有血肉的活物，就是飞鸟、牲畜，和一切爬在地上的昆虫，都要带出来，叫它在地上多多滋生，大大兴旺。”"},
{volumn:         1,chapter:         8,versenumber:        18,verse:"于是挪亚和他的妻子、儿子、儿妇都出来了。"},
{volumn:         1,chapter:         8,versenumber:        19,verse:"一切走兽、昆虫、飞鸟，和地上所有的动物，各从其类，也都出了方舟。"},
{volumn:         1,chapter:         8,versenumber:        20,verse:"挪亚为耶和华筑了一座坛，拿各类洁净的牲畜、飞鸟献在坛上为燔祭。"},
{volumn:         1,chapter:         8,versenumber:        21,verse:"耶和华闻那馨香之气，就心里说：“我不再因人的缘故咒诅地（人从小时心里怀着恶念），也不再按着我才行的灭各种的活物了。"},
{volumn:         1,chapter:         8,versenumber:        22,verse:"地还存留的时候，稼穑、寒暑、冬夏、昼夜就永不停息了。”"},
{volumn:         1,chapter:         9,versenumber:         1,verse:"神赐福给挪亚和他的儿子，对他们说：“你们要生养众多，遍满了地。"},
{volumn:         1,chapter:         9,versenumber:         2,verse:"凡地上的走兽和空中的飞鸟都必惊恐，惧怕你们，连地上一切的昆虫并海里一切的鱼都交付你们的手。"},
{volumn:         1,chapter:         9,versenumber:         3,verse:"凡活着的动物都可以作你们的食物。这一切我都赐给你们，如同菜蔬一样。"},
{volumn:         1,chapter:         9,versenumber:         4,verse:"惟独肉带着血，那就是它的生命，你们不可吃。"},
{volumn:         1,chapter:         9,versenumber:         5,verse:"流你们血、害你们命的，无论是兽是人，我必讨他的罪，就是向各人的弟兄也是如此。"},
{volumn:         1,chapter:         9,versenumber:         6,verse:"凡流人血的，他的血也必被人所流，因为　神造人是照自己的形像造的。"},
{volumn:         1,chapter:         9,versenumber:         7,verse:"你们要生养众多，在地上昌盛繁茂。”"},
{volumn:         1,chapter:         9,versenumber:         8,verse:"神晓谕挪亚和他的儿子说："},
{volumn:         1,chapter:         9,versenumber:         9,verse:"“我与你们和你们的后裔立约，"},
{volumn:         1,chapter:         9,versenumber:        10,verse:"并与你们这里的一切活物，就是飞鸟、牲畜、走兽，凡从方舟里出来的活物立约。"},
{volumn:         1,chapter:         9,versenumber:        11,verse:"我与你们立约，凡有血肉的，不再被洪水灭绝，也不再有洪水毁坏地了。”"},
{volumn:         1,chapter:         9,versenumber:        12,verse:"神说：“我与你们并你们这里的各样活物所立的永约是有记号的。"},
{volumn:         1,chapter:         9,versenumber:        13,verse:"我把虹放在云彩中，这就可作我与地立约的记号了。"},
{volumn:         1,chapter:         9,versenumber:        14,verse:"我使云彩盖地的时候，必有虹现在云彩中，"},
{volumn:         1,chapter:         9,versenumber:        15,verse:"我便记念我与你们和各样有血肉的活物所立的约，水就再不泛滥、毁坏一切有血肉的物了。"},
{volumn:         1,chapter:         9,versenumber:        16,verse:"虹必现在云彩中，我看见，就要记念我与地上各样有血肉的活物所立的永约。”"},
{volumn:         1,chapter:         9,versenumber:        17,verse:"神对挪亚说：“这就是我与地上一切有血肉之物立约的记号了。”"},
{volumn:         1,chapter:         9,versenumber:        18,verse:"出方舟挪亚的儿子就是闪、含、雅弗。含是迦南的父亲。"},
{volumn:         1,chapter:         9,versenumber:        19,verse:"这是挪亚的三个儿子，他们的后裔分散在全地。"},
{volumn:         1,chapter:         9,versenumber:        20,verse:"挪亚作起农夫来，栽了一个葡萄园。"},
{volumn:         1,chapter:         9,versenumber:        21,verse:"他喝了园中的酒便醉了，在帐棚里赤着身子。"},
{volumn:         1,chapter:         9,versenumber:        22,verse:"迦南的父亲含看见他父亲赤身，就到外边告诉他两个弟兄。"},
{volumn:         1,chapter:         9,versenumber:        23,verse:"于是闪和雅弗拿件衣服搭在肩上，倒退着进去，给他父亲盖上；他们背着脸就看不见父亲的赤身。"},
{volumn:         1,chapter:         9,versenumber:        24,verse:"挪亚醒了酒，知道小儿子向他所做的事，"},
{volumn:         1,chapter:         9,versenumber:        25,verse:"就说：“迦南当受咒诅，必给他弟兄作奴仆的奴仆。”"},
{volumn:         1,chapter:         9,versenumber:        26,verse:"又说：“ 耶和华闪的　神是应当称颂的！愿迦南作闪的奴仆。"},
{volumn:         1,chapter:         9,versenumber:        27,verse:"愿　神使雅弗扩张，使他住在闪的帐棚里；又愿迦南作他的奴仆。”"},
{volumn:         1,chapter:         9,versenumber:        28,verse:"洪水以后，挪亚又活了三百五十年。"},
{volumn:         1,chapter:         9,versenumber:        29,verse:"挪亚共活了九百五十岁就死了。"},
{volumn:         1,chapter:        10,versenumber:         1,verse:"挪亚的儿子闪、含、雅弗的后代记在下面。洪水以后，他们都生了儿子。"},
{volumn:         1,chapter:        10,versenumber:         2,verse:"雅弗的儿子是歌篾、玛各、玛代、雅完、土巴、米设、提拉。"},
{volumn:         1,chapter:        10,versenumber:         3,verse:"歌篾的儿子是亚实基拿、利法、陀迦玛。"},
{volumn:         1,chapter:        10,versenumber:         4,verse:"雅完的儿子是以利沙、他施、基提、多单。"},
{volumn:         1,chapter:        10,versenumber:         5,verse:"这些人的后裔将各国的地土、海岛分开居住，各随各的方言、宗族立国。"},
{volumn:         1,chapter:        10,versenumber:         6,verse:"含的儿子是古实、麦西、弗、迦南。"},
{volumn:         1,chapter:        10,versenumber:         7,verse:"古实的儿子是西巴、哈腓拉、撒弗他、拉玛、撒弗提迦。拉玛的儿子是示巴、底但。"},
{volumn:         1,chapter:        10,versenumber:         8,verse:"古实又生宁录，他为世上英雄之首。"},
{volumn:         1,chapter:        10,versenumber:         9,verse:"他在耶和华面前是个英勇的猎户，所以俗语说：“像宁录在耶和华面前是个英勇的猎户。”"},
{volumn:         1,chapter:        10,versenumber:        10,verse:"他国的起头是巴别、以力、亚甲、甲尼，都在示拿地。"},
{volumn:         1,chapter:        10,versenumber:        11,verse:"他从那地出来往亚述去，建造尼尼微、利河伯、迦拉，"},
{volumn:         1,chapter:        10,versenumber:        12,verse:"和尼尼微、迦拉中间的利鲜，这就是那大城。"},
{volumn:         1,chapter:        10,versenumber:        13,verse:"麦西生路低人、亚拿米人、利哈比人、拿弗土希人、"},
{volumn:         1,chapter:        10,versenumber:        14,verse:"帕斯鲁细人、迦斯路希人、迦斐托人；从迦斐托出来的有非利士人。"},
{volumn:         1,chapter:        10,versenumber:        15,verse:"迦南生长子西顿，又生赫"},
{volumn:         1,chapter:        10,versenumber:        16,verse:"和耶布斯人、亚摩利人、革迦撒人、"},
{volumn:         1,chapter:        10,versenumber:        17,verse:"希未人、亚基人、西尼人、"},
{volumn:         1,chapter:        10,versenumber:        18,verse:"亚瓦底人、洗玛利人、哈马人，后来迦南的诸族分散了。"},
{volumn:         1,chapter:        10,versenumber:        19,verse:"迦南的境界是从西顿向基拉耳的路上，直到迦萨，又向所多玛、蛾摩拉、押玛、洗扁的路上，直到拉沙。"},
{volumn:         1,chapter:        10,versenumber:        20,verse:"这就是含的后裔，各随他们的宗族、方言，所住的地土、邦国。"},
{volumn:         1,chapter:        10,versenumber:        21,verse:"雅弗的哥哥闪，是希伯子孙之祖，他也生了儿子。"},
{volumn:         1,chapter:        10,versenumber:        22,verse:"闪的儿子是以拦、亚述、亚法撒、路德、亚兰。"},
{volumn:         1,chapter:        10,versenumber:        23,verse:"亚兰的儿子是乌斯、户勒、基帖、玛施。"},
{volumn:         1,chapter:        10,versenumber:        24,verse:"亚法撒生沙拉；沙拉生希伯。"},
{volumn:         1,chapter:        10,versenumber:        25,verse:"希伯生了两个儿子，一个名叫法勒（就是分的意思），因为那时人就分地居住；法勒的兄弟名叫约坍。"},
{volumn:         1,chapter:        10,versenumber:        26,verse:"约坍生亚摩答、沙列、哈萨玛非、耶拉、"},
{volumn:         1,chapter:        10,versenumber:        27,verse:"哈多兰、乌萨、德拉、"},
{volumn:         1,chapter:        10,versenumber:        28,verse:"俄巴路、亚比玛利、示巴、"},
{volumn:         1,chapter:        10,versenumber:        29,verse:"阿斐、哈腓拉、约巴，这都是约坍的儿子。"},
{volumn:         1,chapter:        10,versenumber:        30,verse:"他们所住的地方是从米沙直到西发东边的山。"},
{volumn:         1,chapter:        10,versenumber:        31,verse:"这就是闪的子孙，各随他们的宗族、方言，所住的地土、邦国。"},
{volumn:         1,chapter:        10,versenumber:        32,verse:"这些都是挪亚三个儿子的宗族，各随他们的支派立国。洪水以后，他们在地上分为邦国。"},
{volumn:         1,chapter:        11,versenumber:         1,verse:"那时，天下人的口音、言语都是一样。"},
{volumn:         1,chapter:        11,versenumber:         2,verse:"他们往东边迁移的时候，在示拿地遇见一片平原，就住在那里。"},
{volumn:         1,chapter:        11,versenumber:         3,verse:"他们彼此商量说：“来吧！我们要做砖，把砖烧透了。”他们就拿砖当石头，又拿石漆当灰泥。"},
{volumn:         1,chapter:        11,versenumber:         4,verse:"他们说：“来吧！我们要建造一座城和一座塔，塔顶通天，为要传扬我们的名，免得我们分散在全地上。”"},
{volumn:         1,chapter:        11,versenumber:         5,verse:"耶和华降临，要看看世人所建造的城和塔。"},
{volumn:         1,chapter:        11,versenumber:         6,verse:"耶和华说：“看哪，他们成为一样的人民，都是一样的言语，如今既做起这事来，以后他们所要做的事就没有不成就的了。"},
{volumn:         1,chapter:        11,versenumber:         7,verse:"我们下去，在那里变乱他们的口音，使他们的言语彼此不通。”"},
{volumn:         1,chapter:        11,versenumber:         8,verse:"于是耶和华使他们从那里分散在全地上；他们就停工，不造那城了。"},
{volumn:         1,chapter:        11,versenumber:         9,verse:"因为耶和华在那里变乱天下人的言语，使众人分散在全地上，所以那城名叫巴别（就是“ 变乱”的意思）。"},
{volumn:         1,chapter:        11,versenumber:        10,verse:"闪的后代记在下面。洪水以后二年，闪一百岁生了亚法撒。"},
{volumn:         1,chapter:        11,versenumber:        11,verse:"闪生亚法撒之后又活了五百年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        12,verse:"亚法撒活到三十五岁，生了沙拉。"},
{volumn:         1,chapter:        11,versenumber:        13,verse:"亚法撒生沙拉之后又活了四百零三年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        14,verse:"沙拉活到三十岁，生了希伯。"},
{volumn:         1,chapter:        11,versenumber:        15,verse:"沙拉生希伯之后又活了四百零三年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        16,verse:"希伯活到三十四岁，生了法勒。"},
{volumn:         1,chapter:        11,versenumber:        17,verse:"希伯生法勒之后又活了四百三十年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        18,verse:"法勒活到三十岁，生了拉吴。"},
{volumn:         1,chapter:        11,versenumber:        19,verse:"法勒生拉吴之后又活了二百零九年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        20,verse:"拉吴活到三十二岁，生了西鹿。"},
{volumn:         1,chapter:        11,versenumber:        21,verse:"拉吴生西鹿之后又活了二百零七年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        22,verse:"西鹿活到三十岁，生了拿鹤。"},
{volumn:         1,chapter:        11,versenumber:        23,verse:"西鹿生拿鹤之后又活了二百年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        24,verse:"拿鹤活到二十九岁，生了他拉。"},
{volumn:         1,chapter:        11,versenumber:        25,verse:"拿鹤生他拉之后又活了一百一十九年，并且生儿养女。"},
{volumn:         1,chapter:        11,versenumber:        26,verse:"他拉活到七十岁，生了亚伯兰、拿鹤、哈兰。"},
{volumn:         1,chapter:        11,versenumber:        27,verse:"他拉的后代记在下面。他拉生亚伯兰、拿鹤、哈兰；哈兰生罗得。"},
{volumn:         1,chapter:        11,versenumber:        28,verse:"哈兰死在他的本地迦勒底的吾珥，在他父亲他拉之先。"},
{volumn:         1,chapter:        11,versenumber:        29,verse:"亚伯兰、拿鹤各娶了妻：亚伯兰的妻子名叫撒莱；拿鹤的妻子名叫密迦，是哈兰的女儿；哈兰是密迦和亦迦的父亲。"},
{volumn:         1,chapter:        11,versenumber:        30,verse:"撒莱不生育，没有孩子。"},
{volumn:         1,chapter:        11,versenumber:        31,verse:"他拉带着他儿子亚伯兰和他孙子哈兰的儿子罗得，并他儿妇亚伯兰的妻子撒莱，出了迦勒底的吾珥，要往迦南地去；他们走到哈兰，就住在那里。"},
{volumn:         1,chapter:        11,versenumber:        32,verse:"他拉共活了二百零五岁，就死在哈兰。"},
{volumn:         1,chapter:        12,versenumber:         1,verse:"耶和华对亚伯兰说：“你要离开本地、本族、父家，往我所要指示你的地去。"},
{volumn:         1,chapter:        12,versenumber:         2,verse:"我必叫你成为大国。我必赐福给你，叫你的名为大；你也要叫别人得福。"},
{volumn:         1,chapter:        12,versenumber:         3,verse:"为你祝福的，我必赐福与他；那咒诅你的，我必咒诅他。地上的万族都要因你得福。”"},
{volumn:         1,chapter:        12,versenumber:         4,verse:"亚伯兰就照着耶和华的吩咐去了；罗得也和他同去。亚伯兰出哈兰的时候年七十五岁。"},
{volumn:         1,chapter:        12,versenumber:         5,verse:"亚伯兰将他妻子撒莱和侄儿罗得，连他们在哈兰所积蓄的财物、所得的人口，都带往迦南地去。他们就到了迦南地。"},
{volumn:         1,chapter:        12,versenumber:         6,verse:"亚伯兰经过那地，到了示剑地方、摩利橡树那里。那时迦南人住在那地。"},
{volumn:         1,chapter:        12,versenumber:         7,verse:"耶和华向亚伯兰显现，说：“我要把这地赐给你的后裔。”亚伯兰就在那里为向他显现的耶和华筑了一座坛。"},
{volumn:         1,chapter:        12,versenumber:         8,verse:"从那里他又迁到伯特利东边的山，支搭帐棚；西边是伯特利，东边是艾。他在那里又为耶和华筑了一座坛，求告耶和华的名。"},
{volumn:         1,chapter:        12,versenumber:         9,verse:"后来亚伯兰又渐渐迁往南地去。"},
{volumn:         1,chapter:        12,versenumber:        10,verse:"那地遭遇饥荒。因饥荒甚大，亚伯兰就下埃及去，要在那里暂居。"},
{volumn:         1,chapter:        12,versenumber:        11,verse:"将近埃及，就对他妻子撒莱说：“我知道你是容貌俊美的妇人。"},
{volumn:         1,chapter:        12,versenumber:        12,verse:"埃及人看见你必说：‘这是他的妻子’，他们就要杀我，却叫你存活。"},
{volumn:         1,chapter:        12,versenumber:        13,verse:"求你说，你是我的妹子，使我因你得平安，我的命也因你存活。”"},
{volumn:         1,chapter:        12,versenumber:        14,verse:"及至亚伯兰到了埃及，埃及人看见那妇人极其美貌。"},
{volumn:         1,chapter:        12,versenumber:        15,verse:"法老的臣宰看见了她，就在法老面前夸奖她。那妇人就被带进法老的宫去。"},
{volumn:         1,chapter:        12,versenumber:        16,verse:"法老因这妇人就厚待亚伯兰，亚伯兰得了许多牛、羊、骆驼、公驴、母驴、仆婢。"},
{volumn:         1,chapter:        12,versenumber:        17,verse:"耶和华因亚伯兰妻子撒莱的缘故，降大灾与法老和他的全家。"},
{volumn:         1,chapter:        12,versenumber:        18,verse:"法老就召了亚伯兰来，说：“你这向我做的是什么事呢？为什么没有告诉我她是你的妻子？"},
{volumn:         1,chapter:        12,versenumber:        19,verse:"为什么说她是你的妹子，以致我把她取来要作我的妻子？现在你的妻子在这里，可以带她走吧。”"},
{volumn:         1,chapter:        12,versenumber:        20,verse:"于是法老吩咐人将亚伯兰和他妻子，并他所有的都送走了。"},
{volumn:         1,chapter:        13,versenumber:         1,verse:"亚伯兰带着他的妻子与罗得，并一切所有的，都从埃及上南地去。"},
{volumn:         1,chapter:        13,versenumber:         2,verse:"亚伯兰的金、银、牲畜极多。"},
{volumn:         1,chapter:        13,versenumber:         3,verse:"他从南地渐渐往伯特利去，到了伯特利和艾的中间，就是从前支搭帐棚的地方，"},
{volumn:         1,chapter:        13,versenumber:         4,verse:"也是他起先筑坛的地方；他又在那里求告耶和华的名。"},
{volumn:         1,chapter:        13,versenumber:         5,verse:"与亚伯兰同行的罗得也有牛群、羊群、帐棚。"},
{volumn:         1,chapter:        13,versenumber:         6,verse:"那地容不下他们；因为他们的财物甚多，使他们不能同居。"},
{volumn:         1,chapter:        13,versenumber:         7,verse:"当时，迦南人与比利洗人在那地居住。亚伯兰的牧人和罗得的牧人相争。"},
{volumn:         1,chapter:        13,versenumber:         8,verse:"亚伯兰就对罗得说：“你我不可相争，你的牧人和我的牧人也不可相争，因为我们是骨肉（原文是“弟兄”）。"},
{volumn:         1,chapter:        13,versenumber:         9,verse:"遍地不都在你眼前吗？请你离开我：你向左，我就向右；你向右，我就向左。”"},
{volumn:         1,chapter:        13,versenumber:        10,verse:"罗得举目看见约旦河的全平原，直到琐珥，都是滋润的，那地在耶和华未灭所多玛、蛾摩拉以先如同耶和华的园子，也像埃及地。"},
{volumn:         1,chapter:        13,versenumber:        11,verse:"于是罗得选择约旦河的全平原，往东迁移；他们就彼此分离了。"},
{volumn:         1,chapter:        13,versenumber:        12,verse:"亚伯兰住在迦南地，罗得住在平原的城邑，渐渐挪移帐棚，直到所多玛。"},
{volumn:         1,chapter:        13,versenumber:        13,verse:"所多玛人在耶和华面前罪大恶极。"},
{volumn:         1,chapter:        13,versenumber:        14,verse:"罗得离别亚伯兰以后，耶和华对亚伯兰说：“从你所在的地方，你举目向东西南北观看；"},
{volumn:         1,chapter:        13,versenumber:        15,verse:"凡你所看见的一切地，我都要赐给你和你的后裔，直到永远。"},
{volumn:         1,chapter:        13,versenumber:        16,verse:"我也要使你的后裔如同地上的尘沙那样多，人若能数算地上的尘沙才能数算你的后裔。"},
{volumn:         1,chapter:        13,versenumber:        17,verse:"你起来，纵横走遍这地，因为我必把这地赐给你。”"},
{volumn:         1,chapter:        13,versenumber:        18,verse:"亚伯兰就搬了帐棚，来到希伯仑、幔利的橡树那里居住，在那里为耶和华筑了一座坛。"},
{volumn:         1,chapter:        14,versenumber:         1,verse:"当暗拉非作示拿王，亚略作以拉撒王，基大老玛作以拦王，提达作戈印王的时候，"},
{volumn:         1,chapter:        14,versenumber:         2,verse:"他们都攻打所多玛王比拉、蛾摩拉王比沙、押玛王示纳、洗扁王善以别，和比拉王；比拉就是琐珥。"},
{volumn:         1,chapter:        14,versenumber:         3,verse:"这五王都在西订谷会合；西订谷就是盐海。"},
{volumn:         1,chapter:        14,versenumber:         4,verse:"他们已经侍奉基大老玛十二年，到十三年就背叛了。"},
{volumn:         1,chapter:        14,versenumber:         5,verse:"十四年，基大老玛和同盟的王都来在亚特律·加宁，杀败了利乏音人，在哈麦杀败了苏西人，在沙微·基列亭杀败了以米人，"},
{volumn:         1,chapter:        14,versenumber:         6,verse:"在何利人的西珥山杀败了何利人，一直杀到靠近旷野的伊勒·巴兰。"},
{volumn:         1,chapter:        14,versenumber:         7,verse:"他们回到安·密巴，就是加低斯，杀败了亚玛力全地的人，以及住在哈洗逊·他玛的亚摩利人。"},
{volumn:         1,chapter:        14,versenumber:         8,verse:"于是所多玛王、蛾摩拉王、押玛王、洗扁王，和比拉王（比拉就是琐珥）都出来，在西订谷摆阵，与他们交战，"},
{volumn:         1,chapter:        14,versenumber:         9,verse:"就是与以拦王基大老玛、戈印王提达、示拿王暗拉非、以拉撒王亚略交战；乃是四王与五王交战。"},
{volumn:         1,chapter:        14,versenumber:        10,verse:"西订谷有许多石漆坑。所多玛王和蛾摩拉王逃跑，有掉在坑里的，其余的人都往山上逃跑。"},
{volumn:         1,chapter:        14,versenumber:        11,verse:"四王就把所多玛和蛾摩拉所有的财物，并一切的粮食都掳掠去了；"},
{volumn:         1,chapter:        14,versenumber:        12,verse:"又把亚伯兰的侄儿罗得和罗得的财物掳掠去了。当时罗得正住在所多玛。"},
{volumn:         1,chapter:        14,versenumber:        13,verse:"有一个逃出来的人告诉希伯来人亚伯兰；亚伯兰正住在亚摩利人幔利的橡树那里。幔利和以实各并亚乃都是弟兄，曾与亚伯兰联盟。"},
{volumn:         1,chapter:        14,versenumber:        14,verse:"亚伯兰听见他侄儿（原文是“弟兄”）被掳去，就率领他家里生养的精练壮丁三百一十八人，直追到但，"},
{volumn:         1,chapter:        14,versenumber:        15,verse:"便在夜间，自己同仆人分队杀败敌人，又追到大马士革左边的何把，"},
{volumn:         1,chapter:        14,versenumber:        16,verse:"将被掳掠的一切财物夺回来，连他侄儿罗得和他的财物，以及妇女、人民也都夺回来。"},
{volumn:         1,chapter:        14,versenumber:        17,verse:"亚伯兰杀败基大老玛和与他同盟的王回来的时候，所多玛王出来，在沙微谷迎接他；沙微谷就是王谷。"},
{volumn:         1,chapter:        14,versenumber:        18,verse:"又有撒冷王麦基洗德带着饼和酒出来迎接；他是至高　神的祭司。"},
{volumn:         1,chapter:        14,versenumber:        19,verse:"他为亚伯兰祝福，说：“愿天地的主、至高的　神赐福与亚伯兰！"},
{volumn:         1,chapter:        14,versenumber:        20,verse:"至高的　神把敌人交在你手里，是应当称颂的！”亚伯兰就把所得的拿出十分之一来，给麦基洗德。"},
{volumn:         1,chapter:        14,versenumber:        21,verse:"所多玛王对亚伯兰说：“你把人口给我，财物你自己拿去吧！”"},
{volumn:         1,chapter:        14,versenumber:        22,verse:"亚伯兰对所多玛王说：“我已经向天地的主、至高的　神耶和华起誓："},
{volumn:         1,chapter:        14,versenumber:        23,verse:"凡是你的东西，就是一根线、一根鞋带，我都不拿，免得你说：‘我使亚伯兰富足！’"},
{volumn:         1,chapter:        14,versenumber:        24,verse:"只有仆人所吃的，并与我同行的亚乃、以实各、幔利所应得的份，可以任凭他们拿去。”"},
{volumn:         1,chapter:        15,versenumber:         1,verse:"这事以后，耶和华在异象中有话对亚伯兰说：“亚伯兰，你不要惧怕！我是你的盾牌，必大大地赏赐你。”"},
{volumn:         1,chapter:        15,versenumber:         2,verse:"亚伯兰说：“主耶和华啊，我既无子，你还赐我什么呢？并且要承受我家业的是大马士革人以利以谢。”"},
{volumn:         1,chapter:        15,versenumber:         3,verse:"亚伯兰又说：“你没有给我儿子；那生在我家中的人就是我的后嗣。”"},
{volumn:         1,chapter:        15,versenumber:         4,verse:"耶和华又有话对他说：“这人必不成为你的后嗣；你本身所生的才成为你的后嗣。”"},
{volumn:         1,chapter:        15,versenumber:         5,verse:"于是领他走到外边，说：“你向天观看，数算众星，能数得过来吗？”又对他说：“你的后裔将要如此。”"},
{volumn:         1,chapter:        15,versenumber:         6,verse:"亚伯兰信耶和华，耶和华就以此为他的义。"},
{volumn:         1,chapter:        15,versenumber:         7,verse:"耶和华又对他说：“我是耶和华，曾领你出了迦勒底的吾珥，为要将这地赐你为业。”"},
{volumn:         1,chapter:        15,versenumber:         8,verse:"亚伯兰说：“主耶和华啊，我怎能知道必得这地为业呢？”"},
{volumn:         1,chapter:        15,versenumber:         9,verse:"他说：“你为我取一只三年的母牛，一只三年的母山羊，一只三年的公绵羊，一只斑鸠，一只雏鸽。”"},
{volumn:         1,chapter:        15,versenumber:        10,verse:"亚伯兰就取了这些来，每样劈开，分成两半，一半对着一半地摆列，只有鸟没有劈开。"},
{volumn:         1,chapter:        15,versenumber:        11,verse:"有鸷鸟下来，落在那死畜的肉上，亚伯兰就把它吓飞了。"},
{volumn:         1,chapter:        15,versenumber:        12,verse:"日头正落的时候，亚伯兰沉沉地睡了；忽然有惊人的大黑暗落在他身上。"},
{volumn:         1,chapter:        15,versenumber:        13,verse:"耶和华对亚伯兰说：“你要的确知道，你的后裔必寄居别人的地，又服侍那地的人；那地的人要苦待他们四百年。"},
{volumn:         1,chapter:        15,versenumber:        14,verse:"并且他们所要服侍的那国，我要惩罚，后来他们必带着许多财物从那里出来。"},
{volumn:         1,chapter:        15,versenumber:        15,verse:"但你要享大寿数，平平安安地归到你列祖那里，被人埋葬。"},
{volumn:         1,chapter:        15,versenumber:        16,verse:"到了第四代，他们必回到此地，因为亚摩利人的罪孽还没有满盈。”"},
{volumn:         1,chapter:        15,versenumber:        17,verse:"日落天黑，不料有冒烟的炉并烧着的火把从那些肉块中经过。"},
{volumn:         1,chapter:        15,versenumber:        18,verse:"当那日，耶和华与亚伯兰立约，说：“我已赐给你的后裔，从埃及河直到幼发拉底大河之地，"},
{volumn:         1,chapter:        15,versenumber:        19,verse:"就是基尼人、基尼洗人、甲摩尼人、"},
{volumn:         1,chapter:        15,versenumber:        20,verse:"赫人、比利洗人、利乏音人、"},
{volumn:         1,chapter:        15,versenumber:        21,verse:"亚摩利人、迦南人、革迦撒人、耶布斯人之地。”"},
{volumn:         1,chapter:        16,versenumber:         1,verse:"亚伯兰的妻子撒莱不给他生儿女。撒莱有一个使女，名叫夏甲，是埃及人。"},
{volumn:         1,chapter:        16,versenumber:         2,verse:"撒莱对亚伯兰说：“耶和华使我不能生育。求你和我的使女同房，或者我可以因她得孩子（得孩子：原文是被建立）。”亚伯兰听从了撒莱的话。"},
{volumn:         1,chapter:        16,versenumber:         3,verse:"于是亚伯兰的妻子撒莱将使女埃及人夏甲给了丈夫为妾；那时亚伯兰在迦南已经住了十年。"},
{volumn:         1,chapter:        16,versenumber:         4,verse:"亚伯兰与夏甲同房，夏甲就怀了孕；她见自己有孕，就小看她的主母。"},
{volumn:         1,chapter:        16,versenumber:         5,verse:"撒莱对亚伯兰说：“我因你受屈。我将我的使女放在你怀中，她见自己有了孕，就小看我。愿耶和华在你我中间判断。”"},
{volumn:         1,chapter:        16,versenumber:         6,verse:"亚伯兰对撒莱说：“使女在你手下，你可以随意待她。”撒莱苦待她，她就从撒莱面前逃走了。"},
{volumn:         1,chapter:        16,versenumber:         7,verse:"耶和华的使者在旷野书珥路上的水泉旁遇见她，"},
{volumn:         1,chapter:        16,versenumber:         8,verse:"对她说：“撒莱的使女夏甲，你从哪里来？要往哪里去？”夏甲说：“我从我的主母撒莱面前逃出来。”"},
{volumn:         1,chapter:        16,versenumber:         9,verse:"耶和华的使者对她说：“你回到你主母那里，服在她手下”；"},
{volumn:         1,chapter:        16,versenumber:        10,verse:"又说：“我必使你的后裔极其繁多，甚至不可胜数”；"},
{volumn:         1,chapter:        16,versenumber:        11,verse:"并说：“你如今怀孕要生一个儿子，可以给他起名叫以实玛利（就是　神听见的意思），因为耶和华听见了你的苦情。"},
{volumn:         1,chapter:        16,versenumber:        12,verse:"他为人必像野驴。他的手要攻打人，人的手也要攻打他；他必住在众弟兄的东边。”"},
{volumn:         1,chapter:        16,versenumber:        13,verse:"夏甲就称那对她说话的耶和华为“看顾人的　神”。因而说：“在这里我也看见那看顾我的吗？”"},
{volumn:         1,chapter:        16,versenumber:        14,verse:"所以这井名叫庇耳·拉海·莱。这井正在加低斯和巴列中间。"},
{volumn:         1,chapter:        16,versenumber:        15,verse:"后来夏甲给亚伯兰生了一个儿子；亚伯兰给他起名叫以实玛利。"},
{volumn:         1,chapter:        16,versenumber:        16,verse:"夏甲给亚伯兰生以实玛利的时候，亚伯兰年八十六岁。"},
{volumn:         1,chapter:        17,versenumber:         1,verse:"亚伯兰年九十九岁的时候，耶和华向他显现，对他说：“我是全能的　神。你当在我面前作完全人，"},
{volumn:         1,chapter:        17,versenumber:         2,verse:"我就与你立约，使你的后裔极其繁多。”"},
{volumn:         1,chapter:        17,versenumber:         3,verse:"亚伯兰俯伏在地；　神又对他说："},
{volumn:         1,chapter:        17,versenumber:         4,verse:"“我与你立约：你要作多国的父。"},
{volumn:         1,chapter:        17,versenumber:         5,verse:"从此以后，你的名不再叫亚伯兰，要叫亚伯拉罕，因为我已立你作多国的父。"},
{volumn:         1,chapter:        17,versenumber:         6,verse:"我必使你的后裔极其繁多；国度从你而立，君王从你而出。"},
{volumn:         1,chapter:        17,versenumber:         7,verse:"我要与你并你世世代代的后裔坚立我的约，作永远的约，是要作你和你后裔的　神。"},
{volumn:         1,chapter:        17,versenumber:         8,verse:"我要将你现在寄居的地，就是迦南全地，赐给你和你的后裔永远为业，我也必作他们的　神。”"},
{volumn:         1,chapter:        17,versenumber:         9,verse:"神又对亚伯拉罕说：“你和你的后裔必世世代代遵守我的约。"},
{volumn:         1,chapter:        17,versenumber:        10,verse:"你们所有的男子都要受割礼；这就是我与你并你的后裔所立的约，是你们所当遵守的。"},
{volumn:         1,chapter:        17,versenumber:        11,verse:"你们都要受割礼（原文是割阳皮；十四、二十三、二十四、二十五节同）；这是我与你们立约的证据。"},
{volumn:         1,chapter:        17,versenumber:        12,verse:"你们世世代代的男子，无论是家里生的，是在你后裔之外用银子从外人买的，生下来第八日，都要受割礼。"},
{volumn:         1,chapter:        17,versenumber:        13,verse:"你家里生的和你用银子买的，都必须受割礼。这样，我的约就立在你们肉体上作永远的约。"},
{volumn:         1,chapter:        17,versenumber:        14,verse:"但不受割礼的男子必从民中剪除，因他背了我的约。”"},
{volumn:         1,chapter:        17,versenumber:        15,verse:"神又对亚伯拉罕说：“你的妻子撒莱不可再叫撒莱，她的名要叫撒拉。"},
{volumn:         1,chapter:        17,versenumber:        16,verse:"我必赐福给她，也要使你从她得一个儿子。我要赐福给她，她也要作多国之母；必有百姓的君王从她而出。”"},
{volumn:         1,chapter:        17,versenumber:        17,verse:"亚伯拉罕就俯伏在地喜笑，心里说：“一百岁的人还能得孩子吗？撒拉已经九十岁了，还能生养吗？”"},
{volumn:         1,chapter:        17,versenumber:        18,verse:"亚伯拉罕对　神说：“但愿以实玛利活在你面前。”"},
{volumn:         1,chapter:        17,versenumber:        19,verse:"神说：“不然，你妻子撒拉要给你生一个儿子，你要给他起名叫以撒。我要与他坚定所立的约，作他后裔永远的约。"},
{volumn:         1,chapter:        17,versenumber:        20,verse:"至于以实玛利，我也应允你：我必赐福给他，使他昌盛，极其繁多。他必生十二个族长；我也要使他成为大国。"},
{volumn:         1,chapter:        17,versenumber:        21,verse:"到明年这时节，撒拉必给你生以撒，我要与他坚定所立的约。”"},
{volumn:         1,chapter:        17,versenumber:        22,verse:"神和亚伯拉罕说完了话，就离开他上升去了。"},
{volumn:         1,chapter:        17,versenumber:        23,verse:"正当那日，亚伯拉罕遵着　神的命，给他的儿子以实玛利和家里的一切男子，无论是在家里生的，是用银子买的，都行了割礼。"},
{volumn:         1,chapter:        17,versenumber:        24,verse:"亚伯拉罕受割礼的时候年九十九岁。"},
{volumn:         1,chapter:        17,versenumber:        25,verse:"他儿子以实玛利受割礼的时候年十三岁。"},
{volumn:         1,chapter:        17,versenumber:        26,verse:"正当那日，亚伯拉罕和他儿子以实玛利一同受了割礼。"},
{volumn:         1,chapter:        17,versenumber:        27,verse:"家里所有的人，无论是在家里生的，是用银子从外人买的，也都一同受了割礼。"},
{volumn:         1,chapter:        18,versenumber:         1,verse:"耶和华在幔利橡树那里向亚伯拉罕显现出来。那时正热，亚伯拉罕坐在帐棚门口，"},
{volumn:         1,chapter:        18,versenumber:         2,verse:"举目观看，见有三个人在对面站着。他一见，就从帐棚门口跑去迎接他们，俯伏在地，"},
{volumn:         1,chapter:        18,versenumber:         3,verse:"说：“我主，我若在你眼前蒙恩，求你不要离开仆人往前去。"},
{volumn:         1,chapter:        18,versenumber:         4,verse:"容我拿点水来，你们洗洗脚，在树下歇息歇息。"},
{volumn:         1,chapter:        18,versenumber:         5,verse:"我再拿一点饼来，你们可以加添心力，然后往前去。你们既到仆人这里来，理当如此。”他们说：“就照你说的行吧。”"},
{volumn:         1,chapter:        18,versenumber:         6,verse:"亚伯拉罕急忙进帐棚见撒拉，说：“你速速拿三细亚细面调和做饼。”"},
{volumn:         1,chapter:        18,versenumber:         7,verse:"亚伯拉罕又跑到牛群里，牵了一只又嫩又好的牛犊来，交给仆人，仆人急忙预备好了。"},
{volumn:         1,chapter:        18,versenumber:         8,verse:"亚伯拉罕又取了奶油和奶，并预备好的牛犊来，摆在他们面前，自己在树下站在旁边，他们就吃了。"},
{volumn:         1,chapter:        18,versenumber:         9,verse:"他们问亚伯拉罕说：“你妻子撒拉在哪里？”他说：“在帐棚里。”"},
{volumn:         1,chapter:        18,versenumber:        10,verse:"三人中有一位说：“到明年这时候，我必要回到你这里；你的妻子撒拉必生一个儿子。”撒拉在那人后边的帐棚门口也听见了这话。"},
{volumn:         1,chapter:        18,versenumber:        11,verse:"亚伯拉罕和撒拉年纪老迈，撒拉的月经已断绝了。"},
{volumn:         1,chapter:        18,versenumber:        12,verse:"撒拉心里暗笑，说：“我既已衰败，我主也老迈，岂能有这喜事呢？”"},
{volumn:         1,chapter:        18,versenumber:        13,verse:"耶和华对亚伯拉罕说：“撒拉为什么暗笑，说：‘我既已年老，果真能生养吗？’"},
{volumn:         1,chapter:        18,versenumber:        14,verse:"耶和华岂有难成的事吗？到了日期，明年这时候，我必回到你这里，撒拉必生一个儿子。”"},
{volumn:         1,chapter:        18,versenumber:        15,verse:"撒拉就害怕，不承认，说：“我没有笑。”那位说：“不然，你实在笑了。”"},
{volumn:         1,chapter:        18,versenumber:        16,verse:"三人就从那里起行，向所多玛观看，亚伯拉罕也与他们同行，要送他们一程。"},
{volumn:         1,chapter:        18,versenumber:        17,verse:"耶和华说：“我所要做的事岂可瞒着亚伯拉罕呢？"},
{volumn:         1,chapter:        18,versenumber:        18,verse:"亚伯拉罕必要成为强大的国；地上的万国都必因他得福。"},
{volumn:         1,chapter:        18,versenumber:        19,verse:"我眷顾他，为要叫他吩咐他的众子和他的眷属遵守我的道，秉公行义，使我所应许亚伯拉罕的话都成就了。”"},
{volumn:         1,chapter:        18,versenumber:        20,verse:"耶和华说：“所多玛和蛾摩拉的罪恶甚重，声闻于我。"},
{volumn:         1,chapter:        18,versenumber:        21,verse:"我现在要下去，察看他们所行的，果然尽像那达到我耳中的声音一样吗？若是不然，我也必知道。”"},
{volumn:         1,chapter:        18,versenumber:        22,verse:"二人转身离开那里，向所多玛去；但亚伯拉罕仍旧站在耶和华面前。"},
{volumn:         1,chapter:        18,versenumber:        23,verse:"亚伯拉罕近前来，说：“无论善恶，你都要剿灭吗？"},
{volumn:         1,chapter:        18,versenumber:        24,verse:"假若那城里有五十个义人，你还剿灭那地方吗？不为城里这五十个义人饶恕其中的人吗？"},
{volumn:         1,chapter:        18,versenumber:        25,verse:"将义人与恶人同杀，将义人与恶人一样看待，这断不是你所行的。审判全地的主岂不行公义吗？”"},
{volumn:         1,chapter:        18,versenumber:        26,verse:"耶和华说：“我若在所多玛城里见有五十个义人，我就为他们的缘故饶恕那地方的众人。”"},
{volumn:         1,chapter:        18,versenumber:        27,verse:"亚伯拉罕说：“我虽然是灰尘，还敢对主说话。"},
{volumn:         1,chapter:        18,versenumber:        28,verse:"假若这五十个义人短了五个，你就因为短了五个毁灭全城吗？”他说：“我在那里若见有四十五个，也不毁灭那城。”"},
{volumn:         1,chapter:        18,versenumber:        29,verse:"亚伯拉罕又对他说：“假若在那里见有四十个怎么样呢？”他说：“为这四十个的缘故，我也不做这事。”"},
{volumn:         1,chapter:        18,versenumber:        30,verse:"亚伯拉罕说：“求主不要动怒，容我说，假若在那里见有三十个怎么样呢？”他说：“我在那里若见有三十个，我也不做这事。”"},
{volumn:         1,chapter:        18,versenumber:        31,verse:"亚伯拉罕说：“我还敢对主说话，假若在那里见有二十个怎么样呢？”他说：“为这二十个的缘故，我也不毁灭那城。”"},
{volumn:         1,chapter:        18,versenumber:        32,verse:"亚伯拉罕说：“求主不要动怒，我再说这一次，假若在那里见有十个呢？”他说：“为这十个的缘故，我也不毁灭那城。”"},
{volumn:         1,chapter:        18,versenumber:        33,verse:"耶和华与亚伯拉罕说完了话就走了；亚伯拉罕也回到自己的地方去了。"},
{volumn:         1,chapter:        19,versenumber:         1,verse:"那两个天使晚上到了所多玛；罗得正坐在所多玛城门口，看见他们，就起来迎接，脸伏于地下拜，"},
{volumn:         1,chapter:        19,versenumber:         2,verse:"说：“我主啊，请你们到仆人家里洗洗脚，住一夜，清早起来再走。”他们说：“不！我们要在街上过夜。”"},
{volumn:         1,chapter:        19,versenumber:         3,verse:"罗得切切地请他们，他们这才进去，到他屋里。罗得为他们预备筵席，烤无酵饼，他们就吃了。"},
{volumn:         1,chapter:        19,versenumber:         4,verse:"他们还没有躺下，所多玛城里各处的人，连老带少，都来围住那房子，"},
{volumn:         1,chapter:        19,versenumber:         5,verse:"呼叫罗得说：“今日晚上到你这里来的人在哪里呢？把他们带出来，任我们所为。”"},
{volumn:         1,chapter:        19,versenumber:         6,verse:"罗得出来，把门关上，到众人那里，"},
{volumn:         1,chapter:        19,versenumber:         7,verse:"说：“众弟兄，请你们不要做这恶事。"},
{volumn:         1,chapter:        19,versenumber:         8,verse:"我有两个女儿，还是处女，容我领出来，任凭你们的心愿而行；只是这两个人既然到我舍下，不要向他们做什么。”"},
{volumn:         1,chapter:        19,versenumber:         9,verse:"众人说：“退去吧！”又说：“这个人来寄居，还想要作官哪！现在我们要害你比害他们更甚。”众人就向前拥挤罗得，要攻破房门。"},
{volumn:         1,chapter:        19,versenumber:        10,verse:"只是那二人伸出手来，将罗得拉进屋去，把门关上，"},
{volumn:         1,chapter:        19,versenumber:        11,verse:"并且使门外的人，无论老少，眼都昏迷；他们摸来摸去，总寻不着房门。"},
{volumn:         1,chapter:        19,versenumber:        12,verse:"二人对罗得说：“你这里还有什么人吗？无论是女婿是儿女，和这城中一切属你的人，你都要将他们从这地方带出去。"},
{volumn:         1,chapter:        19,versenumber:        13,verse:"我们要毁灭这地方；因为城内罪恶的声音在耶和华面前甚大，耶和华差我们来，要毁灭这地方。”"},
{volumn:         1,chapter:        19,versenumber:        14,verse:"罗得就出去，告诉娶了（或译：将要娶）他女儿的女婿们说：“你们起来离开这地方，因为耶和华要毁灭这城。”他女婿们却以为他说的是戏言。"},
{volumn:         1,chapter:        19,versenumber:        15,verse:"天明了，天使催逼罗得说：“起来！带着你的妻子和你在这里的两个女儿出去，免得你因这城里的罪恶同被剿灭。”"},
{volumn:         1,chapter:        19,versenumber:        16,verse:"但罗得迟延不走。二人因为耶和华怜恤罗得，就拉着他的手和他妻子的手，并他两个女儿的手，把他们领出来，安置在城外；"},
{volumn:         1,chapter:        19,versenumber:        17,verse:"领他们出来以后，就说：“逃命吧！不可回头看，也不可在平原站住。要往山上逃跑，免得你被剿灭。”"},
{volumn:         1,chapter:        19,versenumber:        18,verse:"罗得对他们说：“我主啊，不要如此！"},
{volumn:         1,chapter:        19,versenumber:        19,verse:"你仆人已经在你眼前蒙恩；你又向我显出莫大的慈爱，救我的性命。我不能逃到山上去，恐怕这灾祸临到我，我便死了。"},
{volumn:         1,chapter:        19,versenumber:        20,verse:"看哪，这座城又小又近，容易逃到，这不是一个小的吗？求你容我逃到那里，我的性命就得存活。”"},
{volumn:         1,chapter:        19,versenumber:        21,verse:"天使对他说：“这事我也应允你；我不倾覆你所说的这城。"},
{volumn:         1,chapter:        19,versenumber:        22,verse:"你要速速地逃到那城；因为你还没有到那里，我不能做什么。”因此那城名叫琐珥（就是小的意思）。"},
{volumn:         1,chapter:        19,versenumber:        23,verse:"罗得到了琐珥，日头已经出来了。"},
{volumn:         1,chapter:        19,versenumber:        24,verse:"当时，耶和华将硫磺与火从天上耶和华那里降与所多玛和蛾摩拉，"},
{volumn:         1,chapter:        19,versenumber:        25,verse:"把那些城和全平原，并城里所有的居民，连地上生长的，都毁灭了。"},
{volumn:         1,chapter:        19,versenumber:        26,verse:"罗得的妻子在后边回头一看，就变成了一根盐柱。"},
{volumn:         1,chapter:        19,versenumber:        27,verse:"亚伯拉罕清早起来，到了他从前站在耶和华面前的地方，"},
{volumn:         1,chapter:        19,versenumber:        28,verse:"向所多玛和蛾摩拉与平原的全地观看，不料，那地方烟气上腾，如同烧窑一般。"},
{volumn:         1,chapter:        19,versenumber:        29,verse:"当　神毁灭平原诸城的时候，他记念亚伯拉罕，正在倾覆罗得所住之城的时候，就打发罗得从倾覆之中出来。"},
{volumn:         1,chapter:        19,versenumber:        30,verse:"罗得因为怕住在琐珥，就同他两个女儿从琐珥上去，住在山里；他和两个女儿住在一个洞里。"},
{volumn:         1,chapter:        19,versenumber:        31,verse:"大女儿对小女儿说：“我们的父亲老了，地上又无人按着世上的常规进到我们这里。"},
{volumn:         1,chapter:        19,versenumber:        32,verse:"来！我们可以叫父亲喝酒，与他同寝。这样，我们好从他存留后裔。”"},
{volumn:         1,chapter:        19,versenumber:        33,verse:"于是，那夜她们叫父亲喝酒，大女儿就进去和她父亲同寝；她几时躺下，几时起来，父亲都不知道。"},
{volumn:         1,chapter:        19,versenumber:        34,verse:"第二天，大女儿对小女儿说：“我昨夜与父亲同寝。今夜我们再叫他喝酒，你可以进去与他同寝。这样，我们好从父亲存留后裔。”"},
{volumn:         1,chapter:        19,versenumber:        35,verse:"于是，那夜她们又叫父亲喝酒，小女儿起来与她父亲同寝；她几时躺下，几时起来，父亲都不知道。"},
{volumn:         1,chapter:        19,versenumber:        36,verse:"这样，罗得的两个女儿都从她父亲怀了孕。"},
{volumn:         1,chapter:        19,versenumber:        37,verse:"大女儿生了儿子，给他起名叫摩押，就是现今摩押人的始祖。"},
{volumn:         1,chapter:        19,versenumber:        38,verse:"小女儿也生了儿子，给他起名叫便亚米，就是现今亚扪人的始祖。"},
{volumn:         1,chapter:        20,versenumber:         1,verse:"亚伯拉罕从那里向南地迁去，寄居在加低斯和书珥中间的基拉耳。"},
{volumn:         1,chapter:        20,versenumber:         2,verse:"亚伯拉罕称他的妻撒拉为妹子，基拉耳王亚比米勒差人把撒拉取了去。"},
{volumn:         1,chapter:        20,versenumber:         3,verse:"但夜间，　神来，在梦中对亚比米勒说：“你是个死人哪！因为你取了那女人来；她原是别人的妻子。”"},
{volumn:         1,chapter:        20,versenumber:         4,verse:"亚比米勒却还没有亲近撒拉；他说：“主啊，连有义的国，你也要毁灭吗？"},
{volumn:         1,chapter:        20,versenumber:         5,verse:"那人岂不是自己对我说‘她是我的妹子’吗？就是女人也自己说：‘他是我的哥哥。’我做这事是心正手洁的。”"},
{volumn:         1,chapter:        20,versenumber:         6,verse:"神在梦中对他说：“我知道你做这事是心中正直；我也拦阻了你，免得你得罪我，所以我不容你沾着她。"},
{volumn:         1,chapter:        20,versenumber:         7,verse:"现在你把这人的妻子归还他；因为他是先知，他要为你祷告，使你存活。你若不归还他，你当知道，你和你所有的人都必要死。”"},
{volumn:         1,chapter:        20,versenumber:         8,verse:"亚比米勒清早起来，召了众臣仆来，将这些事都说给他们听，他们都甚惧怕。"},
{volumn:         1,chapter:        20,versenumber:         9,verse:"亚比米勒召了亚伯拉罕来，对他说：“你怎么向我这样行呢？我在什么事上得罪了你，你竟使我和我国里的人陷在大罪里？你向我行不当行的事了！”"},
{volumn:         1,chapter:        20,versenumber:        10,verse:"亚比米勒又对亚伯拉罕说：“你见了什么才做这事呢？”"},
{volumn:         1,chapter:        20,versenumber:        11,verse:"亚伯拉罕说：“我以为这地方的人总不惧怕　神，必为我妻子的缘故杀我。"},
{volumn:         1,chapter:        20,versenumber:        12,verse:"况且她也实在是我的妹子；她与我是同父异母，后来作了我的妻子。"},
{volumn:         1,chapter:        20,versenumber:        13,verse:"当　神叫我离开父家、飘流在外的时候，我对她说：‘我们无论走到什么地方，你可以对人说：他是我的哥哥；这就是你待我的恩典了。’”"},
{volumn:         1,chapter:        20,versenumber:        14,verse:"亚比米勒把牛、羊、仆婢赐给亚伯拉罕，又把他的妻子撒拉归还他。"},
{volumn:         1,chapter:        20,versenumber:        15,verse:"亚比米勒又说：“看哪，我的地都在你面前，你可以随意居住”；"},
{volumn:         1,chapter:        20,versenumber:        16,verse:"又对撒拉说：“我给你哥哥一千银子，作为你在合家人面前遮羞的（羞：原文是眼），你就在众人面前没有不是了。”"},
{volumn:         1,chapter:        20,versenumber:        17,verse:"亚伯拉罕祷告　神，　神就医好了亚比米勒和他的妻子，并他的众女仆，她们便能生育。"},
{volumn:         1,chapter:        20,versenumber:        18,verse:"因耶和华为亚伯拉罕的妻子撒拉的缘故，已经使亚比米勒家中的妇人不能生育。"},
{volumn:         1,chapter:        21,versenumber:         1,verse:"耶和华按着先前的话眷顾撒拉，便照他所说的给撒拉成就。"},
{volumn:         1,chapter:        21,versenumber:         2,verse:"当亚伯拉罕年老的时候，撒拉怀了孕；到　神所说的日期，就给亚伯拉罕生了一个儿子。"},
{volumn:         1,chapter:        21,versenumber:         3,verse:"亚伯拉罕给撒拉所生的儿子起名叫以撒。"},
{volumn:         1,chapter:        21,versenumber:         4,verse:"以撒生下来第八日，亚伯拉罕照着　神所吩咐的，给以撒行了割礼。"},
{volumn:         1,chapter:        21,versenumber:         5,verse:"他儿子以撒生的时候，亚伯拉罕年一百岁。"},
{volumn:         1,chapter:        21,versenumber:         6,verse:"撒拉说：“　神使我喜笑，凡听见的必与我一同喜笑”；"},
{volumn:         1,chapter:        21,versenumber:         7,verse:"又说：“谁能预先对亚伯拉罕说‘撒拉要乳养婴孩’呢？因为在他年老的时候，我给他生了一个儿子。”"},
{volumn:         1,chapter:        21,versenumber:         8,verse:"孩子渐长，就断了奶。以撒断奶的日子，亚伯拉罕设摆丰盛的筵席。"},
{volumn:         1,chapter:        21,versenumber:         9,verse:"当时，撒拉看见埃及人夏甲给亚伯拉罕所生的儿子戏笑，"},
{volumn:         1,chapter:        21,versenumber:        10,verse:"就对亚伯拉罕说：“你把这使女和她儿子赶出去！因为这使女的儿子不可与我的儿子以撒一同承受产业。”"},
{volumn:         1,chapter:        21,versenumber:        11,verse:"亚伯拉罕因他儿子的缘故很忧愁。"},
{volumn:         1,chapter:        21,versenumber:        12,verse:"神对亚伯拉罕说：“你不必为这童子和你的使女忧愁。凡撒拉对你说的话，你都该听从；因为从以撒生的，才要称为你的后裔。"},
{volumn:         1,chapter:        21,versenumber:        13,verse:"至于使女的儿子，我也必使他的后裔成立一国，因为他是你所生的。”"},
{volumn:         1,chapter:        21,versenumber:        14,verse:"亚伯拉罕清早起来，拿饼和一皮袋水，给了夏甲，搭在她的肩上，又把孩子交给她，打发她走。夏甲就走了，在别是巴的旷野走迷了路。"},
{volumn:         1,chapter:        21,versenumber:        15,verse:"皮袋的水用尽了，夏甲就把孩子撇在小树底下，"},
{volumn:         1,chapter:        21,versenumber:        16,verse:"自己走开约有一箭之远，相对而坐，说：“我不忍见孩子死”，就相对而坐，放声大哭。"},
{volumn:         1,chapter:        21,versenumber:        17,verse:"神听见童子的声音；　神的使者从天上呼叫夏甲说：“夏甲，你为何这样呢？不要害怕，　神已经听见童子的声音了。"},
{volumn:         1,chapter:        21,versenumber:        18,verse:"起来！把童子抱在怀中（怀：原文是手），我必使他的后裔成为大国。”"},
{volumn:         1,chapter:        21,versenumber:        19,verse:"神使夏甲的眼睛明亮，她就看见一口水井，便去将皮袋盛满了水，给童子喝。"},
{volumn:         1,chapter:        21,versenumber:        20,verse:"神保佑童子，他就渐长，住在旷野，成了弓箭手。"},
{volumn:         1,chapter:        21,versenumber:        21,verse:"他住在巴兰的旷野；他母亲从埃及地给他娶了一个妻子。"},
{volumn:         1,chapter:        21,versenumber:        22,verse:"当那时候，亚比米勒同他军长非各对亚伯拉罕说：“凡你所行的事都有　神的保佑。"},
{volumn:         1,chapter:        21,versenumber:        23,verse:"我愿你如今在这里指着　神对我起誓，不要欺负我与我的儿子，并我的子孙。我怎样厚待了你，你也要照样厚待我与你所寄居这地的民。”"},
{volumn:         1,chapter:        21,versenumber:        24,verse:"亚伯拉罕说：“我情愿起誓。”"},
{volumn:         1,chapter:        21,versenumber:        25,verse:"从前，亚比米勒的仆人霸占了一口水井，亚伯拉罕为这事指责亚比米勒。"},
{volumn:         1,chapter:        21,versenumber:        26,verse:"亚比米勒说：“谁做这事，我不知道，你也没有告诉我，今日我才听见了。”"},
{volumn:         1,chapter:        21,versenumber:        27,verse:"亚伯拉罕把羊和牛给了亚比米勒，二人就彼此立约。"},
{volumn:         1,chapter:        21,versenumber:        28,verse:"亚伯拉罕把七只母羊羔另放在一处。"},
{volumn:         1,chapter:        21,versenumber:        29,verse:"亚比米勒问亚伯拉罕说：“你把这七只母羊羔另放在一处，是什么意思呢？”"},
{volumn:         1,chapter:        21,versenumber:        30,verse:"他说：“你要从我手里受这七只母羊羔，作我挖这口井的证据。”"},
{volumn:         1,chapter:        21,versenumber:        31,verse:"所以他给那地方起名叫别是巴（就是盟誓的井的意思），因为他们二人在那里起了誓。"},
{volumn:         1,chapter:        21,versenumber:        32,verse:"他们在别是巴立了约，亚比米勒就同他军长非各起身回非利士地去了。"},
{volumn:         1,chapter:        21,versenumber:        33,verse:"亚伯拉罕在别是巴栽上一棵垂丝柳树，又在那里求告耶和华永生　神的名。"},
{volumn:         1,chapter:        21,versenumber:        34,verse:"亚伯拉罕在非利士人的地寄居了多日。"},
{volumn:         1,chapter:        22,versenumber:         1,verse:"这些事以后，　神要试验亚伯拉罕，就呼叫他说：“亚伯拉罕！”他说：“我在这里。”"},
{volumn:         1,chapter:        22,versenumber:         2,verse:"神说：“你带着你的儿子，就是你独生的儿子，你所爱的以撒，往摩利亚地去，在我所要指示你的山上，把他献为燔祭。”"},
{volumn:         1,chapter:        22,versenumber:         3,verse:"亚伯拉罕清早起来，备上驴，带着两个仆人和他儿子以撒，也劈好了燔祭的柴，就起身往　神所指示他的地方去了。"},
{volumn:         1,chapter:        22,versenumber:         4,verse:"到了第三日，亚伯拉罕举目远远地看见那地方。"},
{volumn:         1,chapter:        22,versenumber:         5,verse:"亚伯拉罕对他的仆人说：“你们和驴在此等候，我与童子往那里去拜一拜，就回到你们这里来。”"},
{volumn:         1,chapter:        22,versenumber:         6,verse:"亚伯拉罕把燔祭的柴放在他儿子以撒身上，自己手里拿着火与刀；于是二人同行。"},
{volumn:         1,chapter:        22,versenumber:         7,verse:"以撒对他父亲亚伯拉罕说：“父亲哪！”亚伯拉罕说：“我儿，我在这里。”以撒说：“请看，火与柴都有了，但燔祭的羊羔在哪里呢？”"},
{volumn:         1,chapter:        22,versenumber:         8,verse:"亚伯拉罕说：“我儿，　神必自己预备作燔祭的羊羔。”于是二人同行。"},
{volumn:         1,chapter:        22,versenumber:         9,verse:"他们到了　神所指示的地方，亚伯拉罕在那里筑坛，把柴摆好，捆绑他的儿子以撒，放在坛的柴上。"},
{volumn:         1,chapter:        22,versenumber:        10,verse:"亚伯拉罕就伸手拿刀，要杀他的儿子。"},
{volumn:         1,chapter:        22,versenumber:        11,verse:"耶和华的使者从天上呼叫他说：“亚伯拉罕！亚伯拉罕！”他说：“我在这里。”"},
{volumn:         1,chapter:        22,versenumber:        12,verse:"天使说：“你不可在这童子身上下手。一点不可害他！现在我知道你是敬畏　神的了；因为你没有将你的儿子，就是你独生的儿子，留下不给我。”"},
{volumn:         1,chapter:        22,versenumber:        13,verse:"亚伯拉罕举目观看，不料，有一只公羊，两角扣在稠密的小树中，亚伯拉罕就取了那只公羊来，献为燔祭，代替他的儿子。"},
{volumn:         1,chapter:        22,versenumber:        14,verse:"亚伯拉罕给那地方起名叫“耶和华以勒”（就是耶和华必预备的意思），直到今日人还说：“在耶和华的山上必有预备。”"},
{volumn:         1,chapter:        22,versenumber:        15,verse:"耶和华的使者第二次从天上呼叫亚伯拉罕说："},
{volumn:         1,chapter:        22,versenumber:        16,verse:"“耶和华说：‘你既行了这事，不留下你的儿子，就是你独生的儿子，我便指着自己起誓说："},
{volumn:         1,chapter:        22,versenumber:        17,verse:"论福，我必赐大福给你；论子孙，我必叫你的子孙多起来，如同天上的星，海边的沙。你子孙必得着仇敌的城门，"},
{volumn:         1,chapter:        22,versenumber:        18,verse:"并且地上万国都必因你的后裔得福，因为你听从了我的话。’”"},
{volumn:         1,chapter:        22,versenumber:        19,verse:"于是亚伯拉罕回到他仆人那里，他们一同起身往别是巴去，亚伯拉罕就住在别是巴。"},
{volumn:         1,chapter:        22,versenumber:        20,verse:"这事以后，有人告诉亚伯拉罕说：“密迦给你兄弟拿鹤生了几个儿子，"},
{volumn:         1,chapter:        22,versenumber:        21,verse:"长子是乌斯，他的兄弟是布斯和亚兰的父亲基母利，"},
{volumn:         1,chapter:        22,versenumber:        22,verse:"并基薛、哈琐、必达、益拉、彼土利（彼土利生利百加）。”"},
{volumn:         1,chapter:        22,versenumber:        23,verse:"这八个人都是密迦给亚伯拉罕的兄弟拿鹤生的。"},
{volumn:         1,chapter:        22,versenumber:        24,verse:"拿鹤的妾名叫流玛，生了提八、迦含、他辖，和玛迦。"},
{volumn:         1,chapter:        23,versenumber:         1,verse:"撒拉享寿一百二十七岁，这是撒拉一生的岁数。"},
{volumn:         1,chapter:        23,versenumber:         2,verse:"撒拉死在迦南地的基列·亚巴，就是希伯仑。亚伯拉罕为她哀恸哭号。"},
{volumn:         1,chapter:        23,versenumber:         3,verse:"后来亚伯拉罕从死人面前起来，对赫人说："},
{volumn:         1,chapter:        23,versenumber:         4,verse:"“我在你们中间是外人，是寄居的。求你们在这里给我一块地，我好埋葬我的死人，使她不在我眼前。”"},
{volumn:         1,chapter:        23,versenumber:         5,verse:"赫人回答亚伯拉罕说："},
{volumn:         1,chapter:        23,versenumber:         6,verse:"“我主请听。你在我们中间是一位尊大的王子，只管在我们最好的坟地里埋葬你的死人；我们没有一人不容你在他的坟地里埋葬你的死人。”"},
{volumn:         1,chapter:        23,versenumber:         7,verse:"亚伯拉罕就起来，向那地的赫人下拜，"},
{volumn:         1,chapter:        23,versenumber:         8,verse:"对他们说：“你们若有意叫我埋葬我的死人，使她不在我眼前，就请听我的话，为我求琐辖的儿子以弗仑；"},
{volumn:         1,chapter:        23,versenumber:         9,verse:"把田头上那麦比拉洞给我；他可以按着足价卖给我，作我在你们中间的坟地。”"},
{volumn:         1,chapter:        23,versenumber:        10,verse:"当时以弗仑正坐在赫人中间。于是，赫人以弗仑在城门出入的赫人面前对亚伯拉罕说："},
{volumn:         1,chapter:        23,versenumber:        11,verse:"“不然，我主请听。我送给你这块田，连田间的洞也送给你，在我同族的人面前都给你，可以埋葬你的死人。”"},
{volumn:         1,chapter:        23,versenumber:        12,verse:"亚伯拉罕就在那地的人民面前下拜，"},
{volumn:         1,chapter:        23,versenumber:        13,verse:"在他们面前对以弗仑说：“你若应允，请听我的话。我要把田价给你，求你收下，我就在那里埋葬我的死人。”"},
{volumn:         1,chapter:        23,versenumber:        14,verse:"以弗仑回答亚伯拉罕说："},
{volumn:         1,chapter:        23,versenumber:        15,verse:"“我主请听。值四百舍客勒银子的一块田，在你我中间还算什么呢？只管埋葬你的死人吧！”"},
{volumn:         1,chapter:        23,versenumber:        16,verse:"亚伯拉罕听从了以弗仑，照着他在赫人面前所说的话，把买卖通用的银子平了四百舍客勒给以弗仑。"},
{volumn:         1,chapter:        23,versenumber:        17,verse:"于是，麦比拉、幔利前、以弗仑的那块田和其中的洞，并田间四围的树木，"},
{volumn:         1,chapter:        23,versenumber:        18,verse:"都定准归与亚伯拉罕，乃是他在赫人面前并城门出入的人面前买妥的。"},
{volumn:         1,chapter:        23,versenumber:        19,verse:"此后，亚伯拉罕把他妻子撒拉埋葬在迦南地幔利前的麦比拉田间的洞里。（幔利就是希伯仑）。"},
{volumn:         1,chapter:        23,versenumber:        20,verse:"从此，那块田和田间的洞就藉着赫人定准归与亚伯拉罕作坟地。"},
{volumn:         1,chapter:        24,versenumber:         1,verse:"亚伯拉罕年纪老迈，向来在一切事上耶和华都赐福给他。"},
{volumn:         1,chapter:        24,versenumber:         2,verse:"亚伯拉罕对管理他全业最老的仆人说：“请你把手放在我大腿底下。"},
{volumn:         1,chapter:        24,versenumber:         3,verse:"我要叫你指着耶和华天地的主起誓，不要为我儿子娶这迦南地中的女子为妻。"},
{volumn:         1,chapter:        24,versenumber:         4,verse:"你要往我本地本族去，为我的儿子以撒娶一个妻子。”"},
{volumn:         1,chapter:        24,versenumber:         5,verse:"仆人对他说：“倘若女子不肯跟我到这地方来，我必须将你的儿子带回你原出之地吗？”"},
{volumn:         1,chapter:        24,versenumber:         6,verse:"亚伯拉罕对他说：“你要谨慎，不要带我的儿子回那里去。"},
{volumn:         1,chapter:        24,versenumber:         7,verse:"耶和华天上的主曾带领我离开父家和本族的地，对我说话，向我起誓说：‘我要将这地赐给你的后裔。’他必差遣使者在你面前，你就可以从那里为我儿子娶一个妻子。"},
{volumn:         1,chapter:        24,versenumber:         8,verse:"倘若女子不肯跟你来，我使你起的誓就与你无干了，只是不可带我的儿子回那里去。”"},
{volumn:         1,chapter:        24,versenumber:         9,verse:"仆人就把手放在他主人亚伯拉罕的大腿底下，为这事向他起誓。"},
{volumn:         1,chapter:        24,versenumber:        10,verse:"那仆人从他主人的骆驼里取了十匹骆驼，并带些他主人各样的财物，起身往美索不达米亚去，到了拿鹤的城。"},
{volumn:         1,chapter:        24,versenumber:        11,verse:"天将晚，众女子出来打水的时候，他便叫骆驼跪在城外的水井那里。"},
{volumn:         1,chapter:        24,versenumber:        12,verse:"他说：“耶和华我主人亚伯拉罕的　神啊，求你施恩给我主人亚伯拉罕，使我今日遇见好机会。"},
{volumn:         1,chapter:        24,versenumber:        13,verse:"我现今站在井旁，城内居民的女子们正出来打水。"},
{volumn:         1,chapter:        24,versenumber:        14,verse:"我向那一个女子说：‘请你拿下水瓶来，给我水喝’，她若说：‘请喝！我也给你的骆驼喝。’愿那女子就作你所预定给你仆人以撒的妻。这样，我便知道你施恩给我主人了。”"},
{volumn:         1,chapter:        24,versenumber:        15,verse:"话还没有说完，不料，利百加肩头上扛着水瓶出来。利百加是彼土利所生的；彼土利是亚伯拉罕兄弟拿鹤妻子密迦的儿子。"},
{volumn:         1,chapter:        24,versenumber:        16,verse:"那女子容貌极其俊美，还是处女，也未曾有人亲近她。她下到井旁，打满了瓶，又上来。"},
{volumn:         1,chapter:        24,versenumber:        17,verse:"仆人跑上前去迎着她，说：“求你将瓶里的水给我一点喝。”"},
{volumn:         1,chapter:        24,versenumber:        18,verse:"女子说：“我主请喝！”就急忙拿下瓶来，托在手上给他喝。"},
{volumn:         1,chapter:        24,versenumber:        19,verse:"女子给他喝了，就说：“我再为你的骆驼打水，叫骆驼也喝足。”"},
{volumn:         1,chapter:        24,versenumber:        20,verse:"她就急忙把瓶里的水倒在槽里，又跑到井旁打水，就为所有的骆驼打上水来。"},
{volumn:         1,chapter:        24,versenumber:        21,verse:"那人定睛看她，一句话也不说，要晓得耶和华赐他通达的道路没有。"},
{volumn:         1,chapter:        24,versenumber:        22,verse:"骆驼喝足了，那人就拿一个金环，重半舍客勒，两个金镯，重十舍客勒，给了那女子，"},
{volumn:         1,chapter:        24,versenumber:        23,verse:"说：“请告诉我，你是谁的女儿？你父亲家里有我们住宿的地方没有？”"},
{volumn:         1,chapter:        24,versenumber:        24,verse:"女子说：“我是密迦与拿鹤之子彼土利的女儿”；"},
{volumn:         1,chapter:        24,versenumber:        25,verse:"又说：“我们家里足有粮草，也有住宿的地方。”"},
{volumn:         1,chapter:        24,versenumber:        26,verse:"那人就低头向耶和华下拜，"},
{volumn:         1,chapter:        24,versenumber:        27,verse:"说：“耶和华我主人亚伯拉罕的　神是应当称颂的，因他不断地以慈爱诚实待我主人。至于我，耶和华在路上引领我，直走到我主人的兄弟家里。”"},
{volumn:         1,chapter:        24,versenumber:        28,verse:"女子跑回去，照着这些话告诉她母亲和她家里的人。"},
{volumn:         1,chapter:        24,versenumber:        29,verse:"利百加有一个哥哥，名叫拉班，看见金环，又看见金镯在他妹子的手上，并听见他妹子利百加的话，说那人对我如此如此说。拉班就跑出来往井旁去，到那人跟前，见他仍站在骆驼旁边的井旁那里，"},
{volumn:         1,chapter:        24,versenumber:        30,verse:""},
{volumn:         1,chapter:        24,versenumber:        31,verse:"便对他说：“你这蒙耶和华赐福的，请进来，为什么站在外边？我已经收拾了房屋，也为骆驼预备了地方。”"},
{volumn:         1,chapter:        24,versenumber:        32,verse:"那人就进了拉班的家。拉班卸了骆驼，用草料喂上，拿水给那人和跟随的人洗脚；"},
{volumn:         1,chapter:        24,versenumber:        33,verse:"把饭摆在他面前，叫他吃，他却说：“我不吃，等我说明白我的事情再吃。”拉班说：“请说。”"},
{volumn:         1,chapter:        24,versenumber:        34,verse:"他说：“我是亚伯拉罕的仆人。"},
{volumn:         1,chapter:        24,versenumber:        35,verse:"耶和华大大地赐福给我主人，使他昌大，又赐给他羊群、牛群、金银、仆婢、骆驼，和驴。"},
{volumn:         1,chapter:        24,versenumber:        36,verse:"我主人的妻子撒拉年老的时候给我主人生了一个儿子；我主人也将一切所有的都给了这个儿子。"},
{volumn:         1,chapter:        24,versenumber:        37,verse:"我主人叫我起誓说：‘你不要为我儿子娶迦南地的女子为妻。"},
{volumn:         1,chapter:        24,versenumber:        38,verse:"你要往我父家、我本族那里去，为我的儿子娶一个妻子。’"},
{volumn:         1,chapter:        24,versenumber:        39,verse:"我对我主人说：‘恐怕女子不肯跟我来。’"},
{volumn:         1,chapter:        24,versenumber:        40,verse:"他就说：‘我所侍奉的耶和华必要差遣他的使者与你同去，叫你的道路通达，你就得以在我父家、我本族那里，给我的儿子娶一个妻子。"},
{volumn:         1,chapter:        24,versenumber:        41,verse:"只要你到了我本族那里，我使你起的誓就与你无干。他们若不把女子交给你，我使你起的誓也与你无干。’"},
{volumn:         1,chapter:        24,versenumber:        42,verse:"“我今日到了井旁，便说：‘耶和华我主人亚伯拉罕的　神啊，愿你叫我所行的道路通达。"},
{volumn:         1,chapter:        24,versenumber:        43,verse:"我如今站在井旁，对哪一个出来打水的女子说：请你把你瓶里的水给我一点喝；"},
{volumn:         1,chapter:        24,versenumber:        44,verse:"她若说：你只管喝，我也为你的骆驼打水；愿那女子就作耶和华给我主人儿子所预定的妻。’"},
{volumn:         1,chapter:        24,versenumber:        45,verse:"我心里的话还没有说完，利百加就出来，肩头上扛着水瓶，下到井旁打水。我便对她说：‘请你给我水喝。’"},
{volumn:         1,chapter:        24,versenumber:        46,verse:"她就急忙从肩头上拿下瓶来，说：‘请喝！我也给你的骆驼喝。’我便喝了；她又给我的骆驼喝了。"},
{volumn:         1,chapter:        24,versenumber:        47,verse:"我问她说：‘你是谁的女儿？’她说：‘我是密迦与拿鹤之子彼土利的女儿。’我就把环子戴在她鼻子上，把镯子戴在她两手上。"},
{volumn:         1,chapter:        24,versenumber:        48,verse:"随后我低头向耶和华下拜，称颂耶和华我主人亚伯拉罕的　神；因为他引导我走合适的道路，使我得着我主人兄弟的孙女，给我主人的儿子为妻。"},
{volumn:         1,chapter:        24,versenumber:        49,verse:"现在你们若愿以慈爱诚实待我主人，就告诉我；若不然，也告诉我，使我可以或向左，或向右。”"},
{volumn:         1,chapter:        24,versenumber:        50,verse:"拉班和彼土利回答说：“这事乃出于耶和华，我们不能向你说好说歹。"},
{volumn:         1,chapter:        24,versenumber:        51,verse:"看哪，利百加在你面前，可以将她带去，照着耶和华所说的，给你主人的儿子为妻。”"},
{volumn:         1,chapter:        24,versenumber:        52,verse:"亚伯拉罕的仆人听见他们这话，就向耶和华俯伏在地。"},
{volumn:         1,chapter:        24,versenumber:        53,verse:"当下仆人拿出金器、银器，和衣服送给利百加，又将宝物送给她哥哥和她母亲。"},
{volumn:         1,chapter:        24,versenumber:        54,verse:"仆人和跟从他的人吃了喝了，住了一夜。早晨起来，仆人就说：“请打发我回我主人那里去吧。”"},
{volumn:         1,chapter:        24,versenumber:        55,verse:"利百加的哥哥和她母亲说：“让女子同我们再住几天，至少十天，然后她可以去。”"},
{volumn:         1,chapter:        24,versenumber:        56,verse:"仆人说：“耶和华既赐给我通达的道路，你们不要耽误我，请打发我走，回我主人那里去吧。”"},
{volumn:         1,chapter:        24,versenumber:        57,verse:"他们说：“我们把女子叫来问问她”，"},
{volumn:         1,chapter:        24,versenumber:        58,verse:"就叫了利百加来，问她说：“你和这人同去吗？”利百加说：“我去。”"},
{volumn:         1,chapter:        24,versenumber:        59,verse:"于是他们打发妹子利百加和她的乳母，同亚伯拉罕的仆人，并跟从仆人的，都走了。"},
{volumn:         1,chapter:        24,versenumber:        60,verse:"他们就给利百加祝福说：我们的妹子啊，愿你作千万人的母！愿你的后裔得着仇敌的城门！"},
{volumn:         1,chapter:        24,versenumber:        61,verse:"利百加和她的使女们起来，骑上骆驼，跟着那仆人，仆人就带着利百加走了。"},
{volumn:         1,chapter:        24,versenumber:        62,verse:"那时，以撒住在南地，刚从庇耳·拉海·莱回来。"},
{volumn:         1,chapter:        24,versenumber:        63,verse:"天将晚，以撒出来在田间默想，举目一看，见来了些骆驼。"},
{volumn:         1,chapter:        24,versenumber:        64,verse:"利百加举目看见以撒，就急忙下了骆驼，"},
{volumn:         1,chapter:        24,versenumber:        65,verse:"问那仆人说：“这田间走来迎接我们的是谁？”仆人说：“是我的主人。”利百加就拿帕子蒙上脸。"},
{volumn:         1,chapter:        24,versenumber:        66,verse:"仆人就将所办的一切事都告诉以撒。"},
{volumn:         1,chapter:        24,versenumber:        67,verse:"以撒便领利百加进了他母亲撒拉的帐棚，娶了她为妻，并且爱她。以撒自从他母亲不在了，这才得了安慰。"},
{volumn:         1,chapter:        25,versenumber:         1,verse:"亚伯拉罕又娶了一妻，名叫基土拉。"},
{volumn:         1,chapter:        25,versenumber:         2,verse:"基土拉给他生了心兰、约珊、米但、米甸、伊施巴，和书亚。"},
{volumn:         1,chapter:        25,versenumber:         3,verse:"约珊生了示巴和底但。底但的子孙是亚书利族、利都是族，和利乌米族。"},
{volumn:         1,chapter:        25,versenumber:         4,verse:"米甸的儿子是以法、以弗、哈诺、亚比大，和以勒大。这都是基土拉的子孙。"},
{volumn:         1,chapter:        25,versenumber:         5,verse:"亚伯拉罕将一切所有的都给了以撒。"},
{volumn:         1,chapter:        25,versenumber:         6,verse:"亚伯拉罕把财物分给他庶出的众子，趁着自己还在世的时候打发他们离开他的儿子以撒，往东方去。"},
{volumn:         1,chapter:        25,versenumber:         7,verse:"亚伯拉罕一生的年日是一百七十五岁。"},
{volumn:         1,chapter:        25,versenumber:         8,verse:"亚伯拉罕寿高年迈，气绝而死，归到他列祖（原文是本民）那里。"},
{volumn:         1,chapter:        25,versenumber:         9,verse:"他两个儿子以撒、以实玛利把他埋葬在麦比拉洞里。这洞在幔利前、赫人琐辖的儿子以弗仑的田中，"},
{volumn:         1,chapter:        25,versenumber:        10,verse:"就是亚伯拉罕向赫人买的那块田。亚伯拉罕和他妻子撒拉都葬在那里。"},
{volumn:         1,chapter:        25,versenumber:        11,verse:"亚伯拉罕死了以后，　神赐福给他的儿子以撒。以撒靠近庇耳·拉海·莱居住。"},
{volumn:         1,chapter:        25,versenumber:        12,verse:"撒拉的使女埃及人夏甲给亚伯拉罕所生的儿子是以实玛利。"},
{volumn:         1,chapter:        25,versenumber:        13,verse:"以实玛利儿子们的名字，按着他们的家谱记在下面。以实玛利的长子是尼拜约，又有基达、亚德别、米比衫、"},
{volumn:         1,chapter:        25,versenumber:        14,verse:"米施玛、度玛、玛撒、"},
{volumn:         1,chapter:        25,versenumber:        15,verse:"哈大、提玛、伊突、拿非施、基底玛。"},
{volumn:         1,chapter:        25,versenumber:        16,verse:"这是以实玛利众子的名字，照着他们的村庄、营寨，作了十二族的族长。"},
{volumn:         1,chapter:        25,versenumber:        17,verse:"以实玛利享寿一百三十七岁，气绝而死，归到他列祖（原文是本民）那里。"},
{volumn:         1,chapter:        25,versenumber:        18,verse:"他子孙的住处在他众弟兄东边，从哈腓拉直到埃及前的书珥，正在亚述的道上。"},
{volumn:         1,chapter:        25,versenumber:        19,verse:"亚伯拉罕的儿子以撒的后代记在下面。亚伯拉罕生以撒。"},
{volumn:         1,chapter:        25,versenumber:        20,verse:"以撒娶利百加为妻的时候正四十岁。利百加是巴旦·亚兰地的亚兰人彼土利的女儿，是亚兰人拉班的妹子。"},
{volumn:         1,chapter:        25,versenumber:        21,verse:"以撒因他妻子不生育，就为她祈求耶和华；耶和华应允他的祈求，他的妻子利百加就怀了孕。"},
{volumn:         1,chapter:        25,versenumber:        22,verse:"孩子们在她腹中彼此相争，她就说：“若是这样，我为什么活着呢（或译：我为什么如此呢）？”她就去求问耶和华。"},
{volumn:         1,chapter:        25,versenumber:        23,verse:"耶和华对她说：“两国在你腹内；两族要从你身上出来。这族必强于那族；将来大的要服侍小的。”"},
{volumn:         1,chapter:        25,versenumber:        24,verse:"生产的日子到了，腹中果然是双子。"},
{volumn:         1,chapter:        25,versenumber:        25,verse:"先产的身体发红，浑身有毛，如同皮衣，他们就给他起名叫以扫（就是有毛的意思）。"},
{volumn:         1,chapter:        25,versenumber:        26,verse:"随后又生了以扫的兄弟，手抓住以扫的脚跟，因此给他起名叫雅各（就是抓住的意思）。利百加生下两个儿子的时候，以撒年正六十岁。"},
{volumn:         1,chapter:        25,versenumber:        27,verse:"两个孩子渐渐长大，以扫善于打猎，常在田野；雅各为人安静，常住在帐棚里。"},
{volumn:         1,chapter:        25,versenumber:        28,verse:"以撒爱以扫，因为常吃他的野味；利百加却爱雅各。"},
{volumn:         1,chapter:        25,versenumber:        29,verse:"有一天，雅各熬汤，以扫从田野回来累昏了。"},
{volumn:         1,chapter:        25,versenumber:        30,verse:"以扫对雅各说：“我累昏了，求你把这红汤给我喝。”因此以扫又叫以东（就是红的意思）。"},
{volumn:         1,chapter:        25,versenumber:        31,verse:"雅各说：“你今日把长子的名分卖给我吧。”"},
{volumn:         1,chapter:        25,versenumber:        32,verse:"以扫说：“我将要死，这长子的名分于我有什么益处呢？”"},
{volumn:         1,chapter:        25,versenumber:        33,verse:"雅各说：“你今日对我起誓吧。”以扫就对他起了誓，把长子的名分卖给雅各。"},
{volumn:         1,chapter:        25,versenumber:        34,verse:"于是雅各将饼和红豆汤给了以扫，以扫吃了喝了，便起来走了。这就是以扫轻看了他长子的名分。"},
{volumn:         1,chapter:        26,versenumber:         1,verse:"在亚伯拉罕的日子，那地有一次饥荒；这时又有饥荒，以撒就往基拉耳去，到非利士人的王亚比米勒那里。"},
{volumn:         1,chapter:        26,versenumber:         2,verse:"耶和华向以撒显现，说：“你不要下埃及去，要住在我所指示你的地。"},
{volumn:         1,chapter:        26,versenumber:         3,verse:"你寄居在这地，我必与你同在，赐福给你，因为我要将这些地都赐给你和你的后裔。我必坚定我向你父亚伯拉罕所起的誓。"},
{volumn:         1,chapter:        26,versenumber:         4,verse:"我要加增你的后裔，像天上的星那样多，又要将这些地都赐给你的后裔。并且地上万国必因你的后裔得福；"},
{volumn:         1,chapter:        26,versenumber:         5,verse:"都因亚伯拉罕听从我的话，遵守我的吩咐和我的命令、律例、法度。”"},
{volumn:         1,chapter:        26,versenumber:         6,verse:"以撒就住在基拉耳。"},
{volumn:         1,chapter:        26,versenumber:         7,verse:"那地方的人问到他的妻子，他便说：“那是我的妹子。”原来他怕说：“是我的妻子”；他心里想：“恐怕这地方的人为利百加的缘故杀我”，因为她容貌俊美。"},
{volumn:         1,chapter:        26,versenumber:         8,verse:"他在那里住了许久。有一天，非利士人的王亚比米勒从窗户里往外观看，见以撒和他的妻子利百加戏玩。"},
{volumn:         1,chapter:        26,versenumber:         9,verse:"亚比米勒召了以撒来，对他说：“她实在是你的妻子，你怎么说她是你的妹子？”以撒说：“我心里想，恐怕我因她而死。”"},
{volumn:         1,chapter:        26,versenumber:        10,verse:"亚比米勒说：“你向我们做的是什么事呢？民中险些有人和你的妻同寝，把我们陷在罪里。”"},
{volumn:         1,chapter:        26,versenumber:        11,verse:"于是亚比米勒晓谕众民说：“凡沾着这个人，或是他妻子的，定要把他治死。”"},
{volumn:         1,chapter:        26,versenumber:        12,verse:"以撒在那地耕种，那一年有百倍的收成。耶和华赐福给他，"},
{volumn:         1,chapter:        26,versenumber:        13,verse:"他就昌大，日增月盛，成了大富户。"},
{volumn:         1,chapter:        26,versenumber:        14,verse:"他有羊群牛群，又有许多仆人，非利士人就嫉妒他。"},
{volumn:         1,chapter:        26,versenumber:        15,verse:"当他父亲亚伯拉罕在世的日子，他父亲的仆人所挖的井，非利士人全都塞住，填满了土。"},
{volumn:         1,chapter:        26,versenumber:        16,verse:"亚比米勒对以撒说：“你离开我们去吧。因为你比我们强盛得多。”"},
{volumn:         1,chapter:        26,versenumber:        17,verse:"以撒就离开那里，在基拉耳谷支搭帐棚，住在那里。"},
{volumn:         1,chapter:        26,versenumber:        18,verse:"当他父亲亚伯拉罕在世之日所挖的水井因非利士人在亚伯拉罕死后塞住了，以撒就重新挖出来，仍照他父亲所叫的叫那些井的名字。"},
{volumn:         1,chapter:        26,versenumber:        19,verse:"以撒的仆人在谷中挖井，便得了一口活水井。"},
{volumn:         1,chapter:        26,versenumber:        20,verse:"基拉耳的牧人与以撒的牧人争竞，说：“这水是我们的。”以撒就给那井起名叫埃色（就是相争的意思），因为他们和他相争。"},
{volumn:         1,chapter:        26,versenumber:        21,verse:"以撒的仆人又挖了一口井，他们又为这井争竞，因此以撒给这井起名叫西提拿（就是为敌的意思）。"},
{volumn:         1,chapter:        26,versenumber:        22,verse:"以撒离开那里，又挖了一口井，他们不为这井争竞了，他就给那井起名叫利河伯（就是宽阔的意思）。他说：“耶和华现在给我们宽阔之地，我们必在这地昌盛。”"},
{volumn:         1,chapter:        26,versenumber:        23,verse:"以撒从那里上别是巴去。"},
{volumn:         1,chapter:        26,versenumber:        24,verse:"当夜耶和华向他显现，说：“我是你父亲亚伯拉罕的　神，不要惧怕！因为我与你同在，要赐福给你，并要为我仆人亚伯拉罕的缘故，使你的后裔繁多。”"},
{volumn:         1,chapter:        26,versenumber:        25,verse:"以撒就在那里筑了一座坛，求告耶和华的名，并且支搭帐棚；他的仆人便在那里挖了一口井。"},
{volumn:         1,chapter:        26,versenumber:        26,verse:"亚比米勒，同他的朋友亚户撒和他的军长非各，从基拉耳来见以撒。"},
{volumn:         1,chapter:        26,versenumber:        27,verse:"以撒对他们说：“你们既然恨我，打发我走了，为什么到我这里来呢？”"},
{volumn:         1,chapter:        26,versenumber:        28,verse:"他们说：“我们明明地看见耶和华与你同在，便说，不如我们两下彼此起誓，彼此立约，"},
{volumn:         1,chapter:        26,versenumber:        29,verse:"使你不害我们，正如我们未曾害你，一味地厚待你，并且打发你平平安安地走。你是蒙耶和华赐福的了。”"},
{volumn:         1,chapter:        26,versenumber:        30,verse:"以撒就为他们设摆筵席，他们便吃了喝了。"},
{volumn:         1,chapter:        26,versenumber:        31,verse:"他们清早起来彼此起誓。以撒打发他们走，他们就平平安安地离开他走了。"},
{volumn:         1,chapter:        26,versenumber:        32,verse:"那一天，以撒的仆人来，将挖井的事告诉他说：“我们得了水了。”"},
{volumn:         1,chapter:        26,versenumber:        33,verse:"他就给那井起名叫示巴；因此那城叫做别是巴，直到今日。"},
{volumn:         1,chapter:        26,versenumber:        34,verse:"以扫四十岁的时候娶了赫人比利的女儿犹滴，与赫人以伦的女儿巴实抹为妻。"},
{volumn:         1,chapter:        26,versenumber:        35,verse:"她们常使以撒和利百加心里愁烦。"},
{volumn:         1,chapter:        27,versenumber:         1,verse:"以撒年老，眼睛昏花，不能看见，就叫了他大儿子以扫来，说：“我儿。”以扫说：“我在这里。”"},
{volumn:         1,chapter:        27,versenumber:         2,verse:"他说：“我如今老了，不知道哪一天死。"},
{volumn:         1,chapter:        27,versenumber:         3,verse:"现在拿你的器械，就是箭囊和弓，往田野去为我打猎，"},
{volumn:         1,chapter:        27,versenumber:         4,verse:"照我所爱的做成美味，拿来给我吃，使我在未死之先给你祝福。”"},
{volumn:         1,chapter:        27,versenumber:         5,verse:"以撒对他儿子以扫说话，利百加也听见了。以扫往田野去打猎，要得野味带来。"},
{volumn:         1,chapter:        27,versenumber:         6,verse:"利百加就对她儿子雅各说：“我听见你父亲对你哥哥以扫说："},
{volumn:         1,chapter:        27,versenumber:         7,verse:"‘你去把野兽带来，做成美味给我吃，我好在未死之先，在耶和华面前给你祝福。’"},
{volumn:         1,chapter:        27,versenumber:         8,verse:"现在，我儿，你要照着我所吩咐你的，听从我的话。"},
{volumn:         1,chapter:        27,versenumber:         9,verse:"你到羊群里去，给我拿两只肥山羊羔来，我便照你父亲所爱的给他做成美味。"},
{volumn:         1,chapter:        27,versenumber:        10,verse:"你拿到你父亲那里给他吃，使他在未死之先给你祝福。”"},
{volumn:         1,chapter:        27,versenumber:        11,verse:"雅各对他母亲利百加说：“我哥哥以扫浑身是有毛的，我身上是光滑的；"},
{volumn:         1,chapter:        27,versenumber:        12,verse:"倘若我父亲摸着我，必以我为欺哄人的，我就招咒诅，不得祝福。”"},
{volumn:         1,chapter:        27,versenumber:        13,verse:"他母亲对他说：“我儿，你招的咒诅归到我身上；你只管听我的话，去把羊羔给我拿来。”"},
{volumn:         1,chapter:        27,versenumber:        14,verse:"他便去拿来，交给他母亲；他母亲就照他父亲所爱的做成美味。"},
{volumn:         1,chapter:        27,versenumber:        15,verse:"利百加又把家里所存大儿子以扫上好的衣服给她小儿子雅各穿上，"},
{volumn:         1,chapter:        27,versenumber:        16,verse:"又用山羊羔皮包在雅各的手上和颈项的光滑处，"},
{volumn:         1,chapter:        27,versenumber:        17,verse:"就把所做的美味和饼交在她儿子雅各的手里。"},
{volumn:         1,chapter:        27,versenumber:        18,verse:"雅各到他父亲那里说：“我父亲！”他说：“我在这里。我儿，你是谁？”"},
{volumn:         1,chapter:        27,versenumber:        19,verse:"雅各对他父亲说：“我是你的长子以扫；我已照你所吩咐我的行了。请起来坐着，吃我的野味，好给我祝福。”"},
{volumn:         1,chapter:        27,versenumber:        20,verse:"以撒对他儿子说：“我儿，你如何找得这么快呢？”他说：“因为耶和华你的　神使我遇见好机会得着的。”"},
{volumn:         1,chapter:        27,versenumber:        21,verse:"以撒对雅各说：“我儿，你近前来，我摸摸你，知道你真是我的儿子以扫不是。”"},
{volumn:         1,chapter:        27,versenumber:        22,verse:"雅各就挨近他父亲以撒。以撒摸着他，说：“声音是雅各的声音，手却是以扫的手。”"},
{volumn:         1,chapter:        27,versenumber:        23,verse:"以撒就辨不出他来；因为他手上有毛，像他哥哥以扫的手一样，就给他祝福；"},
{volumn:         1,chapter:        27,versenumber:        24,verse:"又说：“你真是我儿子以扫吗？”他说：“我是。”"},
{volumn:         1,chapter:        27,versenumber:        25,verse:"以撒说：“你递给我，我好吃我儿子的野味，给你祝福。”雅各就递给他，他便吃了，又拿酒给他，他也喝了。"},
{volumn:         1,chapter:        27,versenumber:        26,verse:"他父亲以撒对他说：“我儿，你上前来与我亲嘴。”"},
{volumn:         1,chapter:        27,versenumber:        27,verse:"他就上前与父亲亲嘴。他父亲一闻他衣服上的香气，就给他祝福，说：“我儿的香气如同耶和华赐福之田地的香气一样。"},
{volumn:         1,chapter:        27,versenumber:        28,verse:"愿　神赐你天上的甘露，地上的肥土，并许多五谷新酒。"},
{volumn:         1,chapter:        27,versenumber:        29,verse:"愿多民侍奉你，多国跪拜你。愿你作你弟兄的主；你母亲的儿子向你跪拜。凡咒诅你的，愿他受咒诅；为你祝福的，愿他蒙福。”"},
{volumn:         1,chapter:        27,versenumber:        30,verse:"以撒为雅各祝福已毕，雅各从他父亲那里才出来，他哥哥以扫正打猎回来，"},
{volumn:         1,chapter:        27,versenumber:        31,verse:"也做了美味，拿来给他父亲，说：“请父亲起来，吃你儿子的野味，好给我祝福。”"},
{volumn:         1,chapter:        27,versenumber:        32,verse:"他父亲以撒对他说：“你是谁？”他说：“我是你的长子以扫。”"},
{volumn:         1,chapter:        27,versenumber:        33,verse:"以撒就大大地战兢，说：“你未来之先，是谁得了野味拿来给我呢？我已经吃了，为他祝福；他将来也必蒙福。”"},
{volumn:         1,chapter:        27,versenumber:        34,verse:"以扫听了他父亲的话，就放声痛哭，说：“我父啊，求你也为我祝福！”"},
{volumn:         1,chapter:        27,versenumber:        35,verse:"以撒说：“你兄弟已经用诡计来将你的福分夺去了。”"},
{volumn:         1,chapter:        27,versenumber:        36,verse:"以扫说：“他名雅各，岂不是正对吗？因为他欺骗了我两次：他从前夺了我长子的名分，你看，他现在又夺了我的福分。”以扫又说：“你没有留下为我可祝的福吗？”"},
{volumn:         1,chapter:        27,versenumber:        37,verse:"以撒回答以扫说：“我已立他为你的主，使他的弟兄都给他作仆人，并赐他五谷新酒可以养生。我儿，现在我还能为你做什么呢？”"},
{volumn:         1,chapter:        27,versenumber:        38,verse:"以扫对他父亲说：“父啊，你只有一样可祝的福吗？我父啊，求你也为我祝福！”以扫就放声而哭。"},
{volumn:         1,chapter:        27,versenumber:        39,verse:"他父亲以撒说：“地上的肥土必为你所住；天上的甘露必为你所得。"},
{volumn:         1,chapter:        27,versenumber:        40,verse:"你必倚靠刀剑度日，又必侍奉你的兄弟；到你强盛的时候，必从你颈项上挣开他的轭。”"},
{volumn:         1,chapter:        27,versenumber:        41,verse:"以扫因他父亲给雅各祝的福，就怨恨雅各，心里说：“为我父亲居丧的日子近了，到那时候，我要杀我的兄弟雅各。”"},
{volumn:         1,chapter:        27,versenumber:        42,verse:"有人把利百加大儿子以扫的话告诉利百加，她就打发人去，叫了她小儿子雅各来，对他说：“你哥哥以扫想要杀你，报仇雪恨。"},
{volumn:         1,chapter:        27,versenumber:        43,verse:"现在，我儿，你要听我的话：起来，逃往哈兰、我哥哥拉班那里去，"},
{volumn:         1,chapter:        27,versenumber:        44,verse:"同他住些日子，直等你哥哥的怒气消了。"},
{volumn:         1,chapter:        27,versenumber:        45,verse:"你哥哥向你消了怒气，忘了你向他所做的事，我便打发人去把你从那里带回来。为什么一日丧你们二人呢？”"},
{volumn:         1,chapter:        27,versenumber:        46,verse:"利百加对以撒说：“我因这赫人的女子连性命都厌烦了；倘若雅各也娶赫人的女子为妻，像这些一样，我活着还有什么益处呢？”"},
{volumn:         1,chapter:        28,versenumber:         1,verse:"以撒叫了雅各来，给他祝福，并嘱咐他说：“你不要娶迦南的女子为妻。"},
{volumn:         1,chapter:        28,versenumber:         2,verse:"你起身往巴旦·亚兰去，到你外祖彼土利家里，在你母舅拉班的女儿中娶一女为妻。"},
{volumn:         1,chapter:        28,versenumber:         3,verse:"愿全能的　神赐福给你，使你生养众多，成为多族，"},
{volumn:         1,chapter:        28,versenumber:         4,verse:"将应许亚伯拉罕的福赐给你和你的后裔，使你承受你所寄居的地为业，就是　神赐给亚伯拉罕的地。”"},
{volumn:         1,chapter:        28,versenumber:         5,verse:"以撒打发雅各走了，他就往巴旦·亚兰去，到亚兰人彼土利的儿子拉班那里。拉班是雅各、以扫的母舅。"},
{volumn:         1,chapter:        28,versenumber:         6,verse:"以扫见以撒已经给雅各祝福，而且打发他往巴旦·亚兰去，在那里娶妻，并见祝福的时候嘱咐他说：“不要娶迦南的女子为妻”，"},
{volumn:         1,chapter:        28,versenumber:         7,verse:"又见雅各听从父母的话往巴旦·亚兰去了，"},
{volumn:         1,chapter:        28,versenumber:         8,verse:"以扫就晓得他父亲以撒看不中迦南的女子，"},
{volumn:         1,chapter:        28,versenumber:         9,verse:"便往以实玛利那里去，在他二妻之外又娶了玛哈拉为妻。她是亚伯拉罕儿子以实玛利的女儿，尼拜约的妹子。"},
{volumn:         1,chapter:        28,versenumber:        10,verse:"雅各出了别是巴，向哈兰走去；"},
{volumn:         1,chapter:        28,versenumber:        11,verse:"到了一个地方，因为太阳落了，就在那里住宿，便拾起那地方的一块石头枕在头下，在那里躺卧睡了，"},
{volumn:         1,chapter:        28,versenumber:        12,verse:"梦见一个梯子立在地上，梯子的头顶着天，有　神的使者在梯子上，上去下来。"},
{volumn:         1,chapter:        28,versenumber:        13,verse:"耶和华站在梯子以上（或译：站在他旁边），说：“我是耶和华你祖亚伯拉罕的　神，也是以撒的　神；我要将你现在所躺卧之地赐给你和你的后裔。"},
{volumn:         1,chapter:        28,versenumber:        14,verse:"你的后裔必像地上的尘沙那样多，必向东西南北开展；地上万族必因你和你的后裔得福。"},
{volumn:         1,chapter:        28,versenumber:        15,verse:"我也与你同在。你无论往哪里去，我必保佑你，领你归回这地，总不离弃你，直到我成全了向你所应许的。”"},
{volumn:         1,chapter:        28,versenumber:        16,verse:"雅各睡醒了，说：“耶和华真在这里，我竟不知道！”"},
{volumn:         1,chapter:        28,versenumber:        17,verse:"就惧怕，说：“这地方何等可畏！这不是别的，乃是　神的殿，也是天的门。”"},
{volumn:         1,chapter:        28,versenumber:        18,verse:"雅各清早起来，把所枕的石头立作柱子，浇油在上面。"},
{volumn:         1,chapter:        28,versenumber:        19,verse:"他就给那地方起名叫伯特利（就是　神殿的意思）；但那地方起先名叫路斯。"},
{volumn:         1,chapter:        28,versenumber:        20,verse:"雅各许愿说：“　神若与我同在，在我所行的路上保佑我，又给我食物吃，衣服穿，"},
{volumn:         1,chapter:        28,versenumber:        21,verse:"使我平平安安地回到我父亲的家，我就必以耶和华为我的　神。"},
{volumn:         1,chapter:        28,versenumber:        22,verse:"我所立为柱子的石头也必作　神的殿；凡你所赐给我的，我必将十分之一献给你。”"},
{volumn:         1,chapter:        29,versenumber:         1,verse:"雅各起行，到了东方人之地，"},
{volumn:         1,chapter:        29,versenumber:         2,verse:"看见田间有一口井，有三群羊卧在井旁；因为人饮羊群都是用那井里的水。井口上的石头是大的。"},
{volumn:         1,chapter:        29,versenumber:         3,verse:"常有羊群在那里聚集，牧人把石头转离井口饮羊，随后又把石头放在井口的原处。"},
{volumn:         1,chapter:        29,versenumber:         4,verse:"雅各对牧人说：“弟兄们，你们是哪里来的？”他们说：“我们是哈兰来的。”"},
{volumn:         1,chapter:        29,versenumber:         5,verse:"他问他们说：“拿鹤的孙子拉班，你们认识吗？”他们说：“我们认识。”"},
{volumn:         1,chapter:        29,versenumber:         6,verse:"雅各说：“他平安吗？”他们说：“平安。看哪，他女儿拉结领着羊来了。”"},
{volumn:         1,chapter:        29,versenumber:         7,verse:"雅各说：“日头还高，不是羊群聚集的时候，你们不如饮羊，再去放一放。”"},
{volumn:         1,chapter:        29,versenumber:         8,verse:"他们说：“我们不能，必等羊群聚齐，人把石头转离井口才可饮羊。”"},
{volumn:         1,chapter:        29,versenumber:         9,verse:"雅各正和他们说话的时候，拉结领着她父亲的羊来了，因为那些羊是她牧放的。"},
{volumn:         1,chapter:        29,versenumber:        10,verse:"雅各看见母舅拉班的女儿拉结和母舅拉班的羊群，就上前把石头转离井口，饮他母舅拉班的羊群。"},
{volumn:         1,chapter:        29,versenumber:        11,verse:"雅各与拉结亲嘴，就放声而哭。"},
{volumn:         1,chapter:        29,versenumber:        12,verse:"雅各告诉拉结，自己是她父亲的外甥，是利百加的儿子，拉结就跑去告诉她父亲。"},
{volumn:         1,chapter:        29,versenumber:        13,verse:"拉班听见外甥雅各的信息，就跑去迎接，抱着他，与他亲嘴，领他到自己的家。雅各将一切的情由告诉拉班。"},
{volumn:         1,chapter:        29,versenumber:        14,verse:"拉班对他说：“你实在是我的骨肉。”雅各就和他同住了一个月。"},
{volumn:         1,chapter:        29,versenumber:        15,verse:"拉班对雅各说：“你虽是我的骨肉（原文是弟兄），岂可白白地服侍我？请告诉我，你要什么为工价？”"},
{volumn:         1,chapter:        29,versenumber:        16,verse:"拉班有两个女儿，大的名叫利亚，小的名叫拉结。"},
{volumn:         1,chapter:        29,versenumber:        17,verse:"利亚的眼睛没有神气，拉结却生得美貌俊秀。"},
{volumn:         1,chapter:        29,versenumber:        18,verse:"雅各爱拉结，就说：“我愿为你小女儿拉结服侍你七年。”"},
{volumn:         1,chapter:        29,versenumber:        19,verse:"拉班说：“我把她给你，胜似给别人，你与我同住吧！”"},
{volumn:         1,chapter:        29,versenumber:        20,verse:"雅各就为拉结服侍了七年；他因为深爱拉结，就看这七年如同几天。"},
{volumn:         1,chapter:        29,versenumber:        21,verse:"雅各对拉班说：“日期已经满了，求你把我的妻子给我，我好与她同房。”"},
{volumn:         1,chapter:        29,versenumber:        22,verse:"拉班就摆设筵席，请齐了那地方的众人。"},
{volumn:         1,chapter:        29,versenumber:        23,verse:"到晚上，拉班将女儿利亚送来给雅各，雅各就与她同房。"},
{volumn:         1,chapter:        29,versenumber:        24,verse:"拉班又将婢女悉帕给女儿利亚作使女。"},
{volumn:         1,chapter:        29,versenumber:        25,verse:"到了早晨，雅各一看是利亚，就对拉班说：“你向我做的是什么事呢？我服侍你，不是为拉结吗？你为什么欺哄我呢？”"},
{volumn:         1,chapter:        29,versenumber:        26,verse:"拉班说：“大女儿还没有给人，先把小女儿给人，在我们这地方没有这规矩。"},
{volumn:         1,chapter:        29,versenumber:        27,verse:"你为这个满了七日，我就把那个也给你，你再为她服侍我七年。”"},
{volumn:         1,chapter:        29,versenumber:        28,verse:"雅各就如此行。满了利亚的七日，拉班便将女儿拉结给雅各为妻。"},
{volumn:         1,chapter:        29,versenumber:        29,verse:"拉班又将婢女辟拉给女儿拉结作使女。"},
{volumn:         1,chapter:        29,versenumber:        30,verse:"雅各也与拉结同房，并且爱拉结胜似爱利亚，于是又服侍了拉班七年。"},
{volumn:         1,chapter:        29,versenumber:        31,verse:"耶和华见利亚失宠（原文是被恨；下同），就使她生育，拉结却不生育。"},
{volumn:         1,chapter:        29,versenumber:        32,verse:"利亚怀孕生子，就给他起名叫流便（就是有儿子的意思），因而说：“耶和华看见我的苦情，如今我的丈夫必爱我。”"},
{volumn:         1,chapter:        29,versenumber:        33,verse:"她又怀孕生子，就说：“耶和华因为听见我失宠，所以又赐给我这个儿子”，于是给他起名叫西缅（就是听见的意思）。"},
{volumn:         1,chapter:        29,versenumber:        34,verse:"她又怀孕生子，起名叫利未（就是联合的意思），说：“我给丈夫生了三个儿子，他必与我联合。”"},
{volumn:         1,chapter:        29,versenumber:        35,verse:"她又怀孕生子，说：“这回我要赞美耶和华”，因此给他起名叫犹大（就是赞美的意思）。这才停了生育。"},
{volumn:         1,chapter:        30,versenumber:         1,verse:"拉结见自己不给雅各生子，就嫉妒她姐姐，对雅各说：“你给我孩子，不然我就死了。”"},
{volumn:         1,chapter:        30,versenumber:         2,verse:"雅各向拉结生气，说：“叫你不生育的是　神，我岂能代替他作主呢？”"},
{volumn:         1,chapter:        30,versenumber:         3,verse:"拉结说：“有我的使女辟拉在这里，你可以与她同房，使她生子在我膝下，我便因她也得孩子（原文是被建立）。”"},
{volumn:         1,chapter:        30,versenumber:         4,verse:"拉结就把她的使女辟拉给丈夫为妾；雅各便与她同房，"},
{volumn:         1,chapter:        30,versenumber:         5,verse:"辟拉就怀孕，给雅各生了一个儿子。"},
{volumn:         1,chapter:        30,versenumber:         6,verse:"拉结说：“　神伸了我的冤，也听了我的声音，赐我一个儿子”，因此给他起名叫但（就是伸冤的意思）。"},
{volumn:         1,chapter:        30,versenumber:         7,verse:"拉结的使女辟拉又怀孕，给雅各生了第二个儿子。"},
{volumn:         1,chapter:        30,versenumber:         8,verse:"拉结说：“我与我姐姐大大相争，并且得胜”，于是给他起名叫拿弗他利（就是相争的意思）。"},
{volumn:         1,chapter:        30,versenumber:         9,verse:"利亚见自己停了生育，就把使女悉帕给雅各为妾。"},
{volumn:         1,chapter:        30,versenumber:        10,verse:"利亚的使女悉帕给雅各生了一个儿子。"},
{volumn:         1,chapter:        30,versenumber:        11,verse:"利亚说：“万幸！”于是给他起名叫迦得（就是万幸的意思）。"},
{volumn:         1,chapter:        30,versenumber:        12,verse:"利亚的使女悉帕又给雅各生了第二个儿子。"},
{volumn:         1,chapter:        30,versenumber:        13,verse:"利亚说：“我有福啊，众女子都要称我是有福的”，于是给他起名叫亚设（就是有福的意思）。"},
{volumn:         1,chapter:        30,versenumber:        14,verse:"割麦子的时候，流便往田里去，寻见风茄，拿来给他母亲利亚。拉结对利亚说：“请你把你儿子的风茄给我些。”"},
{volumn:         1,chapter:        30,versenumber:        15,verse:"利亚说：“你夺了我的丈夫还算小事吗？你又要夺我儿子的风茄吗？”拉结说：“为你儿子的风茄，今夜他可以与你同寝。”"},
{volumn:         1,chapter:        30,versenumber:        16,verse:"到了晚上，雅各从田里回来，利亚出来迎接他，说：“你要与我同寝，因为我实在用我儿子的风茄把你雇下了。”那一夜，雅各就与她同寝。"},
{volumn:         1,chapter:        30,versenumber:        17,verse:"神应允了利亚，她就怀孕，给雅各生了第五个儿子。"},
{volumn:         1,chapter:        30,versenumber:        18,verse:"利亚说：“　神给了我价值，因为我把使女给了我丈夫”，于是给他起名叫以萨迦（就是价值的意思）。"},
{volumn:         1,chapter:        30,versenumber:        19,verse:"利亚又怀孕，给雅各生了第六个儿子。"},
{volumn:         1,chapter:        30,versenumber:        20,verse:"利亚说：“　神赐我厚赏；我丈夫必与我同住，因我给他生了六个儿子”，于是给他起名西布伦（就是同住的意思）。"},
{volumn:         1,chapter:        30,versenumber:        21,verse:"后来又生了一个女儿，给她起名叫底拿。"},
{volumn:         1,chapter:        30,versenumber:        22,verse:"神顾念拉结，应允了她，使她能生育。"},
{volumn:         1,chapter:        30,versenumber:        23,verse:"拉结怀孕生子，说：“　神除去了我的羞耻”，"},
{volumn:         1,chapter:        30,versenumber:        24,verse:"就给他起名叫约瑟（就是增添的意思），意思说：“愿耶和华再增添我一个儿子。”"},
{volumn:         1,chapter:        30,versenumber:        25,verse:"拉结生约瑟之后，雅各对拉班说：“请打发我走，叫我回到我本乡本土去。"},
{volumn:         1,chapter:        30,versenumber:        26,verse:"请你把我服侍你所得的妻子和儿女给我，让我走；我怎样服侍你，你都知道。”"},
{volumn:         1,chapter:        30,versenumber:        27,verse:"拉班对他说：“我若在你眼前蒙恩，请你仍与我同住，因为我已算定，耶和华赐福与我是为你的缘故”；"},
{volumn:         1,chapter:        30,versenumber:        28,verse:"又说：“请你定你的工价，我就给你。”"},
{volumn:         1,chapter:        30,versenumber:        29,verse:"雅各对他说：“我怎样服侍你，你的牲畜在我手里怎样，是你知道的。"},
{volumn:         1,chapter:        30,versenumber:        30,verse:"我未来之先，你所有的很少，现今却发大众多，耶和华随我的脚步赐福与你。如今，我什么时候才为自己兴家立业呢？”"},
{volumn:         1,chapter:        30,versenumber:        31,verse:"拉班说：“我当给你什么呢？”雅各说：“什么你也不必给我，只有一件事，你若应承，我便仍旧牧放你的羊群。"},
{volumn:         1,chapter:        30,versenumber:        32,verse:"今天我要走遍你的羊群，把绵羊中凡有点的、有斑的，和黑色的，并山羊中凡有斑的、有点的，都挑出来；将来这一等的就算我的工价。"},
{volumn:         1,chapter:        30,versenumber:        33,verse:"以后你来查看我的工价，凡在我手里的山羊不是有点有斑的，绵羊不是黑色的，那就算是我偷的；这样便可证出我的公义来。”"},
{volumn:         1,chapter:        30,versenumber:        34,verse:"拉班说：“好啊！我情愿照着你的话行。”"},
{volumn:         1,chapter:        30,versenumber:        35,verse:"当日，拉班把有纹的、有斑的公山羊，有点的、有斑的、有杂白纹的母山羊，并黑色的绵羊，都挑出来，交在他儿子们的手下，"},
{volumn:         1,chapter:        30,versenumber:        36,verse:"又使自己和雅各相离三天的路程。雅各就牧养拉班其余的羊。"},
{volumn:         1,chapter:        30,versenumber:        37,verse:"雅各拿杨树、杏树、枫树的嫩枝，将皮剥成白纹，使枝子露出白的来，"},
{volumn:         1,chapter:        30,versenumber:        38,verse:"将剥了皮的枝子，对着羊群，插在饮羊的水沟里和水槽里，羊来喝的时候，牝牡配合。"},
{volumn:         1,chapter:        30,versenumber:        39,verse:"羊对着枝子配合，就生下有纹的、有点的、有斑的来。"},
{volumn:         1,chapter:        30,versenumber:        40,verse:"雅各把羊羔分出来，使拉班的羊与这有纹和黑色的羊相对，把自己的羊另放一处，不叫他和拉班的羊混杂。"},
{volumn:         1,chapter:        30,versenumber:        41,verse:"到羊群肥壮配合的时候，雅各就把枝子插在水沟里，使羊对着枝子配合。"},
{volumn:         1,chapter:        30,versenumber:        42,verse:"只是到羊瘦弱配合的时候就不插枝子。这样，瘦弱的就归拉班，肥壮的就归雅各。"},
{volumn:         1,chapter:        30,versenumber:        43,verse:"于是雅各极其发大，得了许多的羊群、仆婢、骆驼，和驴。"},
{volumn:         1,chapter:        31,versenumber:         1,verse:"雅各听见拉班的儿子们有话说：“雅各把我们父亲所有的都夺了去，并藉着我们父亲的，得了这一切的荣耀（或译：财）。”"},
{volumn:         1,chapter:        31,versenumber:         2,verse:"雅各见拉班的气色向他不如从前了。"},
{volumn:         1,chapter:        31,versenumber:         3,verse:"耶和华对雅各说：“你要回你祖、你父之地，到你亲族那里去，我必与你同在。”"},
{volumn:         1,chapter:        31,versenumber:         4,verse:"雅各就打发人，叫拉结和利亚到田野羊群那里来，"},
{volumn:         1,chapter:        31,versenumber:         5,verse:"对她们说：“我看你们父亲的气色向我不如从前了；但我父亲的　神向来与我同在。"},
{volumn:         1,chapter:        31,versenumber:         6,verse:"你们也知道，我尽了我的力量服侍你们的父亲。"},
{volumn:         1,chapter:        31,versenumber:         7,verse:"你们的父亲欺哄我，十次改了我的工价；然而　神不容他害我。"},
{volumn:         1,chapter:        31,versenumber:         8,verse:"他若说：‘有点的归你作工价’，羊群所生的都有点；他若说：‘有纹的归你作工价’，羊群所生的都有纹。"},
{volumn:         1,chapter:        31,versenumber:         9,verse:"这样，　神把你们父亲的牲畜夺来赐给我了。"},
{volumn:         1,chapter:        31,versenumber:        10,verse:"羊配合的时候，我梦中举目一看，见跳母羊的公羊都是有纹的、有点的、有花斑的。"},
{volumn:         1,chapter:        31,versenumber:        11,verse:"神的使者在那梦中呼叫我说：‘雅各。’我说：‘我在这里。’"},
{volumn:         1,chapter:        31,versenumber:        12,verse:"他说：‘你举目观看，跳母羊的公羊都是有纹的、有点的、有花斑的；凡拉班向你所做的，我都看见了。"},
{volumn:         1,chapter:        31,versenumber:        13,verse:"我是伯特利的　神；你在那里用油浇过柱子，向我许过愿。现今你起来，离开这地，回你本地去吧！’”"},
{volumn:         1,chapter:        31,versenumber:        14,verse:"拉结和利亚回答雅各说：“在我们父亲的家里还有我们可得的份吗？还有我们的产业吗？"},
{volumn:         1,chapter:        31,versenumber:        15,verse:"我们不是被他当作外人吗？因为他卖了我们，吞了我们的价值。"},
{volumn:         1,chapter:        31,versenumber:        16,verse:"神从我们父亲所夺出来的一切财物，那就是我们和我们孩子们的。现今凡　神所吩咐你的，你只管去行吧！”"},
{volumn:         1,chapter:        31,versenumber:        17,verse:"雅各起来，使他的儿子和妻子都骑上骆驼，"},
{volumn:         1,chapter:        31,versenumber:        18,verse:"又带着他在巴旦·亚兰所得的一切牲畜和财物，往迦南地、他父亲以撒那里去了。"},
{volumn:         1,chapter:        31,versenumber:        19,verse:"当时拉班剪羊毛去了，拉结偷了他父亲家中的神像。"},
{volumn:         1,chapter:        31,versenumber:        20,verse:"雅各背着亚兰人拉班偷走了，并不告诉他，"},
{volumn:         1,chapter:        31,versenumber:        21,verse:"就带着所有的逃跑。他起身过大河，面向基列山行去。"},
{volumn:         1,chapter:        31,versenumber:        22,verse:"到第三日，有人告诉拉班，雅各逃跑了。"},
{volumn:         1,chapter:        31,versenumber:        23,verse:"拉班带领他的众弟兄去追赶，追了七日，在基列山就追上了。"},
{volumn:         1,chapter:        31,versenumber:        24,verse:"夜间，　神到亚兰人拉班那里，在梦中对他说：“你要小心，不可与雅各说好说歹。”"},
{volumn:         1,chapter:        31,versenumber:        25,verse:"拉班追上雅各。雅各在山上支搭帐棚；拉班和他的众弟兄也在基列山上支搭帐棚。"},
{volumn:         1,chapter:        31,versenumber:        26,verse:"拉班对雅各说：“你做的是什么事呢？你背着我偷走了，又把我的女儿们带了去，如同用刀剑掳去的一般。"},
{volumn:         1,chapter:        31,versenumber:        27,verse:"你为什么暗暗地逃跑，偷着走，并不告诉我，叫我可以欢乐、唱歌、击鼓、弹琴地送你回去？"},
{volumn:         1,chapter:        31,versenumber:        28,verse:"又不容我与外孙和女儿亲嘴？你所行的真是愚昧！"},
{volumn:         1,chapter:        31,versenumber:        29,verse:"我手中原有能力害你，只是你父亲的　神昨夜对我说：‘你要小心，不可与雅各说好说歹。’"},
{volumn:         1,chapter:        31,versenumber:        30,verse:"现在你虽然想你父家，不得不去，为什么又偷了我的神像呢？”"},
{volumn:         1,chapter:        31,versenumber:        31,verse:"雅各回答拉班说：“恐怕你把你的女儿从我夺去，所以我逃跑。"},
{volumn:         1,chapter:        31,versenumber:        32,verse:"至于你的神像，你在谁那里搜出来，就不容谁存活。当着我们的众弟兄，你认一认，在我这里有什么东西是你的，就拿去。”原来雅各不知道拉结偷了那些神像。"},
{volumn:         1,chapter:        31,versenumber:        33,verse:"拉班进了雅各、利亚，并两个使女的帐棚，都没有搜出来，就从利亚的帐棚出来，进了拉结的帐棚。"},
{volumn:         1,chapter:        31,versenumber:        34,verse:"拉结已经把神像藏在骆驼的驮篓里，便坐在上头。拉班摸遍了那帐棚，并没有摸着。"},
{volumn:         1,chapter:        31,versenumber:        35,verse:"拉结对她父亲说：“现在我身上不便，不能在你面前起来，求我主不要生气。”这样，拉班搜寻神像，竟没有搜出来。"},
{volumn:         1,chapter:        31,versenumber:        36,verse:"雅各就发怒斥责拉班说：“我有什么过犯，有什么罪恶，你竟这样火速地追我？"},
{volumn:         1,chapter:        31,versenumber:        37,verse:"你摸遍了我一切的家具，你搜出什么来呢？可以放在你我弟兄面前，叫他们在你我中间辨别辨别。"},
{volumn:         1,chapter:        31,versenumber:        38,verse:"我在你家这二十年，你的母绵羊、母山羊没有掉过胎。你群中的公羊，我没有吃过；"},
{volumn:         1,chapter:        31,versenumber:        39,verse:"被野兽撕裂的，我没有带来给你，是我自己赔上。无论是白日，是黑夜，被偷去的，你都向我索要。"},
{volumn:         1,chapter:        31,versenumber:        40,verse:"我白日受尽干热，黑夜受尽寒霜，不得合眼睡着，我常是这样。"},
{volumn:         1,chapter:        31,versenumber:        41,verse:"我这二十年在你家里，为你的两个女儿服侍你十四年，为你的羊群服侍你六年，你又十次改了我的工价。"},
{volumn:         1,chapter:        31,versenumber:        42,verse:"若不是我父亲以撒所敬畏的　神，就是亚伯拉罕的　神与我同在，你如今必定打发我空手而去。　神看见我的苦情和我的劳碌，就在昨夜责备你。”"},
{volumn:         1,chapter:        31,versenumber:        43,verse:"拉班回答雅各说：“这女儿是我的女儿，这些孩子是我的孩子，这些羊群也是我的羊群；凡在你眼前的都是我的。我的女儿并她们所生的孩子，我今日能向他们做什么呢？"},
{volumn:         1,chapter:        31,versenumber:        44,verse:"来吧！你我二人可以立约，作你我中间的证据。”"},
{volumn:         1,chapter:        31,versenumber:        45,verse:"雅各就拿一块石头立作柱子，"},
{volumn:         1,chapter:        31,versenumber:        46,verse:"又对众弟兄说：“你们堆聚石头。”他们就拿石头来堆成一堆，大家便在旁边吃喝。"},
{volumn:         1,chapter:        31,versenumber:        47,verse:"拉班称那石堆为伊迦尔·撒哈杜他，雅各却称那石堆为迦累得（都是以石堆为证的意思）。"},
{volumn:         1,chapter:        31,versenumber:        48,verse:"拉班说：“今日这石堆作你我中间的证据。”因此这地方名叫迦累得，"},
{volumn:         1,chapter:        31,versenumber:        49,verse:"又叫米斯巴，意思说：“我们彼此离别以后，愿耶和华在你我中间鉴察。"},
{volumn:         1,chapter:        31,versenumber:        50,verse:"你若苦待我的女儿，又在我的女儿以外另娶妻，虽没有人知道，却有　神在你我中间作见证。”"},
{volumn:         1,chapter:        31,versenumber:        51,verse:"拉班又说：“你看我在你我中间所立的这石堆和柱子。"},
{volumn:         1,chapter:        31,versenumber:        52,verse:"这石堆作证据，这柱子也作证据。我必不过这石堆去害你；你也不可过这石堆和柱子来害我。"},
{volumn:         1,chapter:        31,versenumber:        53,verse:"但愿亚伯拉罕的　神和拿鹤的　神，就是他们父亲的　神，在你我中间判断。”雅各就指着他父亲以撒所敬畏的　神起誓，"},
{volumn:         1,chapter:        31,versenumber:        54,verse:"又在山上献祭，请众弟兄来吃饭。他们吃了饭，便在山上住宿。"},
{volumn:         1,chapter:        31,versenumber:        55,verse:"拉班清早起来，与他外孙和女儿亲嘴，给他们祝福，回往自己的地方去了。"},
{volumn:         1,chapter:        32,versenumber:         1,verse:"雅各仍旧行路，　神的使者遇见他。"},
{volumn:         1,chapter:        32,versenumber:         2,verse:"雅各看见他们就说：“这是　神的军兵”，于是给那地方起名叫玛哈念（就是二军兵的意思）。"},
{volumn:         1,chapter:        32,versenumber:         3,verse:"雅各打发人先往西珥地去，就是以东地，见他哥哥以扫，"},
{volumn:         1,chapter:        32,versenumber:         4,verse:"吩咐他们说：“你们对我主以扫说：‘你的仆人雅各这样说：我在拉班那里寄居，直到如今。"},
{volumn:         1,chapter:        32,versenumber:         5,verse:"我有牛、驴、羊群、仆婢，现在打发人来报告我主，为要在你眼前蒙恩。’”"},
{volumn:         1,chapter:        32,versenumber:         6,verse:"所打发的人回到雅各那里，说：“我们到了你哥哥以扫那里，他带着四百人，正迎着你来。”"},
{volumn:         1,chapter:        32,versenumber:         7,verse:"雅各就甚惧怕，而且愁烦，便把那与他同在的人口和羊群、牛群、骆驼分作两队，"},
{volumn:         1,chapter:        32,versenumber:         8,verse:"说：“以扫若来击杀这一队，剩下的那一队还可以逃避。”"},
{volumn:         1,chapter:        32,versenumber:         9,verse:"雅各说：“耶和华我祖亚伯拉罕的　神，我父亲以撒的　神啊，你曾对我说：‘回你本地本族去，我要厚待你。’"},
{volumn:         1,chapter:        32,versenumber:        10,verse:"你向仆人所施的一切慈爱和诚实，我一点也不配得；我先前只拿着我的杖过这约旦河，如今我却成了两队了。"},
{volumn:         1,chapter:        32,versenumber:        11,verse:"求你救我脱离我哥哥以扫的手；因为我怕他来杀我，连妻子带儿女一同杀了。"},
{volumn:         1,chapter:        32,versenumber:        12,verse:"你曾说：‘我必定厚待你，使你的后裔如同海边的沙，多得不可胜数。’”"},
{volumn:         1,chapter:        32,versenumber:        13,verse:"当夜，雅各在那里住宿，就从他所有的物中拿礼物要送给他哥哥以扫："},
{volumn:         1,chapter:        32,versenumber:        14,verse:"母山羊二百只，公山羊二十只，母绵羊二百只，公绵羊二十只，"},
{volumn:         1,chapter:        32,versenumber:        15,verse:"奶崽子的骆驼三十只，各带着崽子；母牛四十只，公牛十只，母驴二十匹，驴驹十匹。"},
{volumn:         1,chapter:        32,versenumber:        16,verse:"每样各分一群，交在仆人手下，就对仆人说：“你们要在我前头过去，使群群相离，有空闲的地方”；"},
{volumn:         1,chapter:        32,versenumber:        17,verse:"又吩咐尽先走的说：“我哥哥以扫遇见你的时候，问你说：‘你是哪家的人？要往哪里去？你前头这些是谁的？’"},
{volumn:         1,chapter:        32,versenumber:        18,verse:"你就说：‘是你仆人雅各的，是送给我主以扫的礼物；他自己也在我们后边。’”"},
{volumn:         1,chapter:        32,versenumber:        19,verse:"又吩咐第二、第三，和一切赶群畜的人说：“你们遇见以扫的时候也要这样对他说；"},
{volumn:         1,chapter:        32,versenumber:        20,verse:"并且你们要说：‘你仆人雅各在我们后边。’”因雅各心里说：“我藉着在我前头去的礼物解他的恨，然后再见他的面，或者他容纳我。”"},
{volumn:         1,chapter:        32,versenumber:        21,verse:"于是礼物先过去了；那夜，雅各在队中住宿。"},
{volumn:         1,chapter:        32,versenumber:        22,verse:"他夜间起来，带着两个妻子，两个使女，并十一个儿子，都过了雅博渡口，"},
{volumn:         1,chapter:        32,versenumber:        23,verse:"先打发他们过河，又打发所有的都过去，"},
{volumn:         1,chapter:        32,versenumber:        24,verse:"只剩下雅各一人。有一个人来和他摔跤，直到黎明。"},
{volumn:         1,chapter:        32,versenumber:        25,verse:"那人见自己胜不过他，就将他的大腿窝摸了一把，雅各的大腿窝正在摔跤的时候就扭了。"},
{volumn:         1,chapter:        32,versenumber:        26,verse:"那人说：“天黎明了，容我去吧！”雅各说：“你不给我祝福，我就不容你去。”"},
{volumn:         1,chapter:        32,versenumber:        27,verse:"那人说：“你名叫什么？”他说：“我名叫雅各。”"},
{volumn:         1,chapter:        32,versenumber:        28,verse:"那人说：“你的名不要再叫雅各，要叫以色列；因为你与　神与人较力，都得了胜。”"},
{volumn:         1,chapter:        32,versenumber:        29,verse:"雅各问他说：“请将你的名告诉我。”那人说：“何必问我的名？”于是在那里给雅各祝福。"},
{volumn:         1,chapter:        32,versenumber:        30,verse:"雅各便给那地方起名叫毗努伊勒（就是　神之面的意思），意思说：“我面对面见了　神，我的性命仍得保全。”"},
{volumn:         1,chapter:        32,versenumber:        31,verse:"日头刚出来的时候，雅各经过毗努伊勒，他的大腿就瘸了。"},
{volumn:         1,chapter:        32,versenumber:        32,verse:"故此，以色列人不吃大腿窝的筋，直到今日，因为那人摸了雅各大腿窝的筋。"},
{volumn:         1,chapter:        33,versenumber:         1,verse:"雅各举目观看，见以扫来了，后头跟着四百人，他就把孩子们分开交给利亚、拉结，和两个使女，"},
{volumn:         1,chapter:        33,versenumber:         2,verse:"并且叫两个使女和她们的孩子在前头，利亚和她的孩子在后头，拉结和约瑟在尽后头。"},
{volumn:         1,chapter:        33,versenumber:         3,verse:"他自己在他们前头过去，一连七次俯伏在地才就近他哥哥。"},
{volumn:         1,chapter:        33,versenumber:         4,verse:"以扫跑来迎接他，将他抱住，又搂着他的颈项，与他亲嘴，两个人就哭了。"},
{volumn:         1,chapter:        33,versenumber:         5,verse:"以扫举目看见妇人孩子，就说：“这些和你同行的是谁呢？”雅各说：“这些孩子是　神施恩给你的仆人的。”"},
{volumn:         1,chapter:        33,versenumber:         6,verse:"于是两个使女和她们的孩子前来下拜；"},
{volumn:         1,chapter:        33,versenumber:         7,verse:"利亚和她的孩子也前来下拜；随后约瑟和拉结也前来下拜。"},
{volumn:         1,chapter:        33,versenumber:         8,verse:"以扫说：“我所遇见的这些群畜是什么意思呢？”雅各说：“是要在我主面前蒙恩的。”"},
{volumn:         1,chapter:        33,versenumber:         9,verse:"以扫说：“兄弟啊，我的已经够了，你的仍归你吧！”"},
{volumn:         1,chapter:        33,versenumber:        10,verse:"雅各说：“不然，我若在你眼前蒙恩，就求你从我手里收下这礼物；因为我见了你的面，如同见了　神的面，并且你容纳了我。"},
{volumn:         1,chapter:        33,versenumber:        11,verse:"求你收下我带来给你的礼物；因为　神恩待我，使我充足。”雅各再三地求他，他才收下了。"},
{volumn:         1,chapter:        33,versenumber:        12,verse:"以扫说：“我们可以起身前往，我在你前头走。”"},
{volumn:         1,chapter:        33,versenumber:        13,verse:"雅各对他说：“我主知道孩子们年幼娇嫩，牛羊也正在乳养的时候，若是催赶一天，群畜都必死了。"},
{volumn:         1,chapter:        33,versenumber:        14,verse:"求我主在仆人前头走，我要量着在我面前群畜和孩子的力量慢慢地前行，直走到西珥我主那里。”"},
{volumn:         1,chapter:        33,versenumber:        15,verse:"以扫说：“容我把跟随我的人留几个在你这里。”雅各说：“何必呢？只要在我主眼前蒙恩就是了。”"},
{volumn:         1,chapter:        33,versenumber:        16,verse:"于是，以扫当日起行，回往西珥去了。"},
{volumn:         1,chapter:        33,versenumber:        17,verse:"雅各就往疏割去，在那里为自己盖造房屋，又为牲畜搭棚；因此那地方名叫疏割（就是棚的意思）。"},
{volumn:         1,chapter:        33,versenumber:        18,verse:"雅各从巴旦·亚兰回来的时候，平平安安地到了迦南地的示剑城，在城东支搭帐棚，"},
{volumn:         1,chapter:        33,versenumber:        19,verse:"就用一百块银子向示剑的父亲、哈抹的子孙买了支帐棚的那块地，"},
{volumn:         1,chapter:        33,versenumber:        20,verse:"在那里筑了一座坛，起名叫伊利·伊罗伊·以色列（就是　神、以色列　神的意思）。"},
{volumn:         1,chapter:        34,versenumber:         1,verse:"利亚给雅各所生的女儿底拿出去，要见那地的女子们。"},
{volumn:         1,chapter:        34,versenumber:         2,verse:"那地的主希未人、哈抹的儿子示剑看见她，就拉住她，与她行淫，玷辱她。"},
{volumn:         1,chapter:        34,versenumber:         3,verse:"示剑的心系恋雅各的女儿底拿，喜爱这女子，甜言蜜语地安慰她。"},
{volumn:         1,chapter:        34,versenumber:         4,verse:"示剑对他父亲哈抹说：“求你为我聘这女子为妻。”"},
{volumn:         1,chapter:        34,versenumber:         5,verse:"雅各听见示剑玷污了他的女儿底拿。那时他的儿子们正和群畜在田野，雅各就闭口不言，等他们回来。"},
{volumn:         1,chapter:        34,versenumber:         6,verse:"示剑的父亲哈抹出来见雅各，要和他商议。"},
{volumn:         1,chapter:        34,versenumber:         7,verse:"雅各的儿子们听见这事，就从田野回来，人人忿恨，十分恼怒；因示剑在以色列家做了丑事，与雅各的女儿行淫，这本是不该做的事。"},
{volumn:         1,chapter:        34,versenumber:         8,verse:"哈抹和他们商议说：“我儿子示剑的心恋慕这女子，求你们将她给我的儿子为妻。"},
{volumn:         1,chapter:        34,versenumber:         9,verse:"你们与我们彼此结亲；你们可以把女儿给我们，也可以娶我们的女儿。"},
{volumn:         1,chapter:        34,versenumber:        10,verse:"你们与我们同住吧！这地都在你们面前，只管在此居住，做买卖，置产业。”"},
{volumn:         1,chapter:        34,versenumber:        11,verse:"示剑对女儿的父亲和弟兄们说：“但愿我在你们眼前蒙恩，你们向我要什么，我必给你们。"},
{volumn:         1,chapter:        34,versenumber:        12,verse:"任凭向我要多重的聘金和礼物，我必照你们所说的给你们；只要把女子给我为妻。”"},
{volumn:         1,chapter:        34,versenumber:        13,verse:"雅各的儿子们因为示剑玷污了他们的妹子底拿，就用诡诈的话回答示剑和他父亲哈抹，"},
{volumn:         1,chapter:        34,versenumber:        14,verse:"对他们说：“我们不能把我们的妹子给没有受割礼的人为妻，因为那是我们的羞辱。"},
{volumn:         1,chapter:        34,versenumber:        15,verse:"惟有一件才可以应允：若你们所有的男丁都受割礼，和我们一样，"},
{volumn:         1,chapter:        34,versenumber:        16,verse:"我们就把女儿给你们，也娶你们的女儿；我们便与你们同住，两下成为一样的人民。"},
{volumn:         1,chapter:        34,versenumber:        17,verse:"倘若你们不听从我们受割礼，我们就带着妹子走了。”"},
{volumn:         1,chapter:        34,versenumber:        18,verse:"哈抹和他的儿子示剑喜欢这话。"},
{volumn:         1,chapter:        34,versenumber:        19,verse:"那少年人做这事并不迟延，因为他喜爱雅各的女儿；他在他父亲家中也是人最尊重的。"},
{volumn:         1,chapter:        34,versenumber:        20,verse:"哈抹和他儿子示剑到本城的门口，对本城的人说："},
{volumn:         1,chapter:        34,versenumber:        21,verse:"“这些人与我们和睦，不如许他们在这地居住，做买卖；这地也宽阔，足可容下他们。我们可以娶他们的女儿为妻，也可以把我们的女儿嫁给他们。"},
{volumn:         1,chapter:        34,versenumber:        22,verse:"惟有一件事我们必须做，他们才肯应允和我们同住，成为一样的人民：就是我们中间所有的男丁都要受割礼，和他们一样。"},
{volumn:         1,chapter:        34,versenumber:        23,verse:"他们的群畜、货财，和一切的牲口岂不都归我们吗？只要依从他们，他们就与我们同住。”"},
{volumn:         1,chapter:        34,versenumber:        24,verse:"凡从城门出入的人就都听从哈抹和他儿子示剑的话；于是凡从城门出入的男丁都受了割礼。"},
{volumn:         1,chapter:        34,versenumber:        25,verse:"到第三天，众人正在疼痛的时候，雅各的两个儿子，就是底拿的哥哥西缅和利未，各拿刀剑，趁着众人想不到的时候来到城中，把一切男丁都杀了，"},
{volumn:         1,chapter:        34,versenumber:        26,verse:"又用刀杀了哈抹和他儿子示剑，把底拿从示剑家里带出来就走了。"},
{volumn:         1,chapter:        34,versenumber:        27,verse:"雅各的儿子们因为他们的妹子受了玷污，就来到被杀的人那里，掳掠那城，"},
{volumn:         1,chapter:        34,versenumber:        28,verse:"夺了他们的羊群、牛群，和驴，并城里田间所有的；"},
{volumn:         1,chapter:        34,versenumber:        29,verse:"又把他们一切货财、孩子、妇女，并各房中所有的，都掳掠去了。"},
{volumn:         1,chapter:        34,versenumber:        30,verse:"雅各对西缅和利未说：“你们连累我，使我在这地的居民中，就是在迦南人和比利洗人中，有了臭名。我的人丁既然稀少，他们必聚集来击杀我，我和全家的人都必灭绝。”"},
{volumn:         1,chapter:        34,versenumber:        31,verse:"他们说：“他岂可待我们的妹子如同妓女吗？”"},
{volumn:         1,chapter:        35,versenumber:         1,verse:"神对雅各说：“起来！上伯特利去，住在那里；要在那里筑一座坛给　神，就是你逃避你哥哥以扫的时候向你显现的那位。”"},
{volumn:         1,chapter:        35,versenumber:         2,verse:"雅各就对他家中的人并一切与他同在的人说：“你们要除掉你们中间的外邦神，也要自洁，更换衣裳。"},
{volumn:         1,chapter:        35,versenumber:         3,verse:"我们要起来，上伯特利去，在那里我要筑一座坛给　神，就是在我遭难的日子应允我的祷告、在我行的路上保佑我的那位。”"},
{volumn:         1,chapter:        35,versenumber:         4,verse:"他们就把外邦人的神像和他们耳朵上的环子交给雅各；雅各都藏在示剑那里的橡树底下。"},
{volumn:         1,chapter:        35,versenumber:         5,verse:"他们便起行前往。　神使那周围城邑的人都甚惊惧，就不追赶雅各的众子了。"},
{volumn:         1,chapter:        35,versenumber:         6,verse:"于是，雅各和一切与他同在的人到了迦南地的路斯，就是伯特利。"},
{volumn:         1,chapter:        35,versenumber:         7,verse:"他在那里筑了一座坛，就给那地方起名叫伊勒·伯特利（就是伯特利之　神的意思）；因为他逃避他哥哥的时候，　神在那里向他显现。"},
{volumn:         1,chapter:        35,versenumber:         8,verse:"利百加的奶母底波拉死了，就葬在伯特利下边橡树底下；那棵树名叫亚伦·巴古。"},
{volumn:         1,chapter:        35,versenumber:         9,verse:"雅各从巴旦·亚兰回来，　神又向他显现，赐福与他，"},
{volumn:         1,chapter:        35,versenumber:        10,verse:"且对他说：“你的名原是雅各，从今以后不要再叫雅各，要叫以色列。”这样，他就改名叫以色列。"},
{volumn:         1,chapter:        35,versenumber:        11,verse:"神又对他说：“我是全能的　神；你要生养众多，将来有一族和多国的民从你而生，又有君王从你而出。"},
{volumn:         1,chapter:        35,versenumber:        12,verse:"我所赐给亚伯拉罕和以撒的地，我要赐给你与你的后裔。”"},
{volumn:         1,chapter:        35,versenumber:        13,verse:"神就从那与雅各说话的地方升上去了。"},
{volumn:         1,chapter:        35,versenumber:        14,verse:"雅各便在那里立了一根石柱，在柱子上奠酒，浇油。"},
{volumn:         1,chapter:        35,versenumber:        15,verse:"雅各就给那地方起名叫伯特利。"},
{volumn:         1,chapter:        35,versenumber:        16,verse:"他们从伯特利起行，离以法他还有一段路程，拉结临产甚是艰难。"},
{volumn:         1,chapter:        35,versenumber:        17,verse:"正在艰难的时候，收生婆对她说：“不要怕，你又要得一个儿子了。”"},
{volumn:         1,chapter:        35,versenumber:        18,verse:"她将近于死，灵魂要走的时候，就给她儿子起名叫便·俄尼；他父亲却给他起名叫便雅悯。"},
{volumn:         1,chapter:        35,versenumber:        19,verse:"拉结死了，葬在以法他的路旁；以法他就是伯利恒。"},
{volumn:         1,chapter:        35,versenumber:        20,verse:"雅各在她的坟上立了一统碑，就是拉结的墓碑，到今日还在。"},
{volumn:         1,chapter:        35,versenumber:        21,verse:"以色列起行前往，在以得台那边支搭帐棚。"},
{volumn:         1,chapter:        35,versenumber:        22,verse:"以色列住在那地的时候，流便去与他父亲的妾辟拉同寝，以色列也听见了。雅各共有十二个儿子。"},
{volumn:         1,chapter:        35,versenumber:        23,verse:"利亚所生的是雅各的长子流便，还有西缅、利未、犹大、以萨迦、西布伦。"},
{volumn:         1,chapter:        35,versenumber:        24,verse:"拉结所生的是约瑟、便雅悯。"},
{volumn:         1,chapter:        35,versenumber:        25,verse:"拉结的使女辟拉所生的是但、拿弗他利。"},
{volumn:         1,chapter:        35,versenumber:        26,verse:"利亚的使女悉帕所生的是迦得、亚设。这是雅各在巴旦·亚兰所生的儿子。"},
{volumn:         1,chapter:        35,versenumber:        27,verse:"雅各来到他父亲以撒那里，到了基列·亚巴的幔利，乃是亚伯拉罕和以撒寄居的地方；基列·亚巴就是希伯仑。"},
{volumn:         1,chapter:        35,versenumber:        28,verse:"以撒共活了一百八十岁。"},
{volumn:         1,chapter:        35,versenumber:        29,verse:"以撒年纪老迈，日子满足，气绝而死，归到他列祖（原文是本民）那里。他两个儿子以扫、雅各把他埋葬了。"},
{volumn:         1,chapter:        36,versenumber:         1,verse:"以扫就是以东，他的后代记在下面。"},
{volumn:         1,chapter:        36,versenumber:         2,verse:"以扫娶迦南的女子为妻，就是赫人以伦的女儿亚大和希未人祭便的孙女、亚拿的女儿阿何利巴玛，"},
{volumn:         1,chapter:        36,versenumber:         3,verse:"又娶了以实玛利的女儿、尼拜约的妹子巴实抹。"},
{volumn:         1,chapter:        36,versenumber:         4,verse:"亚大给以扫生了以利法；巴实抹生了流珥；"},
{volumn:         1,chapter:        36,versenumber:         5,verse:"阿何利巴玛生了耶乌施、雅兰、可拉。这都是以扫的儿子，是在迦南地生的。"},
{volumn:         1,chapter:        36,versenumber:         6,verse:"以扫带着他的妻子、儿女，与家中一切的人口，并他的牛羊、牲畜，和一切货财，就是他在迦南地所得的，往别处去，离了他兄弟雅各。"},
{volumn:         1,chapter:        36,versenumber:         7,verse:"因为二人的财物群畜甚多，寄居的地方容不下他们，所以不能同居。"},
{volumn:         1,chapter:        36,versenumber:         8,verse:"于是以扫住在西珥山里；以扫就是以东。"},
{volumn:         1,chapter:        36,versenumber:         9,verse:"以扫是西珥山里以东人的始祖，他的后代记在下面。"},
{volumn:         1,chapter:        36,versenumber:        10,verse:"以扫众子的名字如下。以扫的妻子亚大生以利法；以扫的妻子巴实抹生流珥。"},
{volumn:         1,chapter:        36,versenumber:        11,verse:"以利法的儿子是提幔、阿抹、洗玻、迦坦、基纳斯。"},
{volumn:         1,chapter:        36,versenumber:        12,verse:"亭纳是以扫儿子以利法的妾；她给以利法生了亚玛力。这是以扫的妻子亚大的子孙。"},
{volumn:         1,chapter:        36,versenumber:        13,verse:"流珥的儿子是拿哈、谢拉、沙玛、米撒。这是以扫妻子巴实抹的子孙。"},
{volumn:         1,chapter:        36,versenumber:        14,verse:"以扫的妻子阿何利巴玛是祭便的孙女，亚拿的女儿；她给以扫生了耶乌施、雅兰、可拉。"},
{volumn:         1,chapter:        36,versenumber:        15,verse:"以扫子孙中作族长的记在下面。以扫的长子以利法的子孙中，有提幔族长、阿抹族长、洗玻族长、基纳斯族长、"},
{volumn:         1,chapter:        36,versenumber:        16,verse:"可拉族长、迦坦族长、亚玛力族长。这是在以东地从以利法所出的族长，都是亚大的子孙。"},
{volumn:         1,chapter:        36,versenumber:        17,verse:"以扫的儿子流珥的子孙中，有拿哈族长、谢拉族长、沙玛族长、米撒族长。这是在以东地从流珥所出的族长，都是以扫妻子巴实抹的子孙。"},
{volumn:         1,chapter:        36,versenumber:        18,verse:"以扫的妻子阿何利巴玛的子孙中，有耶乌施族长、雅兰族长、可拉族长。这是从以扫妻子，亚拿的女儿，阿何利巴玛子孙中所出的族长。"},
{volumn:         1,chapter:        36,versenumber:        19,verse:"以上的族长都是以扫的子孙；以扫就是以东。"},
{volumn:         1,chapter:        36,versenumber:        20,verse:"那地原有的居民何利人西珥的子孙记在下面：就是罗坍、朔巴、祭便、亚拿、"},
{volumn:         1,chapter:        36,versenumber:        21,verse:"底顺、以察、底珊。这是从以东地的何利人西珥子孙中所出的族长。"},
{volumn:         1,chapter:        36,versenumber:        22,verse:"罗坍的儿子是何利、希幔；罗坍的妹子是亭纳。"},
{volumn:         1,chapter:        36,versenumber:        23,verse:"朔巴的儿子是亚勒文、玛拿辖、以巴录、示玻、阿南。"},
{volumn:         1,chapter:        36,versenumber:        24,verse:"祭便的儿子是亚雅、亚拿（当时在旷野放他父亲祭便的驴，遇着温泉的，就是这亚拿）。"},
{volumn:         1,chapter:        36,versenumber:        25,verse:"亚拿的儿子是底顺；亚拿的女儿是阿何利巴玛。"},
{volumn:         1,chapter:        36,versenumber:        26,verse:"底顺的儿子是欣但、伊是班、益兰、基兰。"},
{volumn:         1,chapter:        36,versenumber:        27,verse:"以察的儿子是辟罕、撒番、亚干。"},
{volumn:         1,chapter:        36,versenumber:        28,verse:"底珊的儿子是乌斯、亚兰。"},
{volumn:         1,chapter:        36,versenumber:        29,verse:"从何利人所出的族长记在下面：就是罗坍族长、朔巴族长、祭便族长、亚拿族长、"},
{volumn:         1,chapter:        36,versenumber:        30,verse:"底顺族长、以察族长、底珊族长。这是从何利人所出的族长，都在西珥地，按着宗族作族长。"},
{volumn:         1,chapter:        36,versenumber:        31,verse:"以色列人未有君王治理以先，在以东地作王的记在下面。"},
{volumn:         1,chapter:        36,versenumber:        32,verse:"比珥的儿子比拉在以东作王，他的京城名叫亭哈巴。"},
{volumn:         1,chapter:        36,versenumber:        33,verse:"比拉死了，波斯拉人谢拉的儿子约巴接续他作王。"},
{volumn:         1,chapter:        36,versenumber:        34,verse:"约巴死了，提幔地的人户珊接续他作王。"},
{volumn:         1,chapter:        36,versenumber:        35,verse:"户珊死了，比达的儿子哈达接续他作王；这哈达就是在摩押地杀败米甸人的，他的京城名叫亚未得。"},
{volumn:         1,chapter:        36,versenumber:        36,verse:"哈达死了，玛士利加人桑拉接续他作王。"},
{volumn:         1,chapter:        36,versenumber:        37,verse:"桑拉死了，大河边的利河伯人扫罗接续他作王。"},
{volumn:         1,chapter:        36,versenumber:        38,verse:"扫罗死了，亚革波的儿子巴勒·哈南接续他作王。"},
{volumn:         1,chapter:        36,versenumber:        39,verse:"亚革波的儿子巴勒·哈南死了，哈达接续他作王，他的京城名叫巴乌；他的妻子名叫米希她别，是米·萨合的孙女，玛特列的女儿。"},
{volumn:         1,chapter:        36,versenumber:        40,verse:"从以扫所出的族长，按着他们的宗族、住处、名字记在下面：就是亭纳族长、亚勒瓦族长、耶帖族长、"},
{volumn:         1,chapter:        36,versenumber:        41,verse:"阿何利巴玛族长、以拉族长、比嫩族长、"},
{volumn:         1,chapter:        36,versenumber:        42,verse:"基纳斯族长、提幔族长、米比萨族长、"},
{volumn:         1,chapter:        36,versenumber:        43,verse:"玛基叠族长、以兰族长。这是以东人在所得为业的地上，按着他们的住处。（所有的族长都是以东人的始祖以扫的后代。）"},
{volumn:         1,chapter:        37,versenumber:         1,verse:"雅各住在迦南地，就是他父亲寄居的地。"},
{volumn:         1,chapter:        37,versenumber:         2,verse:"雅各的记略如下：约瑟十七岁与他哥哥们一同牧羊。他是个童子，与他父亲的妾辟拉、悉帕的儿子们常在一处。约瑟将他哥哥们的恶行报给他们的父亲。"},
{volumn:         1,chapter:        37,versenumber:         3,verse:"以色列原来爱约瑟过于爱他的众子，因为约瑟是他年老生的；他给约瑟做了一件彩衣。"},
{volumn:         1,chapter:        37,versenumber:         4,verse:"约瑟的哥哥们见父亲爱约瑟过于爱他们，就恨约瑟，不与他说和睦的话。"},
{volumn:         1,chapter:        37,versenumber:         5,verse:"约瑟做了一梦，告诉他哥哥们，他们就越发恨他。"},
{volumn:         1,chapter:        37,versenumber:         6,verse:"约瑟对他们说：“请听我所做的梦："},
{volumn:         1,chapter:        37,versenumber:         7,verse:"我们在田里捆禾稼，我的捆起来站着，你们的捆来围着我的捆下拜。”"},
{volumn:         1,chapter:        37,versenumber:         8,verse:"他的哥哥们回答说：“难道你真要作我们的王吗？难道你真要管辖我们吗？”他们就因为他的梦和他的话越发恨他。"},
{volumn:         1,chapter:        37,versenumber:         9,verse:"后来他又做了一梦，也告诉他的哥哥们说：“看哪，我又做了一梦，梦见太阳、月亮，与十一个星向我下拜。”"},
{volumn:         1,chapter:        37,versenumber:        10,verse:"约瑟将这梦告诉他父亲和他哥哥们，他父亲就责备他说：“你做的这是什么梦！难道我和你母亲、你弟兄果然要来俯伏在地，向你下拜吗？”"},
{volumn:         1,chapter:        37,versenumber:        11,verse:"他哥哥们都嫉妒他，他父亲却把这话存在心里。"},
{volumn:         1,chapter:        37,versenumber:        12,verse:"约瑟的哥哥们往示剑去放他们父亲的羊。"},
{volumn:         1,chapter:        37,versenumber:        13,verse:"以色列对约瑟说：“你哥哥们不是在示剑放羊吗？你来，我要打发你往他们那里去。”约瑟说：“我在这里。”"},
{volumn:         1,chapter:        37,versenumber:        14,verse:"以色列说：“你去看看你哥哥们平安不平安，群羊平安不平安，就回来报信给我”；于是打发他出希伯仑谷，他就往示剑去了。"},
{volumn:         1,chapter:        37,versenumber:        15,verse:"有人遇见他在田野走迷了路，就问他说：“你找什么？”"},
{volumn:         1,chapter:        37,versenumber:        16,verse:"他说：“我找我的哥哥们，求你告诉我，他们在何处放羊。”"},
{volumn:         1,chapter:        37,versenumber:        17,verse:"那人说：“他们已经走了，我听见他们说要往多坍去。”约瑟就去追赶他哥哥们，遇见他们在多坍。"},
{volumn:         1,chapter:        37,versenumber:        18,verse:"他们远远地看见他，趁他还没有走到跟前，大家就同谋要害死他，"},
{volumn:         1,chapter:        37,versenumber:        19,verse:"彼此说：“你看！那做梦的来了。"},
{volumn:         1,chapter:        37,versenumber:        20,verse:"来吧！我们将他杀了，丢在一个坑里，就说有恶兽把他吃了。我们且看他的梦将来怎么样。”"},
{volumn:         1,chapter:        37,versenumber:        21,verse:"流便听见了，要救他脱离他们的手，说：“我们不可害他的性命”；"},
{volumn:         1,chapter:        37,versenumber:        22,verse:"又说：“不可流他的血，可以把他丢在这野地的坑里，不可下手害他。”流便的意思是要救他脱离他们的手，把他归还他的父亲。"},
{volumn:         1,chapter:        37,versenumber:        23,verse:"约瑟到了他哥哥们那里，他们就剥了他的外衣，就是他穿的那件彩衣，"},
{volumn:         1,chapter:        37,versenumber:        24,verse:"把他丢在坑里；那坑是空的，里头没有水。"},
{volumn:         1,chapter:        37,versenumber:        25,verse:"他们坐下吃饭，举目观看，见有一伙米甸的以实玛利人从基列来，用骆驼驮着香料、乳香、没药，要带下埃及去。"},
{volumn:         1,chapter:        37,versenumber:        26,verse:"犹大对众弟兄说：“我们杀我们的兄弟，藏了他的血有什么益处呢？"},
{volumn:         1,chapter:        37,versenumber:        27,verse:"我们不如将他卖给以实玛利人，不可下手害他；因为他是我们的兄弟，我们的骨肉。”众弟兄就听从了他。"},
{volumn:         1,chapter:        37,versenumber:        28,verse:"有些米甸的商人从那里经过，哥哥们就把约瑟从坑里拉上来，讲定二十舍客勒银子，把约瑟卖给以实玛利人。他们就把约瑟带到埃及去了。"},
{volumn:         1,chapter:        37,versenumber:        29,verse:"流便回到坑边，见约瑟不在坑里，就撕裂衣服，"},
{volumn:         1,chapter:        37,versenumber:        30,verse:"回到兄弟们那里，说：“童子没有了。我往哪里去才好呢？”"},
{volumn:         1,chapter:        37,versenumber:        31,verse:"他们宰了一只公山羊，把约瑟的那件彩衣染了血，"},
{volumn:         1,chapter:        37,versenumber:        32,verse:"打发人送到他们的父亲那里，说：“我们捡了这个；请认一认是你儿子的外衣不是？”"},
{volumn:         1,chapter:        37,versenumber:        33,verse:"他认得，就说：“这是我儿子的外衣。有恶兽把他吃了，约瑟被撕碎了！撕碎了！”"},
{volumn:         1,chapter:        37,versenumber:        34,verse:"雅各便撕裂衣服，腰间围上麻布，为他儿子悲哀了多日。"},
{volumn:         1,chapter:        37,versenumber:        35,verse:"他的儿女都起来安慰他，他却不肯受安慰，说：“我必悲哀着下阴间，到我儿子那里。”约瑟的父亲就为他哀哭。"},
{volumn:         1,chapter:        37,versenumber:        36,verse:"米甸人带约瑟到埃及，把他卖给法老的内臣，护卫长波提乏。"},
{volumn:         1,chapter:        38,versenumber:         1,verse:"那时，犹大离开他弟兄下去，到一个亚杜兰人名叫希拉的家里去。"},
{volumn:         1,chapter:        38,versenumber:         2,verse:"犹大在那里看见一个迦南人名叫书亚的女儿，就娶她为妻，与她同房，"},
{volumn:         1,chapter:        38,versenumber:         3,verse:"她就怀孕生了儿子，犹大给他起名叫珥。"},
{volumn:         1,chapter:        38,versenumber:         4,verse:"她又怀孕生了儿子，母亲给他起名叫俄南。"},
{volumn:         1,chapter:        38,versenumber:         5,verse:"她复又生了儿子，给他起名叫示拉。她生示拉的时候，犹大正在基悉。"},
{volumn:         1,chapter:        38,versenumber:         6,verse:"犹大为长子珥娶妻，名叫他玛。"},
{volumn:         1,chapter:        38,versenumber:         7,verse:"犹大的长子珥在耶和华眼中看为恶，耶和华就叫他死了。"},
{volumn:         1,chapter:        38,versenumber:         8,verse:"犹大对俄南说：“你当与你哥哥的妻子同房，向她尽你为弟的本分，为你哥哥生子立后。”"},
{volumn:         1,chapter:        38,versenumber:         9,verse:"俄南知道生子不归自己，所以同房的时候便遗在地，免得给他哥哥留后。"},
{volumn:         1,chapter:        38,versenumber:        10,verse:"俄南所做的在耶和华眼中看为恶，耶和华也就叫他死了。"},
{volumn:         1,chapter:        38,versenumber:        11,verse:"犹大心里说：“恐怕示拉也死，像他两个哥哥一样”，就对他儿妇他玛说：“你去，在你父亲家里守寡，等我儿子示拉长大。”他玛就回去，住在她父亲家里。"},
{volumn:         1,chapter:        38,versenumber:        12,verse:"过了许久，犹大的妻子书亚的女儿死了。犹大得了安慰，就和他朋友亚杜兰人希拉上亭拿去，到他剪羊毛的人那里。"},
{volumn:         1,chapter:        38,versenumber:        13,verse:"有人告诉他玛说：“你的公公上亭拿剪羊毛去了。”"},
{volumn:         1,chapter:        38,versenumber:        14,verse:"他玛见示拉已经长大，还没有娶她为妻，就脱了她作寡妇的衣裳，用帕子蒙着脸，又遮住身体，坐在亭拿路上的伊拿印城门口。"},
{volumn:         1,chapter:        38,versenumber:        15,verse:"犹大看见她，以为是妓女，因为她蒙着脸。"},
{volumn:         1,chapter:        38,versenumber:        16,verse:"犹大就转到她那里去，说：“来吧！让我与你同寝。”他原不知道是他的儿妇。他玛说：“你要与我同寝，把什么给我呢？”"},
{volumn:         1,chapter:        38,versenumber:        17,verse:"犹大说：“我从羊群里取一只山羊羔，打发人送来给你。”他玛说：“在未送以先，你愿意给我一个当头吗？”"},
{volumn:         1,chapter:        38,versenumber:        18,verse:"他说：“我给你什么当头呢？”他玛说：“你的印、你的带子，和你手里的杖。”犹大就给了她，与她同寝，她就从犹大怀了孕。"},
{volumn:         1,chapter:        38,versenumber:        19,verse:"他玛起来走了，除去帕子，仍旧穿上作寡妇的衣裳。"},
{volumn:         1,chapter:        38,versenumber:        20,verse:"犹大托他朋友亚杜兰人送一只山羊羔去，要从那女人手里取回当头来，却找不着她，"},
{volumn:         1,chapter:        38,versenumber:        21,verse:"就问那地方的人说：“伊拿印路旁的妓女在哪里？”他们说：“这里并没有妓女。”"},
{volumn:         1,chapter:        38,versenumber:        22,verse:"他回去见犹大说：“我没有找着她，并且那地方的人说：‘这里没有妓女。’”"},
{volumn:         1,chapter:        38,versenumber:        23,verse:"犹大说：“我把这山羊羔送去了，你竟找不着她。任凭她拿去吧，免得我们被羞辱。”"},
{volumn:         1,chapter:        38,versenumber:        24,verse:"约过了三个月，有人告诉犹大说：“你的儿妇他玛作了妓女，且因行淫有了身孕。”犹大说：“拉出她来，把她烧了！”"},
{volumn:         1,chapter:        38,versenumber:        25,verse:"他玛被拉出来的时候便打发人去见她公公，对他说：“这些东西是谁的，我就是从谁怀的孕。请你认一认，这印和带子并杖都是谁的？”"},
{volumn:         1,chapter:        38,versenumber:        26,verse:"犹大承认说：“她比我更有义，因为我没有将她给我的儿子示拉。”从此犹大不再与她同寝了。"},
{volumn:         1,chapter:        38,versenumber:        27,verse:"他玛将要生产，不料她腹里是一对双生。"},
{volumn:         1,chapter:        38,versenumber:        28,verse:"到生产的时候，一个孩子伸出一只手来；收生婆拿红线拴在他手上，说：“这是头生的。”"},
{volumn:         1,chapter:        38,versenumber:        29,verse:"随后这孩子把手收回去，他哥哥生出来了；收生婆说：“你为什么抢着来呢？”因此给他起名叫法勒斯。"},
{volumn:         1,chapter:        38,versenumber:        30,verse:"后来，他兄弟那手上有红线的也生出来，就给他起名叫谢拉。"},
{volumn:         1,chapter:        39,versenumber:         1,verse:"约瑟被带下埃及去。有一个埃及人，是法老的内臣，护卫长波提乏，从那些带下他来的以实玛利人手下买了他去。"},
{volumn:         1,chapter:        39,versenumber:         2,verse:"约瑟住在他主人埃及人的家中，耶和华与他同在，他就百事顺利。"},
{volumn:         1,chapter:        39,versenumber:         3,verse:"他主人见耶和华与他同在，又见耶和华使他手里所办的尽都顺利，"},
{volumn:         1,chapter:        39,versenumber:         4,verse:"约瑟就在主人眼前蒙恩，伺候他主人，并且主人派他管理家务，把一切所有的都交在他手里。"},
{volumn:         1,chapter:        39,versenumber:         5,verse:"自从主人派约瑟管理家务和他一切所有的，耶和华就因约瑟的缘故赐福与那埃及人的家；凡家里和田间一切所有的都蒙耶和华赐福。"},
{volumn:         1,chapter:        39,versenumber:         6,verse:"波提乏将一切所有的都交在约瑟的手中，除了自己所吃的饭，别的事一概不知。		约瑟原来秀雅俊美。"},
{volumn:         1,chapter:        39,versenumber:         7,verse:"这事以后，约瑟主人的妻以目送情给约瑟，说：“你与我同寝吧！”"},
{volumn:         1,chapter:        39,versenumber:         8,verse:"约瑟不从，对他主人的妻说：“看哪，一切家务，我主人都不知道；他把所有的都交在我手里。"},
{volumn:         1,chapter:        39,versenumber:         9,verse:"在这家里没有比我大的；并且他没有留下一样不交给我，只留下了你，因为你是他的妻子。我怎能作这大恶，得罪　神呢？”"},
{volumn:         1,chapter:        39,versenumber:        10,verse:"后来她天天和约瑟说，约瑟却不听从她，不与她同寝，也不和她在一处。"},
{volumn:         1,chapter:        39,versenumber:        11,verse:"有一天，约瑟进屋里去办事，家中人没有一个在那屋里，"},
{volumn:         1,chapter:        39,versenumber:        12,verse:"妇人就拉住他的衣裳，说：“你与我同寝吧！”约瑟把衣裳丢在妇人手里，跑到外边去了。"},
{volumn:         1,chapter:        39,versenumber:        13,verse:"妇人看见约瑟把衣裳丢在她手里跑出去了，"},
{volumn:         1,chapter:        39,versenumber:        14,verse:"就叫了家里的人来，对他们说：“你们看！他带了一个希伯来人进入我们家里，要戏弄我们。他到我这里来，要与我同寝，我就大声喊叫。"},
{volumn:         1,chapter:        39,versenumber:        15,verse:"他听见我放声喊起来，就把衣裳丢在我这里，跑到外边去了。”"},
{volumn:         1,chapter:        39,versenumber:        16,verse:"妇人把约瑟的衣裳放在自己那里，等着他主人回家，"},
{volumn:         1,chapter:        39,versenumber:        17,verse:"就对他如此如此说：“你所带到我们这里的那希伯来仆人进来要戏弄我，"},
{volumn:         1,chapter:        39,versenumber:        18,verse:"我放声喊起来，他就把衣裳丢在我这里，跑出去了。”"},
{volumn:         1,chapter:        39,versenumber:        19,verse:"约瑟的主人听见他妻子对他所说的话，说“你的仆人如此如此待我”，他就生气，"},
{volumn:         1,chapter:        39,versenumber:        20,verse:"把约瑟下在监里，就是王的囚犯被囚的地方。于是约瑟在那里坐监。"},
{volumn:         1,chapter:        39,versenumber:        21,verse:"但耶和华与约瑟同在，向他施恩，使他在司狱的眼前蒙恩。"},
{volumn:         1,chapter:        39,versenumber:        22,verse:"司狱就把监里所有的囚犯都交在约瑟的手下；他们在那里所办的事都是经他的手。"},
{volumn:         1,chapter:        39,versenumber:        23,verse:"凡在约瑟手下的事，司狱一概不察，因为耶和华与约瑟同在；耶和华使他所做的尽都顺利。"},
{volumn:         1,chapter:        40,versenumber:         1,verse:"这事以后，埃及王的酒政和膳长得罪了他们的主埃及王，"},
{volumn:         1,chapter:        40,versenumber:         2,verse:"法老就恼怒酒政和膳长这二臣，"},
{volumn:         1,chapter:        40,versenumber:         3,verse:"把他们下在护卫长府内的监里，就是约瑟被囚的地方。"},
{volumn:         1,chapter:        40,versenumber:         4,verse:"护卫长把他们交给约瑟，约瑟便伺候他们；他们有些日子在监里。"},
{volumn:         1,chapter:        40,versenumber:         5,verse:"被囚在监之埃及王的酒政和膳长二人同夜各做一梦，各梦都有讲解。"},
{volumn:         1,chapter:        40,versenumber:         6,verse:"到了早晨，约瑟进到他们那里，见他们有愁闷的样子。"},
{volumn:         1,chapter:        40,versenumber:         7,verse:"他便问法老的二臣，就是与他同囚在他主人府里的，说：“你们今日为什么面带愁容呢？”"},
{volumn:         1,chapter:        40,versenumber:         8,verse:"他们对他说：“我们各人做了一梦，没有人能解。”约瑟说：“解梦不是出于　神吗？请你们将梦告诉我。”"},
{volumn:         1,chapter:        40,versenumber:         9,verse:"酒政便将他的梦告诉约瑟说：“我梦见在我面前有一棵葡萄树，"},
{volumn:         1,chapter:        40,versenumber:        10,verse:"树上有三根枝子，好像发了芽，开了花，上头的葡萄都成熟了。"},
{volumn:         1,chapter:        40,versenumber:        11,verse:"法老的杯在我手中，我就拿葡萄挤在法老的杯里，将杯递在他手中。”"},
{volumn:         1,chapter:        40,versenumber:        12,verse:"约瑟对他说：“你所做的梦是这样解：三根枝子就是三天；"},
{volumn:         1,chapter:        40,versenumber:        13,verse:"三天之内，法老必提你出监，叫你官复原职，你仍要递杯在法老的手中，和先前作他的酒政一样。"},
{volumn:         1,chapter:        40,versenumber:        14,verse:"但你得好处的时候，求你记念我，施恩与我，在法老面前提说我，救我出这监牢。"},
{volumn:         1,chapter:        40,versenumber:        15,verse:"我实在是从希伯来人之地被拐来的；我在这里也没有做过什么，叫他们把我下在监里。”"},
{volumn:         1,chapter:        40,versenumber:        16,verse:"膳长见梦解得好，就对约瑟说：“我在梦中见我头上顶着三筐白饼；"},
{volumn:         1,chapter:        40,versenumber:        17,verse:"极上的筐子里有为法老烤的各样食物，有飞鸟来吃我头上筐子里的食物。”"},
{volumn:         1,chapter:        40,versenumber:        18,verse:"约瑟说：“你的梦是这样解：三个筐子就是三天；"},
{volumn:         1,chapter:        40,versenumber:        19,verse:"三天之内，法老必斩断你的头，把你挂在木头上，必有飞鸟来吃你身上的肉。”"},
{volumn:         1,chapter:        40,versenumber:        20,verse:"到了第三天，是法老的生日，他为众臣仆设摆筵席，把酒政和膳长提出监来，"},
{volumn:         1,chapter:        40,versenumber:        21,verse:"使酒政官复原职，他仍旧递杯在法老手中；"},
{volumn:         1,chapter:        40,versenumber:        22,verse:"但把膳长挂起来，正如约瑟向他们所解的话。"},
{volumn:         1,chapter:        40,versenumber:        23,verse:"酒政却不记念约瑟，竟忘了他。"},
{volumn:         1,chapter:        41,versenumber:         1,verse:"过了两年，法老做梦，梦见自己站在河边，"},
{volumn:         1,chapter:        41,versenumber:         2,verse:"有七只母牛从河里上来，又美好又肥壮，在芦荻中吃草。"},
{volumn:         1,chapter:        41,versenumber:         3,verse:"随后又有七只母牛从河里上来，又丑陋又干瘦，与那七只母牛一同站在河边。"},
{volumn:         1,chapter:        41,versenumber:         4,verse:"这又丑陋又干瘦的七只母牛吃尽了那又美好又肥壮的七只母牛。法老就醒了。"},
{volumn:         1,chapter:        41,versenumber:         5,verse:"他又睡着，第二回做梦，梦见一棵麦子长了七个穗子，又肥大又佳美，"},
{volumn:         1,chapter:        41,versenumber:         6,verse:"随后又长了七个穗子，又细弱又被东风吹焦了。"},
{volumn:         1,chapter:        41,versenumber:         7,verse:"这细弱的穗子吞了那七个又肥大又饱满的穗子。法老醒了，不料是个梦。"},
{volumn:         1,chapter:        41,versenumber:         8,verse:"到了早晨，法老心里不安，就差人召了埃及所有的术士和博士来；法老就把所做的梦告诉他们，却没有人能给法老圆解。"},
{volumn:         1,chapter:        41,versenumber:         9,verse:"那时酒政对法老说：“我今日想起我的罪来。"},
{volumn:         1,chapter:        41,versenumber:        10,verse:"从前法老恼怒臣仆，把我和膳长下在护卫长府内的监里。"},
{volumn:         1,chapter:        41,versenumber:        11,verse:"我们二人同夜各做一梦，各梦都有讲解。"},
{volumn:         1,chapter:        41,versenumber:        12,verse:"在那里同着我们有一个希伯来的少年人，是护卫长的仆人，我们告诉他，他就把我们的梦圆解，是按着各人的梦圆解的。"},
{volumn:         1,chapter:        41,versenumber:        13,verse:"后来正如他给我们圆解的成就了：我官复原职，膳长被挂起来了。”"},
{volumn:         1,chapter:        41,versenumber:        14,verse:"法老遂即差人去召约瑟，他们便急忙带他出监，他就剃头，刮脸，换衣裳，进到法老面前。"},
{volumn:         1,chapter:        41,versenumber:        15,verse:"法老对约瑟说：“我做了一梦，没有人能解；我听见人说，你听了梦就能解。”"},
{volumn:         1,chapter:        41,versenumber:        16,verse:"约瑟回答法老说：“这不在乎我，　神必将平安的话回答法老。”"},
{volumn:         1,chapter:        41,versenumber:        17,verse:"法老对约瑟说：“我梦见我站在河边，"},
{volumn:         1,chapter:        41,versenumber:        18,verse:"有七只母牛从河里上来，又肥壮又美好，在芦荻中吃草。"},
{volumn:         1,chapter:        41,versenumber:        19,verse:"随后又有七只母牛上来，又软弱又丑陋又干瘦，在埃及遍地，我没有见过这样不好的。"},
{volumn:         1,chapter:        41,versenumber:        20,verse:"这又干瘦又丑陋的母牛吃尽了那以先的七只肥母牛，"},
{volumn:         1,chapter:        41,versenumber:        21,verse:"吃了以后却看不出是吃了，那丑陋的样子仍旧和先前一样。我就醒了。"},
{volumn:         1,chapter:        41,versenumber:        22,verse:"我又梦见一棵麦子，长了七个穗子，又饱满又佳美，"},
{volumn:         1,chapter:        41,versenumber:        23,verse:"随后又长了七个穗子，枯槁细弱，被东风吹焦了。"},
{volumn:         1,chapter:        41,versenumber:        24,verse:"这些细弱的穗子吞了那七个佳美的穗子。我将这梦告诉了术士，却没有人能给我解说。”"},
{volumn:         1,chapter:        41,versenumber:        25,verse:"约瑟对法老说：“法老的梦乃是一个。　神已将所要做的事指示法老了。"},
{volumn:         1,chapter:        41,versenumber:        26,verse:"七只好母牛是七年，七个好穗子也是七年；这梦乃是一个。"},
{volumn:         1,chapter:        41,versenumber:        27,verse:"那随后上来的七只又干瘦又丑陋的母牛是七年，那七个虚空、被东风吹焦的穗子也是七年，都是七个荒年。"},
{volumn:         1,chapter:        41,versenumber:        28,verse:"这就是我对法老所说，　神已将所要做的事显明给法老了。"},
{volumn:         1,chapter:        41,versenumber:        29,verse:"埃及遍地必来七个大丰年，"},
{volumn:         1,chapter:        41,versenumber:        30,verse:"随后又要来七个荒年，甚至在埃及地都忘了先前的丰收，全地必被饥荒所灭。"},
{volumn:         1,chapter:        41,versenumber:        31,verse:"因那以后的饥荒甚大，便不觉得先前的丰收了。"},
{volumn:         1,chapter:        41,versenumber:        32,verse:"至于法老两回做梦，是因　神命定这事，而且必速速成就。"},
{volumn:         1,chapter:        41,versenumber:        33,verse:"所以，法老当拣选一个有聪明有智慧的人，派他治理埃及地。"},
{volumn:         1,chapter:        41,versenumber:        34,verse:"法老当这样行，又派官员管理这地。当七个丰年的时候，征收埃及地的五分之一，"},
{volumn:         1,chapter:        41,versenumber:        35,verse:"叫他们把将来丰年一切的粮食聚敛起来，积蓄五谷，收存在各城里做食物，归于法老的手下。"},
{volumn:         1,chapter:        41,versenumber:        36,verse:"所积蓄的粮食可以防备埃及地将来的七个荒年，免得这地被饥荒所灭。”"},
{volumn:         1,chapter:        41,versenumber:        37,verse:"法老和他一切臣仆都以这事为妙。"},
{volumn:         1,chapter:        41,versenumber:        38,verse:"法老对臣仆说：“像这样的人，有　神的灵在他里头，我们岂能找得着呢？”"},
{volumn:         1,chapter:        41,versenumber:        39,verse:"法老对约瑟说：“　神既将这事都指示你，可见没有人像你这样有聪明有智慧。"},
{volumn:         1,chapter:        41,versenumber:        40,verse:"你可以掌管我的家；我的民都必听从你的话。惟独在宝座上我比你大。”"},
{volumn:         1,chapter:        41,versenumber:        41,verse:"法老又对约瑟说：“我派你治理埃及全地。”"},
{volumn:         1,chapter:        41,versenumber:        42,verse:"法老就摘下手上打印的戒指，戴在约瑟的手上，给他穿上细麻衣，把金链戴在他的颈项上，"},
{volumn:         1,chapter:        41,versenumber:        43,verse:"又叫约瑟坐他的副车，喝道的在前呼叫说：“跪下。”这样，法老派他治理埃及全地。"},
{volumn:         1,chapter:        41,versenumber:        44,verse:"法老对约瑟说：“我是法老，在埃及全地，若没有你的命令，不许人擅自办事（原文是动手动脚）。”"},
{volumn:         1,chapter:        41,versenumber:        45,verse:"法老赐名给约瑟，叫撒发那忒·巴内亚，又将安城的祭司波提非拉的女儿亚西纳给他为妻。约瑟就出去巡行埃及地。"},
{volumn:         1,chapter:        41,versenumber:        46,verse:"约瑟见埃及王法老的时候年三十岁。他从法老面前出去，遍行埃及全地。"},
{volumn:         1,chapter:        41,versenumber:        47,verse:"七个丰年之内，地的出产极丰极盛（原文是一把一把的），"},
{volumn:         1,chapter:        41,versenumber:        48,verse:"约瑟聚敛埃及地七个丰年一切的粮食，把粮食积存在各城里；各城周围田地的粮食都积存在本城里。"},
{volumn:         1,chapter:        41,versenumber:        49,verse:"约瑟积蓄五谷甚多，如同海边的沙，无法计算，因为谷不可胜数。"},
{volumn:         1,chapter:        41,versenumber:        50,verse:"荒年未到以前，安城的祭司波提非拉的女儿亚西纳给约瑟生了两个儿子。"},
{volumn:         1,chapter:        41,versenumber:        51,verse:"约瑟给长子起名叫玛拿西（就是使之忘了的意思），因为他说：“　神使我忘了一切的困苦和我父的全家。”"},
{volumn:         1,chapter:        41,versenumber:        52,verse:"他给次子起名叫以法莲（就是使之昌盛的意思），因为他说：“　神使我在受苦的地方昌盛。”"},
{volumn:         1,chapter:        41,versenumber:        53,verse:"埃及地的七个丰年一完，"},
{volumn:         1,chapter:        41,versenumber:        54,verse:"七个荒年就来了。正如约瑟所说的，各地都有饥荒；惟独埃及全地有粮食。"},
{volumn:         1,chapter:        41,versenumber:        55,verse:"及至埃及全地有了饥荒，众民向法老哀求粮食，法老对他们说：“你们往约瑟那里去，凡他所说的，你们都要做。”"},
{volumn:         1,chapter:        41,versenumber:        56,verse:"当时饥荒遍满天下，约瑟开了各处的仓，粜粮给埃及人；在埃及地饥荒甚大。"},
{volumn:         1,chapter:        41,versenumber:        57,verse:"各地的人都往埃及去，到约瑟那里籴粮，因为天下的饥荒甚大。"},
{volumn:         1,chapter:        42,versenumber:         1,verse:"雅各见埃及有粮，就对儿子们说：“你们为什么彼此观望呢？"},
{volumn:         1,chapter:        42,versenumber:         2,verse:"我听见埃及有粮，你们可以下去，从那里为我们籴些来，使我们可以存活，不至于死。”"},
{volumn:         1,chapter:        42,versenumber:         3,verse:"于是，约瑟的十个哥哥都下埃及籴粮去了。"},
{volumn:         1,chapter:        42,versenumber:         4,verse:"但约瑟的兄弟便雅悯，雅各没有打发他和哥哥们同去，因为雅各说：“恐怕他遭害。”"},
{volumn:         1,chapter:        42,versenumber:         5,verse:"来籴粮的人中有以色列的儿子们，因为迦南地也有饥荒。"},
{volumn:         1,chapter:        42,versenumber:         6,verse:"当时治理埃及地的是约瑟；粜粮给那地众民的就是他。约瑟的哥哥们来了，脸伏于地，向他下拜。"},
{volumn:         1,chapter:        42,versenumber:         7,verse:"约瑟看见他哥哥们，就认得他们，却装作生人，向他们说些严厉话，问他们说：“你们从哪里来？”他们说：“我们从迦南地来籴粮。”"},
{volumn:         1,chapter:        42,versenumber:         8,verse:"约瑟认得他哥哥们，他们却不认得他。"},
{volumn:         1,chapter:        42,versenumber:         9,verse:"约瑟想起从前所做的那两个梦，就对他们说：“你们是奸细，来窥探这地的虚实。”"},
{volumn:         1,chapter:        42,versenumber:        10,verse:"他们对他说：“我主啊，不是的。仆人们是籴粮来的。"},
{volumn:         1,chapter:        42,versenumber:        11,verse:"我们都是一个人的儿子，是诚实人；仆人们并不是奸细。”"},
{volumn:         1,chapter:        42,versenumber:        12,verse:"约瑟说：“不然，你们必是窥探这地的虚实来的。”"},
{volumn:         1,chapter:        42,versenumber:        13,verse:"他们说：“仆人们本是弟兄十二人，是迦南地一个人的儿子，顶小的现今在我们的父亲那里，有一个没有了。”"},
{volumn:         1,chapter:        42,versenumber:        14,verse:"约瑟说：“我才说你们是奸细，这话实在不错。"},
{volumn:         1,chapter:        42,versenumber:        15,verse:"我指着法老的性命起誓，若是你们的小兄弟不到这里来，你们就不得出这地方，从此就可以把你们证验出来了。"},
{volumn:         1,chapter:        42,versenumber:        16,verse:"须要打发你们中间一个人去，把你们的兄弟带来。至于你们，都要囚在这里，好证验你们的话真不真，若不真，我指着法老的性命起誓，你们一定是奸细。”"},
{volumn:         1,chapter:        42,versenumber:        17,verse:"于是约瑟把他们都下在监里三天。"},
{volumn:         1,chapter:        42,versenumber:        18,verse:"到第三天，约瑟对他们说：“我是敬畏　神的；你们照我的话行就可以存活。"},
{volumn:         1,chapter:        42,versenumber:        19,verse:"你们如果是诚实人，可以留你们中间的一个人囚在监里，但你们可以带着粮食回去，救你们家里的饥荒。"},
{volumn:         1,chapter:        42,versenumber:        20,verse:"把你们的小兄弟带到我这里来，如此，你们的话便有证据，你们也不至于死。”他们就照样而行。"},
{volumn:         1,chapter:        42,versenumber:        21,verse:"他们彼此说：“我们在兄弟身上实在有罪。他哀求我们的时候，我们见他心里的愁苦，却不肯听，所以这场苦难临到我们身上。”"},
{volumn:         1,chapter:        42,versenumber:        22,verse:"流便说：“我岂不是对你们说过，不可伤害那孩子吗？只是你们不肯听，所以流他血的罪向我们追讨。”"},
{volumn:         1,chapter:        42,versenumber:        23,verse:"他们不知道约瑟听得出来，因为在他们中间用通事传话。"},
{volumn:         1,chapter:        42,versenumber:        24,verse:"约瑟转身退去，哭了一场，又回来对他们说话，就从他们中间挑出西缅来，在他们眼前把他捆绑。"},
{volumn:         1,chapter:        42,versenumber:        25,verse:"约瑟吩咐人把粮食装满他们的器具，把各人的银子归还在各人的口袋里，又给他们路上用的食物，人就照他的话办了。"},
{volumn:         1,chapter:        42,versenumber:        26,verse:"他们就把粮食驮在驴上，离开那里去了。"},
{volumn:         1,chapter:        42,versenumber:        27,verse:"到了住宿的地方，他们中间有一个人打开口袋，要拿料喂驴，才看见自己的银子仍在口袋里，"},
{volumn:         1,chapter:        42,versenumber:        28,verse:"就对弟兄们说：“我的银子归还了，看哪，仍在我口袋里！”他们就提心吊胆，战战兢兢地彼此说：“这是　神向我们做什么呢？”"},
{volumn:         1,chapter:        42,versenumber:        29,verse:"他们来到迦南地、他们的父亲雅各那里，将所遭遇的事都告诉他，说："},
{volumn:         1,chapter:        42,versenumber:        30,verse:"“那地的主对我们说严厉的话，把我们当作窥探那地的奸细。"},
{volumn:         1,chapter:        42,versenumber:        31,verse:"我们对他说：‘我们是诚实人，并不是奸细。"},
{volumn:         1,chapter:        42,versenumber:        32,verse:"我们本是弟兄十二人，都是一个父亲的儿子，有一个没有了，顶小的如今同我们的父亲在迦南地。’"},
{volumn:         1,chapter:        42,versenumber:        33,verse:"那地的主对我们说：‘若要我知道你们是诚实人，可以留下你们中间的一个人在我这里，你们可以带着粮食回去，救你们家里的饥荒。"},
{volumn:         1,chapter:        42,versenumber:        34,verse:"把你们的小兄弟带到我这里来，我便知道你们不是奸细，乃是诚实人。这样，我就把你们的弟兄交给你们，你们也可以在这地做买卖。’”"},
{volumn:         1,chapter:        42,versenumber:        35,verse:"后来他们倒口袋，不料，各人的银包都在口袋里；他们和父亲看见银包就都害怕。"},
{volumn:         1,chapter:        42,versenumber:        36,verse:"他们的父亲雅各对他们说：“你们使我丧失我的儿子：约瑟没有了，西缅也没有了，你们又要将便雅悯带去；这些事都归到我身上了。”"},
{volumn:         1,chapter:        42,versenumber:        37,verse:"流便对他父亲说：“我若不带他回来交给你，你可以杀我的两个儿子。只管把他交在我手里，我必带他回来交给你。”"},
{volumn:         1,chapter:        42,versenumber:        38,verse:"雅各说：“我的儿子不可与你们一同下去；他哥哥死了，只剩下他，他若在你们所行的路上遭害，那便是你们使我白发苍苍、悲悲惨惨地下阴间去了。”"},
{volumn:         1,chapter:        43,versenumber:         1,verse:"那地的饥荒甚大。"},
{volumn:         1,chapter:        43,versenumber:         2,verse:"他们从埃及带来的粮食吃尽了，他们的父亲就对他们说：“你们再去给我籴些粮来。”"},
{volumn:         1,chapter:        43,versenumber:         3,verse:"犹大对他说：“那人谆谆地告诫我们说：‘你们的兄弟若不与你们同来，你们就不得见我的面。’"},
{volumn:         1,chapter:        43,versenumber:         4,verse:"你若打发我们的兄弟与我们同去，我们就下去给你籴粮；"},
{volumn:         1,chapter:        43,versenumber:         5,verse:"你若不打发他去，我们就不下去，因为那人对我们说：‘你们的兄弟若不与你们同来，你们就不得见我的面。’”"},
{volumn:         1,chapter:        43,versenumber:         6,verse:"以色列说：“你们为什么这样害我，告诉那人你们还有兄弟呢？”"},
{volumn:         1,chapter:        43,versenumber:         7,verse:"他们回答说：“那人详细问到我们和我们的亲属，说：‘你们的父亲还在吗？你们还有兄弟吗？’我们就按着他所问的告诉他，焉能知道他要说‘必须把你们的兄弟带下来’呢？”"},
{volumn:         1,chapter:        43,versenumber:         8,verse:"犹大又对他父亲以色列说：“你打发童子与我同去，我们就起身下去，好叫我们和你，并我们的妇人孩子，都得存活，不至于死。"},
{volumn:         1,chapter:        43,versenumber:         9,verse:"我为他作保；你可以从我手中追讨，我若不带他回来交在你面前，我情愿永远担罪。"},
{volumn:         1,chapter:        43,versenumber:        10,verse:"我们若没有耽搁，如今第二次都回来了。”"},
{volumn:         1,chapter:        43,versenumber:        11,verse:"他们的父亲以色列说：“若必须如此，你们就当这样行：可以将这地土产中最好的乳香、蜂蜜、香料、没药、榧子、杏仁都取一点，收在器具里，带下去送给那人作礼物，"},
{volumn:         1,chapter:        43,versenumber:        12,verse:"又要手里加倍地带银子，并将归还在你们口袋内的银子仍带在手里；那或者是错了。"},
{volumn:         1,chapter:        43,versenumber:        13,verse:"也带着你们的兄弟，起身去见那人。"},
{volumn:         1,chapter:        43,versenumber:        14,verse:"但愿全能的　神使你们在那人面前蒙怜悯，释放你们的那弟兄和便雅悯回来。我若丧了儿子，就丧了吧！”"},
{volumn:         1,chapter:        43,versenumber:        15,verse:"于是，他们拿着那礼物，又手里加倍地带银子，并且带着便雅悯，起身下到埃及，站在约瑟面前。"},
{volumn:         1,chapter:        43,versenumber:        16,verse:"约瑟见便雅悯和他们同来，就对家宰说：“将这些人领到屋里。要宰杀牲畜，预备筵席，因为晌午这些人同我吃饭。”"},
{volumn:         1,chapter:        43,versenumber:        17,verse:"家宰就遵着约瑟的命去行，领他们进约瑟的屋里。"},
{volumn:         1,chapter:        43,versenumber:        18,verse:"他们因为被领到约瑟的屋里，就害怕，说：“领我们到这里来，必是因为头次归还在我们口袋里的银子，找我们的错缝，下手害我们，强取我们为奴仆，抢夺我们的驴。”"},
{volumn:         1,chapter:        43,versenumber:        19,verse:"他们就挨近约瑟的家宰，在屋门口和他说话，"},
{volumn:         1,chapter:        43,versenumber:        20,verse:"说：“我主啊，我们头次下来实在是要籴粮。"},
{volumn:         1,chapter:        43,versenumber:        21,verse:"后来到了住宿的地方，我们打开口袋，不料，各人的银子，分量足数，仍在各人的口袋内，现在我们手里又带回来了。"},
{volumn:         1,chapter:        43,versenumber:        22,verse:"另外又带下银子来籴粮。不知道先前谁把银子放在我们的口袋里。”"},
{volumn:         1,chapter:        43,versenumber:        23,verse:"家宰说：“你们可以放心，不要害怕，是你们的　神和你们父亲的　神赐给你们财宝在你们的口袋里；你们的银子，我早已收了。”他就把西缅带出来，交给他们。"},
{volumn:         1,chapter:        43,versenumber:        24,verse:"家宰就领他们进约瑟的屋里，给他们水洗脚，又给他们草料喂驴。"},
{volumn:         1,chapter:        43,versenumber:        25,verse:"他们就预备那礼物，等候约瑟晌午来，因为他们听见要在那里吃饭。"},
{volumn:         1,chapter:        43,versenumber:        26,verse:"约瑟来到家里，他们就把手中的礼物拿进屋去给他，又俯伏在地，向他下拜。"},
{volumn:         1,chapter:        43,versenumber:        27,verse:"约瑟问他们好，又问：“你们的父亲，就是你们所说的那老人家平安吗？他还在吗？”"},
{volumn:         1,chapter:        43,versenumber:        28,verse:"他们回答说：“你仆人我们的父亲平安；他还在。”于是他们低头下拜。"},
{volumn:         1,chapter:        43,versenumber:        29,verse:"约瑟举目看见他同母的兄弟便雅悯，就说：“你们向我所说那顶小的兄弟就是这位吗？”又说：“小儿啊，愿　神赐恩给你！”"},
{volumn:         1,chapter:        43,versenumber:        30,verse:"约瑟爱弟之情发动，就急忙寻找可哭之地，进入自己的屋里，哭了一场。"},
{volumn:         1,chapter:        43,versenumber:        31,verse:"他洗了脸出来，勉强隐忍，吩咐人摆饭。"},
{volumn:         1,chapter:        43,versenumber:        32,verse:"他们就为约瑟单摆了一席，为那些人又摆了一席，也为和约瑟同吃饭的埃及人另摆了一席，因为埃及人不可和希伯来人一同吃饭；那原是埃及人所厌恶的。"},
{volumn:         1,chapter:        43,versenumber:        33,verse:"约瑟使众弟兄在他面前排列坐席，都按着长幼的次序，众弟兄就彼此诧异。"},
{volumn:         1,chapter:        43,versenumber:        34,verse:"约瑟把他面前的食物分出来，送给他们；但便雅悯所得的比别人多五倍。他们就饮酒，和约瑟一同宴乐。"},
{volumn:         1,chapter:        44,versenumber:         1,verse:"约瑟吩咐家宰说：“把粮食装满这些人的口袋，尽着他们的驴所能驮的，又把各人的银子放在各人的口袋里，"},
{volumn:         1,chapter:        44,versenumber:         2,verse:"并将我的银杯和那少年人籴粮的银子一同装在他的口袋里。”家宰就照约瑟所说的话行了；"},
{volumn:         1,chapter:        44,versenumber:         3,verse:"天一亮就打发那些人带着驴走了。"},
{volumn:         1,chapter:        44,versenumber:         4,verse:"他们出城走了不远，约瑟对家宰说：“起来，追那些人去，追上了就对他们说：‘你们为什么以恶报善呢？"},
{volumn:         1,chapter:        44,versenumber:         5,verse:"这不是我主人饮酒的杯吗？岂不是他占卜用的吗？你们这样行是作恶了。’”"},
{volumn:         1,chapter:        44,versenumber:         6,verse:"家宰追上他们，将这些话对他们说了。"},
{volumn:         1,chapter:        44,versenumber:         7,verse:"他们回答说：“我主为什么说这样的话呢？你仆人断不能做这样的事。"},
{volumn:         1,chapter:        44,versenumber:         8,verse:"你看，我们从前在口袋里所见的银子，尚且从迦南地带来还你，我们怎能从你主人家里偷窃金银呢？"},
{volumn:         1,chapter:        44,versenumber:         9,verse:"你仆人中无论在谁那里搜出来，就叫他死，我们也作我主的奴仆。”"},
{volumn:         1,chapter:        44,versenumber:        10,verse:"家宰说：“现在就照你们的话行吧！在谁那里搜出来，谁就作我的奴仆；其余的都没有罪。”"},
{volumn:         1,chapter:        44,versenumber:        11,verse:"于是他们各人急忙把口袋卸在地上，各人打开口袋。"},
{volumn:         1,chapter:        44,versenumber:        12,verse:"家宰就搜查，从年长的起到年幼的为止，那杯竟在便雅悯的口袋里搜出来。"},
{volumn:         1,chapter:        44,versenumber:        13,verse:"他们就撕裂衣服，各人把驮子抬在驴上，回城去了。"},
{volumn:         1,chapter:        44,versenumber:        14,verse:"犹大和他弟兄们来到约瑟的屋中，约瑟还在那里，他们就在他面前俯伏于地。"},
{volumn:         1,chapter:        44,versenumber:        15,verse:"约瑟对他们说：“你们做的是什么事呢？你们岂不知像我这样的人必能占卜吗？”"},
{volumn:         1,chapter:        44,versenumber:        16,verse:"犹大说：“我们对我主说什么呢？还有什么话可说呢？我们怎能自己表白出来呢？　神已经查出仆人的罪孽了。我们与那在他手中搜出杯来的都是我主的奴仆。”"},
{volumn:         1,chapter:        44,versenumber:        17,verse:"约瑟说：“我断不能这样行！在谁的手中搜出杯来，谁就作我的奴仆；至于你们，可以平平安安地上你们父亲那里去。”"},
{volumn:         1,chapter:        44,versenumber:        18,verse:"犹大挨近他，说：“我主啊，求你容仆人说一句话给我主听，不要向仆人发烈怒，因为你如同法老一样。"},
{volumn:         1,chapter:        44,versenumber:        19,verse:"我主曾问仆人们说：‘你们有父亲有兄弟没有？’"},
{volumn:         1,chapter:        44,versenumber:        20,verse:"我们对我主说：‘我们有父亲，已经年老，还有他老年所生的一个小孩子。他哥哥死了，他母亲只撇下他一人，他父亲疼爱他。’"},
{volumn:         1,chapter:        44,versenumber:        21,verse:"你对仆人说：‘把他带到我这里来，叫我亲眼看看他。’"},
{volumn:         1,chapter:        44,versenumber:        22,verse:"我们对我主说：‘童子不能离开他父亲，若是离开，他父亲必死。’"},
{volumn:         1,chapter:        44,versenumber:        23,verse:"你对仆人说：‘你们的小兄弟若不与你们一同下来，你们就不得再见我的面。’"},
{volumn:         1,chapter:        44,versenumber:        24,verse:"我们上到你仆人我们父亲那里，就把我主的话告诉了他。"},
{volumn:         1,chapter:        44,versenumber:        25,verse:"我们的父亲说：‘你们再去给我籴些粮来。’"},
{volumn:         1,chapter:        44,versenumber:        26,verse:"我们就说：‘我们不能下去。我们的小兄弟若和我们同往，我们就可以下去。因为，小兄弟若不与我们同往，我们必不得见那人的面。’"},
{volumn:         1,chapter:        44,versenumber:        27,verse:"你仆人我父亲对我们说：‘你们知道我的妻子给我生了两个儿子。"},
{volumn:         1,chapter:        44,versenumber:        28,verse:"一个离开我出去了；我说他必是被撕碎了，直到如今我也没有见他。"},
{volumn:         1,chapter:        44,versenumber:        29,verse:"现在你们又要把这个带去离开我，倘若他遭害，那便是你们使我白发苍苍、悲悲惨惨地下阴间去了。’"},
{volumn:         1,chapter:        44,versenumber:        30,verse:"我父亲的命与这童子的命相连。如今我回到你仆人我父亲那里，若没有童子与我们同在，"},
{volumn:         1,chapter:        44,versenumber:        31,verse:"我们的父亲见没有童子，他就必死。这便是我们使你仆人我们的父亲白发苍苍、悲悲惨惨地下阴间去了。"},
{volumn:         1,chapter:        44,versenumber:        32,verse:"因为仆人曾向我父亲为这童子作保，说：‘我若不带他回来交给父亲，我便在父亲面前永远担罪。’"},
{volumn:         1,chapter:        44,versenumber:        33,verse:"现在求你容仆人住下，替这童子作我主的奴仆，叫童子和他哥哥们一同上去。"},
{volumn:         1,chapter:        44,versenumber:        34,verse:"若童子不和我同去，我怎能上去见我父亲呢？恐怕我看见灾祸临到我父亲身上。”"},
{volumn:         1,chapter:        45,versenumber:         1,verse:"约瑟在左右站着的人面前情不自禁，吩咐一声说：“人都要离开我出去！”约瑟和弟兄们相认的时候并没有一人站在他面前。"},
{volumn:         1,chapter:        45,versenumber:         2,verse:"他就放声大哭；埃及人和法老家中的人都听见了。"},
{volumn:         1,chapter:        45,versenumber:         3,verse:"约瑟对他弟兄们说：“我是约瑟。我的父亲还在吗？”他弟兄不能回答，因为在他面前都惊惶。"},
{volumn:         1,chapter:        45,versenumber:         4,verse:"约瑟又对他弟兄们说：“请你们近前来。”他们就近前来。他说：“我是你们的兄弟约瑟，就是你们所卖到埃及的。"},
{volumn:         1,chapter:        45,versenumber:         5,verse:"现在，不要因为把我卖到这里自忧自恨。这是　神差我在你们以先来，为要保全生命。"},
{volumn:         1,chapter:        45,versenumber:         6,verse:"现在这地的饥荒已经二年了，还有五年不能耕种，不能收成。"},
{volumn:         1,chapter:        45,versenumber:         7,verse:"神差我在你们以先来，为要给你们存留余种在世上，又要大施拯救，保全你们的生命。"},
{volumn:         1,chapter:        45,versenumber:         8,verse:"这样看来，差我到这里来的不是你们，乃是　神。他又使我如法老的父，作他全家的主，并埃及全地的宰相。"},
{volumn:         1,chapter:        45,versenumber:         9,verse:"你们要赶紧上到我父亲那里，对他说：‘你儿子约瑟这样说：　神使我作全埃及的主，请你下到我这里来，不要耽延。"},
{volumn:         1,chapter:        45,versenumber:        10,verse:"你和你的儿子孙子，连牛群羊群，并一切所有的，都可以住在歌珊地，与我相近。"},
{volumn:         1,chapter:        45,versenumber:        11,verse:"我要在那里奉养你；因为还有五年的饥荒，免得你和你的眷属，并一切所有的，都败落了。’"},
{volumn:         1,chapter:        45,versenumber:        12,verse:"况且你们的眼和我兄弟便雅悯的眼都看见是我亲口对你们说话。"},
{volumn:         1,chapter:        45,versenumber:        13,verse:"你们也要将我在埃及一切的荣耀和你们所看见的事都告诉我父亲，又要赶紧地将我父亲搬到我这里来。”"},
{volumn:         1,chapter:        45,versenumber:        14,verse:"于是约瑟伏在他兄弟便雅悯的颈项上哭，便雅悯也在他的颈项上哭。"},
{volumn:         1,chapter:        45,versenumber:        15,verse:"他又与众弟兄亲嘴，抱着他们哭，随后他弟兄们就和他说话。"},
{volumn:         1,chapter:        45,versenumber:        16,verse:"这风声传到法老的宫里，说：“约瑟的弟兄们来了。”法老和他的臣仆都很喜欢。"},
{volumn:         1,chapter:        45,versenumber:        17,verse:"法老对约瑟说：“你吩咐你的弟兄们说：‘你们要这样行：把驮子抬在牲口上，起身往迦南地去。"},
{volumn:         1,chapter:        45,versenumber:        18,verse:"将你们的父亲和你们的眷属都搬到我这里来，我要把埃及地的美物赐给你们，你们也要吃这地肥美的出产。"},
{volumn:         1,chapter:        45,versenumber:        19,verse:"现在我吩咐你们要这样行：从埃及地带着车辆去，把你们的孩子和妻子，并你们的父亲都搬来。"},
{volumn:         1,chapter:        45,versenumber:        20,verse:"你们眼中不要爱惜你们的家具，因为埃及全地的美物都是你们的。’”"},
{volumn:         1,chapter:        45,versenumber:        21,verse:"以色列的儿子们就如此行。约瑟照着法老的吩咐给他们车辆和路上用的食物，"},
{volumn:         1,chapter:        45,versenumber:        22,verse:"又给他们各人一套衣服，惟独给便雅悯三百银子，五套衣服；"},
{volumn:         1,chapter:        45,versenumber:        23,verse:"送给他父亲公驴十匹，驮着埃及的美物，母驴十匹，驮着粮食与饼和菜，为他父亲路上用。"},
{volumn:         1,chapter:        45,versenumber:        24,verse:"于是约瑟打发他弟兄们回去，又对他们说：“你们不要在路上相争。”"},
{volumn:         1,chapter:        45,versenumber:        25,verse:"他们从埃及上去，来到迦南地、他们的父亲雅各那里，"},
{volumn:         1,chapter:        45,versenumber:        26,verse:"告诉他说：“约瑟还在，并且作埃及全地的宰相。”雅各心里冰凉，因为不信他们。"},
{volumn:         1,chapter:        45,versenumber:        27,verse:"他们便将约瑟对他们说的一切话都告诉了他。他们父亲雅各又看见约瑟打发来接他的车辆，心就苏醒了。"},
{volumn:         1,chapter:        45,versenumber:        28,verse:"以色列说：“罢了！罢了！我的儿子约瑟还在，趁我未死以先，我要去见他一面。”"},
{volumn:         1,chapter:        46,versenumber:         1,verse:"以色列带着一切所有的，起身来到别是巴，就献祭给他父亲以撒的　神。"},
{volumn:         1,chapter:        46,versenumber:         2,verse:"夜间，　神在异象中对以色列说：“雅各！雅各！”他说：“我在这里。”"},
{volumn:         1,chapter:        46,versenumber:         3,verse:"神说：“我是　神，就是你父亲的　神。你下埃及去不要害怕，因为我必使你在那里成为大族。"},
{volumn:         1,chapter:        46,versenumber:         4,verse:"我要和你同下埃及去，也必定带你上来；约瑟必给你送终（原文是将手按在你的眼睛上）。”"},
{volumn:         1,chapter:        46,versenumber:         5,verse:"雅各就从别是巴起行。以色列的儿子们使他们的父亲雅各和他们的妻子、儿女都坐在法老为雅各送来的车上。"},
{volumn:         1,chapter:        46,versenumber:         6,verse:"他们又带着迦南地所得的牲畜、货财来到埃及。雅各和他的一切子孙都一同来了。"},
{volumn:         1,chapter:        46,versenumber:         7,verse:"雅各把他的儿子、孙子、女儿、孙女，并他的子子孙孙，一同带到埃及。"},
{volumn:         1,chapter:        46,versenumber:         8,verse:"来到埃及的以色列人名字记在下面。雅各和他的儿孙：雅各的长子是流便。"},
{volumn:         1,chapter:        46,versenumber:         9,verse:"流便的儿子是哈诺、法路、希斯伦、迦米。"},
{volumn:         1,chapter:        46,versenumber:        10,verse:"西缅的儿子是耶母利、雅悯、阿辖、雅斤、琐辖，还有迦南女子所生的扫罗。"},
{volumn:         1,chapter:        46,versenumber:        11,verse:"利未的儿子是革顺、哥辖、米拉利。"},
{volumn:         1,chapter:        46,versenumber:        12,verse:"犹大的儿子是珥、俄南、示拉、法勒斯、谢拉；惟有珥与俄南死在迦南地。法勒斯的儿子是希斯仑、哈母勒。"},
{volumn:         1,chapter:        46,versenumber:        13,verse:"以萨迦的儿子是陀拉、普瓦、约伯、伸仑。"},
{volumn:         1,chapter:        46,versenumber:        14,verse:"西布伦的儿子是西烈、以伦、雅利。"},
{volumn:         1,chapter:        46,versenumber:        15,verse:"这是利亚在巴旦·亚兰给雅各所生的儿子，还有女儿底拿。儿孙共有三十三人。"},
{volumn:         1,chapter:        46,versenumber:        16,verse:"迦得的儿子是洗非芸、哈基、书尼、以斯本、以利、亚罗底、亚列利。"},
{volumn:         1,chapter:        46,versenumber:        17,verse:"亚设的儿子是音拿、亦施瓦、亦施韦、比利亚，还有他们的妹子西拉。比利亚的儿子是希别、玛结。"},
{volumn:         1,chapter:        46,versenumber:        18,verse:"这是拉班给他女儿利亚的婢女悉帕从雅各所生的儿孙，共有十六人。"},
{volumn:         1,chapter:        46,versenumber:        19,verse:"雅各之妻拉结的儿子是约瑟和便雅悯。"},
{volumn:         1,chapter:        46,versenumber:        20,verse:"约瑟在埃及地生了玛拿西和以法莲，就是安城的祭司波提非拉的女儿亚西纳给约瑟生的。"},
{volumn:         1,chapter:        46,versenumber:        21,verse:"便雅悯的儿子是比拉、比结、亚实别、基拉、乃幔、以希、罗实、母平、户平、亚勒。"},
{volumn:         1,chapter:        46,versenumber:        22,verse:"这是拉结给雅各所生的儿孙，共有十四人。"},
{volumn:         1,chapter:        46,versenumber:        23,verse:"但的儿子是户伸。"},
{volumn:         1,chapter:        46,versenumber:        24,verse:"拿弗他利的儿子是雅薛、沽尼、耶色、示冷。"},
{volumn:         1,chapter:        46,versenumber:        25,verse:"这是拉班给他女儿拉结的婢女辟拉从雅各所生的儿孙，共有七人。"},
{volumn:         1,chapter:        46,versenumber:        26,verse:"那与雅各同到埃及的，除了他儿妇之外，凡从他所生的，共有六十六人。"},
{volumn:         1,chapter:        46,versenumber:        27,verse:"还有约瑟在埃及所生的两个儿子。雅各家来到埃及的共有七十人。"},
{volumn:         1,chapter:        46,versenumber:        28,verse:"雅各打发犹大先去见约瑟，请派人引路往歌珊去；于是他们来到歌珊地。"},
{volumn:         1,chapter:        46,versenumber:        29,verse:"约瑟套车往歌珊去，迎接他父亲以色列，及至见了面，就伏在父亲的颈项上，哭了许久。"},
{volumn:         1,chapter:        46,versenumber:        30,verse:"以色列对约瑟说：“我既得见你的面，知道你还在，就是死我也甘心。”"},
{volumn:         1,chapter:        46,versenumber:        31,verse:"约瑟对他的弟兄和他父的全家说：“我要上去告诉法老，对他说：‘我的弟兄和我父的全家从前在迦南地，现今都到我这里来了。"},
{volumn:         1,chapter:        46,versenumber:        32,verse:"他们本是牧羊的人，以养牲畜为业；他们把羊群牛群和一切所有的都带来了。’"},
{volumn:         1,chapter:        46,versenumber:        33,verse:"等法老召你们的时候，问你们说：‘你们以何事为业？’"},
{volumn:         1,chapter:        46,versenumber:        34,verse:"你们要说：‘你的仆人，从幼年直到如今，都以养牲畜为业，连我们的祖宗也都以此为业。’这样，你们可以住在歌珊地，因为凡牧羊的都被埃及人所厌恶。”"},
{volumn:         1,chapter:        47,versenumber:         1,verse:"约瑟进去告诉法老说：“我的父亲和我的弟兄带着羊群牛群，并一切所有的，从迦南地来了，如今在歌珊地。”"},
{volumn:         1,chapter:        47,versenumber:         2,verse:"约瑟从他弟兄中挑出五个人来，引他们去见法老。"},
{volumn:         1,chapter:        47,versenumber:         3,verse:"法老问约瑟的弟兄说：“你们以何事为业？”他们对法老说：“你仆人是牧羊的，连我们的祖宗也是牧羊的。”"},
{volumn:         1,chapter:        47,versenumber:         4,verse:"他们又对法老说：“迦南地的饥荒甚大，仆人的羊群没有草吃，所以我们来到这地寄居。现在求你容仆人住在歌珊地。”"},
{volumn:         1,chapter:        47,versenumber:         5,verse:"法老对约瑟说：“你父亲和你弟兄到你这里来了，"},
{volumn:         1,chapter:        47,versenumber:         6,verse:"埃及地都在你面前，只管叫你父亲和你弟兄住在国中最好的地；他们可以住在歌珊地。你若知道他们中间有什么能人，就派他们看管我的牲畜。”"},
{volumn:         1,chapter:        47,versenumber:         7,verse:"约瑟领他父亲雅各进到法老面前，雅各就给法老祝福。"},
{volumn:         1,chapter:        47,versenumber:         8,verse:"法老问雅各说：“你平生的年日是多少呢？”"},
{volumn:         1,chapter:        47,versenumber:         9,verse:"雅各对法老说：“我寄居在世的年日是一百三十岁，我平生的年日又少又苦，不及我列祖在世寄居的年日。”"},
{volumn:         1,chapter:        47,versenumber:        10,verse:"雅各又给法老祝福，就从法老面前出去了。"},
{volumn:         1,chapter:        47,versenumber:        11,verse:"约瑟遵着法老的命，把埃及国最好的地，就是兰塞境内的地，给他父亲和弟兄居住，作为产业。"},
{volumn:         1,chapter:        47,versenumber:        12,verse:"约瑟用粮食奉养他父亲和他弟兄，并他父亲全家的眷属，都是照各家的人口奉养他们。"},
{volumn:         1,chapter:        47,versenumber:        13,verse:"饥荒甚大，全地都绝了粮，甚至埃及地和迦南地的人因那饥荒的缘故都饿昏了。"},
{volumn:         1,chapter:        47,versenumber:        14,verse:"约瑟收聚了埃及地和迦南地所有的银子，就是众人籴粮的银子，约瑟就把那银子带到法老的宫里。"},
{volumn:         1,chapter:        47,versenumber:        15,verse:"埃及地和迦南地的银子都花尽了，埃及众人都来见约瑟，说：“我们的银子都用尽了，求你给我们粮食，我们为什么死在你面前呢？”"},
{volumn:         1,chapter:        47,versenumber:        16,verse:"约瑟说：“若是银子用尽了，可以把你们的牲畜给我，我就为你们的牲畜给你们粮食。”"},
{volumn:         1,chapter:        47,versenumber:        17,verse:"于是他们把牲畜赶到约瑟那里，约瑟就拿粮食换了他们的牛、羊、驴、马；那一年因换他们一切的牲畜，就用粮食养活他们。"},
{volumn:         1,chapter:        47,versenumber:        18,verse:"那一年过去，第二年他们又来见约瑟，说：“我们不瞒我主，我们的银子都花尽了，牲畜也都归了我主。我们在我主眼前，除了我们的身体和田地之外，一无所剩。"},
{volumn:         1,chapter:        47,versenumber:        19,verse:"你何忍见我们人死地荒呢？求你用粮食买我们和我们的地，我们和我们的地就要给法老效力。又求你给我们种子，使我们得以存活，不至死亡，地土也不至荒凉。”"},
{volumn:         1,chapter:        47,versenumber:        20,verse:"于是，约瑟为法老买了埃及所有的地，埃及人因被饥荒所迫，各都卖了自己的田地；那地就都归了法老。"},
{volumn:         1,chapter:        47,versenumber:        21,verse:"至于百姓，约瑟叫他们，从埃及这边直到埃及那边，都各归各城。"},
{volumn:         1,chapter:        47,versenumber:        22,verse:"惟有祭司的地，约瑟没有买，因为祭司有从法老所得的常俸。他们吃法老所给的常俸，所以他们不卖自己的地。"},
{volumn:         1,chapter:        47,versenumber:        23,verse:"约瑟对百姓说：“我今日为法老买了你们和你们的地，看哪，这里有种子给你们，你们可以种地。"},
{volumn:         1,chapter:        47,versenumber:        24,verse:"后来打粮食的时候，你们要把五分之一纳给法老，四分可以归你们做地里的种子，也做你们和你们家口孩童的食物。”"},
{volumn:         1,chapter:        47,versenumber:        25,verse:"他们说：“你救了我们的性命。但愿我们在我主眼前蒙恩，我们就作法老的仆人。”"},
{volumn:         1,chapter:        47,versenumber:        26,verse:"于是约瑟为埃及地定下常例，直到今日：法老必得五分之一，惟独祭司的地不归法老。"},
{volumn:         1,chapter:        47,versenumber:        27,verse:"以色列人住在埃及的歌珊地。他们在那里置了产业，并且生育甚多。"},
{volumn:         1,chapter:        47,versenumber:        28,verse:"雅各住在埃及地十七年，雅各平生的年日是一百四十七岁。"},
{volumn:         1,chapter:        47,versenumber:        29,verse:"以色列的死期临近了，他就叫了他儿子约瑟来，说：“我若在你眼前蒙恩，请你把手放在我大腿底下，用慈爱和诚实待我，请你不要将我葬在埃及。"},
{volumn:         1,chapter:        47,versenumber:        30,verse:"我与我祖我父同睡的时候，你要将我带出埃及，葬在他们所葬的地方。”约瑟说：“我必遵着你的命而行。”"},
{volumn:         1,chapter:        47,versenumber:        31,verse:"雅各说：“你要向我起誓。”约瑟就向他起了誓，于是以色列在床头上（或译：扶着杖头）敬拜　神。"},
{volumn:         1,chapter:        48,versenumber:         1,verse:"这事以后，有人告诉约瑟说：“你的父亲病了。”他就带着两个儿子玛拿西和以法莲同去。"},
{volumn:         1,chapter:        48,versenumber:         2,verse:"有人告诉雅各说：“请看，你儿子约瑟到你这里来了。”以色列就勉强在床上坐起来。"},
{volumn:         1,chapter:        48,versenumber:         3,verse:"雅各对约瑟说：“全能的　神曾在迦南地的路斯向我显现，赐福与我，"},
{volumn:         1,chapter:        48,versenumber:         4,verse:"对我说：‘我必使你生养众多，成为多民，又要把这地赐给你的后裔，永远为业。’"},
{volumn:         1,chapter:        48,versenumber:         5,verse:"我未到埃及见你之先，你在埃及地所生的以法莲和玛拿西这两个儿子是我的，正如流便和西缅是我的一样。"},
{volumn:         1,chapter:        48,versenumber:         6,verse:"你在他们以后所生的就是你的，他们可以归于他们弟兄的名下得产业。"},
{volumn:         1,chapter:        48,versenumber:         7,verse:"至于我，我从巴旦来的时候，拉结死在我眼前，在迦南地的路上，离以法他还有一段路程，我就把她葬在以法他的路上（以法他就是伯利恒）。”"},
{volumn:         1,chapter:        48,versenumber:         8,verse:"以色列看见约瑟的两个儿子，就说：“这是谁？”"},
{volumn:         1,chapter:        48,versenumber:         9,verse:"约瑟对他父亲说：“这是　神在这里赐给我的儿子。”以色列说：“请你领他们到我跟前，我要给他们祝福。”"},
{volumn:         1,chapter:        48,versenumber:        10,verse:"以色列年纪老迈，眼睛昏花，不能看见。约瑟领他们到他跟前，他就和他们亲嘴，抱着他们。"},
{volumn:         1,chapter:        48,versenumber:        11,verse:"以色列对约瑟说：“我想不到得见你的面，不料，　神又使我得见你的儿子。”"},
{volumn:         1,chapter:        48,versenumber:        12,verse:"约瑟把两个儿子从以色列两膝中领出来，自己就脸伏于地下拜。"},
{volumn:         1,chapter:        48,versenumber:        13,verse:"随后，约瑟又拉着他们两个，以法莲在他的右手里，对着以色列的左手，玛拿西在他的左手里，对着以色列的右手，领他们到以色列的跟前。"},
{volumn:         1,chapter:        48,versenumber:        14,verse:"以色列伸出右手来，按在以法莲的头上（以法莲乃是次子），又剪搭过左手来，按在玛拿西的头上（玛拿西原是长子）。"},
{volumn:         1,chapter:        48,versenumber:        15,verse:"他就给约瑟祝福说：“愿我祖亚伯拉罕和我父以撒所侍奉的　神，就是一生牧养我直到今日的　神，"},
{volumn:         1,chapter:        48,versenumber:        16,verse:"救赎我脱离一切患难的那使者，赐福与这两个童子。愿他们归在我的名下和我祖亚伯拉罕、我父以撒的名下。又愿他们在世界中生养众多。”"},
{volumn:         1,chapter:        48,versenumber:        17,verse:"约瑟见他父亲把右手按在以法莲的头上，就不喜悦，便提起他父亲的手，要从以法莲头上挪到玛拿西的头上。"},
{volumn:         1,chapter:        48,versenumber:        18,verse:"约瑟对他父亲说：“我父，不是这样。这本是长子，求你把右手按在他的头上。”"},
{volumn:         1,chapter:        48,versenumber:        19,verse:"他父亲不从，说：“我知道，我儿，我知道。他也必成为一族，也必昌大。只是他的兄弟将来比他还大；他兄弟的后裔要成为多族。”"},
{volumn:         1,chapter:        48,versenumber:        20,verse:"当日就给他们祝福说：“以色列人要指着你们祝福说：‘愿　神使你如以法莲、玛拿西一样。’”于是立以法莲在玛拿西以上。"},
{volumn:         1,chapter:        48,versenumber:        21,verse:"以色列又对约瑟说：“我要死了，但　神必与你们同在，领你们回到你们列祖之地。"},
{volumn:         1,chapter:        48,versenumber:        22,verse:"并且我从前用弓用刀从亚摩利人手下夺的那块地，我都赐给你，使你比众弟兄多得一份。”"},
{volumn:         1,chapter:        49,versenumber:         1,verse:"雅各叫了他的儿子们来，说：“你们都来聚集，我好把你们日后必遇的事告诉你们。"},
{volumn:         1,chapter:        49,versenumber:         2,verse:"雅各的儿子们，你们要聚集而听，要听你们父亲以色列的话。"},
{volumn:         1,chapter:        49,versenumber:         3,verse:"“流便哪，你是我的长子，是我力量强壮的时候生的，本当大有尊荣，权力超众。"},
{volumn:         1,chapter:        49,versenumber:         4,verse:"但你放纵情欲，滚沸如水，必不得居首位；因为你上了你父亲的床，污秽了我的榻。"},
{volumn:         1,chapter:        49,versenumber:         5,verse:"“西缅和利未是弟兄；他们的刀剑是残忍的器具。"},
{volumn:         1,chapter:        49,versenumber:         6,verse:"我的灵啊，不要与他们同谋；我的心哪，不要与他们联络；因为他们趁怒杀害人命，任意砍断牛腿大筋。"},
{volumn:         1,chapter:        49,versenumber:         7,verse:"他们的怒气暴烈可咒；他们的忿恨残忍可诅。我要使他们分居在雅各家里，散住在以色列地中。"},
{volumn:         1,chapter:        49,versenumber:         8,verse:"“犹大啊，你弟兄们必赞美你；你手必掐住仇敌的颈项；你父亲的儿子们必向你下拜。"},
{volumn:         1,chapter:        49,versenumber:         9,verse:"犹大是个小狮子；我儿啊，你抓了食便上去。你屈下身去，卧如公狮，蹲如母狮，谁敢惹你？"},
{volumn:         1,chapter:        49,versenumber:        10,verse:"圭必不离犹大，杖必不离他两脚之间，直等细罗（就是赐平安者）来到，万民都必归顺。"},
{volumn:         1,chapter:        49,versenumber:        11,verse:"犹大把小驴拴在葡萄树上，把驴驹拴在美好的葡萄树上。他在葡萄酒中洗了衣服，在葡萄汁中洗了袍褂。"},
{volumn:         1,chapter:        49,versenumber:        12,verse:"他的眼睛必因酒红润；他的牙齿必因奶白亮。"},
{volumn:         1,chapter:        49,versenumber:        13,verse:"“西布伦必住在海口，必成为停船的海口；他的境界必延到西顿。"},
{volumn:         1,chapter:        49,versenumber:        14,verse:"“以萨迦是个强壮的驴，卧在羊圈之中。"},
{volumn:         1,chapter:        49,versenumber:        15,verse:"他以安静为佳，以肥地为美，便低肩背重，成为服苦的仆人。"},
{volumn:         1,chapter:        49,versenumber:        16,verse:"“但必判断他的民，作以色列支派之一。"},
{volumn:         1,chapter:        49,versenumber:        17,verse:"但必作道上的蛇，路中的虺，咬伤马蹄，使骑马的坠落于后。"},
{volumn:         1,chapter:        49,versenumber:        18,verse:"耶和华啊，我向来等候你的救恩。"},
{volumn:         1,chapter:        49,versenumber:        19,verse:"“迦得必被敌军追逼，他却要追逼他们的脚跟。"},
{volumn:         1,chapter:        49,versenumber:        20,verse:"“亚设之地必出肥美的粮食，且出君王的美味。"},
{volumn:         1,chapter:        49,versenumber:        21,verse:"“拿弗他利是被释放的母鹿；他出嘉美的言语。"},
{volumn:         1,chapter:        49,versenumber:        22,verse:"“约瑟是多结果子的树枝，是泉旁多结果的枝子；他的枝条探出墙外。"},
{volumn:         1,chapter:        49,versenumber:        23,verse:"弓箭手将他苦害，向他射箭，逼迫他。"},
{volumn:         1,chapter:        49,versenumber:        24,verse:"但他的弓仍旧坚硬；他的手健壮敏捷。这是因以色列的牧者，以色列的磐石，就是雅各的大能者。"},
{volumn:         1,chapter:        49,versenumber:        25,verse:"你父亲的　神必帮助你；那全能者必将天上所有的福，地里所藏的福，以及生产乳养的福，都赐给你。"},
{volumn:         1,chapter:        49,versenumber:        26,verse:"你父亲所祝的福胜过我祖先所祝的福，如永世的山岭，至极的边界；这些福必降在约瑟的头上，临到那与弟兄迥别之人的顶上。"},
{volumn:         1,chapter:        49,versenumber:        27,verse:"“便雅悯是个撕掠的狼，早晨要吃他所抓的，晚上要分他所夺的。”"},
{volumn:         1,chapter:        49,versenumber:        28,verse:"这一切是以色列的十二支派；这也是他们的父亲对他们所说的话，为他们所祝的福，都是按着各人的福分为他们祝福。"},
{volumn:         1,chapter:        49,versenumber:        29,verse:"他又嘱咐他们说：“我将要归到我列祖（原文是本民）那里，你们要将我葬在赫人以弗仑田间的洞里，与我祖我父在一处，"},
{volumn:         1,chapter:        49,versenumber:        30,verse:"就是在迦南地幔利前、麦比拉田间的洞；那洞和田是亚伯拉罕向赫人以弗仑买来为业，作坟地的。"},
{volumn:         1,chapter:        49,versenumber:        31,verse:"他们在那里葬了亚伯拉罕和他妻子撒拉，又在那里葬了以撒和他的妻子利百加；我也在那里葬了利亚。"},
{volumn:         1,chapter:        49,versenumber:        32,verse:"那块田和田间的洞原是向赫人买的。”"},
{volumn:         1,chapter:        49,versenumber:        33,verse:"雅各嘱咐众子已毕，就把脚收在床上，气绝而死，归他列祖（原文是本民）那里去了。"},
{volumn:         1,chapter:        50,versenumber:         1,verse:"约瑟伏在他父亲的面上哀哭，与他亲嘴。"},
{volumn:         1,chapter:        50,versenumber:         2,verse:"约瑟吩咐伺候他的医生用香料薰他父亲，医生就用香料薰了以色列。"},
{volumn:         1,chapter:        50,versenumber:         3,verse:"薰尸的常例是四十天；那四十天满了，埃及人为他哀哭了七十天。"},
{volumn:         1,chapter:        50,versenumber:         4,verse:"为他哀哭的日子过了，约瑟对法老家中的人说：“我若在你们眼前蒙恩，请你们报告法老说："},
{volumn:         1,chapter:        50,versenumber:         5,verse:"‘我父亲要死的时候叫我起誓说：你要将我葬在迦南地，在我为自己所掘的坟墓里。’现在求你让我上去葬我父亲，以后我必回来。”"},
{volumn:         1,chapter:        50,versenumber:         6,verse:"法老说：“你可以上去，照着你父亲叫你起的誓，将他葬埋。”"},
{volumn:         1,chapter:        50,versenumber:         7,verse:"于是约瑟上去葬他父亲。与他一同上去的，有法老的臣仆和法老家中的长老，并埃及国的长老，"},
{volumn:         1,chapter:        50,versenumber:         8,verse:"还有约瑟的全家和他的弟兄们，并他父亲的眷属；只有他们的妇人孩子，和羊群牛群，都留在歌珊地。"},
{volumn:         1,chapter:        50,versenumber:         9,verse:"又有车辆马兵，和他一同上去；那一帮人甚多。"},
{volumn:         1,chapter:        50,versenumber:        10,verse:"他们到了约旦河外、亚达的禾场，就在那里大大地号啕痛哭。约瑟为他父亲哀哭了七天。"},
{volumn:         1,chapter:        50,versenumber:        11,verse:"迦南的居民见亚达禾场上的哀哭，就说：“这是埃及人一场极大的哀哭。”因此那地方名叫亚伯·麦西，是在约旦河东。"},
{volumn:         1,chapter:        50,versenumber:        12,verse:"雅各的儿子们就遵着他父亲所吩咐的办了，"},
{volumn:         1,chapter:        50,versenumber:        13,verse:"把他搬到迦南地，葬在幔利前、麦比拉田间的洞里；那洞和田是亚伯拉罕向赫人以弗仑买来为业，作坟地的。"},
{volumn:         1,chapter:        50,versenumber:        14,verse:"约瑟葬了他父亲以后，就和众弟兄，并一切同他上去葬他父亲的人，都回埃及去了。"},
{volumn:         1,chapter:        50,versenumber:        15,verse:"约瑟的哥哥们见父亲死了，就说：“或者约瑟怀恨我们，照着我们从前待他一切的恶足足地报复我们。”"},
{volumn:         1,chapter:        50,versenumber:        16,verse:"他们就打发人去见约瑟，说：“你父亲未死以先吩咐说："},
{volumn:         1,chapter:        50,versenumber:        17,verse:"‘你们要对约瑟这样说：从前你哥哥们恶待你，求你饶恕他们的过犯和罪恶。’如今求你饶恕你父亲　神之仆人的过犯。”他们对约瑟说这话，约瑟就哭了。"},
{volumn:         1,chapter:        50,versenumber:        18,verse:"他的哥哥们又来俯伏在他面前，说：“我们是你的仆人。”"},
{volumn:         1,chapter:        50,versenumber:        19,verse:"约瑟对他们说：“不要害怕，我岂能代替　神呢？"},
{volumn:         1,chapter:        50,versenumber:        20,verse:"从前你们的意思是要害我，但　神的意思原是好的，要保全许多人的性命，成就今日的光景。"},
{volumn:         1,chapter:        50,versenumber:        21,verse:"现在你们不要害怕，我必养活你们和你们的妇人孩子。”于是约瑟用亲爱的话安慰他们。"},
{volumn:         1,chapter:        50,versenumber:        22,verse:"约瑟和他父亲的眷属都住在埃及。约瑟活了一百一十岁。"},
{volumn:         1,chapter:        50,versenumber:        23,verse:"约瑟得见以法莲第三代的子孙。玛拿西的孙子、玛吉的儿子也养在约瑟的膝上。"},
{volumn:         1,chapter:        50,versenumber:        24,verse:"约瑟对他弟兄们说：“我要死了，但　神必定看顾你们，领你们从这地上去，到他起誓所应许给亚伯拉罕、以撒、雅各之地。”"},
{volumn:         1,chapter:        50,versenumber:        25,verse:"约瑟叫以色列的子孙起誓说：“　神必定看顾你们；你们要把我的骸骨从这里搬上去。”"},
{volumn:         1,chapter:        50,versenumber:        26,verse:"约瑟死了，正一百一十岁。人用香料将他薰了，把他收殓在棺材里，停在埃及。"},
{volumn:         2,chapter:         1,versenumber:         1,verse:"以色列的众子，各带家眷，和雅各一同来到埃及。他们的名字记在下面。"},
{volumn:         2,chapter:         1,versenumber:         2,verse:"有流便、西缅、利未、犹大、"},
{volumn:         2,chapter:         1,versenumber:         3,verse:"以萨迦、西布伦、便雅悯、"},
{volumn:         2,chapter:         1,versenumber:         4,verse:"但、拿弗他利、迦得、亚设。"},
{volumn:         2,chapter:         1,versenumber:         5,verse:"凡从雅各而生的，共有七十人。约瑟已经在埃及。"},
{volumn:         2,chapter:         1,versenumber:         6,verse:"约瑟和他的弟兄，并那一代的人，都死了。"},
{volumn:         2,chapter:         1,versenumber:         7,verse:"以色列人生养众多，并且繁茂，极其强盛，满了那地。"},
{volumn:         2,chapter:         1,versenumber:         8,verse:"有不认识约瑟的新王起来，治理埃及，"},
{volumn:         2,chapter:         1,versenumber:         9,verse:"对他的百姓说：“看哪，这以色列民比我们还多，又比我们强盛。"},
{volumn:         2,chapter:         1,versenumber:        10,verse:"来吧，我们不如用巧计待他们，恐怕他们多起来，日后若遇什么争战的事，就连合我们的仇敌攻击我们，离开这地去了。”"},
{volumn:         2,chapter:         1,versenumber:        11,verse:"于是埃及人派督工的辖制他们，加重担苦害他们。他们为法老建造两座积货城，就是比东和兰塞。"},
{volumn:         2,chapter:         1,versenumber:        12,verse:"只是越发苦害他们，他们越发多起来，越发蔓延；埃及人就因以色列人愁烦。"},
{volumn:         2,chapter:         1,versenumber:        13,verse:"埃及人严严地使以色列人做工，"},
{volumn:         2,chapter:         1,versenumber:        14,verse:"使他们因做苦工觉得命苦；无论是和泥，是做砖，是做田间各样的工，在一切的工上都严严地待他们。"},
{volumn:         2,chapter:         1,versenumber:        15,verse:"有希伯来的两个收生婆，一名施弗拉，一名普阿；埃及王对她们说："},
{volumn:         2,chapter:         1,versenumber:        16,verse:"“你们为希伯来妇人收生，看她们临盆的时候，若是男孩，就把他杀了；若是女孩，就留她存活。”"},
{volumn:         2,chapter:         1,versenumber:        17,verse:"但是收生婆敬畏　神，不照埃及王的吩咐行，竟存留男孩的性命。"},
{volumn:         2,chapter:         1,versenumber:        18,verse:"埃及王召了收生婆来，说：“你们为什么做这事，存留男孩的性命呢？”"},
{volumn:         2,chapter:         1,versenumber:        19,verse:"收生婆对法老说：“因为希伯来妇人与埃及妇人不同；希伯来妇人本是健壮的（原文是活泼的），收生婆还没有到，她们已经生产了。”"},
{volumn:         2,chapter:         1,versenumber:        20,verse:"神厚待收生婆。以色列人多起来，极其强盛。"},
{volumn:         2,chapter:         1,versenumber:        21,verse:"收生婆因为敬畏　神，　神便叫她们成立家室。"},
{volumn:         2,chapter:         1,versenumber:        22,verse:"法老吩咐他的众民说：“以色列人所生的男孩，你们都要丢在河里；一切的女孩，你们要存留她的性命。”"},
{volumn:         2,chapter:         2,versenumber:         1,verse:"有一个利未家的人娶了一个利未女子为妻。"},
{volumn:         2,chapter:         2,versenumber:         2,verse:"那女人怀孕，生一个儿子，见他俊美，就藏了他三个月，"},
{volumn:         2,chapter:         2,versenumber:         3,verse:"后来不能再藏，就取了一个蒲草箱，抹上石漆和石油，将孩子放在里头，把箱子搁在河边的芦荻中。"},
{volumn:         2,chapter:         2,versenumber:         4,verse:"孩子的姐姐远远站着，要知道他究竟怎么样。"},
{volumn:         2,chapter:         2,versenumber:         5,verse:"法老的女儿来到河边洗澡，她的使女们在河边行走。她看见箱子在芦荻中，就打发一个婢女拿来。"},
{volumn:         2,chapter:         2,versenumber:         6,verse:"她打开箱子，看见那孩子。孩子哭了，她就可怜他，说：“这是希伯来人的一个孩子。”"},
{volumn:         2,chapter:         2,versenumber:         7,verse:"孩子的姐姐对法老的女儿说：“我去在希伯来妇人中叫一个奶妈来，为你奶这孩子，可以不可以？”"},
{volumn:         2,chapter:         2,versenumber:         8,verse:"法老的女儿说：“可以。”童女就去叫了孩子的母亲来。"},
{volumn:         2,chapter:         2,versenumber:         9,verse:"法老的女儿对她说：“你把这孩子抱去，为我奶他，我必给你工价。”妇人就抱了孩子去奶他。"},
{volumn:         2,chapter:         2,versenumber:        10,verse:"孩子渐长，妇人把他带到法老的女儿那里，就作了她的儿子。她给孩子起名叫摩西，意思说：“因我把他从水里拉出来。”"},
{volumn:         2,chapter:         2,versenumber:        11,verse:"后来，摩西长大，他出去到他弟兄那里，看他们的重担，见一个埃及人打希伯来人的一个弟兄。"},
{volumn:         2,chapter:         2,versenumber:        12,verse:"他左右观看，见没有人，就把埃及人打死了，藏在沙土里。"},
{volumn:         2,chapter:         2,versenumber:        13,verse:"第二天他出去，见有两个希伯来人争斗，就对那欺负人的说：“你为什么打你同族的人呢？”"},
{volumn:         2,chapter:         2,versenumber:        14,verse:"那人说：“谁立你作我们的首领和审判官呢？难道你要杀我，像杀那埃及人吗？”摩西便惧怕，说：“这事必是被人知道了。”"},
{volumn:         2,chapter:         2,versenumber:        15,verse:"法老听见这事，就想杀摩西，但摩西躲避法老，逃往米甸地居住。"},
{volumn:         2,chapter:         2,versenumber:        16,verse:"一日，他在井旁坐下。米甸的祭司有七个女儿；她们来打水，打满了槽，要饮父亲的群羊。"},
{volumn:         2,chapter:         2,versenumber:        17,verse:"有牧羊的人来，把她们赶走了，摩西却起来帮助她们，又饮了她们的群羊。"},
{volumn:         2,chapter:         2,versenumber:        18,verse:"她们来到父亲流珥那里；他说：“今日你们为何来得这么快呢？”"},
{volumn:         2,chapter:         2,versenumber:        19,verse:"她们说：“有一个埃及人救我们脱离牧羊人的手，并且为我们打水饮了群羊。”"},
{volumn:         2,chapter:         2,versenumber:        20,verse:"他对女儿们说：“那个人在哪里？你们为什么撇下他呢？你们去请他来吃饭。”"},
{volumn:         2,chapter:         2,versenumber:        21,verse:"摩西甘心和那人同住；那人把他的女儿西坡拉给摩西为妻。"},
{volumn:         2,chapter:         2,versenumber:        22,verse:"西坡拉生了一个儿子，摩西给他起名叫革舜，意思说：“因我在外邦作了寄居的。”"},
{volumn:         2,chapter:         2,versenumber:        23,verse:"过了多年，埃及王死了。以色列人因做苦工，就叹息哀求，他们的哀声达于　神。"},
{volumn:         2,chapter:         2,versenumber:        24,verse:"神听见他们的哀声，就记念他与亚伯拉罕、以撒、雅各所立的约。"},
{volumn:         2,chapter:         2,versenumber:        25,verse:"神看顾以色列人，也知道他们的苦情。"},
{volumn:         2,chapter:         3,versenumber:         1,verse:"摩西牧养他岳父米甸祭司叶忒罗的羊群；一日领羊群往野外去，到了　神的山，就是何烈山。"},
{volumn:         2,chapter:         3,versenumber:         2,verse:"耶和华的使者从荆棘里火焰中向摩西显现。摩西观看，不料，荆棘被火烧着，却没有烧毁。"},
{volumn:         2,chapter:         3,versenumber:         3,verse:"摩西说：“我要过去看这大异象，这荆棘为何没有烧坏呢？”"},
{volumn:         2,chapter:         3,versenumber:         4,verse:"耶和华　神见他过去要看，就从荆棘里呼叫说：“摩西！摩西！”他说：“我在这里。”"},
{volumn:         2,chapter:         3,versenumber:         5,verse:"神说：“不要近前来。当把你脚上的鞋脱下来，因为你所站之地是圣地”；"},
{volumn:         2,chapter:         3,versenumber:         6,verse:"又说：“我是你父亲的　神，是亚伯拉罕的　神，以撒的　神，雅各的　神。”摩西蒙上脸，因为怕看　神。"},
{volumn:         2,chapter:         3,versenumber:         7,verse:"耶和华说：“我的百姓在埃及所受的困苦，我实在看见了；他们因受督工的辖制所发的哀声，我也听见了。我原知道他们的痛苦，"},
{volumn:         2,chapter:         3,versenumber:         8,verse:"我下来是要救他们脱离埃及人的手，领他们出了那地，到美好、宽阔、流奶与蜜之地，就是到迦南人、赫人、亚摩利人、比利洗人、希未人、耶布斯人之地。"},
{volumn:         2,chapter:         3,versenumber:         9,verse:"现在以色列人的哀声达到我耳中，我也看见埃及人怎样欺压他们。"},
{volumn:         2,chapter:         3,versenumber:        10,verse:"故此，我要打发你去见法老，使你可以将我的百姓以色列人从埃及领出来。”"},
{volumn:         2,chapter:         3,versenumber:        11,verse:"摩西对　神说：“我是什么人，竟能去见法老，将以色列人从埃及领出来呢？”"},
{volumn:         2,chapter:         3,versenumber:        12,verse:"神说：“我必与你同在。你将百姓从埃及领出来之后，你们必在这山上侍奉我；这就是我打发你去的证据。”"},
{volumn:         2,chapter:         3,versenumber:        13,verse:"摩西对　神说：“我到以色列人那里，对他们说：‘你们祖宗的　神打发我到你们这里来。’他们若问我说：‘他叫什么名字？’我要对他们说什么呢？”"},
{volumn:         2,chapter:         3,versenumber:        14,verse:"神对摩西说：“我是自有永有的”；又说：“你要对以色列人这样说：‘那自有的打发我到你们这里来。’”"},
{volumn:         2,chapter:         3,versenumber:        15,verse:"神又对摩西说：“你要对以色列人这样说：‘耶和华你们祖宗的　神，就是亚伯拉罕的　神，以撒的　神，雅各的　神，打发我到你们这里来。’耶和华是我的名，直到永远；这也是我的纪念，直到万代。"},
{volumn:         2,chapter:         3,versenumber:        16,verse:"你去招聚以色列的长老，对他们说：‘耶和华你们祖宗的　神，就是亚伯拉罕的　神，以撒的　神，雅各的　神，向我显现，说：我实在眷顾了你们，我也看见埃及人怎样待你们。"},
{volumn:         2,chapter:         3,versenumber:        17,verse:"我也说：要将你们从埃及的困苦中领出来，往迦南人、赫人、亚摩利人、比利洗人、希未人、耶布斯人的地去，就是到流奶与蜜之地。’"},
{volumn:         2,chapter:         3,versenumber:        18,verse:"他们必听你的话。你和以色列的长老要去见埃及王，对他说：‘耶和华希伯来人的　神遇见了我们，现在求你容我们往旷野去，走三天的路程，为要祭祀耶和华我们的神。’"},
{volumn:         2,chapter:         3,versenumber:        19,verse:"我知道虽用大能的手，埃及王也不容你们去。"},
{volumn:         2,chapter:         3,versenumber:        20,verse:"我必伸手在埃及中间施行我一切的奇事，攻击那地，然后他才容你们去。"},
{volumn:         2,chapter:         3,versenumber:        21,verse:"我必叫你们在埃及人眼前蒙恩，你们去的时候就不至于空手而去。"},
{volumn:         2,chapter:         3,versenumber:        22,verse:"但各妇女必向她的邻舍，并居住在她家里的女人，要金器银器和衣裳，好给你们的儿女穿戴。这样你们就把埃及人的财物夺去了。”"},
{volumn:         2,chapter:         4,versenumber:         1,verse:"摩西回答说：“他们必不信我，也不听我的话，必说：‘耶和华并没有向你显现。’”"},
{volumn:         2,chapter:         4,versenumber:         2,verse:"耶和华对摩西说：“你手里是什么？”他说：“是杖。”"},
{volumn:         2,chapter:         4,versenumber:         3,verse:"耶和华说：“丢在地上。”他一丢下去，就变作蛇；摩西便跑开。"},
{volumn:         2,chapter:         4,versenumber:         4,verse:"耶和华对摩西说：“伸出手来，拿住它的尾巴，它必在你手中仍变为杖；"},
{volumn:         2,chapter:         4,versenumber:         5,verse:"如此好叫他们信耶和华他们祖宗的　神，就是亚伯拉罕的　神，以撒的　神，雅各的　神，是向你显现了。”"},
{volumn:         2,chapter:         4,versenumber:         6,verse:"耶和华又对他说：“把手放在怀里。”他就把手放在怀里，及至抽出来，不料，手长了大麻风，有雪那样白。"},
{volumn:         2,chapter:         4,versenumber:         7,verse:"耶和华说：“再把手放在怀里。”他就再把手放在怀里，及至从怀里抽出来，不料，手已经复原，与周身的肉一样；"},
{volumn:         2,chapter:         4,versenumber:         8,verse:"又说：“倘或他们不听你的话，也不信头一个神迹，他们必信第二个神迹。"},
{volumn:         2,chapter:         4,versenumber:         9,verse:"这两个神迹若都不信，也不听你的话，你就从河里取些水，倒在旱地上，你从河里取的水必在旱地上变作血。”"},
{volumn:         2,chapter:         4,versenumber:        10,verse:"摩西对耶和华说：“主啊，我素日不是能言的人，就是从你对仆人说话以后，也是这样。我本是拙口笨舌的。”"},
{volumn:         2,chapter:         4,versenumber:        11,verse:"耶和华对他说：“谁造人的口呢？谁使人口哑、耳聋、目明、眼瞎呢？岂不是我耶和华吗？"},
{volumn:         2,chapter:         4,versenumber:        12,verse:"现在去吧，我必赐你口才，指教你所当说的话。”"},
{volumn:         2,chapter:         4,versenumber:        13,verse:"摩西说：“主啊，你愿意打发谁，就打发谁去吧！”"},
{volumn:         2,chapter:         4,versenumber:        14,verse:"耶和华向摩西发怒说：“不是有你的哥哥利未人亚伦吗？我知道他是能言的；现在他出来迎接你，他一见你，心里就欢喜。"},
{volumn:         2,chapter:         4,versenumber:        15,verse:"你要将当说的话传给他；我也要赐你和他口才，又要指教你们所当行的事。"},
{volumn:         2,chapter:         4,versenumber:        16,verse:"他要替你对百姓说话；你要以他当作口，他要以你当作　神。"},
{volumn:         2,chapter:         4,versenumber:        17,verse:"你手里要拿这杖，好行神迹。”"},
{volumn:         2,chapter:         4,versenumber:        18,verse:"于是，摩西回到他岳父叶忒罗那里，对他说：“求你容我回去见我在埃及的弟兄，看他们还在不在。”叶忒罗对摩西说：“你可以平平安安地去吧！”"},
{volumn:         2,chapter:         4,versenumber:        19,verse:"耶和华在米甸对摩西说：“你要回埃及去，因为寻索你命的人都死了。”"},
{volumn:         2,chapter:         4,versenumber:        20,verse:"摩西就带着妻子和两个儿子，叫他们骑上驴，回埃及地去。摩西手里拿着　神的杖。"},
{volumn:         2,chapter:         4,versenumber:        21,verse:"耶和华对摩西说：“你回到埃及的时候，要留意将我指示你的一切奇事行在法老面前。但我要使（或译：任凭；下同）他的心刚硬，他必不容百姓去。"},
{volumn:         2,chapter:         4,versenumber:        22,verse:"你要对法老说：‘耶和华这样说：以色列是我的儿子，我的长子。"},
{volumn:         2,chapter:         4,versenumber:        23,verse:"我对你说过：容我的儿子去，好侍奉我。你还是不肯容他去。看哪，我要杀你的长子。’”"},
{volumn:         2,chapter:         4,versenumber:        24,verse:"摩西在路上住宿的地方，耶和华遇见他，想要杀他。"},
{volumn:         2,chapter:         4,versenumber:        25,verse:"西坡拉就拿一块火石，割下他儿子的阳皮，丢在摩西脚前，说：“你真是我的血郎了。”"},
{volumn:         2,chapter:         4,versenumber:        26,verse:"这样，耶和华才放了他。西坡拉说：“你因割礼就是血郎了。”"},
{volumn:         2,chapter:         4,versenumber:        27,verse:"耶和华对亚伦说：“你往旷野去迎接摩西。”他就去，在　神的山遇见摩西，和他亲嘴。"},
{volumn:         2,chapter:         4,versenumber:        28,verse:"摩西将耶和华打发他所说的言语和嘱咐他所行的神迹都告诉了亚伦。"},
{volumn:         2,chapter:         4,versenumber:        29,verse:"摩西、亚伦就去招聚以色列的众长老。"},
{volumn:         2,chapter:         4,versenumber:        30,verse:"亚伦将耶和华对摩西所说的一切话述说了一遍，又在百姓眼前行了那些神迹，"},
{volumn:         2,chapter:         4,versenumber:        31,verse:"百姓就信了。以色列人听见耶和华眷顾他们，鉴察他们的困苦，就低头下拜。"},
{volumn:         2,chapter:         5,versenumber:         1,verse:"后来摩西、亚伦去对法老说：“耶和华以色列的　神这样说：‘容我的百姓去，在旷野向我守节。’”"},
{volumn:         2,chapter:         5,versenumber:         2,verse:"法老说：“耶和华是谁，使我听他的话，容以色列人去呢？我不认识耶和华，也不容以色列人去！”"},
{volumn:         2,chapter:         5,versenumber:         3,verse:"他们说：“希伯来人的　神遇见了我们。求你容我们往旷野去，走三天的路程，祭祀耶和华我们的　神，免得他用瘟疫、刀兵攻击我们。”"},
{volumn:         2,chapter:         5,versenumber:         4,verse:"埃及王对他们说：“摩西、亚伦！你们为什么叫百姓旷工呢？你们去担你们的担子吧！”"},
{volumn:         2,chapter:         5,versenumber:         5,verse:"又说：“看哪，这地的以色列人如今众多，你们竟叫他们歇下担子！”"},
{volumn:         2,chapter:         5,versenumber:         6,verse:"当天，法老吩咐督工的和官长说："},
{volumn:         2,chapter:         5,versenumber:         7,verse:"“你们不可照常把草给百姓做砖，叫他们自己去捡草。"},
{volumn:         2,chapter:         5,versenumber:         8,verse:"他们素常做砖的数目，你们仍旧向他们要，一点不可减少；因为他们是懒惰的，所以呼求说：‘容我们去祭祀我们的　神。’"},
{volumn:         2,chapter:         5,versenumber:         9,verse:"你们要把更重的工夫加在这些人身上，叫他们劳碌，不听虚谎的言语。”"},
{volumn:         2,chapter:         5,versenumber:        10,verse:"督工的和官长出来对百姓说：“法老这样说：‘我不给你们草。"},
{volumn:         2,chapter:         5,versenumber:        11,verse:"你们自己在哪里能找草，就往那里去找吧！但你们的工一点不可减少。’”"},
{volumn:         2,chapter:         5,versenumber:        12,verse:"于是百姓散在埃及遍地，捡碎秸当作草。"},
{volumn:         2,chapter:         5,versenumber:        13,verse:"督工的催着说：“你们一天当完一天的工，与先前有草一样。”"},
{volumn:         2,chapter:         5,versenumber:        14,verse:"法老督工的，责打他所派以色列人的官长，说：“你们昨天今天为什么没有照向来的数目做砖、完你们的工作呢？”"},
{volumn:         2,chapter:         5,versenumber:        15,verse:"以色列人的官长就来哀求法老说：“为什么这样待你的仆人？"},
{volumn:         2,chapter:         5,versenumber:        16,verse:"督工的不把草给仆人，并且对我们说：‘做砖吧！’看哪，你仆人挨了打，其实是你百姓的错。”"},
{volumn:         2,chapter:         5,versenumber:        17,verse:"但法老说：“你们是懒惰的！你们是懒惰的！所以说：‘容我们去祭祀耶和华。’"},
{volumn:         2,chapter:         5,versenumber:        18,verse:"现在你们去做工吧！草是不给你们的，砖却要如数交纳。”"},
{volumn:         2,chapter:         5,versenumber:        19,verse:"以色列人的官长听说“你们每天做砖的工作一点不可减少”，就知道是遭遇祸患了。"},
{volumn:         2,chapter:         5,versenumber:        20,verse:"他们离了法老出来，正遇见摩西、亚伦站在对面，"},
{volumn:         2,chapter:         5,versenumber:        21,verse:"就向他们说：“愿耶和华鉴察你们，施行判断；因你们使我们在法老和他臣仆面前有了臭名，把刀递在他们手中杀我们。”"},
{volumn:         2,chapter:         5,versenumber:        22,verse:"摩西回到耶和华那里，说：“主啊，你为什么苦待这百姓呢？为什么打发我去呢？"},
{volumn:         2,chapter:         5,versenumber:        23,verse:"自从我去见法老，奉你的名说话，他就苦待这百姓，你一点也没有拯救他们。”"},
{volumn:         2,chapter:         6,versenumber:         1,verse:"耶和华对摩西说：“现在你必看见我向法老所行的事，使他因我大能的手容以色列人去，且把他们赶出他的地。”"},
{volumn:         2,chapter:         6,versenumber:         2,verse:"神晓谕摩西说：“我是耶和华。"},
{volumn:         2,chapter:         6,versenumber:         3,verse:"我从前向亚伯拉罕、以撒、雅各显现为全能的　神；至于我名耶和华，他们未曾知道。"},
{volumn:         2,chapter:         6,versenumber:         4,verse:"我与他们坚定所立的约，要把他们寄居的迦南地赐给他们。"},
{volumn:         2,chapter:         6,versenumber:         5,verse:"我也听见以色列人被埃及人苦待的哀声，我也记念我的约。"},
{volumn:         2,chapter:         6,versenumber:         6,verse:"所以你要对以色列人说：‘我是耶和华；我要用伸出来的膀臂重重地刑罚埃及人，救赎你们脱离他们的重担，不做他们的苦工。"},
{volumn:         2,chapter:         6,versenumber:         7,verse:"我要以你们为我的百姓，我也要作你们的　神。你们要知道我是耶和华你们的　神，是救你们脱离埃及人之重担的。"},
{volumn:         2,chapter:         6,versenumber:         8,verse:"我起誓应许给亚伯拉罕、以撒、雅各的那地，我要把你们领进去，将那地赐给你们为业。我是耶和华。’”"},
{volumn:         2,chapter:         6,versenumber:         9,verse:"摩西将这话告诉以色列人，只是他们因苦工愁烦，不肯听他的话。"},
{volumn:         2,chapter:         6,versenumber:        10,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:         6,versenumber:        11,verse:"“你进去对埃及王法老说，要容以色列人出他的地。”"},
{volumn:         2,chapter:         6,versenumber:        12,verse:"摩西在耶和华面前说：“以色列人尚且不听我的话，法老怎肯听我这拙口笨舌的人呢？”"},
{volumn:         2,chapter:         6,versenumber:        13,verse:"耶和华吩咐摩西、亚伦往以色列人和埃及王法老那里去，把以色列人从埃及地领出来。"},
{volumn:         2,chapter:         6,versenumber:        14,verse:"以色列人家长的名字记在下面。以色列长子流便的儿子是哈诺、法路、希斯伦、迦米；这是流便的各家。"},
{volumn:         2,chapter:         6,versenumber:        15,verse:"西缅的儿子是耶母利、雅悯、阿辖、雅斤、琐辖，和迦南女子的儿子扫罗；这是西缅的各家。"},
{volumn:         2,chapter:         6,versenumber:        16,verse:"利未众子的名字按着他们的后代记在下面：就是革顺、哥辖、米拉利。利未一生的岁数是一百三十七岁。"},
{volumn:         2,chapter:         6,versenumber:        17,verse:"革顺的儿子按着家室是立尼、示每。"},
{volumn:         2,chapter:         6,versenumber:        18,verse:"哥辖的儿子是暗兰、以斯哈、希伯伦、乌薛。哥辖一生的岁数是一百三十三岁。"},
{volumn:         2,chapter:         6,versenumber:        19,verse:"米拉利的儿子是抹利和母示；这是利未的家，都按着他们的后代。"},
{volumn:         2,chapter:         6,versenumber:        20,verse:"暗兰娶了他父亲的妹妹约基别为妻，她给他生了亚伦和摩西。暗兰一生的岁数是一百三十七岁。"},
{volumn:         2,chapter:         6,versenumber:        21,verse:"以斯哈的儿子是可拉、尼斐、细基利。"},
{volumn:         2,chapter:         6,versenumber:        22,verse:"乌薛的儿子是米沙利、以利撒反、西提利。"},
{volumn:         2,chapter:         6,versenumber:        23,verse:"亚伦娶了亚米拿达的女儿，拿顺的妹妹，以利沙巴为妻，她给他生了拿答、亚比户、以利亚撒、以他玛。"},
{volumn:         2,chapter:         6,versenumber:        24,verse:"可拉的儿子是亚惜、以利加拿、亚比亚撒；这是可拉的各家。"},
{volumn:         2,chapter:         6,versenumber:        25,verse:"亚伦的儿子以利亚撒娶了普铁的一个女儿为妻，她给他生了非尼哈。这是利未人的家长，都按着他们的家。"},
{volumn:         2,chapter:         6,versenumber:        26,verse:"耶和华说：“将以色列人按着他们的军队从埃及地领出来。”这是对那亚伦、摩西说的。"},
{volumn:         2,chapter:         6,versenumber:        27,verse:"对埃及王法老说要将以色列人从埃及领出来的，就是这摩西、亚伦。"},
{volumn:         2,chapter:         6,versenumber:        28,verse:"当耶和华在埃及地对摩西说话的日子，"},
{volumn:         2,chapter:         6,versenumber:        29,verse:"他向摩西说：“我是耶和华；我对你说的一切话，你都要告诉埃及王法老。”"},
{volumn:         2,chapter:         6,versenumber:        30,verse:"摩西在耶和华面前说：“看哪，我是拙口笨舌的人，法老怎肯听我呢？”"},
{volumn:         2,chapter:         7,versenumber:         1,verse:"耶和华对摩西说：“我使你在法老面前代替　神，你的哥哥亚伦是替你说话的。"},
{volumn:         2,chapter:         7,versenumber:         2,verse:"凡我所吩咐你的，你都要说。你的哥哥亚伦要对法老说，容以色列人出他的地。"},
{volumn:         2,chapter:         7,versenumber:         3,verse:"我要使法老的心刚硬，也要在埃及地多行神迹奇事。"},
{volumn:         2,chapter:         7,versenumber:         4,verse:"但法老必不听你们；我要伸手重重地刑罚埃及，将我的军队以色列民从埃及地领出来。"},
{volumn:         2,chapter:         7,versenumber:         5,verse:"我伸手攻击埃及，将以色列人从他们中间领出来的时候，埃及人就要知道我是耶和华。”"},
{volumn:         2,chapter:         7,versenumber:         6,verse:"摩西、亚伦这样行；耶和华怎样吩咐他们，他们就照样行了。"},
{volumn:         2,chapter:         7,versenumber:         7,verse:"摩西、亚伦与法老说话的时候，摩西八十岁，亚伦八十三岁。"},
{volumn:         2,chapter:         7,versenumber:         8,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         2,chapter:         7,versenumber:         9,verse:"“法老若对你们说：‘你们行件奇事吧！’你就吩咐亚伦说：‘把杖丢在法老面前，使杖变作蛇。’”"},
{volumn:         2,chapter:         7,versenumber:        10,verse:"摩西、亚伦进去见法老，就照耶和华所吩咐的行。亚伦把杖丢在法老和臣仆面前，杖就变作蛇。"},
{volumn:         2,chapter:         7,versenumber:        11,verse:"于是法老召了博士和术士来；他们是埃及行法术的，也用邪术照样而行。"},
{volumn:         2,chapter:         7,versenumber:        12,verse:"他们各人丢下自己的杖，杖就变作蛇；但亚伦的杖吞了他们的杖。"},
{volumn:         2,chapter:         7,versenumber:        13,verse:"法老心里刚硬，不肯听从摩西、亚伦，正如耶和华所说的。"},
{volumn:         2,chapter:         7,versenumber:        14,verse:"耶和华对摩西说：“法老心里固执，不肯容百姓去。"},
{volumn:         2,chapter:         7,versenumber:        15,verse:"明日早晨，他出来往水边去，你要往河边迎接他，手里要拿着那变过蛇的杖，"},
{volumn:         2,chapter:         7,versenumber:        16,verse:"对他说：‘耶和华希伯来人的　神打发我来见你，说：容我的百姓去，好在旷野侍奉我。到如今你还是不听。"},
{volumn:         2,chapter:         7,versenumber:        17,verse:"耶和华这样说：我要用我手里的杖击打河中的水，水就变作血；因此，你必知道我是耶和华。"},
{volumn:         2,chapter:         7,versenumber:        18,verse:"河里的鱼必死，河也要腥臭，埃及人就要厌恶吃这河里的水。’”"},
{volumn:         2,chapter:         7,versenumber:        19,verse:"耶和华晓谕摩西说：“你对亚伦说：‘把你的杖伸在埃及所有的水以上，就是在他们的江、河、池、塘以上，叫水都变作血。在埃及遍地，无论在木器中，石器中，都必有血。’”"},
{volumn:         2,chapter:         7,versenumber:        20,verse:"摩西、亚伦就照耶和华所吩咐的行。亚伦在法老和臣仆眼前举杖击打河里的水，河里的水都变作血了。"},
{volumn:         2,chapter:         7,versenumber:        21,verse:"河里的鱼死了，河也腥臭了，埃及人就不能吃这河里的水；埃及遍地都有了血。"},
{volumn:         2,chapter:         7,versenumber:        22,verse:"埃及行法术的，也用邪术照样而行。法老心里刚硬，不肯听摩西、亚伦，正如耶和华所说的。"},
{volumn:         2,chapter:         7,versenumber:        23,verse:"法老转身进宫，也不把这事放在心上。"},
{volumn:         2,chapter:         7,versenumber:        24,verse:"埃及人都在河的两边挖地，要得水喝，因为他们不能喝这河里的水。"},
{volumn:         2,chapter:         7,versenumber:        25,verse:"耶和华击打河以后满了七天。"},
{volumn:         2,chapter:         8,versenumber:         1,verse:"耶和华吩咐摩西说：“你进去见法老，对他说：‘耶和华这样说：容我的百姓去，好侍奉我。"},
{volumn:         2,chapter:         8,versenumber:         2,verse:"你若不肯容他们去，我必使青蛙糟蹋你的四境。"},
{volumn:         2,chapter:         8,versenumber:         3,verse:"河里要滋生青蛙；这青蛙要上来进你的宫殿和你的卧房，上你的床榻，进你臣仆的房屋，上你百姓的身上，进你的炉灶和你的抟面盆，"},
{volumn:         2,chapter:         8,versenumber:         4,verse:"又要上你和你百姓并你众臣仆的身上。’”"},
{volumn:         2,chapter:         8,versenumber:         5,verse:"耶和华晓谕摩西说：“你对亚伦说：‘把你的杖伸在江、河、池以上，使青蛙到埃及地上来。’”"},
{volumn:         2,chapter:         8,versenumber:         6,verse:"亚伦便伸杖在埃及的诸水以上，青蛙就上来，遮满了埃及地。"},
{volumn:         2,chapter:         8,versenumber:         7,verse:"行法术的也用他们的邪术照样而行，叫青蛙上了埃及地。"},
{volumn:         2,chapter:         8,versenumber:         8,verse:"法老召了摩西、亚伦来，说：“请你们求耶和华使这青蛙离开我和我的民，我就容百姓去祭祀耶和华。”"},
{volumn:         2,chapter:         8,versenumber:         9,verse:"摩西对法老说：“任凭你吧，我要何时为你和你的臣仆并你的百姓祈求，除灭青蛙离开你和你的宫殿只留在河里呢？”"},
{volumn:         2,chapter:         8,versenumber:        10,verse:"他说：“明天。”摩西说：“可以照你的话吧，好叫你知道没有像耶和华我们　神的。"},
{volumn:         2,chapter:         8,versenumber:        11,verse:"青蛙要离开你和你的宫殿，并你的臣仆与你的百姓，只留在河里。”"},
{volumn:         2,chapter:         8,versenumber:        12,verse:"于是摩西、亚伦离开法老出去。摩西为扰害法老的青蛙呼求耶和华。"},
{volumn:         2,chapter:         8,versenumber:        13,verse:"耶和华就照摩西的话行。凡在房里、院中、田间的青蛙都死了。"},
{volumn:         2,chapter:         8,versenumber:        14,verse:"众人把青蛙聚拢成堆，遍地就都腥臭。"},
{volumn:         2,chapter:         8,versenumber:        15,verse:"但法老见灾祸松缓，就硬着心，不肯听他们，正如耶和华所说的。"},
{volumn:         2,chapter:         8,versenumber:        16,verse:"耶和华吩咐摩西说：“你对亚伦说：‘伸出你的杖击打地上的尘土，使尘土在埃及遍地变作虱子（或译：虼蚤；下同）。’”"},
{volumn:         2,chapter:         8,versenumber:        17,verse:"他们就这样行。亚伦伸杖击打地上的尘土，就在人身上和牲畜身上有了虱子；埃及遍地的尘土都变成虱子了。"},
{volumn:         2,chapter:         8,versenumber:        18,verse:"行法术的也用邪术要生出虱子来，却是不能。于是在人身上和牲畜身上都有了虱子。"},
{volumn:         2,chapter:         8,versenumber:        19,verse:"行法术的就对法老说：“这是　神的手段。”法老心里刚硬，不肯听摩西、亚伦，正如耶和华所说的。"},
{volumn:         2,chapter:         8,versenumber:        20,verse:"耶和华对摩西说：“你清早起来，法老来到水边，你站在他面前，对他说：‘耶和华这样说：容我的百姓去，好侍奉我。"},
{volumn:         2,chapter:         8,versenumber:        21,verse:"你若不容我的百姓去，我要叫成群的苍蝇到你和你臣仆并你百姓的身上，进你的房屋，并且埃及人的房屋和他们所住的地都要满了成群的苍蝇。"},
{volumn:         2,chapter:         8,versenumber:        22,verse:"当那日，我必分别我百姓所住的歌珊地，使那里没有成群的苍蝇，好叫你知道我是天下的耶和华。"},
{volumn:         2,chapter:         8,versenumber:        23,verse:"我要将我的百姓和你的百姓分别出来。明天必有这神迹。’”"},
{volumn:         2,chapter:         8,versenumber:        24,verse:"耶和华就这样行。苍蝇成了大群，进入法老的宫殿，和他臣仆的房屋；埃及遍地就因这成群的苍蝇败坏了。"},
{volumn:         2,chapter:         8,versenumber:        25,verse:"法老召了摩西、亚伦来，说：“你们去，在这地祭祀你们的　神吧！”"},
{volumn:         2,chapter:         8,versenumber:        26,verse:"摩西说：“这样行本不相宜，因为我们要把埃及人所厌恶的祭祀耶和华我们的　神；若把埃及人所厌恶的在他们眼前献为祭，他们岂不拿石头打死我们吗？"},
{volumn:         2,chapter:         8,versenumber:        27,verse:"我们要往旷野去，走三天的路程，照着耶和华我们　神所要吩咐我们的祭祀他。”"},
{volumn:         2,chapter:         8,versenumber:        28,verse:"法老说：“我容你们去，在旷野祭祀耶和华你们的　神；只是不要走得很远。求你们为我祈祷。”"},
{volumn:         2,chapter:         8,versenumber:        29,verse:"摩西说：“我要出去求耶和华，使成群的苍蝇明天离开法老和法老的臣仆并法老的百姓；法老却不可再行诡诈，不容百姓去祭祀耶和华。”"},
{volumn:         2,chapter:         8,versenumber:        30,verse:"于是摩西离开法老去求耶和华。"},
{volumn:         2,chapter:         8,versenumber:        31,verse:"耶和华就照摩西的话行，叫成群的苍蝇离开法老和他的臣仆并他的百姓，一个也没有留下。"},
{volumn:         2,chapter:         8,versenumber:        32,verse:"这一次法老又硬着心，不容百姓去。"},
{volumn:         2,chapter:         9,versenumber:         1,verse:"耶和华吩咐摩西说：“你进去见法老，对他说：‘耶和华希伯来人的　神这样说：容我的百姓去，好侍奉我。"},
{volumn:         2,chapter:         9,versenumber:         2,verse:"你若不肯容他们去，仍旧强留他们，"},
{volumn:         2,chapter:         9,versenumber:         3,verse:"耶和华的手加在你田间的牲畜上，就是在马、驴、骆驼、牛群、羊群上，必有重重的瘟疫。"},
{volumn:         2,chapter:         9,versenumber:         4,verse:"耶和华要分别以色列的牲畜和埃及的牲畜，凡属以色列人的，一样都不死。’”"},
{volumn:         2,chapter:         9,versenumber:         5,verse:"耶和华就定了时候，说：“明天耶和华必在此地行这事。”"},
{volumn:         2,chapter:         9,versenumber:         6,verse:"第二天，耶和华就行这事。埃及的牲畜几乎都死了，只是以色列人的牲畜，一个都没有死。"},
{volumn:         2,chapter:         9,versenumber:         7,verse:"法老打发人去看，谁知以色列人的牲畜连一个都没有死。法老的心却是固执，不容百姓去。"},
{volumn:         2,chapter:         9,versenumber:         8,verse:"耶和华吩咐摩西、亚伦说：“你们取几捧炉灰，摩西要在法老面前向天扬起来。"},
{volumn:         2,chapter:         9,versenumber:         9,verse:"这灰要在埃及全地变作尘土，在人身上和牲畜身上成了起泡的疮。”"},
{volumn:         2,chapter:         9,versenumber:        10,verse:"摩西、亚伦取了炉灰，站在法老面前。摩西向天扬起来，就在人身上和牲畜身上成了起泡的疮。"},
{volumn:         2,chapter:         9,versenumber:        11,verse:"行法术的在摩西面前站立不住，因为在他们身上和一切埃及人身上都有这疮。"},
{volumn:         2,chapter:         9,versenumber:        12,verse:"耶和华使法老的心刚硬，不听他们，正如耶和华对摩西所说的。"},
{volumn:         2,chapter:         9,versenumber:        13,verse:"耶和华对摩西说：“你清早起来，站在法老面前，对他说：‘耶和华希伯来人的　神这样说：容我的百姓去，好侍奉我。"},
{volumn:         2,chapter:         9,versenumber:        14,verse:"因为这一次我要叫一切的灾殃临到你和你臣仆并你百姓的身上，叫你知道在普天下没有像我的。"},
{volumn:         2,chapter:         9,versenumber:        15,verse:"我若伸手用瘟疫攻击你和你的百姓，你早就从地上除灭了。"},
{volumn:         2,chapter:         9,versenumber:        16,verse:"其实，我叫你存立，是特要向你显我的大能，并要使我的名传遍天下。"},
{volumn:         2,chapter:         9,versenumber:        17,verse:"你还向我的百姓自高，不容他们去吗？"},
{volumn:         2,chapter:         9,versenumber:        18,verse:"到明天约在这时候，我必叫重大的冰雹降下，自从埃及开国以来，没有这样的冰雹。"},
{volumn:         2,chapter:         9,versenumber:        19,verse:"现在你要打发人把你的牲畜和你田间一切所有的催进来；凡在田间不收回家的，无论是人是牲畜，冰雹必降在他们身上，他们就必死。’”"},
{volumn:         2,chapter:         9,versenumber:        20,verse:"法老的臣仆中，惧怕耶和华这话的，便叫他的奴仆和牲畜跑进家来。"},
{volumn:         2,chapter:         9,versenumber:        21,verse:"但那不把耶和华这话放在心上的，就将他的奴仆和牲畜留在田里。"},
{volumn:         2,chapter:         9,versenumber:        22,verse:"耶和华对摩西说：“你向天伸杖，使埃及遍地的人身上和牲畜身上，并田间各样菜蔬上，都有冰雹。”"},
{volumn:         2,chapter:         9,versenumber:        23,verse:"摩西向天伸杖，耶和华就打雷下雹，有火闪到地上；耶和华下雹在埃及地上。"},
{volumn:         2,chapter:         9,versenumber:        24,verse:"那时，雹与火搀杂，甚是厉害，自从埃及成国以来，遍地没有这样的。"},
{volumn:         2,chapter:         9,versenumber:        25,verse:"在埃及遍地，雹击打了田间所有的人和牲畜，并一切的菜蔬，又打坏田间一切的树木。"},
{volumn:         2,chapter:         9,versenumber:        26,verse:"惟独以色列人所住的歌珊地没有冰雹。"},
{volumn:         2,chapter:         9,versenumber:        27,verse:"法老打发人召摩西、亚伦来，对他们说：“这一次我犯了罪了。耶和华是公义的；我和我的百姓是邪恶的。"},
{volumn:         2,chapter:         9,versenumber:        28,verse:"这雷轰和冰雹已经够了。请你们求耶和华，我就容你们去，不再留住你们。”"},
{volumn:         2,chapter:         9,versenumber:        29,verse:"摩西对他说：“我一出城，就要向耶和华举手祷告；雷必止住，也不再有冰雹，叫你知道全地都是属耶和华的。"},
{volumn:         2,chapter:         9,versenumber:        30,verse:"至于你和你的臣仆，我知道你们还是不惧怕耶和华　神。”"},
{volumn:         2,chapter:         9,versenumber:        31,verse:"那时，麻和大麦被雹击打；因为大麦已经吐穗，麻也开了花。"},
{volumn:         2,chapter:         9,versenumber:        32,verse:"只是小麦和粗麦没有被击打，因为还没有长成。"},
{volumn:         2,chapter:         9,versenumber:        33,verse:"摩西离了法老出城，向耶和华举手祷告；雷和雹就止住，雨也不再浇在地上了。"},
{volumn:         2,chapter:         9,versenumber:        34,verse:"法老见雨和雹与雷止住，就越发犯罪；他和他的臣仆都硬着心。"},
{volumn:         2,chapter:         9,versenumber:        35,verse:"法老的心刚硬，不容以色列人去，正如耶和华藉着摩西所说的。"},
{volumn:         2,chapter:        10,versenumber:         1,verse:"耶和华对摩西说：“你进去见法老。我使他和他臣仆的心刚硬，为要在他们中间显我这些神迹，"},
{volumn:         2,chapter:        10,versenumber:         2,verse:"并要叫你将我向埃及人所做的事，和在他们中间所行的神迹，传于你儿子和你孙子的耳中，好叫你们知道我是耶和华。”"},
{volumn:         2,chapter:        10,versenumber:         3,verse:"摩西、亚伦就进去见法老，对他说：“耶和华希伯来人的　神这样说：‘你在我面前不肯自卑要到几时呢？容我的百姓去，好侍奉我。"},
{volumn:         2,chapter:        10,versenumber:         4,verse:"你若不肯容我的百姓去，明天我要使蝗虫进入你的境内，"},
{volumn:         2,chapter:        10,versenumber:         5,verse:"遮满地面，甚至看不见地，并且吃那冰雹所剩的和田间所长的一切树木。"},
{volumn:         2,chapter:        10,versenumber:         6,verse:"你的宫殿和你众臣仆的房屋，并一切埃及人的房屋，都要被蝗虫占满了；自从你祖宗和你祖宗的祖宗在世以来，直到今日，没有见过这样的灾。’”摩西就转身离开法老出去。"},
{volumn:         2,chapter:        10,versenumber:         7,verse:"法老的臣仆对法老说：“这人为我们的网罗要到几时呢？容这些人去侍奉耶和华他们的　神吧！埃及已经败坏了，你还不知道吗？”"},
{volumn:         2,chapter:        10,versenumber:         8,verse:"于是摩西、亚伦被召回来见法老；法老对他们说：“你们去侍奉耶和华你们的　神；但那要去的是谁呢？”"},
{volumn:         2,chapter:        10,versenumber:         9,verse:"摩西说：“我们要和我们老的少的、儿子女儿同去，且把羊群牛群一同带去，因为我们务要向耶和华守节。”"},
{volumn:         2,chapter:        10,versenumber:        10,verse:"法老对他们说：“我容你们和你们妇人孩子去的时候，耶和华与你们同在吧！你们要谨慎；因为有祸在你们眼前（或译：你们存着恶意），"},
{volumn:         2,chapter:        10,versenumber:        11,verse:"不可都去！你们这壮年人去侍奉耶和华吧，因为这是你们所求的。”于是把他们从法老面前撵出去。"},
{volumn:         2,chapter:        10,versenumber:        12,verse:"耶和华对摩西说：“你向埃及地伸杖，使蝗虫到埃及地上来，吃地上一切的菜蔬，就是冰雹所剩的。”"},
{volumn:         2,chapter:        10,versenumber:        13,verse:"摩西就向埃及地伸杖，那一昼一夜，耶和华使东风刮在埃及地上；到了早晨，东风把蝗虫刮了来。"},
{volumn:         2,chapter:        10,versenumber:        14,verse:"蝗虫上来，落在埃及的四境，甚是厉害；以前没有这样的，以后也必没有。"},
{volumn:         2,chapter:        10,versenumber:        15,verse:"因为这蝗虫遮满地面，甚至地都黑暗了，又吃地上一切的菜蔬和冰雹所剩树上的果子。埃及遍地，无论是树木，是田间的菜蔬，连一点青的也没有留下。"},
{volumn:         2,chapter:        10,versenumber:        16,verse:"于是法老急忙召了摩西、亚伦来，说：“我得罪耶和华你们的　神，又得罪了你们。"},
{volumn:         2,chapter:        10,versenumber:        17,verse:"现在求你，只这一次，饶恕我的罪，求耶和华你们的　神使我脱离这一次的死亡。”"},
{volumn:         2,chapter:        10,versenumber:        18,verse:"摩西就离开法老去求耶和华。"},
{volumn:         2,chapter:        10,versenumber:        19,verse:"耶和华转了极大的西风，把蝗虫刮起，吹入红海；在埃及的四境连一个也没有留下。"},
{volumn:         2,chapter:        10,versenumber:        20,verse:"但耶和华使法老的心刚硬，不容以色列人去。"},
{volumn:         2,chapter:        10,versenumber:        21,verse:"耶和华对摩西说：“你向天伸杖，使埃及地黑暗；这黑暗似乎摸得着。”"},
{volumn:         2,chapter:        10,versenumber:        22,verse:"摩西向天伸杖，埃及遍地就乌黑了三天。"},
{volumn:         2,chapter:        10,versenumber:        23,verse:"三天之久，人不能相见，谁也不敢起来离开本处；惟有以色列人家中都有亮光。"},
{volumn:         2,chapter:        10,versenumber:        24,verse:"法老就召摩西来，说：“你们去侍奉耶和华；只是你们的羊群牛群要留下；你们的妇人孩子可以和你们同去。”"},
{volumn:         2,chapter:        10,versenumber:        25,verse:"摩西说：“你总要把祭物和燔祭牲交给我们，使我们可以祭祀耶和华我们的　神。"},
{volumn:         2,chapter:        10,versenumber:        26,verse:"我们的牲畜也要带去，连一蹄也不留下；因为我们要从其中取出来，侍奉耶和华我们的　神。我们未到那里，还不知道用什么侍奉耶和华。”"},
{volumn:         2,chapter:        10,versenumber:        27,verse:"但耶和华使法老的心刚硬，不肯容他们去。"},
{volumn:         2,chapter:        10,versenumber:        28,verse:"法老对摩西说：“你离开我去吧，你要小心，不要再见我的面！因为你见我面的那日你就必死！”"},
{volumn:         2,chapter:        10,versenumber:        29,verse:"摩西说：“你说得好！我必不再见你的面了。”"},
{volumn:         2,chapter:        11,versenumber:         1,verse:"耶和华对摩西说：“我再使一样的灾殃临到法老和埃及，然后他必容你们离开这地。他容你们去的时候，总要催逼你们都从这地出去。"},
{volumn:         2,chapter:        11,versenumber:         2,verse:"你要传于百姓的耳中，叫他们男女各人向邻舍要金器银器。”"},
{volumn:         2,chapter:        11,versenumber:         3,verse:"耶和华叫百姓在埃及人眼前蒙恩，并且摩西在埃及地、法老臣仆，和百姓的眼中看为极大。"},
{volumn:         2,chapter:        11,versenumber:         4,verse:"摩西说：“耶和华这样说：‘约到半夜，我必出去巡行埃及遍地。"},
{volumn:         2,chapter:        11,versenumber:         5,verse:"凡在埃及地，从坐宝座的法老直到磨子后的婢女所有的长子，以及一切头生的牲畜，都必死。"},
{volumn:         2,chapter:        11,versenumber:         6,verse:"埃及遍地必有大哀号；从前没有这样的，后来也必没有。"},
{volumn:         2,chapter:        11,versenumber:         7,verse:"至于以色列中，无论是人是牲畜，连狗也不敢向他们摇舌，好叫你们知道耶和华是将埃及人和以色列人分别出来。’"},
{volumn:         2,chapter:        11,versenumber:         8,verse:"你这一切臣仆都要俯伏来见我，说：‘求你和跟从你的百姓都出去’，然后我要出去。”于是，摩西气忿忿地离开法老，出去了。"},
{volumn:         2,chapter:        11,versenumber:         9,verse:"耶和华对摩西说：“法老必不听你们，使我的奇事在埃及地多起来。”"},
{volumn:         2,chapter:        11,versenumber:        10,verse:"摩西、亚伦在法老面前行了这一切奇事；耶和华使法老的心刚硬，不容以色列人出离他的地。"},
{volumn:         2,chapter:        12,versenumber:         1,verse:"耶和华在埃及地晓谕摩西、亚伦说："},
{volumn:         2,chapter:        12,versenumber:         2,verse:"“你们要以本月为正月，为一年之首。"},
{volumn:         2,chapter:        12,versenumber:         3,verse:"你们吩咐以色列全会众说：本月初十日，各人要按着父家取羊羔，一家一只。"},
{volumn:         2,chapter:        12,versenumber:         4,verse:"若是一家的人太少，吃不了一只羊羔，本人就要和他隔壁的邻舍共取一只。你们预备羊羔，要按着人数和饭量计算。"},
{volumn:         2,chapter:        12,versenumber:         5,verse:"要无残疾、一岁的公羊羔，你们或从绵羊里取，或从山羊里取，都可以。"},
{volumn:         2,chapter:        12,versenumber:         6,verse:"要留到本月十四日，在黄昏的时候，以色列全会众把羊羔宰了。"},
{volumn:         2,chapter:        12,versenumber:         7,verse:"各家要取点血，涂在吃羊羔的房屋左右的门框上和门楣上。"},
{volumn:         2,chapter:        12,versenumber:         8,verse:"当夜要吃羊羔的肉；用火烤了，与无酵饼和苦菜同吃。"},
{volumn:         2,chapter:        12,versenumber:         9,verse:"不可吃生的，断不可吃水煮的，要带着头、腿、五脏，用火烤了吃。"},
{volumn:         2,chapter:        12,versenumber:        10,verse:"不可剩下一点留到早晨；若留到早晨，要用火烧了。"},
{volumn:         2,chapter:        12,versenumber:        11,verse:"你们吃羊羔当腰间束带，脚上穿鞋，手中拿杖，赶紧地吃；这是耶和华的逾越节。"},
{volumn:         2,chapter:        12,versenumber:        12,verse:"因为那夜我要巡行埃及地，把埃及地一切头生的，无论是人是牲畜，都击杀了，又要败坏埃及一切的神。我是耶和华。"},
{volumn:         2,chapter:        12,versenumber:        13,verse:"这血要在你们所住的房屋上作记号；我一见这血，就越过你们去。我击杀埃及地头生的时候，灾殃必不临到你们身上灭你们。”"},
{volumn:         2,chapter:        12,versenumber:        14,verse:"“你们要记念这日，守为耶和华的节，作为你们世世代代永远的定例。"},
{volumn:         2,chapter:        12,versenumber:        15,verse:"你们要吃无酵饼七日。头一日要把酵从你们各家中除去；因为从头一日起，到第七日为止，凡吃有酵之饼的，必从以色列中剪除。"},
{volumn:         2,chapter:        12,versenumber:        16,verse:"头一日你们当有圣会，第七日也当有圣会。这两日之内，除了预备各人所要吃的以外，无论何工都不可做。"},
{volumn:         2,chapter:        12,versenumber:        17,verse:"你们要守无酵节，因为我正当这日把你们的军队从埃及地领出来。所以，你们要守这日，作为世世代代永远的定例。"},
{volumn:         2,chapter:        12,versenumber:        18,verse:"从正月十四日晚上，直到二十一日晚上，你们要吃无酵饼。"},
{volumn:         2,chapter:        12,versenumber:        19,verse:"在你们各家中，七日之内不可有酵；因为凡吃有酵之物的，无论是寄居的，是本地的，必从以色列的会中剪除。"},
{volumn:         2,chapter:        12,versenumber:        20,verse:"有酵的物，你们都不可吃；在你们一切住处要吃无酵饼。”"},
{volumn:         2,chapter:        12,versenumber:        21,verse:"于是，摩西召了以色列的众长老来，对他们说：“你们要按着家口取出羊羔，把这逾越节的羊羔宰了。"},
{volumn:         2,chapter:        12,versenumber:        22,verse:"拿一把牛膝草，蘸盆里的血，打在门楣上和左右的门框上。你们谁也不可出自己的房门，直到早晨。"},
{volumn:         2,chapter:        12,versenumber:        23,verse:"因为耶和华要巡行击杀埃及人，他看见血在门楣上和左右的门框上，就必越过那门，不容灭命的进你们的房屋，击杀你们。"},
{volumn:         2,chapter:        12,versenumber:        24,verse:"这例，你们要守着，作为你们和你们子孙永远的定例。"},
{volumn:         2,chapter:        12,versenumber:        25,verse:"日后，你们到了耶和华按着所应许赐给你们的那地，就要守这礼。"},
{volumn:         2,chapter:        12,versenumber:        26,verse:"你们的儿女问你们说：‘行这礼是什么意思？’"},
{volumn:         2,chapter:        12,versenumber:        27,verse:"你们就说：‘这是献给耶和华逾越节的祭。当以色列人在埃及的时候，他击杀埃及人，越过以色列人的房屋，救了我们各家。’”于是百姓低头下拜。"},
{volumn:         2,chapter:        12,versenumber:        28,verse:"耶和华怎样吩咐摩西、亚伦，以色列人就怎样行。"},
{volumn:         2,chapter:        12,versenumber:        29,verse:"到了半夜，耶和华把埃及地所有的长子，就是从坐宝座的法老，直到被掳囚在监里之人的长子，以及一切头生的牲畜，尽都杀了。"},
{volumn:         2,chapter:        12,versenumber:        30,verse:"法老和一切臣仆，并埃及众人，夜间都起来了。在埃及有大哀号，无一家不死一个人的。"},
{volumn:         2,chapter:        12,versenumber:        31,verse:"夜间，法老召了摩西、亚伦来，说：“起来！连你们带以色列人，从我民中出去，依你们所说的，去侍奉耶和华吧！"},
{volumn:         2,chapter:        12,versenumber:        32,verse:"也依你们所说的，连羊群牛群带着走吧！并要为我祝福。”"},
{volumn:         2,chapter:        12,versenumber:        33,verse:"埃及人催促百姓，打发他们快快出离那地，因为埃及人说：“我们都要死了。”"},
{volumn:         2,chapter:        12,versenumber:        34,verse:"百姓就拿着没有酵的生面，把抟面盆包在衣服中，扛在肩头上。"},
{volumn:         2,chapter:        12,versenumber:        35,verse:"以色列人照着摩西的话行，向埃及人要金器、银器，和衣裳。"},
{volumn:         2,chapter:        12,versenumber:        36,verse:"耶和华叫百姓在埃及人眼前蒙恩，以致埃及人给他们所要的。他们就把埃及人的财物夺去了。"},
{volumn:         2,chapter:        12,versenumber:        37,verse:"以色列人从兰塞起行，往疏割去；除了妇人孩子，步行的男人约有六十万。"},
{volumn:         2,chapter:        12,versenumber:        38,verse:"又有许多闲杂人，并有羊群牛群，和他们一同上去。"},
{volumn:         2,chapter:        12,versenumber:        39,verse:"他们用埃及带出来的生面烤成无酵饼。这生面原没有发起；因为他们被催逼离开埃及，不能耽延，也没有为自己预备什么食物。"},
{volumn:         2,chapter:        12,versenumber:        40,verse:"以色列人住在埃及共有四百三十年。"},
{volumn:         2,chapter:        12,versenumber:        41,verse:"正满了四百三十年的那一天，耶和华的军队都从埃及地出来了。"},
{volumn:         2,chapter:        12,versenumber:        42,verse:"这夜是耶和华的夜；因耶和华领他们出了埃及地，所以当向耶和华谨守，是以色列众人世世代代该谨守的。"},
{volumn:         2,chapter:        12,versenumber:        43,verse:"耶和华对摩西、亚伦说：“逾越节的例是这样：外邦人都不可吃这羊羔。"},
{volumn:         2,chapter:        12,versenumber:        44,verse:"但各人用银子买的奴仆，既受了割礼就可以吃。"},
{volumn:         2,chapter:        12,versenumber:        45,verse:"寄居的和雇工人都不可吃。"},
{volumn:         2,chapter:        12,versenumber:        46,verse:"应当在一个房子里吃；不可把一点肉从房子里带到外头去。羊羔的骨头一根也不可折断。"},
{volumn:         2,chapter:        12,versenumber:        47,verse:"以色列全会众都要守这礼。"},
{volumn:         2,chapter:        12,versenumber:        48,verse:"若有外人寄居在你们中间，愿向耶和华守逾越节，他所有的男子务要受割礼，然后才容他前来遵守，他也就像本地人一样；但未受割礼的，都不可吃这羊羔。"},
{volumn:         2,chapter:        12,versenumber:        49,verse:"本地人和寄居在你们中间的外人同归一例。”"},
{volumn:         2,chapter:        12,versenumber:        50,verse:"耶和华怎样吩咐摩西、亚伦，以色列众人就怎样行了。"},
{volumn:         2,chapter:        12,versenumber:        51,verse:"正当那日，耶和华将以色列人按着他们的军队，从埃及地领出来。"},
{volumn:         2,chapter:        13,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        13,versenumber:         2,verse:"“以色列中凡头生的，无论是人是牲畜，都是我的，要分别为圣归我。”"},
{volumn:         2,chapter:        13,versenumber:         3,verse:"摩西对百姓说：“你们要记念从埃及为奴之家出来的这日，因为耶和华用大能的手将你们从这地方领出来。有酵的饼都不可吃。"},
{volumn:         2,chapter:        13,versenumber:         4,verse:"亚笔月间的这日是你们出来的日子。"},
{volumn:         2,chapter:        13,versenumber:         5,verse:"将来耶和华领你进迦南人、赫人、亚摩利人、希未人、耶布斯人之地，就是他向你的祖宗起誓应许给你那流奶与蜜之地，那时你要在这月间守这礼。"},
{volumn:         2,chapter:        13,versenumber:         6,verse:"你要吃无酵饼七日，到第七日要向耶和华守节。"},
{volumn:         2,chapter:        13,versenumber:         7,verse:"这七日之久，要吃无酵饼；在你四境之内不可见有酵的饼，也不可见发酵的物。"},
{volumn:         2,chapter:        13,versenumber:         8,verse:"当那日，你要告诉你的儿子说：‘这是因耶和华在我出埃及的时候为我所行的事。"},
{volumn:         2,chapter:        13,versenumber:         9,verse:"这要在你手上作记号，在你额上作纪念，使耶和华的律法常在你口中，因为耶和华曾用大能的手将你从埃及领出来。"},
{volumn:         2,chapter:        13,versenumber:        10,verse:"所以你每年要按着日期守这例。’”"},
{volumn:         2,chapter:        13,versenumber:        11,verse:"“将来，耶和华照他向你和你祖宗所起的誓将你领进迦南人之地，把这地赐给你，"},
{volumn:         2,chapter:        13,versenumber:        12,verse:"那时你要将一切头生的，并牲畜中头生的，归给耶和华；公的都要属耶和华。"},
{volumn:         2,chapter:        13,versenumber:        13,verse:"凡头生的驴，你要用羊羔代赎；若不代赎，就要打折它的颈项。凡你儿子中头生的都要赎出来。"},
{volumn:         2,chapter:        13,versenumber:        14,verse:"日后，你的儿子问你说：‘这是什么意思？’你就说：‘耶和华用大能的手将我们从埃及为奴之家领出来。"},
{volumn:         2,chapter:        13,versenumber:        15,verse:"那时法老几乎不容我们去，耶和华就把埃及地所有头生的，无论是人是牲畜，都杀了。因此，我把一切头生的公牲畜献给耶和华为祭，但将头生的儿子都赎出来。"},
{volumn:         2,chapter:        13,versenumber:        16,verse:"这要在你手上作记号，在你额上作经文，因为耶和华用大能的手将我们从埃及领出来。’”"},
{volumn:         2,chapter:        13,versenumber:        17,verse:"法老容百姓去的时候，非利士地的道路虽近，　神却不领他们从那里走；因为　神说：“恐怕百姓遇见打仗后悔，就回埃及去。”"},
{volumn:         2,chapter:        13,versenumber:        18,verse:"所以　神领百姓绕道而行，走红海旷野的路。以色列人出埃及地，都带着兵器上去。"},
{volumn:         2,chapter:        13,versenumber:        19,verse:"摩西把约瑟的骸骨一同带去；因为约瑟曾叫以色列人严严地起誓，对他们说：“　神必眷顾你们，你们要把我的骸骨从这里一同带上去。”"},
{volumn:         2,chapter:        13,versenumber:        20,verse:"他们从疏割起行，在旷野边的以倘安营。"},
{volumn:         2,chapter:        13,versenumber:        21,verse:"日间，耶和华在云柱中领他们的路；夜间，在火柱中光照他们，使他们日夜都可以行走。"},
{volumn:         2,chapter:        13,versenumber:        22,verse:"日间云柱，夜间火柱，总不离开百姓的面前。"},
{volumn:         2,chapter:        14,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        14,versenumber:         2,verse:"“你吩咐以色列人转回，安营在比·哈希录前，密夺和海的中间，对着巴力·洗分，靠近海边安营。"},
{volumn:         2,chapter:        14,versenumber:         3,verse:"法老必说：‘以色列人在地中绕迷了，旷野把他们困住了。’"},
{volumn:         2,chapter:        14,versenumber:         4,verse:"我要使法老的心刚硬，他要追赶他们，我便在法老和他全军身上得荣耀；埃及人就知道我是耶和华。”于是以色列人这样行了。"},
{volumn:         2,chapter:        14,versenumber:         5,verse:"有人告诉埃及王说：“百姓逃跑。”法老和他的臣仆就向百姓变心，说：“我们容以色列人去，不再服侍我们，这做的是什么事呢？”"},
{volumn:         2,chapter:        14,versenumber:         6,verse:"法老就预备他的车辆，带领军兵同去，"},
{volumn:         2,chapter:        14,versenumber:         7,verse:"并带着六百辆特选的车和埃及所有的车，每辆都有车兵长。"},
{volumn:         2,chapter:        14,versenumber:         8,verse:"耶和华使埃及王法老的心刚硬，他就追赶以色列人，因为以色列人是昂然无惧地出埃及。"},
{volumn:         2,chapter:        14,versenumber:         9,verse:"埃及人追赶他们，法老一切的马匹、车辆、马兵，与军兵就在海边上，靠近比·哈希录，对着巴力·洗分，在他们安营的地方追上了。"},
{volumn:         2,chapter:        14,versenumber:        10,verse:"法老临近的时候，以色列人举目看见埃及人赶来，就甚惧怕，向耶和华哀求。"},
{volumn:         2,chapter:        14,versenumber:        11,verse:"他们对摩西说：“难道在埃及没有坟地，你把我们带来死在旷野吗？你为什么这样待我们，将我们从埃及领出来呢？"},
{volumn:         2,chapter:        14,versenumber:        12,verse:"我们在埃及岂没有对你说过，不要搅扰我们，容我们服侍埃及人吗？因为服侍埃及人比死在旷野还好。”"},
{volumn:         2,chapter:        14,versenumber:        13,verse:"摩西对百姓说：“不要惧怕，只管站住！看耶和华今天向你们所要施行的救恩。因为，你们今天所看见的埃及人必永远不再看见了。"},
{volumn:         2,chapter:        14,versenumber:        14,verse:"耶和华必为你们争战；你们只管静默，不要作声。”"},
{volumn:         2,chapter:        14,versenumber:        15,verse:"耶和华对摩西说：“你为什么向我哀求呢？你吩咐以色列人往前走。"},
{volumn:         2,chapter:        14,versenumber:        16,verse:"你举手向海伸杖，把水分开。以色列人要下海中走干地。"},
{volumn:         2,chapter:        14,versenumber:        17,verse:"我要使埃及人的心刚硬，他们就跟着下去。我要在法老和他的全军、车辆、马兵上得荣耀。"},
{volumn:         2,chapter:        14,versenumber:        18,verse:"我在法老和他的车辆、马兵上得荣耀的时候，埃及人就知道我是耶和华了。”"},
{volumn:         2,chapter:        14,versenumber:        19,verse:"在以色列营前行走　神的使者，转到他们后边去；云柱也从他们前边转到他们后边立住。"},
{volumn:         2,chapter:        14,versenumber:        20,verse:"在埃及营和以色列营中间有云柱，一边黑暗，一边发光，终夜两下不得相近。"},
{volumn:         2,chapter:        14,versenumber:        21,verse:"摩西向海伸杖，耶和华便用大东风，使海水一夜退去，水便分开，海就成了干地。"},
{volumn:         2,chapter:        14,versenumber:        22,verse:"以色列人下海中走干地，水在他们的左右作了墙垣。"},
{volumn:         2,chapter:        14,versenumber:        23,verse:"埃及人追赶他们，法老一切的马匹、车辆，和马兵都跟着下到海中。"},
{volumn:         2,chapter:        14,versenumber:        24,verse:"到了晨更的时候，耶和华从云火柱中向埃及的军兵观看，使埃及的军兵混乱了；"},
{volumn:         2,chapter:        14,versenumber:        25,verse:"又使他们的车轮脱落，难以行走，以致埃及人说：“我们从以色列人面前逃跑吧！因耶和华为他们攻击我们了。”"},
{volumn:         2,chapter:        14,versenumber:        26,verse:"耶和华对摩西说：“你向海伸杖，叫水仍合在埃及人并他们的车辆、马兵身上。”"},
{volumn:         2,chapter:        14,versenumber:        27,verse:"摩西就向海伸杖，到了天一亮，海水仍旧复原。埃及人避水逃跑的时候，耶和华把他们推翻在海中，"},
{volumn:         2,chapter:        14,versenumber:        28,verse:"水就回流，淹没了车辆和马兵。那些跟着以色列人下海法老的全军，连一个也没有剩下。"},
{volumn:         2,chapter:        14,versenumber:        29,verse:"以色列人却在海中走干地；水在他们的左右作了墙垣。"},
{volumn:         2,chapter:        14,versenumber:        30,verse:"当日，耶和华这样拯救以色列人脱离埃及人的手，以色列人看见埃及人的死尸都在海边了。"},
{volumn:         2,chapter:        14,versenumber:        31,verse:"以色列人看见耶和华向埃及人所行的大事，就敬畏耶和华，又信服他和他的仆人摩西。"},
{volumn:         2,chapter:        15,versenumber:         1,verse:"那时，摩西和以色列人向耶和华唱歌说：“我要向耶和华歌唱，因他大大战胜，将马和骑马的投在海中。"},
{volumn:         2,chapter:        15,versenumber:         2,verse:"耶和华是我的力量，我的诗歌，也成了我的拯救。这是我的　神，我要赞美他，是我父亲的　神，我要尊崇他。"},
{volumn:         2,chapter:        15,versenumber:         3,verse:"耶和华是战士；他的名是耶和华。"},
{volumn:         2,chapter:        15,versenumber:         4,verse:"法老的车辆、军兵，耶和华已抛在海中；他特选的军长都沉于红海。"},
{volumn:         2,chapter:        15,versenumber:         5,verse:"深水淹没他们；他们如同石头坠到深处。"},
{volumn:         2,chapter:        15,versenumber:         6,verse:"耶和华啊，你的右手施展能力，显出荣耀；耶和华啊，你的右手摔碎仇敌。"},
{volumn:         2,chapter:        15,versenumber:         7,verse:"你大发威严，推翻那些起来攻击你的；你发出烈怒如火，烧灭他们像烧碎秸一样。"},
{volumn:         2,chapter:        15,versenumber:         8,verse:"你发鼻中的气，水便聚起成堆，大水直立如垒，海中的深水凝结。"},
{volumn:         2,chapter:        15,versenumber:         9,verse:"仇敌说：‘我要追赶，我要追上；我要分掳物，我要在他们身上称我的心愿。我要拔出刀来，亲手杀灭他们。’"},
{volumn:         2,chapter:        15,versenumber:        10,verse:"你叫风一吹，海就把他们淹没；他们如铅沉在大水之中。"},
{volumn:         2,chapter:        15,versenumber:        11,verse:"“耶和华啊，众神之中，谁能像你？谁能像你至圣至荣，可颂可畏，施行奇事？"},
{volumn:         2,chapter:        15,versenumber:        12,verse:"你伸出右手，地便吞灭他们。"},
{volumn:         2,chapter:        15,versenumber:        13,verse:"你凭慈爱领了你所赎的百姓；你凭能力引他们到了你的圣所。"},
{volumn:         2,chapter:        15,versenumber:        14,verse:"外邦人听见就发颤；疼痛抓住非利士的居民。"},
{volumn:         2,chapter:        15,versenumber:        15,verse:"那时，以东的族长惊惶，摩押的英雄被战兢抓住，迦南的居民心都消化了。"},
{volumn:         2,chapter:        15,versenumber:        16,verse:"惊骇恐惧临到他们。耶和华啊，因你膀臂的大能，他们如石头寂然不动，等候你的百姓过去，等候你所赎的百姓过去。"},
{volumn:         2,chapter:        15,versenumber:        17,verse:"你要将他们领进去，栽于你产业的山上。耶和华啊，就是你为自己所造的住处；主啊，就是你手所建立的圣所。"},
{volumn:         2,chapter:        15,versenumber:        18,verse:"耶和华必作王，直到永永远远！”"},
{volumn:         2,chapter:        15,versenumber:        19,verse:"法老的马匹、车辆，和马兵下到海中，耶和华使海水回流，淹没他们；惟有以色列人在海中走干地。"},
{volumn:         2,chapter:        15,versenumber:        20,verse:"亚伦的姐姐，女先知米利暗，手里拿着鼓；众妇女也跟她出去拿鼓跳舞。"},
{volumn:         2,chapter:        15,versenumber:        21,verse:"米利暗应声说：“你们要歌颂耶和华，因他大大战胜，将马和骑马的投在海中。”"},
{volumn:         2,chapter:        15,versenumber:        22,verse:"摩西领以色列人从红海往前行，到了书珥的旷野，在旷野走了三天，找不着水。"},
{volumn:         2,chapter:        15,versenumber:        23,verse:"到了玛拉，不能喝那里的水；因为水苦，所以那地名叫玛拉。"},
{volumn:         2,chapter:        15,versenumber:        24,verse:"百姓就向摩西发怨言，说：“我们喝什么呢？”"},
{volumn:         2,chapter:        15,versenumber:        25,verse:"摩西呼求耶和华，耶和华指示他一棵树。他把树丢在水里，水就变甜了。耶和华在那里为他们定了律例、典章，在那里试验他们；"},
{volumn:         2,chapter:        15,versenumber:        26,verse:"又说：“你若留意听耶和华你　神的话，又行我眼中看为正的事，留心听我的诫命，守我一切的律例，我就不将所加与埃及人的疾病加在你身上，因为我耶和华是医治你的。”"},
{volumn:         2,chapter:        15,versenumber:        27,verse:"他们到了以琳，在那里有十二股水泉，七十棵棕树；他们就在那里的水边安营。"},
{volumn:         2,chapter:        16,versenumber:         1,verse:"以色列全会众从以琳起行，在出埃及后第二个月十五日到了以琳和西奈中间、汛的旷野。"},
{volumn:         2,chapter:        16,versenumber:         2,verse:"以色列全会众在旷野向摩西、亚伦发怨言，"},
{volumn:         2,chapter:        16,versenumber:         3,verse:"说：“巴不得我们早死在埃及地、耶和华的手下；那时我们坐在肉锅旁边，吃得饱足。你们将我们领出来，到这旷野，是要叫这全会众都饿死啊！”"},
{volumn:         2,chapter:        16,versenumber:         4,verse:"耶和华对摩西说：“我要将粮食从天降给你们。百姓可以出去，每天收每天的份，我好试验他们遵不遵我的法度。"},
{volumn:         2,chapter:        16,versenumber:         5,verse:"到第六天，他们要把所收进来的预备好了，比每天所收的多一倍。”"},
{volumn:         2,chapter:        16,versenumber:         6,verse:"摩西、亚伦对以色列众人说：“到了晚上，你们要知道是耶和华将你们从埃及地领出来的。"},
{volumn:         2,chapter:        16,versenumber:         7,verse:"早晨，你们要看见耶和华的荣耀，因为耶和华听见你们向他所发的怨言了。我们算什么，你们竟向我们发怨言呢？”"},
{volumn:         2,chapter:        16,versenumber:         8,verse:"摩西又说：“耶和华晚上必给你们肉吃，早晨必给你们食物得饱；因为你们向耶和华发的怨言，他都听见了。我们算什么，你们的怨言不是向我们发的，乃是向耶和华发的。”"},
{volumn:         2,chapter:        16,versenumber:         9,verse:"摩西对亚伦说：“你告诉以色列全会众说：‘你们就近耶和华面前，因为他已经听见你们的怨言了。’”"},
{volumn:         2,chapter:        16,versenumber:        10,verse:"亚伦正对以色列全会众说话的时候，他们向旷野观看，不料，耶和华的荣光在云中显现。"},
{volumn:         2,chapter:        16,versenumber:        11,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        16,versenumber:        12,verse:"“我已经听见以色列人的怨言。你告诉他们说：‘到黄昏的时候，你们要吃肉，早晨必有食物得饱，你们就知道我是耶和华你们的　神。’”"},
{volumn:         2,chapter:        16,versenumber:        13,verse:"到了晚上，有鹌鹑飞来，遮满了营；早晨在营四围的地上有露水。"},
{volumn:         2,chapter:        16,versenumber:        14,verse:"露水上升之后，不料，野地面上有如白霜的小圆物。"},
{volumn:         2,chapter:        16,versenumber:        15,verse:"以色列人看见，不知道是什么，就彼此对问说：“这是什么呢？”摩西对他们说：“这就是耶和华给你们吃的食物。"},
{volumn:         2,chapter:        16,versenumber:        16,verse:"耶和华所吩咐的是这样：你们要按着各人的饭量，为帐棚里的人，按着人数收起来，各拿一俄梅珥。”"},
{volumn:         2,chapter:        16,versenumber:        17,verse:"以色列人就这样行；有多收的，有少收的。"},
{volumn:         2,chapter:        16,versenumber:        18,verse:"及至用俄梅珥量一量，多收的也没有余，少收的也没有缺；各人按着自己的饭量收取。"},
{volumn:         2,chapter:        16,versenumber:        19,verse:"摩西对他们说：“所收的，不许什么人留到早晨。”"},
{volumn:         2,chapter:        16,versenumber:        20,verse:"然而他们不听摩西的话，内中有留到早晨的，就生虫变臭了；摩西便向他们发怒。"},
{volumn:         2,chapter:        16,versenumber:        21,verse:"他们每日早晨，按着各人的饭量收取，日头一发热，就消化了。"},
{volumn:         2,chapter:        16,versenumber:        22,verse:"到第六天，他们收了双倍的食物，每人两俄梅珥。会众的官长来告诉摩西；"},
{volumn:         2,chapter:        16,versenumber:        23,verse:"摩西对他们说：“耶和华这样说：‘明天是圣安息日，是向耶和华守的圣安息日。你们要烤的就烤了，要煮的就煮了，所剩下的都留到早晨。’”"},
{volumn:         2,chapter:        16,versenumber:        24,verse:"他们就照摩西的吩咐留到早晨，也不臭，里头也没有虫子。"},
{volumn:         2,chapter:        16,versenumber:        25,verse:"摩西说：“你们今天吃这个吧！因为今天是向耶和华守的安息日；你们在田野必找不着了。"},
{volumn:         2,chapter:        16,versenumber:        26,verse:"六天可以收取，第七天乃是安息日，那一天必没有了。”"},
{volumn:         2,chapter:        16,versenumber:        27,verse:"第七天，百姓中有人出去收，什么也找不着。"},
{volumn:         2,chapter:        16,versenumber:        28,verse:"耶和华对摩西说：“你们不肯守我的诫命和律法，要到几时呢？"},
{volumn:         2,chapter:        16,versenumber:        29,verse:"你们看！耶和华既将安息日赐给你们，所以第六天他赐给你们两天的食物，第七天各人要住在自己的地方，不许什么人出去。”"},
{volumn:         2,chapter:        16,versenumber:        30,verse:"于是百姓第七天安息了。"},
{volumn:         2,chapter:        16,versenumber:        31,verse:"这食物，以色列家叫吗哪；样子像芫荽子，颜色是白的，滋味如同搀蜜的薄饼。"},
{volumn:         2,chapter:        16,versenumber:        32,verse:"摩西说：“耶和华所吩咐的是这样：‘要将一满俄梅珥吗哪留到世世代代，使后人可以看见我当日将你们领出埃及地，在旷野所给你们吃的食物。’”"},
{volumn:         2,chapter:        16,versenumber:        33,verse:"摩西对亚伦说：“你拿一个罐子，盛一满俄梅珥吗哪，存在耶和华面前，要留到世世代代。”"},
{volumn:         2,chapter:        16,versenumber:        34,verse:"耶和华怎么吩咐摩西，亚伦就怎么行，把吗哪放在法柜前存留。"},
{volumn:         2,chapter:        16,versenumber:        35,verse:"以色列人吃吗哪共四十年，直到进了有人居住之地，就是迦南的境界。（"},
{volumn:         2,chapter:        16,versenumber:        36,verse:"俄梅珥就是伊法十分之一。）"},
{volumn:         2,chapter:        17,versenumber:         1,verse:"以色列全会众都遵耶和华的吩咐，按着站口从汛的旷野往前行，在利非订安营。百姓没有水喝，"},
{volumn:         2,chapter:        17,versenumber:         2,verse:"所以与摩西争闹，说：“给我们水喝吧！”摩西对他们说：“你们为什么与我争闹？为什么试探耶和华呢？”"},
{volumn:         2,chapter:        17,versenumber:         3,verse:"百姓在那里甚渴，要喝水，就向摩西发怨言，说：“你为什么将我们从埃及领出来，使我们和我们的儿女并牲畜都渴死呢？”"},
{volumn:         2,chapter:        17,versenumber:         4,verse:"摩西就呼求耶和华说：“我向这百姓怎样行呢？他们几乎要拿石头打死我。”"},
{volumn:         2,chapter:        17,versenumber:         5,verse:"耶和华对摩西说：“你手里拿着你先前击打河水的杖，带领以色列的几个长老，从百姓面前走过去。"},
{volumn:         2,chapter:        17,versenumber:         6,verse:"我必在何烈的磐石那里，站在你面前。你要击打磐石，从磐石里必有水流出来，使百姓可以喝。”摩西就在以色列的长老眼前这样行了。"},
{volumn:         2,chapter:        17,versenumber:         7,verse:"他给那地方起名叫玛撒（就是试探的意思），又叫米利巴（就是争闹的意思）；因以色列人争闹，又因他们试探耶和华，说：“耶和华是在我们中间不是？”"},
{volumn:         2,chapter:        17,versenumber:         8,verse:"那时，亚玛力人来在利非订，和以色列人争战。"},
{volumn:         2,chapter:        17,versenumber:         9,verse:"摩西对约书亚说：“你为我们选出人来，出去和亚玛力人争战。明天我手里要拿着　神的杖，站在山顶上。”"},
{volumn:         2,chapter:        17,versenumber:        10,verse:"于是约书亚照着摩西对他所说的话行，和亚玛力人争战。摩西、亚伦，与户珥都上了山顶。"},
{volumn:         2,chapter:        17,versenumber:        11,verse:"摩西何时举手，以色列人就得胜，何时垂手，亚玛力人就得胜。"},
{volumn:         2,chapter:        17,versenumber:        12,verse:"但摩西的手发沉，他们就搬石头来，放在他以下，他就坐在上面。亚伦与户珥扶着他的手，一个在这边，一个在那边，他的手就稳住，直到日落的时候。"},
{volumn:         2,chapter:        17,versenumber:        13,verse:"约书亚用刀杀了亚玛力王和他的百姓。"},
{volumn:         2,chapter:        17,versenumber:        14,verse:"耶和华对摩西说：“我要将亚玛力的名号从天下全然涂抹了；你要将这话写在书上作纪念，又念给约书亚听。”"},
{volumn:         2,chapter:        17,versenumber:        15,verse:"摩西筑了一座坛，起名叫“耶和华尼西”（就是耶和华是我旌旗的意思），"},
{volumn:         2,chapter:        17,versenumber:        16,verse:"又说：“耶和华已经起了誓，必世世代代和亚玛力人争战。”"},
{volumn:         2,chapter:        18,versenumber:         1,verse:"摩西的岳父，米甸祭司叶忒罗，听见　神为摩西和　神的百姓以色列所行的一切事，就是耶和华将以色列从埃及领出来的事，"},
{volumn:         2,chapter:        18,versenumber:         2,verse:"便带着摩西的妻子西坡拉，就是摩西从前打发回去的，"},
{volumn:         2,chapter:        18,versenumber:         3,verse:"又带着西坡拉的两个儿子，一个名叫革舜，因为摩西说：“我在外邦作了寄居的”；"},
{volumn:         2,chapter:        18,versenumber:         4,verse:"一个名叫以利以谢，因为他说：“我父亲的　神帮助了我，救我脱离法老的刀。”"},
{volumn:         2,chapter:        18,versenumber:         5,verse:"摩西的岳父叶忒罗带着摩西的妻子和两个儿子来到　神的山，就是摩西在旷野安营的地方。"},
{volumn:         2,chapter:        18,versenumber:         6,verse:"他对摩西说：“我是你岳父叶忒罗，带着你的妻子和两个儿子来到你这里。”"},
{volumn:         2,chapter:        18,versenumber:         7,verse:"摩西迎接他的岳父，向他下拜，与他亲嘴，彼此问安，都进了帐棚。"},
{volumn:         2,chapter:        18,versenumber:         8,verse:"摩西将耶和华为以色列的缘故向法老和埃及人所行的一切事，以及路上所遭遇的一切艰难，并耶和华怎样搭救他们，都述说与他岳父听。"},
{volumn:         2,chapter:        18,versenumber:         9,verse:"叶忒罗因耶和华待以色列的一切好处，就是拯救他们脱离埃及人的手，便甚欢喜。"},
{volumn:         2,chapter:        18,versenumber:        10,verse:"叶忒罗说：“耶和华是应当称颂的；他救了你们脱离埃及人和法老的手，将这百姓从埃及人的手下救出来。"},
{volumn:         2,chapter:        18,versenumber:        11,verse:"我现今在埃及人向这百姓发狂傲的事上得知，耶和华比万神都大。”"},
{volumn:         2,chapter:        18,versenumber:        12,verse:"摩西的岳父叶忒罗把燔祭和平安祭献给　神。亚伦和以色列的众长老都来了，与摩西的岳父在　神面前吃饭。"},
{volumn:         2,chapter:        18,versenumber:        13,verse:"第二天，摩西坐着审判百姓，百姓从早到晚都站在摩西的左右。"},
{volumn:         2,chapter:        18,versenumber:        14,verse:"摩西的岳父看见他向百姓所做的一切事，就说：“你向百姓做的是什么事呢？你为什么独自坐着，众百姓从早到晚都站在你的左右呢？”"},
{volumn:         2,chapter:        18,versenumber:        15,verse:"摩西对岳父说：“这是因百姓到我这里来求问　神。"},
{volumn:         2,chapter:        18,versenumber:        16,verse:"他们有事的时候就到我这里来，我便在两造之间施行审判；我又叫他们知道　神的律例和法度。”"},
{volumn:         2,chapter:        18,versenumber:        17,verse:"摩西的岳父说：“你这做的不好。"},
{volumn:         2,chapter:        18,versenumber:        18,verse:"你和这些百姓必都疲惫；因为这事太重，你独自一人办理不了。"},
{volumn:         2,chapter:        18,versenumber:        19,verse:"现在你要听我的话。我为你出个主意，愿　神与你同在。你要替百姓到　神面前，将案件奏告　神；"},
{volumn:         2,chapter:        18,versenumber:        20,verse:"又要将律例和法度教训他们，指示他们当行的道，当做的事；"},
{volumn:         2,chapter:        18,versenumber:        21,verse:"并要从百姓中拣选有才能的人，就是敬畏　神、诚实无妄、恨不义之财的人，派他们作千夫长、百夫长、五十夫长、十夫长，管理百姓，"},
{volumn:         2,chapter:        18,versenumber:        22,verse:"叫他们随时审判百姓，大事都要呈到你这里，小事他们自己可以审判。这样，你就轻省些，他们也可以同当此任。"},
{volumn:         2,chapter:        18,versenumber:        23,verse:"你若这样行，　神也这样吩咐你，你就能受得住，这百姓也都平平安安归回他们的住处。”"},
{volumn:         2,chapter:        18,versenumber:        24,verse:"于是，摩西听从他岳父的话，按着他所说的去行。"},
{volumn:         2,chapter:        18,versenumber:        25,verse:"摩西从以色列人中拣选了有才能的人，立他们为百姓的首领，作千夫长、百夫长、五十夫长、十夫长。"},
{volumn:         2,chapter:        18,versenumber:        26,verse:"他们随时审判百姓，有难断的案件就呈到摩西那里，但各样小事他们自己审判。"},
{volumn:         2,chapter:        18,versenumber:        27,verse:"此后，摩西让他的岳父去，他就往本地去了。"},
{volumn:         2,chapter:        19,versenumber:         1,verse:"以色列人出埃及地以后，满了三个月的那一天，就来到西奈的旷野。"},
{volumn:         2,chapter:        19,versenumber:         2,verse:"他们离了利非订，来到西奈的旷野，就在那里的山下安营。"},
{volumn:         2,chapter:        19,versenumber:         3,verse:"摩西到　神那里，耶和华从山上呼唤他说：“你要这样告诉雅各家，晓谕以色列人说："},
{volumn:         2,chapter:        19,versenumber:         4,verse:"‘我向埃及人所行的事，你们都看见了，且看见我如鹰将你们背在翅膀上，带来归我。"},
{volumn:         2,chapter:        19,versenumber:         5,verse:"如今你们若实在听从我的话，遵守我的约，就要在万民中作属我的子民，因为全地都是我的。"},
{volumn:         2,chapter:        19,versenumber:         6,verse:"你们要归我作祭司的国度，为圣洁的国民。’这些话你要告诉以色列人。”"},
{volumn:         2,chapter:        19,versenumber:         7,verse:"摩西去召了民间的长老来，将耶和华所吩咐他的话都在他们面前陈明。"},
{volumn:         2,chapter:        19,versenumber:         8,verse:"百姓都同声回答说：“凡耶和华所说的，我们都要遵行。”摩西就将百姓的话回复耶和华。"},
{volumn:         2,chapter:        19,versenumber:         9,verse:"耶和华对摩西说：“我要在密云中临到你那里，叫百姓在我与你说话的时候可以听见，也可以永远信你了。”于是，摩西将百姓的话奏告耶和华。"},
{volumn:         2,chapter:        19,versenumber:        10,verse:"耶和华又对摩西说：“你往百姓那里去，叫他们今天明天自洁，又叫他们洗衣服。"},
{volumn:         2,chapter:        19,versenumber:        11,verse:"到第三天要预备好了，因为第三天耶和华要在众百姓眼前降临在西奈山上。"},
{volumn:         2,chapter:        19,versenumber:        12,verse:"你要在山的四围给百姓定界限，说：‘你们当谨慎，不可上山去，也不可摸山的边界；凡摸这山的，必要治死他。"},
{volumn:         2,chapter:        19,versenumber:        13,verse:"不可用手摸他，必用石头打死，或用箭射透；无论是人是牲畜，都不得活。到角声拖长的时候，他们才可到山根来。’”"},
{volumn:         2,chapter:        19,versenumber:        14,verse:"摩西下山往百姓那里去，叫他们自洁，他们就洗衣服。"},
{volumn:         2,chapter:        19,versenumber:        15,verse:"他对百姓说：“到第三天要预备好了。不可亲近女人。”"},
{volumn:         2,chapter:        19,versenumber:        16,verse:"到了第三天早晨，在山上有雷轰、闪电，和密云，并且角声甚大，营中的百姓尽都发颤。"},
{volumn:         2,chapter:        19,versenumber:        17,verse:"摩西率领百姓出营迎接　神，都站在山下。"},
{volumn:         2,chapter:        19,versenumber:        18,verse:"西奈全山冒烟，因为耶和华在火中降于山上。山的烟气上腾，如烧窑一般，遍山大大地震动。"},
{volumn:         2,chapter:        19,versenumber:        19,verse:"角声渐渐地高而又高，摩西就说话，　神有声音答应他。"},
{volumn:         2,chapter:        19,versenumber:        20,verse:"耶和华降临在西奈山顶上，耶和华召摩西上山顶，摩西就上去。"},
{volumn:         2,chapter:        19,versenumber:        21,verse:"耶和华对摩西说：“你下去嘱咐百姓，不可闯过来到我面前观看，恐怕他们有多人死亡；"},
{volumn:         2,chapter:        19,versenumber:        22,verse:"又叫亲近我的祭司自洁，恐怕我忽然出来击杀他们。”"},
{volumn:         2,chapter:        19,versenumber:        23,verse:"摩西对耶和华说：“百姓不能上西奈山，因为你已经嘱咐我们说：‘要在山的四围定界限，叫山成圣。’”"},
{volumn:         2,chapter:        19,versenumber:        24,verse:"耶和华对他说：“下去吧，你要和亚伦一同上来；只是祭司和百姓不可闯过来上到我面前，恐怕我忽然出来击杀他们。”"},
{volumn:         2,chapter:        19,versenumber:        25,verse:"于是摩西下到百姓那里告诉他们。"},
{volumn:         2,chapter:        20,versenumber:         1,verse:"神吩咐这一切的话说："},
{volumn:         2,chapter:        20,versenumber:         2,verse:"“我是耶和华你的　神，曾将你从埃及地为奴之家领出来。"},
{volumn:         2,chapter:        20,versenumber:         3,verse:"“除了我以外，你不可有别的神。"},
{volumn:         2,chapter:        20,versenumber:         4,verse:"“不可为自己雕刻偶像，也不可做什么形像仿佛上天、下地，和地底下、水中的百物。"},
{volumn:         2,chapter:        20,versenumber:         5,verse:"不可跪拜那些像，也不可侍奉它，因为我耶和华你的　神是忌邪的　神。恨我的，我必追讨他的罪，自父及子，直到三四代；"},
{volumn:         2,chapter:        20,versenumber:         6,verse:"爱我、守我诫命的，我必向他们发慈爱，直到千代。"},
{volumn:         2,chapter:        20,versenumber:         7,verse:"“不可妄称耶和华你　神的名；因为妄称耶和华名的，耶和华必不以他为无罪。"},
{volumn:         2,chapter:        20,versenumber:         8,verse:"“当记念安息日，守为圣日。"},
{volumn:         2,chapter:        20,versenumber:         9,verse:"六日要劳碌做你一切的工，"},
{volumn:         2,chapter:        20,versenumber:        10,verse:"但第七日是向耶和华你　神当守的安息日。这一日你和你的儿女、仆婢、牲畜，并你城里寄居的客旅，无论何工都不可做；"},
{volumn:         2,chapter:        20,versenumber:        11,verse:"因为六日之内，耶和华造天、地、海，和其中的万物，第七日便安息，所以耶和华赐福与安息日，定为圣日。"},
{volumn:         2,chapter:        20,versenumber:        12,verse:"“当孝敬父母，使你的日子在耶和华你　神所赐你的地上得以长久。"},
{volumn:         2,chapter:        20,versenumber:        13,verse:"“不可杀人。"},
{volumn:         2,chapter:        20,versenumber:        14,verse:"“不可奸淫。"},
{volumn:         2,chapter:        20,versenumber:        15,verse:"“不可偷盗。"},
{volumn:         2,chapter:        20,versenumber:        16,verse:"“不可作假见证陷害人。"},
{volumn:         2,chapter:        20,versenumber:        17,verse:"“不可贪恋人的房屋；也不可贪恋人的妻子、仆婢、牛驴，并他一切所有的。”"},
{volumn:         2,chapter:        20,versenumber:        18,verse:"众百姓见雷轰、闪电、角声、山上冒烟，就都发颤，远远地站立，"},
{volumn:         2,chapter:        20,versenumber:        19,verse:"对摩西说：“求你和我们说话，我们必听；不要　神和我们说话，恐怕我们死亡。”"},
{volumn:         2,chapter:        20,versenumber:        20,verse:"摩西对百姓说：“不要惧怕；因为　神降临是要试验你们，叫你们时常敬畏他，不致犯罪。”"},
{volumn:         2,chapter:        20,versenumber:        21,verse:"于是百姓远远地站立；摩西就挨近　神所在的幽暗之中。"},
{volumn:         2,chapter:        20,versenumber:        22,verse:"耶和华对摩西说：“你要向以色列人这样说：‘你们自己看见我从天上和你们说话了。"},
{volumn:         2,chapter:        20,versenumber:        23,verse:"你们不可做什么神像与我相配，不可为自己做金银的神像。"},
{volumn:         2,chapter:        20,versenumber:        24,verse:"你要为我筑土坛，在上面以牛羊献为燔祭和平安祭。凡记下我名的地方，我必到那里赐福给你。"},
{volumn:         2,chapter:        20,versenumber:        25,verse:"你若为我筑一座石坛，不可用凿成的石头，因你在上头一动家具，就把坛污秽了。"},
{volumn:         2,chapter:        20,versenumber:        26,verse:"你上我的坛，不可用台阶，免得露出你的下体来。’”"},
{volumn:         2,chapter:        21,versenumber:         1,verse:"“你在百姓面前所要立的典章是这样："},
{volumn:         2,chapter:        21,versenumber:         2,verse:"你若买希伯来人作奴仆，他必服侍你六年；第七年他可以自由，白白地出去。"},
{volumn:         2,chapter:        21,versenumber:         3,verse:"他若孤身来就可以孤身去；他若有妻，他的妻就可以同他出去。"},
{volumn:         2,chapter:        21,versenumber:         4,verse:"他主人若给他妻子，妻子给他生了儿子或女儿，妻子和儿女要归主人，他要独自出去。"},
{volumn:         2,chapter:        21,versenumber:         5,verse:"倘或奴仆明说：‘我爱我的主人和我的妻子儿女，不愿意自由出去。’"},
{volumn:         2,chapter:        21,versenumber:         6,verse:"他的主人就要带他到审判官（或译：神；下同）那里，又要带他到门前，靠近门框，用锥子穿他的耳朵，他就永远服侍主人。"},
{volumn:         2,chapter:        21,versenumber:         7,verse:"“人若卖女儿作婢女，婢女不可像男仆那样出去。"},
{volumn:         2,chapter:        21,versenumber:         8,verse:"主人选定她归自己，若不喜欢她，就要许她赎身；主人既然用诡诈待她，就没有权柄卖给外邦人。"},
{volumn:         2,chapter:        21,versenumber:         9,verse:"主人若选定她给自己的儿子，就当待她如同女儿。"},
{volumn:         2,chapter:        21,versenumber:        10,verse:"若另娶一个，那女子的吃食、衣服，并好合的事，仍不可减少。"},
{volumn:         2,chapter:        21,versenumber:        11,verse:"若不向她行这三样，她就可以不用钱赎，白白地出去。”"},
{volumn:         2,chapter:        21,versenumber:        12,verse:"“打人以致打死的，必要把他治死。"},
{volumn:         2,chapter:        21,versenumber:        13,verse:"人若不是埋伏着杀人，乃是　神交在他手中，我就设下一个地方，他可以往那里逃跑。"},
{volumn:         2,chapter:        21,versenumber:        14,verse:"人若任意用诡计杀了他的邻舍，就是逃到我的坛那里，也当捉去把他治死。"},
{volumn:         2,chapter:        21,versenumber:        15,verse:"“打父母的，必要把他治死。"},
{volumn:         2,chapter:        21,versenumber:        16,verse:"“拐带人口，或是把人卖了，或是留在他手下，必要把他治死。"},
{volumn:         2,chapter:        21,versenumber:        17,verse:"“咒骂父母的，必要把他治死。"},
{volumn:         2,chapter:        21,versenumber:        18,verse:"“人若彼此相争，这个用石头或是拳头打那个，尚且不至于死，不过躺卧在床，"},
{volumn:         2,chapter:        21,versenumber:        19,verse:"若再能起来扶杖而出，那打他的可算无罪；但要将他耽误的工夫用钱赔补，并要将他全然医好。"},
{volumn:         2,chapter:        21,versenumber:        20,verse:"“人若用棍子打奴仆或婢女，立时死在他的手下，他必要受刑。"},
{volumn:         2,chapter:        21,versenumber:        21,verse:"若过一两天才死，就可以不受刑，因为是用钱买的。"},
{volumn:         2,chapter:        21,versenumber:        22,verse:"“人若彼此争斗，伤害有孕的妇人，甚至坠胎，随后却无别害，那伤害她的，总要按妇人的丈夫所要的，照审判官所断的，受罚。"},
{volumn:         2,chapter:        21,versenumber:        23,verse:"若有别害，就要以命偿命，"},
{volumn:         2,chapter:        21,versenumber:        24,verse:"以眼还眼，以牙还牙，以手还手，以脚还脚，"},
{volumn:         2,chapter:        21,versenumber:        25,verse:"以烙还烙，以伤还伤，以打还打。"},
{volumn:         2,chapter:        21,versenumber:        26,verse:"“人若打坏了他奴仆或是婢女的一只眼，就要因他的眼放他去得以自由。"},
{volumn:         2,chapter:        21,versenumber:        27,verse:"若打掉了他奴仆或是婢女的一个牙，就要因他的牙放他去得以自由。”"},
{volumn:         2,chapter:        21,versenumber:        28,verse:"“牛若触死男人或是女人，总要用石头打死那牛，却不可吃它的肉；牛的主人可算无罪。"},
{volumn:         2,chapter:        21,versenumber:        29,verse:"倘若那牛素来是触人的，有人报告了牛主，他竟不把牛拴着，以致把男人或是女人触死，就要用石头打死那牛，牛主也必治死。"},
{volumn:         2,chapter:        21,versenumber:        30,verse:"若罚他赎命的价银，他必照所罚的赎他的命。"},
{volumn:         2,chapter:        21,versenumber:        31,verse:"牛无论触了人的儿子或是女儿，必照这例办理。"},
{volumn:         2,chapter:        21,versenumber:        32,verse:"牛若触了奴仆或是婢女，必将银子三十舍客勒给他们的主人，也要用石头把牛打死。"},
{volumn:         2,chapter:        21,versenumber:        33,verse:"“人若敞着井口，或挖井不遮盖，有牛或驴掉在里头，"},
{volumn:         2,chapter:        21,versenumber:        34,verse:"井主要拿钱赔还本主人，死牲畜要归自己。"},
{volumn:         2,chapter:        21,versenumber:        35,verse:"“这人的牛若伤了那人的牛，以至于死，他们要卖了活牛，平分价值，也要平分死牛。"},
{volumn:         2,chapter:        21,versenumber:        36,verse:"人若知道这牛素来是触人的，主人竟不把牛拴着，他必要以牛还牛，死牛要归自己。”"},
{volumn:         2,chapter:        22,versenumber:         1,verse:"“人若偷牛或羊，无论是宰了，是卖了，他就要以五牛赔一牛，四羊赔一羊。"},
{volumn:         2,chapter:        22,versenumber:         2,verse:"人若遇见贼挖窟窿，把贼打了，以至于死，就不能为他有流血的罪。"},
{volumn:         2,chapter:        22,versenumber:         3,verse:"若太阳已经出来，就为他有流血的罪。贼若被拿，总要赔还。若他一无所有，就要被卖，顶他所偷的物。"},
{volumn:         2,chapter:        22,versenumber:         4,verse:"若他所偷的，或牛，或驴，或羊，仍在他手下存活，他就要加倍赔还。"},
{volumn:         2,chapter:        22,versenumber:         5,verse:"“人若在田间或在葡萄园里放牲畜，任凭牲畜上别人的田里去吃，就必拿自己田间上好的和葡萄园上好的赔还。"},
{volumn:         2,chapter:        22,versenumber:         6,verse:"“若点火焚烧荆棘，以致将别人堆积的禾捆，站着的禾稼，或是田园，都烧尽了，那点火的必要赔还。"},
{volumn:         2,chapter:        22,versenumber:         7,verse:"“人若将银钱或家具交付邻舍看守，这物从那人的家被偷去，若把贼找到了，贼要加倍赔还；"},
{volumn:         2,chapter:        22,versenumber:         8,verse:"若找不到贼，那家主必就近审判官，要看看他拿了原主的物件没有。"},
{volumn:         2,chapter:        22,versenumber:         9,verse:"“两个人的案件，无论是为什么过犯，或是为牛，为驴，为羊，为衣裳，或是为什么失掉之物，有一人说：‘这是我的’，两造就要将案件禀告审判官，审判官定谁有罪，谁就要加倍赔还。"},
{volumn:         2,chapter:        22,versenumber:        10,verse:"“人若将驴，或牛，或羊，或别的牲畜，交付邻舍看守，牲畜或死，或受伤，或被赶去，无人看见，"},
{volumn:         2,chapter:        22,versenumber:        11,verse:"那看守的人要凭着耶和华起誓，手里未曾拿邻舍的物，本主就要罢休，看守的人不必赔还。"},
{volumn:         2,chapter:        22,versenumber:        12,verse:"牲畜若从看守的那里被偷去，他就要赔还本主；"},
{volumn:         2,chapter:        22,versenumber:        13,verse:"若被野兽撕碎，看守的要带来当作证据，所撕的不必赔还。"},
{volumn:         2,chapter:        22,versenumber:        14,verse:"“人若向邻舍借什么，所借的或受伤，或死，本主没有同在一处，借的人总要赔还；"},
{volumn:         2,chapter:        22,versenumber:        15,verse:"若本主同在一处，他就不必赔还；若是雇的，也不必赔还，本是为雇价来的。”"},
{volumn:         2,chapter:        22,versenumber:        16,verse:"“人若引诱没有受聘的处女，与她行淫，他总要交出聘礼，娶她为妻。"},
{volumn:         2,chapter:        22,versenumber:        17,verse:"若女子的父亲决不肯将女子给他，他就要按处女的聘礼，交出钱来。"},
{volumn:         2,chapter:        22,versenumber:        18,verse:"“行邪术的女人，不可容她存活。"},
{volumn:         2,chapter:        22,versenumber:        19,verse:"“凡与兽淫合的，总要把他治死。"},
{volumn:         2,chapter:        22,versenumber:        20,verse:"“祭祀别神，不单单祭祀耶和华的，那人必要灭绝。"},
{volumn:         2,chapter:        22,versenumber:        21,verse:"“不可亏负寄居的，也不可欺压他，因为你们在埃及地也作过寄居的。"},
{volumn:         2,chapter:        22,versenumber:        22,verse:"不可苦待寡妇和孤儿；"},
{volumn:         2,chapter:        22,versenumber:        23,verse:"若是苦待他们一点，他们向我一哀求，我总要听他们的哀声，"},
{volumn:         2,chapter:        22,versenumber:        24,verse:"并要发烈怒，用刀杀你们，使你们的妻子为寡妇，儿女为孤儿。"},
{volumn:         2,chapter:        22,versenumber:        25,verse:"“我民中有贫穷人与你同住，你若借钱给他，不可如放债的向他取利。"},
{volumn:         2,chapter:        22,versenumber:        26,verse:"你即或拿邻舍的衣服作当头，必在日落以先归还他；"},
{volumn:         2,chapter:        22,versenumber:        27,verse:"因他只有这一件当盖头，是他盖身的衣服，若是没有，他拿什么睡觉呢？他哀求我，我就应允，因为我是有恩惠的。"},
{volumn:         2,chapter:        22,versenumber:        28,verse:"“不可毁谤　神；也不可毁谤你百姓的官长。"},
{volumn:         2,chapter:        22,versenumber:        29,verse:"“你要从你庄稼中的谷和酒榨中滴出来的酒拿来献上，不可迟延。	“你要将头生的儿子归给我。"},
{volumn:         2,chapter:        22,versenumber:        30,verse:"你牛羊头生的，也要这样；七天当跟着母，第八天要归给我。"},
{volumn:         2,chapter:        22,versenumber:        31,verse:"“你们要在我面前为圣洁的人。因此，田间被野兽撕裂牲畜的肉，你们不可吃，要丢给狗吃。”"},
{volumn:         2,chapter:        23,versenumber:         1,verse:"“不可随伙布散谣言；不可与恶人连手妄作见证。"},
{volumn:         2,chapter:        23,versenumber:         2,verse:"不可随众行恶；不可在争讼的事上随众偏行，作见证屈枉正直；"},
{volumn:         2,chapter:        23,versenumber:         3,verse:"也不可在争讼的事上偏护穷人。"},
{volumn:         2,chapter:        23,versenumber:         4,verse:"“若遇见你仇敌的牛或驴失迷了路，总要牵回来交给他。"},
{volumn:         2,chapter:        23,versenumber:         5,verse:"若看见恨你人的驴压卧在重驮之下，不可走开，务要和驴主一同抬开重驮。"},
{volumn:         2,chapter:        23,versenumber:         6,verse:"“不可在穷人争讼的事上屈枉正直。"},
{volumn:         2,chapter:        23,versenumber:         7,verse:"当远离虚假的事。不可杀无辜和有义的人，因我必不以恶人为义。"},
{volumn:         2,chapter:        23,versenumber:         8,verse:"不可受贿赂；因为贿赂能叫明眼人变瞎了，又能颠倒义人的话。"},
{volumn:         2,chapter:        23,versenumber:         9,verse:"“不可欺压寄居的；因为你们在埃及地作过寄居的，知道寄居的心。”"},
{volumn:         2,chapter:        23,versenumber:        10,verse:"“六年你要耕种田地，收藏土产，"},
{volumn:         2,chapter:        23,versenumber:        11,verse:"只是第七年要叫地歇息，不耕不种，使你民中的穷人有吃的；他们所剩下的，野兽可以吃。你的葡萄园和橄榄园也要照样办理。"},
{volumn:         2,chapter:        23,versenumber:        12,verse:"“六日你要做工，第七日要安息，使牛、驴可以歇息，并使你婢女的儿子和寄居的都可以舒畅。"},
{volumn:         2,chapter:        23,versenumber:        13,verse:"“凡我对你们说的话，你们要谨守。别神的名，你不可提，也不可从你口中传说。”"},
{volumn:         2,chapter:        23,versenumber:        14,verse:"“一年三次，你要向我守节。"},
{volumn:         2,chapter:        23,versenumber:        15,verse:"你要守除酵节，照我所吩咐你的，在亚笔月内所定的日期，吃无酵饼七天。谁也不可空手朝见我，因为你是这月出了埃及。"},
{volumn:         2,chapter:        23,versenumber:        16,verse:"又要守收割节，所收的是你田间所种、劳碌得来初熟之物。并在年底收藏，要守收藏节。"},
{volumn:         2,chapter:        23,versenumber:        17,verse:"一切的男丁要一年三次朝见主耶和华。"},
{volumn:         2,chapter:        23,versenumber:        18,verse:"“不可将我祭牲的血和有酵的饼一同献上；也不可将我节上祭牲的脂油留到早晨。"},
{volumn:         2,chapter:        23,versenumber:        19,verse:"“地里首先初熟之物要送到耶和华你　神的殿。“不可用山羊羔母的奶煮山羊羔。”"},
{volumn:         2,chapter:        23,versenumber:        20,verse:"“看哪，我差遣使者在你前面，在路上保护你，领你到我所预备的地方去。"},
{volumn:         2,chapter:        23,versenumber:        21,verse:"他是奉我名来的；你们要在他面前谨慎，听从他的话，不可惹（或译：违背）他，因为他必不赦免你们的过犯。"},
{volumn:         2,chapter:        23,versenumber:        22,verse:"“你若实在听从他的话，照着我一切所说的去行，我就向你的仇敌作仇敌，向你的敌人作敌人。"},
{volumn:         2,chapter:        23,versenumber:        23,verse:"“我的使者要在你前面行，领你到亚摩利人、赫人、比利洗人、迦南人、希未人、耶布斯人那里去，我必将他们剪除。"},
{volumn:         2,chapter:        23,versenumber:        24,verse:"你不可跪拜他们的神，不可侍奉他，也不可效法他们的行为，却要把神像尽行拆毁，打碎他们的柱像。"},
{volumn:         2,chapter:        23,versenumber:        25,verse:"你们要侍奉耶和华你们的　神，他必赐福与你的粮与你的水，也必从你们中间除去疾病。"},
{volumn:         2,chapter:        23,versenumber:        26,verse:"你境内必没有坠胎的，不生产的。我要使你满了你年日的数目。"},
{volumn:         2,chapter:        23,versenumber:        27,verse:"凡你所到的地方，我要使那里的众民在你面前惊骇，扰乱，又要使你一切仇敌转背逃跑。"},
{volumn:         2,chapter:        23,versenumber:        28,verse:"我要打发黄蜂飞在你前面，把希未人、迦南人、赫人撵出去。"},
{volumn:         2,chapter:        23,versenumber:        29,verse:"我不在一年之内将他们从你面前撵出去，恐怕地成为荒凉，野地的兽多起来害你。"},
{volumn:         2,chapter:        23,versenumber:        30,verse:"我要渐渐地将他们从你面前撵出去，等到你的人数加多，承受那地为业。"},
{volumn:         2,chapter:        23,versenumber:        31,verse:"我要定你的境界，从红海直到非利士海，又从旷野直到大河。我要将那地的居民交在你手中，你要将他们从你面前撵出去。"},
{volumn:         2,chapter:        23,versenumber:        32,verse:"不可和他们并他们的神立约。"},
{volumn:         2,chapter:        23,versenumber:        33,verse:"他们不可住在你的地上，恐怕他们使你得罪我。你若侍奉他们的神，这必成为你的网罗。”"},
{volumn:         2,chapter:        24,versenumber:         1,verse:"耶和华对摩西说：“你和亚伦、拿答、亚比户，并以色列长老中的七十人，都要上到我这里来，远远地下拜。"},
{volumn:         2,chapter:        24,versenumber:         2,verse:"惟独你可以亲近耶和华；他们却不可亲近；百姓也不可和你一同上来。”"},
{volumn:         2,chapter:        24,versenumber:         3,verse:"摩西下山，将耶和华的命令典章都述说与百姓听。众百姓齐声说：“耶和华所吩咐的，我们都必遵行。”"},
{volumn:         2,chapter:        24,versenumber:         4,verse:"摩西将耶和华的命令都写上。清早起来，在山下筑一座坛，按以色列十二支派立十二根柱子，"},
{volumn:         2,chapter:        24,versenumber:         5,verse:"又打发以色列人中的少年人去献燔祭，又向耶和华献牛为平安祭。"},
{volumn:         2,chapter:        24,versenumber:         6,verse:"摩西将血一半盛在盆中，一半洒在坛上；"},
{volumn:         2,chapter:        24,versenumber:         7,verse:"又将约书念给百姓听。他们说：“耶和华所吩咐的，我们都必遵行。”"},
{volumn:         2,chapter:        24,versenumber:         8,verse:"摩西将血洒在百姓身上，说：“你看！这是立约的血，是耶和华按这一切话与你们立约的凭据。”"},
{volumn:         2,chapter:        24,versenumber:         9,verse:"摩西、亚伦、拿答、亚比户，并以色列长老中的七十人，都上了山。"},
{volumn:         2,chapter:        24,versenumber:        10,verse:"他们看见以色列的　神，他脚下仿佛有平铺的蓝宝石，如同天色明净。"},
{volumn:         2,chapter:        24,versenumber:        11,verse:"他的手不加害在以色列的尊者身上。他们观看　神；他们又吃又喝。"},
{volumn:         2,chapter:        24,versenumber:        12,verse:"耶和华对摩西说：“你上山到我这里来，住在这里，我要将石版并我所写的律法和诫命赐给你，使你可以教训百姓。”"},
{volumn:         2,chapter:        24,versenumber:        13,verse:"摩西和他的帮手约书亚起来，上了　神的山。"},
{volumn:         2,chapter:        24,versenumber:        14,verse:"摩西对长老说：“你们在这里等着，等到我们再回来，有亚伦、户珥与你们同在。凡有争讼的，都可以就近他们去。”"},
{volumn:         2,chapter:        24,versenumber:        15,verse:"摩西上山，有云彩把山遮盖。"},
{volumn:         2,chapter:        24,versenumber:        16,verse:"耶和华的荣耀停于西奈山；云彩遮盖山六天，第七天他从云中召摩西。"},
{volumn:         2,chapter:        24,versenumber:        17,verse:"耶和华的荣耀在山顶上，在以色列人眼前，形状如烈火。"},
{volumn:         2,chapter:        24,versenumber:        18,verse:"摩西进入云中上山，在山上四十昼夜。"},
{volumn:         2,chapter:        25,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        25,versenumber:         2,verse:"“你告诉以色列人当为我送礼物来；凡甘心乐意的，你们就可以收下归我。"},
{volumn:         2,chapter:        25,versenumber:         3,verse:"所要收的礼物：就是金、银、铜，"},
{volumn:         2,chapter:        25,versenumber:         4,verse:"蓝色、紫色、朱红色线，细麻，山羊毛，"},
{volumn:         2,chapter:        25,versenumber:         5,verse:"染红的公羊皮，海狗皮，皂荚木，"},
{volumn:         2,chapter:        25,versenumber:         6,verse:"点灯的油并做膏油和香的香料，"},
{volumn:         2,chapter:        25,versenumber:         7,verse:"红玛瑙与别样的宝石，可以镶嵌在以弗得和胸牌上。"},
{volumn:         2,chapter:        25,versenumber:         8,verse:"又当为我造圣所，使我可以住在他们中间。"},
{volumn:         2,chapter:        25,versenumber:         9,verse:"制造帐幕和其中的一切器具都要照我所指示你的样式。”"},
{volumn:         2,chapter:        25,versenumber:        10,verse:"“要用皂荚木做一柜，长二肘半，宽一肘半，高一肘半。"},
{volumn:         2,chapter:        25,versenumber:        11,verse:"要里外包上精金，四围镶上金牙边。"},
{volumn:         2,chapter:        25,versenumber:        12,verse:"也要铸四个金环，安在柜的四脚上；这边两环，那边两环。"},
{volumn:         2,chapter:        25,versenumber:        13,verse:"要用皂荚木做两根杠，用金包裹。"},
{volumn:         2,chapter:        25,versenumber:        14,verse:"要把杠穿在柜旁的环内，以便抬柜。"},
{volumn:         2,chapter:        25,versenumber:        15,verse:"这杠要常在柜的环内，不可抽出来。"},
{volumn:         2,chapter:        25,versenumber:        16,verse:"必将我所要赐给你的法版放在柜里。"},
{volumn:         2,chapter:        25,versenumber:        17,verse:"要用精金做施恩座（施恩：或译蔽罪；下同），长二肘半，宽一肘半。"},
{volumn:         2,chapter:        25,versenumber:        18,verse:"要用金子锤出两个基路伯来，安在施恩座的两头。"},
{volumn:         2,chapter:        25,versenumber:        19,verse:"这头做一个基路伯，那头做一个基路伯，二基路伯要接连一块，在施恩座的两头。"},
{volumn:         2,chapter:        25,versenumber:        20,verse:"二基路伯要高张翅膀，遮掩施恩座。基路伯要脸对脸，朝着施恩座。"},
{volumn:         2,chapter:        25,versenumber:        21,verse:"要将施恩座安在柜的上边，又将我所要赐给你的法版放在柜里。"},
{volumn:         2,chapter:        25,versenumber:        22,verse:"我要在那里与你相会，又要从法柜施恩座上二基路伯中间，和你说我所要吩咐你传给以色列人的一切事。”"},
{volumn:         2,chapter:        25,versenumber:        23,verse:"“要用皂荚木做一张桌子，长二肘，宽一肘，高一肘半。"},
{volumn:         2,chapter:        25,versenumber:        24,verse:"要包上精金，四围镶上金牙边。"},
{volumn:         2,chapter:        25,versenumber:        25,verse:"桌子的四围各做一掌宽的横梁，横梁上镶着金牙边。"},
{volumn:         2,chapter:        25,versenumber:        26,verse:"要做四个金环，安在桌子的四角上，就是桌子四脚上的四角。"},
{volumn:         2,chapter:        25,versenumber:        27,verse:"安环子的地方要挨近横梁，可以穿杠抬桌子。"},
{volumn:         2,chapter:        25,versenumber:        28,verse:"要用皂荚木做两根杠，用金包裹，以便抬桌子。"},
{volumn:         2,chapter:        25,versenumber:        29,verse:"要做桌子上的盘子、调羹，并奠酒的爵和瓶；这都要用精金制作。"},
{volumn:         2,chapter:        25,versenumber:        30,verse:"又要在桌子上，在我面前，常摆陈设饼。”"},
{volumn:         2,chapter:        25,versenumber:        31,verse:"“要用精金做一个灯台。灯台的座和干与杯、球、花，都要接连一块锤出来。"},
{volumn:         2,chapter:        25,versenumber:        32,verse:"灯台两旁要杈出六个枝子：这旁三个，那旁三个。"},
{volumn:         2,chapter:        25,versenumber:        33,verse:"这旁每枝上有三个杯，形状像杏花，有球，有花；那旁每枝上也有三个杯，形状像杏花，有球，有花。从灯台杈出来的六个枝子都是如此。"},
{volumn:         2,chapter:        25,versenumber:        34,verse:"灯台上有四个杯，形状像杏花，有球，有花。"},
{volumn:         2,chapter:        25,versenumber:        35,verse:"灯台每两个枝子以下有球与枝子接连一块。灯台出的六个枝子都是如此。"},
{volumn:         2,chapter:        25,versenumber:        36,verse:"球和枝子要接连一块，都是一块精金锤出来的。"},
{volumn:         2,chapter:        25,versenumber:        37,verse:"要做灯台的七个灯盏。祭司要点这灯，使灯光对照。"},
{volumn:         2,chapter:        25,versenumber:        38,verse:"灯台的蜡剪和蜡花盘也是要精金的。"},
{volumn:         2,chapter:        25,versenumber:        39,verse:"做灯台和这一切的器具要用精金一他连得。"},
{volumn:         2,chapter:        25,versenumber:        40,verse:"要谨慎做这些物件，都要照着在山上指示你的样式。”"},
{volumn:         2,chapter:        26,versenumber:         1,verse:"“你要用十幅幔子做帐幕。这些幔子要用捻的细麻和蓝色、紫色、朱红色线制造，并用巧匠的手工绣上基路伯。"},
{volumn:         2,chapter:        26,versenumber:         2,verse:"每幅幔子要长二十八肘，宽四肘，幔子都要一样的尺寸。"},
{volumn:         2,chapter:        26,versenumber:         3,verse:"这五幅幔子要幅幅相连；那五幅幔子也要幅幅相连。"},
{volumn:         2,chapter:        26,versenumber:         4,verse:"在这相连的幔子末幅边上要做蓝色的钮扣；在那相连的幔子末幅边上也要照样做。"},
{volumn:         2,chapter:        26,versenumber:         5,verse:"要在这相连的幔子上做五十个钮扣；在那相连的幔子上也做五十个钮扣，都要两两相对。"},
{volumn:         2,chapter:        26,versenumber:         6,verse:"又要做五十个金钩，用钩使幔子相连，这才成了一个帐幕。"},
{volumn:         2,chapter:        26,versenumber:         7,verse:"“你要用山羊毛织十一幅幔子，作为帐幕以上的罩棚。"},
{volumn:         2,chapter:        26,versenumber:         8,verse:"每幅幔子要长三十肘，宽四肘；十一幅幔子都要一样的尺寸。"},
{volumn:         2,chapter:        26,versenumber:         9,verse:"要把五幅幔子连成一幅，又把六幅幔子连成一幅，这第六幅幔子要在罩棚的前面折上去。"},
{volumn:         2,chapter:        26,versenumber:        10,verse:"在这相连的幔子末幅边上要做五十个钮扣；在那相连的幔子末幅边上也做五十个钮扣。"},
{volumn:         2,chapter:        26,versenumber:        11,verse:"又要做五十个铜钩，钩在钮扣中，使罩棚连成一个。"},
{volumn:         2,chapter:        26,versenumber:        12,verse:"罩棚的幔子所余那垂下来的半幅幔子，要垂在帐幕的后头。"},
{volumn:         2,chapter:        26,versenumber:        13,verse:"罩棚的幔子所余长的，这边一肘，那边一肘，要垂在帐幕的两旁，遮盖帐幕。"},
{volumn:         2,chapter:        26,versenumber:        14,verse:"又要用染红的公羊皮做罩棚的盖；再用海狗皮做一层罩棚上的顶盖。"},
{volumn:         2,chapter:        26,versenumber:        15,verse:"“你要用皂荚木做帐幕的竖板。"},
{volumn:         2,chapter:        26,versenumber:        16,verse:"每块要长十肘，宽一肘半；"},
{volumn:         2,chapter:        26,versenumber:        17,verse:"每块必有两榫相对。帐幕一切的板都要这样做。"},
{volumn:         2,chapter:        26,versenumber:        18,verse:"帐幕的南面要做板二十块。"},
{volumn:         2,chapter:        26,versenumber:        19,verse:"在这二十块板底下要做四十个带卯的银座，两卯接这块板上的两榫，两卯接那块板上的两榫。"},
{volumn:         2,chapter:        26,versenumber:        20,verse:"帐幕第二面，就是北面，也要做板二十块，"},
{volumn:         2,chapter:        26,versenumber:        21,verse:"和带卯的银座四十个。这板底下有两卯，那板底下也有两卯。"},
{volumn:         2,chapter:        26,versenumber:        22,verse:"帐幕的后面，就是西面，要做板六块。"},
{volumn:         2,chapter:        26,versenumber:        23,verse:"帐幕后面的拐角要做板两块。"},
{volumn:         2,chapter:        26,versenumber:        24,verse:"板的下半截要双的，上半截要整的，直顶到第一个环子；两块都要这样做两个拐角。"},
{volumn:         2,chapter:        26,versenumber:        25,verse:"必有八块板和十六个带卯的银座；这板底下有两卯，那板底下也有两卯。"},
{volumn:         2,chapter:        26,versenumber:        26,verse:"“你要用皂荚木作闩：为帐幕这面的板作五闩，"},
{volumn:         2,chapter:        26,versenumber:        27,verse:"为帐幕那面的板做五闩，又为帐幕后面的板做五闩。"},
{volumn:         2,chapter:        26,versenumber:        28,verse:"板腰间的中闩要从这一头通到那一头。"},
{volumn:         2,chapter:        26,versenumber:        29,verse:"板要用金子包裹，又要做板上的金环套闩；闩也要用金子包裹。"},
{volumn:         2,chapter:        26,versenumber:        30,verse:"要照着在山上指示你的样式立起帐幕。"},
{volumn:         2,chapter:        26,versenumber:        31,verse:"“你要用蓝色、紫色、朱红色线，和捻的细麻织幔子，以巧匠的手工绣上基路伯。"},
{volumn:         2,chapter:        26,versenumber:        32,verse:"要把幔子挂在四根包金的皂荚木柱子上，柱子上当有金钩，柱子安在四个带卯的银座上。"},
{volumn:         2,chapter:        26,versenumber:        33,verse:"要使幔子垂在钩子下，把法柜抬进幔子内；这幔子要将圣所和至圣所隔开。"},
{volumn:         2,chapter:        26,versenumber:        34,verse:"又要把施恩座安在至圣所内的法柜上，"},
{volumn:         2,chapter:        26,versenumber:        35,verse:"把桌子安在幔子外帐幕的北面；把灯台安在帐幕的南面，彼此相对。"},
{volumn:         2,chapter:        26,versenumber:        36,verse:"“你要拿蓝色、紫色、朱红色线，和捻的细麻，用绣花的手工织帐幕的门帘。"},
{volumn:         2,chapter:        26,versenumber:        37,verse:"要用皂荚木为帘子做五根柱子，用金子包裹。柱子上当有金钩；又要为柱子用铜铸造五个带卯的座。”"},
{volumn:         2,chapter:        27,versenumber:         1,verse:"“你要用皂荚木做坛。这坛要四方的，长五肘，宽五肘，高三肘。"},
{volumn:         2,chapter:        27,versenumber:         2,verse:"要在坛的四拐角上做四个角，与坛接连一块，用铜把坛包裹。"},
{volumn:         2,chapter:        27,versenumber:         3,verse:"要做盆，收去坛上的灰，又做铲子、盘子、肉叉子、火鼎；坛上一切的器具都用铜做。"},
{volumn:         2,chapter:        27,versenumber:         4,verse:"要为坛做一个铜网，在网的四角上做四个铜环，"},
{volumn:         2,chapter:        27,versenumber:         5,verse:"把网安在坛四面的围腰板以下，使网从下达到坛的半腰。"},
{volumn:         2,chapter:        27,versenumber:         6,verse:"又要用皂荚木为坛做杠，用铜包裹。"},
{volumn:         2,chapter:        27,versenumber:         7,verse:"这杠要穿在坛两旁的环子内，用以抬坛。"},
{volumn:         2,chapter:        27,versenumber:         8,verse:"要用板做坛，坛是空的，都照着在山上指示你的样式做。”"},
{volumn:         2,chapter:        27,versenumber:         9,verse:"“你要做帐幕的院子。院子的南面要用捻的细麻做帷子，长一百肘。"},
{volumn:         2,chapter:        27,versenumber:        10,verse:"帷子的柱子要二十根，带卯的铜座二十个。柱子上的钩子和杆子都要用银子做。"},
{volumn:         2,chapter:        27,versenumber:        11,verse:"北面也当有帷子，长一百肘，帷子的柱子二十根，带卯的铜座二十个。柱子上的钩子和杆子都要用银子做。"},
{volumn:         2,chapter:        27,versenumber:        12,verse:"院子的西面当有帷子，宽五十肘，帷子的柱子十根，带卯的座十个。"},
{volumn:         2,chapter:        27,versenumber:        13,verse:"院子的东面要宽五十肘。"},
{volumn:         2,chapter:        27,versenumber:        14,verse:"门这边的帷子要十五肘，帷子的柱子三根，带卯的座三个。"},
{volumn:         2,chapter:        27,versenumber:        15,verse:"门那边的帷子也要十五肘，帷子的柱子三根，带卯的座三个。"},
{volumn:         2,chapter:        27,versenumber:        16,verse:"院子的门当有帘子，长二十肘，要拿蓝色、紫色、朱红色线，和捻的细麻，用绣花的手工织成，柱子四根，带卯的座四个。"},
{volumn:         2,chapter:        27,versenumber:        17,verse:"院子四围一切的柱子都要用银杆连络，柱子上的钩子要用银做，带卯的座要用铜做。"},
{volumn:         2,chapter:        27,versenumber:        18,verse:"院子要长一百肘，宽五十肘，高五肘，帷子要用捻的细麻做，带卯的座要用铜做。"},
{volumn:         2,chapter:        27,versenumber:        19,verse:"帐幕各样用处的器具，并帐幕一切的橛子，和院子里一切的橛子，都要用铜做。”"},
{volumn:         2,chapter:        27,versenumber:        20,verse:"“你要吩咐以色列人，把那为点灯捣成的清橄榄油拿来给你，使灯常常点着。"},
{volumn:         2,chapter:        27,versenumber:        21,verse:"在会幕中法柜前的幔外，亚伦和他的儿子，从晚上到早晨，要在耶和华面前经理这灯。这要作以色列人世世代代永远的定例。”"},
{volumn:         2,chapter:        28,versenumber:         1,verse:"“你要从以色列人中，使你的哥哥亚伦和他的儿子拿答、亚比户、以利亚撒、以他玛一同就近你，给我供祭司的职分。"},
{volumn:         2,chapter:        28,versenumber:         2,verse:"你要给你哥哥亚伦做圣衣为荣耀，为华美。"},
{volumn:         2,chapter:        28,versenumber:         3,verse:"又要吩咐一切心中有智慧的，就是我用智慧的灵所充满的，给亚伦做衣服，使他分别为圣，可以给我供祭司的职分。"},
{volumn:         2,chapter:        28,versenumber:         4,verse:"所要做的就是胸牌、以弗得、外袍、杂色的内袍、冠冕、腰带，使你哥哥亚伦和他儿子穿这圣服，可以给我供祭司的职分。"},
{volumn:         2,chapter:        28,versenumber:         5,verse:"要用金线和蓝色、紫色、朱红色线，并细麻去做。"},
{volumn:         2,chapter:        28,versenumber:         6,verse:"“他们要拿金线和蓝色、紫色、朱红色线，并捻的细麻，用巧匠的手工做以弗得。"},
{volumn:         2,chapter:        28,versenumber:         7,verse:"以弗得当有两条肩带，接上两头，使它相连。"},
{volumn:         2,chapter:        28,versenumber:         8,verse:"其上巧工织的带子，要和以弗得一样的做法，用以束上，与以弗得接连一块，要用金线和蓝色、紫色、朱红色线，并捻的细麻做成。"},
{volumn:         2,chapter:        28,versenumber:         9,verse:"要取两块红玛瑙，在上面刻以色列儿子的名字："},
{volumn:         2,chapter:        28,versenumber:        10,verse:"六个名字在这块宝石上，六个名字在那块宝石上，都照他们生来的次序。"},
{volumn:         2,chapter:        28,versenumber:        11,verse:"要用刻宝石的手工，仿佛刻图书，按着以色列儿子的名字，刻这两块宝石，要镶在金槽上。"},
{volumn:         2,chapter:        28,versenumber:        12,verse:"要将这两块宝石安在以弗得的两条肩带上，为以色列人做纪念石。亚伦要在两肩上担他们的名字，在耶和华面前作为纪念。"},
{volumn:         2,chapter:        28,versenumber:        13,verse:"要用金子做二槽，"},
{volumn:         2,chapter:        28,versenumber:        14,verse:"又拿精金，用拧工仿佛拧绳子，做两条链子，把这拧成的链子搭在二槽上。”"},
{volumn:         2,chapter:        28,versenumber:        15,verse:"“你要用巧匠的手工做一个决断的胸牌。要和以弗得一样的做法：用金线和蓝色、紫色、朱红色线，并捻的细麻做成。"},
{volumn:         2,chapter:        28,versenumber:        16,verse:"这胸牌要四方的，叠为两层，长一虎口，宽一虎口。"},
{volumn:         2,chapter:        28,versenumber:        17,verse:"要在上面镶宝石四行：第一行是红宝石、红璧玺、红玉；"},
{volumn:         2,chapter:        28,versenumber:        18,verse:"第二行是绿宝石、蓝宝石、金钢石；"},
{volumn:         2,chapter:        28,versenumber:        19,verse:"第三行是紫玛瑙、白玛瑙、紫晶；"},
{volumn:         2,chapter:        28,versenumber:        20,verse:"第四行是水苍玉、红玛瑙、碧玉。这都要镶在金槽中。"},
{volumn:         2,chapter:        28,versenumber:        21,verse:"这些宝石都要按着以色列十二个儿子的名字，仿佛刻图书，刻十二个支派的名字。"},
{volumn:         2,chapter:        28,versenumber:        22,verse:"要在胸牌上用精金拧成如绳的链子。"},
{volumn:         2,chapter:        28,versenumber:        23,verse:"在胸牌上也要做两个金环，安在胸牌的两头。"},
{volumn:         2,chapter:        28,versenumber:        24,verse:"要把那两条拧成的金链子，穿过胸牌两头的环子。"},
{volumn:         2,chapter:        28,versenumber:        25,verse:"又要把链子的那两头接在两槽上，安在以弗得前面肩带上。"},
{volumn:         2,chapter:        28,versenumber:        26,verse:"要做两个金环，安在胸牌的两头，在以弗得里面的边上。"},
{volumn:         2,chapter:        28,versenumber:        27,verse:"又要做两个金环，安在以弗得前面两条肩带的下边，挨近相接之处，在以弗得巧工织的带子以上。"},
{volumn:         2,chapter:        28,versenumber:        28,verse:"要用蓝细带子把胸牌的环子与以弗得的环子系住，使胸牌贴在以弗得巧工织的带子上，不可与以弗得离缝。"},
{volumn:         2,chapter:        28,versenumber:        29,verse:"亚伦进圣所的时候，要将决断胸牌，就是刻着以色列儿子名字的，带在胸前，在耶和华面前常作纪念。"},
{volumn:         2,chapter:        28,versenumber:        30,verse:"又要将乌陵和土明放在决断的胸牌里；亚伦进到耶和华面前的时候，要带在胸前，在耶和华面前常将以色列人的决断牌带在胸前。”"},
{volumn:         2,chapter:        28,versenumber:        31,verse:"“你要做以弗得的外袍，颜色全是蓝的。"},
{volumn:         2,chapter:        28,versenumber:        32,verse:"袍上要为头留一领口，口的周围织出领边来，仿佛铠甲的领口，免得破裂。"},
{volumn:         2,chapter:        28,versenumber:        33,verse:"袍子周围底边上要用蓝色、紫色、朱红色线做石榴。在袍子周围的石榴中间要有金铃铛："},
{volumn:         2,chapter:        28,versenumber:        34,verse:"一个金铃铛一个石榴，一个金铃铛一个石榴，在袍子周围的底边上。"},
{volumn:         2,chapter:        28,versenumber:        35,verse:"亚伦供职的时候要穿这袍子。他进圣所到耶和华面前，以及出来的时候，袍上的响声必被听见，使他不至于死亡。"},
{volumn:         2,chapter:        28,versenumber:        36,verse:"“你要用精金做一面牌，在上面按刻图书之法刻着‘归耶和华为圣’。"},
{volumn:         2,chapter:        28,versenumber:        37,verse:"要用一条蓝细带子将牌系在冠冕的前面。"},
{volumn:         2,chapter:        28,versenumber:        38,verse:"这牌必在亚伦的额上，亚伦要担当干犯圣物条例的罪孽；这圣物是以色列人在一切的圣礼物上所分别为圣的。这牌要常在他的额上，使他们可以在耶和华面前蒙悦纳。"},
{volumn:         2,chapter:        28,versenumber:        39,verse:"要用杂色细麻线织内袍，用细麻布做冠冕，又用绣花的手工做腰带。"},
{volumn:         2,chapter:        28,versenumber:        40,verse:"“你要为亚伦的儿子做内袍、腰带、裹头巾，为荣耀，为华美。"},
{volumn:         2,chapter:        28,versenumber:        41,verse:"要把这些给你的哥哥亚伦和他的儿子穿戴，又要膏他们，将他们分别为圣，好给我供祭司的职分。"},
{volumn:         2,chapter:        28,versenumber:        42,verse:"要给他们做细麻布裤子，遮掩下体；裤子当从腰达到大腿。"},
{volumn:         2,chapter:        28,versenumber:        43,verse:"亚伦和他儿子进入会幕，或就近坛，在圣所供职的时候必穿上，免得担罪而死。这要为亚伦和他的后裔作永远的定例。”"},
{volumn:         2,chapter:        29,versenumber:         1,verse:"“你使亚伦和他儿子成圣，给我供祭司的职分，要如此行：取一只公牛犊，两只无残疾的公绵羊，"},
{volumn:         2,chapter:        29,versenumber:         2,verse:"无酵饼和调油的无酵饼，与抹油的无酵薄饼；这都要用细麦面做成。"},
{volumn:         2,chapter:        29,versenumber:         3,verse:"这饼要装在一个筐子里，连筐子带来，又把公牛和两只公绵羊牵来。"},
{volumn:         2,chapter:        29,versenumber:         4,verse:"要使亚伦和他儿子到会幕门口来，用水洗身。"},
{volumn:         2,chapter:        29,versenumber:         5,verse:"要给亚伦穿上内袍和以弗得的外袍，并以弗得，又带上胸牌，束上以弗得巧工织的带子。"},
{volumn:         2,chapter:        29,versenumber:         6,verse:"把冠冕戴在他头上，将圣冠加在冠冕上，"},
{volumn:         2,chapter:        29,versenumber:         7,verse:"就把膏油倒在他头上膏他。"},
{volumn:         2,chapter:        29,versenumber:         8,verse:"要叫他的儿子来，给他们穿上内袍。"},
{volumn:         2,chapter:        29,versenumber:         9,verse:"给亚伦和他儿子束上腰带，包上裹头巾，他们就凭永远的定例得了祭司的职任。又要将亚伦和他儿子分别为圣。"},
{volumn:         2,chapter:        29,versenumber:        10,verse:"“你要把公牛牵到会幕前，亚伦和他儿子要按手在公牛的头上。"},
{volumn:         2,chapter:        29,versenumber:        11,verse:"你要在耶和华面前，在会幕门口，宰这公牛。"},
{volumn:         2,chapter:        29,versenumber:        12,verse:"要取些公牛的血，用指头抹在坛的四角上，把血都倒在坛脚那里。"},
{volumn:         2,chapter:        29,versenumber:        13,verse:"要把一切盖脏的脂油与肝上的网子，并两个腰子和腰子上的脂油，都烧在坛上。"},
{volumn:         2,chapter:        29,versenumber:        14,verse:"只是公牛的皮、肉、粪都要用火烧在营外。这牛是赎罪祭。"},
{volumn:         2,chapter:        29,versenumber:        15,verse:"“你要牵一只公绵羊来，亚伦和他儿子要按手在这羊的头上。"},
{volumn:         2,chapter:        29,versenumber:        16,verse:"要宰这羊，把血洒在坛的周围。"},
{volumn:         2,chapter:        29,versenumber:        17,verse:"要把羊切成块子，洗净五脏和腿，连块子带头，都放在一处。"},
{volumn:         2,chapter:        29,versenumber:        18,verse:"要把全羊烧在坛上，是给耶和华献的燔祭，是献给耶和华为馨香的火祭。"},
{volumn:         2,chapter:        29,versenumber:        19,verse:"“你要将那一只公绵羊牵来，亚伦和他儿子要按手在羊的头上。"},
{volumn:         2,chapter:        29,versenumber:        20,verse:"你要宰这羊，取点血抹在亚伦的右耳垂上和他儿子的右耳垂上，又抹在他们右手的大拇指上和右脚的大拇指上；并要把血洒在坛的四围。"},
{volumn:         2,chapter:        29,versenumber:        21,verse:"你要取点膏油和坛上的血，弹在亚伦和他的衣服上，并他儿子和他儿子的衣服上，他们和他们的衣服就一同成圣。"},
{volumn:         2,chapter:        29,versenumber:        22,verse:"“你要取这羊的脂油和肥尾巴，并盖脏的脂油与肝上的网子，两个腰子和腰子上的脂油并右腿（这是承接圣职所献的羊）。"},
{volumn:         2,chapter:        29,versenumber:        23,verse:"再从耶和华面前装无酵饼的筐子中取一个饼，一个调油的饼和一个薄饼，"},
{volumn:         2,chapter:        29,versenumber:        24,verse:"都放在亚伦的手上和他儿子的手上，作为摇祭，在耶和华面前摇一摇。"},
{volumn:         2,chapter:        29,versenumber:        25,verse:"要从他们手中接过来，烧在耶和华面前坛上的燔祭上，是献给耶和华为馨香的火祭。"},
{volumn:         2,chapter:        29,versenumber:        26,verse:"“你要取亚伦承接圣职所献公羊的胸，作为摇祭，在耶和华面前摇一摇，这就可以作你的份。"},
{volumn:         2,chapter:        29,versenumber:        27,verse:"那摇祭的胸和举祭的腿，就是承接圣职所摇的、所举的，是归亚伦和他儿子的。这些你都要成为圣，"},
{volumn:         2,chapter:        29,versenumber:        28,verse:"作亚伦和他子孙从以色列人中永远所得的份，因为是举祭。这要从以色列人的平安祭中，作为献给耶和华的举祭。"},
{volumn:         2,chapter:        29,versenumber:        29,verse:"“亚伦的圣衣要留给他的子孙，可以穿着受膏，又穿着承接圣职。"},
{volumn:         2,chapter:        29,versenumber:        30,verse:"他的子孙接续他当祭司的，每逢进会幕在圣所供职的时候，要穿七天。"},
{volumn:         2,chapter:        29,versenumber:        31,verse:"“你要将承接圣职所献公羊的肉煮在圣处。"},
{volumn:         2,chapter:        29,versenumber:        32,verse:"亚伦和他儿子要在会幕门口吃这羊的肉和筐内的饼。"},
{volumn:         2,chapter:        29,versenumber:        33,verse:"他们吃那些赎罪之物，好承接圣职，使他们成圣；只是外人不可吃，因为这是圣物。"},
{volumn:         2,chapter:        29,versenumber:        34,verse:"那承接圣职所献的肉或饼，若有一点留到早晨，就要用火烧了，不可吃这物，因为是圣物。"},
{volumn:         2,chapter:        29,versenumber:        35,verse:"“你要这样照我一切所吩咐的，向亚伦和他儿子行承接圣职的礼七天。"},
{volumn:         2,chapter:        29,versenumber:        36,verse:"每天要献公牛一只为赎罪祭。你洁净坛的时候，坛就洁净了；且要用膏抹坛，使坛成圣。"},
{volumn:         2,chapter:        29,versenumber:        37,verse:"要洁净坛七天，使坛成圣，坛就成为至圣。凡挨着坛的都成为圣。”"},
{volumn:         2,chapter:        29,versenumber:        38,verse:"“你每天所要献在坛上的就是两只一岁的羊羔；"},
{volumn:         2,chapter:        29,versenumber:        39,verse:"早晨要献这一只，黄昏的时候要献那一只。"},
{volumn:         2,chapter:        29,versenumber:        40,verse:"和这一只羊羔同献的，要用细面伊法十分之一与捣成的油一欣四分之一调和，又用酒一欣四分之一作为奠祭。"},
{volumn:         2,chapter:        29,versenumber:        41,verse:"那一只羊羔要在黄昏的时候献上，照着早晨的素祭和奠祭的礼办理，作为献给耶和华馨香的火祭。"},
{volumn:         2,chapter:        29,versenumber:        42,verse:"这要在耶和华面前、会幕门口，作你们世世代代常献的燔祭。我要在那里与你们相会，和你们说话。"},
{volumn:         2,chapter:        29,versenumber:        43,verse:"我要在那里与以色列人相会，会幕就要因我的荣耀成为圣。"},
{volumn:         2,chapter:        29,versenumber:        44,verse:"我要使会幕和坛成圣，也要使亚伦和他的儿子成圣，给我供祭司的职分。"},
{volumn:         2,chapter:        29,versenumber:        45,verse:"我要住在以色列人中间，作他们的　神。"},
{volumn:         2,chapter:        29,versenumber:        46,verse:"他们必知道我是耶和华他们的　神，是将他们从埃及地领出来的，为要住在他们中间。我是耶和华他们的　神。”"},
{volumn:         2,chapter:        30,versenumber:         1,verse:"“你要用皂荚木做一座烧香的坛。"},
{volumn:         2,chapter:        30,versenumber:         2,verse:"这坛要四方的，长一肘，宽一肘，高二肘；坛的四角要与坛接连一块。"},
{volumn:         2,chapter:        30,versenumber:         3,verse:"要用精金把坛的上面与坛的四围，并坛的四角，包裹；又要在坛的四围镶上金牙边。"},
{volumn:         2,chapter:        30,versenumber:         4,verse:"要做两个金环安在牙子边以下，在坛的两旁，两根横撑上，作为穿杠的用处，以便抬坛。"},
{volumn:         2,chapter:        30,versenumber:         5,verse:"要用皂荚木做杠，用金包裹。"},
{volumn:         2,chapter:        30,versenumber:         6,verse:"要把坛放在法柜前的幔子外，对着法柜上的施恩座，就是我要与你相会的地方。"},
{volumn:         2,chapter:        30,versenumber:         7,verse:"亚伦在坛上要烧馨香料做的香；每早晨他收拾灯的时候，要烧这香。"},
{volumn:         2,chapter:        30,versenumber:         8,verse:"黄昏点灯的时候，他要在耶和华面前烧这香，作为世世代代常烧的香。"},
{volumn:         2,chapter:        30,versenumber:         9,verse:"在这坛上不可奉上异样的香，不可献燔祭、素祭，也不可浇上奠祭。"},
{volumn:         2,chapter:        30,versenumber:        10,verse:"亚伦一年一次要在坛的角上行赎罪之礼。他一年一次要用赎罪祭牲的血在坛上行赎罪之礼，作为世世代代的定例。这坛在耶和华面前为至圣。”"},
{volumn:         2,chapter:        30,versenumber:        11,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        30,versenumber:        12,verse:"“你要按以色列人被数的，计算总数，你数的时候，他们各人要为自己的生命把赎价奉给耶和华，免得数的时候在他们中间有灾殃。"},
{volumn:         2,chapter:        30,versenumber:        13,verse:"凡过去归那些被数之人的，每人要按圣所的平，拿银子半舍客勒；这半舍客勒是奉给耶和华的礼物（一舍客勒是二十季拉）。"},
{volumn:         2,chapter:        30,versenumber:        14,verse:"凡过去归那些被数的人，从二十岁以外的，要将这礼物奉给耶和华。"},
{volumn:         2,chapter:        30,versenumber:        15,verse:"他们为赎生命将礼物奉给耶和华，富足的不可多出，贫穷的也不可少出，各人要出半舍客勒。"},
{volumn:         2,chapter:        30,versenumber:        16,verse:"你要从以色列人收这赎罪银，作为会幕的使用，可以在耶和华面前为以色列人作纪念，赎生命。”"},
{volumn:         2,chapter:        30,versenumber:        17,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        30,versenumber:        18,verse:"“你要用铜做洗濯盆和盆座，以便洗濯。要将盆放在会幕和坛的中间，在盆里盛水。"},
{volumn:         2,chapter:        30,versenumber:        19,verse:"亚伦和他的儿子要在这盆里洗手洗脚。"},
{volumn:         2,chapter:        30,versenumber:        20,verse:"他们进会幕，或是就近坛前供职给耶和华献火祭的时候，必用水洗濯，免得死亡。"},
{volumn:         2,chapter:        30,versenumber:        21,verse:"他们洗手洗脚就免得死亡。这要作亚伦和他后裔世世代代永远的定例。”"},
{volumn:         2,chapter:        30,versenumber:        22,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        30,versenumber:        23,verse:"“你要取上品的香料，就是流质的没药五百舍客勒，香肉桂一半，就是二百五十舍客勒，菖蒲二百五十舍客勒，"},
{volumn:         2,chapter:        30,versenumber:        24,verse:"桂皮五百舍客勒，都按着圣所的平，又取橄榄油一欣，"},
{volumn:         2,chapter:        30,versenumber:        25,verse:"按做香之法调和做成圣膏油。"},
{volumn:         2,chapter:        30,versenumber:        26,verse:"要用这膏油抹会幕和法柜，"},
{volumn:         2,chapter:        30,versenumber:        27,verse:"桌子与桌子的一切器具，灯台和灯台的器具，并香坛、"},
{volumn:         2,chapter:        30,versenumber:        28,verse:"燔祭坛，和坛的一切器具，洗濯盆和盆座。"},
{volumn:         2,chapter:        30,versenumber:        29,verse:"要使这些物成为圣，好成为至圣；凡挨着的都成为圣。"},
{volumn:         2,chapter:        30,versenumber:        30,verse:"要膏亚伦和他的儿子，使他们成为圣，可以给我供祭司的职分。"},
{volumn:         2,chapter:        30,versenumber:        31,verse:"你要对以色列人说：‘这油，我要世世代代以为圣膏油。"},
{volumn:         2,chapter:        30,versenumber:        32,verse:"不可倒在别人的身上，也不可按这调和之法做与此相似的。这膏油是圣的，你们也要以为圣。"},
{volumn:         2,chapter:        30,versenumber:        33,verse:"凡调和与此相似的，或将这膏膏在别人身上的，这人要从民中剪除。’”"},
{volumn:         2,chapter:        30,versenumber:        34,verse:"耶和华吩咐摩西说：“你要取馨香的香料，就是拿他弗、施喜列、喜利比拿；这馨香的香料和净乳香各样要一般大的分量。"},
{volumn:         2,chapter:        30,versenumber:        35,verse:"你要用这些加上盐，按做香之法做成清净圣洁的香。"},
{volumn:         2,chapter:        30,versenumber:        36,verse:"这香要取点捣得极细，放在会幕内、法柜前，我要在那里与你相会。你们要以这香为至圣。"},
{volumn:         2,chapter:        30,versenumber:        37,verse:"你们不可按这调和之法为自己做香；要以这香为圣，归耶和华。"},
{volumn:         2,chapter:        30,versenumber:        38,verse:"凡做香和这香一样，为要闻香味的，这人要从民中剪除。”"},
{volumn:         2,chapter:        31,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        31,versenumber:         2,verse:"“看哪，犹大支派中，户珥的孙子、乌利的儿子比撒列，我已经提他的名召他。"},
{volumn:         2,chapter:        31,versenumber:         3,verse:"我也以我的灵充满了他，使他有智慧，有聪明，有知识，能做各样的工，"},
{volumn:         2,chapter:        31,versenumber:         4,verse:"能想出巧工，用金、银、铜制造各物，"},
{volumn:         2,chapter:        31,versenumber:         5,verse:"又能刻宝石，可以镶嵌，能雕刻木头，能做各样的工。"},
{volumn:         2,chapter:        31,versenumber:         6,verse:"我分派但支派中、亚希撒抹的儿子亚何利亚伯与他同工。凡心里有智慧的，我更使他们有智慧，能做我一切所吩咐的，"},
{volumn:         2,chapter:        31,versenumber:         7,verse:"就是会幕和法柜，并其上的施恩座，与会幕中一切的器具，"},
{volumn:         2,chapter:        31,versenumber:         8,verse:"桌子和桌子的器具，精金的灯台和灯台的一切器具并香坛，"},
{volumn:         2,chapter:        31,versenumber:         9,verse:"燔祭坛和坛的一切器具，并洗濯盆与盆座，"},
{volumn:         2,chapter:        31,versenumber:        10,verse:"精工做的礼服，和祭司亚伦并他儿子用以供祭司职分的圣衣，"},
{volumn:         2,chapter:        31,versenumber:        11,verse:"膏油和为圣所用馨香的香料。他们都要照我一切所吩咐的去做。”"},
{volumn:         2,chapter:        31,versenumber:        12,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        31,versenumber:        13,verse:"“你要吩咐以色列人说：‘你们务要守我的安息日；因为这是你我之间世世代代的证据，使你们知道我耶和华是叫你们成为圣的。"},
{volumn:         2,chapter:        31,versenumber:        14,verse:"所以你们要守安息日，以为圣日。凡干犯这日的，必要把他治死；凡在这日做工的，必从民中剪除。"},
{volumn:         2,chapter:        31,versenumber:        15,verse:"六日要做工，但第七日是安息圣日，是向耶和华守为圣的。凡在安息日做工的，必要把他治死。’"},
{volumn:         2,chapter:        31,versenumber:        16,verse:"故此，以色列人要世世代代守安息日为永远的约。"},
{volumn:         2,chapter:        31,versenumber:        17,verse:"这是我和以色列人永远的证据；因为六日之内耶和华造天地，第七日便安息舒畅。”"},
{volumn:         2,chapter:        31,versenumber:        18,verse:"耶和华在西奈山和摩西说完了话，就把两块法版交给他，是　神用指头写的石版。"},
{volumn:         2,chapter:        32,versenumber:         1,verse:"百姓见摩西迟延不下山，就大家聚集到亚伦那里，对他说：“起来！为我们做神像，可以在我们前面引路；因为领我们出埃及地的那个摩西，我们不知道他遭了什么事。”"},
{volumn:         2,chapter:        32,versenumber:         2,verse:"亚伦对他们说：“你们去摘下你们妻子、儿女耳上的金环，拿来给我。”"},
{volumn:         2,chapter:        32,versenumber:         3,verse:"百姓就都摘下他们耳上的金环，拿来给亚伦。"},
{volumn:         2,chapter:        32,versenumber:         4,verse:"亚伦从他们手里接过来，铸了一只牛犊，用雕刻的器具做成。他们就说：“以色列啊，这是领你出埃及地的　神。”"},
{volumn:         2,chapter:        32,versenumber:         5,verse:"亚伦看见，就在牛犊面前筑坛，且宣告说：“明日要向耶和华守节。”"},
{volumn:         2,chapter:        32,versenumber:         6,verse:"次日清早，百姓起来献燔祭和平安祭，就坐下吃喝，起来玩耍。"},
{volumn:         2,chapter:        32,versenumber:         7,verse:"耶和华吩咐摩西说：“下去吧，因为你的百姓，就是你从埃及地领出来的，已经败坏了。"},
{volumn:         2,chapter:        32,versenumber:         8,verse:"他们快快偏离了我所吩咐的道，为自己铸了一只牛犊，向它下拜献祭，说：‘以色列啊，这就是领你出埃及地的神。’”"},
{volumn:         2,chapter:        32,versenumber:         9,verse:"耶和华对摩西说：“我看这百姓真是硬着颈项的百姓。"},
{volumn:         2,chapter:        32,versenumber:        10,verse:"你且由着我，我要向他们发烈怒，将他们灭绝，使你的后裔成为大国。”"},
{volumn:         2,chapter:        32,versenumber:        11,verse:"摩西便恳求耶和华他的　神说：“耶和华啊，你为什么向你的百姓发烈怒呢？这百姓是你用大力和大能的手从埃及地领出来的。"},
{volumn:         2,chapter:        32,versenumber:        12,verse:"为什么使埃及人议论说‘他领他们出去，是要降祸与他们，把他们杀在山中，将他们从地上除灭’？求你转意，不发你的烈怒，后悔，不降祸与你的百姓。"},
{volumn:         2,chapter:        32,versenumber:        13,verse:"求你记念你的仆人亚伯拉罕、以撒、以色列。你曾指着自己起誓说：‘我必使你们的后裔像天上的星那样多，并且我所应许的这全地，必给你们的后裔，他们要永远承受为业。’”"},
{volumn:         2,chapter:        32,versenumber:        14,verse:"于是耶和华后悔，不把所说的祸降与他的百姓。"},
{volumn:         2,chapter:        32,versenumber:        15,verse:"摩西转身下山，手里拿着两块法版。这版是两面写的，这面那面都有字，"},
{volumn:         2,chapter:        32,versenumber:        16,verse:"是　神的工作，字是　神写的，刻在版上。"},
{volumn:         2,chapter:        32,versenumber:        17,verse:"约书亚一听见百姓呼喊的声音，就对摩西说：“在营里有争战的声音。”"},
{volumn:         2,chapter:        32,versenumber:        18,verse:"摩西说：“这不是人打胜仗的声音，也不是人打败仗的声音；我所听见的乃是人歌唱的声音。”"},
{volumn:         2,chapter:        32,versenumber:        19,verse:"摩西挨近营前就看见牛犊，又看见人跳舞，便发烈怒，把两块版扔在山下摔碎了，"},
{volumn:         2,chapter:        32,versenumber:        20,verse:"又将他们所铸的牛犊用火焚烧，磨得粉碎，撒在水面上，叫以色列人喝。"},
{volumn:         2,chapter:        32,versenumber:        21,verse:"摩西对亚伦说：“这百姓向你做了什么？你竟使他们陷在大罪里！”"},
{volumn:         2,chapter:        32,versenumber:        22,verse:"亚伦说：“求我主不要发烈怒。这百姓专于作恶，是你知道的。"},
{volumn:         2,chapter:        32,versenumber:        23,verse:"他们对我说：‘你为我们做神像，可以在我们前面引路；因为领我们出埃及地的那个摩西，我们不知道他遭了什么事。’"},
{volumn:         2,chapter:        32,versenumber:        24,verse:"我对他们说：‘凡有金环的可以摘下来’，他们就给了我。我把金环扔在火中，这牛犊便出来了。”"},
{volumn:         2,chapter:        32,versenumber:        25,verse:"摩西见百姓放肆（亚伦纵容他们，使他们在仇敌中间被讥刺），"},
{volumn:         2,chapter:        32,versenumber:        26,verse:"就站在营门中，说：“凡属耶和华的，都要到我这里来！”于是利未的子孙都到他那里聚集。"},
{volumn:         2,chapter:        32,versenumber:        27,verse:"他对他们说：“耶和华以色列的　神这样说：‘你们各人把刀跨在腰间，在营中往来，从这门到那门，各人杀他的弟兄与同伴并邻舍。’”"},
{volumn:         2,chapter:        32,versenumber:        28,verse:"利未的子孙照摩西的话行了。那一天百姓中被杀的约有三千。"},
{volumn:         2,chapter:        32,versenumber:        29,verse:"摩西说：“今天你们要自洁，归耶和华为圣，各人攻击他的儿子和弟兄，使耶和华赐福与你们。”"},
{volumn:         2,chapter:        32,versenumber:        30,verse:"到了第二天，摩西对百姓说：“你们犯了大罪。我如今要上耶和华那里去，或者可以为你们赎罪。”"},
{volumn:         2,chapter:        32,versenumber:        31,verse:"摩西回到耶和华那里，说：“唉！这百姓犯了大罪，为自己做了金像。"},
{volumn:         2,chapter:        32,versenumber:        32,verse:"倘或你肯赦免他们的罪......不然，求你从你所写的册上涂抹我的名。”"},
{volumn:         2,chapter:        32,versenumber:        33,verse:"耶和华对摩西说：“谁得罪我，我就从我的册上涂抹谁的名。"},
{volumn:         2,chapter:        32,versenumber:        34,verse:"现在你去领这百姓，往我所告诉你的地方去，我的使者必在你前面引路；只是到我追讨的日子，我必追讨他们的罪。”"},
{volumn:         2,chapter:        32,versenumber:        35,verse:"耶和华杀百姓的缘故是因他们同亚伦做了牛犊。"},
{volumn:         2,chapter:        33,versenumber:         1,verse:"耶和华吩咐摩西说：“我曾起誓应许亚伯拉罕、以撒、雅各说：‘要将迦南地赐给你的后裔。’现在你和你从埃及地所领出来的百姓，要从这里往那地去。"},
{volumn:         2,chapter:        33,versenumber:         2,verse:"我要差遣使者在你前面，撵出迦南人、亚摩利人、赫人、比利洗人、希未人、耶布斯人，"},
{volumn:         2,chapter:        33,versenumber:         3,verse:"领你到那流奶与蜜之地。我自己不同你们上去；因为你们是硬着颈项的百姓，恐怕我在路上把你们灭绝。”"},
{volumn:         2,chapter:        33,versenumber:         4,verse:"百姓听见这凶信就悲哀，也没有人佩戴妆饰。"},
{volumn:         2,chapter:        33,versenumber:         5,verse:"耶和华对摩西说：“你告诉以色列人说：‘耶和华说：你们是硬着颈项的百姓，我若一霎时临到你们中间，必灭绝你们。现在你们要把身上的妆饰摘下来，使我可以知道怎样待你们。’”"},
{volumn:         2,chapter:        33,versenumber:         6,verse:"以色列人从住何烈山以后，就把身上的妆饰摘得干净。"},
{volumn:         2,chapter:        33,versenumber:         7,verse:"摩西素常将帐棚支搭在营外，离营却远，他称这帐棚为会幕。凡求问耶和华的，就到营外的会幕那里去。"},
{volumn:         2,chapter:        33,versenumber:         8,verse:"当摩西出营到会幕去的时候，百姓就都起来，各人站在自己帐棚的门口，望着摩西，直等到他进了会幕。"},
{volumn:         2,chapter:        33,versenumber:         9,verse:"摩西进会幕的时候，云柱降下来，立在会幕的门前，耶和华便与摩西说话。"},
{volumn:         2,chapter:        33,versenumber:        10,verse:"众百姓看见云柱立在会幕门前，就都起来，各人在自己帐棚的门口下拜。"},
{volumn:         2,chapter:        33,versenumber:        11,verse:"耶和华与摩西面对面说话，好像人与朋友说话一般。摩西转到营里去，惟有他的帮手一个少年人嫩的儿子约书亚不离开会幕。"},
{volumn:         2,chapter:        33,versenumber:        12,verse:"摩西对耶和华说：“你吩咐我说：‘将这百姓领上去’，却没有叫我知道你要打发谁与我同去，只说：‘我按你的名认识你，你在我眼前也蒙了恩。’"},
{volumn:         2,chapter:        33,versenumber:        13,verse:"我如今若在你眼前蒙恩，求你将你的道指示我，使我可以认识你，好在你眼前蒙恩。求你想到这民是你的民。”"},
{volumn:         2,chapter:        33,versenumber:        14,verse:"耶和华说：“我必亲自和你同去，使你得安息。”"},
{volumn:         2,chapter:        33,versenumber:        15,verse:"摩西说：“你若不亲自和我同去，就不要把我们从这里领上去。"},
{volumn:         2,chapter:        33,versenumber:        16,verse:"人在何事上得以知道我和你的百姓在你眼前蒙恩呢？岂不是因你与我们同去、使我和你的百姓与地上的万民有分别吗？”"},
{volumn:         2,chapter:        33,versenumber:        17,verse:"耶和华对摩西说：“你这所求的我也要行；因为你在我眼前蒙了恩，并且我按你的名认识你。”"},
{volumn:         2,chapter:        33,versenumber:        18,verse:"摩西说：“求你显出你的荣耀给我看。”"},
{volumn:         2,chapter:        33,versenumber:        19,verse:"耶和华说：“我要显我一切的恩慈，在你面前经过，宣告我的名。我要恩待谁就恩待谁；要怜悯谁就怜悯谁”；"},
{volumn:         2,chapter:        33,versenumber:        20,verse:"又说：“你不能看见我的面，因为人见我的面不能存活。”"},
{volumn:         2,chapter:        33,versenumber:        21,verse:"耶和华说：“看哪，在我这里有地方，你要站在磐石上。"},
{volumn:         2,chapter:        33,versenumber:        22,verse:"我的荣耀经过的时候，我必将你放在磐石穴中，用我的手遮掩你，等我过去，"},
{volumn:         2,chapter:        33,versenumber:        23,verse:"然后我要将我的手收回，你就得见我的背，却不得见我的面。”"},
{volumn:         2,chapter:        34,versenumber:         1,verse:"耶和华吩咐摩西说：“你要凿出两块石版，和先前你摔碎的那版一样；其上的字我要写在这版上。"},
{volumn:         2,chapter:        34,versenumber:         2,verse:"明日早晨，你要预备好了，上西奈山，在山顶上站在我面前。"},
{volumn:         2,chapter:        34,versenumber:         3,verse:"谁也不可和你一同上去，遍山都不可有人，在山根也不可叫羊群牛群吃草。”"},
{volumn:         2,chapter:        34,versenumber:         4,verse:"摩西就凿出两块石版，和先前的一样。清晨起来，照耶和华所吩咐的上西奈山去，手里拿着两块石版。"},
{volumn:         2,chapter:        34,versenumber:         5,verse:"耶和华在云中降临，和摩西一同站在那里，宣告耶和华的名。"},
{volumn:         2,chapter:        34,versenumber:         6,verse:"耶和华在他面前宣告说：“耶和华，耶和华，是有怜悯有恩典的　神，不轻易发怒，并有丰盛的慈爱和诚实，"},
{volumn:         2,chapter:        34,versenumber:         7,verse:"为千万人存留慈爱，赦免罪孽、过犯，和罪恶，万不以有罪的为无罪，必追讨他的罪，自父及子，直到三、四代。”"},
{volumn:         2,chapter:        34,versenumber:         8,verse:"摩西急忙伏地下拜，"},
{volumn:         2,chapter:        34,versenumber:         9,verse:"说：“主啊，我若在你眼前蒙恩，求你在我们中间同行，因为这是硬着颈项的百姓。又求你赦免我们的罪孽和罪恶，以我们为你的产业。”"},
{volumn:         2,chapter:        34,versenumber:        10,verse:"耶和华说：“我要立约，要在百姓面前行奇妙的事，是在遍地万国中所未曾行的。在你四围的外邦人就要看见耶和华的作为，因我向你所行的是可畏惧的事。"},
{volumn:         2,chapter:        34,versenumber:        11,verse:"“我今天所吩咐你的，你要谨守。我要从你面前撵出亚摩利人、迦南人、赫人、比利洗人、希未人、耶布斯人。"},
{volumn:         2,chapter:        34,versenumber:        12,verse:"你要谨慎，不可与你所去那地的居民立约，恐怕成为你们中间的网罗；"},
{volumn:         2,chapter:        34,versenumber:        13,verse:"却要拆毁他们的祭坛，打碎他们的柱像，砍下他们的木偶。"},
{volumn:         2,chapter:        34,versenumber:        14,verse:"不可敬拜别神；因为耶和华是忌邪的　神，名为忌邪者。"},
{volumn:         2,chapter:        34,versenumber:        15,verse:"只怕你与那地的居民立约，百姓随从他们的神，就行邪淫，祭祀他们的神，有人叫你，你便吃他的祭物，"},
{volumn:         2,chapter:        34,versenumber:        16,verse:"又为你的儿子娶他们的女儿为妻，他们的女儿随从她们的神，就行邪淫，使你的儿子也随从她们的神行邪淫。"},
{volumn:         2,chapter:        34,versenumber:        17,verse:"“不可为自己铸造神像。"},
{volumn:         2,chapter:        34,versenumber:        18,verse:"“你要守除酵节，照我所吩咐你的，在亚笔月内所定的日期吃无酵饼七天，因为你是这亚笔月内出了埃及。"},
{volumn:         2,chapter:        34,versenumber:        19,verse:"凡头生的都是我的；一切牲畜头生的，无论是牛是羊，公的都是我的。"},
{volumn:         2,chapter:        34,versenumber:        20,verse:"头生的驴要用羊羔代赎，若不代赎就要打折它的颈项。凡头生的儿子都要赎出来。谁也不可空手朝见我。"},
{volumn:         2,chapter:        34,versenumber:        21,verse:"“你六日要做工，第七日要安息，虽在耕种收割的时候也要安息。"},
{volumn:         2,chapter:        34,versenumber:        22,verse:"在收割初熟麦子的时候要守七七节；又在年底要守收藏节。"},
{volumn:         2,chapter:        34,versenumber:        23,verse:"你们一切男丁要一年三次朝见主耶和华以色列的　神。"},
{volumn:         2,chapter:        34,versenumber:        24,verse:"我要从你面前赶出外邦人，扩张你的境界。你一年三次上去朝见耶和华你　神的时候，必没有人贪慕你的地土。"},
{volumn:         2,chapter:        34,versenumber:        25,verse:"“你不可将我祭物的血和有酵的饼一同献上。逾越节的祭物也不可留到早晨。"},
{volumn:         2,chapter:        34,versenumber:        26,verse:"地里首先初熟之物要送到耶和华你　神的殿。不可用山羊羔母的奶煮山羊羔。”"},
{volumn:         2,chapter:        34,versenumber:        27,verse:"耶和华吩咐摩西说：“你要将这些话写上，因为我是按这话与你和以色列人立约。”"},
{volumn:         2,chapter:        34,versenumber:        28,verse:"摩西在耶和华那里四十昼夜，也不吃饭也不喝水。耶和华将这约的话，就是十条诫，写在两块版上。"},
{volumn:         2,chapter:        34,versenumber:        29,verse:"摩西手里拿着两块法版下西奈山的时候，不知道自己的面皮因耶和华和他说话就发了光。"},
{volumn:         2,chapter:        34,versenumber:        30,verse:"亚伦和以色列众人看见摩西的面皮发光就怕挨近他。"},
{volumn:         2,chapter:        34,versenumber:        31,verse:"摩西叫他们来；于是亚伦和会众的官长都到他那里去，摩西就与他们说话。"},
{volumn:         2,chapter:        34,versenumber:        32,verse:"随后以色列众人都近前来，他就把耶和华在西奈山与他所说的一切话都吩咐他们。"},
{volumn:         2,chapter:        34,versenumber:        33,verse:"摩西与他们说完了话就用帕子蒙上脸。"},
{volumn:         2,chapter:        34,versenumber:        34,verse:"但摩西进到耶和华面前与他说话就揭去帕子，及至出来的时候便将耶和华所吩咐的告诉以色列人。"},
{volumn:         2,chapter:        34,versenumber:        35,verse:"以色列人看见摩西的面皮发光。摩西又用帕子蒙上脸，等到他进去与耶和华说话就揭去帕子。"},
{volumn:         2,chapter:        35,versenumber:         1,verse:"摩西招聚以色列全会众，对他们说：“这是耶和华所吩咐的话，叫你们照着行："},
{volumn:         2,chapter:        35,versenumber:         2,verse:"六日要做工，第七日乃为圣日，当向耶和华守为安息圣日。凡这日之内做工的，必把他治死。"},
{volumn:         2,chapter:        35,versenumber:         3,verse:"当安息日，不可在你们一切的住处生火。”"},
{volumn:         2,chapter:        35,versenumber:         4,verse:"摩西对以色列全会众说：“耶和华所吩咐的是这样："},
{volumn:         2,chapter:        35,versenumber:         5,verse:"你们中间要拿礼物献给耶和华，凡乐意献的可以拿耶和华的礼物来，就是金、银、铜，"},
{volumn:         2,chapter:        35,versenumber:         6,verse:"蓝色、紫色、朱红色线，细麻，山羊毛，"},
{volumn:         2,chapter:        35,versenumber:         7,verse:"染红的公羊皮，海狗皮，皂荚木，"},
{volumn:         2,chapter:        35,versenumber:         8,verse:"点灯的油，并做膏油和香的香料，"},
{volumn:         2,chapter:        35,versenumber:         9,verse:"红玛瑙与别样的宝石，可以镶嵌在以弗得和胸牌上。”"},
{volumn:         2,chapter:        35,versenumber:        10,verse:"“你们中间凡心里有智慧的都要来做耶和华一切所吩咐的："},
{volumn:         2,chapter:        35,versenumber:        11,verse:"就是帐幕和帐幕的罩棚，并帐幕的盖、钩子、板、闩、柱子、带卯的座，"},
{volumn:         2,chapter:        35,versenumber:        12,verse:"柜和柜的杠，施恩座和遮掩柜的幔子，"},
{volumn:         2,chapter:        35,versenumber:        13,verse:"桌子和桌子的杠与桌子的一切器具，并陈设饼，"},
{volumn:         2,chapter:        35,versenumber:        14,verse:"灯台和灯台的器具，灯盏并点灯的油，"},
{volumn:         2,chapter:        35,versenumber:        15,verse:"香坛和坛的杠，膏油和馨香的香料，并帐幕门口的帘子，"},
{volumn:         2,chapter:        35,versenumber:        16,verse:"燔祭坛和坛的铜网，坛的杠并坛的一切器具，洗濯盆和盆座，"},
{volumn:         2,chapter:        35,versenumber:        17,verse:"院子的帷子和帷子的柱子，带卯的座和院子的门帘，"},
{volumn:         2,chapter:        35,versenumber:        18,verse:"帐幕的橛子并院子的橛子，和这两处的绳子，"},
{volumn:         2,chapter:        35,versenumber:        19,verse:"精工做的礼服和祭司亚伦并他儿子在圣所用以供祭司职分的圣衣。”"},
{volumn:         2,chapter:        35,versenumber:        20,verse:"以色列全会众从摩西面前退去。"},
{volumn:         2,chapter:        35,versenumber:        21,verse:"凡心里受感和甘心乐意的都拿耶和华的礼物来，用以做会幕和其中一切的使用，又用以做圣衣。"},
{volumn:         2,chapter:        35,versenumber:        22,verse:"凡心里乐意献礼物的，连男带女，各将金器，就是胸前针、耳环（或译：鼻环）、打印的戒指，和手钏带来献给耶和华。"},
{volumn:         2,chapter:        35,versenumber:        23,verse:"凡有蓝色、紫色、朱红色线，细麻，山羊毛，染红的公羊皮，海狗皮的，都拿了来；"},
{volumn:         2,chapter:        35,versenumber:        24,verse:"凡献银子和铜给耶和华为礼物的都拿了来；凡有皂荚木可做什么使用的也拿了来。"},
{volumn:         2,chapter:        35,versenumber:        25,verse:"凡心中有智慧的妇女亲手纺线，把所纺的蓝色、紫色、朱红色线，和细麻都拿了来。"},
{volumn:         2,chapter:        35,versenumber:        26,verse:"凡有智慧、心里受感的妇女就纺山羊毛。"},
{volumn:         2,chapter:        35,versenumber:        27,verse:"众官长把红玛瑙和别样的宝石，可以镶嵌在以弗得与胸牌上的，都拿了来；"},
{volumn:         2,chapter:        35,versenumber:        28,verse:"又拿香料做香，拿油点灯，做膏油。"},
{volumn:         2,chapter:        35,versenumber:        29,verse:"以色列人，无论男女，凡甘心乐意献礼物给耶和华的，都将礼物拿来，做耶和华藉摩西所吩咐的一切工。"},
{volumn:         2,chapter:        35,versenumber:        30,verse:"摩西对以色列人说：“犹大支派中，户珥的孙子、乌利的儿子比撒列，耶和华已经提他的名召他，"},
{volumn:         2,chapter:        35,versenumber:        31,verse:"又以　神的灵充满了他，使他有智慧、聪明、知识，能做各样的工，"},
{volumn:         2,chapter:        35,versenumber:        32,verse:"能想出巧工，用金、银、铜制造各物，"},
{volumn:         2,chapter:        35,versenumber:        33,verse:"又能刻宝石，可以镶嵌，能雕刻木头，能做各样的巧工。"},
{volumn:         2,chapter:        35,versenumber:        34,verse:"耶和华又使他，和但支派中亚希撒抹的儿子亚何利亚伯，心里灵明，能教导人。"},
{volumn:         2,chapter:        35,versenumber:        35,verse:"耶和华使他们的心满有智慧，能做各样的工，无论是雕刻的工，巧匠的工，用蓝色、紫色、朱红色线，和细麻、绣花的工，并机匠的工，他们都能做，也能想出奇巧的工。"},
{volumn:         2,chapter:        36,versenumber:         1,verse:"“比撒列和亚何利亚伯，并一切心里有智慧的，就是蒙耶和华赐智慧聪明、叫他知道做圣所各样使用之工的，都要照耶和华所吩咐的做工。”"},
{volumn:         2,chapter:        36,versenumber:         2,verse:"凡耶和华赐他心里有智慧、而且受感前来做这工的，摩西把他们和比撒列并亚何利亚伯一同召来。"},
{volumn:         2,chapter:        36,versenumber:         3,verse:"这些人就从摩西收了以色列人为做圣所并圣所使用之工所拿来的礼物。百姓每早晨还把甘心献的礼物拿来。"},
{volumn:         2,chapter:        36,versenumber:         4,verse:"凡做圣所一切工的智慧人各都离开他所做的工，"},
{volumn:         2,chapter:        36,versenumber:         5,verse:"来对摩西说：“百姓为耶和华吩咐使用之工所拿来的，富富有余。”"},
{volumn:         2,chapter:        36,versenumber:         6,verse:"摩西传命，他们就在全营中宣告说：“无论男女，不必再为圣所拿什么礼物来。”这样才拦住百姓不再拿礼物来。"},
{volumn:         2,chapter:        36,versenumber:         7,verse:"因为他们所有的材料够做一切当做的物，而且有余。"},
{volumn:         2,chapter:        36,versenumber:         8,verse:"他们中间，凡心里有智慧做工的，用十幅幔子做帐幕。这幔子是比撒列用捻的细麻和蓝色、紫色、朱红色线制造的，并用巧匠的手工绣上基路伯。"},
{volumn:         2,chapter:        36,versenumber:         9,verse:"每幅幔子长二十八肘，宽四肘，都是一样的尺寸。"},
{volumn:         2,chapter:        36,versenumber:        10,verse:"他使这五幅幔子幅幅相连，又使那五幅幔子幅幅相连；"},
{volumn:         2,chapter:        36,versenumber:        11,verse:"在这相连的幔子末幅边上做蓝色的钮扣，在那相连的幔子末幅边上也照样做；"},
{volumn:         2,chapter:        36,versenumber:        12,verse:"在这相连的幔子上做五十个钮扣，在那相连的幔子上也做五十个钮扣，都是两两相对；"},
{volumn:         2,chapter:        36,versenumber:        13,verse:"又做五十个金钩，使幔子相连。这才成了一个帐幕。"},
{volumn:         2,chapter:        36,versenumber:        14,verse:"他用山羊毛织十一幅幔子，作为帐幕以上的罩棚。"},
{volumn:         2,chapter:        36,versenumber:        15,verse:"每幅幔子长三十肘，宽四肘；十一幅幔子都是一样的尺寸。"},
{volumn:         2,chapter:        36,versenumber:        16,verse:"他把五幅幔子连成一幅，又把六幅幔子连成一幅；"},
{volumn:         2,chapter:        36,versenumber:        17,verse:"在这相连的幔子末幅边上做五十个钮扣，在那相连的幔子末幅边上也做五十个钮扣；"},
{volumn:         2,chapter:        36,versenumber:        18,verse:"又做五十个铜钩，使罩棚连成一个；"},
{volumn:         2,chapter:        36,versenumber:        19,verse:"并用染红的公羊皮做罩棚的盖，再用海狗皮做一层罩棚上的顶盖。"},
{volumn:         2,chapter:        36,versenumber:        20,verse:"他用皂荚木做帐幕的竖板。"},
{volumn:         2,chapter:        36,versenumber:        21,verse:"每块长十肘，宽一肘半；"},
{volumn:         2,chapter:        36,versenumber:        22,verse:"每块有两榫相对。帐幕一切的板都是这样做。"},
{volumn:         2,chapter:        36,versenumber:        23,verse:"帐幕的南面做板二十块。"},
{volumn:         2,chapter:        36,versenumber:        24,verse:"在这二十块板底下又做四十个带卯的银座：两卯接这块板上的两榫，两卯接那块板上的两榫。"},
{volumn:         2,chapter:        36,versenumber:        25,verse:"帐幕的第二面，就是北面，也做板二十块"},
{volumn:         2,chapter:        36,versenumber:        26,verse:"和带卯的银座四十个：这板底下有两卯，那板底下也有两卯。"},
{volumn:         2,chapter:        36,versenumber:        27,verse:"帐幕的后面，就是西面，做板六块。"},
{volumn:         2,chapter:        36,versenumber:        28,verse:"帐幕后面的拐角做板两块。"},
{volumn:         2,chapter:        36,versenumber:        29,verse:"板的下半截是双的，上半截是整的，直到第一个环子；在帐幕的两个拐角上都是这样做。"},
{volumn:         2,chapter:        36,versenumber:        30,verse:"有八块板和十六个带卯的银座，每块板底下有两卯。"},
{volumn:         2,chapter:        36,versenumber:        31,verse:"他用皂荚木做闩：为帐幕这面的板做五闩，"},
{volumn:         2,chapter:        36,versenumber:        32,verse:"为帐幕那面的板做五闩，又为帐幕后面的板做五闩，"},
{volumn:         2,chapter:        36,versenumber:        33,verse:"使板腰间的中闩从这一头通到那一头。"},
{volumn:         2,chapter:        36,versenumber:        34,verse:"用金子将板包裹，又做板上的金环套闩；闩也用金子包裹。"},
{volumn:         2,chapter:        36,versenumber:        35,verse:"他用蓝色、紫色、朱红色线，和捻的细麻织幔子，以巧匠的手工绣上基路伯。"},
{volumn:         2,chapter:        36,versenumber:        36,verse:"为幔子做四根皂荚木柱子，用金包裹，柱子上有金钩，又为柱子铸了四个带卯的银座。"},
{volumn:         2,chapter:        36,versenumber:        37,verse:"拿蓝色、紫色、朱红色线，和捻的细麻，用绣花的手工织帐幕的门帘；"},
{volumn:         2,chapter:        36,versenumber:        38,verse:"又为帘子做五根柱子和柱子上的钩子，用金子把柱顶和柱子上的杆子包裹。柱子有五个带卯的座，是铜的。"},
{volumn:         2,chapter:        37,versenumber:         1,verse:"比撒列用皂荚木做柜，长二肘半，宽一肘半，高一肘半。"},
{volumn:         2,chapter:        37,versenumber:         2,verse:"里外包上精金，四围镶上金牙边，"},
{volumn:         2,chapter:        37,versenumber:         3,verse:"又铸四个金环，安在柜的四脚上：这边两环，那边两环。"},
{volumn:         2,chapter:        37,versenumber:         4,verse:"用皂荚木做两根杠，用金包裹。"},
{volumn:         2,chapter:        37,versenumber:         5,verse:"把杠穿在柜旁的环内，以便抬柜。"},
{volumn:         2,chapter:        37,versenumber:         6,verse:"用精金做施恩座，长二肘半，宽一肘半。"},
{volumn:         2,chapter:        37,versenumber:         7,verse:"用金子锤出两个基路伯来，安在施恩座的两头，"},
{volumn:         2,chapter:        37,versenumber:         8,verse:"这头做一个基路伯，那头做一个基路伯，二基路伯接连一块，在施恩座的两头。"},
{volumn:         2,chapter:        37,versenumber:         9,verse:"二基路伯高张翅膀，遮掩施恩座；基路伯是脸对脸，朝着施恩座。"},
{volumn:         2,chapter:        37,versenumber:        10,verse:"他用皂荚木做一张桌子，长二肘，宽一肘，高一肘半，"},
{volumn:         2,chapter:        37,versenumber:        11,verse:"又包上精金，四围镶上金牙边。"},
{volumn:         2,chapter:        37,versenumber:        12,verse:"桌子的四围各做一掌宽的横梁，横梁上镶着金牙边，"},
{volumn:         2,chapter:        37,versenumber:        13,verse:"又铸了四个金环，安在桌子四脚的四角上。"},
{volumn:         2,chapter:        37,versenumber:        14,verse:"安环子的地方是挨近横梁，可以穿杠抬桌子。"},
{volumn:         2,chapter:        37,versenumber:        15,verse:"他用皂荚木做两根杠，用金包裹，以便抬桌子；"},
{volumn:         2,chapter:        37,versenumber:        16,verse:"又用精金做桌子上的器皿，就是盘子、调羹，并奠酒的瓶和爵。"},
{volumn:         2,chapter:        37,versenumber:        17,verse:"他用精金做一个灯台；这灯台的座和干，与杯、球、花，都是接连一块锤出来的。"},
{volumn:         2,chapter:        37,versenumber:        18,verse:"灯台两旁杈出六个枝子：这旁三个，那旁三个。"},
{volumn:         2,chapter:        37,versenumber:        19,verse:"这旁每枝上有三个杯，形状像杏花，有球有花；那旁每枝上也有三个杯，形状像杏花，有球有花。从灯台杈出来的六个枝子都是如此。"},
{volumn:         2,chapter:        37,versenumber:        20,verse:"灯台上有四个杯，形状像杏花，有球有花。"},
{volumn:         2,chapter:        37,versenumber:        21,verse:"灯台每两个枝子以下有球，与枝子接连一块；灯台杈出的六个枝子都是如此。"},
{volumn:         2,chapter:        37,versenumber:        22,verse:"球和枝子是接连一块，都是一块精金锤出来的。"},
{volumn:         2,chapter:        37,versenumber:        23,verse:"用精金做灯台的七个灯盏，并灯台的蜡剪和蜡花盘。"},
{volumn:         2,chapter:        37,versenumber:        24,verse:"他用精金一他连得做灯台和灯台的一切器具。"},
{volumn:         2,chapter:        37,versenumber:        25,verse:"他用皂荚木做香坛，是四方的，长一肘，宽一肘，高二肘，坛的四角与坛接连一块；"},
{volumn:         2,chapter:        37,versenumber:        26,verse:"又用精金把坛的上面与坛的四面并坛的四角包裹，又在坛的四围镶上金牙边。"},
{volumn:         2,chapter:        37,versenumber:        27,verse:"做两个金环，安在牙子边以下，在坛的两旁、两根横撑上，作为穿杠的用处，以便抬坛。"},
{volumn:         2,chapter:        37,versenumber:        28,verse:"用皂荚木做杠，用金包裹。"},
{volumn:         2,chapter:        37,versenumber:        29,verse:"又按做香之法做圣膏油和馨香料的净香。"},
{volumn:         2,chapter:        38,versenumber:         1,verse:"他用皂荚木做燔祭坛，是四方的，长五肘，宽五肘，高三肘，"},
{volumn:         2,chapter:        38,versenumber:         2,verse:"在坛的四拐角上做四个角，与坛接连一块，用铜把坛包裹。"},
{volumn:         2,chapter:        38,versenumber:         3,verse:"他做坛上的盆、铲子、盘子、肉叉子、火鼎；这一切器具都是用铜做的。"},
{volumn:         2,chapter:        38,versenumber:         4,verse:"又为坛做一个铜网，安在坛四面的围腰板以下，从下达到坛的半腰。"},
{volumn:         2,chapter:        38,versenumber:         5,verse:"为铜网的四角铸四个环子，作为穿杠的用处。"},
{volumn:         2,chapter:        38,versenumber:         6,verse:"用皂荚木做杠，用铜包裹，"},
{volumn:         2,chapter:        38,versenumber:         7,verse:"把杠穿在坛两旁的环子内，用以抬坛，并用板做坛；坛是空的。"},
{volumn:         2,chapter:        38,versenumber:         8,verse:"他用铜做洗濯盆和盆座，是用会幕门前伺候的妇人之镜子做的。"},
{volumn:         2,chapter:        38,versenumber:         9,verse:"他做帐幕的院子。院子的南面用捻的细麻做帷子，宽一百肘。"},
{volumn:         2,chapter:        38,versenumber:        10,verse:"帷子的柱子二十根，带卯的铜座二十个；柱子上的钩子和杆子都是用银子做的。"},
{volumn:         2,chapter:        38,versenumber:        11,verse:"北面也有帷子，宽一百肘。帷子的柱子二十根，带卯的铜座二十个；柱子上的钩子和杆子都是用银子做的。"},
{volumn:         2,chapter:        38,versenumber:        12,verse:"院子的西面有帷子，宽五十肘。帷子的柱子十根，带卯的座十个；柱子的钩子和杆子都是用银子做的。"},
{volumn:         2,chapter:        38,versenumber:        13,verse:"院子的东面，宽五十肘。"},
{volumn:         2,chapter:        38,versenumber:        14,verse:"门这边的帷子十五肘，那边也是一样。帷子的柱子三根，带卯的座三个。在门的左右各有帷子十五肘，帷子的柱子三根，带卯的座三个。"},
{volumn:         2,chapter:        38,versenumber:        15,verse:""},
{volumn:         2,chapter:        38,versenumber:        16,verse:"院子四面的帷子都是用捻的细麻做的。"},
{volumn:         2,chapter:        38,versenumber:        17,verse:"柱子带卯的座是铜的，柱子上的钩子和杆子是银的，柱顶是用银子包的。院子一切的柱子都是用银杆连络的。"},
{volumn:         2,chapter:        38,versenumber:        18,verse:"院子的门帘是以绣花的手工，用蓝色、紫色、朱红色线，和捻的细麻织的，宽二十肘，高五肘，与院子的帷子相配。"},
{volumn:         2,chapter:        38,versenumber:        19,verse:"帷子的柱子四根，带卯的铜座四个；柱子上的钩子和杆子是银的；柱顶是用银子包的。"},
{volumn:         2,chapter:        38,versenumber:        20,verse:"帐幕一切的橛子和院子四围的橛子都是铜的。"},
{volumn:         2,chapter:        38,versenumber:        21,verse:"这是法柜的帐幕中利未人所用物件的总数，是照摩西的吩咐，经祭司亚伦的儿子以他玛的手数点的。"},
{volumn:         2,chapter:        38,versenumber:        22,verse:"凡耶和华所吩咐摩西的都是犹大支派户珥的孙子、乌利的儿子比撒列做的。"},
{volumn:         2,chapter:        38,versenumber:        23,verse:"与他同工的有但支派中亚希撒抹的儿子亚何利亚伯；他是雕刻匠，又是巧匠，又能用蓝色、紫色、朱红色线，和细麻绣花。"},
{volumn:         2,chapter:        38,versenumber:        24,verse:"为圣所一切工作使用所献的金子，按圣所的平，有二十九他连得并七百三十舍客勒。"},
{volumn:         2,chapter:        38,versenumber:        25,verse:"会中被数的人所出的银子，按圣所的平，有一百他连得并一千七百七十五舍客勒。"},
{volumn:         2,chapter:        38,versenumber:        26,verse:"凡过去归那些被数之人的，从二十岁以外，有六十万零三千五百五十人。按圣所的平，每人出银半舍客勒，就是一比加。"},
{volumn:         2,chapter:        38,versenumber:        27,verse:"用那一百他连得银子铸造圣所带卯的座和幔子柱子带卯的座；一百他连得共一百带卯的座，每带卯的座用一他连得。"},
{volumn:         2,chapter:        38,versenumber:        28,verse:"用那一千七百七十五舍客勒银子做柱子上的钩子，包裹柱顶并柱子上的杆子。"},
{volumn:         2,chapter:        38,versenumber:        29,verse:"所献的铜有七十他连得并二千四百舍客勒。"},
{volumn:         2,chapter:        38,versenumber:        30,verse:"用这铜做会幕门带卯的座和铜坛，并坛上的铜网和坛的一切器具，"},
{volumn:         2,chapter:        38,versenumber:        31,verse:"并院子四围带卯的座和院门带卯的座，与帐幕一切的橛子和院子四围所有的橛子。"},
{volumn:         2,chapter:        39,versenumber:         1,verse:"比撒列用蓝色、紫色、朱红色线做精致的衣服，在圣所用以供职，又为亚伦做圣衣，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:         2,verse:"他用金线和蓝色、紫色、朱红色线，并捻的细麻做以弗得；"},
{volumn:         2,chapter:        39,versenumber:         3,verse:"把金子锤成薄片，剪出线来，与蓝色、紫色、朱红色线，用巧匠的手工一同绣上。"},
{volumn:         2,chapter:        39,versenumber:         4,verse:"又为以弗得做两条相连的肩带，接连在以弗得的两头。"},
{volumn:         2,chapter:        39,versenumber:         5,verse:"其上巧工织的带子和以弗得一样的做法，用以束上，与以弗得接连一块，是用金线和蓝色、紫色、朱红色线，并捻的细麻做的，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:         6,verse:"又琢出两块红玛瑙，镶在金槽上，仿佛刻图书，按着以色列儿子的名字雕刻；"},
{volumn:         2,chapter:        39,versenumber:         7,verse:"将这两块宝石安在以弗得的两条肩带上，为以色列人做纪念石，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:         8,verse:"他用巧匠的手工做胸牌，和以弗得一样的做法，用金线与蓝色、紫色、朱红色线，并捻的细麻做的。"},
{volumn:         2,chapter:        39,versenumber:         9,verse:"胸牌是四方的，叠为两层；这两层长一虎口，宽一虎口，"},
{volumn:         2,chapter:        39,versenumber:        10,verse:"上面镶着宝石四行：第一行是红宝石、红璧玺、红玉；"},
{volumn:         2,chapter:        39,versenumber:        11,verse:"第二行是绿宝石、蓝宝石、金钢石；"},
{volumn:         2,chapter:        39,versenumber:        12,verse:"第三行是紫玛瑙、白玛瑙、紫晶；"},
{volumn:         2,chapter:        39,versenumber:        13,verse:"第四行是水苍玉、红玛瑙、碧玉。这都镶在金槽中。"},
{volumn:         2,chapter:        39,versenumber:        14,verse:"这些宝石都是按着以色列十二个儿子的名字，仿佛刻图书，刻十二个支派的名字。"},
{volumn:         2,chapter:        39,versenumber:        15,verse:"在胸牌上，用精金拧成如绳子的链子。"},
{volumn:         2,chapter:        39,versenumber:        16,verse:"又做两个金槽和两个金环，安在胸牌的两头。"},
{volumn:         2,chapter:        39,versenumber:        17,verse:"把那两条拧成的金链子穿过胸牌两头的环子，"},
{volumn:         2,chapter:        39,versenumber:        18,verse:"又把链子的那两头接在两槽上，安在以弗得前面肩带上。"},
{volumn:         2,chapter:        39,versenumber:        19,verse:"做两个金环，安在胸牌的两头，在以弗得里面的边上，"},
{volumn:         2,chapter:        39,versenumber:        20,verse:"又做两个金环，安在以弗得前面两条肩带的下边，挨近相接之处，在以弗得巧工织的带子以上。"},
{volumn:         2,chapter:        39,versenumber:        21,verse:"用一条蓝细带子把胸牌的环子和以弗得的环子系住，使胸牌贴在以弗得巧工织的带子上，不可与以弗得离缝，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:        22,verse:"他用织工做以弗得的外袍，颜色全是蓝的。"},
{volumn:         2,chapter:        39,versenumber:        23,verse:"袍上留一领口，口的周围织出领边来，仿佛铠甲的领口，免得破裂。"},
{volumn:         2,chapter:        39,versenumber:        24,verse:"在袍子底边上，用蓝色、紫色、朱红色线，并捻的细麻做石榴，"},
{volumn:         2,chapter:        39,versenumber:        25,verse:"又用精金做铃铛，把铃铛钉在袍子周围底边上的石榴中间："},
{volumn:         2,chapter:        39,versenumber:        26,verse:"一个铃铛一个石榴，一个铃铛一个石榴，在袍子周围底边上用以供职，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:        27,verse:"他用织成的细麻布为亚伦和他的儿子做内袍，"},
{volumn:         2,chapter:        39,versenumber:        28,verse:"并用细麻布做冠冕和华美的裹头巾，用捻的细麻布做裤子，"},
{volumn:         2,chapter:        39,versenumber:        29,verse:"又用蓝色、紫色、朱红色线，并捻的细麻，以绣花的手工做腰带，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:        30,verse:"他用精金做圣冠上的牌，在上面按刻图书之法，刻着“归耶和华为圣”。"},
{volumn:         2,chapter:        39,versenumber:        31,verse:"又用一条蓝细带子将牌系在冠冕上，是照耶和华所吩咐摩西的。"},
{volumn:         2,chapter:        39,versenumber:        32,verse:"帐幕，就是会幕，一切的工就这样做完了。凡耶和华所吩咐摩西的，以色列人都照样做了。"},
{volumn:         2,chapter:        39,versenumber:        33,verse:"他们送到摩西那里。帐幕和帐幕的一切器具，就是钩子、板、闩、柱子、带卯的座，"},
{volumn:         2,chapter:        39,versenumber:        34,verse:"染红公羊皮的盖、海狗皮的顶盖，和遮掩柜的幔子，"},
{volumn:         2,chapter:        39,versenumber:        35,verse:"法柜和柜的杠并施恩座，"},
{volumn:         2,chapter:        39,versenumber:        36,verse:"桌子和桌子的一切器具并陈设饼，"},
{volumn:         2,chapter:        39,versenumber:        37,verse:"精金的灯台和摆列的灯盏，与灯台的一切器具，并点灯的油，"},
{volumn:         2,chapter:        39,versenumber:        38,verse:"金坛、膏油、馨香的香料、会幕的门帘，"},
{volumn:         2,chapter:        39,versenumber:        39,verse:"铜坛和坛上的铜网，坛的杠并坛的一切器具，洗濯盆和盆座，"},
{volumn:         2,chapter:        39,versenumber:        40,verse:"院子的帷子和柱子，并带卯的座，院子的门帘、绳子、橛子，并帐幕和会幕中一切使用的器具，"},
{volumn:         2,chapter:        39,versenumber:        41,verse:"精工做的礼服，和祭司亚伦并他儿子在圣所用以供祭司职分的圣衣。"},
{volumn:         2,chapter:        39,versenumber:        42,verse:"这一切工作都是以色列人照耶和华所吩咐摩西做的。"},
{volumn:         2,chapter:        39,versenumber:        43,verse:"耶和华怎样吩咐的，他们就怎样做了。摩西看见一切的工都做成了，就给他们祝福。"},
{volumn:         2,chapter:        40,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         2,chapter:        40,versenumber:         2,verse:"“正月初一日，你要立起帐幕，"},
{volumn:         2,chapter:        40,versenumber:         3,verse:"把法柜安放在里面，用幔子将柜遮掩。"},
{volumn:         2,chapter:        40,versenumber:         4,verse:"把桌子搬进去，摆设上面的物。把灯台搬进去，点其上的灯。"},
{volumn:         2,chapter:        40,versenumber:         5,verse:"把烧香的金坛安在法柜前，挂上帐幕的门帘。"},
{volumn:         2,chapter:        40,versenumber:         6,verse:"把燔祭坛安在帐幕门前。"},
{volumn:         2,chapter:        40,versenumber:         7,verse:"把洗濯盆安在会幕和坛的中间，在盆里盛水。"},
{volumn:         2,chapter:        40,versenumber:         8,verse:"又在四围立院帷，把院子的门帘挂上。"},
{volumn:         2,chapter:        40,versenumber:         9,verse:"用膏油把帐幕和其中所有的都抹上，使帐幕和一切器具成圣，就都成圣。"},
{volumn:         2,chapter:        40,versenumber:        10,verse:"又要抹燔祭坛和一切器具，使坛成圣，就都成为至圣。"},
{volumn:         2,chapter:        40,versenumber:        11,verse:"要抹洗濯盆和盆座，使盆成圣。"},
{volumn:         2,chapter:        40,versenumber:        12,verse:"要使亚伦和他儿子到会幕门口来，用水洗身。"},
{volumn:         2,chapter:        40,versenumber:        13,verse:"要给亚伦穿上圣衣，又膏他，使他成圣，可以给我供祭司的职分；"},
{volumn:         2,chapter:        40,versenumber:        14,verse:"又要使他儿子来，给他们穿上内袍。"},
{volumn:         2,chapter:        40,versenumber:        15,verse:"怎样膏他们的父亲，也要照样膏他们，使他们给我供祭司的职分。他们世世代代凡受膏的，就永远当祭司的职任。”"},
{volumn:         2,chapter:        40,versenumber:        16,verse:"摩西这样行，都是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        17,verse:"第二年正月初一日，帐幕就立起来。"},
{volumn:         2,chapter:        40,versenumber:        18,verse:"摩西立起帐幕，安上带卯的座，立上板，穿上闩，立起柱子。"},
{volumn:         2,chapter:        40,versenumber:        19,verse:"在帐幕以上搭罩棚，把罩棚的顶盖盖在其上，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        20,verse:"又把法版放在柜里，把杠穿在柜的两旁，把施恩座安在柜上。"},
{volumn:         2,chapter:        40,versenumber:        21,verse:"把柜抬进帐幕，挂上遮掩柜的幔子，把法柜遮掩了，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        22,verse:"又把桌子安在会幕内，在帐幕北边，在幔子外。"},
{volumn:         2,chapter:        40,versenumber:        23,verse:"在桌子上将饼陈设在耶和华面前，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        24,verse:"又把灯台安在会幕内，在帐幕南边，与桌子相对，"},
{volumn:         2,chapter:        40,versenumber:        25,verse:"在耶和华面前点灯，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        26,verse:"把金坛安在会幕内的幔子前，"},
{volumn:         2,chapter:        40,versenumber:        27,verse:"在坛上烧了馨香料做的香，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        28,verse:"又挂上帐幕的门帘。"},
{volumn:         2,chapter:        40,versenumber:        29,verse:"在会幕的帐幕门前，安设燔祭坛，把燔祭和素祭献在其上，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        30,verse:"把洗濯盆安在会幕和坛的中间，盆中盛水，以便洗濯。"},
{volumn:         2,chapter:        40,versenumber:        31,verse:"摩西和亚伦并亚伦的儿子在这盆里洗手洗脚。"},
{volumn:         2,chapter:        40,versenumber:        32,verse:"他们进会幕或就近坛的时候，便都洗濯，是照耶和华所吩咐他的。"},
{volumn:         2,chapter:        40,versenumber:        33,verse:"在帐幕和坛的四围立了院帷，把院子的门帘挂上。这样，摩西就完了工。"},
{volumn:         2,chapter:        40,versenumber:        34,verse:"当时，云彩遮盖会幕，耶和华的荣光就充满了帐幕。"},
{volumn:         2,chapter:        40,versenumber:        35,verse:"摩西不能进会幕；因为云彩停在其上，并且耶和华的荣光充满了帐幕。"},
{volumn:         2,chapter:        40,versenumber:        36,verse:"每逢云彩从帐幕收上去，以色列人就起程前往；"},
{volumn:         2,chapter:        40,versenumber:        37,verse:"云彩若不收上去，他们就不起程，直等到云彩收上去。"},
{volumn:         2,chapter:        40,versenumber:        38,verse:"日间，耶和华的云彩是在帐幕以上；夜间，云中有火，在以色列全家的眼前。在他们所行的路上都是这样。"},
{volumn:         3,chapter:         1,versenumber:         1,verse:"耶和华从会幕中呼叫摩西，对他说："},
{volumn:         3,chapter:         1,versenumber:         2,verse:"“你晓谕以色列人说：你们中间若有人献供物给耶和华，要从牛群羊群中献牲畜为供物。"},
{volumn:         3,chapter:         1,versenumber:         3,verse:"“他的供物若以牛为燔祭，就要在会幕门口献一只没有残疾的公牛，可以在耶和华面前蒙悦纳。"},
{volumn:         3,chapter:         1,versenumber:         4,verse:"他要按手在燔祭牲的头上，燔祭便蒙悦纳，为他赎罪。"},
{volumn:         3,chapter:         1,versenumber:         5,verse:"他要在耶和华面前宰公牛；亚伦子孙作祭司的，要奉上血，把血洒在会幕门口、坛的周围。"},
{volumn:         3,chapter:         1,versenumber:         6,verse:"那人要剥去燔祭牲的皮，把燔祭牲切成块子。"},
{volumn:         3,chapter:         1,versenumber:         7,verse:"祭司亚伦的子孙要把火放在坛上，把柴摆在火上。"},
{volumn:         3,chapter:         1,versenumber:         8,verse:"亚伦子孙作祭司的，要把肉块和头并脂油摆在坛上火的柴上。"},
{volumn:         3,chapter:         1,versenumber:         9,verse:"但燔祭的脏腑与腿要用水洗。祭司就要把一切全烧在坛上，当作燔祭，献与耶和华为馨香的火祭。"},
{volumn:         3,chapter:         1,versenumber:        10,verse:"“人的供物若以绵羊或山羊为燔祭，就要献上没有残疾的公羊。"},
{volumn:         3,chapter:         1,versenumber:        11,verse:"要把羊宰于坛的北边，在耶和华面前；亚伦子孙作祭司的，要把羊血洒在坛的周围。"},
{volumn:         3,chapter:         1,versenumber:        12,verse:"要把燔祭牲切成块子，连头和脂油，祭司就要摆在坛上火的柴上；"},
{volumn:         3,chapter:         1,versenumber:        13,verse:"但脏腑与腿要用水洗，祭司就要全然奉献，烧在坛上。这是燔祭，是献与耶和华为馨香的火祭。"},
{volumn:         3,chapter:         1,versenumber:        14,verse:"“人奉给耶和华的供物，若以鸟为燔祭，就要献斑鸠或是雏鸽为供物。"},
{volumn:         3,chapter:         1,versenumber:        15,verse:"祭司要把鸟拿到坛前，揪下头来，把鸟烧在坛上；鸟的血要流在坛的旁边；"},
{volumn:         3,chapter:         1,versenumber:        16,verse:"又要把鸟的嗉子和脏物（脏物：或译翎毛）除掉，丢在坛的东边倒灰的地方。"},
{volumn:         3,chapter:         1,versenumber:        17,verse:"要拿着鸟的两个翅膀，把鸟撕开，只是不可撕断；祭司要在坛上、在火的柴上焚烧。这是燔祭，是献与耶和华为馨香的火祭。”"},
{volumn:         3,chapter:         2,versenumber:         1,verse:"“若有人献素祭为供物给耶和华，要用细面浇上油，加上乳香，"},
{volumn:         3,chapter:         2,versenumber:         2,verse:"带到亚伦子孙作祭司的那里；祭司就要从细面中取出一把来，并取些油和所有的乳香，然后要把所取的这些作为纪念，烧在坛上，是献与耶和华为馨香的火祭。"},
{volumn:         3,chapter:         2,versenumber:         3,verse:"素祭所剩的要归给亚伦和他的子孙；这是献与耶和华的火祭中为至圣的。"},
{volumn:         3,chapter:         2,versenumber:         4,verse:"“若用炉中烤的物为素祭，就要用调油的无酵细面饼，或是抹油的无酵薄饼。"},
{volumn:         3,chapter:         2,versenumber:         5,verse:"若用铁鏊上做的物为素祭，就要用调油的无酵细面，"},
{volumn:         3,chapter:         2,versenumber:         6,verse:"分成块子，浇上油；这是素祭。"},
{volumn:         3,chapter:         2,versenumber:         7,verse:"若用煎盘做的物为素祭，就要用油与细面做成。"},
{volumn:         3,chapter:         2,versenumber:         8,verse:"要把这些东西做的素祭带到耶和华面前，并奉给祭司，带到坛前。"},
{volumn:         3,chapter:         2,versenumber:         9,verse:"祭司要从素祭中取出作为纪念的，烧在坛上，是献与耶和华为馨香的火祭。"},
{volumn:         3,chapter:         2,versenumber:        10,verse:"素祭所剩的要归给亚伦和他的子孙。这是献与耶和华的火祭中为至圣的。"},
{volumn:         3,chapter:         2,versenumber:        11,verse:"“凡献给耶和华的素祭都不可有酵；因为你们不可烧一点酵、一点蜜当作火祭献给耶和华。"},
{volumn:         3,chapter:         2,versenumber:        12,verse:"这些物要献给耶和华作为初熟的供物，只是不可在坛上献为馨香的祭。"},
{volumn:         3,chapter:         2,versenumber:        13,verse:"凡献为素祭的供物都要用盐调和，在素祭上不可缺了你　神立约的盐。一切的供物都要配盐而献。"},
{volumn:         3,chapter:         2,versenumber:        14,verse:"若向耶和华献初熟之物为素祭，要献上烘了的禾穗子，就是轧了的新穗子，当作初熟之物的素祭。"},
{volumn:         3,chapter:         2,versenumber:        15,verse:"并要抹上油，加上乳香；这是素祭。"},
{volumn:         3,chapter:         2,versenumber:        16,verse:"祭司要把其中作为纪念的，就是一些轧了的禾穗子和一些油，并所有的乳香，都焚烧，是向耶和华献的火祭。”"},
{volumn:         3,chapter:         3,versenumber:         1,verse:"“人献供物为平安祭（平安：或译酬恩；下同），若是从牛群中献，无论是公的是母的，必用没有残疾的献在耶和华面前。"},
{volumn:         3,chapter:         3,versenumber:         2,verse:"他要按手在供物的头上，宰于会幕门口。亚伦子孙作祭司的，要把血洒在坛的周围。"},
{volumn:         3,chapter:         3,versenumber:         3,verse:"从平安祭中，将火祭献给耶和华，也要把盖脏的脂油和脏上所有的脂油，"},
{volumn:         3,chapter:         3,versenumber:         4,verse:"并两个腰子和腰子上的脂油，就是靠腰两旁的脂油，与肝上的网子和腰子，一概取下。"},
{volumn:         3,chapter:         3,versenumber:         5,verse:"亚伦的子孙要把这些烧在坛的燔祭上，就是在火的柴上，是献与耶和华为馨香的火祭。"},
{volumn:         3,chapter:         3,versenumber:         6,verse:"“人向耶和华献供物为平安祭，若是从羊群中献，无论是公的是母的，必用没有残疾的。"},
{volumn:         3,chapter:         3,versenumber:         7,verse:"若献一只羊羔为供物，必在耶和华面前献上，"},
{volumn:         3,chapter:         3,versenumber:         8,verse:"并要按手在供物的头上，宰于会幕前。亚伦的子孙要把血洒在坛的周围。"},
{volumn:         3,chapter:         3,versenumber:         9,verse:"从平安祭中，将火祭献给耶和华，其中的脂油和整肥尾巴都要在靠近脊骨处取下，并要把盖脏的脂油和脏上所有的脂油，"},
{volumn:         3,chapter:         3,versenumber:        10,verse:"两个腰子和腰子上的脂油，就是靠腰两旁的脂油，并肝上的网子和腰子，一概取下。"},
{volumn:         3,chapter:         3,versenumber:        11,verse:"祭司要在坛上焚烧，是献给耶和华为食物的火祭。"},
{volumn:         3,chapter:         3,versenumber:        12,verse:"“人的供物若是山羊，必在耶和华面前献上。"},
{volumn:         3,chapter:         3,versenumber:        13,verse:"要按手在山羊头上，宰于会幕前。亚伦的子孙要把血洒在坛的周围，"},
{volumn:         3,chapter:         3,versenumber:        14,verse:"又把盖脏的脂油和脏上所有的脂油，两个腰子和腰子上的脂油，就是靠腰两旁的脂油，并肝上的网子和腰子，一概取下，献给耶和华为火祭。"},
{volumn:         3,chapter:         3,versenumber:        15,verse:""},
{volumn:         3,chapter:         3,versenumber:        16,verse:"祭司要在坛上焚烧，作为馨香火祭的食物。脂油都是耶和华的。"},
{volumn:         3,chapter:         3,versenumber:        17,verse:"在你们一切的住处，脂油和血都不可吃；这要成为你们世世代代永远的定例。”"},
{volumn:         3,chapter:         4,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:         4,versenumber:         2,verse:"“你晓谕以色列人说：若有人在耶和华所吩咐不可行的什么事上误犯了一件，"},
{volumn:         3,chapter:         4,versenumber:         3,verse:"或是受膏的祭司犯罪，使百姓陷在罪里，就当为他所犯的罪把没有残疾的公牛犊献给耶和华为赎罪祭。"},
{volumn:         3,chapter:         4,versenumber:         4,verse:"他要牵公牛到会幕门口，在耶和华面前按手在牛的头上，把牛宰于耶和华面前。"},
{volumn:         3,chapter:         4,versenumber:         5,verse:"受膏的祭司要取些公牛的血带到会幕，"},
{volumn:         3,chapter:         4,versenumber:         6,verse:"把指头蘸于血中，在耶和华面前对着圣所的幔子弹血七次，"},
{volumn:         3,chapter:         4,versenumber:         7,verse:"又要把些血抹在会幕内、耶和华面前香坛的四角上，再把公牛所有的血倒在会幕门口、燔祭坛的脚那里。"},
{volumn:         3,chapter:         4,versenumber:         8,verse:"要把赎罪祭公牛所有的脂油，乃是盖脏的脂油和脏上所有的脂油，"},
{volumn:         3,chapter:         4,versenumber:         9,verse:"并两个腰子和腰子上的脂油，就是靠腰两旁的脂油，与肝上的网子和腰子，一概取下，"},
{volumn:         3,chapter:         4,versenumber:        10,verse:"与平安祭公牛上所取的一样；祭司要把这些烧在燔祭的坛上。"},
{volumn:         3,chapter:         4,versenumber:        11,verse:"公牛的皮和所有的肉，并头、腿、脏、腑、粪，"},
{volumn:         3,chapter:         4,versenumber:        12,verse:"就是全公牛，要搬到营外洁净之地、倒灰之所，用火烧在柴上。"},
{volumn:         3,chapter:         4,versenumber:        13,verse:"“以色列全会众若行了耶和华所吩咐不可行的什么事，误犯了罪，是隐而未现、会众看不出来的，"},
{volumn:         3,chapter:         4,versenumber:        14,verse:"会众一知道所犯的罪就要献一只公牛犊为赎罪祭，牵到会幕前。"},
{volumn:         3,chapter:         4,versenumber:        15,verse:"会中的长老就要在耶和华面前按手在牛的头上，将牛在耶和华面前宰了。"},
{volumn:         3,chapter:         4,versenumber:        16,verse:"受膏的祭司要取些公牛的血带到会幕，"},
{volumn:         3,chapter:         4,versenumber:        17,verse:"把指头蘸于血中，在耶和华面前对着幔子弹血七次，"},
{volumn:         3,chapter:         4,versenumber:        18,verse:"又要把些血抹在会幕内、耶和华面前坛的四角上，再把所有的血倒在会幕门口、燔祭坛的脚那里。"},
{volumn:         3,chapter:         4,versenumber:        19,verse:"把牛所有的脂油都取下，烧在坛上；"},
{volumn:         3,chapter:         4,versenumber:        20,verse:"收拾这牛，与那赎罪祭的牛一样。祭司要为他们赎罪，他们必蒙赦免。"},
{volumn:         3,chapter:         4,versenumber:        21,verse:"他要把牛搬到营外烧了，像烧头一个牛一样；这是会众的赎罪祭。"},
{volumn:         3,chapter:         4,versenumber:        22,verse:"“官长若行了耶和华他　神所吩咐不可行的什么事，误犯了罪，"},
{volumn:         3,chapter:         4,versenumber:        23,verse:"所犯的罪自己知道了，就要牵一只没有残疾的公山羊为供物，"},
{volumn:         3,chapter:         4,versenumber:        24,verse:"按手在羊的头上，宰于耶和华面前、宰燔祭牲的地方；这是赎罪祭。"},
{volumn:         3,chapter:         4,versenumber:        25,verse:"祭司要用指头蘸些赎罪祭牲的血，抹在燔祭坛的四角上，把血倒在燔祭坛的脚那里。"},
{volumn:         3,chapter:         4,versenumber:        26,verse:"所有的脂油，祭司都要烧在坛上，正如平安祭的脂油一样。至于他的罪，祭司要为他赎了，他必蒙赦免。"},
{volumn:         3,chapter:         4,versenumber:        27,verse:"“民中若有人行了耶和华所吩咐不可行的什么事，误犯了罪，"},
{volumn:         3,chapter:         4,versenumber:        28,verse:"所犯的罪自己知道了，就要为所犯的罪牵一只没有残疾的母山羊为供物，"},
{volumn:         3,chapter:         4,versenumber:        29,verse:"按手在赎罪祭牲的头上，在那宰燔祭牲的地方宰了。"},
{volumn:         3,chapter:         4,versenumber:        30,verse:"祭司要用指头蘸些羊的血，抹在燔祭坛的四角上，所有的血都要倒在坛的脚那里，"},
{volumn:         3,chapter:         4,versenumber:        31,verse:"又要把羊所有的脂油都取下，正如取平安祭牲的脂油一样。祭司要在坛上焚烧，在耶和华面前作为馨香的祭，为他赎罪，他必蒙赦免。"},
{volumn:         3,chapter:         4,versenumber:        32,verse:"“人若牵一只绵羊羔为赎罪祭的供物，必要牵一只没有残疾的母羊，"},
{volumn:         3,chapter:         4,versenumber:        33,verse:"按手在赎罪祭牲的头上，在那宰燔祭牲的地方宰了作赎罪祭。"},
{volumn:         3,chapter:         4,versenumber:        34,verse:"祭司要用指头蘸些赎罪祭牲的血，抹在燔祭坛的四角上，所有的血都要倒在坛的脚那里，"},
{volumn:         3,chapter:         4,versenumber:        35,verse:"又要把所有的脂油都取下，正如取平安祭羊羔的脂油一样。祭司要按献给耶和华火祭的条例，烧在坛上。至于所犯的罪，祭司要为他赎了，他必蒙赦免。”"},
{volumn:         3,chapter:         5,versenumber:         1,verse:"“若有人听见发誓的声音（或译：若有人听见叫人发誓的声音），他本是见证，却不把所看见的、所知道的说出来，这就是罪；他要担当他的罪孽。"},
{volumn:         3,chapter:         5,versenumber:         2,verse:"或是有人摸了不洁的物，无论是不洁的死兽，是不洁的死畜，是不洁的死虫，他却不知道，因此成了不洁，就有了罪。"},
{volumn:         3,chapter:         5,versenumber:         3,verse:"或是他摸了别人的污秽，无论是染了什么污秽，他却不知道，一知道了就有了罪。"},
{volumn:         3,chapter:         5,versenumber:         4,verse:"或是有人嘴里冒失发誓，要行恶，要行善，无论人在什么事上冒失发誓，他却不知道，一知道了就要在这其中的一件上有了罪。"},
{volumn:         3,chapter:         5,versenumber:         5,verse:"他有了罪的时候，就要承认所犯的罪，"},
{volumn:         3,chapter:         5,versenumber:         6,verse:"并要因所犯的罪，把他的赎愆祭牲，就是羊群中的母羊，或是一只羊羔，或是一只山羊，牵到耶和华面前为赎罪祭。至于他的罪，祭司要为他赎了。"},
{volumn:         3,chapter:         5,versenumber:         7,verse:"“他的力量若不够献一只羊羔，就要因所犯的罪，把两只斑鸠或是两只雏鸽带到耶和华面前为赎愆祭：一只作赎罪祭，一只作燔祭。"},
{volumn:         3,chapter:         5,versenumber:         8,verse:"把这些带到祭司那里，祭司就要先把那赎罪祭献上，从鸟的颈项上揪下头来，只是不可把鸟撕断，"},
{volumn:         3,chapter:         5,versenumber:         9,verse:"也把些赎罪祭牲的血弹在坛的旁边，剩下的血要流在坛的脚那里；这是赎罪祭。"},
{volumn:         3,chapter:         5,versenumber:        10,verse:"他要照例献第二只为燔祭。至于他所犯的罪，祭司要为他赎了，他必蒙赦免。"},
{volumn:         3,chapter:         5,versenumber:        11,verse:"“他的力量若不够献两只斑鸠或是两只雏鸽，就要因所犯的罪带供物来，就是细面伊法十分之一为赎罪祭；不可加上油，也不可加上乳香，因为是赎罪祭。"},
{volumn:         3,chapter:         5,versenumber:        12,verse:"他要把供物带到祭司那里，祭司要取出自己的一把来作为纪念，按献给耶和华火祭的条例烧在坛上；这是赎罪祭。"},
{volumn:         3,chapter:         5,versenumber:        13,verse:"至于他在这几件事中所犯的罪，祭司要为他赎了，他必蒙赦免。剩下的面都归与祭司，和素祭一样。”"},
{volumn:         3,chapter:         5,versenumber:        14,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:         5,versenumber:        15,verse:"“人若在耶和华的圣物上误犯了罪，有了过犯，就要照你所估的，按圣所的舍客勒拿银子，将赎愆祭牲，就是羊群中一只没有残疾的公绵羊，牵到耶和华面前为赎愆祭；"},
{volumn:         3,chapter:         5,versenumber:        16,verse:"并且他因在圣物上的差错要偿还，另外加五分之一，都给祭司。祭司要用赎愆祭的公绵羊为他赎罪，他必蒙赦免。"},
{volumn:         3,chapter:         5,versenumber:        17,verse:"“若有人犯罪，行了耶和华所吩咐不可行的什么事，他虽然不知道，还是有了罪，就要担当他的罪孽；"},
{volumn:         3,chapter:         5,versenumber:        18,verse:"也要照你所估定的价，从羊群中牵一只没有残疾的公绵羊来，给祭司作赎愆祭。至于他误行的那错事，祭司要为他赎罪，他必蒙赦免。"},
{volumn:         3,chapter:         5,versenumber:        19,verse:"这是赎愆祭，因他在耶和华面前实在有了罪。”"},
{volumn:         3,chapter:         6,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:         6,versenumber:         2,verse:"“若有人犯罪，干犯耶和华，在邻舍交付他的物上，或是在交易上行了诡诈，或是抢夺人的财物，或是欺压邻舍，"},
{volumn:         3,chapter:         6,versenumber:         3,verse:"或是在捡了遗失的物上行了诡诈，说谎起誓，在这一切的事上犯了什么罪；"},
{volumn:         3,chapter:         6,versenumber:         4,verse:"他既犯了罪，有了过犯，就要归还他所抢夺的，或是因欺压所得的，或是人交付他的，或是人遗失他所捡的物，"},
{volumn:         3,chapter:         6,versenumber:         5,verse:"或是他因什么物起了假誓，就要如数归还，另外加上五分之一，在查出他有罪的日子要交还本主。"},
{volumn:         3,chapter:         6,versenumber:         6,verse:"也要照你所估定的价，把赎愆祭牲，就是羊群中一只没有残疾的公绵羊，牵到耶和华面前，给祭司为赎愆祭。"},
{volumn:         3,chapter:         6,versenumber:         7,verse:"祭司要在耶和华面前为他赎罪；他无论行了什么事，使他有了罪，都必蒙赦免。”"},
{volumn:         3,chapter:         6,versenumber:         8,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:         6,versenumber:         9,verse:"“你要吩咐亚伦和他的子孙说，燔祭的条例乃是这样：燔祭要放在坛的柴上，从晚上到天亮，坛上的火要常常烧着。"},
{volumn:         3,chapter:         6,versenumber:        10,verse:"祭司要穿上细麻布衣服，又要把细麻布裤子穿在身上，把坛上所烧的燔祭灰收起来，倒在坛的旁边；"},
{volumn:         3,chapter:         6,versenumber:        11,verse:"随后要脱去这衣服，穿上别的衣服，把灰拿到营外洁净之处。"},
{volumn:         3,chapter:         6,versenumber:        12,verse:"坛上的火要在其上常常烧着，不可熄灭。祭司要每日早晨在上面烧柴，并要把燔祭摆在坛上，在其上烧平安祭牲的脂油。"},
{volumn:         3,chapter:         6,versenumber:        13,verse:"在坛上必有常常烧着的火，不可熄灭。”"},
{volumn:         3,chapter:         6,versenumber:        14,verse:"“素祭的条例乃是这样：亚伦的子孙要在坛前把这祭献在耶和华面前。"},
{volumn:         3,chapter:         6,versenumber:        15,verse:"祭司要从其中，就是从素祭的细面中，取出自己的一把，又要取些油和素祭上所有的乳香，烧在坛上，奉给耶和华为馨香素祭的纪念。"},
{volumn:         3,chapter:         6,versenumber:        16,verse:"所剩下的，亚伦和他子孙要吃，必在圣处不带酵而吃，要在会幕的院子里吃。"},
{volumn:         3,chapter:         6,versenumber:        17,verse:"烤的时候不可搀酵。这是从所献给我的火祭中赐给他们的份，是至圣的，和赎罪祭并赎愆祭一样。"},
{volumn:         3,chapter:         6,versenumber:        18,verse:"凡献给耶和华的火祭，亚伦子孙中的男丁都要吃这一份，直到万代，作他们永得的份。摸这些祭物的，都要成为圣。”"},
{volumn:         3,chapter:         6,versenumber:        19,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:         6,versenumber:        20,verse:"“当亚伦受膏的日子，他和他子孙所要献给耶和华的供物，就是细面伊法十分之一，为常献的素祭：早晨一半，晚上一半。"},
{volumn:         3,chapter:         6,versenumber:        21,verse:"要在铁鏊上用油调和做成，调匀了，你就拿进来；烤好了分成块子，献给耶和华为馨香的素祭。"},
{volumn:         3,chapter:         6,versenumber:        22,verse:"亚伦的子孙中，接续他为受膏的祭司，要把这素祭献上，要全烧给耶和华。这是永远的定例。"},
{volumn:         3,chapter:         6,versenumber:        23,verse:"祭司的素祭都要烧了，却不可吃。”"},
{volumn:         3,chapter:         6,versenumber:        24,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:         6,versenumber:        25,verse:"“你对亚伦和他的子孙说，赎罪祭的条例乃是这样：要在耶和华面前、宰燔祭牲的地方宰赎罪祭牲；这是至圣的。"},
{volumn:         3,chapter:         6,versenumber:        26,verse:"为赎罪献这祭的祭司要吃，要在圣处，就是在会幕的院子里吃。"},
{volumn:         3,chapter:         6,versenumber:        27,verse:"凡摸这祭肉的要成为圣；这祭牲的血若弹在什么衣服上，所弹的那一件要在圣处洗净。"},
{volumn:         3,chapter:         6,versenumber:        28,verse:"惟有煮祭物的瓦器要打碎；若是煮在铜器里，这铜器要擦磨，在水中涮净。"},
{volumn:         3,chapter:         6,versenumber:        29,verse:"凡祭司中的男丁都可以吃；这是至圣的。"},
{volumn:         3,chapter:         6,versenumber:        30,verse:"凡赎罪祭，若将血带进会幕在圣所赎罪，那肉都不可吃，必用火焚烧。”"},
{volumn:         3,chapter:         7,versenumber:         1,verse:"“赎愆祭的条例乃是如此：这祭是至圣的。"},
{volumn:         3,chapter:         7,versenumber:         2,verse:"人在哪里宰燔祭牲，也要在那里宰赎愆祭牲；其血，祭司要洒在坛的周围。"},
{volumn:         3,chapter:         7,versenumber:         3,verse:"又要将肥尾巴和盖脏的脂油，"},
{volumn:         3,chapter:         7,versenumber:         4,verse:"两个腰子和腰子上的脂油，就是靠腰两旁的脂油，并肝上的网子和腰子，一概取下。"},
{volumn:         3,chapter:         7,versenumber:         5,verse:"祭司要在坛上焚烧，为献给耶和华的火祭，是赎愆祭。"},
{volumn:         3,chapter:         7,versenumber:         6,verse:"祭司中的男丁都可以吃这祭物；要在圣处吃，是至圣的。"},
{volumn:         3,chapter:         7,versenumber:         7,verse:"赎罪祭怎样，赎愆祭也是怎样，两个祭是一个条例。献赎愆祭赎罪的祭司要得这祭物。"},
{volumn:         3,chapter:         7,versenumber:         8,verse:"献燔祭的祭司，无论为谁奉献，要亲自得他所献那燔祭牲的皮。"},
{volumn:         3,chapter:         7,versenumber:         9,verse:"凡在炉中烤的素祭和煎盘中做的，并铁鏊上做的，都要归那献祭的祭司。"},
{volumn:         3,chapter:         7,versenumber:        10,verse:"凡素祭，无论是油调和的是干的，都要归亚伦的子孙，大家均分。”"},
{volumn:         3,chapter:         7,versenumber:        11,verse:"“人献与耶和华平安祭的条例乃是这样："},
{volumn:         3,chapter:         7,versenumber:        12,verse:"他若为感谢献上，就要用调油的无酵饼和抹油的无酵薄饼，并用油调匀细面做的饼，与感谢祭一同献上。"},
{volumn:         3,chapter:         7,versenumber:        13,verse:"要用有酵的饼和为感谢献的平安祭，与供物一同献上。"},
{volumn:         3,chapter:         7,versenumber:        14,verse:"从各样的供物中，他要把一个饼献给耶和华为举祭，是要归给洒平安祭牲血的祭司。"},
{volumn:         3,chapter:         7,versenumber:        15,verse:"为感谢献平安祭牲的肉，要在献的日子吃，一点不可留到早晨。"},
{volumn:         3,chapter:         7,versenumber:        16,verse:"若所献的是为还愿，或是甘心献的，必在献祭的日子吃，所剩下的第二天也可以吃。"},
{volumn:         3,chapter:         7,versenumber:        17,verse:"但所剩下的祭肉，到第三天要用火焚烧；"},
{volumn:         3,chapter:         7,versenumber:        18,verse:"第三天若吃了平安祭的肉，这祭必不蒙悦纳，人所献的也不算为祭，反为可憎嫌的，吃这祭肉的，就必担当他的罪孽。"},
{volumn:         3,chapter:         7,versenumber:        19,verse:"“挨了污秽物的肉就不可吃，要用火焚烧。至于平安祭的肉，凡洁净的人都要吃；"},
{volumn:         3,chapter:         7,versenumber:        20,verse:"只是献与耶和华平安祭的肉，人若不洁净而吃了，这人必从民中剪除。"},
{volumn:         3,chapter:         7,versenumber:        21,verse:"有人摸了什么不洁净的物，或是人的不洁净，或是不洁净的牲畜，或是不洁可憎之物，吃了献与耶和华平安祭的肉，这人必从民中剪除。”"},
{volumn:         3,chapter:         7,versenumber:        22,verse:"耶和华对摩西说："},
{volumn:         3,chapter:         7,versenumber:        23,verse:"“你晓谕以色列人说：牛的脂油、绵羊的脂油、山羊的脂油，你们都不可吃。"},
{volumn:         3,chapter:         7,versenumber:        24,verse:"自死的和被野兽撕裂的，那脂油可以做别的使用，只是你们万不可吃。"},
{volumn:         3,chapter:         7,versenumber:        25,verse:"无论何人吃了献给耶和华当火祭牲畜的脂油，那人必从民中剪除。"},
{volumn:         3,chapter:         7,versenumber:        26,verse:"在你们一切的住处，无论是雀鸟的血是野兽的血，你们都不可吃。"},
{volumn:         3,chapter:         7,versenumber:        27,verse:"无论是谁吃血，那人必从民中剪除。”"},
{volumn:         3,chapter:         7,versenumber:        28,verse:"耶和华对摩西说："},
{volumn:         3,chapter:         7,versenumber:        29,verse:"“你晓谕以色列人说：献平安祭给耶和华的，要从平安祭中取些来奉给耶和华。"},
{volumn:         3,chapter:         7,versenumber:        30,verse:"他亲手献给耶和华的火祭，就是脂油和胸，要带来，好把胸在耶和华面前作摇祭，摇一摇。"},
{volumn:         3,chapter:         7,versenumber:        31,verse:"祭司要把脂油在坛上焚烧，但胸要归亚伦和他的子孙。"},
{volumn:         3,chapter:         7,versenumber:        32,verse:"你们要从平安祭中把右腿作举祭，奉给祭司。"},
{volumn:         3,chapter:         7,versenumber:        33,verse:"亚伦子孙中，献平安祭牲血和脂油的，要得这右腿为份；"},
{volumn:         3,chapter:         7,versenumber:        34,verse:"因为我从以色列人的平安祭中，取了这摇的胸和举的腿给祭司亚伦和他子孙，作他们从以色列人中所永得的份。”"},
{volumn:         3,chapter:         7,versenumber:        35,verse:"这是从耶和华火祭中，作亚伦受膏的份和他子孙受膏的份，正在摩西（原文是他）叫他们前来给耶和华供祭司职分的日子，"},
{volumn:         3,chapter:         7,versenumber:        36,verse:"就是在摩西（原文是他）膏他们的日子，耶和华吩咐以色列人给他们的。这是他们世世代代永得的份。"},
{volumn:         3,chapter:         7,versenumber:        37,verse:"这就是燔祭、素祭、赎罪祭、赎愆祭，和平安祭的条例，并承接圣职的礼，"},
{volumn:         3,chapter:         7,versenumber:        38,verse:"都是耶和华在西奈山所吩咐摩西的，就是他在西奈旷野吩咐以色列人献供物给耶和华之日所说的。"},
{volumn:         3,chapter:         8,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:         8,versenumber:         2,verse:"“你将亚伦和他儿子一同带来，并将圣衣、膏油，与赎罪祭的一只公牛、两只公绵羊、一筐无酵饼都带来，"},
{volumn:         3,chapter:         8,versenumber:         3,verse:"又招聚会众到会幕门口。”"},
{volumn:         3,chapter:         8,versenumber:         4,verse:"摩西就照耶和华所吩咐的行了；于是会众聚集在会幕门口。"},
{volumn:         3,chapter:         8,versenumber:         5,verse:"摩西告诉会众说：“这就是耶和华所吩咐当行的事。”"},
{volumn:         3,chapter:         8,versenumber:         6,verse:"摩西带了亚伦和他儿子来，用水洗了他们。"},
{volumn:         3,chapter:         8,versenumber:         7,verse:"给亚伦穿上内袍，束上腰带，穿上外袍，又加上以弗得，用其上巧工织的带子把以弗得系在他身上，"},
{volumn:         3,chapter:         8,versenumber:         8,verse:"又给他戴上胸牌，把乌陵和土明放在胸牌内，"},
{volumn:         3,chapter:         8,versenumber:         9,verse:"把冠冕戴在他头上，在冠冕的前面钉上金牌，就是圣冠，都是照耶和华所吩咐摩西的。"},
{volumn:         3,chapter:         8,versenumber:        10,verse:"摩西用膏油抹帐幕和其中所有的，使它成圣；"},
{volumn:         3,chapter:         8,versenumber:        11,verse:"又用膏油在坛上弹了七次，又抹了坛和坛的一切器皿，并洗濯盆和盆座，使它成圣；"},
{volumn:         3,chapter:         8,versenumber:        12,verse:"又把膏油倒在亚伦的头上膏他，使他成圣。"},
{volumn:         3,chapter:         8,versenumber:        13,verse:"摩西带了亚伦的儿子来，给他们穿上内袍，束上腰带，包上裹头巾，都是照耶和华所吩咐摩西的。"},
{volumn:         3,chapter:         8,versenumber:        14,verse:"他牵了赎罪祭的公牛来，亚伦和他儿子按手在赎罪祭公牛的头上，"},
{volumn:         3,chapter:         8,versenumber:        15,verse:"就宰了公牛。摩西用指头蘸血，抹在坛上四角的周围，使坛洁净，把血倒在坛的脚那里，使坛成圣，坛就洁净了；"},
{volumn:         3,chapter:         8,versenumber:        16,verse:"又取脏上所有的脂油和肝上的网子，并两个腰子与腰子上的脂油，都烧在坛上；"},
{volumn:         3,chapter:         8,versenumber:        17,verse:"惟有公牛，连皮带肉并粪，用火烧在营外，都是照耶和华所吩咐摩西的。"},
{volumn:         3,chapter:         8,versenumber:        18,verse:"他奉上燔祭的公绵羊；亚伦和他儿子按手在羊的头上，"},
{volumn:         3,chapter:         8,versenumber:        19,verse:"就宰了公羊。摩西把血洒在坛的周围，"},
{volumn:         3,chapter:         8,versenumber:        20,verse:"把羊切成块子，把头和肉块并脂油都烧了。"},
{volumn:         3,chapter:         8,versenumber:        21,verse:"用水洗了脏腑和腿，就把全羊烧在坛上为馨香的燔祭，是献给耶和华的火祭，都是照耶和华所吩咐摩西的。"},
{volumn:         3,chapter:         8,versenumber:        22,verse:"他又奉上第二只公绵羊，就是承接圣职之礼的羊；亚伦和他儿子按手在羊的头上，"},
{volumn:         3,chapter:         8,versenumber:        23,verse:"就宰了羊。摩西把些血抹在亚伦的右耳垂上和右手的大拇指上，并右脚的大拇指上，"},
{volumn:         3,chapter:         8,versenumber:        24,verse:"又带了亚伦的儿子来，把些血抹在他们的右耳垂上和右手的大拇指上，并右脚的大拇指上，又把血洒在坛的周围。"},
{volumn:         3,chapter:         8,versenumber:        25,verse:"取脂油和肥尾巴，并脏上一切的脂油与肝上的网子，两个腰子和腰子上的脂油，并右腿，"},
{volumn:         3,chapter:         8,versenumber:        26,verse:"再从耶和华面前、盛无酵饼的筐子里取出一个无酵饼，一个油饼，一个薄饼，都放在脂油和右腿上，"},
{volumn:         3,chapter:         8,versenumber:        27,verse:"把这一切放在亚伦的手上和他儿子的手上作摇祭，在耶和华面前摇一摇。"},
{volumn:         3,chapter:         8,versenumber:        28,verse:"摩西从他们的手上拿下来，烧在坛上的燔祭上，都是为承接圣职献给耶和华馨香的火祭。"},
{volumn:         3,chapter:         8,versenumber:        29,verse:"摩西拿羊的胸作为摇祭，在耶和华面前摇一摇，是承接圣职之礼，归摩西的份，都是照耶和华所吩咐摩西的。"},
{volumn:         3,chapter:         8,versenumber:        30,verse:"摩西取点膏油和坛上的血，弹在亚伦和他的衣服上，并他儿子和他儿子的衣服上，使他和他们的衣服一同成圣。"},
{volumn:         3,chapter:         8,versenumber:        31,verse:"摩西对亚伦和他儿子说：“把肉煮在会幕门口，在那里吃，又吃承接圣职筐子里的饼，按我所吩咐的说（或译：按所吩咐我的说）：‘这是亚伦和他儿子要吃的。’"},
{volumn:         3,chapter:         8,versenumber:        32,verse:"剩下的肉和饼，你们要用火焚烧。"},
{volumn:         3,chapter:         8,versenumber:        33,verse:"你们七天不可出会幕的门，等到你们承接圣职的日子满了，因为主叫你们七天承接圣职。"},
{volumn:         3,chapter:         8,versenumber:        34,verse:"像今天所行的都是耶和华吩咐行的，为你们赎罪。"},
{volumn:         3,chapter:         8,versenumber:        35,verse:"七天你们要昼夜住在会幕门口，遵守耶和华的吩咐，免得你们死亡，因为所吩咐我的就是这样。”"},
{volumn:         3,chapter:         8,versenumber:        36,verse:"于是亚伦和他儿子行了耶和华藉着摩西所吩咐的一切事。"},
{volumn:         3,chapter:         9,versenumber:         1,verse:"到了第八天，摩西召了亚伦和他儿子，并以色列的众长老来，"},
{volumn:         3,chapter:         9,versenumber:         2,verse:"对亚伦说：“你当取牛群中的一只公牛犊作赎罪祭，一只公绵羊作燔祭，都要没有残疾的，献在耶和华面前。"},
{volumn:         3,chapter:         9,versenumber:         3,verse:"你也要对以色列人说：‘你们当取一只公山羊作赎罪祭，又取一只牛犊和一只绵羊羔，都要一岁、没有残疾的，作燔祭，"},
{volumn:         3,chapter:         9,versenumber:         4,verse:"又取一只公牛，一只公绵羊作平安祭，献在耶和华面前，并取调油的素祭，因为今天耶和华要向你们显现。’”"},
{volumn:         3,chapter:         9,versenumber:         5,verse:"于是他们把摩西所吩咐的，带到会幕前；全会众都近前来，站在耶和华面前。"},
{volumn:         3,chapter:         9,versenumber:         6,verse:"摩西说：“这是耶和华吩咐你们所当行的；耶和华的荣光就要向你们显现。”"},
{volumn:         3,chapter:         9,versenumber:         7,verse:"摩西对亚伦说：“你就近坛前，献你的赎罪祭和燔祭，为自己与百姓赎罪，又献上百姓的供物，为他们赎罪，都照耶和华所吩咐的。”"},
{volumn:         3,chapter:         9,versenumber:         8,verse:"于是，亚伦就近坛前，宰了为自己作赎罪祭的牛犊。"},
{volumn:         3,chapter:         9,versenumber:         9,verse:"亚伦的儿子把血奉给他，他就把指头蘸在血中，抹在坛的四角上，又把血倒在坛脚那里。"},
{volumn:         3,chapter:         9,versenumber:        10,verse:"惟有赎罪祭的脂油和腰子，并肝上取的网子，都烧在坛上，是照耶和华所吩咐摩西的；"},
{volumn:         3,chapter:         9,versenumber:        11,verse:"又用火将肉和皮烧在营外。"},
{volumn:         3,chapter:         9,versenumber:        12,verse:"亚伦宰了燔祭牲，他儿子把血递给他，他就洒在坛的周围，"},
{volumn:         3,chapter:         9,versenumber:        13,verse:"又把燔祭一块一块地、连头递给他，他都烧在坛上；"},
{volumn:         3,chapter:         9,versenumber:        14,verse:"又洗了脏腑和腿，烧在坛上的燔祭上。"},
{volumn:         3,chapter:         9,versenumber:        15,verse:"他奉上百姓的供物，把那给百姓作赎罪祭的公山羊宰了，为罪献上，和先献的一样；"},
{volumn:         3,chapter:         9,versenumber:        16,verse:"也奉上燔祭，照例而献。"},
{volumn:         3,chapter:         9,versenumber:        17,verse:"他又奉上素祭，从其中取一满把，烧在坛上；这是在早晨的燔祭以外。"},
{volumn:         3,chapter:         9,versenumber:        18,verse:"亚伦宰了那给百姓作平安祭的公牛和公绵羊。他儿子把血递给他，他就洒在坛的周围；"},
{volumn:         3,chapter:         9,versenumber:        19,verse:"又把公牛和公绵羊的脂油、肥尾巴，并盖脏的脂油与腰子，和肝上的网子，都递给他；"},
{volumn:         3,chapter:         9,versenumber:        20,verse:"把脂油放在胸上，他就把脂油烧在坛上。"},
{volumn:         3,chapter:         9,versenumber:        21,verse:"胸和右腿，亚伦当作摇祭，在耶和华面前摇一摇，都是照摩西所吩咐的。"},
{volumn:         3,chapter:         9,versenumber:        22,verse:"亚伦向百姓举手，为他们祝福。他献了赎罪祭、燔祭、平安祭就下来了。"},
{volumn:         3,chapter:         9,versenumber:        23,verse:"摩西、亚伦进入会幕，又出来为百姓祝福，耶和华的荣光就向众民显现。"},
{volumn:         3,chapter:         9,versenumber:        24,verse:"有火从耶和华面前出来，在坛上烧尽燔祭和脂油；众民一见，就都欢呼，俯伏在地。"},
{volumn:         3,chapter:        10,versenumber:         1,verse:"亚伦的儿子拿答、亚比户各拿自己的香炉，盛上火，加上香，在耶和华面前献上凡火，是耶和华没有吩咐他们的，"},
{volumn:         3,chapter:        10,versenumber:         2,verse:"就有火从耶和华面前出来，把他们烧灭，他们就死在耶和华面前。"},
{volumn:         3,chapter:        10,versenumber:         3,verse:"于是摩西对亚伦说：“这就是耶和华所说：‘我在亲近我的人中要显为圣；在众民面前，我要得荣耀。’”亚伦就默默不言。"},
{volumn:         3,chapter:        10,versenumber:         4,verse:"摩西召了亚伦叔父乌薛的儿子米沙利、以利撒反来，对他们说：“上前来，把你们的亲属从圣所前抬到营外。”"},
{volumn:         3,chapter:        10,versenumber:         5,verse:"于是二人上前来，把他们穿着袍子抬到营外，是照摩西所吩咐的。"},
{volumn:         3,chapter:        10,versenumber:         6,verse:"摩西对亚伦和他儿子以利亚撒、以他玛说：“不可蓬头散发，也不可撕裂衣裳，免得你们死亡，又免得耶和华向会众发怒；只要你们的弟兄以色列全家为耶和华所发的火哀哭。"},
{volumn:         3,chapter:        10,versenumber:         7,verse:"你们也不可出会幕的门，恐怕你们死亡，因为耶和华的膏油在你们的身上。”他们就照摩西的话行了。"},
{volumn:         3,chapter:        10,versenumber:         8,verse:"耶和华晓谕亚伦说："},
{volumn:         3,chapter:        10,versenumber:         9,verse:"“你和你儿子进会幕的时候，清酒、浓酒都不可喝，免得你们死亡；这要作你们世世代代永远的定例。"},
{volumn:         3,chapter:        10,versenumber:        10,verse:"使你们可以将圣的、俗的，洁净的、不洁净的，分别出来；"},
{volumn:         3,chapter:        10,versenumber:        11,verse:"又使你们可以将耶和华藉摩西晓谕以色列人的一切律例教训他们。”"},
{volumn:         3,chapter:        10,versenumber:        12,verse:"摩西对亚伦和他剩下的儿子以利亚撒、以他玛说：“你们献给耶和华火祭中所剩的素祭，要在坛旁不带酵而吃，因为是至圣的。"},
{volumn:         3,chapter:        10,versenumber:        13,verse:"你们要在圣处吃；因为在献给耶和华的火祭中，这是你的份和你儿子的份；所吩咐我的本是这样。"},
{volumn:         3,chapter:        10,versenumber:        14,verse:"所摇的胸，所举的腿，你们要在洁净地方吃。你和你的儿女都要同吃；因为这些是从以色列人平安祭中给你，当你的份和你儿子的份。"},
{volumn:         3,chapter:        10,versenumber:        15,verse:"所举的腿，所摇的胸，他们要与火祭的脂油一同带来当摇祭，在耶和华面前摇一摇；这要归你和你儿子，当作永得的份，都是照耶和华所吩咐的。”"},
{volumn:         3,chapter:        10,versenumber:        16,verse:"当下摩西急切地寻找作赎罪祭的公山羊，谁知已经焚烧了，便向亚伦剩下的儿子以利亚撒、以他玛发怒，说："},
{volumn:         3,chapter:        10,versenumber:        17,verse:"“这赎罪祭既是至圣的，主又给了你们，为要你们担当会众的罪孽，在耶和华面前为他们赎罪，你们为何没有在圣所吃呢？"},
{volumn:         3,chapter:        10,versenumber:        18,verse:"看哪，这祭牲的血并没有拿到圣所里去，你们本当照我所吩咐的，在圣所里吃这祭肉。”"},
{volumn:         3,chapter:        10,versenumber:        19,verse:"亚伦对摩西说：“今天他们在耶和华面前献上赎罪祭和燔祭，我又遇见这样的灾，若今天吃了赎罪祭，耶和华岂能看为美呢？”"},
{volumn:         3,chapter:        10,versenumber:        20,verse:"摩西听见这话，便以为美。"},
{volumn:         3,chapter:        11,versenumber:         1,verse:"耶和华对摩西、亚伦说："},
{volumn:         3,chapter:        11,versenumber:         2,verse:"“你们晓谕以色列人说，在地上一切走兽中可吃的乃是这些："},
{volumn:         3,chapter:        11,versenumber:         3,verse:"凡蹄分两瓣、倒嚼的走兽，你们都可以吃。"},
{volumn:         3,chapter:        11,versenumber:         4,verse:"但那倒嚼或分蹄之中不可吃的乃是：骆驼，因为倒嚼不分蹄，就与你们不洁净；"},
{volumn:         3,chapter:        11,versenumber:         5,verse:"沙番，因为倒嚼不分蹄，就与你们不洁净；"},
{volumn:         3,chapter:        11,versenumber:         6,verse:"兔子，因为倒嚼不分蹄，就与你们不洁净；"},
{volumn:         3,chapter:        11,versenumber:         7,verse:"猪，因为蹄分两瓣，却不倒嚼，就与你们不洁净。"},
{volumn:         3,chapter:        11,versenumber:         8,verse:"这些兽的肉，你们不可吃；死的，你们不可摸，都与你们不洁净。"},
{volumn:         3,chapter:        11,versenumber:         9,verse:"“水中可吃的乃是这些：凡在水里、海里、河里、有翅有鳞的，都可以吃。"},
{volumn:         3,chapter:        11,versenumber:        10,verse:"凡在海里、河里，并一切水里游动的活物，无翅无鳞的，你们都当以为可憎。"},
{volumn:         3,chapter:        11,versenumber:        11,verse:"这些无翅无鳞、以为可憎的，你们不可吃它的肉；死的也当以为可憎。"},
{volumn:         3,chapter:        11,versenumber:        12,verse:"凡水里无翅无鳞的，你们都当以为可憎。"},
{volumn:         3,chapter:        11,versenumber:        13,verse:"“雀鸟中你们当以为可憎、不可吃的乃是：雕、狗头雕、红头雕、"},
{volumn:         3,chapter:        11,versenumber:        14,verse:"鹞鹰、小鹰与其类；"},
{volumn:         3,chapter:        11,versenumber:        15,verse:"乌鸦与其类；"},
{volumn:         3,chapter:        11,versenumber:        16,verse:"鸵鸟、夜鹰、鱼鹰、鹰与其类；"},
{volumn:         3,chapter:        11,versenumber:        17,verse:"鸮鸟、鸬鹚、猫头鹰、"},
{volumn:         3,chapter:        11,versenumber:        18,verse:"角鸱、鹈鹕、秃雕、"},
{volumn:         3,chapter:        11,versenumber:        19,verse:"鹳、鹭鸶与其类；戴鵀与蝙蝠。"},
{volumn:         3,chapter:        11,versenumber:        20,verse:"“凡有翅膀用四足爬行的物，你们都当以为可憎。"},
{volumn:         3,chapter:        11,versenumber:        21,verse:"只是有翅膀用四足爬行的物中，有足有腿，在地上蹦跳的，你们还可以吃。"},
{volumn:         3,chapter:        11,versenumber:        22,verse:"其中有蝗虫、蚂蚱、蟋蟀与其类；蚱蜢与其类；这些你们都可以吃。"},
{volumn:         3,chapter:        11,versenumber:        23,verse:"但是有翅膀有四足的爬物，你们都当以为可憎。"},
{volumn:         3,chapter:        11,versenumber:        24,verse:"“这些都能使你们不洁净。凡摸了死的，必不洁净到晚上。"},
{volumn:         3,chapter:        11,versenumber:        25,verse:"凡拿了死的，必不洁净到晚上，并要洗衣服。"},
{volumn:         3,chapter:        11,versenumber:        26,verse:"凡走兽分蹄不成两瓣、也不倒嚼的，是与你们不洁净；凡摸了的就不洁净。"},
{volumn:         3,chapter:        11,versenumber:        27,verse:"凡四足的走兽，用掌行走的，是与你们不洁净；摸其尸的，必不洁净到晚上。"},
{volumn:         3,chapter:        11,versenumber:        28,verse:"拿其尸的，必不洁净到晚上，并要洗衣服。这些是与你们不洁净的。"},
{volumn:         3,chapter:        11,versenumber:        29,verse:"“地上爬物与你们不洁净的乃是这些：鼬鼠、鼫鼠、蜥蜴与其类；"},
{volumn:         3,chapter:        11,versenumber:        30,verse:"壁虎、龙子、守宫、蛇医、蝘蜓。"},
{volumn:         3,chapter:        11,versenumber:        31,verse:"这些爬物都是与你们不洁净的。在它死了以后，凡摸了的，必不洁净到晚上。"},
{volumn:         3,chapter:        11,versenumber:        32,verse:"其中死了的，掉在什么东西上，这东西就不洁净，无论是木器、衣服、皮子、口袋，不拘是做什么工用的器皿，须要放在水中，必不洁净到晚上，到晚上才洁净了。"},
{volumn:         3,chapter:        11,versenumber:        33,verse:"若有死了掉在瓦器里的，其中不拘有什么，就不洁净，你们要把这瓦器打破了。"},
{volumn:         3,chapter:        11,versenumber:        34,verse:"其中一切可吃的食物，沾水的就不洁净，并且那样器皿中一切可喝的，也必不洁净。"},
{volumn:         3,chapter:        11,versenumber:        35,verse:"其中已死的，若有一点掉在什么物件上，那物件就不洁净，不拘是炉子，是锅台，就要打碎，都不洁净，也必与你们不洁净。"},
{volumn:         3,chapter:        11,versenumber:        36,verse:"但是泉源或是聚水的池子仍是洁净；惟挨了那死的，就不洁净。"},
{volumn:         3,chapter:        11,versenumber:        37,verse:"若是死的，有一点掉在要种的子粒上，子粒仍是洁净；"},
{volumn:         3,chapter:        11,versenumber:        38,verse:"若水已经浇在子粒上，那死的有一点掉在上头，这子粒就与你们不洁净。"},
{volumn:         3,chapter:        11,versenumber:        39,verse:"“你们可吃的走兽若是死了，有人摸它，必不洁净到晚上；"},
{volumn:         3,chapter:        11,versenumber:        40,verse:"有人吃那死了的走兽，必不洁净到晚上，并要洗衣服；拿了死走兽的，必不洁净到晚上，并要洗衣服。"},
{volumn:         3,chapter:        11,versenumber:        41,verse:"“凡地上的爬物是可憎的，都不可吃。"},
{volumn:         3,chapter:        11,versenumber:        42,verse:"凡用肚子行走的和用四足行走的，或是有许多足的，就是一切爬在地上的，你们都不可吃，因为是可憎的。"},
{volumn:         3,chapter:        11,versenumber:        43,verse:"你们不可因什么爬物使自己成为可憎的，也不可因这些使自己不洁净，以致染了污秽。"},
{volumn:         3,chapter:        11,versenumber:        44,verse:"我是耶和华你们的　神；所以你们要成为圣洁，因为我是圣洁的。你们也不可因地上的爬物污秽自己。"},
{volumn:         3,chapter:        11,versenumber:        45,verse:"我是把你们从埃及地领出来的耶和华，要作你们的　神；所以你们要圣洁，因为我是圣洁的。”"},
{volumn:         3,chapter:        11,versenumber:        46,verse:"这是走兽、飞鸟，和水中游动的活物，并地上爬物的条例。"},
{volumn:         3,chapter:        11,versenumber:        47,verse:"要把洁净的和不洁净的，可吃的与不可吃的活物，都分别出来。"},
{volumn:         3,chapter:        12,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        12,versenumber:         2,verse:"“你晓谕以色列人说：若有妇人怀孕生男孩，她就不洁净七天，像在月经污秽的日子不洁净一样。"},
{volumn:         3,chapter:        12,versenumber:         3,verse:"第八天，要给婴孩行割礼。"},
{volumn:         3,chapter:        12,versenumber:         4,verse:"妇人在产血不洁之中，要家居三十三天。她洁净的日子未满，不可摸圣物，也不可进入圣所。"},
{volumn:         3,chapter:        12,versenumber:         5,verse:"她若生女孩，就不洁净两个七天，像污秽的时候一样，要在产血不洁之中，家居六十六天。"},
{volumn:         3,chapter:        12,versenumber:         6,verse:"“满了洁净的日子，无论是为男孩是为女孩，她要把一岁的羊羔为燔祭，一只雏鸽或是一只斑鸠为赎罪祭，带到会幕门口交给祭司。"},
{volumn:         3,chapter:        12,versenumber:         7,verse:"祭司要献在耶和华面前，为她赎罪，她的血源就洁净了。这条例是为生育的妇人，无论是生男生女。"},
{volumn:         3,chapter:        12,versenumber:         8,verse:"她的力量若不够献一只羊羔，她就要取两只斑鸠或是两只雏鸽，一只为燔祭，一只为赎罪祭。祭司要为她赎罪，她就洁净了。”"},
{volumn:         3,chapter:        13,versenumber:         1,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         3,chapter:        13,versenumber:         2,verse:"“人的肉皮上若长了疖子，或长了癣，或长了火斑，在他肉皮上成了大麻风的灾病，就要将他带到祭司亚伦或亚伦作祭司的一个子孙面前。"},
{volumn:         3,chapter:        13,versenumber:         3,verse:"祭司要察看肉皮上的灾病，若灾病处的毛已经变白，灾病的现象深于肉上的皮，这便是大麻风的灾病。祭司要察看他，定他为不洁净。"},
{volumn:         3,chapter:        13,versenumber:         4,verse:"若火斑在他肉皮上是白的，现象不深于皮，其上的毛也没有变白，祭司就要将有灾病的人关锁七天。"},
{volumn:         3,chapter:        13,versenumber:         5,verse:"第七天，祭司要察看他，若看灾病止住了，没有在皮上发散，祭司还要将他关锁七天。"},
{volumn:         3,chapter:        13,versenumber:         6,verse:"第七天，祭司要再察看他，若灾病发暗，而且没有在皮上发散，祭司要定他为洁净，原来是癣；那人就要洗衣服，得为洁净。"},
{volumn:         3,chapter:        13,versenumber:         7,verse:"但他为得洁净，将身体给祭司察看以后，癣若在皮上发散开了，他要再将身体给祭司察看。"},
{volumn:         3,chapter:        13,versenumber:         8,verse:"祭司要察看，癣若在皮上发散，就要定他为不洁净，是大麻风。"},
{volumn:         3,chapter:        13,versenumber:         9,verse:"“人有了大麻风的灾病，就要将他带到祭司面前。"},
{volumn:         3,chapter:        13,versenumber:        10,verse:"祭司要察看，皮上若长了白疖，使毛变白，在长白疖之处有了红瘀肉，"},
{volumn:         3,chapter:        13,versenumber:        11,verse:"这是肉皮上的旧大麻风，祭司要定他为不洁净，不用将他关锁，因为他是不洁净了。"},
{volumn:         3,chapter:        13,versenumber:        12,verse:"大麻风若在皮上四外发散，长满了患灾病人的皮，据祭司察看，从头到脚无处不有，"},
{volumn:         3,chapter:        13,versenumber:        13,verse:"祭司就要察看，全身的肉若长满了大麻风，就要定那患灾病的为洁净；全身都变为白，他乃洁净了。"},
{volumn:         3,chapter:        13,versenumber:        14,verse:"但红肉几时显在他的身上就几时不洁净。"},
{volumn:         3,chapter:        13,versenumber:        15,verse:"祭司一看那红肉就定他为不洁净。红肉本是不洁净，是大麻风。"},
{volumn:         3,chapter:        13,versenumber:        16,verse:"红肉若复原，又变白了，他就要来见祭司。"},
{volumn:         3,chapter:        13,versenumber:        17,verse:"祭司要察看，灾病处若变白了，祭司就要定那患灾病的为洁净，他乃洁净了。"},
{volumn:         3,chapter:        13,versenumber:        18,verse:"“人若在皮肉上长疮，却治好了，"},
{volumn:         3,chapter:        13,versenumber:        19,verse:"在长疮之处又起了白疖，或是白中带红的火斑，就要给祭司察看。"},
{volumn:         3,chapter:        13,versenumber:        20,verse:"祭司要察看，若现象洼于皮，其上的毛也变白了，就要定他为不洁净，是大麻风的灾病发在疮中。"},
{volumn:         3,chapter:        13,versenumber:        21,verse:"祭司若察看，其上没有白毛，也没有洼于皮，乃是发暗，就要将他关锁七天。"},
{volumn:         3,chapter:        13,versenumber:        22,verse:"若在皮上发散开了，祭司就要定他为不洁净，是灾病。"},
{volumn:         3,chapter:        13,versenumber:        23,verse:"火斑若在原处止住，没有发散，便是疮的痕迹，祭司就要定他为洁净。"},
{volumn:         3,chapter:        13,versenumber:        24,verse:"“人的皮肉上若起了火毒，火毒的瘀肉成了火斑，或是白中带红的，或是全白的，"},
{volumn:         3,chapter:        13,versenumber:        25,verse:"祭司就要察看，火斑中的毛若变白了，现象又深于皮，是大麻风在火毒中发出，就要定他为不洁净，是大麻风的灾病。"},
{volumn:         3,chapter:        13,versenumber:        26,verse:"但是祭司察看，在火斑中若没有白毛，也没有洼于皮，乃是发暗，就要将他关锁七天。"},
{volumn:         3,chapter:        13,versenumber:        27,verse:"到第七天，祭司要察看他，火斑若在皮上发散开了，就要定他为不洁净，是大麻风的灾病。"},
{volumn:         3,chapter:        13,versenumber:        28,verse:"火斑若在原处止住，没有在皮上发散，乃是发暗，是起的火毒，祭司要定他为洁净，不过是火毒的痕迹。"},
{volumn:         3,chapter:        13,versenumber:        29,verse:"“无论男女，若在头上有灾病，或是男人胡须上有灾病，"},
{volumn:         3,chapter:        13,versenumber:        30,verse:"祭司就要察看；这灾病现象若深于皮，其间有细黄毛，就要定他为不洁净，这是头疥，是头上或是胡须上的大麻风。"},
{volumn:         3,chapter:        13,versenumber:        31,verse:"祭司若察看头疥的灾病，现象不深于皮，其间也没有黑毛，就要将长头疥灾病的关锁七天。"},
{volumn:         3,chapter:        13,versenumber:        32,verse:"第七天，祭司要察看灾病，若头疥没有发散，其间也没有黄毛，头疥的现象不深于皮，"},
{volumn:         3,chapter:        13,versenumber:        33,verse:"那人就要剃去须发，但他不可剃头疥之处。祭司要将那长头疥的，再关锁七天。"},
{volumn:         3,chapter:        13,versenumber:        34,verse:"第七天，祭司要察看头疥，头疥若没有在皮上发散，现象也不深于皮，就要定他为洁净，他要洗衣服，便成为洁净。"},
{volumn:         3,chapter:        13,versenumber:        35,verse:"但他得洁净以后，头疥若在皮上发散开了，"},
{volumn:         3,chapter:        13,versenumber:        36,verse:"祭司就要察看他。头疥若在皮上发散，就不必找那黄毛，他是不洁净了。"},
{volumn:         3,chapter:        13,versenumber:        37,verse:"祭司若看头疥已经止住，其间也长了黑毛，头疥已然痊愈，那人是洁净了，就要定他为洁净。"},
{volumn:         3,chapter:        13,versenumber:        38,verse:"“无论男女，皮肉上若起了火斑，就是白火斑，"},
{volumn:         3,chapter:        13,versenumber:        39,verse:"祭司就要察看，他们肉皮上的火斑若白中带黑，这是皮上发出的白癣，那人是洁净了。"},
{volumn:         3,chapter:        13,versenumber:        40,verse:"“人头上的发若掉了，他不过是头秃，还是洁净。"},
{volumn:         3,chapter:        13,versenumber:        41,verse:"他顶前若掉了头发，他不过是顶门秃，还是洁净。"},
{volumn:         3,chapter:        13,versenumber:        42,verse:"头秃处或是顶门秃处若有白中带红的灾病，这就是大麻风发在他头秃处或是顶门秃处，"},
{volumn:         3,chapter:        13,versenumber:        43,verse:"祭司就要察看，他起的那灾病若在头秃处或是顶门秃处有白中带红的，像肉皮上大麻风的现象，"},
{volumn:         3,chapter:        13,versenumber:        44,verse:"那人就是长大麻风，不洁净的，祭司总要定他为不洁净，他的灾病是在头上。"},
{volumn:         3,chapter:        13,versenumber:        45,verse:"“身上有长大麻风灾病的，他的衣服要撕裂，也要蓬头散发，蒙着上唇，喊叫说：‘不洁净了！不洁净了！’"},
{volumn:         3,chapter:        13,versenumber:        46,verse:"灾病在他身上的日子，他便是不洁净；他既是不洁净，就要独居营外。”"},
{volumn:         3,chapter:        13,versenumber:        47,verse:"“染了大麻风灾病的衣服，无论是羊毛衣服、是麻布衣服，"},
{volumn:         3,chapter:        13,versenumber:        48,verse:"无论是在经上、在纬上，是麻布的、是羊毛的，是在皮子上，或在皮子做的什么物件上，"},
{volumn:         3,chapter:        13,versenumber:        49,verse:"或在衣服上、皮子上，经上、纬上，或在皮子做的什么物件上，这灾病若是发绿，或是发红，是大麻风的灾病，要给祭司察看。"},
{volumn:         3,chapter:        13,versenumber:        50,verse:"祭司就要察看那灾病，把染了灾病的物件关锁七天。"},
{volumn:         3,chapter:        13,versenumber:        51,verse:"第七天，他要察看那灾病，灾病或在衣服上，经上、纬上，皮子上，若发散，这皮子无论当作何用，这灾病是蚕食的大麻风，都是不洁净了。"},
{volumn:         3,chapter:        13,versenumber:        52,verse:"那染了灾病的衣服，或是经上、纬上，羊毛上，麻衣上，或是皮子做的什么物件上，他都要焚烧；因为这是蚕食的大麻风，必在火中焚烧。"},
{volumn:         3,chapter:        13,versenumber:        53,verse:"“祭司要察看，若灾病在衣服上，经上、纬上，或是皮子做的什么物件上，没有发散，"},
{volumn:         3,chapter:        13,versenumber:        54,verse:"祭司就要吩咐他们，把染了灾病的物件洗了，再关锁七天。"},
{volumn:         3,chapter:        13,versenumber:        55,verse:"洗过以后，祭司要察看，那物件若没有变色，灾病也没有消散，那物件就不洁净，是透重的灾病，无论正面反面，都要在火中焚烧。"},
{volumn:         3,chapter:        13,versenumber:        56,verse:"洗过以后，祭司要察看，若见那灾病发暗，他就要把那灾病从衣服上，皮子上，经上、纬上，都撕去。"},
{volumn:         3,chapter:        13,versenumber:        57,verse:"若仍现在衣服上，或是经上、纬上、皮子做的什么物件上，这就是灾病又发了，必用火焚烧那染灾病的物件。"},
{volumn:         3,chapter:        13,versenumber:        58,verse:"所洗的衣服，或是经，或是纬，或是皮子做的什么物件，若灾病离开了，要再洗，就洁净了。”"},
{volumn:         3,chapter:        13,versenumber:        59,verse:"这就是大麻风灾病的条例，无论是在羊毛衣服上，麻布衣服上，经上、纬上，和皮子做的什么物件上，可以定为洁净或是不洁净。"},
{volumn:         3,chapter:        14,versenumber:         1,verse:"耶和华晓谕摩西说：“"},
{volumn:         3,chapter:        14,versenumber:         2,verse:"长大麻风得洁净的日子，其例乃是这样：要带他去见祭司；"},
{volumn:         3,chapter:        14,versenumber:         3,verse:"祭司要出到营外察看，若见他的大麻风痊愈了，"},
{volumn:         3,chapter:        14,versenumber:         4,verse:"就要吩咐人为那求洁净的拿两只洁净的活鸟和香柏木、朱红色线，并牛膝草来。"},
{volumn:         3,chapter:        14,versenumber:         5,verse:"祭司要吩咐用瓦器盛活水，把一只鸟宰在上面。"},
{volumn:         3,chapter:        14,versenumber:         6,verse:"至于那只活鸟，祭司要把它和香柏木、朱红色线并牛膝草一同蘸于宰在活水上的鸟血中，"},
{volumn:         3,chapter:        14,versenumber:         7,verse:"用以在那长大麻风求洁净的人身上洒七次，就定他为洁净，又把活鸟放在田野里。"},
{volumn:         3,chapter:        14,versenumber:         8,verse:"求洁净的人当洗衣服，剃去毛发，用水洗澡，就洁净了；然后可以进营，只是要在自己的帐棚外居住七天。"},
{volumn:         3,chapter:        14,versenumber:         9,verse:"第七天，再把头上所有的头发与胡须、眉毛，并全身的毛，都剃了；又要洗衣服，用水洗身，就洁净了。"},
{volumn:         3,chapter:        14,versenumber:        10,verse:"“第八天，他要取两只没有残疾的公羊羔和一只没有残疾、一岁的母羊羔，又要把调油的细面伊法十分之三为素祭，并油一罗革，一同取来。"},
{volumn:         3,chapter:        14,versenumber:        11,verse:"行洁净之礼的祭司要将那求洁净的人和这些东西安置在会幕门口、耶和华面前。"},
{volumn:         3,chapter:        14,versenumber:        12,verse:"祭司要取一只公羊羔献为赎愆祭，和那一罗革油一同作摇祭，在耶和华面前摇一摇；"},
{volumn:         3,chapter:        14,versenumber:        13,verse:"把公羊羔宰于圣地，就是宰赎罪祭牲和燔祭牲之地。赎愆祭要归祭司，与赎罪祭一样，是至圣的。"},
{volumn:         3,chapter:        14,versenumber:        14,verse:"祭司要取些赎愆祭牲的血，抹在求洁净人的右耳垂上和右手的大拇指上，并右脚的大拇指上。"},
{volumn:         3,chapter:        14,versenumber:        15,verse:"祭司要从那一罗革油中取些倒在自己的左手掌里，"},
{volumn:         3,chapter:        14,versenumber:        16,verse:"把右手的一个指头蘸在左手的油里，在耶和华面前用指头弹七次。"},
{volumn:         3,chapter:        14,versenumber:        17,verse:"将手里所剩的油抹在那求洁净人的右耳垂上和右手的大拇指上，并右脚的大拇指上，就是抹在赎愆祭牲的血上。"},
{volumn:         3,chapter:        14,versenumber:        18,verse:"祭司手里所剩的油要抹在那求洁净人的头上，在耶和华面前为他赎罪。"},
{volumn:         3,chapter:        14,versenumber:        19,verse:"祭司要献赎罪祭，为那本不洁净、求洁净的人赎罪；然后要宰燔祭牲，"},
{volumn:         3,chapter:        14,versenumber:        20,verse:"把燔祭和素祭献在坛上，为他赎罪，他就洁净了。"},
{volumn:         3,chapter:        14,versenumber:        21,verse:"“他若贫穷不能预备够数，就要取一只公羊羔作赎愆祭，可以摇一摇，为他赎罪；也要把调油的细面伊法十分之一为素祭，和油一罗革一同取来；"},
{volumn:         3,chapter:        14,versenumber:        22,verse:"又照他的力量取两只斑鸠或是两只雏鸽，一只作赎罪祭，一只作燔祭。"},
{volumn:         3,chapter:        14,versenumber:        23,verse:"第八天，要为洁净，把这些带到会幕门口、耶和华面前，交给祭司。"},
{volumn:         3,chapter:        14,versenumber:        24,verse:"祭司要把赎愆祭的羊羔和那一罗革油一同作摇祭，在耶和华面前摇一摇。"},
{volumn:         3,chapter:        14,versenumber:        25,verse:"要宰了赎愆祭的羊羔，取些赎愆祭牲的血，抹在那求洁净人的右耳垂上和右手的大拇指上，并右脚的大拇指上。"},
{volumn:         3,chapter:        14,versenumber:        26,verse:"祭司要把些油倒在自己的左手掌里，"},
{volumn:         3,chapter:        14,versenumber:        27,verse:"把左手里的油，在耶和华面前，用右手的一个指头弹七次，"},
{volumn:         3,chapter:        14,versenumber:        28,verse:"又把手里的油抹些在那求洁净人的右耳垂上和右手的大拇指上，并右脚的大拇指上，就是抹赎愆祭之血的原处。"},
{volumn:         3,chapter:        14,versenumber:        29,verse:"祭司手里所剩的油要抹在那求洁净人的头上，在耶和华面前为他赎罪。"},
{volumn:         3,chapter:        14,versenumber:        30,verse:"那人又要照他的力量献上一只斑鸠或是一只雏鸽，"},
{volumn:         3,chapter:        14,versenumber:        31,verse:"就是他所能办的，一只为赎罪祭，一只为燔祭，与素祭一同献上；祭司要在耶和华面前为他赎罪。"},
{volumn:         3,chapter:        14,versenumber:        32,verse:"这是那有大麻风灾病的人、不能将关乎得洁净之物预备够数的条例。”"},
{volumn:         3,chapter:        14,versenumber:        33,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         3,chapter:        14,versenumber:        34,verse:"“你们到了我赐给你们为业的迦南地，我若使你们所得为业之地的房屋中有大麻风的灾病，"},
{volumn:         3,chapter:        14,versenumber:        35,verse:"房主就要去告诉祭司说：‘据我看，房屋中似乎有灾病。’"},
{volumn:         3,chapter:        14,versenumber:        36,verse:"祭司还没有进去察看灾病以前，就要吩咐人把房子腾空，免得房子里所有的都成了不洁净；然后祭司要进去察看房子。"},
{volumn:         3,chapter:        14,versenumber:        37,verse:"他要察看那灾病，灾病若在房子的墙上有发绿或发红的凹斑纹，现象洼于墙，"},
{volumn:         3,chapter:        14,versenumber:        38,verse:"祭司就要出到房门外，把房子封锁七天。"},
{volumn:         3,chapter:        14,versenumber:        39,verse:"第七天，祭司要再去察看，灾病若在房子的墙上发散，"},
{volumn:         3,chapter:        14,versenumber:        40,verse:"就要吩咐人把那有灾病的石头挖出来，扔在城外不洁净之处；"},
{volumn:         3,chapter:        14,versenumber:        41,verse:"也要叫人刮房内的四围，所刮掉的灰泥要倒在城外不洁净之处；"},
{volumn:         3,chapter:        14,versenumber:        42,verse:"又要用别的石头代替那挖出来的石头，要另用灰泥墁房子。"},
{volumn:         3,chapter:        14,versenumber:        43,verse:"“他挖出石头，刮了房子，墁了以后，灾病若在房子里又发现，"},
{volumn:         3,chapter:        14,versenumber:        44,verse:"祭司就要进去察看，灾病若在房子里发散，这就是房内蚕食的大麻风，是不洁净。"},
{volumn:         3,chapter:        14,versenumber:        45,verse:"他就要拆毁房子，把石头、木头、灰泥都搬到城外不洁净之处。"},
{volumn:         3,chapter:        14,versenumber:        46,verse:"在房子封锁的时候，进去的人必不洁净到晚上；"},
{volumn:         3,chapter:        14,versenumber:        47,verse:"在房子里躺着的必洗衣服；在房子里吃饭的也必洗衣服。"},
{volumn:         3,chapter:        14,versenumber:        48,verse:"“房子墁了以后，祭司若进去察看，见灾病在房内没有发散，就要定房子为洁净，因为灾病已经消除。"},
{volumn:         3,chapter:        14,versenumber:        49,verse:"要为洁净房子取两只鸟和香柏木、朱红色线并牛膝草，"},
{volumn:         3,chapter:        14,versenumber:        50,verse:"用瓦器盛活水，把一只鸟宰在上面，"},
{volumn:         3,chapter:        14,versenumber:        51,verse:"把香柏木、牛膝草、朱红色线，并那活鸟，都蘸在被宰的鸟血中与活水中，用以洒房子七次。"},
{volumn:         3,chapter:        14,versenumber:        52,verse:"要用鸟血、活水、活鸟、香柏木、牛膝草，并朱红色线，洁净那房子。"},
{volumn:         3,chapter:        14,versenumber:        53,verse:"但要把活鸟放在城外田野里。这样洁净房子（原文是为房子赎罪），房子就洁净了。”"},
{volumn:         3,chapter:        14,versenumber:        54,verse:"这是为各类大麻风的灾病和头疥，"},
{volumn:         3,chapter:        14,versenumber:        55,verse:"并衣服与房子的大麻风，"},
{volumn:         3,chapter:        14,versenumber:        56,verse:"以及疖子、癣、火斑所立的条例，"},
{volumn:         3,chapter:        14,versenumber:        57,verse:"指明何时为洁净，何时为不洁净。这是大麻风的条例。"},
{volumn:         3,chapter:        15,versenumber:         1,verse:"耶和华对摩西、亚伦说："},
{volumn:         3,chapter:        15,versenumber:         2,verse:"“你们晓谕以色列人说：人若身患漏症，他因这漏症就不洁净了。"},
{volumn:         3,chapter:        15,versenumber:         3,verse:"他患漏症，无论是下流的，是止住的，都是不洁净。"},
{volumn:         3,chapter:        15,versenumber:         4,verse:"他所躺的床都为不洁净，所坐的物也为不洁净。"},
{volumn:         3,chapter:        15,versenumber:         5,verse:"凡摸那床的，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:         6,verse:"那坐患漏症人所坐之物的，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:         7,verse:"那摸患漏症人身体的，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:         8,verse:"若患漏症人吐在洁净的人身上，那人必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:         9,verse:"患漏症人所骑的鞍子也为不洁净。"},
{volumn:         3,chapter:        15,versenumber:        10,verse:"凡摸了他身下之物的，必不洁净到晚上；拿了那物的，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:        11,verse:"患漏症的人没有用水涮手，无论摸了谁，谁必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:        12,verse:"患漏症人所摸的瓦器就必打破；所摸的一切木器也必用水涮洗。"},
{volumn:         3,chapter:        15,versenumber:        13,verse:"“患漏症的人痊愈了，就要为洁净自己计算七天，也必洗衣服，用活水洗身，就洁净了。"},
{volumn:         3,chapter:        15,versenumber:        14,verse:"第八天，要取两只斑鸠或是两只雏鸽，来到会幕门口、耶和华面前，把鸟交给祭司。"},
{volumn:         3,chapter:        15,versenumber:        15,verse:"祭司要献上一只为赎罪祭，一只为燔祭；因那人患的漏症，祭司要在耶和华面前为他赎罪。"},
{volumn:         3,chapter:        15,versenumber:        16,verse:"“人若梦遗，他必不洁净到晚上，并要用水洗全身。"},
{volumn:         3,chapter:        15,versenumber:        17,verse:"无论是衣服是皮子，被精所染，必不洁净到晚上，并要用水洗。"},
{volumn:         3,chapter:        15,versenumber:        18,verse:"若男女交合，两个人必不洁净到晚上，并要用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:        19,verse:"“女人行经，必污秽七天；凡摸她的，必不洁净到晚上。"},
{volumn:         3,chapter:        15,versenumber:        20,verse:"女人在污秽之中，凡她所躺的物件都为不洁净，所坐的物件也都不洁净。"},
{volumn:         3,chapter:        15,versenumber:        21,verse:"凡摸她床的，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:        22,verse:"凡摸她所坐什么物件的，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:        23,verse:"在女人的床上，或在她坐的物上，若有别的物件，人一摸了，必不洁净到晚上。"},
{volumn:         3,chapter:        15,versenumber:        24,verse:"男人若与那女人同房，染了她的污秽，就要七天不洁净；所躺的床也为不洁净。"},
{volumn:         3,chapter:        15,versenumber:        25,verse:"“女人若在经期以外患多日的血漏，或是经期过长，有了漏症，她就因这漏症不洁净，与她在经期不洁净一样。"},
{volumn:         3,chapter:        15,versenumber:        26,verse:"她在患漏症的日子所躺的床、所坐的物都要看为不洁净，与她月经的时候一样。"},
{volumn:         3,chapter:        15,versenumber:        27,verse:"凡摸这些物件的，就为不洁净，必不洁净到晚上，并要洗衣服，用水洗澡。"},
{volumn:         3,chapter:        15,versenumber:        28,verse:"女人的漏症若好了，就要计算七天，然后才为洁净。"},
{volumn:         3,chapter:        15,versenumber:        29,verse:"第八天，要取两只斑鸠或是两只雏鸽，带到会幕门口给祭司。"},
{volumn:         3,chapter:        15,versenumber:        30,verse:"祭司要献一只为赎罪祭，一只为燔祭；因那人血漏不洁，祭司要在耶和华面前为她赎罪。"},
{volumn:         3,chapter:        15,versenumber:        31,verse:"“你们要这样使以色列人与他们的污秽隔绝，免得他们玷污我的帐幕，就因自己的污秽死亡。”"},
{volumn:         3,chapter:        15,versenumber:        32,verse:"这是患漏症和梦遗而不洁净的，"},
{volumn:         3,chapter:        15,versenumber:        33,verse:"并有月经病的和患漏症的，无论男女，并人与不洁净女人同房的条例。"},
{volumn:         3,chapter:        16,versenumber:         1,verse:"亚伦的两个儿子近到耶和华面前死了。死了之后，耶和华晓谕摩西说："},
{volumn:         3,chapter:        16,versenumber:         2,verse:"“要告诉你哥哥亚伦，不可随时进圣所的幔子内、到柜上的施恩座前，免得他死亡，因为我要从云中显现在施恩座上。"},
{volumn:         3,chapter:        16,versenumber:         3,verse:"亚伦进圣所，要带一只公牛犊为赎罪祭，一只公绵羊为燔祭。"},
{volumn:         3,chapter:        16,versenumber:         4,verse:"要穿上细麻布圣内袍，把细麻布裤子穿在身上，腰束细麻布带子，头戴细麻布冠冕；这都是圣服。他要用水洗身，然后穿戴。"},
{volumn:         3,chapter:        16,versenumber:         5,verse:"要从以色列会众取两只公山羊为赎罪祭，一只公绵羊为燔祭。"},
{volumn:         3,chapter:        16,versenumber:         6,verse:"“亚伦要把赎罪祭的公牛奉上，为自己和本家赎罪；"},
{volumn:         3,chapter:        16,versenumber:         7,verse:"也要把两只公山羊安置在会幕门口、耶和华面前，"},
{volumn:         3,chapter:        16,versenumber:         8,verse:"为那两只羊拈阄，一阄归与耶和华，一阄归与阿撒泻勒。"},
{volumn:         3,chapter:        16,versenumber:         9,verse:"亚伦要把那拈阄归与耶和华的羊献为赎罪祭，"},
{volumn:         3,chapter:        16,versenumber:        10,verse:"但那拈阄归与阿撒泻勒的羊要活着安置在耶和华面前，用以赎罪，打发人送到旷野去，归与阿撒泻勒。"},
{volumn:         3,chapter:        16,versenumber:        11,verse:"“亚伦要把赎罪祭的公牛牵来宰了，为自己和本家赎罪；"},
{volumn:         3,chapter:        16,versenumber:        12,verse:"拿香炉，从耶和华面前的坛上盛满火炭，又拿一捧捣细的香料，都带入幔子内，"},
{volumn:         3,chapter:        16,versenumber:        13,verse:"在耶和华面前，把香放在火上，使香的烟云遮掩法柜上的施恩座，免得他死亡；"},
{volumn:         3,chapter:        16,versenumber:        14,verse:"也要取些公牛的血，用指头弹在施恩座的东面，又在施恩座的前面弹血七次。"},
{volumn:         3,chapter:        16,versenumber:        15,verse:"“随后他要宰那为百姓作赎罪祭的公山羊，把羊的血带入幔子内，弹在施恩座的上面和前面，好像弹公牛的血一样。"},
{volumn:         3,chapter:        16,versenumber:        16,verse:"他因以色列人诸般的污秽、过犯，就是他们一切的罪愆，当这样在圣所行赎罪之礼，并因会幕在他们污秽之中，也要照样而行。"},
{volumn:         3,chapter:        16,versenumber:        17,verse:"他进圣所赎罪的时候，会幕里不可有人，直等到他为自己和本家并以色列全会众赎了罪出来。"},
{volumn:         3,chapter:        16,versenumber:        18,verse:"他出来，要到耶和华面前的坛那里，在坛上行赎罪之礼，又要取些公牛的血和公山羊的血，抹在坛上四角的周围；"},
{volumn:         3,chapter:        16,versenumber:        19,verse:"也要用指头把血弹在坛上七次，洁净了坛，从坛上除掉以色列人诸般的污秽，使坛成圣。”"},
{volumn:         3,chapter:        16,versenumber:        20,verse:"“亚伦为圣所和会幕并坛献完了赎罪祭，就要把那只活着的公山羊奉上。"},
{volumn:         3,chapter:        16,versenumber:        21,verse:"两手按在羊头上，承认以色列人诸般的罪孽过犯，就是他们一切的罪愆，把这罪都归在羊的头上，藉着所派之人的手，送到旷野去。"},
{volumn:         3,chapter:        16,versenumber:        22,verse:"要把这羊放在旷野，这羊要担当他们一切的罪孽，带到无人之地。"},
{volumn:         3,chapter:        16,versenumber:        23,verse:"“亚伦要进会幕，把他进圣所时所穿的细麻布衣服脱下，放在那里，"},
{volumn:         3,chapter:        16,versenumber:        24,verse:"又要在圣处用水洗身，穿上衣服，出来，把自己的燔祭和百姓的燔祭献上，为自己和百姓赎罪。"},
{volumn:         3,chapter:        16,versenumber:        25,verse:"赎罪祭牲的脂油要在坛上焚烧。"},
{volumn:         3,chapter:        16,versenumber:        26,verse:"那放羊归与阿撒泻勒的人要洗衣服，用水洗身，然后进营。"},
{volumn:         3,chapter:        16,versenumber:        27,verse:"作赎罪祭的公牛和公山羊的血既带入圣所赎罪，这牛羊就要搬到营外，将皮、肉、粪用火焚烧。"},
{volumn:         3,chapter:        16,versenumber:        28,verse:"焚烧的人要洗衣服，用水洗身，然后进营。”"},
{volumn:         3,chapter:        16,versenumber:        29,verse:"“每逢七月初十日，你们要刻苦己心，无论是本地人，是寄居在你们中间的外人，什么工都不可做；这要作你们永远的定例。"},
{volumn:         3,chapter:        16,versenumber:        30,verse:"因在这日要为你们赎罪，使你们洁净。你们要在耶和华面前得以洁净，脱尽一切的罪愆。"},
{volumn:         3,chapter:        16,versenumber:        31,verse:"这日你们要守为圣安息日，要刻苦己心；这为永远的定例。"},
{volumn:         3,chapter:        16,versenumber:        32,verse:"那受膏、接续他父亲承接圣职的祭司要穿上细麻布的圣衣，行赎罪之礼。"},
{volumn:         3,chapter:        16,versenumber:        33,verse:"他要在至圣所和会幕与坛行赎罪之礼，并要为众祭司和会众的百姓赎罪。"},
{volumn:         3,chapter:        16,versenumber:        34,verse:"这要作你们永远的定例，就是因以色列人一切的罪，要一年一次为他们赎罪。”于是，亚伦照耶和华所吩咐摩西的行了。"},
{volumn:         3,chapter:        17,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        17,versenumber:         2,verse:"“你晓谕亚伦和他儿子并以色列众人说，耶和华所吩咐的乃是这样："},
{volumn:         3,chapter:        17,versenumber:         3,verse:"凡以色列家中的人宰公牛，或是绵羊羔，或是山羊，不拘宰于营内营外，"},
{volumn:         3,chapter:        17,versenumber:         4,verse:"若未曾牵到会幕门口、耶和华的帐幕前献给耶和华为供物，流血的罪必归到那人身上。他流了血，要从民中剪除。"},
{volumn:         3,chapter:        17,versenumber:         5,verse:"这是为要使以色列人把他们在田野里所献的祭带到会幕门口、耶和华面前，交给祭司，献与耶和华为平安祭。"},
{volumn:         3,chapter:        17,versenumber:         6,verse:"祭司要把血洒在会幕门口、耶和华的坛上，把脂油焚烧，献给耶和华为馨香的祭。"},
{volumn:         3,chapter:        17,versenumber:         7,verse:"他们不可再献祭给他们行邪淫所随从的鬼魔（原文是公山羊）；这要作他们世世代代永远的定例。"},
{volumn:         3,chapter:        17,versenumber:         8,verse:"“你要晓谕他们说：凡以色列家中的人，或是寄居在他们中间的外人，献燔祭或是平安祭，"},
{volumn:         3,chapter:        17,versenumber:         9,verse:"若不带到会幕门口献给耶和华，那人必从民中剪除。"},
{volumn:         3,chapter:        17,versenumber:        10,verse:"“凡以色列家中的人，或是寄居在他们中间的外人，若吃什么血，我必向那吃血的人变脸，把他从民中剪除。"},
{volumn:         3,chapter:        17,versenumber:        11,verse:"因为活物的生命是在血中。我把这血赐给你们，可以在坛上为你们的生命赎罪；因血里有生命，所以能赎罪。"},
{volumn:         3,chapter:        17,versenumber:        12,verse:"因此，我对以色列人说：你们都不可吃血；寄居在你们中间的外人也不可吃血。"},
{volumn:         3,chapter:        17,versenumber:        13,verse:"凡以色列人，或是寄居在他们中间的外人，若打猎得了可吃的禽兽，必放出它的血来，用土掩盖。"},
{volumn:         3,chapter:        17,versenumber:        14,verse:"“论到一切活物的生命，就在血中。所以我对以色列人说：无论什么活物的血，你们都不可吃，因为一切活物的血就是他的生命。凡吃了血的，必被剪除。"},
{volumn:         3,chapter:        17,versenumber:        15,verse:"凡吃自死的，或是被野兽撕裂的，无论是本地人，是寄居的，必不洁净到晚上，都要洗衣服，用水洗身，到了晚上才为洁净。"},
{volumn:         3,chapter:        17,versenumber:        16,verse:"但他若不洗衣服，也不洗身，就必担当他的罪孽。”"},
{volumn:         3,chapter:        18,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        18,versenumber:         2,verse:"“你晓谕以色列人说：我是耶和华你们的　神。"},
{volumn:         3,chapter:        18,versenumber:         3,verse:"你们从前住的埃及地，那里人的行为，你们不可效法，我要领你们到的迦南地，那里人的行为，也不可效法，也不可照他们的恶俗行。"},
{volumn:         3,chapter:        18,versenumber:         4,verse:"你们要遵我的典章，守我的律例，按此而行。我是耶和华你们的　神。"},
{volumn:         3,chapter:        18,versenumber:         5,verse:"所以，你们要守我的律例典章；人若遵行，就必因此活着。我是耶和华。"},
{volumn:         3,chapter:        18,versenumber:         6,verse:"“你们都不可露骨肉之亲的下体，亲近他们。我是耶和华。"},
{volumn:         3,chapter:        18,versenumber:         7,verse:"不可露你母亲的下体，羞辱了你父亲。她是你的母亲，不可露她的下体。"},
{volumn:         3,chapter:        18,versenumber:         8,verse:"不可露你继母的下体；这本是你父亲的下体。"},
{volumn:         3,chapter:        18,versenumber:         9,verse:"你的姐妹，不拘是异母同父的，是异父同母的，无论是生在家生在外的，都不可露她们的下体。"},
{volumn:         3,chapter:        18,versenumber:        10,verse:"不可露你孙女或是外孙女的下体，露了她们的下体就是露了自己的下体。"},
{volumn:         3,chapter:        18,versenumber:        11,verse:"你继母从你父亲生的女儿本是你的妹妹，不可露她的下体。"},
{volumn:         3,chapter:        18,versenumber:        12,verse:"不可露你姑母的下体；她是你父亲的骨肉之亲。"},
{volumn:         3,chapter:        18,versenumber:        13,verse:"不可露你姨母的下体；她是你母亲的骨肉之亲。"},
{volumn:         3,chapter:        18,versenumber:        14,verse:"不可亲近你伯叔之妻，羞辱了你伯叔；她是你的伯叔母。"},
{volumn:         3,chapter:        18,versenumber:        15,verse:"不可露你儿妇的下体；她是你儿子的妻，不可露她的下体。"},
{volumn:         3,chapter:        18,versenumber:        16,verse:"不可露你弟兄妻子的下体；这本是你弟兄的下体。"},
{volumn:         3,chapter:        18,versenumber:        17,verse:"不可露了妇人的下体，又露她女儿的下体，也不可娶她孙女或是外孙女，露她们的下体；她们是骨肉之亲，这本是大恶。"},
{volumn:         3,chapter:        18,versenumber:        18,verse:"你妻还在的时候，不可另娶她的姐妹作对头，露她的下体。"},
{volumn:         3,chapter:        18,versenumber:        19,verse:"“女人行经不洁净的时候，不可露她的下体，与她亲近。"},
{volumn:         3,chapter:        18,versenumber:        20,verse:"不可与邻舍的妻行淫，玷污自己。"},
{volumn:         3,chapter:        18,versenumber:        21,verse:"不可使你的儿女经火归与摩洛，也不可亵渎你　神的名。我是耶和华。"},
{volumn:         3,chapter:        18,versenumber:        22,verse:"不可与男人苟合，像与女人一样；这本是可憎恶的。"},
{volumn:         3,chapter:        18,versenumber:        23,verse:"不可与兽淫合，玷污自己。女人也不可站在兽前，与它淫合；这本是逆性的事。"},
{volumn:         3,chapter:        18,versenumber:        24,verse:"“在这一切的事上，你们都不可玷污自己；因为我在你们面前所逐出的列邦，在这一切的事上玷污了自己；"},
{volumn:         3,chapter:        18,versenumber:        25,verse:"连地也玷污了，所以我追讨那地的罪孽，那地也吐出它的居民。"},
{volumn:         3,chapter:        18,versenumber:        26,verse:"故此，你们要守我的律例典章。这一切可憎恶的事，无论是本地人，是寄居在你们中间的外人，都不可行，（"},
{volumn:         3,chapter:        18,versenumber:        27,verse:"在你们以先居住那地的人行了这一切可憎恶的事，地就玷污了，）"},
{volumn:         3,chapter:        18,versenumber:        28,verse:"免得你们玷污那地的时候，地就把你们吐出，像吐出在你们以先的国民一样。"},
{volumn:         3,chapter:        18,versenumber:        29,verse:"无论什么人，行了其中可憎的一件事，必从民中剪除。"},
{volumn:         3,chapter:        18,versenumber:        30,verse:"所以，你们要守我所吩咐的，免得你们随从那些可憎的恶俗，就是在你们以先的人所常行的，以致玷污了自己。我是耶和华你们的　神。”"},
{volumn:         3,chapter:        19,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        19,versenumber:         2,verse:"“你晓谕以色列全会众说：你们要圣洁，因为我耶和华你们的　神是圣洁的。"},
{volumn:         3,chapter:        19,versenumber:         3,verse:"你们各人都当孝敬父母，也要守我的安息日。我是耶和华你们的　神。"},
{volumn:         3,chapter:        19,versenumber:         4,verse:"你们不可偏向虚无的神，也不可为自己铸造神像。我是耶和华你们的　神。"},
{volumn:         3,chapter:        19,versenumber:         5,verse:"你们献平安祭给耶和华的时候，要献得可蒙悦纳。"},
{volumn:         3,chapter:        19,versenumber:         6,verse:"这祭物要在献的那一天和第二天吃，若有剩到第三天的，就必用火焚烧。"},
{volumn:         3,chapter:        19,versenumber:         7,verse:"第三天若再吃，这就为可憎恶的，必不蒙悦纳。"},
{volumn:         3,chapter:        19,versenumber:         8,verse:"凡吃的人必担当他的罪孽；因为他亵渎了耶和华的圣物，那人必从民中剪除。"},
{volumn:         3,chapter:        19,versenumber:         9,verse:"“在你们的地收割庄稼，不可割尽田角，也不可拾取所遗落的。"},
{volumn:         3,chapter:        19,versenumber:        10,verse:"不可摘尽葡萄园的果子，也不可拾取葡萄园所掉的果子；要留给穷人和寄居的。我是耶和华你们的　神。"},
{volumn:         3,chapter:        19,versenumber:        11,verse:"“你们不可偷盗，不可欺骗，也不可彼此说谎。"},
{volumn:         3,chapter:        19,versenumber:        12,verse:"不可指着我的名起假誓，亵渎你　神的名。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        13,verse:"“不可欺压你的邻舍，也不可抢夺他的物。雇工人的工价，不可在你那里过夜，留到早晨。"},
{volumn:         3,chapter:        19,versenumber:        14,verse:"不可咒骂聋子，也不可将绊脚石放在瞎子面前，只要敬畏你的　神。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        15,verse:"“你们施行审判，不可行不义；不可偏护穷人，也不可重看有势力的人，只要按着公义审判你的邻舍。"},
{volumn:         3,chapter:        19,versenumber:        16,verse:"不可在民中往来搬弄是非，也不可与邻舍为敌，置之于死（原文是流他的血）。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        17,verse:"“不可心里恨你的弟兄；总要指摘你的邻舍，免得因他担罪。"},
{volumn:         3,chapter:        19,versenumber:        18,verse:"不可报仇，也不可埋怨你本国的子民，却要爱人如己。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        19,verse:"“你们要守我的律例。不可叫你的牲畜与异类配合；不可用两样搀杂的种种你的地，也不可用两样搀杂的料做衣服穿在身上。"},
{volumn:         3,chapter:        19,versenumber:        20,verse:"“婢女许配了丈夫，还没有被赎、得释放，人若与她行淫，二人要受刑罚，却不把他们治死，因为婢女还没有得自由。"},
{volumn:         3,chapter:        19,versenumber:        21,verse:"那人要把赎愆祭，就是一只公绵羊牵到会幕门口、耶和华面前。"},
{volumn:         3,chapter:        19,versenumber:        22,verse:"祭司要用赎愆祭的羊在耶和华面前赎他所犯的罪，他的罪就必蒙赦免。"},
{volumn:         3,chapter:        19,versenumber:        23,verse:"“你们到了迦南地，栽种各样结果子的树木，就要以所结的果子如未受割礼的一样。三年之久，你们要以这些果子，如未受割礼的，是不可吃的。"},
{volumn:         3,chapter:        19,versenumber:        24,verse:"但第四年所结的果子全要成为圣，用以赞美耶和华。"},
{volumn:         3,chapter:        19,versenumber:        25,verse:"第五年，你们要吃那树上的果子，好叫树给你们结果子更多。我是耶和华你们的　神。"},
{volumn:         3,chapter:        19,versenumber:        26,verse:"“你们不可吃带血的物；不可用法术，也不可观兆。"},
{volumn:         3,chapter:        19,versenumber:        27,verse:"头的周围（或译：两鬓）不可剃，胡须的周围也不可损坏。"},
{volumn:         3,chapter:        19,versenumber:        28,verse:"不可为死人用刀划身，也不可在身上刺花纹。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        29,verse:"“不可辱没你的女儿，使她为娼妓，恐怕地上的人专向淫乱，地就满了大恶。"},
{volumn:         3,chapter:        19,versenumber:        30,verse:"你们要守我的安息日，敬我的圣所。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        31,verse:"“不可偏向那些交鬼的和行巫术的；不可求问他们，以致被他们玷污了。我是耶和华你们的　神。"},
{volumn:         3,chapter:        19,versenumber:        32,verse:"“在白发的人面前，你要站起来；也要尊敬老人，又要敬畏你的　神。我是耶和华。"},
{volumn:         3,chapter:        19,versenumber:        33,verse:"“若有外人在你们国中和你同居，就不可欺负他。"},
{volumn:         3,chapter:        19,versenumber:        34,verse:"和你们同居的外人，你们要看他如本地人一样，并要爱他如己，因为你们在埃及地也作过寄居的。我是耶和华你们的　神。"},
{volumn:         3,chapter:        19,versenumber:        35,verse:"“你们施行审判，不可行不义；在尺、秤、升、斗上也是如此。"},
{volumn:         3,chapter:        19,versenumber:        36,verse:"要用公道天平、公道法码、公道升斗、公道秤。我是耶和华你们的　神，曾把你们从埃及地领出来的。"},
{volumn:         3,chapter:        19,versenumber:        37,verse:"你们要谨守遵行我一切的律例典章。我是耶和华。”"},
{volumn:         3,chapter:        20,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        20,versenumber:         2,verse:"“你还要晓谕以色列人说：凡以色列人，或是在以色列中寄居的外人，把自己的儿女献给摩洛的，总要治死他；本地人要用石头把他打死。"},
{volumn:         3,chapter:        20,versenumber:         3,verse:"我也要向那人变脸，把他从民中剪除；因为他把儿女献给摩洛，玷污我的圣所，亵渎我的圣名。"},
{volumn:         3,chapter:        20,versenumber:         4,verse:"那人把儿女献给摩洛，本地人若佯为不见，不把他治死，"},
{volumn:         3,chapter:        20,versenumber:         5,verse:"我就要向这人和他的家变脸，把他和一切随他与摩洛行邪淫的人都从民中剪除。"},
{volumn:         3,chapter:        20,versenumber:         6,verse:"“人偏向交鬼的和行巫术的，随他们行邪淫，我要向那人变脸，把他从民中剪除。"},
{volumn:         3,chapter:        20,versenumber:         7,verse:"所以你们要自洁成圣，因为我是耶和华你们的　神。"},
{volumn:         3,chapter:        20,versenumber:         8,verse:"你们要谨守遵行我的律例；我是叫你们成圣的耶和华。"},
{volumn:         3,chapter:        20,versenumber:         9,verse:"凡咒骂父母的，总要治死他；他咒骂了父母，他的罪（原文是血；本章同）要归到他身上。"},
{volumn:         3,chapter:        20,versenumber:        10,verse:"“与邻舍之妻行淫的，奸夫淫妇都必治死。"},
{volumn:         3,chapter:        20,versenumber:        11,verse:"与继母行淫的，就是羞辱了他父亲，总要把他们二人治死，罪要归到他们身上。"},
{volumn:         3,chapter:        20,versenumber:        12,verse:"与儿妇同房的，总要把他们二人治死；他们行了逆伦的事，罪要归到他们身上。"},
{volumn:         3,chapter:        20,versenumber:        13,verse:"人若与男人苟合，像与女人一样，他们二人行了可憎的事，总要把他们治死，罪要归到他们身上。"},
{volumn:         3,chapter:        20,versenumber:        14,verse:"人若娶妻，并娶其母，便是大恶，要把这三人用火焚烧，使你们中间免去大恶。"},
{volumn:         3,chapter:        20,versenumber:        15,verse:"人若与兽淫合，总要治死他，也要杀那兽。"},
{volumn:         3,chapter:        20,versenumber:        16,verse:"女人若与兽亲近，与它淫合，你要杀那女人和那兽，总要把他们治死，罪要归到他们身上。"},
{volumn:         3,chapter:        20,versenumber:        17,verse:"“人若娶他的姐妹，无论是异母同父的，是异父同母的，彼此见了下体，这是可耻的事；他们必在本民的眼前被剪除。他露了姐妹的下体，必担当自己的罪孽。"},
{volumn:         3,chapter:        20,versenumber:        18,verse:"妇人有月经，若与她同房，露了她的下体，就是露了妇人的血源，妇人也露了自己的血源，二人必从民中剪除。"},
{volumn:         3,chapter:        20,versenumber:        19,verse:"不可露姨母或是姑母的下体，这是露了骨肉之亲的下体；二人必担当自己的罪孽。"},
{volumn:         3,chapter:        20,versenumber:        20,verse:"人若与伯叔之妻同房，就羞辱了他的伯叔；二人要担当自己的罪，必无子女而死。"},
{volumn:         3,chapter:        20,versenumber:        21,verse:"人若娶弟兄之妻，这本是污秽的事，羞辱了他的弟兄；二人必无子女。"},
{volumn:         3,chapter:        20,versenumber:        22,verse:"“所以，你们要谨守遵行我一切的律例典章，免得我领你们去住的那地把你们吐出。"},
{volumn:         3,chapter:        20,versenumber:        23,verse:"我在你们面前所逐出的国民，你们不可随从他们的风俗；因为他们行了这一切的事，所以我厌恶他们。"},
{volumn:         3,chapter:        20,versenumber:        24,verse:"但我对你们说过，你们要承受他们的地，就是我要赐给你们为业、流奶与蜜之地。我是耶和华你们的　神，使你们与万民有分别的。"},
{volumn:         3,chapter:        20,versenumber:        25,verse:"所以，你们要把洁净和不洁净的禽兽分别出来；不可因我给你们分为不洁净的禽兽，或是滋生在地上的活物，使自己成为可憎恶的。"},
{volumn:         3,chapter:        20,versenumber:        26,verse:"你们要归我为圣，因为我耶和华是圣的，并叫你们与万民有分别，使你们作我的民。"},
{volumn:         3,chapter:        20,versenumber:        27,verse:"“无论男女，是交鬼的或行巫术的，总要治死他们。人必用石头把他们打死，罪要归到他们身上。”"},
{volumn:         3,chapter:        21,versenumber:         1,verse:"耶和华对摩西说：“你告诉亚伦子孙作祭司的说：祭司不可为民中的死人沾染自己，"},
{volumn:         3,chapter:        21,versenumber:         2,verse:"除非为他骨肉之亲的父母、儿女、弟兄，"},
{volumn:         3,chapter:        21,versenumber:         3,verse:"和未曾出嫁、作处女的姐妹，才可以沾染自己。"},
{volumn:         3,chapter:        21,versenumber:         4,verse:"祭司既在民中为首，就不可从俗沾染自己。"},
{volumn:         3,chapter:        21,versenumber:         5,verse:"不可使头光秃；不可剃除胡须的周围，也不可用刀划身。"},
{volumn:         3,chapter:        21,versenumber:         6,verse:"要归　神为圣，不可亵渎　神的名；因为耶和华的火祭，就是　神的食物，是他们献的，所以他们要成为圣。"},
{volumn:         3,chapter:        21,versenumber:         7,verse:"不可娶妓女或被污的女人为妻，也不可娶被休的妇人为妻，因为祭司是归　神为圣。"},
{volumn:         3,chapter:        21,versenumber:         8,verse:"所以你要使他成圣，因为他奉献你　神的食物；你要以他为圣，因为我使你们成圣的耶和华是圣的。"},
{volumn:         3,chapter:        21,versenumber:         9,verse:"祭司的女儿若行淫辱没自己，就辱没了父亲，必用火将她焚烧。"},
{volumn:         3,chapter:        21,versenumber:        10,verse:"“在弟兄中作大祭司、头上倒了膏油、又承接圣职、穿了圣衣的，不可蓬头散发，也不可撕裂衣服。"},
{volumn:         3,chapter:        21,versenumber:        11,verse:"不可挨近死尸，也不可为父母沾染自己。"},
{volumn:         3,chapter:        21,versenumber:        12,verse:"不可出圣所，也不可亵渎　神的圣所，因为　神膏油的冠冕在他头上。我是耶和华。"},
{volumn:         3,chapter:        21,versenumber:        13,verse:"他要娶处女为妻。"},
{volumn:         3,chapter:        21,versenumber:        14,verse:"寡妇或是被休的妇人，或是被污为妓的女人，都不可娶；只可娶本民中的处女为妻。"},
{volumn:         3,chapter:        21,versenumber:        15,verse:"不可在民中辱没他的儿女，因为我是叫他成圣的耶和华。”"},
{volumn:         3,chapter:        21,versenumber:        16,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        21,versenumber:        17,verse:"“你告诉亚伦说：你世世代代的后裔，凡有残疾的，都不可近前来献他　神的食物。"},
{volumn:         3,chapter:        21,versenumber:        18,verse:"因为凡有残疾的，无论是瞎眼的、瘸腿的、塌鼻子的、肢体有余的、"},
{volumn:         3,chapter:        21,versenumber:        19,verse:"折脚折手的、"},
{volumn:         3,chapter:        21,versenumber:        20,verse:"驼背的、矮矬的、眼睛有毛病的、长癣的、长疥的，或是损坏肾子的，都不可近前来。"},
{volumn:         3,chapter:        21,versenumber:        21,verse:"祭司亚伦的后裔，凡有残疾的，都不可近前来，将火祭献给耶和华。他有残疾，不可近前来献　神的食物。"},
{volumn:         3,chapter:        21,versenumber:        22,verse:"神的食物，无论是圣的，至圣的，他都可以吃。"},
{volumn:         3,chapter:        21,versenumber:        23,verse:"但不可进到幔子前，也不可就近坛前；因为他有残疾，免得亵渎我的圣所。我是叫他成圣的耶和华。”"},
{volumn:         3,chapter:        21,versenumber:        24,verse:"于是，摩西晓谕亚伦和亚伦的子孙，并以色列众人。"},
{volumn:         3,chapter:        22,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        22,versenumber:         2,verse:"“你吩咐亚伦和他子孙说：要远离以色列人所分别为圣、归给我的圣物，免得亵渎我的圣名。我是耶和华。"},
{volumn:         3,chapter:        22,versenumber:         3,verse:"你要对他们说：你们世世代代的后裔，凡身上有污秽、亲近以色列人所分别为圣、归耶和华圣物的，那人必在我面前剪除。我是耶和华。"},
{volumn:         3,chapter:        22,versenumber:         4,verse:"亚伦的后裔，凡长大麻风的，或是有漏症的，不可吃圣物，直等他洁净了。无论谁摸那因死尸不洁净的物（或译：人），或是遗精的人，"},
{volumn:         3,chapter:        22,versenumber:         5,verse:"或是摸什么使他不洁净的爬物，或是摸那使他不洁净的人（不拘那人有什么不洁净），"},
{volumn:         3,chapter:        22,versenumber:         6,verse:"摸了这些人、物的，必不洁净到晚上；若不用水洗身，就不可吃圣物。"},
{volumn:         3,chapter:        22,versenumber:         7,verse:"日落的时候，他就洁净了，然后可以吃圣物，因为这是他的食物。"},
{volumn:         3,chapter:        22,versenumber:         8,verse:"自死的或是被野兽撕裂的，他不可吃，因此污秽自己。我是耶和华。"},
{volumn:         3,chapter:        22,versenumber:         9,verse:"所以他们要守我所吩咐的，免得轻忽了，因此担罪而死。我是叫他们成圣的耶和华。"},
{volumn:         3,chapter:        22,versenumber:        10,verse:"“凡外人不可吃圣物；寄居在祭司家的，或是雇工人，都不可吃圣物；"},
{volumn:         3,chapter:        22,versenumber:        11,verse:"倘若祭司买人，是他的钱买的，那人就可以吃圣物；生在他家的人也可以吃。"},
{volumn:         3,chapter:        22,versenumber:        12,verse:"祭司的女儿若嫁外人，就不可吃举祭的圣物。"},
{volumn:         3,chapter:        22,versenumber:        13,verse:"但祭司的女儿若是寡妇，或是被休的，没有孩子，又归回父家，与她青年一样，就可以吃她父亲的食物；只是外人不可吃。"},
{volumn:         3,chapter:        22,versenumber:        14,verse:"若有人误吃了圣物，要照圣物的原数加上五分之一交给祭司。"},
{volumn:         3,chapter:        22,versenumber:        15,verse:"祭司不可亵渎以色列人所献给耶和华的圣物，"},
{volumn:         3,chapter:        22,versenumber:        16,verse:"免得他们在吃圣物上自取罪孽，因为我是叫他们成圣的耶和华。”"},
{volumn:         3,chapter:        22,versenumber:        17,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        22,versenumber:        18,verse:"“你晓谕亚伦和他子孙，并以色列众人说：以色列家中的人，或在以色列中寄居的，凡献供物，无论是所许的愿，是甘心献的，就是献给耶和华作燔祭的，"},
{volumn:         3,chapter:        22,versenumber:        19,verse:"要将没有残疾的公牛，或是绵羊，或是山羊献上，如此方蒙悦纳。"},
{volumn:         3,chapter:        22,versenumber:        20,verse:"凡有残疾的，你们不可献上，因为这不蒙悦纳。"},
{volumn:         3,chapter:        22,versenumber:        21,verse:"凡从牛群或是羊群中，将平安祭献给耶和华，为要还特许的愿，或是作甘心献的，所献的必纯全无残疾的才蒙悦纳。"},
{volumn:         3,chapter:        22,versenumber:        22,verse:"瞎眼的、折伤的、残废的、有瘤子的、长癣的、长疥的都不可献给耶和华，也不可在坛上作为火祭献给耶和华。"},
{volumn:         3,chapter:        22,versenumber:        23,verse:"无论是公牛是绵羊羔，若肢体有余的，或是缺少的，只可作甘心祭献上；用以还愿，却不蒙悦纳。"},
{volumn:         3,chapter:        22,versenumber:        24,verse:"肾子损伤的，或是压碎的，或是破裂的，或是骟了的，不可献给耶和华，在你们的地上也不可这样行。"},
{volumn:         3,chapter:        22,versenumber:        25,verse:"这类的物，你们从外人的手，一样也不可接受作你们　神的食物献上；因为这些都有损坏，有残疾，不蒙悦纳。”"},
{volumn:         3,chapter:        22,versenumber:        26,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:        22,versenumber:        27,verse:"“才生的公牛，或是绵羊或是山羊，七天当跟着母；从第八天以后，可以当供物蒙悦纳，作为耶和华的火祭。"},
{volumn:         3,chapter:        22,versenumber:        28,verse:"无论是母牛是母羊，不可同日宰母和子。"},
{volumn:         3,chapter:        22,versenumber:        29,verse:"你们献感谢祭给耶和华，要献得可蒙悦纳。"},
{volumn:         3,chapter:        22,versenumber:        30,verse:"要当天吃，一点不可留到早晨。我是耶和华。"},
{volumn:         3,chapter:        22,versenumber:        31,verse:"“你们要谨守遵行我的诫命。我是耶和华。"},
{volumn:         3,chapter:        22,versenumber:        32,verse:"你们不可亵渎我的圣名；我在以色列人中，却要被尊为圣。我是叫你们成圣的耶和华，"},
{volumn:         3,chapter:        22,versenumber:        33,verse:"把你们从埃及地领出来，作你们的　神。我是耶和华。”"},
{volumn:         3,chapter:        23,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        23,versenumber:         2,verse:"“你晓谕以色列人说：耶和华的节期，你们要宣告为圣会的节期。"},
{volumn:         3,chapter:        23,versenumber:         3,verse:"六日要做工，第七日是圣安息日，当有圣会；你们什么工都不可做。这是在你们一切的住处向耶和华守的安息日。"},
{volumn:         3,chapter:        23,versenumber:         4,verse:"耶和华的节期，就是你们到了日期要宣告为圣会的，乃是这些。”"},
{volumn:         3,chapter:        23,versenumber:         5,verse:"“正月十四日，黄昏的时候，是耶和华的逾越节。"},
{volumn:         3,chapter:        23,versenumber:         6,verse:"这月十五日是向耶和华守的无酵节；你们要吃无酵饼七日。"},
{volumn:         3,chapter:        23,versenumber:         7,verse:"第一日当有圣会，什么劳碌的工都不可做；"},
{volumn:         3,chapter:        23,versenumber:         8,verse:"要将火祭献给耶和华七日。第七日是圣会，什么劳碌的工都不可做。”"},
{volumn:         3,chapter:        23,versenumber:         9,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        23,versenumber:        10,verse:"“你晓谕以色列人说：你们到了我赐给你们的地，收割庄稼的时候，要将初熟的庄稼一捆带给祭司。"},
{volumn:         3,chapter:        23,versenumber:        11,verse:"他要把这一捆在耶和华面前摇一摇，使你们得蒙悦纳。祭司要在安息日的次日把这捆摇一摇。"},
{volumn:         3,chapter:        23,versenumber:        12,verse:"摇这捆的日子，你们要把一岁、没有残疾的公绵羊羔献给耶和华为燔祭。"},
{volumn:         3,chapter:        23,versenumber:        13,verse:"同献的素祭，就是调油的细面伊法十分之二，作为馨香的火祭，献给耶和华。同献的奠祭，要酒一欣四分之一。"},
{volumn:         3,chapter:        23,versenumber:        14,verse:"无论是饼，是烘的子粒，是新穗子，你们都不可吃，直等到把你们献给　神的供物带来的那一天才可以吃。这在你们一切的住处作为世世代代永远的定例。”"},
{volumn:         3,chapter:        23,versenumber:        15,verse:"“你们要从安息日的次日，献禾捆为摇祭的那日算起，要满了七个安息日。"},
{volumn:         3,chapter:        23,versenumber:        16,verse:"到第七个安息日的次日，共计五十天，又要将新素祭献给耶和华。"},
{volumn:         3,chapter:        23,versenumber:        17,verse:"要从你们的住处取出细面伊法十分之二，加酵，烤成两个摇祭的饼，当作初熟之物献给耶和华。"},
{volumn:         3,chapter:        23,versenumber:        18,verse:"又要将一岁、没有残疾的羊羔七只、公牛犊一只、公绵羊两只，和饼一同奉上。这些与同献的素祭和奠祭要作为燔祭献给耶和华，就是作馨香的火祭献给耶和华。"},
{volumn:         3,chapter:        23,versenumber:        19,verse:"你们要献一只公山羊为赎罪祭，两只一岁的公绵羊羔为平安祭。"},
{volumn:         3,chapter:        23,versenumber:        20,verse:"祭司要把这些和初熟麦子做的饼一同作摇祭，在耶和华面前摇一摇；这是献与耶和华为圣物归给祭司的。"},
{volumn:         3,chapter:        23,versenumber:        21,verse:"当这日，你们要宣告圣会；什么劳碌的工都不可做。这在你们一切的住处作为世世代代永远的定例。"},
{volumn:         3,chapter:        23,versenumber:        22,verse:"“在你们的地收割庄稼，不可割尽田角，也不可拾取所遗落的；要留给穷人和寄居的。我是耶和华你们的　神。”"},
{volumn:         3,chapter:        23,versenumber:        23,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        23,versenumber:        24,verse:"“你晓谕以色列人说：七月初一，你们要守为圣安息日，要吹角作纪念，当有圣会。"},
{volumn:         3,chapter:        23,versenumber:        25,verse:"什么劳碌的工都不可做；要将火祭献给耶和华。”"},
{volumn:         3,chapter:        23,versenumber:        26,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:        23,versenumber:        27,verse:"“七月初十是赎罪日；你们要守为圣会，并要刻苦己心，也要将火祭献给耶和华。"},
{volumn:         3,chapter:        23,versenumber:        28,verse:"当这日，什么工都不可做；因为是赎罪日，要在耶和华你们的　神面前赎罪。"},
{volumn:         3,chapter:        23,versenumber:        29,verse:"当这日，凡不刻苦己心的，必从民中剪除。"},
{volumn:         3,chapter:        23,versenumber:        30,verse:"凡这日做什么工的，我必将他从民中除灭。"},
{volumn:         3,chapter:        23,versenumber:        31,verse:"你们什么工都不可做。这在你们一切的住处作为世世代代永远的定例。"},
{volumn:         3,chapter:        23,versenumber:        32,verse:"你们要守这日为圣安息日，并要刻苦己心。从这月初九日晚上到次日晚上，要守为安息日。”"},
{volumn:         3,chapter:        23,versenumber:        33,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        23,versenumber:        34,verse:"“你晓谕以色列人说：这七月十五日是住棚节，要在耶和华面前守这节七日。"},
{volumn:         3,chapter:        23,versenumber:        35,verse:"第一日当有圣会，什么劳碌的工都不可做。"},
{volumn:         3,chapter:        23,versenumber:        36,verse:"七日内要将火祭献给耶和华。第八日当守圣会，要将火祭献给耶和华。这是严肃会，什么劳碌的工都不可做。"},
{volumn:         3,chapter:        23,versenumber:        37,verse:"“这是耶和华的节期，就是你们要宣告为圣会的节期；要将火祭、燔祭、素祭、祭物，并奠祭，各归各日，献给耶和华。"},
{volumn:         3,chapter:        23,versenumber:        38,verse:"这是在耶和华的安息日以外，又在你们的供物和所许的愿，并甘心献给耶和华的以外。"},
{volumn:         3,chapter:        23,versenumber:        39,verse:"“你们收藏了地的出产，就从七月十五日起，要守耶和华的节七日。第一日为圣安息；第八日也为圣安息。"},
{volumn:         3,chapter:        23,versenumber:        40,verse:"第一日要拿美好树上的果子和棕树上的枝子，与茂密树的枝条并河旁的柳枝，在耶和华你们的　神面前欢乐七日。"},
{volumn:         3,chapter:        23,versenumber:        41,verse:"每年七月间，要向耶和华守这节七日。这为你们世世代代永远的定例。"},
{volumn:         3,chapter:        23,versenumber:        42,verse:"你们要住在棚里七日；凡以色列家的人都要住在棚里，"},
{volumn:         3,chapter:        23,versenumber:        43,verse:"好叫你们世世代代知道，我领以色列人出埃及地的时候曾使他们住在棚里。我是耶和华你们的　神。”"},
{volumn:         3,chapter:        23,versenumber:        44,verse:"于是，摩西将耶和华的节期传给以色列人。"},
{volumn:         3,chapter:        24,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:        24,versenumber:         2,verse:"“要吩咐以色列人，把那为点灯捣成的清橄榄油拿来给你，使灯常常点着。"},
{volumn:         3,chapter:        24,versenumber:         3,verse:"在会幕中法柜的幔子外，亚伦从晚上到早晨必在耶和华面前经理这灯。这要作你们世世代代永远的定例。"},
{volumn:         3,chapter:        24,versenumber:         4,verse:"他要在耶和华面前常收拾精金灯台上的灯。”"},
{volumn:         3,chapter:        24,versenumber:         5,verse:"“你要取细面，烤成十二个饼，每饼用面伊法十分之二。"},
{volumn:         3,chapter:        24,versenumber:         6,verse:"要把饼摆列两行（或译：摞；下同），每行六个，在耶和华面前精金的桌子上；"},
{volumn:         3,chapter:        24,versenumber:         7,verse:"又要把净乳香放在每行饼上，作为纪念，就是作为火祭献给耶和华。"},
{volumn:         3,chapter:        24,versenumber:         8,verse:"每安息日要常摆在耶和华面前；这为以色列人作永远的约。"},
{volumn:         3,chapter:        24,versenumber:         9,verse:"这饼是要给亚伦和他子孙的；他们要在圣处吃，为永远的定例，因为在献给耶和华的火祭中是至圣的。”"},
{volumn:         3,chapter:        24,versenumber:        10,verse:"有一个以色列妇人的儿子，他父亲是埃及人，一日闲游在以色列人中。这以色列妇人的儿子和一个以色列人在营里争斗。"},
{volumn:         3,chapter:        24,versenumber:        11,verse:"这以色列妇人的儿子亵渎了圣名，并且咒诅，就有人把他送到摩西那里。（他母亲名叫示罗密，是但支派底伯利的女儿。）"},
{volumn:         3,chapter:        24,versenumber:        12,verse:"他们把那人收在监里，要得耶和华所指示的话。"},
{volumn:         3,chapter:        24,versenumber:        13,verse:"耶和华晓谕摩西说："},
{volumn:         3,chapter:        24,versenumber:        14,verse:"“把那咒诅圣名的人带到营外。叫听见的人都放手在他头上；全会众就要用石头打死他。"},
{volumn:         3,chapter:        24,versenumber:        15,verse:"你要晓谕以色列人说：凡咒诅　神的，必担当他的罪。"},
{volumn:         3,chapter:        24,versenumber:        16,verse:"那亵渎耶和华名的，必被治死；全会众总要用石头打死他。不管是寄居的是本地人，他亵渎耶和华名的时候，必被治死。"},
{volumn:         3,chapter:        24,versenumber:        17,verse:"打死人的，必被治死；"},
{volumn:         3,chapter:        24,versenumber:        18,verse:"打死牲畜的，必赔上牲畜，以命偿命。"},
{volumn:         3,chapter:        24,versenumber:        19,verse:"人若使他邻舍的身体有残疾，他怎样行，也要照样向他行："},
{volumn:         3,chapter:        24,versenumber:        20,verse:"以伤还伤，以眼还眼，以牙还牙。他怎样叫人的身体有残疾，也要照样向他行。"},
{volumn:         3,chapter:        24,versenumber:        21,verse:"打死牲畜的，必赔上牲畜；打死人的，必被治死。"},
{volumn:         3,chapter:        24,versenumber:        22,verse:"不管是寄居的是本地人，同归一例。我是耶和华你们的　神。”"},
{volumn:         3,chapter:        24,versenumber:        23,verse:"于是，摩西晓谕以色列人，他们就把那咒诅圣名的人带到营外，用石头打死。以色列人就照耶和华所吩咐摩西的行了。"},
{volumn:         3,chapter:        25,versenumber:         1,verse:"耶和华在西奈山对摩西说："},
{volumn:         3,chapter:        25,versenumber:         2,verse:"“你晓谕以色列人说：你们到了我所赐你们那地的时候，地就要向耶和华守安息。"},
{volumn:         3,chapter:        25,versenumber:         3,verse:"六年要耕种田地，也要修理葡萄园，收藏地的出产。"},
{volumn:         3,chapter:        25,versenumber:         4,verse:"第七年，地要守圣安息，就是向耶和华守的安息，不可耕种田地，也不可修理葡萄园。"},
{volumn:         3,chapter:        25,versenumber:         5,verse:"遗落自长的庄稼不可收割；没有修理的葡萄树也不可摘取葡萄。这年，地要守圣安息。"},
{volumn:         3,chapter:        25,versenumber:         6,verse:"地在安息年所出的，要给你和你的仆人、婢女、雇工人，并寄居的外人当食物。"},
{volumn:         3,chapter:        25,versenumber:         7,verse:"这年的土产也要给你的牲畜和你地上的走兽当食物。”"},
{volumn:         3,chapter:        25,versenumber:         8,verse:"“你要计算七个安息年，就是七七年。这便为你成了七个安息年，共是四十九年。"},
{volumn:         3,chapter:        25,versenumber:         9,verse:"当年七月初十日，你要大发角声；这日就是赎罪日，要在遍地发出角声。"},
{volumn:         3,chapter:        25,versenumber:        10,verse:"第五十年，你们要当作圣年，在遍地给一切的居民宣告自由。这年必为你们的禧年，各人要归自己的产业，各归本家。"},
{volumn:         3,chapter:        25,versenumber:        11,verse:"第五十年要作为你们的禧年。这年不可耕种；地中自长的，不可收割；没有修理的葡萄树也不可摘取葡萄。"},
{volumn:         3,chapter:        25,versenumber:        12,verse:"因为这是禧年，你们要当作圣年，吃地中自出的土产。"},
{volumn:         3,chapter:        25,versenumber:        13,verse:"“这禧年，你们各人要归自己的地业。"},
{volumn:         3,chapter:        25,versenumber:        14,verse:"你若卖什么给邻舍，或是从邻舍的手中买什么，彼此不可亏负。"},
{volumn:         3,chapter:        25,versenumber:        15,verse:"你要按禧年以后的年数向邻舍买；他也要按年数的收成卖给你。"},
{volumn:         3,chapter:        25,versenumber:        16,verse:"年岁若多，要照数加添价值；年岁若少，要照数减去价值，因为他照收成的数目卖给你。"},
{volumn:         3,chapter:        25,versenumber:        17,verse:"你们彼此不可亏负，只要敬畏你们的　神，因为我是耶和华你们的　神。”"},
{volumn:         3,chapter:        25,versenumber:        18,verse:"“我的律例，你们要遵行，我的典章，你们要谨守，就可以在那地上安然居住。"},
{volumn:         3,chapter:        25,versenumber:        19,verse:"地必出土产，你们就要吃饱，在那地上安然居住。"},
{volumn:         3,chapter:        25,versenumber:        20,verse:"你们若说：‘这第七年我们不耕种，也不收藏土产，吃什么呢？’"},
{volumn:         3,chapter:        25,versenumber:        21,verse:"我必在第六年将我所命的福赐给你们，地便生三年的土产。"},
{volumn:         3,chapter:        25,versenumber:        22,verse:"第八年，你们要耕种，也要吃陈粮，等到第九年出产收来的时候，你们还吃陈粮。”"},
{volumn:         3,chapter:        25,versenumber:        23,verse:"“地不可永卖，因为地是我的；你们在我面前是客旅，是寄居的。"},
{volumn:         3,chapter:        25,versenumber:        24,verse:"在你们所得为业的全地，也要准人将地赎回。"},
{volumn:         3,chapter:        25,versenumber:        25,verse:"你的弟兄（弟兄是指本国人说；下同）若渐渐穷乏，卖了几分地业，他至近的亲属就要来把弟兄所卖的赎回。"},
{volumn:         3,chapter:        25,versenumber:        26,verse:"若没有能给他赎回的，他自己渐渐富足，能够赎回，"},
{volumn:         3,chapter:        25,versenumber:        27,verse:"就要算出卖地的年数，把余剩年数的价值还那买主，自己便归回自己的地业。"},
{volumn:         3,chapter:        25,versenumber:        28,verse:"倘若不能为自己得回所卖的，仍要存在买主的手里直到禧年；到了禧年，地业要出买主的手，自己便归回自己的地业。"},
{volumn:         3,chapter:        25,versenumber:        29,verse:"“人若卖城内的住宅，卖了以后，一年之内可以赎回；在一整年，必有赎回的权柄。"},
{volumn:         3,chapter:        25,versenumber:        30,verse:"若在一整年之内不赎回，这城内的房屋就定准永归买主，世世代代为业；在禧年也不得出买主的手。"},
{volumn:         3,chapter:        25,versenumber:        31,verse:"但房屋在无城墙的村庄里，要看如乡下的田地一样，可以赎回；到了禧年，都要出买主的手。"},
{volumn:         3,chapter:        25,versenumber:        32,verse:"然而利未人所得为业的城邑，其中的房屋，利未人可以随时赎回。"},
{volumn:         3,chapter:        25,versenumber:        33,verse:"若是一个利未人不将所卖的房屋赎回，是在所得为业的城内，到了禧年就要出买主的手，因为利未人城邑的房屋是他们在以色列人中的产业。"},
{volumn:         3,chapter:        25,versenumber:        34,verse:"只是他们各城郊野之地不可卖，因为是他们永远的产业。”"},
{volumn:         3,chapter:        25,versenumber:        35,verse:"“你的弟兄在你那里若渐渐贫穷，手中缺乏，你就要帮补他，使他与你同住，像外人和寄居的一样。"},
{volumn:         3,chapter:        25,versenumber:        36,verse:"不可向他取利，也不可向他多要；只要敬畏你的　神，使你的弟兄与你同住。"},
{volumn:         3,chapter:        25,versenumber:        37,verse:"你借钱给他，不可向他取利；借粮给他，也不可向他多要。"},
{volumn:         3,chapter:        25,versenumber:        38,verse:"我是耶和华你们的　神，曾领你们从埃及地出来，为要把迦南地赐给你们，要作你们的　神。”"},
{volumn:         3,chapter:        25,versenumber:        39,verse:"“你的弟兄若在你那里渐渐穷乏，将自己卖给你，不可叫他像奴仆服侍你。"},
{volumn:         3,chapter:        25,versenumber:        40,verse:"他要在你那里像雇工人和寄居的一样，要服侍你直到禧年。"},
{volumn:         3,chapter:        25,versenumber:        41,verse:"到了禧年，他和他儿女要离开你，一同出去归回本家，到他祖宗的地业那里去。"},
{volumn:         3,chapter:        25,versenumber:        42,verse:"因为他们是我的仆人，是我从埃及地领出来的，不可卖为奴仆。"},
{volumn:         3,chapter:        25,versenumber:        43,verse:"不可严严地辖管他，只要敬畏你的　神。"},
{volumn:         3,chapter:        25,versenumber:        44,verse:"至于你的奴仆、婢女，可以从你四围的国中买。"},
{volumn:         3,chapter:        25,versenumber:        45,verse:"并且那寄居在你们中间的外人和他们的家属，在你们地上所生的，你们也可以从其中买人；他们要作你们的产业。"},
{volumn:         3,chapter:        25,versenumber:        46,verse:"你们要将他们遗留给你们的子孙为产业，要永远从他们中间拣出奴仆；只是你们的弟兄以色列人，你们不可严严地辖管。"},
{volumn:         3,chapter:        25,versenumber:        47,verse:"“住在你那里的外人，或是寄居的，若渐渐富足，你的弟兄却渐渐穷乏，将自己卖给那外人，或是寄居的，或是外人的宗族，"},
{volumn:         3,chapter:        25,versenumber:        48,verse:"卖了以后，可以将他赎回。无论是他的弟兄，"},
{volumn:         3,chapter:        25,versenumber:        49,verse:"或伯叔、伯叔的儿子，本家的近支，都可以赎他。他自己若渐渐富足，也可以自赎。"},
{volumn:         3,chapter:        25,versenumber:        50,verse:"他要和买主计算，从卖自己的那年起，算到禧年；所卖的价值照着年数多少，好像工人每年的工价。"},
{volumn:         3,chapter:        25,versenumber:        51,verse:"若缺少的年数多，就要按着年数从买价中偿还他的赎价。"},
{volumn:         3,chapter:        25,versenumber:        52,verse:"若到禧年只缺少几年，就要按着年数和买主计算，偿还他的赎价。"},
{volumn:         3,chapter:        25,versenumber:        53,verse:"他和买主同住，要像每年雇的工人，买主不可严严地辖管他。"},
{volumn:         3,chapter:        25,versenumber:        54,verse:"他若不这样被赎，到了禧年，要和他的儿女一同出去。"},
{volumn:         3,chapter:        25,versenumber:        55,verse:"因为以色列人都是我的仆人，是我从埃及地领出来的。我是耶和华你们的　神。”"},
{volumn:         3,chapter:        26,versenumber:         1,verse:"“你们不可做什么虚无的神像，不可立雕刻的偶像或是柱像，也不可在你们的地上安什么錾成的石像，向它跪拜，因为我是耶和华你们的　神。"},
{volumn:         3,chapter:        26,versenumber:         2,verse:"你们要守我的安息日，敬我的圣所。我是耶和华。"},
{volumn:         3,chapter:        26,versenumber:         3,verse:"“你们若遵行我的律例，谨守我的诫命，"},
{volumn:         3,chapter:        26,versenumber:         4,verse:"我就给你们降下时雨，叫地生出土产，田野的树木结果子。"},
{volumn:         3,chapter:        26,versenumber:         5,verse:"你们打粮食要打到摘葡萄的时候，摘葡萄要摘到撒种的时候；并且要吃得饱足，在你们的地上安然居住。"},
{volumn:         3,chapter:        26,versenumber:         6,verse:"我要赐平安在你们的地上；你们躺卧，无人惊吓。我要叫恶兽从你们的地上息灭；刀剑也必不经过你们的地。"},
{volumn:         3,chapter:        26,versenumber:         7,verse:"你们要追赶仇敌，他们必倒在你们刀下。"},
{volumn:         3,chapter:        26,versenumber:         8,verse:"你们五个人要追赶一百人，一百人要追赶一万人；仇敌必倒在你们刀下。"},
{volumn:         3,chapter:        26,versenumber:         9,verse:"我要眷顾你们，使你们生养众多，也要与你们坚定所立的约。"},
{volumn:         3,chapter:        26,versenumber:        10,verse:"你们要吃陈粮，又因新粮挪开陈粮。"},
{volumn:         3,chapter:        26,versenumber:        11,verse:"我要在你们中间立我的帐幕；我的心也不厌恶你们。"},
{volumn:         3,chapter:        26,versenumber:        12,verse:"我要在你们中间行走；我要作你们的　神，你们要作我的子民。"},
{volumn:         3,chapter:        26,versenumber:        13,verse:"我是耶和华你们的　神，曾将你们从埃及地领出来，使你们不作埃及人的奴仆；我也折断你们所负的轭，叫你们挺身而走。”"},
{volumn:         3,chapter:        26,versenumber:        14,verse:"“你们若不听从我，不遵行我的诫命，"},
{volumn:         3,chapter:        26,versenumber:        15,verse:"厌弃我的律例，厌恶我的典章，不遵行我一切的诫命，背弃我的约，"},
{volumn:         3,chapter:        26,versenumber:        16,verse:"我待你们就要这样：我必命定惊惶，叫眼目干瘪、精神消耗的痨病热病辖制你们。你们也要白白地撒种，因为仇敌要吃你们所种的。"},
{volumn:         3,chapter:        26,versenumber:        17,verse:"我要向你们变脸，你们就要败在仇敌面前。恨恶你们的，必辖管你们；无人追赶，你们却要逃跑。"},
{volumn:         3,chapter:        26,versenumber:        18,verse:"你们因这些事若还不听从我，我就要为你们的罪加七倍惩罚你们。"},
{volumn:         3,chapter:        26,versenumber:        19,verse:"我必断绝你们因势力而有的骄傲，又要使覆你们的天如铁，载你们的地如铜。"},
{volumn:         3,chapter:        26,versenumber:        20,verse:"你们要白白地劳力；因为你们的地不出土产，其上的树木也不结果子。"},
{volumn:         3,chapter:        26,versenumber:        21,verse:"“你们行事若与我反对，不肯听从我，我就要按你们的罪加七倍降灾与你们。"},
{volumn:         3,chapter:        26,versenumber:        22,verse:"我也要打发野地的走兽到你们中间，抢吃你们的儿女，吞灭你们的牲畜，使你们的人数减少，道路荒凉。"},
{volumn:         3,chapter:        26,versenumber:        23,verse:"“你们因这些事若仍不改正归我，行事与我反对，"},
{volumn:         3,chapter:        26,versenumber:        24,verse:"我就要行事与你们反对，因你们的罪击打你们七次。"},
{volumn:         3,chapter:        26,versenumber:        25,verse:"我又要使刀剑临到你们，报复你们背约的仇；聚集你们在各城内，降瘟疫在你们中间，也必将你们交在仇敌的手中。"},
{volumn:         3,chapter:        26,versenumber:        26,verse:"我要折断你们的杖，就是断绝你们的粮。那时，必有十个女人在一个炉子给你们烤饼，按分量秤给你们；你们要吃，也吃不饱。"},
{volumn:         3,chapter:        26,versenumber:        27,verse:"“你们因这一切的事若不听从我，却行事与我反对，"},
{volumn:         3,chapter:        26,versenumber:        28,verse:"我就要发烈怒，行事与你们反对，又因你们的罪惩罚你们七次。"},
{volumn:         3,chapter:        26,versenumber:        29,verse:"并且你们要吃儿子的肉，也要吃女儿的肉。"},
{volumn:         3,chapter:        26,versenumber:        30,verse:"我又要毁坏你们的邱坛，砍下你们的日像，把你们的尸首扔在你们偶像的身上；我的心也必厌恶你们。"},
{volumn:         3,chapter:        26,versenumber:        31,verse:"我要使你们的城邑变为荒凉，使你们的众圣所成为荒场；我也不闻你们馨香的香气。"},
{volumn:         3,chapter:        26,versenumber:        32,verse:"我要使地成为荒场，住在其上的仇敌就因此诧异。"},
{volumn:         3,chapter:        26,versenumber:        33,verse:"我要把你们散在列邦中；我也要拔刀追赶你们。你们的地要成为荒场；你们的城邑要变为荒凉。"},
{volumn:         3,chapter:        26,versenumber:        34,verse:"“你们在仇敌之地居住的时候，你们的地荒凉，要享受众安息；正在那时候，地要歇息，享受安息。"},
{volumn:         3,chapter:        26,versenumber:        35,verse:"地多时为荒场，就要多时歇息；地这样歇息，是你们住在其上的安息年所不能得的。"},
{volumn:         3,chapter:        26,versenumber:        36,verse:"至于你们剩下的人，我要使他们在仇敌之地心惊胆怯。叶子被风吹的响声，要追赶他们；他们要逃避，像人逃避刀剑，无人追赶，却要跌倒。"},
{volumn:         3,chapter:        26,versenumber:        37,verse:"无人追赶，他们要彼此撞跌，像在刀剑之前。你们在仇敌面前也必站立不住。"},
{volumn:         3,chapter:        26,versenumber:        38,verse:"你们要在列邦中灭亡；仇敌之地要吞吃你们。"},
{volumn:         3,chapter:        26,versenumber:        39,verse:"你们剩下的人必因自己的罪孽和祖宗的罪孽在仇敌之地消灭。"},
{volumn:         3,chapter:        26,versenumber:        40,verse:"“他们要承认自己的罪和他们祖宗的罪，就是干犯我的那罪，并且承认自己行事与我反对，"},
{volumn:         3,chapter:        26,versenumber:        41,verse:"我所以行事与他们反对，把他们带到仇敌之地。那时，他们未受割礼的心若谦卑了，他们也服了罪孽的刑罚，"},
{volumn:         3,chapter:        26,versenumber:        42,verse:"我就要记念我与雅各所立的约，与以撒所立的约，与亚伯拉罕所立的约，并要记念这地。"},
{volumn:         3,chapter:        26,versenumber:        43,verse:"他们离开这地，地在荒废无人的时候就要享受安息。并且他们要服罪孽的刑罚；因为他们厌弃了我的典章，心中厌恶了我的律例。"},
{volumn:         3,chapter:        26,versenumber:        44,verse:"虽是这样，他们在仇敌之地，我却不厌弃他们，也不厌恶他们，将他们尽行灭绝，也不背弃我与他们所立的约，因为我是耶和华他们的　神。"},
{volumn:         3,chapter:        26,versenumber:        45,verse:"我却要为他们的缘故记念我与他们先祖所立的约。他们的先祖是我在列邦人眼前、从埃及地领出来的，为要作他们的　神。我是耶和华。”"},
{volumn:         3,chapter:        26,versenumber:        46,verse:"这些律例、典章，和法度是耶和华与以色列人在西奈山藉着摩西立的。"},
{volumn:         3,chapter:        27,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         3,chapter:        27,versenumber:         2,verse:"“你晓谕以色列人说：人还特许的愿，被许的人要按你所估的价值归给耶和华。"},
{volumn:         3,chapter:        27,versenumber:         3,verse:"你估定的，从二十岁到六十岁的男人，要按圣所的平，估定价银五十舍客勒。"},
{volumn:         3,chapter:        27,versenumber:         4,verse:"若是女人，你要估定三十舍客勒。"},
{volumn:         3,chapter:        27,versenumber:         5,verse:"若是从五岁到二十岁，男子你要估定二十舍客勒，女子估定十舍客勒。"},
{volumn:         3,chapter:        27,versenumber:         6,verse:"若是从一月到五岁，男子你要估定五舍客勒，女子估定三舍客勒。"},
{volumn:         3,chapter:        27,versenumber:         7,verse:"若是从六十岁以上，男人你要估定十五舍客勒，女人估定十舍客勒。"},
{volumn:         3,chapter:        27,versenumber:         8,verse:"他若贫穷，不能照你所估定的价，就要把他带到祭司面前，祭司要按许愿人的力量估定他的价。"},
{volumn:         3,chapter:        27,versenumber:         9,verse:"“所许的若是牲畜，就是人献给耶和华为供物的，凡这一类献给耶和华的，都要成为圣。"},
{volumn:         3,chapter:        27,versenumber:        10,verse:"人不可改换，也不可更换，或是好的换坏的，或是坏的换好的。若以牲畜更换牲畜，所许的与所换的都要成为圣。"},
{volumn:         3,chapter:        27,versenumber:        11,verse:"若牲畜不洁净，是不可献给耶和华为供物的，就要把牲畜安置在祭司面前。"},
{volumn:         3,chapter:        27,versenumber:        12,verse:"祭司就要估定价值；牲畜是好是坏，祭司怎样估定，就要以怎样为是。"},
{volumn:         3,chapter:        27,versenumber:        13,verse:"他若一定要赎回，就要在你所估定的价值以外加上五分之一。"},
{volumn:         3,chapter:        27,versenumber:        14,verse:"“人将房屋分别为圣，归给耶和华，祭司就要估定价值。房屋是好是坏，祭司怎样估定，就要以怎样为定。"},
{volumn:         3,chapter:        27,versenumber:        15,verse:"将房屋分别为圣的人，若要赎回房屋，就必在你所估定的价值以外加上五分之一，房屋仍旧归他。"},
{volumn:         3,chapter:        27,versenumber:        16,verse:"“人若将承受为业的几分地分别为圣，归给耶和华，你要按这地撒种多少估定价值，若撒大麦一贺梅珥，要估价五十舍客勒。"},
{volumn:         3,chapter:        27,versenumber:        17,verse:"他若从禧年将地分别为圣，就要以你所估定的价为定。"},
{volumn:         3,chapter:        27,versenumber:        18,verse:"倘若他在禧年以后将地分别为圣，祭司就要按着未到禧年所剩的年数推算价值，也要从你所估的减去价值。"},
{volumn:         3,chapter:        27,versenumber:        19,verse:"将地分别为圣的人若定要把地赎回，他便要在你所估的价值以外加上五分之一，地就准定归他。"},
{volumn:         3,chapter:        27,versenumber:        20,verse:"他若不赎回那地，或是将地卖给别人，就再不能赎了。"},
{volumn:         3,chapter:        27,versenumber:        21,verse:"但到了禧年，那地从买主手下出来的时候，就要归耶和华为圣，和永献的地一样，要归祭司为业。"},
{volumn:         3,chapter:        27,versenumber:        22,verse:"他若将所买的一块地，不是承受为业的，分别为圣归给耶和华，"},
{volumn:         3,chapter:        27,versenumber:        23,verse:"祭司就要将你所估的价值给他推算到禧年。当日，他要以你所估的价银为圣，归给耶和华。"},
{volumn:         3,chapter:        27,versenumber:        24,verse:"到了禧年，那地要归卖主，就是那承受为业的原主。"},
{volumn:         3,chapter:        27,versenumber:        25,verse:"凡你所估定的价银都要按着圣所的平：二十季拉为一舍客勒。"},
{volumn:         3,chapter:        27,versenumber:        26,verse:"“惟独牲畜中头生的，无论是牛是羊，既归耶和华，谁也不可再分别为圣，因为这是耶和华的。"},
{volumn:         3,chapter:        27,versenumber:        27,verse:"若是不洁净的牲畜生的，就要按你所估定的价值加上五分之一赎回；若不赎回，就要按你所估定的价值卖了。"},
{volumn:         3,chapter:        27,versenumber:        28,verse:"“但一切永献的，就是人从他所有永献给耶和华的，无论是人，是牲畜，是他承受为业的地，都不可卖，也不可赎。凡永献的是归给耶和华为至圣。"},
{volumn:         3,chapter:        27,versenumber:        29,verse:"凡从人中当灭的都不可赎，必被治死。”"},
{volumn:         3,chapter:        27,versenumber:        30,verse:"“地上所有的，无论是地上的种子是树上的果子，十分之一是耶和华的，是归给耶和华为圣的。"},
{volumn:         3,chapter:        27,versenumber:        31,verse:"人若要赎这十分之一的什么物，就要加上五分之一。"},
{volumn:         3,chapter:        27,versenumber:        32,verse:"凡牛群羊群中，一切从杖下经过的，每第十只要归给耶和华为圣。"},
{volumn:         3,chapter:        27,versenumber:        33,verse:"不可问是好是坏，也不可更换；若定要更换，所更换的与本来的牲畜都要成为圣，不可赎回。”"},
{volumn:         3,chapter:        27,versenumber:        34,verse:"这就是耶和华在西奈山为以色列人所吩咐摩西的命令。"},
{volumn:         4,chapter:         1,versenumber:         1,verse:"以色列人出埃及地后，第二年二月初一日，耶和华在西奈的旷野、会幕中晓谕摩西说："},
{volumn:         4,chapter:         1,versenumber:         2,verse:"“你要按以色列全会众的家室、宗族、人名的数目计算所有的男丁。"},
{volumn:         4,chapter:         1,versenumber:         3,verse:"凡以色列中，从二十岁以外，能出去打仗的，你和亚伦要照他们的军队数点。"},
{volumn:         4,chapter:         1,versenumber:         4,verse:"每支派中必有一人作本支派的族长，帮助你们。"},
{volumn:         4,chapter:         1,versenumber:         5,verse:"他们的名字：属流便的，有示丢珥的儿子以利蓿；"},
{volumn:         4,chapter:         1,versenumber:         6,verse:"属西缅的，有苏利沙代的儿子示路蔑；"},
{volumn:         4,chapter:         1,versenumber:         7,verse:"属犹大的，有亚米拿达的儿子拿顺；"},
{volumn:         4,chapter:         1,versenumber:         8,verse:"属以萨迦的，有苏押的儿子拿坦业；"},
{volumn:         4,chapter:         1,versenumber:         9,verse:"属西布伦的，有希伦的儿子以利押；"},
{volumn:         4,chapter:         1,versenumber:        10,verse:"约瑟子孙、属以法莲的，有亚米忽的儿子以利沙玛；属玛拿西的，有比大蓿的儿子迦玛列；"},
{volumn:         4,chapter:         1,versenumber:        11,verse:"属便雅悯的，有基多尼的儿子亚比但；"},
{volumn:         4,chapter:         1,versenumber:        12,verse:"属但的，有亚米沙代的儿子亚希以谢；"},
{volumn:         4,chapter:         1,versenumber:        13,verse:"属亚设的，有俄兰的儿子帕结；"},
{volumn:         4,chapter:         1,versenumber:        14,verse:"属迦得的，有丢珥的儿子以利雅萨；"},
{volumn:         4,chapter:         1,versenumber:        15,verse:"属拿弗他利的，有以南的儿子亚希拉。"},
{volumn:         4,chapter:         1,versenumber:        16,verse:"这都是从会中选召的，各作本支派的首领，都是以色列军中的统领。”"},
{volumn:         4,chapter:         1,versenumber:        17,verse:"于是，摩西、亚伦带着这些按名指定的人，"},
{volumn:         4,chapter:         1,versenumber:        18,verse:"当二月初一日招聚全会众。会众就照他们的家室、宗族、人名的数目，从二十岁以外的，都述说自己的家谱。"},
{volumn:         4,chapter:         1,versenumber:        19,verse:"耶和华怎样吩咐摩西，他就怎样在西奈的旷野数点他们。"},
{volumn:         4,chapter:         1,versenumber:        20,verse:"以色列的长子，流便子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的男丁，共有四万六千五百名。"},
{volumn:         4,chapter:         1,versenumber:        21,verse:""},
{volumn:         4,chapter:         1,versenumber:        22,verse:"西缅子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的男丁，共有五万九千三百名。"},
{volumn:         4,chapter:         1,versenumber:        23,verse:""},
{volumn:         4,chapter:         1,versenumber:        24,verse:"迦得子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有四万五千六百五十名。"},
{volumn:         4,chapter:         1,versenumber:        25,verse:""},
{volumn:         4,chapter:         1,versenumber:        26,verse:"犹大子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有七万四千六百名。"},
{volumn:         4,chapter:         1,versenumber:        27,verse:""},
{volumn:         4,chapter:         1,versenumber:        28,verse:"以萨迦子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有五万四千四百名。"},
{volumn:         4,chapter:         1,versenumber:        29,verse:""},
{volumn:         4,chapter:         1,versenumber:        30,verse:"西布伦子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有五万七千四百名。"},
{volumn:         4,chapter:         1,versenumber:        31,verse:""},
{volumn:         4,chapter:         1,versenumber:        32,verse:"约瑟子孙属以法莲子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有四万零五百名。"},
{volumn:         4,chapter:         1,versenumber:        33,verse:""},
{volumn:         4,chapter:         1,versenumber:        34,verse:"玛拿西子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有三万二千二百名。"},
{volumn:         4,chapter:         1,versenumber:        35,verse:""},
{volumn:         4,chapter:         1,versenumber:        36,verse:"便雅悯子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有三万五千四百名。"},
{volumn:         4,chapter:         1,versenumber:        37,verse:""},
{volumn:         4,chapter:         1,versenumber:        38,verse:"但子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有六万二千七百名。"},
{volumn:         4,chapter:         1,versenumber:        39,verse:""},
{volumn:         4,chapter:         1,versenumber:        40,verse:"亚设子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有四万一千五百名。"},
{volumn:         4,chapter:         1,versenumber:        41,verse:""},
{volumn:         4,chapter:         1,versenumber:        42,verse:"拿弗他利子孙的后代，照着家室、宗族、人名的数目，从二十岁以外，凡能出去打仗、被数的，共有五万三千四百名。"},
{volumn:         4,chapter:         1,versenumber:        43,verse:""},
{volumn:         4,chapter:         1,versenumber:        44,verse:"这些就是被数点的，是摩西、亚伦，和以色列中十二个首领所数点的；这十二个人各作各宗族的代表。"},
{volumn:         4,chapter:         1,versenumber:        45,verse:"这样，凡以色列人中被数的，照着宗族，从二十岁以外，能出去打仗、被数的，共有六十万零三千五百五十名。"},
{volumn:         4,chapter:         1,versenumber:        46,verse:""},
{volumn:         4,chapter:         1,versenumber:        47,verse:"利未人却没有按着支派数在其中，"},
{volumn:         4,chapter:         1,versenumber:        48,verse:"因为耶和华晓谕摩西说："},
{volumn:         4,chapter:         1,versenumber:        49,verse:"“惟独利未支派你不可数点，也不可在以色列人中计算他们的总数。"},
{volumn:         4,chapter:         1,versenumber:        50,verse:"只要派利未人管法柜的帐幕和其中的器具，并属乎帐幕的；他们要抬（或译：搬运）帐幕和其中的器具，并要办理帐幕的事，在帐幕的四围安营。"},
{volumn:         4,chapter:         1,versenumber:        51,verse:"帐幕将往前行的时候，利未人要拆卸；将支搭的时候，利未人要竖起。近前来的外人必被治死。"},
{volumn:         4,chapter:         1,versenumber:        52,verse:"以色列人支搭帐棚，要照他们的军队，各归本营，各归本纛。"},
{volumn:         4,chapter:         1,versenumber:        53,verse:"但利未人要在法柜帐幕的四围安营，免得忿怒临到以色列会众；利未人并要谨守法柜的帐幕。”"},
{volumn:         4,chapter:         1,versenumber:        54,verse:"以色列人就这样行。凡耶和华所吩咐摩西的，他们就照样行了。"},
{volumn:         4,chapter:         2,versenumber:         1,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         4,chapter:         2,versenumber:         2,verse:"“以色列人要各归自己的纛下，在本族的旗号那里，对着会幕的四围安营。"},
{volumn:         4,chapter:         2,versenumber:         3,verse:"在东边，向日出之地，照着军队安营的是犹大营的纛。有亚米拿达的儿子拿顺作犹大人的首领。"},
{volumn:         4,chapter:         2,versenumber:         4,verse:"他军队被数的，共有七万四千六百名。"},
{volumn:         4,chapter:         2,versenumber:         5,verse:"挨着他安营的是以萨迦支派。有苏押的儿子拿坦业作以萨迦人的首领。"},
{volumn:         4,chapter:         2,versenumber:         6,verse:"他军队被数的，共有五万四千四百名。"},
{volumn:         4,chapter:         2,versenumber:         7,verse:"又有西布伦支派。希伦的儿子以利押作西布伦人的首领。"},
{volumn:         4,chapter:         2,versenumber:         8,verse:"他军队被数的，共有五万七千四百名。"},
{volumn:         4,chapter:         2,versenumber:         9,verse:"凡属犹大营、按着军队被数的，共有十八万六千四百名，要作第一队往前行。"},
{volumn:         4,chapter:         2,versenumber:        10,verse:"“在南边，按着军队是流便营的纛。有示丢珥的儿子以利蓿作流便人的首领。"},
{volumn:         4,chapter:         2,versenumber:        11,verse:"他军队被数的，共有四万六千五百名。"},
{volumn:         4,chapter:         2,versenumber:        12,verse:"挨着他安营的是西缅支派。苏利沙代的儿子示路蔑作西缅人的首领。"},
{volumn:         4,chapter:         2,versenumber:        13,verse:"他军队被数的，共有五万九千三百名。"},
{volumn:         4,chapter:         2,versenumber:        14,verse:"又有迦得支派。丢珥的儿子以利雅萨作迦得人的首领。"},
{volumn:         4,chapter:         2,versenumber:        15,verse:"他军队被数的，共有四万五千六百五十名，"},
{volumn:         4,chapter:         2,versenumber:        16,verse:"凡属流便营、按着军队被数的，共有十五万一千四百五十名，要作第二队往前行。"},
{volumn:         4,chapter:         2,versenumber:        17,verse:"“随后，会幕要往前行，有利未营在诸营中间。他们怎样安营就怎样往前行，各按本位，各归本纛。"},
{volumn:         4,chapter:         2,versenumber:        18,verse:"“在西边，按着军队是以法莲营的纛。亚米忽的儿子以利沙玛作以法莲人的首领。"},
{volumn:         4,chapter:         2,versenumber:        19,verse:"他军队被数的，共有四万零五百名。"},
{volumn:         4,chapter:         2,versenumber:        20,verse:"挨着他的是玛拿西支派。比大蓿的儿子迦玛列作玛拿西人的首领。"},
{volumn:         4,chapter:         2,versenumber:        21,verse:"他军队被数的，共有三万二千二百名。"},
{volumn:         4,chapter:         2,versenumber:        22,verse:"又有便雅悯支派。基多尼的儿子亚比但作便雅悯人的首领。"},
{volumn:         4,chapter:         2,versenumber:        23,verse:"他军队被数的，共有三万五千四百名。"},
{volumn:         4,chapter:         2,versenumber:        24,verse:"凡属以法莲营、按着军队被数的，共有十万零八千一百名，要作第三队往前行。"},
{volumn:         4,chapter:         2,versenumber:        25,verse:"“在北边，按着军队是但营的纛。亚米沙代的儿子亚希以谢作但人的首领。"},
{volumn:         4,chapter:         2,versenumber:        26,verse:"他军队被数的，共有六万二千七百名。"},
{volumn:         4,chapter:         2,versenumber:        27,verse:"挨着他安营的是亚设支派。俄兰的儿子帕结作亚设人的首领。"},
{volumn:         4,chapter:         2,versenumber:        28,verse:"他军队被数的，共有四万一千五百名。"},
{volumn:         4,chapter:         2,versenumber:        29,verse:"又有拿弗他利支派。以南的儿子亚希拉作拿弗他利人的首领。"},
{volumn:         4,chapter:         2,versenumber:        30,verse:"他军队被数的，共有五万三千四百名。"},
{volumn:         4,chapter:         2,versenumber:        31,verse:"凡但营被数的，共有十五万七千六百名，要归本纛作末队往前行。”"},
{volumn:         4,chapter:         2,versenumber:        32,verse:"这些以色列人，照他们的宗族，按他们的军队，在诸营中被数的，共有六十万零三千五百五十名。"},
{volumn:         4,chapter:         2,versenumber:        33,verse:"惟独利未人没有数在以色列人中，是照耶和华所吩咐摩西的。"},
{volumn:         4,chapter:         2,versenumber:        34,verse:"以色列人就这样行，各人照他们的家室、宗族归于本纛，安营起行，都是照耶和华所吩咐摩西的。"},
{volumn:         4,chapter:         3,versenumber:         1,verse:"耶和华在西奈山晓谕摩西的日子，亚伦和摩西的后代如下："},
{volumn:         4,chapter:         3,versenumber:         2,verse:"亚伦的儿子，长子名叫拿答，还有亚比户、以利亚撒、以他玛。"},
{volumn:         4,chapter:         3,versenumber:         3,verse:"这是亚伦儿子的名字，都是受膏的祭司，是摩西叫他们承接圣职供祭司职分的。"},
{volumn:         4,chapter:         3,versenumber:         4,verse:"拿答、亚比户在西奈的旷野向耶和华献凡火的时候就死在耶和华面前了。他们也没有儿子。以利亚撒、以他玛在他们的父亲亚伦面前供祭司的职分。"},
{volumn:         4,chapter:         3,versenumber:         5,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         3,versenumber:         6,verse:"“你使利未支派近前来，站在祭司亚伦面前好服侍他，"},
{volumn:         4,chapter:         3,versenumber:         7,verse:"替他和会众在会幕前守所吩咐的，办理帐幕的事。"},
{volumn:         4,chapter:         3,versenumber:         8,verse:"又要看守会幕的器具，并守所吩咐以色列人的，办理帐幕的事。"},
{volumn:         4,chapter:         3,versenumber:         9,verse:"你要将利未人给亚伦和他的儿子，因为他们是从以色列人中选出来给他的。"},
{volumn:         4,chapter:         3,versenumber:        10,verse:"你要嘱咐亚伦和他的儿子谨守自己祭司的职任。近前来的外人必被治死。”"},
{volumn:         4,chapter:         3,versenumber:        11,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         3,versenumber:        12,verse:"“我从以色列人中拣选了利未人，代替以色列人一切头生的；利未人要归我。"},
{volumn:         4,chapter:         3,versenumber:        13,verse:"因为凡头生的是我的；我在埃及地击杀一切头生的那日就把以色列中一切头生的，连人带牲畜都分别为圣归我；他们定要属我。我是耶和华。”"},
{volumn:         4,chapter:         3,versenumber:        14,verse:"耶和华在西奈的旷野晓谕摩西说："},
{volumn:         4,chapter:         3,versenumber:        15,verse:"“你要照利未人的宗族、家室数点他们。凡一个月以外的男子都要数点。”"},
{volumn:         4,chapter:         3,versenumber:        16,verse:"于是摩西照耶和华所吩咐的数点他们。"},
{volumn:         4,chapter:         3,versenumber:        17,verse:"利未众子的名字是革顺、哥辖、米拉利。"},
{volumn:         4,chapter:         3,versenumber:        18,verse:"革顺的儿子，按着家室，是立尼、示每。"},
{volumn:         4,chapter:         3,versenumber:        19,verse:"哥辖的儿子，按着家室，是暗兰、以斯哈、希伯伦、乌薛。"},
{volumn:         4,chapter:         3,versenumber:        20,verse:"米拉利的儿子，按着家室，是抹利、母示。这些按着宗族是利未人的家室。"},
{volumn:         4,chapter:         3,versenumber:        21,verse:"属革顺的，有立尼族、示每族。这是革顺的二族。"},
{volumn:         4,chapter:         3,versenumber:        22,verse:"其中被数、从一个月以外所有的男子共有七千五百名。"},
{volumn:         4,chapter:         3,versenumber:        23,verse:"这革顺的二族要在帐幕后西边安营。"},
{volumn:         4,chapter:         3,versenumber:        24,verse:"拉伊勒的儿子以利雅萨作革顺人宗族的首领。"},
{volumn:         4,chapter:         3,versenumber:        25,verse:"革顺的子孙在会幕中所要看守的，就是帐幕和罩棚，并罩棚的盖与会幕的门帘，"},
{volumn:         4,chapter:         3,versenumber:        26,verse:"院子的帷子和门帘（院子是围帐幕和坛的），并一切使用的绳子。"},
{volumn:         4,chapter:         3,versenumber:        27,verse:"属哥辖的，有暗兰族、以斯哈族、希伯伦族、乌薛族。这是哥辖的诸族。"},
{volumn:         4,chapter:         3,versenumber:        28,verse:"按所有男子的数目，从一个月以外看守圣所的，共有八千六百名。"},
{volumn:         4,chapter:         3,versenumber:        29,verse:"哥辖儿子的诸族要在帐幕的南边安营。"},
{volumn:         4,chapter:         3,versenumber:        30,verse:"乌薛的儿子以利撒反作哥辖宗族家室的首领。"},
{volumn:         4,chapter:         3,versenumber:        31,verse:"他们所要看守的是约柜、桌子、灯台、两座坛与圣所内使用的器皿，并帘子和一切使用之物。"},
{volumn:         4,chapter:         3,versenumber:        32,verse:"祭司亚伦的儿子以利亚撒作利未人众首领的领袖，要监察那些看守圣所的人。"},
{volumn:         4,chapter:         3,versenumber:        33,verse:"属米拉利的，有抹利族、母示族。这是米拉利的二族。"},
{volumn:         4,chapter:         3,versenumber:        34,verse:"他们被数的，按所有男子的数目，从一个月以外的，共有六千二百名。"},
{volumn:         4,chapter:         3,versenumber:        35,verse:"亚比亥的儿子苏列作米拉利二宗族的首领。他们要在帐幕的北边安营。"},
{volumn:         4,chapter:         3,versenumber:        36,verse:"米拉利子孙的职分是看守帐幕的板、闩、柱子、带卯的座，和帐幕一切所使用的器具，"},
{volumn:         4,chapter:         3,versenumber:        37,verse:"院子四围的柱子、带卯的座、橛子，和绳子。"},
{volumn:         4,chapter:         3,versenumber:        38,verse:"在帐幕前东边，向日出之地安营的是摩西、亚伦，和亚伦的儿子。他们看守圣所，替以色列人守耶和华所吩咐的。近前来的外人必被治死。"},
{volumn:         4,chapter:         3,versenumber:        39,verse:"凡被数的利未人，就是摩西、亚伦照耶和华吩咐所数的，按着家室，从一个月以外的男子，共有二万二千名。"},
{volumn:         4,chapter:         3,versenumber:        40,verse:"耶和华对摩西说：“你要从以色列人中数点一个月以外、凡头生的男子，把他们的名字记下。"},
{volumn:         4,chapter:         3,versenumber:        41,verse:"我是耶和华。你要拣选利未人归我，代替以色列人所有头生的，也取利未人的牲畜代替以色列所有头生的牲畜。”"},
{volumn:         4,chapter:         3,versenumber:        42,verse:"摩西就照耶和华所吩咐的把以色列人头生的都数点了。"},
{volumn:         4,chapter:         3,versenumber:        43,verse:"按人名的数目，从一个月以外、凡头生的男子，共有二万二千二百七十三名。"},
{volumn:         4,chapter:         3,versenumber:        44,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         3,versenumber:        45,verse:"“你拣选利未人代替以色列人所有头生的，也取利未人的牲畜代替以色列人的牲畜。利未人要归我；我是耶和华。"},
{volumn:         4,chapter:         3,versenumber:        46,verse:"以色列人中头生的男子比利未人多二百七十三个，必当将他们赎出来。"},
{volumn:         4,chapter:         3,versenumber:        47,verse:"你要按人丁，照圣所的平，每人取赎银五舍客勒（一舍客勒是二十季拉），"},
{volumn:         4,chapter:         3,versenumber:        48,verse:"把那多余之人的赎银交给亚伦和他的儿子。”"},
{volumn:         4,chapter:         3,versenumber:        49,verse:"于是摩西从那被利未人所赎以外的人取了赎银。"},
{volumn:         4,chapter:         3,versenumber:        50,verse:"从以色列人头生的所取之银，按圣所的平，有一千三百六十五舍客勒。"},
{volumn:         4,chapter:         3,versenumber:        51,verse:"摩西照耶和华的话把这赎银给亚伦和他的儿子，正如耶和华所吩咐的。"},
{volumn:         4,chapter:         4,versenumber:         1,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         4,chapter:         4,versenumber:         2,verse:"“你从利未人中，将哥辖子孙的总数，照他们的家室、宗族，"},
{volumn:         4,chapter:         4,versenumber:         3,verse:"从三十岁直到五十岁，凡前来任职、在会幕里办事的，全都计算。"},
{volumn:         4,chapter:         4,versenumber:         4,verse:"哥辖子孙在会幕搬运至圣之物，所办的事乃是这样："},
{volumn:         4,chapter:         4,versenumber:         5,verse:"起营的时候，亚伦和他儿子要进去摘下遮掩柜的幔子，用以蒙盖法柜，"},
{volumn:         4,chapter:         4,versenumber:         6,verse:"又用海狗皮盖在上头，再蒙上纯蓝色的毯子，把杠穿上。"},
{volumn:         4,chapter:         4,versenumber:         7,verse:"又用蓝色毯子铺在陈设饼的桌子上，将盘子、调羹、奠酒的爵，和杯摆在上头。桌子上也必有常设的饼。"},
{volumn:         4,chapter:         4,versenumber:         8,verse:"在其上又要蒙朱红色的毯子，再蒙上海狗皮，把杠穿上。"},
{volumn:         4,chapter:         4,versenumber:         9,verse:"要拿蓝色毯子，把灯台和灯台上所用的灯盏、剪子、蜡花盘，并一切盛油的器皿，全都遮盖。"},
{volumn:         4,chapter:         4,versenumber:        10,verse:"又要把灯台和灯台的一切器具包在海狗皮里，放在抬架上。"},
{volumn:         4,chapter:         4,versenumber:        11,verse:"在金坛上要铺蓝色毯子，蒙上海狗皮，把杠穿上。"},
{volumn:         4,chapter:         4,versenumber:        12,verse:"又要把圣所用的一切器具包在蓝色毯子里，用海狗皮蒙上，放在抬架上。"},
{volumn:         4,chapter:         4,versenumber:        13,verse:"要收去坛上的灰，把紫色毯子铺在坛上；"},
{volumn:         4,chapter:         4,versenumber:        14,verse:"又要把所用的一切器具，就是火鼎、肉叉子、铲子、盘子，一切属坛的器具都摆在坛上，又蒙上海狗皮，把杠穿上。"},
{volumn:         4,chapter:         4,versenumber:        15,verse:"将要起营的时候，亚伦和他儿子把圣所和圣所的一切器具遮盖完了，哥辖的子孙就要来抬，只是不可摸圣物，免得他们死亡。会幕里这些物件是哥辖子孙所当抬的。"},
{volumn:         4,chapter:         4,versenumber:        16,verse:"“祭司亚伦的儿子以利亚撒所要看守的是点灯的油与香料，并当献的素祭和膏油，也要看守全帐幕与其中所有的，并圣所和圣所的器具。”"},
{volumn:         4,chapter:         4,versenumber:        17,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         4,chapter:         4,versenumber:        18,verse:"“你们不可将哥辖人的支派从利未人中剪除。"},
{volumn:         4,chapter:         4,versenumber:        19,verse:"他们挨近至圣物的时候，亚伦和他儿子要进去派他们各人所当办的，所当抬的。这样待他们，好使他们活着，不致死亡。"},
{volumn:         4,chapter:         4,versenumber:        20,verse:"只是他们连片时不可进去观看圣所，免得他们死亡。”"},
{volumn:         4,chapter:         4,versenumber:        21,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         4,versenumber:        22,verse:"“你要将革顺子孙的总数，照着宗族、家室，"},
{volumn:         4,chapter:         4,versenumber:        23,verse:"从三十岁直到五十岁，凡前来任职、在会幕里办事的，全都数点。"},
{volumn:         4,chapter:         4,versenumber:        24,verse:"革顺人各族所办的事、所抬的物乃是这样："},
{volumn:         4,chapter:         4,versenumber:        25,verse:"他们要抬帐幕的幔子和会幕，并会幕的盖与其上的海狗皮，和会幕的门帘，"},
{volumn:         4,chapter:         4,versenumber:        26,verse:"院子的帷子和门帘（院子是围帐幕和坛的）、绳子，并所用的器具，不论是做什么用的，他们都要经理。"},
{volumn:         4,chapter:         4,versenumber:        27,verse:"革顺的子孙在一切抬物办事之上都要凭亚伦和他儿子的吩咐；他们所当抬的，要派他们看守。"},
{volumn:         4,chapter:         4,versenumber:        28,verse:"这是革顺子孙的各族在会幕里所办的事；他们所看守的，必在祭司亚伦儿子以他玛的手下。”"},
{volumn:         4,chapter:         4,versenumber:        29,verse:"“至于米拉利的子孙，你要照着家室、宗族把他们数点。"},
{volumn:         4,chapter:         4,versenumber:        30,verse:"从三十岁直到五十岁，凡前来任职、在会幕里办事的，你都要数点。"},
{volumn:         4,chapter:         4,versenumber:        31,verse:"他们办理会幕的事，就是抬帐幕的板、闩、柱子，和带卯的座，"},
{volumn:         4,chapter:         4,versenumber:        32,verse:"院子四围的柱子和其上带卯的座、橛子、绳子，并一切使用的器具。他们所抬的器具，你们要按名指定。"},
{volumn:         4,chapter:         4,versenumber:        33,verse:"这是米拉利子孙各族在会幕里所办的事，都在祭司亚伦儿子以他玛的手下。”"},
{volumn:         4,chapter:         4,versenumber:        34,verse:"摩西、亚伦与会众的诸首领将哥辖的子孙，照着家室、宗族，"},
{volumn:         4,chapter:         4,versenumber:        35,verse:"从三十岁直到五十岁，凡前来任职、在会幕里办事的，都数点了。"},
{volumn:         4,chapter:         4,versenumber:        36,verse:"被数的共有二千七百五十名。"},
{volumn:         4,chapter:         4,versenumber:        37,verse:"这是哥辖各族中被数的，是在会幕里办事的，就是摩西、亚伦照耶和华藉摩西所吩咐数点的。"},
{volumn:         4,chapter:         4,versenumber:        38,verse:"革顺子孙被数的，照着家室、宗族，"},
{volumn:         4,chapter:         4,versenumber:        39,verse:"从三十岁直到五十岁，凡前来任职、在会幕里办事的，共有二千六百三十名。"},
{volumn:         4,chapter:         4,versenumber:        40,verse:""},
{volumn:         4,chapter:         4,versenumber:        41,verse:"这是革顺子孙各族中被数的，是在会幕里办事的，就是摩西、亚伦照耶和华藉摩西所吩咐数点的。"},
{volumn:         4,chapter:         4,versenumber:        42,verse:"米拉利子孙中各族被数的，照着家室、宗族，"},
{volumn:         4,chapter:         4,versenumber:        43,verse:"从三十岁直到五十岁，凡前来任职、在会幕里办事的，共有三千二百名。"},
{volumn:         4,chapter:         4,versenumber:        44,verse:""},
{volumn:         4,chapter:         4,versenumber:        45,verse:"这是米拉利子孙各族中被数的，就是摩西、亚伦照耶和华藉摩西所吩咐数点的。"},
{volumn:         4,chapter:         4,versenumber:        46,verse:"凡被数的利未人，就是摩西、亚伦并以色列众首领，照着家室、宗族所数点的，"},
{volumn:         4,chapter:         4,versenumber:        47,verse:"从三十岁直到五十岁，凡前来任职、在会幕里做抬物之工的，共有八千五百八十名。"},
{volumn:         4,chapter:         4,versenumber:        48,verse:""},
{volumn:         4,chapter:         4,versenumber:        49,verse:"摩西按他们所办的事、所抬的物，凭耶和华的吩咐数点他们；他们这样被摩西数点，正如耶和华所吩咐他的。"},
{volumn:         4,chapter:         5,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         5,versenumber:         2,verse:"“你吩咐以色列人，使一切长大麻风的，患漏症的，并因死尸不洁净的，都出营外去。"},
{volumn:         4,chapter:         5,versenumber:         3,verse:"无论男女都要使他们出到营外，免得污秽他们的营；这营是我所住的。”"},
{volumn:         4,chapter:         5,versenumber:         4,verse:"以色列人就这样行，使他们出到营外。耶和华怎样吩咐摩西，以色列人就怎样行了。"},
{volumn:         4,chapter:         5,versenumber:         5,verse:"耶和华对摩西说："},
{volumn:         4,chapter:         5,versenumber:         6,verse:"“你晓谕以色列人说：无论男女，若犯了人所常犯的罪，以致干犯耶和华，那人就有了罪。"},
{volumn:         4,chapter:         5,versenumber:         7,verse:"他要承认所犯的罪，将所亏负人的，如数赔还，另外加上五分之一，也归与所亏负的人。"},
{volumn:         4,chapter:         5,versenumber:         8,verse:"那人若没有亲属可受所赔还的，那所赔还的就要归与服侍耶和华的祭司；至于那为他赎罪的公羊是在外。"},
{volumn:         4,chapter:         5,versenumber:         9,verse:"以色列人一切的圣物中，所奉给祭司的举祭都要归与祭司。"},
{volumn:         4,chapter:         5,versenumber:        10,verse:"各人所分别为圣的物，无论是什么，都要归给祭司。”"},
{volumn:         4,chapter:         5,versenumber:        11,verse:"耶和华对摩西说："},
{volumn:         4,chapter:         5,versenumber:        12,verse:"“你晓谕以色列人说：人的妻若有邪行，得罪她丈夫，"},
{volumn:         4,chapter:         5,versenumber:        13,verse:"有人与她行淫，事情严密，瞒过她丈夫，而且她被玷污，没有作见证的人，当她行淫的时候也没有被捉住，"},
{volumn:         4,chapter:         5,versenumber:        14,verse:"她丈夫生了疑恨的心，疑恨她，她是被玷污，或是她丈夫生了疑恨的心，疑恨她，她并没有被玷污，"},
{volumn:         4,chapter:         5,versenumber:        15,verse:"这人就要将妻送到祭司那里，又为她带着大麦面伊法十分之一作供物，不可浇上油，也不可加上乳香；因为这是疑恨的素祭，是思念的素祭，使人思念罪孽。"},
{volumn:         4,chapter:         5,versenumber:        16,verse:"“祭司要使那妇人近前来，站在耶和华面前。"},
{volumn:         4,chapter:         5,versenumber:        17,verse:"祭司要把圣水盛在瓦器里，又从帐幕的地上取点尘土，放在水中。"},
{volumn:         4,chapter:         5,versenumber:        18,verse:"祭司要叫那妇人蓬头散发，站在耶和华面前，把思念的素祭，就是疑恨的素祭，放在她手中。祭司手里拿着致咒诅的苦水，"},
{volumn:         4,chapter:         5,versenumber:        19,verse:"要叫妇人起誓，对她说：‘若没有人与你行淫，也未曾背着丈夫做污秽的事，你就免受这致咒诅苦水的灾。"},
{volumn:         4,chapter:         5,versenumber:        20,verse:"你若背着丈夫行了污秽的事，在你丈夫以外有人与你行淫，"},
{volumn:         4,chapter:         5,versenumber:        21,verse:"（祭司叫妇人发咒起誓，）愿耶和华叫你大腿消瘦，肚腹发胀，使你在你民中被人咒诅，成了誓语；"},
{volumn:         4,chapter:         5,versenumber:        22,verse:"并且这致咒诅的水入你的肠中，要叫你的肚腹发胀，大腿消瘦。’妇人要回答说：‘阿们，阿们。’"},
{volumn:         4,chapter:         5,versenumber:        23,verse:"“祭司要写这咒诅的话，将所写的字抹在苦水里，"},
{volumn:         4,chapter:         5,versenumber:        24,verse:"又叫妇人喝这致咒诅的苦水；这水要进入她里面变苦了。"},
{volumn:         4,chapter:         5,versenumber:        25,verse:"祭司要从妇人的手中取那疑恨的素祭，在耶和华面前摇一摇，拿到坛前；"},
{volumn:         4,chapter:         5,versenumber:        26,verse:"又要从素祭中取出一把，作为这事的纪念，烧在坛上，然后叫妇人喝这水。"},
{volumn:         4,chapter:         5,versenumber:        27,verse:"叫她喝了以后，她若被玷污，得罪了丈夫，这致咒诅的水必进入她里面变苦了，她的肚腹就要发胀，大腿就要消瘦，那妇人便要在他民中被人咒诅。"},
{volumn:         4,chapter:         5,versenumber:        28,verse:"若妇人没有被玷污，却是清洁的，就要免受这灾，且要怀孕。"},
{volumn:         4,chapter:         5,versenumber:        29,verse:"“妻子背着丈夫行了污秽的事，"},
{volumn:         4,chapter:         5,versenumber:        30,verse:"或是人生了疑恨的心，疑恨他的妻，就有这疑恨的条例。那时他要叫妇人站在耶和华面前，祭司要在她身上照这条例而行。"},
{volumn:         4,chapter:         5,versenumber:        31,verse:"男人就为无罪，妇人必担当自己的罪孽。”"},
{volumn:         4,chapter:         6,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         4,chapter:         6,versenumber:         2,verse:"“你晓谕以色列人说：无论男女许了特别的愿，就是拿细耳人的愿（拿细耳就是归主的意思；下同），要离俗归耶和华。"},
{volumn:         4,chapter:         6,versenumber:         3,verse:"他就要远离清酒浓酒，也不可喝什么清酒浓酒做的醋；不可喝什么葡萄汁，也不可吃鲜葡萄和干葡萄。"},
{volumn:         4,chapter:         6,versenumber:         4,verse:"在一切离俗的日子，凡葡萄树上结的，自核至皮所做的物，都不可吃。"},
{volumn:         4,chapter:         6,versenumber:         5,verse:"“在他一切许愿离俗的日子，不可用剃头刀剃头，要由发绺长长了。他要圣洁，直到离俗归耶和华的日子满了。"},
{volumn:         4,chapter:         6,versenumber:         6,verse:"在他离俗归耶和华的一切日子，不可挨近死尸。"},
{volumn:         4,chapter:         6,versenumber:         7,verse:"他的父母或是弟兄姐妹死了的时候，他不可因他们使自己不洁净，因为那离俗归　神的凭据是在他头上。"},
{volumn:         4,chapter:         6,versenumber:         8,verse:"在他一切离俗的日子是归耶和华为圣。"},
{volumn:         4,chapter:         6,versenumber:         9,verse:"“若在他旁边忽然有人死了，以致沾染了他离俗的头，他要在第七日，得洁净的时候，剃头。"},
{volumn:         4,chapter:         6,versenumber:        10,verse:"第八日，他要把两只斑鸠或两只雏鸽带到会幕门口，交给祭司。"},
{volumn:         4,chapter:         6,versenumber:        11,verse:"祭司要献一只作赎罪祭，一只作燔祭，为他赎那因死尸而有的罪，并要当日使他的头成为圣洁。"},
{volumn:         4,chapter:         6,versenumber:        12,verse:"他要另选离俗归耶和华的日子，又要牵一只一岁的公羊羔来作赎愆祭；但先前的日子要归徒然，因为他在离俗之间被玷污了。"},
{volumn:         4,chapter:         6,versenumber:        13,verse:"“拿细耳人满了离俗的日子乃有这条例：人要领他到会幕门口，"},
{volumn:         4,chapter:         6,versenumber:        14,verse:"他要将供物奉给耶和华，就是一只没有残疾、一岁的公羊羔作燔祭，一只没有残疾、一岁的母羊羔作赎罪祭，和一只没有残疾的公绵羊作平安祭，"},
{volumn:         4,chapter:         6,versenumber:        15,verse:"并一筐子无酵调油的细面饼，与抹油的无酵薄饼，并同献的素祭和奠祭。"},
{volumn:         4,chapter:         6,versenumber:        16,verse:"祭司要在耶和华面前献那人的赎罪祭和燔祭；"},
{volumn:         4,chapter:         6,versenumber:        17,verse:"也要把那只公羊和那筐无酵饼献给耶和华作平安祭，又要将同献的素祭和奠祭献上。"},
{volumn:         4,chapter:         6,versenumber:        18,verse:"拿细耳人要在会幕门口剃离俗的头，把离俗头上的发放在平安祭下的火上。"},
{volumn:         4,chapter:         6,versenumber:        19,verse:"他剃了以后，祭司就要取那已煮的公羊一条前腿，又从筐子里取一个无酵饼和一个无酵薄饼，都放在他手上。"},
{volumn:         4,chapter:         6,versenumber:        20,verse:"祭司要拿这些作为摇祭，在耶和华面前摇一摇；这与所摇的胸、所举的腿同为圣物，归给祭司。然后拿细耳人可以喝酒。"},
{volumn:         4,chapter:         6,versenumber:        21,verse:"“许愿的拿细耳人为离俗所献的供物，和他以外所能得的献给耶和华，就有这条例。他怎样许愿就当照离俗的条例行。”"},
{volumn:         4,chapter:         6,versenumber:        22,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         6,versenumber:        23,verse:"“你告诉亚伦和他儿子说：你们要这样为以色列人祝福，说："},
{volumn:         4,chapter:         6,versenumber:        24,verse:"‘愿耶和华赐福给你，保护你。"},
{volumn:         4,chapter:         6,versenumber:        25,verse:"愿耶和华使他的脸光照你，赐恩给你。"},
{volumn:         4,chapter:         6,versenumber:        26,verse:"愿耶和华向你仰脸，赐你平安。’"},
{volumn:         4,chapter:         6,versenumber:        27,verse:"他们要如此奉我的名为以色列人祝福；我也要赐福给他们。”"},
{volumn:         4,chapter:         7,versenumber:         1,verse:"摩西立完了帐幕，就把帐幕用膏抹了，使它成圣，又把其中的器具和坛，并坛上的器具，都抹了，使它成圣。"},
{volumn:         4,chapter:         7,versenumber:         2,verse:"当天，以色列的众首领，就是各族的族长，都来奉献。他们是各支派的首领，管理那些被数的人。"},
{volumn:         4,chapter:         7,versenumber:         3,verse:"他们把自己的供物送到耶和华面前，就是六辆篷子车和十二只公牛。每两个首领奉献一辆车，每首领奉献一只牛。他们把这些都奉到帐幕前。"},
{volumn:         4,chapter:         7,versenumber:         4,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         7,versenumber:         5,verse:"“你要收下这些，好作会幕的使用，都要照利未人所办的事交给他们。”"},
{volumn:         4,chapter:         7,versenumber:         6,verse:"于是摩西收了车和牛，交给利未人，"},
{volumn:         4,chapter:         7,versenumber:         7,verse:"把两辆车，四只牛，照革顺子孙所办的事交给他们，"},
{volumn:         4,chapter:         7,versenumber:         8,verse:"又把四辆车，八只牛，照米拉利子孙所办的事交给他们；他们都在祭司亚伦的儿子以他玛手下。"},
{volumn:         4,chapter:         7,versenumber:         9,verse:"但车与牛都没有交给哥辖子孙；因为他们办的是圣所的事，在肩头上抬圣物。"},
{volumn:         4,chapter:         7,versenumber:        10,verse:"用膏抹坛的日子，首领都来行奉献坛的礼，众首领就在坛前献供物。"},
{volumn:         4,chapter:         7,versenumber:        11,verse:"耶和华对摩西说：“众首领为行奉献坛的礼，要每天一个首领来献供物。”"},
{volumn:         4,chapter:         7,versenumber:        12,verse:"头一日献供物的是犹大支派的亚米拿达的儿子拿顺。"},
{volumn:         4,chapter:         7,versenumber:        13,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        14,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        15,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        16,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        17,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是亚米拿达儿子拿顺的供物。"},
{volumn:         4,chapter:         7,versenumber:        18,verse:"第二日来献的是以萨迦子孙的首领、苏押的儿子拿坦业。"},
{volumn:         4,chapter:         7,versenumber:        19,verse:"他献为供物的是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        20,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        21,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        22,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        23,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是苏押儿子拿坦业的供物。"},
{volumn:         4,chapter:         7,versenumber:        24,verse:"第三日来献的是西布伦子孙的首领、希伦的儿子以利押。"},
{volumn:         4,chapter:         7,versenumber:        25,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        26,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        27,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        28,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        29,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是希伦儿子以利押的供物。"},
{volumn:         4,chapter:         7,versenumber:        30,verse:"第四日来献的是流便子孙的首领、示丢珥的儿子以利蓿。"},
{volumn:         4,chapter:         7,versenumber:        31,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        32,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        33,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        34,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        35,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是示丢珥的儿子以利蓿的供物。"},
{volumn:         4,chapter:         7,versenumber:        36,verse:"第五日来献的是西缅子孙的首领、苏利沙代的儿子示路蔑。"},
{volumn:         4,chapter:         7,versenumber:        37,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        38,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        39,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        40,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        41,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是苏利沙代儿子示路蔑的供物。"},
{volumn:         4,chapter:         7,versenumber:        42,verse:"第六日来献的是迦得子孙的首领、丢珥的儿子以利雅萨。"},
{volumn:         4,chapter:         7,versenumber:        43,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        44,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        45,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        46,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        47,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是丢珥的儿子以利雅萨的供物。"},
{volumn:         4,chapter:         7,versenumber:        48,verse:"第七日来献的是以法莲子孙的首领、亚米忽的儿子以利沙玛。"},
{volumn:         4,chapter:         7,versenumber:        49,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        50,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        51,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        52,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        53,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是亚米忽儿子以利沙玛的供物。"},
{volumn:         4,chapter:         7,versenumber:        54,verse:"第八日来献的是玛拿西子孙的首领、比大蓿的儿子迦玛列。"},
{volumn:         4,chapter:         7,versenumber:        55,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        56,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        57,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        58,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        59,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是比大蓿儿子迦玛列的供物。"},
{volumn:         4,chapter:         7,versenumber:        60,verse:"第九日来献的是便雅悯子孙的首领、基多尼的儿子亚比但。"},
{volumn:         4,chapter:         7,versenumber:        61,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        62,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        63,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        64,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        65,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是基多尼儿子亚比但的供物。"},
{volumn:         4,chapter:         7,versenumber:        66,verse:"第十日来献的是但子孙的首领、亚米沙代的儿子亚希以谢。"},
{volumn:         4,chapter:         7,versenumber:        67,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        68,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        69,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        70,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        71,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是亚米沙代儿子亚希以谢的供物。"},
{volumn:         4,chapter:         7,versenumber:        72,verse:"第十一日来献的是亚设子孙的首领、俄兰的儿子帕结。"},
{volumn:         4,chapter:         7,versenumber:        73,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        74,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        75,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        76,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        77,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是俄兰儿子帕结的供物。"},
{volumn:         4,chapter:         7,versenumber:        78,verse:"第十二日来献的是拿弗他利子孙的首领、以南儿子亚希拉。"},
{volumn:         4,chapter:         7,versenumber:        79,verse:"他的供物是：一个银盘子，重一百三十舍客勒，一个银碗，重七十舍客勒，都是按圣所的平，也都盛满了调油的细面作素祭；"},
{volumn:         4,chapter:         7,versenumber:        80,verse:"一个金盂，重十舍客勒，盛满了香；"},
{volumn:         4,chapter:         7,versenumber:        81,verse:"一只公牛犊，一只公绵羊，一只一岁的公羊羔作燔祭；"},
{volumn:         4,chapter:         7,versenumber:        82,verse:"一只公山羊作赎罪祭；"},
{volumn:         4,chapter:         7,versenumber:        83,verse:"两只公牛，五只公绵羊，五只公山羊，五只一岁的公羊羔作平安祭。这是以南儿子亚希拉的供物。"},
{volumn:         4,chapter:         7,versenumber:        84,verse:"用膏抹坛的日子，以色列的众首领为行献坛之礼所献的是：银盘子十二个，银碗十二个，金盂十二个；"},
{volumn:         4,chapter:         7,versenumber:        85,verse:"每盘子重一百三十舍客勒，每碗重七十舍客勒。一切器皿的银子，按圣所的平，共有二千四百舍客勒。"},
{volumn:         4,chapter:         7,versenumber:        86,verse:"十二个金盂盛满了香，按圣所的平，每盂重十舍客勒，所有的金子共一百二十舍客勒。"},
{volumn:         4,chapter:         7,versenumber:        87,verse:"作燔祭的，共有公牛十二只，公羊十二只，一岁的公羊羔十二只，并同献的素祭作赎罪祭的公山羊十二只；"},
{volumn:         4,chapter:         7,versenumber:        88,verse:"作平安祭的，共有公牛二十四只，公绵羊六十只，公山羊六十只，一岁的公羊羔六十只。这就是用膏抹坛之后，为行奉献坛之礼所献的。"},
{volumn:         4,chapter:         7,versenumber:        89,verse:"摩西进会幕要与耶和华说话的时候，听见法柜的施恩座以上、二基路伯中间有与他说话的声音，就是耶和华与他说话。"},
{volumn:         4,chapter:         8,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         8,versenumber:         2,verse:"“你告诉亚伦说：点灯的时候，七盏灯都要向灯台前面发光。”"},
{volumn:         4,chapter:         8,versenumber:         3,verse:"亚伦便这样行。他点灯台上的灯，使灯向前发光，是照耶和华所吩咐摩西的。"},
{volumn:         4,chapter:         8,versenumber:         4,verse:"这灯台的做法是用金子锤出来的，连座带花都是锤出来的。摩西制造灯台，是照耶和华所指示的样式。"},
{volumn:         4,chapter:         8,versenumber:         5,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         8,versenumber:         6,verse:"“你从以色列人中选出利未人来，洁净他们。"},
{volumn:         4,chapter:         8,versenumber:         7,verse:"洁净他们当这样行：用除罪水弹在他们身上，又叫他们用剃头刀刮全身，洗衣服，洁净自己。"},
{volumn:         4,chapter:         8,versenumber:         8,verse:"然后叫他们取一只公牛犊，并同献的素祭，就是调油的细面；你要另取一只公牛犊作赎罪祭。"},
{volumn:         4,chapter:         8,versenumber:         9,verse:"将利未人奉到会幕前，招聚以色列全会众。"},
{volumn:         4,chapter:         8,versenumber:        10,verse:"将利未人奉到耶和华面前，以色列人要按手在他们头上。"},
{volumn:         4,chapter:         8,versenumber:        11,verse:"亚伦也将他们奉到耶和华面前，为以色列人当作摇祭，使他们好办耶和华的事。"},
{volumn:         4,chapter:         8,versenumber:        12,verse:"利未人要按手在那两只牛的头上；你要将一只作赎罪祭，一只作燔祭，献给耶和华，为利未人赎罪。"},
{volumn:         4,chapter:         8,versenumber:        13,verse:"你也要使利未人站在亚伦和他儿子面前，将他们当作摇祭奉给耶和华。"},
{volumn:         4,chapter:         8,versenumber:        14,verse:"“这样，你从以色列人中将利未人分别出来，利未人便要归我。"},
{volumn:         4,chapter:         8,versenumber:        15,verse:"此后利未人要进去办会幕的事，你要洁净他们，将他们当作摇祭奉上；"},
{volumn:         4,chapter:         8,versenumber:        16,verse:"因为他们是从以色列人中全然给我的，我拣选他们归我，是代替以色列人中一切头生的。"},
{volumn:         4,chapter:         8,versenumber:        17,verse:"以色列人中一切头生的，连人带牲畜，都是我的。我在埃及地击杀一切头生的那天，将他们分别为圣归我。"},
{volumn:         4,chapter:         8,versenumber:        18,verse:"我拣选利未人代替以色列人中一切头生的。"},
{volumn:         4,chapter:         8,versenumber:        19,verse:"我从以色列人中将利未人当作赏赐给亚伦和他的儿子，在会幕中办以色列人的事，又为以色列人赎罪，免得他们挨近圣所，有灾殃临到他们中间。”"},
{volumn:         4,chapter:         8,versenumber:        20,verse:"摩西、亚伦，并以色列全会众便向利未人如此行。凡耶和华指着利未人所吩咐摩西的，以色列人就向他们这样行。"},
{volumn:         4,chapter:         8,versenumber:        21,verse:"于是利未人洁净自己，除了罪，洗了衣服；亚伦将他们当作摇祭奉到耶和华面前，又为他们赎罪，洁净他们。"},
{volumn:         4,chapter:         8,versenumber:        22,verse:"然后利未人进去，在亚伦和他儿子面前，在会幕中办事。耶和华指着利未人怎样吩咐摩西，以色列人就怎样向他们行了。"},
{volumn:         4,chapter:         8,versenumber:        23,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:         8,versenumber:        24,verse:"“利未人是这样：从二十五岁以外，他们要前来任职，办会幕的事。"},
{volumn:         4,chapter:         8,versenumber:        25,verse:"到了五十岁要停工退任，不再办事，"},
{volumn:         4,chapter:         8,versenumber:        26,verse:"只要在会幕里，和他们的弟兄一同伺候，谨守所吩咐的，不再办事了。至于所吩咐利未人的，你要这样向他们行。”"},
{volumn:         4,chapter:         9,versenumber:         1,verse:"以色列人出埃及地以后，第二年正月，耶和华在西奈的旷野吩咐摩西说："},
{volumn:         4,chapter:         9,versenumber:         2,verse:"“以色列人应当在所定的日期守逾越节，"},
{volumn:         4,chapter:         9,versenumber:         3,verse:"就是本月十四日黄昏的时候，你们要在所定的日期守这节，要按这节的律例典章而守。”"},
{volumn:         4,chapter:         9,versenumber:         4,verse:"于是摩西吩咐以色列人守逾越节。"},
{volumn:         4,chapter:         9,versenumber:         5,verse:"他们就在西奈的旷野，正月十四日黄昏的时候，守逾越节。凡耶和华所吩咐摩西的，以色列人都照样行了。"},
{volumn:         4,chapter:         9,versenumber:         6,verse:"有几个人因死尸而不洁净，不能在那日守逾越节。当日他们到摩西、亚伦面前，"},
{volumn:         4,chapter:         9,versenumber:         7,verse:"说：“我们虽因死尸而不洁净，为何被阻止、不得同以色列人在所定的日期献耶和华的供物呢？”"},
{volumn:         4,chapter:         9,versenumber:         8,verse:"摩西对他们说：“你们暂且等候，我可以去听耶和华指着你们是怎样吩咐的。”"},
{volumn:         4,chapter:         9,versenumber:         9,verse:"耶和华对摩西说："},
{volumn:         4,chapter:         9,versenumber:        10,verse:"“你晓谕以色列人说：你们和你们后代中，若有人因死尸而不洁净，或在远方行路，还要向耶和华守逾越节。"},
{volumn:         4,chapter:         9,versenumber:        11,verse:"他们要在二月十四日黄昏的时候，守逾越节。要用无酵饼与苦菜，和逾越节的羊羔同吃。"},
{volumn:         4,chapter:         9,versenumber:        12,verse:"一点不可留到早晨；羊羔的骨头一根也不可折断。他们要照逾越节的一切律例而守。"},
{volumn:         4,chapter:         9,versenumber:        13,verse:"那洁净而不行路的人若推辞不守逾越节，那人要从民中剪除；因为他在所定的日期不献耶和华的供物，应该担当他的罪。"},
{volumn:         4,chapter:         9,versenumber:        14,verse:"若有外人寄居在你们中间，愿意向耶和华守逾越节，他要照逾越节的律例典章行，不管是寄居的是本地人，同归一例。”"},
{volumn:         4,chapter:         9,versenumber:        15,verse:"立起帐幕的那日，有云彩遮盖帐幕，就是法柜的帐幕；从晚上到早晨，云彩在其上，形状如火。"},
{volumn:         4,chapter:         9,versenumber:        16,verse:"常是这样，云彩遮盖帐幕，夜间形状如火。"},
{volumn:         4,chapter:         9,versenumber:        17,verse:"云彩几时从帐幕收上去，以色列人就几时起行；云彩在哪里停住，以色列人就在那里安营。"},
{volumn:         4,chapter:         9,versenumber:        18,verse:"以色列人遵耶和华的吩咐起行，也遵耶和华的吩咐安营。云彩在帐幕上停住几时，他们就住营几时。"},
{volumn:         4,chapter:         9,versenumber:        19,verse:"云彩在帐幕上停留许多日子，以色列人就守耶和华所吩咐的不起行。"},
{volumn:         4,chapter:         9,versenumber:        20,verse:"有时云彩在帐幕上几天，他们就照耶和华的吩咐住营，也照耶和华的吩咐起行。"},
{volumn:         4,chapter:         9,versenumber:        21,verse:"有时从晚上到早晨，有这云彩在帐幕上；早晨云彩收上去，他们就起行。有时昼夜云彩停在帐幕上，收上去的时候，他们就起行。"},
{volumn:         4,chapter:         9,versenumber:        22,verse:"云彩停留在帐幕上，无论是两天，是一月，是一年，以色列人就住营不起行；但云彩收上去，他们就起行。"},
{volumn:         4,chapter:         9,versenumber:        23,verse:"他们遵耶和华的吩咐安营，也遵耶和华的吩咐起行。他们守耶和华所吩咐的，都是凭耶和华吩咐摩西的。"},
{volumn:         4,chapter:        10,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        10,versenumber:         2,verse:"“你要用银子做两枝号，都要锤出来的，用以招聚会众，并叫众营起行。"},
{volumn:         4,chapter:        10,versenumber:         3,verse:"吹这号的时候，全会众要到你那里，聚集在会幕门口。"},
{volumn:         4,chapter:        10,versenumber:         4,verse:"若单吹一枝，众首领，就是以色列军中的统领，要聚集到你那里。"},
{volumn:         4,chapter:        10,versenumber:         5,verse:"吹出大声的时候，东边安的营都要起行。"},
{volumn:         4,chapter:        10,versenumber:         6,verse:"二次吹出大声的时候，南边安的营都要起行。他们将起行，必吹出大声。"},
{volumn:         4,chapter:        10,versenumber:         7,verse:"但招聚会众的时候，你们要吹号，却不要吹出大声。"},
{volumn:         4,chapter:        10,versenumber:         8,verse:"亚伦子孙作祭司的要吹这号；这要作你们世世代代永远的定例。"},
{volumn:         4,chapter:        10,versenumber:         9,verse:"你们在自己的地，与欺压你们的敌人打仗，就要用号吹出大声，便在耶和华你们的　神面前得蒙纪念，也蒙拯救脱离仇敌。"},
{volumn:         4,chapter:        10,versenumber:        10,verse:"在你们快乐的日子和节期，并月朔，献燔祭和平安祭，也要吹号，这都要在你们的　神面前作为纪念。我是耶和华你们的　神。”"},
{volumn:         4,chapter:        10,versenumber:        11,verse:"第二年二月二十日，云彩从法柜的帐幕收上去。"},
{volumn:         4,chapter:        10,versenumber:        12,verse:"以色列人就按站往前行，离开西奈的旷野，云彩停住在巴兰的旷野。"},
{volumn:         4,chapter:        10,versenumber:        13,verse:"这是他们照耶和华藉摩西所吩咐的，初次往前行。"},
{volumn:         4,chapter:        10,versenumber:        14,verse:"按着军队首先往前行的是犹大营的纛。统领军队的是亚米拿达的儿子拿顺。"},
{volumn:         4,chapter:        10,versenumber:        15,verse:"统领以萨迦支派军队的是苏押的儿子拿坦业。"},
{volumn:         4,chapter:        10,versenumber:        16,verse:"统领西布伦支派军队的是希伦的儿子以利押。"},
{volumn:         4,chapter:        10,versenumber:        17,verse:"帐幕拆卸，革顺的子孙和米拉利的子孙就抬着帐幕先往前行。"},
{volumn:         4,chapter:        10,versenumber:        18,verse:"按着军队往前行的是流便营的纛。统领军队的是示丢珥的儿子以利蓿。"},
{volumn:         4,chapter:        10,versenumber:        19,verse:"统领西缅支派军队的是苏利沙代的儿子示路蔑。"},
{volumn:         4,chapter:        10,versenumber:        20,verse:"统领迦得支派军队的是丢珥的儿子以利雅萨。"},
{volumn:         4,chapter:        10,versenumber:        21,verse:"哥辖人抬着圣物先往前行。他们未到以前，抬帐幕的已经把帐幕支好。"},
{volumn:         4,chapter:        10,versenumber:        22,verse:"按着军队往前行的是以法莲营的纛，统领军队的是亚米忽的儿子以利沙玛。"},
{volumn:         4,chapter:        10,versenumber:        23,verse:"统领玛拿西支派军队的是比大蓿的儿子迦玛列。"},
{volumn:         4,chapter:        10,versenumber:        24,verse:"统领便雅悯支派军队的是基多尼的儿子亚比但。"},
{volumn:         4,chapter:        10,versenumber:        25,verse:"在诸营末后的是但营的纛，按着军队往前行。统领军队的是亚米沙代的儿子亚希以谢。"},
{volumn:         4,chapter:        10,versenumber:        26,verse:"统领亚设支派军队的是俄兰的儿子帕结。"},
{volumn:         4,chapter:        10,versenumber:        27,verse:"统领拿弗他利支派军队的是以南的儿子亚希拉。"},
{volumn:         4,chapter:        10,versenumber:        28,verse:"以色列人按着军队往前行，就是这样。"},
{volumn:         4,chapter:        10,versenumber:        29,verse:"摩西对他岳父（或译：内兄）米甸人流珥的儿子何巴说：“我们要行路，往耶和华所应许之地去；他曾说：‘我要将这地赐给你们。’现在求你和我们同去，我们必厚待你，因为耶和华指着以色列人已经应许给好处。”"},
{volumn:         4,chapter:        10,versenumber:        30,verse:"何巴回答说：“我不去；我要回本地本族那里去。”"},
{volumn:         4,chapter:        10,versenumber:        31,verse:"摩西说：“求你不要离开我们；因为你知道我们要在旷野安营，你可以当作我们的眼目。"},
{volumn:         4,chapter:        10,versenumber:        32,verse:"你若和我们同去，将来耶和华有什么好处待我们，我们也必以什么好处待你。”"},
{volumn:         4,chapter:        10,versenumber:        33,verse:"以色列人离开耶和华的山，往前行了三天的路程；耶和华的约柜在前头行了三天的路程，为他们寻找安歇的地方。"},
{volumn:         4,chapter:        10,versenumber:        34,verse:"他们拔营往前行，日间有耶和华的云彩在他们以上。"},
{volumn:         4,chapter:        10,versenumber:        35,verse:"约柜往前行的时候，摩西就说：“耶和华啊，求你兴起！愿你的仇敌四散！愿恨你的人从你面前逃跑！”"},
{volumn:         4,chapter:        10,versenumber:        36,verse:"约柜停住的时候，他就说：“耶和华啊，求你回到以色列的千万人中！”"},
{volumn:         4,chapter:        11,versenumber:         1,verse:"众百姓发怨言，他们的恶语达到耶和华的耳中。耶和华听见了就怒气发作，使火在他们中间焚烧，直烧到营的边界。"},
{volumn:         4,chapter:        11,versenumber:         2,verse:"百姓向摩西哀求，摩西祈求耶和华，火就熄了。"},
{volumn:         4,chapter:        11,versenumber:         3,verse:"那地方便叫做他备拉，因为耶和华的火烧在他们中间。"},
{volumn:         4,chapter:        11,versenumber:         4,verse:"他们中间的闲杂人大起贪欲的心；以色列人又哭号说：“谁给我们肉吃呢？"},
{volumn:         4,chapter:        11,versenumber:         5,verse:"我们记得，在埃及的时候不花钱就吃鱼，也记得有黄瓜、西瓜、韭菜、葱、蒜。"},
{volumn:         4,chapter:        11,versenumber:         6,verse:"现在我们的心血枯竭了，除这吗哪以外，在我们眼前并没有别的东西。”"},
{volumn:         4,chapter:        11,versenumber:         7,verse:"这吗哪仿佛芫荽子，又好像珍珠。"},
{volumn:         4,chapter:        11,versenumber:         8,verse:"百姓周围行走，把吗哪收起来，或用磨推，或用臼捣，煮在锅中，又做成饼，滋味好像新油。"},
{volumn:         4,chapter:        11,versenumber:         9,verse:"夜间露水降在营中，吗哪也随着降下。"},
{volumn:         4,chapter:        11,versenumber:        10,verse:"摩西听见百姓各在各家的帐棚门口哭号。耶和华的怒气便大发作，摩西就不喜悦。"},
{volumn:         4,chapter:        11,versenumber:        11,verse:"摩西对耶和华说：“你为何苦待仆人？我为何不在你眼前蒙恩，竟把这管理百姓的重任加在我身上呢？"},
{volumn:         4,chapter:        11,versenumber:        12,verse:"这百姓岂是我怀的胎，岂是我生下来的呢？你竟对我说：‘把他们抱在怀里，如养育之父抱吃奶的孩子，直抱到你起誓应许给他们祖宗的地去。’"},
{volumn:         4,chapter:        11,versenumber:        13,verse:"我从哪里得肉给这百姓吃呢？他们都向我哭号说：‘你给我们肉吃吧！’"},
{volumn:         4,chapter:        11,versenumber:        14,verse:"管理这百姓的责任太重了，我独自担当不起。"},
{volumn:         4,chapter:        11,versenumber:        15,verse:"你这样待我，我若在你眼前蒙恩，求你立时将我杀了，不叫我见自己的苦情。”"},
{volumn:         4,chapter:        11,versenumber:        16,verse:"耶和华对摩西说：“你从以色列的长老中招聚七十个人，就是你所知道作百姓的长老和官长的，到我这里来，领他们到会幕前，使他们和你一同站立。"},
{volumn:         4,chapter:        11,versenumber:        17,verse:"我要在那里降临，与你说话，也要把降于你身上的灵分赐他们，他们就和你同当这管百姓的重任，免得你独自担当。"},
{volumn:         4,chapter:        11,versenumber:        18,verse:"又要对百姓说：‘你们应当自洁，预备明天吃肉，因为你们哭号说：谁给我们肉吃！我们在埃及很好。这声音达到了耶和华的耳中，所以他必给你们肉吃。"},
{volumn:         4,chapter:        11,versenumber:        19,verse:"你们不止吃一天、两天、五天、十天、二十天，"},
{volumn:         4,chapter:        11,versenumber:        20,verse:"要吃一个整月，甚至肉从你们鼻孔里喷出来，使你们厌恶了，因为你们厌弃住在你们中间的耶和华，在他面前哭号说：我们为何出了埃及呢！’”"},
{volumn:         4,chapter:        11,versenumber:        21,verse:"摩西对耶和华说：“这与我同住的百姓、步行的男人有六十万，你还说：‘我要把肉给他们，使他们可以吃一个整月。’"},
{volumn:         4,chapter:        11,versenumber:        22,verse:"难道给他们宰了羊群牛群，或是把海中所有的鱼都聚了来，就够他们吃吗？”"},
{volumn:         4,chapter:        11,versenumber:        23,verse:"耶和华对摩西说：“耶和华的膀臂岂是缩短了吗？现在要看我的话向你应验不应验。”"},
{volumn:         4,chapter:        11,versenumber:        24,verse:"摩西出去，将耶和华的话告诉百姓，又招聚百姓的长老中七十个人来，使他们站在会幕的四围。"},
{volumn:         4,chapter:        11,versenumber:        25,verse:"耶和华在云中降临，对摩西说话，把降与他身上的灵分赐那七十个长老。灵停在他们身上的时候，他们就受感说话，以后却没有再说。"},
{volumn:         4,chapter:        11,versenumber:        26,verse:"但有两个人仍在营里，一个名叫伊利达，一个名叫米达。他们本是在那些被录的人中，却没有到会幕那里去。灵停在他们身上，他们就在营里说预言。"},
{volumn:         4,chapter:        11,versenumber:        27,verse:"有个少年人跑来告诉摩西说：“伊利达、米达在营里说预言。”"},
{volumn:         4,chapter:        11,versenumber:        28,verse:"摩西的帮手，嫩的儿子约书亚，就是摩西所拣选的一个人，说：“请我主摩西禁止他们。”"},
{volumn:         4,chapter:        11,versenumber:        29,verse:"摩西对他说：“你为我的缘故嫉妒人吗？惟愿耶和华的百姓都受感说话！愿耶和华把他的灵降在他们身上！”"},
{volumn:         4,chapter:        11,versenumber:        30,verse:"于是，摩西和以色列的长老都回到营里去。"},
{volumn:         4,chapter:        11,versenumber:        31,verse:"有风从耶和华那里刮起，把鹌鹑由海面刮来，飞散在营边和营的四围；这边约有一天的路程，那边约有一天的路程，离地面约有二肘。"},
{volumn:         4,chapter:        11,versenumber:        32,verse:"百姓起来，终日终夜，并次日一整天，捕取鹌鹑；至少的也取了十贺梅珥，为自己摆列在营的四围。"},
{volumn:         4,chapter:        11,versenumber:        33,verse:"肉在他们牙齿之间尚未嚼烂，耶和华的怒气就向他们发作，用最重的灾殃击杀了他们。"},
{volumn:         4,chapter:        11,versenumber:        34,verse:"那地方便叫做基博罗·哈他瓦（就是贪欲之人的坟墓），因为他们在那里葬埋那起贪欲之心的人。"},
{volumn:         4,chapter:        11,versenumber:        35,verse:"百姓从基博罗·哈他瓦走到哈洗录，就住在哈洗录。"},
{volumn:         4,chapter:        12,versenumber:         1,verse:"摩西娶了古实女子为妻。米利暗和亚伦因他所娶的古实女子就毁谤他，说："},
{volumn:         4,chapter:        12,versenumber:         2,verse:"“难道耶和华单与摩西说话，不也与我们说话吗？”这话耶和华听见了。"},
{volumn:         4,chapter:        12,versenumber:         3,verse:"摩西为人极其谦和，胜过世上的众人。"},
{volumn:         4,chapter:        12,versenumber:         4,verse:"耶和华忽然对摩西、亚伦、米利暗说：“你们三个人都出来，到会幕这里。”他们三个人就出来了。"},
{volumn:         4,chapter:        12,versenumber:         5,verse:"耶和华在云柱中降临，站在会幕门口，召亚伦和米利暗，二人就出来了。"},
{volumn:         4,chapter:        12,versenumber:         6,verse:"耶和华说：“你们且听我的话：你们中间若有先知，我耶和华必在异象中向他显现，在梦中与他说话。"},
{volumn:         4,chapter:        12,versenumber:         7,verse:"我的仆人摩西不是这样；他是在我全家尽忠的。"},
{volumn:         4,chapter:        12,versenumber:         8,verse:"我要与他面对面说话，乃是明说，不用谜语，并且他必见我的形像。你们毁谤我的仆人摩西，为何不惧怕呢？”"},
{volumn:         4,chapter:        12,versenumber:         9,verse:"耶和华就向他们二人发怒而去。"},
{volumn:         4,chapter:        12,versenumber:        10,verse:"云彩从会幕上挪开了，不料，米利暗长了大麻风，有雪那样白。亚伦一看米利暗长了大麻风，"},
{volumn:         4,chapter:        12,versenumber:        11,verse:"就对摩西说：“我主啊，求你不要因我们愚昧犯罪，便将这罪加在我们身上。"},
{volumn:         4,chapter:        12,versenumber:        12,verse:"求你不要使她像那出母腹、肉已半烂的死胎。”"},
{volumn:         4,chapter:        12,versenumber:        13,verse:"于是摩西哀求耶和华说：“　神啊，求你医治她！”"},
{volumn:         4,chapter:        12,versenumber:        14,verse:"耶和华对摩西说：“她父亲若吐唾沫在她脸上，她岂不蒙羞七天吗？现在要把她在营外关锁七天，然后才可以领她进来。”"},
{volumn:         4,chapter:        12,versenumber:        15,verse:"于是米利暗关锁在营外七天。百姓没有行路，直等到把米利暗领进来。"},
{volumn:         4,chapter:        12,versenumber:        16,verse:"以后百姓从哈洗录起行，在巴兰的旷野安营。"},
{volumn:         4,chapter:        13,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        13,versenumber:         2,verse:"“你打发人去窥探我所赐给以色列人的迦南地，他们每支派中要打发一个人，都要作首领的。”"},
{volumn:         4,chapter:        13,versenumber:         3,verse:"摩西就照耶和华的吩咐从巴兰的旷野打发他们去；他们都是以色列人的族长。"},
{volumn:         4,chapter:        13,versenumber:         4,verse:"他们的名字：属流便支派的有撒刻的儿子沙母亚。"},
{volumn:         4,chapter:        13,versenumber:         5,verse:"属西缅支派的有何利的儿子沙法。"},
{volumn:         4,chapter:        13,versenumber:         6,verse:"属犹大支派的有耶孚尼的儿子迦勒。"},
{volumn:         4,chapter:        13,versenumber:         7,verse:"属以萨迦支派的有约色的儿子以迦。"},
{volumn:         4,chapter:        13,versenumber:         8,verse:"属以法莲支派的有嫩的儿子何西阿。"},
{volumn:         4,chapter:        13,versenumber:         9,verse:"属便雅悯支派的有拉孚的儿子帕提。"},
{volumn:         4,chapter:        13,versenumber:        10,verse:"属西布伦支派的有梭底的儿子迦叠。"},
{volumn:         4,chapter:        13,versenumber:        11,verse:"约瑟的子孙，属玛拿西支派的有稣西的儿子迦底。"},
{volumn:         4,chapter:        13,versenumber:        12,verse:"属但支派的有基玛利的儿子亚米利。"},
{volumn:         4,chapter:        13,versenumber:        13,verse:"属亚设支派的有米迦勒的儿子西帖。"},
{volumn:         4,chapter:        13,versenumber:        14,verse:"属拿弗他利支派的有缚西的儿子拿比。"},
{volumn:         4,chapter:        13,versenumber:        15,verse:"属迦得支派的有玛基的儿子臼利。"},
{volumn:         4,chapter:        13,versenumber:        16,verse:"这就是摩西所打发、窥探那地之人的名字。摩西就称嫩的儿子何西阿为约书亚。"},
{volumn:         4,chapter:        13,versenumber:        17,verse:"摩西打发他们去窥探迦南地，说：“你们从南地上山地去，"},
{volumn:         4,chapter:        13,versenumber:        18,verse:"看那地如何，其中所住的民是强是弱，是多是少，"},
{volumn:         4,chapter:        13,versenumber:        19,verse:"所住之地是好是歹，所住之处是营盘是坚城。"},
{volumn:         4,chapter:        13,versenumber:        20,verse:"又看那地土是肥美是瘠薄，其中有树木没有。你们要放开胆量，把那地的果子带些来。”（那时正是葡萄初熟的时候。）"},
{volumn:         4,chapter:        13,versenumber:        21,verse:"他们上去窥探那地，从寻的旷野到利合，直到哈马口。"},
{volumn:         4,chapter:        13,versenumber:        22,verse:"他们从南地上去，到了希伯仑；在那里有亚衲族人亚希幔、示筛、挞买。（原来希伯仑城被建造比埃及的锁安城早七年。）"},
{volumn:         4,chapter:        13,versenumber:        23,verse:"他们到了以实各谷，从那里砍了葡萄树的一枝，上头有一挂葡萄，两个人用杠抬着，又带了些石榴和无花果来。（"},
{volumn:         4,chapter:        13,versenumber:        24,verse:"因为以色列人从那里砍来的那挂葡萄，所以那地方叫做以实各谷。）"},
{volumn:         4,chapter:        13,versenumber:        25,verse:"过了四十天，他们窥探那地才回来，"},
{volumn:         4,chapter:        13,versenumber:        26,verse:"到了巴兰旷野的加低斯，见摩西、亚伦，并以色列的全会众，回报摩西、亚伦，并全会众，又把那地的果子给他们看；"},
{volumn:         4,chapter:        13,versenumber:        27,verse:"又告诉摩西说：“我们到了你所打发我们去的那地，果然是流奶与蜜之地；这就是那地的果子。"},
{volumn:         4,chapter:        13,versenumber:        28,verse:"然而住那地的民强壮，城邑也坚固宽大，并且我们在那里看见了亚衲族的人。"},
{volumn:         4,chapter:        13,versenumber:        29,verse:"亚玛力人住在南地；赫人、耶布斯人、亚摩利人住在山地；迦南人住在海边并约旦河旁。”"},
{volumn:         4,chapter:        13,versenumber:        30,verse:"迦勒在摩西面前安抚百姓，说：“我们立刻上去得那地吧！我们足能得胜。”"},
{volumn:         4,chapter:        13,versenumber:        31,verse:"但那些和他同去的人说：“我们不能上去攻击那民，因为他们比我们强壮。”"},
{volumn:         4,chapter:        13,versenumber:        32,verse:"探子中有人论到所窥探之地，向以色列人报恶信，说：“我们所窥探、经过之地是吞吃居民之地，我们在那里所看见的人民都身量高大。"},
{volumn:         4,chapter:        13,versenumber:        33,verse:"我们在那里看见亚衲族人，就是伟人；他们是伟人的后裔。据我们看，自己就如蚱蜢一样；据他们看，我们也是如此。”"},
{volumn:         4,chapter:        14,versenumber:         1,verse:"当下，全会众大声喧嚷；那夜百姓都哭号。"},
{volumn:         4,chapter:        14,versenumber:         2,verse:"以色列众人向摩西、亚伦发怨言；全会众对他们说：“巴不得我们早死在埃及地，或是死在这旷野。"},
{volumn:         4,chapter:        14,versenumber:         3,verse:"耶和华为什么把我们领到那地，使我们倒在刀下呢？我们的妻子和孩子必被掳掠。我们回埃及去岂不好吗？”"},
{volumn:         4,chapter:        14,versenumber:         4,verse:"众人彼此说：“我们不如立一个首领回埃及去吧！”"},
{volumn:         4,chapter:        14,versenumber:         5,verse:"摩西、亚伦就俯伏在以色列全会众面前。"},
{volumn:         4,chapter:        14,versenumber:         6,verse:"窥探地的人中，嫩的儿子约书亚和耶孚尼的儿子迦勒撕裂衣服，"},
{volumn:         4,chapter:        14,versenumber:         7,verse:"对以色列全会众说：“我们所窥探、经过之地是极美之地。"},
{volumn:         4,chapter:        14,versenumber:         8,verse:"耶和华若喜悦我们，就必将我们领进那地，把地赐给我们；那地原是流奶与蜜之地。"},
{volumn:         4,chapter:        14,versenumber:         9,verse:"但你们不可背叛耶和华，也不要怕那地的居民；因为他们是我们的食物，并且荫庇他们的已经离开他们。有耶和华与我们同在，不要怕他们！”"},
{volumn:         4,chapter:        14,versenumber:        10,verse:"但全会众说：“拿石头打死他们二人。”忽然，耶和华的荣光在会幕中向以色列众人显现。"},
{volumn:         4,chapter:        14,versenumber:        11,verse:"耶和华对摩西说：“这百姓藐视我要到几时呢？我在他们中间行了这一切神迹，他们还不信我要到几时呢？"},
{volumn:         4,chapter:        14,versenumber:        12,verse:"我要用瘟疫击杀他们，使他们不得承受那地，叫你的后裔成为大国，比他们强胜。”"},
{volumn:         4,chapter:        14,versenumber:        13,verse:"摩西对耶和华说：“埃及人必听见这事；因为你曾施展大能，将这百姓从他们中间领上来。"},
{volumn:         4,chapter:        14,versenumber:        14,verse:"埃及人要将这事传给迦南地的居民；那民已经听见你耶和华是在这百姓中间；因为你面对面被人看见，有你的云彩停在他们以上。你日间在云柱中，夜间在火柱中，在他们前面行。"},
{volumn:         4,chapter:        14,versenumber:        15,verse:"如今你若把这百姓杀了，如杀一人，那些听见你名声的列邦必议论说："},
{volumn:         4,chapter:        14,versenumber:        16,verse:"‘耶和华因为不能把这百姓领进他向他们起誓应许之地，所以在旷野把他们杀了。’"},
{volumn:         4,chapter:        14,versenumber:        17,verse:"现在求主大显能力，照你所说过的话说："},
{volumn:         4,chapter:        14,versenumber:        18,verse:"‘耶和华不轻易发怒，并有丰盛的慈爱，赦免罪孽和过犯；万不以有罪的为无罪，必追讨他的罪，自父及子，直到三、四代。’"},
{volumn:         4,chapter:        14,versenumber:        19,verse:"求你照你的大慈爱赦免这百姓的罪孽，好像你从埃及到如今常赦免他们一样。”"},
{volumn:         4,chapter:        14,versenumber:        20,verse:"耶和华说：“我照着你的话赦免了他们。"},
{volumn:         4,chapter:        14,versenumber:        21,verse:"然我指着我的永生起誓，遍地要被我的荣耀充满。"},
{volumn:         4,chapter:        14,versenumber:        22,verse:"这些人虽看见我的荣耀和我在埃及与旷野所行的神迹，仍然试探我这十次，不听从我的话，"},
{volumn:         4,chapter:        14,versenumber:        23,verse:"他们断不得看见我向他们的祖宗所起誓应许之地。凡藐视我的，一个也不得看见；"},
{volumn:         4,chapter:        14,versenumber:        24,verse:"惟独我的仆人迦勒，因他另有一个心志，专一跟从我，我就把他领进他所去过的那地；他的后裔也必得那地为业。"},
{volumn:         4,chapter:        14,versenumber:        25,verse:"亚玛力人和迦南人住在谷中，明天你们要转回，从红海的路往旷野去。”"},
{volumn:         4,chapter:        14,versenumber:        26,verse:"耶和华对摩西、亚伦说："},
{volumn:         4,chapter:        14,versenumber:        27,verse:"“这恶会众向我发怨言，我忍耐他们要到几时呢？以色列人向我所发的怨言，我都听见了。"},
{volumn:         4,chapter:        14,versenumber:        28,verse:"你们告诉他们，耶和华说：‘我指着我的永生起誓，我必要照你们达到我耳中的话待你们。"},
{volumn:         4,chapter:        14,versenumber:        29,verse:"你们的尸首必倒在这旷野，并且你们中间凡被数点、从二十岁以外、向我发怨言的，"},
{volumn:         4,chapter:        14,versenumber:        30,verse:"必不得进我起誓应许叫你们住的那地；惟有耶孚尼的儿子迦勒和嫩的儿子约书亚才能进去。"},
{volumn:         4,chapter:        14,versenumber:        31,verse:"但你们的妇人孩子，就是你们所说、要被掳掠的，我必把他们领进去，他们就得知你们所厌弃的那地。"},
{volumn:         4,chapter:        14,versenumber:        32,verse:"至于你们，你们的尸首必倒在这旷野；"},
{volumn:         4,chapter:        14,versenumber:        33,verse:"你们的儿女必在旷野飘流四十年，担当你们淫行的罪，直到你们的尸首在旷野消灭。"},
{volumn:         4,chapter:        14,versenumber:        34,verse:"按你们窥探那地的四十日，一年顶一日，你们要担当罪孽四十年，就知道我与你们疏远了，"},
{volumn:         4,chapter:        14,versenumber:        35,verse:"我耶和华说过，我总要这样待这一切聚集敌我的恶会众；他们必在这旷野消灭，在这里死亡。’”"},
{volumn:         4,chapter:        14,versenumber:        36,verse:"摩西所打发、窥探那地的人回来，报那地的恶信，叫全会众向摩西发怨言，"},
{volumn:         4,chapter:        14,versenumber:        37,verse:"这些报恶信的人都遭瘟疫，死在耶和华面前。"},
{volumn:         4,chapter:        14,versenumber:        38,verse:"其中惟有嫩的儿子约书亚和耶孚尼的儿子迦勒仍然存活。"},
{volumn:         4,chapter:        14,versenumber:        39,verse:"摩西将这些话告诉以色列众人，他们就甚悲哀。"},
{volumn:         4,chapter:        14,versenumber:        40,verse:"清早起来，上山顶去，说：“我们在这里，我们有罪了；情愿上耶和华所应许的地方去。”"},
{volumn:         4,chapter:        14,versenumber:        41,verse:"摩西说：“你们为何违背耶和华的命令呢？这事不能顺利了。"},
{volumn:         4,chapter:        14,versenumber:        42,verse:"不要上去；因为耶和华不在你们中间，恐怕你们被仇敌杀败了。"},
{volumn:         4,chapter:        14,versenumber:        43,verse:"亚玛力人和迦南人都在你们面前，你们必倒在刀下；因你们退回不跟从耶和华，所以他必不与你们同在。”"},
{volumn:         4,chapter:        14,versenumber:        44,verse:"他们却擅敢上山顶去，然而耶和华的约柜和摩西没有出营。"},
{volumn:         4,chapter:        14,versenumber:        45,verse:"于是亚玛力人和住在那山上的迦南人都下来击打他们，把他们杀退了，直到何珥玛。"},
{volumn:         4,chapter:        15,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         4,chapter:        15,versenumber:         2,verse:"“你晓谕以色列人说：你们到了我所赐给你们居住的地，"},
{volumn:         4,chapter:        15,versenumber:         3,verse:"若愿意从牛群羊群中取牛羊作火祭，献给耶和华，无论是燔祭是平安祭，为要还特许的愿，或是作甘心祭，或是逢你们节期献的，都要奉给耶和华为馨香之祭。"},
{volumn:         4,chapter:        15,versenumber:         4,verse:"那献供物的就要将细面伊法十分之一，并油一欣四分之一，调和作素祭，献给耶和华。"},
{volumn:         4,chapter:        15,versenumber:         5,verse:"无论是燔祭是平安祭，你要为每只绵羊羔，一同预备奠祭的酒一欣四分之一。"},
{volumn:         4,chapter:        15,versenumber:         6,verse:"为公绵羊预备细面伊法十分之二，并油一欣三分之一，调和作素祭，"},
{volumn:         4,chapter:        15,versenumber:         7,verse:"又用酒一欣三分之一作奠祭，献给耶和华为馨香之祭。"},
{volumn:         4,chapter:        15,versenumber:         8,verse:"你预备公牛作燔祭，或是作平安祭，为要还特许的愿，或是作平安祭，献给耶和华，"},
{volumn:         4,chapter:        15,versenumber:         9,verse:"就要把细面伊法十分之三，并油半欣，调和作素祭，和公牛一同献上，"},
{volumn:         4,chapter:        15,versenumber:        10,verse:"又用酒半欣作奠祭，献给耶和华为馨香的火祭。"},
{volumn:         4,chapter:        15,versenumber:        11,verse:"“献公牛、公绵羊、绵羊羔、山羊羔，每只都要这样办理。"},
{volumn:         4,chapter:        15,versenumber:        12,verse:"照你们所预备的数目，按着只数都要这样办理。"},
{volumn:         4,chapter:        15,versenumber:        13,verse:"凡本地人将馨香的火祭献给耶和华，都要这样办理。"},
{volumn:         4,chapter:        15,versenumber:        14,verse:"若有外人和你们同居，或有人世世代代住在你们中间，愿意将馨香的火祭献给耶和华，你们怎样办理，他也要照样办理。"},
{volumn:         4,chapter:        15,versenumber:        15,verse:"至于会众，你们和同居的外人都归一例，作为你们世世代代永远的定例，在耶和华面前，你们怎样，寄居的也要怎样。"},
{volumn:         4,chapter:        15,versenumber:        16,verse:"你们并与你们同居的外人当有一样的条例，一样的典章。”"},
{volumn:         4,chapter:        15,versenumber:        17,verse:"耶和华对摩西说："},
{volumn:         4,chapter:        15,versenumber:        18,verse:"“你晓谕以色列人说：你们到了我所领你们进去的那地，"},
{volumn:         4,chapter:        15,versenumber:        19,verse:"吃那地的粮食，就要把举祭献给耶和华。"},
{volumn:         4,chapter:        15,versenumber:        20,verse:"你们要用初熟的麦子磨面，做饼当举祭奉献；你们举上，好像举禾场的举祭一样。"},
{volumn:         4,chapter:        15,versenumber:        21,verse:"你们世世代代要用初熟的麦子磨面，当举祭献给耶和华。"},
{volumn:         4,chapter:        15,versenumber:        22,verse:"“你们有错误的时候，不守耶和华所晓谕摩西的这一切命令，"},
{volumn:         4,chapter:        15,versenumber:        23,verse:"就是耶和华藉摩西一切所吩咐你们的，自那日以至你们的世世代代，"},
{volumn:         4,chapter:        15,versenumber:        24,verse:"若有误行，是会众所不知道的，后来全会众就要将一只公牛犊作燔祭，并照典章把素祭和奠祭一同献给耶和华为馨香之祭，又献一只公山羊作赎罪祭。"},
{volumn:         4,chapter:        15,versenumber:        25,verse:"祭司要为以色列全会众赎罪，他们就必蒙赦免，因为这是错误。他们又因自己的错误，把供物，就是向耶和华献的火祭和赎罪祭，一并奉到耶和华面前。"},
{volumn:         4,chapter:        15,versenumber:        26,verse:"以色列全会众和寄居在他们中间的外人就必蒙赦免，因为这罪是百姓误犯的。"},
{volumn:         4,chapter:        15,versenumber:        27,verse:"“若有一个人误犯了罪，他就要献一岁的母山羊作赎罪祭。"},
{volumn:         4,chapter:        15,versenumber:        28,verse:"那误行的人犯罪的时候，祭司要在耶和华面前为他赎罪，他就必蒙赦免。"},
{volumn:         4,chapter:        15,versenumber:        29,verse:"以色列中的本地人和寄居在他们中间的外人，若误行了什么事，必归一样的条例。"},
{volumn:         4,chapter:        15,versenumber:        30,verse:"但那擅敢行事的，无论是本地人是寄居的，他亵渎了耶和华，必从民中剪除。"},
{volumn:         4,chapter:        15,versenumber:        31,verse:"因他藐视耶和华的言语，违背耶和华的命令，那人总要剪除；他的罪孽要归到他身上。”"},
{volumn:         4,chapter:        15,versenumber:        32,verse:"以色列人在旷野的时候，遇见一个人在安息日捡柴。"},
{volumn:         4,chapter:        15,versenumber:        33,verse:"遇见他捡柴的人，就把他带到摩西、亚伦并全会众那里，"},
{volumn:         4,chapter:        15,versenumber:        34,verse:"将他收在监内；因为当怎样办他，还没有指明。"},
{volumn:         4,chapter:        15,versenumber:        35,verse:"耶和华吩咐摩西说：“总要把那人治死；全会众要在营外用石头把他打死。”"},
{volumn:         4,chapter:        15,versenumber:        36,verse:"于是全会众将他带到营外，用石头打死他，是照耶和华所吩咐摩西的。"},
{volumn:         4,chapter:        15,versenumber:        37,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        15,versenumber:        38,verse:"“你吩咐以色列人，叫他们世世代代在衣服边上做繸子，又在底边的繸子上钉一根蓝细带子。"},
{volumn:         4,chapter:        15,versenumber:        39,verse:"你们佩带这繸子，好叫你们看见就记念遵行耶和华一切的命令，不随从自己的心意、眼目行邪淫，像你们素常一样；"},
{volumn:         4,chapter:        15,versenumber:        40,verse:"使你们记念遵行我一切的命令，成为圣洁，归与你们的　神。"},
{volumn:         4,chapter:        15,versenumber:        41,verse:"“我是耶和华你们的　神，曾把你们从埃及地领出来，要作你们的　神。我是耶和华你们的　神。”"},
{volumn:         4,chapter:        16,versenumber:         1,verse:"利未的曾孙、哥辖的孙子、以斯哈的儿子可拉，和流便子孙中以利押的儿子大坍、亚比兰，与比勒的儿子安，"},
{volumn:         4,chapter:        16,versenumber:         2,verse:"并以色列会中的二百五十个首领，就是有名望选入会中的人，在摩西面前一同起来，"},
{volumn:         4,chapter:        16,versenumber:         3,verse:"聚集攻击摩西、亚伦，说：“你们擅自专权！全会众个个既是圣洁，耶和华也在他们中间，你们为什么自高，超过耶和华的会众呢？”"},
{volumn:         4,chapter:        16,versenumber:         4,verse:"摩西听见这话就俯伏在地，"},
{volumn:         4,chapter:        16,versenumber:         5,verse:"对可拉和他一党的人说：“到了早晨，耶和华必指示谁是属他的，谁是圣洁的，就叫谁亲近他；他所拣选的是谁，必叫谁亲近他。"},
{volumn:         4,chapter:        16,versenumber:         6,verse:"可拉啊，你们要这样行，你和你的一党要拿香炉来。"},
{volumn:         4,chapter:        16,versenumber:         7,verse:"明日，在耶和华面前，把火盛在炉中，把香放在其上。耶和华拣选谁，谁就为圣洁。你们这利未的子孙擅自专权了！”"},
{volumn:         4,chapter:        16,versenumber:         8,verse:"摩西又对可拉说：“利未的子孙哪，你们听我说！"},
{volumn:         4,chapter:        16,versenumber:         9,verse:"以色列的　神从以色列会中将你们分别出来，使你们亲近他，办耶和华帐幕的事，并站在会众面前替他们当差。"},
{volumn:         4,chapter:        16,versenumber:        10,verse:"耶和华又使你和你一切弟兄利未的子孙一同亲近他，这岂为小事？你们还要求祭司的职任吗？"},
{volumn:         4,chapter:        16,versenumber:        11,verse:"你和你一党的人聚集是要攻击耶和华。亚伦算什么，你们竟向他发怨言呢？”"},
{volumn:         4,chapter:        16,versenumber:        12,verse:"摩西打发人去召以利押的儿子大坍、亚比兰。他们说：“我们不上去！"},
{volumn:         4,chapter:        16,versenumber:        13,verse:"你将我们从流奶与蜜之地领上来，要在旷野杀我们，这岂为小事？你还要自立为王辖管我们吗？"},
{volumn:         4,chapter:        16,versenumber:        14,verse:"并且你没有将我们领到流奶与蜜之地，也没有把田地和葡萄园给我们为业。难道你要剜这些人的眼睛吗？我们不上去！”"},
{volumn:         4,chapter:        16,versenumber:        15,verse:"摩西就甚发怒，对耶和华说：“求你不要享受他们的供物。我并没有夺过他们一匹驴，也没有害过他们一个人。”"},
{volumn:         4,chapter:        16,versenumber:        16,verse:"摩西对可拉说：“明天，你和你一党的人，并亚伦，都要站在耶和华面前；"},
{volumn:         4,chapter:        16,versenumber:        17,verse:"各人要拿一个香炉，共二百五十个，把香放在上面，到耶和华面前。你和亚伦也各拿自己的香炉。”"},
{volumn:         4,chapter:        16,versenumber:        18,verse:"于是他们各人拿一个香炉，盛上火，加上香，同摩西、亚伦站在会幕门前。"},
{volumn:         4,chapter:        16,versenumber:        19,verse:"可拉招聚全会众到会幕门前，要攻击摩西、亚伦；耶和华的荣光就向全会众显现。"},
{volumn:         4,chapter:        16,versenumber:        20,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         4,chapter:        16,versenumber:        21,verse:"“你们离开这会众，我好在转眼之间把他们灭绝。”"},
{volumn:         4,chapter:        16,versenumber:        22,verse:"摩西、亚伦就俯伏在地，说：“　神，万人之灵的　神啊，一人犯罪，你就要向全会众发怒吗？”"},
{volumn:         4,chapter:        16,versenumber:        23,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        16,versenumber:        24,verse:"“你吩咐会众说：‘你们离开可拉、大坍、亚比兰帐棚的四围。’”"},
{volumn:         4,chapter:        16,versenumber:        25,verse:"摩西起来，往大坍、亚比兰那里去；以色列的长老也随着他去。"},
{volumn:         4,chapter:        16,versenumber:        26,verse:"他吩咐会众说：“你们离开这恶人的帐棚吧，他们的物件，什么都不可摸，恐怕你们陷在他们的罪中，与他们一同消灭。”"},
{volumn:         4,chapter:        16,versenumber:        27,verse:"于是众人离开可拉、大坍、亚比兰帐棚的四围。大坍、亚比兰带着妻子、儿女、小孩子，都出来，站在自己的帐棚门口。"},
{volumn:         4,chapter:        16,versenumber:        28,verse:"摩西说：“我行的这一切事本不是凭我自己心意行的，乃是耶和华打发我行的，必有证据使你们知道。"},
{volumn:         4,chapter:        16,versenumber:        29,verse:"这些人死若与世人无异，或是他们所遭的与世人相同，就不是耶和华打发我来的。"},
{volumn:         4,chapter:        16,versenumber:        30,verse:"倘若耶和华创作一件新事，使地开口，把他们和一切属他们的都吞下去，叫他们活活地坠落阴间，你们就明白这些人是藐视耶和华了。”"},
{volumn:         4,chapter:        16,versenumber:        31,verse:"摩西刚说完了这一切话，他们脚下的地就开了口，"},
{volumn:         4,chapter:        16,versenumber:        32,verse:"把他们和他们的家眷，并一切属可拉的人丁、财物，都吞下去。"},
{volumn:         4,chapter:        16,versenumber:        33,verse:"这样，他们和一切属他们的，都活活地坠落阴间；地口在他们上头照旧合闭，他们就从会中灭亡。"},
{volumn:         4,chapter:        16,versenumber:        34,verse:"在他们四围的以色列众人听他们呼号，就都逃跑，说：“恐怕地也把我们吞下去。”"},
{volumn:         4,chapter:        16,versenumber:        35,verse:"又有火从耶和华那里出来，烧灭了那献香的二百五十个人。"},
{volumn:         4,chapter:        16,versenumber:        36,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        16,versenumber:        37,verse:"“你吩咐祭司亚伦的儿子以利亚撒从火中捡起那些香炉来，把火撒在别处，因为那些香炉是圣的。"},
{volumn:         4,chapter:        16,versenumber:        38,verse:"把那些犯罪、自害己命之人的香炉，叫人锤成片子，用以包坛。那些香炉本是他们在耶和华面前献过的，所以是圣的，并且可以给以色列人作记号。”"},
{volumn:         4,chapter:        16,versenumber:        39,verse:"于是祭司以利亚撒将被烧之人所献的铜香炉拿来，人就锤出来，用以包坛，"},
{volumn:         4,chapter:        16,versenumber:        40,verse:"给以色列人作纪念，使亚伦后裔之外的人不得近前来在耶和华面前烧香，免得他遭可拉和他一党所遭的。这乃是照耶和华藉着摩西所吩咐的。"},
{volumn:         4,chapter:        16,versenumber:        41,verse:"第二天，以色列全会众都向摩西、亚伦发怨言说：“你们杀了耶和华的百姓了。”"},
{volumn:         4,chapter:        16,versenumber:        42,verse:"会众聚集攻击摩西、亚伦的时候，向会幕观看，不料，有云彩遮盖了，耶和华的荣光显现。"},
{volumn:         4,chapter:        16,versenumber:        43,verse:"摩西、亚伦就来到会幕前。"},
{volumn:         4,chapter:        16,versenumber:        44,verse:"耶和华吩咐摩西说："},
{volumn:         4,chapter:        16,versenumber:        45,verse:"“你们离开这会众，我好在转眼之间把他们灭绝。”他们二人就俯伏于地。"},
{volumn:         4,chapter:        16,versenumber:        46,verse:"摩西对亚伦说：“拿你的香炉，把坛上的火盛在其中，又加上香，快快带到会众那里，为他们赎罪；因为有忿怒从耶和华那里出来，瘟疫已经发作了。”"},
{volumn:         4,chapter:        16,versenumber:        47,verse:"亚伦照着摩西所说的拿来，跑到会中，不料，瘟疫在百姓中已经发作了。他就加上香，为百姓赎罪。"},
{volumn:         4,chapter:        16,versenumber:        48,verse:"他站在活人死人中间，瘟疫就止住了。"},
{volumn:         4,chapter:        16,versenumber:        49,verse:"除了因可拉事情死的以外，遭瘟疫死的，共有一万四千七百人。"},
{volumn:         4,chapter:        16,versenumber:        50,verse:"亚伦回到会幕门口，到摩西那里，瘟疫已经止住了。"},
{volumn:         4,chapter:        17,versenumber:         1,verse:"耶和华对摩西说："},
{volumn:         4,chapter:        17,versenumber:         2,verse:"“你晓谕以色列人，从他们手下取杖，每支派一根；从他们所有的首领，按着支派，共取十二根。你要将各人的名字写在各人的杖上，"},
{volumn:         4,chapter:        17,versenumber:         3,verse:"并要将亚伦的名字写在利未的杖上，因为各族长必有一根杖。"},
{volumn:         4,chapter:        17,versenumber:         4,verse:"你要把这些杖存在会幕内法柜前，就是我与你们相会之处。"},
{volumn:         4,chapter:        17,versenumber:         5,verse:"后来我所拣选的那人，他的杖必发芽。这样，我必使以色列人向你们所发的怨言止息，不再达到我耳中。”"},
{volumn:         4,chapter:        17,versenumber:         6,verse:"于是摩西晓谕以色列人，他们的首领就把杖交给他，按着支派，每首领一根，共有十二根；亚伦的杖也在其中。"},
{volumn:         4,chapter:        17,versenumber:         7,verse:"摩西就把杖存在法柜的帐幕内，在耶和华面前。"},
{volumn:         4,chapter:        17,versenumber:         8,verse:"第二天，摩西进法柜的帐幕去。谁知利未族亚伦的杖已经发了芽，生了花苞，开了花，结了熟杏。"},
{volumn:         4,chapter:        17,versenumber:         9,verse:"摩西就把所有的杖从耶和华面前拿出来，给以色列众人看；他们看见了，各首领就把自己的杖拿去。"},
{volumn:         4,chapter:        17,versenumber:        10,verse:"耶和华吩咐摩西说：“把亚伦的杖还放在法柜前，给这些背叛之子留作记号。这样，你就使他们向我发的怨言止息，免得他们死亡。”"},
{volumn:         4,chapter:        17,versenumber:        11,verse:"摩西就这样行。耶和华怎样吩咐他，他就怎样行了。"},
{volumn:         4,chapter:        17,versenumber:        12,verse:"以色列人对摩西说：“我们死啦！我们灭亡啦！都灭亡啦！"},
{volumn:         4,chapter:        17,versenumber:        13,verse:"凡挨近耶和华帐幕的是必死的。我们都要死亡吗？”"},
{volumn:         4,chapter:        18,versenumber:         1,verse:"耶和华对亚伦说：“你和你的儿子，并你本族的人，要一同担当干犯圣所的罪孽。你和你的儿子也要一同担当干犯祭司职任的罪孽。"},
{volumn:         4,chapter:        18,versenumber:         2,verse:"你要带你弟兄利未人，就是你祖宗支派的人前来，使他们与你联合，服侍你，只是你和你的儿子，要一同在法柜的帐幕前供职。"},
{volumn:         4,chapter:        18,versenumber:         3,verse:"他们要守所吩咐你的，并守全帐幕，只是不可挨近圣所的器具和坛，免得他们和你们都死亡。"},
{volumn:         4,chapter:        18,versenumber:         4,verse:"他们要与你联合，也要看守会幕，办理帐幕一切的事，只是外人不可挨近你们。"},
{volumn:         4,chapter:        18,versenumber:         5,verse:"你们要看守圣所和坛，免得忿怒再临到以色列人。"},
{volumn:         4,chapter:        18,versenumber:         6,verse:"我已将你们的弟兄利未人从以色列人中拣选出来归耶和华，是给你们为赏赐的，为要办理会幕的事。"},
{volumn:         4,chapter:        18,versenumber:         7,verse:"你和你的儿子要为一切属坛和幔子内的事一同守祭司的职任。你们要这样供职；我将祭司的职任给你们当作赏赐侍奉我。凡挨近的外人必被治死。”"},
{volumn:         4,chapter:        18,versenumber:         8,verse:"耶和华晓谕亚伦说：“我已将归我的举祭，就是以色列人一切分别为圣的物，交给你经管；因你受过膏，把这些都赐给你和你的子孙，当作永得的份。"},
{volumn:         4,chapter:        18,versenumber:         9,verse:"以色列人归给我至圣的供物，就是一切的素祭、赎罪祭、赎愆祭，其中所有存留不经火的，都为至圣之物，要归给你和你的子孙。"},
{volumn:         4,chapter:        18,versenumber:        10,verse:"你要拿这些当至圣物吃；凡男丁都可以吃。你当以此物为圣。"},
{volumn:         4,chapter:        18,versenumber:        11,verse:"以色列人所献的举祭并摇祭都是你的；我已赐给你和你的儿女，当作永得的份；凡在你家中的洁净人都可以吃。"},
{volumn:         4,chapter:        18,versenumber:        12,verse:"凡油中、新酒中、五谷中至好的，就是以色列人所献给耶和华初熟之物，我都赐给你。"},
{volumn:         4,chapter:        18,versenumber:        13,verse:"凡从他们地上所带来给耶和华初熟之物也都要归与你。你家中的洁净人都可以吃。"},
{volumn:         4,chapter:        18,versenumber:        14,verse:"以色列中一切永献的都必归与你。"},
{volumn:         4,chapter:        18,versenumber:        15,verse:"他们所有奉给耶和华的，连人带牲畜，凡头生的，都要归给你；只是人头生的，总要赎出来；不洁净牲畜头生的，也要赎出来。"},
{volumn:         4,chapter:        18,versenumber:        16,verse:"其中在一月之外所当赎的，要照你所估定的价，按圣所的平，用银子五舍客勒赎出来（一舍客勒是二十季拉）。"},
{volumn:         4,chapter:        18,versenumber:        17,verse:"只是头生的牛，或是头生的绵羊和山羊，必不可赎，都是圣的，要把它的血洒在坛上，把它的脂油焚烧，当作馨香的火祭献给耶和华。"},
{volumn:         4,chapter:        18,versenumber:        18,verse:"它的肉必归你，像被摇的胸、被举的右腿归你一样。"},
{volumn:         4,chapter:        18,versenumber:        19,verse:"凡以色列人所献给耶和华圣物中的举祭，我都赐给你和你的儿女，当作永得的份。这是给你和你的后裔、在耶和华面前作为永远的盐约（盐就是不废坏的意思）。”"},
{volumn:         4,chapter:        18,versenumber:        20,verse:"耶和华对亚伦说：“你在以色列人的境内不可有产业，在他们中间也不可有份。我就是你的份，是你的产业。”"},
{volumn:         4,chapter:        18,versenumber:        21,verse:"“凡以色列中出产的十分之一，我已赐给利未的子孙为业；因他们所办的是会幕的事，所以赐给他们为酬他们的劳。"},
{volumn:         4,chapter:        18,versenumber:        22,verse:"从今以后，以色列人不可挨近会幕，免得他们担罪而死。"},
{volumn:         4,chapter:        18,versenumber:        23,verse:"惟独利未人要办会幕的事，担当罪孽；这要作你们世世代代永远的定例。他们在以色列人中不可有产业；"},
{volumn:         4,chapter:        18,versenumber:        24,verse:"因为以色列人中出产的十分之一，就是献给耶和华为举祭的，我已赐给利未人为业。所以我对他们说：‘在以色列人中不可有产业。’”"},
{volumn:         4,chapter:        18,versenumber:        25,verse:"耶和华吩咐摩西说："},
{volumn:         4,chapter:        18,versenumber:        26,verse:"“你晓谕利未人说：你们从以色列人中所取的十分之一，就是我给你们为业的，要再从那十分之一中取十分之一作为举祭献给耶和华，"},
{volumn:         4,chapter:        18,versenumber:        27,verse:"这举祭要算为你们场上的谷，又如满酒榨的酒。"},
{volumn:         4,chapter:        18,versenumber:        28,verse:"这样，你们从以色列人中所得的十分之一也要作举祭献给耶和华，从这十分之一中，将所献给耶和华的举祭归给祭司亚伦。"},
{volumn:         4,chapter:        18,versenumber:        29,verse:"奉给你们的一切礼物，要从其中将至好的，就是分别为圣的，献给耶和华为举祭。"},
{volumn:         4,chapter:        18,versenumber:        30,verse:"所以你要对利未人说：你们从其中将至好的举起，这就算为你们场上的粮，又如酒榨的酒。"},
{volumn:         4,chapter:        18,versenumber:        31,verse:"你们和你们家属随处可以吃；这原是你们的赏赐，是酬你们在会幕里办事的劳。"},
{volumn:         4,chapter:        18,versenumber:        32,verse:"你们从其中将至好的举起，就不至因这物担罪。你们不可亵渎以色列人的圣物，免得死亡。”"},
{volumn:         4,chapter:        19,versenumber:         1,verse:"耶和华晓谕摩西、亚伦说："},
{volumn:         4,chapter:        19,versenumber:         2,verse:"“耶和华命定律法中的一条律例乃是这样说：你要吩咐以色列人，把一只没有残疾、未曾负轭、纯红的母牛牵到你这里来，"},
{volumn:         4,chapter:        19,versenumber:         3,verse:"交给祭司以利亚撒；他必牵到营外，人就把牛宰在他面前。"},
{volumn:         4,chapter:        19,versenumber:         4,verse:"祭司以利亚撒要用指头蘸这牛的血，向会幕前面弹七次。"},
{volumn:         4,chapter:        19,versenumber:         5,verse:"人要在他眼前把这母牛焚烧；牛的皮、肉、血、粪都要焚烧。"},
{volumn:         4,chapter:        19,versenumber:         6,verse:"祭司要把香柏木、牛膝草、朱红色线都丢在烧牛的火中。"},
{volumn:         4,chapter:        19,versenumber:         7,verse:"祭司必不洁净到晚上，要洗衣服，用水洗身，然后可以进营。"},
{volumn:         4,chapter:        19,versenumber:         8,verse:"烧牛的人必不洁净到晚上，也要洗衣服，用水洗身。"},
{volumn:         4,chapter:        19,versenumber:         9,verse:"必有一个洁净的人收起母牛的灰，存在营外洁净的地方，为以色列会众调做除污秽的水。这本是除罪的。"},
{volumn:         4,chapter:        19,versenumber:        10,verse:"收起母牛灰的人必不洁净到晚上，要洗衣服。这要给以色列人和寄居在他们中间的外人作为永远的定例。”"},
{volumn:         4,chapter:        19,versenumber:        11,verse:"“摸了人死尸的，就必七天不洁净。"},
{volumn:         4,chapter:        19,versenumber:        12,verse:"那人到第三天要用这除污秽的水洁净自己，第七天就洁净了。他若在第三天不洁净自己，第七天就不洁净了。"},
{volumn:         4,chapter:        19,versenumber:        13,verse:"凡摸了人死尸、不洁净自己的，就玷污了耶和华的帐幕，这人必从以色列中剪除；因为那除污秽的水没有洒在他身上，他就为不洁净，污秽还在他身上。"},
{volumn:         4,chapter:        19,versenumber:        14,verse:"“人死在帐棚里的条例乃是这样：凡进那帐棚的，和一切在帐棚里的，都必七天不洁净。"},
{volumn:         4,chapter:        19,versenumber:        15,verse:"凡敞口的器皿，就是没有扎上盖的，也是不洁净。"},
{volumn:         4,chapter:        19,versenumber:        16,verse:"无论何人在田野里摸了被刀杀的，或是尸首，或是人的骨头，或是坟墓，就要七天不洁净。"},
{volumn:         4,chapter:        19,versenumber:        17,verse:"要为这不洁净的人拿些烧成的除罪灰放在器皿里，倒上活水。"},
{volumn:         4,chapter:        19,versenumber:        18,verse:"必当有一个洁净的人拿牛膝草蘸在这水中，把水洒在帐棚上，和一切器皿并帐棚内的众人身上，又洒在摸了骨头，或摸了被杀的，或摸了自死的，或摸了坟墓的那人身上。"},
{volumn:         4,chapter:        19,versenumber:        19,verse:"第三天和第七天，洁净的人要洒水在不洁净的人身上，第七天就使他成为洁净。那人要洗衣服，用水洗澡，到晚上就洁净了。"},
{volumn:         4,chapter:        19,versenumber:        20,verse:"“但那污秽而不洁净自己的，要将他从会中剪除，因为他玷污了耶和华的圣所。除污秽的水没有洒在他身上，他是不洁净的。"},
{volumn:         4,chapter:        19,versenumber:        21,verse:"这要给你们作为永远的定例。并且那洒除污秽水的人要洗衣服。凡摸除污秽水的，必不洁净到晚上。"},
{volumn:         4,chapter:        19,versenumber:        22,verse:"不洁净人所摸的一切物就不洁净；摸了这物的人必不洁净到晚上。”"},
{volumn:         4,chapter:        20,versenumber:         1,verse:"正月间，以色列全会众到了寻的旷野，就住在加低斯。米利暗死在那里，就葬在那里。"},
{volumn:         4,chapter:        20,versenumber:         2,verse:"会众没有水喝，就聚集攻击摩西、亚伦。"},
{volumn:         4,chapter:        20,versenumber:         3,verse:"百姓向摩西争闹说：“我们的弟兄曾死在耶和华面前，我们恨不得与他们同死。"},
{volumn:         4,chapter:        20,versenumber:         4,verse:"你们为何把耶和华的会众领到这旷野、使我们和牲畜都死在这里呢？"},
{volumn:         4,chapter:        20,versenumber:         5,verse:"你们为何逼着我们出埃及、领我们到这坏地方呢？这地方不好撒种，也没有无花果树、葡萄树、石榴树，又没有水喝。”"},
{volumn:         4,chapter:        20,versenumber:         6,verse:"摩西、亚伦离开会众，到会幕门口，俯伏在地；耶和华的荣光向他们显现。"},
{volumn:         4,chapter:        20,versenumber:         7,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        20,versenumber:         8,verse:"“你拿着杖去，和你的哥哥亚伦招聚会众，在他们眼前吩咐磐石发出水来，水就从磐石流出，给会众和他们的牲畜喝。”"},
{volumn:         4,chapter:        20,versenumber:         9,verse:"于是摩西照耶和华所吩咐的，从耶和华面前取了杖去。"},
{volumn:         4,chapter:        20,versenumber:        10,verse:"摩西、亚伦就招聚会众到磐石前。摩西说：“你们这些背叛的人听我说：我为你们使水从这磐石中流出来吗？”"},
{volumn:         4,chapter:        20,versenumber:        11,verse:"摩西举手，用杖击打磐石两下，就有许多水流出来，会众和他们的牲畜都喝了。"},
{volumn:         4,chapter:        20,versenumber:        12,verse:"耶和华对摩西、亚伦说：“因为你们不信我，不在以色列人眼前尊我为圣，所以你们必不得领这会众进我所赐给他们的地去。”"},
{volumn:         4,chapter:        20,versenumber:        13,verse:"这水名叫米利巴水（米利巴就是争闹的意思），是因以色列人向耶和华争闹，耶和华就在他们面前显为圣。"},
{volumn:         4,chapter:        20,versenumber:        14,verse:"摩西从加低斯差遣使者去见以东王，说：“你的弟兄以色列人这样说：‘我们所遭遇的一切艰难，"},
{volumn:         4,chapter:        20,versenumber:        15,verse:"就是我们的列祖下到埃及，我们在埃及久住；埃及人恶待我们的列祖和我们，"},
{volumn:         4,chapter:        20,versenumber:        16,verse:"我们哀求耶和华的时候，他听了我们的声音，差遣使者把我们从埃及领出来。这事你都知道。如今，我们在你边界上的城加低斯。"},
{volumn:         4,chapter:        20,versenumber:        17,verse:"求你容我们从你的地经过。我们不走田间和葡萄园，也不喝井里的水，只走大道（原文是王道），不偏左右，直到过了你的境界。’”"},
{volumn:         4,chapter:        20,versenumber:        18,verse:"以东王说：“你不可从我的地经过，免得我带刀出去攻击你。”"},
{volumn:         4,chapter:        20,versenumber:        19,verse:"以色列人说：“我们要走大道上去；我们和牲畜若喝你的水，必给你价值。不求别的，只求你容我们步行过去。”"},
{volumn:         4,chapter:        20,versenumber:        20,verse:"以东王说：“你们不可经过！”就率领许多人出来，要用强硬的手攻击以色列人。"},
{volumn:         4,chapter:        20,versenumber:        21,verse:"这样，以东王不肯容以色列人从他的境界过去。于是他们转去，离开他。"},
{volumn:         4,chapter:        20,versenumber:        22,verse:"以色列全会众从加低斯起行，到了何珥山。"},
{volumn:         4,chapter:        20,versenumber:        23,verse:"耶和华在附近以东边界的何珥山上晓谕摩西、亚伦说："},
{volumn:         4,chapter:        20,versenumber:        24,verse:"“亚伦要归到他列祖（原文是本民）那里。他必不得入我所赐给以色列人的地；因为在米利巴水，你们违背了我的命。"},
{volumn:         4,chapter:        20,versenumber:        25,verse:"你带亚伦和他的儿子以利亚撒上何珥山，"},
{volumn:         4,chapter:        20,versenumber:        26,verse:"把亚伦的圣衣脱下来，给他的儿子以利亚撒穿上；亚伦必死在那里，归他列祖。”"},
{volumn:         4,chapter:        20,versenumber:        27,verse:"摩西就照耶和华所吩咐的行。三人当着会众的眼前上了何珥山。"},
{volumn:         4,chapter:        20,versenumber:        28,verse:"摩西把亚伦的圣衣脱下来，给他的儿子以利亚撒穿上，亚伦就死在山顶那里。于是摩西和以利亚撒下了山。"},
{volumn:         4,chapter:        20,versenumber:        29,verse:"全会众，就是以色列全家，见亚伦已经死了，便都为亚伦哀哭了三十天。"},
{volumn:         4,chapter:        21,versenumber:         1,verse:"住南地的迦南人亚拉得王，听说以色列人从亚他林路来，就和以色列人争战，掳了他们几个人。"},
{volumn:         4,chapter:        21,versenumber:         2,verse:"以色列人向耶和华发愿说：“你若将这民交付我手，我就把他们的城邑尽行毁灭。”"},
{volumn:         4,chapter:        21,versenumber:         3,verse:"耶和华应允了以色列人，把迦南人交付他们，他们就把迦南人和迦南人的城邑尽行毁灭。那地方的名便叫何珥玛（就是毁灭的意思）。"},
{volumn:         4,chapter:        21,versenumber:         4,verse:"他们从何珥山起行，往红海那条路走，要绕过以东地。百姓因这路难行，心中甚是烦躁，"},
{volumn:         4,chapter:        21,versenumber:         5,verse:"就怨讟　神和摩西说：“你们为什么把我们从埃及领出来、使我们死在旷野呢？这里没有粮，没有水，我们的心厌恶这淡薄的食物。”"},
{volumn:         4,chapter:        21,versenumber:         6,verse:"于是耶和华使火蛇进入百姓中间，蛇就咬他们。以色列人中死了许多。"},
{volumn:         4,chapter:        21,versenumber:         7,verse:"百姓到摩西那里，说：“我们怨讟耶和华和你，有罪了。求你祷告耶和华，叫这些蛇离开我们。”于是摩西为百姓祷告。"},
{volumn:         4,chapter:        21,versenumber:         8,verse:"耶和华对摩西说：“你制造一条火蛇，挂在杆子上；凡被咬的，一望这蛇，就必得活。”"},
{volumn:         4,chapter:        21,versenumber:         9,verse:"摩西便制造一条铜蛇，挂在杆子上；凡被蛇咬的，一望这铜蛇就活了。"},
{volumn:         4,chapter:        21,versenumber:        10,verse:"以色列人起行，安营在阿伯。"},
{volumn:         4,chapter:        21,versenumber:        11,verse:"又从阿伯起行，安营在以耶·亚巴琳，与摩押相对的旷野，向日出之地。"},
{volumn:         4,chapter:        21,versenumber:        12,verse:"从那里起行，安营在撒烈谷。"},
{volumn:         4,chapter:        21,versenumber:        13,verse:"从那里起行，安营在亚嫩河那边。这亚嫩河是在旷野，从亚摩利的境界流出来的；原来亚嫩河是摩押的边界，在摩押和亚摩利人搭界的地方。"},
{volumn:         4,chapter:        21,versenumber:        14,verse:"所以耶和华的战记上说：“苏法的哇哈伯与亚嫩河的谷，"},
{volumn:         4,chapter:        21,versenumber:        15,verse:"并向亚珥城众谷的下坡，是靠近摩押的境界。”"},
{volumn:         4,chapter:        21,versenumber:        16,verse:"以色列人从那里起行，到了比珥（就是井的意思）。从前耶和华吩咐摩西说：“招聚百姓，我好给他们水喝”，说的就是这井。"},
{volumn:         4,chapter:        21,versenumber:        17,verse:"当时，以色列人唱歌说：“井啊，涌上水来！你们要向这井歌唱。"},
{volumn:         4,chapter:        21,versenumber:        18,verse:"这井是首领和民中的尊贵人用圭用杖所挖所掘的。”以色列人从旷野往玛他拿去;"},
{volumn:         4,chapter:        21,versenumber:        19,verse:"从玛他拿到拿哈列，从拿哈列到巴末，"},
{volumn:         4,chapter:        21,versenumber:        20,verse:"从巴末到摩押地的谷，又到那下望旷野之毗斯迦的山顶。"},
{volumn:         4,chapter:        21,versenumber:        21,verse:"以色列人差遣使者去见亚摩利人的王西宏，说："},
{volumn:         4,chapter:        21,versenumber:        22,verse:"“求你容我们从你的地经过；我们不偏入田间和葡萄园，也不喝井里的水，只走大道（原文是王道），直到过了你的境界。”"},
{volumn:         4,chapter:        21,versenumber:        23,verse:"西宏不容以色列人从他的境界经过，就招聚他的众民出到旷野，要攻击以色列人，到了雅杂与以色列人争战。"},
{volumn:         4,chapter:        21,versenumber:        24,verse:"以色列人用刀杀了他，得了他的地，从亚嫩河到雅博河，直到亚扪人的境界，因为亚扪人的境界多有坚垒。"},
{volumn:         4,chapter:        21,versenumber:        25,verse:"以色列人夺取这一切的城邑，也住亚摩利人的城邑，就是希实本与希实本的一切乡村。"},
{volumn:         4,chapter:        21,versenumber:        26,verse:"这希实本是亚摩利王西宏的京城；西宏曾与摩押的先王争战，从他手中夺取了全地，直到亚嫩河。"},
{volumn:         4,chapter:        21,versenumber:        27,verse:"所以那些作诗歌的说：“你们来到希实本；愿西宏的城被修造，被建立。"},
{volumn:         4,chapter:        21,versenumber:        28,verse:"因为有火从希实本发出，有火焰出于西宏的城，烧尽摩押的亚珥和亚嫩河邱坛的祭司（祭司原文是主）。"},
{volumn:         4,chapter:        21,versenumber:        29,verse:"摩押啊，你有祸了！基抹的民哪，你们灭亡了！基抹的男子逃奔，女子被掳，交付亚摩利的王西宏。"},
{volumn:         4,chapter:        21,versenumber:        30,verse:"我们射了他们；希实本直到底本尽皆毁灭。我们使地变成荒场，直到挪法；这挪法直延到米底巴。”"},
{volumn:         4,chapter:        21,versenumber:        31,verse:"这样，以色列人就住在亚摩利人之地。"},
{volumn:         4,chapter:        21,versenumber:        32,verse:"摩西打发人去窥探雅谢，以色列人就占了雅谢的镇市，赶出那里的亚摩利人。"},
{volumn:         4,chapter:        21,versenumber:        33,verse:"以色列人转回，向巴珊去。巴珊王噩和他的众民都出来，在以得来与他们交战。"},
{volumn:         4,chapter:        21,versenumber:        34,verse:"耶和华对摩西说：“不要怕他！因我已将他和他的众民，并他的地，都交在你手中；你要待他像从前待住希实本的亚摩利王西宏一般。”"},
{volumn:         4,chapter:        21,versenumber:        35,verse:"于是他们杀了他和他的众子，并他的众民，没有留下一个，就得了他的地。"},
{volumn:         4,chapter:        22,versenumber:         1,verse:"以色列人起行，在摩押平原、约旦河东，对着耶利哥安营。"},
{volumn:         4,chapter:        22,versenumber:         2,verse:"以色列人向亚摩利人所行的一切事，西拨的儿子巴勒都看见了。"},
{volumn:         4,chapter:        22,versenumber:         3,verse:"摩押人因以色列民甚多，就大大惧怕，心内忧急，"},
{volumn:         4,chapter:        22,versenumber:         4,verse:"对米甸的长老说：“现在这众人要把我们四围所有的一概舔尽，就如牛舔尽田间的草一般。”那时西拨的儿子巴勒作摩押王。"},
{volumn:         4,chapter:        22,versenumber:         5,verse:"他差遣使者往大河边的毗夺去，到比珥的儿子巴兰本乡那里，召巴兰来，说：“有一宗民从埃及出来，遮满地面，与我对居。"},
{volumn:         4,chapter:        22,versenumber:         6,verse:"这民比我强盛，现在求你来为我咒诅他们，或者我能得胜，攻打他们，赶出此地。因为我知道，你为谁祝福，谁就得福；你咒诅谁，谁就受咒诅。”"},
{volumn:         4,chapter:        22,versenumber:         7,verse:"摩押的长老和米甸的长老手里拿着卦金，到了巴兰那里，将巴勒的话都告诉了他。"},
{volumn:         4,chapter:        22,versenumber:         8,verse:"巴兰说：“你们今夜在这里住宿，我必照耶和华所晓谕我的回报你们。”摩押的使臣就在巴兰那里住下了。"},
{volumn:         4,chapter:        22,versenumber:         9,verse:"神临到巴兰那里，说：“在你这里的人都是谁？”"},
{volumn:         4,chapter:        22,versenumber:        10,verse:"巴兰回答说：“是摩押王西拨的儿子巴勒打发人到我这里来，说："},
{volumn:         4,chapter:        22,versenumber:        11,verse:"‘从埃及出来的民遮满地面，你来为我咒诅他们，或者我能与他们争战，把他们赶出去。’”"},
{volumn:         4,chapter:        22,versenumber:        12,verse:"神对巴兰说：“你不可同他们去，也不可咒诅那民，因为那民是蒙福的。”"},
{volumn:         4,chapter:        22,versenumber:        13,verse:"巴兰早晨起来，对巴勒的使臣说：“你们回本地去吧，因为耶和华不容我和你们同去。”"},
{volumn:         4,chapter:        22,versenumber:        14,verse:"摩押的使臣就起来，回巴勒那里去，说：“巴兰不肯和我们同来。”"},
{volumn:         4,chapter:        22,versenumber:        15,verse:"巴勒又差遣使臣，比先前的又多又尊贵。"},
{volumn:         4,chapter:        22,versenumber:        16,verse:"他们到了巴兰那里，对他说：“西拨的儿子巴勒这样说：‘求你不容什么事拦阻你不到我这里来，"},
{volumn:         4,chapter:        22,versenumber:        17,verse:"因为我必使你得极大的尊荣。你向我要什么，我就给你什么；只求你来为我咒诅这民。’”"},
{volumn:         4,chapter:        22,versenumber:        18,verse:"巴兰回答巴勒的臣仆说：“巴勒就是将他满屋的金银给我，我行大事小事也不得越过耶和华我　神的命。"},
{volumn:         4,chapter:        22,versenumber:        19,verse:"现在我请你们今夜在这里住宿，等我得知耶和华还要对我说什么。”"},
{volumn:         4,chapter:        22,versenumber:        20,verse:"当夜，　神临到巴兰那里，说：“这些人若来召你，你就起来同他们去，你只要遵行我对你所说的话。”"},
{volumn:         4,chapter:        22,versenumber:        21,verse:"巴兰早晨起来，备上驴，和摩押的使臣一同去了。"},
{volumn:         4,chapter:        22,versenumber:        22,verse:"神因他去就发了怒；耶和华的使者站在路上敌挡他。他骑着驴，有两个仆人跟随他。"},
{volumn:         4,chapter:        22,versenumber:        23,verse:"驴看见耶和华的使者站在路上，手里有拔出来的刀，就从路上跨进田间，巴兰便打驴，要叫它回转上路。"},
{volumn:         4,chapter:        22,versenumber:        24,verse:"耶和华的使者就站在葡萄园的窄路上；这边有墙，那边也有墙。"},
{volumn:         4,chapter:        22,versenumber:        25,verse:"驴看见耶和华的使者，就贴靠墙，将巴兰的脚挤伤了；巴兰又打驴。"},
{volumn:         4,chapter:        22,versenumber:        26,verse:"耶和华的使者又往前去，站在狭窄之处，左右都没有转折的地方。"},
{volumn:         4,chapter:        22,versenumber:        27,verse:"驴看见耶和华的使者，就卧在巴兰底下，巴兰发怒，用杖打驴。"},
{volumn:         4,chapter:        22,versenumber:        28,verse:"耶和华叫驴开口，对巴兰说：“我向你行了什么，你竟打我这三次呢？”"},
{volumn:         4,chapter:        22,versenumber:        29,verse:"巴兰对驴说：“因为你戏弄我，我恨不能手中有刀，把你杀了。”"},
{volumn:         4,chapter:        22,versenumber:        30,verse:"驴对巴兰说：“我不是你从小时直到今日所骑的驴吗？我素常向你这样行过吗？”巴兰说：“没有。”"},
{volumn:         4,chapter:        22,versenumber:        31,verse:"当时，耶和华使巴兰的眼目明亮，他就看见耶和华的使者站在路上，手里有拔出来的刀，巴兰便低头俯伏在地。"},
{volumn:         4,chapter:        22,versenumber:        32,verse:"耶和华的使者对他说：“你为何这三次打你的驴呢？我出来敌挡你，因你所行的，在我面前偏僻。"},
{volumn:         4,chapter:        22,versenumber:        33,verse:"驴看见我就三次从我面前偏过去；驴若没有偏过去，我早把你杀了，留它存活。”"},
{volumn:         4,chapter:        22,versenumber:        34,verse:"巴兰对耶和华的使者说：“我有罪了。我不知道你站在路上阻挡我；你若不喜欢我去，我就转回。”"},
{volumn:         4,chapter:        22,versenumber:        35,verse:"耶和华的使者对巴兰说：“你同这些人去吧！你只要说我对你说的话。”于是巴兰同着巴勒的使臣去了。"},
{volumn:         4,chapter:        22,versenumber:        36,verse:"巴勒听见巴兰来了，就往摩押京城去迎接他；这城是在边界上，在亚嫩河旁。"},
{volumn:         4,chapter:        22,versenumber:        37,verse:"巴勒对巴兰说：“我不是急急地打发人到你那里去召你吗？你为何不到我这里来呢？我岂不能使你得尊荣吗？”"},
{volumn:         4,chapter:        22,versenumber:        38,verse:"巴兰说：“我已经到你这里来了！现在我岂能擅自说什么呢？　神将什么话传给我，我就说什么。”"},
{volumn:         4,chapter:        22,versenumber:        39,verse:"巴兰和巴勒同行，来到基列·胡琐。"},
{volumn:         4,chapter:        22,versenumber:        40,verse:"巴勒宰了（原文是献）牛羊，送给巴兰和陪伴的使臣。"},
{volumn:         4,chapter:        22,versenumber:        41,verse:"到了早晨，巴勒领巴兰到巴力的高处；巴兰从那里观看以色列营的边界。"},
{volumn:         4,chapter:        23,versenumber:         1,verse:"巴兰对巴勒说：“你在这里给我筑七座坛，为我预备七只公牛，七只公羊。”"},
{volumn:         4,chapter:        23,versenumber:         2,verse:"巴勒照巴兰的话行了。巴勒和巴兰在每座坛上献一只公牛，一只公羊。"},
{volumn:         4,chapter:        23,versenumber:         3,verse:"巴兰对巴勒说：“你站在你的燔祭旁边，我且往前去，或者耶和华来迎见我。他指示我什么，我必告诉你。”于是巴兰上一净光的高处。"},
{volumn:         4,chapter:        23,versenumber:         4,verse:"神迎见巴兰；巴兰说：“我预备了七座坛，在每座坛上献了一只公牛，一只公羊。”"},
{volumn:         4,chapter:        23,versenumber:         5,verse:"耶和华将话传给巴兰，又说：“你回到巴勒那里，要如此如此说。”"},
{volumn:         4,chapter:        23,versenumber:         6,verse:"他就回到巴勒那里，见他同摩押的使臣都站在燔祭旁边。"},
{volumn:         4,chapter:        23,versenumber:         7,verse:"巴兰便题起诗歌说：“巴勒引我出亚兰，摩押王引我出东山，说：‘来啊，为我咒诅雅各；来啊，怒骂以色列。’"},
{volumn:         4,chapter:        23,versenumber:         8,verse:"神没有咒诅的，我焉能咒诅？耶和华没有怒骂的，我焉能怒骂？"},
{volumn:         4,chapter:        23,versenumber:         9,verse:"我从高峰看他，从小山望他；这是独居的民，不列在万民中。"},
{volumn:         4,chapter:        23,versenumber:        10,verse:"谁能数点雅各的尘土？谁能计算以色列的四分之一？我愿如义人之死而死；我愿如义人之终而终。”"},
{volumn:         4,chapter:        23,versenumber:        11,verse:"巴勒对巴兰说：“你向我做的是什么事呢？我领你来咒诅我的仇敌，不料，你竟为他们祝福。”"},
{volumn:         4,chapter:        23,versenumber:        12,verse:"他回答说：“耶和华传给我的话，我能不谨慎传说吗？”"},
{volumn:         4,chapter:        23,versenumber:        13,verse:"巴勒说：“求你同我往别处去，在那里可以看见他们；你不能全看见，只能看见他们边界上的人。在那里要为我咒诅他们。”"},
{volumn:         4,chapter:        23,versenumber:        14,verse:"于是领巴兰到了琐腓田，上了毗斯迦山顶，筑了七座坛；每座坛上献一只公牛，一只公羊。"},
{volumn:         4,chapter:        23,versenumber:        15,verse:"巴兰对巴勒说：“你站在这燔祭旁边，等我往那边去迎见耶和华。”"},
{volumn:         4,chapter:        23,versenumber:        16,verse:"耶和华临到巴兰那里，将话传给他；又说：“你回到巴勒那里，要如此如此说。”"},
{volumn:         4,chapter:        23,versenumber:        17,verse:"他就回到巴勒那里，见他站在燔祭旁边；摩押的使臣也和他在一处。巴勒问他说：“耶和华说了什么话呢？”"},
{volumn:         4,chapter:        23,versenumber:        18,verse:"巴兰就题诗歌说：“巴勒，你起来听；西拨的儿子，你听我言。"},
{volumn:         4,chapter:        23,versenumber:        19,verse:"神非人，必不致说谎，也非人子，必不致后悔。他说话岂不照着行呢？他发言岂不要成就呢？"},
{volumn:         4,chapter:        23,versenumber:        20,verse:"我奉命祝福；　神也曾赐福，此事我不能翻转。"},
{volumn:         4,chapter:        23,versenumber:        21,verse:"他未见雅各中有罪孽，也未见以色列中有奸恶。耶和华他的　神和他同在；有欢呼王的声音在他们中间。"},
{volumn:         4,chapter:        23,versenumber:        22,verse:"神领他们出埃及；他们似乎有野牛之力。"},
{volumn:         4,chapter:        23,versenumber:        23,verse:"断没有法术可以害雅各，也没有占卜可以害以色列。现在必有人论及雅各，就是论及以色列说：‘神为他行了何等的大事！’"},
{volumn:         4,chapter:        23,versenumber:        24,verse:"这民起来，仿佛母狮，挺身，好像公狮，未曾吃野食，未曾喝被伤者之血，决不躺卧。”"},
{volumn:         4,chapter:        23,versenumber:        25,verse:"巴勒对巴兰说：“你一点不要咒诅他们，也不要为他们祝福。”"},
{volumn:         4,chapter:        23,versenumber:        26,verse:"巴兰回答巴勒说：“我岂不是告诉你说‘凡耶和华所说的，我必须遵行’吗？”"},
{volumn:         4,chapter:        23,versenumber:        27,verse:"巴勒对巴兰说：“来吧，我领你往别处去，或者　神喜欢你在那里为我咒诅他们。”"},
{volumn:         4,chapter:        23,versenumber:        28,verse:"巴勒就领巴兰到那下望旷野的毗珥山顶上。"},
{volumn:         4,chapter:        23,versenumber:        29,verse:"巴兰对巴勒说：“你在这里为我筑七座坛，又在这里为我预备七只公牛，七只公羊。”"},
{volumn:         4,chapter:        23,versenumber:        30,verse:"巴勒就照巴兰的话行，在每座坛上献一只公牛，一只公羊。"},
{volumn:         4,chapter:        24,versenumber:         1,verse:"巴兰见耶和华喜欢赐福与以色列，就不像前两次去求法术，却面向旷野。"},
{volumn:         4,chapter:        24,versenumber:         2,verse:"巴兰举目，看见以色列人照着支派居住。　神的灵就临到他身上，"},
{volumn:         4,chapter:        24,versenumber:         3,verse:"他便题起诗歌说：“比珥的儿子巴兰说，‘眼目闭住（或译：睁开）的人说，"},
{volumn:         4,chapter:        24,versenumber:         4,verse:"得听　神的言语，得见全能者的异象，眼目睁开而仆倒的人说："},
{volumn:         4,chapter:        24,versenumber:         5,verse:"雅各啊，你的帐棚何等华美！以色列啊，你的帐幕何其华丽！"},
{volumn:         4,chapter:        24,versenumber:         6,verse:"如接连的山谷，如河旁的园子，如耶和华所栽的沉香树，如水边的香柏木。"},
{volumn:         4,chapter:        24,versenumber:         7,verse:"水要从他的桶里流出；种子要撒在多水之处。他的王必超过亚甲；他的国必要振兴。"},
{volumn:         4,chapter:        24,versenumber:         8,verse:"神领他出埃及；他似乎有野牛之力。他要吞吃敌国，折断他们的骨头，用箭射透他们。"},
{volumn:         4,chapter:        24,versenumber:         9,verse:"他蹲如公狮，卧如母狮，谁敢惹他？凡给你祝福的，愿他蒙福；凡咒诅你的，愿他受咒诅。’”"},
{volumn:         4,chapter:        24,versenumber:        10,verse:"巴勒向巴兰生气，就拍起手来，对巴兰说：“我召你来为我咒诅仇敌，不料，你这三次竟为他们祝福。"},
{volumn:         4,chapter:        24,versenumber:        11,verse:"如今你快回本地去吧！我想使你得大尊荣，耶和华却阻止你不得尊荣。”"},
{volumn:         4,chapter:        24,versenumber:        12,verse:"巴兰对巴勒说：“我岂不是对你所差遣到我那里的使者说："},
{volumn:         4,chapter:        24,versenumber:        13,verse:"‘巴勒就是将他满屋的金银给我，我也不得越过耶和华的命，凭自己的心意行好行歹。耶和华说什么，我就要说什么’？”"},
{volumn:         4,chapter:        24,versenumber:        14,verse:"“现在我要回本族去。你来，我告诉你这民日后要怎样待你的民。”"},
{volumn:         4,chapter:        24,versenumber:        15,verse:"他就题起诗歌说：“比珥的儿子巴兰说：‘眼目闭住（或译：睁开）的人说，"},
{volumn:         4,chapter:        24,versenumber:        16,verse:"得听　神的言语，明白至高者的意旨，看见全能者的异象，眼目睁开而仆倒的人说："},
{volumn:         4,chapter:        24,versenumber:        17,verse:"我看他却不在现时；我望他却不在近日。有星要出于雅各，有杖要兴于以色列，必打破摩押的四角，毁坏扰乱之子。"},
{volumn:         4,chapter:        24,versenumber:        18,verse:"他必得以东为基业，又得仇敌之地西珥为产业；以色列必行事勇敢。"},
{volumn:         4,chapter:        24,versenumber:        19,verse:"有一位出于雅各的，必掌大权；他要除灭城中的余民。’”"},
{volumn:         4,chapter:        24,versenumber:        20,verse:"巴兰观看亚玛力，就题起诗歌说：“亚玛力原为诸国之首，但他终必沉沦。”"},
{volumn:         4,chapter:        24,versenumber:        21,verse:"巴兰观看基尼人，就题起诗歌说：“你的住处本是坚固；你的窝巢做在岩穴中。"},
{volumn:         4,chapter:        24,versenumber:        22,verse:"然而基尼必至衰微，直到亚述把你掳去。”"},
{volumn:         4,chapter:        24,versenumber:        23,verse:"巴兰又题起诗歌说：“哀哉！　神行这事，谁能得活？"},
{volumn:         4,chapter:        24,versenumber:        24,verse:"必有人乘船从基提界而来，苦害亚述，苦害希伯；他也必至沉沦。”"},
{volumn:         4,chapter:        24,versenumber:        25,verse:"于是巴兰起来，回他本地去；巴勒也回去了。"},
{volumn:         4,chapter:        25,versenumber:         1,verse:"以色列人住在什亭，百姓与摩押女子行起淫乱。"},
{volumn:         4,chapter:        25,versenumber:         2,verse:"因为这女子叫百姓来，一同给她们的神献祭，百姓就吃她们的祭物，跪拜她们的神。"},
{volumn:         4,chapter:        25,versenumber:         3,verse:"以色列人与巴力·毗珥连合，耶和华的怒气就向以色列人发作。"},
{volumn:         4,chapter:        25,versenumber:         4,verse:"耶和华吩咐摩西说：“将百姓中所有的族长在我面前对着日头悬挂，使我向以色列人所发的怒气可以消了。”"},
{volumn:         4,chapter:        25,versenumber:         5,verse:"于是摩西吩咐以色列的审判官说：“凡属你们的人，有与巴力·毗珥连合的，你们各人要把他们杀了。”"},
{volumn:         4,chapter:        25,versenumber:         6,verse:"摩西和以色列全会众正在会幕门前哭泣的时候，谁知，有以色列中的一个人，当他们眼前，带着一个米甸女人到他弟兄那里去。"},
{volumn:         4,chapter:        25,versenumber:         7,verse:"祭司亚伦的孙子，以利亚撒的儿子非尼哈看见了，就从会中起来，手里拿着枪，"},
{volumn:         4,chapter:        25,versenumber:         8,verse:"跟随那以色列人进亭子里去，便将以色列人和那女人由腹中刺透。这样，在以色列人中瘟疫就止息了。"},
{volumn:         4,chapter:        25,versenumber:         9,verse:"那时遭瘟疫死的，有二万四千人。"},
{volumn:         4,chapter:        25,versenumber:        10,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        25,versenumber:        11,verse:"“祭司亚伦的孙子，以利亚撒的儿子非尼哈，使我向以色列人所发的怒消了；因他在他们中间，以我的忌邪为心，使我不在忌邪中把他们除灭。"},
{volumn:         4,chapter:        25,versenumber:        12,verse:"因此，你要说：‘我将我平安的约赐给他。"},
{volumn:         4,chapter:        25,versenumber:        13,verse:"这约要给他和他的后裔，作为永远当祭司职任的约；因他为　神有忌邪的心，为以色列人赎罪。’”"},
{volumn:         4,chapter:        25,versenumber:        14,verse:"那与米甸女人一同被杀的以色列人，名叫心利，是撒路的儿子，是西缅一个宗族的首领。"},
{volumn:         4,chapter:        25,versenumber:        15,verse:"那被杀的米甸女人，名叫哥斯比，是苏珥的女儿；这苏珥是米甸一个宗族的首领。"},
{volumn:         4,chapter:        25,versenumber:        16,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        25,versenumber:        17,verse:"“你要扰害米甸人，击杀他们；"},
{volumn:         4,chapter:        25,versenumber:        18,verse:"因为他们用诡计扰害你们，在毗珥的事上和他们的姐妹、米甸首领的女儿哥斯比的事上，用这诡计诱惑了你们；这哥斯比，当瘟疫流行的日子，因毗珥的事被杀了。”"},
{volumn:         4,chapter:        26,versenumber:         1,verse:"瘟疫之后，耶和华晓谕摩西和祭司亚伦的儿子以利亚撒说："},
{volumn:         4,chapter:        26,versenumber:         2,verse:"“你们要将以色列全会众，按他们的宗族，凡以色列中从二十岁以外、能出去打仗的，计算总数。”"},
{volumn:         4,chapter:        26,versenumber:         3,verse:"摩西和祭司以利亚撒在摩押平原与耶利哥相对的约旦河边向以色列人说："},
{volumn:         4,chapter:        26,versenumber:         4,verse:"“将你们中间从二十岁以外的计算总数”；是照耶和华吩咐出埃及地的摩西和以色列人的话。"},
{volumn:         4,chapter:        26,versenumber:         5,verse:"以色列的长子是流便。流便的众子：属哈诺的，有哈诺族；属法路的，有法路族；"},
{volumn:         4,chapter:        26,versenumber:         6,verse:"属希斯伦的，有希斯伦族；属迦米的，有迦米族；"},
{volumn:         4,chapter:        26,versenumber:         7,verse:"这就是流便的各族；其中被数的，共有四万三千七百三十名。"},
{volumn:         4,chapter:        26,versenumber:         8,verse:"法路的儿子是以利押。"},
{volumn:         4,chapter:        26,versenumber:         9,verse:"以利押的众子是尼母利、大坍、亚比兰。这大坍、亚比兰，就是从会中选召的，与可拉一党同向耶和华争闹的时候也向摩西、亚伦争闹；"},
{volumn:         4,chapter:        26,versenumber:        10,verse:"地便开口吞了他们，和可拉、可拉的党类一同死亡。那时火烧灭了二百五十个人；他们就作了警戒。"},
{volumn:         4,chapter:        26,versenumber:        11,verse:"然而可拉的众子没有死亡。"},
{volumn:         4,chapter:        26,versenumber:        12,verse:"按着家族，西缅的众子：属尼母利的，有尼母利族；属雅悯的，有雅悯族；属雅斤的，有雅斤族；"},
{volumn:         4,chapter:        26,versenumber:        13,verse:"属谢拉的，有谢拉族；属扫罗的，有扫罗族。"},
{volumn:         4,chapter:        26,versenumber:        14,verse:"这就是西缅的各族，共有二万二千二百名。"},
{volumn:         4,chapter:        26,versenumber:        15,verse:"按着家族，迦得的众子：属洗分的，有洗分族；属哈基的，有哈基族；属书尼的，有书尼族；"},
{volumn:         4,chapter:        26,versenumber:        16,verse:"属阿斯尼的，有阿斯尼族；属以利的，有以利族；"},
{volumn:         4,chapter:        26,versenumber:        17,verse:"属亚律的，有亚律族；属亚列利的，有亚列利族。"},
{volumn:         4,chapter:        26,versenumber:        18,verse:"这就是迦得子孙的各族，照他们中间被数的，共有四万零五百名。"},
{volumn:         4,chapter:        26,versenumber:        19,verse:"犹大的儿子是珥和俄南。这珥和俄南死在迦南地。"},
{volumn:         4,chapter:        26,versenumber:        20,verse:"按着家族，犹大其余的众子：属示拉的，有示拉族；属法勒斯的，有法勒斯族；属谢拉的有谢拉族。"},
{volumn:         4,chapter:        26,versenumber:        21,verse:"法勒斯的儿子：属希斯仑的，有希斯仑族；属哈母勒的，有哈母勒族。"},
{volumn:         4,chapter:        26,versenumber:        22,verse:"这就是犹大的各族，照他们中间被数的，共有七万六千五百名。"},
{volumn:         4,chapter:        26,versenumber:        23,verse:"按着家族，以萨迦的众子：属陀拉的，有陀拉族；属普瓦的，有普瓦族；"},
{volumn:         4,chapter:        26,versenumber:        24,verse:"属雅述的，有雅述族；属伸仑的，有伸仑族。"},
{volumn:         4,chapter:        26,versenumber:        25,verse:"这就是以萨迦的各族，照他们中间被数的，共有六万四千三百名。"},
{volumn:         4,chapter:        26,versenumber:        26,verse:"按着家族，西布伦的众子：属西烈的，有西烈族；属以伦的，有以伦族；属雅利的，有雅利族。"},
{volumn:         4,chapter:        26,versenumber:        27,verse:"这就是西布伦的各族，照他们中间被数的，共有六万零五百名。"},
{volumn:         4,chapter:        26,versenumber:        28,verse:"按着家族，约瑟的儿子有玛拿西、以法莲。"},
{volumn:         4,chapter:        26,versenumber:        29,verse:"玛拿西的众子：属玛吉的，有玛吉族；玛吉生基列；属基列的，有基列族。"},
{volumn:         4,chapter:        26,versenumber:        30,verse:"基列的众子：属伊以谢的，有伊以谢族；属希勒的，有希勒族；"},
{volumn:         4,chapter:        26,versenumber:        31,verse:"属亚斯烈的，有亚斯烈族；属示剑的，有示剑族；"},
{volumn:         4,chapter:        26,versenumber:        32,verse:"属示米大的，有示米大族；属希弗的，有希弗族。"},
{volumn:         4,chapter:        26,versenumber:        33,verse:"希弗的儿子：西罗非哈没儿子，只有女儿。西罗非哈女儿的名字就是玛拉、挪阿、曷拉、密迦、得撒。"},
{volumn:         4,chapter:        26,versenumber:        34,verse:"这就是玛拿西的各族；他们中间被数的，共有五万二千七百名。"},
{volumn:         4,chapter:        26,versenumber:        35,verse:"按着家族，以法莲的众子：属书提拉的，有书提拉族；属比结的，有比结族；属他罕的，有他罕族。"},
{volumn:         4,chapter:        26,versenumber:        36,verse:"书提拉的众子：属以兰的，有以兰族。"},
{volumn:         4,chapter:        26,versenumber:        37,verse:"这就是以法莲子孙的各族，照他们中间被数的，共有三万二千五百名。按着家族，这都是约瑟的子孙。"},
{volumn:         4,chapter:        26,versenumber:        38,verse:"按着家族，便雅悯的众子：属比拉的，有比拉族；属亚实别的，有亚实别族；属亚希兰的，有亚希兰族；"},
{volumn:         4,chapter:        26,versenumber:        39,verse:"属书反的，有书反族；属户反的，有户反族。"},
{volumn:         4,chapter:        26,versenumber:        40,verse:"比拉的众子是亚勒、乃幔。属亚勒的，有亚勒族；属乃幔的，有乃幔族。"},
{volumn:         4,chapter:        26,versenumber:        41,verse:"按着家族，这就是便雅悯的子孙，其中被数的，共有四万五千六百名。"},
{volumn:         4,chapter:        26,versenumber:        42,verse:"按着家族，但的众子：属书含的，有书含族。按着家族，这就是但的各族。"},
{volumn:         4,chapter:        26,versenumber:        43,verse:"照其中被数的，书含所有的各族，共有六万四千四百名。"},
{volumn:         4,chapter:        26,versenumber:        44,verse:"按着家族，亚设的众子：属音拿的，有音拿族；属亦施韦的，有亦施韦族；属比利亚的，有比利亚族。"},
{volumn:         4,chapter:        26,versenumber:        45,verse:"比利亚的众子：属希别的，有希别族；属玛结的，有玛结族。"},
{volumn:         4,chapter:        26,versenumber:        46,verse:"亚设的女儿名叫西拉。"},
{volumn:         4,chapter:        26,versenumber:        47,verse:"这就是亚设子孙的各族，照他们中间被数的，共有五万三千四百名。"},
{volumn:         4,chapter:        26,versenumber:        48,verse:"按着家族，拿弗他利的众子：属雅薛的，有雅薛族；属沽尼的，有沽尼族；"},
{volumn:         4,chapter:        26,versenumber:        49,verse:"属耶色的，有耶色族；属示冷的，有示冷族。"},
{volumn:         4,chapter:        26,versenumber:        50,verse:"按着家族，这就是拿弗他利的各族；他们中间被数的，共有四万五千四百名。"},
{volumn:         4,chapter:        26,versenumber:        51,verse:"以色列人中被数的，共有六十万零一千七百三十名。"},
{volumn:         4,chapter:        26,versenumber:        52,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        26,versenumber:        53,verse:"“你要按着人名的数目将地分给这些人为业。"},
{volumn:         4,chapter:        26,versenumber:        54,verse:"人多的，你要把产业多分给他们；人少的，你要把产业少分给他们；要照被数的人数，把产业分给各人。"},
{volumn:         4,chapter:        26,versenumber:        55,verse:"虽是这样，还要拈阄分地。他们要按着祖宗各支派的名字承受为业。"},
{volumn:         4,chapter:        26,versenumber:        56,verse:"要按着所拈的阄，看人数多，人数少，把产业分给他们。”"},
{volumn:         4,chapter:        26,versenumber:        57,verse:"利未人，按着他们的各族被数的：属革顺的，有革顺族；属哥辖的，有哥辖族；属米拉利的，有米拉利族。"},
{volumn:         4,chapter:        26,versenumber:        58,verse:"利未的各族有立尼族、希伯伦族、玛利族、母示族、可拉族。哥辖生暗兰。"},
{volumn:         4,chapter:        26,versenumber:        59,verse:"暗兰的妻名叫约基别，是利未女子，生在埃及。她给暗兰生了亚伦、摩西，并他们的姐姐米利暗。"},
{volumn:         4,chapter:        26,versenumber:        60,verse:"亚伦生拿答、亚比户、以利亚撒、以他玛。"},
{volumn:         4,chapter:        26,versenumber:        61,verse:"拿答、亚比户在耶和华面前献凡火的时候就死了。"},
{volumn:         4,chapter:        26,versenumber:        62,verse:"利未人中，凡一个月以外、被数的男丁，共有二万三千。他们本来没有数在以色列人中；因为在以色列人中，没有分给他们产业。"},
{volumn:         4,chapter:        26,versenumber:        63,verse:"这些就是被摩西和祭司以利亚撒所数的；他们在摩押平原与耶利哥相对的约旦河边数点以色列人。"},
{volumn:         4,chapter:        26,versenumber:        64,verse:"但被数的人中，没有一个是摩西和祭司亚伦从前在西奈的旷野所数的以色列人，"},
{volumn:         4,chapter:        26,versenumber:        65,verse:"因为耶和华论到他们说：“他们必要死在旷野。”所以，除了耶孚尼的儿子迦勒和嫩的儿子约书亚以外，连一个人也没有存留。"},
{volumn:         4,chapter:        27,versenumber:         1,verse:"属约瑟的儿子玛拿西的各族，有玛拿西的玄孙，玛吉的曾孙，基列的孙子，希弗的儿子西罗非哈的女儿，名叫玛拉、挪阿、曷拉、密迦、得撒。她们前来，"},
{volumn:         4,chapter:        27,versenumber:         2,verse:"站在会幕门口，在摩西和祭司以利亚撒，并众首领与全会众面前，说："},
{volumn:         4,chapter:        27,versenumber:         3,verse:"“我们的父亲死在旷野。他不与可拉同党聚集攻击耶和华，是在自己罪中死的；他也没有儿子。"},
{volumn:         4,chapter:        27,versenumber:         4,verse:"为什么因我们的父亲没有儿子就把他的名从他族中除掉呢？求你们在我们父亲的弟兄中分给我们产业。”"},
{volumn:         4,chapter:        27,versenumber:         5,verse:"于是，摩西将她们的案件呈到耶和华面前。"},
{volumn:         4,chapter:        27,versenumber:         6,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        27,versenumber:         7,verse:"“西罗非哈的女儿说得有理。你定要在她们父亲的弟兄中，把地分给她们为业；要将她们父亲的产业归给她们。"},
{volumn:         4,chapter:        27,versenumber:         8,verse:"你也要晓谕以色列人说：‘人若死了没有儿子，就要把他的产业归给他的女儿。"},
{volumn:         4,chapter:        27,versenumber:         9,verse:"他若没有女儿，就要把他的产业给他的弟兄。"},
{volumn:         4,chapter:        27,versenumber:        10,verse:"他若没有弟兄，就要把他的产业给他父亲的弟兄。"},
{volumn:         4,chapter:        27,versenumber:        11,verse:"他父亲若没有弟兄，就要把他的产业给他族中最近的亲属，他便要得为业。’这要作以色列人的律例典章，是照耶和华吩咐摩西的。”"},
{volumn:         4,chapter:        27,versenumber:        12,verse:"耶和华对摩西说：“你上这亚巴琳山，观看我所赐给以色列人的地。"},
{volumn:         4,chapter:        27,versenumber:        13,verse:"看了以后，你也必归到你列祖（原文是本民）那里，像你哥哥亚伦一样。"},
{volumn:         4,chapter:        27,versenumber:        14,verse:"因为你们在寻的旷野，当会众争闹的时候，违背了我的命，没有在涌水之地、会众眼前尊我为圣。”（这水就是寻的旷野加低斯米利巴水。）"},
{volumn:         4,chapter:        27,versenumber:        15,verse:"摩西对耶和华说："},
{volumn:         4,chapter:        27,versenumber:        16,verse:"“愿耶和华万人之灵的　神，立一个人治理会众，"},
{volumn:         4,chapter:        27,versenumber:        17,verse:"可以在他们面前出入，也可以引导他们，免得耶和华的会众如同没有牧人的羊群一般。”"},
{volumn:         4,chapter:        27,versenumber:        18,verse:"耶和华对摩西说：“嫩的儿子约书亚是心中有圣灵的；你将他领来，按手在他头上，"},
{volumn:         4,chapter:        27,versenumber:        19,verse:"使他站在祭司以利亚撒和全会众面前，嘱咐他，"},
{volumn:         4,chapter:        27,versenumber:        20,verse:"又将你的尊荣给他几分，使以色列全会众都听从他。"},
{volumn:         4,chapter:        27,versenumber:        21,verse:"他要站在祭司以利亚撒面前；以利亚撒要凭乌陵的判断，在耶和华面前为他求问。他和以色列全会众都要遵以利亚撒的命出入。”"},
{volumn:         4,chapter:        27,versenumber:        22,verse:"于是摩西照耶和华所吩咐的将约书亚领来，使他站在祭司以利亚撒和全会众面前，"},
{volumn:         4,chapter:        27,versenumber:        23,verse:"按手在他头上，嘱咐他，是照耶和华藉摩西所说的话。"},
{volumn:         4,chapter:        28,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        28,versenumber:         2,verse:"“你要吩咐以色列人说：‘献给我的供物，就是献给我作馨香火祭的食物，你们要按日期献给我’；"},
{volumn:         4,chapter:        28,versenumber:         3,verse:"又要对他们说：你们要献给耶和华的火祭，就是没有残疾、一岁的公羊羔，每日两只，作为常献的燔祭。"},
{volumn:         4,chapter:        28,versenumber:         4,verse:"早晨要献一只，黄昏的时候要献一只；"},
{volumn:         4,chapter:        28,versenumber:         5,verse:"又用细面伊法十分之一，并捣成的油一欣四分之一，调和作为素祭。"},
{volumn:         4,chapter:        28,versenumber:         6,verse:"这是西奈山所命定为常献的燔祭，是献给耶和华为馨香的火祭。"},
{volumn:         4,chapter:        28,versenumber:         7,verse:"为这一只羊羔，要同献奠祭的酒一欣四分之一。在圣所中，你要将醇酒奉给耶和华为奠祭。"},
{volumn:         4,chapter:        28,versenumber:         8,verse:"晚上，你要献那一只羊羔，必照早晨的素祭和同献的奠祭献上，作为馨香的火祭，献给耶和华。”"},
{volumn:         4,chapter:        28,versenumber:         9,verse:"“当安息日，要献两只没有残疾、一岁的公羊羔，并用调油的细面伊法十分之二为素祭，又将同献的奠祭献上。"},
{volumn:         4,chapter:        28,versenumber:        10,verse:"这是每安息日献的燔祭；那常献的燔祭和同献的奠祭在外。”"},
{volumn:         4,chapter:        28,versenumber:        11,verse:"“每月朔，你们要将两只公牛犊，一只公绵羊，七只没有残疾、一岁的公羊羔，献给耶和华为燔祭。"},
{volumn:         4,chapter:        28,versenumber:        12,verse:"每只公牛要用调油的细面伊法十分之三作为素祭；那只公羊也用调油的细面伊法十分之二作为素祭；"},
{volumn:         4,chapter:        28,versenumber:        13,verse:"每只羊羔要用调油的细面伊法十分之一作为素祭和馨香的燔祭，是献给耶和华的火祭。"},
{volumn:         4,chapter:        28,versenumber:        14,verse:"一只公牛要奠酒半欣，一只公羊要奠酒一欣三分之一，一只羊羔也奠酒一欣四分之一。这是每月的燔祭，一年之中要月月如此。"},
{volumn:         4,chapter:        28,versenumber:        15,verse:"又要将一只公山羊为赎罪祭，献给耶和华；要献在常献的燔祭和同献的奠祭以外。”"},
{volumn:         4,chapter:        28,versenumber:        16,verse:"“正月十四日是耶和华的逾越节。"},
{volumn:         4,chapter:        28,versenumber:        17,verse:"这月十五日是节期，要吃无酵饼七日。"},
{volumn:         4,chapter:        28,versenumber:        18,verse:"第一日当有圣会；什么劳碌的工都不可做。"},
{volumn:         4,chapter:        28,versenumber:        19,verse:"当将公牛犊两只，公绵羊一只，一岁的公羊羔七只，都要没有残疾的，用火献给耶和华为燔祭。"},
{volumn:         4,chapter:        28,versenumber:        20,verse:"同献的素祭用调油的细面；为一只公牛要献伊法十分之三；为一只公羊要献伊法十分之二；"},
{volumn:         4,chapter:        28,versenumber:        21,verse:"为那七只羊羔，每只要献伊法十分之一。"},
{volumn:         4,chapter:        28,versenumber:        22,verse:"并献一只公山羊作赎罪祭，为你们赎罪。"},
{volumn:         4,chapter:        28,versenumber:        23,verse:"你们献这些，要在早晨常献的燔祭以外。"},
{volumn:         4,chapter:        28,versenumber:        24,verse:"一连七日，每日要照这例把馨香火祭的食物献给耶和华，是在常献的燔祭和同献的奠祭以外。"},
{volumn:         4,chapter:        28,versenumber:        25,verse:"第七日当有圣会，什么劳碌的工都不可做。”"},
{volumn:         4,chapter:        28,versenumber:        26,verse:"“七七节庄稼初熟，你们献新素祭给耶和华的日子，当有圣会；什么劳碌的工都不可做。"},
{volumn:         4,chapter:        28,versenumber:        27,verse:"只要将公牛犊两只，公绵羊一只，一岁的公羊羔七只，作为馨香的燔祭献给耶和华。"},
{volumn:         4,chapter:        28,versenumber:        28,verse:"同献的素祭用调油的细面；为每只公牛要献伊法十分之三；为一只公羊要献伊法十分之二；"},
{volumn:         4,chapter:        28,versenumber:        29,verse:"为那七只羊羔，每只要献伊法十分之一。"},
{volumn:         4,chapter:        28,versenumber:        30,verse:"并献一只公山羊为你们赎罪。"},
{volumn:         4,chapter:        28,versenumber:        31,verse:"这些，你们要献在常献的燔祭和同献的素祭并同献的奠祭以外，都要没有残疾的。”"},
{volumn:         4,chapter:        29,versenumber:         1,verse:"“七月初一日，你们当有圣会；什么劳碌的工都不可做，是你们当守为吹角的日子。"},
{volumn:         4,chapter:        29,versenumber:         2,verse:"你们要将公牛犊一只，公绵羊一只，没有残疾、一岁的公羊羔七只，作为馨香的燔祭献给耶和华。"},
{volumn:         4,chapter:        29,versenumber:         3,verse:"同献的素祭用调油的细面；为一只公牛要献伊法十分之三；为一只公羊要献伊法十分之二；"},
{volumn:         4,chapter:        29,versenumber:         4,verse:"为那七只羊羔，每只要献伊法十分之一。"},
{volumn:         4,chapter:        29,versenumber:         5,verse:"又献一只公山羊作赎罪祭，为你们赎罪。"},
{volumn:         4,chapter:        29,versenumber:         6,verse:"这些是在月朔的燔祭和同献的素祭，并常献的燔祭与同献的素祭，以及照例同献的奠祭以外，都作为馨香的火祭献给耶和华。”"},
{volumn:         4,chapter:        29,versenumber:         7,verse:"“七月初十日，你们当有圣会；要刻苦己心，什么工都不可做。"},
{volumn:         4,chapter:        29,versenumber:         8,verse:"只要将公牛犊一只，公绵羊一只，一岁的公羊羔七只，都要没有残疾的，作为馨香的燔祭献给耶和华。"},
{volumn:         4,chapter:        29,versenumber:         9,verse:"同献的素祭用调油的细面：为一只公牛要献伊法十分之三；为一只公羊要献伊法十分之二；"},
{volumn:         4,chapter:        29,versenumber:        10,verse:"为那七只羊羔，每只要献伊法十分之一。"},
{volumn:         4,chapter:        29,versenumber:        11,verse:"又献一只公山羊为赎罪祭。这是在赎罪祭和常献的燔祭，与同献的素祭并同献的奠祭以外。”"},
{volumn:         4,chapter:        29,versenumber:        12,verse:"“七月十五日，你们当有圣会；什么劳碌的工都不可做，要向耶和华守节七日。"},
{volumn:         4,chapter:        29,versenumber:        13,verse:"又要将公牛犊十三只，公绵羊两只，一岁的公羊羔十四只，都要没有残疾的，用火献给耶和华为馨香的燔祭。"},
{volumn:         4,chapter:        29,versenumber:        14,verse:"同献的素祭用调油的细面；为那十三只公牛，每只要献伊法十分之三；为那两只公羊，每只要献伊法十分之二；"},
{volumn:         4,chapter:        29,versenumber:        15,verse:"为那十四只羊羔，每只要献伊法十分之一。"},
{volumn:         4,chapter:        29,versenumber:        16,verse:"并献一只公山羊为赎罪祭，这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        17,verse:"“第二日要献公牛犊十二只，公绵羊两只，没有残疾、一岁的公羊羔十四只；"},
{volumn:         4,chapter:        29,versenumber:        18,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        19,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        20,verse:"“第三日要献公牛十一只，公羊两只，没有残疾、一岁的公羊羔十四只；"},
{volumn:         4,chapter:        29,versenumber:        21,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        22,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        23,verse:"“第四日要献公牛十只，公羊两只，没有残疾、一岁的公羊羔十四只；"},
{volumn:         4,chapter:        29,versenumber:        24,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        25,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        26,verse:"“第五日要献公牛九只，公羊两只，没有残疾、一岁的公羊羔十四只；"},
{volumn:         4,chapter:        29,versenumber:        27,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        28,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        29,verse:"“第六日要献公牛八只，公羊两只，没有残疾、一岁的公羊羔十四只；"},
{volumn:         4,chapter:        29,versenumber:        30,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        31,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        32,verse:"“第七日要献公牛七只，公羊两只，没有残疾、一岁的公羊羔十四只；"},
{volumn:         4,chapter:        29,versenumber:        33,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        34,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        35,verse:"“第八日你们当有严肃会；什么劳碌的工都不可做；"},
{volumn:         4,chapter:        29,versenumber:        36,verse:"只要将公牛一只，公羊一只，没有残疾、一岁的公羊羔七只作火祭，献给耶和华为馨香的燔祭；"},
{volumn:         4,chapter:        29,versenumber:        37,verse:"并为公牛、公羊，和羊羔，按数照例，献同献的素祭和同献的奠祭。"},
{volumn:         4,chapter:        29,versenumber:        38,verse:"又要献一只公山羊为赎罪祭。这是在常献的燔祭和同献的素祭并同献的奠祭以外。"},
{volumn:         4,chapter:        29,versenumber:        39,verse:"“这些祭要在你们的节期献给耶和华，都在所许的愿并甘心所献的以外，作为你们的燔祭、素祭、奠祭，和平安祭。”"},
{volumn:         4,chapter:        29,versenumber:        40,verse:"于是，摩西照耶和华所吩咐他的一切话告诉以色列人。"},
{volumn:         4,chapter:        30,versenumber:         1,verse:"摩西晓谕以色列各支派的首领说：“耶和华所吩咐的乃是这样："},
{volumn:         4,chapter:        30,versenumber:         2,verse:"人若向耶和华许愿或起誓，要约束自己，就不可食言，必要按口中所出的一切话行。"},
{volumn:         4,chapter:        30,versenumber:         3,verse:"女子年幼、还在父家的时候，若向耶和华许愿，要约束自己，"},
{volumn:         4,chapter:        30,versenumber:         4,verse:"她父亲也听见她所许的愿并约束自己的话，却向她默默不言，她所许的愿并约束自己的话就都要为定。"},
{volumn:         4,chapter:        30,versenumber:         5,verse:"但她父亲听见的日子若不应承她所许的愿和约束自己的话，就都不得为定；耶和华也必赦免她，因为她父亲不应承。"},
{volumn:         4,chapter:        30,versenumber:         6,verse:"她若出了嫁，有愿在身，或是口中出了约束自己的冒失话，"},
{volumn:         4,chapter:        30,versenumber:         7,verse:"她丈夫听见的日子，却向她默默不言，她所许的愿并约束自己的话就都要为定。"},
{volumn:         4,chapter:        30,versenumber:         8,verse:"但她丈夫听见的日子，若不应承，就算废了她所许的愿和她出口约束自己的冒失话；耶和华也必赦免她。"},
{volumn:         4,chapter:        30,versenumber:         9,verse:"寡妇或是被休的妇人所许的愿，就是她约束自己的话，都要为定。"},
{volumn:         4,chapter:        30,versenumber:        10,verse:"她若在丈夫家里许了愿或起了誓，约束自己，"},
{volumn:         4,chapter:        30,versenumber:        11,verse:"丈夫听见，却向她默默不言，也没有不应承，她所许的愿并约束自己的话就都要为定。"},
{volumn:         4,chapter:        30,versenumber:        12,verse:"丈夫听见的日子，若把这两样全废了，妇人口中所许的愿或是约束自己的话就都不得为定，因她丈夫已经把这两样废了；耶和华也必赦免她。"},
{volumn:         4,chapter:        30,versenumber:        13,verse:"凡她所许的愿和刻苦约束自己所起的誓，她丈夫可以坚定，也可以废去。"},
{volumn:         4,chapter:        30,versenumber:        14,verse:"倘若她丈夫天天向她默默不言，就算是坚定她所许的愿和约束自己的话；因丈夫听见的日子向她默默不言，就使这两样坚定。"},
{volumn:         4,chapter:        30,versenumber:        15,verse:"但她丈夫听见以后，若使这两样全废了，就要担当妇人的罪孽。”"},
{volumn:         4,chapter:        30,versenumber:        16,verse:"这是丈夫待妻子，父亲待女儿，女儿年幼、还在父家，耶和华所吩咐摩西的律例。"},
{volumn:         4,chapter:        31,versenumber:         1,verse:"耶和华吩咐摩西说："},
{volumn:         4,chapter:        31,versenumber:         2,verse:"“你要在米甸人身上报以色列人的仇，后来要归到你列祖（原文是本民）那里。”"},
{volumn:         4,chapter:        31,versenumber:         3,verse:"摩西吩咐百姓说：“要从你们中间叫人带兵器出去攻击米甸，好在米甸人身上为耶和华报仇。"},
{volumn:         4,chapter:        31,versenumber:         4,verse:"从以色列众支派中，每支派要打发一千人去打仗。”"},
{volumn:         4,chapter:        31,versenumber:         5,verse:"于是从以色列千万人中，每支派交出一千人，共一万二千人，带着兵器预备打仗。"},
{volumn:         4,chapter:        31,versenumber:         6,verse:"摩西就打发每支派的一千人去打仗，并打发祭司以利亚撒的儿子非尼哈同去；非尼哈手里拿着圣所的器皿和吹大声的号筒。"},
{volumn:         4,chapter:        31,versenumber:         7,verse:"他们就照耶和华所吩咐摩西的，与米甸人打仗，杀了所有的男丁。"},
{volumn:         4,chapter:        31,versenumber:         8,verse:"在所杀的人中，杀了米甸的五王，就是以未、利金、苏珥、户珥、利巴，又用刀杀了比珥的儿子巴兰。"},
{volumn:         4,chapter:        31,versenumber:         9,verse:"以色列人掳了米甸人的妇女孩子，并将他们的牲畜、羊群，和所有的财物都夺了来，当作掳物，"},
{volumn:         4,chapter:        31,versenumber:        10,verse:"又用火焚烧他们所住的城邑和所有的营寨，"},
{volumn:         4,chapter:        31,versenumber:        11,verse:"把一切所夺的、所掳的，连人带牲畜都带了去，"},
{volumn:         4,chapter:        31,versenumber:        12,verse:"将所掳的人，所夺的牲畜、财物，都带到摩押平原，在约旦河边与耶利哥相对的营盘，交给摩西和祭司以利亚撒，并以色列的会众。"},
{volumn:         4,chapter:        31,versenumber:        13,verse:"摩西和祭司以利亚撒，并会众一切的首领，都出到营外迎接他们。"},
{volumn:         4,chapter:        31,versenumber:        14,verse:"摩西向打仗回来的军长，就是千夫长、百夫长，发怒，"},
{volumn:         4,chapter:        31,versenumber:        15,verse:"对他们说：“你们要存留这一切妇女的活命吗？"},
{volumn:         4,chapter:        31,versenumber:        16,verse:"这些妇女因巴兰的计谋，叫以色列人在毗珥的事上得罪耶和华，以致耶和华的会众遭遇瘟疫。"},
{volumn:         4,chapter:        31,versenumber:        17,verse:"所以，你们要把一切的男孩和所有已嫁的女子都杀了。"},
{volumn:         4,chapter:        31,versenumber:        18,verse:"但女孩子中，凡没有出嫁的，你们都可以存留她的活命。"},
{volumn:         4,chapter:        31,versenumber:        19,verse:"你们要在营外驻扎七日；凡杀了人的，和一切摸了被杀的，并你们所掳来的人口，第三日，第七日，都要洁净自己，"},
{volumn:         4,chapter:        31,versenumber:        20,verse:"也要因一切的衣服、皮物、山羊毛织的物，和各样的木器，洁净自己。”"},
{volumn:         4,chapter:        31,versenumber:        21,verse:"祭司以利亚撒对打仗回来的兵丁说：“耶和华所吩咐摩西律法中的条例乃是这样："},
{volumn:         4,chapter:        31,versenumber:        22,verse:"金、银、铜、铁、锡、铅，"},
{volumn:         4,chapter:        31,versenumber:        23,verse:"凡能见火的，你们要叫它经火就为洁净，然而还要用除污秽的水洁净它；凡不能见火的，你们要叫它过水。"},
{volumn:         4,chapter:        31,versenumber:        24,verse:"第七日，你们要洗衣服，就为洁净，然后可以进营。”"},
{volumn:         4,chapter:        31,versenumber:        25,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        31,versenumber:        26,verse:"“你和祭司以利亚撒，并会众的各族长，要计算所掳来的人口和牲畜的总数。"},
{volumn:         4,chapter:        31,versenumber:        27,verse:"把所掳来的分作两半：一半归与出去打仗的精兵，一半归与全会众。"},
{volumn:         4,chapter:        31,versenumber:        28,verse:"又要从出去打仗所得的人口、牛、驴、羊群中，每五百取一，作为贡物奉给耶和华。"},
{volumn:         4,chapter:        31,versenumber:        29,verse:"从他们一半之中，要取出来交给祭司以利亚撒，作为耶和华的举祭。"},
{volumn:         4,chapter:        31,versenumber:        30,verse:"从以色列人的一半之中，就是从人口、牛、驴、羊群、各样牲畜中，每五十取一，交给看守耶和华帐幕的利未人。”"},
{volumn:         4,chapter:        31,versenumber:        31,verse:"于是摩西和祭司以利亚撒照耶和华所吩咐摩西的行了。"},
{volumn:         4,chapter:        31,versenumber:        32,verse:"除了兵丁所夺的财物以外，所掳来的：羊六十七万五千只；"},
{volumn:         4,chapter:        31,versenumber:        33,verse:"牛七万二千只；"},
{volumn:         4,chapter:        31,versenumber:        34,verse:"驴六万一千匹；"},
{volumn:         4,chapter:        31,versenumber:        35,verse:"女人共三万二千口，都是没有出嫁的。"},
{volumn:         4,chapter:        31,versenumber:        36,verse:"出去打仗之人的份，就是他们所得的那一半，共计羊三十三万七千五百只，"},
{volumn:         4,chapter:        31,versenumber:        37,verse:"从其中归耶和华为贡物的，有六百七十五只；"},
{volumn:         4,chapter:        31,versenumber:        38,verse:"牛三万六千只，从其中归耶和华为贡物的，有七十二只；"},
{volumn:         4,chapter:        31,versenumber:        39,verse:"驴三万零五百匹，从其中归耶和华为贡物的，有六十一匹；"},
{volumn:         4,chapter:        31,versenumber:        40,verse:"人一万六千口，从其中归耶和华的，有三十二口。"},
{volumn:         4,chapter:        31,versenumber:        41,verse:"摩西把贡物，就是归与耶和华的举祭，交给祭司以利亚撒，是照耶和华所吩咐摩西的。"},
{volumn:         4,chapter:        31,versenumber:        42,verse:"以色列人所得的那一半，就是摩西从打仗的人取来分给他们的。（"},
{volumn:         4,chapter:        31,versenumber:        43,verse:"会众的那一半有：羊三十三万七千五百只；"},
{volumn:         4,chapter:        31,versenumber:        44,verse:"牛三万六千只；"},
{volumn:         4,chapter:        31,versenumber:        45,verse:"驴三万零五百匹；"},
{volumn:         4,chapter:        31,versenumber:        46,verse:"人一万六千口。）"},
{volumn:         4,chapter:        31,versenumber:        47,verse:"无论是人口是牲畜，摩西每五十取一，交给看守耶和华帐幕的利未人，是照耶和华所吩咐摩西的。"},
{volumn:         4,chapter:        31,versenumber:        48,verse:"带领千军的各军长，就是千夫长、百夫长，都近前来见摩西，"},
{volumn:         4,chapter:        31,versenumber:        49,verse:"对他说：“仆人权下的兵已经计算总数，并不短少一人。"},
{volumn:         4,chapter:        31,versenumber:        50,verse:"如今我们将各人所得的金器，就是脚链子、镯子、打印的戒指、耳环、手钏，都送来为耶和华的供物，好在耶和华面前为我们的生命赎罪。”"},
{volumn:         4,chapter:        31,versenumber:        51,verse:"摩西和祭司以利亚撒就收了他们的金子，都是打成的器皿。"},
{volumn:         4,chapter:        31,versenumber:        52,verse:"千夫长、百夫长所献给耶和华为举祭的金子共有一万六千七百五十舍客勒。"},
{volumn:         4,chapter:        31,versenumber:        53,verse:"各兵丁都为自己夺了财物。"},
{volumn:         4,chapter:        31,versenumber:        54,verse:"摩西和祭司以利亚撒收了千夫长、百夫长的金子，就带进会幕，在耶和华面前作为以色列人的纪念。"},
{volumn:         4,chapter:        32,versenumber:         1,verse:"流便子孙和迦得子孙的牲畜极其众多；他们看见雅谢地和基列地是可牧放牲畜之地，"},
{volumn:         4,chapter:        32,versenumber:         2,verse:"就来见摩西和祭司以利亚撒，并会众的首领，说："},
{volumn:         4,chapter:        32,versenumber:         3,verse:"“亚大录、底本、雅谢、宁拉、希实本、以利亚利、示班、尼波、比稳，"},
{volumn:         4,chapter:        32,versenumber:         4,verse:"就是耶和华在以色列会众前面所攻取之地，是可牧放牲畜之地，你仆人也有牲畜”；"},
{volumn:         4,chapter:        32,versenumber:         5,verse:"又说：“我们若在你眼前蒙恩，求你把这地给我们为业，不要领我们过约旦河。”"},
{volumn:         4,chapter:        32,versenumber:         6,verse:"摩西对迦得子孙和流便子孙说：“难道你们的弟兄去打仗，你们竟坐在这里吗？"},
{volumn:         4,chapter:        32,versenumber:         7,verse:"你们为何使以色列人灰心丧胆、不过去进入耶和华所赐给他们的那地呢？"},
{volumn:         4,chapter:        32,versenumber:         8,verse:"我先前从加低斯·巴尼亚打发你们先祖去窥探那地，他们也是这样行。"},
{volumn:         4,chapter:        32,versenumber:         9,verse:"他们上以实各谷，去窥探那地回来的时候，使以色列人灰心丧胆，不进入耶和华所赐给他们的地。"},
{volumn:         4,chapter:        32,versenumber:        10,verse:"当日，耶和华的怒气发作，就起誓说："},
{volumn:         4,chapter:        32,versenumber:        11,verse:"‘凡从埃及上来、二十岁以外的人断不得看见我对亚伯拉罕、以撒、雅各起誓应许之地，因为他们没有专心跟从我。"},
{volumn:         4,chapter:        32,versenumber:        12,verse:"惟有基尼洗族耶孚尼的儿子迦勒和嫩的儿子约书亚可以看见，因为他们专心跟从我。’"},
{volumn:         4,chapter:        32,versenumber:        13,verse:"耶和华的怒气向以色列人发作，使他们在旷野飘流四十年，等到在耶和华眼前行恶的那一代人都消灭了。"},
{volumn:         4,chapter:        32,versenumber:        14,verse:"谁知，你们起来接续先祖，增添罪人的数目，使耶和华向以色列大发烈怒。"},
{volumn:         4,chapter:        32,versenumber:        15,verse:"你们若退后不跟从他，他还要把以色列人撇在旷野，便是你们使这众民灭亡。”"},
{volumn:         4,chapter:        32,versenumber:        16,verse:"两支派的人挨近摩西，说：“我们要在这里为牲畜垒圈，为妇人孩子造城。"},
{volumn:         4,chapter:        32,versenumber:        17,verse:"我们自己要带兵器行在以色列人的前头，好把他们领到他们的地方；但我们的妇人孩子，因这地居民的缘故，要住在坚固的城内。"},
{volumn:         4,chapter:        32,versenumber:        18,verse:"我们不回家，直等到以色列人各承受自己的产业。"},
{volumn:         4,chapter:        32,versenumber:        19,verse:"我们不和他们在约旦河那边一带之地同受产业，因为我们的产业是坐落在约旦河东边这里。”"},
{volumn:         4,chapter:        32,versenumber:        20,verse:"摩西对他们说：“你们若这样行，在耶和华面前带着兵器出去打仗，"},
{volumn:         4,chapter:        32,versenumber:        21,verse:"所有带兵器的人都要在耶和华面前过约旦河，等他赶出他的仇敌，"},
{volumn:         4,chapter:        32,versenumber:        22,verse:"那地被耶和华制伏了，然后你们可以回来，向耶和华和以色列才为无罪，这地也必在耶和华面前归你们为业。"},
{volumn:         4,chapter:        32,versenumber:        23,verse:"倘若你们不这样行，就得罪耶和华，要知道你们的罪必追上你们。"},
{volumn:         4,chapter:        32,versenumber:        24,verse:"如今你们口中所出的，只管去行，为你们的妇人孩子造城，为你们的羊群垒圈。”"},
{volumn:         4,chapter:        32,versenumber:        25,verse:"迦得子孙和流便子孙对摩西说：“仆人要照我主所吩咐的去行。"},
{volumn:         4,chapter:        32,versenumber:        26,verse:"我们的妻子、孩子、羊群，和所有的牲畜都要留在基列的各城。"},
{volumn:         4,chapter:        32,versenumber:        27,verse:"但你的仆人，凡带兵器的，都要照我主所说的话，在耶和华面前过去打仗。”"},
{volumn:         4,chapter:        32,versenumber:        28,verse:"于是，摩西为他们嘱咐祭司以利亚撒和嫩的儿子约书亚，并以色列众支派的族长，说："},
{volumn:         4,chapter:        32,versenumber:        29,verse:"“迦得子孙和流便子孙，凡带兵器在耶和华面前去打仗的，若与你们一同过约旦河，那地被你们制伏了，你们就要把基列地给他们为业。"},
{volumn:         4,chapter:        32,versenumber:        30,verse:"倘若他们不带兵器和你们一同过去，就要在迦南地你们中间得产业。”"},
{volumn:         4,chapter:        32,versenumber:        31,verse:"迦得子孙和流便子孙回答说：“耶和华怎样吩咐仆人，仆人就怎样行。"},
{volumn:         4,chapter:        32,versenumber:        32,verse:"我们要带兵器，在耶和华面前过去，进入迦南地，只是约旦河这边、我们所得为业之地仍归我们。”"},
{volumn:         4,chapter:        32,versenumber:        33,verse:"摩西将亚摩利王西宏的国和巴珊王噩的国，连那地和周围的城邑，都给了迦得子孙和流便子孙，并约瑟的儿子玛拿西半个支派。"},
{volumn:         4,chapter:        32,versenumber:        34,verse:"迦得子孙建造底本、亚他录、亚罗珥、"},
{volumn:         4,chapter:        32,versenumber:        35,verse:"亚他录·朔反、雅谢、约比哈、"},
{volumn:         4,chapter:        32,versenumber:        36,verse:"伯·宁拉、伯·哈兰，都是坚固城。他们又垒羊圈。"},
{volumn:         4,chapter:        32,versenumber:        37,verse:"流便子孙建造希实本、以利亚利、基列亭、"},
{volumn:         4,chapter:        32,versenumber:        38,verse:"尼波、巴力·免、西比玛（尼波、巴力·免，名字是改了的），又给他们所建造的城另起别名。"},
{volumn:         4,chapter:        32,versenumber:        39,verse:"玛拿西的儿子玛吉，他的子孙往基列去，占了那地，赶出那里的亚摩利人。"},
{volumn:         4,chapter:        32,versenumber:        40,verse:"摩西将基列赐给玛拿西的儿子玛吉，他子孙就住在那里。"},
{volumn:         4,chapter:        32,versenumber:        41,verse:"玛拿西的子孙睚珥去占了基列的村庄，就称这些村庄为哈倭特·睚珥。"},
{volumn:         4,chapter:        32,versenumber:        42,verse:"挪巴去占了基纳和基纳的乡村，就按自己的名称基纳为挪巴。"},
{volumn:         4,chapter:        33,versenumber:         1,verse:"以色列人按着军队，在摩西、亚伦的手下出埃及地所行的路程（或译：站口；下同）记在下面。"},
{volumn:         4,chapter:        33,versenumber:         2,verse:"摩西遵着耶和华的吩咐记载他们所行的路程，其路程乃是这样："},
{volumn:         4,chapter:        33,versenumber:         3,verse:"正月十五日，就是逾越节的次日，以色列人从兰塞起行，在一切埃及人眼前昂然无惧地出去。"},
{volumn:         4,chapter:        33,versenumber:         4,verse:"那时，埃及人正葬埋他们的长子，就是耶和华在他们中间所击杀的；耶和华也败坏他们的神。"},
{volumn:         4,chapter:        33,versenumber:         5,verse:"以色列人从兰塞起行，安营在疏割。"},
{volumn:         4,chapter:        33,versenumber:         6,verse:"从疏割起行，安营在旷野边的以倘。"},
{volumn:         4,chapter:        33,versenumber:         7,verse:"从以倘起行，转到比哈·希录，是在巴力·洗分对面，就在密夺安营。"},
{volumn:         4,chapter:        33,versenumber:         8,verse:"从比哈·希录对面起行，经过海中到了书珥旷野，又在伊坦的旷野走了三天的路程，就安营在玛拉。"},
{volumn:         4,chapter:        33,versenumber:         9,verse:"从玛拉起行，来到以琳（以琳有十二股水泉，七十棵棕树），就在那里安营。"},
{volumn:         4,chapter:        33,versenumber:        10,verse:"从以琳起行，安营在红海边。"},
{volumn:         4,chapter:        33,versenumber:        11,verse:"从红海边起行，安营在汛的旷野。"},
{volumn:         4,chapter:        33,versenumber:        12,verse:"从汛的旷野起行，安营在脱加。"},
{volumn:         4,chapter:        33,versenumber:        13,verse:"从脱加起行，安营在亚录。"},
{volumn:         4,chapter:        33,versenumber:        14,verse:"从亚录起行，安营在利非订；在那里，百姓没有水喝。"},
{volumn:         4,chapter:        33,versenumber:        15,verse:"从利非订起行，安营在西奈的旷野。"},
{volumn:         4,chapter:        33,versenumber:        16,verse:"从西奈的旷野起行，安营在基博罗·哈他瓦。"},
{volumn:         4,chapter:        33,versenumber:        17,verse:"从基博罗·哈他瓦起行，安营在哈洗录。"},
{volumn:         4,chapter:        33,versenumber:        18,verse:"从哈洗录起行，安营在利提玛。"},
{volumn:         4,chapter:        33,versenumber:        19,verse:"从利提玛起行，安营在临门·帕烈。"},
{volumn:         4,chapter:        33,versenumber:        20,verse:"从临门·帕烈起行，安营在立拿。"},
{volumn:         4,chapter:        33,versenumber:        21,verse:"从立拿起行，安营在勒撒。"},
{volumn:         4,chapter:        33,versenumber:        22,verse:"从勒撒起行，安营在基希拉他。"},
{volumn:         4,chapter:        33,versenumber:        23,verse:"从基希拉他起行，安营在沙斐山。"},
{volumn:         4,chapter:        33,versenumber:        24,verse:"从沙斐山起行，安营在哈拉大。"},
{volumn:         4,chapter:        33,versenumber:        25,verse:"从哈拉大起行，安营在玛吉希录。"},
{volumn:         4,chapter:        33,versenumber:        26,verse:"从玛吉希录起行，安营在他哈。"},
{volumn:         4,chapter:        33,versenumber:        27,verse:"从他哈起行，安营在他拉。"},
{volumn:         4,chapter:        33,versenumber:        28,verse:"从他拉起行，安营在密加。"},
{volumn:         4,chapter:        33,versenumber:        29,verse:"从密加起行，安营在哈摩拿。"},
{volumn:         4,chapter:        33,versenumber:        30,verse:"从哈摩拿起行，安营在摩西录。"},
{volumn:         4,chapter:        33,versenumber:        31,verse:"从摩西录起行，安营在比尼·亚干。"},
{volumn:         4,chapter:        33,versenumber:        32,verse:"从比尼·亚干起行，安营在曷·哈及甲。"},
{volumn:         4,chapter:        33,versenumber:        33,verse:"从曷·哈及甲起行，安营在约巴他。"},
{volumn:         4,chapter:        33,versenumber:        34,verse:"从约巴他起行，安营在阿博拿。"},
{volumn:         4,chapter:        33,versenumber:        35,verse:"从阿博拿起行，安营在以旬·迦别。"},
{volumn:         4,chapter:        33,versenumber:        36,verse:"从以旬·迦别起行，安营在寻的旷野，就是加低斯。"},
{volumn:         4,chapter:        33,versenumber:        37,verse:"从加低斯起行，安营在何珥山，以东地的边界。"},
{volumn:         4,chapter:        33,versenumber:        38,verse:"以色列人出了埃及地后四十年，五月初一日，祭司亚伦遵着耶和华的吩咐上何珥山，就死在那里。"},
{volumn:         4,chapter:        33,versenumber:        39,verse:"亚伦死在何珥山的时候年一百二十三岁。"},
{volumn:         4,chapter:        33,versenumber:        40,verse:"住在迦南南地的迦南人亚拉得王听说以色列人来了。"},
{volumn:         4,chapter:        33,versenumber:        41,verse:"以色列人从何珥山起行，安营在撒摩拿。"},
{volumn:         4,chapter:        33,versenumber:        42,verse:"从撒摩拿起行，安营在普嫩。"},
{volumn:         4,chapter:        33,versenumber:        43,verse:"从普嫩起行，安营在阿伯。"},
{volumn:         4,chapter:        33,versenumber:        44,verse:"从阿伯起行，安营在以耶·亚巴琳，摩押的边界。"},
{volumn:         4,chapter:        33,versenumber:        45,verse:"从以耶·亚巴琳起行，安营在底本·迦得。"},
{volumn:         4,chapter:        33,versenumber:        46,verse:"从底本·迦得起行，安营在亚门·低比拉太音。"},
{volumn:         4,chapter:        33,versenumber:        47,verse:"从亚门·低比拉太音起行，安营在尼波对面的亚巴琳山里。"},
{volumn:         4,chapter:        33,versenumber:        48,verse:"从亚巴琳山起行，安营在摩押平原、约旦河边、耶利哥对面。"},
{volumn:         4,chapter:        33,versenumber:        49,verse:"他们在摩押平原沿约旦河边安营，从伯·耶施末直到亚伯·什亭。"},
{volumn:         4,chapter:        33,versenumber:        50,verse:"耶和华在摩押平原约旦河边、耶利哥对面晓谕摩西说："},
{volumn:         4,chapter:        33,versenumber:        51,verse:"“你吩咐以色列人说：你们过约旦河进迦南地的时候，"},
{volumn:         4,chapter:        33,versenumber:        52,verse:"就要从你们面前赶出那里所有的居民，毁灭他们一切錾成的石像和他们一切铸成的偶像，又拆毁他们一切的邱坛。"},
{volumn:         4,chapter:        33,versenumber:        53,verse:"你们要夺那地，住在其中，因我把那地赐给你们为业。"},
{volumn:         4,chapter:        33,versenumber:        54,verse:"你们要按家室拈阄，承受那地；人多的，要把产业多分给他们；人少的，要把产业少分给他们。拈出何地给何人，就要归何人。你们要按宗族的支派承受。"},
{volumn:         4,chapter:        33,versenumber:        55,verse:"倘若你们不赶出那地的居民，所容留的居民就必作你们眼中的刺，肋下的荆棘，也必在你们所住的地上扰害你们。"},
{volumn:         4,chapter:        33,versenumber:        56,verse:"而且我素常有意怎样待他们，也必照样待你们。”"},
{volumn:         4,chapter:        34,versenumber:         1,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        34,versenumber:         2,verse:"“你吩咐以色列人说：你们到了迦南地，就是归你们为业的迦南四境之地，"},
{volumn:         4,chapter:        34,versenumber:         3,verse:"南角要从寻的旷野，贴着以东的边界；南界要从盐海东头起，"},
{volumn:         4,chapter:        34,versenumber:         4,verse:"绕到亚克拉滨坡的南边，接连到寻，直通到加低斯·巴尼亚的南边，又通到哈萨·亚达，接连到押们，"},
{volumn:         4,chapter:        34,versenumber:         5,verse:"从押们转到埃及小河，直通到海为止。"},
{volumn:         4,chapter:        34,versenumber:         6,verse:"“西边要以大海为界；这就是你们的西界。"},
{volumn:         4,chapter:        34,versenumber:         7,verse:"“北界要从大海起，划到何珥山，"},
{volumn:         4,chapter:        34,versenumber:         8,verse:"从何珥山划到哈马口，通到西达达，"},
{volumn:         4,chapter:        34,versenumber:         9,verse:"又通到西斐仑，直到哈萨·以难。这要作你们的北界。"},
{volumn:         4,chapter:        34,versenumber:        10,verse:"“你们要从哈萨·以难划到示番为东界。"},
{volumn:         4,chapter:        34,versenumber:        11,verse:"这界要从示番下到亚延东边的利比拉，又要达到基尼烈湖的东边。"},
{volumn:         4,chapter:        34,versenumber:        12,verse:"这界要下到约旦河，通到盐海为止。这四围的边界以内，要作你们的地。”"},
{volumn:         4,chapter:        34,versenumber:        13,verse:"摩西吩咐以色列人说：“这地就是耶和华吩咐拈阄给九个半支派承受为业的；"},
{volumn:         4,chapter:        34,versenumber:        14,verse:"因为流便支派和迦得支派按着宗族受了产业，玛拿西半个支派也受了产业。"},
{volumn:         4,chapter:        34,versenumber:        15,verse:"这两个半支派已经在耶利哥对面、约旦河东、向日出之地受了产业。”"},
{volumn:         4,chapter:        34,versenumber:        16,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        34,versenumber:        17,verse:"“要给你们分地为业之人的名字是祭司以利亚撒和嫩的儿子约书亚。"},
{volumn:         4,chapter:        34,versenumber:        18,verse:"又要从每支派中选一个首领帮助他们。"},
{volumn:         4,chapter:        34,versenumber:        19,verse:"这些人的名字：犹大支派有耶孚尼的儿子迦勒。"},
{volumn:         4,chapter:        34,versenumber:        20,verse:"西缅支派有亚米忽的儿子示母利。"},
{volumn:         4,chapter:        34,versenumber:        21,verse:"便雅悯支派有基斯伦的儿子以利达。"},
{volumn:         4,chapter:        34,versenumber:        22,verse:"但支派有一个首领，约利的儿子布基。"},
{volumn:         4,chapter:        34,versenumber:        23,verse:"约瑟的子孙玛拿西支派有一个首领，以弗的儿子汉聂。"},
{volumn:         4,chapter:        34,versenumber:        24,verse:"以法莲支派有一个首领，拾弗但的儿子基母利。"},
{volumn:         4,chapter:        34,versenumber:        25,verse:"西布伦支派有一个首领，帕纳的儿子以利撒番。"},
{volumn:         4,chapter:        34,versenumber:        26,verse:"以萨迦支派有一个首领，阿散的儿子帕铁。"},
{volumn:         4,chapter:        34,versenumber:        27,verse:"亚设支派有一个首领，示罗米的儿子亚希忽。"},
{volumn:         4,chapter:        34,versenumber:        28,verse:"拿弗他利支派有一个首领，亚米忽的儿子比大黑。”"},
{volumn:         4,chapter:        34,versenumber:        29,verse:"这些人就是耶和华所吩咐、在迦南地把产业分给以色列人的。"},
{volumn:         4,chapter:        35,versenumber:         1,verse:"耶和华在摩押平原、约旦河边、耶利哥对面晓谕摩西说："},
{volumn:         4,chapter:        35,versenumber:         2,verse:"“你吩咐以色列人，要从所得为业的地中把些城给利未人居住，也要把这城四围的郊野给利未人。"},
{volumn:         4,chapter:        35,versenumber:         3,verse:"这城邑要归他们居住，城邑的郊野可以牧养他们的牛羊和各样的牲畜，又可以安置他们的财物。"},
{volumn:         4,chapter:        35,versenumber:         4,verse:"你们给利未人的郊野，要从城根起，四围往外量一千肘。"},
{volumn:         4,chapter:        35,versenumber:         5,verse:"另外东量二千肘，南量二千肘，西量二千肘，北量二千肘，为边界，城在当中；这要归他们作城邑的郊野。"},
{volumn:         4,chapter:        35,versenumber:         6,verse:"你们给利未人的城邑，其中当有六座逃城，使误杀人的可以逃到那里。此外还要给他们四十二座城。"},
{volumn:         4,chapter:        35,versenumber:         7,verse:"你们要给利未人的城，共有四十八座，连城带郊野都要给他们。"},
{volumn:         4,chapter:        35,versenumber:         8,verse:"以色列人所得的地业从中要把些城邑给利未人；人多的就多给，人少的就少给；各支派要按所承受为业之地把城邑给利未人。”"},
{volumn:         4,chapter:        35,versenumber:         9,verse:"耶和华晓谕摩西说："},
{volumn:         4,chapter:        35,versenumber:        10,verse:"“你吩咐以色列人说：你们过约旦河，进了迦南地，"},
{volumn:         4,chapter:        35,versenumber:        11,verse:"就要分出几座城，为你们作逃城，使误杀人的可以逃到那里。"},
{volumn:         4,chapter:        35,versenumber:        12,verse:"这些城可以作逃避报仇人的城，使误杀人的不至于死，等他站在会众面前听审判。"},
{volumn:         4,chapter:        35,versenumber:        13,verse:"你们所分出来的城，要作六座逃城。"},
{volumn:         4,chapter:        35,versenumber:        14,verse:"在约旦河东要分出三座城，在迦南地也要分出三座城，都作逃城。"},
{volumn:         4,chapter:        35,versenumber:        15,verse:"这六座城要给以色列人和他们中间的外人，并寄居的，作为逃城，使误杀人的都可以逃到那里。"},
{volumn:         4,chapter:        35,versenumber:        16,verse:"“倘若人用铁器打人，以致打死，他就是故杀人的；故杀人的必被治死。"},
{volumn:         4,chapter:        35,versenumber:        17,verse:"若用可以打死人的石头打死了人，他就是故杀人的；故杀人的必被治死。"},
{volumn:         4,chapter:        35,versenumber:        18,verse:"若用可以打死人的木器打死了人，他就是故杀人的；故杀人的必被治死。"},
{volumn:         4,chapter:        35,versenumber:        19,verse:"报血仇的必亲自杀那故杀人的，一遇见就杀他。"},
{volumn:         4,chapter:        35,versenumber:        20,verse:"人若因怨恨把人推倒，或是埋伏往人身上扔物，以致于死，"},
{volumn:         4,chapter:        35,versenumber:        21,verse:"或是因仇恨用手打人，以致于死，那打人的必被治死。他是故杀人的；报血仇的一遇见就杀他。"},
{volumn:         4,chapter:        35,versenumber:        22,verse:"“倘若人没有仇恨，忽然将人推倒，或是没有埋伏把物扔在人身上，"},
{volumn:         4,chapter:        35,versenumber:        23,verse:"或是没有看见的时候用可以打死人的石头扔在人身上，以致于死，本来与他无仇，也无意害他。"},
{volumn:         4,chapter:        35,versenumber:        24,verse:"会众就要照典章，在打死人的和报血仇的中间审判。"},
{volumn:         4,chapter:        35,versenumber:        25,verse:"会众要救这误杀人的脱离报血仇人的手，也要使他归入逃城。他要住在其中，直等到受圣膏的大祭司死了。"},
{volumn:         4,chapter:        35,versenumber:        26,verse:"但误杀人的，无论什么时候，若出了逃城的境外，"},
{volumn:         4,chapter:        35,versenumber:        27,verse:"报血仇的在逃城境外遇见他，将他杀了，报血仇的就没有流血之罪。"},
{volumn:         4,chapter:        35,versenumber:        28,verse:"因为误杀人的该住在逃城里，等到大祭司死了。大祭司死了以后，误杀人的才可以回到他所得为业之地。"},
{volumn:         4,chapter:        35,versenumber:        29,verse:"这在你们一切的住处，要作你们世世代代的律例典章。"},
{volumn:         4,chapter:        35,versenumber:        30,verse:"“无论谁故杀人，要凭几个见证人的口把那故杀人的杀了，只是不可凭一个见证的口叫人死。"},
{volumn:         4,chapter:        35,versenumber:        31,verse:"故杀人、犯死罪的，你们不可收赎价代替他的命；他必被治死。"},
{volumn:         4,chapter:        35,versenumber:        32,verse:"那逃到逃城的人，你们不可为他收赎价，使他在大祭司未死以先再来住在本地。"},
{volumn:         4,chapter:        35,versenumber:        33,verse:"这样，你们就不污秽所住之地，因为血是污秽地的；若有在地上流人血的，非流那杀人者的血，那地就不得洁净（原文是赎）。"},
{volumn:         4,chapter:        35,versenumber:        34,verse:"你们不可玷污所住之地，就是我住在其中之地，因为我耶和华住在以色列人中间。”"},
{volumn:         4,chapter:        36,versenumber:         1,verse:"约瑟的后裔，玛拿西的孙子，玛吉的儿子基列，他子孙中的诸族长来到摩西和作首领的以色列人族长面前，说："},
{volumn:         4,chapter:        36,versenumber:         2,verse:"“耶和华曾吩咐我主拈阄分地给以色列人为业，我主也受了耶和华的吩咐将我们兄弟西罗非哈的产业分给他的众女儿。"},
{volumn:         4,chapter:        36,versenumber:         3,verse:"她们若嫁以色列别支派的人，就必将我们祖宗所遗留的产业加在她们丈夫支派的产业中。这样，我们拈阄所得的产业就要减少了。"},
{volumn:         4,chapter:        36,versenumber:         4,verse:"到了以色列人的禧年，这女儿的产业就必加在她们丈夫支派的产业上。这样，我们祖宗支派的产业就减少了。”"},
{volumn:         4,chapter:        36,versenumber:         5,verse:"摩西照耶和华的话吩咐以色列人说：“约瑟支派的人说得有理。"},
{volumn:         4,chapter:        36,versenumber:         6,verse:"论到西罗非哈的众女儿，耶和华这样吩咐说：她们可以随意嫁人，只是要嫁同宗支派的人。"},
{volumn:         4,chapter:        36,versenumber:         7,verse:"这样，以色列人的产业就不从这支派归到那支派，因为以色列人要各守各祖宗支派的产业。"},
{volumn:         4,chapter:        36,versenumber:         8,verse:"凡在以色列支派中得了产业的女子必作同宗支派人的妻，好叫以色列人各自承受他祖宗的产业。"},
{volumn:         4,chapter:        36,versenumber:         9,verse:"这样，他们的产业就不从这支派归到那支派，因为以色列支派的人要各守各的产业。”"},
{volumn:         4,chapter:        36,versenumber:        10,verse:"耶和华怎样吩咐摩西，西罗非哈的众女儿就怎样行。"},
{volumn:         4,chapter:        36,versenumber:        11,verse:"西罗非哈的女儿玛拉、得撒、曷拉、密迦、挪阿都嫁了他们伯叔的儿子。"},
{volumn:         4,chapter:        36,versenumber:        12,verse:"她们嫁入约瑟儿子、玛拿西子孙的族中；她们的产业仍留在同宗支派中。"},
{volumn:         4,chapter:        36,versenumber:        13,verse:"这是耶和华在摩押平原、约旦河边、耶利哥对面，藉着摩西所吩咐以色列人的命令、典章。"},
{volumn:         5,chapter:         1,versenumber:         1,verse:"以下所记的是摩西在约旦河东的旷野、疏弗对面的亚拉巴，就是巴兰、陀弗、拉班、哈洗录、底撒哈中间，向以色列众人所说的话。（"},
{volumn:         5,chapter:         1,versenumber:         2,verse:"从何烈山经过西珥山到加低斯·巴尼亚有十一天的路程。）"},
{volumn:         5,chapter:         1,versenumber:         3,verse:"出埃及第四十年十一月初一日，摩西照耶和华藉着他所吩咐以色列人的话都晓谕他们。"},
{volumn:         5,chapter:         1,versenumber:         4,verse:"那时，他已经击杀了住希实本的亚摩利王西宏和住以得来、亚斯他录的巴珊王噩。"},
{volumn:         5,chapter:         1,versenumber:         5,verse:"摩西在约旦河东的摩押地讲律法说："},
{volumn:         5,chapter:         1,versenumber:         6,verse:"“耶和华我们的　神在何烈山晓谕我们说：你们在这山上住的日子够了；"},
{volumn:         5,chapter:         1,versenumber:         7,verse:"要起行转到亚摩利人的山地和靠近这山地的各处，就是亚拉巴、山地、高原、南地，沿海一带迦南人的地，并黎巴嫩山又到幼发拉底大河。"},
{volumn:         5,chapter:         1,versenumber:         8,verse:"如今我将这地摆在你们面前；你们要进去得这地，就是耶和华向你们列祖亚伯拉罕、以撒、雅各起誓应许赐给他们和他们后裔为业之地。”"},
{volumn:         5,chapter:         1,versenumber:         9,verse:"“那时，我对你们说：‘管理你们的重任，我独自担当不起。"},
{volumn:         5,chapter:         1,versenumber:        10,verse:"耶和华你们的　神使你们多起来。看哪，你们今日像天上的星那样多。"},
{volumn:         5,chapter:         1,versenumber:        11,verse:"惟愿耶和华你们列祖的　神使你们比如今更多千倍，照他所应许你们的话赐福与你们。"},
{volumn:         5,chapter:         1,versenumber:        12,verse:"但你们的麻烦，和管理你们的重任，并你们的争讼，我独自一人怎能担当得起呢？"},
{volumn:         5,chapter:         1,versenumber:        13,verse:"你们要按着各支派选举有智慧、有见识、为众人所认识的，我立他们为你们的首领。’"},
{volumn:         5,chapter:         1,versenumber:        14,verse:"你们回答我说：‘照你所说的行了为妙。’"},
{volumn:         5,chapter:         1,versenumber:        15,verse:"我便将你们各支派的首领，有智慧、为众人所认识的，照你们的支派，立他们为官长、千夫长、百夫长、五十夫长、十夫长，管理你们。"},
{volumn:         5,chapter:         1,versenumber:        16,verse:"“当时，我嘱咐你们的审判官说：‘你们听讼，无论是弟兄彼此争讼，是与同居的外人争讼，都要按公义判断。"},
{volumn:         5,chapter:         1,versenumber:        17,verse:"审判的时候，不可看人的外貌；听讼不可分贵贱，不可惧怕人，因为审判是属乎　神的。若有难断的案件，可以呈到我这里，我就判断。’"},
{volumn:         5,chapter:         1,versenumber:        18,verse:"那时，我将你们所当行的事都吩咐你们了。”"},
{volumn:         5,chapter:         1,versenumber:        19,verse:"“我们照着耶和华我们　神所吩咐的，从何烈山起行，经过你们所看见那大而可怕的旷野，往亚摩利人的山地去，到了加低斯·巴尼亚。"},
{volumn:         5,chapter:         1,versenumber:        20,verse:"我对你们说：‘你们已经到了耶和华我们　神所赐给我们的亚摩利人之山地。"},
{volumn:         5,chapter:         1,versenumber:        21,verse:"看哪，耶和华你的　神已将那地摆在你面前，你要照耶和华你列祖的　神所说的上去得那地为业；不要惧怕，也不要惊惶。’"},
{volumn:         5,chapter:         1,versenumber:        22,verse:"你们都就近我来说：‘我们要先打发人去，为我们窥探那地，将我们上去该走何道，必进何城，都回报我们。’"},
{volumn:         5,chapter:         1,versenumber:        23,verse:"这话我以为美，就从你们中间选了十二个人，每支派一人。"},
{volumn:         5,chapter:         1,versenumber:        24,verse:"于是他们起身上山地去，到以实各谷，窥探那地。"},
{volumn:         5,chapter:         1,versenumber:        25,verse:"他们手里拿着那地的果子下来，到我们那里，回报说：‘耶和华我们的　神所赐给我们的是美地。’"},
{volumn:         5,chapter:         1,versenumber:        26,verse:"“你们却不肯上去，竟违背了耶和华你们　神的命令，"},
{volumn:         5,chapter:         1,versenumber:        27,verse:"在帐棚内发怨言说：‘耶和华因为恨我们，所以将我们从埃及地领出来，要交在亚摩利人手中，除灭我们。"},
{volumn:         5,chapter:         1,versenumber:        28,verse:"我们上哪里去呢？我们的弟兄使我们的心消化，说那地的民比我们又大又高，城邑又广大又坚固，高得顶天，并且我们在那里看见亚衲族的人。’"},
{volumn:         5,chapter:         1,versenumber:        29,verse:"我就对你们说：‘不要惊恐，也不要怕他们。"},
{volumn:         5,chapter:         1,versenumber:        30,verse:"在你们前面行的耶和华你们的　神必为你们争战，正如他在埃及和旷野，在你们眼前所行的一样。"},
{volumn:         5,chapter:         1,versenumber:        31,verse:"你们在旷野所行的路上，也曾见耶和华你们的　神抚养你们，如同人抚养儿子一般，直等你们来到这地方。’"},
{volumn:         5,chapter:         1,versenumber:        32,verse:"你们在这事上却不信耶和华你们的　神。"},
{volumn:         5,chapter:         1,versenumber:        33,verse:"他在路上，在你们前面行，为你们找安营的地方；夜间在火柱里，日间在云柱里，指示你们所当行的路。”"},
{volumn:         5,chapter:         1,versenumber:        34,verse:"“耶和华听见你们这话，就发怒，起誓说："},
{volumn:         5,chapter:         1,versenumber:        35,verse:"‘这恶世代的人，连一个也不得见我起誓应许赐给你们列祖的美地；"},
{volumn:         5,chapter:         1,versenumber:        36,verse:"惟有耶孚尼的儿子迦勒必得看见，并且我要将他所踏过的地赐给他和他的子孙，因为他专心跟从我。’"},
{volumn:         5,chapter:         1,versenumber:        37,verse:"耶和华为你的缘故也向我发怒，说：‘你必不得进入那地。"},
{volumn:         5,chapter:         1,versenumber:        38,verse:"伺候你、嫩的儿子约书亚，他必得进入那地；你要勉励他，因为他要使以色列人承受那地为业。"},
{volumn:         5,chapter:         1,versenumber:        39,verse:"并且你们的妇人孩子，就是你们所说、必被掳掠的，和今日不知善恶的儿女，必进入那地。我要将那地赐给他们，他们必得为业。"},
{volumn:         5,chapter:         1,versenumber:        40,verse:"至于你们，要转回，从红海的路往旷野去。’"},
{volumn:         5,chapter:         1,versenumber:        41,verse:"“那时，你们回答我说：‘我们得罪了耶和华，情愿照耶和华我们　神一切所吩咐的上去争战。’于是你们各人带着兵器，争先上山地去了。"},
{volumn:         5,chapter:         1,versenumber:        42,verse:"耶和华吩咐我说：‘你对他们说：不要上去，也不要争战；因我不在你们中间，恐怕你们被仇敌杀败了。’"},
{volumn:         5,chapter:         1,versenumber:        43,verse:"我就告诉了你们，你们却不听从，竟违背耶和华的命令，擅自上山地去了。"},
{volumn:         5,chapter:         1,versenumber:        44,verse:"住那山地的亚摩利人就出来攻击你们，追赶你们，如蜂拥一般，在西珥杀退你们，直到何珥玛。"},
{volumn:         5,chapter:         1,versenumber:        45,verse:"你们便回来，在耶和华面前哭号；耶和华却不听你们的声音，也不向你们侧耳。"},
{volumn:         5,chapter:         1,versenumber:        46,verse:"于是你们在加低斯住了许多日子。”"},
{volumn:         5,chapter:         2,versenumber:         1,verse:"“此后，我们转回，从红海的路往旷野去，是照耶和华所吩咐我的。我们在西珥山绕行了许多日子。"},
{volumn:         5,chapter:         2,versenumber:         2,verse:"耶和华对我说："},
{volumn:         5,chapter:         2,versenumber:         3,verse:"‘你们绕行这山的日子够了，要转向北去。"},
{volumn:         5,chapter:         2,versenumber:         4,verse:"你吩咐百姓说：你们弟兄以扫的子孙住在西珥，你们要经过他们的境界。他们必惧怕你们，所以你们要分外谨慎。"},
{volumn:         5,chapter:         2,versenumber:         5,verse:"不可与他们争战；他们的地，连脚掌可踏之处，我都不给你们，因我已将西珥山赐给以扫为业。"},
{volumn:         5,chapter:         2,versenumber:         6,verse:"你们要用钱向他们买粮吃，也要用钱向他们买水喝。"},
{volumn:         5,chapter:         2,versenumber:         7,verse:"因为耶和华你的　神在你手里所办的一切事上已赐福与你。你走这大旷野，他都知道了。这四十年，耶和华你的　神常与你同在，故此你一无所缺。’"},
{volumn:         5,chapter:         2,versenumber:         8,verse:"“于是，我们离了我们弟兄以扫子孙所住的西珥，从亚拉巴的路，经过以拉他、以旬·迦别，转向摩押旷野的路去。"},
{volumn:         5,chapter:         2,versenumber:         9,verse:"耶和华吩咐我说：‘不可扰害摩押人，也不可与他们争战。他们的地，我不赐给你为业，因我已将亚珥赐给罗得的子孙为业。’"},
{volumn:         5,chapter:         2,versenumber:        10,verse:"先前，有以米人住在那里，民数众多，身体高大，像亚衲人一样。"},
{volumn:         5,chapter:         2,versenumber:        11,verse:"这以米人像亚衲人；也算为利乏音人；摩押人称他们为以米人。"},
{volumn:         5,chapter:         2,versenumber:        12,verse:"先前，何利人也住在西珥，但以扫的子孙将他们除灭，得了他们的地，接着居住，就如以色列在耶和华赐给他为业之地所行的一样。"},
{volumn:         5,chapter:         2,versenumber:        13,verse:"现在，起来过撒烈溪！于是我们过了撒烈溪。"},
{volumn:         5,chapter:         2,versenumber:        14,verse:"自从离开加低斯·巴尼亚，到过了撒烈溪的时候，共有三十八年，等那世代的兵丁都从营中灭尽，正如耶和华向他们所起的誓。"},
{volumn:         5,chapter:         2,versenumber:        15,verse:"耶和华的手也攻击他们，将他们从营中除灭，直到灭尽。"},
{volumn:         5,chapter:         2,versenumber:        16,verse:"“兵丁从民中都灭尽死亡以后，"},
{volumn:         5,chapter:         2,versenumber:        17,verse:"耶和华吩咐我说："},
{volumn:         5,chapter:         2,versenumber:        18,verse:"‘你今天要从摩押的境界亚珥经过，"},
{volumn:         5,chapter:         2,versenumber:        19,verse:"走近亚扪人之地，不可扰害他们，也不可与他们争战。亚扪人的地，我不赐给你们为业，因我已将那地赐给罗得的子孙为业。’"},
{volumn:         5,chapter:         2,versenumber:        20,verse:"那地也算为利乏音人之地，先前利乏音人住在那里，亚扪人称他们为散送冥。"},
{volumn:         5,chapter:         2,versenumber:        21,verse:"那民众多，身体高大，像亚衲人一样，但耶和华从亚扪人面前除灭他们，亚扪人就得了他们的地，接着居住。"},
{volumn:         5,chapter:         2,versenumber:        22,verse:"正如耶和华从前为住西珥的以扫子孙将何利人从他们面前除灭、他们得了何利人的地、接着居住一样，直到今日。"},
{volumn:         5,chapter:         2,versenumber:        23,verse:"从迦斐托出来的迦斐托人将先前住在乡村直到迦萨的亚卫人除灭，接着居住。"},
{volumn:         5,chapter:         2,versenumber:        24,verse:"你们起来前往，过亚嫩谷；我已将亚摩利人希实本王西宏和他的地交在你手中，你要与他争战，得他的地为业。"},
{volumn:         5,chapter:         2,versenumber:        25,verse:"从今日起，我要使天下万民听见你的名声都惊恐惧怕，且因你发颤伤恸。”"},
{volumn:         5,chapter:         2,versenumber:        26,verse:"“我从基底莫的旷野差遣使者去见希实本王西宏，用和睦的话说："},
{volumn:         5,chapter:         2,versenumber:        27,verse:"‘求你容我从你的地经过，只走大道，不偏左右。"},
{volumn:         5,chapter:         2,versenumber:        28,verse:"你可以卖粮给我吃，也可以卖水给我喝，只要容我步行过去，"},
{volumn:         5,chapter:         2,versenumber:        29,verse:"就如住西珥的以扫子孙和住亚珥的摩押人待我一样，等我过了约旦河，好进入耶和华我们　神所赐给我们的地。’"},
{volumn:         5,chapter:         2,versenumber:        30,verse:"但希实本王西宏不容我们从他那里经过；因为耶和华你的　神使他心中刚硬，性情顽梗，为要将他交在你手中，像今日一样。"},
{volumn:         5,chapter:         2,versenumber:        31,verse:"耶和华对我说：‘从此起首，我要将西宏和他的地交给你；你要得他的地为业。’"},
{volumn:         5,chapter:         2,versenumber:        32,verse:"那时，西宏和他的众民出来攻击我们，在雅杂与我们交战。"},
{volumn:         5,chapter:         2,versenumber:        33,verse:"耶和华我们的　神将他交给我们，我们就把他和他的儿子，并他的众民，都击杀了。"},
{volumn:         5,chapter:         2,versenumber:        34,verse:"我们夺了他的一切城邑，将有人烟的各城，连女人带孩子，尽都毁灭，没有留下一个。"},
{volumn:         5,chapter:         2,versenumber:        35,verse:"惟有牲畜和所夺的各城，并其中的财物，都取为自己的掠物。"},
{volumn:         5,chapter:         2,versenumber:        36,verse:"从亚嫩谷边的亚罗珥和谷中的城，直到基列，耶和华我们的　神都交给我们了，没有一座城高得使我们不能攻取的。"},
{volumn:         5,chapter:         2,versenumber:        37,verse:"惟有亚扪人之地，凡靠近雅博河的地，并山地的城邑，与耶和华我们　神所禁止我们去的地方，都没有挨近。”"},
{volumn:         5,chapter:         3,versenumber:         1,verse:"“以后，我们转回，向巴珊去。巴珊王噩和他的众民都出来，在以得来与我们交战。"},
{volumn:         5,chapter:         3,versenumber:         2,verse:"耶和华对我说：‘不要怕他！因我已将他和他的众民，并他的地，都交在你手中；你要待他像从前待住希实本的亚摩利王西宏一样。’"},
{volumn:         5,chapter:         3,versenumber:         3,verse:"于是耶和华我们的　神也将巴珊王噩和他的众民都交在我们手中；我们杀了他们，没有留下一个。"},
{volumn:         5,chapter:         3,versenumber:         4,verse:"那时，我们夺了他所有的城，共有六十座，没有一座城不被我们所夺。这为亚珥歌伯的全境，就是巴珊地噩王的国。"},
{volumn:         5,chapter:         3,versenumber:         5,verse:"这些城都有坚固的高墙，有门有闩。此外还有许多无城墙的乡村。"},
{volumn:         5,chapter:         3,versenumber:         6,verse:"我们将这些都毁灭了，像从前待希实本王西宏一样，把有人烟的各城，连女人带孩子，尽都毁灭；"},
{volumn:         5,chapter:         3,versenumber:         7,verse:"惟有一切牲畜和城中的财物都取为自己的掠物。"},
{volumn:         5,chapter:         3,versenumber:         8,verse:"那时，我们从约旦河东两个亚摩利王的手将亚嫩谷直到黑门山之地夺过来（"},
{volumn:         5,chapter:         3,versenumber:         9,verse:"这黑门山，西顿人称为西连，亚摩利人称为示尼珥），"},
{volumn:         5,chapter:         3,versenumber:        10,verse:"就是夺了平原的各城、基列全地、巴珊全地，直到撒迦和以得来，都是巴珊王噩国内的城邑。（"},
{volumn:         5,chapter:         3,versenumber:        11,verse:"利乏音人所剩下的只有巴珊王噩。他的床是铁的，长九肘，宽四肘，都是以人肘为度。现今岂不是在亚扪人的拉巴吗？）”"},
{volumn:         5,chapter:         3,versenumber:        12,verse:"“那时，我们得了这地。从亚嫩谷边的亚罗珥起，我将基列山地的一半，并其中的城邑，都给了流便人和迦得人。"},
{volumn:         5,chapter:         3,versenumber:        13,verse:"其余的基列地和巴珊全地，就是噩王的国，我给了玛拿西半支派。亚珥歌伯全地乃是巴珊全地；这叫做利乏音人之地。"},
{volumn:         5,chapter:         3,versenumber:        14,verse:"玛拿西的子孙睚珥占了亚珥歌伯全境，直到基述人和玛迦人的交界，就按自己的名称这巴珊地为哈倭特·睚珥，直到今日。"},
{volumn:         5,chapter:         3,versenumber:        15,verse:"我又将基列给了玛吉。"},
{volumn:         5,chapter:         3,versenumber:        16,verse:"从基列到亚嫩谷，以谷中为界，直到亚扪人交界的雅博河，我给了流便人和迦得人，"},
{volumn:         5,chapter:         3,versenumber:        17,verse:"又将亚拉巴和靠近约旦河之地，从基尼烈直到亚拉巴海，就是盐海，并毗斯迦山根东边之地，都给了他们。"},
{volumn:         5,chapter:         3,versenumber:        18,verse:"“那时，我吩咐你们说：‘耶和华你们的　神已将这地赐给你们为业；你们所有的勇士都要带着兵器，在你们的弟兄以色列人前面过去。"},
{volumn:         5,chapter:         3,versenumber:        19,verse:"但你们的妻子、孩子、牲畜（我知道你们有许多的牲畜）可以住在我所赐给你们的各城里。"},
{volumn:         5,chapter:         3,versenumber:        20,verse:"等到你们弟兄在约旦河那边，也得耶和华你们　神所赐给他们的地，又使他们得享平安，与你们一样，你们才可以回到我所赐给你们为业之地。’"},
{volumn:         5,chapter:         3,versenumber:        21,verse:"那时我吩咐约书亚说：‘你亲眼看见了耶和华你　神向这二王所行的；耶和华也必向你所要去的各国照样行。"},
{volumn:         5,chapter:         3,versenumber:        22,verse:"你不要怕他们，因那为你争战的是耶和华你的　神。’”"},
{volumn:         5,chapter:         3,versenumber:        23,verse:"“那时，我恳求耶和华说："},
{volumn:         5,chapter:         3,versenumber:        24,verse:"‘主耶和华啊，你已将你的大力大能显给仆人看。在天上，在地下，有什么神能像你行事、像你有大能的作为呢？"},
{volumn:         5,chapter:         3,versenumber:        25,verse:"求你容我过去，看约旦河那边的美地，就是那佳美的山地和黎巴嫩。’"},
{volumn:         5,chapter:         3,versenumber:        26,verse:"但耶和华因你们的缘故向我发怒，不应允我，对我说：‘罢了！你不要向我再提这事。"},
{volumn:         5,chapter:         3,versenumber:        27,verse:"你且上毗斯迦山顶去，向东、西、南、北举目观望，因为你必不能过这约旦河。"},
{volumn:         5,chapter:         3,versenumber:        28,verse:"你却要嘱咐约书亚，勉励他，使他胆壮；因为他必在这百姓前面过去，使他们承受你所要观看之地。’"},
{volumn:         5,chapter:         3,versenumber:        29,verse:"于是我们住在伯·毗珥对面的谷中。”"},
{volumn:         5,chapter:         4,versenumber:         1,verse:"“以色列人哪，现在我所教训你们的律例典章，你们要听从遵行，好叫你们存活，得以进入耶和华你们列祖之　神所赐给你们的地，承受为业。"},
{volumn:         5,chapter:         4,versenumber:         2,verse:"所吩咐你们的话，你们不可加添，也不可删减，好叫你们遵守我所吩咐的，就是耶和华你们　神的命令。"},
{volumn:         5,chapter:         4,versenumber:         3,verse:"耶和华因巴力·毗珥的事所行的，你们亲眼看见了。凡随从巴力·毗珥的人，耶和华你们的　神都从你们中间除灭了。"},
{volumn:         5,chapter:         4,versenumber:         4,verse:"惟有你们专靠耶和华你们　神的人，今日全都存活。"},
{volumn:         5,chapter:         4,versenumber:         5,verse:"我照着耶和华我　神所吩咐的将律例典章教训你们，使你们在所要进去得为业的地上遵行。"},
{volumn:         5,chapter:         4,versenumber:         6,verse:"所以你们要谨守遵行；这就是你们在万民眼前的智慧、聪明。他们听见这一切律例，必说：‘这大国的人真是有智慧，有聪明！’"},
{volumn:         5,chapter:         4,versenumber:         7,verse:"哪一大国的人有神与他们相近，像耶和华我们的　神、在我们求告他的时候与我们相近呢？"},
{volumn:         5,chapter:         4,versenumber:         8,verse:"又哪一大国有这样公义的律例典章、像我今日在你们面前所陈明的这一切律法呢？"},
{volumn:         5,chapter:         4,versenumber:         9,verse:"“你只要谨慎，殷勤保守你的心灵，免得忘记你亲眼所看见的事，又免得你一生、这事离开你的心；总要传给你的子子孙孙。"},
{volumn:         5,chapter:         4,versenumber:        10,verse:"你在何烈山站在耶和华你　神面前的那日，耶和华对我说：‘你为我招聚百姓，我要叫他们听见我的话，使他们存活在世的日子，可以学习敬畏我，又可以教训儿女这样行。’"},
{volumn:         5,chapter:         4,versenumber:        11,verse:"那时你们近前来，站在山下；山上有火焰冲天，并有昏黑、密云、幽暗。"},
{volumn:         5,chapter:         4,versenumber:        12,verse:"耶和华从火焰中对你们说话，你们只听见声音，却没有看见形像。"},
{volumn:         5,chapter:         4,versenumber:        13,verse:"他将所吩咐你们当守的约指示你们，就是十条诫，并将这诫写在两块石版上。"},
{volumn:         5,chapter:         4,versenumber:        14,verse:"那时耶和华又吩咐我将律例典章教训你们，使你们在所要过去得为业的地上遵行。”"},
{volumn:         5,chapter:         4,versenumber:        15,verse:"“所以，你们要分外谨慎；因为耶和华在何烈山、从火中对你们说话的那日，你们没有看见什么形像。"},
{volumn:         5,chapter:         4,versenumber:        16,verse:"惟恐你们败坏自己，雕刻偶像，仿佛什么男像女像，"},
{volumn:         5,chapter:         4,versenumber:        17,verse:"或地上走兽的像，或空中飞鸟的像，"},
{volumn:         5,chapter:         4,versenumber:        18,verse:"或地上爬物的像，或地底下水中鱼的像。"},
{volumn:         5,chapter:         4,versenumber:        19,verse:"又恐怕你向天举目观看，见耶和华你的　神为天下万民所摆列的日月星，就是天上的万象，自己便被勾引敬拜侍奉它。"},
{volumn:         5,chapter:         4,versenumber:        20,verse:"耶和华将你们从埃及领出来，脱离铁炉，要特作自己产业的子民，像今日一样。"},
{volumn:         5,chapter:         4,versenumber:        21,verse:"耶和华又因你们的缘故向我发怒，起誓必不容我过约旦河，也不容我进入耶和华你　神所赐你为业的那美地。"},
{volumn:         5,chapter:         4,versenumber:        22,verse:"我只得死在这地，不能过约旦河；但你们必过去得那美地。"},
{volumn:         5,chapter:         4,versenumber:        23,verse:"你们要谨慎，免得忘记耶和华你们　神与你们所立的约，为自己雕刻偶像，就是耶和华你　神所禁止你做的偶像；"},
{volumn:         5,chapter:         4,versenumber:        24,verse:"因为耶和华你的　神乃是烈火，是忌邪的　神。"},
{volumn:         5,chapter:         4,versenumber:        25,verse:"“你们在那地住久了，生子生孙，就雕刻偶像，仿佛什么形像，败坏自己，行耶和华你　神眼中看为恶的事，惹他发怒。"},
{volumn:         5,chapter:         4,versenumber:        26,verse:"我今日呼天唤地向你们作见证，你们必在过约旦河得为业的地上速速灭尽！你们不能在那地上长久，必尽行除灭。"},
{volumn:         5,chapter:         4,versenumber:        27,verse:"耶和华必使你们分散在万民中；在他所领你们到的万国里，你们剩下的人数稀少。"},
{volumn:         5,chapter:         4,versenumber:        28,verse:"在那里，你们必侍奉人手所造的神，就是用木石造成、不能看、不能听、不能吃、不能闻的神。"},
{volumn:         5,chapter:         4,versenumber:        29,verse:"但你们在那里必寻求耶和华你的　神。你尽心尽性寻求他的时候，就必寻见。"},
{volumn:         5,chapter:         4,versenumber:        30,verse:"日后你遭遇一切患难的时候，你必归回耶和华你的　神，听从他的话。"},
{volumn:         5,chapter:         4,versenumber:        31,verse:"耶和华你　神原是有怜悯的　神；他总不撇下你，不灭绝你，也不忘记他起誓与你列祖所立的约。"},
{volumn:         5,chapter:         4,versenumber:        32,verse:"“你且考察在你以前的世代，自　神造人在世以来，从天这边到天那边，曾有何民听见　神在火中说话的声音，像你听见还能存活呢？这样的大事何曾有、何曾听见呢？"},
{volumn:         5,chapter:         4,versenumber:        33,verse:""},
{volumn:         5,chapter:         4,versenumber:        34,verse:"神何曾从别的国中将一国的人民领出来，用试验、神迹、奇事、争战、大能的手，和伸出来的膀臂，并大可畏的事，像耶和华你们的　神在埃及，在你们眼前为你们所行的一切事呢？"},
{volumn:         5,chapter:         4,versenumber:        35,verse:"这是显给你看，要使你知道，惟有耶和华他是　神，除他以外，再无别神。"},
{volumn:         5,chapter:         4,versenumber:        36,verse:"他从天上使你听见他的声音，为要教训你，又在地上使你看见他的烈火，并且听见他从火中所说的话。"},
{volumn:         5,chapter:         4,versenumber:        37,verse:"因他爱你的列祖，所以拣选他们的后裔，用大能亲自领你出了埃及，"},
{volumn:         5,chapter:         4,versenumber:        38,verse:"要将比你强大的国民从你面前赶出，领你进去，将他们的地赐你为业，像今日一样。"},
{volumn:         5,chapter:         4,versenumber:        39,verse:"所以，今日你要知道，也要记在心上，天上地下惟有耶和华他是　神，除他以外，再无别神。"},
{volumn:         5,chapter:         4,versenumber:        40,verse:"我今日将他的律例诫命晓谕你，你要遵守，使你和你的子孙可以得福，并使你的日子在耶和华你　神所赐的地上得以长久。”"},
{volumn:         5,chapter:         4,versenumber:        41,verse:"“那时，摩西在约旦河东，向日出之地，分定三座城，"},
{volumn:         5,chapter:         4,versenumber:        42,verse:"使那素无仇恨、无心杀了人的，可以逃到这三城之中的一座城，就得存活："},
{volumn:         5,chapter:         4,versenumber:        43,verse:"为流便人分定旷野平原的比悉；为迦得人分定基列的拉末；为玛拿西人分定巴珊的哥兰。”"},
{volumn:         5,chapter:         4,versenumber:        44,verse:"摩西在以色列人面前所陈明的律法，"},
{volumn:         5,chapter:         4,versenumber:        45,verse:"就是摩西在以色列人出埃及后所传给他们的法度、律例、典章；"},
{volumn:         5,chapter:         4,versenumber:        46,verse:"在约旦河东伯·毗珥对面的谷中，在住希实本、亚摩利王西宏之地；这西宏是摩西和以色列人出埃及后所击杀的。"},
{volumn:         5,chapter:         4,versenumber:        47,verse:"他们得了他的地，又得了巴珊王噩的地，就是两个亚摩利王，在约旦河东向日出之地。"},
{volumn:         5,chapter:         4,versenumber:        48,verse:"从亚嫩谷边的亚罗珥，直到西云山，就是黑门山。"},
{volumn:         5,chapter:         4,versenumber:        49,verse:"还有约旦河东的全亚拉巴，直到亚拉巴海，靠近毗斯迦山根。"},
{volumn:         5,chapter:         5,versenumber:         1,verse:"摩西将以色列众人召了来，对他们说：“以色列人哪，我今日晓谕你们的律例典章，你们要听，可以学习，谨守遵行。"},
{volumn:         5,chapter:         5,versenumber:         2,verse:"耶和华我们的　神在何烈山与我们立约。"},
{volumn:         5,chapter:         5,versenumber:         3,verse:"这约不是与我们列祖立的，乃是与我们今日在这里存活之人立的。"},
{volumn:         5,chapter:         5,versenumber:         4,verse:"耶和华在山上，从火中，面对面与你们说话。"},
{volumn:         5,chapter:         5,versenumber:         5,verse:"那时我站在耶和华和你们中间，要将耶和华的话传给你们；因为你们惧怕那火，没有上山。）说："},
{volumn:         5,chapter:         5,versenumber:         6,verse:"“‘我是耶和华你的　神，曾将你从埃及地为奴之家领出来。"},
{volumn:         5,chapter:         5,versenumber:         7,verse:"“‘除了我以外，你不可有别的神。"},
{volumn:         5,chapter:         5,versenumber:         8,verse:"“‘不可为自己雕刻偶像，也不可做什么形像，仿佛上天、下地和地底下、水中的百物。"},
{volumn:         5,chapter:         5,versenumber:         9,verse:"不可跪拜那些像，也不可侍奉它，因为我耶和华你的　神是忌邪的　神。恨我的，我必追讨他的罪，自父及子，直到三、四代；"},
{volumn:         5,chapter:         5,versenumber:        10,verse:"爱我、守我诫命的，我必向他们发慈爱，直到千代。"},
{volumn:         5,chapter:         5,versenumber:        11,verse:"“‘不可妄称耶和华你　神的名；因为妄称耶和华名的，耶和华必不以他为无罪。"},
{volumn:         5,chapter:         5,versenumber:        12,verse:"“‘当照耶和华你　神所吩咐的守安息日为圣日。"},
{volumn:         5,chapter:         5,versenumber:        13,verse:"六日要劳碌做你一切的工，"},
{volumn:         5,chapter:         5,versenumber:        14,verse:"但第七日是向耶和华你　神当守的安息日。这一日，你和你的儿女、仆婢、牛、驴、牲畜，并在你城里寄居的客旅，无论何工都不可做，使你的仆婢可以和你一样安息。"},
{volumn:         5,chapter:         5,versenumber:        15,verse:"你也要记念你在埃及地作过奴仆；耶和华你　神用大能的手和伸出来的膀臂将你从那里领出来。因此，耶和华你的　神吩咐你守安息日。"},
{volumn:         5,chapter:         5,versenumber:        16,verse:"“‘当照耶和华你　神所吩咐的孝敬父母，使你得福，并使你的日子在耶和华你　神所赐你的地上得以长久。"},
{volumn:         5,chapter:         5,versenumber:        17,verse:"“‘不可杀人。"},
{volumn:         5,chapter:         5,versenumber:        18,verse:"“‘不可奸淫。"},
{volumn:         5,chapter:         5,versenumber:        19,verse:"“‘不可偷盗。"},
{volumn:         5,chapter:         5,versenumber:        20,verse:"“‘不可作假见证陷害人。"},
{volumn:         5,chapter:         5,versenumber:        21,verse:"“‘不可贪恋人的妻子；也不可贪图人的房屋、田地、仆婢、牛、驴，并他一切所有的。’"},
{volumn:         5,chapter:         5,versenumber:        22,verse:"“这些话是耶和华在山上，从火中、云中、幽暗中，大声晓谕你们全会众的；此外并没有添别的话。他就把这话写在两块石版上，交给我了。”"},
{volumn:         5,chapter:         5,versenumber:        23,verse:"“那时，火焰烧山，你们听见从黑暗中出来的声音；你们支派中所有的首领和长老都来就近我，"},
{volumn:         5,chapter:         5,versenumber:        24,verse:"说：‘看哪，耶和华我们　神将他的荣光和他的大能显给我们看，我们又听见他的声音从火中出来。今日我们得见　神与人说话，人还存活。"},
{volumn:         5,chapter:         5,versenumber:        25,verse:"现在这大火将要烧灭我们，我们何必冒死呢？若再听见耶和华我们　神的声音就必死亡。"},
{volumn:         5,chapter:         5,versenumber:        26,verse:"凡属血气的，曾有何人听见永生　神的声音从火中出来，像我们听见还能存活呢？"},
{volumn:         5,chapter:         5,versenumber:        27,verse:"求你近前去，听耶和华我们　神所要说的一切话，将他对你说的话都传给我们，我们就听从遵行。’"},
{volumn:         5,chapter:         5,versenumber:        28,verse:"“你们对我说的话，耶和华都听见了。耶和华对我说：‘这百姓的话，我听见了；他们所说的都是。"},
{volumn:         5,chapter:         5,versenumber:        29,verse:"惟愿他们存这样的心敬畏我，常遵守我的一切诫命，使他们和他们的子孙永远得福。"},
{volumn:         5,chapter:         5,versenumber:        30,verse:"你去对他们说：你们回帐棚去吧！"},
{volumn:         5,chapter:         5,versenumber:        31,verse:"至于你，可以站在我这里，我要将一切诫命、律例、典章传给你；你要教训他们，使他们在我赐他们为业的地上遵行。’"},
{volumn:         5,chapter:         5,versenumber:        32,verse:"所以，你们要照耶和华你们　神所吩咐的谨守遵行，不可偏离左右。"},
{volumn:         5,chapter:         5,versenumber:        33,verse:"耶和华你们　神所吩咐你们行的，你们都要去行，使你们可以存活得福，并使你们的日子在所要承受的地上得以长久。”"},
{volumn:         5,chapter:         6,versenumber:         1,verse:"“这是耶和华你们　神所吩咐教训你们的诫命、律例、典章，使你们在所要过去得为业的地上遵行，"},
{volumn:         5,chapter:         6,versenumber:         2,verse:"好叫你和你子子孙孙一生敬畏耶和华你的　神，谨守他的一切律例诫命，就是我所吩咐你的，使你的日子得以长久。"},
{volumn:         5,chapter:         6,versenumber:         3,verse:"以色列啊，你要听，要谨守遵行，使你可以在那流奶与蜜之地得以享福，人数极其增多，正如耶和华你列祖的　神所应许你的。"},
{volumn:         5,chapter:         6,versenumber:         4,verse:"“以色列啊，你要听！耶和华我们　神是独一的主。"},
{volumn:         5,chapter:         6,versenumber:         5,verse:"你要尽心、尽性、尽力爱耶和华你的　神。"},
{volumn:         5,chapter:         6,versenumber:         6,verse:"我今日所吩咐你的话都要记在心上，"},
{volumn:         5,chapter:         6,versenumber:         7,verse:"也要殷勤教训你的儿女。无论你坐在家里，行在路上，躺下，起来，都要谈论。"},
{volumn:         5,chapter:         6,versenumber:         8,verse:"也要系在手上为记号，戴在额上为经文；"},
{volumn:         5,chapter:         6,versenumber:         9,verse:"又要写在你房屋的门框上，并你的城门上。”"},
{volumn:         5,chapter:         6,versenumber:        10,verse:"“耶和华你的　神领你进他向你列祖亚伯拉罕、以撒、雅各起誓应许给你的地。那里有城邑，又大又美，非你所建造的；"},
{volumn:         5,chapter:         6,versenumber:        11,verse:"有房屋，装满各样美物，非你所装满的；有凿成的水井，非你所凿成的；还有葡萄园、橄榄园，非你所栽种的；你吃了而且饱足。"},
{volumn:         5,chapter:         6,versenumber:        12,verse:"那时你要谨慎，免得你忘记将你从埃及地、为奴之家领出来的耶和华。"},
{volumn:         5,chapter:         6,versenumber:        13,verse:"你要敬畏耶和华你的　神，侍奉他，指着他的名起誓。"},
{volumn:         5,chapter:         6,versenumber:        14,verse:"不可随从别神，就是你们四围国民的神；"},
{volumn:         5,chapter:         6,versenumber:        15,verse:"因为在你们中间的耶和华你　神是忌邪的　神，惟恐耶和华你　神的怒气向你发作，就把你从地上除灭。"},
{volumn:         5,chapter:         6,versenumber:        16,verse:"“你们不可试探耶和华你们的　神，像你们在玛撒那样试探他。"},
{volumn:         5,chapter:         6,versenumber:        17,verse:"要留意遵守耶和华你们　神所吩咐的诫命、法度、律例。"},
{volumn:         5,chapter:         6,versenumber:        18,verse:"耶和华眼中看为正、看为善的，你都要遵行，使你可以享福，并可以进去得耶和华向你列祖起誓应许的那美地，"},
{volumn:         5,chapter:         6,versenumber:        19,verse:"照耶和华所说的，从你面前撵出你的一切仇敌。"},
{volumn:         5,chapter:         6,versenumber:        20,verse:"“日后，你的儿子问你说：‘耶和华我们　神吩咐你们的这些法度、律例、典章是什么意思呢？’"},
{volumn:         5,chapter:         6,versenumber:        21,verse:"你就告诉你的儿子说：‘我们在埃及作过法老的奴仆；耶和华用大能的手将我们从埃及领出来，"},
{volumn:         5,chapter:         6,versenumber:        22,verse:"在我们眼前，将重大可怕的神迹奇事施行在埃及地和法老并他全家的身上，"},
{volumn:         5,chapter:         6,versenumber:        23,verse:"将我们从那里领出来，要领我们进入他向我们列祖起誓应许之地，把这地赐给我们。"},
{volumn:         5,chapter:         6,versenumber:        24,verse:"耶和华又吩咐我们遵行这一切律例，要敬畏耶和华我们的　神，使我们常得好处，蒙他保全我们的生命，像今日一样。"},
{volumn:         5,chapter:         6,versenumber:        25,verse:"我们若照耶和华我们　神所吩咐的一切诫命谨守遵行，这就是我们的义了。’”"},
{volumn:         5,chapter:         7,versenumber:         1,verse:"“耶和华你　神领你进入要得为业之地，从你面前赶出许多国民，就是赫人、革迦撒人、亚摩利人、迦南人、比利洗人、希未人、耶布斯人，共七国的民，都比你强大。"},
{volumn:         5,chapter:         7,versenumber:         2,verse:"耶和华你　神将他们交给你击杀，那时你要把他们灭绝净尽，不可与他们立约，也不可怜恤他们。"},
{volumn:         5,chapter:         7,versenumber:         3,verse:"不可与他们结亲。不可将你的女儿嫁他们的儿子，也不可叫你的儿子娶他们的女儿；"},
{volumn:         5,chapter:         7,versenumber:         4,verse:"因为他必使你儿子转离不跟从主，去侍奉别神，以致耶和华的怒气向你们发作，就速速地将你们灭绝。"},
{volumn:         5,chapter:         7,versenumber:         5,verse:"你们却要这样待他们：拆毁他们的祭坛，打碎他们的柱像，砍下他们的木偶，用火焚烧他们雕刻的偶像。"},
{volumn:         5,chapter:         7,versenumber:         6,verse:"因为你归耶和华你　神为圣洁的民；耶和华你　神从地上的万民中拣选你，特作自己的子民。"},
{volumn:         5,chapter:         7,versenumber:         7,verse:"“耶和华专爱你们，拣选你们，并非因你们的人数多于别民，原来你们的人数在万民中是最少的。"},
{volumn:         5,chapter:         7,versenumber:         8,verse:"只因耶和华爱你们，又因要守他向你们列祖所起的誓，就用大能的手领你们出来，从为奴之家救赎你们脱离埃及王法老的手。"},
{volumn:         5,chapter:         7,versenumber:         9,verse:"所以，你要知道耶和华你的　神，他是　神，是信实的　神；向爱他、守他诫命的人守约，施慈爱，直到千代；"},
{volumn:         5,chapter:         7,versenumber:        10,verse:"向恨他的人当面报应他们，将他们灭绝。凡恨他的人必报应他们，决不迟延。"},
{volumn:         5,chapter:         7,versenumber:        11,verse:"所以，你要谨守遵行我今日所吩咐你的诫命、律例、典章。”"},
{volumn:         5,chapter:         7,versenumber:        12,verse:"“你们果然听从这些典章，谨守遵行，耶和华你　神就必照他向你列祖所起的誓守约，施慈爱。"},
{volumn:         5,chapter:         7,versenumber:        13,verse:"他必爱你，赐福与你，使你人数增多，也必在他向你列祖起誓应许给你的地上赐福与你身所生的，地所产的，并你的五谷、新酒，和油，以及牛犊、羊羔。"},
{volumn:         5,chapter:         7,versenumber:        14,verse:"你必蒙福胜过万民；你们的男女没有不能生养的，牲畜也没有不能生育的。"},
{volumn:         5,chapter:         7,versenumber:        15,verse:"耶和华必使一切的病症离开你；你所知道埃及各样的恶疾，他不加在你身上，只加在一切恨你的人身上。"},
{volumn:         5,chapter:         7,versenumber:        16,verse:"耶和华你　神所要交给你的一切人民，你要将他们除灭；你眼不可顾惜他们。你也不可侍奉他们的神，因这必成为你的网罗。"},
{volumn:         5,chapter:         7,versenumber:        17,verse:"“你若心里说，这些国的民比我更多，我怎能赶出他们呢？"},
{volumn:         5,chapter:         7,versenumber:        18,verse:"你不要惧怕他们，要牢牢记念耶和华你　神向法老和埃及全地所行的事，"},
{volumn:         5,chapter:         7,versenumber:        19,verse:"就是你亲眼所看见的大试验、神迹、奇事，和大能的手，并伸出来的膀臂，都是耶和华你　神领你出来所用的。耶和华你　神必照样待你所惧怕的一切人民。"},
{volumn:         5,chapter:         7,versenumber:        20,verse:"并且耶和华你　神必打发黄蜂飞到他们中间，直到那剩下而藏躲的人从你面前灭亡。"},
{volumn:         5,chapter:         7,versenumber:        21,verse:"你不要因他们惊恐，因为耶和华你　神在你们中间是大而可畏的　神。"},
{volumn:         5,chapter:         7,versenumber:        22,verse:"耶和华你　神必将这些国的民从你面前渐渐赶出；你不可把他们速速灭尽，恐怕野地的兽多起来害你。"},
{volumn:         5,chapter:         7,versenumber:        23,verse:"耶和华你　神必将他们交给你，大大地扰乱他们，直到他们灭绝了；"},
{volumn:         5,chapter:         7,versenumber:        24,verse:"又要将他们的君王交在你手中，你就使他们的名从天下消灭。必无一人能在你面前站立得住，直到你将他们灭绝了。"},
{volumn:         5,chapter:         7,versenumber:        25,verse:"他们雕刻的神像，你们要用火焚烧；其上的金银，你不可贪图，也不可收取，免得你因此陷入网罗；这原是耶和华你　神所憎恶的。"},
{volumn:         5,chapter:         7,versenumber:        26,verse:"可憎的物，你不可带进家去；不然，你就成了当毁灭的，与那物一样。你要十分厌恶，十分憎嫌，因为这是当毁灭的物。”"},
{volumn:         5,chapter:         8,versenumber:         1,verse:"“我今日所吩咐的一切诫命，你们要谨守遵行，好叫你们存活，人数增多，且进去得耶和华向你们列祖起誓应许的那地。"},
{volumn:         5,chapter:         8,versenumber:         2,verse:"你也要记念耶和华你的　神在旷野引导你这四十年，是要苦炼你，试验你，要知道你心内如何，肯守他的诫命不肯。"},
{volumn:         5,chapter:         8,versenumber:         3,verse:"他苦炼你，任你饥饿，将你和你列祖所不认识的吗哪赐给你吃，使你知道，人活着不是单靠食物，乃是靠耶和华口里所出的一切话。"},
{volumn:         5,chapter:         8,versenumber:         4,verse:"这四十年，你的衣服没有穿破，你的脚也没有肿。"},
{volumn:         5,chapter:         8,versenumber:         5,verse:"你当心里思想，耶和华你　神管教你，好像人管教儿子一样。"},
{volumn:         5,chapter:         8,versenumber:         6,verse:"你要谨守耶和华你　神的诫命，遵行他的道，敬畏他。"},
{volumn:         5,chapter:         8,versenumber:         7,verse:"因为耶和华你　神领你进入美地，那地有河，有泉，有源，从山谷中流出水来。"},
{volumn:         5,chapter:         8,versenumber:         8,verse:"那地有小麦、大麦、葡萄树、无花果树、石榴树、橄榄树，和蜜。"},
{volumn:         5,chapter:         8,versenumber:         9,verse:"你在那地不缺食物，一无所缺。那地的石头是铁，山内可以挖铜。"},
{volumn:         5,chapter:         8,versenumber:        10,verse:"你吃得饱足，就要称颂耶和华你的　神，因他将那美地赐给你了。”"},
{volumn:         5,chapter:         8,versenumber:        11,verse:"“你要谨慎，免得忘记耶和华你的　神，不守他的诫命、典章、律例，就是我今日所吩咐你的；"},
{volumn:         5,chapter:         8,versenumber:        12,verse:"恐怕你吃得饱足，建造美好的房屋居住，"},
{volumn:         5,chapter:         8,versenumber:        13,verse:"你的牛羊加多，你的金银增添，并你所有的全都加增，"},
{volumn:         5,chapter:         8,versenumber:        14,verse:"你就心高气傲，忘记耶和华你的　神，就是将你从埃及地为奴之家领出来的，"},
{volumn:         5,chapter:         8,versenumber:        15,verse:"引你经过那大而可怕的旷野，那里有火蛇、蝎子、干旱无水之地。他曾为你使水从坚硬的磐石中流出来，"},
{volumn:         5,chapter:         8,versenumber:        16,verse:"又在旷野将你列祖所不认识的吗哪赐给你吃，是要苦炼你，试验你，叫你终久享福；"},
{volumn:         5,chapter:         8,versenumber:        17,verse:"恐怕你心里说：‘这货财是我力量、我能力得来的。’"},
{volumn:         5,chapter:         8,versenumber:        18,verse:"你要记念耶和华你的　神，因为得货财的力量是他给你的，为要坚定他向你列祖起誓所立的约，像今日一样。"},
{volumn:         5,chapter:         8,versenumber:        19,verse:"你若忘记耶和华你的　神，随从别神，侍奉敬拜，你们必定灭亡；这是我今日警戒你们的。"},
{volumn:         5,chapter:         8,versenumber:        20,verse:"耶和华在你们面前怎样使列国的民灭亡，你们也必照样灭亡，因为你们不听从耶和华你们　神的话。”"},
{volumn:         5,chapter:         9,versenumber:         1,verse:"“以色列啊，你当听！你今日要过约旦河，进去赶出比你强大的国民，得着广大坚固、高得顶天的城邑。"},
{volumn:         5,chapter:         9,versenumber:         2,verse:"那民是亚衲族的人，又大又高，是你所知道的；也曾听见有人指着他们说：‘谁能在亚衲族人面前站立得住呢？’"},
{volumn:         5,chapter:         9,versenumber:         3,verse:"你今日当知道，耶和华你的　神在你前面过去，如同烈火，要灭绝他们，将他们制伏在你面前。这样，你就要照耶和华所说的赶出他们，使他们速速灭亡。"},
{volumn:         5,chapter:         9,versenumber:         4,verse:"“耶和华你的　神将这些国民从你面前撵出以后，你心里不可说：‘耶和华将我领进来得这地是因我的义。’其实，耶和华将他们从你面前赶出去是因他们的恶。"},
{volumn:         5,chapter:         9,versenumber:         5,verse:"你进去得他们的地，并不是因你的义，也不是因你心里正直，乃是因这些国民的恶，耶和华你的　神将他们从你面前赶出去，又因耶和华要坚定他向你列祖亚伯拉罕、以撒、雅各起誓所应许的话。"},
{volumn:         5,chapter:         9,versenumber:         6,verse:"“你当知道，耶和华你　神将这美地赐你为业，并不是因你的义；你本是硬着颈项的百姓。"},
{volumn:         5,chapter:         9,versenumber:         7,verse:"你当记念不忘，你在旷野怎样惹耶和华你　神发怒。自从你出了埃及地的那日，直到你们来到这地方，你们时常悖逆耶和华。"},
{volumn:         5,chapter:         9,versenumber:         8,verse:"你们在何烈山又惹耶和华发怒；他恼怒你们，要灭绝你们。"},
{volumn:         5,chapter:         9,versenumber:         9,verse:"我上了山，要领受两块石版，就是耶和华与你们立约的版。那时我在山上住了四十昼夜，没有吃饭，也没有喝水。"},
{volumn:         5,chapter:         9,versenumber:        10,verse:"耶和华把那两块石版交给我，是　神用指头写的。版上所写的是照耶和华在大会的日子、在山上、从火中对你们所说的一切话。"},
{volumn:         5,chapter:         9,versenumber:        11,verse:"过了四十昼夜，耶和华把那两块石版，就是约版，交给我。"},
{volumn:         5,chapter:         9,versenumber:        12,verse:"对我说：‘你起来，赶快下去！因为你从埃及领出来的百姓已经败坏了自己；他们快快地偏离了我所吩咐的道，为自己铸成了偶像。’"},
{volumn:         5,chapter:         9,versenumber:        13,verse:"“耶和华又对我说：‘我看这百姓是硬着颈项的百姓。"},
{volumn:         5,chapter:         9,versenumber:        14,verse:"你且由着我，我要灭绝他们，将他们的名从天下涂抹，使你的后裔比他们成为更大更强的国。’"},
{volumn:         5,chapter:         9,versenumber:        15,verse:"于是我转身下山，山被火烧着，两块约版在我两手之中。"},
{volumn:         5,chapter:         9,versenumber:        16,verse:"我一看见你们得罪了耶和华你们的　神，铸成了牛犊，快快地偏离了耶和华所吩咐你们的道，"},
{volumn:         5,chapter:         9,versenumber:        17,verse:"我就把那两块版从我手中扔下去，在你们眼前摔碎了。"},
{volumn:         5,chapter:         9,versenumber:        18,verse:"因你们所犯的一切罪，行了耶和华眼中看为恶的事，惹他发怒，我就像从前俯伏在耶和华面前四十昼夜，没有吃饭，也没有喝水。"},
{volumn:         5,chapter:         9,versenumber:        19,verse:"我因耶和华向你们大发烈怒，要灭绝你们，就甚害怕；但那次耶和华又应允了我。"},
{volumn:         5,chapter:         9,versenumber:        20,verse:"耶和华也向亚伦甚是发怒，要灭绝他；那时我又为亚伦祈祷。"},
{volumn:         5,chapter:         9,versenumber:        21,verse:"我把那叫你们犯罪所铸的牛犊用火焚烧，又捣碎磨得很细，以致细如灰尘，我就把这灰尘撒在从山上流下来的溪水中。"},
{volumn:         5,chapter:         9,versenumber:        22,verse:"“你们在他备拉、玛撒、基博罗·哈他瓦又惹耶和华发怒。"},
{volumn:         5,chapter:         9,versenumber:        23,verse:"耶和华打发你们离开加低斯·巴尼亚，说：‘你们上去得我所赐给你们的地。’那时你们违背了耶和华你们　神的命令，不信服他，不听从他的话。"},
{volumn:         5,chapter:         9,versenumber:        24,verse:"自从我认识你们以来，你们常常悖逆耶和华。"},
{volumn:         5,chapter:         9,versenumber:        25,verse:"“我因耶和华说要灭绝你们，就在耶和华面前照旧俯伏四十昼夜。"},
{volumn:         5,chapter:         9,versenumber:        26,verse:"我祈祷耶和华说：‘主耶和华啊，求你不要灭绝你的百姓。他们是你的产业，是你用大力救赎的，用大能从埃及领出来的。"},
{volumn:         5,chapter:         9,versenumber:        27,verse:"求你记念你的仆人亚伯拉罕、以撒、雅各，不要想念这百姓的顽梗、邪恶、罪过，"},
{volumn:         5,chapter:         9,versenumber:        28,verse:"免得你领我们出来的那地之人说，耶和华因为不能将这百姓领进他所应许之地，又因恨他们，所以领他们出去，要在旷野杀他们。"},
{volumn:         5,chapter:         9,versenumber:        29,verse:"其实他们是你的百姓，你的产业，是你用大能和伸出来的膀臂领出来的。’”"},
{volumn:         5,chapter:        10,versenumber:         1,verse:"“那时，耶和华吩咐我说：‘你要凿出两块石版，和先前的一样，上山到我这里来，又要做一木柜。"},
{volumn:         5,chapter:        10,versenumber:         2,verse:"你先前摔碎的那版，其上的字我要写在这版上；你要将这版放在柜中。’"},
{volumn:         5,chapter:        10,versenumber:         3,verse:"于是我用皂荚木做了一柜，又凿出两块石版，和先前的一样，手里拿这两块版上山去了。"},
{volumn:         5,chapter:        10,versenumber:         4,verse:"耶和华将那大会之日、在山上从火中所传与你们的十条诫，照先前所写的，写在这版上，将版交给我了。"},
{volumn:         5,chapter:        10,versenumber:         5,verse:"我转身下山，将这版放在我所做的柜中，现今还在那里，正如耶和华所吩咐我的。”"},
{volumn:         5,chapter:        10,versenumber:         6,verse:"以色列人从比罗比尼·亚干（或译：亚干井）起行，到了摩西拉。亚伦死在那里，就葬在那里。他儿子以利亚撒接续他供祭司的职分。"},
{volumn:         5,chapter:        10,versenumber:         7,verse:"他们从那里起行，到了谷歌大，又从谷歌大到了有溪水之地的约巴他。"},
{volumn:         5,chapter:        10,versenumber:         8,verse:"那时，耶和华将利未支派分别出来，抬耶和华的约柜，又侍立在耶和华面前侍奉他，奉他的名祝福，直到今日。"},
{volumn:         5,chapter:        10,versenumber:         9,verse:"所以利未人在他弟兄中无份无业，耶和华是他的产业，正如耶和华你　神所应许他的。"},
{volumn:         5,chapter:        10,versenumber:        10,verse:"“我又像从前在山上住了四十昼夜。那次耶和华也应允我，不忍将你灭绝。"},
{volumn:         5,chapter:        10,versenumber:        11,verse:"耶和华吩咐我说：‘你起来引导这百姓，使他们进去得我向他们列祖起誓应许所赐之地。’”"},
{volumn:         5,chapter:        10,versenumber:        12,verse:"“以色列啊，现在耶和华你　神向你所要的是什么呢？只要你敬畏耶和华你的　神，遵行他的道，爱他，尽心尽性侍奉他，"},
{volumn:         5,chapter:        10,versenumber:        13,verse:"遵守他的诫命律例，就是我今日所吩咐你的，为要叫你得福。"},
{volumn:         5,chapter:        10,versenumber:        14,verse:"看哪，天和天上的天，地和地上所有的，都属耶和华你的　神。"},
{volumn:         5,chapter:        10,versenumber:        15,verse:"耶和华但喜悦你的列祖，爱他们，从万民中拣选他们的后裔，就是你们，像今日一样。"},
{volumn:         5,chapter:        10,versenumber:        16,verse:"所以你们要将心里的污秽除掉，不可再硬着颈项。"},
{volumn:         5,chapter:        10,versenumber:        17,verse:"因为耶和华你们的　神，他是万神之神，万主之主，至大的　神，大有能力，大而可畏，不以貌取人，也不受贿赂。"},
{volumn:         5,chapter:        10,versenumber:        18,verse:"他为孤儿寡妇伸冤，又怜爱寄居的，赐给他衣食。"},
{volumn:         5,chapter:        10,versenumber:        19,verse:"所以你们要怜爱寄居的，因为你们在埃及地也作过寄居的。"},
{volumn:         5,chapter:        10,versenumber:        20,verse:"你要敬畏耶和华你的　神，侍奉他，专靠他，也要指着他的名起誓。"},
{volumn:         5,chapter:        10,versenumber:        21,verse:"他是你所赞美的，是你的　神，为你做了那大而可畏的事，是你亲眼所看见的。"},
{volumn:         5,chapter:        10,versenumber:        22,verse:"你的列祖七十人下埃及；现在耶和华你的　神使你如同天上的星那样多。”"},
{volumn:         5,chapter:        11,versenumber:         1,verse:"“你要爱耶和华你的　神，常守他的吩咐、律例、典章、诫命。"},
{volumn:         5,chapter:        11,versenumber:         2,verse:"你们今日当知道，我本不是和你们的儿女说话；因为他们不知道，也没有看见耶和华你们　神的管教、威严、大能的手，和伸出来的膀臂，"},
{volumn:         5,chapter:        11,versenumber:         3,verse:"并他在埃及中向埃及王法老和其全地所行的神迹奇事；"},
{volumn:         5,chapter:        11,versenumber:         4,verse:"也没有看见他怎样待埃及的军兵、车马，他们追赶你们的时候，耶和华怎样使红海的水淹没他们，将他们灭绝，直到今日，"},
{volumn:         5,chapter:        11,versenumber:         5,verse:"并他在旷野怎样待你们，以致你们来到这地方；"},
{volumn:         5,chapter:        11,versenumber:         6,verse:"也没有看见他怎样待流便子孙以利押的儿子大坍、亚比兰，地怎样在以色列人中间开口，吞了他们和他们的家眷，并帐棚与跟他们的一切活物。"},
{volumn:         5,chapter:        11,versenumber:         7,verse:"惟有你们亲眼看见耶和华所做的一切大事。”"},
{volumn:         5,chapter:        11,versenumber:         8,verse:"“所以，你们要守我今日所吩咐的一切诫命，使你们胆壮，能以进去，得你们所要得的那地，"},
{volumn:         5,chapter:        11,versenumber:         9,verse:"并使你们的日子在耶和华向你们列祖起誓、应许给他们和他们后裔的地上得以长久；那是流奶与蜜之地。"},
{volumn:         5,chapter:        11,versenumber:        10,verse:"你要进去得为业的那地，本不像你出来的埃及地。你在那里撒种，用脚浇灌，像浇灌菜园一样。"},
{volumn:         5,chapter:        11,versenumber:        11,verse:"你们要过去得为业的那地乃是有山有谷、雨水滋润之地，"},
{volumn:         5,chapter:        11,versenumber:        12,verse:"是耶和华你　神所眷顾的；从岁首到年终，耶和华你　神的眼目时常看顾那地。"},
{volumn:         5,chapter:        11,versenumber:        13,verse:"“你们若留意听从我今日所吩咐的诫命，爱耶和华你们的　神，尽心尽性侍奉他，"},
{volumn:         5,chapter:        11,versenumber:        14,verse:"他（原文是我）必按时降秋雨春雨在你们的地上，使你们可以收藏五谷、新酒，和油，"},
{volumn:         5,chapter:        11,versenumber:        15,verse:"也必使你吃得饱足，并使田野为你的牲畜长草。"},
{volumn:         5,chapter:        11,versenumber:        16,verse:"你们要谨慎，免得心中受迷惑，就偏离正路，去侍奉敬拜别神。"},
{volumn:         5,chapter:        11,versenumber:        17,verse:"耶和华的怒气向你们发作，就使天闭塞不下雨，地也不出产，使你们在耶和华所赐给你们的美地上速速灭亡。"},
{volumn:         5,chapter:        11,versenumber:        18,verse:"“你们要将我这话存在心内，留在意中，系在手上为记号，戴在额上为经文；"},
{volumn:         5,chapter:        11,versenumber:        19,verse:"也要教训你们的儿女，无论坐在家里，行在路上，躺下，起来，都要谈论；"},
{volumn:         5,chapter:        11,versenumber:        20,verse:"又要写在房屋的门框上，并城门上，"},
{volumn:         5,chapter:        11,versenumber:        21,verse:"使你们和你们子孙的日子在耶和华向你们列祖起誓、应许给他们的地上得以增多，如天覆地的日子那样多。"},
{volumn:         5,chapter:        11,versenumber:        22,verse:"你们若留意谨守遵行我所吩咐这一切的诫命，爱耶和华你们的　神，行他的道，专靠他，"},
{volumn:         5,chapter:        11,versenumber:        23,verse:"他必从你们面前赶出这一切国民，就是比你们更大更强的国民，你们也要得他们的地。"},
{volumn:         5,chapter:        11,versenumber:        24,verse:"凡你们脚掌所踏之地都必归你们；从旷野和黎巴嫩，并幼发拉底大河，直到西海，都要作你们的境界。"},
{volumn:         5,chapter:        11,versenumber:        25,verse:"必无一人能在你们面前站立得住；耶和华你们的　神必照他所说的，使惧怕惊恐临到你们所踏之地的居民。"},
{volumn:         5,chapter:        11,versenumber:        26,verse:"“看哪，我今日将祝福与咒诅的话都陈明在你们面前。"},
{volumn:         5,chapter:        11,versenumber:        27,verse:"你们若听从耶和华你们　神的诫命，就是我今日所吩咐你们的，就必蒙福。"},
{volumn:         5,chapter:        11,versenumber:        28,verse:"你们若不听从耶和华你们　神的诫命，偏离我今日所吩咐你们的道，去侍奉你们素来所不认识的别神，就必受祸。"},
{volumn:         5,chapter:        11,versenumber:        29,verse:"及至耶和华你的　神领你进入要去得为业的那地，你就要将祝福的话陈明在基利心山上，将咒诅的话陈明在以巴路山上。"},
{volumn:         5,chapter:        11,versenumber:        30,verse:"这二山岂不是在约旦河那边，日落之处，在住亚拉巴的迦南人之地与吉甲相对，靠近摩利橡树吗？"},
{volumn:         5,chapter:        11,versenumber:        31,verse:"你们要过约旦河，进去得耶和华你们　神所赐你们为业之地，在那地居住。"},
{volumn:         5,chapter:        11,versenumber:        32,verse:"你们要谨守遵行我今日在你们面前所陈明的一切律例典章。”"},
{volumn:         5,chapter:        12,versenumber:         1,verse:"“你们存活于世的日子，在耶和华你们列祖的　神所赐你们为业的地上，要谨守遵行的律例典章乃是这些："},
{volumn:         5,chapter:        12,versenumber:         2,verse:"你们要将所赶出的国民侍奉神的各地方，无论是在高山，在小山，在各青翠树下，都毁坏了；"},
{volumn:         5,chapter:        12,versenumber:         3,verse:"也要拆毁他们的祭坛，打碎他们的柱像，用火焚烧他们的木偶，砍下他们雕刻的神像，并将其名从那地方除灭。"},
{volumn:         5,chapter:        12,versenumber:         4,verse:"你们不可照他们那样侍奉耶和华你们的　神。"},
{volumn:         5,chapter:        12,versenumber:         5,verse:"但耶和华你们的　神从你们各支派中选择何处为立他名的居所，你们就当往那里去求问，"},
{volumn:         5,chapter:        12,versenumber:         6,verse:"将你们的燔祭、平安祭、十分取一之物，和手中的举祭，并还愿祭、甘心祭，以及牛群羊群中头生的，都奉到那里。"},
{volumn:         5,chapter:        12,versenumber:         7,verse:"在那里，耶和华你们　神的面前，你们和你们的家属都可以吃，并且因你手所办的一切事蒙耶和华你的　神赐福，就都欢乐。"},
{volumn:         5,chapter:        12,versenumber:         8,verse:"我们今日在这里所行的是各人行自己眼中看为正的事，你们将来不可这样行；"},
{volumn:         5,chapter:        12,versenumber:         9,verse:"因为你们还没有到耶和华你　神所赐你的安息地，所给你的产业。"},
{volumn:         5,chapter:        12,versenumber:        10,verse:"但你们过了约旦河，得以住在耶和华你们　神使你们承受为业之地，又使你们太平，不被四围的一切仇敌扰乱，安然居住。"},
{volumn:         5,chapter:        12,versenumber:        11,verse:"那时要将我所吩咐你们的燔祭、平安祭、十分取一之物，和手中的举祭，并向耶和华许愿献的一切美祭，都奉到耶和华你们　神所选择要立为他名的居所。"},
{volumn:         5,chapter:        12,versenumber:        12,verse:"你们和儿女、仆婢，并住在你们城里无份无业的利未人，都要在耶和华你们的　神面前欢乐。"},
{volumn:         5,chapter:        12,versenumber:        13,verse:"你要谨慎，不可在你所看中的各处献燔祭。"},
{volumn:         5,chapter:        12,versenumber:        14,verse:"惟独耶和华从你那一支派中所选择的地方，你就要在那里献燔祭，行我一切所吩咐你的。"},
{volumn:         5,chapter:        12,versenumber:        15,verse:"“然而，在你各城里都可以照耶和华你　神所赐你的福分，随心所欲宰牲吃肉；无论洁净人不洁净人都可以吃，就如吃羚羊与鹿一般。"},
{volumn:         5,chapter:        12,versenumber:        16,verse:"只是不可吃血，要倒在地上，如同倒水一样。"},
{volumn:         5,chapter:        12,versenumber:        17,verse:"你的五谷、新酒，和油的十分之一，或是牛群羊群中头生的，或是你许愿献的，甘心献的，或是手中的举祭，都不可在你城里吃。"},
{volumn:         5,chapter:        12,versenumber:        18,verse:"但要在耶和华你的　神面前吃，在耶和华你　神所要选择的地方，你和儿女、仆婢，并住在你城里的利未人，都可以吃；也要因你手所办的，在耶和华你　神面前欢乐。"},
{volumn:         5,chapter:        12,versenumber:        19,verse:"你要谨慎，在你所住的地方永不可丢弃利未人。"},
{volumn:         5,chapter:        12,versenumber:        20,verse:"“耶和华你的　神照他所应许扩张你境界的时候，你心里想要吃肉，说：‘我要吃肉’，就可以随心所欲地吃肉。"},
{volumn:         5,chapter:        12,versenumber:        21,verse:"耶和华你　神所选择要立他名的地方若离你太远，就可以照我所吩咐的，将耶和华赐给你的牛羊取些宰了，可以随心所欲在你城里吃。"},
{volumn:         5,chapter:        12,versenumber:        22,verse:"你吃那肉，要像吃羚羊与鹿一般；无论洁净人不洁净人都可以吃。"},
{volumn:         5,chapter:        12,versenumber:        23,verse:"只是你要心意坚定，不可吃血，因为血是生命；不可将血（原文是生命）与肉同吃。"},
{volumn:         5,chapter:        12,versenumber:        24,verse:"不可吃血，要倒在地上，如同倒水一样。"},
{volumn:         5,chapter:        12,versenumber:        25,verse:"不可吃血。这样，你行耶和华眼中看为正的事，你和你的子孙就可以得福。"},
{volumn:         5,chapter:        12,versenumber:        26,verse:"只是你分别为圣的物和你的还愿祭要奉到耶和华所选择的地方去。"},
{volumn:         5,chapter:        12,versenumber:        27,verse:"你的燔祭，连肉带血，都要献在耶和华你　神的坛上。平安祭的血要倒在耶和华你　神的坛上；平安祭的肉，你自己可以吃。"},
{volumn:         5,chapter:        12,versenumber:        28,verse:"你要谨守听从我所吩咐的一切话，行耶和华你　神眼中看为善，看为正的事。这样，你和你的子孙就可以永远享福。”"},
{volumn:         5,chapter:        12,versenumber:        29,verse:"“耶和华你　神将你要去赶出的国民从你面前剪除，你得了他们的地居住，"},
{volumn:         5,chapter:        12,versenumber:        30,verse:"那时就要谨慎，不可在他们除灭之后随从他们的恶俗，陷入网罗，也不可访问他们的神说：‘这些国民怎样侍奉他们的神，我也要照样行。’"},
{volumn:         5,chapter:        12,versenumber:        31,verse:"你不可向耶和华你的　神这样行，因为他们向他们的神行了耶和华所憎嫌所恨恶的一切事，甚至将自己的儿女用火焚烧，献与他们的神。"},
{volumn:         5,chapter:        12,versenumber:        32,verse:"凡我所吩咐的，你们都要谨守遵行，不可加添，也不可删减。"},
{volumn:         5,chapter:        13,versenumber:         1,verse:"“你们中间若有先知或是做梦的起来，向你显个神迹奇事，"},
{volumn:         5,chapter:        13,versenumber:         2,verse:"对你说：‘我们去随从你素来所不认识的别神，侍奉它吧。’他所显的神迹奇事虽有应验，"},
{volumn:         5,chapter:        13,versenumber:         3,verse:"你也不可听那先知或是那做梦之人的话；因为这是耶和华你们的　神试验你们，要知道你们是尽心尽性爱耶和华你们的　神不是。"},
{volumn:         5,chapter:        13,versenumber:         4,verse:"你们要顺从耶和华你们的　神，敬畏他，谨守他的诫命，听从他的话，侍奉他，专靠他。"},
{volumn:         5,chapter:        13,versenumber:         5,verse:"那先知或是那做梦的既用言语叛逆那领你们出埃及地、救赎你脱离为奴之家的耶和华你们的　神，要勾引你离开耶和华你　神所吩咐你行的道，你便要将他治死。这样，就把那恶从你们中间除掉。"},
{volumn:         5,chapter:        13,versenumber:         6,verse:"“你的同胞弟兄，或是你的儿女，或是你怀中的妻，或是如同你性命的朋友，若暗中引诱你，说：‘我们不如去侍奉你和你列祖素来所不认识的别神，"},
{volumn:         5,chapter:        13,versenumber:         7,verse:"是你四围列国的神。’无论是离你近，离你远，从地这边到地那边的神，"},
{volumn:         5,chapter:        13,versenumber:         8,verse:"你不可依从他，也不可听从他，眼不可顾惜他。你不可怜恤他，也不可遮庇他，"},
{volumn:         5,chapter:        13,versenumber:         9,verse:"总要杀他；你先下手，然后众民也下手，将他治死。"},
{volumn:         5,chapter:        13,versenumber:        10,verse:"要用石头打死他，因为他想要勾引你离开那领你出埃及地为奴之家的耶和华你的　神。"},
{volumn:         5,chapter:        13,versenumber:        11,verse:"以色列众人都要听见害怕，就不敢在你们中间再行这样的恶了。"},
{volumn:         5,chapter:        13,versenumber:        12,verse:"“在耶和华你　神所赐你居住的各城中，你若听人说，有些匪类从你们中间的一座城出来勾引本城的居民，说：‘我们不如去侍奉你们素来所不认识的别神’；"},
{volumn:         5,chapter:        13,versenumber:        13,verse:""},
{volumn:         5,chapter:        13,versenumber:        14,verse:"你就要探听，查究，细细地访问，果然是真，准有这可憎恶的事行在你们中间，"},
{volumn:         5,chapter:        13,versenumber:        15,verse:"你必要用刀杀那城里的居民，把城里所有的，连牲畜，都用刀杀尽。"},
{volumn:         5,chapter:        13,versenumber:        16,verse:"你从那城里所夺的财物都要堆积在街市上，用火将城和其内所夺的财物都在耶和华你　神面前烧尽；那城就永为荒堆，不可再建造。"},
{volumn:         5,chapter:        13,versenumber:        17,verse:"那当毁灭的物连一点都不可粘你的手。你要听从耶和华你　神的话，遵守我今日所吩咐你的一切诫命，行耶和华你　神眼中看为正的事，耶和华就必转意，不发烈怒，恩待你，怜恤你，照他向你列祖所起的誓使你人数增多。”"},
{volumn:         5,chapter:        13,versenumber:        18,verse:""},
{volumn:         5,chapter:        14,versenumber:         1,verse:"“你们是耶和华你们　神的儿女。不可为死人用刀划身，也不可将额上剃光；"},
{volumn:         5,chapter:        14,versenumber:         2,verse:"因为你归耶和华你　神为圣洁的民，耶和华从地上的万民中拣选你特作自己的子民。”"},
{volumn:         5,chapter:        14,versenumber:         3,verse:"“凡可憎的物都不可吃。"},
{volumn:         5,chapter:        14,versenumber:         4,verse:"可吃的牲畜就是牛、绵羊、山羊、"},
{volumn:         5,chapter:        14,versenumber:         5,verse:"鹿、羚羊、狍子、野山羊、麋鹿、黄羊、青羊。"},
{volumn:         5,chapter:        14,versenumber:         6,verse:"凡分蹄成为两瓣又倒嚼的走兽，你们都可以吃。"},
{volumn:         5,chapter:        14,versenumber:         7,verse:"但那些倒嚼或是分蹄之中不可吃的乃是骆驼、兔子、沙番，因为是倒嚼不分蹄，就与你们不洁净；"},
{volumn:         5,chapter:        14,versenumber:         8,verse:"猪，因为是分蹄却不倒嚼，就与你们不洁净。这些兽的肉，你们不可吃，死的也不可摸。"},
{volumn:         5,chapter:        14,versenumber:         9,verse:"“水中可吃的乃是这些：凡有翅有鳞的都可以吃；"},
{volumn:         5,chapter:        14,versenumber:        10,verse:"凡无翅无鳞的都不可吃，是与你们不洁净。"},
{volumn:         5,chapter:        14,versenumber:        11,verse:"“凡洁净的鸟，你们都可以吃。"},
{volumn:         5,chapter:        14,versenumber:        12,verse:"不可吃的乃是雕、狗头雕、红头雕、"},
{volumn:         5,chapter:        14,versenumber:        13,verse:"鹯、小鹰、鹞鹰与其类，"},
{volumn:         5,chapter:        14,versenumber:        14,verse:"乌鸦与其类，"},
{volumn:         5,chapter:        14,versenumber:        15,verse:"鸵鸟、夜鹰、鱼鹰、鹰与其类，"},
{volumn:         5,chapter:        14,versenumber:        16,verse:"鸮鸟、猫头鹰、角鸱、"},
{volumn:         5,chapter:        14,versenumber:        17,verse:"鹈鹕、秃雕、鸬鹚、"},
{volumn:         5,chapter:        14,versenumber:        18,verse:"鹳、鹭鸶与其类，戴鵀与蝙蝠。"},
{volumn:         5,chapter:        14,versenumber:        19,verse:"凡有翅膀爬行的物是与你们不洁净，都不可吃。"},
{volumn:         5,chapter:        14,versenumber:        20,verse:"凡洁净的鸟，你们都可以吃。"},
{volumn:         5,chapter:        14,versenumber:        21,verse:"“凡自死的，你们都不可吃，可以给你城里寄居的吃，或卖与外人吃，因为你是归耶和华你　神为圣洁的民。“不可用山羊羔母的奶煮山羊羔。”"},
{volumn:         5,chapter:        14,versenumber:        22,verse:"“你要把你撒种所产的，就是你田地每年所出的，十分取一分；"},
{volumn:         5,chapter:        14,versenumber:        23,verse:"又要把你的五谷、新酒、和油的十分之一，并牛群羊群中头生的，吃在耶和华你　神面前，就是他所选择要立为他名的居所。这样，你可以学习时常敬畏耶和华你的　神。"},
{volumn:         5,chapter:        14,versenumber:        24,verse:"当耶和华你　神赐福与你的时候，耶和华你　神所选择要立为他名的地方若离你太远，那路也太长，使你不能把这物带到那里去，"},
{volumn:         5,chapter:        14,versenumber:        25,verse:"你就可以换成银子，将银子包起来，拿在手中，往耶和华你　神所要选择的地方去。"},
{volumn:         5,chapter:        14,versenumber:        26,verse:"你用这银子，随心所欲，或买牛羊，或买清酒浓酒，凡你心所想的都可以买；你和你的家属在耶和华你　神的面前吃喝快乐。"},
{volumn:         5,chapter:        14,versenumber:        27,verse:"“住在你城里的利未人，你不可丢弃他，因为他在你们中间无份无业。"},
{volumn:         5,chapter:        14,versenumber:        28,verse:"每逢三年的末一年，你要将本年的土产十分之一都取出来，积存在你的城中。"},
{volumn:         5,chapter:        14,versenumber:        29,verse:"在你城里无份无业的利未人，和你城里寄居的，并孤儿寡妇，都可以来，吃得饱足。这样，耶和华你的　神必在你手里所办的一切事上赐福与你。”"},
{volumn:         5,chapter:        15,versenumber:         1,verse:"“每逢七年末一年，你要施行豁免。"},
{volumn:         5,chapter:        15,versenumber:         2,verse:"豁免的定例乃是这样：凡债主要把所借给邻舍的豁免了；不可向邻舍和弟兄追讨，因为耶和华的豁免年已经宣告了。"},
{volumn:         5,chapter:        15,versenumber:         3,verse:"若借给外邦人，你可以向他追讨；但借给你弟兄，无论是什么，你要松手豁免了。"},
{volumn:         5,chapter:        15,versenumber:         4,verse:"你若留意听从耶和华你　神的话，谨守遵行我今日所吩咐你这一切的命令，就必在你们中间没有穷人了（在耶和华你　神所赐你为业的地上，耶和华必大大赐福与你。）"},
{volumn:         5,chapter:        15,versenumber:         5,verse:""},
{volumn:         5,chapter:        15,versenumber:         6,verse:"因为耶和华你的　神必照他所应许你的赐福与你。你必借给许多国民，却不致向他们借贷；你必管辖许多国民，他们却不能管辖你。"},
{volumn:         5,chapter:        15,versenumber:         7,verse:"“在耶和华你　神所赐你的地上，无论哪一座城里，你弟兄中若有一个穷人，你不可忍着心、揝着手不帮补你穷乏的弟兄。"},
{volumn:         5,chapter:        15,versenumber:         8,verse:"总要向他松开手，照他所缺乏的借给他，补他的不足。"},
{volumn:         5,chapter:        15,versenumber:         9,verse:"你要谨慎，不可心里起恶念，说：‘第七年的豁免年快到了’，你便恶眼看你穷乏的弟兄，什么都不给他，以致他因你求告耶和华，罪便归于你了。"},
{volumn:         5,chapter:        15,versenumber:        10,verse:"你总要给他，给他的时候心里不可愁烦；因耶和华你的　神必在你这一切所行的，并你手里所办的事上，赐福与你。"},
{volumn:         5,chapter:        15,versenumber:        11,verse:"原来那地上的穷人永不断绝；所以我吩咐你说：‘总要向你地上困苦穷乏的弟兄松开手。’”"},
{volumn:         5,chapter:        15,versenumber:        12,verse:"“你弟兄中，若有一个希伯来男人或希伯来女人被卖给你，服侍你六年，到第七年就要任他自由出去。"},
{volumn:         5,chapter:        15,versenumber:        13,verse:"你任他自由的时候，不可使他空手而去，"},
{volumn:         5,chapter:        15,versenumber:        14,verse:"要从你羊群、禾场、酒榨之中多多地给他；耶和华你的　神怎样赐福与你，你也要照样给他。"},
{volumn:         5,chapter:        15,versenumber:        15,verse:"要记念你在埃及地作过奴仆，耶和华你的　神将你救赎；因此，我今日吩咐你这件事。"},
{volumn:         5,chapter:        15,versenumber:        16,verse:"他若对你说：‘我不愿意离开你’，是因他爱你和你的家，且因在你那里很好，"},
{volumn:         5,chapter:        15,versenumber:        17,verse:"你就要拿锥子将他的耳朵在门上刺透，他便永为你的奴仆了。你待婢女也要这样。"},
{volumn:         5,chapter:        15,versenumber:        18,verse:"你任他自由的时候，不可以为难事，因他服侍你六年，较比雇工的工价多加一倍了。耶和华你的　神就必在你所做的一切事上赐福与你。”"},
{volumn:         5,chapter:        15,versenumber:        19,verse:"“你牛群羊群中头生的，凡是公的，都要分别为圣，归耶和华你的　神。牛群中头生的，不可用它耕地；羊群中头生的，不可剪毛。"},
{volumn:         5,chapter:        15,versenumber:        20,verse:"这头生的，你和你的家属，每年要在耶和华所选择的地方，在耶和华你　神面前吃。"},
{volumn:         5,chapter:        15,versenumber:        21,verse:"这头生的若有什么残疾，就如瘸腿的、瞎眼的，无论有什么恶残疾，都不可献给耶和华你的　神；"},
{volumn:         5,chapter:        15,versenumber:        22,verse:"可以在你城里吃；洁净人与不洁净人都可以吃，就如吃羚羊与鹿一般。"},
{volumn:         5,chapter:        15,versenumber:        23,verse:"只是不可吃它的血；要倒在地上，如同倒水一样。”"},
{volumn:         5,chapter:        16,versenumber:         1,verse:"“你要注意亚笔月，向耶和华你的　神守逾越节，因为耶和华你的　神在亚笔月夜间领你出埃及。"},
{volumn:         5,chapter:        16,versenumber:         2,verse:"你当在耶和华所选择要立为他名的居所，从牛群羊群中，将逾越节的祭牲献给耶和华你的　神。"},
{volumn:         5,chapter:        16,versenumber:         3,verse:"你吃这祭牲，不可吃有酵的饼；七日之内要吃无酵饼，就是困苦饼，（你本是急忙出了埃及地，）要叫你一生一世记念你从埃及地出来的日子。"},
{volumn:         5,chapter:        16,versenumber:         4,verse:"在你四境之内，七日不可见面酵，头一日晚上所献的肉，一点不可留到早晨。"},
{volumn:         5,chapter:        16,versenumber:         5,verse:"在耶和华你　神所赐的各城中，你不可献逾越节的祭；"},
{volumn:         5,chapter:        16,versenumber:         6,verse:"只当在耶和华你　神所选择要立为他名的居所，晚上日落的时候，乃是你出埃及的时候，献逾越节的祭。"},
{volumn:         5,chapter:        16,versenumber:         7,verse:"当在耶和华你　神所选择的地方把肉烤了吃（烤：或译煮），次日早晨就回到你的帐棚去。"},
{volumn:         5,chapter:        16,versenumber:         8,verse:"你要吃无酵饼六日，第七日要向耶和华你的　神守严肃会，不可做工。”"},
{volumn:         5,chapter:        16,versenumber:         9,verse:"“你要计算七七日：从你开镰收割禾稼时算起，共计七七日。"},
{volumn:         5,chapter:        16,versenumber:        10,verse:"你要照耶和华你　神所赐你的福，手里拿着甘心祭，献在耶和华你的　神面前，守七七节。"},
{volumn:         5,chapter:        16,versenumber:        11,verse:"你和你儿女、仆婢，并住在你城里的利未人，以及在你们中间寄居的与孤儿寡妇，都要在耶和华你　神所选择立为他名的居所，在耶和华你的　神面前欢乐。"},
{volumn:         5,chapter:        16,versenumber:        12,verse:"你也要记念你在埃及作过奴仆。你要谨守遵行这些律例。”"},
{volumn:         5,chapter:        16,versenumber:        13,verse:"“你把禾场的谷、酒榨的酒收藏以后，就要守住棚节七日。"},
{volumn:         5,chapter:        16,versenumber:        14,verse:"守节的时候，你和你儿女、仆婢，并住在你城里的利未人，以及寄居的与孤儿寡妇，都要欢乐。"},
{volumn:         5,chapter:        16,versenumber:        15,verse:"在耶和华所选择的地方，你当向耶和华你的　神守节七日；因为耶和华你　神在你一切的土产上和你手里所办的事上要赐福与你，你就非常地欢乐。"},
{volumn:         5,chapter:        16,versenumber:        16,verse:"你一切的男丁要在除酵节、七七节、住棚节，一年三次，在耶和华你　神所选择的地方朝见他，却不可空手朝见。"},
{volumn:         5,chapter:        16,versenumber:        17,verse:"各人要按自己的力量，照耶和华你　神所赐的福分，奉献礼物。”"},
{volumn:         5,chapter:        16,versenumber:        18,verse:"“你要在耶和华你　神所赐的各城里，按着各支派设立审判官和官长。他们必按公义的审判判断百姓。"},
{volumn:         5,chapter:        16,versenumber:        19,verse:"不可屈枉正直；不可看人的外貌。也不可受贿赂；因为贿赂能叫智慧人的眼变瞎了，又能颠倒义人的话。"},
{volumn:         5,chapter:        16,versenumber:        20,verse:"你要追求至公至义，好叫你存活，承受耶和华你　神所赐你的地。"},
{volumn:         5,chapter:        16,versenumber:        21,verse:"“你为耶和华你的　神筑坛，不可在坛旁栽什么树木作为木偶。"},
{volumn:         5,chapter:        16,versenumber:        22,verse:"也不可为自己设立柱像；这是耶和华你　神所恨恶的。"},
{volumn:         5,chapter:        17,versenumber:         1,verse:"“凡有残疾，或有什么恶病的牛羊，你都不可献给耶和华你的　神，因为这是耶和华你　神所憎恶的。"},
{volumn:         5,chapter:        17,versenumber:         2,verse:"“在你们中间，在耶和华你　神所赐你的诸城中，无论哪座城里，若有人，或男或女，行耶和华你　神眼中看为恶的事，违背了他的约，"},
{volumn:         5,chapter:        17,versenumber:         3,verse:"去侍奉敬拜别神，或拜日头，或拜月亮，或拜天象，是主不曾吩咐的；"},
{volumn:         5,chapter:        17,versenumber:         4,verse:"有人告诉你，你也听见了，就要细细地探听，果然是真，准有这可憎恶的事行在以色列中，"},
{volumn:         5,chapter:        17,versenumber:         5,verse:"你就要将行这恶事的男人或女人拉到城门外，用石头将他打死。"},
{volumn:         5,chapter:        17,versenumber:         6,verse:"要凭两三个人的口作见证将那当死的人治死；不可凭一个人的口作见证将他治死。"},
{volumn:         5,chapter:        17,versenumber:         7,verse:"见证人要先下手，然后众民也下手将他治死。这样，就把那恶从你们中间除掉。"},
{volumn:         5,chapter:        17,versenumber:         8,verse:"“你城中若起了争讼的事，或因流血，或因争竞，或因殴打，是你难断的案件，你就当起来，往耶和华你　神所选择的地方"},
{volumn:         5,chapter:        17,versenumber:         9,verse:"去见祭司利未人，并当时的审判官，求问他们，他们必将判语指示你。"},
{volumn:         5,chapter:        17,versenumber:        10,verse:"他们在耶和华所选择的地方指示你的判语，你必照着他们所指教你的一切话谨守遵行。"},
{volumn:         5,chapter:        17,versenumber:        11,verse:"要按他们所指教你的律法，照他们所断定的去行；他们所指示你的判语，你不可偏离左右。"},
{volumn:         5,chapter:        17,versenumber:        12,verse:"若有人擅敢不听从那侍立在耶和华你　神面前的祭司，或不听从审判官，那人就必治死；这样，便将那恶从以色列中除掉。"},
{volumn:         5,chapter:        17,versenumber:        13,verse:"众百姓都要听见害怕，不再擅敢行事。”"},
{volumn:         5,chapter:        17,versenumber:        14,verse:"“到了耶和华你　神所赐你的地，得了那地居住的时候，若说：‘我要立王治理我，像四围的国一样。’"},
{volumn:         5,chapter:        17,versenumber:        15,verse:"你总要立耶和华你　神所拣选的人为王。必从你弟兄中立一人；不可立你弟兄以外的人为王。"},
{volumn:         5,chapter:        17,versenumber:        16,verse:"只是王不可为自己加添马匹，也不可使百姓回埃及去，为要加添他的马匹，因耶和华曾吩咐你们说：‘不可再回那条路去。’"},
{volumn:         5,chapter:        17,versenumber:        17,verse:"他也不可为自己多立妃嫔，恐怕他的心偏邪；也不可为自己多积金银。"},
{volumn:         5,chapter:        17,versenumber:        18,verse:"他登了国位，就要将祭司利未人面前的这律法书，为自己抄录一本，"},
{volumn:         5,chapter:        17,versenumber:        19,verse:"存在他那里，要平生诵读，好学习敬畏耶和华他的　神，谨守遵行这律法书上的一切言语和这些律例，"},
{volumn:         5,chapter:        17,versenumber:        20,verse:"免得他向弟兄心高气傲，偏左偏右，离了这诫命。这样，他和他的子孙便可在以色列中，在国位上年长日久。”"},
{volumn:         5,chapter:        18,versenumber:         1,verse:"“祭司利未人和利未全支派必在以色列中无份无业；他们所吃用的就是献给耶和华的火祭和一切所捐的。"},
{volumn:         5,chapter:        18,versenumber:         2,verse:"他们在弟兄中必没有产业；耶和华是他们的产业，正如耶和华所应许他们的。"},
{volumn:         5,chapter:        18,versenumber:         3,verse:"祭司从百姓所当得的份乃是这样：凡献牛或羊为祭的，要把前腿和两腮并脾胃给祭司。"},
{volumn:         5,chapter:        18,versenumber:         4,verse:"初收的五谷、新酒和油，并初剪的羊毛，也要给他；"},
{volumn:         5,chapter:        18,versenumber:         5,verse:"因为耶和华你的　神从你各支派中将他拣选出来，使他和他子孙永远奉耶和华的名侍立，侍奉。"},
{volumn:         5,chapter:        18,versenumber:         6,verse:"“利未人无论寄居在以色列中的哪一座城，若从那里出来，一心愿意到耶和华所选择的地方，"},
{volumn:         5,chapter:        18,versenumber:         7,verse:"就要奉耶和华他　神的名侍奉，像他众弟兄利未人侍立在耶和华面前侍奉一样。"},
{volumn:         5,chapter:        18,versenumber:         8,verse:"除了他卖祖父产业所得的以外，还要得一份祭物与他们同吃。”"},
{volumn:         5,chapter:        18,versenumber:         9,verse:"“你到了耶和华你　神所赐之地，那些国民所行可憎恶的事，你不可学着行。"},
{volumn:         5,chapter:        18,versenumber:        10,verse:"你们中间不可有人使儿女经火，也不可有占卜的、观兆的、用法术的、行邪术的、"},
{volumn:         5,chapter:        18,versenumber:        11,verse:"用迷术的、交鬼的、行巫术的、过阴的。"},
{volumn:         5,chapter:        18,versenumber:        12,verse:"凡行这些事的都为耶和华所憎恶；因那些国民行这可憎恶的事，所以耶和华你的　神将他们从你面前赶出。"},
{volumn:         5,chapter:        18,versenumber:        13,verse:"你要在耶和华你的　神面前作完全人。”"},
{volumn:         5,chapter:        18,versenumber:        14,verse:"“因你所要赶出的那些国民都听信观兆的和占卜的，至于你，耶和华你的　神从来不许你这样行。"},
{volumn:         5,chapter:        18,versenumber:        15,verse:"耶和华你的　神要从你们弟兄中间给你兴起一位先知，像我，你们要听从他。"},
{volumn:         5,chapter:        18,versenumber:        16,verse:"正如你在何烈山大会的日子求耶和华你　神一切的话，说：‘求你不再叫我听见耶和华我　神的声音，也不再叫我看见这大火，免得我死亡。’"},
{volumn:         5,chapter:        18,versenumber:        17,verse:"耶和华就对我说：‘他们所说的是。"},
{volumn:         5,chapter:        18,versenumber:        18,verse:"我必在他们弟兄中间给他们兴起一位先知，像你。我要将当说的话传给他；他要将我一切所吩咐的都传给他们。"},
{volumn:         5,chapter:        18,versenumber:        19,verse:"谁不听他奉我名所说的话，我必讨谁的罪。"},
{volumn:         5,chapter:        18,versenumber:        20,verse:"若有先知擅敢托我的名说我所未曾吩咐他说的话，或是奉别神的名说话，那先知就必治死。’"},
{volumn:         5,chapter:        18,versenumber:        21,verse:"你心里若说：‘耶和华所未曾吩咐的话，我们怎能知道呢？’"},
{volumn:         5,chapter:        18,versenumber:        22,verse:"先知托耶和华的名说话，所说的若不成就，也无效验，这就是耶和华所未曾吩咐的，是那先知擅自说的，你不要怕他。”"},
{volumn:         5,chapter:        19,versenumber:         1,verse:"“耶和华你　神将列国之民剪除的时候，耶和华你　神也将他们的地赐给你，你接着住他们的城邑并他们的房屋，"},
{volumn:         5,chapter:        19,versenumber:         2,verse:"就要在耶和华你　神所赐你为业的地上分定三座城。"},
{volumn:         5,chapter:        19,versenumber:         3,verse:"要将耶和华你　神使你承受为业的地分为三段；又要预备道路，使误杀人的，都可以逃到那里去。"},
{volumn:         5,chapter:        19,versenumber:         4,verse:"“误杀人的逃到那里可以存活，定例乃是这样：凡素无仇恨，无心杀了人的，"},
{volumn:         5,chapter:        19,versenumber:         5,verse:"就如人与邻舍同入树林砍伐树木，手拿斧子一砍，本想砍下树木，不料，斧头脱了把，飞落在邻舍身上，以致于死，这人逃到那些城的一座城，就可以存活，"},
{volumn:         5,chapter:        19,versenumber:         6,verse:"免得报血仇的，心中火热追赶他，因路远就追上，将他杀死；其实他不该死，因为他与被杀的素无仇恨。"},
{volumn:         5,chapter:        19,versenumber:         7,verse:"所以我吩咐你说，要分定三座城。"},
{volumn:         5,chapter:        19,versenumber:         8,verse:"耶和华你　神若照他向你列祖所起的誓扩张你的境界，将所应许赐你列祖的地全然给你，"},
{volumn:         5,chapter:        19,versenumber:         9,verse:"你若谨守遵行我今日所吩咐的这一切诫命，爱耶和华你的　神，常常遵行他的道，就要在这三座城之外，再添三座城，"},
{volumn:         5,chapter:        19,versenumber:        10,verse:"免得无辜之人的血流在耶和华你　神所赐你为业的地上，流血的罪就归于你。"},
{volumn:         5,chapter:        19,versenumber:        11,verse:"“若有人恨他的邻舍，埋伏着起来击杀他，以致于死，便逃到这些城的一座城，"},
{volumn:         5,chapter:        19,versenumber:        12,verse:"本城的长老就要打发人去，从那里带出他来，交在报血仇的手中，将他治死。"},
{volumn:         5,chapter:        19,versenumber:        13,verse:"你眼不可顾惜他，却要从以色列中除掉流无辜血的罪，使你可以得福。”"},
{volumn:         5,chapter:        19,versenumber:        14,verse:"“在耶和华你　神所赐你承受为业之地，不可挪移你邻舍的地界，那是先人所定的。”"},
{volumn:         5,chapter:        19,versenumber:        15,verse:"“人无论犯什么罪，作什么恶，不可凭一个人的口作见证，总要凭两三个人的口作见证才可定案。"},
{volumn:         5,chapter:        19,versenumber:        16,verse:"若有凶恶的见证人起来，见证某人作恶，"},
{volumn:         5,chapter:        19,versenumber:        17,verse:"这两个争讼的人就要站在耶和华面前，和当时的祭司，并审判官面前，"},
{volumn:         5,chapter:        19,versenumber:        18,verse:"审判官要细细地查究，若见证人果然是作假见证的，以假见证陷害弟兄，"},
{volumn:         5,chapter:        19,versenumber:        19,verse:"你们就要待他如同他想要待的弟兄。这样，就把那恶从你们中间除掉。"},
{volumn:         5,chapter:        19,versenumber:        20,verse:"别人听见都要害怕，就不敢在你们中间再行这样的恶了。"},
{volumn:         5,chapter:        19,versenumber:        21,verse:"你眼不可顾惜，要以命偿命，以眼还眼，以牙还牙，以手还手，以脚还脚。”"},
{volumn:         5,chapter:        20,versenumber:         1,verse:"“你出去与仇敌争战的时候，看见马匹、车辆，并有比你多的人民，不要怕他们，因为领你出埃及地的耶和华你　神与你同在。"},
{volumn:         5,chapter:        20,versenumber:         2,verse:"你们将要上阵的时候，祭司要到百姓面前宣告"},
{volumn:         5,chapter:        20,versenumber:         3,verse:"说：‘以色列人哪，你们当听，你们今日将要与仇敌争战，不要胆怯，不要惧怕战兢，也不要因他们惊恐；"},
{volumn:         5,chapter:        20,versenumber:         4,verse:"因为耶和华你们的　神与你们同去，要为你们与仇敌争战，拯救你们。’"},
{volumn:         5,chapter:        20,versenumber:         5,verse:"官长也要对百姓宣告说：‘谁建造房屋，尚未奉献，他可以回家去，恐怕他阵亡，别人去奉献。"},
{volumn:         5,chapter:        20,versenumber:         6,verse:"谁种葡萄园，尚未用所结的果子，他可以回家去，恐怕他阵亡，别人去用。"},
{volumn:         5,chapter:        20,versenumber:         7,verse:"谁聘定了妻，尚未迎娶，他可以回家去，恐怕他阵亡，别人去娶。’"},
{volumn:         5,chapter:        20,versenumber:         8,verse:"官长又要对百姓宣告说：‘谁惧怕胆怯，他可以回家去，恐怕他弟兄的心消化，和他一样。’"},
{volumn:         5,chapter:        20,versenumber:         9,verse:"官长对百姓宣告完了，就当派军长率领他们。"},
{volumn:         5,chapter:        20,versenumber:        10,verse:"“你临近一座城、要攻打的时候，先要对城里的民宣告和睦的话。"},
{volumn:         5,chapter:        20,versenumber:        11,verse:"他们若以和睦的话回答你，给你开了城，城里所有的人都要给你效劳，服侍你；"},
{volumn:         5,chapter:        20,versenumber:        12,verse:"若不肯与你和好，反要与你打仗，你就要围困那城。"},
{volumn:         5,chapter:        20,versenumber:        13,verse:"耶和华你的　神把城交付你手，你就要用刀杀尽这城的男丁。"},
{volumn:         5,chapter:        20,versenumber:        14,verse:"惟有妇女、孩子、牲畜，和城内一切的财物，你可以取为自己的掠物。耶和华你　神把你仇敌的财物赐给你，你可以吃用。"},
{volumn:         5,chapter:        20,versenumber:        15,verse:"离你甚远的各城，不是这些国民的城，你都要这样待他。"},
{volumn:         5,chapter:        20,versenumber:        16,verse:"但这些国民的城，耶和华你　神既赐你为业，其中凡有气息的，一个不可存留；"},
{volumn:         5,chapter:        20,versenumber:        17,verse:"只要照耶和华你　神所吩咐的将这赫人、亚摩利人、迦南人、比利洗人、希未人、耶布斯人都灭绝净尽，"},
{volumn:         5,chapter:        20,versenumber:        18,verse:"免得他们教导你们学习一切可憎恶的事，就是他们向自己神所行的，以致你们得罪耶和华你们的　神。"},
{volumn:         5,chapter:        20,versenumber:        19,verse:"“你若许久围困、攻打所要取的一座城，就不可举斧子砍坏树木；因为你可以吃那树上的果子，不可砍伐。田间的树木岂是人，叫你糟蹋吗？"},
{volumn:         5,chapter:        20,versenumber:        20,verse:"惟独你所知道不是结果子的树木可以毁坏、砍伐，用以修筑营垒，攻击那与你打仗的城，直到攻塌了。”"},
{volumn:         5,chapter:        21,versenumber:         1,verse:"“在耶和华你　神所赐你为业的地上，若遇见被杀的人倒在田野，不知道是谁杀的，"},
{volumn:         5,chapter:        21,versenumber:         2,verse:"长老和审判官就要出去，从被杀的人那里量起，直量到四围的城邑，"},
{volumn:         5,chapter:        21,versenumber:         3,verse:"看哪城离被杀的人最近，那城的长老就要从牛群中取一只未曾耕地、未曾负轭的母牛犊，"},
{volumn:         5,chapter:        21,versenumber:         4,verse:"把母牛犊牵到流水、未曾耕种的山谷去，在谷中打折母牛犊的颈项。"},
{volumn:         5,chapter:        21,versenumber:         5,verse:"祭司利未的子孙要近前来；因为耶和华你的　神拣选了他们侍奉他，奉耶和华的名祝福，所有争讼殴打的事都要凭他们判断。"},
{volumn:         5,chapter:        21,versenumber:         6,verse:"那城的众长老，就是离被杀的人最近的，要在那山谷中，在所打折颈项的母牛犊以上洗手，"},
{volumn:         5,chapter:        21,versenumber:         7,verse:"祷告（原文是回答）说：‘我们的手未曾流这人的血；我们的眼也未曾看见这事。"},
{volumn:         5,chapter:        21,versenumber:         8,verse:"耶和华啊，求你赦免你所救赎的以色列民，不要使流无辜血的罪归在你的百姓以色列中间。’这样，流血的罪必得赦免。"},
{volumn:         5,chapter:        21,versenumber:         9,verse:"你行耶和华眼中看为正的事，就可以从你们中间除掉流无辜血的罪。”"},
{volumn:         5,chapter:        21,versenumber:        10,verse:"“你出去与仇敌争战的时候，耶和华你的　神将他们交在你手中，你就掳了他们去。"},
{volumn:         5,chapter:        21,versenumber:        11,verse:"若在被掳的人中见有美貌的女子，恋慕她，要娶她为妻，"},
{volumn:         5,chapter:        21,versenumber:        12,verse:"就可以领她到你家里去；她便要剃头发，修指甲，"},
{volumn:         5,chapter:        21,versenumber:        13,verse:"脱去被掳时所穿的衣服，住在你家里哀哭父母一个整月，然后可以与她同房。你作她的丈夫，她作你的妻子。"},
{volumn:         5,chapter:        21,versenumber:        14,verse:"后来你若不喜悦她，就要由她随意出去，决不可为钱卖她，也不可当婢女待她，因为你玷污了她。”"},
{volumn:         5,chapter:        21,versenumber:        15,verse:"“人若有二妻，一为所爱，一为所恶，所爱的、所恶的都给他生了儿子，但长子是所恶之妻生的。"},
{volumn:         5,chapter:        21,versenumber:        16,verse:"到了把产业分给儿子承受的时候，不可将所爱之妻生的儿子立为长子，在所恶之妻生的儿子以上，"},
{volumn:         5,chapter:        21,versenumber:        17,verse:"却要认所恶之妻生的儿子为长子，将产业多加一份给他；因这儿子是他力量强壮的时候生的，长子的名分本当归他。”"},
{volumn:         5,chapter:        21,versenumber:        18,verse:"“人若有顽梗悖逆的儿子，不听从父母的话，他们虽惩治他，他仍不听从，"},
{volumn:         5,chapter:        21,versenumber:        19,verse:"父母就要抓住他，将他带到本地的城门、本城的长老那里，"},
{volumn:         5,chapter:        21,versenumber:        20,verse:"对长老说：‘我们这儿子顽梗悖逆，不听从我们的话，是贪食好酒的人。’"},
{volumn:         5,chapter:        21,versenumber:        21,verse:"本城的众人就要用石头将他打死。这样，就把那恶从你们中间除掉，以色列众人都要听见害怕。”"},
{volumn:         5,chapter:        21,versenumber:        22,verse:"“人若犯该死的罪，被治死了，你将他挂在木头上，"},
{volumn:         5,chapter:        21,versenumber:        23,verse:"他的尸首不可留在木头上过夜，必要当日将他葬埋，免得玷污了耶和华你　神所赐你为业之地。因为被挂的人是在　神面前受咒诅的。"},
{volumn:         5,chapter:        22,versenumber:         1,verse:"“你若看见弟兄的牛或羊失迷了路，不可佯为不见，总要把它牵回来交给你的弟兄。"},
{volumn:         5,chapter:        22,versenumber:         2,verse:"你弟兄若离你远，或是你不认识他，就要牵到你家去，留在你那里，等你弟兄来寻找就还给他。"},
{volumn:         5,chapter:        22,versenumber:         3,verse:"你的弟兄无论失落什么，或是驴，或是衣服，你若遇见，都要这样行，不可佯为不见。"},
{volumn:         5,chapter:        22,versenumber:         4,verse:"你若看见弟兄的牛或驴跌倒在路上，不可佯为不见，总要帮助他拉起来。"},
{volumn:         5,chapter:        22,versenumber:         5,verse:"“妇女不可穿戴男子所穿戴的，男子也不可穿妇女的衣服，因为这样行都是耶和华你　神所憎恶的。"},
{volumn:         5,chapter:        22,versenumber:         6,verse:"“你若路上遇见鸟窝，或在树上或在地上，里头有雏或有蛋，母鸟伏在雏上或在蛋上，你不可连母带雏一并取去。"},
{volumn:         5,chapter:        22,versenumber:         7,verse:"总要放母，只可取雏；这样你就可以享福，日子得以长久。"},
{volumn:         5,chapter:        22,versenumber:         8,verse:"“你若建造房屋，要在房上的四围安栏杆，免得有人从房上掉下来，流血的罪就归于你家。"},
{volumn:         5,chapter:        22,versenumber:         9,verse:"“不可把两样种子种在你的葡萄园里，免得你撒种所结的和葡萄园的果子都要充公。"},
{volumn:         5,chapter:        22,versenumber:        10,verse:"不可并用牛、驴耕地。"},
{volumn:         5,chapter:        22,versenumber:        11,verse:"不可穿羊毛、细麻两样搀杂料做的衣服。"},
{volumn:         5,chapter:        22,versenumber:        12,verse:"“你要在所披的外衣上四围做繸子。”"},
{volumn:         5,chapter:        22,versenumber:        13,verse:"“人若娶妻，与她同房之后恨恶她，"},
{volumn:         5,chapter:        22,versenumber:        14,verse:"信口说她，将丑名加在她身上，说：‘我娶了这女子，与她同房，见她没有贞洁的凭据’；"},
{volumn:         5,chapter:        22,versenumber:        15,verse:"女子的父母就要把女子贞洁的凭据拿出来，带到本城门长老那里。"},
{volumn:         5,chapter:        22,versenumber:        16,verse:"女子的父亲要对长老说：‘我将我的女儿给这人为妻，他恨恶她，"},
{volumn:         5,chapter:        22,versenumber:        17,verse:"信口说她，说：我见你的女儿没有贞洁的凭据；其实这就是我女儿贞洁的凭据。’父母就把那布铺在本城的长老面前。"},
{volumn:         5,chapter:        22,versenumber:        18,verse:"本城的长老要拿住那人，惩治他，"},
{volumn:         5,chapter:        22,versenumber:        19,verse:"并要罚他一百舍客勒银子，给女子的父亲，因为他将丑名加在以色列的一个处女身上。女子仍作他的妻，终身不可休她。"},
{volumn:         5,chapter:        22,versenumber:        20,verse:"但这事若是真的，女子没有贞洁的凭据，"},
{volumn:         5,chapter:        22,versenumber:        21,verse:"就要将女子带到她父家的门口，本城的人要用石头将她打死；因为她在父家行了淫乱，在以色列中做了丑事。这样，就把那恶从你们中间除掉。"},
{volumn:         5,chapter:        22,versenumber:        22,verse:"“若遇见人与有丈夫的妇人行淫，就要将奸夫淫妇一并治死。这样，就把那恶从以色列中除掉。"},
{volumn:         5,chapter:        22,versenumber:        23,verse:"“若有处女已经许配丈夫，有人在城里遇见她，与她行淫，"},
{volumn:         5,chapter:        22,versenumber:        24,verse:"你们就要把这二人带到本城门，用石头打死，女子是因为虽在城里却没有喊叫；男子是因为玷污别人的妻。这样，就把那恶从你们中间除掉。"},
{volumn:         5,chapter:        22,versenumber:        25,verse:"“若有男子在田野遇见已经许配人的女子，强与她行淫，只要将那男子治死。"},
{volumn:         5,chapter:        22,versenumber:        26,verse:"但不可办女子；她本没有该死的罪，这事就类乎人起来攻击邻舍，将他杀了一样。"},
{volumn:         5,chapter:        22,versenumber:        27,verse:"因为男子是在田野遇见那已经许配人的女子，女子喊叫，并无人救她。"},
{volumn:         5,chapter:        22,versenumber:        28,verse:"“若有男子遇见没有许配人的处女，抓住她，与她行淫，被人看见，"},
{volumn:         5,chapter:        22,versenumber:        29,verse:"这男子就要拿五十舍客勒银子给女子的父亲；因他玷污了这女子，就要娶她为妻，终身不可休她。"},
{volumn:         5,chapter:        22,versenumber:        30,verse:"“人不可娶继母为妻；不可掀开他父亲的衣襟。”"},
{volumn:         5,chapter:        23,versenumber:         1,verse:"“凡外肾受伤的，或被阉割的，不可入耶和华的会。"},
{volumn:         5,chapter:        23,versenumber:         2,verse:"“私生子不可入耶和华的会；他的子孙，直到十代，也不可入耶和华的会。"},
{volumn:         5,chapter:        23,versenumber:         3,verse:"“亚扪人或是摩押人不可入耶和华的会；他们的子孙，虽过十代，也永不可入耶和华的会。"},
{volumn:         5,chapter:        23,versenumber:         4,verse:"因为你们出埃及的时候，他们没有拿食物和水在路上迎接你们，又因他们雇了美索不达米亚的毗夺人比珥的儿子巴兰来咒诅你们。"},
{volumn:         5,chapter:        23,versenumber:         5,verse:"然而耶和华你的　神不肯听从巴兰，却使那咒诅的言语变为祝福的话，因为耶和华你的　神爱你。"},
{volumn:         5,chapter:        23,versenumber:         6,verse:"你一生一世永不可求他们的平安和他们的利益。"},
{volumn:         5,chapter:        23,versenumber:         7,verse:"“不可憎恶以东人，因为他是你的弟兄。不可憎恶埃及人，因为你在他的地上作过寄居的。"},
{volumn:         5,chapter:        23,versenumber:         8,verse:"他们第三代子孙可以入耶和华的会。”"},
{volumn:         5,chapter:        23,versenumber:         9,verse:"“你出兵攻打仇敌，就要远避诸恶。"},
{volumn:         5,chapter:        23,versenumber:        10,verse:"“你们中间，若有人夜间偶然梦遗，不洁净，就要出到营外，不可入营；"},
{volumn:         5,chapter:        23,versenumber:        11,verse:"到傍晚的时候，他要用水洗澡，及至日落了才可以入营。"},
{volumn:         5,chapter:        23,versenumber:        12,verse:"“你在营外也该定出一个地方作为便所。"},
{volumn:         5,chapter:        23,versenumber:        13,verse:"在你器械之中当预备一把锹，你出营外便溺以后，用以铲土，转身掩盖。"},
{volumn:         5,chapter:        23,versenumber:        14,verse:"因为耶和华你的　神常在你营中行走，要救护你，将仇敌交给你，所以你的营理当圣洁，免得他见你那里有污秽，就离开你。”"},
{volumn:         5,chapter:        23,versenumber:        15,verse:"“若有奴仆脱了主人的手，逃到你那里，你不可将他交付他的主人。"},
{volumn:         5,chapter:        23,versenumber:        16,verse:"他必在你那里与你同住，在你的城邑中，要由他选择一个所喜悦的地方居住；你不可欺负他。"},
{volumn:         5,chapter:        23,versenumber:        17,verse:"“以色列的女子中不可有妓女；以色列的男子中不可有娈童。"},
{volumn:         5,chapter:        23,versenumber:        18,verse:"娼妓所得的钱，或娈童（原文是狗）所得的价，你不可带入耶和华你　神的殿还愿，因为这两样都是耶和华你　神所憎恶的。"},
{volumn:         5,chapter:        23,versenumber:        19,verse:"“你借给你弟兄的，或是钱财或是粮食，无论什么可生利的物，都不可取利。"},
{volumn:         5,chapter:        23,versenumber:        20,verse:"借给外邦人可以取利，只是借给你弟兄不可取利。这样，耶和华你　神必在你所去得为业的地上和你手里所办的一切事上赐福与你。"},
{volumn:         5,chapter:        23,versenumber:        21,verse:"“你向耶和华你的　神许愿，偿还不可迟延；因为耶和华你的　神必定向你追讨，你不偿还就有罪。"},
{volumn:         5,chapter:        23,versenumber:        22,verse:"你若不许愿，倒无罪。"},
{volumn:         5,chapter:        23,versenumber:        23,verse:"你嘴里所出的，就是你口中应许甘心所献的，要照你向耶和华你　神所许的愿谨守遵行。"},
{volumn:         5,chapter:        23,versenumber:        24,verse:"“你进了邻舍的葡萄园，可以随意吃饱了葡萄，只是不可装在器皿中。"},
{volumn:         5,chapter:        23,versenumber:        25,verse:"你进了邻舍站着的禾稼，可以用手摘穗子，只是不可用镰刀割取禾稼。”"},
{volumn:         5,chapter:        24,versenumber:         1,verse:"“人若娶妻以后，见她有什么不合理的事，不喜悦她，就可以写休书交在她手中，打发她离开夫家。"},
{volumn:         5,chapter:        24,versenumber:         2,verse:"妇人离开夫家以后，可以去嫁别人。"},
{volumn:         5,chapter:        24,versenumber:         3,verse:"后夫若恨恶她，写休书交在她手中，打发她离开夫家，或是娶她为妻的后夫死了，"},
{volumn:         5,chapter:        24,versenumber:         4,verse:"打发她去的前夫不可在妇人玷污之后再娶她为妻，因为这是耶和华所憎恶的；不可使耶和华你　神所赐为业之地被玷污了。”"},
{volumn:         5,chapter:        24,versenumber:         5,verse:"“新娶妻之人不可从军出征，也不可托他办理什么公事，可以在家清闲一年，使他所娶的妻快活。"},
{volumn:         5,chapter:        24,versenumber:         6,verse:"“不可拿人的全盘磨石或是上磨石作当头，因为这是拿人的命作当头。"},
{volumn:         5,chapter:        24,versenumber:         7,verse:"“若遇见人拐带以色列中的一个弟兄，当奴才待他，或是卖了他，那拐带人的就必治死。这样，便将那恶从你们中间除掉。"},
{volumn:         5,chapter:        24,versenumber:         8,verse:"“在大麻风的灾病上，你们要谨慎，照祭司利未人一切所指教你们的留意遵行。我怎样吩咐他们，你们要怎样遵行。"},
{volumn:         5,chapter:        24,versenumber:         9,verse:"当记念出埃及后，在路上，耶和华你　神向米利暗所行的事。"},
{volumn:         5,chapter:        24,versenumber:        10,verse:"“你借给邻舍，不拘是什么，不可进他家拿他的当头。"},
{volumn:         5,chapter:        24,versenumber:        11,verse:"要站在外面，等那向你借贷的人把当头拿出来交给你。"},
{volumn:         5,chapter:        24,versenumber:        12,verse:"他若是穷人，你不可留他的当头过夜。"},
{volumn:         5,chapter:        24,versenumber:        13,verse:"日落的时候，总要把当头还他，使他用那件衣服盖着睡觉，他就为你祝福；这在耶和华你　神面前就是你的义了。"},
{volumn:         5,chapter:        24,versenumber:        14,verse:"“困苦穷乏的雇工，无论是你的弟兄或是在你城里寄居的，你不可欺负他。"},
{volumn:         5,chapter:        24,versenumber:        15,verse:"要当日给他工价，不可等到日落，因为他穷苦，把心放在工价上，恐怕他因你求告耶和华，罪便归你了。"},
{volumn:         5,chapter:        24,versenumber:        16,verse:"“不可因子杀父，也不可因父杀子；凡被杀的都为本身的罪。"},
{volumn:         5,chapter:        24,versenumber:        17,verse:"“你不可向寄居的和孤儿屈枉正直，也不可拿寡妇的衣裳作当头。"},
{volumn:         5,chapter:        24,versenumber:        18,verse:"要记念你在埃及作过奴仆。耶和华你的　神从那里将你救赎，所以我吩咐你这样行。"},
{volumn:         5,chapter:        24,versenumber:        19,verse:"“你在田间收割庄稼，若忘下一捆，不可回去再取，要留给寄居的与孤儿寡妇。这样，耶和华你　神必在你手里所办的一切事上赐福与你。"},
{volumn:         5,chapter:        24,versenumber:        20,verse:"你打橄榄树，枝上剩下的，不可再打；要留给寄居的与孤儿寡妇。"},
{volumn:         5,chapter:        24,versenumber:        21,verse:"你摘葡萄园的葡萄，所剩下的，不可再摘；要留给寄居的与孤儿寡妇。"},
{volumn:         5,chapter:        24,versenumber:        22,verse:"你也要记念你在埃及地作过奴仆，所以我吩咐你这样行。"},
{volumn:         5,chapter:        25,versenumber:         1,verse:"“人若有争讼，来听审判，审判官就要定义人有理，定恶人有罪。"},
{volumn:         5,chapter:        25,versenumber:         2,verse:"恶人若该受责打，审判官就要叫他当面伏在地上，按着他的罪照数责打。"},
{volumn:         5,chapter:        25,versenumber:         3,verse:"只可打他四十下，不可过数；若过数，便是轻贱你的弟兄了。"},
{volumn:         5,chapter:        25,versenumber:         4,verse:"“牛在场上踹谷的时候，不可笼住它的嘴。”"},
{volumn:         5,chapter:        25,versenumber:         5,verse:"“弟兄同居，若死了一个，没有儿子，死人的妻不可出嫁外人，她丈夫的兄弟当尽弟兄的本分，娶她为妻，与她同房。"},
{volumn:         5,chapter:        25,versenumber:         6,verse:"妇人生的长子必归死兄的名下，免得他的名在以色列中涂抹了。"},
{volumn:         5,chapter:        25,versenumber:         7,verse:"那人若不愿意娶她哥哥的妻，他哥哥的妻就要到城门长老那里，说：‘我丈夫的兄弟不肯在以色列中兴起他哥哥的名字，不给我尽弟兄的本分。’"},
{volumn:         5,chapter:        25,versenumber:         8,verse:"本城的长老就要召那人来问他，他若执意说：‘我不愿意娶她’，"},
{volumn:         5,chapter:        25,versenumber:         9,verse:"他哥哥的妻就要当着长老到那人的跟前，脱了他的鞋，吐唾沫在他脸上，说：‘凡不为哥哥建立家室的都要这样待他。’"},
{volumn:         5,chapter:        25,versenumber:        10,verse:"在以色列中，他的名必称为脱鞋之家。”"},
{volumn:         5,chapter:        25,versenumber:        11,verse:"“若有二人争斗，这人的妻近前来，要救她丈夫脱离那打她丈夫之人的手，抓住那人的下体，"},
{volumn:         5,chapter:        25,versenumber:        12,verse:"就要砍断妇人的手，眼不可顾惜她。"},
{volumn:         5,chapter:        25,versenumber:        13,verse:"“你囊中不可有一大一小两样的法码。"},
{volumn:         5,chapter:        25,versenumber:        14,verse:"你家里不可有一大一小两样的升斗。"},
{volumn:         5,chapter:        25,versenumber:        15,verse:"当用对准公平的法码，公平的升斗。这样，在耶和华你　神所赐你的地上，你的日子就可以长久。"},
{volumn:         5,chapter:        25,versenumber:        16,verse:"因为行非义之事的人都是耶和华你　神所憎恶的。”"},
{volumn:         5,chapter:        25,versenumber:        17,verse:"“你要记念你们出埃及的时候，亚玛力人在路上怎样待你。"},
{volumn:         5,chapter:        25,versenumber:        18,verse:"他们在路上遇见你，趁你疲乏困倦击杀你尽后边软弱的人，并不敬畏　神。"},
{volumn:         5,chapter:        25,versenumber:        19,verse:"所以耶和华你　神使你不被四围一切的仇敌扰乱，在耶和华你　神赐你为业的地上得享平安。那时，你要将亚玛力的名号从天下涂抹了，不可忘记。”"},
{volumn:         5,chapter:        26,versenumber:         1,verse:"“你进去得了耶和华你　神所赐你为业之地居住，"},
{volumn:         5,chapter:        26,versenumber:         2,verse:"就要从耶和华你　神赐你的地上将所收的各种初熟的土产取些来，盛在筐子里，往耶和华你　神所选择要立为他名的居所去，"},
{volumn:         5,chapter:        26,versenumber:         3,verse:"见当时作祭司的，对他说：‘我今日向耶和华你　神明认，我已来到耶和华向我们列祖起誓应许赐给我们的地。’"},
{volumn:         5,chapter:        26,versenumber:         4,verse:"祭司就从你手里取过筐子来，放在耶和华你　神的坛前。"},
{volumn:         5,chapter:        26,versenumber:         5,verse:"你要在耶和华你　神面前说：‘我祖原是一个将亡的亚兰人，下到埃及寄居。他人口稀少，在那里却成了又大又强、人数很多的国民。"},
{volumn:         5,chapter:        26,versenumber:         6,verse:"埃及人恶待我们，苦害我们，将苦工加在我们身上。"},
{volumn:         5,chapter:        26,versenumber:         7,verse:"于是我们哀求耶和华我们列祖的　神，耶和华听见我们的声音，看见我们所受的困苦、劳碌、欺压，"},
{volumn:         5,chapter:        26,versenumber:         8,verse:"他就用大能的手和伸出来的膀臂，并大可畏的事与神迹奇事，领我们出了埃及，"},
{volumn:         5,chapter:        26,versenumber:         9,verse:"将我们领进这地方，把这流奶与蜜之地赐给我们。"},
{volumn:         5,chapter:        26,versenumber:        10,verse:"耶和华啊，现在我把你所赐给我地上初熟的土产奉了来。’随后你要把筐子放在耶和华你　神面前，向耶和华你的　神下拜。"},
{volumn:         5,chapter:        26,versenumber:        11,verse:"你和利未人，并在你们中间寄居的，要因耶和华你　神所赐你和你家的一切福分欢乐。"},
{volumn:         5,chapter:        26,versenumber:        12,verse:"“每逢三年，就是十分取一之年，你取完了一切土产的十分之一，要分给利未人和寄居的，与孤儿寡妇，使他们在你城中可以吃得饱足。"},
{volumn:         5,chapter:        26,versenumber:        13,verse:"你又要在耶和华你　神面前说：‘我已将圣物从我家里拿出来，给了利未人和寄居的，与孤儿寡妇，是照你所吩咐我的一切命令。你的命令我都没有违背，也没有忘记。"},
{volumn:         5,chapter:        26,versenumber:        14,verse:"我守丧的时候，没有吃这圣物；不洁净的时候，也没有拿出来，又没有为死人送去。我听从了耶和华我　神的话，都照你所吩咐的行了。"},
{volumn:         5,chapter:        26,versenumber:        15,verse:"求你从天上、你的圣所垂看，赐福给你的百姓以色列与你所赐给我们的地，就是你向我们列祖起誓赐我们流奶与蜜之地。’”"},
{volumn:         5,chapter:        26,versenumber:        16,verse:"“耶和华你的　神今日吩咐你行这些律例典章，所以你要尽心尽性谨守遵行。"},
{volumn:         5,chapter:        26,versenumber:        17,verse:"你今日认耶和华为你的　神，应许遵行他的道，谨守他的律例、诫命、典章，听从他的话。"},
{volumn:         5,chapter:        26,versenumber:        18,verse:"耶和华今日照他所应许你的，也认你为他的子民，使你谨守他的一切诫命，"},
{volumn:         5,chapter:        26,versenumber:        19,verse:"又使你得称赞、美名、尊荣，超乎他所造的万民之上，并照他所应许的使你归耶和华你　神为圣洁的民。”"},
{volumn:         5,chapter:        27,versenumber:         1,verse:"摩西和以色列的众长老吩咐百姓说：“你们要遵守我今日所吩咐的一切诫命。"},
{volumn:         5,chapter:        27,versenumber:         2,verse:"你们过约旦河，到了耶和华你　神所赐给你的地，当天要立起几块大石头，墁上石灰，"},
{volumn:         5,chapter:        27,versenumber:         3,verse:"把这律法的一切话写在石头上。你过了河，可以进入耶和华你　神所赐你流奶与蜜之地，正如耶和华你列祖之　神所应许你的。"},
{volumn:         5,chapter:        27,versenumber:         4,verse:"你们过了约旦河，就要在以巴路山上照我今日所吩咐的，将这些石头立起来，墁上石灰。"},
{volumn:         5,chapter:        27,versenumber:         5,verse:"在那里要为耶和华你的　神筑一座石坛；在石头上不可动铁器。"},
{volumn:         5,chapter:        27,versenumber:         6,verse:"要用没有凿过的石头筑耶和华你　神的坛，在坛上要将燔祭献给耶和华你的　神。"},
{volumn:         5,chapter:        27,versenumber:         7,verse:"又要献平安祭，且在那里吃，在耶和华你的　神面前欢乐。"},
{volumn:         5,chapter:        27,versenumber:         8,verse:"你要将这律法的一切话明明地写在石头上。”"},
{volumn:         5,chapter:        27,versenumber:         9,verse:"摩西和祭司利未人晓谕以色列众人说：“以色列啊，要默默静听。你今日成为耶和华你　神的百姓了。"},
{volumn:         5,chapter:        27,versenumber:        10,verse:"所以要听从耶和华你　神的话，遵行他的诫命律例，就是我今日所吩咐你的。”"},
{volumn:         5,chapter:        27,versenumber:        11,verse:"当日，摩西嘱咐百姓说："},
{volumn:         5,chapter:        27,versenumber:        12,verse:"“你们过了约旦河，西缅、利未、犹大、以萨迦、约瑟、便雅悯六个支派的人都要站在基利心山上为百姓祝福。"},
{volumn:         5,chapter:        27,versenumber:        13,verse:"流便、迦得、亚设、西布伦、但、拿弗他利六个支派的人都要站在以巴路山上宣布咒诅。"},
{volumn:         5,chapter:        27,versenumber:        14,verse:"利未人要向以色列众人高声说："},
{volumn:         5,chapter:        27,versenumber:        15,verse:"“‘有人制造耶和华所憎恶的偶像，或雕刻，或铸造，就是工匠手所做的，在暗中设立，那人必受咒诅！’百姓都要答应说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        16,verse:"“‘轻慢父母的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        17,verse:"“‘挪移邻舍地界的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        18,verse:"“‘使瞎子走差路的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        19,verse:"“‘向寄居的和孤儿寡妇屈枉正直的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        20,verse:"“‘与继母行淫的，必受咒诅！因为掀开他父亲的衣襟。’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        21,verse:"“‘与兽淫合的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        22,verse:"“‘与异母同父，或异父同母的姐妹行淫的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        23,verse:"“‘与岳母行淫的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        24,verse:"“‘暗中杀人的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        25,verse:"“‘受贿赂害死无辜之人的，必受咒诅！’百姓都要说：‘阿们！’"},
{volumn:         5,chapter:        27,versenumber:        26,verse:"“‘不坚守遵行这律法言语的，必受咒诅！’百姓都要说：‘阿们！’”"},
{volumn:         5,chapter:        28,versenumber:         1,verse:"“你若留意听从耶和华你　神的话，谨守遵行他的一切诫命，就是我今日所吩咐你的，他必使你超乎天下万民之上。"},
{volumn:         5,chapter:        28,versenumber:         2,verse:"你若听从耶和华你　神的话，这以下的福必追随你，临到你身上："},
{volumn:         5,chapter:        28,versenumber:         3,verse:"你在城里必蒙福，在田间也必蒙福。"},
{volumn:         5,chapter:        28,versenumber:         4,verse:"你身所生的，地所产的，牲畜所下的，以及牛犊、羊羔，都必蒙福。"},
{volumn:         5,chapter:        28,versenumber:         5,verse:"你的筐子和你的抟面盆都必蒙福。"},
{volumn:         5,chapter:        28,versenumber:         6,verse:"你出也蒙福，入也蒙福。"},
{volumn:         5,chapter:        28,versenumber:         7,verse:"“仇敌起来攻击你，耶和华必使他们在你面前被你杀败；他们从一条路来攻击你，必从七条路逃跑。"},
{volumn:         5,chapter:        28,versenumber:         8,verse:"在你仓房里，并你手所办的一切事上，耶和华所命的福必临到你。耶和华你　神也要在所给你的地上赐福与你。"},
{volumn:         5,chapter:        28,versenumber:         9,verse:"你若谨守耶和华你　神的诫命，遵行他的道，他必照着向你所起的誓立你作为自己的圣民。"},
{volumn:         5,chapter:        28,versenumber:        10,verse:"天下万民见你归在耶和华的名下，就要惧怕你。"},
{volumn:         5,chapter:        28,versenumber:        11,verse:"你在耶和华向你列祖起誓应许赐你的地上，他必使你身所生的，牲畜所下的，地所产的，都绰绰有余。"},
{volumn:         5,chapter:        28,versenumber:        12,verse:"耶和华必为你开天上的府库，按时降雨在你的地上。在你手里所办的一切事上赐福与你。你必借给许多国民，却不致向他们借贷。"},
{volumn:         5,chapter:        28,versenumber:        13,verse:"你若听从耶和华你　神的诫命，就是我今日所吩咐你的，谨守遵行，不偏左右，也不随从侍奉别神，耶和华就必使你作首不作尾，但居上不居下。”"},
{volumn:         5,chapter:        28,versenumber:        14,verse:""},
{volumn:         5,chapter:        28,versenumber:        15,verse:"“你若不听从耶和华你　神的话，不谨守遵行他的一切诫命律例，就是我今日所吩咐你的，这以下的咒诅都必追随你，临到你身上："},
{volumn:         5,chapter:        28,versenumber:        16,verse:"你在城里必受咒诅，在田间也必受咒诅。"},
{volumn:         5,chapter:        28,versenumber:        17,verse:"你的筐子和你的抟面盆都必受咒诅。"},
{volumn:         5,chapter:        28,versenumber:        18,verse:"你身所生的，地所产的，以及牛犊、羊羔，都必受咒诅。"},
{volumn:         5,chapter:        28,versenumber:        19,verse:"你出也受咒诅，入也受咒诅。"},
{volumn:         5,chapter:        28,versenumber:        20,verse:"耶和华因你行恶离弃他，必在你手里所办的一切事上，使咒诅、扰乱、责罚临到你，直到你被毁灭，速速地灭亡。"},
{volumn:         5,chapter:        28,versenumber:        21,verse:"耶和华必使瘟疫贴在你身上，直到他将你从所进去得为业的地上灭绝。"},
{volumn:         5,chapter:        28,versenumber:        22,verse:"耶和华要用痨病、热病、火症、疟疾、刀剑、旱风（或译：干旱）、霉烂攻击你。这都要追赶你，直到你灭亡。"},
{volumn:         5,chapter:        28,versenumber:        23,verse:"你头上的天要变为铜，脚下的地要变为铁。"},
{volumn:         5,chapter:        28,versenumber:        24,verse:"耶和华要使那降在你地上的雨变为尘沙，从天临在你身上，直到你灭亡。"},
{volumn:         5,chapter:        28,versenumber:        25,verse:"“耶和华必使你败在仇敌面前，你从一条路去攻击他们，必从七条路逃跑。你必在天下万国中抛来抛去。"},
{volumn:         5,chapter:        28,versenumber:        26,verse:"你的尸首必给空中的飞鸟和地上的走兽作食物，并无人哄赶。"},
{volumn:         5,chapter:        28,versenumber:        27,verse:"耶和华必用埃及人的疮并痔疮、牛皮癣与疥攻击你，使你不能医治。"},
{volumn:         5,chapter:        28,versenumber:        28,verse:"耶和华必用癫狂、眼瞎、心惊攻击你。"},
{volumn:         5,chapter:        28,versenumber:        29,verse:"你必在午间摸索，好像瞎子在暗中摸索一样。你所行的必不亨通，时常遭遇欺压、抢夺，无人搭救。"},
{volumn:         5,chapter:        28,versenumber:        30,verse:"你聘定了妻，别人必与她同房；你建造房屋，不得住在其内；你栽种葡萄园，也不得用其中的果子。"},
{volumn:         5,chapter:        28,versenumber:        31,verse:"你的牛在你眼前宰了，你必不得吃它的肉；你的驴在你眼前被抢夺，不得归还；你的羊归了仇敌，无人搭救。"},
{volumn:         5,chapter:        28,versenumber:        32,verse:"你的儿女必归与别国的民；你的眼目终日切望，甚至失明，你手中无力拯救。"},
{volumn:         5,chapter:        28,versenumber:        33,verse:"你的土产和你劳碌得来的，必被你所不认识的国民吃尽。你时常被欺负，受压制，"},
{volumn:         5,chapter:        28,versenumber:        34,verse:"甚至你因眼中所看见的，必致疯狂。"},
{volumn:         5,chapter:        28,versenumber:        35,verse:"耶和华必攻击你，使你膝上腿上，从脚掌到头顶，长毒疮无法医治。"},
{volumn:         5,chapter:        28,versenumber:        36,verse:"“耶和华必将你和你所立的王领到你和你列祖素不认识的国去；在那里你必侍奉木头石头的神。"},
{volumn:         5,chapter:        28,versenumber:        37,verse:"你在耶和华领你到的各国中，要令人惊骇、笑谈、讥诮。"},
{volumn:         5,chapter:        28,versenumber:        38,verse:"你带到田间的种子虽多，收进来的却少，因为被蝗虫吃了。"},
{volumn:         5,chapter:        28,versenumber:        39,verse:"你栽种、修理葡萄园，却不得收葡萄，也不得喝葡萄酒，因为被虫子吃了。"},
{volumn:         5,chapter:        28,versenumber:        40,verse:"你全境有橄榄树，却不得其油抹身，因为树上的橄榄不熟自落了。"},
{volumn:         5,chapter:        28,versenumber:        41,verse:"你生儿养女，却不算是你的，因为必被掳去。"},
{volumn:         5,chapter:        28,versenumber:        42,verse:"你所有的树木和你地里的出产必被蝗虫所吃。"},
{volumn:         5,chapter:        28,versenumber:        43,verse:"在你中间寄居的，必渐渐上升，比你高而又高；你必渐渐下降，低而又低。"},
{volumn:         5,chapter:        28,versenumber:        44,verse:"他必借给你，你却不能借给他；他必作首，你必作尾。"},
{volumn:         5,chapter:        28,versenumber:        45,verse:"这一切咒诅必追随你，赶上你，直到你灭亡；因为你不听从耶和华你　神的话，不遵守他所吩咐的诫命律例。"},
{volumn:         5,chapter:        28,versenumber:        46,verse:"这些咒诅必在你和你后裔的身上成为异迹奇事，直到永远！"},
{volumn:         5,chapter:        28,versenumber:        47,verse:"“因为你富有的时候，不欢心乐意地侍奉耶和华你的　神，"},
{volumn:         5,chapter:        28,versenumber:        48,verse:"所以你必在饥饿、干渴、赤露、缺乏之中侍奉耶和华所打发来攻击你的仇敌。他必把铁轭加在你的颈项上，直到将你灭绝。"},
{volumn:         5,chapter:        28,versenumber:        49,verse:"“耶和华要从远方、地极带一国的民，如鹰飞来攻击你。这民的言语，你不懂得。"},
{volumn:         5,chapter:        28,versenumber:        50,verse:"这民的面貌凶恶，不顾恤年老的，也不恩待年少的。"},
{volumn:         5,chapter:        28,versenumber:        51,verse:"他们必吃你牲畜所下的和你地土所产的，直到你灭亡。你的五谷、新酒，和油，以及牛犊、羊羔，都不给你留下，直到将你灭绝。"},
{volumn:         5,chapter:        28,versenumber:        52,verse:"他们必将你困在你各城里，直到你所倚靠、高大坚固的城墙都被攻塌。他们必将你困在耶和华你　神所赐你遍地的各城里。"},
{volumn:         5,chapter:        28,versenumber:        53,verse:"你在仇敌围困窘迫之中，必吃你本身所生的，就是耶和华你　神所赐给你的儿女之肉。"},
{volumn:         5,chapter:        28,versenumber:        54,verse:"你们中间，柔弱娇嫩的人必恶眼看他弟兄和他怀中的妻，并他余剩的儿女；"},
{volumn:         5,chapter:        28,versenumber:        55,verse:"甚至在你受仇敌围困窘迫的城中，他要吃儿女的肉，不肯分一点给他的亲人，因为他一无所剩。"},
{volumn:         5,chapter:        28,versenumber:        56,verse:"你们中间，柔弱娇嫩的妇人，是因娇嫩柔弱不肯把脚踏地的，必恶眼看她怀中的丈夫和她的儿女。"},
{volumn:         5,chapter:        28,versenumber:        57,verse:"她两腿中间出来的婴孩与她所要生的儿女，她因缺乏一切就要在你受仇敌围困窘迫的城中将他们暗暗地吃了。"},
{volumn:         5,chapter:        28,versenumber:        58,verse:"“这书上所写律法的一切话是叫你敬畏耶和华你　神可荣可畏的名。你若不谨守遵行，耶和华就必将奇灾，就是至大至长的灾，至重至久的病，加在你和你后裔的身上，"},
{volumn:         5,chapter:        28,versenumber:        59,verse:""},
{volumn:         5,chapter:        28,versenumber:        60,verse:"也必使你所惧怕、埃及人的病都临到你，贴在你身上，"},
{volumn:         5,chapter:        28,versenumber:        61,verse:"又必将没有写在这律法书上的各样疾病、灾殃降在你身上，直到你灭亡。"},
{volumn:         5,chapter:        28,versenumber:        62,verse:"你们先前虽然像天上的星那样多，却因不听从耶和华你　神的话，所剩的人数就稀少了。"},
{volumn:         5,chapter:        28,versenumber:        63,verse:"先前耶和华怎样喜悦善待你们，使你们众多，也要照样喜悦毁灭你们，使你们灭亡；并且你们从所要进去得的地上必被拔除。"},
{volumn:         5,chapter:        28,versenumber:        64,verse:"耶和华必使你们分散在万民中，从地这边到地那边，你必在那里侍奉你和你列祖素不认识、木头石头的神。"},
{volumn:         5,chapter:        28,versenumber:        65,verse:"在那些国中，你必不得安逸，也不得落脚之地；耶和华却使你在那里心中跳动，眼目失明，精神消耗。"},
{volumn:         5,chapter:        28,versenumber:        66,verse:"你的性命必悬悬无定；你昼夜恐惧，自料性命难保。"},
{volumn:         5,chapter:        28,versenumber:        67,verse:"你因心里所恐惧的，眼中所看见的，早晨必说，巴不得到晚上才好；晚上必说，巴不得到早晨才好。"},
{volumn:         5,chapter:        28,versenumber:        68,verse:"耶和华必使你坐船回埃及去，走我曾告诉你不得再见的路；在那里你必卖己身与仇敌作奴婢，却无人买。”"},
{volumn:         5,chapter:        29,versenumber:         1,verse:"这是耶和华在摩押地吩咐摩西与以色列人立约的话，是在他和他们于何烈山所立的约之外。"},
{volumn:         5,chapter:        29,versenumber:         2,verse:"摩西召了以色列众人来，对他们说：“耶和华在埃及地，在你们眼前向法老和他众臣仆，并他全地所行的一切事，你们都看见了，"},
{volumn:         5,chapter:        29,versenumber:         3,verse:"就是你亲眼看见的大试验和神迹，并那些大奇事。"},
{volumn:         5,chapter:        29,versenumber:         4,verse:"但耶和华到今日没有使你们心能明白，眼能看见，耳能听见。"},
{volumn:         5,chapter:        29,versenumber:         5,verse:"我领你们在旷野四十年，你们身上的衣服并没有穿破，脚上的鞋也没有穿坏。"},
{volumn:         5,chapter:        29,versenumber:         6,verse:"你们没有吃饼，也没有喝清酒浓酒。这要使你们知道，耶和华是你们的　神。"},
{volumn:         5,chapter:        29,versenumber:         7,verse:"你们来到这地方，希实本王西宏、巴珊王噩都出来与我们交战，我们就击杀了他们，"},
{volumn:         5,chapter:        29,versenumber:         8,verse:"取了他们的地给流便支派、迦得支派，和玛拿西半支派为业。"},
{volumn:         5,chapter:        29,versenumber:         9,verse:"所以你们要谨守遵行这约的话，好叫你们在一切所行的事上亨通。"},
{volumn:         5,chapter:        29,versenumber:        10,verse:"“今日，你们的首领、族长（原文是支派）、长老、官长、以色列的男丁，你们的妻子儿女，和营中寄居的，以及为你们劈柴挑水的人，都站在耶和华你们的　神面前，"},
{volumn:         5,chapter:        29,versenumber:        11,verse:""},
{volumn:         5,chapter:        29,versenumber:        12,verse:"为要你顺从耶和华你　神今日与你所立的约，向你所起的誓。"},
{volumn:         5,chapter:        29,versenumber:        13,verse:"这样，他要照他向你所应许的话，又向你列祖亚伯拉罕、以撒、雅各所起的誓，今日立你作他的子民，他作你的　神。"},
{volumn:         5,chapter:        29,versenumber:        14,verse:"我不但与你们立这约，起这誓，"},
{volumn:         5,chapter:        29,versenumber:        15,verse:"凡与我们一同站在耶和华我们　神面前的，并今日不在我们这里的人，我也与他们立这约，起这誓。"},
{volumn:         5,chapter:        29,versenumber:        16,verse:"“我们曾住过埃及地，也从列国经过；这是你们知道的。"},
{volumn:         5,chapter:        29,versenumber:        17,verse:"你们也看见他们中间可憎之物，并他们木、石、金、银的偶像。"},
{volumn:         5,chapter:        29,versenumber:        18,verse:"惟恐你们中间，或男或女，或族长或支派长，今日心里偏离耶和华我们的　神，去侍奉那些国的神；又怕你们中间有恶根生出苦菜和茵陈来，"},
{volumn:         5,chapter:        29,versenumber:        19,verse:"听见这咒诅的话，心里仍是自夸说：‘我虽然行事心里顽梗，连累众人，却还是平安。’"},
{volumn:         5,chapter:        29,versenumber:        20,verse:"耶和华必不饶恕他；耶和华的怒气与愤恨要向他发作，如烟冒出，将这书上所写的一切咒诅都加在他身上。耶和华又要从天下涂抹他的名，"},
{volumn:         5,chapter:        29,versenumber:        21,verse:"也必照着写在律法书上、约中的一切咒诅将他从以色列众支派中分别出来，使他受祸。"},
{volumn:         5,chapter:        29,versenumber:        22,verse:"你们的后代，就是以后兴起来的子孙，和远方来的外人，看见这地的灾殃，并耶和华所降与这地的疾病，"},
{volumn:         5,chapter:        29,versenumber:        23,verse:"又看见遍地有硫磺，有盐卤，有火迹，没有耕种，没有出产，连草都不生长，好像耶和华在忿怒中所倾覆的所多玛、蛾摩拉、押玛、洗扁一样。"},
{volumn:         5,chapter:        29,versenumber:        24,verse:"所看见的人，连万国人，都必问说：‘耶和华为何向此地这样行呢？这样大发烈怒是什么意思呢？’"},
{volumn:         5,chapter:        29,versenumber:        25,verse:"人必回答说：‘是因这地的人离弃了耶和华他们列祖的　神，领他们出埃及地的时候与他们所立的约，"},
{volumn:         5,chapter:        29,versenumber:        26,verse:"去侍奉敬拜素不认识的别神，是耶和华所未曾给他们安排的。"},
{volumn:         5,chapter:        29,versenumber:        27,verse:"所以耶和华的怒气向这地发作，将这书上所写的一切咒诅都降在这地上。"},
{volumn:         5,chapter:        29,versenumber:        28,verse:"耶和华在怒气、忿怒、大恼恨中将他们从本地拔出来，扔在别的地上，像今日一样。’"},
{volumn:         5,chapter:        29,versenumber:        29,verse:"“隐秘的事是属耶和华我们　神的；惟有明显的事是永远属我们和我们子孙的，好叫我们遵行这律法上的一切话。”"},
{volumn:         5,chapter:        30,versenumber:         1,verse:"“我所陈明在你面前的这一切咒诅都临到你身上；你在耶和华你　神追赶你到的万国中必心里追念祝福的话；"},
{volumn:         5,chapter:        30,versenumber:         2,verse:"你和你的子孙若尽心尽性归向耶和华你的　神，照着我今日一切所吩咐的听从他的话；"},
{volumn:         5,chapter:        30,versenumber:         3,verse:"那时，耶和华你的　神必怜恤你，救回你这被掳的子民；耶和华你的　神要回转过来，从分散你到的万民中将你招聚回来。"},
{volumn:         5,chapter:        30,versenumber:         4,verse:"你被赶散的人，就是在天涯的，耶和华你的　神也必从那里将你招聚回来。"},
{volumn:         5,chapter:        30,versenumber:         5,verse:"耶和华你的　神必领你进入你列祖所得的地，使你可以得着；又必善待你，使你的人数比你列祖众多。"},
{volumn:         5,chapter:        30,versenumber:         6,verse:"耶和华你　神必将你心里和你后裔心里的污秽除掉，好叫你尽心尽性爱耶和华你的　神，使你可以存活。"},
{volumn:         5,chapter:        30,versenumber:         7,verse:"耶和华你的　神必将这一切咒诅加在你仇敌和恨恶你、逼迫你的人身上。"},
{volumn:         5,chapter:        30,versenumber:         8,verse:"你必归回，听从耶和华的话，遵行他的一切诫命，就是我今日所吩咐你的。"},
{volumn:         5,chapter:        30,versenumber:         9,verse:"你若听从耶和华你　神的话，谨守这律法书上所写的诫命律例，又尽心尽性归向耶和华你的　神，他必使你手里所办的一切事，并你身所生的，牲畜所下的，地土所产的，都绰绰有余；因为耶和华必再喜悦你，降福与你，像从前喜悦你列祖一样。"},
{volumn:         5,chapter:        30,versenumber:        10,verse:""},
{volumn:         5,chapter:        30,versenumber:        11,verse:"“我今日所吩咐你的诫命不是你难行的，也不是离你远的；"},
{volumn:         5,chapter:        30,versenumber:        12,verse:"不是在天上，使你说：‘谁替我们上天取下来，使我们听见可以遵行呢？’"},
{volumn:         5,chapter:        30,versenumber:        13,verse:"也不是在海外，使你说：‘谁替我们过海取了来，使我们听见可以遵行呢？’"},
{volumn:         5,chapter:        30,versenumber:        14,verse:"这话却离你甚近，就在你口中，在你心里，使你可以遵行。"},
{volumn:         5,chapter:        30,versenumber:        15,verse:"“看哪，我今日将生与福，死与祸，陈明在你面前。"},
{volumn:         5,chapter:        30,versenumber:        16,verse:"吩咐你爱耶和华你的　神，遵行他的道，谨守他的诫命、律例、典章，使你可以存活，人数增多，耶和华你　神就必在你所要进去得为业的地上赐福与你。"},
{volumn:         5,chapter:        30,versenumber:        17,verse:"倘若你心里偏离，不肯听从，却被勾引去敬拜侍奉别神，"},
{volumn:         5,chapter:        30,versenumber:        18,verse:"我今日明明告诉你们，你们必要灭亡；在你过约旦河、进去得为业的地上，你的日子必不长久。"},
{volumn:         5,chapter:        30,versenumber:        19,verse:"我今日呼天唤地向你作见证；我将生死祸福陈明在你面前，所以你要拣选生命，使你和你的后裔都得存活；"},
{volumn:         5,chapter:        30,versenumber:        20,verse:"且爱耶和华你的　神，听从他的话，专靠他；因为他是你的生命，你的日子长久也在乎他。这样，你就可以在耶和华向你列祖亚伯拉罕、以撒、雅各起誓应许所赐的地上居住。”"},
{volumn:         5,chapter:        31,versenumber:         1,verse:"摩西去告诉以色列众人"},
{volumn:         5,chapter:        31,versenumber:         2,verse:"说：“我现在一百二十岁了，不能照常出入；耶和华也曾对我说：‘你必不得过这约旦河。’"},
{volumn:         5,chapter:        31,versenumber:         3,verse:"耶和华你们的　神必引导你们过去，将这些国民在你们面前灭绝，你们就得他们的地。约书亚必引导你们过去，正如耶和华所说的。"},
{volumn:         5,chapter:        31,versenumber:         4,verse:"耶和华必待他们，如同从前待他所灭绝的亚摩利二王西宏与噩以及他们的国一样。"},
{volumn:         5,chapter:        31,versenumber:         5,verse:"耶和华必将他们交给你们；你们要照我所吩咐的一切命令待他们。"},
{volumn:         5,chapter:        31,versenumber:         6,verse:"你们当刚强壮胆，不要害怕，也不要畏惧他们，因为耶和华你的　神和你同去。他必不撇下你，也不丢弃你。”"},
{volumn:         5,chapter:        31,versenumber:         7,verse:"摩西召了约书亚来，在以色列众人眼前对他说：“你当刚强壮胆！因为，你要和这百姓一同进入耶和华向他们列祖起誓应许所赐之地；你也要使他们承受那地为业。"},
{volumn:         5,chapter:        31,versenumber:         8,verse:"耶和华必在你前面行；他必与你同在，必不撇下你，也不丢弃你。不要惧怕，也不要惊惶。”"},
{volumn:         5,chapter:        31,versenumber:         9,verse:"摩西将这律法写出来，交给抬耶和华约柜的祭司利未子孙和以色列的众长老。"},
{volumn:         5,chapter:        31,versenumber:        10,verse:"摩西吩咐他们说：“每逢七年的末一年，就在豁免年的定期住棚节的时候，"},
{volumn:         5,chapter:        31,versenumber:        11,verse:"以色列众人来到耶和华你　神所选择的地方朝见他。那时，你要在以色列众人面前将这律法念给他们听。"},
{volumn:         5,chapter:        31,versenumber:        12,verse:"要招聚他们男、女、孩子，并城里寄居的，使他们听，使他们学习，好敬畏耶和华你们的　神，谨守、遵行这律法的一切话，"},
{volumn:         5,chapter:        31,versenumber:        13,verse:"也使他们未曾晓得这律法的儿女得以听见，学习敬畏耶和华你们的　神，在你们过约旦河要得为业之地，存活的日子，常常这样行。”"},
{volumn:         5,chapter:        31,versenumber:        14,verse:"耶和华对摩西说：“你的死期临近了；要召约书亚来，你们二人站在会幕里，我好嘱咐他。”于是摩西和约书亚去站在会幕里。"},
{volumn:         5,chapter:        31,versenumber:        15,verse:"耶和华在会幕里云柱中显现，云柱停在会幕门以上。"},
{volumn:         5,chapter:        31,versenumber:        16,verse:"耶和华又对摩西说：“你必和你列祖同睡。这百姓要起来，在他们所要去的地上，在那地的人中，随从外邦神行邪淫，离弃我，违背我与他们所立的约。"},
{volumn:         5,chapter:        31,versenumber:        17,verse:"那时，我的怒气必向他们发作；我也必离弃他们，掩面不顾他们，以致他们被吞灭，并有许多的祸患灾难临到他们。那日他们必说：‘这些祸患临到我们，岂不是因我们的　神不在我们中间吗？’"},
{volumn:         5,chapter:        31,versenumber:        18,verse:"那时，因他们偏向别神所行的一切恶，我必定掩面不顾他们。"},
{volumn:         5,chapter:        31,versenumber:        19,verse:"现在你要写一篇歌，教导以色列人，传给他们，使这歌见证他们的不是；"},
{volumn:         5,chapter:        31,versenumber:        20,verse:"因为我将他们领进我向他们列祖起誓应许那流奶与蜜之地，他们在那里吃得饱足，身体肥胖，就必偏向别神，侍奉他们，藐视我，背弃我的约。"},
{volumn:         5,chapter:        31,versenumber:        21,verse:"那时，有许多祸患灾难临到他们，这歌必在他们面前作见证，他们后裔的口中必念诵不忘。我未领他们到我所起誓应许之地以先，他们所怀的意念我都知道了。”"},
{volumn:         5,chapter:        31,versenumber:        22,verse:"当日摩西就写了一篇歌，教导以色列人。"},
{volumn:         5,chapter:        31,versenumber:        23,verse:"耶和华嘱咐嫩的儿子约书亚说：“你当刚强壮胆，因为你必领以色列人进我所起誓应许他们的地；我必与你同在。”"},
{volumn:         5,chapter:        31,versenumber:        24,verse:"摩西将这律法的话写在书上，及至写完了，"},
{volumn:         5,chapter:        31,versenumber:        25,verse:"就吩咐抬耶和华约柜的利未人说："},
{volumn:         5,chapter:        31,versenumber:        26,verse:"“将这律法书放在耶和华你们　神的约柜旁，可以在那里见证以色列人的不是；"},
{volumn:         5,chapter:        31,versenumber:        27,verse:"因为我知道你们是悖逆的，是硬着颈项的。我今日还活着与你们同在，你们尚且悖逆耶和华，何况我死后呢？"},
{volumn:         5,chapter:        31,versenumber:        28,verse:"你们要将你们支派的众长老和官长都招聚了来，我好将这些话说与他们听，并呼天唤地见证他们的不是。"},
{volumn:         5,chapter:        31,versenumber:        29,verse:"我知道我死后，你们必全然败坏，偏离我所吩咐你们的道，行耶和华眼中看为恶的事，以手所做的惹他发怒；日后必有祸患临到你们。”"},
{volumn:         5,chapter:        31,versenumber:        30,verse:"摩西将这一篇歌的话都说与以色列全会众听。"},
{volumn:         5,chapter:        32,versenumber:         1,verse:"“诸天哪，侧耳，我要说话；愿地也听我口中的言语。"},
{volumn:         5,chapter:        32,versenumber:         2,verse:"我的教训要淋漓如雨；我的言语要滴落如露，如细雨降在嫩草上，如甘霖降在菜蔬中。"},
{volumn:         5,chapter:        32,versenumber:         3,verse:"我要宣告耶和华的名；你们要将大德归与我们的　神。"},
{volumn:         5,chapter:        32,versenumber:         4,verse:"他是磐石，他的作为完全；他所行的无不公平，是诚实无伪的　神，又公义，又正直。"},
{volumn:         5,chapter:        32,versenumber:         5,verse:"这乖僻弯曲的世代向他行事邪僻；有这弊病就不是他的儿女。"},
{volumn:         5,chapter:        32,versenumber:         6,verse:"愚昧无知的民哪，你们这样报答耶和华吗？他岂不是你的父、将你买来的吗？他是制造你、建立你的。"},
{volumn:         5,chapter:        32,versenumber:         7,verse:"你当追想上古之日，思念历代之年；问你的父亲，他必指示你；问你的长者，他必告诉你。"},
{volumn:         5,chapter:        32,versenumber:         8,verse:"至高者将地业赐给列邦，将世人分开，就照以色列人的数目立定万民的疆界。"},
{volumn:         5,chapter:        32,versenumber:         9,verse:"耶和华的份本是他的百姓；他的产业本是雅各。"},
{volumn:         5,chapter:        32,versenumber:        10,verse:"耶和华遇见他在旷野荒凉野兽吼叫之地，就环绕他，看顾他，保护他，如同保护眼中的瞳人。"},
{volumn:         5,chapter:        32,versenumber:        11,verse:"又如鹰搅动巢窝，在雏鹰以上两翅搧展，接取雏鹰，背在两翼之上。"},
{volumn:         5,chapter:        32,versenumber:        12,verse:"这样，耶和华独自引导他，并无外邦神与他同在。"},
{volumn:         5,chapter:        32,versenumber:        13,verse:"耶和华使他乘驾地的高处，得吃田间的土产；又使他从磐石中咂蜜，从坚石中吸油；"},
{volumn:         5,chapter:        32,versenumber:        14,verse:"也吃牛的奶油，羊的奶，羊羔的脂油，巴珊所出的公绵羊和山羊，与上好的麦子，也喝葡萄汁酿的酒。"},
{volumn:         5,chapter:        32,versenumber:        15,verse:"但耶书仑渐渐肥胖，粗壮，光润，踢跳，奔跑，便离弃造他的　神，轻看救他的磐石；"},
{volumn:         5,chapter:        32,versenumber:        16,verse:"敬拜别神，触动　神的愤恨，行可憎恶的事，惹了他的怒气。"},
{volumn:         5,chapter:        32,versenumber:        17,verse:"所祭祀的鬼魔并非真神，乃是素不认识的神，是近来新兴的，是你列祖所不畏惧的。"},
{volumn:         5,chapter:        32,versenumber:        18,verse:"你轻忽生你的磐石，忘记产你的　神。"},
{volumn:         5,chapter:        32,versenumber:        19,verse:"耶和华看见他的儿女惹动他，就厌恶他们。"},
{volumn:         5,chapter:        32,versenumber:        20,verse:"说：我要向他们掩面，看他们的结局如何。他们本是极乖僻的族类，心中无诚实的儿女。"},
{volumn:         5,chapter:        32,versenumber:        21,verse:"他们以那“不算为神”的触动我的愤恨，以虚无的神惹了我的怒气。我也要以那“不成子民”的触动他们的愤恨，以愚昧的国民惹了他们的怒气。"},
{volumn:         5,chapter:        32,versenumber:        22,verse:"因为在我怒中有火烧起，直烧到极深的阴间，把地和地的出产尽都焚烧，山的根基也烧着了。"},
{volumn:         5,chapter:        32,versenumber:        23,verse:"我要将祸患堆在他们身上，把我的箭向他们射尽。"},
{volumn:         5,chapter:        32,versenumber:        24,verse:"他们必因饥饿消瘦，被炎热苦毒吞灭。我要打发野兽用牙齿咬他们，并土中腹行的，用毒气害他们。"},
{volumn:         5,chapter:        32,versenumber:        25,verse:"外头有刀剑，内室有惊恐，使人丧亡，使少男、童女、吃奶的、白发的，尽都灭绝。’"},
{volumn:         5,chapter:        32,versenumber:        26,verse:"我说：‘我必将他们分散远方，使他们的名号从人间除灭。’"},
{volumn:         5,chapter:        32,versenumber:        27,verse:"惟恐仇敌惹动我，只怕敌人错看，说：‘是我们手的能力，并非耶和华所行的。’"},
{volumn:         5,chapter:        32,versenumber:        28,verse:"因为以色列民毫无计谋，心中没有聪明。"},
{volumn:         5,chapter:        32,versenumber:        29,verse:"惟愿他们有智慧，能明白这事，肯思念他们的结局。"},
{volumn:         5,chapter:        32,versenumber:        30,verse:"若不是他们的磐石卖了他们，若不是耶和华交出他们，一人焉能追赶他们千人？二人焉能使万人逃跑呢？"},
{volumn:         5,chapter:        32,versenumber:        31,verse:"据我们的仇敌自己断定，他们的磐石不如我们的磐石。"},
{volumn:         5,chapter:        32,versenumber:        32,verse:"他们的葡萄树是所多玛的葡萄树，蛾摩拉田园所生的；他们的葡萄是毒葡萄，全挂都是苦的。"},
{volumn:         5,chapter:        32,versenumber:        33,verse:"他们的酒是大蛇的毒气，是虺蛇残害的恶毒。"},
{volumn:         5,chapter:        32,versenumber:        34,verse:"这不都是积蓄在我这里，封锁在我府库中吗？"},
{volumn:         5,chapter:        32,versenumber:        35,verse:"他们失脚的时候，伸冤报应在我；因他们遭灾的日子近了；那要临在他们身上的必速速来到。"},
{volumn:         5,chapter:        32,versenumber:        36,verse:"耶和华见他百姓毫无能力，无论困住的、自由的都没有剩下，就必为他们伸冤，为他的仆人后悔。"},
{volumn:         5,chapter:        32,versenumber:        37,verse:"他必说：‘他们的神，他们所投靠的磐石，"},
{volumn:         5,chapter:        32,versenumber:        38,verse:"就是向来吃他们祭牲的脂油，喝他们奠祭之酒的，在哪里呢？他可以兴起帮助你们，护卫你们。"},
{volumn:         5,chapter:        32,versenumber:        39,verse:"你们如今要知道：我，惟有我是　神；在我以外并无别神。我使人死，我使人活；我损伤，我也医治，并无人能从我手中救出来。"},
{volumn:         5,chapter:        32,versenumber:        40,verse:"我向天举手说：我凭我的永生起誓："},
{volumn:         5,chapter:        32,versenumber:        41,verse:"我若磨我闪亮的刀，手掌审判之权，就必报复我的敌人，报应恨我的人。"},
{volumn:         5,chapter:        32,versenumber:        42,verse:"我要使我的箭饮血饮醉，就是被杀被掳之人的血。我的刀要吃肉，乃是仇敌中首领之头的肉。’"},
{volumn:         5,chapter:        32,versenumber:        43,verse:"你们外邦人当与主的百姓一同欢呼；因他要伸他仆人流血的冤，报应他的敌人，洁净他的地，救赎他的百姓。”"},
{volumn:         5,chapter:        32,versenumber:        44,verse:"摩西和嫩的儿子约书亚去将这歌的一切话说给百姓听。"},
{volumn:         5,chapter:        32,versenumber:        45,verse:"摩西向以色列众人说完了这一切的话，"},
{volumn:         5,chapter:        32,versenumber:        46,verse:"又说：“我今日所警教你们的，你们都要放在心上；要吩咐你们的子孙谨守遵行这律法上的话。"},
{volumn:         5,chapter:        32,versenumber:        47,verse:"因为这不是虚空、与你们无关的事，乃是你们的生命；在你们过约旦河要得为业的地上必因这事日子得以长久。”"},
{volumn:         5,chapter:        32,versenumber:        48,verse:"当日，耶和华吩咐摩西说："},
{volumn:         5,chapter:        32,versenumber:        49,verse:"“你上这亚巴琳山中的尼波山去，在摩押地与耶利哥相对，观看我所要赐给以色列人为业的迦南地。"},
{volumn:         5,chapter:        32,versenumber:        50,verse:"你必死在你所登的山上，归你列祖（原文是本民）去，像你哥哥亚伦死在何珥山上，归他的列祖一样。"},
{volumn:         5,chapter:        32,versenumber:        51,verse:"因为你们在寻的旷野，加低斯的米利巴水，在以色列人中没有尊我为圣，得罪了我。"},
{volumn:         5,chapter:        32,versenumber:        52,verse:"我所赐给以色列人的地，你可以远远地观看，却不得进去。”"},
{volumn:         5,chapter:        33,versenumber:         1,verse:"以下是神人摩西在未死之先为以色列人所祝的福："},
{volumn:         5,chapter:        33,versenumber:         2,verse:"他说：“耶和华从西奈而来，从西珥向他们显现，从巴兰山发出光辉，从万万圣者中来临，从他右手为百姓传出烈火的律法。"},
{volumn:         5,chapter:        33,versenumber:         3,verse:"他疼爱百姓；众圣徒都在他手中。他们坐在他的脚下，领受他的言语。"},
{volumn:         5,chapter:        33,versenumber:         4,verse:"摩西将律法传给我们，作为雅各会众的产业。"},
{volumn:         5,chapter:        33,versenumber:         5,verse:"百姓的众首领，以色列的各支派，一同聚会的时候，耶和华（原文是他）在耶书仑中为王。"},
{volumn:         5,chapter:        33,versenumber:         6,verse:"“愿流便存活，不致死亡；愿他人数不致稀少。"},
{volumn:         5,chapter:        33,versenumber:         7,verse:"“为犹大祝福说：求耶和华俯听犹大的声音，引导他归于本族；他曾用手为自己争战，你必帮助他攻击敌人。"},
{volumn:         5,chapter:        33,versenumber:         8,verse:"“论利未说：耶和华啊，你的土明和乌陵都在你的虔诚人那里。你在玛撒曾试验他，在米利巴水与他争论。"},
{volumn:         5,chapter:        33,versenumber:         9,verse:"他论自己的父母说：我未曾看见；他也不承认弟兄，也不认识自己的儿女。这是因利未人遵行你的话，谨守你的约。"},
{volumn:         5,chapter:        33,versenumber:        10,verse:"他们要将你的典章教训雅各，将你的律法教训以色列。他们要把香焚在你面前，把全牲的燔祭献在你的坛上。"},
{volumn:         5,chapter:        33,versenumber:        11,verse:"求耶和华降福在他的财物上，悦纳他手里所办的事。那些起来攻击他和恨恶他的人，愿你刺透他们的腰，使他们不得再起来。"},
{volumn:         5,chapter:        33,versenumber:        12,verse:"“论便雅悯说：耶和华所亲爱的必同耶和华安然居住；耶和华终日遮蔽他，也住在他两肩之中。"},
{volumn:         5,chapter:        33,versenumber:        13,verse:"“论约瑟说：愿他的地蒙耶和华赐福，得天上的宝物、甘露，以及地里所藏的泉水；"},
{volumn:         5,chapter:        33,versenumber:        14,verse:"得太阳所晒熟的美果，月亮所养成的宝物；"},
{volumn:         5,chapter:        33,versenumber:        15,verse:"得上古之山的至宝，永世之岭的宝物；"},
{volumn:         5,chapter:        33,versenumber:        16,verse:"得地和其中所充满的宝物，并住荆棘中上主的喜悦。愿这些福都归于约瑟的头上，归于那与弟兄迥别之人的顶上。"},
{volumn:         5,chapter:        33,versenumber:        17,verse:"他为牛群中头生的，有威严；他的角是野牛的角，用以抵触万邦，直到地极。这角是以法莲的万万，玛拿西的千千。"},
{volumn:         5,chapter:        33,versenumber:        18,verse:"“论西布伦说：西布伦哪，你出外可以欢喜。以萨迦啊，在你帐棚里可以快乐。"},
{volumn:         5,chapter:        33,versenumber:        19,verse:"他们要将列邦召到山上，在那里献公义的祭；因为他们要吸取海里的丰富，并沙中所藏的珍宝。"},
{volumn:         5,chapter:        33,versenumber:        20,verse:"“论迦得说：使迦得扩张的应当称颂！迦得住如母狮；他撕裂膀臂，连头顶也撕裂。"},
{volumn:         5,chapter:        33,versenumber:        21,verse:"他为自己选择头一段地，因在那里有设立律法者的份存留。他与百姓的首领同来；他施行耶和华的公义和耶和华与以色列所立的典章。"},
{volumn:         5,chapter:        33,versenumber:        22,verse:"“论但说：但为小狮子，从巴珊跳出来。"},
{volumn:         5,chapter:        33,versenumber:        23,verse:"“论拿弗他利说：拿弗他利啊，你足沾恩惠，满得耶和华的福，可以得西方和南方为业。"},
{volumn:         5,chapter:        33,versenumber:        24,verse:"“论亚设说：愿亚设享受多子的福乐，得他弟兄的喜悦，可以把脚蘸在油中。"},
{volumn:         5,chapter:        33,versenumber:        25,verse:"你的门闩（或译：鞋）是铜的，铁的。你的日子如何，你的力量也必如何。"},
{volumn:         5,chapter:        33,versenumber:        26,verse:"“耶书仑哪，没有能比　神的。他为帮助你，乘在天空，显其威荣，驾行穹苍。"},
{volumn:         5,chapter:        33,versenumber:        27,verse:"永生的　神是你的居所；他永久的膀臂在你以下。他在你前面撵出仇敌，说：‘毁灭吧！’"},
{volumn:         5,chapter:        33,versenumber:        28,verse:"以色列安然居住；雅各的本源独居五谷新酒之地。他的天也滴甘露。"},
{volumn:         5,chapter:        33,versenumber:        29,verse:"以色列啊，你是有福的！谁像你这蒙耶和华所拯救的百姓呢？他是你的盾牌，帮助你，是你威荣的刀剑。你的仇敌必投降你；你必踏在他们的高处。”"},
{volumn:         5,chapter:        34,versenumber:         1,verse:"摩西从摩押平原登尼波山，上了那与耶利哥相对的毗斯迦山顶。耶和华把基列全地直到但，"},
{volumn:         5,chapter:        34,versenumber:         2,verse:"拿弗他利全地，以法莲、玛拿西的地，犹大全地直到西海，"},
{volumn:         5,chapter:        34,versenumber:         3,verse:"南地和棕树城耶利哥的平原，直到琐珥，都指给他看。"},
{volumn:         5,chapter:        34,versenumber:         4,verse:"耶和华对他说：“这就是我向亚伯拉罕、以撒、雅各起誓应许之地，说：‘我必将这地赐给你的后裔。’现在我使你眼睛看见了，你却不得过到那里去。”"},
{volumn:         5,chapter:        34,versenumber:         5,verse:"于是，耶和华的仆人摩西死在摩押地，正如耶和华所说的。"},
{volumn:         5,chapter:        34,versenumber:         6,verse:"耶和华将他埋葬在摩押地、伯·毗珥对面的谷中，只是到今日没有人知道他的坟墓。"},
{volumn:         5,chapter:        34,versenumber:         7,verse:"摩西死的时候年一百二十岁；眼目没有昏花，精神没有衰败。"},
{volumn:         5,chapter:        34,versenumber:         8,verse:"以色列人在摩押平原为摩西哀哭了三十日，为摩西居丧哀哭的日子就满了。"},
{volumn:         5,chapter:        34,versenumber:         9,verse:"嫩的儿子约书亚；因为摩西曾按手在他头上，就被智慧的灵充满，以色列人便听从他，照着耶和华吩咐摩西的行了。"},
{volumn:         5,chapter:        34,versenumber:        10,verse:"以后以色列中再没有兴起先知像摩西的。他是耶和华面对面所认识的。"},
{volumn:         5,chapter:        34,versenumber:        11,verse:"耶和华打发他在埃及地向法老和他的一切臣仆，并他的全地，行各样神迹奇事，"},
{volumn:         5,chapter:        34,versenumber:        12,verse:"又在以色列众人眼前显大能的手，行一切大而可畏的事。"},
{volumn:         6,chapter:         1,versenumber:         1,verse:"耶和华的仆人摩西死了以后，耶和华晓谕摩西的帮手，嫩的儿子约书亚，说："},
{volumn:         6,chapter:         1,versenumber:         2,verse:"“我的仆人摩西死了。现在你要起来，和众百姓过这约旦河，往我所要赐给以色列人的地去。"},
{volumn:         6,chapter:         1,versenumber:         3,verse:"凡你们脚掌所踏之地，我都照着我所应许摩西的话赐给你们了。"},
{volumn:         6,chapter:         1,versenumber:         4,verse:"从旷野和这黎巴嫩，直到幼发拉底大河，赫人的全地，又到大海日落之处，都要作你们的境界。"},
{volumn:         6,chapter:         1,versenumber:         5,verse:"你平生的日子，必无一人能在你面前站立得住。我怎样与摩西同在，也必照样与你同在；我必不撇下你，也不丢弃你。"},
{volumn:         6,chapter:         1,versenumber:         6,verse:"你当刚强壮胆！因为你必使这百姓承受那地为业，就是我向他们列祖起誓应许赐给他们的地。"},
{volumn:         6,chapter:         1,versenumber:         7,verse:"只要刚强，大大壮胆，谨守遵行我仆人摩西所吩咐你的一切律法，不可偏离左右，使你无论往哪里去，都可以顺利。"},
{volumn:         6,chapter:         1,versenumber:         8,verse:"这律法书不可离开你的口，总要昼夜思想，好使你谨守遵行这书上所写的一切话。如此，你的道路就可以亨通，凡事顺利。"},
{volumn:         6,chapter:         1,versenumber:         9,verse:"我岂没有吩咐你吗？你当刚强壮胆！不要惧怕，也不要惊惶；因为你无论往哪里去，耶和华你的　神必与你同在。”"},
{volumn:         6,chapter:         1,versenumber:        10,verse:"于是，约书亚吩咐百姓的官长说："},
{volumn:         6,chapter:         1,versenumber:        11,verse:"“你们要走遍营中，吩咐百姓说：‘当预备食物；因为三日之内你们要过这约旦河，进去得耶和华你们　神赐你们为业之地。’”"},
{volumn:         6,chapter:         1,versenumber:        12,verse:"约书亚对流便人、迦得人，和玛拿西半支派的人说："},
{volumn:         6,chapter:         1,versenumber:        13,verse:"“你们要追念耶和华的仆人摩西所吩咐你们的话说：‘耶和华你们的　神使你们得享平安，也必将这地赐给你们。’"},
{volumn:         6,chapter:         1,versenumber:        14,verse:"你们的妻子、孩子，和牲畜都可以留在约旦河东、摩西所给你们的地；但你们中间一切大能的勇士都要带着兵器在你们的弟兄前面过去，帮助他们，"},
{volumn:         6,chapter:         1,versenumber:        15,verse:"等到耶和华使你们的弟兄像你们一样得享平安，并且得着耶和华你们　神所赐他们为业之地，那时才可以回你们所得之地，承受为业，就是耶和华的仆人摩西在约旦河东、向日出之地所给你们的。”"},
{volumn:         6,chapter:         1,versenumber:        16,verse:"他们回答约书亚说：“你所吩咐我们行的，我们都必行；你所差遣我们去的，我们都必去。"},
{volumn:         6,chapter:         1,versenumber:        17,verse:"我们从前在一切事上怎样听从摩西，现在也必照样听从你；惟愿耶和华你的　神与你同在，像与摩西同在一样。"},
{volumn:         6,chapter:         1,versenumber:        18,verse:"无论什么人违背你的命令，不听从你所吩咐他的一切话，就必治死他。你只要刚强壮胆！”"},
{volumn:         6,chapter:         2,versenumber:         1,verse:"当下，嫩的儿子约书亚从什亭暗暗打发两个人作探子，吩咐说：“你们去窥探那地和耶利哥。”于是二人去了，来到一个妓女名叫喇合的家里，就在那里躺卧。"},
{volumn:         6,chapter:         2,versenumber:         2,verse:"有人告诉耶利哥王说：“今夜有以色列人来到这里窥探此地。”"},
{volumn:         6,chapter:         2,versenumber:         3,verse:"耶利哥王打发人去见喇合说：“那来到你这里、进了你家的人要交出来，因为他们来窥探全地。”"},
{volumn:         6,chapter:         2,versenumber:         4,verse:"女人将二人隐藏，就回答说：“那人果然到我这里来；他们是哪里来的我却不知道。"},
{volumn:         6,chapter:         2,versenumber:         5,verse:"天黑、要关城门的时候，他们出去了，往哪里去我却不知道。你们快快地去追赶，就必追上。”（"},
{volumn:         6,chapter:         2,versenumber:         6,verse:"先是女人领二人上了房顶，将他们藏在那里所摆的麻秸中。）"},
{volumn:         6,chapter:         2,versenumber:         7,verse:"那些人就往约旦河的渡口追赶他们去了。追赶他们的人一出去，城门就关了。"},
{volumn:         6,chapter:         2,versenumber:         8,verse:"二人还没有躺卧，女人就上房顶，到他们那里，"},
{volumn:         6,chapter:         2,versenumber:         9,verse:"对他们说：“我知道耶和华已经把这地赐给你们，并且因你们的缘故我们都惊慌了。这地的一切居民在你们面前心都消化了；"},
{volumn:         6,chapter:         2,versenumber:        10,verse:"因为我们听见你们出埃及的时候，耶和华怎样在你们前面使红海的水干了，并且你们怎样待约旦河东的两个亚摩利王西宏和噩，将他们尽行毁灭。"},
{volumn:         6,chapter:         2,versenumber:        11,verse:"我们一听见这些事，心就消化了。因你们的缘故，并无一人有胆气。耶和华你们的　神本是上天下地的　神。"},
{volumn:         6,chapter:         2,versenumber:        12,verse:"现在我既是恩待你们，求你们指着耶和华向我起誓，也要恩待我父家，并给我一个实在的证据，"},
{volumn:         6,chapter:         2,versenumber:        13,verse:"要救活我的父母、弟兄、姐妹，和一切属他们的，拯救我们的性命不死。”"},
{volumn:         6,chapter:         2,versenumber:        14,verse:"二人对她说：“你若不泄漏我们这件事，我们情愿替你们死。耶和华将这地赐给我们的时候，我们必以慈爱诚实待你。”"},
{volumn:         6,chapter:         2,versenumber:        15,verse:"于是女人用绳子将二人从窗户里缒下去；因她的房子是在城墙边上，她也住在城墙上。"},
{volumn:         6,chapter:         2,versenumber:        16,verse:"她对他们说：“你们且往山上去，恐怕追赶的人碰见你们；要在那里隐藏三天，等追赶的人回来，然后才可以走你们的路。”"},
{volumn:         6,chapter:         2,versenumber:        17,verse:"二人对她说：“你要这样行。不然，你叫我们所起的誓就与我们无干了。"},
{volumn:         6,chapter:         2,versenumber:        18,verse:"我们来到这地的时候，你要把这条朱红线绳系在缒我们下去的窗户上，并要使你的父母、弟兄，和你父的全家都聚集在你家中。"},
{volumn:         6,chapter:         2,versenumber:        19,verse:"凡出了你家门往街上去的，他的罪（原文是血）必归到自己的头上，与我们无干了。凡在你家里的，若有人下手害他，流他血的罪就归到我们的头上。"},
{volumn:         6,chapter:         2,versenumber:        20,verse:"你若泄漏我们这件事，你叫我们所起的誓就与我们无干了。”"},
{volumn:         6,chapter:         2,versenumber:        21,verse:"女人说：“照你们的话行吧！”于是打发他们去了，又把朱红线绳系在窗户上。"},
{volumn:         6,chapter:         2,versenumber:        22,verse:"二人到山上，在那里住了三天，等着追赶的人回去了。追赶的人一路找他们，却找不着。"},
{volumn:         6,chapter:         2,versenumber:        23,verse:"二人就下山回来，过了河，到嫩的儿子约书亚那里，向他述说所遭遇的一切事；"},
{volumn:         6,chapter:         2,versenumber:        24,verse:"又对约书亚说：“耶和华果然将那全地交在我们手中；那地的一切居民在我们面前心都消化了。”"},
{volumn:         6,chapter:         3,versenumber:         1,verse:"约书亚清早起来，和以色列众人都离开什亭，来到约旦河，就住在那里，等候过河。"},
{volumn:         6,chapter:         3,versenumber:         2,verse:"过了三天，官长走遍营中，"},
{volumn:         6,chapter:         3,versenumber:         3,verse:"吩咐百姓说：“你们看见耶和华你们　神的约柜，又见祭司利未人抬着，就要离开所住的地方，跟着约柜去。"},
{volumn:         6,chapter:         3,versenumber:         4,verse:"只是你们和约柜相离要量二千肘，不可与约柜相近，使你们知道所当走的路，因为这条路你们向来没有走过。”"},
{volumn:         6,chapter:         3,versenumber:         5,verse:"约书亚吩咐百姓说：“你们要自洁，因为明天耶和华必在你们中间行奇事。”"},
{volumn:         6,chapter:         3,versenumber:         6,verse:"约书亚又吩咐祭司说：“你们抬起约柜，在百姓前头过去。”于是他们抬起约柜，在百姓前头走。"},
{volumn:         6,chapter:         3,versenumber:         7,verse:"耶和华对约书亚说：“从今日起，我必使你在以色列众人眼前尊大，使他们知道我怎样与摩西同在，也必照样与你同在。"},
{volumn:         6,chapter:         3,versenumber:         8,verse:"你要吩咐抬约柜的祭司说：‘你们到了约旦河的水边上，就要在约旦河水里站住。’”"},
{volumn:         6,chapter:         3,versenumber:         9,verse:"约书亚对以色列人说：“你们近前来，听耶和华你们　神的话。”"},
{volumn:         6,chapter:         3,versenumber:        10,verse:"约书亚说：“看哪，普天下主的约柜必在你们前头过去，到约旦河里，因此你们就知道在你们中间有永生　神；并且他必在你们面前赶出迦南人、赫人、希未人、比利洗人、革迦撒人、亚摩利人、耶布斯人。"},
{volumn:         6,chapter:         3,versenumber:        11,verse:""},
{volumn:         6,chapter:         3,versenumber:        12,verse:"你们现在要从以色列支派中拣选十二个人，每支派一人，"},
{volumn:         6,chapter:         3,versenumber:        13,verse:"等到抬普天下主耶和华约柜的祭司把脚站在约旦河水里，约旦河的水，就是从上往下流的水，必然断绝，立起成垒。”"},
{volumn:         6,chapter:         3,versenumber:        14,verse:"百姓离开帐棚要过约旦河的时候，抬约柜的祭司乃在百姓的前头。"},
{volumn:         6,chapter:         3,versenumber:        15,verse:"他们到了约旦河，脚一入水（原来约旦河水在收割的日子涨过两岸），"},
{volumn:         6,chapter:         3,versenumber:        16,verse:"那从上往下流的水便在极远之地、撒拉但旁的亚当城那里停住，立起成垒；那往亚拉巴的海，就是盐海，下流的水全然断绝。于是百姓在耶利哥的对面过去了。"},
{volumn:         6,chapter:         3,versenumber:        17,verse:"抬耶和华约柜的祭司在约旦河中的干地上站定，以色列众人都从干地上过去，直到国民尽都过了约旦河。"},
{volumn:         6,chapter:         4,versenumber:         1,verse:"国民尽都过了约旦河，耶和华就对约书亚说："},
{volumn:         6,chapter:         4,versenumber:         2,verse:"“你从民中要拣选十二个人，每支派一人，"},
{volumn:         6,chapter:         4,versenumber:         3,verse:"吩咐他们说：‘你们从这里，从约旦河中、祭司脚站定的地方，取十二块石头带过去，放在你们今夜要住宿的地方。’”"},
{volumn:         6,chapter:         4,versenumber:         4,verse:"于是，约书亚将他从以色列人中所预备的那十二个人，每支派一人，都召了来。"},
{volumn:         6,chapter:         4,versenumber:         5,verse:"对他们说：“你们下约旦河中，过到耶和华你们　神的约柜前头，按着以色列人十二支派的数目，每人取一块石头扛在肩上。"},
{volumn:         6,chapter:         4,versenumber:         6,verse:"这些石头在你们中间可以作为证据。日后，你们的子孙问你们说：‘这些石头是什么意思？’"},
{volumn:         6,chapter:         4,versenumber:         7,verse:"你们就对他们说：‘这是因为约旦河的水在耶和华的约柜前断绝；约柜过约旦河的时候，约旦河的水就断绝了。这些石头要作以色列人永远的纪念。’”"},
{volumn:         6,chapter:         4,versenumber:         8,verse:"以色列人就照约书亚所吩咐的，按着以色列人支派的数目，从约旦河中取了十二块石头，都遵耶和华所吩咐约书亚的行了。他们把石头带过去，到他们所住宿的地方，就放在那里。"},
{volumn:         6,chapter:         4,versenumber:         9,verse:"约书亚另把十二块石头立在约旦河中，在抬约柜的祭司脚站立的地方；直到今日，那石头还在那里。"},
{volumn:         6,chapter:         4,versenumber:        10,verse:"抬约柜的祭司站在约旦河中，等到耶和华晓谕约书亚吩咐百姓的事办完了，是照摩西所吩咐约书亚的一切话。于是百姓急速过去了。"},
{volumn:         6,chapter:         4,versenumber:        11,verse:"众百姓尽都过了河，耶和华的约柜和祭司就在百姓面前过去。"},
{volumn:         6,chapter:         4,versenumber:        12,verse:"流便人、迦得人、玛拿西半支派的人都照摩西所吩咐他们的，带着兵器在以色列人前头过去。"},
{volumn:         6,chapter:         4,versenumber:        13,verse:"约有四万人都准备打仗，在耶和华面前过去，到耶利哥的平原，等候上阵。"},
{volumn:         6,chapter:         4,versenumber:        14,verse:"当那日，耶和华使约书亚在以色列众人眼前尊大。在他平生的日子，百姓敬畏他，像从前敬畏摩西一样。"},
{volumn:         6,chapter:         4,versenumber:        15,verse:"耶和华晓谕约书亚说："},
{volumn:         6,chapter:         4,versenumber:        16,verse:"“你吩咐抬法柜的祭司从约旦河里上来。”"},
{volumn:         6,chapter:         4,versenumber:        17,verse:"约书亚就吩咐祭司说：“你们从约旦河里上来。”"},
{volumn:         6,chapter:         4,versenumber:        18,verse:"抬耶和华约柜的祭司从约旦河里上来，脚掌刚落旱地，约旦河的水就流到原处，仍旧涨过两岸。"},
{volumn:         6,chapter:         4,versenumber:        19,verse:"正月初十日，百姓从约旦河里上来，就在吉甲，在耶利哥的东边安营。"},
{volumn:         6,chapter:         4,versenumber:        20,verse:"他们从约旦河中取来的那十二块石头，约书亚就立在吉甲，"},
{volumn:         6,chapter:         4,versenumber:        21,verse:"对以色列人说：“日后你们的子孙问他们的父亲说：‘这些石头是什么意思？’"},
{volumn:         6,chapter:         4,versenumber:        22,verse:"你们就告诉他们说：‘以色列人曾走干地过这约旦河；"},
{volumn:         6,chapter:         4,versenumber:        23,verse:"因为耶和华你们的　神在你们前面使约旦河的水干了，等着你们过来，就如耶和华你们的　神从前在我们前面使红海干了，等着我们过来一样，"},
{volumn:         6,chapter:         4,versenumber:        24,verse:"要使地上万民都知道，耶和华的手大有能力，也要使你们永远敬畏耶和华你们的　神。’”"},
{volumn:         6,chapter:         5,versenumber:         1,verse:"约旦河西亚摩利人的诸王和靠海迦南人的诸王，听见耶和华在以色列人前面使约旦河的水干了，等到我们过去，他们的心因以色列人的缘故就消化了，不再有胆气。"},
{volumn:         6,chapter:         5,versenumber:         2,verse:"那时，耶和华吩咐约书亚说：“你制造火石刀，第二次给以色列人行割礼。”"},
{volumn:         6,chapter:         5,versenumber:         3,verse:"约书亚就制造了火石刀，在“除皮山”那里给以色列人行割礼。"},
{volumn:         6,chapter:         5,versenumber:         4,verse:"约书亚行割礼的缘故，是因为从埃及出来的众民，就是一切能打仗的男丁，出了埃及以后，都死在旷野的路上。"},
{volumn:         6,chapter:         5,versenumber:         5,verse:"因为出来的众民都受过割礼；惟独出埃及以后、在旷野的路上所生的众民都没有受过割礼。"},
{volumn:         6,chapter:         5,versenumber:         6,verse:"以色列人在旷野走了四十年，等到国民，就是出埃及的兵丁，都消灭了，因为他们没有听从耶和华的话。耶和华曾向他们起誓，必不容他们看见耶和华向他们列祖起誓、应许赐给我们的地，就是流奶与蜜之地。"},
{volumn:         6,chapter:         5,versenumber:         7,verse:"他们的子孙，就是耶和华所兴起来接续他们的，都没有受过割礼；因为在路上没有给他们行割礼，约书亚这才给他们行了。"},
{volumn:         6,chapter:         5,versenumber:         8,verse:"国民都受完了割礼，就住在营中自己的地方，等到痊愈了。"},
{volumn:         6,chapter:         5,versenumber:         9,verse:"耶和华对约书亚说：“我今日将埃及的羞辱从你们身上滚去了。”因此，那地方名叫吉甲（就是滚的意思），直到今日。"},
{volumn:         6,chapter:         5,versenumber:        10,verse:"以色列人在吉甲安营。正月十四日晚上，在耶利哥的平原守逾越节。"},
{volumn:         6,chapter:         5,versenumber:        11,verse:"逾越节的次日，他们就吃了那地的出产；正当那日吃无酵饼和烘的谷。"},
{volumn:         6,chapter:         5,versenumber:        12,verse:"他们吃了那地的出产，第二日吗哪就止住了，以色列人也不再有吗哪了。那一年，他们却吃迦南地的出产。"},
{volumn:         6,chapter:         5,versenumber:        13,verse:"约书亚靠近耶利哥的时候，举目观看，不料，有一个人手里有拔出来的刀，对面站立。约书亚到他那里，问他说：“你是帮助我们呢，是帮助我们敌人呢？”"},
{volumn:         6,chapter:         5,versenumber:        14,verse:"他回答说：“不是的，我来是要作耶和华军队的元帅。”约书亚就俯伏在地下拜，说：“我主有什么话吩咐仆人。”"},
{volumn:         6,chapter:         5,versenumber:        15,verse:"耶和华军队的元帅对约书亚说：“把你脚上的鞋脱下来，因为你所站的地方是圣的。”约书亚就照着行了。"},
{volumn:         6,chapter:         6,versenumber:         1,verse:"耶利哥的城门因以色列人就关得严紧，无人出入。"},
{volumn:         6,chapter:         6,versenumber:         2,verse:"耶和华晓谕约书亚说：“看哪，我已经把耶利哥和耶利哥的王，并大能的勇士，都交在你手中。"},
{volumn:         6,chapter:         6,versenumber:         3,verse:"你们的一切兵丁要围绕这城，一日围绕一次，六日都要这样行。"},
{volumn:         6,chapter:         6,versenumber:         4,verse:"七个祭司要拿七个羊角走在约柜前。到第七日，你们要绕城七次，祭司也要吹角。"},
{volumn:         6,chapter:         6,versenumber:         5,verse:"他们吹的角声拖长，你们听见角声，众百姓要大声呼喊，城墙就必塌陷，各人都要往前直上。”"},
{volumn:         6,chapter:         6,versenumber:         6,verse:"嫩的儿子约书亚召了祭司来，吩咐他们说：“你们抬起约柜来，要有七个祭司拿七个羊角走在耶和华的约柜前”；"},
{volumn:         6,chapter:         6,versenumber:         7,verse:"又对百姓说：“你们前去绕城，带兵器的要走在耶和华的约柜前。”"},
{volumn:         6,chapter:         6,versenumber:         8,verse:"约书亚对百姓说完了话，七个祭司拿七个羊角走在耶和华面前吹角；耶和华的约柜在他们后面跟随。"},
{volumn:         6,chapter:         6,versenumber:         9,verse:"带兵器的走在吹角的祭司前面，后队随着约柜行。祭司一面走一面吹。"},
{volumn:         6,chapter:         6,versenumber:        10,verse:"约书亚吩咐百姓说：“你们不可呼喊，不可出声，连一句话也不可出你们的口，等到我吩咐你们呼喊的日子，那时才可以呼喊。”"},
{volumn:         6,chapter:         6,versenumber:        11,verse:"这样，他使耶和华的约柜绕城，把城绕了一次；众人回到营里，就在营里住宿。"},
{volumn:         6,chapter:         6,versenumber:        12,verse:"约书亚清早起来，祭司又抬起耶和华的约柜。"},
{volumn:         6,chapter:         6,versenumber:        13,verse:"七个祭司拿七个羊角在耶和华的约柜前，时常行走吹角；带兵器的在他们前面走，后队随着耶和华的约柜行。祭司一面走一面吹。"},
{volumn:         6,chapter:         6,versenumber:        14,verse:"第二日，众人把城绕了一次，就回营里去。六日都是这样行。"},
{volumn:         6,chapter:         6,versenumber:        15,verse:"第七日清早，黎明的时候，他们起来，照样绕城七次；惟独这日把城绕了七次。"},
{volumn:         6,chapter:         6,versenumber:        16,verse:"到了第七次，祭司吹角的时候，约书亚吩咐百姓说：“呼喊吧，因为耶和华已经把城交给你们了！"},
{volumn:         6,chapter:         6,versenumber:        17,verse:"这城和其中所有的都要在耶和华面前毁灭；只有妓女喇合与她家中所有的可以存活，因为她隐藏了我们所打发的使者。"},
{volumn:         6,chapter:         6,versenumber:        18,verse:"至于你们，务要谨慎，不可取那当灭的物，恐怕你们取了那当灭的物就连累以色列的全营，使全营受咒诅。"},
{volumn:         6,chapter:         6,versenumber:        19,verse:"惟有金子、银子，和铜铁的器皿都要归耶和华为圣，必入耶和华的库中。”"},
{volumn:         6,chapter:         6,versenumber:        20,verse:"于是百姓呼喊，祭司也吹角。百姓听见角声，便大声呼喊，城墙就塌陷，百姓便上去进城，各人往前直上，将城夺取；"},
{volumn:         6,chapter:         6,versenumber:        21,verse:"又将城中所有的，不拘男女老少，牛羊和驴，都用刀杀尽。"},
{volumn:         6,chapter:         6,versenumber:        22,verse:"约书亚吩咐窥探地的两个人说：“你们进那妓女的家，照着你们向她所起的誓，将那女人和她所有的都从那里带出来。”"},
{volumn:         6,chapter:         6,versenumber:        23,verse:"当探子的两个少年人就进去，将喇合与她的父母、弟兄，和她所有的，并她一切的亲眷，都带出来，安置在以色列的营外。"},
{volumn:         6,chapter:         6,versenumber:        24,verse:"众人就用火将城和其中所有的焚烧了；惟有金子、银子，和铜铁的器皿都放在耶和华殿的库中。"},
{volumn:         6,chapter:         6,versenumber:        25,verse:"约书亚却把妓女喇合与她父家，并她所有的，都救活了；因为她隐藏了约书亚所打发窥探耶利哥的使者，她就住在以色列中，直到今日。"},
{volumn:         6,chapter:         6,versenumber:        26,verse:"当时，约书亚叫众人起誓说：“有兴起重修这耶利哥城的人，当在耶和华面前受咒诅。他立根基的时候，必丧长子，安门的时候，必丧幼子。”"},
{volumn:         6,chapter:         6,versenumber:        27,verse:"耶和华与约书亚同在，约书亚的声名传扬遍地。"},
{volumn:         6,chapter:         7,versenumber:         1,verse:"以色列人在当灭的物上犯了罪；因为犹大支派中，谢拉的曾孙，撒底的孙子，迦米的儿子亚干取了当灭的物；耶和华的怒气就向以色列人发作。"},
{volumn:         6,chapter:         7,versenumber:         2,verse:"当下，约书亚从耶利哥打发人往伯特利东边、靠近伯·亚文的艾城去，吩咐他们说：“你们上去窥探那地。”他们就上去窥探艾城。"},
{volumn:         6,chapter:         7,versenumber:         3,verse:"他们回到约书亚那里，对他说：“众民不必都上去，只要二三千人上去就能攻取艾城；不必劳累众民都去，因为那里的人少。”"},
{volumn:         6,chapter:         7,versenumber:         4,verse:"于是民中约有三千人上那里去，竟在艾城人面前逃跑了。"},
{volumn:         6,chapter:         7,versenumber:         5,verse:"艾城的人击杀了他们三十六人，从城门前追赶他们，直到示巴琳，在下坡杀败他们；众民的心就消化如水。"},
{volumn:         6,chapter:         7,versenumber:         6,verse:"约书亚便撕裂衣服；他和以色列的长老把灰撒在头上，在耶和华的约柜前俯伏在地，直到晚上。"},
{volumn:         6,chapter:         7,versenumber:         7,verse:"约书亚说：“哀哉！主耶和华啊，你为什么竟领这百姓过约旦河，将我们交在亚摩利人的手中，使我们灭亡呢？我们不如住在约旦河那边倒好。"},
{volumn:         6,chapter:         7,versenumber:         8,verse:"主啊，以色列人既在仇敌面前转背逃跑，我还有什么可说的呢？"},
{volumn:         6,chapter:         7,versenumber:         9,verse:"迦南人和这地一切的居民听见了就必围困我们，将我们的名从地上除灭。那时你为你的大名要怎样行呢？”"},
{volumn:         6,chapter:         7,versenumber:        10,verse:"耶和华吩咐约书亚说：“起来！你为何这样俯伏在地呢？"},
{volumn:         6,chapter:         7,versenumber:        11,verse:"以色列人犯了罪，违背了我所吩咐他们的约，取了当灭的物；又偷窃，又行诡诈，又把那当灭的放在他们的家具里。"},
{volumn:         6,chapter:         7,versenumber:        12,verse:"因此，以色列人在仇敌面前站立不住。他们在仇敌面前转背逃跑，是因成了被咒诅的；你们若不把当灭的物从你们中间除掉，我就不再与你们同在了。"},
{volumn:         6,chapter:         7,versenumber:        13,verse:"你起来，叫百姓自洁，对他们说：‘你们要自洁，预备明天，因为耶和华以色列的　神这样说：以色列啊，你们中间有当灭的物，你们若不除掉，在仇敌面前必站立不住！’"},
{volumn:         6,chapter:         7,versenumber:        14,verse:"到了早晨，你们要按着支派近前来；耶和华所取的支派，要按着宗族近前来；耶和华所取的宗族，要按着家室近前来；耶和华所取的家室，要按着人丁，一个一个地近前来。"},
{volumn:         6,chapter:         7,versenumber:        15,verse:"被取的人有当灭的物在他那里，他和他所有的必被火焚烧；因他违背了耶和华的约，又因他在以色列中行了愚妄的事。”"},
{volumn:         6,chapter:         7,versenumber:        16,verse:"于是，约书亚清早起来，使以色列人按着支派近前来，取出来的是犹大支派；"},
{volumn:         6,chapter:         7,versenumber:        17,verse:"使犹大支派（原文是宗族）近前来，就取了谢拉的宗族；使谢拉的宗族，按着家室人丁，一个一个地近前来，取出来的是撒底；"},
{volumn:         6,chapter:         7,versenumber:        18,verse:"使撒底的家室，按着人丁，一个一个地近前来，就取出犹大支派的人谢拉的曾孙，撒底的孙子，迦米的儿子亚干。"},
{volumn:         6,chapter:         7,versenumber:        19,verse:"约书亚对亚干说：“我儿，我劝你将荣耀归给耶和华以色列的　神，在他面前认罪，将你所做的事告诉我，不要向我隐瞒。”"},
{volumn:         6,chapter:         7,versenumber:        20,verse:"亚干回答约书亚说：“我实在得罪了耶和华以色列的　神。我所做的事如此如此："},
{volumn:         6,chapter:         7,versenumber:        21,verse:"我在所夺的财物中看见一件美好的示拿衣服，二百舍客勒银子，一条金子重五十舍客勒，我就贪爱这些物件，便拿去了。现今藏在我帐棚内的地里，银子在衣服底下。”"},
{volumn:         6,chapter:         7,versenumber:        22,verse:"约书亚就打发人跑到亚干的帐棚里。那件衣服果然藏在他帐棚内，银子在底下。"},
{volumn:         6,chapter:         7,versenumber:        23,verse:"他们就从帐棚里取出来，拿到约书亚和以色列众人那里，放在耶和华面前。"},
{volumn:         6,chapter:         7,versenumber:        24,verse:"约书亚和以色列众人把谢拉的曾孙亚干和那银子、那件衣服、那条金子，并亚干的儿女、牛、驴、羊、帐棚，以及他所有的，都带到亚割谷去。"},
{volumn:         6,chapter:         7,versenumber:        25,verse:"约书亚说：“你为什么连累我们呢？今日耶和华必叫你受连累。”于是以色列众人用石头打死他，将石头扔在其上，又用火焚烧他所有的（原文是他们）。"},
{volumn:         6,chapter:         7,versenumber:        26,verse:"众人在亚干身上堆成一大堆石头，直存到今日。于是耶和华转意，不发他的烈怒。因此那地方名叫亚割谷（就是连累的意思），直到今日。"},
{volumn:         6,chapter:         8,versenumber:         1,verse:"耶和华对约书亚说：“不要惧怕，也不要惊惶。你起来，率领一切兵丁上艾城去，我已经把艾城的王和他的民、他的城，并他的地，都交在你手里。"},
{volumn:         6,chapter:         8,versenumber:         2,verse:"你怎样待耶利哥和耶利哥的王，也当照样待艾城和艾城的王。只是城内所夺的财物和牲畜，你们可以取为自己的掠物。你要在城后设下伏兵。”"},
{volumn:         6,chapter:         8,versenumber:         3,verse:"于是，约书亚和一切兵丁都起来，要上艾城去。约书亚选了三万大能的勇士，夜间打发他们前往，"},
{volumn:         6,chapter:         8,versenumber:         4,verse:"吩咐他们说：“你们要在城后埋伏，不可离城太远，都要各自准备。"},
{volumn:         6,chapter:         8,versenumber:         5,verse:"我与我所带领的众民要向城前往。城里的人像初次出来攻击我们的时候，我们就在他们面前逃跑，"},
{volumn:         6,chapter:         8,versenumber:         6,verse:"他们必出来追赶我们，直到我们引诱他们离开城；因为他们必说：‘这些人像初次在我们面前逃跑。’所以我们要在他们面前逃跑，"},
{volumn:         6,chapter:         8,versenumber:         7,verse:"你们就从埋伏的地方起来，夺取那城，因为耶和华你们的　神必把城交在你们手里。"},
{volumn:         6,chapter:         8,versenumber:         8,verse:"你们夺了城以后，就放火烧城，要照耶和华的话行。这是我吩咐你们的。”"},
{volumn:         6,chapter:         8,versenumber:         9,verse:"约书亚打发他们前往，他们就上埋伏的地方去，住在伯特利和艾城的中间，就是在艾城的西边。这夜约书亚却在民中住宿。"},
{volumn:         6,chapter:         8,versenumber:        10,verse:"约书亚清早起来，点齐百姓，他和以色列的长老在百姓前面上艾城去。"},
{volumn:         6,chapter:         8,versenumber:        11,verse:"众民，就是他所带领的兵丁，都上去，向前直往，来到城前，在艾城北边安营。在约书亚和艾城中间有一山谷。"},
{volumn:         6,chapter:         8,versenumber:        12,verse:"他挑了约有五千人，使他们埋伏在伯特利和艾城的中间，就是在艾城的西边，"},
{volumn:         6,chapter:         8,versenumber:        13,verse:"于是安置了百姓，就是城北的全军和城西的伏兵。这夜约书亚进入山谷之中。"},
{volumn:         6,chapter:         8,versenumber:        14,verse:"艾城的王看见这景况，就和全城的人，清早急忙起来，按所定的时候，出到亚拉巴前，要与以色列人交战；王却不知道在城后有伏兵。"},
{volumn:         6,chapter:         8,versenumber:        15,verse:"约书亚和以色列众人在他们面前装败，往那通旷野的路逃跑。"},
{volumn:         6,chapter:         8,versenumber:        16,verse:"城内的众民都被招聚，追赶他们；艾城人追赶的时候，就被引诱离开城。"},
{volumn:         6,chapter:         8,versenumber:        17,verse:"艾城和伯特利城没有一人不出来追赶以色列人的，撇了敞开的城门，去追赶以色列人。"},
{volumn:         6,chapter:         8,versenumber:        18,verse:"耶和华吩咐约书亚说：“你向艾城伸出手里的短枪，因为我要将城交在你手里。”约书亚就向城伸出手里的短枪。"},
{volumn:         6,chapter:         8,versenumber:        19,verse:"他一伸手，伏兵就从埋伏的地方急忙起来，夺了城，跑进城去，放火焚烧。"},
{volumn:         6,chapter:         8,versenumber:        20,verse:"艾城的人回头一看，不料，城中烟气冲天，他们就无力向左向右逃跑。那往旷野逃跑的百姓便转身攻击追赶他们的人。"},
{volumn:         6,chapter:         8,versenumber:        21,verse:"约书亚和以色列众人见伏兵已经夺了城，城中烟气飞腾，就转身回去，击杀艾城的人。"},
{volumn:         6,chapter:         8,versenumber:        22,verse:"伏兵也出城迎击艾城人，艾城人就困在以色列人中间，前后都是以色列人。于是以色列人击杀他们，没有留下一个，也没有一个逃脱的，"},
{volumn:         6,chapter:         8,versenumber:        23,verse:"生擒了艾城的王，将他解到约书亚那里。"},
{volumn:         6,chapter:         8,versenumber:        24,verse:"以色列人在田间和旷野杀尽所追赶一切艾城的居民。艾城人倒在刀下，直到灭尽；以色列众人就回到艾城，用刀杀了城中的人。"},
{volumn:         6,chapter:         8,versenumber:        25,verse:"当日杀毙的人，连男带女共有一万二千，就是艾城所有的人。"},
{volumn:         6,chapter:         8,versenumber:        26,verse:"约书亚没有收回手里所伸出来的短枪，直到把艾城的一切居民尽行杀灭。"},
{volumn:         6,chapter:         8,versenumber:        27,verse:"惟独城中的牲畜和财物，以色列人都取为自己的掠物，是照耶和华所吩咐约书亚的话。"},
{volumn:         6,chapter:         8,versenumber:        28,verse:"约书亚将艾城焚烧，使城永为高堆、荒场，直到今日；"},
{volumn:         6,chapter:         8,versenumber:        29,verse:"又将艾城王挂在树上，直到晚上。日落的时候，约书亚吩咐人把尸首从树上取下来，丢在城门口，在尸首上堆成一大堆石头，直存到今日。"},
{volumn:         6,chapter:         8,versenumber:        30,verse:"那时，约书亚在以巴路山上为耶和华以色列的　神筑一座坛，"},
{volumn:         6,chapter:         8,versenumber:        31,verse:"是用没有动过铁器的整石头筑的，照着耶和华仆人摩西所吩咐以色列人的话，正如摩西律法书上所写的。众人在这坛上给耶和华奉献燔祭和平安祭。"},
{volumn:         6,chapter:         8,versenumber:        32,verse:"约书亚在那里，当着以色列人面前，将摩西所写的律法抄写在石头上。"},
{volumn:         6,chapter:         8,versenumber:        33,verse:"以色列众人，无论是本地人、是寄居的，和长老、官长，并审判官，都站在约柜两旁，在抬耶和华约柜的祭司利未人面前，一半对着基利心山，一半对着以巴路山，为以色列民祝福，正如耶和华仆人摩西先前所吩咐的。"},
{volumn:         6,chapter:         8,versenumber:        34,verse:"随后，约书亚将律法上祝福、咒诅的话，照着律法书上一切所写的，都宣读了一遍。"},
{volumn:         6,chapter:         8,versenumber:        35,verse:"摩西所吩咐的一切话，约书亚在以色列全会众和妇女、孩子，并他们中间寄居的外人面前，没有一句不宣读的。"},
{volumn:         6,chapter:         9,versenumber:         1,verse:"约旦河西，住山地、高原，并对着黎巴嫩山沿大海一带的诸王，就是赫人、亚摩利人、迦南人、比利洗人、希未人、耶布斯人的诸王，听见这事，"},
{volumn:         6,chapter:         9,versenumber:         2,verse:"就都聚集，同心合意地要与约书亚和以色列人争战。"},
{volumn:         6,chapter:         9,versenumber:         3,verse:"基遍的居民听见约书亚向耶利哥和艾城所行的事，"},
{volumn:         6,chapter:         9,versenumber:         4,verse:"就设诡计，假充使者，拿旧口袋和破裂缝补的旧皮酒袋驮在驴上，"},
{volumn:         6,chapter:         9,versenumber:         5,verse:"将补过的旧鞋穿在脚上，把旧衣服穿在身上；他们所带的饼都是干的，长了霉了。"},
{volumn:         6,chapter:         9,versenumber:         6,verse:"他们到吉甲营中见约书亚，对他和以色列人说：“我们是从远方来的，现在求你与我们立约。”"},
{volumn:         6,chapter:         9,versenumber:         7,verse:"以色列人对这些希未人说：“只怕你们是住在我们中间的；若是这样，怎能和你们立约呢？”"},
{volumn:         6,chapter:         9,versenumber:         8,verse:"他们对约书亚说：“我们是你的仆人。”约书亚问他们说：“你们是什么人？是从哪里来的？”"},
{volumn:         6,chapter:         9,versenumber:         9,verse:"他们回答说：“仆人从极远之地而来，是因听见耶和华你　神的名声和他在埃及所行的一切事，"},
{volumn:         6,chapter:         9,versenumber:        10,verse:"并他向约旦河东的两个亚摩利王，就是希实本王西宏和在亚斯他录的巴珊王噩一切所行的事。"},
{volumn:         6,chapter:         9,versenumber:        11,verse:"我们的长老和我们那地的一切居民对我们说：‘你们手里要带着路上用的食物去迎接以色列人，对他们说：我们是你们的仆人；现在求你们与我们立约。’"},
{volumn:         6,chapter:         9,versenumber:        12,verse:"我们出来要往你们这里来的日子，从家里带出来的这饼还是热的；看哪，现在都干了，长了霉了。"},
{volumn:         6,chapter:         9,versenumber:        13,verse:"这皮酒袋，我们盛酒的时候还是新的；看哪，现在已经破裂。我们这衣服和鞋，因为道路甚远，也都穿旧了。”"},
{volumn:         6,chapter:         9,versenumber:        14,verse:"以色列人受了他们些食物，并没有求问耶和华。"},
{volumn:         6,chapter:         9,versenumber:        15,verse:"于是约书亚与他们讲和，与他们立约，容他们活着；会众的首领也向他们起誓。"},
{volumn:         6,chapter:         9,versenumber:        16,verse:"以色列人与他们立约之后，过了三天才听见他们是近邻，住在以色列人中间的。"},
{volumn:         6,chapter:         9,versenumber:        17,verse:"以色列人起行，第三天到了他们的城邑，就是基遍、基非拉、比录、基列·耶琳。"},
{volumn:         6,chapter:         9,versenumber:        18,verse:"因为会众的首领已经指着耶和华以色列的　神向他们起誓，所以以色列人不击杀他们；全会众就向首领发怨言。"},
{volumn:         6,chapter:         9,versenumber:        19,verse:"众首领对全会众说：“我们已经指着耶和华以色列的　神向他们起誓，现在我们不能害他们。"},
{volumn:         6,chapter:         9,versenumber:        20,verse:"我们要如此待他们，容他们活着，免得有忿怒因我们所起的誓临到我们身上。”"},
{volumn:         6,chapter:         9,versenumber:        21,verse:"首领又对会众说：“要容他们活着。”于是他们为全会众作了劈柴挑水的人，正如首领对他们所说的话。"},
{volumn:         6,chapter:         9,versenumber:        22,verse:"约书亚召了他们来，对他们说：“为什么欺哄我们说‘我们离你们甚远’呢？其实你们是住在我们中间。"},
{volumn:         6,chapter:         9,versenumber:        23,verse:"现在你们是被咒诅的！你们中间的人必断不了作奴仆，为我　神的殿作劈柴挑水的人。”"},
{volumn:         6,chapter:         9,versenumber:        24,verse:"他们回答约书亚说：“因为有人实在告诉你的仆人，耶和华你的　神曾吩咐他的仆人摩西，把这全地赐给你们，并在你们面前灭绝这地的一切居民，所以我们为你们的缘故甚怕丧命，就行了这事。"},
{volumn:         6,chapter:         9,versenumber:        25,verse:"现在我们在你手中，你以怎样待我们为善为正，就怎样做吧！”"},
{volumn:         6,chapter:         9,versenumber:        26,verse:"于是约书亚这样待他们，救他们脱离以色列人的手，以色列人就没有杀他们。"},
{volumn:         6,chapter:         9,versenumber:        27,verse:"当日约书亚使他们在耶和华所要选择的地方，为会众和耶和华的坛作劈柴挑水的人，直到今日。"},
{volumn:         6,chapter:        10,versenumber:         1,verse:"耶路撒冷王亚多尼·洗德听见约书亚夺了艾城，尽行毁灭，怎样待耶利哥和耶利哥的王，也照样待艾城和艾城的王，又听见基遍的居民与以色列人立了和约，住在他们中间，"},
{volumn:         6,chapter:        10,versenumber:         2,verse:"就甚惧怕；因为基遍是一座大城，如都城一般，比艾城更大，并且城内的人都是勇士。"},
{volumn:         6,chapter:        10,versenumber:         3,verse:"所以耶路撒冷王亚多尼·洗德打发人去见希伯仑王何咸、耶末王毗兰、拉吉王雅非亚，和伊矶伦王底璧，说："},
{volumn:         6,chapter:        10,versenumber:         4,verse:"“求你们上来帮助我，我们好攻打基遍，因为他们与约书亚和以色列人立了和约。”"},
{volumn:         6,chapter:        10,versenumber:         5,verse:"于是五个亚摩利王，就是耶路撒冷王、希伯仑王、耶末王、拉吉王、伊矶伦王，大家聚集，率领他们的众军上去，对着基遍安营，攻打基遍。"},
{volumn:         6,chapter:        10,versenumber:         6,verse:"基遍人就打发人往吉甲的营中去见约书亚，说：“你不要袖手不顾你的仆人，求你速速上来拯救我们，帮助我们，因为住山地亚摩利人的诸王都聚集攻击我们。”"},
{volumn:         6,chapter:        10,versenumber:         7,verse:"于是约书亚和他一切兵丁，并大能的勇士，都从吉甲上去。"},
{volumn:         6,chapter:        10,versenumber:         8,verse:"耶和华对约书亚说：“不要怕他们；因为我已将他们交在你手里，他们无一人能在你面前站立得住。”"},
{volumn:         6,chapter:        10,versenumber:         9,verse:"约书亚就终夜从吉甲上去，猛然临到他们那里。"},
{volumn:         6,chapter:        10,versenumber:        10,verse:"耶和华使他们在以色列人面前溃乱。约书亚在基遍大大地杀败他们，追赶他们，在伯·和仑的上坡路击杀他们，直到亚西加和玛基大。"},
{volumn:         6,chapter:        10,versenumber:        11,verse:"他们在以色列人面前逃跑，正在伯·和仑下坡的时候，耶和华从天上降大冰雹（原文是石头）在他们身上，直降到亚西加，打死他们。被冰雹打死的，比以色列人用刀杀死的还多。"},
{volumn:         6,chapter:        10,versenumber:        12,verse:"当耶和华将亚摩利人交付以色列人的日子，约书亚就祷告耶和华，在以色列人眼前说：“日头啊，你要停在基遍；月亮啊，你要止在亚雅仑谷。”"},
{volumn:         6,chapter:        10,versenumber:        13,verse:"于是日头停留，月亮止住，直等国民向敌人报仇。这事岂不是写在雅煞珥书上吗？日头在天当中停住，不急速下落，约有一日之久。"},
{volumn:         6,chapter:        10,versenumber:        14,verse:"在这日以前，这日以后，耶和华听人的祷告，没有像这日的，是因耶和华为以色列争战。"},
{volumn:         6,chapter:        10,versenumber:        15,verse:"约书亚和以色列众人回到吉甲的营中。"},
{volumn:         6,chapter:        10,versenumber:        16,verse:"那五王逃跑，藏在玛基大洞里。"},
{volumn:         6,chapter:        10,versenumber:        17,verse:"有人告诉约书亚说：“那五王已经找到了，都藏在玛基大洞里。”"},
{volumn:         6,chapter:        10,versenumber:        18,verse:"约书亚说：“你们把几块大石头滚到洞口，派人看守，"},
{volumn:         6,chapter:        10,versenumber:        19,verse:"你们却不可耽延，要追赶你们的仇敌，击杀他们尽后边的人，不容他们进自己的城邑，因为耶和华你们的　神已经把他们交在你们手里。”"},
{volumn:         6,chapter:        10,versenumber:        20,verse:"约书亚和以色列人大大杀败他们，直到将他们灭尽；其中剩下的人都进了坚固的城。"},
{volumn:         6,chapter:        10,versenumber:        21,verse:"众百姓就安然回玛基大营中，到约书亚那里。没有一人敢向以色列人饶舌。"},
{volumn:         6,chapter:        10,versenumber:        22,verse:"约书亚说：“打开洞口，将那五王从洞里带出来，领到我面前。”"},
{volumn:         6,chapter:        10,versenumber:        23,verse:"众人就这样行，将那五王，就是耶路撒冷王、希伯仑王、耶末王、拉吉王、伊矶伦王，从洞里带出来，领到约书亚面前。"},
{volumn:         6,chapter:        10,versenumber:        24,verse:"带出那五王到约书亚面前的时候，约书亚就召了以色列众人来，对那些和他同去的军长说：“你们近前来，把脚踏在这些王的颈项上。”他们就近前来，把脚踏在这些王的颈项上。"},
{volumn:         6,chapter:        10,versenumber:        25,verse:"约书亚对他们说：“你们不要惧怕，也不要惊惶。应当刚强壮胆，因为耶和华必这样待你们所要攻打的一切仇敌。”"},
{volumn:         6,chapter:        10,versenumber:        26,verse:"随后约书亚将这五王杀死，挂在五棵树上。他们就在树上直挂到晚上。"},
{volumn:         6,chapter:        10,versenumber:        27,verse:"日头要落的时候，约书亚一吩咐，人就把尸首从树上取下来，丢在他们藏过的洞里，把几块大石头放在洞口，直存到今日。"},
{volumn:         6,chapter:        10,versenumber:        28,verse:"当日，约书亚夺了玛基大，用刀击杀城中的人和王；将其中一切人口尽行杀灭，没有留下一个。他待玛基大王，像从前待耶利哥王一样。"},
{volumn:         6,chapter:        10,versenumber:        29,verse:"约书亚和以色列众人从玛基大往立拿去，攻打立拿。"},
{volumn:         6,chapter:        10,versenumber:        30,verse:"耶和华将立拿和立拿的王也交在以色列人手里。约书亚攻打这城，用刀击杀了城中的一切人口，没有留下一个。他待立拿王，像从前待耶利哥王一样。"},
{volumn:         6,chapter:        10,versenumber:        31,verse:"约书亚和以色列众人从立拿往拉吉去，对着拉吉安营，攻打这城。"},
{volumn:         6,chapter:        10,versenumber:        32,verse:"耶和华将拉吉交在以色列人的手里。第二天约书亚就夺了拉吉，用刀击杀了城中的一切人口，是照他向立拿一切所行的。"},
{volumn:         6,chapter:        10,versenumber:        33,verse:"那时基色王荷兰上来帮助拉吉，约书亚就把他和他的民都击杀了，没有留下一个。"},
{volumn:         6,chapter:        10,versenumber:        34,verse:"约书亚和以色列众人从拉吉往伊矶伦去，对着伊矶伦安营，攻打这城。"},
{volumn:         6,chapter:        10,versenumber:        35,verse:"当日就夺了城，用刀击杀了城中的人。那日，约书亚将城中的一切人口尽行杀灭，是照他向拉吉一切所行的。"},
{volumn:         6,chapter:        10,versenumber:        36,verse:"约书亚和以色列众人从伊矶伦上希伯仑去，攻打这城，"},
{volumn:         6,chapter:        10,versenumber:        37,verse:"就夺了希伯仑和属希伯仑的诸城邑，用刀将城中的人与王，并那些城邑中的人口，都击杀了，没有留下一个，是照他向伊矶伦所行的，把城中的一切人口尽行杀灭。"},
{volumn:         6,chapter:        10,versenumber:        38,verse:"约书亚和以色列众人回到底璧，攻打这城，"},
{volumn:         6,chapter:        10,versenumber:        39,verse:"就夺了底璧和属底璧的城邑，又擒获底璧的王，用刀将这些城中的人口尽行杀灭，没有留下一个。他待底璧和底璧王，像从前待希伯仑和立拿与立拿王一样。"},
{volumn:         6,chapter:        10,versenumber:        40,verse:"这样，约书亚击杀全地的人，就是山地、南地、高原、山坡的人，和那些地的诸王，没有留下一个。将凡有气息的尽行杀灭，正如耶和华以色列的　神所吩咐的。"},
{volumn:         6,chapter:        10,versenumber:        41,verse:"约书亚从加低斯·巴尼亚攻击到迦萨，又攻击歌珊全地，直到基遍。"},
{volumn:         6,chapter:        10,versenumber:        42,verse:"约书亚一时杀败了这些王，并夺了他们的地，因为耶和华以色列的　神为以色列争战。"},
{volumn:         6,chapter:        10,versenumber:        43,verse:"于是约书亚和以色列众人回到吉甲的营中。"},
{volumn:         6,chapter:        11,versenumber:         1,verse:"夏琐王耶宾听见这事，就打发人去见玛顿王约巴、伸仑王、押煞王，"},
{volumn:         6,chapter:        11,versenumber:         2,verse:"与北方山地、基尼烈南边的亚拉巴高原，并西边多珥山冈的诸王；"},
{volumn:         6,chapter:        11,versenumber:         3,verse:"又去见东方和西方的迦南人，与山地的亚摩利人、赫人、比利洗人、耶布斯人，并黑门山根米斯巴地的希未人。"},
{volumn:         6,chapter:        11,versenumber:         4,verse:"这些王和他们的众军都出来，人数多如海边的沙，并有许多马匹车辆。"},
{volumn:         6,chapter:        11,versenumber:         5,verse:"这诸王会合，来到米伦水边，一同安营，要与以色列人争战。"},
{volumn:         6,chapter:        11,versenumber:         6,verse:"耶和华对约书亚说：“你不要因他们惧怕。明日这时，我必将他们交付以色列人全然杀了。你要砍断他们马的蹄筋，用火焚烧他们的车辆。”"},
{volumn:         6,chapter:        11,versenumber:         7,verse:"于是约书亚率领一切兵丁，在米伦水边突然向前攻打他们。"},
{volumn:         6,chapter:        11,versenumber:         8,verse:"耶和华将他们交在以色列人手里，以色列人就击杀他们，追赶他们到西顿大城，到米斯利弗·玛音，直到东边米斯巴的平原，将他们击杀，没有留下一个。"},
{volumn:         6,chapter:        11,versenumber:         9,verse:"约书亚就照耶和华所吩咐他的去行，砍断他们马的蹄筋，用火焚烧他们的车辆。"},
{volumn:         6,chapter:        11,versenumber:        10,verse:"当时，约书亚转回夺了夏琐，用刀击杀夏琐王。（素来夏琐在这诸国中是为首的。）"},
{volumn:         6,chapter:        11,versenumber:        11,verse:"以色列人用刀击杀城中的人口，将他们尽行杀灭；凡有气息的没有留下一个。约书亚又用火焚烧夏琐。"},
{volumn:         6,chapter:        11,versenumber:        12,verse:"约书亚夺了这些王的一切城邑，擒获其中的诸王，用刀击杀他们，将他们尽行杀灭，正如耶和华仆人摩西所吩咐的。"},
{volumn:         6,chapter:        11,versenumber:        13,verse:"至于造在山冈上的城，除了夏琐以外，以色列人都没有焚烧。约书亚只将夏琐焚烧了。"},
{volumn:         6,chapter:        11,versenumber:        14,verse:"那些城邑所有的财物和牲畜，以色列人都取为自己的掠物；惟有一切人口都用刀击杀，直到杀尽；凡有气息的没有留下一个。"},
{volumn:         6,chapter:        11,versenumber:        15,verse:"耶和华怎样吩咐他仆人摩西，摩西就照样吩咐约书亚，约书亚也照样行。凡耶和华所吩咐摩西的，约书亚没有一件懈怠不行的。"},
{volumn:         6,chapter:        11,versenumber:        16,verse:"约书亚夺了那全地，就是山地、一带南地、歌珊全地、高原、亚拉巴、以色列的山地，和山下的高原。"},
{volumn:         6,chapter:        11,versenumber:        17,verse:"从上西珥的哈拉山，直到黑门山下黎巴嫩平原的巴力·迦得，并且擒获那些地的诸王，将他们杀死。"},
{volumn:         6,chapter:        11,versenumber:        18,verse:"约书亚和这诸王争战了许多年日。"},
{volumn:         6,chapter:        11,versenumber:        19,verse:"除了基遍的希未人之外，没有一城与以色列人讲和的，都是以色列人争战夺来的。"},
{volumn:         6,chapter:        11,versenumber:        20,verse:"因为耶和华的意思是要使他们心里刚硬，来与以色列人争战，好叫他们尽被杀灭，不蒙怜悯，正如耶和华所吩咐摩西的。"},
{volumn:         6,chapter:        11,versenumber:        21,verse:"当时约书亚来到，将住山地、希伯仑、底璧、亚拿伯、犹大山地、以色列山地所有的亚衲族人剪除了。约书亚将他们和他们的城邑尽都毁灭。"},
{volumn:         6,chapter:        11,versenumber:        22,verse:"在以色列人的地没有留下一个亚衲族人，只在迦萨、迦特，和亚实突有留下的。"},
{volumn:         6,chapter:        11,versenumber:        23,verse:"这样，约书亚照着耶和华所吩咐摩西的一切话夺了那全地，就按着以色列支派的宗族将地分给他们为业。于是国中太平，没有争战了。"},
{volumn:         6,chapter:        12,versenumber:         1,verse:"以色列人在约旦河外向日出之地击杀二王，得他们的地，就是从亚嫩谷直到黑门山，并东边的全亚拉巴之地。"},
{volumn:         6,chapter:        12,versenumber:         2,verse:"这二王，有住希实本、亚摩利人的王西宏。他所管之地是从亚嫩谷边的亚罗珥和谷中的城，并基列一半，直到亚扪人的境界，雅博河"},
{volumn:         6,chapter:        12,versenumber:         3,verse:"与约旦河东边的亚拉巴，直到基尼烈海，又到亚拉巴的海，就是盐海，通伯·耶西末的路，以及南方，直到毗斯迦的山根。"},
{volumn:         6,chapter:        12,versenumber:         4,verse:"又有巴珊王噩。他是利乏音人所剩下的，住在亚斯他录和以得来。"},
{volumn:         6,chapter:        12,versenumber:         5,verse:"他所管之地是黑门山、撒迦、巴珊全地，直到基述人和玛迦人的境界，并基列一半，直到希实本王西宏的境界。"},
{volumn:         6,chapter:        12,versenumber:         6,verse:"这二王是耶和华仆人摩西和以色列人所击杀的；耶和华仆人摩西将他们的地赐给流便人、迦得人，和玛拿西半支派的人为业。"},
{volumn:         6,chapter:        12,versenumber:         7,verse:"约书亚和以色列人在约旦河西击杀了诸王。他们的地是从黎巴嫩平原的巴力·迦得，直到上西珥的哈拉山。约书亚就将那地按着以色列支派的宗族分给他们为业，"},
{volumn:         6,chapter:        12,versenumber:         8,verse:"就是赫人、亚摩利人，迦南人、比利洗人、希未人、耶布斯人的山地、高原亚拉巴、山坡、旷野，和南地。"},
{volumn:         6,chapter:        12,versenumber:         9,verse:"他们的王：一个是耶利哥王，一个是靠近伯特利的艾城王，"},
{volumn:         6,chapter:        12,versenumber:        10,verse:"一个是耶路撒冷王，一个是希伯仑王，"},
{volumn:         6,chapter:        12,versenumber:        11,verse:"一个是耶末王，一个是拉吉王，"},
{volumn:         6,chapter:        12,versenumber:        12,verse:"一个是伊矶伦王，一个是基色王，"},
{volumn:         6,chapter:        12,versenumber:        13,verse:"一个是底璧王，一个是基德王，"},
{volumn:         6,chapter:        12,versenumber:        14,verse:"一个是何珥玛王，一个是亚拉得王，"},
{volumn:         6,chapter:        12,versenumber:        15,verse:"一个是立拿王，一个是亚杜兰王，"},
{volumn:         6,chapter:        12,versenumber:        16,verse:"一个是玛基大王，一个是伯特利王，"},
{volumn:         6,chapter:        12,versenumber:        17,verse:"一个是他普亚王，一个是希弗王，"},
{volumn:         6,chapter:        12,versenumber:        18,verse:"一个是亚弗王，一个是拉沙仑王，"},
{volumn:         6,chapter:        12,versenumber:        19,verse:"一个是玛顿王，一个是夏琐王，"},
{volumn:         6,chapter:        12,versenumber:        20,verse:"一个是伸仑·米仑王，一个是押煞王，"},
{volumn:         6,chapter:        12,versenumber:        21,verse:"一个是他纳王，一个是米吉多王，"},
{volumn:         6,chapter:        12,versenumber:        22,verse:"一个是基低斯王，一个是靠近迦密的约念王，"},
{volumn:         6,chapter:        12,versenumber:        23,verse:"一个是多珥山冈的多珥王，一个是吉甲的戈印王，"},
{volumn:         6,chapter:        12,versenumber:        24,verse:"一个是得撒王；共计三十一个王。"},
{volumn:         6,chapter:        13,versenumber:         1,verse:"约书亚年纪老迈，耶和华对他说：“你年纪老迈了，还有许多未得之地，"},
{volumn:         6,chapter:        13,versenumber:         2,verse:"就是非利士人的全境和基述人的全地。"},
{volumn:         6,chapter:        13,versenumber:         3,verse:"从埃及前的西曷河往北，直到以革伦的境界，就算属迦南人之地。有非利士人五个首领所管的迦萨人、亚实突人、亚实基伦人、迦特人、以革伦人之地，并有南方亚卫人之地。"},
{volumn:         6,chapter:        13,versenumber:         4,verse:"又有迦南人的全地，并属西顿人的米亚拉到亚弗，直到亚摩利人的境界。"},
{volumn:         6,chapter:        13,versenumber:         5,verse:"还有迦巴勒人之地，并向日出的全黎巴嫩，就是从黑门山根的巴力·迦得，直到哈马口。"},
{volumn:         6,chapter:        13,versenumber:         6,verse:"山地的一切居民，从黎巴嫩直到米斯利弗·玛音，就是所有的西顿人，我必在以色列人面前赶出他们去。你只管照我所吩咐的，将这地拈阄分给以色列人为业。"},
{volumn:         6,chapter:        13,versenumber:         7,verse:"现在你要把这地分给九个支派和玛拿西半个支派为业。”"},
{volumn:         6,chapter:        13,versenumber:         8,verse:"玛拿西那半支派和流便、迦得二支派已经受了产业，就是耶和华的仆人摩西在约旦河东所赐给他们的："},
{volumn:         6,chapter:        13,versenumber:         9,verse:"是从亚嫩谷边的亚罗珥和谷中的城，并米底巴的全平原，直到底本，"},
{volumn:         6,chapter:        13,versenumber:        10,verse:"和在希实本作王亚摩利王西宏的诸城，直到亚扪人的境界；"},
{volumn:         6,chapter:        13,versenumber:        11,verse:"又有基列地、基述人、玛迦人的地界，并黑门全山、巴珊全地，直到撒迦；"},
{volumn:         6,chapter:        13,versenumber:        12,verse:"又有巴珊王噩的全国，他在亚斯他录和以得来作王（利乏音人所存留的只剩下他）。这些地的人都是摩西所击杀、所赶逐的。"},
{volumn:         6,chapter:        13,versenumber:        13,verse:"以色列人却没有赶逐基述人、玛迦人；这些人仍住在以色列中，直到今日。"},
{volumn:         6,chapter:        13,versenumber:        14,verse:"只是利未支派，摩西（原文是他）没有把产业分给他们。他们的产业乃是献与耶和华以色列　神的火祭，正如耶和华所应许他们的。"},
{volumn:         6,chapter:        13,versenumber:        15,verse:"摩西按着流便支派的宗族分给他们产业。"},
{volumn:         6,chapter:        13,versenumber:        16,verse:"他们的境界是亚嫩谷边的亚罗珥和谷中的城，靠近米底巴的全平原；"},
{volumn:         6,chapter:        13,versenumber:        17,verse:"希实本并属希实本平原的各城，底本、巴末·巴力、伯·巴力·勉、"},
{volumn:         6,chapter:        13,versenumber:        18,verse:"雅杂、基底莫、米法押、"},
{volumn:         6,chapter:        13,versenumber:        19,verse:"基列亭、西比玛、谷中山的细列·哈沙辖、"},
{volumn:         6,chapter:        13,versenumber:        20,verse:"伯·毗珥、毗斯迦山坡、伯·耶西末；"},
{volumn:         6,chapter:        13,versenumber:        21,verse:"平原的各城，并亚摩利王西宏的全国。这西宏曾在希实本作王，摩西把他和米甸的族长以未、利金、苏珥、户珥、利巴击杀了；这都是住那地属西宏为首领的。"},
{volumn:         6,chapter:        13,versenumber:        22,verse:"那时以色列人在所杀的人中，也用刀杀了比珥的儿子术士巴兰。"},
{volumn:         6,chapter:        13,versenumber:        23,verse:"流便人的境界就是约旦河与靠近约旦河的地。以上是流便人按着宗族所得为业的诸城，并属城的村庄。"},
{volumn:         6,chapter:        13,versenumber:        24,verse:"摩西按着迦得支派的宗族分给他们产业。"},
{volumn:         6,chapter:        13,versenumber:        25,verse:"他们的境界是雅谢和基列的各城，并亚扪人的一半地，直到拉巴前的亚罗珥；"},
{volumn:         6,chapter:        13,versenumber:        26,verse:"从希实本到拉抹·米斯巴和比多宁，又从玛哈念到底璧的境界，"},
{volumn:         6,chapter:        13,versenumber:        27,verse:"并谷中的伯·亚兰、伯·宁拉、疏割、撒分，就是希实本王西宏国中的余地，以及约旦河与靠近约旦河的地，直到基尼烈海的极边，都在约旦河东。"},
{volumn:         6,chapter:        13,versenumber:        28,verse:"以上是迦得人按着宗族所得为业的诸城，并属城的村庄。"},
{volumn:         6,chapter:        13,versenumber:        29,verse:"摩西把产业分给玛拿西半支派，是按着玛拿西半支派的宗族所分的。"},
{volumn:         6,chapter:        13,versenumber:        30,verse:"他们的境界是从玛哈念起，包括巴珊全地，就是巴珊王噩的全国，并在巴珊、睚珥的一切城邑，共六十个。"},
{volumn:         6,chapter:        13,versenumber:        31,verse:"基列的一半，并亚斯他录、以得来，就是属巴珊王噩国的二城，是按着宗族给玛拿西的儿子玛吉的一半子孙。"},
{volumn:         6,chapter:        13,versenumber:        32,verse:"以上是摩西在约旦河东对着耶利哥的摩押平原所分给他们的产业。"},
{volumn:         6,chapter:        13,versenumber:        33,verse:"只是利未支派，摩西没有把产业分给他们。耶和华以色列的　神是他们的产业，正如耶和华所应许他们的。"},
{volumn:         6,chapter:        14,versenumber:         1,verse:"以色列人在迦南地所得的产业，就是祭司以利亚撒和嫩的儿子约书亚，并以色列各支派的族长所分给他们的，都记在下面，"},
{volumn:         6,chapter:        14,versenumber:         2,verse:"是照耶和华藉摩西所吩咐的，把产业拈阄分给九个半支派。"},
{volumn:         6,chapter:        14,versenumber:         3,verse:"原来，摩西在约旦河东已经把产业分给那两个半支派，只是在他们中间没有把产业分给利未人。"},
{volumn:         6,chapter:        14,versenumber:         4,verse:"因为约瑟的子孙是两个支派，就是玛拿西和以法莲，所以没有把地分给利未人，但给他们城邑居住，并城邑的郊野，可以牧养他们的牲畜，安置他们的财物。"},
{volumn:         6,chapter:        14,versenumber:         5,verse:"耶和华怎样吩咐摩西，以色列人就照样行，把地分了。"},
{volumn:         6,chapter:        14,versenumber:         6,verse:"那时，犹大人来到吉甲见约书亚，有基尼洗族耶孚尼的儿子迦勒对约书亚说：“耶和华在加低斯·巴尼亚指着我与你对神人摩西所说的话，你都知道了。"},
{volumn:         6,chapter:        14,versenumber:         7,verse:"耶和华的仆人摩西从加低斯·巴尼亚打发我窥探这地，那时我正四十岁；我按着心意回报他。"},
{volumn:         6,chapter:        14,versenumber:         8,verse:"然而，同我上去的众弟兄使百姓的心消化；但我专心跟从耶和华我的　神。"},
{volumn:         6,chapter:        14,versenumber:         9,verse:"当日摩西起誓说：‘你脚所踏之地定要归你和你的子孙永远为业，因为你专心跟从耶和华我的　神。’"},
{volumn:         6,chapter:        14,versenumber:        10,verse:"自从耶和华对摩西说这话的时候，耶和华照他所应许的使我存活这四十五年；其间以色列人在旷野行走。看哪，现今我八十五岁了，"},
{volumn:         6,chapter:        14,versenumber:        11,verse:"我还是强壮，像摩西打发我去的那天一样；无论是争战，是出入，我的力量那时如何，现在还是如何。"},
{volumn:         6,chapter:        14,versenumber:        12,verse:"求你将耶和华那日应许我的这山地给我；那里有亚衲族人，并宽大坚固的城，你也曾听见了。或者耶和华照他所应许的与我同在，我就把他们赶出去。”"},
{volumn:         6,chapter:        14,versenumber:        13,verse:"于是约书亚为耶孚尼的儿子迦勒祝福，将希伯仑给他为业。"},
{volumn:         6,chapter:        14,versenumber:        14,verse:"所以希伯仑作了基尼洗族耶孚尼的儿子迦勒的产业，直到今日，因为他专心跟从耶和华以色列的　神。"},
{volumn:         6,chapter:        14,versenumber:        15,verse:"希伯仑从前名叫基列·亚巴；亚巴是亚衲族中最尊大的人。于是国中太平，没有争战了。"},
{volumn:         6,chapter:        15,versenumber:         1,verse:"犹大支派按着宗族拈阄所得之地是在尽南边，到以东的交界，向南直到寻的旷野。"},
{volumn:         6,chapter:        15,versenumber:         2,verse:"他们的南界是从盐海的尽边，就是从朝南的海汊起，"},
{volumn:         6,chapter:        15,versenumber:         3,verse:"通到亚克拉滨坡的南边，接连到寻，上到加低斯·巴尼亚的南边，又过希斯仑，上到亚达珥，绕到甲加，"},
{volumn:         6,chapter:        15,versenumber:         4,verse:"接连到押们，通到埃及小河，直通到海为止。这就是他们的南界。"},
{volumn:         6,chapter:        15,versenumber:         5,verse:"东界是从盐海南边到约旦河口。北界是从约旦河口的海汊起，"},
{volumn:         6,chapter:        15,versenumber:         6,verse:"上到伯·曷拉，过伯·亚拉巴的北边，上到流便之子波罕的磐石；"},
{volumn:         6,chapter:        15,versenumber:         7,verse:"从亚割谷往北，上到底璧，直向河南亚都冥坡对面的吉甲；又接连到隐·示麦泉，直通到隐·罗结，"},
{volumn:         6,chapter:        15,versenumber:         8,verse:"上到欣嫩子谷，贴近耶布斯的南界（耶布斯就是耶路撒冷）；又上到欣嫩谷西边的山顶，就是在利乏音谷极北的边界；"},
{volumn:         6,chapter:        15,versenumber:         9,verse:"又从山顶延到尼弗多亚的水源，通到以弗仑山的城邑，又延到巴拉（巴拉就是基列·耶琳）；"},
{volumn:         6,chapter:        15,versenumber:        10,verse:"又从巴拉往西绕到西珥山，接连到耶琳山的北边（耶琳就是基撒仑）；又下到伯·示麦过亭纳，"},
{volumn:         6,chapter:        15,versenumber:        11,verse:"通到以革伦北边，延到施基仑，接连到巴拉山；又通到雅比聂，直通到海为止。"},
{volumn:         6,chapter:        15,versenumber:        12,verse:"西界就是大海和靠近大海之地。这是犹大人按着宗族所得之地四围的交界。"},
{volumn:         6,chapter:        15,versenumber:        13,verse:"约书亚照耶和华所吩咐的，将犹大人中的一段地，就是基列·亚巴，分给耶孚尼的儿子迦勒。亚巴是亚衲族的始祖（基列·亚巴就是希伯仑）。"},
{volumn:         6,chapter:        15,versenumber:        14,verse:"迦勒就从那里赶出亚衲族的三个族长，就是示筛、亚希幔、挞买；"},
{volumn:         6,chapter:        15,versenumber:        15,verse:"又从那里上去，攻击底璧的居民。（这底璧从前名叫基列·西弗。）"},
{volumn:         6,chapter:        15,versenumber:        16,verse:"迦勒说：“谁能攻打基列·西弗将城夺取，我就把我女儿押撒给他为妻。”"},
{volumn:         6,chapter:        15,versenumber:        17,verse:"迦勒兄弟基纳斯的儿子俄陀聂夺取了那城，迦勒就把女儿押撒给他为妻。"},
{volumn:         6,chapter:        15,versenumber:        18,verse:"押撒过门的时候，劝丈夫向她父亲求一块田，押撒一下驴，迦勒问她说：“你要什么？”"},
{volumn:         6,chapter:        15,versenumber:        19,verse:"她说：“求你赐福给我，你既将我安置在南地，求你也给我水泉。”她父亲就把上泉下泉赐给她。"},
{volumn:         6,chapter:        15,versenumber:        20,verse:"以下是犹大支派按着宗族所得的产业。"},
{volumn:         6,chapter:        15,versenumber:        21,verse:"犹大支派尽南边的城邑，与以东交界相近的，就是甲薛、以得、雅姑珥、"},
{volumn:         6,chapter:        15,versenumber:        22,verse:"基拿、底摩拿、亚大达、"},
{volumn:         6,chapter:        15,versenumber:        23,verse:"基低斯、夏琐、以提楠、"},
{volumn:         6,chapter:        15,versenumber:        24,verse:"西弗、提链、比亚绿、"},
{volumn:         6,chapter:        15,versenumber:        25,verse:"夏琐·哈大他、加略·希斯仑（加略·希斯仑就是夏琐）、"},
{volumn:         6,chapter:        15,versenumber:        26,verse:"亚曼、示玛、摩拉大、"},
{volumn:         6,chapter:        15,versenumber:        27,verse:"哈萨·迦大、黑实门、伯·帕列、"},
{volumn:         6,chapter:        15,versenumber:        28,verse:"哈萨·书亚、别是巴、比斯约他、"},
{volumn:         6,chapter:        15,versenumber:        29,verse:"巴拉、以因、以森、"},
{volumn:         6,chapter:        15,versenumber:        30,verse:"伊勒多腊、基失、何珥玛、"},
{volumn:         6,chapter:        15,versenumber:        31,verse:"洗革拉、麦玛拿、三撒拿、"},
{volumn:         6,chapter:        15,versenumber:        32,verse:"利巴勿、实忻、亚因、临门，共二十九座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        33,verse:"在高原有以实陶、琐拉、亚实拿、"},
{volumn:         6,chapter:        15,versenumber:        34,verse:"撒挪亚、隐·干宁、他普亚、以楠、"},
{volumn:         6,chapter:        15,versenumber:        35,verse:"耶末、亚杜兰、梭哥、亚西加、"},
{volumn:         6,chapter:        15,versenumber:        36,verse:"沙拉音、亚底他音、基底拉、基底罗他音，共十四座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        37,verse:"又有洗楠、哈大沙、麦大·迦得、"},
{volumn:         6,chapter:        15,versenumber:        38,verse:"底连、米斯巴、约帖、"},
{volumn:         6,chapter:        15,versenumber:        39,verse:"拉吉、波斯加、伊矶伦、"},
{volumn:         6,chapter:        15,versenumber:        40,verse:"迦本、拉幔、基提利、"},
{volumn:         6,chapter:        15,versenumber:        41,verse:"基低罗、伯·大衮、拿玛、玛基大，共十六座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        42,verse:"又有立拿、以帖、亚珊、"},
{volumn:         6,chapter:        15,versenumber:        43,verse:"益弗他、亚实拿、尼悉、"},
{volumn:         6,chapter:        15,versenumber:        44,verse:"基伊拉、亚革悉、玛利沙，共九座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        45,verse:"又有以革伦和属以革伦的镇市村庄；"},
{volumn:         6,chapter:        15,versenumber:        46,verse:"从以革伦直到海，一切靠近亚实突之地，并属其地的村庄。"},
{volumn:         6,chapter:        15,versenumber:        47,verse:"亚实突和属亚实突的镇市村庄；迦萨和属迦萨的镇市村庄；直到埃及小河，并大海和靠近大海之地。"},
{volumn:         6,chapter:        15,versenumber:        48,verse:"在山地有沙密、雅提珥、梭哥、"},
{volumn:         6,chapter:        15,versenumber:        49,verse:"大拿、基列·萨拿（基列·萨拿就是底璧）、"},
{volumn:         6,chapter:        15,versenumber:        50,verse:"亚拿伯、以实提莫、亚念、"},
{volumn:         6,chapter:        15,versenumber:        51,verse:"歌珊、何伦、基罗，共十一座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        52,verse:"又有亚拉、度玛、以珊、"},
{volumn:         6,chapter:        15,versenumber:        53,verse:"雅农、伯·他普亚、亚非加、"},
{volumn:         6,chapter:        15,versenumber:        54,verse:"宏他、基列·亚巴（基列·亚巴就是希伯仑）、洗珥，共九座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        55,verse:"又有玛云、迦密、西弗、淤他、"},
{volumn:         6,chapter:        15,versenumber:        56,verse:"耶斯列、约甸、撒挪亚、"},
{volumn:         6,chapter:        15,versenumber:        57,verse:"该隐、基比亚、亭纳，共十座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        58,verse:"又有哈忽、伯·夙、基突、"},
{volumn:         6,chapter:        15,versenumber:        59,verse:"玛腊、伯·亚诺、伊勒提君，共六座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        60,verse:"又有基列·巴力（基列·巴力就是基列·耶琳）、拉巴，共两座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        61,verse:"在旷野有伯·亚拉巴、密丁、西迦迦、"},
{volumn:         6,chapter:        15,versenumber:        62,verse:"匿珊、盐城、隐·基底，共六座城，还有属城的村庄。"},
{volumn:         6,chapter:        15,versenumber:        63,verse:"至于住耶路撒冷的耶布斯人，犹大人不能把他们赶出去，耶布斯人却在耶路撒冷与犹大人同住，直到今日。"},
{volumn:         6,chapter:        16,versenumber:         1,verse:"约瑟的子孙拈阄所得之地是从靠近耶利哥的约旦河起，以耶利哥东边的水为界：从耶利哥上去，通过山地的旷野，到伯特利；"},
{volumn:         6,chapter:        16,versenumber:         2,verse:"又从伯特利到路斯，接连到亚基人的境界，至亚他绿；"},
{volumn:         6,chapter:        16,versenumber:         3,verse:"又往西下到押利提人的境界，到下伯·和仑的境界，直到基色，通到海为止。"},
{volumn:         6,chapter:        16,versenumber:         4,verse:"约瑟的儿子玛拿西、以法莲就得了他们的地业。"},
{volumn:         6,chapter:        16,versenumber:         5,verse:"以法莲子孙的境界，按着宗族所得的，记在下面：他们地业的东界是亚他绿·亚达到上伯·和仑；"},
{volumn:         6,chapter:        16,versenumber:         6,verse:"往西通到北边的密米他，又向东绕到他纳·示罗，又接连到雅挪哈的东边；"},
{volumn:         6,chapter:        16,versenumber:         7,verse:"从雅挪哈下到亚他绿，又到拿拉，达到耶利哥，通到约旦河为止；"},
{volumn:         6,chapter:        16,versenumber:         8,verse:"从他普亚往西，到加拿河，直通到海为止。这就是以法莲支派按着宗族所得的地业。"},
{volumn:         6,chapter:        16,versenumber:         9,verse:"另外在玛拿西人地业中得了些城邑和属城的村庄。这都是分给以法莲子孙的。"},
{volumn:         6,chapter:        16,versenumber:        10,verse:"他们没有赶出住基色的迦南人；迦南人却住在以法莲人中间，成为做苦工的仆人，直到今日。"},
{volumn:         6,chapter:        17,versenumber:         1,verse:"玛拿西是约瑟的长子，他的支派拈阄所得之地记在下面。至于玛拿西的长子基列之父（或译：主）玛吉，因为是勇士就得了基列和巴珊。"},
{volumn:         6,chapter:        17,versenumber:         2,verse:"玛拿西其余的子孙，按着宗族拈阄分地，就是亚比以谢子孙，希勒子孙，亚斯列子孙，示剑子孙，希弗子孙，示米大子孙；这些按着宗族，都是约瑟儿子玛拿西子孙的男丁。"},
{volumn:         6,chapter:        17,versenumber:         3,verse:"玛拿西的玄孙，玛吉的曾孙，基列的孙子，希弗的儿子西罗非哈没有儿子，只有女儿。他的女儿名叫玛拉、挪阿、曷拉、密迦、得撒；"},
{volumn:         6,chapter:        17,versenumber:         4,verse:"她们来到祭司以利亚撒和嫩的儿子约书亚并众首领面前，说：“耶和华曾吩咐摩西在我们弟兄中分给我们产业。”于是约书亚照耶和华所吩咐的，在她们伯叔中，把产业分给她们。"},
{volumn:         6,chapter:        17,versenumber:         5,verse:"除了约旦河东的基列和巴珊地之外，还有十份地归玛拿西，"},
{volumn:         6,chapter:        17,versenumber:         6,verse:"因为玛拿西的孙女们在玛拿西的孙子中得了产业。基列地是属玛拿西其余的子孙。"},
{volumn:         6,chapter:        17,versenumber:         7,verse:"玛拿西的境界：从亚设起，到示剑前的密米他，往北到隐·他普亚居民之地。"},
{volumn:         6,chapter:        17,versenumber:         8,verse:"他普亚地归玛拿西，只是玛拿西境界上的他普亚城归以法莲子孙。"},
{volumn:         6,chapter:        17,versenumber:         9,verse:"其界下到加拿河的南边。在玛拿西城邑中的这些城邑都归以法莲。玛拿西的地界是在河北直通到海为止；"},
{volumn:         6,chapter:        17,versenumber:        10,verse:"南归以法莲，北归玛拿西，以海为界；北边到亚设，东边到以萨迦。"},
{volumn:         6,chapter:        17,versenumber:        11,verse:"玛拿西在以萨迦和亚设境内，有伯·善和属伯·善的镇市，以伯莲和属以伯莲的镇市，多珥的居民和属多珥的镇市；又有三处山冈，就是隐·多珥和属隐·多珥的镇市；他纳的居民和属他纳的镇市，米吉多的居民和属米吉多的镇市。"},
{volumn:         6,chapter:        17,versenumber:        12,verse:"只是玛拿西子孙不能赶出这些城的居民，迦南人偏要住在那地。"},
{volumn:         6,chapter:        17,versenumber:        13,verse:"及至以色列人强盛了，就使迦南人做苦工，没有把他们全然赶出。"},
{volumn:         6,chapter:        17,versenumber:        14,verse:"约瑟的子孙对约书亚说：“耶和华到如今既然赐福与我们，我们也族大人多，你为什么仅将一阄一段之地分给我们为业呢？”"},
{volumn:         6,chapter:        17,versenumber:        15,verse:"约书亚说：“你们如果族大人多，嫌以法莲山地窄小，就可以上比利洗人、利乏音人之地，在树林中砍伐树木。”"},
{volumn:         6,chapter:        17,versenumber:        16,verse:"约瑟的子孙说：“那山地容不下我们，并且住平原的迦南人，就是住伯·善和属伯·善的镇市，并住耶斯列平原的人，都有铁车。”"},
{volumn:         6,chapter:        17,versenumber:        17,verse:"约书亚对约瑟家，就是以法莲和玛拿西人，说：“你是族大人多，并且强盛，不可仅有一阄之地，"},
{volumn:         6,chapter:        17,versenumber:        18,verse:"山地也要归你，虽是树林，你也可以砍伐；靠近之地必归你。迦南人虽有铁车，虽是强盛，你也能把他们赶出去。”"},
{volumn:         6,chapter:        18,versenumber:         1,verse:"以色列的全会众都聚集在示罗，把会幕设立在那里，那地已经被他们制伏了。"},
{volumn:         6,chapter:        18,versenumber:         2,verse:"以色列人中其余的七个支派还没有分给他们地业。"},
{volumn:         6,chapter:        18,versenumber:         3,verse:"约书亚对以色列人说：“耶和华你们列祖的　神所赐给你们的地，你们耽延不去得，要到几时呢？"},
{volumn:         6,chapter:        18,versenumber:         4,verse:"你们每支派当选举三个人，我要打发他们去，他们就要起身走遍那地，按着各支派应得的地业写明（或译：画图），就回到我这里来。"},
{volumn:         6,chapter:        18,versenumber:         5,verse:"他们要将地分作七份；犹大仍在南方，住在他的境内。约瑟家仍在北方，住在他的境内。"},
{volumn:         6,chapter:        18,versenumber:         6,verse:"你们要将地分作七份，写明了拿到我这里来。我要在耶和华我们　神面前，为你们拈阄。"},
{volumn:         6,chapter:        18,versenumber:         7,verse:"利未人在你们中间没有份，因为供耶和华祭司的职任就是他们的产业。迦得支派、流便支派，和玛拿西半支派已经在约旦河东得了地业，就是耶和华仆人摩西所给他们的。”"},
{volumn:         6,chapter:        18,versenumber:         8,verse:"划地势的人起身去的时候，约书亚嘱咐他们说：“你们去走遍那地，划明地势，就回到我这里来。我要在示罗这里，耶和华面前，为你们拈阄。”"},
{volumn:         6,chapter:        18,versenumber:         9,verse:"他们就去了，走遍那地，按着城邑分作七份，写在册子上，回到示罗营中见约书亚。"},
{volumn:         6,chapter:        18,versenumber:        10,verse:"约书亚就在示罗，耶和华面前，为他们拈阄。约书亚在那里，按着以色列人的支派，将地分给他们。"},
{volumn:         6,chapter:        18,versenumber:        11,verse:"便雅悯支派，按着宗族拈阄所得之地，是在犹大、约瑟子孙中间。"},
{volumn:         6,chapter:        18,versenumber:        12,verse:"他们的北界是从约旦河起，往上贴近耶利哥的北边；又往西通过山地，直到伯·亚文的旷野；"},
{volumn:         6,chapter:        18,versenumber:        13,verse:"从那里往南接连到路斯，贴近路斯（路斯就是伯特利），又下到亚他绿·亚达，靠近下伯·和仑南边的山；"},
{volumn:         6,chapter:        18,versenumber:        14,verse:"从那里往西，又转向南，从伯·和仑南对面的山，直达到犹大人的城基列·巴力（基列·巴力就是基列·耶琳）；这是西界。"},
{volumn:         6,chapter:        18,versenumber:        15,verse:"南界是从基列·耶琳的尽边起，往西达到尼弗多亚的水源；"},
{volumn:         6,chapter:        18,versenumber:        16,verse:"又下到欣嫩子谷对面山的尽边，就是利乏音谷北边的山；又下到欣嫩谷，贴近耶布斯的南边；又下到隐·罗结；"},
{volumn:         6,chapter:        18,versenumber:        17,verse:"又往北通到隐·示麦，达到亚都冥坡对面的基利绿；又下到流便之子波罕的磐石；"},
{volumn:         6,chapter:        18,versenumber:        18,verse:"又接连到亚拉巴对面，往北下到亚拉巴；"},
{volumn:         6,chapter:        18,versenumber:        19,verse:"又接连到伯·曷拉的北边，直通到盐海的北汊，就是约旦河的南头；这是南界。"},
{volumn:         6,chapter:        18,versenumber:        20,verse:"东界是约旦河。这是便雅悯人按着宗族，照他们四围的交界所得的地业。"},
{volumn:         6,chapter:        18,versenumber:        21,verse:"便雅悯支派按着宗族所得的城邑就是：耶利哥、伯·曷拉、伊麦·基悉、"},
{volumn:         6,chapter:        18,versenumber:        22,verse:"伯·亚拉巴、洗玛脸、伯特利、"},
{volumn:         6,chapter:        18,versenumber:        23,verse:"亚文、巴拉、俄弗拉、"},
{volumn:         6,chapter:        18,versenumber:        24,verse:"基法·阿摩尼、俄弗尼、迦巴，共十二座城，还有属城的村庄；"},
{volumn:         6,chapter:        18,versenumber:        25,verse:"又有基遍、拉玛、比录、"},
{volumn:         6,chapter:        18,versenumber:        26,verse:"米斯巴、基非拉、摩撒、"},
{volumn:         6,chapter:        18,versenumber:        27,verse:"利坚、伊利毗勒、他拉拉、"},
{volumn:         6,chapter:        18,versenumber:        28,verse:"洗拉、以利弗、耶布斯（耶布斯就是耶路撒冷）、基比亚、基列，共十四座城，还有属城的村庄。这是便雅悯人按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:         1,verse:"为西缅支派的人，按着宗族，拈出第二阄。他们所得的地业是在犹大人地业中间。"},
{volumn:         6,chapter:        19,versenumber:         2,verse:"他们所得为业之地就是：别是巴（或名示巴）、摩拉大、"},
{volumn:         6,chapter:        19,versenumber:         3,verse:"哈萨·书亚、巴拉、以森、"},
{volumn:         6,chapter:        19,versenumber:         4,verse:"伊利多拉、比土力、何珥玛、"},
{volumn:         6,chapter:        19,versenumber:         5,verse:"洗革拉、伯·玛加博、哈萨·苏撒、"},
{volumn:         6,chapter:        19,versenumber:         6,verse:"伯·利巴勿、沙鲁险，共十三座城，还有属城的村庄；"},
{volumn:         6,chapter:        19,versenumber:         7,verse:"又有亚因、利门、以帖、亚珊，共四座城，还有属城的村庄；"},
{volumn:         6,chapter:        19,versenumber:         8,verse:"并有这些城邑四围一切的村庄，直到巴拉·比珥，就是南地的拉玛。这是西缅支派按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:         9,verse:"西缅人的地业是从犹大人地业中得来的；因为犹大人的份过多，所以西缅人在他们的地业中得了地业。"},
{volumn:         6,chapter:        19,versenumber:        10,verse:"为西布伦人，按着宗族，拈出第三阄。他们地业的境界是到撒立；"},
{volumn:         6,chapter:        19,versenumber:        11,verse:"往西上到玛拉拉，达到大巴设，又达到约念前的河；"},
{volumn:         6,chapter:        19,versenumber:        12,verse:"又从撒立往东转向日出之地，到吉斯绿·他泊的境界，又通到大比拉，上到雅非亚；"},
{volumn:         6,chapter:        19,versenumber:        13,verse:"从那里往东，接连到迦特·希弗，至以特·加汛，通到临门，临门延到尼亚；"},
{volumn:         6,chapter:        19,versenumber:        14,verse:"又绕过尼亚的北边，转到哈拿顿，通到伊弗他·伊勒谷。"},
{volumn:         6,chapter:        19,versenumber:        15,verse:"还有加他、拿哈拉、伸仑、以大拉、伯利恒，共十二座城，还有属城的村庄。"},
{volumn:         6,chapter:        19,versenumber:        16,verse:"这些城并属城的村庄就是西布伦人按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:        17,verse:"为以萨迦人，按着宗族，拈出第四阄。"},
{volumn:         6,chapter:        19,versenumber:        18,verse:"他们的境界是到耶斯列、基苏律、书念、"},
{volumn:         6,chapter:        19,versenumber:        19,verse:"哈弗连、示按、亚拿哈拉、"},
{volumn:         6,chapter:        19,versenumber:        20,verse:"拉璧、基善、亚别、"},
{volumn:         6,chapter:        19,versenumber:        21,verse:"利篾、隐·干宁、隐·哈大、伯·帕薛；"},
{volumn:         6,chapter:        19,versenumber:        22,verse:"又达到他泊、沙哈洗玛、伯·示麦，直通到约旦河为止，共十六座城，还有属城的村庄。"},
{volumn:         6,chapter:        19,versenumber:        23,verse:"这些城并属城的村庄就是以萨迦支派按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:        24,verse:"为亚设支派，按着宗族，拈出第五阄。"},
{volumn:         6,chapter:        19,versenumber:        25,verse:"他们的境界是黑甲、哈利、比田、押煞、"},
{volumn:         6,chapter:        19,versenumber:        26,verse:"亚拉米勒、亚末、米沙勒；往西达到迦密，又到希曷·立纳，"},
{volumn:         6,chapter:        19,versenumber:        27,verse:"转向日出之地，到伯·大衮，达到细步纶；往北到伊弗他·伊勒谷，到伯·以墨和尼业，也通到迦步勒的左边；"},
{volumn:         6,chapter:        19,versenumber:        28,verse:"又到义伯仑、利合、哈们、加拿，直到西顿大城；"},
{volumn:         6,chapter:        19,versenumber:        29,verse:"转到拉玛和坚固城推罗；又转到何萨，靠近亚革悉一带地方，直通到海；"},
{volumn:         6,chapter:        19,versenumber:        30,verse:"又有乌玛、亚弗、利合，共二十二座城，还有属城的村庄。"},
{volumn:         6,chapter:        19,versenumber:        31,verse:"这些城并属城的村庄就是亚设支派按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:        32,verse:"为拿弗他利人，按着宗族，拈出第六阄。"},
{volumn:         6,chapter:        19,versenumber:        33,verse:"他们的境界是从希利弗从撒拿音的橡树，从亚大米·尼吉和雅比聂，直到拉共，通到约旦河；"},
{volumn:         6,chapter:        19,versenumber:        34,verse:"又转向西到亚斯纳·他泊，从那里通到户割，南边到西布伦，西边到亚设，又向日出之地，达到约旦河那里的犹大。"},
{volumn:         6,chapter:        19,versenumber:        35,verse:"坚固的城就是：西丁、侧耳、哈末、拉甲、基尼烈、"},
{volumn:         6,chapter:        19,versenumber:        36,verse:"亚大玛、拉玛、夏琐、"},
{volumn:         6,chapter:        19,versenumber:        37,verse:"基低斯、以得来、隐·夏琐、"},
{volumn:         6,chapter:        19,versenumber:        38,verse:"以利稳、密大·伊勒、和琏、伯·亚纳、伯·示麦，共十九座城，还有属城的村庄。"},
{volumn:         6,chapter:        19,versenumber:        39,verse:"这些城并属城的村庄就是拿弗他利支派按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:        40,verse:"为但支派，按着宗族，拈出第七阄。"},
{volumn:         6,chapter:        19,versenumber:        41,verse:"他们地业的境界是琐拉、以实陶、伊珥·示麦、"},
{volumn:         6,chapter:        19,versenumber:        42,verse:"沙拉宾、亚雅仑、伊提拉、"},
{volumn:         6,chapter:        19,versenumber:        43,verse:"以伦、亭拿他、以革伦、"},
{volumn:         6,chapter:        19,versenumber:        44,verse:"伊利提基、基比顿、巴拉、"},
{volumn:         6,chapter:        19,versenumber:        45,verse:"伊胡得、比尼·比拉、迦特·临门、"},
{volumn:         6,chapter:        19,versenumber:        46,verse:"美·耶昆、拉昆，并约帕对面的地界。"},
{volumn:         6,chapter:        19,versenumber:        47,verse:"但人的地界越过原得的地界；因为但人上去攻取利善，用刀击杀城中的人，得了那城，住在其中，以他们先祖但的名将利善改名为但。"},
{volumn:         6,chapter:        19,versenumber:        48,verse:"这些城并属城的村庄就是但支派按着宗族所得的地业。"},
{volumn:         6,chapter:        19,versenumber:        49,verse:"以色列人按着境界分完了地业，就在他们中间将地给嫩的儿子约书亚为业，"},
{volumn:         6,chapter:        19,versenumber:        50,verse:"是照耶和华的吩咐，将约书亚所求的城，就是以法莲山地的亭拿·西拉城，给了他。他就修那城，住在其中。"},
{volumn:         6,chapter:        19,versenumber:        51,verse:"这就是祭司以利亚撒和嫩的儿子约书亚，并以色列各支派的族长，在示罗会幕门口，耶和华面前，拈阄所分的地业。这样，他们把地分完了。"},
{volumn:         6,chapter:        20,versenumber:         1,verse:"耶和华晓谕约书亚说："},
{volumn:         6,chapter:        20,versenumber:         2,verse:"“你吩咐以色列人说：你们要照着我藉摩西所晓谕你们的，为自己设立逃城，"},
{volumn:         6,chapter:        20,versenumber:         3,verse:"使那无心而误杀人的，可以逃到那里。这些城可以作你们逃避报血仇人的地方。"},
{volumn:         6,chapter:        20,versenumber:         4,verse:"那杀人的要逃到这些城中的一座城，站在城门口，将他的事情说给城内的长老们听。他们就把他收进城里，给他地方，使他住在他们中间。"},
{volumn:         6,chapter:        20,versenumber:         5,verse:"若是报血仇的追了他来，长老不可将他交在报血仇的手里；因为他是素无仇恨，无心杀了人的。"},
{volumn:         6,chapter:        20,versenumber:         6,verse:"他要住在那城里，站在会众面前听审判，等到那时的大祭司死了，杀人的才可以回到本城本家，就是他所逃出来的那城。”"},
{volumn:         6,chapter:        20,versenumber:         7,verse:"于是，以色列人在拿弗他利山地分定加利利的基低斯；在以法莲山地分定示剑；在犹大山地分定基列·亚巴（基列·亚巴就是希伯仑）；"},
{volumn:         6,chapter:        20,versenumber:         8,verse:"又在约旦河外耶利哥东，从流便支派中，在旷野的平原，设立比悉；从迦得支派中设立基列的拉末；从玛拿西支派中设立巴珊的哥兰。"},
{volumn:         6,chapter:        20,versenumber:         9,verse:"这都是为以色列众人和在他们中间寄居的外人所分定的城邑，使误杀人的都可以逃到那里，不死在报血仇人的手中，等他站在会众面前听审判。"},
{volumn:         6,chapter:        21,versenumber:         1,verse:"那时，利未人的众族长来到祭司以利亚撒和嫩的儿子约书亚，并以色列各支派的族长面前，"},
{volumn:         6,chapter:        21,versenumber:         2,verse:"在迦南地的示罗对他们说：“从前耶和华藉着摩西吩咐给我们城邑居住，并城邑的郊野可以牧养我们的牲畜。”"},
{volumn:         6,chapter:        21,versenumber:         3,verse:"于是以色列人照耶和华所吩咐的，从自己的地业中，将以下所记的城邑和城邑的郊野给了利未人。"},
{volumn:         6,chapter:        21,versenumber:         4,verse:"为哥辖族拈阄：利未人的祭司、亚伦的子孙，从犹大支派、西缅支派、便雅悯支派的地业中，按阄得了十三座城。"},
{volumn:         6,chapter:        21,versenumber:         5,verse:"哥辖其余的子孙，从以法莲支派、但支派、玛拿西半支派的地业中，按阄得了十座城。"},
{volumn:         6,chapter:        21,versenumber:         6,verse:"革顺的子孙，从以萨迦支派、亚设支派、拿弗他利支派、住巴珊的玛拿西半支派的地业中，按阄得了十三座城。"},
{volumn:         6,chapter:        21,versenumber:         7,verse:"米拉利的子孙，按着宗族，从流便支派、迦得支派、西布伦支派的地业中，按阄得了十二座城。"},
{volumn:         6,chapter:        21,versenumber:         8,verse:"以色列人照着耶和华藉摩西所吩咐的，将这些城邑和城邑的郊野，按阄分给利未人。"},
{volumn:         6,chapter:        21,versenumber:         9,verse:"从犹大支派、西缅支派的地业中，将以下所记的城给了利未支派哥辖宗族亚伦的子孙；因为给他们拈出头一阄，"},
{volumn:         6,chapter:        21,versenumber:        10,verse:""},
{volumn:         6,chapter:        21,versenumber:        11,verse:"将犹大山地的基列·亚巴和四围的郊野给了他们。亚巴是亚衲族的始祖。（基列·亚巴就是希伯仑）。"},
{volumn:         6,chapter:        21,versenumber:        12,verse:"惟将属城的田地和村庄给了耶孚尼的儿子迦勒为业。"},
{volumn:         6,chapter:        21,versenumber:        13,verse:"以色列人将希伯仑，就是误杀人的逃城和属城的郊野，给了祭司亚伦的子孙；又给他们立拿和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        14,verse:"雅提珥和属城的郊野，以实提莫和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        15,verse:"何仑和属城的郊野，底璧和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        16,verse:"亚因和属城的郊野，淤他和属城的郊野，伯·示麦和属城的郊野，共九座城，都是从这二支派中分出来的。"},
{volumn:         6,chapter:        21,versenumber:        17,verse:"又从便雅悯支派的地业中给了他们基遍和属城的郊野，迦巴和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        18,verse:"亚拿突和属城的郊野，亚勒们和属城的郊野，共四座城。"},
{volumn:         6,chapter:        21,versenumber:        19,verse:"亚伦子孙作祭司的共有十三座城，还有属城的郊野。"},
{volumn:         6,chapter:        21,versenumber:        20,verse:"利未支派中哥辖的宗族，就是哥辖其余的子孙，拈阄所得的城有从以法莲支派中分出来的。"},
{volumn:         6,chapter:        21,versenumber:        21,verse:"以色列人将以法莲山地的示剑，就是误杀人的逃城和属城的郊野，给了他们；又给他们基色和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        22,verse:"基伯先和属城的郊野，伯·和仑和属城的郊野，共四座城；"},
{volumn:         6,chapter:        21,versenumber:        23,verse:"又从但支派的地业中给了他们伊利提基和属城的郊野，基比顿和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        24,verse:"亚雅仑和属城的郊野，迦特·临门和属城的郊野，共四座城；"},
{volumn:         6,chapter:        21,versenumber:        25,verse:"又从玛拿西半支派的地业中给了他们他纳和属城的郊野，迦特·临门和属城的郊野，共两座城。"},
{volumn:         6,chapter:        21,versenumber:        26,verse:"哥辖其余的子孙共有十座城，还有属城的郊野。"},
{volumn:         6,chapter:        21,versenumber:        27,verse:"以色列人又从玛拿西半支派的地业中将巴珊的哥兰，就是误杀人的逃城和属城的郊野，给了利未支派革顺的子孙；又给他们比·施提拉和属城的郊野，共两座城；"},
{volumn:         6,chapter:        21,versenumber:        28,verse:"又从以萨迦支派的地业中给了他们基善和属城的郊野，大比拉和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        29,verse:"耶末和属城的郊野，隐·干宁和属城的郊野，共四座城；"},
{volumn:         6,chapter:        21,versenumber:        30,verse:"又从亚设支派的地业中给了他们米沙勒和属城的郊野，押顿和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        31,verse:"黑甲和属城的郊野，利合和属城的郊野，共四座城；"},
{volumn:         6,chapter:        21,versenumber:        32,verse:"又从拿弗他利支派的地业中将加利利的基低斯，就是误杀人的逃城和属城的郊野，给了他们；又给他们哈末·多珥和属城的郊野，加珥坦和属城的郊野，共三座城。"},
{volumn:         6,chapter:        21,versenumber:        33,verse:"革顺人按着宗族所得的城，共十三座，还有属城的郊野。"},
{volumn:         6,chapter:        21,versenumber:        34,verse:"其余利未支派米拉利子孙，从西布伦支派的地业中所得的，就是约念和属城的郊野，加珥他和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        35,verse:"丁拿和属城的郊野，拿哈拉和属城的郊野，共四座城；"},
{volumn:         6,chapter:        21,versenumber:        36,verse:"又从流便支派的地业中给了他们比悉和属城的郊野，雅杂和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        37,verse:"基底莫和属城的郊野，米法押和属城的郊野，共四座城；"},
{volumn:         6,chapter:        21,versenumber:        38,verse:"又从迦得支派的地业中，将基列的拉末，就是误杀人的逃城和属城的郊野，给了他们；又给他们玛哈念和属城的郊野，"},
{volumn:         6,chapter:        21,versenumber:        39,verse:"希实本和属城的郊野，雅谢和属城的郊野，共四座城。"},
{volumn:         6,chapter:        21,versenumber:        40,verse:"其余利未支派的人，就是米拉利的子孙，按着宗族拈阄所得的，共十二座城。"},
{volumn:         6,chapter:        21,versenumber:        41,verse:"利未人在以色列人的地业中所得的城，共四十八座，并有属城的郊野。"},
{volumn:         6,chapter:        21,versenumber:        42,verse:"这些城四围都有属城的郊野，城城都是如此。"},
{volumn:         6,chapter:        21,versenumber:        43,verse:"这样，耶和华将从前向他们列祖起誓所应许的全地赐给以色列人，他们就得了为业，住在其中。"},
{volumn:         6,chapter:        21,versenumber:        44,verse:"耶和华照着向他们列祖起誓所应许的一切话，使他们四境平安；他们一切仇敌中，没有一人在他们面前站立得住。耶和华把一切仇敌都交在他们手中。"},
{volumn:         6,chapter:        21,versenumber:        45,verse:"耶和华应许赐福给以色列家的话一句也没有落空，都应验了。"},
{volumn:         6,chapter:        22,versenumber:         1,verse:"当时，约书亚召了流便人、迦得人，和玛拿西半支派的人来，"},
{volumn:         6,chapter:        22,versenumber:         2,verse:"对他们说：“耶和华仆人摩西所吩咐你们的，你们都遵守了；我所吩咐你们的，你们也都听从了。"},
{volumn:         6,chapter:        22,versenumber:         3,verse:"你们这许多日子，总没有撇离你们的弟兄，直到今日，并守了耶和华你们　神所吩咐你们当守的。"},
{volumn:         6,chapter:        22,versenumber:         4,verse:"如今耶和华你们　神照着他所应许的，使你们弟兄得享平安，现在可以转回你们的帐棚，到耶和华的仆人摩西在约旦河东所赐你们为业之地。"},
{volumn:         6,chapter:        22,versenumber:         5,verse:"只要切切地谨慎遵行耶和华仆人摩西所吩咐你们的诫命律法，爱耶和华你们的　神，行他一切的道，守他的诫命，专靠他，尽心尽性侍奉他。”"},
{volumn:         6,chapter:        22,versenumber:         6,verse:"于是约书亚为他们祝福，打发他们去，他们就回自己的帐棚去了。"},
{volumn:         6,chapter:        22,versenumber:         7,verse:"玛拿西那半支派，摩西早已在巴珊分给他们地业。这半支派，约书亚在约旦河西，在他们弟兄中，分给他们地业。约书亚打发他们回帐棚的时候为他们祝福，"},
{volumn:         6,chapter:        22,versenumber:         8,verse:"对他们说：“你们带许多财物，许多牲畜和金、银、铜、铁，并许多衣服，回你们的帐棚去，要将你们从仇敌夺来的物，与你们众弟兄同分。”"},
{volumn:         6,chapter:        22,versenumber:         9,verse:"于是流便人、迦得人、玛拿西半支派的人从迦南地的示罗起行，离开以色列人，回往他们得为业的基列地，就是照耶和华藉摩西所吩咐的得了为业之地。"},
{volumn:         6,chapter:        22,versenumber:        10,verse:"流便人、迦得人，和玛拿西半支派的人到了靠近约旦河的一带迦南地，就在约旦河那里筑了一座坛；那坛看着高大。"},
{volumn:         6,chapter:        22,versenumber:        11,verse:"以色列人听说流便人、迦得人、玛拿西半支派的人靠近约旦河边，在迦南地属以色列人的那边筑了一座坛。"},
{volumn:         6,chapter:        22,versenumber:        12,verse:"全会众一听见，就聚集在示罗，要上去攻打他们。"},
{volumn:         6,chapter:        22,versenumber:        13,verse:"以色列人打发祭司以利亚撒的儿子非尼哈，往基列地去见流便人、迦得人、玛拿西半支派的人；"},
{volumn:         6,chapter:        22,versenumber:        14,verse:"又打发十个首领与非尼哈同去，就是以色列每支派的一个首领，都是以色列军中的统领。"},
{volumn:         6,chapter:        22,versenumber:        15,verse:"他们到了基列地，见流便人、迦得人，和玛拿西半支派的人，对他们说："},
{volumn:         6,chapter:        22,versenumber:        16,verse:"“耶和华全会众这样说，你们今日转去不跟从耶和华，干犯以色列的　神，为自己筑一座坛，悖逆了耶和华，这犯的是什么罪呢？"},
{volumn:         6,chapter:        22,versenumber:        17,verse:"从前拜毗珥的罪孽还算小吗？虽然瘟疫临到耶和华的会众，到今日我们还没有洗净这罪。"},
{volumn:         6,chapter:        22,versenumber:        18,verse:"你们今日竟转去不跟从耶和华吗？你们今日既悖逆耶和华，明日他必向以色列全会众发怒。"},
{volumn:         6,chapter:        22,versenumber:        19,verse:"你们所得为业之地，若嫌不洁净，就可以过到耶和华之地，就是耶和华的帐幕所住之地，在我们中间得地业。只是不可悖逆耶和华，也不可得罪我们，在耶和华我们　神的坛以外为自己筑坛。"},
{volumn:         6,chapter:        22,versenumber:        20,verse:"从前谢拉的曾孙亚干岂不是在那当灭的物上犯了罪，就有忿怒临到以色列全会众吗？那人在所犯的罪中不独一人死亡。”"},
{volumn:         6,chapter:        22,versenumber:        21,verse:"于是流便人、迦得人、玛拿西半支派的人回答以色列军中的统领说："},
{volumn:         6,chapter:        22,versenumber:        22,verse:"“大能者　神耶和华！大能者　神耶和华！他是知道的！以色列人也必知道！我们若有悖逆的意思，或是干犯耶和华（愿你今日不保佑我们），"},
{volumn:         6,chapter:        22,versenumber:        23,verse:"为自己筑坛，要转去不跟从耶和华，或是要将燔祭、素祭、平安祭献在坛上，愿耶和华亲自讨我们的罪。"},
{volumn:         6,chapter:        22,versenumber:        24,verse:"我们行这事并非无故，是特意做的，说：恐怕日后你们的子孙对我们的子孙说：‘你们与耶和华以色列的　神有何关涉呢？"},
{volumn:         6,chapter:        22,versenumber:        25,verse:"因为耶和华把约旦河定为我们和你们这流便人、迦得人的交界，你们与耶和华无份了。’这样，你们的子孙就使我们的子孙不再敬畏耶和华了。"},
{volumn:         6,chapter:        22,versenumber:        26,verse:"因此我们说：‘不如为自己筑一座坛，不是为献燔祭，也不是为献别的祭，"},
{volumn:         6,chapter:        22,versenumber:        27,verse:"乃是为你我中间和你我后人中间作证据，好叫我们也在耶和华面前献燔祭、平安祭，和别的祭侍奉他，免得你们的子孙日后对我们的子孙说，你们与耶和华无份了。’"},
{volumn:         6,chapter:        22,versenumber:        28,verse:"所以我们说：‘日后你们对我们，或对我们的后人这样说，我们就可以回答说，你们看我们列祖所筑的坛是耶和华坛的样式；这并不是为献燔祭，也不是为献别的祭，乃是为作你我中间的证据。’"},
{volumn:         6,chapter:        22,versenumber:        29,verse:"我们在耶和华我们　神帐幕前的坛以外，另筑一座坛，为献燔祭、素祭，和别的祭，悖逆耶和华，今日转去不跟从他，我们断没有这个意思。”"},
{volumn:         6,chapter:        22,versenumber:        30,verse:"祭司非尼哈与会中的首领，就是与他同来以色列军中的统领，听见流便人、迦得人、玛拿西人所说的话，就都以为美。"},
{volumn:         6,chapter:        22,versenumber:        31,verse:"祭司以利亚撒的儿子非尼哈对流便人、迦得人、玛拿西人说：“今日我们知道耶和华在我们中间，因为你们没有向他犯了这罪。现在你们救以色列人脱离耶和华的手了。”"},
{volumn:         6,chapter:        22,versenumber:        32,verse:"祭司以利亚撒的儿子非尼哈与众首领离了流便人、迦得人，从基列地回往迦南地，到了以色列人那里，便将这事回报他们。"},
{volumn:         6,chapter:        22,versenumber:        33,verse:"以色列人以这事为美，就称颂　神，不再提上去攻打流便人、迦得人、毁坏他们所住的地了。"},
{volumn:         6,chapter:        22,versenumber:        34,verse:"流便人、迦得人给坛起名叫证坛，意思说：这坛在我们中间证明耶和华是　神。"},
{volumn:         6,chapter:        23,versenumber:         1,verse:"耶和华使以色列人安静，不与四围的一切仇敌争战，已经多日。约书亚年纪老迈，"},
{volumn:         6,chapter:        23,versenumber:         2,verse:"就把以色列众人的长老、族长、审判官，并官长都召了来，对他们说：“我年纪已经老迈。"},
{volumn:         6,chapter:        23,versenumber:         3,verse:"耶和华你们的　神因你们的缘故向那些国所行的一切事，你们亲眼看见了，因那为你们争战的是耶和华你们的　神。"},
{volumn:         6,chapter:        23,versenumber:         4,verse:"我所剪除和所剩下的各国，从约旦河起到日落之处的大海，我已经拈阄分给你们各支派为业。"},
{volumn:         6,chapter:        23,versenumber:         5,verse:"耶和华你们的　神必将他们从你们面前赶出去，使他们离开你们，你们就必得他们的地为业，正如耶和华你们的　神所应许的。"},
{volumn:         6,chapter:        23,versenumber:         6,verse:"所以，你们要大大壮胆，谨守遵行写在摩西律法书上的一切话，不可偏离左右。"},
{volumn:         6,chapter:        23,versenumber:         7,verse:"不可与你们中间所剩下的这些国民搀杂。他们的神，你们不可提他的名，不可指着他起誓，也不可侍奉、叩拜；"},
{volumn:         6,chapter:        23,versenumber:         8,verse:"只要照着你们到今日所行的，专靠耶和华你们的　神。"},
{volumn:         6,chapter:        23,versenumber:         9,verse:"因为耶和华已经把又大又强的国民从你们面前赶出；直到今日，没有一人在你们面前站立得住。"},
{volumn:         6,chapter:        23,versenumber:        10,verse:"你们一人必追赶千人，因耶和华你们的　神照他所应许的，为你们争战。"},
{volumn:         6,chapter:        23,versenumber:        11,verse:"你们要分外谨慎，爱耶和华你们的　神。"},
{volumn:         6,chapter:        23,versenumber:        12,verse:"你们若稍微转去，与你们中间所剩下的这些国民联络，彼此结亲，互相往来，"},
{volumn:         6,chapter:        23,versenumber:        13,verse:"你们要确实知道，耶和华你们的　神必不再将他们从你们眼前赶出；他们却要成为你们的网罗、机槛、肋上的鞭、眼中的刺，直到你们在耶和华你们　神所赐的这美地上灭亡。"},
{volumn:         6,chapter:        23,versenumber:        14,verse:"“我现在要走世人必走的路。你们是一心一意地知道，耶和华你们　神所应许赐福与你们的话没有一句落空，都应验在你们身上了。"},
{volumn:         6,chapter:        23,versenumber:        15,verse:"耶和华你们　神所应许的一切福气怎样临到你们身上，耶和华也必照样使各样祸患临到你们身上，直到把你们从耶和华你们　神所赐的这美地上除灭。"},
{volumn:         6,chapter:        23,versenumber:        16,verse:"你们若违背耶和华你们　神吩咐你们所守的约，去侍奉别神，叩拜他，耶和华的怒气必向你们发作，使你们在他所赐的美地上速速灭亡。”"},
{volumn:         6,chapter:        24,versenumber:         1,verse:"约书亚将以色列的众支派聚集在示剑，召了以色列的长老、族长、审判官，并官长来，他们就站在　神面前。"},
{volumn:         6,chapter:        24,versenumber:         2,verse:"约书亚对众民说：“耶和华以色列的　神如此说：‘古时你们的列祖，就是亚伯拉罕和拿鹤的父亲他拉，住在大河那边侍奉别神，"},
{volumn:         6,chapter:        24,versenumber:         3,verse:"我将你们的祖宗亚伯拉罕从大河那边带来，领他走遍迦南全地，又使他的子孙众多，把以撒赐给他；"},
{volumn:         6,chapter:        24,versenumber:         4,verse:"又把雅各和以扫赐给以撒，将西珥山赐给以扫为业；后来雅各和他的子孙下到埃及去了。"},
{volumn:         6,chapter:        24,versenumber:         5,verse:"我差遣摩西、亚伦，并照我在埃及中所行的降灾与埃及，然后把你们领出来。"},
{volumn:         6,chapter:        24,versenumber:         6,verse:"我领你们列祖出埃及，他们就到了红海；埃及人带领车辆马兵追赶你们列祖到红海。"},
{volumn:         6,chapter:        24,versenumber:         7,verse:"你们列祖哀求耶和华，他就使你们和埃及人中间黑暗了，又使海水淹没埃及人。我在埃及所行的事，你们亲眼见过。你们在旷野也住了许多年日。"},
{volumn:         6,chapter:        24,versenumber:         8,verse:"我领你们到约旦河东亚摩利人所住之地。他们与你们争战，我将他们交在你们手中，你们便得了他们的地为业；我也在你们面前将他们灭绝。"},
{volumn:         6,chapter:        24,versenumber:         9,verse:"那时，摩押王西拨的儿子巴勒起来攻击以色列人，打发人召了比珥的儿子巴兰来咒诅你们。"},
{volumn:         6,chapter:        24,versenumber:        10,verse:"我不肯听巴兰的话，所以他倒为你们连连祝福。这样，我便救你们脱离巴勒的手。"},
{volumn:         6,chapter:        24,versenumber:        11,verse:"你们过了约旦河，到了耶利哥；耶利哥人、亚摩利人、比利洗人、迦南人、赫人、革迦撒人、希未人、耶布斯人都与你们争战；我把他们交在你们手里。"},
{volumn:         6,chapter:        24,versenumber:        12,verse:"我打发黄蜂飞在你们前面，将亚摩利人的二王从你们面前撵出，并不是用你的刀，也不是用你的弓。"},
{volumn:         6,chapter:        24,versenumber:        13,verse:"我赐给你们地土，非你们所修治的；我赐给你们城邑，非你们所建造的。你们就住在其中，又得吃非你们所栽种的葡萄园、橄榄园的果子。’"},
{volumn:         6,chapter:        24,versenumber:        14,verse:"“现在你们要敬畏耶和华，诚心实意地侍奉他，将你们列祖在大河那边和在埃及所侍奉的神除掉，去侍奉耶和华。"},
{volumn:         6,chapter:        24,versenumber:        15,verse:"若是你们以侍奉耶和华为不好，今日就可以选择所要侍奉的：是你们列祖在大河那边所侍奉的神呢？是你们所住这地的亚摩利人的神呢？至于我和我家，我们必定侍奉耶和华。”"},
{volumn:         6,chapter:        24,versenumber:        16,verse:"百姓回答说：“我们断不敢离弃耶和华去侍奉别神；"},
{volumn:         6,chapter:        24,versenumber:        17,verse:"因耶和华我们的　神曾将我们和我们列祖从埃及地的为奴之家领出来，在我们眼前行了那些大神迹，在我们所行的道上，所经过的诸国，都保护了我们。"},
{volumn:         6,chapter:        24,versenumber:        18,verse:"耶和华又把住此地的亚摩利人都从我们面前赶出去。所以，我们必侍奉耶和华，因为他是我们的　神。”"},
{volumn:         6,chapter:        24,versenumber:        19,verse:"约书亚对百姓说：“你们不能侍奉耶和华；因为他是圣洁的　神，是忌邪的　神，必不赦免你们的过犯罪恶。"},
{volumn:         6,chapter:        24,versenumber:        20,verse:"你们若离弃耶和华去侍奉外邦神，耶和华在降福之后，必转而降祸与你们，把你们灭绝。”"},
{volumn:         6,chapter:        24,versenumber:        21,verse:"百姓回答约书亚说：“不然，我们定要侍奉耶和华。”"},
{volumn:         6,chapter:        24,versenumber:        22,verse:"约书亚对百姓说：“你们选定耶和华，要侍奉他，你们自己作见证吧！”他们说：“我们愿意作见证。”"},
{volumn:         6,chapter:        24,versenumber:        23,verse:"约书亚说：“你们现在要除掉你们中间的外邦神，专心归向耶和华以色列的　神。”"},
{volumn:         6,chapter:        24,versenumber:        24,verse:"百姓回答约书亚说：“我们必侍奉耶和华我们的　神，听从他的话。”"},
{volumn:         6,chapter:        24,versenumber:        25,verse:"当日，约书亚就与百姓立约，在示剑为他们立定律例典章。"},
{volumn:         6,chapter:        24,versenumber:        26,verse:"约书亚将这些话都写在　神的律法书上，又将一块大石头立在橡树下耶和华的圣所旁边。"},
{volumn:         6,chapter:        24,versenumber:        27,verse:"约书亚对百姓说：“看哪，这石头可以向我们作见证；因为是听见了耶和华所吩咐我们的一切话，倘或你们背弃你们的　神，这石头就可以向你们作见证（倘或…见证：或译所以要向你们作见证，免得你们背弃耶和华你们的　神）。”"},
{volumn:         6,chapter:        24,versenumber:        28,verse:"于是约书亚打发百姓各归自己的地业去了。"},
{volumn:         6,chapter:        24,versenumber:        29,verse:"这些事以后，耶和华的仆人嫩的儿子约书亚，正一百一十岁，就死了。"},
{volumn:         6,chapter:        24,versenumber:        30,verse:"以色列人将他葬在他地业的境内，就是在以法莲山地的亭拿·西拉，在迦实山的北边。"},
{volumn:         6,chapter:        24,versenumber:        31,verse:"约书亚在世和约书亚死后，那些知道耶和华为以色列人所行诸事的长老还在的时候，以色列人侍奉耶和华。"},
{volumn:         6,chapter:        24,versenumber:        32,verse:"以色列人从埃及所带来约瑟的骸骨，葬埋在示剑，就是在雅各从前用一百块银子向示剑的父亲、哈抹的子孙所买的那块地里；这就作了约瑟子孙的产业。"},
{volumn:         6,chapter:        24,versenumber:        33,verse:"亚伦的儿子以利亚撒也死了，就把他葬在他儿子非尼哈所得以法莲山地的小山上。"},
{volumn:         7,chapter:         1,versenumber:         1,verse:"约书亚死后，以色列人求问耶和华说：“我们中间谁当首先上去攻击迦南人，与他们争战？”"},
{volumn:         7,chapter:         1,versenumber:         2,verse:"耶和华说：“犹大当先上去，我已将那地交在他手中。”"},
{volumn:         7,chapter:         1,versenumber:         3,verse:"犹大对他哥哥西缅说：“请你同我到拈阄所得之地去，好与迦南人争战；以后我也同你到你拈阄所得之地去。”于是西缅与他同去。"},
{volumn:         7,chapter:         1,versenumber:         4,verse:"犹大就上去；耶和华将迦南人和比利洗人交在他们手中。他们在比色击杀了一万人，"},
{volumn:         7,chapter:         1,versenumber:         5,verse:"又在那里遇见亚多尼·比色，与他争战，杀败迦南人和比利洗人。"},
{volumn:         7,chapter:         1,versenumber:         6,verse:"亚多尼·比色逃跑；他们追赶，拿住他，砍断他手脚的大拇指。"},
{volumn:         7,chapter:         1,versenumber:         7,verse:"亚多尼·比色说：“从前有七十个王，手脚的大拇指都被我砍断，在我桌子底下拾取零碎食物。现在　神按着我所行的报应我了。”于是他们将亚多尼·比色带到耶路撒冷，他就死在那里。"},
{volumn:         7,chapter:         1,versenumber:         8,verse:"犹大人攻打耶路撒冷，将城攻取，用刀杀了城内的人，并且放火烧城。"},
{volumn:         7,chapter:         1,versenumber:         9,verse:"后来犹大人下去，与住山地、南地，和高原的迦南人争战。"},
{volumn:         7,chapter:         1,versenumber:        10,verse:"犹大人去攻击住希伯仑的迦南人，杀了示筛、亚希幔、挞买。希伯仑从前名叫基列·亚巴。"},
{volumn:         7,chapter:         1,versenumber:        11,verse:"他们从那里去攻击底壁的居民；底壁从前名叫基列·西弗。"},
{volumn:         7,chapter:         1,versenumber:        12,verse:"迦勒说：“谁能攻打基列·西弗，将城夺取，我就把我女儿押撒给他为妻。”"},
{volumn:         7,chapter:         1,versenumber:        13,verse:"迦勒兄弟基纳斯的儿子俄陀聂夺取了那城，迦勒就把女儿押撒给他为妻。"},
{volumn:         7,chapter:         1,versenumber:        14,verse:"押撒过门的时候，劝丈夫向她父亲求一块田。押撒一下驴，迦勒问她说：“你要什么？”"},
{volumn:         7,chapter:         1,versenumber:        15,verse:"她说：“求你赐福给我，你既将我安置在南地，求你也给我水泉。”迦勒就把上泉下泉赐给她。"},
{volumn:         7,chapter:         1,versenumber:        16,verse:"摩西的内兄（或译：岳父）是基尼人，他的子孙与犹大人一同离了棕树城，往亚拉得以南的犹大旷野去，就住在民中。"},
{volumn:         7,chapter:         1,versenumber:        17,verse:"犹大和他哥哥西缅同去，击杀了住洗法的迦南人，将城尽行毁灭，那城的名便叫何珥玛。"},
{volumn:         7,chapter:         1,versenumber:        18,verse:"犹大又取了迦萨和迦萨的四境，亚实基伦和亚实基伦的四境，以革伦和以革伦的四境。"},
{volumn:         7,chapter:         1,versenumber:        19,verse:"耶和华与犹大同在，犹大就赶出山地的居民，只是不能赶出平原的居民，因为他们有铁车。"},
{volumn:         7,chapter:         1,versenumber:        20,verse:"以色列人照摩西所说的，将希伯仑给了迦勒；迦勒就从那里赶出亚衲族的三个族长。"},
{volumn:         7,chapter:         1,versenumber:        21,verse:"便雅悯人没有赶出住耶路撒冷的耶布斯人。耶布斯人仍在耶路撒冷与便雅悯人同住，直到今日。"},
{volumn:         7,chapter:         1,versenumber:        22,verse:"约瑟家也上去攻打伯特利；耶和华与他们同在。"},
{volumn:         7,chapter:         1,versenumber:        23,verse:"约瑟家打发人去窥探伯特利（那城起先名叫路斯）。"},
{volumn:         7,chapter:         1,versenumber:        24,verse:"窥探的人看见一个人从城里出来，就对他说：“求你将进城的路指示我们，我们必恩待你。”"},
{volumn:         7,chapter:         1,versenumber:        25,verse:"那人将进城的路指示他们，他们就用刀击杀了城中的居民，但将那人和他全家放去。"},
{volumn:         7,chapter:         1,versenumber:        26,verse:"那人往赫人之地去，筑了一座城，起名叫路斯。那城到如今还叫这名。"},
{volumn:         7,chapter:         1,versenumber:        27,verse:"玛拿西没有赶出伯·善和属伯·善乡村的居民，他纳和属他纳乡村的居民，多珥和属多珥乡村的居民，以伯莲和属以伯莲乡村的居民，米吉多和属米吉多乡村的居民；迦南人却执意住在那些地方。"},
{volumn:         7,chapter:         1,versenumber:        28,verse:"及至以色列强盛了，就使迦南人做苦工，没有把他们全然赶出。"},
{volumn:         7,chapter:         1,versenumber:        29,verse:"以法莲没有赶出住基色的迦南人。于是迦南人仍住在基色，在以法莲中间。"},
{volumn:         7,chapter:         1,versenumber:        30,verse:"西布伦没有赶出基伦的居民和拿哈拉的居民。于是迦南人仍住在西布伦中间，成了服苦的人。"},
{volumn:         7,chapter:         1,versenumber:        31,verse:"亚设没有赶出亚柯和西顿的居民，亚黑拉和亚革悉的居民，黑巴、亚弗革与利合的居民。"},
{volumn:         7,chapter:         1,versenumber:        32,verse:"于是，亚设因为没有赶出那地的迦南人，就住在他们中间。"},
{volumn:         7,chapter:         1,versenumber:        33,verse:"拿弗他利没有赶出伯·示麦和伯·亚纳的居民，于是拿弗他利就住在那地的迦南人中间；然而伯·示麦和伯·亚纳的居民成了服苦的人。"},
{volumn:         7,chapter:         1,versenumber:        34,verse:"亚摩利人强逼但人住在山地，不容他们下到平原；"},
{volumn:         7,chapter:         1,versenumber:        35,verse:"亚摩利人却执意住在希烈山和亚雅仑并沙宾。然而约瑟家胜了他们，使他们成了服苦的人。"},
{volumn:         7,chapter:         1,versenumber:        36,verse:"亚摩利人的境界，是从亚克拉滨坡，从西拉而上。"},
{volumn:         7,chapter:         2,versenumber:         1,verse:"耶和华的使者从吉甲上到波金，对以色列人说：“我使你们从埃及上来，领你们到我向你们列祖起誓应许之地。我又说：‘我永不废弃与你们所立的约。"},
{volumn:         7,chapter:         2,versenumber:         2,verse:"你们也不可与这地的居民立约，要拆毁他们的祭坛。你们竟没有听从我的话！为何这样行呢？’"},
{volumn:         7,chapter:         2,versenumber:         3,verse:"因此我又说：‘我必不将他们从你们面前赶出；他们必作你们肋下的荆棘。他们的神必作你们的网罗。’”"},
{volumn:         7,chapter:         2,versenumber:         4,verse:"耶和华的使者向以色列众人说这话的时候，百姓就放声而哭；"},
{volumn:         7,chapter:         2,versenumber:         5,verse:"于是给那地方起名叫波金（就是哭的意思）。众人在那里向耶和华献祭。"},
{volumn:         7,chapter:         2,versenumber:         6,verse:"从前约书亚打发以色列百姓去的时候，他们各归自己的地业，占据地土。"},
{volumn:         7,chapter:         2,versenumber:         7,verse:"约书亚在世和约书亚死后，那些见耶和华为以色列人所行大事的长老还在的时候，百姓都侍奉耶和华。"},
{volumn:         7,chapter:         2,versenumber:         8,verse:"耶和华的仆人、嫩的儿子约书亚，正一百一十岁就死了。"},
{volumn:         7,chapter:         2,versenumber:         9,verse:"以色列人将他葬在他地业的境内，就是在以法莲山地的亭拿·希烈，在迦实山的北边。"},
{volumn:         7,chapter:         2,versenumber:        10,verse:"那世代的人也都归了自己的列祖。后来有别的世代兴起，不知道耶和华，也不知道耶和华为以色列人所行的事。"},
{volumn:         7,chapter:         2,versenumber:        11,verse:"以色列人行耶和华眼中看为恶的事，去侍奉诸巴力，"},
{volumn:         7,chapter:         2,versenumber:        12,verse:"离弃了领他们出埃及地的耶和华他们列祖的　神，去叩拜别神，就是四围列国的神，惹耶和华发怒；"},
{volumn:         7,chapter:         2,versenumber:        13,verse:"并离弃耶和华，去侍奉巴力和亚斯他录。"},
{volumn:         7,chapter:         2,versenumber:        14,verse:"耶和华的怒气向以色列人发作，就把他们交在抢夺他们的人手中，又将他们付与四围仇敌的手中，甚至他们在仇敌面前再不能站立得住。"},
{volumn:         7,chapter:         2,versenumber:        15,verse:"他们无论往何处去，耶和华都以灾祸攻击他们，正如耶和华所说的话，又如耶和华向他们所起的誓；他们便极其困苦。"},
{volumn:         7,chapter:         2,versenumber:        16,verse:"耶和华兴起士师，士师就拯救他们脱离抢夺他们人的手。"},
{volumn:         7,chapter:         2,versenumber:        17,verse:"他们却不听从士师，竟随从叩拜别神，行了邪淫，速速地偏离他们列祖所行的道，不如他们列祖顺从耶和华的命令。"},
{volumn:         7,chapter:         2,versenumber:        18,verse:"耶和华为他们兴起士师，就与那士师同在。士师在世的一切日子，耶和华拯救他们脱离仇敌的手。他们因受欺压扰害，就哀声叹气，所以耶和华后悔了。"},
{volumn:         7,chapter:         2,versenumber:        19,verse:"及至士师死后，他们就转去行恶，比他们列祖更甚，去侍奉叩拜别神，总不断绝顽梗的恶行。"},
{volumn:         7,chapter:         2,versenumber:        20,verse:"于是耶和华的怒气向以色列人发作。他说：“因这民违背我吩咐他们列祖所守的约，不听从我的话，"},
{volumn:         7,chapter:         2,versenumber:        21,verse:"所以约书亚死的时候所剩下的各族，我必不再从他们面前赶出，"},
{volumn:         7,chapter:         2,versenumber:        22,verse:"为要藉此试验以色列人，看他们肯照他们列祖谨守遵行我的道不肯。”"},
{volumn:         7,chapter:         2,versenumber:        23,verse:"这样耶和华留下各族，不将他们速速赶出，也没有交付约书亚的手。"},
{volumn:         7,chapter:         3,versenumber:         1,verse:"耶和华留下这几族，为要试验那不曾知道与迦南争战之事的以色列人，"},
{volumn:         7,chapter:         3,versenumber:         2,verse:"好叫以色列的后代又知道又学习未曾晓得的战事。"},
{volumn:         7,chapter:         3,versenumber:         3,verse:"所留下的就是非利士的五个首领和一切迦南人、西顿人，并住黎巴嫩山的希未人，从巴力·黑们山直到哈马口。"},
{volumn:         7,chapter:         3,versenumber:         4,verse:"留下这几族，为要试验以色列人，知道他们肯听从耶和华藉摩西吩咐他们列祖的诫命不肯。"},
{volumn:         7,chapter:         3,versenumber:         5,verse:"以色列人竟住在迦南人、赫人、亚摩利人、比利洗人、希未人、耶布斯人中间，"},
{volumn:         7,chapter:         3,versenumber:         6,verse:"娶他们的女儿为妻，将自己的女儿嫁给他们的儿子，并侍奉他们的神。"},
{volumn:         7,chapter:         3,versenumber:         7,verse:"以色列人行耶和华眼中看为恶的事，忘记耶和华他们的　神，去侍奉诸巴力和亚舍拉，"},
{volumn:         7,chapter:         3,versenumber:         8,verse:"所以耶和华的怒气向以色列人发作，就把他们交在美索不达米亚王古珊·利萨田的手中。以色列人服侍古珊·利萨田八年。"},
{volumn:         7,chapter:         3,versenumber:         9,verse:"以色列人呼求耶和华的时候，耶和华就为他们兴起一位拯救者救他们，就是迦勒兄弟基纳斯的儿子俄陀聂。"},
{volumn:         7,chapter:         3,versenumber:        10,verse:"耶和华的灵降在他身上，他就作了以色列的士师，出去争战。耶和华将美索不达米亚王古珊·利萨田交在他手中，他便胜了古珊·利萨田。"},
{volumn:         7,chapter:         3,versenumber:        11,verse:"于是国中太平四十年。基纳斯的儿子俄陀聂死了。"},
{volumn:         7,chapter:         3,versenumber:        12,verse:"以色列人又行耶和华眼中看为恶的事，耶和华就使摩押王伊矶伦强盛，攻击以色列人。"},
{volumn:         7,chapter:         3,versenumber:        13,verse:"伊矶伦招聚亚扪人和亚玛力人，去攻打以色列人，占据棕树城。"},
{volumn:         7,chapter:         3,versenumber:        14,verse:"于是以色列人服侍摩押王伊矶伦十八年。"},
{volumn:         7,chapter:         3,versenumber:        15,verse:"以色列人呼求耶和华的时候，耶和华就为他们兴起一位拯救者，就是便雅悯人基拉的儿子以笏；他是左手便利的。以色列人托他送礼物给摩押王伊矶伦。"},
{volumn:         7,chapter:         3,versenumber:        16,verse:"以笏打了一把两刃的剑，长一肘，带在右腿上衣服里面。"},
{volumn:         7,chapter:         3,versenumber:        17,verse:"他将礼物献给摩押王伊矶伦（原来伊矶伦极其肥胖）；"},
{volumn:         7,chapter:         3,versenumber:        18,verse:"以笏献完礼物，便将抬礼物的人打发走了，"},
{volumn:         7,chapter:         3,versenumber:        19,verse:"自己却从靠近吉甲凿石之地回来，说：“王啊，我有一件机密事奏告你。”王说：“回避吧！”于是左右侍立的人都退去了。"},
{volumn:         7,chapter:         3,versenumber:        20,verse:"以笏来到王面前；王独自一人坐在凉楼上。以笏说：“我奉　神的命报告你一件事。”王就从座位上站起来。"},
{volumn:         7,chapter:         3,versenumber:        21,verse:"以笏便伸左手，从右腿上拔出剑来，刺入王的肚腹，"},
{volumn:         7,chapter:         3,versenumber:        22,verse:"连剑把都刺进去了。剑被肥肉夹住，他没有从王的肚腹拔出来，且穿通了后身。"},
{volumn:         7,chapter:         3,versenumber:        23,verse:"以笏就出到游廊，将楼门尽都关锁。"},
{volumn:         7,chapter:         3,versenumber:        24,verse:"以笏出来之后，王的仆人到了，看见楼门关锁，就说：“他必是在楼上大解。”"},
{volumn:         7,chapter:         3,versenumber:        25,verse:"他们等烦了，见仍不开楼门，就拿钥匙开了，不料，他们的主人已死，倒在地上。"},
{volumn:         7,chapter:         3,versenumber:        26,verse:"他们耽延的时候，以笏就逃跑了，经过凿石之地，逃到西伊拉；"},
{volumn:         7,chapter:         3,versenumber:        27,verse:"到了，就在以法莲山地吹角。以色列人随着他下了山地，他在前头引路，"},
{volumn:         7,chapter:         3,versenumber:        28,verse:"对他们说：“你们随我来，因为耶和华已经把你们的仇敌摩押人交在你们手中。”于是他们跟着他下去，把守约旦河的渡口，不容摩押一人过去。"},
{volumn:         7,chapter:         3,versenumber:        29,verse:"那时击杀了摩押人约有一万，都是强壮的勇士，没有一人逃脱。"},
{volumn:         7,chapter:         3,versenumber:        30,verse:"这样，摩押就被以色列人制伏了。国中太平八十年。"},
{volumn:         7,chapter:         3,versenumber:        31,verse:"以笏之后，有亚拿的儿子珊迦，他用赶牛的棍子打死六百非利士人。他也救了以色列人。"},
{volumn:         7,chapter:         4,versenumber:         1,verse:"以笏死后，以色列人又行耶和华眼中看为恶的事，"},
{volumn:         7,chapter:         4,versenumber:         2,verse:"耶和华就把他们付与在夏琐作王的迦南王耶宾手中；他的将军是西西拉，住在外邦人的夏罗设。"},
{volumn:         7,chapter:         4,versenumber:         3,verse:"耶宾王有铁车九百辆。他大大欺压以色列人二十年，以色列人就呼求耶和华。"},
{volumn:         7,chapter:         4,versenumber:         4,verse:"有一位女先知名叫底波拉，是拉比多的妻，当时作以色列的士师。"},
{volumn:         7,chapter:         4,versenumber:         5,verse:"她住在以法莲山地拉玛和伯特利中间，在底波拉的棕树下。以色列人都上她那里去听判断。"},
{volumn:         7,chapter:         4,versenumber:         6,verse:"她打发人从拿弗他利的基低斯将亚比挪庵的儿子巴拉召了来，对他说：“耶和华以色列的　神吩咐你说：‘你率领一万拿弗他利和西布伦人上他泊山去。"},
{volumn:         7,chapter:         4,versenumber:         7,verse:"我必使耶宾的将军西西拉率领他的车辆和全军往基顺河，到你那里去；我必将他交在你手中。’”"},
{volumn:         7,chapter:         4,versenumber:         8,verse:"巴拉说：“你若同我去，我就去；你若不同我去，我就不去。”"},
{volumn:         7,chapter:         4,versenumber:         9,verse:"底波拉说：“我必与你同去，只是你在所行的路上得不着荣耀，因为耶和华要将西西拉交在一个妇人手里。”于是底波拉起来，与巴拉一同往基低斯去了。"},
{volumn:         7,chapter:         4,versenumber:        10,verse:"巴拉就招聚西布伦人和拿弗他利人到基低斯，跟他上去的有一万人。底波拉也同他上去。"},
{volumn:         7,chapter:         4,versenumber:        11,verse:"摩西岳父（或译：内兄）何巴的后裔，基尼人希百曾离开基尼族，到靠近基低斯、撒拿音的橡树旁支搭帐棚。"},
{volumn:         7,chapter:         4,versenumber:        12,verse:"有人告诉西西拉说：“亚比挪庵的儿子巴拉已经上他泊山了。”"},
{volumn:         7,chapter:         4,versenumber:        13,verse:"西西拉就聚集所有的铁车九百辆和跟随他的全军，从外邦人的夏罗设出来，到了基顺河。"},
{volumn:         7,chapter:         4,versenumber:        14,verse:"底波拉对巴拉说：“你起来，今日就是耶和华将西西拉交在你手的日子。耶和华岂不在你前头行吗？”于是巴拉下了他泊山，跟随他有一万人。"},
{volumn:         7,chapter:         4,versenumber:        15,verse:"耶和华使西西拉和他一切车辆全军溃乱，在巴拉面前被刀杀败；西西拉下车步行逃跑。"},
{volumn:         7,chapter:         4,versenumber:        16,verse:"巴拉追赶车辆、军队，直到外邦人的夏罗设。西西拉的全军都倒在刀下，没有留下一人。"},
{volumn:         7,chapter:         4,versenumber:        17,verse:"只有西西拉步行逃跑，到了基尼人希百之妻雅亿的帐棚，因为夏琐王耶宾与基尼人希百家和好。"},
{volumn:         7,chapter:         4,versenumber:        18,verse:"雅亿出来迎接西西拉，对他说：“请我主进来，不要惧怕”；西西拉就进了她的帐棚。雅亿用被将他遮盖。"},
{volumn:         7,chapter:         4,versenumber:        19,verse:"西西拉对雅亿说：“我渴了，求你给我一点水喝。”雅亿就打开皮袋，给他奶子喝，仍旧把他遮盖。"},
{volumn:         7,chapter:         4,versenumber:        20,verse:"西西拉又对雅亿说：“请你站在帐棚门口，若有人来问你说：‘有人在这里没有？’你就说：‘没有。’”"},
{volumn:         7,chapter:         4,versenumber:        21,verse:"西西拉疲乏沉睡。希百的妻雅亿取了帐棚的橛子，手里拿着锤子，轻悄悄地到他旁边，将橛子从他鬓边钉进去，钉入地里。西西拉就死了。"},
{volumn:         7,chapter:         4,versenumber:        22,verse:"巴拉追赶西西拉的时候，雅亿出来迎接他说：“来吧，我将你所寻找的人给你看。”他就进入帐棚，看见西西拉已经死了，倒在地上，橛子还在他鬓中。"},
{volumn:         7,chapter:         4,versenumber:        23,verse:"这样，　神使迦南王耶宾被以色列人制伏了。"},
{volumn:         7,chapter:         4,versenumber:        24,verse:"从此以色列人的手越发有力，胜了迦南王耶宾，直到将他灭绝了。"},
{volumn:         7,chapter:         5,versenumber:         1,verse:"那时，底波拉和亚比挪庵的儿子巴拉作歌，说："},
{volumn:         7,chapter:         5,versenumber:         2,verse:"“因为以色列中有军长率领，百姓也甘心牺牲自己，你们应当颂赞耶和华！"},
{volumn:         7,chapter:         5,versenumber:         3,verse:"“君王啊，要听！王子啊，要侧耳而听！我要向耶和华歌唱；我要歌颂耶和华以色列的　神。"},
{volumn:         7,chapter:         5,versenumber:         4,verse:"“耶和华啊，你从西珥出来，由以东地行走。那时地震天漏，云也落雨。"},
{volumn:         7,chapter:         5,versenumber:         5,verse:"山见耶和华的面就震动，西奈山见耶和华以色列　神的面也是如此。"},
{volumn:         7,chapter:         5,versenumber:         6,verse:"在亚拿之子珊迦的时候，又在雅亿的日子，大道无人行走，都是绕道而行。"},
{volumn:         7,chapter:         5,versenumber:         7,verse:"以色列中的官长停职，直到我底波拉兴起，等我兴起作以色列的母。"},
{volumn:         7,chapter:         5,versenumber:         8,verse:"以色列人选择新　神，争战的事就临到城门。那时，以色列四万人中岂能见盾牌枪矛呢？"},
{volumn:         7,chapter:         5,versenumber:         9,verse:"我心倾向以色列的首领，他们在民中甘心牺牲自己。你们应当颂赞耶和华！"},
{volumn:         7,chapter:         5,versenumber:        10,verse:"骑白驴的、坐绣花毯子的、行路的，你们都当传扬！"},
{volumn:         7,chapter:         5,versenumber:        11,verse:"在远离弓箭响声打水之处，人必述说耶和华公义的作为，就是他治理以色列公义的作为。那时耶和华的民下到城门。"},
{volumn:         7,chapter:         5,versenumber:        12,verse:"“底波拉啊，兴起！兴起！你当兴起，兴起，唱歌。亚比挪庵的儿子巴拉啊，你当奋兴，掳掠你的敌人。"},
{volumn:         7,chapter:         5,versenumber:        13,verse:"那时有余剩的贵胄和百姓一同下来；耶和华降临，为我攻击勇士。"},
{volumn:         7,chapter:         5,versenumber:        14,verse:"有根本在亚玛力人的地，从以法莲下来的；便雅悯在民中跟随你。有掌权的从玛吉下来；有持杖检点民数的从西布伦下来；"},
{volumn:         7,chapter:         5,versenumber:        15,verse:"以萨迦的首领与底波拉同来；以萨迦怎样，巴拉也怎样。众人都跟随巴拉冲下平原；在流便的溪水旁有心中定大志的。"},
{volumn:         7,chapter:         5,versenumber:        16,verse:"你为何坐在羊圈内听群中吹笛的声音呢？在流便的溪水旁有心中设大谋的。"},
{volumn:         7,chapter:         5,versenumber:        17,verse:"基列人安居在约旦河外。但人为何等在船上？亚设人在海口静坐，在港口安居。"},
{volumn:         7,chapter:         5,versenumber:        18,verse:"西布伦人是拚命敢死的；拿弗他利人在田野的高处也是如此。"},
{volumn:         7,chapter:         5,versenumber:        19,verse:"君王都来争战。那时迦南诸王在米吉多水旁的他纳争战，却未得掳掠银钱。"},
{volumn:         7,chapter:         5,versenumber:        20,verse:"星宿从天上争战，从其轨道攻击西西拉。"},
{volumn:         7,chapter:         5,versenumber:        21,verse:"基顺古河把敌人冲没；我的灵啊，应当努力前行。"},
{volumn:         7,chapter:         5,versenumber:        22,verse:"那时壮马驰驱，踢跳，奔腾。"},
{volumn:         7,chapter:         5,versenumber:        23,verse:"耶和华的使者说：‘应当咒诅米罗斯，大大咒诅其中的居民；因为他们不来帮助耶和华，不来帮助耶和华攻击勇士。’"},
{volumn:         7,chapter:         5,versenumber:        24,verse:"“愿基尼人希百的妻雅亿比众妇人多得福气，比住帐棚的妇人更蒙福祉。"},
{volumn:         7,chapter:         5,versenumber:        25,verse:"西西拉求水，雅亿给他奶子，用宝贵的盘子给他奶油。"},
{volumn:         7,chapter:         5,versenumber:        26,verse:"雅亿左手拿着帐棚的橛子，右手拿着匠人的锤子，击打西西拉，打伤他的头，把他的鬓角打破穿通。"},
{volumn:         7,chapter:         5,versenumber:        27,verse:"西西拉在她脚前曲身仆倒，在她脚前曲身倒卧；在那里曲身，就在那里死亡。"},
{volumn:         7,chapter:         5,versenumber:        28,verse:"“西西拉的母亲从窗户里往外观看，从窗棂中呼叫说：‘他的战车为何耽延不来呢？他的车轮为何行得慢呢？’"},
{volumn:         7,chapter:         5,versenumber:        29,verse:"聪明的宫女安慰她（原文是回答她），她也自言自语地说："},
{volumn:         7,chapter:         5,versenumber:        30,verse:"‘他们莫非得财而分？每人得了一两个女子？西西拉得了彩衣为掳物，得绣花的彩衣为掠物。这彩衣两面绣花，乃是披在被掳之人颈项上的。’"},
{volumn:         7,chapter:         5,versenumber:        31,verse:"耶和华啊，愿你的仇敌都这样灭亡！愿爱你的人如日头出现，光辉烈烈！”这样，国中太平四十年。"},
{volumn:         7,chapter:         6,versenumber:         1,verse:"以色列人又行耶和华眼中看为恶的事，耶和华就把他们交在米甸人手里七年。"},
{volumn:         7,chapter:         6,versenumber:         2,verse:"米甸人压制以色列人；以色列人因为米甸人，就在山中挖穴、挖洞、建造营寨。"},
{volumn:         7,chapter:         6,versenumber:         3,verse:"以色列人每逢撒种之后，米甸人、亚玛力人，和东方人都上来攻打他们，"},
{volumn:         7,chapter:         6,versenumber:         4,verse:"对着他们安营，毁坏土产，直到迦萨，没有给以色列人留下食物，牛、羊、驴也没有留下；"},
{volumn:         7,chapter:         6,versenumber:         5,verse:"因为那些人带着牲畜帐棚来，像蝗虫那样多，人和骆驼无数，都进入国内，毁坏全地。"},
{volumn:         7,chapter:         6,versenumber:         6,verse:"以色列人因米甸人的缘故，极其穷乏，就呼求耶和华。"},
{volumn:         7,chapter:         6,versenumber:         7,verse:"以色列人因米甸人的缘故，呼求耶和华，"},
{volumn:         7,chapter:         6,versenumber:         8,verse:"耶和华就差遣先知到以色列人那里，对他们说：“耶和华以色列的　神如此说：‘我曾领你们从埃及上来，出了为奴之家，"},
{volumn:         7,chapter:         6,versenumber:         9,verse:"救你们脱离埃及人的手，并脱离一切欺压你们之人的手，把他们从你们面前赶出，将他们的地赐给你们’；"},
{volumn:         7,chapter:         6,versenumber:        10,verse:"又对你们说：‘我是耶和华你们的　神。你们住在亚摩利人的地，不可敬畏他们的神。你们竟不听从我的话。’”"},
{volumn:         7,chapter:         6,versenumber:        11,verse:"耶和华的使者到了俄弗拉，坐在亚比以谢族人约阿施的橡树下。约阿施的儿子基甸正在酒榨那里打麦子，为要防备米甸人。"},
{volumn:         7,chapter:         6,versenumber:        12,verse:"耶和华的使者向基甸显现，对他说：“大能的勇士啊，耶和华与你同在！”"},
{volumn:         7,chapter:         6,versenumber:        13,verse:"基甸说：“主啊，耶和华若与我们同在，我们何至遭遇这一切事呢？我们的列祖不是向我们说‘耶和华领我们从埃及上来’吗？他那样奇妙的作为在哪里呢？现在他却丢弃我们，将我们交在米甸人手里。”"},
{volumn:         7,chapter:         6,versenumber:        14,verse:"耶和华观看基甸，说：“你靠着你这能力去从米甸人手里拯救以色列人，不是我差遣你去的吗？”"},
{volumn:         7,chapter:         6,versenumber:        15,verse:"基甸说：“主啊，我有何能拯救以色列人呢？我家在玛拿西支派中是至贫穷的。我在我父家是至微小的。”"},
{volumn:         7,chapter:         6,versenumber:        16,verse:"耶和华对他说：“我与你同在，你就必击打米甸人，如击打一人一样。”"},
{volumn:         7,chapter:         6,versenumber:        17,verse:"基甸说：“我若在你眼前蒙恩，求你给我一个证据，使我知道与我说话的就是主。"},
{volumn:         7,chapter:         6,versenumber:        18,verse:"求你不要离开这里，等我归回将礼物带来供在你面前。”主说：“我必等你回来。”"},
{volumn:         7,chapter:         6,versenumber:        19,verse:"基甸去预备了一只山羊羔，用一伊法细面做了无酵饼，将肉放在筐内，把汤盛在壶中，带到橡树下，献在使者面前。"},
{volumn:         7,chapter:         6,versenumber:        20,verse:"神的使者吩咐基甸说：“将肉和无酵饼放在这磐石上，把汤倒出来。”他就这样行了。"},
{volumn:         7,chapter:         6,versenumber:        21,verse:"耶和华的使者伸出手内的杖，杖头挨了肉和无酵饼，就有火从磐石中出来，烧尽了肉和无酵饼。耶和华的使者也就不见了。"},
{volumn:         7,chapter:         6,versenumber:        22,verse:"基甸见他是耶和华的使者，就说：“哀哉！主耶和华啊，我不好了，因为我觌面看见耶和华的使者。”"},
{volumn:         7,chapter:         6,versenumber:        23,verse:"耶和华对他说：“你放心，不要惧怕，你必不至死。”"},
{volumn:         7,chapter:         6,versenumber:        24,verse:"于是基甸在那里为耶和华筑了一座坛，起名叫“耶和华沙龙”（就是耶和华赐平安的意思）。（这坛在亚比以谢族的俄弗拉直到如今。）"},
{volumn:         7,chapter:         6,versenumber:        25,verse:"当那夜，耶和华吩咐基甸说：“你取你父亲的牛来，就是（或译：和）那七岁的第二只牛，并拆毁你父亲为巴力所筑的坛，砍下坛旁的木偶，"},
{volumn:         7,chapter:         6,versenumber:        26,verse:"在这磐石（原文是保障）上整整齐齐地为耶和华你的　神筑一座坛，将第二只牛献为燔祭，用你所砍下的木偶作柴。”"},
{volumn:         7,chapter:         6,versenumber:        27,verse:"基甸就从他仆人中挑了十个人，照着耶和华吩咐他的行了。他因怕父家和本城的人，不敢在白昼行这事，就在夜间行了。"},
{volumn:         7,chapter:         6,versenumber:        28,verse:"城里的人清早起来，见巴力的坛拆毁，坛旁的木偶砍下，第二只牛献在新筑的坛上，"},
{volumn:         7,chapter:         6,versenumber:        29,verse:"就彼此说：“这事是谁做的呢？”他们访查之后，就说：“这是约阿施的儿子基甸做的。”"},
{volumn:         7,chapter:         6,versenumber:        30,verse:"城里的人对约阿施说：“将你儿子交出来，好治死他；因为他拆毁了巴力的坛，砍下坛旁的木偶。”"},
{volumn:         7,chapter:         6,versenumber:        31,verse:"约阿施回答站着攻击他的众人说：“你们是为巴力争论吗？你们要救他吗？谁为他争论，趁早将谁治死！巴力若果是神，有人拆毁他的坛，让他为自己争论吧！”"},
{volumn:         7,chapter:         6,versenumber:        32,verse:"所以当日人称基甸为耶路·巴力，意思说：“他拆毁巴力的坛，让巴力与他争论。”"},
{volumn:         7,chapter:         6,versenumber:        33,verse:"那时，米甸人、亚玛力人，和东方人都聚集过河，在耶斯列平原安营。"},
{volumn:         7,chapter:         6,versenumber:        34,verse:"耶和华的灵降在基甸身上，他就吹角；亚比以谢族都聚集跟随他。"},
{volumn:         7,chapter:         6,versenumber:        35,verse:"他打发人走遍玛拿西地，玛拿西人也聚集跟随他；又打发人去见亚设人、西布伦人、拿弗他利人，他们也都出来与他们会合。"},
{volumn:         7,chapter:         6,versenumber:        36,verse:"基甸对　神说：“你若果照着所说的话，藉我手拯救以色列人，"},
{volumn:         7,chapter:         6,versenumber:        37,verse:"我就把一团羊毛放在禾场上：若单是羊毛上有露水，别的地方都是干的，我就知道你必照着所说的话，藉我手拯救以色列人。”"},
{volumn:         7,chapter:         6,versenumber:        38,verse:"次日早晨基甸起来，见果然是这样；将羊毛挤一挤，从羊毛中拧出满盆的露水来。"},
{volumn:         7,chapter:         6,versenumber:        39,verse:"基甸又对　神说：“求你不要向我发怒，我再说这一次：让我将羊毛再试一次。但愿羊毛是干的，别的地方都有露水。”"},
{volumn:         7,chapter:         6,versenumber:        40,verse:"这夜　神也如此行：独羊毛上是干的，别的地方都有露水。"},
{volumn:         7,chapter:         7,versenumber:         1,verse:"耶路·巴力就是基甸，他和一切跟随的人早晨起来，在哈律泉旁安营。米甸营在他们北边的平原，靠近摩利冈。"},
{volumn:         7,chapter:         7,versenumber:         2,verse:"耶和华对基甸说：“跟随你的人过多，我不能将米甸人交在他们手中，免得以色列人向我夸大，说：‘是我们自己的手救了我们。’"},
{volumn:         7,chapter:         7,versenumber:         3,verse:"现在你要向这些人宣告说：‘凡惧怕胆怯的，可以离开基列山回去。’”于是有二万二千人回去，只剩下一万。"},
{volumn:         7,chapter:         7,versenumber:         4,verse:"耶和华对基甸说：“人还是过多；你要带他们下到水旁，我好在那里为你试试他们。我指点谁说：‘这人可以同你去’，他就可以同你去；我指点谁说：‘这人不可同你去’，他就不可同你去。”"},
{volumn:         7,chapter:         7,versenumber:         5,verse:"基甸就带他们下到水旁。耶和华对基甸说：“凡用舌头舔水，像狗舔的，要使他单站在一处；凡跪下喝水的，也要使他单站在一处。”"},
{volumn:         7,chapter:         7,versenumber:         6,verse:"于是用手捧着舔水的有三百人，其余的都跪下喝水。"},
{volumn:         7,chapter:         7,versenumber:         7,verse:"耶和华对基甸说：“我要用这舔水的三百人拯救你们，将米甸人交在你手中；其余的人都可以各归各处去。”"},
{volumn:         7,chapter:         7,versenumber:         8,verse:"这三百人就带着食物和角；其余的以色列人，基甸都打发他们各归各的帐棚，只留下这三百人。米甸营在他下边的平原里。"},
{volumn:         7,chapter:         7,versenumber:         9,verse:"当那夜，耶和华吩咐基甸说：“起来，下到米甸营里去，因我已将他们交在你手中。"},
{volumn:         7,chapter:         7,versenumber:        10,verse:"倘若你怕下去，就带你的仆人普拉下到那营里去。"},
{volumn:         7,chapter:         7,versenumber:        11,verse:"你必听见他们所说的，然后你就有胆量下去攻营。”于是基甸带着仆人普拉下到营旁。"},
{volumn:         7,chapter:         7,versenumber:        12,verse:"米甸人、亚玛力人，和一切东方人都布散在平原，如同蝗虫那样多。他们的骆驼无数，多如海边的沙。"},
{volumn:         7,chapter:         7,versenumber:        13,verse:"基甸到了，就听见一人将梦告诉同伴说：“我做了一梦，梦见一个大麦饼滚入米甸营中，到了帐幕，将帐幕撞倒，帐幕就翻转倾覆了。”"},
{volumn:         7,chapter:         7,versenumber:        14,verse:"那同伴说：“这不是别的，乃是以色列人约阿施的儿子基甸的刀；　神已将米甸和全军都交在他的手中。”"},
{volumn:         7,chapter:         7,versenumber:        15,verse:"基甸听见这梦和梦的讲解，就敬拜　神，回到以色列营中，说：“起来吧！耶和华已将米甸的军队交在你们手中了。”"},
{volumn:         7,chapter:         7,versenumber:        16,verse:"于是基甸将三百人分作三队，把角和空瓶交在各人手里（瓶内都藏着火把），"},
{volumn:         7,chapter:         7,versenumber:        17,verse:"吩咐他们说：“你们要看我行事：我到了营的旁边怎样行，你们也要怎样行。"},
{volumn:         7,chapter:         7,versenumber:        18,verse:"我和一切跟随我的人吹角的时候，你们也要在营的四围吹角，喊叫说：‘耶和华和基甸的刀！’”"},
{volumn:         7,chapter:         7,versenumber:        19,verse:"基甸和跟随他的一百人，在三更之初才换更的时候，来到营旁，就吹角，打破手中的瓶。"},
{volumn:         7,chapter:         7,versenumber:        20,verse:"三队的人就都吹角，打破瓶子，左手拿着火把，右手拿着角，喊叫说：“耶和华和基甸的刀！”"},
{volumn:         7,chapter:         7,versenumber:        21,verse:"他们在营的四围各站各的地方；全营的人都乱窜。三百人呐喊，使他们逃跑。"},
{volumn:         7,chapter:         7,versenumber:        22,verse:"三百人就吹角，耶和华使全营的人用刀互相击杀，逃到西利拉的伯·哈示他，直逃到靠近他巴的亚伯·米何拉。"},
{volumn:         7,chapter:         7,versenumber:        23,verse:"以色列人就从拿弗他利、亚设，和玛拿西全地聚集来追赶米甸人。"},
{volumn:         7,chapter:         7,versenumber:        24,verse:"基甸打发人走遍以法莲山地，说：“你们下来攻击米甸人，争先把守约旦河的渡口，直到伯·巴拉。”于是以法莲的众人聚集，把守约旦河的渡口，直到伯·巴拉，"},
{volumn:         7,chapter:         7,versenumber:        25,verse:"捉住了米甸人的两个首领：一名俄立，一名西伊伯；将俄立杀在俄立磐石上，将西伊伯杀在西伊伯酒榨那里；又追赶米甸人，将俄立和西伊伯的首级带过约旦河，到基甸那里。"},
{volumn:         7,chapter:         8,versenumber:         1,verse:"以法莲人对基甸说：“你去与米甸人争战，没有招我们同去，为什么这样待我们呢？”他们就与基甸大大地争吵。"},
{volumn:         7,chapter:         8,versenumber:         2,verse:"基甸对他们说：“我所行的岂能比你们所行的呢？以法莲拾取剩下的葡萄不强过亚比以谢所摘的葡萄吗？"},
{volumn:         7,chapter:         8,versenumber:         3,verse:"神已将米甸人的两个首领俄立和西伊伯交在你们手中；我所行的岂能比你们所行的呢？”基甸说了这话，以法莲人的怒气就消了。"},
{volumn:         7,chapter:         8,versenumber:         4,verse:"基甸和跟随他的三百人到约旦河过渡，虽然疲乏，还是追赶。"},
{volumn:         7,chapter:         8,versenumber:         5,verse:"基甸对疏割人说：“求你们拿饼来给跟随我的人吃，因为他们疲乏了；我们追赶米甸人的两个王西巴和撒慕拿。”"},
{volumn:         7,chapter:         8,versenumber:         6,verse:"疏割人的首领回答说：“西巴和撒慕拿已经在你手里，你使我们将饼给你的军兵吗？”"},
{volumn:         7,chapter:         8,versenumber:         7,verse:"基甸说：“耶和华将西巴和撒慕拿交在我手之后，我就用野地的荆条和枳棘打伤你们。”"},
{volumn:         7,chapter:         8,versenumber:         8,verse:"基甸从那里上到毗努伊勒，对那里的人也是这样说；毗努伊勒人也与疏割人回答他的话一样。"},
{volumn:         7,chapter:         8,versenumber:         9,verse:"他向毗努伊勒人说：“我平平安安回来的时候，我必拆毁这楼。”"},
{volumn:         7,chapter:         8,versenumber:        10,verse:"那时西巴和撒慕拿，并跟随他们的军队都在加各，约有一万五千人，就是东方人全军所剩下的；已经被杀约有十二万拿刀的。"},
{volumn:         7,chapter:         8,versenumber:        11,verse:"基甸就由挪巴和约比哈东边，从住帐棚人的路上去，杀败了米甸人的军兵，因为他们坦然无惧。"},
{volumn:         7,chapter:         8,versenumber:        12,verse:"西巴和撒慕拿逃跑；基甸追赶他们，捉住米甸的二王西巴和撒慕拿，惊散全军。"},
{volumn:         7,chapter:         8,versenumber:        13,verse:"约阿施的儿子基甸由希列斯坡从阵上回来，"},
{volumn:         7,chapter:         8,versenumber:        14,verse:"捉住疏割的一个少年人，问他：“疏割的首领长老是谁？”他就将首领长老七十七个人的名字写出来。"},
{volumn:         7,chapter:         8,versenumber:        15,verse:"基甸到了疏割，对那里的人说：“你们从前讥诮我说：‘西巴和撒慕拿已经在你手里，你使我们将饼给跟随你的疲乏人吗？’现在西巴和撒慕拿在这里。”"},
{volumn:         7,chapter:         8,versenumber:        16,verse:"于是捉住那城内的长老，用野地的荆条和枳棘责打（原文是指教）疏割人；"},
{volumn:         7,chapter:         8,versenumber:        17,verse:"又拆了毗努伊勒的楼，杀了那城里的人。"},
{volumn:         7,chapter:         8,versenumber:        18,verse:"基甸问西巴和撒慕拿说：“你们在他泊山所杀的人是什么样式？”回答说：“他们好像你，各人都有王子的样式。”"},
{volumn:         7,chapter:         8,versenumber:        19,verse:"基甸说：“他们是我同母的弟兄，我指着永生的耶和华起誓，你们从前若存留他们的性命，我如今就不杀你们了。”"},
{volumn:         7,chapter:         8,versenumber:        20,verse:"于是对他的长子益帖说：“你起来杀他们。”但益帖因为是童子，害怕，不敢拔刀。"},
{volumn:         7,chapter:         8,versenumber:        21,verse:"西巴和撒慕拿说：“你自己起来杀我们吧！因为人如何，力量也是如何。”基甸就起来，杀了西巴和撒慕拿，夺获他们骆驼项上戴的月牙圈。"},
{volumn:         7,chapter:         8,versenumber:        22,verse:"以色列人对基甸说：“你既救我们脱离米甸人的手，愿你和你的儿孙管理我们。”"},
{volumn:         7,chapter:         8,versenumber:        23,verse:"基甸说：“我不管理你们，我的儿子也不管理你们，惟有耶和华管理你们。”"},
{volumn:         7,chapter:         8,versenumber:        24,verse:"基甸又对他们说：“我有一件事求你们：请你们各人将所夺的耳环给我。”（原来仇敌是以实玛利人，都是戴金耳环的。）"},
{volumn:         7,chapter:         8,versenumber:        25,verse:"他们说：“我们情愿给你”，就铺开一件外衣，各人将所夺的耳环丢在其上。"},
{volumn:         7,chapter:         8,versenumber:        26,verse:"基甸所要出来的金耳环重一千七百舍客勒金子。此外还有米甸王所戴的月环、耳坠，和所穿的紫色衣服，并骆驼项上的金链子。"},
{volumn:         7,chapter:         8,versenumber:        27,verse:"基甸以此制造了一个以弗得，设立在本城俄弗拉。后来以色列人拜那以弗得行了邪淫；这就作了基甸和他全家的网罗。"},
{volumn:         7,chapter:         8,versenumber:        28,verse:"这样，米甸人被以色列人制伏了，不敢再抬头。基甸还在的日子，国中太平四十年。"},
{volumn:         7,chapter:         8,versenumber:        29,verse:"约阿施的儿子耶路·巴力回去，住在自己家里。"},
{volumn:         7,chapter:         8,versenumber:        30,verse:"基甸有七十个亲生的儿子，因为他有许多的妻。"},
{volumn:         7,chapter:         8,versenumber:        31,verse:"他的妾住在示剑，也给他生了一个儿子。基甸与他起名叫亚比米勒。"},
{volumn:         7,chapter:         8,versenumber:        32,verse:"约阿施的儿子基甸，年纪老迈而死，葬在亚比以谢族的俄弗拉，在他父亲约阿施的坟墓里。"},
{volumn:         7,chapter:         8,versenumber:        33,verse:"基甸死后，以色列人又去随从诸巴力行邪淫，以巴力·比利土为他们的神。"},
{volumn:         7,chapter:         8,versenumber:        34,verse:"以色列人不记念耶和华他们的　神，就是拯救他们脱离四围仇敌之手的，"},
{volumn:         7,chapter:         8,versenumber:        35,verse:"也不照着耶路·巴力，就是基甸向他们所施的恩惠厚待他的家。"},
{volumn:         7,chapter:         9,versenumber:         1,verse:"耶路·巴力的儿子亚比米勒到了示剑见他的众母舅，对他们和他外祖全家的人说："},
{volumn:         7,chapter:         9,versenumber:         2,verse:"“请你们问示剑的众人说，是耶路·巴力的众子七十人都管理你们好呢？还是一人管理你们好呢？你们又要记念我是你们的骨肉。”"},
{volumn:         7,chapter:         9,versenumber:         3,verse:"他的众母舅便将这一切话为他说给示剑人听，示剑人的心就归向亚比米勒。他们说：“他原是我们的弟兄”；"},
{volumn:         7,chapter:         9,versenumber:         4,verse:"就从巴力·比利土的庙中取了七十舍客勒银子给亚比米勒；亚比米勒用以雇了些匪徒跟随他。"},
{volumn:         7,chapter:         9,versenumber:         5,verse:"他往俄弗拉到他父亲的家，将他弟兄、耶路·巴力的众子七十人都杀在一块磐石上；只剩下耶路·巴力的小儿子约坦，因为他躲藏了。"},
{volumn:         7,chapter:         9,versenumber:         6,verse:"示剑人和米罗人都一同聚集，往示剑橡树旁的柱子那里，立亚比米勒为王。"},
{volumn:         7,chapter:         9,versenumber:         7,verse:"有人将这事告诉约坦，他就去站在基利心山顶上，向众人大声喊叫说：“示剑人哪，你们要听我的话，　神也就听你们的话。"},
{volumn:         7,chapter:         9,versenumber:         8,verse:"有一时，树木要膏一树为王，管理他们，就去对橄榄树说：‘请你作我们的王。’"},
{volumn:         7,chapter:         9,versenumber:         9,verse:"橄榄树回答说：‘我岂肯止住供奉神和尊重人的油，飘摇在众树之上呢？’"},
{volumn:         7,chapter:         9,versenumber:        10,verse:"树木对无花果树说：‘请你来作我们的王。’"},
{volumn:         7,chapter:         9,versenumber:        11,verse:"无花果树回答说：‘我岂肯止住所结甜美的果子，飘摇在众树之上呢？’"},
{volumn:         7,chapter:         9,versenumber:        12,verse:"树木对葡萄树说：‘请你来作我们的王。’"},
{volumn:         7,chapter:         9,versenumber:        13,verse:"葡萄树回答说：‘我岂肯止住使　神和人喜乐的新酒，飘摇在众树之上呢？’"},
{volumn:         7,chapter:         9,versenumber:        14,verse:"众树对荆棘说：‘请你来作我们的王。’"},
{volumn:         7,chapter:         9,versenumber:        15,verse:"荆棘回答说：‘你们若诚诚实实地膏我为王，就要投在我的荫下；不然，愿火从荆棘里出来，烧灭黎巴嫩的香柏树。’"},
{volumn:         7,chapter:         9,versenumber:        16,verse:"“现在你们立亚比米勒为王，若按诚实正直善待耶路·巴力和他的全家，这就是酬他的劳。"},
{volumn:         7,chapter:         9,versenumber:        17,verse:"从前我父冒死为你们争战，救了你们脱离米甸人的手。"},
{volumn:         7,chapter:         9,versenumber:        18,verse:"你们如今起来攻击我的父家，将他众子七十人杀在一块磐石上，又立他婢女所生的儿子亚比米勒为示剑人的王；他原是你们的弟兄。"},
{volumn:         7,chapter:         9,versenumber:        19,verse:"你们如今若按诚实正直待耶路·巴力和他的家，就可因亚比米勒得欢乐，他也可因你们得欢乐；"},
{volumn:         7,chapter:         9,versenumber:        20,verse:"不然，愿火从亚比米勒发出，烧灭示剑人和米罗众人，又愿火从示剑人和米罗人中出来，烧灭亚比米勒。”"},
{volumn:         7,chapter:         9,versenumber:        21,verse:"约坦因怕他弟兄亚比米勒，就逃跑，来到比珥，住在那里。"},
{volumn:         7,chapter:         9,versenumber:        22,verse:"亚比米勒管理以色列人三年。"},
{volumn:         7,chapter:         9,versenumber:        23,verse:"神使恶魔降在亚比米勒和示剑人中间，示剑人就以诡诈待亚比米勒。"},
{volumn:         7,chapter:         9,versenumber:        24,verse:"这是要叫耶路·巴力七十个儿子所受的残害归与他们的哥哥亚比米勒，又叫那流他们血的罪归与帮助他杀弟兄的示剑人。"},
{volumn:         7,chapter:         9,versenumber:        25,verse:"示剑人在山顶上设埋伏，等候亚比米勒。凡从他们那里经过的人，他们就抢夺。有人将这事告诉亚比米勒。"},
{volumn:         7,chapter:         9,versenumber:        26,verse:"以别的儿子迦勒和他的弟兄来到示剑，示剑人都信靠他。"},
{volumn:         7,chapter:         9,versenumber:        27,verse:"示剑人出城到田间去，摘下葡萄，踹酒，设摆筵宴，进他们神的庙中吃喝，咒诅亚比米勒。"},
{volumn:         7,chapter:         9,versenumber:        28,verse:"以别的儿子迦勒说：“亚比米勒是谁？示剑是谁？使我们服侍他呢？他不是耶路·巴力的儿子吗？他的帮手不是西布勒吗？你们可以服侍示剑的父亲哈抹的后裔。我们为何服侍亚比米勒呢？"},
{volumn:         7,chapter:         9,versenumber:        29,verse:"惟愿这民归我的手下，我就除掉亚比米勒。”迦勒又对亚比米勒说：“增添你的军兵出来吧。”"},
{volumn:         7,chapter:         9,versenumber:        30,verse:"邑宰西布勒听见以别的儿子迦勒的话，就发怒，"},
{volumn:         7,chapter:         9,versenumber:        31,verse:"悄悄地打发人去见亚比米勒，说：“以别的儿子迦勒和他的弟兄到了示剑，煽惑城中的民攻击你。"},
{volumn:         7,chapter:         9,versenumber:        32,verse:"现在你和跟随你的人今夜起来，在田间埋伏。"},
{volumn:         7,chapter:         9,versenumber:        33,verse:"到早晨太阳一出，你就起来闯城。迦勒和跟随他的人出来攻击你的时候，你便向他们见机而做。”"},
{volumn:         7,chapter:         9,versenumber:        34,verse:"于是，亚比米勒和跟随他的众人夜间起来，分作四队，埋伏等候示剑人。"},
{volumn:         7,chapter:         9,versenumber:        35,verse:"以别的儿子迦勒出去，站在城门口。亚比米勒和跟随他的人从埋伏之处起来。"},
{volumn:         7,chapter:         9,versenumber:        36,verse:"迦勒看见那些人，就对西布勒说：“看哪，有人从山顶上下来了。”西布勒说：“你看见山的影子，以为是人。”"},
{volumn:         7,chapter:         9,versenumber:        37,verse:"迦勒又说：“看哪，有人从高处下来，又有一队从米恶尼尼橡树的路上而来。”"},
{volumn:         7,chapter:         9,versenumber:        38,verse:"西布勒对他说：“你曾说，亚比米勒是谁，叫我们服侍他？你所夸的口在哪里呢？这不是你所藐视的民吗？你现在出去，与他们交战吧！”"},
{volumn:         7,chapter:         9,versenumber:        39,verse:"于是迦勒率领示剑人出去，与亚比米勒交战。"},
{volumn:         7,chapter:         9,versenumber:        40,verse:"亚比米勒追赶迦勒；迦勒在他面前逃跑，有许多受伤仆倒的，直到城门。"},
{volumn:         7,chapter:         9,versenumber:        41,verse:"亚比米勒住在亚鲁玛。西布勒赶出迦勒和他弟兄，不准他们住在示剑。"},
{volumn:         7,chapter:         9,versenumber:        42,verse:"次日，民出到田间，有人告诉亚比米勒；"},
{volumn:         7,chapter:         9,versenumber:        43,verse:"他就把他的人分作三队，埋伏在田间，看见示剑人从城里出来，就起来击杀他们。"},
{volumn:         7,chapter:         9,versenumber:        44,verse:"亚比米勒和跟随他的一队向前闯去，站在城门口；那两队直闯到田间，击杀了众人。"},
{volumn:         7,chapter:         9,versenumber:        45,verse:"亚比米勒整天攻打城，将城夺取，杀了其中的居民，将城拆毁，撒上了盐。"},
{volumn:         7,chapter:         9,versenumber:        46,verse:"示剑楼的人听见了，就躲入巴力·比利土庙的卫所。"},
{volumn:         7,chapter:         9,versenumber:        47,verse:"有人告诉亚比米勒说：“示剑楼的人都聚在一处。”"},
{volumn:         7,chapter:         9,versenumber:        48,verse:"亚比米勒和跟随他的人就都上撒们山。亚比米勒手拿斧子，砍下一根树枝，扛在肩上，对跟随他的人说：“你们看我所行的，也当赶紧照样行。”"},
{volumn:         7,chapter:         9,versenumber:        49,verse:"众人就各砍一枝，跟随亚比米勒，把树枝堆在卫所的四围，放火烧了卫所，以致示剑楼的人都死了，男女约有一千。"},
{volumn:         7,chapter:         9,versenumber:        50,verse:"亚比米勒到提备斯，向提备斯安营，就攻取了那城。"},
{volumn:         7,chapter:         9,versenumber:        51,verse:"城中有一座坚固的楼；城里的众人，无论男女，都逃进楼去，关上门，上了楼顶。"},
{volumn:         7,chapter:         9,versenumber:        52,verse:"亚比米勒到了楼前攻打，挨近楼门，要用火焚烧。"},
{volumn:         7,chapter:         9,versenumber:        53,verse:"有一个妇人把一块上磨石抛在亚比米勒的头上，打破了他的脑骨。"},
{volumn:         7,chapter:         9,versenumber:        54,verse:"他就急忙喊叫拿他兵器的少年人，对他说：“拔出你的刀来，杀了我吧！免得人议论我说，他为一个妇人所杀。”于是少年人把他刺透，他就死了。"},
{volumn:         7,chapter:         9,versenumber:        55,verse:"以色列人见亚比米勒死了，便各回自己的地方去了。"},
{volumn:         7,chapter:         9,versenumber:        56,verse:"这样，　神报应亚比米勒向他父亲所行的恶，就是杀了弟兄七十个人的恶。"},
{volumn:         7,chapter:         9,versenumber:        57,verse:"示剑人的一切恶，　神也都报应在他们头上；耶路·巴力的儿子约坦的咒诅归到他们身上了。"},
{volumn:         7,chapter:        10,versenumber:         1,verse:"亚比米勒以后，有以萨迦人朵多的孙子、普瓦的儿子陀拉兴起，拯救以色列人。他住在以法莲山地的沙密。"},
{volumn:         7,chapter:        10,versenumber:         2,verse:"陀拉作以色列的士师二十三年，就死了，葬在沙密。"},
{volumn:         7,chapter:        10,versenumber:         3,verse:"在他以后有基列人睚珥兴起，作以色列的士师二十二年。"},
{volumn:         7,chapter:        10,versenumber:         4,verse:"他有三十个儿子，骑着三十匹驴驹。他们有三十座城邑，叫作哈倭特·睚珥，直到如今，都是在基列地。"},
{volumn:         7,chapter:        10,versenumber:         5,verse:"睚珥死了，就葬在加们。"},
{volumn:         7,chapter:        10,versenumber:         6,verse:"以色列人又行耶和华眼中看为恶的事，去侍奉诸巴力和亚斯他录，并亚兰的神、西顿的神、摩押的神、亚扪人的神、非利士人的神，离弃耶和华，不侍奉他。"},
{volumn:         7,chapter:        10,versenumber:         7,verse:"耶和华的怒气向以色列人发作，就把他们交在非利士人和亚扪人的手中。"},
{volumn:         7,chapter:        10,versenumber:         8,verse:"从那年起，他们扰害欺压约旦河那边、住亚摩利人之基列地的以色列人，共有十八年。"},
{volumn:         7,chapter:        10,versenumber:         9,verse:"亚扪人又渡过约旦河去攻打犹大和便雅悯，并以法莲族。以色列人就甚觉窘迫。"},
{volumn:         7,chapter:        10,versenumber:        10,verse:"以色列人哀求耶和华说：“我们得罪了你；因为离弃了我们　神，去侍奉诸巴力。”"},
{volumn:         7,chapter:        10,versenumber:        11,verse:"耶和华对以色列人说：“我岂没有救过你们脱离埃及人、亚摩利人、亚扪人，和非利士人吗？"},
{volumn:         7,chapter:        10,versenumber:        12,verse:"西顿人、亚玛力人、马云人也都欺压你们；你们哀求我，我也拯救你们脱离他们的手。"},
{volumn:         7,chapter:        10,versenumber:        13,verse:"你们竟离弃我，侍奉别神！所以我不再救你们了。"},
{volumn:         7,chapter:        10,versenumber:        14,verse:"你们去哀求所选择的神；你们遭遇急难的时候，让他救你们吧！”"},
{volumn:         7,chapter:        10,versenumber:        15,verse:"以色列人对耶和华说：“我们犯罪了，任凭你随意待我们吧！只求你今日拯救我们。”"},
{volumn:         7,chapter:        10,versenumber:        16,verse:"以色列人就除掉他们中间的外邦神，侍奉耶和华。耶和华因以色列人受的苦难，就心中担忧。"},
{volumn:         7,chapter:        10,versenumber:        17,verse:"当时亚扪人聚集，安营在基列。以色列人也聚集，安营在米斯巴。"},
{volumn:         7,chapter:        10,versenumber:        18,verse:"基列的民和众首领彼此商议说：“谁能先去攻打亚扪人，谁必作基列一切居民的领袖。”"},
{volumn:         7,chapter:        11,versenumber:         1,verse:"基列人耶弗他是个大能的勇士，是妓女的儿子。耶弗他是基列所生的。"},
{volumn:         7,chapter:        11,versenumber:         2,verse:"基列的妻也生了几个儿子：他妻所生的儿子长大了，就赶逐耶弗他，说：“你不可在我们父家承受产业，因为你是妓女的儿子。”"},
{volumn:         7,chapter:        11,versenumber:         3,verse:"耶弗他就逃避他的弟兄，去住在陀伯地，有些匪徒到他那里聚集，与他一同出入。"},
{volumn:         7,chapter:        11,versenumber:         4,verse:"过了些日子，亚扪人攻打以色列。"},
{volumn:         7,chapter:        11,versenumber:         5,verse:"亚扪人攻打以色列的时候，基列的长老到陀伯地去，要叫耶弗他回来；"},
{volumn:         7,chapter:        11,versenumber:         6,verse:"对耶弗他说：“请你来作我们的元帅，我们好与亚扪人争战。”"},
{volumn:         7,chapter:        11,versenumber:         7,verse:"耶弗他回答基列的长老说：“从前你们不是恨我、赶逐我出离父家吗？现在你们遭遇急难为何到我这里来呢？”"},
{volumn:         7,chapter:        11,versenumber:         8,verse:"基列的长老回答耶弗他说：“现在我们到你这里来，是要你同我们去，与亚扪人争战；你可以作基列一切居民的领袖。”"},
{volumn:         7,chapter:        11,versenumber:         9,verse:"耶弗他对基列的长老说：“你们叫我回去，与亚扪人争战，耶和华把他交给我，我可以作你们的领袖吗？”"},
{volumn:         7,chapter:        11,versenumber:        10,verse:"基列的长老回答耶弗他说：“有耶和华在你我中间作见证，我们必定照你的话行。”"},
{volumn:         7,chapter:        11,versenumber:        11,verse:"于是耶弗他同基列的长老回去，百姓就立耶弗他作领袖、作元帅。耶弗他在米斯巴将自己的一切话陈明在耶和华面前。"},
{volumn:         7,chapter:        11,versenumber:        12,verse:"耶弗他打发使者去见亚扪人的王，说：“你与我有什么相干，竟来到我国中攻打我呢？”"},
{volumn:         7,chapter:        11,versenumber:        13,verse:"亚扪人的王回答耶弗他的使者说：“因为以色列人从埃及上来的时候占据我的地，从亚嫩河到雅博河，直到约旦河。现在你要好好地将这地归还吧！”"},
{volumn:         7,chapter:        11,versenumber:        14,verse:"耶弗他又打发使者去见亚扪人的王，"},
{volumn:         7,chapter:        11,versenumber:        15,verse:"对他说：“耶弗他如此说，以色列人并没有占据摩押地和亚扪人的地。"},
{volumn:         7,chapter:        11,versenumber:        16,verse:"以色列人从埃及上来乃是经过旷野到红海，来到加低斯，"},
{volumn:         7,chapter:        11,versenumber:        17,verse:"就打发使者去见以东王，说：‘求你容我从你的地经过。’以东王却不应允。又照样打发使者去见摩押王，他也不允准。以色列人就住在加低斯。"},
{volumn:         7,chapter:        11,versenumber:        18,verse:"他们又经过旷野，绕着以东和摩押地，从摩押地的东边过来，在亚嫩河边安营，并没有入摩押的境内，因为亚嫩河是摩押的边界。"},
{volumn:         7,chapter:        11,versenumber:        19,verse:"以色列人打发使者去见亚摩利王西宏，就是希实本的王，对他说：‘求你容我们从你的地经过，往我们自己的地方去。’"},
{volumn:         7,chapter:        11,versenumber:        20,verse:"西宏却不信服以色列人，不容他们经过他的境界，乃招聚他的众民在雅杂安营，与以色列人争战。"},
{volumn:         7,chapter:        11,versenumber:        21,verse:"耶和华以色列的　神将西宏和他的众民都交在以色列人手中，以色列人就击杀他们，得了亚摩利人的全地："},
{volumn:         7,chapter:        11,versenumber:        22,verse:"从亚嫩河到雅博河，从旷野直到约旦河。"},
{volumn:         7,chapter:        11,versenumber:        23,verse:"耶和华以色列的　神在他百姓以色列面前赶出亚摩利人，你竟要得他们的地吗？"},
{volumn:         7,chapter:        11,versenumber:        24,verse:"你的神基抹所赐你的地你不是得为业吗？耶和华我们的　神在我们面前所赶出的人，我们就得他的地。"},
{volumn:         7,chapter:        11,versenumber:        25,verse:"难道你比摩押王西拨的儿子巴勒还强吗？他曾与以色列人争竞，或是与他们争战吗？"},
{volumn:         7,chapter:        11,versenumber:        26,verse:"以色列人住希实本和属希实本的乡村，亚罗珥和属亚罗珥的乡村，并沿亚嫩河的一切城邑，已经有三百年了；在这三百年之内，你们为什么没有取回这些地方呢？"},
{volumn:         7,chapter:        11,versenumber:        27,verse:"原来我没有得罪你，你却攻打我，恶待我。愿审判人的耶和华今日在以色列人和亚扪人中间判断是非。”"},
{volumn:         7,chapter:        11,versenumber:        28,verse:"但亚扪人的王不肯听耶弗他打发人说的话。"},
{volumn:         7,chapter:        11,versenumber:        29,verse:"耶和华的灵降在耶弗他身上，他就经过基列和玛拿西，来到基列的米斯巴，又从米斯巴来到亚扪人那里。"},
{volumn:         7,chapter:        11,versenumber:        30,verse:"耶弗他就向耶和华许愿，说：“你若将亚扪人交在我手中，"},
{volumn:         7,chapter:        11,versenumber:        31,verse:"我从亚扪人那里平平安安回来的时候，无论什么人，先从我家门出来迎接我，就必归你，我也必将他献上为燔祭。”"},
{volumn:         7,chapter:        11,versenumber:        32,verse:"于是耶弗他往亚扪人那里去，与他们争战；耶和华将他们交在他手中，"},
{volumn:         7,chapter:        11,versenumber:        33,verse:"他就大大杀败他们，从亚罗珥到米匿，直到亚备勒·基拉明，攻取了二十座城。这样亚扪人就被以色列人制伏了。"},
{volumn:         7,chapter:        11,versenumber:        34,verse:"耶弗他回米斯巴到了自己的家，不料，他女儿拿着鼓跳舞出来迎接他，是他独生的，此外无儿无女。"},
{volumn:         7,chapter:        11,versenumber:        35,verse:"耶弗他看见她，就撕裂衣服，说：“哀哉！我的女儿啊，你使我甚是愁苦，叫我作难了；因为我已经向耶和华开口许愿，不能挽回。”"},
{volumn:         7,chapter:        11,versenumber:        36,verse:"他女儿回答说：“父啊，你既向耶和华开口，就当照你口中所说的向我行，因耶和华已经在仇敌亚扪人身上为你报仇”；"},
{volumn:         7,chapter:        11,versenumber:        37,verse:"又对父亲说：“有一件事求你允准：容我去两个月，与同伴在山上，好哀哭我终为处女。”"},
{volumn:         7,chapter:        11,versenumber:        38,verse:"耶弗他说：“你去吧！”就容她去两个月。她便和同伴去了，在山上为她终为处女哀哭。"},
{volumn:         7,chapter:        11,versenumber:        39,verse:"两月已满，她回到父亲那里，父亲就照所许的愿向她行了。女儿终身没有亲近男子。"},
{volumn:         7,chapter:        11,versenumber:        40,verse:"此后以色列中有个规矩，每年以色列的女子去为基列人耶弗他的女儿哀哭四天。"},
{volumn:         7,chapter:        12,versenumber:         1,verse:"以法莲人聚集，到了北方，对耶弗他说：“你去与亚扪人争战，为什么没有招我们同去呢？我们必用火烧你和你的房屋。”"},
{volumn:         7,chapter:        12,versenumber:         2,verse:"耶弗他对他们说：“我和我的民与亚扪人大大争战；我招你们来，你们竟没有来救我脱离他们的手。"},
{volumn:         7,chapter:        12,versenumber:         3,verse:"我见你们不来救我，我就拚命前去攻击亚扪人，耶和华将他们交在我手中。你们今日为什么上我这里来攻打我呢？”"},
{volumn:         7,chapter:        12,versenumber:         4,verse:"于是耶弗他招聚基列人，与以法莲人争战。基列人击杀以法莲人，是因他们说：“你们基列人在以法莲、玛拿西中间，不过是以法莲逃亡的人。”"},
{volumn:         7,chapter:        12,versenumber:         5,verse:"基列人把守约旦河的渡口，不容以法莲人过去。以法莲逃走的人若说：“容我过去。”基列人就问他说：“你是以法莲人不是？”他若说：“不是”，"},
{volumn:         7,chapter:        12,versenumber:         6,verse:"就对他说：“你说‘示播列’。”以法莲人因为咬不真字音，便说“西播列”。基列人就将他拿住，杀在约旦河的渡口。那时以法莲人被杀的有四万二千人。"},
{volumn:         7,chapter:        12,versenumber:         7,verse:"耶弗他作以色列的士师六年。基列人耶弗他死了，葬在基列的一座城里。"},
{volumn:         7,chapter:        12,versenumber:         8,verse:"耶弗他以后，有伯利恒人以比赞作以色列的士师。"},
{volumn:         7,chapter:        12,versenumber:         9,verse:"他有三十个儿子，三十个女儿；女儿都嫁出去了。他给众子从外乡娶了三十个媳妇。他作以色列的士师七年。"},
{volumn:         7,chapter:        12,versenumber:        10,verse:"以比赞死了，葬在伯利恒。"},
{volumn:         7,chapter:        12,versenumber:        11,verse:"以比赞之后，有西布伦人以伦，作以色列的士师十年。"},
{volumn:         7,chapter:        12,versenumber:        12,verse:"西布伦人以伦死了，葬在西布伦地的亚雅仑。"},
{volumn:         7,chapter:        12,versenumber:        13,verse:"以伦之后，有比拉顿人希列的儿子押顿作以色列的士师。"},
{volumn:         7,chapter:        12,versenumber:        14,verse:"他有四十个儿子，三十个孙子，骑着七十匹驴驹。押顿作以色列的士师八年。"},
{volumn:         7,chapter:        12,versenumber:        15,verse:"比拉顿人希列的儿子押顿死了，葬在以法莲地的比拉顿，在亚玛力人的山地。"},
{volumn:         7,chapter:        13,versenumber:         1,verse:"以色列人又行耶和华眼中看为恶的事，耶和华将他们交在非利士人手中四十年。"},
{volumn:         7,chapter:        13,versenumber:         2,verse:"那时，有一个琐拉人，是属但族的，名叫玛挪亚。他的妻不怀孕，不生育。"},
{volumn:         7,chapter:        13,versenumber:         3,verse:"耶和华的使者向那妇人显现，对她说：“向来你不怀孕，不生育，如今你必怀孕生一个儿子。"},
{volumn:         7,chapter:        13,versenumber:         4,verse:"所以你当谨慎，清酒浓酒都不可喝，一切不洁之物也不可吃。"},
{volumn:         7,chapter:        13,versenumber:         5,verse:"你必怀孕生一个儿子，不可用剃头刀剃他的头，因为这孩子一出胎就归　神作拿细耳人。他必起首拯救以色列人脱离非利士人的手。”"},
{volumn:         7,chapter:        13,versenumber:         6,verse:"妇人就回去对丈夫说：“有一个神人到我面前来，他的相貌如　神使者的相貌，甚是可畏。我没有问他从哪里来，他也没有将他的名告诉我，"},
{volumn:         7,chapter:        13,versenumber:         7,verse:"却对我说：‘你要怀孕生一个儿子，所以清酒浓酒都不可喝，一切不洁之物也不可吃；因为这孩子从出胎一直到死，必归　神作拿细耳人。’”"},
{volumn:         7,chapter:        13,versenumber:         8,verse:"玛挪亚就祈求耶和华说：“主啊，求你再差遣那神人到我们这里来，好指教我们怎样待这将要生的孩子。”"},
{volumn:         7,chapter:        13,versenumber:         9,verse:"神应允玛挪亚的话；妇人正坐在田间的时候，　神的使者又到她那里，她丈夫玛挪亚却没有同她在一处。"},
{volumn:         7,chapter:        13,versenumber:        10,verse:"妇人急忙跑去告诉丈夫说：“那日到我面前来的人，又向我显现。”"},
{volumn:         7,chapter:        13,versenumber:        11,verse:"玛挪亚起来跟随他的妻来到那人面前，对他说：“与这妇人说话的就是你吗？”他说：“是我。”"},
{volumn:         7,chapter:        13,versenumber:        12,verse:"玛挪亚说：“愿你的话应验！我们当怎样待这孩子，他后来当怎样呢？”"},
{volumn:         7,chapter:        13,versenumber:        13,verse:"耶和华的使者对玛挪亚说：“我告诉妇人的一切事，她都当谨慎。"},
{volumn:         7,chapter:        13,versenumber:        14,verse:"葡萄树所结的都不可吃，清酒浓酒都不可喝，一切不洁之物也不可吃。凡我所吩咐的，她都当遵守。”"},
{volumn:         7,chapter:        13,versenumber:        15,verse:"玛挪亚对耶和华的使者说：“求你容我们款留你，好为你预备一只山羊羔。”"},
{volumn:         7,chapter:        13,versenumber:        16,verse:"耶和华的使者对玛挪亚说：“你虽然款留我，我却不吃你的食物，你若预备燔祭就当献与耶和华。”原来玛挪亚不知道他是耶和华的使者。"},
{volumn:         7,chapter:        13,versenumber:        17,verse:"玛挪亚对耶和华的使者说：“请将你的名告诉我，到你话应验的时候，我们好尊敬你。”"},
{volumn:         7,chapter:        13,versenumber:        18,verse:"耶和华的使者对他说：“你何必问我的名，我名是奇妙的。”"},
{volumn:         7,chapter:        13,versenumber:        19,verse:"玛挪亚将一只山羊羔和素祭在磐石上献与耶和华，使者行奇妙的事；玛挪亚和他的妻观看，"},
{volumn:         7,chapter:        13,versenumber:        20,verse:"见火焰从坛上往上升，耶和华的使者在坛上的火焰中也升上去了。玛挪亚和他的妻看见，就俯伏于地。"},
{volumn:         7,chapter:        13,versenumber:        21,verse:"耶和华的使者不再向玛挪亚和他的妻显现，玛挪亚才知道他是耶和华的使者。"},
{volumn:         7,chapter:        13,versenumber:        22,verse:"玛挪亚对他的妻说：“我们必要死，因为看见了　神。”"},
{volumn:         7,chapter:        13,versenumber:        23,verse:"他的妻却对他说：“耶和华若要杀我们，必不从我们手里收纳燔祭和素祭，并不将这一切事指示我们，今日也不将这些话告诉我们。”"},
{volumn:         7,chapter:        13,versenumber:        24,verse:"后来妇人生了一个儿子，给他起名叫参孙。孩子长大，耶和华赐福与他。"},
{volumn:         7,chapter:        13,versenumber:        25,verse:"在玛哈尼·但，就是琐拉和以实陶中间，耶和华的灵才感动他。"},
{volumn:         7,chapter:        14,versenumber:         1,verse:"参孙下到亭拿，在那里看见一个女子，是非利士人的女儿。"},
{volumn:         7,chapter:        14,versenumber:         2,verse:"参孙上来禀告他父母说：“我在亭拿看见一个女子，是非利士人的女儿，愿你们给我娶来为妻。”"},
{volumn:         7,chapter:        14,versenumber:         3,verse:"他父母说：“在你弟兄的女儿中，或在本国的民中，岂没有一个女子，何至你去在未受割礼的非利士人中娶妻呢？”参孙对他父亲说：“愿你给我娶那女子，因我喜悦她。”"},
{volumn:         7,chapter:        14,versenumber:         4,verse:"他的父母却不知道这事是出于耶和华，因为他找机会攻击非利士人。那时，非利士人辖制以色列人。"},
{volumn:         7,chapter:        14,versenumber:         5,verse:"参孙跟他父母下亭拿去，到了亭拿的葡萄园，见有一只少壮狮子向他吼叫。"},
{volumn:         7,chapter:        14,versenumber:         6,verse:"耶和华的灵大大感动参孙，他虽然手无器械，却将狮子撕裂，如同撕裂山羊羔一样。他行这事并没有告诉父母。"},
{volumn:         7,chapter:        14,versenumber:         7,verse:"参孙下去与女子说话，就喜悦她；"},
{volumn:         7,chapter:        14,versenumber:         8,verse:"过了些日子，再下去要娶那女子，转向道旁要看死狮，见有一群蜂子和蜜在死狮之内，"},
{volumn:         7,chapter:        14,versenumber:         9,verse:"就用手取蜜，且吃且走；到了父母那里，给他父母，他们也吃了；只是没有告诉这蜜是从死狮之内取来的。"},
{volumn:         7,chapter:        14,versenumber:        10,verse:"他父亲下去见女子。参孙在那里设摆筵宴，因为向来少年人都有这个规矩。"},
{volumn:         7,chapter:        14,versenumber:        11,verse:"众人看见参孙，就请了三十个人陪伴他。"},
{volumn:         7,chapter:        14,versenumber:        12,verse:"参孙对他们说：“我给你们出一个谜语，你们在七日筵宴之内，若能猜出意思告诉我，我就给你们三十件里衣，三十套衣裳；"},
{volumn:         7,chapter:        14,versenumber:        13,verse:"你们若不能猜出意思告诉我，你们就给我三十件里衣，三十套衣裳。”他们说：“请将谜语说给我们听。”"},
{volumn:         7,chapter:        14,versenumber:        14,verse:"参孙对他们说：“吃的从吃者出来；甜的从强者出来。”他们三日不能猜出谜语的意思。"},
{volumn:         7,chapter:        14,versenumber:        15,verse:"到第七天，他们对参孙的妻说：“你诓哄你丈夫，探出谜语的意思告诉我们，免得我们用火烧你和你父家。你们请了我们来，是要夺我们所有的吗？”"},
{volumn:         7,chapter:        14,versenumber:        16,verse:"参孙的妻在丈夫面前啼哭说：“你是恨我，不是爱我，你给我本国的人出谜语，却没有将意思告诉我。”参孙回答说：“连我父母我都没有告诉，岂可告诉你呢？”"},
{volumn:         7,chapter:        14,versenumber:        17,verse:"七日筵宴之内，她在丈夫面前啼哭，到第七天逼着他，他才将谜语的意思告诉他妻，他妻就告诉本国的人。"},
{volumn:         7,chapter:        14,versenumber:        18,verse:"到第七天，日头未落以前，那城里的人对参孙说：“有什么比蜜还甜呢？有什么比狮子还强呢？”参孙对他们说：“你们若非用我的母牛犊耕地，就猜不出我谜语的意思来。”"},
{volumn:         7,chapter:        14,versenumber:        19,verse:"耶和华的灵大大感动参孙，他就下到亚实基伦，击杀了三十个人，夺了他们的衣裳，将衣裳给了猜出谜语的人。参孙发怒，就上父家去了。"},
{volumn:         7,chapter:        14,versenumber:        20,verse:"参孙的妻便归了参孙的陪伴，就是作过他朋友的。"},
{volumn:         7,chapter:        15,versenumber:         1,verse:"过了些日子，到割麦子的时候，参孙带着一只山羊羔去看他的妻，说：“我要进内室见我的妻。”他岳父不容他进去，"},
{volumn:         7,chapter:        15,versenumber:         2,verse:"说：“我估定你是极其恨她，因此我将她给了你的陪伴。她的妹子不是比她还美丽吗？你可以娶来代替她吧！”"},
{volumn:         7,chapter:        15,versenumber:         3,verse:"参孙说：“这回我加害于非利士人不算有罪。”"},
{volumn:         7,chapter:        15,versenumber:         4,verse:"于是参孙去捉了三百只狐狸（或译：野狗），将狐狸尾巴一对一对地捆上，将火把捆在两条尾巴中间，"},
{volumn:         7,chapter:        15,versenumber:         5,verse:"点着火把，就放狐狸进入非利士人站着的禾稼，将堆集的禾捆和未割的禾稼，并橄榄园尽都烧了。"},
{volumn:         7,chapter:        15,versenumber:         6,verse:"非利士人说：“这事是谁做的呢？”有人说：“是亭拿人的女婿参孙，因为他岳父将他的妻给了他的陪伴。”于是非利士人上去，用火烧了妇人和她的父亲。"},
{volumn:         7,chapter:        15,versenumber:         7,verse:"参孙对非利士人说：“你们既然这样行，我必向你们报仇才肯罢休。”"},
{volumn:         7,chapter:        15,versenumber:         8,verse:"参孙就大大击杀他们，连腿带腰都砍断了。他便下去，住在以坦磐的穴内。"},
{volumn:         7,chapter:        15,versenumber:         9,verse:"非利士人上去安营在犹大，布散在利希。"},
{volumn:         7,chapter:        15,versenumber:        10,verse:"犹大人说：“你们为何上来攻击我们呢？”他们说：“我们上来是要捆绑参孙；他向我们怎样行，我们也要向他怎样行。”"},
{volumn:         7,chapter:        15,versenumber:        11,verse:"于是有三千犹大人下到以坦磐的穴内，对参孙说：“非利士人辖制我们，你不知道吗？你向我们行的是什么事呢？”他回答说：“他们向我怎样行，我也要向他们怎样行。”"},
{volumn:         7,chapter:        15,versenumber:        12,verse:"犹大人对他说：“我们下来是要捆绑你，将你交在非利士人手中。”参孙说：“你们要向我起誓，应承你们自己不害死我。”"},
{volumn:         7,chapter:        15,versenumber:        13,verse:"他们说：“我们断不杀你，只要将你捆绑交在非利士人手中。”于是用两条新绳捆绑参孙，将他从以坦磐带上去。"},
{volumn:         7,chapter:        15,versenumber:        14,verse:"参孙到了利希，非利士人都迎着喧嚷。耶和华的灵大大感动参孙，他臂上的绳就像火烧的麻一样，他的绑绳都从他手上脱落下来。"},
{volumn:         7,chapter:        15,versenumber:        15,verse:"他见一块未干的驴腮骨，就伸手拾起来，用以击杀一千人。"},
{volumn:         7,chapter:        15,versenumber:        16,verse:"参孙说：“我用驴腮骨杀人成堆，用驴腮骨杀了一千人。”"},
{volumn:         7,chapter:        15,versenumber:        17,verse:"说完这话，就把那腮骨从手里抛出去了。那地便叫拉末·利希。"},
{volumn:         7,chapter:        15,versenumber:        18,verse:"参孙甚觉口渴，就求告耶和华说：“你既藉仆人的手施行这么大的拯救，岂可任我渴死、落在未受割礼的人手中呢？”"},
{volumn:         7,chapter:        15,versenumber:        19,verse:"神就使利希的洼处裂开，有水从其中涌出来。参孙喝了，精神复原；因此那泉名叫隐·哈歌利，那泉直到今日还在利希。"},
{volumn:         7,chapter:        15,versenumber:        20,verse:"当非利士人辖制以色列人的时候，参孙作以色列的士师二十年。"},
{volumn:         7,chapter:        16,versenumber:         1,verse:"参孙到了迦萨，在那里看见一个妓女，就与她亲近。"},
{volumn:         7,chapter:        16,versenumber:         2,verse:"有人告诉迦萨人说：“参孙到这里来了！”他们就把他团团围住，终夜在城门悄悄埋伏，说：“等到天亮我们便杀他。”"},
{volumn:         7,chapter:        16,versenumber:         3,verse:"参孙睡到半夜，起来，将城门的门扇、门框、门闩，一齐拆下来，扛在肩上，扛到希伯仑前的山顶上。"},
{volumn:         7,chapter:        16,versenumber:         4,verse:"后来，参孙在梭烈谷喜爱一个妇人，名叫大利拉。"},
{volumn:         7,chapter:        16,versenumber:         5,verse:"非利士人的首领上去见那妇人，对她说：“求你诓哄参孙，探探他因何有这么大的力气，我们用何法能胜他，捆绑克制他。我们就每人给你一千一百舍客勒银子。”"},
{volumn:         7,chapter:        16,versenumber:         6,verse:"大利拉对参孙说：“求你告诉我，你因何有这么大的力气，当用何法捆绑克制你。”"},
{volumn:         7,chapter:        16,versenumber:         7,verse:"参孙回答说：“人若用七条未干的青绳子捆绑我，我就软弱像别人一样。”"},
{volumn:         7,chapter:        16,versenumber:         8,verse:"于是非利士人的首领拿了七条未干的青绳子来，交给妇人，她就用绳子捆绑参孙。"},
{volumn:         7,chapter:        16,versenumber:         9,verse:"有人预先埋伏在妇人的内室里。妇人说：“参孙哪，非利士人拿你来了！”参孙就挣断绳子，如挣断经火的麻线一般。这样，他力气的根由人还是不知道。"},
{volumn:         7,chapter:        16,versenumber:        10,verse:"大利拉对参孙说：“你欺哄我，向我说谎言。现在求你告诉我当用何法捆绑你。”"},
{volumn:         7,chapter:        16,versenumber:        11,verse:"参孙回答说：“人若用没有使过的新绳捆绑我，我就软弱像别人一样。”"},
{volumn:         7,chapter:        16,versenumber:        12,verse:"大利拉就用新绳捆绑他，对他说：“参孙哪，非利士人拿你来了！”有人预先埋伏在内室里。参孙将臂上的绳挣断了，如挣断一条线一样。"},
{volumn:         7,chapter:        16,versenumber:        13,verse:"大利拉对参孙说：“你到如今还是欺哄我，向我说谎言。求你告诉我，当用何法捆绑你。”参孙回答说：“你若将我头上的七条发绺，与纬线同织就可以了。”"},
{volumn:         7,chapter:        16,versenumber:        14,verse:"于是大利拉将他的发绺与纬线同织，用橛子钉住，对他说：“参孙哪，非利士人拿你来了！”参孙从睡中醒来，将机上的橛子和纬线一齐都拔出来了。"},
{volumn:         7,chapter:        16,versenumber:        15,verse:"大利拉对参孙说：“你既不与我同心，怎么说你爱我呢？你这三次欺哄我，没有告诉我，你因何有这么大的力气。”"},
{volumn:         7,chapter:        16,versenumber:        16,verse:"大利拉天天用话催逼他，甚至他心里烦闷要死。"},
{volumn:         7,chapter:        16,versenumber:        17,verse:"参孙就把心中所藏的都告诉了她，对她说：“向来人没有用剃头刀剃我的头，因为我自出母胎就归　神作拿细耳人；若剃了我的头发，我的力气就离开我，我便软弱像别人一样。”"},
{volumn:         7,chapter:        16,versenumber:        18,verse:"大利拉见他把心中所藏的都告诉了她，就打发人到非利士人的首领那里，对他们说：“他已经把心中所藏的都告诉了我，请你们再上来一次。”于是非利士人的首领手里拿着银子，上到妇人那里。"},
{volumn:         7,chapter:        16,versenumber:        19,verse:"大利拉使参孙枕着她的膝睡觉，叫了一个人来剃除他头上的七条发绺。于是大利拉克制他，他的力气就离开他了。"},
{volumn:         7,chapter:        16,versenumber:        20,verse:"大利拉说：“参孙哪，非利士人拿你来了！”参孙从睡中醒来，心里说：“我要像前几次出去活动身体”；他却不知道耶和华已经离开他了。"},
{volumn:         7,chapter:        16,versenumber:        21,verse:"非利士人将他拿住，剜了他的眼睛，带他下到迦萨，用铜链拘索他；他就在监里推磨。"},
{volumn:         7,chapter:        16,versenumber:        22,verse:"然而他的头发被剃之后，又渐渐长起来了。"},
{volumn:         7,chapter:        16,versenumber:        23,verse:"非利士人的首领聚集，要给他们的神大衮献大祭，并且欢乐，因为他们说：“我们的神将我们的仇敌参孙交在我们手中了。”"},
{volumn:         7,chapter:        16,versenumber:        24,verse:"众人看见参孙，就赞美他们的神说：“我们的神将毁坏我们地、杀害我们许多人的仇敌交在我们手中了。”"},
{volumn:         7,chapter:        16,versenumber:        25,verse:"他们正宴乐的时候，就说：“叫参孙来，在我们面前戏耍戏耍。”于是将参孙从监里提出来，他就在众人面前戏耍。他们使他站在两柱中间。"},
{volumn:         7,chapter:        16,versenumber:        26,verse:"参孙向拉他手的童子说：“求你让我摸着托房的柱子，我要靠一靠。”"},
{volumn:         7,chapter:        16,versenumber:        27,verse:"那时房内充满男女，非利士人的众首领也都在那里。房的平顶上约有三千男女观看参孙戏耍。"},
{volumn:         7,chapter:        16,versenumber:        28,verse:"参孙求告耶和华说：“主耶和华啊，求你眷念我。　神啊，求你赐我这一次的力量，使我在非利士人身上报那剜我双眼的仇。”"},
{volumn:         7,chapter:        16,versenumber:        29,verse:"参孙就抱住托房的那两根柱子：左手抱一根，右手抱一根，"},
{volumn:         7,chapter:        16,versenumber:        30,verse:"说：“我情愿与非利士人同死！”就尽力屈身，房子倒塌，压住首领和房内的众人。这样，参孙死时所杀的人比活着所杀的还多。"},
{volumn:         7,chapter:        16,versenumber:        31,verse:"参孙的弟兄和他父的全家都下去取他的尸首，抬上来葬在琐拉和以实陶中间，在他父玛挪亚的坟墓里。参孙作以色列的士师二十年。"},
{volumn:         7,chapter:        17,versenumber:         1,verse:"以法莲山地有一个人名叫米迦。"},
{volumn:         7,chapter:        17,versenumber:         2,verse:"他对母亲说：“你那一千一百舍客勒银子被人拿去，你因此咒诅，并且告诉了我。看哪，这银子在我这里，是我拿去了。”他母亲说：“我儿啊，愿耶和华赐福与你！”"},
{volumn:         7,chapter:        17,versenumber:         3,verse:"米迦就把这一千一百舍客勒银子还他母亲。他母亲说：“我分出这银子来为你献给耶和华，好雕刻一个像，铸成一个像。现在我还是交给你。”"},
{volumn:         7,chapter:        17,versenumber:         4,verse:"米迦将银子还他母亲，他母亲将二百舍客勒银子交给银匠，雕刻一个像，铸成一个像，安置在米迦的屋内。"},
{volumn:         7,chapter:        17,versenumber:         5,verse:"这米迦有了神堂，又制造以弗得和家中的神像，分派他一个儿子作祭司。"},
{volumn:         7,chapter:        17,versenumber:         6,verse:"那时以色列中没有王，各人任意而行。"},
{volumn:         7,chapter:        17,versenumber:         7,verse:"犹大的伯利恒有一个少年人，是犹大族的利未人，他在那里寄居。"},
{volumn:         7,chapter:        17,versenumber:         8,verse:"这人离开犹大的伯利恒城，要找一个可住的地方。行路的时候，到了以法莲山地，走到米迦的家。"},
{volumn:         7,chapter:        17,versenumber:         9,verse:"米迦问他说：“你从哪里来？”他回答说：“从犹大的伯利恒来。我是利未人，要找一个可住的地方。”"},
{volumn:         7,chapter:        17,versenumber:        10,verse:"米迦说：“你可以住在我这里，我以你为父、为祭司。我每年给你十舍客勒银子，一套衣服和度日的食物。”利未人就进了他的家。"},
{volumn:         7,chapter:        17,versenumber:        11,verse:"利未人情愿与那人同住；那人看这少年人如自己的儿子一样。"},
{volumn:         7,chapter:        17,versenumber:        12,verse:"米迦分派这少年的利未人作祭司，他就住在米迦的家里。"},
{volumn:         7,chapter:        17,versenumber:        13,verse:"米迦说：“现在我知道耶和华必赐福与我，因我有一个利未人作祭司。”"},
{volumn:         7,chapter:        18,versenumber:         1,verse:"那时，以色列中没有王。但支派的人仍是寻地居住；因为到那日子，他们还没有在以色列支派中得地为业。"},
{volumn:         7,chapter:        18,versenumber:         2,verse:"但人从琐拉和以实陶打发本族中的五个勇士，去仔细窥探那地，吩咐他们说：“你们去窥探那地。”他们来到以法莲山地，进了米迦的住宅，就在那里住宿。"},
{volumn:         7,chapter:        18,versenumber:         3,verse:"他们临近米迦的住宅，听出那少年利未人的口音来，就进去问他说：“谁领你到这里来？你在这里做什么？你在这里得什么？”"},
{volumn:         7,chapter:        18,versenumber:         4,verse:"他回答说：“米迦待我如此如此，请我作祭司。”"},
{volumn:         7,chapter:        18,versenumber:         5,verse:"他们对他说：“请你求问　神，使我们知道所行的道路通达不通达。”"},
{volumn:         7,chapter:        18,versenumber:         6,verse:"祭司对他们说：“你们可以平平安安地去，你们所行的道路是在耶和华面前的。”"},
{volumn:         7,chapter:        18,versenumber:         7,verse:"五人就走了，来到拉亿，见那里的民安居无虑，如同西顿人安居一样。在那地没有人掌权扰乱他们；他们离西顿人也远，与别人没有来往。"},
{volumn:         7,chapter:        18,versenumber:         8,verse:"五人回到琐拉和以实陶，见他们的弟兄；弟兄问他们说：“你们有什么话？”"},
{volumn:         7,chapter:        18,versenumber:         9,verse:"他们回答说：“起来，我们上去攻击他们吧！我们已经窥探那地，见那地甚好。你们为何静坐不动呢？要急速前往得那地为业，不可迟延。"},
{volumn:         7,chapter:        18,versenumber:        10,verse:"你们到了那里，必看见安居无虑的民，地也宽阔。　神已将那地交在你们手中；那地百物俱全，一无所缺。”"},
{volumn:         7,chapter:        18,versenumber:        11,verse:"于是但族中的六百人，各带兵器，从琐拉和以实陶前往，"},
{volumn:         7,chapter:        18,versenumber:        12,verse:"上到犹大的基列·耶琳，在基列·耶琳后边安营。因此那地方名叫玛哈尼·但，直到今日。"},
{volumn:         7,chapter:        18,versenumber:        13,verse:"他们从那里往以法莲山地去，来到米迦的住宅。"},
{volumn:         7,chapter:        18,versenumber:        14,verse:"从前窥探拉亿地的五个人对他们的弟兄说：“这宅子里有以弗得和家中的神像，并雕刻的像与铸成的像，你们知道吗？现在你们要想一想当怎样行。”"},
{volumn:         7,chapter:        18,versenumber:        15,verse:"五人就进入米迦的住宅，到了那少年利未人的房内问他好。"},
{volumn:         7,chapter:        18,versenumber:        16,verse:"那六百但人各带兵器，站在门口。"},
{volumn:         7,chapter:        18,versenumber:        17,verse:"窥探地的五个人走进去，将雕刻的像、以弗得、家中的神像，并铸成的像，都拿了去。祭司和带兵器的六百人，一同站在门口。"},
{volumn:         7,chapter:        18,versenumber:        18,verse:"那五个人进入米迦的住宅，拿出雕刻的像、以弗得、家中的神像，并铸成的像，祭司就问他们说：“你们做什么呢？”"},
{volumn:         7,chapter:        18,versenumber:        19,verse:"他们回答说：“不要作声，用手捂口，跟我们去吧！我们必以你为父、为祭司。你作一家的祭司好呢？还是作以色列一族一支派的祭司好呢？”"},
{volumn:         7,chapter:        18,versenumber:        20,verse:"祭司心里喜悦，便拿着以弗得和家中的神像，并雕刻的像，进入他们中间。"},
{volumn:         7,chapter:        18,versenumber:        21,verse:"他们就转身离开那里，妻子、儿女、牲畜、财物都在前头。"},
{volumn:         7,chapter:        18,versenumber:        22,verse:"离米迦的住宅已远，米迦的近邻都聚集来，追赶但人，"},
{volumn:         7,chapter:        18,versenumber:        23,verse:"呼叫但人。但人回头问米迦说：“你聚集这许多人来做什么呢？”"},
{volumn:         7,chapter:        18,versenumber:        24,verse:"米迦说：“你们将我所做的神像和祭司都带了去，我还有所剩的吗？怎么还问我说‘做什么’呢？”"},
{volumn:         7,chapter:        18,versenumber:        25,verse:"但人对米迦说：“你不要使我们听见你的声音，恐怕有性暴的人攻击你，以致你和你的全家尽都丧命。”"},
{volumn:         7,chapter:        18,versenumber:        26,verse:"但人还是走他们的路。米迦见他们的势力比自己强盛，就转身回家去了。"},
{volumn:         7,chapter:        18,versenumber:        27,verse:"但人将米迦所做的神像和他的祭司都带到拉亿，见安居无虑的民，就用刀杀了那民，又放火烧了那城，"},
{volumn:         7,chapter:        18,versenumber:        28,verse:"并无人搭救；因为离西顿远，他们又与别人没有来往。城在平原，那平原靠近伯·利合。但人又在那里修城居住，"},
{volumn:         7,chapter:        18,versenumber:        29,verse:"照着他们始祖以色列之子但的名字，给那城起名叫但；原先那城名叫拉亿。"},
{volumn:         7,chapter:        18,versenumber:        30,verse:"但人就为自己设立那雕刻的像。摩西的孙子、革舜的儿子约拿单，和他的子孙作但支派的祭司，直到那地遭掳掠的日子。"},
{volumn:         7,chapter:        18,versenumber:        31,verse:"神的殿在示罗多少日子，但人为自己设立米迦所雕刻的像也在但多少日子。"},
{volumn:         7,chapter:        19,versenumber:         1,verse:"当以色列中没有王的时候，有住以法莲山地那边的一个利未人，娶了一个犹大伯利恒的女子为妾。"},
{volumn:         7,chapter:        19,versenumber:         2,verse:"妾行淫离开丈夫，回犹大的伯利恒，到了父家，在那里住了四个月。"},
{volumn:         7,chapter:        19,versenumber:         3,verse:"她丈夫起来，带着一个仆人、两匹驴去见她，用好话劝她回来。女子就引丈夫进入父家。她父见了那人，便欢欢喜喜地迎接。"},
{volumn:         7,chapter:        19,versenumber:         4,verse:"那人的岳父，就是女子的父亲，将那人留下住了三天。于是二人一同吃喝、住宿。"},
{volumn:         7,chapter:        19,versenumber:         5,verse:"到第四天，利未人清早起来要走，女子的父亲对女婿说：“请你吃点饭，加添心力，然后可以行路。”"},
{volumn:         7,chapter:        19,versenumber:         6,verse:"于是二人坐下一同吃喝。女子的父亲对那人说：“请你再住一夜，畅快你的心。”"},
{volumn:         7,chapter:        19,versenumber:         7,verse:"那人起来要走，他岳父强留他，他又住了一宿。"},
{volumn:         7,chapter:        19,versenumber:         8,verse:"到第五天，他清早起来要走，女子的父亲说：“请你吃点饭，加添心力，等到日头偏西再走。”于是二人一同吃饭。"},
{volumn:         7,chapter:        19,versenumber:         9,verse:"那人同他的妾和仆人起来要走，他岳父，就是女子的父亲，对他说：“看哪，日头偏西了，请你再住一夜；天快晚了，可以在这里住宿，畅快你的心。明天早早起行回家去。”"},
{volumn:         7,chapter:        19,versenumber:        10,verse:"那人不愿再住一夜，就备上那两匹驴，带着妾起身走了，来到耶布斯的对面（耶布斯就是耶路撒冷。）"},
{volumn:         7,chapter:        19,versenumber:        11,verse:"临近耶布斯的时候，日头快要落了，仆人对主人说：“我们不如进这耶布斯人的城里住宿。”"},
{volumn:         7,chapter:        19,versenumber:        12,verse:"主人回答说：“我们不可进不是以色列人住的外邦城，不如过到基比亚去”；"},
{volumn:         7,chapter:        19,versenumber:        13,verse:"又对仆人说：“我们可以到一个地方，或住在基比亚，或住在拉玛。”"},
{volumn:         7,chapter:        19,versenumber:        14,verse:"他们就往前走。将到便雅悯的基比亚，日头已经落了；"},
{volumn:         7,chapter:        19,versenumber:        15,verse:"他们进入基比亚要在那里住宿，就坐在城里的街上，因为无人接他们进家住宿。"},
{volumn:         7,chapter:        19,versenumber:        16,verse:"晚上，有一个老年人从田间做工回来。他原是以法莲山地的人，住在基比亚；那地方的人却是便雅悯人。"},
{volumn:         7,chapter:        19,versenumber:        17,verse:"老年人举目看见客人坐在城里的街上，就问他说：“你从哪里来？要往哪里去？”"},
{volumn:         7,chapter:        19,versenumber:        18,verse:"他回答说：“我们从犹大的伯利恒来，要往以法莲山地那边去。我原是那里的人，到过犹大的伯利恒，现在我往耶和华的殿去，在这里无人接我进他的家。"},
{volumn:         7,chapter:        19,versenumber:        19,verse:"其实我有粮草可以喂驴，我与我的妾，并我的仆人，有饼有酒，并不缺少什么。”"},
{volumn:         7,chapter:        19,versenumber:        20,verse:"老年人说：“愿你平安！你所需用的我都给你，只是不可在街上过夜。”"},
{volumn:         7,chapter:        19,versenumber:        21,verse:"于是领他们到家里，喂上驴，他们就洗脚吃喝。"},
{volumn:         7,chapter:        19,versenumber:        22,verse:"他们心里正欢畅的时候，城中的匪徒围住房子，连连叩门，对房主老人说：“你把那进你家的人带出来，我们要与他交合。”"},
{volumn:         7,chapter:        19,versenumber:        23,verse:"那房主出来对他们说：“弟兄们哪，不要这样作恶；这人既然进了我的家，你们就不要行这丑事。"},
{volumn:         7,chapter:        19,versenumber:        24,verse:"我有个女儿，还是处女，并有这人的妾，我将她们领出来任凭你们玷辱她们，只是向这人不可行这样的丑事。”"},
{volumn:         7,chapter:        19,versenumber:        25,verse:"那些人却不听从他的话。那人就把他的妾拉出去交给他们，他们便与她交合，终夜凌辱她，直到天色快亮才放她去。"},
{volumn:         7,chapter:        19,versenumber:        26,verse:"天快亮的时候，妇人回到她主人住宿的房门前，就仆倒在地，直到天亮。"},
{volumn:         7,chapter:        19,versenumber:        27,verse:"早晨，她的主人起来开了房门，出去要行路，不料那妇人仆倒在房门前，两手搭在门槛上；"},
{volumn:         7,chapter:        19,versenumber:        28,verse:"就对妇人说：“起来，我们走吧！”妇人却不回答。那人便将她驮在驴上，起身回本处去了。"},
{volumn:         7,chapter:        19,versenumber:        29,verse:"到了家里，用刀将妾的尸身切成十二块，使人拿着传送以色列的四境。"},
{volumn:         7,chapter:        19,versenumber:        30,verse:"凡看见的人都说：“从以色列人出埃及地，直到今日，这样的事没有行过，也没有见过。现在应当思想，大家商议当怎样办理。”"},
{volumn:         7,chapter:        20,versenumber:         1,verse:"于是以色列从但到别是巴，以及住基列地的众人都出来，如同一人，聚集在米斯巴耶和华面前。"},
{volumn:         7,chapter:        20,versenumber:         2,verse:"以色列民的首领，就是各支派的军长，都站在　神百姓的会中；拿刀的步兵共有四十万。"},
{volumn:         7,chapter:        20,versenumber:         3,verse:"以色列人上到米斯巴，便雅悯人都听见了。以色列人说：“请你将这件恶事的情由对我们说明。”"},
{volumn:         7,chapter:        20,versenumber:         4,verse:"那利未人，就是被害之妇人的丈夫，回答说：“我和我的妾到了便雅悯的基比亚住宿。"},
{volumn:         7,chapter:        20,versenumber:         5,verse:"基比亚人夜间起来，围了我住的房子，想要杀我，又将我的妾强奸致死。"},
{volumn:         7,chapter:        20,versenumber:         6,verse:"我就把我妾的尸身切成块子，使人拿着传送以色列得为业的全地，因为基比亚人在以色列中行了凶淫丑恶的事。"},
{volumn:         7,chapter:        20,versenumber:         7,verse:"你们以色列人都当筹划商议。”"},
{volumn:         7,chapter:        20,versenumber:         8,verse:"众民都起来如同一人，说：“我们连一人都不回自己帐棚、自己房屋去。"},
{volumn:         7,chapter:        20,versenumber:         9,verse:"我们向基比亚人必这样行，照所掣的签去攻击他们。"},
{volumn:         7,chapter:        20,versenumber:        10,verse:"我们要在以色列各支派中，一百人挑取十人，一千人挑取百人，一万人挑取千人，为民运粮，等大众到了便雅悯的基比亚，就照基比亚人在以色列中所行的丑事征伐他们。”"},
{volumn:         7,chapter:        20,versenumber:        11,verse:"于是以色列众人彼此连合如同一人，聚集攻击那城。"},
{volumn:         7,chapter:        20,versenumber:        12,verse:"以色列众支派打发人去，问便雅悯支派的各家说：“你们中间怎么做了这样的恶事呢？"},
{volumn:         7,chapter:        20,versenumber:        13,verse:"现在你们要将基比亚的那些匪徒交出来，我们好治死他们，从以色列中除掉这恶。”便雅悯人却不肯听从他们弟兄以色列人的话。"},
{volumn:         7,chapter:        20,versenumber:        14,verse:"便雅悯人从他们的各城里出来，聚集到了基比亚，要与以色列人打仗。"},
{volumn:         7,chapter:        20,versenumber:        15,verse:"那时便雅悯人从各城里点出拿刀的，共有二万六千；另外还有基比亚人点出七百精兵。"},
{volumn:         7,chapter:        20,versenumber:        16,verse:"在众军之中有拣选的七百精兵，都是左手便利的，能用机弦甩石打人，毫发不差。"},
{volumn:         7,chapter:        20,versenumber:        17,verse:"便雅悯人之外，点出以色列人拿刀的，共有四十万，都是战士。"},
{volumn:         7,chapter:        20,versenumber:        18,verse:"以色列人就起来，到伯特利去求问　神说：“我们中间谁当首先上去与便雅悯人争战呢？”耶和华说：“犹大当先上去。”"},
{volumn:         7,chapter:        20,versenumber:        19,verse:"以色列人早晨起来，对着基比亚安营。"},
{volumn:         7,chapter:        20,versenumber:        20,verse:"以色列人出来，要与便雅悯人打仗，就在基比亚前摆阵。"},
{volumn:         7,chapter:        20,versenumber:        21,verse:"便雅悯人就从基比亚出来，当日杀死以色列人二万二千。"},
{volumn:         7,chapter:        20,versenumber:        22,verse:"以色列人彼此奋勇，仍在头一日摆阵的地方又摆阵。"},
{volumn:         7,chapter:        20,versenumber:        23,verse:"未摆阵之先，以色列人上去，在耶和华面前哭号，直到晚上，求问耶和华说：“我们再去与我们弟兄便雅悯人打仗可以不可以？”耶和华说：“可以上去攻击他们。”"},
{volumn:         7,chapter:        20,versenumber:        24,verse:"第二日，以色列人就上前攻击便雅悯人。"},
{volumn:         7,chapter:        20,versenumber:        25,verse:"便雅悯人也在这日从基比亚出来，与以色列人接战，又杀死他们一万八千，都是拿刀的。"},
{volumn:         7,chapter:        20,versenumber:        26,verse:"以色列众人就上到伯特利，坐在耶和华面前哭号，当日禁食直到晚上；又在耶和华面前献燔祭和平安祭。"},
{volumn:         7,chapter:        20,versenumber:        27,verse:"那时，　神的约柜在那里；亚伦的孙子、以利亚撒的儿子非尼哈侍立在约柜前。以色列人问耶和华说：“我们当再出去与我们弟兄便雅悯人打仗呢？还是罢兵呢？”耶和华说：“你们当上去，因为明日我必将他们交在你们手中。”"},
{volumn:         7,chapter:        20,versenumber:        28,verse:""},
{volumn:         7,chapter:        20,versenumber:        29,verse:"以色列人在基比亚的四围设下伏兵。"},
{volumn:         7,chapter:        20,versenumber:        30,verse:"第三日，以色列人又上去攻击便雅悯人，在基比亚前摆阵，与前两次一样。"},
{volumn:         7,chapter:        20,versenumber:        31,verse:"便雅悯人也出来迎敌，就被引诱离城；在田间两条路上，一通伯特利，一通基比亚，像前两次，动手杀死以色列人约有三十个。"},
{volumn:         7,chapter:        20,versenumber:        32,verse:"便雅悯人说：“他们仍旧败在我们面前。”但以色列人说：“我们不如逃跑，引诱他们离开城到路上来。”"},
{volumn:         7,chapter:        20,versenumber:        33,verse:"以色列众人都起来，在巴力·他玛摆阵，以色列的伏兵从马利·迦巴埋伏的地方冲上前去。"},
{volumn:         7,chapter:        20,versenumber:        34,verse:"有以色列人中的一万精兵，来到基比亚前接战，势派甚是凶猛；便雅悯人却不知道灾祸临近了。"},
{volumn:         7,chapter:        20,versenumber:        35,verse:"耶和华使以色列人杀败便雅悯人。那日，以色列人杀死便雅悯人二万五千一百，都是拿刀的。"},
{volumn:         7,chapter:        20,versenumber:        36,verse:"于是便雅悯人知道自己败了。先是以色列人；因为靠着在基比亚前所设的伏兵，就在便雅悯人面前诈败。"},
{volumn:         7,chapter:        20,versenumber:        37,verse:"伏兵急忙闯进基比亚，用刀杀死全城的人。"},
{volumn:         7,chapter:        20,versenumber:        38,verse:"以色列人预先同伏兵约定在城内放火，以烟气上腾为号。"},
{volumn:         7,chapter:        20,versenumber:        39,verse:"以色列人临退阵的时候，便雅悯人动手杀死以色列人，约有三十个，就说：“他们仍像前次被我们杀败了。”"},
{volumn:         7,chapter:        20,versenumber:        40,verse:"当烟气如柱从城中上腾的时候，便雅悯人回头观看，见全城的烟气冲天。"},
{volumn:         7,chapter:        20,versenumber:        41,verse:"以色列人又转身回来，便雅悯人就甚惊惶，因为看见灾祸临到自己了。"},
{volumn:         7,chapter:        20,versenumber:        42,verse:"他们在以色列人面前转身往旷野逃跑；以色列人在后面追杀。那从各城里出来的，也都夹攻杀灭他们。"},
{volumn:         7,chapter:        20,versenumber:        43,verse:"以色列人围绕便雅悯人，追赶他们，在他们歇脚之处、对着日出之地的基比亚践踏他们。"},
{volumn:         7,chapter:        20,versenumber:        44,verse:"便雅悯人死了的有一万八千，都是勇士。"},
{volumn:         7,chapter:        20,versenumber:        45,verse:"其余的人转身向旷野逃跑，往临门磐去。以色列人在道路上杀了他们五千人，如拾取遗穗一样，追到基顿又杀了他们二千人。"},
{volumn:         7,chapter:        20,versenumber:        46,verse:"那日便雅悯死了的共有二万五千人，都是拿刀的勇士。"},
{volumn:         7,chapter:        20,versenumber:        47,verse:"只剩下六百人，转身向旷野逃跑，到了临门磐，就在那里住了四个月。"},
{volumn:         7,chapter:        20,versenumber:        48,verse:"以色列人又转到便雅悯地，将各城的人和牲畜，并一切所遇见的，都用刀杀尽，又放火烧了一切城邑。"},
{volumn:         7,chapter:        21,versenumber:         1,verse:"以色列人在米斯巴曾起誓说：“我们都不将女儿给便雅悯人为妻。”"},
{volumn:         7,chapter:        21,versenumber:         2,verse:"以色列人来到伯特利，坐在　神面前直到晚上，放声痛哭，"},
{volumn:         7,chapter:        21,versenumber:         3,verse:"说：“耶和华以色列的　神啊，为何以色列中有这样缺了一支派的事呢？”"},
{volumn:         7,chapter:        21,versenumber:         4,verse:"次日清早，百姓起来，在那里筑了一座坛，献燔祭和平安祭。"},
{volumn:         7,chapter:        21,versenumber:         5,verse:"以色列人彼此问说：“以色列各支派中，谁没有同会众上到耶和华面前来呢？”先是以色列人起过大誓说，凡不上米斯巴到耶和华面前来的，必将他治死。"},
{volumn:         7,chapter:        21,versenumber:         6,verse:"以色列人为他们的弟兄便雅悯后悔，说：“如今以色列中绝了一个支派了。"},
{volumn:         7,chapter:        21,versenumber:         7,verse:"我们既在耶和华面前起誓说，必不将我们的女儿给便雅悯人为妻，现在我们当怎样办理、使他们剩下的人有妻呢？”"},
{volumn:         7,chapter:        21,versenumber:         8,verse:"又彼此问说：“以色列支派中谁没有上米斯巴到耶和华面前来呢？”他们就查出基列·雅比没有一人进营到会众那里；"},
{volumn:         7,chapter:        21,versenumber:         9,verse:"因为百姓被数的时候，没有一个基列·雅比人在那里。"},
{volumn:         7,chapter:        21,versenumber:        10,verse:"会众就打发一万二千大勇士，吩咐他们说：“你们去用刀将基列·雅比人连妇女带孩子都击杀了。"},
{volumn:         7,chapter:        21,versenumber:        11,verse:"所当行的就是这样：要将一切男子和已嫁的女子尽行杀戮。”"},
{volumn:         7,chapter:        21,versenumber:        12,verse:"他们在基列·雅比人中，遇见了四百个未嫁的处女，就带到迦南地的示罗营里。"},
{volumn:         7,chapter:        21,versenumber:        13,verse:"全会众打发人到临门磐的便雅悯人那里，向他们说和睦的话。"},
{volumn:         7,chapter:        21,versenumber:        14,verse:"当时便雅悯人回来了，以色列人就把所存活基列·雅比的女子给他们为妻，还是不够。"},
{volumn:         7,chapter:        21,versenumber:        15,verse:"百姓为便雅悯人后悔，因为耶和华使以色列人缺了一个支派（原文是使以色列中有了破口）。"},
{volumn:         7,chapter:        21,versenumber:        16,verse:"会中的长老说：“便雅悯中的女子既然除灭了，我们当怎样办理、使那余剩的人有妻呢？”"},
{volumn:         7,chapter:        21,versenumber:        17,verse:"又说：“便雅悯逃脱的人当有地业，免得以色列中涂抹了一个支派。"},
{volumn:         7,chapter:        21,versenumber:        18,verse:"只是我们不能将自己的女儿给他们为妻；因为以色列人曾起誓说，有将女儿给便雅悯人为妻的，必受咒诅。”"},
{volumn:         7,chapter:        21,versenumber:        19,verse:"他们又说：“在利波拿以南，伯特利以北，在示剑大路以东的示罗，年年有耶和华的节期”；"},
{volumn:         7,chapter:        21,versenumber:        20,verse:"就吩咐便雅悯人说：“你们去，在葡萄园中埋伏。"},
{volumn:         7,chapter:        21,versenumber:        21,verse:"若看见示罗的女子出来跳舞，就从葡萄园出来，在示罗的女子中各抢一个为妻，回便雅悯地去。"},
{volumn:         7,chapter:        21,versenumber:        22,verse:"他们的父亲或是弟兄若来与我们争竞，我们就说：‘求你们看我们的情面，施恩给这些人，因我们在争战的时候没有给他们留下女子为妻。这也不是你们将女子给他们的；若是你们给的，就算有罪。’”"},
{volumn:         7,chapter:        21,versenumber:        23,verse:"于是便雅悯人照样而行，按着他们的数目从跳舞的女子中抢去为妻，就回自己的地业去，又重修城邑居住。"},
{volumn:         7,chapter:        21,versenumber:        24,verse:"当时以色列人离开那里，各归本支派、本宗族、本地业去了。"},
{volumn:         7,chapter:        21,versenumber:        25,verse:"那时，以色列中没有王，各人任意而行。"},
{volumn:         8,chapter:         1,versenumber:         1,verse:"当士师秉政的时候，国中遭遇饥荒。在犹大的伯利恒，有一个人带着妻子和两个儿子往摩押地去寄居。"},
{volumn:         8,chapter:         1,versenumber:         2,verse:"这人名叫以利米勒，他的妻名叫拿俄米；他两个儿子，一个名叫玛伦，一个名叫基连，都是犹大伯利恒的以法他人。他们到了摩押地，就住在那里。"},
{volumn:         8,chapter:         1,versenumber:         3,verse:"后来拿俄米的丈夫以利米勒死了，剩下妇人和她两个儿子。"},
{volumn:         8,chapter:         1,versenumber:         4,verse:"这两个儿子娶了摩押女子为妻，一个名叫俄珥巴，一个名叫路得，在那里住了约有十年。"},
{volumn:         8,chapter:         1,versenumber:         5,verse:"玛伦和基连二人也死了，剩下拿俄米，没有丈夫，也没有儿子。"},
{volumn:         8,chapter:         1,versenumber:         6,verse:"她就与两个儿妇起身，要从摩押地归回；因为她在摩押地听见耶和华眷顾自己的百姓，赐粮食与他们。"},
{volumn:         8,chapter:         1,versenumber:         7,verse:"于是她和两个儿妇起行离开所住的地方，要回犹大地去。"},
{volumn:         8,chapter:         1,versenumber:         8,verse:"拿俄米对两个儿妇说：“你们各人回娘家去吧。愿耶和华恩待你们，像你们恩待已死的人与我一样！"},
{volumn:         8,chapter:         1,versenumber:         9,verse:"愿耶和华使你们各在新夫家中得平安！”于是拿俄米与她们亲嘴。她们就放声而哭，"},
{volumn:         8,chapter:         1,versenumber:        10,verse:"说：“不然，我们必与你一同回你本国去。”"},
{volumn:         8,chapter:         1,versenumber:        11,verse:"拿俄米说：“我女儿们哪，回去吧！为何要跟我去呢？我还能生子作你们的丈夫吗？"},
{volumn:         8,chapter:         1,versenumber:        12,verse:"我女儿们哪，回去吧！我年纪老迈，不能再有丈夫；即或说，我还有指望，今夜有丈夫可以生子，"},
{volumn:         8,chapter:         1,versenumber:        13,verse:"你们岂能等着他们长大呢？你们岂能等着他们不嫁别人呢？我女儿们哪，不要这样。我为你们的缘故甚是愁苦，因为耶和华伸手攻击我。”"},
{volumn:         8,chapter:         1,versenumber:        14,verse:"两个儿妇又放声而哭，俄珥巴与婆婆亲嘴而别，只是路得舍不得拿俄米。"},
{volumn:         8,chapter:         1,versenumber:        15,verse:"拿俄米说：“看哪，你嫂子已经回她本国和她所拜的神那里去了，你也跟着你嫂子回去吧！”"},
{volumn:         8,chapter:         1,versenumber:        16,verse:"路得说：“不要催我回去不跟随你。你往哪里去，我也往那里去；你在哪里住宿，我也在那里住宿；你的国就是我的国，你的　神就是我的　神。"},
{volumn:         8,chapter:         1,versenumber:        17,verse:"你在哪里死，我也在那里死，也葬在那里。除非死能使你我相离！不然，愿耶和华重重地降罚与我。”"},
{volumn:         8,chapter:         1,versenumber:        18,verse:"拿俄米见路得定意要跟随自己去，就不再劝她了。"},
{volumn:         8,chapter:         1,versenumber:        19,verse:"于是二人同行，来到伯利恒。她们到了伯利恒，合城的人就都惊讶。妇女们说：“这是拿俄米吗？”"},
{volumn:         8,chapter:         1,versenumber:        20,verse:"拿俄米对她们说：“不要叫我拿俄米（就是甜的意思），要叫我玛拉（就是苦的意思），因为全能者使我受了大苦。"},
{volumn:         8,chapter:         1,versenumber:        21,verse:"我满满地出去，耶和华使我空空地回来。耶和华降祸与我；全能者使我受苦。既是这样，你们为何还叫我拿俄米呢？”"},
{volumn:         8,chapter:         1,versenumber:        22,verse:"拿俄米和她儿妇摩押女子路得，从摩押地回来到伯利恒，正是动手割大麦的时候。"},
{volumn:         8,chapter:         2,versenumber:         1,verse:"拿俄米的丈夫以利米勒的亲族中，有一个人名叫波阿斯，是个大财主。"},
{volumn:         8,chapter:         2,versenumber:         2,verse:"摩押女子路得对拿俄米说：“容我往田间去，我蒙谁的恩，就在谁的身后拾取麦穗。”拿俄米说：“女儿啊，你只管去。”"},
{volumn:         8,chapter:         2,versenumber:         3,verse:"路得就去了，来到田间，在收割的人身后拾取麦穗。她恰巧到了以利米勒本族的人波阿斯那块田里。"},
{volumn:         8,chapter:         2,versenumber:         4,verse:"波阿斯正从伯利恒来，对收割的人说：“愿耶和华与你们同在！”他们回答说：“愿耶和华赐福与你！”"},
{volumn:         8,chapter:         2,versenumber:         5,verse:"波阿斯问监管收割的仆人说：“那是谁家的女子？”"},
{volumn:         8,chapter:         2,versenumber:         6,verse:"监管收割的仆人回答说：“是那摩押女子，跟随拿俄米从摩押地回来的。"},
{volumn:         8,chapter:         2,versenumber:         7,verse:"她说：‘请你容我跟着收割的人拾取打捆剩下的麦穗。’她从早晨直到如今，除了在屋子里坐一会儿，常在这里。”"},
{volumn:         8,chapter:         2,versenumber:         8,verse:"波阿斯对路得说：“女儿啊，听我说，不要往别人田里拾取麦穗，也不要离开这里，要常与我使女们在一处。"},
{volumn:         8,chapter:         2,versenumber:         9,verse:"我的仆人在那块田收割，你就跟着他们去。我已经吩咐仆人不可欺负你；你若渴了，就可以到器皿那里喝仆人打来的水。”"},
{volumn:         8,chapter:         2,versenumber:        10,verse:"路得就俯伏在地叩拜，对他说：“我既是外邦人，怎么蒙你的恩，这样顾恤我呢？”"},
{volumn:         8,chapter:         2,versenumber:        11,verse:"波阿斯回答说：“自从你丈夫死后，凡你向婆婆所行的，并你离开父母和本地，到素不认识的民中，这些事人全都告诉我了。"},
{volumn:         8,chapter:         2,versenumber:        12,verse:"愿耶和华照你所行的赏赐你。你来投靠耶和华以色列　神的翅膀下，愿你满得他的赏赐。”"},
{volumn:         8,chapter:         2,versenumber:        13,verse:"路得说：“我主啊，愿在你眼前蒙恩。我虽然不及你的一个使女，你还用慈爱的话安慰我的心。”"},
{volumn:         8,chapter:         2,versenumber:        14,verse:"到了吃饭的时候，波阿斯对路得说：“你到这里来吃饼，将饼蘸在醋里。”路得就在收割的人旁边坐下；他们把烘了的穗子递给她。她吃饱了，还有余剩的。"},
{volumn:         8,chapter:         2,versenumber:        15,verse:"她起来又拾取麦穗，波阿斯吩咐仆人说：“她就是在捆中拾取麦穗，也可以容她，不可羞辱她；"},
{volumn:         8,chapter:         2,versenumber:        16,verse:"并要从捆里抽出些来，留在地下任她拾取，不可叱吓她。”"},
{volumn:         8,chapter:         2,versenumber:        17,verse:"这样，路得在田间拾取麦穗，直到晚上，将所拾取的打了，约有一伊法大麦。"},
{volumn:         8,chapter:         2,versenumber:        18,verse:"她就把所拾取的带进城去给婆婆看，又把她吃饱了所剩的给了婆婆。"},
{volumn:         8,chapter:         2,versenumber:        19,verse:"婆婆问她说：“你今日在哪里拾取麦穗，在哪里做工呢？愿那顾恤你的得福。”路得就告诉婆婆说：“我今日在一个名叫波阿斯的人那里做工。”"},
{volumn:         8,chapter:         2,versenumber:        20,verse:"拿俄米对儿妇说：“愿那人蒙耶和华赐福，因为他不断地恩待活人死人。”拿俄米又说：“那是我们本族的人，是一个至近的亲属。”"},
{volumn:         8,chapter:         2,versenumber:        21,verse:"摩押女子路得说：“他对我说：‘你要紧随我的仆人拾取麦穗，直等他们收完了我的庄稼。’”"},
{volumn:         8,chapter:         2,versenumber:        22,verse:"拿俄米对儿妇路得说：“女儿啊，你跟着他的使女出去，不叫人遇见你在别人田间，这才为好。”"},
{volumn:         8,chapter:         2,versenumber:        23,verse:"于是路得与波阿斯的使女常在一处拾取麦穗，直到收完了大麦和小麦。路得仍与婆婆同住。"},
{volumn:         8,chapter:         3,versenumber:         1,verse:"路得的婆婆拿俄米对她说：“女儿啊，我不当为你找个安身之处，使你享福吗？"},
{volumn:         8,chapter:         3,versenumber:         2,verse:"你与波阿斯的使女常在一处，波阿斯不是我们的亲族吗？他今夜在场上簸大麦；"},
{volumn:         8,chapter:         3,versenumber:         3,verse:"你要沐浴抹膏，换上衣服，下到场上，却不要使那人认出你来。你等他吃喝完了，"},
{volumn:         8,chapter:         3,versenumber:         4,verse:"到他睡的时候，你看准他睡的地方，就进去掀开他脚上的被，躺卧在那里，他必告诉你所当做的事。”"},
{volumn:         8,chapter:         3,versenumber:         5,verse:"路得说：“凡你所吩咐的，我必遵行。”"},
{volumn:         8,chapter:         3,versenumber:         6,verse:"路得就下到场上，照她婆婆所吩咐她的而行。"},
{volumn:         8,chapter:         3,versenumber:         7,verse:"波阿斯吃喝完了，心里欢畅，就去睡在麦堆旁边。路得便悄悄地来掀开他脚上的被，躺卧在那里。"},
{volumn:         8,chapter:         3,versenumber:         8,verse:"到了夜半，那人忽然惊醒，翻过身来，不料有女子躺在他的脚下。"},
{volumn:         8,chapter:         3,versenumber:         9,verse:"他就说：“你是谁？”回答说：“我是你的婢女路得。求你用你的衣襟遮盖我，因为你是我一个至近的亲属。”"},
{volumn:         8,chapter:         3,versenumber:        10,verse:"波阿斯说：“女儿啊，愿你蒙耶和华赐福。你末后的恩比先前更大；因为少年人无论贫富，你都没有跟从。"},
{volumn:         8,chapter:         3,versenumber:        11,verse:"女儿啊，现在不要惧怕，凡你所说的，我必照着行；我本城的人都知道你是个贤德的女子。"},
{volumn:         8,chapter:         3,versenumber:        12,verse:"我实在是你一个至近的亲属，只是还有一个人比我更近。"},
{volumn:         8,chapter:         3,versenumber:        13,verse:"你今夜在这里住宿，明早他若肯为你尽亲属的本分，就由他吧！倘若不肯，我指着永生的耶和华起誓，我必为你尽了本分，你只管躺到天亮。”"},
{volumn:         8,chapter:         3,versenumber:        14,verse:"路得便在他脚下躺到天快亮，人彼此不能辨认的时候就起来了。波阿斯说：“不可使人知道有女子到场上来”；"},
{volumn:         8,chapter:         3,versenumber:        15,verse:"又对路得说：“打开你所披的外衣。”她打开了，波阿斯就撮了六簸箕大麦，帮她扛在肩上，她便进城去了。"},
{volumn:         8,chapter:         3,versenumber:        16,verse:"路得回到婆婆那里，婆婆说：“女儿啊，怎么样了？”路得就将那人向她所行的述说了一遍，"},
{volumn:         8,chapter:         3,versenumber:        17,verse:"又说：“那人给了我六簸箕大麦，对我说：‘你不可空手回去见你的婆婆。’”"},
{volumn:         8,chapter:         3,versenumber:        18,verse:"婆婆说：“女儿啊，你只管安坐等候，看这事怎样成就，因为那人今日不办成这事必不休息。”"},
{volumn:         8,chapter:         4,versenumber:         1,verse:"波阿斯到了城门，坐在那里，恰巧波阿斯所说的那至近的亲属经过。波阿斯说：“某人哪，你来坐在这里。”他就来坐下。"},
{volumn:         8,chapter:         4,versenumber:         2,verse:"波阿斯又从本城的长老中拣选了十人，对他们说：“请你们坐在这里。”他们就都坐下。"},
{volumn:         8,chapter:         4,versenumber:         3,verse:"波阿斯对那至近的亲属说：“从摩押地回来的拿俄米，现在要卖我们族兄以利米勒的那块地；"},
{volumn:         8,chapter:         4,versenumber:         4,verse:"我想当赎那块地的是你，其次是我，以外再没有别人了。你可以在这里的人面前和我本国的长老面前说明，你若肯赎就赎，若不肯赎就告诉我。”那人回答说：“我肯赎。”"},
{volumn:         8,chapter:         4,versenumber:         5,verse:"波阿斯说：“你从拿俄米手中买这地的时候，也当娶（原文是买；十节同）死人的妻摩押女子路得，使死人在产业上存留他的名。”"},
{volumn:         8,chapter:         4,versenumber:         6,verse:"那人说：“这样我就不能赎了，恐怕于我的产业有碍。你可以赎我所当赎的，我不能赎了。”"},
{volumn:         8,chapter:         4,versenumber:         7,verse:"从前，在以色列中要定夺什么事，或赎回，或交易，这人就脱鞋给那人。以色列人都以此为证据。"},
{volumn:         8,chapter:         4,versenumber:         8,verse:"那人对波阿斯说：“你自己买吧！”于是将鞋脱下来了。"},
{volumn:         8,chapter:         4,versenumber:         9,verse:"波阿斯对长老和众民说：“你们今日作见证，凡属以利米勒和基连、玛伦的，我都从拿俄米手中置买了；"},
{volumn:         8,chapter:         4,versenumber:        10,verse:"又娶了玛伦的妻摩押女子路得为妻，好在死人的产业上存留他的名，免得他的名在本族本乡灭没。你们今日可以作见证。”"},
{volumn:         8,chapter:         4,versenumber:        11,verse:"在城门坐着的众民和长老都说：“我们作见证。愿耶和华使进你家的这女子，像建立以色列家的拉结、利亚二人一样。又愿你在以法他得亨通，在伯利恒得名声。"},
{volumn:         8,chapter:         4,versenumber:        12,verse:"愿耶和华从这少年女子赐你后裔，使你的家像他玛从犹大所生法勒斯的家一般。”"},
{volumn:         8,chapter:         4,versenumber:        13,verse:"于是，波阿斯娶了路得为妻，与她同房。耶和华使她怀孕生了一个儿子。"},
{volumn:         8,chapter:         4,versenumber:        14,verse:"妇人们对拿俄米说：“耶和华是应当称颂的！因为今日没有撇下你，使你无至近的亲属。愿这孩子在以色列中得名声。"},
{volumn:         8,chapter:         4,versenumber:        15,verse:"他必提起你的精神，奉养你的老，因为是爱慕你的那儿妇所生的。有这儿妇比有七个儿子还好！”"},
{volumn:         8,chapter:         4,versenumber:        16,verse:"拿俄米就把孩子抱在怀中，作他的养母。"},
{volumn:         8,chapter:         4,versenumber:        17,verse:"邻舍的妇人说：“拿俄米得孩子了！”就给孩子起名叫俄备得。这俄备得是耶西的父，耶西是大卫的父。"},
{volumn:         8,chapter:         4,versenumber:        18,verse:"法勒斯的后代记在下面：法勒斯生希斯仑；"},
{volumn:         8,chapter:         4,versenumber:        19,verse:"希斯仑生兰；兰生亚米拿达；"},
{volumn:         8,chapter:         4,versenumber:        20,verse:"亚米拿达生拿顺；拿顺生撒门；"},
{volumn:         8,chapter:         4,versenumber:        21,verse:"撒门生波阿斯；波阿斯生俄备得；"},
{volumn:         8,chapter:         4,versenumber:        22,verse:"俄备得生耶西；耶西生大卫。"},
{volumn:         9,chapter:         1,versenumber:         1,verse:"以法莲山地的拉玛·琐非有一个以法莲人，名叫以利加拿，是苏弗的玄孙，托户的曾孙，以利户的孙子，耶罗罕的儿子。"},
{volumn:         9,chapter:         1,versenumber:         2,verse:"他有两个妻：一名哈拿，一名毗尼拿。毗尼拿有儿女，哈拿没有儿女。"},
{volumn:         9,chapter:         1,versenumber:         3,verse:"这人每年从本城上到示罗，敬拜祭祀万军之耶和华；在那里有以利的两个儿子何弗尼、非尼哈当耶和华的祭司。"},
{volumn:         9,chapter:         1,versenumber:         4,verse:"以利加拿每逢献祭的日子，将祭肉分给他的妻毗尼拿和毗尼拿所生的儿女；"},
{volumn:         9,chapter:         1,versenumber:         5,verse:"给哈拿的却是双份，因为他爱哈拿。无奈耶和华不使哈拿生育。"},
{volumn:         9,chapter:         1,versenumber:         6,verse:"毗尼拿见耶和华不使哈拿生育，就作她的对头，大大激动她，要使她生气。"},
{volumn:         9,chapter:         1,versenumber:         7,verse:"每年上到耶和华殿的时候，以利加拿都以双份给哈拿；毗尼拿仍是激动她，以致她哭泣不吃饭。"},
{volumn:         9,chapter:         1,versenumber:         8,verse:"她丈夫以利加拿对她说：“哈拿啊，你为何哭泣，不吃饭，心里愁闷呢？有我不比十个儿子还好吗？”"},
{volumn:         9,chapter:         1,versenumber:         9,verse:"他们在示罗吃喝完了，哈拿就站起来。祭司以利在耶和华殿的门框旁边，坐在自己的位上。"},
{volumn:         9,chapter:         1,versenumber:        10,verse:"哈拿心里愁苦，就痛痛哭泣，祈祷耶和华，"},
{volumn:         9,chapter:         1,versenumber:        11,verse:"许愿说：“万军之耶和华啊，你若垂顾婢女的苦情，眷念不忘婢女，赐我一个儿子，我必使他终身归与耶和华，不用剃头刀剃他的头。”"},
{volumn:         9,chapter:         1,versenumber:        12,verse:"哈拿在耶和华面前不住地祈祷，以利定睛看她的嘴。（"},
{volumn:         9,chapter:         1,versenumber:        13,verse:"原来哈拿心中默祷，只动嘴唇，不出声音，因此以利以为她喝醉了。）"},
{volumn:         9,chapter:         1,versenumber:        14,verse:"以利对她说：“你要醉到几时呢？你不应该喝酒。”"},
{volumn:         9,chapter:         1,versenumber:        15,verse:"哈拿回答说：“主啊，不是这样。我是心里愁苦的妇人，清酒浓酒都没有喝，但在耶和华面前倾心吐意。"},
{volumn:         9,chapter:         1,versenumber:        16,verse:"不要将婢女看作不正经的女子。我因被人激动，愁苦太多，所以祈求到如今。”"},
{volumn:         9,chapter:         1,versenumber:        17,verse:"以利说：“你可以平平安安地回去。愿以色列的　神允准你向他所求的！”"},
{volumn:         9,chapter:         1,versenumber:        18,verse:"哈拿说：“愿婢女在你眼前蒙恩。”于是妇人走去吃饭，面上再不带愁容了。"},
{volumn:         9,chapter:         1,versenumber:        19,verse:"次日清早，他们起来，在耶和华面前敬拜，就回拉玛。到了家里，以利加拿和妻哈拿同房，耶和华顾念哈拿，"},
{volumn:         9,chapter:         1,versenumber:        20,verse:"哈拿就怀孕。日期满足，生了一个儿子，给他起名叫撒母耳，说：“这是我从耶和华那里求来的。”"},
{volumn:         9,chapter:         1,versenumber:        21,verse:"以利加拿和他全家都上示罗去，要向耶和华献年祭，并还所许的愿。"},
{volumn:         9,chapter:         1,versenumber:        22,verse:"哈拿却没有上去，对丈夫说：“等孩子断了奶，我便带他上去朝见耶和华，使他永远住在那里。”"},
{volumn:         9,chapter:         1,versenumber:        23,verse:"她丈夫以利加拿说：“就随你的意行吧！可以等儿子断了奶。但愿耶和华应验他的话。”于是妇人在家里乳养儿子，直到断了奶；"},
{volumn:         9,chapter:         1,versenumber:        24,verse:"既断了奶，就把孩子带上示罗，到了耶和华的殿；又带了三只公牛，一伊法细面，一皮袋酒。（那时，孩子还小。）"},
{volumn:         9,chapter:         1,versenumber:        25,verse:"宰了一只公牛，就领孩子到以利面前。"},
{volumn:         9,chapter:         1,versenumber:        26,verse:"妇人说：“主啊，我敢在你面前起誓，从前在你这里站着祈求耶和华的那妇人，就是我。"},
{volumn:         9,chapter:         1,versenumber:        27,verse:"我祈求为要得这孩子；耶和华已将我所求的赐给我了。"},
{volumn:         9,chapter:         1,versenumber:        28,verse:"所以，我将这孩子归与耶和华，使他终身归与耶和华。”	于是在那里敬拜耶和华。"},
{volumn:         9,chapter:         2,versenumber:         1,verse:"哈拿祷告说：“我的心因耶和华快乐；我的角因耶和华高举。我的口向仇敌张开；我因耶和华的救恩欢欣。"},
{volumn:         9,chapter:         2,versenumber:         2,verse:"“只有耶和华为圣；除他以外没有可比的，也没有磐石像我们的　神。"},
{volumn:         9,chapter:         2,versenumber:         3,verse:"人不要夸口说骄傲的话，也不要出狂妄的言语；因耶和华是大有智识的　神，人的行为被他衡量。"},
{volumn:         9,chapter:         2,versenumber:         4,verse:"勇士的弓都已折断；跌倒的人以力量束腰。"},
{volumn:         9,chapter:         2,versenumber:         5,verse:"素来饱足的，反作佣人求食；饥饿的，再不饥饿。不生育的，生了七个儿子；多有儿女的，反倒衰微。"},
{volumn:         9,chapter:         2,versenumber:         6,verse:"耶和华使人死，也使人活，使人下阴间，也使人往上升。"},
{volumn:         9,chapter:         2,versenumber:         7,verse:"他使人贫穷，也使人富足，使人卑微，也使人高贵。"},
{volumn:         9,chapter:         2,versenumber:         8,verse:"他从灰尘里抬举贫寒人，从粪堆中提拔穷乏人，使他们与王子同坐，得着荣耀的座位。地的柱子属于耶和华；他将世界立在其上。"},
{volumn:         9,chapter:         2,versenumber:         9,verse:"“他必保护圣民的脚步，使恶人在黑暗中寂然不动；人都不能靠力量得胜。"},
{volumn:         9,chapter:         2,versenumber:        10,verse:"与耶和华争竞的，必被打碎；耶和华必从天上以雷攻击他，必审判地极的人，将力量赐与所立的王，高举受膏者的角。”"},
{volumn:         9,chapter:         2,versenumber:        11,verse:"以利加拿往拉玛回家去了。那孩子在祭司以利面前侍奉耶和华。"},
{volumn:         9,chapter:         2,versenumber:        12,verse:"以利的两个儿子是恶人，不认识耶和华。"},
{volumn:         9,chapter:         2,versenumber:        13,verse:"这二祭司待百姓是这样的规矩：凡有人献祭，正煮肉的时候，祭司的仆人就来，手拿三齿的叉子，"},
{volumn:         9,chapter:         2,versenumber:        14,verse:"将叉子往罐里，或鼎里，或釜里，或锅里一插，插上来的肉，祭司都取了去。凡上到示罗的以色列人，他们都是这样看待。"},
{volumn:         9,chapter:         2,versenumber:        15,verse:"又在未烧脂油以前，祭司的仆人就来对献祭的人说：“将肉给祭司，叫他烤吧。他不要煮过的，要生的。”"},
{volumn:         9,chapter:         2,versenumber:        16,verse:"献祭的人若说：“必须先烧脂油，然后你可以随意取肉。”仆人就说：“你立时给我，不然我便抢去。”"},
{volumn:         9,chapter:         2,versenumber:        17,verse:"如此，这二少年人的罪在耶和华面前甚重了，因为他们藐视耶和华的祭物（或译：他们使人厌弃给耶和华献祭）。"},
{volumn:         9,chapter:         2,versenumber:        18,verse:"那时，撒母耳还是孩子，穿着细麻布的以弗得，侍立在耶和华面前。"},
{volumn:         9,chapter:         2,versenumber:        19,verse:"他母亲每年为他做一件小外袍，同着丈夫上来献年祭的时候带来给他。"},
{volumn:         9,chapter:         2,versenumber:        20,verse:"以利为以利加拿和他的妻祝福，说：“愿耶和华由这妇人再赐你后裔，代替你从耶和华求来的孩子。”他们就回本乡去了。"},
{volumn:         9,chapter:         2,versenumber:        21,verse:"耶和华眷顾哈拿，她就怀孕生了三个儿子，两个女儿。那孩子撒母耳在耶和华面前渐渐长大。"},
{volumn:         9,chapter:         2,versenumber:        22,verse:"以利年甚老迈，听见他两个儿子待以色列众人的事，又听见他们与会幕门前伺候的妇人苟合，"},
{volumn:         9,chapter:         2,versenumber:        23,verse:"他就对他们说：“你们为何行这样的事呢？我从这众百姓听见你们的恶行。"},
{volumn:         9,chapter:         2,versenumber:        24,verse:"我儿啊，不可这样！我听见你们的风声不好，你们使耶和华的百姓犯了罪。"},
{volumn:         9,chapter:         2,versenumber:        25,verse:"人若得罪人，有士师审判他；人若得罪耶和华，谁能为他祈求呢？”然而他们还是不听父亲的话，因为耶和华想要杀他们。"},
{volumn:         9,chapter:         2,versenumber:        26,verse:"孩子撒母耳渐渐长大，耶和华与人越发喜爱他。"},
{volumn:         9,chapter:         2,versenumber:        27,verse:"有神人来见以利，对他说：“耶和华如此说：‘你祖父在埃及法老家作奴仆的时候，我不是向他们显现吗？"},
{volumn:         9,chapter:         2,versenumber:        28,verse:"在以色列众支派中，我不是拣选人作我的祭司，使他烧香，在我坛上献祭，在我面前穿以弗得，又将以色列人所献的火祭都赐给你父家吗？"},
{volumn:         9,chapter:         2,versenumber:        29,verse:"我所吩咐献在我居所的祭物，你们为何践踏？尊重你的儿子过于尊重我，将我民以色列所献美好的祭物肥己呢？’"},
{volumn:         9,chapter:         2,versenumber:        30,verse:"因此，耶和华以色列的　神说：‘我曾说，你和你父家必永远行在我面前；现在我却说，决不容你们这样行。因为尊重我的，我必重看他；藐视我的，他必被轻视。"},
{volumn:         9,chapter:         2,versenumber:        31,verse:"日子必到，我要折断你的膀臂和你父家的膀臂，使你家中没有一个老年人。"},
{volumn:         9,chapter:         2,versenumber:        32,verse:"在　神使以色列人享福的时候，你必看见我居所的败落。在你家中必永远没有一个老年人。"},
{volumn:         9,chapter:         2,versenumber:        33,verse:"我必不从我坛前灭尽你家中的人；那未灭的必使你眼目干瘪、心中忧伤。你家中所生的人都必死在中年。"},
{volumn:         9,chapter:         2,versenumber:        34,verse:"你的两个儿子何弗尼、非尼哈所遭遇的事可作你的证据：他们二人必一日同死。"},
{volumn:         9,chapter:         2,versenumber:        35,verse:"我要为自己立一个忠心的祭司；他必照我的心意而行。我要为他建立坚固的家；他必永远行在我的受膏者面前。"},
{volumn:         9,chapter:         2,versenumber:        36,verse:"你家所剩下的人都必来叩拜他，求块银子，求个饼，说：求你赐我祭司的职分，好叫我得点饼吃。’”"},
{volumn:         9,chapter:         3,versenumber:         1,verse:"童子撒母耳在以利面前侍奉耶和华。当那些日子，耶和华的言语稀少，不常有默示。"},
{volumn:         9,chapter:         3,versenumber:         2,verse:"一日，以利睡卧在自己的地方；他眼目昏花，看不分明。"},
{volumn:         9,chapter:         3,versenumber:         3,verse:"神的灯在　神耶和华殿内约柜那里，还没有熄灭，撒母耳已经睡了。"},
{volumn:         9,chapter:         3,versenumber:         4,verse:"耶和华呼唤撒母耳。撒母耳说：“我在这里！”"},
{volumn:         9,chapter:         3,versenumber:         5,verse:"就跑到以利那里，说：“你呼唤我？我在这里。”以利回答说：“我没有呼唤你，你去睡吧。”他就去睡了。"},
{volumn:         9,chapter:         3,versenumber:         6,verse:"耶和华又呼唤撒母耳。撒母耳起来，到以利那里，说：“你呼唤我？我在这里。”以利回答说：“我的儿，我没有呼唤你，你去睡吧。”"},
{volumn:         9,chapter:         3,versenumber:         7,verse:"那时撒母耳还未认识耶和华，也未得耶和华的默示。"},
{volumn:         9,chapter:         3,versenumber:         8,verse:"耶和华第三次呼唤撒母耳。撒母耳起来，到以利那里，说：“你又呼唤我？我在这里。”以利才明白是耶和华呼唤童子。"},
{volumn:         9,chapter:         3,versenumber:         9,verse:"因此以利对撒母耳说：“你仍去睡吧；若再呼唤你，你就说：‘耶和华啊，请说，仆人敬听！’”撒母耳就去，仍睡在原处。"},
{volumn:         9,chapter:         3,versenumber:        10,verse:"耶和华又来站着，像前三次呼唤说：“撒母耳啊！撒母耳啊！”撒母耳回答说：“请说，仆人敬听！”"},
{volumn:         9,chapter:         3,versenumber:        11,verse:"耶和华对撒母耳说：“我在以色列中必行一件事，叫听见的人都必耳鸣。"},
{volumn:         9,chapter:         3,versenumber:        12,verse:"我指着以利家所说的话，到了时候，我必始终应验在以利身上。"},
{volumn:         9,chapter:         3,versenumber:        13,verse:"我曾告诉他必永远降罚与他的家，因他知道儿子作孽，自招咒诅，却不禁止他们。"},
{volumn:         9,chapter:         3,versenumber:        14,verse:"所以我向以利家起誓说：‘以利家的罪孽，虽献祭奉礼物，永不能得赎去。’”"},
{volumn:         9,chapter:         3,versenumber:        15,verse:"撒母耳睡到天亮，就开了耶和华的殿门，不敢将默示告诉以利。"},
{volumn:         9,chapter:         3,versenumber:        16,verse:"以利呼唤撒母耳说：“我儿撒母耳啊！”撒母耳回答说：“我在这里！”"},
{volumn:         9,chapter:         3,versenumber:        17,verse:"以利说：“耶和华对你说什么，你不要向我隐瞒；你若将　神对你所说的隐瞒一句，愿他重重地降罚与你。”"},
{volumn:         9,chapter:         3,versenumber:        18,verse:"撒母耳就把一切话都告诉了以利，并没有隐瞒。以利说：“这是出于耶和华，愿他凭自己的意旨而行。”"},
{volumn:         9,chapter:         3,versenumber:        19,verse:"撒母耳长大了，耶和华与他同在，使他所说的话一句都不落空。"},
{volumn:         9,chapter:         3,versenumber:        20,verse:"从但到别是巴所有的以色列人都知道耶和华立撒母耳为先知。"},
{volumn:         9,chapter:         3,versenumber:        21,verse:"耶和华又在示罗显现；因为耶和华将自己的话默示撒母耳，撒母耳就把这话传遍以色列地。"},
{volumn:         9,chapter:         4,versenumber:         1,verse:"以色列人出去与非利士人打仗，安营在以便以谢；非利士人安营在亚弗。"},
{volumn:         9,chapter:         4,versenumber:         2,verse:"非利士人向以色列人摆阵。两军交战的时候，以色列人败在非利士人面前；非利士人在战场上杀了他们的军兵约有四千人。"},
{volumn:         9,chapter:         4,versenumber:         3,verse:"百姓回到营里，以色列的长老说：“耶和华今日为何使我们败在非利士人面前呢？我们不如将耶和华的约柜从示罗抬到我们这里来，好在我们中间救我们脱离敌人的手。”"},
{volumn:         9,chapter:         4,versenumber:         4,verse:"于是百姓打发人到示罗，从那里将坐在二基路伯上万军之耶和华的约柜抬来。以利的两个儿子何弗尼、非尼哈与　神的约柜同来。"},
{volumn:         9,chapter:         4,versenumber:         5,verse:"耶和华的约柜到了营中，以色列众人就大声欢呼，地便震动。"},
{volumn:         9,chapter:         4,versenumber:         6,verse:"非利士人听见欢呼的声音，就说：“在希伯来人营里大声欢呼，是什么缘故呢？”随后就知道耶和华的约柜到了营中。"},
{volumn:         9,chapter:         4,versenumber:         7,verse:"非利士人就惧怕起来，说：“有神到了他们营中”；又说：“我们有祸了！向来不曾有这样的事。"},
{volumn:         9,chapter:         4,versenumber:         8,verse:"我们有祸了！谁能救我们脱离这些大能之神的手呢？从前在旷野用各样灾殃击打埃及人的，就是这些神。"},
{volumn:         9,chapter:         4,versenumber:         9,verse:"非利士人哪，你们要刚强，要作大丈夫，免得作希伯来人的奴仆，如同他们作你们的奴仆一样。你们要作大丈夫，与他们争战。”"},
{volumn:         9,chapter:         4,versenumber:        10,verse:"非利士人和以色列人打仗，以色列人败了，各向各家奔逃，被杀的人甚多，以色列的步兵仆倒了三万。"},
{volumn:         9,chapter:         4,versenumber:        11,verse:"神的约柜被掳去，以利的两个儿子何弗尼、非尼哈也都被杀了。"},
{volumn:         9,chapter:         4,versenumber:        12,verse:"当日，有一个便雅悯人从阵上逃跑，衣服撕裂，头蒙灰尘，来到示罗。"},
{volumn:         9,chapter:         4,versenumber:        13,verse:"到了的时候，以利正在道旁坐在自己的位上观望，为　神的约柜心里担忧。那人进城报信，合城的人就都呼喊起来。"},
{volumn:         9,chapter:         4,versenumber:        14,verse:"以利听见呼喊的声音就问说：“这喧嚷是什么缘故呢？”那人急忙来报信给以利。"},
{volumn:         9,chapter:         4,versenumber:        15,verse:"那时以利九十八岁了，眼目发直，不能看见。"},
{volumn:         9,chapter:         4,versenumber:        16,verse:"那人对以利说：“我是从阵上来的，今日我从阵上逃回。”以利说：“我儿，事情怎样？”"},
{volumn:         9,chapter:         4,versenumber:        17,verse:"报信的回答说：“以色列人在非利士人面前逃跑，民中被杀的甚多！你的两个儿子何弗尼、非尼哈也都死了，并且　神的约柜被掳去。”"},
{volumn:         9,chapter:         4,versenumber:        18,verse:"他一提　神的约柜，以利就从他的位上往后跌倒，在门旁折断颈项而死；因为他年纪老迈，身体沉重。以利作以色列的士师四十年。"},
{volumn:         9,chapter:         4,versenumber:        19,verse:"以利的儿妇、非尼哈的妻怀孕将到产期，她听见　神的约柜被掳去，公公和丈夫都死了，就猛然疼痛，曲身生产；"},
{volumn:         9,chapter:         4,versenumber:        20,verse:"将要死的时候，旁边站着的妇人们对她说：“不要怕！你生了男孩子了。”她却不回答，也不放在心上。"},
{volumn:         9,chapter:         4,versenumber:        21,verse:"她给孩子起名叫以迦博，说：“荣耀离开以色列了！”这是因　神的约柜被掳去，又因她公公和丈夫都死了。"},
{volumn:         9,chapter:         4,versenumber:        22,verse:"她又说：“荣耀离开以色列，因为　神的约柜被掳去了。”"},
{volumn:         9,chapter:         5,versenumber:         1,verse:"非利士人将　神的约柜从以便以谢抬到亚实突。"},
{volumn:         9,chapter:         5,versenumber:         2,verse:"非利士人将　神的约柜抬进大衮庙，放在大衮的旁边。"},
{volumn:         9,chapter:         5,versenumber:         3,verse:"次日清早，亚实突人起来，见大衮仆倒在耶和华的约柜前，脸伏于地，就把大衮仍立在原处。"},
{volumn:         9,chapter:         5,versenumber:         4,verse:"又次日清早起来，见大衮仆倒在耶和华的约柜前，脸伏于地，并且大衮的头和两手都在门槛上折断，只剩下大衮的残体。（"},
{volumn:         9,chapter:         5,versenumber:         5,verse:"因此，大衮的祭司和一切进亚实突、大衮庙的人都不踏大衮庙的门槛，直到今日。）"},
{volumn:         9,chapter:         5,versenumber:         6,verse:"耶和华的手重重加在亚实突人身上，败坏他们，使他们生痔疮。亚实突和亚实突的四境都是如此。"},
{volumn:         9,chapter:         5,versenumber:         7,verse:"亚实突人见这光景，就说：“以色列　神的约柜不可留在我们这里，因为他的手重重加在我们和我们神大衮的身上”；"},
{volumn:         9,chapter:         5,versenumber:         8,verse:"就打发人去请非利士的众首领来聚集，问他们说：“我们向以色列　神的约柜应当怎样行呢？”他们回答说：“可以将以色列　神的约柜运到迦特去。”于是将以色列　神的约柜运到那里去。"},
{volumn:         9,chapter:         5,versenumber:         9,verse:"运到之后，耶和华的手攻击那城，使那城的人大大惊慌，无论大小都生痔疮。"},
{volumn:         9,chapter:         5,versenumber:        10,verse:"他们就把　神的约柜送到以革伦。　神的约柜到了，以革伦人就喊嚷起来说：“他们将以色列　神的约柜运到我们这里，要害我们和我们的众民！”"},
{volumn:         9,chapter:         5,versenumber:        11,verse:"于是打发人去请非利士的众首领来，说：“愿你们将以色列　神的约柜送回原处，免得害了我们和我们的众民！”原来　神的手重重攻击那城，城中的人有因惊慌而死的；"},
{volumn:         9,chapter:         5,versenumber:        12,verse:"未曾死的人都生了痔疮。合城呼号，声音上达于天。"},
{volumn:         9,chapter:         6,versenumber:         1,verse:"耶和华的约柜在非利士人之地七个月。"},
{volumn:         9,chapter:         6,versenumber:         2,verse:"非利士人将祭司和占卜的聚了来，问他们说：“我们向耶和华的约柜应当怎样行？请指示我们用何法将约柜送回原处。”"},
{volumn:         9,chapter:         6,versenumber:         3,verse:"他们说：“若要将以色列　神的约柜送回去，不可空空地送去，必要给他献赔罪的礼物，然后你们可得痊愈，并知道他的手为何不离开你们。”"},
{volumn:         9,chapter:         6,versenumber:         4,verse:"非利士人说：“应当用什么献为赔罪的礼物呢？”他们回答说：“当照非利士首领的数目，用五个金痔疮，五个金老鼠，因为在你们众人和你们首领的身上都是一样的灾。"},
{volumn:         9,chapter:         6,versenumber:         5,verse:"所以当制造你们痔疮的像和毁坏你们田地老鼠的像，并要归荣耀给以色列的　神，或者他向你们和你们的　神，并你们的田地，把手放轻些。"},
{volumn:         9,chapter:         6,versenumber:         6,verse:"你们为何硬着心像埃及人和法老一样呢？　神在埃及人中间行奇事，埃及人岂不释放以色列人，他们就去了吗？"},
{volumn:         9,chapter:         6,versenumber:         7,verse:"现在你们应当造一辆新车，将两只未曾负轭有乳的母牛套在车上，使牛犊回家去，离开母牛。"},
{volumn:         9,chapter:         6,versenumber:         8,verse:"把耶和华的约柜放在车上，将所献赔罪的金物装在匣子里，放在柜旁，将柜送去。"},
{volumn:         9,chapter:         6,versenumber:         9,verse:"你们要看看：车若直行以色列的境界到伯·示麦去，这大灾就是耶和华降在我们身上的；若不然，便可以知道不是他的手击打我们，是我们偶然遇见的。”"},
{volumn:         9,chapter:         6,versenumber:        10,verse:"非利士人就这样行：将两只有乳的母牛套在车上，将牛犊关在家里，"},
{volumn:         9,chapter:         6,versenumber:        11,verse:"把耶和华的约柜和装金老鼠并金痔疮像的匣子都放在车上。"},
{volumn:         9,chapter:         6,versenumber:        12,verse:"牛直行大道，往伯·示麦去，一面走一面叫，不偏左右。非利士的首领跟在后面，直到伯·示麦的境界。"},
{volumn:         9,chapter:         6,versenumber:        13,verse:"伯·示麦人正在平原收割麦子，举目看见约柜，就欢喜了。"},
{volumn:         9,chapter:         6,versenumber:        14,verse:"车到了伯·示麦人约书亚的田间，就站住了。在那里有一块大磐石，他们把车劈了，将两只母牛献给耶和华为燔祭。"},
{volumn:         9,chapter:         6,versenumber:        15,verse:"利未人将耶和华的约柜和装金物的匣子拿下来，放在大磐石上。当日伯·示麦人将燔祭和平安祭献给耶和华。"},
{volumn:         9,chapter:         6,versenumber:        16,verse:"非利士人的五个首领看见，当日就回以革伦去了。"},
{volumn:         9,chapter:         6,versenumber:        17,verse:"非利士人献给耶和华作赔罪的金痔疮像，就是这些：一个是为亚实突，一个是为迦萨，一个是为亚实基伦，一个是为迦特，一个是为以革伦。"},
{volumn:         9,chapter:         6,versenumber:        18,verse:"金老鼠的数目是照非利士五个首领的城邑，就是坚固的城邑和乡村，以及大磐石。这磐石是放耶和华约柜的，到今日还在伯·示麦人约书亚的田间。"},
{volumn:         9,chapter:         6,versenumber:        19,verse:"耶和华因伯·示麦人擅观他的约柜，就击杀了他们七十人；那时有五万人在那里（原文是七十人加五万人）。百姓因耶和华大大击杀他们，就哀哭了。"},
{volumn:         9,chapter:         6,versenumber:        20,verse:"伯·示麦人说：“谁能在耶和华这圣洁的　神面前侍立呢？这约柜可以从我们这里送到谁那里去呢？”"},
{volumn:         9,chapter:         6,versenumber:        21,verse:"于是打发人去见基列·耶琳的居民，说：“非利士人将耶和华的约柜送回来了，你们下来将约柜接到你们那里去吧！”"},
{volumn:         9,chapter:         7,versenumber:         1,verse:"基列·耶琳人就下来，将耶和华的约柜接上去，放在山上亚比拿达的家中，分派他儿子以利亚撒看守耶和华的约柜。"},
{volumn:         9,chapter:         7,versenumber:         2,verse:"约柜在基列·耶琳许久。过了二十年，以色列全家都倾向耶和华。"},
{volumn:         9,chapter:         7,versenumber:         3,verse:"撒母耳对以色列全家说：“你们若一心归顺耶和华，就要把外邦的神和亚斯他录从你们中间除掉，专心归向耶和华，单单地侍奉他。他必救你们脱离非利士人的手。”"},
{volumn:         9,chapter:         7,versenumber:         4,verse:"以色列人就除掉诸巴力和亚斯他录，单单地侍奉耶和华。"},
{volumn:         9,chapter:         7,versenumber:         5,verse:"撒母耳说：“要使以色列众人聚集在米斯巴，我好为你们祷告耶和华。”"},
{volumn:         9,chapter:         7,versenumber:         6,verse:"他们就聚集在米斯巴，打水浇在耶和华面前，当日禁食，说：“我们得罪了耶和华。”于是撒母耳在米斯巴审判以色列人。"},
{volumn:         9,chapter:         7,versenumber:         7,verse:"非利士人听见以色列人聚集在米斯巴，非利士的首领就上来要攻击以色列人。以色列人听见，就惧怕非利士人。"},
{volumn:         9,chapter:         7,versenumber:         8,verse:"以色列人对撒母耳说：“愿你不住地为我们呼求耶和华我们的　神，救我们脱离非利士人的手。”"},
{volumn:         9,chapter:         7,versenumber:         9,verse:"撒母耳就把一只吃奶的羊羔献与耶和华作全牲的燔祭，为以色列人呼求耶和华；耶和华就应允他。"},
{volumn:         9,chapter:         7,versenumber:        10,verse:"撒母耳正献燔祭的时候，非利士人前来要与以色列人争战。当日，耶和华大发雷声，惊乱非利士人，他们就败在以色列人面前。"},
{volumn:         9,chapter:         7,versenumber:        11,verse:"以色列人从米斯巴出来，追赶非利士人，击杀他们，直到伯·甲的下边。"},
{volumn:         9,chapter:         7,versenumber:        12,verse:"撒母耳将一块石头立在米斯巴和善的中间，给石头起名叫以便以谢，说：“到如今耶和华都帮助我们。”"},
{volumn:         9,chapter:         7,versenumber:        13,verse:"从此，非利士人就被制伏，不敢再入以色列人的境内。撒母耳作士师的时候，耶和华的手攻击非利士人。"},
{volumn:         9,chapter:         7,versenumber:        14,verse:"非利士人所取以色列人的城邑，从以革伦直到迦特，都归以色列人了。属这些城的四境，以色列人也从非利士人手下收回。那时以色列人与亚摩利人和好。"},
{volumn:         9,chapter:         7,versenumber:        15,verse:"撒母耳平生作以色列的士师。"},
{volumn:         9,chapter:         7,versenumber:        16,verse:"他每年巡行到伯特利、吉甲、米斯巴，在这几处审判以色列人。"},
{volumn:         9,chapter:         7,versenumber:        17,verse:"随后回到拉玛，因为他的家在那里；也在那里审判以色列人，且为耶和华筑了一座坛。"},
{volumn:         9,chapter:         8,versenumber:         1,verse:"撒母耳年纪老迈，就立他儿子作以色列的士师。"},
{volumn:         9,chapter:         8,versenumber:         2,verse:"长子名叫约珥，次子名叫亚比亚；他们在别是巴作士师。"},
{volumn:         9,chapter:         8,versenumber:         3,verse:"他儿子不行他的道，贪图财利，收受贿赂，屈枉正直。"},
{volumn:         9,chapter:         8,versenumber:         4,verse:"以色列的长老都聚集，来到拉玛见撒母耳，"},
{volumn:         9,chapter:         8,versenumber:         5,verse:"对他说：“你年纪老迈了，你儿子不行你的道。现在求你为我们立一个王治理我们，像列国一样。”"},
{volumn:         9,chapter:         8,versenumber:         6,verse:"撒母耳不喜悦他们说“立一个王治理我们”，他就祷告耶和华。"},
{volumn:         9,chapter:         8,versenumber:         7,verse:"耶和华对撒母耳说：“百姓向你说的一切话，你只管依从；因为他们不是厌弃你，乃是厌弃我，不要我作他们的王。"},
{volumn:         9,chapter:         8,versenumber:         8,verse:"自从我领他们出埃及到如今，他们常常离弃我，侍奉别神。现在他们向你所行的，是照他们素来所行的。"},
{volumn:         9,chapter:         8,versenumber:         9,verse:"故此你要依从他们的话，只是当警戒他们，告诉他们将来那王怎样管辖他们。”"},
{volumn:         9,chapter:         8,versenumber:        10,verse:"撒母耳将耶和华的话都传给求他立王的百姓，说："},
{volumn:         9,chapter:         8,versenumber:        11,verse:"“管辖你们的王必这样行：他必派你们的儿子为他赶车、跟马，奔走在车前；"},
{volumn:         9,chapter:         8,versenumber:        12,verse:"又派他们作千夫长、五十夫长，为他耕种田地，收割庄稼，打造军器和车上的器械；"},
{volumn:         9,chapter:         8,versenumber:        13,verse:"必取你们的女儿为他制造香膏，做饭烤饼；"},
{volumn:         9,chapter:         8,versenumber:        14,verse:"也必取你们最好的田地、葡萄园、橄榄园赐给他的臣仆。"},
{volumn:         9,chapter:         8,versenumber:        15,verse:"你们的粮食和葡萄园所出的，他必取十分之一给他的太监和臣仆；"},
{volumn:         9,chapter:         8,versenumber:        16,verse:"又必取你们的仆人婢女，健壮的少年人和你们的驴，供他的差役。"},
{volumn:         9,chapter:         8,versenumber:        17,verse:"你们的羊群他必取十分之一，你们也必作他的仆人。"},
{volumn:         9,chapter:         8,versenumber:        18,verse:"那时你们必因所选的王哀求耶和华，耶和华却不应允你们。”"},
{volumn:         9,chapter:         8,versenumber:        19,verse:"百姓竟不肯听撒母耳的话，说：“不然！我们定要一个王治理我们，"},
{volumn:         9,chapter:         8,versenumber:        20,verse:"使我们像列国一样，有王治理我们，统领我们，为我们争战。”"},
{volumn:         9,chapter:         8,versenumber:        21,verse:"撒母耳听见百姓这一切话，就将这话陈明在耶和华面前。"},
{volumn:         9,chapter:         8,versenumber:        22,verse:"耶和华对撒母耳说：“你只管依从他们的话，为他们立王。”撒母耳对以色列人说：“你们各归各城去吧！”"},
{volumn:         9,chapter:         9,versenumber:         1,verse:"有一个便雅悯人，名叫基士，是便雅悯人亚斐亚的玄孙，比歌拉的曾孙，洗罗的孙子，亚别的儿子，是个大能的勇士（或译：大财主）。"},
{volumn:         9,chapter:         9,versenumber:         2,verse:"他有一个儿子，名叫扫罗，又健壮、又俊美，在以色列人中没有一个能比他的；身体比众民高过一头。"},
{volumn:         9,chapter:         9,versenumber:         3,verse:"扫罗的父亲基士丢了几头驴，他就吩咐儿子扫罗说：“你带一个仆人去寻找驴。”"},
{volumn:         9,chapter:         9,versenumber:         4,verse:"扫罗就走过以法莲山地，又过沙利沙地，都没有找着；又过沙琳地，驴也不在那里；又过便雅悯地，还没有找着。"},
{volumn:         9,chapter:         9,versenumber:         5,verse:"到了苏弗地，扫罗对跟随他的仆人说：“我们不如回去，恐怕我父亲不为驴挂心，反为我们担忧。”"},
{volumn:         9,chapter:         9,versenumber:         6,verse:"仆人说：“这城里有一位神人，是众人所尊重的，凡他所说的全都应验。我们不如往他那里去，或者他能将我们当走的路指示我们。”"},
{volumn:         9,chapter:         9,versenumber:         7,verse:"扫罗对仆人说：“我们若去，有什么可以送那人呢？我们囊中的食物都吃尽了，也没有礼物可以送那神人，我们还有什么没有？”"},
{volumn:         9,chapter:         9,versenumber:         8,verse:"仆人回答扫罗说：“我手里有银子一舍客勒的四分之一，可以送那神人，请他指示我们当走的路。”（"},
{volumn:         9,chapter:         9,versenumber:         9,verse:"从前以色列中，若有人去问　神，就说：“我们问先见去吧！”现在称为“先知”的，从前称为“先见”。）"},
{volumn:         9,chapter:         9,versenumber:        10,verse:"扫罗对仆人说：“你说的是，我们可以去。”于是他们往神人所住的城里去了。"},
{volumn:         9,chapter:         9,versenumber:        11,verse:"他们上坡要进城，就遇见几个少年女子出来打水，问她们说：“先见在这里没有？”"},
{volumn:         9,chapter:         9,versenumber:        12,verse:"女子回答说：“在这里，他在你们前面。快去吧！他今日正到城里，因为今日百姓要在邱坛献祭。"},
{volumn:         9,chapter:         9,versenumber:        13,verse:"在他还没有上邱坛吃祭物之先，你们一进城必遇见他；因他未到，百姓不能吃，必等他先祝祭，然后请的客才吃。现在你们上去，这时候必遇见他。”"},
{volumn:         9,chapter:         9,versenumber:        14,verse:"二人就上去；将进城的时候，撒母耳正迎着他们来，要上邱坛去。"},
{volumn:         9,chapter:         9,versenumber:        15,verse:"扫罗未到的前一日，耶和华已经指示撒母耳说："},
{volumn:         9,chapter:         9,versenumber:        16,verse:"“明日这时候，我必使一个人从便雅悯地到你这里来，你要膏他作我民以色列的君。他必救我民脱离非利士人的手；因我民的哀声上达于我，我就眷顾他们。”"},
{volumn:         9,chapter:         9,versenumber:        17,verse:"撒母耳看见扫罗的时候，耶和华对他说：“看哪，这人就是我对你所说的，他必治理我的民。”"},
{volumn:         9,chapter:         9,versenumber:        18,verse:"扫罗在城门里走到撒母耳跟前，说：“请告诉我，先见的寓所在哪里？”"},
{volumn:         9,chapter:         9,versenumber:        19,verse:"撒母耳回答说：“我就是先见。你在我前面上邱坛去，因为你们今日必与我同席；明日早晨我送你去，将你心里的事都告诉你。"},
{volumn:         9,chapter:         9,versenumber:        20,verse:"至于你前三日所丢的那几头驴，你心里不必挂念，已经找着了。以色列众人所仰慕的是谁呢？不是仰慕你和你父的全家吗？”"},
{volumn:         9,chapter:         9,versenumber:        21,verse:"扫罗说：“我不是以色列支派中至小的便雅悯人吗？我家不是便雅悯支派中至小的家吗？你为何对我说这样的话呢？”"},
{volumn:         9,chapter:         9,versenumber:        22,verse:"撒母耳领扫罗和他仆人进了客堂，使他们在请来的客中坐首位；客约有三十个人。"},
{volumn:         9,chapter:         9,versenumber:        23,verse:"撒母耳对厨役说：“我交给你收存的那一份祭肉现在可以拿来。”"},
{volumn:         9,chapter:         9,versenumber:        24,verse:"厨役就把收存的腿拿来，摆在扫罗面前，撒母耳说：“这是所留下的，放在你面前。吃吧！因我请百姓的时候，特意为你存留这肉到此时。”当日，扫罗就与撒母耳同席。"},
{volumn:         9,chapter:         9,versenumber:        25,verse:"众人从邱坛下来进城，撒母耳和扫罗在房顶上说话。"},
{volumn:         9,chapter:         9,versenumber:        26,verse:"次日清早起来，黎明的时候，扫罗在房顶上。撒母耳呼叫他说：“起来吧，我好送你回去。”扫罗就起来，和撒母耳一同出去。"},
{volumn:         9,chapter:         9,versenumber:        27,verse:"二人下到城角，撒母耳对扫罗说：“要吩咐仆人先走（仆人就先走了）；你且站在这里，等我将　神的话传与你听。”"},
{volumn:         9,chapter:        10,versenumber:         1,verse:"撒母耳拿瓶膏油倒在扫罗的头上，与他亲嘴，说：“这不是耶和华膏你作他产业的君吗？"},
{volumn:         9,chapter:        10,versenumber:         2,verse:"你今日与我离别之后，在便雅悯境内的泄撒，靠近拉结的坟墓，要遇见两个人。他们必对你说：‘你去找的那几头驴已经找着了。现在你父亲不为驴挂心，反为你担忧，说：我为儿子怎么才好呢？’"},
{volumn:         9,chapter:        10,versenumber:         3,verse:"你从那里往前行，到了他泊的橡树那里，必遇见三个往伯特利去拜　神的人：一个带着三只山羊羔，一个带着三个饼，一个带着一皮袋酒。"},
{volumn:         9,chapter:        10,versenumber:         4,verse:"他们必问你安，给你两个饼，你就从他们手中接过来。"},
{volumn:         9,chapter:        10,versenumber:         5,verse:"此后你到　神的山，在那里有非利士人的防兵。你到了城的时候，必遇见一班先知从邱坛下来，前面有鼓瑟的、击鼓的、吹笛的、弹琴的，他们都受感说话。"},
{volumn:         9,chapter:        10,versenumber:         6,verse:"耶和华的灵必大大感动你，你就与他们一同受感说话；你要变为新人。"},
{volumn:         9,chapter:        10,versenumber:         7,verse:"这兆头临到你，你就可以趁时而做，因为　神与你同在。"},
{volumn:         9,chapter:        10,versenumber:         8,verse:"你当在我以先下到吉甲，我也必下到那里献燔祭和平安祭。你要等候七日，等我到了那里，指示你当行的事。”"},
{volumn:         9,chapter:        10,versenumber:         9,verse:"扫罗转身离别撒母耳，　神就赐他一个新心。当日这一切兆头都应验了。"},
{volumn:         9,chapter:        10,versenumber:        10,verse:"扫罗到了那山，有一班先知遇见他，　神的灵大大感动他，他就在先知中受感说话。"},
{volumn:         9,chapter:        10,versenumber:        11,verse:"素来认识扫罗的，看见他和先知一同受感说话，就彼此说：“基士的儿子遇见什么了？扫罗也列在先知中吗？”"},
{volumn:         9,chapter:        10,versenumber:        12,verse:"那地方有一个人说：“这些人的父亲是谁呢？”此后有句俗语说：“扫罗也列在先知中吗？”"},
{volumn:         9,chapter:        10,versenumber:        13,verse:"扫罗受感说话已毕，就上邱坛去了。"},
{volumn:         9,chapter:        10,versenumber:        14,verse:"扫罗的叔叔问扫罗和他仆人说：“你们往哪里去了？”回答说：“找驴去了。我们见没有驴，就到了撒母耳那里。”"},
{volumn:         9,chapter:        10,versenumber:        15,verse:"扫罗的叔叔说：“请将撒母耳向你们所说的话告诉我。”"},
{volumn:         9,chapter:        10,versenumber:        16,verse:"扫罗对他叔叔说：“他明明地告诉我们驴已经找着了。”至于撒母耳所说的国事，扫罗却没有告诉叔叔。"},
{volumn:         9,chapter:        10,versenumber:        17,verse:"撒母耳将百姓招聚到米斯巴耶和华那里，"},
{volumn:         9,chapter:        10,versenumber:        18,verse:"对他们说：“耶和华以色列的　神如此说：‘我领你们以色列人出埃及，救你们脱离埃及人的手，又救你们脱离欺压你们各国之人的手。’"},
{volumn:         9,chapter:        10,versenumber:        19,verse:"你们今日却厌弃了救你们脱离一切灾难的　神，说：‘求你立一个王治理我们。’现在你们应当按着支派、宗族都站在耶和华面前。”"},
{volumn:         9,chapter:        10,versenumber:        20,verse:"于是，撒母耳使以色列众支派近前来掣签，就掣出便雅悯支派来；"},
{volumn:         9,chapter:        10,versenumber:        21,verse:"又使便雅悯支派按着宗族近前来，就掣出玛特利族，从其中又掣出基士的儿子扫罗。众人寻找他却寻不着，"},
{volumn:         9,chapter:        10,versenumber:        22,verse:"就问耶和华说：“那人到这里来了没有？”耶和华说：“他藏在器具中了。”"},
{volumn:         9,chapter:        10,versenumber:        23,verse:"众人就跑去从那里领出他来。他站在百姓中间，身体比众民高过一头。"},
{volumn:         9,chapter:        10,versenumber:        24,verse:"撒母耳对众民说：“你们看耶和华所拣选的人，众民中有可比他的吗？”众民就大声欢呼说：“愿王万岁！”"},
{volumn:         9,chapter:        10,versenumber:        25,verse:"撒母耳将国法对百姓说明，又记在书上，放在耶和华面前，然后遣散众民，各回各家去了。"},
{volumn:         9,chapter:        10,versenumber:        26,verse:"扫罗往基比亚回家去，有　神感动的一群人跟随他。"},
{volumn:         9,chapter:        10,versenumber:        27,verse:"但有些匪徒说：“这人怎能救我们呢？”就藐视他，没有送他礼物；扫罗却不理会。"},
{volumn:         9,chapter:        11,versenumber:         1,verse:"亚扪人的王拿辖上来，对着基列·雅比安营。雅比众人对拿辖说：“你与我们立约，我们就服侍你。”"},
{volumn:         9,chapter:        11,versenumber:         2,verse:"亚扪人拿辖说：“你们若由我剜出你们各人的右眼，以此凌辱以色列众人，我就与你们立约。”"},
{volumn:         9,chapter:        11,versenumber:         3,verse:"雅比的长老对他说：“求你宽容我们七日，等我们打发人往以色列的全境去；若没有人救我们，我们就出来归顺你。”"},
{volumn:         9,chapter:        11,versenumber:         4,verse:"使者到了扫罗住的基比亚，将这话说给百姓听，百姓就都放声而哭。"},
{volumn:         9,chapter:        11,versenumber:         5,verse:"扫罗正从田间赶牛回来，问说：“百姓为什么哭呢？”众人将雅比人的话告诉他。"},
{volumn:         9,chapter:        11,versenumber:         6,verse:"扫罗听见这话，就被　神的灵大大感动，甚是发怒。"},
{volumn:         9,chapter:        11,versenumber:         7,verse:"他将一对牛切成块子，托付使者传送以色列的全境，说：“凡不出来跟随扫罗和撒母耳的，也必这样切开他的牛。”于是耶和华使百姓惧怕，他们就都出来，如同一人。"},
{volumn:         9,chapter:        11,versenumber:         8,verse:"扫罗在比色数点他们：以色列人有三十万，犹大人有三万。"},
{volumn:         9,chapter:        11,versenumber:         9,verse:"众人对那使者说：“你们要回复基列·雅比人说，明日太阳近午的时候，你们必得解救。”使者回去告诉雅比人，他们就欢喜了。"},
{volumn:         9,chapter:        11,versenumber:        10,verse:"于是雅比人对亚扪人说：“明日我们出来归顺你们，你们可以随意待我们。”"},
{volumn:         9,chapter:        11,versenumber:        11,verse:"第二日，扫罗将百姓分为三队，在晨更的时候入了亚扪人的营，击杀他们直到太阳近午，剩下的人都逃散，没有二人同在一处的。"},
{volumn:         9,chapter:        11,versenumber:        12,verse:"百姓对撒母耳说：“那说‘扫罗岂能管理我们’的是谁呢？可以将他交出来，我们好杀死他。”"},
{volumn:         9,chapter:        11,versenumber:        13,verse:"扫罗说：“今日耶和华在以色列中施行拯救，所以不可杀人。”"},
{volumn:         9,chapter:        11,versenumber:        14,verse:"撒母耳对百姓说：“我们要往吉甲去，在那里立国。”"},
{volumn:         9,chapter:        11,versenumber:        15,verse:"众百姓就到了吉甲那里，在耶和华面前立扫罗为王，又在耶和华面前献平安祭。扫罗和以色列众人大大欢喜。"},
{volumn:         9,chapter:        12,versenumber:         1,verse:"撒母耳对以色列众人说：“你们向我所求的，我已应允了，为你们立了一个王；"},
{volumn:         9,chapter:        12,versenumber:         2,verse:"现在有这王在你们前面行。我已年老发白，我的儿子都在你们这里。我从幼年直到今日都在你们前面行。"},
{volumn:         9,chapter:        12,versenumber:         3,verse:"我在这里，你们要在耶和华和他的受膏者面前给我作见证。我夺过谁的牛，抢过谁的驴，欺负过谁，虐待过谁，从谁手里受过贿赂因而眼瞎呢？若有，我必偿还。”"},
{volumn:         9,chapter:        12,versenumber:         4,verse:"众人说：“你未曾欺负我们，虐待我们，也未曾从谁手里受过什么。”"},
{volumn:         9,chapter:        12,versenumber:         5,verse:"撒母耳对他们说：“你们在我手里没有找着什么，有耶和华和他的受膏者今日为证。”他们说：“愿他为证。”"},
{volumn:         9,chapter:        12,versenumber:         6,verse:"撒母耳对百姓说：“从前立摩西、亚伦，又领你们列祖出埃及地的是耶和华。"},
{volumn:         9,chapter:        12,versenumber:         7,verse:"现在你们要站住，等我在耶和华面前对你们讲论耶和华向你们和你们列祖所行一切公义的事。"},
{volumn:         9,chapter:        12,versenumber:         8,verse:"从前雅各到了埃及，后来你们列祖呼求耶和华，耶和华就差遣摩西、亚伦领你们列祖出埃及，使他们在这地方居住。"},
{volumn:         9,chapter:        12,versenumber:         9,verse:"他们却忘记耶和华他们的　神，他就把他们付与夏琐将军西西拉的手里，和非利士人并摩押王的手里。于是这些人常来攻击他们。"},
{volumn:         9,chapter:        12,versenumber:        10,verse:"他们就呼求耶和华说：‘我们离弃耶和华，侍奉巴力和亚斯他录，是有罪了。现在求你救我们脱离仇敌的手，我们必侍奉你。’"},
{volumn:         9,chapter:        12,versenumber:        11,verse:"耶和华就差遣耶路·巴力、比但、耶弗他、撒母耳救你们脱离四围仇敌的手，你们才安然居住。"},
{volumn:         9,chapter:        12,versenumber:        12,verse:"你们见亚扪人的王拿辖来攻击你们，就对我说：‘我们定要一个王治理我们。’其实耶和华你们的　神是你们的王。"},
{volumn:         9,chapter:        12,versenumber:        13,verse:"现在，你们所求所选的王在这里。看哪，耶和华已经为你们立王了。"},
{volumn:         9,chapter:        12,versenumber:        14,verse:"你们若敬畏耶和华，侍奉他，听从他的话，不违背他的命令，你们和治理你们的王也都顺从耶和华你们的　神就好了。"},
{volumn:         9,chapter:        12,versenumber:        15,verse:"倘若不听从耶和华的话，违背他的命令，耶和华的手必攻击你们，像从前攻击你们列祖一样。"},
{volumn:         9,chapter:        12,versenumber:        16,verse:"现在你们要站住，看耶和华在你们眼前要行一件大事。"},
{volumn:         9,chapter:        12,versenumber:        17,verse:"这不是割麦子的时候吗？我求告耶和华，他必打雷降雨，使你们又知道又看出，你们求立王的事是在耶和华面前犯大罪了。”"},
{volumn:         9,chapter:        12,versenumber:        18,verse:"于是撒母耳求告耶和华，耶和华就在这日打雷降雨，众民便甚惧怕耶和华和撒母耳。"},
{volumn:         9,chapter:        12,versenumber:        19,verse:"众民对撒母耳说：“求你为仆人们祷告耶和华你的　神，免得我们死亡，因为我们求立王的事正是罪上加罪了。”"},
{volumn:         9,chapter:        12,versenumber:        20,verse:"撒母耳对百姓说：“不要惧怕！你们虽然行了这恶，却不要偏离耶和华，只要尽心侍奉他。"},
{volumn:         9,chapter:        12,versenumber:        21,verse:"若偏离耶和华去顺从那不能救人的虚神是无益的。"},
{volumn:         9,chapter:        12,versenumber:        22,verse:"耶和华既喜悦选你们作他的子民，就必因他的大名不撇弃你们。"},
{volumn:         9,chapter:        12,versenumber:        23,verse:"至于我，断不停止为你们祷告，以致得罪耶和华。我必以善道正路指教你们。"},
{volumn:         9,chapter:        12,versenumber:        24,verse:"只要你们敬畏耶和华，诚诚实实地尽心侍奉他，想念他向你们所行的事何等大。"},
{volumn:         9,chapter:        12,versenumber:        25,verse:"你们若仍然作恶，你们和你们的王必一同灭亡。”"},
{volumn:         9,chapter:        13,versenumber:         1,verse:"扫罗登基年四十岁；作以色列王二年的时候，"},
{volumn:         9,chapter:        13,versenumber:         2,verse:"就从以色列中拣选了三千人：二千跟随扫罗在密抹和伯特利山，一千跟随约拿单在便雅悯的基比亚；其余的人扫罗都打发各回各家去了。"},
{volumn:         9,chapter:        13,versenumber:         3,verse:"约拿单攻击在迦巴的非利士人的防营，非利士人听见了。扫罗就在遍地吹角，意思说，要使希伯来人听见。"},
{volumn:         9,chapter:        13,versenumber:         4,verse:"以色列众人听见扫罗攻击非利士人的防营，又听见以色列人为非利士人所憎恶，就跟随扫罗聚集在吉甲。"},
{volumn:         9,chapter:        13,versenumber:         5,verse:"非利士人聚集，要与以色列人争战，有车三万辆，马兵六千，步兵像海边的沙那样多，就上来在伯·亚文东边的密抹安营。"},
{volumn:         9,chapter:        13,versenumber:         6,verse:"以色列百姓见自己危急窘迫，就藏在山洞、丛林、石穴、隐密处，和坑中。"},
{volumn:         9,chapter:        13,versenumber:         7,verse:"有些希伯来人过了约旦河，逃到迦得和基列地。扫罗还是在吉甲，百姓都战战兢兢地跟随他。"},
{volumn:         9,chapter:        13,versenumber:         8,verse:"扫罗照着撒母耳所定的日期等了七日。撒母耳还没有来到吉甲，百姓也离开扫罗散去了。"},
{volumn:         9,chapter:        13,versenumber:         9,verse:"扫罗说：“把燔祭和平安祭带到我这里来。”扫罗就献上燔祭。"},
{volumn:         9,chapter:        13,versenumber:        10,verse:"刚献完燔祭，撒母耳就到了。扫罗出去迎接他，要问他好。"},
{volumn:         9,chapter:        13,versenumber:        11,verse:"撒母耳说：“你做的是什么事呢？”扫罗说：“因为我见百姓离开我散去，你也不照所定的日期来到，而且非利士人聚集在密抹。"},
{volumn:         9,chapter:        13,versenumber:        12,verse:"所以我心里说：恐怕我没有祷告耶和华。非利士人下到吉甲攻击我，我就勉强献上燔祭。”"},
{volumn:         9,chapter:        13,versenumber:        13,verse:"撒母耳对扫罗说：“你做了糊涂事了，没有遵守耶和华你　神所吩咐你的命令。若遵守，耶和华必在以色列中坚立你的王位，直到永远。"},
{volumn:         9,chapter:        13,versenumber:        14,verse:"现在你的王位必不长久。耶和华已经寻着一个合他心意的人，立他作百姓的君，因为你没有遵守耶和华所吩咐你的。”"},
{volumn:         9,chapter:        13,versenumber:        15,verse:"撒母耳就起来，从吉甲上到便雅悯的基比亚。扫罗数点跟随他的，约有六百人。"},
{volumn:         9,chapter:        13,versenumber:        16,verse:"扫罗和他儿子约拿单，并跟随他们的人，都住在便雅悯的迦巴；但非利士人安营在密抹。"},
{volumn:         9,chapter:        13,versenumber:        17,verse:"有掠兵从非利士营中出来，分为三队：一队往俄弗拉向书亚地去，"},
{volumn:         9,chapter:        13,versenumber:        18,verse:"一队往伯·和仑去，一队往洗波音谷对面的地境向旷野去。"},
{volumn:         9,chapter:        13,versenumber:        19,verse:"那时，以色列全地没有一个铁匠；因为非利士人说，恐怕希伯来人制造刀枪。"},
{volumn:         9,chapter:        13,versenumber:        20,verse:"以色列人要磨锄、犁、斧、铲，就下到非利士人那里去磨。"},
{volumn:         9,chapter:        13,versenumber:        21,verse:"但有锉可以锉铲、犁、三齿叉、斧子，并赶牛锥。"},
{volumn:         9,chapter:        13,versenumber:        22,verse:"所以到了争战的日子，跟随扫罗和约拿单的人没有一个手里有刀有枪的，惟独扫罗和他儿子约拿单有。"},
{volumn:         9,chapter:        13,versenumber:        23,verse:"非利士人的一队防兵到了密抹的隘口。"},
{volumn:         9,chapter:        14,versenumber:         1,verse:"有一日，扫罗的儿子约拿单对拿他兵器的少年人说：“我们不如过到那边，到非利士人的防营那里去。”但他没有告诉父亲。"},
{volumn:         9,chapter:        14,versenumber:         2,verse:"扫罗在基比亚的尽边，坐在米矶仑的石榴树下，跟随他的约有六百人。"},
{volumn:         9,chapter:        14,versenumber:         3,verse:"在那里有亚希突的儿子亚希亚，穿着以弗得。（亚希突是以迦博的哥哥，非尼哈的儿子，以利的孙子。以利从前在示罗作耶和华的祭司。）约拿单去了，百姓却不知道。"},
{volumn:         9,chapter:        14,versenumber:         4,verse:"约拿单要从隘口过到非利士防营那里去。这隘口两边各有一个山峰：一名播薛，一名西尼；"},
{volumn:         9,chapter:        14,versenumber:         5,verse:"一峰向北，与密抹相对，一峰向南，与迦巴相对。"},
{volumn:         9,chapter:        14,versenumber:         6,verse:"约拿单对拿兵器的少年人说：“我们不如过到未受割礼人的防营那里去，或者耶和华为我们施展能力；因为耶和华使人得胜，不在乎人多人少。”"},
{volumn:         9,chapter:        14,versenumber:         7,verse:"拿兵器的对他说：“随你的心意行吧。你可以上去，我必跟随你，与你同心。”"},
{volumn:         9,chapter:        14,versenumber:         8,verse:"约拿单说：“我们要过到那些人那里去，使他们看见我们。"},
{volumn:         9,chapter:        14,versenumber:         9,verse:"他们若对我们说：‘你们站住，等我们到你们那里去’，我们就站住，不上他们那里去。"},
{volumn:         9,chapter:        14,versenumber:        10,verse:"他们若说：‘你们上到我们这里来’，这话就是我们的证据；我们便上去，因为耶和华将他们交在我们手里了。”"},
{volumn:         9,chapter:        14,versenumber:        11,verse:"二人就使非利士的防兵看见。非利士人说：“希伯来人从所藏的洞穴里出来了！”"},
{volumn:         9,chapter:        14,versenumber:        12,verse:"防兵对约拿单和拿兵器的人说：“你们上到这里来，我们有一件事指示你们。”约拿单就对拿兵器的人说：“你跟随我上去，因为耶和华将他们交在以色列人手里了。”"},
{volumn:         9,chapter:        14,versenumber:        13,verse:"约拿单就爬上去，拿兵器的人跟随他。约拿单杀倒非利士人，拿兵器的人也随着杀他们。"},
{volumn:         9,chapter:        14,versenumber:        14,verse:"约拿单和拿兵器的人起头所杀的约有二十人，都在一亩地的半犁沟之内。"},
{volumn:         9,chapter:        14,versenumber:        15,verse:"于是在营中、在田野、在众民内都有战兢，防兵和掠兵也都战兢，地也震动，战兢之势甚大。"},
{volumn:         9,chapter:        14,versenumber:        16,verse:"在便雅悯的基比亚，扫罗的守望兵看见非利士的军众溃散，四围乱窜。"},
{volumn:         9,chapter:        14,versenumber:        17,verse:"扫罗就对跟随他的民说：“你们查点查点，看从我们这里出去的是谁？”他们一查点，就知道约拿单和拿兵器的人没有在这里。"},
{volumn:         9,chapter:        14,versenumber:        18,verse:"那时　神的约柜在以色列人那里。扫罗对亚希亚说：“你将　神的约柜运了来。”"},
{volumn:         9,chapter:        14,versenumber:        19,verse:"扫罗正与祭司说话的时候，非利士营中的喧嚷越发大了；扫罗就对祭司说：“停手吧！”"},
{volumn:         9,chapter:        14,versenumber:        20,verse:"扫罗和跟随他的人都聚集，来到战场，看见非利士人用刀互相击杀，大大惶乱。"},
{volumn:         9,chapter:        14,versenumber:        21,verse:"从前由四方来跟随非利士军的希伯来人现在也转过来，帮助跟随扫罗和约拿单的以色列人了。"},
{volumn:         9,chapter:        14,versenumber:        22,verse:"那藏在以法莲山地的以色列人听说非利士人逃跑，就出来紧紧地追杀他们。"},
{volumn:         9,chapter:        14,versenumber:        23,verse:"那日，耶和华使以色列人得胜，一直战到伯·亚文。"},
{volumn:         9,chapter:        14,versenumber:        24,verse:"扫罗叫百姓起誓说，凡不等到晚上向敌人报完了仇吃什么的，必受咒诅。因此这日百姓没有吃什么，就极其困惫。"},
{volumn:         9,chapter:        14,versenumber:        25,verse:"众民进入树林，见有蜜在地上。"},
{volumn:         9,chapter:        14,versenumber:        26,verse:"他们进了树林，见有蜜流下来，却没有人敢用手取蜜入口，因为他们怕那誓言。"},
{volumn:         9,chapter:        14,versenumber:        27,verse:"约拿单没有听见他父亲叫百姓起誓，所以伸手中的杖，用杖头蘸在蜂房里，转手送入口内，眼睛就明亮了。"},
{volumn:         9,chapter:        14,versenumber:        28,verse:"百姓中有一人对他说：“你父亲曾叫百姓严严地起誓说，今日吃什么的，必受咒诅；因此百姓就疲乏了。”"},
{volumn:         9,chapter:        14,versenumber:        29,verse:"约拿单说：“我父亲连累你们了。你看，我尝了这一点蜜，眼睛就明亮了。"},
{volumn:         9,chapter:        14,versenumber:        30,verse:"今日百姓若任意吃了从仇敌所夺的物，击杀的非利士人岂不更多吗？”"},
{volumn:         9,chapter:        14,versenumber:        31,verse:"这日，以色列人击杀非利士人，从密抹直到亚雅仑。百姓甚是疲乏，"},
{volumn:         9,chapter:        14,versenumber:        32,verse:"就急忙将所夺的牛羊和牛犊宰于地上，肉还带血就吃了。"},
{volumn:         9,chapter:        14,versenumber:        33,verse:"有人告诉扫罗说：“百姓吃带血的肉，得罪耶和华了。”扫罗说：“你们有罪了，今日要将大石头滚到我这里来。”"},
{volumn:         9,chapter:        14,versenumber:        34,verse:"扫罗又说：“你们散在百姓中，对他们说，你们各人将牛羊牵到我这里来宰了吃，不可吃带血的肉得罪耶和华。”这夜，百姓就把牛羊牵到那里宰了。"},
{volumn:         9,chapter:        14,versenumber:        35,verse:"扫罗为耶和华筑了一座坛，这是他初次为耶和华筑的坛。"},
{volumn:         9,chapter:        14,versenumber:        36,verse:"扫罗说：“我们不如夜里下去追赶非利士人，抢掠他们，直到天亮，不留他们一人。”众民说：“你看怎样好就去行吧！”祭司说：“我们先当亲近　神。”"},
{volumn:         9,chapter:        14,versenumber:        37,verse:"扫罗求问　神说：“我下去追赶非利士人可以不可以？你将他们交在以色列人手里不交？”这日　神没有回答他。"},
{volumn:         9,chapter:        14,versenumber:        38,verse:"扫罗说：“你们百姓中的长老都上这里来，查明今日是谁犯了罪。"},
{volumn:         9,chapter:        14,versenumber:        39,verse:"我指着救以色列永生的耶和华起誓，就是我儿子约拿单犯了罪，他也必死。”但百姓中无一人回答他。"},
{volumn:         9,chapter:        14,versenumber:        40,verse:"扫罗就对以色列众人说：“你们站在一边，我与我儿子约拿单也站在一边。”百姓对扫罗说：“你看怎样好就去行吧！”"},
{volumn:         9,chapter:        14,versenumber:        41,verse:"扫罗祷告耶和华以色列的　神说：“求你指示实情。”于是掣签掣出扫罗和约拿单来；百姓尽都无事。"},
{volumn:         9,chapter:        14,versenumber:        42,verse:"扫罗说：“你们再掣签，看是我，是我儿子约拿单”，就掣出约拿单来。"},
{volumn:         9,chapter:        14,versenumber:        43,verse:"扫罗对约拿单说：“你告诉我，你做了什么事？”约拿单说：“我实在以手里的杖，用杖头蘸了一点蜜尝了一尝。这样我就死吗（或译：吧！）？”"},
{volumn:         9,chapter:        14,versenumber:        44,verse:"扫罗说：“约拿单哪，你定要死！若不然，愿　神重重地降罚与我。”"},
{volumn:         9,chapter:        14,versenumber:        45,verse:"百姓对扫罗说：“约拿单在以色列人中这样大行拯救，岂可使他死呢？断乎不可！我们指着永生的耶和华起誓，连他的一根头发也不可落地，因为他今日与　神一同做事。”于是百姓救约拿单免了死亡。"},
{volumn:         9,chapter:        14,versenumber:        46,verse:"扫罗回去，不追赶非利士人；非利士人也回本地去了。"},
{volumn:         9,chapter:        14,versenumber:        47,verse:"扫罗执掌以色列的国权，常常攻击他四围的一切仇敌，就是摩押人、亚扪人、以东人，和琐巴诸王，并非利士人。他无论往何处去，都打败仇敌。"},
{volumn:         9,chapter:        14,versenumber:        48,verse:"扫罗奋勇攻击亚玛力人，救了以色列人脱离抢掠他们之人的手。"},
{volumn:         9,chapter:        14,versenumber:        49,verse:"扫罗的儿子是约拿单、亦施韦、麦基舒亚。他的两个女儿：长女名米拉，次女名米甲。"},
{volumn:         9,chapter:        14,versenumber:        50,verse:"扫罗的妻名叫亚希暖，是亚希玛斯的女儿。扫罗的元帅名叫押尼珥，是尼珥的儿子；尼珥是扫罗的叔叔。"},
{volumn:         9,chapter:        14,versenumber:        51,verse:"扫罗的父亲基士，押尼珥的父亲尼珥，都是亚别的儿子。"},
{volumn:         9,chapter:        14,versenumber:        52,verse:"扫罗平生常与非利士人大大争战。扫罗遇见有能力的人或勇士，都招募了来跟随他。"},
{volumn:         9,chapter:        15,versenumber:         1,verse:"撒母耳对扫罗说：“耶和华差遣我膏你为王，治理他的百姓以色列；所以你当听从耶和华的话。"},
{volumn:         9,chapter:        15,versenumber:         2,verse:"万军之耶和华如此说：‘以色列人出埃及的时候，在路上亚玛力人怎样待他们，怎样抵挡他们，我都没忘。"},
{volumn:         9,chapter:        15,versenumber:         3,verse:"现在你要去击打亚玛力人，灭尽他们所有的，不可怜惜他们，将男女、孩童、吃奶的，并牛、羊、骆驼，和驴尽行杀死。’”"},
{volumn:         9,chapter:        15,versenumber:         4,verse:"于是扫罗招聚百姓在提拉因，数点他们，共有步兵二十万，另有犹大人一万。"},
{volumn:         9,chapter:        15,versenumber:         5,verse:"扫罗到了亚玛力的京城，在谷中设下埋伏。"},
{volumn:         9,chapter:        15,versenumber:         6,verse:"扫罗对基尼人说：“你们离开亚玛力人下去吧，恐怕我将你们和亚玛力人一同杀灭；因为以色列人出埃及的时候，你们曾恩待他们。”于是基尼人离开亚玛力人去了。"},
{volumn:         9,chapter:        15,versenumber:         7,verse:"扫罗击打亚玛力人，从哈腓拉直到埃及前的书珥，"},
{volumn:         9,chapter:        15,versenumber:         8,verse:"生擒了亚玛力王亚甲，用刀杀尽亚玛力的众民。"},
{volumn:         9,chapter:        15,versenumber:         9,verse:"扫罗和百姓却怜惜亚甲，也爱惜上好的牛、羊、牛犊、羊羔，并一切美物，不肯灭绝。凡下贱瘦弱的，尽都杀了。"},
{volumn:         9,chapter:        15,versenumber:        10,verse:"耶和华的话临到撒母耳说："},
{volumn:         9,chapter:        15,versenumber:        11,verse:"“我立扫罗为王，我后悔了；因为他转去不跟从我，不遵守我的命令。”撒母耳便甚忧愁，终夜哀求耶和华。"},
{volumn:         9,chapter:        15,versenumber:        12,verse:"撒母耳清早起来，迎接扫罗。有人告诉撒母耳说：“扫罗到了迦密，在那里立了纪念碑，又转身下到吉甲。”"},
{volumn:         9,chapter:        15,versenumber:        13,verse:"撒母耳到了扫罗那里，扫罗对他说：“愿耶和华赐福与你，耶和华的命令我已遵守了。”"},
{volumn:         9,chapter:        15,versenumber:        14,verse:"撒母耳说：“我耳中听见有羊叫、牛鸣，是从哪里来的呢？”"},
{volumn:         9,chapter:        15,versenumber:        15,verse:"扫罗说：“这是百姓从亚玛力人那里带来的；因为他们爱惜上好的牛羊，要献与耶和华你的　神；其余的，我们都灭尽了。”"},
{volumn:         9,chapter:        15,versenumber:        16,verse:"撒母耳对扫罗说：“你住口吧！等我将耶和华昨夜向我所说的话告诉你。”扫罗说：“请讲。”"},
{volumn:         9,chapter:        15,versenumber:        17,verse:"撒母耳对扫罗说：“从前你虽然以自己为小，岂不是被立为以色列支派的元首吗？耶和华膏你作以色列的王。"},
{volumn:         9,chapter:        15,versenumber:        18,verse:"耶和华差遣你，吩咐你说，你去击打那些犯罪的亚玛力人，将他们灭绝净尽。"},
{volumn:         9,chapter:        15,versenumber:        19,verse:"你为何没有听从耶和华的命令，急忙掳掠财物，行耶和华眼中看为恶的事呢？”"},
{volumn:         9,chapter:        15,versenumber:        20,verse:"扫罗对撒母耳说：“我实在听从了耶和华的命令，行了耶和华所差遣我行的路，擒了亚玛力王亚甲来，灭尽了亚玛力人。"},
{volumn:         9,chapter:        15,versenumber:        21,verse:"百姓却在所当灭的物中，取了最好的牛羊，要在吉甲献与耶和华你的　神。”"},
{volumn:         9,chapter:        15,versenumber:        22,verse:"撒母耳说：“耶和华喜悦燔祭和平安祭，岂如喜悦人听从他的话呢？听命胜于献祭；顺从胜于公羊的脂油。"},
{volumn:         9,chapter:        15,versenumber:        23,verse:"悖逆的罪与行邪术的罪相等；顽梗的罪与拜虚神和偶像的罪相同。你既厌弃耶和华的命令，耶和华也厌弃你作王。”"},
{volumn:         9,chapter:        15,versenumber:        24,verse:"扫罗对撒母耳说：“我有罪了，我因惧怕百姓，听从他们的话，就违背了耶和华的命令和你的言语。"},
{volumn:         9,chapter:        15,versenumber:        25,verse:"现在求你赦免我的罪，同我回去，我好敬拜耶和华。”"},
{volumn:         9,chapter:        15,versenumber:        26,verse:"撒母耳对扫罗说：“我不同你回去；因为你厌弃耶和华的命令，耶和华也厌弃你作以色列的王。”"},
{volumn:         9,chapter:        15,versenumber:        27,verse:"撒母耳转身要走，扫罗就扯住他外袍的衣襟，衣襟就撕断了。"},
{volumn:         9,chapter:        15,versenumber:        28,verse:"撒母耳对他说：“如此，今日耶和华使以色列国与你断绝，将这国赐与比你更好的人。"},
{volumn:         9,chapter:        15,versenumber:        29,verse:"以色列的大能者必不致说谎，也不致后悔；因为他迥非世人，决不后悔。”"},
{volumn:         9,chapter:        15,versenumber:        30,verse:"扫罗说：“我有罪了，虽然如此，求你在我百姓的长老和以色列人面前抬举我，同我回去，我好敬拜耶和华你的　神。”"},
{volumn:         9,chapter:        15,versenumber:        31,verse:"于是撒母耳转身跟随扫罗回去，扫罗就敬拜耶和华。"},
{volumn:         9,chapter:        15,versenumber:        32,verse:"撒母耳说：“要把亚玛力王亚甲带到我这里来。”亚甲就欢欢喜喜地来到他面前，心里说，死亡的苦难必定过去了。"},
{volumn:         9,chapter:        15,versenumber:        33,verse:"撒母耳说：“你既用刀使妇人丧子，这样，你母亲在妇人中也必丧子。”于是，撒母耳在吉甲耶和华面前将亚甲杀死。"},
{volumn:         9,chapter:        15,versenumber:        34,verse:"撒母耳回了拉玛。扫罗上他所住的基比亚，回自己的家去了。"},
{volumn:         9,chapter:        15,versenumber:        35,verse:"撒母耳直到死的日子，再没有见扫罗；但撒母耳为扫罗悲伤，是因耶和华后悔立他为以色列的王。"},
{volumn:         9,chapter:        16,versenumber:         1,verse:"耶和华对撒母耳说：“我既厌弃扫罗作以色列的王，你为他悲伤要到几时呢？你将膏油盛满了角，我差遣你往伯利恒人耶西那里去；因为我在他众子之内，预定一个作王的。”"},
{volumn:         9,chapter:        16,versenumber:         2,verse:"撒母耳说：“我怎能去呢？扫罗若听见，必要杀我。”耶和华说：“你可以带一只牛犊去，就说：‘我来是要向耶和华献祭。’"},
{volumn:         9,chapter:        16,versenumber:         3,verse:"你要请耶西来吃祭肉，我就指示你所当行的事。我所指给你的人，你要膏他。”"},
{volumn:         9,chapter:        16,versenumber:         4,verse:"撒母耳就照耶和华的话去行。到了伯利恒，那城里的长老都战战兢兢地出来迎接他，问他说：“你是为平安来的吗？”"},
{volumn:         9,chapter:        16,versenumber:         5,verse:"他说：“为平安来的，我是给耶和华献祭。你们当自洁，来与我同吃祭肉。”撒母耳就使耶西和他众子自洁，请他们来吃祭肉。"},
{volumn:         9,chapter:        16,versenumber:         6,verse:"他们来的时候，撒母耳看见以利押，就心里说，耶和华的受膏者必定在他面前。"},
{volumn:         9,chapter:        16,versenumber:         7,verse:"耶和华却对撒母耳说：“不要看他的外貌和他身材高大，我不拣选他。因为，耶和华不像人看人：人是看外貌；耶和华是看内心。”"},
{volumn:         9,chapter:        16,versenumber:         8,verse:"耶西叫亚比拿达从撒母耳面前经过，撒母耳说：“耶和华也不拣选他。”"},
{volumn:         9,chapter:        16,versenumber:         9,verse:"耶西又叫沙玛从撒母耳面前经过，撒母耳说：“耶和华也不拣选他。”"},
{volumn:         9,chapter:        16,versenumber:        10,verse:"耶西叫他七个儿子都从撒母耳面前经过，撒母耳说：“这都不是耶和华所拣选的。”"},
{volumn:         9,chapter:        16,versenumber:        11,verse:"撒母耳对耶西说：“你的儿子都在这里吗？”他回答说：“还有个小的，现在放羊。”撒母耳对耶西说：“你打发人去叫他来；他若不来，我们必不坐席。”"},
{volumn:         9,chapter:        16,versenumber:        12,verse:"耶西就打发人去叫了他来。他面色光红，双目清秀，容貌俊美。耶和华说：“这就是他，你起来膏他。”"},
{volumn:         9,chapter:        16,versenumber:        13,verse:"撒母耳就用角里的膏油，在他诸兄中膏了他。从这日起，耶和华的灵就大大感动大卫。撒母耳起身回拉玛去了。"},
{volumn:         9,chapter:        16,versenumber:        14,verse:"耶和华的灵离开扫罗，有恶魔从耶和华那里来扰乱他。"},
{volumn:         9,chapter:        16,versenumber:        15,verse:"扫罗的臣仆对他说：“现在有恶魔从　神那里来扰乱你。"},
{volumn:         9,chapter:        16,versenumber:        16,verse:"我们的主可以吩咐面前的臣仆，找一个善于弹琴的来，等　神那里来的恶魔临到你身上的时候，使他用手弹琴，你就好了。”"},
{volumn:         9,chapter:        16,versenumber:        17,verse:"扫罗对臣仆说：“你们可以为我找一个善于弹琴的，带到我这里来。”"},
{volumn:         9,chapter:        16,versenumber:        18,verse:"其中有一个少年人说：“我曾见伯利恒人耶西的一个儿子善于弹琴，是大有勇敢的战士，说话合宜，容貌俊美，耶和华也与他同在。”"},
{volumn:         9,chapter:        16,versenumber:        19,verse:"于是扫罗差遣使者去见耶西，说：“请你打发你放羊的儿子大卫到我这里来。”"},
{volumn:         9,chapter:        16,versenumber:        20,verse:"耶西就把几个饼和一皮袋酒，并一只山羊羔，都驮在驴上，交给他儿子大卫，送与扫罗。"},
{volumn:         9,chapter:        16,versenumber:        21,verse:"大卫到了扫罗那里，就侍立在扫罗面前。扫罗甚喜爱他，他就作了扫罗拿兵器的人。"},
{volumn:         9,chapter:        16,versenumber:        22,verse:"扫罗差遣人去见耶西，说：“求你容大卫侍立在我面前，因为他在我眼前蒙了恩。”"},
{volumn:         9,chapter:        16,versenumber:        23,verse:"从　神那里来的恶魔临到扫罗身上的时候，大卫就拿琴，用手而弹，扫罗便舒畅爽快，恶魔离了他。"},
{volumn:         9,chapter:        17,versenumber:         1,verse:"非利士人招聚他们的军旅，要来争战；聚集在属犹大的梭哥，安营在梭哥和亚西加中间的以弗·大悯。"},
{volumn:         9,chapter:        17,versenumber:         2,verse:"扫罗和以色列人也聚集，在以拉谷安营，摆列队伍，要与非利士人打仗。"},
{volumn:         9,chapter:        17,versenumber:         3,verse:"非利士人站在这边山上，以色列人站在那边山上，当中有谷。"},
{volumn:         9,chapter:        17,versenumber:         4,verse:"从非利士营中出来一个讨战的人，名叫歌利亚，是迦特人，身高六肘零一虎口；"},
{volumn:         9,chapter:        17,versenumber:         5,verse:"头戴铜盔，身穿铠甲，甲重五千舍客勒；"},
{volumn:         9,chapter:        17,versenumber:         6,verse:"腿上有铜护膝，两肩之中背负铜戟；"},
{volumn:         9,chapter:        17,versenumber:         7,verse:"枪杆粗如织布的机轴，铁枪头重六百舍客勒。有一个拿盾牌的人在他前面走。"},
{volumn:         9,chapter:        17,versenumber:         8,verse:"歌利亚对着以色列的军队站立，呼叫说：“你们出来摆列队伍做什么呢？我不是非利士人吗？你们不是扫罗的仆人吗？可以从你们中间拣选一人，使他下到我这里来。"},
{volumn:         9,chapter:        17,versenumber:         9,verse:"他若能与我战斗，将我杀死，我们就作你们的仆人；我若胜了他，将他杀死，你们就作我们的仆人，服侍我们。”"},
{volumn:         9,chapter:        17,versenumber:        10,verse:"那非利士人又说：“我今日向以色列人的军队骂阵。你们叫一个人出来，与我战斗。”"},
{volumn:         9,chapter:        17,versenumber:        11,verse:"扫罗和以色列众人听见非利士人的这些话，就惊惶，极其害怕。"},
{volumn:         9,chapter:        17,versenumber:        12,verse:"大卫是犹大伯利恒的以法他人耶西的儿子。耶西有八个儿子。当扫罗的时候，耶西已经老迈。"},
{volumn:         9,chapter:        17,versenumber:        13,verse:"耶西的三个大儿子跟随扫罗出征。这出征的三个儿子：长子名叫以利押，次子名叫亚比拿达，三子名叫沙玛。"},
{volumn:         9,chapter:        17,versenumber:        14,verse:"大卫是最小的；那三个大儿子跟随扫罗。"},
{volumn:         9,chapter:        17,versenumber:        15,verse:"大卫有时离开扫罗，回伯利恒放他父亲的羊。"},
{volumn:         9,chapter:        17,versenumber:        16,verse:"那非利士人早晚都出来站着，如此四十日。"},
{volumn:         9,chapter:        17,versenumber:        17,verse:"一日，耶西对他儿子大卫说：“你拿一伊法烘了的穗子和十个饼，速速地送到营里去，交给你哥哥们；"},
{volumn:         9,chapter:        17,versenumber:        18,verse:"再拿这十块奶饼，送给他们的千夫长，且问你哥哥们好，向他们要一封信来。”"},
{volumn:         9,chapter:        17,versenumber:        19,verse:"扫罗与大卫的三个哥哥和以色列众人，在以拉谷与非利士人打仗。"},
{volumn:         9,chapter:        17,versenumber:        20,verse:"大卫早晨起来，将羊交托一个看守的人，照着他父亲所吩咐的话，带着食物去了。到了辎重营，军兵刚出到战场，呐喊要战。"},
{volumn:         9,chapter:        17,versenumber:        21,verse:"以色列人和非利士人都摆列队伍，彼此相对。"},
{volumn:         9,chapter:        17,versenumber:        22,verse:"大卫把他带来的食物留在看守物件人的手下，跑到战场，问他哥哥们安。"},
{volumn:         9,chapter:        17,versenumber:        23,verse:"与他们说话的时候，那讨战的，就是属迦特的非利士人歌利亚，从非利士队中出来，说从前所说的话；大卫都听见了。"},
{volumn:         9,chapter:        17,versenumber:        24,verse:"以色列众人看见那人，就逃跑，极其害怕。"},
{volumn:         9,chapter:        17,versenumber:        25,verse:"以色列人彼此说：“这上来的人你看见了吗？他上来是要向以色列人骂阵。若有能杀他的，王必赏赐他大财，将自己的女儿给他为妻，并在以色列人中免他父家纳粮当差。”"},
{volumn:         9,chapter:        17,versenumber:        26,verse:"大卫问站在旁边的人说：“有人杀这非利士人，除掉以色列人的耻辱，怎样待他呢？这未受割礼的非利士人是谁呢？竟敢向永生　神的军队骂阵吗？”"},
{volumn:         9,chapter:        17,versenumber:        27,verse:"百姓照先前的话回答他说：“有人能杀这非利士人，必如此如此待他。”"},
{volumn:         9,chapter:        17,versenumber:        28,verse:"大卫的长兄以利押听见大卫与他们所说的话，就向他发怒，说：“你下来做什么呢？在旷野的那几只羊，你交托了谁呢？我知道你的骄傲和你心里的恶意，你下来特为要看争战！”"},
{volumn:         9,chapter:        17,versenumber:        29,verse:"大卫说：“我做了什么呢？我来岂没有缘故吗？”"},
{volumn:         9,chapter:        17,versenumber:        30,verse:"大卫就离开他转向别人，照先前的话而问；百姓仍照先前的话回答他。"},
{volumn:         9,chapter:        17,versenumber:        31,verse:"有人听见大卫所说的话，就告诉了扫罗；扫罗便打发人叫他来。"},
{volumn:         9,chapter:        17,versenumber:        32,verse:"大卫对扫罗说：“人都不必因那非利士人胆怯。你的仆人要去与那非利士人战斗。”"},
{volumn:         9,chapter:        17,versenumber:        33,verse:"扫罗对大卫说：“你不能去与那非利士人战斗；因为你年纪太轻，他自幼就作战士。”"},
{volumn:         9,chapter:        17,versenumber:        34,verse:"大卫对扫罗说：“你仆人为父亲放羊，有时来了狮子，有时来了熊，从群中衔一只羊羔去。"},
{volumn:         9,chapter:        17,versenumber:        35,verse:"我就追赶它，击打它，将羊羔从它口中救出来。它起来要害我，我就揪着它的胡子，将它打死。"},
{volumn:         9,chapter:        17,versenumber:        36,verse:"你仆人曾打死狮子和熊，这未受割礼的非利士人向永生　神的军队骂阵，也必像狮子和熊一般。”"},
{volumn:         9,chapter:        17,versenumber:        37,verse:"大卫又说：“耶和华救我脱离狮子和熊的爪，也必救我脱离这非利士人的手。”扫罗对大卫说：“你可以去吧！耶和华必与你同在。”"},
{volumn:         9,chapter:        17,versenumber:        38,verse:"扫罗就把自己的战衣给大卫穿上，将铜盔给他戴上，又给他穿上铠甲。"},
{volumn:         9,chapter:        17,versenumber:        39,verse:"大卫把刀跨在战衣外，试试能走不能走；因为素来没有穿惯，就对扫罗说：“我穿戴这些不能走，因为素来没有穿惯。”于是摘脱了。"},
{volumn:         9,chapter:        17,versenumber:        40,verse:"他手中拿杖，又在溪中挑选了五块光滑石子，放在袋里，就是牧人带的囊里；手中拿着甩石的机弦，就去迎那非利士人。"},
{volumn:         9,chapter:        17,versenumber:        41,verse:"非利士人也渐渐地迎着大卫来，拿盾牌的走在前头。"},
{volumn:         9,chapter:        17,versenumber:        42,verse:"非利士人观看，见了大卫，就藐视他；因为他年轻，面色光红，容貌俊美。"},
{volumn:         9,chapter:        17,versenumber:        43,verse:"非利士人对大卫说：“你拿杖到我这里来，我岂是狗呢？”非利士人就指着自己的神咒诅大卫。"},
{volumn:         9,chapter:        17,versenumber:        44,verse:"非利士人又对大卫说：“来吧！我将你的肉给空中的飞鸟、田野的走兽吃。”"},
{volumn:         9,chapter:        17,versenumber:        45,verse:"大卫对非利士人说：“你来攻击我，是靠着刀枪和铜戟；我来攻击你，是靠着万军之耶和华的名，就是你所怒骂带领以色列军队的　神。"},
{volumn:         9,chapter:        17,versenumber:        46,verse:"今日耶和华必将你交在我手里。我必杀你，斩你的头，又将非利士军兵的尸首给空中的飞鸟、地上的野兽吃，使普天下的人都知道以色列中有　神；"},
{volumn:         9,chapter:        17,versenumber:        47,verse:"又使这众人知道耶和华使人得胜，不是用刀用枪，因为争战的胜败全在乎耶和华。他必将你们交在我们手里。”"},
{volumn:         9,chapter:        17,versenumber:        48,verse:"非利士人起身，迎着大卫前来。大卫急忙迎着非利士人，往战场跑去。"},
{volumn:         9,chapter:        17,versenumber:        49,verse:"大卫用手从囊中掏出一块石子来，用机弦甩去，打中非利士人的额，石子进入额内，他就仆倒，面伏于地。"},
{volumn:         9,chapter:        17,versenumber:        50,verse:"这样，大卫用机弦甩石，胜了那非利士人，打死他；大卫手中却没有刀。"},
{volumn:         9,chapter:        17,versenumber:        51,verse:"大卫跑去，站在非利士人身旁，将他的刀从鞘中拔出来，杀死他，割了他的头。非利士众人看见他们讨战的勇士死了，就都逃跑。"},
{volumn:         9,chapter:        17,versenumber:        52,verse:"以色列人和犹大人便起身呐喊，追赶非利士人，直到迦特（或译：该）和以革伦的城门。被杀的非利士人倒在沙拉音的路上，直到迦特和以革伦。"},
{volumn:         9,chapter:        17,versenumber:        53,verse:"以色列人追赶非利士人回来，就夺了他们的营盘。"},
{volumn:         9,chapter:        17,versenumber:        54,verse:"大卫将那非利士人的头拿到耶路撒冷，却将他军装放在自己的帐棚里。"},
{volumn:         9,chapter:        17,versenumber:        55,verse:"扫罗看见大卫去攻击非利士人，就问元帅押尼珥说：“押尼珥啊，那少年人是谁的儿子？”押尼珥说：“我敢在王面前起誓，我不知道。”"},
{volumn:         9,chapter:        17,versenumber:        56,verse:"王说：“你可以问问那幼年人是谁的儿子。”"},
{volumn:         9,chapter:        17,versenumber:        57,verse:"大卫打死非利士人回来，押尼珥领他到扫罗面前，他手中拿着非利士人的头。"},
{volumn:         9,chapter:        17,versenumber:        58,verse:"扫罗问他说：“少年人哪，你是谁的儿子？”大卫说：“我是你仆人伯利恒人耶西的儿子。”"},
{volumn:         9,chapter:        18,versenumber:         1,verse:"大卫对扫罗说完了话，约拿单的心与大卫的心深相契合。约拿单爱大卫，如同爱自己的性命。"},
{volumn:         9,chapter:        18,versenumber:         2,verse:"那日扫罗留住大卫，不容他再回父家。"},
{volumn:         9,chapter:        18,versenumber:         3,verse:"约拿单爱大卫如同爱自己的性命，就与他结盟。"},
{volumn:         9,chapter:        18,versenumber:         4,verse:"约拿单从身上脱下外袍，给了大卫，又将战衣、刀、弓、腰带都给了他。"},
{volumn:         9,chapter:        18,versenumber:         5,verse:"扫罗无论差遣大卫往何处去，他都做事精明。扫罗就立他作战士长，众百姓和扫罗的臣仆无不喜悦。"},
{volumn:         9,chapter:        18,versenumber:         6,verse:"大卫打死了那非利士人，同众人回来的时候，妇女们从以色列各城里出来，欢欢喜喜，打鼓击磬，歌唱跳舞，迎接扫罗王。"},
{volumn:         9,chapter:        18,versenumber:         7,verse:"众妇女舞蹈唱和，说：“扫罗杀死千千，大卫杀死万万。”"},
{volumn:         9,chapter:        18,versenumber:         8,verse:"扫罗甚发怒，不喜悦这话，就说：“将万万归大卫，千千归我，只剩下王位没有给他了。”"},
{volumn:         9,chapter:        18,versenumber:         9,verse:"从这日起，扫罗就怒视大卫。"},
{volumn:         9,chapter:        18,versenumber:        10,verse:"次日，从　神那里来的恶魔大大降在扫罗身上，他就在家中胡言乱语。大卫照常弹琴，扫罗手里拿着枪。"},
{volumn:         9,chapter:        18,versenumber:        11,verse:"扫罗把枪一抡，心里说，我要将大卫刺透，钉在墙上。大卫躲避他两次。"},
{volumn:         9,chapter:        18,versenumber:        12,verse:"扫罗惧怕大卫；因为耶和华离开自己，与大卫同在。"},
{volumn:         9,chapter:        18,versenumber:        13,verse:"所以扫罗使大卫离开自己，立他为千夫长，他就领兵出入。"},
{volumn:         9,chapter:        18,versenumber:        14,verse:"大卫做事无不精明，耶和华也与他同在。"},
{volumn:         9,chapter:        18,versenumber:        15,verse:"扫罗见大卫做事精明，就甚怕他。"},
{volumn:         9,chapter:        18,versenumber:        16,verse:"但以色列和犹大众人都爱大卫，因为他领他们出入。"},
{volumn:         9,chapter:        18,versenumber:        17,verse:"扫罗对大卫说：“我将大女儿米拉给你为妻，只要你为我奋勇，为耶和华争战。”扫罗心里说：“我不好亲手害他，要藉非利士人的手害他。”"},
{volumn:         9,chapter:        18,versenumber:        18,verse:"大卫对扫罗说：“我是谁，我是什么出身，我父家在以色列中是何等的家，岂敢作王的女婿呢？”"},
{volumn:         9,chapter:        18,versenumber:        19,verse:"扫罗的女儿米拉到了当给大卫的时候，扫罗却给了米何拉人亚得列为妻。"},
{volumn:         9,chapter:        18,versenumber:        20,verse:"扫罗的次女米甲爱大卫。有人告诉扫罗，扫罗就喜悦。"},
{volumn:         9,chapter:        18,versenumber:        21,verse:"扫罗心里说：“我将这女儿给大卫，作他的网罗，好藉非利士人的手害他。”所以扫罗对大卫说：“你今日可以第二次作我的女婿。”"},
{volumn:         9,chapter:        18,versenumber:        22,verse:"扫罗吩咐臣仆说：“你们暗中对大卫说：‘王喜悦你，王的臣仆也都喜爱你，所以你当作王的女婿。’”"},
{volumn:         9,chapter:        18,versenumber:        23,verse:"扫罗的臣仆就照这话说给大卫听。大卫说：“你们以为作王的女婿是一件小事吗？我是贫穷卑微的人。”"},
{volumn:         9,chapter:        18,versenumber:        24,verse:"扫罗的臣仆回奏说，大卫所说的如此如此。"},
{volumn:         9,chapter:        18,versenumber:        25,verse:"扫罗说：“你们要对大卫这样说：‘王不要什么聘礼，只要一百非利士人的阳皮，好在王的仇敌身上报仇。’”扫罗的意思要使大卫丧在非利士人的手里。"},
{volumn:         9,chapter:        18,versenumber:        26,verse:"扫罗的臣仆将这话告诉大卫，大卫就欢喜作王的女婿。日期还没有到，"},
{volumn:         9,chapter:        18,versenumber:        27,verse:"大卫和跟随他的人起身前往，杀了二百非利士人，将阳皮满数交给王，为要作王的女婿。于是扫罗将女儿米甲给大卫为妻。"},
{volumn:         9,chapter:        18,versenumber:        28,verse:"扫罗见耶和华与大卫同在，又知道女儿米甲爱大卫，"},
{volumn:         9,chapter:        18,versenumber:        29,verse:"就更怕大卫，常作大卫的仇敌。"},
{volumn:         9,chapter:        18,versenumber:        30,verse:"每逢非利士军长出来打仗，大卫比扫罗的臣仆做事精明，因此他的名被人尊重。"},
{volumn:         9,chapter:        19,versenumber:         1,verse:"扫罗对他儿子约拿单和众臣仆说，要杀大卫；扫罗的儿子约拿单却甚喜爱大卫。"},
{volumn:         9,chapter:        19,versenumber:         2,verse:"约拿单告诉大卫说：“我父扫罗想要杀你，所以明日早晨你要小心，到一个僻静地方藏身。"},
{volumn:         9,chapter:        19,versenumber:         3,verse:"我就出到你所藏的田里，站在我父亲旁边与他谈论。我看他情形怎样，我必告诉你。”"},
{volumn:         9,chapter:        19,versenumber:         4,verse:"约拿单向他父亲扫罗替大卫说好话，说：“王不可得罪王的仆人大卫；因为他未曾得罪你，他所行的都与你大有益处。"},
{volumn:         9,chapter:        19,versenumber:         5,verse:"他拚命杀那非利士人，耶和华为以色列众人大行拯救；那时你看见，甚是欢喜，现在为何无故要杀大卫，流无辜人的血，自己取罪呢？”"},
{volumn:         9,chapter:        19,versenumber:         6,verse:"扫罗听了约拿单的话，就指着永生的耶和华起誓说：“我必不杀他。”"},
{volumn:         9,chapter:        19,versenumber:         7,verse:"约拿单叫大卫来，把这一切事告诉他，带他去见扫罗。他就仍然侍立在扫罗面前。"},
{volumn:         9,chapter:        19,versenumber:         8,verse:"此后又有争战的事。大卫出去与非利士人打仗，大大杀败他们，他们就在他面前逃跑。"},
{volumn:         9,chapter:        19,versenumber:         9,verse:"从耶和华那里来的恶魔又降在扫罗身上（扫罗手里拿枪坐在屋里），大卫就用手弹琴。"},
{volumn:         9,chapter:        19,versenumber:        10,verse:"扫罗用枪想要刺透大卫，钉在墙上，他却躲开，扫罗的枪刺入墙内。当夜大卫逃走，躲避了。"},
{volumn:         9,chapter:        19,versenumber:        11,verse:"扫罗打发人到大卫的房屋那里窥探他，要等到天亮杀他。大卫的妻米甲对他说：“你今夜若不逃命，明日你要被杀。”"},
{volumn:         9,chapter:        19,versenumber:        12,verse:"于是米甲将大卫从窗户里缒下去，大卫就逃走，躲避了。"},
{volumn:         9,chapter:        19,versenumber:        13,verse:"米甲把家中的神像放在床上，头枕在山羊毛装的枕头上，用被遮盖。"},
{volumn:         9,chapter:        19,versenumber:        14,verse:"扫罗打发人去捉拿大卫，米甲说：“他病了。”"},
{volumn:         9,chapter:        19,versenumber:        15,verse:"扫罗又打发人去看大卫，说：“当连床将他抬来，我好杀他。”"},
{volumn:         9,chapter:        19,versenumber:        16,verse:"使者进去，看见床上有神像，头枕在山羊毛装的枕头上。"},
{volumn:         9,chapter:        19,versenumber:        17,verse:"扫罗对米甲说：“你为什么这样欺哄我，放我仇敌逃走呢？”米甲回答说：“他对我说：‘你放我走，不然我要杀你。’”"},
{volumn:         9,chapter:        19,versenumber:        18,verse:"大卫逃避，来到拉玛见撒母耳，将扫罗向他所行的事述说了一遍。他和撒母耳就往拿约去居住。"},
{volumn:         9,chapter:        19,versenumber:        19,verse:"有人告诉扫罗，说大卫在拉玛的拿约。"},
{volumn:         9,chapter:        19,versenumber:        20,verse:"扫罗打发人去捉拿大卫。去的人见有一班先知都受感说话，撒母耳站在其中监管他们；打发去的人也受　神的灵感动说话。"},
{volumn:         9,chapter:        19,versenumber:        21,verse:"有人将这事告诉扫罗，他又打发人去，他们也受感说话。扫罗第三次打发人去，他们也受感说话。"},
{volumn:         9,chapter:        19,versenumber:        22,verse:"然后扫罗自己往拉玛去，到了西沽的大井，问人说：“撒母耳和大卫在哪里呢？”有人说：“在拉玛的拿约。”"},
{volumn:         9,chapter:        19,versenumber:        23,verse:"他就往拉玛的拿约去。　神的灵也感动他，一面走一面说话，直到拉玛的拿约。"},
{volumn:         9,chapter:        19,versenumber:        24,verse:"他就脱了衣服，在撒母耳面前受感说话，一昼一夜露体躺卧。因此有句俗语说：“扫罗也列在先知中吗？”"},
{volumn:         9,chapter:        20,versenumber:         1,verse:"大卫从拉玛的拿约逃跑，来到约拿单那里，对他说：“我做了什么？有什么罪孽呢？在你父亲面前犯了什么罪，他竟寻索我的性命呢？”"},
{volumn:         9,chapter:        20,versenumber:         2,verse:"约拿单回答说：“断然不是！你必不致死。我父做事，无论大小，没有不叫我知道的。怎么独有这事隐瞒我呢？决不如此。”"},
{volumn:         9,chapter:        20,versenumber:         3,verse:"大卫又起誓说：“你父亲准知我在你眼前蒙恩。他心里说，不如不叫约拿单知道，恐怕他愁烦。我指着永生的耶和华，又敢在你面前起誓，我离死不过一步。”"},
{volumn:         9,chapter:        20,versenumber:         4,verse:"约拿单对大卫说：“你心里所求的，我必为你成就。”"},
{volumn:         9,chapter:        20,versenumber:         5,verse:"大卫对约拿单说：“明日是初一，我当与王同席，求你容我去藏在田野，直到第三日晚上。"},
{volumn:         9,chapter:        20,versenumber:         6,verse:"你父亲若见我不在席上，你就说：‘大卫切求我许他回本城伯利恒去，因为他全家在那里献年祭。’"},
{volumn:         9,chapter:        20,versenumber:         7,verse:"你父亲若说好，仆人就平安了；他若发怒，你就知道他决意要害我。"},
{volumn:         9,chapter:        20,versenumber:         8,verse:"求你施恩与仆人，因你在耶和华面前曾与仆人结盟。我若有罪，不如你自己杀我，何必将我交给你父亲呢？”"},
{volumn:         9,chapter:        20,versenumber:         9,verse:"约拿单说：“断无此事！我若知道我父亲决意害你，我岂不告诉你呢？”"},
{volumn:         9,chapter:        20,versenumber:        10,verse:"大卫对约拿单说：“你父亲若用厉言回答你，谁来告诉我呢？”"},
{volumn:         9,chapter:        20,versenumber:        11,verse:"约拿单对大卫说：“你我且往田野去。”二人就往田野去了。"},
{volumn:         9,chapter:        20,versenumber:        12,verse:"约拿单对大卫说：“愿耶和华以色列的　神为证。明日约在这时候，或第三日，我探我父亲的意思，若向你有好意，我岂不打发人告诉你吗？"},
{volumn:         9,chapter:        20,versenumber:        13,verse:"我父亲若有意害你，我不告诉你使你平平安安地走，愿耶和华重重地降罚与我。愿耶和华与你同在，如同从前与我父亲同在一样。"},
{volumn:         9,chapter:        20,versenumber:        14,verse:"你要照耶和华的慈爱恩待我，不但我活着的时候免我死亡，"},
{volumn:         9,chapter:        20,versenumber:        15,verse:"就是我死后，耶和华从地上剪除你仇敌的时候，你也永不可向我家绝了恩惠。”"},
{volumn:         9,chapter:        20,versenumber:        16,verse:"于是约拿单与大卫家结盟，说：“愿耶和华藉大卫的仇敌追讨背约的罪。”"},
{volumn:         9,chapter:        20,versenumber:        17,verse:"约拿单因爱大卫如同爱自己的性命，就使他再起誓。"},
{volumn:         9,chapter:        20,versenumber:        18,verse:"约拿单对他说：“明日是初一，你的座位空设，人必理会你不在那里。"},
{volumn:         9,chapter:        20,versenumber:        19,verse:"你等三日，就要速速下去，到你从前遇事所藏的地方，在以色磐石那里等候。"},
{volumn:         9,chapter:        20,versenumber:        20,verse:"我要向磐石旁边射三箭，如同射箭靶一样。"},
{volumn:         9,chapter:        20,versenumber:        21,verse:"我要打发童子，说：‘去把箭找来。’我若对童子说：‘箭在后头，把箭拿来’，你就可以回来；我指着永生的耶和华起誓，你必平安无事。"},
{volumn:         9,chapter:        20,versenumber:        22,verse:"我若对童子说：‘箭在前头’，你就要去，因为是耶和华打发你去的。"},
{volumn:         9,chapter:        20,versenumber:        23,verse:"至于你我今日所说的话，有耶和华在你我中间为证，直到永远。”"},
{volumn:         9,chapter:        20,versenumber:        24,verse:"大卫就去藏在田野。到了初一日，王坐席要吃饭。"},
{volumn:         9,chapter:        20,versenumber:        25,verse:"王照常坐在靠墙的位上，约拿单侍立，押尼珥坐在扫罗旁边，大卫的座位空设。"},
{volumn:         9,chapter:        20,versenumber:        26,verse:"然而这日扫罗没有说什么，他想大卫遇事，偶染不洁，他必定是不洁。"},
{volumn:         9,chapter:        20,versenumber:        27,verse:"初二日大卫的座位还空设。扫罗问他儿子约拿单说：“耶西的儿子为何昨日、今日没有来吃饭呢？”"},
{volumn:         9,chapter:        20,versenumber:        28,verse:"约拿单回答扫罗说：“大卫切求我容他往伯利恒去。"},
{volumn:         9,chapter:        20,versenumber:        29,verse:"他说：‘求你容我去，因为我家在城里有献祭的事；我长兄吩咐我去。如今我若在你眼前蒙恩，求你容我去见我的弟兄’；所以大卫没有赴王的席。”"},
{volumn:         9,chapter:        20,versenumber:        30,verse:"扫罗向约拿单发怒，对他说：“你这顽梗背逆之妇人所生的，我岂不知道你喜悦耶西的儿子，自取羞辱，以致你母亲露体蒙羞吗？"},
{volumn:         9,chapter:        20,versenumber:        31,verse:"耶西的儿子若在世间活着，你和你的国位必站立不住。现在你要打发人去，将他捉拿交给我；他是该死的。”"},
{volumn:         9,chapter:        20,versenumber:        32,verse:"约拿单对父亲扫罗说：“他为什么该死呢？他做了什么呢？”"},
{volumn:         9,chapter:        20,versenumber:        33,verse:"扫罗向约拿单抡枪要刺他，约拿单就知道他父亲决意要杀大卫。"},
{volumn:         9,chapter:        20,versenumber:        34,verse:"于是约拿单气忿忿地从席上起来，在这初二日没有吃饭。他因见父亲羞辱大卫，就为大卫愁烦。"},
{volumn:         9,chapter:        20,versenumber:        35,verse:"次日早晨，约拿单按着与大卫约会的时候出到田野，有一个童子跟随。"},
{volumn:         9,chapter:        20,versenumber:        36,verse:"约拿单对童子说：“你跑去，把我所射的箭找来。”童子跑去，约拿单就把箭射在童子前头。"},
{volumn:         9,chapter:        20,versenumber:        37,verse:"童子到了约拿单落箭之地，约拿单呼叫童子说：“箭不是在你前头吗？”"},
{volumn:         9,chapter:        20,versenumber:        38,verse:"约拿单又呼叫童子说：“速速地去，不要迟延！”童子就拾起箭来，回到主人那里。"},
{volumn:         9,chapter:        20,versenumber:        39,verse:"童子却不知道这是什么意思，只有约拿单和大卫知道。"},
{volumn:         9,chapter:        20,versenumber:        40,verse:"约拿单将弓箭交给童子，吩咐说：“你拿到城里去。”"},
{volumn:         9,chapter:        20,versenumber:        41,verse:"童子一去，大卫就从磐石的南边出来，俯伏在地，拜了三拜；二人亲嘴，彼此哭泣，大卫哭得更恸。"},
{volumn:         9,chapter:        20,versenumber:        42,verse:"约拿单对大卫说：“我们二人曾指着耶和华的名起誓说：‘愿耶和华在你我中间，并你我后裔中间为证，直到永远。’如今你平平安安地去吧！”大卫就起身走了；约拿单也回城里去了。"},
{volumn:         9,chapter:        21,versenumber:         1,verse:"大卫到了挪伯祭司亚希米勒那里，亚希米勒战战兢兢地出来迎接他，问他说：“你为什么独自来，没有人跟随呢？”"},
{volumn:         9,chapter:        21,versenumber:         2,verse:"大卫回答祭司亚希米勒说：“王吩咐我一件事说：‘我差遣你委托你的这件事，不要使人知道。’故此我已派定少年人在某处等候我。"},
{volumn:         9,chapter:        21,versenumber:         3,verse:"现在你手下有什么？求你给我五个饼或是别样的食物。”"},
{volumn:         9,chapter:        21,versenumber:         4,verse:"祭司对大卫说：“我手下没有寻常的饼，只有圣饼；若少年人没有亲近妇人才可以给。”"},
{volumn:         9,chapter:        21,versenumber:         5,verse:"大卫对祭司说：“实在约有三日我们没有亲近妇人；我出来的时候，虽是寻常行路，少年人的器皿还是洁净的；何况今日不更是洁净吗？”"},
{volumn:         9,chapter:        21,versenumber:         6,verse:"祭司就拿圣饼给他；因为在那里没有别样饼，只有更换新饼，从耶和华面前撤下来的陈设饼。"},
{volumn:         9,chapter:        21,versenumber:         7,verse:"当日有扫罗的一个臣子留在耶和华面前。他名叫多益，是以东人，作扫罗的司牧长。"},
{volumn:         9,chapter:        21,versenumber:         8,verse:"大卫问亚希米勒说：“你手下有枪有刀没有？因为王的事甚急，连刀剑器械我都没有带。”"},
{volumn:         9,chapter:        21,versenumber:         9,verse:"祭司说：“你在以拉谷杀非利士人歌利亚的那刀在这里，裹在布中，放在以弗得后边，你要就可以拿去；除此以外，再没有别的。”大卫说：“这刀没有可比的！求你给我。”"},
{volumn:         9,chapter:        21,versenumber:        10,verse:"那日大卫起来，躲避扫罗，逃到迦特王亚吉那里。"},
{volumn:         9,chapter:        21,versenumber:        11,verse:"亚吉的臣仆对亚吉说：“这不是以色列国王大卫吗？那里的妇女跳舞唱和，不是指着他说‘扫罗杀死千千，大卫杀死万万’吗？”"},
{volumn:         9,chapter:        21,versenumber:        12,verse:"大卫将这话放在心里，甚惧怕迦特王亚吉，"},
{volumn:         9,chapter:        21,versenumber:        13,verse:"就在众人面前改变了寻常的举动，在他们手下假装疯癫，在城门的门扇上胡写乱画，使唾沫流在胡子上。"},
{volumn:         9,chapter:        21,versenumber:        14,verse:"亚吉对臣仆说：“你们看，这人是疯子。为什么带他到我这里来呢？"},
{volumn:         9,chapter:        21,versenumber:        15,verse:"我岂缺少疯子，你们带这人来在我面前疯癫吗？这人岂可进我的家呢？”"},
{volumn:         9,chapter:        22,versenumber:         1,verse:"大卫就离开那里，逃到亚杜兰洞。他的弟兄和他父亲的全家听见了，就都下到他那里。"},
{volumn:         9,chapter:        22,versenumber:         2,verse:"凡受窘迫的、欠债的、心里苦恼的都聚集到大卫那里；大卫就作他们的头目，跟随他的约有四百人。"},
{volumn:         9,chapter:        22,versenumber:         3,verse:"大卫从那里往摩押的米斯巴去，对摩押王说：“求你容我父母搬来，住在你们这里，等我知道　神要为我怎样行。”"},
{volumn:         9,chapter:        22,versenumber:         4,verse:"大卫领他父母到摩押王面前。大卫住山寨多少日子，他父母也住摩押王那里多少日子。"},
{volumn:         9,chapter:        22,versenumber:         5,verse:"先知迦得对大卫说：“你不要住在山寨，要往犹大地去。”大卫就离开那里，进入哈列的树林。"},
{volumn:         9,chapter:        22,versenumber:         6,verse:"扫罗在基比亚的拉玛，坐在垂丝柳树下，手里拿着枪，众臣仆侍立在左右。扫罗听见大卫和跟随他的人在何处，"},
{volumn:         9,chapter:        22,versenumber:         7,verse:"就对左右侍立的臣仆说：“便雅悯人哪，你们要听我的话！耶西的儿子能将田地和葡萄园赐给你们各人吗？能立你们各人作千夫长百夫长吗？"},
{volumn:         9,chapter:        22,versenumber:         8,verse:"你们竟都结党害我！我的儿子与耶西的儿子结盟的时候，无人告诉我；我的儿子挑唆我的臣子谋害我，就如今日的光景，也无人告诉我，为我忧虑。”"},
{volumn:         9,chapter:        22,versenumber:         9,verse:"那时以东人多益站在扫罗的臣仆中，对他说：“我曾看见耶西的儿子到了挪伯，亚希突的儿子亚希米勒那里。"},
{volumn:         9,chapter:        22,versenumber:        10,verse:"亚希米勒为他求问耶和华，又给他食物，并给他杀非利士人歌利亚的刀。”"},
{volumn:         9,chapter:        22,versenumber:        11,verse:"王就打发人将祭司亚希突的儿子亚希米勒和他父亲的全家，就是住挪伯的祭司都召了来；他们就来见王。"},
{volumn:         9,chapter:        22,versenumber:        12,verse:"扫罗说：“亚希突的儿子，要听我的话！”他回答说：“主啊，我在这里。”"},
{volumn:         9,chapter:        22,versenumber:        13,verse:"扫罗对他说：“你为什么与耶西的儿子结党害我，将食物和刀给他，又为他求问　神，使他起来谋害我，就如今日的光景？”"},
{volumn:         9,chapter:        22,versenumber:        14,verse:"亚希米勒回答王说：“王的臣仆中有谁比大卫忠心呢？他是王的女婿，又是王的参谋，并且在王家中是尊贵的。"},
{volumn:         9,chapter:        22,versenumber:        15,verse:"我岂是从今日才为他求问　神呢？断不是这样！王不要将罪归我和我父的全家；因为这事，无论大小，仆人都不知道。”"},
{volumn:         9,chapter:        22,versenumber:        16,verse:"王说：“亚希米勒啊，你和你父的全家都是该死的！”"},
{volumn:         9,chapter:        22,versenumber:        17,verse:"王就吩咐左右的侍卫说：“你们去杀耶和华的祭司；因为他们帮助大卫，又知道大卫逃跑，竟没有告诉我。”扫罗的臣子却不肯伸手杀耶和华的祭司。"},
{volumn:         9,chapter:        22,versenumber:        18,verse:"王吩咐多益说：“你去杀祭司吧！”以东人多益就去杀祭司，那日杀了穿细麻布以弗得的八十五人；"},
{volumn:         9,chapter:        22,versenumber:        19,verse:"又用刀将祭司城挪伯中的男女、孩童、吃奶的，和牛、羊、驴尽都杀灭。"},
{volumn:         9,chapter:        22,versenumber:        20,verse:"亚希突的儿子亚希米勒有一个儿子，名叫亚比亚他，逃到大卫那里。"},
{volumn:         9,chapter:        22,versenumber:        21,verse:"亚比亚他将扫罗杀耶和华祭司的事告诉大卫。"},
{volumn:         9,chapter:        22,versenumber:        22,verse:"大卫对亚比亚他说：“那日我见以东人多益在那里，就知道他必告诉扫罗。你父的全家丧命，都是因我的缘故。"},
{volumn:         9,chapter:        22,versenumber:        23,verse:"你可以住在我这里，不要惧怕。因为寻索你命的就是寻索我的命；你在我这里可得保全。”"},
{volumn:         9,chapter:        23,versenumber:         1,verse:"有人告诉大卫说：“非利士人攻击基伊拉，抢夺禾场。”"},
{volumn:         9,chapter:        23,versenumber:         2,verse:"所以大卫求问耶和华说：“我去攻打那些非利士人可以不可以？”耶和华对大卫说：“你可以去攻打非利士人，拯救基伊拉。”"},
{volumn:         9,chapter:        23,versenumber:         3,verse:"跟随大卫的人对他说：“我们在犹大地这里尚且惧怕，何况往基伊拉去攻打非利士人的军旅呢？”"},
{volumn:         9,chapter:        23,versenumber:         4,verse:"大卫又求问耶和华。耶和华回答说：“你起身下基伊拉去，我必将非利士人交在你手里。”"},
{volumn:         9,chapter:        23,versenumber:         5,verse:"大卫和跟随他的人往基伊拉去，与非利士人打仗，大大杀败他们，又夺获他们的牲畜。这样，大卫救了基伊拉的居民。"},
{volumn:         9,chapter:        23,versenumber:         6,verse:"亚希米勒的儿子亚比亚他逃到基伊拉见大卫的时候，手里拿着以弗得。"},
{volumn:         9,chapter:        23,versenumber:         7,verse:"有人告诉扫罗说：“大卫到了基伊拉。”扫罗说：“他进了有门有闩的城，困闭在里头；这是　神将他交在我手里了。”"},
{volumn:         9,chapter:        23,versenumber:         8,verse:"于是扫罗招聚众民，要下去攻打基伊拉城，围困大卫和跟随他的人。"},
{volumn:         9,chapter:        23,versenumber:         9,verse:"大卫知道扫罗设计谋害他，就对祭司亚比亚他说：“将以弗得拿过来。”"},
{volumn:         9,chapter:        23,versenumber:        10,verse:"大卫祷告说：“耶和华以色列的　神啊，你仆人听真了扫罗要往基伊拉来，为我的缘故灭城。"},
{volumn:         9,chapter:        23,versenumber:        11,verse:"基伊拉人将我交在扫罗手里不交？扫罗照着你仆人所听的话下来不下来？耶和华以色列的　神啊，求你指示仆人！”耶和华说：“扫罗必下来。”"},
{volumn:         9,chapter:        23,versenumber:        12,verse:"大卫又说：“基伊拉人将我和跟随我的人交在扫罗手里不交？”耶和华说：“必交出来。”"},
{volumn:         9,chapter:        23,versenumber:        13,verse:"大卫和跟随他的约有六百人，就起身出了基伊拉，往他们所能往的地方去。有人告诉扫罗，大卫离开基伊拉逃走；于是扫罗不出来了。"},
{volumn:         9,chapter:        23,versenumber:        14,verse:"大卫住在旷野的山寨里，常在西弗旷野的山地。扫罗天天寻索大卫，　神却不将大卫交在他手里。"},
{volumn:         9,chapter:        23,versenumber:        15,verse:"大卫知道扫罗出来寻索他的命。那时，他住在西弗旷野的树林里；"},
{volumn:         9,chapter:        23,versenumber:        16,verse:"扫罗的儿子约拿单起身，往那树林里去见大卫，使他倚靠　神得以坚固，"},
{volumn:         9,chapter:        23,versenumber:        17,verse:"对他说：“不要惧怕！我父扫罗的手必不加害于你；你必作以色列的王，我也作你的宰相。这事我父扫罗知道了。”"},
{volumn:         9,chapter:        23,versenumber:        18,verse:"于是二人在耶和华面前立约。大卫仍住在树林里，约拿单回家去了。"},
{volumn:         9,chapter:        23,versenumber:        19,verse:"西弗人上到基比亚见扫罗，说：“大卫不是在我们那里的树林里山寨中、旷野南边的哈基拉山藏着吗？"},
{volumn:         9,chapter:        23,versenumber:        20,verse:"王啊，请你随你的心愿下来，我们必亲自将他交在王的手里。”"},
{volumn:         9,chapter:        23,versenumber:        21,verse:"扫罗说：“愿耶和华赐福与你们，因你们顾恤我。"},
{volumn:         9,chapter:        23,versenumber:        22,verse:"请你们回去，再确实查明他的住处和行踪，是谁看见他在那里，因为我听见人说他甚狡猾。"},
{volumn:         9,chapter:        23,versenumber:        23,verse:"所以要看准他藏匿的地方，回来据实地告诉我，我就与你们同去。他若在犹大的境内，我必从千门万户中搜出他来。”"},
{volumn:         9,chapter:        23,versenumber:        24,verse:"西弗人就起身，在扫罗以先往西弗去。大卫和跟随他的人却在玛云旷野南边的亚拉巴。"},
{volumn:         9,chapter:        23,versenumber:        25,verse:"扫罗和跟随他的人去寻找大卫；有人告诉大卫，他就下到磐石，住在玛云的旷野。扫罗听见，便在玛云的旷野追赶大卫。"},
{volumn:         9,chapter:        23,versenumber:        26,verse:"扫罗在山这边走，大卫和跟随他的人在山那边走。大卫急忙躲避扫罗；因为扫罗和跟随他的人，四面围住大卫和跟随他的人，要拿获他们。"},
{volumn:         9,chapter:        23,versenumber:        27,verse:"忽有使者来报告扫罗说：“非利士人犯境抢掠，请王快快回去！”"},
{volumn:         9,chapter:        23,versenumber:        28,verse:"于是扫罗不追赶大卫，回去攻打非利士人。因此那地方名叫西拉·哈玛希罗结。"},
{volumn:         9,chapter:        23,versenumber:        29,verse:"大卫从那里上去，住在隐·基底的山寨里。"},
{volumn:         9,chapter:        24,versenumber:         1,verse:"扫罗追赶非利士人回来，有人告诉他说：“大卫在隐·基底的旷野。”"},
{volumn:         9,chapter:        24,versenumber:         2,verse:"扫罗就从以色列人中挑选三千精兵，率领他们往野羊的磐石去，寻索大卫和跟随他的人。"},
{volumn:         9,chapter:        24,versenumber:         3,verse:"到了路旁的羊圈，在那里有洞，扫罗进去大解。大卫和跟随他的人正藏在洞里的深处。"},
{volumn:         9,chapter:        24,versenumber:         4,verse:"跟随的人对大卫说：“耶和华曾应许你说：‘我要将你的仇敌交在你手里，你可以任意待他。’如今时候到了！”大卫就起来，悄悄地割下扫罗外袍的衣襟。"},
{volumn:         9,chapter:        24,versenumber:         5,verse:"随后大卫心中自责，因为割下扫罗的衣襟；"},
{volumn:         9,chapter:        24,versenumber:         6,verse:"对跟随他的人说：“我的主乃是耶和华的受膏者，我在耶和华面前万不敢伸手害他，因他是耶和华的受膏者。”"},
{volumn:         9,chapter:        24,versenumber:         7,verse:"大卫用这话拦住跟随他的人，不容他们起来害扫罗。扫罗起来，从洞里出去行路。"},
{volumn:         9,chapter:        24,versenumber:         8,verse:"随后大卫也起来，从洞里出去，呼叫扫罗说：“我主，我王！”扫罗回头观看，大卫就屈身、脸伏于地下拜。"},
{volumn:         9,chapter:        24,versenumber:         9,verse:"大卫对扫罗说：“你为何听信人的谗言，说大卫想要害你呢？"},
{volumn:         9,chapter:        24,versenumber:        10,verse:"今日你亲眼看见在洞中，耶和华将你交在我手里；有人叫我杀你，我却爱惜你，说：‘我不敢伸手害我的主，因为他是耶和华的受膏者。’"},
{volumn:         9,chapter:        24,versenumber:        11,verse:"我父啊，看看你外袍的衣襟在我手中。我割下你的衣襟，没有杀你；你由此可以知道我没有恶意叛逆你。你虽然猎取我的命，我却没有得罪你。"},
{volumn:         9,chapter:        24,versenumber:        12,verse:"愿耶和华在你我中间判断是非，在你身上为我伸冤，我却不亲手加害于你。"},
{volumn:         9,chapter:        24,versenumber:        13,verse:"古人有句俗语说：‘恶事出于恶人。’我却不亲手加害于你。"},
{volumn:         9,chapter:        24,versenumber:        14,verse:"以色列王出来要寻找谁呢？追赶谁呢？不过追赶一条死狗，一个虼蚤就是了。"},
{volumn:         9,chapter:        24,versenumber:        15,verse:"愿耶和华在你我中间施行审判，断定是非，并且鉴察，为我伸冤，救我脱离你的手。”"},
{volumn:         9,chapter:        24,versenumber:        16,verse:"大卫向扫罗说完这话，扫罗说：“我儿大卫，这是你的声音吗？”就放声大哭，"},
{volumn:         9,chapter:        24,versenumber:        17,verse:"对大卫说：“你比我公义；因为你以善待我，我却以恶待你。"},
{volumn:         9,chapter:        24,versenumber:        18,verse:"你今日显明是以善待我；因为耶和华将我交在你手里，你却没有杀我。"},
{volumn:         9,chapter:        24,versenumber:        19,verse:"人若遇见仇敌，岂肯放他平安无事地去呢？愿耶和华因你今日向我所行的，以善报你。"},
{volumn:         9,chapter:        24,versenumber:        20,verse:"我也知道你必要作王，以色列的国必坚立在你手里。"},
{volumn:         9,chapter:        24,versenumber:        21,verse:"现在你要指着耶和华向我起誓，不剪除我的后裔，在我父家不灭没我的名。”"},
{volumn:         9,chapter:        24,versenumber:        22,verse:"于是大卫向扫罗起誓，扫罗就回家去；大卫和跟随他的人上山寨去了。"},
{volumn:         9,chapter:        25,versenumber:         1,verse:"撒母耳死了，以色列众人聚集，为他哀哭，将他葬在拉玛，他自己的坟墓（原文是房屋）里。大卫起身，下到巴兰的旷野。"},
{volumn:         9,chapter:        25,versenumber:         2,verse:"在玛云有一个人，他的产业在迦密，是一个大富户，有三千绵羊，一千山羊；他正在迦密剪羊毛。"},
{volumn:         9,chapter:        25,versenumber:         3,verse:"那人名叫拿八，是迦勒族的人；他的妻名叫亚比该，是聪明俊美的妇人。拿八为人刚愎凶恶。"},
{volumn:         9,chapter:        25,versenumber:         4,verse:"大卫在旷野听见说拿八剪羊毛，"},
{volumn:         9,chapter:        25,versenumber:         5,verse:"大卫就打发十个仆人，吩咐他们说：“你们上迦密去见拿八，提我的名问他安。"},
{volumn:         9,chapter:        25,versenumber:         6,verse:"要对那富户如此说：‘愿你平安，愿你家平安，愿你一切所有的都平安。"},
{volumn:         9,chapter:        25,versenumber:         7,verse:"现在我听说有人为你剪羊毛，你的牧人在迦密的时候和我们在一处，我们没有欺负他们，他们也未曾失落什么。"},
{volumn:         9,chapter:        25,versenumber:         8,verse:"可以问你的仆人，他们必告诉你。所以愿我的仆人在你眼前蒙恩，因为是在好日子来的。求你随手取点赐与仆人和你儿子大卫。’”"},
{volumn:         9,chapter:        25,versenumber:         9,verse:"大卫的仆人到了，将这话提大卫的名都告诉了拿八，就住了口。"},
{volumn:         9,chapter:        25,versenumber:        10,verse:"拿八回答大卫的仆人说：“大卫是谁？耶西的儿子是谁？近来悖逆主人奔逃的仆人甚多，"},
{volumn:         9,chapter:        25,versenumber:        11,verse:"我岂可将饮食和为我剪羊毛人所宰的肉给我不知道从哪里来的人呢？”"},
{volumn:         9,chapter:        25,versenumber:        12,verse:"大卫的仆人就转身从原路回去，照这话告诉大卫。"},
{volumn:         9,chapter:        25,versenumber:        13,verse:"大卫向跟随他的人说：“你们各人都要带上刀！”众人就都带上刀，大卫也带上刀。跟随大卫上去的约有四百人，留下二百人看守器具。"},
{volumn:         9,chapter:        25,versenumber:        14,verse:"有拿八的一个仆人告诉拿八的妻亚比该说：“大卫从旷野打发使者来问我主人的安，主人却辱骂他们。"},
{volumn:         9,chapter:        25,versenumber:        15,verse:"但是那些人待我们甚好；我们在田野与他们来往的时候，没有受他们的欺负，也未曾失落什么。"},
{volumn:         9,chapter:        25,versenumber:        16,verse:"我们在他们那里牧羊的时候，他们昼夜作我们的保障。"},
{volumn:         9,chapter:        25,versenumber:        17,verse:"所以你当筹划，看怎样行才好；不然，祸患定要临到我主人和他全家。他性情凶暴，无人敢与他说话。”"},
{volumn:         9,chapter:        25,versenumber:        18,verse:"亚比该急忙将二百饼，两皮袋酒，五只收拾好了的羊，五细亚烘好了的穗子，一百葡萄饼，二百无花果饼，都驮在驴上，"},
{volumn:         9,chapter:        25,versenumber:        19,verse:"对仆人说：“你们前头走，我随着你们去。”这事她却没有告诉丈夫拿八。"},
{volumn:         9,chapter:        25,versenumber:        20,verse:"亚比该骑着驴，正下山坡，见大卫和跟随他的人从对面下来，亚比该就迎接他们。"},
{volumn:         9,chapter:        25,versenumber:        21,verse:"大卫曾说：“我在旷野为那人看守所有的，以致他一样不失落，实在是徒然了！他向我以恶报善。"},
{volumn:         9,chapter:        25,versenumber:        22,verse:"凡属拿八的男丁，我若留一个到明日早晨，愿　神重重降罚与我！”"},
{volumn:         9,chapter:        25,versenumber:        23,verse:"亚比该见大卫，便急忙下驴，在大卫面前脸伏于地叩拜，"},
{volumn:         9,chapter:        25,versenumber:        24,verse:"俯伏在大卫的脚前，说：“我主啊，愿这罪归我！求你容婢女向你进言，更求你听婢女的话。"},
{volumn:         9,chapter:        25,versenumber:        25,verse:"我主不要理这坏人拿八，他的性情与他的名相称；他名叫拿八（就是愚顽的意思），他为人果然愚顽。但我主所打发的仆人，婢女并没有看见。"},
{volumn:         9,chapter:        25,versenumber:        26,verse:"我主啊，耶和华既然阻止你亲手报仇，取流血的罪，所以我指着永生的耶和华、又敢在你面前起誓说：‘愿你的仇敌和谋害你的人都像拿八一样。’"},
{volumn:         9,chapter:        25,versenumber:        27,verse:"如今求你将婢女送来的礼物给跟随你的仆人。"},
{volumn:         9,chapter:        25,versenumber:        28,verse:"求你饶恕婢女的罪过。耶和华必为我主建立坚固的家，因我主为耶和华争战；并且在你平生的日子查不出有什么过来。"},
{volumn:         9,chapter:        25,versenumber:        29,verse:"虽有人起来追逼你，寻索你的性命，你的性命却在耶和华你的　神那里蒙保护，如包裹宝器一样；你仇敌的性命，耶和华必抛去，如用机弦甩石一样。"},
{volumn:         9,chapter:        25,versenumber:        30,verse:"我主现在若不亲手报仇流无辜人的血，到了耶和华照所应许你的话赐福与你，立你作以色列的王，那时我主必不至心里不安，觉得良心有亏。耶和华赐福与我主的时候，求你记念婢女。”"},
{volumn:         9,chapter:        25,versenumber:        31,verse:""},
{volumn:         9,chapter:        25,versenumber:        32,verse:"大卫对亚比该说：“耶和华以色列的　神是应当称颂的，因为他今日使你来迎接我。"},
{volumn:         9,chapter:        25,versenumber:        33,verse:"你和你的见识也当称赞；因为你今日拦阻我亲手报仇、流人的血。"},
{volumn:         9,chapter:        25,versenumber:        34,verse:"我指着阻止我加害于你的耶和华以色列永生的　神起誓，你若不速速地来迎接我，到明日早晨，凡属拿八的男丁必定不留一个。”"},
{volumn:         9,chapter:        25,versenumber:        35,verse:"大卫受了亚比该送来的礼物，就对她说：“我听了你的话，准了你的情面，你可以平平安安地回家吧！”"},
{volumn:         9,chapter:        25,versenumber:        36,verse:"亚比该到拿八那里，见他在家里设摆筵席，如同王的筵席；拿八快乐大醉。亚比该无论大小事都没有告诉他，就等到次日早晨。"},
{volumn:         9,chapter:        25,versenumber:        37,verse:"到了早晨，拿八醒了酒，他的妻将这些事都告诉他，他就魂不附体，身僵如石头一般。"},
{volumn:         9,chapter:        25,versenumber:        38,verse:"过了十天，耶和华击打拿八，他就死了。"},
{volumn:         9,chapter:        25,versenumber:        39,verse:"大卫听见拿八死了，就说：“应当称颂耶和华，因他伸了拿八羞辱我的冤，又阻止仆人行恶；也使拿八的恶归到拿八的头上。”于是大卫打发人去，与亚比该说，要娶她为妻。"},
{volumn:         9,chapter:        25,versenumber:        40,verse:"大卫的仆人到了迦密见亚比该，对她说：“大卫打发我们来见你，想要娶你为妻。”"},
{volumn:         9,chapter:        25,versenumber:        41,verse:"亚比该就起来，俯伏在地，说：“我情愿作婢女，洗我主仆人的脚。”"},
{volumn:         9,chapter:        25,versenumber:        42,verse:"亚比该立刻起身，骑上驴，带着五个使女，跟从大卫的使者去了，就作了大卫的妻。"},
{volumn:         9,chapter:        25,versenumber:        43,verse:"大卫先娶了耶斯列人亚希暖，她们二人都作了他的妻。"},
{volumn:         9,chapter:        25,versenumber:        44,verse:"扫罗已将他的女儿米甲，就是大卫的妻，给了迦琳人拉亿的儿子帕提为妻。"},
{volumn:         9,chapter:        26,versenumber:         1,verse:"西弗人到基比亚见扫罗，说：“大卫不是在旷野前的哈基拉山藏着吗？”"},
{volumn:         9,chapter:        26,versenumber:         2,verse:"扫罗就起身，带领以色列人中挑选的三千精兵下到西弗的旷野，要在那里寻索大卫。"},
{volumn:         9,chapter:        26,versenumber:         3,verse:"扫罗在旷野前的哈基拉山，在道路上安营。大卫住在旷野，听说扫罗到旷野来追寻他，"},
{volumn:         9,chapter:        26,versenumber:         4,verse:"就打发人去探听，便知道扫罗果然来到。"},
{volumn:         9,chapter:        26,versenumber:         5,verse:"大卫起来，到扫罗安营的地方，看见扫罗和他的元帅尼珥的儿子押尼珥睡卧之处；扫罗睡在辎重营里，百姓安营在他周围。"},
{volumn:         9,chapter:        26,versenumber:         6,verse:"大卫对赫人亚希米勒和洗鲁雅的儿子约押的兄弟亚比筛说：“谁同我下到扫罗营里去？”亚比筛说：“我同你下去。”"},
{volumn:         9,chapter:        26,versenumber:         7,verse:"于是大卫和亚比筛夜间到了百姓那里，见扫罗睡在辎重营里；他的枪在头旁，插在地上。押尼珥和百姓睡在他周围。"},
{volumn:         9,chapter:        26,versenumber:         8,verse:"亚比筛对大卫说：“现在　神将你的仇敌交在你手里，求你容我拿枪将他刺透在地，一刺就成，不用再刺。”"},
{volumn:         9,chapter:        26,versenumber:         9,verse:"大卫对亚比筛说：“不可害死他。有谁伸手害耶和华的受膏者而无罪呢？”"},
{volumn:         9,chapter:        26,versenumber:        10,verse:"大卫又说：“我指着永生的耶和华起誓，他或被耶和华击打，或是死期到了，或是出战阵亡；"},
{volumn:         9,chapter:        26,versenumber:        11,verse:"我在耶和华面前，万不敢伸手害耶和华的受膏者。现在你可以将他头旁的枪和水瓶拿来，我们就走。”"},
{volumn:         9,chapter:        26,versenumber:        12,verse:"大卫从扫罗的头旁拿了枪和水瓶，二人就走了，没有人看见，没有人知道，也没有人醒起，都睡着了，因为耶和华使他们沉沉地睡了。"},
{volumn:         9,chapter:        26,versenumber:        13,verse:"大卫过到那边去，远远地站在山顶上，与他们相离甚远。"},
{volumn:         9,chapter:        26,versenumber:        14,verse:"大卫呼叫百姓和尼珥的儿子押尼珥说：“押尼珥啊，你为何不答应呢？”押尼珥说：“你是谁？竟敢呼叫王呢？”"},
{volumn:         9,chapter:        26,versenumber:        15,verse:"大卫对押尼珥说：“你不是个勇士吗？以色列中谁能比你呢？民中有人进来要害死王你的主，你为何没有保护王你的主呢？"},
{volumn:         9,chapter:        26,versenumber:        16,verse:"你这样是不好的！我指着永生的耶和华起誓，你们都是该死的；因为没有保护你们的主，就是耶和华的受膏者。现在你看看王头旁的枪和水瓶在哪里。”"},
{volumn:         9,chapter:        26,versenumber:        17,verse:"扫罗听出是大卫的声音，就说：“我儿大卫，这是你的声音吗？”大卫说：“主我的王啊，是我的声音”；"},
{volumn:         9,chapter:        26,versenumber:        18,verse:"又说：“我做了什么？我手里有什么恶事？我主竟追赶仆人呢？"},
{volumn:         9,chapter:        26,versenumber:        19,verse:"求我主我王听仆人的话：若是耶和华激发你攻击我，愿耶和华收纳祭物；若是人激发你，愿他在耶和华面前受咒诅；因为他现今赶逐我，不容我在耶和华的产业上有份，说：‘你去侍奉别神吧！’"},
{volumn:         9,chapter:        26,versenumber:        20,verse:"现在求王不要使我的血流在离耶和华远的地方。以色列王出来是寻找一个虼蚤，如同人在山上猎取一个鹧鸪一般。”"},
{volumn:         9,chapter:        26,versenumber:        21,verse:"扫罗说：“我有罪了！我儿大卫，你可以回来，因你今日看我的性命为宝贵；我必不再加害于你。我是糊涂人，大大错了。”"},
{volumn:         9,chapter:        26,versenumber:        22,verse:"大卫说：“王的枪在这里，可以吩咐一个仆人过来拿去。"},
{volumn:         9,chapter:        26,versenumber:        23,verse:"今日耶和华将王交在我手里，我却不肯伸手害耶和华的受膏者。耶和华必照各人的公义诚实报应他。"},
{volumn:         9,chapter:        26,versenumber:        24,verse:"我今日重看你的性命，愿耶和华也重看我的性命，并且拯救我脱离一切患难。”"},
{volumn:         9,chapter:        26,versenumber:        25,verse:"扫罗对大卫说：“我儿大卫，愿你得福！你必做大事，也必得胜。”于是大卫起行，扫罗回他的本处去了。"},
{volumn:         9,chapter:        27,versenumber:         1,verse:"大卫心里说：“必有一日我死在扫罗手里，不如逃奔非利士地去。扫罗见我不在以色列的境内，就必绝望，不再寻索我；这样我可以脱离他的手。”"},
{volumn:         9,chapter:        27,versenumber:         2,verse:"于是大卫起身，和跟随他的六百人投奔迦特王玛俄的儿子亚吉去了。"},
{volumn:         9,chapter:        27,versenumber:         3,verse:"大卫和他的两个妻，就是耶斯列人亚希暖和作过拿八妻的迦密人亚比该，并跟随他的人，连各人的眷属，都住在迦特的亚吉那里。"},
{volumn:         9,chapter:        27,versenumber:         4,verse:"有人告诉扫罗说：“大卫逃到迦特。”扫罗就不再寻索他了。"},
{volumn:         9,chapter:        27,versenumber:         5,verse:"大卫对亚吉说：“我若在你眼前蒙恩，求你在京外的城邑中赐我一个地方居住。仆人何必与王同住京都呢？”"},
{volumn:         9,chapter:        27,versenumber:         6,verse:"当日亚吉将洗革拉赐给他，因此洗革拉属犹大王，直到今日。"},
{volumn:         9,chapter:        27,versenumber:         7,verse:"大卫在非利士地住了一年零四个月。"},
{volumn:         9,chapter:        27,versenumber:         8,verse:"大卫和跟随他的人上去，侵夺基述人、基色人、亚玛力人之地。这几族历来住在那地，从书珥直到埃及。"},
{volumn:         9,chapter:        27,versenumber:         9,verse:"大卫击杀那地的人，无论男女都没有留下一个，又夺获牛、羊、骆驼、驴，并衣服，回来见亚吉。"},
{volumn:         9,chapter:        27,versenumber:        10,verse:"亚吉说：“你们今日侵夺了什么地方呢？”大卫说：“侵夺了犹大的南方、耶拉篾的南方、基尼的南方。”"},
{volumn:         9,chapter:        27,versenumber:        11,verse:"无论男女，大卫没有留下一个带到迦特来。他说：“恐怕他们将我们的事告诉人，说大卫住在非利士地的时候常常这样行。”"},
{volumn:         9,chapter:        27,versenumber:        12,verse:"亚吉信了大卫，心里说：“大卫使本族以色列人憎恶他，所以他必永远作我的仆人了。”"},
{volumn:         9,chapter:        28,versenumber:         1,verse:"那时，非利士人聚集军旅，要与以色列人打仗。亚吉对大卫说：“你当知道，你和跟随你的人都要随我出战。”"},
{volumn:         9,chapter:        28,versenumber:         2,verse:"大卫对亚吉说：“仆人所能做的事，王必知道。”亚吉对大卫说：“这样，我立你永远作我的护卫长。”"},
{volumn:         9,chapter:        28,versenumber:         3,verse:"那时撒母耳已经死了，以色列众人为他哀哭，葬他在拉玛，就是在他本城里。扫罗曾在国内不容有交鬼的和行巫术的人。"},
{volumn:         9,chapter:        28,versenumber:         4,verse:"非利士人聚集，来到书念安营；扫罗聚集以色列众人在基利波安营。"},
{volumn:         9,chapter:        28,versenumber:         5,verse:"扫罗看见非利士的军旅就惧怕，心中发颤。"},
{volumn:         9,chapter:        28,versenumber:         6,verse:"扫罗求问耶和华，耶和华却不藉梦，或乌陵，或先知回答他。"},
{volumn:         9,chapter:        28,versenumber:         7,verse:"扫罗吩咐臣仆说：“当为我找一个交鬼的妇人，我好去问她。”臣仆说：“在隐·多珥有一个交鬼的妇人。”"},
{volumn:         9,chapter:        28,versenumber:         8,verse:"于是扫罗改了装，穿上别的衣服，带着两个人，夜里去见那妇人。扫罗说：“求你用交鬼的法术，将我所告诉你的死人，为我招上来。”"},
{volumn:         9,chapter:        28,versenumber:         9,verse:"妇人对他说：“你知道扫罗从国中剪除交鬼的和行巫术的。你为何陷害我的性命，使我死呢？”"},
{volumn:         9,chapter:        28,versenumber:        10,verse:"扫罗向妇人指着耶和华起誓说：“我指着永生的耶和华起誓，你必不因这事受刑。”"},
{volumn:         9,chapter:        28,versenumber:        11,verse:"妇人说：“我为你招谁上来呢？”回答说：“为我招撒母耳上来。”"},
{volumn:         9,chapter:        28,versenumber:        12,verse:"妇人看见撒母耳，就大声呼叫，对扫罗说：“你是扫罗，为什么欺哄我呢？”"},
{volumn:         9,chapter:        28,versenumber:        13,verse:"王对妇人说：“不要惧怕，你看见了什么呢？”妇人对扫罗说：“我看见有神从地里上来。”"},
{volumn:         9,chapter:        28,versenumber:        14,verse:"扫罗说：“他是怎样的形状？”妇人说：“有一个老人上来，身穿长衣。”扫罗知道是撒母耳，就屈身，脸伏于地下拜。"},
{volumn:         9,chapter:        28,versenumber:        15,verse:"撒母耳对扫罗说：“你为什么搅扰我，招我上来呢？”扫罗回答说：“我甚窘急；因为非利士人攻击我，　神也离开我，不再藉先知或梦回答我。因此请你上来，好指示我应当怎样行。”"},
{volumn:         9,chapter:        28,versenumber:        16,verse:"撒母耳说：“耶和华已经离开你，且与你为敌，你何必问我呢？"},
{volumn:         9,chapter:        28,versenumber:        17,verse:"耶和华照他藉我说的话，已经从你手里夺去国权，赐与别人，就是大卫。"},
{volumn:         9,chapter:        28,versenumber:        18,verse:"因你没有听从耶和华的命令；他恼怒亚玛力人，你没有灭绝他们，所以今日耶和华向你这样行，"},
{volumn:         9,chapter:        28,versenumber:        19,verse:"并且耶和华必将你和以色列人交在非利士人的手里。明日你和你众子必与我在一处了；耶和华必将以色列的军兵交在非利士人手里。”"},
{volumn:         9,chapter:        28,versenumber:        20,verse:"扫罗猛然仆倒，挺身在地，因撒母耳的话甚是惧怕；那一昼一夜，没有吃什么，就毫无气力。"},
{volumn:         9,chapter:        28,versenumber:        21,verse:"妇人到扫罗面前，见他极其惊恐，对他说：“婢女听从你的话，不顾惜自己的性命，遵从你所吩咐的。"},
{volumn:         9,chapter:        28,versenumber:        22,verse:"现在求你听婢女的话，容我在你面前摆上一点食物，你吃了，可以有气力行路。”"},
{volumn:         9,chapter:        28,versenumber:        23,verse:"扫罗不肯，说：“我不吃。”但他的仆人和妇人再三劝他，他才听了他们的话，从地上起来，坐在床上。"},
{volumn:         9,chapter:        28,versenumber:        24,verse:"妇人急忙将家里的一只肥牛犊宰了，又拿面抟成无酵饼烤了，"},
{volumn:         9,chapter:        28,versenumber:        25,verse:"摆在扫罗和他仆人面前。他们吃完，当夜就起身走了。"},
{volumn:         9,chapter:        29,versenumber:         1,verse:"非利士人将他们的军旅聚到亚弗；以色列人在耶斯列的泉旁安营。"},
{volumn:         9,chapter:        29,versenumber:         2,verse:"非利士人的首领各率军队，或百或千，挨次前进；大卫和跟随他的人同着亚吉跟在后边。"},
{volumn:         9,chapter:        29,versenumber:         3,verse:"非利士人的首领说：“这些希伯来人在这里做什么呢？”亚吉对他们说：“这不是以色列王扫罗的臣子大卫吗？他在我这里有些年日了。自从他投降我直到今日，我未曾见他有过错。”"},
{volumn:         9,chapter:        29,versenumber:         4,verse:"非利士人的首领向亚吉发怒，对他说：“你要叫这人回你所安置他的地方，不可叫他同我们出战，恐怕他在阵上反为我们的敌人。他用什么与他主人复和呢？岂不是用我们这些人的首级吗？"},
{volumn:         9,chapter:        29,versenumber:         5,verse:"从前以色列的妇女跳舞唱和说：‘扫罗杀死千千，大卫杀死万万’，所说的不是这个大卫吗？”"},
{volumn:         9,chapter:        29,versenumber:         6,verse:"亚吉叫大卫来，对他说：“我指着永生的耶和华起誓，你是正直人。你随我在军中出入，我看你甚好。自从你投奔我到如今，我未曾见你有什么过失；只是众首领不喜悦你。"},
{volumn:         9,chapter:        29,versenumber:         7,verse:"现在你可以平平安安地回去，免得非利士人的首领不欢喜你。”"},
{volumn:         9,chapter:        29,versenumber:         8,verse:"大卫对亚吉说：“我做了什么呢？自从仆人到你面前，直到今日，你查出我有什么过错，使我不去攻击主我王的仇敌呢？”"},
{volumn:         9,chapter:        29,versenumber:         9,verse:"亚吉说：“我知道你在我眼前是好人，如同　神的使者一般；只是非利士人的首领说：‘这人不可同我们出战。’"},
{volumn:         9,chapter:        29,versenumber:        10,verse:"故此你和跟随你的人，就是你本主的仆人，要明日早晨起来，等到天亮回去吧！”"},
{volumn:         9,chapter:        29,versenumber:        11,verse:"于是大卫和跟随他的人早晨起来，回往非利士地去。非利士人也上耶斯列去了。"},
{volumn:         9,chapter:        30,versenumber:         1,verse:"第三日，大卫和跟随他的人到了洗革拉。亚玛力人已经侵夺南地，攻破洗革拉，用火焚烧，"},
{volumn:         9,chapter:        30,versenumber:         2,verse:"掳了城内的妇女和其中的大小人口，却没有杀一个，都带着走了。"},
{volumn:         9,chapter:        30,versenumber:         3,verse:"大卫和跟随他的人到了那城，不料，城已烧毁，他们的妻子儿女都被掳去了。"},
{volumn:         9,chapter:        30,versenumber:         4,verse:"大卫和跟随他的人就放声大哭，直哭得没有气力。"},
{volumn:         9,chapter:        30,versenumber:         5,verse:"大卫的两个妻，耶斯列人亚希暖和作过拿八妻的迦密人亚比该，也被掳去了。"},
{volumn:         9,chapter:        30,versenumber:         6,verse:"大卫甚是焦急，因众人为自己的儿女苦恼，说：“要用石头打死他。”大卫却倚靠耶和华他的　神，心里坚固。"},
{volumn:         9,chapter:        30,versenumber:         7,verse:"大卫对亚希米勒的儿子祭司亚比亚他说：“请你将以弗得拿过来。”亚比亚他就将以弗得拿到大卫面前。"},
{volumn:         9,chapter:        30,versenumber:         8,verse:"大卫求问耶和华说：“我追赶敌军，追得上追不上呢？”耶和华说：“你可以追，必追得上，都救得回来。”"},
{volumn:         9,chapter:        30,versenumber:         9,verse:"于是，大卫和跟随他的六百人来到比梭溪；有不能前去的就留在那里。"},
{volumn:         9,chapter:        30,versenumber:        10,verse:"大卫却带着四百人往前追赶，有二百人疲乏，不能过比梭溪，所以留在那里。"},
{volumn:         9,chapter:        30,versenumber:        11,verse:"这四百人在田野遇见一个埃及人，就带他到大卫面前，给他饼吃，给他水喝，"},
{volumn:         9,chapter:        30,versenumber:        12,verse:"又给他一块无花果饼，两个葡萄饼。他吃了，就精神复原；因为他三日三夜没有吃饼，没有喝水。"},
{volumn:         9,chapter:        30,versenumber:        13,verse:"大卫问他说：“你是属谁的？你是哪里的人？”他回答说：“我是埃及的少年人，是亚玛力人的奴仆；因我三日前患病，我主人就把我撇弃了。"},
{volumn:         9,chapter:        30,versenumber:        14,verse:"我们侵夺了基利提的南方和属犹大的地，并迦勒地的南方，又用火烧了洗革拉。”"},
{volumn:         9,chapter:        30,versenumber:        15,verse:"大卫问他说：“你肯领我们到敌军那里不肯？”他回答说：“你要向我指着　神起誓，不杀我，也不将我交在我主人手里，我就领你下到敌军那里。”"},
{volumn:         9,chapter:        30,versenumber:        16,verse:"那人领大卫下去，见他们散在地上，吃喝跳舞，因为从非利士地和犹大地所掳来的财物甚多。"},
{volumn:         9,chapter:        30,versenumber:        17,verse:"大卫从黎明直到次日晚上，击杀他们，除了四百骑骆驼的少年人之外，没有一个逃脱的。"},
{volumn:         9,chapter:        30,versenumber:        18,verse:"亚玛力人所掳去的财物，大卫全都夺回，并救回他的两个妻来。"},
{volumn:         9,chapter:        30,versenumber:        19,verse:"凡亚玛力人所掳去的，无论大小、儿女、财物，大卫都夺回来，没有失落一个。"},
{volumn:         9,chapter:        30,versenumber:        20,verse:"大卫所夺来的牛群羊群，跟随他的人赶在原有的群畜前边，说：“这是大卫的掠物。”"},
{volumn:         9,chapter:        30,versenumber:        21,verse:"大卫到了那疲乏不能跟随、留在比梭溪的二百人那里。他们出来迎接大卫并跟随的人。大卫前来问他们安。"},
{volumn:         9,chapter:        30,versenumber:        22,verse:"跟随大卫人中的恶人和匪类说：“这些人既然没有和我们同去，我们所夺的财物就不分给他们，只将他们各人的妻子儿女给他们，使他们带去就是了。”"},
{volumn:         9,chapter:        30,versenumber:        23,verse:"大卫说：“弟兄们，耶和华所赐给我们的，不可不分给他们；因为他保佑我们，将那攻击我们的敌军交在我们手里。"},
{volumn:         9,chapter:        30,versenumber:        24,verse:"这事谁肯依从你们呢？上阵的得多少，看守器具的也得多少；应当大家平分。”"},
{volumn:         9,chapter:        30,versenumber:        25,verse:"大卫定此为以色列的律例典章，从那日直到今日。"},
{volumn:         9,chapter:        30,versenumber:        26,verse:"大卫到了洗革拉，从掠物中取些送给他朋友犹大的长老，说：“这是从耶和华仇敌那里夺来的，送你们为礼物。”"},
{volumn:         9,chapter:        30,versenumber:        27,verse:"他送礼物给住伯特利的，南地拉末的，雅提珥的；"},
{volumn:         9,chapter:        30,versenumber:        28,verse:"住亚罗珥的，息末的，以实提莫的；"},
{volumn:         9,chapter:        30,versenumber:        29,verse:"住拉哈勒的，耶拉篾各城的，基尼各城的；"},
{volumn:         9,chapter:        30,versenumber:        30,verse:"住何珥玛的，歌拉珊的，亚挞的；"},
{volumn:         9,chapter:        30,versenumber:        31,verse:"住希伯仑的，并大卫和跟随他的人素来所到之处的人。"},
{volumn:         9,chapter:        31,versenumber:         1,verse:"非利士人与以色列人争战。以色列人在非利士人面前逃跑，在基利波有被杀仆倒的。"},
{volumn:         9,chapter:        31,versenumber:         2,verse:"非利士人紧追扫罗和他儿子们，就杀了扫罗的儿子约拿单、亚比拿达、麦基舒亚。"},
{volumn:         9,chapter:        31,versenumber:         3,verse:"势派甚大，扫罗被弓箭手追上，射伤甚重，"},
{volumn:         9,chapter:        31,versenumber:         4,verse:"就吩咐拿他兵器的人说：“你拔出刀来，将我刺死，免得那些未受割礼的人来刺我，凌辱我。”但拿兵器的人甚惧怕，不肯刺他；扫罗就自己伏在刀上死了。"},
{volumn:         9,chapter:        31,versenumber:         5,verse:"拿兵器的人见扫罗已死，也伏在刀上死了。"},
{volumn:         9,chapter:        31,versenumber:         6,verse:"这样，扫罗和他三个儿子，与拿他兵器的人，以及跟随他的人，都一同死亡。"},
{volumn:         9,chapter:        31,versenumber:         7,verse:"住平原那边并约旦河西的以色列人，见以色列军兵逃跑，扫罗和他儿子都死了，也就弃城逃跑。非利士人便来住在其中。"},
{volumn:         9,chapter:        31,versenumber:         8,verse:"次日，非利士人来剥那被杀之人的衣服，看见扫罗和他三个儿子仆倒在基利波山，"},
{volumn:         9,chapter:        31,versenumber:         9,verse:"就割下他的首级，剥了他的军装，打发人到（或译：送到）非利士地的四境，报信与他们庙里的偶像和众民；"},
{volumn:         9,chapter:        31,versenumber:        10,verse:"又将扫罗的军装放在亚斯他录庙里，将他的尸身钉在伯·珊的城墙上。"},
{volumn:         9,chapter:        31,versenumber:        11,verse:"基列·雅比的居民听见非利士人向扫罗所行的事，"},
{volumn:         9,chapter:        31,versenumber:        12,verse:"他们中间所有的勇士就起身，走了一夜，将扫罗和他儿子的尸身从伯·珊城墙上取下来，送到雅比那里，用火烧了；"},
{volumn:         9,chapter:        31,versenumber:        13,verse:"将他们骸骨葬在雅比的垂丝柳树下，就禁食七日。"},
{volumn:        10,chapter:         1,versenumber:         1,verse:"扫罗死后，大卫击杀亚玛力人回来，在洗革拉住了两天。"},
{volumn:        10,chapter:         1,versenumber:         2,verse:"第三天，有一人从扫罗的营里出来，衣服撕裂，头蒙灰尘，到大卫面前伏地叩拜。"},
{volumn:        10,chapter:         1,versenumber:         3,verse:"大卫问他说：“你从哪里来？”他说：“我从以色列的营里逃来。”"},
{volumn:        10,chapter:         1,versenumber:         4,verse:"大卫又问他说：“事情怎样？请你告诉我。”他回答说：“百姓从阵上逃跑，也有许多人仆倒死亡；扫罗和他儿子约拿单也死了。”"},
{volumn:        10,chapter:         1,versenumber:         5,verse:"大卫问报信的少年人说：“你怎么知道扫罗和他儿子约拿单死了呢？”"},
{volumn:        10,chapter:         1,versenumber:         6,verse:"报信的少年人说：“我偶然到基利波山，看见扫罗伏在自己枪上，有战车、马兵紧紧地追他。"},
{volumn:        10,chapter:         1,versenumber:         7,verse:"他回头看见我，就呼叫我。我说：‘我在这里。’"},
{volumn:        10,chapter:         1,versenumber:         8,verse:"他问我说：‘你是什么人？’我说：‘我是亚玛力人。’"},
{volumn:        10,chapter:         1,versenumber:         9,verse:"他说：‘请你来，将我杀死；因为痛苦抓住我，我的生命尚存。’"},
{volumn:        10,chapter:         1,versenumber:        10,verse:"我准知他仆倒必不能活，就去将他杀死，把他头上的冠冕、臂上的镯子拿到我主这里。”"},
{volumn:        10,chapter:         1,versenumber:        11,verse:"大卫就撕裂衣服，跟随他的人也是如此，"},
{volumn:        10,chapter:         1,versenumber:        12,verse:"而且悲哀哭号，禁食到晚上，是因扫罗和他儿子约拿单，并耶和华的民以色列家的人，倒在刀下。"},
{volumn:        10,chapter:         1,versenumber:        13,verse:"大卫问报信的少年人说：“你是哪里的人？”他说：“我是亚玛力客人的儿子。”"},
{volumn:        10,chapter:         1,versenumber:        14,verse:"大卫说：“你伸手杀害耶和华的受膏者，怎么不畏惧呢？”"},
{volumn:        10,chapter:         1,versenumber:        15,verse:"大卫叫了一个少年人来，说：“你去杀他吧！”"},
{volumn:        10,chapter:         1,versenumber:        16,verse:"大卫对他说：“你流人血的罪归到自己的头上，因为你亲口作见证说：‘我杀了耶和华的受膏者。’”少年人就把他杀了。"},
{volumn:        10,chapter:         1,versenumber:        17,verse:"大卫作哀歌，吊扫罗和他儿子约拿单，"},
{volumn:        10,chapter:         1,versenumber:        18,verse:"且吩咐将这歌教导犹大人。这歌名叫“弓歌”，写在雅煞珥书上。"},
{volumn:        10,chapter:         1,versenumber:        19,verse:"歌中说：以色列啊，你尊荣者在山上被杀！大英雄何竟死亡！"},
{volumn:        10,chapter:         1,versenumber:        20,verse:"不要在迦特报告；不要在亚实基伦街上传扬；免得非利士的女子欢乐；免得未受割礼之人的女子矜夸。"},
{volumn:        10,chapter:         1,versenumber:        21,verse:"基利波山哪，愿你那里没有雨露！愿你田地无土产可作供物！因为英雄的盾牌在那里被污丢弃；扫罗的盾牌仿佛未曾抹油。"},
{volumn:        10,chapter:         1,versenumber:        22,verse:"约拿单的弓箭非流敌人的血不退缩；扫罗的刀剑非剖勇士的油不收回。"},
{volumn:        10,chapter:         1,versenumber:        23,verse:"扫罗和约拿单，活时相悦相爱，死时也不分离。他们比鹰更快，比狮子还强。"},
{volumn:        10,chapter:         1,versenumber:        24,verse:"以色列的女子啊，当为扫罗哭号！他曾使你们穿朱红色的美衣，使你们衣服有黄金的妆饰。"},
{volumn:        10,chapter:         1,versenumber:        25,verse:"英雄何竟在阵上仆倒！约拿单何竟在山上被杀！"},
{volumn:        10,chapter:         1,versenumber:        26,verse:"我兄约拿单哪，我为你悲伤！我甚喜悦你！你向我发的爱情奇妙非常，过于妇女的爱情。"},
{volumn:        10,chapter:         1,versenumber:        27,verse:"英雄何竟仆倒！战具何竟灭没！"},
{volumn:        10,chapter:         2,versenumber:         1,verse:"此后，大卫问耶和华说：“我上犹大的一个城去可以吗？”耶和华说：“可以。”大卫说：“我上哪一个城去呢？”耶和华说：“上希伯仑去。”"},
{volumn:        10,chapter:         2,versenumber:         2,verse:"于是大卫和他的两个妻：一个是耶斯列人亚希暖，一个是作过迦密人拿八妻的亚比该，都上那里去了。"},
{volumn:        10,chapter:         2,versenumber:         3,verse:"大卫也将跟随他的人和他们各人的眷属一同带上去，住在希伯仑的城邑中。"},
{volumn:        10,chapter:         2,versenumber:         4,verse:"犹大人来到希伯仑，在那里膏大卫作犹大家的王。有人告诉大卫说：“葬埋扫罗的是基列·雅比人。”"},
{volumn:        10,chapter:         2,versenumber:         5,verse:"大卫就差人去见基列·雅比人，对他们说：“你们厚待你们的主扫罗，将他葬埋。愿耶和华赐福与你们！"},
{volumn:        10,chapter:         2,versenumber:         6,verse:"你们既行了这事，愿耶和华以慈爱诚实待你们，我也要为此厚待你们。"},
{volumn:        10,chapter:         2,versenumber:         7,verse:"现在你们的主扫罗死了，犹大家已经膏我作他们的王，所以你们要刚强奋勇。”"},
{volumn:        10,chapter:         2,versenumber:         8,verse:"扫罗的元帅尼珥的儿子押尼珥，曾将扫罗的儿子伊施波设带过河，到玛哈念，"},
{volumn:        10,chapter:         2,versenumber:         9,verse:"立他作王，治理基列、亚书利、耶斯列、以法莲、便雅悯，和以色列众人。"},
{volumn:        10,chapter:         2,versenumber:        10,verse:"扫罗的儿子伊施波设登基的时候年四十岁，作以色列王二年；惟独犹大家归从大卫。"},
{volumn:        10,chapter:         2,versenumber:        11,verse:"大卫在希伯仑作犹大家的王，共七年零六个月。"},
{volumn:        10,chapter:         2,versenumber:        12,verse:"尼珥的儿子押尼珥和扫罗的儿子伊施波设的仆人从玛哈念出来，往基遍去。"},
{volumn:        10,chapter:         2,versenumber:        13,verse:"洗鲁雅的儿子约押和大卫的仆人也出来，在基遍池旁与他们相遇；一班坐在池这边，一班坐在池那边。"},
{volumn:        10,chapter:         2,versenumber:        14,verse:"押尼珥对约押说：“让少年人起来，在我们面前戏耍吧！”约押说：“可以。”"},
{volumn:        10,chapter:         2,versenumber:        15,verse:"就按着定数起来：属扫罗儿子伊施波设的便雅悯人过去十二名，大卫的仆人也过去十二名，"},
{volumn:        10,chapter:         2,versenumber:        16,verse:"彼此揪头，用刀刺肋，一同仆倒。所以，那地叫做希利甲·哈素林，就在基遍。"},
{volumn:        10,chapter:         2,versenumber:        17,verse:"那日的战事凶猛，押尼珥和以色列人败在大卫的仆人面前。"},
{volumn:        10,chapter:         2,versenumber:        18,verse:"在那里有洗鲁雅的三个儿子：约押、亚比筛、亚撒黑。亚撒黑脚快如野鹿一般；"},
{volumn:        10,chapter:         2,versenumber:        19,verse:"亚撒黑追赶押尼珥，直追赶他不偏左右。"},
{volumn:        10,chapter:         2,versenumber:        20,verse:"押尼珥回头说：“你是亚撒黑吗？”回答说：“是。”"},
{volumn:        10,chapter:         2,versenumber:        21,verse:"押尼珥对他说：“你或转向左转向右，拿住一个少年人，剥去他的战衣。”亚撒黑却不肯转开不追赶他。"},
{volumn:        10,chapter:         2,versenumber:        22,verse:"押尼珥又对亚撒黑说：“你转开不追赶我吧！我何必杀你呢？若杀你，有什么脸见你哥哥约押呢？”"},
{volumn:        10,chapter:         2,versenumber:        23,verse:"亚撒黑仍不肯转开。故此，押尼珥就用枪鐏刺入他的肚腹，甚至枪从背后透出，亚撒黑就在那里仆倒而死。众人赶到亚撒黑仆倒而死的地方，就都站住。"},
{volumn:        10,chapter:         2,versenumber:        24,verse:"约押和亚比筛追赶押尼珥，日落的时候，到了通基遍旷野的路旁，基亚对面的亚玛山。"},
{volumn:        10,chapter:         2,versenumber:        25,verse:"便雅悯人聚集，跟随押尼珥站在一个山顶上。"},
{volumn:        10,chapter:         2,versenumber:        26,verse:"押尼珥呼叫约押说：“刀剑岂可永远杀人吗？你岂不知终久必有苦楚吗？你要等何时才叫百姓回去、不追赶弟兄呢？”"},
{volumn:        10,chapter:         2,versenumber:        27,verse:"约押说：“我指着永生的　神起誓：你若不说戏耍的那句话，今日早晨百姓就回去，不追赶弟兄了。”"},
{volumn:        10,chapter:         2,versenumber:        28,verse:"于是约押吹角，众民就站住，不再追赶以色列人，也不再打仗了。"},
{volumn:        10,chapter:         2,versenumber:        29,verse:"押尼珥和跟随他的人整夜经过亚拉巴，过约旦河，走过毕伦，到了玛哈念。"},
{volumn:        10,chapter:         2,versenumber:        30,verse:"约押追赶押尼珥回来，聚集众民，见大卫的仆人中缺少了十九个人和亚撒黑。"},
{volumn:        10,chapter:         2,versenumber:        31,verse:"但大卫的仆人杀了便雅悯人和跟随押尼珥的人，共三百六十名。"},
{volumn:        10,chapter:         2,versenumber:        32,verse:"众人将亚撒黑送到伯利恒，葬在他父亲的坟墓里。约押和跟随他的人走了一夜，天亮的时候到了希伯仑。"},
{volumn:        10,chapter:         3,versenumber:         1,verse:"扫罗家和大卫家争战许久。大卫家日见强盛；扫罗家日见衰弱。"},
{volumn:        10,chapter:         3,versenumber:         2,verse:"大卫在希伯仑得了几个儿子：长子暗嫩是耶斯列人亚希暖所生的；"},
{volumn:        10,chapter:         3,versenumber:         3,verse:"次子基利押（在历代志上三章一节作但以利）是作过迦密人拿八的妻亚比该所生的；三子押沙龙是基述王达买的女儿玛迦所生的；"},
{volumn:        10,chapter:         3,versenumber:         4,verse:"四子亚多尼雅是哈及所生的；五子示法提雅是亚比他所生的；"},
{volumn:        10,chapter:         3,versenumber:         5,verse:"六子以特念是大卫的妻以格拉所生的。大卫这六个儿子都是在希伯仑生的。"},
{volumn:        10,chapter:         3,versenumber:         6,verse:"扫罗家和大卫家争战的时候，押尼珥在扫罗家大有权势。"},
{volumn:        10,chapter:         3,versenumber:         7,verse:"扫罗有一妃嫔，名叫利斯巴，是爱亚的女儿。一日，伊施波设对押尼珥说：“你为什么与我父的妃嫔同房呢？”"},
{volumn:        10,chapter:         3,versenumber:         8,verse:"押尼珥因伊施波设的话就甚发怒，说：“我岂是犹大的狗头呢？我恩待你父扫罗的家和他的弟兄、朋友，不将你交在大卫手里，今日你竟为这妇人责备我吗？"},
{volumn:        10,chapter:         3,versenumber:         9,verse:"我若不照着耶和华起誓应许大卫的话行，废去扫罗的位，建立大卫的位，使他治理以色列和犹大，从但直到别是巴，愿　神重重地降罚与我！”"},
{volumn:        10,chapter:         3,versenumber:        10,verse:""},
{volumn:        10,chapter:         3,versenumber:        11,verse:"伊施波设惧怕押尼珥，不敢回答一句。"},
{volumn:        10,chapter:         3,versenumber:        12,verse:"押尼珥打发人去见大卫，替他说：“这国归谁呢？”又说：“你与我立约，我必帮助你，使以色列人都归服你。”"},
{volumn:        10,chapter:         3,versenumber:        13,verse:"大卫说：“好！我与你立约。但有一件，你来见我面的时候，若不将扫罗的女儿米甲带来，必不得见我的面。”"},
{volumn:        10,chapter:         3,versenumber:        14,verse:"大卫就打发人去见扫罗的儿子伊施波设，说：“你要将我的妻米甲归还我；她是我从前用一百非利士人的阳皮所聘定的。”"},
{volumn:        10,chapter:         3,versenumber:        15,verse:"伊施波设就打发人去，将米甲从拉亿的儿子、她丈夫帕铁那里接回来。"},
{volumn:        10,chapter:         3,versenumber:        16,verse:"米甲的丈夫跟着她，一面走一面哭，直跟到巴户琳。押尼珥说：“你回去吧！”帕铁就回去了。"},
{volumn:        10,chapter:         3,versenumber:        17,verse:"押尼珥对以色列长老说：“从前你们愿意大卫作王治理你们，"},
{volumn:        10,chapter:         3,versenumber:        18,verse:"现在你们可以照心愿而行。因为耶和华曾论到大卫说：‘我必藉我仆人大卫的手，救我民以色列脱离非利士人和众仇敌的手。’”"},
{volumn:        10,chapter:         3,versenumber:        19,verse:"押尼珥也用这话说给便雅悯人听，又到希伯仑，将以色列人和便雅悯全家一切所喜悦的事说给大卫听。"},
{volumn:        10,chapter:         3,versenumber:        20,verse:"押尼珥带着二十个人来到希伯仑见大卫，大卫就为押尼珥和他带来的人设摆筵席。"},
{volumn:        10,chapter:         3,versenumber:        21,verse:"押尼珥对大卫说：“我要起身去招聚以色列众人来见我主我王，与你立约，你就可以照着心愿作王。”于是大卫送押尼珥去，押尼珥就平平安安地去了。"},
{volumn:        10,chapter:         3,versenumber:        22,verse:"约押和大卫的仆人攻击敌军，带回许多的掠物。那时押尼珥不在希伯仑大卫那里，因大卫已经送他去，他也平平安安地去了。"},
{volumn:        10,chapter:         3,versenumber:        23,verse:"约押和跟随他的全军到了，就有人告诉约押说：“尼珥的儿子押尼珥来见王，王送他去，他也平平安安地去了。”"},
{volumn:        10,chapter:         3,versenumber:        24,verse:"约押去见王说：“你这是做什么呢？押尼珥来见你，你为何送他去，他就踪影不见了呢？"},
{volumn:        10,chapter:         3,versenumber:        25,verse:"你当晓得，尼珥的儿子押尼珥来是要诓哄你，要知道你的出入和你一切所行的事。”"},
{volumn:        10,chapter:         3,versenumber:        26,verse:"约押从大卫那里出来，就打发人去追赶押尼珥，在西拉井追上他，将他带回来，大卫却不知道。"},
{volumn:        10,chapter:         3,versenumber:        27,verse:"押尼珥回到希伯仑，约押领他到城门的瓮洞，假作要与他说机密话，就在那里刺透他的肚腹，他便死了。这是报杀他兄弟亚撒黑的仇。"},
{volumn:        10,chapter:         3,versenumber:        28,verse:"大卫听见了，就说：“流尼珥的儿子押尼珥的血，这罪在耶和华面前必永不归我和我的国。"},
{volumn:        10,chapter:         3,versenumber:        29,verse:"愿流他血的罪归到约押头上和他父的全家；又愿约押家不断有患漏症的，长大麻风的，架拐而行的，被刀杀死的，缺乏饮食的。”"},
{volumn:        10,chapter:         3,versenumber:        30,verse:"约押和他兄弟亚比筛杀了押尼珥，是因押尼珥在基遍争战的时候杀了他们的兄弟亚撒黑。"},
{volumn:        10,chapter:         3,versenumber:        31,verse:"大卫吩咐约押和跟随他的众人说：“你们当撕裂衣服，腰束麻布，在押尼珥棺前哀哭。”大卫王也跟在棺后。"},
{volumn:        10,chapter:         3,versenumber:        32,verse:"他们将押尼珥葬在希伯仑。王在押尼珥的墓旁放声而哭，众民也都哭了。"},
{volumn:        10,chapter:         3,versenumber:        33,verse:"王为押尼珥举哀，说：“押尼珥何竟像愚顽人死呢？"},
{volumn:        10,chapter:         3,versenumber:        34,verse:"你手未曾捆绑，脚未曾锁住。你死，如人死在罪孽之辈手下一样。”于是众民又为押尼珥哀哭。"},
{volumn:        10,chapter:         3,versenumber:        35,verse:"日头未落的时候，众民来劝大卫吃饭，但大卫起誓说：“我若在日头未落以前吃饭，或吃别物，愿　神重重地降罚与我！”"},
{volumn:        10,chapter:         3,versenumber:        36,verse:"众民知道了就都喜悦。凡王所行的，众民无不喜悦。"},
{volumn:        10,chapter:         3,versenumber:        37,verse:"那日，以色列众民才知道杀尼珥的儿子押尼珥并非出于王意。"},
{volumn:        10,chapter:         3,versenumber:        38,verse:"王对臣仆说：“你们岂不知今日以色列人中死了一个作元帅的大丈夫吗？"},
{volumn:        10,chapter:         3,versenumber:        39,verse:"我虽然受膏为王，今日还是软弱；这洗鲁雅的两个儿子比我刚强。愿耶和华照着恶人所行的恶报应他。”"},
{volumn:        10,chapter:         4,versenumber:         1,verse:"扫罗的儿子伊施波设听见押尼珥死在希伯仑，手就发软；以色列众人也都惊惶。"},
{volumn:        10,chapter:         4,versenumber:         2,verse:"扫罗的儿子伊施波设有两个军长，一名巴拿，一名利甲，是便雅悯支派、比录人临门的儿子。比录也属便雅悯。"},
{volumn:        10,chapter:         4,versenumber:         3,verse:"比录人早先逃到基他音，在那里寄居，直到今日。"},
{volumn:        10,chapter:         4,versenumber:         4,verse:"扫罗的儿子约拿单有一个儿子名叫米非波设，是瘸腿的。扫罗和约拿单死亡的消息从耶斯列传到的时候，他才五岁。他乳母抱着他逃跑；因为跑得太急，孩子掉在地上，腿就瘸了。"},
{volumn:        10,chapter:         4,versenumber:         5,verse:"一日，比录人临门的两个儿子利甲和巴拿出去，约在午热的时候到了伊施波设的家；伊施波设正睡午觉。"},
{volumn:        10,chapter:         4,versenumber:         6,verse:"他们进了房子，假作要取麦子，就刺透伊施波设的肚腹，逃跑了。"},
{volumn:        10,chapter:         4,versenumber:         7,verse:"他们进房子的时候，伊施波设正在卧房里躺在床上，他们将他杀死，割了他的首级，拿着首级在亚拉巴走了一夜，"},
{volumn:        10,chapter:         4,versenumber:         8,verse:"将伊施波设的首级拿到希伯仑见大卫王，说：“王的仇敌扫罗曾寻索王的性命。看哪，这是他儿子伊施波设的首级；耶和华今日为我主我王在扫罗和他后裔的身上报了仇。”"},
{volumn:        10,chapter:         4,versenumber:         9,verse:"大卫对比录人临门的儿子利甲和他兄弟巴拿说：“我指着救我性命脱离一切苦难、永生的耶和华起誓："},
{volumn:        10,chapter:         4,versenumber:        10,verse:"从前有人报告我说，扫罗死了，他自以为报好消息；我就拿住他，将他杀在洗革拉，这就作了他报消息的赏赐。"},
{volumn:        10,chapter:         4,versenumber:        11,verse:"何况恶人将义人杀在他的床上，我岂不向你们讨流他血的罪、从世上除灭你们呢？”"},
{volumn:        10,chapter:         4,versenumber:        12,verse:"于是大卫吩咐少年人将他们杀了，砍断他们的手脚，挂在希伯仑的池旁，却将伊施波设的首级葬在希伯仑、押尼珥的坟墓里。"},
{volumn:        10,chapter:         5,versenumber:         1,verse:"以色列众支派来到希伯仑见大卫，说：“我们原是你的骨肉。"},
{volumn:        10,chapter:         5,versenumber:         2,verse:"从前扫罗作我们王的时候，率领以色列人出入的是你；耶和华也曾应许你说：‘你必牧养我的民以色列，作以色列的君。’”"},
{volumn:        10,chapter:         5,versenumber:         3,verse:"于是以色列的长老都来到希伯仑见大卫王，大卫在希伯仑耶和华面前与他们立约，他们就膏大卫作以色列的王。"},
{volumn:        10,chapter:         5,versenumber:         4,verse:"大卫登基的时候年三十岁，在位四十年；"},
{volumn:        10,chapter:         5,versenumber:         5,verse:"在希伯仑作犹大王七年零六个月，在耶路撒冷作以色列和犹大王三十三年。"},
{volumn:        10,chapter:         5,versenumber:         6,verse:"大卫和跟随他的人到了耶路撒冷，要攻打住那地方的耶布斯人。耶布斯人对大卫说：“你若不赶出瞎子、瘸子，必不能进这地方”；心里想大卫决不能进去。"},
{volumn:        10,chapter:         5,versenumber:         7,verse:"然而大卫攻取锡安的保障，就是大卫的城。"},
{volumn:        10,chapter:         5,versenumber:         8,verse:"当日，大卫说：“谁攻打耶布斯人，当上水沟攻打我心里所恨恶的瘸子、瞎子。”从此有俗语说：“在那里有瞎子、瘸子，他不能进屋去。”"},
{volumn:        10,chapter:         5,versenumber:         9,verse:"大卫住在保障里，给保障起名叫大卫城。大卫又从米罗以里，周围筑墙。"},
{volumn:        10,chapter:         5,versenumber:        10,verse:"大卫日见强盛，因为耶和华万军之　神与他同在。"},
{volumn:        10,chapter:         5,versenumber:        11,verse:"推罗王希兰将香柏木运到大卫那里，又差遣使者和木匠、石匠给大卫建造宫殿。"},
{volumn:        10,chapter:         5,versenumber:        12,verse:"大卫就知道耶和华坚立他作以色列王，又为自己的民以色列使他的国兴旺。"},
{volumn:        10,chapter:         5,versenumber:        13,verse:"大卫离开希伯仑之后，在耶路撒冷又立后妃，又生儿女。"},
{volumn:        10,chapter:         5,versenumber:        14,verse:"在耶路撒冷所生的儿子是沙母亚、朔罢、拿单、所罗门、"},
{volumn:        10,chapter:         5,versenumber:        15,verse:"益辖、以利书亚、尼斐、雅非亚、"},
{volumn:        10,chapter:         5,versenumber:        16,verse:"以利沙玛、以利雅大、以利法列。"},
{volumn:        10,chapter:         5,versenumber:        17,verse:"非利士人听见人膏大卫作以色列王，非利士众人就上来寻索大卫；大卫听见，就下到保障。"},
{volumn:        10,chapter:         5,versenumber:        18,verse:"非利士人来了，布散在利乏音谷。"},
{volumn:        10,chapter:         5,versenumber:        19,verse:"大卫求问耶和华说：“我可以上去攻打非利士人吗？你将他们交在我手里吗？”耶和华说：“你可以上去，我必将非利士人交在你手里。”"},
{volumn:        10,chapter:         5,versenumber:        20,verse:"大卫来到巴力·毗拉心，在那里击杀非利士人，说：“耶和华在我面前冲破敌人，如同水冲去一般。”因此称那地方为巴力·毗拉心。"},
{volumn:        10,chapter:         5,versenumber:        21,verse:"非利士人将偶像撇在那里，大卫和跟随他的人拿去了。"},
{volumn:        10,chapter:         5,versenumber:        22,verse:"非利士人又上来，布散在利乏音谷。"},
{volumn:        10,chapter:         5,versenumber:        23,verse:"大卫求问耶和华；耶和华说：“不要一直地上去，要转到他们后头，从桑林对面攻打他们。"},
{volumn:        10,chapter:         5,versenumber:        24,verse:"你听见桑树梢上有脚步的声音，就要急速前去，因为那时耶和华已经在你前头去攻打非利士人的军队。”"},
{volumn:        10,chapter:         5,versenumber:        25,verse:"大卫就遵着耶和华所吩咐的去行，攻打非利士人，从迦巴直到基色。"},
{volumn:        10,chapter:         6,versenumber:         1,verse:"大卫又聚集以色列中所有挑选的人三万。"},
{volumn:        10,chapter:         6,versenumber:         2,verse:"大卫起身，率领跟随他的众人前往，要从巴拉·犹大将　神的约柜运来；这约柜就是坐在二基路伯上万军之耶和华留名的约柜。"},
{volumn:        10,chapter:         6,versenumber:         3,verse:"他们将　神的约柜从冈上亚比拿达的家里抬出来，放在新车上；亚比拿达的两个儿子乌撒和亚希约赶这新车。"},
{volumn:        10,chapter:         6,versenumber:         4,verse:"他们将　神的约柜从冈上亚比拿达家里抬出来的时候，亚希约在柜前行走。"},
{volumn:        10,chapter:         6,versenumber:         5,verse:"大卫和以色列的全家在耶和华面前，用松木制造的各样乐器和琴、瑟、鼓、钹、锣，作乐跳舞。"},
{volumn:        10,chapter:         6,versenumber:         6,verse:"到了拿艮的禾场，因为牛失前蹄（或译：惊跳），乌撒就伸手扶住　神的约柜。"},
{volumn:        10,chapter:         6,versenumber:         7,verse:"神耶和华向乌撒发怒，因这错误击杀他，他就死在　神的约柜旁。"},
{volumn:        10,chapter:         6,versenumber:         8,verse:"大卫因耶和华击杀（原文是闯杀）乌撒，心里愁烦，就称那地方为毗列斯·乌撒，直到今日。"},
{volumn:        10,chapter:         6,versenumber:         9,verse:"那日，大卫惧怕耶和华，说：“耶和华的约柜怎可运到我这里来？”"},
{volumn:        10,chapter:         6,versenumber:        10,verse:"于是大卫不肯将耶和华的约柜运进大卫的城，却运到迦特人俄别·以东的家中。"},
{volumn:        10,chapter:         6,versenumber:        11,verse:"耶和华的约柜在迦特人俄别·以东家中三个月；耶和华赐福给俄别·以东和他的全家。"},
{volumn:        10,chapter:         6,versenumber:        12,verse:"有人告诉大卫王说：“耶和华因为约柜赐福给俄别·以东的家和一切属他的。”大卫就去，欢欢喜喜地将　神的约柜从俄别·以东家中抬到大卫的城里。"},
{volumn:        10,chapter:         6,versenumber:        13,verse:"抬耶和华约柜的人走了六步，大卫就献牛与肥羊为祭。"},
{volumn:        10,chapter:         6,versenumber:        14,verse:"大卫穿着细麻布的以弗得，在耶和华面前极力跳舞。"},
{volumn:        10,chapter:         6,versenumber:        15,verse:"这样，大卫和以色列的全家欢呼吹角，将耶和华的约柜抬上来。"},
{volumn:        10,chapter:         6,versenumber:        16,verse:"耶和华的约柜进了大卫城的时候，扫罗的女儿米甲从窗户里观看，见大卫王在耶和华面前踊跃跳舞，心里就轻视他。"},
{volumn:        10,chapter:         6,versenumber:        17,verse:"众人将耶和华的约柜请进去，安放在所预备的地方，就是在大卫所搭的帐幕里。大卫在耶和华面前献燔祭和平安祭。"},
{volumn:        10,chapter:         6,versenumber:        18,verse:"大卫献完了燔祭和平安祭，就奉万军之耶和华的名给民祝福，"},
{volumn:        10,chapter:         6,versenumber:        19,verse:"并且分给以色列众人，无论男女，每人一个饼，一块肉，一个葡萄饼；众人就各回各家去了。"},
{volumn:        10,chapter:         6,versenumber:        20,verse:"大卫回家要给眷属祝福；扫罗的女儿米甲出来迎接他，说：“以色列王今日在臣仆的婢女眼前露体，如同一个轻贱人无耻露体一样，有好大的荣耀啊！”"},
{volumn:        10,chapter:         6,versenumber:        21,verse:"大卫对米甲说：“这是在耶和华面前；耶和华已拣选我，废了你父和你父的全家，立我作耶和华民以色列的君，所以我必在耶和华面前跳舞。"},
{volumn:        10,chapter:         6,versenumber:        22,verse:"我也必更加卑微，自己看为轻贱。你所说的那些婢女，她们倒要尊敬我。”"},
{volumn:        10,chapter:         6,versenumber:        23,verse:"扫罗的女儿米甲，直到死日，没有生养儿女。"},
{volumn:        10,chapter:         7,versenumber:         1,verse:"王住在自己宫中，耶和华使他安靖，不被四围的仇敌扰乱。"},
{volumn:        10,chapter:         7,versenumber:         2,verse:"那时，王对先知拿单说：“看哪，我住在香柏木的宫中，　神的约柜反在幔子里。”"},
{volumn:        10,chapter:         7,versenumber:         3,verse:"拿单对王说：“你可以照你的心意而行，因为耶和华与你同在。”"},
{volumn:        10,chapter:         7,versenumber:         4,verse:"当夜，耶和华的话临到拿单说："},
{volumn:        10,chapter:         7,versenumber:         5,verse:"“你去告诉我仆人大卫，说耶和华如此说：‘你岂可建造殿宇给我居住呢？"},
{volumn:        10,chapter:         7,versenumber:         6,verse:"自从我领以色列人出埃及直到今日，我未曾住过殿宇，常在会幕和帐幕中行走。"},
{volumn:        10,chapter:         7,versenumber:         7,verse:"凡我同以色列人所走的地方，我何曾向以色列一支派的士师，就是我吩咐牧养我民以色列的说：你们为何不给我建造香柏木的殿宇呢？’"},
{volumn:        10,chapter:         7,versenumber:         8,verse:"“现在，你要告诉我仆人大卫，说万军之耶和华如此说：‘我从羊圈中将你召来，叫你不再跟从羊群，立你作我民以色列的君。"},
{volumn:        10,chapter:         7,versenumber:         9,verse:"你无论往哪里去，我常与你同在，剪除你的一切仇敌。我必使你得大名，好像世上大大有名的人一样。"},
{volumn:        10,chapter:         7,versenumber:        10,verse:"我必为我民以色列选定一个地方，栽培他们，使他们住自己的地方，不再迁移；凶恶之子也不像从前扰害他们，"},
{volumn:        10,chapter:         7,versenumber:        11,verse:"并不像我命士师治理我民以色列的时候一样。我必使你安靖，不被一切仇敌扰乱，并且我耶和华应许你，必为你建立家室。"},
{volumn:        10,chapter:         7,versenumber:        12,verse:"你寿数满足、与你列祖同睡的时候，我必使你的后裔接续你的位；我也必坚定他的国。"},
{volumn:        10,chapter:         7,versenumber:        13,verse:"他必为我的名建造殿宇；我必坚定他的国位，直到永远。"},
{volumn:        10,chapter:         7,versenumber:        14,verse:"我要作他的父，他要作我的子；他若犯了罪，我必用人的杖责打他，用人的鞭责罚他。"},
{volumn:        10,chapter:         7,versenumber:        15,verse:"但我的慈爱仍不离开他，像离开在你面前所废弃的扫罗一样。"},
{volumn:        10,chapter:         7,versenumber:        16,verse:"你的家和你的国必在我（原文是你）面前永远坚立。你的国位也必坚定，直到永远。’”"},
{volumn:        10,chapter:         7,versenumber:        17,verse:"拿单就按这一切话，照这默示，告诉大卫。"},
{volumn:        10,chapter:         7,versenumber:        18,verse:"于是大卫王进去，坐在耶和华面前，说：“主耶和华啊，我是谁？我的家算什么？你竟使我到这地步呢？"},
{volumn:        10,chapter:         7,versenumber:        19,verse:"主耶和华啊，这在你眼中还看为小，又应许你仆人的家至于久远。主耶和华啊，这岂是人所常遇的事吗？"},
{volumn:        10,chapter:         7,versenumber:        20,verse:"主耶和华啊，我还有何言可以对你说呢？因为你知道你的仆人。"},
{volumn:        10,chapter:         7,versenumber:        21,verse:"你行这大事使仆人知道，是因你所应许的话，也是照你的心意。"},
{volumn:        10,chapter:         7,versenumber:        22,verse:"主耶和华啊，你本为大，照我们耳中听见，没有可比你的；除你以外再无　神。"},
{volumn:        10,chapter:         7,versenumber:        23,verse:"世上有何民能比你的民以色列呢？你从埃及救赎他们作自己的子民，又在你赎出来的民面前行大而可畏的事，驱逐列邦人和他们的神，显出你的大名。"},
{volumn:        10,chapter:         7,versenumber:        24,verse:"你曾坚立你的民以色列作你的子民，直到永远；你耶和华也作了他们的　神。"},
{volumn:        10,chapter:         7,versenumber:        25,verse:"“耶和华　神啊，你所应许仆人和仆人家的话，求你坚定，直到永远；照你所说的而行。"},
{volumn:        10,chapter:         7,versenumber:        26,verse:"愿人永远尊你的名为大，说：‘万军之耶和华是治理以色列的　神。’这样，你仆人大卫的家必在你面前坚立。"},
{volumn:        10,chapter:         7,versenumber:        27,verse:"万军之耶和华以色列的　神啊，因你启示你的仆人说：‘我必为你建立家室’，所以仆人大胆向你如此祈祷。"},
{volumn:        10,chapter:         7,versenumber:        28,verse:"“主耶和华啊，惟有你是　神。你的话是真实的；你也应许将这福气赐给仆人。"},
{volumn:        10,chapter:         7,versenumber:        29,verse:"现在求你赐福与仆人的家，可以永存在你面前。主耶和华啊，这是你所应许的。愿你永远赐福与仆人的家！”"},
{volumn:        10,chapter:         8,versenumber:         1,verse:"此后，大卫攻打非利士人，把他们治服，从他们手下夺取了京城的权柄（原文是母城的嚼环）；"},
{volumn:        10,chapter:         8,versenumber:         2,verse:"又攻打摩押人，使他们躺卧在地上，用绳量一量：量二绳的杀了，量一绳的存留。摩押人就归服大卫，给他进贡。"},
{volumn:        10,chapter:         8,versenumber:         3,verse:"琐巴王利合的儿子哈大底谢往大河去，要夺回他的国权。大卫就攻打他，"},
{volumn:        10,chapter:         8,versenumber:         4,verse:"擒拿了他的马兵一千七百，步兵二万，将拉战车的马砍断蹄筋，但留下一百辆车的马。"},
{volumn:        10,chapter:         8,versenumber:         5,verse:"大马士革的亚兰人来帮助琐巴王哈大底谢，大卫就杀了亚兰人二万二千。"},
{volumn:        10,chapter:         8,versenumber:         6,verse:"于是大卫在大马士革的亚兰地设立防营，亚兰人就归服他，给他进贡。大卫无论往哪里去，耶和华都使他得胜。"},
{volumn:        10,chapter:         8,versenumber:         7,verse:"他夺了哈大底谢臣仆所拿的金盾牌，带到耶路撒冷。"},
{volumn:        10,chapter:         8,versenumber:         8,verse:"大卫王又从属哈大底谢的比他和比罗他城中夺取了许多的铜。"},
{volumn:        10,chapter:         8,versenumber:         9,verse:"哈马王陀以听见大卫杀败哈大底谢的全军，"},
{volumn:        10,chapter:         8,versenumber:        10,verse:"就打发他儿子约兰去见大卫王，问他的安，为他祝福，因为他杀败了哈大底谢（原来陀以与哈大底谢常常争战）。约兰带了金银铜的器皿来，"},
{volumn:        10,chapter:         8,versenumber:        11,verse:"大卫王将这些器皿和他治服各国所得来的金银都分别为圣，献给耶和华，"},
{volumn:        10,chapter:         8,versenumber:        12,verse:"就是从亚兰、摩押、亚扪、非利士、亚玛力人所得来的，以及从琐巴王利合的儿子哈大底谢所掠之物。"},
{volumn:        10,chapter:         8,versenumber:        13,verse:"大卫在盐谷击杀了亚兰（或译：以东，见诗篇六十篇诗题）一万八千人回来，就得了大名；"},
{volumn:        10,chapter:         8,versenumber:        14,verse:"又在以东全地设立防营，以东人就都归服大卫。大卫无论往哪里去，耶和华都使他得胜。"},
{volumn:        10,chapter:         8,versenumber:        15,verse:"大卫作以色列众人的王，又向众民秉公行义。"},
{volumn:        10,chapter:         8,versenumber:        16,verse:"洗鲁雅的儿子约押作元帅；亚希律的儿子约沙法作史官；"},
{volumn:        10,chapter:         8,versenumber:        17,verse:"亚希突的儿子撒督和亚比亚他的儿子亚希米勒作祭司长；西莱雅作书记；"},
{volumn:        10,chapter:         8,versenumber:        18,verse:"耶何耶大的儿子比拿雅统辖基利提人和比利提人。大卫的众子都作领袖。"},
{volumn:        10,chapter:         9,versenumber:         1,verse:"大卫问说：“扫罗家还有剩下的人没有？我要因约拿单的缘故向他施恩。”"},
{volumn:        10,chapter:         9,versenumber:         2,verse:"扫罗家有一个仆人，名叫洗巴，有人叫他来见大卫，王问他说：“你是洗巴吗？”回答说：“仆人是。”"},
{volumn:        10,chapter:         9,versenumber:         3,verse:"王说：“扫罗家还有人没有？我要照　神的慈爱恩待他。”洗巴对王说：“还有约拿单的一个儿子，是瘸腿的。”"},
{volumn:        10,chapter:         9,versenumber:         4,verse:"王说：“他在哪里？”洗巴对王说：“他在罗·底巴，亚米利的儿子玛吉家里。”"},
{volumn:        10,chapter:         9,versenumber:         5,verse:"于是大卫王打发人去，从罗·底巴、亚米利的儿子玛吉家里召了他来。"},
{volumn:        10,chapter:         9,versenumber:         6,verse:"扫罗的孙子、约拿单的儿子米非波设来见大卫，伏地叩拜。大卫说：“米非波设！”米非波设说：“仆人在此。”"},
{volumn:        10,chapter:         9,versenumber:         7,verse:"大卫说：“你不要惧怕，我必因你父亲约拿单的缘故施恩与你，将你祖父扫罗的一切田地都归还你；你也可以常与我同席吃饭。”"},
{volumn:        10,chapter:         9,versenumber:         8,verse:"米非波设又叩拜，说：“仆人算什么，不过如死狗一般，竟蒙王这样眷顾！”"},
{volumn:        10,chapter:         9,versenumber:         9,verse:"王召了扫罗的仆人洗巴来，对他说：“我已将属扫罗和他的一切家产都赐给你主人的儿子了。"},
{volumn:        10,chapter:         9,versenumber:        10,verse:"你和你的众子、仆人要为你主人的儿子米非波设耕种田地，把所产的拿来供他食用；他却要常与我同席吃饭。”洗巴有十五个儿子，二十个仆人。"},
{volumn:        10,chapter:         9,versenumber:        11,verse:"洗巴对王说：“凡我主我王吩咐仆人的，仆人都必遵行。”王又说：“米非波设必与我同席吃饭，如王的儿子一样。”"},
{volumn:        10,chapter:         9,versenumber:        12,verse:"米非波设有一个小儿子，名叫米迦。凡住在洗巴家里的人都作了米非波设的仆人。"},
{volumn:        10,chapter:         9,versenumber:        13,verse:"于是米非波设住在耶路撒冷，常与王同席吃饭。他两腿都是瘸的。"},
{volumn:        10,chapter:        10,versenumber:         1,verse:"此后，亚扪人的王死了，他儿子哈嫩接续他作王。"},
{volumn:        10,chapter:        10,versenumber:         2,verse:"大卫说：“我要照哈嫩的父亲拿辖厚待我的恩典厚待哈嫩。”于是大卫差遣臣仆，为他丧父安慰他。大卫的臣仆到了亚扪人的境内。"},
{volumn:        10,chapter:        10,versenumber:         3,verse:"但亚扪人的首领对他们的主哈嫩说：“大卫差人来安慰你，你想他是尊敬你父亲吗？他差臣仆来不是详察窥探、要倾覆这城吗？”"},
{volumn:        10,chapter:        10,versenumber:         4,verse:"哈嫩便将大卫臣仆的胡须剃去一半，又割断他们下半截的衣服，使他们露出下体，打发他们回去。"},
{volumn:        10,chapter:        10,versenumber:         5,verse:"有人告诉大卫，他就差人去迎接他们，（因为他们甚觉羞耻），告诉他们说：“可以住在耶利哥，等到胡须长起再回来。”"},
{volumn:        10,chapter:        10,versenumber:         6,verse:"亚扪人知道大卫憎恶他们，就打发人去，招募伯·利合的亚兰人和琐巴的亚兰人，步兵二万，与玛迦王的人一千、陀伯人一万二千。"},
{volumn:        10,chapter:        10,versenumber:         7,verse:"大卫听见了，就差派约押统带勇猛的全军出去。"},
{volumn:        10,chapter:        10,versenumber:         8,verse:"亚扪人出来，在城门前摆阵；琐巴与利合的亚兰人、陀伯人，并玛迦人，另在郊野摆阵。"},
{volumn:        10,chapter:        10,versenumber:         9,verse:"约押看见敌人在他前后摆阵，就从以色列军中挑选精兵，使他们对着亚兰人摆阵。"},
{volumn:        10,chapter:        10,versenumber:        10,verse:"其余的兵交与他兄弟亚比筛，对着亚扪人摆阵。"},
{volumn:        10,chapter:        10,versenumber:        11,verse:"约押对亚比筛说：“亚兰人若强过我，你就来帮助我；亚扪人若强过你，我就去帮助你。"},
{volumn:        10,chapter:        10,versenumber:        12,verse:"我们都当刚强，为本国的民和　神的城邑作大丈夫。愿耶和华凭他的意旨而行！”"},
{volumn:        10,chapter:        10,versenumber:        13,verse:"于是，约押和跟随他的人前进攻打亚兰人；亚兰人在约押面前逃跑。"},
{volumn:        10,chapter:        10,versenumber:        14,verse:"亚扪人见亚兰人逃跑，他们也在亚比筛面前逃跑进城。约押就离开亚扪人那里，回耶路撒冷去了。"},
{volumn:        10,chapter:        10,versenumber:        15,verse:"亚兰人见自己被以色列人打败，就又聚集。"},
{volumn:        10,chapter:        10,versenumber:        16,verse:"哈大底谢差遣人，将大河那边的亚兰人调来；他们到了希兰，哈大底谢的将军朔法率领他们。"},
{volumn:        10,chapter:        10,versenumber:        17,verse:"有人告诉大卫，他就聚集以色列众人，过约旦河，来到希兰。亚兰人迎着大卫摆阵，与他打仗。"},
{volumn:        10,chapter:        10,versenumber:        18,verse:"亚兰人在以色列人面前逃跑；大卫杀了亚兰七百辆战车的人，四万马兵，又杀了亚兰的将军朔法。"},
{volumn:        10,chapter:        10,versenumber:        19,verse:"属哈大底谢的诸王见自己被以色列人打败，就与以色列人和好，归服他们。于是亚兰人不敢再帮助亚扪人了。"},
{volumn:        10,chapter:        11,versenumber:         1,verse:"过了一年，到列王出战的时候，大卫又差派约押，率领臣仆和以色列众人出战。他们就打败亚扪人，围攻拉巴。大卫仍住在耶路撒冷。"},
{volumn:        10,chapter:        11,versenumber:         2,verse:"一日，太阳平西，大卫从床上起来，在王宫的平顶上游行，看见一个妇人沐浴，容貌甚美，"},
{volumn:        10,chapter:        11,versenumber:         3,verse:"大卫就差人打听那妇人是谁。有人说：“她是以连的女儿，赫人乌利亚的妻拔示巴。”"},
{volumn:        10,chapter:        11,versenumber:         4,verse:"大卫差人去，将妇人接来；那时她的月经才得洁净。她来了，大卫与她同房，她就回家去了。"},
{volumn:        10,chapter:        11,versenumber:         5,verse:"于是她怀了孕，打发人去告诉大卫说：“我怀了孕。”"},
{volumn:        10,chapter:        11,versenumber:         6,verse:"大卫差人到约押那里，说：“你打发赫人乌利亚到我这里来。”约押就打发乌利亚去见大卫。"},
{volumn:        10,chapter:        11,versenumber:         7,verse:"乌利亚来了，大卫问约押好，也问兵好，又问争战的事怎样。"},
{volumn:        10,chapter:        11,versenumber:         8,verse:"大卫对乌利亚说：“你回家去，洗洗脚吧！”乌利亚出了王宫，随后王送他一份食物。"},
{volumn:        10,chapter:        11,versenumber:         9,verse:"乌利亚却和他主人的仆人一同睡在宫门外，没有回家去。"},
{volumn:        10,chapter:        11,versenumber:        10,verse:"有人告诉大卫说：“乌利亚没有回家去。”大卫就问乌利亚说：“你从远路上来，为什么不回家去呢？”"},
{volumn:        10,chapter:        11,versenumber:        11,verse:"乌利亚对大卫说：“约柜和以色列与犹大兵都住在棚里，我主约押和我主（或译：王）的仆人都在田野安营，我岂可回家吃喝、与妻子同寝呢？我敢在王面前起誓（原文是我指着王和王的性命起誓）：我决不行这事！”"},
{volumn:        10,chapter:        11,versenumber:        12,verse:"大卫吩咐乌利亚说：“你今日仍住在这里，明日我打发你去。”于是乌利亚那日和次日住在耶路撒冷。"},
{volumn:        10,chapter:        11,versenumber:        13,verse:"大卫召了乌利亚来，叫他在自己面前吃喝，使他喝醉。到了晚上，乌利亚出去与他主的仆人一同住宿，还没有回到家里去。"},
{volumn:        10,chapter:        11,versenumber:        14,verse:"次日早晨，大卫写信与约押，交乌利亚随手带去。"},
{volumn:        10,chapter:        11,versenumber:        15,verse:"信内写着说：“要派乌利亚前进，到阵势极险之处，你们便退后，使他被杀。”"},
{volumn:        10,chapter:        11,versenumber:        16,verse:"约押围城的时候，知道敌人那里有勇士，便将乌利亚派在那里。"},
{volumn:        10,chapter:        11,versenumber:        17,verse:"城里的人出来和约押打仗；大卫的仆人中有几个被杀的，赫人乌利亚也死了。"},
{volumn:        10,chapter:        11,versenumber:        18,verse:"于是，约押差人去将争战的一切事告诉大卫，"},
{volumn:        10,chapter:        11,versenumber:        19,verse:"又嘱咐使者说：“你把争战的一切事对王说完了，"},
{volumn:        10,chapter:        11,versenumber:        20,verse:"王若发怒，问你说：‘你们打仗为什么挨近城墙呢？岂不知敌人必从城上射箭吗？"},
{volumn:        10,chapter:        11,versenumber:        21,verse:"从前打死耶路·比设（就是耶路·巴力，见士师记九章一节）儿子亚比米勒的是谁呢？岂不是一个妇人从城上抛下一块上磨石来，打在他身上，他就死在提备斯吗？你们为什么挨近城墙呢？’你就说：‘王的仆人赫人乌利亚也死了。’”"},
{volumn:        10,chapter:        11,versenumber:        22,verse:"使者起身，来见大卫，照着约押所吩咐他的话奏告大卫。"},
{volumn:        10,chapter:        11,versenumber:        23,verse:"使者对大卫说：“敌人强过我们，出到郊野与我们打仗，我们追杀他们，直到城门口。"},
{volumn:        10,chapter:        11,versenumber:        24,verse:"射箭的从城上射王的仆人，射死几个，赫人乌利亚也死了。”"},
{volumn:        10,chapter:        11,versenumber:        25,verse:"王向使者说：“你告诉约押说：‘不要因这事愁闷，刀剑或吞灭这人或吞灭那人，没有一定的；你只管竭力攻城，将城倾覆。’可以用这话勉励约押。”"},
{volumn:        10,chapter:        11,versenumber:        26,verse:"乌利亚的妻听见丈夫乌利亚死了，就为他哀哭。"},
{volumn:        10,chapter:        11,versenumber:        27,verse:"哀哭的日子过了，大卫差人将她接到宫里，她就作了大卫的妻，给大卫生了一个儿子。但大卫所行的这事，耶和华甚不喜悦。"},
{volumn:        10,chapter:        12,versenumber:         1,verse:"耶和华差遣拿单去见大卫。拿单到了大卫那里，对他说：“在一座城里有两个人：一个是富户，一个是穷人。"},
{volumn:        10,chapter:        12,versenumber:         2,verse:"富户有许多牛群羊群；"},
{volumn:        10,chapter:        12,versenumber:         3,verse:"穷人除了所买来养活的一只小母羊羔之外，别无所有。羊羔在他家里和他儿女一同长大，吃他所吃的，喝他所喝的，睡在他怀中，在他看来如同女儿一样。"},
{volumn:        10,chapter:        12,versenumber:         4,verse:"有一客人来到这富户家里；富户舍不得从自己的牛群羊群中取一只预备给客人吃，却取了那穷人的羊羔，预备给客人吃。”"},
{volumn:        10,chapter:        12,versenumber:         5,verse:"大卫就甚恼怒那人，对拿单说：“我指着永生的耶和华起誓，行这事的人该死！"},
{volumn:        10,chapter:        12,versenumber:         6,verse:"他必偿还羊羔四倍；因为他行这事，没有怜恤的心。”"},
{volumn:        10,chapter:        12,versenumber:         7,verse:"拿单对大卫说：“你就是那人！耶和华以色列的　神如此说：‘我膏你作以色列的王，救你脱离扫罗的手。"},
{volumn:        10,chapter:        12,versenumber:         8,verse:"我将你主人的家业赐给你，将你主人的妻交在你怀里，又将以色列和犹大家赐给你；你若还以为不足，我早就加倍地赐给你。"},
{volumn:        10,chapter:        12,versenumber:         9,verse:"你为什么藐视耶和华的命令，行他眼中看为恶的事呢？你藉亚扪人的刀杀害赫人乌利亚，又娶了他的妻为妻。"},
{volumn:        10,chapter:        12,versenumber:        10,verse:"你既藐视我，娶了赫人乌利亚的妻为妻，所以刀剑必永不离开你的家。’"},
{volumn:        10,chapter:        12,versenumber:        11,verse:"耶和华如此说：‘我必从你家中兴起祸患攻击你；我必在你眼前把你的妃嫔赐给别人，他在日光之下就与她们同寝。"},
{volumn:        10,chapter:        12,versenumber:        12,verse:"你在暗中行这事，我却要在以色列众人面前，日光之下，报应你。’”"},
{volumn:        10,chapter:        12,versenumber:        13,verse:"大卫对拿单说：“我得罪耶和华了！”拿单说：“耶和华已经除掉你的罪，你必不至于死。"},
{volumn:        10,chapter:        12,versenumber:        14,verse:"只是你行这事，叫耶和华的仇敌大得亵渎的机会，故此，你所得的孩子必定要死。”"},
{volumn:        10,chapter:        12,versenumber:        15,verse:"拿单就回家去了。耶和华击打乌利亚妻给大卫所生的孩子，使他得重病。"},
{volumn:        10,chapter:        12,versenumber:        16,verse:"所以大卫为这孩子恳求　神，而且禁食，进入内室，终夜躺在地上。"},
{volumn:        10,chapter:        12,versenumber:        17,verse:"他家中的老臣来到他旁边，要把他从地上扶起来，他却不肯起来，也不同他们吃饭。"},
{volumn:        10,chapter:        12,versenumber:        18,verse:"到第七日，孩子死了。大卫的臣仆不敢告诉他孩子死了，因他们说：“孩子还活着的时候，我们劝他，他尚且不肯听我们的话，若告诉他孩子死了，岂不更加忧伤吗？”"},
{volumn:        10,chapter:        12,versenumber:        19,verse:"大卫见臣仆彼此低声说话，就知道孩子死了，问臣仆说：“孩子死了吗？”他们说：“死了。”"},
{volumn:        10,chapter:        12,versenumber:        20,verse:"大卫就从地上起来，沐浴，抹膏，换了衣裳，进耶和华的殿敬拜；然后回宫，吩咐人摆饭，他便吃了。"},
{volumn:        10,chapter:        12,versenumber:        21,verse:"臣仆问他说：“你所行的是什么意思？孩子活着的时候，你禁食哭泣；孩子死了，你倒起来吃饭。”"},
{volumn:        10,chapter:        12,versenumber:        22,verse:"大卫说：“孩子还活着，我禁食哭泣；因为我想，或者耶和华怜恤我，使孩子不死也未可知。"},
{volumn:        10,chapter:        12,versenumber:        23,verse:"孩子死了，我何必禁食，我岂能使他返回呢？我必往他那里去，他却不能回我这里来。”"},
{volumn:        10,chapter:        12,versenumber:        24,verse:"大卫安慰他的妻拔示巴，与她同寝，她就生了儿子，给他起名叫所罗门。耶和华也喜爱他，"},
{volumn:        10,chapter:        12,versenumber:        25,verse:"就藉先知拿单赐他一个名字，叫耶底底亚，因为耶和华爱他。"},
{volumn:        10,chapter:        12,versenumber:        26,verse:"约押攻取亚扪人的京城拉巴。"},
{volumn:        10,chapter:        12,versenumber:        27,verse:"约押打发使者去见大卫，说：“我攻打拉巴，取其水城。"},
{volumn:        10,chapter:        12,versenumber:        28,verse:"现在你要聚集其余的军兵来，安营围攻这城，恐怕我取了这城，人就以我的名叫这城。”"},
{volumn:        10,chapter:        12,versenumber:        29,verse:"于是大卫聚集众军，往拉巴去攻城，就取了这城，"},
{volumn:        10,chapter:        12,versenumber:        30,verse:"夺了亚扪人之王所戴的金冠冕（王：或译玛勒堪；玛勒堪就是米勒公，又名摩洛，亚扪族之神名），其上的金子重一他连得，又嵌着宝石。人将这冠冕戴在大卫头上。大卫从城里夺了许多财物，"},
{volumn:        10,chapter:        12,versenumber:        31,verse:"将城里的人拉出来，放在锯下，或铁耙下，或铁斧下，或叫他经过砖窑（或译：强他们用锯，或用打粮食的铁器，或用铁斧做工，或使在砖窑里服役）；大卫待亚扪各城的居民都是如此。其后，大卫和众军都回耶路撒冷去了。"},
{volumn:        10,chapter:        13,versenumber:         1,verse:"大卫的儿子押沙龙有一个美貌的妹子，名叫他玛。大卫的儿子暗嫩爱她。"},
{volumn:        10,chapter:        13,versenumber:         2,verse:"暗嫩为他妹子他玛忧急成病。他玛还是处女，暗嫩以为难向她行事。"},
{volumn:        10,chapter:        13,versenumber:         3,verse:"暗嫩有一个朋友，名叫约拿达，是大卫长兄示米亚的儿子。这约拿达为人极其狡猾；"},
{volumn:        10,chapter:        13,versenumber:         4,verse:"他问暗嫩说：“王的儿子啊，为何一天比一天瘦弱呢？请你告诉我。”暗嫩回答说：“我爱我兄弟押沙龙的妹子他玛。”"},
{volumn:        10,chapter:        13,versenumber:         5,verse:"约拿达说：“你不如躺在床上装病；你父亲来看你，就对他说：‘求父叫我妹子他玛来，在我眼前预备食物，递给我吃，使我看见，好从她手里接过来吃。’”"},
{volumn:        10,chapter:        13,versenumber:         6,verse:"于是暗嫩躺卧装病。王来看他，他对王说：“求父叫我妹子他玛来，在我眼前为我做两个饼，我好从她手里接过来吃。”"},
{volumn:        10,chapter:        13,versenumber:         7,verse:"大卫就打发人到宫里，对他玛说：“你往你哥哥暗嫩的屋里去，为他预备食物。”"},
{volumn:        10,chapter:        13,versenumber:         8,verse:"他玛就到她哥哥暗嫩的屋里；暗嫩正躺卧。他玛抟面，在他眼前做饼，且烤熟了，"},
{volumn:        10,chapter:        13,versenumber:         9,verse:"在他面前将饼从锅里倒出来，他却不肯吃，便说：“众人离开我出去吧！”众人就都离开他，出去了。"},
{volumn:        10,chapter:        13,versenumber:        10,verse:"暗嫩对他玛说：“你把食物拿进卧房，我好从你手里接过来吃。”他玛就把所做的饼拿进卧房，到她哥哥暗嫩那里，"},
{volumn:        10,chapter:        13,versenumber:        11,verse:"拿着饼上前给他吃，他便拉住他玛，说：“我妹妹，你来与我同寝。”"},
{volumn:        10,chapter:        13,versenumber:        12,verse:"他玛说：“我哥哥，不要玷辱我。以色列人中不当这样行，你不要做这丑事；"},
{volumn:        10,chapter:        13,versenumber:        13,verse:"你玷辱了我，我何以掩盖我的羞耻呢？你在以色列中也成了愚妄人。你可以求王，他必不禁止我归你。”"},
{volumn:        10,chapter:        13,versenumber:        14,verse:"但暗嫩不肯听她的话，因比她力大，就玷辱她，与她同寝。"},
{volumn:        10,chapter:        13,versenumber:        15,verse:"随后，暗嫩极其恨她，那恨她的心比先前爱她的心更甚，对她说：“你起来，去吧！”"},
{volumn:        10,chapter:        13,versenumber:        16,verse:"他玛说：“不要这样！你赶出我去的这罪比你才行的更重！”但暗嫩不肯听她的话，"},
{volumn:        10,chapter:        13,versenumber:        17,verse:"就叫伺候自己的仆人来，说：“将这个女子赶出去！她一出去，你就关门，上闩。”"},
{volumn:        10,chapter:        13,versenumber:        18,verse:"那时他玛穿着彩衣，因为没有出嫁的公主都是这样穿。暗嫩的仆人就把她赶出去，关门上闩。"},
{volumn:        10,chapter:        13,versenumber:        19,verse:"他玛把灰尘撒在头上，撕裂所穿的彩衣，以手抱头，一面行走，一面哭喊。"},
{volumn:        10,chapter:        13,versenumber:        20,verse:"她胞兄押沙龙问她说：“莫非你哥哥暗嫩与你亲近了吗？我妹妹，暂且不要作声，他是你的哥哥，不要将这事放在心上。”他玛就孤孤单单地住在她胞兄押沙龙家里。"},
{volumn:        10,chapter:        13,versenumber:        21,verse:"大卫王听见这事，就甚发怒。"},
{volumn:        10,chapter:        13,versenumber:        22,verse:"押沙龙并不和他哥哥暗嫩说好说歹；因为暗嫩玷辱他妹妹他玛，所以押沙龙恨恶他。"},
{volumn:        10,chapter:        13,versenumber:        23,verse:"过了二年，在靠近以法莲的巴力·夏琐有人为押沙龙剪羊毛；押沙龙请王的众子与他同去。"},
{volumn:        10,chapter:        13,versenumber:        24,verse:"押沙龙来见王，说：“现在有人为仆人剪羊毛，请王和王的臣仆与仆人同去。”"},
{volumn:        10,chapter:        13,versenumber:        25,verse:"王对押沙龙说：“我儿，我们不必都去，恐怕使你耗费太多。”押沙龙再三请王，王仍是不肯去，只为他祝福。"},
{volumn:        10,chapter:        13,versenumber:        26,verse:"押沙龙说：“王若不去，求王许我哥哥暗嫩同去。”王说：“何必要他去呢？”"},
{volumn:        10,chapter:        13,versenumber:        27,verse:"押沙龙再三求王，王就许暗嫩和王的众子与他同去。"},
{volumn:        10,chapter:        13,versenumber:        28,verse:"押沙龙吩咐仆人说：“你们注意，看暗嫩饮酒畅快的时候，我对你们说杀暗嫩，你们便杀他，不要惧怕。这不是我吩咐你们的吗？你们只管壮胆奋勇！”"},
{volumn:        10,chapter:        13,versenumber:        29,verse:"押沙龙的仆人就照押沙龙所吩咐的，向暗嫩行了。王的众子都起来，各人骑上骡子，逃跑了。"},
{volumn:        10,chapter:        13,versenumber:        30,verse:"他们还在路上，有风声传到大卫那里，说：“押沙龙将王的众子都杀了，没有留下一个。”"},
{volumn:        10,chapter:        13,versenumber:        31,verse:"王就起来，撕裂衣服，躺在地上。王的臣仆也都撕裂衣服，站在旁边。"},
{volumn:        10,chapter:        13,versenumber:        32,verse:"大卫的长兄，示米亚的儿子约拿达说：“我主，不要以为王的众子少年人都杀了，只有暗嫩一个人死了。自从暗嫩玷辱押沙龙妹子他玛的那日，押沙龙就定意杀暗嫩了。"},
{volumn:        10,chapter:        13,versenumber:        33,verse:"现在，我主我王，不要把这事放在心上，以为王的众子都死了，只有暗嫩一个人死了。”"},
{volumn:        10,chapter:        13,versenumber:        34,verse:"押沙龙逃跑了。守望的少年人举目观看，见有许多人从山坡的路上来。"},
{volumn:        10,chapter:        13,versenumber:        35,verse:"约拿达对王说：“看哪，王的众子都来了，果然与你仆人所说的相合。”"},
{volumn:        10,chapter:        13,versenumber:        36,verse:"话才说完，王的众子都到了，放声大哭；王和臣仆也都哭得甚恸。"},
{volumn:        10,chapter:        13,versenumber:        37,verse:"押沙龙逃到基述王亚米忽的儿子达买那里去了。大卫天天为他儿子悲哀。"},
{volumn:        10,chapter:        13,versenumber:        38,verse:"押沙龙逃到基述，在那里住了三年。"},
{volumn:        10,chapter:        13,versenumber:        39,verse:"暗嫩死了以后，大卫王得了安慰，心里切切想念押沙龙。"},
{volumn:        10,chapter:        14,versenumber:         1,verse:"洗鲁雅的儿子约押，知道王心里想念押沙龙，"},
{volumn:        10,chapter:        14,versenumber:         2,verse:"就打发人往提哥亚去，从那里叫了一个聪明的妇人来，对她说：“请你假装居丧的，穿上孝衣，不要用膏抹身，要装作为死者许久悲哀的妇人；"},
{volumn:        10,chapter:        14,versenumber:         3,verse:"进去见王，对王如此如此说。”于是约押将当说的话教导了妇人。"},
{volumn:        10,chapter:        14,versenumber:         4,verse:"提哥亚妇人到王面前，伏地叩拜，说：“王啊，求你拯救！”"},
{volumn:        10,chapter:        14,versenumber:         5,verse:"王问她说：“你有什么事呢？”回答说：“婢女实在是寡妇，我丈夫死了。"},
{volumn:        10,chapter:        14,versenumber:         6,verse:"我有两个儿子，一日在田间争斗，没有人解劝，这个就打死那个。"},
{volumn:        10,chapter:        14,versenumber:         7,verse:"现在全家的人都起来攻击婢女，说：‘你将那打死兄弟的交出来，我们好治死他，偿他打死兄弟的命，灭绝那承受家业的。’这样，他们要将我剩下的炭火灭尽，不与我丈夫留名留后在世上。”"},
{volumn:        10,chapter:        14,versenumber:         8,verse:"王对妇人说：“你回家去吧！我必为你下令。”"},
{volumn:        10,chapter:        14,versenumber:         9,verse:"提哥亚妇人又对王说：“我主我王，愿这罪归我和我父家，与王和王的位无干。”"},
{volumn:        10,chapter:        14,versenumber:        10,verse:"王说：“凡难为你的，你就带他到我这里来，他必不再搅扰你。”"},
{volumn:        10,chapter:        14,versenumber:        11,verse:"妇人说：“愿王记念耶和华你的　神，不许报血仇的人施行灭绝，恐怕他们灭绝我的儿子。”王说：“我指着永生的耶和华起誓：你的儿子连一根头发也不致落在地上。”"},
{volumn:        10,chapter:        14,versenumber:        12,verse:"妇人说：“求我主我王容婢女再说一句话。”王说：“你说吧！”"},
{volumn:        10,chapter:        14,versenumber:        13,verse:"妇人说：“王为何也起意要害　神的民呢？王不使那逃亡的人回来，王的这话就是自证己错了！"},
{volumn:        10,chapter:        14,versenumber:        14,verse:"我们都是必死的，如同水泼在地上，不能收回。　神并不夺取人的性命，乃设法使逃亡的人不致成为赶出、回不来的。"},
{volumn:        10,chapter:        14,versenumber:        15,verse:"我来将这话告诉我主我王，是因百姓使我惧怕。婢女想，不如将这话告诉王，或者王成就婢女所求的。"},
{volumn:        10,chapter:        14,versenumber:        16,verse:"人要将我和我儿子从　神的地业上一同除灭，王必应允救我脱离他的手。"},
{volumn:        10,chapter:        14,versenumber:        17,verse:"婢女又想，我主我王的话必安慰我；因为我主我王能辨别是非，如同　神的使者一样。惟愿耶和华你的　神与你同在！”"},
{volumn:        10,chapter:        14,versenumber:        18,verse:"王对妇人说：“我要问你一句话，你一点不要瞒我。”妇人说：“愿我主我王说。”"},
{volumn:        10,chapter:        14,versenumber:        19,verse:"王说：“你这些话莫非是约押的主意吗？”妇人说：“我敢在我主我王面前起誓：王的话正对，不偏左右，是王的仆人约押吩咐我的，这些话是他教导我的。"},
{volumn:        10,chapter:        14,versenumber:        20,verse:"王的仆人约押如此行，为要挽回这事。我主的智慧却如　神使者的智慧，能知世上一切事。”"},
{volumn:        10,chapter:        14,versenumber:        21,verse:"王对约押说：“我应允你这事。你可以去，把那少年人押沙龙带回来。”"},
{volumn:        10,chapter:        14,versenumber:        22,verse:"约押就面伏于地叩拜，祝谢于王，又说：“王既应允仆人所求的，仆人今日知道在我主我王眼前蒙恩了。”"},
{volumn:        10,chapter:        14,versenumber:        23,verse:"于是约押起身往基述去，将押沙龙带回耶路撒冷。"},
{volumn:        10,chapter:        14,versenumber:        24,verse:"王说：“使他回自己家里去，不要见我的面。”押沙龙就回自己家里去，没有见王的面。"},
{volumn:        10,chapter:        14,versenumber:        25,verse:"以色列全地之中，无人像押沙龙那样俊美，得人的称赞，从脚底到头顶毫无瑕疵。"},
{volumn:        10,chapter:        14,versenumber:        26,verse:"他的头发甚重，每到年底剪发一次；所剪下来的，按王的平称一称，重二百舍客勒。"},
{volumn:        10,chapter:        14,versenumber:        27,verse:"押沙龙生了三个儿子，一个女儿。女儿名叫他玛，是个容貌俊美的女子。"},
{volumn:        10,chapter:        14,versenumber:        28,verse:"押沙龙住在耶路撒冷足有二年，没有见王的面。"},
{volumn:        10,chapter:        14,versenumber:        29,verse:"押沙龙打发人去叫约押来，要托他去见王，约押却不肯来。第二次打发人去叫他，他仍不肯来。"},
{volumn:        10,chapter:        14,versenumber:        30,verse:"所以押沙龙对仆人说：“你们看，约押有一块田，与我的田相近，其中有大麦，你们去放火烧了。”押沙龙的仆人就去放火烧了那田。"},
{volumn:        10,chapter:        14,versenumber:        31,verse:"于是约押起来，到了押沙龙家里，问他说：“你的仆人为何放火烧了我的田呢？”"},
{volumn:        10,chapter:        14,versenumber:        32,verse:"押沙龙回答约押说：“我打发人去请你来，好托你去见王，替我说：‘我为何从基述回来呢？不如仍在那里。’现在要许我见王的面；我若有罪，任凭王杀我就是了。”"},
{volumn:        10,chapter:        14,versenumber:        33,verse:"于是约押去见王，将这话奏告王，王便叫押沙龙来。押沙龙来见王，在王面前俯伏于地，王就与押沙龙亲嘴。"},
{volumn:        10,chapter:        15,versenumber:         1,verse:"此后，押沙龙为自己预备车马，又派五十人在他前头奔走。"},
{volumn:        10,chapter:        15,versenumber:         2,verse:"押沙龙常常早晨起来，站在城门的道旁，凡有争讼要去求王判断的，押沙龙就叫他过来，问他说：“你是哪一城的人？”回答说：“仆人是以色列某支派的人。”"},
{volumn:        10,chapter:        15,versenumber:         3,verse:"押沙龙对他说：“你的事有情有理，无奈王没有委人听你伸诉。”"},
{volumn:        10,chapter:        15,versenumber:         4,verse:"押沙龙又说：“恨不得我作国中的士师！凡有争讼求审判的到我这里来，我必秉公判断。”"},
{volumn:        10,chapter:        15,versenumber:         5,verse:"若有人近前来要拜押沙龙，押沙龙就伸手拉住他，与他亲嘴。"},
{volumn:        10,chapter:        15,versenumber:         6,verse:"以色列人中，凡去见王求判断的，押沙龙都是如此待他们。这样，押沙龙暗中得了以色列人的心。"},
{volumn:        10,chapter:        15,versenumber:         7,verse:"满了四十年（有作四年的），押沙龙对王说：“求你准我往希伯仑去，还我向耶和华所许的愿。"},
{volumn:        10,chapter:        15,versenumber:         8,verse:"因为仆人住在亚兰的基述，曾许愿说：‘耶和华若使我再回耶路撒冷，我必侍奉他。’”"},
{volumn:        10,chapter:        15,versenumber:         9,verse:"王说：“你平平安安地去吧！”押沙龙就起身，往希伯仑去了。"},
{volumn:        10,chapter:        15,versenumber:        10,verse:"押沙龙打发探子走遍以色列各支派，说：“你们一听见角声就说：‘押沙龙在希伯仑作王了！’”"},
{volumn:        10,chapter:        15,versenumber:        11,verse:"押沙龙在耶路撒冷请了二百人与他同去，都是诚诚实实去的，并不知道其中的真情。"},
{volumn:        10,chapter:        15,versenumber:        12,verse:"押沙龙献祭的时候，打发人去将大卫的谋士、基罗人亚希多弗从他本城请了来。于是叛逆的势派甚大；因为随从押沙龙的人民，日渐增多。"},
{volumn:        10,chapter:        15,versenumber:        13,verse:"有人报告大卫说：“以色列人的心都归向押沙龙了！”"},
{volumn:        10,chapter:        15,versenumber:        14,verse:"大卫就对耶路撒冷跟随他的臣仆说：“我们要起来逃走，不然都不能躲避押沙龙了；要速速地去，恐怕他忽然来到，加害于我们，用刀杀尽合城的人。”"},
{volumn:        10,chapter:        15,versenumber:        15,verse:"王的臣仆对王说：“我主我王所定的，仆人都愿遵行。”"},
{volumn:        10,chapter:        15,versenumber:        16,verse:"于是王带着全家的人出去了，但留下十个妃嫔看守宫殿。"},
{volumn:        10,chapter:        15,versenumber:        17,verse:"王出去，众民都跟随他，到伯·墨哈，就住下了。"},
{volumn:        10,chapter:        15,versenumber:        18,verse:"王的臣仆都在他面前过去。基利提人、比利提人，就是从迦特跟随王来的六百人，也都在他面前过去。"},
{volumn:        10,chapter:        15,versenumber:        19,verse:"王对迦特人以太说：“你是外邦逃来的人，为什么与我们同去呢？你可以回去与新王同住，或者回你本地去吧！"},
{volumn:        10,chapter:        15,versenumber:        20,verse:"你来的日子不多，我今日怎好叫你与我们一同飘流、没有一定的住处呢？你不如带你的弟兄回去吧！愿耶和华用慈爱诚实待你。”"},
{volumn:        10,chapter:        15,versenumber:        21,verse:"以太对王说：“我指着永生的耶和华起誓，又敢在王面前起誓：无论生死，王在哪里，仆人也必在那里。”"},
{volumn:        10,chapter:        15,versenumber:        22,verse:"大卫对以太说：“你前去过河吧！”于是迦特人以太带着跟随他的人和所有的妇人孩子，就都过去了。"},
{volumn:        10,chapter:        15,versenumber:        23,verse:"本地的人都放声大哭。众民尽都过去，王也过了汲沦溪；众民往旷野去了。"},
{volumn:        10,chapter:        15,versenumber:        24,verse:"撒督和抬　神约柜的利未人也一同来了，将　神的约柜放下。亚比亚他上来，等着众民从城里出来过去。"},
{volumn:        10,chapter:        15,versenumber:        25,verse:"王对撒督说：“你将　神的约柜抬回城去。我若在耶和华眼前蒙恩，他必使我回来，再见约柜和他的居所。"},
{volumn:        10,chapter:        15,versenumber:        26,verse:"倘若他说：‘我不喜悦你’，看哪，我在这里，愿他凭自己的意旨待我！”"},
{volumn:        10,chapter:        15,versenumber:        27,verse:"王又对祭司撒督说：“你不是先见吗？你可以安然回城；你儿子亚希玛斯和亚比亚他的儿子约拿单都可以与你同去。"},
{volumn:        10,chapter:        15,versenumber:        28,verse:"我在旷野的渡口那里等你们报信给我。”"},
{volumn:        10,chapter:        15,versenumber:        29,verse:"于是撒督和亚比亚他将　神的约柜抬回耶路撒冷，他们就住在那里。"},
{volumn:        10,chapter:        15,versenumber:        30,verse:"大卫蒙头赤脚上橄榄山，一面上一面哭。跟随他的人也都蒙头哭着上去；"},
{volumn:        10,chapter:        15,versenumber:        31,verse:"有人告诉大卫说：“亚希多弗也在叛党之中，随从押沙龙。”大卫祷告说：“耶和华啊，求你使亚希多弗的计谋变为愚拙！”"},
{volumn:        10,chapter:        15,versenumber:        32,verse:"大卫到了山顶、敬拜　神的地方，见亚基人户筛，衣服撕裂，头蒙灰尘来迎接他。"},
{volumn:        10,chapter:        15,versenumber:        33,verse:"大卫对他说：“你若与我同去，必累赘我；"},
{volumn:        10,chapter:        15,versenumber:        34,verse:"你若回城去，对押沙龙说：‘王啊，我愿作你的仆人；我向来作你父亲的仆人，现在我也照样作你的仆人。’这样，你就可以为我破坏亚希多弗的计谋。"},
{volumn:        10,chapter:        15,versenumber:        35,verse:"祭司撒督和亚比亚他岂不都在那里吗？你在王宫里听见什么，就要告诉祭司撒督和亚比亚他。"},
{volumn:        10,chapter:        15,versenumber:        36,verse:"撒督的儿子亚希玛斯，亚比亚他的儿子约拿单，也都在那里。凡你们所听见的可以托这二人来报告我。”"},
{volumn:        10,chapter:        15,versenumber:        37,verse:"于是，大卫的朋友户筛进了城；押沙龙也进了耶路撒冷。"},
{volumn:        10,chapter:        16,versenumber:         1,verse:"大卫刚过山顶，见米非波设的仆人洗巴拉着备好了的两匹驴，驴上驮着二百面饼，一百葡萄饼，一百个夏天的果饼，一皮袋酒来迎接他。"},
{volumn:        10,chapter:        16,versenumber:         2,verse:"王问洗巴说：“你带这些来是什么意思呢？”洗巴说：“驴是给王的家眷骑的；面饼和夏天的果饼是给少年人吃的；酒是给在旷野疲乏人喝的。”"},
{volumn:        10,chapter:        16,versenumber:         3,verse:"王问说：“你主人的儿子在哪里呢？”洗巴回答王说：“他仍在耶路撒冷，因他说：‘以色列人今日必将我父的国归还我。’”"},
{volumn:        10,chapter:        16,versenumber:         4,verse:"王对洗巴说：“凡属米非波设的都归你了。”洗巴说：“我叩拜我主我王，愿我在你眼前蒙恩。”"},
{volumn:        10,chapter:        16,versenumber:         5,verse:"大卫王到了巴户琳，见有一个人出来，是扫罗族基拉的儿子，名叫示每。他一面走一面咒骂，"},
{volumn:        10,chapter:        16,versenumber:         6,verse:"又拿石头砍大卫王和王的臣仆；众民和勇士都在王的左右。"},
{volumn:        10,chapter:        16,versenumber:         7,verse:"示每咒骂说：“你这流人血的坏人哪，去吧去吧！"},
{volumn:        10,chapter:        16,versenumber:         8,verse:"你流扫罗全家的血，接续他作王；耶和华把这罪归在你身上，将这国交给你儿子押沙龙。现在你自取其祸，因为你是流人血的人。”"},
{volumn:        10,chapter:        16,versenumber:         9,verse:"洗鲁雅的儿子亚比筛对王说：“这死狗岂可咒骂我主我王呢？求你容我过去，割下他的头来。”"},
{volumn:        10,chapter:        16,versenumber:        10,verse:"王说：“洗鲁雅的儿子，我与你们有何关涉呢？他咒骂是因耶和华吩咐他说：‘你要咒骂大卫。’如此，谁敢说你为什么这样行呢？”"},
{volumn:        10,chapter:        16,versenumber:        11,verse:"大卫又对亚比筛和众臣仆说：“我亲生的儿子尚且寻索我的性命，何况这便雅悯人呢？由他咒骂吧！因为这是耶和华吩咐他的。"},
{volumn:        10,chapter:        16,versenumber:        12,verse:"或者耶和华见我遭难，为我今日被这人咒骂，就施恩与我。”"},
{volumn:        10,chapter:        16,versenumber:        13,verse:"于是大卫和跟随他的人往前行走。示每在大卫对面山坡，一面行走一面咒骂，又拿石头砍他，拿土扬他。"},
{volumn:        10,chapter:        16,versenumber:        14,verse:"王和跟随他的众人疲疲乏乏地到了一个地方，就在那里歇息歇息。"},
{volumn:        10,chapter:        16,versenumber:        15,verse:"押沙龙和以色列众人来到耶路撒冷；亚希多弗也与他同来。"},
{volumn:        10,chapter:        16,versenumber:        16,verse:"大卫的朋友亚基人户筛去见押沙龙，对他说：“愿王万岁！愿王万岁！”"},
{volumn:        10,chapter:        16,versenumber:        17,verse:"押沙龙问户筛说：“这是你恩待朋友吗？为什么不与你的朋友同去呢？”"},
{volumn:        10,chapter:        16,versenumber:        18,verse:"户筛对押沙龙说：“不然，耶和华和这民，并以色列众人所拣选的，我必归顺他，与他同住。"},
{volumn:        10,chapter:        16,versenumber:        19,verse:"再者，我当服侍谁呢？岂不是前王的儿子吗？我怎样服侍你父亲，也必照样服侍你。”"},
{volumn:        10,chapter:        16,versenumber:        20,verse:"押沙龙对亚希多弗说：“你们出个主意，我们怎样行才好？”"},
{volumn:        10,chapter:        16,versenumber:        21,verse:"亚希多弗对押沙龙说：“你父所留下看守宫殿的妃嫔，你可以与她们亲近。以色列众人听见你父亲憎恶你，凡归顺你人的手就更坚强。”"},
{volumn:        10,chapter:        16,versenumber:        22,verse:"于是人为押沙龙在宫殿的平顶上支搭帐棚；押沙龙在以色列众人眼前，与他父的妃嫔亲近。"},
{volumn:        10,chapter:        16,versenumber:        23,verse:"那时亚希多弗所出的主意好像人问　神的话一样；他昔日给大卫，今日给押沙龙所出的主意，都是这样。"},
{volumn:        10,chapter:        17,versenumber:         1,verse:"亚希多弗又对押沙龙说：“求你准我挑选一万二千人，今夜我就起身追赶大卫，"},
{volumn:        10,chapter:        17,versenumber:         2,verse:"趁他疲乏手软，我忽然追上他，使他惊惶；跟随他的民必都逃跑，我就单杀王一人，"},
{volumn:        10,chapter:        17,versenumber:         3,verse:"使众民都归顺你。你所寻找的人既然死了，众民就如已经归顺你；这样，也都平安无事了。”"},
{volumn:        10,chapter:        17,versenumber:         4,verse:"押沙龙和以色列的长老都以这话为美。"},
{volumn:        10,chapter:        17,versenumber:         5,verse:"押沙龙说：“要召亚基人户筛来，我们也要听他怎样说。”"},
{volumn:        10,chapter:        17,versenumber:         6,verse:"户筛到了押沙龙面前，押沙龙向他说：“亚希多弗是如此如此说的，我们照着他的话行可以不可以？若不可，你就说吧！”"},
{volumn:        10,chapter:        17,versenumber:         7,verse:"户筛对押沙龙说：“亚希多弗这次所定的谋不善。”"},
{volumn:        10,chapter:        17,versenumber:         8,verse:"户筛又说：“你知道，你父亲和跟随他的人都是勇士，现在他们心里恼怒，如同田野丢崽子的母熊一般，而且你父亲是个战士，必不和民一同住宿。"},
{volumn:        10,chapter:        17,versenumber:         9,verse:"他现今或藏在坑中或在别处，若有人首先被杀，凡听见的必说：‘跟随押沙龙的民被杀了。’"},
{volumn:        10,chapter:        17,versenumber:        10,verse:"虽有人胆大如狮子，他的心也必消化；因为以色列人都知道你父亲是英雄，跟随他的人也都是勇士。"},
{volumn:        10,chapter:        17,versenumber:        11,verse:"依我之计，不如将以色列众人，从但直到别是巴，如同海边的沙那样多，聚集到你这里来，你也亲自率领他们出战。"},
{volumn:        10,chapter:        17,versenumber:        12,verse:"这样，我们在何处遇见他，就下到他那里，如同露水下在地上一般，连他带跟随他的人，一个也不留下。"},
{volumn:        10,chapter:        17,versenumber:        13,verse:"他若进了哪一座城，以色列众人必带绳子去，将那城拉到河里，甚至连一块小石头都不剩下。”"},
{volumn:        10,chapter:        17,versenumber:        14,verse:"押沙龙和以色列众人说：“亚基人户筛的计谋比亚希多弗的计谋更好！”这是因耶和华定意破坏亚希多弗的良谋，为要降祸与押沙龙。"},
{volumn:        10,chapter:        17,versenumber:        15,verse:"户筛对祭司撒督和亚比亚他说：“亚希多弗为押沙龙和以色列的长老所定的计谋是如此如此，我所定的计谋是如此如此。"},
{volumn:        10,chapter:        17,versenumber:        16,verse:"现在你们要急速打发人去，告诉大卫说：‘今夜不可住在旷野的渡口，务要过河，免得王和跟随他的人都被吞灭。’”"},
{volumn:        10,chapter:        17,versenumber:        17,verse:"那时，约拿单和亚希玛斯在隐·罗结那里等候，不敢进城，恐怕被人看见。有一个使女出来，将这话告诉他们，他们就去报信给大卫王。"},
{volumn:        10,chapter:        17,versenumber:        18,verse:"然而有一个童子看见他们，就去告诉押沙龙。他们急忙跑到巴户琳某人的家里；那人院中有一口井，他们就下到井里。"},
{volumn:        10,chapter:        17,versenumber:        19,verse:"那家的妇人用盖盖上井口，又在上头铺上碎麦，事就没有泄漏。"},
{volumn:        10,chapter:        17,versenumber:        20,verse:"押沙龙的仆人来到那家，问妇人说：“亚希玛斯和约拿单在哪里？”妇人说：“他们过了河了。”仆人找他们，找不着，就回耶路撒冷去了。"},
{volumn:        10,chapter:        17,versenumber:        21,verse:"他们走后，二人从井里上来，去告诉大卫王说：“亚希多弗如此如此定计害你，你们务要起来，快快过河。”"},
{volumn:        10,chapter:        17,versenumber:        22,verse:"于是大卫和跟随他的人都起来，过约旦河。到了天亮，无一人不过约旦河的。"},
{volumn:        10,chapter:        17,versenumber:        23,verse:"亚希多弗见不依从他的计谋，就备上驴，归回本城；到了家，留下遗言，便吊死了，葬在他父亲的坟墓里。"},
{volumn:        10,chapter:        17,versenumber:        24,verse:"大卫到了玛哈念，押沙龙和跟随他的以色列人也都过了约旦河。"},
{volumn:        10,chapter:        17,versenumber:        25,verse:"押沙龙立亚玛撒作元帅，代替约押。亚玛撒是以实玛利人（又作以色列人）以特拉的儿子。以特拉曾与拿辖的女儿亚比该亲近；这亚比该与约押的母亲洗鲁雅是姐妹。"},
{volumn:        10,chapter:        17,versenumber:        26,verse:"押沙龙和以色列人都安营在基列地。"},
{volumn:        10,chapter:        17,versenumber:        27,verse:"大卫到了玛哈念，亚扪族的拉巴人拿辖的儿子朔比，罗·底巴人亚米利的儿子玛吉，基列的罗基琳人巴西莱，"},
{volumn:        10,chapter:        17,versenumber:        28,verse:"带着被、褥、盆、碗、瓦器、小麦、大麦、麦面、炒谷、豆子、红豆、炒豆、"},
{volumn:        10,chapter:        17,versenumber:        29,verse:"蜂蜜、奶油、绵羊、奶饼，供给大卫和跟随他的人吃；他们说：“民在旷野，必饥渴困乏了。”"},
{volumn:        10,chapter:        18,versenumber:         1,verse:"大卫数点跟随他的人，立千夫长、百夫长率领他们。"},
{volumn:        10,chapter:        18,versenumber:         2,verse:"大卫打发军兵出战，分为三队：一队在约押手下，一队在洗鲁雅的儿子、约押兄弟亚比筛手下，一队在迦特人以太手下。大卫对军兵说：“我必与你们一同出战。”"},
{volumn:        10,chapter:        18,versenumber:         3,verse:"军兵却说：“你不可出战。若是我们逃跑，敌人必不介意；我们阵亡一半，敌人也不介意。因为你一人强似我们万人，你不如在城里预备帮助我们。”"},
{volumn:        10,chapter:        18,versenumber:         4,verse:"王向他们说：“你们以为怎样好，我就怎样行。”于是王站在城门旁，军兵或百或千地挨次出去了。"},
{volumn:        10,chapter:        18,versenumber:         5,verse:"王嘱咐约押、亚比筛、以太说：“你们要为我的缘故宽待那少年人押沙龙。”王为押沙龙嘱咐众将的话，兵都听见了。"},
{volumn:        10,chapter:        18,versenumber:         6,verse:"兵就出到田野迎着以色列人，在以法莲树林里交战。"},
{volumn:        10,chapter:        18,versenumber:         7,verse:"以色列人败在大卫的仆人面前；那日阵亡的甚多，共有二万人。"},
{volumn:        10,chapter:        18,versenumber:         8,verse:"因为在那里四面打仗，死于树林的比死于刀剑的更多。"},
{volumn:        10,chapter:        18,versenumber:         9,verse:"押沙龙偶然遇见大卫的仆人。押沙龙骑着骡子，从大橡树密枝底下经过，他的头发被树枝绕住，就悬挂起来，所骑的骡子便离他去了。"},
{volumn:        10,chapter:        18,versenumber:        10,verse:"有个人看见，就告诉约押说：“我看见押沙龙挂在橡树上了。”"},
{volumn:        10,chapter:        18,versenumber:        11,verse:"约押对报信的人说：“你既看见他，为什么不将他打死落在地上呢？你若打死他，我就赏你十舍客勒银子，一条带子。”"},
{volumn:        10,chapter:        18,versenumber:        12,verse:"那人对约押说：“我就是得你一千舍客勒银子，我也不敢伸手害王的儿子；因为我们听见王嘱咐你和亚比筛并以太说：‘你们要谨慎，不可害那少年人押沙龙。’"},
{volumn:        10,chapter:        18,versenumber:        13,verse:"我若妄为害了他的性命，就是你自己也必与我为敌（原来，无论何事都瞒不过王。）”"},
{volumn:        10,chapter:        18,versenumber:        14,verse:"约押说：“我不能与你留连。”约押手拿三杆短枪，趁押沙龙在橡树上还活着，就刺透他的心。"},
{volumn:        10,chapter:        18,versenumber:        15,verse:"给约押拿兵器的十个少年人围绕押沙龙，将他杀死。"},
{volumn:        10,chapter:        18,versenumber:        16,verse:"约押吹角，拦阻众人，他们就回来，不再追赶以色列人。"},
{volumn:        10,chapter:        18,versenumber:        17,verse:"他们将押沙龙丢在林中一个大坑里，上头堆起一大堆石头。以色列众人都逃跑，各回各家去了。"},
{volumn:        10,chapter:        18,versenumber:        18,verse:"押沙龙活着的时候，在王谷立了一根石柱，因他说：“我没有儿子为我留名。”他就以自己的名称那石柱叫押沙龙柱，直到今日。"},
{volumn:        10,chapter:        18,versenumber:        19,verse:"撒督的儿子亚希玛斯说：“容我跑去，将耶和华向仇敌给王报仇的信息报与王知。”"},
{volumn:        10,chapter:        18,versenumber:        20,verse:"约押对他说：“你今日不可去报信，改日可以报信；因为今日王的儿子死了，所以你不可去报信。”"},
{volumn:        10,chapter:        18,versenumber:        21,verse:"约押对古示人说：“你去将你所看见的告诉王。”古示人在约押面前下拜，就跑去了。"},
{volumn:        10,chapter:        18,versenumber:        22,verse:"撒督的儿子亚希玛斯又对约押说：“无论怎样，求你容我随着古示人跑去。”约押说：“我儿，你报这信息，既不得赏赐，何必要跑去呢？”"},
{volumn:        10,chapter:        18,versenumber:        23,verse:"他又说：“无论怎样，我要跑去。”约押说：“你跑去吧！”亚希玛斯就从平原往前跑，跑过古示人去了。"},
{volumn:        10,chapter:        18,versenumber:        24,verse:"大卫正坐在城瓮里。守望的人上城门楼的顶上，举目观看，见有一个人独自跑来。"},
{volumn:        10,chapter:        18,versenumber:        25,verse:"守望的人就大声告诉王。王说：“他若独自来，必是报口信的。”那人跑得渐渐近了。"},
{volumn:        10,chapter:        18,versenumber:        26,verse:"守望的人又见一人跑来，就对守城门的人说：“又有一人独自跑来。”王说：“这也必是报信的。”"},
{volumn:        10,chapter:        18,versenumber:        27,verse:"守望的人说：“我看前头人的跑法，好像撒督的儿子亚希玛斯的跑法一样。”王说：“他是个好人，必是报好信息。”"},
{volumn:        10,chapter:        18,versenumber:        28,verse:"亚希玛斯向王呼叫说：“平安了！”就在王面前脸伏于地叩拜，说：“耶和华你的　神是应当称颂的，因他已将那举手攻击我主我王的人交给王了。”"},
{volumn:        10,chapter:        18,versenumber:        29,verse:"王问说：“少年人押沙龙平安不平安？”亚希玛斯回答说：“约押打发王的仆人，那时仆人听见众民大声喧哗，却不知道是什么事。”"},
{volumn:        10,chapter:        18,versenumber:        30,verse:"王说：“你退去，站在旁边。”他就退去，站在旁边。"},
{volumn:        10,chapter:        18,versenumber:        31,verse:"古示人也来到，说：“有信息报给我主我王！耶和华今日向一切兴起攻击你的人给你报仇了。”"},
{volumn:        10,chapter:        18,versenumber:        32,verse:"王问古示人说：“少年人押沙龙平安不平安？”古示人回答说：“愿我主我王的仇敌，和一切兴起要杀害你的人，都与那少年人一样。”"},
{volumn:        10,chapter:        18,versenumber:        33,verse:"王就心里伤恸，上城门楼去哀哭，一面走一面说：“我儿押沙龙啊！我儿，我儿押沙龙啊！我恨不得替你死，押沙龙啊，我儿！我儿！”"},
{volumn:        10,chapter:        19,versenumber:         1,verse:"有人告诉约押说：“王为押沙龙哭泣悲哀。”"},
{volumn:        10,chapter:        19,versenumber:         2,verse:"众民听说王为他儿子忧愁，他们得胜的欢乐却变成悲哀。"},
{volumn:        10,chapter:        19,versenumber:         3,verse:"那日众民暗暗地进城，就如败阵逃跑、惭愧的民一般。"},
{volumn:        10,chapter:        19,versenumber:         4,verse:"王蒙着脸，大声哭号说：“我儿押沙龙啊！押沙龙，我儿，我儿啊！”"},
{volumn:        10,chapter:        19,versenumber:         5,verse:"约押进去见王，说：“你今日使你一切仆人脸面惭愧了！他们今日救了你的性命和你儿女妻妾的性命，"},
{volumn:        10,chapter:        19,versenumber:         6,verse:"你却爱那恨你的人，恨那爱你的人。你今日明明地不以将帅、仆人为念。我今日看明，若押沙龙活着，我们都死亡，你就喜悦了。"},
{volumn:        10,chapter:        19,versenumber:         7,verse:"现在你当出去，安慰你仆人的心。我指着耶和华起誓：你若不出去，今夜必无一人与你同在一处；这祸患就比你从幼年到如今所遭的更甚！”"},
{volumn:        10,chapter:        19,versenumber:         8,verse:"于是王起来，坐在城门口。众民听说王坐在城门口，就都到王面前。以色列人已经逃跑，各回各家去了。"},
{volumn:        10,chapter:        19,versenumber:         9,verse:"以色列众支派的人纷纷议论说：“王曾救我们脱离仇敌的手，又救我们脱离非利士人的手，现在他躲避押沙龙逃走了。"},
{volumn:        10,chapter:        19,versenumber:        10,verse:"我们膏押沙龙治理我们，他已经阵亡。现在为什么不出一言请王回来呢？”"},
{volumn:        10,chapter:        19,versenumber:        11,verse:"大卫王差人去见祭司撒督和亚比亚他，说：“你们当向犹大长老说：‘以色列众人已经有话请王回宫，你们为什么落在他们后头呢？"},
{volumn:        10,chapter:        19,versenumber:        12,verse:"你们是我的弟兄，是我的骨肉，为什么在人后头请王回来呢？’"},
{volumn:        10,chapter:        19,versenumber:        13,verse:"也要对亚玛撒说：‘你不是我的骨肉吗？我若不立你替约押常作元帅，愿　神重重地降罚与我！’”"},
{volumn:        10,chapter:        19,versenumber:        14,verse:"如此就挽回犹大众人的心，如同一人的心。他们便打发人去见王，说：“请王和王的一切臣仆回来。”"},
{volumn:        10,chapter:        19,versenumber:        15,verse:"王就回来，到了约旦河。犹大人来到吉甲，要去迎接王，请他过约旦河。"},
{volumn:        10,chapter:        19,versenumber:        16,verse:"巴户琳的便雅悯人、基拉的儿子示每急忙与犹大人一同下去迎接大卫王。"},
{volumn:        10,chapter:        19,versenumber:        17,verse:"跟从示每的有一千便雅悯人，还有扫罗家的仆人洗巴和他十五个儿子，二十个仆人；他们都趟过约旦河迎接王。"},
{volumn:        10,chapter:        19,versenumber:        18,verse:"有摆渡船过去，渡王的家眷，任王使用。王要过约旦河的时候，基拉的儿子示每就俯伏在王面前，"},
{volumn:        10,chapter:        19,versenumber:        19,verse:"对王说：“我主我王出耶路撒冷的时候，仆人行悖逆的事，现在求我主不要因此加罪与仆人，不要记念，也不要放在心上。"},
{volumn:        10,chapter:        19,versenumber:        20,verse:"仆人明知自己有罪，所以约瑟全家之中，今日我首先下来迎接我主我王。”"},
{volumn:        10,chapter:        19,versenumber:        21,verse:"洗鲁雅的儿子亚比筛说：“示每既咒骂耶和华的受膏者，不应当治死他吗？”"},
{volumn:        10,chapter:        19,versenumber:        22,verse:"大卫说：“洗鲁雅的儿子，我与你们有何关涉，使你们今日与我反对呢？今日在以色列中岂可治死人呢？我岂不知今日我作以色列的王吗？”"},
{volumn:        10,chapter:        19,versenumber:        23,verse:"于是王对示每说：“你必不死。”王就向他起誓。"},
{volumn:        10,chapter:        19,versenumber:        24,verse:"扫罗的孙子米非波设也下去迎接王。他自从王去的日子，直到王平平安安地回来，没有修脚，没有剃胡须，也没有洗衣服。"},
{volumn:        10,chapter:        19,versenumber:        25,verse:"他来到耶路撒冷迎接王的时候，王问他说：“米非波设，你为什么没有与我同去呢？”"},
{volumn:        10,chapter:        19,versenumber:        26,verse:"他回答说：“我主我王，仆人是瘸腿的。那日我想要备驴骑上，与王同去，无奈我的仆人欺哄了我，"},
{volumn:        10,chapter:        19,versenumber:        27,verse:"又在我主我王面前谗毁我。然而我主我王如同　神的使者一般，你看怎样好，就怎样行吧！"},
{volumn:        10,chapter:        19,versenumber:        28,verse:"因为我祖全家的人，在我主我王面前都算为死人，王却使仆人在王的席上同人吃饭，我现在向王还能辨理诉冤吗？”"},
{volumn:        10,chapter:        19,versenumber:        29,verse:"王对他说：“你何必再提你的事呢？我说，你与洗巴均分地土。”"},
{volumn:        10,chapter:        19,versenumber:        30,verse:"米非波设对王说：“我主我王既平平安安地回宫，就任凭洗巴都取了也可以。”"},
{volumn:        10,chapter:        19,versenumber:        31,verse:"基列人巴西莱从罗基琳下来，要送王过约旦河，就与王一同过了约旦河。"},
{volumn:        10,chapter:        19,versenumber:        32,verse:"巴西莱年纪老迈，已经八十岁了。王住在玛哈念的时候，他就拿食物来供给王；他原是大富户。"},
{volumn:        10,chapter:        19,versenumber:        33,verse:"王对巴西莱说：“你与我同去，我要在耶路撒冷那里养你的老。”"},
{volumn:        10,chapter:        19,versenumber:        34,verse:"巴西莱对王说：“我在世的年日还能有多少，使我与王同上耶路撒冷呢？"},
{volumn:        10,chapter:        19,versenumber:        35,verse:"仆人现在八十岁了，还能尝出饮食的滋味、辨别美恶吗？还能听男女歌唱的声音吗？仆人何必累赘我主我王呢？"},
{volumn:        10,chapter:        19,versenumber:        36,verse:"仆人只要送王过约旦河，王何必赐我这样的恩典呢？"},
{volumn:        10,chapter:        19,versenumber:        37,verse:"求你准我回去，好死在我本城，葬在我父母的墓旁。这里有王的仆人金罕，让他同我主我王过去，可以随意待他。”"},
{volumn:        10,chapter:        19,versenumber:        38,verse:"王说：“金罕可以与我同去，我必照你的心愿待他。你向我求什么，我都必为你成就。”"},
{volumn:        10,chapter:        19,versenumber:        39,verse:"于是众民过约旦河，王也过去。王与巴西莱亲嘴，为他祝福，巴西莱就回本地去了。"},
{volumn:        10,chapter:        19,versenumber:        40,verse:"王过去，到了吉甲，金罕也跟他过去。犹大众民和以色列民的一半也都送王过去。"},
{volumn:        10,chapter:        19,versenumber:        41,verse:"以色列众人来见王，对他说：“我们弟兄犹大人为什么暗暗送王和王的家眷，并跟随王的人过约旦河？”"},
{volumn:        10,chapter:        19,versenumber:        42,verse:"犹大众人回答以色列人说：“因为王与我们是亲属，你们为何因这事发怒呢？我们吃了王的什么呢？王赏赐了我们什么呢？”"},
{volumn:        10,chapter:        19,versenumber:        43,verse:"以色列人回答犹大人说：“按支派，我们与王有十分的情分；在大卫身上，我们也比你们更有情分。你们为何藐视我们，请王回来不先与我们商量呢？”但犹大人的话比以色列人的话更硬。"},
{volumn:        10,chapter:        20,versenumber:         1,verse:"在那里恰巧有一个匪徒，名叫示巴，是便雅悯人比基利的儿子。他吹角，说：“我们与大卫无份，与耶西的儿子无涉。以色列人哪，你们各回各家去吧！”"},
{volumn:        10,chapter:        20,versenumber:         2,verse:"于是以色列人都离开大卫，跟随比基利的儿子示巴。但犹大人从约旦河直到耶路撒冷，都紧紧跟随他们的王。"},
{volumn:        10,chapter:        20,versenumber:         3,verse:"大卫王来到耶路撒冷，进了宫殿，就把从前留下看守宫殿的十个妃嫔禁闭在冷宫，养活她们，不与她们亲近。她们如同寡妇被禁，直到死的日子。"},
{volumn:        10,chapter:        20,versenumber:         4,verse:"王对亚玛撒说：“你要在三日之内将犹大人招聚了来，你也回到这里来。”"},
{volumn:        10,chapter:        20,versenumber:         5,verse:"亚玛撒就去招聚犹大人，却耽延过了王所限的日期。"},
{volumn:        10,chapter:        20,versenumber:         6,verse:"大卫对亚比筛说：“现在恐怕比基利的儿子示巴加害于我们比押沙龙更甚。你要带领你主的仆人追赶他，免得他得了坚固城，躲避我们。”"},
{volumn:        10,chapter:        20,versenumber:         7,verse:"约押的人和基利提人、比利提人，并所有的勇士，都跟着亚比筛，从耶路撒冷出去追赶比基利的儿子示巴。"},
{volumn:        10,chapter:        20,versenumber:         8,verse:"他们到了基遍的大磐石那里，亚玛撒来迎接他们。那时约押穿着战衣，腰束佩刀的带子，刀在鞘内；约押前行，刀从鞘内掉出来。"},
{volumn:        10,chapter:        20,versenumber:         9,verse:"约押左手拾起刀来，对亚玛撒说：“我兄弟，你好啊！”就用右手抓住亚玛撒的胡子，要与他亲嘴。"},
{volumn:        10,chapter:        20,versenumber:        10,verse:"亚玛撒没有防备约押手里所拿的刀；约押用刀刺入他的肚腹，他的肠子流在地上，没有再刺他，就死了。约押和他兄弟亚比筛往前追赶比基利的儿子示巴。"},
{volumn:        10,chapter:        20,versenumber:        11,verse:"有约押的一个少年人站在亚玛撒尸身旁边，对众人说：“谁喜悦约押，谁归顺大卫，就当跟随约押去。”"},
{volumn:        10,chapter:        20,versenumber:        12,verse:"亚玛撒在道路上滚在自己的血里。那人见众民经过都站住，就把亚玛撒的尸身从路上挪到田间，用衣服遮盖。"},
{volumn:        10,chapter:        20,versenumber:        13,verse:"尸身从路上挪移之后，众民就都跟随约押去追赶比基利的儿子示巴。"},
{volumn:        10,chapter:        20,versenumber:        14,verse:"他走遍以色列各支派，直到伯·玛迦的亚比拉，并比利人的全地；那些地方的人也都聚集跟随他。"},
{volumn:        10,chapter:        20,versenumber:        15,verse:"约押和跟随的人到了伯·玛迦的亚比拉，围困示巴，就对着城筑垒；跟随约押的众民用锤撞城，要使城塌陷。"},
{volumn:        10,chapter:        20,versenumber:        16,verse:"有一个聪明妇人从城上呼叫说：“听啊，听啊，请约押近前来，我好与他说话。”"},
{volumn:        10,chapter:        20,versenumber:        17,verse:"约押就近前来，妇人问他说：“你是约押不是？”他说：“我是。”妇人说：“求你听婢女的话。”约押说：“我听。”"},
{volumn:        10,chapter:        20,versenumber:        18,verse:"妇人说：“古时有话说，当先在亚比拉求问，然后事就定妥。"},
{volumn:        10,chapter:        20,versenumber:        19,verse:"我们这城的人在以色列人中是和平、忠厚的。你为何要毁坏以色列中的大城，吞灭耶和华的产业呢？”"},
{volumn:        10,chapter:        20,versenumber:        20,verse:"约押回答说：“我决不吞灭毁坏，"},
{volumn:        10,chapter:        20,versenumber:        21,verse:"乃因以法莲山地的一个人，比基利的儿子示巴，举手攻击大卫王，你们若将他一人交出来，我便离城而去。”妇人对约押说：“那人的首级必从城墙上丢给你。”"},
{volumn:        10,chapter:        20,versenumber:        22,verse:"妇人就凭她的智慧去劝众人。他们便割下比基利的儿子示巴的首级，丢给约押。约押吹角，众人就离城而散，各归各家去了。约押回耶路撒冷，到王那里。"},
{volumn:        10,chapter:        20,versenumber:        23,verse:"约押作以色列全军的元帅；耶何耶大的儿子比拿雅统辖基利提人和比利提人；"},
{volumn:        10,chapter:        20,versenumber:        24,verse:"亚多兰掌管服苦的人；亚希律的儿子约沙法作史官；"},
{volumn:        10,chapter:        20,versenumber:        25,verse:"示法作书记；撒督和亚比亚他作祭司长；"},
{volumn:        10,chapter:        20,versenumber:        26,verse:"睚珥人以拉作大卫的宰相。"},
{volumn:        10,chapter:        21,versenumber:         1,verse:"大卫年间有饥荒，一连三年，大卫就求问耶和华。耶和华说：“这饥荒是因扫罗和他流人血之家杀死基遍人。”"},
{volumn:        10,chapter:        21,versenumber:         2,verse:"原来这基遍人不是以色列人，乃是亚摩利人中所剩的；以色列人曾向他们起誓，不杀灭他们，扫罗却为以色列人和犹大人发热心，想要杀灭他们。大卫王召了他们来，"},
{volumn:        10,chapter:        21,versenumber:         3,verse:"问他们说：“我当为你们怎样行呢？可用什么赎这罪，使你们为耶和华的产业祝福呢？”"},
{volumn:        10,chapter:        21,versenumber:         4,verse:"基遍人回答说：“我们和扫罗与他家的事并不关乎金银，也不要因我们的缘故杀一个以色列人。”大卫说：“你们怎样说，我就为你们怎样行。”"},
{volumn:        10,chapter:        21,versenumber:         5,verse:"他们对王说：“那从前谋害我们、要灭我们、使我们不得再住以色列境内的人，"},
{volumn:        10,chapter:        21,versenumber:         6,verse:"现在愿将他的子孙七人交给我们，我们好在耶和华面前，将他们悬挂在耶和华拣选扫罗的基比亚。”王说：“我必交给你们。”"},
{volumn:        10,chapter:        21,versenumber:         7,verse:"王因为曾与扫罗的儿子约拿单指着耶和华起誓结盟，就爱惜扫罗的孙子、约拿单的儿子米非波设，不交出来，"},
{volumn:        10,chapter:        21,versenumber:         8,verse:"却把爱雅的女儿利斯巴给扫罗所生的两个儿子亚摩尼、米非波设，和扫罗女儿米甲的姐姐给米何拉人巴西莱儿子亚得列所生的五个儿子"},
{volumn:        10,chapter:        21,versenumber:         9,verse:"交在基遍人的手里。基遍人就把他们，在耶和华面前，悬挂在山上，这七人就一同死亡。被杀的时候正是收割的日子，就是动手割大麦的时候。"},
{volumn:        10,chapter:        21,versenumber:        10,verse:"爱雅的女儿利斯巴用麻布在磐石上搭棚，从动手收割的时候直到天降雨在尸身上的时候，日间不容空中的雀鸟落在尸身上，夜间不让田野的走兽前来糟践。"},
{volumn:        10,chapter:        21,versenumber:        11,verse:"有人将扫罗的妃嫔爱雅女儿利斯巴所行的这事告诉大卫。"},
{volumn:        10,chapter:        21,versenumber:        12,verse:"大卫就去，从基列·雅比人那里将扫罗和他儿子约拿单的骸骨搬了来（是因非利士人从前在基利波杀扫罗，将尸身悬挂在伯·珊的街市上，基列·雅比人把尸身偷了去。）"},
{volumn:        10,chapter:        21,versenumber:        13,verse:"大卫将扫罗和他儿子约拿单的骸骨从那里搬了来，又收殓被悬挂七人的骸骨，"},
{volumn:        10,chapter:        21,versenumber:        14,verse:"将扫罗和他儿子约拿单的骸骨葬在便雅悯的洗拉，在扫罗父亲基士的坟墓里；众人行了王所吩咐的。此后　神垂听国民所求的。"},
{volumn:        10,chapter:        21,versenumber:        15,verse:"非利士人与以色列人打仗；大卫带领仆人下去，与非利士人接战，大卫就疲乏了。"},
{volumn:        10,chapter:        21,versenumber:        16,verse:"伟人的一个儿子以实·比诺要杀大卫；他的铜枪重三百舍客勒，又佩着新刀。"},
{volumn:        10,chapter:        21,versenumber:        17,verse:"但洗鲁雅的儿子亚比筛帮助大卫，攻打非利士人，将他杀死。当日，跟随大卫的人向大卫起誓说：“以后你不可再与我们一同出战，恐怕熄灭以色列的灯。”"},
{volumn:        10,chapter:        21,versenumber:        18,verse:"后来，以色列人在歌伯与非利士人打仗，户沙人西比该杀了伟人的一个儿子撒弗。"},
{volumn:        10,chapter:        21,versenumber:        19,verse:"又在歌伯与非利士人打仗，伯利恒人雅雷俄珥金的儿子伊勒哈难杀了迦特人歌利亚。这人的枪杆粗如织布的机轴。"},
{volumn:        10,chapter:        21,versenumber:        20,verse:"又在迦特打仗，那里有一个身量高大的人，手脚都是六指，共有二十四个指头；他也是伟人的儿子。"},
{volumn:        10,chapter:        21,versenumber:        21,verse:"这人向以色列人骂阵，大卫的哥哥示米亚的儿子约拿单就杀了他。"},
{volumn:        10,chapter:        21,versenumber:        22,verse:"这四个人是迦特伟人的儿子，都死在大卫和他仆人的手下。"},
{volumn:        10,chapter:        22,versenumber:         1,verse:"当耶和华救大卫脱离一切仇敌和扫罗之手的日子，他向耶和华念这诗，"},
{volumn:        10,chapter:        22,versenumber:         2,verse:"说：“耶和华是我的岩石，我的山寨，我的救主，"},
{volumn:        10,chapter:        22,versenumber:         3,verse:"我的　神，我的磐石，我所投靠的。他是我的盾牌，是拯救我的角，是我的高台，是我的避难所。我的救主啊，你是救我脱离强暴的。"},
{volumn:        10,chapter:        22,versenumber:         4,verse:"我要求告当赞美的耶和华，这样，我必从仇敌手中被救出来。"},
{volumn:        10,chapter:        22,versenumber:         5,verse:"曾有死亡的波浪环绕我，匪类的急流使我惊惧，"},
{volumn:        10,chapter:        22,versenumber:         6,verse:"阴间的绳索缠绕我，死亡的网罗临到我。"},
{volumn:        10,chapter:        22,versenumber:         7,verse:"我在急难中求告耶和华，向我的　神呼求。他从殿中听了我的声音；我的呼求入了他的耳中。"},
{volumn:        10,chapter:        22,versenumber:         8,verse:"那时因他发怒，地就摇撼战抖；天的根基也震动摇撼。"},
{volumn:        10,chapter:        22,versenumber:         9,verse:"从他鼻孔冒烟上腾；从他口中发火焚烧，连炭也着了。"},
{volumn:        10,chapter:        22,versenumber:        10,verse:"他又使天下垂，亲自降临；有黑云在他脚下。"},
{volumn:        10,chapter:        22,versenumber:        11,verse:"他坐着基路伯飞行，在风的翅膀上显现。"},
{volumn:        10,chapter:        22,versenumber:        12,verse:"他以黑暗和聚集的水、天空的厚云为他四围的行宫。"},
{volumn:        10,chapter:        22,versenumber:        13,verse:"因他面前的光辉炭都着了。"},
{volumn:        10,chapter:        22,versenumber:        14,verse:"耶和华从天上打雷；至高者发出声音。"},
{volumn:        10,chapter:        22,versenumber:        15,verse:"他射出箭来，使仇敌四散，发出闪电，使他们扰乱。"},
{volumn:        10,chapter:        22,versenumber:        16,verse:"耶和华的斥责一发，鼻孔的气一出，海底就出现，大地的根基也显露。"},
{volumn:        10,chapter:        22,versenumber:        17,verse:"他从高天伸手抓住我，把我从大水中拉上来。"},
{volumn:        10,chapter:        22,versenumber:        18,verse:"他救我脱离我的劲敌和那些恨我的人，因为他们比我强盛。"},
{volumn:        10,chapter:        22,versenumber:        19,verse:"我遭遇灾难的日子，他们来攻击我；但耶和华是我的倚靠。"},
{volumn:        10,chapter:        22,versenumber:        20,verse:"他又领我到宽阔之处；他救拔我，因他喜悦我。"},
{volumn:        10,chapter:        22,versenumber:        21,verse:"耶和华按着我的公义报答我，按着我手中的清洁赏赐我。"},
{volumn:        10,chapter:        22,versenumber:        22,verse:"因为我遵守了耶和华的道，未曾作恶离开我的　神。"},
{volumn:        10,chapter:        22,versenumber:        23,verse:"他的一切典章常在我面前；他的律例，我也未曾离弃。"},
{volumn:        10,chapter:        22,versenumber:        24,verse:"我在他面前作了完全人；我也保守自己远离我的罪孽。"},
{volumn:        10,chapter:        22,versenumber:        25,verse:"所以耶和华按我的公义，按我在他眼前的清洁赏赐我。"},
{volumn:        10,chapter:        22,versenumber:        26,verse:"慈爱的人，你以慈爱待他；完全的人，你以完全待他；"},
{volumn:        10,chapter:        22,versenumber:        27,verse:"清洁的人，你以清洁待他；乖僻的人，你以弯曲待他。"},
{volumn:        10,chapter:        22,versenumber:        28,verse:"困苦的百姓，你必拯救；但你的眼目察看高傲的人，使他降卑。"},
{volumn:        10,chapter:        22,versenumber:        29,verse:"耶和华啊，你是我的灯；耶和华必照明我的黑暗。"},
{volumn:        10,chapter:        22,versenumber:        30,verse:"我藉着你冲入敌军，藉着我的　神跳过墙垣。"},
{volumn:        10,chapter:        22,versenumber:        31,verse:"至于　神，他的道是完全的；耶和华的话是炼净的。凡投靠他的，他便作他们的盾牌。"},
{volumn:        10,chapter:        22,versenumber:        32,verse:"除了耶和华，谁是　神呢？除了我们的　神，谁是磐石呢？"},
{volumn:        10,chapter:        22,versenumber:        33,verse:"神是我坚固的保障；他引导完全人行他的路。"},
{volumn:        10,chapter:        22,versenumber:        34,verse:"他使我的脚快如母鹿的蹄，又使我在高处安稳。"},
{volumn:        10,chapter:        22,versenumber:        35,verse:"他教导我的手能以争战，甚至我的膀臂能开铜弓。"},
{volumn:        10,chapter:        22,versenumber:        36,verse:"你把你的救恩给我作盾牌；你的温和使我为大。"},
{volumn:        10,chapter:        22,versenumber:        37,verse:"你使我脚下的地步宽阔；我的脚未曾滑跌。"},
{volumn:        10,chapter:        22,versenumber:        38,verse:"我追赶我的仇敌，灭绝了他们，未灭以先，我没有归回。"},
{volumn:        10,chapter:        22,versenumber:        39,verse:"我灭绝了他们，打伤了他们，使他们不能起来；他们都倒在我的脚下。"},
{volumn:        10,chapter:        22,versenumber:        40,verse:"因为你曾以力量束我的腰，使我能争战；你也使那起来攻击我的都服在我以下。"},
{volumn:        10,chapter:        22,versenumber:        41,verse:"你又使我的仇敌在我面前转背逃跑，叫我能以剪除那恨我的人。"},
{volumn:        10,chapter:        22,versenumber:        42,verse:"他们仰望，却无人拯救；就是呼求耶和华，他也不应允。"},
{volumn:        10,chapter:        22,versenumber:        43,verse:"我捣碎他们，如同地上的灰尘，践踏他们，四散在地，如同街上的泥土。"},
{volumn:        10,chapter:        22,versenumber:        44,verse:"你救我脱离我百姓的争竞，保护我作列国的元首；我素不认识的民必侍奉我。"},
{volumn:        10,chapter:        22,versenumber:        45,verse:"外邦人要投降我，一听见我的名声就必顺从我。"},
{volumn:        10,chapter:        22,versenumber:        46,verse:"外邦人要衰残，战战兢兢地出他们的营寨。"},
{volumn:        10,chapter:        22,versenumber:        47,verse:"耶和华是活　神，愿我的磐石被人称颂！愿　神，那拯救我的磐石被人尊崇！"},
{volumn:        10,chapter:        22,versenumber:        48,verse:"这位　神就是那为我伸冤、使众民服在我以下的。"},
{volumn:        10,chapter:        22,versenumber:        49,verse:"你救我脱离仇敌，又把我举起，高过那些起来攻击我的；你救我脱离强暴的人。"},
{volumn:        10,chapter:        22,versenumber:        50,verse:"耶和华啊，因此我要在外邦中称谢你，歌颂你的名。"},
{volumn:        10,chapter:        22,versenumber:        51,verse:"耶和华赐极大的救恩给他所立的王，施慈爱给他的受膏者，就是给大卫和他的后裔，直到永远！”"},
{volumn:        10,chapter:        23,versenumber:         1,verse:"以下是大卫末了的话。耶西的儿子大卫得居高位，是雅各　神所膏的，作以色列的美歌者，说："},
{volumn:        10,chapter:        23,versenumber:         2,verse:"“耶和华的灵藉着我说：他的话在我口中。"},
{volumn:        10,chapter:        23,versenumber:         3,verse:"以色列的　神、以色列的磐石晓谕我说：那以公义治理人民的，敬畏　神执掌权柄，"},
{volumn:        10,chapter:        23,versenumber:         4,verse:"他必像日出的晨光，如无云的清晨，雨后的晴光，使地发生嫩草。"},
{volumn:        10,chapter:        23,versenumber:         5,verse:"我家在　神面前并非如此；　神却与我立永远的约。这约凡事坚稳，关乎我的一切救恩和我一切所想望的，他岂不为我成就吗？"},
{volumn:        10,chapter:        23,versenumber:         6,verse:"但匪类都必像荆棘被丢弃；人不敢用手拿它；"},
{volumn:        10,chapter:        23,versenumber:         7,verse:"拿它的人必带铁器和枪杆，终久它必被火焚烧。”"},
{volumn:        10,chapter:        23,versenumber:         8,verse:"大卫勇士的名字记在下面：他革扪人约设·巴设，又称伊斯尼人亚底挪，他是军长的统领，一时击杀了八百人。"},
{volumn:        10,chapter:        23,versenumber:         9,verse:"其次是亚合人朵多的儿子以利亚撒。从前非利士人聚集要打仗，以色列人迎着上去，有跟随大卫的三个勇士向非利士人骂阵，其中有以利亚撒。"},
{volumn:        10,chapter:        23,versenumber:        10,verse:"他起来击杀非利士人，直到手臂疲乏，手黏住刀把。那日耶和华使以色列人大获全胜；众民在以利亚撒后头专夺财物。"},
{volumn:        10,chapter:        23,versenumber:        11,verse:"其次是哈拉人亚基的儿子沙玛。一日，非利士人聚集成群，在一块长满红豆的田里，众民就在非利士人面前逃跑。"},
{volumn:        10,chapter:        23,versenumber:        12,verse:"沙玛却站在那田间击杀非利士人，救护了那田。耶和华使以色列人大获全胜。"},
{volumn:        10,chapter:        23,versenumber:        13,verse:"收割的时候，有三十个勇士中的三个人下到亚杜兰洞见大卫。非利士的军兵在利乏音谷安营。"},
{volumn:        10,chapter:        23,versenumber:        14,verse:"那时大卫在山寨，非利士人的防营在伯利恒。"},
{volumn:        10,chapter:        23,versenumber:        15,verse:"大卫渴想，说：“甚愿有人将伯利恒城门旁、井里的水打来给我喝。”"},
{volumn:        10,chapter:        23,versenumber:        16,verse:"这三个勇士就闯过非利士人的营盘，从伯利恒城门旁的井里打水，拿来奉给大卫。他却不肯喝，将水奠在耶和华面前，"},
{volumn:        10,chapter:        23,versenumber:        17,verse:"说：“耶和华啊，这三个人冒死去打水；这水好像他们的血一般，我断不敢喝。”如此，大卫不肯喝。这是三个勇士所做的事。"},
{volumn:        10,chapter:        23,versenumber:        18,verse:"洗鲁雅的儿子、约押的兄弟亚比筛是这三个勇士的首领；他举枪杀了三百人，就在三个勇士里得了名。"},
{volumn:        10,chapter:        23,versenumber:        19,verse:"他在这三个勇士里是最尊贵的，所以作他们的首领，只是不及前三个勇士。"},
{volumn:        10,chapter:        23,versenumber:        20,verse:"有甲薛勇士耶何耶大的儿子比拿雅行过大能的事；他杀了摩押人亚利伊勒的两个儿子，又在下雪的时候下坑里去，杀了一个狮子，"},
{volumn:        10,chapter:        23,versenumber:        21,verse:"又杀了一个强壮的埃及人；埃及人手里拿着枪，比拿雅只拿着棍子下去，从埃及人手里夺过枪来，用那枪将他杀死。"},
{volumn:        10,chapter:        23,versenumber:        22,verse:"这是耶何耶大的儿子比拿雅所行的事，就在三个勇士里得了名。"},
{volumn:        10,chapter:        23,versenumber:        23,verse:"他比那三十个勇士都尊贵，只是不及前三个勇士。大卫立他作护卫长。"},
{volumn:        10,chapter:        23,versenumber:        24,verse:"三十个勇士里有约押的兄弟亚撒黑，伯利恒人朵多的儿子伊勒哈难，"},
{volumn:        10,chapter:        23,versenumber:        25,verse:"哈律人沙玛，哈律人以利加，"},
{volumn:        10,chapter:        23,versenumber:        26,verse:"帕勒提人希利斯，提哥亚人益吉的儿子以拉，"},
{volumn:        10,chapter:        23,versenumber:        27,verse:"亚拿突人亚比以谢，户沙人米本乃，"},
{volumn:        10,chapter:        23,versenumber:        28,verse:"亚合人撒们，尼陀法人玛哈莱，"},
{volumn:        10,chapter:        23,versenumber:        29,verse:"尼陀法人巴拿的儿子希立，便雅悯族、基比亚人利拜的儿子以太，"},
{volumn:        10,chapter:        23,versenumber:        30,verse:"比拉顿人比拿雅，迦实溪人希太，"},
{volumn:        10,chapter:        23,versenumber:        31,verse:"伯·亚拉巴人亚比亚本，巴鲁米人押斯玛弗，"},
{volumn:        10,chapter:        23,versenumber:        32,verse:"沙本人以利雅哈巴，雅善儿子中的约拿单，"},
{volumn:        10,chapter:        23,versenumber:        33,verse:"哈拉人沙玛，哈拉人沙拉的儿子亚希暗，"},
{volumn:        10,chapter:        23,versenumber:        34,verse:"玛迦人亚哈拜的儿子以利法列，基罗人亚希多弗的儿子以连，"},
{volumn:        10,chapter:        23,versenumber:        35,verse:"迦密人希斯莱，亚巴人帕莱，"},
{volumn:        10,chapter:        23,versenumber:        36,verse:"琐巴人拿单的儿子以甲，迦得人巴尼，"},
{volumn:        10,chapter:        23,versenumber:        37,verse:"亚扪人洗勒，比录人拿哈莱（是给洗鲁雅的儿子约押拿兵器的），"},
{volumn:        10,chapter:        23,versenumber:        38,verse:"以帖人以拉，以帖人迦立，"},
{volumn:        10,chapter:        23,versenumber:        39,verse:"赫人乌利亚，共有三十七人。"},
{volumn:        10,chapter:        24,versenumber:         1,verse:"耶和华又向以色列人发怒，就激动大卫，使他吩咐人去数点以色列人和犹大人。"},
{volumn:        10,chapter:        24,versenumber:         2,verse:"大卫就吩咐跟随他的元帅约押说：“你去走遍以色列众支派，从但直到别是巴，数点百姓，我好知道他们的数目。”"},
{volumn:        10,chapter:        24,versenumber:         3,verse:"约押对王说：“无论百姓多少，愿耶和华你的　神再加增百倍，使我主我王亲眼得见。我主我王何必喜悦行这事呢？”"},
{volumn:        10,chapter:        24,versenumber:         4,verse:"但王的命令胜过约押和众军长。约押和众军长就从王面前出去，数点以色列的百姓。"},
{volumn:        10,chapter:        24,versenumber:         5,verse:"他们过了约旦河，在迦得谷中、城的右边亚罗珥安营，与雅谢相对，"},
{volumn:        10,chapter:        24,versenumber:         6,verse:"又到了基列和他停·合示地，又到了但·雅安，绕到西顿，"},
{volumn:        10,chapter:        24,versenumber:         7,verse:"来到推罗的保障，并希未人和迦南人的各城，又到犹大南方的别是巴。"},
{volumn:        10,chapter:        24,versenumber:         8,verse:"他们走遍全地，过了九个月零二十天，就回到耶路撒冷。"},
{volumn:        10,chapter:        24,versenumber:         9,verse:"约押将百姓的总数奏告于王：以色列拿刀的勇士有八十万；犹大有五十万。"},
{volumn:        10,chapter:        24,versenumber:        10,verse:"大卫数点百姓以后，就心中自责，祷告耶和华说：“我行这事大有罪了。耶和华啊，求你除掉仆人的罪孽，因我所行的甚是愚昧。”"},
{volumn:        10,chapter:        24,versenumber:        11,verse:"大卫早晨起来，耶和华的话临到先知迦得，就是大卫的先见，说："},
{volumn:        10,chapter:        24,versenumber:        12,verse:"“你去告诉大卫，说耶和华如此说：‘我有三样灾，随你选择一样，我好降与你。’”"},
{volumn:        10,chapter:        24,versenumber:        13,verse:"于是迦得来见大卫，对他说：“你愿意国中有七年的饥荒呢？是在你敌人面前逃跑，被追赶三个月呢？是在你国中有三日的瘟疫呢？现在你要揣摩思想，我好回复那差我来的。”"},
{volumn:        10,chapter:        24,versenumber:        14,verse:"大卫对迦得说：“我甚为难！我愿落在耶和华的手里，因为他有丰盛的怜悯。我不愿落在人的手里。”"},
{volumn:        10,chapter:        24,versenumber:        15,verse:"于是，耶和华降瘟疫与以色列人，自早晨到所定的时候；从但直到别是巴，民间死了七万人。"},
{volumn:        10,chapter:        24,versenumber:        16,verse:"天使向耶路撒冷伸手要灭城的时候，耶和华后悔，就不降这灾了，吩咐灭民的天使说：“够了！住手吧！”那时耶和华的使者在耶布斯人亚劳拿的禾场那里。"},
{volumn:        10,chapter:        24,versenumber:        17,verse:"大卫看见灭民的天使，就祷告耶和华说：“我犯了罪，行了恶；但这群羊做了什么呢？愿你的手攻击我和我的父家。”"},
{volumn:        10,chapter:        24,versenumber:        18,verse:"当日，迦得来见大卫，对他说：“你上去，在耶布斯人亚劳拿的禾场上为耶和华筑一座坛。”"},
{volumn:        10,chapter:        24,versenumber:        19,verse:"大卫就照着迦得奉耶和华名所说的话上去了。"},
{volumn:        10,chapter:        24,versenumber:        20,verse:"亚劳拿观看，见王和他臣仆前来，就迎接出去，脸伏于地，向王下拜，"},
{volumn:        10,chapter:        24,versenumber:        21,verse:"说：“我主我王为何来到仆人这里呢？”大卫说：“我要买你这禾场，为耶和华筑一座坛，使民间的瘟疫止住。”"},
{volumn:        10,chapter:        24,versenumber:        22,verse:"亚劳拿对大卫说：“我主我王，你喜悦用什么，就拿去献祭。看哪，这里有牛可以作燔祭，有打粮的器具和套牛的轭可以当柴烧。"},
{volumn:        10,chapter:        24,versenumber:        23,verse:"王啊，这一切，我亚劳拿都奉给你”；又对王说：“愿耶和华你的　神悦纳你。”"},
{volumn:        10,chapter:        24,versenumber:        24,verse:"王对亚劳拿说：“不然。我必要按着价值向你买；我不肯用白得之物作燔祭献给耶和华我的　神。”大卫就用五十舍客勒银子买了那禾场与牛。"},
{volumn:        10,chapter:        24,versenumber:        25,verse:"大卫在那里为耶和华筑了一座坛，献燔祭和平安祭。如此，耶和华垂听国民所求的，瘟疫在以色列人中就止住了。"},
{volumn:        11,chapter:         1,versenumber:         1,verse:"大卫王年纪老迈，虽用被遮盖，仍不觉暖。"},
{volumn:        11,chapter:         1,versenumber:         2,verse:"所以臣仆对他说：“不如为我主我王寻找一个处女，使她伺候王，奉养王，睡在王的怀中，好叫我主我王得暖。”"},
{volumn:        11,chapter:         1,versenumber:         3,verse:"于是在以色列全境寻找美貌的童女，寻得书念的一个童女亚比煞，就带到王那里。"},
{volumn:        11,chapter:         1,versenumber:         4,verse:"这童女极其美貌，她奉养王，伺候王，王却没有与她亲近。"},
{volumn:        11,chapter:         1,versenumber:         5,verse:"那时，哈及的儿子亚多尼雅自尊，说：“我必作王”，就为自己预备车辆、马兵，又派五十人在他前头奔走。"},
{volumn:        11,chapter:         1,versenumber:         6,verse:"他父亲素来没有使他忧闷，说：“你是做什么呢？”他甚俊美，生在押沙龙之后。"},
{volumn:        11,chapter:         1,versenumber:         7,verse:"亚多尼雅与洗鲁雅的儿子约押，和祭司亚比亚他商议；二人就顺从他，帮助他。"},
{volumn:        11,chapter:         1,versenumber:         8,verse:"但祭司撒督、耶何耶大的儿子比拿雅、先知拿单、示每、利以，并大卫的勇士都不顺从亚多尼雅。"},
{volumn:        11,chapter:         1,versenumber:         9,verse:"一日，亚多尼雅在隐·罗结旁、琐希列磐石那里宰了牛羊、肥犊，请他的诸弟兄，就是王的众子，并所有作王臣仆的犹大人；"},
{volumn:        11,chapter:         1,versenumber:        10,verse:"惟独先知拿单和比拿雅并勇士，与他的兄弟所罗门，他都没有请。"},
{volumn:        11,chapter:         1,versenumber:        11,verse:"拿单对所罗门的母亲拔示巴说：“哈及的儿子亚多尼雅作王了，你没有听见吗？我们的主大卫却不知道。"},
{volumn:        11,chapter:         1,versenumber:        12,verse:"现在我可以给你出个主意，好保全你和你儿子所罗门的性命。"},
{volumn:        11,chapter:         1,versenumber:        13,verse:"你进去见大卫王，对他说：‘我主我王啊，你不曾向婢女起誓说：你儿子所罗门必接续我作王，坐在我的位上吗？现在亚多尼雅怎么作了王呢？’"},
{volumn:        11,chapter:         1,versenumber:        14,verse:"你还与王说话的时候，我也随后进去，证实你的话。”"},
{volumn:        11,chapter:         1,versenumber:        15,verse:"拔示巴进入内室见王，王甚老迈，书念的童女亚比煞正伺候王。"},
{volumn:        11,chapter:         1,versenumber:        16,verse:"拔示巴向王屈身下拜；王说：“你要什么？”"},
{volumn:        11,chapter:         1,versenumber:        17,verse:"她说：“我主啊，你曾向婢女指着耶和华你的　神起誓说：‘你儿子所罗门必接续我作王，坐在我的位上。’"},
{volumn:        11,chapter:         1,versenumber:        18,verse:"现在亚多尼雅作王了，我主我王却不知道。"},
{volumn:        11,chapter:         1,versenumber:        19,verse:"他宰了许多牛羊、肥犊，请了王的众子和祭司亚比亚他，并元帅约押；惟独王的仆人所罗门，他没有请。"},
{volumn:        11,chapter:         1,versenumber:        20,verse:"我主我王啊，以色列众人的眼目都仰望你，等你晓谕他们，在我主我王之后谁坐你的位。"},
{volumn:        11,chapter:         1,versenumber:        21,verse:"若不然，到我主我王与列祖同睡以后，我和我儿子所罗门必算为罪人了。”"},
{volumn:        11,chapter:         1,versenumber:        22,verse:"拔示巴还与王说话的时候，先知拿单也进来了。"},
{volumn:        11,chapter:         1,versenumber:        23,verse:"有人奏告王说：“先知拿单来了。”拿单进到王前，脸伏于地。"},
{volumn:        11,chapter:         1,versenumber:        24,verse:"拿单说：“我主我王果然应许亚多尼雅说‘你必接续我作王，坐在我的位上’吗？"},
{volumn:        11,chapter:         1,versenumber:        25,verse:"他今日下去，宰了许多牛羊、肥犊，请了王的众子和军长，并祭司亚比亚他；他们正在亚多尼雅面前吃喝，说：‘愿亚多尼雅王万岁！’"},
{volumn:        11,chapter:         1,versenumber:        26,verse:"惟独我，就是你的仆人和祭司撒督，耶何耶大的儿子比拿雅，并王的仆人所罗门，他都没有请。"},
{volumn:        11,chapter:         1,versenumber:        27,verse:"这事果然出乎我主我王吗？王却没有告诉仆人们，在我主我王之后谁坐你的位。”"},
{volumn:        11,chapter:         1,versenumber:        28,verse:"大卫王吩咐说：“叫拔示巴来。”拔示巴就进来，站在王面前。"},
{volumn:        11,chapter:         1,versenumber:        29,verse:"王起誓说：“我指着救我性命脱离一切苦难、永生的耶和华起誓。"},
{volumn:        11,chapter:         1,versenumber:        30,verse:"我既然指着耶和华以色列的　神向你起誓说：你儿子所罗门必接续我作王，坐在我的位上。我今日就必照这话而行。”"},
{volumn:        11,chapter:         1,versenumber:        31,verse:"于是，拔示巴脸伏于地，向王下拜，说：“愿我主大卫王万岁！”"},
{volumn:        11,chapter:         1,versenumber:        32,verse:"大卫王又吩咐说：“将祭司撒督、先知拿单、耶何耶大的儿子比拿雅召来！”他们就都来到王面前。"},
{volumn:        11,chapter:         1,versenumber:        33,verse:"王对他们说：“要带领你们主的仆人，使我儿子所罗门骑我的骡子，送他下到基训；"},
{volumn:        11,chapter:         1,versenumber:        34,verse:"在那里，祭司撒督和先知拿单要膏他作以色列的王；你们也要吹角，说：‘愿所罗门王万岁！’"},
{volumn:        11,chapter:         1,versenumber:        35,verse:"然后要跟随他上来，使他坐在我的位上，接续我作王。我已立他作以色列和犹大的君。”"},
{volumn:        11,chapter:         1,versenumber:        36,verse:"耶何耶大的儿子比拿雅对王说：“阿们！愿耶和华我主我王的　神也这样命定。"},
{volumn:        11,chapter:         1,versenumber:        37,verse:"耶和华怎样与我主我王同在，愿他照样与所罗门同在，使他的国位比我主大卫王的国位更大。”"},
{volumn:        11,chapter:         1,versenumber:        38,verse:"于是，祭司撒督、先知拿单、耶何耶大的儿子比拿雅，和基利提人、比利提人都下去使所罗门骑大卫王的骡子，将他送到基训。"},
{volumn:        11,chapter:         1,versenumber:        39,verse:"祭司撒督就从帐幕中取了盛膏油的角来，用膏膏所罗门。人就吹角，众民都说：“愿所罗门王万岁！”"},
{volumn:        11,chapter:         1,versenumber:        40,verse:"众民跟随他上来，且吹笛，大大欢呼，声音震地。"},
{volumn:        11,chapter:         1,versenumber:        41,verse:"亚多尼雅和所请的众客筵宴方毕，听见这声音；约押听见角声就说：“城中为何有这响声呢？”"},
{volumn:        11,chapter:         1,versenumber:        42,verse:"他正说话的时候，祭司亚比亚他的儿子约拿单来了。亚多尼雅对他说：“进来吧！你是个忠义的人，必是报好信息。”"},
{volumn:        11,chapter:         1,versenumber:        43,verse:"约拿单对亚多尼雅说：“我们的主大卫王诚然立所罗门为王了。"},
{volumn:        11,chapter:         1,versenumber:        44,verse:"王差遣祭司撒督、先知拿单、耶何耶大的儿子比拿雅，和基利提人、比利提人都去使所罗门骑王的骡子。"},
{volumn:        11,chapter:         1,versenumber:        45,verse:"祭司撒督和先知拿单在基训已经膏他作王。众人都从那里欢呼着上来，声音使城震动，这就是你们所听见的声音；"},
{volumn:        11,chapter:         1,versenumber:        46,verse:"并且所罗门登了国位。"},
{volumn:        11,chapter:         1,versenumber:        47,verse:"王的臣仆也来为我们的主大卫王祝福，说：‘愿王的　神使所罗门的名比王的名更尊荣；使他的国位比王的国位更大。’王就在床上屈身下拜。"},
{volumn:        11,chapter:         1,versenumber:        48,verse:"王又说：‘耶和华以色列的　神是应当称颂的；因他赐我一人今日坐在我的位上，我也亲眼看见了。’”"},
{volumn:        11,chapter:         1,versenumber:        49,verse:"亚多尼雅的众客听见这话就都惊惧，起来四散。"},
{volumn:        11,chapter:         1,versenumber:        50,verse:"亚多尼雅惧怕所罗门，就起来，去抓住祭坛的角。"},
{volumn:        11,chapter:         1,versenumber:        51,verse:"有人告诉所罗门说：“亚多尼雅惧怕所罗门王，现在抓住祭坛的角，说：‘愿所罗门王今日向我起誓，必不用刀杀仆人。’”"},
{volumn:        11,chapter:         1,versenumber:        52,verse:"所罗门说：“他若作忠义的人，连一根头发也不致落在地上；他若行恶，必要死亡。”"},
{volumn:        11,chapter:         1,versenumber:        53,verse:"于是所罗门王差遣人，使亚多尼雅从坛上下来，他就来，向所罗门王下拜；所罗门对他说：“你回家去吧！”"},
{volumn:        11,chapter:         2,versenumber:         1,verse:"大卫的死期临近了，就嘱咐他儿子所罗门说："},
{volumn:        11,chapter:         2,versenumber:         2,verse:"“我现在要走世人必走的路。所以，你当刚强，作大丈夫，"},
{volumn:        11,chapter:         2,versenumber:         3,verse:"遵守耶和华你　神所吩咐的，照着摩西律法上所写的行主的道，谨守他的律例、诫命、典章、法度。这样，你无论做什么事，不拘往何处去，尽都亨通。"},
{volumn:        11,chapter:         2,versenumber:         4,verse:"耶和华必成就向我所应许的话说：‘你的子孙若谨慎自己的行为，尽心尽意诚诚实实地行在我面前，就不断人坐以色列的国位。’"},
{volumn:        11,chapter:         2,versenumber:         5,verse:"你知道洗鲁雅的儿子约押向我所行的，就是杀了以色列的两个元帅：尼珥的儿子押尼珥和益帖的儿子亚玛撒。他在太平之时流这二人的血，如在争战之时一样，将这血染了腰间束的带和脚上穿的鞋。"},
{volumn:        11,chapter:         2,versenumber:         6,verse:"所以你要照你的智慧行，不容他白头安然下阴间。"},
{volumn:        11,chapter:         2,versenumber:         7,verse:"你当恩待基列人巴西莱的众子，使他们常与你同席吃饭；因为我躲避你哥哥押沙龙的时候，他们拿食物来迎接我。"},
{volumn:        11,chapter:         2,versenumber:         8,verse:"在你这里有巴户琳的便雅悯人，基拉的儿子示每；我往玛哈念去的那日，他用狠毒的言语咒骂我，后来却下约旦河迎接我，我就指着耶和华向他起誓说：‘我必不用刀杀你。’"},
{volumn:        11,chapter:         2,versenumber:         9,verse:"现在你不要以他为无罪。你是聪明人，必知道怎样待他，使他白头见杀，流血下到阴间。”"},
{volumn:        11,chapter:         2,versenumber:        10,verse:"大卫与他列祖同睡，葬在大卫城。"},
{volumn:        11,chapter:         2,versenumber:        11,verse:"大卫作以色列王四十年：在希伯仑作王七年，在耶路撒冷作王三十三年。"},
{volumn:        11,chapter:         2,versenumber:        12,verse:"所罗门坐他父亲大卫的位，他的国甚是坚固。"},
{volumn:        11,chapter:         2,versenumber:        13,verse:"哈及的儿子亚多尼雅去见所罗门的母亲拔示巴，拔示巴问他说：“你来是为平安吗？”回答说：“是为平安”；"},
{volumn:        11,chapter:         2,versenumber:        14,verse:"又说：“我有话对你说。”拔示巴说：“你说吧。”"},
{volumn:        11,chapter:         2,versenumber:        15,verse:"亚多尼雅说：“你知道国原是归我的，以色列众人也都仰望我作王，不料，国反归了我兄弟，因他得国是出乎耶和华。"},
{volumn:        11,chapter:         2,versenumber:        16,verse:"现在我有一件事求你，望你不要推辞。”拔示巴说：“你说吧。”"},
{volumn:        11,chapter:         2,versenumber:        17,verse:"他说：“求你请所罗门王将书念的女子亚比煞赐我为妻，因他必不推辞你。”"},
{volumn:        11,chapter:         2,versenumber:        18,verse:"拔示巴说：“好，我必为你对王提说。”"},
{volumn:        11,chapter:         2,versenumber:        19,verse:"于是，拔示巴去见所罗门王，要为亚多尼雅提说；王起来迎接，向她下拜，就坐在位上，吩咐人为王母设一座位，她便坐在王的右边。"},
{volumn:        11,chapter:         2,versenumber:        20,verse:"拔示巴说：“我有一件小事求你，望你不要推辞。”王说：“请母亲说，我必不推辞。”"},
{volumn:        11,chapter:         2,versenumber:        21,verse:"拔示巴说：“求你将书念的女子亚比煞赐给你哥哥亚多尼雅为妻。”"},
{volumn:        11,chapter:         2,versenumber:        22,verse:"所罗门王对他母亲说：“为何单替他求书念的女子亚比煞呢？也可以为他求国吧！他是我的哥哥，他有祭司亚比亚他和洗鲁雅的儿子约押为辅佐。”"},
{volumn:        11,chapter:         2,versenumber:        23,verse:"所罗门王就指着耶和华起誓说：“亚多尼雅这话是自己送命，不然，愿　神重重地降罚与我。"},
{volumn:        11,chapter:         2,versenumber:        24,verse:"耶和华坚立我，使我坐在父亲大卫的位上，照着所应许的话为我建立家室；现在我指着永生的耶和华起誓，亚多尼雅今日必被治死。”"},
{volumn:        11,chapter:         2,versenumber:        25,verse:"于是所罗门王差遣耶何耶大的儿子比拿雅，将亚多尼雅杀死。"},
{volumn:        11,chapter:         2,versenumber:        26,verse:"王对祭司亚比亚他说：“你回亚拿突归自己的田地去吧！你本是该死的，但因你在我父亲大卫面前抬过主耶和华的约柜，又与我父亲同受一切苦难，所以我今日不将你杀死。”"},
{volumn:        11,chapter:         2,versenumber:        27,verse:"所罗门就革除亚比亚他，不许他作耶和华的祭司。这样，便应验耶和华在示罗论以利家所说的话。"},
{volumn:        11,chapter:         2,versenumber:        28,verse:"约押虽然没有归从押沙龙，却归从了亚多尼雅。他听见这风声，就逃到耶和华的帐幕，抓住祭坛的角。"},
{volumn:        11,chapter:         2,versenumber:        29,verse:"有人告诉所罗门王说：“约押逃到耶和华的帐幕，现今在祭坛的旁边。”所罗门就差遣耶何耶大的儿子比拿雅，说：“你去将他杀死。”"},
{volumn:        11,chapter:         2,versenumber:        30,verse:"比拿雅来到耶和华的帐幕，对约押说：“王吩咐说，你出来吧！”他说：“我不出去，我要死在这里。”比拿雅就去回复王，说约押如此如此回答我。"},
{volumn:        11,chapter:         2,versenumber:        31,verse:"王说：“你可以照着他的话行，杀死他，将他葬埋，好叫约押流无辜人血的罪不归我和我的父家了。"},
{volumn:        11,chapter:         2,versenumber:        32,verse:"耶和华必使约押流人血的罪归到他自己的头上；因为他用刀杀了两个比他又义又好的人，就是以色列元帅尼珥的儿子押尼珥和犹大元帅益帖的儿子亚玛撒，我父亲大卫却不知道。"},
{volumn:        11,chapter:         2,versenumber:        33,verse:"故此，流这二人血的罪必归到约押和他后裔的头上，直到永远；惟有大卫和他的后裔，并他的家与国，必从耶和华那里得平安，直到永远。”"},
{volumn:        11,chapter:         2,versenumber:        34,verse:"于是耶何耶大的儿子比拿雅上去，将约押杀死，葬在旷野约押自己的坟墓（原文是房屋）里。"},
{volumn:        11,chapter:         2,versenumber:        35,verse:"王就立耶何耶大的儿子比拿雅作元帅，代替约押，又使祭司撒督代替亚比亚他。"},
{volumn:        11,chapter:         2,versenumber:        36,verse:"王差遣人将示每召来，对他说：“你要在耶路撒冷建造房屋居住，不可出来往别处去。"},
{volumn:        11,chapter:         2,versenumber:        37,verse:"你当确实地知道，你何日出来过汲沦溪，何日必死！你的罪（原文是血）必归到自己的头上。”"},
{volumn:        11,chapter:         2,versenumber:        38,verse:"示每对王说：“这话甚好！我主我王怎样说，仆人必怎样行。”于是示每多日住在耶路撒冷。"},
{volumn:        11,chapter:         2,versenumber:        39,verse:"过了三年，示每的两个仆人逃到迦特王玛迦的儿子亚吉那里去。有人告诉示每说：“你的仆人在迦特。”"},
{volumn:        11,chapter:         2,versenumber:        40,verse:"示每起来，备上驴，往迦特到亚吉那里去找他的仆人，就从迦特带他仆人回来。"},
{volumn:        11,chapter:         2,versenumber:        41,verse:"有人告诉所罗门说：“示每出耶路撒冷往迦特去，回来了。”"},
{volumn:        11,chapter:         2,versenumber:        42,verse:"王就差遣人将示每召了来，对他说：“我岂不是叫你指着耶和华起誓，并且警戒你说‘你当确实地知道，你哪日出来往别处去，那日必死’吗？你也对我说：‘这话甚好，我必听从。’"},
{volumn:        11,chapter:         2,versenumber:        43,verse:"现在你为何不遵守你指着耶和华起的誓和我所吩咐你的命令呢？”"},
{volumn:        11,chapter:         2,versenumber:        44,verse:"王又对示每说：“你向我父亲大卫所行的一切恶事，你自己心里也知道，所以耶和华必使你的罪恶归到自己的头上；"},
{volumn:        11,chapter:         2,versenumber:        45,verse:"惟有所罗门王必得福，并且大卫的国位必在耶和华面前坚定，直到永远。”"},
{volumn:        11,chapter:         2,versenumber:        46,verse:"于是王吩咐耶何耶大的儿子比拿雅，他就去杀死示每。这样，便坚定了所罗门的国位。"},
{volumn:        11,chapter:         3,versenumber:         1,verse:"所罗门与埃及王法老结亲，娶了法老的女儿为妻，接她进入大卫城，直等到造完了自己的宫和耶和华的殿，并耶路撒冷周围的城墙。"},
{volumn:        11,chapter:         3,versenumber:         2,verse:"当那些日子，百姓仍在邱坛献祭，因为还没有为耶和华的名建殿。"},
{volumn:        11,chapter:         3,versenumber:         3,verse:"所罗门爱耶和华，遵行他父亲大卫的律例，只是还在邱坛献祭烧香。"},
{volumn:        11,chapter:         3,versenumber:         4,verse:"所罗门王上基遍去献祭；因为在那里有极大（或译：出名）的邱坛，他在那坛上献一千牺牲作燔祭。"},
{volumn:        11,chapter:         3,versenumber:         5,verse:"在基遍，夜间梦中，耶和华向所罗门显现，对他说：“你愿我赐你什么？你可以求。”"},
{volumn:        11,chapter:         3,versenumber:         6,verse:"所罗门说：“你仆人我父亲大卫用诚实、公义、正直的心行在你面前，你就向他大施恩典，又为他存留大恩，赐他一个儿子坐在他的位上，正如今日一样。"},
{volumn:        11,chapter:         3,versenumber:         7,verse:"耶和华我的　神啊，如今你使仆人接续我父亲大卫作王；但我是幼童，不知道应当怎样出入。"},
{volumn:        11,chapter:         3,versenumber:         8,verse:"仆人住在你所拣选的民中，这民多得不可胜数。"},
{volumn:        11,chapter:         3,versenumber:         9,verse:"所以求你赐我智慧，可以判断你的民，能辨别是非。不然，谁能判断这众多的民呢？”"},
{volumn:        11,chapter:         3,versenumber:        10,verse:"所罗门因为求这事，就蒙主喜悦。"},
{volumn:        11,chapter:         3,versenumber:        11,verse:"神对他说：“你既然求这事，不为自己求寿、求富，也不求灭绝你仇敌的性命，单求智慧可以听讼，"},
{volumn:        11,chapter:         3,versenumber:        12,verse:"我就应允你所求的，赐你聪明智慧，甚至在你以前没有像你的，在你以后也没有像你的。"},
{volumn:        11,chapter:         3,versenumber:        13,verse:"你所没有求的，我也赐给你，就是富足、尊荣，使你在世的日子，列王中没有一个能比你的。"},
{volumn:        11,chapter:         3,versenumber:        14,verse:"你若效法你父亲大卫，遵行我的道，谨守我的律例、诫命，我必使你长寿。”"},
{volumn:        11,chapter:         3,versenumber:        15,verse:"所罗门醒了，不料是个梦。他就回到耶路撒冷，站在耶和华的约柜前，献燔祭和平安祭，又为他众臣仆设摆筵席。"},
{volumn:        11,chapter:         3,versenumber:        16,verse:"一日，有两个妓女来，站在王面前。"},
{volumn:        11,chapter:         3,versenumber:        17,verse:"一个说：“我主啊，我和这妇人同住一房；她在房中的时候，我生了一个男孩。"},
{volumn:        11,chapter:         3,versenumber:        18,verse:"我生孩子后第三日，这妇人也生了孩子。我们是同住的，除了我们二人之外，房中再没有别人。"},
{volumn:        11,chapter:         3,versenumber:        19,verse:"夜间，这妇人睡着的时候，压死了她的孩子。"},
{volumn:        11,chapter:         3,versenumber:        20,verse:"她半夜起来，趁我睡着，从我旁边把我的孩子抱去，放在她怀里，将她的死孩子放在我怀里。"},
{volumn:        11,chapter:         3,versenumber:        21,verse:"天要亮的时候，我起来要给我的孩子吃奶，不料，孩子死了；及至天亮，我细细地察看，不是我所生的孩子。”"},
{volumn:        11,chapter:         3,versenumber:        22,verse:"那妇人说：“不然，活孩子是我的，死孩子是你的。”这妇人说：“不然，死孩子是你的，活孩子是我的。”她们在王面前如此争论。"},
{volumn:        11,chapter:         3,versenumber:        23,verse:"王说：“这妇人说‘活孩子是我的，死孩子是你的’，那妇人说‘不然，死孩子是你的，活孩子是我的’”，"},
{volumn:        11,chapter:         3,versenumber:        24,verse:"就吩咐说：“拿刀来！”人就拿刀来。"},
{volumn:        11,chapter:         3,versenumber:        25,verse:"王说：“将活孩子劈成两半，一半给那妇人，一半给这妇人。”"},
{volumn:        11,chapter:         3,versenumber:        26,verse:"活孩子的母亲为自己的孩子心里急痛，就说：“求我主将活孩子给那妇人吧，万不可杀他！”那妇人说：“这孩子也不归我，也不归你，把他劈了吧！”"},
{volumn:        11,chapter:         3,versenumber:        27,verse:"王说：“将活孩子给这妇人，万不可杀他；这妇人实在是他的母亲。”"},
{volumn:        11,chapter:         3,versenumber:        28,verse:"以色列众人听见王这样判断，就都敬畏他；因为见他心里有　神的智慧，能以断案。"},
{volumn:        11,chapter:         4,versenumber:         1,verse:"所罗门作以色列众人的王。"},
{volumn:        11,chapter:         4,versenumber:         2,verse:"他的臣子记在下面：撒督的儿子亚撒利雅作祭司，"},
{volumn:        11,chapter:         4,versenumber:         3,verse:"示沙的两个儿子以利何烈、亚希亚作书记，亚希律的儿子约沙法作史官，"},
{volumn:        11,chapter:         4,versenumber:         4,verse:"耶何耶大的儿子比拿雅作元帅，撒督和亚比亚他作祭司长，"},
{volumn:        11,chapter:         4,versenumber:         5,verse:"拿单的儿子亚撒利雅作众吏长，王的朋友拿单的儿子撒布得作领袖，"},
{volumn:        11,chapter:         4,versenumber:         6,verse:"亚希煞作家宰，亚比大的儿子亚多尼兰掌管服苦的人。"},
{volumn:        11,chapter:         4,versenumber:         7,verse:"所罗门在以色列全地立了十二个官吏，使他们供给王和王家的食物，每年各人供给一月。"},
{volumn:        11,chapter:         4,versenumber:         8,verse:"他们的名字记在下面：在以法莲山地有便·户珥；"},
{volumn:        11,chapter:         4,versenumber:         9,verse:"在玛迦斯、沙宾、伯·示麦、以伦·伯·哈南有便·底甲；"},
{volumn:        11,chapter:         4,versenumber:        10,verse:"在亚鲁泊有便·希悉，他管理梭哥和希弗全地；"},
{volumn:        11,chapter:         4,versenumber:        11,verse:"在多珥山冈（或译：全境）有便·亚比拿达，他娶了所罗门的女儿她法为妻；"},
{volumn:        11,chapter:         4,versenumber:        12,verse:"在他纳和米吉多，并靠近撒拉他拿、耶斯列下边的伯·善全地，从伯·善到亚伯·米何拉直到约念之外，有亚希律的儿子巴拿；"},
{volumn:        11,chapter:         4,versenumber:        13,verse:"在基列的拉末有便·基别，他管理在基列的玛拿西子孙睚珥的城邑，巴珊的亚珥歌伯地的大城六十座，都有城墙和铜闩；"},
{volumn:        11,chapter:         4,versenumber:        14,verse:"在玛哈念有易多的儿子亚希拿达；"},
{volumn:        11,chapter:         4,versenumber:        15,verse:"在拿弗他利有亚希玛斯，他也娶了所罗门的一个女儿巴实抹为妻；"},
{volumn:        11,chapter:         4,versenumber:        16,verse:"在亚设和亚禄有户筛的儿子巴拿；"},
{volumn:        11,chapter:         4,versenumber:        17,verse:"在以萨迦有帕路亚的儿子约沙法；"},
{volumn:        11,chapter:         4,versenumber:        18,verse:"在便雅悯有以拉的儿子示每；"},
{volumn:        11,chapter:         4,versenumber:        19,verse:"在基列地，就是从前属亚摩利王西宏和巴珊王噩之地，有乌利的儿子基别一人管理。"},
{volumn:        11,chapter:         4,versenumber:        20,verse:"犹大人和以色列人如同海边的沙那样多，都吃喝快乐。"},
{volumn:        11,chapter:         4,versenumber:        21,verse:"所罗门统管诸国，从大河到非利士地，直到埃及的边界。所罗门在世的日子，这些国都进贡服侍他。"},
{volumn:        11,chapter:         4,versenumber:        22,verse:"所罗门每日所用的食物：细面三十歌珥，粗面六十歌珥，"},
{volumn:        11,chapter:         4,versenumber:        23,verse:"肥牛十只，草场的牛二十只，羊一百只，还有鹿、羚羊、狍子，并肥禽。"},
{volumn:        11,chapter:         4,versenumber:        24,verse:"所罗门管理大河西边的诸王，以及从提弗萨直到迦萨的全地，四境尽都平安。"},
{volumn:        11,chapter:         4,versenumber:        25,verse:"所罗门在世的日子，从但到别是巴的犹大人和以色列人都在自己的葡萄树下和无花果树下安然居住。"},
{volumn:        11,chapter:         4,versenumber:        26,verse:"所罗门有套车的马四万，还有马兵一万二千。"},
{volumn:        11,chapter:         4,versenumber:        27,verse:"那十二个官吏各按各月供给所罗门王，并一切与他同席之人的食物，一无所缺。"},
{volumn:        11,chapter:         4,versenumber:        28,verse:"众人各按各份，将养马与快马的大麦和干草送到官吏那里。"},
{volumn:        11,chapter:         4,versenumber:        29,verse:"神赐给所罗门极大的智慧聪明和广大的心，如同海沙不可测量。"},
{volumn:        11,chapter:         4,versenumber:        30,verse:"所罗门的智慧超过东方人和埃及人的一切智慧。"},
{volumn:        11,chapter:         4,versenumber:        31,verse:"他的智慧胜过万人，胜过以斯拉人以探，并玛曷的儿子希幔、甲各、达大的智慧。他的名声传扬在四围的列国。"},
{volumn:        11,chapter:         4,versenumber:        32,verse:"他作箴言三千句，诗歌一千零五首。"},
{volumn:        11,chapter:         4,versenumber:        33,verse:"他讲论草木，自黎巴嫩的香柏树直到墙上长的牛膝草，又讲论飞禽走兽、昆虫水族。"},
{volumn:        11,chapter:         4,versenumber:        34,verse:"天下列王听见所罗门的智慧，就都差人来听他的智慧话。"},
{volumn:        11,chapter:         5,versenumber:         1,verse:"推罗王希兰，平素爱大卫；他听见以色列人膏所罗门，接续他父亲作王，就差遣臣仆来见他。"},
{volumn:        11,chapter:         5,versenumber:         2,verse:"所罗门也差遣人去见希兰，说："},
{volumn:        11,chapter:         5,versenumber:         3,verse:"“你知道我父亲大卫因四围的争战，不能为耶和华他　神的名建殿，直等到耶和华使仇敌都服在他脚下。"},
{volumn:        11,chapter:         5,versenumber:         4,verse:"现在耶和华我的　神使我四围平安，没有仇敌，没有灾祸。"},
{volumn:        11,chapter:         5,versenumber:         5,verse:"我定意要为耶和华我　神的名建殿，是照耶和华应许我父亲大卫的话说：‘我必使你儿子接续你坐你的位，他必为我的名建殿。’"},
{volumn:        11,chapter:         5,versenumber:         6,verse:"所以求你吩咐你的仆人在黎巴嫩为我砍伐香柏木，我的仆人也必帮助他们，我必照你所定的，给你仆人的工价；因为你知道，在我们中间没有人像西顿人善于砍伐树木。”"},
{volumn:        11,chapter:         5,versenumber:         7,verse:"希兰听见所罗门的话，就甚喜悦，说：“今日应当称颂耶和华；因他赐给大卫一个有智慧的儿子，治理这众多的民。”"},
{volumn:        11,chapter:         5,versenumber:         8,verse:"希兰打发人去见所罗门，说：“你差遣人向我所提的那事，我都听见了；论到香柏木和松木，我必照你的心愿而行。"},
{volumn:        11,chapter:         5,versenumber:         9,verse:"我的仆人必将这木料从黎巴嫩运到海里，扎成筏子，浮海运到你所指定我的地方，在那里拆开，你就可以收取；你也要成全我的心愿，将食物给我的家。”"},
{volumn:        11,chapter:         5,versenumber:        10,verse:"于是希兰照着所罗门所要的，给他香柏木和松木；"},
{volumn:        11,chapter:         5,versenumber:        11,verse:"所罗门给希兰麦子二万歌珥，清油二十歌珥，作他家的食物。所罗门每年都是这样给希兰。"},
{volumn:        11,chapter:         5,versenumber:        12,verse:"耶和华照着所应许的赐智慧给所罗门。希兰与所罗门和好，彼此立约。"},
{volumn:        11,chapter:         5,versenumber:        13,verse:"所罗门王从以色列人中挑取服苦的人共有三万，"},
{volumn:        11,chapter:         5,versenumber:        14,verse:"派他们轮流每月一万人上黎巴嫩去；一个月在黎巴嫩，两个月在家里。亚多尼兰掌管他们。"},
{volumn:        11,chapter:         5,versenumber:        15,verse:"所罗门用七万扛抬的，八万在山上凿石头的。"},
{volumn:        11,chapter:         5,versenumber:        16,verse:"此外，所罗门用三千三百督工的，监管工人。"},
{volumn:        11,chapter:         5,versenumber:        17,verse:"王下令，人就凿出又大又宝贵的石头来，用以立殿的根基。"},
{volumn:        11,chapter:         5,versenumber:        18,verse:"所罗门的匠人和希兰的匠人，并迦巴勒人，都将石头凿好，预备木料和石头建殿。"},
{volumn:        11,chapter:         6,versenumber:         1,verse:"以色列人出埃及地后四百八十年，所罗门作以色列王第四年西弗月，就是二月，开工建造耶和华的殿。"},
{volumn:        11,chapter:         6,versenumber:         2,verse:"所罗门王为耶和华所建的殿，长六十肘，宽二十肘，高三十肘。"},
{volumn:        11,chapter:         6,versenumber:         3,verse:"殿前的廊子长二十肘，与殿的宽窄一样，阔十肘；"},
{volumn:        11,chapter:         6,versenumber:         4,verse:"又为殿做了严紧的窗棂。"},
{volumn:        11,chapter:         6,versenumber:         5,verse:"靠着殿墙，围着外殿内殿，造了三层旁屋；"},
{volumn:        11,chapter:         6,versenumber:         6,verse:"下层宽五肘，中层宽六肘，上层宽七肘。殿外旁屋的梁木搁在殿墙坎上，免得插入殿墙。"},
{volumn:        11,chapter:         6,versenumber:         7,verse:"建殿是用山中凿成的石头。建殿的时候，锤子、斧子，和别样铁器的响声都没有听见。"},
{volumn:        11,chapter:         6,versenumber:         8,verse:"在殿右边当中的旁屋有门，门内有旋螺的楼梯，可以上到第二层，从第二层可以上到第三层。"},
{volumn:        11,chapter:         6,versenumber:         9,verse:"所罗门建殿，安置香柏木的栋梁，又用香柏木板遮盖。"},
{volumn:        11,chapter:         6,versenumber:        10,verse:"靠着殿所造的旁屋，每层高五肘，香柏木的栋梁搁在殿墙坎上。"},
{volumn:        11,chapter:         6,versenumber:        11,verse:"耶和华的话临到所罗门说："},
{volumn:        11,chapter:         6,versenumber:        12,verse:"“论到你所建的这殿，你若遵行我的律例，谨守我的典章，遵从我的一切诫命，我必向你应验我所应许你父亲大卫的话。"},
{volumn:        11,chapter:         6,versenumber:        13,verse:"我必住在以色列人中间，并不丢弃我民以色列。”"},
{volumn:        11,chapter:         6,versenumber:        14,verse:"所罗门建造殿宇。"},
{volumn:        11,chapter:         6,versenumber:        15,verse:"殿里面用香柏木板贴墙，从地到棚顶都用木板遮蔽，又用松木板铺地。"},
{volumn:        11,chapter:         6,versenumber:        16,verse:"内殿，就是至圣所，长二十肘，从地到棚顶用香柏木板遮蔽（或译：隔断）。"},
{volumn:        11,chapter:         6,versenumber:        17,verse:"内殿前的外殿，长四十肘。"},
{volumn:        11,chapter:         6,versenumber:        18,verse:"殿里一点石头都不显露，一概用香柏木遮蔽；上面刻着野瓜和初开的花。"},
{volumn:        11,chapter:         6,versenumber:        19,verse:"殿里预备了内殿，好安放耶和华的约柜。"},
{volumn:        11,chapter:         6,versenumber:        20,verse:"内殿长二十肘，宽二十肘，高二十肘，墙面都贴上精金；又用香柏木做坛，包上精金。"},
{volumn:        11,chapter:         6,versenumber:        21,verse:"所罗门用精金贴了殿内的墙，又用金链子挂在内殿前门扇，用金包裹。"},
{volumn:        11,chapter:         6,versenumber:        22,verse:"全殿都贴上金子，直到贴完；内殿前的坛，也都用金包裹。"},
{volumn:        11,chapter:         6,versenumber:        23,verse:"他用橄榄木做两个基路伯，各高十肘，安在内殿。"},
{volumn:        11,chapter:         6,versenumber:        24,verse:"这一个基路伯有两个翅膀，各长五肘，从这翅膀尖到那翅膀尖共有十肘；"},
{volumn:        11,chapter:         6,versenumber:        25,verse:"那一个基路伯的两个翅膀也是十肘，两个基路伯的尺寸、形像都是一样。"},
{volumn:        11,chapter:         6,versenumber:        26,verse:"这基路伯高十肘，那基路伯也是如此。"},
{volumn:        11,chapter:         6,versenumber:        27,verse:"他将两个基路伯安在内殿里；基路伯的翅膀是张开的，这基路伯的一个翅膀挨着这边的墙，那基路伯的一个翅膀挨着那边的墙，里边的两个翅膀在殿中间彼此相接；"},
{volumn:        11,chapter:         6,versenumber:        28,verse:"又用金子包裹二基路伯。"},
{volumn:        11,chapter:         6,versenumber:        29,verse:"内殿、外殿周围的墙上都刻着基路伯、棕树，和初开的花。"},
{volumn:        11,chapter:         6,versenumber:        30,verse:"内殿、外殿的地板都贴上金子。"},
{volumn:        11,chapter:         6,versenumber:        31,verse:"又用橄榄木制造内殿的门扇、门楣、门框；门口有墙的五分之一。"},
{volumn:        11,chapter:         6,versenumber:        32,verse:"在橄榄木做的两门扇上刻着基路伯、棕树，和初开的花，都贴上金子。"},
{volumn:        11,chapter:         6,versenumber:        33,verse:"又用橄榄木制造外殿的门框，门口有墙的四分之一。"},
{volumn:        11,chapter:         6,versenumber:        34,verse:"用松木做门两扇。这扇分两扇，是折叠的；那扇分两扇，也是折叠的。"},
{volumn:        11,chapter:         6,versenumber:        35,verse:"上面刻着基路伯、棕树，和初开的花，都用金子贴了。"},
{volumn:        11,chapter:         6,versenumber:        36,verse:"他又用凿成的石头三层、香柏木一层建筑内院。"},
{volumn:        11,chapter:         6,versenumber:        37,verse:"所罗门在位第四年西弗月，立了耶和华殿的根基。"},
{volumn:        11,chapter:         6,versenumber:        38,verse:"到十一年布勒月，就是八月，殿和一切属殿的都按着样式造成。他建殿的工夫共有七年。"},
{volumn:        11,chapter:         7,versenumber:         1,verse:"所罗门为自己建造宫室，十三年方才造成；"},
{volumn:        11,chapter:         7,versenumber:         2,verse:"又建造黎巴嫩林宫，长一百肘，宽五十肘，高三十肘，有香柏木柱三（原文是四）行，柱上有香柏木柁梁。"},
{volumn:        11,chapter:         7,versenumber:         3,verse:"其上以香柏木为盖，每行柱子十五根，共有四十五根。"},
{volumn:        11,chapter:         7,versenumber:         4,verse:"有窗户三层，窗与窗相对。"},
{volumn:        11,chapter:         7,versenumber:         5,verse:"所有的门框都是厚木见方的，有窗户三层，窗与窗相对。"},
{volumn:        11,chapter:         7,versenumber:         6,verse:"并建造有柱子的廊子，长五十肘，宽三十肘；在这廊前又有廊子，廊外有柱子和台阶。"},
{volumn:        11,chapter:         7,versenumber:         7,verse:"又建造一廊，其中设立审判的座位，这廊从地到顶都用香柏木遮蔽。"},
{volumn:        11,chapter:         7,versenumber:         8,verse:"廊后院内有所罗门住的宫室；工作与这工作相同。所罗门又为所娶法老的女儿建造一宫，做法与这廊子一样。"},
{volumn:        11,chapter:         7,versenumber:         9,verse:"建造这一切所用的石头都是宝贵的，是按着尺寸凿成的，是用锯里外锯齐的；从根基直到檐石，从外头直到大院，都是如此。"},
{volumn:        11,chapter:         7,versenumber:        10,verse:"根基是宝贵的大石头，有长十肘的，有长八肘的；"},
{volumn:        11,chapter:         7,versenumber:        11,verse:"上面有香柏木和按着尺寸凿成宝贵的石头。"},
{volumn:        11,chapter:         7,versenumber:        12,verse:"大院周围有凿成的石头三层、香柏木一层，都照耶和华殿的内院和殿廊的样式。"},
{volumn:        11,chapter:         7,versenumber:        13,verse:"所罗门王差遣人往推罗去，将户兰召了来。"},
{volumn:        11,chapter:         7,versenumber:        14,verse:"他是拿弗他利支派中一个寡妇的儿子，他父亲是推罗人，作铜匠的。户兰满有智慧、聪明、技能，善于各样铜作。他来到所罗门王那里，做王一切所要做的。"},
{volumn:        11,chapter:         7,versenumber:        15,verse:"他制造两根铜柱，每根高十八肘，围十二肘；"},
{volumn:        11,chapter:         7,versenumber:        16,verse:"又用铜铸了两个柱顶安在柱上，各高五肘。"},
{volumn:        11,chapter:         7,versenumber:        17,verse:"柱顶上有装修的网子和拧成的链索，每顶七个。"},
{volumn:        11,chapter:         7,versenumber:        18,verse:"网子周围有两行石榴遮盖柱顶，两个柱顶都是如此。"},
{volumn:        11,chapter:         7,versenumber:        19,verse:"廊子的柱顶径四肘，刻着百合花。"},
{volumn:        11,chapter:         7,versenumber:        20,verse:"两柱顶的鼓肚上挨着网子，各有两行石榴环绕，两行共有二百。"},
{volumn:        11,chapter:         7,versenumber:        21,verse:"他将两根柱子立在殿廊前头：右边立一根，起名叫雅斤；左边立一根，起名叫波阿斯。"},
{volumn:        11,chapter:         7,versenumber:        22,verse:"在柱顶上刻着百合花。这样，造柱子的工就完毕了。"},
{volumn:        11,chapter:         7,versenumber:        23,verse:"他又铸一个铜海，样式是圆的，高五肘，径十肘，围三十肘。"},
{volumn:        11,chapter:         7,versenumber:        24,verse:"在海边之下，周围有野瓜的样式；每肘十瓜，共有两行，是铸海的时候铸上的。"},
{volumn:        11,chapter:         7,versenumber:        25,verse:"有十二只铜牛驮海：三只向北，三只向西，三只向南，三只向东；海在牛上，牛尾都向内。"},
{volumn:        11,chapter:         7,versenumber:        26,verse:"海厚一掌，边如杯边，又如百合花，可容二千罢特。"},
{volumn:        11,chapter:         7,versenumber:        27,verse:"他用铜制造十个盆座，每座长四肘，宽四肘，高三肘。"},
{volumn:        11,chapter:         7,versenumber:        28,verse:"座的造法是这样：四面都有心子，心子在边子当中，"},
{volumn:        11,chapter:         7,versenumber:        29,verse:"心子上有狮子和牛，并基路伯；边上有小座，狮子和牛以下有垂下的璎珞。"},
{volumn:        11,chapter:         7,versenumber:        30,verse:"每盆座有四个铜轮和铜轴。小座的四角上在盆以下，有铸成的盆架，其旁都有璎珞。"},
{volumn:        11,chapter:         7,versenumber:        31,verse:"小座高一肘，口是圆的，仿佛座的样式，径一肘半，在口上有雕工，心子是方的，不是圆的。"},
{volumn:        11,chapter:         7,versenumber:        32,verse:"四个轮子在心子以下，轮轴与座相连，每轮高一肘半。"},
{volumn:        11,chapter:         7,versenumber:        33,verse:"轮的样式如同车轮；轴、辋、辐、毂都是铸的。"},
{volumn:        11,chapter:         7,versenumber:        34,verse:"每座四角上都有盆架，是与座一同铸成的。"},
{volumn:        11,chapter:         7,versenumber:        35,verse:"座上有圆架，高半肘；座上有撑子和心子，是与座一同铸的。"},
{volumn:        11,chapter:         7,versenumber:        36,verse:"在撑子和心子上刻着基路伯、狮子，和棕树，周围有璎珞。"},
{volumn:        11,chapter:         7,versenumber:        37,verse:"十个盆座都是这样，铸法、尺寸、样式相同。"},
{volumn:        11,chapter:         7,versenumber:        38,verse:"又用铜制造十个盆，每盆可容四十罢特。盆径四肘，在那十座上，每座安设一盆。"},
{volumn:        11,chapter:         7,versenumber:        39,verse:"五个安在殿门的右边，五个放在殿门的左边；又将海放在殿门的右旁，就是南边。"},
{volumn:        11,chapter:         7,versenumber:        40,verse:"户兰又造了盆、铲子，和盘子。这样，他为所罗门王做完了耶和华殿的一切工。"},
{volumn:        11,chapter:         7,versenumber:        41,verse:"所造的就是：两根柱子和柱上两个如球的顶；并两个盖柱顶的网子；"},
{volumn:        11,chapter:         7,versenumber:        42,verse:"和四百石榴，安在两个网子上，每网两行，盖着两个柱上如球的顶；"},
{volumn:        11,chapter:         7,versenumber:        43,verse:"十个座和其上的十个盆；"},
{volumn:        11,chapter:         7,versenumber:        44,verse:"海和海下的十二只牛；"},
{volumn:        11,chapter:         7,versenumber:        45,verse:"盆、铲子、盘子。这一切都是户兰给所罗门王用光亮的铜为耶和华的殿造成的，"},
{volumn:        11,chapter:         7,versenumber:        46,verse:"是遵王命在约旦平原、疏割和撒拉但中间藉胶泥铸成的。"},
{volumn:        11,chapter:         7,versenumber:        47,verse:"这一切所罗门都没有过秤；因为甚多，铜的轻重也无法可查。"},
{volumn:        11,chapter:         7,versenumber:        48,verse:"所罗门又造耶和华殿里的金坛和陈设饼的金桌子；"},
{volumn:        11,chapter:         7,versenumber:        49,verse:"内殿前的精金灯台：右边五个，左边五个，并其上的金花、灯盏、蜡剪，"},
{volumn:        11,chapter:         7,versenumber:        50,verse:"与精金的杯、盘、镊子、调羹、火鼎，以及至圣所、内殿的门枢，和外殿的门枢。"},
{volumn:        11,chapter:         7,versenumber:        51,verse:"所罗门王做完了耶和华殿的一切工，就把他父大卫分别为圣的金银和器皿都带来放在耶和华殿的府库里。"},
{volumn:        11,chapter:         8,versenumber:         1,verse:"那时，所罗门将以色列的长老和各支派的首领，并以色列的族长，招聚到耶路撒冷，要把耶和华的约柜从大卫城就是锡安运上来。"},
{volumn:        11,chapter:         8,versenumber:         2,verse:"以他念月，就是七月，在节前，以色列人都聚集到所罗门王那里。"},
{volumn:        11,chapter:         8,versenumber:         3,verse:"以色列长老来到，祭司便抬起约柜，"},
{volumn:        11,chapter:         8,versenumber:         4,verse:"祭司和利未人将耶和华的约柜运上来，又将会幕和会幕的一切圣器具都带上来。"},
{volumn:        11,chapter:         8,versenumber:         5,verse:"所罗门王和聚集到他那里的以色列全会众，一同在约柜前献牛羊为祭，多得不可胜数。"},
{volumn:        11,chapter:         8,versenumber:         6,verse:"祭司将耶和华的约柜抬进内殿，就是至圣所，放在两个基路伯的翅膀底下。"},
{volumn:        11,chapter:         8,versenumber:         7,verse:"基路伯张着翅膀在约柜之上，遮掩约柜和抬柜的杠。"},
{volumn:        11,chapter:         8,versenumber:         8,verse:"这杠甚长，杠头在内殿前的圣所可以看见，在殿外却不能看见，直到如今还在那里。"},
{volumn:        11,chapter:         8,versenumber:         9,verse:"约柜里惟有两块石版，就是以色列人出埃及地后，耶和华与他们立约的时候摩西在何烈山所放的。除此以外，并无别物。"},
{volumn:        11,chapter:         8,versenumber:        10,verse:"祭司从圣所出来的时候，有云充满耶和华的殿；"},
{volumn:        11,chapter:         8,versenumber:        11,verse:"甚至祭司不能站立供职，因为耶和华的荣光充满了殿。"},
{volumn:        11,chapter:         8,versenumber:        12,verse:"那时所罗门说：“耶和华曾说，他必住在幽暗之处。"},
{volumn:        11,chapter:         8,versenumber:        13,verse:"我已经建造殿宇作你的居所，为你永远的住处。”"},
{volumn:        11,chapter:         8,versenumber:        14,verse:"王转脸为以色列会众祝福，以色列会众就都站立。"},
{volumn:        11,chapter:         8,versenumber:        15,verse:"所罗门说：“耶和华以色列的　神是应当称颂的！因他亲口向我父大卫所应许的，也亲手成就了。"},
{volumn:        11,chapter:         8,versenumber:        16,verse:"他说：‘自从我领我民以色列出埃及以来，我未曾在以色列各支派中选择一城，建造殿宇为我名的居所，但拣选大卫治理我民以色列。’”"},
{volumn:        11,chapter:         8,versenumber:        17,verse:"所罗门说：“我父大卫曾立意，要为耶和华以色列　神的名建殿。"},
{volumn:        11,chapter:         8,versenumber:        18,verse:"耶和华却对我父大卫说：‘你立意为我的名建殿，这意思甚好。"},
{volumn:        11,chapter:         8,versenumber:        19,verse:"只是你不可建殿，惟你所生的儿子必为我名建殿。’"},
{volumn:        11,chapter:         8,versenumber:        20,verse:"现在耶和华成就了他所应许的话，使我接续我父大卫坐以色列的国位，又为耶和华以色列　神的名建造了殿。"},
{volumn:        11,chapter:         8,versenumber:        21,verse:"我也在其中为约柜预备一处。约柜内有耶和华的约，就是他领我们列祖出埃及地的时候，与他们所立的约。”"},
{volumn:        11,chapter:         8,versenumber:        22,verse:"所罗门当着以色列会众，站在耶和华的坛前，向天举手说："},
{volumn:        11,chapter:         8,versenumber:        23,verse:"“耶和华以色列的　神啊，天上地下没有神可比你的！你向那尽心行在你面前的仆人守约施慈爱；"},
{volumn:        11,chapter:         8,versenumber:        24,verse:"向你仆人我父大卫所应许的话现在应验了。你亲口应许，亲手成就，正如今日一样。"},
{volumn:        11,chapter:         8,versenumber:        25,verse:"耶和华以色列的　神啊，你所应许你仆人我父大卫的话说：‘你的子孙若谨慎自己的行为，在我面前行事像你所行的一样，就不断人坐以色列的国位。’现在求你应验这话。"},
{volumn:        11,chapter:         8,versenumber:        26,verse:"以色列的　神啊，求你成就向你仆人我父大卫所应许的话。"},
{volumn:        11,chapter:         8,versenumber:        27,verse:"“　神果真住在地上吗？看哪，天和天上的天尚且不足你居住的，何况我所建的这殿呢？"},
{volumn:        11,chapter:         8,versenumber:        28,verse:"惟求耶和华我的　神垂顾仆人的祷告祈求，俯听仆人今日在你面前的祈祷呼吁。"},
{volumn:        11,chapter:         8,versenumber:        29,verse:"愿你昼夜看顾这殿，就是你应许立为你名的居所；求你垂听仆人向此处祷告的话。"},
{volumn:        11,chapter:         8,versenumber:        30,verse:"你仆人和你民以色列向此处祈祷的时候，求你在天上你的居所垂听，垂听而赦免。"},
{volumn:        11,chapter:         8,versenumber:        31,verse:"“人若得罪邻舍，有人叫他起誓，他来到这殿在你的坛前起誓，"},
{volumn:        11,chapter:         8,versenumber:        32,verse:"求你在天上垂听，判断你的仆人：定恶人有罪，照他所行的报应在他头上；定义人有理，照他的义赏赐他。"},
{volumn:        11,chapter:         8,versenumber:        33,verse:"“你的民以色列若得罪你，败在仇敌面前，又归向你，承认你的名，在这殿里祈求祷告，"},
{volumn:        11,chapter:         8,versenumber:        34,verse:"求你在天上垂听，赦免你民以色列的罪，使他们归回你赐给他们列祖之地。"},
{volumn:        11,chapter:         8,versenumber:        35,verse:"“你的民因得罪你，你惩罚他们，使天闭塞不下雨；他们若向此处祷告，承认你的名，离开他们的罪，"},
{volumn:        11,chapter:         8,versenumber:        36,verse:"求你在天上垂听，赦免你仆人以色列民的罪，将当行的善道指教他们，且降雨在你的地，就是你赐给你民为业之地。"},
{volumn:        11,chapter:         8,versenumber:        37,verse:"“国中若有饥荒、瘟疫、旱风、霉烂、蝗虫、蚂蚱，或有仇敌犯境围困城邑，无论遭遇什么灾祸疾病，"},
{volumn:        11,chapter:         8,versenumber:        38,verse:"你的民以色列，或是众人，或是一人，自觉有罪（原文是灾），向这殿举手，无论祈求什么，祷告什么，"},
{volumn:        11,chapter:         8,versenumber:        39,verse:"求你在天上你的居所垂听赦免。你是知道人心的，要照各人所行的待他们（惟有你知道世人的心），"},
{volumn:        11,chapter:         8,versenumber:        40,verse:"使他们在你赐给我们列祖之地上一生一世敬畏你。"},
{volumn:        11,chapter:         8,versenumber:        41,verse:"“论到不属你民以色列的外邦人，为你名从远方而来，"},
{volumn:        11,chapter:         8,versenumber:        42,verse:"（他们听人论说你的大名和大能的手，并伸出来的膀臂）向这殿祷告，"},
{volumn:        11,chapter:         8,versenumber:        43,verse:"求你在天上你的居所垂听，照着外邦人所祈求的而行，使天下万民都认识你的名，敬畏你像你的民以色列一样；又使他们知道我建造的这殿是称为你名下的。"},
{volumn:        11,chapter:         8,versenumber:        44,verse:"“你的民若奉你的差遣，无论往何处去与仇敌争战，向耶和华所选择的城与我为你名所建造的殿祷告，"},
{volumn:        11,chapter:         8,versenumber:        45,verse:"求你在天上垂听他们的祷告祈求，使他们得胜。"},
{volumn:        11,chapter:         8,versenumber:        46,verse:"“你的民若得罪你（世上没有不犯罪的人），你向他们发怒，将他们交给仇敌掳到仇敌之地，或远或近，"},
{volumn:        11,chapter:         8,versenumber:        47,verse:"他们若在掳到之地想起罪来，回心转意，恳求你说：‘我们有罪了，我们悖逆了，我们作恶了’；"},
{volumn:        11,chapter:         8,versenumber:        48,verse:"他们若在掳到之地尽心尽性归服你，又向自己的地，就是你赐给他们列祖之地和你所选择的城，并我为你名所建造的殿祷告，"},
{volumn:        11,chapter:         8,versenumber:        49,verse:"求你在天上你的居所垂听他们的祷告祈求，为他们伸冤；"},
{volumn:        11,chapter:         8,versenumber:        50,verse:"饶恕得罪你的民，赦免他们的一切过犯，使他们在掳他们的人面前蒙怜恤。"},
{volumn:        11,chapter:         8,versenumber:        51,verse:"因为他们是你的子民，你的产业，是你从埃及领出来脱离铁炉的。"},
{volumn:        11,chapter:         8,versenumber:        52,verse:"愿你的眼目看顾仆人，听你民以色列的祈求，无论何时向你祈求，愿你垂听。"},
{volumn:        11,chapter:         8,versenumber:        53,verse:"主耶和华啊，你将他们从地上的万民中分别出来作你的产业，是照你领我们列祖出埃及的时候，藉你仆人摩西所应许的话。”"},
{volumn:        11,chapter:         8,versenumber:        54,verse:"所罗门在耶和华的坛前屈膝跪着，向天举手，在耶和华面前祷告祈求已毕，就起来，"},
{volumn:        11,chapter:         8,versenumber:        55,verse:"站着，大声为以色列全会众祝福，说："},
{volumn:        11,chapter:         8,versenumber:        56,verse:"“耶和华是应当称颂的！因为他照着一切所应许的赐平安给他的民以色列人，凡藉他仆人摩西应许赐福的话，一句都没有落空。"},
{volumn:        11,chapter:         8,versenumber:        57,verse:"愿耶和华我们的　神与我们同在，像与我们列祖同在一样，不撇下我们，不丢弃我们，"},
{volumn:        11,chapter:         8,versenumber:        58,verse:"使我们的心归向他，遵行他的道，谨守他吩咐我们列祖的诫命、律例、典章。"},
{volumn:        11,chapter:         8,versenumber:        59,verse:"我在耶和华面前祈求的这些话，愿耶和华我们的　神昼夜垂念，每日为他仆人与他民以色列伸冤，"},
{volumn:        11,chapter:         8,versenumber:        60,verse:"使地上的万民都知道惟独耶和华是　神，并无别神。"},
{volumn:        11,chapter:         8,versenumber:        61,verse:"所以你们当向耶和华我们的　神存诚实的心，遵行他的律例，谨守他的诫命，至终如今日一样。”"},
{volumn:        11,chapter:         8,versenumber:        62,verse:"王和以色列众民一同在耶和华面前献祭。"},
{volumn:        11,chapter:         8,versenumber:        63,verse:"所罗门向耶和华献平安祭，用牛二万二千，羊十二万。这样，王和以色列众民为耶和华的殿行奉献之礼。"},
{volumn:        11,chapter:         8,versenumber:        64,verse:"当日，王因耶和华殿前的铜坛太小，容不下燔祭、素祭，和平安祭牲的脂油，便将耶和华殿前院子当中分别为圣，在那里献燔祭、素祭，和平安祭牲的脂油。"},
{volumn:        11,chapter:         8,versenumber:        65,verse:"那时，所罗门和以色列众人，就是从哈马口直到埃及小河所有的以色列人，都聚集成为大会，在耶和华我们的　神面前守节七日又七日，共十四日。"},
{volumn:        11,chapter:         8,versenumber:        66,verse:"第八日，王遣散众民；他们都为王祝福。因见耶和华向他仆人大卫和他民以色列所施的一切恩惠，就都心中喜乐，各归各家去了。"},
{volumn:        11,chapter:         9,versenumber:         1,verse:"所罗门建造耶和华殿和王宫，并一切所愿意建造的都完毕了，"},
{volumn:        11,chapter:         9,versenumber:         2,verse:"耶和华就二次向所罗门显现，如先前在基遍向他显现一样，"},
{volumn:        11,chapter:         9,versenumber:         3,verse:"对他说：“你向我所祷告祈求的，我都应允了。我已将你所建的这殿分别为圣，使我的名永远在其中；我的眼、我的心也必常在那里。"},
{volumn:        11,chapter:         9,versenumber:         4,verse:"你若效法你父大卫，存诚实正直的心行在我面前，遵行我一切所吩咐你的，谨守我的律例典章，"},
{volumn:        11,chapter:         9,versenumber:         5,verse:"我就必坚固你的国位在以色列中，直到永远，正如我应许你父大卫说：‘你的子孙必不断人坐以色列的国位。’"},
{volumn:        11,chapter:         9,versenumber:         6,verse:"倘若你们和你们的子孙转去不跟从我，不守我指示你们的诫命律例，去侍奉敬拜别神，"},
{volumn:        11,chapter:         9,versenumber:         7,verse:"我就必将以色列人从我赐给他们的地上剪除，并且我为己名所分别为圣的殿也必舍弃不顾，使以色列人在万民中作笑谈，被讥诮。"},
{volumn:        11,chapter:         9,versenumber:         8,verse:"这殿虽然甚高，将来经过的人必惊讶、嗤笑，说：‘耶和华为何向这地和这殿如此行呢？’"},
{volumn:        11,chapter:         9,versenumber:         9,verse:"人必回答说：‘是因此地的人离弃领他们列祖出埃及地之耶和华他们的　神，去亲近别神，侍奉敬拜他，所以耶和华使这一切灾祸临到他们。’”"},
{volumn:        11,chapter:         9,versenumber:        10,verse:"所罗门建造耶和华殿和王宫，这两所二十年才完毕了。"},
{volumn:        11,chapter:         9,versenumber:        11,verse:"推罗王希兰曾照所罗门所要的，资助他香柏木、松木，和金子；所罗门王就把加利利地的二十座城给了希兰。"},
{volumn:        11,chapter:         9,versenumber:        12,verse:"希兰从推罗出来，察看所罗门给他的城邑，就不喜悦，"},
{volumn:        11,chapter:         9,versenumber:        13,verse:"说：“我兄啊，你给我的是什么城邑呢？”他就给这城邑之地起名叫迦步勒，直到今日。"},
{volumn:        11,chapter:         9,versenumber:        14,verse:"希兰给所罗门一百二十他连得金子。"},
{volumn:        11,chapter:         9,versenumber:        15,verse:"所罗门王挑取服苦的人，是为建造耶和华的殿、自己的宫、米罗、耶路撒冷的城墙、夏琐、米吉多，并基色。"},
{volumn:        11,chapter:         9,versenumber:        16,verse:"先前埃及王法老上来攻取基色，用火焚烧，杀了城内居住的迦南人，将城赐给他女儿所罗门的妻作妆奁。"},
{volumn:        11,chapter:         9,versenumber:        17,verse:"所罗门建造基色、下伯·和仑、"},
{volumn:        11,chapter:         9,versenumber:        18,verse:"巴拉，并国中旷野里的达莫，"},
{volumn:        11,chapter:         9,versenumber:        19,verse:"又建造所有的积货城，并屯车和马兵的城，与耶路撒冷、黎巴嫩，以及自己治理的全国中所愿建造的。"},
{volumn:        11,chapter:         9,versenumber:        20,verse:"至于国中所剩下不属以色列人的亚摩利人、赫人、比利洗人、希未人、耶布斯人，"},
{volumn:        11,chapter:         9,versenumber:        21,verse:"就是以色列人不能灭尽的，所罗门挑取他们的后裔作服苦的奴仆，直到今日。"},
{volumn:        11,chapter:         9,versenumber:        22,verse:"惟有以色列人，所罗门不使他们作奴仆，乃是作他的战士、臣仆、统领、军长、车兵长、马兵长。"},
{volumn:        11,chapter:         9,versenumber:        23,verse:"所罗门有五百五十督工的，监管工人。"},
{volumn:        11,chapter:         9,versenumber:        24,verse:"法老的女儿从大卫城搬到所罗门为她建造的宫里。那时，所罗门才建造米罗。"},
{volumn:        11,chapter:         9,versenumber:        25,verse:"所罗门每年三次在他为耶和华所筑的坛上献燔祭和平安祭，又在耶和华面前的坛上烧香。这样，他建造殿的工程完毕了。"},
{volumn:        11,chapter:         9,versenumber:        26,verse:"所罗门王在以东地红海边，靠近以禄的以旬·迦别制造船只。"},
{volumn:        11,chapter:         9,versenumber:        27,verse:"希兰差遣他的仆人，就是熟悉泛海的船家，与所罗门的仆人一同坐船航海。"},
{volumn:        11,chapter:         9,versenumber:        28,verse:"他们到了俄斐，从那里得了四百二十他连得金子，运到所罗门王那里。"},
{volumn:        11,chapter:        10,versenumber:         1,verse:"示巴女王听见所罗门因耶和华之名所得的名声，就来要用难解的话试问所罗门。"},
{volumn:        11,chapter:        10,versenumber:         2,verse:"跟随她到耶路撒冷的人甚多，又有骆驼驮着香料、宝石，和许多金子。她来见了所罗门王，就把心里所有的对所罗门都说出来。"},
{volumn:        11,chapter:        10,versenumber:         3,verse:"所罗门王将她所问的都答上了，没有一句不明白、不能答的。"},
{volumn:        11,chapter:        10,versenumber:         4,verse:"示巴女王见所罗门大有智慧，和他所建造的宫室，"},
{volumn:        11,chapter:        10,versenumber:         5,verse:"席上的珍馐美味，群臣分列而坐，仆人两旁侍立，以及他们的衣服装饰和酒政的衣服装饰，又见他上耶和华殿的台阶（或译：他在耶和华殿里所献的燔祭），就诧异得神不守舍；"},
{volumn:        11,chapter:        10,versenumber:         6,verse:"对王说：“我在本国里所听见论到你的事和你的智慧实在是真的！"},
{volumn:        11,chapter:        10,versenumber:         7,verse:"我先不信那些话，及至我来亲眼见了才知道人所告诉我的还不到一半。你的智慧和你的福分越过我所听见的风声。"},
{volumn:        11,chapter:        10,versenumber:         8,verse:"你的臣子、你的仆人常侍立在你面前听你智慧的话是有福的！"},
{volumn:        11,chapter:        10,versenumber:         9,verse:"耶和华你的　神是应当称颂的！他喜悦你，使你坐以色列的国位；因为他永远爱以色列，所以立你作王，使你秉公行义。”"},
{volumn:        11,chapter:        10,versenumber:        10,verse:"于是，示巴女王将一百二十他连得金子和宝石，与极多的香料，送给所罗门王。她送给王的香料，以后奉来的不再有这样多。"},
{volumn:        11,chapter:        10,versenumber:        11,verse:"希兰的船只从俄斐运了金子来，又从俄斐运了许多檀香木（或译：乌木；下同）和宝石来。"},
{volumn:        11,chapter:        10,versenumber:        12,verse:"王用檀香木为耶和华殿和王宫做栏杆，又为歌唱的人做琴瑟。以后再没有这样的檀香木进国来，也没有人看见过，直到如今。"},
{volumn:        11,chapter:        10,versenumber:        13,verse:"示巴女王一切所要所求的，所罗门王都送给她，另外照自己的厚意馈送她。于是女王和她臣仆转回本国去了。"},
{volumn:        11,chapter:        10,versenumber:        14,verse:"所罗门每年所得的金子共有六百六十六他连得。"},
{volumn:        11,chapter:        10,versenumber:        15,verse:"另外还有商人和杂族（杂族在历代下九章十四节是阿拉伯）的诸王，与国中的省长所进的金子。"},
{volumn:        11,chapter:        10,versenumber:        16,verse:"所罗门王用锤出来的金子打成挡牌二百面，每面用金子六百舍客勒；"},
{volumn:        11,chapter:        10,versenumber:        17,verse:"又用锤出来的金子打成盾牌三百面，每面用金子三弥那，都放在黎巴嫩林宫里。"},
{volumn:        11,chapter:        10,versenumber:        18,verse:"王用象牙制造一个宝座，用精金包裹。"},
{volumn:        11,chapter:        10,versenumber:        19,verse:"宝座有六层台阶，座的后背是圆的，两旁有扶手，靠近扶手有两个狮子站立。"},
{volumn:        11,chapter:        10,versenumber:        20,verse:"六层台阶上有十二个狮子站立，每层有两个：左边一个，右边一个；在列国中没有这样做的。"},
{volumn:        11,chapter:        10,versenumber:        21,verse:"所罗门王一切的饮器都是金子的。黎巴嫩林宫里的一切器皿都是精金的。所罗门年间，银子算不了什么。"},
{volumn:        11,chapter:        10,versenumber:        22,verse:"因为王有他施船只与希兰的船只一同航海，三年一次，装载金银、象牙、猿猴、孔雀回来。"},
{volumn:        11,chapter:        10,versenumber:        23,verse:"所罗门王的财宝与智慧胜过天下的列王。"},
{volumn:        11,chapter:        10,versenumber:        24,verse:"普天下的王都求见所罗门，要听　神赐给他智慧的话。"},
{volumn:        11,chapter:        10,versenumber:        25,verse:"他们各带贡物，就是金器、银器、衣服、军械、香料、骡马，每年有一定之例。"},
{volumn:        11,chapter:        10,versenumber:        26,verse:"所罗门聚集战车马兵，有战车一千四百辆，马兵一万二千名，安置在屯车的城邑和耶路撒冷，就是王那里。"},
{volumn:        11,chapter:        10,versenumber:        27,verse:"王在耶路撒冷使银子多如石头，香柏木多如高原的桑树。"},
{volumn:        11,chapter:        10,versenumber:        28,verse:"所罗门的马是从埃及带来的，是王的商人一群一群按着定价买来的。"},
{volumn:        11,chapter:        10,versenumber:        29,verse:"从埃及买来的车，每辆价银六百舍客勒，马每匹一百五十舍客勒。赫人诸王和亚兰诸王所买的车马，也是按这价值经他们手买来的。"},
{volumn:        11,chapter:        11,versenumber:         1,verse:"所罗门王在法老的女儿之外，又宠爱许多外邦女子，就是摩押女子、亚扪女子、以东女子、西顿女子、赫人女子。"},
{volumn:        11,chapter:        11,versenumber:         2,verse:"论到这些国的人，耶和华曾晓谕以色列人说：“你们不可与她们往来相通，因为她们必诱惑你们的心去随从她们的神。”所罗门却恋爱这些女子。"},
{volumn:        11,chapter:        11,versenumber:         3,verse:"所罗门有妃七百，都是公主；还有嫔三百。这些妃嫔诱惑他的心。"},
{volumn:        11,chapter:        11,versenumber:         4,verse:"所罗门年老的时候，他的妃嫔诱惑他的心去随从别神，不效法他父亲大卫诚诚实实地顺服耶和华他的　神。"},
{volumn:        11,chapter:        11,versenumber:         5,verse:"因为所罗门随从西顿人的女神亚斯他录和亚扪人可憎的神米勒公。"},
{volumn:        11,chapter:        11,versenumber:         6,verse:"所罗门行耶和华眼中看为恶的事，不效法他父亲大卫专心顺从耶和华。"},
{volumn:        11,chapter:        11,versenumber:         7,verse:"所罗门为摩押可憎的神基抹和亚扪人可憎的神摩洛，在耶路撒冷对面的山上建筑邱坛。"},
{volumn:        11,chapter:        11,versenumber:         8,verse:"他为那些向自己的神烧香献祭的外邦女子，就是他娶来的妃嫔也是这样行。"},
{volumn:        11,chapter:        11,versenumber:         9,verse:"耶和华向所罗门发怒，因为他的心偏离向他两次显现的耶和华以色列的　神。"},
{volumn:        11,chapter:        11,versenumber:        10,verse:"耶和华曾吩咐他不可随从别神，他却没有遵守耶和华所吩咐的。"},
{volumn:        11,chapter:        11,versenumber:        11,verse:"所以耶和华对他说：“你既行了这事，不遵守我所吩咐你守的约和律例，我必将你的国夺回，赐给你的臣子。"},
{volumn:        11,chapter:        11,versenumber:        12,verse:"然而，因你父亲大卫的缘故，我不在你活着的日子行这事，必从你儿子的手中将国夺回。"},
{volumn:        11,chapter:        11,versenumber:        13,verse:"只是我不将全国夺回，要因我仆人大卫和我所选择的耶路撒冷，还留一支派给你的儿子。”"},
{volumn:        11,chapter:        11,versenumber:        14,verse:"耶和华使以东人哈达兴起，作所罗门的敌人；他是以东王的后裔。"},
{volumn:        11,chapter:        11,versenumber:        15,verse:"先前大卫攻击以东，元帅约押上去葬埋阵亡的人，将以东的男丁都杀了。"},
{volumn:        11,chapter:        11,versenumber:        16,verse:"约押和以色列众人在以东住了六个月，直到将以东的男丁尽都剪除。"},
{volumn:        11,chapter:        11,versenumber:        17,verse:"那时哈达还是幼童；他和他父亲的臣仆，几个以东人逃往埃及。"},
{volumn:        11,chapter:        11,versenumber:        18,verse:"他们从米甸起行，到了巴兰；从巴兰带着几个人来到埃及见埃及王法老；法老为他派定粮食，又给他房屋田地。"},
{volumn:        11,chapter:        11,versenumber:        19,verse:"哈达在法老面前大蒙恩惠，以致法老将王后答比匿的妹子赐他为妻。"},
{volumn:        11,chapter:        11,versenumber:        20,verse:"答比匿的妹子给哈达生了一个儿子，名叫基努拔。答比匿使基努拔在法老的宫里断奶，基努拔就与法老的众子一同住在法老的宫里。"},
{volumn:        11,chapter:        11,versenumber:        21,verse:"哈达在埃及听见大卫与他列祖同睡，元帅约押也死了，就对法老说：“求王容我回本国去。”"},
{volumn:        11,chapter:        11,versenumber:        22,verse:"法老对他说：“你在我这里有什么缺乏，你竟要回你本国去呢？”他回答说：“我没有缺乏什么，只是求王容我回去。”"},
{volumn:        11,chapter:        11,versenumber:        23,verse:"神又使以利亚大的儿子利逊兴起，作所罗门的敌人。他先前逃避主人琐巴王哈大底谢。"},
{volumn:        11,chapter:        11,versenumber:        24,verse:"大卫击杀琐巴人的时候，利逊招聚了一群人，自己作他们的头目，往大马士革居住，在那里作王。"},
{volumn:        11,chapter:        11,versenumber:        25,verse:"所罗门活着的时候，哈达为患之外，利逊也作以色列的敌人。他恨恶以色列人，且作了亚兰人的王。"},
{volumn:        11,chapter:        11,versenumber:        26,verse:"所罗门的臣仆、尼八的儿子耶罗波安也举手攻击王。他是以法莲支派的洗利达人，他母亲是寡妇，名叫洗鲁阿。"},
{volumn:        11,chapter:        11,versenumber:        27,verse:"他举手攻击王的缘故，乃由先前所罗门建造米罗，修补他父亲大卫城的破口。"},
{volumn:        11,chapter:        11,versenumber:        28,verse:"耶罗波安是大有才能的人。所罗门见这少年人殷勤，就派他监管约瑟家的一切工程。"},
{volumn:        11,chapter:        11,versenumber:        29,verse:"一日，耶罗波安出了耶路撒冷，示罗人先知亚希雅在路上遇见他；亚希雅身上穿着一件新衣。他们二人在田野，以外并无别人。"},
{volumn:        11,chapter:        11,versenumber:        30,verse:"亚希雅将自己穿的那件新衣撕成十二片，"},
{volumn:        11,chapter:        11,versenumber:        31,verse:"对耶罗波安说：“你可以拿十片。耶和华以色列的　神如此说：‘我必将国从所罗门手里夺回，将十个支派赐给你。（"},
{volumn:        11,chapter:        11,versenumber:        32,verse:"我因仆人大卫和我在以色列众支派中所选择的耶路撒冷城的缘故，仍给所罗门留一个支派。）"},
{volumn:        11,chapter:        11,versenumber:        33,verse:"因为他离弃我，敬拜西顿人的女神亚斯他录、摩押的神基抹，和亚扪人的神米勒公，没有遵从我的道，行我眼中看为正的事，守我的律例典章，像他父亲大卫一样。"},
{volumn:        11,chapter:        11,versenumber:        34,verse:"但我不从他手里将全国夺回；使他终身为君，是因我所拣选的仆人大卫谨守我的诫命律例。"},
{volumn:        11,chapter:        11,versenumber:        35,verse:"我必从他儿子的手里将国夺回，以十个支派赐给你，"},
{volumn:        11,chapter:        11,versenumber:        36,verse:"还留一个支派给他的儿子，使我仆人大卫在我所选择立我名的耶路撒冷城里，在我面前长有灯光。"},
{volumn:        11,chapter:        11,versenumber:        37,verse:"我必拣选你，使你照心里一切所愿的，作王治理以色列。"},
{volumn:        11,chapter:        11,versenumber:        38,verse:"你若听从我一切所吩咐你的，遵行我的道，行我眼中看为正的事，谨守我的律例诫命，像我仆人大卫所行的，我就与你同在，为你立坚固的家，像我为大卫所立的一样，将以色列人赐给你。"},
{volumn:        11,chapter:        11,versenumber:        39,verse:"我必因所罗门所行的使大卫后裔受患难，但不至于永远。’”"},
{volumn:        11,chapter:        11,versenumber:        40,verse:"所罗门因此想要杀耶罗波安。耶罗波安却起身逃往埃及；到了埃及王示撒那里，就住在埃及，直到所罗门死了。"},
{volumn:        11,chapter:        11,versenumber:        41,verse:"所罗门其余的事，凡他所行的和他的智慧都写在所罗门记上。"},
{volumn:        11,chapter:        11,versenumber:        42,verse:"所罗门在耶路撒冷作以色列众人的王共四十年。"},
{volumn:        11,chapter:        11,versenumber:        43,verse:"所罗门与他列祖同睡，葬在他父亲大卫的城里。他儿子罗波安接续他作王。"},
{volumn:        11,chapter:        12,versenumber:         1,verse:"罗波安往示剑去；因为以色列人都到了示剑要立他作王。"},
{volumn:        11,chapter:        12,versenumber:         2,verse:"尼八的儿子耶罗波安先前躲避所罗门王，逃往埃及，住在那里（他听见这事。）"},
{volumn:        11,chapter:        12,versenumber:         3,verse:"以色列人打发人去请他来，他就和以色列会众都来见罗波安，对他说："},
{volumn:        11,chapter:        12,versenumber:         4,verse:"“你父亲使我们负重轭，做苦工，现在求你使我们做的苦工、负的重轭轻松些，我们就侍奉你。”"},
{volumn:        11,chapter:        12,versenumber:         5,verse:"罗波安对他们说：“你们暂且去，第三日再来见我。”民就去了。"},
{volumn:        11,chapter:        12,versenumber:         6,verse:"罗波安之父所罗门在世的日子，有侍立在他面前的老年人，罗波安王和他们商议，说：“你们给我出个什么主意，我好回复这民。”"},
{volumn:        11,chapter:        12,versenumber:         7,verse:"老年人对他说：“现在王若服侍这民如仆人，用好话回答他们，他们就永远作王的仆人。”"},
{volumn:        11,chapter:        12,versenumber:         8,verse:"王却不用老年人给他出的主意，就和那些与他一同长大、在他面前侍立的少年人商议，"},
{volumn:        11,chapter:        12,versenumber:         9,verse:"说：“这民对我说：‘你父亲使我们负重轭，求你使我们轻松些。’你们给我出个什么主意，我好回复他们。”"},
{volumn:        11,chapter:        12,versenumber:        10,verse:"那同他长大的少年人说：“这民对王说：‘你父亲使我们负重轭，求你使我们轻松些。’王要对他们如此说：‘我的小拇指头比我父亲的腰还粗。"},
{volumn:        11,chapter:        12,versenumber:        11,verse:"我父亲使你们负重轭，我必使你们负更重的轭！我父亲用鞭子责打你们，我要用蝎子鞭责打你们！’”"},
{volumn:        11,chapter:        12,versenumber:        12,verse:"耶罗波安和众百姓遵着罗波安王所说“你们第三日再来见我”的那话，第三日他们果然来了。"},
{volumn:        11,chapter:        12,versenumber:        13,verse:"王用严厉的话回答百姓，不用老年人给他所出的主意，"},
{volumn:        11,chapter:        12,versenumber:        14,verse:"照着少年人所出的主意对民说：“我父亲使你们负重轭，我必使你们负更重的轭！我父亲用鞭子责打你们，我要用蝎子鞭责打你们！”"},
{volumn:        11,chapter:        12,versenumber:        15,verse:"王不肯依从百姓，这事乃出于耶和华，为要应验他藉示罗人亚希雅对尼八的儿子耶罗波安所说的话。"},
{volumn:        11,chapter:        12,versenumber:        16,verse:"以色列众民见王不依从他们，就对王说：“我们与大卫有什么份儿呢？与耶西的儿子并没有关涉。以色列人哪，各回各家去吧！大卫家啊，自己顾自己吧！”于是以色列人都回自己家里去了，"},
{volumn:        11,chapter:        12,versenumber:        17,verse:"惟独住犹大城邑的以色列人，罗波安仍作他们的王。"},
{volumn:        11,chapter:        12,versenumber:        18,verse:"罗波安王差遣掌管服苦之人的亚多兰往以色列人那里去，以色列人就用石头打死他。罗波安王急忙上车，逃回耶路撒冷去了。"},
{volumn:        11,chapter:        12,versenumber:        19,verse:"这样，以色列人背叛大卫家，直到今日。"},
{volumn:        11,chapter:        12,versenumber:        20,verse:"以色列众人听见耶罗波安回来了，就打发人去请他到会众面前，立他作以色列众人的王。除了犹大支派以外，没有顺从大卫家的。"},
{volumn:        11,chapter:        12,versenumber:        21,verse:"罗波安来到耶路撒冷，招聚犹大全家和便雅悯支派的人共十八万，都是挑选的战士，要与以色列家争战，好将国夺回，再归所罗门的儿子罗波安。"},
{volumn:        11,chapter:        12,versenumber:        22,verse:"但　神的话临到神人示玛雅，说："},
{volumn:        11,chapter:        12,versenumber:        23,verse:"“你去告诉所罗门的儿子犹大王罗波安和犹大、便雅悯全家，并其余的民说："},
{volumn:        11,chapter:        12,versenumber:        24,verse:"‘耶和华如此说：你们不可上去与你们的弟兄以色列人争战。各归各家去吧！因为这事出于我。’”众人就听从耶和华的话，遵着耶和华的命回去了。"},
{volumn:        11,chapter:        12,versenumber:        25,verse:"耶罗波安在以法莲山地建筑示剑，就住在其中；又从示剑出去，建筑毗努伊勒。"},
{volumn:        11,chapter:        12,versenumber:        26,verse:"耶罗波安心里说：“恐怕这国仍归大卫家；"},
{volumn:        11,chapter:        12,versenumber:        27,verse:"这民若上耶路撒冷去，在耶和华的殿里献祭，他们的心必归向他们的主犹大王罗波安，就把我杀了，仍归犹大王罗波安。”"},
{volumn:        11,chapter:        12,versenumber:        28,verse:"耶罗波安王就筹划定妥，铸造了两个金牛犊，对众民说：“以色列人哪，你们上耶路撒冷去实在是难；这就是领你们出埃及地的　神。”"},
{volumn:        11,chapter:        12,versenumber:        29,verse:"他就把牛犊一只安在伯特利，一只安在但。"},
{volumn:        11,chapter:        12,versenumber:        30,verse:"这事叫百姓陷在罪里，因为他们往但去拜那牛犊。"},
{volumn:        11,chapter:        12,versenumber:        31,verse:"耶罗波安在邱坛那里建殿，将那不属利未人的凡民立为祭司。"},
{volumn:        11,chapter:        12,versenumber:        32,verse:"耶罗波安定八月十五日为节期，像在犹大的节期一样，自己上坛献祭。他在伯特利也这样向他所铸的牛犊献祭，又将立为邱坛的祭司安置在伯特利。"},
{volumn:        11,chapter:        12,versenumber:        33,verse:"他在八月十五日，就是他私自所定的月日，为以色列人立作节期的日子，在伯特利上坛烧香。"},
{volumn:        11,chapter:        13,versenumber:         1,verse:"那时，有一个神人奉耶和华的命从犹大来到伯特利。耶罗波安正站在坛旁要烧香；"},
{volumn:        11,chapter:        13,versenumber:         2,verse:"神人奉耶和华的命向坛呼叫，说：“坛哪，坛哪！耶和华如此说：大卫家里必生一个儿子，名叫约西亚，他必将邱坛的祭司，就是在你上面烧香的，杀在你上面，人的骨头也必烧在你上面。”"},
{volumn:        11,chapter:        13,versenumber:         3,verse:"当日，神人设个预兆，说：“这坛必破裂，坛上的灰必倾撒，这是耶和华说的预兆。”"},
{volumn:        11,chapter:        13,versenumber:         4,verse:"耶罗波安王听见神人向伯特利的坛所呼叫的话，就从坛上伸手，说：“拿住他吧！”王向神人所伸的手就枯干了，不能弯回；"},
{volumn:        11,chapter:        13,versenumber:         5,verse:"坛也破裂了，坛上的灰倾撒了，正如神人奉耶和华的命所设的预兆。"},
{volumn:        11,chapter:        13,versenumber:         6,verse:"王对神人说：“请你为我祷告，求耶和华你　神的恩典使我的手复原。”于是神人祈祷耶和华，王的手就复了原，仍如寻常一样。"},
{volumn:        11,chapter:        13,versenumber:         7,verse:"王对神人说：“请你同我回去吃饭，加添心力，我也必给你赏赐。”"},
{volumn:        11,chapter:        13,versenumber:         8,verse:"神人对王说：“你就是把你的宫一半给我，我也不同你进去，也不在这地方吃饭喝水；"},
{volumn:        11,chapter:        13,versenumber:         9,verse:"因为有耶和华的话嘱咐我，说不可在伯特利吃饭喝水，也不可从你去的原路回来。”"},
{volumn:        11,chapter:        13,versenumber:        10,verse:"于是神人从别的路回去，不从伯特利来的原路回去。"},
{volumn:        11,chapter:        13,versenumber:        11,verse:"有一个老先知住在伯特利，他儿子们来，将神人当日在伯特利所行的一切事和向王所说的话都告诉了父亲。"},
{volumn:        11,chapter:        13,versenumber:        12,verse:"父亲问他们说：“神人从哪条路去了呢？”儿子们就告诉他；原来他们看见那从犹大来的神人所去的路。"},
{volumn:        11,chapter:        13,versenumber:        13,verse:"老先知就吩咐他儿子们说：“你们为我备驴。”他们备好了驴，他就骑上，"},
{volumn:        11,chapter:        13,versenumber:        14,verse:"去追赶神人，遇见他坐在橡树底下，就问他说：“你是从犹大来的神人不是？”他说：“是。”"},
{volumn:        11,chapter:        13,versenumber:        15,verse:"老先知对他说：“请你同我回家吃饭。”"},
{volumn:        11,chapter:        13,versenumber:        16,verse:"神人说：“我不可同你回去进你的家，也不可在这里同你吃饭喝水；"},
{volumn:        11,chapter:        13,versenumber:        17,verse:"因为有耶和华的话嘱咐我说：‘你在那里不可吃饭喝水，也不可从你去的原路回来。’”"},
{volumn:        11,chapter:        13,versenumber:        18,verse:"老先知对他说：“我也是先知，和你一样。有天使奉耶和华的命对我说：‘你去把他带回你的家，叫他吃饭喝水。’”这都是老先知诓哄他。"},
{volumn:        11,chapter:        13,versenumber:        19,verse:"于是神人同老先知回去，在他家里吃饭喝水。"},
{volumn:        11,chapter:        13,versenumber:        20,verse:"二人坐席的时候，耶和华的话临到那带神人回来的先知，"},
{volumn:        11,chapter:        13,versenumber:        21,verse:"他就对那从犹大来的神人说：“耶和华如此说：‘你既违背耶和华的话，不遵守耶和华你　神的命令，"},
{volumn:        11,chapter:        13,versenumber:        22,verse:"反倒回来，在耶和华禁止你吃饭喝水的地方吃了喝了，因此你的尸身不得入你列祖的坟墓。’”"},
{volumn:        11,chapter:        13,versenumber:        23,verse:"吃喝完了，老先知为所带回来的先知备驴。"},
{volumn:        11,chapter:        13,versenumber:        24,verse:"他就去了，在路上有个狮子遇见他，将他咬死，尸身倒在路上，驴站在尸身旁边，狮子也站在尸身旁边。"},
{volumn:        11,chapter:        13,versenumber:        25,verse:"有人从那里经过，看见尸身倒在路上，狮子站在尸身旁边，就来到老先知所住的城里述说这事。"},
{volumn:        11,chapter:        13,versenumber:        26,verse:"那带神人回来的先知听见这事，就说：“这是那违背了耶和华命令的神人，所以耶和华把他交给狮子；狮子抓伤他，咬死他，是应验耶和华对他说的话。”"},
{volumn:        11,chapter:        13,versenumber:        27,verse:"老先知就吩咐他儿子们说：“你们为我备驴。”他们就备了驴。"},
{volumn:        11,chapter:        13,versenumber:        28,verse:"他去了，看见神人的尸身倒在路上，驴和狮子站在尸身旁边，狮子却没有吃尸身，也没有抓伤驴。"},
{volumn:        11,chapter:        13,versenumber:        29,verse:"老先知就把神人的尸身驮在驴上，带回自己的城里，要哀哭他，葬埋他；"},
{volumn:        11,chapter:        13,versenumber:        30,verse:"就把他的尸身葬在自己的坟墓里，哀哭他，说：“哀哉！我兄啊。”"},
{volumn:        11,chapter:        13,versenumber:        31,verse:"安葬之后，老先知对他儿子们说：“我死了，你们要葬我在神人的坟墓里，使我的尸骨靠近他的尸骨，"},
{volumn:        11,chapter:        13,versenumber:        32,verse:"因为他奉耶和华的命指着伯特利的坛和撒玛利亚各城有邱坛之殿所说的话必定应验。”"},
{volumn:        11,chapter:        13,versenumber:        33,verse:"这事以后，耶罗波安仍不离开他的恶道，将凡民立为邱坛的祭司；凡愿意的，他都分别为圣，立为邱坛的祭司。"},
{volumn:        11,chapter:        13,versenumber:        34,verse:"这事叫耶罗波安的家陷在罪里，甚至他的家从地上除灭了。"},
{volumn:        11,chapter:        14,versenumber:         1,verse:"那时，耶罗波安的儿子亚比雅病了。"},
{volumn:        11,chapter:        14,versenumber:         2,verse:"耶罗波安对他的妻说：“你可以起来改装，使人不知道你是耶罗波安的妻，往示罗去，在那里有先知亚希雅。他曾告诉我说，你必作这民的王。"},
{volumn:        11,chapter:        14,versenumber:         3,verse:"现在你要带十个饼，与几个薄饼，和一瓶蜜去见他，他必告诉你儿子将要怎样。”"},
{volumn:        11,chapter:        14,versenumber:         4,verse:"耶罗波安的妻就这样行，起身往示罗去，到了亚希雅的家。亚希雅因年纪老迈，眼目发直，不能看见。"},
{volumn:        11,chapter:        14,versenumber:         5,verse:"耶和华先晓谕亚希雅说：“耶罗波安的妻要来问你，因她儿子病了，你当如此如此告诉她。她进来的时候必装作别的妇人。”"},
{volumn:        11,chapter:        14,versenumber:         6,verse:"她刚进门，亚希雅听见她脚步的响声，就说：“耶罗波安的妻，进来吧！你为何装作别的妇人呢？我奉差遣将凶事告诉你。"},
{volumn:        11,chapter:        14,versenumber:         7,verse:"你回去告诉耶罗波安说：‘耶和华以色列的　神如此说：我从民中将你高举，立你作我民以色列的君，"},
{volumn:        11,chapter:        14,versenumber:         8,verse:"将国从大卫家夺回赐给你；你却不效法我仆人大卫，遵守我的诫命，一心顺从我，行我眼中看为正的事。"},
{volumn:        11,chapter:        14,versenumber:         9,verse:"你竟行恶，比那在你以先的更甚，为自己立了别神，铸了偶像，惹我发怒，将我丢在背后。"},
{volumn:        11,chapter:        14,versenumber:        10,verse:"因此，我必使灾祸临到耶罗波安的家，将属耶罗波安的男丁，无论困住的、自由的都从以色列中剪除，必除尽耶罗波安的家，如人除尽粪土一般。"},
{volumn:        11,chapter:        14,versenumber:        11,verse:"凡属耶罗波安的人，死在城中的必被狗吃，死在田野的必被空中的鸟吃。这是耶和华说的。’"},
{volumn:        11,chapter:        14,versenumber:        12,verse:"所以你起身回家去吧！你的脚一进城，你儿子就必死了。"},
{volumn:        11,chapter:        14,versenumber:        13,verse:"以色列众人必为他哀哭，将他葬埋。凡属耶罗波安的人，惟有他得入坟墓；因为在耶罗波安的家中，只有他向耶和华以色列的　神显出善行。"},
{volumn:        11,chapter:        14,versenumber:        14,verse:"耶和华必另立一王治理以色列。到了日期，他必剪除耶罗波安的家；那日期已经到了。"},
{volumn:        11,chapter:        14,versenumber:        15,verse:"耶和华必击打以色列人，使他们摇动，像水中的芦苇一般；又将他们从耶和华赐给他们列祖的美地上拔出来，分散在大河那边；因为他们做木偶，惹耶和华发怒。"},
{volumn:        11,chapter:        14,versenumber:        16,verse:"因耶罗波安所犯的罪，又使以色列人陷在罪里，耶和华必将以色列人交给仇敌。”"},
{volumn:        11,chapter:        14,versenumber:        17,verse:"耶罗波安的妻起身回去，到了得撒，刚到门槛，儿子就死了。"},
{volumn:        11,chapter:        14,versenumber:        18,verse:"以色列众人将他葬埋，为他哀哭，正如耶和华藉他仆人先知亚希雅所说的话。"},
{volumn:        11,chapter:        14,versenumber:        19,verse:"耶罗波安其余的事，他怎样争战，怎样作王，都写在以色列诸王记上。"},
{volumn:        11,chapter:        14,versenumber:        20,verse:"耶罗波安作王二十二年，就与他列祖同睡。他儿子拿答接续他作王。"},
{volumn:        11,chapter:        14,versenumber:        21,verse:"所罗门的儿子罗波安作犹大王。他登基的时候年四十一岁，在耶路撒冷，就是耶和华从以色列众支派中所选择立他名的城，作王十七年。罗波安的母亲名叫拿玛，是亚扪人。"},
{volumn:        11,chapter:        14,versenumber:        22,verse:"犹大人行耶和华眼中看为恶的事，犯罪触动他的愤恨，比他们列祖更甚。"},
{volumn:        11,chapter:        14,versenumber:        23,verse:"因为他们在各高冈上，各青翠树下筑坛，立柱像和木偶。"},
{volumn:        11,chapter:        14,versenumber:        24,verse:"国中也有娈童。犹大人效法耶和华在以色列人面前所赶出的外邦人，行一切可憎恶的事。"},
{volumn:        11,chapter:        14,versenumber:        25,verse:"罗波安王第五年，埃及王示撒上来攻取耶路撒冷，"},
{volumn:        11,chapter:        14,versenumber:        26,verse:"夺了耶和华殿和王宫里的宝物，尽都带走，又夺去所罗门制造的金盾牌。"},
{volumn:        11,chapter:        14,versenumber:        27,verse:"罗波安王制造铜盾牌代替那金盾牌，交给守王宫门的护卫长看守。"},
{volumn:        11,chapter:        14,versenumber:        28,verse:"王每逢进耶和华的殿，护卫兵就拿这盾牌，随后仍将盾牌送回，放在护卫房。"},
{volumn:        11,chapter:        14,versenumber:        29,verse:"罗波安其余的事，凡他所行的，都写在犹大列王记上。"},
{volumn:        11,chapter:        14,versenumber:        30,verse:"罗波安与耶罗波安时常争战。"},
{volumn:        11,chapter:        14,versenumber:        31,verse:"罗波安与他列祖同睡，葬在大卫城他列祖的坟地里。他母亲名叫拿玛，是亚扪人。他儿子亚比央（又名亚比雅）接续他作王。"},
{volumn:        11,chapter:        15,versenumber:         1,verse:"尼八的儿子耶罗波安王十八年，亚比央登基作犹大王，"},
{volumn:        11,chapter:        15,versenumber:         2,verse:"在耶路撒冷作王三年。他母亲名叫玛迦，是押沙龙的女儿。"},
{volumn:        11,chapter:        15,versenumber:         3,verse:"亚比央行他父亲在他以前所行的一切恶，他的心不像他祖大卫的心，诚诚实实地顺服耶和华他的　神。"},
{volumn:        11,chapter:        15,versenumber:         4,verse:"然而耶和华他的　神因大卫的缘故，仍使他在耶路撒冷有灯光，叫他儿子接续他作王，坚立耶路撒冷。"},
{volumn:        11,chapter:        15,versenumber:         5,verse:"因为大卫除了赫人乌利亚那件事，都是行耶和华眼中看为正的事，一生没有违背耶和华一切所吩咐的。"},
{volumn:        11,chapter:        15,versenumber:         6,verse:"罗波安在世的日子常与耶罗波安争战。"},
{volumn:        11,chapter:        15,versenumber:         7,verse:"亚比央其余的事，凡他所行的，都写在犹大列王记上。亚比央常与耶罗波安争战。"},
{volumn:        11,chapter:        15,versenumber:         8,verse:"亚比央与他列祖同睡，葬在大卫的城里。他儿子亚撒接续他作王。"},
{volumn:        11,chapter:        15,versenumber:         9,verse:"以色列王耶罗波安二十年，亚撒登基作犹大王，"},
{volumn:        11,chapter:        15,versenumber:        10,verse:"在耶路撒冷作王四十一年。他祖母名叫玛迦，是押沙龙的女儿。"},
{volumn:        11,chapter:        15,versenumber:        11,verse:"亚撒效法他祖大卫行耶和华眼中看为正的事，"},
{volumn:        11,chapter:        15,versenumber:        12,verse:"从国中除去娈童，又除掉他列祖所造的一切偶像；"},
{volumn:        11,chapter:        15,versenumber:        13,verse:"并且贬了他祖母玛迦太后的位，因她造了可憎的偶像亚舍拉。亚撒砍下她的偶像，烧在汲沦溪边，"},
{volumn:        11,chapter:        15,versenumber:        14,verse:"只是邱坛还没有废去。亚撒一生却向耶和华存诚实的心。"},
{volumn:        11,chapter:        15,versenumber:        15,verse:"亚撒将他父亲所分别为圣与自己所分别为圣的金银和器皿都奉到耶和华的殿里。"},
{volumn:        11,chapter:        15,versenumber:        16,verse:"亚撒和以色列王巴沙在世的日子常常争战。"},
{volumn:        11,chapter:        15,versenumber:        17,verse:"以色列王巴沙上来要攻击犹大，修筑拉玛，不许人从犹大王亚撒那里出入。"},
{volumn:        11,chapter:        15,versenumber:        18,verse:"于是亚撒将耶和华殿和王宫府库里所剩下的金银都交在他臣仆手中，打发他们往住大马士革的亚兰王希旬的孙子、他伯利们的儿子便·哈达那里去，"},
{volumn:        11,chapter:        15,versenumber:        19,verse:"说：“你父曾与我父立约，我与你也要立约。现在我将金银送你为礼物，求你废掉你与以色列王巴沙所立的约，使他离开我。”"},
{volumn:        11,chapter:        15,versenumber:        20,verse:"便·哈达听从亚撒王的话，派军长去攻击以色列的城邑；他们就攻破以云、但、亚伯·伯·玛迦、基尼烈全境、拿弗他利全境。"},
{volumn:        11,chapter:        15,versenumber:        21,verse:"巴沙听见，就停工不修筑拉玛了，仍住在得撒。"},
{volumn:        11,chapter:        15,versenumber:        22,verse:"于是亚撒王宣告犹大众人，不准一个推辞，吩咐他们将巴沙修筑拉玛所用的石头、木头都运去，用以修筑便雅悯的迦巴和米斯巴。"},
{volumn:        11,chapter:        15,versenumber:        23,verse:"亚撒其余的事，凡他所行的，并他的勇力与他所建筑的城邑，都写在犹大列王记上。亚撒年老的时候，脚上有病。"},
{volumn:        11,chapter:        15,versenumber:        24,verse:"亚撒与他列祖同睡，葬在他祖大卫城他列祖的坟地里。他儿子约沙法接续他作王。"},
{volumn:        11,chapter:        15,versenumber:        25,verse:"犹大王亚撒第二年，耶罗波安的儿子拿答登基作以色列王共二年，"},
{volumn:        11,chapter:        15,versenumber:        26,verse:"拿答行耶和华眼中看为恶的事，行他父亲所行的，犯他父亲使以色列人陷在罪里的那罪。"},
{volumn:        11,chapter:        15,versenumber:        27,verse:"以萨迦人亚希雅的儿子巴沙背叛拿答，在非利士的基比顿杀了他。那时拿答和以色列众人正围困基比顿。"},
{volumn:        11,chapter:        15,versenumber:        28,verse:"在犹大王亚撒第三年巴沙杀了他，篡了他的位。"},
{volumn:        11,chapter:        15,versenumber:        29,verse:"巴沙一作王就杀了耶罗波安的全家，凡有气息的没有留下一个，都灭尽了，正应验耶和华藉他仆人示罗人亚希雅所说的话。"},
{volumn:        11,chapter:        15,versenumber:        30,verse:"这是因为耶罗波安所犯的罪使以色列人陷在罪里，惹动耶和华以色列　神的怒气。"},
{volumn:        11,chapter:        15,versenumber:        31,verse:"拿答其余的事，凡他所行的，都写在以色列诸王记上。"},
{volumn:        11,chapter:        15,versenumber:        32,verse:"亚撒和以色列王巴沙在世的日子常常争战。"},
{volumn:        11,chapter:        15,versenumber:        33,verse:"犹大王亚撒第三年，亚希雅的儿子巴沙在得撒登基作以色列众人的王共二十四年。"},
{volumn:        11,chapter:        15,versenumber:        34,verse:"他行耶和华眼中看为恶的事，行耶罗波安所行的道，犯他使以色列人陷在罪里的那罪。"},
{volumn:        11,chapter:        16,versenumber:         1,verse:"耶和华的话临到哈拿尼的儿子耶户，责备巴沙说："},
{volumn:        11,chapter:        16,versenumber:         2,verse:"“我既从尘埃中提拔你，立你作我民以色列的君，你竟行耶罗波安所行的道，使我民以色列陷在罪里，惹我发怒，"},
{volumn:        11,chapter:        16,versenumber:         3,verse:"我必除尽你和你的家，使你的家像尼八的儿子耶罗波安的家一样。"},
{volumn:        11,chapter:        16,versenumber:         4,verse:"凡属巴沙的人，死在城中的必被狗吃，死在田野的必被空中的鸟吃。”"},
{volumn:        11,chapter:        16,versenumber:         5,verse:"巴沙其余的事，凡他所行的和他的勇力，都写在以色列诸王记上。"},
{volumn:        11,chapter:        16,versenumber:         6,verse:"巴沙与他列祖同睡，葬在得撒。他儿子以拉接续他作王。"},
{volumn:        11,chapter:        16,versenumber:         7,verse:"耶和华的话临到哈拿尼的儿子先知耶户，责备巴沙和他的家，因他行耶和华眼中看为恶的一切事，以他手所做的惹耶和华发怒，像耶罗波安的家一样，又因他杀了耶罗波安的全家。"},
{volumn:        11,chapter:        16,versenumber:         8,verse:"犹大王亚撒二十六年，巴沙的儿子以拉在得撒登基作以色列王共二年。"},
{volumn:        11,chapter:        16,versenumber:         9,verse:"有管理他一半战车的臣子心利背叛他。当他在得撒家宰亚杂家里喝醉的时候，"},
{volumn:        11,chapter:        16,versenumber:        10,verse:"心利就进去杀了他，篡了他的位。这是犹大王亚撒二十七年的事。"},
{volumn:        11,chapter:        16,versenumber:        11,verse:"心利一坐王位就杀了巴沙的全家，连他的亲属、朋友也没有留下一个男丁。"},
{volumn:        11,chapter:        16,versenumber:        12,verse:"心利这样灭绝巴沙的全家，正如耶和华藉先知耶户责备巴沙的话。"},
{volumn:        11,chapter:        16,versenumber:        13,verse:"这是因巴沙和他儿子以拉的一切罪，就是他们使以色列人陷在罪里的那罪，以虚无的神惹耶和华以色列　神的怒气。"},
{volumn:        11,chapter:        16,versenumber:        14,verse:"以拉其余的事，凡他所行的，都写在以色列诸王记上。"},
{volumn:        11,chapter:        16,versenumber:        15,verse:"犹大王亚撒二十七年，心利在得撒作王七日。那时民正安营围攻非利士的基比顿。"},
{volumn:        11,chapter:        16,versenumber:        16,verse:"民在营中听说心利背叛，又杀了王，故此以色列众人当日在营中立元帅暗利作以色列王。"},
{volumn:        11,chapter:        16,versenumber:        17,verse:"暗利率领以色列众人，从基比顿上去，围困得撒。"},
{volumn:        11,chapter:        16,versenumber:        18,verse:"心利见城破失，就进了王宫的卫所，放火焚烧宫殿，自焚而死。"},
{volumn:        11,chapter:        16,versenumber:        19,verse:"这是因他犯罪，行耶和华眼中看为恶的事，行耶罗波安所行的，犯他使以色列人陷在罪里的那罪。"},
{volumn:        11,chapter:        16,versenumber:        20,verse:"心利其余的事和他背叛的情形都写在以色列诸王记上。"},
{volumn:        11,chapter:        16,versenumber:        21,verse:"那时，以色列民分为两半：一半随从基纳的儿子提比尼，要立他作王；一半随从暗利。"},
{volumn:        11,chapter:        16,versenumber:        22,verse:"但随从暗利的民胜过随从基纳的儿子提比尼的民。提比尼死了，暗利就作了王。"},
{volumn:        11,chapter:        16,versenumber:        23,verse:"犹大王亚撒三十一年，暗利登基作以色列王共十二年；在得撒作王六年。"},
{volumn:        11,chapter:        16,versenumber:        24,verse:"暗利用二他连得银子向撒玛买了撒玛利亚山，在山上造城，就按着山的原主撒玛的名，给所造的城起名叫撒玛利亚。"},
{volumn:        11,chapter:        16,versenumber:        25,verse:"暗利行耶和华眼中看为恶的事，比他以前的列王作恶更甚。"},
{volumn:        11,chapter:        16,versenumber:        26,verse:"因他行了尼八的儿子耶罗波安所行的，犯他使以色列人陷在罪里的那罪，以虚无的神惹耶和华以色列　神的怒气。"},
{volumn:        11,chapter:        16,versenumber:        27,verse:"暗利其余的事和他所显出的勇力都写在以色列诸王记上。"},
{volumn:        11,chapter:        16,versenumber:        28,verse:"暗利与他列祖同睡，葬在撒玛利亚。他儿子亚哈接续他作王。"},
{volumn:        11,chapter:        16,versenumber:        29,verse:"犹大王亚撒三十八年，暗利的儿子亚哈登基作了以色列王。暗利的儿子亚哈在撒玛利亚作以色列王二十二年。"},
{volumn:        11,chapter:        16,versenumber:        30,verse:"暗利的儿子亚哈行耶和华眼中看为恶的事，比他以前的列王更甚，"},
{volumn:        11,chapter:        16,versenumber:        31,verse:"犯了尼八的儿子耶罗波安所犯的罪；他还以为轻，又娶了西顿王谒巴力的女儿耶洗别为妻，去侍奉敬拜巴力，"},
{volumn:        11,chapter:        16,versenumber:        32,verse:"在撒玛利亚建造巴力的庙，在庙里为巴力筑坛。"},
{volumn:        11,chapter:        16,versenumber:        33,verse:"亚哈又做亚舍拉，他所行的惹耶和华以色列　神的怒气，比他以前的以色列诸王更甚。"},
{volumn:        11,chapter:        16,versenumber:        34,verse:"亚哈在位的时候，有伯特利人希伊勒重修耶利哥城；立根基的时候，丧了长子亚比兰；安门的时候，丧了幼子西割，正如耶和华藉嫩的儿子约书亚所说的话。"},
{volumn:        11,chapter:        17,versenumber:         1,verse:"基列寄居的提斯比人以利亚对亚哈说：“我指着所侍奉永生耶和华以色列的　神起誓，这几年我若不祷告，必不降露，不下雨。”"},
{volumn:        11,chapter:        17,versenumber:         2,verse:"耶和华的话临到以利亚说："},
{volumn:        11,chapter:        17,versenumber:         3,verse:"“你离开这里往东去，藏在约旦河东边的基立溪旁。"},
{volumn:        11,chapter:        17,versenumber:         4,verse:"你要喝那溪里的水，我已吩咐乌鸦在那里供养你。”"},
{volumn:        11,chapter:        17,versenumber:         5,verse:"于是以利亚照着耶和华的话，去住在约旦河东的基立溪旁。"},
{volumn:        11,chapter:        17,versenumber:         6,verse:"乌鸦早晚给他叼饼和肉来，他也喝那溪里的水。"},
{volumn:        11,chapter:        17,versenumber:         7,verse:"过了些日子，溪水就干了，因为雨没有下在地上。"},
{volumn:        11,chapter:        17,versenumber:         8,verse:"耶和华的话临到他说："},
{volumn:        11,chapter:        17,versenumber:         9,verse:"“你起身往西顿的撒勒法（撒勒法与路加福音四章二十六节同）去，住在那里；我已吩咐那里的一个寡妇供养你。”"},
{volumn:        11,chapter:        17,versenumber:        10,verse:"以利亚就起身往撒勒法去。到了城门，见有一个寡妇在那里捡柴，以利亚呼叫她说：“求你用器皿取点水来给我喝。”"},
{volumn:        11,chapter:        17,versenumber:        11,verse:"她去取水的时候，以利亚又呼叫她说：“也求你拿点饼来给我！”"},
{volumn:        11,chapter:        17,versenumber:        12,verse:"她说：“我指着永生耶和华你的　神起誓，我没有饼，坛内只有一把面，瓶里只有一点油；我现在找两根柴，回家要为我和我儿子做饼；我们吃了，死就死吧！”"},
{volumn:        11,chapter:        17,versenumber:        13,verse:"以利亚对她说：“不要惧怕！可以照你所说的去做吧！只要先为我做一个小饼拿来给我，然后为你和你的儿子做饼。"},
{volumn:        11,chapter:        17,versenumber:        14,verse:"因为耶和华以色列的　神如此说：坛内的面必不减少，瓶里的油必不缺短，直到耶和华使雨降在地上的日子。”"},
{volumn:        11,chapter:        17,versenumber:        15,verse:"妇人就照以利亚的话去行。她和她家中的人，并以利亚，吃了许多日子。"},
{volumn:        11,chapter:        17,versenumber:        16,verse:"坛内的面果不减少，瓶里的油也不缺短，正如耶和华藉以利亚所说的话。"},
{volumn:        11,chapter:        17,versenumber:        17,verse:"这事以后，作那家主母的妇人，她儿子病了；病得甚重，以致身无气息。"},
{volumn:        11,chapter:        17,versenumber:        18,verse:"妇人对以利亚说：“神人哪，我与你何干？你竟到我这里来，使　神想念我的罪，以致我的儿子死呢？”"},
{volumn:        11,chapter:        17,versenumber:        19,verse:"以利亚对她说：“把你儿子交给我。”以利亚就从妇人怀中将孩子接过来，抱到他所住的楼中，放在自己的床上，"},
{volumn:        11,chapter:        17,versenumber:        20,verse:"就求告耶和华说：“耶和华我的　神啊，我寄居在这寡妇的家里，你就降祸与她，使她的儿子死了吗？”"},
{volumn:        11,chapter:        17,versenumber:        21,verse:"以利亚三次伏在孩子的身上，求告耶和华说：“耶和华我的　神啊，求你使这孩子的灵魂仍入他的身体！”"},
{volumn:        11,chapter:        17,versenumber:        22,verse:"耶和华应允以利亚的话，孩子的灵魂仍入他的身体，他就活了。"},
{volumn:        11,chapter:        17,versenumber:        23,verse:"以利亚将孩子从楼上抱下来，进屋子交给他母亲，说：“看哪，你的儿子活了！”"},
{volumn:        11,chapter:        17,versenumber:        24,verse:"妇人对以利亚说：“现在我知道你是神人，耶和华藉你口所说的话是真的。”"},
{volumn:        11,chapter:        18,versenumber:         1,verse:"过了许久，到第三年，耶和华的话临到以利亚说：“你去，使亚哈得见你；我要降雨在地上。”"},
{volumn:        11,chapter:        18,versenumber:         2,verse:"以利亚就去，要使亚哈得见他。那时，撒玛利亚有大饥荒；"},
{volumn:        11,chapter:        18,versenumber:         3,verse:"亚哈将他的家宰俄巴底召了来。（俄巴底甚是敬畏耶和华，"},
{volumn:        11,chapter:        18,versenumber:         4,verse:"耶洗别杀耶和华众先知的时候，俄巴底将一百个先知藏了，每五十人藏在一个洞里，拿饼和水供养他们。）"},
{volumn:        11,chapter:        18,versenumber:         5,verse:"亚哈对俄巴底说：“我们走遍这地，到一切水泉旁和一切溪边，或者找得着青草，可以救活骡马，免得绝了牲畜。”"},
{volumn:        11,chapter:        18,versenumber:         6,verse:"于是二人分地游行，亚哈独走一路，俄巴底独走一路。"},
{volumn:        11,chapter:        18,versenumber:         7,verse:"俄巴底在路上恰与以利亚相遇，俄巴底认出他来，就俯伏在地，说：“你是我主以利亚不是？”"},
{volumn:        11,chapter:        18,versenumber:         8,verse:"回答说：“是。你去告诉你主人说，以利亚在这里。”"},
{volumn:        11,chapter:        18,versenumber:         9,verse:"俄巴底说：“仆人有什么罪，你竟要将我交在亚哈手里，使他杀我呢？"},
{volumn:        11,chapter:        18,versenumber:        10,verse:"我指着永生耶和华你的　神起誓，无论哪一邦哪一国，我主都打发人去找你。若说你没有在那里，就必使那邦那国的人起誓说，实在是找不着你。"},
{volumn:        11,chapter:        18,versenumber:        11,verse:"现在你说，要去告诉你主人说，以利亚在这里；"},
{volumn:        11,chapter:        18,versenumber:        12,verse:"恐怕我一离开你，耶和华的灵就提你到我所不知道的地方去。这样，我去告诉亚哈，他若找不着你，就必杀我；仆人却是自幼敬畏耶和华的。"},
{volumn:        11,chapter:        18,versenumber:        13,verse:"耶洗别杀耶和华众先知的时候，我将耶和华的一百个先知藏了，每五十人藏在一个洞里，拿饼和水供养他们，岂没有人将这事告诉我主吗？"},
{volumn:        11,chapter:        18,versenumber:        14,verse:"现在你说，要去告诉你主人说，以利亚在这里，他必杀我。”"},
{volumn:        11,chapter:        18,versenumber:        15,verse:"以利亚说：“我指着所侍奉永生的万军之耶和华起誓，我今日必使亚哈得见我。”"},
{volumn:        11,chapter:        18,versenumber:        16,verse:"于是俄巴底去迎着亚哈，告诉他；亚哈就去迎着以利亚。"},
{volumn:        11,chapter:        18,versenumber:        17,verse:"亚哈见了以利亚，便说：“使以色列遭灾的就是你吗？”"},
{volumn:        11,chapter:        18,versenumber:        18,verse:"以利亚说：“使以色列遭灾的不是我，乃是你和你父家；因为你们离弃耶和华的诫命，去随从巴力。"},
{volumn:        11,chapter:        18,versenumber:        19,verse:"现在你当差遣人，招聚以色列众人和侍奉巴力的那四百五十个先知，并耶洗别所供养侍奉亚舍拉的那四百个先知，使他们都上迦密山去见我。”"},
{volumn:        11,chapter:        18,versenumber:        20,verse:"亚哈就差遣人招聚以色列众人和先知都上迦密山。"},
{volumn:        11,chapter:        18,versenumber:        21,verse:"以利亚前来对众民说：“你们心持两意要到几时呢？若耶和华是　神，就当顺从耶和华；若巴力是　神，就当顺从巴力。”众民一言不答。"},
{volumn:        11,chapter:        18,versenumber:        22,verse:"以利亚对众民说：“作耶和华先知的只剩下我一个人；巴力的先知却有四百五十个人。"},
{volumn:        11,chapter:        18,versenumber:        23,verse:"当给我们两只牛犊，巴力的先知可以挑选一只，切成块子，放在柴上，不要点火；我也预备一只牛犊放在柴上，也不点火。"},
{volumn:        11,chapter:        18,versenumber:        24,verse:"你们求告你们神的名，我也求告耶和华的名。那降火显应的　神，就是　神。”众民回答说：“这话甚好。”"},
{volumn:        11,chapter:        18,versenumber:        25,verse:"以利亚对巴力的先知说：“你们既是人多，当先挑选一只牛犊，预备好了，就求告你们神的名，却不要点火。”"},
{volumn:        11,chapter:        18,versenumber:        26,verse:"他们将所得的牛犊预备好了，从早晨到午间，求告巴力的名说：“巴力啊，求你应允我们！”却没有声音，没有应允的。他们在所筑的坛四围踊跳。"},
{volumn:        11,chapter:        18,versenumber:        27,verse:"到了正午，以利亚嬉笑他们，说：“大声求告吧！因为他是神，他或默想，或走到一边，或行路，或睡觉，你们当叫醒他。”"},
{volumn:        11,chapter:        18,versenumber:        28,verse:"他们大声求告，按着他们的规矩，用刀枪自割、自刺，直到身体流血。"},
{volumn:        11,chapter:        18,versenumber:        29,verse:"从午后直到献晚祭的时候，他们狂呼乱叫，却没有声音，没有应允的，也没有理会的。"},
{volumn:        11,chapter:        18,versenumber:        30,verse:"以利亚对众民说：“你们到我这里来。”众民就到他那里。他便重修已经毁坏耶和华的坛。"},
{volumn:        11,chapter:        18,versenumber:        31,verse:"以利亚照雅各子孙支派的数目，取了十二块石头（耶和华的话曾临到雅各说：“你的名要叫以色列”），"},
{volumn:        11,chapter:        18,versenumber:        32,verse:"用这些石头为耶和华的名筑一座坛，在坛的四围挖沟，可容谷种二细亚，"},
{volumn:        11,chapter:        18,versenumber:        33,verse:"又在坛上摆好了柴，把牛犊切成块子放在柴上，对众人说：“你们用四个桶盛满水，倒在燔祭和柴上”；"},
{volumn:        11,chapter:        18,versenumber:        34,verse:"又说：“倒第二次。”他们就倒第二次；又说：“倒第三次。”他们就倒第三次。"},
{volumn:        11,chapter:        18,versenumber:        35,verse:"水流在坛的四围，沟里也满了水。"},
{volumn:        11,chapter:        18,versenumber:        36,verse:"到了献晚祭的时候，先知以利亚近前来，说：“亚伯拉罕、以撒、以色列的　神，耶和华啊，求你今日使人知道你是以色列的　神，也知道我是你的仆人，又是奉你的命行这一切事。"},
{volumn:        11,chapter:        18,versenumber:        37,verse:"耶和华啊，求你应允我，应允我！使这民知道你耶和华是　神，又知道是你叫这民的心回转。”"},
{volumn:        11,chapter:        18,versenumber:        38,verse:"于是，耶和华降下火来，烧尽燔祭、木柴、石头、尘土，又烧干沟里的水。"},
{volumn:        11,chapter:        18,versenumber:        39,verse:"众民看见了，就俯伏在地，说：“耶和华是　神！耶和华是　神！”"},
{volumn:        11,chapter:        18,versenumber:        40,verse:"以利亚对他们说：“拿住巴力的先知，不容一人逃脱！”众人就拿住他们。以利亚带他们到基顺河边，在那里杀了他们。"},
{volumn:        11,chapter:        18,versenumber:        41,verse:"以利亚对亚哈说：“你现在可以上去吃喝，因为有多雨的响声了。”"},
{volumn:        11,chapter:        18,versenumber:        42,verse:"亚哈就上去吃喝。以利亚上了迦密山顶，屈身在地，将脸伏在两膝之中；"},
{volumn:        11,chapter:        18,versenumber:        43,verse:"对仆人说：“你上去，向海观看。”仆人就上去观看，说：“没有什么。”他说：“你再去观看。”如此七次。"},
{volumn:        11,chapter:        18,versenumber:        44,verse:"第七次仆人说：“我看见有一小片云从海里上来，不过如人手那样大。”以利亚说：“你上去告诉亚哈，当套车下去，免得被雨阻挡。”"},
{volumn:        11,chapter:        18,versenumber:        45,verse:"霎时间，天因风云黑暗，降下大雨。亚哈就坐车往耶斯列去了。"},
{volumn:        11,chapter:        18,versenumber:        46,verse:"耶和华的灵（原文是手）降在以利亚身上，他就束上腰，奔在亚哈前头，直到耶斯列的城门。"},
{volumn:        11,chapter:        19,versenumber:         1,verse:"亚哈将以利亚一切所行的和他用刀杀众先知的事都告诉耶洗别。"},
{volumn:        11,chapter:        19,versenumber:         2,verse:"耶洗别就差遣人去见以利亚，告诉他说：“明日约在这时候，我若不使你的性命像那些人的性命一样，愿神明重重地降罚与我。”"},
{volumn:        11,chapter:        19,versenumber:         3,verse:"以利亚见这光景就起来逃命，到了犹大的别是巴，将仆人留在那里，"},
{volumn:        11,chapter:        19,versenumber:         4,verse:"自己在旷野走了一日的路程，来到一棵罗腾树下（罗腾，小树名，松类；下同），就坐在那里求死，说：“耶和华啊，罢了！求你取我的性命，因为我不胜于我的列祖。”"},
{volumn:        11,chapter:        19,versenumber:         5,verse:"他就躺在罗腾树下，睡着了。有一个天使拍他，说：“起来吃吧！”"},
{volumn:        11,chapter:        19,versenumber:         6,verse:"他观看，见头旁有一瓶水与炭火烧的饼，他就吃了喝了，仍然躺下。"},
{volumn:        11,chapter:        19,versenumber:         7,verse:"耶和华的使者第二次来拍他，说：“起来吃吧！因为你当走的路甚远。”"},
{volumn:        11,chapter:        19,versenumber:         8,verse:"他就起来吃了喝了，仗着这饮食的力，走了四十昼夜，到了　神的山，就是何烈山。"},
{volumn:        11,chapter:        19,versenumber:         9,verse:"他在那里进了一个洞，就住在洞中。耶和华的话临到他说：“以利亚啊，你在这里做什么？”"},
{volumn:        11,chapter:        19,versenumber:        10,verse:"他说：“我为耶和华万军之　神大发热心；因为以色列人背弃了你的约，毁坏了你的坛，用刀杀了你的先知，只剩下我一个人，他们还要寻索我的命。”"},
{volumn:        11,chapter:        19,versenumber:        11,verse:"耶和华说：“你出来站在山上，在我面前。”那时耶和华从那里经过，在他面前有烈风大作，崩山碎石，耶和华却不在风中；风后地震，耶和华却不在其中；"},
{volumn:        11,chapter:        19,versenumber:        12,verse:"地震后有火，耶和华也不在火中；火后有微小的声音。"},
{volumn:        11,chapter:        19,versenumber:        13,verse:"以利亚听见，就用外衣蒙上脸，出来站在洞口。有声音向他说：“以利亚啊，你在这里做什么？”"},
{volumn:        11,chapter:        19,versenumber:        14,verse:"他说：“我为耶和华万军之　神大发热心；因为以色列人背弃了你的约，毁坏了你的坛，用刀杀了你的先知，只剩下我一个人，他们还要寻索我的命。”"},
{volumn:        11,chapter:        19,versenumber:        15,verse:"耶和华对他说：“你回去，从旷野往大马士革去。到了那里，就要膏哈薛作亚兰王，"},
{volumn:        11,chapter:        19,versenumber:        16,verse:"又膏宁示的孙子耶户作以色列王，并膏亚伯·米何拉人沙法的儿子以利沙作先知接续你。"},
{volumn:        11,chapter:        19,versenumber:        17,verse:"将来躲避哈薛之刀的，必被耶户所杀；躲避耶户之刀的，必被以利沙所杀。"},
{volumn:        11,chapter:        19,versenumber:        18,verse:"但我在以色列人中为自己留下七千人，是未曾向巴力屈膝的，未曾与巴力亲嘴的。”"},
{volumn:        11,chapter:        19,versenumber:        19,verse:"于是，以利亚离开那里走了，遇见沙法的儿子以利沙耕地；在他前头有十二对牛，自己赶着第十二对。以利亚到他那里去，将自己的外衣搭在他身上。"},
{volumn:        11,chapter:        19,versenumber:        20,verse:"以利沙就离开牛，跑到以利亚那里，说：“求你容我先与父母亲嘴，然后我便跟随你。”以利亚对他说：“你回去吧，我向你做了什么呢？”"},
{volumn:        11,chapter:        19,versenumber:        21,verse:"以利沙就离开他回去，宰了一对牛，用套牛的器具煮肉给民吃，随后就起身跟随以利亚，服侍他。"},
{volumn:        11,chapter:        20,versenumber:         1,verse:"亚兰王便·哈达聚集他的全军，率领三十二个王，带着车马上来围攻撒玛利亚；"},
{volumn:        11,chapter:        20,versenumber:         2,verse:"又差遣使者进城见以色列王亚哈，对他说：“便·哈达如此说："},
{volumn:        11,chapter:        20,versenumber:         3,verse:"你的金银都要归我，你妻子儿女中最美的也要归我。”"},
{volumn:        11,chapter:        20,versenumber:         4,verse:"以色列王回答说：“我主我王啊，可以依着你的话，我与我所有的都归你。”"},
{volumn:        11,chapter:        20,versenumber:         5,verse:"使者又来说：“便·哈达如此说：我已差遣人去见你，要你将你的金银、妻子、儿女都给我。"},
{volumn:        11,chapter:        20,versenumber:         6,verse:"但明日约在这时候，我还要差遣臣仆到你那里，搜查你的家和你仆人的家，将你眼中一切所喜爱的都拿了去。”"},
{volumn:        11,chapter:        20,versenumber:         7,verse:"以色列王召了国中的长老来，对他们说：“请你们看看，这人是怎样地谋害我，他先差遣人到我这里来，要我的妻子、儿女，和金银，我并没有推辞他。”"},
{volumn:        11,chapter:        20,versenumber:         8,verse:"长老和百姓对王说：“不要听从他，也不要应允他。”"},
{volumn:        11,chapter:        20,versenumber:         9,verse:"故此，以色列王对便·哈达的使者说：“你们告诉我主我王说：王头一次差遣人向仆人所要的，仆人都依从；但这次所要的，我不能依从。”使者就去回复便·哈达。"},
{volumn:        11,chapter:        20,versenumber:        10,verse:"便·哈达又差遣人去见亚哈说：“撒玛利亚的尘土若够跟从我的人每人捧一捧的，愿神明重重地降罚与我！”"},
{volumn:        11,chapter:        20,versenumber:        11,verse:"以色列王说：“你告诉他说，才顶盔贯甲的，休要像摘盔卸甲的夸口。”"},
{volumn:        11,chapter:        20,versenumber:        12,verse:"便·哈达和诸王正在帐幕里喝酒，听见这话，就对他臣仆说：“摆队吧！”他们就摆队攻城。"},
{volumn:        11,chapter:        20,versenumber:        13,verse:"有一个先知来见以色列王亚哈，说：“耶和华如此说：‘这一大群人你看见了吗？今日我必将他们交在你手里，你就知道我是耶和华。’”"},
{volumn:        11,chapter:        20,versenumber:        14,verse:"亚哈说：“藉着谁呢？”他回答说：“耶和华说，藉着跟从省长的少年人。”亚哈说：“要谁率领呢？”他说：“要你亲自率领。”"},
{volumn:        11,chapter:        20,versenumber:        15,verse:"于是亚哈数点跟从省长的少年人，共有二百三十二名，后又数点以色列的众兵，共有七千名。"},
{volumn:        11,chapter:        20,versenumber:        16,verse:"午间，他们就出城；便·哈达和帮助他的三十二个王正在帐幕里痛饮。"},
{volumn:        11,chapter:        20,versenumber:        17,verse:"跟从省长的少年人先出城；便·哈达差遣人去探望，他们回报说：“有人从撒玛利亚出来了。”"},
{volumn:        11,chapter:        20,versenumber:        18,verse:"他说：“他们若为讲和出来，要活捉他们；若为打仗出来，也要活捉他们。”"},
{volumn:        11,chapter:        20,versenumber:        19,verse:"跟从省长的少年人出城，军兵跟随他们；"},
{volumn:        11,chapter:        20,versenumber:        20,verse:"各人遇见敌人就杀。亚兰人逃跑，以色列人追赶他们；亚兰王便·哈达骑着马和马兵一同逃跑。"},
{volumn:        11,chapter:        20,versenumber:        21,verse:"以色列王出城攻打车马，大大击杀亚兰人。"},
{volumn:        11,chapter:        20,versenumber:        22,verse:"那先知来见以色列王，对他说：“你当自强，留心怎样防备；因为到明年这时候，亚兰王必上来攻击你。”"},
{volumn:        11,chapter:        20,versenumber:        23,verse:"亚兰王的臣仆对亚兰王说：“以色列人的神是山神，所以他们胜过我们；但在平原与他们打仗，我们必定得胜。"},
{volumn:        11,chapter:        20,versenumber:        24,verse:"王当这样行：把诸王革去，派军长代替他们，"},
{volumn:        11,chapter:        20,versenumber:        25,verse:"又照着王丧失军兵之数，再招募一军，马补马，车补车，我们在平原与他们打仗，必定得胜。”王便听臣仆的话去行。"},
{volumn:        11,chapter:        20,versenumber:        26,verse:"次年，便·哈达果然点齐亚兰人上亚弗去，要与以色列人打仗。"},
{volumn:        11,chapter:        20,versenumber:        27,verse:"以色列人也点齐军兵，预备食物，迎着亚兰人出去，对着他们安营，好像两小群山羊羔；亚兰人却满了地面。"},
{volumn:        11,chapter:        20,versenumber:        28,verse:"有神人来见以色列王，说：“耶和华如此说：‘亚兰人既说我耶和华是山神，不是平原的神，所以我必将这一大群人都交在你手中，你们就知道我是耶和华。’”"},
{volumn:        11,chapter:        20,versenumber:        29,verse:"以色列人与亚兰人相对安营七日，到第七日两军交战；那一日以色列人杀了亚兰人步兵十万，"},
{volumn:        11,chapter:        20,versenumber:        30,verse:"其余的逃入亚弗城；城墙塌倒，压死剩下的二万七千人。便·哈达也逃入城，藏在严密的屋子里。"},
{volumn:        11,chapter:        20,versenumber:        31,verse:"他的臣仆对他说：“我们听说以色列王都是仁慈的王，现在我们不如腰束麻布，头套绳索，出去投降以色列王，或者他存留王的性命。”"},
{volumn:        11,chapter:        20,versenumber:        32,verse:"于是他们腰束麻布，头套绳索，去见以色列王，说：“王的仆人便·哈达说，求王存留我的性命。”亚哈说：“他还活着吗？他是我的兄弟。”"},
{volumn:        11,chapter:        20,versenumber:        33,verse:"这些人留心探出他的口气来，便急忙就着他的话说：“便·哈达是王的兄弟！”王说：“你们去请他来。”便·哈达出来见王，王就请他上车。"},
{volumn:        11,chapter:        20,versenumber:        34,verse:"便·哈达对王说：“我父从你父那里所夺的城邑，我必归还。你可以在大马士革立街市，像我父在撒玛利亚所立的一样。”亚哈说：“我照此立约，放你回去”，就与他立约，放他去了。"},
{volumn:        11,chapter:        20,versenumber:        35,verse:"有先知的一个门徒奉耶和华的命对他的同伴说：“你打我吧！”那人不肯打他。"},
{volumn:        11,chapter:        20,versenumber:        36,verse:"他就对那人说：“你既不听从耶和华的话，你一离开我，必有狮子咬死你。”那人一离开他，果然遇见狮子，把他咬死了。"},
{volumn:        11,chapter:        20,versenumber:        37,verse:"先知的门徒又遇见一个人，对他说：“你打我吧！”那人就打他，将他打伤。"},
{volumn:        11,chapter:        20,versenumber:        38,verse:"他就去了，用头巾蒙眼，改换面目，在路旁等候王。"},
{volumn:        11,chapter:        20,versenumber:        39,verse:"王从那里经过，他向王呼叫说：“仆人在阵上的时候，有人带了一个人来，对我说：‘你看守这人，若把他失了，你的性命必代替他的性命；不然，你必交出一他连得银子来。’"},
{volumn:        11,chapter:        20,versenumber:        40,verse:"仆人正在忙乱之间，那人就不见了。”以色列王对他说：“你自己定妥了，必照样判断你。”"},
{volumn:        11,chapter:        20,versenumber:        41,verse:"他急忙除掉蒙眼的头巾，以色列王就认出他是一个先知。"},
{volumn:        11,chapter:        20,versenumber:        42,verse:"他对王说：“耶和华如此说：‘因你将我定要灭绝的人放去，你的命就必代替他的命，你的民也必代替他的民。’”"},
{volumn:        11,chapter:        20,versenumber:        43,verse:"于是以色列王闷闷不乐地回到撒玛利亚，进了他的宫。"},
{volumn:        11,chapter:        21,versenumber:         1,verse:"这事以后，又有一事。耶斯列人拿伯在耶斯列有一个葡萄园，靠近撒玛利亚王亚哈的宫。"},
{volumn:        11,chapter:        21,versenumber:         2,verse:"亚哈对拿伯说：“你将你的葡萄园给我作菜园，因为是靠近我的宫；我就把更好的葡萄园换给你，或是你要银子，我就按着价值给你。”"},
{volumn:        11,chapter:        21,versenumber:         3,verse:"拿伯对亚哈说：“我敬畏耶和华，万不敢将我先人留下的产业给你。”"},
{volumn:        11,chapter:        21,versenumber:         4,verse:"亚哈因耶斯列人拿伯说“我不敢将我先人留下的产业给你”，就闷闷不乐地回宫，躺在床上，转脸向内，也不吃饭。"},
{volumn:        11,chapter:        21,versenumber:         5,verse:"王后耶洗别来问他说：“你为什么心里这样忧闷，不吃饭呢？”"},
{volumn:        11,chapter:        21,versenumber:         6,verse:"他回答说：“因我向耶斯列人拿伯说：‘你将你的葡萄园给我，我给你价银，或是你愿意，我就把别的葡萄园换给你’；他却说：‘我不将我的葡萄园给你。’”"},
{volumn:        11,chapter:        21,versenumber:         7,verse:"王后耶洗别对亚哈说：“你现在是治理以色列国不是？只管起来，心里畅畅快快地吃饭，我必将耶斯列人拿伯的葡萄园给你。”"},
{volumn:        11,chapter:        21,versenumber:         8,verse:"于是托亚哈的名写信，用王的印印上，送给那些与拿伯同城居住的长老贵胄。"},
{volumn:        11,chapter:        21,versenumber:         9,verse:"信上写着说：“你们当宣告禁食，叫拿伯坐在民间的高位上，"},
{volumn:        11,chapter:        21,versenumber:        10,verse:"又叫两个匪徒坐在拿伯对面，作见证告他说：‘你谤渎　神和王了’；随后就把他拉出去用石头打死。”"},
{volumn:        11,chapter:        21,versenumber:        11,verse:"那些与拿伯同城居住的长老贵胄得了耶洗别的信，就照信而行，"},
{volumn:        11,chapter:        21,versenumber:        12,verse:"宣告禁食，叫拿伯坐在民间的高位上。"},
{volumn:        11,chapter:        21,versenumber:        13,verse:"有两个匪徒来，坐在拿伯的对面，当着众民作见证告他说：“拿伯谤渎　神和王了！”众人就把他拉到城外，用石头打死。"},
{volumn:        11,chapter:        21,versenumber:        14,verse:"于是打发人去见耶洗别，说：“拿伯被石头打死了。”"},
{volumn:        11,chapter:        21,versenumber:        15,verse:"耶洗别听见拿伯被石头打死，就对亚哈说：“你起来得耶斯列人拿伯不肯为价银给你的葡萄园吧！现在他已经死了。”"},
{volumn:        11,chapter:        21,versenumber:        16,verse:"亚哈听见拿伯死了，就起来，下去要得耶斯列人拿伯的葡萄园。"},
{volumn:        11,chapter:        21,versenumber:        17,verse:"耶和华的话临到提斯比人以利亚说："},
{volumn:        11,chapter:        21,versenumber:        18,verse:"“你起来，去见住撒玛利亚的以色列王亚哈，他下去要得拿伯的葡萄园，现今正在那园里。"},
{volumn:        11,chapter:        21,versenumber:        19,verse:"你要对他说：‘耶和华如此说：你杀了人，又得他的产业吗？’又要对他说：‘耶和华如此说：狗在何处舔拿伯的血，也必在何处舔你的血。’”"},
{volumn:        11,chapter:        21,versenumber:        20,verse:"亚哈对以利亚说：“我仇敌啊，你找到我吗？”他回答说：“我找到你了；因为你卖了自己，行耶和华眼中看为恶的事。"},
{volumn:        11,chapter:        21,versenumber:        21,verse:"耶和华说：‘我必使灾祸临到你，将你除尽。凡属你的男丁，无论困住的、自由的，都从以色列中剪除。"},
{volumn:        11,chapter:        21,versenumber:        22,verse:"我必使你的家像尼八的儿子耶罗波安的家，又像亚希雅的儿子巴沙的家；因为你惹我发怒，又使以色列人陷在罪里。’"},
{volumn:        11,chapter:        21,versenumber:        23,verse:"论到耶洗别，耶和华也说：‘狗在耶斯列的外郭必吃耶洗别的肉。"},
{volumn:        11,chapter:        21,versenumber:        24,verse:"凡属亚哈的人，死在城中的必被狗吃，死在田野的必被空中的鸟吃。’”"},
{volumn:        11,chapter:        21,versenumber:        25,verse:"(从来没有像亚哈的，因他自卖，行耶和华眼中看为恶的事，受了王后耶洗别的耸动；"},
{volumn:        11,chapter:        21,versenumber:        26,verse:"就照耶和华在以色列人面前所赶出的亚摩利人，行了最可憎恶的事，信从偶像。)"},
{volumn:        11,chapter:        21,versenumber:        27,verse:"亚哈听见这话，就撕裂衣服，禁食，身穿麻布，睡卧也穿着麻布，并且缓缓而行。"},
{volumn:        11,chapter:        21,versenumber:        28,verse:"耶和华的话临到提斯比人以利亚说："},
{volumn:        11,chapter:        21,versenumber:        29,verse:"“亚哈在我面前这样自卑，你看见了吗？因他在我面前自卑，他还在世的时候，我不降这祸；到他儿子的时候，我必降这祸与他的家。”"},
{volumn:        11,chapter:        22,versenumber:         1,verse:"亚兰国和以色列国三年没有争战。"},
{volumn:        11,chapter:        22,versenumber:         2,verse:"到第三年，犹大王约沙法下去见以色列王。"},
{volumn:        11,chapter:        22,versenumber:         3,verse:"以色列王对臣仆说：“你们不知道基列的拉末是属我们的吗？我们岂可静坐不动，不从亚兰王手里夺回来吗？”"},
{volumn:        11,chapter:        22,versenumber:         4,verse:"亚哈问约沙法说：“你肯同我去攻取基列的拉末吗？”约沙法对以色列王说：“你我不分彼此，我的民与你的民一样，我的马与你的马一样。”"},
{volumn:        11,chapter:        22,versenumber:         5,verse:"约沙法对以色列王说：“请你先求问耶和华。”"},
{volumn:        11,chapter:        22,versenumber:         6,verse:"于是以色列王招聚先知，约有四百人，问他们说：“我上去攻取基列的拉末可以不可以？”他们说：“可以上去，因为主必将那城交在王的手里。”"},
{volumn:        11,chapter:        22,versenumber:         7,verse:"约沙法说：“这里不是还有耶和华的先知，我们可以求问他吗？”"},
{volumn:        11,chapter:        22,versenumber:         8,verse:"以色列王对约沙法说：“还有一个人，是音拉的儿子米该雅，我们可以托他求问耶和华。只是我恨他；因为他指着我所说的预言，不说吉语，单说凶言。”约沙法说：“王不必这样说。”"},
{volumn:        11,chapter:        22,versenumber:         9,verse:"以色列王就召了一个太监来，说：“你快去，将音拉的儿子米该雅召来。”"},
{volumn:        11,chapter:        22,versenumber:        10,verse:"以色列王和犹大王约沙法在撒玛利亚城门前的空场上，各穿朝服，坐在位上，所有的先知都在他们面前说预言。"},
{volumn:        11,chapter:        22,versenumber:        11,verse:"基拿拿的儿子西底家造了两个铁角，说：“耶和华如此说：‘你要用这角抵触亚兰人，直到将他们灭尽。’”"},
{volumn:        11,chapter:        22,versenumber:        12,verse:"所有的先知也都这样预言说：“可以上基列的拉末去，必然得胜，因为耶和华必将那城交在王的手中。”"},
{volumn:        11,chapter:        22,versenumber:        13,verse:"那去召米该雅的使者对米该雅说：“众先知一口同音地都向王说吉言，你不如与他们说一样的话，也说吉言。”"},
{volumn:        11,chapter:        22,versenumber:        14,verse:"米该雅说：“我指着永生的耶和华起誓，耶和华对我说什么，我就说什么。”"},
{volumn:        11,chapter:        22,versenumber:        15,verse:"米该雅到王面前，王问他说：“米该雅啊，我们上去攻取基列的拉末可以不可以？”他回答说：“可以上去，必然得胜，耶和华必将那城交在王的手中。”"},
{volumn:        11,chapter:        22,versenumber:        16,verse:"王对他说：“我当嘱咐你几次，你才奉耶和华的名向我说实话呢？”"},
{volumn:        11,chapter:        22,versenumber:        17,verse:"米该雅说：“我看见以色列众民散在山上，如同没有牧人的羊群一般。耶和华说：‘这民没有主人，他们可以平平安安地各归各家去。’”"},
{volumn:        11,chapter:        22,versenumber:        18,verse:"以色列王对约沙法说：“我岂没有告诉你，这人指着我所说的预言，不说吉语单说凶言吗？”"},
{volumn:        11,chapter:        22,versenumber:        19,verse:"米该雅说：“你要听耶和华的话！我看见耶和华坐在宝座上，天上的万军侍立在他左右。"},
{volumn:        11,chapter:        22,versenumber:        20,verse:"耶和华说：‘谁去引诱亚哈上基列的拉末去阵亡呢？’这个就这样说，那个就那样说。"},
{volumn:        11,chapter:        22,versenumber:        21,verse:"随后有一个神灵出来，站在耶和华面前，说：‘我去引诱他。’"},
{volumn:        11,chapter:        22,versenumber:        22,verse:"耶和华问他说：‘你用何法呢？’他说：‘我去，要在他众先知口中作谎言的灵。’耶和华说：‘这样，你必能引诱他，你去如此行吧！’"},
{volumn:        11,chapter:        22,versenumber:        23,verse:"现在耶和华使谎言的灵入了你这些先知的口，并且耶和华已经命定降祸与你。”"},
{volumn:        11,chapter:        22,versenumber:        24,verse:"基拿拿的儿子西底家前来，打米该雅的脸，说：“耶和华的灵从哪里离开我与你说话呢？”"},
{volumn:        11,chapter:        22,versenumber:        25,verse:"米该雅说：“你进严密的屋子藏躲的那日，就必看见了。”"},
{volumn:        11,chapter:        22,versenumber:        26,verse:"以色列王说：“将米该雅带回，交给邑宰亚们和王的儿子约阿施，说"},
{volumn:        11,chapter:        22,versenumber:        27,verse:"王如此说，把这个人下在监里，使他受苦，吃不饱喝不足，等候我平平安安地回来。”"},
{volumn:        11,chapter:        22,versenumber:        28,verse:"米该雅说：“你若能平平安安地回来，那就是耶和华没有藉我说这话了”；又说：“众民哪，你们都要听！”"},
{volumn:        11,chapter:        22,versenumber:        29,verse:"以色列王和犹大王约沙法上基列的拉末去了。"},
{volumn:        11,chapter:        22,versenumber:        30,verse:"以色列王对约沙法说：“我要改装上阵，你可以仍穿王服。”以色列王就改装上阵。"},
{volumn:        11,chapter:        22,versenumber:        31,verse:"先是亚兰王吩咐他的三十二个车兵长说：“他们的兵将，无论大小，你们都不可与他们争战，只要与以色列王争战。”"},
{volumn:        11,chapter:        22,versenumber:        32,verse:"车兵长看见约沙法，便说：“这必是以色列王！”就转过去与他争战，约沙法便呼喊。"},
{volumn:        11,chapter:        22,versenumber:        33,verse:"车兵长见不是以色列王，就转去不追他了。"},
{volumn:        11,chapter:        22,versenumber:        34,verse:"有一人随便开弓，恰巧射入以色列王的甲缝里。王对赶车的说：“我受了重伤，你转过车来，拉我出阵吧！”"},
{volumn:        11,chapter:        22,versenumber:        35,verse:"那日，阵势越战越猛，有人扶王站在车上，抵挡亚兰人。到晚上，王就死了，血从伤处流在车中。"},
{volumn:        11,chapter:        22,versenumber:        36,verse:"约在日落的时候，有号令传遍军中，说：“各归本城，各归本地吧！”"},
{volumn:        11,chapter:        22,versenumber:        37,verse:"王既死了，众人将他送到撒玛利亚，就葬在那里；"},
{volumn:        11,chapter:        22,versenumber:        38,verse:"又有人把他的车洗在撒玛利亚的池旁（妓女在那里洗澡），狗来舔他的血，正如耶和华所说的话。"},
{volumn:        11,chapter:        22,versenumber:        39,verse:"亚哈其余的事，凡他所行的和他所修造的象牙宫，并所建筑的一切城邑，都写在以色列诸王记上。"},
{volumn:        11,chapter:        22,versenumber:        40,verse:"亚哈与他列祖同睡。他儿子亚哈谢接续他作王。"},
{volumn:        11,chapter:        22,versenumber:        41,verse:"以色列王亚哈第四年，亚撒的儿子约沙法登基作了犹大王。"},
{volumn:        11,chapter:        22,versenumber:        42,verse:"约沙法登基的时候年三十五岁，在耶路撒冷作王二十五年。他母亲名叫阿苏巴，乃示利希的女儿。"},
{volumn:        11,chapter:        22,versenumber:        43,verse:"约沙法行他父亲亚撒所行的道，不偏离左右，行耶和华眼中看为正的事；只是邱坛还没有废去，百姓仍在那里献祭烧香。"},
{volumn:        11,chapter:        22,versenumber:        44,verse:"约沙法与以色列王和好。"},
{volumn:        11,chapter:        22,versenumber:        45,verse:"约沙法其余的事和他所显出的勇力，并他怎样争战，都写在犹大列王记上。"},
{volumn:        11,chapter:        22,versenumber:        46,verse:"约沙法将他父亲亚撒在世所剩下的娈童都从国中除去了。"},
{volumn:        11,chapter:        22,versenumber:        47,verse:"那时以东没有王，有总督治理。"},
{volumn:        11,chapter:        22,versenumber:        48,verse:"约沙法制造他施船只，要往俄斐去，将金子运来；只是没有去，因为船在以旬·迦别破坏了。"},
{volumn:        11,chapter:        22,versenumber:        49,verse:"亚哈的儿子亚哈谢对约沙法说：“容我的仆人和你的仆人坐船同去吧！”约沙法却不肯。"},
{volumn:        11,chapter:        22,versenumber:        50,verse:"约沙法与列祖同睡。葬在大卫城他列祖的坟地里。他儿子约兰接续他作王。"},
{volumn:        11,chapter:        22,versenumber:        51,verse:"犹大王约沙法十七年，亚哈的儿子亚哈谢在撒玛利亚登基，作以色列王共二年。"},
{volumn:        11,chapter:        22,versenumber:        52,verse:"他行耶和华眼中看为恶的事，效法他的父母，又行尼八的儿子耶罗波安使以色列人陷在罪里的事。"},
{volumn:        11,chapter:        22,versenumber:        53,verse:"他照他父亲一切所行的，侍奉敬拜巴力，惹耶和华以色列　神的怒气。"},
{volumn:        12,chapter:         1,versenumber:         1,verse:"亚哈死后，摩押背叛以色列。"},
{volumn:        12,chapter:         1,versenumber:         2,verse:"亚哈谢在撒玛利亚，一日从楼上的栏杆里掉下来，就病了；于是差遣使者说：“你们去问以革伦的神巴力·西卜，我这病能好不能好。”"},
{volumn:        12,chapter:         1,versenumber:         3,verse:"但耶和华的使者对提斯比人以利亚说：“你起来，去迎着撒玛利亚王的使者，对他们说：‘你们去问以革伦神巴力·西卜，岂因以色列中没有　神吗？’"},
{volumn:        12,chapter:         1,versenumber:         4,verse:"所以耶和华如此说：‘你必不下你所上的床，必定要死！’”以利亚就去了。"},
{volumn:        12,chapter:         1,versenumber:         5,verse:"使者回来见王，王问他们说：“你们为什么回来呢？”"},
{volumn:        12,chapter:         1,versenumber:         6,verse:"使者回答说：“有一个人迎着我们来，对我们说：‘你们回去见差你们来的王，对他说：耶和华如此说，你差人去问以革伦神巴力·西卜，岂因以色列中没有　神吗？所以你必不下所上的床，必定要死。’”"},
{volumn:        12,chapter:         1,versenumber:         7,verse:"王问他们说：“迎着你们来告诉你们这话的，是怎样的人？”"},
{volumn:        12,chapter:         1,versenumber:         8,verse:"回答说：“他身穿毛衣，腰束皮带。”王说：“这必是提斯比人以利亚。”"},
{volumn:        12,chapter:         1,versenumber:         9,verse:"于是，王差遣五十夫长，带领五十人去见以利亚，他就上到以利亚那里；以利亚正坐在山顶上。五十夫长对他说：“神人哪，王吩咐你下来！”"},
{volumn:        12,chapter:         1,versenumber:        10,verse:"以利亚回答说：“我若是神人，愿火从天上降下来，烧灭你和你那五十人！”于是有火从天上降下来，烧灭五十夫长和他那五十人。"},
{volumn:        12,chapter:         1,versenumber:        11,verse:"王第二次差遣一个五十夫长，带领五十人去见以利亚。五十夫长对以利亚说：“神人哪，王吩咐你快快下来！”"},
{volumn:        12,chapter:         1,versenumber:        12,verse:"以利亚回答说：“我若是神人，愿火从天上降下来，烧灭你和你那五十人！”于是　神的火从天上降下来，烧灭五十夫长和他那五十人。"},
{volumn:        12,chapter:         1,versenumber:        13,verse:"王第三次差遣一个五十夫长，带领五十人去。这五十夫长上去，双膝跪在以利亚面前，哀求他说：“神人哪，愿我的性命和你这五十个仆人的性命在你眼前看为宝贵！"},
{volumn:        12,chapter:         1,versenumber:        14,verse:"已经有火从天上降下来，烧灭前两次来的五十夫长和他们各自带的五十人；现在愿我的性命在你眼前看为宝贵！”"},
{volumn:        12,chapter:         1,versenumber:        15,verse:"耶和华的使者对以利亚说：“你同着他下去，不要怕他！”以利亚就起来，同着他下去见王，"},
{volumn:        12,chapter:         1,versenumber:        16,verse:"对王说：“耶和华如此说：‘你差人去问以革伦神巴力·西卜，岂因以色列中没有　神可以求问吗？所以你必不下所上的床，必定要死！’”"},
{volumn:        12,chapter:         1,versenumber:        17,verse:"亚哈谢果然死了，正如耶和华藉以利亚所说的话。因他没有儿子，他兄弟约兰接续他作王，正在犹大王约沙法的儿子约兰第二年。"},
{volumn:        12,chapter:         1,versenumber:        18,verse:"亚哈谢其余所行的事都写在以色列诸王记上。"},
{volumn:        12,chapter:         2,versenumber:         1,verse:"耶和华要用旋风接以利亚升天的时候，以利亚与以利沙从吉甲前往。"},
{volumn:        12,chapter:         2,versenumber:         2,verse:"以利亚对以利沙说：“耶和华差我往伯特利去，你可以在这里等候。”以利沙说：“我指着永生的耶和华，又敢在你面前起誓，我必不离开你。”于是二人下到伯特利。"},
{volumn:        12,chapter:         2,versenumber:         3,verse:"住伯特利的先知门徒出来见以利沙，对他说：“耶和华今日要接你的师傅离开你，你知道不知道？”他说：“我知道，你们不要作声。”"},
{volumn:        12,chapter:         2,versenumber:         4,verse:"以利亚对以利沙说：“耶和华差遣我往耶利哥去，你可以在这里等候。”以利沙说：“我指着永生的耶和华，又敢在你面前起誓，我必不离开你。”于是二人到了耶利哥。"},
{volumn:        12,chapter:         2,versenumber:         5,verse:"住耶利哥的先知门徒就近以利沙，对他说：“耶和华今日要接你的师傅离开你，你知道不知道？”他说：“我知道，你们不要作声。”"},
{volumn:        12,chapter:         2,versenumber:         6,verse:"以利亚对以利沙说：“耶和华差遣我往约旦河去，你可以在这里等候。”以利沙说：“我指着永生的耶和华，又敢在你面前起誓，我必不离开你。”于是二人一同前往。"},
{volumn:        12,chapter:         2,versenumber:         7,verse:"有先知门徒去了五十人，远远地站在他们对面；二人在约旦河边站住。"},
{volumn:        12,chapter:         2,versenumber:         8,verse:"以利亚将自己的外衣卷起来，用以打水，水就左右分开，二人走干地而过。"},
{volumn:        12,chapter:         2,versenumber:         9,verse:"过去之后，以利亚对以利沙说：“我未曾被接去离开你，你要我为你做什么，只管求我。”以利沙说：“愿感动你的灵加倍地感动我。”"},
{volumn:        12,chapter:         2,versenumber:        10,verse:"以利亚说：“你所求的难得。虽然如此，我被接去离开你的时候，你若看见我，就必得着；不然，必得不着了。”"},
{volumn:        12,chapter:         2,versenumber:        11,verse:"他们正走着说话，忽有火车火马将二人隔开，以利亚就乘旋风升天去了。"},
{volumn:        12,chapter:         2,versenumber:        12,verse:"以利沙看见，就呼叫说：“我父啊！我父啊！以色列的战车马兵啊！”以后不再见他了。于是以利沙把自己的衣服撕为两片。"},
{volumn:        12,chapter:         2,versenumber:        13,verse:"他拾起以利亚身上掉下来的外衣，回去站在约旦河边。"},
{volumn:        12,chapter:         2,versenumber:        14,verse:"他用以利亚身上掉下来的外衣打水，说：“耶和华，以利亚的　神在哪里呢？”打水之后，水也左右分开，以利沙就过来了。"},
{volumn:        12,chapter:         2,versenumber:        15,verse:"住耶利哥的先知门徒从对面看见他，就说：“感动以利亚的灵感动以利沙了。”他们就来迎接他，在他面前俯伏于地，"},
{volumn:        12,chapter:         2,versenumber:        16,verse:"对他说：“仆人们这里有五十个壮士，求你容他们去寻找你师傅，或者耶和华的灵将他提起来，投在某山某谷。”以利沙说：“你们不必打发人去。”"},
{volumn:        12,chapter:         2,versenumber:        17,verse:"他们再三催促他，他难以推辞，就说：“你们打发人去吧！”他们便打发五十人去，寻找了三天，也没有找着。"},
{volumn:        12,chapter:         2,versenumber:        18,verse:"以利沙仍然在耶利哥等候他们回到他那里；他对他们说：“我岂没有告诉你们不必去吗？”"},
{volumn:        12,chapter:         2,versenumber:        19,verse:"耶利哥城的人对以利沙说：“这城的地势美好，我主看见了；只是水恶劣，土产不熟而落。”"},
{volumn:        12,chapter:         2,versenumber:        20,verse:"以利沙说：“你们拿一个新瓶来，装盐给我”；他们就拿来给他。"},
{volumn:        12,chapter:         2,versenumber:        21,verse:"他出到水源，将盐倒在水中，说：“耶和华如此说：‘我治好了这水，从此必不再使人死，也不再使地土不生产。’”"},
{volumn:        12,chapter:         2,versenumber:        22,verse:"于是那水治好了，直到今日，正如以利沙所说的。"},
{volumn:        12,chapter:         2,versenumber:        23,verse:"以利沙从那里上伯特利去，正上去的时候，有些童子从城里出来，戏笑他说：“秃头的上去吧！秃头的上去吧！”"},
{volumn:        12,chapter:         2,versenumber:        24,verse:"他回头看见，就奉耶和华的名咒诅他们。于是有两只母熊从林中出来，撕裂他们中间四十二个童子。"},
{volumn:        12,chapter:         2,versenumber:        25,verse:"以利沙从伯特利上迦密山，又从迦密山回到撒玛利亚。"},
{volumn:        12,chapter:         3,versenumber:         1,verse:"犹大王约沙法十八年，亚哈的儿子约兰在撒玛利亚登基作了以色列王十二年。"},
{volumn:        12,chapter:         3,versenumber:         2,verse:"他行耶和华眼中看为恶的事，但不至像他父母所行的，因为除掉他父所造巴力的柱像。"},
{volumn:        12,chapter:         3,versenumber:         3,verse:"然而，他贴近尼八的儿子耶罗波安使以色列人陷在罪里的那罪，总不离开。"},
{volumn:        12,chapter:         3,versenumber:         4,verse:"摩押王米沙牧养许多羊，每年将十万羊羔的毛和十万公绵羊的毛给以色列王进贡。"},
{volumn:        12,chapter:         3,versenumber:         5,verse:"亚哈死后，摩押王背叛以色列王。"},
{volumn:        12,chapter:         3,versenumber:         6,verse:"那时约兰王出撒玛利亚，数点以色列众人。"},
{volumn:        12,chapter:         3,versenumber:         7,verse:"前行的时候，差人去见犹大王约沙法，说：“摩押王背叛我，你肯同我去攻打摩押吗？”他说：“我肯上去，你我不分彼此，我的民与你的民一样，我的马与你的马一样。”"},
{volumn:        12,chapter:         3,versenumber:         8,verse:"约兰说：“我们从哪条路上去呢？”回答说：“从以东旷野的路上去。”"},
{volumn:        12,chapter:         3,versenumber:         9,verse:"于是，以色列王和犹大王，并以东王，都一同去绕行七日的路程；军队和所带的牲畜没有水喝。"},
{volumn:        12,chapter:         3,versenumber:        10,verse:"以色列王说：“哀哉！耶和华招聚我们这三王，乃要交在摩押人的手里。”"},
{volumn:        12,chapter:         3,versenumber:        11,verse:"约沙法说：“这里不是有耶和华的先知吗？我们可以托他求问耶和华。”以色列王的一个臣子回答说：“这里有沙法的儿子以利沙，就是从前服侍以利亚的（原文是倒水在以利亚手上的）。”"},
{volumn:        12,chapter:         3,versenumber:        12,verse:"约沙法说：“他必有耶和华的话。”于是以色列王和约沙法，并以东王都下去见他。"},
{volumn:        12,chapter:         3,versenumber:        13,verse:"以利沙对以色列王说：“我与你何干？去问你父亲的先知和你母亲的先知吧！”以色列王对他说：“不要这样说，耶和华招聚我们这三王，乃要交在摩押人的手里。”"},
{volumn:        12,chapter:         3,versenumber:        14,verse:"以利沙说：“我指着所侍奉永生的万军耶和华起誓，我若不看犹大王约沙法的情面，必不理你，不顾你。"},
{volumn:        12,chapter:         3,versenumber:        15,verse:"现在你们给我找一个弹琴的来。”弹琴的时候，耶和华的灵（原文是手）就降在以利沙身上。"},
{volumn:        12,chapter:         3,versenumber:        16,verse:"他便说：“耶和华如此说：‘你们要在这谷中满处挖沟；"},
{volumn:        12,chapter:         3,versenumber:        17,verse:"因为耶和华如此说：你们虽不见风，不见雨，这谷必满了水，使你们和牲畜有水喝。’"},
{volumn:        12,chapter:         3,versenumber:        18,verse:"在耶和华眼中这还算为小事，他也必将摩押人交在你们手中。"},
{volumn:        12,chapter:         3,versenumber:        19,verse:"你们必攻破一切坚城美邑，砍伐各种佳树，塞住一切水泉，用石头糟踏一切美田。”"},
{volumn:        12,chapter:         3,versenumber:        20,verse:"次日早晨，约在献祭的时候，有水从以东而来，遍地就满了水。"},
{volumn:        12,chapter:         3,versenumber:        21,verse:"摩押众人听见这三王上来要与他们争战，凡能顶盔贯甲的，无论老少，尽都聚集站在边界上。"},
{volumn:        12,chapter:         3,versenumber:        22,verse:"次日早晨，日光照在水上，摩押人起来，看见对面水红如血，"},
{volumn:        12,chapter:         3,versenumber:        23,verse:"就说：“这是血啊！必是三王互相击杀，俱都灭亡。摩押人哪，我们现在去抢夺财物吧！”"},
{volumn:        12,chapter:         3,versenumber:        24,verse:"摩押人到了以色列营，以色列人就起来攻打他们，以致他们在以色列人面前逃跑。以色列人往前追杀摩押人，直杀入摩押的境内，"},
{volumn:        12,chapter:         3,versenumber:        25,verse:"拆毁摩押的城邑，各人抛石填满一切美田，塞住一切水泉，砍伐各种佳树，只剩下吉珥·哈列设的石墙；甩石的兵在四围攻打那城。"},
{volumn:        12,chapter:         3,versenumber:        26,verse:"摩押王见阵势甚大，难以对敌，就率领七百拿刀的兵，要冲过阵去到以东王那里，却是不能；"},
{volumn:        12,chapter:         3,versenumber:        27,verse:"便将那应当接续他作王的长子，在城上献为燔祭。以色列人遭遇耶和华的大怒（或译：招人痛恨），于是三王离开摩押王，各回本国去了。"},
{volumn:        12,chapter:         4,versenumber:         1,verse:"有一个先知门徒的妻哀求以利沙说：“你仆人我丈夫死了，他敬畏耶和华是你所知道的。现在有债主来，要取我两个儿子作奴仆。”"},
{volumn:        12,chapter:         4,versenumber:         2,verse:"以利沙问她说：“我可以为你做什么呢？你告诉我，你家里有什么？”她说：“婢女家中除了一瓶油之外，没有什么。”"},
{volumn:        12,chapter:         4,versenumber:         3,verse:"以利沙说：“你去，向你众邻舍借空器皿，不要少借；"},
{volumn:        12,chapter:         4,versenumber:         4,verse:"回到家里，关上门，你和你儿子在里面将油倒在所有的器皿里，倒满了的放在一边。”"},
{volumn:        12,chapter:         4,versenumber:         5,verse:"于是，妇人离开以利沙去了，关上门，自己和儿子在里面；儿子把器皿拿来，她就倒油。"},
{volumn:        12,chapter:         4,versenumber:         6,verse:"器皿都满了，她对儿子说：“再给我拿器皿来。”儿子说：“再没有器皿了。”油就止住了。"},
{volumn:        12,chapter:         4,versenumber:         7,verse:"妇人去告诉神人，神人说：“你去卖油还债，所剩的你和你儿子可以靠着度日。”"},
{volumn:        12,chapter:         4,versenumber:         8,verse:"一日，以利沙走到书念，在那里有一个大户的妇人强留他吃饭。此后，以利沙每从那里经过就进去吃饭。"},
{volumn:        12,chapter:         4,versenumber:         9,verse:"妇人对丈夫说：“我看出那常从我们这里经过的是圣洁的神人。"},
{volumn:        12,chapter:         4,versenumber:        10,verse:"我们可以为他在墙上盖一间小楼，在其中安放床榻、桌子、椅子、灯台，他来到我们这里，就可以住在其间。”"},
{volumn:        12,chapter:         4,versenumber:        11,verse:"一日，以利沙来到那里，就进了那楼躺卧。"},
{volumn:        12,chapter:         4,versenumber:        12,verse:"以利沙吩咐仆人基哈西说：“你叫这书念妇人来。”他就把妇人叫了来，妇人站在以利沙面前。"},
{volumn:        12,chapter:         4,versenumber:        13,verse:"以利沙吩咐仆人说：“你对她说：你既为我们费了许多心思，可以为你做什么呢？你向王或元帅有所求的没有？”她回答说：“我在我本乡安居无事。”"},
{volumn:        12,chapter:         4,versenumber:        14,verse:"以利沙对仆人说：“究竟当为她做什么呢？”基哈西说：“她没有儿子，她丈夫也老了。”"},
{volumn:        12,chapter:         4,versenumber:        15,verse:"以利沙说：“再叫她来。”于是叫了她来，她就站在门口。"},
{volumn:        12,chapter:         4,versenumber:        16,verse:"以利沙说：“明年到这时候，你必抱一个儿子。”她说：“神人，我主啊，不要那样欺哄婢女。”"},
{volumn:        12,chapter:         4,versenumber:        17,verse:"妇人果然怀孕，到了那时候，生了一个儿子，正如以利沙所说的。"},
{volumn:        12,chapter:         4,versenumber:        18,verse:"孩子渐渐长大，一日到他父亲和收割的人那里，"},
{volumn:        12,chapter:         4,versenumber:        19,verse:"他对父亲说：“我的头啊，我的头啊！”他父亲对仆人说：“把他抱到他母亲那里。”"},
{volumn:        12,chapter:         4,versenumber:        20,verse:"仆人抱去，交给他母亲；孩子坐在母亲的膝上，到晌午就死了。"},
{volumn:        12,chapter:         4,versenumber:        21,verse:"他母亲抱他上了楼，将他放在神人的床上，关上门出来，"},
{volumn:        12,chapter:         4,versenumber:        22,verse:"呼叫她丈夫说：“你叫一个仆人给我牵一匹驴来，我要快快地去见神人，就回来。”"},
{volumn:        12,chapter:         4,versenumber:        23,verse:"丈夫说：“今日不是月朔，也不是安息日，你为何要去见他呢？”妇人说：“平安无事。”"},
{volumn:        12,chapter:         4,versenumber:        24,verse:"于是备上驴，对仆人说：“你快快赶着走，我若不吩咐你，就不要迟慢。”"},
{volumn:        12,chapter:         4,versenumber:        25,verse:"妇人就往迦密山去见神人。神人远远地看见她，对仆人基哈西说：“看哪，书念的妇人来了！"},
{volumn:        12,chapter:         4,versenumber:        26,verse:"你跑去迎接她，问她说：你平安吗？你丈夫平安吗？孩子平安吗？”她说：“平安。”"},
{volumn:        12,chapter:         4,versenumber:        27,verse:"妇人上了山，到神人那里，就抱住神人的脚。基哈西前来要推开她，神人说：“由她吧！因为她心里愁苦，耶和华向我隐瞒，没有指示我。”"},
{volumn:        12,chapter:         4,versenumber:        28,verse:"妇人说：“我何尝向我主求过儿子呢？我岂没有说过，不要欺哄我吗？”"},
{volumn:        12,chapter:         4,versenumber:        29,verse:"以利沙吩咐基哈西说：“你束上腰，手拿我的杖前去；若遇见人，不要向他问安；人若向你问安，也不要回答；要把我的杖放在孩子脸上。”"},
{volumn:        12,chapter:         4,versenumber:        30,verse:"孩子的母亲说：“我指着永生的耶和华，又敢在你面前起誓，我必不离开你。”于是以利沙起身，随着她去了。"},
{volumn:        12,chapter:         4,versenumber:        31,verse:"基哈西先去，把杖放在孩子脸上，却没有声音，也没有动静。基哈西就迎着以利沙回来，告诉他说：“孩子还没有醒过来。”"},
{volumn:        12,chapter:         4,versenumber:        32,verse:"以利沙来到，进了屋子，看见孩子死了，放在自己的床上。"},
{volumn:        12,chapter:         4,versenumber:        33,verse:"他就关上门，只有自己和孩子在里面，他便祈祷耶和华，"},
{volumn:        12,chapter:         4,versenumber:        34,verse:"上床伏在孩子身上，口对口，眼对眼，手对手；既伏在孩子身上，孩子的身体就渐渐温和了。"},
{volumn:        12,chapter:         4,versenumber:        35,verse:"然后他下来，在屋里来往走了一趟，又上去伏在孩子身上，孩子打了七个喷嚏，就睁开眼睛了。"},
{volumn:        12,chapter:         4,versenumber:        36,verse:"以利沙叫基哈西说：“你叫这书念妇人来”；于是叫了她来。以利沙说：“将你儿子抱起来。”"},
{volumn:        12,chapter:         4,versenumber:        37,verse:"妇人就进来，在以利沙脚前俯伏于地，抱起她儿子出去了。"},
{volumn:        12,chapter:         4,versenumber:        38,verse:"以利沙又来到吉甲，那地正有饥荒。先知门徒坐在他面前，他吩咐仆人说：“你将大锅放在火上，给先知门徒熬汤。”"},
{volumn:        12,chapter:         4,versenumber:        39,verse:"有一个人去到田野掐菜，遇见一棵野瓜藤，就摘了一兜野瓜回来，切了搁在熬汤的锅中，因为他们不知道是什么东西；"},
{volumn:        12,chapter:         4,versenumber:        40,verse:"倒出来给众人吃，吃的时候，都喊叫说：“神人哪，锅中有致死的毒物！”所以众人不能吃了。"},
{volumn:        12,chapter:         4,versenumber:        41,verse:"以利沙说：“拿点面来”，就把面撒在锅中，说：“倒出来，给众人吃吧！”锅中就没有毒了。"},
{volumn:        12,chapter:         4,versenumber:        42,verse:"有一个人从巴力·沙利沙来，带着初熟大麦做的饼二十个，并新穗子，装在口袋里送给神人。神人说：“把这些给众人吃。”"},
{volumn:        12,chapter:         4,versenumber:        43,verse:"仆人说：“这一点岂可摆给一百人吃呢？”以利沙说：“你只管给众人吃吧！因为耶和华如此说，众人必吃了，还剩下。”"},
{volumn:        12,chapter:         4,versenumber:        44,verse:"仆人就摆在众人面前，他们吃了，果然还剩下，正如耶和华所说的。"},
{volumn:        12,chapter:         5,versenumber:         1,verse:"亚兰王的元帅乃缦在他主人面前为尊为大，因耶和华曾藉他使亚兰人得胜；他又是大能的勇士，只是长了大麻风。"},
{volumn:        12,chapter:         5,versenumber:         2,verse:"先前亚兰人成群地出去，从以色列国掳了一个小女子，这女子就服侍乃缦的妻。"},
{volumn:        12,chapter:         5,versenumber:         3,verse:"她对主母说：“巴不得我主人去见撒玛利亚的先知，必能治好他的大麻风。”"},
{volumn:        12,chapter:         5,versenumber:         4,verse:"乃缦进去，告诉他主人说，以色列国的女子如此如此说。"},
{volumn:        12,chapter:         5,versenumber:         5,verse:"亚兰王说：“你可以去，我也达信于以色列王。”于是乃缦带银子十他连得，金子六千舍客勒，衣裳十套，就去了；"},
{volumn:        12,chapter:         5,versenumber:         6,verse:"且带信给以色列王，信上说：“我打发臣仆乃缦去见你，你接到这信，就要治好他的大麻风。”"},
{volumn:        12,chapter:         5,versenumber:         7,verse:"以色列王看了信就撕裂衣服，说：“我岂是　神，能使人死使人活呢？这人竟打发人来，叫我治好他的大麻风。你们看一看，这人何以寻隙攻击我呢？”"},
{volumn:        12,chapter:         5,versenumber:         8,verse:"神人以利沙听见以色列王撕裂衣服，就打发人去见王，说：“你为什么撕了衣服呢？可使那人到我这里来，他就知道以色列中有先知了。”"},
{volumn:        12,chapter:         5,versenumber:         9,verse:"于是，乃缦带着车马到了以利沙的家，站在门前。"},
{volumn:        12,chapter:         5,versenumber:        10,verse:"以利沙打发一个使者，对乃缦说：“你去在约旦河中沐浴七回，你的肉就必复原，而得洁净。”"},
{volumn:        12,chapter:         5,versenumber:        11,verse:"乃缦却发怒走了，说：“我想他必定出来见我，站着求告耶和华他　神的名，在患处以上摇手，治好这大麻风。"},
{volumn:        12,chapter:         5,versenumber:        12,verse:"大马士革的河亚罢拿和法珥法岂不比以色列的一切水更好吗？我在那里沐浴不得洁净吗？”于是气忿忿地转身去了。"},
{volumn:        12,chapter:         5,versenumber:        13,verse:"他的仆人进前来，对他说：“我父啊，先知若吩咐你做一件大事，你岂不做吗？何况说你去沐浴而得洁净呢？”"},
{volumn:        12,chapter:         5,versenumber:        14,verse:"于是乃缦下去，照着神人的话，在约旦河里沐浴七回；他的肉复原，好像小孩子的肉，他就洁净了。"},
{volumn:        12,chapter:         5,versenumber:        15,verse:"乃缦带着一切跟随他的人，回到神人那里，站在他面前，说：“如今我知道，除了以色列之外，普天下没有　神。现在求你收点仆人的礼物。”"},
{volumn:        12,chapter:         5,versenumber:        16,verse:"以利沙说：“我指着所侍奉永生的耶和华起誓，我必不受。”乃缦再三地求他，他却不受。"},
{volumn:        12,chapter:         5,versenumber:        17,verse:"乃缦说：“你若不肯受，请将两骡子驮的土赐给仆人。从今以后，仆人必不再将燔祭或平安祭献与别神，只献给耶和华。"},
{volumn:        12,chapter:         5,versenumber:        18,verse:"惟有一件事，愿耶和华饶恕你仆人：我主人进临门庙叩拜的时候，我用手搀他在临门庙，我也屈身。我在临门庙屈身的这事，愿耶和华饶恕我。”"},
{volumn:        12,chapter:         5,versenumber:        19,verse:"以利沙对他说：“你可以平平安安地回去！”乃缦就离开他去了；走了不远，"},
{volumn:        12,chapter:         5,versenumber:        20,verse:"神人以利沙的仆人基哈西心里说：“我主人不愿从这亚兰人乃缦手里受他带来的礼物，我指着永生的耶和华起誓，我必跑去追上他，向他要些。”"},
{volumn:        12,chapter:         5,versenumber:        21,verse:"于是基哈西追赶乃缦。乃缦看见有人追赶，就急忙下车迎着他，说：“都平安吗？”"},
{volumn:        12,chapter:         5,versenumber:        22,verse:"说：“都平安。我主人打发我来说：‘刚才有两个少年人，是先知门徒，从以法莲山地来见我，请你赐他们一他连得银子，两套衣裳。’”"},
{volumn:        12,chapter:         5,versenumber:        23,verse:"乃缦说：“请受二他连得”；再三地请受，便将二他连得银子装在两个口袋里，又将两套衣裳交给两个仆人；他们就在基哈西前头抬着走。"},
{volumn:        12,chapter:         5,versenumber:        24,verse:"到了山冈，基哈西从他们手中接过来，放在屋里，打发他们回去。"},
{volumn:        12,chapter:         5,versenumber:        25,verse:"基哈西进去，站在他主人面前。以利沙问他说：“基哈西你从哪里来？”回答说：“仆人没有往哪里去。”"},
{volumn:        12,chapter:         5,versenumber:        26,verse:"以利沙对他说：“那人下车转回迎你的时候，我的心岂没有去呢？这岂是受银子、衣裳、买橄榄园、葡萄园、牛羊、仆婢的时候呢？"},
{volumn:        12,chapter:         5,versenumber:        27,verse:"因此，乃缦的大麻风必沾染你和你的后裔，直到永远。”基哈西从以利沙面前退出去，就长了大麻风，像雪那样白。"},
{volumn:        12,chapter:         6,versenumber:         1,verse:"先知门徒对以利沙说：“看哪，我们同你所住的地方过于窄小，"},
{volumn:        12,chapter:         6,versenumber:         2,verse:"求你容我们往约旦河去，各人从那里取一根木料建造房屋居住。”他说：“你们去吧！”"},
{volumn:        12,chapter:         6,versenumber:         3,verse:"有一人说：“求你与仆人同去。”回答说：“我可以去。”"},
{volumn:        12,chapter:         6,versenumber:         4,verse:"于是以利沙与他们同去。到了约旦河，就砍伐树木。"},
{volumn:        12,chapter:         6,versenumber:         5,verse:"有一人砍树的时候，斧头掉在水里，他就呼叫说：“哀哉！我主啊，这斧子是借的。”"},
{volumn:        12,chapter:         6,versenumber:         6,verse:"神人问说：“掉在哪里了？”他将那地方指给以利沙看。以利沙砍了一根木头，抛在水里，斧头就漂上来了。"},
{volumn:        12,chapter:         6,versenumber:         7,verse:"以利沙说：“拿起来吧！”那人就伸手拿起来了。"},
{volumn:        12,chapter:         6,versenumber:         8,verse:"亚兰王与以色列人争战，和他的臣仆商议说：“我要在某处某处安营。”"},
{volumn:        12,chapter:         6,versenumber:         9,verse:"神人打发人去见以色列王，说：“你要谨慎，不要从某处经过，因为亚兰人从那里下来了。”"},
{volumn:        12,chapter:         6,versenumber:        10,verse:"以色列王差人去窥探神人所告诉所警戒他去的地方，就防备未受其害，不止一两次。"},
{volumn:        12,chapter:         6,versenumber:        11,verse:"亚兰王因这事心里惊疑，召了臣仆来，对他们说：“我们这里有谁帮助以色列王，你们不指给我吗？”"},
{volumn:        12,chapter:         6,versenumber:        12,verse:"有一个臣仆说：“我主，我王！无人帮助他，只有以色列中的先知以利沙，将王在卧房所说的话告诉以色列王了。”"},
{volumn:        12,chapter:         6,versenumber:        13,verse:"王说：“你们去探他在哪里，我好打发人去捉拿他。”有人告诉王说：“他在多坍。”"},
{volumn:        12,chapter:         6,versenumber:        14,verse:"王就打发车马和大军往那里去，夜间到了，围困那城。"},
{volumn:        12,chapter:         6,versenumber:        15,verse:"神人的仆人清早起来出去，看见车马军兵围困了城。仆人对神人说：“哀哉！我主啊，我们怎样行才好呢？”"},
{volumn:        12,chapter:         6,versenumber:        16,verse:"神人说：“不要惧怕！与我们同在的比与他们同在的更多。”"},
{volumn:        12,chapter:         6,versenumber:        17,verse:"以利沙祷告说：“耶和华啊，求你开这少年人的眼目，使他能看见。”耶和华开他的眼目，他就看见满山有火车火马围绕以利沙。"},
{volumn:        12,chapter:         6,versenumber:        18,verse:"敌人下到以利沙那里，以利沙祷告耶和华说：“求你使这些人的眼目昏迷。”耶和华就照以利沙的话，使他们的眼目昏迷。"},
{volumn:        12,chapter:         6,versenumber:        19,verse:"以利沙对他们说：“这不是那道，也不是那城；你们跟我去，我必领你们到所寻找的人那里。”于是领他们到了撒玛利亚。"},
{volumn:        12,chapter:         6,versenumber:        20,verse:"他们进了撒玛利亚，以利沙祷告说：“耶和华啊，求你开这些人的眼目，使他们能看见。”耶和华开他们的眼目，他们就看见了，不料，是在撒玛利亚的城中。"},
{volumn:        12,chapter:         6,versenumber:        21,verse:"以色列王见了他们，就问以利沙说：“我父啊，我可以击杀他们吗？”"},
{volumn:        12,chapter:         6,versenumber:        22,verse:"回答说：“不可击杀他们！就是你用刀用弓掳来的，岂可击杀他们吗（或译：也不可击杀，何况这些人呢）？当在他们面前设摆饮食，使他们吃喝回到他们的主人那里。”"},
{volumn:        12,chapter:         6,versenumber:        23,verse:"王就为他们预备了许多食物；他们吃喝完了，打发他们回到他们主人那里。从此，亚兰军不再犯以色列境了。"},
{volumn:        12,chapter:         6,versenumber:        24,verse:"此后，亚兰王便·哈达聚集他的全军，上来围困撒玛利亚。"},
{volumn:        12,chapter:         6,versenumber:        25,verse:"于是撒玛利亚被围困，有饥荒，甚至一个驴头值银八十舍客勒，二升鸽子粪值银五舍客勒。"},
{volumn:        12,chapter:         6,versenumber:        26,verse:"一日，以色列王在城上经过，有一个妇人向他呼叫说：“我主，我王啊！求你帮助。”"},
{volumn:        12,chapter:         6,versenumber:        27,verse:"王说：“耶和华不帮助你，我从何处帮助你？是从禾场，是从酒榨呢？”"},
{volumn:        12,chapter:         6,versenumber:        28,verse:"王问妇人说：“你有什么苦处？”她回答说：“这妇人对我说：‘将你的儿子取来，我们今日可以吃，明日可以吃我的儿子。’"},
{volumn:        12,chapter:         6,versenumber:        29,verse:"我们就煮了我的儿子吃了。次日我对她说：‘要将你的儿子取来，我们可以吃。’她却将她的儿子藏起来了。”"},
{volumn:        12,chapter:         6,versenumber:        30,verse:"王听见妇人的话，就撕裂衣服；（王在城上经过）百姓看见王贴身穿着麻衣。"},
{volumn:        12,chapter:         6,versenumber:        31,verse:"王说：“我今日若容沙法的儿子以利沙的头仍在他项上，愿　神重重地降罚与我！”"},
{volumn:        12,chapter:         6,versenumber:        32,verse:"那时，以利沙正坐在家中，长老也与他同坐。王打发一个伺候他的人去；他还没有到，以利沙对长老说：“你们看这凶手之子，打发人来斩我的头；你们看着使者来到，就关上门，用门将他推出去。在他后头不是有他主人脚步的响声吗？”"},
{volumn:        12,chapter:         6,versenumber:        33,verse:"正说话的时候，使者来到，王也到了，说：“这灾祸是从耶和华那里来的，我何必再仰望耶和华呢？”"},
{volumn:        12,chapter:         7,versenumber:         1,verse:"以利沙说：“你们要听耶和华的话，耶和华如此说：明日约到这时候，在撒玛利亚城门口，一细亚细面要卖银一舍客勒，二细亚大麦也要卖银一舍客勒。”"},
{volumn:        12,chapter:         7,versenumber:         2,verse:"有一个搀扶王的军长对神人说：“即便耶和华使天开了窗户，也不能有这事。”以利沙说：“你必亲眼看见，却不得吃。”"},
{volumn:        12,chapter:         7,versenumber:         3,verse:"在城门那里有四个长大麻风的人，他们彼此说：“我们为何坐在这里等死呢？"},
{volumn:        12,chapter:         7,versenumber:         4,verse:"我们若说，进城去吧！城里有饥荒，必死在那里；若在这里坐着不动，也必是死。来吧，我们去投降亚兰人的军队，他们若留我们的活命，就活着；若杀我们，就死了吧！”"},
{volumn:        12,chapter:         7,versenumber:         5,verse:"黄昏的时候，他们起来往亚兰人的营盘去；到了营边，不见一人在那里。"},
{volumn:        12,chapter:         7,versenumber:         6,verse:"因为主使亚兰人的军队听见车马的声音，是大军的声音；他们就彼此说：“这必是以色列王贿买赫人的诸王和埃及人的诸王来攻击我们。”"},
{volumn:        12,chapter:         7,versenumber:         7,verse:"所以，在黄昏的时候他们起来逃跑，撇下帐棚、马、驴，营盘照旧，只顾逃命。"},
{volumn:        12,chapter:         7,versenumber:         8,verse:"那些长大麻风的到了营边，进了帐棚，吃了喝了，且从其中拿出金银和衣服来，去收藏了；回来又进了一座帐棚，从其中拿出财物来去收藏了。"},
{volumn:        12,chapter:         7,versenumber:         9,verse:"那时，他们彼此说：“我们所做的不好！今日是有好信息的日子，我们竟不作声！若等到天亮，罪必临到我们。来吧，我们与王家报信去！”"},
{volumn:        12,chapter:         7,versenumber:        10,verse:"他们就去叫守城门的，告诉他们说：“我们到了亚兰人的营，不见一人在那里，也无人声，只有拴着的马和驴，帐棚都照旧。”"},
{volumn:        12,chapter:         7,versenumber:        11,verse:"守城门的叫了众守门的人来，他们就进去与王家报信。"},
{volumn:        12,chapter:         7,versenumber:        12,verse:"王夜间起来，对臣仆说：“我告诉你们亚兰人向我们如何行。他们知道我们饥饿，所以离营，埋伏在田野，说：‘以色列人出城的时候，我们就活捉他们，得以进城。’”"},
{volumn:        12,chapter:         7,versenumber:        13,verse:"有一个臣仆对王说：“我们不如用城里剩下之马中的五匹马（马和城里剩下的以色列人都是一样，快要灭绝），打发人去窥探。”"},
{volumn:        12,chapter:         7,versenumber:        14,verse:"于是取了两辆车和马，王差人去追寻亚兰军，说：“你们去窥探窥探。”"},
{volumn:        12,chapter:         7,versenumber:        15,verse:"他们就追寻到约旦河，看见满道上都是亚兰人急跑时丢弃的衣服器具，使者就回来报告王。"},
{volumn:        12,chapter:         7,versenumber:        16,verse:"众人就出去，掳掠亚兰人的营盘。于是一细亚细面卖银一舍客勒，二细亚大麦也卖银一舍客勒，正如耶和华所说的。"},
{volumn:        12,chapter:         7,versenumber:        17,verse:"王派搀扶他的那军长在城门口弹压，众人在那里将他践踏，他就死了，正如神人在王下来见他的时候所说的。"},
{volumn:        12,chapter:         7,versenumber:        18,verse:"神人曾对王说：“明日约到这时候，在撒玛利亚城门口，二细亚大麦要卖银一舍客勒，一细亚细面也要卖银一舍客勒。”"},
{volumn:        12,chapter:         7,versenumber:        19,verse:"那军长对神人说：“即便耶和华使天开了窗户，也不能有这事。”神人说：“你必亲眼看见，却不得吃。”"},
{volumn:        12,chapter:         7,versenumber:        20,verse:"这话果然应验在他身上；因为众人在城门口将他践踏，他就死了。"},
{volumn:        12,chapter:         8,versenumber:         1,verse:"以利沙曾对所救活之子的那妇人说：“你和你的全家要起身往你可住的地方去住，因为耶和华命饥荒降在这地七年。”"},
{volumn:        12,chapter:         8,versenumber:         2,verse:"妇人就起身，照神人的话带着全家往非利士地去，住了七年。"},
{volumn:        12,chapter:         8,versenumber:         3,verse:"七年完了，那妇人从非利士地回来，就出去为自己的房屋田地哀告王。"},
{volumn:        12,chapter:         8,versenumber:         4,verse:"那时王正与神人的仆人基哈西说：“请你将以利沙所行的一切大事告诉我。”"},
{volumn:        12,chapter:         8,versenumber:         5,verse:"基哈西告诉王以利沙如何使死人复活，恰巧以利沙所救活、她儿子的那妇人为自己的房屋田地来哀告王。基哈西说：“我主我王，这就是那妇人，这是她的儿子，就是以利沙所救活的。”"},
{volumn:        12,chapter:         8,versenumber:         6,verse:"王问那妇人，她就把那事告诉王。于是王为她派一个太监，说：“凡属这妇人的都还给她，自从她离开本地直到今日，她田地的出产也都还给她。”"},
{volumn:        12,chapter:         8,versenumber:         7,verse:"以利沙来到大马士革，亚兰王便·哈达正患病。有人告诉王说：“神人来到这里了。”"},
{volumn:        12,chapter:         8,versenumber:         8,verse:"王就吩咐哈薛说：“你带着礼物去见神人，托他求问耶和华，我这病能好不能好？”"},
{volumn:        12,chapter:         8,versenumber:         9,verse:"于是哈薛用四十个骆驼，驮着大马士革的各样美物为礼物，去见以利沙。到了他那里，站在他面前，说：“你儿子亚兰王便·哈达打发我来见你，他问说：‘我这病能好不能好？’”"},
{volumn:        12,chapter:         8,versenumber:        10,verse:"以利沙对哈薛说：“你回去告诉他说，这病必能好；但耶和华指示我，他必要死。”"},
{volumn:        12,chapter:         8,versenumber:        11,verse:"神人定睛看着哈薛，甚至他惭愧。神人就哭了；"},
{volumn:        12,chapter:         8,versenumber:        12,verse:"哈薛说：“我主为什么哭？”回答说：“因为我知道你必苦害以色列人，用火焚烧他们的保障，用刀杀死他们的壮丁，摔死他们的婴孩，剖开他们的孕妇。”"},
{volumn:        12,chapter:         8,versenumber:        13,verse:"哈薛说：“你仆人算什么，不过是一条狗，焉能行这大事呢？”以利沙回答说：“耶和华指示我，你必作亚兰王。”"},
{volumn:        12,chapter:         8,versenumber:        14,verse:"哈薛离开以利沙，回去见他的主人。主人问他说：“以利沙对你说什么？”回答说：“他告诉我你必能好。”"},
{volumn:        12,chapter:         8,versenumber:        15,verse:"次日，哈薛拿被窝浸在水中，蒙住王的脸，王就死了。于是哈薛篡了他的位。"},
{volumn:        12,chapter:         8,versenumber:        16,verse:"以色列王亚哈的儿子约兰第五年，犹大王约沙法还在位的时候，约沙法的儿子约兰登基作了犹大王。"},
{volumn:        12,chapter:         8,versenumber:        17,verse:"约兰登基的时候年三十二岁，在耶路撒冷作王八年。"},
{volumn:        12,chapter:         8,versenumber:        18,verse:"他行以色列诸王所行的，与亚哈家一样；因为他娶了亚哈的女儿为妻，行耶和华眼中看为恶的事。"},
{volumn:        12,chapter:         8,versenumber:        19,verse:"耶和华却因他仆人大卫的缘故，仍不肯灭绝犹大，照他所应许大卫的话，永远赐灯光与他的子孙。"},
{volumn:        12,chapter:         8,versenumber:        20,verse:"约兰年间，以东人背叛犹大，脱离他的权下，自己立王。"},
{volumn:        12,chapter:         8,versenumber:        21,verse:"约兰率领所有的战车往撒益去，夜间起来，攻打围困他的以东人和车兵长；犹大兵就逃跑，各回各家去了。"},
{volumn:        12,chapter:         8,versenumber:        22,verse:"这样，以东人背叛犹大，脱离他的权下，直到今日。那时立拿人也背叛了。"},
{volumn:        12,chapter:         8,versenumber:        23,verse:"约兰其余的事，凡他所行的，都写在犹大列王记上。"},
{volumn:        12,chapter:         8,versenumber:        24,verse:"约兰与他列祖同睡，葬在大卫城他列祖的坟地里。他儿子亚哈谢接续他作王。"},
{volumn:        12,chapter:         8,versenumber:        25,verse:"以色列王亚哈的儿子约兰十二年，犹大王约兰的儿子亚哈谢登基。"},
{volumn:        12,chapter:         8,versenumber:        26,verse:"他登基的时候年二十二岁，在耶路撒冷作王一年。他母亲名叫亚他利雅，是以色列王暗利的孙女。"},
{volumn:        12,chapter:         8,versenumber:        27,verse:"亚哈谢效法亚哈家行耶和华眼中看为恶的事，与亚哈家一样，因为他是亚哈家的女婿。"},
{volumn:        12,chapter:         8,versenumber:        28,verse:"他与亚哈的儿子约兰同往基列的拉末去，与亚兰王哈薛争战。亚兰人打伤了约兰，"},
{volumn:        12,chapter:         8,versenumber:        29,verse:"约兰王回到耶斯列，医治在拉末与亚兰王哈薛打仗的时候所受的伤。犹大王约兰的儿子亚哈谢因为亚哈的儿子约兰病了，就下到耶斯列看望他。"},
{volumn:        12,chapter:         9,versenumber:         1,verse:"先知以利沙叫了一个先知门徒来，吩咐他说：“你束上腰，手拿这瓶膏油往基列的拉末去。"},
{volumn:        12,chapter:         9,versenumber:         2,verse:"到了那里，要寻找宁示的孙子、约沙法的儿子耶户，使他从同僚中起来，带他进严密的屋子，"},
{volumn:        12,chapter:         9,versenumber:         3,verse:"将瓶里的膏油倒在他头上，说：‘耶和华如此说：我膏你作以色列王。’说完了，就开门逃跑，不要迟延。”"},
{volumn:        12,chapter:         9,versenumber:         4,verse:"于是那少年先知往基列的拉末去了。"},
{volumn:        12,chapter:         9,versenumber:         5,verse:"到了那里，看见众军长都坐着，就说：“将军哪，我有话对你说。”耶户说：“我们众人里，你要对哪一个说呢？”回答说：“将军哪，我要对你说。”"},
{volumn:        12,chapter:         9,versenumber:         6,verse:"耶户就起来，进了屋子，少年人将膏油倒在他头上，对他说：“耶和华以色列的　神如此说：‘我膏你作耶和华民以色列的王。"},
{volumn:        12,chapter:         9,versenumber:         7,verse:"你要击杀你主人亚哈的全家，我好在耶洗别身上伸我仆人众先知和耶和华一切仆人流血的冤。"},
{volumn:        12,chapter:         9,versenumber:         8,verse:"亚哈全家必都灭亡，凡属亚哈的男丁，无论是困住的、自由的，我必从以色列中剪除，"},
{volumn:        12,chapter:         9,versenumber:         9,verse:"使亚哈的家像尼八儿子耶罗波安的家，又像亚希雅儿子巴沙的家。"},
{volumn:        12,chapter:         9,versenumber:        10,verse:"耶洗别必在耶斯列田里被狗所吃，无人葬埋。’”说完了，少年人就开门逃跑了。"},
{volumn:        12,chapter:         9,versenumber:        11,verse:"耶户出来，回到他主人的臣仆那里，有一人问他说：“平安吗？这狂妄的人来见你有什么事呢？”回答说：“你们认得那人，也知道他说什么。”"},
{volumn:        12,chapter:         9,versenumber:        12,verse:"他们说：“这是假话，你据实地告诉我们。”回答说：“他如此如此对我说。他说：‘耶和华如此说：我膏你作以色列王。’”"},
{volumn:        12,chapter:         9,versenumber:        13,verse:"他们就急忙各将自己的衣服铺在上层台阶，使耶户坐在其上；他们吹角，说：“耶户作王了！”"},
{volumn:        12,chapter:         9,versenumber:        14,verse:"这样，宁示的孙子、约沙法的儿子耶户背叛约兰。先是约兰和以色列众人因为亚兰王哈薛的缘故，把守基列的拉末；"},
{volumn:        12,chapter:         9,versenumber:        15,verse:"但约兰王回到耶斯列，医治与亚兰王哈薛打仗所受的伤。耶户说：“若合你们的意思，就不容人逃出城往耶斯列报信去。”"},
{volumn:        12,chapter:         9,versenumber:        16,verse:"于是耶户坐车往耶斯列去，因为约兰病卧在那里。犹大王亚哈谢已经下去看望他。"},
{volumn:        12,chapter:         9,versenumber:        17,verse:"有一个守望的人站在耶斯列的楼上，看见耶户带着一群人来，就说：“我看见一群人。”约兰说：“打发一个骑马的去迎接他们，问说：平安不平安？”"},
{volumn:        12,chapter:         9,versenumber:        18,verse:"骑马的就去迎接耶户，说：“王问说，平安不平安？”耶户说：“平安不平安与你何干？你转在我后头吧！”守望的人又说：“使者到了他们那里，却不回来。”"},
{volumn:        12,chapter:         9,versenumber:        19,verse:"王又打发一个骑马的去。这人到了他们那里，说：“王问说，平安不平安？”耶户说：“平安不平安与你何干？你转在我后头吧！”"},
{volumn:        12,chapter:         9,versenumber:        20,verse:"守望的人又说：“他到了他们那里，也不回来；车赶得甚猛，像宁示的孙子耶户的赶法。”"},
{volumn:        12,chapter:         9,versenumber:        21,verse:"约兰吩咐说：“套车！”人就给他套车。以色列王约兰和犹大王亚哈谢各坐自己的车出去迎接耶户，在耶斯列人拿伯的田那里遇见他。"},
{volumn:        12,chapter:         9,versenumber:        22,verse:"约兰见耶户就说：“耶户啊，平安吗？”耶户说：“你母亲耶洗别的淫行邪术这样多，焉能平安呢？”"},
{volumn:        12,chapter:         9,versenumber:        23,verse:"约兰就转车逃跑，对亚哈谢说：“亚哈谢啊，反了！”"},
{volumn:        12,chapter:         9,versenumber:        24,verse:"耶户开满了弓，射中约兰的脊背，箭从心窝穿出，约兰就仆倒在车上。"},
{volumn:        12,chapter:         9,versenumber:        25,verse:"耶户对他的军长毕甲说：“你把他抛在耶斯列人拿伯的田间。你当追想，你我一同坐车跟随他父亚哈的时候，耶和华对亚哈所说的预言，"},
{volumn:        12,chapter:         9,versenumber:        26,verse:"说：‘我昨日看见拿伯的血和他众子的血，我必在这块田上报应你。’这是耶和华说的，现在你要照着耶和华的话，把他抛在这田间。”"},
{volumn:        12,chapter:         9,versenumber:        27,verse:"犹大王亚哈谢见这光景，就从园亭之路逃跑。耶户追赶他，说：“把这人也杀在车上。”到了靠近以伯莲姑珥的坡上击伤了他。他逃到米吉多，就死在那里。"},
{volumn:        12,chapter:         9,versenumber:        28,verse:"他的臣仆用车将他的尸首送到耶路撒冷，葬在大卫城他自己的坟墓里，与他列祖同葬。"},
{volumn:        12,chapter:         9,versenumber:        29,verse:"亚哈谢登基作犹大王的时候，是在亚哈的儿子约兰第十一年。"},
{volumn:        12,chapter:         9,versenumber:        30,verse:"耶户到了耶斯列；耶洗别听见就擦粉、梳头，从窗户里往外观看。"},
{volumn:        12,chapter:         9,versenumber:        31,verse:"耶户进门的时候，耶洗别说：“杀主人的心利啊，平安吗？”"},
{volumn:        12,chapter:         9,versenumber:        32,verse:"耶户抬头向窗户观看，说：“谁顺从我？”有两三个太监从窗户往外看他。"},
{volumn:        12,chapter:         9,versenumber:        33,verse:"耶户说：“把她扔下来！”他们就把她扔下来。她的血溅在墙上和马上；于是把她践踏了。"},
{volumn:        12,chapter:         9,versenumber:        34,verse:"耶户进去，吃了喝了，吩咐说：“你们把这被咒诅的妇人葬埋了，因为她是王的女儿。”"},
{volumn:        12,chapter:         9,versenumber:        35,verse:"他们就去葬埋她，只寻得她的头骨和脚，并手掌。"},
{volumn:        12,chapter:         9,versenumber:        36,verse:"他们回去告诉耶户，耶户说：“这正应验耶和华藉他仆人提斯比人以利亚所说的话，说：‘在耶斯列田间，狗必吃耶洗别的肉；"},
{volumn:        12,chapter:         9,versenumber:        37,verse:"耶洗别的尸首必在耶斯列田间如同粪土，甚至人不能说这是耶洗别。’”"},
{volumn:        12,chapter:        10,versenumber:         1,verse:"亚哈有七十个儿子在撒玛利亚。耶户写信送到撒玛利亚，通知耶斯列的首领，就是长老和教养亚哈众子的人，说："},
{volumn:        12,chapter:        10,versenumber:         2,verse:"“你们那里既有你们主人的众子和车马、器械、坚固城，"},
{volumn:        12,chapter:        10,versenumber:         3,verse:"接了这信，就可以在你们主人的众子中选择一个贤能合宜的，使他坐他父亲的位，你们也可以为你们主人的家争战。”"},
{volumn:        12,chapter:        10,versenumber:         4,verse:"他们却甚惧怕，彼此说：“二王在他面前尚且站立不住，我们怎能站得住呢？”"},
{volumn:        12,chapter:        10,versenumber:         5,verse:"家宰、邑宰，和长老，并教养众子的人，打发人去见耶户，说：“我们是你的仆人，凡你所吩咐我们的都必遵行，我们不立谁作王，你看怎样好就怎样行。”"},
{volumn:        12,chapter:        10,versenumber:         6,verse:"耶户又给他们写信说：“你们若归顺我，听从我的话，明日这时候，要将你们主人众子的首级带到耶斯列来见我。”那时王的儿子七十人都住在教养他们那城中的尊贵人家里。"},
{volumn:        12,chapter:        10,versenumber:         7,verse:"信一到，他们就把王的七十个儿子杀了，将首级装在筐里，送到在耶斯列的耶户那里。"},
{volumn:        12,chapter:        10,versenumber:         8,verse:"有使者来告诉耶户说：“他们将王众子的首级送来了。”耶户说：“将首级在城门口堆作两堆，搁到明日。”"},
{volumn:        12,chapter:        10,versenumber:         9,verse:"次日早晨，耶户出来，站着对众民说：“你们都是公义的，我背叛我主人，将他杀了；这些人却是谁杀的呢？"},
{volumn:        12,chapter:        10,versenumber:        10,verse:"由此可知，耶和华指着亚哈家所说的话一句没有落空，因为耶和华藉他仆人以利亚所说的话都成就了。”"},
{volumn:        12,chapter:        10,versenumber:        11,verse:"凡亚哈家在耶斯列所剩下的人和他的大臣、密友、祭司，耶户尽都杀了，没有留下一个。"},
{volumn:        12,chapter:        10,versenumber:        12,verse:"耶户起身往撒玛利亚去。在路上、牧人剪羊毛之处，"},
{volumn:        12,chapter:        10,versenumber:        13,verse:"遇见犹大王亚哈谢的弟兄，问他们说：“你们是谁？”回答说：“我们是亚哈谢的弟兄，现在下去要问王和太后的众子安。”"},
{volumn:        12,chapter:        10,versenumber:        14,verse:"耶户吩咐说：“活捉他们！”跟从的人就活捉了他们，将他们杀在剪羊毛之处的坑边，共四十二人，没有留下一个。"},
{volumn:        12,chapter:        10,versenumber:        15,verse:"耶户从那里前行，恰遇利甲的儿子约拿达来迎接他，耶户问他安，对他说：“你诚心待我像我诚心待你吗？”约拿达回答说：“是。”耶户说：“若是这样，你向我伸手”，他就伸手；耶户拉他上车。"},
{volumn:        12,chapter:        10,versenumber:        16,verse:"耶户说：“你和我同去，看我为耶和华怎样热心”；于是请他坐在车上，"},
{volumn:        12,chapter:        10,versenumber:        17,verse:"到了撒玛利亚，就把撒玛利亚的亚哈家剩下的人都杀了，直到灭尽，正如耶和华对以利亚所说的。"},
{volumn:        12,chapter:        10,versenumber:        18,verse:"耶户招聚众民，对他们说：“亚哈侍奉巴力还冷淡，耶户却更热心。"},
{volumn:        12,chapter:        10,versenumber:        19,verse:"现在我要给巴力献大祭。应当叫巴力的众先知和一切拜巴力的人，并巴力的众祭司，都到我这里来，不可缺少一个；凡不来的必不得活。”耶户这样行，是用诡计要杀尽拜巴力的人。"},
{volumn:        12,chapter:        10,versenumber:        20,verse:"耶户说：“要为巴力宣告严肃会！”于是宣告了。"},
{volumn:        12,chapter:        10,versenumber:        21,verse:"耶户差人走遍以色列地；凡拜巴力的人都来齐了，没有一个不来的。他们进了巴力庙，巴力庙中从前边直到后边都满了人。"},
{volumn:        12,chapter:        10,versenumber:        22,verse:"耶户吩咐掌管礼服的人说：“拿出礼服来，给一切拜巴力的人穿。”他就拿出礼服来给了他们。"},
{volumn:        12,chapter:        10,versenumber:        23,verse:"耶户和利甲的儿子约拿达进了巴力庙，对拜巴力的人说：“你们察看察看，在你们这里不可有耶和华的仆人，只可容留拜巴力的人。”"},
{volumn:        12,chapter:        10,versenumber:        24,verse:"耶户和约拿达进去，献平安祭和燔祭。耶户先安派八十人在庙外，吩咐说：“我将这些人交在你们手中，若有一人脱逃，谁放的必叫他偿命！”"},
{volumn:        12,chapter:        10,versenumber:        25,verse:"耶户献完了燔祭，就出来吩咐护卫兵和众军长说：“你们进去杀他们，不容一人出来！”护卫兵和军长就用刀杀他们，将尸首抛出去，便到巴力庙的城去了，"},
{volumn:        12,chapter:        10,versenumber:        26,verse:"将巴力庙中的柱像都拿出来烧了；"},
{volumn:        12,chapter:        10,versenumber:        27,verse:"毁坏了巴力柱像，拆毁了巴力庙作为厕所，直到今日。"},
{volumn:        12,chapter:        10,versenumber:        28,verse:"这样，耶户在以色列中灭了巴力。"},
{volumn:        12,chapter:        10,versenumber:        29,verse:"只是耶户不离开尼八的儿子耶罗波安使以色列人陷在罪里的那罪，就是拜伯特利和但的金牛犊。"},
{volumn:        12,chapter:        10,versenumber:        30,verse:"耶和华对耶户说：“因你办好我眼中看为正的事，照我的心意待亚哈家，你的子孙必接续你坐以色列的国位，直到四代。”"},
{volumn:        12,chapter:        10,versenumber:        31,verse:"只是耶户不尽心遵守耶和华以色列　神的律法，不离开耶罗波安使以色列人陷在罪里的那罪。"},
{volumn:        12,chapter:        10,versenumber:        32,verse:"在那些日子，耶和华才割裂以色列国，使哈薛攻击以色列的境界，"},
{volumn:        12,chapter:        10,versenumber:        33,verse:"乃是约旦河东、基列全地，从靠近亚嫩谷边的亚罗珥起，就是基列和巴珊的迦得人、流便人、玛拿西人之地。"},
{volumn:        12,chapter:        10,versenumber:        34,verse:"耶户其余的事，凡他所行的和他的勇力都写在以色列诸王记上。"},
{volumn:        12,chapter:        10,versenumber:        35,verse:"耶户与他列祖同睡，葬在撒玛利亚；他儿子约哈斯接续他作王。"},
{volumn:        12,chapter:        10,versenumber:        36,verse:"耶户在撒玛利亚作以色列王二十八年。"},
{volumn:        12,chapter:        11,versenumber:         1,verse:"亚哈谢的母亲亚他利雅见她儿子死了，就起来剿灭王室。"},
{volumn:        12,chapter:        11,versenumber:         2,verse:"但约兰王的女儿，亚哈谢的妹子约示巴，将亚哈谢的儿子约阿施从那被杀的王子中偷出来，把他和他的乳母都藏在卧房里，躲避亚他利雅，免得被杀。"},
{volumn:        12,chapter:        11,versenumber:         3,verse:"约阿施和他的乳母藏在耶和华的殿里六年；亚他利雅篡了国位。"},
{volumn:        12,chapter:        11,versenumber:         4,verse:"第七年，耶何耶大打发人叫迦利人（或译：亲兵）和护卫兵的众百夫长来，领他们进了耶和华的殿，与他们立约，使他们在耶和华殿里起誓，又将王的儿子指给他们看，"},
{volumn:        12,chapter:        11,versenumber:         5,verse:"吩咐他们说：“你们当这样行：凡安息日进班的三分之一要看守王宫，"},
{volumn:        12,chapter:        11,versenumber:         6,verse:"三分之一要在苏珥门，三分之一要在护卫兵院的后门。这样把守王宫，拦阻闲人。"},
{volumn:        12,chapter:        11,versenumber:         7,verse:"你们安息日所有出班的三分之二要在耶和华的殿里护卫王；"},
{volumn:        12,chapter:        11,versenumber:         8,verse:"各人手拿兵器，四围护卫王。凡擅入你们班次的必当治死，王出入的时候，你们当跟随他。”"},
{volumn:        12,chapter:        11,versenumber:         9,verse:"众百夫长就照着祭司耶何耶大一切所吩咐的去行，各带所管安息日进班出班的人来见祭司耶何耶大。"},
{volumn:        12,chapter:        11,versenumber:        10,verse:"祭司便将耶和华殿里所藏大卫王的枪和盾牌交给百夫长。"},
{volumn:        12,chapter:        11,versenumber:        11,verse:"护卫兵手中各拿兵器，在坛和殿那里，从殿右直到殿左，站在王子的四围。"},
{volumn:        12,chapter:        11,versenumber:        12,verse:"祭司领王子出来，给他戴上冠冕，将律法书交给他，膏他作王；众人就拍掌说：“愿王万岁！”"},
{volumn:        12,chapter:        11,versenumber:        13,verse:"亚他利雅听见护卫兵和民的声音，就到民那里，进耶和华的殿，"},
{volumn:        12,chapter:        11,versenumber:        14,verse:"看见王照例站在柱旁，百夫长和吹号的人侍立在王左右，国中的众民欢乐吹号；亚他利雅就撕裂衣服，喊叫说：“反了！反了！”"},
{volumn:        12,chapter:        11,versenumber:        15,verse:"祭司耶何耶大吩咐管辖军兵的百夫长说：“将她赶出班外，凡跟随她的必用刀杀死！”因为祭司说“不可在耶和华殿里杀她”，"},
{volumn:        12,chapter:        11,versenumber:        16,verse:"众兵就闪开让她去；她从马路上王宫去，便在那里被杀。"},
{volumn:        12,chapter:        11,versenumber:        17,verse:"耶何耶大使王和民与耶和华立约，作耶和华的民；又使王与民立约。"},
{volumn:        12,chapter:        11,versenumber:        18,verse:"于是国民都到巴力庙，拆毁了庙，打碎坛和像，又在坛前将巴力的祭司玛坦杀了。祭司耶何耶大派官看守耶和华的殿，"},
{volumn:        12,chapter:        11,versenumber:        19,verse:"又率领百夫长和迦利人（或译：亲兵）与护卫兵，以及国中的众民，请王从耶和华殿下来，由护卫兵的门进入王宫，他就坐了王位。"},
{volumn:        12,chapter:        11,versenumber:        20,verse:"国民都欢乐，合城都安静。众人已将亚他利雅在王宫那里用刀杀了。"},
{volumn:        12,chapter:        11,versenumber:        21,verse:"约阿施登基的时候年方七岁。"},
{volumn:        12,chapter:        12,versenumber:         1,verse:"耶户第七年，约阿施登基，在耶路撒冷作王四十年。他母亲名叫西比亚，是别是巴人。"},
{volumn:        12,chapter:        12,versenumber:         2,verse:"约阿施在祭司耶何耶大教训他的时候，就行耶和华眼中看为正的事；"},
{volumn:        12,chapter:        12,versenumber:         3,verse:"只是邱坛还没有废去，百姓仍在那里献祭烧香。"},
{volumn:        12,chapter:        12,versenumber:         4,verse:"约阿施对众祭司说：“凡奉到耶和华殿分别为圣之物所值通用的银子，或各人当纳的身价，或乐意奉到耶和华殿的银子，"},
{volumn:        12,chapter:        12,versenumber:         5,verse:"你们当从所认识的人收了来，修理殿的一切破坏之处。”"},
{volumn:        12,chapter:        12,versenumber:         6,verse:"无奈到了约阿施王二十三年，祭司仍未修理殿的破坏之处。"},
{volumn:        12,chapter:        12,versenumber:         7,verse:"所以约阿施王召了大祭司耶何耶大和众祭司来，对他们说：“你们怎么不修理殿的破坏之处呢？从今以后，你们不要从所认识的人再收银子，要将所收的交出来，修理殿的破坏之处。”"},
{volumn:        12,chapter:        12,versenumber:         8,verse:"众祭司答应不再收百姓的银子，也不修理殿的破坏之处。"},
{volumn:        12,chapter:        12,versenumber:         9,verse:"祭司耶何耶大取了一个柜子，在柜盖上钻了一个窟窿，放于坛旁，在进耶和华殿的右边；守门的祭司将奉到耶和华殿的一切银子投在柜里。"},
{volumn:        12,chapter:        12,versenumber:        10,verse:"他们见柜里的银子多了，便叫王的书记和大祭司上来，将耶和华殿里的银子数算包起来。"},
{volumn:        12,chapter:        12,versenumber:        11,verse:"把所平的银子交给督工的，就是耶和华殿里办事的人；他们把银子转交修理耶和华殿的木匠和工人，"},
{volumn:        12,chapter:        12,versenumber:        12,verse:"并瓦匠、石匠，又买木料和凿成的石头，修理耶和华殿的破坏之处，以及修理殿的各样使用。"},
{volumn:        12,chapter:        12,versenumber:        13,verse:"但那奉到耶和华殿的银子，没有用以做耶和华殿里的银杯、蜡剪、碗、号，和别样的金银器皿，"},
{volumn:        12,chapter:        12,versenumber:        14,verse:"乃将那银子交给督工的人修理耶和华的殿；"},
{volumn:        12,chapter:        12,versenumber:        15,verse:"且将银子交给办事的人转交做工的人，不与他们算账，因为他们办事诚实。"},
{volumn:        12,chapter:        12,versenumber:        16,verse:"惟有赎愆祭、赎罪祭的银子没有奉到耶和华的殿，都归祭司。"},
{volumn:        12,chapter:        12,versenumber:        17,verse:"那时，亚兰王哈薛上来攻打迦特，攻取了，就定意上来攻打耶路撒冷。"},
{volumn:        12,chapter:        12,versenumber:        18,verse:"犹大王约阿施将他列祖犹大王约沙法、约兰、亚哈谢所分别为圣的物和自己所分别为圣的物，并耶和华殿与王宫府库里所有的金子都送给亚兰王哈薛；哈薛就不上耶路撒冷来了。"},
{volumn:        12,chapter:        12,versenumber:        19,verse:"约阿施其余的事，凡他所行的都写在犹大列王记上。"},
{volumn:        12,chapter:        12,versenumber:        20,verse:"约阿施的臣仆起来背叛，在下悉拉的米罗宫那里将他杀了。"},
{volumn:        12,chapter:        12,versenumber:        21,verse:"杀他的那臣仆就是示米押的儿子约撒甲和朔默的儿子约萨拔。众人将他葬在大卫城他列祖的坟地里。他儿子亚玛谢接续他作王。"},
{volumn:        12,chapter:        13,versenumber:         1,verse:"犹大王亚哈谢的儿子约阿施二十三年，耶户的儿子约哈斯在撒玛利亚登基作以色列王十七年。"},
{volumn:        12,chapter:        13,versenumber:         2,verse:"约哈斯行耶和华眼中看为恶的事，效法尼八的儿子耶罗波安使以色列人陷在罪里的那罪，总不离开。"},
{volumn:        12,chapter:        13,versenumber:         3,verse:"于是，耶和华的怒气向以色列人发作，将他们屡次交在亚兰王哈薛和他儿子便哈达的手里。"},
{volumn:        12,chapter:        13,versenumber:         4,verse:"约哈斯恳求耶和华，耶和华就应允他，因为见以色列人所受亚兰王的欺压。"},
{volumn:        12,chapter:        13,versenumber:         5,verse:"耶和华赐给以色列人一位拯救者，使他们脱离亚兰人的手；于是以色列人仍旧安居在家里。"},
{volumn:        12,chapter:        13,versenumber:         6,verse:"然而，他们不离开耶罗波安家使以色列人陷在罪里的那罪，仍然去行，并且在撒玛利亚留下亚舍拉。"},
{volumn:        12,chapter:        13,versenumber:         7,verse:"亚兰王灭绝约哈斯的民，践踏他们如禾场上的尘沙，只给约哈斯留下五十马兵，十辆战车，一万步兵。"},
{volumn:        12,chapter:        13,versenumber:         8,verse:"约哈斯其余的事，凡他所行的和他的勇力都写在以色列诸王记上。"},
{volumn:        12,chapter:        13,versenumber:         9,verse:"约哈斯与他列祖同睡，葬在撒玛利亚。他儿子约阿施接续他作王。"},
{volumn:        12,chapter:        13,versenumber:        10,verse:"犹大王约阿施三十七年，约哈斯的儿子约阿施在撒玛利亚登基作以色列王十六年。"},
{volumn:        12,chapter:        13,versenumber:        11,verse:"他行耶和华眼中看为恶的事，不离开尼八的儿子耶罗波安使以色列人陷在罪里的一切罪，仍然去行。"},
{volumn:        12,chapter:        13,versenumber:        12,verse:"约阿施其余的事，凡他所行的和他与犹大王亚玛谢争战的勇力，都写在以色列诸王记上。"},
{volumn:        12,chapter:        13,versenumber:        13,verse:"约阿施与他列祖同睡，耶罗波安坐了他的位。约阿施与以色列诸王一同葬在撒玛利亚。"},
{volumn:        12,chapter:        13,versenumber:        14,verse:"以利沙得了必死的病，以色列王约阿施下来看他，伏在他脸上哭泣，说：“我父啊！我父啊！以色列的战车马兵啊！”"},
{volumn:        12,chapter:        13,versenumber:        15,verse:"以利沙对他说：“你取弓箭来。”王就取了弓箭来；"},
{volumn:        12,chapter:        13,versenumber:        16,verse:"又对以色列王说：“你用手拿弓。”王就用手拿弓。以利沙按手在王的手上，"},
{volumn:        12,chapter:        13,versenumber:        17,verse:"说：“你开朝东的窗户。”他就开了。以利沙说：“射箭吧！”他就射箭。以利沙说：“这是耶和华的得胜箭，就是战胜亚兰人的箭；因为你必在亚弗攻打亚兰人，直到灭尽他们。”"},
{volumn:        12,chapter:        13,versenumber:        18,verse:"以利沙又说：“取几枝箭来。”他就取了来。以利沙说：“打地吧！”他打了三次，便止住了。"},
{volumn:        12,chapter:        13,versenumber:        19,verse:"神人向他发怒，说：“应当击打五六次，就能攻打亚兰人直到灭尽；现在只能打败亚兰人三次。”"},
{volumn:        12,chapter:        13,versenumber:        20,verse:"以利沙死了，人将他葬埋。到了新年，有一群摩押人犯境，"},
{volumn:        12,chapter:        13,versenumber:        21,verse:"有人正葬死人，忽然看见一群人，就把死人抛在以利沙的坟墓里，一碰着以利沙的骸骨，死人就复活，站起来了。"},
{volumn:        12,chapter:        13,versenumber:        22,verse:"约哈斯年间，亚兰王哈薛屡次欺压以色列人。"},
{volumn:        12,chapter:        13,versenumber:        23,verse:"耶和华却因与亚伯拉罕、以撒、雅各所立的约，仍施恩给以色列人，怜恤他们，眷顾他们，不肯灭尽他们，尚未赶逐他们离开自己面前。"},
{volumn:        12,chapter:        13,versenumber:        24,verse:"亚兰王哈薛死了，他儿子便·哈达接续他作王。"},
{volumn:        12,chapter:        13,versenumber:        25,verse:"从前哈薛和约阿施的父亲约哈斯争战，攻取了些城邑，现在约哈斯的儿子约阿施三次打败哈薛的儿子便哈达，就收回了以色列的城邑。"},
{volumn:        12,chapter:        14,versenumber:         1,verse:"以色列王约哈斯的儿子约阿施第二年，犹大王约阿施的儿子亚玛谢登基。"},
{volumn:        12,chapter:        14,versenumber:         2,verse:"他登基的时候年二十五岁，在耶路撒冷作王二十九年。他母亲名叫约耶但，是耶路撒冷人。"},
{volumn:        12,chapter:        14,versenumber:         3,verse:"亚玛谢行耶和华眼中看为正的事，但不如他祖大卫，乃效法他父约阿施一切所行的；"},
{volumn:        12,chapter:        14,versenumber:         4,verse:"只是邱坛还没有废去，百姓仍在那里献祭烧香。"},
{volumn:        12,chapter:        14,versenumber:         5,verse:"国一坚定，就把杀他父王的臣仆杀了，"},
{volumn:        12,chapter:        14,versenumber:         6,verse:"却没有治死杀王之人的儿子，是照摩西律法书上耶和华所吩咐的说：“不可因子杀父，也不可因父杀子，各人要为本身的罪而死。”"},
{volumn:        12,chapter:        14,versenumber:         7,verse:"亚玛谢在盐谷杀了以东人一万，又攻取了西拉，改名叫约帖，直到今日。"},
{volumn:        12,chapter:        14,versenumber:         8,verse:"那时，亚玛谢差遣使者去见耶户的孙子约哈斯的儿子以色列王约阿施，说：“你来，我们二人相见于战场。”"},
{volumn:        12,chapter:        14,versenumber:         9,verse:"以色列王约阿施差遣使者去见犹大王亚玛谢，说：“黎巴嫩的蒺藜差遣使者去见黎巴嫩的香柏树，说：将你的女儿给我儿子为妻。后来黎巴嫩有一只野兽经过，把蒺藜践踏了。"},
{volumn:        12,chapter:        14,versenumber:        10,verse:"你打败了以东人就心高气傲，你以此为荣耀，在家里安居就罢了，为何要惹祸，使自己和犹大国一同败亡呢？”"},
{volumn:        12,chapter:        14,versenumber:        11,verse:"亚玛谢却不肯听这话。于是以色列王约阿施上来，在犹大的伯·示麦与犹大王亚玛谢相见于战场。"},
{volumn:        12,chapter:        14,versenumber:        12,verse:"犹大人败在以色列人面前，各自逃回家里去了。"},
{volumn:        12,chapter:        14,versenumber:        13,verse:"以色列王约阿施在伯·示麦擒住亚哈谢的孙子、约阿施的儿子犹大王亚玛谢，就来到耶路撒冷，拆毁耶路撒冷的城墙，从以法莲门直到角门共四百肘，"},
{volumn:        12,chapter:        14,versenumber:        14,verse:"又将耶和华殿里与王宫府库里所有的金银和器皿都拿了去，并带人去为质，就回撒玛利亚去了。"},
{volumn:        12,chapter:        14,versenumber:        15,verse:"约阿施其余所行的事和他的勇力，并与犹大王亚玛谢争战的事，都写在以色列诸王记上。"},
{volumn:        12,chapter:        14,versenumber:        16,verse:"约阿施与他列祖同睡，葬在撒玛利亚，以色列诸王的坟地里。他儿子耶罗波安接续他作王。"},
{volumn:        12,chapter:        14,versenumber:        17,verse:"以色列王约哈斯的儿子约阿施死后，犹大王约阿施的儿子亚玛谢又活了十五年。"},
{volumn:        12,chapter:        14,versenumber:        18,verse:"亚玛谢其余的事都写在犹大列王记上。"},
{volumn:        12,chapter:        14,versenumber:        19,verse:"耶路撒冷有人背叛亚玛谢，他就逃到拉吉；叛党却打发人到拉吉将他杀了。"},
{volumn:        12,chapter:        14,versenumber:        20,verse:"人就用马将他的尸首驮到耶路撒冷，葬在大卫城他列祖的坟地里。"},
{volumn:        12,chapter:        14,versenumber:        21,verse:"犹大众民立亚玛谢的儿子亚撒利雅（又名乌西雅）接续他父作王，那时他年十六岁。"},
{volumn:        12,chapter:        14,versenumber:        22,verse:"亚玛谢与他列祖同睡之后，亚撒利雅收回以拉他仍归犹大，又重新修理。"},
{volumn:        12,chapter:        14,versenumber:        23,verse:"犹大王约阿施的儿子亚玛谢十五年，以色列王约阿施的儿子耶罗波安在撒玛利亚登基，作王四十一年。"},
{volumn:        12,chapter:        14,versenumber:        24,verse:"他行耶和华眼中看为恶的事，不离开尼八的儿子耶罗波安使以色列人陷在罪里的一切罪。"},
{volumn:        12,chapter:        14,versenumber:        25,verse:"他收回以色列边界之地，从哈马口直到亚拉巴海，正如耶和华以色列的　神藉他仆人迦特希弗人亚米太的儿子先知约拿所说的。"},
{volumn:        12,chapter:        14,versenumber:        26,verse:"因为耶和华看见以色列人甚是艰苦，无论困住的、自由的都没有了，也无人帮助以色列人。"},
{volumn:        12,chapter:        14,versenumber:        27,verse:"耶和华并没有说要将以色列的名从天下涂抹，乃藉约阿施的儿子耶罗波安拯救他们。"},
{volumn:        12,chapter:        14,versenumber:        28,verse:"耶罗波安其余的事，凡他所行的和他的勇力，他怎样争战，怎样收回大马士革和先前属犹大的哈马归以色列，都写在以色列诸王记上。"},
{volumn:        12,chapter:        14,versenumber:        29,verse:"耶罗波安与他列祖以色列诸王同睡。他儿子撒迦利雅接续他作王。"},
{volumn:        12,chapter:        15,versenumber:         1,verse:"以色列王耶罗波安二十七年，犹大王亚玛谢的儿子亚撒利雅登基，"},
{volumn:        12,chapter:        15,versenumber:         2,verse:"他登基的时候年十六岁，在耶路撒冷作王五十二年。他母亲名叫耶可利雅，是耶路撒冷人。"},
{volumn:        12,chapter:        15,versenumber:         3,verse:"亚撒利雅行耶和华眼中看为正的事，效法他父亲亚玛谢一切所行的；"},
{volumn:        12,chapter:        15,versenumber:         4,verse:"只是邱坛还没有废去，百姓仍在那里献祭烧香。"},
{volumn:        12,chapter:        15,versenumber:         5,verse:"耶和华降灾与王，使他长大麻风，直到死日，他就住在别的宫里。他的儿子约坦管理家事，治理国民。"},
{volumn:        12,chapter:        15,versenumber:         6,verse:"亚撒利雅其余的事，凡他所行的都写在犹大列王记上。"},
{volumn:        12,chapter:        15,versenumber:         7,verse:"亚撒利雅与他列祖同睡，葬在大卫城他列祖的坟地里。他儿子约坦接续他作王。"},
{volumn:        12,chapter:        15,versenumber:         8,verse:"犹大王亚撒利雅三十八年，耶罗波安的儿子撒迦利雅在撒玛利亚作以色列王六个月。"},
{volumn:        12,chapter:        15,versenumber:         9,verse:"他行耶和华眼中看为恶的事，效法他列祖所行的，不离开尼八的儿子耶罗波安使以色列人陷在罪里的那罪。"},
{volumn:        12,chapter:        15,versenumber:        10,verse:"雅比的儿子沙龙背叛他，在百姓面前击杀他，篡了他的位。"},
{volumn:        12,chapter:        15,versenumber:        11,verse:"撒迦利雅其余的事都写在以色列诸王记上。"},
{volumn:        12,chapter:        15,versenumber:        12,verse:"这是从前耶和华应许耶户说：“你的子孙必坐以色列的国位直到四代。”这话果然应验了。"},
{volumn:        12,chapter:        15,versenumber:        13,verse:"犹大王乌西雅（就是亚撒利雅）三十九年，雅比的儿子沙龙登基在撒玛利亚作王一个月。"},
{volumn:        12,chapter:        15,versenumber:        14,verse:"迦底的儿子米拿现从得撒上撒玛利亚，杀了雅比的儿子沙龙，篡了他的位。"},
{volumn:        12,chapter:        15,versenumber:        15,verse:"沙龙其余的事和他背叛的情形都写在以色列诸王记上。"},
{volumn:        12,chapter:        15,versenumber:        16,verse:"那时米拿现从得撒起攻打提斐萨和其四境，击杀城中一切的人，剖开其中所有的孕妇，都因他们没有给他开城。"},
{volumn:        12,chapter:        15,versenumber:        17,verse:"犹大王亚撒利雅三十九年，迦底的儿子米拿现登基，在撒玛利亚作以色列王十年。"},
{volumn:        12,chapter:        15,versenumber:        18,verse:"他行耶和华眼中看为恶的事，终身不离开尼八的儿子耶罗波安使以色列人陷在罪里的那罪。"},
{volumn:        12,chapter:        15,versenumber:        19,verse:"亚述王普勒来攻击以色列国，米拿现给他一千他连得银子，请普勒帮助他坚定国位。"},
{volumn:        12,chapter:        15,versenumber:        20,verse:"米拿现向以色列一切大富户索要银子，使他们各出五十舍客勒，就给了亚述王。于是亚述王回去，不在国中停留。"},
{volumn:        12,chapter:        15,versenumber:        21,verse:"米拿现其余的事，凡他所行的都写在以色列诸王记上。"},
{volumn:        12,chapter:        15,versenumber:        22,verse:"米拿现与他列祖同睡。他儿子比加辖接续他作王。"},
{volumn:        12,chapter:        15,versenumber:        23,verse:"犹大王亚撒利雅五十年，米拿现的儿子比加辖在撒玛利亚登基作以色列王二年。"},
{volumn:        12,chapter:        15,versenumber:        24,verse:"他行耶和华眼中看为恶的事，不离开尼八的儿子耶罗波安使以色列人陷在罪里的那罪。"},
{volumn:        12,chapter:        15,versenumber:        25,verse:"比加辖的将军、利玛利的儿子比加背叛他，在撒玛利亚王宫里的卫所杀了他。亚珥歌伯和亚利耶并基列的五十人帮助比加；比加击杀他，篡了他的位。"},
{volumn:        12,chapter:        15,versenumber:        26,verse:"比加辖其余的事，凡他所行的都写在以色列诸王记上。"},
{volumn:        12,chapter:        15,versenumber:        27,verse:"犹大王亚撒利雅五十二年，利玛利的儿子比加在撒玛利亚登基作以色列王二十年。"},
{volumn:        12,chapter:        15,versenumber:        28,verse:"他行耶和华眼中看为恶的事，不离开尼八的儿子耶罗波安使以色列人陷在罪里的那罪。"},
{volumn:        12,chapter:        15,versenumber:        29,verse:"以色列王比加年间，亚述王提革拉·毗列色来夺了以云、亚伯·伯·玛迦、亚挪、基低斯、夏琐、基列、加利利，和拿弗他利全地，将这些地方的居民都掳到亚述去了。"},
{volumn:        12,chapter:        15,versenumber:        30,verse:"乌西雅的儿子约坦二十年，以拉的儿子何细亚背叛利玛利的儿子比加，击杀他，篡了他的位。"},
{volumn:        12,chapter:        15,versenumber:        31,verse:"比加其余的事，凡他所行的都写在以色列诸王记上。"},
{volumn:        12,chapter:        15,versenumber:        32,verse:"以色列王利玛利的儿子比加第二年，犹大王乌西雅的儿子约坦登基。"},
{volumn:        12,chapter:        15,versenumber:        33,verse:"他登基的时候年二十五岁，在耶路撒冷作王十六年。他母亲名叫耶路沙，是撒督的女儿。"},
{volumn:        12,chapter:        15,versenumber:        34,verse:"约坦行耶和华眼中看为正的事，效法他父亲乌西雅一切所行的；"},
{volumn:        12,chapter:        15,versenumber:        35,verse:"只是邱坛还没有废去，百姓仍在那里献祭烧香。约坦建立耶和华殿的上门。"},
{volumn:        12,chapter:        15,versenumber:        36,verse:"约坦其余的事，凡他所行的都写在犹大列王记上。"},
{volumn:        12,chapter:        15,versenumber:        37,verse:"在那些日子，耶和华才使亚兰王利汛和利玛利的儿子比加去攻击犹大。"},
{volumn:        12,chapter:        15,versenumber:        38,verse:"约坦与他列祖同睡，葬在他祖大卫城他列祖的坟地里。他儿子亚哈斯接续他作王。"},
{volumn:        12,chapter:        16,versenumber:         1,verse:"利玛利的儿子比加十七年，犹大王约坦的儿子亚哈斯登基。"},
{volumn:        12,chapter:        16,versenumber:         2,verse:"他登基的时候年二十岁，在耶路撒冷作王十六年；不像他祖大卫行耶和华他　神眼中看为正的事，"},
{volumn:        12,chapter:        16,versenumber:         3,verse:"却效法以色列诸王所行的，又照着耶和华从以色列人面前赶出的外邦人所行可憎的事，使他的儿子经火，"},
{volumn:        12,chapter:        16,versenumber:         4,verse:"并在邱坛上、山冈上、各青翠树下献祭烧香。"},
{volumn:        12,chapter:        16,versenumber:         5,verse:"亚兰王利汛和以色列王利玛利的儿子比加上来攻打耶路撒冷，围困亚哈斯，却不能胜他。"},
{volumn:        12,chapter:        16,versenumber:         6,verse:"当时亚兰王利汛收回以拉他归与亚兰，将犹大人从以拉他赶出去。亚兰人（有译以东人的）就来到以拉他，住在那里，直到今日。"},
{volumn:        12,chapter:        16,versenumber:         7,verse:"亚哈斯差遣使者去见亚述王提革拉·毗列色，说：“我是你的仆人、你的儿子。现在亚兰王和以色列王攻击我，求你来救我脱离他们的手。”"},
{volumn:        12,chapter:        16,versenumber:         8,verse:"亚哈斯将耶和华殿里和王宫府库里所有的金银都送给亚述王为礼物。"},
{volumn:        12,chapter:        16,versenumber:         9,verse:"亚述王应允了他，就上去攻打大马士革，将城攻取，杀了利汛，把居民掳到吉珥。"},
{volumn:        12,chapter:        16,versenumber:        10,verse:"亚哈斯王上大马士革去迎接亚述王提革拉·毗列色，在大马士革看见一座坛，就照坛的规模样式作法画了图样，送到祭司乌利亚那里。"},
{volumn:        12,chapter:        16,versenumber:        11,verse:"祭司乌利亚照着亚哈斯王从大马士革送来的图样，在亚哈斯王没有从大马士革回来之先，建筑一座坛。"},
{volumn:        12,chapter:        16,versenumber:        12,verse:"王从大马士革回来看见坛，就近前来，在坛上献祭；"},
{volumn:        12,chapter:        16,versenumber:        13,verse:"烧燔祭、素祭、浇奠祭，将平安祭牲的血洒在坛上，"},
{volumn:        12,chapter:        16,versenumber:        14,verse:"又将耶和华面前的铜坛从耶和华殿和新坛的中间搬到新坛的北边。"},
{volumn:        12,chapter:        16,versenumber:        15,verse:"亚哈斯王吩咐祭司乌利亚说：“早晨的燔祭、晚上的素祭，王的燔祭、素祭，国内众民的燔祭、素祭、奠祭都要烧在大坛上。燔祭牲和平安祭牲的血也要洒在这坛上，只是铜坛我要用以求问耶和华。”"},
{volumn:        12,chapter:        16,versenumber:        16,verse:"祭司乌利亚就照着亚哈斯王所吩咐的行了。"},
{volumn:        12,chapter:        16,versenumber:        17,verse:"亚哈斯王打掉盆座四面镶着的心子，把盆从座上挪下来，又将铜海从驮海的铜牛上搬下来，放在铺石地；"},
{volumn:        12,chapter:        16,versenumber:        18,verse:"又因亚述王的缘故，将耶和华殿为安息日所盖的廊子和王从外入殿的廊子挪移，围绕耶和华的殿。"},
{volumn:        12,chapter:        16,versenumber:        19,verse:"亚哈斯其余所行的事都写在犹大列王记上。"},
{volumn:        12,chapter:        16,versenumber:        20,verse:"亚哈斯与他列祖同睡，葬在大卫城他列祖的坟地里。他儿子希西家接续他作王。"},
{volumn:        12,chapter:        17,versenumber:         1,verse:"犹大王亚哈斯十二年，以拉的儿子何细亚在撒玛利亚登基作以色列王九年。"},
{volumn:        12,chapter:        17,versenumber:         2,verse:"他行耶和华眼中看为恶的事，只是不像在他以前的以色列诸王。"},
{volumn:        12,chapter:        17,versenumber:         3,verse:"亚述王撒缦以色上来攻击何细亚，何细亚就服侍他，给他进贡。"},
{volumn:        12,chapter:        17,versenumber:         4,verse:"何细亚背叛，差人去见埃及王梭，不照往年所行的与亚述王进贡。亚述王知道了，就把他锁禁，囚在监里。"},
{volumn:        12,chapter:        17,versenumber:         5,verse:"亚述王上来攻击以色列遍地，上到撒玛利亚，围困三年。"},
{volumn:        12,chapter:        17,versenumber:         6,verse:"何细亚第九年亚述王攻取了撒玛利亚，将以色列人掳到亚述，把他们安置在哈腊与歌散的哈博河边，并玛代人的城邑。"},
{volumn:        12,chapter:        17,versenumber:         7,verse:"这是因以色列人得罪那领他们出埃及地、脱离埃及王法老手的耶和华他们的　神，去敬畏别神，"},
{volumn:        12,chapter:        17,versenumber:         8,verse:"随从耶和华在他们面前所赶出外邦人的风俗和以色列诸王所立的条规。"},
{volumn:        12,chapter:        17,versenumber:         9,verse:"以色列人暗中行不正的事，违背耶和华他们的　神，在他们所有的城邑，从了望楼直到坚固城，建筑邱坛；"},
{volumn:        12,chapter:        17,versenumber:        10,verse:"在各高冈上、各青翠树下立柱像和木偶；"},
{volumn:        12,chapter:        17,versenumber:        11,verse:"在邱坛上烧香，效法耶和华在他们面前赶出的外邦人所行的，又行恶事惹动耶和华的怒气；"},
{volumn:        12,chapter:        17,versenumber:        12,verse:"且侍奉偶像，就是耶和华警戒他们不可行的。"},
{volumn:        12,chapter:        17,versenumber:        13,verse:"但耶和华藉众先知、先见劝戒以色列人和犹大人说：“当离开你们的恶行，谨守我的诫命律例，遵行我吩咐你们列祖，并藉我仆人众先知所传给你们的律法。”"},
{volumn:        12,chapter:        17,versenumber:        14,verse:"他们却不听从，竟硬着颈项，效法他们列祖，不信服耶和华他们的　神，"},
{volumn:        12,chapter:        17,versenumber:        15,verse:"厌弃他的律例和他与他们列祖所立的约，并劝戒他们的话，随从虚无的神，自己成为虚妄，效法周围的外邦人，就是耶和华嘱咐他们不可效法的；"},
{volumn:        12,chapter:        17,versenumber:        16,verse:"离弃耶和华他们　神的一切诫命，为自己铸了两个牛犊的像，立了亚舍拉，敬拜天上的万象，侍奉巴力，"},
{volumn:        12,chapter:        17,versenumber:        17,verse:"又使他们的儿女经火，用占卜，行法术卖了自己，行耶和华眼中看为恶的事，惹动他的怒气。"},
{volumn:        12,chapter:        17,versenumber:        18,verse:"所以耶和华向以色列人大大发怒，从自己面前赶出他们，只剩下犹大一个支派。"},
{volumn:        12,chapter:        17,versenumber:        19,verse:"犹大人也不遵守耶和华他们　神的诫命，随从以色列人所立的条规。"},
{volumn:        12,chapter:        17,versenumber:        20,verse:"耶和华就厌弃以色列全族，使他们受苦，把他们交在抢夺他们的人手中，以致赶出他们离开自己面前，"},
{volumn:        12,chapter:        17,versenumber:        21,verse:"将以色列国从大卫家夺回；他们就立尼八的儿子耶罗波安作王。耶罗波安引诱以色列人不随从耶和华，陷在大罪里。"},
{volumn:        12,chapter:        17,versenumber:        22,verse:"以色列人犯耶罗波安所犯的一切罪，总不离开，"},
{volumn:        12,chapter:        17,versenumber:        23,verse:"以致耶和华从自己面前赶出他们，正如藉他仆人众先知所说的。这样，以色列人从本地被掳到亚述，直到今日。"},
{volumn:        12,chapter:        17,versenumber:        24,verse:"亚述王从巴比伦、古他、亚瓦、哈马，和西法瓦音迁移人来，安置在撒玛利亚的城邑，代替以色列人；他们就得了撒玛利亚，住在其中。"},
{volumn:        12,chapter:        17,versenumber:        25,verse:"他们才住那里的时候，不敬畏耶和华，所以耶和华叫狮子进入他们中间，咬死了些人。"},
{volumn:        12,chapter:        17,versenumber:        26,verse:"有人告诉亚述王说：“你所迁移安置在撒玛利亚各城的那些民，不知道那地之神的规矩，所以那神叫狮子进入他们中间，咬死他们。”"},
{volumn:        12,chapter:        17,versenumber:        27,verse:"亚述王就吩咐说：“叫所掳来的祭司回去一个，使他住在那里，将那地之神的规矩指教那些民。”"},
{volumn:        12,chapter:        17,versenumber:        28,verse:"于是有一个从撒玛利亚掳去的祭司回来，住在伯特利，指教他们怎样敬畏耶和华。"},
{volumn:        12,chapter:        17,versenumber:        29,verse:"然而，各族之人在所住的城里各为自己制造神像，安置在撒玛利亚人所造有邱坛的殿中。"},
{volumn:        12,chapter:        17,versenumber:        30,verse:"巴比伦人造疏割·比讷像；古他人造匿甲像；哈马人造亚示玛像；"},
{volumn:        12,chapter:        17,versenumber:        31,verse:"亚瓦人造匿哈和他珥他像；西法瓦音人用火焚烧儿女，献给西法瓦音的神亚得米勒和亚拿米勒。"},
{volumn:        12,chapter:        17,versenumber:        32,verse:"他们惧怕耶和华，也从他们中间立邱坛的祭司，为他们在有邱坛的殿中献祭。"},
{volumn:        12,chapter:        17,versenumber:        33,verse:"他们又惧怕耶和华，又侍奉自己的神，从何邦迁移，就随何邦的风俗。"},
{volumn:        12,chapter:        17,versenumber:        34,verse:"他们直到如今仍照先前的风俗去行，不专心敬畏耶和华，不全守自己的规矩、典章，也不遵守耶和华吩咐雅各后裔的律法、诫命。（雅各，就是从前耶和华起名叫以色列的。）"},
{volumn:        12,chapter:        17,versenumber:        35,verse:"耶和华曾与他们立约，嘱咐他们说：“不可敬畏别神，不可跪拜侍奉他，也不可向他献祭。"},
{volumn:        12,chapter:        17,versenumber:        36,verse:"但那用大能和伸出来的膀臂领你们出埃及地的耶和华，你们当敬畏，跪拜，向他献祭。"},
{volumn:        12,chapter:        17,versenumber:        37,verse:"他给你们写的律例、典章、律法、诫命，你们应当永远谨守遵行，不可敬畏别神。"},
{volumn:        12,chapter:        17,versenumber:        38,verse:"我耶和华与你们所立的约你们不可忘记，也不可敬畏别神。"},
{volumn:        12,chapter:        17,versenumber:        39,verse:"但要敬畏耶和华你们的　神，他必救你们脱离一切仇敌的手。”"},
{volumn:        12,chapter:        17,versenumber:        40,verse:"他们却不听从，仍照先前的风俗去行。"},
{volumn:        12,chapter:        17,versenumber:        41,verse:"如此这些民又惧怕耶和华，又侍奉他们的偶像。他们子子孙孙也都照样行，效法他们的祖宗，直到今日。"},
{volumn:        12,chapter:        18,versenumber:         1,verse:"以色列王以拉的儿子何细亚第三年，犹大王亚哈斯的儿子希西家登基。"},
{volumn:        12,chapter:        18,versenumber:         2,verse:"他登基的时候年二十五岁，在耶路撒冷作王二十九年。他母亲名叫亚比，是撒迦利雅的女儿。"},
{volumn:        12,chapter:        18,versenumber:         3,verse:"希西家行耶和华眼中看为正的事，效法他祖大卫一切所行的。"},
{volumn:        12,chapter:        18,versenumber:         4,verse:"他废去邱坛，毁坏柱像，砍下木偶，打碎摩西所造的铜蛇，因为到那时以色列人仍向铜蛇烧香。希西家叫铜蛇为铜块（或译：人称铜蛇为铜像）。"},
{volumn:        12,chapter:        18,versenumber:         5,verse:"希西家倚靠耶和华以色列的　神，在他前后的犹大列王中没有一个及他的。"},
{volumn:        12,chapter:        18,versenumber:         6,verse:"因为他专靠耶和华，总不离开，谨守耶和华所吩咐摩西的诫命。"},
{volumn:        12,chapter:        18,versenumber:         7,verse:"耶和华与他同在，他无论往何处去尽都亨通。他背叛、不肯侍奉亚述王。"},
{volumn:        12,chapter:        18,versenumber:         8,verse:"希西家攻击非利士人，直到迦萨，并迦萨的四境，从了望楼到坚固城。"},
{volumn:        12,chapter:        18,versenumber:         9,verse:"希西家王第四年，就是以色列王以拉的儿子何细亚第七年，亚述王撒缦以色上来围困撒玛利亚；"},
{volumn:        12,chapter:        18,versenumber:        10,verse:"过了三年就攻取了城。希西家第六年，以色列王何细亚第九年，撒玛利亚被攻取了。"},
{volumn:        12,chapter:        18,versenumber:        11,verse:"亚述王将以色列人掳到亚述，把他们安置在哈腊与歌散的哈博河边，并玛代人的城邑；"},
{volumn:        12,chapter:        18,versenumber:        12,verse:"都因他们不听从耶和华他们　神的话，违背他的约，就是耶和华仆人摩西吩咐他们所当守的。"},
{volumn:        12,chapter:        18,versenumber:        13,verse:"希西家王十四年，亚述王西拿基立上来攻击犹大的一切坚固城，将城攻取。"},
{volumn:        12,chapter:        18,versenumber:        14,verse:"犹大王希西家差人往拉吉去见亚述王，说：“我有罪了，求你离开我；凡你罚我的，我必承当。”于是亚述王罚犹大王希西家银子三百他连得，金子三十他连得。"},
{volumn:        12,chapter:        18,versenumber:        15,verse:"希西家就把耶和华殿里和王宫府库里所有的银子都给了他。"},
{volumn:        12,chapter:        18,versenumber:        16,verse:"那时，犹大王希西家将耶和华殿门上的金子和他自己包在柱上的金子都刮下来，给了亚述王。"},
{volumn:        12,chapter:        18,versenumber:        17,verse:"亚述王从拉吉差遣他珥探、拉伯撒利，和拉伯沙基率领大军往耶路撒冷，到希西家王那里去。他们上到耶路撒冷，就站在上池的水沟旁，在漂布地的大路上。"},
{volumn:        12,chapter:        18,versenumber:        18,verse:"他们呼叫王的时候，就有希勒家的儿子家宰以利亚敬，并书记舍伯那和亚萨的儿子史官约亚，出来见他们。"},
{volumn:        12,chapter:        18,versenumber:        19,verse:"拉伯沙基说：“你们去告诉希西家说，亚述大王如此说：‘你所倚靠的有什么可仗赖的呢？"},
{volumn:        12,chapter:        18,versenumber:        20,verse:"你说有打仗的计谋和能力，我看不过是虚话。你到底倚靠谁才背叛我呢？"},
{volumn:        12,chapter:        18,versenumber:        21,verse:"看哪，你所倚靠的埃及是那压伤的苇杖；人若靠这杖，就必刺透他的手。埃及王法老向一切倚靠他的人也是这样。"},
{volumn:        12,chapter:        18,versenumber:        22,verse:"你们若对我说：我们倚靠耶和华我们的　神，希西家岂不是将　神的邱坛和祭坛废去，且对犹大和耶路撒冷的人说：你们当在耶路撒冷这坛前敬拜吗？"},
{volumn:        12,chapter:        18,versenumber:        23,verse:"现在你把当头给我主亚述王，我给你二千匹马，看你这一面骑马的人够不够。"},
{volumn:        12,chapter:        18,versenumber:        24,verse:"若不然，怎能打败我主臣仆中最小的军长呢？你竟倚靠埃及的战车马兵吗？"},
{volumn:        12,chapter:        18,versenumber:        25,verse:"现在我上来攻击毁灭这地，岂没有耶和华的意思吗？耶和华吩咐我说：你上去攻击毁灭这地吧！’”"},
{volumn:        12,chapter:        18,versenumber:        26,verse:"希勒家的儿子以利亚敬和舍伯那，并约亚，对拉伯沙基说：“求你用亚兰言语和仆人说话，因为我们懂得；不要用犹大言语和我们说话，达到城上百姓的耳中。”"},
{volumn:        12,chapter:        18,versenumber:        27,verse:"拉伯沙基说：“我主差遣我来，岂是单对你和你的主说这些话吗？不也是对这些坐在城上、要与你们一同吃自己粪、喝自己尿的人说吗？”"},
{volumn:        12,chapter:        18,versenumber:        28,verse:"于是拉伯沙基站着，用犹大言语大声喊着说：“你们当听亚述大王的话！"},
{volumn:        12,chapter:        18,versenumber:        29,verse:"王如此说：‘你们不要被希西家欺哄了；因他不能救你们脱离我的手。"},
{volumn:        12,chapter:        18,versenumber:        30,verse:"也不要听希西家使你们倚靠耶和华，说耶和华必要拯救我们，这城必不交在亚述王的手中。’"},
{volumn:        12,chapter:        18,versenumber:        31,verse:"不要听希西家的话！因亚述王如此说：‘你们要与我和好，出来投降我，各人就可以吃自己葡萄树和无花果树的果子，喝自己井里的水。"},
{volumn:        12,chapter:        18,versenumber:        32,verse:"等我来领你们到一个地方与你们本地一样，就是有五谷和新酒之地，有粮食和葡萄园之地，有橄榄树和蜂蜜之地，好使你们存活，不至于死。希西家劝导你们，说耶和华必拯救我们；你们不要听他的话。"},
{volumn:        12,chapter:        18,versenumber:        33,verse:"列国的神有哪一个救他本国脱离亚述王的手呢？"},
{volumn:        12,chapter:        18,versenumber:        34,verse:"哈马、亚珥拔的神在哪里呢？西法瓦音、希拿、以瓦的神在哪里呢？他们曾救撒玛利亚脱离我的手吗？"},
{volumn:        12,chapter:        18,versenumber:        35,verse:"这些国的神有谁曾救自己的国脱离我的手呢？难道耶和华能救耶路撒冷脱离我的手吗？’”"},
{volumn:        12,chapter:        18,versenumber:        36,verse:"百姓静默不言，并不回答一句，因为王曾吩咐说：“不要回答他。”"},
{volumn:        12,chapter:        18,versenumber:        37,verse:"当下，希勒家的儿子家宰以利亚敬和书记舍伯那，并亚萨的儿子史官约亚，都撕裂衣服，来到希西家那里，将拉伯沙基的话告诉了他。"},
{volumn:        12,chapter:        19,versenumber:         1,verse:"希西家王听见，就撕裂衣服，披上麻布，进了耶和华的殿；"},
{volumn:        12,chapter:        19,versenumber:         2,verse:"使家宰以利亚敬和书记舍伯那，并祭司中的长老，都披上麻布，去见亚摩斯的儿子先知以赛亚，"},
{volumn:        12,chapter:        19,versenumber:         3,verse:"对他说：“希西家如此说：‘今日是急难、责罚、凌辱的日子，就如妇人将要生产婴孩，却没有力量生产。"},
{volumn:        12,chapter:        19,versenumber:         4,verse:"或者耶和华你的　神听见拉伯沙基的一切话，就是他主人亚述王打发他来辱骂永生　神的话，耶和华你的　神听见这话，就发斥责。故此，求你为余剩的民扬声祷告。’”"},
{volumn:        12,chapter:        19,versenumber:         5,verse:"希西家王的臣仆就去见以赛亚。"},
{volumn:        12,chapter:        19,versenumber:         6,verse:"以赛亚对他们说：“要这样对你们的主人说，耶和华如此说：‘你听见亚述王的仆人亵渎我的话，不要惧怕。"},
{volumn:        12,chapter:        19,versenumber:         7,verse:"我必惊动（原文是使灵进入）他的心，他要听见风声就归回本地。我必使他在那里倒在刀下。’”"},
{volumn:        12,chapter:        19,versenumber:         8,verse:"拉伯沙基回去，正遇见亚述王攻打立拿，原来他早听见亚述王拔营离开拉吉。"},
{volumn:        12,chapter:        19,versenumber:         9,verse:"亚述王听见人论古实王特哈加说：“他出来要与你争战。”于是亚述王又打发使者去见希西家，吩咐他们说："},
{volumn:        12,chapter:        19,versenumber:        10,verse:"“你们对犹大王希西家如此说：‘不要听你所倚靠的　神欺哄你，说耶路撒冷必不交在亚述王的手中。"},
{volumn:        12,chapter:        19,versenumber:        11,verse:"你总听说亚述诸王向列国所行的，乃是尽行灭绝，难道你还能得救吗？"},
{volumn:        12,chapter:        19,versenumber:        12,verse:"我列祖所毁灭的，就是歌散、哈兰、利色，和属提·拉撒的伊甸人，这些国的神何曾拯救这些国呢？"},
{volumn:        12,chapter:        19,versenumber:        13,verse:"哈马的王、亚珥拔的王、西法瓦音城的王、希拿，和以瓦的王都在哪里呢？’”"},
{volumn:        12,chapter:        19,versenumber:        14,verse:"希西家从使者手里接过书信来，看完了，就上耶和华的殿，将书信在耶和华面前展开。"},
{volumn:        12,chapter:        19,versenumber:        15,verse:"希西家向耶和华祷告说：“坐在二基路伯上耶和华以色列的　神啊，你是天下万国的　神，你曾创造天地。"},
{volumn:        12,chapter:        19,versenumber:        16,verse:"耶和华啊，求你侧耳而听！耶和华啊，求你睁眼而看！要听西拿基立打发使者来辱骂永生　神的话。"},
{volumn:        12,chapter:        19,versenumber:        17,verse:"耶和华啊，亚述诸王果然使列国和列国之地变为荒凉，"},
{volumn:        12,chapter:        19,versenumber:        18,verse:"将列国的神像都扔在火里；因为它本不是神，乃是人手所造的，是木头石头的，所以灭绝它。"},
{volumn:        12,chapter:        19,versenumber:        19,verse:"耶和华我们的　神啊，现在求你救我们脱离亚述王的手，使天下万国都知道惟独你耶和华是　神！”"},
{volumn:        12,chapter:        19,versenumber:        20,verse:"亚摩斯的儿子以赛亚就打发人去见希西家，说：“耶和华以色列的　神如此说：‘你既然求我攻击亚述王西拿基立，我已听见了。’"},
{volumn:        12,chapter:        19,versenumber:        21,verse:"耶和华论他这样说：‘锡安的处女藐视你，嗤笑你；耶路撒冷的女子向你摇头。"},
{volumn:        12,chapter:        19,versenumber:        22,verse:"你辱骂谁？亵渎谁？扬起声来，高举眼目攻击谁呢？乃是攻击以色列的圣者！"},
{volumn:        12,chapter:        19,versenumber:        23,verse:"你藉你的使者辱骂主，并说：我率领许多战车上山顶，到黎巴嫩极深之处；我要砍伐其中高大的香柏树和佳美的松树；我必上极高之处，进入肥田的树林。"},
{volumn:        12,chapter:        19,versenumber:        24,verse:"我已经在外邦挖井喝水；我必用脚掌踏干埃及的一切河。’"},
{volumn:        12,chapter:        19,versenumber:        25,verse:"“耶和华说：‘我早先所做的，古时所立的，就是现在藉你使坚固城荒废，变为乱堆，这事你岂没有听见吗？"},
{volumn:        12,chapter:        19,versenumber:        26,verse:"所以其中的居民力量甚小，惊惶羞愧。他们像野草，像青菜，如房顶上的草，又如未长成而枯干的禾稼。"},
{volumn:        12,chapter:        19,versenumber:        27,verse:"你坐下，你出去，你进来，你向我发烈怒，我都知道。"},
{volumn:        12,chapter:        19,versenumber:        28,verse:"因你向我发烈怒，又因你狂傲的话达到我耳中，我就要用钩子钩上你的鼻子，把嚼环放在你口里，使你从你来的路转回去。’"},
{volumn:        12,chapter:        19,versenumber:        29,verse:"“以色列人哪，我赐你们一个证据：你们今年要吃自生的，明年也要吃自长的；至于后年，你们要耕种收割，栽植葡萄园，吃其中的果子。"},
{volumn:        12,chapter:        19,versenumber:        30,verse:"犹大家所逃脱余剩的，仍要往下扎根，向上结果。"},
{volumn:        12,chapter:        19,versenumber:        31,verse:"必有余剩的民从耶路撒冷而出；必有逃脱的人从锡安山而来。耶和华的热心必成就这事。"},
{volumn:        12,chapter:        19,versenumber:        32,verse:"“所以，耶和华论亚述王如此说：‘他必不得来到这城，也不在这里射箭，不得拿盾牌到城前，也不筑垒攻城。"},
{volumn:        12,chapter:        19,versenumber:        33,verse:"他从哪条路来，必从那条路回去，必不得来到这城。这是耶和华说的。"},
{volumn:        12,chapter:        19,versenumber:        34,verse:"因我为自己的缘故，又为我仆人大卫的缘故，必保护拯救这城。’”"},
{volumn:        12,chapter:        19,versenumber:        35,verse:"当夜，耶和华的使者出去，在亚述营中杀了十八万五千人。清早有人起来，一看，都是死尸了。"},
{volumn:        12,chapter:        19,versenumber:        36,verse:"亚述王西拿基立就拔营回去，住在尼尼微。"},
{volumn:        12,chapter:        19,versenumber:        37,verse:"一日在他的神尼斯洛庙里叩拜，他儿子亚得米勒和沙利色用刀杀了他，就逃到亚拉腊地。他儿子以撒哈顿接续他作王。"},
{volumn:        12,chapter:        20,versenumber:         1,verse:"那时，希西家病得要死。亚摩斯的儿子先知以赛亚去见他，对他说：“耶和华如此说：‘你当留遗命与你的家，因为你必死，不能活了。’”"},
{volumn:        12,chapter:        20,versenumber:         2,verse:"希西家就转脸朝墙，祷告耶和华说："},
{volumn:        12,chapter:        20,versenumber:         3,verse:"“耶和华啊，求你记念我在你面前怎样存完全的心，按诚实行事，又做你眼中所看为善的。”希西家就痛哭了。"},
{volumn:        12,chapter:        20,versenumber:         4,verse:"以赛亚出来，还没有到中院（院：或译城），耶和华的话就临到他，说："},
{volumn:        12,chapter:        20,versenumber:         5,verse:"“你回去告诉我民的君希西家说：耶和华你祖大卫的　神如此说：‘我听见了你的祷告，看见了你的眼泪，我必医治你；到第三日，你必上到耶和华的殿。"},
{volumn:        12,chapter:        20,versenumber:         6,verse:"我必加增你十五年的寿数，并且我要救你和这城脱离亚述王的手。我为自己和我仆人大卫的缘故，必保护这城。’”"},
{volumn:        12,chapter:        20,versenumber:         7,verse:"以赛亚说：“当取一块无花果饼来。”人就取了来，贴在疮上，王便痊愈了。"},
{volumn:        12,chapter:        20,versenumber:         8,verse:"希西家问以赛亚说：“耶和华必医治我，到第三日，我能上耶和华的殿，有什么兆头呢？”"},
{volumn:        12,chapter:        20,versenumber:         9,verse:"以赛亚说：“耶和华必成就他所说的。这是他给你的兆头：你要日影向前进十度呢？是要往后退十度呢？”"},
{volumn:        12,chapter:        20,versenumber:        10,verse:"希西家回答说：“日影向前进十度容易，我要日影往后退十度。”"},
{volumn:        12,chapter:        20,versenumber:        11,verse:"先知以赛亚求告耶和华，耶和华就使亚哈斯的日晷向前进的日影，往后退了十度。"},
{volumn:        12,chapter:        20,versenumber:        12,verse:"那时，巴比伦王巴拉但的儿子米罗达·巴拉但听见希西家病而痊愈，就送书信和礼物给他。"},
{volumn:        12,chapter:        20,versenumber:        13,verse:"希西家听从使者的话，就把他宝库的金子、银子、香料、贵重的膏油，和他武库的一切军器，并他所有的财宝，都给他们看。他家中和他全国之内，希西家没有一样不给他们看的。"},
{volumn:        12,chapter:        20,versenumber:        14,verse:"于是先知以赛亚来见希西家王，问他说：“这些人说什么？他们从哪里来见你？”希西家说：“他们从远方的巴比伦来。”"},
{volumn:        12,chapter:        20,versenumber:        15,verse:"以赛亚说：“他们在你家里看见了什么？”希西家说：“凡我家中所有的，他们都看见了；我财宝中没有一样不给他们看的。”"},
{volumn:        12,chapter:        20,versenumber:        16,verse:"以赛亚对希西家说：“你要听耶和华的话，"},
{volumn:        12,chapter:        20,versenumber:        17,verse:"日子必到，凡你家里所有的，并你列祖积蓄到如今的，都要被掳到巴比伦去，不留下一样。这是耶和华说的。"},
{volumn:        12,chapter:        20,versenumber:        18,verse:"并且从你本身所生的众子，其中必有被掳去在巴比伦王宫里当太监的。”"},
{volumn:        12,chapter:        20,versenumber:        19,verse:"希西家对以赛亚说：“你所说耶和华的话甚好！若在我的年日中有太平和稳固的景况，岂不是好吗？”"},
{volumn:        12,chapter:        20,versenumber:        20,verse:"希西家其余的事和他的勇力，他怎样挖池、挖沟、引水入城，都写在犹大列王记上。"},
{volumn:        12,chapter:        20,versenumber:        21,verse:"希西家与他列祖同睡。他儿子玛拿西接续他作王。"},
{volumn:        12,chapter:        21,versenumber:         1,verse:"玛拿西登基的时候年十二岁，在耶路撒冷作王五十五年。他母亲名叫协西巴。"},
{volumn:        12,chapter:        21,versenumber:         2,verse:"玛拿西行耶和华眼中看为恶的事，效法耶和华在以色列人面前赶出的外邦人所行可憎的事。"},
{volumn:        12,chapter:        21,versenumber:         3,verse:"重新建筑他父希西家所毁坏的邱坛，又为巴力筑坛，做亚舍拉像，效法以色列王亚哈所行的，且敬拜侍奉天上的万象；"},
{volumn:        12,chapter:        21,versenumber:         4,verse:"在耶和华殿宇中筑坛。耶和华曾指着这殿说：“我必立我的名在耶路撒冷。”"},
{volumn:        12,chapter:        21,versenumber:         5,verse:"他在耶和华殿的两院中为天上的万象筑坛，"},
{volumn:        12,chapter:        21,versenumber:         6,verse:"并使他的儿子经火，又观兆，用法术，立交鬼的和行巫术的，多行耶和华眼中看为恶的事，惹动他的怒气；"},
{volumn:        12,chapter:        21,versenumber:         7,verse:"又在殿内立雕刻的亚舍拉像。耶和华曾对大卫和他儿子所罗门说：“我在以色列众支派中所选择的耶路撒冷和这殿，必立我的名，直到永远。"},
{volumn:        12,chapter:        21,versenumber:         8,verse:"以色列人若谨守遵行我一切所吩咐他们的和我仆人摩西所吩咐他们的一切律法，我就不再使他们挪移离开我所赐给他们列祖之地。”"},
{volumn:        12,chapter:        21,versenumber:         9,verse:"他们却不听从。玛拿西引诱他们行恶，比耶和华在以色列人面前所灭的列国更甚。"},
{volumn:        12,chapter:        21,versenumber:        10,verse:"耶和华藉他仆人众先知说："},
{volumn:        12,chapter:        21,versenumber:        11,verse:"“因犹大王玛拿西行这些可憎的恶事比先前亚摩利人所行的更甚，使犹大人拜他的偶像，陷在罪里；"},
{volumn:        12,chapter:        21,versenumber:        12,verse:"所以耶和华以色列的　神如此说：我必降祸与耶路撒冷和犹大，叫一切听见的人无不耳鸣。"},
{volumn:        12,chapter:        21,versenumber:        13,verse:"我必用量撒玛利亚的准绳和亚哈家的线铊拉在耶路撒冷上，必擦净耶路撒冷，如人擦盘，将盘倒扣。"},
{volumn:        12,chapter:        21,versenumber:        14,verse:"我必弃掉所余剩的子民（原文是产业），把他们交在仇敌手中，使他们成为一切仇敌掳掠之物；"},
{volumn:        12,chapter:        21,versenumber:        15,verse:"是因他们自从列祖出埃及直到如今，常行我眼中看为恶的事，惹动我的怒气。”"},
{volumn:        12,chapter:        21,versenumber:        16,verse:"玛拿西行耶和华眼中看为恶的事，使犹大人陷在罪里，又流许多无辜人的血，充满了耶路撒冷，从这边直到那边。"},
{volumn:        12,chapter:        21,versenumber:        17,verse:"玛拿西其余的事，凡他所行的和他所犯的罪都写在犹大列王记上。"},
{volumn:        12,chapter:        21,versenumber:        18,verse:"玛拿西与他列祖同睡，葬在自己宫院乌撒的园内；他儿子亚们接续他作王。"},
{volumn:        12,chapter:        21,versenumber:        19,verse:"亚们登基的时候年二十二岁，在耶路撒冷作王二年。他母亲名叫米舒利密，是约提巴人哈鲁斯的女儿。"},
{volumn:        12,chapter:        21,versenumber:        20,verse:"亚们行耶和华眼中看为恶的事，与他父亲玛拿西所行的一样；"},
{volumn:        12,chapter:        21,versenumber:        21,verse:"行他父亲一切所行的，敬奉他父亲所敬奉的偶像，"},
{volumn:        12,chapter:        21,versenumber:        22,verse:"离弃耶和华他列祖的　神，不遵行耶和华的道。"},
{volumn:        12,chapter:        21,versenumber:        23,verse:"亚们王的臣仆背叛他，在宫里杀了他。"},
{volumn:        12,chapter:        21,versenumber:        24,verse:"但国民杀了那些背叛亚们王的人，立他儿子约西亚接续他作王。"},
{volumn:        12,chapter:        21,versenumber:        25,verse:"亚们其余所行的事都写在犹大列王记上。"},
{volumn:        12,chapter:        21,versenumber:        26,verse:"亚们葬在乌撒的园内自己的坟墓里。他儿子约西亚接续他作王。"},
{volumn:        12,chapter:        22,versenumber:         1,verse:"约西亚登基的时候年八岁，在耶路撒冷作王三十一年。他母亲名叫耶底大，是波斯加人亚大雅的女儿。"},
{volumn:        12,chapter:        22,versenumber:         2,verse:"约西亚行耶和华眼中看为正的事，行他祖大卫一切所行的，不偏左右。"},
{volumn:        12,chapter:        22,versenumber:         3,verse:"约西亚王十八年，王差遣米书兰的孙子、亚萨利的儿子、书记沙番上耶和华殿去，吩咐他说："},
{volumn:        12,chapter:        22,versenumber:         4,verse:"“你去见大祭司希勒家，使他将奉到耶和华殿的银子，就是守门的从民中收聚的银子，数算数算，"},
{volumn:        12,chapter:        22,versenumber:         5,verse:"交给耶和华殿里办事的人，使他们转交耶和华殿里做工的人，好修理殿的破坏之处，"},
{volumn:        12,chapter:        22,versenumber:         6,verse:"就是转交木匠和工人，并瓦匠，又买木料和凿成的石头修理殿宇，"},
{volumn:        12,chapter:        22,versenumber:         7,verse:"将银子交在办事的人手里，不与他们算帐，因为他们办事诚实。”"},
{volumn:        12,chapter:        22,versenumber:         8,verse:"大祭司希勒家对书记沙番说：“我在耶和华殿里得了律法书。”希勒家将书递给沙番，沙番就看了。"},
{volumn:        12,chapter:        22,versenumber:         9,verse:"书记沙番到王那里，回复王说：“你的仆人已将殿里的银子倒出数算，交给耶和华殿里办事的人了。”"},
{volumn:        12,chapter:        22,versenumber:        10,verse:"书记沙番又对王说：“祭司希勒家递给我一卷书。”沙番就在王面前读那书。"},
{volumn:        12,chapter:        22,versenumber:        11,verse:"王听见律法书上的话，便撕裂衣服，"},
{volumn:        12,chapter:        22,versenumber:        12,verse:"吩咐祭司希勒家与沙番的儿子亚希甘、米该亚的儿子亚革波、书记沙番和王的臣仆亚撒雅，说："},
{volumn:        12,chapter:        22,versenumber:        13,verse:"“你们去为我、为民、为犹大众人，以这书上的话求问耶和华；因为我们列祖没有听从这书上的言语，没有遵着书上所吩咐我们的去行，耶和华就向我们大发烈怒。”"},
{volumn:        12,chapter:        22,versenumber:        14,verse:"于是，祭司希勒家和亚希甘、亚革波、沙番、亚撒雅都去见女先知户勒大。户勒大是掌管礼服沙龙的妻；沙龙是哈珥哈斯的孙子、特瓦的儿子。户勒大住在耶路撒冷第二区。他们请问于她。"},
{volumn:        12,chapter:        22,versenumber:        15,verse:"她对他们说：“耶和华以色列的　神如此说：‘你们可以回复那差遣你们来见我的人说，"},
{volumn:        12,chapter:        22,versenumber:        16,verse:"耶和华如此说：我必照着犹大王所读那书上的一切话，降祸与这地和其上的居民。"},
{volumn:        12,chapter:        22,versenumber:        17,verse:"因为他们离弃我，向别神烧香，用他们手所做的惹我发怒，所以我的忿怒必向这地发作，总不止息。’"},
{volumn:        12,chapter:        22,versenumber:        18,verse:"然而，差遣你们来求问耶和华的犹大王，你们要这样回复他说：‘耶和华以色列的　神如此说：至于你所听见的话，"},
{volumn:        12,chapter:        22,versenumber:        19,verse:"就是听见我指着这地和其上的居民所说、要使这地变为荒场、民受咒诅的话，你便心里敬服，在我面前自卑，撕裂衣服，向我哭泣，因此我应允了你。这是我耶和华说的。"},
{volumn:        12,chapter:        22,versenumber:        20,verse:"我必使你平平安安地归到坟墓到你列祖那里；我要降与这地的一切灾祸，你也不至亲眼看见。’”他们就回复王去了。"},
{volumn:        12,chapter:        23,versenumber:         1,verse:"王差遣人招聚犹大和耶路撒冷的众长老来。"},
{volumn:        12,chapter:        23,versenumber:         2,verse:"王和犹大众人与耶路撒冷的居民，并祭司、先知，和所有的百姓，无论大小，都一同上到耶和华的殿；王就把耶和华殿里所得的约书念给他们听。"},
{volumn:        12,chapter:        23,versenumber:         3,verse:"王站在柱旁，在耶和华面前立约，要尽心尽性地顺从耶和华，遵守他的诫命、法度、律例，成就这书上所记的约言。众民都服从这约。"},
{volumn:        12,chapter:        23,versenumber:         4,verse:"王吩咐大祭司希勒家和副祭司，并把门的，将那为巴力和亚舍拉，并天上万象所造的器皿，都从耶和华殿里搬出来，在耶路撒冷外汲沦溪旁的田间烧了，把灰拿到伯特利去。"},
{volumn:        12,chapter:        23,versenumber:         5,verse:"从前犹大列王所立拜偶像的祭司，在犹大城邑的邱坛和耶路撒冷的周围烧香，现在王都废去，又废去向巴力和日、月、行星（或译：十二宫），并天上万象烧香的人；"},
{volumn:        12,chapter:        23,versenumber:         6,verse:"又从耶和华殿里将亚舍拉搬到耶路撒冷外汲沦溪边焚烧，打碎成灰，将灰撒在平民的坟上；"},
{volumn:        12,chapter:        23,versenumber:         7,verse:"又拆毁耶和华殿里娈童的屋子，就是妇女为亚舍拉织帐子的屋子，"},
{volumn:        12,chapter:        23,versenumber:         8,verse:"并且从犹大的城邑带众祭司来，污秽祭司烧香的邱坛，从迦巴直到别是巴，又拆毁城门旁的邱坛，这邱坛在邑宰约书亚门前，进城门的左边。"},
{volumn:        12,chapter:        23,versenumber:         9,verse:"但是邱坛的祭司不登耶路撒冷耶和华的坛，只在他们弟兄中间吃无酵饼。"},
{volumn:        12,chapter:        23,versenumber:        10,verse:"又污秽欣嫩子谷的陀斐特，不许人在那里使儿女经火献给摩洛；"},
{volumn:        12,chapter:        23,versenumber:        11,verse:"又将犹大列王在耶和华殿门旁、太监拿单·米勒靠近游廊的屋子、向日头所献的马废去，且用火焚烧日车。"},
{volumn:        12,chapter:        23,versenumber:        12,verse:"犹大列王在亚哈斯楼顶上所筑的坛和玛拿西在耶和华殿两院中所筑的坛，王都拆毁打碎了，就把灰倒在汲沦溪中。"},
{volumn:        12,chapter:        23,versenumber:        13,verse:"从前以色列王所罗门在耶路撒冷前、邪僻山右边为西顿人可憎的神亚斯他录、摩押人可憎的神基抹、亚扪人可憎的神米勒公所筑的邱坛，王都污秽了，"},
{volumn:        12,chapter:        23,versenumber:        14,verse:"又打碎柱像，砍下木偶，将人的骨头充满了那地方。"},
{volumn:        12,chapter:        23,versenumber:        15,verse:"他将伯特利的坛，就是叫以色列人陷在罪里、尼八的儿子耶罗波安所筑的那坛，都拆毁焚烧，打碎成灰，并焚烧了亚舍拉。"},
{volumn:        12,chapter:        23,versenumber:        16,verse:"约西亚回头，看见山上的坟墓，就打发人将坟墓里的骸骨取出来，烧在坛上，污秽了坛，正如从前神人宣传耶和华的话。"},
{volumn:        12,chapter:        23,versenumber:        17,verse:"约西亚问说：“我所看见的是什么碑？”那城里的人回答说：“先前有神人从犹大来，预先说王现在向伯特利坛所行的事，这就是他的墓碑。”"},
{volumn:        12,chapter:        23,versenumber:        18,verse:"约西亚说：“由他吧！不要挪移他的骸骨。”他们就不动他的骸骨，也不动从撒玛利亚来那先知的骸骨。"},
{volumn:        12,chapter:        23,versenumber:        19,verse:"从前以色列诸王在撒玛利亚的城邑建筑邱坛的殿，惹动耶和华的怒气，现在约西亚都废去了，就如他在伯特利所行的一般；"},
{volumn:        12,chapter:        23,versenumber:        20,verse:"又将邱坛的祭司都杀在坛上，并在坛上烧人的骨头，就回耶路撒冷去了。"},
{volumn:        12,chapter:        23,versenumber:        21,verse:"王吩咐众民说：“你们当照这约书上所写的，向耶和华你们的　神守逾越节。”"},
{volumn:        12,chapter:        23,versenumber:        22,verse:"自从士师治理以色列人和以色列王、犹大王的时候，直到如今，实在没有守过这样的逾越节；"},
{volumn:        12,chapter:        23,versenumber:        23,verse:"只有约西亚王十八年在耶路撒冷向耶和华守这逾越节。"},
{volumn:        12,chapter:        23,versenumber:        24,verse:"凡犹大国和耶路撒冷所有交鬼的、行巫术的，与家中的神像和偶像，并一切可憎之物，约西亚尽都除掉，成就了祭司希勒家在耶和华殿里所得律法书上所写的话。"},
{volumn:        12,chapter:        23,versenumber:        25,verse:"在约西亚以前没有王像他尽心、尽性、尽力地归向耶和华，遵行摩西的一切律法；在他以后也没有兴起一个王像他。"},
{volumn:        12,chapter:        23,versenumber:        26,verse:"然而，耶和华向犹大所发猛烈的怒气仍不止息，是因玛拿西诸事惹动他。"},
{volumn:        12,chapter:        23,versenumber:        27,verse:"耶和华说：“我必将犹大人从我面前赶出，如同赶出以色列人一般；我必弃掉我从前所选择的这城耶路撒冷和我所说立我名的殿。”"},
{volumn:        12,chapter:        23,versenumber:        28,verse:"约西亚其余的事，凡他所行的都写在犹大列王记上。"},
{volumn:        12,chapter:        23,versenumber:        29,verse:"约西亚年间，埃及王法老尼哥上到幼发拉底河攻击亚述王；约西亚王去抵挡他。埃及王遇见约西亚在米吉多，就杀了他。"},
{volumn:        12,chapter:        23,versenumber:        30,verse:"他的臣仆用车将他的尸首从米吉多送到耶路撒冷，葬在他自己的坟墓里。国民膏约西亚的儿子约哈斯接续他父亲作王。"},
{volumn:        12,chapter:        23,versenumber:        31,verse:"约哈斯登基的时候年二十三岁，在耶路撒冷作王三个月。他母亲名叫哈慕他，是立拿人耶利米的女儿。"},
{volumn:        12,chapter:        23,versenumber:        32,verse:"约哈斯行耶和华眼中看为恶的事，效法他列祖一切所行的。"},
{volumn:        12,chapter:        23,versenumber:        33,verse:"法老尼哥将约哈斯锁禁在哈马地的利比拉，不许他在耶路撒冷作王，又罚犹大国银子一百他连得，金子一他连得。"},
{volumn:        12,chapter:        23,versenumber:        34,verse:"法老尼哥立约西亚的儿子以利亚敬接续他父亲约西亚作王，给他改名叫约雅敬，却将约哈斯带到埃及，他就死在那里。"},
{volumn:        12,chapter:        23,versenumber:        35,verse:"约雅敬将金银给法老，遵着法老的命向国民征取金银，按着各人的力量派定，索要金银，好给法老尼哥。"},
{volumn:        12,chapter:        23,versenumber:        36,verse:"约雅敬登基的时候年二十五岁，在耶路撒冷作王十一年。他母亲名叫西布大，是鲁玛人毗大雅的女儿。"},
{volumn:        12,chapter:        23,versenumber:        37,verse:"约雅敬行耶和华眼中看为恶的事，效法他列祖一切所行的。"},
{volumn:        12,chapter:        24,versenumber:         1,verse:"约雅敬年间，巴比伦王尼布甲尼撒上到犹大；约雅敬服侍他三年，然后背叛他。"},
{volumn:        12,chapter:        24,versenumber:         2,verse:"耶和华使迦勒底军、亚兰军、摩押军，和亚扪人的军来攻击约雅敬，毁灭犹大，正如耶和华藉他仆人众先知所说的。"},
{volumn:        12,chapter:        24,versenumber:         3,verse:"这祸临到犹大人，诚然是耶和华所命的，要将他们从自己面前赶出，是因玛拿西所犯的一切罪；"},
{volumn:        12,chapter:        24,versenumber:         4,verse:"又因他流无辜人的血，充满了耶路撒冷；耶和华决不肯赦免。"},
{volumn:        12,chapter:        24,versenumber:         5,verse:"约雅敬其余的事，凡他所行的都写在犹大列王记上。"},
{volumn:        12,chapter:        24,versenumber:         6,verse:"约雅敬与他列祖同睡。他儿子约雅斤接续他作王。"},
{volumn:        12,chapter:        24,versenumber:         7,verse:"埃及王不再从他国中出来；因为巴比伦王将埃及王所管之地，从埃及小河直到幼发拉底河都夺去了。"},
{volumn:        12,chapter:        24,versenumber:         8,verse:"约雅斤登基的时候年十八岁，在耶路撒冷作王三个月。他母亲名叫尼护施他，是耶路撒冷人以利拿单的女儿。"},
{volumn:        12,chapter:        24,versenumber:         9,verse:"约雅斤行耶和华眼中看为恶的事，效法他父亲一切所行的。"},
{volumn:        12,chapter:        24,versenumber:        10,verse:"那时，巴比伦王尼布甲尼撒的军兵上到耶路撒冷，围困城。"},
{volumn:        12,chapter:        24,versenumber:        11,verse:"当他军兵围困城的时候，巴比伦王尼布甲尼撒就亲自来了。"},
{volumn:        12,chapter:        24,versenumber:        12,verse:"犹大王约雅斤和他母亲、臣仆、首领、太监一同出城，投降巴比伦王；巴比伦王便拿住他。那时是巴比伦王第八年。"},
{volumn:        12,chapter:        24,versenumber:        13,verse:"巴比伦王将耶和华殿和王宫里的宝物都拿去了，将以色列王所罗门所造耶和华殿里的金器都毁坏了，正如耶和华所说的；"},
{volumn:        12,chapter:        24,versenumber:        14,verse:"又将耶路撒冷的众民和众首领，并所有大能的勇士，共一万人，连一切木匠、铁匠都掳了去；除了国中极贫穷的人以外，没有剩下的；"},
{volumn:        12,chapter:        24,versenumber:        15,verse:"并将约雅斤和王母、后妃、太监，与国中的大官，都从耶路撒冷掳到巴比伦去了；"},
{volumn:        12,chapter:        24,versenumber:        16,verse:"又将一切勇士七千人和木匠、铁匠一千人，都是能上阵的勇士，全掳到巴比伦去了。"},
{volumn:        12,chapter:        24,versenumber:        17,verse:"巴比伦王立约雅斤的叔叔玛探雅代替他作王，给玛探雅改名叫西底家。"},
{volumn:        12,chapter:        24,versenumber:        18,verse:"西底家登基的时候年二十一岁，在耶路撒冷作王十一年。他母亲名叫哈慕他，是立拿人耶利米的女儿。"},
{volumn:        12,chapter:        24,versenumber:        19,verse:"西底家行耶和华眼中看为恶的事，是照约雅敬一切所行的。"},
{volumn:        12,chapter:        24,versenumber:        20,verse:"因此耶和华的怒气在耶路撒冷和犹大发作，以致将人民从自己面前赶出。"},
{volumn:        12,chapter:        25,versenumber:         1,verse:"西底家背叛巴比伦王。他作王第九年十月初十日，巴比伦王尼布甲尼撒率领全军来攻击耶路撒冷，对城安营，四围筑垒攻城。"},
{volumn:        12,chapter:        25,versenumber:         2,verse:"于是城被围困，直到西底家王十一年。"},
{volumn:        12,chapter:        25,versenumber:         3,verse:"四月初九日，城里有大饥荒，甚至百姓都没有粮食。"},
{volumn:        12,chapter:        25,versenumber:         4,verse:"城被攻破，一切兵丁就在夜间从靠近王园两城中间的门逃跑。迦勒底人正在四围攻城，王就向亚拉巴逃走。"},
{volumn:        12,chapter:        25,versenumber:         5,verse:"迦勒底的军队追赶王，在耶利哥的平原追上他；他的全军都离开他四散了。"},
{volumn:        12,chapter:        25,versenumber:         6,verse:"迦勒底人就拿住王，带他到在利比拉的巴比伦王那里审判他。"},
{volumn:        12,chapter:        25,versenumber:         7,verse:"在西底家眼前杀了他的众子，并且剜了西底家的眼睛，用铜链锁着他，带到巴比伦去。"},
{volumn:        12,chapter:        25,versenumber:         8,verse:"巴比伦王尼布甲尼撒十九年五月初七日，巴比伦王的臣仆、护卫长尼布撒拉旦来到耶路撒冷，"},
{volumn:        12,chapter:        25,versenumber:         9,verse:"用火焚烧耶和华的殿和王宫，又焚烧耶路撒冷的房屋，就是各大户家的房屋。"},
{volumn:        12,chapter:        25,versenumber:        10,verse:"跟从护卫长迦勒底的全军就拆毁耶路撒冷四围的城墙。"},
{volumn:        12,chapter:        25,versenumber:        11,verse:"那时护卫长尼布撒拉旦将城里所剩下的百姓，并已经投降巴比伦王的人，以及大众所剩下的人，都掳去了。"},
{volumn:        12,chapter:        25,versenumber:        12,verse:"但护卫长留下些民中最穷的，使他们修理葡萄园，耕种田地。"},
{volumn:        12,chapter:        25,versenumber:        13,verse:"耶和华殿的铜柱，并耶和华殿的盆座和铜海，迦勒底人都打碎了，将那铜运到巴比伦去了，"},
{volumn:        12,chapter:        25,versenumber:        14,verse:"又带去锅、铲子、蜡剪、调羹，并所用的一切铜器，"},
{volumn:        12,chapter:        25,versenumber:        15,verse:"火鼎、碗，无论金的银的，护卫长也都带去了。"},
{volumn:        12,chapter:        25,versenumber:        16,verse:"所罗门为耶和华殿所造的两根铜柱、一个铜海，和几个盆座，这一切的铜，多得无法可称。"},
{volumn:        12,chapter:        25,versenumber:        17,verse:"这一根柱子高十八肘，柱上有铜顶，高三肘；铜顶的周围有网子和石榴，都是铜的。那一根柱子，照此一样，也有网子。"},
{volumn:        12,chapter:        25,versenumber:        18,verse:"护卫长拿住大祭司西莱雅、副祭司西番亚，和三个把门的，"},
{volumn:        12,chapter:        25,versenumber:        19,verse:"又从城中拿住一个管理兵丁的官（或译：太监），并在城里所遇常见王面的五个人和检点国民军长的书记，以及城里遇见的国民六十个人。"},
{volumn:        12,chapter:        25,versenumber:        20,verse:"护卫长尼布撒拉旦将这些人带到在利比拉的巴比伦王那里。"},
{volumn:        12,chapter:        25,versenumber:        21,verse:"巴比伦王就把他们击杀在哈马地的利比拉。这样，犹大人被掳去离开本地。"},
{volumn:        12,chapter:        25,versenumber:        22,verse:"至于犹大国剩下的民，就是巴比伦王尼布甲尼撒所剩下的，巴比伦王立了沙番的孙子、亚希甘的儿子基大利作他们的省长。"},
{volumn:        12,chapter:        25,versenumber:        23,verse:"众军长和属他们的人听见巴比伦王立了基大利作省长，于是军长尼探雅的儿子以实玛利、加利亚的儿子约哈难、尼陀法人单户篾的儿子西莱雅、玛迦人的儿子雅撒尼亚，和属他们的人都到米斯巴见基大利。"},
{volumn:        12,chapter:        25,versenumber:        24,verse:"基大利向他们和属他们的人起誓说：“你们不必惧怕迦勒底臣仆，只管住在这地服侍巴比伦王，就可以得福。”"},
{volumn:        12,chapter:        25,versenumber:        25,verse:"七月间，宗室以利沙玛的孙子、尼探雅的儿子以实玛利带着十个人来，杀了基大利和同他在米斯巴的犹大人与迦勒底人。"},
{volumn:        12,chapter:        25,versenumber:        26,verse:"于是众民，无论大小，连众军长；因为惧怕迦勒底人，都起身往埃及去了。"},
{volumn:        12,chapter:        25,versenumber:        27,verse:"犹大王约雅斤被掳后三十七年，巴比伦王以未·米罗达元年十二月二十七日，使犹大王约雅斤抬头，提他出监；"},
{volumn:        12,chapter:        25,versenumber:        28,verse:"又对他说恩言，使他的位高过与他一同在巴比伦众王的位，"},
{volumn:        12,chapter:        25,versenumber:        29,verse:"给他脱了囚服。他终身常在巴比伦王面前吃饭。"},
{volumn:        12,chapter:        25,versenumber:        30,verse:"王赐他所需用的食物，日日赐他一份，终身都是这样。"},
{volumn:        13,chapter:         1,versenumber:         1,verse:"亚当生塞特；塞特生以挪士；"},
{volumn:        13,chapter:         1,versenumber:         2,verse:"以挪士生该南；该南生玛勒列；玛勒列生雅列；"},
{volumn:        13,chapter:         1,versenumber:         3,verse:"雅列生以诺；以诺生玛土撒拉；玛土撒拉生拉麦；"},
{volumn:        13,chapter:         1,versenumber:         4,verse:"拉麦生挪亚；挪亚生闪、含、雅弗。"},
{volumn:        13,chapter:         1,versenumber:         5,verse:"雅弗的儿子是歌篾、玛各、玛代、雅完、土巴、米设、提拉。"},
{volumn:        13,chapter:         1,versenumber:         6,verse:"歌篾的儿子是亚实基拿、低法（在创世记十章三节是利法）、陀迦玛。"},
{volumn:        13,chapter:         1,versenumber:         7,verse:"雅完的儿子是以利沙、他施、基提、多单（有作罗单的）。"},
{volumn:        13,chapter:         1,versenumber:         8,verse:"含的儿子是古实、麦西、弗、迦南。"},
{volumn:        13,chapter:         1,versenumber:         9,verse:"古实的儿子是西巴、哈腓拉、撒弗他、拉玛、撒弗提迦。拉玛的儿子是示巴、底但。"},
{volumn:        13,chapter:         1,versenumber:        10,verse:"古实生宁录；他为世上英雄之首。"},
{volumn:        13,chapter:         1,versenumber:        11,verse:"麦西生路低人、亚拿米人、利哈比人、拿弗土希人、"},
{volumn:        13,chapter:         1,versenumber:        12,verse:"帕斯鲁细人、迦斯路希人、迦斐托人；从迦斐托出来的有非利士人。"},
{volumn:        13,chapter:         1,versenumber:        13,verse:"迦南生长子西顿，又生赫"},
{volumn:        13,chapter:         1,versenumber:        14,verse:"和耶布斯人、亚摩利人、革迦撒人、"},
{volumn:        13,chapter:         1,versenumber:        15,verse:"希未人、亚基人、西尼人、"},
{volumn:        13,chapter:         1,versenumber:        16,verse:"亚瓦底人、洗玛利人，并哈马人。"},
{volumn:        13,chapter:         1,versenumber:        17,verse:"闪的儿子是以拦、亚述、亚法撒、路德、亚兰、乌斯、户勒、基帖、米设（在创世记十章二十三节是玛施）。"},
{volumn:        13,chapter:         1,versenumber:        18,verse:"亚法撒生沙拉；沙拉生希伯。"},
{volumn:        13,chapter:         1,versenumber:        19,verse:"希伯生了两个儿子：一个名叫法勒（就是分的意思），因为那时人就分地居住；法勒的兄弟名叫约坍。"},
{volumn:        13,chapter:         1,versenumber:        20,verse:"约坍生亚摩答、沙列、哈萨玛非、耶拉、"},
{volumn:        13,chapter:         1,versenumber:        21,verse:"哈多兰、乌萨、德拉、"},
{volumn:        13,chapter:         1,versenumber:        22,verse:"以巴录、亚比玛利、示巴、"},
{volumn:        13,chapter:         1,versenumber:        23,verse:"阿斐、哈腓拉、约巴。这都是约坍的儿子。"},
{volumn:        13,chapter:         1,versenumber:        24,verse:"闪生亚法撒；亚法撒生沙拉；"},
{volumn:        13,chapter:         1,versenumber:        25,verse:"沙拉生希伯；希伯生法勒；法勒生拉吴；"},
{volumn:        13,chapter:         1,versenumber:        26,verse:"拉吴生西鹿；西鹿生拿鹤；拿鹤生他拉；"},
{volumn:        13,chapter:         1,versenumber:        27,verse:"他拉生亚伯兰，亚伯兰就是亚伯拉罕。"},
{volumn:        13,chapter:         1,versenumber:        28,verse:"亚伯拉罕的儿子是以撒、以实玛利。"},
{volumn:        13,chapter:         1,versenumber:        29,verse:"以实玛利的儿子记在下面：以实玛利的长子是尼拜约，其次是基达、押德别、米比衫、"},
{volumn:        13,chapter:         1,versenumber:        30,verse:"米施玛、度玛、玛撒、哈达、提玛、"},
{volumn:        13,chapter:         1,versenumber:        31,verse:"伊突、拿非施、基底玛。这都是以实玛利的儿子。"},
{volumn:        13,chapter:         1,versenumber:        32,verse:"亚伯拉罕的妾基土拉所生的儿子，就是心兰、约珊、米但、米甸、伊施巴、书亚。约珊的儿子是示巴、底但。"},
{volumn:        13,chapter:         1,versenumber:        33,verse:"米甸的儿子是以法、以弗、哈诺、亚比大、以勒大。这都是基土拉的子孙。"},
{volumn:        13,chapter:         1,versenumber:        34,verse:"亚伯拉罕生以撒；以撒的儿子是以扫和以色列。"},
{volumn:        13,chapter:         1,versenumber:        35,verse:"以扫的儿子是以利法、流珥、耶乌施、雅兰、可拉。"},
{volumn:        13,chapter:         1,versenumber:        36,verse:"以利法的儿子是提幔、阿抹、洗玻、迦坦、基纳斯、亭纳、亚玛力。"},
{volumn:        13,chapter:         1,versenumber:        37,verse:"流珥的儿子是拿哈、谢拉、沙玛、米撒。"},
{volumn:        13,chapter:         1,versenumber:        38,verse:"西珥的儿子是罗坍、朔巴、祭便、亚拿、底顺、以察、底珊。"},
{volumn:        13,chapter:         1,versenumber:        39,verse:"罗坍的儿子是何利、荷幔；罗坍的妹子是亭纳。"},
{volumn:        13,chapter:         1,versenumber:        40,verse:"朔巴的儿子是亚勒文、玛拿辖、以巴录、示非、阿南。祭便的儿子是亚雅、亚拿。"},
{volumn:        13,chapter:         1,versenumber:        41,verse:"亚拿的儿子是底顺。底顺的儿子是哈默兰、伊是班、益兰、基兰。"},
{volumn:        13,chapter:         1,versenumber:        42,verse:"以察的儿子是辟罕、撒番、亚干。底珊的儿子是乌斯、亚兰。"},
{volumn:        13,chapter:         1,versenumber:        43,verse:"以色列人未有君王治理之先，在以东地作王的记在下面：有比珥的儿子比拉，他的京城名叫亭哈巴。"},
{volumn:        13,chapter:         1,versenumber:        44,verse:"比拉死了，波斯拉人谢拉的儿子约巴接续他作王。"},
{volumn:        13,chapter:         1,versenumber:        45,verse:"约巴死了，提幔地的人户珊接续他作王。"},
{volumn:        13,chapter:         1,versenumber:        46,verse:"户珊死了，比达的儿子哈达接续他作王。这哈达就是在摩押地杀败米甸人的，他的京城名叫亚未得。"},
{volumn:        13,chapter:         1,versenumber:        47,verse:"哈达死了，玛士利加人桑拉接续他作王。"},
{volumn:        13,chapter:         1,versenumber:        48,verse:"桑拉死了，大河边的利河伯人扫罗接续他作王。"},
{volumn:        13,chapter:         1,versenumber:        49,verse:"扫罗死了，亚革波的儿子巴勒·哈南接续他作王。"},
{volumn:        13,chapter:         1,versenumber:        50,verse:"巴勒·哈南死了，哈达接续他作王。他的京城名叫巴伊，他的妻子名叫米希她别，是米·萨合的孙女，玛特列的女儿。"},
{volumn:        13,chapter:         1,versenumber:        51,verse:"哈达死了，以东人的族长有亭纳族长、亚勒瓦族长、耶帖族长、"},
{volumn:        13,chapter:         1,versenumber:        52,verse:"亚何利巴玛族长、以拉族长、比嫩族长、"},
{volumn:        13,chapter:         1,versenumber:        53,verse:"基纳斯族长、提幔族长、米比萨族长、"},
{volumn:        13,chapter:         1,versenumber:        54,verse:"玛基叠族长、以兰族长。这都是以东人的族长。"},
{volumn:        13,chapter:         2,versenumber:         1,verse:"以色列的儿子是流便、西缅、利未、犹大、以萨迦、西布伦、"},
{volumn:        13,chapter:         2,versenumber:         2,verse:"但、约瑟、便雅悯、拿弗他利、迦得、亚设。"},
{volumn:        13,chapter:         2,versenumber:         3,verse:"犹大的儿子是珥、俄南、示拉，这三人是迦南人书亚女儿所生的。犹大的长子珥在耶和华眼中看为恶，耶和华就使他死了。"},
{volumn:        13,chapter:         2,versenumber:         4,verse:"犹大的儿妇他玛给犹大生法勒斯和谢拉。犹大共有五个儿子。"},
{volumn:        13,chapter:         2,versenumber:         5,verse:"法勒斯的儿子是希斯仑、哈母勒。"},
{volumn:        13,chapter:         2,versenumber:         6,verse:"谢拉的儿子是心利、以探、希幔、甲各、大拉（就是达大），共五人。"},
{volumn:        13,chapter:         2,versenumber:         7,verse:"迦米的儿子是亚干，这亚干在当灭的物上犯了罪，连累了以色列人。"},
{volumn:        13,chapter:         2,versenumber:         8,verse:"以探的儿子是亚撒利雅。"},
{volumn:        13,chapter:         2,versenumber:         9,verse:"希斯仑所生的儿子是耶拉篾、兰、基路拜。"},
{volumn:        13,chapter:         2,versenumber:        10,verse:"兰生亚米拿达；亚米拿达生拿顺。拿顺作犹大人的首领。"},
{volumn:        13,chapter:         2,versenumber:        11,verse:"拿顺生撒门；撒门生波阿斯；"},
{volumn:        13,chapter:         2,versenumber:        12,verse:"波阿斯生俄备得；俄备得生耶西；"},
{volumn:        13,chapter:         2,versenumber:        13,verse:"耶西生长子以利押，次子亚比拿达，三子示米亚（示米亚就是沙玛，见撒母耳上十六章九节），"},
{volumn:        13,chapter:         2,versenumber:        14,verse:"四子拿坦业，五子拉代，"},
{volumn:        13,chapter:         2,versenumber:        15,verse:"六子阿鲜，七子大卫。"},
{volumn:        13,chapter:         2,versenumber:        16,verse:"他们的姐妹是洗鲁雅和亚比该。洗鲁雅的儿子是亚比筛、约押、亚撒黑，共三人。"},
{volumn:        13,chapter:         2,versenumber:        17,verse:"亚比该生亚玛撒；亚玛撒的父亲是以实玛利人益帖。"},
{volumn:        13,chapter:         2,versenumber:        18,verse:"希斯仑的儿子迦勒娶阿苏巴和耶略为妻，阿苏巴的儿子是耶设、朔罢、押墩。"},
{volumn:        13,chapter:         2,versenumber:        19,verse:"阿苏巴死了，迦勒又娶以法他，生了户珥。"},
{volumn:        13,chapter:         2,versenumber:        20,verse:"户珥生乌利；乌利生比撒列。"},
{volumn:        13,chapter:         2,versenumber:        21,verse:"希斯仑正六十岁娶了基列父亲玛吉的女儿，与她同房；玛吉的女儿生了西割；"},
{volumn:        13,chapter:         2,versenumber:        22,verse:"西割生睚珥。睚珥在基列地有二十三个城邑。"},
{volumn:        13,chapter:         2,versenumber:        23,verse:"后来基述人和亚兰人夺了睚珥的城邑，并基纳和其乡村，共六十个。这都是基列父亲玛吉之子的。"},
{volumn:        13,chapter:         2,versenumber:        24,verse:"希斯仑在迦勒·以法他死后，他的妻亚比雅给他生了亚施户；亚施户是提哥亚的父亲。"},
{volumn:        13,chapter:         2,versenumber:        25,verse:"希斯仑的长子耶拉篾生长子兰，又生布拿、阿连、阿鲜、亚希雅。"},
{volumn:        13,chapter:         2,versenumber:        26,verse:"耶拉篾又娶一妻名叫亚她拉，是阿南的母亲。"},
{volumn:        13,chapter:         2,versenumber:        27,verse:"耶拉篾长子兰的儿子是玛斯、雅悯、以结。"},
{volumn:        13,chapter:         2,versenumber:        28,verse:"阿南的儿子是沙买、雅大。沙买的儿子是拿答、亚比述。"},
{volumn:        13,chapter:         2,versenumber:        29,verse:"亚比述的妻名叫亚比孩，亚比孩给他生了亚办和摩利。"},
{volumn:        13,chapter:         2,versenumber:        30,verse:"拿答的儿子是西列、亚遍；西列死了没有儿子。"},
{volumn:        13,chapter:         2,versenumber:        31,verse:"亚遍的儿子是以示；以示的儿子是示珊；示珊的儿子是亚来。"},
{volumn:        13,chapter:         2,versenumber:        32,verse:"沙买兄弟雅大的儿子是益帖、约拿单；益帖死了没有儿子。"},
{volumn:        13,chapter:         2,versenumber:        33,verse:"约拿单的儿子是比勒、撒萨。这都是耶拉篾的子孙。"},
{volumn:        13,chapter:         2,versenumber:        34,verse:"示珊没有儿子，只有女儿。示珊有一个仆人名叫耶哈，是埃及人。"},
{volumn:        13,chapter:         2,versenumber:        35,verse:"示珊将女儿给了仆人耶哈为妻，给他生了亚太。"},
{volumn:        13,chapter:         2,versenumber:        36,verse:"亚太生拿单；拿单生撒拔；"},
{volumn:        13,chapter:         2,versenumber:        37,verse:"撒拔生以弗拉；以弗拉生俄备得；"},
{volumn:        13,chapter:         2,versenumber:        38,verse:"俄备得生耶户；耶户生亚撒利雅；"},
{volumn:        13,chapter:         2,versenumber:        39,verse:"亚撒利雅生希利斯；希利斯生以利亚萨；"},
{volumn:        13,chapter:         2,versenumber:        40,verse:"以利亚萨生西斯买；西斯买生沙龙；"},
{volumn:        13,chapter:         2,versenumber:        41,verse:"沙龙生耶加米雅；耶加米雅生以利沙玛。"},
{volumn:        13,chapter:         2,versenumber:        42,verse:"耶拉篾兄弟迦勒的长子米沙，是西弗之祖玛利沙的儿子，是希伯仑之祖。"},
{volumn:        13,chapter:         2,versenumber:        43,verse:"希伯仑的儿子是可拉、他普亚、利肯、示玛。"},
{volumn:        13,chapter:         2,versenumber:        44,verse:"示玛生拉含，是约干之祖。利肯生沙买。"},
{volumn:        13,chapter:         2,versenumber:        45,verse:"沙买的儿子是玛云；玛云是伯·夙之祖。"},
{volumn:        13,chapter:         2,versenumber:        46,verse:"迦勒的妾以法生哈兰、摩撒、迦谢；哈兰生迦卸。（"},
{volumn:        13,chapter:         2,versenumber:        47,verse:"雅代的儿子是利健、约坦、基珊、毗力、以法、沙亚弗。）"},
{volumn:        13,chapter:         2,versenumber:        48,verse:"迦勒的妾玛迦生示别、特哈拿，"},
{volumn:        13,chapter:         2,versenumber:        49,verse:"又生麦玛拿之祖沙亚弗、抹比拿和基比亚之祖示法。迦勒的女儿是押撒。"},
{volumn:        13,chapter:         2,versenumber:        50,verse:"迦勒的子孙就是以法他的长子、户珥的儿子，记在下面：基列·耶琳之祖朔巴，"},
{volumn:        13,chapter:         2,versenumber:        51,verse:"伯利恒之祖萨玛，伯迦得之祖哈勒。"},
{volumn:        13,chapter:         2,versenumber:        52,verse:"基列·耶琳之祖朔巴的子孙是哈罗以和一半米努·哈人（米努·哈人就是玛拿哈人）。"},
{volumn:        13,chapter:         2,versenumber:        53,verse:"基列·耶琳的诸族是以帖人、布特人、舒玛人、密来人，又从这些族中生出琐拉人和以实陶人来。"},
{volumn:        13,chapter:         2,versenumber:        54,verse:"萨玛的子孙是伯利恒人、尼陀法人、亚他绿·伯·约押人、一半玛拿哈人、琐利人，"},
{volumn:        13,chapter:         2,versenumber:        55,verse:"和住雅比斯众文士家的特拉人、示米押人、苏甲人。这都是基尼人利甲家之祖哈末所生的。"},
{volumn:        13,chapter:         3,versenumber:         1,verse:"大卫在希伯仑所生的儿子记在下面：长子暗嫩是耶斯列人亚希暖生的。次子但以利是迦密人亚比该生的。"},
{volumn:        13,chapter:         3,versenumber:         2,verse:"三子押沙龙是基述王达买的女儿玛迦生的。四子亚多尼雅是哈及生的。"},
{volumn:        13,chapter:         3,versenumber:         3,verse:"五子示法提雅是亚比他生的。六子以特念是大卫的妻以格拉生的。"},
{volumn:        13,chapter:         3,versenumber:         4,verse:"这六人都是大卫在希伯仑生的。大卫在希伯仑作王七年零六个月，在耶路撒冷作王三十三年。"},
{volumn:        13,chapter:         3,versenumber:         5,verse:"大卫在耶路撒冷所生的儿子是示米亚、朔罢、拿单、所罗门。这四人是亚米利的女儿拔·书亚生的。"},
{volumn:        13,chapter:         3,versenumber:         6,verse:"还有益辖、以利沙玛、以利法列、"},
{volumn:        13,chapter:         3,versenumber:         7,verse:"挪迦、尼斐、雅非亚、"},
{volumn:        13,chapter:         3,versenumber:         8,verse:"以利沙玛、以利雅大、以利法列，共九人。"},
{volumn:        13,chapter:         3,versenumber:         9,verse:"这都是大卫的儿子，还有他们的妹子他玛，妃嫔的儿子不在其内。"},
{volumn:        13,chapter:         3,versenumber:        10,verse:"所罗门的儿子是罗波安；罗波安的儿子是亚比雅；亚比雅的儿子是亚撒；亚撒的儿子是约沙法；"},
{volumn:        13,chapter:         3,versenumber:        11,verse:"约沙法的儿子是约兰；约兰的儿子是亚哈谢；亚哈谢的儿子是约阿施；"},
{volumn:        13,chapter:         3,versenumber:        12,verse:"约阿施的儿子是亚玛谢；亚玛谢的儿子是亚撒利雅；亚撒利雅的儿子是约坦；"},
{volumn:        13,chapter:         3,versenumber:        13,verse:"约坦的儿子是亚哈斯；亚哈斯的儿子是希西家；希西家的儿子是玛拿西；"},
{volumn:        13,chapter:         3,versenumber:        14,verse:"玛拿西的儿子是亚们；亚们的儿子是约西亚；"},
{volumn:        13,chapter:         3,versenumber:        15,verse:"约西亚的长子是约哈难，次子是约雅敬，三子是西底家，四子是沙龙。"},
{volumn:        13,chapter:         3,versenumber:        16,verse:"约雅敬的儿子是耶哥尼雅和西底家。"},
{volumn:        13,chapter:         3,versenumber:        17,verse:"耶哥尼雅被掳。他的儿子是撒拉铁、"},
{volumn:        13,chapter:         3,versenumber:        18,verse:"玛基兰、毗大雅、示拿萨、耶加米、何沙玛、尼大比雅。"},
{volumn:        13,chapter:         3,versenumber:        19,verse:"毗大雅的儿子是所罗巴伯、示每。所罗巴伯的儿子是米书兰、哈拿尼雅，他们的妹子名叫示罗密。"},
{volumn:        13,chapter:         3,versenumber:        20,verse:"米书兰的儿子是哈舒巴、阿黑、比利家、哈撒底、于沙·希悉，共五人。"},
{volumn:        13,chapter:         3,versenumber:        21,verse:"哈拿尼雅的儿子是毗拉提、耶筛亚。还有利法雅的众子，亚珥难的众子，俄巴底亚的众子，示迦尼的众子。"},
{volumn:        13,chapter:         3,versenumber:        22,verse:"示迦尼的儿子是示玛雅；示玛雅的儿子是哈突、以甲、巴利亚、尼利雅、沙法，共六人。"},
{volumn:        13,chapter:         3,versenumber:        23,verse:"尼利雅的儿子是以利约乃、希西家、亚斯利干，共三人。"},
{volumn:        13,chapter:         3,versenumber:        24,verse:"以利约乃的儿子是何大雅、以利亚实、毗莱雅、阿谷、约哈难、第莱雅、阿拿尼，共七人。"},
{volumn:        13,chapter:         4,versenumber:         1,verse:"犹大的儿子是法勒斯、希斯仑、迦米、户珥、朔巴。"},
{volumn:        13,chapter:         4,versenumber:         2,verse:"朔巴的儿子利亚雅生雅哈；雅哈生亚户买和拉哈。这是琐拉人的诸族。"},
{volumn:        13,chapter:         4,versenumber:         3,verse:"以坦之祖的儿子是耶斯列、伊施玛、伊得巴；他们的妹子名叫哈悉勒玻尼。"},
{volumn:        13,chapter:         4,versenumber:         4,verse:"基多之祖是毗努伊勒。户沙之祖是以谢珥。这都是伯利恒之祖以法他的长子户珥所生的。"},
{volumn:        13,chapter:         4,versenumber:         5,verse:"提哥亚之祖亚施户有两个妻子：一名希拉，一名拿拉。"},
{volumn:        13,chapter:         4,versenumber:         6,verse:"拿拉给亚施户生亚户撒、希弗、提米尼、哈辖斯他利。这都是拿拉的儿子。"},
{volumn:        13,chapter:         4,versenumber:         7,verse:"希拉的儿子是洗列、琐辖、伊提南。"},
{volumn:        13,chapter:         4,versenumber:         8,verse:"哥斯生亚诺、琐比巴，并哈仑儿子亚哈黑的诸族。"},
{volumn:        13,chapter:         4,versenumber:         9,verse:"雅比斯比他众弟兄更尊贵，他母亲给他起名叫雅比斯，意思说：我生他甚是痛苦。"},
{volumn:        13,chapter:         4,versenumber:        10,verse:"雅比斯求告以色列的　神说：“甚愿你赐福与我，扩张我的境界，常与我同在，保佑我不遭患难，不受艰苦。”　神就应允他所求的。"},
{volumn:        13,chapter:         4,versenumber:        11,verse:"书哈的弟兄基绿生米黑，米黑是伊施屯之祖。"},
{volumn:        13,chapter:         4,versenumber:        12,verse:"伊施屯生伯拉巴、巴西亚，并珥拿辖之祖提欣拿，这都是利迦人。"},
{volumn:        13,chapter:         4,versenumber:        13,verse:"基纳斯的儿子是俄陀聂、西莱雅。俄陀聂的儿子是哈塔。"},
{volumn:        13,chapter:         4,versenumber:        14,verse:"悯挪太生俄弗拉；西莱雅生革·夏纳欣人之祖约押。他们都是匠人。"},
{volumn:        13,chapter:         4,versenumber:        15,verse:"耶孚尼的儿子是迦勒；迦勒的儿子是以路、以拉、拿安。以拉的儿子是基纳斯。"},
{volumn:        13,chapter:         4,versenumber:        16,verse:"耶哈利勒的儿子是西弗、西法、提利、亚撒列。"},
{volumn:        13,chapter:         4,versenumber:        17,verse:"以斯拉的儿子是益帖、米列、以弗、雅伦。米列娶法老女儿比提雅为妻，生米利暗、沙买，和以实提摩之祖益巴。米列又娶犹大女子为妻，生基多之祖雅列，梭哥之祖希伯，和撒挪亚之祖耶古铁。"},
{volumn:        13,chapter:         4,versenumber:        18,verse:""},
{volumn:        13,chapter:         4,versenumber:        19,verse:"荷第雅的妻是拿含的妹子，她所生的儿子是迦米人基伊拉和玛迦人以实提摩之祖。"},
{volumn:        13,chapter:         4,versenumber:        20,verse:"示门的儿子是暗嫩、林拿、便·哈南、提伦。以示的儿子是梭黑与便·梭黑。"},
{volumn:        13,chapter:         4,versenumber:        21,verse:"犹大的儿子是示拉；示拉的儿子是利迦之祖珥，玛利沙之祖拉大，和属亚实比族织细麻布的各家。"},
{volumn:        13,chapter:         4,versenumber:        22,verse:"还有约敬、哥西巴人、约阿施、萨拉，就是在摩押地掌权的，又有雅叔比利恒。这都是古时所记载的。"},
{volumn:        13,chapter:         4,versenumber:        23,verse:"这些人都是窑匠，是尼他应和基低拉的居民；与王同处，为王做工。"},
{volumn:        13,chapter:         4,versenumber:        24,verse:"西缅的儿子是尼母利、雅悯、雅立、谢拉、扫罗。"},
{volumn:        13,chapter:         4,versenumber:        25,verse:"扫罗的儿子是沙龙；沙龙的儿子是米比衫；米比衫的儿子是米施玛；"},
{volumn:        13,chapter:         4,versenumber:        26,verse:"米施玛的儿子是哈母利；哈母利的儿子是撒刻；撒刻的儿子是示每。"},
{volumn:        13,chapter:         4,versenumber:        27,verse:"示每有十六个儿子，六个女儿，他弟兄的儿女不多，他们各家不如犹大族的人丁增多。"},
{volumn:        13,chapter:         4,versenumber:        28,verse:"西缅人住在别是巴、摩拉大、哈萨·书亚、"},
{volumn:        13,chapter:         4,versenumber:        29,verse:"辟拉、以森、陀腊、"},
{volumn:        13,chapter:         4,versenumber:        30,verse:"彼土利、何珥玛、洗革拉、"},
{volumn:        13,chapter:         4,versenumber:        31,verse:"伯·玛嘉博、哈萨·苏撒、伯·比利、沙拉音，这些城邑直到大卫作王的时候都是属西缅人的。"},
{volumn:        13,chapter:         4,versenumber:        32,verse:"他们的五个城邑是以坦、亚因、临门、陀健、亚珊；"},
{volumn:        13,chapter:         4,versenumber:        33,verse:"还有属城的乡村，直到巴力。这是他们的住处，他们都有家谱。"},
{volumn:        13,chapter:         4,versenumber:        34,verse:"还有米所巴、雅米勒、亚玛谢的儿子约沙、"},
{volumn:        13,chapter:         4,versenumber:        35,verse:"约珥、约示比的儿子耶户；约示比是西莱雅的儿子；西莱雅是亚薛的儿子。"},
{volumn:        13,chapter:         4,versenumber:        36,verse:"还有以利约乃、雅哥巴、约朔海、亚帅雅、亚底业、耶西篾、比拿雅、"},
{volumn:        13,chapter:         4,versenumber:        37,verse:"示非的儿子细撒。示非是亚龙的儿子；亚龙是耶大雅的儿子；耶大雅是申利的儿子；申利是示玛雅的儿子。"},
{volumn:        13,chapter:         4,versenumber:        38,verse:"以上所记的人名都是作族长的，他们宗族的人数增多。"},
{volumn:        13,chapter:         4,versenumber:        39,verse:"他们往平原东边基多口去，寻找牧放羊群的草场，"},
{volumn:        13,chapter:         4,versenumber:        40,verse:"寻得肥美的草场地，又宽阔又平静。从前住那里的是含族的人。"},
{volumn:        13,chapter:         4,versenumber:        41,verse:"以上录名的人，在犹大王希西家年间，来攻击含族人的帐棚和那里所有的米乌尼人，将他们灭尽，就住在他们的地方，直到今日，因为那里有草场可以牧放羊群。"},
{volumn:        13,chapter:         4,versenumber:        42,verse:"这西缅人中，有五百人上西珥山，率领他们的是以示的儿子毗拉提、尼利雅、利法雅，和乌薛，"},
{volumn:        13,chapter:         4,versenumber:        43,verse:"杀了逃脱剩下的亚玛力人，就住在那里直到今日。"},
{volumn:        13,chapter:         5,versenumber:         1,verse:"以色列的长子原是流便；因他污秽了父亲的床，他长子的名分就归了约瑟。只是按家谱他不算长子。"},
{volumn:        13,chapter:         5,versenumber:         2,verse:"犹大胜过一切弟兄，君王也是从他而出；长子的名分却归约瑟。"},
{volumn:        13,chapter:         5,versenumber:         3,verse:"以色列长子流便的儿子是哈诺、法路、希斯伦、迦米。"},
{volumn:        13,chapter:         5,versenumber:         4,verse:"约珥的儿子是示玛雅；示玛雅的儿子是歌革；歌革的儿子是示每；"},
{volumn:        13,chapter:         5,versenumber:         5,verse:"示每的儿子是米迦；米迦的儿子是利亚雅；利亚雅的儿子是巴力；"},
{volumn:        13,chapter:         5,versenumber:         6,verse:"巴力的儿子是备拉。这备拉作流便支派的首领，被亚述王提革拉·毗列色掳去。"},
{volumn:        13,chapter:         5,versenumber:         7,verse:"他的弟兄照着宗族，按着家谱作族长的是耶利、撒迦利雅、比拉。"},
{volumn:        13,chapter:         5,versenumber:         8,verse:"比拉是亚撒的儿子；亚撒是示玛的儿子；示玛是约珥的儿子；约珥所住的地方是从亚罗珥直到尼波和巴力·免，"},
{volumn:        13,chapter:         5,versenumber:         9,verse:"又向东延到幼发拉底河这边的旷野，因为他们在基列地牲畜增多。"},
{volumn:        13,chapter:         5,versenumber:        10,verse:"扫罗年间，他们与夏甲人争战，夏甲人倒在他们手下，他们就在基列东边的全地，住在夏甲人的帐棚里。"},
{volumn:        13,chapter:         5,versenumber:        11,verse:"迦得的子孙在流便对面，住在巴珊地，延到撒迦。"},
{volumn:        13,chapter:         5,versenumber:        12,verse:"他们中间有作族长的约珥，有作副族长的沙番，还有雅乃和住在巴珊的沙法。"},
{volumn:        13,chapter:         5,versenumber:        13,verse:"他们族弟兄是米迦勒、米书兰、示巴、约赖、雅干、细亚、希伯，共七人。"},
{volumn:        13,chapter:         5,versenumber:        14,verse:"这都是亚比孩的儿子。亚比孩是户利的儿子；户利是耶罗亚的儿子；耶罗亚是基列的儿子；基列是米迦勒的儿子；米迦勒是耶示筛的儿子；耶示筛是耶哈多的儿子；耶哈多是布斯的儿子。"},
{volumn:        13,chapter:         5,versenumber:        15,verse:"还有古尼的孙子、押比叠的儿子亚希。这都是作族长的。"},
{volumn:        13,chapter:         5,versenumber:        16,verse:"他们住在基列与巴珊和巴珊的乡村，并沙仑的郊野，直到四围的交界。"},
{volumn:        13,chapter:         5,versenumber:        17,verse:"这些人在犹大王约坦并在以色列王耶罗波安年间，都载入家谱。"},
{volumn:        13,chapter:         5,versenumber:        18,verse:"流便人、迦得人，和玛拿西半支派的人，能拿盾牌和刀剑、拉弓射箭、出征善战的勇士共有四万四千七百六十名。"},
{volumn:        13,chapter:         5,versenumber:        19,verse:"他们与夏甲人、伊突人、拿非施人、挪答人争战。"},
{volumn:        13,chapter:         5,versenumber:        20,verse:"他们得了　神的帮助，夏甲人和跟随夏甲的人都交在他们手中；因为他们在阵上呼求　神，倚赖　神，　神就应允他们。"},
{volumn:        13,chapter:         5,versenumber:        21,verse:"他们掳掠了夏甲人的牲畜，有骆驼五万，羊二十五万，驴二千；又有人十万。"},
{volumn:        13,chapter:         5,versenumber:        22,verse:"敌人被杀仆倒的甚多，因为这争战是出乎　神。他们就住在敌人的地上，直到被掳的时候。"},
{volumn:        13,chapter:         5,versenumber:        23,verse:"玛拿西半支派的人住在那地。从巴珊延到巴力·黑们、示尼珥与黑门山。"},
{volumn:        13,chapter:         5,versenumber:        24,verse:"他们的族长是以弗、以示、以列、亚斯列、耶利米、何达威雅、雅叠，都是大能的勇士，是有名的人，也是作族长的。"},
{volumn:        13,chapter:         5,versenumber:        25,verse:"他们得罪了他们列祖的　神，随从那地之民的神行邪淫；这民就是　神在他们面前所除灭的。"},
{volumn:        13,chapter:         5,versenumber:        26,verse:"故此，以色列的　神激动亚述王普勒和亚述王提革拉·毗列色的心，他们就把流便人、迦得人、玛拿西半支派的人掳到哈腊、哈博、哈拉与歌散河边，直到今日还在那里。"},
{volumn:        13,chapter:         6,versenumber:         1,verse:"利未的儿子是革顺、哥辖、米拉利。"},
{volumn:        13,chapter:         6,versenumber:         2,verse:"哥辖的儿子是暗兰、以斯哈、希伯伦、乌薛。"},
{volumn:        13,chapter:         6,versenumber:         3,verse:"暗兰的儿子是亚伦、摩西，还有女儿米利暗。亚伦的儿子是拿答、亚比户、以利亚撒、以他玛。"},
{volumn:        13,chapter:         6,versenumber:         4,verse:"以利亚撒生非尼哈；非尼哈生亚比书；"},
{volumn:        13,chapter:         6,versenumber:         5,verse:"亚比书生布基；布基生乌西；"},
{volumn:        13,chapter:         6,versenumber:         6,verse:"乌西生西拉希雅；西拉希雅生米拉约；"},
{volumn:        13,chapter:         6,versenumber:         7,verse:"米拉约生亚玛利雅；亚玛利雅生亚希突；"},
{volumn:        13,chapter:         6,versenumber:         8,verse:"亚希突生撒督；撒督生亚希玛斯；"},
{volumn:        13,chapter:         6,versenumber:         9,verse:"亚希玛斯生亚撒利雅；亚撒利雅生约哈难；"},
{volumn:        13,chapter:         6,versenumber:        10,verse:"约哈难生亚撒利雅（这亚撒利雅在所罗门于耶路撒冷所建造的殿中，供祭司的职分）；"},
{volumn:        13,chapter:         6,versenumber:        11,verse:"亚撒利雅生亚玛利雅；亚玛利雅生亚希突；"},
{volumn:        13,chapter:         6,versenumber:        12,verse:"亚希突生撒督；撒督生沙龙；"},
{volumn:        13,chapter:         6,versenumber:        13,verse:"沙龙生希勒家；希勒家生亚撒利雅；"},
{volumn:        13,chapter:         6,versenumber:        14,verse:"亚撒利雅生西莱雅；西莱雅生约萨答。"},
{volumn:        13,chapter:         6,versenumber:        15,verse:"当耶和华藉尼布甲尼撒的手掳掠犹大和耶路撒冷人的时候，这约萨答也被掳去。"},
{volumn:        13,chapter:         6,versenumber:        16,verse:"利未的儿子是革顺、哥辖、米拉利。"},
{volumn:        13,chapter:         6,versenumber:        17,verse:"革顺的儿子名叫立尼、示每。"},
{volumn:        13,chapter:         6,versenumber:        18,verse:"哥辖的儿子是暗兰、以斯哈、希伯伦、乌薛。"},
{volumn:        13,chapter:         6,versenumber:        19,verse:"米拉利的儿子是抹利、母示。这是按着利未人宗族分的各家。"},
{volumn:        13,chapter:         6,versenumber:        20,verse:"革顺的儿子是立尼；立尼的儿子是雅哈；雅哈的儿子是薪玛；"},
{volumn:        13,chapter:         6,versenumber:        21,verse:"薪玛的儿子是约亚；约亚的儿子是易多；易多的儿子是谢拉；谢拉的儿子是耶特赖。"},
{volumn:        13,chapter:         6,versenumber:        22,verse:"哥辖的儿子是亚米拿达；亚米拿达的儿子是可拉；可拉的儿子是亚惜；"},
{volumn:        13,chapter:         6,versenumber:        23,verse:"亚惜的儿子是以利加拿；以利加拿的儿子是以比雅撒；以比雅撒的儿子是亚惜；"},
{volumn:        13,chapter:         6,versenumber:        24,verse:"亚惜的儿子是他哈；他哈的儿子是乌列；乌列的儿子是乌西雅；乌西雅的儿子是少罗。"},
{volumn:        13,chapter:         6,versenumber:        25,verse:"以利加拿的儿子是亚玛赛和亚希摩。"},
{volumn:        13,chapter:         6,versenumber:        26,verse:"亚希摩的儿子是以利加拿；以利加拿的儿子是琐菲；琐菲的儿子是拿哈；"},
{volumn:        13,chapter:         6,versenumber:        27,verse:"拿哈的儿子是以利押；以利押的儿子是耶罗罕；耶罗罕的儿子是以利加拿；以利加拿的儿子是撒母耳。"},
{volumn:        13,chapter:         6,versenumber:        28,verse:"撒母耳的长子是约珥，次子是亚比亚。"},
{volumn:        13,chapter:         6,versenumber:        29,verse:"米拉利的儿子是抹利；抹利的儿子是立尼；立尼的儿子是示每；示每的儿子是乌撒；"},
{volumn:        13,chapter:         6,versenumber:        30,verse:"乌撒的儿子是示米亚；示米亚的儿子是哈基雅；哈基雅的儿子是亚帅雅。"},
{volumn:        13,chapter:         6,versenumber:        31,verse:"约柜安设之后，大卫派人在耶和华殿中管理歌唱的事。"},
{volumn:        13,chapter:         6,versenumber:        32,verse:"他们就在会幕前当歌唱的差，及至所罗门在耶路撒冷建造了耶和华的殿，他们便按着班次供职。"},
{volumn:        13,chapter:         6,versenumber:        33,verse:"供职的人和他们的子孙记在下面： 34哥辖的子孙中有歌唱的希幔。希幔是约珥的儿子；约珥是撒母耳的儿子；"},
{volumn:        13,chapter:         6,versenumber:        34,verse:"哥辖的子孙中有歌唱的希幔。希幔是约珥的儿子；约珥是撒母耳的儿子；撒母耳是以利加拿的儿子；以利加拿是耶罗罕的儿子；耶罗罕是以列的儿子；以列是陀亚的儿子；"},
{volumn:        13,chapter:         6,versenumber:        35,verse:"陀亚是苏弗的儿子；苏弗是以利加拿的儿子；以利加拿是玛哈的儿子；玛哈是亚玛赛的儿子；"},
{volumn:        13,chapter:         6,versenumber:        36,verse:"亚玛赛是以利加拿的儿子；以利加拿是约珥的儿子；约珥是亚撒利雅的儿子；亚撒利雅是西番雅的儿子；"},
{volumn:        13,chapter:         6,versenumber:        37,verse:"西番雅是他哈的儿子；他哈是亚惜的儿子；亚惜是以比雅撒的儿子；以比雅撒是可拉的儿子；"},
{volumn:        13,chapter:         6,versenumber:        38,verse:"可拉是以斯哈的儿子；以斯哈是哥辖的儿子；哥辖是利未的儿子；利未是以色列的儿子。"},
{volumn:        13,chapter:         6,versenumber:        39,verse:"希幔的族兄亚萨是比利家的儿子，亚萨在希幔右边供职。比利家是示米亚的儿子；"},
{volumn:        13,chapter:         6,versenumber:        40,verse:"示米亚是米迦勒的儿子；米迦勒是巴西雅的儿子；巴西雅是玛基雅的儿子；"},
{volumn:        13,chapter:         6,versenumber:        41,verse:"玛基雅是伊特尼的儿子；伊特尼是谢拉的儿子；谢拉是亚大雅的儿子；"},
{volumn:        13,chapter:         6,versenumber:        42,verse:"亚大雅是以探的儿子；以探是薪玛的儿子；薪玛是示每的儿子；"},
{volumn:        13,chapter:         6,versenumber:        43,verse:"示每是雅哈的儿子；雅哈是革顺的儿子。革顺是利未的儿子。"},
{volumn:        13,chapter:         6,versenumber:        44,verse:"他们的族弟兄米拉利的子孙，在他们左边供职的有以探。以探是基示的儿子；基示是亚伯底的儿子；亚伯底是玛鹿的儿子；"},
{volumn:        13,chapter:         6,versenumber:        45,verse:"玛鹿是哈沙比雅的儿子；哈沙比雅是亚玛谢的儿子；亚玛谢是希勒家的儿子；"},
{volumn:        13,chapter:         6,versenumber:        46,verse:"希勒家是暗西的儿子；暗西是巴尼的儿子；巴尼是沙麦的儿子；"},
{volumn:        13,chapter:         6,versenumber:        47,verse:"沙麦是末力的儿子；末力是母示的儿子；母示是米拉利的儿子；米拉利是利未的儿子。"},
{volumn:        13,chapter:         6,versenumber:        48,verse:"他们的族弟兄利未人也被派办　神殿中的一切事。"},
{volumn:        13,chapter:         6,versenumber:        49,verse:"亚伦和他的子孙在燔祭坛和香坛上献祭烧香，又在至圣所办理一切的事，为以色列人赎罪，是照　神仆人摩西所吩咐的。"},
{volumn:        13,chapter:         6,versenumber:        50,verse:"亚伦的儿子是以利亚撒；以利亚撒的儿子是非尼哈；非尼哈的儿子是亚比书；"},
{volumn:        13,chapter:         6,versenumber:        51,verse:"亚比书的儿子是布基；布基的儿子是乌西；乌西的儿子是西拉希雅；"},
{volumn:        13,chapter:         6,versenumber:        52,verse:"西拉希雅的儿子是米拉约；米拉约的儿子是亚玛利雅；亚玛利雅的儿子是亚希突；"},
{volumn:        13,chapter:         6,versenumber:        53,verse:"亚希突的儿子是撒督；撒督的儿子是亚希玛斯。"},
{volumn:        13,chapter:         6,versenumber:        54,verse:"他们的住处按着境内的营寨，记在下面：哥辖族亚伦的子孙先拈阄得地，"},
{volumn:        13,chapter:         6,versenumber:        55,verse:"在犹大地中得了希伯仑和四围的郊野；"},
{volumn:        13,chapter:         6,versenumber:        56,verse:"只是属城的田地和村庄都为耶孚尼的儿子迦勒所得。"},
{volumn:        13,chapter:         6,versenumber:        57,verse:"亚伦的子孙得了逃城希伯仑，又得了立拿与其郊野，雅提珥、以实提莫与其郊野；"},
{volumn:        13,chapter:         6,versenumber:        58,verse:"希仑与其郊野，底璧与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        59,verse:"亚珊与其郊野，伯·示麦与其郊野。"},
{volumn:        13,chapter:         6,versenumber:        60,verse:"在便雅悯支派的地中，得了迦巴与其郊野，阿勒篾与其郊野，亚拿突与其郊野。他们诸家所得的城共十三座。"},
{volumn:        13,chapter:         6,versenumber:        61,verse:"哥辖族其余的人又拈阄，在玛拿西半支派的地中得了十座城。"},
{volumn:        13,chapter:         6,versenumber:        62,verse:"革顺族按着宗族，在以萨迦支派的地中，亚设支派的地中，拿弗他利支派的地中，巴珊内玛拿西支派的地中，得了十三座城。"},
{volumn:        13,chapter:         6,versenumber:        63,verse:"米拉利族按着宗族拈阄，在流便支派的地中，迦得支派的地中，西布伦支派的地中，得了十二座城。"},
{volumn:        13,chapter:         6,versenumber:        64,verse:"以色列人将这些城与其郊野给了利未人。"},
{volumn:        13,chapter:         6,versenumber:        65,verse:"这以上录名的城，在犹大、西缅、便雅悯三支派的地中，以色列人拈阄给了他们。"},
{volumn:        13,chapter:         6,versenumber:        66,verse:"哥辖族中有几家在以法莲支派的地中也得了城邑，"},
{volumn:        13,chapter:         6,versenumber:        67,verse:"在以法莲山地得了逃城示剑与其郊野，又得了基色与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        68,verse:"约缅与其郊野，伯·和仑与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        69,verse:"亚雅仑与其郊野，迦特·临门与其郊野。"},
{volumn:        13,chapter:         6,versenumber:        70,verse:"哥辖族其余的人在玛拿西半支派的地中得了亚乃与其郊野，比连与其郊野。"},
{volumn:        13,chapter:         6,versenumber:        71,verse:"革顺族在玛拿西半支派的地中得了巴珊的哥兰与其郊野，亚斯他录与其郊野；"},
{volumn:        13,chapter:         6,versenumber:        72,verse:"又在以萨迦支派的地中得了基低斯与其郊野，大比拉与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        73,verse:"拉末与其郊野，亚年与其郊野；"},
{volumn:        13,chapter:         6,versenumber:        74,verse:"在亚设支派的地中得了玛沙与其郊野，押顿与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        75,verse:"户割与其郊野，利合与其郊野；"},
{volumn:        13,chapter:         6,versenumber:        76,verse:"在拿弗他利支派的地中得了加利利的基低斯与其郊野，哈们与其郊野，基列亭与其郊野。"},
{volumn:        13,chapter:         6,versenumber:        77,verse:"还有米拉利族的人在西布伦支派的地中得了临摩挪与其郊野，他泊与其郊野；"},
{volumn:        13,chapter:         6,versenumber:        78,verse:"又在耶利哥的约旦河东，在流便支派的地中得了旷野的比悉与其郊野，雅哈撒与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        79,verse:"基底莫与其郊野，米法押与其郊野；"},
{volumn:        13,chapter:         6,versenumber:        80,verse:"又在迦得支派的地中得了基列的拉末与其郊野，玛哈念与其郊野，"},
{volumn:        13,chapter:         6,versenumber:        81,verse:"希实本与其郊野，雅谢与其郊野。"},
{volumn:        13,chapter:         7,versenumber:         1,verse:"以萨迦的儿子是陀拉、普瓦、雅述（在创世记第四十六章十三节是约伯）、伸仑，共四人。"},
{volumn:        13,chapter:         7,versenumber:         2,verse:"陀拉的儿子是乌西、利法雅、耶勒、雅买、易伯散、示母利，都是陀拉的族长，是大能的勇士。到大卫年间，他们的人数共有二万二千六百名。"},
{volumn:        13,chapter:         7,versenumber:         3,verse:"乌西的儿子是伊斯拉希；伊斯拉希的儿子是米迦勒、俄巴底亚、约珥、伊示雅，共五人，都是族长。"},
{volumn:        13,chapter:         7,versenumber:         4,verse:"他们所率领的，按着宗族出战的军队，共有三万六千人，因为他们的妻和子众多。"},
{volumn:        13,chapter:         7,versenumber:         5,verse:"他们的族弟兄在以萨迦各族中都是大能的勇士，按着家谱计算共有八万七千人。"},
{volumn:        13,chapter:         7,versenumber:         6,verse:"便雅悯的儿子是比拉、比结、耶叠，共三人。"},
{volumn:        13,chapter:         7,versenumber:         7,verse:"比拉的儿子是以斯本、乌西、乌薛、耶利摩、以利，共五人，都是族长，是大能的勇士。按着家谱计算，他们的子孙共有二万二千零三十四人。"},
{volumn:        13,chapter:         7,versenumber:         8,verse:"比结的儿子是细米拉、约阿施、以利以谢、以利约乃、暗利、耶利摩、亚比雅、亚拿突、亚拉篾。这都是比结的儿子。"},
{volumn:        13,chapter:         7,versenumber:         9,verse:"他们都是族长，是大能的勇士。按着家谱计算，他们的子孙共有二万零二百人。"},
{volumn:        13,chapter:         7,versenumber:        10,verse:"耶叠的儿子是比勒罕；比勒罕的儿子是耶乌施、便雅悯、以忽、基拿拿、细坦、他施、亚希沙哈。"},
{volumn:        13,chapter:         7,versenumber:        11,verse:"这都是耶叠的儿子，都是族长，是大能的勇士；他们的子孙能上阵打仗的，共有一万七千二百人。"},
{volumn:        13,chapter:         7,versenumber:        12,verse:"还有以珥的儿子书品、户品，并亚黑的儿子户伸。"},
{volumn:        13,chapter:         7,versenumber:        13,verse:"拿弗他利的儿子是雅薛、沽尼、耶色、沙龙。这都是辟拉的子孙。"},
{volumn:        13,chapter:         7,versenumber:        14,verse:"玛拿西的儿子亚斯列是他妾亚兰人所生的，又生了基列之父玛吉。"},
{volumn:        13,chapter:         7,versenumber:        15,verse:"玛吉娶的妻是户品、书品的妹子，名叫玛迦。玛拿西的次子名叫西罗非哈；西罗非哈但有几个女儿。"},
{volumn:        13,chapter:         7,versenumber:        16,verse:"玛吉的妻玛迦生了一个儿子，起名叫毗利施。毗利施的兄弟名叫示利施；示利施的儿子是乌兰和利金。"},
{volumn:        13,chapter:         7,versenumber:        17,verse:"乌兰的儿子是比但。这都是基列的子孙。基列是玛吉的儿子，玛吉是玛拿西的儿子。"},
{volumn:        13,chapter:         7,versenumber:        18,verse:"基列的妹子哈摩利吉生了伊施荷、亚比以谢、玛拉。（"},
{volumn:        13,chapter:         7,versenumber:        19,verse:"示米大的儿子是亚现、示剑、利克希、阿尼安。）"},
{volumn:        13,chapter:         7,versenumber:        20,verse:"以法莲的儿子是书提拉；书提拉的儿子是比列；比列的儿子是他哈；他哈的儿子是以拉大；以拉大的儿子是他哈；"},
{volumn:        13,chapter:         7,versenumber:        21,verse:"他哈的儿子是撒拔；撒拔的儿子是书提拉。以法莲又生以谢、以列；这二人因为下去夺取迦特人的牲畜，被本地的迦特人杀了。"},
{volumn:        13,chapter:         7,versenumber:        22,verse:"他们的父亲以法莲为他们悲哀了多日，他的弟兄都来安慰他。"},
{volumn:        13,chapter:         7,versenumber:        23,verse:"以法莲与妻同房，他妻就怀孕生了一子，以法莲因为家里遭祸，就给这儿子起名叫比利亚。"},
{volumn:        13,chapter:         7,versenumber:        24,verse:"他的女儿名叫舍伊拉，就是建筑上伯·和仑、下伯·和仑与乌羡·舍伊拉的。"},
{volumn:        13,chapter:         7,versenumber:        25,verse:"比利阿的儿子是利法和利悉。利悉的儿子是他拉；他拉的儿子是他罕；"},
{volumn:        13,chapter:         7,versenumber:        26,verse:"他罕的儿子是拉但；拉但的儿子是亚米忽；亚米忽的儿子是以利沙玛；"},
{volumn:        13,chapter:         7,versenumber:        27,verse:"以利沙玛的儿子是嫩；嫩的儿子是约书亚。"},
{volumn:        13,chapter:         7,versenumber:        28,verse:"以法莲人的地业和住处是伯特利与其村庄；东边拿兰，西边基色与其村庄；示剑与其村庄，直到迦萨与其村庄；"},
{volumn:        13,chapter:         7,versenumber:        29,verse:"还有靠近玛拿西人的境界，伯·善与其村庄；他纳与其村庄；米吉多与其村庄；多珥与其村庄。以色列儿子约瑟的子孙住在这些地方。"},
{volumn:        13,chapter:         7,versenumber:        30,verse:"亚设的儿子是音拿、亦施瓦、亦施韦、比利亚，还有他们的妹子西拉。"},
{volumn:        13,chapter:         7,versenumber:        31,verse:"比利亚的儿子是希别、玛结；玛结是比撒威的父亲。"},
{volumn:        13,chapter:         7,versenumber:        32,verse:"希别生雅弗勒、朔默、何坦，和他们的妹子书雅。"},
{volumn:        13,chapter:         7,versenumber:        33,verse:"雅弗勒的儿子是巴萨、宾哈、亚施法。这都是雅弗勒的儿子。"},
{volumn:        13,chapter:         7,versenumber:        34,verse:"朔默的儿子是亚希、罗迦、耶户巴、亚兰。"},
{volumn:        13,chapter:         7,versenumber:        35,verse:"朔默兄弟希连的儿子是琐法、音那、示利斯、亚抹。"},
{volumn:        13,chapter:         7,versenumber:        36,verse:"琐法的儿子是书亚、哈尼弗、书阿勒、比利、音拉、"},
{volumn:        13,chapter:         7,versenumber:        37,verse:"比悉、河得、珊玛、施沙、益兰、比拉。"},
{volumn:        13,chapter:         7,versenumber:        38,verse:"益帖的儿子是耶孚尼、毗斯巴、亚拉。"},
{volumn:        13,chapter:         7,versenumber:        39,verse:"乌拉的儿子是亚拉、汉尼业、利写。"},
{volumn:        13,chapter:         7,versenumber:        40,verse:"这都是亚设的子孙，都是族长，是精壮大能的勇士，也是首领中的头目，按着家谱计算，他们的子孙能出战的共有二万六千人。"},
{volumn:        13,chapter:         8,versenumber:         1,verse:"便雅悯的长子比拉，次子亚实别，三子亚哈拉，"},
{volumn:        13,chapter:         8,versenumber:         2,verse:"四子挪哈，五子拉法。"},
{volumn:        13,chapter:         8,versenumber:         3,verse:"比拉的儿子是亚大、基拉、亚比忽、"},
{volumn:        13,chapter:         8,versenumber:         4,verse:"亚比书、乃幔、亚何亚、"},
{volumn:        13,chapter:         8,versenumber:         5,verse:"基拉、示孚汛、户兰。"},
{volumn:        13,chapter:         8,versenumber:         6,verse:"以忽的儿子作迦巴居民的族长，被掳到玛拿辖；"},
{volumn:        13,chapter:         8,versenumber:         7,verse:"以忽的儿子乃幔、亚希亚、基拉也被掳去。基拉生乌撒、亚希忽。"},
{volumn:        13,chapter:         8,versenumber:         8,verse:"沙哈连休他二妻户伸和巴拉之后，在摩押地生了儿子。"},
{volumn:        13,chapter:         8,versenumber:         9,verse:"他与妻贺得同房，生了约巴、洗比雅、米沙、玛拉干、"},
{volumn:        13,chapter:         8,versenumber:        10,verse:"耶乌斯、沙迦、米玛。他这些儿子都是族长。"},
{volumn:        13,chapter:         8,versenumber:        11,verse:"他的妻户伸给他生的儿子有亚比突、以利巴力。"},
{volumn:        13,chapter:         8,versenumber:        12,verse:"以利巴力的儿子是希伯、米珊、沙麦。沙麦建立阿挪和罗德二城与其村庄。"},
{volumn:        13,chapter:         8,versenumber:        13,verse:"又有比利亚和示玛是亚雅仑居民的族长，是驱逐迦特人的。"},
{volumn:        13,chapter:         8,versenumber:        14,verse:"亚希约、沙煞、耶利末、"},
{volumn:        13,chapter:         8,versenumber:        15,verse:"西巴第雅、亚拉得、亚得、"},
{volumn:        13,chapter:         8,versenumber:        16,verse:"米迦勒、伊施巴、约哈都是比利亚的儿子。"},
{volumn:        13,chapter:         8,versenumber:        17,verse:"西巴第雅、米书兰、希西基、希伯、"},
{volumn:        13,chapter:         8,versenumber:        18,verse:"伊施米莱、伊斯利亚、约巴都是以利巴力的儿子。"},
{volumn:        13,chapter:         8,versenumber:        19,verse:"雅金、细基利、撒底、"},
{volumn:        13,chapter:         8,versenumber:        20,verse:"以利乃、洗勒太、以列、"},
{volumn:        13,chapter:         8,versenumber:        21,verse:"亚大雅、比拉雅、申拉都是示每的儿子。"},
{volumn:        13,chapter:         8,versenumber:        22,verse:"伊施班、希伯、以列、"},
{volumn:        13,chapter:         8,versenumber:        23,verse:"亚伯顿、细基利、哈难、"},
{volumn:        13,chapter:         8,versenumber:        24,verse:"哈拿尼雅、以拦、安陀提雅、"},
{volumn:        13,chapter:         8,versenumber:        25,verse:"伊弗底雅、毗努伊勒都是沙煞的儿子。"},
{volumn:        13,chapter:         8,versenumber:        26,verse:"珊示莱、示哈利、亚他利雅、"},
{volumn:        13,chapter:         8,versenumber:        27,verse:"雅利西、以利亚、细基利都是耶罗罕的儿子。"},
{volumn:        13,chapter:         8,versenumber:        28,verse:"这些人都是著名的族长，住在耶路撒冷。"},
{volumn:        13,chapter:         8,versenumber:        29,verse:"在基遍住的有基遍的父亲耶利。他的妻名叫玛迦；"},
{volumn:        13,chapter:         8,versenumber:        30,verse:"他长子是亚伯顿。他又生苏珥、基士、巴力、拿答、"},
{volumn:        13,chapter:         8,versenumber:        31,verse:"基多、亚希约、撒迦、米基罗。"},
{volumn:        13,chapter:         8,versenumber:        32,verse:"米基罗生示米暗。这些人和他们的弟兄在耶路撒冷对面居住。"},
{volumn:        13,chapter:         8,versenumber:        33,verse:"尼珥生基士；基士生扫罗；扫罗生约拿单、麦基舒亚、亚比拿达、伊施·巴力。"},
{volumn:        13,chapter:         8,versenumber:        34,verse:"约拿单的儿子是米力·巴力（在撒母耳下四章四节是米非波设）；米力·巴力生米迦。"},
{volumn:        13,chapter:         8,versenumber:        35,verse:"米迦的儿子是毗敦、米勒、他利亚、亚哈斯；"},
{volumn:        13,chapter:         8,versenumber:        36,verse:"亚哈斯生耶何阿达；耶何阿达生亚拉篾、亚斯玛威、心利；心利生摩撒；"},
{volumn:        13,chapter:         8,versenumber:        37,verse:"摩撒生比尼亚；比尼亚的儿子是拉法；拉法的儿子是以利亚萨；以利亚萨的儿子是亚悉。"},
{volumn:        13,chapter:         8,versenumber:        38,verse:"亚悉有六个儿子，他们的名字是亚斯利干、波基路、以实玛利、示亚利雅、俄巴底雅、哈难。这都是亚悉的儿子。"},
{volumn:        13,chapter:         8,versenumber:        39,verse:"亚悉兄弟以设的长子是乌兰，次子耶乌施，三子是以利法列。"},
{volumn:        13,chapter:         8,versenumber:        40,verse:"乌兰的儿子都是大能的勇士，是弓箭手，他们有许多的子孙，共一百五十名，都是便雅悯人。"},
{volumn:        13,chapter:         9,versenumber:         1,verse:"以色列人都按家谱计算，写在以色列诸王记上。犹大人因犯罪就被掳到巴比伦。"},
{volumn:        13,chapter:         9,versenumber:         2,verse:"先从巴比伦回来，住在自己地业城邑中的有以色列人、祭司、利未人、尼提宁的首领。"},
{volumn:        13,chapter:         9,versenumber:         3,verse:"住在耶路撒冷的有犹大人、便雅悯人、以法莲人、玛拿西人。"},
{volumn:        13,chapter:         9,versenumber:         4,verse:"犹大儿子法勒斯的子孙中有乌太。乌太是亚米忽的儿子；亚米忽是暗利的儿子；暗利是音利的儿子；音利是巴尼的儿子。"},
{volumn:        13,chapter:         9,versenumber:         5,verse:"示罗的子孙中有长子亚帅雅和他的众子。"},
{volumn:        13,chapter:         9,versenumber:         6,verse:"谢拉的子孙中有耶乌利和他的弟兄，共六百九十人。"},
{volumn:        13,chapter:         9,versenumber:         7,verse:"便雅悯人中有哈西努的曾孙、何达威雅的孙子、米书兰的儿子撒路，"},
{volumn:        13,chapter:         9,versenumber:         8,verse:"又有耶罗罕的儿子伊比尼雅，米基立的孙子、乌西的儿子以拉，伊比尼雅的曾孙、流珥的孙子、示法提雅的儿子米书兰，"},
{volumn:        13,chapter:         9,versenumber:         9,verse:"和他们的族弟兄，按着家谱计算共有九百五十六名。这些人都是他们的族长。"},
{volumn:        13,chapter:         9,versenumber:        10,verse:"祭司中有耶大雅、耶何雅立、雅斤，"},
{volumn:        13,chapter:         9,versenumber:        11,verse:"还有管理　神殿希勒家的儿子亚萨利雅。希勒家是米书兰的儿子；米书兰是撒督的儿子；撒督是米拉约的儿子；米拉约是亚希突的儿子。"},
{volumn:        13,chapter:         9,versenumber:        12,verse:"有玛基雅的曾孙、巴施户珥的孙子、耶罗罕的儿子亚大雅，又有亚第业的儿子玛赛；亚第业是雅希细拉的儿子；雅希细拉是米书兰的儿子；米书兰是米实利密的儿子；米实利密是音麦的儿子。"},
{volumn:        13,chapter:         9,versenumber:        13,verse:"他们和众弟兄都是族长，共有一千七百六十人，是善于做　神殿使用之工的。"},
{volumn:        13,chapter:         9,versenumber:        14,verse:"利未人米拉利的子孙中，有哈沙比雅的曾孙、押利甘的孙子、哈述的儿子示玛雅。"},
{volumn:        13,chapter:         9,versenumber:        15,verse:"有拔巴甲、黑勒施、迦拉，并亚萨的曾孙、细基利的孙子、米迦的儿子玛探雅，"},
{volumn:        13,chapter:         9,versenumber:        16,verse:"又有耶杜顿的曾孙、迦拉的孙子、示玛雅的儿子俄巴底，还有以利加拿的孙子、亚撒的儿子比利家。他们都住在尼陀法人的村庄。"},
{volumn:        13,chapter:         9,versenumber:        17,verse:"守门的是沙龙、亚谷、达们、亚希幔，和他们的弟兄；沙龙为长。"},
{volumn:        13,chapter:         9,versenumber:        18,verse:"从前这些人看守朝东的王门，如今是利未营中守门的。"},
{volumn:        13,chapter:         9,versenumber:        19,verse:"可拉的曾孙、以比雅撒的孙子、可利的儿子沙龙，和他的族弟兄可拉人都管理使用之工，并守会幕的门。他们的祖宗曾管理耶和华的营盘，又把守营门。"},
{volumn:        13,chapter:         9,versenumber:        20,verse:"从前以利亚撒的儿子非尼哈管理他们，耶和华也与他同在。"},
{volumn:        13,chapter:         9,versenumber:        21,verse:"米施利米雅的儿子撒迦利雅是看守会幕之门的。"},
{volumn:        13,chapter:         9,versenumber:        22,verse:"被选守门的人共有二百一十二名。他们在自己的村庄，按着家谱计算，是大卫和先见撒母耳所派当这紧要职任的。"},
{volumn:        13,chapter:         9,versenumber:        23,verse:"他们和他们的子孙按着班次看守耶和华殿的门，就是会幕的门。"},
{volumn:        13,chapter:         9,versenumber:        24,verse:"在东西南北，四方都有守门的。"},
{volumn:        13,chapter:         9,versenumber:        25,verse:"他们的族弟兄住在村庄，每七日来与他们换班。"},
{volumn:        13,chapter:         9,versenumber:        26,verse:"这四个门领都是利未人，各有紧要的职任，看守　神殿的仓库。"},
{volumn:        13,chapter:         9,versenumber:        27,verse:"他们住在　神殿的四围，是因委托他们守殿，要每日早晨开门。"},
{volumn:        13,chapter:         9,versenumber:        28,verse:"利未人中有管理使用器皿的，按着数目拿出拿入；"},
{volumn:        13,chapter:         9,versenumber:        29,verse:"又有人管理器具和圣所的器皿，并细面、酒、油、乳香、香料。"},
{volumn:        13,chapter:         9,versenumber:        30,verse:"祭司中有人用香料做膏油。"},
{volumn:        13,chapter:         9,versenumber:        31,verse:"利未人玛他提雅是可拉族沙龙的长子，他紧要的职任是管理盘中烤的物。"},
{volumn:        13,chapter:         9,versenumber:        32,verse:"他们族弟兄哥辖子孙中，有管理陈设饼的，每安息日预备摆列。"},
{volumn:        13,chapter:         9,versenumber:        33,verse:"歌唱的有利未人的族长，住在属殿的房屋，昼夜供职，不做别样的工。"},
{volumn:        13,chapter:         9,versenumber:        34,verse:"以上都是利未人著名的族长，住在耶路撒冷。"},
{volumn:        13,chapter:         9,versenumber:        35,verse:"在基遍住的有基遍的父亲耶利。他的妻名叫玛迦；"},
{volumn:        13,chapter:         9,versenumber:        36,verse:"他长子是亚伯顿。他又生苏珥、基士、巴力、尼珥、拿答、"},
{volumn:        13,chapter:         9,versenumber:        37,verse:"基多、亚希约、撒迦利雅、米基罗。"},
{volumn:        13,chapter:         9,versenumber:        38,verse:"米基罗生示米暗。这些人和他们的弟兄在耶路撒冷对面居住。"},
{volumn:        13,chapter:         9,versenumber:        39,verse:"尼珥生基士；基士生扫罗；扫罗生约拿单、麦基舒亚、亚比拿达、伊施·巴力。"},
{volumn:        13,chapter:         9,versenumber:        40,verse:"约拿单的儿子是米力·巴力（就是米非波设）；米力·巴力生米迦。"},
{volumn:        13,chapter:         9,versenumber:        41,verse:"米迦的儿子是毗敦、米勒、他利亚、亚哈斯。"},
{volumn:        13,chapter:         9,versenumber:        42,verse:"亚哈斯生雅拉；雅拉生亚拉篾、亚斯玛威、心利；心利生摩撒；"},
{volumn:        13,chapter:         9,versenumber:        43,verse:"摩撒生比尼亚；比尼亚生利法雅；利法雅的儿子是以利亚萨；以利亚萨的儿子是亚悉。"},
{volumn:        13,chapter:         9,versenumber:        44,verse:"亚悉有六个儿子，他们的名字是亚斯利干、波基路、以实玛利、示亚利雅、俄巴底雅、哈难。这都是亚悉的儿子。"},
{volumn:        13,chapter:        10,versenumber:         1,verse:"非利士人与以色列人争战，以色列人在非利士人面前逃跑，在基利波山有被杀仆倒的。"},
{volumn:        13,chapter:        10,versenumber:         2,verse:"非利士人紧追扫罗和他儿子们，就杀了扫罗的儿子约拿单、亚比拿达、麦基舒亚。"},
{volumn:        13,chapter:        10,versenumber:         3,verse:"势派甚大，扫罗被弓箭手追上，射伤甚重，"},
{volumn:        13,chapter:        10,versenumber:         4,verse:"就吩咐拿他兵器的人说：“你拔出刀来，将我刺死，免得那些未受割礼的人来凌辱我。”但拿兵器的人甚惧怕，不肯刺他；"},
{volumn:        13,chapter:        10,versenumber:         5,verse:"扫罗就自己伏在刀上死了。拿兵器的人见扫罗已死，也伏在刀上死了。"},
{volumn:        13,chapter:        10,versenumber:         6,verse:"这样，扫罗和他三个儿子，并他的全家都一同死亡。"},
{volumn:        13,chapter:        10,versenumber:         7,verse:"住平原的以色列众人见以色列军兵逃跑，扫罗和他儿子都死了，也就弃城逃跑，非利士人便来住在其中。"},
{volumn:        13,chapter:        10,versenumber:         8,verse:"次日，非利士人来剥那被杀之人的衣服，看见扫罗和他儿子仆倒在基利波山，"},
{volumn:        13,chapter:        10,versenumber:         9,verse:"就剥了他的军装，割下他的首级，打发人到（到：或译送到）非利士地的四境报信与他们的偶像和众民，"},
{volumn:        13,chapter:        10,versenumber:        10,verse:"又将扫罗的军装放在他们神的庙里，将他的首级钉在大衮庙中。"},
{volumn:        13,chapter:        10,versenumber:        11,verse:"基列·雅比人听见非利士人向扫罗所行的一切事，"},
{volumn:        13,chapter:        10,versenumber:        12,verse:"他们中间所有的勇士就起身前去，将扫罗和他儿子的尸身送到雅比，将他们的尸骨葬在雅比的橡树下，就禁食七日。"},
{volumn:        13,chapter:        10,versenumber:        13,verse:"这样，扫罗死了。因为他干犯耶和华，没有遵守耶和华的命；又因他求问交鬼的妇人，"},
{volumn:        13,chapter:        10,versenumber:        14,verse:"没有求问耶和华，所以耶和华使他被杀，把国归于耶西的儿子大卫。"},
{volumn:        13,chapter:        11,versenumber:         1,verse:"以色列众人聚集到希伯仑见大卫，说：“我们原是你的骨肉。"},
{volumn:        13,chapter:        11,versenumber:         2,verse:"从前扫罗作王的时候，率领以色列人出入的是你；耶和华你的　神也曾应许你说：‘你必牧养我的民以色列，作以色列的君。’”"},
{volumn:        13,chapter:        11,versenumber:         3,verse:"于是以色列的长老都来到希伯仑见大卫王。大卫在希伯仑耶和华面前与他们立约，他们就膏大卫作以色列的王，是照耶和华藉撒母耳所说的话。"},
{volumn:        13,chapter:        11,versenumber:         4,verse:"大卫和以色列众人到了耶路撒冷，就是耶布斯；那时耶布斯人住在那里。"},
{volumn:        13,chapter:        11,versenumber:         5,verse:"耶布斯人对大卫说：“你决不能进这地方。”然而大卫攻取锡安的保障，就是大卫的城。"},
{volumn:        13,chapter:        11,versenumber:         6,verse:"大卫说：“谁先攻打耶布斯人，必作首领元帅。”洗鲁雅的儿子约押先上去，就作了元帅。"},
{volumn:        13,chapter:        11,versenumber:         7,verse:"大卫住在保障里，所以那保障叫作大卫城。"},
{volumn:        13,chapter:        11,versenumber:         8,verse:"大卫又从米罗起，四围建筑城墙，其余的是约押修理。"},
{volumn:        13,chapter:        11,versenumber:         9,verse:"大卫日见强盛，因为万军之耶和华与他同在。"},
{volumn:        13,chapter:        11,versenumber:        10,verse:"以下记录跟随大卫勇士的首领，就是奋勇帮助他得国、照着耶和华吩咐以色列人的话、与以色列人一同立他作王的。"},
{volumn:        13,chapter:        11,versenumber:        11,verse:"大卫勇士的数目记在下面：哈革摩尼的儿子雅朔班，他是军长的统领，一时举枪杀了三百人。"},
{volumn:        13,chapter:        11,versenumber:        12,verse:"其次是亚合人朵多的儿子以利亚撒，他是三个勇士里的一个。"},
{volumn:        13,chapter:        11,versenumber:        13,verse:"他从前与大卫在巴斯·达闵，非利士人聚集要打仗。那里有一块长满大麦的田，众民就在非利士人面前逃跑；"},
{volumn:        13,chapter:        11,versenumber:        14,verse:"这勇士便站在那田间击杀非利士人，救护了那田。耶和华使以色列人大获全胜。"},
{volumn:        13,chapter:        11,versenumber:        15,verse:"三十个勇士中的三个人下到磐石那里，进了亚杜兰洞见大卫；非利士的军队在利乏音谷安营。"},
{volumn:        13,chapter:        11,versenumber:        16,verse:"那时大卫在山寨，非利士人的防营在伯利恒。"},
{volumn:        13,chapter:        11,versenumber:        17,verse:"大卫渴想，说：“甚愿有人将伯利恒城门旁井里的水打来给我喝！”"},
{volumn:        13,chapter:        11,versenumber:        18,verse:"这三个勇士就闯过非利士人的营盘，从伯利恒城门旁的井里打水，拿来奉给大卫。他却不肯喝，将水奠在耶和华面前，"},
{volumn:        13,chapter:        11,versenumber:        19,verse:"说：“我的　神啊，这三个人冒死去打水，这水好像他们的血一般，我断不敢喝！”如此，大卫不肯喝。这是三个勇士所做的事。"},
{volumn:        13,chapter:        11,versenumber:        20,verse:"约押的兄弟亚比筛是这三个勇士的首领；他举枪杀了三百人，就在三个勇士里得了名。"},
{volumn:        13,chapter:        11,versenumber:        21,verse:"他在这三个勇士里是最尊贵的，所以作他们的首领；只是不及前三个勇士。"},
{volumn:        13,chapter:        11,versenumber:        22,verse:"有甲薛勇士耶何耶大的儿子比拿雅行过大能的事：他杀了摩押人亚利伊勒的两个儿子，又在下雪的时候下坑里去杀了一个狮子，"},
{volumn:        13,chapter:        11,versenumber:        23,verse:"又杀了一个埃及人。埃及人身高五肘，手里拿着枪，枪杆粗如织布的机轴；比拿雅只拿着棍子下去，从埃及人手里夺过枪来，用那枪将他刺死。"},
{volumn:        13,chapter:        11,versenumber:        24,verse:"这是耶何耶大的儿子比拿雅所行的事，就在三个勇士里得了名。"},
{volumn:        13,chapter:        11,versenumber:        25,verse:"他比那三十个勇士都尊贵，只是不及前三个勇士。大卫立他作护卫长。"},
{volumn:        13,chapter:        11,versenumber:        26,verse:"军中的勇士有约押的兄弟亚撒黑，伯利恒人朵多的儿子伊勒哈难，"},
{volumn:        13,chapter:        11,versenumber:        27,verse:"哈律人沙玛，比伦人希利斯，"},
{volumn:        13,chapter:        11,versenumber:        28,verse:"提哥亚人益吉的儿子以拉，亚拿突人亚比以谢，"},
{volumn:        13,chapter:        11,versenumber:        29,verse:"户沙人西比该，亚合人以来，"},
{volumn:        13,chapter:        11,versenumber:        30,verse:"尼陀法人玛哈莱，尼陀法人巴拿的儿子希立，"},
{volumn:        13,chapter:        11,versenumber:        31,verse:"便雅悯族基比亚人利拜的儿子以太，比拉顿人比拿雅，"},
{volumn:        13,chapter:        11,versenumber:        32,verse:"迦实溪人户莱，亚拉巴人亚比，"},
{volumn:        13,chapter:        11,versenumber:        33,verse:"巴路米人押斯玛弗，沙本人以利雅哈巴，"},
{volumn:        13,chapter:        11,versenumber:        34,verse:"基孙人哈深的众子，哈拉人沙基的儿子约拿单，"},
{volumn:        13,chapter:        11,versenumber:        35,verse:"哈拉人沙甲的儿子亚希暗，吾珥的儿子以利法勒，"},
{volumn:        13,chapter:        11,versenumber:        36,verse:"米基拉人希弗，比伦人亚希雅，"},
{volumn:        13,chapter:        11,versenumber:        37,verse:"迦密人希斯罗，伊斯拜的儿子拿莱，"},
{volumn:        13,chapter:        11,versenumber:        38,verse:"拿单的兄弟约珥，哈基利的儿子弥伯哈，"},
{volumn:        13,chapter:        11,versenumber:        39,verse:"亚扪人洗勒，比录人拿哈莱（拿哈莱是给洗鲁雅的儿子约押拿兵器的），"},
{volumn:        13,chapter:        11,versenumber:        40,verse:"以帖人以拉，以帖人迦立，"},
{volumn:        13,chapter:        11,versenumber:        41,verse:"赫人乌利亚，亚莱的儿子撒拔，"},
{volumn:        13,chapter:        11,versenumber:        42,verse:"流便人示撒的儿子亚第拿（他是流便支派中的一个族长，率领三十人），"},
{volumn:        13,chapter:        11,versenumber:        43,verse:"玛迦的儿子哈难，弥特尼人约沙法，"},
{volumn:        13,chapter:        11,versenumber:        44,verse:"亚施他拉人乌西亚，亚罗珥人何坦的儿子沙玛、耶利，"},
{volumn:        13,chapter:        11,versenumber:        45,verse:"提洗人申利的儿子耶叠和他的兄弟约哈，"},
{volumn:        13,chapter:        11,versenumber:        46,verse:"玛哈未人以利业，伊利拿安的儿子耶利拜、约沙未雅，摩押人伊特玛、"},
{volumn:        13,chapter:        11,versenumber:        47,verse:"以利业、俄备得，并米琐八人雅西业。"},
{volumn:        13,chapter:        12,versenumber:         1,verse:"大卫因怕基士的儿子扫罗，躲在洗革拉的时候，有勇士到他那里帮助他打仗。"},
{volumn:        13,chapter:        12,versenumber:         2,verse:"他们善于拉弓，能用左右两手甩石射箭，都是便雅悯人扫罗的族弟兄。"},
{volumn:        13,chapter:        12,versenumber:         3,verse:"为首的是亚希以谢，其次是约阿施，都是基比亚人示玛的儿子。还有亚斯玛威的儿子耶薛和毗力，又有比拉迦，并亚拿突人耶户，"},
{volumn:        13,chapter:        12,versenumber:         4,verse:"基遍人以实买雅（他在三十人中是勇士，管理他们），且有耶利米、雅哈悉、约哈难，和基得拉人约撒拔、"},
{volumn:        13,chapter:        12,versenumber:         5,verse:"伊利乌赛、耶利摩、比亚利雅、示玛利雅，哈律弗人示法提雅，"},
{volumn:        13,chapter:        12,versenumber:         6,verse:"可拉人以利加拿、耶西亚、亚萨列、约以谢、雅朔班，"},
{volumn:        13,chapter:        12,versenumber:         7,verse:"基多人耶罗罕的儿子犹拉和西巴第雅。"},
{volumn:        13,chapter:        12,versenumber:         8,verse:"迦得支派中有人到旷野的山寨投奔大卫，都是大能的勇士，能拿盾牌和枪的战士。他们的面貌好像狮子，快跑如同山上的鹿。"},
{volumn:        13,chapter:        12,versenumber:         9,verse:"第一以薛，第二俄巴底雅，第三以利押，"},
{volumn:        13,chapter:        12,versenumber:        10,verse:"第四弥施玛拿，第五耶利米，"},
{volumn:        13,chapter:        12,versenumber:        11,verse:"第六亚太，第七以利业，"},
{volumn:        13,chapter:        12,versenumber:        12,verse:"第八约哈难，第九以利萨巴，"},
{volumn:        13,chapter:        12,versenumber:        13,verse:"第十耶利米，第十一末巴奈。"},
{volumn:        13,chapter:        12,versenumber:        14,verse:"这都是迦得人中的军长，至小的能抵一百人，至大的能抵一千人。"},
{volumn:        13,chapter:        12,versenumber:        15,verse:"正月，约旦河水涨过两岸的时候，他们过河，使一切住平原的人东奔西逃。"},
{volumn:        13,chapter:        12,versenumber:        16,verse:"又有便雅悯和犹大人到山寨大卫那里。"},
{volumn:        13,chapter:        12,versenumber:        17,verse:"大卫出去迎接他们，对他们说：“你们若是和和平平地来帮助我，我心就与你们相契；你们若是将我这无罪的人卖在敌人手里，愿我们列祖的　神察看责罚。”"},
{volumn:        13,chapter:        12,versenumber:        18,verse:"那时　神的灵感动那三十个勇士的首领亚玛撒，他就说：“大卫啊，我们是归于你的！耶西的儿子啊，我们是帮助你的！愿你平平安安，愿帮助你的也都平安！因为你的　神帮助你。”大卫就收留他们，立他们作军长。"},
{volumn:        13,chapter:        12,versenumber:        19,verse:"大卫从前与非利士人同去，要与扫罗争战，有些玛拿西人来投奔大卫，他们却没有帮助非利士人；因为非利士人的首领商议，打发他们回去，说：“恐怕大卫拿我们的首级，归降他的主人扫罗。”"},
{volumn:        13,chapter:        12,versenumber:        20,verse:"大卫往洗革拉去的时候，有玛拿西人的千夫长押拿、约撒拔、耶叠、米迦勒、约撒拔、以利户、洗勒太都来投奔他。"},
{volumn:        13,chapter:        12,versenumber:        21,verse:"这些人帮助大卫攻击群贼；他们都是大能的勇士，且作军长。"},
{volumn:        13,chapter:        12,versenumber:        22,verse:"那时天天有人来帮助大卫，以致成了大军，如　神的军一样。"},
{volumn:        13,chapter:        12,versenumber:        23,verse:"预备打仗的兵来到希伯仑见大卫，要照着耶和华的话将扫罗的国位归与大卫。他们的数目如下："},
{volumn:        13,chapter:        12,versenumber:        24,verse:"犹大支派，拿盾牌和枪预备打仗的有六千八百人。"},
{volumn:        13,chapter:        12,versenumber:        25,verse:"西缅支派，能上阵大能的勇士有七千一百人。"},
{volumn:        13,chapter:        12,versenumber:        26,verse:"利未支派有四千六百人。"},
{volumn:        13,chapter:        12,versenumber:        27,verse:"耶何耶大是亚伦家的首领，跟从他的有三千七百人。"},
{volumn:        13,chapter:        12,versenumber:        28,verse:"还有少年大能的勇士撒督，同着他的有族长二十二人。"},
{volumn:        13,chapter:        12,versenumber:        29,verse:"便雅悯支派，扫罗的族弟兄也有三千人，他们向来大半归顺扫罗家。"},
{volumn:        13,chapter:        12,versenumber:        30,verse:"以法莲支派大能的勇士，在本族著名的有二万零八百人。"},
{volumn:        13,chapter:        12,versenumber:        31,verse:"玛拿西半支派，册上有名的共一万八千人，都来立大卫作王。"},
{volumn:        13,chapter:        12,versenumber:        32,verse:"以萨迦支派，有二百族长都通达时务，知道以色列人所当行的；他们族弟兄都听从他们的命令。"},
{volumn:        13,chapter:        12,versenumber:        33,verse:"西布伦支派，能上阵用各样兵器打仗、行伍整齐、不生二心的有五万人。"},
{volumn:        13,chapter:        12,versenumber:        34,verse:"拿弗他利支派，有一千军长；跟从他们、拿盾牌和枪的有三万七千人。"},
{volumn:        13,chapter:        12,versenumber:        35,verse:"但支派，能摆阵的有二万八千六百人。"},
{volumn:        13,chapter:        12,versenumber:        36,verse:"亚设支派，能上阵打仗的有四万人。"},
{volumn:        13,chapter:        12,versenumber:        37,verse:"约旦河东的流便支派、迦得支派、玛拿西半支派，拿着各样兵器打仗的有十二万人。"},
{volumn:        13,chapter:        12,versenumber:        38,verse:"以上都是能守行伍的战士，他们都诚心来到希伯仑，要立大卫作以色列的王。以色列其余的人也都一心要立大卫作王。"},
{volumn:        13,chapter:        12,versenumber:        39,verse:"他们在那里三日，与大卫一同吃喝，因为他们的族弟兄给他们预备了。"},
{volumn:        13,chapter:        12,versenumber:        40,verse:"靠近他们的人以及以萨迦、西布伦、拿弗他利人将许多面饼、无花果饼、干葡萄、酒、油，用驴、骆驼、骡子、牛驮来，又带了许多的牛和羊来，因为以色列人甚是欢乐。"},
{volumn:        13,chapter:        13,versenumber:         1,verse:"大卫与千夫长、百夫长，就是一切首领商议。"},
{volumn:        13,chapter:        13,versenumber:         2,verse:"大卫对以色列全会众说：“你们若以为美，见这事是出于耶和华我们的　神，我们就差遣人走遍以色列地，见我们未来的弟兄，又见住在有郊野之城的祭司利未人，使他们都到这里来聚集。"},
{volumn:        13,chapter:        13,versenumber:         3,verse:"我们要把　神的约柜运到我们这里来；因为在扫罗年间，我们没有在约柜前求问　神。”"},
{volumn:        13,chapter:        13,versenumber:         4,verse:"全会众都说可以如此行；这事在众民眼中都看为好。"},
{volumn:        13,chapter:        13,versenumber:         5,verse:"于是，大卫将以色列人从埃及的西曷河直到哈马口都招聚了来，要从基列·耶琳将　神的约柜运来。"},
{volumn:        13,chapter:        13,versenumber:         6,verse:"大卫率领以色列众人上到巴拉，就是属犹大的基列·耶琳，要从那里将约柜运来。这约柜就是坐在二基路伯上耶和华　神留名的约柜。"},
{volumn:        13,chapter:        13,versenumber:         7,verse:"他们将　神的约柜从亚比拿达的家里抬出来，放在新车上。乌撒和亚希约赶车。"},
{volumn:        13,chapter:        13,versenumber:         8,verse:"大卫和以色列众人在　神前用琴、瑟、锣、鼓、号作乐，极力跳舞歌唱。"},
{volumn:        13,chapter:        13,versenumber:         9,verse:"到了基顿（在撒母耳下六章六节是拿艮）的禾场；因为牛失前蹄（或译：惊跳），乌撒就伸手扶住约柜。"},
{volumn:        13,chapter:        13,versenumber:        10,verse:"耶和华向他发怒，因他伸手扶住约柜击杀他，他就死在　神面前。"},
{volumn:        13,chapter:        13,versenumber:        11,verse:"大卫因耶和华击杀（原文是闯杀）乌撒，心里愁烦，就称那地方为毗列斯·乌撒，直到今日。"},
{volumn:        13,chapter:        13,versenumber:        12,verse:"那日，大卫惧怕　神，说：“　神的约柜怎可运到我这里来？”"},
{volumn:        13,chapter:        13,versenumber:        13,verse:"于是大卫不将约柜运进大卫的城，却运到迦特人俄别·以东的家中。"},
{volumn:        13,chapter:        13,versenumber:        14,verse:"神的约柜在俄别·以东家中三个月，耶和华赐福给俄别·以东的家和他一切所有的。"},
{volumn:        13,chapter:        14,versenumber:         1,verse:"推罗王希兰将香柏木运到大卫那里，又差遣使者和石匠、木匠给大卫建造宫殿。"},
{volumn:        13,chapter:        14,versenumber:         2,verse:"大卫就知道耶和华坚立他作以色列王，又为自己的民以色列，使他的国兴旺。"},
{volumn:        13,chapter:        14,versenumber:         3,verse:"大卫在耶路撒冷又立后妃，又生儿女。"},
{volumn:        13,chapter:        14,versenumber:         4,verse:"在耶路撒冷所生的众子是沙母亚、朔罢、拿单、所罗门、"},
{volumn:        13,chapter:        14,versenumber:         5,verse:"益辖、以利书亚、以法列、"},
{volumn:        13,chapter:        14,versenumber:         6,verse:"挪迦、尼斐、雅非亚、"},
{volumn:        13,chapter:        14,versenumber:         7,verse:"以利沙玛、比利雅大、以利法列。"},
{volumn:        13,chapter:        14,versenumber:         8,verse:"非利士人听见大卫受膏作以色列众人的王，非利士众人就上来寻索大卫。大卫听见，就出去迎敌。"},
{volumn:        13,chapter:        14,versenumber:         9,verse:"非利士人来了，布散在利乏音谷。"},
{volumn:        13,chapter:        14,versenumber:        10,verse:"大卫求问　神，说：“我可以上去攻打非利士人吗？你将他们交在我手里吗？”耶和华说：“你可以上去，我必将他们交在你手里。”"},
{volumn:        13,chapter:        14,versenumber:        11,verse:"非利士人来到巴力·毗拉心，大卫在那里杀败他们。大卫说：“　神藉我的手冲破敌人，如同水冲去一般”；因此称那地方为巴力·毗拉心。"},
{volumn:        13,chapter:        14,versenumber:        12,verse:"非利士人将神像撇在那里，大卫吩咐人用火焚烧了。"},
{volumn:        13,chapter:        14,versenumber:        13,verse:"非利士人又布散在利乏音谷。"},
{volumn:        13,chapter:        14,versenumber:        14,verse:"大卫又求问　神。　神说：“不要一直地上去，要转到他们后头，从桑林对面攻打他们。"},
{volumn:        13,chapter:        14,versenumber:        15,verse:"你听见桑树梢上有脚步的声音，就要出战，因为　神已经在你前头去攻打非利士人的军队。”"},
{volumn:        13,chapter:        14,versenumber:        16,verse:"大卫就遵着　神所吩咐的，攻打非利士人的军队，从基遍直到基色。"},
{volumn:        13,chapter:        14,versenumber:        17,verse:"于是大卫的名传扬到列国，耶和华使列国都惧怕他。"},
{volumn:        13,chapter:        15,versenumber:         1,verse:"大卫在大卫城为自己建造宫殿，又为　神的约柜预备地方，支搭帐幕。"},
{volumn:        13,chapter:        15,versenumber:         2,verse:"那时大卫说：“除了利未人之外，无人可抬　神的约柜；因为耶和华拣选他们抬　神的约柜，且永远侍奉他。”"},
{volumn:        13,chapter:        15,versenumber:         3,verse:"大卫招聚以色列众人到耶路撒冷，要将耶和华的约柜抬到他所预备的地方。"},
{volumn:        13,chapter:        15,versenumber:         4,verse:"大卫又聚集亚伦的子孙和利未人。"},
{volumn:        13,chapter:        15,versenumber:         5,verse:"哥辖子孙中有族长乌列和他的弟兄一百二十人。"},
{volumn:        13,chapter:        15,versenumber:         6,verse:"米拉利子孙中有族长亚帅雅和他的弟兄二百二十人。"},
{volumn:        13,chapter:        15,versenumber:         7,verse:"革顺子孙中有族长约珥和他的弟兄一百三十人。"},
{volumn:        13,chapter:        15,versenumber:         8,verse:"以利撒反子孙中有族长示玛雅和他的弟兄二百人。"},
{volumn:        13,chapter:        15,versenumber:         9,verse:"希伯仑子孙中有族长以列和他的弟兄八十人。"},
{volumn:        13,chapter:        15,versenumber:        10,verse:"乌薛子孙中有族长亚米拿达和他的弟兄一百一十二人。"},
{volumn:        13,chapter:        15,versenumber:        11,verse:"大卫将祭司撒督和亚比亚他，并利未人乌列、亚帅雅、约珥、示玛雅、以列、亚米拿达召来，"},
{volumn:        13,chapter:        15,versenumber:        12,verse:"对他们说：“你们是利未人的族长，你们和你们的弟兄应当自洁，好将耶和华以色列　神的约柜抬到我所预备的地方。"},
{volumn:        13,chapter:        15,versenumber:        13,verse:"因你们先前没有抬这约柜，按定例求问耶和华我们的　神，所以他刑罚（原文是闯杀）我们。”"},
{volumn:        13,chapter:        15,versenumber:        14,verse:"于是祭司利未人自洁，好将耶和华以色列　神的约柜抬上来。"},
{volumn:        13,chapter:        15,versenumber:        15,verse:"利未子孙就用杠，肩抬　神的约柜，是照耶和华藉摩西所吩咐的。"},
{volumn:        13,chapter:        15,versenumber:        16,verse:"大卫吩咐利未人的族长，派他们歌唱的弟兄用琴瑟和钹作乐，欢欢喜喜地大声歌颂。"},
{volumn:        13,chapter:        15,versenumber:        17,verse:"于是利未人派约珥的儿子希幔和他弟兄中比利家的儿子亚萨，并他们族弟兄米拉利子孙里古沙雅的儿子以探。"},
{volumn:        13,chapter:        15,versenumber:        18,verse:"其次还有他们的弟兄撒迦利雅、便雅薛、示米拉末、耶歇、乌尼、以利押、比拿雅、玛西雅、玛他提雅、以利斐利户、弥克尼雅，并守门的俄别·以东和耶利。"},
{volumn:        13,chapter:        15,versenumber:        19,verse:"这样，派歌唱的希幔、亚萨、以探敲铜钹，大发响声；"},
{volumn:        13,chapter:        15,versenumber:        20,verse:"派撒迦利雅、雅薛、示米拉末、耶歇、乌尼、以利押、玛西雅、比拿雅鼓瑟，调用女音；"},
{volumn:        13,chapter:        15,versenumber:        21,verse:"又派玛他提雅、以利斐利户、弥克尼雅、俄别·以东、耶利、亚撒西雅领首弹琴，调用第八。"},
{volumn:        13,chapter:        15,versenumber:        22,verse:"利未人的族长基拿尼雅是歌唱人的首领，又教训人歌唱，因为他精通此事。"},
{volumn:        13,chapter:        15,versenumber:        23,verse:"比利家、以利加拿是约柜前守门的。"},
{volumn:        13,chapter:        15,versenumber:        24,verse:"祭司示巴尼、约沙法、拿坦业、亚玛赛、撒迦利雅、比拿亚、以利以谢在　神的约柜前吹号。俄别·以东和耶希亚也是约柜前守门的。"},
{volumn:        13,chapter:        15,versenumber:        25,verse:"于是，大卫和以色列的长老，并千夫长都去从俄别·以东的家欢欢喜喜地将耶和华的约柜抬上来。"},
{volumn:        13,chapter:        15,versenumber:        26,verse:"神赐恩与抬耶和华约柜的利未人，他们就献上七只公牛，七只公羊。"},
{volumn:        13,chapter:        15,versenumber:        27,verse:"大卫和抬约柜的利未人，并歌唱人的首领基拿尼雅，以及歌唱的人，都穿着细麻布的外袍；大卫另外穿着细麻布的以弗得。"},
{volumn:        13,chapter:        15,versenumber:        28,verse:"这样，以色列众人欢呼吹角、吹号、敲钹、鼓瑟、弹琴，大发响声，将耶和华的约柜抬上来。"},
{volumn:        13,chapter:        15,versenumber:        29,verse:"耶和华的约柜进了大卫城的时候，扫罗的女儿米甲从窗户里观看，见大卫王踊跃跳舞，心里就轻视他。"},
{volumn:        13,chapter:        16,versenumber:         1,verse:"众人将　神的约柜请进去，安放在大卫所搭的帐幕里，就在　神面前献燔祭和平安祭。"},
{volumn:        13,chapter:        16,versenumber:         2,verse:"大卫献完了燔祭和平安祭，就奉耶和华的名给民祝福，"},
{volumn:        13,chapter:        16,versenumber:         3,verse:"并且分给以色列人，无论男女，每人一个饼，一块肉，一个葡萄饼。"},
{volumn:        13,chapter:        16,versenumber:         4,verse:"大卫派几个利未人在耶和华的约柜前侍奉，颂扬，称谢，赞美耶和华以色列的　神："},
{volumn:        13,chapter:        16,versenumber:         5,verse:"为首的是亚萨，其次是撒迦利雅、雅薛、示米拉末、耶歇、玛他提雅、以利押、比拿雅、俄别·以东、耶利，鼓瑟弹琴；惟有亚萨敲钹，大发响声；"},
{volumn:        13,chapter:        16,versenumber:         6,verse:"祭司比拿雅和雅哈悉常在　神的约柜前吹号。"},
{volumn:        13,chapter:        16,versenumber:         7,verse:"那日，大卫初次藉亚萨和他的弟兄以诗歌称颂耶和华，说："},
{volumn:        13,chapter:        16,versenumber:         8,verse:"“你们要称谢耶和华，求告他的名，在万民中传扬他的作为！"},
{volumn:        13,chapter:        16,versenumber:         9,verse:"要向他唱诗、歌颂，谈论他一切奇妙的作为。"},
{volumn:        13,chapter:        16,versenumber:        10,verse:"要以他的圣名夸耀；寻求耶和华的人，心中应当欢喜。"},
{volumn:        13,chapter:        16,versenumber:        11,verse:"要寻求耶和华与他的能力，时常寻求他的面。"},
{volumn:        13,chapter:        16,versenumber:        12,verse:"他仆人以色列的后裔，他所拣选雅各的子孙哪，你们要记念他奇妙的作为和他的奇事，并他口中的判语。"},
{volumn:        13,chapter:        16,versenumber:        13,verse:""},
{volumn:        13,chapter:        16,versenumber:        14,verse:"“他是耶和华我们的　神，全地都有他的判断。"},
{volumn:        13,chapter:        16,versenumber:        15,verse:"你们要记念他的约，直到永远；他所吩咐的话，直到千代，"},
{volumn:        13,chapter:        16,versenumber:        16,verse:"就是与亚伯拉罕所立的约，向以撒所起的誓。"},
{volumn:        13,chapter:        16,versenumber:        17,verse:"他又将这约向雅各定为律例，向以色列定为永远的约，"},
{volumn:        13,chapter:        16,versenumber:        18,verse:"说：‘我必将迦南地赐给你，作你产业的份。’"},
{volumn:        13,chapter:        16,versenumber:        19,verse:"“当时你们人丁有限，数目稀少，并且在那地为寄居的；"},
{volumn:        13,chapter:        16,versenumber:        20,verse:"他们从这邦游到那邦，从这国行到那国。"},
{volumn:        13,chapter:        16,versenumber:        21,verse:"耶和华不容什么人欺负他们，为他们的缘故责备君王，"},
{volumn:        13,chapter:        16,versenumber:        22,verse:"说：‘不可难为我受膏的人，也不可恶待我的先知。’"},
{volumn:        13,chapter:        16,versenumber:        23,verse:"“全地都要向耶和华歌唱！天天传扬他的救恩，"},
{volumn:        13,chapter:        16,versenumber:        24,verse:"在列邦中述说他的荣耀，在万民中述说他的奇事。"},
{volumn:        13,chapter:        16,versenumber:        25,verse:"因耶和华为大，当受极大的赞美；他在万　神之上，当受敬畏。"},
{volumn:        13,chapter:        16,versenumber:        26,verse:"外邦的神都属虚无，惟独耶和华创造诸天。"},
{volumn:        13,chapter:        16,versenumber:        27,verse:"有尊荣和威严在他面前，有能力和喜乐在他圣所。"},
{volumn:        13,chapter:        16,versenumber:        28,verse:"“民中的万族啊，你们要将荣耀能力归给耶和华，都归给耶和华！"},
{volumn:        13,chapter:        16,versenumber:        29,verse:"要将耶和华的名所当得的荣耀归给他，拿供物来奉到他面前；当以圣洁的（的：或译为）妆饰敬拜耶和华。"},
{volumn:        13,chapter:        16,versenumber:        30,verse:"全地要在他面前战抖，世界也坚定不得动摇。"},
{volumn:        13,chapter:        16,versenumber:        31,verse:"愿天欢喜，愿地快乐；愿人在列邦中说：‘耶和华作王了！’"},
{volumn:        13,chapter:        16,versenumber:        32,verse:"愿海和其中所充满的澎湃；愿田和其中所有的都欢乐。"},
{volumn:        13,chapter:        16,versenumber:        33,verse:"那时，林中的树木都要在耶和华面前欢呼，因为他来要审判全地。"},
{volumn:        13,chapter:        16,versenumber:        34,verse:"应当称谢耶和华；因他本为善，他的慈爱永远长存！"},
{volumn:        13,chapter:        16,versenumber:        35,verse:"“要说：‘拯救我们的　神啊，求你救我们，聚集我们，使我们脱离外邦，我们好称赞你的圣名，以赞美你为夸胜。’"},
{volumn:        13,chapter:        16,versenumber:        36,verse:"耶和华以色列的　神，从亘古直到永远，是应当称颂的！”众民都说：“阿们！”并且赞美耶和华。"},
{volumn:        13,chapter:        16,versenumber:        37,verse:"大卫派亚萨和他的弟兄在约柜前常常侍奉耶和华，一日尽一日的职分；"},
{volumn:        13,chapter:        16,versenumber:        38,verse:"又派俄别·以东和他的弟兄六十八人，与耶杜顿的儿子俄别·以东，并何萨作守门的；"},
{volumn:        13,chapter:        16,versenumber:        39,verse:"且派祭司撒督和他弟兄众祭司在基遍的邱坛、耶和华的帐幕前燔祭坛上，每日早晚，照着耶和华律法书上所吩咐以色列人的，常给耶和华献燔祭。"},
{volumn:        13,chapter:        16,versenumber:        40,verse:""},
{volumn:        13,chapter:        16,versenumber:        41,verse:"与他们一同被派的有希幔、耶杜顿，和其余被选名字录在册上的，称谢耶和华，因他的慈爱永远长存。"},
{volumn:        13,chapter:        16,versenumber:        42,verse:"希幔、耶杜顿同着他们吹号、敲钹，大发响声，并用别的乐器随着歌颂　神。耶杜顿的子孙作守门的。"},
{volumn:        13,chapter:        16,versenumber:        43,verse:"于是众民各归各家；大卫也回去为家眷祝福。"},
{volumn:        13,chapter:        17,versenumber:         1,verse:"大卫住在自己宫中，对先知拿单说：“看哪，我住在香柏木的宫中，耶和华的约柜反在幔子里。”"},
{volumn:        13,chapter:        17,versenumber:         2,verse:"拿单对大卫说：“你可以照你的心意而行，因为　神与你同在。”"},
{volumn:        13,chapter:        17,versenumber:         3,verse:"当夜，　神的话临到拿单，说："},
{volumn:        13,chapter:        17,versenumber:         4,verse:"“你去告诉我仆人大卫，说耶和华如此说：‘你不可建造殿宇给我居住。"},
{volumn:        13,chapter:        17,versenumber:         5,verse:"自从我领以色列人出埃及，直到今日，我未曾住过殿宇，乃从这会幕到那会幕，从这帐幕到那帐幕。"},
{volumn:        13,chapter:        17,versenumber:         6,verse:"凡我同以色列人所走的地方，我何曾向以色列的一个士师，就是我吩咐牧养我民的说：你为何不给我建造香柏木的殿宇呢？’"},
{volumn:        13,chapter:        17,versenumber:         7,verse:"现在你要告诉我仆人大卫，说万军之耶和华如此说：‘我从羊圈中将你召来，叫你不再跟从羊群，立你作我民以色列的君。"},
{volumn:        13,chapter:        17,versenumber:         8,verse:"你无论往哪里去，我常与你同在，剪除你的一切仇敌；我必使你得大名，好像世上大大有名的人一样。"},
{volumn:        13,chapter:        17,versenumber:         9,verse:"我必为我民以色列选定一个地方，栽培他们，使他们住自己的地方，不再迁移；凶恶之子也不像从前扰害他们，"},
{volumn:        13,chapter:        17,versenumber:        10,verse:"并不像我命士师治理我民以色列的时候一样。我必治服你的一切仇敌，并且我耶和华应许你，必为你建立家室。"},
{volumn:        13,chapter:        17,versenumber:        11,verse:"你寿数满足归你列祖的时候，我必使你的后裔接续你的位，我也必坚定他的国。"},
{volumn:        13,chapter:        17,versenumber:        12,verse:"他必为我建造殿宇；我必坚定他的国位直到永远。"},
{volumn:        13,chapter:        17,versenumber:        13,verse:"我要作他的父，他要作我的子；并不使我的慈爱离开他，像离开在你以前的扫罗一样。"},
{volumn:        13,chapter:        17,versenumber:        14,verse:"我却要将他永远坚立在我家里和我国里；他的国位也必坚定，直到永远。’”"},
{volumn:        13,chapter:        17,versenumber:        15,verse:"拿单就按这一切话，照这默示告诉大卫。"},
{volumn:        13,chapter:        17,versenumber:        16,verse:"于是大卫王进去，坐在耶和华面前，说：“耶和华　神啊，我是谁，我的家算什么，你竟使我到这地步呢？"},
{volumn:        13,chapter:        17,versenumber:        17,verse:"神啊，这在你眼中还看为小，又应许你仆人的家至于久远。耶和华　神啊，你看顾我好像看顾高贵的人。"},
{volumn:        13,chapter:        17,versenumber:        18,verse:"你加于仆人的尊荣，我还有何言可说呢？因为你知道你的仆人。"},
{volumn:        13,chapter:        17,versenumber:        19,verse:"耶和华啊，你行了这大事，并且显明出来，是因你仆人的缘故，也是照你的心意。"},
{volumn:        13,chapter:        17,versenumber:        20,verse:"耶和华啊，照我们耳中听见，没有可比你的，除你以外再无　神。"},
{volumn:        13,chapter:        17,versenumber:        21,verse:"世上有何民能比你的民以色列呢？你　神从埃及救赎他们作自己的子民，又在你赎出来的民面前行大而可畏的事，驱逐列邦人，显出你的大名。"},
{volumn:        13,chapter:        17,versenumber:        22,verse:"你使以色列人作你的子民，直到永远；你耶和华也作他们的　神。"},
{volumn:        13,chapter:        17,versenumber:        23,verse:"耶和华啊，你所应许仆人和仆人家的话，求你坚定，直到永远，照你所说的而行。"},
{volumn:        13,chapter:        17,versenumber:        24,verse:"愿你的名永远坚立，被尊为大，说：‘万军之耶和华是以色列的　神，是治理以色列的神。’这样，你仆人大卫的家必在你面前坚立。"},
{volumn:        13,chapter:        17,versenumber:        25,verse:"我的　神啊，因你启示仆人说：‘我必为你建立家室。’所以仆人大胆在你面前祈祷。"},
{volumn:        13,chapter:        17,versenumber:        26,verse:"耶和华啊，惟有你是　神，你也应许将这福气赐给仆人。"},
{volumn:        13,chapter:        17,versenumber:        27,verse:"现在你喜悦赐福与仆人的家，可以永存在你面前。耶和华啊，你已经赐福，还要赐福到永远。”"},
{volumn:        13,chapter:        18,versenumber:         1,verse:"此后，大卫攻打非利士人，把他们治服，从他们手下夺取了迦特和属迦特的村庄；"},
{volumn:        13,chapter:        18,versenumber:         2,verse:"又攻打摩押，摩押人就归服大卫，给他进贡。"},
{volumn:        13,chapter:        18,versenumber:         3,verse:"琐巴王哈大利谢（在撒母耳下八章三节是哈大底谢）往幼发拉底河去，要坚定自己的国权，大卫就攻打他，直到哈马，"},
{volumn:        13,chapter:        18,versenumber:         4,verse:"夺了他的战车一千，马兵七千，步兵二万，将拉战车的马砍断蹄筋，但留下一百辆车的马。"},
{volumn:        13,chapter:        18,versenumber:         5,verse:"大马士革的亚兰人来帮助琐巴王哈大利谢，大卫就杀了亚兰人二万二千。"},
{volumn:        13,chapter:        18,versenumber:         6,verse:"于是大卫在大马士革的亚兰地设立防营，亚兰人就归服他，给他进贡。大卫无论往哪里去，耶和华都使他得胜。"},
{volumn:        13,chapter:        18,versenumber:         7,verse:"他夺了哈大利谢臣仆所拿的金盾牌带到耶路撒冷。"},
{volumn:        13,chapter:        18,versenumber:         8,verse:"大卫又从属哈大利谢的提巴（或译：比他）和均二城中夺取了许多的铜。后来所罗门用此制造铜海、铜柱，和一切的铜器。"},
{volumn:        13,chapter:        18,versenumber:         9,verse:"哈马王陀乌听见大卫杀败琐巴王哈大利谢的全军，"},
{volumn:        13,chapter:        18,versenumber:        10,verse:"就打发他儿子哈多兰去见大卫王，问他的安，为他祝福，因为他杀败了哈大利谢（原来陀乌与哈大利谢常常争战）。哈多兰带了金银铜的各样器皿来。"},
{volumn:        13,chapter:        18,versenumber:        11,verse:"大卫王将这些器皿，并从各国夺来的金银，就是从以东、摩押、亚扪、非利士、亚玛力人所夺来的，都分别为圣献给耶和华。"},
{volumn:        13,chapter:        18,versenumber:        12,verse:"洗鲁雅的儿子亚比筛在盐谷击杀了以东一万八千人。"},
{volumn:        13,chapter:        18,versenumber:        13,verse:"大卫在以东地设立防营，以东人就都归服他。大卫无论往哪里去，耶和华都使他得胜。"},
{volumn:        13,chapter:        18,versenumber:        14,verse:"大卫作以色列众人的王，又向众民秉公行义。"},
{volumn:        13,chapter:        18,versenumber:        15,verse:"洗鲁雅的儿子约押作元帅；亚希律的儿子约沙法作史官；"},
{volumn:        13,chapter:        18,versenumber:        16,verse:"亚希突的儿子撒督和亚比亚他的儿子亚希米勒作祭司长；沙威沙作书记；"},
{volumn:        13,chapter:        18,versenumber:        17,verse:"耶何耶大的儿子比拿雅统辖基利提人和比利提人。大卫的众子都在王的左右作领袖。"},
{volumn:        13,chapter:        19,versenumber:         1,verse:"此后，亚扪人的王拿辖死了，他儿子接续他作王。"},
{volumn:        13,chapter:        19,versenumber:         2,verse:"大卫说：“我要照哈嫩的父亲拿辖厚待我的恩典厚待哈嫩。”于是大卫差遣使者为他丧父安慰他。大卫的臣仆到了亚扪人的境内见哈嫩，要安慰他，"},
{volumn:        13,chapter:        19,versenumber:         3,verse:"但亚扪人的首领对哈嫩说：“大卫差人来安慰你，你想他是尊敬你父亲吗？他的臣仆来见你不是为详察窥探、倾覆这地吗？”"},
{volumn:        13,chapter:        19,versenumber:         4,verse:"哈嫩便将大卫臣仆的胡须剃去一半，又割断他们下半截的衣服，使他们露出下体，打发他们回去。"},
{volumn:        13,chapter:        19,versenumber:         5,verse:"有人将臣仆所遇的事告诉大卫，他就差人去迎接他们，因为他们甚觉羞耻；告诉他们说：“可以住在耶利哥，等到胡须长起再回来。”"},
{volumn:        13,chapter:        19,versenumber:         6,verse:"亚扪人知道大卫憎恶他们，哈嫩和亚扪人就打发人拿一千他连得银子，从美索不达米亚、亚兰、玛迦、琐巴雇战车和马兵，"},
{volumn:        13,chapter:        19,versenumber:         7,verse:"于是雇了三万二千辆战车和玛迦王并他的军兵；他们来安营在米底巴前。亚扪人也从他们的城里出来，聚集交战。"},
{volumn:        13,chapter:        19,versenumber:         8,verse:"大卫听见了，就差派约押统带勇猛的全军出去。"},
{volumn:        13,chapter:        19,versenumber:         9,verse:"亚扪人出来在城门前摆阵，所来的诸王另在郊野摆阵。"},
{volumn:        13,chapter:        19,versenumber:        10,verse:"约押看见敌人在他前后摆阵，就从以色列军中挑选精兵，使他们对着亚兰人摆阵；"},
{volumn:        13,chapter:        19,versenumber:        11,verse:"其余的兵交与他兄弟亚比筛，对着亚扪人摆阵。"},
{volumn:        13,chapter:        19,versenumber:        12,verse:"约押对亚比筛说：“亚兰人若强过我，你就来帮助我；亚扪人若强过你，我就去帮助你。"},
{volumn:        13,chapter:        19,versenumber:        13,verse:"我们都当刚强，为本国的民和　神的城邑作大丈夫，愿耶和华凭他的意旨而行。”"},
{volumn:        13,chapter:        19,versenumber:        14,verse:"于是约押和跟随他的人前进攻打亚兰人；亚兰人在约押面前逃跑。"},
{volumn:        13,chapter:        19,versenumber:        15,verse:"亚扪人见亚兰人逃跑，他们也在约押的兄弟亚比筛面前逃跑进城。约押就回耶路撒冷去了。"},
{volumn:        13,chapter:        19,versenumber:        16,verse:"亚兰人见自己被以色列人打败，就打发使者将大河那边的亚兰人调来，哈大利谢的将军朔法率领他们。"},
{volumn:        13,chapter:        19,versenumber:        17,verse:"有人告诉大卫，他就聚集以色列众人过约旦河，来到亚兰人那里，迎着他们摆阵。大卫既摆阵攻击亚兰人，亚兰人就与他打仗。"},
{volumn:        13,chapter:        19,versenumber:        18,verse:"亚兰人在以色列人面前逃跑。大卫杀了亚兰七千辆战车的人，四万步兵，又杀了亚兰的将军朔法。"},
{volumn:        13,chapter:        19,versenumber:        19,verse:"属哈大利谢的诸王见自己被以色列人打败，就与大卫和好，归服他。于是亚兰人不敢再帮助亚扪人了。"},
{volumn:        13,chapter:        20,versenumber:         1,verse:"过了一年，到列王出战的时候，约押率领军兵毁坏亚扪人的地，围攻拉巴；大卫仍住在耶路撒冷。约押攻打拉巴，将城倾覆。"},
{volumn:        13,chapter:        20,versenumber:         2,verse:"大卫夺了亚扪人之王所戴的金冠冕（王：或译玛勒堪。玛勒堪即米勒公，是亚扪族之神名），其上的金子重一他连得，又嵌着宝石；人将这冠冕戴在大卫头上。大卫从城里夺了许多财物，"},
{volumn:        13,chapter:        20,versenumber:         3,verse:"将城里的人拉出来，放在锯下，或铁耙下，或铁斧下（或译：强他们用锯，或用打粮食的铁器，或用铁斧做工），大卫待亚扪各城的居民都是如此。其后大卫和众军都回耶路撒冷去了。"},
{volumn:        13,chapter:        20,versenumber:         4,verse:"后来，以色列人在基色与非利士人打仗。户沙人西比该杀了伟人的一个儿子细派，非利士人就被制伏了。"},
{volumn:        13,chapter:        20,versenumber:         5,verse:"又与非利士人打仗。睚珥的儿子伊勒哈难杀了迦特人歌利亚的兄弟拉哈米；这人的枪杆粗如织布的机轴。"},
{volumn:        13,chapter:        20,versenumber:         6,verse:"又在迦特打仗。那里有一个身量高大的人，手脚都是六指，共有二十四个指头，他也是伟人的儿子。"},
{volumn:        13,chapter:        20,versenumber:         7,verse:"这人向以色列人骂阵，大卫的哥哥示米亚的儿子约拿单就杀了他。"},
{volumn:        13,chapter:        20,versenumber:         8,verse:"这三个人是迦特伟人的儿子，都死在大卫和他仆人的手下。"},
{volumn:        13,chapter:        21,versenumber:         1,verse:"撒但起来攻击以色列人，激动大卫数点他们。"},
{volumn:        13,chapter:        21,versenumber:         2,verse:"大卫就吩咐约押和民中的首领说：“你们去数点以色列人，从别是巴直到但，回来告诉我，我好知道他们的数目。”"},
{volumn:        13,chapter:        21,versenumber:         3,verse:"约押说：“愿耶和华使他的百姓比现在加增百倍。我主我王啊，他们不都是你的仆人吗？我主为何吩咐行这事，为何使以色列人陷在罪里呢？”"},
{volumn:        13,chapter:        21,versenumber:         4,verse:"但王的命令胜过约押。约押就出去，走遍以色列地，回到耶路撒冷，"},
{volumn:        13,chapter:        21,versenumber:         5,verse:"将百姓的总数奏告大卫：以色列人拿刀的有一百一十万；犹大人拿刀的有四十七万。"},
{volumn:        13,chapter:        21,versenumber:         6,verse:"惟有利未人和便雅悯人没有数在其中，因为约押厌恶王的这命令。"},
{volumn:        13,chapter:        21,versenumber:         7,verse:"神不喜悦这数点百姓的事，便降灾给以色列人。"},
{volumn:        13,chapter:        21,versenumber:         8,verse:"大卫祷告　神说：“我行这事大有罪了！现在求你除掉仆人的罪孽，因我所行的甚是愚昧。”"},
{volumn:        13,chapter:        21,versenumber:         9,verse:"耶和华吩咐大卫的先见迦得说："},
{volumn:        13,chapter:        21,versenumber:        10,verse:"“你去告诉大卫说，耶和华如此说：我有三样灾，随你选择一样，我好降与你。”"},
{volumn:        13,chapter:        21,versenumber:        11,verse:"于是，迦得来见大卫，对他说：“耶和华如此说：‘你可以随意选择："},
{volumn:        13,chapter:        21,versenumber:        12,verse:"或三年的饥荒；或败在你敌人面前，被敌人的刀追杀三个月；或在你国中有耶和华的刀，就是三日的瘟疫，耶和华的使者在以色列的四境施行毁灭。’现在你要想一想，我好回复那差我来的。”"},
{volumn:        13,chapter:        21,versenumber:        13,verse:"大卫对迦得说：“我甚为难。我愿落在耶和华的手里，因为他有丰盛的怜悯；我不愿落在人的手里。”"},
{volumn:        13,chapter:        21,versenumber:        14,verse:"于是，耶和华降瘟疫与以色列人，以色列人就死了七万。"},
{volumn:        13,chapter:        21,versenumber:        15,verse:"神差遣使者去灭耶路撒冷，刚要灭的时候，耶和华看见后悔，就不降这灾了，吩咐灭城的天使说：“够了，住手吧！”那时，耶和华的使者站在耶布斯人阿珥楠的禾场那里。"},
{volumn:        13,chapter:        21,versenumber:        16,verse:"大卫举目，看见耶和华的使者站在天地间，手里有拔出来的刀，伸在耶路撒冷以上。大卫和长老都身穿麻衣，面伏于地。"},
{volumn:        13,chapter:        21,versenumber:        17,verse:"大卫祷告　神说：“吩咐数点百姓的不是我吗？我犯了罪，行了恶，但这群羊做了什么呢？愿耶和华我　神的手攻击我和我的父家，不要攻击你的民，降瘟疫与他们。”"},
{volumn:        13,chapter:        21,versenumber:        18,verse:"耶和华的使者吩咐迦得去告诉大卫，叫他上去，在耶布斯人阿珥楠的禾场上为耶和华筑一座坛；"},
{volumn:        13,chapter:        21,versenumber:        19,verse:"大卫就照着迦得奉耶和华名所说的话上去了。"},
{volumn:        13,chapter:        21,versenumber:        20,verse:"那时阿珥楠正打麦子，回头看见天使，就和他四个儿子都藏起来了。"},
{volumn:        13,chapter:        21,versenumber:        21,verse:"大卫到了阿珥楠那里，阿珥楠看见大卫，就从禾场上出去，脸伏于地，向他下拜。"},
{volumn:        13,chapter:        21,versenumber:        22,verse:"大卫对阿珥楠说：“你将这禾场与相连之地卖给我，我必给你足价，我好在其上为耶和华筑一座坛，使民间的瘟疫止住。”"},
{volumn:        13,chapter:        21,versenumber:        23,verse:"阿珥楠对大卫说：“你可以用这禾场，愿我主我王照你所喜悦的去行。我也将牛给你作燔祭，把打粮的器具当柴烧，拿麦子作素祭。这些我都送给你。”"},
{volumn:        13,chapter:        21,versenumber:        24,verse:"大卫王对阿珥楠说：“不然！我必要用足价向你买。我不用你的物献给耶和华，也不用白得之物献为燔祭。”"},
{volumn:        13,chapter:        21,versenumber:        25,verse:"于是大卫为那块地平了六百舍客勒金子给阿珥楠。"},
{volumn:        13,chapter:        21,versenumber:        26,verse:"大卫在那里为耶和华筑了一座坛，献燔祭和平安祭，求告耶和华。耶和华就应允他，使火从天降在燔祭坛上。"},
{volumn:        13,chapter:        21,versenumber:        27,verse:"耶和华吩咐使者，他就收刀入鞘。"},
{volumn:        13,chapter:        21,versenumber:        28,verse:"那时，大卫见耶和华在耶布斯人阿珥楠的禾场上应允了他，就在那里献祭。"},
{volumn:        13,chapter:        21,versenumber:        29,verse:"摩西在旷野所造之耶和华的帐幕和燔祭坛都在基遍的高处；"},
{volumn:        13,chapter:        21,versenumber:        30,verse:"只是大卫不敢前去求问　神，因为惧怕耶和华使者的刀。"},
{volumn:        13,chapter:        22,versenumber:         1,verse:"大卫说：“这就是耶和华　神的殿，为以色列人献燔祭的坛。”"},
{volumn:        13,chapter:        22,versenumber:         2,verse:"大卫吩咐聚集住以色列地的外邦人，从其中派石匠凿石头，要建造　神的殿。"},
{volumn:        13,chapter:        22,versenumber:         3,verse:"大卫预备许多铁做门上的钉子和钩子，又预备许多铜，多得无法可称；"},
{volumn:        13,chapter:        22,versenumber:         4,verse:"又预备无数的香柏木，因为西顿人和推罗人给大卫运了许多香柏木来。"},
{volumn:        13,chapter:        22,versenumber:         5,verse:"大卫说：“我儿子所罗门还年幼娇嫩，要为耶和华建造的殿宇必须高大辉煌，使名誉荣耀传遍万国；所以我要为殿预备材料。”于是，大卫在未死之先预备的材料甚多。"},
{volumn:        13,chapter:        22,versenumber:         6,verse:"大卫召了他儿子所罗门来，嘱咐他给耶和华以色列的　神建造殿宇，"},
{volumn:        13,chapter:        22,versenumber:         7,verse:"对所罗门说：“我儿啊，我心里本想为耶和华我　神的名建造殿宇，"},
{volumn:        13,chapter:        22,versenumber:         8,verse:"只是耶和华的话临到我说：‘你流了多人的血，打了多次大仗，你不可为我的名建造殿宇，因为你在我眼前使多人的血流在地上。"},
{volumn:        13,chapter:        22,versenumber:         9,verse:"你要生一个儿子，他必作太平的人；我必使他安静，不被四围的仇敌扰乱。他的名要叫所罗门（就是太平的意思）。他在位的日子，我必使以色列人平安康泰。"},
{volumn:        13,chapter:        22,versenumber:        10,verse:"他必为我的名建造殿宇。他要作我的子；我要作他的父。他作以色列王；我必坚定他的国位，直到永远。’"},
{volumn:        13,chapter:        22,versenumber:        11,verse:"我儿啊，现今愿耶和华与你同在，使你亨通，照他指着你说的话，建造耶和华你　神的殿。"},
{volumn:        13,chapter:        22,versenumber:        12,verse:"但愿耶和华赐你聪明智慧，好治理以色列国，遵行耶和华你　神的律法。"},
{volumn:        13,chapter:        22,versenumber:        13,verse:"你若谨守遵行耶和华藉摩西吩咐以色列的律例典章，就得亨通。你当刚强壮胆，不要惧怕，也不要惊惶。"},
{volumn:        13,chapter:        22,versenumber:        14,verse:"我在困难之中为耶和华的殿预备了金子十万他连得，银子一百万他连得，铜和铁多得无法可称；我也预备了木头、石头，你还可以增添。"},
{volumn:        13,chapter:        22,versenumber:        15,verse:"你有许多匠人，就是石匠、木匠，和一切能做各样工的巧匠，"},
{volumn:        13,chapter:        22,versenumber:        16,verse:"并有无数的金银铜铁。你当起来办事，愿耶和华与你同在。”"},
{volumn:        13,chapter:        22,versenumber:        17,verse:"大卫又吩咐以色列的众首领帮助他儿子所罗门，说："},
{volumn:        13,chapter:        22,versenumber:        18,verse:"“耶和华你们的　神不是与你们同在吗？不是叫你们四围都平安吗？因他已将这地的居民交在我手中，这地就在耶和华与他百姓面前制伏了。"},
{volumn:        13,chapter:        22,versenumber:        19,verse:"现在你们应当立定心意，寻求耶和华你们的　神；也当起来建造耶和华　神的圣所，好将耶和华的约柜和供奉　神的圣器皿都搬进为耶和华名建造的殿里。”"},
{volumn:        13,chapter:        23,versenumber:         1,verse:"大卫年纪老迈，日子满足，就立他儿子所罗门作以色列的王。"},
{volumn:        13,chapter:        23,versenumber:         2,verse:"大卫招聚以色列的众首领和祭司利未人。"},
{volumn:        13,chapter:        23,versenumber:         3,verse:"利未人从三十岁以外的都被数点，他们男丁的数目共有三万八千；"},
{volumn:        13,chapter:        23,versenumber:         4,verse:"其中有二万四千人管理耶和华殿的事，有六千人作官长和士师，"},
{volumn:        13,chapter:        23,versenumber:         5,verse:"有四千人作守门的，又有四千人用大卫所做的乐器颂赞耶和华。"},
{volumn:        13,chapter:        23,versenumber:         6,verse:"大卫将利未人革顺、哥辖、米拉利的子孙分了班次。"},
{volumn:        13,chapter:        23,versenumber:         7,verse:"革顺的子孙有拉但和示每。"},
{volumn:        13,chapter:        23,versenumber:         8,verse:"拉但的长子是耶歇，还有细坦和约珥，共三人。"},
{volumn:        13,chapter:        23,versenumber:         9,verse:"示每的儿子是示罗密、哈薛、哈兰三人。这是拉但族的族长。"},
{volumn:        13,chapter:        23,versenumber:        10,verse:"示每的儿子是雅哈、细拿、耶乌施、比利亚共四人。"},
{volumn:        13,chapter:        23,versenumber:        11,verse:"雅哈是长子，细撒是次子。但耶乌施和比利亚的子孙不多，所以算为一族。"},
{volumn:        13,chapter:        23,versenumber:        12,verse:"哥辖的儿子是暗兰、以斯哈、希伯伦、乌薛共四人。"},
{volumn:        13,chapter:        23,versenumber:        13,verse:"暗兰的儿子是亚伦、摩西。亚伦和他的子孙分出来，好分别至圣的物，在耶和华面前烧香、侍奉他，奉他的名祝福，直到永远。"},
{volumn:        13,chapter:        23,versenumber:        14,verse:"至于神人摩西，他的子孙名字记在利未支派的册上。"},
{volumn:        13,chapter:        23,versenumber:        15,verse:"摩西的儿子是革舜和以利以谢。"},
{volumn:        13,chapter:        23,versenumber:        16,verse:"革舜的长子是细布业；"},
{volumn:        13,chapter:        23,versenumber:        17,verse:"以利以谢的儿子是利哈比雅。以利以谢没有别的儿子，但利哈比雅的子孙甚多。"},
{volumn:        13,chapter:        23,versenumber:        18,verse:"以斯哈的长子是示罗密。"},
{volumn:        13,chapter:        23,versenumber:        19,verse:"希伯伦的长子是耶利雅，次子是亚玛利亚，三子是雅哈悉，四子是耶加面。"},
{volumn:        13,chapter:        23,versenumber:        20,verse:"乌薛的长子是米迦，次子是耶西雅。"},
{volumn:        13,chapter:        23,versenumber:        21,verse:"米拉利的儿子是抹利、母示。抹利的儿子是以利亚撒、基士。"},
{volumn:        13,chapter:        23,versenumber:        22,verse:"以利亚撒死了，没有儿子，只有女儿，他们本族基士的儿子娶了她们为妻。"},
{volumn:        13,chapter:        23,versenumber:        23,verse:"母示的儿子是末力、以得、耶利摩共三人。"},
{volumn:        13,chapter:        23,versenumber:        24,verse:"以上利未子孙作族长的，照着男丁的数目，从二十岁以外，都办耶和华殿的事务。"},
{volumn:        13,chapter:        23,versenumber:        25,verse:"大卫说：“耶和华以色列的　神已经使他的百姓平安，他永远住在耶路撒冷。"},
{volumn:        13,chapter:        23,versenumber:        26,verse:"利未人不必再抬帐幕和其中所用的一切器皿了。”"},
{volumn:        13,chapter:        23,versenumber:        27,verse:"照着大卫临终所吩咐的，利未人从二十岁以外的都被数点。"},
{volumn:        13,chapter:        23,versenumber:        28,verse:"他们的职任是服侍亚伦的子孙，在耶和华的殿和院子，并屋中办事，洁净一切圣物，就是办　神殿的事务，"},
{volumn:        13,chapter:        23,versenumber:        29,verse:"并管理陈设饼，素祭的细面，或无酵薄饼，或用盘烤，或用油调和的物，又管理各样的升斗尺度；"},
{volumn:        13,chapter:        23,versenumber:        30,verse:"每日早晚，站立称谢赞美耶和华，"},
{volumn:        13,chapter:        23,versenumber:        31,verse:"又在安息日、月朔，并节期，按数照例，将燔祭常常献给耶和华；"},
{volumn:        13,chapter:        23,versenumber:        32,verse:"又看守会幕和圣所，并守耶和华吩咐他们弟兄亚伦子孙的，办耶和华殿的事。"},
{volumn:        13,chapter:        24,versenumber:         1,verse:"亚伦子孙的班次记在下面：亚伦的儿子是拿答、亚比户、以利亚撒、以他玛。"},
{volumn:        13,chapter:        24,versenumber:         2,verse:"拿答、亚比户死在他们父亲之先，没有留下儿子；故此，以利亚撒、以他玛供祭司的职分。"},
{volumn:        13,chapter:        24,versenumber:         3,verse:"以利亚撒的子孙撒督和以他玛的子孙亚希米勒，同着大卫将他们的族弟兄分成班次。"},
{volumn:        13,chapter:        24,versenumber:         4,verse:"以利亚撒子孙中为首的比以他玛子孙中为首的更多，分班如下：以利亚撒的子孙中有十六个族长，以他玛的子孙中有八个族长；"},
{volumn:        13,chapter:        24,versenumber:         5,verse:"都掣签分立，彼此一样。在圣所和　神面前作首领的有以利亚撒的子孙，也有以他玛的子孙。"},
{volumn:        13,chapter:        24,versenumber:         6,verse:"作书记的利未人拿坦业的儿子示玛雅在王和首领，与祭司撒督、亚比亚他的儿子亚希米勒，并祭司利未人的族长面前记录他们的名字。在以利亚撒的子孙中取一族，在以他玛的子孙中取一族。"},
{volumn:        13,chapter:        24,versenumber:         7,verse:"掣签的时候，第一掣出来的是耶何雅立，第二是耶大雅，"},
{volumn:        13,chapter:        24,versenumber:         8,verse:"第三是哈琳，第四是梭琳，"},
{volumn:        13,chapter:        24,versenumber:         9,verse:"第五是玛基雅，第六是米雅民，"},
{volumn:        13,chapter:        24,versenumber:        10,verse:"第七是哈歌斯，第八是亚比雅，"},
{volumn:        13,chapter:        24,versenumber:        11,verse:"第九是耶书亚，第十是示迦尼，"},
{volumn:        13,chapter:        24,versenumber:        12,verse:"第十一是以利亚实，第十二是雅金，"},
{volumn:        13,chapter:        24,versenumber:        13,verse:"第十三是胡巴，第十四是耶是比押，"},
{volumn:        13,chapter:        24,versenumber:        14,verse:"第十五是璧迦，第十六是音麦，"},
{volumn:        13,chapter:        24,versenumber:        15,verse:"第十七是希悉，第十八是哈辟悉，"},
{volumn:        13,chapter:        24,versenumber:        16,verse:"第十九是毗他希雅，第二十是以西结，"},
{volumn:        13,chapter:        24,versenumber:        17,verse:"第二十一是雅斤，第二十二是迦末，"},
{volumn:        13,chapter:        24,versenumber:        18,verse:"第二十三是第来雅，第二十四是玛西亚。"},
{volumn:        13,chapter:        24,versenumber:        19,verse:"这就是他们的班次，要照耶和华以色列的　神藉他们祖宗亚伦所吩咐的条例进入耶和华的殿办理事务。"},
{volumn:        13,chapter:        24,versenumber:        20,verse:"利未其余的子孙如下：暗兰的子孙里有书巴业；书巴业的子孙里有耶希底亚。"},
{volumn:        13,chapter:        24,versenumber:        21,verse:"利哈比雅的子孙里有长子伊示雅。"},
{volumn:        13,chapter:        24,versenumber:        22,verse:"以斯哈的子孙里有示罗摩；示罗摩的子孙里有雅哈。"},
{volumn:        13,chapter:        24,versenumber:        23,verse:"希伯伦的子孙里有长子耶利雅，次子亚玛利亚，三子雅哈悉，四子耶加面。"},
{volumn:        13,chapter:        24,versenumber:        24,verse:"乌薛的子孙里有米迦；米迦的子孙里有沙密。"},
{volumn:        13,chapter:        24,versenumber:        25,verse:"米迦的兄弟是伊示雅；伊示雅的子孙里有撒迦利雅。"},
{volumn:        13,chapter:        24,versenumber:        26,verse:"米拉利的儿子是抹利、母示、雅西雅；雅西雅的儿子有比挪；"},
{volumn:        13,chapter:        24,versenumber:        27,verse:"米拉利的子孙里有雅西雅的儿子比挪、朔含、撒刻、伊比利。"},
{volumn:        13,chapter:        24,versenumber:        28,verse:"抹利的儿子是以利亚撒；以利亚撒没有儿子。"},
{volumn:        13,chapter:        24,versenumber:        29,verse:"基士的子孙里有耶拉篾。"},
{volumn:        13,chapter:        24,versenumber:        30,verse:"母示的儿子是末力、以得、耶利摩。按着宗族这都是利未的子孙。"},
{volumn:        13,chapter:        24,versenumber:        31,verse:"他们在大卫王和撒督，并亚希米勒与祭司利未人的族长面前掣签，正如他们弟兄亚伦的子孙一般。各族的长者与兄弟没有分别。"},
{volumn:        13,chapter:        25,versenumber:         1,verse:"大卫和众首领分派亚萨、希幔，并耶杜顿的子孙弹琴、鼓瑟、敲钹、唱歌（原文是说预言；本章同）。他们供职的人数记在下面："},
{volumn:        13,chapter:        25,versenumber:         2,verse:"亚萨的儿子撒刻、约瑟、尼探雅、亚萨利拉都归亚萨指教，遵王的旨意唱歌。"},
{volumn:        13,chapter:        25,versenumber:         3,verse:"耶杜顿的儿子基大利、西利、耶筛亚、哈沙比雅、玛他提雅、示每共六人，都归他们父亲耶杜顿指教，弹琴，唱歌，称谢，颂赞耶和华。"},
{volumn:        13,chapter:        25,versenumber:         4,verse:"希幔的儿子布基雅、玛探雅、乌薛、细布业、耶利摩、哈拿尼雅、哈拿尼、以利亚他、基大利提、罗幔提·以谢、约施比加沙、玛罗提、何提、玛哈秀；"},
{volumn:        13,chapter:        25,versenumber:         5,verse:"这都是希幔的儿子，吹角颂赞。希幔奉　神之命作王的先见。　神赐给希幔十四个儿子，三个女儿，"},
{volumn:        13,chapter:        25,versenumber:         6,verse:"都归他们父亲指教，在耶和华的殿唱歌、敲钹、弹琴、鼓瑟，办　神殿的事务。亚萨、耶杜顿、希幔都是王所命定的。"},
{volumn:        13,chapter:        25,versenumber:         7,verse:"他们和他们的弟兄学习颂赞耶和华；善于歌唱的共有二百八十八人。"},
{volumn:        13,chapter:        25,versenumber:         8,verse:"这些人无论大小，为师的、为徒的，都一同掣签分了班次。"},
{volumn:        13,chapter:        25,versenumber:         9,verse:"掣签的时候，第一掣出来的是亚萨的儿子约瑟。第二是基大利；他和他弟兄并儿子共十二人。"},
{volumn:        13,chapter:        25,versenumber:        10,verse:"第三是撒刻；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        11,verse:"第四是伊洗利；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        12,verse:"第五是尼探雅；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        13,verse:"第六是布基雅；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        14,verse:"第七是耶萨利拉；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        15,verse:"第八是耶筛亚；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        16,verse:"第九是玛探雅；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        17,verse:"第十是示每；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        18,verse:"第十一是亚萨烈；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        19,verse:"第十二是哈沙比雅；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        20,verse:"第十三是书巴业；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        21,verse:"第十四是玛他提雅；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        22,verse:"第十五是耶利摩；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        23,verse:"第十六是哈拿尼雅；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        24,verse:"第十七是约施比加沙；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        25,verse:"第十八是哈拿尼；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        26,verse:"第十九是玛罗提；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        27,verse:"第二十是以利亚他；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        28,verse:"第二十一是何提；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        29,verse:"第二十二是基大利提；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        30,verse:"第二十三是玛哈秀；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        25,versenumber:        31,verse:"第二十四是罗幔提·以谢；他和他儿子并弟兄共十二人。"},
{volumn:        13,chapter:        26,versenumber:         1,verse:"守门的班次记在下面：可拉族亚萨的子孙中，有可利的儿子米施利米雅。"},
{volumn:        13,chapter:        26,versenumber:         2,verse:"米施利米雅的长子是撒迦利亚，次子是耶叠，三子是西巴第雅，四子是耶提聂，"},
{volumn:        13,chapter:        26,versenumber:         3,verse:"五子是以拦，六子是约哈难，七子是以利约乃。"},
{volumn:        13,chapter:        26,versenumber:         4,verse:"俄别·以东的长子是示玛雅，次子是约萨拔，三子是约亚，四子是沙甲，五子是拿坦业，"},
{volumn:        13,chapter:        26,versenumber:         5,verse:"六子是亚米利，七子是以萨迦，八子是毗乌利太，因为　神赐福与俄别·以东。"},
{volumn:        13,chapter:        26,versenumber:         6,verse:"他的儿子示玛雅有几个儿子，都是大能的壮士，掌管父亲的家。"},
{volumn:        13,chapter:        26,versenumber:         7,verse:"示玛雅的儿子是俄得尼、利法益、俄备得、以利萨巴。以利萨巴的弟兄是壮士，还有以利户和西玛迦。"},
{volumn:        13,chapter:        26,versenumber:         8,verse:"这都是俄别·以东的子孙，他们和他们的儿子并弟兄，都是善于办事的壮士。俄别·以东的子孙共六十二人。"},
{volumn:        13,chapter:        26,versenumber:         9,verse:"米施利米雅的儿子和弟兄都是壮士，共十八人。"},
{volumn:        13,chapter:        26,versenumber:        10,verse:"米拉利子孙何萨有几个儿子：长子是申利，他原不是长子，是他父亲立他作长子。"},
{volumn:        13,chapter:        26,versenumber:        11,verse:"次子是希勒家，三子是底巴利雅，四子是撒迦利亚。何萨的儿子并弟兄共十三人。"},
{volumn:        13,chapter:        26,versenumber:        12,verse:"这些人都是守门的班长，与他们的弟兄一同在耶和华殿里按班供职。"},
{volumn:        13,chapter:        26,versenumber:        13,verse:"他们无论大小，都按着宗族掣签分守各门。"},
{volumn:        13,chapter:        26,versenumber:        14,verse:"掣签守东门的是示利米雅；他的儿子撒迦利亚是精明的谋士，掣签守北门。"},
{volumn:        13,chapter:        26,versenumber:        15,verse:"俄别·以东守南门，他的儿子守库房。"},
{volumn:        13,chapter:        26,versenumber:        16,verse:"书聘与何萨守西门，在靠近沙利基门、通着往上去的街道上，班与班相对。"},
{volumn:        13,chapter:        26,versenumber:        17,verse:"每日东门有六个利未人，北门有四个，南门有四个，库房有两个，又有两个轮班替换。"},
{volumn:        13,chapter:        26,versenumber:        18,verse:"在西面街道上有四个，在游廊上有两个。"},
{volumn:        13,chapter:        26,versenumber:        19,verse:"以上是可拉子孙和米拉利子孙守门的班次。"},
{volumn:        13,chapter:        26,versenumber:        20,verse:"利未子孙中有亚希雅掌管　神殿的府库和圣物的府库。"},
{volumn:        13,chapter:        26,versenumber:        21,verse:"革顺族、拉但子孙里，作族长的是革顺族拉但的子孙耶希伊利。"},
{volumn:        13,chapter:        26,versenumber:        22,verse:"耶希伊利的儿子西坦和他兄弟约珥掌管耶和华殿里的府库。"},
{volumn:        13,chapter:        26,versenumber:        23,verse:"暗兰族、以斯哈族、希伯伦族、乌泄族也有职分。"},
{volumn:        13,chapter:        26,versenumber:        24,verse:"摩西的孙子、革舜的儿子细布业掌管府库。"},
{volumn:        13,chapter:        26,versenumber:        25,verse:"还有他的弟兄以利以谢。以利以谢的儿子是利哈比雅；利哈比雅的儿子是耶筛亚；耶筛亚的儿子是约兰；约兰的儿子是细基利；细基利的儿子是示罗密。"},
{volumn:        13,chapter:        26,versenumber:        26,verse:"这示罗密和他的弟兄掌管府库的圣物，就是大卫王和众族长、千夫长、百夫长，并军长所分别为圣的物。"},
{volumn:        13,chapter:        26,versenumber:        27,verse:"他们将争战时所夺的财物分别为圣，以备修造耶和华的殿。"},
{volumn:        13,chapter:        26,versenumber:        28,verse:"先见撒母耳、基士的儿子扫罗、尼珥的儿子押尼珥、洗鲁雅的儿子约押所分别为圣的物都归示罗密和他的弟兄掌管。"},
{volumn:        13,chapter:        26,versenumber:        29,verse:"以斯哈族有基拿尼雅和他众子作官长和士师，管理以色列的外事。"},
{volumn:        13,chapter:        26,versenumber:        30,verse:"希伯伦族有哈沙比雅和他弟兄一千七百人，都是壮士，在约旦河西、以色列地办理耶和华与王的事。"},
{volumn:        13,chapter:        26,versenumber:        31,verse:"希伯伦族中有耶利雅作族长。大卫作王第四十年，在基列的雅谢，从这族中寻得大能的勇士。"},
{volumn:        13,chapter:        26,versenumber:        32,verse:"耶利雅的弟兄有二千七百人，都是壮士，且作族长；大卫王派他们在流便支派、迦得支派、玛拿西半支派中办理　神和王的事。"},
{volumn:        13,chapter:        27,versenumber:         1,verse:"以色列人的族长、千夫长、百夫长，和官长都分定班次，每班是二万四千人，周年按月轮流，替换出入服侍王。"},
{volumn:        13,chapter:        27,versenumber:         2,verse:"正月第一班的班长是撒巴第业的儿子雅朔班；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:         3,verse:"他是法勒斯的子孙，统管正月班的一切军长。"},
{volumn:        13,chapter:        27,versenumber:         4,verse:"二月的班长是亚哈希人朵代，还有副官密基罗；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:         5,verse:"三月第三班的班长（原文是军长；下同）是祭司耶何耶大的儿子比拿雅；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:         6,verse:"这比拿雅是那三十人中的勇士，管理那三十人；他班内又有他儿子暗米萨拔。"},
{volumn:        13,chapter:        27,versenumber:         7,verse:"四月第四班的班长是约押的兄弟亚撒黑。接续他的是他儿子西巴第雅；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:         8,verse:"五月第五班的班长是伊斯拉人珊合；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:         9,verse:"六月第六班的班长是提哥亚人益吉的儿子以拉；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        10,verse:"七月第七班的班长是以法莲族比伦人希利斯；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        11,verse:"八月第八班的班长是谢拉族户沙人西比该；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        12,verse:"九月第九班的班长是便雅悯族亚拿突人亚比以谢；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        13,verse:"十月第十班的班长是谢拉族尼陀法人玛哈莱；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        14,verse:"十一月第十一班的班长是以法莲族比拉顿人比拿雅；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        15,verse:"十二月第十二班的班长是俄陀聂族尼陀法人黑玳；他班内有二万四千人。"},
{volumn:        13,chapter:        27,versenumber:        16,verse:"管理以色列众支派的记在下面：管流便人的是细基利的儿子以利以谢；管西缅人的是玛迦的儿子示法提雅；"},
{volumn:        13,chapter:        27,versenumber:        17,verse:"管利未人的是基母利的儿子哈沙比雅；管亚伦子孙的是撒督；"},
{volumn:        13,chapter:        27,versenumber:        18,verse:"管犹大人的是大卫的一个哥哥以利户；管以萨迦人的是米迦勒的儿子暗利；"},
{volumn:        13,chapter:        27,versenumber:        19,verse:"管西布伦人的是俄巴第雅的儿子伊施玛雅；管拿弗他利人的是亚斯列的儿子耶利摩；"},
{volumn:        13,chapter:        27,versenumber:        20,verse:"管以法莲人的是阿撒细雅的儿子何细亚；管玛拿西半支派的是毗大雅的儿子约珥；"},
{volumn:        13,chapter:        27,versenumber:        21,verse:"管基列地玛拿西那半支派的是撒迦利亚的儿子易多；管便雅悯人的是押尼珥的儿子雅西业；"},
{volumn:        13,chapter:        27,versenumber:        22,verse:"管但人的是耶罗罕的儿子亚萨列。以上是以色列众支派的首领。"},
{volumn:        13,chapter:        27,versenumber:        23,verse:"以色列人二十岁以内的，大卫没有记其数目；因耶和华曾应许说，必加增以色列人如天上的星那样多。"},
{volumn:        13,chapter:        27,versenumber:        24,verse:"洗鲁雅的儿子约押动手数点，当时耶和华的烈怒临到以色列人；因此，没有点完，数目也没有写在大卫王记上。"},
{volumn:        13,chapter:        27,versenumber:        25,verse:"掌管王府库的是亚叠的儿子押斯马威。掌管田野城邑村庄保障之仓库的是乌西雅的儿子约拿单。"},
{volumn:        13,chapter:        27,versenumber:        26,verse:"掌管耕田种地的是基绿的儿子以斯利。"},
{volumn:        13,chapter:        27,versenumber:        27,verse:"掌管葡萄园的是拉玛人示每。掌管葡萄园酒窖的是实弗米人撒巴底。"},
{volumn:        13,chapter:        27,versenumber:        28,verse:"掌管高原橄榄树和桑树的是基第利人巴勒·哈南。掌管油库的是约阿施。"},
{volumn:        13,chapter:        27,versenumber:        29,verse:"掌管沙仑牧放牛群的是沙仑人施提赉。掌管山谷牧养牛群的是亚第赉的儿子沙法。"},
{volumn:        13,chapter:        27,versenumber:        30,verse:"掌管驼群的是以实玛利人阿比勒。掌管驴群的是米仑人耶希底亚。掌管羊群的是夏甲人雅悉。"},
{volumn:        13,chapter:        27,versenumber:        31,verse:"这都是给大卫王掌管产业的。"},
{volumn:        13,chapter:        27,versenumber:        32,verse:"大卫的叔叔约拿单作谋士；这人有智慧，又作书记。哈摩尼的儿子耶歇作王众子的师傅。"},
{volumn:        13,chapter:        27,versenumber:        33,verse:"亚希多弗也作王的谋士。亚基人户筛作王的陪伴。"},
{volumn:        13,chapter:        27,versenumber:        34,verse:"亚希多弗之后，有比拿雅的儿子耶何耶大和亚比亚他接续他作谋士。约押作王的元帅。"},
{volumn:        13,chapter:        28,versenumber:         1,verse:"大卫招聚以色列各支派的首领和轮班服侍王的军长，与千夫长、百夫长，掌管王和王子产业牲畜的，并太监，以及大能的勇士，都到耶路撒冷来。"},
{volumn:        13,chapter:        28,versenumber:         2,verse:"大卫王就站起来，说：“我的弟兄，我的百姓啊，你们当听我言，我心里本想建造殿宇，安放耶和华的约柜，作为我　神的脚凳；我已经预备建造的材料。"},
{volumn:        13,chapter:        28,versenumber:         3,verse:"只是　神对我说：‘你不可为我的名建造殿宇；因你是战士，流了人的血。’"},
{volumn:        13,chapter:        28,versenumber:         4,verse:"然而，耶和华以色列的　神在我父的全家拣选我作以色列的王，直到永远。因他拣选犹大为首领；在犹大支派中拣选我父家，在我父的众子里喜悦我，立我作以色列众人的王。"},
{volumn:        13,chapter:        28,versenumber:         5,verse:"耶和华赐我许多儿子，在我儿子中拣选所罗门坐耶和华的国位，治理以色列人。"},
{volumn:        13,chapter:        28,versenumber:         6,verse:"耶和华对我说：‘你儿子所罗门必建造我的殿和院宇；因为我拣选他作我的子，我也必作他的父。"},
{volumn:        13,chapter:        28,versenumber:         7,verse:"他若恒久遵行我的诫命典章如今日一样，我就必坚定他的国位，直到永远。’"},
{volumn:        13,chapter:        28,versenumber:         8,verse:"现今在耶和华的会中，以色列众人眼前所说的，我们的　神也听见了。你们应当寻求耶和华你们　神的一切诫命，谨守遵行，如此你们可以承受这美地，遗留给你们的子孙，永远为业。"},
{volumn:        13,chapter:        28,versenumber:         9,verse:"“我儿所罗门哪，你当认识耶和华你父的　神，诚心乐意地侍奉他；因为他鉴察众人的心，知道一切心思意念。你若寻求他，他必使你寻见；你若离弃他，他必永远丢弃你。"},
{volumn:        13,chapter:        28,versenumber:        10,verse:"你当谨慎，因耶和华拣选你建造殿宇作为圣所。你当刚强去行。”"},
{volumn:        13,chapter:        28,versenumber:        11,verse:"大卫将殿的游廊、旁屋、府库、楼房、内殿，和施恩所的样式指示他儿子所罗门，"},
{volumn:        13,chapter:        28,versenumber:        12,verse:"又将被灵感动所得的样式，就是耶和华　神殿的院子、周围的房屋、殿的府库，和圣物府库的一切样式都指示他；"},
{volumn:        13,chapter:        28,versenumber:        13,verse:"又指示他祭司和利未人的班次与耶和华殿里各样的工作，并耶和华殿里一切器皿的样式，"},
{volumn:        13,chapter:        28,versenumber:        14,verse:"以及各样应用金器的分量和各样应用银器的分量，"},
{volumn:        13,chapter:        28,versenumber:        15,verse:"金灯台和金灯的分量，银灯台和银灯的分量，轻重各都合宜；"},
{volumn:        13,chapter:        28,versenumber:        16,verse:"陈设饼金桌子的分量，银桌子的分量，"},
{volumn:        13,chapter:        28,versenumber:        17,verse:"精金的肉叉子、盘子，和爵的分量，各金碗与各银碗的分两，"},
{volumn:        13,chapter:        28,versenumber:        18,verse:"精金香坛的分量，并用金子做基路伯（原文是用金子做车式的基路伯）；基路伯张开翅膀，遮掩耶和华的约柜。"},
{volumn:        13,chapter:        28,versenumber:        19,verse:"大卫说：“这一切工作的样式都是耶和华用手划出来使我明白的。”"},
{volumn:        13,chapter:        28,versenumber:        20,verse:"大卫又对他儿子所罗门说：“你当刚强壮胆去行！不要惧怕，也不要惊惶。因为耶和华　神就是我的　神，与你同在；他必不撇下你，也不丢弃你，直到耶和华殿的工作都完毕了。"},
{volumn:        13,chapter:        28,versenumber:        21,verse:"有祭司和利未人的各班，为要办理　神殿各样的事，又有灵巧的人在各样的工作上乐意帮助你；并有众首领和众民一心听从你的命令。”"},
{volumn:        13,chapter:        29,versenumber:         1,verse:"大卫王对会众说：“我儿子所罗门是　神特选的，还年幼娇嫩；这工程甚大，因这殿不是为人，乃是为耶和华　神建造的。"},
{volumn:        13,chapter:        29,versenumber:         2,verse:"我为我　神的殿已经尽力，预备金子做金器，银子做银器，铜做铜器，铁做铁器，木做木器，还有红玛瑙可镶嵌的宝石，彩石和一切的宝石，并许多汉白玉。"},
{volumn:        13,chapter:        29,versenumber:         3,verse:"且因我心中爱慕我　神的殿，就在预备建造圣殿的材料之外，又将我自己积蓄的金银献上，建造我　神的殿，"},
{volumn:        13,chapter:        29,versenumber:         4,verse:"就是俄斐金三千他连得、精炼的银子七千他连得，以贴殿墙。"},
{volumn:        13,chapter:        29,versenumber:         5,verse:"金子做金器，银子做银器，并藉匠人的手制造一切。今日有谁乐意将自己献给耶和华呢？”"},
{volumn:        13,chapter:        29,versenumber:         6,verse:"于是，众族长和以色列各支派的首领、千夫长、百夫长，并监管王工的官长，都乐意献上。"},
{volumn:        13,chapter:        29,versenumber:         7,verse:"他们为　神殿的使用献上金子五千他连得零一万达利克，银子一万他连得，铜一万八千他连得，铁十万他连得。"},
{volumn:        13,chapter:        29,versenumber:         8,verse:"凡有宝石的都交给革顺人耶歇，送入耶和华殿的府库。"},
{volumn:        13,chapter:        29,versenumber:         9,verse:"因这些人诚心乐意献给耶和华，百姓就欢喜，大卫王也大大欢喜。"},
{volumn:        13,chapter:        29,versenumber:        10,verse:"所以，大卫在会众面前称颂耶和华说：“耶和华我们的父，以色列的　神是应当称颂，直到永永远远的！"},
{volumn:        13,chapter:        29,versenumber:        11,verse:"耶和华啊，尊大、能力、荣耀、强胜、威严都是你的；凡天上地下的都是你的；国度也是你的，并且你为至高，为万有之首。"},
{volumn:        13,chapter:        29,versenumber:        12,verse:"丰富尊荣都从你而来，你也治理万物。在你手里有大能大力，使人尊大强盛都出于你。"},
{volumn:        13,chapter:        29,versenumber:        13,verse:"我们的　神啊，现在我们称谢你，赞美你荣耀之名！"},
{volumn:        13,chapter:        29,versenumber:        14,verse:"“我算什么，我的民算什么，竟能如此乐意奉献？因为万物都从你而来，我们把从你而得的献给你。"},
{volumn:        13,chapter:        29,versenumber:        15,verse:"我们在你面前是客旅，是寄居的，与我们列祖一样。我们在世的日子如影儿，不能长存（或译：没有长存的指望）。"},
{volumn:        13,chapter:        29,versenumber:        16,verse:"耶和华我们的　神啊，我们预备这许多材料，要为你的圣名建造殿宇，都是从你而来，都是属你的。"},
{volumn:        13,chapter:        29,versenumber:        17,verse:"我的　神啊，我知道你察验人心，喜悦正直；我以正直的心乐意献上这一切物。现在我喜欢见你的民在这里都乐意奉献与你。"},
{volumn:        13,chapter:        29,versenumber:        18,verse:"耶和华我们列祖亚伯拉罕、以撒、以色列的　神啊，求你使你的民常存这样的心思意念，坚定他们的心归向你，"},
{volumn:        13,chapter:        29,versenumber:        19,verse:"又求你赐我儿子所罗门诚实的心，遵守你的命令、法度、律例，成就这一切的事，用我所预备的建造殿宇。”"},
{volumn:        13,chapter:        29,versenumber:        20,verse:"大卫对全会众说：“你们应当称颂耶和华你们的　神。”于是会众称颂耶和华他们列祖的　神，低头拜耶和华与王。"},
{volumn:        13,chapter:        29,versenumber:        21,verse:"次日，他们向耶和华献平安祭和燔祭，就是献公牛一千只，公绵羊一千只，羊羔一千只，并同献的奠祭；又为以色列众人献许多的祭。那日，他们在耶和华面前吃喝，大大欢乐。"},
{volumn:        13,chapter:        29,versenumber:        22,verse:"他们奉耶和华的命再膏大卫的儿子所罗门作王，又膏撒督作祭司。"},
{volumn:        13,chapter:        29,versenumber:        23,verse:"于是所罗门坐在耶和华所赐的位上，接续他父亲大卫作王，万事亨通；以色列众人也都听从他。"},
{volumn:        13,chapter:        29,versenumber:        24,verse:"众首领和勇士，并大卫王的众子，都顺服所罗门王。"},
{volumn:        13,chapter:        29,versenumber:        25,verse:"耶和华使所罗门在以色列众人眼前甚为尊大，极其威严，胜过在他以前的以色列王。"},
{volumn:        13,chapter:        29,versenumber:        26,verse:"耶西的儿子大卫作以色列众人的王，"},
{volumn:        13,chapter:        29,versenumber:        27,verse:"作王共四十年：在希伯仑作王七年，在耶路撒冷作王三十三年。"},
{volumn:        13,chapter:        29,versenumber:        28,verse:"他年纪老迈，日子满足，享受丰富、尊荣，就死了。他儿子所罗门接续他作王。"},
{volumn:        13,chapter:        29,versenumber:        29,verse:"大卫王始终的事都写在先见撒母耳的书上和先知拿单并先见迦得的书上。"},
{volumn:        13,chapter:        29,versenumber:        30,verse:"他的国事和他的勇力，以及他和以色列并列国所经过的事都写在这书上。"},
{volumn:        14,chapter:         1,versenumber:         1,verse:"大卫的儿子所罗门国位坚固；耶和华他的　神与他同在，使他甚为尊大。"},
{volumn:        14,chapter:         1,versenumber:         2,verse:"所罗门吩咐以色列众人，就是千夫长、百夫长、审判官、首领与族长都来。"},
{volumn:        14,chapter:         1,versenumber:         3,verse:"所罗门和会众都往基遍的邱坛去，因那里有　神的会幕，就是耶和华仆人摩西在旷野所制造的。"},
{volumn:        14,chapter:         1,versenumber:         4,verse:"只是　神的约柜，大卫已经从基列·耶琳搬到他所预备的地方，因他曾在耶路撒冷为约柜支搭了帐幕，"},
{volumn:        14,chapter:         1,versenumber:         5,verse:"并且户珥的孙子、乌利的儿子比撒列所造的铜坛也在基遍耶和华的会幕前。所罗门和会众都就近坛前。"},
{volumn:        14,chapter:         1,versenumber:         6,verse:"所罗门上到耶和华面前会幕的铜坛那里，献一千牺牲为燔祭。"},
{volumn:        14,chapter:         1,versenumber:         7,verse:"当夜，　神向所罗门显现，对他说：“你愿我赐你什么，你可以求。”"},
{volumn:        14,chapter:         1,versenumber:         8,verse:"所罗门对　神说：“你曾向我父大卫大施慈爱，使我接续他作王。"},
{volumn:        14,chapter:         1,versenumber:         9,verse:"耶和华　神啊，现在求你成就向我父大卫所应许的话；因你立我作这民的王，他们如同地上尘沙那样多。"},
{volumn:        14,chapter:         1,versenumber:        10,verse:"求你赐我智慧聪明，我好在这民前出入；不然，谁能判断这众多的民呢？”"},
{volumn:        14,chapter:         1,versenumber:        11,verse:"神对所罗门说：“我已立你作我民的王。你既有这心意，并不求资财、丰富、尊荣，也不求灭绝那恨你之人的性命，又不求大寿数，只求智慧聪明好判断我的民；"},
{volumn:        14,chapter:         1,versenumber:        12,verse:"我必赐你智慧聪明，也必赐你资财、丰富、尊荣。在你以前的列王都没有这样，在你以后也必没有这样的。”"},
{volumn:        14,chapter:         1,versenumber:        13,verse:"于是，所罗门从基遍邱坛会幕前回到耶路撒冷，治理以色列人。"},
{volumn:        14,chapter:         1,versenumber:        14,verse:"所罗门聚集战车马兵，有战车一千四百辆，马兵一万二千名，安置在屯车的城邑和耶路撒冷，就是王那里。"},
{volumn:        14,chapter:         1,versenumber:        15,verse:"王在耶路撒冷使金银多如石头，香柏木多如高原的桑树。"},
{volumn:        14,chapter:         1,versenumber:        16,verse:"所罗门的马是从埃及带来的，是王的商人一群一群按着定价买来的。"},
{volumn:        14,chapter:         1,versenumber:        17,verse:"他们从埃及买来的车，每辆价银六百舍客勒，马每匹一百五十舍客勒。赫人诸王和亚兰诸王所买的车马，也是按这价值经他们手买来的。"},
{volumn:        14,chapter:         2,versenumber:         1,verse:"所罗门定意要为耶和华的名建造殿宇，又为自己的国建造宫室。"},
{volumn:        14,chapter:         2,versenumber:         2,verse:"所罗门就挑选七万扛抬的，八万在山上凿石头的，三千六百督工的。"},
{volumn:        14,chapter:         2,versenumber:         3,verse:"所罗门差人去见推罗王希兰，说：“你曾运香柏木与我父大卫建宫居住，求你也这样待我。"},
{volumn:        14,chapter:         2,versenumber:         4,verse:"我要为耶和华我　神的名建造殿宇，分别为圣献给他，在他面前焚烧美香，常摆陈设饼，每早晚、安息日、月朔，并耶和华我们　神所定的节期献燔祭。这是以色列人永远的定例。"},
{volumn:        14,chapter:         2,versenumber:         5,verse:"我所要建造的殿宇甚大；因为我们的　神至大，超乎诸神。"},
{volumn:        14,chapter:         2,versenumber:         6,verse:"天和天上的天，尚且不足他居住的，谁能为他建造殿宇呢？我是谁？能为他建造殿宇吗？不过在他面前烧香而已。"},
{volumn:        14,chapter:         2,versenumber:         7,verse:"现在求你差一个巧匠来，就是善用金、银、铜、铁，和紫色、朱红色、蓝色线，并精于雕刻之工的巧匠，与我父大卫在犹大和耶路撒冷所预备的巧匠一同做工；"},
{volumn:        14,chapter:         2,versenumber:         8,verse:"又求你从黎巴嫩运些香柏木、松木、檀香木到我这里来，因我知道你的仆人善于砍伐黎巴嫩的树木。我的仆人也必与你的仆人同工。"},
{volumn:        14,chapter:         2,versenumber:         9,verse:"这样，可以给我预备许多的木料，因我要建造的殿宇高大出奇。"},
{volumn:        14,chapter:         2,versenumber:        10,verse:"你的仆人砍伐树木，我必给他们打好了的小麦二万歌珥，大麦二万歌珥，酒二万罢特，油二万罢特。”"},
{volumn:        14,chapter:         2,versenumber:        11,verse:"推罗王希兰写信回答所罗门说：“耶和华因为爱他的子民，所以立你作他们的王”；"},
{volumn:        14,chapter:         2,versenumber:        12,verse:"又说：“创造天地的耶和华以色列的　神是应当称颂的！他赐给大卫王一个有智慧的儿子，使他有谋略聪明，可以为耶和华建造殿宇，又为自己的国建造宫室。"},
{volumn:        14,chapter:         2,versenumber:        13,verse:"“现在我打发一个精巧有聪明的人去，他是我父亲希兰所用的，"},
{volumn:        14,chapter:         2,versenumber:        14,verse:"是但支派一个妇人的儿子。他父亲是推罗人，他善用金、银、铜、铁、石、木，和紫色、蓝色、朱红色线与细麻制造各物，并精于雕刻，又能想出各样的巧工。请你派定这人，与你的巧匠和你父我主大卫的巧匠一同做工。"},
{volumn:        14,chapter:         2,versenumber:        15,verse:"我主所说的小麦、大麦、酒、油，愿我主运来给众仆人。"},
{volumn:        14,chapter:         2,versenumber:        16,verse:"我们必照你所需用的，从黎巴嫩砍伐树木，扎成筏子，浮海运到约帕；你可以从那里运到耶路撒冷。”"},
{volumn:        14,chapter:         2,versenumber:        17,verse:"所罗门仿照他父大卫数点住在以色列地所有寄居的外邦人，共有十五万三千六百名；"},
{volumn:        14,chapter:         2,versenumber:        18,verse:"使七万人扛抬材料，八万人在山上凿石头，三千六百人督理工作。"},
{volumn:        14,chapter:         3,versenumber:         1,verse:"所罗门就在耶路撒冷、耶和华向他父大卫显现的摩利亚山上，就是耶布斯人阿珥楠的禾场上、大卫所指定的地方预备好了，开工建造耶和华的殿。"},
{volumn:        14,chapter:         3,versenumber:         2,verse:"所罗门作王第四年二月初二日开工建造。"},
{volumn:        14,chapter:         3,versenumber:         3,verse:"所罗门建筑　神殿的根基，乃是这样：长六十肘，宽二十肘，都按着古时的尺寸。"},
{volumn:        14,chapter:         3,versenumber:         4,verse:"殿前的廊子长二十肘，与殿的宽窄一样，高一百二十肘；里面贴上精金。"},
{volumn:        14,chapter:         3,versenumber:         5,verse:"大殿的墙都用松木板遮蔽，又贴了精金，上面雕刻棕树和链子；"},
{volumn:        14,chapter:         3,versenumber:         6,verse:"又用宝石装饰殿墙，使殿华美；所用的金子都是巴瓦音的金子。"},
{volumn:        14,chapter:         3,versenumber:         7,verse:"又用金子贴殿和殿的栋梁、门槛、墙壁、门扇；墙上雕刻基路伯。"},
{volumn:        14,chapter:         3,versenumber:         8,verse:"又建造至圣所，长二十肘，与殿的宽窄一样，宽也是二十肘；贴上精金，共用金子六百他连得。"},
{volumn:        14,chapter:         3,versenumber:         9,verse:"金钉重五十舍客勒。楼房都贴上金子。"},
{volumn:        14,chapter:         3,versenumber:        10,verse:"在至圣所按造像的法子造两个基路伯，用金子包裹。"},
{volumn:        14,chapter:         3,versenumber:        11,verse:"两个基路伯的翅膀共长二十肘。这基路伯的一个翅膀长五肘，挨着殿这边的墙；那一个翅膀也长五肘，与那基路伯翅膀相接。"},
{volumn:        14,chapter:         3,versenumber:        12,verse:"那基路伯的一个翅膀长五肘，挨着殿那边的墙；那一个翅膀也长五肘，与这基路伯的翅膀相接。"},
{volumn:        14,chapter:         3,versenumber:        13,verse:"两个基路伯张开翅膀，共长二十肘，面向外殿而立。"},
{volumn:        14,chapter:         3,versenumber:        14,verse:"又用蓝色、紫色、朱红色线和细麻织幔子，在其上绣出基路伯来。"},
{volumn:        14,chapter:         3,versenumber:        15,verse:"在殿前造了两根柱子，高三十五肘；每柱顶高五肘。"},
{volumn:        14,chapter:         3,versenumber:        16,verse:"又照圣所内链子的样式做链子，安在柱顶上；又做一百石榴，安在链子上。"},
{volumn:        14,chapter:         3,versenumber:        17,verse:"将两根柱子立在殿前，一根在右边，一根在左边；右边的起名叫雅斤，左边的起名叫波阿斯。"},
{volumn:        14,chapter:         4,versenumber:         1,verse:"他又制造一座铜坛，长二十肘，宽二十肘，高十肘；"},
{volumn:        14,chapter:         4,versenumber:         2,verse:"又铸一个铜海，样式是圆的，高五肘，径十肘，围三十肘；"},
{volumn:        14,chapter:         4,versenumber:         3,verse:"海周围有野瓜（野瓜：原文是牛）的样式，每肘十瓜，共有两行，是铸海的时候铸上的；"},
{volumn:        14,chapter:         4,versenumber:         4,verse:"有十二只铜牛驮海：三只向北，三只向西，三只向南，三只向东；海在牛上，牛尾向内；"},
{volumn:        14,chapter:         4,versenumber:         5,verse:"海厚一掌，边如杯边，又如百合花，可容三千罢特；"},
{volumn:        14,chapter:         4,versenumber:         6,verse:"又制造十个盆：五个放在右边，五个放在左边，献燔祭所用之物都洗在其内；但海是为祭司沐浴的。"},
{volumn:        14,chapter:         4,versenumber:         7,verse:"他又照所定的样式造十个金灯台放在殿里：五个在右边，五个在左边；"},
{volumn:        14,chapter:         4,versenumber:         8,verse:"又造十张桌子放在殿里：五张在右边，五张在左边；又造一百个金碗；"},
{volumn:        14,chapter:         4,versenumber:         9,verse:"又建立祭司院和大院，并院门，用铜包裹门扇；"},
{volumn:        14,chapter:         4,versenumber:        10,verse:"将海安在殿门的右边，就是南边。"},
{volumn:        14,chapter:         4,versenumber:        11,verse:"户兰又造了盆、铲、碗。这样，他为所罗门王做完了　神殿的工。"},
{volumn:        14,chapter:         4,versenumber:        12,verse:"所造的就是：两根柱子和柱上两个如球的顶，并两个盖柱顶的网子"},
{volumn:        14,chapter:         4,versenumber:        13,verse:"和四百石榴，安在两个网子上（每网两行盖着两个柱上如球的顶）。"},
{volumn:        14,chapter:         4,versenumber:        14,verse:"盆座和其上的盆，"},
{volumn:        14,chapter:         4,versenumber:        15,verse:"海和海下的十二只牛，"},
{volumn:        14,chapter:         4,versenumber:        16,verse:"盆、铲子、肉叉子，与耶和华殿里的一切器皿，都是巧匠户兰用光亮的铜为所罗门王造成的，"},
{volumn:        14,chapter:         4,versenumber:        17,verse:"是在约旦平原疏割和撒利但中间藉胶泥铸成的。"},
{volumn:        14,chapter:         4,versenumber:        18,verse:"所罗门制造的这一切甚多，铜的轻重无法可查。"},
{volumn:        14,chapter:         4,versenumber:        19,verse:"所罗门又造　神殿里的金坛和陈设饼的桌子，"},
{volumn:        14,chapter:         4,versenumber:        20,verse:"并精金的灯台和灯盏，可以照例点在内殿前。"},
{volumn:        14,chapter:         4,versenumber:        21,verse:"灯台上的花和灯盏，并蜡剪都是金的，且是纯金的；"},
{volumn:        14,chapter:         4,versenumber:        22,verse:"又用精金制造镊子、盘子、调羹、火鼎。至于殿门和至圣所的门扇，并殿的门扇，都是金子妆饰的。"},
{volumn:        14,chapter:         5,versenumber:         1,verse:"所罗门做完了耶和华殿的一切工，就把他父大卫分别为圣的金银和器皿都带来，放在　神殿的府库里。"},
{volumn:        14,chapter:         5,versenumber:         2,verse:"那时，所罗门将以色列的长老、各支派的首领，并以色列的族长招聚到耶路撒冷，要把耶和华的约柜从大卫城就是锡安运上来。"},
{volumn:        14,chapter:         5,versenumber:         3,verse:"于是以色列众人在七月节前都聚集到王那里。"},
{volumn:        14,chapter:         5,versenumber:         4,verse:"以色列众长老来到，利未人便抬起约柜。"},
{volumn:        14,chapter:         5,versenumber:         5,verse:"祭司利未人将约柜运上来，又将会幕和会幕的一切圣器具都带上来。"},
{volumn:        14,chapter:         5,versenumber:         6,verse:"所罗门王和聚集到他那里的以色列全会众都在约柜前献牛羊为祭，多得不可胜数。"},
{volumn:        14,chapter:         5,versenumber:         7,verse:"祭司将耶和华的约柜抬进内殿，就是至圣所，放在两个基路伯的翅膀底下。"},
{volumn:        14,chapter:         5,versenumber:         8,verse:"基路伯张着翅膀在约柜之上，遮掩约柜和抬柜的杠。"},
{volumn:        14,chapter:         5,versenumber:         9,verse:"这杠甚长，杠头在内殿前可以看见，在殿外却不能看见，直到如今还在那里。"},
{volumn:        14,chapter:         5,versenumber:        10,verse:"约柜里惟有两块石版，就是以色列人出埃及后，耶和华与他们立约的时候，摩西在何烈山所放的。除此以外，并无别物。"},
{volumn:        14,chapter:         5,versenumber:        11,verse:"当时，在那里所有的祭司都已自洁，并不分班供职。"},
{volumn:        14,chapter:         5,versenumber:        12,verse:"他们出圣所的时候，歌唱的利未人亚萨、希幔、耶杜顿，和他们的众子众弟兄都穿细麻布衣服，站在坛的东边，敲钹、鼓瑟、弹琴，同着他们有一百二十个祭司吹号。"},
{volumn:        14,chapter:         5,versenumber:        13,verse:"吹号的、歌唱的都一齐发声，声合为一，赞美感谢耶和华。吹号、敲钹，用各种乐器，扬声赞美耶和华说：“耶和华本为善，他的慈爱永远长存！”那时，耶和华的殿有云充满，"},
{volumn:        14,chapter:         5,versenumber:        14,verse:"甚至祭司不能站立供职，因为耶和华的荣光充满了　神的殿。"},
{volumn:        14,chapter:         6,versenumber:         1,verse:"那时，所罗门说：“耶和华曾说他必住在幽暗之处。"},
{volumn:        14,chapter:         6,versenumber:         2,verse:"但我已经建造殿宇作你的居所，为你永远的住处。”"},
{volumn:        14,chapter:         6,versenumber:         3,verse:"王转脸为以色列会众祝福，以色列会众就都站立。"},
{volumn:        14,chapter:         6,versenumber:         4,verse:"所罗门说：“耶和华以色列的　神是应当称颂的！因他亲口向我父大卫所应许的，也亲手成就了。"},
{volumn:        14,chapter:         6,versenumber:         5,verse:"他说：‘自从我领我民出埃及地以来，我未曾在以色列众支派中选择一城建造殿宇为我名的居所，也未曾拣选一人作我民以色列的君；"},
{volumn:        14,chapter:         6,versenumber:         6,verse:"但选择耶路撒冷为我名的居所，又拣选大卫治理我民以色列。’”"},
{volumn:        14,chapter:         6,versenumber:         7,verse:"所罗门说：“我父大卫曾立意要为耶和华以色列　神的名建殿，"},
{volumn:        14,chapter:         6,versenumber:         8,verse:"耶和华却对我父大卫说：‘你立意要为我的名建殿，这意思甚好；"},
{volumn:        14,chapter:         6,versenumber:         9,verse:"只是你不可建殿，惟你所生的儿子必为我名建殿。’"},
{volumn:        14,chapter:         6,versenumber:        10,verse:"“现在耶和华成就了他所应许的话，使我接续我父大卫坐以色列的国位，是照耶和华所说的，又为耶和华以色列　神的名建造了殿。"},
{volumn:        14,chapter:         6,versenumber:        11,verse:"我将约柜安置在其中，柜内有耶和华的约，就是他与以色列人所立的约。”"},
{volumn:        14,chapter:         6,versenumber:        12,verse:"所罗门当着以色列会众，站在耶和华的坛前，举起手来，"},
{volumn:        14,chapter:         6,versenumber:        13,verse:"（所罗门曾造一个铜台，长五肘，宽五肘，高三肘，放在院中）就站在台上，当着以色列的会众跪下，向天举手，"},
{volumn:        14,chapter:         6,versenumber:        14,verse:"说：“耶和华以色列的　神啊，天上地下没有神可比你的！你向那尽心行在你面前的仆人守约施慈爱；"},
{volumn:        14,chapter:         6,versenumber:        15,verse:"向你仆人我父大卫所应许的话现在应验了。你亲口应许，亲手成就，正如今日一样。"},
{volumn:        14,chapter:         6,versenumber:        16,verse:"耶和华以色列的　神啊，你所应许你仆人我父大卫的话说：‘你的子孙若谨慎自己的行为，遵守我的律法，像你在我面前所行的一样，就不断人坐以色列的国位。’现在求你应验这话。"},
{volumn:        14,chapter:         6,versenumber:        17,verse:"耶和华以色列的　神啊，求你成就向你仆人大卫所应许的话。"},
{volumn:        14,chapter:         6,versenumber:        18,verse:"“　神果真与世人同住在地上吗？看哪，天和天上的天尚且不足你居住的，何况我所建的这殿呢？"},
{volumn:        14,chapter:         6,versenumber:        19,verse:"惟求耶和华我的　神垂顾仆人的祷告祈求，俯听仆人在你面前的祈祷呼吁。"},
{volumn:        14,chapter:         6,versenumber:        20,verse:"愿你昼夜看顾这殿，就是你应许立为你名的居所；求你垂听仆人向此处祷告的话。"},
{volumn:        14,chapter:         6,versenumber:        21,verse:"你仆人和你民以色列向此处祈祷的时候，求你从天上你的居所垂听，垂听而赦免。"},
{volumn:        14,chapter:         6,versenumber:        22,verse:"“人若得罪邻舍，有人叫他起誓，他来到这殿，在你的坛前起誓，"},
{volumn:        14,chapter:         6,versenumber:        23,verse:"求你从天上垂听，判断你的仆人，定恶人有罪，照他所行的报应在他头上；定义人有理，照他的义赏赐他。"},
{volumn:        14,chapter:         6,versenumber:        24,verse:"“你的民以色列若得罪你，败在仇敌面前，又回心转意承认你的名，在这殿里向你祈求祷告，"},
{volumn:        14,chapter:         6,versenumber:        25,verse:"求你从天上垂听，赦免你民以色列的罪，使他们归回你赐给他们和他们列祖之地。"},
{volumn:        14,chapter:         6,versenumber:        26,verse:"“你的民因得罪你，你惩罚他们，使天闭塞不下雨，他们若向此处祷告，承认你的名，离开他们的罪，"},
{volumn:        14,chapter:         6,versenumber:        27,verse:"求你在天上垂听，赦免你仆人和你民以色列的罪，将当行的善道指教他们，且降雨在你的地，就是你赐给你民为业之地。"},
{volumn:        14,chapter:         6,versenumber:        28,verse:"“国中若有饥荒、瘟疫、旱风、霉烂、蝗虫、蚂蚱，或有仇敌犯境，围困城邑，无论遭遇什么灾祸疾病，"},
{volumn:        14,chapter:         6,versenumber:        29,verse:"你的民以色列，或是众人，或是一人，自觉灾祸甚苦，向这殿举手，无论祈求什么，祷告什么，"},
{volumn:        14,chapter:         6,versenumber:        30,verse:"求你从天上你的居所垂听赦免。你是知道人心的，要照各人所行的待他们（惟有你知道世人的心），"},
{volumn:        14,chapter:         6,versenumber:        31,verse:"使他们在你赐给我们列祖之地上一生一世敬畏你，遵行你的道。"},
{volumn:        14,chapter:         6,versenumber:        32,verse:"“论到不属你民以色列的外邦人，为你的大名和大能的手，并伸出来的膀臂，从远方而来，向这殿祷告，"},
{volumn:        14,chapter:         6,versenumber:        33,verse:"求你从天上你的居所垂听，照着外邦人所祈求的而行，使天下万民都认识你的名，敬畏你，像你的民以色列一样，又使他们知道我建造的这殿是称为你名下的。"},
{volumn:        14,chapter:         6,versenumber:        34,verse:"“你的民若奉你的差遣，无论往何处去与仇敌争战，向你所选择的城与我为你名所建造的殿祷告，"},
{volumn:        14,chapter:         6,versenumber:        35,verse:"求你从天上垂听他们的祷告祈求，使他们得胜。"},
{volumn:        14,chapter:         6,versenumber:        36,verse:"“你的民若得罪你（世上没有不犯罪的人），你向他们发怒，将他们交给仇敌掳到或远或近之地；"},
{volumn:        14,chapter:         6,versenumber:        37,verse:"他们若在掳到之地想起罪来，回心转意，恳求你说：‘我们有罪了，我们悖逆了，我们作恶了’；"},
{volumn:        14,chapter:         6,versenumber:        38,verse:"他们若在掳到之地尽心尽性归服你，又向自己的地，就是你赐给他们列祖之地和你所选择的城，并我为你名所建造的殿祷告，"},
{volumn:        14,chapter:         6,versenumber:        39,verse:"求你从天上你的居所垂听你民的祷告祈求，为他们伸冤，赦免他们的过犯。"},
{volumn:        14,chapter:         6,versenumber:        40,verse:"“我的　神啊，现在求你睁眼看、侧耳听在此处所献的祷告。"},
{volumn:        14,chapter:         6,versenumber:        41,verse:"耶和华　神啊，求你起来，和你有能力的约柜同入安息之所。耶和华　神啊，愿你的祭司披上救恩；愿你的圣民蒙福欢乐。"},
{volumn:        14,chapter:         6,versenumber:        42,verse:"耶和华　神啊，求你不要厌弃你的受膏者，要记念向你仆人大卫所施的慈爱。”"},
{volumn:        14,chapter:         7,versenumber:         1,verse:"所罗门祈祷已毕，就有火从天上降下来，烧尽燔祭和别的祭。耶和华的荣光充满了殿；"},
{volumn:        14,chapter:         7,versenumber:         2,verse:"因耶和华的荣光充满了耶和华殿，所以祭司不能进殿。"},
{volumn:        14,chapter:         7,versenumber:         3,verse:"那火降下、耶和华的荣光在殿上的时候，以色列众人看见，就在铺石地俯伏叩拜，称谢耶和华说：“耶和华本为善，他的慈爱永远长存！”"},
{volumn:        14,chapter:         7,versenumber:         4,verse:"王和众民在耶和华面前献祭。"},
{volumn:        14,chapter:         7,versenumber:         5,verse:"所罗门王用牛二万二千，羊十二万献祭。这样，王和众民为　神的殿行奉献之礼。"},
{volumn:        14,chapter:         7,versenumber:         6,verse:"祭司侍立，各供其职；利未人也拿着耶和华的乐器，就是大卫王造出来、藉利未人颂赞耶和华的。（他的慈爱永远长存！）祭司在众人面前吹号，以色列人都站立。"},
{volumn:        14,chapter:         7,versenumber:         7,verse:"所罗门因他所造的铜坛容不下燔祭、素祭，和脂油，便将耶和华殿前院子当中分别为圣，在那里献燔祭和平安祭牲的脂油。"},
{volumn:        14,chapter:         7,versenumber:         8,verse:"那时所罗门和以色列众人，就是从哈马口直到埃及小河，所有的以色列人都聚集成为大会，守节七日。"},
{volumn:        14,chapter:         7,versenumber:         9,verse:"第八日设立严肃会，行奉献坛的礼七日，守节七日。"},
{volumn:        14,chapter:         7,versenumber:        10,verse:"七月二十三日，王遣散众民；他们因见耶和华向大卫和所罗门与他民以色列所施的恩惠，就都心中喜乐，各归各家去了。"},
{volumn:        14,chapter:         7,versenumber:        11,verse:"所罗门造成了耶和华殿和王宫；在耶和华殿和王宫凡他心中所要做的，都顺顺利利地做成了。"},
{volumn:        14,chapter:         7,versenumber:        12,verse:"夜间耶和华向所罗门显现，对他说：“我已听了你的祷告，也选择这地方作为祭祀我的殿宇。"},
{volumn:        14,chapter:         7,versenumber:        13,verse:"我若使天闭塞不下雨，或使蝗虫吃这地的出产，或使瘟疫流行在我民中，"},
{volumn:        14,chapter:         7,versenumber:        14,verse:"这称为我名下的子民，若是自卑、祷告，寻求我的面，转离他们的恶行，我必从天上垂听，赦免他们的罪，医治他们的地。"},
{volumn:        14,chapter:         7,versenumber:        15,verse:"我必睁眼看、侧耳听在此处所献的祷告。"},
{volumn:        14,chapter:         7,versenumber:        16,verse:"现在我已选择这殿，分别为圣，使我的名永在其中，我的眼、我的心也必常在那里。"},
{volumn:        14,chapter:         7,versenumber:        17,verse:"你若在我面前效法你父大卫所行的，遵行我一切所吩咐你的，谨守我的律例典章，"},
{volumn:        14,chapter:         7,versenumber:        18,verse:"我就必坚固你的国位，正如我与你父大卫所立的约，说：‘你的子孙必不断人作以色列的王。’"},
{volumn:        14,chapter:         7,versenumber:        19,verse:"“倘若你们转去丢弃我指示你们的律例诫命，去侍奉敬拜别神，"},
{volumn:        14,chapter:         7,versenumber:        20,verse:"我就必将以色列人从我赐给他们的地上拔出根来，并且我为己名所分别为圣的殿也必舍弃不顾，使他在万民中作笑谈，被讥诮。"},
{volumn:        14,chapter:         7,versenumber:        21,verse:"这殿虽然甚高，将来经过的人必惊讶说：‘耶和华为何向这地和这殿如此行呢？’"},
{volumn:        14,chapter:         7,versenumber:        22,verse:"人必回答说：‘是因此地的人离弃耶和华他们列祖的　神，就是领他们出埃及地的　神，去亲近别神，敬拜侍奉他，所以耶和华使这一切灾祸临到他们。’”"},
{volumn:        14,chapter:         8,versenumber:         1,verse:"所罗门建造耶和华殿和王宫，二十年才完毕了。"},
{volumn:        14,chapter:         8,versenumber:         2,verse:"以后所罗门重新修筑希兰送给他的那些城邑，使以色列人住在那里。"},
{volumn:        14,chapter:         8,versenumber:         3,verse:"所罗门往哈马琐巴去，攻取了那地方。"},
{volumn:        14,chapter:         8,versenumber:         4,verse:"所罗门建造旷野里的达莫，又建造哈马所有的积货城，"},
{volumn:        14,chapter:         8,versenumber:         5,verse:"又建造上伯·和仑、下伯·和仑作为保障，都有墙，有门，有闩；"},
{volumn:        14,chapter:         8,versenumber:         6,verse:"又建造巴拉和所有的积货城，并屯车辆马兵的城，与耶路撒冷、黎巴嫩，以及自己治理的全国中所愿意建造的。"},
{volumn:        14,chapter:         8,versenumber:         7,verse:"至于国中所剩下不属以色列人的赫人、亚摩利人、比利洗人、希未人、耶布斯人，"},
{volumn:        14,chapter:         8,versenumber:         8,verse:"就是以色列人未曾灭绝的，所罗门挑取他们的后裔作服苦的奴仆，直到今日。"},
{volumn:        14,chapter:         8,versenumber:         9,verse:"惟有以色列人，所罗门不使他们当奴仆做工，乃是作他的战士、军长的统领、车兵长、马兵长。"},
{volumn:        14,chapter:         8,versenumber:        10,verse:"所罗门王有二百五十督工的，监管工人。"},
{volumn:        14,chapter:         8,versenumber:        11,verse:"所罗门将法老的女儿带出大卫城，上到为她建造的宫里；因所罗门说：“耶和华约柜所到之处都为圣地，所以我的妻不可住在以色列王大卫的宫里。”"},
{volumn:        14,chapter:         8,versenumber:        12,verse:"所罗门在耶和华的坛上，就是在廊子前他所筑的坛上，与耶和华献燔祭；"},
{volumn:        14,chapter:         8,versenumber:        13,verse:"又遵着摩西的吩咐在安息日、月朔，并一年三节，就是除酵节、七七节、住棚节，献每日所当献的祭。"},
{volumn:        14,chapter:         8,versenumber:        14,verse:"所罗门照着他父大卫所定的例，派定祭司的班次，使他们各供己事，又使利未人各尽其职，赞美耶和华，在祭司面前做每日所当做的；又派守门的按着班次看守各门，因为神人大卫是这样吩咐的。"},
{volumn:        14,chapter:         8,versenumber:        15,verse:"王所吩咐众祭司和利未人的，无论是管府库或办别的事，他们都不违背。"},
{volumn:        14,chapter:         8,versenumber:        16,verse:"所罗门建造耶和华的殿，从立根基直到成功的日子，工料俱备。这样，耶和华的殿全然完毕。"},
{volumn:        14,chapter:         8,versenumber:        17,verse:"那时，所罗门往以东地靠海的以旬·迦别和以禄去。"},
{volumn:        14,chapter:         8,versenumber:        18,verse:"希兰差遣他的臣仆，将船只和熟悉泛海的仆人送到所罗门那里。他们同着所罗门的仆人到了俄斐，得了四百五十他连得金子，运到所罗门王那里。"},
{volumn:        14,chapter:         9,versenumber:         1,verse:"示巴女王听见所罗门的名声，就来到耶路撒冷，要用难解的话试问所罗门；跟随她的人甚多，又有骆驼驮着香料、宝石，和许多金子。她来见了所罗门，就把心里所有的对所罗门都说出来。"},
{volumn:        14,chapter:         9,versenumber:         2,verse:"所罗门将她所问的都答上了，没有一句不明白、不能答的。"},
{volumn:        14,chapter:         9,versenumber:         3,verse:"示巴女王见所罗门的智慧和他所建造的宫室、"},
{volumn:        14,chapter:         9,versenumber:         4,verse:"席上的珍馐美味、群臣分列而坐、仆人两旁侍立，以及他们的衣服装饰、酒政，和酒政的衣服装饰，又见他上耶和华殿的台阶，就诧异得神不守舍，"},
{volumn:        14,chapter:         9,versenumber:         5,verse:"对王说：“我在本国里所听见论到你的事和你的智慧实在是真的！"},
{volumn:        14,chapter:         9,versenumber:         6,verse:"我先不信那些话，及至我来亲眼见了，才知道你的大智慧；人所告诉我的，还不到一半；你的实迹越过我所听见的名声。"},
{volumn:        14,chapter:         9,versenumber:         7,verse:"你的群臣、你的仆人常侍立在你面前听你智慧的话是有福的。"},
{volumn:        14,chapter:         9,versenumber:         8,verse:"耶和华你的　神是应当称颂的！他喜悦你，使你坐他的国位，为耶和华你的　神作王；因为你的　神爱以色列人，要永远坚立他们，所以立你作他们的王，使你秉公行义。”"},
{volumn:        14,chapter:         9,versenumber:         9,verse:"于是示巴女王将一百二十他连得金子和宝石，与极多的香料送给所罗门王；她送给王的香料，以后再没有这样的。"},
{volumn:        14,chapter:         9,versenumber:        10,verse:"希兰的仆人和所罗门的仆人从俄斐运了金子来，也运了檀香木（或译：乌木；下同）和宝石来。"},
{volumn:        14,chapter:         9,versenumber:        11,verse:"王用檀香木为耶和华殿和王宫做台，又为歌唱的人做琴瑟；犹大地从来没有见过这样的。"},
{volumn:        14,chapter:         9,versenumber:        12,verse:"所罗门王按示巴女王所带来的，还她礼物，另外照她一切所要所求的，都送给她。于是女王和她臣仆转回本国去了。"},
{volumn:        14,chapter:         9,versenumber:        13,verse:"所罗门每年所得的金子共有六百六十六他连得，"},
{volumn:        14,chapter:         9,versenumber:        14,verse:"另外还有商人所进的金子，并且阿拉伯诸王与属国的省长都带金银给所罗门。"},
{volumn:        14,chapter:         9,versenumber:        15,verse:"所罗门王用锤出来的金子打成挡牌二百面，每面用金子六百舍客勒；"},
{volumn:        14,chapter:         9,versenumber:        16,verse:"又用锤出来的金子打成盾牌三百面，每面用金子三百舍客勒，都放在黎巴嫩林宫里。"},
{volumn:        14,chapter:         9,versenumber:        17,verse:"王用象牙制造一个大宝座，用精金包裹。"},
{volumn:        14,chapter:         9,versenumber:        18,verse:"宝座有六层台阶，又有金脚凳，与宝座相连。宝座两旁有扶手，靠近扶手有两个狮子站立。"},
{volumn:        14,chapter:         9,versenumber:        19,verse:"六层台阶上有十二个狮子站立，每层有两个：左边一个，右边一个；在列国中没有这样做的。"},
{volumn:        14,chapter:         9,versenumber:        20,verse:"所罗门王一切的饮器都是金的，黎巴嫩林宫里的一切器皿都是精金的。所罗门年间，银子算不了什么。"},
{volumn:        14,chapter:         9,versenumber:        21,verse:"因为王的船只与希兰的仆人一同往他施去；他施船只三年一次装载金、银、象牙、猿猴、孔雀回来。"},
{volumn:        14,chapter:         9,versenumber:        22,verse:"所罗门王的财宝与智慧胜过天下的列王。"},
{volumn:        14,chapter:         9,versenumber:        23,verse:"普天下的王都求见所罗门，要听　神赐给他智慧的话。"},
{volumn:        14,chapter:         9,versenumber:        24,verse:"他们各带贡物，就是金器、银器、衣服、军械、香料、骡马，每年有一定之例。"},
{volumn:        14,chapter:         9,versenumber:        25,verse:"所罗门有套车的马四千棚，有马兵一万二千，安置在屯车的城邑和耶路撒冷，就是王那里。"},
{volumn:        14,chapter:         9,versenumber:        26,verse:"所罗门统管诸王，从大河到非利士地，直到埃及的边界。"},
{volumn:        14,chapter:         9,versenumber:        27,verse:"王在耶路撒冷使银子多如石头，香柏木多如高原的桑树。"},
{volumn:        14,chapter:         9,versenumber:        28,verse:"有人从埃及和各国为所罗门赶马群来。"},
{volumn:        14,chapter:         9,versenumber:        29,verse:"所罗门其余的事，自始至终，不都写在先知拿单的书上和示罗人亚希雅的预言书上，并先见易多论尼八儿子耶罗波安的默示书上吗？"},
{volumn:        14,chapter:         9,versenumber:        30,verse:"所罗门在耶路撒冷作以色列众人的王共四十年。"},
{volumn:        14,chapter:         9,versenumber:        31,verse:"所罗门与他列祖同睡，葬在他父大卫城里。他儿子罗波安接续他作王。"},
{volumn:        14,chapter:        10,versenumber:         1,verse:"罗波安往示剑去，因为以色列人都到了示剑，要立他作王。"},
{volumn:        14,chapter:        10,versenumber:         2,verse:"尼八的儿子耶罗波安先前躲避所罗门王，逃往埃及，住在那里；他听见这事，就从埃及回来。"},
{volumn:        14,chapter:        10,versenumber:         3,verse:"以色列人打发人去请他，他就和以色列众人来见罗波安，对他说："},
{volumn:        14,chapter:        10,versenumber:         4,verse:"“你父亲使我们负重轭做苦工，现在求你使我们做的苦工负的重轭轻松些，我们就侍奉你。”"},
{volumn:        14,chapter:        10,versenumber:         5,verse:"罗波安对他们说：“第三日再来见我吧！”民就去了。"},
{volumn:        14,chapter:        10,versenumber:         6,verse:"罗波安之父所罗门在世的日子，有侍立在他面前的老年人，罗波安王和他们商议，说：“你们给我出个什么主意，我好回复这民。”"},
{volumn:        14,chapter:        10,versenumber:         7,verse:"老年人对他说：“王若恩待这民，使他们喜悦，用好话回复他们，他们就永远作王的仆人。”"},
{volumn:        14,chapter:        10,versenumber:         8,verse:"王却不用老年人给他出的主意，就和那些与他一同长大、在他面前侍立的少年人商议，"},
{volumn:        14,chapter:        10,versenumber:         9,verse:"说：“这民对我说：‘你父亲使我们负重轭，求你使我们轻松些’；你们给我出个什么主意，我好回复他们。”"},
{volumn:        14,chapter:        10,versenumber:        10,verse:"那同他长大的少年人说：“这民对王说：‘你父亲使我们负重轭，求你使我们轻松些’；王要对他们如此说：‘我的小拇指比我父亲的腰还粗；"},
{volumn:        14,chapter:        10,versenumber:        11,verse:"我父亲使你们负重轭，我必使你们负更重的轭；我父亲用鞭子责打你们，我要用蝎子鞭责打你们。’”"},
{volumn:        14,chapter:        10,versenumber:        12,verse:"耶罗波安和众百姓遵着罗波安王所说“你们第三日再来见我”的那话，第三日他们果然来了。"},
{volumn:        14,chapter:        10,versenumber:        13,verse:"罗波安王用严厉的话回复他们，不用老年人所出的主意，"},
{volumn:        14,chapter:        10,versenumber:        14,verse:"照着少年人所出的主意对他们说：“我父亲使你们负重轭，我必使你们负更重的轭；我父亲用鞭子责打你们，我要用蝎子鞭责打你们。”"},
{volumn:        14,chapter:        10,versenumber:        15,verse:"王不肯依从百姓；这事乃出于　神，为要应验耶和华藉示罗人亚希雅对尼八儿子耶罗波安所说的话。"},
{volumn:        14,chapter:        10,versenumber:        16,verse:"以色列众民见王不依从他们，就对王说：“我们与大卫有什么份儿呢？与耶西的儿子并没有关涉！以色列人哪，各回各家去吧！大卫家啊，自己顾自己吧！”于是，以色列众人都回自己家里去了。"},
{volumn:        14,chapter:        10,versenumber:        17,verse:"惟独住在犹大城邑的以色列人，罗波安仍作他们的王。"},
{volumn:        14,chapter:        10,versenumber:        18,verse:"罗波安王差遣掌管服苦之人的哈多兰往以色列人那里去，以色列人就用石头打死他。罗波安王急忙上车，逃回耶路撒冷去了。"},
{volumn:        14,chapter:        10,versenumber:        19,verse:"这样，以色列人背叛大卫家，直到今日。"},
{volumn:        14,chapter:        11,versenumber:         1,verse:"罗波安来到耶路撒冷，招聚犹大家和便雅悯家，共十八万人，都是挑选的战士，要与以色列人争战，好将国夺回再归自己。"},
{volumn:        14,chapter:        11,versenumber:         2,verse:"但耶和华的话临到神人示玛雅说："},
{volumn:        14,chapter:        11,versenumber:         3,verse:"“你去告诉所罗门的儿子犹大王罗波安和住犹大、便雅悯的以色列众人说，"},
{volumn:        14,chapter:        11,versenumber:         4,verse:"耶和华如此说：‘你们不可上去与你们的弟兄争战，各归各家去吧！因为这事出于我。’”众人就听从耶和华的话归回，不去与耶罗波安争战。"},
{volumn:        14,chapter:        11,versenumber:         5,verse:"罗波安住在耶路撒冷，在犹大地修筑城邑，"},
{volumn:        14,chapter:        11,versenumber:         6,verse:"为保障修筑伯利恒、以坦、提哥亚、"},
{volumn:        14,chapter:        11,versenumber:         7,verse:"伯·夙、梭哥、亚杜兰、"},
{volumn:        14,chapter:        11,versenumber:         8,verse:"迦特、玛利沙、西弗、"},
{volumn:        14,chapter:        11,versenumber:         9,verse:"亚多莱音、拉吉、亚西加、"},
{volumn:        14,chapter:        11,versenumber:        10,verse:"琐拉、亚雅仑、希伯仑。这都是犹大和便雅悯的坚固城。"},
{volumn:        14,chapter:        11,versenumber:        11,verse:"罗波安又坚固各处的保障，在其中安置军长，又预备下粮食、油、酒。"},
{volumn:        14,chapter:        11,versenumber:        12,verse:"他在各城里预备盾牌和枪，且使城极其坚固。犹大和便雅悯都归了他。"},
{volumn:        14,chapter:        11,versenumber:        13,verse:"以色列全地的祭司和利未人都从四方来归罗波安。"},
{volumn:        14,chapter:        11,versenumber:        14,verse:"利未人撇下他们的郊野和产业，来到犹大与耶路撒冷，是因耶罗波安和他的儿子拒绝他们，不许他们供祭司职分侍奉耶和华。"},
{volumn:        14,chapter:        11,versenumber:        15,verse:"耶罗波安为邱坛、为鬼魔（原文是公山羊）、为自己所铸造的牛犊设立祭司。"},
{volumn:        14,chapter:        11,versenumber:        16,verse:"以色列各支派中，凡立定心意寻求耶和华以色列　神的，都随从利未人，来到耶路撒冷祭祀耶和华他们列祖的　神。"},
{volumn:        14,chapter:        11,versenumber:        17,verse:"这样，就坚固犹大国，使所罗门的儿子罗波安强盛三年，因为他们三年遵行大卫和所罗门的道。"},
{volumn:        14,chapter:        11,versenumber:        18,verse:"罗波安娶大卫儿子耶利摩的女儿玛哈拉为妻，又娶耶西儿子以利押的女儿亚比孩为妻。"},
{volumn:        14,chapter:        11,versenumber:        19,verse:"从她生了几个儿子，就是耶乌施、示玛利雅、撒罕。"},
{volumn:        14,chapter:        11,versenumber:        20,verse:"后来又娶押沙龙的女儿玛迦（十三章二节是乌列的女儿米该雅），从她生了亚比雅、亚太、细撒、示罗密。"},
{volumn:        14,chapter:        11,versenumber:        21,verse:"罗波安娶十八个妻，立六十个妾，生二十八个儿子，六十个女儿；他却爱押沙龙的女儿玛迦，比爱别的妻妾更甚。"},
{volumn:        14,chapter:        11,versenumber:        22,verse:"罗波安立玛迦的儿子亚比雅作太子，在他弟兄中为首，因为想要立他接续作王。"},
{volumn:        14,chapter:        11,versenumber:        23,verse:"罗波安办事精明，使他众子分散在犹大和便雅悯全地各坚固城里，又赐他们许多粮食，为他们多寻妻子。"},
{volumn:        14,chapter:        12,versenumber:         1,verse:"罗波安的国坚立，他强盛的时候就离弃耶和华的律法，以色列人也都随从他。"},
{volumn:        14,chapter:        12,versenumber:         2,verse:"罗波安王第五年，埃及王示撒上来攻打耶路撒冷，因为王和民得罪了耶和华。"},
{volumn:        14,chapter:        12,versenumber:         3,verse:"示撒带战车一千二百辆，马兵六万，并且跟从他出埃及的路比人、苏基人，和古实人，多得不可胜数。"},
{volumn:        14,chapter:        12,versenumber:         4,verse:"他攻取了犹大的坚固城，就来到耶路撒冷。"},
{volumn:        14,chapter:        12,versenumber:         5,verse:"那时，犹大的首领因为示撒就聚集在耶路撒冷。有先知示玛雅去见罗波安和众首领，对他们说：“耶和华如此说：‘你们离弃了我，所以我使你们落在示撒手里。’”"},
{volumn:        14,chapter:        12,versenumber:         6,verse:"于是王和以色列的众首领都自卑说：“耶和华是公义的。”"},
{volumn:        14,chapter:        12,versenumber:         7,verse:"耶和华见他们自卑，耶和华的话就临到示玛雅说：“他们既自卑，我必不灭绝他们；必使他们略得拯救，我不藉着示撒的手将我的怒气倒在耶路撒冷。"},
{volumn:        14,chapter:        12,versenumber:         8,verse:"然而他们必作示撒的仆人，好叫他们知道，服侍我与服侍外邦人有何分别。”"},
{volumn:        14,chapter:        12,versenumber:         9,verse:"于是，埃及王示撒上来攻取耶路撒冷，夺了耶和华殿和王宫里的宝物，尽都带走，又夺去所罗门制造的金盾牌。"},
{volumn:        14,chapter:        12,versenumber:        10,verse:"罗波安王制造铜盾牌代替那金盾牌，交给守王宫门的护卫长看守。"},
{volumn:        14,chapter:        12,versenumber:        11,verse:"王每逢进耶和华的殿，护卫兵就拿这盾牌，随后仍将盾牌送回，放在护卫房。"},
{volumn:        14,chapter:        12,versenumber:        12,verse:"王自卑的时候，耶和华的怒气就转消了，不将他灭尽，并且在犹大中间也有善益的事。"},
{volumn:        14,chapter:        12,versenumber:        13,verse:"罗波安王自强，在耶路撒冷作王。他登基的时候年四十一岁，在耶路撒冷，就是耶和华从以色列众支派中所选择立他名的城，作王十七年。罗波安的母亲名叫拿玛，是亚扪人。"},
{volumn:        14,chapter:        12,versenumber:        14,verse:"罗波安行恶，因他不立定心意寻求耶和华。"},
{volumn:        14,chapter:        12,versenumber:        15,verse:"罗波安所行的事，自始至终不都写在先知示玛雅和先见易多的史记上吗？罗波安与耶罗波安时常争战。"},
{volumn:        14,chapter:        12,versenumber:        16,verse:"罗波安与他列祖同睡，葬在大卫城里。他儿子亚比雅接续他作王。"},
{volumn:        14,chapter:        13,versenumber:         1,verse:"耶罗波安王十八年，亚比雅登基作犹大王，"},
{volumn:        14,chapter:        13,versenumber:         2,verse:"在耶路撒冷作王三年。他母亲名叫米该亚（又作玛迦），是基比亚人乌列的女儿。亚比雅常与耶罗波安争战。"},
{volumn:        14,chapter:        13,versenumber:         3,verse:"有一次亚比雅率领挑选的兵四十万摆阵，都是勇敢的战士；耶罗波安也挑选大能的勇士八十万，对亚比雅摆阵。"},
{volumn:        14,chapter:        13,versenumber:         4,verse:"亚比雅站在以法莲山地中的洗玛脸山上，说：“耶罗波安和以色列众人哪，要听我说！"},
{volumn:        14,chapter:        13,versenumber:         5,verse:"耶和华以色列的　神曾立盐约（盐就是不废坏的意思），将以色列国永远赐给大卫和他的子孙，你们不知道吗？"},
{volumn:        14,chapter:        13,versenumber:         6,verse:"无奈大卫儿子所罗门的臣仆、尼八儿子耶罗波安起来背叛他的主人。"},
{volumn:        14,chapter:        13,versenumber:         7,verse:"有些无赖的匪徒聚集跟从他，逞强攻击所罗门的儿子罗波安；那时罗波安还幼弱，不能抵挡他们。"},
{volumn:        14,chapter:        13,versenumber:         8,verse:"“现在你们有意抗拒大卫子孙手下所治耶和华的国，你们的人也甚多，你们那里又有耶罗波安为你们所造当作神的金牛犊。"},
{volumn:        14,chapter:        13,versenumber:         9,verse:"你们不是驱逐耶和华的祭司亚伦的后裔和利未人吗？不是照着外邦人的恶俗为自己立祭司吗？无论何人牵一只公牛犊、七只公绵羊将自己分别出来，就可作虚无之神的祭司。"},
{volumn:        14,chapter:        13,versenumber:        10,verse:"至于我们，耶和华是我们的　神，我们并没有离弃他。我们有侍奉耶和华的祭司，都是亚伦的后裔，并有利未人各尽其职，"},
{volumn:        14,chapter:        13,versenumber:        11,verse:"每日早晚向耶和华献燔祭，烧美香，又在精金的桌子上摆陈设饼；又有金灯台和灯盏，每晚点起，因为我们遵守耶和华我们　神的命；惟有你们离弃了他。"},
{volumn:        14,chapter:        13,versenumber:        12,verse:"率领我们的是　神，我们这里也有　神的祭司拿号向你们吹出大声。以色列人哪，不要与耶和华你们列祖的　神争战，因你们必不能亨通。”"},
{volumn:        14,chapter:        13,versenumber:        13,verse:"耶罗波安却在犹大人的后头设伏兵。这样，以色列人在犹大人的前头，伏兵在犹大人的后头。"},
{volumn:        14,chapter:        13,versenumber:        14,verse:"犹大人回头观看，见前后都有敌兵，就呼求耶和华，祭司也吹号。"},
{volumn:        14,chapter:        13,versenumber:        15,verse:"于是犹大人呐喊；犹大人呐喊的时候，　神就使耶罗波安和以色列众人败在亚比雅与犹大人面前。"},
{volumn:        14,chapter:        13,versenumber:        16,verse:"以色列人在犹大人面前逃跑，　神将他们交在犹大人手里。"},
{volumn:        14,chapter:        13,versenumber:        17,verse:"亚比雅和他的军兵大大杀戮以色列人，以色列人仆倒死亡的精兵有五十万。"},
{volumn:        14,chapter:        13,versenumber:        18,verse:"那时，以色列人被制伏了，犹大人得胜，是因倚靠耶和华他们列祖的　神。"},
{volumn:        14,chapter:        13,versenumber:        19,verse:"亚比雅追赶耶罗波安，攻取了他的几座城，就是伯特利和属伯特利的镇市，耶沙拿和属耶沙拿的镇市，以法拉音（或译：以弗伦）和属以法拉音的镇市。"},
{volumn:        14,chapter:        13,versenumber:        20,verse:"亚比雅在世的时候，耶罗波安不能再强盛；耶和华攻击他，他就死了。"},
{volumn:        14,chapter:        13,versenumber:        21,verse:"亚比雅却渐渐强盛，娶妻妾十四个，生了二十二个儿子，十六个女儿。"},
{volumn:        14,chapter:        13,versenumber:        22,verse:"亚比雅其余的事和他的言行都写在先知易多的传上。"},
{volumn:        14,chapter:        14,versenumber:         1,verse:"亚比雅与他列祖同睡，葬在大卫城里。他儿子亚撒接续他作王。亚撒年间，国中太平十年。"},
{volumn:        14,chapter:        14,versenumber:         2,verse:"亚撒行耶和华他　神眼中看为善为正的事，"},
{volumn:        14,chapter:        14,versenumber:         3,verse:"除掉外邦神的坛和邱坛，打碎柱像，砍下木偶，"},
{volumn:        14,chapter:        14,versenumber:         4,verse:"吩咐犹大人寻求耶和华他们列祖的　神，遵行他的律法、诫命；"},
{volumn:        14,chapter:        14,versenumber:         5,verse:"又在犹大各城邑除掉邱坛和日像，那时国享太平；"},
{volumn:        14,chapter:        14,versenumber:         6,verse:"又在犹大建造了几座坚固城。国中太平数年，没有战争，因为耶和华赐他平安。"},
{volumn:        14,chapter:        14,versenumber:         7,verse:"他对犹大人说：“我们要建造这些城邑，四围筑墙，盖楼，安门，做闩；地还属我们，是因寻求耶和华我们的　神；我们既寻求他，他就赐我们四境平安。”于是建造城邑，诸事亨通。"},
{volumn:        14,chapter:        14,versenumber:         8,verse:"亚撒的军兵，出自犹大拿盾牌拿枪的三十万人；出自便雅悯拿盾牌拉弓的二十八万人。这都是大能的勇士。"},
{volumn:        14,chapter:        14,versenumber:         9,verse:"有古实王谢拉率领军兵一百万，战车三百辆，出来攻击犹大人，到了玛利沙。"},
{volumn:        14,chapter:        14,versenumber:        10,verse:"于是亚撒出去与他迎敌，就在玛利沙的洗法谷彼此摆阵。"},
{volumn:        14,chapter:        14,versenumber:        11,verse:"亚撒呼求耶和华他的　神说：“耶和华啊，惟有你能帮助软弱的，胜过强盛的。耶和华我们的　神啊，求你帮助我们；因为我们仰赖你，奉你的名来攻击这大军。耶和华啊，你是我们的　神，不要容人胜过你。”"},
{volumn:        14,chapter:        14,versenumber:        12,verse:"于是耶和华使古实人败在亚撒和犹大人面前，古实人就逃跑了；"},
{volumn:        14,chapter:        14,versenumber:        13,verse:"亚撒和跟随他的军兵追赶他们，直到基拉耳。古实人被杀的甚多，不能再强盛，因为败在耶和华与他军兵面前。犹大人就夺了许多财物，"},
{volumn:        14,chapter:        14,versenumber:        14,verse:"又打破基拉耳四围的城邑；耶和华使其中的人都甚恐惧。犹大人又将所有的城掳掠一空，因其中的财物甚多，"},
{volumn:        14,chapter:        14,versenumber:        15,verse:"又毁坏了群畜的圈，夺取许多的羊和骆驼，就回耶路撒冷去了。"},
{volumn:        14,chapter:        15,versenumber:         1,verse:"神的灵感动俄德的儿子亚撒利雅。"},
{volumn:        14,chapter:        15,versenumber:         2,verse:"他出来迎接亚撒，对他说：“亚撒和犹大、便雅悯众人哪，要听我说：你们若顺从耶和华，耶和华必与你们同在；你们若寻求他，就必寻见；你们若离弃他，他必离弃你们。"},
{volumn:        14,chapter:        15,versenumber:         3,verse:"以色列人不信真　神，没有训诲的祭司，也没有律法，已经好久了；"},
{volumn:        14,chapter:        15,versenumber:         4,verse:"但他们在急难的时候归向耶和华以色列的　神，寻求他，他就被他们寻见。"},
{volumn:        14,chapter:        15,versenumber:         5,verse:"那时，出入的人不得平安，列国的居民都遭大乱；"},
{volumn:        14,chapter:        15,versenumber:         6,verse:"这国攻击那国，这城攻击那城，互相破坏，因为　神用各样灾难扰乱他们。"},
{volumn:        14,chapter:        15,versenumber:         7,verse:"现在你们要刚强，不要手软，因你们所行的必得赏赐。”"},
{volumn:        14,chapter:        15,versenumber:         8,verse:"亚撒听见这话和俄德儿子先知亚撒利雅的预言，就壮起胆来，在犹大、便雅悯全地，并以法莲山地所夺的各城，将可憎之物尽都除掉，又在耶和华殿的廊前重新修筑耶和华的坛；"},
{volumn:        14,chapter:        15,versenumber:         9,verse:"又招聚犹大、便雅悯的众人，并他们中间寄居的以法莲人、玛拿西人、西缅人。有许多以色列人归降亚撒，因见耶和华他的　神与他同在。"},
{volumn:        14,chapter:        15,versenumber:        10,verse:"亚撒十五年三月，他们都聚集在耶路撒冷。"},
{volumn:        14,chapter:        15,versenumber:        11,verse:"当日他们从所取的掳物中，将牛七百只、羊七千只献给耶和华。"},
{volumn:        14,chapter:        15,versenumber:        12,verse:"他们就立约，要尽心尽性地寻求耶和华他们列祖的　神。"},
{volumn:        14,chapter:        15,versenumber:        13,verse:"凡不寻求耶和华以色列　神的，无论大小、男女，必被治死。"},
{volumn:        14,chapter:        15,versenumber:        14,verse:"他们就大声欢呼，吹号吹角，向耶和华起誓。"},
{volumn:        14,chapter:        15,versenumber:        15,verse:"犹大众人为所起的誓欢喜；因他们是尽心起誓，尽意寻求耶和华，耶和华就被他们寻见，且赐他们四境平安。"},
{volumn:        14,chapter:        15,versenumber:        16,verse:"亚撒王贬了他祖母玛迦太后的位，因她造了可憎的偶像亚舍拉。亚撒砍下她的偶像，捣得粉碎，烧在汲沦溪边。"},
{volumn:        14,chapter:        15,versenumber:        17,verse:"只是邱坛还没有从以色列中废去，然而亚撒的心一生诚实。"},
{volumn:        14,chapter:        15,versenumber:        18,verse:"亚撒将他父所分别为圣、与自己所分别为圣的金银和器皿都奉到　神的殿里。"},
{volumn:        14,chapter:        15,versenumber:        19,verse:"从这时直到亚撒三十五年，都没有争战的事。"},
{volumn:        14,chapter:        16,versenumber:         1,verse:"亚撒三十六年，以色列王巴沙上来攻击犹大，修筑拉玛，不许人从犹大王亚撒那里出入。"},
{volumn:        14,chapter:        16,versenumber:         2,verse:"于是亚撒从耶和华殿和王宫的府库里拿出金银来，送与住大马士革的亚兰王便哈达，说："},
{volumn:        14,chapter:        16,versenumber:         3,verse:"“你父曾与我父立约，我与你也要立约。现在我将金银送给你，求你废掉你与以色列王巴沙所立的约，使他离开我。”"},
{volumn:        14,chapter:        16,versenumber:         4,verse:"便哈达听从亚撒王的话，派军长去攻击以色列的城邑。他们就攻破以云、但、亚伯·玛音，和拿弗他利一切的积货城。"},
{volumn:        14,chapter:        16,versenumber:         5,verse:"巴沙听见就停工，不修筑拉玛了。"},
{volumn:        14,chapter:        16,versenumber:         6,verse:"于是亚撒王率领犹大众人，将巴沙修筑拉玛所用的石头、木头都运去，用以修筑迦巴和米斯巴。"},
{volumn:        14,chapter:        16,versenumber:         7,verse:"那时，先见哈拿尼来见犹大王亚撒，对他说：“因你仰赖亚兰王，没有仰赖耶和华你的　神，所以亚兰王的军兵脱离了你的手。"},
{volumn:        14,chapter:        16,versenumber:         8,verse:"古实人、路比人的军队不是甚大吗？战车马兵不是极多吗？只因你仰赖耶和华，他便将他们交在你手里。"},
{volumn:        14,chapter:        16,versenumber:         9,verse:"耶和华的眼目遍察全地，要显大能帮助向他心存诚实的人。你这事行得愚昧；此后，你必有争战的事。”"},
{volumn:        14,chapter:        16,versenumber:        10,verse:"亚撒因此恼恨先见，将他囚在监里。那时亚撒也虐待一些人民。"},
{volumn:        14,chapter:        16,versenumber:        11,verse:"亚撒所行的事，自始至终都写在犹大和以色列诸王记上。"},
{volumn:        14,chapter:        16,versenumber:        12,verse:"亚撒作王三十九年，他脚上有病，而且甚重。病的时候没有求耶和华，只求医生。"},
{volumn:        14,chapter:        16,versenumber:        13,verse:"他作王四十一年而死，与他列祖同睡，"},
{volumn:        14,chapter:        16,versenumber:        14,verse:"葬在大卫城自己所凿的坟墓里，放在床上，其床堆满各样馨香的香料，就是按做香的作法调和的香料，又为他烧了许多的物件。"},
{volumn:        14,chapter:        17,versenumber:         1,verse:"亚撒的儿子约沙法接续他作王，奋勇自强，防备以色列人，"},
{volumn:        14,chapter:        17,versenumber:         2,verse:"安置军兵在犹大一切坚固城里，又安置防兵在犹大地和他父亚撒所得以法莲的城邑中。"},
{volumn:        14,chapter:        17,versenumber:         3,verse:"耶和华与约沙法同在；因为他行他祖大卫初行的道，不寻求巴力，"},
{volumn:        14,chapter:        17,versenumber:         4,verse:"只寻求他父亲的　神，遵行他的诫命，不效法以色列人的行为。"},
{volumn:        14,chapter:        17,versenumber:         5,verse:"所以耶和华坚定他的国，犹大众人给他进贡；约沙法大有尊荣资财。"},
{volumn:        14,chapter:        17,versenumber:         6,verse:"他高兴遵行耶和华的道，并且从犹大除掉一切邱坛和木偶。"},
{volumn:        14,chapter:        17,versenumber:         7,verse:"他作王第三年，就差遣臣子便亥伊勒、俄巴底、撒迦利雅、拿坦业、米该亚往犹大各城去教训百姓。"},
{volumn:        14,chapter:        17,versenumber:         8,verse:"同着他们有利未人示玛雅、尼探雅、西巴第雅、亚撒黑、示米拉末、约拿单、亚多尼雅、多比雅、驼巴多尼雅，又有祭司以利沙玛、约兰同着他们。"},
{volumn:        14,chapter:        17,versenumber:         9,verse:"他们带着耶和华的律法书，走遍犹大各城教训百姓。"},
{volumn:        14,chapter:        17,versenumber:        10,verse:"耶和华使犹大四围的列国都甚恐惧，不敢与约沙法争战。"},
{volumn:        14,chapter:        17,versenumber:        11,verse:"有些非利士人与约沙法送礼物，纳贡银。阿拉伯人也送他公绵羊七千七百只，公山羊七千七百只。"},
{volumn:        14,chapter:        17,versenumber:        12,verse:"约沙法日渐强大，在犹大建造营寨和积货城。"},
{volumn:        14,chapter:        17,versenumber:        13,verse:"他在犹大城邑中有许多工程，又在耶路撒冷有战士，就是大能的勇士。"},
{volumn:        14,chapter:        17,versenumber:        14,verse:"他们的数目，按着宗族，记在下面：犹大族的，千夫长押拿为首率领大能的勇士三十万；"},
{volumn:        14,chapter:        17,versenumber:        15,verse:"其次是，千夫长约哈难率领大能的勇士二十八万；"},
{volumn:        14,chapter:        17,versenumber:        16,verse:"其次是，细基利的儿子亚玛斯雅（他为耶和华牺牲自己）率领大能的勇士二十万。"},
{volumn:        14,chapter:        17,versenumber:        17,verse:"便雅悯族，是大能的勇士以利雅大率领拿弓箭和盾牌的二十万；"},
{volumn:        14,chapter:        17,versenumber:        18,verse:"其次是，约萨拔率领预备打仗的十八万。"},
{volumn:        14,chapter:        17,versenumber:        19,verse:"这都是伺候王的，还有王在犹大全地坚固城所安置的不在其内。"},
{volumn:        14,chapter:        18,versenumber:         1,verse:"约沙法大有尊荣资财，就与亚哈结亲。"},
{volumn:        14,chapter:        18,versenumber:         2,verse:"过了几年，他下到撒玛利亚去见亚哈；亚哈为他和跟从他的人宰了许多牛羊，劝他与自己同去攻取基列的拉末。"},
{volumn:        14,chapter:        18,versenumber:         3,verse:"以色列王亚哈问犹大王约沙法说：“你肯同我去攻取基列的拉末吗？”他回答说：“你我不分彼此，我的民与你的民一样，必与你同去争战。”"},
{volumn:        14,chapter:        18,versenumber:         4,verse:"约沙法对以色列王说：“请你先求问耶和华。”"},
{volumn:        14,chapter:        18,versenumber:         5,verse:"于是以色列王招聚先知四百人，问他们说：“我们上去攻取基列的拉末可以不可以？”他们说：“可以上去，因为　神必将那城交在王的手里。”"},
{volumn:        14,chapter:        18,versenumber:         6,verse:"约沙法说：“这里不是还有耶和华的先知，我们可以求问他吗？”"},
{volumn:        14,chapter:        18,versenumber:         7,verse:"以色列王对约沙法说：“还有一个人，是音拉的儿子米该雅。我们可以托他求问耶和华，只是我恨他；因为他指着我所说的预言，不说吉语，常说凶言。”约沙法说：“王不必这样说。”"},
{volumn:        14,chapter:        18,versenumber:         8,verse:"以色列王就召了一个太监来，说：“你快去将音拉的儿子米该雅召来。”"},
{volumn:        14,chapter:        18,versenumber:         9,verse:"以色列王和犹大王约沙法在撒玛利亚城门前的空场上，各穿朝服坐在位上，所有的先知都在他们面前说预言。"},
{volumn:        14,chapter:        18,versenumber:        10,verse:"基拿拿的儿子西底家造了两个铁角，说：“耶和华如此说：‘你要用这角抵触亚兰人，直到将他们灭尽。’”"},
{volumn:        14,chapter:        18,versenumber:        11,verse:"所有的先知也都这样预言说：“可以上基列的拉末去，必然得胜，因为耶和华必将那城交在王的手中。”"},
{volumn:        14,chapter:        18,versenumber:        12,verse:"那去召米该雅的使者对米该雅说：“众先知一口同音地都向王说吉言，你不如与他们说一样的话，也说吉言。”"},
{volumn:        14,chapter:        18,versenumber:        13,verse:"米该雅说：“我指着永生的耶和华起誓，我的　神说什么，我就说什么。”"},
{volumn:        14,chapter:        18,versenumber:        14,verse:"米该雅到王面前，王问他说：“米该雅啊，我们上去攻取基列的拉末可以不可以？”他说：“可以上去，必然得胜，敌人必交在你们手里。”"},
{volumn:        14,chapter:        18,versenumber:        15,verse:"王对他说：“我当嘱咐你几次，你才奉耶和华的名向我说实话呢？”"},
{volumn:        14,chapter:        18,versenumber:        16,verse:"米该雅说：“我看见以色列众民散在山上，如同没有牧人的羊群一般。耶和华说：‘这民没有主人，他们可以平平安安地各归各家去。’”"},
{volumn:        14,chapter:        18,versenumber:        17,verse:"以色列王对约沙法说：“我岂没有告诉你，这人指着我所说的预言，不说吉语，单说凶言吗？”"},
{volumn:        14,chapter:        18,versenumber:        18,verse:"米该雅说：“你们要听耶和华的话。我看见耶和华坐在宝座上，天上的万军侍立在他左右。"},
{volumn:        14,chapter:        18,versenumber:        19,verse:"耶和华说：‘谁去引诱以色列王亚哈上基列的拉末去阵亡呢？’这个就这样说，那个就那样说。"},
{volumn:        14,chapter:        18,versenumber:        20,verse:"随后，有一个神灵出来，站在耶和华面前说：‘我去引诱他。’耶和华问他说：‘你用何法呢？’"},
{volumn:        14,chapter:        18,versenumber:        21,verse:"他说：‘我去，要在他众先知口中作谎言的灵。’耶和华说：‘这样，你必能引诱他，你去如此行吧！’"},
{volumn:        14,chapter:        18,versenumber:        22,verse:"现在耶和华使谎言的灵入了你这些先知的口，并且耶和华已经命定降祸与你。”"},
{volumn:        14,chapter:        18,versenumber:        23,verse:"基拿拿的儿子西底家前来打米该雅的脸，说：“耶和华的灵从哪里离开我与你说话呢？”"},
{volumn:        14,chapter:        18,versenumber:        24,verse:"米该雅说：“你进严密的屋子藏躲的那日，就必看见了。”"},
{volumn:        14,chapter:        18,versenumber:        25,verse:"以色列王说：“将米该雅带回，交给邑宰亚们和王的儿子约阿施，说："},
{volumn:        14,chapter:        18,versenumber:        26,verse:"‘王如此说：把这个人下在监里，使他受苦，吃不饱喝不足，等候我平平安安地回来。’”"},
{volumn:        14,chapter:        18,versenumber:        27,verse:"米该雅说：“你若能平安回来，那就是耶和华没有藉我说这话了”；又说：“众民哪，你们都要听！”"},
{volumn:        14,chapter:        18,versenumber:        28,verse:"以色列王和犹大王约沙法上基列的拉末去了。"},
{volumn:        14,chapter:        18,versenumber:        29,verse:"以色列王对约沙法说：“我要改装上阵，你可以仍穿王服。”于是以色列王改装，他们就上阵去了。"},
{volumn:        14,chapter:        18,versenumber:        30,verse:"先是亚兰王吩咐车兵长说：“他们的兵将，无论大小，你们都不可与他们争战，只要与以色列王争战。”"},
{volumn:        14,chapter:        18,versenumber:        31,verse:"车兵长看见约沙法便说，这必是以色列王，就转过去与他争战。约沙法一呼喊，耶和华就帮助他，　神又感动他们离开他。"},
{volumn:        14,chapter:        18,versenumber:        32,verse:"车兵长见不是以色列王，就转去不追他了。"},
{volumn:        14,chapter:        18,versenumber:        33,verse:"有一人随便开弓，恰巧射入以色列王的甲缝里。王对赶车的说：“我受了重伤，你转过车来，拉我出阵吧！”"},
{volumn:        14,chapter:        18,versenumber:        34,verse:"那日阵势越战越猛，以色列王勉强站在车上抵挡亚兰人，直到晚上。约在日落的时候，王就死了。"},
{volumn:        14,chapter:        19,versenumber:         1,verse:"犹大王约沙法平平安安地回耶路撒冷，到宫里去了。"},
{volumn:        14,chapter:        19,versenumber:         2,verse:"先见哈拿尼的儿子耶户出来迎接约沙法王，对他说：“你岂当帮助恶人，爱那恨恶耶和华的人呢？因此耶和华的忿怒临到你。"},
{volumn:        14,chapter:        19,versenumber:         3,verse:"然而你还有善行，因你从国中除掉木偶，立定心意寻求　神。”"},
{volumn:        14,chapter:        19,versenumber:         4,verse:"约沙法住在耶路撒冷，以后又出巡民间，从别是巴直到以法莲山地，引导民归向耶和华他们列祖的　神；"},
{volumn:        14,chapter:        19,versenumber:         5,verse:"又在犹大国中遍地的坚固城里设立审判官，"},
{volumn:        14,chapter:        19,versenumber:         6,verse:"对他们说：“你们办事应当谨慎；因为你们判断不是为人，乃是为耶和华。判断的时候，他必与你们同在。"},
{volumn:        14,chapter:        19,versenumber:         7,verse:"现在你们应当敬畏耶和华，谨慎办事；因为耶和华我们的　神没有不义，不偏待人，也不受贿赂。”"},
{volumn:        14,chapter:        19,versenumber:         8,verse:"约沙法从利未人和祭司，并以色列族长中派定人，在耶路撒冷为耶和华判断，听民间的争讼，就回耶路撒冷去了。"},
{volumn:        14,chapter:        19,versenumber:         9,verse:"约沙法嘱咐他们说：“你们当敬畏耶和华，忠心诚实办事。"},
{volumn:        14,chapter:        19,versenumber:        10,verse:"住在各城里你们的弟兄，若有争讼的事来到你们这里，或为流血，或犯律法、诫命、律例、典章，你们要警戒他们，免得他们得罪耶和华，以致他的忿怒临到你们和你们的弟兄；这样行，你们就没有罪了。"},
{volumn:        14,chapter:        19,versenumber:        11,verse:"凡属耶和华的事，有大祭司亚玛利雅管理你们；凡属王的事，有犹大支派的族长以实玛利的儿子西巴第雅管理你们；在你们面前有利未人作官长。你们应当壮胆办事，愿耶和华与善人同在。”"},
{volumn:        14,chapter:        20,versenumber:         1,verse:"此后，摩押人和亚扪人，又有米乌尼人，一同来攻击约沙法。"},
{volumn:        14,chapter:        20,versenumber:         2,verse:"有人来报告约沙法说：“从海外亚兰（又作以东）那边有大军来攻击你，如今他们在哈洗逊·他玛，就是隐·基底。”"},
{volumn:        14,chapter:        20,versenumber:         3,verse:"约沙法便惧怕，定意寻求耶和华，在犹大全地宣告禁食。"},
{volumn:        14,chapter:        20,versenumber:         4,verse:"于是犹大人聚会，求耶和华帮助。犹大各城都有人出来寻求耶和华。"},
{volumn:        14,chapter:        20,versenumber:         5,verse:"约沙法就在犹大和耶路撒冷的会中，站在耶和华殿的新院前，"},
{volumn:        14,chapter:        20,versenumber:         6,verse:"说：“耶和华我们列祖的　神啊，你不是天上的　神吗？你不是万邦万国的主宰吗？在你手中有大能大力，无人能抵挡你。"},
{volumn:        14,chapter:        20,versenumber:         7,verse:"我们的　神啊，你不是曾在你民以色列人面前驱逐这地的居民，将这地赐给你朋友亚伯拉罕的后裔永远为业吗？"},
{volumn:        14,chapter:        20,versenumber:         8,verse:"他们住在这地，又为你的名建造圣所，说："},
{volumn:        14,chapter:        20,versenumber:         9,verse:"‘倘有祸患临到我们，或刀兵灾殃，或瘟疫饥荒，我们在急难的时候，站在这殿前向你呼求，你必垂听而拯救，因为你的名在这殿里。’"},
{volumn:        14,chapter:        20,versenumber:        10,verse:"从前以色列人出埃及地的时候，你不容以色列人侵犯亚扪人、摩押人，和西珥山人，以色列人就离开他们，不灭绝他们。"},
{volumn:        14,chapter:        20,versenumber:        11,verse:"看哪，他们怎样报复我们，要来驱逐我们出离你的地，就是你赐给我们为业之地。"},
{volumn:        14,chapter:        20,versenumber:        12,verse:"我们的　神啊，你不惩罚他们吗？因为我们无力抵挡这来攻击我们的大军，我们也不知道怎样行，我们的眼目单仰望你。”"},
{volumn:        14,chapter:        20,versenumber:        13,verse:"犹大众人和他们的婴孩、妻子、儿女都站在耶和华面前。"},
{volumn:        14,chapter:        20,versenumber:        14,verse:"那时，耶和华的灵在会中临到利未人亚萨的后裔，玛探雅的玄孙，耶利的曾孙，比拿雅的孙子，撒迦利雅的儿子雅哈悉。"},
{volumn:        14,chapter:        20,versenumber:        15,verse:"他说：“犹大众人、耶路撒冷的居民，和约沙法王，你们请听。耶和华对你们如此说：‘不要因这大军恐惧惊惶；因为胜败不在乎你们，乃在乎　神。"},
{volumn:        14,chapter:        20,versenumber:        16,verse:"明日你们要下去迎敌，他们是从洗斯坡上来，你们必在耶鲁伊勒旷野前的谷口遇见他们。"},
{volumn:        14,chapter:        20,versenumber:        17,verse:"犹大和耶路撒冷人哪，这次你们不要争战，要摆阵站着，看耶和华为你们施行拯救。不要恐惧，也不要惊惶。明日当出去迎敌，因为耶和华与你们同在。’”"},
{volumn:        14,chapter:        20,versenumber:        18,verse:"约沙法就面伏于地，犹大众人和耶路撒冷的居民也俯伏在耶和华面前，叩拜耶和华。"},
{volumn:        14,chapter:        20,versenumber:        19,verse:"哥辖族和可拉族的利未人都起来，用极大的声音赞美耶和华以色列的　神。"},
{volumn:        14,chapter:        20,versenumber:        20,verse:"次日清早，众人起来往提哥亚的旷野去。出去的时候，约沙法站着说：“犹大人和耶路撒冷的居民哪，要听我说：信耶和华你们的　神就必立稳；信他的先知就必亨通。”"},
{volumn:        14,chapter:        20,versenumber:        21,verse:"约沙法既与民商议了，就设立歌唱的人，颂赞耶和华，使他们穿上圣洁的礼服，走在军前赞美耶和华说：“当称谢耶和华，因他的慈爱永远长存！”"},
{volumn:        14,chapter:        20,versenumber:        22,verse:"众人方唱歌赞美的时候，耶和华就派伏兵击杀那来攻击犹大人的亚扪人、摩押人，和西珥山人，他们就被打败了。"},
{volumn:        14,chapter:        20,versenumber:        23,verse:"因为亚扪人和摩押人起来，击杀住西珥山的人，将他们灭尽；灭尽住西珥山的人之后，他们又彼此自相击杀。"},
{volumn:        14,chapter:        20,versenumber:        24,verse:"犹大人来到旷野的望楼，向那大军观看，见尸横遍地，没有一个逃脱的。"},
{volumn:        14,chapter:        20,versenumber:        25,verse:"约沙法和他的百姓就来收取敌人的财物，在尸首中见了许多财物、珍宝，他们剥脱下来的多得不可携带；因为甚多，直收取了三日。"},
{volumn:        14,chapter:        20,versenumber:        26,verse:"第四日众人聚集在比拉迦谷（就是称颂的意思），在那里称颂耶和华。因此那地方名叫比拉迦谷，直到今日。"},
{volumn:        14,chapter:        20,versenumber:        27,verse:"犹大人和耶路撒冷人都欢欢喜喜地回耶路撒冷，约沙法率领他们；因为耶和华使他们战胜仇敌，就欢喜快乐。"},
{volumn:        14,chapter:        20,versenumber:        28,verse:"他们弹琴、鼓瑟、吹号来到耶路撒冷，进了耶和华的殿。"},
{volumn:        14,chapter:        20,versenumber:        29,verse:"列邦诸国听见耶和华战败以色列的仇敌，就甚惧怕。"},
{volumn:        14,chapter:        20,versenumber:        30,verse:"这样，约沙法的国得享太平，因为　神赐他四境平安。"},
{volumn:        14,chapter:        20,versenumber:        31,verse:"约沙法作犹大王，登基的时候年三十五岁，在耶路撒冷作王二十五年。他母亲名叫阿苏巴，乃示利希的女儿。"},
{volumn:        14,chapter:        20,versenumber:        32,verse:"约沙法效法他父亚撒所行的，不偏左右，行耶和华眼中看为正的事。"},
{volumn:        14,chapter:        20,versenumber:        33,verse:"只是邱坛还没有废去，百姓也没有立定心意归向他们列祖的　神。"},
{volumn:        14,chapter:        20,versenumber:        34,verse:"约沙法其余的事，自始至终都写在哈拿尼的儿子耶户的书上，也载入以色列诸王记上。"},
{volumn:        14,chapter:        20,versenumber:        35,verse:"此后，犹大王约沙法与以色列王亚哈谢交好；亚哈谢行恶太甚。"},
{volumn:        14,chapter:        20,versenumber:        36,verse:"二王合伙造船要往他施去，遂在以旬迦别造船。"},
{volumn:        14,chapter:        20,versenumber:        37,verse:"那时玛利沙人、多大瓦的儿子以利以谢向约沙法预言说：“因你与亚哈谢交好，耶和华必破坏你所造的。”后来那船果然破坏，不能往他施去了。"},
{volumn:        14,chapter:        21,versenumber:         1,verse:"约沙法与他列祖同睡，葬在大卫城他列祖的坟地里。他儿子约兰接续他作王。"},
{volumn:        14,chapter:        21,versenumber:         2,verse:"约兰有几个兄弟，就是约沙法的儿子亚撒利雅、耶歇、撒迦利雅、亚撒利雅、米迦勒、示法提雅。这都是犹大王约沙法的儿子。"},
{volumn:        14,chapter:        21,versenumber:         3,verse:"他们的父亲将许多金银、财宝，和犹大地的坚固城赐给他们；但将国赐给约兰，因为他是长子。"},
{volumn:        14,chapter:        21,versenumber:         4,verse:"约兰兴起坐他父的位，奋勇自强，就用刀杀了他的众兄弟和以色列的几个首领。"},
{volumn:        14,chapter:        21,versenumber:         5,verse:"约兰登基的时候年三十二岁，在耶路撒冷作王八年。"},
{volumn:        14,chapter:        21,versenumber:         6,verse:"他行以色列诸王的道，与亚哈家一样；因他娶了亚哈的女儿为妻，行耶和华眼中看为恶的事。"},
{volumn:        14,chapter:        21,versenumber:         7,verse:"耶和华却因自己与大卫所立的约，不肯灭大卫的家，照他所应许的，永远赐灯光与大卫和他的子孙。"},
{volumn:        14,chapter:        21,versenumber:         8,verse:"约兰年间，以东人背叛犹大，脱离他的权下，自己立王。"},
{volumn:        14,chapter:        21,versenumber:         9,verse:"约兰就率领军长和所有的战车，夜间起来，攻击围困他的以东人和车兵长。"},
{volumn:        14,chapter:        21,versenumber:        10,verse:"这样，以东人背叛犹大，脱离他的权下，直到今日。那时，立拿人也背叛了，因为约兰离弃耶和华他列祖的　神。"},
{volumn:        14,chapter:        21,versenumber:        11,verse:"他又在犹大诸山建筑邱坛，使耶路撒冷的居民行邪淫，诱惑犹大人。"},
{volumn:        14,chapter:        21,versenumber:        12,verse:"先知以利亚达信与约兰说：“耶和华你祖大卫的　神如此说：‘因为你不行你父约沙法和犹大王亚撒的道，"},
{volumn:        14,chapter:        21,versenumber:        13,verse:"乃行以色列诸王的道，使犹大人和耶路撒冷的居民行邪淫，像亚哈家一样，又杀了你父家比你好的诸兄弟。"},
{volumn:        14,chapter:        21,versenumber:        14,verse:"故此，耶和华降大灾与你的百姓和你的妻子、儿女，并你一切所有的。"},
{volumn:        14,chapter:        21,versenumber:        15,verse:"你的肠子必患病，日加沉重，以致你的肠子坠落下来。’”"},
{volumn:        14,chapter:        21,versenumber:        16,verse:"以后，耶和华激动非利士人和靠近古实的阿拉伯人来攻击约兰。"},
{volumn:        14,chapter:        21,versenumber:        17,verse:"他们上来攻击犹大，侵入境内，掳掠了王宫里所有的财货和他的妻子、儿女，除了他小儿子约哈斯（又名亚哈谢）之外，没有留下一个儿子。"},
{volumn:        14,chapter:        21,versenumber:        18,verse:"这些事以后，耶和华使约兰的肠子患不能医治的病。"},
{volumn:        14,chapter:        21,versenumber:        19,verse:"他患此病缠绵日久，过了二年，肠子坠落下来，病重而死。他的民没有为他烧什么物件，像从前为他列祖所烧的一样。"},
{volumn:        14,chapter:        21,versenumber:        20,verse:"约兰登基的时候年三十二岁，在耶路撒冷作王八年。他去世无人思慕，众人葬他在大卫城，只是不在列王的坟墓里。"},
{volumn:        14,chapter:        22,versenumber:         1,verse:"耶路撒冷的居民立约兰的小儿子亚哈谢接续他作王；因为跟随阿拉伯人来攻营的军兵曾杀了亚哈谢的众兄长。这样，犹大王约兰的儿子亚哈谢作了王。"},
{volumn:        14,chapter:        22,versenumber:         2,verse:"亚哈谢登基的时候年四十二岁（列王下八章二十六节是二十二岁），在耶路撒冷作王一年。他母亲名叫亚他利雅，是暗利的孙女。"},
{volumn:        14,chapter:        22,versenumber:         3,verse:"亚哈谢也行亚哈家的道；因为他母亲给他主谋，使他行恶。"},
{volumn:        14,chapter:        22,versenumber:         4,verse:"他行耶和华眼中看为恶的事，像亚哈家一样；因他父亲死后有亚哈家的人给他主谋，以致败坏。"},
{volumn:        14,chapter:        22,versenumber:         5,verse:"他听从亚哈家的计谋，同以色列王亚哈的儿子约兰往基列的拉末去，与亚兰王哈薛争战；亚兰人打伤了约兰。"},
{volumn:        14,chapter:        22,versenumber:         6,verse:"约兰回到耶斯列，医治在拉末与亚兰王哈薛打仗所受的伤，犹大王约兰的儿子亚撒利雅（就是亚哈谢）因为亚哈的儿子约兰病了，就下到耶斯列看望他。"},
{volumn:        14,chapter:        22,versenumber:         7,verse:"亚哈谢去见约兰就被害了，这是出乎　神；因为他到了，就同约兰出去攻击宁示的孙子耶户。这耶户是耶和华所膏、使他剪除亚哈家的。"},
{volumn:        14,chapter:        22,versenumber:         8,verse:"耶户讨亚哈家罪的时候，遇见犹大的众首领和亚哈谢的众侄子服侍亚哈谢，就把他们都杀了。"},
{volumn:        14,chapter:        22,versenumber:         9,verse:"亚哈谢藏在撒玛利亚，耶户寻找他，众人将他拿住，送到耶户那里，就杀了他，将他葬埋；因他们说，他是那尽心寻求耶和华之约沙法的儿子。这样，亚哈谢的家无力保守国权。"},
{volumn:        14,chapter:        22,versenumber:        10,verse:"亚哈谢的母亲亚他利雅见她儿子死了，就起来剿灭犹大王室。"},
{volumn:        14,chapter:        22,versenumber:        11,verse:"但王的女儿约示巴将亚哈谢的儿子约阿施从那被杀的王子中偷出来，把他和他的乳母都藏在卧房里。约示巴是约兰王的女儿，亚哈谢的妹子，祭司耶何耶大的妻。她收藏约阿施，躲避亚他利雅，免得被杀。"},
{volumn:        14,chapter:        22,versenumber:        12,verse:"约阿施和她们一同藏在　神殿里六年；亚他利雅篡了国位。"},
{volumn:        14,chapter:        23,versenumber:         1,verse:"第七年，耶何耶大奋勇自强，将百夫长耶罗罕的儿子亚撒利雅，约哈难的儿子以实玛利，俄备得的儿子亚撒利雅，亚大雅的儿子玛西雅，细基利的儿子以利沙法召来，与他们立约。"},
{volumn:        14,chapter:        23,versenumber:         2,verse:"他们走遍犹大，从犹大各城里招聚利未人和以色列的众族长到耶路撒冷来。"},
{volumn:        14,chapter:        23,versenumber:         3,verse:"会众在　神殿里与王立约。耶何耶大对他们说：“看哪，王的儿子必当作王，正如耶和华指着大卫子孙所应许的话”；"},
{volumn:        14,chapter:        23,versenumber:         4,verse:"又说：“你们当这样行：祭司和利未人凡安息日进班的，三分之一要把守各门，"},
{volumn:        14,chapter:        23,versenumber:         5,verse:"三分之一要在王宫，三分之一要在基址门；众百姓要在耶和华殿的院内。"},
{volumn:        14,chapter:        23,versenumber:         6,verse:"除了祭司和供职的利未人之外，不准别人进耶和华的殿；惟独他们可以进去，因为他们圣洁。众百姓要遵守耶和华所吩咐的。"},
{volumn:        14,chapter:        23,versenumber:         7,verse:"利未人要手中各拿兵器，四围护卫王；凡擅入殿宇的，必当治死。王出入的时候，你们当跟随他。”"},
{volumn:        14,chapter:        23,versenumber:         8,verse:"利未人和犹大众人都照着祭司耶何耶大一切所吩咐的去行，各带所管安息日进班出班的人来，因为祭司耶何耶大不许他们下班。"},
{volumn:        14,chapter:        23,versenumber:         9,verse:"祭司耶何耶大便将　神殿里所藏大卫王的枪、盾牌、挡牌交给百夫长，"},
{volumn:        14,chapter:        23,versenumber:        10,verse:"又分派众民手中各拿兵器，在坛和殿那里，从殿右直到殿左，站在王子的四围；"},
{volumn:        14,chapter:        23,versenumber:        11,verse:"于是领王子出来，给他戴上冠冕，将律法书交给他，立他作王。耶何耶大和众子膏他，众人说：“愿王万岁！”"},
{volumn:        14,chapter:        23,versenumber:        12,verse:"亚他利雅听见民奔走赞美王的声音，就到民那里，进耶和华的殿，"},
{volumn:        14,chapter:        23,versenumber:        13,verse:"看见王站在殿门的柱旁，百夫长和吹号的人侍立在王左右，国民都欢乐吹号，又有歌唱的，用各样的乐器领人歌唱赞美；亚他利雅就撕裂衣服，喊叫说：“反了！反了！”"},
{volumn:        14,chapter:        23,versenumber:        14,verse:"祭司耶何耶大带管辖军兵的百夫长出来，吩咐他们说：“将她赶到班外，凡跟随她的必用刀杀死！”因为祭司说：“不可在耶和华殿里杀她。”"},
{volumn:        14,chapter:        23,versenumber:        15,verse:"众兵就闪开，让她去；她走到王宫的马门，便在那里把她杀了。"},
{volumn:        14,chapter:        23,versenumber:        16,verse:"耶何耶大与众民和王立约，都要作耶和华的民。"},
{volumn:        14,chapter:        23,versenumber:        17,verse:"于是众民都到巴力庙，拆毁了庙，打碎坛和像，又在坛前将巴力的祭司玛坦杀了。"},
{volumn:        14,chapter:        23,versenumber:        18,verse:"耶何耶大派官看守耶和华的殿，是在祭司利未人手下。这祭司利未人是大卫分派在耶和华殿中、照摩西律法上所写的，给耶和华献燔祭，又按大卫所定的例，欢乐歌唱；"},
{volumn:        14,chapter:        23,versenumber:        19,verse:"且设立守门的把守耶和华殿的各门，无论为何事，不洁净的人都不准进去。"},
{volumn:        14,chapter:        23,versenumber:        20,verse:"又率领百夫长和贵胄，与民间的官长，并国中的众民，请王从耶和华殿下来，由上门进入王宫，立王坐在国位上。"},
{volumn:        14,chapter:        23,versenumber:        21,verse:"国民都欢乐，合城都安静。众人已将亚他利雅用刀杀了。"},
{volumn:        14,chapter:        24,versenumber:         1,verse:"约阿施登基的时候年七岁，在耶路撒冷作王四十年。他母亲名叫西比亚，是别是巴人。"},
{volumn:        14,chapter:        24,versenumber:         2,verse:"祭司耶何耶大在世的时候，约阿施行耶和华眼中看为正的事。"},
{volumn:        14,chapter:        24,versenumber:         3,verse:"耶何耶大为他娶了两个妻，并且生儿养女。"},
{volumn:        14,chapter:        24,versenumber:         4,verse:"此后，约阿施有意重修耶和华的殿，"},
{volumn:        14,chapter:        24,versenumber:         5,verse:"便召聚众祭司和利未人，吩咐他们说：“你们要往犹大各城去，使以色列众人捐纳银子，每年可以修理你们　神的殿；你们要急速办理这事。”只是利未人不急速办理。"},
{volumn:        14,chapter:        24,versenumber:         6,verse:"王召了大祭司耶何耶大来，对他说：“从前耶和华的仆人摩西，为法柜的帐幕与以色列会众所定的捐项，你为何不叫利未人照这例从犹大和耶路撒冷带来作殿的费用呢？”（"},
{volumn:        14,chapter:        24,versenumber:         7,verse:"因为那恶妇亚他利雅的众子曾拆毁　神的殿，又用耶和华殿中分别为圣的物供奉巴力。）"},
{volumn:        14,chapter:        24,versenumber:         8,verse:"于是王下令，众人做了一柜，放在耶和华殿的门外，"},
{volumn:        14,chapter:        24,versenumber:         9,verse:"又通告犹大和耶路撒冷的百姓，要将　神仆人摩西在旷野所吩咐以色列人的捐项给耶和华送来。"},
{volumn:        14,chapter:        24,versenumber:        10,verse:"众首领和百姓都欢欢喜喜地将银子送来，投入柜中，直到捐完。"},
{volumn:        14,chapter:        24,versenumber:        11,verse:"利未人见银子多了，就把柜抬到王所派的司事面前；王的书记和大祭司的属员来将柜倒空，仍放在原处。日日都是这样，积蓄的银子甚多。"},
{volumn:        14,chapter:        24,versenumber:        12,verse:"王与耶何耶大将银子交给耶和华殿里办事的人，他们就雇了石匠、木匠重修耶和华的殿，又雇了铁匠、铜匠修理耶和华的殿。"},
{volumn:        14,chapter:        24,versenumber:        13,verse:"工人操作，渐渐修成，将　神殿修造得与从前一样，而且甚是坚固。"},
{volumn:        14,chapter:        24,versenumber:        14,verse:"工程完了，他们就把其余的银子拿到王与耶何耶大面前，用以制造耶和华殿供奉所用的器皿和调羹，并金银的器皿。耶何耶大在世的时候，众人常在耶和华殿里献燔祭。"},
{volumn:        14,chapter:        24,versenumber:        15,verse:"耶何耶大年纪老迈，日子满足而死。死的时候年一百三十岁，"},
{volumn:        14,chapter:        24,versenumber:        16,verse:"葬在大卫城列王的坟墓里；因为他在以色列人中行善，又侍奉　神，修理　神的殿。"},
{volumn:        14,chapter:        24,versenumber:        17,verse:"耶何耶大死后，犹大的众首领来朝拜王；王就听从他们。"},
{volumn:        14,chapter:        24,versenumber:        18,verse:"他们离弃耶和华他们列祖　神的殿，去侍奉亚舍拉和偶像；因他们这罪，就有忿怒临到犹大和耶路撒冷。"},
{volumn:        14,chapter:        24,versenumber:        19,verse:"但　神仍遣先知到他们那里，引导他们归向耶和华。这先知警戒他们，他们却不肯听。"},
{volumn:        14,chapter:        24,versenumber:        20,verse:"那时，　神的灵感动祭司耶何耶大的儿子撒迦利亚，他就站在上面对民说：“　神如此说：你们为何干犯耶和华的诫命，以致不得亨通呢？因为你们离弃耶和华，所以他也离弃你们。”"},
{volumn:        14,chapter:        24,versenumber:        21,verse:"众民同心谋害撒迦利亚，就照王的吩咐，在耶和华殿的院内用石头打死他。"},
{volumn:        14,chapter:        24,versenumber:        22,verse:"这样，约阿施王不想念撒迦利亚的父亲耶何耶大向自己所施的恩，杀了他的儿子。撒迦利亚临死的时候说：“愿耶和华鉴察伸冤！”"},
{volumn:        14,chapter:        24,versenumber:        23,verse:"满了一年，亚兰的军兵上来攻击约阿施，来到犹大和耶路撒冷，杀了民中的众首领，将所掠的财货送到大马士革王那里。"},
{volumn:        14,chapter:        24,versenumber:        24,verse:"亚兰的军兵虽来了一小队，耶和华却将大队的军兵交在他们手里，是因犹大人离弃耶和华他们列祖的　神，所以藉亚兰人惩罚约阿施。"},
{volumn:        14,chapter:        24,versenumber:        25,verse:"亚兰人离开约阿施的时候，他患重病；臣仆背叛他，要报祭司耶何耶大儿子流血之仇，杀他在床上，葬他在大卫城，只是不葬在列王的坟墓里。"},
{volumn:        14,chapter:        24,versenumber:        26,verse:"背叛他的是亚扪妇人示米押的儿子撒拔和摩押妇人示米利的儿子约萨拔。"},
{volumn:        14,chapter:        24,versenumber:        27,verse:"至于他的众子和他所受的警戒，并他重修　神殿的事，都写在列王的传上。他儿子亚玛谢接续他作王。"},
{volumn:        14,chapter:        25,versenumber:         1,verse:"亚玛谢登基的时候年二十五岁，在耶路撒冷作王二十九年。他母亲名叫约耶但，是耶路撒冷人。"},
{volumn:        14,chapter:        25,versenumber:         2,verse:"亚玛谢行耶和华眼中看为正的事，只是心不专诚。"},
{volumn:        14,chapter:        25,versenumber:         3,verse:"国一坚定，就把杀他父王的臣仆杀了，"},
{volumn:        14,chapter:        25,versenumber:         4,verse:"却没有治死他们的儿子，是照摩西律法书上耶和华所吩咐的说：“不可因子杀父，也不可因父杀子，各人要为本身的罪而死。”"},
{volumn:        14,chapter:        25,versenumber:         5,verse:"亚玛谢招聚犹大人，按着犹大和便雅悯的宗族设立千夫长、百夫长，又数点人数，从二十岁以外，能拿枪拿盾牌出去打仗的精兵共有三十万；"},
{volumn:        14,chapter:        25,versenumber:         6,verse:"又用银子一百他连得，从以色列招募了十万大能的勇士。"},
{volumn:        14,chapter:        25,versenumber:         7,verse:"有一个神人来见亚玛谢，对他说：“王啊，不要使以色列的军兵与你同去，因为耶和华不与以色列人以法莲的后裔同在。"},
{volumn:        14,chapter:        25,versenumber:         8,verse:"你若一定要去，就奋勇争战吧！但　神必使你败在敌人面前；因为　神能助人得胜，也能使人倾败。”"},
{volumn:        14,chapter:        25,versenumber:         9,verse:"亚玛谢问神人说：“我给了以色列军的那一百他连得银子怎么样呢？”神人回答说：“耶和华能把更多的赐给你。”"},
{volumn:        14,chapter:        25,versenumber:        10,verse:"于是亚玛谢将那从以法莲来的军兵分别出来，叫他们回家去。故此，他们甚恼怒犹大人，气忿忿地回家去了。"},
{volumn:        14,chapter:        25,versenumber:        11,verse:"亚玛谢壮起胆来，率领他的民到盐谷，杀了西珥人一万。"},
{volumn:        14,chapter:        25,versenumber:        12,verse:"犹大人又生擒了一万带到山崖上，从那里把他们扔下去，以致他们都摔碎了。"},
{volumn:        14,chapter:        25,versenumber:        13,verse:"但亚玛谢所打发回去、不许一同出征的那些军兵攻打犹大各城，从撒玛利亚直到伯·和仑，杀了三千人，抢了许多财物。"},
{volumn:        14,chapter:        25,versenumber:        14,verse:"亚玛谢杀了以东人回来，就把西珥的神像带回，立为自己的神，在它面前叩拜烧香。"},
{volumn:        14,chapter:        25,versenumber:        15,verse:"因此，耶和华的怒气向亚玛谢发作，就差一个先知去见他，说：“这些神不能救它的民脱离你的手，你为何寻求它呢？”"},
{volumn:        14,chapter:        25,versenumber:        16,verse:"先知与王说话的时候，王对他说：“谁立你作王的谋士呢？你住口吧！为何找打呢？”先知就止住了，又说：“你行这事，不听从我的劝戒，我知道　神定意要灭你。”"},
{volumn:        14,chapter:        25,versenumber:        17,verse:"犹大王亚玛谢与群臣商议，就差遣使者去见耶户的孙子、约哈斯的儿子、以色列王约阿施，说：“你来，我们二人相见于战场。”"},
{volumn:        14,chapter:        25,versenumber:        18,verse:"以色列王约阿施差遣使者去见犹大王亚玛谢，说：“黎巴嫩的蒺藜差遣使者去见黎巴嫩的香柏树，说：‘将你的女儿给我儿子为妻。’后来黎巴嫩有一个野兽经过，把蒺藜践踏了。"},
{volumn:        14,chapter:        25,versenumber:        19,verse:"你说：‘看哪，我打败了以东人’，你就心高气傲，以致矜夸。你在家里安居就罢了，为何要惹祸使自己和犹大国一同败亡呢？”"},
{volumn:        14,chapter:        25,versenumber:        20,verse:"亚玛谢却不肯听从。这是出乎　神，好将他们交在敌人手里，因为他们寻求以东的神。"},
{volumn:        14,chapter:        25,versenumber:        21,verse:"于是以色列王约阿施上来，在犹大的伯·示麦与犹大王亚玛谢相见于战场。"},
{volumn:        14,chapter:        25,versenumber:        22,verse:"犹大人败在以色列人面前，各自逃回家里去了。"},
{volumn:        14,chapter:        25,versenumber:        23,verse:"以色列王约阿施在伯·示麦擒住约哈斯（就是亚哈谢）的孙子、约阿施的儿子、犹大王亚玛谢，将他带到耶路撒冷，又拆毁耶路撒冷的城墙，从以法莲门直到角门，共四百肘；"},
{volumn:        14,chapter:        25,versenumber:        24,verse:"又将俄别·以东所看守　神殿里的一切金银和器皿，与王宫里的财宝都拿了去，并带人去为质，就回撒玛利亚去了。"},
{volumn:        14,chapter:        25,versenumber:        25,verse:"以色列王约哈斯的儿子约阿施死后，犹大王约阿施的儿子亚玛谢又活了十五年。"},
{volumn:        14,chapter:        25,versenumber:        26,verse:"亚玛谢其余的事，自始至终不都写在犹大和以色列诸王记上吗？"},
{volumn:        14,chapter:        25,versenumber:        27,verse:"自从亚玛谢离弃耶和华之后，在耶路撒冷有人背叛他，他就逃到拉吉；叛党却打发人到拉吉，将他杀了。"},
{volumn:        14,chapter:        25,versenumber:        28,verse:"人就用马将他的尸首驮回，葬在犹大京城他列祖的坟地里。"},
{volumn:        14,chapter:        26,versenumber:         1,verse:"犹大众民立亚玛谢的儿子乌西雅（又名亚撒利雅）接续他父作王，那时他年十六岁。（"},
{volumn:        14,chapter:        26,versenumber:         2,verse:"亚玛谢与他列祖同睡之后，乌西雅收回以禄仍归犹大，又重新修理。）"},
{volumn:        14,chapter:        26,versenumber:         3,verse:"乌西雅登基的时候年十六岁，在耶路撒冷作王五十二年。他母亲名叫耶可利雅，是耶路撒冷人。"},
{volumn:        14,chapter:        26,versenumber:         4,verse:"乌西雅行耶和华眼中看为正的事，效法他父亚玛谢一切所行的；"},
{volumn:        14,chapter:        26,versenumber:         5,verse:"通晓　神默示，撒迦利亚在世的时候，乌西雅定意寻求　神；他寻求耶和华，　神就使他亨通。"},
{volumn:        14,chapter:        26,versenumber:         6,verse:"他出去攻击非利士人，拆毁了迦特城、雅比尼城，和亚实突城；在非利士人中，在亚实突境内，又建筑了些城。"},
{volumn:        14,chapter:        26,versenumber:         7,verse:"神帮助他攻击非利士人和住在姑珥·巴力的阿拉伯人，并米乌尼人。"},
{volumn:        14,chapter:        26,versenumber:         8,verse:"亚扪人给乌西雅进贡。他的名声传到埃及，因他甚是强盛。"},
{volumn:        14,chapter:        26,versenumber:         9,verse:"乌西雅在耶路撒冷的角门和谷门，并城墙转弯之处，建筑城楼，且甚坚固；"},
{volumn:        14,chapter:        26,versenumber:        10,verse:"又在旷野与高原和平原，建筑望楼，挖了许多井，因他的牲畜甚多；又在山地和佳美之地，有农夫和修理葡萄园的人，因为他喜悦农事。"},
{volumn:        14,chapter:        26,versenumber:        11,verse:"乌西雅又有军兵，照书记耶利和官长玛西雅所数点的，在王的一个将军哈拿尼雅手下，分队出战。"},
{volumn:        14,chapter:        26,versenumber:        12,verse:"族长、大能勇士的总数共有二千六百人，"},
{volumn:        14,chapter:        26,versenumber:        13,verse:"他们手下的军兵共有三十万七千五百人，都有大能，善于争战，帮助王攻击仇敌。"},
{volumn:        14,chapter:        26,versenumber:        14,verse:"乌西雅为全军预备盾牌、枪、盔、甲、弓，和甩石的机弦，"},
{volumn:        14,chapter:        26,versenumber:        15,verse:"又在耶路撒冷使巧匠做机器，安在城楼和角楼上，用以射箭发石。乌西雅的名声传到远方；因为他得了非常的帮助，甚是强盛。"},
{volumn:        14,chapter:        26,versenumber:        16,verse:"他既强盛，就心高气傲，以致行事邪僻，干犯耶和华他的　神，进耶和华的殿，要在香坛上烧香。"},
{volumn:        14,chapter:        26,versenumber:        17,verse:"祭司亚撒利雅率领耶和华勇敢的祭司八十人，跟随他进去。"},
{volumn:        14,chapter:        26,versenumber:        18,verse:"他们就阻挡乌西雅王，对他说：“乌西雅啊，给耶和华烧香不是你的事，乃是亚伦子孙承接圣职祭司的事。你出圣殿吧！因为你犯了罪。你行这事，耶和华　神必不使你得荣耀。”"},
{volumn:        14,chapter:        26,versenumber:        19,verse:"乌西雅就发怒，手拿香炉要烧香。他向祭司发怒的时候，在耶和华殿中香坛旁众祭司面前，额上忽然发出大麻风。"},
{volumn:        14,chapter:        26,versenumber:        20,verse:"大祭司亚撒利雅和众祭司观看，见他额上发出大麻风，就催他出殿；他自己也急速出去，因为耶和华降灾与他。"},
{volumn:        14,chapter:        26,versenumber:        21,verse:"乌西雅王长大麻风直到死日，因此住在别的宫里，与耶和华的殿隔绝。他儿子约坦管理家事，治理国民。"},
{volumn:        14,chapter:        26,versenumber:        22,verse:"乌西雅其余的事，自始至终都是亚摩斯的儿子先知以赛亚所记的。"},
{volumn:        14,chapter:        26,versenumber:        23,verse:"乌西雅与他列祖同睡，葬在王陵的田间他列祖的坟地里；因为人说，他是长大麻风的。他儿子约坦接续他作王。"},
{volumn:        14,chapter:        27,versenumber:         1,verse:"约坦登基的时候年二十五岁，在耶路撒冷作王十六年，他母亲名叫耶路沙，是撒督的女儿。"},
{volumn:        14,chapter:        27,versenumber:         2,verse:"约坦行耶和华眼中看为正的事，效法他父乌西雅一切所行的，只是不入耶和华的殿。百姓还行邪僻的事。"},
{volumn:        14,chapter:        27,versenumber:         3,verse:"约坦建立耶和华殿的上门，在俄斐勒城上多有建造，"},
{volumn:        14,chapter:        27,versenumber:         4,verse:"又在犹大山地建造城邑，在树林中建筑营寨和高楼。"},
{volumn:        14,chapter:        27,versenumber:         5,verse:"约坦与亚扪人的王打仗胜了他们，当年他们进贡银一百他连得，小麦一万歌珥，大麦一万歌珥；第二年、第三年也是这样。"},
{volumn:        14,chapter:        27,versenumber:         6,verse:"约坦在耶和华他　神面前行正道，以致日渐强盛。"},
{volumn:        14,chapter:        27,versenumber:         7,verse:"约坦其余的事和一切争战，并他的行为，都写在以色列和犹大列王记上。"},
{volumn:        14,chapter:        27,versenumber:         8,verse:"他登基的时候年二十五岁，在耶路撒冷作王十六年。"},
{volumn:        14,chapter:        27,versenumber:         9,verse:"约坦与他列祖同睡，葬在大卫城里。他儿子亚哈斯接续他作王。"},
{volumn:        14,chapter:        28,versenumber:         1,verse:"亚哈斯登基的时候年二十岁，在耶路撒冷作王十六年；不像他祖大卫行耶和华眼中看为正的事，"},
{volumn:        14,chapter:        28,versenumber:         2,verse:"却行以色列诸王的道，又铸造巴力的像，"},
{volumn:        14,chapter:        28,versenumber:         3,verse:"并且在欣嫩子谷烧香，用火焚烧他的儿女，行耶和华在以色列人面前所驱逐的外邦人那可憎的事；"},
{volumn:        14,chapter:        28,versenumber:         4,verse:"并在邱坛上、山冈上、各青翠树下献祭烧香。"},
{volumn:        14,chapter:        28,versenumber:         5,verse:"所以，耶和华他的　神将他交在亚兰王手里。亚兰王打败他，掳了他许多的民，带到大马士革去。　神又将他交在以色列王手里，以色列王向他大行杀戮。"},
{volumn:        14,chapter:        28,versenumber:         6,verse:"利玛利的儿子比加一日杀了犹大人十二万，都是勇士，因为他们离弃了耶和华他们列祖的　神。"},
{volumn:        14,chapter:        28,versenumber:         7,verse:"有一个以法莲中的勇士，名叫细基利，杀了王的儿子玛西雅和管理王宫的押斯利甘，并宰相以利加拿。"},
{volumn:        14,chapter:        28,versenumber:         8,verse:"以色列人掳了他们的弟兄，连妇人带儿女共有二十万，又掠了许多的财物，带到撒玛利亚去了。"},
{volumn:        14,chapter:        28,versenumber:         9,verse:"但那里有耶和华的一个先知，名叫俄德，出来迎接往撒玛利亚去的军兵，对他们说：“因为耶和华你们列祖的　神恼怒犹大人，所以将他们交在你们手里，你们竟怒气冲天，大行杀戮。"},
{volumn:        14,chapter:        28,versenumber:        10,verse:"如今你们又有意强逼犹大人和耶路撒冷人作你们的奴婢，你们岂不也有得罪耶和华你们　神的事吗？"},
{volumn:        14,chapter:        28,versenumber:        11,verse:"现在你们当听我说，要将掳来的弟兄释放回去，因为耶和华向你们已经大发烈怒。”"},
{volumn:        14,chapter:        28,versenumber:        12,verse:"于是，以法莲人的几个族长，就是约哈难的儿子亚撒利雅、米实利末的儿子比利家、沙龙的儿子耶希西家、哈得莱的儿子亚玛撒，起来拦挡出兵回来的人，"},
{volumn:        14,chapter:        28,versenumber:        13,verse:"对他们说：“你们不可带进这被掳的人来！你们想要使我们得罪耶和华，加增我们的罪恶过犯？因为我们的罪过甚大，已经有烈怒临到以色列人了。”"},
{volumn:        14,chapter:        28,versenumber:        14,verse:"于是带兵器的人将掳来的人口和掠来的财物都留在众首领和会众的面前。"},
{volumn:        14,chapter:        28,versenumber:        15,verse:"以上提名的那些人就站起，使被掳的人前来；其中有赤身的，就从所掠的财物中拿出衣服和鞋来，给他们穿，又给他们吃喝，用膏抹他们；其中有软弱的，就使他们骑驴，送到棕树城耶利哥他们弟兄那里；随后就回撒玛利亚去了。"},
{volumn:        14,chapter:        28,versenumber:        16,verse:"那时，亚哈斯王差遣人去见亚述诸王，求他们帮助；"},
{volumn:        14,chapter:        28,versenumber:        17,verse:"因为以东人又来攻击犹大，掳掠子民。"},
{volumn:        14,chapter:        28,versenumber:        18,verse:"非利士人也来侵占高原和犹大南方的城邑，取了伯·示麦、亚雅仑、基低罗，梭哥和属梭哥的乡村，亭纳和属亭纳的乡村，瑾锁和属瑾锁的乡村，就住在那里。"},
{volumn:        14,chapter:        28,versenumber:        19,verse:"因为以色列王亚哈斯在犹大放肆，大大干犯耶和华，所以耶和华使犹大卑微。"},
{volumn:        14,chapter:        28,versenumber:        20,verse:"亚述王提革拉·毗列色上来，却没有帮助他，反倒欺凌他。"},
{volumn:        14,chapter:        28,versenumber:        21,verse:"亚哈斯从耶和华殿里和王宫中，并首领家内所取的财宝给了亚述王，这也无济于事。"},
{volumn:        14,chapter:        28,versenumber:        22,verse:"这亚哈斯王在急难的时候，越发得罪耶和华。"},
{volumn:        14,chapter:        28,versenumber:        23,verse:"他祭祀攻击他的大马士革之神，说：“因为亚兰王的神帮助他们，我也献祭与他，他好帮助我。”但那些神使他和以色列众人败亡了。"},
{volumn:        14,chapter:        28,versenumber:        24,verse:"亚哈斯将　神殿里的器皿都聚了来，毁坏了，且封锁耶和华殿的门；在耶路撒冷各处的拐角建筑祭坛，"},
{volumn:        14,chapter:        28,versenumber:        25,verse:"又在犹大各城建立邱坛，与别神烧香，惹动耶和华他列祖　神的怒气。"},
{volumn:        14,chapter:        28,versenumber:        26,verse:"亚哈斯其余的事和他的行为，自始至终都写在犹大和以色列诸王记上。"},
{volumn:        14,chapter:        28,versenumber:        27,verse:"亚哈斯与他列祖同睡，葬在耶路撒冷城里，没有送入以色列诸王的坟墓中。他儿子希西家接续他作王。"},
{volumn:        14,chapter:        29,versenumber:         1,verse:"希西家登基的时候年二十五岁，在耶路撒冷作王二十九年。他母亲名叫亚比雅，是撒迦利雅的女儿。"},
{volumn:        14,chapter:        29,versenumber:         2,verse:"希西家行耶和华眼中看为正的事，效法他祖大卫一切所行的。"},
{volumn:        14,chapter:        29,versenumber:         3,verse:"元年正月，开了耶和华殿的门，重新修理。"},
{volumn:        14,chapter:        29,versenumber:         4,verse:"他召众祭司和利未人来，聚集在东边的宽阔处，"},
{volumn:        14,chapter:        29,versenumber:         5,verse:"对他们说：“利未人哪，当听我说：现在你们要洁净自己，又洁净耶和华你们列祖　神的殿，从圣所中除去污秽之物。"},
{volumn:        14,chapter:        29,versenumber:         6,verse:"我们列祖犯了罪，行耶和华我们　神眼中看为恶的事，离弃他，转脸背向他的居所，"},
{volumn:        14,chapter:        29,versenumber:         7,verse:"封锁廊门，吹灭灯火，不在圣所中向以色列　神烧香，或献燔祭。"},
{volumn:        14,chapter:        29,versenumber:         8,verse:"因此，耶和华的忿怒临到犹大和耶路撒冷，将其中的人抛来抛去，令人惊骇、嗤笑，正如你们亲眼所见的。"},
{volumn:        14,chapter:        29,versenumber:         9,verse:"所以我们的祖宗倒在刀下，我们的妻子儿女也被掳掠。"},
{volumn:        14,chapter:        29,versenumber:        10,verse:"现在我心中有意与耶和华以色列的　神立约，好使他的烈怒转离我们。"},
{volumn:        14,chapter:        29,versenumber:        11,verse:"我的众子啊，现在不要懈怠；因为耶和华拣选你们站在他面前侍奉他，与他烧香。”"},
{volumn:        14,chapter:        29,versenumber:        12,verse:"于是，利未人哥辖的子孙、亚玛赛的儿子玛哈，亚撒利雅的儿子约珥；米拉利的子孙、亚伯底的儿子基士，耶哈利勒的儿子亚撒利雅；革顺的子孙、薪玛的儿子约亚，约亚的儿子伊甸；"},
{volumn:        14,chapter:        29,versenumber:        13,verse:"以利撒反的子孙申利和耶利；亚萨的子孙撒迦利雅和玛探雅；"},
{volumn:        14,chapter:        29,versenumber:        14,verse:"希幔的子孙耶歇和示每；耶杜顿的子孙示玛雅和乌薛；"},
{volumn:        14,chapter:        29,versenumber:        15,verse:"起来聚集他们的弟兄，洁净自己，照着王的吩咐、耶和华的命令，进去洁净耶和华的殿。"},
{volumn:        14,chapter:        29,versenumber:        16,verse:"祭司进入耶和华的殿要洁净殿，将殿中所有污秽之物搬到耶和华殿的院内，利未人接去，搬到外头汲沦溪边。"},
{volumn:        14,chapter:        29,versenumber:        17,verse:"从正月初一日洁净起，初八日到了耶和华的殿廊，用八日的工夫洁净耶和华的殿，到正月十六日才洁净完了。"},
{volumn:        14,chapter:        29,versenumber:        18,verse:"于是，他们晋见希西家王，说：“我们已将耶和华的全殿和燔祭坛，并坛的一切器皿、陈设饼的桌子，与桌子的一切器皿都洁净了；"},
{volumn:        14,chapter:        29,versenumber:        19,verse:"并且亚哈斯王在位犯罪的时候所废弃的器皿，我们预备齐全，且洁净了，现今都在耶和华的坛前。”"},
{volumn:        14,chapter:        29,versenumber:        20,verse:"希西家王清早起来，聚集城里的首领都上耶和华的殿；"},
{volumn:        14,chapter:        29,versenumber:        21,verse:"牵了七只公牛，七只公羊，七只羊羔，七只公山羊，要为国、为殿、为犹大人作赎罪祭。王吩咐亚伦的子孙众祭司，献在耶和华的坛上，"},
{volumn:        14,chapter:        29,versenumber:        22,verse:"就宰了公牛，祭司接血洒在坛上，宰了公羊，把血洒在坛上，又宰了羊羔，也把血洒在坛上；"},
{volumn:        14,chapter:        29,versenumber:        23,verse:"把那作赎罪祭的公山羊牵到王和会众面前，他们就按手在其上。"},
{volumn:        14,chapter:        29,versenumber:        24,verse:"祭司宰了羊，将血献在坛上作赎罪祭，为以色列众人赎罪，因为王吩咐将燔祭和赎罪祭为以色列众人献上。"},
{volumn:        14,chapter:        29,versenumber:        25,verse:"王又派利未人在耶和华殿中敲钹，鼓瑟，弹琴，乃照大卫和他先见迦得，并先知拿单所吩咐的，就是耶和华藉先知所吩咐的。"},
{volumn:        14,chapter:        29,versenumber:        26,verse:"利未人拿大卫的乐器，祭司拿号，一同站立。"},
{volumn:        14,chapter:        29,versenumber:        27,verse:"希西家吩咐在坛上献燔祭，燔祭一献，就唱赞美耶和华的歌，用号，并用以色列王大卫的乐器相和。"},
{volumn:        14,chapter:        29,versenumber:        28,verse:"会众都敬拜，歌唱的歌唱，吹号的吹号，如此直到燔祭献完了。"},
{volumn:        14,chapter:        29,versenumber:        29,verse:"献完了祭，王和一切跟随的人都俯伏敬拜。"},
{volumn:        14,chapter:        29,versenumber:        30,verse:"希西家王与众首领又吩咐利未人用大卫和先见亚萨的诗词颂赞耶和华；他们就欢欢喜喜地颂赞耶和华，低头敬拜。"},
{volumn:        14,chapter:        29,versenumber:        31,verse:"希西家说：“你们既然归耶和华为圣，就要前来把祭物和感谢祭奉到耶和华殿里。”会众就把祭物和感谢祭奉来，凡甘心乐意的也将燔祭奉来。"},
{volumn:        14,chapter:        29,versenumber:        32,verse:"会众所奉的燔祭如下：公牛七十只，公羊一百只，羊羔二百只，这都是作燔祭献给耶和华的；"},
{volumn:        14,chapter:        29,versenumber:        33,verse:"又有分别为圣之物，公牛六百只，绵羊三千只。"},
{volumn:        14,chapter:        29,versenumber:        34,verse:"但祭司太少，不能剥尽燔祭牲的皮，所以他们的弟兄利未人帮助他们，直等燔祭的事完了，又等别的祭司自洁了；因为利未人诚心自洁，胜过祭司。"},
{volumn:        14,chapter:        29,versenumber:        35,verse:"燔祭和平安祭牲的脂油，并燔祭同献的奠祭甚多。这样，耶和华殿中的事务俱都齐备了（或译：就整顿了）。"},
{volumn:        14,chapter:        29,versenumber:        36,verse:"这事办的甚速，希西家和众民都喜乐，是因　神为众民所预备的。"},
{volumn:        14,chapter:        30,versenumber:         1,verse:"希西家差遣人去见以色列和犹大众人，又写信给以法莲和玛拿西人，叫他们到耶路撒冷耶和华的殿，向耶和华以色列的　神守逾越节；"},
{volumn:        14,chapter:        30,versenumber:         2,verse:"因为王和众首领，并耶路撒冷全会众已经商议，要在二月内守逾越节。"},
{volumn:        14,chapter:        30,versenumber:         3,verse:"正月（原文是那时）间他们不能守；因为自洁的祭司尚不敷用，百姓也没有聚集在耶路撒冷；"},
{volumn:        14,chapter:        30,versenumber:         4,verse:"王与全会众都以这事为善。"},
{volumn:        14,chapter:        30,versenumber:         5,verse:"于是定了命令，传遍以色列，从别是巴直到但，使他们都来，在耶路撒冷向耶和华以色列的　神守逾越节；因为照所写的例，守这节的不多了（或译：因为民许久没有照所写的例守节了）。"},
{volumn:        14,chapter:        30,versenumber:         6,verse:"驿卒就把王和众首领的信，遵着王命传遍以色列和犹大。信内说：“以色列人哪，你们当转向耶和华亚伯拉罕、以撒、以色列的　神，好叫他转向你们这脱离亚述王手的余民。"},
{volumn:        14,chapter:        30,versenumber:         7,verse:"你们不要效法你们列祖和你们的弟兄；他们干犯耶和华他们列祖的　神，以致耶和华丢弃他们，使他们败亡（或译：令人惊骇），正如你们所见的。"},
{volumn:        14,chapter:        30,versenumber:         8,verse:"现在不要像你们列祖硬着颈项，只要归顺耶和华，进入他的圣所，就是永远成圣的居所；又要侍奉耶和华你们的　神，好使他的烈怒转离你们。"},
{volumn:        14,chapter:        30,versenumber:         9,verse:"你们若转向耶和华，你们的弟兄和儿女必在掳掠他们的人面前蒙怜恤，得以归回这地，因为耶和华你们的　神有恩典、施怜悯。你们若转向他，他必不转脸不顾你们。”"},
{volumn:        14,chapter:        30,versenumber:        10,verse:"驿卒就由这城跑到那城，传遍了以法莲、玛拿西，直到西布伦。那里的人却戏笑他们，讥诮他们。"},
{volumn:        14,chapter:        30,versenumber:        11,verse:"然而亚设、玛拿西、西布伦中也有人自卑，来到耶路撒冷。"},
{volumn:        14,chapter:        30,versenumber:        12,verse:"神也感动犹大人，使他们一心遵行王与众首领凭耶和华之言所发的命令。"},
{volumn:        14,chapter:        30,versenumber:        13,verse:"二月，有许多人在耶路撒冷聚集，成为大会，要守除酵节。"},
{volumn:        14,chapter:        30,versenumber:        14,verse:"他们起来，把耶路撒冷的祭坛和烧香的坛尽都除去，抛在汲沦溪中。"},
{volumn:        14,chapter:        30,versenumber:        15,verse:"二月十四日，宰了逾越节的羊羔。祭司与利未人觉得惭愧，就洁净自己，把燔祭奉到耶和华殿中，"},
{volumn:        14,chapter:        30,versenumber:        16,verse:"遵着神人摩西的律法，照例站在自己的地方；祭司从利未人手里接过血来，洒在坛上。"},
{volumn:        14,chapter:        30,versenumber:        17,verse:"会中有许多人尚未自洁，所以利未人为一切不洁之人宰逾越节的羊羔，使他们在耶和华面前成为圣洁。"},
{volumn:        14,chapter:        30,versenumber:        18,verse:"以法莲、玛拿西、以萨迦、西布伦有许多人尚未自洁，他们却也吃逾越节的羊羔，不合所记录的定例。希西家为他们祷告说：“凡专心寻求　神，就是耶和华他列祖之　神的，虽不照着圣所洁净之礼自洁，求至善的耶和华也饶恕他。”"},
{volumn:        14,chapter:        30,versenumber:        19,verse:""},
{volumn:        14,chapter:        30,versenumber:        20,verse:"耶和华垂听希西家的祷告，就饶恕（原文是医治）百姓。"},
{volumn:        14,chapter:        30,versenumber:        21,verse:"在耶路撒冷的以色列人大大喜乐，守除酵节七日。利未人和祭司用响亮的乐器，日日颂赞耶和华。"},
{volumn:        14,chapter:        30,versenumber:        22,verse:"希西家慰劳一切善于侍奉耶和华的利未人。于是众人吃节筵七日，又献平安祭，且向耶和华他们列祖的　神认罪。"},
{volumn:        14,chapter:        30,versenumber:        23,verse:"全会众商议，要再守节七日；于是欢欢喜喜地又守节七日。"},
{volumn:        14,chapter:        30,versenumber:        24,verse:"犹大王希西家赐给会众公牛一千只，羊七千只为祭物；众首领也赐给会众公牛一千只，羊一万只，并有许多的祭司洁净自己。"},
{volumn:        14,chapter:        30,versenumber:        25,verse:"犹大全会众、祭司、利未人，并那从以色列地来的会众和寄居的人，以及犹大寄居的人，尽都喜乐。"},
{volumn:        14,chapter:        30,versenumber:        26,verse:"这样，在耶路撒冷大有喜乐，自从以色列王大卫儿子所罗门的时候，在耶路撒冷没有这样的喜乐。"},
{volumn:        14,chapter:        30,versenumber:        27,verse:"那时，祭司、利未人起来，为民祝福。他们的声音蒙　神垂听，他们的祷告达到天上的圣所。"},
{volumn:        14,chapter:        31,versenumber:         1,verse:"这事既都完毕，在那里的以色列众人就到犹大的城邑，打碎柱像，砍断木偶，又在犹大、便雅悯、以法莲、玛拿西遍地将邱坛和祭坛拆毁净尽。于是以色列众人各回各城，各归各地。"},
{volumn:        14,chapter:        31,versenumber:         2,verse:"希西家派定祭司利未人的班次，各按各职献燔祭和平安祭，又在耶和华殿（原文是营）门内侍奉，称谢颂赞耶和华。"},
{volumn:        14,chapter:        31,versenumber:         3,verse:"王又从自己的产业中定出分来为燔祭，就是早晚的燔祭和安息日、月朔，并节期的燔祭，都是按耶和华律法上所载的；"},
{volumn:        14,chapter:        31,versenumber:         4,verse:"又吩咐住耶路撒冷的百姓将祭司、利未人所应得的分给他们，使他们专心遵守耶和华的律法。"},
{volumn:        14,chapter:        31,versenumber:         5,verse:"谕旨一出，以色列人就把初熟的五谷、新酒、油、蜜，和田地的出产多多送来，又把各物的十分之一送来的极多。"},
{volumn:        14,chapter:        31,versenumber:         6,verse:"住犹大各城的以色列人和犹大人也将牛羊的十分之一，并分别为圣归耶和华他们　神之物，就是十分取一之物，尽都送来积成堆垒。"},
{volumn:        14,chapter:        31,versenumber:         7,verse:"从三月积起，到七月才完。"},
{volumn:        14,chapter:        31,versenumber:         8,verse:"希西家和众首领来，看见堆垒，就称颂耶和华，又为耶和华的民以色列人祝福。"},
{volumn:        14,chapter:        31,versenumber:         9,verse:"希西家向祭司、利未人查问这堆垒。"},
{volumn:        14,chapter:        31,versenumber:        10,verse:"撒督家的大祭司亚撒利雅回答说：“自从民将供物送到耶和华殿以来，我们不但吃饱，且剩下的甚多；因为耶和华赐福给他的民，所剩下的才这样丰盛。”"},
{volumn:        14,chapter:        31,versenumber:        11,verse:"希西家吩咐在耶和华殿里预备仓房，他们就预备了。"},
{volumn:        14,chapter:        31,versenumber:        12,verse:"他们诚心将供物和十分取一之物，并分别为圣之物，都搬入仓内。利未人歌楠雅掌管这事，他兄弟示每为副管。"},
{volumn:        14,chapter:        31,versenumber:        13,verse:"耶歇、亚撒细雅、拿哈、亚撒黑、耶利末、约撒拔、以列、伊斯玛基雅、玛哈、比拿雅都是督理，在歌楠雅和他兄弟示每的手下，是希西家王和管理　神殿的亚撒利雅所派的。"},
{volumn:        14,chapter:        31,versenumber:        14,verse:"守东门的利未人音拿的儿子可利，掌管乐意献与　神的礼物，发放献与耶和华的供物和至圣的物。"},
{volumn:        14,chapter:        31,versenumber:        15,verse:"在他手下有伊甸、珉雅珉、耶书亚、示玛雅、亚玛利雅、示迦尼雅，在祭司的各城里供紧要的职任，无论弟兄大小，都按着班次分给他们。"},
{volumn:        14,chapter:        31,versenumber:        16,verse:"按家谱，三岁以外的男丁，凡每日进耶和华殿、按班次供职的，也分给他；"},
{volumn:        14,chapter:        31,versenumber:        17,verse:"又按宗族家谱分给祭司，按班次职任分给二十岁以外的利未人，"},
{volumn:        14,chapter:        31,versenumber:        18,verse:"又按家谱计算，分给他们会中的妻子、儿女；因他们身供要职，自洁成圣。"},
{volumn:        14,chapter:        31,versenumber:        19,verse:"按名派定的人要把应得的分给亚伦子孙，住在各城郊野、祭司所有的男丁和一切载入家谱的利未人。"},
{volumn:        14,chapter:        31,versenumber:        20,verse:"希西家在犹大遍地这样办理，行耶和华他　神眼中看为善为正为忠的事。"},
{volumn:        14,chapter:        31,versenumber:        21,verse:"凡他所行的，无论是办　神殿的事，是遵律法守诫命，是寻求他的　神，都是尽心去行，无不亨通。"},
{volumn:        14,chapter:        32,versenumber:         1,verse:"这虔诚的事以后，亚述王西拿基立来侵入犹大，围困一切坚固城，想要攻破占据。"},
{volumn:        14,chapter:        32,versenumber:         2,verse:"希西家见西拿基立来，定意要攻打耶路撒冷，"},
{volumn:        14,chapter:        32,versenumber:         3,verse:"就与首领和勇士商议塞住城外的泉源，他们就都帮助他。"},
{volumn:        14,chapter:        32,versenumber:         4,verse:"于是有许多人聚集，塞了一切泉源，并通流国中的小河，说：“亚述王来，为何让他得着许多水呢？”"},
{volumn:        14,chapter:        32,versenumber:         5,verse:"希西家力图自强，就修筑所有拆毁的城墙，高与城楼相齐；在城外又筑一城，坚固大卫城的米罗，制造了许多军器、盾牌；"},
{volumn:        14,chapter:        32,versenumber:         6,verse:"设立军长管理百姓，将他们招聚在城门的宽阔处，用话勉励他们，说："},
{volumn:        14,chapter:        32,versenumber:         7,verse:"“你们当刚强壮胆，不要因亚述王和跟随他的大军恐惧、惊慌；因为与我们同在的，比与他们同在的更大。"},
{volumn:        14,chapter:        32,versenumber:         8,verse:"与他们同在的是肉臂，与我们同在的是耶和华我们的　神，他必帮助我们，为我们争战。”百姓就靠犹大王希西家的话，安然无惧了。"},
{volumn:        14,chapter:        32,versenumber:         9,verse:"此后，亚述王西拿基立和他的全军攻打拉吉，就差遣臣仆到耶路撒冷见犹大王希西家和一切在耶路撒冷的犹大人，说："},
{volumn:        14,chapter:        32,versenumber:        10,verse:"“亚述王西拿基立如此说：你们倚靠什么，还在耶路撒冷受困呢？"},
{volumn:        14,chapter:        32,versenumber:        11,verse:"希西家对你们说'耶和华我们的　神必救我们脱离亚述王的手'，这不是诱惑你们，使你们受饥渴而死吗？"},
{volumn:        14,chapter:        32,versenumber:        12,verse:"这希西家岂不是废去耶和华的邱坛和祭坛，吩咐犹大与耶路撒冷的人说‘你们当在一个坛前敬拜，在其上烧香’吗？"},
{volumn:        14,chapter:        32,versenumber:        13,verse:"我与我列祖向列邦所行的，你们岂不知道吗？列邦的神何尝能救自己的国脱离我手呢？"},
{volumn:        14,chapter:        32,versenumber:        14,verse:"我列祖所灭的国，那些神中谁能救自己的民脱离我手呢？难道你们的神能救你们脱离我手吗？"},
{volumn:        14,chapter:        32,versenumber:        15,verse:"所以你们不要叫希西家这样欺哄诱惑你们，也不要信他；因为没有一国一邦的神能救自己的民脱离我手和我列祖的手，何况你们的神更不能救你们脱离我的手。”"},
{volumn:        14,chapter:        32,versenumber:        16,verse:"西拿基立的臣仆还有别的话毁谤耶和华　神和他仆人希西家。"},
{volumn:        14,chapter:        32,versenumber:        17,verse:"西拿基立也写信毁谤耶和华以色列的　神说：“列邦的神既不能救他的民脱离我手，希西家的神也不能救他的民脱离我手了。”"},
{volumn:        14,chapter:        32,versenumber:        18,verse:"亚述王的臣仆用犹大言语向耶路撒冷城上的民大声呼叫，要惊吓他们，扰乱他们，以便取城。"},
{volumn:        14,chapter:        32,versenumber:        19,verse:"他们论耶路撒冷的　神，如同论世上人手所造的神一样。"},
{volumn:        14,chapter:        32,versenumber:        20,verse:"希西家王和亚摩斯的儿子先知以赛亚因此祷告，向天呼求。"},
{volumn:        14,chapter:        32,versenumber:        21,verse:"耶和华就差遣一个使者进入亚述王营中，把所有大能的勇士和官长、将帅尽都灭了。亚述王满面含羞地回到本国，进了他神的庙中，有他亲生的儿子在那里用刀杀了他。"},
{volumn:        14,chapter:        32,versenumber:        22,verse:"这样，耶和华救希西家和耶路撒冷的居民脱离亚述王西拿基立的手，也脱离一切仇敌的手，又赐他们四境平安。"},
{volumn:        14,chapter:        32,versenumber:        23,verse:"有许多人到耶路撒冷，将供物献与耶和华，又将宝物送给犹大王希西家。此后，希西家在列邦人的眼中看为尊大。"},
{volumn:        14,chapter:        32,versenumber:        24,verse:"那时希西家病得要死，就祷告耶和华，耶和华应允他，赐他一个兆头。"},
{volumn:        14,chapter:        32,versenumber:        25,verse:"希西家却没有照他所蒙的恩报答耶和华；因他心里骄傲，所以忿怒要临到他和犹大并耶路撒冷。"},
{volumn:        14,chapter:        32,versenumber:        26,verse:"但希西家和耶路撒冷的居民觉得心里骄傲，就一同自卑，以致耶和华的忿怒在希西家的日子没有临到他们。"},
{volumn:        14,chapter:        32,versenumber:        27,verse:"希西家大有尊荣资财，建造府库，收藏金银、宝石、香料、盾牌，和各样的宝器，"},
{volumn:        14,chapter:        32,versenumber:        28,verse:"又建造仓房，收藏五谷、新酒，和油，又为各类牲畜盖棚立圈；"},
{volumn:        14,chapter:        32,versenumber:        29,verse:"并且建立城邑，还有许多的羊群牛群，因为　神赐他极多的财产。"},
{volumn:        14,chapter:        32,versenumber:        30,verse:"这希西家也塞住基训的上源，引水直下，流在大卫城的西边。希西家所行的事尽都亨通。"},
{volumn:        14,chapter:        32,versenumber:        31,verse:"惟有一件事，就是巴比伦王差遣使者来见希西家，访问国中所现的奇事；这件事　神离开他，要试验他，好知道他心内如何。"},
{volumn:        14,chapter:        32,versenumber:        32,verse:"希西家其余的事和他的善行都写在亚摩斯的儿子先知以赛亚的默示书上和犹大、以色列的诸王记上。"},
{volumn:        14,chapter:        32,versenumber:        33,verse:"希西家与他列祖同睡，葬在大卫子孙的高陵上。他死的时候，犹大人和耶路撒冷的居民都尊敬他。他儿子玛拿西接续他作王。"},
{volumn:        14,chapter:        33,versenumber:         1,verse:"玛拿西登基的时候年十二岁，在耶路撒冷作王五十五年。"},
{volumn:        14,chapter:        33,versenumber:         2,verse:"他行耶和华眼中看为恶的事，效法耶和华在以色列人面前赶出的外邦人那可憎的事，"},
{volumn:        14,chapter:        33,versenumber:         3,verse:"重新建筑他父希西家所拆毁的邱坛，又为巴力筑坛，做木偶，且敬拜侍奉天上的万象，"},
{volumn:        14,chapter:        33,versenumber:         4,verse:"在耶和华的殿宇中筑坛。耶和华曾指着这殿说：“我的名必永远在耶路撒冷。”"},
{volumn:        14,chapter:        33,versenumber:         5,verse:"他在耶和华殿的两院中为天上的万象筑坛，"},
{volumn:        14,chapter:        33,versenumber:         6,verse:"并在欣嫩子谷使他的儿女经火，又观兆，用法术，行邪术，立交鬼的和行巫术的，多行耶和华眼中看为恶的事，惹动他的怒气，"},
{volumn:        14,chapter:        33,versenumber:         7,verse:"又在　神殿内立雕刻的偶像。　神曾对大卫和他儿子所罗门说：“我在以色列各支派中所选择的耶路撒冷和这殿，必立我的名直到永远。"},
{volumn:        14,chapter:        33,versenumber:         8,verse:"以色列人若谨守遵行我藉摩西所吩咐他们的一切法度、律例、典章，我就不再使他们挪移离开我所赐给他们列祖之地。”"},
{volumn:        14,chapter:        33,versenumber:         9,verse:"玛拿西引诱犹大和耶路撒冷的居民，以致他们行恶比耶和华在以色列人面前所灭的列国更甚。"},
{volumn:        14,chapter:        33,versenumber:        10,verse:"耶和华警戒玛拿西和他的百姓，他们却是不听。"},
{volumn:        14,chapter:        33,versenumber:        11,verse:"所以耶和华使亚述王的将帅来攻击他们，用铙钩钩住玛拿西，用铜链锁住他，带到巴比伦去。"},
{volumn:        14,chapter:        33,versenumber:        12,verse:"他在急难的时候，就恳求耶和华他的　神，且在他列祖的　神面前极其自卑。"},
{volumn:        14,chapter:        33,versenumber:        13,verse:"他祈祷耶和华，耶和华就允准他的祈求，垂听他的祷告，使他归回耶路撒冷，仍坐国位。玛拿西这才知道惟独耶和华是　神。"},
{volumn:        14,chapter:        33,versenumber:        14,verse:"此后，玛拿西在大卫城外，从谷内基训西边直到鱼门口，建筑城墙，环绕俄斐勒，这墙筑得甚高；又在犹大各坚固城内设立勇敢的军长；"},
{volumn:        14,chapter:        33,versenumber:        15,verse:"并除掉外邦人的神像与耶和华殿中的偶像，又将他在耶和华殿的山上和耶路撒冷所筑的各坛都拆毁抛在城外；"},
{volumn:        14,chapter:        33,versenumber:        16,verse:"重修耶和华的祭坛，在坛上献平安祭、感谢祭，吩咐犹大人侍奉耶和华以色列的　神。"},
{volumn:        14,chapter:        33,versenumber:        17,verse:"百姓却仍在邱坛上献祭，只献给耶和华他们的　神。"},
{volumn:        14,chapter:        33,versenumber:        18,verse:"玛拿西其余的事和祷告他　神的话，并先见奉耶和华以色列　神的名警戒他的言语，都写在以色列诸王记上。"},
{volumn:        14,chapter:        33,versenumber:        19,verse:"他的祷告，与　神怎样应允他，他未自卑以前的罪愆过犯，并在何处建筑邱坛，设立亚舍拉和雕刻的偶像，都写在何赛的书上。"},
{volumn:        14,chapter:        33,versenumber:        20,verse:"玛拿西与他列祖同睡，葬在自己的宫院里。他儿子亚们接续他作王。"},
{volumn:        14,chapter:        33,versenumber:        21,verse:"亚们登基的时候年二十二岁，在耶路撒冷作王二年。"},
{volumn:        14,chapter:        33,versenumber:        22,verse:"他行耶和华眼中看为恶的事，效法他父玛拿西所行的，祭祀侍奉他父玛拿西所雕刻的偶像，"},
{volumn:        14,chapter:        33,versenumber:        23,verse:"不在耶和华面前像他父玛拿西自卑。这亚们所犯的罪越犯越大。"},
{volumn:        14,chapter:        33,versenumber:        24,verse:"他的臣仆背叛，在宫里杀了他。"},
{volumn:        14,chapter:        33,versenumber:        25,verse:"但国民杀了那些背叛亚们王的人，立他儿子约西亚接续他作王。"},
{volumn:        14,chapter:        34,versenumber:         1,verse:"约西亚登基的时候年八岁，在耶路撒冷作王三十一年。"},
{volumn:        14,chapter:        34,versenumber:         2,verse:"他行耶和华眼中看为正的事，效法他祖大卫所行的，不偏左右。"},
{volumn:        14,chapter:        34,versenumber:         3,verse:"他作王第八年，尚且年幼，就寻求他祖大卫的　神。到了十二年才洁净犹大和耶路撒冷，除掉邱坛、木偶、雕刻的像，和铸造的像。"},
{volumn:        14,chapter:        34,versenumber:         4,verse:"众人在他面前拆毁巴力的坛，砍断坛上高高的日像，又把木偶和雕刻的像，并铸造的像打碎成灰，撒在祭偶像人的坟上，"},
{volumn:        14,chapter:        34,versenumber:         5,verse:"将他们祭司的骸骨烧在坛上，洁净了犹大和耶路撒冷；"},
{volumn:        14,chapter:        34,versenumber:         6,verse:"又在玛拿西、以法莲、西缅、拿弗他利各城，和四围破坏之处，都这样行；"},
{volumn:        14,chapter:        34,versenumber:         7,verse:"又拆毁祭坛，把木偶和雕刻的像打碎成灰，砍断以色列遍地所有的日像，就回耶路撒冷去了。"},
{volumn:        14,chapter:        34,versenumber:         8,verse:"约西亚王十八年，净地净殿之后，就差遣亚萨利雅的儿子沙番、邑宰玛西雅、约哈斯的儿子史官约亚去修理耶和华他神的殿。"},
{volumn:        14,chapter:        34,versenumber:         9,verse:"他们就去见大祭司希勒家，将奉到　神殿的银子交给他；这银子是看守殿门的利未人从玛拿西、以法莲，和一切以色列剩下的人，以及犹大、便雅悯众人，并耶路撒冷的居民收来的。"},
{volumn:        14,chapter:        34,versenumber:        10,verse:"又将这银子交给耶和华殿里督工的，转交修理耶和华殿的工匠，"},
{volumn:        14,chapter:        34,versenumber:        11,verse:"就是交给木匠、石匠，买凿成的石头和架木与栋梁，修犹大王所毁坏的殿。"},
{volumn:        14,chapter:        34,versenumber:        12,verse:"这些人办事诚实，督工的是利未人米拉利的子孙雅哈、俄巴底；督催的是哥辖的子孙撒迦利亚、米书兰；还有善于作乐的利未人。"},
{volumn:        14,chapter:        34,versenumber:        13,verse:"他们又监管扛抬的人，督催一切做工的。利未人中也有作书记、作司事、作守门的。"},
{volumn:        14,chapter:        34,versenumber:        14,verse:"他们将奉到耶和华殿的银子运出来的时候，祭司希勒家偶然得了摩西所传耶和华的律法书。"},
{volumn:        14,chapter:        34,versenumber:        15,verse:"希勒家对书记沙番说：“我在耶和华殿里得了律法书。”遂将书递给沙番。"},
{volumn:        14,chapter:        34,versenumber:        16,verse:"沙番把书拿到王那里，回复王说：“凡交给仆人们办的都办理了。"},
{volumn:        14,chapter:        34,versenumber:        17,verse:"耶和华殿里的银子倒出来，交给督工的和匠人的手里了。”"},
{volumn:        14,chapter:        34,versenumber:        18,verse:"书记沙番又对王说：“祭司希勒家递给我一卷书。”沙番就在王面前读那书。"},
{volumn:        14,chapter:        34,versenumber:        19,verse:"王听见律法上的话，就撕裂衣服，"},
{volumn:        14,chapter:        34,versenumber:        20,verse:"吩咐希勒家与沙番的儿子亚希甘、米迦的儿子亚比顿、书记沙番，和王的臣仆亚撒雅说："},
{volumn:        14,chapter:        34,versenumber:        21,verse:"“你们去为我、为以色列和犹大剩下的人，以这书上的话求问耶和华；因我们列祖没有遵守耶和华的言语，没有照这书上所记的去行，耶和华的烈怒就倒在我们身上。”"},
{volumn:        14,chapter:        34,versenumber:        22,verse:"于是，希勒家和王所派的众人都去见女先知户勒大。户勒大是掌管礼服沙龙的妻，沙龙是哈斯拉的孙子、特瓦的儿子。户勒大住在耶路撒冷第二区；他们请问于她。"},
{volumn:        14,chapter:        34,versenumber:        23,verse:"她对他们说：“耶和华以色列的　神如此说：‘你们可以回复那差遣你们来见我的人说，"},
{volumn:        14,chapter:        34,versenumber:        24,verse:"耶和华如此说：我必照着在犹大王面前所读那书上的一切咒诅，降祸与这地和其上的居民；"},
{volumn:        14,chapter:        34,versenumber:        25,verse:"因为他们离弃我，向别神烧香，用他们手所做的惹我发怒，所以我的忿怒如火倒在这地上，总不息灭。’"},
{volumn:        14,chapter:        34,versenumber:        26,verse:"然而差遣你们来求问耶和华的犹大王，你们要这样回复他说：‘耶和华以色列的　神如此说：至于你所听见的话，"},
{volumn:        14,chapter:        34,versenumber:        27,verse:"就是听见我指着这地和其上居民所说的话，你便心里敬服，在我面前自卑，撕裂衣服，向我哭泣，因此我应允了你。这是我耶和华说的。"},
{volumn:        14,chapter:        34,versenumber:        28,verse:"我必使你平平安安地归到坟墓，到你列祖那里，我要降与这地和其上居民的一切灾祸，你也不至亲眼看见。’”他们就回复王去了。"},
{volumn:        14,chapter:        34,versenumber:        29,verse:"王差遣人招聚犹大和耶路撒冷的众长老来。"},
{volumn:        14,chapter:        34,versenumber:        30,verse:"王和犹大众人，与耶路撒冷的居民，并祭司利未人，以及所有的百姓，无论大小，都一同上到耶和华的殿；王就把殿里所得的约书念给他们听。"},
{volumn:        14,chapter:        34,versenumber:        31,verse:"王站在他的地位上，在耶和华面前立约，要尽心尽性地顺从耶和华，遵守他的诫命、法度、律例，成就这书上所记的约言；"},
{volumn:        14,chapter:        34,versenumber:        32,verse:"又使住耶路撒冷和便雅悯的人都服从这约。于是耶路撒冷的居民都遵行他们列祖之　神的约。"},
{volumn:        14,chapter:        34,versenumber:        33,verse:"约西亚从以色列各处将一切可憎之物尽都除掉，使以色列境内的人都侍奉耶和华他们的　神。约西亚在世的日子，就跟从耶和华他们列祖的　神，总不离开。"},
{volumn:        14,chapter:        35,versenumber:         1,verse:"约西亚在耶路撒冷向耶和华守逾越节。正月十四日，就宰了逾越节的羊羔。"},
{volumn:        14,chapter:        35,versenumber:         2,verse:"王分派祭司各尽其职，又勉励他们办耶和华殿中的事；"},
{volumn:        14,chapter:        35,versenumber:         3,verse:"又对那归耶和华为圣、教训以色列人的利未人说：“你们将圣约柜安放在以色列王大卫儿子所罗门建造的殿里，不必再用肩扛抬。现在要侍奉耶和华你们的　神，服侍他的民以色列。"},
{volumn:        14,chapter:        35,versenumber:         4,verse:"你们应当按着宗族，照着班次，遵以色列王大卫和他儿子所罗门所写的，自己预备。"},
{volumn:        14,chapter:        35,versenumber:         5,verse:"要按着你们的弟兄，这民宗族的班次，站在圣所，每班中要利未宗族的几个人。"},
{volumn:        14,chapter:        35,versenumber:         6,verse:"要宰逾越节的羊羔，洁净自己，为你们的弟兄预备了，好遵守耶和华藉摩西所吩咐的话。”"},
{volumn:        14,chapter:        35,versenumber:         7,verse:"约西亚从群畜中赐给在那里所有的人民，绵羊羔和山羊羔三万只，牛三千只，作逾越节的祭物；这都是出自王的产业中。"},
{volumn:        14,chapter:        35,versenumber:         8,verse:"约西亚的众首领也乐意将牺牲给百姓和祭司利未人；又有管理　神殿的希勒家、撒迦利亚、耶歇将羊羔二千六百只，牛三百只，给祭司作逾越节的祭物。"},
{volumn:        14,chapter:        35,versenumber:         9,verse:"利未人的族长歌楠雅和他两个兄弟示玛雅、拿坦业，与哈沙比雅、耶利、约撒拔将羊羔五千只，牛五百只，给利未人作逾越节的祭物。"},
{volumn:        14,chapter:        35,versenumber:        10,verse:"这样，供献的事齐备了。祭司站在自己的地方，利未人按着班次站立，都是照王所吩咐的。"},
{volumn:        14,chapter:        35,versenumber:        11,verse:"利未人宰了逾越节的羊羔，祭司从他们手里接过血来洒在坛上；利未人剥皮，"},
{volumn:        14,chapter:        35,versenumber:        12,verse:"将燔祭搬来，按着宗族的班次分给众民，好照摩西书上所写的，献给耶和华；献牛也是这样。"},
{volumn:        14,chapter:        35,versenumber:        13,verse:"他们按着常例，用火烤逾越节的羊羔。别的圣物用锅，用釜，用罐煮了，速速地送给众民。"},
{volumn:        14,chapter:        35,versenumber:        14,verse:"然后为自己和祭司预备祭物；因为祭司亚伦的子孙献燔祭和脂油，直到晚上。所以利未人为自己和祭司亚伦的子孙，预备祭物。"},
{volumn:        14,chapter:        35,versenumber:        15,verse:"歌唱的亚萨之子孙，照着大卫、亚萨、希幔，和王的先见耶杜顿所吩咐的，站在自己的地位上。守门的看守各门，不用离开他们的职事，因为他们的弟兄利未人给他们预备祭物。"},
{volumn:        14,chapter:        35,versenumber:        16,verse:"当日，供奉耶和华的事齐备了，就照约西亚王的吩咐守逾越节，献燔祭在耶和华的坛上。"},
{volumn:        14,chapter:        35,versenumber:        17,verse:"当时在耶路撒冷的以色列人守逾越节，又守除酵节七日。"},
{volumn:        14,chapter:        35,versenumber:        18,verse:"自从先知撒母耳以来，在以色列中没有守过这样的逾越节，以色列诸王也没有守过，像约西亚、祭司、利未人、在那里的犹大人，和以色列人，以及耶路撒冷居民所守的逾越节。"},
{volumn:        14,chapter:        35,versenumber:        19,verse:"这逾越节是约西亚作王十八年守的。"},
{volumn:        14,chapter:        35,versenumber:        20,verse:"这事以后，约西亚修完了殿，有埃及王尼哥上来，要攻击靠近幼发拉底河的迦基米施；约西亚出去抵挡他。"},
{volumn:        14,chapter:        35,versenumber:        21,verse:"他差遣使者来见约西亚，说：“犹大王啊，我与你何干？我今日来不是要攻击你，乃是要攻击与我争战之家，并且　神吩咐我速行，你不要干预　神的事，免得他毁灭你，因为　神是与我同在。”"},
{volumn:        14,chapter:        35,versenumber:        22,verse:"约西亚却不肯转去离开他，改装要与他打仗，不听从　神藉尼哥之口所说的话，便来到米吉多平原争战。"},
{volumn:        14,chapter:        35,versenumber:        23,verse:"弓箭手射中约西亚王。王对他的臣仆说：“我受了重伤，你拉我出阵吧！”"},
{volumn:        14,chapter:        35,versenumber:        24,verse:"他的臣仆扶他下了战车，上了次车，送他到耶路撒冷，他就死了，葬在他列祖的坟墓里。犹大人和耶路撒冷人都为他悲哀。"},
{volumn:        14,chapter:        35,versenumber:        25,verse:"耶利米为约西亚作哀歌。所有歌唱的男女也唱哀歌，追悼约西亚，直到今日；而且在以色列中成了定例。这歌载在哀歌书上。"},
{volumn:        14,chapter:        35,versenumber:        26,verse:"约西亚其余的事和他遵着耶和华律法上所记而行的善事，"},
{volumn:        14,chapter:        35,versenumber:        27,verse:"并他自始至终所行的，都写在以色列和犹大列王记上。"},
{volumn:        14,chapter:        36,versenumber:         1,verse:"国民立约西亚的儿子约哈斯在耶路撒冷接续他父作王。"},
{volumn:        14,chapter:        36,versenumber:         2,verse:"约哈斯登基的时候年二十三岁，在耶路撒冷作王三个月。"},
{volumn:        14,chapter:        36,versenumber:         3,verse:"埃及王在耶路撒冷废了他，又罚犹大国银子一百他连得，金子一他连得。"},
{volumn:        14,chapter:        36,versenumber:         4,verse:"埃及王尼哥立约哈斯的哥哥以利雅敬作犹大和耶路撒冷的王，改名叫约雅敬，又将约哈斯带到埃及去了。"},
{volumn:        14,chapter:        36,versenumber:         5,verse:"约雅敬登基的时候年二十五岁，在耶路撒冷作王十一年，行耶和华他　神眼中看为恶的事。"},
{volumn:        14,chapter:        36,versenumber:         6,verse:"巴比伦王尼布甲尼撒上来攻击他，用铜链锁着他，要将他带到巴比伦去。"},
{volumn:        14,chapter:        36,versenumber:         7,verse:"尼布甲尼撒又将耶和华殿里的器皿带到巴比伦，放在他神的庙里（或译：自己的宫里）。"},
{volumn:        14,chapter:        36,versenumber:         8,verse:"约雅敬其余的事和他所行可憎的事，并他一切的行为，都写在以色列和犹大列王记上。他儿子约雅斤接续他作王。"},
{volumn:        14,chapter:        36,versenumber:         9,verse:"约雅斤登基的时候年八岁（在列王下二十四章八节是十八岁），在耶路撒冷作王三个月零十天，行耶和华眼中看为恶的事。"},
{volumn:        14,chapter:        36,versenumber:        10,verse:"过了一年，尼布甲尼撒差遣人将约雅斤和耶和华殿里各样宝贵的器皿带到巴比伦，就立约雅斤的叔叔（原文是兄）西底家作犹大和耶路撒冷的王。"},
{volumn:        14,chapter:        36,versenumber:        11,verse:"西底家登基的时候年二十一岁，在耶路撒冷作王十一年，"},
{volumn:        14,chapter:        36,versenumber:        12,verse:"行耶和华他　神眼中看为恶的事。先知耶利米以耶和华的话劝他，他仍不在耶利米面前自卑。"},
{volumn:        14,chapter:        36,versenumber:        13,verse:"尼布甲尼撒曾使他指着　神起誓，他却背叛，强项硬心，不归服耶和华以色列的　神。"},
{volumn:        14,chapter:        36,versenumber:        14,verse:"众祭司长和百姓也大大犯罪，效法外邦人一切可憎的事，污秽耶和华在耶路撒冷分别为圣的殿。"},
{volumn:        14,chapter:        36,versenumber:        15,verse:"耶和华他们列祖的　神因为爱惜自己的民和他的居所，从早起来差遣使者去警戒他们。"},
{volumn:        14,chapter:        36,versenumber:        16,verse:"他们却嘻笑　神的使者，藐视他的言语，讥诮他的先知，以致耶和华的忿怒向他的百姓发作，无法可救。"},
{volumn:        14,chapter:        36,versenumber:        17,verse:"所以，耶和华使迦勒底人的王来攻击他们，在他们圣殿里用刀杀了他们的壮丁，不怜恤他们的少男处女、老人白叟。耶和华将他们都交在迦勒底王手里。"},
{volumn:        14,chapter:        36,versenumber:        18,verse:"迦勒底王将　神殿里的大小器皿与耶和华殿里的财宝，并王和众首领的财宝，都带到巴比伦去了。"},
{volumn:        14,chapter:        36,versenumber:        19,verse:"迦勒底人焚烧　神的殿，拆毁耶路撒冷的城墙，用火烧了城里的宫殿，毁坏了城里宝贵的器皿。"},
{volumn:        14,chapter:        36,versenumber:        20,verse:"凡脱离刀剑的，迦勒底王都掳到巴比伦去，作他和他子孙的仆婢，直到波斯国兴起来。"},
{volumn:        14,chapter:        36,versenumber:        21,verse:"这就应验耶和华藉耶利米口所说的话：地享受安息；因为地土荒凉便守安息，直满了七十年。"},
{volumn:        14,chapter:        36,versenumber:        22,verse:"波斯王居鲁士元年，耶和华为要应验藉耶利米口所说的话，就激动波斯王居鲁士的心，使他下诏通告全国，说："},
{volumn:        14,chapter:        36,versenumber:        23,verse:"“波斯王居鲁士如此说：耶和华天上的　神已将天下万国赐给我，又嘱咐我在犹大的耶路撒冷为他建造殿宇。你们中间凡作他子民的，可以上去，愿耶和华他的　神与他同在。”"},
{volumn:        15,chapter:         1,versenumber:         1,verse:"波斯王居鲁士元年，耶和华为要应验藉耶利米口所说的话，就激动波斯王居鲁士的心，使他下诏通告全国说："},
{volumn:        15,chapter:         1,versenumber:         2,verse:"“波斯王居鲁士如此说：‘耶和华天上的　神已将天下万国赐给我，又嘱咐我在犹大的耶路撒冷为他建造殿宇。"},
{volumn:        15,chapter:         1,versenumber:         3,verse:"在你们中间凡作他子民的，可以上犹大的耶路撒冷，在耶路撒冷重建耶和华以色列　神的殿（只有他是　神）。愿　神与这人同在。"},
{volumn:        15,chapter:         1,versenumber:         4,verse:"凡剩下的人，无论寄居何处，那地的人要用金银、财物、牲畜帮助他，另外也要为耶路撒冷　神的殿甘心献上礼物。’”"},
{volumn:        15,chapter:         1,versenumber:         5,verse:"于是，犹大和便雅悯的族长、祭司、利未人，就是一切被　神激动他心的人，都起来要上耶路撒冷去建造耶和华的殿。"},
{volumn:        15,chapter:         1,versenumber:         6,verse:"他们四围的人就拿银器、金子、财物、牲畜、珍宝帮助他们（原文是坚固他们的手），另外还有甘心献的礼物。"},
{volumn:        15,chapter:         1,versenumber:         7,verse:"居鲁士王也将耶和华殿的器皿拿出来，这器皿是尼布甲尼撒从耶路撒冷掠来、放在自己神之庙中的。"},
{volumn:        15,chapter:         1,versenumber:         8,verse:"波斯王居鲁士派库官米提利达将这器皿拿出来，按数交给犹大的首领设巴萨。"},
{volumn:        15,chapter:         1,versenumber:         9,verse:"器皿的数目记在下面：金盘三十个，银盘一千个，刀二十九把，"},
{volumn:        15,chapter:         1,versenumber:        10,verse:"金碗三十个，银碗之次的四百一十个，别样的器皿一千件。"},
{volumn:        15,chapter:         1,versenumber:        11,verse:"金银器皿共有五千四百件。被掳的人从巴比伦上耶路撒冷的时候，设巴萨将这一切都带上来。"},
{volumn:        15,chapter:         2,versenumber:         1,verse:"巴比伦王尼布甲尼撒从前掳到巴比伦之犹大省的人，现在他们的子孙从被掳到之地回耶路撒冷和犹大，各归本城。"},
{volumn:        15,chapter:         2,versenumber:         2,verse:"他们是同着所罗巴伯、耶书亚、尼希米、西莱雅、利来雅、末底改、必珊、米斯拔、比革瓦伊、利宏、巴拿回来的。"},
{volumn:        15,chapter:         2,versenumber:         3,verse:"以色列人民的数目记在下面：巴录的子孙二千一百七十二名；"},
{volumn:        15,chapter:         2,versenumber:         4,verse:"示法提雅的子孙三百七十二名；"},
{volumn:        15,chapter:         2,versenumber:         5,verse:"亚拉的子孙七百七十五名；"},
{volumn:        15,chapter:         2,versenumber:         6,verse:"巴哈·摩押的后裔，就是耶书亚和约押的子孙二千八百一十二名；"},
{volumn:        15,chapter:         2,versenumber:         7,verse:"以拦的子孙一千二百五十四名；"},
{volumn:        15,chapter:         2,versenumber:         8,verse:"萨土的子孙九百四十五名；"},
{volumn:        15,chapter:         2,versenumber:         9,verse:"萨改的子孙七百六十名；"},
{volumn:        15,chapter:         2,versenumber:        10,verse:"巴尼的子孙六百四十二名；"},
{volumn:        15,chapter:         2,versenumber:        11,verse:"比拜的子孙六百二十三名；"},
{volumn:        15,chapter:         2,versenumber:        12,verse:"押甲的子孙一千二百二十二名；"},
{volumn:        15,chapter:         2,versenumber:        13,verse:"亚多尼干的子孙六百六十六名；"},
{volumn:        15,chapter:         2,versenumber:        14,verse:"比革瓦伊的子孙二千零五十六名；"},
{volumn:        15,chapter:         2,versenumber:        15,verse:"亚丁的子孙四百五十四名；"},
{volumn:        15,chapter:         2,versenumber:        16,verse:"亚特的后裔，就是希西家的子孙九十八名；"},
{volumn:        15,chapter:         2,versenumber:        17,verse:"比赛的子孙三百二十三名；"},
{volumn:        15,chapter:         2,versenumber:        18,verse:"约拉的子孙一百一十二名；"},
{volumn:        15,chapter:         2,versenumber:        19,verse:"哈顺的子孙二百二十三名；"},
{volumn:        15,chapter:         2,versenumber:        20,verse:"吉罢珥人九十五名；"},
{volumn:        15,chapter:         2,versenumber:        21,verse:"伯利恒人一百二十三名；"},
{volumn:        15,chapter:         2,versenumber:        22,verse:"尼陀法人五十六名；"},
{volumn:        15,chapter:         2,versenumber:        23,verse:"亚拿突人一百二十八名；"},
{volumn:        15,chapter:         2,versenumber:        24,verse:"亚斯玛弗人四十二名；"},
{volumn:        15,chapter:         2,versenumber:        25,verse:"基列·耶琳人、基非拉人、比录人共七百四十三名；"},
{volumn:        15,chapter:         2,versenumber:        26,verse:"拉玛人、迦巴人共六百二十一名；"},
{volumn:        15,chapter:         2,versenumber:        27,verse:"默玛人一百二十二名；"},
{volumn:        15,chapter:         2,versenumber:        28,verse:"伯特利人、艾人共二百二十三名；"},
{volumn:        15,chapter:         2,versenumber:        29,verse:"尼波人五十二名；"},
{volumn:        15,chapter:         2,versenumber:        30,verse:"末必人一百五十六名；"},
{volumn:        15,chapter:         2,versenumber:        31,verse:"别的以拦子孙一千二百五十四名；"},
{volumn:        15,chapter:         2,versenumber:        32,verse:"哈琳的子孙三百二十名；"},
{volumn:        15,chapter:         2,versenumber:        33,verse:"罗德人、哈第人、阿挪人共七百二十五名；"},
{volumn:        15,chapter:         2,versenumber:        34,verse:"耶利哥人三百四十五名；"},
{volumn:        15,chapter:         2,versenumber:        35,verse:"西拿人三千六百三十名。"},
{volumn:        15,chapter:         2,versenumber:        36,verse:"祭司：耶书亚家耶大雅的子孙九百七十三名；"},
{volumn:        15,chapter:         2,versenumber:        37,verse:"音麦的子孙一千零五十二名；"},
{volumn:        15,chapter:         2,versenumber:        38,verse:"巴施户珥的子孙一千二百四十七名；"},
{volumn:        15,chapter:         2,versenumber:        39,verse:"哈琳的子孙一千零一十七名。"},
{volumn:        15,chapter:         2,versenumber:        40,verse:"利未人：何达威雅的后裔，就是耶书亚和甲篾的子孙七十四名。"},
{volumn:        15,chapter:         2,versenumber:        41,verse:"歌唱的：亚萨的子孙一百二十八名。"},
{volumn:        15,chapter:         2,versenumber:        42,verse:"守门的：沙龙的子孙、亚特的子孙、达们的子孙、亚谷的子孙、哈底大的子孙、朔拜的子孙，共一百三十九名。"},
{volumn:        15,chapter:         2,versenumber:        43,verse:"尼提宁（就是殿役）：西哈的子孙、哈苏巴的子孙、答巴俄的子孙、"},
{volumn:        15,chapter:         2,versenumber:        44,verse:"基绿的子孙、西亚的子孙、巴顿的子孙、"},
{volumn:        15,chapter:         2,versenumber:        45,verse:"利巴拿的子孙、哈迦巴的子孙、亚谷的子孙、"},
{volumn:        15,chapter:         2,versenumber:        46,verse:"哈甲的子孙、萨买的子孙、哈难的子孙、"},
{volumn:        15,chapter:         2,versenumber:        47,verse:"吉德的子孙、迦哈的子孙、利亚雅的子孙、"},
{volumn:        15,chapter:         2,versenumber:        48,verse:"利汛的子孙、尼哥大的子孙、迦散的子孙、"},
{volumn:        15,chapter:         2,versenumber:        49,verse:"乌撒的子孙、巴西亚的子孙、比赛的子孙、"},
{volumn:        15,chapter:         2,versenumber:        50,verse:"押拿的子孙、米乌宁的子孙、尼普心的子孙、"},
{volumn:        15,chapter:         2,versenumber:        51,verse:"巴卜的子孙、哈古巴的子孙、哈忽的子孙、"},
{volumn:        15,chapter:         2,versenumber:        52,verse:"巴洗律的子孙、米希大的子孙、哈沙的子孙、"},
{volumn:        15,chapter:         2,versenumber:        53,verse:"巴柯的子孙、西西拉的子孙、答玛的子孙、"},
{volumn:        15,chapter:         2,versenumber:        54,verse:"尼细亚的子孙、哈提法的子孙。"},
{volumn:        15,chapter:         2,versenumber:        55,verse:"所罗门仆人的后裔，就是琐太的子孙、琐斐列的子孙、比路大的子孙、"},
{volumn:        15,chapter:         2,versenumber:        56,verse:"雅拉的子孙、达昆的子孙、吉德的子孙、"},
{volumn:        15,chapter:         2,versenumber:        57,verse:"示法提雅的子孙、哈替的子孙、玻黑列·哈斯巴音的子孙、亚米的子孙。"},
{volumn:        15,chapter:         2,versenumber:        58,verse:"尼提宁和所罗门仆人的后裔共三百九十二名。"},
{volumn:        15,chapter:         2,versenumber:        59,verse:"从特·米拉、特·哈萨、基绿、押但、音麦上来的，不能指明他们的宗族谱系是以色列人不是；"},
{volumn:        15,chapter:         2,versenumber:        60,verse:"他们是第来雅的子孙、多比雅的子孙、尼哥大的子孙，共六百五十二名。"},
{volumn:        15,chapter:         2,versenumber:        61,verse:"祭司中，哈巴雅的子孙、哈哥斯的子孙、巴西莱的子孙；因为他们的先祖娶了基列人巴西莱的女儿为妻，所以起名叫巴西莱。"},
{volumn:        15,chapter:         2,versenumber:        62,verse:"这三家的人在族谱之中寻查自己的谱系，却寻不着，因此算为不洁，不准供祭司的职任。"},
{volumn:        15,chapter:         2,versenumber:        63,verse:"省长对他们说：“不可吃至圣的物，直到有用乌陵和土明决疑的祭司兴起来。”"},
{volumn:        15,chapter:         2,versenumber:        64,verse:"会众共有四万二千三百六十名。"},
{volumn:        15,chapter:         2,versenumber:        65,verse:"此外，还有他们的仆婢七千三百三十七名，又有歌唱的男女二百名。"},
{volumn:        15,chapter:         2,versenumber:        66,verse:"他们有马七百三十六匹，骡子二百四十五匹，"},
{volumn:        15,chapter:         2,versenumber:        67,verse:"骆驼四百三十五只，驴六千七百二十匹。"},
{volumn:        15,chapter:         2,versenumber:        68,verse:"有些族长到了耶路撒冷耶和华殿的地方，便为　神的殿甘心献上礼物，要重新建造。"},
{volumn:        15,chapter:         2,versenumber:        69,verse:"他们量力捐入工程库的金子六万一千达利克，银子五千弥拿，并祭司的礼服一百件。"},
{volumn:        15,chapter:         2,versenumber:        70,verse:"于是祭司、利未人、民中的一些人、歌唱的、守门的、尼提宁，并以色列众人，各住在自己的城里。"},
{volumn:        15,chapter:         3,versenumber:         1,verse:"到了七月，以色列人住在各城；那时他们如同一人，聚集在耶路撒冷。"},
{volumn:        15,chapter:         3,versenumber:         2,verse:"约萨达的儿子耶书亚和他的弟兄众祭司，并撒拉铁的儿子所罗巴伯与他的弟兄，都起来建筑以色列　神的坛，要照神人摩西律法书上所写的，在坛上献燔祭。"},
{volumn:        15,chapter:         3,versenumber:         3,verse:"他们在原有的根基上筑坛，因惧怕邻国的民，又在其上向耶和华早晚献燔祭，"},
{volumn:        15,chapter:         3,versenumber:         4,verse:"又照律法书上所写的守住棚节，按数照例献每日所当献的燔祭；"},
{volumn:        15,chapter:         3,versenumber:         5,verse:"其后献常献的燔祭，并在月朔与耶和华的一切圣节献祭，又向耶和华献各人的甘心祭。"},
{volumn:        15,chapter:         3,versenumber:         6,verse:"从七月初一日起，他们就向耶和华献燔祭。但耶和华殿的根基尚未立定。"},
{volumn:        15,chapter:         3,versenumber:         7,verse:"他们又将银子给石匠、木匠，把粮食、酒、油给西顿人、推罗人，使他们将香柏树从黎巴嫩运到海里，浮海运到约帕，是照波斯王居鲁士所允准的。"},
{volumn:        15,chapter:         3,versenumber:         8,verse:"百姓到了耶路撒冷　神殿的地方。第二年二月，撒拉铁的儿子所罗巴伯，约萨达的儿子耶书亚和其余的弟兄，就是祭司、利未人，并一切被掳归回耶路撒冷的人，都兴工建造；又派利未人，从二十岁以外的，督理建造耶和华殿的工作。"},
{volumn:        15,chapter:         3,versenumber:         9,verse:"于是犹大（在二章四十节作何达威雅）的后裔，就是耶书亚和他的子孙与弟兄，甲篾和他的子孙，利未人希拿达的子孙与弟兄，都一同起来，督理那在　神殿做工的人。"},
{volumn:        15,chapter:         3,versenumber:        10,verse:"匠人立耶和华殿根基的时候，祭司皆穿礼服吹号，亚萨的子孙利未人敲钹，照以色列王大卫所定的例，都站着赞美耶和华。"},
{volumn:        15,chapter:         3,versenumber:        11,verse:"他们彼此唱和，赞美称谢耶和华说：“他本为善，他向以色列人永发慈爱。”他们赞美耶和华的时候，众民大声呼喊，因耶和华殿的根基已经立定。"},
{volumn:        15,chapter:         3,versenumber:        12,verse:"然而有许多祭司、利未人、族长，就是见过旧殿的老年人，现在亲眼看见立这殿的根基，便大声哭号，也有许多人大声欢呼，"},
{volumn:        15,chapter:         3,versenumber:        13,verse:"甚至百姓不能分辨欢呼的声音和哭号的声音；因为众人大声呼喊，声音听到远处。"},
{volumn:        15,chapter:         4,versenumber:         1,verse:"犹大和便雅悯的敌人听说被掳归回的人为耶和华以色列的　神建造殿宇，"},
{volumn:        15,chapter:         4,versenumber:         2,verse:"就去见所罗巴伯和以色列的族长，对他们说：“请容我们与你们一同建造；因为我们寻求你们的　神，与你们一样。自从亚述王以撒哈顿带我们上这地以来，我们常祭祀　神。”"},
{volumn:        15,chapter:         4,versenumber:         3,verse:"但所罗巴伯、耶书亚，和其余以色列的族长对他们说：“我们建造　神的殿与你们无干，我们自己为耶和华以色列的　神协力建造，是照波斯王居鲁士所吩咐的。”"},
{volumn:        15,chapter:         4,versenumber:         4,verse:"那地的民，就在犹大人建造的时候，使他们的手发软，扰乱他们；"},
{volumn:        15,chapter:         4,versenumber:         5,verse:"从波斯王居鲁士年间，直到波斯王大流士登基的时候，贿买谋士，要败坏他们的谋算。"},
{volumn:        15,chapter:         4,versenumber:         6,verse:"在亚哈随鲁才登基的时候，上本控告犹大和耶路撒冷的居民。"},
{volumn:        15,chapter:         4,versenumber:         7,verse:"亚达薛西年间，比施兰、米特利达、他别，和他们的同党上本奏告波斯王亚达薛西。本章是用亚兰文字，亚兰方言。"},
{volumn:        15,chapter:         4,versenumber:         8,verse:"省长利宏、书记伸帅要控告耶路撒冷人，也上本奏告亚达薛西王。"},
{volumn:        15,chapter:         4,versenumber:         9,verse:"省长利宏、书记伸帅，和同党的底拿人、亚法萨提迦人、他毗拉人、亚法撒人、亚基卫人、巴比伦人、书珊迦人、底亥人、以拦人，"},
{volumn:        15,chapter:         4,versenumber:        10,verse:"和尊大的亚斯那巴所迁移、安置在撒玛利亚城，并大河西一带地方的人等，"},
{volumn:        15,chapter:         4,versenumber:        11,verse:"上奏亚达薛西王说：“河西的臣民云云："},
{volumn:        15,chapter:         4,versenumber:        12,verse:"王该知道，从王那里上到我们这里的犹大人，已经到耶路撒冷重建这反叛恶劣的城，筑立根基，建造城墙。"},
{volumn:        15,chapter:         4,versenumber:        13,verse:"如今王该知道，他们若建造这城，城墙完毕就不再与王进贡，交课，纳税，终久王必受亏损。"},
{volumn:        15,chapter:         4,versenumber:        14,verse:"我们既食御盐，不忍见王吃亏，因此奏告于王。"},
{volumn:        15,chapter:         4,versenumber:        15,verse:"请王考察先王的实录，必在其上查知这城是反叛的城，与列王和各省有害；自古以来，其中常有悖逆的事，因此这城曾被拆毁。"},
{volumn:        15,chapter:         4,versenumber:        16,verse:"我们谨奏王知，这城若再建造，城墙完毕，河西之地王就无份了。”"},
{volumn:        15,chapter:         4,versenumber:        17,verse:"那时王谕复省长利宏、书记伸帅，和他们的同党，就是住撒玛利亚并河西一带地方的人，说：“愿你们平安云云。"},
{volumn:        15,chapter:         4,versenumber:        18,verse:"你们所上的本，已经明读在我面前。"},
{volumn:        15,chapter:         4,versenumber:        19,verse:"我已命人考查，得知此城古来果然背叛列王，其中常有反叛悖逆的事。"},
{volumn:        15,chapter:         4,versenumber:        20,verse:"从前耶路撒冷也有大君王统管河西全地，人就给他们进贡，交课，纳税。"},
{volumn:        15,chapter:         4,versenumber:        21,verse:"现在你们要出告示命这些人停工，使这城不得建造，等我降旨。"},
{volumn:        15,chapter:         4,versenumber:        22,verse:"你们当谨慎，不可迟延，为何容害加重，使王受亏损呢？”"},
{volumn:        15,chapter:         4,versenumber:        23,verse:"亚达薛西王的上谕读在利宏和书记伸帅，并他们的同党面前，他们就急忙往耶路撒冷去见犹大人，用势力强迫他们停工。"},
{volumn:        15,chapter:         4,versenumber:        24,verse:"于是，在耶路撒冷　神殿的工程就停止了，直停到波斯王大流士第二年。"},
{volumn:        15,chapter:         5,versenumber:         1,verse:"那时，先知哈该和易多的孙子撒迦利亚奉以色列　神的名向犹大和耶路撒冷的犹大人说劝勉的话。"},
{volumn:        15,chapter:         5,versenumber:         2,verse:"于是撒拉铁的儿子所罗巴伯和约萨达的儿子耶书亚都起来动手建造耶路撒冷　神的殿，有　神的先知在那里帮助他们。"},
{volumn:        15,chapter:         5,versenumber:         3,verse:"当时河西的总督达乃和示他·波斯乃，并他们的同党来问说：“谁降旨让你们建造这殿，修成这墙呢？”"},
{volumn:        15,chapter:         5,versenumber:         4,verse:"我们便告诉他们建造这殿的人叫什么名字。"},
{volumn:        15,chapter:         5,versenumber:         5,verse:"神的眼目看顾犹大的长老，以致总督等没有叫他们停工，直到这事奏告大流士，得着他的回谕。"},
{volumn:        15,chapter:         5,versenumber:         6,verse:"河西的总督达乃和示他·波斯乃，并他们的同党，就是住河西的亚法萨迦人，上本奏告大流士王。"},
{volumn:        15,chapter:         5,versenumber:         7,verse:"本上写着说：“愿大流士王诸事平安。"},
{volumn:        15,chapter:         5,versenumber:         8,verse:"王该知道，我们往犹大省去，到了至大　神的殿，这殿是用大石建造的。梁木插入墙内，工作甚速，他们手下亨通。"},
{volumn:        15,chapter:         5,versenumber:         9,verse:"我们就问那些长老说：‘谁降旨让你们建造这殿，修成这墙呢？’"},
{volumn:        15,chapter:         5,versenumber:        10,verse:"又问他们的名字，要记录他们首领的名字，奏告于王。"},
{volumn:        15,chapter:         5,versenumber:        11,verse:"他们回答说：‘我们是天地之　神的仆人，重建前多年所建造的殿，就是以色列的一位大君王建造修成的。"},
{volumn:        15,chapter:         5,versenumber:        12,verse:"只因我们列祖惹天上的　神发怒，　神把他们交在迦勒底人巴比伦王尼布甲尼撒的手中，他就拆毁这殿，又将百姓掳到巴比伦。"},
{volumn:        15,chapter:         5,versenumber:        13,verse:"然而巴比伦王居鲁士元年，他降旨允准建造　神的这殿。"},
{volumn:        15,chapter:         5,versenumber:        14,verse:"神殿中的金、银器皿，就是尼布甲尼撒从耶路撒冷的殿中掠去带到巴比伦庙里的，居鲁士王从巴比伦庙里取出来，交给派为省长的，名叫设巴萨，"},
{volumn:        15,chapter:         5,versenumber:        15,verse:"对他说可以将这些器皿带去，放在耶路撒冷的殿中，在原处建造　神的殿。"},
{volumn:        15,chapter:         5,versenumber:        16,verse:"于是这设巴萨来建立耶路撒冷　神殿的根基。这殿从那时直到如今尚未造成。’"},
{volumn:        15,chapter:         5,versenumber:        17,verse:"现在王若以为美，请察巴比伦王的府库，看居鲁士王降旨允准在耶路撒冷建造　神的殿没有，王的心意如何？请降旨晓谕我们。”"},
{volumn:        15,chapter:         6,versenumber:         1,verse:"于是大流士王降旨，要寻察典籍库内，就是在巴比伦藏宝物之处；"},
{volumn:        15,chapter:         6,versenumber:         2,verse:"在玛代省亚马他城的宫内寻得一卷，其中记着说："},
{volumn:        15,chapter:         6,versenumber:         3,verse:"“居鲁士王元年，他降旨论到耶路撒冷　神的殿，要建造这殿为献祭之处，坚立殿的根基。殿高六十肘，宽六十肘，"},
{volumn:        15,chapter:         6,versenumber:         4,verse:"用三层大石头，一层新木头，经费要出于王库；"},
{volumn:        15,chapter:         6,versenumber:         5,verse:"并且　神殿的金银器皿，就是尼布甲尼撒从耶路撒冷的殿中掠到巴比伦的，要归还带到耶路撒冷的殿中，各按原处放在　神的殿里。”"},
{volumn:        15,chapter:         6,versenumber:         6,verse:"“现在河西的总督达乃和示他·波斯乃，并你们的同党，就是住河西的亚法萨迦人，你们当远离他们。"},
{volumn:        15,chapter:         6,versenumber:         7,verse:"不要拦阻　神殿的工作，任凭犹大人的省长和犹大人的长老在原处建造　神的这殿。"},
{volumn:        15,chapter:         6,versenumber:         8,verse:"我又降旨，吩咐你们向犹大人的长老为建造　神的殿当怎样行，就是从河西的款项中，急速拨取贡银作他们的经费，免得耽误工作。"},
{volumn:        15,chapter:         6,versenumber:         9,verse:"他们与天上的　神献燔祭所需用的公牛犊、公绵羊、绵羊羔，并所用的麦子、盐、酒、油，都要照耶路撒冷祭司的话，每日供给他们，不得有误；"},
{volumn:        15,chapter:         6,versenumber:        10,verse:"好叫他们献馨香的祭给天上的　神，又为王和王众子的寿命祈祷。"},
{volumn:        15,chapter:         6,versenumber:        11,verse:"我再降旨，无论谁更改这命令，必从他房屋中拆出一根梁来，把他举起，悬在其上，又使他的房屋成为粪堆。"},
{volumn:        15,chapter:         6,versenumber:        12,verse:"若有王和民伸手更改这命令，拆毁这殿，愿那使耶路撒冷的殿作为他名居所的　神将他们灭绝。我大流士降这旨意，当速速遵行。”"},
{volumn:        15,chapter:         6,versenumber:        13,verse:"于是，河西总督达乃和示他·波斯乃，并他们的同党，因大流士王所发的命令，就急速遵行。"},
{volumn:        15,chapter:         6,versenumber:        14,verse:"犹大长老因先知哈该和易多的孙子撒迦利亚所说劝勉的话就建造这殿，凡事亨通。他们遵着以色列　神的命令和波斯王居鲁士、大流士、亚达薛西的旨意，建造完毕。"},
{volumn:        15,chapter:         6,versenumber:        15,verse:"大流士王第六年，亚达月初三日，这殿修成了。"},
{volumn:        15,chapter:         6,versenumber:        16,verse:"以色列的祭司和利未人，并其余被掳归回的人都欢欢喜喜地行奉献　神殿的礼。"},
{volumn:        15,chapter:         6,versenumber:        17,verse:"行奉献　神殿的礼就献公牛一百只，公绵羊二百只，绵羊羔四百只，又照以色列支派的数目献公山羊十二只，为以色列众人作赎罪祭；"},
{volumn:        15,chapter:         6,versenumber:        18,verse:"且派祭司和利未人按着班次在耶路撒冷侍奉　神，是照摩西律法书上所写的。"},
{volumn:        15,chapter:         6,versenumber:        19,verse:"正月十四日，被掳归回的人守逾越节。"},
{volumn:        15,chapter:         6,versenumber:        20,verse:"原来，祭司和利未人一同自洁，无一人不洁净。利未人为被掳归回的众人和他们的弟兄众祭司，并为自己宰逾越节的羊羔。"},
{volumn:        15,chapter:         6,versenumber:        21,verse:"从掳到之地归回的以色列人和一切除掉所染外邦人污秽、归附他们、要寻求耶和华以色列　神的人都吃这羊羔，"},
{volumn:        15,chapter:         6,versenumber:        22,verse:"欢欢喜喜地守除酵节七日；因为耶和华使他们欢喜，又使亚述王的心转向他们，坚固他们的手，作以色列　神殿的工程。"},
{volumn:        15,chapter:         7,versenumber:         1,verse:"这事以后，波斯王亚达薛西年间，有个以斯拉，他是西莱雅的儿子，西莱雅是亚撒利雅的儿子，亚撒利雅是希勒家的儿子，"},
{volumn:        15,chapter:         7,versenumber:         2,verse:"希勒家是沙龙的儿子，沙龙是撒督的儿子，撒督是亚希突的儿子，"},
{volumn:        15,chapter:         7,versenumber:         3,verse:"亚希突是亚玛利雅的儿子，亚玛利雅是亚撒利雅的儿子，亚撒利雅是米拉约的儿子，"},
{volumn:        15,chapter:         7,versenumber:         4,verse:"米拉约是西拉希雅的儿子，西拉希雅是乌西的儿子，乌西是布基的儿子，"},
{volumn:        15,chapter:         7,versenumber:         5,verse:"布基是亚比书的儿子，亚比书是非尼哈的儿子，非尼哈是以利亚撒的儿子，以利亚撒是大祭司亚伦的儿子。"},
{volumn:        15,chapter:         7,versenumber:         6,verse:"这以斯拉从巴比伦上来，他是敏捷的文士，通达耶和华以色列　神所赐摩西的律法书。王允准他一切所求的，是因耶和华他　神的手帮助他。"},
{volumn:        15,chapter:         7,versenumber:         7,verse:"亚达薛西王第七年，以色列人、祭司、利未人、歌唱的、守门的、尼提宁，有上耶路撒冷的。"},
{volumn:        15,chapter:         7,versenumber:         8,verse:"王第七年五月，以斯拉到了耶路撒冷。"},
{volumn:        15,chapter:         7,versenumber:         9,verse:"正月初一日，他从巴比伦起程；因他　神施恩的手帮助他，五月初一日就到了耶路撒冷。"},
{volumn:        15,chapter:         7,versenumber:        10,verse:"以斯拉定志考究遵行耶和华的律法，又将律例典章教训以色列人。"},
{volumn:        15,chapter:         7,versenumber:        11,verse:"祭司以斯拉是通达耶和华诫命和赐以色列之律例的文士。亚达薛西王赐给他们谕旨，上面写着说："},
{volumn:        15,chapter:         7,versenumber:        12,verse:"“诸王之王亚达薛西，达于祭司以斯拉通达天上　神律法大德的文士，云云。"},
{volumn:        15,chapter:         7,versenumber:        13,verse:"住在我国中的以色列人、祭司、利未人，凡甘心上耶路撒冷去的，我降旨准他们与你同去。"},
{volumn:        15,chapter:         7,versenumber:        14,verse:"王与七个谋士既然差你去，照你手中　神的律法书察问犹大和耶路撒冷的景况；"},
{volumn:        15,chapter:         7,versenumber:        15,verse:"又带金银，就是王和谋士甘心献给住耶路撒冷、以色列　神的，"},
{volumn:        15,chapter:         7,versenumber:        16,verse:"并带你在巴比伦全省所得的金银，和百姓、祭司乐意献给耶路撒冷他们　神殿的礼物。"},
{volumn:        15,chapter:         7,versenumber:        17,verse:"所以你当用这金银，急速买公牛、公绵羊、绵羊羔，和同献的素祭奠祭之物，献在耶路撒冷你们　神殿的坛上。"},
{volumn:        15,chapter:         7,versenumber:        18,verse:"剩下的金银，你和你的弟兄看着怎样好，就怎样用，总要遵着你们　神的旨意。"},
{volumn:        15,chapter:         7,versenumber:        19,verse:"所交给你　神殿中使用的器皿，你要交在耶路撒冷　神面前。"},
{volumn:        15,chapter:         7,versenumber:        20,verse:"你　神殿里若再有需用的经费，你可以从王的府库里支取。"},
{volumn:        15,chapter:         7,versenumber:        21,verse:"“我亚达薛西王又降旨与河西的一切库官，说：‘通达天上　神律法的文士祭司以斯拉，无论向你们要什么，你们要速速地备办，"},
{volumn:        15,chapter:         7,versenumber:        22,verse:"就是银子直到一百他连得，麦子一百柯珥，酒一百罢特，油一百罢特，盐不计其数，也要给他。"},
{volumn:        15,chapter:         7,versenumber:        23,verse:"凡天上之　神所吩咐的，当为天上　神的殿详细办理。为何使忿怒临到王和王众子的国呢？"},
{volumn:        15,chapter:         7,versenumber:        24,verse:"我又晓谕你们，至于祭司、利未人、歌唱的、守门的，和尼提宁，并在　神殿当差的人，不可叫他们进贡，交课，纳税。’"},
{volumn:        15,chapter:         7,versenumber:        25,verse:"“以斯拉啊，要照着你　神赐你的智慧，将所有明白你　神律法的人立为士师、审判官，治理河西的百姓，使他们教训一切不明白　神律法的人。"},
{volumn:        15,chapter:         7,versenumber:        26,verse:"凡不遵行你　神律法和王命令的人就当速速定他的罪，或治死，或充军，或抄家，或囚禁。”"},
{volumn:        15,chapter:         7,versenumber:        27,verse:"以斯拉说：“耶和华我们列祖的　神是应当称颂的！因他使王起这心意修饰耶路撒冷耶和华的殿，"},
{volumn:        15,chapter:         7,versenumber:        28,verse:"又在王和谋士，并大能的军长面前施恩于我。因耶和华我　神的手帮助我，我就得以坚强，从以色列中招聚首领，与我一同上来。”"},
{volumn:        15,chapter:         8,versenumber:         1,verse:"当亚达薛西王年间，同我从巴比伦上来的人，他们的族长和他们的家谱记在下面："},
{volumn:        15,chapter:         8,versenumber:         2,verse:"属非尼哈的子孙有革顺；属以他玛的子孙有但以理；属大卫的子孙有哈突；"},
{volumn:        15,chapter:         8,versenumber:         3,verse:"属巴录的后裔，就是示迦尼的子孙有撒迦利亚，同着他，按家谱计算，男丁一百五十人；"},
{volumn:        15,chapter:         8,versenumber:         4,verse:"属巴哈·摩押的子孙有西拉希雅的儿子以利约乃，同着他有男丁二百；"},
{volumn:        15,chapter:         8,versenumber:         5,verse:"属示迦尼的子孙有雅哈悉的儿子，同着他有男丁三百；"},
{volumn:        15,chapter:         8,versenumber:         6,verse:"属亚丁的子孙有约拿单的儿子以别，同着他有男丁五十；"},
{volumn:        15,chapter:         8,versenumber:         7,verse:"属以拦的子孙有亚他利雅的儿子耶筛亚，同着他有男丁七十；"},
{volumn:        15,chapter:         8,versenumber:         8,verse:"属示法提雅的子孙有米迦勒的儿子西巴第雅，同着他有男丁八十；"},
{volumn:        15,chapter:         8,versenumber:         9,verse:"属约押的子孙有耶歇的儿子俄巴底亚，同着他有男丁二百一十八；"},
{volumn:        15,chapter:         8,versenumber:        10,verse:"属示罗密的子孙有约细斐的儿子，同着他有男丁一百六十；"},
{volumn:        15,chapter:         8,versenumber:        11,verse:"属比拜的子孙有比拜的儿子撒迦利亚，同着他有男丁二十八；"},
{volumn:        15,chapter:         8,versenumber:        12,verse:"属押甲的子孙有哈加坦的儿子约哈难，同着他有男丁一百一十；"},
{volumn:        15,chapter:         8,versenumber:        13,verse:"属亚多尼干的子孙，就是末尾的，他们的名字是以利法列、耶利、示玛雅，同着他们有男丁六十；"},
{volumn:        15,chapter:         8,versenumber:        14,verse:"属比革瓦伊的子孙有乌太和撒布，同着他们有男丁七十。"},
{volumn:        15,chapter:         8,versenumber:        15,verse:"我招聚这些人在流入亚哈瓦的河边，我们在那里住了三日。我查看百姓和祭司，见没有利未人在那里，"},
{volumn:        15,chapter:         8,versenumber:        16,verse:"就召首领以利以谢、亚列、示玛雅、以利拿单、雅立、以利拿单、拿单、撒迦利亚、米书兰，又召教习约雅立和以利拿单。"},
{volumn:        15,chapter:         8,versenumber:        17,verse:"我打发他们往迦西斐雅地方去见那里的首领易多，又告诉他们当向易多和他的弟兄尼提宁说什么话，叫他们为我们　神的殿带使用的人来。"},
{volumn:        15,chapter:         8,versenumber:        18,verse:"蒙我们　神施恩的手帮助我们，他们在以色列的曾孙、利未的孙子、抹利的后裔中带一个通达人来；还有示利比和他的众子与弟兄共一十八人。"},
{volumn:        15,chapter:         8,versenumber:        19,verse:"又有哈沙比雅，同着他有米拉利的子孙耶筛亚，并他的众子和弟兄共二十人。"},
{volumn:        15,chapter:         8,versenumber:        20,verse:"从前大卫和众首领派尼提宁服侍利未人，现在从这尼提宁中也带了二百二十人来，都是按名指定的。"},
{volumn:        15,chapter:         8,versenumber:        21,verse:"那时，我在亚哈瓦河边宣告禁食，为要在我们　神面前克苦己心，求他使我们和妇人孩子，并一切所有的，都得平坦的道路。"},
{volumn:        15,chapter:         8,versenumber:        22,verse:"我求王拨步兵马兵帮助我们抵挡路上的仇敌，本以为羞耻；因我曾对王说：“我们　神施恩的手必帮助一切寻求他的；但他的能力和忿怒必攻击一切离弃他的。”"},
{volumn:        15,chapter:         8,versenumber:        23,verse:"所以我们禁食祈求我们的　神，他就应允了我们。"},
{volumn:        15,chapter:         8,versenumber:        24,verse:"我分派祭司长十二人，就是示利比、哈沙比雅，和他们的弟兄十人，"},
{volumn:        15,chapter:         8,versenumber:        25,verse:"将王和谋士、军长，并在那里的以色列众人为我们　神殿所献的金银和器皿，都秤了交给他们。"},
{volumn:        15,chapter:         8,versenumber:        26,verse:"我秤了交在他们手中的银子有六百五十他连得；银器重一百他连得；金子一百他连得；"},
{volumn:        15,chapter:         8,versenumber:        27,verse:"金碗二十个，重一千达利克；上等光铜的器皿两个，宝贵如金。"},
{volumn:        15,chapter:         8,versenumber:        28,verse:"我对他们说：“你们归耶和华为圣，器皿也为圣；金银是甘心献给耶和华你们列祖之　神的。"},
{volumn:        15,chapter:         8,versenumber:        29,verse:"你们当警醒看守，直到你们在耶路撒冷耶和华殿的库内，在祭司长和利未族长，并以色列的各族长面前过了秤。”"},
{volumn:        15,chapter:         8,versenumber:        30,verse:"于是，祭司、利未人按着分量接受金银和器皿，要带到耶路撒冷我们　神的殿里。"},
{volumn:        15,chapter:         8,versenumber:        31,verse:"正月十二日，我们从亚哈瓦河边起行，要往耶路撒冷去。我们　神的手保佑我们，救我们脱离仇敌和路上埋伏之人的手。"},
{volumn:        15,chapter:         8,versenumber:        32,verse:"我们到了耶路撒冷，在那里住了三日。"},
{volumn:        15,chapter:         8,versenumber:        33,verse:"第四日，在我们　神的殿里把金银和器皿都秤了，交在祭司乌利亚的儿子米利末的手中。同着他有非尼哈的儿子以利亚撒，还有利未人耶书亚的儿子约撒拔和宾内的儿子挪亚底。"},
{volumn:        15,chapter:         8,versenumber:        34,verse:"当时都点了数目，按着分量写在册上。"},
{volumn:        15,chapter:         8,versenumber:        35,verse:"从掳到之地归回的人向以色列的　神献燔祭，就是为以色列众人献公牛十二只，公绵羊九十六只，绵羊羔七十七只，又献公山羊十二只作赎罪祭，这都是向耶和华焚献的。"},
{volumn:        15,chapter:         8,versenumber:        36,verse:"他们将王的谕旨交给王所派的总督与河西的省长，他们就帮助百姓，又供给　神殿里所需用的。"},
{volumn:        15,chapter:         9,versenumber:         1,verse:"这事做完了，众首领来见我，说：“以色列民和祭司并利未人，没有离绝迦南人、赫人、比利洗人、耶布斯人、亚扪人、摩押人、埃及人、亚摩利人，仍效法这些国的民，行可憎的事。"},
{volumn:        15,chapter:         9,versenumber:         2,verse:"因他们为自己和儿子娶了这些外邦女子为妻，以致圣洁的种类和这些国的民混杂；而且首领和官长在这事上为罪魁。”"},
{volumn:        15,chapter:         9,versenumber:         3,verse:"我一听见这事，就撕裂衣服和外袍，拔了头发和胡须，惊惧忧闷而坐。"},
{volumn:        15,chapter:         9,versenumber:         4,verse:"凡为以色列　神言语战兢的，都因这被掳归回之人所犯的罪聚集到我这里来。我就惊惧忧闷而坐，直到献晚祭的时候。"},
{volumn:        15,chapter:         9,versenumber:         5,verse:"献晚祭的时候我起来，心中愁苦，穿着撕裂的衣袍，双膝跪下向耶和华我的　神举手，"},
{volumn:        15,chapter:         9,versenumber:         6,verse:"说：“我的　神啊，我抱愧蒙羞，不敢向我　神仰面；因为我们的罪孽灭顶，我们的罪恶滔天。"},
{volumn:        15,chapter:         9,versenumber:         7,verse:"从我们列祖直到今日，我们的罪恶甚重；因我们的罪孽，我们和君王、祭司都交在外邦列王的手中，杀害、掳掠、抢夺、脸上蒙羞正如今日的光景。"},
{volumn:        15,chapter:         9,versenumber:         8,verse:"现在耶和华我们的　神暂且施恩与我们，给我们留些逃脱的人，使我们安稳如钉子钉在他的圣所，我们的　神好光照我们的眼目，使我们在受辖制之中稍微复兴。"},
{volumn:        15,chapter:         9,versenumber:         9,verse:"我们是奴仆，然而在受辖制之中，我们的　神仍没有丢弃我们，在波斯王眼前向我们施恩，叫我们复兴，能重建我们　神的殿，修其毁坏之处，使我们在犹大和耶路撒冷有墙垣。"},
{volumn:        15,chapter:         9,versenumber:        10,verse:"“我们的　神啊，既是如此，我们还有什么话可说呢？因为我们已经离弃你的命令，"},
{volumn:        15,chapter:         9,versenumber:        11,verse:"就是你藉你仆人众先知所吩咐的说：‘你们要去得为业之地是污秽之地；因列国之民的污秽和可憎的事，叫全地从这边直到那边满了污秽。"},
{volumn:        15,chapter:         9,versenumber:        12,verse:"所以不可将你们的女儿嫁他们的儿子，也不可为你们的儿子娶他们的女儿，永不可求他们的平安和他们的利益，这样你们就可以强盛，吃这地的美物，并遗留这地给你们的子孙永远为业。’"},
{volumn:        15,chapter:         9,versenumber:        13,verse:"神啊，我们因自己的恶行和大罪，遭遇了这一切的事，并且你刑罚我们轻于我们罪所当得的，又给我们留下这些人。"},
{volumn:        15,chapter:         9,versenumber:        14,verse:"我们岂可再违背你的命令，与这行可憎之事的民结亲呢？若这样行，你岂不向我们发怒，将我们灭绝，以致没有一个剩下逃脱的人吗？"},
{volumn:        15,chapter:         9,versenumber:        15,verse:"耶和华以色列的　神啊，因你是公义的，我们这剩下的人才得逃脱，正如今日的光景。看哪，我们在你面前有罪恶，因此无人在你面前站立得住。”"},
{volumn:        15,chapter:        10,versenumber:         1,verse:"以斯拉祷告，认罪，哭泣，俯伏在　神殿前的时候，有以色列中的男女孩童聚集到以斯拉那里，成了大会，众民无不痛哭。"},
{volumn:        15,chapter:        10,versenumber:         2,verse:"属以拦的子孙、耶歇的儿子示迦尼对以斯拉说：“我们在此地娶了外邦女子为妻，干犯了我们的　神，然而以色列人还有指望。"},
{volumn:        15,chapter:        10,versenumber:         3,verse:"现在当与我们的　神立约，休这一切的妻，离绝她们所生的，照着我主和那因　神命令战兢之人所议定的，按律法而行。"},
{volumn:        15,chapter:        10,versenumber:         4,verse:"你起来，这是你当办的事，我们必帮助你，你当奋勉而行。”"},
{volumn:        15,chapter:        10,versenumber:         5,verse:"以斯拉便起来，使祭司长和利未人，并以色列众人起誓说，必照这话去行；他们就起了誓。"},
{volumn:        15,chapter:        10,versenumber:         6,verse:"以斯拉从　神殿前起来，进入以利亚实的儿子约哈难的屋里，到了那里不吃饭，也不喝水；因为被掳归回之人所犯的罪，心里悲伤。"},
{volumn:        15,chapter:        10,versenumber:         7,verse:"他们通告犹大和耶路撒冷被掳归回的人，叫他们在耶路撒冷聚集。"},
{volumn:        15,chapter:        10,versenumber:         8,verse:"凡不遵首领和长老所议定、三日之内不来的，就必抄他的家，使他离开被掳归回之人的会。"},
{volumn:        15,chapter:        10,versenumber:         9,verse:"于是，犹大和便雅悯众人，三日之内都聚集在耶路撒冷。那日正是九月二十日，众人都坐在　神殿前的宽阔处；因这事，又因下大雨，就都战兢。"},
{volumn:        15,chapter:        10,versenumber:        10,verse:"祭司以斯拉站起来，对他们说：“你们有罪了；因你们娶了外邦的女子为妻，增添以色列人的罪恶。"},
{volumn:        15,chapter:        10,versenumber:        11,verse:"现在当向耶和华你们列祖的　神认罪，遵行他的旨意，离绝这些国的民和外邦的女子。”"},
{volumn:        15,chapter:        10,versenumber:        12,verse:"会众都大声回答说：“我们必照着你的话行，"},
{volumn:        15,chapter:        10,versenumber:        13,verse:"只是百姓众多，又逢大雨的时令，我们不能站在外头，这也不是一两天办完的事，因我们在这事上犯了大罪；"},
{volumn:        15,chapter:        10,versenumber:        14,verse:"不如为全会众派首领办理。凡我们城邑中娶外邦女子为妻的，当按所定的日期，同着本城的长老和士师而来，直到办完这事，　神的烈怒就转离我们了。”"},
{volumn:        15,chapter:        10,versenumber:        15,verse:"惟有亚撒黑的儿子约拿单，特瓦的儿子雅哈谢阻挡（或译：总办）这事，并有米书兰和利未人沙比太帮助他们。"},
{volumn:        15,chapter:        10,versenumber:        16,verse:"被掳归回的人如此而行。祭司以斯拉和些族长按着宗族都指名见派；在十月初一日，一同在座查办这事，"},
{volumn:        15,chapter:        10,versenumber:        17,verse:"到正月初一日，才查清娶外邦女子的人数。"},
{volumn:        15,chapter:        10,versenumber:        18,verse:"在祭司中查出娶外邦女子为妻的，就是耶书亚的子孙约萨达的儿子，和他弟兄玛西雅、以利以谢、雅立、基大利；"},
{volumn:        15,chapter:        10,versenumber:        19,verse:"他们便应许必休他们的妻。他们因有罪，就献群中的一只公绵羊赎罪。"},
{volumn:        15,chapter:        10,versenumber:        20,verse:"音麦的子孙中，有哈拿尼、西巴第雅。"},
{volumn:        15,chapter:        10,versenumber:        21,verse:"哈琳的子孙中，有玛西雅、以利雅、示玛雅、耶歇、乌西雅。"},
{volumn:        15,chapter:        10,versenumber:        22,verse:"巴施户珥的子孙中，有以利约乃、玛西雅、以实玛利、拿坦业、约撒拔、以利亚撒。"},
{volumn:        15,chapter:        10,versenumber:        23,verse:"利未人中，有约撒拔、示每、基拉雅（基拉雅就是基利他），还有毗他希雅、犹大、以利以谢。"},
{volumn:        15,chapter:        10,versenumber:        24,verse:"歌唱的人中有以利亚实。守门的人中，有沙龙、提联、乌利。"},
{volumn:        15,chapter:        10,versenumber:        25,verse:"以色列人巴录的子孙中，有拉米、耶西雅、玛基雅、米雅民、以利亚撒、玛基雅、比拿雅。"},
{volumn:        15,chapter:        10,versenumber:        26,verse:"以拦的子孙中，有玛他尼、撒迦利亚、耶歇、押底、耶利末、以利雅。"},
{volumn:        15,chapter:        10,versenumber:        27,verse:"萨土的子孙中，有以利约乃、以利亚实、玛他尼、耶利末、撒拔、亚西撒。"},
{volumn:        15,chapter:        10,versenumber:        28,verse:"比拜的子孙中，有约哈难、哈拿尼雅、萨拜、亚勒。"},
{volumn:        15,chapter:        10,versenumber:        29,verse:"巴尼的子孙中，有米书兰、玛鹿、亚大雅、雅述、示押、耶利末。"},
{volumn:        15,chapter:        10,versenumber:        30,verse:"巴哈·摩押的子孙中，有阿底拿、基拉、比拿雅、玛西雅、玛他尼、比撒列、宾内、玛拿西。"},
{volumn:        15,chapter:        10,versenumber:        31,verse:"哈琳的子孙中，有以利以谢、伊示雅、玛基雅、示玛雅、西缅、"},
{volumn:        15,chapter:        10,versenumber:        32,verse:"便雅悯、玛鹿、示玛利雅。"},
{volumn:        15,chapter:        10,versenumber:        33,verse:"哈顺的子孙中，有玛特乃、玛达他、撒拔、以利法列、耶利买、玛拿西、示每。"},
{volumn:        15,chapter:        10,versenumber:        34,verse:"巴尼的子孙中，有玛玳、暗兰、乌益、"},
{volumn:        15,chapter:        10,versenumber:        35,verse:"比拿雅、比底雅、基禄、"},
{volumn:        15,chapter:        10,versenumber:        36,verse:"瓦尼雅、米利末、以利亚实、"},
{volumn:        15,chapter:        10,versenumber:        37,verse:"玛他尼、玛特乃、雅扫、"},
{volumn:        15,chapter:        10,versenumber:        38,verse:"巴尼、宾内、示每、"},
{volumn:        15,chapter:        10,versenumber:        39,verse:"示利米雅、拿单、亚大雅、"},
{volumn:        15,chapter:        10,versenumber:        40,verse:"玛拿底拜、沙赛、沙赖、"},
{volumn:        15,chapter:        10,versenumber:        41,verse:"亚萨利、示利米雅、示玛利雅、"},
{volumn:        15,chapter:        10,versenumber:        42,verse:"沙龙、亚玛利雅、约瑟。"},
{volumn:        15,chapter:        10,versenumber:        43,verse:"尼波的子孙中，有耶利、玛他提雅、撒拔、西比拿、雅玳、约珥、比拿雅。"},
{volumn:        15,chapter:        10,versenumber:        44,verse:"这些人都娶了外邦女子为妻，其中也有生了儿女的。"},
{volumn:        16,chapter:         1,versenumber:         1,verse:"哈迦利亚的儿子尼希米的言语如下：亚达薛西王二十年基斯流月，我在书珊城的宫中。"},
{volumn:        16,chapter:         1,versenumber:         2,verse:"那时，有我一个弟兄哈拿尼，同着几个人从犹大来。我问他们那些被掳归回、剩下逃脱的犹大人和耶路撒冷的光景。"},
{volumn:        16,chapter:         1,versenumber:         3,verse:"他们对我说：“那些被掳归回剩下的人在犹大省遭大难，受凌辱；并且耶路撒冷的城墙拆毁，城门被火焚烧。”"},
{volumn:        16,chapter:         1,versenumber:         4,verse:"我听见这话，就坐下哭泣，悲哀几日，在天上的　神面前禁食祈祷，说："},
{volumn:        16,chapter:         1,versenumber:         5,verse:"“耶和华天上的　神，大而可畏的　神啊，你向爱你、守你诫命的人守约施慈爱。"},
{volumn:        16,chapter:         1,versenumber:         6,verse:"愿你睁眼看，侧耳听，你仆人昼夜在你面前为你众仆人以色列民的祈祷，承认我们以色列人向你所犯的罪；我与我父家都有罪了。"},
{volumn:        16,chapter:         1,versenumber:         7,verse:"我们向你所行的甚是邪恶，没有遵守你藉着仆人摩西所吩咐的诫命、律例、典章。"},
{volumn:        16,chapter:         1,versenumber:         8,verse:"求你记念所吩咐你仆人摩西的话，说：‘你们若犯罪，我就把你们分散在万民中；"},
{volumn:        16,chapter:         1,versenumber:         9,verse:"但你们若归向我，谨守遵行我的诫命，你们被赶散的人虽在天涯，我也必从那里将他们招聚回来，带到我所选择立为我名的居所。’"},
{volumn:        16,chapter:         1,versenumber:        10,verse:"这都是你的仆人、你的百姓，就是你用大力和大能的手所救赎的。"},
{volumn:        16,chapter:         1,versenumber:        11,verse:"主啊，求你侧耳听你仆人的祈祷，和喜爱敬畏你名众仆人的祈祷，使你仆人现今亨通，在王面前蒙恩。”我是作王酒政的。"},
{volumn:        16,chapter:         2,versenumber:         1,verse:"亚达薛西王二十年尼散月，在王面前摆酒，我拿起酒来奉给王。我素来在王面前没有愁容。"},
{volumn:        16,chapter:         2,versenumber:         2,verse:"王对我说：“你既没有病，为什么面带愁容呢？这不是别的，必是你心中愁烦。”于是我甚惧怕。"},
{volumn:        16,chapter:         2,versenumber:         3,verse:"我对王说：“愿王万岁！我列祖坟墓所在的那城荒凉，城门被火焚烧，我岂能面无愁容吗？”"},
{volumn:        16,chapter:         2,versenumber:         4,verse:"王问我说：“你要求什么？”于是我默祷天上的　神。"},
{volumn:        16,chapter:         2,versenumber:         5,verse:"我对王说：“仆人若在王眼前蒙恩，王若喜欢，求王差遣我往犹大，到我列祖坟墓所在的那城去，我好重新建造。”"},
{volumn:        16,chapter:         2,versenumber:         6,verse:"那时王后坐在王的旁边。王问我说：“你去要多少日子？几时回来？”我就定了日期。于是王喜欢差遣我去。"},
{volumn:        16,chapter:         2,versenumber:         7,verse:"我又对王说：“王若喜欢，求王赐我诏书，通知大河西的省长准我经过，直到犹大；"},
{volumn:        16,chapter:         2,versenumber:         8,verse:"又赐诏书，通知管理王园林的亚萨，使他给我木料，做属殿营楼之门的横梁和城墙，与我自己房屋使用的。”王就允准我，因我　神施恩的手帮助我。"},
{volumn:        16,chapter:         2,versenumber:         9,verse:"王派了军长和马兵护送我。我到了河西的省长那里，将王的诏书交给他们。"},
{volumn:        16,chapter:         2,versenumber:        10,verse:"和伦人参巴拉，并为奴的亚扪人多比雅，听见有人来为以色列人求好处，就甚恼怒。"},
{volumn:        16,chapter:         2,versenumber:        11,verse:"我到了耶路撒冷，在那里住了三日。"},
{volumn:        16,chapter:         2,versenumber:        12,verse:"我夜间起来，有几个人也一同起来；但　神使我心里要为耶路撒冷做什么事，我并没有告诉人。除了我骑的牲口以外，也没有别的牲口在我那里。"},
{volumn:        16,chapter:         2,versenumber:        13,verse:"当夜我出了谷门，往野狗井去（野狗：或译龙），到了粪厂门，察看耶路撒冷的城墙，见城墙拆毁，城门被火焚烧。"},
{volumn:        16,chapter:         2,versenumber:        14,verse:"我又往前，到了泉门和王池，但所骑的牲口没有地方过去。"},
{volumn:        16,chapter:         2,versenumber:        15,verse:"于是夜间沿溪而上，察看城墙，又转身进入谷门，就回来了。"},
{volumn:        16,chapter:         2,versenumber:        16,verse:"我往哪里去，我做什么事，官长都不知道。我还没有告诉犹大平民、祭司、贵胄、官长，和其余做工的人。"},
{volumn:        16,chapter:         2,versenumber:        17,verse:"以后，我对他们说：“我们所遭的难，耶路撒冷怎样荒凉，城门被火焚烧，你们都看见了。来吧，我们重建耶路撒冷的城墙，免得再受凌辱！”"},
{volumn:        16,chapter:         2,versenumber:        18,verse:"我告诉他们我　神施恩的手怎样帮助我，并王对我所说的话。他们就说：“我们起来建造吧！”于是他们奋勇做这善工。"},
{volumn:        16,chapter:         2,versenumber:        19,verse:"但和伦人参巴拉，并为奴的亚扪人多比雅和阿拉伯人基善听见就嗤笑我们，藐视我们，说：“你们做什么呢？要背叛王吗？”"},
{volumn:        16,chapter:         2,versenumber:        20,verse:"我回答他们说：“天上的　神必使我们亨通。我们作他仆人的，要起来建造；你们却在耶路撒冷无份、无权、无纪念。”"},
{volumn:        16,chapter:         3,versenumber:         1,verse:"那时，大祭司以利亚实和他的弟兄众祭司起来建立羊门，分别为圣，安立门扇，又筑城墙到哈米亚楼，直到哈楠业楼，分别为圣。"},
{volumn:        16,chapter:         3,versenumber:         2,verse:"其次是耶利哥人建造。其次是音利的儿子撒刻建造。"},
{volumn:        16,chapter:         3,versenumber:         3,verse:"哈西拿的子孙建立鱼门，架横梁、安门扇，和闩锁。"},
{volumn:        16,chapter:         3,versenumber:         4,verse:"其次是哈哥斯的孙子、乌利亚的儿子米利末修造。其次是米示萨别的孙子、比利迦的儿子米书兰修造。其次是巴拿的儿子撒督修造。"},
{volumn:        16,chapter:         3,versenumber:         5,verse:"其次是提哥亚人修造；但是他们的贵胄不用肩（原文是颈项）担他们主的工作。"},
{volumn:        16,chapter:         3,versenumber:         6,verse:"巴西亚的儿子耶何耶大与比所玳的儿子米书兰修造古门，架横梁，安门扇和闩锁。"},
{volumn:        16,chapter:         3,versenumber:         7,verse:"其次是基遍人米拉提，米伦人雅顿与基遍人，并属河西总督所管的米斯巴人修造。"},
{volumn:        16,chapter:         3,versenumber:         8,verse:"其次是银匠哈海雅的儿子乌薛修造。其次是做香的哈拿尼雅修造。这些人修坚耶路撒冷，直到宽墙。"},
{volumn:        16,chapter:         3,versenumber:         9,verse:"其次是管理耶路撒冷一半、户珥的儿子利法雅修造。"},
{volumn:        16,chapter:         3,versenumber:        10,verse:"其次是哈路抹的儿子耶大雅对着自己的房屋修造。其次是哈沙尼的儿子哈突修造。"},
{volumn:        16,chapter:         3,versenumber:        11,verse:"哈琳的儿子玛基雅和巴哈·摩押的儿子哈述修造一段，并修造炉楼。"},
{volumn:        16,chapter:         3,versenumber:        12,verse:"其次是管理耶路撒冷那一半、哈罗黑的儿子沙龙和他的女儿们修造。"},
{volumn:        16,chapter:         3,versenumber:        13,verse:"哈嫩和撒挪亚的居民修造谷门，立门，安门扇和闩锁，又建筑城墙一千肘，直到粪厂门。"},
{volumn:        16,chapter:         3,versenumber:        14,verse:"管理伯·哈基琳、利甲的儿子玛基雅修造粪厂门，立门，安门扇和闩锁。"},
{volumn:        16,chapter:         3,versenumber:        15,verse:"管理米斯巴、各荷西的儿子沙仑修造泉门，立门，盖门顶，安门扇和闩锁，又修造靠近王园西罗亚池的墙垣，直到那从大卫城下来的台阶。"},
{volumn:        16,chapter:         3,versenumber:        16,verse:"其次是管理伯·夙一半、押卜的儿子尼希米修造，直到大卫坟地的对面，又到挖成的池子，并勇士的房屋。"},
{volumn:        16,chapter:         3,versenumber:        17,verse:"其次是利未人巴尼的儿子利宏修造。其次是管理基伊拉一半、哈沙比雅为他所管的本境修造。"},
{volumn:        16,chapter:         3,versenumber:        18,verse:"其次是利未人弟兄中管理基伊拉那一半、希拿达的儿子巴瓦伊修造。"},
{volumn:        16,chapter:         3,versenumber:        19,verse:"其次是管理米斯巴、耶书亚的儿子以谢修造一段，对着武库的上坡、城墙转弯之处。"},
{volumn:        16,chapter:         3,versenumber:        20,verse:"其次是萨拜的儿子巴录竭力修造一段，从城墙转弯，直到大祭司以利亚实的府门。"},
{volumn:        16,chapter:         3,versenumber:        21,verse:"其次是哈哥斯的孙子、乌利亚的儿子米利末修造一段，从以利亚实的府门，直到以利亚实府的尽头。"},
{volumn:        16,chapter:         3,versenumber:        22,verse:"其次是住平原的祭司修造。"},
{volumn:        16,chapter:         3,versenumber:        23,verse:"其次是便雅悯与哈述对着自己的房屋修造。其次是亚难尼的孙子、玛西雅的儿子亚撒利雅在靠近自己的房屋修造。"},
{volumn:        16,chapter:         3,versenumber:        24,verse:"其次是希拿达的儿子宾内修造一段，从亚撒利雅的房屋直到城墙转弯，又到城角。"},
{volumn:        16,chapter:         3,versenumber:        25,verse:"乌赛的儿子巴拉修造对着城墙的转弯和王上宫凸出来的城楼，靠近护卫院的那一段。其次是巴录的儿子毗大雅修造。（"},
{volumn:        16,chapter:         3,versenumber:        26,verse:"尼提宁住在俄斐勒，直到朝东水门的对面和凸出来的城楼。）"},
{volumn:        16,chapter:         3,versenumber:        27,verse:"其次是提哥亚人又修一段，对着那凸出来的大楼，直到俄斐勒的墙。"},
{volumn:        16,chapter:         3,versenumber:        28,verse:"从马门往上，众祭司各对自己的房屋修造。"},
{volumn:        16,chapter:         3,versenumber:        29,verse:"其次是音麦的儿子撒督对着自己的房屋修造。其次是守东门、示迦尼的儿子示玛雅修造。"},
{volumn:        16,chapter:         3,versenumber:        30,verse:"其次是示利米雅的儿子哈拿尼雅和萨拉的第六子哈嫩又修一段。其次是比利迦的儿子米书兰对着自己的房屋修造。"},
{volumn:        16,chapter:         3,versenumber:        31,verse:"其次是银匠玛基雅修造到尼提宁和商人的房屋，对着哈米弗甲门，直到城的角楼。"},
{volumn:        16,chapter:         3,versenumber:        32,verse:"银匠与商人在城的角楼和羊门中间修造。"},
{volumn:        16,chapter:         4,versenumber:         1,verse:"参巴拉听见我们修造城墙就发怒，大大恼恨，嗤笑犹大人，"},
{volumn:        16,chapter:         4,versenumber:         2,verse:"对他弟兄和撒玛利亚的军兵说：“这些软弱的犹大人做什么呢？要保护自己吗？要献祭吗？要一日成功吗？要从土堆里拿出火烧的石头再立墙吗？”"},
{volumn:        16,chapter:         4,versenumber:         3,verse:"亚扪人多比雅站在旁边，说：“他们所修造的石墙，就是狐狸上去也必跐倒。”"},
{volumn:        16,chapter:         4,versenumber:         4,verse:"我们的　神啊，求你垂听，因为我们被藐视。求你使他们的毁谤归于他们的头上，使他们在掳到之地作为掠物。"},
{volumn:        16,chapter:         4,versenumber:         5,verse:"不要遮掩他们的罪孽，不要使他们的罪恶从你面前涂抹，因为他们在修造的人眼前惹动你的怒气。"},
{volumn:        16,chapter:         4,versenumber:         6,verse:"这样，我们修造城墙，城墙就都连络，高至一半，因为百姓专心做工。"},
{volumn:        16,chapter:         4,versenumber:         7,verse:"参巴拉、多比雅、阿拉伯人、亚扪人、亚实突人听见修造耶路撒冷城墙，着手进行堵塞破裂的地方，就甚发怒。"},
{volumn:        16,chapter:         4,versenumber:         8,verse:"大家同谋要来攻击耶路撒冷，使城内扰乱。"},
{volumn:        16,chapter:         4,versenumber:         9,verse:"然而，我们祷告我们的　神，又因他们的缘故，就派人看守，昼夜防备。"},
{volumn:        16,chapter:         4,versenumber:        10,verse:"犹大人说：“灰土尚多，扛抬的人力气已经衰败，所以我们不能建造城墙。”"},
{volumn:        16,chapter:         4,versenumber:        11,verse:"我们的敌人且说：“趁他们不知不见，我们进入他们中间，杀他们，使工作止住。”"},
{volumn:        16,chapter:         4,versenumber:        12,verse:"那靠近敌人居住的犹大人十次从各处来见我们，说：“你们必要回到我们那里。”"},
{volumn:        16,chapter:         4,versenumber:        13,verse:"所以我使百姓各按宗族拿刀、拿枪、拿弓站在城墙后边低洼的空处。"},
{volumn:        16,chapter:         4,versenumber:        14,verse:"我察看了，就起来对贵胄、官长，和其余的人说：“不要怕他们！当记念主是大而可畏的。你们要为弟兄、儿女、妻子、家产争战。”"},
{volumn:        16,chapter:         4,versenumber:        15,verse:"仇敌听见我们知道他们的心意，见　神也破坏他们的计谋，就不来了。我们都回到城墙那里，各做各的工。"},
{volumn:        16,chapter:         4,versenumber:        16,verse:"从那日起，我的仆人一半做工，一半拿枪、拿盾牌、拿弓、穿（或译：拿）铠甲，官长都站在犹大众人的后边。"},
{volumn:        16,chapter:         4,versenumber:        17,verse:"修造城墙的，扛抬材料的，都一手做工一手拿兵器。"},
{volumn:        16,chapter:         4,versenumber:        18,verse:"修造的人都腰间佩刀修造，吹角的人在我旁边。"},
{volumn:        16,chapter:         4,versenumber:        19,verse:"我对贵胄、官长，和其余的人说：“这工程浩大，我们在城墙上相离甚远；"},
{volumn:        16,chapter:         4,versenumber:        20,verse:"你们听见角声在哪里，就聚集到我们那里去。我们的　神必为我们争战。”"},
{volumn:        16,chapter:         4,versenumber:        21,verse:"于是，我们做工，一半拿兵器，从天亮直到星宿出现的时候。"},
{volumn:        16,chapter:         4,versenumber:        22,verse:"那时，我又对百姓说：“各人和他的仆人当在耶路撒冷住宿，好在夜间保守我们，白昼做工。”"},
{volumn:        16,chapter:         4,versenumber:        23,verse:"这样，我和弟兄仆人，并跟从我的护兵都不脱衣服，出去打水也带兵器。"},
{volumn:        16,chapter:         5,versenumber:         1,verse:"百姓和他们的妻大大呼号，埋怨他们的弟兄犹大人。"},
{volumn:        16,chapter:         5,versenumber:         2,verse:"有的说：“我们和儿女人口众多，要去得粮食度命”；"},
{volumn:        16,chapter:         5,versenumber:         3,verse:"有的说：“我们典了田地、葡萄园、房屋，要得粮食充饥”；"},
{volumn:        16,chapter:         5,versenumber:         4,verse:"有的说：“我们已经指着田地、葡萄园，借了钱给王纳税。"},
{volumn:        16,chapter:         5,versenumber:         5,verse:"我们的身体与我们弟兄的身体一样；我们的儿女与他们的儿女一般。现在我们将要使儿女作人的仆婢，我们的女儿已有为婢的；我们并无力拯救，因为我们的田地、葡萄园已经归了别人。”"},
{volumn:        16,chapter:         5,versenumber:         6,verse:"我听见他们呼号说这些话，便甚发怒。"},
{volumn:        16,chapter:         5,versenumber:         7,verse:"我心里筹划，就斥责贵胄和官长说：“你们各人向弟兄取利！”于是我招聚大会攻击他们。"},
{volumn:        16,chapter:         5,versenumber:         8,verse:"我对他们说：“我们尽力赎回我们弟兄，就是卖与外邦的犹大人；你们还要卖弟兄，使我们赎回来吗？”他们就静默不语，无话可答。"},
{volumn:        16,chapter:         5,versenumber:         9,verse:"我又说：“你们所行的不善！你们行事不当敬畏我们的　神吗？不然，难免我们的仇敌外邦人毁谤我们。"},
{volumn:        16,chapter:         5,versenumber:        10,verse:"我和我的弟兄与仆人也将银钱粮食借给百姓；我们大家都当免去利息。"},
{volumn:        16,chapter:         5,versenumber:        11,verse:"如今我劝你们将他们的田地、葡萄园、橄榄园、房屋，并向他们所取的银钱、粮食、新酒，和油，百分之一的利息都归还他们。”"},
{volumn:        16,chapter:         5,versenumber:        12,verse:"众人说：“我们必归还，不再向他们索要，必照你的话行。”我就召了祭司来，叫众人起誓，必照着所应许的而行。"},
{volumn:        16,chapter:         5,versenumber:        13,verse:"我也抖着胸前的衣襟，说：“凡不成就这应许的，愿　神照样抖他离开家产和他劳碌得来的，直到抖空了。”会众都说：“阿们！”又赞美耶和华。百姓就照着所应许的去行。"},
{volumn:        16,chapter:         5,versenumber:        14,verse:"自从我奉派作犹大地的省长，就是从亚达薛西王二十年直到三十二年，共十二年之久，我与我弟兄都没有吃省长的俸禄。"},
{volumn:        16,chapter:         5,versenumber:        15,verse:"在我以前的省长加重百姓的担子，每日索要粮食和酒，并银子四十舍客勒，就是他们的仆人也辖制百姓；但我因敬畏　神不这样行。"},
{volumn:        16,chapter:         5,versenumber:        16,verse:"并且我恒心修造城墙，并没有置买田地；我的仆人也都聚集在那里做工。"},
{volumn:        16,chapter:         5,versenumber:        17,verse:"除了从四围外邦中来的犹大人以外，有犹大平民和官长一百五十人在我席上吃饭。"},
{volumn:        16,chapter:         5,versenumber:        18,verse:"每日预备一只公牛，六只肥羊，又预备些飞禽；每十日一次，多预备各样的酒。虽然如此，我并不要省长的俸禄，因为百姓服役甚重。"},
{volumn:        16,chapter:         5,versenumber:        19,verse:"我的　神啊，求你记念我为这百姓所行的一切事，施恩与我。"},
{volumn:        16,chapter:         6,versenumber:         1,verse:"参巴拉、多比雅、阿拉伯人基善和我们其余的仇敌听见我已经修完了城墙，其中没有破裂之处（那时我还没有安门扇），"},
{volumn:        16,chapter:         6,versenumber:         2,verse:"参巴拉和基善就打发人来见我，说：“请你来，我们在阿挪平原的一个村庄相会。”他们却想害我。"},
{volumn:        16,chapter:         6,versenumber:         3,verse:"于是我差遣人去见他们，说：“我现在办理大工，不能下去。焉能停工下去见你们呢？”"},
{volumn:        16,chapter:         6,versenumber:         4,verse:"他们这样四次打发人来见我，我都如此回答他们。"},
{volumn:        16,chapter:         6,versenumber:         5,verse:"参巴拉第五次打发仆人来见我，手里拿着未封的信，"},
{volumn:        16,chapter:         6,versenumber:         6,verse:"信上写着说：“外邦人中有风声，迦施慕（就是基善，见二章十九节）也说，你和犹大人谋反，修造城墙，你要作他们的王；"},
{volumn:        16,chapter:         6,versenumber:         7,verse:"你又派先知在耶路撒冷指着你宣讲，说在犹大有王。现在这话必传与王知；所以请你来，与我们彼此商议。”"},
{volumn:        16,chapter:         6,versenumber:         8,verse:"我就差遣人去见他，说：“你所说的这事，一概没有，是你心里捏造的。”"},
{volumn:        16,chapter:         6,versenumber:         9,verse:"他们都要使我们惧怕，意思说，他们的手必软弱，以致工作不能成就。　神啊，求你坚固我的手。"},
{volumn:        16,chapter:         6,versenumber:        10,verse:"我到了米希大别的孙子、第来雅的儿子示玛雅家里；那时，他闭门不出。他说：“我们不如在　神的殿里会面，将殿门关锁；因为他们要来杀你，就是夜里来杀你。”"},
{volumn:        16,chapter:         6,versenumber:        11,verse:"我说：“像我这样的人岂要逃跑呢？像我这样的人岂能进入殿里保全生命呢？我不进去！”"},
{volumn:        16,chapter:         6,versenumber:        12,verse:"我看明　神没有差遣他，是他自己说这话攻击我，是多比雅和参巴拉贿买了他。"},
{volumn:        16,chapter:         6,versenumber:        13,verse:"贿买他的缘故，是要叫我惧怕，依从他犯罪，他们好传扬恶言毁谤我。"},
{volumn:        16,chapter:         6,versenumber:        14,verse:"我的　神啊，多比雅、参巴拉、女先知挪亚底，和其余的先知要叫我惧怕，求你记念他们所行的这些事。"},
{volumn:        16,chapter:         6,versenumber:        15,verse:"以禄月二十五日，城墙修完了，共修了五十二天。"},
{volumn:        16,chapter:         6,versenumber:        16,verse:"我们一切仇敌、四围的外邦人听见了便惧怕，愁眉不展；因为见这工作完成是出乎我们的　神。"},
{volumn:        16,chapter:         6,versenumber:        17,verse:"在那些日子，犹大的贵胄屡次寄信与多比雅，多比雅也来信与他们。"},
{volumn:        16,chapter:         6,versenumber:        18,verse:"在犹大有许多人与多比雅结盟；因他是亚拉的儿子，示迦尼的女婿，并且他的儿子约哈难娶了比利迦儿子米书兰的女儿为妻。"},
{volumn:        16,chapter:         6,versenumber:        19,verse:"他们常在我面前说多比雅的善行，也将我的话传与他。多比雅又常寄信来，要叫我惧怕。"},
{volumn:        16,chapter:         7,versenumber:         1,verse:"城墙修完，我安了门扇，守门的、歌唱的，和利未人都已派定。"},
{volumn:        16,chapter:         7,versenumber:         2,verse:"我就派我的弟兄哈拿尼和营楼的宰官哈拿尼雅管理耶路撒冷；因为哈拿尼雅是忠信的，又敬畏　神过于众人。"},
{volumn:        16,chapter:         7,versenumber:         3,verse:"我吩咐他们说：“等到太阳上升才可开耶路撒冷的城门；人尚看守的时候就要关门上闩；也当派耶路撒冷的居民各按班次看守自己房屋对面之处。”"},
{volumn:        16,chapter:         7,versenumber:         4,verse:"城是广大，其中的民却稀少，房屋还没有建造。"},
{volumn:        16,chapter:         7,versenumber:         5,verse:"我的　神感动我心，招聚贵胄、官长，和百姓，要照家谱计算。我找着第一次上来之人的家谱，其上写着："},
{volumn:        16,chapter:         7,versenumber:         6,verse:"巴比伦王尼布甲尼撒从前掳去犹大省的人，现在他们的子孙从被掳到之地回耶路撒冷和犹大，各归本城。"},
{volumn:        16,chapter:         7,versenumber:         7,verse:"他们是同着所罗巴伯、耶书亚、尼希米、亚撒利雅、拉米、拿哈玛尼、末底改、必珊、米斯毗列、比革瓦伊、尼宏、巴拿回来的。"},
{volumn:        16,chapter:         7,versenumber:         8,verse:"以色列人民的数目记在下面：巴录的子孙二千一百七十二名；"},
{volumn:        16,chapter:         7,versenumber:         9,verse:"示法提雅的子孙三百七十二名；"},
{volumn:        16,chapter:         7,versenumber:        10,verse:"亚拉的子孙六百五十二名；"},
{volumn:        16,chapter:         7,versenumber:        11,verse:"巴哈·摩押的后裔，就是耶书亚和约押的子孙二千八百一十八名；"},
{volumn:        16,chapter:         7,versenumber:        12,verse:"以拦的子孙一千二百五十四名；"},
{volumn:        16,chapter:         7,versenumber:        13,verse:"萨土的子孙八百四十五名；"},
{volumn:        16,chapter:         7,versenumber:        14,verse:"萨改的子孙七百六十名；"},
{volumn:        16,chapter:         7,versenumber:        15,verse:"宾内的子孙六百四十八名；"},
{volumn:        16,chapter:         7,versenumber:        16,verse:"比拜的子孙六百二十八名；"},
{volumn:        16,chapter:         7,versenumber:        17,verse:"押甲的子孙二千三百二十二名；"},
{volumn:        16,chapter:         7,versenumber:        18,verse:"亚多尼干的子孙六百六十七名；"},
{volumn:        16,chapter:         7,versenumber:        19,verse:"比革瓦伊的子孙二千零六十七名；"},
{volumn:        16,chapter:         7,versenumber:        20,verse:"亚丁的子孙六百五十五名；"},
{volumn:        16,chapter:         7,versenumber:        21,verse:"亚特的后裔，就是希西家的子孙九十八名；"},
{volumn:        16,chapter:         7,versenumber:        22,verse:"哈顺的子孙三百二十八名；"},
{volumn:        16,chapter:         7,versenumber:        23,verse:"比赛的子孙三百二十四名；"},
{volumn:        16,chapter:         7,versenumber:        24,verse:"哈拉的子孙一百一十二名；"},
{volumn:        16,chapter:         7,versenumber:        25,verse:"基遍人九十五名；"},
{volumn:        16,chapter:         7,versenumber:        26,verse:"伯利恒人和尼陀法人共一百八十八名；"},
{volumn:        16,chapter:         7,versenumber:        27,verse:"亚拿突人一百二十八名；"},
{volumn:        16,chapter:         7,versenumber:        28,verse:"伯·亚斯玛弗人四十二名；"},
{volumn:        16,chapter:         7,versenumber:        29,verse:"基列·耶琳人、基非拉人、比录人共七百四十三名；"},
{volumn:        16,chapter:         7,versenumber:        30,verse:"拉玛人和迦巴人共六百二十一名；"},
{volumn:        16,chapter:         7,versenumber:        31,verse:"默玛人一百二十二名；"},
{volumn:        16,chapter:         7,versenumber:        32,verse:"伯特利人和艾人共一百二十三名；"},
{volumn:        16,chapter:         7,versenumber:        33,verse:"别的尼波人五十二名；"},
{volumn:        16,chapter:         7,versenumber:        34,verse:"别的以拦子孙一千二百五十四名；"},
{volumn:        16,chapter:         7,versenumber:        35,verse:"哈琳的子孙三百二十名；"},
{volumn:        16,chapter:         7,versenumber:        36,verse:"耶利哥人三百四十五名；"},
{volumn:        16,chapter:         7,versenumber:        37,verse:"罗德人、哈第人、阿挪人共七百二十一名；"},
{volumn:        16,chapter:         7,versenumber:        38,verse:"西拿人三千九百三十名。"},
{volumn:        16,chapter:         7,versenumber:        39,verse:"祭司：耶书亚家，耶大雅的子孙九百七十三名；"},
{volumn:        16,chapter:         7,versenumber:        40,verse:"音麦的子孙一千零五十二名；"},
{volumn:        16,chapter:         7,versenumber:        41,verse:"巴施户珥的子孙一千二百四十七名；"},
{volumn:        16,chapter:         7,versenumber:        42,verse:"哈琳的子孙一千零一十七名。"},
{volumn:        16,chapter:         7,versenumber:        43,verse:"利未人：何达威的后裔，就是耶书亚和甲篾的子孙七十四名。"},
{volumn:        16,chapter:         7,versenumber:        44,verse:"歌唱的：亚萨的子孙一百四十八名。"},
{volumn:        16,chapter:         7,versenumber:        45,verse:"守门的：沙龙的子孙、亚特的子孙、达们的子孙、亚谷的子孙、哈底大的子孙、朔拜的子孙，共一百三十八名。"},
{volumn:        16,chapter:         7,versenumber:        46,verse:"尼提宁（就是殿役）：西哈的子孙、哈苏巴的子孙、答巴俄的子孙、"},
{volumn:        16,chapter:         7,versenumber:        47,verse:"基绿的子孙、西亚的子孙、巴顿的子孙、"},
{volumn:        16,chapter:         7,versenumber:        48,verse:"利巴拿的子孙、哈迦巴的子孙、萨买的子孙、"},
{volumn:        16,chapter:         7,versenumber:        49,verse:"哈难的子孙、吉德的子孙、迦哈的子孙、"},
{volumn:        16,chapter:         7,versenumber:        50,verse:"利亚雅的子孙、利汛的子孙、尼哥大的子孙、"},
{volumn:        16,chapter:         7,versenumber:        51,verse:"迦散的子孙、乌撒的子孙、巴西亚的子孙、"},
{volumn:        16,chapter:         7,versenumber:        52,verse:"比赛的子孙、米乌宁的子孙、尼普心的子孙、"},
{volumn:        16,chapter:         7,versenumber:        53,verse:"巴卜的子孙、哈古巴的子孙、哈忽的子孙、"},
{volumn:        16,chapter:         7,versenumber:        54,verse:"巴洗律的子孙、米希大的子孙、哈沙的子孙、"},
{volumn:        16,chapter:         7,versenumber:        55,verse:"巴柯的子孙、西西拉的子孙、答玛的子孙、"},
{volumn:        16,chapter:         7,versenumber:        56,verse:"尼细亚的子孙、哈提法的子孙。"},
{volumn:        16,chapter:         7,versenumber:        57,verse:"所罗门仆人的后裔，就是琐太的子孙、琐斐列的子孙、比路大的子孙、"},
{volumn:        16,chapter:         7,versenumber:        58,verse:"雅拉的子孙、达昆的子孙、吉德的子孙、"},
{volumn:        16,chapter:         7,versenumber:        59,verse:"示法提雅的子孙、哈替的子孙、玻黑列·哈斯巴音的子孙、亚们的子孙。"},
{volumn:        16,chapter:         7,versenumber:        60,verse:"尼提宁和所罗门仆人的后裔共三百九十二名。"},
{volumn:        16,chapter:         7,versenumber:        61,verse:"从特米拉、特哈萨、基绿、亚顿、音麦上来的，不能指明他们的宗族谱系是以色列人不是；"},
{volumn:        16,chapter:         7,versenumber:        62,verse:"他们是第莱雅的子孙、多比雅的子孙、尼哥大的子孙，共六百四十二名。"},
{volumn:        16,chapter:         7,versenumber:        63,verse:"祭司中，哈巴雅的子孙、哈哥斯的子孙、巴西莱的子孙；因为他们的先祖娶了基列人巴西莱的女儿为妻，所以起名叫巴西莱。"},
{volumn:        16,chapter:         7,versenumber:        64,verse:"这三家的人在族谱之中寻查自己的谱系，却寻不着，因此算为不洁，不准供祭司的职任。"},
{volumn:        16,chapter:         7,versenumber:        65,verse:"省长对他们说：“不可吃至圣的物，直到有用乌陵和土明决疑的祭司兴起来。”"},
{volumn:        16,chapter:         7,versenumber:        66,verse:"会众共有四万二千三百六十名。"},
{volumn:        16,chapter:         7,versenumber:        67,verse:"此外，还有他们的仆婢七千三百三十七名，又有歌唱的男女二百四十五名。"},
{volumn:        16,chapter:         7,versenumber:        68,verse:"他们有马七百三十六匹，骡子二百四十五匹，"},
{volumn:        16,chapter:         7,versenumber:        69,verse:"骆驼四百三十五只，驴六千七百二十匹。"},
{volumn:        16,chapter:         7,versenumber:        70,verse:"有些族长为工程捐助。省长捐入库中的金子一千达利克，碗五十个，祭司的礼服五百三十件。"},
{volumn:        16,chapter:         7,versenumber:        71,verse:"又有族长捐入工程库的金子二万达利克，银子二千二百弥拿。"},
{volumn:        16,chapter:         7,versenumber:        72,verse:"其余百姓所捐的金子二万达利克，银子二千弥拿，祭司的礼服六十七件。"},
{volumn:        16,chapter:         7,versenumber:        73,verse:"于是祭司、利未人、守门的、歌唱的、民中的一些人、尼提宁，并以色列众人，各住在自己的城里。"},
{volumn:        16,chapter:         8,versenumber:         1,verse:"到了七月，以色列人住在自己的城里。那时，他们如同一人聚集在水门前的宽阔处，请文士以斯拉将耶和华藉摩西传给以色列人的律法书带来。"},
{volumn:        16,chapter:         8,versenumber:         2,verse:"七月初一日，祭司以斯拉将律法书带到听了能明白的男女会众面前。"},
{volumn:        16,chapter:         8,versenumber:         3,verse:"在水门前的宽阔处，从清早到晌午，在众男女、一切听了能明白的人面前读这律法书。众民侧耳而听。"},
{volumn:        16,chapter:         8,versenumber:         4,verse:"文士以斯拉站在为这事特备的木台上。玛他提雅、示玛、亚奈雅、乌利亚、希勒家，和玛西雅站在他的右边；毗大雅、米沙利、玛基雅、哈顺、哈拔大拿、撒迦利亚，和米书兰站在他的左边。"},
{volumn:        16,chapter:         8,versenumber:         5,verse:"以斯拉站在众民以上，在众民眼前展开这书。他一展开，众民就都站起来。"},
{volumn:        16,chapter:         8,versenumber:         6,verse:"以斯拉称颂耶和华至大的　神；众民都举手应声说：“阿们！阿们！”就低头，面伏于地，敬拜耶和华。"},
{volumn:        16,chapter:         8,versenumber:         7,verse:"耶书亚、巴尼、示利比、雅悯、亚谷、沙比太、荷第雅、玛西雅、基利他、亚撒利雅、约撒拔、哈难、毗莱雅，和利未人使百姓明白律法；百姓都站在自己的地方。"},
{volumn:        16,chapter:         8,versenumber:         8,verse:"他们清清楚楚地念　神的律法书，讲明意思，使百姓明白所念的。"},
{volumn:        16,chapter:         8,versenumber:         9,verse:"省长尼希米和作祭司的文士以斯拉，并教训百姓的利未人，对众民说：“今日是耶和华你们　神的圣日，不要悲哀哭泣。”这是因为众民听见律法书上的话都哭了；"},
{volumn:        16,chapter:         8,versenumber:        10,verse:"又对他们说：“你们去吃肥美的，喝甘甜的，有不能预备的就分给他，因为今日是我们主的圣日。你们不要忧愁，因靠耶和华而得的喜乐是你们的力量。”"},
{volumn:        16,chapter:         8,versenumber:        11,verse:"于是利未人使众民静默，说：“今日是圣日；不要作声，也不要忧愁。”"},
{volumn:        16,chapter:         8,versenumber:        12,verse:"众民都去吃喝，也分给人，大大快乐，因为他们明白所教训他们的话。"},
{volumn:        16,chapter:         8,versenumber:        13,verse:"次日，众民的族长、祭司，和利未人都聚集到文士以斯拉那里，要留心听律法上的话。"},
{volumn:        16,chapter:         8,versenumber:        14,verse:"他们见律法上写着，耶和华藉摩西吩咐以色列人要在七月节住棚，"},
{volumn:        16,chapter:         8,versenumber:        15,verse:"并要在各城和耶路撒冷宣传报告说：“你们当上山，将橄榄树、野橄榄树、番石榴树、棕树，和各样茂密树的枝子取来，照着所写的搭棚。”"},
{volumn:        16,chapter:         8,versenumber:        16,verse:"于是百姓出去，取了树枝来，各人在自己的房顶上，或院内，或　神殿的院内，或水门的宽阔处，或以法莲门的宽阔处搭棚。"},
{volumn:        16,chapter:         8,versenumber:        17,verse:"从掳到之地归回的全会众就搭棚，住在棚里。从嫩的儿子约书亚的时候直到这日，以色列人没有这样行。于是众人大大喜乐。"},
{volumn:        16,chapter:         8,versenumber:        18,verse:"从头一天直到末一天，以斯拉每日念　神的律法书。众人守节七日，第八日照例有严肃会。"},
{volumn:        16,chapter:         9,versenumber:         1,verse:"这月二十四日，以色列人聚集禁食，身穿麻衣，头蒙灰尘。"},
{volumn:        16,chapter:         9,versenumber:         2,verse:"以色列人（人：原文是种类）就与一切外邦人离绝，站着承认自己的罪恶和列祖的罪孽。"},
{volumn:        16,chapter:         9,versenumber:         3,verse:"那日的四分之一站在自己的地方念耶和华他们　神的律法书，又四分之一认罪，敬拜耶和华他们的　神。"},
{volumn:        16,chapter:         9,versenumber:         4,verse:"耶书亚、巴尼、甲篾、示巴尼、布尼、示利比、巴尼、基拿尼站在利未人的台上，大声哀求耶和华他们的　神。"},
{volumn:        16,chapter:         9,versenumber:         5,verse:"利未人耶书亚、甲篾、巴尼、哈沙尼、示利比、荷第雅、示巴尼、毗他希雅说：“你们要站起来称颂耶和华你们的　神，永世无尽。耶和华啊，你荣耀之名是应当称颂的！超乎一切称颂和赞美。”"},
{volumn:        16,chapter:         9,versenumber:         6,verse:"“你，惟独你是耶和华！你造了天和天上的天，并天上的万象，地和地上的万物，海和海中所有的；这一切都是你所保存的。天军也都敬拜你。"},
{volumn:        16,chapter:         9,versenumber:         7,verse:"你是耶和华　神，曾拣选亚伯兰，领他出迦勒底的吾珥，给他改名叫亚伯拉罕。"},
{volumn:        16,chapter:         9,versenumber:         8,verse:"你见他在你面前心里诚实，就与他立约，应许把迦南人、赫人、亚摩利人、比利洗人、耶布斯人、革迦撒人之地赐给他的后裔，且应验了你的话，因为你是公义的。"},
{volumn:        16,chapter:         9,versenumber:         9,verse:"“你曾看见我们列祖在埃及所受的困苦，垂听他们在红海边的哀求，"},
{volumn:        16,chapter:         9,versenumber:        10,verse:"就施行神迹奇事在法老和他一切臣仆，并他国中的众民身上。你也得了名声，正如今日一样，因为你知道他们向我们列祖行事狂傲。"},
{volumn:        16,chapter:         9,versenumber:        11,verse:"你又在我们列祖面前把海分开，使他们在海中行走干地，将追赶他们的人抛在深海，如石头抛在大水中；"},
{volumn:        16,chapter:         9,versenumber:        12,verse:"并且白昼用云柱引导他们，黑夜用火柱照亮他们当行的路。"},
{volumn:        16,chapter:         9,versenumber:        13,verse:"你也降临在西奈山，从天上与他们说话，赐给他们正直的典章、真实的律法、美好的条例与诫命，"},
{volumn:        16,chapter:         9,versenumber:        14,verse:"又使他们知道你的安息圣日，并藉你仆人摩西传给他们诫命、条例、律法。"},
{volumn:        16,chapter:         9,versenumber:        15,verse:"从天上赐下粮食充他们的饥，从磐石使水流出解他们的渴，又吩咐他们进去得你起誓应许赐给他们的地。"},
{volumn:        16,chapter:         9,versenumber:        16,verse:"“但我们的列祖行事狂傲，硬着颈项不听从你的诫命；"},
{volumn:        16,chapter:         9,versenumber:        17,verse:"不肯顺从，也不记念你在他们中间所行的奇事，竟硬着颈项，居心背逆，自立首领，要回他们为奴之地。但你是乐意饶恕人，有恩典，有怜悯，不轻易发怒，有丰盛慈爱的　神，并不丢弃他们。"},
{volumn:        16,chapter:         9,versenumber:        18,verse:"他们虽然铸了一只牛犊，彼此说‘这是领你出埃及的神’，因而大大惹动你的怒气；"},
{volumn:        16,chapter:         9,versenumber:        19,verse:"你还是大施怜悯，在旷野不丢弃他们。白昼，云柱不离开他们，仍引导他们行路；黑夜，火柱也不离开他们，仍照亮他们当行的路。"},
{volumn:        16,chapter:         9,versenumber:        20,verse:"你也赐下你良善的灵教训他们；未尝不赐吗哪使他们糊口，并赐水解他们的渴。"},
{volumn:        16,chapter:         9,versenumber:        21,verse:"在旷野四十年，你养育他们，他们就一无所缺：衣服没有穿破，脚也没有肿。"},
{volumn:        16,chapter:         9,versenumber:        22,verse:"并且你将列国之地照份赐给他们，他们就得了西宏之地、希实本王之地，和巴珊王噩之地。"},
{volumn:        16,chapter:         9,versenumber:        23,verse:"你也使他们的子孙多如天上的星，带他们到你所应许他们列祖进入得为业之地。"},
{volumn:        16,chapter:         9,versenumber:        24,verse:"这样，他们进去得了那地，你在他们面前制伏那地的居民，就是迦南人；将迦南人和其君王，并那地的居民，都交在他们手里，让他们任意而待。"},
{volumn:        16,chapter:         9,versenumber:        25,verse:"他们得了坚固的城邑、肥美的地土、充满各样美物的房屋、凿成的水井、葡萄园、橄榄园，并许多果木树。他们就吃而得饱，身体肥胖，因你的大恩，心中快乐。"},
{volumn:        16,chapter:         9,versenumber:        26,verse:"“然而，他们不顺从，竟背叛你，将你的律法丢在背后，杀害那劝他们归向你的众先知，大大惹动你的怒气。"},
{volumn:        16,chapter:         9,versenumber:        27,verse:"所以你将他们交在敌人的手中，磨难他们。他们遭难的时候哀求你，你就从天上垂听，照你的大怜悯赐给他们拯救者，救他们脱离敌人的手。"},
{volumn:        16,chapter:         9,versenumber:        28,verse:"但他们得平安之后，又在你面前行恶，所以你丢弃他们在仇敌的手中，使仇敌辖制他们。然而他们转回哀求你，你仍从天上垂听，屡次照你的怜悯拯救他们，"},
{volumn:        16,chapter:         9,versenumber:        29,verse:"又警戒他们，要使他们归服你的律法。他们却行事狂傲，不听从你的诫命，干犯你的典章（人若遵行就必因此活着），扭转肩头，硬着颈项，不肯听从。"},
{volumn:        16,chapter:         9,versenumber:        30,verse:"但你多年宽容他们，又用你的灵藉众先知劝戒他们，他们仍不听从，所以你将他们交在列国之民的手中。"},
{volumn:        16,chapter:         9,versenumber:        31,verse:"然而你大发怜悯，不全然灭绝他们，也不丢弃他们；因为你是有恩典、有怜悯的　神。"},
{volumn:        16,chapter:         9,versenumber:        32,verse:"“我们的　神啊，你是至大、至能、至可畏、守约施慈爱的　神。我们的君王、首领、祭司、先知、列祖，和你的众民，从亚述列王的时候直到今日所遭遇的苦难，现在求你不要以为小。"},
{volumn:        16,chapter:         9,versenumber:        33,verse:"在一切临到我们的事上，你却是公义的；因你所行的是诚实，我们所做的是邪恶。"},
{volumn:        16,chapter:         9,versenumber:        34,verse:"我们的君王、首领、祭司、列祖都不遵守你的律法，不听从你的诫命和你警戒他们的话。"},
{volumn:        16,chapter:         9,versenumber:        35,verse:"他们在本国里沾你大恩的时候，在你所赐给他们这广大肥美之地上不侍奉你，也不转离他们的恶行。"},
{volumn:        16,chapter:         9,versenumber:        36,verse:"我们现今作了奴仆；至于你所赐给我们列祖享受其上的土产，并美物之地，看哪，我们在这地上作了奴仆！"},
{volumn:        16,chapter:         9,versenumber:        37,verse:"这地许多出产归了列王，就是你因我们的罪所派辖制我们的。他们任意辖制我们的身体和牲畜，我们遭了大难。”"},
{volumn:        16,chapter:         9,versenumber:        38,verse:"因这一切的事，我们立确实的约，写在册上。我们的首领、利未人，和祭司都签了名。"},
{volumn:        16,chapter:        10,versenumber:         1,verse:"签名的是：哈迦利亚的儿子、省长尼希米，和西底家；"},
{volumn:        16,chapter:        10,versenumber:         2,verse:"祭司：西莱雅、亚撒利雅、耶利米、"},
{volumn:        16,chapter:        10,versenumber:         3,verse:"巴施户珥、亚玛利雅、玛基雅、"},
{volumn:        16,chapter:        10,versenumber:         4,verse:"哈突、示巴尼、玛鹿、"},
{volumn:        16,chapter:        10,versenumber:         5,verse:"哈琳、米利末、俄巴底亚、"},
{volumn:        16,chapter:        10,versenumber:         6,verse:"但以理、近顿、巴录、"},
{volumn:        16,chapter:        10,versenumber:         7,verse:"米书兰、亚比雅、米雅民、"},
{volumn:        16,chapter:        10,versenumber:         8,verse:"玛西亚、璧该、示玛雅；"},
{volumn:        16,chapter:        10,versenumber:         9,verse:"又有利未人，就是亚散尼的儿子耶书亚、希拿达的子孙宾内、甲篾；"},
{volumn:        16,chapter:        10,versenumber:        10,verse:"还有他们的弟兄示巴尼、荷第雅、基利他、毗莱雅、哈难、"},
{volumn:        16,chapter:        10,versenumber:        11,verse:"米迦、利合、哈沙比雅、"},
{volumn:        16,chapter:        10,versenumber:        12,verse:"撒刻、示利比、示巴尼、"},
{volumn:        16,chapter:        10,versenumber:        13,verse:"荷第雅、巴尼、比尼努；"},
{volumn:        16,chapter:        10,versenumber:        14,verse:"又有民的首领，就是巴录、巴哈·摩押、以拦、萨土、巴尼、"},
{volumn:        16,chapter:        10,versenumber:        15,verse:"布尼、押甲、比拜、"},
{volumn:        16,chapter:        10,versenumber:        16,verse:"亚多尼雅、比革瓦伊、亚丁、"},
{volumn:        16,chapter:        10,versenumber:        17,verse:"亚特、希西家、押朔、"},
{volumn:        16,chapter:        10,versenumber:        18,verse:"荷第雅、哈顺、比赛、"},
{volumn:        16,chapter:        10,versenumber:        19,verse:"哈拉、亚拿突、尼拜、"},
{volumn:        16,chapter:        10,versenumber:        20,verse:"抹比押、米书兰、希悉、"},
{volumn:        16,chapter:        10,versenumber:        21,verse:"米示萨别、撒督、押杜亚、"},
{volumn:        16,chapter:        10,versenumber:        22,verse:"毗拉提、哈难、亚奈雅、"},
{volumn:        16,chapter:        10,versenumber:        23,verse:"何细亚、哈拿尼雅、哈述、"},
{volumn:        16,chapter:        10,versenumber:        24,verse:"哈罗黑、毗利哈、朔百、"},
{volumn:        16,chapter:        10,versenumber:        25,verse:"利宏、哈沙拿、玛西雅、"},
{volumn:        16,chapter:        10,versenumber:        26,verse:"亚希雅、哈难、亚难、"},
{volumn:        16,chapter:        10,versenumber:        27,verse:"玛鹿、哈琳、巴拿。"},
{volumn:        16,chapter:        10,versenumber:        28,verse:"其余的民、祭司、利未人、守门的、歌唱的、尼提宁，和一切离绝邻邦居民归服　神律法的，并他们的妻子、儿女，凡有知识能明白的，"},
{volumn:        16,chapter:        10,versenumber:        29,verse:"都随从他们贵胄的弟兄，发咒起誓，必遵行神藉他仆人摩西所传的律法，谨守遵行耶和华我们主的一切诫命、典章、律例；"},
{volumn:        16,chapter:        10,versenumber:        30,verse:"并不将我们的女儿嫁给这地的居民，也不为我们的儿子娶他们的女儿。"},
{volumn:        16,chapter:        10,versenumber:        31,verse:"这地的居民若在安息日，或什么圣日，带了货物或粮食来卖给我们，我们必不买。每逢第七年必不耕种，凡欠我们债的必不追讨。"},
{volumn:        16,chapter:        10,versenumber:        32,verse:"我们又为自己定例，每年各人捐银一舍客勒三分之一，为我们　神殿的使用，"},
{volumn:        16,chapter:        10,versenumber:        33,verse:"就是为陈设饼、常献的素祭，和燔祭，安息日、月朔、节期所献的与圣物，并以色列人的赎罪祭，以及我们　神殿里一切的费用。"},
{volumn:        16,chapter:        10,versenumber:        34,verse:"我们的祭司、利未人，和百姓都掣签，看每年是哪一族按定期将献祭的柴奉到我们　神的殿里，照着律法上所写的，烧在耶和华我们　神的坛上。"},
{volumn:        16,chapter:        10,versenumber:        35,verse:"又定每年将我们地上初熟的土产和各样树上初熟的果子都奉到耶和华的殿里。"},
{volumn:        16,chapter:        10,versenumber:        36,verse:"又照律法上所写的，将我们头胎的儿子和首生的牛羊都奉到我们　神的殿，交给我们　神殿里供职的祭司；"},
{volumn:        16,chapter:        10,versenumber:        37,verse:"并将初熟之麦子所磨的面和举祭、各样树上初熟的果子、新酒与油奉给祭司，收在我们　神殿的库房里，把我们地上所产的十分之一奉给利未人，因利未人在我们一切城邑的土产中当取十分之一。"},
{volumn:        16,chapter:        10,versenumber:        38,verse:"利未人取十分之一的时候，亚伦的子孙中，当有一个祭司与利未人同在。利未人也当从十分之一中取十分之一，奉到我们　神殿的屋子里，收在库房中。"},
{volumn:        16,chapter:        10,versenumber:        39,verse:"以色列人和利未人要将五谷、新酒，和油为举祭，奉到收存圣所器皿的屋子里，就是供职的祭司、守门的、歌唱的所住的屋子。这样，我们就不离弃我们　神的殿。"},
{volumn:        16,chapter:        11,versenumber:         1,verse:"百姓的首领住在耶路撒冷。其余的百姓掣签，每十人中使一人来住在圣城耶路撒冷，那九人住在别的城邑。"},
{volumn:        16,chapter:        11,versenumber:         2,verse:"凡甘心乐意住在耶路撒冷的，百姓都为他们祝福。"},
{volumn:        16,chapter:        11,versenumber:         3,verse:"以色列人、祭司、利未人、尼提宁，和所罗门仆人的后裔都住在犹大城邑，各在自己的地业中。本省的首领住在耶路撒冷的记在下面："},
{volumn:        16,chapter:        11,versenumber:         4,verse:"其中有些犹大人和便雅悯人。犹大人中有法勒斯的子孙、乌西雅的儿子亚他雅。乌西雅是撒迦利雅的儿子；撒迦利雅是亚玛利雅的儿子；亚玛利雅是示法提雅的儿子；示法提雅是玛勒列的儿子。"},
{volumn:        16,chapter:        11,versenumber:         5,verse:"又有巴录的儿子玛西雅。巴录是谷何西的儿子；谷何西是哈赛雅的儿子；哈赛雅是亚大雅的儿子；亚大雅是约雅立的儿子；约雅立是撒迦利雅的儿子；撒迦利雅是示罗尼的儿子。"},
{volumn:        16,chapter:        11,versenumber:         6,verse:"住在耶路撒冷、法勒斯的子孙共四百六十八名，都是勇士。"},
{volumn:        16,chapter:        11,versenumber:         7,verse:"便雅悯人中有米书兰的儿子撒路。米书兰是约叶的儿子；约叶是毗大雅的儿子；毗大雅是哥赖雅的儿子；哥赖雅是玛西雅的儿子；玛西雅是以铁的儿子；以铁是耶筛亚的儿子。"},
{volumn:        16,chapter:        11,versenumber:         8,verse:"其次有迦拜、撒来的子孙，共九百二十八名。"},
{volumn:        16,chapter:        11,versenumber:         9,verse:"细基利的儿子约珥是他们的长官。哈西努亚的儿子犹大是耶路撒冷的副官。"},
{volumn:        16,chapter:        11,versenumber:        10,verse:"祭司中有雅斤，又有约雅立的儿子耶大雅；"},
{volumn:        16,chapter:        11,versenumber:        11,verse:"还有管理　神殿的西莱雅。西莱雅是希勒家的儿子；希勒家是米书兰的儿子；米书兰是撒督的儿子；撒督是米拉约的儿子；米拉约是亚希突的儿子。"},
{volumn:        16,chapter:        11,versenumber:        12,verse:"还有他们的弟兄在殿里供职的，共八百二十二名；又有耶罗罕的儿子亚大雅。耶罗罕是毗拉利的儿子；毗拉利是暗洗的儿子；暗洗是撒迦利亚的儿子；撒迦利亚是巴施户珥的儿子；巴施户珥是玛基雅的儿子。"},
{volumn:        16,chapter:        11,versenumber:        13,verse:"还有他的弟兄作族长的，二百四十二名；又有亚萨列的儿子亚玛帅。亚萨列是亚哈赛的儿子；亚哈赛是米实利末的儿子；米实利末是音麦的儿子。"},
{volumn:        16,chapter:        11,versenumber:        14,verse:"还有他们弟兄、大能的勇士共一百二十八名。哈基多琳的儿子撒巴第业是他们的长官。"},
{volumn:        16,chapter:        11,versenumber:        15,verse:"利未人中有哈述的儿子示玛雅。哈述是押利甘的儿子；押利甘是哈沙比雅的儿子；哈沙比雅是布尼的儿子。"},
{volumn:        16,chapter:        11,versenumber:        16,verse:"又有利未人的族长沙比太和约撒拔管理　神殿的外事。"},
{volumn:        16,chapter:        11,versenumber:        17,verse:"祈祷的时候，为称谢领首的是米迦的儿子玛他尼。米迦是撒底的儿子；撒底是亚萨的儿子；又有玛他尼弟兄中的八布迦为副。还有沙母亚的儿子押大。沙母亚是加拉的儿子；加拉是耶杜顿的儿子。"},
{volumn:        16,chapter:        11,versenumber:        18,verse:"在圣城的利未人共二百八十四名。"},
{volumn:        16,chapter:        11,versenumber:        19,verse:"守门的是亚谷和达们，并守门的弟兄，共一百七十二名。"},
{volumn:        16,chapter:        11,versenumber:        20,verse:"其余的以色列人、祭司、利未人都住在犹大的一切城邑，各在自己的地业中。"},
{volumn:        16,chapter:        11,versenumber:        21,verse:"尼提宁却住在俄斐勒；西哈和基斯帕管理他们。"},
{volumn:        16,chapter:        11,versenumber:        22,verse:"在耶路撒冷、利未人的长官，管理　神殿事务的是歌唱者亚萨的子孙、巴尼的儿子乌西。巴尼是哈沙比雅的儿子；哈沙比雅是玛他尼的儿子；玛他尼是米迦的儿子。"},
{volumn:        16,chapter:        11,versenumber:        23,verse:"王为歌唱的出命令，每日供给他们必有一定之粮。"},
{volumn:        16,chapter:        11,versenumber:        24,verse:"犹大儿子谢拉的子孙、米示萨别的儿子毗他希雅辅助王办理犹大民的事。"},
{volumn:        16,chapter:        11,versenumber:        25,verse:"至于村庄和属村庄的田地，有犹大人住在基列·亚巴和属基列·亚巴的乡村；底本和属底本的乡村；叶甲薛和属叶甲薛的村庄；"},
{volumn:        16,chapter:        11,versenumber:        26,verse:"耶书亚、摩拉大、伯·帕列、"},
{volumn:        16,chapter:        11,versenumber:        27,verse:"哈萨·书亚、别是巴，和属别是巴的乡村；"},
{volumn:        16,chapter:        11,versenumber:        28,verse:"洗革拉、米哥拿，和属米哥拿的乡村；"},
{volumn:        16,chapter:        11,versenumber:        29,verse:"音·临门、琐拉、耶末、"},
{volumn:        16,chapter:        11,versenumber:        30,verse:"撒挪亚、亚杜兰，和属这两处的村庄；拉吉和属拉吉的田地；亚西加和属亚西加的乡村。他们所住的地方是从别是巴直到欣嫩谷。"},
{volumn:        16,chapter:        11,versenumber:        31,verse:"便雅悯人从迦巴起，住在密抹、亚雅、伯特利和属伯特利的乡村。"},
{volumn:        16,chapter:        11,versenumber:        32,verse:"亚拿突、挪伯、亚难雅、"},
{volumn:        16,chapter:        11,versenumber:        33,verse:"夏琐、拉玛、基他音、"},
{volumn:        16,chapter:        11,versenumber:        34,verse:"哈叠、洗编、尼八拉、"},
{volumn:        16,chapter:        11,versenumber:        35,verse:"罗德、阿挪、匠人之谷。"},
{volumn:        16,chapter:        11,versenumber:        36,verse:"利未人中有几班曾住在犹大地归于便雅悯的。"},
{volumn:        16,chapter:        12,versenumber:         1,verse:"同着撒拉铁的儿子所罗巴伯和耶书亚回来的祭司与利未人记在下面：祭司是西莱雅、耶利米、以斯拉、"},
{volumn:        16,chapter:        12,versenumber:         2,verse:"亚玛利雅、玛鹿、哈突、"},
{volumn:        16,chapter:        12,versenumber:         3,verse:"示迦尼、利宏、米利末、"},
{volumn:        16,chapter:        12,versenumber:         4,verse:"易多、近顿、亚比雅、"},
{volumn:        16,chapter:        12,versenumber:         5,verse:"米雅民、玛底雅、璧迦、"},
{volumn:        16,chapter:        12,versenumber:         6,verse:"示玛雅、约雅立、耶大雅、"},
{volumn:        16,chapter:        12,versenumber:         7,verse:"撒路、亚木、希勒家、耶大雅。这些人在耶书亚的时候作祭司和他们弟兄的首领。"},
{volumn:        16,chapter:        12,versenumber:         8,verse:"利未人是耶书亚、宾内、甲篾、示利比、犹大、玛他尼。这玛他尼和他的弟兄管理称谢的事。"},
{volumn:        16,chapter:        12,versenumber:         9,verse:"他们的弟兄八布迦和乌尼照自己的班次与他们相对。"},
{volumn:        16,chapter:        12,versenumber:        10,verse:"耶书亚生约雅金；约雅金生以利亚实；以利亚实生耶何耶大；"},
{volumn:        16,chapter:        12,versenumber:        11,verse:"耶何耶大生约拿单；约拿单生押杜亚。"},
{volumn:        16,chapter:        12,versenumber:        12,verse:"在约雅金的时候，祭司作族长的西莱雅族（或译：班；本段同）有米拉雅；耶利米族有哈拿尼雅；"},
{volumn:        16,chapter:        12,versenumber:        13,verse:"以斯拉族有米书兰；亚玛利雅族有约哈难；"},
{volumn:        16,chapter:        12,versenumber:        14,verse:"米利古族有约拿单；示巴尼族有约瑟；"},
{volumn:        16,chapter:        12,versenumber:        15,verse:"哈琳族有押拿；米拉约族有希勒恺；"},
{volumn:        16,chapter:        12,versenumber:        16,verse:"易多族有撒迦利亚；近顿族有米书兰；"},
{volumn:        16,chapter:        12,versenumber:        17,verse:"亚比雅族有细基利；米拿民族某；摩亚底族有毗勒太；"},
{volumn:        16,chapter:        12,versenumber:        18,verse:"璧迦族有沙母亚；示玛雅族有约拿单；"},
{volumn:        16,chapter:        12,versenumber:        19,verse:"约雅立族有玛特乃；耶大雅族有乌西；"},
{volumn:        16,chapter:        12,versenumber:        20,verse:"撒来族有加莱；亚木族有希伯；"},
{volumn:        16,chapter:        12,versenumber:        21,verse:"希勒家族有哈沙比雅；耶大雅族有拿坦业。"},
{volumn:        16,chapter:        12,versenumber:        22,verse:"至于利未人，当以利亚实、耶何耶大、约哈难、押杜亚的时候，他们的族长记在册上。波斯王大流士在位的时候，作族长的祭司也记在册上。"},
{volumn:        16,chapter:        12,versenumber:        23,verse:"利未人作族长的记在历史上，直到以利亚实的儿子约哈难的时候。"},
{volumn:        16,chapter:        12,versenumber:        24,verse:"利未人的族长是哈沙比雅、示利比、甲篾的儿子耶书亚，与他们弟兄的班次相对，照着神人大卫的命令一班一班地赞美称谢。"},
{volumn:        16,chapter:        12,versenumber:        25,verse:"玛他尼、八布迦、俄巴底亚、米书兰、达们、亚谷是守门的，就是在库房那里守门。"},
{volumn:        16,chapter:        12,versenumber:        26,verse:"这都是在约撒达的孙子、耶书亚的儿子约雅金和省长尼希米，并祭司文士以斯拉的时候，有职任的。"},
{volumn:        16,chapter:        12,versenumber:        27,verse:"耶路撒冷城墙告成的时候，众民就把各处的利未人招到耶路撒冷，要称谢、歌唱、敲钹、鼓瑟、弹琴，欢欢喜喜地行告成之礼。"},
{volumn:        16,chapter:        12,versenumber:        28,verse:"歌唱的人从耶路撒冷的周围和尼陀法的村庄与伯·吉甲，又从迦巴和押玛弗的田地聚集，因为歌唱的人在耶路撒冷四围为自己立了村庄。"},
{volumn:        16,chapter:        12,versenumber:        29,verse:""},
{volumn:        16,chapter:        12,versenumber:        30,verse:"祭司和利未人就洁净自己，也洁净百姓和城门，并城墙。"},
{volumn:        16,chapter:        12,versenumber:        31,verse:"我带犹大的首领上城，使称谢的人分为两大队，排列而行：第一队在城上往右边向粪厂门行走，"},
{volumn:        16,chapter:        12,versenumber:        32,verse:"在他们后头的有何沙雅与犹大首领的一半，"},
{volumn:        16,chapter:        12,versenumber:        33,verse:"又有亚撒利雅、以斯拉、米书兰、"},
{volumn:        16,chapter:        12,versenumber:        34,verse:"犹大、便雅悯、示玛雅、耶利米。"},
{volumn:        16,chapter:        12,versenumber:        35,verse:"还有些吹号之祭司的子孙，约拿单的儿子撒迦利亚。约拿单是示玛雅的儿子；示玛雅是玛他尼的儿子；玛他尼是米该亚的儿子；米该亚是撒刻的儿子；撒刻是亚萨的儿子；"},
{volumn:        16,chapter:        12,versenumber:        36,verse:"又有撒迦利亚的弟兄示玛雅、亚撒利、米拉莱、基拉莱、玛艾、拿坦业、犹大、哈拿尼，都拿着神人大卫的乐器，文士以斯拉引领他们。"},
{volumn:        16,chapter:        12,versenumber:        37,verse:"他们经过泉门往前，从大卫城的台阶随地势而上，在大卫宫殿以上，直行到朝东的水门。"},
{volumn:        16,chapter:        12,versenumber:        38,verse:"第二队称谢的人要与那一队相迎而行。我和民的一半跟随他们，在城墙上过了炉楼，直到宽墙；"},
{volumn:        16,chapter:        12,versenumber:        39,verse:"又过了以法莲门、古门、鱼门、哈楠业楼、哈米亚楼，直到羊门，就在护卫门站住。"},
{volumn:        16,chapter:        12,versenumber:        40,verse:"于是，这两队称谢的人连我和官长的一半，站在　神的殿里。"},
{volumn:        16,chapter:        12,versenumber:        41,verse:"还有祭司以利亚金、玛西雅、米拿民、米该雅、以利约乃、撒迦利亚、哈楠尼亚吹号；"},
{volumn:        16,chapter:        12,versenumber:        42,verse:"又有玛西雅、示玛雅、以利亚撒、乌西、约哈难、玛基雅、以拦，和以谢奏乐。歌唱的就大声歌唱，伊斯拉希雅管理他们。"},
{volumn:        16,chapter:        12,versenumber:        43,verse:"那日，众人献大祭而欢乐；因为　神使他们大大欢乐，连妇女带孩童也都欢乐，甚至耶路撒冷中的欢声听到远处。"},
{volumn:        16,chapter:        12,versenumber:        44,verse:"当日，派人管理库房，将举祭、初熟之物和所取的十分之一，就是按各城田地，照律法所定归给祭司和利未人的份，都收在里头。犹大人因祭司和利未人供职，就欢乐了。"},
{volumn:        16,chapter:        12,versenumber:        45,verse:"祭司利未人遵守　神所吩咐的，并守洁净的礼。歌唱的、守门的，照着大卫和他儿子所罗门的命令也如此行。"},
{volumn:        16,chapter:        12,versenumber:        46,verse:"古时，在大卫和亚萨的日子，有歌唱的伶长，并有赞美称谢　神的诗歌。"},
{volumn:        16,chapter:        12,versenumber:        47,verse:"当所罗巴伯和尼希米的时候，以色列众人将歌唱的、守门的，每日所当得的份供给他们，又给利未人当得的份；利未人又给亚伦的子孙当得的份。"},
{volumn:        16,chapter:        13,versenumber:         1,verse:"当日，人念摩西的律法书给百姓听，遇见书上写着说，亚扪人和摩押人永不可入　神的会；"},
{volumn:        16,chapter:        13,versenumber:         2,verse:"因为他们没有拿食物和水来迎接以色列人，且雇了巴兰咒诅他们，但我们的　神使那咒诅变为祝福。"},
{volumn:        16,chapter:        13,versenumber:         3,verse:"以色列民听见这律法，就与一切闲杂人绝交。"},
{volumn:        16,chapter:        13,versenumber:         4,verse:"先是蒙派管理我们　神殿中库房的祭司以利亚实与多比雅结亲，"},
{volumn:        16,chapter:        13,versenumber:         5,verse:"便为他预备一间大屋子，就是从前收存素祭、乳香、器皿，和照命令供给利未人、歌唱的、守门的五谷、新酒，和油的十分之一，并归祭司举祭的屋子。"},
{volumn:        16,chapter:        13,versenumber:         6,verse:"那时我不在耶路撒冷；因为巴比伦王亚达薛西三十二年，我回到王那里。过了多日，我向王告假。"},
{volumn:        16,chapter:        13,versenumber:         7,verse:"我来到耶路撒冷，就知道以利亚实为多比雅在　神殿的院内预备屋子的那件恶事。"},
{volumn:        16,chapter:        13,versenumber:         8,verse:"我甚恼怒，就把多比雅的一切家具从屋里都抛出去，"},
{volumn:        16,chapter:        13,versenumber:         9,verse:"吩咐人洁净这屋子，遂将　神殿的器皿和素祭、乳香又搬进去。"},
{volumn:        16,chapter:        13,versenumber:        10,verse:"我见利未人所当得的份无人供给他们，甚至供职的利未人与歌唱的俱各奔回自己的田地去了。"},
{volumn:        16,chapter:        13,versenumber:        11,verse:"我就斥责官长说：“为何离弃　神的殿呢？”我便招聚利未人，使他们照旧供职。"},
{volumn:        16,chapter:        13,versenumber:        12,verse:"犹大众人就把五谷、新酒，和油的十分之一送入库房。"},
{volumn:        16,chapter:        13,versenumber:        13,verse:"我派祭司示利米雅、文士撒督，和利未人毗大雅作库官管理库房；副官是哈难。哈难是撒刻的儿子；撒刻是玛他尼的儿子。这些人都是忠信的，他们的职分是将所供给的分给他们的弟兄。"},
{volumn:        16,chapter:        13,versenumber:        14,verse:"我的　神啊，求你因这事记念我，不要涂抹我为　神的殿与其中的礼节所行的善。"},
{volumn:        16,chapter:        13,versenumber:        15,verse:"那些日子，我在犹大见有人在安息日榨酒（原文是踹酒榨），搬运禾捆驮在驴上，又把酒、葡萄、无花果，和各样的担子在安息日担入耶路撒冷，我就在他们卖食物的那日警戒他们。"},
{volumn:        16,chapter:        13,versenumber:        16,verse:"又有推罗人住在耶路撒冷；他们把鱼和各样货物运进来，在安息日卖给犹大人。"},
{volumn:        16,chapter:        13,versenumber:        17,verse:"我就斥责犹大的贵胄说：“你们怎么行这恶事犯了安息日呢？"},
{volumn:        16,chapter:        13,versenumber:        18,verse:"从前你们列祖岂不是这样行，以致我们　神使一切灾祸临到我们和这城吗？现在你们还犯安息日，使忿怒越发临到以色列！”"},
{volumn:        16,chapter:        13,versenumber:        19,verse:"在安息日的前一日，耶路撒冷城门有黑影的时候，我就吩咐人将门关锁，不过安息日不准开放。我又派我几个仆人管理城门，免得有人在安息日担什么担子进城。"},
{volumn:        16,chapter:        13,versenumber:        20,verse:"于是商人和贩卖各样货物的，一两次住宿在耶路撒冷城外。"},
{volumn:        16,chapter:        13,versenumber:        21,verse:"我就警戒他们说：“你们为何在城外住宿呢？若再这样，我必下手拿办你们。”从此以后，他们在安息日不再来了。"},
{volumn:        16,chapter:        13,versenumber:        22,verse:"我吩咐利未人洁净自己，来守城门，使安息日为圣。我的　神啊，求你因这事记念我，照你的大慈爱怜恤我。"},
{volumn:        16,chapter:        13,versenumber:        23,verse:"那些日子，我也见犹大人娶了亚实突、亚扪、摩押的女子为妻。"},
{volumn:        16,chapter:        13,versenumber:        24,verse:"他们的儿女说话，一半是亚实突的话，不会说犹大的话，所说的是照着各族的方言。"},
{volumn:        16,chapter:        13,versenumber:        25,verse:"我就斥责他们，咒诅他们，打了他们几个人，拔下他们的头发，叫他们指着　神起誓，必不将自己的女儿嫁给外邦人的儿子，也不为自己和儿子娶他们的女儿。"},
{volumn:        16,chapter:        13,versenumber:        26,verse:"我又说：“以色列王所罗门不是在这样的事上犯罪吗？在多国中并没有一王像他，且蒙他　神所爱，　神立他作以色列全国的王；然而连他也被外邦女子引诱犯罪。"},
{volumn:        16,chapter:        13,versenumber:        27,verse:"如此，我岂听你们行这大恶，娶外邦女子干犯我们的　神呢？”"},
{volumn:        16,chapter:        13,versenumber:        28,verse:"大祭司以利亚实的孙子、耶何耶大的一个儿子是和伦人参巴拉的女婿，我就从我这里把他赶出去。"},
{volumn:        16,chapter:        13,versenumber:        29,verse:"我的　神啊，求你记念他们的罪；因为他们玷污了祭司的职任，违背你与祭司利未人所立的约。"},
{volumn:        16,chapter:        13,versenumber:        30,verse:"这样，我洁净他们，使他们离绝一切外邦人，派定祭司和利未人的班次，使他们各尽其职。"},
{volumn:        16,chapter:        13,versenumber:        31,verse:"我又派百姓按定期献柴和初熟的土产。我的　神啊，求你记念我，施恩与我。"},
{volumn:        17,chapter:         1,versenumber:         1,verse:"亚哈随鲁作王，从印度直到古实，统管一百二十七省。"},
{volumn:        17,chapter:         1,versenumber:         2,verse:"亚哈随鲁王在书珊城的宫登基；"},
{volumn:        17,chapter:         1,versenumber:         3,verse:"在位第三年，为他一切首领臣仆设摆筵席，有波斯和玛代的权贵，就是各省的贵胄与首领，在他面前。"},
{volumn:        17,chapter:         1,versenumber:         4,verse:"他把他荣耀之国的丰富和他美好威严的尊贵给他们看了许多日，就是一百八十日。"},
{volumn:        17,chapter:         1,versenumber:         5,verse:"这日子满了，又为所有住书珊城的大小人民在御园的院子里设摆筵席七日。"},
{volumn:        17,chapter:         1,versenumber:         6,verse:"有白色、绿色、蓝色的帐子，用细麻绳、紫色绳从银环内系在白玉石柱上；有金银的床榻摆在红、白、黄、黑玉石的铺石地上。"},
{volumn:        17,chapter:         1,versenumber:         7,verse:"用金器皿赐酒，器皿各有不同。御酒甚多，足显王的厚意。"},
{volumn:        17,chapter:         1,versenumber:         8,verse:"喝酒有例，不准勉强人，因王吩咐宫里的一切臣宰，让人各随己意。"},
{volumn:        17,chapter:         1,versenumber:         9,verse:"王后瓦实提在亚哈随鲁王的宫内也为妇女设摆筵席。"},
{volumn:        17,chapter:         1,versenumber:        10,verse:"第七日，亚哈随鲁王饮酒，心中快乐，就吩咐在他面前侍立的七个太监米户幔、比斯他、哈波拿、比革他、亚拔他、西达、甲迦，"},
{volumn:        17,chapter:         1,versenumber:        11,verse:"请王后瓦实提头戴王后的冠冕到王面前，使各等臣民看她的美貌，因为她容貌甚美。"},
{volumn:        17,chapter:         1,versenumber:        12,verse:"王后瓦实提却不肯遵太监所传的王命而来，所以王甚发怒，心如火烧。"},
{volumn:        17,chapter:         1,versenumber:        13,verse:"那时，在王左右常见王面、国中坐高位的，有波斯和玛代的七个大臣，就是甲示拿、示达、押玛他、他施斯、米力、玛西拿、米母干，都是达时务的明哲人。按王的常规，办事必先询问知例明法的人。王问他们说："},
{volumn:        17,chapter:         1,versenumber:        14,verse:""},
{volumn:        17,chapter:         1,versenumber:        15,verse:"“王后瓦实提不遵太监所传的王命，照例应当怎样办理呢？”"},
{volumn:        17,chapter:         1,versenumber:        16,verse:"米母干在王和众首领面前回答说：“王后瓦实提这事，不但得罪王，并且有害于王各省的臣民；"},
{volumn:        17,chapter:         1,versenumber:        17,verse:"因为王后这事必传到众妇人的耳中，说：‘亚哈随鲁王吩咐王后瓦实提到王面前，她却不来’，她们就藐视自己的丈夫。"},
{volumn:        17,chapter:         1,versenumber:        18,verse:"今日波斯和玛代的众夫人听见王后这事，必向王的大臣照样行；从此必大开藐视和忿怒之端。"},
{volumn:        17,chapter:         1,versenumber:        19,verse:"王若以为美，就降旨写在波斯和玛代人的例中，永不更改，不准瓦实提再到王面前，将她王后的位分赐给比她还好的人。"},
{volumn:        17,chapter:         1,versenumber:        20,verse:"所降的旨意传遍通国（国度本来广大），所有的妇人，无论丈夫贵贱都必尊敬他。”"},
{volumn:        17,chapter:         1,versenumber:        21,verse:"王和众首领都以米母干的话为美，王就照这话去行，"},
{volumn:        17,chapter:         1,versenumber:        22,verse:"发诏书，用各省的文字、各族的方言通知各省，使为丈夫的在家中作主，各说本地的方言。"},
{volumn:        17,chapter:         2,versenumber:         1,verse:"这事以后，亚哈随鲁王的忿怒止息，就想念瓦实提和她所行的，并怎样降旨办她。"},
{volumn:        17,chapter:         2,versenumber:         2,verse:"于是王的侍臣对王说：“不如为王寻找美貌的处女。"},
{volumn:        17,chapter:         2,versenumber:         3,verse:"王可以派官在国中的各省招聚美貌的处女到书珊城（或译：宫）的女院，交给掌管女子的太监希该，给她们当用的香品。"},
{volumn:        17,chapter:         2,versenumber:         4,verse:"王所喜爱的女子可以立为王后，代替瓦实提。”王以这事为美，就如此行。"},
{volumn:        17,chapter:         2,versenumber:         5,verse:"书珊城有一个犹大人，名叫末底改，是便雅悯人基士的曾孙，示每的孙子，睚珥的儿子。"},
{volumn:        17,chapter:         2,versenumber:         6,verse:"从前巴比伦王尼布甲尼撒将犹大王耶哥尼雅（又名约雅斤）和百姓从耶路撒冷掳去，末底改也在其内。"},
{volumn:        17,chapter:         2,versenumber:         7,verse:"末底改抚养他叔叔的女儿哈大沙（后名以斯帖），因为她没有父母。这女子又容貌俊美；她父母死了，末底改就收她为自己的女儿。"},
{volumn:        17,chapter:         2,versenumber:         8,verse:"王的谕旨传出，就招聚许多女子到书珊城，交给掌管女子的希该；以斯帖也送入王宫，交付希该。"},
{volumn:        17,chapter:         2,versenumber:         9,verse:"希该喜悦以斯帖，就恩待她，急忙给她需用的香品和她所当得的份，又派所当得的七个宫女服侍她，使她和她的宫女搬入女院上好的房屋。"},
{volumn:        17,chapter:         2,versenumber:        10,verse:"以斯帖未曾将籍贯宗族告诉人，因为末底改嘱咐她不可叫人知道。"},
{volumn:        17,chapter:         2,versenumber:        11,verse:"末底改天天在女院前边行走，要知道以斯帖平安不平安，并后事如何。"},
{volumn:        17,chapter:         2,versenumber:        12,verse:"众女子照例先洁净身体十二个月：六个月用没药油，六个月用香料和洁身之物。满了日期，然后挨次进去见亚哈随鲁王。"},
{volumn:        17,chapter:         2,versenumber:        13,verse:"女子进去见王是这样：从女院到王宫的时候，凡她所要的都必给她。"},
{volumn:        17,chapter:         2,versenumber:        14,verse:"晚上进去，次日回到女子第二院，交给掌管妃嫔的太监沙甲；除非王喜爱她，再提名召她，就不再进去见王。"},
{volumn:        17,chapter:         2,versenumber:        15,verse:"末底改叔叔亚比孩的女儿，就是末底改收为自己女儿的以斯帖，按次序当进去见王的时候，除了掌管女子的太监希该所派定给她的，她别无所求。凡看见以斯帖的都喜悦她。"},
{volumn:        17,chapter:         2,versenumber:        16,verse:"亚哈随鲁王第七年十月，就是提别月，以斯帖被引入宫见王。"},
{volumn:        17,chapter:         2,versenumber:        17,verse:"王爱以斯帖过于爱众女，她在王眼前蒙宠爱比众处女更甚。王就把王后的冠冕戴在她头上，立她为王后，代替瓦实提。"},
{volumn:        17,chapter:         2,versenumber:        18,verse:"王因以斯帖的缘故给众首领和臣仆设摆大筵席，又豁免各省的租税，并照王的厚意大颁赏赐。"},
{volumn:        17,chapter:         2,versenumber:        19,verse:"第二次招聚处女的时候，末底改坐在朝门。"},
{volumn:        17,chapter:         2,versenumber:        20,verse:"以斯帖照着末底改所嘱咐的，还没有将籍贯宗族告诉人；因为以斯帖遵末底改的命，如抚养她的时候一样。"},
{volumn:        17,chapter:         2,versenumber:        21,verse:"当那时候，末底改坐在朝门，王的太监中有两个守门的，辟探和提列，恼恨亚哈随鲁王，想要下手害他。"},
{volumn:        17,chapter:         2,versenumber:        22,verse:"末底改知道了，就告诉王后以斯帖。以斯帖奉末底改的名，报告于王；"},
{volumn:        17,chapter:         2,versenumber:        23,verse:"究察这事，果然是实，就把二人挂在木头上，将这事在王面前写于历史上。"},
{volumn:        17,chapter:         3,versenumber:         1,verse:"这事以后，亚哈随鲁王抬举亚甲族哈米大他的儿子哈曼，使他高升，叫他的爵位超过与他同事的一切臣宰。"},
{volumn:        17,chapter:         3,versenumber:         2,verse:"在朝门的一切臣仆都跪拜哈曼，因为王如此吩咐；惟独末底改不跪不拜。"},
{volumn:        17,chapter:         3,versenumber:         3,verse:"在朝门的臣仆问末底改说：“你为何违背王的命令呢？”"},
{volumn:        17,chapter:         3,versenumber:         4,verse:"他们天天劝他，他还是不听，他们就告诉哈曼，要看末底改的事站得住站不住，因他已经告诉他们自己是犹大人。"},
{volumn:        17,chapter:         3,versenumber:         5,verse:"哈曼见末底改不跪不拜，他就怒气填胸。"},
{volumn:        17,chapter:         3,versenumber:         6,verse:"他们已将末底改的本族告诉哈曼；他以为下手害末底改一人是小事，就要灭绝亚哈随鲁王通国所有的犹大人，就是末底改的本族。"},
{volumn:        17,chapter:         3,versenumber:         7,verse:"亚哈随鲁王十二年正月，就是尼散月，人在哈曼面前，按日日月月掣普珥，就是掣签，要定何月何日为吉，择定了十二月，就是亚达月。"},
{volumn:        17,chapter:         3,versenumber:         8,verse:"哈曼对亚哈随鲁王说：“有一种民散居在王国各省的民中；他们的律例与万民的律例不同，也不守王的律例，所以容留他们与王无益。"},
{volumn:        17,chapter:         3,versenumber:         9,verse:"王若以为美，请下旨意灭绝他们；我就捐一万他连得银子交给掌管国帑的人，纳入王的府库。”"},
{volumn:        17,chapter:         3,versenumber:        10,verse:"于是王从自己手上摘下戒指给犹大人的仇敌，亚甲族哈米大他的儿子哈曼。"},
{volumn:        17,chapter:         3,versenumber:        11,verse:"王对哈曼说：“这银子仍赐给你，这民也交给你，你可以随意待他们。”"},
{volumn:        17,chapter:         3,versenumber:        12,verse:"正月十三日，就召了王的书记来，照着哈曼一切所吩咐的，用各省的文字、各族的方言，奉亚哈随鲁王的名写旨意，传与总督和各省的省长，并各族的首领；又用王的戒指盖印，"},
{volumn:        17,chapter:         3,versenumber:        13,verse:"交给驿卒传到王的各省，吩咐将犹大人，无论老少妇女孩子，在一日之间，十二月，就是亚达月十三日，全然剪除，杀戮灭绝，并夺他们的财为掠物。"},
{volumn:        17,chapter:         3,versenumber:        14,verse:"抄录这旨意，颁行各省，宣告各族，使他们预备等候那日。"},
{volumn:        17,chapter:         3,versenumber:        15,verse:"驿卒奉王命急忙起行，旨意也传遍书珊城。王同哈曼坐下饮酒，书珊城的民却都慌乱。"},
{volumn:        17,chapter:         4,versenumber:         1,verse:"末底改知道所做的这一切事，就撕裂衣服，穿麻衣，蒙灰尘，在城中行走，痛哭哀号。"},
{volumn:        17,chapter:         4,versenumber:         2,verse:"到了朝门前停住脚步，因为穿麻衣的不可进朝门。"},
{volumn:        17,chapter:         4,versenumber:         3,verse:"王的谕旨所到的各省各处，犹大人大大悲哀，禁食哭泣哀号，穿麻衣躺在灰中的甚多。"},
{volumn:        17,chapter:         4,versenumber:         4,verse:"王后以斯帖的宫女和太监来把这事告诉以斯帖，她甚是忧愁，就送衣服给末底改穿，要他脱下麻衣，他却不受。"},
{volumn:        17,chapter:         4,versenumber:         5,verse:"以斯帖就把王所派伺候她的一个太监，名叫哈他革召来，吩咐他去见末底改，要知道这是什么事，是什么缘故。"},
{volumn:        17,chapter:         4,versenumber:         6,verse:"于是哈他革出到朝门前的宽阔处见末底改。"},
{volumn:        17,chapter:         4,versenumber:         7,verse:"末底改将自己所遇的事，并哈曼为灭绝犹大人应许捐入王库的银数都告诉了他；"},
{volumn:        17,chapter:         4,versenumber:         8,verse:"又将所抄写传遍书珊城要灭绝犹大人的旨意交给哈他革，要给以斯帖看，又要给她说明，并嘱咐她进去见王，为本族的人在王面前恳切祈求。"},
{volumn:        17,chapter:         4,versenumber:         9,verse:"哈他革回来，将末底改的话告诉以斯帖；"},
{volumn:        17,chapter:         4,versenumber:        10,verse:"以斯帖就吩咐哈他革去见末底改，说："},
{volumn:        17,chapter:         4,versenumber:        11,verse:"“王的一切臣仆和各省的人民都知道有一个定例：若不蒙召，擅入内院见王的，无论男女必被治死；除非王向他伸出金杖，不得存活。现在我没有蒙召进去见王已经三十日了。”"},
{volumn:        17,chapter:         4,versenumber:        12,verse:"人就把以斯帖这话告诉末底改。"},
{volumn:        17,chapter:         4,versenumber:        13,verse:"末底改托人回复以斯帖说：“你莫想在王宫里强过一切犹大人，得免这祸。"},
{volumn:        17,chapter:         4,versenumber:        14,verse:"此时你若闭口不言，犹大人必从别处得解脱，蒙拯救；你和你父家必致灭亡。焉知你得了王后的位分不是为现今的机会吗？”"},
{volumn:        17,chapter:         4,versenumber:        15,verse:"以斯帖就吩咐人回报末底改说："},
{volumn:        17,chapter:         4,versenumber:        16,verse:"“你当去招聚书珊城所有的犹大人，为我禁食三昼三夜，不吃不喝；我和我的宫女也要这样禁食。然后我违例进去见王，我若死就死吧！”"},
{volumn:        17,chapter:         4,versenumber:        17,verse:"于是末底改照以斯帖一切所吩咐的去行。"},
{volumn:        17,chapter:         5,versenumber:         1,verse:"第三日，以斯帖穿上朝服，进王宫的内院，对殿站立。王在殿里坐在宝座上，对着殿门。"},
{volumn:        17,chapter:         5,versenumber:         2,verse:"王见王后以斯帖站在院内，就施恩于她，向她伸出手中的金杖；以斯帖便向前摸杖头。"},
{volumn:        17,chapter:         5,versenumber:         3,verse:"王对她说：“王后以斯帖啊，你要什么？你求什么，就是国的一半也必赐给你。”"},
{volumn:        17,chapter:         5,versenumber:         4,verse:"以斯帖说：“王若以为美，就请王带着哈曼今日赴我所预备的筵席。”"},
{volumn:        17,chapter:         5,versenumber:         5,verse:"王说：“叫哈曼速速照以斯帖的话去行。”于是王带着哈曼赴以斯帖所预备的筵席。"},
{volumn:        17,chapter:         5,versenumber:         6,verse:"在酒席筵前，王又问以斯帖说：“你要什么，我必赐给你；你求什么，就是国的一半也必为你成就。”"},
{volumn:        17,chapter:         5,versenumber:         7,verse:"以斯帖回答说：“我有所要，我有所求。"},
{volumn:        17,chapter:         5,versenumber:         8,verse:"我若在王眼前蒙恩，王若愿意赐我所要的，准我所求的，就请王带着哈曼再赴我所要预备的筵席。明日我必照王所问的说明。”"},
{volumn:        17,chapter:         5,versenumber:         9,verse:"那日哈曼心中快乐，欢欢喜喜地出来；但见末底改在朝门不站起来，连身也不动，就满心恼怒末底改。"},
{volumn:        17,chapter:         5,versenumber:        10,verse:"哈曼暂且忍耐回家，叫人请他朋友和他妻子细利斯来。"},
{volumn:        17,chapter:         5,versenumber:        11,verse:"哈曼将他富厚的荣耀、众多的儿女，和王抬举他使他超乎首领臣仆之上，都述说给他们听。"},
{volumn:        17,chapter:         5,versenumber:        12,verse:"哈曼又说：“王后以斯帖预备筵席，除了我之外不许别人随王赴席。明日王后又请我随王赴席；"},
{volumn:        17,chapter:         5,versenumber:        13,verse:"只是我见犹大人末底改坐在朝门，虽有这一切荣耀，也与我无益。”"},
{volumn:        17,chapter:         5,versenumber:        14,verse:"他的妻细利斯和他一切的朋友对他说：“不如立一个五丈高的木架，明早求王将末底改挂在其上，然后你可以欢欢喜喜地随王赴席。”哈曼以这话为美，就叫人做了木架。"},
{volumn:        17,chapter:         6,versenumber:         1,verse:"那夜王睡不着觉，就吩咐人取历史来，念给他听。"},
{volumn:        17,chapter:         6,versenumber:         2,verse:"正遇见书上写着说：王的太监中有两个守门的，辟探和提列，想要下手害亚哈随鲁王，末底改将这事告诉王后。"},
{volumn:        17,chapter:         6,versenumber:         3,verse:"王说：“末底改行了这事，赐他什么尊荣爵位没有？”伺候王的臣仆回答说：“没有赐他什么。”"},
{volumn:        17,chapter:         6,versenumber:         4,verse:"王说：“谁在院子里？”（那时哈曼正进王宫的外院，要求王将末底改挂在他所预备的木架上。）"},
{volumn:        17,chapter:         6,versenumber:         5,verse:"臣仆说：“哈曼站在院内。”王说：“叫他进来。”"},
{volumn:        17,chapter:         6,versenumber:         6,verse:"哈曼就进去。王问他说：“王所喜悦尊荣的人，当如何待他呢？”哈曼心里说：“王所喜悦尊荣的，不是我是谁呢？”"},
{volumn:        17,chapter:         6,versenumber:         7,verse:"哈曼就回答说：“王所喜悦尊荣的，"},
{volumn:        17,chapter:         6,versenumber:         8,verse:"当将王常穿的朝服和戴冠的御马，"},
{volumn:        17,chapter:         6,versenumber:         9,verse:"都交给王极尊贵的一个大臣，命他将衣服给王所喜悦尊荣的人穿上，使他骑上马，走遍城里的街市，在他面前宣告说：王所喜悦尊荣的人，就如此待他。”"},
{volumn:        17,chapter:         6,versenumber:        10,verse:"王对哈曼说：“你速速将这衣服和马，照你所说的，向坐在朝门的犹大人末底改去行。凡你所说的，一样不可缺。”"},
{volumn:        17,chapter:         6,versenumber:        11,verse:"于是哈曼将朝服给末底改穿上，使他骑上马，走遍城里的街市，在他面前宣告说：“王所喜悦尊荣的人，就如此待他。”"},
{volumn:        17,chapter:         6,versenumber:        12,verse:"末底改仍回到朝门，哈曼却忧忧闷闷地蒙着头，急忙回家去了，"},
{volumn:        17,chapter:         6,versenumber:        13,verse:"将所遇的一切事详细说给他的妻细利斯和他的众朋友听。他的智慧人和他的妻细利斯对他说：“你在末底改面前始而败落，他如果是犹大人，你必不能胜他，终必在他面前败落。”"},
{volumn:        17,chapter:         6,versenumber:        14,verse:"他们还与哈曼说话的时候，王的太监来催哈曼快去赴以斯帖所预备的筵席。"},
{volumn:        17,chapter:         7,versenumber:         1,verse:"王带着哈曼来赴王后以斯帖的筵席。"},
{volumn:        17,chapter:         7,versenumber:         2,verse:"这第二次在酒席筵前，王又问以斯帖说：“王后以斯帖啊，你要什么，我必赐给你；你求什么，就是国的一半也必为你成就。”"},
{volumn:        17,chapter:         7,versenumber:         3,verse:"王后以斯帖回答说：“我若在王眼前蒙恩，王若以为美，我所愿的，是愿王将我的性命赐给我；我所求的，是求王将我的本族赐给我。"},
{volumn:        17,chapter:         7,versenumber:         4,verse:"因我和我的本族被卖了，要剪除杀戮灭绝我们。我们若被卖为奴为婢，我也闭口不言；但王的损失，敌人万不能补足。”"},
{volumn:        17,chapter:         7,versenumber:         5,verse:"亚哈随鲁王问王后以斯帖说：“擅敢起意如此行的是谁？这人在哪里呢？”"},
{volumn:        17,chapter:         7,versenumber:         6,verse:"以斯帖说：“仇人敌人就是这恶人哈曼！”哈曼在王和王后面前就甚惊惶。"},
{volumn:        17,chapter:         7,versenumber:         7,verse:"王便大怒，起来离开酒席往御园去了。哈曼见王定意要加罪与他，就起来，求王后以斯帖救命。"},
{volumn:        17,chapter:         7,versenumber:         8,verse:"王从御园回到酒席之处，见哈曼伏在以斯帖所靠的榻上；王说：“他竟敢在宫内、在我面前凌辱王后吗？”这话一出王口，人就蒙了哈曼的脸。"},
{volumn:        17,chapter:         7,versenumber:         9,verse:"伺候王的一个太监名叫哈波拿，说：“哈曼为那救王有功的末底改做了五丈高的木架，现今立在哈曼家里。”王说：“把哈曼挂在其上。”"},
{volumn:        17,chapter:         7,versenumber:        10,verse:"于是人将哈曼挂在他为末底改所预备的木架上。王的忿怒这才止息。"},
{volumn:        17,chapter:         8,versenumber:         1,verse:"当日，亚哈随鲁王把犹大人仇敌哈曼的家产赐给王后以斯帖。末底改也来到王面前，因为以斯帖已经告诉王，末底改是她的亲属。"},
{volumn:        17,chapter:         8,versenumber:         2,verse:"王摘下自己的戒指，就是从哈曼追回的，给了末底改。以斯帖派末底改管理哈曼的家产。"},
{volumn:        17,chapter:         8,versenumber:         3,verse:"以斯帖又俯伏在王脚前，流泪哀告，求他除掉亚甲族哈曼害犹大人的恶谋。"},
{volumn:        17,chapter:         8,versenumber:         4,verse:"王向以斯帖伸出金杖；以斯帖就起来，站在王前，"},
{volumn:        17,chapter:         8,versenumber:         5,verse:"说：“亚甲族哈米大他的儿子哈曼设谋传旨，要杀灭在王各省的犹大人。现今王若愿意，我若在王眼前蒙恩，王若以为美，若喜悦我，请王另下旨意，废除哈曼所传的那旨意。"},
{volumn:        17,chapter:         8,versenumber:         6,verse:"我何忍见我本族的人受害？何忍见我同宗的人被灭呢？”"},
{volumn:        17,chapter:         8,versenumber:         7,verse:"亚哈随鲁王对王后以斯帖和犹大人末底改说：“因哈曼要下手害犹大人，我已将他的家产赐给以斯帖，人也将哈曼挂在木架上。"},
{volumn:        17,chapter:         8,versenumber:         8,verse:"现在你们可以随意奉王的名写谕旨给犹大人，用王的戒指盖印；因为奉王名所写、用王戒指盖印的谕旨，人都不能废除。”"},
{volumn:        17,chapter:         8,versenumber:         9,verse:"三月，就是西弯月二十三日，将王的书记召来，按着末底改所吩咐的，用各省的文字、各族的方言，并犹大人的文字方言写谕旨，传给那从印度直到古实一百二十七省的犹大人和总督省长首领。"},
{volumn:        17,chapter:         8,versenumber:        10,verse:"末底改奉亚哈随鲁王的名写谕旨，用王的戒指盖印，交给骑御马圈快马的驿卒，传到各处。"},
{volumn:        17,chapter:         8,versenumber:        11,verse:"谕旨中，王准各省各城的犹大人在一日之间，十二月，就是亚达月十三日，聚集保护性命，剪除杀戮灭绝那要攻击犹大人的一切仇敌和他们的妻子儿女，夺取他们的财为掠物。"},
{volumn:        17,chapter:         8,versenumber:        12,verse:""},
{volumn:        17,chapter:         8,versenumber:        13,verse:"抄录这谕旨，颁行各省，宣告各族，使犹大人预备等候那日，在仇敌身上报仇。"},
{volumn:        17,chapter:         8,versenumber:        14,verse:"于是骑快马的驿卒被王命催促，急忙起行；谕旨也传遍书珊城。"},
{volumn:        17,chapter:         8,versenumber:        15,verse:"末底改穿着蓝色白色的朝服，头戴大金冠冕，又穿紫色细麻布的外袍，从王面前出来；书珊城的人民都欢呼快乐。"},
{volumn:        17,chapter:         8,versenumber:        16,verse:"犹大人有光荣，欢喜快乐而得尊贵。"},
{volumn:        17,chapter:         8,versenumber:        17,verse:"王的谕旨所到的各省各城，犹大人都欢喜快乐，设摆筵宴，以那日为吉日。那国的人民，有许多因惧怕犹大人，就入了犹大籍。"},
{volumn:        17,chapter:         9,versenumber:         1,verse:"十二月，乃亚达月十三日，王的谕旨将要举行，就是犹大人的仇敌盼望辖制他们的日子，犹大人反倒辖制恨他们的人。"},
{volumn:        17,chapter:         9,versenumber:         2,verse:"犹大人在亚哈随鲁王各省的城里聚集，下手击杀那要害他们的人。无人能敌挡他们，因为各族都惧怕他们。"},
{volumn:        17,chapter:         9,versenumber:         3,verse:"各省的首领、总督、省长，和办理王事的人，因惧怕末底改，就都帮助犹大人。"},
{volumn:        17,chapter:         9,versenumber:         4,verse:"末底改在朝中为大，名声传遍各省，日渐昌盛。"},
{volumn:        17,chapter:         9,versenumber:         5,verse:"犹大人用刀击杀一切仇敌，任意杀灭恨他们的人。"},
{volumn:        17,chapter:         9,versenumber:         6,verse:"在书珊城，犹大人杀灭了五百人；"},
{volumn:        17,chapter:         9,versenumber:         7,verse:"又杀巴珊大他、达分、亚斯帕他、"},
{volumn:        17,chapter:         9,versenumber:         8,verse:"破拉他、亚大利雅、亚利大他、"},
{volumn:        17,chapter:         9,versenumber:         9,verse:"帕玛斯他、亚利赛、亚利代、瓦耶撒他；"},
{volumn:        17,chapter:         9,versenumber:        10,verse:"这十人都是哈米大他的孙子、犹大人仇敌哈曼的儿子。犹大人却没有下手夺取财物。"},
{volumn:        17,chapter:         9,versenumber:        11,verse:"当日，将书珊城被杀的人数呈在王前。"},
{volumn:        17,chapter:         9,versenumber:        12,verse:"王对王后以斯帖说：“犹大人在书珊城杀灭了五百人，又杀了哈曼的十个儿子，在王的各省不知如何呢？现在你要什么，我必赐给你；你还求什么，也必为你成就。”"},
{volumn:        17,chapter:         9,versenumber:        13,verse:"以斯帖说：“王若以为美，求你准书珊的犹大人，明日也照今日的旨意行，并将哈曼十个儿子的尸首挂在木架上。”"},
{volumn:        17,chapter:         9,versenumber:        14,verse:"王便允准如此行。旨意传在书珊，人就把哈曼十个儿子的尸首挂起来了。"},
{volumn:        17,chapter:         9,versenumber:        15,verse:"亚达月十四日，书珊的犹大人又聚集在书珊，杀了三百人，却没有下手夺取财物。"},
{volumn:        17,chapter:         9,versenumber:        16,verse:"在王各省其余的犹大人也都聚集保护性命，杀了恨他们的人七万五千，却没有下手夺取财物。这样，就脱离仇敌，得享平安。"},
{volumn:        17,chapter:         9,versenumber:        17,verse:"亚达月十三日，行了这事；十四日安息，以这日为设筵欢乐的日子。"},
{volumn:        17,chapter:         9,versenumber:        18,verse:"但书珊的犹大人，这十三日、十四日聚集杀戮仇敌；十五日安息，以这日为设筵欢乐的日子。"},
{volumn:        17,chapter:         9,versenumber:        19,verse:"所以住无城墙乡村的犹大人，如今都以亚达月十四日为设筵欢乐的吉日，彼此馈送礼物。"},
{volumn:        17,chapter:         9,versenumber:        20,verse:"末底改记录这事，写信与亚哈随鲁王各省远近所有的犹大人，"},
{volumn:        17,chapter:         9,versenumber:        21,verse:"嘱咐他们每年守亚达月十四、十五两日，"},
{volumn:        17,chapter:         9,versenumber:        22,verse:"以这月的两日为犹大人脱离仇敌得平安、转忧为喜、转悲为乐的吉日。在这两日设筵欢乐，彼此馈送礼物，周济穷人。"},
{volumn:        17,chapter:         9,versenumber:        23,verse:"于是，犹大人按着末底改所写与他们的信，应承照初次所守的守为永例；"},
{volumn:        17,chapter:         9,versenumber:        24,verse:"是因犹大人的仇敌亚甲族哈米大他的儿子哈曼设谋杀害犹大人，掣普珥，就是掣签，为要杀尽灭绝他们；"},
{volumn:        17,chapter:         9,versenumber:        25,verse:"这事报告于王，王便降旨使哈曼谋害犹大人的恶事归到他自己的头上，并吩咐把他和他的众子都挂在木架上。"},
{volumn:        17,chapter:         9,versenumber:        26,verse:"照着普珥的名字，犹大人就称这两日为“普珥日”。他们因这信上的话，又因所看见所遇见的事，"},
{volumn:        17,chapter:         9,versenumber:        27,verse:"就应承自己与后裔，并归附他们的人，每年按时必守这两日，永远不废。"},
{volumn:        17,chapter:         9,versenumber:        28,verse:"各省各城、家家户户、世世代代纪念遵守这两日，使这“普珥日”在犹大人中不可废掉，在他们后裔中也不可忘记。"},
{volumn:        17,chapter:         9,versenumber:        29,verse:"亚比孩的女儿王后以斯帖和犹大人末底改以全权写第二封信，坚嘱犹大人守这“普珥日”，"},
{volumn:        17,chapter:         9,versenumber:        30,verse:"用和平诚实话写信给亚哈随鲁王国中一百二十七省所有的犹大人，"},
{volumn:        17,chapter:         9,versenumber:        31,verse:"劝他们按时守这“普珥日”，禁食呼求，是照犹大人末底改和王后以斯帖所嘱咐的，也照犹大人为自己与后裔所应承的。"},
{volumn:        17,chapter:         9,versenumber:        32,verse:"以斯帖命定守“普珥日”，这事也记录在书上。"},
{volumn:        17,chapter:        10,versenumber:         1,verse:"亚哈随鲁王使旱地和海岛的人民都进贡。"},
{volumn:        17,chapter:        10,versenumber:         2,verse:"他以权柄能力所行的，并他抬举末底改使他高升的事，岂不都写在玛代和波斯王的历史上吗？"},
{volumn:        17,chapter:        10,versenumber:         3,verse:"犹大人末底改作亚哈随鲁王的宰相，在犹大人中为大，得他众弟兄的喜悦，为本族的人求好处，向他们说和平的话。"},
{volumn:        18,chapter:         1,versenumber:         1,verse:"乌斯地有一个人名叫约伯；那人完全正直，敬畏　神，远离恶事。"},
{volumn:        18,chapter:         1,versenumber:         2,verse:"他生了七个儿子，三个女儿。"},
{volumn:        18,chapter:         1,versenumber:         3,verse:"他的家产有七千羊，三千骆驼，五百对牛，五百母驴，并有许多仆婢。这人在东方人中就为至大。"},
{volumn:        18,chapter:         1,versenumber:         4,verse:"他的儿子按着日子各在自己家里设摆筵宴，就打发人去，请了他们的三个姐妹来，与他们一同吃喝。"},
{volumn:        18,chapter:         1,versenumber:         5,verse:"筵宴的日子过了，约伯打发人去叫他们自洁。他清早起来，按着他们众人的数目献燔祭；因为他说：“恐怕我儿子犯了罪，心中弃掉　神。”约伯常常这样行。"},
{volumn:        18,chapter:         1,versenumber:         6,verse:"有一天，　神的众子来侍立在耶和华面前，撒但也来在其中。"},
{volumn:        18,chapter:         1,versenumber:         7,verse:"耶和华问撒但说：“你从哪里来？”撒但回答说：“我从地上走来走去，往返而来。”"},
{volumn:        18,chapter:         1,versenumber:         8,verse:"耶和华问撒但说：“你曾用心察看我的仆人约伯没有？地上再没有人像他完全正直，敬畏　神，远离恶事。”"},
{volumn:        18,chapter:         1,versenumber:         9,verse:"撒但回答耶和华说：“约伯敬畏　神，岂是无故呢？"},
{volumn:        18,chapter:         1,versenumber:        10,verse:"你岂不是四面圈上篱笆围护他和他的家，并他一切所有的吗？他手所做的都蒙你赐福；他的家产也在地上增多。"},
{volumn:        18,chapter:         1,versenumber:        11,verse:"你且伸手毁他一切所有的；他必当面弃掉你。”"},
{volumn:        18,chapter:         1,versenumber:        12,verse:"耶和华对撒但说：“凡他所有的都在你手中；只是不可伸手加害于他。”于是撒但从耶和华面前退去。"},
{volumn:        18,chapter:         1,versenumber:        13,verse:"有一天，约伯的儿女正在他们长兄的家里吃饭喝酒，"},
{volumn:        18,chapter:         1,versenumber:        14,verse:"有报信的来见约伯，说：“牛正耕地，驴在旁边吃草，"},
{volumn:        18,chapter:         1,versenumber:        15,verse:"示巴人忽然闯来，把牲畜掳去，并用刀杀了仆人；惟有我一人逃脱，来报信给你。”"},
{volumn:        18,chapter:         1,versenumber:        16,verse:"他还说话的时候，又有人来说：“　神从天上降下火来，将群羊和仆人都烧灭了；惟有我一人逃脱，来报信给你。”"},
{volumn:        18,chapter:         1,versenumber:        17,verse:"他还说话的时候，又有人来说：“迦勒底人分作三队忽然闯来，把骆驼掳去，并用刀杀了仆人；惟有我一人逃脱，来报信给你。”"},
{volumn:        18,chapter:         1,versenumber:        18,verse:"他还说话的时候，又有人来说：“你的儿女正在他们长兄的家里吃饭喝酒，"},
{volumn:        18,chapter:         1,versenumber:        19,verse:"不料，有狂风从旷野刮来，击打房屋的四角，房屋倒塌在少年人身上，他们就都死了；惟有我一人逃脱，来报信给你。”"},
{volumn:        18,chapter:         1,versenumber:        20,verse:"约伯便起来，撕裂外袍，剃了头，伏在地上下拜，"},
{volumn:        18,chapter:         1,versenumber:        21,verse:"说：“我赤身出于母胎，也必赤身归回；赏赐的是耶和华，收取的也是耶和华。耶和华的名是应当称颂的。”"},
{volumn:        18,chapter:         1,versenumber:        22,verse:"在这一切的事上约伯并不犯罪，也不以　神为愚妄（或译：也不妄评　神）。"},
{volumn:        18,chapter:         2,versenumber:         1,verse:"又有一天，　神的众子来侍立在耶和华面前，撒但也来在其中。"},
{volumn:        18,chapter:         2,versenumber:         2,verse:"耶和华问撒但说：“你从哪里来？”撒但回答说：“我从地上走来走去，往返而来。”"},
{volumn:        18,chapter:         2,versenumber:         3,verse:"耶和华问撒但说：“你曾用心察看我的仆人约伯没有？地上再没有人像他完全正直，敬畏　神，远离恶事。你虽激动我攻击他，无故地毁灭他，他仍然持守他的纯正。”"},
{volumn:        18,chapter:         2,versenumber:         4,verse:"撒但回答耶和华说：“人以皮代皮，情愿舍去一切所有的，保全性命。"},
{volumn:        18,chapter:         2,versenumber:         5,verse:"你且伸手伤他的骨头和他的肉，他必当面弃掉你。”"},
{volumn:        18,chapter:         2,versenumber:         6,verse:"耶和华对撒但说：“他在你手中，只要存留他的性命。”"},
{volumn:        18,chapter:         2,versenumber:         7,verse:"于是撒但从耶和华面前退去，击打约伯，使他从脚掌到头顶长毒疮。"},
{volumn:        18,chapter:         2,versenumber:         8,verse:"约伯就坐在炉灰中，拿瓦片刮身体。"},
{volumn:        18,chapter:         2,versenumber:         9,verse:"他的妻子对他说：“你仍然持守你的纯正吗？你弃掉　神，死了吧！”"},
{volumn:        18,chapter:         2,versenumber:        10,verse:"约伯却对她说：“你说话像愚顽的妇人一样。嗳！难道我们从　神手里得福，不也受祸吗？”在这一切的事上约伯并不以口犯罪。"},
{volumn:        18,chapter:         2,versenumber:        11,verse:"约伯的三个朋友，提幔人以利法、书亚人比勒达、拿玛人琐法，听说有这一切的灾祸临到他身上，各人就从本处约会同来，为他悲伤，安慰他。"},
{volumn:        18,chapter:         2,versenumber:        12,verse:"他们远远地举目观看，认不出他来，就放声大哭。各人撕裂外袍，把尘土向天扬起来，落在自己的头上。"},
{volumn:        18,chapter:         2,versenumber:        13,verse:"他们就同他七天七夜坐在地上，一个人也不向他说句话，因为他极其痛苦。"},
{volumn:        18,chapter:         3,versenumber:         1,verse:"此后，约伯开口咒诅自己的生日，"},
{volumn:        18,chapter:         3,versenumber:         2,verse:"说："},
{volumn:        18,chapter:         3,versenumber:         3,verse:"“愿我生的那日和说怀了男胎的那夜都灭没。"},
{volumn:        18,chapter:         3,versenumber:         4,verse:"愿那日变为黑暗；愿　神不从上面寻找它；愿亮光不照于其上。"},
{volumn:        18,chapter:         3,versenumber:         5,verse:"愿黑暗和死荫索取那日；愿密云停在其上；愿日蚀恐吓它。"},
{volumn:        18,chapter:         3,versenumber:         6,verse:"愿那夜被幽暗夺取，不在年中的日子同乐，也不入月中的数目。"},
{volumn:        18,chapter:         3,versenumber:         7,verse:"愿那夜没有生育，其间也没有欢乐的声音。"},
{volumn:        18,chapter:         3,versenumber:         8,verse:"愿那咒诅日子且能惹动鳄鱼的咒诅那夜。"},
{volumn:        18,chapter:         3,versenumber:         9,verse:"愿那夜黎明的星宿变为黑暗，盼亮却不亮，也不见早晨的光线（原文是眼皮）；"},
{volumn:        18,chapter:         3,versenumber:        10,verse:"因没有把怀我胎的门关闭，也没有将患难对我的眼隐藏。"},
{volumn:        18,chapter:         3,versenumber:        11,verse:"“我为何不出母胎而死？为何不出母腹绝气？"},
{volumn:        18,chapter:         3,versenumber:        12,verse:"为何有膝接收我？为何有奶哺养我？"},
{volumn:        18,chapter:         3,versenumber:        13,verse:"不然，我就早已躺卧安睡，"},
{volumn:        18,chapter:         3,versenumber:        14,verse:"和地上为自己重造荒邱的君王、谋士，"},
{volumn:        18,chapter:         3,versenumber:        15,verse:"或与有金子、将银子装满了房屋的王子一同安息；"},
{volumn:        18,chapter:         3,versenumber:        16,verse:"或像隐而未现、不到期而落的胎，归于无有，如同未见光的婴孩。"},
{volumn:        18,chapter:         3,versenumber:        17,verse:"在那里恶人止息搅扰，困乏人得享安息，"},
{volumn:        18,chapter:         3,versenumber:        18,verse:"被囚的人同得安逸，不听见督工的声音。"},
{volumn:        18,chapter:         3,versenumber:        19,verse:"大小都在那里；奴仆脱离主人的辖制。"},
{volumn:        18,chapter:         3,versenumber:        20,verse:"“受患难的人为何有光赐给他呢？心中愁苦的人为何有生命赐给他呢？"},
{volumn:        18,chapter:         3,versenumber:        21,verse:"他们切望死，却不得死；求死，胜于求隐藏的珍宝。"},
{volumn:        18,chapter:         3,versenumber:        22,verse:"他们寻见坟墓就快乐，极其欢喜。"},
{volumn:        18,chapter:         3,versenumber:        23,verse:"人的道路既然遮隐，　神又把他四面围困，为何有光赐给他呢？"},
{volumn:        18,chapter:         3,versenumber:        24,verse:"我未曾吃饭就发出叹息；我唉哼的声音涌出如水。"},
{volumn:        18,chapter:         3,versenumber:        25,verse:"因我所恐惧的临到我身，我所惧怕的迎我而来。"},
{volumn:        18,chapter:         3,versenumber:        26,verse:"我不得安逸，不得平静，也不得安息，却有患难来到。”"},
{volumn:        18,chapter:         4,versenumber:         1,verse:"提幔人以利法回答说："},
{volumn:        18,chapter:         4,versenumber:         2,verse:"“人若想与你说话，你就厌烦吗？但谁能忍住不说呢？"},
{volumn:        18,chapter:         4,versenumber:         3,verse:"你素来教导许多的人，又坚固软弱的手。"},
{volumn:        18,chapter:         4,versenumber:         4,verse:"你的言语曾扶助那将要跌倒的人；你又使软弱的膝稳固。"},
{volumn:        18,chapter:         4,versenumber:         5,verse:"但现在祸患临到你，你就昏迷，挨近你，你便惊惶。"},
{volumn:        18,chapter:         4,versenumber:         6,verse:"你的倚靠不是在你敬畏　神吗？你的盼望不是在你行事纯正吗？"},
{volumn:        18,chapter:         4,versenumber:         7,verse:"请你追想：无辜的人有谁灭亡？正直的人在何处剪除？"},
{volumn:        18,chapter:         4,versenumber:         8,verse:"按我所见，耕罪孽、种毒害的人都照样收割。"},
{volumn:        18,chapter:         4,versenumber:         9,verse:"神一出气，他们就灭亡；　神一发怒，他们就消没。"},
{volumn:        18,chapter:         4,versenumber:        10,verse:"狮子的吼叫和猛狮的声音尽都止息；少壮狮子的牙齿也都敲掉。"},
{volumn:        18,chapter:         4,versenumber:        11,verse:"老狮子因绝食而死；母狮之子也都离散。"},
{volumn:        18,chapter:         4,versenumber:        12,verse:"“我暗暗地得了默示；我耳朵也听其细微的声音。"},
{volumn:        18,chapter:         4,versenumber:        13,verse:"在思念夜中、异象之间，世人沉睡的时候，"},
{volumn:        18,chapter:         4,versenumber:        14,verse:"恐惧、战兢临到我身，使我百骨打战。"},
{volumn:        18,chapter:         4,versenumber:        15,verse:"有灵从我面前经过，我身上的毫毛直立。"},
{volumn:        18,chapter:         4,versenumber:        16,verse:"那灵停住，我却不能辨其形状；有影像在我眼前。我在静默中听见有声音说："},
{volumn:        18,chapter:         4,versenumber:        17,verse:"‘必死的人岂能比　神公义吗？人岂能比造他的主洁净吗？"},
{volumn:        18,chapter:         4,versenumber:        18,verse:"主不信靠他的臣仆，并且指他的使者为愚昧；"},
{volumn:        18,chapter:         4,versenumber:        19,verse:"何况那住在土房、根基在尘土里、被蠹虫所毁坏的人呢？"},
{volumn:        18,chapter:         4,versenumber:        20,verse:"早晚之间，就被毁灭，永归无有，无人理会。"},
{volumn:        18,chapter:         4,versenumber:        21,verse:"他帐棚的绳索岂不从中抽出来呢？他死，且是无智慧而死。’”"},
{volumn:        18,chapter:         5,versenumber:         1,verse:"“你且呼求，有谁答应你？诸圣者之中，你转向哪一位呢？"},
{volumn:        18,chapter:         5,versenumber:         2,verse:"忿怒害死愚妄人；嫉妒杀死痴迷人。"},
{volumn:        18,chapter:         5,versenumber:         3,verse:"我曾见愚妄人扎下根，但我忽然咒诅他的住处。"},
{volumn:        18,chapter:         5,versenumber:         4,verse:"他的儿女远离稳妥的地步，在城门口被压，并无人搭救。"},
{volumn:        18,chapter:         5,versenumber:         5,verse:"他的庄稼有饥饿的人吃尽了，就是在荆棘里的也抢去了；他的财宝有网罗张口吞灭了。"},
{volumn:        18,chapter:         5,versenumber:         6,verse:"祸患原不是从土中出来；患难也不是从地里发生。"},
{volumn:        18,chapter:         5,versenumber:         7,verse:"人生在世必遇患难，如同火星飞腾。"},
{volumn:        18,chapter:         5,versenumber:         8,verse:"“至于我，我必仰望　神，把我的事情托付他。"},
{volumn:        18,chapter:         5,versenumber:         9,verse:"他行大事不可测度，行奇事不可胜数："},
{volumn:        18,chapter:         5,versenumber:        10,verse:"降雨在地上，赐水于田里；"},
{volumn:        18,chapter:         5,versenumber:        11,verse:"将卑微的安置在高处，将哀痛的举到稳妥之地；"},
{volumn:        18,chapter:         5,versenumber:        12,verse:"破坏狡猾人的计谋，使他们所谋的不得成就。"},
{volumn:        18,chapter:         5,versenumber:        13,verse:"他叫有智慧的中了自己的诡计，使狡诈人的计谋速速灭亡。"},
{volumn:        18,chapter:         5,versenumber:        14,verse:"他们白昼遇见黑暗，午间摸索如在夜间。"},
{volumn:        18,chapter:         5,versenumber:        15,verse:"神拯救穷乏人脱离他们口中的刀和强暴人的手。"},
{volumn:        18,chapter:         5,versenumber:        16,verse:"这样，贫寒的人有指望，罪孽之辈必塞口无言。"},
{volumn:        18,chapter:         5,versenumber:        17,verse:"“　神所惩治的人是有福的！所以你不可轻看全能者的管教。"},
{volumn:        18,chapter:         5,versenumber:        18,verse:"因为他打破，又缠裹；他击伤，用手医治。"},
{volumn:        18,chapter:         5,versenumber:        19,verse:"你六次遭难，他必救你；就是七次，灾祸也无法害你。"},
{volumn:        18,chapter:         5,versenumber:        20,verse:"在饥荒中，他必救你脱离死亡；在争战中，他必救你脱离刀剑的权力。"},
{volumn:        18,chapter:         5,versenumber:        21,verse:"你必被隐藏，不受口舌之害；灾殃临到，你也不惧怕。"},
{volumn:        18,chapter:         5,versenumber:        22,verse:"你遇见灾害饥馑，就必嬉笑；地上的野兽，你也不惧怕。"},
{volumn:        18,chapter:         5,versenumber:        23,verse:"因为你必与田间的石头立约；田里的野兽也必与你和好。"},
{volumn:        18,chapter:         5,versenumber:        24,verse:"你必知道你帐棚平安，要查看你的羊圈，一无所失；"},
{volumn:        18,chapter:         5,versenumber:        25,verse:"也必知道你的后裔将来发达，你的子孙像地上的青草。"},
{volumn:        18,chapter:         5,versenumber:        26,verse:"你必寿高年迈才归坟墓，好像禾捆到时收藏。"},
{volumn:        18,chapter:         5,versenumber:        27,verse:"这理，我们已经考察，本是如此。你须要听，要知道是与自己有益。”"},
{volumn:        18,chapter:         6,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:         6,versenumber:         2,verse:"“惟愿我的烦恼称一称，我一切的灾害放在天平里；"},
{volumn:        18,chapter:         6,versenumber:         3,verse:"现今都比海沙更重，所以我的言语急躁。"},
{volumn:        18,chapter:         6,versenumber:         4,verse:"因全能者的箭射入我身；其毒，我的灵喝尽了；　神的惊吓摆阵攻击我。"},
{volumn:        18,chapter:         6,versenumber:         5,verse:"野驴有草岂能叫唤？牛有料岂能吼叫？"},
{volumn:        18,chapter:         6,versenumber:         6,verse:"物淡而无盐岂可吃吗？蛋青有什么滋味呢？"},
{volumn:        18,chapter:         6,versenumber:         7,verse:"看为可厌的食物，我心不肯挨近。"},
{volumn:        18,chapter:         6,versenumber:         8,verse:"“惟愿我得着所求的，愿　神赐我所切望的；"},
{volumn:        18,chapter:         6,versenumber:         9,verse:"就是愿　神把我压碎，伸手将我剪除。"},
{volumn:        18,chapter:         6,versenumber:        10,verse:"我因没有违弃那圣者的言语，就仍以此为安慰，在不止息的痛苦中还可踊跃。"},
{volumn:        18,chapter:         6,versenumber:        11,verse:"我有什么气力使我等候？我有什么结局使我忍耐？"},
{volumn:        18,chapter:         6,versenumber:        12,verse:"我的气力岂是石头的气力？我的肉身岂是铜的呢？"},
{volumn:        18,chapter:         6,versenumber:        13,verse:"在我岂不是毫无帮助吗？智慧岂不是从我心中赶出净尽吗？"},
{volumn:        18,chapter:         6,versenumber:        14,verse:"“那将要灰心、离弃全能者、不敬畏　神的人，他的朋友当以慈爱待他。"},
{volumn:        18,chapter:         6,versenumber:        15,verse:"我的弟兄诡诈，好像溪水，又像溪水流干的河道。"},
{volumn:        18,chapter:         6,versenumber:        16,verse:"这河因结冰发黑，有雪藏在其中；"},
{volumn:        18,chapter:         6,versenumber:        17,verse:"天气渐暖就随时消化，日头炎热便从原处干涸。"},
{volumn:        18,chapter:         6,versenumber:        18,verse:"结伴的客旅离弃大道，顺河偏行，到荒野之地死亡。"},
{volumn:        18,chapter:         6,versenumber:        19,verse:"提玛结伴的客旅瞻望；示巴同伙的人等候。"},
{volumn:        18,chapter:         6,versenumber:        20,verse:"他们因失了盼望就抱愧，来到那里便蒙羞。"},
{volumn:        18,chapter:         6,versenumber:        21,verse:"现在你们正是这样，看见惊吓的事便惧怕。"},
{volumn:        18,chapter:         6,versenumber:        22,verse:"我岂说：请你们供给我，从你们的财物中送礼物给我？"},
{volumn:        18,chapter:         6,versenumber:        23,verse:"岂说：拯救我脱离敌人的手吗？救赎我脱离强暴人的手吗？"},
{volumn:        18,chapter:         6,versenumber:        24,verse:"“请你们教导我，我便不作声；使我明白在何事上有错。"},
{volumn:        18,chapter:         6,versenumber:        25,verse:"正直的言语力量何其大！但你们责备是责备什么呢？"},
{volumn:        18,chapter:         6,versenumber:        26,verse:"绝望人的讲论既然如风，你们还想要驳正言语吗？"},
{volumn:        18,chapter:         6,versenumber:        27,verse:"你们想为孤儿拈阄，以朋友当货物。"},
{volumn:        18,chapter:         6,versenumber:        28,verse:"“现在请你们看看我，我决不当面说谎。"},
{volumn:        18,chapter:         6,versenumber:        29,verse:"请你们转意，不要不公；请再转意，我的事有理。"},
{volumn:        18,chapter:         6,versenumber:        30,verse:"我的舌上岂有不义吗？我的口里岂不辨奸恶吗？”"},
{volumn:        18,chapter:         7,versenumber:         1,verse:"“人在世上岂无争战吗？他的日子不像雇工人的日子吗？"},
{volumn:        18,chapter:         7,versenumber:         2,verse:"像奴仆切慕黑影，像雇工人盼望工价；"},
{volumn:        18,chapter:         7,versenumber:         3,verse:"我也照样经过困苦的日月，夜间的疲乏为我而定。"},
{volumn:        18,chapter:         7,versenumber:         4,verse:"我躺卧的时候便说：我何时起来，黑夜就过去呢？我尽是反来复去，直到天亮。"},
{volumn:        18,chapter:         7,versenumber:         5,verse:"我的肉体以虫子和尘土为衣；我的皮肤才收了口又重新破裂。"},
{volumn:        18,chapter:         7,versenumber:         6,verse:"我的日子比梭更快，都消耗在无指望之中。"},
{volumn:        18,chapter:         7,versenumber:         7,verse:"“求你想念，我的生命不过是一口气；我的眼睛必不再见福乐。"},
{volumn:        18,chapter:         7,versenumber:         8,verse:"观看我的人，他的眼必不再见我；你的眼目要看我，我却不在了。"},
{volumn:        18,chapter:         7,versenumber:         9,verse:"云彩消散而过；照样，人下阴间也不再上来。"},
{volumn:        18,chapter:         7,versenumber:        10,verse:"他不再回自己的家；故土也不再认识他。"},
{volumn:        18,chapter:         7,versenumber:        11,verse:"“我不禁止我口；我灵愁苦，要发出言语；我心苦恼，要吐露哀情。"},
{volumn:        18,chapter:         7,versenumber:        12,verse:"我对　神说：我岂是洋海，岂是大鱼，你竟防守我呢？"},
{volumn:        18,chapter:         7,versenumber:        13,verse:"若说：我的床必安慰我，我的榻必解释我的苦情，"},
{volumn:        18,chapter:         7,versenumber:        14,verse:"你就用梦惊骇我，用异象恐吓我，"},
{volumn:        18,chapter:         7,versenumber:        15,verse:"甚至我宁肯噎死，宁肯死亡，胜似留我这一身的骨头。"},
{volumn:        18,chapter:         7,versenumber:        16,verse:"我厌弃性命，不愿永活。你任凭我吧，因我的日子都是虚空。"},
{volumn:        18,chapter:         7,versenumber:        17,verse:"人算什么，你竟看他为大，将他放在心上？"},
{volumn:        18,chapter:         7,versenumber:        18,verse:"每早鉴察他，时刻试验他？"},
{volumn:        18,chapter:         7,versenumber:        19,verse:"你到何时才转眼不看我，才任凭我咽下唾沫呢？"},
{volumn:        18,chapter:         7,versenumber:        20,verse:"鉴察人的主啊，我若有罪，于你何妨？为何以我当你的箭靶子，使我厌弃自己的性命？"},
{volumn:        18,chapter:         7,versenumber:        21,verse:"为何不赦免我的过犯，除掉我的罪孽？我现今要躺卧在尘土中；你要殷勤地寻找我，我却不在了。”"},
{volumn:        18,chapter:         8,versenumber:         1,verse:"书亚人比勒达回答说："},
{volumn:        18,chapter:         8,versenumber:         2,verse:"“这些话你要说到几时？口中的言语如狂风要到几时呢？"},
{volumn:        18,chapter:         8,versenumber:         3,verse:"神岂能偏离公平？全能者岂能偏离公义？"},
{volumn:        18,chapter:         8,versenumber:         4,verse:"或者你的儿女得罪了他；他使他们受报应。"},
{volumn:        18,chapter:         8,versenumber:         5,verse:"你若殷勤地寻求　神，向全能者恳求；"},
{volumn:        18,chapter:         8,versenumber:         6,verse:"你若清洁正直，他必定为你起来，使你公义的居所兴旺。"},
{volumn:        18,chapter:         8,versenumber:         7,verse:"你起初虽然微小，终久必甚发达。"},
{volumn:        18,chapter:         8,versenumber:         8,verse:"“请你考问前代，追念他们的列祖所查究的。"},
{volumn:        18,chapter:         8,versenumber:         9,verse:"我们不过从昨日才有，一无所知；我们在世的日子好像影儿。"},
{volumn:        18,chapter:         8,versenumber:        10,verse:"他们岂不指教你，告诉你，从心里发出言语来呢？"},
{volumn:        18,chapter:         8,versenumber:        11,verse:"“蒲草没有泥岂能发长？芦荻没有水岂能生发？"},
{volumn:        18,chapter:         8,versenumber:        12,verse:"尚青的时候，还没有割下，比百样的草先枯槁。"},
{volumn:        18,chapter:         8,versenumber:        13,verse:"凡忘记　神的人，景况也是这样；不虔敬人的指望要灭没。"},
{volumn:        18,chapter:         8,versenumber:        14,verse:"他所仰赖的必折断；他所倚靠的是蜘蛛网。"},
{volumn:        18,chapter:         8,versenumber:        15,verse:"他要倚靠房屋，房屋却站立不住；他要抓住房屋，房屋却不能存留。"},
{volumn:        18,chapter:         8,versenumber:        16,verse:"他在日光之下发青，蔓子爬满了园子；"},
{volumn:        18,chapter:         8,versenumber:        17,verse:"他的根盘绕石堆，扎入石地。"},
{volumn:        18,chapter:         8,versenumber:        18,verse:"他若从本地被拔出，那地就不认识他，说：我没有见过你。"},
{volumn:        18,chapter:         8,versenumber:        19,verse:"看哪，这就是他道中之乐；以后必另有人从地而生。"},
{volumn:        18,chapter:         8,versenumber:        20,verse:"神必不丢弃完全人，也不扶助邪恶人。"},
{volumn:        18,chapter:         8,versenumber:        21,verse:"他还要以喜笑充满你的口，以欢呼充满你的嘴。"},
{volumn:        18,chapter:         8,versenumber:        22,verse:"恨恶你的要披戴惭愧；恶人的帐棚必归于无有。”"},
{volumn:        18,chapter:         9,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:         9,versenumber:         2,verse:"“我真知道是这样；但人在　神面前怎能成为义呢？"},
{volumn:        18,chapter:         9,versenumber:         3,verse:"若愿意与他争辩，千中之一也不能回答。"},
{volumn:        18,chapter:         9,versenumber:         4,verse:"他心里有智慧，且大有能力。谁向　神刚硬而得亨通呢？"},
{volumn:        18,chapter:         9,versenumber:         5,verse:"他发怒，把山翻倒挪移，山并不知觉。"},
{volumn:        18,chapter:         9,versenumber:         6,verse:"他使地震动，离其本位，地的柱子就摇撼。"},
{volumn:        18,chapter:         9,versenumber:         7,verse:"他吩咐日头不出来，就不出来，又封闭众星。"},
{volumn:        18,chapter:         9,versenumber:         8,verse:"他独自铺张苍天，步行在海浪之上。"},
{volumn:        18,chapter:         9,versenumber:         9,verse:"他造北斗、参星、昴星，并南方的密宫；"},
{volumn:        18,chapter:         9,versenumber:        10,verse:"他行大事，不可测度，行奇事，不可胜数。"},
{volumn:        18,chapter:         9,versenumber:        11,verse:"他从我旁边经过，我却不看见；他在我面前行走，我倒不知觉。"},
{volumn:        18,chapter:         9,versenumber:        12,verse:"他夺取，谁能阻挡？谁敢问他：你做什么？"},
{volumn:        18,chapter:         9,versenumber:        13,verse:"“　神必不收回他的怒气；扶助拉哈伯的，屈身在他以下。"},
{volumn:        18,chapter:         9,versenumber:        14,verse:"既是这样，我怎敢回答他，怎敢选择言语与他辩论呢？"},
{volumn:        18,chapter:         9,versenumber:        15,verse:"我虽有义，也不回答他，只要向那审判我的恳求。"},
{volumn:        18,chapter:         9,versenumber:        16,verse:"我若呼吁，他应允我；我仍不信他真听我的声音。"},
{volumn:        18,chapter:         9,versenumber:        17,verse:"他用暴风折断我，无故地加增我的损伤。"},
{volumn:        18,chapter:         9,versenumber:        18,verse:"我就是喘一口气，他都不容，倒使我满心苦恼。"},
{volumn:        18,chapter:         9,versenumber:        19,verse:"若论力量，他真有能力！若论审判，他说谁能将我传来呢？"},
{volumn:        18,chapter:         9,versenumber:        20,verse:"我虽有义，自己的口要定我为有罪；我虽完全，我口必显我为弯曲。"},
{volumn:        18,chapter:         9,versenumber:        21,verse:"我本完全，不顾自己；我厌恶我的性命。"},
{volumn:        18,chapter:         9,versenumber:        22,verse:"善恶无分，都是一样；所以我说，完全人和恶人，他都灭绝。"},
{volumn:        18,chapter:         9,versenumber:        23,verse:"若忽然遭杀害之祸，他必戏笑无辜的人遇难。"},
{volumn:        18,chapter:         9,versenumber:        24,verse:"世界交在恶人手中；蒙蔽世界审判官的脸，若不是他，是谁呢？"},
{volumn:        18,chapter:         9,versenumber:        25,verse:"“我的日子比跑信的更快，急速过去，不见福乐。"},
{volumn:        18,chapter:         9,versenumber:        26,verse:"我的日子过去如快船，如急落抓食的鹰。"},
{volumn:        18,chapter:         9,versenumber:        27,verse:"我若说：我要忘记我的哀情，除去我的愁容，心中畅快；"},
{volumn:        18,chapter:         9,versenumber:        28,verse:"我因愁苦而惧怕，知道你必不以我为无辜。"},
{volumn:        18,chapter:         9,versenumber:        29,verse:"我必被你定为有罪，我何必徒然劳苦呢？"},
{volumn:        18,chapter:         9,versenumber:        30,verse:"我若用雪水洗身，用碱洁净我的手，"},
{volumn:        18,chapter:         9,versenumber:        31,verse:"你还要扔我在坑里，我的衣服都憎恶我。"},
{volumn:        18,chapter:         9,versenumber:        32,verse:"他本不像我是人，使我可以回答他，又使我们可以同听审判。"},
{volumn:        18,chapter:         9,versenumber:        33,verse:"我们中间没有听讼的人可以向我们两造按手。"},
{volumn:        18,chapter:         9,versenumber:        34,verse:"愿他把杖离开我，不使惊惶威吓我。"},
{volumn:        18,chapter:         9,versenumber:        35,verse:"我就说话，也不惧怕他，现在我却不是那样。”"},
{volumn:        18,chapter:        10,versenumber:         1,verse:"“我厌烦我的性命，必由着自己述说我的哀情；因心里苦恼，我要说话，"},
{volumn:        18,chapter:        10,versenumber:         2,verse:"对　神说：不要定我有罪，要指示我，你为何与我争辩？"},
{volumn:        18,chapter:        10,versenumber:         3,verse:"你手所造的，你又欺压，又藐视，却光照恶人的计谋。这事你以为美吗？"},
{volumn:        18,chapter:        10,versenumber:         4,verse:"你的眼岂是肉眼？你查看岂像人查看吗？"},
{volumn:        18,chapter:        10,versenumber:         5,verse:"你的日子岂像人的日子，你的年岁岂像人的年岁，"},
{volumn:        18,chapter:        10,versenumber:         6,verse:"就追问我的罪孽，寻察我的罪过吗？"},
{volumn:        18,chapter:        10,versenumber:         7,verse:"其实，你知道我没有罪恶，并没有能救我脱离你手的。"},
{volumn:        18,chapter:        10,versenumber:         8,verse:"你的手创造我，造就我的四肢百体，你还要毁灭我。"},
{volumn:        18,chapter:        10,versenumber:         9,verse:"求你记念，制造我如抟泥一般，你还要使我归于尘土吗？"},
{volumn:        18,chapter:        10,versenumber:        10,verse:"你不是倒出我来好像奶，使我凝结如同奶饼吗？"},
{volumn:        18,chapter:        10,versenumber:        11,verse:"你以皮和肉为衣给我穿上，用骨与筋把我全体联络。"},
{volumn:        18,chapter:        10,versenumber:        12,verse:"你将生命和慈爱赐给我；你也眷顾保全我的心灵。"},
{volumn:        18,chapter:        10,versenumber:        13,verse:"然而，你待我的这些事早已藏在你心里；我知道你久有此意。"},
{volumn:        18,chapter:        10,versenumber:        14,verse:"我若犯罪，你就察看我，并不赦免我的罪孽。"},
{volumn:        18,chapter:        10,versenumber:        15,verse:"我若行恶，便有了祸；我若为义，也不敢抬头，正是满心羞愧，眼见我的苦情。"},
{volumn:        18,chapter:        10,versenumber:        16,verse:"我若昂首自得，你就追捕我如狮子，又在我身上显出奇能。"},
{volumn:        18,chapter:        10,versenumber:        17,verse:"你重立见证攻击我，向我加增恼怒，如军兵更换着攻击我。"},
{volumn:        18,chapter:        10,versenumber:        18,verse:"“你为何使我出母胎呢？不如我当时气绝，无人得见我；"},
{volumn:        18,chapter:        10,versenumber:        19,verse:"这样，就如没有我一般，一出母胎就被送入坟墓。"},
{volumn:        18,chapter:        10,versenumber:        20,verse:"我的日子不是甚少吗？求你停手宽容我，叫我在往而不返之先，就是往黑暗和死荫之地以先，可以稍得畅快。"},
{volumn:        18,chapter:        10,versenumber:        21,verse:""},
{volumn:        18,chapter:        10,versenumber:        22,verse:"那地甚是幽暗，是死荫混沌之地；那里的光好像幽暗。”"},
{volumn:        18,chapter:        11,versenumber:         1,verse:"拿玛人琐法回答说："},
{volumn:        18,chapter:        11,versenumber:         2,verse:"“这许多的言语岂不该回答吗？多嘴多舌的人岂可称为义吗？"},
{volumn:        18,chapter:        11,versenumber:         3,verse:"你夸大的话岂能使人不作声吗？你戏笑的时候岂没有人叫你害羞吗？"},
{volumn:        18,chapter:        11,versenumber:         4,verse:"你说：‘我的道理纯全；我在你眼前洁净。’"},
{volumn:        18,chapter:        11,versenumber:         5,verse:"惟愿　神说话；愿他开口攻击你，"},
{volumn:        18,chapter:        11,versenumber:         6,verse:"并将智慧的奥秘指示你；他有诸般的智识。所以当知道　神追讨你比你罪孽该得的还少。"},
{volumn:        18,chapter:        11,versenumber:         7,verse:"你考察就能测透　神吗？你岂能尽情测透全能者吗？"},
{volumn:        18,chapter:        11,versenumber:         8,verse:"他的智慧高于天，你还能做什么？深于阴间，你还能知道什么？"},
{volumn:        18,chapter:        11,versenumber:         9,verse:"其量比地长，比海宽。"},
{volumn:        18,chapter:        11,versenumber:        10,verse:"他若经过，将人拘禁，招人受审，谁能阻挡他呢？"},
{volumn:        18,chapter:        11,versenumber:        11,verse:"他本知道虚妄的人；人的罪孽，他虽不留意，还是无所不见。"},
{volumn:        18,chapter:        11,versenumber:        12,verse:"空虚的人却毫无知识；人生在世好像野驴的驹子。"},
{volumn:        18,chapter:        11,versenumber:        13,verse:"“你若将心安正，又向主举手；"},
{volumn:        18,chapter:        11,versenumber:        14,verse:"你手里若有罪孽，就当远远地除掉，也不容非义住在你帐棚之中。"},
{volumn:        18,chapter:        11,versenumber:        15,verse:"那时，你必仰起脸来毫无斑点；你也必坚固，无所惧怕。"},
{volumn:        18,chapter:        11,versenumber:        16,verse:"你必忘记你的苦楚，就是想起也如流过去的水一样。"},
{volumn:        18,chapter:        11,versenumber:        17,verse:"你在世的日子要比正午更明，虽有黑暗仍像早晨。"},
{volumn:        18,chapter:        11,versenumber:        18,verse:"你因有指望就必稳固，也必四围巡查，坦然安息。"},
{volumn:        18,chapter:        11,versenumber:        19,verse:"你躺卧，无人惊吓，且有许多人向你求恩。"},
{volumn:        18,chapter:        11,versenumber:        20,verse:"但恶人的眼目必要失明。他们无路可逃；他们的指望就是气绝。”"},
{volumn:        18,chapter:        12,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:        12,versenumber:         2,verse:"“你们真是子民哪，你们死亡，智慧也就灭没了。"},
{volumn:        18,chapter:        12,versenumber:         3,verse:"但我也有聪明，与你们一样，并非不及你们。你们所说的，谁不知道呢？"},
{volumn:        18,chapter:        12,versenumber:         4,verse:"我这求告　神、蒙他应允的人竟成了朋友所讥笑的；公义完全人竟受了人的讥笑。"},
{volumn:        18,chapter:        12,versenumber:         5,verse:"安逸的人心里藐视灾祸；这灾祸常常等待滑脚的人。"},
{volumn:        18,chapter:        12,versenumber:         6,verse:"强盗的帐棚兴旺，惹　神的人稳固，　神多将财物送到他们手中。"},
{volumn:        18,chapter:        12,versenumber:         7,verse:"你且问走兽，走兽必指教你；又问空中的飞鸟，飞鸟必告诉你；"},
{volumn:        18,chapter:        12,versenumber:         8,verse:"或与地说话，地必指教你；海中的鱼也必向你说明。"},
{volumn:        18,chapter:        12,versenumber:         9,verse:"看这一切，谁不知道是耶和华的手做成的呢？"},
{volumn:        18,chapter:        12,versenumber:        10,verse:"凡活物的生命和人类的气息都在他手中。"},
{volumn:        18,chapter:        12,versenumber:        11,verse:"耳朵岂不试验言语，正如上膛尝食物吗？"},
{volumn:        18,chapter:        12,versenumber:        12,verse:"年老的有智慧；寿高的有知识。"},
{volumn:        18,chapter:        12,versenumber:        13,verse:"“在　神有智慧和能力；他有谋略和知识。"},
{volumn:        18,chapter:        12,versenumber:        14,verse:"他拆毁的，就不能再建造；他捆住人，便不得开释。"},
{volumn:        18,chapter:        12,versenumber:        15,verse:"他把水留住，水便枯干；他再发出水来，水就翻地。"},
{volumn:        18,chapter:        12,versenumber:        16,verse:"在他有能力和智慧，被诱惑的与诱惑人的都是属他。"},
{volumn:        18,chapter:        12,versenumber:        17,verse:"他把谋士剥衣掳去，又使审判官变成愚人。"},
{volumn:        18,chapter:        12,versenumber:        18,verse:"他放松君王的绑，又用带子捆他们的腰。"},
{volumn:        18,chapter:        12,versenumber:        19,verse:"他把祭司剥衣掳去，又使有能的人倾败。"},
{volumn:        18,chapter:        12,versenumber:        20,verse:"他废去忠信人的讲论，又夺去老人的聪明。"},
{volumn:        18,chapter:        12,versenumber:        21,verse:"他使君王蒙羞被辱，放松有力之人的腰带。"},
{volumn:        18,chapter:        12,versenumber:        22,verse:"他将深奥的事从黑暗中彰显，使死荫显为光明。"},
{volumn:        18,chapter:        12,versenumber:        23,verse:"他使邦国兴旺而又毁灭；他使邦国开广而又掳去。"},
{volumn:        18,chapter:        12,versenumber:        24,verse:"他将地上民中首领的聪明夺去，使他们在荒废无路之地漂流；"},
{volumn:        18,chapter:        12,versenumber:        25,verse:"他们无光，在黑暗中摸索，又使他们东倒西歪，像醉酒的人一样。”"},
{volumn:        18,chapter:        13,versenumber:         1,verse:"“这一切，我眼都见过；我耳都听过，而且明白。"},
{volumn:        18,chapter:        13,versenumber:         2,verse:"你们所知道的，我也知道，并非不及你们。"},
{volumn:        18,chapter:        13,versenumber:         3,verse:"我真要对全能者说话；我愿与　神理论。"},
{volumn:        18,chapter:        13,versenumber:         4,verse:"你们是编造谎言的，都是无用的医生。"},
{volumn:        18,chapter:        13,versenumber:         5,verse:"惟愿你们全然不作声；这就算为你们的智慧！"},
{volumn:        18,chapter:        13,versenumber:         6,verse:"请你们听我的辩论，留心听我口中的分诉。"},
{volumn:        18,chapter:        13,versenumber:         7,verse:"你们要为　神说不义的话吗？为他说诡诈的言语吗？"},
{volumn:        18,chapter:        13,versenumber:         8,verse:"你们要为　神徇情吗？要为他争论吗？"},
{volumn:        18,chapter:        13,versenumber:         9,verse:"他查出你们来，这岂是好吗？人欺哄人，你们也要照样欺哄他吗？"},
{volumn:        18,chapter:        13,versenumber:        10,verse:"你们若暗中徇情，他必要责备你们。"},
{volumn:        18,chapter:        13,versenumber:        11,verse:"他的尊荣岂不叫你们惧怕吗？他的惊吓岂不临到你们吗？"},
{volumn:        18,chapter:        13,versenumber:        12,verse:"你们以为可记念的箴言是炉灰的箴言；你们以为可靠的坚垒是淤泥的坚垒。"},
{volumn:        18,chapter:        13,versenumber:        13,verse:"“你们不要作声，任凭我吧！让我说话，无论如何我都承当。"},
{volumn:        18,chapter:        13,versenumber:        14,verse:"我何必把我的肉挂在牙上，将我的命放在手中。"},
{volumn:        18,chapter:        13,versenumber:        15,verse:"他必杀我；我虽无指望，然而我在他面前还要辩明我所行的。"},
{volumn:        18,chapter:        13,versenumber:        16,verse:"这要成为我的拯救，因为不虔诚的人不得到他面前。"},
{volumn:        18,chapter:        13,versenumber:        17,verse:"你们要细听我的言语，使我所辩论的入你们的耳中。"},
{volumn:        18,chapter:        13,versenumber:        18,verse:"我已陈明我的案，知道自己有义。"},
{volumn:        18,chapter:        13,versenumber:        19,verse:"有谁与我争论，我就情愿缄默不言，气绝而亡。"},
{volumn:        18,chapter:        13,versenumber:        20,verse:"惟有两件不要向我施行，我就不躲开你的面："},
{volumn:        18,chapter:        13,versenumber:        21,verse:"就是把你的手缩回，远离我身；又不使你的惊惶威吓我。"},
{volumn:        18,chapter:        13,versenumber:        22,verse:"这样，你呼叫，我就回答；或是让我说话，你回答我。"},
{volumn:        18,chapter:        13,versenumber:        23,verse:"我的罪孽和罪过有多少呢？求你叫我知道我的过犯与罪愆。"},
{volumn:        18,chapter:        13,versenumber:        24,verse:"你为何掩面、拿我当仇敌呢？"},
{volumn:        18,chapter:        13,versenumber:        25,verse:"你要惊动被风吹的叶子吗？要追赶枯干的碎秸吗？"},
{volumn:        18,chapter:        13,versenumber:        26,verse:"你按罪状刑罚我，又使我担当幼年的罪孽；"},
{volumn:        18,chapter:        13,versenumber:        27,verse:"也把我的脚上了木狗，并窥察我一切的道路，为我的脚掌划定界限。"},
{volumn:        18,chapter:        13,versenumber:        28,verse:"我已经像灭绝的烂物，像虫蛀的衣裳。”"},
{volumn:        18,chapter:        14,versenumber:         1,verse:"“人为妇人所生，日子短少，多有患难；"},
{volumn:        18,chapter:        14,versenumber:         2,verse:"出来如花，又被割下，飞去如影，不能存留。"},
{volumn:        18,chapter:        14,versenumber:         3,verse:"这样的人你岂睁眼看他吗？又叫我来受审吗？"},
{volumn:        18,chapter:        14,versenumber:         4,verse:"谁能使洁净之物出于污秽之中呢？无论谁也不能！"},
{volumn:        18,chapter:        14,versenumber:         5,verse:"人的日子既然限定，他的月数在你那里，你也派定他的界限，使他不能越过，"},
{volumn:        18,chapter:        14,versenumber:         6,verse:"便求你转眼不看他，使他得歇息，直等他像雇工人完毕他的日子。"},
{volumn:        18,chapter:        14,versenumber:         7,verse:"“树若被砍下，还可指望发芽，嫩枝生长不息；"},
{volumn:        18,chapter:        14,versenumber:         8,verse:"其根虽然衰老在地里，干也死在土中，"},
{volumn:        18,chapter:        14,versenumber:         9,verse:"及至得了水气，还要发芽，又长枝条，像新栽的树一样。"},
{volumn:        18,chapter:        14,versenumber:        10,verse:"但人死亡而消灭；他气绝，竟在何处呢？"},
{volumn:        18,chapter:        14,versenumber:        11,verse:"海中的水绝尽，江河消散干涸。"},
{volumn:        18,chapter:        14,versenumber:        12,verse:"人也是如此，躺下不再起来，等到天没有了，仍不得复醒，也不得从睡中唤醒。"},
{volumn:        18,chapter:        14,versenumber:        13,verse:"惟愿你把我藏在阴间，存于隐密处，等你的忿怒过去；愿你为我定了日期，记念我。"},
{volumn:        18,chapter:        14,versenumber:        14,verse:"人若死了岂能再活呢？我只要在我一切争战的日子，等我被释放（或译：改变）的时候来到。"},
{volumn:        18,chapter:        14,versenumber:        15,verse:"你呼叫，我便回答；你手所做的，你必羡慕。"},
{volumn:        18,chapter:        14,versenumber:        16,verse:"但如今你数点我的脚步，岂不窥察我的罪过吗？"},
{volumn:        18,chapter:        14,versenumber:        17,verse:"我的过犯被你封在囊中，也缝严了我的罪孽。"},
{volumn:        18,chapter:        14,versenumber:        18,verse:"“山崩变为无有；磐石挪开原处。"},
{volumn:        18,chapter:        14,versenumber:        19,verse:"水流消磨石头，所流溢的洗去地上的尘土；你也照样灭绝人的指望。"},
{volumn:        18,chapter:        14,versenumber:        20,verse:"你攻击人常常得胜，使他去世；你改变他的容貌，叫他往而不回。"},
{volumn:        18,chapter:        14,versenumber:        21,verse:"他儿子得尊荣，他也不知道，降为卑，他也不觉得。"},
{volumn:        18,chapter:        14,versenumber:        22,verse:"但知身上疼痛，心中悲哀。”"},
{volumn:        18,chapter:        15,versenumber:         1,verse:"提幔人以利法回答说："},
{volumn:        18,chapter:        15,versenumber:         2,verse:"“智慧人岂可用虚空的知识回答，用东风充满肚腹呢？"},
{volumn:        18,chapter:        15,versenumber:         3,verse:"他岂可用无益的话和无济于事的言语理论呢？"},
{volumn:        18,chapter:        15,versenumber:         4,verse:"你是废弃敬畏的意，在　神面前阻止敬虔的心。"},
{volumn:        18,chapter:        15,versenumber:         5,verse:"你的罪孽指教你的口；你选用诡诈人的舌头。"},
{volumn:        18,chapter:        15,versenumber:         6,verse:"你自己的口定你有罪，并非是我；你自己的嘴见证你的不是。"},
{volumn:        18,chapter:        15,versenumber:         7,verse:"“你岂是头一个被生的人吗？你受造在诸山之先吗？"},
{volumn:        18,chapter:        15,versenumber:         8,verse:"你曾听见　神的密旨吗？你还将智慧独自得尽吗？"},
{volumn:        18,chapter:        15,versenumber:         9,verse:"你知道什么是我们不知道的呢？你明白什么是我们不明白的呢？"},
{volumn:        18,chapter:        15,versenumber:        10,verse:"我们这里有白发的和年纪老迈的，比你父亲还老。"},
{volumn:        18,chapter:        15,versenumber:        11,verse:"神用温和的话安慰你，你以为太小吗？"},
{volumn:        18,chapter:        15,versenumber:        12,verse:"你的心为何将你逼去？你的眼为何冒出火星，"},
{volumn:        18,chapter:        15,versenumber:        13,verse:"使你的灵反对　神，也任你的口发这言语？"},
{volumn:        18,chapter:        15,versenumber:        14,verse:"人是什么，竟算为洁净呢？妇人所生的是什么，竟算为义呢？"},
{volumn:        18,chapter:        15,versenumber:        15,verse:"神不信靠他的众圣者；在他眼前，天也不洁净，"},
{volumn:        18,chapter:        15,versenumber:        16,verse:"何况那污秽可憎、喝罪孽如水的世人呢！"},
{volumn:        18,chapter:        15,versenumber:        17,verse:"“我指示你，你要听；我要述说所看见的，"},
{volumn:        18,chapter:        15,versenumber:        18,verse:"就是智慧人从列祖所受，传说而不隐瞒的。"},
{volumn:        18,chapter:        15,versenumber:        19,verse:"这地惟独赐给他们，并没有外人从他们中间经过。"},
{volumn:        18,chapter:        15,versenumber:        20,verse:"恶人一生之日劬劳痛苦；强暴人一生的年数也是如此。"},
{volumn:        18,chapter:        15,versenumber:        21,verse:"惊吓的声音常在他耳中；在平安时，抢夺的必临到他那里。"},
{volumn:        18,chapter:        15,versenumber:        22,verse:"他不信自己能从黑暗中转回；他被刀剑等候。"},
{volumn:        18,chapter:        15,versenumber:        23,verse:"他漂流在外求食，说：‘哪里有食物呢？’他知道黑暗的日子在他手边预备好了。"},
{volumn:        18,chapter:        15,versenumber:        24,verse:"急难困苦叫他害怕，而且胜了他，好像君王预备上阵一样。"},
{volumn:        18,chapter:        15,versenumber:        25,verse:"他伸手攻击　神，以骄傲攻击全能者，"},
{volumn:        18,chapter:        15,versenumber:        26,verse:"挺着颈项，用盾牌的厚凸面向全能者直闯；"},
{volumn:        18,chapter:        15,versenumber:        27,verse:"是因他的脸蒙上脂油，腰积成肥肉。"},
{volumn:        18,chapter:        15,versenumber:        28,verse:"他曾住在荒凉城邑，无人居住、将成乱堆的房屋。"},
{volumn:        18,chapter:        15,versenumber:        29,verse:"他不得富足，财物不得常存，产业在地上也不加增。"},
{volumn:        18,chapter:        15,versenumber:        30,verse:"他不得出离黑暗。火焰要将他的枝子烧干；因　神口中的气，他要灭亡（原文是走去）。"},
{volumn:        18,chapter:        15,versenumber:        31,verse:"他不用倚靠虚假欺哄自己，因虚假必成为他的报应。"},
{volumn:        18,chapter:        15,versenumber:        32,verse:"他的日期未到之先，这事必成就；他的枝子不得青绿。"},
{volumn:        18,chapter:        15,versenumber:        33,verse:"他必像葡萄树的葡萄，未熟而落；又像橄榄树的花，一开而谢。"},
{volumn:        18,chapter:        15,versenumber:        34,verse:"原来不敬虔之辈必无生育；受贿赂之人的帐棚必被火烧。"},
{volumn:        18,chapter:        15,versenumber:        35,verse:"他们所怀的是毒害，所生的是罪孽；心里所预备的是诡诈。”"},
{volumn:        18,chapter:        16,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:        16,versenumber:         2,verse:"“这样的话我听了许多；你们安慰人，反叫人愁烦。"},
{volumn:        18,chapter:        16,versenumber:         3,verse:"虚空的言语有穷尽吗？有什么话惹动你回答呢？"},
{volumn:        18,chapter:        16,versenumber:         4,verse:"我也能说你们那样的话；你们若处在我的境遇，我也会联络言语攻击你们，又能向你们摇头。"},
{volumn:        18,chapter:        16,versenumber:         5,verse:"但我必用口坚固你们，用嘴消解你们的忧愁。"},
{volumn:        18,chapter:        16,versenumber:         6,verse:"“我虽说话，忧愁仍不得消解；我虽停住不说，忧愁就离开我吗？"},
{volumn:        18,chapter:        16,versenumber:         7,verse:"但现在　神使我困倦，使亲友远离我，"},
{volumn:        18,chapter:        16,versenumber:         8,verse:"又抓住我，作见证攻击我；我身体的枯瘦也当面见证我的不是。"},
{volumn:        18,chapter:        16,versenumber:         9,verse:"主发怒撕裂我，逼迫我，向我切齿；我的敌人怒目看我。"},
{volumn:        18,chapter:        16,versenumber:        10,verse:"他们向我开口，打我的脸羞辱我，聚会攻击我。"},
{volumn:        18,chapter:        16,versenumber:        11,verse:"神把我交给不敬虔的人，把我扔到恶人的手中。"},
{volumn:        18,chapter:        16,versenumber:        12,verse:"我素来安逸，他折断我，掐住我的颈项，把我摔碎，又立我为他的箭靶子。"},
{volumn:        18,chapter:        16,versenumber:        13,verse:"他的弓箭手四面围绕我；他破裂我的肺腑，并不留情，把我的胆倾倒在地上，"},
{volumn:        18,chapter:        16,versenumber:        14,verse:"将我破裂又破裂，如同勇士向我直闯。"},
{volumn:        18,chapter:        16,versenumber:        15,verse:"“我缝麻布在我皮肤上，把我的角放在尘土中。"},
{volumn:        18,chapter:        16,versenumber:        16,verse:"我的脸因哭泣发紫，在我的眼皮上有死荫。"},
{volumn:        18,chapter:        16,versenumber:        17,verse:"我的手中却无强暴；我的祈祷也是清洁。"},
{volumn:        18,chapter:        16,versenumber:        18,verse:"地啊，不要遮盖我的血！不要阻挡我的哀求！"},
{volumn:        18,chapter:        16,versenumber:        19,verse:"现今，在天有我的见证，在上有我的中保。"},
{volumn:        18,chapter:        16,versenumber:        20,verse:"我的朋友讥诮我，我却向　神眼泪汪汪。"},
{volumn:        18,chapter:        16,versenumber:        21,verse:"愿人得与　神辩白，如同人与朋友辩白一样；"},
{volumn:        18,chapter:        16,versenumber:        22,verse:"因为再过几年，我必走那往而不返之路。”"},
{volumn:        18,chapter:        17,versenumber:         1,verse:"“我的心灵消耗，我的日子灭尽；坟墓为我预备好了。"},
{volumn:        18,chapter:        17,versenumber:         2,verse:"真有戏笑我的在我这里，我眼常见他们惹动我。"},
{volumn:        18,chapter:        17,versenumber:         3,verse:"“愿主拿凭据给我，自己为我作保。在你以外谁肯与我击掌呢？"},
{volumn:        18,chapter:        17,versenumber:         4,verse:"因你使他们心不明理，所以你必不高举他们。"},
{volumn:        18,chapter:        17,versenumber:         5,verse:"控告他的朋友、以朋友为可抢夺的，连他儿女的眼睛也要失明。"},
{volumn:        18,chapter:        17,versenumber:         6,verse:"“　神使我作了民中的笑谈；他们也吐唾沫在我脸上。"},
{volumn:        18,chapter:        17,versenumber:         7,verse:"我的眼睛因忧愁昏花；我的百体好像影儿。"},
{volumn:        18,chapter:        17,versenumber:         8,verse:"正直人因此必惊奇；无辜的人要兴起攻击不敬虔之辈。"},
{volumn:        18,chapter:        17,versenumber:         9,verse:"然而，义人要持守所行的道；手洁的人要力上加力。"},
{volumn:        18,chapter:        17,versenumber:        10,verse:"至于你们众人，可以再来辩论吧！你们中间，我找不着一个智慧人。"},
{volumn:        18,chapter:        17,versenumber:        11,verse:"我的日子已经过了；我的谋算、我心所想望的已经断绝。"},
{volumn:        18,chapter:        17,versenumber:        12,verse:"他们以黑夜为白昼，说：‘亮光近乎黑暗。’"},
{volumn:        18,chapter:        17,versenumber:        13,verse:"我若盼望阴间为我的房屋，若下榻在黑暗中，"},
{volumn:        18,chapter:        17,versenumber:        14,verse:"若对朽坏说：‘你是我的父’；对虫说：‘你是我的母亲姐妹’。"},
{volumn:        18,chapter:        17,versenumber:        15,verse:"这样，我的指望在哪里呢？我所指望的谁能看见呢？"},
{volumn:        18,chapter:        17,versenumber:        16,verse:"等到安息在尘土中，这指望必下到阴间的门闩那里了。”"},
{volumn:        18,chapter:        18,versenumber:         1,verse:"书亚人比勒达回答说："},
{volumn:        18,chapter:        18,versenumber:         2,verse:"“你寻索言语要到几时呢？你可以揣摩思想，然后我们就说话。"},
{volumn:        18,chapter:        18,versenumber:         3,verse:"我们为何算为畜生，在你眼中看作污秽呢？"},
{volumn:        18,chapter:        18,versenumber:         4,verse:"你这恼怒将自己撕裂的，难道大地为你见弃、磐石挪开原处吗？"},
{volumn:        18,chapter:        18,versenumber:         5,verse:"“恶人的亮光必要熄灭；他的火焰必不照耀。"},
{volumn:        18,chapter:        18,versenumber:         6,verse:"他帐棚中的亮光要变为黑暗；他以上的灯也必熄灭。"},
{volumn:        18,chapter:        18,versenumber:         7,verse:"他坚强的脚步必见狭窄；自己的计谋必将他绊倒。"},
{volumn:        18,chapter:        18,versenumber:         8,verse:"因为他被自己的脚陷入网中，走在缠人的网罗上。"},
{volumn:        18,chapter:        18,versenumber:         9,verse:"圈套必抓住他的脚跟；机关必擒获他。"},
{volumn:        18,chapter:        18,versenumber:        10,verse:"活扣为他藏在土内；羁绊为他藏在路上。"},
{volumn:        18,chapter:        18,versenumber:        11,verse:"四面的惊吓要使他害怕，并且追赶他的脚跟。"},
{volumn:        18,chapter:        18,versenumber:        12,verse:"他的力量必因饥饿衰败；祸患要在他旁边等候。"},
{volumn:        18,chapter:        18,versenumber:        13,verse:"他本身的肢体要被吞吃；死亡的长子要吞吃他的肢体。"},
{volumn:        18,chapter:        18,versenumber:        14,verse:"他要从所倚靠的帐棚被拔出来，带到惊吓的王那里。"},
{volumn:        18,chapter:        18,versenumber:        15,verse:"不属他的必住在他的帐棚里；硫磺必撒在他所住之处。"},
{volumn:        18,chapter:        18,versenumber:        16,verse:"下边，他的根本要枯干；上边，他的枝子要剪除。"},
{volumn:        18,chapter:        18,versenumber:        17,verse:"他的记念在地上必然灭亡；他的名字在街上也不存留。"},
{volumn:        18,chapter:        18,versenumber:        18,verse:"他必从光明中被撵到黑暗里，必被赶出世界。"},
{volumn:        18,chapter:        18,versenumber:        19,verse:"在本民中必无子无孙；在寄居之地也无一人存留。"},
{volumn:        18,chapter:        18,versenumber:        20,verse:"以后来的要惊奇他的日子，好像以前去的受了惊骇。"},
{volumn:        18,chapter:        18,versenumber:        21,verse:"不义之人的住处总是这样；此乃不认识　神之人的地步。”"},
{volumn:        18,chapter:        19,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:        19,versenumber:         2,verse:"“你们搅扰我的心，用言语压碎我要到几时呢？"},
{volumn:        18,chapter:        19,versenumber:         3,verse:"你们这十次羞辱我；你们苦待我也不以为耻。"},
{volumn:        18,chapter:        19,versenumber:         4,verse:"果真我有错，这错乃是在我。"},
{volumn:        18,chapter:        19,versenumber:         5,verse:"你们果然要向我夸大，以我的羞辱为证指责我，"},
{volumn:        18,chapter:        19,versenumber:         6,verse:"就该知道是　神倾覆我，用网罗围绕我。"},
{volumn:        18,chapter:        19,versenumber:         7,verse:"我因委屈呼叫，却不蒙应允；我呼求，却不得公断。"},
{volumn:        18,chapter:        19,versenumber:         8,verse:"神用篱笆拦住我的道路，使我不得经过；又使我的路径黑暗。"},
{volumn:        18,chapter:        19,versenumber:         9,verse:"他剥去我的荣光，摘去我头上的冠冕。"},
{volumn:        18,chapter:        19,versenumber:        10,verse:"他在四围攻击我，我便归于死亡，将我的指望如树拔出来。"},
{volumn:        18,chapter:        19,versenumber:        11,verse:"他的忿怒向我发作，以我为敌人。"},
{volumn:        18,chapter:        19,versenumber:        12,verse:"他的军旅一齐上来，修筑战路攻击我，在我帐棚的四围安营。"},
{volumn:        18,chapter:        19,versenumber:        13,verse:"“他把我的弟兄隔在远处，使我所认识的全然与我生疏。"},
{volumn:        18,chapter:        19,versenumber:        14,verse:"我的亲戚与我断绝；我的密友都忘记我。"},
{volumn:        18,chapter:        19,versenumber:        15,verse:"在我家寄居的，和我的使女都以我为外人；我在他们眼中看为外邦人。"},
{volumn:        18,chapter:        19,versenumber:        16,verse:"我呼唤仆人，虽用口求他，他还是不回答。"},
{volumn:        18,chapter:        19,versenumber:        17,verse:"我口的气味，我妻子厌恶；我的恳求，我同胞也憎嫌。"},
{volumn:        18,chapter:        19,versenumber:        18,verse:"连小孩子也藐视我；我若起来，他们都嘲笑我。"},
{volumn:        18,chapter:        19,versenumber:        19,verse:"我的密友都憎恶我；我平日所爱的人向我翻脸。"},
{volumn:        18,chapter:        19,versenumber:        20,verse:"我的皮肉紧贴骨头；我只剩牙皮逃脱了。"},
{volumn:        18,chapter:        19,versenumber:        21,verse:"我朋友啊，可怜我！可怜我！因为　神的手攻击我。"},
{volumn:        18,chapter:        19,versenumber:        22,verse:"你们为什么仿佛　神逼迫我，吃我的肉还以为不足呢？"},
{volumn:        18,chapter:        19,versenumber:        23,verse:"“惟愿我的言语现在写上，都记录在书上；"},
{volumn:        18,chapter:        19,versenumber:        24,verse:"用铁笔镌刻，用铅灌在磐石上，直存到永远。"},
{volumn:        18,chapter:        19,versenumber:        25,verse:"我知道我的救赎主活着，末了必站立在地上。"},
{volumn:        18,chapter:        19,versenumber:        26,verse:"我这皮肉灭绝之后，我必在肉体之外得见　神。"},
{volumn:        18,chapter:        19,versenumber:        27,verse:"我自己要见他，亲眼要看他，并不像外人。我的心肠在我里面消灭了！"},
{volumn:        18,chapter:        19,versenumber:        28,verse:"你们若说：我们逼迫他要何等地重呢？惹事的根乃在乎他；"},
{volumn:        18,chapter:        19,versenumber:        29,verse:"你们就当惧怕刀剑；因为忿怒惹动刀剑的刑罚，使你们知道有报应（原文是审判）。”"},
{volumn:        18,chapter:        20,versenumber:         1,verse:"拿玛人琐法回答说："},
{volumn:        18,chapter:        20,versenumber:         2,verse:"“我心中急躁，所以我的思念叫我回答。"},
{volumn:        18,chapter:        20,versenumber:         3,verse:"我已听见那羞辱我，责备我的话；我的悟性叫我回答。"},
{volumn:        18,chapter:        20,versenumber:         4,verse:"你岂不知亘古以来，自从人生在地，"},
{volumn:        18,chapter:        20,versenumber:         5,verse:"恶人夸胜是暂时的，不敬虔人的喜乐不过转眼之间吗？"},
{volumn:        18,chapter:        20,versenumber:         6,verse:"他的尊荣虽达到天上，头虽顶到云中，"},
{volumn:        18,chapter:        20,versenumber:         7,verse:"他终必灭亡，像自己的粪一样；素来见他的人要说：‘他在哪里呢？’"},
{volumn:        18,chapter:        20,versenumber:         8,verse:"他必飞去如梦，不再寻见，速被赶去，如夜间的异象。"},
{volumn:        18,chapter:        20,versenumber:         9,verse:"亲眼见过他的，必不再见他；他的本处也再见不着他。"},
{volumn:        18,chapter:        20,versenumber:        10,verse:"他的儿女要求穷人的恩；他的手要赔还不义之财。"},
{volumn:        18,chapter:        20,versenumber:        11,verse:"他的骨头虽然有青年之力，却要和他一同躺卧在尘土中。"},
{volumn:        18,chapter:        20,versenumber:        12,verse:"“他口内虽以恶为甘甜，藏在舌头底下，"},
{volumn:        18,chapter:        20,versenumber:        13,verse:"爱恋不舍，含在口中；"},
{volumn:        18,chapter:        20,versenumber:        14,verse:"他的食物在肚里却要化为酸，在他里面成为虺蛇的恶毒。"},
{volumn:        18,chapter:        20,versenumber:        15,verse:"他吞了财宝，还要吐出；　神要从他腹中掏出来。"},
{volumn:        18,chapter:        20,versenumber:        16,verse:"他必吸饮虺蛇的毒气；蝮蛇的舌头也必杀他。"},
{volumn:        18,chapter:        20,versenumber:        17,verse:"流奶与蜜之河，他不得再见。"},
{volumn:        18,chapter:        20,versenumber:        18,verse:"他劳碌得来的要赔还，不得享用（原文是吞下）；不能照所得的财货欢乐。"},
{volumn:        18,chapter:        20,versenumber:        19,verse:"他欺压穷人，且又离弃；强取非自己所盖的房屋（或译：强取房屋不得再建造）。"},
{volumn:        18,chapter:        20,versenumber:        20,verse:"他因贪而无厌，所喜悦的连一样也不能保守。"},
{volumn:        18,chapter:        20,versenumber:        21,verse:"其余的没有一样他不吞灭，所以他的福乐不能长久。"},
{volumn:        18,chapter:        20,versenumber:        22,verse:"他在满足有余的时候，必到狭窄的地步；凡受苦楚的人都必加手在他身上。"},
{volumn:        18,chapter:        20,versenumber:        23,verse:"他正要充满肚腹的时候，　神必将猛烈的忿怒降在他身上；正在他吃饭的时候，要将这忿怒像雨降在他身上。"},
{volumn:        18,chapter:        20,versenumber:        24,verse:"他要躲避铁器；铜弓的箭要将他射透。"},
{volumn:        18,chapter:        20,versenumber:        25,verse:"他把箭一抽，就从他身上出来；发光的箭头从他胆中出来，有惊惶临在他身上。"},
{volumn:        18,chapter:        20,versenumber:        26,verse:"他的财宝归于黑暗；人所不吹的火要把他烧灭，要把他帐棚中所剩下的烧毁。"},
{volumn:        18,chapter:        20,versenumber:        27,verse:"天要显明他的罪孽；地要兴起攻击他。"},
{volumn:        18,chapter:        20,versenumber:        28,verse:"他的家产必然过去；　神发怒的日子，他的货物都要消灭。"},
{volumn:        18,chapter:        20,versenumber:        29,verse:"这是恶人从　神所得的份，是　神为他所定的产业。”"},
{volumn:        18,chapter:        21,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:        21,versenumber:         2,verse:"“你们要细听我的言语，就算是你们安慰我。"},
{volumn:        18,chapter:        21,versenumber:         3,verse:"请宽容我，我又要说话；说了以后，任凭你们嗤笑吧！"},
{volumn:        18,chapter:        21,versenumber:         4,verse:"我岂是向人诉冤？为何不焦急呢？"},
{volumn:        18,chapter:        21,versenumber:         5,verse:"你们要看着我而惊奇，用手捂口。"},
{volumn:        18,chapter:        21,versenumber:         6,verse:"我每逢思想，心就惊惶，浑身战兢。"},
{volumn:        18,chapter:        21,versenumber:         7,verse:"恶人为何存活，享大寿数，势力强盛呢？"},
{volumn:        18,chapter:        21,versenumber:         8,verse:"他们眼见儿孙，和他们一同坚立。"},
{volumn:        18,chapter:        21,versenumber:         9,verse:"他们的家宅平安无惧；　神的杖也不加在他们身上。"},
{volumn:        18,chapter:        21,versenumber:        10,verse:"他们的公牛孳生而不断绝；母牛下犊而不掉胎。"},
{volumn:        18,chapter:        21,versenumber:        11,verse:"他们打发小孩子出去，多如羊群；他们的儿女踊跃跳舞。"},
{volumn:        18,chapter:        21,versenumber:        12,verse:"他们随着琴鼓歌唱，又因箫声欢喜。"},
{volumn:        18,chapter:        21,versenumber:        13,verse:"他们度日诸事亨通，转眼下入阴间。"},
{volumn:        18,chapter:        21,versenumber:        14,verse:"他们对　神说：‘离开我们吧！我们不愿晓得你的道。"},
{volumn:        18,chapter:        21,versenumber:        15,verse:"全能者是谁，我们何必侍奉他呢？’求告他有什么益处呢？"},
{volumn:        18,chapter:        21,versenumber:        16,verse:"看哪，他们亨通不在乎自己；恶人所谋定的离我好远。"},
{volumn:        18,chapter:        21,versenumber:        17,verse:"“恶人的灯何尝熄灭？患难何尝临到他们呢？　神何尝发怒，向他们分散灾祸呢？"},
{volumn:        18,chapter:        21,versenumber:        18,verse:"他们何尝像风前的碎秸，如暴风刮去的糠秕呢？"},
{volumn:        18,chapter:        21,versenumber:        19,verse:"你们说：　神为恶人的儿女积蓄罪孽；我说：不如本人受报，好使他亲自知道。"},
{volumn:        18,chapter:        21,versenumber:        20,verse:"愿他亲眼看见自己败亡，亲自饮全能者的忿怒。"},
{volumn:        18,chapter:        21,versenumber:        21,verse:"他的岁月既尽，他还顾他本家吗？"},
{volumn:        18,chapter:        21,versenumber:        22,verse:"神既审判那在高位的，谁能将知识教训他呢？"},
{volumn:        18,chapter:        21,versenumber:        23,verse:"有人至死身体强壮，尽得平靖安逸；"},
{volumn:        18,chapter:        21,versenumber:        24,verse:"他的奶桶充满，他的骨髓滋润。"},
{volumn:        18,chapter:        21,versenumber:        25,verse:"有人至死心中痛苦，终身未尝福乐的滋味；"},
{volumn:        18,chapter:        21,versenumber:        26,verse:"他们一样躺卧在尘土中，都被虫子遮盖。"},
{volumn:        18,chapter:        21,versenumber:        27,verse:"“我知道你们的意思，并诬害我的计谋。"},
{volumn:        18,chapter:        21,versenumber:        28,verse:"你们说：‘霸者的房屋在哪里？恶人住过的帐棚在哪里？’"},
{volumn:        18,chapter:        21,versenumber:        29,verse:"你们岂没有询问过路的人吗？不知道他们所引的证据吗？"},
{volumn:        18,chapter:        21,versenumber:        30,verse:"就是恶人在祸患的日子得存留，在发怒的日子得逃脱。"},
{volumn:        18,chapter:        21,versenumber:        31,verse:"“他所行的，有谁当面给他说明？他所做的，有谁报应他呢？"},
{volumn:        18,chapter:        21,versenumber:        32,verse:"然而他要被抬到茔地；并有人看守坟墓。"},
{volumn:        18,chapter:        21,versenumber:        33,verse:"他要以谷中的土块为甘甜；在他以先去的无数，在他以后去的更多。"},
{volumn:        18,chapter:        21,versenumber:        34,verse:"你们对答的话中既都错谬，怎么徒然安慰我呢？”"},
{volumn:        18,chapter:        22,versenumber:         1,verse:"提幔人以利法回答说："},
{volumn:        18,chapter:        22,versenumber:         2,verse:"“人岂能使　神有益呢？智慧人但能有益于己。"},
{volumn:        18,chapter:        22,versenumber:         3,verse:"你为人公义，岂叫全能者喜悦呢？你行为完全，岂能使他得利呢？"},
{volumn:        18,chapter:        22,versenumber:         4,verse:"岂是因你敬畏他就责备你、审判你吗？"},
{volumn:        18,chapter:        22,versenumber:         5,verse:"你的罪恶岂不是大吗？你的罪孽也没有穷尽；"},
{volumn:        18,chapter:        22,versenumber:         6,verse:"因你无故强取弟兄的物为当头，剥去贫寒人的衣服。"},
{volumn:        18,chapter:        22,versenumber:         7,verse:"困乏的人，你没有给他水喝；饥饿的人，你没有给他食物。"},
{volumn:        18,chapter:        22,versenumber:         8,verse:"有能力的人就得地土；尊贵的人也住在其中。"},
{volumn:        18,chapter:        22,versenumber:         9,verse:"你打发寡妇空手回去，折断孤儿的膀臂。"},
{volumn:        18,chapter:        22,versenumber:        10,verse:"因此，有网罗环绕你，有恐惧忽然使你惊惶；"},
{volumn:        18,chapter:        22,versenumber:        11,verse:"或有黑暗蒙蔽你，并有洪水淹没你。"},
{volumn:        18,chapter:        22,versenumber:        12,verse:"“　神岂不是在高天吗？你看星宿何其高呢！"},
{volumn:        18,chapter:        22,versenumber:        13,verse:"你说：　神知道什么？他岂能看透幽暗施行审判呢？"},
{volumn:        18,chapter:        22,versenumber:        14,verse:"密云将他遮盖，使他不能看见；他周游穹苍。"},
{volumn:        18,chapter:        22,versenumber:        15,verse:"你要依从上古的道吗？这道是恶人所行的。"},
{volumn:        18,chapter:        22,versenumber:        16,verse:"他们未到死期，忽然除灭；根基毁坏，好像被江河冲去。"},
{volumn:        18,chapter:        22,versenumber:        17,verse:"他们向　神说：‘离开我们吧！’又说：‘全能者能把我们怎么样呢？’"},
{volumn:        18,chapter:        22,versenumber:        18,verse:"哪知　神以美物充满他们的房屋；但恶人所谋定的离我好远。"},
{volumn:        18,chapter:        22,versenumber:        19,verse:"义人看见他们的结局就欢喜；无辜的人嗤笑他们，"},
{volumn:        18,chapter:        22,versenumber:        20,verse:"说：‘那起来攻击我们的果然被剪除，其余的都被火烧灭。’"},
{volumn:        18,chapter:        22,versenumber:        21,verse:"“你要认识　神，就得平安；福气也必临到你。"},
{volumn:        18,chapter:        22,versenumber:        22,verse:"你当领受他口中的教训，将他的言语存在心里。"},
{volumn:        18,chapter:        22,versenumber:        23,verse:"你若归向全能者，从你帐棚中远除不义，就必得建立。"},
{volumn:        18,chapter:        22,versenumber:        24,verse:"要将你的珍宝丢在尘土里，将俄斐的黄金丢在溪河石头之间；"},
{volumn:        18,chapter:        22,versenumber:        25,verse:"全能者就必为你的珍宝，作你的宝银。"},
{volumn:        18,chapter:        22,versenumber:        26,verse:"你就要以全能者为喜乐，向　神仰起脸来。"},
{volumn:        18,chapter:        22,versenumber:        27,verse:"你要祷告他，他就听你；你也要还你的愿。"},
{volumn:        18,chapter:        22,versenumber:        28,verse:"你定意要做何事，必然给你成就；亮光也必照耀你的路。"},
{volumn:        18,chapter:        22,versenumber:        29,verse:"人使你降卑，你仍可说：必得高升；谦卑的人，　神必然拯救。"},
{volumn:        18,chapter:        22,versenumber:        30,verse:"人非无辜，　神且要搭救他；他因你手中清洁，必蒙拯救。”"},
{volumn:        18,chapter:        23,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:        23,versenumber:         2,verse:"“如今我的哀告还算为悖逆；我的责罚比我的唉哼还重。"},
{volumn:        18,chapter:        23,versenumber:         3,verse:"惟愿我能知道在哪里可以寻见　神，能到他的台前，"},
{volumn:        18,chapter:        23,versenumber:         4,verse:"我就在他面前将我的案件陈明，满口辩白。"},
{volumn:        18,chapter:        23,versenumber:         5,verse:"我必知道他回答我的言语，明白他向我所说的话。"},
{volumn:        18,chapter:        23,versenumber:         6,verse:"他岂用大能与我争辩吗？必不这样！他必理会我。"},
{volumn:        18,chapter:        23,versenumber:         7,verse:"在他那里正直人可以与他辩论；这样，我必永远脱离那审判我的。"},
{volumn:        18,chapter:        23,versenumber:         8,verse:"“只是，我往前行，他不在那里，往后退，也不能见他。"},
{volumn:        18,chapter:        23,versenumber:         9,verse:"他在左边行事，我却不能看见，在右边隐藏，我也不能见他。"},
{volumn:        18,chapter:        23,versenumber:        10,verse:"然而他知道我所行的路；他试炼我之后，我必如精金。"},
{volumn:        18,chapter:        23,versenumber:        11,verse:"我脚追随他的步履；我谨守他的道，并不偏离。"},
{volumn:        18,chapter:        23,versenumber:        12,verse:"他嘴唇的命令，我未曾背弃；我看重他口中的言语，过于我需用的饮食。"},
{volumn:        18,chapter:        23,versenumber:        13,verse:"只是他心志已定，谁能使他转意呢？他心里所愿的，就行出来。"},
{volumn:        18,chapter:        23,versenumber:        14,verse:"他向我所定的，就必做成；这类的事他还有许多。"},
{volumn:        18,chapter:        23,versenumber:        15,verse:"所以我在他面前惊惶；我思念这事便惧怕他。"},
{volumn:        18,chapter:        23,versenumber:        16,verse:"神使我丧胆；全能者使我惊惶。"},
{volumn:        18,chapter:        23,versenumber:        17,verse:"我的恐惧不是因为黑暗，也不是因为幽暗蒙蔽我的脸。”"},
{volumn:        18,chapter:        24,versenumber:         1,verse:"“全能者既定期罚恶，为何不使认识他的人看见那日子呢？"},
{volumn:        18,chapter:        24,versenumber:         2,verse:"有人挪移地界，抢夺群畜而牧养。"},
{volumn:        18,chapter:        24,versenumber:         3,verse:"他们拉去孤儿的驴，强取寡妇的牛为当头。"},
{volumn:        18,chapter:        24,versenumber:         4,verse:"他们使穷人离开正道；世上的贫民尽都隐藏。"},
{volumn:        18,chapter:        24,versenumber:         5,verse:"这些贫穷人如同野驴出到旷野，殷勤寻找食物；他们靠着野地给儿女糊口，"},
{volumn:        18,chapter:        24,versenumber:         6,verse:"收割别人田间的禾稼，摘取恶人余剩的葡萄，"},
{volumn:        18,chapter:        24,versenumber:         7,verse:"终夜赤身无衣，天气寒冷毫无遮盖，"},
{volumn:        18,chapter:        24,versenumber:         8,verse:"在山上被大雨淋湿，因没有避身之处就挨近磐石。"},
{volumn:        18,chapter:        24,versenumber:         9,verse:"又有人从母怀中抢夺孤儿，强取穷人的衣服为当头，"},
{volumn:        18,chapter:        24,versenumber:        10,verse:"使人赤身无衣，到处流行，且因饥饿扛抬禾捆，"},
{volumn:        18,chapter:        24,versenumber:        11,verse:"在那些人的围墙内造油，榨酒，自己还口渴。"},
{volumn:        18,chapter:        24,versenumber:        12,verse:"在多民的城内有人唉哼，受伤的人哀号；　神却不理会那恶人的愚妄。"},
{volumn:        18,chapter:        24,versenumber:        13,verse:"“又有人背弃光明，不认识光明的道，不住在光明的路上。"},
{volumn:        18,chapter:        24,versenumber:        14,verse:"杀人的黎明起来，杀害困苦穷乏人，夜间又作盗贼。"},
{volumn:        18,chapter:        24,versenumber:        15,verse:"奸夫等候黄昏，说：‘必无眼能见我’，就把脸蒙蔽。"},
{volumn:        18,chapter:        24,versenumber:        16,verse:"盗贼黑夜挖窟窿；白日躲藏，并不认识光明。"},
{volumn:        18,chapter:        24,versenumber:        17,verse:"他们看早晨如幽暗，因为他们晓得幽暗的惊骇。"},
{volumn:        18,chapter:        24,versenumber:        18,verse:"“这些恶人犹如浮萍快快飘去。他们所得的份在世上被咒诅；他们不得再走葡萄园的路。"},
{volumn:        18,chapter:        24,versenumber:        19,verse:"干旱炎热消没雪水；阴间也如此消没犯罪之辈。"},
{volumn:        18,chapter:        24,versenumber:        20,verse:"怀他的母（原文是胎）要忘记他；虫子要吃他，觉得甘甜；他不再被人记念。不义的人必如树折断。"},
{volumn:        18,chapter:        24,versenumber:        21,verse:"“他恶待（或译：他吞灭）不怀孕不生养的妇人，不善待寡妇。"},
{volumn:        18,chapter:        24,versenumber:        22,verse:"然而　神用能力保全有势力的人；那性命难保的人仍然兴起。"},
{volumn:        18,chapter:        24,versenumber:        23,verse:"神使他们安稳，他们就有所倚靠；　神的眼目也看顾他们的道路。"},
{volumn:        18,chapter:        24,versenumber:        24,verse:"他们被高举，不过片时就没有了；他们降为卑，被除灭，与众人一样，又如谷穗被割。"},
{volumn:        18,chapter:        24,versenumber:        25,verse:"若不是这样，谁能证实我是说谎的，将我的言语驳为虚空呢？”"},
{volumn:        18,chapter:        25,versenumber:         1,verse:"书亚人比勒达回答说："},
{volumn:        18,chapter:        25,versenumber:         2,verse:"“　神有治理之权，有威严可畏；他在高处施行和平。"},
{volumn:        18,chapter:        25,versenumber:         3,verse:"他的诸军岂能数算？他的光亮一发，谁不蒙照呢？"},
{volumn:        18,chapter:        25,versenumber:         4,verse:"这样在　神面前，人怎能称义？妇人所生的怎能洁净？"},
{volumn:        18,chapter:        25,versenumber:         5,verse:"在　神眼前，月亮也无光亮，星宿也不清洁。"},
{volumn:        18,chapter:        25,versenumber:         6,verse:"何况如虫的人，如蛆的世人呢！”"},
{volumn:        18,chapter:        26,versenumber:         1,verse:"约伯回答说："},
{volumn:        18,chapter:        26,versenumber:         2,verse:"“无能的人蒙你何等的帮助！膀臂无力的人蒙你何等的拯救！"},
{volumn:        18,chapter:        26,versenumber:         3,verse:"无智慧的人蒙你何等的指教！你向他多显大知识！"},
{volumn:        18,chapter:        26,versenumber:         4,verse:"你向谁发出言语来？谁的灵从你而出？"},
{volumn:        18,chapter:        26,versenumber:         5,verse:"在大水和水族以下的阴魂战兢。"},
{volumn:        18,chapter:        26,versenumber:         6,verse:"在　神面前，阴间显露；灭亡也不得遮掩。"},
{volumn:        18,chapter:        26,versenumber:         7,verse:"神将北极铺在空中，将大地悬在虚空；"},
{volumn:        18,chapter:        26,versenumber:         8,verse:"将水包在密云中，云却不破裂；"},
{volumn:        18,chapter:        26,versenumber:         9,verse:"遮蔽他的宝座，将云铺在其上；"},
{volumn:        18,chapter:        26,versenumber:        10,verse:"在水面的周围划出界限，直到光明黑暗的交界。"},
{volumn:        18,chapter:        26,versenumber:        11,verse:"天的柱子因他的斥责震动惊奇。"},
{volumn:        18,chapter:        26,versenumber:        12,verse:"他以能力搅动（或译：平静）大海；他藉知识打伤拉哈伯，"},
{volumn:        18,chapter:        26,versenumber:        13,verse:"藉他的灵使天有妆饰；他的手刺杀快蛇。"},
{volumn:        18,chapter:        26,versenumber:        14,verse:"看哪，这不过是　神工作的些微；我们所听于他的是何等细微的声音！他大能的雷声谁能明透呢？”"},
{volumn:        18,chapter:        27,versenumber:         1,verse:"约伯接着说："},
{volumn:        18,chapter:        27,versenumber:         2,verse:"“　神夺去我的理，全能者使我心中愁苦。我指着永生的　神起誓："},
{volumn:        18,chapter:        27,versenumber:         3,verse:"我的生命尚在我里面；　神所赐呼吸之气仍在我的鼻孔内。"},
{volumn:        18,chapter:        27,versenumber:         4,verse:"我的嘴决不说非义之言；我的舌也不说诡诈之语。"},
{volumn:        18,chapter:        27,versenumber:         5,verse:"我断不以你们为是；我至死必不以自己为不正！"},
{volumn:        18,chapter:        27,versenumber:         6,verse:"我持定我的义，必不放松；在世的日子，我心必不责备我。"},
{volumn:        18,chapter:        27,versenumber:         7,verse:"“愿我的仇敌如恶人一样；愿那起来攻击我的，如不义之人一般。"},
{volumn:        18,chapter:        27,versenumber:         8,verse:"不敬虔的人虽然得利，　神夺取其命的时候还有什么指望呢？"},
{volumn:        18,chapter:        27,versenumber:         9,verse:"患难临到他，　神岂能听他的呼求？"},
{volumn:        18,chapter:        27,versenumber:        10,verse:"他岂以全能者为乐，随时求告　神呢？"},
{volumn:        18,chapter:        27,versenumber:        11,verse:"神的作为，我要指教你们；全能者所行的，我也不隐瞒。"},
{volumn:        18,chapter:        27,versenumber:        12,verse:"你们自己也都见过，为何全然变为虚妄呢？"},
{volumn:        18,chapter:        27,versenumber:        13,verse:"“　神为恶人所定的份，强暴人从全能者所得的报（原文是产业）乃是这样："},
{volumn:        18,chapter:        27,versenumber:        14,verse:"倘或他的儿女增多，还是被刀所杀；他的子孙必不得饱食。"},
{volumn:        18,chapter:        27,versenumber:        15,verse:"他所遗留的人必死而埋葬；他的寡妇也不哀哭。"},
{volumn:        18,chapter:        27,versenumber:        16,verse:"他虽积蓄银子如尘沙，预备衣服如泥土；"},
{volumn:        18,chapter:        27,versenumber:        17,verse:"他只管预备，义人却要穿上；他的银子，无辜的人要分取。"},
{volumn:        18,chapter:        27,versenumber:        18,verse:"他建造房屋如虫做窝，又如守望者所搭的棚。"},
{volumn:        18,chapter:        27,versenumber:        19,verse:"他虽富足躺卧，却不得收殓，转眼之间就不在了。"},
{volumn:        18,chapter:        27,versenumber:        20,verse:"惊恐如波涛将他追上；暴风在夜间将他刮去。"},
{volumn:        18,chapter:        27,versenumber:        21,verse:"东风把他飘去，又刮他离开本处。"},
{volumn:        18,chapter:        27,versenumber:        22,verse:"神要向他射箭，并不留情；他恨不得逃脱　神的手。"},
{volumn:        18,chapter:        27,versenumber:        23,verse:"人要向他拍掌，并要发叱声，使他离开本处。”"},
{volumn:        18,chapter:        28,versenumber:         1,verse:"“银子有矿；炼金有方。"},
{volumn:        18,chapter:        28,versenumber:         2,verse:"铁从地里挖出；铜从石中熔化。"},
{volumn:        18,chapter:        28,versenumber:         3,verse:"人为黑暗定界限，查究幽暗阴翳的石头，直到极处，"},
{volumn:        18,chapter:        28,versenumber:         4,verse:"在无人居住之处刨开矿穴，过路的人也想不到他们；又与人远离，悬在空中摇来摇去。"},
{volumn:        18,chapter:        28,versenumber:         5,verse:"至于地，能出粮食，地内好像被火翻起来。"},
{volumn:        18,chapter:        28,versenumber:         6,verse:"地中的石头有蓝宝石，并有金沙。"},
{volumn:        18,chapter:        28,versenumber:         7,verse:"矿中的路鸷鸟不得知道；鹰眼也未见过。"},
{volumn:        18,chapter:        28,versenumber:         8,verse:"狂傲的野兽未曾行过；猛烈的狮子也未曾经过。"},
{volumn:        18,chapter:        28,versenumber:         9,verse:"“人伸手凿开坚石，倾倒山根，"},
{volumn:        18,chapter:        28,versenumber:        10,verse:"在磐石中凿出水道，亲眼看见各样宝物。"},
{volumn:        18,chapter:        28,versenumber:        11,verse:"他封闭水不得滴流，使隐藏的物显露出来。"},
{volumn:        18,chapter:        28,versenumber:        12,verse:"“然而，智慧有何处可寻？聪明之处在哪里呢？"},
{volumn:        18,chapter:        28,versenumber:        13,verse:"智慧的价值无人能知，在活人之地也无处可寻。"},
{volumn:        18,chapter:        28,versenumber:        14,verse:"深渊说：‘不在我内’。沧海说：‘不在我中。’"},
{volumn:        18,chapter:        28,versenumber:        15,verse:"智慧非用黄金可得，也不能平白银为它的价值。"},
{volumn:        18,chapter:        28,versenumber:        16,verse:"俄斐金和贵重的红玛瑙，并蓝宝石，不足与较量；"},
{volumn:        18,chapter:        28,versenumber:        17,verse:"黄金和玻璃不足与比较；精金的器皿不足与兑换。"},
{volumn:        18,chapter:        28,versenumber:        18,verse:"珊瑚、水晶都不足论；智慧的价值胜过珍珠（或译：红宝石）。"},
{volumn:        18,chapter:        28,versenumber:        19,verse:"古实的红璧玺不足与比较；精金也不足与较量。"},
{volumn:        18,chapter:        28,versenumber:        20,verse:"“智慧从何处来呢？聪明之处在哪里呢？"},
{volumn:        18,chapter:        28,versenumber:        21,verse:"是向一切有生命的眼目隐藏，向空中的飞鸟掩蔽。"},
{volumn:        18,chapter:        28,versenumber:        22,verse:"灭没和死亡说：‘我们风闻其名。’"},
{volumn:        18,chapter:        28,versenumber:        23,verse:"“　神明白智慧的道路，晓得智慧的所在。"},
{volumn:        18,chapter:        28,versenumber:        24,verse:"因他鉴察直到地极，遍观普天之下，"},
{volumn:        18,chapter:        28,versenumber:        25,verse:"要为风定轻重，又度量诸水；"},
{volumn:        18,chapter:        28,versenumber:        26,verse:"他为雨露定命令，为雷电定道路。"},
{volumn:        18,chapter:        28,versenumber:        27,verse:"那时他看见智慧，而且述说；他坚定，并且查究。"},
{volumn:        18,chapter:        28,versenumber:        28,verse:"他对人说：‘敬畏主就是智慧；远离恶便是聪明。’”"},
{volumn:        18,chapter:        29,versenumber:         1,verse:"约伯又接着说："},
{volumn:        18,chapter:        29,versenumber:         2,verse:"“惟愿我的景况如从前的月份，如　神保守我的日子。"},
{volumn:        18,chapter:        29,versenumber:         3,verse:"那时他的灯照在我头上；我藉他的光行过黑暗。"},
{volumn:        18,chapter:        29,versenumber:         4,verse:"我愿如壮年的时候：那时我在帐棚中，　神待我有密友之情；"},
{volumn:        18,chapter:        29,versenumber:         5,verse:"全能者仍与我同在；我的儿女都环绕我。"},
{volumn:        18,chapter:        29,versenumber:         6,verse:"奶多可洗我的脚；磐石为我出油成河。"},
{volumn:        18,chapter:        29,versenumber:         7,verse:"我出到城门，在街上设立座位；"},
{volumn:        18,chapter:        29,versenumber:         8,verse:"少年人见我而回避，老年人也起身站立；"},
{volumn:        18,chapter:        29,versenumber:         9,verse:"王子都停止说话，用手捂口；"},
{volumn:        18,chapter:        29,versenumber:        10,verse:"首领静默无声，舌头贴住上膛。"},
{volumn:        18,chapter:        29,versenumber:        11,verse:"耳朵听我的，就称我有福；眼睛看我的，便称赞我；"},
{volumn:        18,chapter:        29,versenumber:        12,verse:"因我拯救哀求的困苦人和无人帮助的孤儿。"},
{volumn:        18,chapter:        29,versenumber:        13,verse:"将要灭亡的为我祝福；我也使寡妇心中欢乐。"},
{volumn:        18,chapter:        29,versenumber:        14,verse:"我以公义为衣服，以公平为外袍和冠冕。"},
{volumn:        18,chapter:        29,versenumber:        15,verse:"我为瞎子的眼，瘸子的脚。"},
{volumn:        18,chapter:        29,versenumber:        16,verse:"我为穷乏人的父；素不认识的人，我查明他的案件。"},
{volumn:        18,chapter:        29,versenumber:        17,verse:"我打破不义之人的牙床，从他牙齿中夺了所抢的。"},
{volumn:        18,chapter:        29,versenumber:        18,verse:"我便说：我必死在家中（原文是窝中），必增添我的日子，多如尘沙。"},
{volumn:        18,chapter:        29,versenumber:        19,verse:"我的根长到水边；露水终夜沾在我的枝上。"},
{volumn:        18,chapter:        29,versenumber:        20,verse:"我的荣耀在身上增新；我的弓在手中日强。"},
{volumn:        18,chapter:        29,versenumber:        21,verse:"“人听见我而仰望，静默等候我的指教。"},
{volumn:        18,chapter:        29,versenumber:        22,verse:"我说话之后，他们就不再说；我的言语像雨露滴在他们身上。"},
{volumn:        18,chapter:        29,versenumber:        23,verse:"他们仰望我如仰望雨，又张开口如切慕春雨。"},
{volumn:        18,chapter:        29,versenumber:        24,verse:"他们不敢自信，我就向他们含笑；他们不使我脸上的光改变。"},
{volumn:        18,chapter:        29,versenumber:        25,verse:"我为他们选择道路，又坐首位；我如君王在军队中居住，又如吊丧的安慰伤心的人。”"},
{volumn:        18,chapter:        30,versenumber:         1,verse:"“但如今，比我年少的人戏笑我；其人之父我曾藐视，不肯安在看守我羊群的狗中。"},
{volumn:        18,chapter:        30,versenumber:         2,verse:"他们壮年的气力既已衰败，其手之力与我何益呢？"},
{volumn:        18,chapter:        30,versenumber:         3,verse:"他们因穷乏饥饿，身体枯瘦，在荒废凄凉的幽暗中啃干燥之地，"},
{volumn:        18,chapter:        30,versenumber:         4,verse:"在草丛之中采咸草，罗腾（小树名，松类）的根为他们的食物。"},
{volumn:        18,chapter:        30,versenumber:         5,verse:"他们从人中被赶出；人追喊他们如贼一般，"},
{volumn:        18,chapter:        30,versenumber:         6,verse:"以致他们住在荒谷之间，在地洞和岩穴中；"},
{volumn:        18,chapter:        30,versenumber:         7,verse:"在草丛中叫唤，在荆棘下聚集。"},
{volumn:        18,chapter:        30,versenumber:         8,verse:"这都是愚顽下贱人的儿女；他们被鞭打，赶出境外。"},
{volumn:        18,chapter:        30,versenumber:         9,verse:"“现在这些人以我为歌曲，以我为笑谈。"},
{volumn:        18,chapter:        30,versenumber:        10,verse:"他们厌恶我，躲在旁边站着，不住地吐唾沫在我脸上。"},
{volumn:        18,chapter:        30,versenumber:        11,verse:"松开他们的绳索苦待我，在我面前脱去辔头。"},
{volumn:        18,chapter:        30,versenumber:        12,verse:"这等下流人在我右边起来，推开我的脚，筑成战路来攻击我。"},
{volumn:        18,chapter:        30,versenumber:        13,verse:"这些无人帮助的，毁坏我的道，加增我的灾。"},
{volumn:        18,chapter:        30,versenumber:        14,verse:"他们来如同闯进大破口，在毁坏之间滚在我身上。"},
{volumn:        18,chapter:        30,versenumber:        15,verse:"惊恐临到我，驱逐我的尊荣如风；我的福禄如云过去。"},
{volumn:        18,chapter:        30,versenumber:        16,verse:"“现在我心极其悲伤；困苦的日子将我抓住。"},
{volumn:        18,chapter:        30,versenumber:        17,verse:"夜间，我里面的骨头刺我，疼痛不止，好像啃我。"},
{volumn:        18,chapter:        30,versenumber:        18,verse:"因　神的大力，我的外衣污秽不堪，又如里衣的领子将我缠住。"},
{volumn:        18,chapter:        30,versenumber:        19,verse:"神把我扔在淤泥中，我就像尘土和炉灰一般。"},
{volumn:        18,chapter:        30,versenumber:        20,verse:"主啊，我呼求你，你不应允我；我站起来，你就定睛看我。"},
{volumn:        18,chapter:        30,versenumber:        21,verse:"你向我变心，待我残忍，又用大能追逼我，"},
{volumn:        18,chapter:        30,versenumber:        22,verse:"把我提在风中，使我驾风而行，又使我消灭在烈风中。"},
{volumn:        18,chapter:        30,versenumber:        23,verse:"我知道要使我临到死地，到那为众生所定的阴宅。"},
{volumn:        18,chapter:        30,versenumber:        24,verse:"“然而，人仆倒岂不伸手？遇灾难岂不求救呢？"},
{volumn:        18,chapter:        30,versenumber:        25,verse:"人遭难，我岂不为他哭泣呢？人穷乏，我岂不为他忧愁呢？"},
{volumn:        18,chapter:        30,versenumber:        26,verse:"我仰望得好处，灾祸就到了；我等待光明，黑暗便来了。"},
{volumn:        18,chapter:        30,versenumber:        27,verse:"我心里烦扰不安，困苦的日子临到我身。"},
{volumn:        18,chapter:        30,versenumber:        28,verse:"我没有日光就哀哭行去（或译：我面发黑并非因日晒）；我在会中站着求救。"},
{volumn:        18,chapter:        30,versenumber:        29,verse:"我与野狗为弟兄，与鸵鸟为同伴。"},
{volumn:        18,chapter:        30,versenumber:        30,verse:"我的皮肤黑而脱落；我的骨头因热烧焦。"},
{volumn:        18,chapter:        30,versenumber:        31,verse:"所以，我的琴音变为悲音；我的箫声变为哭声。”"},
{volumn:        18,chapter:        31,versenumber:         1,verse:"“我与眼睛立约，怎能恋恋瞻望处女呢？"},
{volumn:        18,chapter:        31,versenumber:         2,verse:"从至上的　神所得之份，从至高全能者所得之业是什么呢？"},
{volumn:        18,chapter:        31,versenumber:         3,verse:"岂不是祸患临到不义的，灾害临到作孽的呢？"},
{volumn:        18,chapter:        31,versenumber:         4,verse:"神岂不是察看我的道路，数点我的脚步呢？"},
{volumn:        18,chapter:        31,versenumber:         5,verse:"“我若与虚谎同行，脚若追随诡诈；"},
{volumn:        18,chapter:        31,versenumber:         6,verse:"我若被公道的天平称度，使　神可以知道我的纯正；"},
{volumn:        18,chapter:        31,versenumber:         7,verse:"我的脚步若偏离正路，我的心若随着我的眼目，若有玷污粘在我手上；"},
{volumn:        18,chapter:        31,versenumber:         8,verse:"就愿我所种的有别人吃，我田所产的被拔出来。"},
{volumn:        18,chapter:        31,versenumber:         9,verse:"“我若受迷惑，向妇人起淫念，在邻舍的门外蹲伏，"},
{volumn:        18,chapter:        31,versenumber:        10,verse:"就愿我的妻子给别人推磨，别人也与她同室。"},
{volumn:        18,chapter:        31,versenumber:        11,verse:"因为这是大罪，是审判官当罚的罪孽。"},
{volumn:        18,chapter:        31,versenumber:        12,verse:"这本是火焚烧，直到毁灭，必拔除我所有的家产。"},
{volumn:        18,chapter:        31,versenumber:        13,verse:"“我的仆婢与我争辩的时候，我若藐视不听他们的情节；"},
{volumn:        18,chapter:        31,versenumber:        14,verse:"神兴起，我怎样行呢？他察问，我怎样回答呢？"},
{volumn:        18,chapter:        31,versenumber:        15,verse:"造我在腹中的，不也是造他吗？将他与我抟在腹中的岂不是一位吗？"},
{volumn:        18,chapter:        31,versenumber:        16,verse:"“我若不容贫寒人得其所愿，或叫寡妇眼中失望，"},
{volumn:        18,chapter:        31,versenumber:        17,verse:"或独自吃我一点食物，孤儿没有与我同吃；"},
{volumn:        18,chapter:        31,versenumber:        18,verse:"从幼年时孤儿与我同长，好像父子一样；我从出母腹就扶助（原文是引领）寡妇。）"},
{volumn:        18,chapter:        31,versenumber:        19,verse:"我若见人因无衣死亡，或见穷乏人身无遮盖；"},
{volumn:        18,chapter:        31,versenumber:        20,verse:"我若不使他因我羊的毛得暖，为我祝福；"},
{volumn:        18,chapter:        31,versenumber:        21,verse:"我若在城门口见有帮助我的，举手攻击孤儿；"},
{volumn:        18,chapter:        31,versenumber:        22,verse:"情愿我的肩头从缺盆骨脱落，我的膀臂从羊矢骨折断。"},
{volumn:        18,chapter:        31,versenumber:        23,verse:"因　神降的灾祸使我恐惧；因他的威严，我不能妄为。"},
{volumn:        18,chapter:        31,versenumber:        24,verse:"“我若以黄金为指望，对精金说：你是我的倚靠；"},
{volumn:        18,chapter:        31,versenumber:        25,verse:"我若因财物丰裕，因我手多得资财而欢喜；"},
{volumn:        18,chapter:        31,versenumber:        26,verse:"我若见太阳发光，明月行在空中，"},
{volumn:        18,chapter:        31,versenumber:        27,verse:"心就暗暗被引诱，口便亲手；"},
{volumn:        18,chapter:        31,versenumber:        28,verse:"这也是审判官当罚的罪孽，又是我背弃在上的　神。"},
{volumn:        18,chapter:        31,versenumber:        29,verse:"“我若见恨我的遭报就欢喜，见他遭灾便高兴；"},
{volumn:        18,chapter:        31,versenumber:        30,verse:"我没有容口犯罪，咒诅他的生命；"},
{volumn:        18,chapter:        31,versenumber:        31,verse:"若我帐棚的人未尝说，谁不以主人的食物吃饱呢？"},
{volumn:        18,chapter:        31,versenumber:        32,verse:"从来我没有容客旅在街上住宿，却开门迎接行路的人；"},
{volumn:        18,chapter:        31,versenumber:        33,verse:"我若像亚当（或译：别人）遮掩我的过犯，将罪孽藏在怀中；"},
{volumn:        18,chapter:        31,versenumber:        34,verse:"因惧怕大众，又因宗族藐视我使我惊恐，以致闭口无言，杜门不出；"},
{volumn:        18,chapter:        31,versenumber:        35,verse:"惟愿有一位肯听我！（看哪，在这里有我所划的押，愿全能者回答我！）"},
{volumn:        18,chapter:        31,versenumber:        36,verse:"愿那敌我者所写的状词在我这里！我必带在肩上，又绑在头上为冠冕。"},
{volumn:        18,chapter:        31,versenumber:        37,verse:"我必向他述说我脚步的数目，必如君王进到他面前。"},
{volumn:        18,chapter:        31,versenumber:        38,verse:"“我若夺取田地，这地向我喊冤，犁沟一同哭泣；"},
{volumn:        18,chapter:        31,versenumber:        39,verse:"我若吃地的出产不给价值，或叫原主丧命；"},
{volumn:        18,chapter:        31,versenumber:        40,verse:"愿这地长蒺藜代替麦子，长恶草代替大麦。”约伯的话说完了。"},
{volumn:        18,chapter:        32,versenumber:         1,verse:"于是这三个人，因约伯自以为义就不再回答他。"},
{volumn:        18,chapter:        32,versenumber:         2,verse:"那时有布西人兰族巴拉迦的儿子以利户向约伯发怒；因约伯自以为义，不以　神为义。"},
{volumn:        18,chapter:        32,versenumber:         3,verse:"他又向约伯的三个朋友发怒；因为他们想不出回答的话来，仍以约伯为有罪。"},
{volumn:        18,chapter:        32,versenumber:         4,verse:"以利户要与约伯说话，就等候他们，因为他们比自己年老。"},
{volumn:        18,chapter:        32,versenumber:         5,verse:"以利户见这三个人口中无话回答，就怒气发作。"},
{volumn:        18,chapter:        32,versenumber:         6,verse:"布西人巴拉迦的儿子以利户回答说：“我年轻，你们老迈；因此我退让，不敢向你们陈说我的意见。"},
{volumn:        18,chapter:        32,versenumber:         7,verse:"我说，年老的当先说话；寿高的当以智慧教训人。"},
{volumn:        18,chapter:        32,versenumber:         8,verse:"但在人里面有灵；全能者的气使人有聪明。"},
{volumn:        18,chapter:        32,versenumber:         9,verse:"尊贵的不都有智慧；寿高的不都能明白公平。"},
{volumn:        18,chapter:        32,versenumber:        10,verse:"因此我说：你们要听我言；我也要陈说我的意见。"},
{volumn:        18,chapter:        32,versenumber:        11,verse:"“你们查究所要说的话；那时我等候你们的话，侧耳听你们的辩论，"},
{volumn:        18,chapter:        32,versenumber:        12,verse:"留心听你们；谁知你们中间无一人折服约伯，驳倒他的话。"},
{volumn:        18,chapter:        32,versenumber:        13,verse:"你们切不可说：我们寻得智慧；　神能胜他，人却不能。"},
{volumn:        18,chapter:        32,versenumber:        14,verse:"约伯没有向我争辩；我也不用你们的话回答他。"},
{volumn:        18,chapter:        32,versenumber:        15,verse:"“他们惊奇，不再回答，一言不发。"},
{volumn:        18,chapter:        32,versenumber:        16,verse:"我岂因他们不说话，站住不再回答，仍旧等候呢？"},
{volumn:        18,chapter:        32,versenumber:        17,verse:"我也要回答我的一份话，陈说我的意见。"},
{volumn:        18,chapter:        32,versenumber:        18,verse:"因为我的言语满怀；我里面的灵激动我。"},
{volumn:        18,chapter:        32,versenumber:        19,verse:"我的胸怀如盛酒之囊没有出气之缝，又如新皮袋快要破裂。"},
{volumn:        18,chapter:        32,versenumber:        20,verse:"我要说话，使我舒畅；我要开口回答。"},
{volumn:        18,chapter:        32,versenumber:        21,verse:"我必不看人的情面，也不奉承人。"},
{volumn:        18,chapter:        32,versenumber:        22,verse:"我不晓得奉承；若奉承，造我的主必快快除灭我。”"},
{volumn:        18,chapter:        33,versenumber:         1,verse:"“约伯啊，请听我的话，留心听我一切的言语。"},
{volumn:        18,chapter:        33,versenumber:         2,verse:"我现在开口，用舌发言。"},
{volumn:        18,chapter:        33,versenumber:         3,verse:"我的言语要发明心中所存的正直；我所知道的，我嘴唇要诚实地说出。"},
{volumn:        18,chapter:        33,versenumber:         4,verse:"神的灵造我；全能者的气使我得生。"},
{volumn:        18,chapter:        33,versenumber:         5,verse:"你若回答我，就站起来，在我面前陈明。"},
{volumn:        18,chapter:        33,versenumber:         6,verse:"我在　神面前与你一样，也是用土造成。"},
{volumn:        18,chapter:        33,versenumber:         7,verse:"我不用威严惊吓你，也不用势力重压你。"},
{volumn:        18,chapter:        33,versenumber:         8,verse:"“你所说的我听见了，也听见你的言语，说："},
{volumn:        18,chapter:        33,versenumber:         9,verse:"‘我是清洁无过的，我是无辜的；在我里面也没有罪孽。"},
{volumn:        18,chapter:        33,versenumber:        10,verse:"神找机会攻击我，以我为仇敌，"},
{volumn:        18,chapter:        33,versenumber:        11,verse:"把我的脚上了木狗，窥察我一切的道路。’"},
{volumn:        18,chapter:        33,versenumber:        12,verse:"“我要回答你说：你这话无理，因　神比世人更大。"},
{volumn:        18,chapter:        33,versenumber:        13,verse:"你为何与他争论呢？因他的事都不对人解说？"},
{volumn:        18,chapter:        33,versenumber:        14,verse:"神说一次、两次，世人却不理会。"},
{volumn:        18,chapter:        33,versenumber:        15,verse:"人躺在床上沉睡的时候，　神就用梦和夜间的异象，"},
{volumn:        18,chapter:        33,versenumber:        16,verse:"开通他们的耳朵，将当受的教训印在他们心上，"},
{volumn:        18,chapter:        33,versenumber:        17,verse:"好叫人不从自己的谋算，不行骄傲的事（原文是将骄傲向人隐藏），"},
{volumn:        18,chapter:        33,versenumber:        18,verse:"拦阻人不陷于坑里，不死在刀下。"},
{volumn:        18,chapter:        33,versenumber:        19,verse:"“人在床上被惩治，骨头中不住地疼痛，"},
{volumn:        18,chapter:        33,versenumber:        20,verse:"以致他的口厌弃食物，心厌恶美味。"},
{volumn:        18,chapter:        33,versenumber:        21,verse:"他的肉消瘦，不得再见；先前不见的骨头都凸出来。"},
{volumn:        18,chapter:        33,versenumber:        22,verse:"他的灵魂临近深坑；他的生命近于灭命的。"},
{volumn:        18,chapter:        33,versenumber:        23,verse:"一千天使中，若有一个作传话的与　神同在，指示人所当行的事，"},
{volumn:        18,chapter:        33,versenumber:        24,verse:"神就给他开恩，说：‘救赎他免得下坑；我已经得了赎价。’"},
{volumn:        18,chapter:        33,versenumber:        25,verse:"他的肉要比孩童的肉更嫩；他就返老还童。"},
{volumn:        18,chapter:        33,versenumber:        26,verse:"他祷告　神，　神就喜悦他，使他欢呼朝见　神的面；　神又看他为义。"},
{volumn:        18,chapter:        33,versenumber:        27,verse:"他在人前歌唱说：‘我犯了罪，颠倒是非，这竟与我无益。"},
{volumn:        18,chapter:        33,versenumber:        28,verse:"神救赎我的灵魂免入深坑；我的生命也必见光。’"},
{volumn:        18,chapter:        33,versenumber:        29,verse:"“　神两次、三次向人行这一切的事，"},
{volumn:        18,chapter:        33,versenumber:        30,verse:"为要从深坑救回人的灵魂，使他被光照耀，与活人一样。"},
{volumn:        18,chapter:        33,versenumber:        31,verse:"约伯啊，你当侧耳听我的话，不要作声，等我讲说。"},
{volumn:        18,chapter:        33,versenumber:        32,verse:"你若有话说，就可以回答我；你只管说，因我愿以你为是。"},
{volumn:        18,chapter:        33,versenumber:        33,verse:"若不然，你就听我说；你不要作声，我便将智慧教训你。”"},
{volumn:        18,chapter:        34,versenumber:         1,verse:"以利户又说："},
{volumn:        18,chapter:        34,versenumber:         2,verse:"“你们智慧人要听我的话；有知识的人要留心听我说。"},
{volumn:        18,chapter:        34,versenumber:         3,verse:"因为耳朵试验话语，好像上膛尝食物。"},
{volumn:        18,chapter:        34,versenumber:         4,verse:"我们当选择何为是，彼此知道何为善。"},
{volumn:        18,chapter:        34,versenumber:         5,verse:"约伯曾说：‘我是公义，　神夺去我的理；"},
{volumn:        18,chapter:        34,versenumber:         6,verse:"我虽有理，还算为说谎言的；我虽无过，受的伤还不能医治。’"},
{volumn:        18,chapter:        34,versenumber:         7,verse:"谁像约伯，喝讥诮如同喝水呢？"},
{volumn:        18,chapter:        34,versenumber:         8,verse:"他与作孽的结伴，和恶人同行。"},
{volumn:        18,chapter:        34,versenumber:         9,verse:"他说：‘人以　神为乐，总是无益。’"},
{volumn:        18,chapter:        34,versenumber:        10,verse:"“所以，你们明理的人要听我的话。　神断不致行恶；全能者断不致作孽。"},
{volumn:        18,chapter:        34,versenumber:        11,verse:"他必按人所做的报应人，使各人照所行的得报。"},
{volumn:        18,chapter:        34,versenumber:        12,verse:"神必不作恶；全能者也不偏离公平。"},
{volumn:        18,chapter:        34,versenumber:        13,verse:"谁派他治理地，安定全世界呢？"},
{volumn:        18,chapter:        34,versenumber:        14,verse:"他若专心为己，将灵和气收归自己，"},
{volumn:        18,chapter:        34,versenumber:        15,verse:"凡有血气的就必一同死亡；世人必仍归尘土。"},
{volumn:        18,chapter:        34,versenumber:        16,verse:"“你若明理，就当听我的话，留心听我言语的声音。"},
{volumn:        18,chapter:        34,versenumber:        17,verse:"难道恨恶公平的可以掌权吗？那有公义的、有大能的，岂可定他有罪吗？"},
{volumn:        18,chapter:        34,versenumber:        18,verse:"他对君王说：你是鄙陋的；对贵臣说：你是邪恶的。"},
{volumn:        18,chapter:        34,versenumber:        19,verse:"他待王子不徇情面，也不看重富足的过于贫穷的，因为都是他手所造。"},
{volumn:        18,chapter:        34,versenumber:        20,verse:"在转眼之间，半夜之中，他们就死亡。百姓被震动而去世；有权力的被夺去非藉人手。"},
{volumn:        18,chapter:        34,versenumber:        21,verse:"“　神注目观看人的道路，看明人的脚步。"},
{volumn:        18,chapter:        34,versenumber:        22,verse:"没有黑暗、阴翳能给作孽的藏身。"},
{volumn:        18,chapter:        34,versenumber:        23,verse:"神审判人，不必使人到他面前再三鉴察。"},
{volumn:        18,chapter:        34,versenumber:        24,verse:"他用难测之法打破有能力的人，设立别人代替他们。"},
{volumn:        18,chapter:        34,versenumber:        25,verse:"他原知道他们的行为，使他们在夜间倾倒灭亡。"},
{volumn:        18,chapter:        34,versenumber:        26,verse:"他在众人眼前击打他们，如同击打恶人一样。"},
{volumn:        18,chapter:        34,versenumber:        27,verse:"因为他们偏行不跟从他，也不留心他的道，"},
{volumn:        18,chapter:        34,versenumber:        28,verse:"甚至使贫穷人的哀声达到他那里；他也听了困苦人的哀声。"},
{volumn:        18,chapter:        34,versenumber:        29,verse:"“他使人安静，谁能扰乱（或译：定罪）呢？他掩面，谁能见他呢？无论待一国或一人都是如此。"},
{volumn:        18,chapter:        34,versenumber:        30,verse:"使不虔敬的人不得作王，免得有人牢笼百姓。"},
{volumn:        18,chapter:        34,versenumber:        31,verse:"“有谁对　神说：‘我受了责罚，不再犯罪。"},
{volumn:        18,chapter:        34,versenumber:        32,verse:"我所看不明的，求你指教我；我若作了孽，必不再作’？"},
{volumn:        18,chapter:        34,versenumber:        33,verse:"他施行报应，岂要随你的心愿、叫你推辞不受吗？选定的是你，不是我。你所知道的只管说吧！"},
{volumn:        18,chapter:        34,versenumber:        34,verse:"明理的人和听我话的智慧人必对我说："},
{volumn:        18,chapter:        34,versenumber:        35,verse:"‘约伯说话没有知识，言语中毫无智慧。’"},
{volumn:        18,chapter:        34,versenumber:        36,verse:"愿约伯被试验到底，因他回答像恶人一样。"},
{volumn:        18,chapter:        34,versenumber:        37,verse:"他在罪上又加悖逆；在我们中间拍手，用许多言语轻慢　神。”"},
{volumn:        18,chapter:        35,versenumber:         1,verse:"以利户又说："},
{volumn:        18,chapter:        35,versenumber:         2,verse:"“你以为有理，或以为你的公义胜于　神的公义，"},
{volumn:        18,chapter:        35,versenumber:         3,verse:"才说，‘这与我有什么益处？我不犯罪比犯罪有什么好处呢？’"},
{volumn:        18,chapter:        35,versenumber:         4,verse:"我要回答你和在你这里的朋友。"},
{volumn:        18,chapter:        35,versenumber:         5,verse:"你要向天观看，瞻望那高于你的穹苍。"},
{volumn:        18,chapter:        35,versenumber:         6,verse:"你若犯罪，能使　神受何害呢？你的过犯加增，能使　神受何损呢？"},
{volumn:        18,chapter:        35,versenumber:         7,verse:"你若是公义，还能加增他什么呢？他从你手里还接受什么呢？"},
{volumn:        18,chapter:        35,versenumber:         8,verse:"你的过恶或能害你这类的人；你的公义或能叫世人得益处。"},
{volumn:        18,chapter:        35,versenumber:         9,verse:"“人因多受欺压就哀求，因受能者的辖制（原文是膀臂）便求救，"},
{volumn:        18,chapter:        35,versenumber:        10,verse:"却无人说：‘造我的　神在哪里？他使人夜间歌唱，"},
{volumn:        18,chapter:        35,versenumber:        11,verse:"教训我们胜于地上的走兽，使我们有聪明胜于空中的飞鸟。’"},
{volumn:        18,chapter:        35,versenumber:        12,verse:"他们在那里，因恶人的骄傲呼求，却无人答应。"},
{volumn:        18,chapter:        35,versenumber:        13,verse:"虚妄的呼求，　神必不垂听；全能者也必不眷顾。"},
{volumn:        18,chapter:        35,versenumber:        14,verse:"何况你说，你不得见他；你的案件在他面前，你等候他吧。"},
{volumn:        18,chapter:        35,versenumber:        15,verse:"但如今因他未曾发怒降罚，也不甚理会狂傲，"},
{volumn:        18,chapter:        35,versenumber:        16,verse:"所以约伯开口说虚妄的话，多发无知识的言语。”"},
{volumn:        18,chapter:        36,versenumber:         1,verse:"以利户又接着说："},
{volumn:        18,chapter:        36,versenumber:         2,verse:"“你再容我片时，我就指示你，因我还有话为　神说。"},
{volumn:        18,chapter:        36,versenumber:         3,verse:"我要将所知道的从远处引来，将公义归给造我的主。"},
{volumn:        18,chapter:        36,versenumber:         4,verse:"我的言语真不虚谎；有知识全备的与你同在。"},
{volumn:        18,chapter:        36,versenumber:         5,verse:"“　神有大能，并不藐视人；他的智慧甚广。"},
{volumn:        18,chapter:        36,versenumber:         6,verse:"他不保护恶人的性命，却为困苦人伸冤。"},
{volumn:        18,chapter:        36,versenumber:         7,verse:"他时常看顾义人，使他们和君王同坐宝座，永远要被高举。"},
{volumn:        18,chapter:        36,versenumber:         8,verse:"他们若被锁链捆住，被苦难的绳索缠住，"},
{volumn:        18,chapter:        36,versenumber:         9,verse:"他就把他们的作为和过犯指示他们，叫他们知道有骄傲的行动。"},
{volumn:        18,chapter:        36,versenumber:        10,verse:"他也开通他们的耳朵得受教训，吩咐他们离开罪孽转回。"},
{volumn:        18,chapter:        36,versenumber:        11,verse:"他们若听从侍奉他，就必度日亨通，历年福乐；"},
{volumn:        18,chapter:        36,versenumber:        12,verse:"若不听从，就要被刀杀灭，无知无识而死。"},
{volumn:        18,chapter:        36,versenumber:        13,verse:"“那心中不敬虔的人积蓄怒气；　神捆绑他们，他们竟不求救；"},
{volumn:        18,chapter:        36,versenumber:        14,verse:"必在青年时死亡，与污秽人一样丧命。"},
{volumn:        18,chapter:        36,versenumber:        15,verse:"神藉着困苦救拔困苦人，趁他们受欺压开通他们的耳朵。"},
{volumn:        18,chapter:        36,versenumber:        16,verse:"神也必引你出离患难，进入宽阔不狭窄之地；摆在你席上的必满有肥甘。"},
{volumn:        18,chapter:        36,versenumber:        17,verse:"“但你满口有恶人批评的言语；判断和刑罚抓住你。"},
{volumn:        18,chapter:        36,versenumber:        18,verse:"不可容忿怒触动你，使你不服责罚；也不可因赎价大就偏行。"},
{volumn:        18,chapter:        36,versenumber:        19,verse:"你的呼求（或译：资财），或是你一切的势力，果有灵验，叫你不受患难吗？"},
{volumn:        18,chapter:        36,versenumber:        20,verse:"不要切慕黑夜，就是众民在本处被除灭的时候。"},
{volumn:        18,chapter:        36,versenumber:        21,verse:"你要谨慎，不可重看罪孽，因你选择罪孽过于选择苦难。"},
{volumn:        18,chapter:        36,versenumber:        22,verse:"神行事有高大的能力；教训人的有谁像他呢？"},
{volumn:        18,chapter:        36,versenumber:        23,verse:"谁派定他的道路？谁能说：‘你所行的不义’？"},
{volumn:        18,chapter:        36,versenumber:        24,verse:"“你不可忘记称赞他所行的为大，就是人所歌颂的。"},
{volumn:        18,chapter:        36,versenumber:        25,verse:"他所行的，万人都看见；世人也从远处观看。"},
{volumn:        18,chapter:        36,versenumber:        26,verse:"神为大，我们不能全知；他的年数不能测度。"},
{volumn:        18,chapter:        36,versenumber:        27,verse:"他吸取水点，这水点从云雾中就变成雨；"},
{volumn:        18,chapter:        36,versenumber:        28,verse:"云彩将雨落下，沛然降与世人。"},
{volumn:        18,chapter:        36,versenumber:        29,verse:"谁能明白云彩如何铺张，和　神行宫的雷声呢？"},
{volumn:        18,chapter:        36,versenumber:        30,verse:"他将亮光普照在自己的四围；他又遮覆海底。"},
{volumn:        18,chapter:        36,versenumber:        31,verse:"他用这些审判众民，且赐丰富的粮食。"},
{volumn:        18,chapter:        36,versenumber:        32,verse:"他以电光遮手，命闪电击中敌人（或译：中了靶子）。"},
{volumn:        18,chapter:        36,versenumber:        33,verse:"所发的雷声显明他的作为，又向牲畜指明要起暴风。”"},
{volumn:        18,chapter:        37,versenumber:         1,verse:"“因此我心战兢，从原处移动。"},
{volumn:        18,chapter:        37,versenumber:         2,verse:"听啊，　神轰轰的声音是他口中所发的响声。"},
{volumn:        18,chapter:        37,versenumber:         3,verse:"他发响声震遍天下，发电光闪到地极。"},
{volumn:        18,chapter:        37,versenumber:         4,verse:"随后人听见有雷声轰轰，大发威严，雷电接连不断。"},
{volumn:        18,chapter:        37,versenumber:         5,verse:"神发出奇妙的雷声；他行大事，我们不能测透。"},
{volumn:        18,chapter:        37,versenumber:         6,verse:"他对雪说：要降在地上；对大雨和暴雨也是这样说。"},
{volumn:        18,chapter:        37,versenumber:         7,verse:"他封住各人的手，叫所造的万人都晓得他的作为。"},
{volumn:        18,chapter:        37,versenumber:         8,verse:"百兽进入穴中，卧在洞内。"},
{volumn:        18,chapter:        37,versenumber:         9,verse:"暴风出于南宫；寒冷出于北方。"},
{volumn:        18,chapter:        37,versenumber:        10,verse:"神嘘气成冰；宽阔之水也都凝结。"},
{volumn:        18,chapter:        37,versenumber:        11,verse:"他使密云盛满水气，布散电光之云；"},
{volumn:        18,chapter:        37,versenumber:        12,verse:"这云是藉他的指引游行旋转，得以在全地面上行他一切所吩咐的，"},
{volumn:        18,chapter:        37,versenumber:        13,verse:"或为责罚，或为润地，或为施行慈爱。"},
{volumn:        18,chapter:        37,versenumber:        14,verse:"“约伯啊，你要留心听，要站立思想　神奇妙的作为。"},
{volumn:        18,chapter:        37,versenumber:        15,verse:"神如何吩咐这些，如何使云中的电光照耀，你知道吗？"},
{volumn:        18,chapter:        37,versenumber:        16,verse:"云彩如何浮于空中，那知识全备者奇妙的作为，你知道吗？"},
{volumn:        18,chapter:        37,versenumber:        17,verse:"南风使地寂静，你的衣服就如火热，你知道吗？"},
{volumn:        18,chapter:        37,versenumber:        18,verse:"你岂能与　神同铺穹苍吗？这穹苍坚硬，如同铸成的镜子。"},
{volumn:        18,chapter:        37,versenumber:        19,verse:"我们愚昧不能陈说；请你指教我们该对他说什么话。"},
{volumn:        18,chapter:        37,versenumber:        20,verse:"人岂可说：我愿与他说话？岂有人自愿灭亡吗？"},
{volumn:        18,chapter:        37,versenumber:        21,verse:"“现在有云遮蔽，人不得见穹苍的光亮；但风吹过，天又发晴。"},
{volumn:        18,chapter:        37,versenumber:        22,verse:"金光出于北方，在　神那里有可怕的威严。"},
{volumn:        18,chapter:        37,versenumber:        23,verse:"论到全能者，我们不能测度；他大有能力，有公平和大义，必不苦待人。"},
{volumn:        18,chapter:        37,versenumber:        24,verse:"所以，人敬畏他；凡自以为心中有智慧的人，他都不顾念。”"},
{volumn:        18,chapter:        38,versenumber:         1,verse:"那时，耶和华从旋风中回答约伯说："},
{volumn:        18,chapter:        38,versenumber:         2,verse:"“谁用无知的言语使我的旨意暗昧不明？"},
{volumn:        18,chapter:        38,versenumber:         3,verse:"你要如勇士束腰；我问你，你可以指示我。"},
{volumn:        18,chapter:        38,versenumber:         4,verse:"“我立大地根基的时候，你在哪里呢？你若有聪明，只管说吧！"},
{volumn:        18,chapter:        38,versenumber:         5,verse:"你若晓得就说，是谁定地的尺度？是谁把准绳拉在其上？"},
{volumn:        18,chapter:        38,versenumber:         6,verse:"地的根基安置在何处？地的角石是谁安放的？"},
{volumn:        18,chapter:        38,versenumber:         7,verse:"那时，晨星一同歌唱；　神的众子也都欢呼。"},
{volumn:        18,chapter:        38,versenumber:         8,verse:"“海水冲出，如出胎胞，那时谁将它关闭呢？"},
{volumn:        18,chapter:        38,versenumber:         9,verse:"是我用云彩当海的衣服，用幽暗当包裹它的布，"},
{volumn:        18,chapter:        38,versenumber:        10,verse:"为它定界限，又安门和闩，"},
{volumn:        18,chapter:        38,versenumber:        11,verse:"说：‘你只可到这里，不可越过；你狂傲的浪要到此止住。’"},
{volumn:        18,chapter:        38,versenumber:        12,verse:"“你自生以来，曾命定晨光，使清晨的日光知道本位，"},
{volumn:        18,chapter:        38,versenumber:        13,verse:"叫这光普照地的四极，将恶人从其中驱逐出来吗？"},
{volumn:        18,chapter:        38,versenumber:        14,verse:"因这光，地面改变如泥上印印，万物出现如衣服一样。"},
{volumn:        18,chapter:        38,versenumber:        15,verse:"亮光不照恶人；强横的膀臂也必折断。"},
{volumn:        18,chapter:        38,versenumber:        16,verse:"“你曾进到海源，或在深渊的隐密处行走吗？"},
{volumn:        18,chapter:        38,versenumber:        17,verse:"死亡的门曾向你显露吗？死荫的门你曾见过吗？"},
{volumn:        18,chapter:        38,versenumber:        18,verse:"地的广大你能明透吗？你若全知道，只管说吧！"},
{volumn:        18,chapter:        38,versenumber:        19,verse:"“光明的居所从何而至？黑暗的本位在于何处？"},
{volumn:        18,chapter:        38,versenumber:        20,verse:"你能带到本境，能看明其室之路吗？"},
{volumn:        18,chapter:        38,versenumber:        21,verse:"你总知道，因为你早已生在世上，你日子的数目也多。"},
{volumn:        18,chapter:        38,versenumber:        22,verse:"“你曾进入雪库，或见过雹仓吗？"},
{volumn:        18,chapter:        38,versenumber:        23,verse:"这雪雹乃是我为降灾，并打仗和争战的日子所预备的。"},
{volumn:        18,chapter:        38,versenumber:        24,verse:"光亮从何路分开？东风从何路分散遍地？"},
{volumn:        18,chapter:        38,versenumber:        25,verse:"“谁为雨水分道？谁为雷电开路？"},
{volumn:        18,chapter:        38,versenumber:        26,verse:"使雨降在无人之地、无人居住的旷野？"},
{volumn:        18,chapter:        38,versenumber:        27,verse:"使荒废凄凉之地得以丰足，青草得以发生？"},
{volumn:        18,chapter:        38,versenumber:        28,verse:"雨有父吗？露水珠是谁生的呢？"},
{volumn:        18,chapter:        38,versenumber:        29,verse:"冰出于谁的胎？天上的霜是谁生的呢？"},
{volumn:        18,chapter:        38,versenumber:        30,verse:"诸水坚硬（或译：隐藏）如石头；深渊之面凝结成冰。"},
{volumn:        18,chapter:        38,versenumber:        31,verse:"你能系住昴星的结吗？能解开参星的带吗？"},
{volumn:        18,chapter:        38,versenumber:        32,verse:"你能按时领出十二宫吗？能引导北斗和随它的众星（星：原文是子）吗？"},
{volumn:        18,chapter:        38,versenumber:        33,verse:"你知道天的定例吗？能使地归在天的权下吗？"},
{volumn:        18,chapter:        38,versenumber:        34,verse:"“你能向云彩扬起声来，使倾盆的雨遮盖你吗？"},
{volumn:        18,chapter:        38,versenumber:        35,verse:"你能发出闪电，叫它行去，使它对你说：‘我们在这里’？"},
{volumn:        18,chapter:        38,versenumber:        36,verse:"谁将智慧放在怀中？谁将聪明赐于心内？"},
{volumn:        18,chapter:        38,versenumber:        37,verse:"谁能用智慧数算云彩呢？尘土聚集成团，土块紧紧结连；那时，谁能倾倒天上的瓶呢？"},
{volumn:        18,chapter:        38,versenumber:        38,verse:""},
{volumn:        18,chapter:        38,versenumber:        39,verse:"“母狮子在洞中蹲伏，少壮狮子在隐密处埋伏；你能为它们抓取食物，使它们饱足吗？"},
{volumn:        18,chapter:        38,versenumber:        40,verse:""},
{volumn:        18,chapter:        38,versenumber:        41,verse:"乌鸦之雏因无食物飞来飞去，哀告　神；那时，谁为它预备食物呢？”"},
{volumn:        18,chapter:        39,versenumber:         1,verse:"“山岩间的野山羊几时生产，你知道吗？母鹿下犊之期，你能察定吗？"},
{volumn:        18,chapter:        39,versenumber:         2,verse:"它们怀胎的月数，你能数算吗？它们几时生产，你能晓得吗？"},
{volumn:        18,chapter:        39,versenumber:         3,verse:"它们屈身，将子生下，就除掉疼痛。"},
{volumn:        18,chapter:        39,versenumber:         4,verse:"这子渐渐肥壮，在荒野长大，去而不回。"},
{volumn:        18,chapter:        39,versenumber:         5,verse:"“谁放野驴出去自由？谁解开快驴的绳索？"},
{volumn:        18,chapter:        39,versenumber:         6,verse:"我使旷野作它的住处，使咸地当它的居所。"},
{volumn:        18,chapter:        39,versenumber:         7,verse:"它嗤笑城内的喧嚷，不听赶牲口的喝声。"},
{volumn:        18,chapter:        39,versenumber:         8,verse:"遍山是它的草场；它寻找各样青绿之物。"},
{volumn:        18,chapter:        39,versenumber:         9,verse:"“野牛岂肯服侍你？岂肯住在你的槽旁？"},
{volumn:        18,chapter:        39,versenumber:        10,verse:"你岂能用套绳将野牛笼在犁沟之间？它岂肯随你耙山谷之地？"},
{volumn:        18,chapter:        39,versenumber:        11,verse:"岂可因它的力大就倚靠它？岂可把你的工交给它做吗？"},
{volumn:        18,chapter:        39,versenumber:        12,verse:"岂可信靠它把你的粮食运到家，又收聚你禾场上的谷吗？"},
{volumn:        18,chapter:        39,versenumber:        13,verse:"“鸵鸟的翅膀欢然搧展，岂是显慈爱的翎毛和羽毛吗？"},
{volumn:        18,chapter:        39,versenumber:        14,verse:"因它把蛋留在地上，在尘土中使得温暖；"},
{volumn:        18,chapter:        39,versenumber:        15,verse:"却想不到被脚踹碎，或被野兽践踏。"},
{volumn:        18,chapter:        39,versenumber:        16,verse:"它忍心待雏，似乎不是自己的；虽然徒受劳苦，也不为雏惧怕；"},
{volumn:        18,chapter:        39,versenumber:        17,verse:"因为　神使它没有智慧，也未将悟性赐给它。"},
{volumn:        18,chapter:        39,versenumber:        18,verse:"它几时挺身展开翅膀，就嗤笑马和骑马的人。"},
{volumn:        18,chapter:        39,versenumber:        19,verse:"“马的大力是你所赐的吗？它颈项上挓挲的鬃是你给它披上的吗？"},
{volumn:        18,chapter:        39,versenumber:        20,verse:"是你叫它跳跃像蝗虫吗？它喷气之威使人惊惶。"},
{volumn:        18,chapter:        39,versenumber:        21,verse:"它在谷中刨地，自喜其力；它出去迎接佩带兵器的人。"},
{volumn:        18,chapter:        39,versenumber:        22,verse:"它嗤笑可怕的事并不惊惶，也不因刀剑退回。"},
{volumn:        18,chapter:        39,versenumber:        23,verse:"箭袋和发亮的枪，并短枪在它身上铮铮有声。"},
{volumn:        18,chapter:        39,versenumber:        24,verse:"它发猛烈的怒气将地吞下；一听角声就不耐站立。"},
{volumn:        18,chapter:        39,versenumber:        25,verse:"角每发声，它说呵哈；它从远处闻着战气，又听见军长大发雷声和兵丁呐喊。"},
{volumn:        18,chapter:        39,versenumber:        26,verse:"“鹰雀飞翔，展开翅膀一直向南，岂是藉你的智慧吗？"},
{volumn:        18,chapter:        39,versenumber:        27,verse:"大鹰上腾在高处搭窝，岂是听你的吩咐吗？"},
{volumn:        18,chapter:        39,versenumber:        28,verse:"它住在山岩，以山峰和坚固之所为家，"},
{volumn:        18,chapter:        39,versenumber:        29,verse:"从那里窥看食物，眼睛远远观望。"},
{volumn:        18,chapter:        39,versenumber:        30,verse:"它的雏也咂血；被杀的人在哪里，它也在那里。”"},
{volumn:        18,chapter:        40,versenumber:         1,verse:"耶和华又对约伯说："},
{volumn:        18,chapter:        40,versenumber:         2,verse:"“强辩的岂可与全能者争论吗？与　神辩驳的可以回答这些吧！”"},
{volumn:        18,chapter:        40,versenumber:         3,verse:"于是，约伯回答耶和华说："},
{volumn:        18,chapter:        40,versenumber:         4,verse:"“我是卑贱的！我用什么回答你呢？只好用手捂口。"},
{volumn:        18,chapter:        40,versenumber:         5,verse:"我说了一次，再不回答；说了两次，就不再说。”"},
{volumn:        18,chapter:        40,versenumber:         6,verse:"于是，耶和华从旋风中回答约伯说："},
{volumn:        18,chapter:        40,versenumber:         7,verse:"“你要如勇士束腰；我问你，你可以指示我。"},
{volumn:        18,chapter:        40,versenumber:         8,verse:"你岂可废弃我所拟定的？岂可定我有罪，好显自己为义吗？"},
{volumn:        18,chapter:        40,versenumber:         9,verse:"你有　神那样的膀臂吗？你能像他发雷声吗？"},
{volumn:        18,chapter:        40,versenumber:        10,verse:"“你要以荣耀庄严为妆饰，以尊荣威严为衣服；"},
{volumn:        18,chapter:        40,versenumber:        11,verse:"要发出你满溢的怒气，见一切骄傲的人，使他降卑；"},
{volumn:        18,chapter:        40,versenumber:        12,verse:"见一切骄傲的人，将他制伏，把恶人践踏在本处；"},
{volumn:        18,chapter:        40,versenumber:        13,verse:"将他们一同隐藏在尘土中，把他们的脸蒙蔽在隐密处；"},
{volumn:        18,chapter:        40,versenumber:        14,verse:"我就认你右手能以救自己。"},
{volumn:        18,chapter:        40,versenumber:        15,verse:"“你且观看河马；我造你也造它。它吃草与牛一样；"},
{volumn:        18,chapter:        40,versenumber:        16,verse:"它的气力在腰间，能力在肚腹的筋上。"},
{volumn:        18,chapter:        40,versenumber:        17,verse:"它摇动尾巴如香柏树；它大腿的筋互相联络。"},
{volumn:        18,chapter:        40,versenumber:        18,verse:"它的骨头好像铜管；它的肢体仿佛铁棍。"},
{volumn:        18,chapter:        40,versenumber:        19,verse:"“它在　神所造的物中为首；创造它的给它刀剑。"},
{volumn:        18,chapter:        40,versenumber:        20,verse:"诸山给它出食物，也是百兽游玩之处。"},
{volumn:        18,chapter:        40,versenumber:        21,verse:"它伏在莲叶之下，卧在芦苇隐密处和水洼子里。"},
{volumn:        18,chapter:        40,versenumber:        22,verse:"莲叶的阴凉遮蔽它；溪旁的柳树环绕它。"},
{volumn:        18,chapter:        40,versenumber:        23,verse:"河水泛滥，它不发战；就是约旦河的水涨到它口边，也是安然。"},
{volumn:        18,chapter:        40,versenumber:        24,verse:"在它防备的时候，谁能捉拿它？谁能牢笼它穿它的鼻子呢？”"},
{volumn:        18,chapter:        41,versenumber:         1,verse:"“你能用鱼钩钓上鳄鱼吗？能用绳子压下它的舌头吗？"},
{volumn:        18,chapter:        41,versenumber:         2,verse:"你能用绳索穿它的鼻子吗？能用钩穿它的腮骨吗？"},
{volumn:        18,chapter:        41,versenumber:         3,verse:"它岂向你连连恳求，说柔和的话吗？"},
{volumn:        18,chapter:        41,versenumber:         4,verse:"岂肯与你立约，使你拿它永远作奴仆吗？"},
{volumn:        18,chapter:        41,versenumber:         5,verse:"你岂可拿它当雀鸟玩耍吗？岂可为你的幼女将它拴住吗？"},
{volumn:        18,chapter:        41,versenumber:         6,verse:"搭伙的渔夫岂可拿它当货物吗？能把它分给商人吗？"},
{volumn:        18,chapter:        41,versenumber:         7,verse:"你能用倒钩枪扎满它的皮，能用鱼叉叉满它的头吗？"},
{volumn:        18,chapter:        41,versenumber:         8,verse:"你按手在它身上，想与它争战，就不再这样行吧！"},
{volumn:        18,chapter:        41,versenumber:         9,verse:"人指望捉拿它是徒然的；一见它，岂不丧胆吗？"},
{volumn:        18,chapter:        41,versenumber:        10,verse:"没有那么凶猛的人敢惹它。这样，谁能在我面前站立得住呢？"},
{volumn:        18,chapter:        41,versenumber:        11,verse:"谁先给我什么，使我偿还呢？天下万物都是我的。"},
{volumn:        18,chapter:        41,versenumber:        12,verse:"“论到鳄鱼的肢体和其大力，并美好的骨骼，我不能缄默不言。"},
{volumn:        18,chapter:        41,versenumber:        13,verse:"谁能剥它的外衣？谁能进它上下牙骨之间呢？"},
{volumn:        18,chapter:        41,versenumber:        14,verse:"谁能开它的腮颊？它牙齿四围是可畏的。"},
{volumn:        18,chapter:        41,versenumber:        15,verse:"它以坚固的鳞甲为可夸，紧紧合闭，封得严密。"},
{volumn:        18,chapter:        41,versenumber:        16,verse:"这鳞甲一一相连，甚至气不得透入其间，"},
{volumn:        18,chapter:        41,versenumber:        17,verse:"都是互相联络、胶结，不能分离。"},
{volumn:        18,chapter:        41,versenumber:        18,verse:"它打喷嚏就发出光来；它眼睛好像早晨的光线（原文是眼皮）。"},
{volumn:        18,chapter:        41,versenumber:        19,verse:"从它口中发出烧着的火把，与飞迸的火星；"},
{volumn:        18,chapter:        41,versenumber:        20,verse:"从它鼻孔冒出烟来，如烧开的锅和点着的芦苇。"},
{volumn:        18,chapter:        41,versenumber:        21,verse:"它的气点着煤炭，有火焰从它口中发出。"},
{volumn:        18,chapter:        41,versenumber:        22,verse:"它颈项中存着劲力；在它面前的都恐吓蹦跳。"},
{volumn:        18,chapter:        41,versenumber:        23,verse:"它的肉块互相联络，紧贴其身，不能摇动。"},
{volumn:        18,chapter:        41,versenumber:        24,verse:"它的心结实如石头，如下磨石那样结实。"},
{volumn:        18,chapter:        41,versenumber:        25,verse:"它一起来，勇士都惊恐，心里慌乱，便都昏迷。"},
{volumn:        18,chapter:        41,versenumber:        26,verse:"人若用刀，用枪，用标枪，用尖枪扎它，都是无用。"},
{volumn:        18,chapter:        41,versenumber:        27,verse:"它以铁为干草，以铜为烂木。"},
{volumn:        18,chapter:        41,versenumber:        28,verse:"箭不能恐吓它使它逃避；弹石在它看为碎秸。"},
{volumn:        18,chapter:        41,versenumber:        29,verse:"棍棒算为禾秸；它嗤笑短枪飕的响声。"},
{volumn:        18,chapter:        41,versenumber:        30,verse:"它肚腹下如尖瓦片；它如钉耙经过淤泥。"},
{volumn:        18,chapter:        41,versenumber:        31,verse:"它使深渊开滚如锅，使洋海如锅中的膏油。"},
{volumn:        18,chapter:        41,versenumber:        32,verse:"它行的路随后发光，令人想深渊如同白发。"},
{volumn:        18,chapter:        41,versenumber:        33,verse:"在地上没有像它造的那样，无所惧怕。"},
{volumn:        18,chapter:        41,versenumber:        34,verse:"凡高大的，它无不藐视；它在骄傲的水族上作王。”"},
{volumn:        18,chapter:        42,versenumber:         1,verse:"约伯回答耶和华说："},
{volumn:        18,chapter:        42,versenumber:         2,verse:"“我知道你万事都能做；你的旨意不能拦阻。"},
{volumn:        18,chapter:        42,versenumber:         3,verse:"谁用无知的言语使你的旨意隐藏呢？我所说的是我不明白的；这些事太奇妙，是我不知道的。"},
{volumn:        18,chapter:        42,versenumber:         4,verse:"求你听我，我要说话；我问你，求你指示我。"},
{volumn:        18,chapter:        42,versenumber:         5,verse:"我从前风闻有你，现在亲眼看见你。"},
{volumn:        18,chapter:        42,versenumber:         6,verse:"因此我厌恶自己（或译：我的言语），在尘土和炉灰中懊悔。”"},
{volumn:        18,chapter:        42,versenumber:         7,verse:"耶和华对约伯说话以后，就对提幔人以利法说：“我的怒气向你和你两个朋友发作，因为你们议论我不如我的仆人约伯说的是。"},
{volumn:        18,chapter:        42,versenumber:         8,verse:"现在你们要取七只公牛，七只公羊，到我仆人约伯那里去，为自己献上燔祭，我的仆人约伯就为你们祈祷。我因悦纳他，就不按你们的愚妄办你们。你们议论我，不如我的仆人约伯说的是。”"},
{volumn:        18,chapter:        42,versenumber:         9,verse:"于是提幔人以利法、书亚人比勒达、拿玛人琐法照着耶和华所吩咐的去行；耶和华就悦纳约伯。"},
{volumn:        18,chapter:        42,versenumber:        10,verse:"约伯为他的朋友祈祷。耶和华就使约伯从苦境（原文是掳掠）转回，并且耶和华赐给他的比他从前所有的加倍。"},
{volumn:        18,chapter:        42,versenumber:        11,verse:"约伯的弟兄、姐妹，和以先所认识的人都来见他，在他家里一同吃饭；又论到耶和华所降与他的一切灾祸，都为他悲伤安慰他。每人也送他一块银子和一个金环。"},
{volumn:        18,chapter:        42,versenumber:        12,verse:"这样，耶和华后来赐福给约伯比先前更多。他有一万四千羊，六千骆驼，一千对牛，一千母驴。"},
{volumn:        18,chapter:        42,versenumber:        13,verse:"他也有七个儿子，三个女儿。"},
{volumn:        18,chapter:        42,versenumber:        14,verse:"他给长女起名叫耶米玛，次女叫基洗亚，三女叫基连·哈朴。"},
{volumn:        18,chapter:        42,versenumber:        15,verse:"在那全地的妇女中找不着像约伯的女儿那样美貌。她们的父亲使她们在弟兄中得产业。"},
{volumn:        18,chapter:        42,versenumber:        16,verse:"此后，约伯又活了一百四十年，得见他的儿孙，直到四代。"},
{volumn:        18,chapter:        42,versenumber:        17,verse:"这样，约伯年纪老迈，日子满足而死。"},
{volumn:        19,chapter:         1,versenumber:         1,verse:"不从恶人的计谋，不站罪人的道路，不坐亵慢人的座位，"},
{volumn:        19,chapter:         1,versenumber:         2,verse:"惟喜爱耶和华的律法，昼夜思想，这人便为有福！"},
{volumn:        19,chapter:         1,versenumber:         3,verse:"他要像一棵树栽在溪水旁，按时候结果子，叶子也不枯干。凡他所做的尽都顺利。"},
{volumn:        19,chapter:         1,versenumber:         4,verse:"恶人并不是这样，乃像糠秕被风吹散。"},
{volumn:        19,chapter:         1,versenumber:         5,verse:"因此，当审判的时候、恶人必站立不住；罪人在义人的会中也是如此。"},
{volumn:        19,chapter:         1,versenumber:         6,verse:"因为耶和华知道义人的道路；恶人的道路却必灭亡。"},
{volumn:        19,chapter:         2,versenumber:         1,verse:"外邦为什么争闹？万民为什么谋算虚妄的事？"},
{volumn:        19,chapter:         2,versenumber:         2,verse:"世上的君王一齐起来，臣宰一同商议，要敌挡耶和华并他的受膏者，"},
{volumn:        19,chapter:         2,versenumber:         3,verse:"说：“我们要挣开他们的捆绑，脱去他们的绳索。”"},
{volumn:        19,chapter:         2,versenumber:         4,verse:"那坐在天上的必发笑；主必嗤笑他们。"},
{volumn:        19,chapter:         2,versenumber:         5,verse:"那时，他要在怒中责备他们，在烈怒中惊吓他们，"},
{volumn:        19,chapter:         2,versenumber:         6,verse:"说：“我已经立我的君在锡安我的圣山上了。”"},
{volumn:        19,chapter:         2,versenumber:         7,verse:"受膏者说：“我要传圣旨。耶和华曾对我说：‘你是我的儿子，我今日生你。"},
{volumn:        19,chapter:         2,versenumber:         8,verse:"你求我，我就将列国赐你为基业，将地极赐你为田产。"},
{volumn:        19,chapter:         2,versenumber:         9,verse:"你必用铁杖打破他们；你必将他们如同窑匠的瓦器摔碎。’”"},
{volumn:        19,chapter:         2,versenumber:        10,verse:"现在，你们君王应当省悟！你们世上的审判官该受管教！"},
{volumn:        19,chapter:         2,versenumber:        11,verse:"当存畏惧侍奉耶和华，又当存战兢而快乐。"},
{volumn:        19,chapter:         2,versenumber:        12,verse:"当以嘴亲子，恐怕他发怒，你们便在道中灭亡，因为他的怒气快要发作。凡投靠他的，都是有福的。"},
{volumn:        19,chapter:         3,versenumber:         1,verse:"耶和华啊，我的敌人何其加增；有许多人起来攻击我。"},
{volumn:        19,chapter:         3,versenumber:         2,verse:"有许多人议论我说：“他得不着　神的帮助。”（细拉）"},
{volumn:        19,chapter:         3,versenumber:         3,verse:"但你耶和华是我四围的盾牌，是我的荣耀，又是叫我抬起头来的。"},
{volumn:        19,chapter:         3,versenumber:         4,verse:"我用我的声音求告耶和华，他就从他的圣山上应允我。（细拉）"},
{volumn:        19,chapter:         3,versenumber:         5,verse:"我躺下睡觉，我醒着，耶和华都保佑我。"},
{volumn:        19,chapter:         3,versenumber:         6,verse:"虽有成万的百姓来周围攻击我，我也不怕。"},
{volumn:        19,chapter:         3,versenumber:         7,verse:"耶和华啊，求你起来！我的　神啊，求你救我！因为你打了我一切仇敌的腮骨，敲碎了恶人的牙齿。"},
{volumn:        19,chapter:         3,versenumber:         8,verse:"救恩属乎耶和华；愿你赐福给你的百姓。（细拉）"},
{volumn:        19,chapter:         4,versenumber:         1,verse:"显我为义的　神啊，我呼吁的时候，求你应允我！我在困苦中，你曾使我宽广；现在求你怜恤我，听我的祷告！"},
{volumn:        19,chapter:         4,versenumber:         2,verse:"你们这上流人哪，你们将我的尊荣变为羞辱要到几时呢？你们喜爱虚妄，寻找虚假，要到几时呢？（细拉）"},
{volumn:        19,chapter:         4,versenumber:         3,verse:"你们要知道，耶和华已经分别虔诚人归他自己；我求告耶和华，他必听我。"},
{volumn:        19,chapter:         4,versenumber:         4,verse:"你们应当畏惧，不可犯罪；在床上的时候，要心里思想，并要肃静。（细拉）"},
{volumn:        19,chapter:         4,versenumber:         5,verse:"当献上公义的祭，又当倚靠耶和华。"},
{volumn:        19,chapter:         4,versenumber:         6,verse:"有许多人说：“谁能指示我们什么好处？”耶和华啊，求你仰起脸来，光照我们。"},
{volumn:        19,chapter:         4,versenumber:         7,verse:"你使我心里快乐，胜过那丰收五谷新酒的人。"},
{volumn:        19,chapter:         4,versenumber:         8,verse:"我必安然躺下睡觉，因为独有你耶和华使我安然居住。"},
{volumn:        19,chapter:         5,versenumber:         1,verse:"耶和华啊，求你留心听我的言语，顾念我的心思！"},
{volumn:        19,chapter:         5,versenumber:         2,verse:"我的王我的　神啊，求你垂听我呼求的声音！因为我向你祈祷。"},
{volumn:        19,chapter:         5,versenumber:         3,verse:"耶和华啊，早晨你必听我的声音；早晨我必向你陈明我的心意，并要警醒！"},
{volumn:        19,chapter:         5,versenumber:         4,verse:"因为你不是喜悦恶事的　神，恶人不能与你同居。"},
{volumn:        19,chapter:         5,versenumber:         5,verse:"狂傲人不能站在你眼前；凡作孽的，都是你所恨恶的。"},
{volumn:        19,chapter:         5,versenumber:         6,verse:"说谎言的，你必灭绝；好流人血弄诡诈的，都为耶和华所憎恶。"},
{volumn:        19,chapter:         5,versenumber:         7,verse:"至于我，我必凭你丰盛的慈爱进入你的居所；我必存敬畏你的心向你的圣殿下拜。"},
{volumn:        19,chapter:         5,versenumber:         8,verse:"耶和华啊，求你因我的仇敌，凭你的公义引领我，使你的道路在我面前正直。"},
{volumn:        19,chapter:         5,versenumber:         9,verse:"因为，他们的口中没有诚实；他们的心里满有邪恶；他们的喉咙是敞开的坟墓；他们用舌头谄媚人。"},
{volumn:        19,chapter:         5,versenumber:        10,verse:"神啊，求你定他们的罪！愿他们因自己的计谋跌倒；愿你在他们许多的过犯中把他们逐出，因为他们背叛了你。"},
{volumn:        19,chapter:         5,versenumber:        11,verse:"凡投靠你的，愿他们喜乐，时常欢呼，因为你护庇他们；又愿那爱你名的人都靠你欢欣。"},
{volumn:        19,chapter:         5,versenumber:        12,verse:"因为你必赐福与义人；耶和华啊，你必用恩惠如同盾牌四面护卫他。"},
{volumn:        19,chapter:         6,versenumber:         1,verse:"耶和华啊，求你不要在怒中责备我，也不要在烈怒中惩罚我！"},
{volumn:        19,chapter:         6,versenumber:         2,verse:"耶和华啊，求你可怜我，因为我软弱。耶和华啊，求你医治我，因为我的骨头发战。"},
{volumn:        19,chapter:         6,versenumber:         3,verse:"我心也大大地惊惶。耶和华啊，你要到几时才救我呢？"},
{volumn:        19,chapter:         6,versenumber:         4,verse:"耶和华啊，求你转回搭救我！因你的慈爱拯救我。"},
{volumn:        19,chapter:         6,versenumber:         5,verse:"因为，在死地无人记念你，在阴间有谁称谢你？"},
{volumn:        19,chapter:         6,versenumber:         6,verse:"我因唉哼而困乏；我每夜流泪，把床榻漂起，把褥子湿透。"},
{volumn:        19,chapter:         6,versenumber:         7,verse:"我因忧愁眼睛干瘪，又因我一切的敌人眼睛昏花。"},
{volumn:        19,chapter:         6,versenumber:         8,verse:"你们一切作孽的人，离开我吧！因为耶和华听了我哀哭的声音。"},
{volumn:        19,chapter:         6,versenumber:         9,verse:"耶和华听了我的恳求；耶和华必收纳我的祷告。"},
{volumn:        19,chapter:         6,versenumber:        10,verse:"我的一切仇敌都必羞愧，大大惊惶；他们必要退后，忽然羞愧。"},
{volumn:        19,chapter:         7,versenumber:         1,verse:"耶和华我的　神啊，我投靠你！求你救我脱离一切追赶我的人，将我救拔出来！"},
{volumn:        19,chapter:         7,versenumber:         2,verse:"恐怕他们像狮子撕裂我，甚至撕碎，无人搭救。"},
{volumn:        19,chapter:         7,versenumber:         3,verse:"耶和华我的　神啊，我若行了这事，若有罪孽在我手里，"},
{volumn:        19,chapter:         7,versenumber:         4,verse:"我若以恶报那与我交好的人（连那无故与我为敌的，我也救了他），"},
{volumn:        19,chapter:         7,versenumber:         5,verse:"就任凭仇敌追赶我，直到追上，将我的性命踏在地下，使我的荣耀归于灰尘。（细拉）"},
{volumn:        19,chapter:         7,versenumber:         6,verse:"耶和华啊，求你在怒中起来，挺身而立，抵挡我敌人的暴怒！求你为我兴起！你已经命定施行审判！"},
{volumn:        19,chapter:         7,versenumber:         7,verse:"愿众民的会环绕你！愿你从其上归于高位！"},
{volumn:        19,chapter:         7,versenumber:         8,verse:"耶和华向众民施行审判；耶和华啊，求你按我的公义和我心中的纯正判断我。"},
{volumn:        19,chapter:         7,versenumber:         9,verse:"愿恶人的恶断绝！愿你坚立义人！因为公义的　神察验人的心肠肺腑。"},
{volumn:        19,chapter:         7,versenumber:        10,verse:"神是我的盾牌；他拯救心里正直的人。"},
{volumn:        19,chapter:         7,versenumber:        11,verse:"神是公义的审判者，又是天天向恶人发怒的　神。"},
{volumn:        19,chapter:         7,versenumber:        12,verse:"若有人不回头，他的刀必磨快，弓必上弦，预备妥当了。"},
{volumn:        19,chapter:         7,versenumber:        13,verse:"他也预备了杀人的器械；他所射的是火箭。"},
{volumn:        19,chapter:         7,versenumber:        14,verse:"试看恶人因奸恶而劬劳，所怀的是毒害，所生的是虚假。"},
{volumn:        19,chapter:         7,versenumber:        15,verse:"他掘了坑，又挖深了，竟掉在自己所挖的阱里。"},
{volumn:        19,chapter:         7,versenumber:        16,verse:"他的毒害必临到他自己的头上；他的强暴必落到他自己的脑袋上。"},
{volumn:        19,chapter:         7,versenumber:        17,verse:"我要照着耶和华的公义称谢他，歌颂耶和华至高者的名。"},
{volumn:        19,chapter:         8,versenumber:         1,verse:"耶和华我们的主啊，你的名在全地何其美！你将你的荣耀彰显于天。"},
{volumn:        19,chapter:         8,versenumber:         2,verse:"你因敌人的缘故，从婴孩和吃奶的口中，建立了能力，使仇敌和报仇的闭口无言。"},
{volumn:        19,chapter:         8,versenumber:         3,verse:"我观看你指头所造的天，并你所陈设的月亮星宿，"},
{volumn:        19,chapter:         8,versenumber:         4,verse:"便说：人算什么，你竟顾念他！世人算什么，你竟眷顾他！"},
{volumn:        19,chapter:         8,versenumber:         5,verse:"你叫他比天使（或译：　神）微小一点，并赐他荣耀尊贵为冠冕。"},
{volumn:        19,chapter:         8,versenumber:         6,verse:"你派他管理你手所造的，使万物，就是一切的羊牛、田野的兽、空中的鸟、海里的鱼，凡经行海道的，都服在他的脚下。"},
{volumn:        19,chapter:         8,versenumber:         7,verse:""},
{volumn:        19,chapter:         8,versenumber:         8,verse:""},
{volumn:        19,chapter:         8,versenumber:         9,verse:"耶和华我们的主啊，你的名在全地何其美！"},
{volumn:        19,chapter:         9,versenumber:         1,verse:"我要一心称谢耶和华；我要传扬你一切奇妙的作为。"},
{volumn:        19,chapter:         9,versenumber:         2,verse:"我要因你欢喜快乐；至高者啊，我要歌颂你的名！"},
{volumn:        19,chapter:         9,versenumber:         3,verse:"我的仇敌转身退去的时候，他们一见你的面就跌倒灭亡。"},
{volumn:        19,chapter:         9,versenumber:         4,verse:"因你已经为我伸冤，为我辨屈；你坐在宝座上，按公义审判。"},
{volumn:        19,chapter:         9,versenumber:         5,verse:"你曾斥责外邦，你曾灭绝恶人；你曾涂抹他们的名，直到永永远远。"},
{volumn:        19,chapter:         9,versenumber:         6,verse:"仇敌到了尽头；他们被毁坏，直到永远。你拆毁他们的城邑，连他们的名号都归于无有。"},
{volumn:        19,chapter:         9,versenumber:         7,verse:"惟耶和华坐着为王，直到永远；他已经为审判设摆他的宝座。"},
{volumn:        19,chapter:         9,versenumber:         8,verse:"他要按公义审判世界，按正直判断万民。"},
{volumn:        19,chapter:         9,versenumber:         9,verse:"耶和华又要给受欺压的人作高台，在患难的时候作高台。"},
{volumn:        19,chapter:         9,versenumber:        10,verse:"耶和华啊，认识你名的人要倚靠你，因你没有离弃寻求你的人。"},
{volumn:        19,chapter:         9,versenumber:        11,verse:"应当歌颂居锡安的耶和华，将他所行的传扬在众民中。"},
{volumn:        19,chapter:         9,versenumber:        12,verse:"因为那追讨流人血之罪的，他记念受屈的人，不忘记困苦人的哀求。"},
{volumn:        19,chapter:         9,versenumber:        13,verse:"耶和华啊，你是从死门把我提拔起来的；求你怜恤我，看那恨我的人所加给我的苦难，"},
{volumn:        19,chapter:         9,versenumber:        14,verse:"好叫我述说你一切的美德；我必在锡安城（原文是女子）的门因你的救恩欢乐。"},
{volumn:        19,chapter:         9,versenumber:        15,verse:"外邦人陷在自己所掘的坑中；他们的脚在自己暗设的网罗里缠住了。"},
{volumn:        19,chapter:         9,versenumber:        16,verse:"耶和华已将自己显明了，他已施行审判；恶人被自己手所做的缠住了（或译：他叫恶人被自己手所做的累住了）。（细拉）"},
{volumn:        19,chapter:         9,versenumber:        17,verse:"恶人，就是忘记　神的外邦人，都必归到阴间。"},
{volumn:        19,chapter:         9,versenumber:        18,verse:"穷乏人必不永久被忘；困苦人的指望必不永远落空。"},
{volumn:        19,chapter:         9,versenumber:        19,verse:"耶和华啊，求你起来，不容人得胜！愿外邦人在你面前受审判！"},
{volumn:        19,chapter:         9,versenumber:        20,verse:"耶和华啊，求你使外邦人恐惧；愿他们知道自己不过是人。（细拉）"},
{volumn:        19,chapter:        10,versenumber:         1,verse:"耶和华啊，你为什么站在远处？在患难的时候为什么隐藏？"},
{volumn:        19,chapter:        10,versenumber:         2,verse:"恶人在骄横中把困苦人追得火急；愿他们陷在自己所设的计谋里。"},
{volumn:        19,chapter:        10,versenumber:         3,verse:"因为恶人以心愿自夸；贪财的背弃耶和华，并且轻慢他（或译：他祝福贪财的，却轻慢耶和华）。"},
{volumn:        19,chapter:        10,versenumber:         4,verse:"恶人面带骄傲，说：耶和华必不追究；他一切所想的都以为没有　神。"},
{volumn:        19,chapter:        10,versenumber:         5,verse:"凡他所做的，时常稳固；你的审判超过他的眼界。至于他一切的敌人，他都向他们喷气。"},
{volumn:        19,chapter:        10,versenumber:         6,verse:"他心里说：“我必不动摇，世世代代不遭灾难。”"},
{volumn:        19,chapter:        10,versenumber:         7,verse:"他满口是咒骂、诡诈、欺压，舌底是毒害、奸恶。"},
{volumn:        19,chapter:        10,versenumber:         8,verse:"他在村庄埋伏等候；他在隐密处杀害无辜的人。他的眼睛窥探无倚无靠的人；"},
{volumn:        19,chapter:        10,versenumber:         9,verse:"他埋伏在暗地，如狮子蹲在洞中。他埋伏，要掳去困苦人；他拉网，就把困苦人掳去。"},
{volumn:        19,chapter:        10,versenumber:        10,verse:"他屈身蹲伏，无倚无靠的人就倒在他爪牙（爪牙：或译强暴人）之下。"},
{volumn:        19,chapter:        10,versenumber:        11,verse:"他心里说：“　神竟忘记了；他掩面永不观看。”"},
{volumn:        19,chapter:        10,versenumber:        12,verse:"耶和华啊，求你起来！　神啊，求你举手，不要忘记困苦人！"},
{volumn:        19,chapter:        10,versenumber:        13,verse:"恶人为何轻慢　神，心里说：“你必不追究”？"},
{volumn:        19,chapter:        10,versenumber:        14,verse:"其实你已经观看；因为奸恶毒害，你都看见了，为要以手施行报应。无倚无靠的人把自己交托你；你向来是帮助孤儿的。"},
{volumn:        19,chapter:        10,versenumber:        15,verse:"愿你打断恶人的膀臂；至于坏人，愿你追究他的恶，直到净尽。"},
{volumn:        19,chapter:        10,versenumber:        16,verse:"耶和华永永远远为王；外邦人从他的地已经灭绝了。"},
{volumn:        19,chapter:        10,versenumber:        17,verse:"耶和华啊，谦卑人的心愿，你早已知道（原文是听见）。你必预备他们的心，也必侧耳听他们的祈求，"},
{volumn:        19,chapter:        10,versenumber:        18,verse:"为要给孤儿和受欺压的人伸冤，使强横的人不再威吓他们。"},
{volumn:        19,chapter:        11,versenumber:         1,verse:"我是投靠耶和华；你们怎么对我说：“你当像鸟飞往你的山去。"},
{volumn:        19,chapter:        11,versenumber:         2,verse:"看哪，恶人弯弓，把箭搭在弦上，要在暗中射那心里正直的人。"},
{volumn:        19,chapter:        11,versenumber:         3,verse:"根基若毁坏，义人还能做什么呢？”"},
{volumn:        19,chapter:        11,versenumber:         4,verse:"耶和华在他的圣殿里；耶和华的宝座在天上；他的慧眼察看世人。"},
{volumn:        19,chapter:        11,versenumber:         5,verse:"耶和华试验义人；惟有恶人和喜爱强暴的人，他心里恨恶。"},
{volumn:        19,chapter:        11,versenumber:         6,verse:"他要向恶人密布网罗；有烈火、硫磺、热风，作他们杯中的份。"},
{volumn:        19,chapter:        11,versenumber:         7,verse:"因为耶和华是公义的，他喜爱公义；正直人必得见他的面。"},
{volumn:        19,chapter:        12,versenumber:         1,verse:"耶和华啊，求你帮助，因虔诚人断绝了；世人中间的忠信人没有了。"},
{volumn:        19,chapter:        12,versenumber:         2,verse:"人人向邻舍说谎；他们说话，是嘴唇油滑，心口不一。"},
{volumn:        19,chapter:        12,versenumber:         3,verse:"凡油滑的嘴唇和夸大的舌头，耶和华必要剪除。"},
{volumn:        19,chapter:        12,versenumber:         4,verse:"他们曾说：“我们必能以舌头得胜；我们的嘴唇是我们自己的，谁能作我们的主呢？”"},
{volumn:        19,chapter:        12,versenumber:         5,verse:"耶和华说：“因为困苦人的冤屈和贫穷人的叹息，我现在要起来，把他安置在他所切慕的稳妥之地。”"},
{volumn:        19,chapter:        12,versenumber:         6,verse:"耶和华的言语是纯净的言语，如同银子在泥炉中炼过七次。"},
{volumn:        19,chapter:        12,versenumber:         7,verse:"耶和华啊，你必保护他们；你必保佑他们永远脱离这世代的人。"},
{volumn:        19,chapter:        12,versenumber:         8,verse:"下流人在世人中升高，就有恶人到处游行。"},
{volumn:        19,chapter:        13,versenumber:         1,verse:"耶和华啊，你忘记我要到几时呢？要到永远吗？你掩面不顾我要到几时呢？"},
{volumn:        19,chapter:        13,versenumber:         2,verse:"我心里筹算，终日愁苦，要到几时呢？我的仇敌升高压制我，要到几时呢？"},
{volumn:        19,chapter:        13,versenumber:         3,verse:"耶和华我的　神啊，求你看顾我，应允我！使我眼目光明，免得我沉睡至死；"},
{volumn:        19,chapter:        13,versenumber:         4,verse:"免得我的仇敌说：“我胜了他”；免得我的敌人在我摇动的时候喜乐。"},
{volumn:        19,chapter:        13,versenumber:         5,verse:"但我倚靠你的慈爱；我的心因你的救恩快乐。"},
{volumn:        19,chapter:        13,versenumber:         6,verse:"我要向耶和华歌唱，因他用厚恩待我。"},
{volumn:        19,chapter:        14,versenumber:         1,verse:"愚顽人心里说：“没有　神。”他们都是邪恶，行了可憎恶的事；没有一个人行善。"},
{volumn:        19,chapter:        14,versenumber:         2,verse:"耶和华从天上垂看世人，要看有明白的没有，有寻求　神的没有。"},
{volumn:        19,chapter:        14,versenumber:         3,verse:"他们都偏离正路，一同变为污秽；并没有行善的，连一个也没有。"},
{volumn:        19,chapter:        14,versenumber:         4,verse:"作孽的都没有知识吗？他们吞吃我的百姓，如同吃饭一样，并不求告耶和华。"},
{volumn:        19,chapter:        14,versenumber:         5,verse:"他们在那里大大地害怕，因为　神在义人的族类中。"},
{volumn:        19,chapter:        14,versenumber:         6,verse:"你们叫困苦人的谋算变为羞辱；然而耶和华是他的避难所。"},
{volumn:        19,chapter:        14,versenumber:         7,verse:"但愿以色列的救恩从锡安而出，耶和华救回他被掳的子民。那时，雅各要快乐，以色列要欢喜。"},
{volumn:        19,chapter:        15,versenumber:         1,verse:"耶和华啊，谁能寄居你的帐幕？谁能住在你的圣山？"},
{volumn:        19,chapter:        15,versenumber:         2,verse:"就是行为正直、做事公义、心里说实话的人。"},
{volumn:        19,chapter:        15,versenumber:         3,verse:"他不以舌头谗谤人，不恶待朋友，也不随伙毁谤邻里。"},
{volumn:        19,chapter:        15,versenumber:         4,verse:"他眼中藐视匪类，却尊重那敬畏耶和华的人。他发了誓，虽然自己吃亏也不更改。"},
{volumn:        19,chapter:        15,versenumber:         5,verse:"他不放债取利，不受贿赂以害无辜。行这些事的人必永不动摇。"},
{volumn:        19,chapter:        16,versenumber:         1,verse:"神啊，求你保佑我，因为我投靠你。"},
{volumn:        19,chapter:        16,versenumber:         2,verse:"我的心哪，你曾对耶和华说：“你是我的主，我的好处不在你以外。”"},
{volumn:        19,chapter:        16,versenumber:         3,verse:"论到世上的圣民，他们又美又善，是我最喜悦的。"},
{volumn:        19,chapter:        16,versenumber:         4,verse:"以别神代替耶和华的（或译：送礼物给别神的），他们的愁苦必加增；他们所浇奠的血我不献上；我嘴唇也不提别神的名号。"},
{volumn:        19,chapter:        16,versenumber:         5,verse:"耶和华是我的产业，是我杯中的份；我所得的，你为我持守。"},
{volumn:        19,chapter:        16,versenumber:         6,verse:"用绳量给我的地界，坐落在佳美之处；我的产业实在美好。"},
{volumn:        19,chapter:        16,versenumber:         7,verse:"我必称颂那指教我的耶和华；我的心肠在夜间也警戒我。"},
{volumn:        19,chapter:        16,versenumber:         8,verse:"我将耶和华常摆在我面前，因他在我右边，我便不致摇动。"},
{volumn:        19,chapter:        16,versenumber:         9,verse:"因此，我的心欢喜，我的灵（原文是荣耀）快乐；我的肉身也要安然居住。"},
{volumn:        19,chapter:        16,versenumber:        10,verse:"因为你必不将我的灵魂撇在阴间，也不叫你的圣者见朽坏。"},
{volumn:        19,chapter:        16,versenumber:        11,verse:"你必将生命的道路指示我。在你面前有满足的喜乐；在你右手中有永远的福乐。"},
{volumn:        19,chapter:        17,versenumber:         1,verse:"耶和华啊，求你听闻公义，侧耳听我的呼吁；求你留心听我这不出于诡诈嘴唇的祈祷！"},
{volumn:        19,chapter:        17,versenumber:         2,verse:"愿我的判语从你面前发出；愿你的眼睛观看公正。"},
{volumn:        19,chapter:        17,versenumber:         3,verse:"你已经试验我的心；你在夜间鉴察我；你熬炼我，却找不着什么；我立志叫我口中没有过失。"},
{volumn:        19,chapter:        17,versenumber:         4,verse:"论到人的行为，我藉着你嘴唇的言语自己谨守，不行强暴人的道路。"},
{volumn:        19,chapter:        17,versenumber:         5,verse:"我的脚踏定了你的路径；我的两脚未曾滑跌。"},
{volumn:        19,chapter:        17,versenumber:         6,verse:"神啊，我曾求告你，因为你必应允我；求你向我侧耳，听我的言语。"},
{volumn:        19,chapter:        17,versenumber:         7,verse:"求你显出你奇妙的慈爱来；你是那用右手拯救投靠你的，脱离起来攻击他们的人。"},
{volumn:        19,chapter:        17,versenumber:         8,verse:"求你保护我，如同保护眼中的瞳人；将我隐藏在你翅膀的荫下，"},
{volumn:        19,chapter:        17,versenumber:         9,verse:"使我脱离那欺压我的恶人，就是围困我要害我命的仇敌。"},
{volumn:        19,chapter:        17,versenumber:        10,verse:"他们的心被脂油包裹；他们用口说骄傲的话。"},
{volumn:        19,chapter:        17,versenumber:        11,verse:"他们围困了我们的脚步；他们瞪着眼，要把我们推倒在地。"},
{volumn:        19,chapter:        17,versenumber:        12,verse:"他像狮子急要抓食，又像少壮狮子蹲伏在暗处。"},
{volumn:        19,chapter:        17,versenumber:        13,verse:"耶和华啊，求你起来，前去迎敌，将他打倒！用你的刀救护我命脱离恶人。"},
{volumn:        19,chapter:        17,versenumber:        14,verse:"耶和华啊，求你用手救我脱离世人，脱离那只在今生有福分的世人！你把你的财宝充满他们的肚腹；他们因有儿女就心满意足，将其余的财物留给他们的婴孩。"},
{volumn:        19,chapter:        17,versenumber:        15,verse:"至于我，我必在义中见你的面；我醒了的时候，得见（或译：着）你的形像就心满意足了。"},
{volumn:        19,chapter:        18,versenumber:         1,verse:"耶和华，我的力量啊，我爱你！"},
{volumn:        19,chapter:        18,versenumber:         2,verse:"耶和华是我的岩石，我的山寨，我的救主，我的　神，我的磐石，我所投靠的。他是我的盾牌，是拯救我的角，是我的高台。"},
{volumn:        19,chapter:        18,versenumber:         3,verse:"我要求告当赞美的耶和华；这样我必从仇敌手中被救出来。"},
{volumn:        19,chapter:        18,versenumber:         4,verse:"曾有死亡的绳索缠绕我，匪类的急流使我惊惧，"},
{volumn:        19,chapter:        18,versenumber:         5,verse:"阴间的绳索缠绕我，死亡的网罗临到我。"},
{volumn:        19,chapter:        18,versenumber:         6,verse:"我在急难中求告耶和华，向我的　神呼求。他从殿中听了我的声音；我在他面前的呼求入了他的耳中。"},
{volumn:        19,chapter:        18,versenumber:         7,verse:"那时，因他发怒，地就摇撼战抖；山的根基也震动摇撼。"},
{volumn:        19,chapter:        18,versenumber:         8,verse:"从他鼻孔冒烟上腾；从他口中发火焚烧，连炭也着了。"},
{volumn:        19,chapter:        18,versenumber:         9,verse:"他又使天下垂，亲自降临，有黑云在他脚下。"},
{volumn:        19,chapter:        18,versenumber:        10,verse:"他坐着基路伯飞行；他藉着风的翅膀快飞。"},
{volumn:        19,chapter:        18,versenumber:        11,verse:"他以黑暗为藏身之处，以水的黑暗、天空的厚云为他四围的行宫。"},
{volumn:        19,chapter:        18,versenumber:        12,verse:"因他面前的光辉，他的厚云行过便有冰雹火炭。"},
{volumn:        19,chapter:        18,versenumber:        13,verse:"耶和华也在天上打雷；至高者发出声音便有冰雹火炭。"},
{volumn:        19,chapter:        18,versenumber:        14,verse:"他射出箭来，使仇敌四散；多多发出闪电，使他们扰乱。"},
{volumn:        19,chapter:        18,versenumber:        15,verse:"耶和华啊，你的斥责一发，你鼻孔的气一出，海底就出现，大地的根基也显露。"},
{volumn:        19,chapter:        18,versenumber:        16,verse:"他从高天伸手抓住我，把我从大水中拉上来。"},
{volumn:        19,chapter:        18,versenumber:        17,verse:"他救我脱离我的劲敌和那些恨我的人，因为他们比我强盛。"},
{volumn:        19,chapter:        18,versenumber:        18,verse:"我遭遇灾难的日子，他们来攻击我；但耶和华是我的倚靠。"},
{volumn:        19,chapter:        18,versenumber:        19,verse:"他又领我到宽阔之处；他救拔我，因他喜悦我。"},
{volumn:        19,chapter:        18,versenumber:        20,verse:"耶和华按着我的公义报答我，按着我手中的清洁赏赐我。"},
{volumn:        19,chapter:        18,versenumber:        21,verse:"因为我遵守了耶和华的道，未曾作恶离开我的　神。"},
{volumn:        19,chapter:        18,versenumber:        22,verse:"他的一切典章常在我面前；他的律例我也未曾丢弃。"},
{volumn:        19,chapter:        18,versenumber:        23,verse:"我在他面前作了完全人；我也保守自己远离我的罪孽。"},
{volumn:        19,chapter:        18,versenumber:        24,verse:"所以，耶和华按我的公义，按我在他眼前手中的清洁偿还我。"},
{volumn:        19,chapter:        18,versenumber:        25,verse:"慈爱的人，你以慈爱待他；完全的人，你以完全待他。"},
{volumn:        19,chapter:        18,versenumber:        26,verse:"清洁的人，你以清洁待他；乖僻的人，你以弯曲待他。"},
{volumn:        19,chapter:        18,versenumber:        27,verse:"困苦的百姓，你必拯救；高傲的眼目，你必使他降卑。"},
{volumn:        19,chapter:        18,versenumber:        28,verse:"你必点着我的灯；耶和华我的　神必照明我的黑暗。"},
{volumn:        19,chapter:        18,versenumber:        29,verse:"我藉着你冲入敌军，藉着我的　神跳过墙垣。"},
{volumn:        19,chapter:        18,versenumber:        30,verse:"至于　神，他的道是完全的；耶和华的话是炼净的。凡投靠他的，他便作他们的盾牌。"},
{volumn:        19,chapter:        18,versenumber:        31,verse:"除了耶和华，谁是　神呢？除了我们的　神，谁是磐石呢？"},
{volumn:        19,chapter:        18,versenumber:        32,verse:"惟有那以力量束我的腰、使我行为完全的，他是　神。"},
{volumn:        19,chapter:        18,versenumber:        33,verse:"他使我的脚快如母鹿的蹄，又使我在高处安稳。"},
{volumn:        19,chapter:        18,versenumber:        34,verse:"他教导我的手能以争战，甚至我的膀臂能开铜弓。"},
{volumn:        19,chapter:        18,versenumber:        35,verse:"你把你的救恩给我作盾牌；你的右手扶持我；你的温和使我为大。"},
{volumn:        19,chapter:        18,versenumber:        36,verse:"你使我脚下的地步宽阔；我的脚未曾滑跌。"},
{volumn:        19,chapter:        18,versenumber:        37,verse:"我要追赶我的仇敌，并要追上他们；不将他们灭绝，我总不归回。"},
{volumn:        19,chapter:        18,versenumber:        38,verse:"我要打伤他们，使他们不能起来；他们必倒在我的脚下。"},
{volumn:        19,chapter:        18,versenumber:        39,verse:"因为你曾以力量束我的腰，使我能争战；你也使那起来攻击我的都服在我以下。"},
{volumn:        19,chapter:        18,versenumber:        40,verse:"你又使我的仇敌在我面前转背逃跑，叫我能以剪除那恨我的人。"},
{volumn:        19,chapter:        18,versenumber:        41,verse:"他们呼求，却无人拯救；就是呼求耶和华，他也不应允。"},
{volumn:        19,chapter:        18,versenumber:        42,verse:"我捣碎他们，如同风前的灰尘，倒出他们，如同街上的泥土。"},
{volumn:        19,chapter:        18,versenumber:        43,verse:"你救我脱离百姓的争竞，立我作列国的元首；我素不认识的民必侍奉我。"},
{volumn:        19,chapter:        18,versenumber:        44,verse:"他们一听见我的名声就必顺从我；外邦人要投降我。"},
{volumn:        19,chapter:        18,versenumber:        45,verse:"外邦人要衰残，战战兢兢地出他们的营寨。"},
{volumn:        19,chapter:        18,versenumber:        46,verse:"耶和华是活　神。愿我的磐石被人称颂；愿救我的　神被人尊崇。"},
{volumn:        19,chapter:        18,versenumber:        47,verse:"这位　神，就是那为我伸冤、使众民服在我以下的。"},
{volumn:        19,chapter:        18,versenumber:        48,verse:"你救我脱离仇敌，又把我举起，高过那些起来攻击我的；你救我脱离强暴的人。"},
{volumn:        19,chapter:        18,versenumber:        49,verse:"耶和华啊，因此我要在外邦中称谢你，歌颂你的名。"},
{volumn:        19,chapter:        18,versenumber:        50,verse:"耶和华赐极大的救恩给他所立的王，施慈爱给他的受膏者，就是给大卫和他的后裔，直到永远。"},
{volumn:        19,chapter:        19,versenumber:         1,verse:"诸天述说　神的荣耀；穹苍传扬他的手段。"},
{volumn:        19,chapter:        19,versenumber:         2,verse:"这日到那日发出言语；这夜到那夜传出知识。"},
{volumn:        19,chapter:        19,versenumber:         3,verse:"无言无语，也无声音可听。"},
{volumn:        19,chapter:        19,versenumber:         4,verse:"他的量带通遍天下，他的言语传到地极。　神在其间为太阳安设帐幕；"},
{volumn:        19,chapter:        19,versenumber:         5,verse:"太阳如同新郎出洞房，又如勇士欢然奔路。"},
{volumn:        19,chapter:        19,versenumber:         6,verse:"它从天这边出来，绕到天那边，没有一物被隐藏不得它的热气。"},
{volumn:        19,chapter:        19,versenumber:         7,verse:"耶和华的律法全备，能苏醒人心；耶和华的法度确定，能使愚人有智慧。"},
{volumn:        19,chapter:        19,versenumber:         8,verse:"耶和华的训词正直，能快活人的心；耶和华的命令清洁，能明亮人的眼目。"},
{volumn:        19,chapter:        19,versenumber:         9,verse:"耶和华的道理洁净，存到永远；耶和华的典章真实，全然公义。"},
{volumn:        19,chapter:        19,versenumber:        10,verse:"都比金子可羡慕，且比极多的精金可羡慕；比蜜甘甜，且比蜂房下滴的蜜甘甜。"},
{volumn:        19,chapter:        19,versenumber:        11,verse:"况且你的仆人因此受警戒，守着这些便有大赏。"},
{volumn:        19,chapter:        19,versenumber:        12,verse:"谁能知道自己的错失呢？愿你赦免我隐而未现的过错。"},
{volumn:        19,chapter:        19,versenumber:        13,verse:"求你拦阻仆人不犯任意妄为的罪，不容这罪辖制我，我便完全，免犯大罪。"},
{volumn:        19,chapter:        19,versenumber:        14,verse:"耶和华我的磐石，我的救赎主啊，愿我口中的言语、心里的意念在你面前蒙悦纳。"},
{volumn:        19,chapter:        20,versenumber:         1,verse:"愿耶和华在你遭难的日子应允你；愿名为雅各　神的高举你。"},
{volumn:        19,chapter:        20,versenumber:         2,verse:"愿他从圣所救助你，从锡安坚固你，"},
{volumn:        19,chapter:        20,versenumber:         3,verse:"记念你的一切供献，悦纳你的燔祭，（细拉）"},
{volumn:        19,chapter:        20,versenumber:         4,verse:"将你心所愿的赐给你，成就你的一切筹算。"},
{volumn:        19,chapter:        20,versenumber:         5,verse:"我们要因你的救恩夸胜，要奉我们　神的名竖立旌旗。愿耶和华成就你一切所求的！"},
{volumn:        19,chapter:        20,versenumber:         6,verse:"现在我知道耶和华救护他的受膏者，必从他的圣天上应允他，用右手的能力救护他。"},
{volumn:        19,chapter:        20,versenumber:         7,verse:"有人靠车，有人靠马，但我们要提到耶和华我们　神的名。"},
{volumn:        19,chapter:        20,versenumber:         8,verse:"他们都屈身仆倒，我们却起来，立得正直。"},
{volumn:        19,chapter:        20,versenumber:         9,verse:"求耶和华施行拯救；我们呼求的时候，愿王应允我们！"},
{volumn:        19,chapter:        21,versenumber:         1,verse:"耶和华啊，王必因你的能力欢喜；因你的救恩，他的快乐何其大！"},
{volumn:        19,chapter:        21,versenumber:         2,verse:"他心里所愿的，你已经赐给他；他嘴唇所求的，你未尝不应允。（细拉）"},
{volumn:        19,chapter:        21,versenumber:         3,verse:"你以美福迎接他，把精金的冠冕戴在他头上。"},
{volumn:        19,chapter:        21,versenumber:         4,verse:"他向你求寿，你便赐给他，就是日子长久，直到永远。"},
{volumn:        19,chapter:        21,versenumber:         5,verse:"他因你的救恩大有荣耀；你又将尊荣威严加在他身上。"},
{volumn:        19,chapter:        21,versenumber:         6,verse:"你使他有洪福，直到永远，又使他在你面前欢喜快乐。"},
{volumn:        19,chapter:        21,versenumber:         7,verse:"王倚靠耶和华，因至高者的慈爱必不摇动。"},
{volumn:        19,chapter:        21,versenumber:         8,verse:"你的手要搜出你的一切仇敌；你的右手要搜出那些恨你的人。"},
{volumn:        19,chapter:        21,versenumber:         9,verse:"你发怒的时候，要使他们如在炎热的火炉中。耶和华要在他的震怒中吞灭他们；那火要把他们烧尽了。"},
{volumn:        19,chapter:        21,versenumber:        10,verse:"你必从世上灭绝他们的子孙（原文是果子），从人间灭绝他们的后裔。"},
{volumn:        19,chapter:        21,versenumber:        11,verse:"因为他们有意加害于你；他们想出计谋，却不能作成。"},
{volumn:        19,chapter:        21,versenumber:        12,verse:"你必使他们转背逃跑，向他们的脸搭箭在弦。"},
{volumn:        19,chapter:        21,versenumber:        13,verse:"耶和华啊，愿你因自己的能力显为至高！这样，我们就唱诗，歌颂你的大能。"},
{volumn:        19,chapter:        22,versenumber:         1,verse:"我的　神，我的　神！为什么离弃我？为什么远离不救我？不听我唉哼的言语？"},
{volumn:        19,chapter:        22,versenumber:         2,verse:"我的　神啊，我白日呼求，你不应允，夜间呼求，并不住声。"},
{volumn:        19,chapter:        22,versenumber:         3,verse:"但你是圣洁的，是用以色列的赞美为宝座（或译：居所）的。"},
{volumn:        19,chapter:        22,versenumber:         4,verse:"我们的祖宗倚靠你；他们倚靠你，你便解救他们。"},
{volumn:        19,chapter:        22,versenumber:         5,verse:"他们哀求你，便蒙解救；他们倚靠你，就不羞愧。"},
{volumn:        19,chapter:        22,versenumber:         6,verse:"但我是虫，不是人，被众人羞辱，被百姓藐视。"},
{volumn:        19,chapter:        22,versenumber:         7,verse:"凡看见我的都嗤笑我；他们撇嘴摇头，说："},
{volumn:        19,chapter:        22,versenumber:         8,verse:"“他把自己交托耶和华，耶和华可以救他吧！耶和华既喜悦他，可以搭救他吧！”"},
{volumn:        19,chapter:        22,versenumber:         9,verse:"但你是叫我出母腹的；我在母怀里，你就使我有倚靠的心。"},
{volumn:        19,chapter:        22,versenumber:        10,verse:"我自出母胎就被交在你手里；从我母亲生我，你就是我的　神。"},
{volumn:        19,chapter:        22,versenumber:        11,verse:"求你不要远离我！因为急难临近了，没有人帮助我。"},
{volumn:        19,chapter:        22,versenumber:        12,verse:"有许多公牛围绕我，巴珊大力的公牛四面困住我。"},
{volumn:        19,chapter:        22,versenumber:        13,verse:"它们向我张口，好像抓撕吼叫的狮子。"},
{volumn:        19,chapter:        22,versenumber:        14,verse:"我如水被倒出来；我的骨头都脱了节；我心在我里面如蜡熔化。"},
{volumn:        19,chapter:        22,versenumber:        15,verse:"我的精力枯干，如同瓦片；我的舌头贴在我牙床上。你将我安置在死地的尘土中。"},
{volumn:        19,chapter:        22,versenumber:        16,verse:"犬类围着我，恶党环绕我；他们扎了我的手，我的脚。"},
{volumn:        19,chapter:        22,versenumber:        17,verse:"我的骨头，我都能数过；他们瞪着眼看我。"},
{volumn:        19,chapter:        22,versenumber:        18,verse:"他们分我的外衣，为我的里衣拈阄。"},
{volumn:        19,chapter:        22,versenumber:        19,verse:"耶和华啊，求你不要远离我！我的救主啊，求你快来帮助我！"},
{volumn:        19,chapter:        22,versenumber:        20,verse:"求你救我的灵魂脱离刀剑，救我的生命（生命：原文是独一者）脱离犬类，"},
{volumn:        19,chapter:        22,versenumber:        21,verse:"救我脱离狮子的口；你已经应允我，使我脱离野牛的角。"},
{volumn:        19,chapter:        22,versenumber:        22,verse:"我要将你的名传与我的弟兄，在会中我要赞美你。"},
{volumn:        19,chapter:        22,versenumber:        23,verse:"你们敬畏耶和华的人要赞美他！雅各的后裔都要荣耀他！以色列的后裔都要惧怕他！"},
{volumn:        19,chapter:        22,versenumber:        24,verse:"因为他没有藐视憎恶受苦的人，也没有向他掩面；那受苦之人呼吁的时候，他就垂听。"},
{volumn:        19,chapter:        22,versenumber:        25,verse:"我在大会中赞美你的话是从你而来的；我要在敬畏耶和华的人面前还我的愿。"},
{volumn:        19,chapter:        22,versenumber:        26,verse:"谦卑的人必吃得饱足；寻求耶和华的人必赞美他。愿你们的心永远活着！"},
{volumn:        19,chapter:        22,versenumber:        27,verse:"地的四极都要想念耶和华，并且归顺他；列国的万族都要在你面前敬拜。"},
{volumn:        19,chapter:        22,versenumber:        28,verse:"因为国权是耶和华的；他是管理万国的。"},
{volumn:        19,chapter:        22,versenumber:        29,verse:"地上一切丰肥的人必吃喝而敬拜；凡下到尘土中不能存活自己性命的人，都要在他面前下拜。"},
{volumn:        19,chapter:        22,versenumber:        30,verse:"他必有后裔侍奉他；主所行的事必传与后代。"},
{volumn:        19,chapter:        22,versenumber:        31,verse:"他们必来把他的公义传给将要生的民，言明这事是他所行的。"},
{volumn:        19,chapter:        23,versenumber:         1,verse:"耶和华是我的牧者，我必不至缺乏。"},
{volumn:        19,chapter:        23,versenumber:         2,verse:"他使我躺卧在青草地上，领我在可安歇的水边。"},
{volumn:        19,chapter:        23,versenumber:         3,verse:"他使我的灵魂苏醒，为自己的名引导我走义路。"},
{volumn:        19,chapter:        23,versenumber:         4,verse:"我虽然行过死荫的幽谷，也不怕遭害，因为你与我同在；你的杖，你的竿，都安慰我。"},
{volumn:        19,chapter:        23,versenumber:         5,verse:"在我敌人面前，你为我摆设筵席；你用油膏了我的头，使我的福杯满溢。"},
{volumn:        19,chapter:        23,versenumber:         6,verse:"我一生一世必有恩惠慈爱随着我；我且要住在耶和华的殿中，直到永远。"},
{volumn:        19,chapter:        24,versenumber:         1,verse:"地和其中所充满的，世界和住在其间的，都属耶和华。"},
{volumn:        19,chapter:        24,versenumber:         2,verse:"他把地建立在海上，安定在大水之上。"},
{volumn:        19,chapter:        24,versenumber:         3,verse:"谁能登耶和华的山？谁能站在他的圣所？"},
{volumn:        19,chapter:        24,versenumber:         4,verse:"就是手洁心清，不向虚妄，起誓不怀诡诈的人。"},
{volumn:        19,chapter:        24,versenumber:         5,verse:"他必蒙耶和华赐福，又蒙救他的　神使他成义。"},
{volumn:        19,chapter:        24,versenumber:         6,verse:"这是寻求耶和华的族类，是寻求你面的雅各。（细拉）"},
{volumn:        19,chapter:        24,versenumber:         7,verse:"众城门哪，你们要抬起头来！永久的门户，你们要被举起！那荣耀的王将要进来！"},
{volumn:        19,chapter:        24,versenumber:         8,verse:"荣耀的王是谁呢？就是有力有能的耶和华，在战场上有能的耶和华！"},
{volumn:        19,chapter:        24,versenumber:         9,verse:"众城门哪，你们要抬起头来！永久的门户，你们要把头抬起！那荣耀的王将要进来！"},
{volumn:        19,chapter:        24,versenumber:        10,verse:"荣耀的王是谁呢？万军之耶和华，他是荣耀的王！（细拉）"},
{volumn:        19,chapter:        25,versenumber:         1,verse:"耶和华啊，我的心仰望你。"},
{volumn:        19,chapter:        25,versenumber:         2,verse:"我的　神啊，我素来倚靠你；求你不要叫我羞愧，不要叫我的仇敌向我夸胜。"},
{volumn:        19,chapter:        25,versenumber:         3,verse:"凡等候你的必不羞愧；惟有那无故行奸诈的必要羞愧。"},
{volumn:        19,chapter:        25,versenumber:         4,verse:"耶和华啊，求你将你的道指示我，将你的路教训我！"},
{volumn:        19,chapter:        25,versenumber:         5,verse:"求你以你的真理引导我，教训我，因为你是救我的　神。我终日等候你。"},
{volumn:        19,chapter:        25,versenumber:         6,verse:"耶和华啊，求你记念你的怜悯和慈爱，因为这是亘古以来所常有的。"},
{volumn:        19,chapter:        25,versenumber:         7,verse:"求你不要记念我幼年的罪愆和我的过犯；耶和华啊，求你因你的恩惠，按你的慈爱记念我。"},
{volumn:        19,chapter:        25,versenumber:         8,verse:"耶和华是良善正直的，所以他必指示罪人走正路。"},
{volumn:        19,chapter:        25,versenumber:         9,verse:"他必按公平引领谦卑人，将他的道教训他们。"},
{volumn:        19,chapter:        25,versenumber:        10,verse:"凡遵守他的约和他法度的人，耶和华都以慈爱诚实待他。"},
{volumn:        19,chapter:        25,versenumber:        11,verse:"耶和华啊，求你因你的名赦免我的罪，因为我的罪重大。"},
{volumn:        19,chapter:        25,versenumber:        12,verse:"谁敬畏耶和华，耶和华必指示他当选择的道路。"},
{volumn:        19,chapter:        25,versenumber:        13,verse:"他必安然居住；他的后裔必承受地土。"},
{volumn:        19,chapter:        25,versenumber:        14,verse:"耶和华与敬畏他的人亲密；他必将自己的约指示他们。"},
{volumn:        19,chapter:        25,versenumber:        15,verse:"我的眼目时常仰望耶和华，因为他必将我的脚从网里拉出来。"},
{volumn:        19,chapter:        25,versenumber:        16,verse:"求你转向我，怜恤我，因为我是孤独困苦。"},
{volumn:        19,chapter:        25,versenumber:        17,verse:"我心里的愁苦甚多，求你救我脱离我的祸患。"},
{volumn:        19,chapter:        25,versenumber:        18,verse:"求你看顾我的困苦，我的艰难，赦免我一切的罪。"},
{volumn:        19,chapter:        25,versenumber:        19,verse:"求你察看我的仇敌，因为他们人多，并且痛痛地恨我。"},
{volumn:        19,chapter:        25,versenumber:        20,verse:"求你保护我的性命，搭救我，使我不致羞愧，因为我投靠你。"},
{volumn:        19,chapter:        25,versenumber:        21,verse:"愿纯全、正直保守我，因为我等候你。"},
{volumn:        19,chapter:        25,versenumber:        22,verse:"神啊，求你救赎以色列脱离他一切的愁苦。"},
{volumn:        19,chapter:        26,versenumber:         1,verse:"耶和华啊，求你为我伸冤，因我向来行事纯全；我又倚靠耶和华，并不摇动。"},
{volumn:        19,chapter:        26,versenumber:         2,verse:"耶和华啊，求你察看我，试验我，熬炼我的肺腑心肠。"},
{volumn:        19,chapter:        26,versenumber:         3,verse:"因为你的慈爱常在我眼前，我也按你的真理而行。"},
{volumn:        19,chapter:        26,versenumber:         4,verse:"我没有和虚谎人同坐，也不与瞒哄人的同群。"},
{volumn:        19,chapter:        26,versenumber:         5,verse:"我恨恶恶人的会，必不与恶人同坐。"},
{volumn:        19,chapter:        26,versenumber:         6,verse:"耶和华啊，我要洗手表明无辜，才环绕你的祭坛；"},
{volumn:        19,chapter:        26,versenumber:         7,verse:"我好发称谢的声音，也要述说你一切奇妙的作为。"},
{volumn:        19,chapter:        26,versenumber:         8,verse:"耶和华啊，我喜爱你所住的殿和你显荣耀的居所。"},
{volumn:        19,chapter:        26,versenumber:         9,verse:"不要把我的灵魂和罪人一同除掉；不要把我的性命和流人血的一同除掉。"},
{volumn:        19,chapter:        26,versenumber:        10,verse:"他们的手中有奸恶，右手满有贿赂。"},
{volumn:        19,chapter:        26,versenumber:        11,verse:"至于我，却要行事纯全；求你救赎我，怜恤我！"},
{volumn:        19,chapter:        26,versenumber:        12,verse:"我的脚站在平坦地方；在众会中我要称颂耶和华！"},
{volumn:        19,chapter:        27,versenumber:         1,verse:"耶和华是我的亮光，是我的拯救，我还怕谁呢？耶和华是我性命的保障（或译：力量），我还惧谁呢？"},
{volumn:        19,chapter:        27,versenumber:         2,verse:"那作恶的就是我的仇敌，前来吃我肉的时候就绊跌仆倒。"},
{volumn:        19,chapter:        27,versenumber:         3,verse:"虽有军兵安营攻击我，我的心也不害怕；虽然兴起刀兵攻击我，我必仍旧安稳。"},
{volumn:        19,chapter:        27,versenumber:         4,verse:"有一件事，我曾求耶和华，我仍要寻求：就是一生一世住在耶和华的殿中，瞻仰他的荣美，在他的殿里求问。"},
{volumn:        19,chapter:        27,versenumber:         5,verse:"因为我遭遇患难，他必暗暗地保守我；在他亭子里，把我藏在他帐幕的隐密处，将我高举在磐石上。"},
{volumn:        19,chapter:        27,versenumber:         6,verse:"现在我得以昂首，高过四面的仇敌。我要在他的帐幕里欢然献祭；我要唱诗歌颂耶和华。"},
{volumn:        19,chapter:        27,versenumber:         7,verse:"耶和华啊，我用声音呼吁的时候，求你垂听；并求你怜恤我，应允我。"},
{volumn:        19,chapter:        27,versenumber:         8,verse:"你说：“你们当寻求我的面。”那时我心向你说：“耶和华啊，你的面我正要寻求。”"},
{volumn:        19,chapter:        27,versenumber:         9,verse:"不要向我掩面。不要发怒赶逐仆人，你向来是帮助我的。救我的　神啊，不要丢掉我，也不要离弃我。"},
{volumn:        19,chapter:        27,versenumber:        10,verse:"我父母离弃我，耶和华必收留我。"},
{volumn:        19,chapter:        27,versenumber:        11,verse:"耶和华啊，求你将你的道指教我，因我仇敌的缘故引导我走平坦的路。"},
{volumn:        19,chapter:        27,versenumber:        12,verse:"求你不要把我交给敌人，遂其所愿；因为妄作见证的和口吐凶言的，起来攻击我。"},
{volumn:        19,chapter:        27,versenumber:        13,verse:"我若不信在活人之地得见耶和华的恩惠，就早已丧胆了。"},
{volumn:        19,chapter:        27,versenumber:        14,verse:"要等候耶和华！当壮胆，坚固你的心！我再说，要等候耶和华！"},
{volumn:        19,chapter:        28,versenumber:         1,verse:"耶和华啊，我要求告你！我的磐石啊，不要向我缄默！倘若你向我闭口，我就如将死的人一样。"},
{volumn:        19,chapter:        28,versenumber:         2,verse:"我呼求你，向你至圣所举手的时候，求你垂听我恳求的声音！"},
{volumn:        19,chapter:        28,versenumber:         3,verse:"不要把我和恶人并作孽的一同除掉；他们与邻舍说和平话，心里却是奸恶。"},
{volumn:        19,chapter:        28,versenumber:         4,verse:"愿你按着他们所做的，并他们所行的恶事待他们。愿你照着他们手所做的待他们，将他们所应得的报应加给他们。"},
{volumn:        19,chapter:        28,versenumber:         5,verse:"他们既然不留心耶和华所行的和他手所做的，他就必毁坏他们，不建立他们。"},
{volumn:        19,chapter:        28,versenumber:         6,verse:"耶和华是应当称颂的，因为他听了我恳求的声音。"},
{volumn:        19,chapter:        28,versenumber:         7,verse:"耶和华是我的力量，是我的盾牌；我心里倚靠他就得帮助。所以我心中欢乐，我必用诗歌颂赞他。"},
{volumn:        19,chapter:        28,versenumber:         8,verse:"耶和华是他百姓的力量，又是他受膏者得救的保障。"},
{volumn:        19,chapter:        28,versenumber:         9,verse:"求你拯救你的百姓，赐福给你的产业，牧养他们，扶持他们，直到永远。"},
{volumn:        19,chapter:        29,versenumber:         1,verse:"神的众子啊，你们要将荣耀、能力归给耶和华，归给耶和华！"},
{volumn:        19,chapter:        29,versenumber:         2,verse:"要将耶和华的名所当得的荣耀归给他，以圣洁的（的：或译为）妆饰敬拜耶和华。"},
{volumn:        19,chapter:        29,versenumber:         3,verse:"耶和华的声音发在水上；荣耀的　神打雷，耶和华打雷在大水之上。"},
{volumn:        19,chapter:        29,versenumber:         4,verse:"耶和华的声音大有能力；耶和华的声音满有威严。"},
{volumn:        19,chapter:        29,versenumber:         5,verse:"耶和华的声音震破香柏树；耶和华震碎黎巴嫩的香柏树。"},
{volumn:        19,chapter:        29,versenumber:         6,verse:"他也使之跳跃如牛犊，使黎巴嫩和西连跳跃如野牛犊。"},
{volumn:        19,chapter:        29,versenumber:         7,verse:"耶和华的声音使火焰分岔。"},
{volumn:        19,chapter:        29,versenumber:         8,verse:"耶和华的声音震动旷野；耶和华震动加低斯的旷野。"},
{volumn:        19,chapter:        29,versenumber:         9,verse:"耶和华的声音惊动母鹿落胎，树木也脱落净光。凡在他殿中的，都称说他的荣耀。"},
{volumn:        19,chapter:        29,versenumber:        10,verse:"洪水泛滥之时，耶和华坐着为王；耶和华坐着为王，直到永远。"},
{volumn:        19,chapter:        29,versenumber:        11,verse:"耶和华必赐力量给他的百姓；耶和华必赐平安的福给他的百姓。"},
{volumn:        19,chapter:        30,versenumber:         1,verse:"耶和华啊，我要尊崇你，因为你曾提拔我，不叫仇敌向我夸耀。"},
{volumn:        19,chapter:        30,versenumber:         2,verse:"耶和华我的　神啊，我曾呼求你，你医治了我。"},
{volumn:        19,chapter:        30,versenumber:         3,verse:"耶和华啊，你曾把我的灵魂从阴间救上来，使我存活，不至于下坑。"},
{volumn:        19,chapter:        30,versenumber:         4,verse:"耶和华的圣民哪，你们要歌颂他，称赞他可记念的圣名。"},
{volumn:        19,chapter:        30,versenumber:         5,verse:"因为，他的怒气不过是转眼之间；他的恩典乃是一生之久。一宿虽然有哭泣，早晨便必欢呼。"},
{volumn:        19,chapter:        30,versenumber:         6,verse:"至于我，我凡事平顺，便说：“我永不动摇。”"},
{volumn:        19,chapter:        30,versenumber:         7,verse:"耶和华啊，你曾施恩，叫我的江山稳固；你掩了面，我就惊惶。"},
{volumn:        19,chapter:        30,versenumber:         8,verse:"耶和华啊，我曾求告你；我向耶和华恳求，说："},
{volumn:        19,chapter:        30,versenumber:         9,verse:"“我被害流血，下到坑中，有什么益处呢？尘土岂能称赞你，传说你的诚实吗？"},
{volumn:        19,chapter:        30,versenumber:        10,verse:"耶和华啊，求你应允我，怜恤我！耶和华啊，求你帮助我！”"},
{volumn:        19,chapter:        30,versenumber:        11,verse:"你已将我的哀哭变为跳舞，将我的麻衣脱去，给我披上喜乐，"},
{volumn:        19,chapter:        30,versenumber:        12,verse:"好叫我的灵（原文是荣耀）歌颂你，并不住声。耶和华我的　神啊，我要称谢你，直到永远！"},
{volumn:        19,chapter:        31,versenumber:         1,verse:"耶和华啊，我投靠你；求你使我永不羞愧；凭你的公义搭救我！"},
{volumn:        19,chapter:        31,versenumber:         2,verse:"求你侧耳而听，快快救我！作我坚固的磐石，拯救我的保障！"},
{volumn:        19,chapter:        31,versenumber:         3,verse:"因为你是我的岩石，我的山寨；所以，求你为你名的缘故引导我，指点我。"},
{volumn:        19,chapter:        31,versenumber:         4,verse:"求你救我脱离人为我暗设的网罗，因为你是我的保障。"},
{volumn:        19,chapter:        31,versenumber:         5,verse:"我将我的灵魂交在你手里；耶和华诚实的　神啊，你救赎了我。"},
{volumn:        19,chapter:        31,versenumber:         6,verse:"我恨恶那信奉虚无之　神的人；我却倚靠耶和华。"},
{volumn:        19,chapter:        31,versenumber:         7,verse:"我要为你的慈爱高兴欢喜；因为你见过我的困苦，知道我心中的艰难。"},
{volumn:        19,chapter:        31,versenumber:         8,verse:"你未曾把我交在仇敌手里；你使我的脚站在宽阔之处。"},
{volumn:        19,chapter:        31,versenumber:         9,verse:"耶和华啊，求你怜恤我，因为我在急难之中；我的眼睛因忧愁而干瘪，连我的身心也不安舒。"},
{volumn:        19,chapter:        31,versenumber:        10,verse:"我的生命为愁苦所消耗；我的年岁为叹息所旷废。我的力量因我的罪孽衰败；我的骨头也枯干。"},
{volumn:        19,chapter:        31,versenumber:        11,verse:"我因一切敌人成了羞辱，在我的邻舍跟前更甚；那认识我的都惧怕我，在外头看见我的都躲避我。"},
{volumn:        19,chapter:        31,versenumber:        12,verse:"我被人忘记，如同死人，无人记念；我好像破碎的器皿。"},
{volumn:        19,chapter:        31,versenumber:        13,verse:"我听见了许多人的谗谤，四围都是惊吓；他们一同商议攻击我的时候，就图谋要害我的性命。"},
{volumn:        19,chapter:        31,versenumber:        14,verse:"耶和华啊，我仍旧倚靠你；我说：“你是我的　神。”"},
{volumn:        19,chapter:        31,versenumber:        15,verse:"我终身的事在你手中；求你救我脱离仇敌的手和那些逼迫我的人。"},
{volumn:        19,chapter:        31,versenumber:        16,verse:"求你使你的脸光照仆人，凭你的慈爱拯救我。"},
{volumn:        19,chapter:        31,versenumber:        17,verse:"耶和华啊，求你叫我不至羞愧，因为我曾呼吁你；求你使恶人羞愧，使他们在阴间缄默无声。"},
{volumn:        19,chapter:        31,versenumber:        18,verse:"那撒谎的人逞骄傲轻慢，出狂妄的话攻击义人；愿他的嘴哑而无言。"},
{volumn:        19,chapter:        31,versenumber:        19,verse:"敬畏你、投靠你的人，你为他们所积存的，在世人面前所施行的恩惠是何等大呢！"},
{volumn:        19,chapter:        31,versenumber:        20,verse:"你必把他们藏在你面前的隐密处，免得遇见人的计谋；你必暗暗地保守他们在亭子里，免受口舌的争闹。"},
{volumn:        19,chapter:        31,versenumber:        21,verse:"耶和华是应当称颂的，因为他在坚固城里向我施展奇妙的慈爱。"},
{volumn:        19,chapter:        31,versenumber:        22,verse:"至于我，我曾急促地说：“我从你眼前被隔绝。”然而，我呼求你的时候，你仍听我恳求的声音。"},
{volumn:        19,chapter:        31,versenumber:        23,verse:"耶和华的圣民哪，你们都要爱他！耶和华保护诚实人，足足报应行事骄傲的人。"},
{volumn:        19,chapter:        31,versenumber:        24,verse:"凡仰望耶和华的人，你们都要壮胆，坚固你们的心！"},
{volumn:        19,chapter:        32,versenumber:         1,verse:"得赦免其过、遮盖其罪的，这人是有福的！"},
{volumn:        19,chapter:        32,versenumber:         2,verse:"凡心里没有诡诈，耶和华不算为有罪的，这人是有福的！"},
{volumn:        19,chapter:        32,versenumber:         3,verse:"我闭口不认罪的时候，因终日唉哼而骨头枯干。"},
{volumn:        19,chapter:        32,versenumber:         4,verse:"黑夜白日，你的手在我身上沉重；我的精力耗尽，如同夏天的干旱。（细拉）"},
{volumn:        19,chapter:        32,versenumber:         5,verse:"我向你陈明我的罪，不隐瞒我的恶。我说：“我要向耶和华承认我的过犯。”你就赦免我的罪恶。（细拉）"},
{volumn:        19,chapter:        32,versenumber:         6,verse:"为此，凡虔诚人都当趁你可寻找的时候祷告你；大水泛溢的时候，必不能到他那里。"},
{volumn:        19,chapter:        32,versenumber:         7,verse:"你是我藏身之处；你必保佑我脱离苦难，以得救的乐歌四面环绕我。（细拉）"},
{volumn:        19,chapter:        32,versenumber:         8,verse:"我要教导你，指示你当行的路；我要定睛在你身上劝戒你。"},
{volumn:        19,chapter:        32,versenumber:         9,verse:"你不可像那无知的骡马，必用嚼环辔头勒住它；不然，就不能驯服。"},
{volumn:        19,chapter:        32,versenumber:        10,verse:"恶人必多受苦楚；惟独倚靠耶和华的，必有慈爱四面环绕他。"},
{volumn:        19,chapter:        32,versenumber:        11,verse:"你们义人应当靠耶和华欢喜快乐；你们心里正直的人都当欢呼。"},
{volumn:        19,chapter:        33,versenumber:         1,verse:"义人哪，你们应当靠耶和华欢乐；正直人的赞美是合宜的。"},
{volumn:        19,chapter:        33,versenumber:         2,verse:"你们应当弹琴称谢耶和华，用十弦瑟歌颂他。"},
{volumn:        19,chapter:        33,versenumber:         3,verse:"应当向他唱新歌，弹得巧妙，声音洪亮。"},
{volumn:        19,chapter:        33,versenumber:         4,verse:"因为耶和华的言语正直；凡他所作的，尽都诚实。"},
{volumn:        19,chapter:        33,versenumber:         5,verse:"他喜爱仁义公平；遍地满了耶和华的慈爱。"},
{volumn:        19,chapter:        33,versenumber:         6,verse:"诸天藉耶和华的命而造；万象藉他口中的气而成。"},
{volumn:        19,chapter:        33,versenumber:         7,verse:"他聚集海水如垒，收藏深洋在库房。"},
{volumn:        19,chapter:        33,versenumber:         8,verse:"愿全地都敬畏耶和华！愿世上的居民都惧怕他！"},
{volumn:        19,chapter:        33,versenumber:         9,verse:"因为他说有，就有，命立，就立。"},
{volumn:        19,chapter:        33,versenumber:        10,verse:"耶和华使列国的筹算归于无有，使众民的思念无有功效。"},
{volumn:        19,chapter:        33,versenumber:        11,verse:"耶和华的筹算永远立定；他心中的思念万代常存。"},
{volumn:        19,chapter:        33,versenumber:        12,verse:"以耶和华为　神的，那国是有福的！他所拣选为自己产业的，那民是有福的！"},
{volumn:        19,chapter:        33,versenumber:        13,verse:"耶和华从天上观看；他看见一切的世人。"},
{volumn:        19,chapter:        33,versenumber:        14,verse:"从他的居所往外察看地上一切的居民。"},
{volumn:        19,chapter:        33,versenumber:        15,verse:"他是那造成他们众人心的，留意他们一切作为的。"},
{volumn:        19,chapter:        33,versenumber:        16,verse:"君王不能因兵多得胜；勇士不能因力大得救。"},
{volumn:        19,chapter:        33,versenumber:        17,verse:"靠马得救是枉然的；马也不能因力大救人。"},
{volumn:        19,chapter:        33,versenumber:        18,verse:"耶和华的眼目看顾敬畏他的人和仰望他慈爱的人，"},
{volumn:        19,chapter:        33,versenumber:        19,verse:"要救他们的命脱离死亡，并使他们在饥荒中存活。"},
{volumn:        19,chapter:        33,versenumber:        20,verse:"我们的心向来等候耶和华；他是我们的帮助，我们的盾牌。"},
{volumn:        19,chapter:        33,versenumber:        21,verse:"我们的心必靠他欢喜，因为我们向来倚靠他的圣名。"},
{volumn:        19,chapter:        33,versenumber:        22,verse:"耶和华啊，求你照着我们所仰望你的，向我们施行慈爱！"},
{volumn:        19,chapter:        34,versenumber:         1,verse:"我要时时称颂耶和华；赞美他的话必常在我口中。"},
{volumn:        19,chapter:        34,versenumber:         2,verse:"我的心必因耶和华夸耀；谦卑人听见，就要喜乐。"},
{volumn:        19,chapter:        34,versenumber:         3,verse:"你们和我当称耶和华为大，一同高举他的名。"},
{volumn:        19,chapter:        34,versenumber:         4,verse:"我曾寻求耶和华，他就应允我，救我脱离了一切的恐惧。"},
{volumn:        19,chapter:        34,versenumber:         5,verse:"凡仰望他的，便有光荣；他们的脸，必不蒙羞。"},
{volumn:        19,chapter:        34,versenumber:         6,verse:"我这困苦人呼求，耶和华便垂听，救我脱离一切患难。"},
{volumn:        19,chapter:        34,versenumber:         7,verse:"耶和华的使者在敬畏他的人四围安营，搭救他们。"},
{volumn:        19,chapter:        34,versenumber:         8,verse:"你们要尝尝主恩的滋味，便知道他是美善；投靠他的人有福了！"},
{volumn:        19,chapter:        34,versenumber:         9,verse:"耶和华的圣民哪，你们当敬畏他，因敬畏他的一无所缺。"},
{volumn:        19,chapter:        34,versenumber:        10,verse:"少壮狮子还缺食忍饿，但寻求耶和华的，什么好处都不缺。"},
{volumn:        19,chapter:        34,versenumber:        11,verse:"众弟子啊，你们当来听我的话！我要将敬畏耶和华的道教训你们。"},
{volumn:        19,chapter:        34,versenumber:        12,verse:"有何人喜好存活，爱慕长寿，得享美福，"},
{volumn:        19,chapter:        34,versenumber:        13,verse:"就要禁止舌头不出恶言，嘴唇不说诡诈的话。"},
{volumn:        19,chapter:        34,versenumber:        14,verse:"要离恶行善，寻求和睦，一心追赶。"},
{volumn:        19,chapter:        34,versenumber:        15,verse:"耶和华的眼目看顾义人；他的耳朵听他们的呼求。"},
{volumn:        19,chapter:        34,versenumber:        16,verse:"耶和华向行恶的人变脸，要从世上除灭他们的名号。"},
{volumn:        19,chapter:        34,versenumber:        17,verse:"义人呼求，耶和华听见了，便救他们脱离一切患难。"},
{volumn:        19,chapter:        34,versenumber:        18,verse:"耶和华靠近伤心的人，拯救灵性痛悔的人。"},
{volumn:        19,chapter:        34,versenumber:        19,verse:"义人多有苦难，但耶和华救他脱离这一切，"},
{volumn:        19,chapter:        34,versenumber:        20,verse:"又保全他一身的骨头，连一根也不折断。"},
{volumn:        19,chapter:        34,versenumber:        21,verse:"恶必害死恶人；恨恶义人的，必被定罪。"},
{volumn:        19,chapter:        34,versenumber:        22,verse:"耶和华救赎他仆人的灵魂；凡投靠他的，必不致定罪。"},
{volumn:        19,chapter:        35,versenumber:         1,verse:"耶和华啊，与我相争的，求你与他们相争！与我相战的，求你与他们相战！"},
{volumn:        19,chapter:        35,versenumber:         2,verse:"拿着大小的盾牌，起来帮助我。"},
{volumn:        19,chapter:        35,versenumber:         3,verse:"抽出枪来，挡住那追赶我的；求你对我的灵魂说：“我是拯救你的。”"},
{volumn:        19,chapter:        35,versenumber:         4,verse:"愿那寻索我命的，蒙羞受辱！愿那谋害我的，退后羞愧！"},
{volumn:        19,chapter:        35,versenumber:         5,verse:"愿他们像风前的糠，有耶和华的使者赶逐他们。"},
{volumn:        19,chapter:        35,versenumber:         6,verse:"愿他们的道路又暗又滑，有耶和华的使者追赶他们。"},
{volumn:        19,chapter:        35,versenumber:         7,verse:"因他们无故地为我暗设网罗，无故地挖坑，要害我的性命。"},
{volumn:        19,chapter:        35,versenumber:         8,verse:"愿灾祸忽然临到他身上！愿他暗设的网缠住自己！愿他落在其中遭灾祸！"},
{volumn:        19,chapter:        35,versenumber:         9,verse:"我的心必靠耶和华快乐，靠他的救恩高兴。"},
{volumn:        19,chapter:        35,versenumber:        10,verse:"我的骨头都要说：“耶和华啊，谁能像你救护困苦人脱离那比他强壮的，救护困苦穷乏人脱离那抢夺他的？”"},
{volumn:        19,chapter:        35,versenumber:        11,verse:"凶恶的见证人起来，盘问我所不知道的事。"},
{volumn:        19,chapter:        35,versenumber:        12,verse:"他们向我以恶报善，使我的灵魂孤苦。"},
{volumn:        19,chapter:        35,versenumber:        13,verse:"至于我，当他们有病的时候，我便穿麻衣，禁食，刻苦己心；我所求的都归到自己的怀中。"},
{volumn:        19,chapter:        35,versenumber:        14,verse:"我这样行，好像他是我的朋友，我的弟兄；我屈身悲哀，如同人为母亲哀痛。"},
{volumn:        19,chapter:        35,versenumber:        15,verse:"我在患难中，他们却欢喜，大家聚集。我所不认识的那些下流人聚集攻击我；他们不住地把我撕裂。"},
{volumn:        19,chapter:        35,versenumber:        16,verse:"他们如同席上好嬉笑的狂妄人向我咬牙。"},
{volumn:        19,chapter:        35,versenumber:        17,verse:"主啊，你看着不理要到几时呢？求你救我的灵魂脱离他们的残害！救我的生命（生命：原文是独一者）脱离少壮狮子！"},
{volumn:        19,chapter:        35,versenumber:        18,verse:"我在大会中要称谢你，在众民中要赞美你。"},
{volumn:        19,chapter:        35,versenumber:        19,verse:"求你不容那无理与我为仇的向我夸耀！不容那无故恨我的向我挤眼！"},
{volumn:        19,chapter:        35,versenumber:        20,verse:"因为他们不说和平话，倒想出诡诈的言语害地上的安静人。"},
{volumn:        19,chapter:        35,versenumber:        21,verse:"他们大大张口攻击我，说：“阿哈，阿哈，我们的眼已经看见了！”"},
{volumn:        19,chapter:        35,versenumber:        22,verse:"耶和华啊，你已经看见了，求你不要闭口！主啊，求你不要远离我！"},
{volumn:        19,chapter:        35,versenumber:        23,verse:"我的　神我的主啊，求你奋兴醒起，判清我的事，伸明我的冤！"},
{volumn:        19,chapter:        35,versenumber:        24,verse:"耶和华我的　神啊，求你按你的公义判断我，不容他们向我夸耀！"},
{volumn:        19,chapter:        35,versenumber:        25,verse:"不容他们心里说：“阿哈，遂我们的心愿了”，不容他们说：“我们已经把他吞了！”"},
{volumn:        19,chapter:        35,versenumber:        26,verse:"愿那喜欢我遭难的一同抱愧蒙羞！愿那向我妄自尊大的披惭愧，蒙羞辱！"},
{volumn:        19,chapter:        35,versenumber:        27,verse:"愿那喜悦我冤屈得伸的（冤屈得伸：原文是公义）欢呼快乐；愿他们常说：“当尊耶和华为大！耶和华喜悦他的仆人平安。”"},
{volumn:        19,chapter:        35,versenumber:        28,verse:"我的舌头要终日论说你的公义，时常赞美你。"},
{volumn:        19,chapter:        36,versenumber:         1,verse:"恶人的罪过在他心里说：“我眼中不怕　神！”"},
{volumn:        19,chapter:        36,versenumber:         2,verse:"他自夸自媚，以为他的罪孽终不显露，不被恨恶。"},
{volumn:        19,chapter:        36,versenumber:         3,verse:"他口中的言语尽是罪孽诡诈；他与智慧善行已经断绝。"},
{volumn:        19,chapter:        36,versenumber:         4,verse:"他在床上图谋罪孽，定意行不善的道，不憎恶恶事。"},
{volumn:        19,chapter:        36,versenumber:         5,verse:"耶和华啊，你的慈爱上及诸天；你的信实达到穹苍。"},
{volumn:        19,chapter:        36,versenumber:         6,verse:"你的公义好像高山；你的判断如同深渊。耶和华啊，人民、牲畜，你都救护。"},
{volumn:        19,chapter:        36,versenumber:         7,verse:"神啊，你的慈爱何其宝贵！世人投靠在你翅膀的荫下。"},
{volumn:        19,chapter:        36,versenumber:         8,verse:"他们必因你殿里的肥甘得以饱足；你也必叫他们喝你乐河的水。"},
{volumn:        19,chapter:        36,versenumber:         9,verse:"因为，在你那里有生命的源头；在你的光中，我们必得见光。"},
{volumn:        19,chapter:        36,versenumber:        10,verse:"愿你常施慈爱给认识你的人，常以公义待心里正直的人。"},
{volumn:        19,chapter:        36,versenumber:        11,verse:"不容骄傲人的脚践踏我；不容凶恶人的手赶逐我。"},
{volumn:        19,chapter:        36,versenumber:        12,verse:"在那里，作孽的人已经仆倒；他们被推倒，不能再起来。"},
{volumn:        19,chapter:        37,versenumber:         1,verse:"不要为作恶的心怀不平，也不要向那行不义的生出嫉妒。"},
{volumn:        19,chapter:        37,versenumber:         2,verse:"因为他们如草快被割下，又如青菜快要枯干。"},
{volumn:        19,chapter:        37,versenumber:         3,verse:"你当倚靠耶和华而行善，住在地上，以他的信实为粮；"},
{volumn:        19,chapter:        37,versenumber:         4,verse:"又要以耶和华为乐，他就将你心里所求的赐给你。"},
{volumn:        19,chapter:        37,versenumber:         5,verse:"当将你的事交托耶和华，并倚靠他，他就必成全。"},
{volumn:        19,chapter:        37,versenumber:         6,verse:"他要使你的公义如光发出，使你的公平明如正午。"},
{volumn:        19,chapter:        37,versenumber:         7,verse:"你当默然倚靠耶和华，耐性等候他；不要因那道路通达的和那恶谋成就的心怀不平。"},
{volumn:        19,chapter:        37,versenumber:         8,verse:"当止住怒气，离弃忿怒；不要心怀不平，以致作恶。"},
{volumn:        19,chapter:        37,versenumber:         9,verse:"因为作恶的必被剪除；惟有等候耶和华的必承受地土。"},
{volumn:        19,chapter:        37,versenumber:        10,verse:"还有片时，恶人要归于无有；你就是细察他的住处也要归于无有。"},
{volumn:        19,chapter:        37,versenumber:        11,verse:"但谦卑人必承受地土，以丰盛的平安为乐。"},
{volumn:        19,chapter:        37,versenumber:        12,verse:"恶人设谋害义人，又向他咬牙。"},
{volumn:        19,chapter:        37,versenumber:        13,verse:"主要笑他，因见他受罚的日子将要来到。"},
{volumn:        19,chapter:        37,versenumber:        14,verse:"恶人已经弓上弦，刀出鞘，要打倒困苦穷乏的人，要杀害行动正直的人。"},
{volumn:        19,chapter:        37,versenumber:        15,verse:"他们的刀必刺入自己的心；他们的弓必被折断。"},
{volumn:        19,chapter:        37,versenumber:        16,verse:"一个义人所有的虽少，强过许多恶人的富余。"},
{volumn:        19,chapter:        37,versenumber:        17,verse:"因为恶人的膀臂必被折断；但耶和华是扶持义人。"},
{volumn:        19,chapter:        37,versenumber:        18,verse:"耶和华知道完全人的日子；他们的产业要存到永远。"},
{volumn:        19,chapter:        37,versenumber:        19,verse:"他们在急难的时候不致羞愧，在饥荒的日子必得饱足。"},
{volumn:        19,chapter:        37,versenumber:        20,verse:"恶人却要灭亡。耶和华的仇敌要像羊羔的脂油（或译：像草地的华美）；他们要消灭，要如烟消灭。"},
{volumn:        19,chapter:        37,versenumber:        21,verse:"恶人借贷而不偿还；义人却恩待人，并且施舍。"},
{volumn:        19,chapter:        37,versenumber:        22,verse:"蒙耶和华赐福的必承受地土；被他咒诅的必被剪除。"},
{volumn:        19,chapter:        37,versenumber:        23,verse:"义人的脚步被耶和华立定；他的道路，耶和华也喜爱。"},
{volumn:        19,chapter:        37,versenumber:        24,verse:"他虽失脚也不至全身仆倒，因为耶和华用手搀扶他（或译：搀扶他的手）。"},
{volumn:        19,chapter:        37,versenumber:        25,verse:"我从前年幼，现在年老，却未见过义人被弃，也未见过他的后裔讨饭。"},
{volumn:        19,chapter:        37,versenumber:        26,verse:"他终日恩待人，借给人；他的后裔也蒙福！"},
{volumn:        19,chapter:        37,versenumber:        27,verse:"你当离恶行善，就可永远安居。"},
{volumn:        19,chapter:        37,versenumber:        28,verse:"因为，耶和华喜爱公平，不撇弃他的圣民；他们永蒙保佑，但恶人的后裔必被剪除。"},
{volumn:        19,chapter:        37,versenumber:        29,verse:"义人必承受地土，永居其上。"},
{volumn:        19,chapter:        37,versenumber:        30,verse:"义人的口谈论智慧；他的舌头讲说公平。"},
{volumn:        19,chapter:        37,versenumber:        31,verse:"神的律法在他心里；他的脚总不滑跌。"},
{volumn:        19,chapter:        37,versenumber:        32,verse:"恶人窥探义人，想要杀他。"},
{volumn:        19,chapter:        37,versenumber:        33,verse:"耶和华必不撇他在恶人手中；当审判的时候，也不定他的罪。"},
{volumn:        19,chapter:        37,versenumber:        34,verse:"你当等候耶和华，遵守他的道，他就抬举你，使你承受地土；恶人被剪除的时候，你必看见。"},
{volumn:        19,chapter:        37,versenumber:        35,verse:"我见过恶人大有势力，好像一棵青翠树在本土生发。"},
{volumn:        19,chapter:        37,versenumber:        36,verse:"有人从那里经过，不料，他没有了；我也寻找他，却寻不着。"},
{volumn:        19,chapter:        37,versenumber:        37,verse:"你要细察那完全人，观看那正直人，因为和平人有好结局。"},
{volumn:        19,chapter:        37,versenumber:        38,verse:"至于犯法的人，必一同灭绝；恶人终必剪除。"},
{volumn:        19,chapter:        37,versenumber:        39,verse:"但义人得救是由于耶和华；他在患难时作他们的营寨。"},
{volumn:        19,chapter:        37,versenumber:        40,verse:"耶和华帮助他们，解救他们；他解救他们脱离恶人，把他们救出来，因为他们投靠他。"},
{volumn:        19,chapter:        38,versenumber:         1,verse:"耶和华啊，求你不要在怒中责备我，不要在烈怒中惩罚我！"},
{volumn:        19,chapter:        38,versenumber:         2,verse:"因为，你的箭射入我身；你的手压住我。"},
{volumn:        19,chapter:        38,versenumber:         3,verse:"因你的恼怒，我的肉无一完全；因我的罪过，我的骨头也不安宁。"},
{volumn:        19,chapter:        38,versenumber:         4,verse:"我的罪孽高过我的头，如同重担叫我担当不起。"},
{volumn:        19,chapter:        38,versenumber:         5,verse:"因我的愚昧，我的伤发臭流脓。"},
{volumn:        19,chapter:        38,versenumber:         6,verse:"我疼痛，大大拳曲，终日哀痛。"},
{volumn:        19,chapter:        38,versenumber:         7,verse:"我满腰是火；我的肉无一完全。"},
{volumn:        19,chapter:        38,versenumber:         8,verse:"我被压伤，身体疲倦；因心里不安，我就唉哼。"},
{volumn:        19,chapter:        38,versenumber:         9,verse:"主啊，我的心愿都在你面前；我的叹息不向你隐瞒。"},
{volumn:        19,chapter:        38,versenumber:        10,verse:"我心跳动，我力衰微，连我眼中的光也没有了。"},
{volumn:        19,chapter:        38,versenumber:        11,verse:"我的良朋密友因我的灾病都躲在旁边站着；我的亲戚本家也远远地站立。"},
{volumn:        19,chapter:        38,versenumber:        12,verse:"那寻索我命的设下网罗；那想要害我的口出恶言，终日思想诡计。"},
{volumn:        19,chapter:        38,versenumber:        13,verse:"但我如聋子不听，像哑巴不开口。"},
{volumn:        19,chapter:        38,versenumber:        14,verse:"我如不听见的人，口中没有回话。"},
{volumn:        19,chapter:        38,versenumber:        15,verse:"耶和华啊，我仰望你！主我的　神啊，你必应允我！"},
{volumn:        19,chapter:        38,versenumber:        16,verse:"我曾说：“恐怕他们向我夸耀；我失脚的时候，他们向我夸大。”"},
{volumn:        19,chapter:        38,versenumber:        17,verse:"我几乎跌倒；我的痛苦常在我面前。"},
{volumn:        19,chapter:        38,versenumber:        18,verse:"我要承认我的罪孽；我要因我的罪忧愁。"},
{volumn:        19,chapter:        38,versenumber:        19,verse:"但我的仇敌又活泼又强壮，无理恨我的增多了。"},
{volumn:        19,chapter:        38,versenumber:        20,verse:"以恶报善的与我作对，因我是追求良善。"},
{volumn:        19,chapter:        38,versenumber:        21,verse:"耶和华啊，求你不要撇弃我！我的　神啊，求你不要远离我！"},
{volumn:        19,chapter:        38,versenumber:        22,verse:"拯救我的主啊，求你快快帮助我！"},
{volumn:        19,chapter:        39,versenumber:         1,verse:"我曾说：“我要谨慎我的言行，免得我舌头犯罪；恶人在我面前的时候，我要用嚼环勒住我的口。”"},
{volumn:        19,chapter:        39,versenumber:         2,verse:"我默然无声，连好话也不出口；我的愁苦就发动了，"},
{volumn:        19,chapter:        39,versenumber:         3,verse:"我的心在我里面发热。我默想的时候，火就烧起，我便用舌头说话。"},
{volumn:        19,chapter:        39,versenumber:         4,verse:"耶和华啊，求你叫我晓得我身之终！我的寿数几何？叫我知道我的生命不长！"},
{volumn:        19,chapter:        39,versenumber:         5,verse:"你使我的年日窄如手掌；我一生的年数，在你面前如同无有。各人最稳妥的时候，真是全然虚幻。（细拉）"},
{volumn:        19,chapter:        39,versenumber:         6,verse:"世人行动实系幻影。他们忙乱，真是枉然；积蓄财宝，不知将来有谁收取。"},
{volumn:        19,chapter:        39,versenumber:         7,verse:"主啊，如今我等什么呢？我的指望在乎你！"},
{volumn:        19,chapter:        39,versenumber:         8,verse:"求你救我脱离一切的过犯，不要使我受愚顽人的羞辱。"},
{volumn:        19,chapter:        39,versenumber:         9,verse:"因我所遭遇的是出于你，我就默然不语。"},
{volumn:        19,chapter:        39,versenumber:        10,verse:"求你把你的责罚从我身上免去；因你手的责打，我便消灭。"},
{volumn:        19,chapter:        39,versenumber:        11,verse:"你因人的罪恶惩罚他的时候，叫他的笑容（的笑容：或译所喜爱的）消灭，如衣被虫所咬。世人真是虚幻！（细拉）"},
{volumn:        19,chapter:        39,versenumber:        12,verse:"耶和华啊，求你听我的祷告，留心听我的呼求！我流泪，求你不要静默无声！因为我在你面前是客旅，是寄居的，像我列祖一般。"},
{volumn:        19,chapter:        39,versenumber:        13,verse:"求你宽容我，使我在去而不返之先可以力量复原。"},
{volumn:        19,chapter:        40,versenumber:         1,verse:"我曾耐性等候耶和华；他垂听我的呼求。"},
{volumn:        19,chapter:        40,versenumber:         2,verse:"他从祸坑里，从淤泥中，把我拉上来，使我的脚立在磐石上，使我脚步稳当。"},
{volumn:        19,chapter:        40,versenumber:         3,verse:"他使我口唱新歌，就是赞美我们　神的话。许多人必看见而惧怕，并要倚靠耶和华。"},
{volumn:        19,chapter:        40,versenumber:         4,verse:"那倚靠耶和华、不理会狂傲和偏向虚假之辈的，这人便为有福！"},
{volumn:        19,chapter:        40,versenumber:         5,verse:"耶和华我的　神啊，你所行的奇事，并你向我们所怀的意念甚多，不能向你陈明；若要陈明，其事不可胜数。"},
{volumn:        19,chapter:        40,versenumber:         6,verse:"祭物和礼物，你不喜悦；你已经开通我的耳朵。燔祭和赎罪祭非你所要。"},
{volumn:        19,chapter:        40,versenumber:         7,verse:"那时我说：“看哪，我来了！我的事在经卷上已经记载了。"},
{volumn:        19,chapter:        40,versenumber:         8,verse:"我的　神啊，我乐意照你的旨意行；你的律法在我心里。”"},
{volumn:        19,chapter:        40,versenumber:         9,verse:"我在大会中宣传公义的佳音；我必不止住我的嘴唇。耶和华啊，这是你所知道的。"},
{volumn:        19,chapter:        40,versenumber:        10,verse:"我未曾把你的公义藏在心里；我已陈明你的信实和你的救恩；我在大会中未曾隐瞒你的慈爱和诚实。"},
{volumn:        19,chapter:        40,versenumber:        11,verse:"耶和华啊，求你不要向我止住你的慈悲！愿你的慈爱和诚实常常保佑我！"},
{volumn:        19,chapter:        40,versenumber:        12,verse:"因有无数的祸患围困我，我的罪孽追上了我，使我不能昂首；这罪孽比我的头发还多，我就心寒胆战。"},
{volumn:        19,chapter:        40,versenumber:        13,verse:"耶和华啊，求你开恩搭救我！耶和华啊，求你速速帮助我！"},
{volumn:        19,chapter:        40,versenumber:        14,verse:"愿那些寻找我、要灭我命的，一同抱愧蒙羞！愿那些喜悦我受害的，退后受辱！"},
{volumn:        19,chapter:        40,versenumber:        15,verse:"愿那些对我说“阿哈、阿哈”的，因羞愧而败亡！"},
{volumn:        19,chapter:        40,versenumber:        16,verse:"愿一切寻求你的，因你高兴欢喜！愿那些喜爱你救恩的，常说：“当尊耶和华为大！”"},
{volumn:        19,chapter:        40,versenumber:        17,verse:"但我是困苦穷乏的，主仍顾念我；你是帮助我的，搭救我的。　神啊，求你不要耽延！"},
{volumn:        19,chapter:        41,versenumber:         1,verse:"眷顾贫穷的有福了！他遭难的日子，耶和华必搭救他。"},
{volumn:        19,chapter:        41,versenumber:         2,verse:"耶和华必保全他，使他存活；他必在地上享福。求你不要把他交给仇敌，遂其所愿。"},
{volumn:        19,chapter:        41,versenumber:         3,verse:"他病重在榻，耶和华必扶持他；他在病中，你必给他铺床。"},
{volumn:        19,chapter:        41,versenumber:         4,verse:"我曾说：“耶和华啊，求你怜恤我，医治我！因为我得罪了你。”"},
{volumn:        19,chapter:        41,versenumber:         5,verse:"我的仇敌用恶言议论我说：“他几时死，他的名才灭亡呢？”"},
{volumn:        19,chapter:        41,versenumber:         6,verse:"他来看我就说假话；他心存奸恶，走到外边才说出来。"},
{volumn:        19,chapter:        41,versenumber:         7,verse:"一切恨我的，都交头接耳地议论我；他们设计要害我。"},
{volumn:        19,chapter:        41,versenumber:         8,verse:"他们说：“有怪病贴在他身上；他已躺卧，必不能再起来。”"},
{volumn:        19,chapter:        41,versenumber:         9,verse:"连我知己的朋友，我所倚靠、吃过我饭的也用脚踢我。"},
{volumn:        19,chapter:        41,versenumber:        10,verse:"耶和华啊，求你怜恤我，使我起来，好报复他们！"},
{volumn:        19,chapter:        41,versenumber:        11,verse:"因我的仇敌不得向我夸胜，我从此便知道你喜爱我。"},
{volumn:        19,chapter:        41,versenumber:        12,verse:"你因我纯正就扶持我，使我永远站在你的面前。"},
{volumn:        19,chapter:        41,versenumber:        13,verse:"耶和华以色列的　神是应当称颂的，从亘古直到永远。阿们！阿们！"},
{volumn:        19,chapter:        42,versenumber:         1,verse:"神啊，我的心切慕你，如鹿切慕溪水。"},
{volumn:        19,chapter:        42,versenumber:         2,verse:"我的心渴想　神，就是永生　神；我几时得朝见　神呢？"},
{volumn:        19,chapter:        42,versenumber:         3,verse:"我昼夜以眼泪当饮食；人不住地对我说：“你的　神在哪里呢？”"},
{volumn:        19,chapter:        42,versenumber:         4,verse:"我从前与众人同往，用欢呼称赞的声音领他们到　神的殿里，大家守节。我追想这些事，我的心极其悲伤。"},
{volumn:        19,chapter:        42,versenumber:         5,verse:"我的心哪，你为何忧闷？为何在我里面烦躁？应当仰望　神，因他笑脸帮助我；我还要称赞他。"},
{volumn:        19,chapter:        42,versenumber:         6,verse:"我的　神啊，我的心在我里面忧闷，所以我从约旦地，从黑门岭，从米萨山记念你。"},
{volumn:        19,chapter:        42,versenumber:         7,verse:"你的瀑布发声，深渊就与深渊响应；你的波浪洪涛漫过我身。"},
{volumn:        19,chapter:        42,versenumber:         8,verse:"白昼，耶和华必向我施慈爱；黑夜，我要歌颂祷告赐我生命的　神。"},
{volumn:        19,chapter:        42,versenumber:         9,verse:"我要对　神我的磐石说：“你为何忘记我呢？我为何因仇敌的欺压时常哀痛呢？”"},
{volumn:        19,chapter:        42,versenumber:        10,verse:"我的敌人辱骂我，好像打碎我的骨头，不住地对我说：“你的　神在哪里呢？”"},
{volumn:        19,chapter:        42,versenumber:        11,verse:"我的心哪，你为何忧闷？为何在我里面烦躁？应当仰望　神，因我还要称赞他。他是我脸上的光荣（原文是帮助），是我的　神。"},
{volumn:        19,chapter:        43,versenumber:         1,verse:"神啊，求你伸我的冤，向不虔诚的国为我辨屈；求你救我脱离诡诈不义的人。"},
{volumn:        19,chapter:        43,versenumber:         2,verse:"因为你是赐我力量的　神，为何丢弃我呢？我为何因仇敌的欺压时常哀痛呢？"},
{volumn:        19,chapter:        43,versenumber:         3,verse:"求你发出你的亮光和真实，好引导我，带我到你的圣山，到你的居所！"},
{volumn:        19,chapter:        43,versenumber:         4,verse:"我就走到　神的祭坛，到我最喜乐的　神那里。　神啊，我的　神，我要弹琴称赞你！"},
{volumn:        19,chapter:        43,versenumber:         5,verse:"我的心哪，你为何忧闷？为何在我里面烦躁？应当仰望　神，因我还要称赞他。他是我脸上的光荣（原文是帮助），是我的　神。"},
{volumn:        19,chapter:        44,versenumber:         1,verse:"神啊，你在古时，我们列祖的日子所行的事，我们亲耳听见了；我们的列祖也给我们述说过。"},
{volumn:        19,chapter:        44,versenumber:         2,verse:"你曾用手赶出外邦人，却栽培了我们列祖；你苦待列邦，却叫我们列祖发达。"},
{volumn:        19,chapter:        44,versenumber:         3,verse:"因为他们不是靠自己的刀剑得地土，也不是靠自己的膀臂得胜，乃是靠你的右手、你的膀臂，和你脸上的亮光，因为你喜悦他们。"},
{volumn:        19,chapter:        44,versenumber:         4,verse:"神啊，你是我的王；求你出令使雅各得胜。"},
{volumn:        19,chapter:        44,versenumber:         5,verse:"我们靠你要推倒我们的敌人，靠你的名要践踏那起来攻击我们的人。"},
{volumn:        19,chapter:        44,versenumber:         6,verse:"因为，我必不靠我的弓；我的刀也不能使我得胜。"},
{volumn:        19,chapter:        44,versenumber:         7,verse:"惟你救了我们脱离敌人，使恨我们的人羞愧。"},
{volumn:        19,chapter:        44,versenumber:         8,verse:"我们终日因　神夸耀，还要永远称谢你的名。（细拉）"},
{volumn:        19,chapter:        44,versenumber:         9,verse:"但如今你丢弃了我们，使我们受辱，不和我们的军兵同去。"},
{volumn:        19,chapter:        44,versenumber:        10,verse:"你使我们向敌人转身退后；那恨我们的人任意抢夺。"},
{volumn:        19,chapter:        44,versenumber:        11,verse:"你使我们当作快要被吃的羊，把我们分散在列邦中。"},
{volumn:        19,chapter:        44,versenumber:        12,verse:"你卖了你的子民也不赚利，所得的价值并不加添你的资财。"},
{volumn:        19,chapter:        44,versenumber:        13,verse:"你使我们受邻国的羞辱，被四围的人嗤笑讥刺。"},
{volumn:        19,chapter:        44,versenumber:        14,verse:"你使我们在列邦中作了笑谈，使众民向我们摇头。"},
{volumn:        19,chapter:        44,versenumber:        15,verse:"我的凌辱终日在我面前，我脸上的羞愧将我遮蔽，"},
{volumn:        19,chapter:        44,versenumber:        16,verse:"都因那辱骂毁谤人的声音，又因仇敌和报仇人的缘故。"},
{volumn:        19,chapter:        44,versenumber:        17,verse:"这都临到我们身上，我们却没有忘记你，也没有违背你的约。"},
{volumn:        19,chapter:        44,versenumber:        18,verse:"我们的心没有退后；我们的脚也没有偏离你的路。"},
{volumn:        19,chapter:        44,versenumber:        19,verse:"你在野狗之处压伤我们，用死荫遮蔽我们。"},
{volumn:        19,chapter:        44,versenumber:        20,verse:"倘若我们忘了　神的名，或向别神举手，"},
{volumn:        19,chapter:        44,versenumber:        21,verse:"神岂不鉴察这事吗？因为他晓得人心里的隐秘。"},
{volumn:        19,chapter:        44,versenumber:        22,verse:"我们为你的缘故终日被杀；人看我们如将宰的羊。"},
{volumn:        19,chapter:        44,versenumber:        23,verse:"主啊，求你睡醒，为何尽睡呢？求你兴起，不要永远丢弃我们！"},
{volumn:        19,chapter:        44,versenumber:        24,verse:"你为何掩面，不顾我们所遭的苦难和所受的欺压？"},
{volumn:        19,chapter:        44,versenumber:        25,verse:"我们的性命伏于尘土；我们的肚腹紧贴地面。"},
{volumn:        19,chapter:        44,versenumber:        26,verse:"求你起来帮助我们！凭你的慈爱救赎我们！"},
{volumn:        19,chapter:        45,versenumber:         1,verse:"我心里涌出美辞；我论到我为王做的事，我的舌头是快手笔。"},
{volumn:        19,chapter:        45,versenumber:         2,verse:"你比世人更美；在你嘴里满有恩惠；所以　神赐福给你，直到永远。"},
{volumn:        19,chapter:        45,versenumber:         3,verse:"大能者啊，愿你腰间佩刀，大有荣耀和威严！"},
{volumn:        19,chapter:        45,versenumber:         4,verse:"为真理、谦卑、公义赫然坐车前往，无不得胜；你的右手必显明可畏的事。"},
{volumn:        19,chapter:        45,versenumber:         5,verse:"你的箭锋快，射中王敌之心；万民仆倒在你以下。"},
{volumn:        19,chapter:        45,versenumber:         6,verse:"神啊，你的宝座是永永远远的；你的国权是正直的。"},
{volumn:        19,chapter:        45,versenumber:         7,verse:"你喜爱公义，恨恶罪恶；所以　神，就是你的　神，用喜乐油膏你，胜过膏你的同伴。"},
{volumn:        19,chapter:        45,versenumber:         8,verse:"你的衣服都有没药、沉香、肉桂的香气；象牙宫中有丝弦乐器的声音使你欢喜。"},
{volumn:        19,chapter:        45,versenumber:         9,verse:"有君王的女儿在你尊贵妇女之中；王后佩戴俄斐金饰站在你右边。"},
{volumn:        19,chapter:        45,versenumber:        10,verse:"女子啊，你要听，要想，要侧耳而听！不要记念你的民和你的父家，"},
{volumn:        19,chapter:        45,versenumber:        11,verse:"王就羡慕你的美貌；因为他是你的主，你当敬拜他。"},
{volumn:        19,chapter:        45,versenumber:        12,verse:"推罗的民（原文是女子）必来送礼；民中的富足人也必向你求恩。"},
{volumn:        19,chapter:        45,versenumber:        13,verse:"王女在宫里极其荣华；她的衣服是用金线绣的。"},
{volumn:        19,chapter:        45,versenumber:        14,verse:"她要穿锦绣的衣服，被引到王前；随从她的陪伴童女也要被带到你面前。"},
{volumn:        19,chapter:        45,versenumber:        15,verse:"她们要欢喜快乐被引导；她们要进入王宫。"},
{volumn:        19,chapter:        45,versenumber:        16,verse:"你的子孙要接续你的列祖；你要立他们在全地作王。"},
{volumn:        19,chapter:        45,versenumber:        17,verse:"我必叫你的名被万代记念，所以万民要永永远远称谢你。"},
{volumn:        19,chapter:        46,versenumber:         1,verse:"神是我们的避难所，是我们的力量，是我们在患难中随时的帮助。"},
{volumn:        19,chapter:        46,versenumber:         2,verse:"所以，地虽改变，山虽摇动到海心，"},
{volumn:        19,chapter:        46,versenumber:         3,verse:"其中的水虽砰訇翻腾，山虽因海涨而战抖，我们也不害怕。（细拉）"},
{volumn:        19,chapter:        46,versenumber:         4,verse:"有一道河，这河的分汊使　神的城欢喜；这城就是至高者居住的圣所。"},
{volumn:        19,chapter:        46,versenumber:         5,verse:"神在其中，城必不动摇；到天一亮，　神必帮助这城。"},
{volumn:        19,chapter:        46,versenumber:         6,verse:"外邦喧嚷，列国动摇；　神发声，地便熔化。"},
{volumn:        19,chapter:        46,versenumber:         7,verse:"万军之耶和华与我们同在；雅各的　神是我们的避难所！（细拉）"},
{volumn:        19,chapter:        46,versenumber:         8,verse:"你们来看耶和华的作为，看他使地怎样荒凉。"},
{volumn:        19,chapter:        46,versenumber:         9,verse:"他止息刀兵，直到地极；他折弓、断枪，把战车焚烧在火中。"},
{volumn:        19,chapter:        46,versenumber:        10,verse:"你们要休息，要知道我是　神！我必在外邦中被尊崇，在遍地上也被尊崇。"},
{volumn:        19,chapter:        46,versenumber:        11,verse:"万军之耶和华与我们同在；雅各的　神是我们的避难所！"},
{volumn:        19,chapter:        47,versenumber:         1,verse:"万民哪，你们都要拍掌！要用夸胜的声音向　神呼喊！"},
{volumn:        19,chapter:        47,versenumber:         2,verse:"因为耶和华至高者是可畏的；他是治理全地的大君王。"},
{volumn:        19,chapter:        47,versenumber:         3,verse:"他叫万民服在我们以下，又叫列邦服在我们脚下。"},
{volumn:        19,chapter:        47,versenumber:         4,verse:"他为我们选择产业，就是他所爱之雅各的荣耀。（细拉）"},
{volumn:        19,chapter:        47,versenumber:         5,verse:"神上升，有喊声相送；耶和华上升，有角声相送。"},
{volumn:        19,chapter:        47,versenumber:         6,verse:"你们要向　神歌颂，歌颂！向我们王歌颂，歌颂！"},
{volumn:        19,chapter:        47,versenumber:         7,verse:"因为　神是全地的王；你们要用悟性歌颂。"},
{volumn:        19,chapter:        47,versenumber:         8,verse:"神作王治理万国；　神坐在他的圣宝座上。"},
{volumn:        19,chapter:        47,versenumber:         9,verse:"列邦的君王聚集要作亚伯拉罕之　神的民。因为世界的盾牌是属　神的；他为至高！"},
{volumn:        19,chapter:        48,versenumber:         1,verse:"耶和华本为大！在我们　神的城中，在他的圣山上，该受大赞美。"},
{volumn:        19,chapter:        48,versenumber:         2,verse:"锡安山，大君王的城，在北面居高华美，为全地所喜悦。"},
{volumn:        19,chapter:        48,versenumber:         3,verse:"神在其宫中，自显为避难所。"},
{volumn:        19,chapter:        48,versenumber:         4,verse:"看哪，众王会合，一同经过。"},
{volumn:        19,chapter:        48,versenumber:         5,verse:"他们见了这城就惊奇丧胆，急忙逃跑。"},
{volumn:        19,chapter:        48,versenumber:         6,verse:"他们在那里被战兢疼痛抓住，好像产难的妇人一样。"},
{volumn:        19,chapter:        48,versenumber:         7,verse:"神啊，你用东风打破他施的船只。"},
{volumn:        19,chapter:        48,versenumber:         8,verse:"我们在万军之耶和华的城中，就是我们　神的城中所看见的，正如我们所听见的。　神必坚立这城，直到永远。（细拉）"},
{volumn:        19,chapter:        48,versenumber:         9,verse:"神啊，我们在你的殿中想念你的慈爱。"},
{volumn:        19,chapter:        48,versenumber:        10,verse:"神啊，你受的赞美正与你的名相称，直到地极！你的右手满了公义。"},
{volumn:        19,chapter:        48,versenumber:        11,verse:"因你的判断，锡安山应当欢喜，犹大的城邑（原文是女子）应当快乐。"},
{volumn:        19,chapter:        48,versenumber:        12,verse:"你们当周游锡安，四围旋绕，数点城楼，"},
{volumn:        19,chapter:        48,versenumber:        13,verse:"细看她的外郭，察看她的宫殿，为要传说到后代。"},
{volumn:        19,chapter:        48,versenumber:        14,verse:"因为这　神永永远远为我们的　神；他必作我们引路的，直到死时。"},
{volumn:        19,chapter:        49,versenumber:         1,verse:"万民哪，你们都当听这话！世上一切的居民，"},
{volumn:        19,chapter:        49,versenumber:         2,verse:"无论上流下流，富足贫穷，都当留心听！"},
{volumn:        19,chapter:        49,versenumber:         3,verse:"我口要说智慧的言语；我心要想通达的道理。"},
{volumn:        19,chapter:        49,versenumber:         4,verse:"我要侧耳听比喻，用琴解谜语。"},
{volumn:        19,chapter:        49,versenumber:         5,verse:"在患难的日子，奸恶随我脚跟，四面环绕我，我何必惧怕？"},
{volumn:        19,chapter:        49,versenumber:         6,verse:"那些倚仗财货自夸钱财多的人，"},
{volumn:        19,chapter:        49,versenumber:         7,verse:"一个也无法赎自己的弟兄，也不能替他将赎价给　神，"},
{volumn:        19,chapter:        49,versenumber:         8,verse:"叫他长远活着，不见朽坏；因为赎他生命的价值极贵，只可永远罢休。"},
{volumn:        19,chapter:        49,versenumber:         9,verse:""},
{volumn:        19,chapter:        49,versenumber:        10,verse:"他必见智慧人死，又见愚顽人和畜类人一同灭亡，将他们的财货留给别人。"},
{volumn:        19,chapter:        49,versenumber:        11,verse:"他们心里思想：他们的家室必永存，住宅必留到万代；他们以自己的名称自己的地。"},
{volumn:        19,chapter:        49,versenumber:        12,verse:"但人居尊贵中不能长久，如同死亡的畜类一样。"},
{volumn:        19,chapter:        49,versenumber:        13,verse:"他们行的这道本为自己的愚昧；但他们以后的人还佩服他们的话语。（细拉）"},
{volumn:        19,chapter:        49,versenumber:        14,verse:"他们如同羊群派定下阴间；死亡必作他们的牧者。到了早晨，正直人必管辖他们；他们的美容必被阴间所灭，以致无处可存。"},
{volumn:        19,chapter:        49,versenumber:        15,verse:"只是　神必救赎我的灵魂脱离阴间的权柄，因他必收纳我。（细拉）"},
{volumn:        19,chapter:        49,versenumber:        16,verse:"见人发财、家室增荣的时候，你不要惧怕；"},
{volumn:        19,chapter:        49,versenumber:        17,verse:"因为，他死的时候什么也不能带去；他的荣耀不能随他下去。"},
{volumn:        19,chapter:        49,versenumber:        18,verse:"他活着的时候，虽然自夸为有福（你若利己，人必夸奖你）；"},
{volumn:        19,chapter:        49,versenumber:        19,verse:"他仍必归到他历代的祖宗那里，永不见光。"},
{volumn:        19,chapter:        49,versenumber:        20,verse:"人在尊贵中而不醒悟，就如死亡的畜类一样。"},
{volumn:        19,chapter:        50,versenumber:         1,verse:"大能者　神耶和华已经发言招呼天下，从日出之地到日落之处。"},
{volumn:        19,chapter:        50,versenumber:         2,verse:"从全美的锡安中，　神已经发光了。"},
{volumn:        19,chapter:        50,versenumber:         3,verse:"我们的　神要来，决不闭口。有烈火在他面前吞灭；有暴风在他四围大刮。"},
{volumn:        19,chapter:        50,versenumber:         4,verse:"他招呼上天下地，为要审判他的民，"},
{volumn:        19,chapter:        50,versenumber:         5,verse:"说：“招聚我的圣民到我这里来，就是那些用祭物与我立约的人。”"},
{volumn:        19,chapter:        50,versenumber:         6,verse:"诸天必表明他的公义，因为　神是施行审判的。（细拉）"},
{volumn:        19,chapter:        50,versenumber:         7,verse:"“我的民哪，你们当听我的话！以色列啊，我要劝戒你；我是　神，是你的　神！"},
{volumn:        19,chapter:        50,versenumber:         8,verse:"我并不因你的祭物责备你；你的燔祭常在我面前。"},
{volumn:        19,chapter:        50,versenumber:         9,verse:"我不从你家中取公牛，也不从你圈内取山羊；"},
{volumn:        19,chapter:        50,versenumber:        10,verse:"因为，树林中的百兽是我的，千山上的牲畜也是我的。"},
{volumn:        19,chapter:        50,versenumber:        11,verse:"山中的飞鸟，我都知道；野地的走兽也都属我。"},
{volumn:        19,chapter:        50,versenumber:        12,verse:"我若是饥饿，我不用告诉你，因为世界和其中所充满的都是我的。"},
{volumn:        19,chapter:        50,versenumber:        13,verse:"我岂吃公牛的肉呢？我岂喝山羊的血呢？"},
{volumn:        19,chapter:        50,versenumber:        14,verse:"你们要以感谢为祭献与　神，又要向至高者还你的愿，"},
{volumn:        19,chapter:        50,versenumber:        15,verse:"并要在患难之日求告我；我必搭救你，你也要荣耀我。”"},
{volumn:        19,chapter:        50,versenumber:        16,verse:"但　神对恶人说：“你怎敢传说我的律例，口中提到我的约呢？"},
{volumn:        19,chapter:        50,versenumber:        17,verse:"其实你恨恶管教，将我的言语丢在背后。"},
{volumn:        19,chapter:        50,versenumber:        18,verse:"你见了盗贼就乐意与他同伙，又与行奸淫的人一同有份。"},
{volumn:        19,chapter:        50,versenumber:        19,verse:"你口任说恶言；你舌编造诡诈。"},
{volumn:        19,chapter:        50,versenumber:        20,verse:"你坐着毁谤你的兄弟，谗毁你亲母的儿子。"},
{volumn:        19,chapter:        50,versenumber:        21,verse:"你行了这些事，我还闭口不言，你想我恰和你一样；其实我要责备你，将这些事摆在你眼前。"},
{volumn:        19,chapter:        50,versenumber:        22,verse:"你们忘记　神的，要思想这事，免得我把你们撕碎，无人搭救。"},
{volumn:        19,chapter:        50,versenumber:        23,verse:"凡以感谢献上为祭的便是荣耀我；那按正路而行的，我必使他得着我的救恩。”"},
{volumn:        19,chapter:        51,versenumber:         1,verse:"神啊，求你按你的慈爱怜恤我！按你丰盛的慈悲涂抹我的过犯！"},
{volumn:        19,chapter:        51,versenumber:         2,verse:"求你将我的罪孽洗除净尽，并洁除我的罪！"},
{volumn:        19,chapter:        51,versenumber:         3,verse:"因为，我知道我的过犯；我的罪常在我面前。"},
{volumn:        19,chapter:        51,versenumber:         4,verse:"我向你犯罪，惟独得罪了你；在你眼前行了这恶，以致你责备我的时候显为公义，判断我的时候显为清正。"},
{volumn:        19,chapter:        51,versenumber:         5,verse:"我是在罪孽里生的，在我母亲怀胎的时候就有了罪。"},
{volumn:        19,chapter:        51,versenumber:         6,verse:"你所喜爱的是内里诚实；你在我隐密处，必使我得智慧。"},
{volumn:        19,chapter:        51,versenumber:         7,verse:"求你用牛膝草洁净我，我就干净；求你洗涤我，我就比雪更白。"},
{volumn:        19,chapter:        51,versenumber:         8,verse:"求你使我得听欢喜快乐的声音，使你所压伤的骨头可以踊跃。"},
{volumn:        19,chapter:        51,versenumber:         9,verse:"求你掩面不看我的罪，涂抹我一切的罪孽。"},
{volumn:        19,chapter:        51,versenumber:        10,verse:"神啊，求你为我造清洁的心，使我里面重新有正直（或译：坚定）的灵。"},
{volumn:        19,chapter:        51,versenumber:        11,verse:"不要丢弃我，使我离开你的面；不要从我收回你的圣灵。"},
{volumn:        19,chapter:        51,versenumber:        12,verse:"求你使我仍得救恩之乐，赐我乐意的灵扶持我，"},
{volumn:        19,chapter:        51,versenumber:        13,verse:"我就把你的道指教有过犯的人，罪人必归顺你。"},
{volumn:        19,chapter:        51,versenumber:        14,verse:"神啊，你是拯救我的　神；求你救我脱离流人血的罪！我的舌头就高声歌唱你的公义。"},
{volumn:        19,chapter:        51,versenumber:        15,verse:"主啊，求你使我嘴唇张开，我的口便传扬赞美你的话！"},
{volumn:        19,chapter:        51,versenumber:        16,verse:"你本不喜爱祭物，若喜爱，我就献上；燔祭，你也不喜悦。"},
{volumn:        19,chapter:        51,versenumber:        17,verse:"神所要的祭就是忧伤的灵；　神啊，忧伤痛悔的心，你必不轻看。"},
{volumn:        19,chapter:        51,versenumber:        18,verse:"求你随你的美意善待锡安，建造耶路撒冷的城墙。"},
{volumn:        19,chapter:        51,versenumber:        19,verse:"那时，你必喜爱公义的祭和燔祭并全牲的燔祭；那时，人必将公牛献在你坛上。"},
{volumn:        19,chapter:        52,versenumber:         1,verse:"勇士啊，你为何以作恶自夸？　神的慈爱是常存的。"},
{volumn:        19,chapter:        52,versenumber:         2,verse:"你的舌头邪恶诡诈，好像剃头刀，快利伤人。"},
{volumn:        19,chapter:        52,versenumber:         3,verse:"你爱恶胜似爱善，又爱说谎，不爱说公义。（细拉）"},
{volumn:        19,chapter:        52,versenumber:         4,verse:"诡诈的舌头啊，你爱说一切毁灭的话！"},
{volumn:        19,chapter:        52,versenumber:         5,verse:"神也要毁灭你，直到永远；他要把你拿去，从你的帐棚中抽出，从活人之地将你拔出。（细拉）"},
{volumn:        19,chapter:        52,versenumber:         6,verse:"义人要看见而惧怕，并要笑他，"},
{volumn:        19,chapter:        52,versenumber:         7,verse:"说：“看哪，这就是那不以　神为他力量的人，只倚仗他丰富的财物，在邪恶上坚立自己。”"},
{volumn:        19,chapter:        52,versenumber:         8,verse:"至于我，就像　神殿中的青橄榄树；我永永远远倚靠　神的慈爱。"},
{volumn:        19,chapter:        52,versenumber:         9,verse:"我要称谢你，直到永远，因为你行了这事。我也要在你圣民面前仰望你的名；这名本为美好。"},
{volumn:        19,chapter:        53,versenumber:         1,verse:"愚顽人心里说：没有　神。他们都是邪恶，行了可憎恶的罪孽；没有一个人行善。"},
{volumn:        19,chapter:        53,versenumber:         2,verse:"神从天上垂看世人，要看有明白的没有？有寻求他的没有？"},
{volumn:        19,chapter:        53,versenumber:         3,verse:"他们各人都退后，一同变为污秽；并没有行善的，连一个也没有。"},
{volumn:        19,chapter:        53,versenumber:         4,verse:"作孽的没有知识吗？他们吞吃我的百姓如同吃饭一样，并不求告　神。"},
{volumn:        19,chapter:        53,versenumber:         5,verse:"他们在无可惧怕之处就大大害怕，因为　神把那安营攻击你之人的骨头散开了。你使他们蒙羞，因为　神弃绝了他们。"},
{volumn:        19,chapter:        53,versenumber:         6,verse:"但愿以色列的救恩从锡安而出，　神救回他被掳的子民。那时，雅各要快乐，以色列要欢喜。"},
{volumn:        19,chapter:        54,versenumber:         1,verse:"神啊，求你以你的名救我，凭你的大能为我伸冤。"},
{volumn:        19,chapter:        54,versenumber:         2,verse:"神啊，求你听我的祷告，留心听我口中的言语。"},
{volumn:        19,chapter:        54,versenumber:         3,verse:"因为，外人起来攻击我，强暴人寻索我的命；他们眼中没有　神。（细拉）"},
{volumn:        19,chapter:        54,versenumber:         4,verse:"神是帮助我的，是扶持我命的。"},
{volumn:        19,chapter:        54,versenumber:         5,verse:"他要报应我仇敌所行的恶；求你凭你的诚实灭绝他们。"},
{volumn:        19,chapter:        54,versenumber:         6,verse:"我要把甘心祭献给你。耶和华啊，我要称赞你的名；这名本为美好。"},
{volumn:        19,chapter:        54,versenumber:         7,verse:"他从一切的急难中把我救出来；我的眼睛也看见了我仇敌遭报。"},
{volumn:        19,chapter:        55,versenumber:         1,verse:"神啊，求你留心听我的祷告，不要隐藏不听我的恳求！"},
{volumn:        19,chapter:        55,versenumber:         2,verse:"求你侧耳听我，应允我。我哀叹不安，发声唉哼，"},
{volumn:        19,chapter:        55,versenumber:         3,verse:"都因仇敌的声音，恶人的欺压；因为他们将罪孽加在我身上，发怒气逼迫我。"},
{volumn:        19,chapter:        55,versenumber:         4,verse:"我心在我里面甚是疼痛；死的惊惶临到我身。"},
{volumn:        19,chapter:        55,versenumber:         5,verse:"恐惧战兢归到我身；惊恐漫过了我。"},
{volumn:        19,chapter:        55,versenumber:         6,verse:"我说：“但愿我有翅膀像鸽子，我就飞去，得享安息。"},
{volumn:        19,chapter:        55,versenumber:         7,verse:"我必远游，宿在旷野。（细拉）"},
{volumn:        19,chapter:        55,versenumber:         8,verse:"我必速速逃到避所，脱离狂风暴雨。”"},
{volumn:        19,chapter:        55,versenumber:         9,verse:"主啊，求你吞灭他们，变乱他们的舌头！因为我在城中见了强暴争竞的事。"},
{volumn:        19,chapter:        55,versenumber:        10,verse:"他们在城墙上昼夜绕行；在城内也有罪孽和奸恶。"},
{volumn:        19,chapter:        55,versenumber:        11,verse:"邪恶在其中；欺压和诡诈不离街市。"},
{volumn:        19,chapter:        55,versenumber:        12,verse:"原来不是仇敌辱骂我，若是仇敌，还可忍耐；也不是恨我的人向我狂大，若是恨我的人就必躲避他。"},
{volumn:        19,chapter:        55,versenumber:        13,verse:"不料是你；你原与我平等，是我的同伴，是我知己的朋友！"},
{volumn:        19,chapter:        55,versenumber:        14,verse:"我们素常彼此谈论，以为甘甜；我们与群众在　神的殿中同行。"},
{volumn:        19,chapter:        55,versenumber:        15,verse:"愿死亡忽然临到他们！愿他们活活地下入阴间！因为他们的住处，他们的心中，都是邪恶。"},
{volumn:        19,chapter:        55,versenumber:        16,verse:"至于我，我要求告　神；耶和华必拯救我。"},
{volumn:        19,chapter:        55,versenumber:        17,verse:"我要晚上、早晨、晌午哀声悲叹；他也必听我的声音。"},
{volumn:        19,chapter:        55,versenumber:        18,verse:"他救赎我命脱离攻击我的人，使我得享平安，因为与我相争的人甚多。"},
{volumn:        19,chapter:        55,versenumber:        19,verse:"那没有更变、不敬畏　神的人，从太古常存的　神必听见而苦待他。"},
{volumn:        19,chapter:        55,versenumber:        20,verse:"他背了约，伸手攻击与他和好的人。"},
{volumn:        19,chapter:        55,versenumber:        21,verse:"他的口如奶油光滑，他的心却怀着争战；他的话比油柔和，其实是拔出来的刀。"},
{volumn:        19,chapter:        55,versenumber:        22,verse:"你要把你的重担卸给耶和华，他必抚养你；他永不叫义人动摇。"},
{volumn:        19,chapter:        55,versenumber:        23,verse:"神啊，你必使恶人下入灭亡的坑；流人血、行诡诈的人必活不到半世，但我要倚靠你。"},
{volumn:        19,chapter:        56,versenumber:         1,verse:"神啊，求你怜悯我，因为人要把我吞了，终日攻击欺压我。"},
{volumn:        19,chapter:        56,versenumber:         2,verse:"我的仇敌终日要把我吞了，因逞骄傲攻击我的人甚多。"},
{volumn:        19,chapter:        56,versenumber:         3,verse:"我惧怕的时候要倚靠你。"},
{volumn:        19,chapter:        56,versenumber:         4,verse:"我倚靠　神，我要赞美他的话；我倚靠　神，必不惧怕。血气之辈能把我怎么样呢？"},
{volumn:        19,chapter:        56,versenumber:         5,verse:"他们终日颠倒我的话；他们一切的心思都是要害我。"},
{volumn:        19,chapter:        56,versenumber:         6,verse:"他们聚集，埋伏窥探我的脚踪，等候要害我的命。"},
{volumn:        19,chapter:        56,versenumber:         7,verse:"他们岂能因罪孽逃脱吗？　神啊，求你在怒中使众民堕落！"},
{volumn:        19,chapter:        56,versenumber:         8,verse:"我几次流离，你都记数；求你把我眼泪装在你的皮袋里。这不都记在你册子上吗？"},
{volumn:        19,chapter:        56,versenumber:         9,verse:"我呼求的日子，我的仇敌都要转身退后。　神帮助我，这是我所知道的。"},
{volumn:        19,chapter:        56,versenumber:        10,verse:"我倚靠　神，我要赞美他的话；我倚靠耶和华，我要赞美他的话。"},
{volumn:        19,chapter:        56,versenumber:        11,verse:"我倚靠　神，必不惧怕。人能把我怎么样呢？"},
{volumn:        19,chapter:        56,versenumber:        12,verse:"神啊，我向你所许的愿在我身上；我要将感谢祭献给你。"},
{volumn:        19,chapter:        56,versenumber:        13,verse:"因为你救我的命脱离死亡。你岂不是救护我的脚不跌倒、使我在生命光中行在　神面前吗？"},
{volumn:        19,chapter:        57,versenumber:         1,verse:"神啊，求你怜悯我，怜悯我！因为我的心投靠你。我要投靠在你翅膀的荫下，等到灾害过去。"},
{volumn:        19,chapter:        57,versenumber:         2,verse:"我要求告至高的　神，就是为我成全诸事的　神。"},
{volumn:        19,chapter:        57,versenumber:         3,verse:"那要吞我的人辱骂我的时候，　神从天上必施恩救我，也必向我发出慈爱和诚实。"},
{volumn:        19,chapter:        57,versenumber:         4,verse:"我的性命在狮子中间；我躺卧在性如烈火的世人当中。他们的牙齿是枪、箭；他们的舌头是快刀。"},
{volumn:        19,chapter:        57,versenumber:         5,verse:"神啊，愿你崇高过于诸天！愿你的荣耀高过全地！"},
{volumn:        19,chapter:        57,versenumber:         6,verse:"他们为我的脚设下网罗，压制我的心；他们在我面前挖了坑，自己反掉在其中。（细拉）"},
{volumn:        19,chapter:        57,versenumber:         7,verse:"神啊，我心坚定，我心坚定；我要唱诗，我要歌颂！"},
{volumn:        19,chapter:        57,versenumber:         8,verse:"我的灵（原文是荣耀）啊，你当醒起！琴瑟啊，你们当醒起！我自己要极早醒起！"},
{volumn:        19,chapter:        57,versenumber:         9,verse:"主啊，我要在万民中称谢你，在列邦中歌颂你！"},
{volumn:        19,chapter:        57,versenumber:        10,verse:"因为，你的慈爱高及诸天；你的诚实达到穹苍。"},
{volumn:        19,chapter:        57,versenumber:        11,verse:"神啊，愿你崇高过于诸天！愿你的荣耀高过全地！"},
{volumn:        19,chapter:        58,versenumber:         1,verse:"世人哪，你们默然不语，真合公义吗？施行审判，岂按正直吗？"},
{volumn:        19,chapter:        58,versenumber:         2,verse:"不然！你们是心中作恶；你们在地上秤出你们手所行的强暴。"},
{volumn:        19,chapter:        58,versenumber:         3,verse:"恶人一出母胎就与　神疏远，一离母腹便走错路，说谎话。"},
{volumn:        19,chapter:        58,versenumber:         4,verse:"他们的毒气好像蛇的毒气；他们好像塞耳的聋虺，"},
{volumn:        19,chapter:        58,versenumber:         5,verse:"不听行法术的声音，虽用极灵的咒语也是不听。"},
{volumn:        19,chapter:        58,versenumber:         6,verse:"神啊，求你敲碎他们口中的牙！耶和华啊，求你敲掉少壮狮子的大牙！"},
{volumn:        19,chapter:        58,versenumber:         7,verse:"愿他们消灭，如急流的水一般；他们瞅准射箭的时候，愿箭头仿佛砍断。"},
{volumn:        19,chapter:        58,versenumber:         8,verse:"愿他们像蜗牛消化过去，又像妇人坠落未见天日的胎。"},
{volumn:        19,chapter:        58,versenumber:         9,verse:"你们用荆棘烧火，锅还未热，他要用旋风把青的和烧着的一齐刮去。"},
{volumn:        19,chapter:        58,versenumber:        10,verse:"义人见仇敌遭报就欢喜，要在恶人的血中洗脚。"},
{volumn:        19,chapter:        58,versenumber:        11,verse:"因此，人必说：“义人诚然有善报；在地上果有施行判断的　神！”"},
{volumn:        19,chapter:        59,versenumber:         1,verse:"我的　神啊，求你救我脱离仇敌，把我安置在高处，得脱那些起来攻击我的人。"},
{volumn:        19,chapter:        59,versenumber:         2,verse:"求你救我脱离作孽的人和喜爱流人血的人！"},
{volumn:        19,chapter:        59,versenumber:         3,verse:"因为，他们埋伏要害我的命；有能力的人聚集来攻击我。耶和华啊，这不是为我的过犯，也不是为我的罪愆。"},
{volumn:        19,chapter:        59,versenumber:         4,verse:"我虽然无过，他们预备整齐，跑来攻击我。求你兴起鉴察，帮助我！"},
{volumn:        19,chapter:        59,versenumber:         5,verse:"万军之　神耶和华以色列的　神啊！求你兴起，惩治万邦！不要怜悯行诡诈的恶人！（细拉）"},
{volumn:        19,chapter:        59,versenumber:         6,verse:"他们晚上转回，叫号如狗，围城绕行。"},
{volumn:        19,chapter:        59,versenumber:         7,verse:"他们口中喷吐恶言，嘴里有刀；他们说：“有谁听见？”"},
{volumn:        19,chapter:        59,versenumber:         8,verse:"但你耶和华必笑话他们；你要嗤笑万邦。"},
{volumn:        19,chapter:        59,versenumber:         9,verse:"我的力量啊，我必仰望你，因为　神是我的高台。"},
{volumn:        19,chapter:        59,versenumber:        10,verse:"我的　神要以慈爱迎接我；　神要叫我看见我仇敌遭报。"},
{volumn:        19,chapter:        59,versenumber:        11,verse:"不要杀他们，恐怕我的民忘记。主啊，你是我们的盾牌；求你用你的能力使他们四散，且降为卑。"},
{volumn:        19,chapter:        59,versenumber:        12,verse:"因他们口中的罪和嘴里的言语，并咒骂虚谎的话，愿他们在骄傲之中被缠住了。"},
{volumn:        19,chapter:        59,versenumber:        13,verse:"求你发怒，使他们消灭，以致归于无有，叫他们知道　神在雅各中间掌权，直到地极。（细拉）"},
{volumn:        19,chapter:        59,versenumber:        14,verse:"到了晚上，任凭他们转回；任凭他们叫号如狗，围城绕行。"},
{volumn:        19,chapter:        59,versenumber:        15,verse:"他们必走来走去，寻找食物，若不得饱就终夜在外。"},
{volumn:        19,chapter:        59,versenumber:        16,verse:"但我要歌颂你的力量，早晨要高唱你的慈爱；因为你作过我的高台，在我急难的日子作过我的避难所。"},
{volumn:        19,chapter:        59,versenumber:        17,verse:"我的力量啊，我要歌颂你；因为　神是我的高台，是赐恩与我的　神。"},
{volumn:        19,chapter:        60,versenumber:         1,verse:"神啊，你丢弃了我们，使我们破败；你向我们发怒，求你使我们复兴！"},
{volumn:        19,chapter:        60,versenumber:         2,verse:"你使地震动，而且崩裂；求你将裂口医好，因为地摇动。"},
{volumn:        19,chapter:        60,versenumber:         3,verse:"你叫你的民遇见艰难；你叫我们喝那使人东倒西歪的酒。"},
{volumn:        19,chapter:        60,versenumber:         4,verse:"你把旌旗赐给敬畏你的人，可以为真理扬起来。（细拉）"},
{volumn:        19,chapter:        60,versenumber:         5,verse:"求你应允我们，用右手拯救我们，好叫你所亲爱的人得救。"},
{volumn:        19,chapter:        60,versenumber:         6,verse:"神已经指着他的圣洁说（说：或译应许我）：我要欢乐；我要分开示剑，丈量疏割谷。"},
{volumn:        19,chapter:        60,versenumber:         7,verse:"基列是我的，玛拿西也是我的。以法莲是护卫我头的；犹大是我的杖。"},
{volumn:        19,chapter:        60,versenumber:         8,verse:"摩押是我的沐浴盆；我要向以东抛鞋。非利士啊，你还能因我欢呼吗？"},
{volumn:        19,chapter:        60,versenumber:         9,verse:"谁能领我进坚固城？谁能引我到以东地？"},
{volumn:        19,chapter:        60,versenumber:        10,verse:"神啊，你不是丢弃了我们吗？　神啊，你不和我们的军兵同去吗？"},
{volumn:        19,chapter:        60,versenumber:        11,verse:"求你帮助我们攻击敌人，因为人的帮助是枉然的。"},
{volumn:        19,chapter:        60,versenumber:        12,verse:"我们倚靠　神才得施展大能，因为践踏我们敌人的就是他。"},
{volumn:        19,chapter:        61,versenumber:         1,verse:"神啊，求你听我的呼求，侧耳听我的祷告！"},
{volumn:        19,chapter:        61,versenumber:         2,verse:"我心里发昏的时候，我要从地极求告你。求你领我到那比我更高的磐石！"},
{volumn:        19,chapter:        61,versenumber:         3,verse:"因为你作过我的避难所，作过我的坚固台，脱离仇敌。"},
{volumn:        19,chapter:        61,versenumber:         4,verse:"我要永远住在你的帐幕里！我要投靠在你翅膀下的隐密处！（细拉）"},
{volumn:        19,chapter:        61,versenumber:         5,verse:"神啊，你原是听了我所许的愿；你将产业赐给敬畏你名的人。"},
{volumn:        19,chapter:        61,versenumber:         6,verse:"你要加添王的寿数；他的年岁必存到世世。"},
{volumn:        19,chapter:        61,versenumber:         7,verse:"他必永远坐在　神面前；愿你预备慈爱和诚实保佑他！"},
{volumn:        19,chapter:        61,versenumber:         8,verse:"这样，我要歌颂你的名，直到永远，好天天还我所许的愿。"},
{volumn:        19,chapter:        62,versenumber:         1,verse:"我的心默默无声，专等候　神；我的救恩是从他而来。"},
{volumn:        19,chapter:        62,versenumber:         2,verse:"惟独他是我的磐石，我的拯救；他是我的高台，我必不很动摇。"},
{volumn:        19,chapter:        62,versenumber:         3,verse:"你们大家攻击一人，把他毁坏，如同毁坏歪斜的墙、将倒的壁，要到几时呢？"},
{volumn:        19,chapter:        62,versenumber:         4,verse:"他们彼此商议，专要从他的尊位上把他推下；他们喜爱谎话，口虽祝福，心却咒诅。（细拉）"},
{volumn:        19,chapter:        62,versenumber:         5,verse:"我的心哪，你当默默无声，专等候　神，因为我的盼望是从他而来。"},
{volumn:        19,chapter:        62,versenumber:         6,verse:"惟独他是我的磐石，我的拯救；他是我的高台，我必不动摇。"},
{volumn:        19,chapter:        62,versenumber:         7,verse:"我的拯救、我的荣耀都在乎　神；我力量的磐石、我的避难所都在乎　神。"},
{volumn:        19,chapter:        62,versenumber:         8,verse:"你们众民当时时倚靠他，在他面前倾心吐意；　神是我们的避难所。（细拉）"},
{volumn:        19,chapter:        62,versenumber:         9,verse:"下流人真是虚空；上流人也是虚假；放在天平里就必浮起；他们一共比空气还轻。"},
{volumn:        19,chapter:        62,versenumber:        10,verse:"不要仗势欺人，也不要因抢夺而骄傲；若财宝加增，不要放在心上。"},
{volumn:        19,chapter:        62,versenumber:        11,verse:"神说了一次、两次，我都听见：就是能力都属乎　神。"},
{volumn:        19,chapter:        62,versenumber:        12,verse:"主啊，慈爱也是属乎你，因为你照着各人所行的报应他。"},
{volumn:        19,chapter:        63,versenumber:         1,verse:"神啊，你是我的　神，我要切切地寻求你，在干旱疲乏无水之地，我渴想你；我的心切慕你。"},
{volumn:        19,chapter:        63,versenumber:         2,verse:"我在圣所中曾如此瞻仰你，为要见你的能力和你的荣耀。"},
{volumn:        19,chapter:        63,versenumber:         3,verse:"因你的慈爱比生命更好，我的嘴唇要颂赞你。"},
{volumn:        19,chapter:        63,versenumber:         4,verse:"我还活的时候要这样称颂你；我要奉你的名举手。"},
{volumn:        19,chapter:        63,versenumber:         5,verse:"我在床上记念你，在夜更的时候思想你；我的心就像饱足了骨髓肥油，我也要以欢乐的嘴唇赞美你。"},
{volumn:        19,chapter:        63,versenumber:         6,verse:""},
{volumn:        19,chapter:        63,versenumber:         7,verse:"因为你曾帮助我，我就在你翅膀的荫下欢呼。"},
{volumn:        19,chapter:        63,versenumber:         8,verse:"我心紧紧地跟随你；你的右手扶持我。"},
{volumn:        19,chapter:        63,versenumber:         9,verse:"但那些寻索要灭我命的人必往地底下去；"},
{volumn:        19,chapter:        63,versenumber:        10,verse:"他们必被刀剑所杀，被野狗所吃。"},
{volumn:        19,chapter:        63,versenumber:        11,verse:"但是王必因　神欢喜。凡指着他发誓的必要夸口，因为说谎之人的口必被塞住。"},
{volumn:        19,chapter:        64,versenumber:         1,verse:"神啊，我哀叹的时候，求你听我的声音！求你保护我的性命，不受仇敌的惊恐！"},
{volumn:        19,chapter:        64,versenumber:         2,verse:"求你把我隐藏，使我脱离作恶之人的暗谋和作孽之人的扰乱。"},
{volumn:        19,chapter:        64,versenumber:         3,verse:"他们磨舌如刀，发出苦毒的言语，好像比准了的箭，"},
{volumn:        19,chapter:        64,versenumber:         4,verse:"要在暗地射完全人；他们忽然射他，并不惧怕。"},
{volumn:        19,chapter:        64,versenumber:         5,verse:"他们彼此勉励设下恶计；他们商量暗设网罗，说：“谁能看见？”"},
{volumn:        19,chapter:        64,versenumber:         6,verse:"他们图谋奸恶，说：“我们是极力图谋的。”他们各人的意念心思是深的。"},
{volumn:        19,chapter:        64,versenumber:         7,verse:"但　神要射他们；他们忽然被箭射伤。"},
{volumn:        19,chapter:        64,versenumber:         8,verse:"他们必然绊跌，被自己的舌头所害；凡看见他们的必都摇头。"},
{volumn:        19,chapter:        64,versenumber:         9,verse:"众人都要害怕，要传扬　神的工作，并且明白他的作为。"},
{volumn:        19,chapter:        64,versenumber:        10,verse:"义人必因耶和华欢喜，并要投靠他；凡心里正直的人都要夸口。"},
{volumn:        19,chapter:        65,versenumber:         1,verse:"神啊，锡安的人都等候赞美你；所许的愿也要向你偿还。"},
{volumn:        19,chapter:        65,versenumber:         2,verse:"听祷告的主啊，凡有血气的都要来就你。"},
{volumn:        19,chapter:        65,versenumber:         3,verse:"罪孽胜了我；至于我们的过犯，你都要赦免。"},
{volumn:        19,chapter:        65,versenumber:         4,verse:"你所拣选、使他亲近你、住在你院中的，这人便为有福！我们必因你居所、你圣殿的美福知足了。"},
{volumn:        19,chapter:        65,versenumber:         5,verse:"拯救我们的　神啊，你必以威严秉公义应允我们；你本是一切地极和海上远处的人所倚靠的。"},
{volumn:        19,chapter:        65,versenumber:         6,verse:"他既以大能束腰，就用力量安定诸山，"},
{volumn:        19,chapter:        65,versenumber:         7,verse:"使诸海的响声和其中波浪的响声，并万民的喧哗，都平静了。"},
{volumn:        19,chapter:        65,versenumber:         8,verse:"住在地极的人因你的神迹惧怕；你使日出日落之地都欢呼。"},
{volumn:        19,chapter:        65,versenumber:         9,verse:"你眷顾地，降下透雨，使地大得肥美。　神的河满了水；你这样浇灌了地，好为人预备五谷。"},
{volumn:        19,chapter:        65,versenumber:        10,verse:"你浇透地的犁沟，润平犁脊，降甘霖，使地软和；其中发长的，蒙你赐福。"},
{volumn:        19,chapter:        65,versenumber:        11,verse:"你以恩典为年岁的冠冕；你的路径都滴下脂油，"},
{volumn:        19,chapter:        65,versenumber:        12,verse:"滴在旷野的草场上。小山以欢乐束腰；"},
{volumn:        19,chapter:        65,versenumber:        13,verse:"草场以羊群为衣；谷中也长满了五谷。这一切都欢呼歌唱。"},
{volumn:        19,chapter:        66,versenumber:         1,verse:"全地都当向　神欢呼！"},
{volumn:        19,chapter:        66,versenumber:         2,verse:"歌颂他名的荣耀！用赞美的言语将他的荣耀发明！"},
{volumn:        19,chapter:        66,versenumber:         3,verse:"当对　神说：“你的作为何等可畏！因你的大能，仇敌要投降你。"},
{volumn:        19,chapter:        66,versenumber:         4,verse:"全地要敬拜你，歌颂你，要歌颂你的名。”（细拉）"},
{volumn:        19,chapter:        66,versenumber:         5,verse:"你们来看　神所行的，他向世人所做之事是可畏的。"},
{volumn:        19,chapter:        66,versenumber:         6,verse:"他将海变成干地，众民步行过河；我们在那里因他欢喜。"},
{volumn:        19,chapter:        66,versenumber:         7,verse:"他用权能治理万民，直到永远。他的眼睛鉴察列邦；悖逆的人不可自高。（细拉）"},
{volumn:        19,chapter:        66,versenumber:         8,verse:"万民哪，你们当称颂我们的　神，使人得听赞美他的声音。"},
{volumn:        19,chapter:        66,versenumber:         9,verse:"他使我们的性命存活，也不叫我们的脚摇动。"},
{volumn:        19,chapter:        66,versenumber:        10,verse:"神啊，你曾试验我们，熬炼我们，如熬炼银子一样。"},
{volumn:        19,chapter:        66,versenumber:        11,verse:"你使我们进入网罗，把重担放在我们的身上。"},
{volumn:        19,chapter:        66,versenumber:        12,verse:"你使人坐车轧我们的头；我们经过水火，你却使我们到丰富之地。"},
{volumn:        19,chapter:        66,versenumber:        13,verse:"我要用燔祭进你的殿，向你还我的愿，"},
{volumn:        19,chapter:        66,versenumber:        14,verse:"就是在急难时我嘴唇所发的、口中所许的。"},
{volumn:        19,chapter:        66,versenumber:        15,verse:"我要把肥牛作燔祭，将公羊的香祭献给你，又把公牛和山羊献上。（细拉）"},
{volumn:        19,chapter:        66,versenumber:        16,verse:"凡敬畏　神的人，你们都来听！我要述说他为我所行的事。"},
{volumn:        19,chapter:        66,versenumber:        17,verse:"我曾用口求告他；我的舌头也称他为高。"},
{volumn:        19,chapter:        66,versenumber:        18,verse:"我若心里注重罪孽，主必不听。"},
{volumn:        19,chapter:        66,versenumber:        19,verse:"但　神实在听见了；他侧耳听了我祷告的声音。"},
{volumn:        19,chapter:        66,versenumber:        20,verse:"神是应当称颂的！他并没有推却我的祷告，也没有叫他的慈爱离开我。"},
{volumn:        19,chapter:        67,versenumber:         1,verse:"愿　神怜悯我们，赐福与我们，用脸光照我们，（细拉）"},
{volumn:        19,chapter:        67,versenumber:         2,verse:"好叫世界得知你的道路，万国得知你的救恩。"},
{volumn:        19,chapter:        67,versenumber:         3,verse:"神啊，愿列邦称赞你！愿万民都称赞你！"},
{volumn:        19,chapter:        67,versenumber:         4,verse:"愿万国都快乐欢呼；因为你必按公正审判万民，引导世上的万国。（细拉）"},
{volumn:        19,chapter:        67,versenumber:         5,verse:"神啊，愿列邦称赞你！愿万民都称赞你！"},
{volumn:        19,chapter:        67,versenumber:         6,verse:"地已经出了土产；　神，就是我们的　神要赐福与我们。"},
{volumn:        19,chapter:        67,versenumber:         7,verse:"神要赐福与我们；地的四极都要敬畏他！"},
{volumn:        19,chapter:        68,versenumber:         1,verse:"愿　神兴起，使他的仇敌四散，叫那恨他的人从他面前逃跑。"},
{volumn:        19,chapter:        68,versenumber:         2,verse:"他们被驱逐，如烟被风吹散；恶人见　神之面而消灭，如蜡被火熔化。"},
{volumn:        19,chapter:        68,versenumber:         3,verse:"惟有义人必然欢喜，在　神面前高兴快乐。"},
{volumn:        19,chapter:        68,versenumber:         4,verse:"你们当向　神唱诗，歌颂他的名；为那坐车行过旷野的修平大路。他的名是耶和华，要在他面前欢乐！"},
{volumn:        19,chapter:        68,versenumber:         5,verse:"神在他的圣所作孤儿的父，作寡妇的伸冤者。"},
{volumn:        19,chapter:        68,versenumber:         6,verse:"神叫孤独的有家，使被囚的出来享福；惟有悖逆的住在干燥之地。"},
{volumn:        19,chapter:        68,versenumber:         7,verse:"神啊，你曾在你百姓前头出来，在旷野行走。（细拉）"},
{volumn:        19,chapter:        68,versenumber:         8,verse:"那时，地见　神的面而震动，天也落雨；西奈山见以色列　神的面也震动。"},
{volumn:        19,chapter:        68,versenumber:         9,verse:"神啊，你降下大雨；你产业以色列疲乏的时候，你使他坚固。"},
{volumn:        19,chapter:        68,versenumber:        10,verse:"你的会众住在其中；　神啊，你的恩惠是为困苦人预备的。"},
{volumn:        19,chapter:        68,versenumber:        11,verse:"主发命令，传好信息的妇女成了大群。"},
{volumn:        19,chapter:        68,versenumber:        12,verse:"统兵的君王逃跑了，逃跑了；在家等候的妇女分受所夺的。"},
{volumn:        19,chapter:        68,versenumber:        13,verse:"你们安卧在羊圈的时候，好像鸽子的翅膀镀白银，翎毛镀黄金一般。"},
{volumn:        19,chapter:        68,versenumber:        14,verse:"全能者在境内赶散列王的时候，势如飘雪在撒们。"},
{volumn:        19,chapter:        68,versenumber:        15,verse:"巴珊山是　神的山；巴珊山是多峰多岭的山。"},
{volumn:        19,chapter:        68,versenumber:        16,verse:"你们多峰多岭的山哪，为何斜看　神所愿居住的山？耶和华必住这山，直到永远！"},
{volumn:        19,chapter:        68,versenumber:        17,verse:"神的车辇累万盈千；主在其中，好像在西奈圣山一样。"},
{volumn:        19,chapter:        68,versenumber:        18,verse:"你已经升上高天，掳掠仇敌；你在人间，就是在悖逆的人间，受了供献，叫耶和华　神可以与他们同住。"},
{volumn:        19,chapter:        68,versenumber:        19,verse:"天天背负我们重担的主，就是拯救我们的　神，是应当称颂的！（细拉）"},
{volumn:        19,chapter:        68,versenumber:        20,verse:"神是为我们施行诸般救恩的　神；人能脱离死亡是在乎主耶和华。"},
{volumn:        19,chapter:        68,versenumber:        21,verse:"但　神要打破他仇敌的头，就是那常犯罪之人的发顶。"},
{volumn:        19,chapter:        68,versenumber:        22,verse:"主说：“我要使众民从巴珊而归，使他们从深海而回，"},
{volumn:        19,chapter:        68,versenumber:        23,verse:"使你打碎仇敌，你的脚踹在血中，使你狗的舌头从其中得份。”"},
{volumn:        19,chapter:        68,versenumber:        24,verse:"神啊，你是我的　神，我的王；人已经看见你行走，进入圣所。"},
{volumn:        19,chapter:        68,versenumber:        25,verse:"歌唱的行在前，作乐的随在后，都在击鼓的童女中间。"},
{volumn:        19,chapter:        68,versenumber:        26,verse:"从以色列源头而来的，当在各会中称颂主　神！"},
{volumn:        19,chapter:        68,versenumber:        27,verse:"在那里，有统管他们的小便雅悯，有犹大的首领和他们的群众，有西布伦的首领，有拿弗他利的首领。"},
{volumn:        19,chapter:        68,versenumber:        28,verse:"以色列的能力是　神所赐的；　神啊，求你坚固你为我们所成全的事！"},
{volumn:        19,chapter:        68,versenumber:        29,verse:"因你耶路撒冷的殿，列王必带贡物献给你。"},
{volumn:        19,chapter:        68,versenumber:        30,verse:"求你叱喝芦苇中的野兽和群公牛，并列邦中的牛犊。把银块踹在脚下；　神已经赶散好争战的列邦。"},
{volumn:        19,chapter:        68,versenumber:        31,verse:"埃及的公侯要出来朝见　神；古实人要急忙举手祷告。"},
{volumn:        19,chapter:        68,versenumber:        32,verse:"世上的列国啊，你们要向　神歌唱；愿你们歌颂主！"},
{volumn:        19,chapter:        68,versenumber:        33,verse:"歌颂那自古驾行在诸天以上的主！他发出声音，是极大的声音。"},
{volumn:        19,chapter:        68,versenumber:        34,verse:"你们要将能力归给　神。他的威荣在以色列之上；他的能力是在穹苍。"},
{volumn:        19,chapter:        68,versenumber:        35,verse:"神啊，你从圣所显为可畏；以色列的　神是那将力量权能赐给他百姓的。　神是应当称颂的！"},
{volumn:        19,chapter:        69,versenumber:         1,verse:"神啊，求你救我！因为众水要淹没我。"},
{volumn:        19,chapter:        69,versenumber:         2,verse:"我陷在深淤泥中，没有立脚之地；我到了深水中，大水漫过我身。"},
{volumn:        19,chapter:        69,versenumber:         3,verse:"我因呼求困乏，喉咙发干；我因等候　神，眼睛失明。"},
{volumn:        19,chapter:        69,versenumber:         4,verse:"无故恨我的，比我头发还多；无理与我为仇、要把我剪除的，甚为强盛。我没有抢夺的，要叫我偿还。"},
{volumn:        19,chapter:        69,versenumber:         5,verse:"神啊，我的愚昧，你原知道；我的罪愆不能隐瞒。"},
{volumn:        19,chapter:        69,versenumber:         6,verse:"万军的主耶和华啊，求你叫那等候你的，不要因我蒙羞！以色列的　神啊，求你叫那寻求你的，不要因我受辱！"},
{volumn:        19,chapter:        69,versenumber:         7,verse:"因我为你的缘故受了辱骂，满面羞愧。"},
{volumn:        19,chapter:        69,versenumber:         8,verse:"我的弟兄看我为外路人；我的同胞看我为外邦人。"},
{volumn:        19,chapter:        69,versenumber:         9,verse:"因我为你的殿心里焦急，如同火烧，并且辱骂你人的辱骂都落在我身上。"},
{volumn:        19,chapter:        69,versenumber:        10,verse:"我哭泣，以禁食刻苦我心；这倒算为我的羞辱。"},
{volumn:        19,chapter:        69,versenumber:        11,verse:"我拿麻布当衣裳，就成了他们的笑谈。"},
{volumn:        19,chapter:        69,versenumber:        12,verse:"坐在城门口的谈论我；酒徒也以我为歌曲。"},
{volumn:        19,chapter:        69,versenumber:        13,verse:"但我在悦纳的时候，向你耶和华祈祷。　神啊，求你按你丰盛的慈爱，凭你拯救的诚实应允我！"},
{volumn:        19,chapter:        69,versenumber:        14,verse:"求你搭救我出离淤泥，不叫我陷在其中；求你使我脱离那些恨我的人，使我出离深水。"},
{volumn:        19,chapter:        69,versenumber:        15,verse:"求你不容大水漫过我，不容深渊吞灭我，不容坑坎在我以上合口。"},
{volumn:        19,chapter:        69,versenumber:        16,verse:"耶和华啊，求你应允我！因为你的慈爱本为美好；求你按你丰盛的慈悲回转眷顾我！"},
{volumn:        19,chapter:        69,versenumber:        17,verse:"不要掩面不顾你的仆人；我是在急难之中，求你速速地应允我！"},
{volumn:        19,chapter:        69,versenumber:        18,verse:"求你亲近我，救赎我！求你因我的仇敌把我赎回！"},
{volumn:        19,chapter:        69,versenumber:        19,verse:"你知道我受的辱骂、欺凌、羞辱；我的敌人都在你面前。"},
{volumn:        19,chapter:        69,versenumber:        20,verse:"辱骂伤破了我的心，我又满了忧愁。我指望有人体恤，却没有一个；我指望有人安慰，却找不着一个。"},
{volumn:        19,chapter:        69,versenumber:        21,verse:"他们拿苦胆给我当食物；我渴了，他们拿醋给我喝。"},
{volumn:        19,chapter:        69,versenumber:        22,verse:"愿他们的筵席在他们面前变为网罗，在他们平安的时候变为机槛。"},
{volumn:        19,chapter:        69,versenumber:        23,verse:"愿他们的眼睛昏蒙，不得看见；愿你使他们的腰常常战抖。"},
{volumn:        19,chapter:        69,versenumber:        24,verse:"求你将你的恼恨倒在他们身上，叫你的烈怒追上他们。"},
{volumn:        19,chapter:        69,versenumber:        25,verse:"愿他们的住处变为荒场；愿他们的帐棚无人居住。"},
{volumn:        19,chapter:        69,versenumber:        26,verse:"因为，你所击打的，他们就逼迫；你所击伤的，他们戏说他的愁苦。"},
{volumn:        19,chapter:        69,versenumber:        27,verse:"愿你在他们的罪上加罪，不容他们在你面前称义。"},
{volumn:        19,chapter:        69,versenumber:        28,verse:"愿他们从生命册上被涂抹，不得记录在义人之中。"},
{volumn:        19,chapter:        69,versenumber:        29,verse:"但我是困苦忧伤的；　神啊，愿你的救恩将我安置在高处。"},
{volumn:        19,chapter:        69,versenumber:        30,verse:"我要以诗歌赞美　神的名，以感谢称他为大！"},
{volumn:        19,chapter:        69,versenumber:        31,verse:"这便叫耶和华喜悦，胜似献牛，或是献有角有蹄的公牛。"},
{volumn:        19,chapter:        69,versenumber:        32,verse:"谦卑的人看见了就喜乐；寻求　神的人，愿你们的心苏醒。"},
{volumn:        19,chapter:        69,versenumber:        33,verse:"因为耶和华听了穷乏人，不藐视被囚的人。"},
{volumn:        19,chapter:        69,versenumber:        34,verse:"愿天和地、洋海和其中一切的动物都赞美他！"},
{volumn:        19,chapter:        69,versenumber:        35,verse:"因为　神要拯救锡安，建造犹大的城邑；他的民要在那里居住，得以为业。"},
{volumn:        19,chapter:        69,versenumber:        36,verse:"他仆人的后裔要承受为业；爱他名的人也要住在其中。"},
{volumn:        19,chapter:        70,versenumber:         1,verse:"神啊，求你快快搭救我！耶和华啊，求你速速帮助我！"},
{volumn:        19,chapter:        70,versenumber:         2,verse:"愿那些寻索我命的，抱愧蒙羞；愿那些喜悦我遭害的，退后受辱。"},
{volumn:        19,chapter:        70,versenumber:         3,verse:"愿那些对我说“阿哈、阿哈”的，因羞愧退后。"},
{volumn:        19,chapter:        70,versenumber:         4,verse:"愿一切寻求你的，因你高兴欢喜；愿那些喜爱你救恩的，常说：“当尊　神为大！”"},
{volumn:        19,chapter:        70,versenumber:         5,verse:"但我是困苦穷乏的；　神啊，求你速速到我这里来！你是帮助我的，搭救我的。耶和华啊，求你不要耽延！"},
{volumn:        19,chapter:        71,versenumber:         1,verse:"耶和华啊，我投靠你；求你叫我永不羞愧！"},
{volumn:        19,chapter:        71,versenumber:         2,verse:"求你凭你的公义搭救我，救拔我；侧耳听我，拯救我！"},
{volumn:        19,chapter:        71,versenumber:         3,verse:"求你作我常住的磐石；你已经命定要救我，因为你是我的岩石，我的山寨。"},
{volumn:        19,chapter:        71,versenumber:         4,verse:"我的　神啊，求你救我脱离恶人的手，脱离不义和残暴之人的手。"},
{volumn:        19,chapter:        71,versenumber:         5,verse:"主耶和华啊，你是我所盼望的；从我年幼，你是我所倚靠的。"},
{volumn:        19,chapter:        71,versenumber:         6,verse:"我从出母胎被你扶持；使我出母腹的是你。我必常常赞美你！"},
{volumn:        19,chapter:        71,versenumber:         7,verse:"许多人以我为怪，但你是我坚固的避难所。"},
{volumn:        19,chapter:        71,versenumber:         8,verse:"你的赞美，你的荣耀终日必满了我的口。"},
{volumn:        19,chapter:        71,versenumber:         9,verse:"我年老的时候，求你不要丢弃我！我力气衰弱的时候，求你不要离弃我！"},
{volumn:        19,chapter:        71,versenumber:        10,verse:"我的仇敌议论我；那些窥探要害我命的彼此商议，"},
{volumn:        19,chapter:        71,versenumber:        11,verse:"说：“　神已经离弃他；我们追赶他，捉拿他吧！因为没有人搭救。”"},
{volumn:        19,chapter:        71,versenumber:        12,verse:"神啊，求你不要远离我！我的　神啊，求你速速帮助我！"},
{volumn:        19,chapter:        71,versenumber:        13,verse:"愿那与我性命为敌的，羞愧被灭；愿那谋害我的，受辱蒙羞。"},
{volumn:        19,chapter:        71,versenumber:        14,verse:"我却要常常盼望，并要越发赞美你。"},
{volumn:        19,chapter:        71,versenumber:        15,verse:"我的口终日要述说你的公义和你的救恩，因我不计其数。"},
{volumn:        19,chapter:        71,versenumber:        16,verse:"我要来说主耶和华大能的事；我单要提说你的公义。"},
{volumn:        19,chapter:        71,versenumber:        17,verse:"神啊，自我年幼时，你就教训我；直到如今，我传扬你奇妙的作为。"},
{volumn:        19,chapter:        71,versenumber:        18,verse:"神啊，我到年老发白的时候，求你不要离弃我！等我将你的能力指示下代，将你的大能指示后世的人。"},
{volumn:        19,chapter:        71,versenumber:        19,verse:"神啊，你的公义甚高；行过大事的　神啊，谁能像你！"},
{volumn:        19,chapter:        71,versenumber:        20,verse:"你是叫我们多经历重大急难的，必使我们复活，从地的深处救上来。"},
{volumn:        19,chapter:        71,versenumber:        21,verse:"求你使我越发昌大，又转来安慰我。"},
{volumn:        19,chapter:        71,versenumber:        22,verse:"我的　神啊，我要鼓瑟称赞你，称赞你的诚实！以色列的圣者啊，我要弹琴歌颂你！"},
{volumn:        19,chapter:        71,versenumber:        23,verse:"我歌颂你的时候，我的嘴唇和你所赎我的灵魂都必欢呼；"},
{volumn:        19,chapter:        71,versenumber:        24,verse:"并且我的舌头必终日讲论你的公义，因为那些谋害我的人已经蒙羞受辱了。"},
{volumn:        19,chapter:        72,versenumber:         1,verse:"神啊，求你将判断的权柄赐给王，将公义赐给王的儿子。"},
{volumn:        19,chapter:        72,versenumber:         2,verse:"他要按公义审判你的民，按公平审判你的困苦人。"},
{volumn:        19,chapter:        72,versenumber:         3,verse:"大山小山都要因公义使民得享平安。"},
{volumn:        19,chapter:        72,versenumber:         4,verse:"他必为民中的困苦人伸冤，拯救穷乏之辈，压碎那欺压人的。"},
{volumn:        19,chapter:        72,versenumber:         5,verse:"太阳还存，月亮还在，人要敬畏你，直到万代！"},
{volumn:        19,chapter:        72,versenumber:         6,verse:"他必降临，像雨降在已割的草地上，如甘霖滋润田地。"},
{volumn:        19,chapter:        72,versenumber:         7,verse:"在他的日子，义人要发旺，大有平安，好像月亮长存。"},
{volumn:        19,chapter:        72,versenumber:         8,verse:"他要执掌权柄，从这海直到那海，从大河直到地极。"},
{volumn:        19,chapter:        72,versenumber:         9,verse:"住在旷野的，必在他面前下拜；他的仇敌必要舔土。"},
{volumn:        19,chapter:        72,versenumber:        10,verse:"他施和海岛的王要进贡；示巴和西巴的王要献礼物。"},
{volumn:        19,chapter:        72,versenumber:        11,verse:"诸王都要叩拜他；万国都要侍奉他。"},
{volumn:        19,chapter:        72,versenumber:        12,verse:"因为，穷乏人呼求的时候，他要搭救；没有人帮助的困苦人，他也要搭救。"},
{volumn:        19,chapter:        72,versenumber:        13,verse:"他要怜恤贫寒和穷乏的人，拯救穷苦人的性命。"},
{volumn:        19,chapter:        72,versenumber:        14,verse:"他要救赎他们脱离欺压和强暴；他们的血在他眼中看为宝贵。"},
{volumn:        19,chapter:        72,versenumber:        15,verse:"他们要存活。示巴的金子要奉给他；人要常常为他祷告，终日称颂他。"},
{volumn:        19,chapter:        72,versenumber:        16,verse:"在地的山顶上，五谷必然茂盛（或译：有一把五谷）；所结的谷实要响动，如黎巴嫩的树林；城里的人要发旺，如地上的草。"},
{volumn:        19,chapter:        72,versenumber:        17,verse:"他的名要存到永远，要留传如日之久。人要因他蒙福；万国要称他有福。"},
{volumn:        19,chapter:        72,versenumber:        18,verse:"独行奇事的耶和华以色列的　神是应当称颂的！"},
{volumn:        19,chapter:        72,versenumber:        19,verse:"他荣耀的名也当称颂，直到永远。愿他的荣耀充满全地！阿们！阿们！"},
{volumn:        19,chapter:        72,versenumber:        20,verse:"耶西的儿子大卫的祈祷完毕。"},
{volumn:        19,chapter:        73,versenumber:         1,verse:"神实在恩待以色列那些清心的人！"},
{volumn:        19,chapter:        73,versenumber:         2,verse:"至于我，我的脚几乎失闪；我的脚险些滑跌。"},
{volumn:        19,chapter:        73,versenumber:         3,verse:"我见恶人和狂傲人享平安就心怀不平。"},
{volumn:        19,chapter:        73,versenumber:         4,verse:"他们死的时候没有疼痛；他们的力气却也壮实。"},
{volumn:        19,chapter:        73,versenumber:         5,verse:"他们不像别人受苦，也不像别人遭灾。"},
{volumn:        19,chapter:        73,versenumber:         6,verse:"所以，骄傲如链子戴在他们的项上；强暴像衣裳遮住他们的身体。"},
{volumn:        19,chapter:        73,versenumber:         7,verse:"他们的眼睛因体胖而凸出；他们所得的，过于心里所想的。"},
{volumn:        19,chapter:        73,versenumber:         8,verse:"他们讥笑人，凭恶意说欺压人的话；他们说话自高。"},
{volumn:        19,chapter:        73,versenumber:         9,verse:"他们的口亵渎上天；他们的舌毁谤全地。"},
{volumn:        19,chapter:        73,versenumber:        10,verse:"所以　神的民归到这里，喝尽了满杯的苦水。"},
{volumn:        19,chapter:        73,versenumber:        11,verse:"他们说：“　神怎能晓得？至高者岂有知识呢？”"},
{volumn:        19,chapter:        73,versenumber:        12,verse:"看哪，这就是恶人；他们既是常享安逸，财宝便加增。"},
{volumn:        19,chapter:        73,versenumber:        13,verse:"我实在徒然洁净了我的心，徒然洗手表明无辜。"},
{volumn:        19,chapter:        73,versenumber:        14,verse:"因为，我终日遭灾难；每早晨受惩治。"},
{volumn:        19,chapter:        73,versenumber:        15,verse:"我若说，“我要这样讲”，这就是以奸诈待你的众子。"},
{volumn:        19,chapter:        73,versenumber:        16,verse:"我思索怎能明白这事，眼看实系为难，"},
{volumn:        19,chapter:        73,versenumber:        17,verse:"等我进了　神的圣所，思想他们的结局。"},
{volumn:        19,chapter:        73,versenumber:        18,verse:"你实在把他们安在滑地，使他们掉在沉沦之中。"},
{volumn:        19,chapter:        73,versenumber:        19,verse:"他们转眼之间成了何等的荒凉！他们被惊恐灭尽了。"},
{volumn:        19,chapter:        73,versenumber:        20,verse:"人睡醒了，怎样看梦；主啊，你醒了也必照样轻看他们的影像。"},
{volumn:        19,chapter:        73,versenumber:        21,verse:"因而，我心里发酸，肺腑被刺。"},
{volumn:        19,chapter:        73,versenumber:        22,verse:"我这样愚昧无知，在你面前如畜类一般。"},
{volumn:        19,chapter:        73,versenumber:        23,verse:"然而，我常与你同在；你搀着我的右手。"},
{volumn:        19,chapter:        73,versenumber:        24,verse:"你要以你的训言引导我，以后必接我到荣耀里。"},
{volumn:        19,chapter:        73,versenumber:        25,verse:"除你以外，在天上我有谁呢？除你以外，在地上我也没有所爱慕的。"},
{volumn:        19,chapter:        73,versenumber:        26,verse:"我的肉体和我的心肠衰残；但　神是我心里的力量，又是我的福分，直到永远。"},
{volumn:        19,chapter:        73,versenumber:        27,verse:"远离你的，必要死亡；凡离弃你行邪淫的，你都灭绝了。"},
{volumn:        19,chapter:        73,versenumber:        28,verse:"但我亲近　神是与我有益；我以主耶和华为我的避难所，好叫我述说你一切的作为。"},
{volumn:        19,chapter:        74,versenumber:         1,verse:"神啊，你为何永远丢弃我们呢？你为何向你草场的羊发怒，如烟冒出呢？"},
{volumn:        19,chapter:        74,versenumber:         2,verse:"求你记念你古时所得来的会众，就是你所赎、作你产业支派的，并记念你向来所居住的锡安山。"},
{volumn:        19,chapter:        74,versenumber:         3,verse:"求你举步去看那日久荒凉之地，仇敌在圣所中所行的一切恶事。"},
{volumn:        19,chapter:        74,versenumber:         4,verse:"你的敌人在你会中吼叫；他们竖了自己的旗为记号。"},
{volumn:        19,chapter:        74,versenumber:         5,verse:"他们好像人扬起斧子，砍伐林中的树。"},
{volumn:        19,chapter:        74,versenumber:         6,verse:"圣所中一切雕刻的，他们现在用斧子锤子打坏了。"},
{volumn:        19,chapter:        74,versenumber:         7,verse:"他们用火焚烧你的圣所，亵渎你名的居所，拆毁到地。"},
{volumn:        19,chapter:        74,versenumber:         8,verse:"他们心里说：“我们要尽行毁灭。”他们就在遍地把　神的会所都烧毁了。"},
{volumn:        19,chapter:        74,versenumber:         9,verse:"我们不见我们的标帜，不再有先知；我们内中也没有人知道这灾祸要到几时呢！"},
{volumn:        19,chapter:        74,versenumber:        10,verse:"神啊，敌人辱骂要到几时呢？仇敌亵渎你的名要到永远吗？"},
{volumn:        19,chapter:        74,versenumber:        11,verse:"你为什么缩回你的右手？求你从怀中伸出来，毁灭他们。"},
{volumn:        19,chapter:        74,versenumber:        12,verse:"神自古以来为我的王，在地上施行拯救。"},
{volumn:        19,chapter:        74,versenumber:        13,verse:"你曾用能力将海分开，将水中大鱼的头打破。"},
{volumn:        19,chapter:        74,versenumber:        14,verse:"你曾砸碎鳄鱼的头，把它给旷野的禽兽（禽兽：原文是民）为食物。"},
{volumn:        19,chapter:        74,versenumber:        15,verse:"你曾分裂磐石，水便成了溪河；你使长流的江河干了。"},
{volumn:        19,chapter:        74,versenumber:        16,verse:"白昼属你，黑夜也属你；亮光和日头是你所预备的。"},
{volumn:        19,chapter:        74,versenumber:        17,verse:"地的一切疆界是你所立的；夏天和冬天是你所定的。"},
{volumn:        19,chapter:        74,versenumber:        18,verse:"耶和华啊，仇敌辱骂，愚顽民亵渎了你的名，求你记念这事。"},
{volumn:        19,chapter:        74,versenumber:        19,verse:"不要将你斑鸠的性命交给野兽；不要永远忘记你困苦人的性命。"},
{volumn:        19,chapter:        74,versenumber:        20,verse:"求你顾念所立的约，因为地上黑暗之处都满了强暴的居所。"},
{volumn:        19,chapter:        74,versenumber:        21,verse:"不要叫受欺压的人蒙羞回去；要叫困苦穷乏的人赞美你的名。"},
{volumn:        19,chapter:        74,versenumber:        22,verse:"神啊，求你起来为自己伸诉！要记念愚顽人怎样终日辱骂你。"},
{volumn:        19,chapter:        74,versenumber:        23,verse:"不要忘记你敌人的声音；那起来敌你之人的喧哗时常上升。"},
{volumn:        19,chapter:        75,versenumber:         1,verse:"神啊，我们称谢你，我们称谢你！因为你的名相近，人都述说你奇妙的作为。"},
{volumn:        19,chapter:        75,versenumber:         2,verse:"我到了所定的日期，必按正直施行审判。"},
{volumn:        19,chapter:        75,versenumber:         3,verse:"地和其上的居民都消化了；我曾立了地的柱子。（细拉）"},
{volumn:        19,chapter:        75,versenumber:         4,verse:"我对狂傲人说：“不要行事狂傲！”对凶恶人说：“不要举角！"},
{volumn:        19,chapter:        75,versenumber:         5,verse:"不要把你们的角高举；不要挺着颈项说话。”"},
{volumn:        19,chapter:        75,versenumber:         6,verse:"因为高举非从东，非从西，也非从南而来。"},
{volumn:        19,chapter:        75,versenumber:         7,verse:"惟有　神断定；他使这人降卑，使那人升高。"},
{volumn:        19,chapter:        75,versenumber:         8,verse:"耶和华手里有杯，其中的酒起沫，杯内满了搀杂的酒；他倒出来，地上的恶人必都喝这酒的渣滓，而且喝尽。"},
{volumn:        19,chapter:        75,versenumber:         9,verse:"但我要宣扬，直到永远！我要歌颂雅各的　神！"},
{volumn:        19,chapter:        75,versenumber:        10,verse:"恶人一切的角，我要砍断；惟有义人的角必被高举。"},
{volumn:        19,chapter:        76,versenumber:         1,verse:"在犹大，　神为人所认识；在以色列，他的名为大。"},
{volumn:        19,chapter:        76,versenumber:         2,verse:"在撒冷有他的帐幕；在锡安有他的居所。"},
{volumn:        19,chapter:        76,versenumber:         3,verse:"他在那里折断弓上的火箭，并盾牌、刀剑，和争战的兵器。（细拉）"},
{volumn:        19,chapter:        76,versenumber:         4,verse:"你从有野食之山而来，有光华和荣美。"},
{volumn:        19,chapter:        76,versenumber:         5,verse:"心中勇敢的人都被抢夺；他们睡了长觉，没有一个英雄能措手。"},
{volumn:        19,chapter:        76,versenumber:         6,verse:"雅各的　神啊，你的斥责一发，坐车的、骑马的都沉睡了。"},
{volumn:        19,chapter:        76,versenumber:         7,verse:"惟独你是可畏的！你怒气一发，谁能在你面前站得住呢？"},
{volumn:        19,chapter:        76,versenumber:         8,verse:"你从天上使人听判断。　神起来施行审判，要救地上一切谦卑的人；那时地就惧怕而静默。（细拉）"},
{volumn:        19,chapter:        76,versenumber:         9,verse:""},
{volumn:        19,chapter:        76,versenumber:        10,verse:"人的忿怒要成全你的荣美；人的余怒，你要禁止。"},
{volumn:        19,chapter:        76,versenumber:        11,verse:"你们许愿，当向耶和华你们的　神还愿；在他四面的人都当拿贡物献给那可畏的主。"},
{volumn:        19,chapter:        76,versenumber:        12,verse:"他要挫折王子的骄气；他向地上的君王显威可畏。"},
{volumn:        19,chapter:        77,versenumber:         1,verse:"我要向　神发声呼求；我向　神发声，他必留心听我。"},
{volumn:        19,chapter:        77,versenumber:         2,verse:"我在患难之日寻求主；我在夜间不住地举手祷告；我的心不肯受安慰。"},
{volumn:        19,chapter:        77,versenumber:         3,verse:"我想念　神，就烦燥不安；我沉吟悲伤，心便发昏。（细拉）"},
{volumn:        19,chapter:        77,versenumber:         4,verse:"你叫我不能闭眼；我烦乱不安，甚至不能说话。"},
{volumn:        19,chapter:        77,versenumber:         5,verse:"我追想古时之日，上古之年。"},
{volumn:        19,chapter:        77,versenumber:         6,verse:"我想起我夜间的歌曲，扪心自问；我心里也仔细省察。"},
{volumn:        19,chapter:        77,versenumber:         7,verse:"难道主要永远丢弃我，不再施恩吗？"},
{volumn:        19,chapter:        77,versenumber:         8,verse:"难道他的慈爱永远穷尽，他的应许世世废弃吗？"},
{volumn:        19,chapter:        77,versenumber:         9,verse:"难道　神忘记开恩，因发怒就止住他的慈悲吗？（细拉）"},
{volumn:        19,chapter:        77,versenumber:        10,verse:"我便说：“这是我的懦弱，但我要追念至高者显出右手之年代。”"},
{volumn:        19,chapter:        77,versenumber:        11,verse:"我要提说耶和华所行的；我要记念你古时的奇事。"},
{volumn:        19,chapter:        77,versenumber:        12,verse:"我也要思想你的经营，默念你的作为。"},
{volumn:        19,chapter:        77,versenumber:        13,verse:"神啊，你的作为是洁净的；有何　神大如　神呢？"},
{volumn:        19,chapter:        77,versenumber:        14,verse:"你是行奇事的　神；你曾在列邦中彰显你的能力。"},
{volumn:        19,chapter:        77,versenumber:        15,verse:"你曾用你的膀臂赎了你的民，就是雅各和约瑟的子孙。（细拉）"},
{volumn:        19,chapter:        77,versenumber:        16,verse:"神啊，诸水见你，一见就都惊惶；深渊也都战抖。"},
{volumn:        19,chapter:        77,versenumber:        17,verse:"云中倒出水来；天空发出响声；你的箭也飞行四方。"},
{volumn:        19,chapter:        77,versenumber:        18,verse:"你的雷声在旋风中；电光照亮世界；大地战抖震动。"},
{volumn:        19,chapter:        77,versenumber:        19,verse:"你的道在海中；你的路在大水中；你的脚踪无人知道。"},
{volumn:        19,chapter:        77,versenumber:        20,verse:"你曾藉摩西和亚伦的手引导你的百姓，好像羊群一般。"},
{volumn:        19,chapter:        78,versenumber:         1,verse:"我的民哪，你们要留心听我的训诲，侧耳听我口中的话。"},
{volumn:        19,chapter:        78,versenumber:         2,verse:"我要开口说比喻；我要说出古时的谜语，"},
{volumn:        19,chapter:        78,versenumber:         3,verse:"是我们所听见、所知道的，也是我们的祖宗告诉我们的。"},
{volumn:        19,chapter:        78,versenumber:         4,verse:"我们不将这些事向他们的子孙隐瞒，要将耶和华的美德和他的能力，并他奇妙的作为，述说给后代听。"},
{volumn:        19,chapter:        78,versenumber:         5,verse:"因为，他在雅各中立法度，在以色列中设律法；是他吩咐我们祖宗要传给子孙的，"},
{volumn:        19,chapter:        78,versenumber:         6,verse:"使将要生的后代子孙可以晓得；他们也要起来告诉他们的子孙，"},
{volumn:        19,chapter:        78,versenumber:         7,verse:"好叫他们仰望　神，不忘记　神的作为，惟要守他的命令。"},
{volumn:        19,chapter:        78,versenumber:         8,verse:"不要像他们的祖宗，是顽梗悖逆、居心不正之辈，向着　神，心不诚实。"},
{volumn:        19,chapter:        78,versenumber:         9,verse:"以法莲的子孙带着兵器，拿着弓，临阵之日转身退后。"},
{volumn:        19,chapter:        78,versenumber:        10,verse:"他们不遵守　神的约，不肯照他的律法行；"},
{volumn:        19,chapter:        78,versenumber:        11,verse:"又忘记他所行的和他显给他们奇妙的作为。"},
{volumn:        19,chapter:        78,versenumber:        12,verse:"他在埃及地，在琐安田，在他们祖宗的眼前施行奇事。"},
{volumn:        19,chapter:        78,versenumber:        13,verse:"他将海分裂，使他们过去，又叫水立起如垒。"},
{volumn:        19,chapter:        78,versenumber:        14,verse:"他白日用云彩，终夜用火光引导他们。"},
{volumn:        19,chapter:        78,versenumber:        15,verse:"他在旷野分裂磐石，多多地给他们水喝，如从深渊而出。"},
{volumn:        19,chapter:        78,versenumber:        16,verse:"他使水从磐石涌出，叫水如江河下流。"},
{volumn:        19,chapter:        78,versenumber:        17,verse:"他们却仍旧得罪他，在干燥之地悖逆至高者。"},
{volumn:        19,chapter:        78,versenumber:        18,verse:"他们心中试探　神，随自己所欲的求食物，"},
{volumn:        19,chapter:        78,versenumber:        19,verse:"并且妄论　神说：“　神在旷野岂能摆设筵席吗？"},
{volumn:        19,chapter:        78,versenumber:        20,verse:"他曾击打磐石，使水涌出，成了江河；他还能赐粮食吗？还能为他的百姓预备肉吗？”"},
{volumn:        19,chapter:        78,versenumber:        21,verse:"所以，耶和华听见就发怒；有烈火向雅各烧起；有怒气向以色列上腾；"},
{volumn:        19,chapter:        78,versenumber:        22,verse:"因为他们不信服　神，不倚赖他的救恩。"},
{volumn:        19,chapter:        78,versenumber:        23,verse:"他却吩咐天空，又敞开天上的门，"},
{volumn:        19,chapter:        78,versenumber:        24,verse:"降吗哪，像雨给他们吃，将天上的粮食赐给他们。"},
{volumn:        19,chapter:        78,versenumber:        25,verse:"各人（或译：人）吃大能者的食物；他赐下粮食，使他们饱足。"},
{volumn:        19,chapter:        78,versenumber:        26,verse:"他领东风起在天空，又用能力引了南风来。"},
{volumn:        19,chapter:        78,versenumber:        27,verse:"他降肉，像雨在他们当中，多如尘土，又降飞鸟，多如海沙，"},
{volumn:        19,chapter:        78,versenumber:        28,verse:"落在他们的营中，在他们住处的四面。"},
{volumn:        19,chapter:        78,versenumber:        29,verse:"他们吃了，而且饱足；这样就随了他们所欲的。"},
{volumn:        19,chapter:        78,versenumber:        30,verse:"他们贪而无厌，食物还在他们口中的时候，"},
{volumn:        19,chapter:        78,versenumber:        31,verse:"神的怒气就向他们上腾，杀了他们内中的肥壮人，打倒以色列的少年人。"},
{volumn:        19,chapter:        78,versenumber:        32,verse:"虽是这样，他们仍旧犯罪，不信他奇妙的作为。"},
{volumn:        19,chapter:        78,versenumber:        33,verse:"因此，他叫他们的日子全归虚空，叫他们的年岁尽属惊恐。"},
{volumn:        19,chapter:        78,versenumber:        34,verse:"他杀他们的时候，他们才求问他，回心转意，切切地寻求　神。"},
{volumn:        19,chapter:        78,versenumber:        35,verse:"他们也追念　神是他们的磐石，至高的　神是他们的救赎主。"},
{volumn:        19,chapter:        78,versenumber:        36,verse:"他们却用口谄媚他，用舌向他说谎。"},
{volumn:        19,chapter:        78,versenumber:        37,verse:"因他们的心向他不正，在他的约上也不忠心。"},
{volumn:        19,chapter:        78,versenumber:        38,verse:"但他有怜悯，赦免他们的罪孽，不灭绝他们，而且屡次消他的怒气，不发尽他的忿怒。"},
{volumn:        19,chapter:        78,versenumber:        39,verse:"他想到他们不过是血气，是一阵去而不返的风。"},
{volumn:        19,chapter:        78,versenumber:        40,verse:"他们在旷野悖逆他，在荒地叫他担忧，何其多呢！"},
{volumn:        19,chapter:        78,versenumber:        41,verse:"他们再三试探　神，惹动以色列的圣者。"},
{volumn:        19,chapter:        78,versenumber:        42,verse:"他们不追念他的能力（原文是手）和赎他们脱离敌人的日子；"},
{volumn:        19,chapter:        78,versenumber:        43,verse:"他怎样在埃及地显神迹，在琐安田显奇事，"},
{volumn:        19,chapter:        78,versenumber:        44,verse:"把他们的江河并河汊的水都变为血，使他们不能喝。"},
{volumn:        19,chapter:        78,versenumber:        45,verse:"他叫苍蝇成群落在他们当中，嘬尽他们，又叫青蛙灭了他们，"},
{volumn:        19,chapter:        78,versenumber:        46,verse:"把他们的土产交给蚂蚱，把他们辛苦得来的交给蝗虫。"},
{volumn:        19,chapter:        78,versenumber:        47,verse:"他降冰雹打坏他们的葡萄树，下严霜打坏他们的桑树，"},
{volumn:        19,chapter:        78,versenumber:        48,verse:"又把他们的牲畜交给冰雹，把他们的群畜交给闪电。"},
{volumn:        19,chapter:        78,versenumber:        49,verse:"他使猛烈的怒气和忿怒、恼恨、苦难成了一群降灾的使者，临到他们。"},
{volumn:        19,chapter:        78,versenumber:        50,verse:"他为自己的怒气修平了路，将他们交给瘟疫，使他们死亡，"},
{volumn:        19,chapter:        78,versenumber:        51,verse:"在埃及击杀一切长子，在含的帐棚中击杀他们强壮时头生的。"},
{volumn:        19,chapter:        78,versenumber:        52,verse:"他却领出自己的民如羊，在旷野引他们如羊群。"},
{volumn:        19,chapter:        78,versenumber:        53,verse:"他领他们稳稳妥妥地，使他们不致害怕；海却淹没他们的仇敌。"},
{volumn:        19,chapter:        78,versenumber:        54,verse:"他带他们到自己圣地的边界，到他右手所得的这山地。"},
{volumn:        19,chapter:        78,versenumber:        55,verse:"他在他们面前赶出外邦人，用绳子将外邦的地量给他们为业，叫以色列支派的人住在他们的帐棚里。"},
{volumn:        19,chapter:        78,versenumber:        56,verse:"他们仍旧试探、悖逆至高的　神，不守他的法度，"},
{volumn:        19,chapter:        78,versenumber:        57,verse:"反倒退后，行诡诈，像他们的祖宗一样；他们改变，如同翻背的弓。"},
{volumn:        19,chapter:        78,versenumber:        58,verse:"因他们的邱坛惹了他的怒气；因他们雕刻的偶像触动他的愤恨。"},
{volumn:        19,chapter:        78,versenumber:        59,verse:"神听见就发怒，极其憎恶以色列人。"},
{volumn:        19,chapter:        78,versenumber:        60,verse:"甚至他离弃示罗的帐幕，就是他在人间所搭的帐棚；"},
{volumn:        19,chapter:        78,versenumber:        61,verse:"又将他的约柜（原文是能力）交与人掳去，将他的荣耀交在敌人手中；"},
{volumn:        19,chapter:        78,versenumber:        62,verse:"并将他的百姓交与刀剑，向他的产业发怒。"},
{volumn:        19,chapter:        78,versenumber:        63,verse:"少年人被火烧灭；处女也无喜歌。"},
{volumn:        19,chapter:        78,versenumber:        64,verse:"祭司倒在刀下，寡妇却不哀哭。"},
{volumn:        19,chapter:        78,versenumber:        65,verse:"那时，主像世人睡醒，像勇士饮酒呼喊。"},
{volumn:        19,chapter:        78,versenumber:        66,verse:"他就打退了他的敌人，叫他们永蒙羞辱；"},
{volumn:        19,chapter:        78,versenumber:        67,verse:"并且他弃掉约瑟的帐棚，不拣选以法莲支派，"},
{volumn:        19,chapter:        78,versenumber:        68,verse:"却拣选犹大支派，他所喜爱的锡安山。"},
{volumn:        19,chapter:        78,versenumber:        69,verse:"盖造他的圣所，好像高峰，又像他建立永存之地；"},
{volumn:        19,chapter:        78,versenumber:        70,verse:"又拣选他的仆人大卫，从羊圈中将他召来，"},
{volumn:        19,chapter:        78,versenumber:        71,verse:"叫他不再跟从那些带奶的母羊，为要牧养自己的百姓雅各和自己的产业以色列。"},
{volumn:        19,chapter:        78,versenumber:        72,verse:"于是，他按心中的纯正牧养他们，用手中的巧妙引导他们。"},
{volumn:        19,chapter:        79,versenumber:         1,verse:"神啊，外邦人进入你的产业，污秽你的圣殿，使耶路撒冷变成荒堆，"},
{volumn:        19,chapter:        79,versenumber:         2,verse:"把你仆人的尸首交与天空的飞鸟为食，把你圣民的肉交与地上的野兽，"},
{volumn:        19,chapter:        79,versenumber:         3,verse:"在耶路撒冷周围流他们的血如水，无人葬埋。"},
{volumn:        19,chapter:        79,versenumber:         4,verse:"我们成为邻国的羞辱，成为我们四围人的嗤笑讥刺。"},
{volumn:        19,chapter:        79,versenumber:         5,verse:"耶和华啊，这到几时呢？你要动怒到永远吗？你的愤恨要如火焚烧吗？"},
{volumn:        19,chapter:        79,versenumber:         6,verse:"愿你将你的忿怒倒在那不认识你的外邦和那不求告你名的国度。"},
{volumn:        19,chapter:        79,versenumber:         7,verse:"因为他们吞了雅各，把他的住处变为荒场。"},
{volumn:        19,chapter:        79,versenumber:         8,verse:"求你不要记念我们先祖的罪孽，向我们追讨；愿你的慈悲快迎着我们，因为我们落到极卑微的地步。"},
{volumn:        19,chapter:        79,versenumber:         9,verse:"拯救我们的　神啊，求你因你名的荣耀帮助我们！为你名的缘故搭救我们，赦免我们的罪。"},
{volumn:        19,chapter:        79,versenumber:        10,verse:"为何容外邦人说“他们的　神在哪里”呢？愿你使外邦人知道你在我们眼前伸你仆人流血的冤。"},
{volumn:        19,chapter:        79,versenumber:        11,verse:"愿被囚之人的叹息达到你面前；愿你按你的大能力存留那些将要死的人。"},
{volumn:        19,chapter:        79,versenumber:        12,verse:"主啊，愿你将我们邻邦所羞辱你的羞辱加七倍归到他们身上。"},
{volumn:        19,chapter:        79,versenumber:        13,verse:"这样，你的民，你草场的羊，要称谢你，直到永远；要述说赞美你的话，直到万代。"},
{volumn:        19,chapter:        80,versenumber:         1,verse:"领约瑟如领羊群之以色列的牧者啊，求你留心听！坐在二基路伯上的啊，求你发出光来！"},
{volumn:        19,chapter:        80,versenumber:         2,verse:"在以法莲、便雅悯、玛拿西前面施展你的大能，来救我们。"},
{volumn:        19,chapter:        80,versenumber:         3,verse:"神啊，求你使我们回转（或译：复兴），使你的脸发光，我们便要得救！"},
{volumn:        19,chapter:        80,versenumber:         4,verse:"耶和华万军之　神啊，你向你百姓的祷告发怒，要到几时呢？"},
{volumn:        19,chapter:        80,versenumber:         5,verse:"你以眼泪当食物给他们吃，又多量出眼泪给他们喝。"},
{volumn:        19,chapter:        80,versenumber:         6,verse:"你使邻邦因我们纷争；我们的仇敌彼此戏笑。"},
{volumn:        19,chapter:        80,versenumber:         7,verse:"万军之　神啊，求你使我们回转，使你的脸发光，我们便要得救！"},
{volumn:        19,chapter:        80,versenumber:         8,verse:"你从埃及挪出一棵葡萄树，赶出外邦人，把这树栽上。"},
{volumn:        19,chapter:        80,versenumber:         9,verse:"你在这树根前预备了地方，它就深深扎根，爬满了地。"},
{volumn:        19,chapter:        80,versenumber:        10,verse:"它的影子遮满了山，枝子好像佳美的香柏树。"},
{volumn:        19,chapter:        80,versenumber:        11,verse:"它发出枝子，长到大海，发出蔓子，延到大河。"},
{volumn:        19,chapter:        80,versenumber:        12,verse:"你为何拆毁这树的篱笆，任凭一切过路的人摘取？"},
{volumn:        19,chapter:        80,versenumber:        13,verse:"林中出来的野猪把它糟踏；野地的走兽拿它当食物。"},
{volumn:        19,chapter:        80,versenumber:        14,verse:"万军之　神啊，求你回转！从天上垂看，眷顾这葡萄树，"},
{volumn:        19,chapter:        80,versenumber:        15,verse:"保护你右手所栽的和你为自己所坚固的枝子。"},
{volumn:        19,chapter:        80,versenumber:        16,verse:"这树已经被火焚烧，被刀砍伐；他们因你脸上的怒容就灭亡了。"},
{volumn:        19,chapter:        80,versenumber:        17,verse:"愿你的手扶持你右边的人，就是你为自己所坚固的人子。"},
{volumn:        19,chapter:        80,versenumber:        18,verse:"这样，我们便不退后离开你；求你救活我们，我们就要求告你的名。"},
{volumn:        19,chapter:        80,versenumber:        19,verse:"耶和华万军之　神啊，求你使我们回转，使你的脸发光，我们便要得救！"},
{volumn:        19,chapter:        81,versenumber:         1,verse:"你们当向　神我们的力量大声欢呼，向雅各的　神发声欢乐！"},
{volumn:        19,chapter:        81,versenumber:         2,verse:"唱起诗歌，打手鼓，弹美琴与瑟。"},
{volumn:        19,chapter:        81,versenumber:         3,verse:"当在月朔并月望、我们过节的日期吹角，"},
{volumn:        19,chapter:        81,versenumber:         4,verse:"因这是为以色列定的律例，是雅各　神的典章。"},
{volumn:        19,chapter:        81,versenumber:         5,verse:"他去攻击埃及地的时候，在约瑟中间立此为证。我在那里听见我所不明白的言语："},
{volumn:        19,chapter:        81,versenumber:         6,verse:"神说：“我使你的肩得脱重担，你的手放下筐子。"},
{volumn:        19,chapter:        81,versenumber:         7,verse:"你在急难中呼求，我就搭救你；我在雷的隐密处应允你，在米利巴水那里试验你。（细拉）"},
{volumn:        19,chapter:        81,versenumber:         8,verse:"我的民哪，你当听，我要劝戒你；以色列啊，甚愿你肯听从我。"},
{volumn:        19,chapter:        81,versenumber:         9,verse:"在你当中，不可有别的神；外邦的神，你也不可下拜。"},
{volumn:        19,chapter:        81,versenumber:        10,verse:"我是耶和华你的　神，曾把你从埃及地领上来；你要大大张口，我就给你充满。"},
{volumn:        19,chapter:        81,versenumber:        11,verse:"“无奈，我的民不听我的声音；以色列全不理我。"},
{volumn:        19,chapter:        81,versenumber:        12,verse:"我便任凭他们心里刚硬，随自己的计谋而行。"},
{volumn:        19,chapter:        81,versenumber:        13,verse:"甚愿我的民肯听从我，以色列肯行我的道，"},
{volumn:        19,chapter:        81,versenumber:        14,verse:"我便速速治服他们的仇敌，反手攻击他们的敌人。"},
{volumn:        19,chapter:        81,versenumber:        15,verse:"恨耶和华的人必来投降，但他的百姓必永久长存。"},
{volumn:        19,chapter:        81,versenumber:        16,verse:"他也必拿上好的麦子给他们吃，又拿从磐石出的蜂蜜叫他们饱足。”"},
{volumn:        19,chapter:        82,versenumber:         1,verse:"神站在有权力者的会中，在诸　神中行审判，"},
{volumn:        19,chapter:        82,versenumber:         2,verse:"说：“你们审判不秉公义，徇恶人的情面，要到几时呢？（细拉）"},
{volumn:        19,chapter:        82,versenumber:         3,verse:"你们当为贫寒的人和孤儿伸冤；当为困苦和穷乏的人施行公义。"},
{volumn:        19,chapter:        82,versenumber:         4,verse:"当保护贫寒和穷乏的人，救他们脱离恶人的手。”"},
{volumn:        19,chapter:        82,versenumber:         5,verse:"你们仍不知道，也不明白，在黑暗中走来走去；地的根基都摇动了。"},
{volumn:        19,chapter:        82,versenumber:         6,verse:"我曾说：“你们是神，都是至高者的儿子。"},
{volumn:        19,chapter:        82,versenumber:         7,verse:"然而，你们要死，与世人一样，要仆倒，像王子中的一位。”"},
{volumn:        19,chapter:        82,versenumber:         8,verse:"神啊，求你起来审判世界，因为你要得万邦为业。"},
{volumn:        19,chapter:        83,versenumber:         1,verse:"神啊，求你不要静默！　神啊，求你不要闭口，也不要不作声！"},
{volumn:        19,chapter:        83,versenumber:         2,verse:"因为你的仇敌喧嚷，恨你的抬起头来。"},
{volumn:        19,chapter:        83,versenumber:         3,verse:"他们同谋奸诈要害你的百姓，彼此商议要害你所隐藏的人。"},
{volumn:        19,chapter:        83,versenumber:         4,verse:"他们说：“来吧，我们将他们剪灭，使他们不再成国！使以色列的名不再被人记念！”"},
{volumn:        19,chapter:        83,versenumber:         5,verse:"他们同心商议，彼此结盟，要抵挡你，"},
{volumn:        19,chapter:        83,versenumber:         6,verse:"就是住帐棚的以东人和以实玛利人，摩押和夏甲人，"},
{volumn:        19,chapter:        83,versenumber:         7,verse:"迦巴勒、亚扪，和亚玛力、非利士并推罗的居民。"},
{volumn:        19,chapter:        83,versenumber:         8,verse:"亚述也与他们连合；他们作罗得子孙的帮手。（细拉）"},
{volumn:        19,chapter:        83,versenumber:         9,verse:"求你待他们，如待米甸，如在基顺河待西西拉和耶宾一样。"},
{volumn:        19,chapter:        83,versenumber:        10,verse:"他们在隐·多珥灭亡，成了地上的粪土。"},
{volumn:        19,chapter:        83,versenumber:        11,verse:"求你叫他们的首领像俄立和西伊伯，叫他们的王子都像西巴和撒慕拿。"},
{volumn:        19,chapter:        83,versenumber:        12,verse:"他们说：“我们要得　神的住处，作为自己的产业。”"},
{volumn:        19,chapter:        83,versenumber:        13,verse:"我的　神啊，求你叫他们像旋风的尘土，像风前的碎秸。"},
{volumn:        19,chapter:        83,versenumber:        14,verse:"火怎样焚烧树林，火焰怎样烧着山岭，"},
{volumn:        19,chapter:        83,versenumber:        15,verse:"求你也照样用狂风追赶他们，用暴雨恐吓他们。"},
{volumn:        19,chapter:        83,versenumber:        16,verse:"愿你使他们满面羞耻，好叫他们寻求你耶和华的名！"},
{volumn:        19,chapter:        83,versenumber:        17,verse:"愿他们永远羞愧惊惶！愿他们惭愧灭亡！"},
{volumn:        19,chapter:        83,versenumber:        18,verse:"使他们知道：惟独你名为耶和华的，是全地以上的至高者！"},
{volumn:        19,chapter:        84,versenumber:         1,verse:"万军之耶和华啊，你的居所何等可爱！"},
{volumn:        19,chapter:        84,versenumber:         2,verse:"我羡慕渴想耶和华的院宇；我的心肠，我的肉体向永生　神呼吁（或译：欢呼）。"},
{volumn:        19,chapter:        84,versenumber:         3,verse:"万军之耶和华我的王，我的　神啊，在你祭坛那里，麻雀为自己找着房屋，燕子为自己找着抱雏之窝。"},
{volumn:        19,chapter:        84,versenumber:         4,verse:"如此住在你殿中的便为有福！他们仍要赞美你。（细拉）"},
{volumn:        19,chapter:        84,versenumber:         5,verse:"靠你有力量、心中想往锡安大道的，这人便为有福！"},
{volumn:        19,chapter:        84,versenumber:         6,verse:"他们经过“流泪谷”，叫这谷变为泉源之地；并有秋雨之福盖满了全谷。"},
{volumn:        19,chapter:        84,versenumber:         7,verse:"他们行走，力上加力，各人到锡安朝见　神。"},
{volumn:        19,chapter:        84,versenumber:         8,verse:"耶和华万军之　神啊，求你听我的祷告！雅各的　神啊，求你留心听！（细拉）"},
{volumn:        19,chapter:        84,versenumber:         9,verse:"神啊，你是我们的盾牌；求你垂顾观看你受膏者的面！"},
{volumn:        19,chapter:        84,versenumber:        10,verse:"在你的院宇住一日，胜似在别处住千日；宁可在我　神殿中看门，不愿住在恶人的帐棚里。"},
{volumn:        19,chapter:        84,versenumber:        11,verse:"因为耶和华　神是日头，是盾牌，要赐下恩惠和荣耀。他未尝留下一样好处不给那些行动正直的人。"},
{volumn:        19,chapter:        84,versenumber:        12,verse:"万军之耶和华啊，倚靠你的人便为有福！"},
{volumn:        19,chapter:        85,versenumber:         1,verse:"耶和华啊，你已经向你的地施恩，救回被掳的雅各。"},
{volumn:        19,chapter:        85,versenumber:         2,verse:"你赦免了你百姓的罪孽，遮盖了他们一切的过犯。（细拉）"},
{volumn:        19,chapter:        85,versenumber:         3,verse:"你收转了所发的忿怒和你猛烈的怒气。"},
{volumn:        19,chapter:        85,versenumber:         4,verse:"拯救我们的　神啊，求你使我们回转，叫你的恼恨向我们止息。"},
{volumn:        19,chapter:        85,versenumber:         5,verse:"你要向我们发怒到永远吗？你要将你的怒气延留到万代吗？"},
{volumn:        19,chapter:        85,versenumber:         6,verse:"你不再将我们救活，使你的百姓靠你欢喜吗？"},
{volumn:        19,chapter:        85,versenumber:         7,verse:"耶和华啊，求你使我们得见你的慈爱，又将你的救恩赐给我们。"},
{volumn:        19,chapter:        85,versenumber:         8,verse:"我要听　神耶和华所说的话；因为他必应许将平安赐给他的百姓，他的圣民；他们却不可再转去妄行。"},
{volumn:        19,chapter:        85,versenumber:         9,verse:"他的救恩诚然与敬畏他的人相近，叫荣耀住在我们的地上。"},
{volumn:        19,chapter:        85,versenumber:        10,verse:"慈爱和诚实彼此相遇；公义和平安彼此相亲。"},
{volumn:        19,chapter:        85,versenumber:        11,verse:"诚实从地而生；公义从天而现。"},
{volumn:        19,chapter:        85,versenumber:        12,verse:"耶和华必将好处赐给我们；我们的地也要多出土产。"},
{volumn:        19,chapter:        85,versenumber:        13,verse:"公义要行在他面前，叫他的脚踪成为可走的路。"},
{volumn:        19,chapter:        86,versenumber:         1,verse:"耶和华啊，求你侧耳应允我，因我是困苦穷乏的。"},
{volumn:        19,chapter:        86,versenumber:         2,verse:"求你保存我的性命，因我是虔诚人。我的　神啊，求你拯救这倚靠你的仆人！"},
{volumn:        19,chapter:        86,versenumber:         3,verse:"主啊，求你怜悯我，因我终日求告你。"},
{volumn:        19,chapter:        86,versenumber:         4,verse:"主啊，求你使仆人心里欢喜，因为我的心仰望你。"},
{volumn:        19,chapter:        86,versenumber:         5,verse:"主啊，你本为良善，乐意饶恕人，有丰盛的慈爱赐给凡求告你的人。"},
{volumn:        19,chapter:        86,versenumber:         6,verse:"耶和华啊，求你留心听我的祷告，垂听我恳求的声音。"},
{volumn:        19,chapter:        86,versenumber:         7,verse:"我在患难之日要求告你，因为你必应允我。"},
{volumn:        19,chapter:        86,versenumber:         8,verse:"主啊，诸神之中没有可比你的；你的作为也无可比。"},
{volumn:        19,chapter:        86,versenumber:         9,verse:"主啊，你所造的万民都要来敬拜你；他们也要荣耀你的名。"},
{volumn:        19,chapter:        86,versenumber:        10,verse:"因你为大，且行奇妙的事；惟独你是　神。"},
{volumn:        19,chapter:        86,versenumber:        11,verse:"耶和华啊，求你将你的道指教我；我要照你的真理行；求你使我专心敬畏你的名！"},
{volumn:        19,chapter:        86,versenumber:        12,verse:"主我的　神啊，我要一心称赞你；我要荣耀你的名，直到永远。"},
{volumn:        19,chapter:        86,versenumber:        13,verse:"因为，你向我发的慈爱是大的；你救了我的灵魂免入极深的阴间。"},
{volumn:        19,chapter:        86,versenumber:        14,verse:"神啊，骄傲的人起来攻击我，又有一党强横的人寻索我的命；他们没有将你放在眼中。"},
{volumn:        19,chapter:        86,versenumber:        15,verse:"主啊，你是有怜悯有恩典的　神，不轻易发怒，并有丰盛的慈爱和诚实。"},
{volumn:        19,chapter:        86,versenumber:        16,verse:"求你向我转脸，怜恤我，将你的力量赐给仆人，救你婢女的儿子。"},
{volumn:        19,chapter:        86,versenumber:        17,verse:"求你向我显出恩待我的凭据，叫恨我的人看见便羞愧，因为你耶和华帮助我，安慰我。"},
{volumn:        19,chapter:        87,versenumber:         1,verse:"耶和华所立的根基在圣山上。"},
{volumn:        19,chapter:        87,versenumber:         2,verse:"他爱锡安的门，胜于爱雅各一切的住处。"},
{volumn:        19,chapter:        87,versenumber:         3,verse:"神的城啊，有荣耀的事乃指着你说的。（细拉）"},
{volumn:        19,chapter:        87,versenumber:         4,verse:"我要提起拉哈伯和巴比伦人，是在认识我之中的；看哪，非利士和推罗并古实人，个个生在那里。"},
{volumn:        19,chapter:        87,versenumber:         5,verse:"论到锡安，必说：“这一个、那一个都生在其中”，而且至高者必亲自坚立这城。"},
{volumn:        19,chapter:        87,versenumber:         6,verse:"当耶和华记录万民的时候，他要点出这一个生在那里。（细拉）"},
{volumn:        19,chapter:        87,versenumber:         7,verse:"歌唱的，跳舞的，都要说：“我的泉源都在你里面。”"},
{volumn:        19,chapter:        88,versenumber:         1,verse:"耶和华拯救我的　神啊，我昼夜在你面前呼吁。"},
{volumn:        19,chapter:        88,versenumber:         2,verse:"愿我的祷告达到你面前；求你侧耳听我的呼求！"},
{volumn:        19,chapter:        88,versenumber:         3,verse:"因为我心里满了患难；我的性命临近阴间。"},
{volumn:        19,chapter:        88,versenumber:         4,verse:"我算和下坑的人同列，如同无力（或译：没有帮助）的人一样。"},
{volumn:        19,chapter:        88,versenumber:         5,verse:"我被丢在死人中，好像被杀的人躺在坟墓里。他们是你不再记念的，与你隔绝了。"},
{volumn:        19,chapter:        88,versenumber:         6,verse:"你把我放在极深的坑里，在黑暗地方，在深处。"},
{volumn:        19,chapter:        88,versenumber:         7,verse:"你的忿怒重压我身；你用一切的波浪困住我。（细拉）"},
{volumn:        19,chapter:        88,versenumber:         8,verse:"你把我所认识的隔在远处，使我为他们所憎恶；我被拘困，不得出来。"},
{volumn:        19,chapter:        88,versenumber:         9,verse:"我的眼睛因困苦而干瘪。耶和华啊，我天天求告你，向你举手。"},
{volumn:        19,chapter:        88,versenumber:        10,verse:"你岂要行奇事给死人看吗？难道阴魂还能起来称赞你吗？（细拉）"},
{volumn:        19,chapter:        88,versenumber:        11,verse:"岂能在坟墓里述说你的慈爱吗？岂能在灭亡中述说你的信实吗？"},
{volumn:        19,chapter:        88,versenumber:        12,verse:"你的奇事岂能在幽暗里被知道吗？你的公义岂能在忘记之地被知道吗？"},
{volumn:        19,chapter:        88,versenumber:        13,verse:"耶和华啊，我呼求你；我早晨的祷告要达到你面前。"},
{volumn:        19,chapter:        88,versenumber:        14,verse:"耶和华啊，你为何丢弃我？为何掩面不顾我？"},
{volumn:        19,chapter:        88,versenumber:        15,verse:"我自幼受苦，几乎死亡；我受你的惊恐，甚至慌张。"},
{volumn:        19,chapter:        88,versenumber:        16,verse:"你的烈怒漫过我身；你的惊吓把我剪除。"},
{volumn:        19,chapter:        88,versenumber:        17,verse:"这些终日如水环绕我，一齐都来围困我。"},
{volumn:        19,chapter:        88,versenumber:        18,verse:"你把我的良朋密友隔在远处，使我所认识的人进入黑暗里。"},
{volumn:        19,chapter:        89,versenumber:         1,verse:"我要歌唱耶和华的慈爱，直到永远；我要用口将你的信实传与万代。"},
{volumn:        19,chapter:        89,versenumber:         2,verse:"因我曾说：你的慈悲必建立到永远；你的信实必坚立在天上。"},
{volumn:        19,chapter:        89,versenumber:         3,verse:"我与我所拣选的人立了约，向我的仆人大卫起了誓："},
{volumn:        19,chapter:        89,versenumber:         4,verse:"我要建立你的后裔，直到永远；要建立你的宝座，直到万代。（细拉）"},
{volumn:        19,chapter:        89,versenumber:         5,verse:"耶和华啊，诸天要称赞你的奇事；在圣者的会中，要称赞你的信实。"},
{volumn:        19,chapter:        89,versenumber:         6,verse:"在天空谁能比耶和华呢？　神的众子中，谁能像耶和华呢？"},
{volumn:        19,chapter:        89,versenumber:         7,verse:"他在圣者的会中，是大有威严的　神，比一切在他四围的更可畏惧。"},
{volumn:        19,chapter:        89,versenumber:         8,verse:"耶和华万军之　神啊，哪一个大能者像你耶和华？你的信实是在你的四围。"},
{volumn:        19,chapter:        89,versenumber:         9,verse:"你管辖海的狂傲；波浪翻腾，你就使它平静了。"},
{volumn:        19,chapter:        89,versenumber:        10,verse:"你打碎了拉哈伯，似乎是已杀的人；你用有能的膀臂打散了你的仇敌。"},
{volumn:        19,chapter:        89,versenumber:        11,verse:"天属你，地也属你；世界和其中所充满的都为你所建立。"},
{volumn:        19,chapter:        89,versenumber:        12,verse:"南北为你所创造；他泊和黑门都因你的名欢呼。"},
{volumn:        19,chapter:        89,versenumber:        13,verse:"你有大能的膀臂；你的手有力，你的右手也高举。"},
{volumn:        19,chapter:        89,versenumber:        14,verse:"公义和公平是你宝座的根基；慈爱和诚实行在你前面。"},
{volumn:        19,chapter:        89,versenumber:        15,verse:"知道向你欢呼的，那民是有福的！耶和华啊，他们在你脸上的光里行走。"},
{volumn:        19,chapter:        89,versenumber:        16,verse:"他们因你的名终日欢乐，因你的公义得以高举。"},
{volumn:        19,chapter:        89,versenumber:        17,verse:"你是他们力量的荣耀；因为你喜悦我们，我们的角必被高举。"},
{volumn:        19,chapter:        89,versenumber:        18,verse:"我们的盾牌属耶和华；我们的王属以色列的圣者。"},
{volumn:        19,chapter:        89,versenumber:        19,verse:"当时，你在异象中晓谕你的圣民，说：“我已把救助之力加在那有能者的身上；我高举那从民中所拣选的。"},
{volumn:        19,chapter:        89,versenumber:        20,verse:"我寻得我的仆人大卫，用我的圣膏膏他。"},
{volumn:        19,chapter:        89,versenumber:        21,verse:"我的手必使他坚立；我的膀臂也必坚固他。"},
{volumn:        19,chapter:        89,versenumber:        22,verse:"仇敌必不勒索他；凶恶之子也不苦害他。"},
{volumn:        19,chapter:        89,versenumber:        23,verse:"我要在他面前打碎他的敌人，击杀那恨他的人。"},
{volumn:        19,chapter:        89,versenumber:        24,verse:"只是我的信实和我的慈爱要与他同在；因我的名，他的角必被高举。"},
{volumn:        19,chapter:        89,versenumber:        25,verse:"我要使他的左手伸到海上，右手伸到河上。"},
{volumn:        19,chapter:        89,versenumber:        26,verse:"他要称呼我说：‘你是我的父，是我的　神，是拯救我的磐石。’"},
{volumn:        19,chapter:        89,versenumber:        27,verse:"我也要立他为长子，为世上最高的君王。"},
{volumn:        19,chapter:        89,versenumber:        28,verse:"我要为他存留我的慈爱，直到永远；我与他立的约必要坚定。"},
{volumn:        19,chapter:        89,versenumber:        29,verse:"我也要使他的后裔存到永远，使他的宝座如天之久。"},
{volumn:        19,chapter:        89,versenumber:        30,verse:"“倘若他的子孙离弃我的律法，不照我的典章行，"},
{volumn:        19,chapter:        89,versenumber:        31,verse:"背弃我的律例，不遵守我的诫命，"},
{volumn:        19,chapter:        89,versenumber:        32,verse:"我就要用杖责罚他们的过犯，用鞭责罚他们的罪孽。"},
{volumn:        19,chapter:        89,versenumber:        33,verse:"只是我必不将我的慈爱全然收回，也必不叫我的信实废弃。"},
{volumn:        19,chapter:        89,versenumber:        34,verse:"我必不背弃我的约，也不改变我口中所出的。"},
{volumn:        19,chapter:        89,versenumber:        35,verse:"我一次指着自己的圣洁起誓：我决不向大卫说谎！"},
{volumn:        19,chapter:        89,versenumber:        36,verse:"他的后裔要存到永远；他的宝座在我面前如日之恒一般，"},
{volumn:        19,chapter:        89,versenumber:        37,verse:"又如月亮永远坚立，如天上确实的见证。”（细拉）"},
{volumn:        19,chapter:        89,versenumber:        38,verse:"但你恼怒你的受膏者，就丢掉弃绝他。"},
{volumn:        19,chapter:        89,versenumber:        39,verse:"你厌恶了与仆人所立的约，将他的冠冕践踏于地。"},
{volumn:        19,chapter:        89,versenumber:        40,verse:"你拆毁了他一切的篱笆，使他的保障变为荒场。"},
{volumn:        19,chapter:        89,versenumber:        41,verse:"凡过路的人都抢夺他；他成为邻邦的羞辱。"},
{volumn:        19,chapter:        89,versenumber:        42,verse:"你高举了他敌人的右手；你叫他一切的仇敌欢喜。"},
{volumn:        19,chapter:        89,versenumber:        43,verse:"你叫他的刀剑卷刃，叫他在争战之中站立不住。"},
{volumn:        19,chapter:        89,versenumber:        44,verse:"你使他的光辉止息，将他的宝座推倒于地。"},
{volumn:        19,chapter:        89,versenumber:        45,verse:"你减少他青年的日子，又使他蒙羞。（细拉）"},
{volumn:        19,chapter:        89,versenumber:        46,verse:"耶和华啊，这要到几时呢？你要将自己隐藏到永远吗？你的忿怒如火焚烧要到几时呢？"},
{volumn:        19,chapter:        89,versenumber:        47,verse:"求你想念我的时候是何等的短少；你创造世人，要使他们归何等的虚空呢？"},
{volumn:        19,chapter:        89,versenumber:        48,verse:"谁能常活免死、救他的灵魂脱离阴间的权柄呢？（细拉）"},
{volumn:        19,chapter:        89,versenumber:        49,verse:"主啊，你从前凭你的信实向大卫立誓要施行的慈爱在哪里呢？"},
{volumn:        19,chapter:        89,versenumber:        50,verse:"主啊，求你记念仆人们所受的羞辱，记念我怎样将一切强盛民的羞辱存在我怀里。"},
{volumn:        19,chapter:        89,versenumber:        51,verse:"耶和华啊，你的仇敌用这羞辱羞辱了你的仆人，羞辱了你受膏者的脚踪。"},
{volumn:        19,chapter:        89,versenumber:        52,verse:"耶和华是应当称颂的，直到永远。阿们！阿们！"},
{volumn:        19,chapter:        90,versenumber:         1,verse:"主啊，你世世代代作我们的居所。"},
{volumn:        19,chapter:        90,versenumber:         2,verse:"诸山未曾生出，地与世界你未曾造成，从亘古到永远，你是　神。"},
{volumn:        19,chapter:        90,versenumber:         3,verse:"你使人归于尘土，说：“你们世人要归回。”"},
{volumn:        19,chapter:        90,versenumber:         4,verse:"在你看来，千年如已过的昨日，又如夜间的一更。"},
{volumn:        19,chapter:        90,versenumber:         5,verse:"你叫他们如水冲去；他们如睡一觉。早晨，他们如生长的草，"},
{volumn:        19,chapter:        90,versenumber:         6,verse:"早晨发芽生长，晚上割下枯干。"},
{volumn:        19,chapter:        90,versenumber:         7,verse:"我们因你的怒气而消灭，因你的忿怒而惊惶。"},
{volumn:        19,chapter:        90,versenumber:         8,verse:"你将我们的罪孽摆在你面前，将我们的隐恶摆在你面光之中。"},
{volumn:        19,chapter:        90,versenumber:         9,verse:"我们经过的日子都在你震怒之下；我们度尽的年岁好像一声叹息。"},
{volumn:        19,chapter:        90,versenumber:        10,verse:"我们一生的年日是七十岁，若是强壮可到八十岁；但其中所矜夸的不过是劳苦愁烦，转眼成空，我们便如飞而去。"},
{volumn:        19,chapter:        90,versenumber:        11,verse:"谁晓得你怒气的权势？谁按着你该受的敬畏晓得你的忿怒呢？"},
{volumn:        19,chapter:        90,versenumber:        12,verse:"求你指教我们怎样数算自己的日子，好叫我们得着智慧的心。"},
{volumn:        19,chapter:        90,versenumber:        13,verse:"耶和华啊，我们要等到几时呢？求你转回，为你的仆人后悔。"},
{volumn:        19,chapter:        90,versenumber:        14,verse:"求你使我们早早饱得你的慈爱，好叫我们一生一世欢呼喜乐。"},
{volumn:        19,chapter:        90,versenumber:        15,verse:"求你照着你使我们受苦的日子，和我们遭难的年岁，叫我们喜乐。"},
{volumn:        19,chapter:        90,versenumber:        16,verse:"愿你的作为向你仆人显现；愿你的荣耀向他们子孙显明。"},
{volumn:        19,chapter:        90,versenumber:        17,verse:"愿主我们　神的荣美归于我们身上。愿你坚立我们手所做的工；我们手所做的工，愿你坚立。"},
{volumn:        19,chapter:        91,versenumber:         1,verse:"住在至高者隐密处的，必住在全能者的荫下。"},
{volumn:        19,chapter:        91,versenumber:         2,verse:"我要论到耶和华说：“他是我的避难所，是我的山寨，是我的　神，是我所倚靠的。”"},
{volumn:        19,chapter:        91,versenumber:         3,verse:"他必救你脱离捕鸟人的网罗和毒害的瘟疫。"},
{volumn:        19,chapter:        91,versenumber:         4,verse:"他必用自己的翎毛遮蔽你；你要投靠在他的翅膀底下；他的诚实是大小的盾牌。"},
{volumn:        19,chapter:        91,versenumber:         5,verse:"你必不怕黑夜的惊骇，或是白日飞的箭，"},
{volumn:        19,chapter:        91,versenumber:         6,verse:"也不怕黑夜行的瘟疫，或是午间灭人的毒病。"},
{volumn:        19,chapter:        91,versenumber:         7,verse:"虽有千人仆倒在你旁边，万人仆倒在你右边，这灾却不得临近你。"},
{volumn:        19,chapter:        91,versenumber:         8,verse:"你惟亲眼观看，见恶人遭报。"},
{volumn:        19,chapter:        91,versenumber:         9,verse:"耶和华是我的避难所；你已将至高者当你的居所，"},
{volumn:        19,chapter:        91,versenumber:        10,verse:"祸患必不临到你，灾害也不挨近你的帐棚。"},
{volumn:        19,chapter:        91,versenumber:        11,verse:"因他要为你吩咐他的使者，在你行的一切道路上保护你。"},
{volumn:        19,chapter:        91,versenumber:        12,verse:"他们要用手托着你，免得你的脚碰在石头上。"},
{volumn:        19,chapter:        91,versenumber:        13,verse:"你要踹在狮子和虺蛇的身上，践踏少壮狮子和大蛇。"},
{volumn:        19,chapter:        91,versenumber:        14,verse:"神说：“因为他专心爱我，我就要搭救他；因为他知道我的名，我要把他安置在高处。"},
{volumn:        19,chapter:        91,versenumber:        15,verse:"他若求告我，我就应允他；他在急难中，我要与他同在；我要搭救他，使他尊贵。"},
{volumn:        19,chapter:        91,versenumber:        16,verse:"我要使他足享长寿，将我的救恩显明给他。”"},
{volumn:        19,chapter:        92,versenumber:         1,verse:"称谢耶和华！歌颂你至高者的名！"},
{volumn:        19,chapter:        92,versenumber:         2,verse:"用十弦的乐器和瑟，用琴弹幽雅的声音，早晨传扬你的慈爱；每夜传扬你的信实。这本为美事。"},
{volumn:        19,chapter:        92,versenumber:         3,verse:""},
{volumn:        19,chapter:        92,versenumber:         4,verse:"因你耶和华藉着你的作为叫我高兴，我要因你手的工作欢呼。"},
{volumn:        19,chapter:        92,versenumber:         5,verse:"耶和华啊，你的工作何其大！你的心思极其深！"},
{volumn:        19,chapter:        92,versenumber:         6,verse:"畜类人不晓得；愚顽人也不明白。"},
{volumn:        19,chapter:        92,versenumber:         7,verse:"恶人茂盛如草，一切作孽之人发旺的时候，正是他们要灭亡，直到永远。"},
{volumn:        19,chapter:        92,versenumber:         8,verse:"惟你耶和华是至高，直到永远。"},
{volumn:        19,chapter:        92,versenumber:         9,verse:"耶和华啊，你的仇敌都要灭亡；一切作孽的也要离散。"},
{volumn:        19,chapter:        92,versenumber:        10,verse:"你却高举了我的角，如野牛的角；我是被新油膏了的。"},
{volumn:        19,chapter:        92,versenumber:        11,verse:"我眼睛看见仇敌遭报；我耳朵听见那些起来攻击我的恶人受罚。"},
{volumn:        19,chapter:        92,versenumber:        12,verse:"义人要发旺如棕树，生长如黎巴嫩的香柏树。"},
{volumn:        19,chapter:        92,versenumber:        13,verse:"他们栽于耶和华的殿中，发旺在我们　神的院里。"},
{volumn:        19,chapter:        92,versenumber:        14,verse:"他们年老的时候仍要结果子，要满了汁浆而常发青，"},
{volumn:        19,chapter:        92,versenumber:        15,verse:"好显明耶和华是正直的。他是我的磐石，在他毫无不义。"},
{volumn:        19,chapter:        93,versenumber:         1,verse:"耶和华作王！他以威严为衣穿上；耶和华以能力为衣，以能力束腰，世界就坚定，不得动摇。"},
{volumn:        19,chapter:        93,versenumber:         2,verse:"你的宝座从太初立定；你从亘古就有。"},
{volumn:        19,chapter:        93,versenumber:         3,verse:"耶和华啊，大水扬起，大水发声，波浪澎湃。"},
{volumn:        19,chapter:        93,versenumber:         4,verse:"耶和华在高处大有能力，胜过诸水的响声，洋海的大浪。"},
{volumn:        19,chapter:        93,versenumber:         5,verse:"耶和华啊，你的法度最的确；你的殿永称为圣，是合宜的。"},
{volumn:        19,chapter:        94,versenumber:         1,verse:"耶和华啊，你是伸冤的　神；伸冤的　神啊，求你发出光来！"},
{volumn:        19,chapter:        94,versenumber:         2,verse:"审判世界的主啊，求你挺身而立，使骄傲人受应得的报应！"},
{volumn:        19,chapter:        94,versenumber:         3,verse:"耶和华啊，恶人夸胜要到几时呢？要到几时呢？"},
{volumn:        19,chapter:        94,versenumber:         4,verse:"他们絮絮叨叨说傲慢的话；一切作孽的人都自己夸张。"},
{volumn:        19,chapter:        94,versenumber:         5,verse:"耶和华啊，他们强压你的百姓，苦害你的产业。"},
{volumn:        19,chapter:        94,versenumber:         6,verse:"他们杀死寡妇和寄居的，又杀害孤儿。"},
{volumn:        19,chapter:        94,versenumber:         7,verse:"他们说：“耶和华必不看见；雅各的　神必不思念。”"},
{volumn:        19,chapter:        94,versenumber:         8,verse:"你们民间的畜类人当思想；你们愚顽人到几时才有智慧呢？"},
{volumn:        19,chapter:        94,versenumber:         9,verse:"造耳朵的，难道自己不听见吗？造眼睛的，难道自己不看见吗？"},
{volumn:        19,chapter:        94,versenumber:        10,verse:"管教列邦的，就是叫人得知识的，难道自己不惩治人吗？"},
{volumn:        19,chapter:        94,versenumber:        11,verse:"耶和华知道人的意念是虚妄的。"},
{volumn:        19,chapter:        94,versenumber:        12,verse:"耶和华啊，你所管教、用律法所教训的人是有福的！"},
{volumn:        19,chapter:        94,versenumber:        13,verse:"你使他在遭难的日子得享平安；惟有恶人陷在所挖的坑中。"},
{volumn:        19,chapter:        94,versenumber:        14,verse:"因为耶和华必不丢弃他的百姓，也不离弃他的产业。"},
{volumn:        19,chapter:        94,versenumber:        15,verse:"审判要转向公义；心里正直的，必都随从。"},
{volumn:        19,chapter:        94,versenumber:        16,verse:"谁肯为我起来攻击作恶的？谁肯为我站起抵挡作孽的？"},
{volumn:        19,chapter:        94,versenumber:        17,verse:"若不是耶和华帮助我，我就住在寂静之中了。"},
{volumn:        19,chapter:        94,versenumber:        18,verse:"我正说我失了脚，耶和华啊，那时你的慈爱扶助我。"},
{volumn:        19,chapter:        94,versenumber:        19,verse:"我心里多忧多疑，你安慰我，就使我欢乐。"},
{volumn:        19,chapter:        94,versenumber:        20,verse:"那藉着律例架弄残害、在位上行奸恶的，岂能与你相交吗？"},
{volumn:        19,chapter:        94,versenumber:        21,verse:"他们大家聚集攻击义人，将无辜的人定为死罪。"},
{volumn:        19,chapter:        94,versenumber:        22,verse:"但耶和华向来作了我的高台；我的　神作了我投靠的磐石。"},
{volumn:        19,chapter:        94,versenumber:        23,verse:"他叫他们的罪孽归到他们身上。他们正在行恶之中，他要剪除他们；耶和华我们的　神要把他们剪除。"},
{volumn:        19,chapter:        95,versenumber:         1,verse:"来啊，我们要向耶和华歌唱，向拯救我们的磐石欢呼！"},
{volumn:        19,chapter:        95,versenumber:         2,verse:"我们要来感谢他，用诗歌向他欢呼！"},
{volumn:        19,chapter:        95,versenumber:         3,verse:"因耶和华为大　神，为大王，超乎万神之上。"},
{volumn:        19,chapter:        95,versenumber:         4,verse:"地的深处在他手中；山的高峰也属他。"},
{volumn:        19,chapter:        95,versenumber:         5,verse:"海洋属他，是他造的；旱地也是他手造成的。"},
{volumn:        19,chapter:        95,versenumber:         6,verse:"来啊，我们要屈身敬拜，在造我们的耶和华面前跪下。"},
{volumn:        19,chapter:        95,versenumber:         7,verse:"因为他是我们的　神；我们是他草场的羊，是他手下的民。惟愿你们今天听他的话："},
{volumn:        19,chapter:        95,versenumber:         8,verse:"你们不可硬着心，像当日在米利巴，就是在旷野的玛撒。"},
{volumn:        19,chapter:        95,versenumber:         9,verse:"那时，你们的祖宗试我探我，并且观看我的作为。"},
{volumn:        19,chapter:        95,versenumber:        10,verse:"四十年之久，我厌烦那世代，说：“这是心里迷糊的百姓，竟不晓得我的作为！”"},
{volumn:        19,chapter:        95,versenumber:        11,verse:"所以，我在怒中起誓，说：“他们断不可进入我的安息！”"},
{volumn:        19,chapter:        96,versenumber:         1,verse:"你们要向耶和华唱新歌！全地都要向耶和华歌唱！"},
{volumn:        19,chapter:        96,versenumber:         2,verse:"要向耶和华歌唱，称颂他的名！天天传扬他的救恩！"},
{volumn:        19,chapter:        96,versenumber:         3,verse:"在列邦中述说他的荣耀！在万民中述说他的奇事！"},
{volumn:        19,chapter:        96,versenumber:         4,verse:"因耶和华为大，当受极大的赞美；他在万神之上，当受敬畏。"},
{volumn:        19,chapter:        96,versenumber:         5,verse:"外邦的神都属虚无；惟独耶和华创造诸天。"},
{volumn:        19,chapter:        96,versenumber:         6,verse:"有尊荣和威严在他面前；有能力与华美在他圣所。"},
{volumn:        19,chapter:        96,versenumber:         7,verse:"民中的万族啊，你们要将荣耀、能力归给耶和华，都归给耶和华！"},
{volumn:        19,chapter:        96,versenumber:         8,verse:"要将耶和华的名所当得的荣耀归给他，拿供物来进入他的院宇。"},
{volumn:        19,chapter:        96,versenumber:         9,verse:"当以圣洁的（的：或译为）妆饰敬拜耶和华；全地要在他面前战抖！"},
{volumn:        19,chapter:        96,versenumber:        10,verse:"人在列邦中要说：“耶和华作王！世界就坚定，不得动摇；他要按公正审判众民。”"},
{volumn:        19,chapter:        96,versenumber:        11,verse:"愿天欢喜，愿地快乐！愿海和其中所充满的澎湃！"},
{volumn:        19,chapter:        96,versenumber:        12,verse:"愿田和其中所有的都欢乐！那时，林中的树木都要在耶和华面前欢呼。"},
{volumn:        19,chapter:        96,versenumber:        13,verse:"因为他来了，他来要审判全地。他要按公义审判世界，按他的信实审判万民。"},
{volumn:        19,chapter:        97,versenumber:         1,verse:"耶和华作王！愿地快乐！愿众海岛欢喜！"},
{volumn:        19,chapter:        97,versenumber:         2,verse:"密云和幽暗在他的四围；公义和公平是他宝座的根基。"},
{volumn:        19,chapter:        97,versenumber:         3,verse:"有烈火在他前头行，烧灭他四围的敌人。"},
{volumn:        19,chapter:        97,versenumber:         4,verse:"他的闪电光照世界，大地看见便震动。"},
{volumn:        19,chapter:        97,versenumber:         5,verse:"诸山见耶和华的面，就是全地之主的面，便消化如蜡。"},
{volumn:        19,chapter:        97,versenumber:         6,verse:"诸天表明他的公义；万民看见他的荣耀。"},
{volumn:        19,chapter:        97,versenumber:         7,verse:"愿一切侍奉雕刻的偶像、靠虚无之神自夸的，都蒙羞愧。万神哪，你们都当拜他。"},
{volumn:        19,chapter:        97,versenumber:         8,verse:"耶和华啊，锡安听见你的判断就欢喜；犹大的城邑（原文是女子）也都快乐。"},
{volumn:        19,chapter:        97,versenumber:         9,verse:"因为你耶和华至高，超乎全地；你被尊崇，远超万神之上。"},
{volumn:        19,chapter:        97,versenumber:        10,verse:"你们爱耶和华的，都当恨恶罪恶；他保护圣民的性命，搭救他们脱离恶人的手。"},
{volumn:        19,chapter:        97,versenumber:        11,verse:"散布亮光是为义人；预备喜乐是为正直人。"},
{volumn:        19,chapter:        97,versenumber:        12,verse:"你们义人当靠耶和华欢喜，称谢他可记念的圣名。"},
{volumn:        19,chapter:        98,versenumber:         1,verse:"你们要向耶和华唱新歌！因为他行过奇妙的事；他的右手和圣臂施行救恩。"},
{volumn:        19,chapter:        98,versenumber:         2,verse:"耶和华发明了他的救恩，在列邦人眼前显出公义；"},
{volumn:        19,chapter:        98,versenumber:         3,verse:"记念他向以色列家所发的慈爱，所凭的信实。地的四极都看见我们　神的救恩。"},
{volumn:        19,chapter:        98,versenumber:         4,verse:"全地都要向耶和华欢乐；要发起大声，欢呼歌颂！"},
{volumn:        19,chapter:        98,versenumber:         5,verse:"要用琴歌颂耶和华，用琴和诗歌的声音歌颂他！"},
{volumn:        19,chapter:        98,versenumber:         6,verse:"用号和角声，在大君王耶和华面前欢呼！"},
{volumn:        19,chapter:        98,versenumber:         7,verse:"愿海和其中所充满的澎湃；世界和住在其间的也要发声。"},
{volumn:        19,chapter:        98,versenumber:         8,verse:"愿大水拍手；愿诸山在耶和华面前一同欢呼；"},
{volumn:        19,chapter:        98,versenumber:         9,verse:"因为他来要审判遍地。他要按公义审判世界，按公正审判万民。"},
{volumn:        19,chapter:        99,versenumber:         1,verse:"耶和华作王；万民当战抖！他坐在二基路伯上，地当动摇。"},
{volumn:        19,chapter:        99,versenumber:         2,verse:"耶和华在锡安为大；他超乎万民之上。"},
{volumn:        19,chapter:        99,versenumber:         3,verse:"他们当称赞他大而可畏的名；他本为圣！"},
{volumn:        19,chapter:        99,versenumber:         4,verse:"王有能力，喜爱公平，坚立公正，在雅各中施行公平和公义。"},
{volumn:        19,chapter:        99,versenumber:         5,verse:"你们当尊崇耶和华我们的　神，在他脚凳前下拜。他本为圣！"},
{volumn:        19,chapter:        99,versenumber:         6,verse:"在他的祭司中有摩西和亚伦；在求告他名的人中有撒母耳。他们求告耶和华，他就应允他们。"},
{volumn:        19,chapter:        99,versenumber:         7,verse:"他在云柱中对他们说话；他们遵守他的法度和他所赐给他们的律例。"},
{volumn:        19,chapter:        99,versenumber:         8,verse:"耶和华我们的　神啊，你应允他们；你是赦免他们的　神，却按他们所行的报应他们。"},
{volumn:        19,chapter:        99,versenumber:         9,verse:"你们要尊崇耶和华我们的　神，在他的圣山下拜，因为耶和华我们的　神本为圣！"},
{volumn:        19,chapter:       100,versenumber:         1,verse:"普天下当向耶和华欢呼！"},
{volumn:        19,chapter:       100,versenumber:         2,verse:"你们当乐意侍奉耶和华，当来向他歌唱！"},
{volumn:        19,chapter:       100,versenumber:         3,verse:"你们当晓得耶和华是　神！我们是他造的，也是属他的；我们是他的民，也是他草场的羊。"},
{volumn:        19,chapter:       100,versenumber:         4,verse:"当称谢进入他的门；当赞美进入他的院。当感谢他，称颂他的名！"},
{volumn:        19,chapter:       100,versenumber:         5,verse:"因为耶和华本为善。他的慈爱存到永远；他的信实直到万代。"},
{volumn:        19,chapter:       101,versenumber:         1,verse:"我要歌唱慈爱和公平；耶和华啊，我要向你歌颂！"},
{volumn:        19,chapter:       101,versenumber:         2,verse:"我要用智慧行完全的道。你几时到我这里来呢？我要存完全的心行在我家中。"},
{volumn:        19,chapter:       101,versenumber:         3,verse:"邪僻的事，我都不摆在我眼前；悖逆人所做的事，我甚恨恶，不容沾在我身上。"},
{volumn:        19,chapter:       101,versenumber:         4,verse:"弯曲的心思，我必远离；一切的恶人（或译：恶事），我不认识。"},
{volumn:        19,chapter:       101,versenumber:         5,verse:"在暗中谗谤他邻居的，我必将他灭绝；眼目高傲、心里骄纵的，我必不容他。"},
{volumn:        19,chapter:       101,versenumber:         6,verse:"我眼要看国中的诚实人，叫他们与我同住；行为完全的，他要伺候我。"},
{volumn:        19,chapter:       101,versenumber:         7,verse:"行诡诈的，必不得住在我家里；说谎话的，必不得立在我眼前。"},
{volumn:        19,chapter:       101,versenumber:         8,verse:"我每日早晨要灭绝国中所有的恶人，好把一切作孽的从耶和华的城里剪除。"},
{volumn:        19,chapter:       102,versenumber:         1,verse:"耶和华啊，求你听我的祷告，容我的呼求达到你面前！"},
{volumn:        19,chapter:       102,versenumber:         2,verse:"我在急难的日子，求你向我侧耳；不要向我掩面！我呼求的日子，求你快快应允我！"},
{volumn:        19,chapter:       102,versenumber:         3,verse:"因为，我的年日如烟云消灭；我的骨头如火把烧着。"},
{volumn:        19,chapter:       102,versenumber:         4,verse:"我的心被伤，如草枯干，甚至我忘记吃饭。"},
{volumn:        19,chapter:       102,versenumber:         5,verse:"因我唉哼的声音，我的肉紧贴骨头。"},
{volumn:        19,chapter:       102,versenumber:         6,verse:"我如同旷野的鹈鹕；我好像荒场的鸮鸟。"},
{volumn:        19,chapter:       102,versenumber:         7,verse:"我警醒不睡；我像房顶上孤单的麻雀。"},
{volumn:        19,chapter:       102,versenumber:         8,verse:"我的仇敌终日辱骂我；向我猖狂的人指着我赌咒。"},
{volumn:        19,chapter:       102,versenumber:         9,verse:"我吃过炉灰，如同吃饭；我所喝的与眼泪搀杂。"},
{volumn:        19,chapter:       102,versenumber:        10,verse:"这都因你的恼恨和忿怒；你把我拾起来，又把我摔下去。"},
{volumn:        19,chapter:       102,versenumber:        11,verse:"我的年日如日影偏斜；我也如草枯干。"},
{volumn:        19,chapter:       102,versenumber:        12,verse:"惟你耶和华必存到永远；你可记念的名也存到万代。"},
{volumn:        19,chapter:       102,versenumber:        13,verse:"你必起来怜恤锡安，因现在是可怜她的时候，日期已经到了。"},
{volumn:        19,chapter:       102,versenumber:        14,verse:"你的仆人原来喜悦她的石头，可怜她的尘土。"},
{volumn:        19,chapter:       102,versenumber:        15,verse:"列国要敬畏耶和华的名；世上诸王都敬畏你的荣耀。"},
{volumn:        19,chapter:       102,versenumber:        16,verse:"因为耶和华建造了锡安，在他荣耀里显现。"},
{volumn:        19,chapter:       102,versenumber:        17,verse:"他垂听穷人的祷告，并不藐视他们的祈求。"},
{volumn:        19,chapter:       102,versenumber:        18,verse:"这必为后代的人记下，将来受造的民要赞美耶和华。"},
{volumn:        19,chapter:       102,versenumber:        19,verse:"因为，他从至高的圣所垂看；耶和华从天向地观察，"},
{volumn:        19,chapter:       102,versenumber:        20,verse:"要垂听被囚之人的叹息，要释放将要死的人，"},
{volumn:        19,chapter:       102,versenumber:        21,verse:"使人在锡安传扬耶和华的名，在耶路撒冷传扬赞美他的话，"},
{volumn:        19,chapter:       102,versenumber:        22,verse:"就是在万民和列国聚会侍奉耶和华的时候。"},
{volumn:        19,chapter:       102,versenumber:        23,verse:"他使我的力量中道衰弱，使我的年日短少。"},
{volumn:        19,chapter:       102,versenumber:        24,verse:"我说：“我的　神啊，不要使我中年去世。你的年数世世无穷！”"},
{volumn:        19,chapter:       102,versenumber:        25,verse:"你起初立了地的根基；天也是你手所造的。"},
{volumn:        19,chapter:       102,versenumber:        26,verse:"天地都要灭没，你却要长存；天地都要如外衣渐渐旧了。你要将天地如里衣更换，天地就都改变了。"},
{volumn:        19,chapter:       102,versenumber:        27,verse:"惟有你永不改变；你的年数没有穷尽。"},
{volumn:        19,chapter:       102,versenumber:        28,verse:"你仆人的子孙要长存；他们的后裔要坚立在你面前。"},
{volumn:        19,chapter:       103,versenumber:         1,verse:"我的心哪，你要称颂耶和华！凡在我里面的，也要称颂他的圣名！"},
{volumn:        19,chapter:       103,versenumber:         2,verse:"我的心哪，你要称颂耶和华！不可忘记他的一切恩惠！"},
{volumn:        19,chapter:       103,versenumber:         3,verse:"他赦免你的一切罪孽，医治你的一切疾病。"},
{volumn:        19,chapter:       103,versenumber:         4,verse:"他救赎你的命脱离死亡，以仁爱和慈悲为你的冠冕。"},
{volumn:        19,chapter:       103,versenumber:         5,verse:"他用美物使你所愿的得以知足，以致你如鹰返老还童。"},
{volumn:        19,chapter:       103,versenumber:         6,verse:"耶和华施行公义，为一切受屈的人伸冤。"},
{volumn:        19,chapter:       103,versenumber:         7,verse:"他使摩西知道他的法则，叫以色列人晓得他的作为。"},
{volumn:        19,chapter:       103,versenumber:         8,verse:"耶和华有怜悯，有恩典，不轻易发怒，且有丰盛的慈爱。"},
{volumn:        19,chapter:       103,versenumber:         9,verse:"他不长久责备，也不永远怀怒。"},
{volumn:        19,chapter:       103,versenumber:        10,verse:"他没有按我们的罪过待我们，也没有照我们的罪孽报应我们。"},
{volumn:        19,chapter:       103,versenumber:        11,verse:"天离地何等的高，他的慈爱向敬畏他的人也是何等的大！"},
{volumn:        19,chapter:       103,versenumber:        12,verse:"东离西有多远，他叫我们的过犯离我们也有多远！"},
{volumn:        19,chapter:       103,versenumber:        13,verse:"父亲怎样怜恤他的儿女，耶和华也怎样怜恤敬畏他的人！"},
{volumn:        19,chapter:       103,versenumber:        14,verse:"因为他知道我们的本体，思念我们不过是尘土。"},
{volumn:        19,chapter:       103,versenumber:        15,verse:"至于世人，他的年日如草一样。他发旺如野地的花，"},
{volumn:        19,chapter:       103,versenumber:        16,verse:"经风一吹，便归无有；它的原处也不再认识它。"},
{volumn:        19,chapter:       103,versenumber:        17,verse:"但耶和华的慈爱归于敬畏他的人，从亘古到永远；他的公义也归于子子孙孙，"},
{volumn:        19,chapter:       103,versenumber:        18,verse:"就是那些遵守他的约、记念他的训词而遵行的人。"},
{volumn:        19,chapter:       103,versenumber:        19,verse:"耶和华在天上立定宝座；他的权柄（原文是国）统管万有。"},
{volumn:        19,chapter:       103,versenumber:        20,verse:"听从他命令、成全他旨意、有大能的天使，都要称颂耶和华！"},
{volumn:        19,chapter:       103,versenumber:        21,verse:"你们作他的诸军，作他的仆役，行他所喜悦的，都要称颂耶和华！"},
{volumn:        19,chapter:       103,versenumber:        22,verse:"你们一切被他造的，在他所治理的各处，都要称颂耶和华！我的心哪，你要称颂耶和华！"},
{volumn:        19,chapter:       104,versenumber:         1,verse:"我的心哪，你要称颂耶和华！耶和华我的　神啊，你为至大！你以尊荣威严为衣服，"},
{volumn:        19,chapter:       104,versenumber:         2,verse:"披上亮光，如披外袍，铺张穹苍，如铺幔子，"},
{volumn:        19,chapter:       104,versenumber:         3,verse:"在水中立楼阁的栋梁，用云彩为车辇，藉着风的翅膀而行，"},
{volumn:        19,chapter:       104,versenumber:         4,verse:"以风为使者，以火焰为仆役，"},
{volumn:        19,chapter:       104,versenumber:         5,verse:"将地立在根基上，使地永不动摇。"},
{volumn:        19,chapter:       104,versenumber:         6,verse:"你用深水遮盖地面，犹如衣裳；诸水高过山岭。"},
{volumn:        19,chapter:       104,versenumber:         7,verse:"你的斥责一发，水便奔逃；你的雷声一发，水便奔流。"},
{volumn:        19,chapter:       104,versenumber:         8,verse:"诸山升上，诸谷沉下（或译：随山上翻，随谷下流），归你为它所安定之地。"},
{volumn:        19,chapter:       104,versenumber:         9,verse:"你定了界限，使水不能过去，不再转回遮盖地面。"},
{volumn:        19,chapter:       104,versenumber:        10,verse:"耶和华使泉源涌在山谷，流在山间，"},
{volumn:        19,chapter:       104,versenumber:        11,verse:"使野地的走兽有水喝，野驴得解其渴。"},
{volumn:        19,chapter:       104,versenumber:        12,verse:"天上的飞鸟在水旁住宿，在树枝上啼叫。"},
{volumn:        19,chapter:       104,versenumber:        13,verse:"他从楼阁中浇灌山岭；因他作为的功效，地就丰足。"},
{volumn:        19,chapter:       104,versenumber:        14,verse:"他使草生长，给六畜吃，使菜蔬发长，供给人用，使人从地里能得食物，"},
{volumn:        19,chapter:       104,versenumber:        15,verse:"又得酒能悦人心，得油能润人面，得粮能养人心。"},
{volumn:        19,chapter:       104,versenumber:        16,verse:"佳美的树木，就是黎巴嫩的香柏树，是耶和华所栽种的，都满了汁浆。"},
{volumn:        19,chapter:       104,versenumber:        17,verse:"雀鸟在其上搭窝；至于鹤，松树是它的房屋。"},
{volumn:        19,chapter:       104,versenumber:        18,verse:"高山为野山羊的住所；岩石为沙番的藏处。"},
{volumn:        19,chapter:       104,versenumber:        19,verse:"你安置月亮为定节令；日头自知沉落。"},
{volumn:        19,chapter:       104,versenumber:        20,verse:"你造黑暗为夜，林中的百兽就都爬出来。"},
{volumn:        19,chapter:       104,versenumber:        21,verse:"少壮狮子吼叫，要抓食，向　神寻求食物。"},
{volumn:        19,chapter:       104,versenumber:        22,verse:"日头一出，兽便躲避，卧在洞里。"},
{volumn:        19,chapter:       104,versenumber:        23,verse:"人出去做工，劳碌直到晚上。"},
{volumn:        19,chapter:       104,versenumber:        24,verse:"耶和华啊，你所造的何其多！都是你用智慧造成的；遍地满了你的丰富。"},
{volumn:        19,chapter:       104,versenumber:        25,verse:"那里有海，又大又广；其中有无数的动物，大小活物都有。"},
{volumn:        19,chapter:       104,versenumber:        26,verse:"那里有船行走，有你所造的鳄鱼游泳在其中。"},
{volumn:        19,chapter:       104,versenumber:        27,verse:"这都仰望你按时给它食物。"},
{volumn:        19,chapter:       104,versenumber:        28,verse:"你给它们，它们便拾起来；你张手，它们饱得美食。"},
{volumn:        19,chapter:       104,versenumber:        29,verse:"你掩面，它们便惊惶；你收回它们的气，它们就死亡，归于尘土。"},
{volumn:        19,chapter:       104,versenumber:        30,verse:"你发出你的灵，它们便受造；你使地面更换为新。"},
{volumn:        19,chapter:       104,versenumber:        31,verse:"愿耶和华的荣耀存到永远！愿耶和华喜悦自己所造的！"},
{volumn:        19,chapter:       104,versenumber:        32,verse:"他看地，地便震动；他摸山，山就冒烟。"},
{volumn:        19,chapter:       104,versenumber:        33,verse:"我要一生向耶和华唱诗！我还活的时候，要向我　神歌颂！"},
{volumn:        19,chapter:       104,versenumber:        34,verse:"愿他以我的默念为甘甜！我要因耶和华欢喜！"},
{volumn:        19,chapter:       104,versenumber:        35,verse:"愿罪人从世上消灭！愿恶人归于无有！我的心哪，要称颂耶和华！你们要赞美耶和华（原文是哈利路亚；下同）！"},
{volumn:        19,chapter:       105,versenumber:         1,verse:"你们要称谢耶和华，求告他的名，在万民中传扬他的作为！"},
{volumn:        19,chapter:       105,versenumber:         2,verse:"要向他唱诗歌颂，谈论他一切奇妙的作为！"},
{volumn:        19,chapter:       105,versenumber:         3,verse:"要以他的圣名夸耀！寻求耶和华的人，心中应当欢喜！"},
{volumn:        19,chapter:       105,versenumber:         4,verse:"要寻求耶和华与他的能力，时常寻求他的面。"},
{volumn:        19,chapter:       105,versenumber:         5,verse:"他仆人亚伯拉罕的后裔，他所拣选雅各的子孙哪，你们要记念他奇妙的作为和他的奇事，并他口中的判语。"},
{volumn:        19,chapter:       105,versenumber:         6,verse:""},
{volumn:        19,chapter:       105,versenumber:         7,verse:"他是耶和华我们的　神；全地都有他的判断。"},
{volumn:        19,chapter:       105,versenumber:         8,verse:"他记念他的约，直到永远，他所吩咐的话，直到千代，"},
{volumn:        19,chapter:       105,versenumber:         9,verse:"就是与亚伯拉罕所立的约，向以撒所起的誓。"},
{volumn:        19,chapter:       105,versenumber:        10,verse:"他又将这约向雅各定为律例，向以色列定为永远的约，"},
{volumn:        19,chapter:       105,versenumber:        11,verse:"说：“我必将迦南地赐给你，作你产业的份。”"},
{volumn:        19,chapter:       105,versenumber:        12,verse:"当时，他们人丁有限，数目稀少，并且在那地为寄居的。"},
{volumn:        19,chapter:       105,versenumber:        13,verse:"他们从这邦游到那邦，从这国行到那国。"},
{volumn:        19,chapter:       105,versenumber:        14,verse:"他不容什么人欺负他们，为他们的缘故责备君王，"},
{volumn:        19,chapter:       105,versenumber:        15,verse:"说：“不可难为我受膏的人，也不可恶待我的先知。”"},
{volumn:        19,chapter:       105,versenumber:        16,verse:"他命饥荒降在那地上，将所倚靠的粮食全行断绝，"},
{volumn:        19,chapter:       105,versenumber:        17,verse:"在他们以先打发一个人去，					约瑟被卖为奴仆。"},
{volumn:        19,chapter:       105,versenumber:        18,verse:"人用脚镣伤他的脚；他被铁链捆拘。"},
{volumn:        19,chapter:       105,versenumber:        19,verse:"耶和华的话试炼他，直等到他所说的应验了。"},
{volumn:        19,chapter:       105,versenumber:        20,verse:"王打发人把他解开，就是治理众民的，把他释放，"},
{volumn:        19,chapter:       105,versenumber:        21,verse:"立他作王家之主，掌管他一切所有的，"},
{volumn:        19,chapter:       105,versenumber:        22,verse:"使他随意捆绑他的臣宰，将智慧教导他的长老。"},
{volumn:        19,chapter:       105,versenumber:        23,verse:"以色列也到了埃及；雅各在含地寄居。"},
{volumn:        19,chapter:       105,versenumber:        24,verse:"耶和华使他的百姓生养众多，使他们比敌人强盛，"},
{volumn:        19,chapter:       105,versenumber:        25,verse:"使敌人的心转去恨他的百姓，并用诡计待他的仆人。"},
{volumn:        19,chapter:       105,versenumber:        26,verse:"他打发他的仆人摩西和他所拣选的亚伦，"},
{volumn:        19,chapter:       105,versenumber:        27,verse:"在敌人中间显他的神迹，在含地显他的奇事。"},
{volumn:        19,chapter:       105,versenumber:        28,verse:"他命黑暗，就有黑暗；没有违背他话的。"},
{volumn:        19,chapter:       105,versenumber:        29,verse:"他叫埃及的水变为血，叫他们的鱼死了。"},
{volumn:        19,chapter:       105,versenumber:        30,verse:"在他们的地上以及王宫的内室，青蛙多多滋生。"},
{volumn:        19,chapter:       105,versenumber:        31,verse:"他说一声，苍蝇就成群而来，并有虱子进入他们四境。"},
{volumn:        19,chapter:       105,versenumber:        32,verse:"他给他们降下冰雹为雨，在他们的地上降下火焰。"},
{volumn:        19,chapter:       105,versenumber:        33,verse:"他也击打他们的葡萄树和无花果树，毁坏他们境内的树木。"},
{volumn:        19,chapter:       105,versenumber:        34,verse:"他说一声，就有蝗虫蚂蚱上来，不计其数，"},
{volumn:        19,chapter:       105,versenumber:        35,verse:"吃尽了他们地上各样的菜蔬和田地的出产。"},
{volumn:        19,chapter:       105,versenumber:        36,verse:"他又击杀他们国内一切的长子，就是他们强壮时头生的。"},
{volumn:        19,chapter:       105,versenumber:        37,verse:"他领自己的百姓带银子金子出来；他支派中没有一个软弱的。"},
{volumn:        19,chapter:       105,versenumber:        38,verse:"他们出来的时候，埃及人便欢喜；原来埃及人惧怕他们。"},
{volumn:        19,chapter:       105,versenumber:        39,verse:"他铺张云彩当遮盖，夜间使火光照。"},
{volumn:        19,chapter:       105,versenumber:        40,verse:"他们一求，他就使鹌鹑飞来，并用天上的粮食叫他们饱足。"},
{volumn:        19,chapter:       105,versenumber:        41,verse:"他打开磐石，水就涌出；在干旱之处，水流成河。"},
{volumn:        19,chapter:       105,versenumber:        42,verse:"这都因他记念他的圣言和他的仆人亚伯拉罕。"},
{volumn:        19,chapter:       105,versenumber:        43,verse:"他带领百姓欢乐而出，带领选民欢呼前往。"},
{volumn:        19,chapter:       105,versenumber:        44,verse:"他将列国的地赐给他们，他们便承受众民劳碌得来的，"},
{volumn:        19,chapter:       105,versenumber:        45,verse:"好使他们遵他的律例，守他的律法。你们要赞美耶和华！"},
{volumn:        19,chapter:       106,versenumber:         1,verse:"你们要赞美耶和华！要称谢耶和华，因他本为善；他的慈爱永远长存！"},
{volumn:        19,chapter:       106,versenumber:         2,verse:"谁能传说耶和华的大能？谁能表明他一切的美德？"},
{volumn:        19,chapter:       106,versenumber:         3,verse:"凡遵守公平、常行公义的，这人便为有福！"},
{volumn:        19,chapter:       106,versenumber:         4,verse:"耶和华啊，你用恩惠待你的百姓；求你也用这恩惠记念我，开你的救恩眷顾我，"},
{volumn:        19,chapter:       106,versenumber:         5,verse:"使我见你选民的福，乐你国民的乐，与你的产业一同夸耀。"},
{volumn:        19,chapter:       106,versenumber:         6,verse:"我们与我们的祖宗一同犯罪；我们作了孽，行了恶。"},
{volumn:        19,chapter:       106,versenumber:         7,verse:"我们的祖宗在埃及不明白你的奇事，不记念你丰盛的慈爱，反倒在红海行了悖逆。"},
{volumn:        19,chapter:       106,versenumber:         8,verse:"然而，他因自己的名拯救他们，为要彰显他的大能，"},
{volumn:        19,chapter:       106,versenumber:         9,verse:"并且斥责红海，海便干了；他带领他们经过深处，如同经过旷野。"},
{volumn:        19,chapter:       106,versenumber:        10,verse:"他拯救他们脱离恨他们人的手，从仇敌手中救赎他们。"},
{volumn:        19,chapter:       106,versenumber:        11,verse:"水淹没他们的敌人，没有一个存留。"},
{volumn:        19,chapter:       106,versenumber:        12,verse:"那时，他们才信了他的话，歌唱赞美他。"},
{volumn:        19,chapter:       106,versenumber:        13,verse:"等不多时，他们就忘了他的作为，不仰望他的指教，"},
{volumn:        19,chapter:       106,versenumber:        14,verse:"反倒在旷野大起欲心，在荒地试探　神。"},
{volumn:        19,chapter:       106,versenumber:        15,verse:"他将他们所求的赐给他们，却使他们的心灵软弱。"},
{volumn:        19,chapter:       106,versenumber:        16,verse:"他们又在营中嫉妒摩西和耶和华的圣者亚伦。"},
{volumn:        19,chapter:       106,versenumber:        17,verse:"地裂开，吞下大坍，掩盖亚比兰一党的人。"},
{volumn:        19,chapter:       106,versenumber:        18,verse:"有火在他们的党中发起；有火焰烧毁了恶人。"},
{volumn:        19,chapter:       106,versenumber:        19,verse:"他们在何烈山造了牛犊，叩拜铸成的像。"},
{volumn:        19,chapter:       106,versenumber:        20,verse:"如此将他们荣耀的主换为吃草之牛的像，"},
{volumn:        19,chapter:       106,versenumber:        21,verse:"忘了　神他们的救主；他曾在埃及行大事，"},
{volumn:        19,chapter:       106,versenumber:        22,verse:"在含地行奇事，在红海行可畏的事。"},
{volumn:        19,chapter:       106,versenumber:        23,verse:"所以，他说要灭绝他们；若非有他所拣选的摩西站在当中（原文是破口），使他的忿怒转消，恐怕他就灭绝他们。"},
{volumn:        19,chapter:       106,versenumber:        24,verse:"他们又藐视那美地，不信他的话，"},
{volumn:        19,chapter:       106,versenumber:        25,verse:"在自己帐棚内发怨言，不听耶和华的声音。"},
{volumn:        19,chapter:       106,versenumber:        26,verse:"所以，他对他们起誓：必叫他们倒在旷野，"},
{volumn:        19,chapter:       106,versenumber:        27,verse:"叫他们的后裔倒在列国之中，分散在各地。"},
{volumn:        19,chapter:       106,versenumber:        28,verse:"他们又与巴力·毗珥连合，且吃了祭死神（或译：人）的物。"},
{volumn:        19,chapter:       106,versenumber:        29,verse:"他们这样行，惹耶和华发怒，便有瘟疫流行在他们中间。"},
{volumn:        19,chapter:       106,versenumber:        30,verse:"那时，非尼哈站起，刑罚恶人，瘟疫这才止息。"},
{volumn:        19,chapter:       106,versenumber:        31,verse:"那就算为他的义，世世代代，直到永远。"},
{volumn:        19,chapter:       106,versenumber:        32,verse:"他们在米利巴水又叫耶和华发怒，甚至摩西也受了亏损，"},
{volumn:        19,chapter:       106,versenumber:        33,verse:"是因他们惹动他的灵，摩西（原文是他）用嘴说了急躁的话。"},
{volumn:        19,chapter:       106,versenumber:        34,verse:"他们不照耶和华所吩咐的灭绝外邦人，"},
{volumn:        19,chapter:       106,versenumber:        35,verse:"反与他们混杂相合，学习他们的行为，"},
{volumn:        19,chapter:       106,versenumber:        36,verse:"侍奉他们的偶像，这就成了自己的网罗，"},
{volumn:        19,chapter:       106,versenumber:        37,verse:"把自己的儿女祭祀鬼魔，"},
{volumn:        19,chapter:       106,versenumber:        38,verse:"流无辜人的血，就是自己儿女的血，把他们祭祀迦南的偶像，那地就被血污秽了。"},
{volumn:        19,chapter:       106,versenumber:        39,verse:"这样，他们被自己所做的污秽了，在行为上犯了邪淫。"},
{volumn:        19,chapter:       106,versenumber:        40,verse:"所以，耶和华的怒气向他的百姓发作，憎恶他的产业，"},
{volumn:        19,chapter:       106,versenumber:        41,verse:"将他们交在外邦人的手里；恨他们的人就辖制他们。"},
{volumn:        19,chapter:       106,versenumber:        42,verse:"他们的仇敌也欺压他们，他们就伏在敌人手下。"},
{volumn:        19,chapter:       106,versenumber:        43,verse:"他屡次搭救他们，他们却设谋背逆，因自己的罪孽降为卑下。"},
{volumn:        19,chapter:       106,versenumber:        44,verse:"然而，他听见他们哀告的时候，就眷顾他们的急难，"},
{volumn:        19,chapter:       106,versenumber:        45,verse:"为他们记念他的约，照他丰盛的慈爱后悔。"},
{volumn:        19,chapter:       106,versenumber:        46,verse:"他也使他们在凡掳掠他们的人面前蒙怜恤。"},
{volumn:        19,chapter:       106,versenumber:        47,verse:"耶和华我们的　神啊，求你拯救我们，从外邦中招聚我们，我们好称赞你的圣名，以赞美你为夸胜。"},
{volumn:        19,chapter:       106,versenumber:        48,verse:"耶和华以色列的　神是应当称颂的，从亘古直到永远。愿众民都说：“阿们！”你们要赞美耶和华！"},
{volumn:        19,chapter:       107,versenumber:         1,verse:"你们要称谢耶和华，因他本为善；他的慈爱永远长存！"},
{volumn:        19,chapter:       107,versenumber:         2,verse:"愿耶和华的赎民说这话，就是他从敌人手中所救赎的，"},
{volumn:        19,chapter:       107,versenumber:         3,verse:"从各地，从东从西，从南从北，所招聚来的。"},
{volumn:        19,chapter:       107,versenumber:         4,verse:"他们在旷野荒地漂流，寻不见可住的城邑，"},
{volumn:        19,chapter:       107,versenumber:         5,verse:"又饥又渴，心里发昏。"},
{volumn:        19,chapter:       107,versenumber:         6,verse:"于是，他们在苦难中哀求耶和华；他从他们的祸患中搭救他们，"},
{volumn:        19,chapter:       107,versenumber:         7,verse:"又领他们行走直路，使他们往可居住的城邑。"},
{volumn:        19,chapter:       107,versenumber:         8,verse:"但愿人因耶和华的慈爱和他向人所行的奇事都称赞他；"},
{volumn:        19,chapter:       107,versenumber:         9,verse:"因他使心里渴慕的人得以知足，使心里饥饿的人得饱美物。"},
{volumn:        19,chapter:       107,versenumber:        10,verse:"那些坐在黑暗中、死荫里的人被困苦和铁链捆锁，"},
{volumn:        19,chapter:       107,versenumber:        11,verse:"是因他们违背　神的话语，藐视至高者的旨意。"},
{volumn:        19,chapter:       107,versenumber:        12,verse:"所以，他用劳苦治服他们的心；他们仆倒，无人扶助。"},
{volumn:        19,chapter:       107,versenumber:        13,verse:"于是，他们在苦难中哀求耶和华；他从他们的祸患中拯救他们。"},
{volumn:        19,chapter:       107,versenumber:        14,verse:"他从黑暗中和死荫里领他们出来，折断他们的绑索。"},
{volumn:        19,chapter:       107,versenumber:        15,verse:"但愿人因耶和华的慈爱和他向人所行的奇事都称赞他；"},
{volumn:        19,chapter:       107,versenumber:        16,verse:"因为他打破了铜门，砍断了铁闩。"},
{volumn:        19,chapter:       107,versenumber:        17,verse:"愚妄人因自己的过犯和自己的罪孽便受苦楚。"},
{volumn:        19,chapter:       107,versenumber:        18,verse:"他们心里厌恶各样的食物，就临近死门。"},
{volumn:        19,chapter:       107,versenumber:        19,verse:"于是，他们在苦难中哀求耶和华；他从他们的祸患中拯救他们。"},
{volumn:        19,chapter:       107,versenumber:        20,verse:"他发命医治他们，救他们脱离死亡。"},
{volumn:        19,chapter:       107,versenumber:        21,verse:"但愿人因耶和华的慈爱和他向人所行的奇事都称赞他。"},
{volumn:        19,chapter:       107,versenumber:        22,verse:"愿他们以感谢为祭献给他，欢呼述说他的作为！"},
{volumn:        19,chapter:       107,versenumber:        23,verse:"在海上坐船，在大水中经理事务的，"},
{volumn:        19,chapter:       107,versenumber:        24,verse:"他们看见耶和华的作为，并他在深水中的奇事。"},
{volumn:        19,chapter:       107,versenumber:        25,verse:"因他一吩咐，狂风就起来，海中的波浪也扬起。"},
{volumn:        19,chapter:       107,versenumber:        26,verse:"他们上到天空，下到海底；他们的心因患难便消化。"},
{volumn:        19,chapter:       107,versenumber:        27,verse:"他们摇摇晃晃，东倒西歪，好像醉酒的人；他们的智慧无法可施。"},
{volumn:        19,chapter:       107,versenumber:        28,verse:"于是，他们在苦难中哀求耶和华，他从他们的祸患中领出他们来。"},
{volumn:        19,chapter:       107,versenumber:        29,verse:"他使狂风止息，波浪就平静。"},
{volumn:        19,chapter:       107,versenumber:        30,verse:"风息浪静，他们便欢喜；他就引他们到所愿去的海口。"},
{volumn:        19,chapter:       107,versenumber:        31,verse:"但愿人因耶和华的慈爱和他向人所行的奇事都称赞他。"},
{volumn:        19,chapter:       107,versenumber:        32,verse:"愿他们在民的会中尊崇他，在长老的位上赞美他！"},
{volumn:        19,chapter:       107,versenumber:        33,verse:"他使江河变为旷野，叫水泉变为干渴之地，"},
{volumn:        19,chapter:       107,versenumber:        34,verse:"使肥地变为碱地；这都因其间居民的罪恶。"},
{volumn:        19,chapter:       107,versenumber:        35,verse:"他使旷野变为水潭，叫旱地变为水泉。"},
{volumn:        19,chapter:       107,versenumber:        36,verse:"他使饥饿的人住在那里，好建造可住的城邑，"},
{volumn:        19,chapter:       107,versenumber:        37,verse:"又种田地，栽葡萄园，得享所出的土产。"},
{volumn:        19,chapter:       107,versenumber:        38,verse:"他又赐福给他们，叫他们生养众多，也不叫他们的牲畜减少。"},
{volumn:        19,chapter:       107,versenumber:        39,verse:"他们又因暴虐、患难、愁苦，就减少且卑下。"},
{volumn:        19,chapter:       107,versenumber:        40,verse:"他使君王蒙羞被辱，使他们在荒废无路之地漂流。"},
{volumn:        19,chapter:       107,versenumber:        41,verse:"他却将穷乏人安置在高处，脱离苦难，使他的家属多如羊群。"},
{volumn:        19,chapter:       107,versenumber:        42,verse:"正直人看见就欢喜；罪孽之辈必塞口无言。"},
{volumn:        19,chapter:       107,versenumber:        43,verse:"凡有智慧的，必在这些事上留心，也必思想耶和华的慈爱。"},
{volumn:        19,chapter:       108,versenumber:         1,verse:"神啊，我心坚定；我口（原文是荣耀）要唱诗歌颂！"},
{volumn:        19,chapter:       108,versenumber:         2,verse:"琴瑟啊，你们当醒起！我自己要极早醒起！"},
{volumn:        19,chapter:       108,versenumber:         3,verse:"耶和华啊，我要在万民中称谢你，在列邦中歌颂你！"},
{volumn:        19,chapter:       108,versenumber:         4,verse:"因为，你的慈爱大过诸天；你的诚实达到穹苍。"},
{volumn:        19,chapter:       108,versenumber:         5,verse:"神啊，愿你崇高过于诸天！愿你的荣耀高过全地！"},
{volumn:        19,chapter:       108,versenumber:         6,verse:"求你应允我们，用右手拯救我们，好叫你所亲爱的人得救。"},
{volumn:        19,chapter:       108,versenumber:         7,verse:"神已经指着他的圣洁说（说：或译应许我）：“我要欢乐；我要分开示剑，丈量疏割谷。"},
{volumn:        19,chapter:       108,versenumber:         8,verse:"基列是我的；玛拿西是我的；以法莲是护卫我头的；犹大是我的杖；"},
{volumn:        19,chapter:       108,versenumber:         9,verse:"摩押是我的沐浴盆；我要向以东抛鞋；我必因胜非利士呼喊。”"},
{volumn:        19,chapter:       108,versenumber:        10,verse:"谁能领我进坚固城？谁能引我到以东地？"},
{volumn:        19,chapter:       108,versenumber:        11,verse:"神啊，你不是丢弃了我们吗？　神啊，你不和我们的军兵同去吗？"},
{volumn:        19,chapter:       108,versenumber:        12,verse:"求你帮助我们攻击敌人，因为人的帮助是枉然的。"},
{volumn:        19,chapter:       108,versenumber:        13,verse:"我们倚靠　神才得施展大能，因为践踏我们敌人的就是他。"},
{volumn:        19,chapter:       109,versenumber:         1,verse:"我所赞美的　神啊，求你不要闭口不言。"},
{volumn:        19,chapter:       109,versenumber:         2,verse:"因为恶人的嘴和诡诈人的口已经张开攻击我；他们用撒谎的舌头对我说话。"},
{volumn:        19,chapter:       109,versenumber:         3,verse:"他们围绕我，说怨恨的话，又无故地攻打我。"},
{volumn:        19,chapter:       109,versenumber:         4,verse:"他们与我为敌以报我爱，但我专心祈祷。"},
{volumn:        19,chapter:       109,versenumber:         5,verse:"他们向我以恶报善，以恨报爱。"},
{volumn:        19,chapter:       109,versenumber:         6,verse:"愿你派一个恶人辖制他，派一个对头站在他右边！"},
{volumn:        19,chapter:       109,versenumber:         7,verse:"他受审判的时候，愿他出来担当罪名！愿他的祈祷反成为罪！"},
{volumn:        19,chapter:       109,versenumber:         8,verse:"愿他的年日短少！愿别人得他的职分！"},
{volumn:        19,chapter:       109,versenumber:         9,verse:"愿他的儿女为孤儿，他的妻子为寡妇！"},
{volumn:        19,chapter:       109,versenumber:        10,verse:"愿他的儿女漂流讨饭，从他们荒凉之处出来求食！"},
{volumn:        19,chapter:       109,versenumber:        11,verse:"愿强暴的债主牢笼他一切所有的！愿外人抢他劳碌得来的！"},
{volumn:        19,chapter:       109,versenumber:        12,verse:"愿无人向他延绵施恩！愿无人可怜他的孤儿！"},
{volumn:        19,chapter:       109,versenumber:        13,verse:"愿他的后人断绝，名字被涂抹，不传于下代！"},
{volumn:        19,chapter:       109,versenumber:        14,verse:"愿他祖宗的罪孽被耶和华记念！愿他母亲的罪过不被涂抹！"},
{volumn:        19,chapter:       109,versenumber:        15,verse:"愿这些罪常在耶和华面前，使他的名号断绝于世！"},
{volumn:        19,chapter:       109,versenumber:        16,verse:"因为他不想施恩，却逼迫困苦穷乏的和伤心的人，要把他们治死。"},
{volumn:        19,chapter:       109,versenumber:        17,verse:"他爱咒骂，咒骂就临到他；他不喜爱福乐，福乐就与他远离！"},
{volumn:        19,chapter:       109,versenumber:        18,verse:"他拿咒骂当衣服穿上；这咒骂就如水进他里面，像油入他的骨头。"},
{volumn:        19,chapter:       109,versenumber:        19,verse:"愿这咒骂当他遮身的衣服，当他常束的腰带！"},
{volumn:        19,chapter:       109,versenumber:        20,verse:"这就是我对头和用恶言议论我的人从耶和华那里所受的报应。"},
{volumn:        19,chapter:       109,versenumber:        21,verse:"主耶和华啊，求你为你的名恩待我；因你的慈爱美好，求你搭救我！"},
{volumn:        19,chapter:       109,versenumber:        22,verse:"因为我困苦穷乏，内心受伤。"},
{volumn:        19,chapter:       109,versenumber:        23,verse:"我如日影渐渐偏斜而去；我如蝗虫被抖出来。"},
{volumn:        19,chapter:       109,versenumber:        24,verse:"我因禁食，膝骨软弱；我身上的肉也渐渐瘦了。"},
{volumn:        19,chapter:       109,versenumber:        25,verse:"我受他们的羞辱，他们看见我便摇头。"},
{volumn:        19,chapter:       109,versenumber:        26,verse:"耶和华我的　神啊，求你帮助我，照你的慈爱拯救我，"},
{volumn:        19,chapter:       109,versenumber:        27,verse:"使他们知道这是你的手，是你耶和华所行的事。"},
{volumn:        19,chapter:       109,versenumber:        28,verse:"任凭他们咒骂，惟愿你赐福；他们几时起来就必蒙羞，你的仆人却要欢喜。"},
{volumn:        19,chapter:       109,versenumber:        29,verse:"愿我的对头披戴羞辱！愿他们以自己的羞愧为外袍遮身！"},
{volumn:        19,chapter:       109,versenumber:        30,verse:"我要用口极力称谢耶和华；我要在众人中间赞美他；"},
{volumn:        19,chapter:       109,versenumber:        31,verse:"因为他必站在穷乏人的右边，要救他脱离审判他灵魂的人。"},
{volumn:        19,chapter:       110,versenumber:         1,verse:"耶和华对我主说：“你坐在我的右边，等我使你仇敌作你的脚凳。”"},
{volumn:        19,chapter:       110,versenumber:         2,verse:"耶和华必使你从锡安伸出能力的杖来；你要在你仇敌中掌权。"},
{volumn:        19,chapter:       110,versenumber:         3,verse:"当你掌权的日子（或译：行军的日子），你的民要以圣洁的妆饰为衣（或译：以圣洁为妆饰），甘心牺牲自己；你的民多如清晨的甘露（或译：你少年时光耀如清晨的甘露）。"},
{volumn:        19,chapter:       110,versenumber:         4,verse:"耶和华起了誓，决不后悔，说：“你是照着麦基洗德的等次永远为祭司。”"},
{volumn:        19,chapter:       110,versenumber:         5,verse:"在你右边的主，当他发怒的日子，必打伤列王。"},
{volumn:        19,chapter:       110,versenumber:         6,verse:"他要在列邦中刑罚恶人，尸首就遍满各处；他要在许多国中打破仇敌的头。"},
{volumn:        19,chapter:       110,versenumber:         7,verse:"他要喝路旁的河水，因此必抬起头来。"},
{volumn:        19,chapter:       111,versenumber:         1,verse:"你们要赞美耶和华！我要在正直人的大会中，并公会中，一心称谢耶和华。"},
{volumn:        19,chapter:       111,versenumber:         2,verse:"耶和华的作为本为大；凡喜爱的都必考察。"},
{volumn:        19,chapter:       111,versenumber:         3,verse:"他所行的是尊荣和威严；他的公义存到永远。"},
{volumn:        19,chapter:       111,versenumber:         4,verse:"他行了奇事，使人记念；耶和华有恩惠，有怜悯。"},
{volumn:        19,chapter:       111,versenumber:         5,verse:"他赐粮食给敬畏他的人；他必永远记念他的约。"},
{volumn:        19,chapter:       111,versenumber:         6,verse:"他向百姓显出大能的作为，把外邦的地赐给他们为业。"},
{volumn:        19,chapter:       111,versenumber:         7,verse:"他手所行的是诚实公平；他的训词都是确实的，"},
{volumn:        19,chapter:       111,versenumber:         8,verse:"是永永远远坚定的，是按诚实正直设立的。"},
{volumn:        19,chapter:       111,versenumber:         9,verse:"他向百姓施行救赎，命定他的约，直到永远；他的名圣而可畏。"},
{volumn:        19,chapter:       111,versenumber:        10,verse:"敬畏耶和华是智慧的开端；凡遵行他命令的是聪明人。耶和华是永远当赞美的！"},
{volumn:        19,chapter:       112,versenumber:         1,verse:"你们要赞美耶和华！敬畏耶和华，甚喜爱他命令的，这人便为有福！"},
{volumn:        19,chapter:       112,versenumber:         2,verse:"他的后裔在世必强盛；正直人的后代必要蒙福。"},
{volumn:        19,chapter:       112,versenumber:         3,verse:"他家中有货物，有钱财；他的公义存到永远。"},
{volumn:        19,chapter:       112,versenumber:         4,verse:"正直人在黑暗中，有光向他发现；他有恩惠，有怜悯，有公义。"},
{volumn:        19,chapter:       112,versenumber:         5,verse:"施恩与人、借贷与人的，这人事情顺利；他被审判的时候要诉明自己的冤。"},
{volumn:        19,chapter:       112,versenumber:         6,verse:"他永不动摇；义人被记念，直到永远。"},
{volumn:        19,chapter:       112,versenumber:         7,verse:"他必不怕凶恶的信息；他心坚定，倚靠耶和华。"},
{volumn:        19,chapter:       112,versenumber:         8,verse:"他心确定，总不惧怕，直到他看见敌人遭报。"},
{volumn:        19,chapter:       112,versenumber:         9,verse:"他施舍钱财，周济贫穷；他的仁义存到永远。他的角必被高举，大有荣耀。"},
{volumn:        19,chapter:       112,versenumber:        10,verse:"恶人看见便恼恨，必咬牙而消化；恶人的心愿要归灭绝。"},
{volumn:        19,chapter:       113,versenumber:         1,verse:"你们要赞美耶和华！耶和华的仆人哪，你们要赞美，赞美耶和华的名！"},
{volumn:        19,chapter:       113,versenumber:         2,verse:"耶和华的名是应当称颂的，从今时直到永远！"},
{volumn:        19,chapter:       113,versenumber:         3,verse:"从日出之地到日落之处，耶和华的名是应当赞美的！"},
{volumn:        19,chapter:       113,versenumber:         4,verse:"耶和华超乎万民之上；他的荣耀高过诸天。"},
{volumn:        19,chapter:       113,versenumber:         5,verse:"谁像耶和华我们的　神呢？他坐在至高之处，"},
{volumn:        19,chapter:       113,versenumber:         6,verse:"自己谦卑，观看天上地下的事。"},
{volumn:        19,chapter:       113,versenumber:         7,verse:"他从灰尘里抬举贫寒人，从粪堆中提拔穷乏人，"},
{volumn:        19,chapter:       113,versenumber:         8,verse:"使他们与王子同坐，就是与本国的王子同坐。"},
{volumn:        19,chapter:       113,versenumber:         9,verse:"他使不能生育的妇人安居家中，为多子的乐母。你们要赞美耶和华！"},
{volumn:        19,chapter:       114,versenumber:         1,verse:"以色列出了埃及，雅各家离开说异言之民；"},
{volumn:        19,chapter:       114,versenumber:         2,verse:"那时，犹大为主的圣所，以色列为他所治理的国度。"},
{volumn:        19,chapter:       114,versenumber:         3,verse:"沧海看见就奔逃；约旦河也倒流。"},
{volumn:        19,chapter:       114,versenumber:         4,verse:"大山踊跃，如公羊；小山跳舞，如羊羔。"},
{volumn:        19,chapter:       114,versenumber:         5,verse:"沧海啊，你为何奔逃？约旦哪，你为何倒流？"},
{volumn:        19,chapter:       114,versenumber:         6,verse:"大山哪，你为何踊跃，如公羊？小山哪，你为何跳舞，如羊羔？"},
{volumn:        19,chapter:       114,versenumber:         7,verse:"大地啊，你因见主的面，就是雅各　神的面，便要震动。"},
{volumn:        19,chapter:       114,versenumber:         8,verse:"他叫磐石变为水池，叫坚石变为泉源。"},
{volumn:        19,chapter:       115,versenumber:         1,verse:"耶和华啊，荣耀不要归与我们，不要归与我们；要因你的慈爱和诚实归在你的名下！"},
{volumn:        19,chapter:       115,versenumber:         2,verse:"为何容外邦人说：“他们的　神在哪里呢？”"},
{volumn:        19,chapter:       115,versenumber:         3,verse:"然而，我们的　神在天上，都随自己的意旨行事。"},
{volumn:        19,chapter:       115,versenumber:         4,verse:"他们的偶像是金的，银的，是人手所造的，"},
{volumn:        19,chapter:       115,versenumber:         5,verse:"有口却不能言，有眼却不能看，"},
{volumn:        19,chapter:       115,versenumber:         6,verse:"有耳却不能听，有鼻却不能闻，"},
{volumn:        19,chapter:       115,versenumber:         7,verse:"有手却不能摸，有脚却不能走，有喉咙也不能出声。"},
{volumn:        19,chapter:       115,versenumber:         8,verse:"造他的要和他一样；凡靠他的也要如此。"},
{volumn:        19,chapter:       115,versenumber:         9,verse:"以色列啊，你要倚靠耶和华！他是你的帮助和你的盾牌。"},
{volumn:        19,chapter:       115,versenumber:        10,verse:"亚伦家啊，你们要倚靠耶和华！他是你们的帮助和你们的盾牌。"},
{volumn:        19,chapter:       115,versenumber:        11,verse:"你们敬畏耶和华的，要倚靠耶和华！他是你们的帮助和你们的盾牌。"},
{volumn:        19,chapter:       115,versenumber:        12,verse:"耶和华向来眷念我们；他还要赐福给我们：要赐福给以色列的家，赐福给亚伦的家。"},
{volumn:        19,chapter:       115,versenumber:        13,verse:"凡敬畏耶和华的，无论大小，主必赐福给他。"},
{volumn:        19,chapter:       115,versenumber:        14,verse:"愿耶和华叫你们和你们的子孙日见加增。"},
{volumn:        19,chapter:       115,versenumber:        15,verse:"你们蒙了造天地之耶和华的福！"},
{volumn:        19,chapter:       115,versenumber:        16,verse:"天，是耶和华的天；地，他却给了世人。"},
{volumn:        19,chapter:       115,versenumber:        17,verse:"死人不能赞美耶和华；下到寂静中的也都不能。"},
{volumn:        19,chapter:       115,versenumber:        18,verse:"但我们要称颂耶和华，从今时直到永远。你们要赞美耶和华！"},
{volumn:        19,chapter:       116,versenumber:         1,verse:"我爱耶和华，因为他听了我的声音和我的恳求。"},
{volumn:        19,chapter:       116,versenumber:         2,verse:"他既向我侧耳，我一生要求告他。"},
{volumn:        19,chapter:       116,versenumber:         3,verse:"死亡的绳索缠绕我；阴间的痛苦抓住我；我遭遇患难愁苦。"},
{volumn:        19,chapter:       116,versenumber:         4,verse:"那时，我便求告耶和华的名，说：“耶和华啊，求你救我的灵魂！”"},
{volumn:        19,chapter:       116,versenumber:         5,verse:"耶和华有恩惠，有公义；我们的　神以怜悯为怀。"},
{volumn:        19,chapter:       116,versenumber:         6,verse:"耶和华保护愚人；我落到卑微的地步，他救了我。"},
{volumn:        19,chapter:       116,versenumber:         7,verse:"我的心哪！你要仍归安乐，因为耶和华用厚恩待你。"},
{volumn:        19,chapter:       116,versenumber:         8,verse:"主啊，你救我的命免了死亡，救我的眼免了流泪，救我的脚免了跌倒。"},
{volumn:        19,chapter:       116,versenumber:         9,verse:"我要在耶和华面前行活人之路。"},
{volumn:        19,chapter:       116,versenumber:        10,verse:"我因信，所以如此说话；“我受了极大的困苦。”"},
{volumn:        19,chapter:       116,versenumber:        11,verse:"我曾急促地说：“人都是说谎的！”"},
{volumn:        19,chapter:       116,versenumber:        12,verse:"我拿什么报答耶和华向我所赐的一切厚恩？"},
{volumn:        19,chapter:       116,versenumber:        13,verse:"我要举起救恩的杯，称扬耶和华的名。"},
{volumn:        19,chapter:       116,versenumber:        14,verse:"我要在他众民面前向耶和华还我的愿。"},
{volumn:        19,chapter:       116,versenumber:        15,verse:"在耶和华眼中，看圣民之死极为宝贵。"},
{volumn:        19,chapter:       116,versenumber:        16,verse:"耶和华啊，我真是你的仆人；我是你的仆人，是你婢女的儿子。你已经解开我的绑索。"},
{volumn:        19,chapter:       116,versenumber:        17,verse:"我要以感谢为祭献给你，又要求告耶和华的名。"},
{volumn:        19,chapter:       116,versenumber:        18,verse:"我要在他众民面前，在耶和华殿的院内，在耶路撒冷当中，向耶和华还我的愿。你们要赞美耶和华！"},
{volumn:        19,chapter:       116,versenumber:        19,verse:""},
{volumn:        19,chapter:       117,versenumber:         1,verse:"万国啊，你们都当赞美耶和华！万民哪，你们都当颂赞他！"},
{volumn:        19,chapter:       117,versenumber:         2,verse:"因为他向我们大施慈爱；耶和华的诚实存到永远。你们要赞美耶和华！"},
{volumn:        19,chapter:       118,versenumber:         1,verse:"你们要称谢耶和华，因他本为善；他的慈爱永远长存！"},
{volumn:        19,chapter:       118,versenumber:         2,verse:"愿以色列说：“他的慈爱永远长存！”"},
{volumn:        19,chapter:       118,versenumber:         3,verse:"愿亚伦的家说：“他的慈爱永远长存！”"},
{volumn:        19,chapter:       118,versenumber:         4,verse:"愿敬畏耶和华的说：“他的慈爱永远长存！”"},
{volumn:        19,chapter:       118,versenumber:         5,verse:"我在急难中求告耶和华，他就应允我，把我安置在宽阔之地。"},
{volumn:        19,chapter:       118,versenumber:         6,verse:"有耶和华帮助我，我必不惧怕，人能把我怎么样呢？"},
{volumn:        19,chapter:       118,versenumber:         7,verse:"在那帮助我的人中，有耶和华帮助我，所以我要看见那恨我的人遭报。"},
{volumn:        19,chapter:       118,versenumber:         8,verse:"投靠耶和华，强似倚赖人；"},
{volumn:        19,chapter:       118,versenumber:         9,verse:"投靠耶和华，强似倚赖王子。"},
{volumn:        19,chapter:       118,versenumber:        10,verse:"万民围绕我，我靠耶和华的名必剿灭他们。"},
{volumn:        19,chapter:       118,versenumber:        11,verse:"他们环绕我，围困我，我靠耶和华的名必剿灭他们。"},
{volumn:        19,chapter:       118,versenumber:        12,verse:"他们如同蜂子围绕我，好像烧荆棘的火，必被熄灭；我靠耶和华的名，必剿灭他们。"},
{volumn:        19,chapter:       118,versenumber:        13,verse:"你推我，要叫我跌倒，但耶和华帮助了我。"},
{volumn:        19,chapter:       118,versenumber:        14,verse:"耶和华是我的力量，是我的诗歌；他也成了我的拯救。"},
{volumn:        19,chapter:       118,versenumber:        15,verse:"在义人的帐棚里，有欢呼拯救的声音；“耶和华的右手施展大能。"},
{volumn:        19,chapter:       118,versenumber:        16,verse:"耶和华的右手高举；耶和华的右手施展大能。”"},
{volumn:        19,chapter:       118,versenumber:        17,verse:"我必不致死，仍要存活，并要传扬耶和华的作为。"},
{volumn:        19,chapter:       118,versenumber:        18,verse:"耶和华虽严严地惩治我，却未曾将我交于死亡。"},
{volumn:        19,chapter:       118,versenumber:        19,verse:"给我敞开义门；我要进去称谢耶和华！"},
{volumn:        19,chapter:       118,versenumber:        20,verse:"这是耶和华的门；义人要进去！"},
{volumn:        19,chapter:       118,versenumber:        21,verse:"我要称谢你，因为你已经应允我，又成了我的拯救！"},
{volumn:        19,chapter:       118,versenumber:        22,verse:"匠人所弃的石头已成了房角的头块石头。"},
{volumn:        19,chapter:       118,versenumber:        23,verse:"这是耶和华所做的，在我们眼中看为希奇。"},
{volumn:        19,chapter:       118,versenumber:        24,verse:"这是耶和华所定的日子，我们在其中要高兴欢喜！"},
{volumn:        19,chapter:       118,versenumber:        25,verse:"耶和华啊，求你拯救！耶和华啊，求你使我们亨通！"},
{volumn:        19,chapter:       118,versenumber:        26,verse:"奉耶和华名来的是应当称颂的！我们从耶和华的殿中为你们祝福！"},
{volumn:        19,chapter:       118,versenumber:        27,verse:"耶和华是　神；他光照了我们。理当用绳索把祭牲拴住，牵到坛角那里。"},
{volumn:        19,chapter:       118,versenumber:        28,verse:"你是我的　神，我要称谢你！你是我的　神，我要尊崇你！"},
{volumn:        19,chapter:       118,versenumber:        29,verse:"你们要称谢耶和华，因他本为善；他的慈爱永远长存！"},
{volumn:        19,chapter:       119,versenumber:         1,verse:"行为完全、遵行耶和华律法的，这人便为有福！"},
{volumn:        19,chapter:       119,versenumber:         2,verse:"遵守他的法度、一心寻求他的，这人便为有福！"},
{volumn:        19,chapter:       119,versenumber:         3,verse:"这人不做非义的事，但遵行他的道。"},
{volumn:        19,chapter:       119,versenumber:         4,verse:"耶和华啊，你曾将你的训词吩咐我们，为要我们殷勤遵守。"},
{volumn:        19,chapter:       119,versenumber:         5,verse:"但愿我行事坚定，得以遵守你的律例。"},
{volumn:        19,chapter:       119,versenumber:         6,verse:"我看重你的一切命令，就不至于羞愧。"},
{volumn:        19,chapter:       119,versenumber:         7,verse:"我学了你公义的判语，就要以正直的心称谢你。"},
{volumn:        19,chapter:       119,versenumber:         8,verse:"我必守你的律例；求你总不要丢弃我！"},
{volumn:        19,chapter:       119,versenumber:         9,verse:"少年人用什么洁净他的行为呢？是要遵行你的话！"},
{volumn:        19,chapter:       119,versenumber:        10,verse:"我一心寻求了你；求你不要叫我偏离你的命令。"},
{volumn:        19,chapter:       119,versenumber:        11,verse:"我将你的话藏在心里，免得我得罪你。"},
{volumn:        19,chapter:       119,versenumber:        12,verse:"耶和华啊，你是应当称颂的！求你将你的律例教训我！"},
{volumn:        19,chapter:       119,versenumber:        13,verse:"我用嘴唇传扬你口中的一切典章。"},
{volumn:        19,chapter:       119,versenumber:        14,verse:"我喜悦你的法度，如同喜悦一切的财物。"},
{volumn:        19,chapter:       119,versenumber:        15,verse:"我要默想你的训词，看重你的道路。"},
{volumn:        19,chapter:       119,versenumber:        16,verse:"我要在你的律例中自乐；我不忘记你的话。"},
{volumn:        19,chapter:       119,versenumber:        17,verse:"求你用厚恩待你的仆人，使我存活，我就遵守你的话。"},
{volumn:        19,chapter:       119,versenumber:        18,verse:"求你开我的眼睛，使我看出你律法中的奇妙。"},
{volumn:        19,chapter:       119,versenumber:        19,verse:"我是在地上作寄居的；求你不要向我隐瞒你的命令！"},
{volumn:        19,chapter:       119,versenumber:        20,verse:"我时常切慕你的典章，甚至心碎。"},
{volumn:        19,chapter:       119,versenumber:        21,verse:"受咒诅、偏离你命令的骄傲人，你已经责备他们。"},
{volumn:        19,chapter:       119,versenumber:        22,verse:"求你除掉我所受的羞辱和藐视，因我遵守你的法度。"},
{volumn:        19,chapter:       119,versenumber:        23,verse:"虽有首领坐着妄论我，你仆人却思想你的律例。"},
{volumn:        19,chapter:       119,versenumber:        24,verse:"你的法度是我所喜乐的，是我的谋士。"},
{volumn:        19,chapter:       119,versenumber:        25,verse:"我的性命几乎归于尘土；求你照你的话将我救活！"},
{volumn:        19,chapter:       119,versenumber:        26,verse:"我述说我所行的，你应允了我；求你将你的律例教训我！"},
{volumn:        19,chapter:       119,versenumber:        27,verse:"求你使我明白你的训词，我就思想你的奇事。"},
{volumn:        19,chapter:       119,versenumber:        28,verse:"我的心因愁苦而消化；求你照你的话使我坚立！"},
{volumn:        19,chapter:       119,versenumber:        29,verse:"求你使我离开奸诈的道，开恩将你的律法赐给我！"},
{volumn:        19,chapter:       119,versenumber:        30,verse:"我拣选了忠信的道，将你的典章摆在我面前。"},
{volumn:        19,chapter:       119,versenumber:        31,verse:"我持守你的法度；耶和华啊，求你不要叫我羞愧！"},
{volumn:        19,chapter:       119,versenumber:        32,verse:"你开广我心的时候，我就往你命令的道上直奔。"},
{volumn:        19,chapter:       119,versenumber:        33,verse:"耶和华啊，求你将你的律例指教我，我必遵守到底！"},
{volumn:        19,chapter:       119,versenumber:        34,verse:"求你赐我悟性，我便遵守你的律法，且要一心遵守。"},
{volumn:        19,chapter:       119,versenumber:        35,verse:"求你叫我遵行你的命令，因为这是我所喜乐的。"},
{volumn:        19,chapter:       119,versenumber:        36,verse:"求你使我的心趋向你的法度，不趋向非义之财。"},
{volumn:        19,chapter:       119,versenumber:        37,verse:"求你叫我转眼不看虚假，又叫我在你的道中生活。"},
{volumn:        19,chapter:       119,versenumber:        38,verse:"你向敬畏你的人所应许的话，求你向仆人坚定！"},
{volumn:        19,chapter:       119,versenumber:        39,verse:"求你使我所怕的羞辱远离我，因你的典章本为美。"},
{volumn:        19,chapter:       119,versenumber:        40,verse:"我羡慕你的训词；求你使我在你的公义上生活！"},
{volumn:        19,chapter:       119,versenumber:        41,verse:"耶和华啊，愿你照你的话，使你的慈爱，就是你的救恩，临到我身上，"},
{volumn:        19,chapter:       119,versenumber:        42,verse:"我就有话回答那羞辱我的，因我倚靠你的话。"},
{volumn:        19,chapter:       119,versenumber:        43,verse:"求你叫真理的话总不离开我口，因我仰望你的典章。"},
{volumn:        19,chapter:       119,versenumber:        44,verse:"我要常守你的律法，直到永永远远。"},
{volumn:        19,chapter:       119,versenumber:        45,verse:"我要自由而行（或译：我要行在宽阔之地），因我素来考究你的训词。"},
{volumn:        19,chapter:       119,versenumber:        46,verse:"我也要在君王面前论说你的法度，并不至于羞愧。"},
{volumn:        19,chapter:       119,versenumber:        47,verse:"我要在你的命令中自乐；这命令素来是我所爱的。"},
{volumn:        19,chapter:       119,versenumber:        48,verse:"我又要遵行（原文是举手）你的命令，这命令素来是我所爱的；我也要思想你的律例。"},
{volumn:        19,chapter:       119,versenumber:        49,verse:"求你记念向你仆人所应许的话，叫我有盼望。"},
{volumn:        19,chapter:       119,versenumber:        50,verse:"这话将我救活了；我在患难中，因此得安慰。"},
{volumn:        19,chapter:       119,versenumber:        51,verse:"骄傲的人甚侮慢我，我却未曾偏离你的律法。"},
{volumn:        19,chapter:       119,versenumber:        52,verse:"耶和华啊，我记念你从古以来的典章，就得了安慰。"},
{volumn:        19,chapter:       119,versenumber:        53,verse:"我见恶人离弃你的律法，就怒气发作，犹如火烧。"},
{volumn:        19,chapter:       119,versenumber:        54,verse:"我在世寄居，素来以你的律例为诗歌。"},
{volumn:        19,chapter:       119,versenumber:        55,verse:"耶和华啊，我夜间记念你的名，遵守你的律法。"},
{volumn:        19,chapter:       119,versenumber:        56,verse:"我所以如此，是因我守你的训词。"},
{volumn:        19,chapter:       119,versenumber:        57,verse:"耶和华是我的福分；我曾说，我要遵守你的言语。"},
{volumn:        19,chapter:       119,versenumber:        58,verse:"我一心求过你的恩；愿你照你的话怜悯我！"},
{volumn:        19,chapter:       119,versenumber:        59,verse:"我思想我所行的道，就转步归向你的法度。"},
{volumn:        19,chapter:       119,versenumber:        60,verse:"我急忙遵守你的命令，并不迟延。"},
{volumn:        19,chapter:       119,versenumber:        61,verse:"恶人的绳索缠绕我，我却没有忘记你的律法。"},
{volumn:        19,chapter:       119,versenumber:        62,verse:"我因你公义的典章，半夜必起来称谢你。"},
{volumn:        19,chapter:       119,versenumber:        63,verse:"凡敬畏你、守你训词的人，我都与他作伴。"},
{volumn:        19,chapter:       119,versenumber:        64,verse:"耶和华啊，你的慈爱遍满大地；求你将你的律例教训我！"},
{volumn:        19,chapter:       119,versenumber:        65,verse:"耶和华啊，你向来是照你的话善待仆人。"},
{volumn:        19,chapter:       119,versenumber:        66,verse:"求你将精明和知识赐给我，因我信了你的命令。"},
{volumn:        19,chapter:       119,versenumber:        67,verse:"我未受苦以先走迷了路，现在却遵守你的话。"},
{volumn:        19,chapter:       119,versenumber:        68,verse:"你本为善，所行的也善；求你将你的律例教训我！"},
{volumn:        19,chapter:       119,versenumber:        69,verse:"骄傲人编造谎言攻击我，我却要一心守你的训词。"},
{volumn:        19,chapter:       119,versenumber:        70,verse:"他们心蒙脂油，我却喜爱你的律法。"},
{volumn:        19,chapter:       119,versenumber:        71,verse:"我受苦是与我有益，为要使我学习你的律例。"},
{volumn:        19,chapter:       119,versenumber:        72,verse:"你口中的训言（或译：律法）与我有益，胜于千万的金银。"},
{volumn:        19,chapter:       119,versenumber:        73,verse:"你的手制造我，建立我；求你赐我悟性，可以学习你的命令！"},
{volumn:        19,chapter:       119,versenumber:        74,verse:"敬畏你的人见我就要欢喜，因我仰望你的话。"},
{volumn:        19,chapter:       119,versenumber:        75,verse:"耶和华啊，我知道你的判语是公义的；你使我受苦是以诚实待我。"},
{volumn:        19,chapter:       119,versenumber:        76,verse:"求你照着应许仆人的话，以慈爱安慰我。"},
{volumn:        19,chapter:       119,versenumber:        77,verse:"愿你的慈悲临到我，使我存活，因你的律法是我所喜爱的。"},
{volumn:        19,chapter:       119,versenumber:        78,verse:"愿骄傲人蒙羞，因为他们无理地倾覆我；但我要思想你的训词。"},
{volumn:        19,chapter:       119,versenumber:        79,verse:"愿敬畏你的人归向我，他们就知道你的法度。"},
{volumn:        19,chapter:       119,versenumber:        80,verse:"愿我的心在你的律例上完全，使我不致蒙羞。"},
{volumn:        19,chapter:       119,versenumber:        81,verse:"我心渴想你的救恩，仰望你的应许。"},
{volumn:        19,chapter:       119,versenumber:        82,verse:"我因盼望你的应许眼睛失明，说：“你何时安慰我？”"},
{volumn:        19,chapter:       119,versenumber:        83,verse:"我好像烟薰的皮袋，却不忘记你的律例。"},
{volumn:        19,chapter:       119,versenumber:        84,verse:"你仆人的年日有多少呢？你几时向逼迫我的人施行审判呢？"},
{volumn:        19,chapter:       119,versenumber:        85,verse:"不从你律法的骄傲人为我掘了坑。"},
{volumn:        19,chapter:       119,versenumber:        86,verse:"你的命令尽都诚实；他们无理地逼迫我，求你帮助我！"},
{volumn:        19,chapter:       119,versenumber:        87,verse:"他们几乎把我从世上灭绝，但我没有离弃你的训词。"},
{volumn:        19,chapter:       119,versenumber:        88,verse:"求你照你的慈爱将我救活，我就遵守你口中的法度。"},
{volumn:        19,chapter:       119,versenumber:        89,verse:"耶和华啊，你的话安定在天，直到永远。"},
{volumn:        19,chapter:       119,versenumber:        90,verse:"你的诚实存到万代；你坚定了地，地就长存。"},
{volumn:        19,chapter:       119,versenumber:        91,verse:"天地照你的安排存到今日；万物都是你的仆役。"},
{volumn:        19,chapter:       119,versenumber:        92,verse:"我若不是喜爱你的律法，早就在苦难中灭绝了！"},
{volumn:        19,chapter:       119,versenumber:        93,verse:"我永不忘记你的训词，因你用这训词将我救活了。"},
{volumn:        19,chapter:       119,versenumber:        94,verse:"我是属你的，求你救我，因我寻求了你的训词。"},
{volumn:        19,chapter:       119,versenumber:        95,verse:"恶人等待我，要灭绝我，我却要揣摩你的法度。"},
{volumn:        19,chapter:       119,versenumber:        96,verse:"我看万事尽都有限，惟有你的命令极其宽广。"},
{volumn:        19,chapter:       119,versenumber:        97,verse:"我何等爱慕你的律法，终日不住地思想。"},
{volumn:        19,chapter:       119,versenumber:        98,verse:"你的命令常存在我心里，使我比仇敌有智慧。"},
{volumn:        19,chapter:       119,versenumber:        99,verse:"我比我的师傅更通达，因我思想你的法度。"},
{volumn:        19,chapter:       119,versenumber:       100,verse:"我比年老的更明白，因我守了你的训词。"},
{volumn:        19,chapter:       119,versenumber:       101,verse:"我禁止我脚走一切的邪路，为要遵守你的话。"},
{volumn:        19,chapter:       119,versenumber:       102,verse:"我没有偏离你的典章，因为你教训了我。"},
{volumn:        19,chapter:       119,versenumber:       103,verse:"你的言语在我上膛何等甘美，在我口中比蜜更甜！"},
{volumn:        19,chapter:       119,versenumber:       104,verse:"我藉着你的训词得以明白，所以我恨一切的假道。"},
{volumn:        19,chapter:       119,versenumber:       105,verse:"你的话是我脚前的灯，是我路上的光。"},
{volumn:        19,chapter:       119,versenumber:       106,verse:"你公义的典章，我曾起誓遵守，我必按誓而行。"},
{volumn:        19,chapter:       119,versenumber:       107,verse:"我甚是受苦；耶和华啊，求你照你的话将我救活！"},
{volumn:        19,chapter:       119,versenumber:       108,verse:"耶和华啊，求你悦纳我口中的赞美为供物，又将你的典章教训我！"},
{volumn:        19,chapter:       119,versenumber:       109,verse:"我的性命常在危险之中，我却不忘记你的律法。"},
{volumn:        19,chapter:       119,versenumber:       110,verse:"恶人为我设下网罗，我却没有偏离你的训词。"},
{volumn:        19,chapter:       119,versenumber:       111,verse:"我以你的法度为永远的产业，因这是我心中所喜爱的。"},
{volumn:        19,chapter:       119,versenumber:       112,verse:"我的心专向你的律例，永远遵行，一直到底。"},
{volumn:        19,chapter:       119,versenumber:       113,verse:"心怀二意的人为我所恨；但你的律法为我所爱。"},
{volumn:        19,chapter:       119,versenumber:       114,verse:"你是我藏身之处，又是我的盾牌；我甚仰望你的话语。"},
{volumn:        19,chapter:       119,versenumber:       115,verse:"作恶的人哪，你们离开我吧！我好遵守我　神的命令。"},
{volumn:        19,chapter:       119,versenumber:       116,verse:"求你照你的话扶持我，使我存活，也不叫我因失望而害羞。"},
{volumn:        19,chapter:       119,versenumber:       117,verse:"求你扶持我，我便得救，时常看重你的律例。"},
{volumn:        19,chapter:       119,versenumber:       118,verse:"凡偏离你律例的人，你都轻弃他们，因为他们的诡诈必归虚空。"},
{volumn:        19,chapter:       119,versenumber:       119,verse:"凡地上的恶人，你除掉他，好像除掉渣滓；因此我爱你的法度。"},
{volumn:        19,chapter:       119,versenumber:       120,verse:"我因惧怕你，肉就发抖；我也怕你的判语。"},
{volumn:        19,chapter:       119,versenumber:       121,verse:"我行过公平和公义，求你不要撇下我给欺压我的人！"},
{volumn:        19,chapter:       119,versenumber:       122,verse:"求你为仆人作保，使我得好处，不容骄傲人欺压我！"},
{volumn:        19,chapter:       119,versenumber:       123,verse:"我因盼望你的救恩和你公义的话眼睛失明。"},
{volumn:        19,chapter:       119,versenumber:       124,verse:"求你照你的慈爱待仆人，将你的律例教训我。"},
{volumn:        19,chapter:       119,versenumber:       125,verse:"我是你的仆人，求你赐我悟性，使我得知你的法度。"},
{volumn:        19,chapter:       119,versenumber:       126,verse:"这是耶和华降罚的时候，因人废了你的律法。"},
{volumn:        19,chapter:       119,versenumber:       127,verse:"所以，我爱你的命令胜于金子，更胜于精金。"},
{volumn:        19,chapter:       119,versenumber:       128,verse:"你一切的训词，在万事上我都以为正直；我却恨恶一切假道。"},
{volumn:        19,chapter:       119,versenumber:       129,verse:"你的法度奇妙，所以我一心谨守。"},
{volumn:        19,chapter:       119,versenumber:       130,verse:"你的言语一解开就发出亮光，使愚人通达。"},
{volumn:        19,chapter:       119,versenumber:       131,verse:"我张口而气喘，因我切慕你的命令。"},
{volumn:        19,chapter:       119,versenumber:       132,verse:"求你转向我，怜悯我，好像你素常待那些爱你名的人。"},
{volumn:        19,chapter:       119,versenumber:       133,verse:"求你用你的话使我脚步稳当，不许什么罪孽辖制我。"},
{volumn:        19,chapter:       119,versenumber:       134,verse:"求你救我脱离人的欺压，我要遵守你的训词。"},
{volumn:        19,chapter:       119,versenumber:       135,verse:"求你用脸光照仆人，又将你的律例教训我。"},
{volumn:        19,chapter:       119,versenumber:       136,verse:"我的眼泪下流成河，因为他们不守你的律法。"},
{volumn:        19,chapter:       119,versenumber:       137,verse:"耶和华啊，你是公义的；你的判语也是正直的！"},
{volumn:        19,chapter:       119,versenumber:       138,verse:"你所命定的法度是凭公义和至诚。"},
{volumn:        19,chapter:       119,versenumber:       139,verse:"我心焦急，如同火烧，因我敌人忘记你的言语。"},
{volumn:        19,chapter:       119,versenumber:       140,verse:"你的话极其精炼，所以你的仆人喜爱。"},
{volumn:        19,chapter:       119,versenumber:       141,verse:"我微小，被人藐视，却不忘记你的训词。"},
{volumn:        19,chapter:       119,versenumber:       142,verse:"你的公义永远长存；你的律法尽都真实。"},
{volumn:        19,chapter:       119,versenumber:       143,verse:"我遭遇患难愁苦，你的命令却是我所喜爱的。"},
{volumn:        19,chapter:       119,versenumber:       144,verse:"你的法度永远是公义的；求你赐我悟性，我就活了。"},
{volumn:        19,chapter:       119,versenumber:       145,verse:"耶和华啊，我一心呼吁你；求你应允我，我必谨守你的律例！"},
{volumn:        19,chapter:       119,versenumber:       146,verse:"我向你呼吁，求你救我！我要遵守你的法度。"},
{volumn:        19,chapter:       119,versenumber:       147,verse:"我趁天未亮呼求；我仰望了你的言语。"},
{volumn:        19,chapter:       119,versenumber:       148,verse:"我趁夜更未换将眼睁开，为要思想你的话语。"},
{volumn:        19,chapter:       119,versenumber:       149,verse:"求你照你的慈爱听我的声音；耶和华啊，求你照你的典章将我救活！"},
{volumn:        19,chapter:       119,versenumber:       150,verse:"追求奸恶的人临近了；他们远离你的律法。"},
{volumn:        19,chapter:       119,versenumber:       151,verse:"耶和华啊，你与我相近；你一切的命令尽都真实！"},
{volumn:        19,chapter:       119,versenumber:       152,verse:"我因学你的法度，久已知道是你永远立定的。"},
{volumn:        19,chapter:       119,versenumber:       153,verse:"求你看顾我的苦难，搭救我，因我不忘记你的律法。"},
{volumn:        19,chapter:       119,versenumber:       154,verse:"求你为我辨屈，救赎我，照你的话将我救活。"},
{volumn:        19,chapter:       119,versenumber:       155,verse:"救恩远离恶人，因为他们不寻求你的律例。"},
{volumn:        19,chapter:       119,versenumber:       156,verse:"耶和华啊，你的慈悲本为大；求你照你的典章将我救活。"},
{volumn:        19,chapter:       119,versenumber:       157,verse:"逼迫我的，抵挡我的，很多，我却没有偏离你的法度。"},
{volumn:        19,chapter:       119,versenumber:       158,verse:"我看见奸恶的人就甚憎恶，因为他们不遵守你的话。"},
{volumn:        19,chapter:       119,versenumber:       159,verse:"你看我怎样爱你的训词！耶和华啊，求你照你的慈爱将我救活！"},
{volumn:        19,chapter:       119,versenumber:       160,verse:"你话的总纲是真实；你一切公义的典章是永远长存。"},
{volumn:        19,chapter:       119,versenumber:       161,verse:"首领无故地逼迫我，但我的心畏惧你的言语。"},
{volumn:        19,chapter:       119,versenumber:       162,verse:"我喜爱你的话，好像人得了许多掳物。"},
{volumn:        19,chapter:       119,versenumber:       163,verse:"谎话是我所恨恶所憎嫌的；惟你的律法是我所爱的。"},
{volumn:        19,chapter:       119,versenumber:       164,verse:"我因你公义的典章一天七次赞美你。"},
{volumn:        19,chapter:       119,versenumber:       165,verse:"爱你律法的人有大平安，什么都不能使他们绊脚。"},
{volumn:        19,chapter:       119,versenumber:       166,verse:"耶和华啊，我仰望了你的救恩，遵行了你的命令。"},
{volumn:        19,chapter:       119,versenumber:       167,verse:"我心里守了你的法度；这法度我甚喜爱。"},
{volumn:        19,chapter:       119,versenumber:       168,verse:"我遵守了你的训词和法度，因我一切所行的都在你面前。"},
{volumn:        19,chapter:       119,versenumber:       169,verse:"耶和华啊，愿我的呼吁达到你面前，照你的话赐我悟性。"},
{volumn:        19,chapter:       119,versenumber:       170,verse:"愿我的恳求达到你面前，照你的话搭救我。"},
{volumn:        19,chapter:       119,versenumber:       171,verse:"愿我的嘴发出赞美的话，因为你将律例教训我。"},
{volumn:        19,chapter:       119,versenumber:       172,verse:"愿我的舌头歌唱你的话，因你一切的命令尽都公义。"},
{volumn:        19,chapter:       119,versenumber:       173,verse:"愿你用手帮助我，因我拣选了你的训词。"},
{volumn:        19,chapter:       119,versenumber:       174,verse:"耶和华啊，我切慕你的救恩！你的律法也是我所喜爱的。"},
{volumn:        19,chapter:       119,versenumber:       175,verse:"愿我的性命存活，得以赞美你！愿你的典章帮助我！"},
{volumn:        19,chapter:       119,versenumber:       176,verse:"我如亡羊走迷了路，求你寻找仆人，因我不忘记你的命令。"},
{volumn:        19,chapter:       120,versenumber:         1,verse:"我在急难中求告耶和华，他就应允我。"},
{volumn:        19,chapter:       120,versenumber:         2,verse:"耶和华啊，求你救我脱离说谎的嘴唇和诡诈的舌头！"},
{volumn:        19,chapter:       120,versenumber:         3,verse:"诡诈的舌头啊，要给你什么呢？要拿什么加给你呢？"},
{volumn:        19,chapter:       120,versenumber:         4,verse:"就是勇士的利箭和罗腾木（小树名，松类）的炭火。"},
{volumn:        19,chapter:       120,versenumber:         5,verse:"我寄居在米设，住在基达帐棚之中，有祸了！"},
{volumn:        19,chapter:       120,versenumber:         6,verse:"我与那恨恶和睦的人许久同住。"},
{volumn:        19,chapter:       120,versenumber:         7,verse:"我愿和睦，但我发言，他们就要争战。"},
{volumn:        19,chapter:       121,versenumber:         1,verse:"我要向山举目；我的帮助从何而来？"},
{volumn:        19,chapter:       121,versenumber:         2,verse:"我的帮助从造天地的耶和华而来。"},
{volumn:        19,chapter:       121,versenumber:         3,verse:"他必不叫你的脚摇动；保护你的必不打盹！"},
{volumn:        19,chapter:       121,versenumber:         4,verse:"保护以色列的，也不打盹也不睡觉。"},
{volumn:        19,chapter:       121,versenumber:         5,verse:"保护你的是耶和华；耶和华在你右边荫庇你。"},
{volumn:        19,chapter:       121,versenumber:         6,verse:"白日，太阳必不伤你；夜间，月亮必不害你。"},
{volumn:        19,chapter:       121,versenumber:         7,verse:"耶和华要保护你，免受一切的灾害；他要保护你的性命。"},
{volumn:        19,chapter:       121,versenumber:         8,verse:"你出你入，耶和华要保护你，从今时直到永远。"},
{volumn:        19,chapter:       122,versenumber:         1,verse:"人对我说：“我们往耶和华的殿去。”我就欢喜。"},
{volumn:        19,chapter:       122,versenumber:         2,verse:"耶路撒冷啊，我们的脚站在你的门内。"},
{volumn:        19,chapter:       122,versenumber:         3,verse:"耶路撒冷被建造，如同连络整齐的一座城。"},
{volumn:        19,chapter:       122,versenumber:         4,verse:"众支派，就是耶和华的支派，上那里去，按以色列的常例（或译：作以色列的证据）称赞耶和华的名。"},
{volumn:        19,chapter:       122,versenumber:         5,verse:"因为在那里设立审判的宝座，就是大卫家的宝座。"},
{volumn:        19,chapter:       122,versenumber:         6,verse:"你们要为耶路撒冷求平安！耶路撒冷啊，爱你的人必然兴旺！"},
{volumn:        19,chapter:       122,versenumber:         7,verse:"愿你城中平安！愿你宫内兴旺！"},
{volumn:        19,chapter:       122,versenumber:         8,verse:"因我弟兄和同伴的缘故，我要说：“愿平安在你中间！”"},
{volumn:        19,chapter:       122,versenumber:         9,verse:"因耶和华我们　神殿的缘故，我要为你求福！"},
{volumn:        19,chapter:       123,versenumber:         1,verse:"坐在天上的主啊，我向你举目。"},
{volumn:        19,chapter:       123,versenumber:         2,verse:"看哪，仆人的眼睛怎样望主人的手，使女的眼睛怎样望主母的手，我们的眼睛也照样望耶和华我们的　神，直到他怜悯我们。"},
{volumn:        19,chapter:       123,versenumber:         3,verse:"耶和华啊，求你怜悯我们，怜悯我们！因为我们被藐视，已到极处。"},
{volumn:        19,chapter:       123,versenumber:         4,verse:"我们被那些安逸人的讥诮和骄傲人的藐视，已到极处。"},
{volumn:        19,chapter:       124,versenumber:         1,verse:"以色列人要说：若不是耶和华帮助我们，"},
{volumn:        19,chapter:       124,versenumber:         2,verse:"若不是耶和华帮助我们，当人起来攻击我们、"},
{volumn:        19,chapter:       124,versenumber:         3,verse:"向我们发怒的时候，就把我们活活地吞了。"},
{volumn:        19,chapter:       124,versenumber:         4,verse:"那时，波涛必漫过我们，河水必淹没我们，"},
{volumn:        19,chapter:       124,versenumber:         5,verse:"狂傲的水必淹没我们。"},
{volumn:        19,chapter:       124,versenumber:         6,verse:"耶和华是应当称颂的！他没有把我们当野食交给他们吞吃（原文是牙齿）。"},
{volumn:        19,chapter:       124,versenumber:         7,verse:"我们好像雀鸟，从捕鸟人的网罗里逃脱；网罗破裂，我们逃脱了。"},
{volumn:        19,chapter:       124,versenumber:         8,verse:"我们得帮助，是在乎倚靠造天地之耶和华的名。"},
{volumn:        19,chapter:       125,versenumber:         1,verse:"倚靠耶和华的人好像锡安山，永不动摇。"},
{volumn:        19,chapter:       125,versenumber:         2,verse:"众山怎样围绕耶路撒冷，耶和华也照样围绕他的百姓，从今时直到永远。"},
{volumn:        19,chapter:       125,versenumber:         3,verse:"恶人的杖不常落在义人的份上，免得义人伸手作恶。"},
{volumn:        19,chapter:       125,versenumber:         4,verse:"耶和华啊，求你善待那些为善和心里正直的人。"},
{volumn:        19,chapter:       125,versenumber:         5,verse:"至于那偏行弯曲道路的人，耶和华必使他和作恶的人一同出去受刑。愿平安归于以色列！"},
{volumn:        19,chapter:       126,versenumber:         1,verse:"当耶和华将那些被掳的带回锡安的时候，我们好像做梦的人。"},
{volumn:        19,chapter:       126,versenumber:         2,verse:"我们满口喜笑、满舌欢呼的时候，外邦中就有人说：“耶和华为他们行了大事！”"},
{volumn:        19,chapter:       126,versenumber:         3,verse:"耶和华果然为我们行了大事，我们就欢喜。"},
{volumn:        19,chapter:       126,versenumber:         4,verse:"耶和华啊，求你使我们被掳的人归回，好像南地的河水复流。"},
{volumn:        19,chapter:       126,versenumber:         5,verse:"流泪撒种的，必欢呼收割！"},
{volumn:        19,chapter:       126,versenumber:         6,verse:"那带种流泪出去的，必要欢欢乐乐地带禾捆回来！"},
{volumn:        19,chapter:       127,versenumber:         1,verse:"若不是耶和华建造房屋，建造的人就枉然劳力；若不是耶和华看守城池，看守的人就枉然警醒。"},
{volumn:        19,chapter:       127,versenumber:         2,verse:"你们清晨早起，夜晚安歇，吃劳碌得来的饭，本是枉然；惟有耶和华所亲爱的，必叫他安然睡觉。"},
{volumn:        19,chapter:       127,versenumber:         3,verse:"儿女是耶和华所赐的产业；所怀的胎是他所给的赏赐。"},
{volumn:        19,chapter:       127,versenumber:         4,verse:"少年时所生的儿女好像勇士手中的箭。"},
{volumn:        19,chapter:       127,versenumber:         5,verse:"箭袋充满的人便为有福；他们在城门口和仇敌说话的时候，必不至于羞愧。"},
{volumn:        19,chapter:       128,versenumber:         1,verse:"凡敬畏耶和华、遵行他道的人便为有福！"},
{volumn:        19,chapter:       128,versenumber:         2,verse:"你要吃劳碌得来的；你要享福，事情顺利。"},
{volumn:        19,chapter:       128,versenumber:         3,verse:"你妻子在你的内室，好像多结果子的葡萄树；你儿女围绕你的桌子，好像橄榄栽子。"},
{volumn:        19,chapter:       128,versenumber:         4,verse:"看哪，敬畏耶和华的人必要这样蒙福！"},
{volumn:        19,chapter:       128,versenumber:         5,verse:"愿耶和华从锡安赐福给你！愿你一生一世看见耶路撒冷的好处！"},
{volumn:        19,chapter:       128,versenumber:         6,verse:"愿你看见你儿女的儿女！愿平安归于以色列！"},
{volumn:        19,chapter:       129,versenumber:         1,verse:"以色列当说：“从我幼年以来，敌人屡次苦害我，"},
{volumn:        19,chapter:       129,versenumber:         2,verse:"从我幼年以来，敌人屡次苦害我，却没有胜了我。"},
{volumn:        19,chapter:       129,versenumber:         3,verse:"如同扶犁的在我背上扶犁而耕，耕的犁沟甚长。”"},
{volumn:        19,chapter:       129,versenumber:         4,verse:"耶和华是公义的；他砍断了恶人的绳索。"},
{volumn:        19,chapter:       129,versenumber:         5,verse:"愿恨恶锡安的都蒙羞退后！"},
{volumn:        19,chapter:       129,versenumber:         6,verse:"愿他们像房顶上的草，未长成而枯干，"},
{volumn:        19,chapter:       129,versenumber:         7,verse:"收割的不够一把，捆禾的也不满怀。"},
{volumn:        19,chapter:       129,versenumber:         8,verse:"过路的也不说：“愿耶和华所赐的福归与你们！我们奉耶和华的名给你们祝福！”"},
{volumn:        19,chapter:       130,versenumber:         1,verse:"耶和华啊，我从深处向你求告！"},
{volumn:        19,chapter:       130,versenumber:         2,verse:"主啊，求你听我的声音！愿你侧耳听我恳求的声音！"},
{volumn:        19,chapter:       130,versenumber:         3,verse:"主耶和华啊，你若究察罪孽，谁能站得住呢？"},
{volumn:        19,chapter:       130,versenumber:         4,verse:"但在你有赦免之恩，要叫人敬畏你。"},
{volumn:        19,chapter:       130,versenumber:         5,verse:"我等候耶和华，我的心等候；我也仰望他的话。"},
{volumn:        19,chapter:       130,versenumber:         6,verse:"我的心等候主，胜于守夜的，等候天亮，胜于守夜的，等候天亮。"},
{volumn:        19,chapter:       130,versenumber:         7,verse:"以色列啊，你当仰望耶和华！因他有慈爱，有丰盛的救恩。"},
{volumn:        19,chapter:       130,versenumber:         8,verse:"他必救赎以色列脱离一切的罪孽。"},
{volumn:        19,chapter:       131,versenumber:         1,verse:"耶和华啊，我的心不狂傲，我的眼不高大；重大和测不透的事，我也不敢行。"},
{volumn:        19,chapter:       131,versenumber:         2,verse:"我的心平稳安静，好像断过奶的孩子在他母亲的怀中；我的心在我里面真像断过奶的孩子。"},
{volumn:        19,chapter:       131,versenumber:         3,verse:"以色列啊，你当仰望耶和华，从今时直到永远！"},
{volumn:        19,chapter:       132,versenumber:         1,verse:"耶和华啊，求你记念大卫所受的一切苦难！"},
{volumn:        19,chapter:       132,versenumber:         2,verse:"他怎样向耶和华起誓，向雅各的大能者许愿，"},
{volumn:        19,chapter:       132,versenumber:         3,verse:"说：“我必不进我的帐幕，也不上我的床榻；"},
{volumn:        19,chapter:       132,versenumber:         4,verse:"我不容我的眼睛睡觉，也不容我的眼目打盹；"},
{volumn:        19,chapter:       132,versenumber:         5,verse:"直等我为耶和华寻得所在，为雅各的大能者寻得居所。”"},
{volumn:        19,chapter:       132,versenumber:         6,verse:"我们听说约柜在以法他，我们在基列·耶琳就寻见了。"},
{volumn:        19,chapter:       132,versenumber:         7,verse:"我们要进他的居所，在他脚凳前下拜。"},
{volumn:        19,chapter:       132,versenumber:         8,verse:"耶和华啊，求你兴起，和你有能力的约柜同入安息之所！"},
{volumn:        19,chapter:       132,versenumber:         9,verse:"愿你的祭司披上公义！愿你的圣民欢呼！"},
{volumn:        19,chapter:       132,versenumber:        10,verse:"求你因你仆人大卫的缘故，不要厌弃你的受膏者！"},
{volumn:        19,chapter:       132,versenumber:        11,verse:"耶和华向大卫、凭诚实起了誓，必不反复，说：“我要使你所生的坐在你的宝座上。"},
{volumn:        19,chapter:       132,versenumber:        12,verse:"你的众子若守我的约和我所教训他们的法度，他们的子孙必永远坐在你的宝座上。”"},
{volumn:        19,chapter:       132,versenumber:        13,verse:"因为耶和华拣选了锡安，愿意当作自己的居所，"},
{volumn:        19,chapter:       132,versenumber:        14,verse:"说：“这是我永远安息之所；我要住在这里，因为是我所愿意的。"},
{volumn:        19,chapter:       132,versenumber:        15,verse:"我要使其中的粮食丰满，使其中的穷人饱足。"},
{volumn:        19,chapter:       132,versenumber:        16,verse:"我要使祭司披上救恩，圣民大声欢呼！"},
{volumn:        19,chapter:       132,versenumber:        17,verse:"我要叫大卫的角在那里发生；我为我的受膏者预备明灯。"},
{volumn:        19,chapter:       132,versenumber:        18,verse:"我要使他的仇敌披上羞耻；但他的冠冕要在头上发光。”"},
{volumn:        19,chapter:       133,versenumber:         1,verse:"看哪，弟兄和睦同居是何等地善，何等地美！"},
{volumn:        19,chapter:       133,versenumber:         2,verse:"这好比那贵重的油浇在亚伦的头上，流到胡须，又流到他的衣襟；"},
{volumn:        19,chapter:       133,versenumber:         3,verse:"又好比黑门的甘露降在锡安山；因为在那里有耶和华所命定的福，就是永远的生命。"},
{volumn:        19,chapter:       134,versenumber:         1,verse:"耶和华的仆人夜间站在耶和华殿中的，你们当称颂耶和华！"},
{volumn:        19,chapter:       134,versenumber:         2,verse:"你们当向圣所举手，称颂耶和华！"},
{volumn:        19,chapter:       134,versenumber:         3,verse:"愿造天地的耶和华，从锡安赐福给你们！"},
{volumn:        19,chapter:       135,versenumber:         1,verse:"你们要赞美耶和华！你们要赞美耶和华的名！耶和华的仆人站在耶和华殿中；站在我们　神殿院中的，你们要赞美他！"},
{volumn:        19,chapter:       135,versenumber:         2,verse:""},
{volumn:        19,chapter:       135,versenumber:         3,verse:"你们要赞美耶和华！耶和华本为善；要歌颂他的名，因为这是美好的。"},
{volumn:        19,chapter:       135,versenumber:         4,verse:"耶和华拣选雅各归自己，拣选以色列特作自己的子民。"},
{volumn:        19,chapter:       135,versenumber:         5,verse:"原来我知道耶和华为大，也知道我们的主超乎万神之上。"},
{volumn:        19,chapter:       135,versenumber:         6,verse:"耶和华在天上，在地下，在海中，在一切的深处，都随自己的意旨而行。"},
{volumn:        19,chapter:       135,versenumber:         7,verse:"他使云雾从地极上腾，造电随雨而闪，从府库中带出风来。"},
{volumn:        19,chapter:       135,versenumber:         8,verse:"他将埃及头生的，连人带牲畜都击杀了。"},
{volumn:        19,chapter:       135,versenumber:         9,verse:"埃及啊，他施行神迹奇事，在你当中，在法老和他一切臣仆身上。"},
{volumn:        19,chapter:       135,versenumber:        10,verse:"他击杀许多的民，又杀戮大能的王，"},
{volumn:        19,chapter:       135,versenumber:        11,verse:"就是亚摩利王西宏和巴珊王噩，并迦南一切的国王，"},
{volumn:        19,chapter:       135,versenumber:        12,verse:"将他们的地赏赐他的百姓以色列为业。"},
{volumn:        19,chapter:       135,versenumber:        13,verse:"耶和华啊，你的名存到永远！耶和华啊，你可记念的名存到万代！"},
{volumn:        19,chapter:       135,versenumber:        14,verse:"耶和华要为他的百姓伸冤，为他的仆人后悔。"},
{volumn:        19,chapter:       135,versenumber:        15,verse:"外邦的偶像是金的，银的，是人手所造的；"},
{volumn:        19,chapter:       135,versenumber:        16,verse:"有口却不能言，有眼却不能看，"},
{volumn:        19,chapter:       135,versenumber:        17,verse:"有耳却不能听，口中也没有气息。"},
{volumn:        19,chapter:       135,versenumber:        18,verse:"造他的要和他一样，凡靠他的也要如此。"},
{volumn:        19,chapter:       135,versenumber:        19,verse:"以色列家啊，你们要称颂耶和华！亚伦家啊，你们要称颂耶和华！"},
{volumn:        19,chapter:       135,versenumber:        20,verse:"利未家啊，你们要称颂耶和华！你们敬畏耶和华的，要称颂耶和华！"},
{volumn:        19,chapter:       135,versenumber:        21,verse:"住在耶路撒冷的耶和华该从锡安受称颂。你们要赞美耶和华！"},
{volumn:        19,chapter:       136,versenumber:         1,verse:"你们要称谢耶和华，因他本为善；他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         2,verse:"你们要称谢万神之神，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         3,verse:"你们要称谢万主之主，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         4,verse:"称谢那独行大奇事的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         5,verse:"称谢那用智慧造天的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         6,verse:"称谢那铺地在水以上的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         7,verse:"称谢那造成大光的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         8,verse:"他造日头管白昼，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:         9,verse:"他造月亮星宿管黑夜，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        10,verse:"称谢那击杀埃及人之长子的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        11,verse:"他领以色列人从他们中间出来，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        12,verse:"他施展大能的手和伸出来的膀臂，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        13,verse:"称谢那分裂红海的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        14,verse:"他领以色列从其中经过，因他的慈爱永远长存；"},
{volumn:        19,chapter:       136,versenumber:        15,verse:"却把法老和他的军兵推翻在红海里，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        16,verse:"称谢那引导自己的民行走旷野的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        17,verse:"称谢那击杀大君王的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        18,verse:"他杀戮有名的君王，因他的慈爱永远长存；"},
{volumn:        19,chapter:       136,versenumber:        19,verse:"就是杀戮亚摩利王西宏，因他的慈爱永远长存；"},
{volumn:        19,chapter:       136,versenumber:        20,verse:"又杀巴珊王噩，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        21,verse:"他将他们的地赐他的百姓为业，因他的慈爱永远长存；"},
{volumn:        19,chapter:       136,versenumber:        22,verse:"就是赐他的仆人以色列为业，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        23,verse:"他顾念我们在卑微的地步，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        24,verse:"他救拔我们脱离敌人，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        25,verse:"他赐粮食给凡有血气的，因他的慈爱永远长存。"},
{volumn:        19,chapter:       136,versenumber:        26,verse:"你们要称谢天上的　神，因他的慈爱永远长存。"},
{volumn:        19,chapter:       137,versenumber:         1,verse:"我们曾在巴比伦的河边坐下，一追想锡安就哭了。"},
{volumn:        19,chapter:       137,versenumber:         2,verse:"我们把琴挂在那里的柳树上；"},
{volumn:        19,chapter:       137,versenumber:         3,verse:"因为在那里，掳掠我们的要我们唱歌，抢夺我们的要我们作乐，说：“给我们唱一首锡安歌吧！”"},
{volumn:        19,chapter:       137,versenumber:         4,verse:"我们怎能在外邦唱耶和华的歌呢？"},
{volumn:        19,chapter:       137,versenumber:         5,verse:"耶路撒冷啊，我若忘记你，情愿我的右手忘记技巧！"},
{volumn:        19,chapter:       137,versenumber:         6,verse:"我若不记念你，若不看耶路撒冷过于我所最喜乐的，情愿我的舌头贴于上膛！"},
{volumn:        19,chapter:       137,versenumber:         7,verse:"耶路撒冷遭难的日子，以东人说：“拆毁！拆毁，直拆到根基！”耶和华啊，求你记念这仇！"},
{volumn:        19,chapter:       137,versenumber:         8,verse:"将要被灭的巴比伦城啊（城：原文是女子），报复你像你待我们的，那人便为有福！"},
{volumn:        19,chapter:       137,versenumber:         9,verse:"拿你的婴孩摔在磐石上的，那人便为有福！"},
{volumn:        19,chapter:       138,versenumber:         1,verse:"我要一心称谢你，在诸神面前歌颂你。"},
{volumn:        19,chapter:       138,versenumber:         2,verse:"我要向你的圣殿下拜，为你的慈爱和诚实称赞你的名；因你使你的话显为大，过于你所应许的（或译：超乎你的名声）。"},
{volumn:        19,chapter:       138,versenumber:         3,verse:"我呼求的日子，你就应允我，鼓励我，使我心里有能力。"},
{volumn:        19,chapter:       138,versenumber:         4,verse:"耶和华啊，地上的君王都要称谢你，因他们听见了你口中的言语。"},
{volumn:        19,chapter:       138,versenumber:         5,verse:"他们要歌颂耶和华的作为，因耶和华大有荣耀。"},
{volumn:        19,chapter:       138,versenumber:         6,verse:"耶和华虽高，仍看顾低微的人；他却从远处看出骄傲的人。"},
{volumn:        19,chapter:       138,versenumber:         7,verse:"我虽行在患难中，你必将我救活；我的仇敌发怒，你必伸手抵挡他们；你的右手也必救我。"},
{volumn:        19,chapter:       138,versenumber:         8,verse:"耶和华必成全关乎我的事；耶和华啊，你的慈爱永远长存！求你不要离弃你手所造的。"},
{volumn:        19,chapter:       139,versenumber:         1,verse:"耶和华啊，你已经鉴察我，认识我。"},
{volumn:        19,chapter:       139,versenumber:         2,verse:"我坐下，我起来，你都晓得；你从远处知道我的意念。"},
{volumn:        19,chapter:       139,versenumber:         3,verse:"我行路，我躺卧，你都细察；你也深知我一切所行的。"},
{volumn:        19,chapter:       139,versenumber:         4,verse:"耶和华啊，我舌头上的话，你没有一句不知道的。"},
{volumn:        19,chapter:       139,versenumber:         5,verse:"你在我前后环绕我，按手在我身上。"},
{volumn:        19,chapter:       139,versenumber:         6,verse:"这样的知识奇妙，是我不能测的，至高，是我不能及的。"},
{volumn:        19,chapter:       139,versenumber:         7,verse:"我往哪里去躲避你的灵？我往哪里逃、躲避你的面？"},
{volumn:        19,chapter:       139,versenumber:         8,verse:"我若升到天上，你在那里；我若在阴间下榻，你也在那里。"},
{volumn:        19,chapter:       139,versenumber:         9,verse:"我若展开清晨的翅膀，飞到海极居住，"},
{volumn:        19,chapter:       139,versenumber:        10,verse:"就是在那里，你的手必引导我；你的右手也必扶持我。"},
{volumn:        19,chapter:       139,versenumber:        11,verse:"我若说：“黑暗必定遮蔽我，我周围的亮光必成为黑夜”，"},
{volumn:        19,chapter:       139,versenumber:        12,verse:"黑暗也不能遮蔽我，使你不见，黑夜却如白昼发亮。黑暗和光明，在你看都是一样。"},
{volumn:        19,chapter:       139,versenumber:        13,verse:"我的肺腑是你所造的；我在母腹中，你已覆庇我。"},
{volumn:        19,chapter:       139,versenumber:        14,verse:"我要称谢你，因我受造，奇妙可畏；你的作为奇妙，这是我心深知道的。"},
{volumn:        19,chapter:       139,versenumber:        15,verse:"我在暗中受造，在地的深处被联络；那时，我的形体并不向你隐藏。"},
{volumn:        19,chapter:       139,versenumber:        16,verse:"我未成形的体质，你的眼早已看见了；你所定的日子，我尚未度一日（或译：我被造的肢体尚未有其一），你都写在你的册上了。"},
{volumn:        19,chapter:       139,versenumber:        17,verse:"神啊，你的意念向我何等宝贵！其数何等众多！"},
{volumn:        19,chapter:       139,versenumber:        18,verse:"我若数点，比海沙更多；我睡醒的时候，仍和你同在。"},
{volumn:        19,chapter:       139,versenumber:        19,verse:"神啊，你必要杀戮恶人；所以，你们好流人血的，离开我去吧！"},
{volumn:        19,chapter:       139,versenumber:        20,verse:"因为他们说恶言顶撞你；你的仇敌也妄称你的名。"},
{volumn:        19,chapter:       139,versenumber:        21,verse:"耶和华啊，恨恶你的，我岂不恨恶他们吗？攻击你的，我岂不憎嫌他们吗？"},
{volumn:        19,chapter:       139,versenumber:        22,verse:"我切切地恨恶他们，以他们为仇敌。"},
{volumn:        19,chapter:       139,versenumber:        23,verse:"神啊，求你鉴察我，知道我的心思，试炼我，知道我的意念，"},
{volumn:        19,chapter:       139,versenumber:        24,verse:"看在我里面有什么恶行没有，引导我走永生的道路。"},
{volumn:        19,chapter:       140,versenumber:         1,verse:"耶和华啊，求你拯救我脱离凶恶的人，保护我脱离强暴的人！"},
{volumn:        19,chapter:       140,versenumber:         2,verse:"他们心中图谋奸恶，常常聚集要争战。"},
{volumn:        19,chapter:       140,versenumber:         3,verse:"他们使舌头尖利如蛇，嘴里有虺蛇的毒气。（细拉）"},
{volumn:        19,chapter:       140,versenumber:         4,verse:"耶和华啊，求你拯救我脱离恶人的手，保护我脱离强暴的人！他们图谋推我跌倒。"},
{volumn:        19,chapter:       140,versenumber:         5,verse:"骄傲人为我暗设网罗和绳索；他们在路旁铺下网，设下圈套。（细拉）"},
{volumn:        19,chapter:       140,versenumber:         6,verse:"我曾对耶和华说：你是我的　神。耶和华啊，求你留心听我恳求的声音！"},
{volumn:        19,chapter:       140,versenumber:         7,verse:"主耶和华、我救恩的力量啊，在争战的日子，你遮蔽了我的头。"},
{volumn:        19,chapter:       140,versenumber:         8,verse:"耶和华啊，求你不要遂恶人的心愿；不要成就他们的计谋，恐怕他们自高。（细拉）"},
{volumn:        19,chapter:       140,versenumber:         9,verse:"至于那些昂首围困我的人，愿他们嘴唇的奸恶陷害（原文是遮蔽）自己！"},
{volumn:        19,chapter:       140,versenumber:        10,verse:"愿火炭落在他们身上！愿他们被丢在火中，抛在深坑里，不能再起来。"},
{volumn:        19,chapter:       140,versenumber:        11,verse:"说恶言的人在地上必坚立不住；祸患必猎取强暴的人，将他打倒。"},
{volumn:        19,chapter:       140,versenumber:        12,verse:"我知道耶和华必为困苦人伸冤，必为穷乏人辨屈。"},
{volumn:        19,chapter:       140,versenumber:        13,verse:"义人必要称赞你的名；正直人必住在你面前。"},
{volumn:        19,chapter:       141,versenumber:         1,verse:"耶和华啊，我曾求告你，求你快快临到我这里！我求告你的时候，愿你留心听我的声音！"},
{volumn:        19,chapter:       141,versenumber:         2,verse:"愿我的祷告如香陈列在你面前！愿我举手祈求，如献晚祭！"},
{volumn:        19,chapter:       141,versenumber:         3,verse:"耶和华啊，求你禁止我的口，把守我的嘴！"},
{volumn:        19,chapter:       141,versenumber:         4,verse:"求你不叫我的心偏向邪恶，以致我和作孽的人同行恶事；也不叫我吃他们的美食。"},
{volumn:        19,chapter:       141,versenumber:         5,verse:"任凭义人击打我，这算为仁慈；任凭他责备我，这算为头上的膏油；我的头不要躲闪。正在他们行恶的时候，我仍要祈祷。"},
{volumn:        19,chapter:       141,versenumber:         6,verse:"他们的审判官被扔在岩下；众人要听我的话，因为这话甘甜。"},
{volumn:        19,chapter:       141,versenumber:         7,verse:"我们的骨头散在墓旁，好像人耕田、刨地的土块。"},
{volumn:        19,chapter:       141,versenumber:         8,verse:"主耶和华啊，我的眼目仰望你；我投靠你，求你不要将我撇得孤苦！"},
{volumn:        19,chapter:       141,versenumber:         9,verse:"求你保护我脱离恶人为我设的网罗和作孽之人的圈套！"},
{volumn:        19,chapter:       141,versenumber:        10,verse:"愿恶人落在自己的网里，我却得以逃脱。"},
{volumn:        19,chapter:       142,versenumber:         1,verse:"我发声哀告耶和华，发声恳求耶和华。"},
{volumn:        19,chapter:       142,versenumber:         2,verse:"我在他面前吐露我的苦情，陈说我的患难。"},
{volumn:        19,chapter:       142,versenumber:         3,verse:"我的灵在我里面发昏的时候，你知道我的道路。在我行的路上，敌人为我暗设网罗。"},
{volumn:        19,chapter:       142,versenumber:         4,verse:"求你向我右边观看，因为没有人认识我；我无处避难，也没有人眷顾我。"},
{volumn:        19,chapter:       142,versenumber:         5,verse:"耶和华啊，我曾向你哀求。我说：“你是我的避难所；在活人之地，你是我的福分。”"},
{volumn:        19,chapter:       142,versenumber:         6,verse:"求你侧耳听我的呼求，因我落到极卑之地；求你救我脱离逼迫我的人，因为他们比我强盛。"},
{volumn:        19,chapter:       142,versenumber:         7,verse:"求你领我出离被囚之地，我好称赞你的名。义人必环绕我，因为你是用厚恩待我。"},
{volumn:        19,chapter:       143,versenumber:         1,verse:"耶和华啊，求你听我的祷告，留心听我的恳求，凭你的信实和公义应允我。"},
{volumn:        19,chapter:       143,versenumber:         2,verse:"求你不要审问仆人；因为在你面前，凡活着的人没有一个是义的。"},
{volumn:        19,chapter:       143,versenumber:         3,verse:"原来仇敌逼迫我，将我打倒在地，使我住在幽暗之处，像死了许久的人一样。"},
{volumn:        19,chapter:       143,versenumber:         4,verse:"所以，我的灵在我里面发昏；我的心在我里面凄惨。"},
{volumn:        19,chapter:       143,versenumber:         5,verse:"我追想古时之日，思想你的一切作为，默念你手的工作。"},
{volumn:        19,chapter:       143,versenumber:         6,verse:"我向你举手；我的心渴想你，如干旱之地盼雨一样。（细拉）"},
{volumn:        19,chapter:       143,versenumber:         7,verse:"耶和华啊，求你速速应允我！我心神耗尽！不要向我掩面，免得我像那些下坑的人一样。"},
{volumn:        19,chapter:       143,versenumber:         8,verse:"求你使我清晨得听你慈爱之言，因我倚靠你；求你使我知道当行的路，因我的心仰望你。"},
{volumn:        19,chapter:       143,versenumber:         9,verse:"耶和华啊，求你救我脱离我的仇敌！我往你那里藏身。"},
{volumn:        19,chapter:       143,versenumber:        10,verse:"求你指教我遵行你的旨意，因你是我的　神。你的灵本为善；求你引我到平坦之地。"},
{volumn:        19,chapter:       143,versenumber:        11,verse:"耶和华啊，求你为你的名将我救活，凭你的公义，将我从患难中领出来，"},
{volumn:        19,chapter:       143,versenumber:        12,verse:"凭你的慈爱剪除我的仇敌，灭绝一切苦待我的人，因我是你的仆人。"},
{volumn:        19,chapter:       144,versenumber:         1,verse:"耶和华我的磐石是应当称颂的！他教导我的手争战，教导我的指头打仗。"},
{volumn:        19,chapter:       144,versenumber:         2,verse:"他是我慈爱的主，我的山寨，我的高台，我的救主，我的盾牌，是我所投靠的；他使我的百姓服在我以下。"},
{volumn:        19,chapter:       144,versenumber:         3,verse:"耶和华啊，人算什么，你竟认识他！世人算什么，你竟顾念他！"},
{volumn:        19,chapter:       144,versenumber:         4,verse:"人好像一口气；他的年日如同影儿快快过去。"},
{volumn:        19,chapter:       144,versenumber:         5,verse:"耶和华啊，求你使天下垂，亲自降临，摸山，山就冒烟。"},
{volumn:        19,chapter:       144,versenumber:         6,verse:"求你发出闪电，使他们四散，射出你的箭，使他们扰乱。"},
{volumn:        19,chapter:       144,versenumber:         7,verse:"求你从上伸手救拔我，救我出离大水，救我脱离外邦人的手。"},
{volumn:        19,chapter:       144,versenumber:         8,verse:"他们的口说谎话；他们的右手起假誓。"},
{volumn:        19,chapter:       144,versenumber:         9,verse:"神啊，我要向你唱新歌，用十弦瑟向你歌颂。"},
{volumn:        19,chapter:       144,versenumber:        10,verse:"你是那拯救君王的；你是那救仆人大卫脱离害命之刀的。"},
{volumn:        19,chapter:       144,versenumber:        11,verse:"求你救拔我，救我脱离外邦人的手。他们的口说谎话；他们的右手起假誓。"},
{volumn:        19,chapter:       144,versenumber:        12,verse:"我们的儿子从幼年好像树栽子长大；我们的女儿如同殿角石，是按建宫的样式凿成的。"},
{volumn:        19,chapter:       144,versenumber:        13,verse:"我们的仓盈满，能出各样的粮食；我们的羊在田间孳生千万。"},
{volumn:        19,chapter:       144,versenumber:        14,verse:"我们的牛驮着满驮，没有人闯进来抢夺，也没有人出去争战；我们的街市上也没有哭号的声音。"},
{volumn:        19,chapter:       144,versenumber:        15,verse:"遇见这光景的百姓便为有福！有耶和华为他们的　神，这百姓便为有福！"},
{volumn:        19,chapter:       145,versenumber:         1,verse:"我的　神我的王啊，我要尊崇你！我要永永远远称颂你的名！"},
{volumn:        19,chapter:       145,versenumber:         2,verse:"我要天天称颂你，也要永永远远赞美你的名！"},
{volumn:        19,chapter:       145,versenumber:         3,verse:"耶和华本为大，该受大赞美；其大无法测度。"},
{volumn:        19,chapter:       145,versenumber:         4,verse:"这代要对那代颂赞你的作为，也要传扬你的大能。"},
{volumn:        19,chapter:       145,versenumber:         5,verse:"我要默念你威严的尊荣和你奇妙的作为。"},
{volumn:        19,chapter:       145,versenumber:         6,verse:"人要传说你可畏之事的能力；我也要传扬你的大德。"},
{volumn:        19,chapter:       145,versenumber:         7,verse:"他们记念你的大恩就要传出来，并要歌唱你的公义。"},
{volumn:        19,chapter:       145,versenumber:         8,verse:"耶和华有恩惠，有怜悯，不轻易发怒，大有慈爱。"},
{volumn:        19,chapter:       145,versenumber:         9,verse:"耶和华善待万民；他的慈悲覆庇他一切所造的。"},
{volumn:        19,chapter:       145,versenumber:        10,verse:"耶和华啊，你一切所造的都要称谢你；你的圣民也要称颂你，"},
{volumn:        19,chapter:       145,versenumber:        11,verse:"传说你国的荣耀，谈论你的大能，"},
{volumn:        19,chapter:       145,versenumber:        12,verse:"好叫世人知道你大能的作为，并你国度威严的荣耀。"},
{volumn:        19,chapter:       145,versenumber:        13,verse:"你的国是永远的国！你执掌的权柄存到万代！"},
{volumn:        19,chapter:       145,versenumber:        14,verse:"凡跌倒的，耶和华将他们扶持；凡被压下的，将他们扶起。"},
{volumn:        19,chapter:       145,versenumber:        15,verse:"万民都举目仰望你；你随时给他们食物。"},
{volumn:        19,chapter:       145,versenumber:        16,verse:"你张手，使有生气的都随愿饱足。"},
{volumn:        19,chapter:       145,versenumber:        17,verse:"耶和华在他一切所行的，无不公义；在他一切所做的都有慈爱。"},
{volumn:        19,chapter:       145,versenumber:        18,verse:"凡求告耶和华的，就是诚心求告他的，耶和华便与他们相近。"},
{volumn:        19,chapter:       145,versenumber:        19,verse:"敬畏他的，他必成就他们的心愿，也必听他们的呼求，拯救他们。"},
{volumn:        19,chapter:       145,versenumber:        20,verse:"耶和华保护一切爱他的人，却要灭绝一切的恶人。"},
{volumn:        19,chapter:       145,versenumber:        21,verse:"我的口要说出赞美耶和华的话；惟愿凡有血气的都永永远远称颂他的圣名。"},
{volumn:        19,chapter:       146,versenumber:         1,verse:"你们要赞美耶和华！我的心哪，你要赞美耶和华！"},
{volumn:        19,chapter:       146,versenumber:         2,verse:"我一生要赞美耶和华！我还活的时候要歌颂我的　神！"},
{volumn:        19,chapter:       146,versenumber:         3,verse:"你们不要倚靠君王，不要倚靠世人；他一点不能帮助。"},
{volumn:        19,chapter:       146,versenumber:         4,verse:"他的气一断，就归回尘土；他所打算的，当日就消灭了。"},
{volumn:        19,chapter:       146,versenumber:         5,verse:"以雅各的　神为帮助、仰望耶和华他　神的，这人便为有福！"},
{volumn:        19,chapter:       146,versenumber:         6,verse:"耶和华造天、地、海，和其中的万物；他守诚实，直到永远。"},
{volumn:        19,chapter:       146,versenumber:         7,verse:"他为受屈的伸冤，赐食物与饥饿的。耶和华释放被囚的；"},
{volumn:        19,chapter:       146,versenumber:         8,verse:"耶和华开了瞎子的眼睛；耶和华扶起被压下的人。耶和华喜爱义人。"},
{volumn:        19,chapter:       146,versenumber:         9,verse:"耶和华保护寄居的，扶持孤儿和寡妇，却使恶人的道路弯曲。"},
{volumn:        19,chapter:       146,versenumber:        10,verse:"耶和华要作王，直到永远！锡安哪，你的　神要作王，直到万代！你们要赞美耶和华！"},
{volumn:        19,chapter:       147,versenumber:         1,verse:"你们要赞美耶和华！因歌颂我们的　神为善为美；赞美的话是合宜的。"},
{volumn:        19,chapter:       147,versenumber:         2,verse:"耶和华建造耶路撒冷，聚集以色列中被赶散的人。"},
{volumn:        19,chapter:       147,versenumber:         3,verse:"他医好伤心的人，裹好他们的伤处。"},
{volumn:        19,chapter:       147,versenumber:         4,verse:"他数点星宿的数目，一一称它的名。"},
{volumn:        19,chapter:       147,versenumber:         5,verse:"我们的主为大，最有能力；他的智慧无法测度。"},
{volumn:        19,chapter:       147,versenumber:         6,verse:"耶和华扶持谦卑人，将恶人倾覆于地。"},
{volumn:        19,chapter:       147,versenumber:         7,verse:"你们要以感谢向耶和华歌唱，用琴向我们的　神歌颂。"},
{volumn:        19,chapter:       147,versenumber:         8,verse:"他用云遮天，为地降雨，使草生长在山上。"},
{volumn:        19,chapter:       147,versenumber:         9,verse:"他赐食给走兽和啼叫的小乌鸦。"},
{volumn:        19,chapter:       147,versenumber:        10,verse:"他不喜悦马的力大，不喜爱人的腿快。"},
{volumn:        19,chapter:       147,versenumber:        11,verse:"耶和华喜爱敬畏他和盼望他慈爱的人。"},
{volumn:        19,chapter:       147,versenumber:        12,verse:"耶路撒冷啊，你要颂赞耶和华！锡安哪，你要赞美你的　神！"},
{volumn:        19,chapter:       147,versenumber:        13,verse:"因为他坚固了你的门闩，赐福给你中间的儿女。"},
{volumn:        19,chapter:       147,versenumber:        14,verse:"他使你境内平安，用上好的麦子使你满足。"},
{volumn:        19,chapter:       147,versenumber:        15,verse:"他发命在地；他的话颁行最快。"},
{volumn:        19,chapter:       147,versenumber:        16,verse:"他降雪如羊毛，撒霜如炉灰。"},
{volumn:        19,chapter:       147,versenumber:        17,verse:"他掷下冰雹如碎渣；他发出寒冷，谁能当得起呢？"},
{volumn:        19,chapter:       147,versenumber:        18,verse:"他一出令，这些就都消化；他使风刮起，水便流动。"},
{volumn:        19,chapter:       147,versenumber:        19,verse:"他将他的道指示雅各，将他的律例典章指示以色列。"},
{volumn:        19,chapter:       147,versenumber:        20,verse:"别国他都没有这样待过；至于他的典章，他们向来没有知道。你们要赞美耶和华！"},
{volumn:        19,chapter:       148,versenumber:         1,verse:"你们要赞美耶和华！从天上赞美耶和华，在高处赞美他！"},
{volumn:        19,chapter:       148,versenumber:         2,verse:"他的众使者都要赞美他！他的诸军都要赞美他！"},
{volumn:        19,chapter:       148,versenumber:         3,verse:"日头月亮，你们要赞美他！放光的星宿，你们都要赞美他！"},
{volumn:        19,chapter:       148,versenumber:         4,verse:"天上的天和天上的水，你们都要赞美他！"},
{volumn:        19,chapter:       148,versenumber:         5,verse:"愿这些都赞美耶和华的名！因他一吩咐便都造成。"},
{volumn:        19,chapter:       148,versenumber:         6,verse:"他将这些立定，直到永永远远；他定了命，不能废去（或译：越过）。"},
{volumn:        19,chapter:       148,versenumber:         7,verse:"所有在地上的，大鱼和一切深洋，"},
{volumn:        19,chapter:       148,versenumber:         8,verse:"火与冰雹，雪和雾气，成就他命的狂风，"},
{volumn:        19,chapter:       148,versenumber:         9,verse:"大山和小山，结果的树木和一切香柏树，"},
{volumn:        19,chapter:       148,versenumber:        10,verse:"野兽和一切牲畜，昆虫和飞鸟，"},
{volumn:        19,chapter:       148,versenumber:        11,verse:"世上的君王和万民，首领和世上一切审判官，"},
{volumn:        19,chapter:       148,versenumber:        12,verse:"少年人和处女，老年人和孩童，都当赞美耶和华！"},
{volumn:        19,chapter:       148,versenumber:        13,verse:"愿这些都赞美耶和华的名！因为独有他的名被尊崇；他的荣耀在天地之上。"},
{volumn:        19,chapter:       148,versenumber:        14,verse:"他将他百姓的角高举，因此他（或译：他使）一切圣民以色列人，就是与他相近的百姓，都赞美他！你们要赞美耶和华！"},
{volumn:        19,chapter:       149,versenumber:         1,verse:"你们要赞美耶和华！向耶和华唱新歌，在圣民的会中赞美他！"},
{volumn:        19,chapter:       149,versenumber:         2,verse:"愿以色列因造他的主欢喜！愿锡安的民因他们的王快乐！"},
{volumn:        19,chapter:       149,versenumber:         3,verse:"愿他们跳舞赞美他的名，击鼓弹琴歌颂他！"},
{volumn:        19,chapter:       149,versenumber:         4,verse:"因为耶和华喜爱他的百姓；他要用救恩当作谦卑人的妆饰。"},
{volumn:        19,chapter:       149,versenumber:         5,verse:"愿圣民因所得的荣耀高兴！愿他们在床上欢呼！"},
{volumn:        19,chapter:       149,versenumber:         6,verse:"愿他们口中称赞　神为高，手里有两刃的刀，"},
{volumn:        19,chapter:       149,versenumber:         7,verse:"为要报复列邦，刑罚万民。"},
{volumn:        19,chapter:       149,versenumber:         8,verse:"要用链子捆他们的君王，用铁镣锁他们的大臣；"},
{volumn:        19,chapter:       149,versenumber:         9,verse:"要在他们身上施行所记录的审判。他的圣民都有这荣耀。你们要赞美耶和华！"},
{volumn:        19,chapter:       150,versenumber:         1,verse:"你们要赞美耶和华！在　神的圣所赞美他！在他显能力的穹苍赞美他！"},
{volumn:        19,chapter:       150,versenumber:         2,verse:"要因他大能的作为赞美他，按着他极美的大德赞美他！"},
{volumn:        19,chapter:       150,versenumber:         3,verse:"要用角声赞美他，鼓瑟弹琴赞美他！"},
{volumn:        19,chapter:       150,versenumber:         4,verse:"击鼓跳舞赞美他！用丝弦的乐器和箫的声音赞美他！"},
{volumn:        19,chapter:       150,versenumber:         5,verse:"用大响的钹赞美他！用高声的钹赞美他！"},
{volumn:        19,chapter:       150,versenumber:         6,verse:"凡有气息的都要赞美耶和华！你们要赞美耶和华！"},
{volumn:        20,chapter:         1,versenumber:         1,verse:"以色列王大卫儿子所罗门的箴言："},
{volumn:        20,chapter:         1,versenumber:         2,verse:"要使人晓得智慧和训诲，分辨通达的言语，"},
{volumn:        20,chapter:         1,versenumber:         3,verse:"使人处事领受智慧、仁义、公平、正直的训诲，"},
{volumn:        20,chapter:         1,versenumber:         4,verse:"使愚人灵明，使少年人有知识和谋略，"},
{volumn:        20,chapter:         1,versenumber:         5,verse:"使智慧人听见，增长学问，使聪明人得着智谋，"},
{volumn:        20,chapter:         1,versenumber:         6,verse:"使人明白箴言和譬喻，懂得智慧人的言词和谜语。"},
{volumn:        20,chapter:         1,versenumber:         7,verse:"敬畏耶和华是知识的开端；愚妄人藐视智慧和训诲。"},
{volumn:        20,chapter:         1,versenumber:         8,verse:"我儿，要听你父亲的训诲，不可离弃你母亲的法则（或译：指教）；"},
{volumn:        20,chapter:         1,versenumber:         9,verse:"因为这要作你头上的华冠，你项上的金链。"},
{volumn:        20,chapter:         1,versenumber:        10,verse:"我儿，恶人若引诱你，你不可随从。"},
{volumn:        20,chapter:         1,versenumber:        11,verse:"他们若说：“你与我们同去，我们要埋伏流人之血，要蹲伏害无罪之人；"},
{volumn:        20,chapter:         1,versenumber:        12,verse:"我们好像阴间，把他们活活吞下；他们如同下坑的人，被我们囫囵吞了；"},
{volumn:        20,chapter:         1,versenumber:        13,verse:"我们必得各样宝物，将所掳来的，装满房屋；"},
{volumn:        20,chapter:         1,versenumber:        14,verse:"你与我们大家同分，我们共用一个囊袋。”"},
{volumn:        20,chapter:         1,versenumber:        15,verse:"我儿，不要与他们同行一道，禁止你脚走他们的路。"},
{volumn:        20,chapter:         1,versenumber:        16,verse:"因为，他们的脚奔跑行恶；他们急速流人的血，"},
{volumn:        20,chapter:         1,versenumber:        17,verse:"好像飞鸟，网罗设在眼前仍不躲避。"},
{volumn:        20,chapter:         1,versenumber:        18,verse:"这些人埋伏，是为自流己血；蹲伏，是为自害己命。"},
{volumn:        20,chapter:         1,versenumber:        19,verse:"凡贪恋财利的，所行之路都是如此；这贪恋之心乃夺去得财者之命。"},
{volumn:        20,chapter:         1,versenumber:        20,verse:"智慧在街市上呼喊，在宽阔处发声，"},
{volumn:        20,chapter:         1,versenumber:        21,verse:"在热闹街头喊叫，在城门口，在城中发出言语，"},
{volumn:        20,chapter:         1,versenumber:        22,verse:"说：“你们愚昧人喜爱愚昧，亵慢人喜欢亵慢，愚顽人恨恶知识，要到几时呢？"},
{volumn:        20,chapter:         1,versenumber:        23,verse:"你们当因我的责备回转；我要将我的灵浇灌你们，将我的话指示你们。"},
{volumn:        20,chapter:         1,versenumber:        24,verse:"我呼唤，你们不肯听从；我伸手，无人理会；"},
{volumn:        20,chapter:         1,versenumber:        25,verse:"反轻弃我一切的劝戒，不肯受我的责备。"},
{volumn:        20,chapter:         1,versenumber:        26,verse:"你们遭灾难，我就发笑；惊恐临到你们，我必嗤笑。"},
{volumn:        20,chapter:         1,versenumber:        27,verse:"惊恐临到你们，好像狂风；灾难来到，如同暴风；急难痛苦临到你们身上。"},
{volumn:        20,chapter:         1,versenumber:        28,verse:"那时，你们必呼求我，我却不答应，恳切地寻找我，却寻不见。"},
{volumn:        20,chapter:         1,versenumber:        29,verse:"因为，你们恨恶知识，不喜爱敬畏耶和华，"},
{volumn:        20,chapter:         1,versenumber:        30,verse:"不听我的劝戒，藐视我一切的责备，"},
{volumn:        20,chapter:         1,versenumber:        31,verse:"所以必吃自结的果子，充满自设的计谋。"},
{volumn:        20,chapter:         1,versenumber:        32,verse:"愚昧人背道，必杀己身；愚顽人安逸，必害己命。"},
{volumn:        20,chapter:         1,versenumber:        33,verse:"惟有听从我的，必安然居住，得享安静，不怕灾祸。”"},
{volumn:        20,chapter:         2,versenumber:         1,verse:"我儿，你若领受我的言语，存记我的命令，"},
{volumn:        20,chapter:         2,versenumber:         2,verse:"侧耳听智慧，专心求聪明，"},
{volumn:        20,chapter:         2,versenumber:         3,verse:"呼求明哲，扬声求聪明，"},
{volumn:        20,chapter:         2,versenumber:         4,verse:"寻找它，如寻找银子，搜求它，如搜求隐藏的珍宝，"},
{volumn:        20,chapter:         2,versenumber:         5,verse:"你就明白敬畏耶和华，得以认识　神。"},
{volumn:        20,chapter:         2,versenumber:         6,verse:"因为，耶和华赐人智慧；知识和聪明都由他口而出。"},
{volumn:        20,chapter:         2,versenumber:         7,verse:"他给正直人存留真智慧，给行为纯正的人作盾牌，"},
{volumn:        20,chapter:         2,versenumber:         8,verse:"为要保守公平人的路，护庇虔敬人的道。"},
{volumn:        20,chapter:         2,versenumber:         9,verse:"你也必明白仁义、公平、正直、一切的善道。"},
{volumn:        20,chapter:         2,versenumber:        10,verse:"智慧必入你心；你的灵要以知识为美。"},
{volumn:        20,chapter:         2,versenumber:        11,verse:"谋略必护卫你；聪明必保守你，"},
{volumn:        20,chapter:         2,versenumber:        12,verse:"要救你脱离恶道（或译：恶人的道），脱离说乖谬话的人。"},
{volumn:        20,chapter:         2,versenumber:        13,verse:"那等人舍弃正直的路，行走黑暗的道，"},
{volumn:        20,chapter:         2,versenumber:        14,verse:"欢喜作恶，喜爱恶人的乖僻，"},
{volumn:        20,chapter:         2,versenumber:        15,verse:"在他们的道中弯曲，在他们的路上偏僻。"},
{volumn:        20,chapter:         2,versenumber:        16,verse:"智慧要救你脱离淫妇，就是那油嘴滑舌的外女。"},
{volumn:        20,chapter:         2,versenumber:        17,verse:"她离弃幼年的配偶，忘了　神的盟约。"},
{volumn:        20,chapter:         2,versenumber:        18,verse:"她的家陷入死地；她的路偏向阴间。"},
{volumn:        20,chapter:         2,versenumber:        19,verse:"凡到她那里去的，不得转回，也得不着生命的路。"},
{volumn:        20,chapter:         2,versenumber:        20,verse:"智慧必使你行善人的道，守义人的路。"},
{volumn:        20,chapter:         2,versenumber:        21,verse:"正直人必在世上居住；完全人必在地上存留。"},
{volumn:        20,chapter:         2,versenumber:        22,verse:"惟有恶人必然剪除；奸诈的，必然拔出。"},
{volumn:        20,chapter:         3,versenumber:         1,verse:"我儿，不要忘记我的法则（或译：指教）；你心要谨守我的诫命；"},
{volumn:        20,chapter:         3,versenumber:         2,verse:"因为他必将长久的日子，生命的年数与平安，加给你。"},
{volumn:        20,chapter:         3,versenumber:         3,verse:"不可使慈爱、诚实离开你，要系在你颈项上，刻在你心版上。"},
{volumn:        20,chapter:         3,versenumber:         4,verse:"这样，你必在　神和世人眼前蒙恩宠，有聪明。"},
{volumn:        20,chapter:         3,versenumber:         5,verse:"你要专心仰赖耶和华，不可倚靠自己的聪明，"},
{volumn:        20,chapter:         3,versenumber:         6,verse:"在你一切所行的事上都要认定他，他必指引你的路。"},
{volumn:        20,chapter:         3,versenumber:         7,verse:"不要自以为有智慧；要敬畏耶和华，远离恶事。"},
{volumn:        20,chapter:         3,versenumber:         8,verse:"这便医治你的肚脐，滋润你的百骨。"},
{volumn:        20,chapter:         3,versenumber:         9,verse:"你要以财物和一切初熟的土产尊荣耶和华。"},
{volumn:        20,chapter:         3,versenumber:        10,verse:"这样，你的仓房必充满有余；你的酒榨有新酒盈溢。"},
{volumn:        20,chapter:         3,versenumber:        11,verse:"我儿，你不可轻看耶和华的管教（或译：惩治），也不可厌烦他的责备；"},
{volumn:        20,chapter:         3,versenumber:        12,verse:"因为耶和华所爱的，他必责备，正如父亲责备所喜爱的儿子。"},
{volumn:        20,chapter:         3,versenumber:        13,verse:"得智慧，得聪明的，这人便为有福。"},
{volumn:        20,chapter:         3,versenumber:        14,verse:"因为得智慧胜过得银子，其利益强如精金，"},
{volumn:        20,chapter:         3,versenumber:        15,verse:"比珍珠（或译：红宝石）宝贵；你一切所喜爱的，都不足与比较。"},
{volumn:        20,chapter:         3,versenumber:        16,verse:"她右手有长寿，左手有富贵。"},
{volumn:        20,chapter:         3,versenumber:        17,verse:"她的道是安乐；她的路全是平安。"},
{volumn:        20,chapter:         3,versenumber:        18,verse:"她与持守她的作生命树；持定她的，俱各有福。"},
{volumn:        20,chapter:         3,versenumber:        19,verse:"耶和华以智慧立地，以聪明定天，"},
{volumn:        20,chapter:         3,versenumber:        20,verse:"以知识使深渊裂开，使天空滴下甘露。"},
{volumn:        20,chapter:         3,versenumber:        21,verse:"我儿，要谨守真智慧和谋略，不可使她离开你的眼目。"},
{volumn:        20,chapter:         3,versenumber:        22,verse:"这样，她必作你的生命，颈项的美饰。"},
{volumn:        20,chapter:         3,versenumber:        23,verse:"你就坦然行路，不致碰脚。"},
{volumn:        20,chapter:         3,versenumber:        24,verse:"你躺下，必不惧怕；你躺卧，睡得香甜。"},
{volumn:        20,chapter:         3,versenumber:        25,verse:"忽然来的惊恐，不要害怕；恶人遭毁灭，也不要恐惧。"},
{volumn:        20,chapter:         3,versenumber:        26,verse:"因为耶和华是你所倚靠的；他必保守你的脚不陷入网罗。"},
{volumn:        20,chapter:         3,versenumber:        27,verse:"你手若有行善的力量，不可推辞，就当向那应得的人施行。"},
{volumn:        20,chapter:         3,versenumber:        28,verse:"你那里若有现成的，不可对邻舍说：“去吧，明天再来，我必给你。”"},
{volumn:        20,chapter:         3,versenumber:        29,verse:"你的邻舍既在你附近安居，你不可设计害他。"},
{volumn:        20,chapter:         3,versenumber:        30,verse:"人未曾加害与你，不可无故与他相争。"},
{volumn:        20,chapter:         3,versenumber:        31,verse:"不可嫉妒强暴的人，也不可选择他所行的路。"},
{volumn:        20,chapter:         3,versenumber:        32,verse:"因为，乖僻人为耶和华所憎恶；正直人为他所亲密。"},
{volumn:        20,chapter:         3,versenumber:        33,verse:"耶和华咒诅恶人的家庭，赐福与义人的居所。"},
{volumn:        20,chapter:         3,versenumber:        34,verse:"他讥诮那好讥诮的人，赐恩给谦卑的人。"},
{volumn:        20,chapter:         3,versenumber:        35,verse:"智慧人必承受尊荣；愚昧人高升也成为羞辱。"},
{volumn:        20,chapter:         4,versenumber:         1,verse:"众子啊，要听父亲的教训，留心得知聪明。"},
{volumn:        20,chapter:         4,versenumber:         2,verse:"因我所给你们的是好教训；不可离弃我的法则（或译：指教）。"},
{volumn:        20,chapter:         4,versenumber:         3,verse:"我在父亲面前为孝子，在母亲眼中为独一的娇儿。"},
{volumn:        20,chapter:         4,versenumber:         4,verse:"父亲教训我说：“你心要存记我的言语，遵守我的命令，便得存活。"},
{volumn:        20,chapter:         4,versenumber:         5,verse:"要得智慧，要得聪明，不可忘记，也不可偏离我口中的言语。"},
{volumn:        20,chapter:         4,versenumber:         6,verse:"不可离弃智慧，智慧就护卫你；要爱她，她就保守你。"},
{volumn:        20,chapter:         4,versenumber:         7,verse:"智慧为首；所以，要得智慧。在你一切所得之内必得聪明（或译：用你一切所得的去换聪明）。"},
{volumn:        20,chapter:         4,versenumber:         8,verse:"高举智慧，她就使你高升；怀抱智慧，她就使你尊荣。"},
{volumn:        20,chapter:         4,versenumber:         9,verse:"她必将华冠加在你头上，把荣冕交给你。”"},
{volumn:        20,chapter:         4,versenumber:        10,verse:"我儿，你要听受我的言语，就必延年益寿。"},
{volumn:        20,chapter:         4,versenumber:        11,verse:"我已指教你走智慧的道，引导你行正直的路。"},
{volumn:        20,chapter:         4,versenumber:        12,verse:"你行走，脚步必不致狭窄；你奔跑，也不致跌倒。"},
{volumn:        20,chapter:         4,versenumber:        13,verse:"要持定训诲，不可放松；必当谨守，因为它是你的生命。"},
{volumn:        20,chapter:         4,versenumber:        14,verse:"不可行恶人的路；不要走坏人的道。"},
{volumn:        20,chapter:         4,versenumber:        15,verse:"要躲避，不可经过；要转身而去。"},
{volumn:        20,chapter:         4,versenumber:        16,verse:"这等人若不行恶，不得睡觉；不使人跌倒，睡卧不安；"},
{volumn:        20,chapter:         4,versenumber:        17,verse:"因为他们以奸恶吃饼，以强暴喝酒。"},
{volumn:        20,chapter:         4,versenumber:        18,verse:"但义人的路好像黎明的光，越照越明，直到日午。"},
{volumn:        20,chapter:         4,versenumber:        19,verse:"恶人的道好像幽暗，自己不知因什么跌倒。"},
{volumn:        20,chapter:         4,versenumber:        20,verse:"我儿，要留心听我的言词，侧耳听我的话语，"},
{volumn:        20,chapter:         4,versenumber:        21,verse:"都不可离你的眼目，要存记在你心中。"},
{volumn:        20,chapter:         4,versenumber:        22,verse:"因为得着它的，就得了生命，又得了医全体的良药。"},
{volumn:        20,chapter:         4,versenumber:        23,verse:"你要保守你心，胜过保守一切（或译：你要切切保守你心），因为一生的果效是由心发出。"},
{volumn:        20,chapter:         4,versenumber:        24,verse:"你要除掉邪僻的口，弃绝乖谬的嘴。"},
{volumn:        20,chapter:         4,versenumber:        25,verse:"你的眼目要向前正看；你的眼睛（原文是皮）当向前直观。"},
{volumn:        20,chapter:         4,versenumber:        26,verse:"要修平你脚下的路，坚定你一切的道。"},
{volumn:        20,chapter:         4,versenumber:        27,verse:"不可偏向左右；要使你的脚离开邪恶。"},
{volumn:        20,chapter:         5,versenumber:         1,verse:"我儿，要留心我智慧的话语，侧耳听我聪明的言词，"},
{volumn:        20,chapter:         5,versenumber:         2,verse:"为要使你谨守谋略，嘴唇保存知识。"},
{volumn:        20,chapter:         5,versenumber:         3,verse:"因为淫妇的嘴滴下蜂蜜；她的口比油更滑，"},
{volumn:        20,chapter:         5,versenumber:         4,verse:"至终却苦似茵陈，快如两刃的刀。"},
{volumn:        20,chapter:         5,versenumber:         5,verse:"她的脚下入死地；她脚步踏住阴间，"},
{volumn:        20,chapter:         5,versenumber:         6,verse:"以致她找不着生命平坦的道。她的路变迁不定，自己还不知道。"},
{volumn:        20,chapter:         5,versenumber:         7,verse:"众子啊，现在要听从我；不可离弃我口中的话。"},
{volumn:        20,chapter:         5,versenumber:         8,verse:"你所行的道要离她远，不可就近她的房门，"},
{volumn:        20,chapter:         5,versenumber:         9,verse:"恐怕将你的尊荣给别人，将你的岁月给残忍的人；"},
{volumn:        20,chapter:         5,versenumber:        10,verse:"恐怕外人满得你的力量，你劳碌得来的，归入外人的家；"},
{volumn:        20,chapter:         5,versenumber:        11,verse:"终久，你皮肉和身体消毁，你就悲叹，"},
{volumn:        20,chapter:         5,versenumber:        12,verse:"说：“我怎么恨恶训诲，心中藐视责备，"},
{volumn:        20,chapter:         5,versenumber:        13,verse:"也不听从我师傅的话，又不侧耳听那教训我的人？"},
{volumn:        20,chapter:         5,versenumber:        14,verse:"我在圣会里，几乎落在诸般恶中。”"},
{volumn:        20,chapter:         5,versenumber:        15,verse:"你要喝自己池中的水，饮自己井里的活水。"},
{volumn:        20,chapter:         5,versenumber:        16,verse:"你的泉源岂可涨溢在外？你的河水岂可流在街上？"},
{volumn:        20,chapter:         5,versenumber:        17,verse:"惟独归你一人，不可与外人同用。"},
{volumn:        20,chapter:         5,versenumber:        18,verse:"要使你的泉源蒙福；要喜悦你幼年所娶的妻。"},
{volumn:        20,chapter:         5,versenumber:        19,verse:"她如可爱的麀鹿，可喜的母鹿；愿她的胸怀使你时时知足，她的爱情使你常常恋慕。"},
{volumn:        20,chapter:         5,versenumber:        20,verse:"我儿，你为何恋慕淫妇？为何抱外女的胸怀？"},
{volumn:        20,chapter:         5,versenumber:        21,verse:"因为，人所行的道都在耶和华眼前；他也修平人一切的路。"},
{volumn:        20,chapter:         5,versenumber:        22,verse:"恶人必被自己的罪孽捉住；他必被自己的罪恶如绳索缠绕。"},
{volumn:        20,chapter:         5,versenumber:        23,verse:"他因不受训诲就必死亡；又因愚昧过甚，必走差了路。"},
{volumn:        20,chapter:         6,versenumber:         1,verse:"我儿，你若为朋友作保，替外人击掌，"},
{volumn:        20,chapter:         6,versenumber:         2,verse:"你就被口中的话语缠住，被嘴里的言语捉住。"},
{volumn:        20,chapter:         6,versenumber:         3,verse:"我儿，你既落在朋友手中，就当这样行才可救自己：你要自卑，去恳求你的朋友。"},
{volumn:        20,chapter:         6,versenumber:         4,verse:"不要容你的眼睛睡觉；不要容你的眼皮打盹。"},
{volumn:        20,chapter:         6,versenumber:         5,verse:"要救自己，如鹿脱离猎户的手，如鸟脱离捕鸟人的手。"},
{volumn:        20,chapter:         6,versenumber:         6,verse:"懒惰人哪，你去察看蚂蚁的动作就可得智慧。"},
{volumn:        20,chapter:         6,versenumber:         7,verse:"蚂蚁没有元帅，没有官长，没有君王，"},
{volumn:        20,chapter:         6,versenumber:         8,verse:"尚且在夏天预备食物，在收割时聚敛粮食。"},
{volumn:        20,chapter:         6,versenumber:         9,verse:"懒惰人哪，你要睡到几时呢？你何时睡醒呢？"},
{volumn:        20,chapter:         6,versenumber:        10,verse:"再睡片时，打盹片时，抱着手躺卧片时，"},
{volumn:        20,chapter:         6,versenumber:        11,verse:"你的贫穷就必如强盗速来，你的缺乏仿佛拿兵器的人来到。"},
{volumn:        20,chapter:         6,versenumber:        12,verse:"无赖的恶徒，行动就用乖僻的口，"},
{volumn:        20,chapter:         6,versenumber:        13,verse:"用眼传神，用脚示意，用指点划，"},
{volumn:        20,chapter:         6,versenumber:        14,verse:"心中乖僻，常设恶谋，布散纷争。"},
{volumn:        20,chapter:         6,versenumber:        15,verse:"所以，灾难必忽然临到他身；他必顷刻败坏，无法可治。"},
{volumn:        20,chapter:         6,versenumber:        16,verse:"耶和华所恨恶的有六样，连他心所憎恶的共有七样："},
{volumn:        20,chapter:         6,versenumber:        17,verse:"就是高傲的眼，撒谎的舌，流无辜人血的手，"},
{volumn:        20,chapter:         6,versenumber:        18,verse:"图谋恶计的心，飞跑行恶的脚，"},
{volumn:        20,chapter:         6,versenumber:        19,verse:"吐谎言的假见证，并弟兄中布散纷争的人。"},
{volumn:        20,chapter:         6,versenumber:        20,verse:"我儿，要谨守你父亲的诫命；不可离弃你母亲的法则（或译：指教），"},
{volumn:        20,chapter:         6,versenumber:        21,verse:"要常系在你心上，挂在你项上。"},
{volumn:        20,chapter:         6,versenumber:        22,verse:"你行走，它必引导你；你躺卧，它必保守你；你睡醒，它必与你谈论。"},
{volumn:        20,chapter:         6,versenumber:        23,verse:"因为诫命是灯，法则（或译：指教）是光，训诲的责备是生命的道，"},
{volumn:        20,chapter:         6,versenumber:        24,verse:"能保你远离恶妇，远离外女谄媚的舌头。"},
{volumn:        20,chapter:         6,versenumber:        25,verse:"你心中不要恋慕她的美色，也不要被她眼皮勾引。"},
{volumn:        20,chapter:         6,versenumber:        26,verse:"因为，妓女能使人只剩一块饼；淫妇猎取人宝贵的生命。"},
{volumn:        20,chapter:         6,versenumber:        27,verse:"人若怀里搋火，衣服岂能不烧呢？"},
{volumn:        20,chapter:         6,versenumber:        28,verse:"人若在火炭上走，脚岂能不烫呢？"},
{volumn:        20,chapter:         6,versenumber:        29,verse:"亲近邻舍之妻的，也是如此；凡挨近她的，不免受罚。"},
{volumn:        20,chapter:         6,versenumber:        30,verse:"贼因饥饿偷窃充饥，人不藐视他，"},
{volumn:        20,chapter:         6,versenumber:        31,verse:"若被找着，他必赔还七倍，必将家中所有的尽都偿还。"},
{volumn:        20,chapter:         6,versenumber:        32,verse:"与妇人行淫的，便是无知；行这事的，必丧掉生命。"},
{volumn:        20,chapter:         6,versenumber:        33,verse:"他必受伤损，必被凌辱；他的羞耻不得涂抹。"},
{volumn:        20,chapter:         6,versenumber:        34,verse:"因为人的嫉恨成了烈怒，报仇的时候决不留情。"},
{volumn:        20,chapter:         6,versenumber:        35,verse:"什么赎价，他都不顾；你虽送许多礼物，他也不肯干休。"},
{volumn:        20,chapter:         7,versenumber:         1,verse:"我儿，你要遵守我的言语，将我的命令存记在心。"},
{volumn:        20,chapter:         7,versenumber:         2,verse:"遵守我的命令就得存活；保守我的法则（或译：指教），好像保守眼中的瞳人，"},
{volumn:        20,chapter:         7,versenumber:         3,verse:"系在你指头上，刻在你心版上。"},
{volumn:        20,chapter:         7,versenumber:         4,verse:"对智慧说：你是我的姐妹，称呼聪明为你的亲人，"},
{volumn:        20,chapter:         7,versenumber:         5,verse:"她就保你远离淫妇，远离说谄媚话的外女。"},
{volumn:        20,chapter:         7,versenumber:         6,verse:"我曾在我房屋的窗户内，从我窗棂之间往外观看："},
{volumn:        20,chapter:         7,versenumber:         7,verse:"见愚蒙人内，少年人中，分明有一个无知的少年人，"},
{volumn:        20,chapter:         7,versenumber:         8,verse:"从街上经过，走近淫妇的巷口，直往通她家的路去，"},
{volumn:        20,chapter:         7,versenumber:         9,verse:"在黄昏，或晚上，或半夜，或黑暗之中。"},
{volumn:        20,chapter:         7,versenumber:        10,verse:"看哪，有一个妇人来迎接他，是妓女的打扮，有诡诈的心思。"},
{volumn:        20,chapter:         7,versenumber:        11,verse:"这妇人喧嚷，不守约束，在家里停不住脚，"},
{volumn:        20,chapter:         7,versenumber:        12,verse:"有时在街市上，有时在宽阔处，或在各巷口蹲伏，"},
{volumn:        20,chapter:         7,versenumber:        13,verse:"拉住那少年人，与他亲嘴，脸无羞耻对他说："},
{volumn:        20,chapter:         7,versenumber:        14,verse:"“平安祭在我这里，今日才还了我所许的愿。"},
{volumn:        20,chapter:         7,versenumber:        15,verse:"因此，我出来迎接你，恳切求见你的面，恰巧遇见了你。"},
{volumn:        20,chapter:         7,versenumber:        16,verse:"我已经用绣花毯子和埃及线织的花纹布铺了我的床。"},
{volumn:        20,chapter:         7,versenumber:        17,verse:"我又用没药、沉香、桂皮薰了我的榻。"},
{volumn:        20,chapter:         7,versenumber:        18,verse:"你来，我们可以饱享爱情，直到早晨；我们可以彼此亲爱欢乐。"},
{volumn:        20,chapter:         7,versenumber:        19,verse:"因为我丈夫不在家，出门行远路；"},
{volumn:        20,chapter:         7,versenumber:        20,verse:"他手拿银囊，必到月望才回家。”"},
{volumn:        20,chapter:         7,versenumber:        21,verse:"淫妇用许多巧言诱他随从，用谄媚的嘴逼他同行。"},
{volumn:        20,chapter:         7,versenumber:        22,verse:"少年人立刻跟随她，好像牛往宰杀之地；又像愚昧人带锁链去受刑罚，"},
{volumn:        20,chapter:         7,versenumber:        23,verse:"直等箭穿他的肝；如同雀鸟急入网罗，却不知是自丧己命。"},
{volumn:        20,chapter:         7,versenumber:        24,verse:"众子啊，现在要听从我，留心听我口中的话。"},
{volumn:        20,chapter:         7,versenumber:        25,verse:"你的心不可偏向淫妇的道，不要入她的迷途。"},
{volumn:        20,chapter:         7,versenumber:        26,verse:"因为，被她伤害仆倒的不少；被她杀戮的而且甚多。"},
{volumn:        20,chapter:         7,versenumber:        27,verse:"她的家是在阴间之路，下到死亡之宫。"},
{volumn:        20,chapter:         8,versenumber:         1,verse:"智慧岂不呼叫？聪明岂不发声？"},
{volumn:        20,chapter:         8,versenumber:         2,verse:"她在道旁高处的顶上，在十字路口站立，"},
{volumn:        20,chapter:         8,versenumber:         3,verse:"在城门旁，在城门口，在城门洞，大声说："},
{volumn:        20,chapter:         8,versenumber:         4,verse:"“众人哪，我呼叫你们，我向世人发声。"},
{volumn:        20,chapter:         8,versenumber:         5,verse:"说：愚蒙人哪，你们要会悟灵明；愚昧人哪，你们当心里明白。"},
{volumn:        20,chapter:         8,versenumber:         6,verse:"你们当听，因我要说极美的话；我张嘴要论正直的事。"},
{volumn:        20,chapter:         8,versenumber:         7,verse:"我的口要发出真理；我的嘴憎恶邪恶。"},
{volumn:        20,chapter:         8,versenumber:         8,verse:"我口中的言语都是公义，并无弯曲乖僻。"},
{volumn:        20,chapter:         8,versenumber:         9,verse:"有聪明的，以为明显，得知识的，以为正直。"},
{volumn:        20,chapter:         8,versenumber:        10,verse:"你们当受我的教训，不受白银；宁得知识，胜过黄金。"},
{volumn:        20,chapter:         8,versenumber:        11,verse:"因为智慧比珍珠（或译：红宝石）更美；一切可喜爱的都不足与比较。"},
{volumn:        20,chapter:         8,versenumber:        12,verse:"“我智慧以灵明为居所，又寻得知识和谋略。"},
{volumn:        20,chapter:         8,versenumber:        13,verse:"敬畏耶和华在乎恨恶邪恶；那骄傲、狂妄，并恶道，以及乖谬的口，都为我所恨恶。"},
{volumn:        20,chapter:         8,versenumber:        14,verse:"我有谋略和真知识；我乃聪明，我有能力。"},
{volumn:        20,chapter:         8,versenumber:        15,verse:"帝王藉我坐国位；君王藉我定公平。"},
{volumn:        20,chapter:         8,versenumber:        16,verse:"王子和首领，世上一切的审判官，都是藉我掌权。"},
{volumn:        20,chapter:         8,versenumber:        17,verse:"爱我的，我也爱他；恳切寻求我的，必寻得见。"},
{volumn:        20,chapter:         8,versenumber:        18,verse:"丰富尊荣在我；恒久的财并公义也在我。"},
{volumn:        20,chapter:         8,versenumber:        19,verse:"我的果实胜过黄金，强如精金；我的出产超乎高银。"},
{volumn:        20,chapter:         8,versenumber:        20,verse:"我在公义的道上走，在公平的路中行，"},
{volumn:        20,chapter:         8,versenumber:        21,verse:"使爱我的，承受货财，并充满他们的府库。"},
{volumn:        20,chapter:         8,versenumber:        22,verse:"“在耶和华造化的起头，在太初创造万物之先，就有了我。"},
{volumn:        20,chapter:         8,versenumber:        23,verse:"从亘古，从太初，未有世界以前，我已被立。"},
{volumn:        20,chapter:         8,versenumber:        24,verse:"没有深渊，没有大水的泉源，我已生出。"},
{volumn:        20,chapter:         8,versenumber:        25,verse:"大山未曾奠定，小山未有之先，我已生出。"},
{volumn:        20,chapter:         8,versenumber:        26,verse:"耶和华还没有创造大地和田野，并世上的土质，我已生出。"},
{volumn:        20,chapter:         8,versenumber:        27,verse:"他立高天，我在那里；他在渊面的周围，划出圆圈。"},
{volumn:        20,chapter:         8,versenumber:        28,verse:"上使穹苍坚硬，下使渊源稳固，"},
{volumn:        20,chapter:         8,versenumber:        29,verse:"为沧海定出界限，使水不越过他的命令，立定大地的根基。"},
{volumn:        20,chapter:         8,versenumber:        30,verse:"那时，我在他那里为工师，日日为他所喜爱，常常在他面前踊跃，"},
{volumn:        20,chapter:         8,versenumber:        31,verse:"踊跃在他为人预备可住之地，也喜悦住在世人之间。"},
{volumn:        20,chapter:         8,versenumber:        32,verse:"众子啊，现在要听从我，因为谨守我道的，便为有福。"},
{volumn:        20,chapter:         8,versenumber:        33,verse:"要听教训就得智慧，不可弃绝。"},
{volumn:        20,chapter:         8,versenumber:        34,verse:"听从我、日日在我门口仰望、在我门框旁边等候的，那人便为有福。"},
{volumn:        20,chapter:         8,versenumber:        35,verse:"因为寻得我的，就寻得生命，也必蒙耶和华的恩惠。"},
{volumn:        20,chapter:         8,versenumber:        36,verse:"得罪我的，却害了自己的性命；恨恶我的，都喜爱死亡。”"},
{volumn:        20,chapter:         9,versenumber:         1,verse:"智慧建造房屋，凿成七根柱子，"},
{volumn:        20,chapter:         9,versenumber:         2,verse:"宰杀牲畜，调和旨酒，设摆筵席；"},
{volumn:        20,chapter:         9,versenumber:         3,verse:"打发使女出去，自己在城中至高处呼叫，"},
{volumn:        20,chapter:         9,versenumber:         4,verse:"说：“谁是愚蒙人，可以转到这里来！”又对那无知的人说："},
{volumn:        20,chapter:         9,versenumber:         5,verse:"“你们来，吃我的饼，喝我调和的酒。"},
{volumn:        20,chapter:         9,versenumber:         6,verse:"你们愚蒙人，要舍弃愚蒙，就得存活，并要走光明的道。”"},
{volumn:        20,chapter:         9,versenumber:         7,verse:"指斥亵慢人的，必受辱骂；责备恶人的，必被玷污。"},
{volumn:        20,chapter:         9,versenumber:         8,verse:"不要责备亵慢人，恐怕他恨你；要责备智慧人，他必爱你。"},
{volumn:        20,chapter:         9,versenumber:         9,verse:"教导智慧人，他就越发有智慧；指示义人，他就增长学问。"},
{volumn:        20,chapter:         9,versenumber:        10,verse:"敬畏耶和华是智慧的开端；认识至圣者便是聪明。"},
{volumn:        20,chapter:         9,versenumber:        11,verse:"你藉着我，日子必增多，年岁也必加添。"},
{volumn:        20,chapter:         9,versenumber:        12,verse:"你若有智慧，是与自己有益；你若亵慢，就必独自担当。"},
{volumn:        20,chapter:         9,versenumber:        13,verse:"愚昧的妇人喧嚷；她是愚蒙，一无所知。"},
{volumn:        20,chapter:         9,versenumber:        14,verse:"她坐在自己的家门口，坐在城中高处的座位上，"},
{volumn:        20,chapter:         9,versenumber:        15,verse:"呼叫过路的，就是直行其道的人，"},
{volumn:        20,chapter:         9,versenumber:        16,verse:"说：“谁是愚蒙人，可以转到这里来！”又对那无知的人说："},
{volumn:        20,chapter:         9,versenumber:        17,verse:"“偷来的水是甜的，暗吃的饼是好的。”"},
{volumn:        20,chapter:         9,versenumber:        18,verse:"人却不知有阴魂在她那里；她的客在阴间的深处。"},
{volumn:        20,chapter:        10,versenumber:         1,verse:"所罗门的箴言：智慧之子使父亲欢乐；愚昧之子叫母亲担忧。"},
{volumn:        20,chapter:        10,versenumber:         2,verse:"不义之财毫无益处；惟有公义能救人脱离死亡。"},
{volumn:        20,chapter:        10,versenumber:         3,verse:"耶和华不使义人受饥饿；恶人所欲的，他必推开。"},
{volumn:        20,chapter:        10,versenumber:         4,verse:"手懒的，要受贫穷；手勤的，却要富足。"},
{volumn:        20,chapter:        10,versenumber:         5,verse:"夏天聚敛的，是智慧之子；收割时沉睡的，是贻羞之子。"},
{volumn:        20,chapter:        10,versenumber:         6,verse:"福祉临到义人的头；强暴蒙蔽恶人的口。"},
{volumn:        20,chapter:        10,versenumber:         7,verse:"义人的纪念被称赞；恶人的名字必朽烂。"},
{volumn:        20,chapter:        10,versenumber:         8,verse:"心中智慧的，必受命令；口里愚妄的，必致倾倒。"},
{volumn:        20,chapter:        10,versenumber:         9,verse:"行正直路的，步步安稳；走弯曲道的，必致败露。"},
{volumn:        20,chapter:        10,versenumber:        10,verse:"以眼传神的，使人忧患；口里愚妄的，必致倾倒。"},
{volumn:        20,chapter:        10,versenumber:        11,verse:"义人的口是生命的泉源；强暴蒙蔽恶人的口。"},
{volumn:        20,chapter:        10,versenumber:        12,verse:"恨能挑启争端；爱能遮掩一切过错。"},
{volumn:        20,chapter:        10,versenumber:        13,verse:"明哲人嘴里有智慧；无知人背上受刑杖。"},
{volumn:        20,chapter:        10,versenumber:        14,verse:"智慧人积存知识；愚妄人的口速致败坏。"},
{volumn:        20,chapter:        10,versenumber:        15,verse:"富户的财物是他的坚城；穷人的贫乏是他的败坏。"},
{volumn:        20,chapter:        10,versenumber:        16,verse:"义人的勤劳致生；恶人的进项致死（死：原文是罪）。"},
{volumn:        20,chapter:        10,versenumber:        17,verse:"谨守训诲的，乃在生命的道上；违弃责备的，便失迷了路。"},
{volumn:        20,chapter:        10,versenumber:        18,verse:"隐藏怨恨的，有说谎的嘴；口出谗谤的，是愚妄的人。"},
{volumn:        20,chapter:        10,versenumber:        19,verse:"多言多语难免有过；禁止嘴唇是有智慧。"},
{volumn:        20,chapter:        10,versenumber:        20,verse:"义人的舌乃似高银；恶人的心所值无几。"},
{volumn:        20,chapter:        10,versenumber:        21,verse:"义人的口教养多人；愚昧人因无知而死亡。"},
{volumn:        20,chapter:        10,versenumber:        22,verse:"耶和华所赐的福使人富足，并不加上忧虑。"},
{volumn:        20,chapter:        10,versenumber:        23,verse:"愚妄人以行恶为戏耍；明哲人却以智慧为乐。"},
{volumn:        20,chapter:        10,versenumber:        24,verse:"恶人所怕的，必临到他；义人所愿的，必蒙应允。"},
{volumn:        20,chapter:        10,versenumber:        25,verse:"暴风一过，恶人归于无有；义人的根基却是永久。"},
{volumn:        20,chapter:        10,versenumber:        26,verse:"懒惰人叫差他的人如醋倒牙，如烟薰目。"},
{volumn:        20,chapter:        10,versenumber:        27,verse:"敬畏耶和华使人日子加多；但恶人的年岁必被减少。"},
{volumn:        20,chapter:        10,versenumber:        28,verse:"义人的盼望必得喜乐；恶人的指望必致灭没。"},
{volumn:        20,chapter:        10,versenumber:        29,verse:"耶和华的道是正直人的保障，却成了作孽人的败坏。"},
{volumn:        20,chapter:        10,versenumber:        30,verse:"义人永不挪移；恶人不得住在地上。"},
{volumn:        20,chapter:        10,versenumber:        31,verse:"义人的口滋生智慧；乖谬的舌必被割断。"},
{volumn:        20,chapter:        10,versenumber:        32,verse:"义人的嘴能令人喜悦；恶人的口说乖谬的话。"},
{volumn:        20,chapter:        11,versenumber:         1,verse:"诡诈的天平为耶和华所憎恶；公平的法码为他所喜悦。"},
{volumn:        20,chapter:        11,versenumber:         2,verse:"骄傲来，羞耻也来；谦逊人却有智慧。"},
{volumn:        20,chapter:        11,versenumber:         3,verse:"正直人的纯正必引导自己；奸诈人的乖僻必毁灭自己。"},
{volumn:        20,chapter:        11,versenumber:         4,verse:"发怒的日子资财无益；惟有公义能救人脱离死亡。"},
{volumn:        20,chapter:        11,versenumber:         5,verse:"完全人的义必指引他的路；但恶人必因自己的恶跌倒。"},
{volumn:        20,chapter:        11,versenumber:         6,verse:"正直人的义必拯救自己；奸诈人必陷在自己的罪孽中。"},
{volumn:        20,chapter:        11,versenumber:         7,verse:"恶人一死，他的指望必灭绝；罪人的盼望也必灭没。"},
{volumn:        20,chapter:        11,versenumber:         8,verse:"义人得脱离患难，有恶人来代替他。"},
{volumn:        20,chapter:        11,versenumber:         9,verse:"不虔敬的人用口败坏邻舍；义人却因知识得救。"},
{volumn:        20,chapter:        11,versenumber:        10,verse:"义人享福，合城喜乐；恶人灭亡，人都欢呼。"},
{volumn:        20,chapter:        11,versenumber:        11,verse:"城因正直人祝福便高举，却因邪恶人的口就倾覆。"},
{volumn:        20,chapter:        11,versenumber:        12,verse:"藐视邻舍的，毫无智慧；明哲人却静默不言。"},
{volumn:        20,chapter:        11,versenumber:        13,verse:"往来传舌的，泄漏密事；心中诚实的，遮隐事情。"},
{volumn:        20,chapter:        11,versenumber:        14,verse:"无智谋，民就败落；谋士多，人便安居。"},
{volumn:        20,chapter:        11,versenumber:        15,verse:"为外人作保的，必受亏损；恨恶击掌的，却得安稳。"},
{volumn:        20,chapter:        11,versenumber:        16,verse:"恩德的妇女得尊荣；强暴的男子得资财。"},
{volumn:        20,chapter:        11,versenumber:        17,verse:"仁慈的人善待自己；残忍的人扰害己身。"},
{volumn:        20,chapter:        11,versenumber:        18,verse:"恶人经营，得虚浮的工价；撒义种的，得实在的果效。"},
{volumn:        20,chapter:        11,versenumber:        19,verse:"恒心为义的，必得生命；追求邪恶的，必致死亡。"},
{volumn:        20,chapter:        11,versenumber:        20,verse:"心中乖僻的，为耶和华所憎恶；行事完全的，为他所喜悦。"},
{volumn:        20,chapter:        11,versenumber:        21,verse:"恶人虽然连手，必不免受罚；义人的后裔必得拯救。"},
{volumn:        20,chapter:        11,versenumber:        22,verse:"妇女美貌而无见识，如同金环带在猪鼻上。"},
{volumn:        20,chapter:        11,versenumber:        23,verse:"义人的心愿尽得好处；恶人的指望致干忿怒。"},
{volumn:        20,chapter:        11,versenumber:        24,verse:"有施散的，却更增添；有吝惜过度的，反致穷乏。"},
{volumn:        20,chapter:        11,versenumber:        25,verse:"好施舍的，必得丰裕；滋润人的，必得滋润。"},
{volumn:        20,chapter:        11,versenumber:        26,verse:"屯粮不卖的，民必咒诅他；情愿出卖的，人必为他祝福。"},
{volumn:        20,chapter:        11,versenumber:        27,verse:"恳切求善的，就求得恩惠；惟独求恶的，恶必临到他身。"},
{volumn:        20,chapter:        11,versenumber:        28,verse:"倚仗自己财物的，必跌倒；义人必发旺，如青叶。"},
{volumn:        20,chapter:        11,versenumber:        29,verse:"扰害己家的，必承受清风；愚昧人必作慧心人的仆人。"},
{volumn:        20,chapter:        11,versenumber:        30,verse:"义人所结的果子就是生命树；有智慧的，必能得人。"},
{volumn:        20,chapter:        11,versenumber:        31,verse:"看哪，义人在世尚且受报，何况恶人和罪人呢？"},
{volumn:        20,chapter:        12,versenumber:         1,verse:"喜爱管教的，就是喜爱知识；恨恶责备的，却是畜类。"},
{volumn:        20,chapter:        12,versenumber:         2,verse:"善人必蒙耶和华的恩惠；设诡计的人，耶和华必定他的罪。"},
{volumn:        20,chapter:        12,versenumber:         3,verse:"人靠恶行不能坚立；义人的根必不动摇。"},
{volumn:        20,chapter:        12,versenumber:         4,verse:"才德的妇人是丈夫的冠冕；贻羞的妇人如同朽烂在她丈夫的骨中。"},
{volumn:        20,chapter:        12,versenumber:         5,verse:"义人的思念是公平；恶人的计谋是诡诈。"},
{volumn:        20,chapter:        12,versenumber:         6,verse:"恶人的言论是埋伏流人的血；正直人的口必拯救人。"},
{volumn:        20,chapter:        12,versenumber:         7,verse:"恶人倾覆，归于无有；义人的家必站得住。"},
{volumn:        20,chapter:        12,versenumber:         8,verse:"人必按自己的智慧被称赞；心中乖谬的，必被藐视。"},
{volumn:        20,chapter:        12,versenumber:         9,verse:"被人轻贱，却有仆人，强如自尊，缺少食物。"},
{volumn:        20,chapter:        12,versenumber:        10,verse:"义人顾惜他牲畜的命；恶人的怜悯也是残忍。"},
{volumn:        20,chapter:        12,versenumber:        11,verse:"耕种自己田地的，必得饱食；追随虚浮的，却是无知。"},
{volumn:        20,chapter:        12,versenumber:        12,verse:"恶人想得坏人的网罗；义人的根得以结实。"},
{volumn:        20,chapter:        12,versenumber:        13,verse:"恶人嘴中的过错是自己的网罗；但义人必脱离患难。"},
{volumn:        20,chapter:        12,versenumber:        14,verse:"人因口所结的果子，必饱得美福；人手所做的，必为自己的报应。"},
{volumn:        20,chapter:        12,versenumber:        15,verse:"愚妄人所行的，在自己眼中看为正直；惟智慧人肯听人的劝教。"},
{volumn:        20,chapter:        12,versenumber:        16,verse:"愚妄人的恼怒立时显露；通达人能忍辱藏羞。"},
{volumn:        20,chapter:        12,versenumber:        17,verse:"说出真话的，显明公义；作假见证的，显出诡诈。"},
{volumn:        20,chapter:        12,versenumber:        18,verse:"说话浮躁的，如刀刺人；智慧人的舌头却为医人的良药。"},
{volumn:        20,chapter:        12,versenumber:        19,verse:"口吐真言，永远坚立；舌说谎话，只存片时。"},
{volumn:        20,chapter:        12,versenumber:        20,verse:"图谋恶事的，心存诡诈；劝人和睦的，便得喜乐。"},
{volumn:        20,chapter:        12,versenumber:        21,verse:"义人不遭灾害；恶人满受祸患。"},
{volumn:        20,chapter:        12,versenumber:        22,verse:"说谎言的嘴为耶和华所憎恶；行事诚实的，为他所喜悦。"},
{volumn:        20,chapter:        12,versenumber:        23,verse:"通达人隐藏知识；愚昧人的心彰显愚昧。"},
{volumn:        20,chapter:        12,versenumber:        24,verse:"殷勤人的手必掌权；懒惰的人必服苦。"},
{volumn:        20,chapter:        12,versenumber:        25,verse:"人心忧虑，屈而不伸；一句良言，使心欢乐。"},
{volumn:        20,chapter:        12,versenumber:        26,verse:"义人引导他的邻舍；恶人的道叫人失迷。"},
{volumn:        20,chapter:        12,versenumber:        27,verse:"懒惰的人不烤打猎所得的；殷勤的人却得宝贵的财物。"},
{volumn:        20,chapter:        12,versenumber:        28,verse:"在公义的道上有生命；其路之中并无死亡。"},
{volumn:        20,chapter:        13,versenumber:         1,verse:"智慧子听父亲的教训；亵慢人不听责备。"},
{volumn:        20,chapter:        13,versenumber:         2,verse:"人因口所结的果子，必享美福；奸诈人必遭强暴。"},
{volumn:        20,chapter:        13,versenumber:         3,verse:"谨守口的，得保生命；大张嘴的，必致败亡。"},
{volumn:        20,chapter:        13,versenumber:         4,verse:"懒惰人羡慕，却无所得；殷勤人必得丰裕。"},
{volumn:        20,chapter:        13,versenumber:         5,verse:"义人恨恶谎言；恶人有臭名，且致惭愧。"},
{volumn:        20,chapter:        13,versenumber:         6,verse:"行为正直的，有公义保守；犯罪的，被邪恶倾覆。"},
{volumn:        20,chapter:        13,versenumber:         7,verse:"假作富足的，却一无所有；装作穷乏的，却广有财物。"},
{volumn:        20,chapter:        13,versenumber:         8,verse:"人的资财是他生命的赎价；穷乏人却听不见威吓的话。"},
{volumn:        20,chapter:        13,versenumber:         9,verse:"义人的光明亮（原文是欢喜）；恶人的灯要熄灭。"},
{volumn:        20,chapter:        13,versenumber:        10,verse:"骄傲只启争竞；听劝言的，却有智慧。"},
{volumn:        20,chapter:        13,versenumber:        11,verse:"不劳而得之财必然消耗；勤劳积蓄的，必见加增。"},
{volumn:        20,chapter:        13,versenumber:        12,verse:"所盼望的迟延未得，令人心忧；所愿意的临到，却是生命树。"},
{volumn:        20,chapter:        13,versenumber:        13,verse:"藐视训言的，自取灭亡；敬畏诫命的，必得善报。"},
{volumn:        20,chapter:        13,versenumber:        14,verse:"智慧人的法则（或译：指教）是生命的泉源，可以使人离开死亡的网罗。"},
{volumn:        20,chapter:        13,versenumber:        15,verse:"美好的聪明使人蒙恩；奸诈人的道路崎岖难行。"},
{volumn:        20,chapter:        13,versenumber:        16,verse:"凡通达人都凭知识行事；愚昧人张扬自己的愚昧。"},
{volumn:        20,chapter:        13,versenumber:        17,verse:"奸恶的使者必陷在祸患里；忠信的使臣乃医人的良药。"},
{volumn:        20,chapter:        13,versenumber:        18,verse:"弃绝管教的，必致贫受辱；领受责备的，必得尊荣。"},
{volumn:        20,chapter:        13,versenumber:        19,verse:"所欲的成就，心觉甘甜；远离恶事，为愚昧人所憎恶。"},
{volumn:        20,chapter:        13,versenumber:        20,verse:"与智慧人同行的，必得智慧；和愚昧人作伴的，必受亏损。"},
{volumn:        20,chapter:        13,versenumber:        21,verse:"祸患追赶罪人；义人必得善报。"},
{volumn:        20,chapter:        13,versenumber:        22,verse:"善人给子孙遗留产业；罪人为义人积存资财。"},
{volumn:        20,chapter:        13,versenumber:        23,verse:"穷人耕种多得粮食，但因不义，有消灭的。"},
{volumn:        20,chapter:        13,versenumber:        24,verse:"不忍用杖打儿子的，是恨恶他；疼爱儿子的，随时管教。"},
{volumn:        20,chapter:        13,versenumber:        25,verse:"义人吃得饱足；恶人肚腹缺粮。"},
{volumn:        20,chapter:        14,versenumber:         1,verse:"智慧妇人建立家室；愚妄妇人亲手拆毁。"},
{volumn:        20,chapter:        14,versenumber:         2,verse:"行动正直的，敬畏耶和华；行事乖僻的，却藐视他。"},
{volumn:        20,chapter:        14,versenumber:         3,verse:"愚妄人口中骄傲，如杖责打己身；智慧人的嘴必保守自己。"},
{volumn:        20,chapter:        14,versenumber:         4,verse:"家里无牛，槽头干净；土产加多乃凭牛力。"},
{volumn:        20,chapter:        14,versenumber:         5,verse:"诚实见证人不说谎话；假见证人吐出谎言。"},
{volumn:        20,chapter:        14,versenumber:         6,verse:"亵慢人寻智慧，却寻不着；聪明人易得知识。"},
{volumn:        20,chapter:        14,versenumber:         7,verse:"到愚昧人面前，不见他嘴中有知识。"},
{volumn:        20,chapter:        14,versenumber:         8,verse:"通达人的智慧在乎明白己道；愚昧人的愚妄乃是诡诈（或译：自叹）。"},
{volumn:        20,chapter:        14,versenumber:         9,verse:"愚妄人犯罪，以为戏耍（或译：赎愆祭愚弄愚妄人）；正直人互相喜悦。"},
{volumn:        20,chapter:        14,versenumber:        10,verse:"心中的苦楚，自己知道；心里的喜乐，外人无干。"},
{volumn:        20,chapter:        14,versenumber:        11,verse:"奸恶人的房屋必倾倒；正直人的帐棚必兴盛。"},
{volumn:        20,chapter:        14,versenumber:        12,verse:"有一条路，人以为正，至终成为死亡之路。"},
{volumn:        20,chapter:        14,versenumber:        13,verse:"人在喜笑中，心也忧愁；快乐至极就生愁苦。"},
{volumn:        20,chapter:        14,versenumber:        14,verse:"心中背道的，必满得自己的结果；善人必从自己的行为得以知足。"},
{volumn:        20,chapter:        14,versenumber:        15,verse:"愚蒙人是话都信；通达人步步谨慎。"},
{volumn:        20,chapter:        14,versenumber:        16,verse:"智慧人惧怕，就远离恶事；愚妄人却狂傲自恃。"},
{volumn:        20,chapter:        14,versenumber:        17,verse:"轻易发怒的，行事愚妄；设立诡计的，被人恨恶。"},
{volumn:        20,chapter:        14,versenumber:        18,verse:"愚蒙人得愚昧为产业；通达人得知识为冠冕。"},
{volumn:        20,chapter:        14,versenumber:        19,verse:"坏人俯伏在善人面前；恶人俯伏在义人门口。"},
{volumn:        20,chapter:        14,versenumber:        20,verse:"贫穷人连邻舍也恨他；富足人朋友最多。"},
{volumn:        20,chapter:        14,versenumber:        21,verse:"藐视邻舍的，这人有罪；怜悯贫穷的，这人有福。"},
{volumn:        20,chapter:        14,versenumber:        22,verse:"谋恶的，岂非走入迷途吗？谋善的，必得慈爱和诚实。"},
{volumn:        20,chapter:        14,versenumber:        23,verse:"诸般勤劳都有益处；嘴上多言乃致穷乏。"},
{volumn:        20,chapter:        14,versenumber:        24,verse:"智慧人的财为自己的冠冕；愚妄人的愚昧终是愚昧。"},
{volumn:        20,chapter:        14,versenumber:        25,verse:"作真见证的，救人性命；吐出谎言的，施行诡诈。"},
{volumn:        20,chapter:        14,versenumber:        26,verse:"敬畏耶和华的，大有倚靠；他的儿女也有避难所。"},
{volumn:        20,chapter:        14,versenumber:        27,verse:"敬畏耶和华就是生命的泉源，可以使人离开死亡的网罗。"},
{volumn:        20,chapter:        14,versenumber:        28,verse:"帝王荣耀在乎民多；君王衰败在乎民少。"},
{volumn:        20,chapter:        14,versenumber:        29,verse:"不轻易发怒的，大有聪明；性情暴躁的，大显愚妄。"},
{volumn:        20,chapter:        14,versenumber:        30,verse:"心中安静是肉体的生命；嫉妒是骨中的朽烂。"},
{volumn:        20,chapter:        14,versenumber:        31,verse:"欺压贫寒的，是辱没造他的主；怜悯穷乏的，乃是尊敬主。"},
{volumn:        20,chapter:        14,versenumber:        32,verse:"恶人在所行的恶上必被推倒；义人临死，有所投靠。"},
{volumn:        20,chapter:        14,versenumber:        33,verse:"智慧存在聪明人心中；愚昧人心里所存的，显而易见。"},
{volumn:        20,chapter:        14,versenumber:        34,verse:"公义使邦国高举；罪恶是人民的羞辱。"},
{volumn:        20,chapter:        14,versenumber:        35,verse:"智慧的臣子蒙王恩惠；贻羞的仆人遭其震怒。"},
{volumn:        20,chapter:        15,versenumber:         1,verse:"回答柔和，使怒消退；言语暴戾，触动怒气。"},
{volumn:        20,chapter:        15,versenumber:         2,verse:"智慧人的舌善发知识；愚昧人的口吐出愚昧。"},
{volumn:        20,chapter:        15,versenumber:         3,verse:"耶和华的眼目无处不在；恶人善人，他都鉴察。"},
{volumn:        20,chapter:        15,versenumber:         4,verse:"温良的舌是生命树；乖谬的嘴使人心碎。"},
{volumn:        20,chapter:        15,versenumber:         5,verse:"愚妄人藐视父亲的管教；领受责备的，得着见识。"},
{volumn:        20,chapter:        15,versenumber:         6,verse:"义人家中多有财宝；恶人得利反受扰害。"},
{volumn:        20,chapter:        15,versenumber:         7,verse:"智慧人的嘴播扬知识；愚昧人的心并不如此。"},
{volumn:        20,chapter:        15,versenumber:         8,verse:"恶人献祭，为耶和华所憎恶；正直人祈祷，为他所喜悦。"},
{volumn:        20,chapter:        15,versenumber:         9,verse:"恶人的道路，为耶和华所憎恶；追求公义的，为他所喜爱。"},
{volumn:        20,chapter:        15,versenumber:        10,verse:"舍弃正路的，必受严刑；恨恶责备的，必致死亡。"},
{volumn:        20,chapter:        15,versenumber:        11,verse:"阴间和灭亡尚在耶和华眼前，何况世人的心呢？"},
{volumn:        20,chapter:        15,versenumber:        12,verse:"亵慢人不爱受责备；他也不就近智慧人。"},
{volumn:        20,chapter:        15,versenumber:        13,verse:"心中喜乐，面带笑容；心里忧愁，灵被损伤。"},
{volumn:        20,chapter:        15,versenumber:        14,verse:"聪明人心求知识；愚昧人口吃愚昧。"},
{volumn:        20,chapter:        15,versenumber:        15,verse:"困苦人的日子都是愁苦；心中欢畅的，常享丰筵。"},
{volumn:        20,chapter:        15,versenumber:        16,verse:"少有财宝，敬畏耶和华，强如多有财宝，烦乱不安。"},
{volumn:        20,chapter:        15,versenumber:        17,verse:"吃素菜，彼此相爱，强如吃肥牛，彼此相恨。"},
{volumn:        20,chapter:        15,versenumber:        18,verse:"暴怒的人挑启争端；忍怒的人止息纷争。"},
{volumn:        20,chapter:        15,versenumber:        19,verse:"懒惰人的道像荆棘的篱笆；正直人的路是平坦的大道。"},
{volumn:        20,chapter:        15,versenumber:        20,verse:"智慧子使父亲喜乐；愚昧人藐视母亲。"},
{volumn:        20,chapter:        15,versenumber:        21,verse:"无知的人以愚妄为乐；聪明的人按正直而行。"},
{volumn:        20,chapter:        15,versenumber:        22,verse:"不先商议，所谋无效；谋士众多，所谋乃成。"},
{volumn:        20,chapter:        15,versenumber:        23,verse:"口善应对，自觉喜乐；话合其时，何等美好。"},
{volumn:        20,chapter:        15,versenumber:        24,verse:"智慧人从生命的道上升，使他远离在下的阴间。"},
{volumn:        20,chapter:        15,versenumber:        25,verse:"耶和华必拆毁骄傲人的家，却要立定寡妇的地界。"},
{volumn:        20,chapter:        15,versenumber:        26,verse:"恶谋为耶和华所憎恶；良言乃为纯净。"},
{volumn:        20,chapter:        15,versenumber:        27,verse:"贪恋财利的，扰害己家；恨恶贿赂的，必得存活。"},
{volumn:        20,chapter:        15,versenumber:        28,verse:"义人的心，思量如何回答；恶人的口吐出恶言。"},
{volumn:        20,chapter:        15,versenumber:        29,verse:"耶和华远离恶人，却听义人的祷告。"},
{volumn:        20,chapter:        15,versenumber:        30,verse:"眼有光，使心喜乐；好信息，使骨滋润。"},
{volumn:        20,chapter:        15,versenumber:        31,verse:"听从生命责备的，必常在智慧人中。"},
{volumn:        20,chapter:        15,versenumber:        32,verse:"弃绝管教的，轻看自己的生命；听从责备的，却得智慧。"},
{volumn:        20,chapter:        15,versenumber:        33,verse:"敬畏耶和华是智慧的训诲；尊荣以前，必有谦卑。"},
{volumn:        20,chapter:        16,versenumber:         1,verse:"心中的谋算在乎人；舌头的应对由于耶和华。"},
{volumn:        20,chapter:        16,versenumber:         2,verse:"人一切所行的，在自己眼中看为清洁；惟有耶和华衡量人心。"},
{volumn:        20,chapter:        16,versenumber:         3,verse:"你所做的，要交托耶和华，你所谋的，就必成立。"},
{volumn:        20,chapter:        16,versenumber:         4,verse:"耶和华所造的，各适其用；就是恶人也为祸患的日子所造。"},
{volumn:        20,chapter:        16,versenumber:         5,verse:"凡心里骄傲的，为耶和华所憎恶；虽然连手，他必不免受罚。"},
{volumn:        20,chapter:        16,versenumber:         6,verse:"因怜悯诚实，罪孽得赎；敬畏耶和华的，远离恶事。"},
{volumn:        20,chapter:        16,versenumber:         7,verse:"人所行的，若蒙耶和华喜悦，耶和华也使他的仇敌与他和好。"},
{volumn:        20,chapter:        16,versenumber:         8,verse:"多有财利，行事不义，不如少有财利，行事公义。"},
{volumn:        20,chapter:        16,versenumber:         9,verse:"人心筹算自己的道路；惟耶和华指引他的脚步。"},
{volumn:        20,chapter:        16,versenumber:        10,verse:"王的嘴中有　神语，审判之时，他的口必不差错。"},
{volumn:        20,chapter:        16,versenumber:        11,verse:"公道的天平和秤都属耶和华；囊中一切法码都为他所定。"},
{volumn:        20,chapter:        16,versenumber:        12,verse:"作恶，为王所憎恶，因国位是靠公义坚立。"},
{volumn:        20,chapter:        16,versenumber:        13,verse:"公义的嘴为王所喜悦；说正直话的，为王所喜爱。"},
{volumn:        20,chapter:        16,versenumber:        14,verse:"王的震怒如杀人的使者；但智慧人能止息王怒。"},
{volumn:        20,chapter:        16,versenumber:        15,verse:"王的脸光使人有生命；王的恩典好像春云时雨。"},
{volumn:        20,chapter:        16,versenumber:        16,verse:"得智慧胜似得金子；选聪明强如选银子。"},
{volumn:        20,chapter:        16,versenumber:        17,verse:"正直人的道是远离恶事；谨守己路的，是保全性命。"},
{volumn:        20,chapter:        16,versenumber:        18,verse:"骄傲在败坏以先；狂心在跌倒之前。"},
{volumn:        20,chapter:        16,versenumber:        19,verse:"心里谦卑与穷乏人来往，强如将掳物与骄傲人同分。"},
{volumn:        20,chapter:        16,versenumber:        20,verse:"谨守训言的，必得好处；倚靠耶和华的，便为有福。"},
{volumn:        20,chapter:        16,versenumber:        21,verse:"心中有智慧，必称为通达人；嘴中的甜言，加增人的学问。"},
{volumn:        20,chapter:        16,versenumber:        22,verse:"人有智慧就有生命的泉源；愚昧人必被愚昧惩治。"},
{volumn:        20,chapter:        16,versenumber:        23,verse:"智慧人的心教训他的口，又使他的嘴增长学问。"},
{volumn:        20,chapter:        16,versenumber:        24,verse:"良言如同蜂房，使心觉甘甜，使骨得医治。"},
{volumn:        20,chapter:        16,versenumber:        25,verse:"有一条路，人以为正，至终成为死亡之路。"},
{volumn:        20,chapter:        16,versenumber:        26,verse:"劳力人的胃口使他劳力，因为他的口腹催逼他。"},
{volumn:        20,chapter:        16,versenumber:        27,verse:"匪徒图谋奸恶，嘴上仿佛有烧焦的火。"},
{volumn:        20,chapter:        16,versenumber:        28,verse:"乖僻人播散纷争；传舌的，离间密友。"},
{volumn:        20,chapter:        16,versenumber:        29,verse:"强暴人诱惑邻舍，领他走不善之道。"},
{volumn:        20,chapter:        16,versenumber:        30,verse:"眼目紧合的，图谋乖僻；嘴唇紧闭的，成就邪恶。"},
{volumn:        20,chapter:        16,versenumber:        31,verse:"白发是荣耀的冠冕，在公义的道上必能得着。"},
{volumn:        20,chapter:        16,versenumber:        32,verse:"不轻易发怒的，胜过勇士；治服己心的，强如取城。"},
{volumn:        20,chapter:        16,versenumber:        33,verse:"签放在怀里，定事由耶和华。"},
{volumn:        20,chapter:        17,versenumber:         1,verse:"设筵满屋，大家相争，不如有块干饼，大家相安。"},
{volumn:        20,chapter:        17,versenumber:         2,verse:"仆人办事聪明，必管辖贻羞之子，又在众子中同分产业。"},
{volumn:        20,chapter:        17,versenumber:         3,verse:"鼎为炼银，炉为炼金；惟有耶和华熬炼人心。"},
{volumn:        20,chapter:        17,versenumber:         4,verse:"行恶的，留心听奸诈之言；说谎的，侧耳听邪恶之语。"},
{volumn:        20,chapter:        17,versenumber:         5,verse:"戏笑穷人的，是辱没造他的主；幸灾乐祸的，必不免受罚。"},
{volumn:        20,chapter:        17,versenumber:         6,verse:"子孙为老人的冠冕；父亲是儿女的荣耀。"},
{volumn:        20,chapter:        17,versenumber:         7,verse:"愚顽人说美言本不相宜，何况君王说谎话呢？"},
{volumn:        20,chapter:        17,versenumber:         8,verse:"贿赂在馈送的人眼中看为宝玉，随处运动都得顺利。"},
{volumn:        20,chapter:        17,versenumber:         9,verse:"遮掩人过的，寻求人爱；屡次挑错的，离间密友。"},
{volumn:        20,chapter:        17,versenumber:        10,verse:"一句责备话深入聪明人的心，强如责打愚昧人一百下。"},
{volumn:        20,chapter:        17,versenumber:        11,verse:"恶人只寻背叛，所以必有严厉的使者奉差攻击他。"},
{volumn:        20,chapter:        17,versenumber:        12,verse:"宁可遇见丢崽子的母熊，不可遇见正行愚妄的愚昧人。"},
{volumn:        20,chapter:        17,versenumber:        13,verse:"以恶报善的，祸患必不离他的家。"},
{volumn:        20,chapter:        17,versenumber:        14,verse:"纷争的起头如水放开，所以，在争闹之先必当止息争竞。"},
{volumn:        20,chapter:        17,versenumber:        15,verse:"定恶人为义的，定义人为恶的，这都为耶和华所憎恶。"},
{volumn:        20,chapter:        17,versenumber:        16,verse:"愚昧人既无聪明，为何手拿价银买智慧呢？"},
{volumn:        20,chapter:        17,versenumber:        17,verse:"朋友乃时常亲爱，弟兄为患难而生。"},
{volumn:        20,chapter:        17,versenumber:        18,verse:"在邻舍面前击掌作保乃是无知的人。"},
{volumn:        20,chapter:        17,versenumber:        19,verse:"喜爱争竞的，是喜爱过犯；高立家门的，乃自取败坏。"},
{volumn:        20,chapter:        17,versenumber:        20,verse:"心存邪僻的，寻不着好处；舌弄是非的，陷在祸患中。"},
{volumn:        20,chapter:        17,versenumber:        21,verse:"生愚昧子的，必自愁苦；愚顽人的父毫无喜乐。"},
{volumn:        20,chapter:        17,versenumber:        22,verse:"喜乐的心乃是良药；忧伤的灵使骨枯干。"},
{volumn:        20,chapter:        17,versenumber:        23,verse:"恶人暗中受贿赂，为要颠倒判断。"},
{volumn:        20,chapter:        17,versenumber:        24,verse:"明哲人眼前有智慧；愚昧人眼望地极。"},
{volumn:        20,chapter:        17,versenumber:        25,verse:"愚昧子使父亲愁烦，使母亲忧苦。"},
{volumn:        20,chapter:        17,versenumber:        26,verse:"刑罚义人为不善；责打君子为不义。"},
{volumn:        20,chapter:        17,versenumber:        27,verse:"寡少言语的，有知识；性情温良的，有聪明。"},
{volumn:        20,chapter:        17,versenumber:        28,verse:"愚昧人若静默不言也可算为智慧；闭口不说也可算为聪明。"},
{volumn:        20,chapter:        18,versenumber:         1,verse:"与众寡合的，独自寻求心愿，并恼恨一切真智慧。"},
{volumn:        20,chapter:        18,versenumber:         2,verse:"愚昧人不喜爱明哲，只喜爱显露心意。"},
{volumn:        20,chapter:        18,versenumber:         3,verse:"恶人来，藐视随来；羞耻到，辱骂同到。"},
{volumn:        20,chapter:        18,versenumber:         4,verse:"人口中的言语如同深水；智慧的泉源好像涌流的河水。"},
{volumn:        20,chapter:        18,versenumber:         5,verse:"瞻徇恶人的情面，偏断义人的案件，都为不善。"},
{volumn:        20,chapter:        18,versenumber:         6,verse:"愚昧人张嘴启争端，开口招鞭打。"},
{volumn:        20,chapter:        18,versenumber:         7,verse:"愚昧人的口自取败坏；他的嘴是他生命的网罗。"},
{volumn:        20,chapter:        18,versenumber:         8,verse:"传舌人的言语如同美食，深入人的心腹。"},
{volumn:        20,chapter:        18,versenumber:         9,verse:"做工懈怠的，与浪费人为弟兄。"},
{volumn:        20,chapter:        18,versenumber:        10,verse:"耶和华的名是坚固台；义人奔入便得安稳。"},
{volumn:        20,chapter:        18,versenumber:        11,verse:"富足人的财物是他的坚城，在他心想，犹如高墙。"},
{volumn:        20,chapter:        18,versenumber:        12,verse:"败坏之先，人心骄傲；尊荣以前，必有谦卑。"},
{volumn:        20,chapter:        18,versenumber:        13,verse:"未曾听完先回答的，便是他的愚昧和羞辱。"},
{volumn:        20,chapter:        18,versenumber:        14,verse:"人有疾病，心能忍耐；心灵忧伤，谁能承当呢？"},
{volumn:        20,chapter:        18,versenumber:        15,verse:"聪明人的心得知识；智慧人的耳求知识。"},
{volumn:        20,chapter:        18,versenumber:        16,verse:"人的礼物为他开路，引他到高位的人面前。"},
{volumn:        20,chapter:        18,versenumber:        17,verse:"先诉情由的，似乎有理；但邻舍来到，就察出实情。"},
{volumn:        20,chapter:        18,versenumber:        18,verse:"掣签能止息争竞，也能解散强胜的人。"},
{volumn:        20,chapter:        18,versenumber:        19,verse:"弟兄结怨，劝他和好，比取坚固城还难；这样的争竞如同坚寨的门闩。"},
{volumn:        20,chapter:        18,versenumber:        20,verse:"人口中所结的果子，必充满肚腹；他嘴所出的，必使他饱足。"},
{volumn:        20,chapter:        18,versenumber:        21,verse:"生死在舌头的权下，喜爱它的，必吃它所结的果子。"},
{volumn:        20,chapter:        18,versenumber:        22,verse:"得着贤妻的，是得着好处，也是蒙了耶和华的恩惠。"},
{volumn:        20,chapter:        18,versenumber:        23,verse:"贫穷人说哀求的话；富足人用威吓的话回答。"},
{volumn:        20,chapter:        18,versenumber:        24,verse:"滥交朋友的，自取败坏；但有一朋友比弟兄更亲密。"},
{volumn:        20,chapter:        19,versenumber:         1,verse:"行为纯正的贫穷人胜过乖谬愚妄的富足人。"},
{volumn:        20,chapter:        19,versenumber:         2,verse:"心无知识的，乃为不善；脚步急快的，难免犯罪。"},
{volumn:        20,chapter:        19,versenumber:         3,verse:"人的愚昧倾败他的道；他的心也抱怨耶和华。"},
{volumn:        20,chapter:        19,versenumber:         4,verse:"财物使朋友增多；但穷人朋友远离。"},
{volumn:        20,chapter:        19,versenumber:         5,verse:"作假见证的，必不免受罚；吐出谎言的，终不能逃脱。"},
{volumn:        20,chapter:        19,versenumber:         6,verse:"好施散的，有多人求他的恩情；爱送礼的，人都为他的朋友。"},
{volumn:        20,chapter:        19,versenumber:         7,verse:"贫穷人，弟兄都恨他；何况他的朋友，更远离他！他用言语追随，他们却走了。"},
{volumn:        20,chapter:        19,versenumber:         8,verse:"得着智慧的，爱惜生命；保守聪明的，必得好处。"},
{volumn:        20,chapter:        19,versenumber:         9,verse:"作假见证的，不免受罚；吐出谎言的，也必灭亡。"},
{volumn:        20,chapter:        19,versenumber:        10,verse:"愚昧人宴乐度日是不合宜的；何况仆人管辖王子呢？"},
{volumn:        20,chapter:        19,versenumber:        11,verse:"人有见识就不轻易发怒；宽恕人的过失便是自己的荣耀。"},
{volumn:        20,chapter:        19,versenumber:        12,verse:"王的忿怒好像狮子吼叫；他的恩典却如草上的甘露。"},
{volumn:        20,chapter:        19,versenumber:        13,verse:"愚昧的儿子是父亲的祸患；妻子的争吵如雨连连滴漏。"},
{volumn:        20,chapter:        19,versenumber:        14,verse:"房屋钱财是祖宗所遗留的；惟有贤慧的妻是耶和华所赐的。"},
{volumn:        20,chapter:        19,versenumber:        15,verse:"懒惰使人沉睡；懈怠的人必受饥饿。"},
{volumn:        20,chapter:        19,versenumber:        16,verse:"谨守诫命的，保全生命；轻忽己路的，必致死亡。"},
{volumn:        20,chapter:        19,versenumber:        17,verse:"怜悯贫穷的，就是借给耶和华；他的善行，耶和华必偿还。"},
{volumn:        20,chapter:        19,versenumber:        18,verse:"趁有指望，管教你的儿子；你的心不可任他死亡。"},
{volumn:        20,chapter:        19,versenumber:        19,verse:"暴怒的人必受刑罚；你若救他，必须再救。"},
{volumn:        20,chapter:        19,versenumber:        20,verse:"你要听劝教，受训诲，使你终久有智慧。"},
{volumn:        20,chapter:        19,versenumber:        21,verse:"人心多有计谋；惟有耶和华的筹算才能立定。"},
{volumn:        20,chapter:        19,versenumber:        22,verse:"施行仁慈的，令人爱慕；穷人强如说谎言的。"},
{volumn:        20,chapter:        19,versenumber:        23,verse:"敬畏耶和华的，得着生命；他必恒久知足，不遭祸患。"},
{volumn:        20,chapter:        19,versenumber:        24,verse:"懒惰人放手在盘子里，就是向口撤回，他也不肯。"},
{volumn:        20,chapter:        19,versenumber:        25,verse:"鞭打亵慢人，愚蒙人必长见识；责备明哲人，他就明白知识。"},
{volumn:        20,chapter:        19,versenumber:        26,verse:"虐待父亲、撵出母亲的，是贻羞致辱之子。"},
{volumn:        20,chapter:        19,versenumber:        27,verse:"我儿，不可听了教训而又偏离知识的言语。"},
{volumn:        20,chapter:        19,versenumber:        28,verse:"匪徒作见证戏笑公平；恶人的口吞下罪孽。"},
{volumn:        20,chapter:        19,versenumber:        29,verse:"刑罚是为亵慢人预备的；鞭打是为愚昧人的背预备的。"},
{volumn:        20,chapter:        20,versenumber:         1,verse:"酒能使人亵慢，浓酒使人喧嚷；凡因酒错误的，就无智慧。"},
{volumn:        20,chapter:        20,versenumber:         2,verse:"王的威吓如同狮子吼叫；惹动他怒的，是自害己命。"},
{volumn:        20,chapter:        20,versenumber:         3,verse:"远离纷争是人的尊荣；愚妄人都爱争闹。"},
{volumn:        20,chapter:        20,versenumber:         4,verse:"懒惰人因冬寒不肯耕种，到收割的时候，他必讨饭而无所得。"},
{volumn:        20,chapter:        20,versenumber:         5,verse:"人心怀藏谋略，好像深水，惟明哲人才能汲引出来。"},
{volumn:        20,chapter:        20,versenumber:         6,verse:"人多述说自己的仁慈，但忠信人谁能遇着呢？"},
{volumn:        20,chapter:        20,versenumber:         7,verse:"行为纯正的义人，他的子孙是有福的！"},
{volumn:        20,chapter:        20,versenumber:         8,verse:"王坐在审判的位上，以眼目驱散诸恶。"},
{volumn:        20,chapter:        20,versenumber:         9,verse:"谁能说，我洁净了我的心，我脱净了我的罪？"},
{volumn:        20,chapter:        20,versenumber:        10,verse:"两样的法码，两样的升斗，都为耶和华所憎恶。"},
{volumn:        20,chapter:        20,versenumber:        11,verse:"孩童的动作是清洁，是正直，都显明他的本性。"},
{volumn:        20,chapter:        20,versenumber:        12,verse:"能听的耳，能看的眼，都是耶和华所造的。"},
{volumn:        20,chapter:        20,versenumber:        13,verse:"不要贪睡，免致贫穷；眼要睁开，你就吃饱。"},
{volumn:        20,chapter:        20,versenumber:        14,verse:"买物的说：“不好，不好”；及至买去，他便自夸。"},
{volumn:        20,chapter:        20,versenumber:        15,verse:"有金子和许多珍珠（或译：红宝石），惟有知识的嘴乃为贵重的珍宝。"},
{volumn:        20,chapter:        20,versenumber:        16,verse:"谁为生人作保，就拿谁的衣服；谁为外人作保，谁就要承当。"},
{volumn:        20,chapter:        20,versenumber:        17,verse:"以虚谎而得的食物，人觉甘甜；但后来，他的口必充满尘沙。"},
{volumn:        20,chapter:        20,versenumber:        18,verse:"计谋都凭筹算立定；打仗要凭智谋。"},
{volumn:        20,chapter:        20,versenumber:        19,verse:"往来传舌的，泄漏密事；大张嘴的，不可与他结交。"},
{volumn:        20,chapter:        20,versenumber:        20,verse:"咒骂父母的，他的灯必灭，变为漆黑的黑暗。"},
{volumn:        20,chapter:        20,versenumber:        21,verse:"起初速得的产业，终久却不为福。"},
{volumn:        20,chapter:        20,versenumber:        22,verse:"你不要说，我要以恶报恶；要等候耶和华，他必拯救你。"},
{volumn:        20,chapter:        20,versenumber:        23,verse:"两样的法码为耶和华所憎恶；诡诈的天平也为不善。"},
{volumn:        20,chapter:        20,versenumber:        24,verse:"人的脚步为耶和华所定；人岂能明白自己的路呢？"},
{volumn:        20,chapter:        20,versenumber:        25,verse:"人冒失说，这是圣物，许愿之后才查问，就是自陷网罗。"},
{volumn:        20,chapter:        20,versenumber:        26,verse:"智慧的王簸散恶人，用碌碡滚轧他们。"},
{volumn:        20,chapter:        20,versenumber:        27,verse:"人的灵是耶和华的灯，鉴察人的心腹。"},
{volumn:        20,chapter:        20,versenumber:        28,verse:"王因仁慈和诚实得以保全他的国位，也因仁慈立稳。"},
{volumn:        20,chapter:        20,versenumber:        29,verse:"强壮乃少年人的荣耀；白发为老年人的尊荣。"},
{volumn:        20,chapter:        20,versenumber:        30,verse:"鞭伤除净人的罪恶；责打能入人的心腹。"},
{volumn:        20,chapter:        21,versenumber:         1,verse:"王的心在耶和华手中，好像陇沟的水随意流转。"},
{volumn:        20,chapter:        21,versenumber:         2,verse:"人所行的，在自己眼中都看为正；惟有耶和华衡量人心。"},
{volumn:        20,chapter:        21,versenumber:         3,verse:"行仁义公平比献祭更蒙耶和华悦纳。"},
{volumn:        20,chapter:        21,versenumber:         4,verse:"恶人发达（发达：原文是灯），眼高心傲，这乃是罪。"},
{volumn:        20,chapter:        21,versenumber:         5,verse:"殷勤筹划的，足致丰裕；行事急躁的，都必缺乏。"},
{volumn:        20,chapter:        21,versenumber:         6,verse:"用诡诈之舌求财的，就是自己取死；所得之财乃是吹来吹去的浮云。"},
{volumn:        20,chapter:        21,versenumber:         7,verse:"恶人的强暴必将自己扫除，因他们不肯按公平行事。"},
{volumn:        20,chapter:        21,versenumber:         8,verse:"负罪之人的路甚是弯曲；至于清洁的人，他所行的乃是正直。"},
{volumn:        20,chapter:        21,versenumber:         9,verse:"宁可住在房顶的角上，不在宽阔的房屋与争吵的妇人同住。"},
{volumn:        20,chapter:        21,versenumber:        10,verse:"恶人的心乐人受祸；他眼并不怜恤邻舍。"},
{volumn:        20,chapter:        21,versenumber:        11,verse:"亵慢的人受刑罚，愚蒙的人就得智慧；智慧人受训诲，便得知识。"},
{volumn:        20,chapter:        21,versenumber:        12,verse:"义人思想恶人的家，知道恶人倾倒，必致灭亡。"},
{volumn:        20,chapter:        21,versenumber:        13,verse:"塞耳不听穷人哀求的，他将来呼吁也不蒙应允。"},
{volumn:        20,chapter:        21,versenumber:        14,verse:"暗中送的礼物挽回怒气；怀中搋的贿赂止息暴怒。"},
{volumn:        20,chapter:        21,versenumber:        15,verse:"秉公行义使义人喜乐，使作孽的人败坏。"},
{volumn:        20,chapter:        21,versenumber:        16,verse:"迷离通达道路的，必住在阴魂的会中。"},
{volumn:        20,chapter:        21,versenumber:        17,verse:"爱宴乐的，必致穷乏；好酒，爱膏油的，必不富足。"},
{volumn:        20,chapter:        21,versenumber:        18,verse:"恶人作了义人的赎价；奸诈人代替正直人。"},
{volumn:        20,chapter:        21,versenumber:        19,verse:"宁可住在旷野，不与争吵使气的妇人同住。"},
{volumn:        20,chapter:        21,versenumber:        20,verse:"智慧人家中积蓄宝物膏油；愚昧人随得来随吞下。"},
{volumn:        20,chapter:        21,versenumber:        21,verse:"追求公义仁慈的，就寻得生命、公义，和尊荣。"},
{volumn:        20,chapter:        21,versenumber:        22,verse:"智慧人爬上勇士的城墙，倾覆他所倚靠的坚垒。"},
{volumn:        20,chapter:        21,versenumber:        23,verse:"谨守口与舌的，就保守自己免受灾难。"},
{volumn:        20,chapter:        21,versenumber:        24,verse:"心骄气傲的人名叫亵慢；他行事狂妄，都出于骄傲。"},
{volumn:        20,chapter:        21,versenumber:        25,verse:"懒惰人的心愿将他杀害，因为他手不肯做工。"},
{volumn:        20,chapter:        21,versenumber:        26,verse:"有终日贪得无厌的；义人施舍而不吝惜。"},
{volumn:        20,chapter:        21,versenumber:        27,verse:"恶人的祭物是可憎的；何况他存恶意来献呢？"},
{volumn:        20,chapter:        21,versenumber:        28,verse:"作假见证的必灭亡；惟有听真情而言的，其言长存。"},
{volumn:        20,chapter:        21,versenumber:        29,verse:"恶人脸无羞耻；正直人行事坚定。"},
{volumn:        20,chapter:        21,versenumber:        30,verse:"没有人能以智慧、聪明、谋略敌挡耶和华。"},
{volumn:        20,chapter:        21,versenumber:        31,verse:"马是为打仗之日预备的；得胜乃在乎耶和华。"},
{volumn:        20,chapter:        22,versenumber:         1,verse:"美名胜过大财；恩宠强如金银。"},
{volumn:        20,chapter:        22,versenumber:         2,verse:"富户穷人在世相遇，都为耶和华所造。"},
{volumn:        20,chapter:        22,versenumber:         3,verse:"通达人见祸藏躲；愚蒙人前往受害。"},
{volumn:        20,chapter:        22,versenumber:         4,verse:"敬畏耶和华心存谦卑，就得富有、尊荣、生命为赏赐。"},
{volumn:        20,chapter:        22,versenumber:         5,verse:"乖僻人的路上有荆棘和网罗；保守自己生命的，必要远离。"},
{volumn:        20,chapter:        22,versenumber:         6,verse:"教养孩童，使他走当行的道，就是到老他也不偏离。"},
{volumn:        20,chapter:        22,versenumber:         7,verse:"富户管辖穷人；欠债的是债主的仆人。"},
{volumn:        20,chapter:        22,versenumber:         8,verse:"撒罪孽的，必收灾祸；他逞怒的杖也必废掉。"},
{volumn:        20,chapter:        22,versenumber:         9,verse:"眼目慈善的，就必蒙福，因他将食物分给穷人。"},
{volumn:        20,chapter:        22,versenumber:        10,verse:"赶出亵慢人，争端就消除；纷争和羞辱也必止息。"},
{volumn:        20,chapter:        22,versenumber:        11,verse:"喜爱清心的人因他嘴上的恩言，王必与他为友。"},
{volumn:        20,chapter:        22,versenumber:        12,verse:"耶和华的眼目眷顾聪明人，却倾败奸诈人的言语。"},
{volumn:        20,chapter:        22,versenumber:        13,verse:"懒惰人说：外头有狮子；我在街上就必被杀。"},
{volumn:        20,chapter:        22,versenumber:        14,verse:"淫妇的口为深坑；耶和华所憎恶的，必陷在其中。"},
{volumn:        20,chapter:        22,versenumber:        15,verse:"愚蒙迷住孩童的心，用管教的杖可以远远赶除。"},
{volumn:        20,chapter:        22,versenumber:        16,verse:"欺压贫穷为要利己的，并送礼与富户的，都必缺乏。"},
{volumn:        20,chapter:        22,versenumber:        17,verse:"你须侧耳听受智慧人的言语，留心领会我的知识。"},
{volumn:        20,chapter:        22,versenumber:        18,verse:"你若心中存记，嘴上咬定，这便为美。"},
{volumn:        20,chapter:        22,versenumber:        19,verse:"我今日以此特特指教你，为要使你倚靠耶和华。"},
{volumn:        20,chapter:        22,versenumber:        20,verse:"谋略和知识的美事，我岂没有写给你吗？"},
{volumn:        20,chapter:        22,versenumber:        21,verse:"要使你知道真言的实理，你好将真言回复那打发你来的人。"},
{volumn:        20,chapter:        22,versenumber:        22,verse:"贫穷人，你不可因他贫穷就抢夺他的物，也不可在城门口欺压困苦人；"},
{volumn:        20,chapter:        22,versenumber:        23,verse:"因耶和华必为他辨屈；抢夺他的，耶和华必夺取那人的命。"},
{volumn:        20,chapter:        22,versenumber:        24,verse:"好生气的人，不可与他结交；暴怒的人，不可与他来往；"},
{volumn:        20,chapter:        22,versenumber:        25,verse:"恐怕你效法他的行为，自己就陷在网罗里。"},
{volumn:        20,chapter:        22,versenumber:        26,verse:"不要与人击掌，不要为欠债的作保。"},
{volumn:        20,chapter:        22,versenumber:        27,verse:"你若没有什么偿还，何必使人夺去你睡卧的床呢？"},
{volumn:        20,chapter:        22,versenumber:        28,verse:"你先祖所立的地界，你不可挪移。"},
{volumn:        20,chapter:        22,versenumber:        29,verse:"你看见办事殷勤的人吗？他必站在君王面前，必不站在下贱人面前。"},
{volumn:        20,chapter:        23,versenumber:         1,verse:"你若与官长坐席，要留意在你面前的是谁。"},
{volumn:        20,chapter:        23,versenumber:         2,verse:"你若是贪食的，就当拿刀放在喉咙上。"},
{volumn:        20,chapter:        23,versenumber:         3,verse:"不可贪恋他的美食，因为是哄人的食物。"},
{volumn:        20,chapter:        23,versenumber:         4,verse:"不要劳碌求富，休仗自己的聪明。"},
{volumn:        20,chapter:        23,versenumber:         5,verse:"你岂要定睛在虚无的钱财上吗？因钱财必长翅膀，如鹰向天飞去。"},
{volumn:        20,chapter:        23,versenumber:         6,verse:"不要吃恶眼人的饭，也不要贪他的美味；"},
{volumn:        20,chapter:        23,versenumber:         7,verse:"因为他心怎样思量，他为人就是怎样。他虽对你说：“请吃，请喝”，他的心却与你相背。"},
{volumn:        20,chapter:        23,versenumber:         8,verse:"你所吃的那点食物必吐出来；你所说的甘美言语也必落空。"},
{volumn:        20,chapter:        23,versenumber:         9,verse:"你不要说话给愚昧人听，因他必藐视你智慧的言语。"},
{volumn:        20,chapter:        23,versenumber:        10,verse:"不可挪移古时的地界，也不可侵入孤儿的田地；"},
{volumn:        20,chapter:        23,versenumber:        11,verse:"因他们的救赎主大有能力，他必向你为他们辨屈。"},
{volumn:        20,chapter:        23,versenumber:        12,verse:"你要留心领受训诲，侧耳听从知识的言语。"},
{volumn:        20,chapter:        23,versenumber:        13,verse:"不可不管教孩童；你用杖打他，他必不至于死。"},
{volumn:        20,chapter:        23,versenumber:        14,verse:"你要用杖打他，就可以救他的灵魂免下阴间。"},
{volumn:        20,chapter:        23,versenumber:        15,verse:"我儿，你心若存智慧，我的心也甚欢喜。"},
{volumn:        20,chapter:        23,versenumber:        16,verse:"你的嘴若说正直话，我的心肠也必快乐。"},
{volumn:        20,chapter:        23,versenumber:        17,verse:"你心中不要嫉妒罪人，只要终日敬畏耶和华；"},
{volumn:        20,chapter:        23,versenumber:        18,verse:"因为至终必有善报，你的指望也不致断绝。"},
{volumn:        20,chapter:        23,versenumber:        19,verse:"我儿，你当听，当存智慧，好在正道上引导你的心。"},
{volumn:        20,chapter:        23,versenumber:        20,verse:"好饮酒的，好吃肉的，不要与他们来往；"},
{volumn:        20,chapter:        23,versenumber:        21,verse:"因为好酒贪食的，必致贫穷；好睡觉的，必穿破烂衣服。"},
{volumn:        20,chapter:        23,versenumber:        22,verse:"你要听从生你的父亲；你母亲老了，也不可藐视她。"},
{volumn:        20,chapter:        23,versenumber:        23,verse:"你当买真理；就是智慧、训诲，和聪明也都不可卖。"},
{volumn:        20,chapter:        23,versenumber:        24,verse:"义人的父亲必大得快乐；人生智慧的儿子，必因他欢喜。"},
{volumn:        20,chapter:        23,versenumber:        25,verse:"你要使父母欢喜，使生你的快乐。"},
{volumn:        20,chapter:        23,versenumber:        26,verse:"我儿，要将你的心归我；你的眼目也要喜悦我的道路。"},
{volumn:        20,chapter:        23,versenumber:        27,verse:"妓女是深坑；外女是窄阱。"},
{volumn:        20,chapter:        23,versenumber:        28,verse:"她埋伏好像强盗；她使人中多有奸诈的。"},
{volumn:        20,chapter:        23,versenumber:        29,verse:"谁有祸患？谁有忧愁？谁有争斗？谁有哀叹（或译：怨言）？谁无故受伤？谁眼目红赤？"},
{volumn:        20,chapter:        23,versenumber:        30,verse:"就是那流连饮酒、常去寻找调和酒的人。"},
{volumn:        20,chapter:        23,versenumber:        31,verse:"酒发红，在杯中闪烁，你不可观看，虽然下咽舒畅，终久是咬你如蛇，刺你如毒蛇。"},
{volumn:        20,chapter:        23,versenumber:        32,verse:""},
{volumn:        20,chapter:        23,versenumber:        33,verse:"你眼必看见异怪的事（或译：淫妇）；你心必发出乖谬的话。"},
{volumn:        20,chapter:        23,versenumber:        34,verse:"你必像躺在海中，或像卧在桅杆上。"},
{volumn:        20,chapter:        23,versenumber:        35,verse:"你必说：“人打我，我却未受伤；人鞭打我，我竟不觉得。我几时清醒，我仍去寻酒。”"},
{volumn:        20,chapter:        24,versenumber:         1,verse:"你不要嫉妒恶人，也不要起意与他们相处；"},
{volumn:        20,chapter:        24,versenumber:         2,verse:"因为，他们的心图谋强暴，他们的口谈论奸恶。"},
{volumn:        20,chapter:        24,versenumber:         3,verse:"房屋因智慧建造，又因聪明立稳；"},
{volumn:        20,chapter:        24,versenumber:         4,verse:"其中因知识充满各样美好宝贵的财物。"},
{volumn:        20,chapter:        24,versenumber:         5,verse:"智慧人大有能力；有知识的人力上加力。"},
{volumn:        20,chapter:        24,versenumber:         6,verse:"你去打仗，要凭智谋；谋士众多，人便得胜。"},
{volumn:        20,chapter:        24,versenumber:         7,verse:"智慧极高，非愚昧人所能及，所以在城门内不敢开口。"},
{volumn:        20,chapter:        24,versenumber:         8,verse:"设计作恶的，必称为奸人。"},
{volumn:        20,chapter:        24,versenumber:         9,verse:"愚妄人的思念乃是罪恶；亵慢者为人所憎恶。"},
{volumn:        20,chapter:        24,versenumber:        10,verse:"你在患难之日若胆怯，你的力量就微小。"},
{volumn:        20,chapter:        24,versenumber:        11,verse:"人被拉到死地，你要解救；人将被杀，你须拦阻。"},
{volumn:        20,chapter:        24,versenumber:        12,verse:"你若说：“这事我未曾知道”，那衡量人心的岂不明白吗？保守你命的岂不知道吗？他岂不按各人所行的报应各人吗？"},
{volumn:        20,chapter:        24,versenumber:        13,verse:"我儿，你要吃蜜，因为是好的；吃蜂房下滴的蜜便觉甘甜。"},
{volumn:        20,chapter:        24,versenumber:        14,verse:"你心得了智慧，也必觉得如此。你若找着，至终必有善报；你的指望也不致断绝。"},
{volumn:        20,chapter:        24,versenumber:        15,verse:"你这恶人，不要埋伏攻击义人的家；不要毁坏他安居之所。"},
{volumn:        20,chapter:        24,versenumber:        16,verse:"因为，义人虽七次跌倒，仍必兴起；恶人却被祸患倾倒。"},
{volumn:        20,chapter:        24,versenumber:        17,verse:"你仇敌跌倒，你不要欢喜；他倾倒，你心不要快乐；"},
{volumn:        20,chapter:        24,versenumber:        18,verse:"恐怕耶和华看见就不喜悦，将怒气从仇敌身上转过来。"},
{volumn:        20,chapter:        24,versenumber:        19,verse:"不要为作恶的心怀不平，也不要嫉妒恶人；"},
{volumn:        20,chapter:        24,versenumber:        20,verse:"因为，恶人终不得善报；恶人的灯也必熄灭。"},
{volumn:        20,chapter:        24,versenumber:        21,verse:"我儿，你要敬畏耶和华与君王，不要与反复无常的人结交，"},
{volumn:        20,chapter:        24,versenumber:        22,verse:"因为他们的灾难必忽然而起。耶和华与君王所施行的毁灭，谁能知道呢？"},
{volumn:        20,chapter:        24,versenumber:        23,verse:"以下也是智慧人的箴言：审判时看人情面是不好的。"},
{volumn:        20,chapter:        24,versenumber:        24,verse:"对恶人说“你是义人”的，这人万民必咒诅，列邦必憎恶。"},
{volumn:        20,chapter:        24,versenumber:        25,verse:"责备恶人的，必得喜悦；美好的福也必临到他。"},
{volumn:        20,chapter:        24,versenumber:        26,verse:"应对正直的，犹如与人亲嘴。"},
{volumn:        20,chapter:        24,versenumber:        27,verse:"你要在外头预备工料，在田间办理整齐，然后建造房屋。"},
{volumn:        20,chapter:        24,versenumber:        28,verse:"不可无故作见证陷害邻舍，也不可用嘴欺骗人。"},
{volumn:        20,chapter:        24,versenumber:        29,verse:"不可说：“人怎样待我，我也怎样待他；我必照他所行的报复他。”"},
{volumn:        20,chapter:        24,versenumber:        30,verse:"我经过懒惰人的田地、无知人的葡萄园，"},
{volumn:        20,chapter:        24,versenumber:        31,verse:"荆棘长满了地皮，刺草遮盖了田面，石墙也坍塌了。"},
{volumn:        20,chapter:        24,versenumber:        32,verse:"我看见就留心思想；我看着就领了训诲。"},
{volumn:        20,chapter:        24,versenumber:        33,verse:"再睡片时，打盹片时，抱着手躺卧片时，"},
{volumn:        20,chapter:        24,versenumber:        34,verse:"你的贫穷就必如强盗速来，你的缺乏仿佛拿兵器的人来到。"},
{volumn:        20,chapter:        25,versenumber:         1,verse:"以下也是所罗门的箴言，是犹大王希西家的人所誊录的。"},
{volumn:        20,chapter:        25,versenumber:         2,verse:"将事隐秘乃　神的荣耀；将事察清乃君王的荣耀。"},
{volumn:        20,chapter:        25,versenumber:         3,verse:"天之高，地之厚，君王之心也测不透。"},
{volumn:        20,chapter:        25,versenumber:         4,verse:"除去银子的渣滓就有银子出来，银匠能以做器皿。"},
{volumn:        20,chapter:        25,versenumber:         5,verse:"除去王面前的恶人，国位就靠公义坚立。"},
{volumn:        20,chapter:        25,versenumber:         6,verse:"不要在王面前妄自尊大；不要在大人的位上站立。"},
{volumn:        20,chapter:        25,versenumber:         7,verse:"宁可有人说：请你上来，强如在你觐见的王子面前叫你退下。"},
{volumn:        20,chapter:        25,versenumber:         8,verse:"不要冒失出去与人争竞，免得至终被他羞辱，你就不知道怎样行了。"},
{volumn:        20,chapter:        25,versenumber:         9,verse:"你与邻舍争讼，要与他一人辩论，不可泄漏人的密事，"},
{volumn:        20,chapter:        25,versenumber:        10,verse:"恐怕听见的人骂你，你的臭名就难以脱离。"},
{volumn:        20,chapter:        25,versenumber:        11,verse:"一句话说得合宜，就如金苹果在银网子里。"},
{volumn:        20,chapter:        25,versenumber:        12,verse:"智慧人的劝戒，在顺从的人耳中，好像金耳环和精金的妆饰。"},
{volumn:        20,chapter:        25,versenumber:        13,verse:"忠信的使者叫差他的人心里舒畅，就如在收割时有冰雪的凉气。"},
{volumn:        20,chapter:        25,versenumber:        14,verse:"空夸赠送礼物的，好像无雨的风云。"},
{volumn:        20,chapter:        25,versenumber:        15,verse:"恒常忍耐可以劝动君王；柔和的舌头能折断骨头。"},
{volumn:        20,chapter:        25,versenumber:        16,verse:"你得了蜜吗？只可吃够而已，恐怕你过饱就呕吐出来。"},
{volumn:        20,chapter:        25,versenumber:        17,verse:"你的脚要少进邻舍的家，恐怕他厌烦你，恨恶你。"},
{volumn:        20,chapter:        25,versenumber:        18,verse:"作假见证陷害邻舍的，就是大槌，是利刀，是快箭。"},
{volumn:        20,chapter:        25,versenumber:        19,verse:"患难时倚靠不忠诚的人，好像破坏的牙，错骨缝的脚。"},
{volumn:        20,chapter:        25,versenumber:        20,verse:"对伤心的人唱歌，就如冷天脱衣服，又如碱上倒醋。"},
{volumn:        20,chapter:        25,versenumber:        21,verse:"你的仇敌若饿了，就给他饭吃；若渴了，就给他水喝；"},
{volumn:        20,chapter:        25,versenumber:        22,verse:"因为，你这样行就是把炭火堆在他的头上；耶和华也必赏赐你。"},
{volumn:        20,chapter:        25,versenumber:        23,verse:"北风生雨，谗谤人的舌头也生怒容。"},
{volumn:        20,chapter:        25,versenumber:        24,verse:"宁可住在房顶的角上，不在宽阔的房屋与争吵的妇人同住。"},
{volumn:        20,chapter:        25,versenumber:        25,verse:"有好消息从远方来，就如拿凉水给口渴的人喝。"},
{volumn:        20,chapter:        25,versenumber:        26,verse:"义人在恶人面前退缩，好像趟浑之泉，弄浊之井。"},
{volumn:        20,chapter:        25,versenumber:        27,verse:"吃蜜过多是不好的；考究自己的荣耀也是可厌的。"},
{volumn:        20,chapter:        25,versenumber:        28,verse:"人不制伏自己的心，好像毁坏的城邑没有墙垣。"},
{volumn:        20,chapter:        26,versenumber:         1,verse:"夏天落雪，收割时下雨，都不相宜；愚昧人得尊荣也是如此。"},
{volumn:        20,chapter:        26,versenumber:         2,verse:"麻雀往来，燕子翻飞；这样，无故的咒诅也必不临到。"},
{volumn:        20,chapter:        26,versenumber:         3,verse:"鞭子是为打马，辔头是为勒驴；刑杖是为打愚昧人的背。"},
{volumn:        20,chapter:        26,versenumber:         4,verse:"不要照愚昧人的愚妄话回答他，恐怕你与他一样。"},
{volumn:        20,chapter:        26,versenumber:         5,verse:"要照愚昧人的愚妄话回答他，免得他自以为有智慧。"},
{volumn:        20,chapter:        26,versenumber:         6,verse:"藉愚昧人手寄信的，是砍断自己的脚，自受（原文是：喝）损害。"},
{volumn:        20,chapter:        26,versenumber:         7,verse:"瘸子的脚空存无用；箴言在愚昧人的口中也是如此。"},
{volumn:        20,chapter:        26,versenumber:         8,verse:"将尊荣给愚昧人的，好像人把石子包在机弦里。"},
{volumn:        20,chapter:        26,versenumber:         9,verse:"箴言在愚昧人的口中，好像荆棘刺入醉汉的手。"},
{volumn:        20,chapter:        26,versenumber:        10,verse:"雇愚昧人的，与雇过路人的，就像射伤众人的弓箭手。"},
{volumn:        20,chapter:        26,versenumber:        11,verse:"愚昧人行愚妄事，行了又行，就如狗转过来吃它所吐的。"},
{volumn:        20,chapter:        26,versenumber:        12,verse:"你见自以为有智慧的人吗？愚昧人比他更有指望。"},
{volumn:        20,chapter:        26,versenumber:        13,verse:"懒惰人说：“道上有猛狮，街上有壮狮。”"},
{volumn:        20,chapter:        26,versenumber:        14,verse:"门在枢纽转动，懒惰人在床上也是如此。"},
{volumn:        20,chapter:        26,versenumber:        15,verse:"懒惰人放手在盘子里，就是向口撤回也以为劳乏。"},
{volumn:        20,chapter:        26,versenumber:        16,verse:"懒惰人看自己比七个善于应对的人更有智慧。"},
{volumn:        20,chapter:        26,versenumber:        17,verse:"过路被事激动，管理不干己的争竞，好像人揪住狗耳。"},
{volumn:        20,chapter:        26,versenumber:        18,verse:"人欺凌邻舍，却说：“我岂不是戏耍吗？”他就像疯狂的人抛掷火把、利箭，与杀人的兵器（原文是死亡）。"},
{volumn:        20,chapter:        26,versenumber:        19,verse:""},
{volumn:        20,chapter:        26,versenumber:        20,verse:"火缺了柴就必熄灭；无人传舌，争竞便止息。"},
{volumn:        20,chapter:        26,versenumber:        21,verse:"好争竞的人煽惑争端，就如余火加炭，火上加柴一样。"},
{volumn:        20,chapter:        26,versenumber:        22,verse:"传舌人的言语，如同美食，深入人的心腹。"},
{volumn:        20,chapter:        26,versenumber:        23,verse:"火热的嘴，奸恶的心，好像银渣包的瓦器。"},
{volumn:        20,chapter:        26,versenumber:        24,verse:"怨恨人的，用嘴粉饰，心里却藏着诡诈；"},
{volumn:        20,chapter:        26,versenumber:        25,verse:"他用甜言蜜语，你不可信他，因为他心中有七样可憎恶的。"},
{volumn:        20,chapter:        26,versenumber:        26,verse:"他虽用诡诈遮掩自己的怨恨，他的邪恶必在会中显露。"},
{volumn:        20,chapter:        26,versenumber:        27,verse:"挖陷坑的，自己必掉在其中；滚石头的，石头必反滚在他身上。"},
{volumn:        20,chapter:        26,versenumber:        28,verse:"虚谎的舌恨他所压伤的人；谄媚的口败坏人的事。"},
{volumn:        20,chapter:        27,versenumber:         1,verse:"不要为明日自夸，因为一日要生何事，你尚且不能知道。"},
{volumn:        20,chapter:        27,versenumber:         2,verse:"要别人夸奖你，不可用口自夸；等外人称赞你，不可用嘴自称。"},
{volumn:        20,chapter:        27,versenumber:         3,verse:"石头重，沙土沉，愚妄人的恼怒比这两样更重。"},
{volumn:        20,chapter:        27,versenumber:         4,verse:"忿怒为残忍，怒气为狂澜，惟有嫉妒，谁能敌得住呢？"},
{volumn:        20,chapter:        27,versenumber:         5,verse:"当面的责备强如背地的爱情。"},
{volumn:        20,chapter:        27,versenumber:         6,verse:"朋友加的伤痕出于忠诚；仇敌连连亲嘴却是多余。"},
{volumn:        20,chapter:        27,versenumber:         7,verse:"人吃饱了，厌恶蜂房的蜜；人饥饿了，一切苦物都觉甘甜。"},
{volumn:        20,chapter:        27,versenumber:         8,verse:"人离本处飘流，好像雀鸟离窝游飞。"},
{volumn:        20,chapter:        27,versenumber:         9,verse:"膏油与香料使人心喜悦；朋友诚实的劝教也是如此甘美。"},
{volumn:        20,chapter:        27,versenumber:        10,verse:"你的朋友和父亲的朋友，你都不可离弃。你遭难的日子，不要上弟兄的家去；相近的邻舍强如远方的弟兄。"},
{volumn:        20,chapter:        27,versenumber:        11,verse:"我儿，你要作智慧人，好叫我的心欢喜，使我可以回答那讥诮我的人。"},
{volumn:        20,chapter:        27,versenumber:        12,verse:"通达人见祸藏躲；愚蒙人前往受害。"},
{volumn:        20,chapter:        27,versenumber:        13,verse:"谁为生人作保，就拿谁的衣服；谁为外女作保，谁就承当。"},
{volumn:        20,chapter:        27,versenumber:        14,verse:"清晨起来，大声给朋友祝福的，就算是咒诅他。"},
{volumn:        20,chapter:        27,versenumber:        15,verse:"大雨之日连连滴漏，和争吵的妇人一样；"},
{volumn:        20,chapter:        27,versenumber:        16,verse:"想拦阻她的，便是拦阻风，也是右手抓油。"},
{volumn:        20,chapter:        27,versenumber:        17,verse:"铁磨铁，磨出刃来；朋友相感（原文是磨朋友的脸）也是如此。"},
{volumn:        20,chapter:        27,versenumber:        18,verse:"看守无花果树的，必吃树上的果子；敬奉主人的，必得尊荣。"},
{volumn:        20,chapter:        27,versenumber:        19,verse:"水中照脸，彼此相符；人与人，心也相对。"},
{volumn:        20,chapter:        27,versenumber:        20,verse:"阴间和灭亡永不满足；人的眼目也是如此。"},
{volumn:        20,chapter:        27,versenumber:        21,verse:"鼎为炼银，炉为炼金，人的称赞也试炼人。"},
{volumn:        20,chapter:        27,versenumber:        22,verse:"你虽用杵将愚妄人与打碎的麦子一同捣在臼中，他的愚妄还是离不了他。"},
{volumn:        20,chapter:        27,versenumber:        23,verse:"你要详细知道你羊群的景况，留心料理你的牛群；"},
{volumn:        20,chapter:        27,versenumber:        24,verse:"因为资财不能永有，冠冕岂能存到万代？"},
{volumn:        20,chapter:        27,versenumber:        25,verse:"干草割去，嫩草发现，山上的菜蔬也被收敛。"},
{volumn:        20,chapter:        27,versenumber:        26,verse:"羊羔之毛是为你作衣服；山羊是为作田地的价值，"},
{volumn:        20,chapter:        27,versenumber:        27,verse:"并有母山羊奶够你吃，也够你的家眷吃，且够养你的婢女。"},
{volumn:        20,chapter:        28,versenumber:         1,verse:"恶人虽无人追赶也逃跑；义人却胆壮像狮子。"},
{volumn:        20,chapter:        28,versenumber:         2,verse:"邦国因有罪过，君王就多更换；因有聪明知识的人，国必长存。"},
{volumn:        20,chapter:        28,versenumber:         3,verse:"穷人欺压贫民，好像暴雨冲没粮食。"},
{volumn:        20,chapter:        28,versenumber:         4,verse:"违弃律法的，夸奖恶人；遵守律法的，却与恶人相争。"},
{volumn:        20,chapter:        28,versenumber:         5,verse:"坏人不明白公义；惟有寻求耶和华的，无不明白。"},
{volumn:        20,chapter:        28,versenumber:         6,verse:"行为纯正的穷乏人胜过行事乖僻的富足人。"},
{volumn:        20,chapter:        28,versenumber:         7,verse:"谨守律法的，是智慧之子；与贪食人作伴的，却羞辱其父。"},
{volumn:        20,chapter:        28,versenumber:         8,verse:"人以厚利加增财物，是给那怜悯穷人者积蓄的。"},
{volumn:        20,chapter:        28,versenumber:         9,verse:"转耳不听律法的，他的祈祷也为可憎。"},
{volumn:        20,chapter:        28,versenumber:        10,verse:"诱惑正直人行恶道的，必掉在自己的坑里；惟有完全人必承受福分。"},
{volumn:        20,chapter:        28,versenumber:        11,verse:"富足人自以为有智慧，但聪明的贫穷人能将他查透。"},
{volumn:        20,chapter:        28,versenumber:        12,verse:"义人得志，有大荣耀；恶人兴起，人就躲藏。"},
{volumn:        20,chapter:        28,versenumber:        13,verse:"遮掩自己罪过的，必不亨通；承认离弃罪过的，必蒙怜恤。"},
{volumn:        20,chapter:        28,versenumber:        14,verse:"常存敬畏的，便为有福；心存刚硬的，必陷在祸患里。"},
{volumn:        20,chapter:        28,versenumber:        15,verse:"暴虐的君王辖制贫民，好像吼叫的狮子、觅食的熊。"},
{volumn:        20,chapter:        28,versenumber:        16,verse:"无知的君多行暴虐；以贪财为可恨的，必年长日久。"},
{volumn:        20,chapter:        28,versenumber:        17,verse:"背负流人血之罪的，必往坑里奔跑，谁也不可拦阻他。"},
{volumn:        20,chapter:        28,versenumber:        18,verse:"行动正直的，必蒙拯救；行事弯曲的，立时跌倒。"},
{volumn:        20,chapter:        28,versenumber:        19,verse:"耕种自己田地的，必得饱食；追随虚浮的，足受穷乏。"},
{volumn:        20,chapter:        28,versenumber:        20,verse:"诚实人必多得福；想要急速发财的，不免受罚。"},
{volumn:        20,chapter:        28,versenumber:        21,verse:"看人的情面乃为不好；人因一块饼枉法也为不好。"},
{volumn:        20,chapter:        28,versenumber:        22,verse:"人有恶眼想要急速发财，却不知穷乏必临到他身。"},
{volumn:        20,chapter:        28,versenumber:        23,verse:"责备人的，后来蒙人喜悦，多于那用舌头谄媚人的。"},
{volumn:        20,chapter:        28,versenumber:        24,verse:"偷窃父母的，说：“这不是罪”，此人就是与强盗同类。"},
{volumn:        20,chapter:        28,versenumber:        25,verse:"心中贪婪的，挑起争端；倚靠耶和华的，必得丰裕。"},
{volumn:        20,chapter:        28,versenumber:        26,verse:"心中自是的，便是愚昧人；凭智慧行事的，必蒙拯救。"},
{volumn:        20,chapter:        28,versenumber:        27,verse:"周济贫穷的，不致缺乏；佯为不见的，必多受咒诅。"},
{volumn:        20,chapter:        28,versenumber:        28,verse:"恶人兴起，人就躲藏；恶人败亡，义人增多。"},
{volumn:        20,chapter:        29,versenumber:         1,verse:"人屡次受责罚，仍然硬着颈项；他必顷刻败坏，无法可治。"},
{volumn:        20,chapter:        29,versenumber:         2,verse:"义人增多，民就喜乐；恶人掌权，民就叹息。"},
{volumn:        20,chapter:        29,versenumber:         3,verse:"爱慕智慧的，使父亲喜乐；与妓女结交的，却浪费钱财。"},
{volumn:        20,chapter:        29,versenumber:         4,verse:"王藉公平，使国坚定；索要贿赂，使国倾败。"},
{volumn:        20,chapter:        29,versenumber:         5,verse:"谄媚邻舍的，就是设网罗绊他的脚。"},
{volumn:        20,chapter:        29,versenumber:         6,verse:"恶人犯罪，自陷网罗；惟独义人欢呼喜乐。"},
{volumn:        20,chapter:        29,versenumber:         7,verse:"义人知道查明穷人的案；恶人没有聪明，就不得而知。"},
{volumn:        20,chapter:        29,versenumber:         8,verse:"亵慢人煽惑通城；智慧人止息众怒。"},
{volumn:        20,chapter:        29,versenumber:         9,verse:"智慧人与愚妄人相争，或怒或笑，总不能使他止息。"},
{volumn:        20,chapter:        29,versenumber:        10,verse:"好流人血的，恨恶完全人，索取正直人的性命。"},
{volumn:        20,chapter:        29,versenumber:        11,verse:"愚妄人怒气全发；智慧人忍气含怒。"},
{volumn:        20,chapter:        29,versenumber:        12,verse:"君王若听谎言，他一切臣仆都是奸恶。"},
{volumn:        20,chapter:        29,versenumber:        13,verse:"贫穷人、强暴人在世相遇；他们的眼目都蒙耶和华光照。"},
{volumn:        20,chapter:        29,versenumber:        14,verse:"君王凭诚实判断穷人；他的国位必永远坚立。"},
{volumn:        20,chapter:        29,versenumber:        15,verse:"杖打和责备能加增智慧；放纵的儿子使母亲羞愧。"},
{volumn:        20,chapter:        29,versenumber:        16,verse:"恶人加多，过犯也加多，义人必看见他们跌倒。"},
{volumn:        20,chapter:        29,versenumber:        17,verse:"管教你的儿子，他就使你得安息，也必使你心里喜乐。"},
{volumn:        20,chapter:        29,versenumber:        18,verse:"没有异象（或译：默示），民就放肆；惟遵守律法的，便为有福。"},
{volumn:        20,chapter:        29,versenumber:        19,verse:"只用言语，仆人不肯受管教；他虽然明白，也不留意。"},
{volumn:        20,chapter:        29,versenumber:        20,verse:"你见言语急躁的人吗？愚昧人比他更有指望。"},
{volumn:        20,chapter:        29,versenumber:        21,verse:"人将仆人从小娇养，这仆人终久必成了他的儿子。"},
{volumn:        20,chapter:        29,versenumber:        22,verse:"好气的人挑启争端；暴怒的人多多犯罪。"},
{volumn:        20,chapter:        29,versenumber:        23,verse:"人的高傲必使他卑下；心里谦逊的，必得尊荣。"},
{volumn:        20,chapter:        29,versenumber:        24,verse:"人与盗贼分赃，是恨恶自己的性命；他听见叫人发誓的声音，却不言语。"},
{volumn:        20,chapter:        29,versenumber:        25,verse:"惧怕人的，陷入网罗；惟有倚靠耶和华的，必得安稳。"},
{volumn:        20,chapter:        29,versenumber:        26,verse:"求王恩的人多；定人事乃在耶和华。"},
{volumn:        20,chapter:        29,versenumber:        27,verse:"为非作歹的，被义人憎嫌；行事正直的，被恶人憎恶。"},
{volumn:        20,chapter:        30,versenumber:         1,verse:"雅基的儿子亚古珥的言语就是真言。这人对以铁和乌甲说："},
{volumn:        20,chapter:        30,versenumber:         2,verse:"我比众人更蠢笨，也没有人的聪明。"},
{volumn:        20,chapter:        30,versenumber:         3,verse:"我没有学好智慧，也不认识至圣者。"},
{volumn:        20,chapter:        30,versenumber:         4,verse:"谁升天又降下来？谁聚风在掌握中？谁包水在衣服里？谁立定地的四极？他名叫什么？他儿子名叫什么？你知道吗？"},
{volumn:        20,chapter:        30,versenumber:         5,verse:"神的言语句句都是炼净的；投靠他的，他便作他们的盾牌。"},
{volumn:        20,chapter:        30,versenumber:         6,verse:"他的言语，你不可加添，恐怕他责备你，你就显为说谎言的。"},
{volumn:        20,chapter:        30,versenumber:         7,verse:"我求你两件事，在我未死之先，不要不赐给我："},
{volumn:        20,chapter:        30,versenumber:         8,verse:"求你使虚假和谎言远离我；使我也不贫穷也不富足；赐给我需用的饮食，"},
{volumn:        20,chapter:        30,versenumber:         9,verse:"恐怕我饱足不认你，说：耶和华是谁呢？又恐怕我贫穷就偷窃，以致亵渎我　神的名。"},
{volumn:        20,chapter:        30,versenumber:        10,verse:"你不要向主人谗谤仆人，恐怕他咒诅你，你便算为有罪。"},
{volumn:        20,chapter:        30,versenumber:        11,verse:"有一宗人（宗：原文是代；下同），咒诅父亲，不给母亲祝福。"},
{volumn:        20,chapter:        30,versenumber:        12,verse:"有一宗人，自以为清洁，却没有洗去自己的污秽。"},
{volumn:        20,chapter:        30,versenumber:        13,verse:"有一宗人，眼目何其高傲，眼皮也是高举。"},
{volumn:        20,chapter:        30,versenumber:        14,verse:"有一宗人，牙如剑，齿如刀，要吞灭地上的困苦人和世间的穷乏人。"},
{volumn:        20,chapter:        30,versenumber:        15,verse:"蚂蟥有两个女儿，常说：“给呀，给呀！”有三样不知足的，连不说“够的”共有四样："},
{volumn:        20,chapter:        30,versenumber:        16,verse:"就是阴间和石胎，浸水不足的地，并火。"},
{volumn:        20,chapter:        30,versenumber:        17,verse:"戏笑父亲、藐视而不听从母亲的，他的眼睛必为谷中的乌鸦啄出来，为鹰雏所吃。"},
{volumn:        20,chapter:        30,versenumber:        18,verse:"我所测不透的奇妙有三样，连我所不知道的共有四样："},
{volumn:        20,chapter:        30,versenumber:        19,verse:"就是鹰在空中飞的道；蛇在磐石上爬的道；船在海中行的道；男与女交合的道。"},
{volumn:        20,chapter:        30,versenumber:        20,verse:"淫妇的道也是这样：她吃了，把嘴一擦就说：“我没有行恶。”"},
{volumn:        20,chapter:        30,versenumber:        21,verse:"使地震动的有三样，连地担不起的共有四样："},
{volumn:        20,chapter:        30,versenumber:        22,verse:"就是仆人作王；愚顽人吃饱；"},
{volumn:        20,chapter:        30,versenumber:        23,verse:"丑恶的女子出嫁；婢女接续主母。"},
{volumn:        20,chapter:        30,versenumber:        24,verse:"地上有四样小物，却甚聪明："},
{volumn:        20,chapter:        30,versenumber:        25,verse:"蚂蚁是无力之类，却在夏天预备粮食。"},
{volumn:        20,chapter:        30,versenumber:        26,verse:"沙番是软弱之类，却在磐石中造房。"},
{volumn:        20,chapter:        30,versenumber:        27,verse:"蝗虫没有君王，却分队而出。"},
{volumn:        20,chapter:        30,versenumber:        28,verse:"守宫用爪抓墙，却住在王宫。"},
{volumn:        20,chapter:        30,versenumber:        29,verse:"步行威武的有三样，连行走威武的共有四样："},
{volumn:        20,chapter:        30,versenumber:        30,verse:"就是狮子乃百兽中最为猛烈、无所躲避的，"},
{volumn:        20,chapter:        30,versenumber:        31,verse:"猎狗，公山羊，和无人能敌的君王。"},
{volumn:        20,chapter:        30,versenumber:        32,verse:"你若行事愚顽，自高自傲，或是怀了恶念，就当用手捂口。"},
{volumn:        20,chapter:        30,versenumber:        33,verse:"摇牛奶必成奶油；扭鼻子必出血。照样，激动怒气必起争端。"},
{volumn:        20,chapter:        31,versenumber:         1,verse:"利慕伊勒王的言语，是他母亲教训他的真言。"},
{volumn:        20,chapter:        31,versenumber:         2,verse:"我的儿啊，我腹中生的儿啊，我许愿得的儿啊！我当怎样教训你呢？"},
{volumn:        20,chapter:        31,versenumber:         3,verse:"不要将你的精力给妇女；也不要有败坏君王的行为。"},
{volumn:        20,chapter:        31,versenumber:         4,verse:"利慕伊勒啊，君王喝酒，君王喝酒不相宜；王子说浓酒在那里也不相宜；"},
{volumn:        20,chapter:        31,versenumber:         5,verse:"恐怕喝了就忘记律例，颠倒一切困苦人的是非。"},
{volumn:        20,chapter:        31,versenumber:         6,verse:"可以把浓酒给将亡的人喝，把清酒给苦心的人喝，"},
{volumn:        20,chapter:        31,versenumber:         7,verse:"让他喝了，就忘记他的贫穷，不再记念他的苦楚。"},
{volumn:        20,chapter:        31,versenumber:         8,verse:"你当为哑巴（或译：不能自辩的）开口，为一切孤独的伸冤。"},
{volumn:        20,chapter:        31,versenumber:         9,verse:"你当开口按公义判断，为困苦和穷乏的辨屈。"},
{volumn:        20,chapter:        31,versenumber:        10,verse:"才德的妇人谁能得着呢？她的价值远胜过珍珠。"},
{volumn:        20,chapter:        31,versenumber:        11,verse:"她丈夫心里倚靠她，必不缺少利益；"},
{volumn:        20,chapter:        31,versenumber:        12,verse:"她一生使丈夫有益无损。"},
{volumn:        20,chapter:        31,versenumber:        13,verse:"她寻找羊绒和麻，甘心用手做工。"},
{volumn:        20,chapter:        31,versenumber:        14,verse:"她好像商船从远方运粮来，"},
{volumn:        20,chapter:        31,versenumber:        15,verse:"未到黎明她就起来，把食物分给家中的人，将当做的工分派婢女。"},
{volumn:        20,chapter:        31,versenumber:        16,verse:"她想得田地就买来；用手所得之利栽种葡萄园。"},
{volumn:        20,chapter:        31,versenumber:        17,verse:"她以能力束腰，使膀臂有力。"},
{volumn:        20,chapter:        31,versenumber:        18,verse:"她觉得所经营的有利；她的灯终夜不灭。"},
{volumn:        20,chapter:        31,versenumber:        19,verse:"她手拿捻线竿，手把纺线车。"},
{volumn:        20,chapter:        31,versenumber:        20,verse:"她张手周济困苦人，伸手帮补穷乏人。"},
{volumn:        20,chapter:        31,versenumber:        21,verse:"她不因下雪为家里的人担心，因为全家都穿着朱红衣服。"},
{volumn:        20,chapter:        31,versenumber:        22,verse:"她为自己制作绣花毯子；她的衣服是细麻和紫色布做的。"},
{volumn:        20,chapter:        31,versenumber:        23,verse:"她丈夫在城门口与本地的长老同坐，为众人所认识。"},
{volumn:        20,chapter:        31,versenumber:        24,verse:"她做细麻布衣裳出卖，又将腰带卖与商家。"},
{volumn:        20,chapter:        31,versenumber:        25,verse:"能力和威仪是她的衣服；她想到日后的景况就喜笑。"},
{volumn:        20,chapter:        31,versenumber:        26,verse:"她开口就发智慧；她舌上有仁慈的法则。"},
{volumn:        20,chapter:        31,versenumber:        27,verse:"她观察家务，并不吃闲饭。"},
{volumn:        20,chapter:        31,versenumber:        28,verse:"她的儿女起来称她有福；她的丈夫也称赞她，"},
{volumn:        20,chapter:        31,versenumber:        29,verse:"说：“才德的女子很多，惟独你超过一切！”"},
{volumn:        20,chapter:        31,versenumber:        30,verse:"艳丽是虚假的，美容是虚浮的；惟敬畏耶和华的妇女必得称赞。"},
{volumn:        20,chapter:        31,versenumber:        31,verse:"愿她享受操作所得的；愿她的工作在城门口荣耀她。"},
{volumn:        21,chapter:         1,versenumber:         1,verse:"在耶路撒冷作王、大卫的儿子、传道者的言语。"},
{volumn:        21,chapter:         1,versenumber:         2,verse:"传道者说：虚空的虚空，虚空的虚空，凡事都是虚空。"},
{volumn:        21,chapter:         1,versenumber:         3,verse:"人一切的劳碌，就是他在日光之下的劳碌，有什么益处呢？"},
{volumn:        21,chapter:         1,versenumber:         4,verse:"一代过去，一代又来，地却永远长存。"},
{volumn:        21,chapter:         1,versenumber:         5,verse:"日头出来，日头落下，急归所出之地。"},
{volumn:        21,chapter:         1,versenumber:         6,verse:"风往南刮，又向北转，不住地旋转，而且返回转行原道。"},
{volumn:        21,chapter:         1,versenumber:         7,verse:"江河都往海里流，海却不满；江河从何处流，仍归还何处。"},
{volumn:        21,chapter:         1,versenumber:         8,verse:"万事令人厌烦（或译：万物满有困乏），人不能说尽。眼看，看不饱；耳听，听不足。"},
{volumn:        21,chapter:         1,versenumber:         9,verse:"已有的事后必再有；已行的事后必再行。日光之下并无新事。"},
{volumn:        21,chapter:         1,versenumber:        10,verse:"岂有一件事人能指着说这是新的？哪知，在我们以前的世代早已有了。"},
{volumn:        21,chapter:         1,versenumber:        11,verse:"已过的世代，无人记念；将来的世代，后来的人也不记念。"},
{volumn:        21,chapter:         1,versenumber:        12,verse:"我传道者在耶路撒冷作过以色列的王。"},
{volumn:        21,chapter:         1,versenumber:        13,verse:"我专心用智慧寻求、查究天下所做的一切事，乃知　神叫世人所经练的是极重的劳苦。"},
{volumn:        21,chapter:         1,versenumber:        14,verse:"我见日光之下所做的一切事，都是虚空，都是捕风。"},
{volumn:        21,chapter:         1,versenumber:        15,verse:"弯曲的，不能变直；缺少的，不能足数。"},
{volumn:        21,chapter:         1,versenumber:        16,verse:"我心里议论说：我得了大智慧，胜过我以前在耶路撒冷的众人，而且我心中多经历智慧和知识的事。"},
{volumn:        21,chapter:         1,versenumber:        17,verse:"我又专心察明智慧、狂妄，和愚昧，乃知这也是捕风。"},
{volumn:        21,chapter:         1,versenumber:        18,verse:"因为多有智慧，就多有愁烦；加增知识的，就加增忧伤。"},
{volumn:        21,chapter:         2,versenumber:         1,verse:"我心里说：“来吧，我以喜乐试试你，你好享福！”谁知，这也是虚空。"},
{volumn:        21,chapter:         2,versenumber:         2,verse:"我指嬉笑说：“这是狂妄。”论喜乐说：“有何功效呢？”"},
{volumn:        21,chapter:         2,versenumber:         3,verse:"我心里察究，如何用酒使我肉体舒畅，我心却仍以智慧引导我；又如何持住愚昧，等我看明世人，在天下一生当行何事为美。"},
{volumn:        21,chapter:         2,versenumber:         4,verse:"我为自己动大工程，建造房屋，栽种葡萄园，"},
{volumn:        21,chapter:         2,versenumber:         5,verse:"修造园囿，在其中栽种各样果木树；"},
{volumn:        21,chapter:         2,versenumber:         6,verse:"挖造水池，用以浇灌嫩小的树木。"},
{volumn:        21,chapter:         2,versenumber:         7,verse:"我买了仆婢，也有生在家中的仆婢；又有许多牛群羊群，胜过以前在耶路撒冷众人所有的。"},
{volumn:        21,chapter:         2,versenumber:         8,verse:"我又为自己积蓄金银和君王的财宝，并各省的财宝；又得唱歌的男女和世人所喜爱的物，并许多的妃嫔。"},
{volumn:        21,chapter:         2,versenumber:         9,verse:"这样，我就日见昌盛，胜过以前在耶路撒冷的众人。我的智慧仍然存留。"},
{volumn:        21,chapter:         2,versenumber:        10,verse:"凡我眼所求的，我没有留下不给它的；我心所乐的，我没有禁止不享受的；因我的心为我一切所劳碌的快乐，这就是我从劳碌中所得的份。"},
{volumn:        21,chapter:         2,versenumber:        11,verse:"后来，我察看我手所经营的一切事和我劳碌所成的功。谁知都是虚空，都是捕风；在日光之下毫无益处。"},
{volumn:        21,chapter:         2,versenumber:        12,verse:"我转念观看智慧、狂妄，和愚昧。在王以后而来的人还能做什么呢？也不过行早先所行的就是了。"},
{volumn:        21,chapter:         2,versenumber:        13,verse:"我便看出智慧胜过愚昧，如同光明胜过黑暗。"},
{volumn:        21,chapter:         2,versenumber:        14,verse:"智慧人的眼目光明（光明：原文是在他头上），愚昧人在黑暗里行。我却看明有一件事，这两等人都必遇见。"},
{volumn:        21,chapter:         2,versenumber:        15,verse:"我就心里说：“愚昧人所遇见的，我也必遇见，我为何更有智慧呢？”我心里说，这也是虚空。"},
{volumn:        21,chapter:         2,versenumber:        16,verse:"智慧人和愚昧人一样，永远无人记念，因为日后都被忘记；可叹智慧人死亡，与愚昧人无异。"},
{volumn:        21,chapter:         2,versenumber:        17,verse:"我所以恨恶生命；因为在日光之下所行的事我都以为烦恼，都是虚空，都是捕风。"},
{volumn:        21,chapter:         2,versenumber:        18,verse:"我恨恶一切的劳碌，就是我在日光之下的劳碌，因为我得来的必留给我以后的人。"},
{volumn:        21,chapter:         2,versenumber:        19,verse:"那人是智慧是愚昧，谁能知道？他竟要管理我劳碌所得的，就是我在日光之下用智慧所得的。这也是虚空。"},
{volumn:        21,chapter:         2,versenumber:        20,verse:"故此，我转想我在日光之下所劳碌的一切工作，心便绝望。"},
{volumn:        21,chapter:         2,versenumber:        21,verse:"因为有人用智慧、知识、灵巧所劳碌得来的，却要留给未曾劳碌的人为份。这也是虚空，也是大患。"},
{volumn:        21,chapter:         2,versenumber:        22,verse:"人在日光之下劳碌累心，在他一切的劳碌上得着什么呢？"},
{volumn:        21,chapter:         2,versenumber:        23,verse:"因为他日日忧虑，他的劳苦成为愁烦，连夜间心也不安。这也是虚空。"},
{volumn:        21,chapter:         2,versenumber:        24,verse:"人莫强如吃喝，且在劳碌中享福，我看这也是出于　神的手。"},
{volumn:        21,chapter:         2,versenumber:        25,verse:"论到吃用、享福，谁能胜过我呢？"},
{volumn:        21,chapter:         2,versenumber:        26,verse:"神喜悦谁，就给谁智慧、知识，和喜乐；惟有罪人，　神使他劳苦，叫他将所收聚的、所堆积的归给　神所喜悦的人。这也是虚空，也是捕风。"},
{volumn:        21,chapter:         3,versenumber:         1,verse:"凡事都有定期，天下万务都有定时。"},
{volumn:        21,chapter:         3,versenumber:         2,verse:"生有时，死有时；栽种有时，拔出所栽种的也有时；"},
{volumn:        21,chapter:         3,versenumber:         3,verse:"杀戮有时，医治有时；拆毁有时，建造有时；"},
{volumn:        21,chapter:         3,versenumber:         4,verse:"哭有时，笑有时；哀恸有时，跳舞有时；"},
{volumn:        21,chapter:         3,versenumber:         5,verse:"抛掷石头有时，堆聚石头有时；怀抱有时，不怀抱有时；"},
{volumn:        21,chapter:         3,versenumber:         6,verse:"寻找有时，失落有时；保守有时，舍弃有时；"},
{volumn:        21,chapter:         3,versenumber:         7,verse:"撕裂有时，缝补有时；静默有时，言语有时；"},
{volumn:        21,chapter:         3,versenumber:         8,verse:"喜爱有时，恨恶有时；争战有时，和好有时。"},
{volumn:        21,chapter:         3,versenumber:         9,verse:"这样看来，做事的人在他的劳碌上有什么益处呢？"},
{volumn:        21,chapter:         3,versenumber:        10,verse:"我见　神叫世人劳苦，使他们在其中受经练。"},
{volumn:        21,chapter:         3,versenumber:        11,verse:"神造万物，各按其时成为美好，又将永生（原文是永远）安置在世人心里。然而　神从始至终的作为，人不能参透。"},
{volumn:        21,chapter:         3,versenumber:        12,verse:"我知道世人，莫强如终身喜乐行善；"},
{volumn:        21,chapter:         3,versenumber:        13,verse:"并且人人吃喝，在他一切劳碌中享福，这也是　神的恩赐。"},
{volumn:        21,chapter:         3,versenumber:        14,verse:"我知道　神一切所做的都必永存；无所增添，无所减少。　神这样行，是要人在他面前存敬畏的心。"},
{volumn:        21,chapter:         3,versenumber:        15,verse:"现今的事早先就有了，将来的事早已也有了，并且　神使已过的事重新再来（或译：并且　神再寻回已过的事）。"},
{volumn:        21,chapter:         3,versenumber:        16,verse:"我又见日光之下，在审判之处有奸恶，在公义之处也有奸恶。"},
{volumn:        21,chapter:         3,versenumber:        17,verse:"我心里说，　神必审判义人和恶人；因为在那里，各样事务，一切工作，都有定时。"},
{volumn:        21,chapter:         3,versenumber:        18,verse:"我心里说，这乃为世人的缘故，是　神要试验他们，使他们觉得自己不过像兽一样。"},
{volumn:        21,chapter:         3,versenumber:        19,verse:"因为世人遭遇的，兽也遭遇，所遭遇的都是一样：这个怎样死，那个也怎样死，气息都是一样。人不能强于兽，都是虚空。"},
{volumn:        21,chapter:         3,versenumber:        20,verse:"都归一处，都是出于尘土，也都归于尘土。"},
{volumn:        21,chapter:         3,versenumber:        21,verse:"谁知道人的灵是往上升，兽的魂是下入地呢？"},
{volumn:        21,chapter:         3,versenumber:        22,verse:"故此，我见人莫强如在他经营的事上喜乐，因为这是他的份。他身后的事谁能使他回来得见呢？"},
{volumn:        21,chapter:         4,versenumber:         1,verse:"我又转念，见日光之下所行的一切欺压。看哪，受欺压的流泪，且无人安慰；欺压他们的有势力，也无人安慰他们。"},
{volumn:        21,chapter:         4,versenumber:         2,verse:"因此，我赞叹那早已死的死人，胜过那还活着的活人。"},
{volumn:        21,chapter:         4,versenumber:         3,verse:"并且我以为那未曾生的，就是未见过日光之下恶事的，比这两等人更强。"},
{volumn:        21,chapter:         4,versenumber:         4,verse:"我又见人为一切的劳碌和各样灵巧的工作就被邻舍嫉妒。这也是虚空，也是捕风。"},
{volumn:        21,chapter:         4,versenumber:         5,verse:"愚昧人抱着手，吃自己的肉。"},
{volumn:        21,chapter:         4,versenumber:         6,verse:"满了一把，得享安静，强如满了两把，劳碌捕风。"},
{volumn:        21,chapter:         4,versenumber:         7,verse:"我又转念，见日光之下有一件虚空的事："},
{volumn:        21,chapter:         4,versenumber:         8,verse:"有人孤单无二，无子无兄，竟劳碌不息，眼目也不以钱财为足。他说：“我劳劳碌碌，刻苦自己，不享福乐，到底是为谁呢？”这也是虚空，是极重的劳苦。"},
{volumn:        21,chapter:         4,versenumber:         9,verse:"两个人总比一个人好，因为二人劳碌同得美好的果效。"},
{volumn:        21,chapter:         4,versenumber:        10,verse:"若是跌倒，这人可以扶起他的同伴；若是孤身跌倒，没有别人扶起他来，这人就有祸了。"},
{volumn:        21,chapter:         4,versenumber:        11,verse:"再者，二人同睡就都暖和，一人独睡怎能暖和呢？"},
{volumn:        21,chapter:         4,versenumber:        12,verse:"有人攻胜孤身一人，若有二人便能敌挡他；三股合成的绳子不容易折断。"},
{volumn:        21,chapter:         4,versenumber:        13,verse:"贫穷而有智慧的少年人胜过年老不肯纳谏的愚昧王。"},
{volumn:        21,chapter:         4,versenumber:        14,verse:"这人是从监牢中出来作王，在他国中，生来原是贫穷的。"},
{volumn:        21,chapter:         4,versenumber:        15,verse:"我见日光之下一切行动的活人都随从那第二位，就是起来代替老王的少年人。"},
{volumn:        21,chapter:         4,versenumber:        16,verse:"他所治理的众人就是他的百姓，多得无数；在他后来的人尚且不喜悦他。这真是虚空，也是捕风。"},
{volumn:        21,chapter:         5,versenumber:         1,verse:"你到　神的殿要谨慎脚步；因为近前听，胜过愚昧人献祭（或译：胜过献愚昧人的祭），他们本不知道所做的是恶。"},
{volumn:        21,chapter:         5,versenumber:         2,verse:"你在　神面前不可冒失开口，也不可心急发言；因为　神在天上，你在地下，所以你的言语要寡少。"},
{volumn:        21,chapter:         5,versenumber:         3,verse:"事务多，就令人做梦；言语多，就显出愚昧。"},
{volumn:        21,chapter:         5,versenumber:         4,verse:"你向　神许愿，偿还不可迟延，因他不喜悦愚昧人，所以你许的愿应当偿还。"},
{volumn:        21,chapter:         5,versenumber:         5,verse:"你许愿不还，不如不许。"},
{volumn:        21,chapter:         5,versenumber:         6,verse:"不可任你的口使肉体犯罪，也不可在祭司（原文是使者）面前说是错许了。为何使　神因你的声音发怒，败坏你手所做的呢？"},
{volumn:        21,chapter:         5,versenumber:         7,verse:"多梦和多言，其中多有虚幻，你只要敬畏　神。"},
{volumn:        21,chapter:         5,versenumber:         8,verse:"你若在一省之中见穷人受欺压，并夺去公义公平的事，不要因此诧异；因有一位高过居高位的鉴察，在他们以上还有更高的。"},
{volumn:        21,chapter:         5,versenumber:         9,verse:"况且地的益处归众人，就是君王也受田地的供应。"},
{volumn:        21,chapter:         5,versenumber:        10,verse:"贪爱银子的，不因得银子知足；贪爱丰富的，也不因得利益知足。这也是虚空。"},
{volumn:        21,chapter:         5,versenumber:        11,verse:"货物增添，吃的人也增添，物主得什么益处呢？不过眼看而已！"},
{volumn:        21,chapter:         5,versenumber:        12,verse:"劳碌的人不拘吃多吃少，睡得香甜；富足人的丰满却不容他睡觉。"},
{volumn:        21,chapter:         5,versenumber:        13,verse:"我见日光之下有一宗大祸患，就是财主积存资财，反害自己。"},
{volumn:        21,chapter:         5,versenumber:        14,verse:"因遭遇祸患，这些资财就消灭；那人若生了儿子，手里也一无所有。"},
{volumn:        21,chapter:         5,versenumber:        15,verse:"他怎样从母胎赤身而来，也必照样赤身而去；他所劳碌得来的，手中分毫不能带去。"},
{volumn:        21,chapter:         5,versenumber:        16,verse:"他来的情形怎样，他去的情形也怎样。这也是一宗大祸患。他为风劳碌有什么益处呢？"},
{volumn:        21,chapter:         5,versenumber:        17,verse:"并且他终身在黑暗中吃喝，多有烦恼，又有病患呕气。"},
{volumn:        21,chapter:         5,versenumber:        18,verse:"我所见为善为美的，就是人在　神赐他一生的日子吃喝，享受日光之下劳碌得来的好处，因为这是他的分。"},
{volumn:        21,chapter:         5,versenumber:        19,verse:"神赐人资财丰富，使他能以吃用，能取自己的分，在他劳碌中喜乐，这乃是　神的恩赐。"},
{volumn:        21,chapter:         5,versenumber:        20,verse:"他不多思念自己一生的年日，因为　神应他的心使他喜乐。"},
{volumn:        21,chapter:         6,versenumber:         1,verse:"我见日光之下有一宗祸患重压在人身上，"},
{volumn:        21,chapter:         6,versenumber:         2,verse:"就是人蒙　神赐他资财、丰富、尊荣，以致他心里所愿的一样都不缺，只是　神使他不能吃用，反有外人来吃用。这是虚空，也是祸患。"},
{volumn:        21,chapter:         6,versenumber:         3,verse:"人若生一百个儿子，活许多岁数，以致他的年日甚多，心里却不得满享福乐，又不得埋葬；据我说，那不到期而落的胎比他倒好。"},
{volumn:        21,chapter:         6,versenumber:         4,verse:"因为虚虚而来，暗暗而去，名字被黑暗遮蔽，"},
{volumn:        21,chapter:         6,versenumber:         5,verse:"并且没有见过天日，也毫无知觉；这胎，比那人倒享安息。"},
{volumn:        21,chapter:         6,versenumber:         6,verse:"那人虽然活千年，再活千年，却不享福，众人岂不都归一个地方去吗？"},
{volumn:        21,chapter:         6,versenumber:         7,verse:"人的劳碌都为口腹，心里却不知足。"},
{volumn:        21,chapter:         6,versenumber:         8,verse:"这样看来，智慧人比愚昧人有什么长处呢？穷人在众人面前知道如何行，有什么长处呢？"},
{volumn:        21,chapter:         6,versenumber:         9,verse:"眼睛所看的比心里妄想的倒好。这也是虚空，也是捕风。"},
{volumn:        21,chapter:         6,versenumber:        10,verse:"先前所有的，早已起了名，并知道何为人，他也不能与那比自己力大的相争。"},
{volumn:        21,chapter:         6,versenumber:        11,verse:"加增虚浮的事既多，这与人有什么益处呢？"},
{volumn:        21,chapter:         6,versenumber:        12,verse:"人一生虚度的日子，就如影儿经过，谁知道什么与他有益呢？谁能告诉他身后在日光之下有什么事呢？"},
{volumn:        21,chapter:         7,versenumber:         1,verse:"名誉强如美好的膏油；人死的日子胜过人生的日子。"},
{volumn:        21,chapter:         7,versenumber:         2,verse:"往遭丧的家去，强如往宴乐的家去；因为死是众人的结局，活人也必将这事放在心上。"},
{volumn:        21,chapter:         7,versenumber:         3,verse:"忧愁强如喜笑；因为面带愁容，终必使心喜乐。"},
{volumn:        21,chapter:         7,versenumber:         4,verse:"智慧人的心在遭丧之家；愚昧人的心在快乐之家。"},
{volumn:        21,chapter:         7,versenumber:         5,verse:"听智慧人的责备，强如听愚昧人的歌唱。"},
{volumn:        21,chapter:         7,versenumber:         6,verse:"愚昧人的笑声，好像锅下烧荆棘的爆声；这也是虚空。"},
{volumn:        21,chapter:         7,versenumber:         7,verse:"勒索使智慧人变为愚妄；贿赂能败坏人的慧心。"},
{volumn:        21,chapter:         7,versenumber:         8,verse:"事情的终局强如事情的起头；存心忍耐的，胜过居心骄傲的。"},
{volumn:        21,chapter:         7,versenumber:         9,verse:"你不要心里急躁恼怒，因为恼怒存在愚昧人的怀中。"},
{volumn:        21,chapter:         7,versenumber:        10,verse:"不要说：先前的日子强过如今的日子，是什么缘故呢？你这样问，不是出于智慧。"},
{volumn:        21,chapter:         7,versenumber:        11,verse:"智慧和产业并好，而且见天日的人得智慧更为有益。"},
{volumn:        21,chapter:         7,versenumber:        12,verse:"因为智慧护庇人，好像银钱护庇人一样。惟独智慧能保全智慧人的生命。这就是知识的益处。"},
{volumn:        21,chapter:         7,versenumber:        13,verse:"你要察看　神的作为；因　神使为曲的，谁能变为直呢？"},
{volumn:        21,chapter:         7,versenumber:        14,verse:"遇亨通的日子你当喜乐；遭患难的日子你当思想；因为　神使这两样并列，为的是叫人查不出身后有什么事。"},
{volumn:        21,chapter:         7,versenumber:        15,verse:"有义人行义，反致灭亡；有恶人行恶，倒享长寿。这都是我在虚度之日中所见过的。"},
{volumn:        21,chapter:         7,versenumber:        16,verse:"不要行义过分，也不要过于自逞智慧，何必自取败亡呢？"},
{volumn:        21,chapter:         7,versenumber:        17,verse:"不要行恶过分，也不要为人愚昧，何必不到期而死呢？"},
{volumn:        21,chapter:         7,versenumber:        18,verse:"你持守这个为美，那个也不要松手；因为敬畏　神的人，必从这两样出来。"},
{volumn:        21,chapter:         7,versenumber:        19,verse:"智慧使有智慧的人比城中十个官长更有能力。"},
{volumn:        21,chapter:         7,versenumber:        20,verse:"时常行善而不犯罪的义人，世上实在没有。"},
{volumn:        21,chapter:         7,versenumber:        21,verse:"人所说的一切话，你不要放在心上，恐怕听见你的仆人咒诅你。"},
{volumn:        21,chapter:         7,versenumber:        22,verse:"因为你心里知道，自己也曾屡次咒诅别人。"},
{volumn:        21,chapter:         7,versenumber:        23,verse:"我曾用智慧试验这一切事；我说，要得智慧，智慧却离我远。"},
{volumn:        21,chapter:         7,versenumber:        24,verse:"万事之理，离我甚远，而且最深，谁能测透呢？"},
{volumn:        21,chapter:         7,versenumber:        25,verse:"我转念，一心要知道，要考察，要寻求智慧和万事的理由；又要知道邪恶为愚昧，愚昧为狂妄。"},
{volumn:        21,chapter:         7,versenumber:        26,verse:"我得知有等妇人比死还苦：她的心是网罗，手是锁链。凡蒙　神喜悦的人必能躲避她；有罪的人却被她缠住了。"},
{volumn:        21,chapter:         7,versenumber:        27,verse:"传道者说：“看哪，一千男子中，我找到一个正直人，但众女子中，没有找到一个。”我将这事一一比较，要寻求其理，我心仍要寻找，却未曾找到。"},
{volumn:        21,chapter:         7,versenumber:        28,verse:""},
{volumn:        21,chapter:         7,versenumber:        29,verse:"我所找到的只有一件，就是　神造人原是正直，但他们寻出许多巧计。"},
{volumn:        21,chapter:         8,versenumber:         1,verse:"谁如智慧人呢？谁知道事情的解释呢？人的智慧使他的脸发光，并使他脸上的暴气改变。"},
{volumn:        21,chapter:         8,versenumber:         2,verse:"我劝你遵守王的命令；既指　神起誓，理当如此。"},
{volumn:        21,chapter:         8,versenumber:         3,verse:"不要急躁离开王的面前，不要固执行恶，因为他凡事都随自己的心意而行。"},
{volumn:        21,chapter:         8,versenumber:         4,verse:"王的话本有权力，谁敢问他说“你做什么”呢？"},
{volumn:        21,chapter:         8,versenumber:         5,verse:"凡遵守命令的，必不经历祸患；智慧人的心能辨明时候和定理（原文是审判；下节同）。"},
{volumn:        21,chapter:         8,versenumber:         6,verse:"各样事务成就都有时候和定理，因为人的苦难重压在他身上。"},
{volumn:        21,chapter:         8,versenumber:         7,verse:"他不知道将来的事，因为将来如何，谁能告诉他呢？"},
{volumn:        21,chapter:         8,versenumber:         8,verse:"无人有权力掌管生命，将生命留住；也无人有权力掌管死期；这场争战，无人能免；邪恶也不能救那好行邪恶的人。"},
{volumn:        21,chapter:         8,versenumber:         9,verse:"这一切我都见过，也专心查考日光之下所做的一切事。有时这人管辖那人，令人受害。"},
{volumn:        21,chapter:         8,versenumber:        10,verse:"我见恶人埋葬，归入坟墓；又见行正直事的离开圣地，在城中被人忘记。这也是虚空。"},
{volumn:        21,chapter:         8,versenumber:        11,verse:"因为断定罪名不立刻施刑，所以世人满心作恶。"},
{volumn:        21,chapter:         8,versenumber:        12,verse:"罪人虽然作恶百次，倒享长久的年日；然而我准知道，敬畏　神的，就是在他面前敬畏的人，终久必得福乐。"},
{volumn:        21,chapter:         8,versenumber:        13,verse:"恶人却不得福乐，也不得长久的年日；这年日好像影儿，因他不敬畏　神。"},
{volumn:        21,chapter:         8,versenumber:        14,verse:"世上有一件虚空的事，就是义人所遭遇的，反照恶人所行的；又有恶人所遭遇的，反照义人所行的。我说，这也是虚空。"},
{volumn:        21,chapter:         8,versenumber:        15,verse:"我就称赞快乐，原来人在日光之下，莫强如吃喝快乐；因为他在日光之下，　神赐他一生的年日，要从劳碌中，时常享受所得的。"},
{volumn:        21,chapter:         8,versenumber:        16,verse:"我专心求智慧，要看世上所做的事。（有昼夜不睡觉不合眼的。）"},
{volumn:        21,chapter:         8,versenumber:        17,verse:"我就看明　神一切的作为，知道人查不出日光之下所做的事；任凭他费多少力寻查，都查不出来，就是智慧人虽想知道，也是查不出来。"},
{volumn:        21,chapter:         9,versenumber:         1,verse:"我将这一切事放在心上，详细考究，就知道义人和智慧人，并他们的作为都在　神手中；或是爱，或是恨，都在他们的前面，人不能知道。"},
{volumn:        21,chapter:         9,versenumber:         2,verse:"凡临到众人的事都是一样：义人和恶人都遭遇一样的事；好人，洁净人和不洁净人，献祭的与不献祭的，也是一样。好人如何，罪人也如何；起誓的如何，怕起誓的也如何。"},
{volumn:        21,chapter:         9,versenumber:         3,verse:"在日光之下所行的一切事上有一件祸患，就是众人所遭遇的都是一样，并且世人的心充满了恶；活着的时候心里狂妄，后来就归死人那里去了。"},
{volumn:        21,chapter:         9,versenumber:         4,verse:"与一切活人相连的，那人还有指望，因为活着的狗比死了的狮子更强。"},
{volumn:        21,chapter:         9,versenumber:         5,verse:"活着的人知道必死；死了的人毫无所知，也不再得赏赐；他们的名无人记念。"},
{volumn:        21,chapter:         9,versenumber:         6,verse:"他们的爱，他们的恨，他们的嫉妒，早都消灭了。在日光之下所行的一切事上，他们永不再有份了。"},
{volumn:        21,chapter:         9,versenumber:         7,verse:"你只管去欢欢喜喜吃你的饭，心中快乐喝你的酒，因为　神已经悦纳你的作为。"},
{volumn:        21,chapter:         9,versenumber:         8,verse:"你的衣服当时常洁白，你头上也不要缺少膏油。"},
{volumn:        21,chapter:         9,versenumber:         9,verse:"在你一生虚空的年日，就是　神赐你在日光之下虚空的年日，当同你所爱的妻，快活度日，因为那是你生前在日光之下劳碌的事上所得的份。"},
{volumn:        21,chapter:         9,versenumber:        10,verse:"凡你手所当做的事要尽力去做；因为在你所必去的阴间没有工作，没有谋算，没有知识，也没有智慧。"},
{volumn:        21,chapter:         9,versenumber:        11,verse:"我又转念：见日光之下，快跑的未必能赢；力战的未必得胜；智慧的未必得粮食；明哲的未必得资财；灵巧的未必得喜悦。所临到众人的是在乎当时的机会。"},
{volumn:        21,chapter:         9,versenumber:        12,verse:"原来人也不知道自己的定期。鱼被恶网圈住，鸟被网罗捉住，祸患忽然临到的时候，世人陷在其中也是如此。"},
{volumn:        21,chapter:         9,versenumber:        13,verse:"我见日光之下有一样智慧，据我看乃是广大，"},
{volumn:        21,chapter:         9,versenumber:        14,verse:"就是有一小城，其中的人数稀少，有大君王来攻击，修筑营垒，将城围困。"},
{volumn:        21,chapter:         9,versenumber:        15,verse:"城中有一个贫穷的智慧人，他用智慧救了那城，却没有人记念那穷人。"},
{volumn:        21,chapter:         9,versenumber:        16,verse:"我就说，智慧胜过勇力；然而那贫穷人的智慧被人藐视，他的话也无人听从。"},
{volumn:        21,chapter:         9,versenumber:        17,verse:"宁可在安静之中听智慧人的言语，不听掌管愚昧人的喊声。"},
{volumn:        21,chapter:         9,versenumber:        18,verse:"智慧胜过打仗的兵器；但一个罪人能败坏许多善事。"},
{volumn:        21,chapter:        10,versenumber:         1,verse:"死苍蝇使做香的膏油发出臭气；这样，一点愚昧也能败坏智慧和尊荣。"},
{volumn:        21,chapter:        10,versenumber:         2,verse:"智慧人的心居右；愚昧人的心居左。"},
{volumn:        21,chapter:        10,versenumber:         3,verse:"并且愚昧人行路显出无知，对众人说，他是愚昧人。"},
{volumn:        21,chapter:        10,versenumber:         4,verse:"掌权者的心若向你发怒，不要离开你的本位，因为柔和能免大过。"},
{volumn:        21,chapter:        10,versenumber:         5,verse:"我见日光之下有一件祸患，似乎出于掌权的错误，"},
{volumn:        21,chapter:        10,versenumber:         6,verse:"就是愚昧人立在高位；富足人坐在低位。"},
{volumn:        21,chapter:        10,versenumber:         7,verse:"我见过仆人骑马，王子像仆人在地上步行。"},
{volumn:        21,chapter:        10,versenumber:         8,verse:"挖陷坑的，自己必掉在其中；拆墙垣的，必为蛇所咬。"},
{volumn:        21,chapter:        10,versenumber:         9,verse:"凿开（或译：挪移）石头的，必受损伤；劈开木头的，必遭危险。"},
{volumn:        21,chapter:        10,versenumber:        10,verse:"铁器钝了，若不将刃磨快，就必多费气力；但得智慧指教，便有益处。"},
{volumn:        21,chapter:        10,versenumber:        11,verse:"未行法术以先，蛇若咬人，后行法术也是无益。"},
{volumn:        21,chapter:        10,versenumber:        12,verse:"智慧人的口说出恩言；愚昧人的嘴吞灭自己。"},
{volumn:        21,chapter:        10,versenumber:        13,verse:"他口中的言语起头是愚昧；他话的末尾是奸恶的狂妄。"},
{volumn:        21,chapter:        10,versenumber:        14,verse:"愚昧人多有言语，人却不知将来有什么事；他身后的事谁能告诉他呢？"},
{volumn:        21,chapter:        10,versenumber:        15,verse:"凡愚昧人，他的劳碌使自己困乏，因为连进城的路，他也不知道。"},
{volumn:        21,chapter:        10,versenumber:        16,verse:"邦国啊，你的王若是孩童，你的群臣早晨宴乐，你就有祸了！"},
{volumn:        21,chapter:        10,versenumber:        17,verse:"邦国啊，你的王若是贵胄之子，你的群臣按时吃喝，为要补力，不为酒醉，你就有福了！"},
{volumn:        21,chapter:        10,versenumber:        18,verse:"因人懒惰，房顶塌下；因人手懒，房屋滴漏。"},
{volumn:        21,chapter:        10,versenumber:        19,verse:"设摆筵席是为喜笑。酒能使人快活；钱能叫万事应心。"},
{volumn:        21,chapter:        10,versenumber:        20,verse:"你不可咒诅君王，也不可心怀此念；在你卧房也不可咒诅富户。因为空中的鸟必传扬这声音，有翅膀的也必述说这事。"},
{volumn:        21,chapter:        11,versenumber:         1,verse:"当将你的粮食撒在水面，因为日久必能得着。"},
{volumn:        21,chapter:        11,versenumber:         2,verse:"你要分给七人，或分给八人，因为你不知道将来有什么灾祸临到地上。"},
{volumn:        21,chapter:        11,versenumber:         3,verse:"云若满了雨，就必倾倒在地上。树若向南倒，或向北倒，树倒在何处，就存在何处。"},
{volumn:        21,chapter:        11,versenumber:         4,verse:"看风的，必不撒种；望云的，必不收割。"},
{volumn:        21,chapter:        11,versenumber:         5,verse:"风从何道来，骨头在怀孕妇人的胎中如何长成，你尚且不得知道；这样，行万事之　神的作为，你更不得知道。"},
{volumn:        21,chapter:        11,versenumber:         6,verse:"早晨要撒你的种，晚上也不要歇你的手，因为你不知道哪一样发旺；或是早撒的，或是晚撒的，或是两样都好。"},
{volumn:        21,chapter:        11,versenumber:         7,verse:"光本是佳美的，眼见日光也是可悦的。"},
{volumn:        21,chapter:        11,versenumber:         8,verse:"人活多年，就当快乐多年；然而也当想到黑暗的日子。因为这日子必多，所要来的都是虚空。"},
{volumn:        21,chapter:        11,versenumber:         9,verse:"少年人哪，你在幼年时当快乐。在幼年的日子，使你的心欢畅，行你心所愿行的，看你眼所爱看的；却要知道，为这一切的事，　神必审问你。"},
{volumn:        21,chapter:        11,versenumber:        10,verse:"所以，你当从心中除掉愁烦，从肉体克去邪恶；因为一生的开端和幼年之时，都是虚空的。"},
{volumn:        21,chapter:        12,versenumber:         1,verse:"你趁着年幼、衰败的日子尚未来到，就是你所说，我毫无喜乐的那些年日未曾临近之先，当记念造你的主。"},
{volumn:        21,chapter:        12,versenumber:         2,verse:"不要等到日头、光明、月亮、星宿变为黑暗，雨后云彩反回，"},
{volumn:        21,chapter:        12,versenumber:         3,verse:"看守房屋的发颤，有力的屈身，推磨的稀少就止息，从窗户往外看的都昏暗；"},
{volumn:        21,chapter:        12,versenumber:         4,verse:"街门关闭，推磨的响声微小，雀鸟一叫，人就起来，唱歌的女子也都衰微。"},
{volumn:        21,chapter:        12,versenumber:         5,verse:"人怕高处，路上有惊慌，杏树开花，蚱蜢成为重担，人所愿的也都废掉；因为人归他永远的家，吊丧的在街上往来。"},
{volumn:        21,chapter:        12,versenumber:         6,verse:"银链折断，金罐破裂，瓶子在泉旁损坏，水轮在井口破烂，"},
{volumn:        21,chapter:        12,versenumber:         7,verse:"尘土仍归于地，灵仍归于赐灵的　神。"},
{volumn:        21,chapter:        12,versenumber:         8,verse:"传道者说：“虚空的虚空，凡事都是虚空。”"},
{volumn:        21,chapter:        12,versenumber:         9,verse:"再者，传道者因有智慧，仍将知识教训众人；又默想，又考查，又陈说许多箴言。"},
{volumn:        21,chapter:        12,versenumber:        10,verse:"传道者专心寻求可喜悦的言语，是凭正直写的诚实话。"},
{volumn:        21,chapter:        12,versenumber:        11,verse:"智慧人的言语好像刺棍；会中之师的言语又像钉稳的钉子，都是一个牧者所赐的。"},
{volumn:        21,chapter:        12,versenumber:        12,verse:"我儿，还有一层，你当受劝戒：著书多，没有穷尽；读书多，身体疲倦。"},
{volumn:        21,chapter:        12,versenumber:        13,verse:"这些事都已听见了，总意就是：敬畏　神，谨守他的诫命，这是人所当尽的本分（或译：这是众人的本分）。"},
{volumn:        21,chapter:        12,versenumber:        14,verse:"因为人所做的事，连一切隐藏的事，无论是善是恶，　神都必审问。"},
{volumn:        22,chapter:         1,versenumber:         1,verse:"所罗门的歌，是歌中的雅歌。"},
{volumn:        22,chapter:         1,versenumber:         2,verse:"愿他用口与我亲嘴；因你的爱情比酒更美。"},
{volumn:        22,chapter:         1,versenumber:         3,verse:"你的膏油馨香；你的名如同倒出来的香膏，所以众童女都爱你。"},
{volumn:        22,chapter:         1,versenumber:         4,verse:"愿你吸引我，我们就快跑跟随你。王带我进了内室，我们必因你欢喜快乐。我们要称赞你的爱情，胜似称赞美酒。他们爱你是理所当然的。"},
{volumn:        22,chapter:         1,versenumber:         5,verse:"耶路撒冷的众女子啊，我虽然黑，却是秀美，如同基达的帐棚，好像所罗门的幔子。"},
{volumn:        22,chapter:         1,versenumber:         6,verse:"不要因日头把我晒黑了就轻看我。我同母的弟兄向我发怒，他们使我看守葡萄园；我自己的葡萄园却没有看守。"},
{volumn:        22,chapter:         1,versenumber:         7,verse:"我心所爱的啊，求你告诉我，你在何处牧羊？晌午在何处使羊歇卧？我何必在你同伴的羊群旁边好像蒙着脸的人呢？"},
{volumn:        22,chapter:         1,versenumber:         8,verse:"你这女子中极美丽的，你若不知道，只管跟随羊群的脚踪去，把你的山羊羔牧放在牧人帐棚的旁边。"},
{volumn:        22,chapter:         1,versenumber:         9,verse:"我的佳偶，我将你比法老车上套的骏马。"},
{volumn:        22,chapter:         1,versenumber:        10,verse:"你的两腮因发辫而秀美；你的颈项因珠串而华丽。"},
{volumn:        22,chapter:         1,versenumber:        11,verse:"我们要为你编上金辫，镶上银钉。"},
{volumn:        22,chapter:         1,versenumber:        12,verse:"王正坐席的时候，我的哪哒香膏发出香味。"},
{volumn:        22,chapter:         1,versenumber:        13,verse:"我以我的良人为一袋没药，常在我怀中。"},
{volumn:        22,chapter:         1,versenumber:        14,verse:"我以我的良人为一棵凤仙花，在隐·基底葡萄园中。"},
{volumn:        22,chapter:         1,versenumber:        15,verse:"我的佳偶，你甚美丽！你甚美丽！你的眼好像鸽子眼。"},
{volumn:        22,chapter:         1,versenumber:        16,verse:"我的良人哪，你甚美丽可爱！我们以青草为床榻，"},
{volumn:        22,chapter:         1,versenumber:        17,verse:"以香柏树为房屋的栋梁，以松树为椽子。"},
{volumn:        22,chapter:         2,versenumber:         1,verse:"我是沙仑的玫瑰花（或译：水仙花），是谷中的百合花。"},
{volumn:        22,chapter:         2,versenumber:         2,verse:"我的佳偶在女子中，好像百合花在荆棘内。"},
{volumn:        22,chapter:         2,versenumber:         3,verse:"我的良人在男子中，如同苹果树在树林中。我欢欢喜喜坐在他的荫下，尝他果子的滋味，觉得甘甜。"},
{volumn:        22,chapter:         2,versenumber:         4,verse:"他带我入筵宴所，以爱为旗在我以上。"},
{volumn:        22,chapter:         2,versenumber:         5,verse:"求你们给我葡萄干增补我力，给我苹果畅快我心，因我思爱成病。"},
{volumn:        22,chapter:         2,versenumber:         6,verse:"他的左手在我头下；他的右手将我抱住。"},
{volumn:        22,chapter:         2,versenumber:         7,verse:"耶路撒冷的众女子啊，我指着羚羊或田野的母鹿嘱咐你们：不要惊动、不要叫醒我所亲爱的，等他自己情愿（不要叫醒......情愿：或译不要激动爱情，等它自发）。"},
{volumn:        22,chapter:         2,versenumber:         8,verse:"听啊！是我良人的声音；看哪！他蹿山越岭而来。"},
{volumn:        22,chapter:         2,versenumber:         9,verse:"我的良人好像羚羊，或像小鹿。他站在我们墙壁后，从窗户往里观看，从窗棂往里窥探。"},
{volumn:        22,chapter:         2,versenumber:        10,verse:"我良人对我说：〔新郎〕我的佳偶，我的美人，起来，与我同去！"},
{volumn:        22,chapter:         2,versenumber:        11,verse:"因为冬天已往，雨水止住过去了。"},
{volumn:        22,chapter:         2,versenumber:        12,verse:"地上百花开放，百鸟鸣叫的时候（或译：修理葡萄树的时候）已经来到；斑鸠的声音在我们境内也听见了。"},
{volumn:        22,chapter:         2,versenumber:        13,verse:"无花果树的果子渐渐成熟；葡萄树开花放香。我的佳偶，我的美人，起来，与我同去！"},
{volumn:        22,chapter:         2,versenumber:        14,verse:"我的鸽子啊，你在磐石穴中，在陡岩的隐密处。求你容我得见你的面貌，得听你的声音；因为你的声音柔和，你的面貌秀美。"},
{volumn:        22,chapter:         2,versenumber:        15,verse:"要给我们擒拿狐狸，就是毁坏葡萄园的小狐狸，因为我们的葡萄正在开花。"},
{volumn:        22,chapter:         2,versenumber:        16,verse:"良人属我，我也属他；他在百合花中牧放群羊。"},
{volumn:        22,chapter:         2,versenumber:        17,verse:"我的良人哪，求你等到天起凉风、日影飞去的时候，你要转回，好像羚羊，或像小鹿在比特山上。"},
{volumn:        22,chapter:         3,versenumber:         1,verse:"我夜间躺卧在床上，寻找我心所爱的；我寻找他，却寻不见。"},
{volumn:        22,chapter:         3,versenumber:         2,verse:"我说：我要起来，游行城中，在街市上，在宽阔处，寻找我心所爱的。我寻找他，却寻不见。"},
{volumn:        22,chapter:         3,versenumber:         3,verse:"城中巡逻看守的人遇见我；我问他们：“你们看见我心所爱的没有？”"},
{volumn:        22,chapter:         3,versenumber:         4,verse:"我刚离开他们就遇见我心所爱的。我拉住他，不容他走，领他入我母家，到怀我者的内室。"},
{volumn:        22,chapter:         3,versenumber:         5,verse:"耶路撒冷的众女子啊，我指着羚羊或田野的母鹿嘱咐你们：不要惊动、不要叫醒我所亲爱的，等他自己情愿（不要叫醒......情愿：或译不要激动爱情，等它自发）。"},
{volumn:        22,chapter:         3,versenumber:         6,verse:"那从旷野上来、形状如烟柱、以没药和乳香并商人各样香粉薰的是谁呢？"},
{volumn:        22,chapter:         3,versenumber:         7,verse:"看哪，是所罗门的轿；四围有六十个勇士，都是以色列中的勇士；"},
{volumn:        22,chapter:         3,versenumber:         8,verse:"手都持刀，善于争战，腰间佩刀，防备夜间有惊慌。"},
{volumn:        22,chapter:         3,versenumber:         9,verse:"所罗门王用黎巴嫩木为自己制造一乘华轿。"},
{volumn:        22,chapter:         3,versenumber:        10,verse:"轿柱是用银做的，轿底是用金做的；坐垫是紫色的，其中所铺的乃耶路撒冷众女子的爱情。"},
{volumn:        22,chapter:         3,versenumber:        11,verse:"锡安的众女子啊，你们出去观看所罗门王！头戴冠冕，就是在他婚筵的日子、心中喜乐的时候，他母亲给他戴上的。"},
{volumn:        22,chapter:         4,versenumber:         1,verse:"我的佳偶，你甚美丽！你甚美丽！你的眼在帕子内好像鸽子眼。你的头发如同山羊群卧在基列山旁。"},
{volumn:        22,chapter:         4,versenumber:         2,verse:"你的牙齿如新剪毛的一群母羊，洗净上来，个个都有双生，没有一只丧掉子的。"},
{volumn:        22,chapter:         4,versenumber:         3,verse:"你的唇好像一条朱红线；你的嘴也秀美。你的两太阳在帕子内，如同一块石榴。"},
{volumn:        22,chapter:         4,versenumber:         4,verse:"你的颈项好像大卫建造收藏军器的高台，其上悬挂一千盾牌，都是勇士的藤牌。"},
{volumn:        22,chapter:         4,versenumber:         5,verse:"你的两乳好像百合花中吃草的一对小鹿，就是母鹿双生的。"},
{volumn:        22,chapter:         4,versenumber:         6,verse:"我要往没药山和乳香冈去，直等到天起凉风、日影飞去的时候回来。"},
{volumn:        22,chapter:         4,versenumber:         7,verse:"我的佳偶，你全然美丽，毫无瑕疵！"},
{volumn:        22,chapter:         4,versenumber:         8,verse:"我的新妇，求你与我一同离开黎巴嫩，与我一同离开黎巴嫩。从亚玛拿顶，从示尼珥与黑门顶，从有狮子的洞，从有豹子的山往下观看。"},
{volumn:        22,chapter:         4,versenumber:         9,verse:"我妹子，我新妇，你夺了我的心。你用眼一看，用你项上的一条金链，夺了我的心！"},
{volumn:        22,chapter:         4,versenumber:        10,verse:"我妹子，我新妇，你的爱情何其美！你的爱情比酒更美！你膏油的香气胜过一切香品！"},
{volumn:        22,chapter:         4,versenumber:        11,verse:"我新妇，你的嘴唇滴蜜，好像蜂房滴蜜；你的舌下有蜜，有奶。你衣服的香气如黎巴嫩的香气。"},
{volumn:        22,chapter:         4,versenumber:        12,verse:"我妹子，我新妇，乃是关锁的园，禁闭的井，封闭的泉源。"},
{volumn:        22,chapter:         4,versenumber:        13,verse:"你园内所种的结了石榴，有佳美的果子，并凤仙花与哪哒树。"},
{volumn:        22,chapter:         4,versenumber:        14,verse:"有哪哒和番红花，菖蒲和桂树，并各样乳香木、没药、沉香，与一切上等的果品。"},
{volumn:        22,chapter:         4,versenumber:        15,verse:"你是园中的泉，活水的井，从黎巴嫩流下来的溪水。"},
{volumn:        22,chapter:         4,versenumber:        16,verse:"北风啊，兴起！南风啊，吹来！吹在我的园内，使其中的香气发出来。愿我的良人进入自己园里，吃他佳美的果子。"},
{volumn:        22,chapter:         5,versenumber:         1,verse:"我妹子，我新妇，我进了我的园中，采了我的没药和香料，吃了我的蜜房和蜂蜜，喝了我的酒和奶。〔耶路撒冷的众女子〕我的朋友们，请吃！我所亲爱的，请喝，且多多地喝！"},
{volumn:        22,chapter:         5,versenumber:         2,verse:"我身睡卧，我心却醒。这是我良人的声音；他敲门说：〔新郎〕“我的妹子，我的佳偶，我的鸽子，我的完全人，求你给我开门；因我的头满了露水，我的头发被夜露滴湿。”"},
{volumn:        22,chapter:         5,versenumber:         3,verse:"我回答说：“我脱了衣裳，怎能再穿上呢？我洗了脚，怎能再玷污呢？”"},
{volumn:        22,chapter:         5,versenumber:         4,verse:"我的良人从门孔里伸进手来，我便因他动了心。"},
{volumn:        22,chapter:         5,versenumber:         5,verse:"我起来，要给我良人开门。我的两手滴下没药；我的指头有没药汁滴在门闩上。"},
{volumn:        22,chapter:         5,versenumber:         6,verse:"我给我的良人开了门；我的良人却已转身走了。他说话的时候，我神不守舍；我寻找他，竟寻不见；我呼叫他，他却不回答。"},
{volumn:        22,chapter:         5,versenumber:         7,verse:"城中巡逻看守的人遇见我，打了我，伤了我；看守城墙的人夺去我的披肩。"},
{volumn:        22,chapter:         5,versenumber:         8,verse:"耶路撒冷的众女子啊，我嘱咐你们：若遇见我的良人，要告诉他，我因思爱成病。"},
{volumn:        22,chapter:         5,versenumber:         9,verse:"你这女子中极美丽的，你的良人比别人的良人有何强处？你的良人比别人的良人有何强处，你就这样嘱咐我们？"},
{volumn:        22,chapter:         5,versenumber:        10,verse:"我的良人白而且红，超乎万人之上。"},
{volumn:        22,chapter:         5,versenumber:        11,verse:"他的头像至精的金子；他的头发厚密累垂，黑如乌鸦。"},
{volumn:        22,chapter:         5,versenumber:        12,verse:"他的眼如溪水旁的鸽子眼，用奶洗净，安得合式。"},
{volumn:        22,chapter:         5,versenumber:        13,verse:"他的两腮如香花畦，如香草台；他的嘴唇像百合花，且滴下没药汁。"},
{volumn:        22,chapter:         5,versenumber:        14,verse:"他的两手好像金管，镶嵌水苍玉；他的身体如同雕刻的象牙，周围镶嵌蓝宝石。"},
{volumn:        22,chapter:         5,versenumber:        15,verse:"他的腿好像白玉石柱，安在精金座上；他的形状如黎巴嫩，且佳美如香柏树。"},
{volumn:        22,chapter:         5,versenumber:        16,verse:"他的口极其甘甜；他全然可爱。耶路撒冷的众女子啊，这是我的良人；这是我的朋友。"},
{volumn:        22,chapter:         6,versenumber:         1,verse:"你这女子中极美丽的，你的良人往何处去了？你的良人转向何处去了，我们好与你同去寻找他。"},
{volumn:        22,chapter:         6,versenumber:         2,verse:"我的良人下入自己园中，到香花畦，在园内牧放群羊，采百合花。"},
{volumn:        22,chapter:         6,versenumber:         3,verse:"我属我的良人，我的良人也属我；他在百合花中牧放群羊。"},
{volumn:        22,chapter:         6,versenumber:         4,verse:"我的佳偶啊，你美丽如得撒，秀美如耶路撒冷，威武如展开旌旗的军队。"},
{volumn:        22,chapter:         6,versenumber:         5,verse:"求你掉转眼目不看我，因你的眼目使我惊乱。你的头发如同山羊群卧在基列山旁。"},
{volumn:        22,chapter:         6,versenumber:         6,verse:"你的牙齿如一群母羊洗净上来，个个都有双生，没有一只丧掉子的。"},
{volumn:        22,chapter:         6,versenumber:         7,verse:"你的两太阳在帕子内，如同一块石榴。"},
{volumn:        22,chapter:         6,versenumber:         8,verse:"有六十王后八十妃嫔，并有无数的童女。"},
{volumn:        22,chapter:         6,versenumber:         9,verse:"我的鸽子，我的完全人，只有这一个是她母亲独生的，是生养她者所宝爱的。众女子见了就称她有福；王后妃嫔见了也赞美她。"},
{volumn:        22,chapter:         6,versenumber:        10,verse:"那向外观看、如晨光发现、美丽如月亮、皎洁如日头、威武如展开旌旗军队的是谁呢？"},
{volumn:        22,chapter:         6,versenumber:        11,verse:"我下入核桃园，要看谷中青绿的植物，要看葡萄发芽没有，石榴开花没有。"},
{volumn:        22,chapter:         6,versenumber:        12,verse:"不知不觉，我的心将我安置在我尊长的车中。"},
{volumn:        22,chapter:         6,versenumber:        13,verse:"回来，回来，书拉密女；你回来，你回来，使我们得观看你。〔新娘〕你们为何要观看书拉密女，像观看玛哈念跳舞的呢？"},
{volumn:        22,chapter:         7,versenumber:         1,verse:"王女啊，你的脚在鞋中何其美好！你的大腿圆润，好像美玉，是巧匠的手做成的。"},
{volumn:        22,chapter:         7,versenumber:         2,verse:"你的肚脐如圆杯，不缺调和的酒；你的腰如一堆麦子，周围有百合花。"},
{volumn:        22,chapter:         7,versenumber:         3,verse:"你的两乳好像一对小鹿，就是母鹿双生的。"},
{volumn:        22,chapter:         7,versenumber:         4,verse:"你的颈项如象牙台；你的眼目像希实本、巴特·拉并门旁的水池；你的鼻子仿佛朝大马士革的黎巴嫩塔。"},
{volumn:        22,chapter:         7,versenumber:         5,verse:"你的头在你身上好像迦密山；你头上的发是紫黑色；王的心因这下垂的发绺系住了。"},
{volumn:        22,chapter:         7,versenumber:         6,verse:"我所爱的，你何其美好！何其可悦，使人欢畅喜乐！"},
{volumn:        22,chapter:         7,versenumber:         7,verse:"你的身量好像棕树；你的两乳如同其上的果子，累累下垂。"},
{volumn:        22,chapter:         7,versenumber:         8,verse:"我说：我要上这棕树，抓住枝子。愿你的两乳好像葡萄累累下垂，你鼻子的气味香如苹果；"},
{volumn:        22,chapter:         7,versenumber:         9,verse:"你的口如上好的酒。〔新娘〕女子说：为我的良人下咽舒畅，流入睡觉人的嘴中。"},
{volumn:        22,chapter:         7,versenumber:        10,verse:"我属我的良人，他也恋慕我。"},
{volumn:        22,chapter:         7,versenumber:        11,verse:"我的良人，来吧！你我可以往田间去；你我可以在村庄住宿。"},
{volumn:        22,chapter:         7,versenumber:        12,verse:"我们早晨起来往葡萄园去，看看葡萄发芽开花没有，石榴放蕊没有；我在那里要将我的爱情给你。"},
{volumn:        22,chapter:         7,versenumber:        13,verse:"风茄放香，在我们的门内有各样新陈佳美的果子；我的良人，这都是我为你存留的。"},
{volumn:        22,chapter:         8,versenumber:         1,verse:"巴不得你像我的兄弟，像吃我母亲奶的兄弟；我在外头遇见你就与你亲嘴，谁也不轻看我。"},
{volumn:        22,chapter:         8,versenumber:         2,verse:"我必引导你，领你进我母亲的家；我可以领受教训，也就使你喝石榴汁酿的香酒。"},
{volumn:        22,chapter:         8,versenumber:         3,verse:"他的左手必在我头下；他的右手必将我抱住。"},
{volumn:        22,chapter:         8,versenumber:         4,verse:"耶路撒冷的众女子啊，我嘱咐你们：不要惊动、不要叫醒我所亲爱的，等他自己情愿（不要叫醒......情愿：或译不要激动爱情，等它自发）。"},
{volumn:        22,chapter:         8,versenumber:         5,verse:"那靠着良人从旷野上来的是谁呢？〔新娘〕我在苹果树下叫醒你。你母亲在那里为你劬劳；生养你的在那里为你劬劳。"},
{volumn:        22,chapter:         8,versenumber:         6,verse:"求你将我放在心上如印记，带在你臂上如戳记。因为爱情如死之坚强，嫉恨如阴间之残忍；所发的电光，是火焰的电光，是耶和华的烈焰。"},
{volumn:        22,chapter:         8,versenumber:         7,verse:"爱情，众水不能息灭，大水也不能淹没。若有人拿家中所有的财宝要换爱情，就全被藐视。"},
{volumn:        22,chapter:         8,versenumber:         8,verse:"我们有一小妹；她的两乳尚未长成，人来提亲的日子，我们当为她怎样办理？"},
{volumn:        22,chapter:         8,versenumber:         9,verse:"她若是墙，我们要在其上建造银塔；她若是门，我们要用香柏木板围护她。"},
{volumn:        22,chapter:         8,versenumber:        10,verse:"我是墙；我两乳像其上的楼。那时，我在他眼中像得平安的人。"},
{volumn:        22,chapter:         8,versenumber:        11,verse:"所罗门在巴力·哈们有一葡萄园；他将这葡萄园交给看守的人，为其中的果子必交一千舍客勒银子。"},
{volumn:        22,chapter:         8,versenumber:        12,verse:"我自己的葡萄园在我面前。所罗门哪，一千舍客勒归你，二百舍客勒归看守果子的人。"},
{volumn:        22,chapter:         8,versenumber:        13,verse:"你这住在园中的，同伴都要听你的声音，求你使我也得听见。"},
{volumn:        22,chapter:         8,versenumber:        14,verse:"我的良人哪，求你快来！如羚羊或小鹿在香草山上。"},
{volumn:        23,chapter:         1,versenumber:         1,verse:"当乌西雅、约坦、亚哈斯、希西家作犹大王的时候，亚摩斯的儿子以赛亚得默示，论到犹大和耶路撒冷。"},
{volumn:        23,chapter:         1,versenumber:         2,verse:"天哪，要听！地啊，侧耳而听！因为耶和华说：“我养育儿女，将他们养大，他们竟悖逆我。"},
{volumn:        23,chapter:         1,versenumber:         3,verse:"牛认识主人，驴认识主人的槽，以色列却不认识；我的民却不留意。”"},
{volumn:        23,chapter:         1,versenumber:         4,verse:"嗐！犯罪的国民，担着罪孽的百姓；行恶的种类，败坏的儿女！他们离弃耶和华，藐视以色列的圣者，与他生疏，往后退步。"},
{volumn:        23,chapter:         1,versenumber:         5,verse:"你们为什么屡次悖逆，还要受责打吗？你们已经满头疼痛，全心发昏。"},
{volumn:        23,chapter:         1,versenumber:         6,verse:"从脚掌到头顶，没有一处完全的，尽是伤口、青肿，与新打的伤痕，都没有收口，没有缠裹，也没有用膏滋润。"},
{volumn:        23,chapter:         1,versenumber:         7,verse:"你们的地土已经荒凉；你们的城邑被火焚毁。你们的田地在你们眼前为外邦人所侵吞，既被外邦人倾覆就成为荒凉。"},
{volumn:        23,chapter:         1,versenumber:         8,verse:"仅存锡安城（原文是女子），好像葡萄园的草棚，瓜田的茅屋，被围困的城邑。"},
{volumn:        23,chapter:         1,versenumber:         9,verse:"若不是万军之耶和华给我们稍留余种，我们早已像所多玛、蛾摩拉的样子了。"},
{volumn:        23,chapter:         1,versenumber:        10,verse:"你们这所多玛的官长啊，要听耶和华的话！你们这蛾摩拉的百姓啊，要侧耳听我们　神的训诲！"},
{volumn:        23,chapter:         1,versenumber:        11,verse:"耶和华说：“你们所献的许多祭物与我何益呢？公绵羊的燔祭和肥畜的脂油，我已经够了；公牛的血，羊羔的血，公山羊的血，我都不喜悦。"},
{volumn:        23,chapter:         1,versenumber:        12,verse:"“你们来朝见我，谁向你们讨这些，使你们践踏我的院宇呢？"},
{volumn:        23,chapter:         1,versenumber:        13,verse:"你们不要再献虚浮的供物。香品是我所憎恶的；月朔和安息日，并宣召的大会，也是我所憎恶的；作罪孽，又守严肃会，我也不能容忍。"},
{volumn:        23,chapter:         1,versenumber:        14,verse:"你们的月朔和节期，我心里恨恶，我都以为麻烦；我担当，便不耐烦。"},
{volumn:        23,chapter:         1,versenumber:        15,verse:"你们举手祷告，我必遮眼不看；就是你们多多地祈祷，我也不听。你们的手都满了杀人的血。"},
{volumn:        23,chapter:         1,versenumber:        16,verse:"你们要洗濯、自洁，从我眼前除掉你们的恶行，要止住作恶，"},
{volumn:        23,chapter:         1,versenumber:        17,verse:"学习行善，寻求公平，解救受欺压的；给孤儿伸冤，为寡妇辨屈。”"},
{volumn:        23,chapter:         1,versenumber:        18,verse:"耶和华说：“你们来，我们彼此辩论。你们的罪虽像朱红，必变成雪白；虽红如丹颜，必白如羊毛。"},
{volumn:        23,chapter:         1,versenumber:        19,verse:"你们若甘心听从，必吃地上的美物，"},
{volumn:        23,chapter:         1,versenumber:        20,verse:"若不听从，反倒悖逆，必被刀剑吞灭。”这是耶和华亲口说的。"},
{volumn:        23,chapter:         1,versenumber:        21,verse:"可叹，忠信的城变为妓女！从前充满了公平，公义居在其中，现今却有凶手居住。"},
{volumn:        23,chapter:         1,versenumber:        22,verse:"你的银子变为渣滓；你的酒用水搀兑。"},
{volumn:        23,chapter:         1,versenumber:        23,verse:"你的官长居心悖逆，与盗贼作伴，各都喜爱贿赂，追求赃私。他们不为孤儿伸冤；寡妇的案件也不得呈到他们面前。"},
{volumn:        23,chapter:         1,versenumber:        24,verse:"因此，主万军之耶和华、以色列的大能者说：“哎！我要向我的对头雪恨，向我的敌人报仇。"},
{volumn:        23,chapter:         1,versenumber:        25,verse:"我必反手加在你身上，炼尽你的渣滓，除净你的杂质。"},
{volumn:        23,chapter:         1,versenumber:        26,verse:"我也必复还你的审判官，像起初一样，复还你的谋士，像起先一般。然后，你必称为公义之城，忠信之邑。”"},
{volumn:        23,chapter:         1,versenumber:        27,verse:"锡安必因公平得蒙救赎；其中归正的人必因公义得蒙救赎。"},
{volumn:        23,chapter:         1,versenumber:        28,verse:"但悖逆的和犯罪的必一同败亡；离弃耶和华的必致消灭。"},
{volumn:        23,chapter:         1,versenumber:        29,verse:"那等人必因你们所喜爱的橡树抱愧；你们必因所选择的园子蒙羞。"},
{volumn:        23,chapter:         1,versenumber:        30,verse:"因为，你们必如叶子枯干的橡树，好像无水浇灌的园子。"},
{volumn:        23,chapter:         1,versenumber:        31,verse:"有权势的必如麻瓤；他的工作好像火星，都要一同焚毁，无人扑灭。"},
{volumn:        23,chapter:         2,versenumber:         1,verse:"亚摩斯的儿子以赛亚得默示，论到犹大和耶路撒冷。"},
{volumn:        23,chapter:         2,versenumber:         2,verse:"末后的日子，耶和华殿的山必坚立，超乎诸山，高举过于万岭；万民都要流归这山。"},
{volumn:        23,chapter:         2,versenumber:         3,verse:"必有许多国的民前往，说：“来吧，我们登耶和华的山，奔雅各　神的殿。主必将他的道教训我们；我们也要行他的路。因为训诲必出于锡安；耶和华的言语必出于耶路撒冷。”"},
{volumn:        23,chapter:         2,versenumber:         4,verse:"他必在列国中施行审判，为许多国民断定是非。他们要将刀打成犁头，把枪打成镰刀。这国不举刀攻击那国；他们也不再学习战事。"},
{volumn:        23,chapter:         2,versenumber:         5,verse:"雅各家啊，来吧！我们在耶和华的光明中行走。"},
{volumn:        23,chapter:         2,versenumber:         6,verse:"耶和华，你离弃了你百姓雅各家，是因他们充满了东方的风俗，作观兆的，像非利士人一样，并与外邦人击掌。"},
{volumn:        23,chapter:         2,versenumber:         7,verse:"他们的国满了金银，财宝也无穷；他们的地满了马匹，车辆也无数。"},
{volumn:        23,chapter:         2,versenumber:         8,verse:"他们的地满了偶像；他们跪拜自己手所造的，就是自己指头所做的。"},
{volumn:        23,chapter:         2,versenumber:         9,verse:"卑贱人屈膝；尊贵人下跪；所以不可饶恕他们。"},
{volumn:        23,chapter:         2,versenumber:        10,verse:"你当进入岩穴，藏在土中，躲避耶和华的惊吓和他威严的荣光。"},
{volumn:        23,chapter:         2,versenumber:        11,verse:"到那日，眼目高傲的必降为卑；性情狂傲的都必屈膝；惟独耶和华被尊崇。"},
{volumn:        23,chapter:         2,versenumber:        12,verse:"必有万军耶和华降罚的一个日子，要临到骄傲狂妄的；一切自高的都必降为卑；"},
{volumn:        23,chapter:         2,versenumber:        13,verse:"又临到黎巴嫩高大的香柏树和巴珊的橡树；"},
{volumn:        23,chapter:         2,versenumber:        14,verse:"又临到一切高山的峻岭；"},
{volumn:        23,chapter:         2,versenumber:        15,verse:"又临到高台和坚固城墙；"},
{volumn:        23,chapter:         2,versenumber:        16,verse:"又临到他施的船只并一切可爱的美物。"},
{volumn:        23,chapter:         2,versenumber:        17,verse:"骄傲的必屈膝；狂妄的必降卑。在那日，惟独耶和华被尊崇；"},
{volumn:        23,chapter:         2,versenumber:        18,verse:"偶像必全然废弃。"},
{volumn:        23,chapter:         2,versenumber:        19,verse:"耶和华兴起，使地大震动的时候，人就进入石洞，进入土穴，躲避耶和华的惊吓和他威严的荣光。"},
{volumn:        23,chapter:         2,versenumber:        20,verse:"到那日，人必将为拜而造的金偶像、银偶像抛给田鼠和蝙蝠。"},
{volumn:        23,chapter:         2,versenumber:        21,verse:"到耶和华兴起，使地大震动的时候，人好进入磐石洞中和岩石穴里，躲避耶和华的惊吓和他威严的荣光。"},
{volumn:        23,chapter:         2,versenumber:        22,verse:"你们休要倚靠世人。他鼻孔里不过有气息；他在一切事上可算什么呢？"},
{volumn:        23,chapter:         3,versenumber:         1,verse:"主万军之耶和华从耶路撒冷和犹大，除掉众人所倚靠的，所仗赖的，就是所倚靠的粮，所仗赖的水；"},
{volumn:        23,chapter:         3,versenumber:         2,verse:"除掉勇士和战士，审判官和先知，占卜的和长老，"},
{volumn:        23,chapter:         3,versenumber:         3,verse:"五十夫长和尊贵人，谋士和有巧艺的，以及妙行法术的。"},
{volumn:        23,chapter:         3,versenumber:         4,verse:"主说：“我必使孩童作他们的首领，使婴孩辖管他们。"},
{volumn:        23,chapter:         3,versenumber:         5,verse:"百姓要彼此欺压；各人受邻舍的欺压。少年人必侮慢老年人；卑贱人必侮慢尊贵人。"},
{volumn:        23,chapter:         3,versenumber:         6,verse:"“人在父家拉住弟兄，说：‘你有衣服，可以作我们的官长。这败落的事归在你手下吧！’"},
{volumn:        23,chapter:         3,versenumber:         7,verse:"那时，他必扬声说：‘我不作医治你们的人；因我家中没有粮食，也没有衣服，你们不可立我作百姓的官长。’”"},
{volumn:        23,chapter:         3,versenumber:         8,verse:"耶路撒冷败落，犹大倾倒；因为他们的舌头和行为与耶和华反对，惹了他荣光的眼目。"},
{volumn:        23,chapter:         3,versenumber:         9,verse:"他们的面色证明自己的不正；他们述说自己的罪恶，并不隐瞒，好像所多玛一样。他们有祸了！因为作恶自害。"},
{volumn:        23,chapter:         3,versenumber:        10,verse:"你们要论义人说：他必享福乐，因为要吃自己行为所结的果子。"},
{volumn:        23,chapter:         3,versenumber:        11,verse:"恶人有祸了！他必遭灾难！因为要照自己手所行的受报应。"},
{volumn:        23,chapter:         3,versenumber:        12,verse:"至于我的百姓，孩童欺压他们，妇女辖管他们。我的百姓啊，引导你的使你走错，并毁坏你所行的道路。"},
{volumn:        23,chapter:         3,versenumber:        13,verse:"耶和华起来辩论，站着审判众民。"},
{volumn:        23,chapter:         3,versenumber:        14,verse:"耶和华必审问他民中的长老和首领，说：“吃尽葡萄园果子的就是你们；向贫穷人所夺的都在你们家中。”"},
{volumn:        23,chapter:         3,versenumber:        15,verse:"主万军之耶和华说：“你们为何压制我的百姓，搓磨贫穷人的脸呢？”"},
{volumn:        23,chapter:         3,versenumber:        16,verse:"耶和华又说：“因为锡安的女子狂傲，行走挺项，卖弄眼目，俏步徐行，脚下玎珰，"},
{volumn:        23,chapter:         3,versenumber:        17,verse:"所以，主必使锡安的女子头长秃疮；耶和华又使她们赤露下体。”"},
{volumn:        23,chapter:         3,versenumber:        18,verse:"到那日，主必除掉她们华美的脚钏、发网、月牙圈、"},
{volumn:        23,chapter:         3,versenumber:        19,verse:"耳环、手镯、蒙脸的帕子、"},
{volumn:        23,chapter:         3,versenumber:        20,verse:"华冠、足链、华带、香盒、符囊、"},
{volumn:        23,chapter:         3,versenumber:        21,verse:"戒指、鼻环、"},
{volumn:        23,chapter:         3,versenumber:        22,verse:"吉服、外套、云肩、荷包、"},
{volumn:        23,chapter:         3,versenumber:        23,verse:"手镜、细麻衣、裹头巾、蒙身的帕子。"},
{volumn:        23,chapter:         3,versenumber:        24,verse:"必有臭烂代替馨香，绳子代替腰带，光秃代替美发，麻衣系腰代替华服，烙伤代替美容。"},
{volumn:        23,chapter:         3,versenumber:        25,verse:"你的男丁必倒在刀下；你的勇士必死在阵上。"},
{volumn:        23,chapter:         3,versenumber:        26,verse:"锡安（原文是她）的城门必悲伤、哀号；她必荒凉坐在地上。"},
{volumn:        23,chapter:         4,versenumber:         1,verse:"在那日，七个女人必拉住一个男人，说：“我们吃自己的食物，穿自己的衣服，但求你许我们归你名下；求你除掉我们的羞耻。”"},
{volumn:        23,chapter:         4,versenumber:         2,verse:"到那日，耶和华发生的苗必华美尊荣，地的出产必为以色列逃脱的人显为荣华茂盛。"},
{volumn:        23,chapter:         4,versenumber:         3,verse:"主以公义的灵和焚烧的灵，将锡安女子的污秽洗去，又将耶路撒冷中杀人的血除净。那时，剩在锡安、留在耶路撒冷的，就是一切住耶路撒冷、在生命册上记名的，必称为圣。"},
{volumn:        23,chapter:         4,versenumber:         4,verse:""},
{volumn:        23,chapter:         4,versenumber:         5,verse:"耶和华也必在锡安全山，并各会众以上，使白日有烟云，黑夜有火焰的光。因为在全荣耀之上必有遮蔽。"},
{volumn:        23,chapter:         4,versenumber:         6,verse:"必有亭子，白日可以得荫避暑，也可以作为藏身之处，躲避狂风暴雨。"},
{volumn:        23,chapter:         5,versenumber:         1,verse:"我要为我所亲爱的唱歌，是我所爱者的歌，论他葡萄园的事：我所亲爱的有葡萄园在肥美的山冈上。"},
{volumn:        23,chapter:         5,versenumber:         2,verse:"他刨挖园子，捡去石头，栽种上等的葡萄树，在园中盖了一座楼，又凿出压酒池；指望结好葡萄，反倒结了野葡萄。"},
{volumn:        23,chapter:         5,versenumber:         3,verse:"耶路撒冷的居民和犹大人哪，请你们现今在我与我的葡萄园中，断定是非。"},
{volumn:        23,chapter:         5,versenumber:         4,verse:"我为我葡萄园所做之外，还有什么可做的呢？我指望结好葡萄，怎么倒结了野葡萄呢？"},
{volumn:        23,chapter:         5,versenumber:         5,verse:"现在我告诉你们，我要向我葡萄园怎样行：我必撤去篱笆，使它被吞灭，拆毁墙垣，使它被践踏。"},
{volumn:        23,chapter:         5,versenumber:         6,verse:"我必使它荒废，不再修理，不再锄刨，荆棘蒺藜倒要生长。我也必命云不降雨在其上。"},
{volumn:        23,chapter:         5,versenumber:         7,verse:"万军之耶和华的葡萄园就是以色列家；他所喜爱的树就是犹大人。他指望的是公平，谁知倒有暴虐（或译：倒流人血）；指望的是公义，谁知倒有冤声。"},
{volumn:        23,chapter:         5,versenumber:         8,verse:"祸哉！那些以房接房，以地连地，以致不留余地的，只顾自己独居境内。"},
{volumn:        23,chapter:         5,versenumber:         9,verse:"我耳闻万军之耶和华说：“必有许多又大又美的房屋成为荒凉，无人居住。"},
{volumn:        23,chapter:         5,versenumber:        10,verse:"三十亩葡萄园只出一罢特酒；一贺梅珥谷种只结一伊法粮食。”"},
{volumn:        23,chapter:         5,versenumber:        11,verse:"祸哉！那些清早起来追求浓酒，留连到夜深，甚至因酒发烧的人。"},
{volumn:        23,chapter:         5,versenumber:        12,verse:"他们在筵席上弹琴，鼓瑟，击鼓，吹笛，饮酒，却不顾念耶和华的作为，也不留心他手所做的。"},
{volumn:        23,chapter:         5,versenumber:        13,verse:"所以，我的百姓因无知就被掳去；他们的尊贵人甚是饥饿，群众极其干渴。"},
{volumn:        23,chapter:         5,versenumber:        14,verse:"故此，阴间扩张其欲，开了无限量的口；他们的荣耀、群众、繁华，并快乐的人都落在其中。"},
{volumn:        23,chapter:         5,versenumber:        15,verse:"卑贱人被压服；尊贵人降为卑；眼目高傲的人也降为卑。"},
{volumn:        23,chapter:         5,versenumber:        16,verse:"惟有万军之耶和华因公平而崇高；圣者　神因公义显为圣。"},
{volumn:        23,chapter:         5,versenumber:        17,verse:"那时，羊羔必来吃草，如同在自己的草场；丰肥人的荒场被游行的人吃尽。"},
{volumn:        23,chapter:         5,versenumber:        18,verse:"祸哉！那些以虚假之细绳牵罪孽的人！他们又像以套绳拉罪恶，"},
{volumn:        23,chapter:         5,versenumber:        19,verse:"说：“任他急速行，赶快成就他的作为，使我们看看；任以色列圣者所谋划的临近成就，使我们知道。”"},
{volumn:        23,chapter:         5,versenumber:        20,verse:"祸哉！那些称恶为善，称善为恶，以暗为光，以光为暗，以苦为甜，以甜为苦的人。"},
{volumn:        23,chapter:         5,versenumber:        21,verse:"祸哉！那些自以为有智慧，自看为通达的人。"},
{volumn:        23,chapter:         5,versenumber:        22,verse:"祸哉！那些勇于饮酒，以能力调浓酒的人。"},
{volumn:        23,chapter:         5,versenumber:        23,verse:"他们因受贿赂，就称恶人为义，将义人的义夺去。"},
{volumn:        23,chapter:         5,versenumber:        24,verse:"火苗怎样吞灭碎秸，干草怎样落在火焰之中，照样，他们的根必像朽物，他们的花必像灰尘飞腾；因为他们厌弃万军之耶和华的训诲，藐视以色列圣者的言语。"},
{volumn:        23,chapter:         5,versenumber:        25,verse:"所以，耶和华的怒气向他的百姓发作。他的手伸出攻击他们，山岭就震动；他们的尸首在街市上好像粪土。虽然如此，他的怒气还未转消；他的手仍伸不缩。"},
{volumn:        23,chapter:         5,versenumber:        26,verse:"他必竖立大旗，招远方的国民，发嘶声叫他们从地极而来；看哪，他们必急速奔来。"},
{volumn:        23,chapter:         5,versenumber:        27,verse:"其中没有疲倦的，绊跌的；没有打盹的，睡觉的；腰带并不放松，鞋带也不折断。"},
{volumn:        23,chapter:         5,versenumber:        28,verse:"他们的箭快利，弓也上了弦。马蹄算如坚石，车轮好像旋风。"},
{volumn:        23,chapter:         5,versenumber:        29,verse:"他们要吼叫，像母狮子，咆哮，像少壮狮子；他们要咆哮抓食，坦然叼去，无人救回。"},
{volumn:        23,chapter:         5,versenumber:        30,verse:"那日，他们要向以色列人吼叫，像海浪砰訇；人若望地，只见黑暗艰难，光明在云中变为昏暗。"},
{volumn:        23,chapter:         6,versenumber:         1,verse:"当乌西雅王崩的那年，我见主坐在高高的宝座上。他的衣裳垂下，遮满圣殿。"},
{volumn:        23,chapter:         6,versenumber:         2,verse:"其上有撒拉弗侍立，各有六个翅膀：用两个翅膀遮脸，两个翅膀遮脚，两个翅膀飞翔；"},
{volumn:        23,chapter:         6,versenumber:         3,verse:"彼此呼喊说：“圣哉！圣哉！圣哉！万军之耶和华；他的荣光充满全地！”"},
{volumn:        23,chapter:         6,versenumber:         4,verse:"因呼喊者的声音，门槛的根基震动，殿充满了烟云。"},
{volumn:        23,chapter:         6,versenumber:         5,verse:"那时我说：“祸哉！我灭亡了！因为我是嘴唇不洁的人，又住在嘴唇不洁的民中，又因我眼见大君王万军之耶和华。”"},
{volumn:        23,chapter:         6,versenumber:         6,verse:"有一撒拉弗飞到我跟前，手里拿着红炭，是用火剪从坛上取下来的，"},
{volumn:        23,chapter:         6,versenumber:         7,verse:"将炭沾我的口，说：“看哪，这炭沾了你的嘴，你的罪孽便除掉，你的罪恶就赦免了。”"},
{volumn:        23,chapter:         6,versenumber:         8,verse:"我又听见主的声音说：“我可以差遣谁呢？谁肯为我们去呢？”我说：“我在这里，请差遣我！”"},
{volumn:        23,chapter:         6,versenumber:         9,verse:"他说：“你去告诉这百姓说：你们听是要听见，却不明白；看是要看见，却不晓得。"},
{volumn:        23,chapter:         6,versenumber:        10,verse:"要使这百姓心蒙脂油，耳朵发沉，眼睛昏迷；恐怕眼睛看见，耳朵听见，心里明白，回转过来，便得医治。”"},
{volumn:        23,chapter:         6,versenumber:        11,verse:"我就说：“主啊，这到几时为止呢？”他说：“直到城邑荒凉，无人居住，房屋空闲无人，地土极其荒凉。"},
{volumn:        23,chapter:         6,versenumber:        12,verse:"并且耶和华将人迁到远方，在这境内撇下的地土很多。"},
{volumn:        23,chapter:         6,versenumber:        13,verse:"境内剩下的人若还有十分之一，也必被吞灭，像栗树、橡树虽被砍伐，树墩子却仍存留。这圣洁的种类在国中也是如此。”"},
{volumn:        23,chapter:         7,versenumber:         1,verse:"乌西雅的孙子、约坦的儿子、犹大王亚哈斯在位的时候，亚兰王利汛和利玛利的儿子、以色列王比加上来攻打耶路撒冷，却不能攻取。"},
{volumn:        23,chapter:         7,versenumber:         2,verse:"有人告诉大卫家说：“亚兰与以法莲已经同盟。”王的心和百姓的心就都跳动，好像林中的树被风吹动一样。"},
{volumn:        23,chapter:         7,versenumber:         3,verse:"耶和华对以赛亚说：“你和你的儿子施亚雅述出去，到上池的水沟头，在漂布地的大路上，去迎接亚哈斯，"},
{volumn:        23,chapter:         7,versenumber:         4,verse:"对他说：‘你要谨慎安静，不要因亚兰王利汛和利玛利的儿子这两个冒烟的火把头所发的烈怒害怕，也不要心里胆怯。"},
{volumn:        23,chapter:         7,versenumber:         5,verse:"因为亚兰和以法莲，并利玛利的儿子，设恶谋害你，"},
{volumn:        23,chapter:         7,versenumber:         6,verse:"说：我们可以上去攻击犹大，扰乱它，攻破它，在其中立他比勒的儿子为王。’"},
{volumn:        23,chapter:         7,versenumber:         7,verse:"所以主耶和华如此说：这所谋的必立不住，也不得成就。"},
{volumn:        23,chapter:         7,versenumber:         8,verse:"原来亚兰的首城是大马士革；大马士革的首领是利汛。（六十五年之内，以法莲必然破坏，不再成为国民。）"},
{volumn:        23,chapter:         7,versenumber:         9,verse:"以法莲的首城是撒玛利亚；撒玛利亚的首领是利玛利的儿子。你们若是不信，定然不得立稳。”"},
{volumn:        23,chapter:         7,versenumber:        10,verse:"耶和华又晓谕亚哈斯说："},
{volumn:        23,chapter:         7,versenumber:        11,verse:"“你向耶和华你的　神求一个兆头：或求显在深处，或求显在高处。”"},
{volumn:        23,chapter:         7,versenumber:        12,verse:"亚哈斯说：“我不求；我不试探耶和华。”"},
{volumn:        23,chapter:         7,versenumber:        13,verse:"以赛亚说：“大卫家啊，你们当听！你们使人厌烦岂算小事，还要使我的　神厌烦吗？"},
{volumn:        23,chapter:         7,versenumber:        14,verse:"因此，主自己要给你们一个兆头，必有童女怀孕生子，给他起名叫以马内利（就是　神与我们同在的意思）。"},
{volumn:        23,chapter:         7,versenumber:        15,verse:"到他晓得弃恶择善的时候，他必吃奶油与蜂蜜。"},
{volumn:        23,chapter:         7,versenumber:        16,verse:"因为在这孩子还不晓得弃恶择善之先，你所憎恶的那二王之地必致见弃。"},
{volumn:        23,chapter:         7,versenumber:        17,verse:"耶和华必使亚述王攻击你的日子临到你和你的百姓，并你的父家，自从以法莲离开犹大以来，未曾有这样的日子。"},
{volumn:        23,chapter:         7,versenumber:        18,verse:"“那时，耶和华要发嘶声，使埃及江河源头的苍蝇和亚述地的蜂子飞来；"},
{volumn:        23,chapter:         7,versenumber:        19,verse:"都必飞来，落在荒凉的谷内、磐石的穴里，和一切荆棘篱笆中，并一切的草场上。"},
{volumn:        23,chapter:         7,versenumber:        20,verse:"“那时，主必用大河外赁的剃头刀，就是亚述王，剃去头发和脚上的毛，并要剃净胡须。"},
{volumn:        23,chapter:         7,versenumber:        21,verse:"“那时，一个人要养活一只母牛犊，两只母绵羊；"},
{volumn:        23,chapter:         7,versenumber:        22,verse:"因为出的奶多，他就得吃奶油，在境内所剩的人都要吃奶油与蜂蜜。"},
{volumn:        23,chapter:         7,versenumber:        23,verse:"“从前，凡种一千棵葡萄树、值银一千舍客勒的地方，到那时必长荆棘和蒺藜。"},
{volumn:        23,chapter:         7,versenumber:        24,verse:"人上那里去，必带弓箭，因为遍地满了荆棘和蒺藜。"},
{volumn:        23,chapter:         7,versenumber:        25,verse:"所有用锄刨挖的山地，你因怕荆棘和蒺藜，不敢上那里去；只可成了放牛之处，为羊践踏之地。”"},
{volumn:        23,chapter:         8,versenumber:         1,verse:"耶和华对我说：“你取一个大牌，拿人所用的笔（或译：人常用的字），写上‘玛黑珥·沙拉勒·哈施·罢斯’（就是掳掠速临、抢夺快到的意思）。"},
{volumn:        23,chapter:         8,versenumber:         2,verse:"我要用诚实的见证人，祭司乌利亚和耶比利家的儿子撒迦利亚记录这事。”"},
{volumn:        23,chapter:         8,versenumber:         3,verse:"我以赛亚与妻子（原文是女先知）同室；她怀孕生子，耶和华就对我说：“给他起名叫玛黑珥·沙拉勒·哈施·罢斯；"},
{volumn:        23,chapter:         8,versenumber:         4,verse:"因为在这小孩子不晓得叫父叫母之先，大马士革的财宝和撒玛利亚的掳物必在亚述王面前搬了去。”"},
{volumn:        23,chapter:         8,versenumber:         5,verse:"耶和华又晓谕我说："},
{volumn:        23,chapter:         8,versenumber:         6,verse:"“这百姓既厌弃西罗亚缓流的水，喜悦利汛和利玛利的儿子；"},
{volumn:        23,chapter:         8,versenumber:         7,verse:"因此，主必使大河翻腾的水猛然冲来，就是亚述王和他所有的威势，必漫过一切的水道，涨过两岸；"},
{volumn:        23,chapter:         8,versenumber:         8,verse:"必冲入犹大，涨溢泛滥，直到颈项。以马内利啊，他展开翅膀，遍满你的地。”"},
{volumn:        23,chapter:         8,versenumber:         9,verse:"列国的人民哪，任凭你们喧嚷，终必破坏；远方的众人哪，当侧耳而听！任凭你们束起腰来，终必破坏；你们束起腰来，终必破坏。"},
{volumn:        23,chapter:         8,versenumber:        10,verse:"任凭你们同谋，终归无有；任凭你们言定，终不成立；因为　神与我们同在。"},
{volumn:        23,chapter:         8,versenumber:        11,verse:"耶和华以大能的手，指教我不可行这百姓所行的道，对我这样说："},
{volumn:        23,chapter:         8,versenumber:        12,verse:"“这百姓说同谋背叛，你们不要说同谋背叛。他们所怕的，你们不要怕，也不要畏惧。"},
{volumn:        23,chapter:         8,versenumber:        13,verse:"但要尊万军之耶和华为圣，以他为你们所当怕的，所当畏惧的。"},
{volumn:        23,chapter:         8,versenumber:        14,verse:"他必作为圣所，却向以色列两家作绊脚的石头，跌人的磐石；向耶路撒冷的居民作为圈套和网罗。"},
{volumn:        23,chapter:         8,versenumber:        15,verse:"许多人必在其上绊脚跌倒，而且跌碎，并陷入网罗，被缠住。”"},
{volumn:        23,chapter:         8,versenumber:        16,verse:"你要卷起律法书，在我门徒中间封住训诲。"},
{volumn:        23,chapter:         8,versenumber:        17,verse:"我要等候那掩面不顾雅各家的耶和华；我也要仰望他。"},
{volumn:        23,chapter:         8,versenumber:        18,verse:"看哪，我与耶和华所给我的儿女，就是从住在锡安山万军之耶和华来的，在以色列中作为预兆和奇迹。"},
{volumn:        23,chapter:         8,versenumber:        19,verse:"有人对你们说：“当求问那些交鬼的和行巫术的，就是声音绵蛮，言语微细的。”你们便回答说：“百姓不当求问自己的　神吗？岂可为活人求问死人呢？”"},
{volumn:        23,chapter:         8,versenumber:        20,verse:"人当以训诲和法度为标准；他们所说的，若不与此相符，必不得见晨光。"},
{volumn:        23,chapter:         8,versenumber:        21,verse:"他们必经过这地，受艰难，受饥饿；饥饿的时候，心中焦躁，咒骂自己的君王和自己的　神。"},
{volumn:        23,chapter:         8,versenumber:        22,verse:"仰观上天，俯察下地，不料，尽是艰难、黑暗，和幽暗的痛苦。他们必被赶入乌黑的黑暗中去。"},
{volumn:        23,chapter:         9,versenumber:         1,verse:"但那受过痛苦的必不再见幽暗。从前　神使西布伦地和拿弗他利地被藐视，末后却使这沿海的路，约旦河外，外邦人的加利利地得着荣耀。"},
{volumn:        23,chapter:         9,versenumber:         2,verse:"在黑暗中行走的百姓看见了大光；住在死荫之地的人有光照耀他们。"},
{volumn:        23,chapter:         9,versenumber:         3,verse:"你使这国民繁多，加增他们的喜乐；他们在你面前欢喜，好像收割的欢喜，像人分掳物那样的快乐。"},
{volumn:        23,chapter:         9,versenumber:         4,verse:"因为他们所负的重轭和肩头上的杖，并欺压他们人的棍，你都已经折断，好像在米甸的日子一样。"},
{volumn:        23,chapter:         9,versenumber:         5,verse:"战士在乱杀之间所穿戴的盔甲，并那滚在血中的衣服，都必作为可烧的，当作火柴。"},
{volumn:        23,chapter:         9,versenumber:         6,verse:"因有一婴孩为我们而生，有一子赐给我们，政权必担在他的肩头上。他名称为奇妙、策士、全能的　神、永在的父、和平的君。"},
{volumn:        23,chapter:         9,versenumber:         7,verse:"他的政权与平安必加增无穷。他必在大卫的宝座上治理他的国，以公平公义使国坚定稳固，从今直到永远。万军之耶和华的热心必成就这事。"},
{volumn:        23,chapter:         9,versenumber:         8,verse:"主使一言入于雅各家，落于以色列家。"},
{volumn:        23,chapter:         9,versenumber:         9,verse:"这众百姓，就是以法莲和撒玛利亚的居民，都要知道；他们凭骄傲自大的心说："},
{volumn:        23,chapter:         9,versenumber:        10,verse:"“砖墙塌了，我们却要凿石头建筑；桑树砍了，我们却要换香柏树。”"},
{volumn:        23,chapter:         9,versenumber:        11,verse:"因此，耶和华要高举利汛的敌人来攻击以色列，并要激动以色列的仇敌。"},
{volumn:        23,chapter:         9,versenumber:        12,verse:"东有亚兰人，西有非利士人；他们张口要吞吃以色列。虽然如此，耶和华的怒气还未转消；他的手仍伸不缩。"},
{volumn:        23,chapter:         9,versenumber:        13,verse:"这百姓还没有归向击打他们的主，也没有寻求万军之耶和华。"},
{volumn:        23,chapter:         9,versenumber:        14,verse:"因此，耶和华一日之间必从以色列中剪除头与尾，棕枝与芦苇。"},
{volumn:        23,chapter:         9,versenumber:        15,verse:"长老和尊贵人就是头，以谎言教人的先知就是尾。"},
{volumn:        23,chapter:         9,versenumber:        16,verse:"因为，引导这百姓的使他们走错了路；被引导的都必败亡。"},
{volumn:        23,chapter:         9,versenumber:        17,verse:"所以，主必不喜悦他们的少年人，也不怜恤他们的孤儿寡妇；因为，各人是亵渎的，是行恶的，并且各人的口都说愚妄的话。虽然如此，耶和华的怒气还未转消；他的手仍伸不缩。"},
{volumn:        23,chapter:         9,versenumber:        18,verse:"邪恶像火焚烧，烧灭荆棘和蒺藜，在稠密的树林中着起来，就成为烟柱，旋转上腾。"},
{volumn:        23,chapter:         9,versenumber:        19,verse:"因万军之耶和华的烈怒，地都烧遍；百姓成为火柴；无人怜爱弟兄。"},
{volumn:        23,chapter:         9,versenumber:        20,verse:"有人右边抢夺，仍受饥饿，左边吞吃，仍不饱足；各人吃自己膀臂上的肉。"},
{volumn:        23,chapter:         9,versenumber:        21,verse:"玛拿西吞吃（或译：攻击；下同）以法莲；以法莲吞吃玛拿西，又一同攻击犹大。虽然如此，耶和华的怒气还未转消；他的手仍伸不缩。"},
{volumn:        23,chapter:        10,versenumber:         1,verse:"祸哉！那些设立不义之律例的和记录奸诈之判语的，"},
{volumn:        23,chapter:        10,versenumber:         2,verse:"为要屈枉穷乏人，夺去我民中困苦人的理，以寡妇当作掳物，以孤儿当作掠物。"},
{volumn:        23,chapter:        10,versenumber:         3,verse:"到降罚的日子，有灾祸从远方临到，那时，你们怎样行呢？你们向谁逃奔求救呢？你们的荣耀（或译：财宝）存留何处呢？"},
{volumn:        23,chapter:        10,versenumber:         4,verse:"他们只得屈身在被掳的人以下，仆倒在被杀的人以下。虽然如此，耶和华的怒气还未转消；他的手仍伸不缩。"},
{volumn:        23,chapter:        10,versenumber:         5,verse:"亚述是我怒气的棍，手中拿我恼恨的杖。"},
{volumn:        23,chapter:        10,versenumber:         6,verse:"我要打发他攻击亵渎的国民，吩咐他攻击我所恼怒的百姓，抢财为掳物，夺货为掠物，将他们践踏，像街上的泥土一样。"},
{volumn:        23,chapter:        10,versenumber:         7,verse:"然而，他不是这样的意思；他心也不这样打算。他心里倒想毁灭，剪除不少的国。"},
{volumn:        23,chapter:        10,versenumber:         8,verse:"他说：“我的臣仆岂不都是王吗？"},
{volumn:        23,chapter:        10,versenumber:         9,verse:"迦勒挪岂不像迦基米施吗？哈马岂不像亚珥拔吗？撒玛利亚岂不像大马士革吗？"},
{volumn:        23,chapter:        10,versenumber:        10,verse:"我手已经够到有偶像的国；这些国雕刻的偶像过于耶路撒冷和撒玛利亚的偶像。"},
{volumn:        23,chapter:        10,versenumber:        11,verse:"我怎样待撒玛利亚和其中的偶像，岂不照样待耶路撒冷和其中的偶像吗？”"},
{volumn:        23,chapter:        10,versenumber:        12,verse:"主在锡安山和耶路撒冷成就他一切工作的时候，主说：“我必罚亚述王自大的心和他高傲眼目的荣耀。”"},
{volumn:        23,chapter:        10,versenumber:        13,verse:"因为他说：“我所成就的事是靠我手的能力和我的智慧，我本有聪明。我挪移列国的地界，抢夺他们所积蓄的财宝；并且我像勇士，使坐宝座的降为卑。"},
{volumn:        23,chapter:        10,versenumber:        14,verse:"我的手够到列国的财宝，好像人够到鸟窝；我也得了全地，好像人拾起所弃的雀蛋。没有动翅膀的；没有张嘴的，也没有鸣叫的。”"},
{volumn:        23,chapter:        10,versenumber:        15,verse:"斧岂可向用斧砍木的自夸呢？锯岂可向用锯的自大呢？好比棍抡起那举棍的，好比杖举起那非木的人。"},
{volumn:        23,chapter:        10,versenumber:        16,verse:"因此，主万军之耶和华必使亚述王的肥壮人变为瘦弱，在他的荣华之下必有火着起，如同焚烧一样。"},
{volumn:        23,chapter:        10,versenumber:        17,verse:"以色列的光必如火；他的圣者必如火焰。在一日之间，将亚述王的荆棘和蒺藜焚烧净尽；"},
{volumn:        23,chapter:        10,versenumber:        18,verse:"又将他树林和肥田的荣耀全然烧尽，好像拿军旗的昏过去一样。"},
{volumn:        23,chapter:        10,versenumber:        19,verse:"他林中剩下的树必稀少，就是孩子也能写其数。"},
{volumn:        23,chapter:        10,versenumber:        20,verse:"到那日，以色列所剩下的和雅各家所逃脱的，不再倚靠那击打他们的，却要诚实倚靠耶和华以色列的圣者。"},
{volumn:        23,chapter:        10,versenumber:        21,verse:"所剩下的，就是雅各家所剩下的，必归回全能的　神。"},
{volumn:        23,chapter:        10,versenumber:        22,verse:"以色列啊，你的百姓虽多如海沙，惟有剩下的归回。原来灭绝的事已定，必有公义施行，如水涨溢。"},
{volumn:        23,chapter:        10,versenumber:        23,verse:"因为主万军之耶和华在全地之中必成就所定规的结局。"},
{volumn:        23,chapter:        10,versenumber:        24,verse:"所以，主万军之耶和华如此说：“住锡安我的百姓啊，亚述王虽然用棍击打你，又照埃及的样子举杖攻击你，你却不要怕他。"},
{volumn:        23,chapter:        10,versenumber:        25,verse:"因为还有一点点时候，向你们发的忿恨就要完毕，我的怒气要向他发作，使他灭亡。"},
{volumn:        23,chapter:        10,versenumber:        26,verse:"万军之耶和华要兴起鞭来攻击他，好像在俄立磐石那里杀戮米甸人一样。耶和华的杖要向海伸出，把杖举起，像在埃及一样。"},
{volumn:        23,chapter:        10,versenumber:        27,verse:"到那日，亚述王的重担必离开你的肩头；他的轭必离开你的颈项；那轭也必因肥壮的缘故撑断（或译：因膏油的缘故毁坏）。”"},
{volumn:        23,chapter:        10,versenumber:        28,verse:"亚述王来到亚叶，经过米矶仑，在密抹安放辎重。"},
{volumn:        23,chapter:        10,versenumber:        29,verse:"他们过了隘口，在迦巴住宿。拉玛人战兢；扫罗的基比亚人逃跑。"},
{volumn:        23,chapter:        10,versenumber:        30,verse:"迦琳的居民（原文是女子）哪，要高声呼喊！莱煞人哪，须听！哀哉！困苦的亚拿突啊。"},
{volumn:        23,chapter:        10,versenumber:        31,verse:"玛得米那人躲避；基柄的居民逃遁。"},
{volumn:        23,chapter:        10,versenumber:        32,verse:"当那日，亚述王要在挪伯歇兵，向锡安女子的山，就是耶路撒冷的山，抡手攻他。"},
{volumn:        23,chapter:        10,versenumber:        33,verse:"看哪，主万军之耶和华以惊吓削去树枝；长高的必被砍下，高大的必被伐倒。"},
{volumn:        23,chapter:        10,versenumber:        34,verse:"稠密的树林，他要用铁器砍下；黎巴嫩的树木必被大能者伐倒。"},
{volumn:        23,chapter:        11,versenumber:         1,verse:"从耶西的本（原文是墩）必发一条；从他根生的枝子必结果实。"},
{volumn:        23,chapter:        11,versenumber:         2,verse:"耶和华的灵必住在他身上，就是使他有智慧和聪明的灵，谋略和能力的灵，知识和敬畏耶和华的灵。"},
{volumn:        23,chapter:        11,versenumber:         3,verse:"他必以敬畏耶和华为乐；行审判不凭眼见，断是非也不凭耳闻；"},
{volumn:        23,chapter:        11,versenumber:         4,verse:"却要以公义审判贫穷人，以正直判断世上的谦卑人，以口中的杖击打世界，以嘴里的气杀戮恶人。"},
{volumn:        23,chapter:        11,versenumber:         5,verse:"公义必当他的腰带；信实必当他胁下的带子。"},
{volumn:        23,chapter:        11,versenumber:         6,verse:"豺狼必与绵羊羔同居，豹子与山羊羔同卧；少壮狮子与牛犊并肥畜同群；小孩子要牵引它们。"},
{volumn:        23,chapter:        11,versenumber:         7,verse:"牛必与熊同食；牛犊必与小熊同卧；狮子必吃草，与牛一样。"},
{volumn:        23,chapter:        11,versenumber:         8,verse:"吃奶的孩子必玩耍在虺蛇的洞口；断奶的婴儿必按手在毒蛇的穴上。"},
{volumn:        23,chapter:        11,versenumber:         9,verse:"在我圣山的遍处，这一切都不伤人，不害物；因为认识耶和华的知识要充满遍地，好像水充满洋海一般。"},
{volumn:        23,chapter:        11,versenumber:        10,verse:"到那日，耶西的根立作万民的大旗；外邦人必寻求他，他安息之所大有荣耀。"},
{volumn:        23,chapter:        11,versenumber:        11,verse:"当那日，主必二次伸手救回自己百姓中所余剩的，就是在亚述、埃及、巴忒罗、古实、以拦、示拿、哈马，并众海岛所剩下的。"},
{volumn:        23,chapter:        11,versenumber:        12,verse:"他必向列国竖立大旗，招回以色列被赶散的人，又从地的四方聚集分散的犹大人。"},
{volumn:        23,chapter:        11,versenumber:        13,verse:"以法莲的嫉妒就必消散；扰害犹大的必被剪除。以法莲必不嫉妒犹大，犹大也不扰害以法莲。"},
{volumn:        23,chapter:        11,versenumber:        14,verse:"他们要向西飞，扑在非利士人的肩头上（肩头上：或译西界），一同掳掠东方人，伸手按住以东和摩押；亚扪人也必顺服他们。"},
{volumn:        23,chapter:        11,versenumber:        15,verse:"耶和华必使埃及海汊枯干，抡手用暴热的风使大河分为七条，令人过去不致湿脚。"},
{volumn:        23,chapter:        11,versenumber:        16,verse:"为主余剩的百姓，就是从亚述剩下回来的，必有一条大道，如当日以色列从埃及地上来一样。"},
{volumn:        23,chapter:        12,versenumber:         1,verse:"到那日，你必说：“耶和华啊，我要称谢你！因为你虽然向我发怒，你的怒气却已转消；你又安慰了我。"},
{volumn:        23,chapter:        12,versenumber:         2,verse:"看哪！　神是我的拯救；我要倚靠他，并不惧怕。因为主耶和华是我的力量，是我的诗歌，他也成了我的拯救。”"},
{volumn:        23,chapter:        12,versenumber:         3,verse:"所以，你们必从救恩的泉源欢然取水。"},
{volumn:        23,chapter:        12,versenumber:         4,verse:"在那日，你们要说：“当称谢耶和华，求告他的名；将他所行的传扬在万民中，提说他的名已被尊崇。"},
{volumn:        23,chapter:        12,versenumber:         5,verse:"你们要向耶和华唱歌，因他所行的甚是美好；但愿这事普传天下。"},
{volumn:        23,chapter:        12,versenumber:         6,verse:"锡安的居民哪，当扬声欢呼，因为在你们中间的以色列圣者乃为至大。”"},
{volumn:        23,chapter:        13,versenumber:         1,verse:"亚摩斯的儿子以赛亚得默示，论巴比伦。"},
{volumn:        23,chapter:        13,versenumber:         2,verse:"应当在净光的山竖立大旗，向群众扬声招手，使他们进入贵胄的门。"},
{volumn:        23,chapter:        13,versenumber:         3,verse:"我吩咐我所挑出来的人；我招呼我的勇士，就是那矜夸高傲之辈，为要成就我怒中所定的。"},
{volumn:        23,chapter:        13,versenumber:         4,verse:"山间有多人的声音，好像是大国人民。有许多国的民聚集哄嚷的声音；这是万军之耶和华点齐军队，预备打仗。"},
{volumn:        23,chapter:        13,versenumber:         5,verse:"他们从远方来，从天边来，就是耶和华并他恼恨的兵器要毁灭这全地。"},
{volumn:        23,chapter:        13,versenumber:         6,verse:"你们要哀号，因为耶和华的日子临近了！这日来到，好像毁灭从全能者来到。"},
{volumn:        23,chapter:        13,versenumber:         7,verse:"所以，人手都必软弱；人心都必消化。"},
{volumn:        23,chapter:        13,versenumber:         8,verse:"他们必惊惶悲痛；愁苦必将他们抓住。他们疼痛，好像产难的妇人一样，彼此惊奇相看，脸如火焰。"},
{volumn:        23,chapter:        13,versenumber:         9,verse:"耶和华的日子临到，必有残忍、忿恨、烈怒，使这地荒凉，从其中除灭罪人。"},
{volumn:        23,chapter:        13,versenumber:        10,verse:"天上的众星群宿都不发光；日头一出就变黑暗；月亮也不放光。"},
{volumn:        23,chapter:        13,versenumber:        11,verse:"我必因邪恶刑罚世界，因罪孽刑罚恶人，使骄傲人的狂妄止息，制伏强暴人的狂傲。"},
{volumn:        23,chapter:        13,versenumber:        12,verse:"我必使人比精金还少，使人比俄斐纯金更少。"},
{volumn:        23,chapter:        13,versenumber:        13,verse:"我万军之耶和华在忿恨中发烈怒的日子，必使天震动，使地摇撼，离其本位。"},
{volumn:        23,chapter:        13,versenumber:        14,verse:"人必像被追赶的鹿，像无人收聚的羊，各归回本族，各逃到本土。"},
{volumn:        23,chapter:        13,versenumber:        15,verse:"凡被仇敌追上的必被刺死；凡被捉住的必被刀杀。"},
{volumn:        23,chapter:        13,versenumber:        16,verse:"他们的婴孩必在他们眼前摔碎；他们的房屋必被抢夺；他们的妻子必被玷污。"},
{volumn:        23,chapter:        13,versenumber:        17,verse:"我必激动玛代人来攻击他们。玛代人不注重银子，也不喜爱金子。"},
{volumn:        23,chapter:        13,versenumber:        18,verse:"他们必用弓击碎少年人，不怜悯妇人所生的，眼也不顾惜孩子。"},
{volumn:        23,chapter:        13,versenumber:        19,verse:"巴比伦素来为列国的荣耀，为迦勒底人所矜夸的华美，必像　神所倾覆的所多玛、蛾摩拉一样。"},
{volumn:        23,chapter:        13,versenumber:        20,verse:"其内必永无人烟，世世代代无人居住。阿拉伯人也不在那里支搭帐棚；牧羊的人也不使羊群卧在那里。"},
{volumn:        23,chapter:        13,versenumber:        21,verse:"只有旷野的走兽卧在那里；咆哮的兽满了房屋。鸵鸟住在那里；野山羊在那里跳舞。"},
{volumn:        23,chapter:        13,versenumber:        22,verse:"豺狼必在它宫中呼号；野狗必在它华美殿内吼叫。巴比伦受罚的时候临近；它的日子必不长久。"},
{volumn:        23,chapter:        14,versenumber:         1,verse:"耶和华要怜恤雅各，必再拣选以色列，将他们安置在本地。寄居的必与他们联合，紧贴雅各家。"},
{volumn:        23,chapter:        14,versenumber:         2,verse:"外邦人必将他们带回本土；以色列家必在耶和华的地上得外邦人为仆婢，也要掳掠先前掳掠他们的，辖制先前欺压他们的。"},
{volumn:        23,chapter:        14,versenumber:         3,verse:"当耶和华使你脱离愁苦、烦恼，并人勉强你做的苦工，得享安息的日子，"},
{volumn:        23,chapter:        14,versenumber:         4,verse:"你必题这诗歌论巴比伦王说：“欺压人的何竟息灭？强暴的何竟止息？"},
{volumn:        23,chapter:        14,versenumber:         5,verse:"耶和华折断了恶人的杖，辖制人的圭，"},
{volumn:        23,chapter:        14,versenumber:         6,verse:"就是在忿怒中连连攻击众民的，在怒气中辖制列国，行逼迫无人阻止的。"},
{volumn:        23,chapter:        14,versenumber:         7,verse:"现在全地得安息，享平静；人皆发声欢呼。"},
{volumn:        23,chapter:        14,versenumber:         8,verse:"松树和黎巴嫩的香柏树都因你欢乐，说：‘自从你仆倒，再无人上来砍伐我们。’"},
{volumn:        23,chapter:        14,versenumber:         9,verse:"你下到阴间，阴间就因你震动来迎接你，又因你惊动在世曾为首领的阴魂，并使那曾为列国君王的，都离位站起。"},
{volumn:        23,chapter:        14,versenumber:        10,verse:"他们都要发言对你说：‘你也变为软弱像我们一样吗？你也成了我们的样子吗？’"},
{volumn:        23,chapter:        14,versenumber:        11,verse:"你的威势和你琴瑟的声音都下到阴间。你下铺的是虫，上盖的是蛆。"},
{volumn:        23,chapter:        14,versenumber:        12,verse:"“明亮之星，早晨之子啊，你何竟从天坠落？你这攻败列国的何竟被砍倒在地上？"},
{volumn:        23,chapter:        14,versenumber:        13,verse:"你心里曾说：‘我要升到天上；我要高举我的宝座在　神众星以上；我要坐在聚会的山上，在北方的极处。"},
{volumn:        23,chapter:        14,versenumber:        14,verse:"我要升到高云之上；我要与至上者同等。’"},
{volumn:        23,chapter:        14,versenumber:        15,verse:"然而，你必坠落阴间，到坑中极深之处。"},
{volumn:        23,chapter:        14,versenumber:        16,verse:"凡看见你的都要定睛看你，留意看你，说：‘使大地战抖，使列国震动，"},
{volumn:        23,chapter:        14,versenumber:        17,verse:"使世界如同荒野，使城邑倾覆，不释放被掳的人归家，是这个人吗？’"},
{volumn:        23,chapter:        14,versenumber:        18,verse:"列国的君王俱各在自己阴宅的荣耀中安睡。"},
{volumn:        23,chapter:        14,versenumber:        19,verse:"惟独你被抛弃，不得入你的坟墓，好像可憎的枝子，以被杀的人为衣，就是被刀刺透，坠落坑中石头那里的；你又像被践踏的尸首一样。"},
{volumn:        23,chapter:        14,versenumber:        20,verse:"你不得与君王同葬；因为你败坏你的国，杀戮你的民。“恶人后裔的名，必永不提说。"},
{volumn:        23,chapter:        14,versenumber:        21,verse:"先人既有罪孽，就要预备杀戮他的子孙，免得他们兴起来，得了遍地，在世上修满城邑。”"},
{volumn:        23,chapter:        14,versenumber:        22,verse:"万军之耶和华说：“我必兴起攻击他们，将巴比伦的名号和所余剩的人，连子带孙一并剪除。这是耶和华说的。"},
{volumn:        23,chapter:        14,versenumber:        23,verse:"我必使巴比伦为箭猪所得，又变为水池；我要用灭亡的扫帚扫净它。这是万军之耶和华说的。”"},
{volumn:        23,chapter:        14,versenumber:        24,verse:"万军之耶和华起誓说：“我怎样思想，必照样成就；我怎样定意，必照样成立，"},
{volumn:        23,chapter:        14,versenumber:        25,verse:"就是在我地上打折亚述人，在我山上将他践踏。他加的轭必离开以色列人；他加的重担必离开他们的肩头。"},
{volumn:        23,chapter:        14,versenumber:        26,verse:"这是向全地所定的旨意；这是向万国所伸出的手。”"},
{volumn:        23,chapter:        14,versenumber:        27,verse:"万军之耶和华既然定意，谁能废弃呢？他的手已经伸出，谁能转回呢？"},
{volumn:        23,chapter:        14,versenumber:        28,verse:"亚哈斯王崩的那年，就有以下的默示："},
{volumn:        23,chapter:        14,versenumber:        29,verse:"非利士全地啊，不要因击打你的杖折断就喜乐。因为从蛇的根必生出毒蛇；它所生的是火焰的飞龙。"},
{volumn:        23,chapter:        14,versenumber:        30,verse:"贫寒人的长子必有所食；穷乏人必安然躺卧。我必以饥荒治死你的根；你所余剩的人必被杀戮。"},
{volumn:        23,chapter:        14,versenumber:        31,verse:"门哪，应当哀号！城啊，应当呼喊！非利士全地啊，你都消化了！因为有烟从北方出来，他行伍中并无乱队的。"},
{volumn:        23,chapter:        14,versenumber:        32,verse:"可怎样回答外邦（或指非利士）的使者呢？必说：“耶和华建立了锡安；他百姓中的困苦人必投奔在其中。”"},
{volumn:        23,chapter:        15,versenumber:         1,verse:"论摩押的默示：一夜之间，摩押的亚珥变为荒废，归于无有；一夜之间，摩押的基珥变为荒废，归于无有。"},
{volumn:        23,chapter:        15,versenumber:         2,verse:"他们上巴益，又往底本，到高处去哭泣。摩押人因尼波和米底巴哀号，各人头上光秃，胡须剃净。"},
{volumn:        23,chapter:        15,versenumber:         3,verse:"他们在街市上都腰束麻布，在房顶上和宽阔处俱各哀号，眼泪汪汪。"},
{volumn:        23,chapter:        15,versenumber:         4,verse:"希实本和以利亚利悲哀的声音达到雅杂，所以摩押带兵器的高声喊嚷，人心战兢。"},
{volumn:        23,chapter:        15,versenumber:         5,verse:"我心为摩押悲哀；他的贵胄（或译：逃民）逃到琐珥，到伊基拉·施利施亚。他们上鲁希坡，随走随哭；在何罗念的路上，因毁灭举起哀声。"},
{volumn:        23,chapter:        15,versenumber:         6,verse:"因为宁林的水成为干涸，青草枯干，嫩草灭没，青绿之物，一无所有。"},
{volumn:        23,chapter:        15,versenumber:         7,verse:"因此，摩押人所得的财物和所积蓄的都要运过柳树河。"},
{volumn:        23,chapter:        15,versenumber:         8,verse:"哀声遍闻摩押的四境；哀号的声音达到以基莲；哀号的声音达到比珥·以琳。"},
{volumn:        23,chapter:        15,versenumber:         9,verse:"底们的水充满了血；我还要加增底们的灾难，叫狮子来追上摩押逃脱的民和那地上所余剩的人。"},
{volumn:        23,chapter:        16,versenumber:         1,verse:"你们当将羊羔奉给那地掌权的，从西拉往旷野，送到锡安城（原文是女子）的山。"},
{volumn:        23,chapter:        16,versenumber:         2,verse:"摩押的居民（原文是女子）在亚嫩渡口，必像游飞的鸟，如拆窝的雏。"},
{volumn:        23,chapter:        16,versenumber:         3,verse:"求你献谋略，行公平，使你的影子在午间如黑夜，隐藏被赶散的人，不可显露逃民。"},
{volumn:        23,chapter:        16,versenumber:         4,verse:"求你容我这被赶散的人和你同居。至于摩押，求你作他的隐密处，脱离灭命者的面。勒索人的归于无有，毁灭的事止息了，欺压人的从国中除灭了，"},
{volumn:        23,chapter:        16,versenumber:         5,verse:"必有宝座因慈爱坚立；必有一位诚诚实实坐在其上，在大卫帐幕中施行审判，寻求公平，速行公义。"},
{volumn:        23,chapter:        16,versenumber:         6,verse:"我们听说摩押人骄傲，是极其骄傲；听说他狂妄、骄傲、忿怒；他夸大的话是虚空的。"},
{volumn:        23,chapter:        16,versenumber:         7,verse:"因此，摩押人必为摩押哀号；人人都要哀号。你们摩押人要为吉珥·哈列设的葡萄饼哀叹，极其忧伤。"},
{volumn:        23,chapter:        16,versenumber:         8,verse:"因为希实本的田地和西比玛的葡萄树都衰残了。列国的君主折断其上美好的枝子；这枝子长到雅谢延到旷野，嫩枝向外探出，直探过盐海。"},
{volumn:        23,chapter:        16,versenumber:         9,verse:"因此，我要为西比玛的葡萄树哀哭，与雅谢人哀哭一样。希实本、以利亚利啊，我要以眼泪浇灌你；因为有交战呐喊的声音临到你夏天的果子，并你收割的庄稼。"},
{volumn:        23,chapter:        16,versenumber:        10,verse:"从肥美的田中夺去了欢喜快乐；在葡萄园里必无歌唱，也无欢呼的声音。踹酒的在酒榨中不得踹出酒来；我使他欢呼的声音止息。"},
{volumn:        23,chapter:        16,versenumber:        11,verse:"因此，我心腹为摩押哀鸣如琴；我心肠为吉珥·哈列设也是如此。"},
{volumn:        23,chapter:        16,versenumber:        12,verse:"摩押人朝见的时候，在高处疲乏，又到他圣所祈祷，也不蒙应允。"},
{volumn:        23,chapter:        16,versenumber:        13,verse:"这是耶和华从前论摩押的话。"},
{volumn:        23,chapter:        16,versenumber:        14,verse:"但现在耶和华说：“三年之内，照雇工的年数，摩押的荣耀与他的群众必被藐视，余剩的人甚少无几。”"},
{volumn:        23,chapter:        17,versenumber:         1,verse:"论大马士革的默示：看哪，大马士革已被废弃，不再为城，必变作乱堆。"},
{volumn:        23,chapter:        17,versenumber:         2,verse:"亚罗珥的城邑已被撇弃，必成为牧羊之处；羊在那里躺卧，无人惊吓。"},
{volumn:        23,chapter:        17,versenumber:         3,verse:"以法莲不再有保障；大马士革不再有国权；亚兰所剩下的必像以色列人的荣耀消灭一样。这是万军之耶和华说的。"},
{volumn:        23,chapter:        17,versenumber:         4,verse:"到那日，雅各的荣耀必至枵薄；他肥胖的身体必渐瘦弱。"},
{volumn:        23,chapter:        17,versenumber:         5,verse:"就必像收割的人收敛禾稼，用手割取穗子，又像人在利乏音谷拾取遗落的穗子。"},
{volumn:        23,chapter:        17,versenumber:         6,verse:"其间所剩下的不多，好像人打橄榄树，在尽上的枝梢上只剩两三个果子；在多果树的旁枝上只剩四五个果子。这是耶和华以色列的　神说的。"},
{volumn:        23,chapter:        17,versenumber:         7,verse:"当那日，人必仰望造他们的主，眼目重看以色列的圣者。"},
{volumn:        23,chapter:        17,versenumber:         8,verse:"他们必不仰望祭坛，就是自己手所筑的，也不重看自己指头所做的，无论是木偶是日像。"},
{volumn:        23,chapter:        17,versenumber:         9,verse:"在那日，他们的坚固城必像树林中和山顶上所撇弃的地方，就是从前在以色列人面前被人撇弃的。这样，地就荒凉了。"},
{volumn:        23,chapter:        17,versenumber:        10,verse:"因你忘记救你的　神，不记念你能力的磐石；所以，你栽上佳美的树秧子，插上异样的栽子。"},
{volumn:        23,chapter:        17,versenumber:        11,verse:"栽种的日子，你周围圈上篱笆，又到早晨使你所种的开花；但在愁苦极其伤痛的日子，所收割的都飞去了。"},
{volumn:        23,chapter:        17,versenumber:        12,verse:"唉！多民哄嚷，好像海浪砰訇；列邦奔腾，好像猛水滔滔；"},
{volumn:        23,chapter:        17,versenumber:        13,verse:"列邦奔腾，好像多水滔滔；但　神斥责他们，他们就远远逃避，又被追赶，如同山上的风前糠，又如暴风前的旋风土。"},
{volumn:        23,chapter:        17,versenumber:        14,verse:"到晚上有惊吓，未到早晨他们就没有了。这是掳掠我们之人所得的份，是抢夺我们之人的报应。"},
{volumn:        23,chapter:        18,versenumber:         1,verse:"唉！古实河外翅膀刷刷响声之地，"},
{volumn:        23,chapter:        18,versenumber:         2,verse:"差遣使者在水面上，坐蒲草船过海。先知说：“你们快行的使者，要到高大光滑的民那里去。自从开国以来，那民极其可畏，是分地界践踏人的；他们的地有江河分开。”"},
{volumn:        23,chapter:        18,versenumber:         3,verse:"世上一切的居民和地上所住的人哪，山上竖立大旗的时候你们要看；吹角的时候你们要听。"},
{volumn:        23,chapter:        18,versenumber:         4,verse:"耶和华对我这样说：“我要安静，在我的居所观看，如同日光中的清热，又如露水的云雾在收割的热天。”"},
{volumn:        23,chapter:        18,versenumber:         5,verse:"收割之先，花开已谢，花也成了将熟的葡萄；他必用镰刀削去嫩枝，又砍掉蔓延的枝条，"},
{volumn:        23,chapter:        18,versenumber:         6,verse:"都要撇给山间的鸷鸟和地上的野兽。夏天，鸷鸟要宿在其上；冬天，野兽都卧在其中。"},
{volumn:        23,chapter:        18,versenumber:         7,verse:"到那时，这高大光滑的民，就是从开国以来极其可畏、分地界践踏人的，他们的地有江河分开；他们必将礼物奉给万军之耶和华，就是奉到锡安山，耶和华安置他名的地方。"},
{volumn:        23,chapter:        19,versenumber:         1,verse:"论埃及的默示：看哪，耶和华乘驾快云，临到埃及。埃及的偶像在他面前战兢；埃及人的心在里面消化。"},
{volumn:        23,chapter:        19,versenumber:         2,verse:"我必激动埃及人攻击埃及人，弟兄攻击弟兄，邻舍攻击邻舍；这城攻击那城，这国攻击那国。"},
{volumn:        23,chapter:        19,versenumber:         3,verse:"埃及人的心神必在里面耗尽；我必败坏他们的谋略。他们必求问偶像和念咒的、交鬼的、行巫术的。"},
{volumn:        23,chapter:        19,versenumber:         4,verse:"我必将埃及人交在残忍主的手中；强暴王必辖制他们。这是主万军之耶和华说的。"},
{volumn:        23,chapter:        19,versenumber:         5,verse:"海中的水必绝尽，河也消没干涸。"},
{volumn:        23,chapter:        19,versenumber:         6,verse:"江河要变臭；埃及的河水都必减少枯干。苇子和芦荻都必衰残；"},
{volumn:        23,chapter:        19,versenumber:         7,verse:"靠尼罗河旁的草田，并沿尼罗河所种的田，都必枯干。庄稼被风吹去，归于无有。"},
{volumn:        23,chapter:        19,versenumber:         8,verse:"打鱼的必哀哭。在尼罗河一切钓鱼的必悲伤；在水上撒网的必都衰弱。"},
{volumn:        23,chapter:        19,versenumber:         9,verse:"用梳好的麻造物的和织白布的都必羞愧；"},
{volumn:        23,chapter:        19,versenumber:        10,verse:"国柱必被打碎，所有佣工的，心必愁烦。"},
{volumn:        23,chapter:        19,versenumber:        11,verse:"琐安的首领极其愚昧；法老大有智慧的谋士，所筹划的成为愚谋。你们怎敢对法老说：“我是智慧人的子孙，我是古王的后裔？”"},
{volumn:        23,chapter:        19,versenumber:        12,verse:"你的智慧人在哪里呢？万军之耶和华向埃及所定的旨意，他们可以知道，可以告诉你吧！"},
{volumn:        23,chapter:        19,versenumber:        13,verse:"琐安的首领都变为愚昧；挪弗的首领都受了迷惑。当埃及支派房角石的，使埃及人走错了路。"},
{volumn:        23,chapter:        19,versenumber:        14,verse:"耶和华使乖谬的灵搀入埃及中间；首领使埃及一切所做的都有差错，好像醉酒之人呕吐的时候东倒西歪一样。"},
{volumn:        23,chapter:        19,versenumber:        15,verse:"埃及中，无论是头与尾，棕枝与芦苇，所做之工都不成就。"},
{volumn:        23,chapter:        19,versenumber:        16,verse:"到那日，埃及人必像妇人一样，他们必因万军之耶和华在埃及以上所抡的手，战兢惧怕。"},
{volumn:        23,chapter:        19,versenumber:        17,verse:"犹大地必使埃及惊恐，向谁提起犹大地，谁就惧怕。这是因万军之耶和华向埃及所定的旨意。"},
{volumn:        23,chapter:        19,versenumber:        18,verse:"当那日，埃及地必有五城的人说迦南的方言，又指着万军之耶和华起誓。有一城必称为“灭亡城”。"},
{volumn:        23,chapter:        19,versenumber:        19,verse:"当那日，在埃及地中必有为耶和华筑的一座坛；在埃及的边界上必有为耶和华立的一根柱。"},
{volumn:        23,chapter:        19,versenumber:        20,verse:"这都要在埃及地为万军之耶和华作记号和证据。埃及人因为受人的欺压哀求耶和华，他就差遣一位救主作护卫者，拯救他们。"},
{volumn:        23,chapter:        19,versenumber:        21,verse:"耶和华必被埃及人所认识。在那日，埃及人必认识耶和华，也要献祭物和供物敬拜他，并向耶和华许愿还愿。"},
{volumn:        23,chapter:        19,versenumber:        22,verse:"耶和华必击打埃及，又击打又医治，埃及人就归向耶和华。他必应允他们的祷告，医治他们。"},
{volumn:        23,chapter:        19,versenumber:        23,verse:"当那日，必有从埃及通亚述去的大道。亚述人要进入埃及，埃及人也进入亚述；埃及人要与亚述人一同敬拜耶和华。"},
{volumn:        23,chapter:        19,versenumber:        24,verse:"当那日，以色列必与埃及、亚述三国一律，使地上的人得福；"},
{volumn:        23,chapter:        19,versenumber:        25,verse:"因为万军之耶和华赐福给他们，说：“埃及我的百姓，亚述我手的工作，以色列我的产业，都有福了！”"},
{volumn:        23,chapter:        20,versenumber:         1,verse:"亚述王撒珥根打发他珥探到亚实突的那年，他珥探就攻打亚实突，将城攻取。"},
{volumn:        23,chapter:        20,versenumber:         2,verse:"那时，耶和华晓谕亚摩斯的儿子以赛亚说：“你去解掉你腰间的麻布，脱下你脚上的鞋。”以赛亚就这样做，露身赤脚行走。"},
{volumn:        23,chapter:        20,versenumber:         3,verse:"耶和华说：“我仆人以赛亚怎样露身赤脚行走三年，作为关乎埃及和古实的预兆奇迹。"},
{volumn:        23,chapter:        20,versenumber:         4,verse:"照样，亚述王也必掳去埃及人，掠去古实人，无论老少，都露身赤脚，现出下体，使埃及蒙羞。"},
{volumn:        23,chapter:        20,versenumber:         5,verse:"以色列人必因所仰望的古实，所夸耀的埃及，惊惶羞愧。"},
{volumn:        23,chapter:        20,versenumber:         6,verse:"那时，这沿海一带的居民必说：‘看哪，我们素所仰望的，就是我们为脱离亚述王逃往求救的，不过是如此！我们怎能逃脱呢？’”"},
{volumn:        23,chapter:        21,versenumber:         1,verse:"论海旁旷野的默示：有仇敌从旷野，从可怕之地而来，好像南方的旋风，猛然扫过。"},
{volumn:        23,chapter:        21,versenumber:         2,verse:"令人凄惨的异象已默示于我。诡诈的行诡诈，毁灭的行毁灭。以拦哪，你要上去！玛代啊，你要围困！主说：“我使一切叹息止住。”"},
{volumn:        23,chapter:        21,versenumber:         3,verse:"所以，我满腰疼痛；痛苦将我抓住，好像产难的妇人一样。我疼痛甚至不能听；我惊惶甚至不能看。"},
{volumn:        23,chapter:        21,versenumber:         4,verse:"我心慌张，惊恐威吓我。我所羡慕的黄昏，变为我的战兢。"},
{volumn:        23,chapter:        21,versenumber:         5,verse:"他们摆设筵席，派人守望，又吃又喝。首领啊，你们起来，用油抹盾牌。"},
{volumn:        23,chapter:        21,versenumber:         6,verse:"主对我如此说：“你去设立守望的，使他将所看见的述说。”"},
{volumn:        23,chapter:        21,versenumber:         7,verse:"他看见军队，就是骑马的一对一对地来，又看见驴队，骆驼队，就要侧耳细听。"},
{volumn:        23,chapter:        21,versenumber:         8,verse:"他像狮子吼叫，说：“主啊，我白日常站在望楼上，整夜立在我守望所。”"},
{volumn:        23,chapter:        21,versenumber:         9,verse:"看哪，有一队军兵骑着马，一对一对地来。他就说：“巴比伦倾倒了！倾倒了！他一切雕刻的神像都打碎于地。”"},
{volumn:        23,chapter:        21,versenumber:        10,verse:"我被打的禾稼，我场上的谷啊，我从万军之耶和华以色列的　神那里所听见的，都告诉你们了。"},
{volumn:        23,chapter:        21,versenumber:        11,verse:"论度玛的默示：有人声从西珥呼问我说：“守望的啊，夜里如何？守望的啊，夜里如何？”"},
{volumn:        23,chapter:        21,versenumber:        12,verse:"守望的说：“早晨将到，黑夜也来。你们若要问就可以问，可以回头再来。”"},
{volumn:        23,chapter:        21,versenumber:        13,verse:"论阿拉伯的默示：底但结伴的客旅啊，你们必在阿拉伯的树林中住宿。"},
{volumn:        23,chapter:        21,versenumber:        14,verse:"提玛地的居民拿水来，送给口渴的，拿饼来迎接逃避的。"},
{volumn:        23,chapter:        21,versenumber:        15,verse:"因为他们逃避刀剑和出了鞘的刀，并上了弦的弓与刀兵的重灾。"},
{volumn:        23,chapter:        21,versenumber:        16,verse:"主对我这样说：“一年之内，照雇工的年数，基达的一切荣耀必归于无有。"},
{volumn:        23,chapter:        21,versenumber:        17,verse:"弓箭手所余剩的，就是基达人的勇士，必然稀少，因为这是耶和华以色列的　神说的。”"},
{volumn:        23,chapter:        22,versenumber:         1,verse:"论异象谷的默示：有什么事使你这满城的人都上房顶呢？"},
{volumn:        23,chapter:        22,versenumber:         2,verse:"你这满处呐喊、大有喧哗的城，欢乐的邑啊，你中间被杀的并不是被刀杀，也不是因打仗死亡。"},
{volumn:        23,chapter:        22,versenumber:         3,verse:"你所有的官长一同逃跑，都为弓箭手所捆绑。你中间一切被找到的都一同被捆绑；他们本是逃往远方的。"},
{volumn:        23,chapter:        22,versenumber:         4,verse:"所以我说：“你们转眼不看我，我要痛哭。不要因我众民（原文是民女）的毁灭，就竭力安慰我。”"},
{volumn:        23,chapter:        22,versenumber:         5,verse:"因为主万军之耶和华使“异象谷”有溃乱、践踏、烦扰的日子。城被攻破，哀声达到山间。"},
{volumn:        23,chapter:        22,versenumber:         6,verse:"以拦带着箭袋，还有坐战车的和马兵；吉珥揭开盾牌。"},
{volumn:        23,chapter:        22,versenumber:         7,verse:"你嘉美的谷遍满战车，也有马兵在城门前排列。"},
{volumn:        23,chapter:        22,versenumber:         8,verse:"他去掉犹大的遮盖。那日，你就仰望林库内的军器。"},
{volumn:        23,chapter:        22,versenumber:         9,verse:"你们看见大卫城的破口很多，便聚积下池的水，"},
{volumn:        23,chapter:        22,versenumber:        10,verse:"又数点耶路撒冷的房屋，将房屋拆毁，修补城墙，"},
{volumn:        23,chapter:        22,versenumber:        11,verse:"又在两道城墙中间挖一个聚水池可盛旧池的水，却不仰望做这事的主，也不顾念从古定这事的。"},
{volumn:        23,chapter:        22,versenumber:        12,verse:"当那日，主万军之耶和华叫人哭泣哀号，头上光秃，身披麻布。"},
{volumn:        23,chapter:        22,versenumber:        13,verse:"谁知，人倒欢喜快乐，宰牛杀羊，吃肉喝酒，说：“我们吃喝吧！因为明天要死了。”"},
{volumn:        23,chapter:        22,versenumber:        14,verse:"万军之耶和华亲自默示我说：“这罪孽直到你们死，断不得赦免！这是主万军之耶和华说的。”"},
{volumn:        23,chapter:        22,versenumber:        15,verse:"主万军之耶和华这样说：“你去见掌银库的，就是家宰舍伯那，对他说："},
{volumn:        23,chapter:        22,versenumber:        16,verse:"‘你在这里做什么呢？有什么人竟在这里凿坟墓，就是在高处为自己凿坟墓，在磐石中为自己凿出安身之所？"},
{volumn:        23,chapter:        22,versenumber:        17,verse:"看哪，耶和华必像大有力的人，将你紧紧缠裹，竭力抛去。"},
{volumn:        23,chapter:        22,versenumber:        18,verse:"他必将你滚成一团，抛在宽阔之地，好像抛球一样。你这主人家的羞辱，必在那里坐你荣耀的车，也必在那里死亡。"},
{volumn:        23,chapter:        22,versenumber:        19,verse:"我必赶逐你离开官职；你必从你的原位撤下。’"},
{volumn:        23,chapter:        22,versenumber:        20,verse:"“到那日，我必召我仆人希勒家的儿子以利亚敬来，"},
{volumn:        23,chapter:        22,versenumber:        21,verse:"将你的外袍给他穿上，将你的腰带给他系紧，将你的政权交在他手中。他必作耶路撒冷居民和犹大家的父。"},
{volumn:        23,chapter:        22,versenumber:        22,verse:"我必将大卫家的钥匙放在他肩头上。他开，无人能关；他关，无人能开。"},
{volumn:        23,chapter:        22,versenumber:        23,verse:"我必将他安稳，像钉子钉在坚固处；他必作为他父家荣耀的宝座。"},
{volumn:        23,chapter:        22,versenumber:        24,verse:"他父家所有的荣耀，连儿女带子孙，都挂在他身上，好像一切小器皿，从杯子到酒瓶挂上一样。"},
{volumn:        23,chapter:        22,versenumber:        25,verse:"万军之耶和华说：当那日，钉在坚固处的钉子必压斜，被砍断落地；挂在其上的重担必被剪断。因为这是耶和华说的。”"},
{volumn:        23,chapter:        23,versenumber:         1,verse:"论推罗的默示：他施的船只都要哀号；因为推罗变为荒场，甚至没有房屋，没有可进之路。这消息是从基提地得来的。"},
{volumn:        23,chapter:        23,versenumber:         2,verse:"沿海的居民，就是素来靠航海西顿的商家得丰盛的，你们当静默无言。"},
{volumn:        23,chapter:        23,versenumber:         3,verse:"在大水之上，西曷的粮食、尼罗河的庄稼是推罗的进项；她作列国的大码头。"},
{volumn:        23,chapter:        23,versenumber:         4,verse:"西顿哪，你当惭愧；因为大海说，就是海中的保障说：“我没有劬劳，也没有生产，没有养育男子，也没有抚养童女。”"},
{volumn:        23,chapter:        23,versenumber:         5,verse:"这风声传到埃及；埃及人为推罗的风声极其疼痛。"},
{volumn:        23,chapter:        23,versenumber:         6,verse:"推罗人哪，你们当过到他施去；沿海的居民哪，你们都当哀号。"},
{volumn:        23,chapter:        23,versenumber:         7,verse:"这是你们欢乐的城，从上古而有的吗？其中的居民往远方寄居。"},
{volumn:        23,chapter:        23,versenumber:         8,verse:"推罗本是赐冠冕的。她的商家是王子；她的买卖人是世上的尊贵人。遭遇如此是谁定的呢？"},
{volumn:        23,chapter:        23,versenumber:         9,verse:"是万军之耶和华所定的！为要污辱一切高傲的荣耀，使地上一切的尊贵人被藐视。"},
{volumn:        23,chapter:        23,versenumber:        10,verse:"他施的民（原文是女）哪，可以流行你的地，好像尼罗河；不再有腰带拘紧你。"},
{volumn:        23,chapter:        23,versenumber:        11,verse:"耶和华已经向海伸手，震动列国。至于迦南，他已经吩咐拆毁其中的保障。"},
{volumn:        23,chapter:        23,versenumber:        12,verse:"他又说：“受欺压西顿的居民（原文是处女）哪，你必不得再欢乐。起来！过到基提去；就是在那里也不得安歇。”"},
{volumn:        23,chapter:        23,versenumber:        13,verse:"看哪，迦勒底人之地向来没有这民，这国是亚述人为住旷野的人所立的。现在他们建筑戍楼，拆毁推罗的宫殿，使她成为荒凉。"},
{volumn:        23,chapter:        23,versenumber:        14,verse:"他施的船只都要哀号，因为你们的保障变为荒场。"},
{volumn:        23,chapter:        23,versenumber:        15,verse:"到那时，推罗必被忘记七十年，照着一王的年日。七十年后，推罗的景况必像妓女所唱的歌："},
{volumn:        23,chapter:        23,versenumber:        16,verse:"“你这被忘记的妓女啊，拿琴周流城内，巧弹多唱，使人再想念你。”"},
{volumn:        23,chapter:        23,versenumber:        17,verse:"七十年后，耶和华必眷顾推罗，她就仍得利息（原文是雇价；下同），与地上的万国交易（原文是行淫）。"},
{volumn:        23,chapter:        23,versenumber:        18,verse:"她的货财和利息要归耶和华为圣，必不积攒存留；因为她的货财必为住在耶和华面前的人所得，使他们吃饱，穿耐久的衣服。"},
{volumn:        23,chapter:        24,versenumber:         1,verse:"看哪，耶和华使地空虚，变为荒凉；又翻转大地，将居民分散。"},
{volumn:        23,chapter:        24,versenumber:         2,verse:"那时百姓怎样，祭司也怎样；仆人怎样，主人也怎样；婢女怎样，主母也怎样；买物的怎样，卖物的也怎样；放债的怎样，借债的也怎样；取利的怎样，出利的也怎样。"},
{volumn:        23,chapter:        24,versenumber:         3,verse:"地必全然空虚，尽都荒凉；因为这话是耶和华说的。"},
{volumn:        23,chapter:        24,versenumber:         4,verse:"地上悲哀衰残，世界败落衰残；地上居高位的人也败落了。"},
{volumn:        23,chapter:        24,versenumber:         5,verse:"地被其上的居民污秽；因为他们犯了律法，废了律例，背了永约。"},
{volumn:        23,chapter:        24,versenumber:         6,verse:"所以，地被咒诅吞灭；住在其上的显为有罪。地上的居民被火焚烧，剩下的人稀少。"},
{volumn:        23,chapter:        24,versenumber:         7,verse:"新酒悲哀，葡萄树衰残；心中欢乐的俱都叹息。"},
{volumn:        23,chapter:        24,versenumber:         8,verse:"击鼓之乐止息；宴乐人的声音完毕，弹琴之乐也止息了。"},
{volumn:        23,chapter:        24,versenumber:         9,verse:"人必不得饮酒唱歌；喝浓酒的，必以为苦。"},
{volumn:        23,chapter:        24,versenumber:        10,verse:"荒凉的城拆毁了；各家关门闭户，使人都不得进去。"},
{volumn:        23,chapter:        24,versenumber:        11,verse:"在街上因酒有悲叹的声音；一切喜乐变为昏暗；地上的欢乐归于无有。"},
{volumn:        23,chapter:        24,versenumber:        12,verse:"城中只有荒凉；城门拆毁净尽。"},
{volumn:        23,chapter:        24,versenumber:        13,verse:"在地上的万民中，必像打过的橄榄树，又像已摘的葡萄所剩无几。"},
{volumn:        23,chapter:        24,versenumber:        14,verse:"这些人要高声欢呼；他们为耶和华的威严，从海那里扬起声来。"},
{volumn:        23,chapter:        24,versenumber:        15,verse:"因此，你们要在东方荣耀耶和华，在众海岛荣耀耶和华以色列　神的名。"},
{volumn:        23,chapter:        24,versenumber:        16,verse:"我们听见从地极有人歌唱，说：“荣耀归于义人。”我却说：“我消灭了！我消灭了，我有祸了！诡诈的行诡诈；诡诈的大行诡诈。”"},
{volumn:        23,chapter:        24,versenumber:        17,verse:"地上的居民哪，恐惧、陷坑、网罗都临近你。"},
{volumn:        23,chapter:        24,versenumber:        18,verse:"躲避恐惧声音的必坠入陷坑；从陷坑上来的必被网罗缠住；因为天上的窗户都开了，地的根基也震动了。"},
{volumn:        23,chapter:        24,versenumber:        19,verse:"地全然破坏，尽都崩裂，大大地震动了。"},
{volumn:        23,chapter:        24,versenumber:        20,verse:"地要东倒西歪，好像醉酒的人；又摇来摇去，好像吊床。罪过在其上沉重，必然塌陷，不能复起。"},
{volumn:        23,chapter:        24,versenumber:        21,verse:"到那日，耶和华在高处必惩罚高处的众军，在地上必惩罚地上的列王。"},
{volumn:        23,chapter:        24,versenumber:        22,verse:"他们必被聚集，像囚犯被聚在牢狱中，并要囚在监牢里，多日之后便被讨罪（或译：眷顾）。"},
{volumn:        23,chapter:        24,versenumber:        23,verse:"那时，月亮要蒙羞，日头要惭愧；因为万军之耶和华必在锡安山，在耶路撒冷作王；在敬畏他的长老面前，必有荣耀。"},
{volumn:        23,chapter:        25,versenumber:         1,verse:"耶和华啊，你是我的　神；我要尊崇你，我要称赞你的名。因为你以忠信诚实行过奇妙的事，成就你古时所定的。"},
{volumn:        23,chapter:        25,versenumber:         2,verse:"你使城变为乱堆，使坚固城变为荒场，使外邦人宫殿的城不再为城，永远不再建造。"},
{volumn:        23,chapter:        25,versenumber:         3,verse:"所以，刚强的民必荣耀你；强暴之国的城必敬畏你。"},
{volumn:        23,chapter:        25,versenumber:         4,verse:"因为当强暴人催逼人的时候，如同暴风直吹墙壁，你就作贫穷人的保障，作困乏人急难中的保障，作躲暴风之处，作避炎热的阴凉。"},
{volumn:        23,chapter:        25,versenumber:         5,verse:"你要压制外邦人的喧哗，好像干燥地的热气下落；禁止强暴人的凯歌，好像热气被云影消化。"},
{volumn:        23,chapter:        25,versenumber:         6,verse:"在这山上，万军之耶和华必为万民用肥甘设摆筵席，用陈酒和满髓的肥甘，并澄清的陈酒，设摆筵席。"},
{volumn:        23,chapter:        25,versenumber:         7,verse:"他又必在这山上除灭遮盖万民之物和遮蔽万国蒙脸的帕子。"},
{volumn:        23,chapter:        25,versenumber:         8,verse:"他已经吞灭死亡直到永远。主耶和华必擦去各人脸上的眼泪，又除掉普天下他百姓的羞辱，因为这是耶和华说的。"},
{volumn:        23,chapter:        25,versenumber:         9,verse:"到那日，人必说：“看哪，这是我们的　神；我们素来等候他，他必拯救我们。这是耶和华，我们素来等候他，我们必因他的救恩欢喜快乐。”"},
{volumn:        23,chapter:        25,versenumber:        10,verse:"耶和华的手必按在这山上；摩押人在所居之地必被践踏，好像干草被践踏在粪池的水中。"},
{volumn:        23,chapter:        25,versenumber:        11,verse:"他必在其中伸开手，好像洑水的伸开手洑水一样；但耶和华必使他的骄傲和他手所行的诡计一并败落。"},
{volumn:        23,chapter:        25,versenumber:        12,verse:"耶和华使你城上的坚固高台倾倒，拆平，直到尘埃。"},
{volumn:        23,chapter:        26,versenumber:         1,verse:"当那日，在犹大地人必唱这歌说：“我们有坚固的城。耶和华要将救恩定为城墙，为外郭。"},
{volumn:        23,chapter:        26,versenumber:         2,verse:"敞开城门，使守信的义民得以进入。"},
{volumn:        23,chapter:        26,versenumber:         3,verse:"坚心倚赖你的，你必保守他十分平安，因为他倚靠你。"},
{volumn:        23,chapter:        26,versenumber:         4,verse:"你们当倚靠耶和华直到永远，因为耶和华是永久的磐石。"},
{volumn:        23,chapter:        26,versenumber:         5,verse:"他使住高处的与高城一并败落，将城拆毁，拆平，直到尘埃，"},
{volumn:        23,chapter:        26,versenumber:         6,verse:"要被脚践踏，就是被困苦人的脚和穷乏人的脚践踏。”"},
{volumn:        23,chapter:        26,versenumber:         7,verse:"义人的道是正直的；你为正直的主，必修平义人的路。"},
{volumn:        23,chapter:        26,versenumber:         8,verse:"耶和华啊，我们在你行审判的路上等候你；我们心里所羡慕的是你的名，就是你那可记念的名。"},
{volumn:        23,chapter:        26,versenumber:         9,verse:"夜间，我心中羡慕你；我里面的灵切切寻求你。因为你在世上行审判的时候，地上的居民就学习公义。"},
{volumn:        23,chapter:        26,versenumber:        10,verse:"以恩惠待恶人，他仍不学习公义；在正直的地上，他必行事不义，也不注意耶和华的威严。"},
{volumn:        23,chapter:        26,versenumber:        11,verse:"耶和华啊，你的手高举，他们仍然不看；却要看你为百姓发的热心，因而抱愧，并且有火烧灭你的敌人。"},
{volumn:        23,chapter:        26,versenumber:        12,verse:"耶和华啊，你必派定我们得平安，因为我们所做的事都是你给我们成就的。"},
{volumn:        23,chapter:        26,versenumber:        13,verse:"耶和华我们的　神啊，在你以外曾有别的主管辖我们，但我们专要倚靠你，提你的名。"},
{volumn:        23,chapter:        26,versenumber:        14,verse:"他们死了，必不能再活；他们去世，必不能再起；因为你刑罚他们，毁灭他们，他们的名号就全然消灭。"},
{volumn:        23,chapter:        26,versenumber:        15,verse:"耶和华啊，你增添国民，你增添国民；你得了荣耀，又扩张地的四境。"},
{volumn:        23,chapter:        26,versenumber:        16,verse:"耶和华啊，他们在急难中寻求你；你的惩罚临到他们身上，他们就倾心吐胆祷告你。"},
{volumn:        23,chapter:        26,versenumber:        17,verse:"妇人怀孕，临产疼痛，在痛苦之中喊叫；耶和华啊，我们在你面前也是如此。"},
{volumn:        23,chapter:        26,versenumber:        18,verse:"我们也曾怀孕疼痛，所产的竟像风一样。我们在地上未曾行什么拯救的事；世上的居民也未曾败落。"},
{volumn:        23,chapter:        26,versenumber:        19,verse:"死人（原文是你的死人）要复活，尸首（原文是我的尸首）要兴起。睡在尘埃的啊，要醒起歌唱！因你的甘露好像菜蔬上的甘露，地也要交出死人来。"},
{volumn:        23,chapter:        26,versenumber:        20,verse:"我的百姓啊，你们要来进入内室，关上门，隐藏片时，等到忿怒过去。"},
{volumn:        23,chapter:        26,versenumber:        21,verse:"因为耶和华从他的居所出来，要刑罚地上居民的罪孽。地也必露出其中的血，不再掩盖被杀的人。"},
{volumn:        23,chapter:        27,versenumber:         1,verse:"到那日，耶和华必用他刚硬有力的大刀刑罚鳄鱼，就是那快行的蛇；刑罚鳄鱼，就是那曲行的蛇，并杀海中的大鱼。"},
{volumn:        23,chapter:        27,versenumber:         2,verse:"当那日，有出酒的葡萄园，你们要指这园唱歌说："},
{volumn:        23,chapter:        27,versenumber:         3,verse:"“我耶和华是看守葡萄园的；我必时刻浇灌，昼夜看守，免得有人损害。"},
{volumn:        23,chapter:        27,versenumber:         4,verse:"我心中不存忿怒。惟愿荆棘蒺藜与我交战，我就勇往直前，把他一同焚烧。"},
{volumn:        23,chapter:        27,versenumber:         5,verse:"不然，让他持住我的能力，使他与我和好，愿他与我和好。”"},
{volumn:        23,chapter:        27,versenumber:         6,verse:"将来雅各要扎根，以色列要发芽开花；他们的果实必充满世界。"},
{volumn:        23,chapter:        27,versenumber:         7,verse:"主击打他们，岂像击打那些击打他们的人吗？他们被杀戮，岂像被他们所杀戮的吗？"},
{volumn:        23,chapter:        27,versenumber:         8,verse:"你打发他们去，是相机宜与他们相争；刮东风的日子，就用暴风将他们逐去。"},
{volumn:        23,chapter:        27,versenumber:         9,verse:"所以，雅各的罪孽得赦免，他的罪过得除掉的果效，全在乎此：就是他叫祭坛的石头变为打碎的灰石，以致木偶和日像不再立起。"},
{volumn:        23,chapter:        27,versenumber:        10,verse:"因为坚固城变为凄凉，成了撇下离弃的居所，像旷野一样；牛犊必在那里吃草，在那里躺卧，并吃尽其中的树枝。"},
{volumn:        23,chapter:        27,versenumber:        11,verse:"枝条枯干，必被折断；妇女要来点火烧着。因为这百姓蒙昧无知，所以，创造他们的必不怜恤他们；造成他们的也不施恩与他们。"},
{volumn:        23,chapter:        27,versenumber:        12,verse:"以色列人哪，到那日，耶和华必从大河，直到埃及小河，将你们一一地收集，如同人打树拾果一样。"},
{volumn:        23,chapter:        27,versenumber:        13,verse:"当那日，必大发角声，在亚述地将要灭亡的，并在埃及地被赶散的，都要来，他们就在耶路撒冷圣山上敬拜耶和华。"},
{volumn:        23,chapter:        28,versenumber:         1,verse:"祸哉！以法莲的酒徒，住在肥美谷的山上，他们心里高傲，以所夸的为冠冕，犹如将残之花。"},
{volumn:        23,chapter:        28,versenumber:         2,verse:"看哪，主有一大能大力者，像一阵冰雹，像毁灭的暴风，像涨溢的大水，他必用手将冠冕摔落于地。"},
{volumn:        23,chapter:        28,versenumber:         3,verse:"以法莲高傲的酒徒，他的冠冕必被踏在脚下。"},
{volumn:        23,chapter:        28,versenumber:         4,verse:"那荣美将残之花，就是在肥美谷山上的，必像夏令以前初熟的无花果；看见这果的就注意，一到手中就吞吃了。"},
{volumn:        23,chapter:        28,versenumber:         5,verse:"到那日，万军之耶和华必作他余剩之民的荣冠华冕，"},
{volumn:        23,chapter:        28,versenumber:         6,verse:"也作了在位上行审判者公平之灵，并城门口打退仇敌者的力量。"},
{volumn:        23,chapter:        28,versenumber:         7,verse:"就是这地的人也因酒摇摇晃晃，因浓酒东倒西歪。祭司和先知因浓酒摇摇晃晃，被酒所困，因浓酒东倒西歪。他们错解默示，谬行审判。"},
{volumn:        23,chapter:        28,versenumber:         8,verse:"因为各席上满了呕吐的污秽，无一处干净。"},
{volumn:        23,chapter:        28,versenumber:         9,verse:"讥诮先知的说：“他要将知识指教谁呢？要使谁明白传言呢？是那刚断奶离怀的吗？"},
{volumn:        23,chapter:        28,versenumber:        10,verse:"他竟命上加命，令上加令，律上加律，例上加例，这里一点，那里一点。”"},
{volumn:        23,chapter:        28,versenumber:        11,verse:"先知说：“不然，主要藉异邦人的嘴唇和外邦人的舌头对这百姓说话。”"},
{volumn:        23,chapter:        28,versenumber:        12,verse:"他曾对他们说：“你们要使疲乏人得安息，这样才得安息，才得舒畅。”他们却不肯听。"},
{volumn:        23,chapter:        28,versenumber:        13,verse:"所以，耶和华向他们说的话是命上加命，令上加令，律上加律，例上加例，这里一点，那里一点，以致他们前行仰面跌倒，而且跌碎，并陷入网罗被缠住。"},
{volumn:        23,chapter:        28,versenumber:        14,verse:"所以，你们这些亵慢的人，就是辖管住在耶路撒冷这百姓的，要听耶和华的话。"},
{volumn:        23,chapter:        28,versenumber:        15,verse:"你们曾说：“我们与死亡立约，与阴间结盟；敌军（原文是鞭子）如水涨漫经过的时候，必不临到我们；因我们以谎言为避所，在虚假以下藏身。”"},
{volumn:        23,chapter:        28,versenumber:        16,verse:"所以，主耶和华如此说：“看哪，我在锡安放一块石头作为根基，是试验过的石头，是稳固根基，宝贵的房角石；信靠的人必不着急。"},
{volumn:        23,chapter:        28,versenumber:        17,verse:"我必以公平为准绳，以公义为线铊。冰雹必冲去谎言的避所；大水必漫过藏身之处。”"},
{volumn:        23,chapter:        28,versenumber:        18,verse:"你们与死亡所立的约必然废掉，与阴间所结的盟必立不住。敌军（原文是鞭子）如水涨漫经过的时候，你们必被他践踏；"},
{volumn:        23,chapter:        28,versenumber:        19,verse:"每逢经过必将你们掳去。因为每早晨他必经过，白昼黑夜都必如此。明白传言的必受惊恐。"},
{volumn:        23,chapter:        28,versenumber:        20,verse:"原来，床榻短，使人不能舒身；被窝窄，使人不能遮体。"},
{volumn:        23,chapter:        28,versenumber:        21,verse:"耶和华必兴起，像在毗拉心山；他必发怒，像在基遍谷，好做成他的工，就是非常的工；成就他的事，就是奇异的事。"},
{volumn:        23,chapter:        28,versenumber:        22,verse:"现在你们不可亵慢，恐怕捆你们的绑索更结实了；因为我从主万军之耶和华那里听见，已经决定在全地上施行灭绝的事。"},
{volumn:        23,chapter:        28,versenumber:        23,verse:"你们当侧耳听我的声音，留心听我的言语。"},
{volumn:        23,chapter:        28,versenumber:        24,verse:"那耕地为要撒种的，岂是常常耕地呢？岂是常常开垦耙地呢？"},
{volumn:        23,chapter:        28,versenumber:        25,verse:"他拉平了地面，岂不就撒种小茴香，播种大茴香，按行列种小麦，在定处种大麦，在田边种粗麦呢？"},
{volumn:        23,chapter:        28,versenumber:        26,verse:"因为他的　神教导他务农相宜，并且指教他。"},
{volumn:        23,chapter:        28,versenumber:        27,verse:"原来打小茴香，不用尖利的器具，轧大茴香，也不用碌碡（原文是车轮；下同）；但用杖打小茴香，用棍打大茴香。"},
{volumn:        23,chapter:        28,versenumber:        28,verse:"做饼的粮食是用磨磨碎，因它不必常打；虽用碌碡和马打散，却不磨它。"},
{volumn:        23,chapter:        28,versenumber:        29,verse:"这也是出于万军之耶和华，他的谋略奇妙；他的智慧广大。"},
{volumn:        23,chapter:        29,versenumber:         1,verse:"唉！亚利伊勒，亚利伊勒，大卫安营的城，任凭你年上加年，节期照常周流。"},
{volumn:        23,chapter:        29,versenumber:         2,verse:"我终必使亚利伊勒困难；她必悲伤哀号，我却仍以她为亚利伊勒。"},
{volumn:        23,chapter:        29,versenumber:         3,verse:"我必四围安营攻击你，屯兵围困你，筑垒攻击你。"},
{volumn:        23,chapter:        29,versenumber:         4,verse:"你必败落，从地中说话；你的言语必微细出于尘埃。你的声音必像那交鬼者的声音出于地；你的言语低低微微出于尘埃。"},
{volumn:        23,chapter:        29,versenumber:         5,verse:"你仇敌的群众，却要像细尘；强暴人的群众，也要像飞糠。这事必顷刻之间忽然临到。"},
{volumn:        23,chapter:        29,versenumber:         6,verse:"万军之耶和华必用雷轰、地震、大声、旋风、暴风，并吞灭的火焰，向她讨罪。"},
{volumn:        23,chapter:        29,versenumber:         7,verse:"那时，攻击亚利伊勒列国的群众，就是一切攻击亚利伊勒和她的保障，并使她困难的，必如梦景，如夜间的异象；"},
{volumn:        23,chapter:        29,versenumber:         8,verse:"又必像饥饿的人梦中吃饭，醒了仍觉腹空；或像口渴的人梦中喝水，醒了仍觉发昏，心里想喝。攻击锡安山列国的群众也必如此。"},
{volumn:        23,chapter:        29,versenumber:         9,verse:"你们等候惊奇吧！你们宴乐昏迷吧！他们醉了，却非因酒；他们东倒西歪，却非因浓酒。"},
{volumn:        23,chapter:        29,versenumber:        10,verse:"因为耶和华将沉睡的灵浇灌你们，封闭你们的眼，蒙盖你们的头。你们的眼就是先知；你们的头就是先见。"},
{volumn:        23,chapter:        29,versenumber:        11,verse:"所有的默示，你们看如封住的书卷，人将这书卷交给识字的，说：“请念吧！”他说：“我不能念，因为是封住了。”"},
{volumn:        23,chapter:        29,versenumber:        12,verse:"又将这书卷交给不识字的人，说：“请念吧！”他说：“我不识字。”"},
{volumn:        23,chapter:        29,versenumber:        13,verse:"主说：“因为这百姓亲近我，用嘴唇尊敬我，心却远离我；他们敬畏我，不过是领受人的吩咐。"},
{volumn:        23,chapter:        29,versenumber:        14,verse:"所以，我在这百姓中要行奇妙的事，就是奇妙又奇妙的事。他们智慧人的智慧必然消灭，聪明人的聪明必然隐藏。”"},
{volumn:        23,chapter:        29,versenumber:        15,verse:"祸哉！那些向耶和华深藏谋略的，又在暗中行事，说：“谁看见我们呢？谁知道我们呢？”"},
{volumn:        23,chapter:        29,versenumber:        16,verse:"你们把事颠倒了，岂可看窑匠如泥吗？被制作的物岂可论制作物的说：他没有制作我？或是被创造的物论造物的说：他没有聪明？"},
{volumn:        23,chapter:        29,versenumber:        17,verse:"黎巴嫩变为肥田，肥田看如树林，不是只有一点点时候吗？"},
{volumn:        23,chapter:        29,versenumber:        18,verse:"那时，聋子必听见这书上的话；瞎子的眼必从迷蒙黑暗中得以看见。"},
{volumn:        23,chapter:        29,versenumber:        19,verse:"谦卑人必因耶和华增添欢喜；人间贫穷的必因以色列的圣者快乐。"},
{volumn:        23,chapter:        29,versenumber:        20,verse:"因为，强暴人已归无有，亵慢人已经灭绝，一切找机会作孽的都被剪除。"},
{volumn:        23,chapter:        29,versenumber:        21,verse:"他们在争讼的事上定无罪的为有罪，为城门口责备人的设下网罗，用虚无的事屈枉义人。"},
{volumn:        23,chapter:        29,versenumber:        22,verse:"所以，救赎亚伯拉罕的耶和华论雅各家如此说：“雅各必不再羞愧，面容也不致变色。"},
{volumn:        23,chapter:        29,versenumber:        23,verse:"但他看见他的众子，就是我手的工作在他那里，他们必尊我的名为圣，必尊雅各的圣者为圣，必敬畏以色列的　神。"},
{volumn:        23,chapter:        29,versenumber:        24,verse:"心中迷糊的必得明白；发怨言的必受训诲。”"},
{volumn:        23,chapter:        30,versenumber:         1,verse:"耶和华说：“祸哉！这悖逆的儿女。他们同谋，却不由于我，结盟，却不由于我的灵，以致罪上加罪；"},
{volumn:        23,chapter:        30,versenumber:         2,verse:"起身下埃及去，并没有求问我；要靠法老的力量加添自己的力量，并投在埃及的荫下。"},
{volumn:        23,chapter:        30,versenumber:         3,verse:"所以，法老的力量必作你们的羞辱；投在埃及的荫下，要为你们的惭愧。"},
{volumn:        23,chapter:        30,versenumber:         4,verse:"他们的首领已在琐安；他们的使臣到了哈内斯。"},
{volumn:        23,chapter:        30,versenumber:         5,verse:"他们必因那不利于他们的民蒙羞。那民并非帮助，也非利益，只作羞耻凌辱。”"},
{volumn:        23,chapter:        30,versenumber:         6,verse:"论南方牲畜的默示：他们把财物驮在驴驹的脊背上，将宝物驮在骆驼的肉鞍上，经过艰难困苦之地，就是公狮、母狮、蝮蛇、火焰的飞龙之地，往那不利于他们的民那里去。"},
{volumn:        23,chapter:        30,versenumber:         7,verse:"埃及的帮助是徒然无益的；所以我称她为“坐而不动的拉哈伯”。"},
{volumn:        23,chapter:        30,versenumber:         8,verse:"现今你去，在他们面前将这话刻在版上，写在书上，以便传留后世，直到永永远远。"},
{volumn:        23,chapter:        30,versenumber:         9,verse:"因为他们是悖逆的百姓、说谎的儿女，不肯听从耶和华训诲的儿女。"},
{volumn:        23,chapter:        30,versenumber:        10,verse:"他们对先见说：“不要望见不吉利的事”，对先知说：“不要向我们讲正直的话；要向我们说柔和的话，言虚幻的事。"},
{volumn:        23,chapter:        30,versenumber:        11,verse:"你们要离弃正道，偏离直路，不要在我们面前再提说以色列的圣者。”"},
{volumn:        23,chapter:        30,versenumber:        12,verse:"所以，以色列的圣者如此说：“因为你们藐视这训诲的话，倚赖欺压和乖僻，以此为可靠的，"},
{volumn:        23,chapter:        30,versenumber:        13,verse:"故此，这罪孽在你们身上，好像将要破裂凸出来的高墙，顷刻之间忽然坍塌；"},
{volumn:        23,chapter:        30,versenumber:        14,verse:"要被打碎，好像把窑匠的瓦器打碎，毫不顾惜，甚至碎块中找不到一片可用以从炉内取火，从池中舀水。”"},
{volumn:        23,chapter:        30,versenumber:        15,verse:"主耶和华以色列的圣者曾如此说：“你们得救在乎归回安息；你们得力在乎平静安稳”；你们竟自不肯。"},
{volumn:        23,chapter:        30,versenumber:        16,verse:"你们却说：“不然，我们要骑马奔走。”所以你们必然奔走；又说：“我们要骑飞快的牲口。”所以追赶你们的，也必飞快。"},
{volumn:        23,chapter:        30,versenumber:        17,verse:"一人叱喝，必令千人逃跑；五人叱喝，你们都必逃跑；以致剩下的，好像山顶的旗杆，冈上的大旗。"},
{volumn:        23,chapter:        30,versenumber:        18,verse:"耶和华必然等候，要施恩给你们；必然兴起，好怜悯你们。因为耶和华是公平的　神；凡等候他的都是有福的！"},
{volumn:        23,chapter:        30,versenumber:        19,verse:"百姓必在锡安、在耶路撒冷居住；你不再哭泣。主必因你哀求的声音施恩给你；他听见的时候就必应允你。"},
{volumn:        23,chapter:        30,versenumber:        20,verse:"主虽然以艰难给你当饼，以困苦给你当水，你的教师却不再隐藏；你眼必看见你的教师。"},
{volumn:        23,chapter:        30,versenumber:        21,verse:"你或向左或向右，你必听见后边有声音说：“这是正路，要行在其间。”"},
{volumn:        23,chapter:        30,versenumber:        22,verse:"你雕刻偶像所包的银子和铸造偶像所镀的金子，你要玷污，要抛弃，好像污秽之物，对偶像说：“去吧！”"},
{volumn:        23,chapter:        30,versenumber:        23,verse:"你将种子撒在地里，主必降雨在其上，并使地所出的粮肥美丰盛。到那时，你的牲畜必在宽阔的草场吃草。"},
{volumn:        23,chapter:        30,versenumber:        24,verse:"耕地的牛和驴驹必吃加盐的料；这料是用木杴和杈子扬净的。"},
{volumn:        23,chapter:        30,versenumber:        25,verse:"在大行杀戮的日子，高台倒塌的时候，各高山冈陵必有川流河涌。"},
{volumn:        23,chapter:        30,versenumber:        26,verse:"当耶和华缠裹他百姓的损处，医治他民鞭伤的日子，月光必像日光，日光必加七倍，像七日的光一样。"},
{volumn:        23,chapter:        30,versenumber:        27,verse:"看哪，耶和华的名从远方来，怒气烧起，密烟上腾。他的嘴唇满有忿恨；他的舌头像吞灭的火。"},
{volumn:        23,chapter:        30,versenumber:        28,verse:"他的气如涨溢的河水，直涨到颈项，要用毁灭的筛箩筛净列国，并且在众民的口中必有使人错行的嚼环。"},
{volumn:        23,chapter:        30,versenumber:        29,verse:"你们必唱歌，像守圣节的夜间一样，并且心中喜乐，像人吹笛，上耶和华的山，到以色列的磐石那里。"},
{volumn:        23,chapter:        30,versenumber:        30,verse:"耶和华必使人听他威严的声音，又显他降罚的膀臂和他怒中的忿恨，并吞灭的火焰与霹雷、暴风、冰雹。"},
{volumn:        23,chapter:        30,versenumber:        31,verse:"亚述人必因耶和华的声音惊惶；耶和华必用杖击打他。"},
{volumn:        23,chapter:        30,versenumber:        32,verse:"耶和华必将命定的杖加在他身上；每打一下，人必击鼓弹琴。打杖的时候，耶和华必抡起手来，与他交战。"},
{volumn:        23,chapter:        30,versenumber:        33,verse:"原来陀斐特又深又宽，早已为王预备好了；其中堆的是火与许多木柴。耶和华的气如一股硫磺火使他着起来。"},
{volumn:        23,chapter:        31,versenumber:         1,verse:"祸哉！那些下埃及求帮助的，是因仗赖马匹，倚靠甚多的车辆，并倚靠强壮的马兵，却不仰望以色列的圣者，也不求问耶和华。"},
{volumn:        23,chapter:        31,versenumber:         2,verse:"其实，耶和华有智慧；他必降灾祸，并不反悔自己的话，却要兴起攻击那作恶之家，又攻击那作孽帮助人的。"},
{volumn:        23,chapter:        31,versenumber:         3,verse:"埃及人不过是人，并不是　神；他们的马不过是血肉，并不是灵。耶和华一伸手，那帮助人的必绊跌，那受帮助的也必跌倒，都一同灭亡。"},
{volumn:        23,chapter:        31,versenumber:         4,verse:"耶和华对我如此说：狮子和少壮狮子护食咆哮，就是喊许多牧人来攻击它，它总不因他们的声音惊惶，也不因他们的喧哗缩伏。如此，万军之耶和华也必降临在锡安山冈上争战。"},
{volumn:        23,chapter:        31,versenumber:         5,verse:"雀鸟怎样搧翅覆雏，万军之耶和华也要照样保护耶路撒冷。他必保护拯救，要越门保守。"},
{volumn:        23,chapter:        31,versenumber:         6,verse:"以色列人哪，你们深深地悖逆耶和华，现今要归向他。"},
{volumn:        23,chapter:        31,versenumber:         7,verse:"到那日，各人必将他金偶像银偶像，就是亲手所造、陷自己在罪中的，都抛弃了。"},
{volumn:        23,chapter:        31,versenumber:         8,verse:"亚述人必倒在刀下，并非人的刀；有刀要将他吞灭，并非人的刀。他必逃避这刀；他的少年人必成为服苦的。"},
{volumn:        23,chapter:        31,versenumber:         9,verse:"他的磐石必因惊吓挪去；他的首领必因大旗惊惶。这是那有火在锡安、有炉在耶路撒冷的耶和华说的。"},
{volumn:        23,chapter:        32,versenumber:         1,verse:"看哪，必有一王凭公义行政；必有首领藉公平掌权。"},
{volumn:        23,chapter:        32,versenumber:         2,verse:"必有一人像避风所和避暴雨的隐密处，又像河流在干旱之地，像大磐石的影子在疲乏之地。"},
{volumn:        23,chapter:        32,versenumber:         3,verse:"那能看的人，眼不再昏迷；能听的人，耳必得听闻。"},
{volumn:        23,chapter:        32,versenumber:         4,verse:"冒失人的心必明白知识；结巴人的舌必说话通快。"},
{volumn:        23,chapter:        32,versenumber:         5,verse:"愚顽人不再称为高明；吝啬人不再称为大方。"},
{volumn:        23,chapter:        32,versenumber:         6,verse:"因为愚顽人必说愚顽话，心里想作罪孽，惯行亵渎的事，说错谬的话攻击耶和华，使饥饿的人无食可吃，使口渴的人无水可喝。"},
{volumn:        23,chapter:        32,versenumber:         7,verse:"吝啬人所用的法子是恶的；他图谋恶计，用谎言毁灭谦卑人；穷乏人讲公理的时候，他也是这样行。"},
{volumn:        23,chapter:        32,versenumber:         8,verse:"高明人却谋高明事，在高明事上也必永存。"},
{volumn:        23,chapter:        32,versenumber:         9,verse:"安逸的妇女啊，起来听我的声音！无虑的女子啊，侧耳听我的言语！"},
{volumn:        23,chapter:        32,versenumber:        10,verse:"无虑的女子啊，再过一年多，必受骚扰；因为无葡萄可摘，无果子（或译：禾稼）可收。"},
{volumn:        23,chapter:        32,versenumber:        11,verse:"安逸的妇女啊，要战兢；无虑的女子啊，要受骚扰。脱去衣服，赤着身体，腰束麻布。"},
{volumn:        23,chapter:        32,versenumber:        12,verse:"她们必为美好的田地和多结果的葡萄树，捶胸哀哭。"},
{volumn:        23,chapter:        32,versenumber:        13,verse:"荆棘蒺藜必长在我百姓的地上，又长在欢乐的城中和一切快乐的房屋上。"},
{volumn:        23,chapter:        32,versenumber:        14,verse:"因为宫殿必被撇下，多民的城必被离弃；山冈望楼永为洞穴，作野驴所喜乐的，为羊群的草场。"},
{volumn:        23,chapter:        32,versenumber:        15,verse:"等到圣灵从上浇灌我们，旷野就变为肥田，肥田看如树林。"},
{volumn:        23,chapter:        32,versenumber:        16,verse:"那时，公平要居在旷野；公义要居在肥田。"},
{volumn:        23,chapter:        32,versenumber:        17,verse:"公义的果效必是平安；公义的效验必是平稳，直到永远。"},
{volumn:        23,chapter:        32,versenumber:        18,verse:"我的百姓必住在平安的居所，安稳的住处，平静的安歇所。"},
{volumn:        23,chapter:        32,versenumber:        19,verse:"（但要降冰雹打倒树林；城必全然拆平。）"},
{volumn:        23,chapter:        32,versenumber:        20,verse:"你们在各水边撒种、牧放牛驴的有福了！"},
{volumn:        23,chapter:        33,versenumber:         1,verse:"祸哉！你这毁灭人的，自己倒不被毁灭；行事诡诈的，人倒不以诡诈待你。你毁灭罢休了，自己必被毁灭；你行完了诡诈，人必以诡诈待你。"},
{volumn:        23,chapter:        33,versenumber:         2,verse:"耶和华啊，求你施恩于我们；我们等候你。求你每早晨作我们的膀臂，遭难的时候为我们的拯救。"},
{volumn:        23,chapter:        33,versenumber:         3,verse:"喧嚷的响声一发，众民奔逃；你一兴起，列国四散。"},
{volumn:        23,chapter:        33,versenumber:         4,verse:"你们所掳的必被敛尽，好像蚂蚱吃（原文是敛）尽禾稼。人要蹦在其上，好像蝗虫一样。"},
{volumn:        23,chapter:        33,versenumber:         5,verse:"耶和华被尊崇，因他居在高处；他以公平公义充满锡安。"},
{volumn:        23,chapter:        33,versenumber:         6,verse:"你一生一世必得安稳，有丰盛的救恩，并智慧和知识；你以敬畏耶和华为至宝。"},
{volumn:        23,chapter:        33,versenumber:         7,verse:"看哪，他们的豪杰在外头哀号；求和的使臣痛痛哭泣。"},
{volumn:        23,chapter:        33,versenumber:         8,verse:"大路荒凉，行人止息；敌人背约，藐视城邑，不顾人民。"},
{volumn:        23,chapter:        33,versenumber:         9,verse:"地上悲哀衰残；黎巴嫩羞愧枯干；沙仑像旷野；巴珊和迦密的树林凋残。"},
{volumn:        23,chapter:        33,versenumber:        10,verse:"耶和华说：“现在我要起来；我要兴起；我要勃然而兴。"},
{volumn:        23,chapter:        33,versenumber:        11,verse:"你们要怀的是糠秕，要生的是碎秸；你们的气就是吞灭自己的火。"},
{volumn:        23,chapter:        33,versenumber:        12,verse:"列邦必像已烧的石灰，像已割的荆棘在火中焚烧。”"},
{volumn:        23,chapter:        33,versenumber:        13,verse:"你们远方的人当听我所行的；你们近处的人当承认我的大能。"},
{volumn:        23,chapter:        33,versenumber:        14,verse:"锡安中的罪人都惧怕；不敬虔的人被战兢抓住。“我们中间谁能与吞灭的火同住？我们中间谁能与永火同住呢？”"},
{volumn:        23,chapter:        33,versenumber:        15,verse:"行事公义、说话正直、憎恶欺压的财利、摆手不受贿赂、塞耳不听流血的话，闭眼不看邪恶事的，"},
{volumn:        23,chapter:        33,versenumber:        16,verse:"他必居高处；他的保障是磐石的坚垒；他的粮必不缺乏（原文是赐给）；他的水必不断绝。"},
{volumn:        23,chapter:        33,versenumber:        17,verse:"你的眼必见王的荣美，必见辽阔之地。"},
{volumn:        23,chapter:        33,versenumber:        18,verse:"你的心必思想那惊吓的事，自问说：“记数目的在哪里呢？平贡银的在哪里呢？数戍楼的在哪里呢？”"},
{volumn:        23,chapter:        33,versenumber:        19,verse:"你必不见那强暴的民，就是说话深奥，你不能明白，言语呢喃，你不能懂得的。"},
{volumn:        23,chapter:        33,versenumber:        20,verse:"你要看锡安我们守圣节的城！你的眼必见耶路撒冷为安静的居所，为不挪移的帐幕，橛子永不拔出，绳索一根也不折断。"},
{volumn:        23,chapter:        33,versenumber:        21,verse:"在那里，耶和华必显威严与我们同在，当作江河宽阔之地；其中必没有荡桨摇橹的船来往，也没有威武的船经过。"},
{volumn:        23,chapter:        33,versenumber:        22,verse:"因为，耶和华是审判我们的；耶和华是给我们设律法的；耶和华是我们的王；他必拯救我们。"},
{volumn:        23,chapter:        33,versenumber:        23,verse:"你的绳索松开：不能栽稳桅杆，也不能扬起篷来。那时许多掳来的物被分了；瘸腿的把掠物夺去了。"},
{volumn:        23,chapter:        33,versenumber:        24,verse:"城内居民必不说：“我病了”；其中居住的百姓，罪孽都赦免了。"},
{volumn:        23,chapter:        34,versenumber:         1,verse:"列国啊，要近前来听！众民哪，要侧耳而听！地和其上所充满的，世界和其中一切所出的都应当听！"},
{volumn:        23,chapter:        34,versenumber:         2,verse:"因为耶和华向万国发忿恨，向他们的全军发烈怒，将他们灭尽，交出他们受杀戮。"},
{volumn:        23,chapter:        34,versenumber:         3,verse:"被杀的必然抛弃，尸首臭气上腾；诸山被他们的血融化。"},
{volumn:        23,chapter:        34,versenumber:         4,verse:"天上的万象都要消没；天被卷起，好像书卷。其上的万象要残败，像葡萄树的叶子残败，又像无花果树的叶子残败一样。"},
{volumn:        23,chapter:        34,versenumber:         5,verse:"因为我的刀在天上已经喝足；这刀必临到以东和我所咒诅的民，要施行审判。"},
{volumn:        23,chapter:        34,versenumber:         6,verse:"耶和华的刀满了血，用脂油和羊羔、公山羊的血，并公绵羊腰子的脂油滋润的；因为耶和华在波斯拉有献祭的事，在以东地大行杀戮。"},
{volumn:        23,chapter:        34,versenumber:         7,verse:"野牛、牛犊，和公牛要一同下来。他们的地喝醉了血；他们的尘土因脂油肥润。"},
{volumn:        23,chapter:        34,versenumber:         8,verse:"因耶和华有报仇之日，为锡安的争辩有报应之年。"},
{volumn:        23,chapter:        34,versenumber:         9,verse:"以东的河水要变为石油，尘埃要变为硫磺；地土成为烧着的石油，"},
{volumn:        23,chapter:        34,versenumber:        10,verse:"昼夜总不熄灭，烟气永远上腾，必世世代代成为荒废，永永远远无人经过。"},
{volumn:        23,chapter:        34,versenumber:        11,verse:"鹈鹕、箭猪却要得为业；猫头鹰、乌鸦要住在其间。耶和华必将空虚的准绳，混沌的线铊，拉在其上。"},
{volumn:        23,chapter:        34,versenumber:        12,verse:"以东人要召贵胄来治国；那里却无一个，首领也都归于无有。"},
{volumn:        23,chapter:        34,versenumber:        13,verse:"以东的宫殿要长荆棘；保障要长蒺藜和刺草；要作野狗的住处，鸵鸟的居所。"},
{volumn:        23,chapter:        34,versenumber:        14,verse:"旷野的走兽要和豺狼相遇；野山羊要与伴偶对叫。夜间的怪物必在那里栖身，自找安歇之处。"},
{volumn:        23,chapter:        34,versenumber:        15,verse:"箭蛇要在那里做窝，下蛋，抱蛋，生子，聚子在其影下；鹞鹰各与伴偶聚集在那里。"},
{volumn:        23,chapter:        34,versenumber:        16,verse:"你们要查考宣读耶和华的书。这都无一缺少，无一没有伴偶；因为我的口已经吩咐，他的灵将它们聚集。"},
{volumn:        23,chapter:        34,versenumber:        17,verse:"他也为它们拈阄，又亲手用准绳给它们分地；它们必永得为业，世世代代住在其间。"},
{volumn:        23,chapter:        35,versenumber:         1,verse:"旷野和干旱之地必然欢喜；沙漠也必快乐；又像玫瑰开花，"},
{volumn:        23,chapter:        35,versenumber:         2,verse:"必开花繁盛，乐上加乐，而且欢呼。黎巴嫩的荣耀，并迦密与沙仑的华美，必赐给它。人必看见耶和华的荣耀，我们　神的华美。"},
{volumn:        23,chapter:        35,versenumber:         3,verse:"你们要使软弱的手坚壮，无力的膝稳固。"},
{volumn:        23,chapter:        35,versenumber:         4,verse:"对胆怯的人说：“你们要刚强，不要惧怕。看哪，你们的　神必来报仇，必来施行极大的报应；他必来拯救你们。”"},
{volumn:        23,chapter:        35,versenumber:         5,verse:"那时，瞎子的眼必睁开；聋子的耳必开通。"},
{volumn:        23,chapter:        35,versenumber:         6,verse:"那时，瘸子必跳跃像鹿；哑巴的舌头必能歌唱。在旷野必有水发出；在沙漠必有河涌流。"},
{volumn:        23,chapter:        35,versenumber:         7,verse:"发光的沙（或译：蜃楼）要变为水池；干渴之地要变为泉源。在野狗躺卧之处，必有青草、芦苇，和蒲草。"},
{volumn:        23,chapter:        35,versenumber:         8,verse:"在那里必有一条大道，称为圣路。污秽人不得经过，必专为赎民行走；行路的人虽愚昧，也不致失迷。"},
{volumn:        23,chapter:        35,versenumber:         9,verse:"在那里必没有狮子，猛兽也不登这路；在那里都遇不见，只有赎民在那里行走。"},
{volumn:        23,chapter:        35,versenumber:        10,verse:"并且耶和华救赎的民必归回，歌唱来到锡安；永乐必归到他们的头上；他们必得着欢喜快乐，忧愁叹息尽都逃避。"},
{volumn:        23,chapter:        36,versenumber:         1,verse:"希西家王十四年，亚述王西拿基立上来攻击犹大的一切坚固城，将城攻取。"},
{volumn:        23,chapter:        36,versenumber:         2,verse:"亚述王从拉吉差遣拉伯沙基率领大军往耶路撒冷，到希西家王那里去。他就站在上池的水沟旁，在漂布地的大路上。"},
{volumn:        23,chapter:        36,versenumber:         3,verse:"于是希勒家的儿子家宰以利亚敬，并书记舍伯那和亚萨的儿子史官约亚，出来见拉伯沙基。"},
{volumn:        23,chapter:        36,versenumber:         4,verse:"拉伯沙基对他们说：“你们去告诉希西家说，亚述大王如此说：‘你所倚靠的有什么可仗赖的呢？"},
{volumn:        23,chapter:        36,versenumber:         5,verse:"你说，有打仗的计谋和能力，我看不过是虚话。你到底倚靠谁才背叛我呢？"},
{volumn:        23,chapter:        36,versenumber:         6,verse:"看哪，你所倚靠的埃及是那压伤的苇杖，人若靠这杖，就必刺透他的手。埃及王法老向一切倚靠他的人也是这样。"},
{volumn:        23,chapter:        36,versenumber:         7,verse:"你若对我说：我们倚靠耶和华我们的　神。希西家岂不是将　神的邱坛和祭坛废去，且对犹大和耶路撒冷的人说：你们当在这坛前敬拜吗？"},
{volumn:        23,chapter:        36,versenumber:         8,verse:"现在你把当头给我主亚述王，我给你二千匹马，看你这一面骑马的人够不够。"},
{volumn:        23,chapter:        36,versenumber:         9,verse:"若不然，怎能打败我主臣仆中最小的军长呢？你竟倚靠埃及的战车马兵吗？"},
{volumn:        23,chapter:        36,versenumber:        10,verse:"现在我上来攻击毁灭这地，岂没有耶和华的意思吗？耶和华吩咐我说，你上去攻击毁灭这地吧！’”"},
{volumn:        23,chapter:        36,versenumber:        11,verse:"以利亚敬、舍伯那、约亚对拉伯沙基说：“求你用亚兰言语和仆人说话，因为我们懂得；不要用犹大言语和我们说话，达到城上百姓的耳中。”"},
{volumn:        23,chapter:        36,versenumber:        12,verse:"拉伯沙基说：“我主差遣我来，岂是单对你和你的主说这些话吗？不也是对这些坐在城上、要与你们一同吃自己粪喝自己尿的人说吗？”"},
{volumn:        23,chapter:        36,versenumber:        13,verse:"于是，拉伯沙基站着，用犹大言语大声喊着说：“你们当听亚述大王的话。"},
{volumn:        23,chapter:        36,versenumber:        14,verse:"王如此说：‘你们不要被希西家欺哄了，因他不能拯救你们。"},
{volumn:        23,chapter:        36,versenumber:        15,verse:"也不要听希西家使你们倚靠耶和华说：耶和华必要拯救我们，这城必不交在亚述王的手中。"},
{volumn:        23,chapter:        36,versenumber:        16,verse:"不要听希西家的话，因亚述王如此说：你们要与我和好。出来投降我，各人就可以吃自己葡萄树和无花果树的果子，喝自己井里的水。"},
{volumn:        23,chapter:        36,versenumber:        17,verse:"等我来领你们到一个地方，与你们本地一样，就是有五谷和新酒之地，有粮食和葡萄园之地。"},
{volumn:        23,chapter:        36,versenumber:        18,verse:"你们要谨防，恐怕希西家劝导你们说：耶和华必拯救我们。列国的神有哪一个救他本国脱离亚述王的手呢？"},
{volumn:        23,chapter:        36,versenumber:        19,verse:"哈马和亚珥拔的神在哪里呢？西法瓦音的神在哪里呢？他们曾救撒玛利亚脱离我的手吗？"},
{volumn:        23,chapter:        36,versenumber:        20,verse:"这些国的神有谁曾救自己的国脱离我的手呢？难道耶和华能救耶路撒冷脱离我的手吗？’”"},
{volumn:        23,chapter:        36,versenumber:        21,verse:"百姓静默不言，并不回答一句，因为王曾吩咐说：“不要回答他。”"},
{volumn:        23,chapter:        36,versenumber:        22,verse:"当下希勒家的儿子家宰以利亚敬和书记舍伯那，并亚萨的儿子史官约亚，都撕裂衣服，来到希西家那里，将拉伯沙基的话告诉了他。"},
{volumn:        23,chapter:        37,versenumber:         1,verse:"希西家王听见就撕裂衣服，披上麻布，进了耶和华的殿，"},
{volumn:        23,chapter:        37,versenumber:         2,verse:"使家宰以利亚敬和书记舍伯那，并祭司中的长老，都披上麻布，去见亚摩斯的儿子先知以赛亚，"},
{volumn:        23,chapter:        37,versenumber:         3,verse:"对他说：“希西家如此说：‘今日是急难、责罚、凌辱的日子，就如妇人将要生产婴孩，却没有力量生产。"},
{volumn:        23,chapter:        37,versenumber:         4,verse:"或者耶和华你的　神听见拉伯沙基的话，就是他主人亚述王打发他来辱骂永生　神的话；耶和华你的　神听见这话就发斥责。故此，求你为余剩的民扬声祷告。’”"},
{volumn:        23,chapter:        37,versenumber:         5,verse:"希西家王的臣仆就去见以赛亚。"},
{volumn:        23,chapter:        37,versenumber:         6,verse:"以赛亚对他们说：“要这样对你们的主人说，耶和华如此说：‘你听见亚述王的仆人亵渎我的话，不要惧怕。"},
{volumn:        23,chapter:        37,versenumber:         7,verse:"我必惊动（原文是使灵进入）他的心；他要听见风声就归回本地，我必使他在那里倒在刀下。’”"},
{volumn:        23,chapter:        37,versenumber:         8,verse:"拉伯沙基回去，正遇见亚述王攻打立拿；原来他早听见亚述王拔营离开拉吉。"},
{volumn:        23,chapter:        37,versenumber:         9,verse:"亚述王听见人论古实王特哈加说：“他出来要与你争战。”亚述王一听见，就打发使者去见希西家，吩咐他们说："},
{volumn:        23,chapter:        37,versenumber:        10,verse:"“你们对犹大王希西家如此说：‘不要听你所倚靠的　神欺哄你说：耶路撒冷必不交在亚述王的手中。"},
{volumn:        23,chapter:        37,versenumber:        11,verse:"你总听说亚述诸王向列国所行的乃是尽行灭绝，难道你还能得救吗？"},
{volumn:        23,chapter:        37,versenumber:        12,verse:"我列祖所毁灭的，就是歌散、哈兰、利色，和属提·拉撒的伊甸人；这些国的神何曾拯救这些国呢？"},
{volumn:        23,chapter:        37,versenumber:        13,verse:"哈马的王，亚珥拔的王，西法瓦音城的王，希拿和以瓦的王，都在哪里呢？’”"},
{volumn:        23,chapter:        37,versenumber:        14,verse:"希西家从使者手里接过书信来，看完了，就上耶和华的殿，将书信在耶和华面前展开。"},
{volumn:        23,chapter:        37,versenumber:        15,verse:"希西家向耶和华祷告说："},
{volumn:        23,chapter:        37,versenumber:        16,verse:"“坐在二基路伯上万军之耶和华以色列的　神啊，你，惟有你，是天下万国的　神，你曾创造天地。"},
{volumn:        23,chapter:        37,versenumber:        17,verse:"耶和华啊，求你侧耳而听；耶和华啊，求你睁眼而看，要听西拿基立的一切话，他是打发使者来辱骂永生　神的。"},
{volumn:        23,chapter:        37,versenumber:        18,verse:"耶和华啊，亚述诸王果然使列国和列国之地变为荒凉，"},
{volumn:        23,chapter:        37,versenumber:        19,verse:"将列国的神像都扔在火里；因为他本不是神，乃是人手所造的，是木头、石头的，所以灭绝他。"},
{volumn:        23,chapter:        37,versenumber:        20,verse:"耶和华我们的　神啊，现在求你救我们脱离亚述王的手，使天下万国都知道惟有你是耶和华。”"},
{volumn:        23,chapter:        37,versenumber:        21,verse:"亚摩斯的儿子以赛亚就打发人去见希西家，说：“耶和华以色列的　神如此说，你既然求我攻击亚述王西拿基立，"},
{volumn:        23,chapter:        37,versenumber:        22,verse:"所以耶和华论他这样说：锡安的处女藐视你，嗤笑你；耶路撒冷的女子向你摇头。"},
{volumn:        23,chapter:        37,versenumber:        23,verse:"“你辱骂谁，亵渎谁？扬起声来，高举眼目攻击谁呢？乃是攻击以色列的圣者。"},
{volumn:        23,chapter:        37,versenumber:        24,verse:"你藉你的臣仆辱骂主说：我率领许多战车上山顶，到黎巴嫩极深之处；我要砍伐其中高大的香柏树和佳美的松树。我必上极高之处，进入肥田的树林。"},
{volumn:        23,chapter:        37,versenumber:        25,verse:"我已经挖井喝水；我必用脚掌踏干埃及的一切河。"},
{volumn:        23,chapter:        37,versenumber:        26,verse:"“耶和华说：你岂没有听见我早先所做的、古时所立的吗？现在藉你使坚固城荒废，变为乱堆。"},
{volumn:        23,chapter:        37,versenumber:        27,verse:"所以其中的居民力量甚小，惊惶羞愧。他们像野草，像青菜，如房顶上的草，又如田间未长成的禾稼。"},
{volumn:        23,chapter:        37,versenumber:        28,verse:"“你坐下，你出去，你进来，你向我发烈怒，我都知道。"},
{volumn:        23,chapter:        37,versenumber:        29,verse:"因你向我发烈怒，又因你狂傲的话达到我耳中，我就要用钩子钩上你的鼻子，把嚼环放在你口里，使你从原路转回去。"},
{volumn:        23,chapter:        37,versenumber:        30,verse:"“以色列人哪，我赐你们一个证据：你们今年要吃自生的，明年也要吃自长的，至于后年，你们要耕种收割，栽植葡萄园，吃其中的果子。"},
{volumn:        23,chapter:        37,versenumber:        31,verse:"犹大家所逃脱余剩的，仍要往下扎根，向上结果。"},
{volumn:        23,chapter:        37,versenumber:        32,verse:"必有余剩的民从耶路撒冷而出；必有逃脱的人从锡安山而来。万军之耶和华的热心必成就这事。"},
{volumn:        23,chapter:        37,versenumber:        33,verse:"“所以耶和华论亚述王如此说：他必不得来到这城，也不在这里射箭，不得拿盾牌到城前，也不筑垒攻城。"},
{volumn:        23,chapter:        37,versenumber:        34,verse:"他从哪条路来，必从那条路回去，必不得来到这城。这是耶和华说的。"},
{volumn:        23,chapter:        37,versenumber:        35,verse:"因我为自己的缘故，又为我仆人大卫的缘故，必保护拯救这城。”"},
{volumn:        23,chapter:        37,versenumber:        36,verse:"耶和华的使者出去，在亚述营中杀了十八万五千人。清早有人起来一看，都是死尸了。"},
{volumn:        23,chapter:        37,versenumber:        37,verse:"亚述王西拿基立就拔营回去，住在尼尼微。"},
{volumn:        23,chapter:        37,versenumber:        38,verse:"一日在他的神尼斯洛庙里叩拜，他儿子亚得米勒和沙利色用刀杀了他，就逃到亚拉腊地。他儿子以撒哈顿接续他作王。"},
{volumn:        23,chapter:        38,versenumber:         1,verse:"那时希西家病得要死，亚摩斯的儿子先知以赛亚去见他，对他说：“耶和华如此说：你当留遗命与你的家，因为你必死不能活了。”"},
{volumn:        23,chapter:        38,versenumber:         2,verse:"希西家就转脸朝墙，祷告耶和华说："},
{volumn:        23,chapter:        38,versenumber:         3,verse:"“耶和华啊，求你记念我在你面前怎样存完全的心，按诚实行事，又做你眼中所看为善的。”希西家就痛哭了。"},
{volumn:        23,chapter:        38,versenumber:         4,verse:"耶和华的话临到以赛亚说："},
{volumn:        23,chapter:        38,versenumber:         5,verse:"“你去告诉希西家说，耶和华你祖大卫的　神如此说：我听见了你的祷告，看见了你的眼泪。我必加增你十五年的寿数；"},
{volumn:        23,chapter:        38,versenumber:         6,verse:"并且我要救你和这城脱离亚述王的手，也要保护这城。"},
{volumn:        23,chapter:        38,versenumber:         7,verse:"“我耶和华必成就我所说的。我先给你一个兆头，"},
{volumn:        23,chapter:        38,versenumber:         8,verse:"就是叫亚哈斯的日晷，向前进的日影往后退十度。”于是，前进的日影果然在日晷上往后退了十度。"},
{volumn:        23,chapter:        38,versenumber:         9,verse:"犹大王希西家患病已经痊愈，就作诗说："},
{volumn:        23,chapter:        38,versenumber:        10,verse:"“我说：正在我中年（或译：晌午）之日必进入阴间的门；我余剩的年岁不得享受。"},
{volumn:        23,chapter:        38,versenumber:        11,verse:"我说：我必不得见耶和华，就是在活人之地不见耶和华；我与世上的居民不再见面。"},
{volumn:        23,chapter:        38,versenumber:        12,verse:"我的住处被迁去离开我，好像牧人的帐棚一样；我将性命卷起，像织布的卷布一样。耶和华必将我从机头剪断，从早到晚，他要使我完结。"},
{volumn:        23,chapter:        38,versenumber:        13,verse:"我使自己安静直到天亮；他像狮子折断我一切的骨头，从早到晚，他要使我完结。"},
{volumn:        23,chapter:        38,versenumber:        14,verse:"我像燕子呢喃，像白鹤鸣叫，又像鸽子哀鸣；我因仰观，眼睛困倦。耶和华啊，我受欺压，求你为我作保。"},
{volumn:        23,chapter:        38,versenumber:        15,verse:"我可说什么呢？他应许我的，也给我成就了。我因心里的苦楚，在一生的年日必悄悄而行。"},
{volumn:        23,chapter:        38,versenumber:        16,verse:"主啊，人得存活乃在乎此。我灵存活也全在此。所以求你使我痊愈，仍然存活。"},
{volumn:        23,chapter:        38,versenumber:        17,verse:"看哪，我受大苦，本为使我得平安；你因爱我的灵魂（或译：生命）便救我脱离败坏的坑，因为你将我一切的罪扔在你的背后。"},
{volumn:        23,chapter:        38,versenumber:        18,verse:"原来，阴间不能称谢你，死亡不能颂扬你；下坑的人不能盼望你的诚实。"},
{volumn:        23,chapter:        38,versenumber:        19,verse:"只有活人，活人必称谢你，像我今日称谢你一样。为父的，必使儿女知道你的诚实。"},
{volumn:        23,chapter:        38,versenumber:        20,verse:"耶和华肯救我，所以，我们要一生一世在耶和华殿中用丝弦的乐器唱我的诗歌。”"},
{volumn:        23,chapter:        38,versenumber:        21,verse:"以赛亚说：“当取一块无花果饼来，贴在疮上，王必痊愈。”"},
{volumn:        23,chapter:        38,versenumber:        22,verse:"希西家问说：“我能上耶和华的殿，有什么兆头呢？”"},
{volumn:        23,chapter:        39,versenumber:         1,verse:"那时，巴比伦王巴拉但的儿子米罗达·巴拉但听见希西家病而痊愈，就送书信和礼物给他。"},
{volumn:        23,chapter:        39,versenumber:         2,verse:"希西家喜欢见使者，就把自己宝库的金子、银子、香料、贵重的膏油，和他武库的一切军器，并所有的财宝都给他们看；他家中和全国之内，希西家没有一样不给他们看的。"},
{volumn:        23,chapter:        39,versenumber:         3,verse:"于是先知以赛亚来见希西家王，问他说：“这些人说什么？他们从哪里来见你？”希西家说：“他们从远方的巴比伦来见我。”"},
{volumn:        23,chapter:        39,versenumber:         4,verse:"以赛亚说：“他们在你家里看见了什么？”希西家说：“凡我家中所有的，他们都看见了；我财宝中没有一样不给他们看的。”"},
{volumn:        23,chapter:        39,versenumber:         5,verse:"以赛亚对希西家说：“你要听万军之耶和华的话："},
{volumn:        23,chapter:        39,versenumber:         6,verse:"日子必到，凡你家里所有的，并你列祖积蓄到如今的，都要被掳到巴比伦去，不留下一样；这是耶和华说的。"},
{volumn:        23,chapter:        39,versenumber:         7,verse:"并且从你本身所生的众子，其中必有被掳去、在巴比伦王宫里当太监的。”"},
{volumn:        23,chapter:        39,versenumber:         8,verse:"希西家对以赛亚说：“你所说耶和华的话甚好，因为在我的年日中必有太平和稳固的景况。”"},
{volumn:        23,chapter:        40,versenumber:         1,verse:"你们的　神说：“你们要安慰，安慰我的百姓。"},
{volumn:        23,chapter:        40,versenumber:         2,verse:"要对耶路撒冷说安慰的话，又向她宣告说，她争战的日子已满了；她的罪孽赦免了；她为自己的一切罪，从耶和华手中加倍受罚。”"},
{volumn:        23,chapter:        40,versenumber:         3,verse:"有人声喊着说：“在旷野预备耶和华的路（或译：在旷野，有人声喊着说：当预备耶和华的路），在沙漠地修平我们　神的道。"},
{volumn:        23,chapter:        40,versenumber:         4,verse:"一切山洼都要填满，大小山冈都要削平；高高低低的要改为平坦，崎崎岖岖的必成为平原。"},
{volumn:        23,chapter:        40,versenumber:         5,verse:"耶和华的荣耀必然显现；凡有血气的必一同看见；因为这是耶和华亲口说的。”"},
{volumn:        23,chapter:        40,versenumber:         6,verse:"有人声说：“你喊叫吧！”有一个说：“我喊叫什么呢？”说：“凡有血气的尽都如草；他的美容都像野地的花。"},
{volumn:        23,chapter:        40,versenumber:         7,verse:"草必枯干，花必凋残，因为耶和华的气吹在其上；百姓诚然是草。"},
{volumn:        23,chapter:        40,versenumber:         8,verse:"草必枯干，花必凋残，惟有我们　神的话必永远立定！”"},
{volumn:        23,chapter:        40,versenumber:         9,verse:"报好信息给锡安的啊，你要登高山；报好信息给耶路撒冷的啊，你要极力扬声。扬声不要惧怕，对犹大的城邑说：“看哪，你们的　神！”"},
{volumn:        23,chapter:        40,versenumber:        10,verse:"主耶和华必像大能者临到；他的膀臂必为他掌权。他的赏赐在他那里；他的报应在他面前。"},
{volumn:        23,chapter:        40,versenumber:        11,verse:"他必像牧人牧养自己的羊群，用膀臂聚集羊羔抱在怀中，慢慢引导那乳养小羊的。"},
{volumn:        23,chapter:        40,versenumber:        12,verse:"谁曾用手心量诸水，用手虎口量苍天，用升斗盛大地的尘土，用秤称山岭，用天平平冈陵呢？"},
{volumn:        23,chapter:        40,versenumber:        13,verse:"谁曾测度耶和华的心（或译：谁曾指示耶和华的灵），或作他的谋士指教他呢？"},
{volumn:        23,chapter:        40,versenumber:        14,verse:"他与谁商议，谁教导他，谁将公平的路指示他，又将知识教训他，将通达的道指教他呢？"},
{volumn:        23,chapter:        40,versenumber:        15,verse:"看哪，万民都像水桶的一滴，又算如天平上的微尘；他举起众海岛，好像极微之物。"},
{volumn:        23,chapter:        40,versenumber:        16,verse:"黎巴嫩的树林不够当柴烧；其中的走兽也不够作燔祭。"},
{volumn:        23,chapter:        40,versenumber:        17,verse:"万民在他面前好像虚无，被他看为不及虚无，乃为虚空。"},
{volumn:        23,chapter:        40,versenumber:        18,verse:"你们究竟将谁比　神，用什么形像与神比较呢？"},
{volumn:        23,chapter:        40,versenumber:        19,verse:"偶像是匠人铸造，银匠用金包裹，为它铸造银链。"},
{volumn:        23,chapter:        40,versenumber:        20,verse:"穷乏献不起这样供物的，就拣选不能朽坏的树木，为自己寻找巧匠，立起不能摇动的偶像。"},
{volumn:        23,chapter:        40,versenumber:        21,verse:"你们岂不曾知道吗？你们岂不曾听见吗？从起初岂没有人告诉你们吗？自从立地的根基，你们岂没有明白吗？"},
{volumn:        23,chapter:        40,versenumber:        22,verse:"神坐在地球大圈之上；地上的居民好像蝗虫。他铺张穹苍如幔子，展开诸天如可住的帐棚。"},
{volumn:        23,chapter:        40,versenumber:        23,verse:"他使君王归于虚无，使地上的审判官成为虚空。"},
{volumn:        23,chapter:        40,versenumber:        24,verse:"他们是刚才（或译：不曾；下同）栽上，刚才种上，根也刚才扎在地里，他一吹在其上，便都枯干；旋风将他们吹去，像碎秸一样。"},
{volumn:        23,chapter:        40,versenumber:        25,verse:"那圣者说：“你们将谁比我，叫他与我相等呢？"},
{volumn:        23,chapter:        40,versenumber:        26,verse:"你们向上举目，看谁创造这万象，按数目领出，他一一称其名；因他的权能，又因他的大能大力，连一个都不缺。”"},
{volumn:        23,chapter:        40,versenumber:        27,verse:"雅各啊，你为何说，“我的道路向耶和华隐藏？”以色列啊，你为何言：“我的冤屈　神并不查问？”"},
{volumn:        23,chapter:        40,versenumber:        28,verse:"你岂不曾知道吗？你岂不曾听见吗？永在的　神耶和华，创造地极的主，并不疲乏，也不困倦；他的智慧无法测度。"},
{volumn:        23,chapter:        40,versenumber:        29,verse:"疲乏的，他赐能力；软弱的，他加力量。"},
{volumn:        23,chapter:        40,versenumber:        30,verse:"就是少年人也要疲乏困倦；强壮的也必全然跌倒。"},
{volumn:        23,chapter:        40,versenumber:        31,verse:"但那等候耶和华的必从新得力。他们必如鹰展翅上腾；他们奔跑却不困倦，行走却不疲乏。"},
{volumn:        23,chapter:        41,versenumber:         1,verse:"众海岛啊，当在我面前静默；众民当从新得力，都要近前来才可以说话，我们可以彼此辩论。"},
{volumn:        23,chapter:        41,versenumber:         2,verse:"谁从东方兴起一人，凭公义召他来到脚前呢？耶和华将列国交给他，使他管辖君王，把他们如灰尘交与他的刀，如风吹的碎秸交与他的弓。"},
{volumn:        23,chapter:        41,versenumber:         3,verse:"他追赶他们，走他所未走的道，坦然前行。"},
{volumn:        23,chapter:        41,versenumber:         4,verse:"谁行做成就这事，从起初宣召历代呢？就是我耶和华！我是首先的，也与末后的同在。"},
{volumn:        23,chapter:        41,versenumber:         5,verse:"海岛看见就都害怕；地极也都战兢，就近前来。"},
{volumn:        23,chapter:        41,versenumber:         6,verse:"他们各人帮助邻舍，各人对弟兄说：“壮胆吧！”"},
{volumn:        23,chapter:        41,versenumber:         7,verse:"木匠勉励银匠，用锤打光的勉励打砧的，论焊工说：“焊得好”；又用钉子钉稳，免得偶像动摇。"},
{volumn:        23,chapter:        41,versenumber:         8,verse:"惟你以色列我的仆人，雅各我所拣选的，我朋友亚伯拉罕的后裔，"},
{volumn:        23,chapter:        41,versenumber:         9,verse:"你是我从地极所领（原文是抓）来的，从地角所召来的，且对你说：“你是我的仆人；我拣选你，并不弃绝你。"},
{volumn:        23,chapter:        41,versenumber:        10,verse:"你不要害怕，因为我与你同在；不要惊惶，因为我是你的　神。我必坚固你，我必帮助你；我必用我公义的右手扶持你。"},
{volumn:        23,chapter:        41,versenumber:        11,verse:"“凡向你发怒的必都抱愧蒙羞；与你相争的必如无有，并要灭亡。"},
{volumn:        23,chapter:        41,versenumber:        12,verse:"与你争竞的，你要找他们也找不着；与你争战的必如无有，成为虚无。"},
{volumn:        23,chapter:        41,versenumber:        13,verse:"因为我耶和华你的　神必搀扶你的右手，对你说：不要害怕！我必帮助你。"},
{volumn:        23,chapter:        41,versenumber:        14,verse:"“你这虫雅各和你们以色列人，不要害怕！耶和华说：我必帮助你。你的救赎主就是以色列的圣者。"},
{volumn:        23,chapter:        41,versenumber:        15,verse:"看哪，我已使你成为有快齿打粮的新器具；你要把山岭打得粉碎，使冈陵如同糠秕。"},
{volumn:        23,chapter:        41,versenumber:        16,verse:"你要把它簸扬，风要吹去；旋风要把它刮散。你倒要以耶和华为喜乐，以以色列的圣者为夸耀。"},
{volumn:        23,chapter:        41,versenumber:        17,verse:"“困苦穷乏人寻求水却没有；他们因口渴，舌头干燥。我耶和华必应允他们；我以色列的　神必不离弃他们。"},
{volumn:        23,chapter:        41,versenumber:        18,verse:"我要在净光的高处开江河，在谷中开泉源；我要使沙漠变为水池，使干地变为涌泉。"},
{volumn:        23,chapter:        41,versenumber:        19,verse:"我要在旷野种上香柏树、皂荚树、番石榴树，和野橄榄树。我在沙漠要把松树、杉树，并黄杨树一同栽植；"},
{volumn:        23,chapter:        41,versenumber:        20,verse:"好叫人看见、知道、思想、明白；这是耶和华的手所做的，是以色列的圣者所造的。”"},
{volumn:        23,chapter:        41,versenumber:        21,verse:"耶和华对假神说：“你们要呈上你们的案件；雅各的君说：你们要声明你们确实的理由。"},
{volumn:        23,chapter:        41,versenumber:        22,verse:"可以声明，指示我们将来必遇的事，说明先前的是什么事，好叫我们思索，得知事的结局，或者把将来的事指示我们。"},
{volumn:        23,chapter:        41,versenumber:        23,verse:"要说明后来的事，好叫我们知道你们是神。你们或降福，或降祸，使我们惊奇，一同观看。"},
{volumn:        23,chapter:        41,versenumber:        24,verse:"看哪，你们属乎虚无；你们的作为也属乎虚空。那选择你们的是可憎恶的。"},
{volumn:        23,chapter:        41,versenumber:        25,verse:"“我从北方兴起一人；他是求告我名的，从日出之地而来。他必临到掌权的，好像临到灰泥，仿佛窑匠踹泥一样。"},
{volumn:        23,chapter:        41,versenumber:        26,verse:"谁从起初指明这事，使我们知道呢？谁从先前说明，使我们说他不错呢？谁也没有指明；谁也没有说明；谁也没有听见你们的话。"},
{volumn:        23,chapter:        41,versenumber:        27,verse:"我首先对锡安说：看哪，我要将一位报好信息的赐给耶路撒冷。"},
{volumn:        23,chapter:        41,versenumber:        28,verse:"我看的时候并没有人；我问的时候，他们中间也没有谋士可以回答一句。"},
{volumn:        23,chapter:        41,versenumber:        29,verse:"看哪，他们和他们的工作都是虚空，且是虚无。他们所铸的偶像都是风，都是虚的。”"},
{volumn:        23,chapter:        42,versenumber:         1,verse:"“看哪，我的仆人，我所扶持、所拣选、心里所喜悦的！我已将我的灵赐给他；他必将公理传给外邦。"},
{volumn:        23,chapter:        42,versenumber:         2,verse:"他不喧嚷，不扬声，也不使街上听见他的声音。"},
{volumn:        23,chapter:        42,versenumber:         3,verse:"压伤的芦苇，他不折断；将残的灯火，他不吹灭。他凭真实将公理传开。"},
{volumn:        23,chapter:        42,versenumber:         4,verse:"他不灰心，也不丧胆，直到他在地上设立公理；海岛都等候他的训诲。”"},
{volumn:        23,chapter:        42,versenumber:         5,verse:"创造诸天，铺张穹苍，将地和地所出的一并铺开，赐气息给地上的众人，又赐灵性给行在其上之人的　神耶和华，他如此说："},
{volumn:        23,chapter:        42,versenumber:         6,verse:"“我耶和华凭公义召你，必搀扶你的手，保守你，使你作众民的中保（中保：原文是约），作外邦人的光，"},
{volumn:        23,chapter:        42,versenumber:         7,verse:"开瞎子的眼，领被囚的出牢狱，领坐黑暗的出监牢。"},
{volumn:        23,chapter:        42,versenumber:         8,verse:"我是耶和华，这是我的名；我必不将我的荣耀归给假神，也不将我的称赞归给雕刻的偶像。"},
{volumn:        23,chapter:        42,versenumber:         9,verse:"看哪，先前的事已经成就，现在我将新事说明，这事未发以先，我就说给你们听。”"},
{volumn:        23,chapter:        42,versenumber:        10,verse:"航海的和海中所有的，海岛和其上的居民，都当向耶和华唱新歌，从地极赞美他。"},
{volumn:        23,chapter:        42,versenumber:        11,verse:"旷野和其中的城邑，并基达人居住的村庄都当扬声；西拉的居民当欢呼，在山顶上呐喊。"},
{volumn:        23,chapter:        42,versenumber:        12,verse:"他们当将荣耀归给耶和华，在海岛中传扬他的颂赞。"},
{volumn:        23,chapter:        42,versenumber:        13,verse:"耶和华必像勇士出去，必像战士激动热心，要喊叫，大声呐喊，要用大力攻击仇敌。"},
{volumn:        23,chapter:        42,versenumber:        14,verse:"“我许久闭口不言，静默不语；现在我要喊叫，像产难的妇人；我要急气而喘哮。"},
{volumn:        23,chapter:        42,versenumber:        15,verse:"我要使大山小冈变为荒场，使其上的花草都枯干；我要使江河变为洲岛，使水池都干涸。"},
{volumn:        23,chapter:        42,versenumber:        16,verse:"我要引瞎子行不认识的道，领他们走不知道的路；在他们面前使黑暗变为光明，使弯曲变为平直。这些事我都要行，并不离弃他们。"},
{volumn:        23,chapter:        42,versenumber:        17,verse:"倚靠雕刻的偶像，对铸造的偶像说：‘你是我们的神’；这等人要退后，全然蒙羞。”"},
{volumn:        23,chapter:        42,versenumber:        18,verse:"“你们这耳聋的，听吧！你们这眼瞎的，看吧！使你们能看见。"},
{volumn:        23,chapter:        42,versenumber:        19,verse:"谁比我的仆人眼瞎呢？谁比我差遣的使者耳聋呢？谁瞎眼像那与我和好的？谁瞎眼像耶和华的仆人呢？"},
{volumn:        23,chapter:        42,versenumber:        20,verse:"你看见许多事却不领会，耳朵开通却不听见。”"},
{volumn:        23,chapter:        42,versenumber:        21,verse:"耶和华因自己公义的缘故，喜欢使律法（或译：训诲）为大，为尊。"},
{volumn:        23,chapter:        42,versenumber:        22,verse:"但这百姓是被抢被夺的，都牢笼在坑中，隐藏在狱里；他们作掠物，无人拯救，作掳物，无人说交还。"},
{volumn:        23,chapter:        42,versenumber:        23,verse:"你们中间谁肯侧耳听此，谁肯留心而听，以防将来呢？"},
{volumn:        23,chapter:        42,versenumber:        24,verse:"谁将雅各交出当作掳物，将以色列交给抢夺的呢？岂不是耶和华吗？就是我们所得罪的那位。他们不肯遵行他的道，也不听从他的训诲。"},
{volumn:        23,chapter:        42,versenumber:        25,verse:"所以，他将猛烈的怒气和争战的勇力倾倒在以色列的身上。在他四围如火着起，他还不知道，烧着他，他也不介意。"},
{volumn:        23,chapter:        43,versenumber:         1,verse:"雅各啊，创造你的耶和华，以色列啊，造成你的那位，现在如此说：“你不要害怕！因为我救赎了你。我曾提你的名召你，你是属我的。"},
{volumn:        23,chapter:        43,versenumber:         2,verse:"你从水中经过，我必与你同在；你趟过江河，水必不漫过你；你从火中行过，必不被烧，火焰也不着在你身上。"},
{volumn:        23,chapter:        43,versenumber:         3,verse:"因为我是耶和华你的　神，是以色列的圣者你的救主；我已经使埃及作你的赎价，使古实和西巴代替你。"},
{volumn:        23,chapter:        43,versenumber:         4,verse:"因我看你为宝为尊；又因我爱你，所以我使人代替你，使列邦人替换你的生命。"},
{volumn:        23,chapter:        43,versenumber:         5,verse:"不要害怕，因我与你同在；我必领你的后裔从东方来，又从西方招聚你。"},
{volumn:        23,chapter:        43,versenumber:         6,verse:"我要对北方说：‘交出来！’对南方说：‘不要拘留！’将我的众子从远方带来，将我的众女从地极领回，"},
{volumn:        23,chapter:        43,versenumber:         7,verse:"就是凡称为我名下的人，是我为自己的荣耀创造的，是我所做成，所造作的。”"},
{volumn:        23,chapter:        43,versenumber:         8,verse:"你要将有眼而瞎、有耳而聋的民都带出来！"},
{volumn:        23,chapter:        43,versenumber:         9,verse:"任凭万国聚集；任凭众民会合。其中谁能将此声明，并将先前的事说给我们听呢？他们可以带出见证来，自显为是；或者他们听见便说：这是真的。"},
{volumn:        23,chapter:        43,versenumber:        10,verse:"耶和华说：“你们是我的见证，我所拣选的仆人。既是这样，便可以知道，且信服我，又明白我就是耶和华。在我以前没有真神（真：原文是造作的）；在我以后也必没有。"},
{volumn:        23,chapter:        43,versenumber:        11,verse:"惟有我是耶和华；除我以外没有救主。"},
{volumn:        23,chapter:        43,versenumber:        12,verse:"我曾指示，我曾拯救，我曾说明，并且在你们中间没有别神。”所以耶和华说：“你们是我的见证。我也是　神；"},
{volumn:        23,chapter:        43,versenumber:        13,verse:"自从有日子以来，我就是　神；谁也不能救人脱离我手。我要行事谁能阻止呢？”"},
{volumn:        23,chapter:        43,versenumber:        14,verse:"耶和华你们的救赎主、以色列的圣者如此说：“因你们的缘故，我已经打发人到巴比伦去；并且我要使迦勒底人如逃民，都坐自己喜乐的船下来。"},
{volumn:        23,chapter:        43,versenumber:        15,verse:"我是耶和华你们的圣者，是创造以色列的，是你们的君王。”"},
{volumn:        23,chapter:        43,versenumber:        16,verse:"耶和华在沧海中开道，在大水中开路，"},
{volumn:        23,chapter:        43,versenumber:        17,verse:"使车辆、马匹、军兵、勇士都出来，一同躺下，不再起来；他们灭没，好像熄灭的灯火。"},
{volumn:        23,chapter:        43,versenumber:        18,verse:"耶和华如此说：“你们不要记念从前的事，也不要思想古时的事。"},
{volumn:        23,chapter:        43,versenumber:        19,verse:"看哪，我要做一件新事；如今要发现，你们岂不知道吗？我必在旷野开道路，在沙漠开江河。"},
{volumn:        23,chapter:        43,versenumber:        20,verse:"野地的走兽必尊重我；野狗和鸵鸟也必如此。因我使旷野有水，使沙漠有河，好赐给我的百姓、我的选民喝。"},
{volumn:        23,chapter:        43,versenumber:        21,verse:"这百姓是我为自己所造的，好述说我的美德。”"},
{volumn:        23,chapter:        43,versenumber:        22,verse:"“雅各啊，你并没有求告我；以色列啊，你倒厌烦我。"},
{volumn:        23,chapter:        43,versenumber:        23,verse:"你没有将你的羊带来给我作燔祭，也没有用祭物尊敬我；我没有因供物使你服劳，也没有因乳香使你厌烦。"},
{volumn:        23,chapter:        43,versenumber:        24,verse:"你没有用银子为我买菖蒲，也没有用祭物的脂油使我饱足；倒使我因你的罪恶服劳，使我因你的罪孽厌烦。"},
{volumn:        23,chapter:        43,versenumber:        25,verse:"“惟有我为自己的缘故涂抹你的过犯；我也不记念你的罪恶。"},
{volumn:        23,chapter:        43,versenumber:        26,verse:"你要提醒我，你我可以一同辩论；你可以将你的理陈明，自显为义。"},
{volumn:        23,chapter:        43,versenumber:        27,verse:"你的始祖犯罪；你的师傅违背我。"},
{volumn:        23,chapter:        43,versenumber:        28,verse:"所以，我要辱没圣所的首领，使雅各成为咒诅，使以色列成为辱骂。”"},
{volumn:        23,chapter:        44,versenumber:         1,verse:"我的仆人雅各，我所拣选的以色列啊，现在你当听。"},
{volumn:        23,chapter:        44,versenumber:         2,verse:"造作你，又从你出胎造就你，并要帮助你的耶和华如此说：“我的仆人雅各，我所拣选的耶书仑哪，不要害怕！"},
{volumn:        23,chapter:        44,versenumber:         3,verse:"因为我要将水浇灌口渴的人，将河浇灌干旱之地。我要将我的灵浇灌你的后裔，将我的福浇灌你的子孙。"},
{volumn:        23,chapter:        44,versenumber:         4,verse:"他们要发生在草中，像溪水旁的柳树。"},
{volumn:        23,chapter:        44,versenumber:         5,verse:"这个要说：我是属耶和华的；那个要以雅各的名自称；又一个要亲手写：归耶和华的（或译：在手上写归耶和华），并自称为以色列。”"},
{volumn:        23,chapter:        44,versenumber:         6,verse:"耶和华以色列的君，以色列的救赎主万军之耶和华如此说：“我是首先的，我是末后的；除我以外再没有真　神。"},
{volumn:        23,chapter:        44,versenumber:         7,verse:"自从我设立古时的民，谁能像我宣告，并且指明，又为自己陈说呢？让他将未来的事和必成的事说明。"},
{volumn:        23,chapter:        44,versenumber:         8,verse:"你们不要恐惧，也不要害怕。我岂不是从上古就说明指示你们吗？并且你们是我的见证！除我以外，岂有真　神吗？诚然没有磐石，我不知道一个！”"},
{volumn:        23,chapter:        44,versenumber:         9,verse:"制造雕刻偶像的尽都虚空；他们所喜悦的都无益处；他们的见证无所看见，无所知晓，他们便觉羞愧。"},
{volumn:        23,chapter:        44,versenumber:        10,verse:"谁制造神像，铸造无益的偶像？"},
{volumn:        23,chapter:        44,versenumber:        11,verse:"看哪，他的同伴都必羞愧。工匠也不过是人，任他们聚会，任他们站立，都必惧怕，一同羞愧。"},
{volumn:        23,chapter:        44,versenumber:        12,verse:"铁匠把铁在火炭中烧热，用锤打铁器，用他有力的膀臂锤成；他饥饿而无力，不喝水而发倦。"},
{volumn:        23,chapter:        44,versenumber:        13,verse:"木匠拉线，用笔划出样子，用刨子刨成形状，用圆尺划了模样，仿照人的体态，做成人形，好住在房屋中。"},
{volumn:        23,chapter:        44,versenumber:        14,verse:"他砍伐香柏树，又取柞（或译：青桐）树和橡树，在树林中选定了一棵。他栽种松树，得雨长养。"},
{volumn:        23,chapter:        44,versenumber:        15,verse:"这树，人可用以烧火；他自己取些烤火，又烧着烤饼，而且做神像跪拜，做雕刻的偶像向它叩拜。"},
{volumn:        23,chapter:        44,versenumber:        16,verse:"他把一份烧在火中，把一份烤肉吃饱。自己烤火说：“啊哈，我暖和了，我见火了。”"},
{volumn:        23,chapter:        44,versenumber:        17,verse:"他用剩下的做了一神，就是雕刻的偶像。他向这偶像俯伏叩拜，祷告它说：“求你拯救我，因你是我的神。”"},
{volumn:        23,chapter:        44,versenumber:        18,verse:"他们不知道，也不思想；因为耶和华闭住他们的眼，不能看见，塞住他们的心，不能明白。"},
{volumn:        23,chapter:        44,versenumber:        19,verse:"谁心里也不醒悟，也没有知识，没有聪明，能说：“我曾拿一份在火中烧了，在炭火上烤过饼；我也烤过肉吃。这剩下的，我岂要作可憎的物吗？我岂可向木墩子叩拜呢？”"},
{volumn:        23,chapter:        44,versenumber:        20,verse:"他以灰为食，心中昏迷，使他偏邪，他不能自救，也不能说：“我右手中岂不是有虚谎吗？”"},
{volumn:        23,chapter:        44,versenumber:        21,verse:"“雅各，以色列啊，你是我的仆人，要记念这些事。以色列啊，你是我的仆人，我造就你必不忘记你。"},
{volumn:        23,chapter:        44,versenumber:        22,verse:"我涂抹了你的过犯，像厚云消散；我涂抹了你的罪恶，如薄云灭没。你当归向我，因我救赎了你。”"},
{volumn:        23,chapter:        44,versenumber:        23,verse:"诸天哪，应当歌唱，因为耶和华做成这事。地的深处啊，应当欢呼；众山应当发声歌唱；树林和其中所有的树都当如此！因为耶和华救赎了雅各，并要因以色列荣耀自己。"},
{volumn:        23,chapter:        44,versenumber:        24,verse:"从你出胎，造就你的救赎主耶和华如此说：“我耶和华是创造万物的，是独自铺张诸天、铺开大地的。谁与我同在呢？"},
{volumn:        23,chapter:        44,versenumber:        25,verse:"使说假话的兆头失效，使占卜的癫狂，使智慧人退后，使他的知识变为愚拙；"},
{volumn:        23,chapter:        44,versenumber:        26,verse:"使我仆人的话语立定，我使者的谋算成就。论到耶路撒冷说：‘必有人居住’；论到犹大的城邑说：‘必被建造，其中的荒场我也必兴起。’"},
{volumn:        23,chapter:        44,versenumber:        27,verse:"对深渊说：‘你干了吧！我也要使你的江河干涸。’"},
{volumn:        23,chapter:        44,versenumber:        28,verse:"论居鲁士说：‘他是我的牧人，必成就我所喜悦的，必下令建造耶路撒冷，发命立稳圣殿的根基。’”"},
{volumn:        23,chapter:        45,versenumber:         1,verse:"我耶和华所膏的居鲁士；我搀扶他的右手，使列国降伏在他面前。我也要放松列王的腰带，使城门在他面前敞开，不得关闭。我对他如此说："},
{volumn:        23,chapter:        45,versenumber:         2,verse:"“我必在你前面行，修平崎岖之地。我必打破铜门，砍断铁闩。"},
{volumn:        23,chapter:        45,versenumber:         3,verse:"我要将暗中的宝物和隐密的财宝赐给你，使你知道提名召你的，就是我耶和华以色列的　神。"},
{volumn:        23,chapter:        45,versenumber:         4,verse:"因我仆人雅各，我所拣选以色列的缘故，我就提名召你；你虽不认识我，我也加给你名号。"},
{volumn:        23,chapter:        45,versenumber:         5,verse:"我是耶和华，在我以外并没有别神；除了我以外再没有　神。你虽不认识我，我必给你束腰。"},
{volumn:        23,chapter:        45,versenumber:         6,verse:"从日出之地到日落之处使人都知道除了我以外，没有别神。我是耶和华；在我以外并没有别神。"},
{volumn:        23,chapter:        45,versenumber:         7,verse:"我造光，又造暗；我施平安，又降灾祸；造作这一切的是我耶和华。"},
{volumn:        23,chapter:        45,versenumber:         8,verse:"“诸天哪，自上而滴，穹苍降下公义；地面开裂，产出救恩，使公义一同发生；这都是我耶和华所造的。”"},
{volumn:        23,chapter:        45,versenumber:         9,verse:"祸哉，那与造他的主争论的！他不过是地上瓦片中的一块瓦片。泥土岂可对抟弄他的说：“你做什么呢？”所做的物岂可说：“你没有手呢？”"},
{volumn:        23,chapter:        45,versenumber:        10,verse:"祸哉，那对父亲说：“你生的是什么呢？”或对母亲（原文是妇人）说：“你产的是什么呢？”"},
{volumn:        23,chapter:        45,versenumber:        11,verse:"耶和华以色列的圣者，就是造就以色列的如此说：“将来的事，你们可以问我；至于我的众子，并我手的工作，你们可以求我命定（原文是吩咐我）。"},
{volumn:        23,chapter:        45,versenumber:        12,verse:"我造地，又造人在地上。我亲手铺张诸天；天上万象也是我所命定的。"},
{volumn:        23,chapter:        45,versenumber:        13,verse:"我凭公义兴起居鲁士（原文是他），又要修直他一切道路。他必建造我的城，释放我被掳的民；不是为工价，也不是为赏赐。这是万军之耶和华说的。”"},
{volumn:        23,chapter:        45,versenumber:        14,verse:"耶和华如此说：“埃及劳碌得来的和古实的货物必归你；身量高大的西巴人必投降你，也要属你。他们必带着锁链过来随从你，又向你下拜，祈求你说：‘　神真在你们中间，此外再没有别神；再没有别的　神。’”"},
{volumn:        23,chapter:        45,versenumber:        15,verse:"救主以色列的　神啊，你实在是自隐的　神。"},
{volumn:        23,chapter:        45,versenumber:        16,verse:"凡制造偶像的都必抱愧蒙羞，都要一同归于惭愧。"},
{volumn:        23,chapter:        45,versenumber:        17,verse:"惟有以色列必蒙耶和华的拯救，得永远的救恩。你们必不蒙羞，也不抱愧，直到永世无尽。"},
{volumn:        23,chapter:        45,versenumber:        18,verse:"创造诸天的耶和华，制造成全大地的　神，他创造坚定大地，并非使地荒凉，是要给人居住。他如此说：“我是耶和华，再没有别神。"},
{volumn:        23,chapter:        45,versenumber:        19,verse:"我没有在隐密黑暗之地说话；我没有对雅各的后裔说：你们寻求我是徒然的。我耶和华所讲的是公义，所说的是正直。”"},
{volumn:        23,chapter:        45,versenumber:        20,verse:"“你们从列国逃脱的人，要一同聚集前来。那些抬着雕刻木偶、祷告不能救人之神的，毫无知识。"},
{volumn:        23,chapter:        45,versenumber:        21,verse:"你们要述说陈明你们的理，让他们彼此商议。谁从古时指明？谁从上古述说？不是我耶和华吗？除了我以外，再没有　神；我是公义的　神，又是救主；除了我以外，再没有别神。"},
{volumn:        23,chapter:        45,versenumber:        22,verse:"地极的人都当仰望我，就必得救；因为我是　神，再没有别神。"},
{volumn:        23,chapter:        45,versenumber:        23,verse:"我指着自己起誓，我口所出的话是凭公义，并不反回：万膝必向我跪拜；万口必凭我起誓。"},
{volumn:        23,chapter:        45,versenumber:        24,verse:"“人论我说，‘公义、能力，惟独在乎耶和华；人都必归向他。凡向他发怒的必至蒙羞。"},
{volumn:        23,chapter:        45,versenumber:        25,verse:"以色列的后裔都必因耶和华得称为义，并要夸耀。’”"},
{volumn:        23,chapter:        46,versenumber:         1,verse:"彼勒屈身，尼波弯腰；巴比伦的偶像驮在兽和牲畜上。他们所抬的如今成了重驮，使牲畜疲乏，"},
{volumn:        23,chapter:        46,versenumber:         2,verse:"都一同弯腰屈身，不能保全重驮，自己倒被掳去。"},
{volumn:        23,chapter:        46,versenumber:         3,verse:"雅各家，以色列家一切余剩的要听我言：“你们自从生下，就蒙我保抱，自从出胎，便蒙我怀搋。"},
{volumn:        23,chapter:        46,versenumber:         4,verse:"直到你们年老，我仍这样；直到你们发白，我仍怀搋。我已造作，也必保抱；我必怀抱，也必拯救。"},
{volumn:        23,chapter:        46,versenumber:         5,verse:"“你们将谁与我相比，与我同等，可以与我比较，使我们相同呢？"},
{volumn:        23,chapter:        46,versenumber:         6,verse:"那从囊中抓金子，用天平平银子的人，雇银匠制造神像，他们又俯伏，又叩拜。"},
{volumn:        23,chapter:        46,versenumber:         7,verse:"他们将神像抬起，扛在肩上，安置在定处，它就站立，不离本位；人呼求它，它不能答应，也不能救人脱离患难。"},
{volumn:        23,chapter:        46,versenumber:         8,verse:"“你们当想念这事，自己作大丈夫。悖逆的人哪，要心里思想。"},
{volumn:        23,chapter:        46,versenumber:         9,verse:"你们要追念上古的事。因为我是　神，并无别神；我是　神，再没有能比我的。"},
{volumn:        23,chapter:        46,versenumber:        10,verse:"我从起初指明末后的事，从古时言明未成的事，说：‘我的筹算必立定；凡我所喜悦的，我必成就。’"},
{volumn:        23,chapter:        46,versenumber:        11,verse:"我召鸷鸟从东方来，召那成就我筹算的人从远方来。我已说出，也必成就；我已谋定，也必做成。"},
{volumn:        23,chapter:        46,versenumber:        12,verse:"你们这些心中顽梗、远离公义的，当听我言。"},
{volumn:        23,chapter:        46,versenumber:        13,verse:"我使我的公义临近，必不远离。我的救恩必不迟延；我要为以色列我的荣耀，在锡安施行救恩。”"},
{volumn:        23,chapter:        47,versenumber:         1,verse:"巴比伦的处女啊，下来坐在尘埃；迦勒底的闺女啊，没有宝座，要坐在地上；因为你不再称为柔弱娇嫩的。"},
{volumn:        23,chapter:        47,versenumber:         2,verse:"要用磨磨面，揭去帕子，脱去长衣，露腿趟河。"},
{volumn:        23,chapter:        47,versenumber:         3,verse:"你的下体必被露出；你的丑陋必被看见。我要报仇，谁也不宽容。"},
{volumn:        23,chapter:        47,versenumber:         4,verse:"我们救赎主的名是万军之耶和华以色列的圣者。"},
{volumn:        23,chapter:        47,versenumber:         5,verse:"迦勒底的闺女啊，你要默然静坐，进入暗中，因为你不再称为列国的主母。"},
{volumn:        23,chapter:        47,versenumber:         6,verse:"我向我的百姓发怒，使我的产业被亵渎，将他们交在你手中，你毫不怜悯他们，把极重的轭加在老年人身上。"},
{volumn:        23,chapter:        47,versenumber:         7,verse:"你自己说：“我必永为主母”，所以你不将这事放在心上，也不思想这事的结局。"},
{volumn:        23,chapter:        47,versenumber:         8,verse:"你这专好宴乐、安然居住的，现在当听这话。你心中说：“惟有我，除我以外再没有别的。我必不致寡居，也不遭丧子之事。”"},
{volumn:        23,chapter:        47,versenumber:         9,verse:"哪知，丧子、寡居这两件事在一日转眼之间必临到你；正在你多行邪术、广施符咒的时候，这两件事必全然临到你身上。"},
{volumn:        23,chapter:        47,versenumber:        10,verse:"你素来倚仗自己的恶行，说：“无人看见我。”你的智慧聪明使你偏邪，并且你心里说：“惟有我，除我以外再没有别的。”"},
{volumn:        23,chapter:        47,versenumber:        11,verse:"因此，祸患要临到你身；你不知何时发现（或译：如何驱逐）灾害落在你身上，你也不能除掉；所不知道的毁灭也必忽然临到你身。"},
{volumn:        23,chapter:        47,versenumber:        12,verse:"站起来吧！用你从幼年劳神施行的符咒和你许多的邪术；或者可得益处，或者可得强胜。"},
{volumn:        23,chapter:        47,versenumber:        13,verse:"你筹划太多，以致疲倦。让那些观天象的，看星宿的，在月朔说预言的，都站起来，救你脱离所要临到你的事。"},
{volumn:        23,chapter:        47,versenumber:        14,verse:"他们要像碎秸被火焚烧，不能救自己脱离火焰之力；这火并非可烤的炭火，也不是可以坐在其前的火。"},
{volumn:        23,chapter:        47,versenumber:        15,verse:"你所劳神的事都要这样与你无益；从幼年与你贸易的也都各奔各乡，无人救你。"},
{volumn:        23,chapter:        48,versenumber:         1,verse:"雅各家，称为以色列名下，从犹大水源出来的，当听我言！你们指着耶和华的名起誓，提说以色列的　神，却不凭诚实，不凭公义。"},
{volumn:        23,chapter:        48,versenumber:         2,verse:"他们自称为圣城的人，所倚靠的是名为万军之耶和华以色列的　神。"},
{volumn:        23,chapter:        48,versenumber:         3,verse:"主说：“早先的事，我从古时说明，已经出了我的口，也是我所指示的；我忽然行做，事便成就。"},
{volumn:        23,chapter:        48,versenumber:         4,verse:"因为我素来知道你是顽梗的，你的颈项是铁的；你的额是铜的。"},
{volumn:        23,chapter:        48,versenumber:         5,verse:"所以，我从古时将这事给你说明，在未成以先指示你，免得你说：‘这些事是我的偶像所行的，是我雕刻的偶像和我铸造的偶像所命定的。’"},
{volumn:        23,chapter:        48,versenumber:         6,verse:"“你已经听见，现在要看见这一切；你不说明吗？从今以后，我将新事，就是你所不知道的隐密事指示你。"},
{volumn:        23,chapter:        48,versenumber:         7,verse:"这事是现今造的，并非从古就有；在今日以先，你也未曾听见，免得你说：‘这事我早已知道了。’"},
{volumn:        23,chapter:        48,versenumber:         8,verse:"你未曾听见，未曾知道；你的耳朵从来未曾开通。我原知道你行事极其诡诈，你自从出胎以来，便称为悖逆的。"},
{volumn:        23,chapter:        48,versenumber:         9,verse:"“我为我的名暂且忍怒，为我的颂赞向你容忍，不将你剪除。"},
{volumn:        23,chapter:        48,versenumber:        10,verse:"我熬炼你，却不像熬炼银子；你在苦难的炉中，我拣选你。"},
{volumn:        23,chapter:        48,versenumber:        11,verse:"我为自己的缘故必行这事，我焉能使我的名被亵渎？我必不将我的荣耀归给假　神。”"},
{volumn:        23,chapter:        48,versenumber:        12,verse:"“雅各、我所选召的以色列啊，当听我言：‘我是耶和华，我是首先的，也是末后的。"},
{volumn:        23,chapter:        48,versenumber:        13,verse:"我手立了地的根基；我右手铺张诸天；我一招呼便都立住。’"},
{volumn:        23,chapter:        48,versenumber:        14,verse:"“你们都当聚集而听，他们（或译：偶像）内中谁说过这些事？耶和华所爱的人必向巴比伦行他所喜悦的事；他的膀臂也要加在迦勒底人身上。"},
{volumn:        23,chapter:        48,versenumber:        15,verse:"惟有我曾说过，我又选召他，领他来，他的道路就必亨通。"},
{volumn:        23,chapter:        48,versenumber:        16,verse:"你们要就近我来听这话：‘我从起头并未曾在隐密处说话；自从有这事，我就在那里。’现在，主耶和华差遣我和他的灵来（或译：耶和华和他的灵差遣我来）。”"},
{volumn:        23,chapter:        48,versenumber:        17,verse:"耶和华你的救赎主，以色列的圣者如此说：“我是耶和华你的　神，教训你，使你得益处，引导你所当行的路。"},
{volumn:        23,chapter:        48,versenumber:        18,verse:"甚愿你素来听从我的命令！你的平安就如河水；你的公义就如海浪。"},
{volumn:        23,chapter:        48,versenumber:        19,verse:"你的后裔也必多如海沙；你腹中所生的也必多如沙粒。他的名在我面前必不剪除，也不灭绝。”"},
{volumn:        23,chapter:        48,versenumber:        20,verse:"你们要从巴比伦出来，从迦勒底人中逃脱，以欢呼的声音传扬说：“耶和华救赎了他的仆人雅各！”你们要将这事宣扬到地极。"},
{volumn:        23,chapter:        48,versenumber:        21,verse:"耶和华引导他们经过沙漠。他们并不干渴；他为他们使水从磐石而流，分裂磐石，水就涌出。"},
{volumn:        23,chapter:        48,versenumber:        22,verse:"耶和华说：“恶人必不得平安！”"},
{volumn:        23,chapter:        49,versenumber:         1,verse:"众海岛啊，当听我言！远方的众民哪，留心而听！自我出胎，耶和华就选召我；自出母腹，他就提我的名。"},
{volumn:        23,chapter:        49,versenumber:         2,verse:"他使我的口如快刀，将我藏在他手荫之下；又使我成为磨亮的箭，将我藏在他箭袋之中；"},
{volumn:        23,chapter:        49,versenumber:         3,verse:"对我说：“你是我的仆人以色列；我必因你得荣耀。”"},
{volumn:        23,chapter:        49,versenumber:         4,verse:"我却说：“我劳碌是徒然；我尽力是虚无虚空。然而，我当得的理必在耶和华那里；我的赏赐必在我　神那里。”"},
{volumn:        23,chapter:        49,versenumber:         5,verse:"耶和华从我出胎，造就我作他的仆人，要使雅各归向他，使以色列到他那里聚集。原来耶和华看我为尊贵；我的　神也成为我的力量。"},
{volumn:        23,chapter:        49,versenumber:         6,verse:"现在他说：“你作我的仆人，使雅各众支派复兴，使以色列中得保全的归回尚为小事，我还要使你作外邦人的光，叫你施行我的救恩，直到地极。”"},
{volumn:        23,chapter:        49,versenumber:         7,verse:"救赎主以色列的圣者耶和华对那被人所藐视、本国所憎恶、官长所虐待的如此说：“君王要看见就站起，首领也要下拜；都因信实的耶和华，就是拣选你以色列的圣者。”"},
{volumn:        23,chapter:        49,versenumber:         8,verse:"耶和华如此说：“在悦纳的时候，我应允了你；在拯救的日子，我济助了你。我要保护你，使你作众民的中保（中保：原文是约）；复兴遍地，使人承受荒凉之地为业。"},
{volumn:        23,chapter:        49,versenumber:         9,verse:"对那被捆绑的人说：‘出来吧！’对那在黑暗的人说：‘显露吧！’他们在路上必得饮食，在一切净光的高处必有食物。"},
{volumn:        23,chapter:        49,versenumber:        10,verse:"不饥不渴，炎热和烈日必不伤害他们；因为怜恤他们的必引导他们，领他们到水泉旁边。"},
{volumn:        23,chapter:        49,versenumber:        11,verse:"我必使我的众山成为大道；我的大路也被修高。"},
{volumn:        23,chapter:        49,versenumber:        12,verse:"看哪，这些从远方来；这些从北方、从西方来；这些从秦（原文是希尼）国来。”"},
{volumn:        23,chapter:        49,versenumber:        13,verse:"诸天哪，应当欢呼！大地啊，应当快乐！众山哪，应当发声歌唱！因为耶和华已经安慰他的百姓，也要怜恤他困苦之民。"},
{volumn:        23,chapter:        49,versenumber:        14,verse:"锡安说：“耶和华离弃了我；主忘记了我。”"},
{volumn:        23,chapter:        49,versenumber:        15,verse:"妇人焉能忘记她吃奶的婴孩，不怜恤她所生的儿子？即或有忘记的，我却不忘记你。"},
{volumn:        23,chapter:        49,versenumber:        16,verse:"看哪，我将你铭刻在我掌上；你的墙垣常在我眼前。"},
{volumn:        23,chapter:        49,versenumber:        17,verse:"你的儿女必急速归回；毁坏你的，使你荒废的，必都离你出去，"},
{volumn:        23,chapter:        49,versenumber:        18,verse:"你举目向四方观看；他们都聚集来到你这里。耶和华说：“我指着我的永生起誓：你必要以他们为妆饰佩戴，以他们为华带束腰，像新妇一样。"},
{volumn:        23,chapter:        49,versenumber:        19,verse:"“至于你荒废凄凉之处，并你被毁坏之地，现今众民居住必显为太窄；吞灭你的必离你遥远。"},
{volumn:        23,chapter:        49,versenumber:        20,verse:"你必听见丧子之后所生的儿女说：‘这地方我居住太窄，求你给我地方居住。"},
{volumn:        23,chapter:        49,versenumber:        21,verse:"那时你心里必说：我既丧子独居，是被掳的，漂流在外。谁给我生这些？谁将这些养大呢？撇下我一人独居的时候，这些在哪里呢？’”"},
{volumn:        23,chapter:        49,versenumber:        22,verse:"主耶和华如此说：“我必向列国举手，向万民竖立大旗；他们必将你的众子怀中抱来，将你的众女肩上扛来。"},
{volumn:        23,chapter:        49,versenumber:        23,verse:"列王必作你的养父；王后必作你的乳母。他们必将脸伏地，向你下拜，并舔你脚上的尘土。你便知道我是耶和华；等候我的必不致羞愧。”"},
{volumn:        23,chapter:        49,versenumber:        24,verse:"勇士抢去的岂能夺回？该掳掠的岂能解救吗？"},
{volumn:        23,chapter:        49,versenumber:        25,verse:"但耶和华如此说：“就是勇士所掳掠的，也可以夺回；强暴人所抢的，也可以解救。与你相争的，我必与他相争；我要拯救你的儿女。"},
{volumn:        23,chapter:        49,versenumber:        26,verse:"并且我必使那欺压你的吃自己的肉，也要以自己的血喝醉，好像喝甜酒一样。凡有血气的必都知道我耶和华是你的救主，是你的救赎主，是雅各的大能者。”"},
{volumn:        23,chapter:        50,versenumber:         1,verse:"耶和华如此说：“我休你们的母亲，休书在哪里呢？我将你们卖给我哪一个债主呢？你们被卖，是因你们的罪孽；你们的母亲被休，是因你们的过犯。"},
{volumn:        23,chapter:        50,versenumber:         2,verse:"我来的时候，为何无人等候呢？我呼唤的时候，为何无人答应呢？我的膀臂岂是缩短、不能救赎吗？我岂无拯救之力吗？看哪，我一斥责，海就干了；我使江河变为旷野；其中的鱼因无水腥臭，干渴而死。"},
{volumn:        23,chapter:        50,versenumber:         3,verse:"我使诸天以黑暗为衣服，以麻布为遮盖。”"},
{volumn:        23,chapter:        50,versenumber:         4,verse:"主耶和华赐我受教者的舌头，使我知道怎样用言语扶助疲乏的人。主每早晨提醒，提醒我的耳朵，使我能听，像受教者一样。"},
{volumn:        23,chapter:        50,versenumber:         5,verse:"主耶和华开通我的耳朵；我并没有违背，也没有退后。"},
{volumn:        23,chapter:        50,versenumber:         6,verse:"人打我的背，我任他打；人拔我腮颊的胡须，我由他拔；人辱我，吐我，我并不掩面。"},
{volumn:        23,chapter:        50,versenumber:         7,verse:"主耶和华必帮助我，所以我不抱愧。我硬着脸面好像坚石；我也知道我必不致蒙羞。"},
{volumn:        23,chapter:        50,versenumber:         8,verse:"称我为义的与我相近；谁与我争论，可以与我一同站立；谁与我作对，可以就近我来。"},
{volumn:        23,chapter:        50,versenumber:         9,verse:"主耶和华要帮助我；谁能定我有罪呢？他们都像衣服渐渐旧了，为蛀虫所咬。"},
{volumn:        23,chapter:        50,versenumber:        10,verse:"你们中间谁是敬畏耶和华、听从他仆人之话的？这人行在暗中，没有亮光。当倚靠耶和华的名，仗赖自己的　神。"},
{volumn:        23,chapter:        50,versenumber:        11,verse:"凡你们点火，用火把围绕自己的可以行在你们的火焰里，并你们所点的火把中。这是我手所定的：你们必躺在悲惨之中。"},
{volumn:        23,chapter:        51,versenumber:         1,verse:"你们这追求公义、寻求耶和华的，当听我言！你们要追想被凿而出的磐石，被挖而出的岩穴。"},
{volumn:        23,chapter:        51,versenumber:         2,verse:"要追想你们的祖宗亚伯拉罕和生养你们的撒拉；因为亚伯拉罕独自一人的时候，我选召他，赐福与他，使他人数增多。"},
{volumn:        23,chapter:        51,versenumber:         3,verse:"耶和华已经安慰锡安和锡安一切的荒场，使旷野像伊甸，使沙漠像耶和华的园囿；在其中必有欢喜、快乐、感谢，和歌唱的声音。"},
{volumn:        23,chapter:        51,versenumber:         4,verse:"“我的百姓啊，要向我留心；我的国民哪，要向我侧耳；因为训诲必从我而出；我必坚定我的公理为万民之光。"},
{volumn:        23,chapter:        51,versenumber:         5,verse:"我的公义临近；我的救恩发出。我的膀臂要审判万民；海岛都要等候我，倚赖我的膀臂。"},
{volumn:        23,chapter:        51,versenumber:         6,verse:"你们要向天举目，观看下地；因为天必像烟云消散，地必如衣服渐渐旧了；其上的居民也要如此死亡（如此死亡：或译像蠓虫死亡）。惟有我的救恩永远长存；我的公义也不废掉。"},
{volumn:        23,chapter:        51,versenumber:         7,verse:"“知道公义、将我训诲存在心中的民，要听我言！不要怕人的辱骂，也不要因人的毁谤惊惶。"},
{volumn:        23,chapter:        51,versenumber:         8,verse:"因为蛀虫必咬他们，好像咬衣服；虫子必咬他们，如同咬羊绒。惟有我的公义永远长存，我的救恩直到万代。”"},
{volumn:        23,chapter:        51,versenumber:         9,verse:"耶和华的膀臂啊，兴起！兴起！以能力为衣穿上，像古时的年日、上古的世代兴起一样。从前砍碎拉哈伯、刺透大鱼的，不是你吗？"},
{volumn:        23,chapter:        51,versenumber:        10,verse:"使海与深渊的水干涸、使海的深处变为赎民经过之路的，不是你吗？"},
{volumn:        23,chapter:        51,versenumber:        11,verse:"耶和华救赎的民必归回，歌唱来到锡安；永乐必归到他们的头上。他们必得着欢喜快乐；忧愁叹息尽都逃避。"},
{volumn:        23,chapter:        51,versenumber:        12,verse:"“惟有我，是安慰你们的。你是谁？竟怕那必死的人，怕那要变如草的世人，"},
{volumn:        23,chapter:        51,versenumber:        13,verse:"却忘记铺张诸天、立定地基、创造你的耶和华。又因欺压者图谋毁灭要发的暴怒，整天害怕！其实那欺压者的暴怒在哪里呢？"},
{volumn:        23,chapter:        51,versenumber:        14,verse:"被掳去的快得释放，必不死而下坑；他的食物也不致缺乏。"},
{volumn:        23,chapter:        51,versenumber:        15,verse:"我是耶和华你的　神，搅动大海，使海中的波浪砰訇，万军之耶和华是我的名。"},
{volumn:        23,chapter:        51,versenumber:        16,verse:"我将我的话传给你，用我的手影遮蔽你，为要栽定诸天，立定地基，又对锡安说：你是我的百姓。”"},
{volumn:        23,chapter:        51,versenumber:        17,verse:"耶路撒冷啊，兴起！兴起！站起来！你从耶和华手中喝了他忿怒之杯，喝了那使人东倒西歪的爵，以致喝尽。"},
{volumn:        23,chapter:        51,versenumber:        18,verse:"她所生育的诸子中，没有一个引导她的；她所养大的诸子中，没有一个搀扶她的。"},
{volumn:        23,chapter:        51,versenumber:        19,verse:"荒凉、毁灭、饥荒、刀兵，这几样临到你，谁为你举哀？我如何能安慰你呢？"},
{volumn:        23,chapter:        51,versenumber:        20,verse:"你的众子发昏，在各市口上躺卧，好像黄羊在网罗之中，都满了耶和华的忿怒、你　神的斥责。"},
{volumn:        23,chapter:        51,versenumber:        21,verse:"因此，你这困苦却非因酒而醉的，要听我言。"},
{volumn:        23,chapter:        51,versenumber:        22,verse:"你的主耶和华，就是为他百姓辨屈的　神如此说：“看哪，我已将那使人东倒西歪的杯，就是我忿怒的爵，从你手中接过来；你必不致再喝。"},
{volumn:        23,chapter:        51,versenumber:        23,verse:"“我必将这杯递在苦待你的人手中；他们曾对你说：‘你屈身，由我们践踏过去吧！’你便以背为地，好像街市，任人经过。”"},
{volumn:        23,chapter:        52,versenumber:         1,verse:"锡安哪，兴起！兴起！披上你的能力！圣城耶路撒冷啊，穿上你华美的衣服！因为从今以后，未受割礼、不洁净的必不再进入你中间。"},
{volumn:        23,chapter:        52,versenumber:         2,verse:"耶路撒冷啊，要抖下尘土！起来坐在位上！锡安被掳的居民（原文是女子）哪，要解开你颈项的锁链！"},
{volumn:        23,chapter:        52,versenumber:         3,verse:"耶和华如此说：“你们是无价被卖的，也必无银被赎。"},
{volumn:        23,chapter:        52,versenumber:         4,verse:"主耶和华如此说：起先我的百姓下到埃及，在那里寄居，又有亚述人无故欺压他们。"},
{volumn:        23,chapter:        52,versenumber:         5,verse:"耶和华说：我的百姓既是无价被掳去，如今我在这里做什么呢？耶和华说：辖制他们的人呼叫，我的名整天受亵渎。"},
{volumn:        23,chapter:        52,versenumber:         6,verse:"所以，我的百姓必知道我的名；到那日他们必知道说这话的就是我。看哪，是我！”"},
{volumn:        23,chapter:        52,versenumber:         7,verse:"那报佳音，传平安，报好信，传救恩的，对锡安说：“你的　神作王了！”这人的脚登山何等佳美！"},
{volumn:        23,chapter:        52,versenumber:         8,verse:"听啊，你守望之人的声音，他们扬起声来，一同歌唱；因为耶和华归回锡安的时候，他们必亲眼看见。"},
{volumn:        23,chapter:        52,versenumber:         9,verse:"耶路撒冷的荒场啊，要发起欢声，一同歌唱；因为耶和华安慰了他的百姓，救赎了耶路撒冷。"},
{volumn:        23,chapter:        52,versenumber:        10,verse:"耶和华在万国眼前露出圣臂；地极的人都看见我们　神的救恩了。"},
{volumn:        23,chapter:        52,versenumber:        11,verse:"你们离开吧！离开吧！从巴比伦出来。不要沾不洁净的物；要从其中出来。你们扛抬耶和华器皿的人哪，务要自洁。"},
{volumn:        23,chapter:        52,versenumber:        12,verse:"你们出来必不至急忙，也不至奔逃。因为，耶和华必在你们前头行；以色列的　神必作你们的后盾。"},
{volumn:        23,chapter:        52,versenumber:        13,verse:"我的仆人行事必有智慧（或译：行事通达），必被高举上升，且成为至高。"},
{volumn:        23,chapter:        52,versenumber:        14,verse:"许多人因他（原文是你）惊奇；他的面貌比别人憔悴；他的形容比世人枯槁。"},
{volumn:        23,chapter:        52,versenumber:        15,verse:"这样，他必洗净（或译：鼓动）许多国民；君王要向他闭口。因所未曾传与他们的，他们必看见；未曾听见的，他们要明白。"},
{volumn:        23,chapter:        53,versenumber:         1,verse:"我们所传的（或译：所传与我们的）有谁信呢？耶和华的膀臂向谁显露呢？"},
{volumn:        23,chapter:        53,versenumber:         2,verse:"他在耶和华面前生长如嫩芽，像根出于干地。他无佳形美容；我们看见他的时候，也无美貌使我们羡慕他。"},
{volumn:        23,chapter:        53,versenumber:         3,verse:"他被藐视，被人厌弃；多受痛苦，常经忧患。他被藐视，好像被人掩面不看的一样；我们也不尊重他。"},
{volumn:        23,chapter:        53,versenumber:         4,verse:"他诚然担当我们的忧患，背负我们的痛苦；我们却以为他受责罚，被　神击打苦待了。"},
{volumn:        23,chapter:        53,versenumber:         5,verse:"哪知他为我们的过犯受害，为我们的罪孽压伤。因他受的刑罚，我们得平安；因他受的鞭伤，我们得医治。"},
{volumn:        23,chapter:        53,versenumber:         6,verse:"我们都如羊走迷；各人偏行己路；耶和华使我们众人的罪孽都归在他身上。"},
{volumn:        23,chapter:        53,versenumber:         7,verse:"他被欺压，在受苦的时候却不开口（或译：他受欺压，却自卑不开口）；他像羊羔被牵到宰杀之地，又像羊在剪毛的人手下无声，他也是这样不开口。"},
{volumn:        23,chapter:        53,versenumber:         8,verse:"因受欺压和审判，他被夺去，至于他同世的人，谁想他受鞭打、从活人之地被剪除，是因我百姓的罪过呢？"},
{volumn:        23,chapter:        53,versenumber:         9,verse:"他虽然未行强暴，口中也没有诡诈，人还使他与恶人同埋；谁知死的时候与财主同葬。"},
{volumn:        23,chapter:        53,versenumber:        10,verse:"耶和华却定意（或译：喜悦）将他压伤，使他受痛苦。耶和华以他为赎罪祭（或译：他献本身为赎罪祭）。他必看见后裔，并且延长年日。耶和华所喜悦的事必在他手中亨通。"},
{volumn:        23,chapter:        53,versenumber:        11,verse:"他必看见自己劳苦的功效，便心满意足。有许多人因认识我的义仆得称为义；并且他要担当他们的罪孽。"},
{volumn:        23,chapter:        53,versenumber:        12,verse:"所以，我要使他与位大的同分，与强盛的均分掳物。因为他将命倾倒，以致于死；他也被列在罪犯之中。他却担当多人的罪，又为罪犯代求。"},
{volumn:        23,chapter:        54,versenumber:         1,verse:"“你这不怀孕、不生养的要歌唱；你这未曾经过产难的要发声歌唱，扬声欢呼；因为没有丈夫的比有丈夫的儿女更多。这是耶和华说的。"},
{volumn:        23,chapter:        54,versenumber:         2,verse:"要扩张你帐幕之地，张大你居所的幔子，不要限止；要放长你的绳子，坚固你的橛子。"},
{volumn:        23,chapter:        54,versenumber:         3,verse:"因为你要向左向右开展；你的后裔必得多国为业，又使荒凉的城邑有人居住。"},
{volumn:        23,chapter:        54,versenumber:         4,verse:"“不要惧怕，因你必不致蒙羞；也不要抱愧，因你必不致受辱。你必忘记幼年的羞愧，不再记念你寡居的羞辱。"},
{volumn:        23,chapter:        54,versenumber:         5,verse:"因为造你的是你的丈夫；万军之耶和华是他的名。救赎你的是以色列的圣者；他必称为全地之　神。"},
{volumn:        23,chapter:        54,versenumber:         6,verse:"耶和华召你，如召被离弃心中忧伤的妻，就是幼年所娶被弃的妻。这是你　神所说的。"},
{volumn:        23,chapter:        54,versenumber:         7,verse:"我离弃你不过片时，却要施大恩将你收回。"},
{volumn:        23,chapter:        54,versenumber:         8,verse:"我的怒气涨溢，顷刻之间向你掩面，却要以永远的慈爱怜恤你。这是耶和华你的救赎主说的。"},
{volumn:        23,chapter:        54,versenumber:         9,verse:"“这事在我好像挪亚的洪水。我怎样起誓不再使挪亚的洪水漫过遍地，我也照样起誓不再向你发怒，也不斥责你。"},
{volumn:        23,chapter:        54,versenumber:        10,verse:"大山可以挪开，小山可以迁移；但我的慈爱必不离开你；我平安的约也不迁移。这是怜恤你的耶和华说的。”"},
{volumn:        23,chapter:        54,versenumber:        11,verse:"“你这受困苦、被风飘荡不得安慰的人哪，我必以彩色安置你的石头，以蓝宝石立定你的根基；"},
{volumn:        23,chapter:        54,versenumber:        12,verse:"又以红宝石造你的女墙，以红玉造你的城门，以宝石造你四围的边界（或译：外郭）。"},
{volumn:        23,chapter:        54,versenumber:        13,verse:"你的儿女都要受耶和华的教训；你的儿女必大享平安。"},
{volumn:        23,chapter:        54,versenumber:        14,verse:"你必因公义得坚立，必远离欺压，不致害怕；你必远离惊吓，惊吓必不临近你。"},
{volumn:        23,chapter:        54,versenumber:        15,verse:"即或有人聚集，却不由于我；凡聚集攻击你的，必因你仆倒（或译：投降你）。"},
{volumn:        23,chapter:        54,versenumber:        16,verse:"吹嘘炭火、打造合用器械的铁匠是我所造；残害人、行毁灭的也是我所造。"},
{volumn:        23,chapter:        54,versenumber:        17,verse:"凡为攻击你造成的器械必不利用；凡在审判时兴起用舌攻击你的，你必定他为有罪。这是耶和华仆人的产业，是他们从我所得的义。这是耶和华说的。”"},
{volumn:        23,chapter:        55,versenumber:         1,verse:"“你们一切干渴的都当就近水来；没有银钱的也可以来。你们都来，买了吃；不用银钱，不用价值，也来买酒和奶。"},
{volumn:        23,chapter:        55,versenumber:         2,verse:"你们为何花钱（原文是平银）买那不足为食物的？用劳碌得来的买那不使人饱足的呢？你们要留意听我的话就能吃那美物，得享肥甘，心中喜乐。"},
{volumn:        23,chapter:        55,versenumber:         3,verse:"你们当就近我来；侧耳而听，就必得活。我必与你们立永约，就是应许大卫那可靠的恩典。"},
{volumn:        23,chapter:        55,versenumber:         4,verse:"我已立他作万民的见证，为万民的君王和司令。"},
{volumn:        23,chapter:        55,versenumber:         5,verse:"你素不认识的国民，你也必召来；素不认识你的国民也必向你奔跑，都因耶和华你的　神以色列的圣者，因为他已经荣耀你。”"},
{volumn:        23,chapter:        55,versenumber:         6,verse:"当趁耶和华可寻找的时候寻找他，相近的时候求告他。"},
{volumn:        23,chapter:        55,versenumber:         7,verse:"恶人当离弃自己的道路；不义的人当除掉自己的意念。归向耶和华，耶和华就必怜恤他；当归向我们的　神，因为　神必广行赦免。"},
{volumn:        23,chapter:        55,versenumber:         8,verse:"耶和华说：“我的意念非同你们的意念；我的道路非同你们的道路。"},
{volumn:        23,chapter:        55,versenumber:         9,verse:"天怎样高过地，照样，我的道路高过你们的道路；我的意念高过你们的意念。"},
{volumn:        23,chapter:        55,versenumber:        10,verse:"雨雪从天而降，并不返回，却滋润地土，使地上发芽结实，使撒种的有种，使要吃的有粮。"},
{volumn:        23,chapter:        55,versenumber:        11,verse:"我口所出的话也必如此，决不徒然返回，却要成就我所喜悦的，在我发他去成就（发他去成就：或译所命定）的事上必然亨通。"},
{volumn:        23,chapter:        55,versenumber:        12,verse:"你们必欢欢喜喜而出来，平平安安蒙引导。大山小山必在你们面前发声歌唱；田野的树木也都拍掌。"},
{volumn:        23,chapter:        55,versenumber:        13,verse:"松树长出，代替荆棘；番石榴长出，代替蒺藜。这要为耶和华留名，作为永远的证据，不能剪除。”"},
{volumn:        23,chapter:        56,versenumber:         1,verse:"耶和华如此说：“你们当守公平，行公义；因我的救恩临近，我的公义将要显现。"},
{volumn:        23,chapter:        56,versenumber:         2,verse:"谨守安息日而不干犯，禁止己手而不作恶；如此行、如此持守的人便为有福。”"},
{volumn:        23,chapter:        56,versenumber:         3,verse:"与耶和华联合的外邦人不要说：“耶和华必定将我从他民中分别出来。”太监也不要说：“我是枯树。”"},
{volumn:        23,chapter:        56,versenumber:         4,verse:"因为耶和华如此说：“那些谨守我的安息日，拣选我所喜悦的事，持守我约的太监，"},
{volumn:        23,chapter:        56,versenumber:         5,verse:"我必使他们在我殿中，在我墙内，有记念，有名号，比有儿女的更美。我必赐他们永远的名，不能剪除。”"},
{volumn:        23,chapter:        56,versenumber:         6,verse:"还有那些与耶和华联合的外邦人，要侍奉他，要爱耶和华的名，要作他的仆人，就是凡守安息日不干犯，又持守他（原文是我）约的人。"},
{volumn:        23,chapter:        56,versenumber:         7,verse:"“我必领他们到我的圣山，使他们在祷告我的殿中喜乐。他们的燔祭和平安祭，在我坛上必蒙悦纳，因我的殿必称为万民祷告的殿。”"},
{volumn:        23,chapter:        56,versenumber:         8,verse:"主耶和华，就是招聚以色列被赶散的，说：“在这被招聚的人以外，我还要招聚别人归并他们。”"},
{volumn:        23,chapter:        56,versenumber:         9,verse:"田野的诸兽都来吞吃吧！林中的诸兽也要如此。"},
{volumn:        23,chapter:        56,versenumber:        10,verse:"他看守的人是瞎眼的，都没有知识，都是哑巴狗，不能叫唤；但知做梦，躺卧，贪睡，"},
{volumn:        23,chapter:        56,versenumber:        11,verse:"这些狗贪食，不知饱足。这些牧人不能明白，各人偏行己路，各从各方求自己的利益。"},
{volumn:        23,chapter:        56,versenumber:        12,verse:"他们说：“来吧！我去拿酒，我们饱饮浓酒；明日必和今日一样，就是宴乐无量极大之日。”"},
{volumn:        23,chapter:        57,versenumber:         1,verse:"义人死亡，无人放在心上；虔诚人被收去，无人思念。这义人被收去是免了将来的祸患；"},
{volumn:        23,chapter:        57,versenumber:         2,verse:"他们得享（原文是进入）平安。素行正直的，各人在坟里（原文是床上）安歇。"},
{volumn:        23,chapter:        57,versenumber:         3,verse:"你们这些巫婆的儿子，奸夫和妓女的种子，都要前来！"},
{volumn:        23,chapter:        57,versenumber:         4,verse:"你们向谁戏笑？向谁张口吐舌呢？你们岂不是悖逆的儿女，虚谎的种类呢？"},
{volumn:        23,chapter:        57,versenumber:         5,verse:"你们在橡树中间，在各青翠树下欲火攻心；在山谷间，在石穴下杀了儿女；"},
{volumn:        23,chapter:        57,versenumber:         6,verse:"在谷中光滑石头里有你的份。这些就是你所得的份；你也向他浇了奠祭，献了供物，因这事我岂能容忍吗？"},
{volumn:        23,chapter:        57,versenumber:         7,verse:"你在高而又高的山上安设床榻，也上那里去献祭。"},
{volumn:        23,chapter:        57,versenumber:         8,verse:"你在门后，在门框后，立起你的纪念；向外人赤露，又上去扩张床榻，与他们立约；你在那里看见他们的床就甚喜爱。"},
{volumn:        23,chapter:        57,versenumber:         9,verse:"你把油带到王那里，又多加香料，打发使者往远方去，自卑自贱直到阴间，"},
{volumn:        23,chapter:        57,versenumber:        10,verse:"你因路远疲倦，却不说这是枉然；你以为有复兴之力，所以不觉疲惫。"},
{volumn:        23,chapter:        57,versenumber:        11,verse:"你怕谁？因谁恐惧？竟说谎，不记念我，又不将这事放在心上。我不是许久闭口不言，你仍不怕我吗？"},
{volumn:        23,chapter:        57,versenumber:        12,verse:"我要指明你的公义；至于你所行的都必与你无益。"},
{volumn:        23,chapter:        57,versenumber:        13,verse:"你哀求的时候，让你所聚集的拯救你吧！风要把他们刮散，一口气要把他们都吹去。但那投靠我的必得地土，必承受我的圣山为业。"},
{volumn:        23,chapter:        57,versenumber:        14,verse:"耶和华要说：“你们修筑修筑，预备道路，将绊脚石从我百姓的路中除掉。”"},
{volumn:        23,chapter:        57,versenumber:        15,verse:"因为那至高至上、永远长存（原文是住在永远）名为圣者的如此说：“我住在至高至圣的所在，也与心灵痛悔谦卑的人同居；要使谦卑人的灵苏醒，也使痛悔人的心苏醒。"},
{volumn:        23,chapter:        57,versenumber:        16,verse:"我必不永远相争，也不长久发怒，恐怕我所造的人与灵性都必发昏。"},
{volumn:        23,chapter:        57,versenumber:        17,verse:"因他贪婪的罪孽，我就发怒击打他；我向他掩面发怒，他却仍然随心背道。"},
{volumn:        23,chapter:        57,versenumber:        18,verse:"我看见他所行的道，也要医治他；又要引导他，使他和那一同伤心的人再得安慰。"},
{volumn:        23,chapter:        57,versenumber:        19,verse:"我造就嘴唇的果子；愿平安康泰归与远处的人，也归与近处的人；并且我要医治他。”这是耶和华说的。"},
{volumn:        23,chapter:        57,versenumber:        20,verse:"惟独恶人，好像翻腾的海，不得平静；其中的水常涌出污秽和淤泥来。"},
{volumn:        23,chapter:        57,versenumber:        21,verse:"我的　神说：“恶人必不得平安！”"},
{volumn:        23,chapter:        58,versenumber:         1,verse:"“你要大声喊叫，不可止息；扬起声来，好像吹角。向我百姓说明他们的过犯；向雅各家说明他们的罪恶。"},
{volumn:        23,chapter:        58,versenumber:         2,verse:"他们天天寻求我，乐意明白我的道，好像行义的国民，不离弃他们　神的典章，向我求问公义的判语，喜悦亲近　神。"},
{volumn:        23,chapter:        58,versenumber:         3,verse:"他们说：‘我们禁食，你为何不看见呢？我们刻苦己心，你为何不理会呢？’看哪，你们禁食的日子仍求利益，勒逼人为你们做苦工。"},
{volumn:        23,chapter:        58,versenumber:         4,verse:"你们禁食，却互相争竞，以凶恶的拳头打人。你们今日禁食，不得使你们的声音听闻于上。"},
{volumn:        23,chapter:        58,versenumber:         5,verse:"这样禁食岂是我所拣选、使人刻苦己心的日子吗？岂是叫人垂头像苇子，用麻布和炉灰铺在他以下吗？你这可称为禁食、为耶和华所悦纳的日子吗？"},
{volumn:        23,chapter:        58,versenumber:         6,verse:"“我所拣选的禁食不是要松开凶恶的绳，解下轭上的索，使被欺压的得自由，折断一切的轭吗？"},
{volumn:        23,chapter:        58,versenumber:         7,verse:"不是要把你的饼分给饥饿的人，将飘流的穷人接到你家中，见赤身的给他衣服遮体，顾恤自己的骨肉而不掩藏吗？"},
{volumn:        23,chapter:        58,versenumber:         8,verse:"这样，你的光就必发现如早晨的光；你所得的医治要速速发明。你的公义必在你前面行；耶和华的荣光必作你的后盾。"},
{volumn:        23,chapter:        58,versenumber:         9,verse:"那时你求告，耶和华必应允；你呼求，他必说：我在这里。“你若从你中间除掉重轭和指摘人的指头，并发恶言的事，"},
{volumn:        23,chapter:        58,versenumber:        10,verse:"你心若向饥饿的人发怜悯，使困苦的人得满足，你的光就必在黑暗中发现；你的幽暗必变如正午。"},
{volumn:        23,chapter:        58,versenumber:        11,verse:"耶和华也必时常引导你，在干旱之地使你心满意足，骨头强壮。你必像浇灌的园子，又像水流不绝的泉源。"},
{volumn:        23,chapter:        58,versenumber:        12,verse:"那些出于你的人必修造久已荒废之处；你要建立拆毁累代的根基。你必称为补破口的，和重修路径与人居住的。”"},
{volumn:        23,chapter:        58,versenumber:        13,verse:"“你若在安息日掉转（或译：谨慎）你的脚步，在我圣日不以操作为喜乐，称安息日为可喜乐的，称耶和华的圣日为可尊重的；而且尊敬这日，不办自己的私事，不随自己的私意，不说自己的私话，"},
{volumn:        23,chapter:        58,versenumber:        14,verse:"你就以耶和华为乐。耶和华要使你乘驾地的高处，又以你祖雅各的产业养育你。”这是耶和华亲口说的。"},
{volumn:        23,chapter:        59,versenumber:         1,verse:"耶和华的膀臂并非缩短，不能拯救，耳朵并非发沉，不能听见，"},
{volumn:        23,chapter:        59,versenumber:         2,verse:"但你们的罪孽使你们与　神隔绝；你们的罪恶使他掩面不听你们。"},
{volumn:        23,chapter:        59,versenumber:         3,verse:"因你们的手被血沾染，你们的指头被罪孽沾污，你们的嘴唇说谎言，你们的舌头出恶语。"},
{volumn:        23,chapter:        59,versenumber:         4,verse:"无一人按公义告状，无一人凭诚实辨白；都倚靠虚妄，说谎言。所怀的是毒害；所生的是罪孽。"},
{volumn:        23,chapter:        59,versenumber:         5,verse:"他们抱毒蛇蛋，结蜘蛛网；人吃这蛋必死。这蛋被踏，必出蝮蛇。"},
{volumn:        23,chapter:        59,versenumber:         6,verse:"所结的网不能成为衣服；所做的也不能遮盖自己。他们的行为都是罪孽；手所做的都是强暴。"},
{volumn:        23,chapter:        59,versenumber:         7,verse:"他们的脚奔跑行恶；他们急速流无辜人的血；意念都是罪孽，所经过的路都荒凉毁灭。"},
{volumn:        23,chapter:        59,versenumber:         8,verse:"平安的路，他们不知道；所行的事没有公平。他们为自己修弯曲的路；凡行此路的都不知道平安。"},
{volumn:        23,chapter:        59,versenumber:         9,verse:"因此，公平离我们远，公义追不上我们。我们指望光亮，却是黑暗，指望光明，却行幽暗。"},
{volumn:        23,chapter:        59,versenumber:        10,verse:"我们摸索墙壁，好像瞎子；我们摸索，如同无目之人。我们晌午绊脚，如在黄昏一样；我们在肥壮人中，像死人一般。"},
{volumn:        23,chapter:        59,versenumber:        11,verse:"我们咆哮如熊，哀鸣如鸽；指望公平，却是没有；指望救恩，却远离我们。"},
{volumn:        23,chapter:        59,versenumber:        12,verse:"我们的过犯在你面前增多，罪恶作见证告我们；过犯与我们同在。至于我们的罪孽，我们都知道："},
{volumn:        23,chapter:        59,versenumber:        13,verse:"就是悖逆、不认识耶和华，转去不跟从我们的　神，说欺压和叛逆的话，心怀谎言，随即说出。"},
{volumn:        23,chapter:        59,versenumber:        14,verse:"并且公平转而退后，公义站在远处；诚实在街上仆倒，正直也不得进入。"},
{volumn:        23,chapter:        59,versenumber:        15,verse:"诚实少见；离恶的人反成掠物。那时，耶和华看见没有公平，甚不喜悦。"},
{volumn:        23,chapter:        59,versenumber:        16,verse:"他见无人拯救，无人代求，甚为诧异，就用自己的膀臂施行拯救，以公义扶持自己。"},
{volumn:        23,chapter:        59,versenumber:        17,verse:"他以公义为铠甲（或译：护心镜），以拯救为头盔，以报仇为衣服，以热心为外袍。"},
{volumn:        23,chapter:        59,versenumber:        18,verse:"他必按人的行为施报，恼怒他的敌人，报复他的仇敌向众海岛施行报应。"},
{volumn:        23,chapter:        59,versenumber:        19,verse:"如此，人从日落之处必敬畏耶和华的名，从日出之地也必敬畏他的荣耀；因为仇敌好像急流的河水冲来，是耶和华之气所驱逐的。"},
{volumn:        23,chapter:        59,versenumber:        20,verse:"必有一位救赎主来到锡安、雅各族中转离过犯的人那里。这是耶和华说的。"},
{volumn:        23,chapter:        59,versenumber:        21,verse:"耶和华说：“至于我与他们所立的约乃是这样：我加给你的灵，传给你的话，必不离你的口，也不离你后裔与你后裔之后裔的口，从今直到永远；这是耶和华说的。”"},
{volumn:        23,chapter:        60,versenumber:         1,verse:"兴起，发光！因为你的光已经来到！耶和华的荣耀发现照耀你。"},
{volumn:        23,chapter:        60,versenumber:         2,verse:"看哪，黑暗遮盖大地，幽暗遮盖万民，耶和华却要显现照耀你；他的荣耀要现在你身上。"},
{volumn:        23,chapter:        60,versenumber:         3,verse:"万国要来就你的光；君王要来就你发现的光辉。"},
{volumn:        23,chapter:        60,versenumber:         4,verse:"你举目向四方观看；众人都聚集来到你这里。你的众子从远方而来；你的众女也被怀抱而来。"},
{volumn:        23,chapter:        60,versenumber:         5,verse:"那时，你看见就有光荣；你心又跳动又宽畅；因为大海丰盛的货物必转来归你；列国的财宝也必来归你。"},
{volumn:        23,chapter:        60,versenumber:         6,verse:"成群的骆驼，并米甸和以法的独峰驼必遮满你；示巴的众人都必来到；要奉上黄金乳香，又要传说耶和华的赞美。"},
{volumn:        23,chapter:        60,versenumber:         7,verse:"基达的羊群都必聚集到你这里，尼拜约的公羊要供你使用，在我坛上必蒙悦纳；我必荣耀我荣耀的殿。"},
{volumn:        23,chapter:        60,versenumber:         8,verse:"那些飞来如云、又如鸽子向窗户飞回的是谁呢？"},
{volumn:        23,chapter:        60,versenumber:         9,verse:"众海岛必等候我，首先是他施的船只，将你的众子连他们的金银从远方一同带来，都为耶和华你　神的名，又为以色列的圣者，因为他已经荣耀了你。"},
{volumn:        23,chapter:        60,versenumber:        10,verse:"外邦人必建筑你的城墙；他们的王必服侍你。我曾发怒击打你，现今却施恩怜恤你。"},
{volumn:        23,chapter:        60,versenumber:        11,verse:"你的城门必时常开放，昼夜不关；使人把列国的财物带来归你，并将他们的君王牵引而来。"},
{volumn:        23,chapter:        60,versenumber:        12,verse:"哪一邦哪一国不侍奉你，就必灭亡，也必全然荒废。"},
{volumn:        23,chapter:        60,versenumber:        13,verse:"黎巴嫩的荣耀，就是松树、杉树、黄杨树，都必一同归你，为要修饰我圣所之地；我也要使我脚踏之处得荣耀。"},
{volumn:        23,chapter:        60,versenumber:        14,verse:"素来苦待你的，他的子孙都必屈身来就你；藐视你的，都要在你脚下跪拜。他们要称你为“耶和华的城”，为“以色列圣者的锡安”。"},
{volumn:        23,chapter:        60,versenumber:        15,verse:"你虽然被撇弃被厌恶，甚至无人经过，我却使你变为永远的荣华，成为累代的喜乐。"},
{volumn:        23,chapter:        60,versenumber:        16,verse:"你也必吃万国的奶，又吃君王的奶。你便知道我耶和华是你的救主，是你的救赎主，雅各的大能者。"},
{volumn:        23,chapter:        60,versenumber:        17,verse:"我要拿金子代替铜，拿银子代替铁，拿铜代替木头，拿铁代替石头；并要以和平为你的官长，以公义为你的监督。"},
{volumn:        23,chapter:        60,versenumber:        18,verse:"你地上不再听见强暴的事，境内不再听见荒凉毁灭的事。你必称你的墙为“拯救”，称你的门为“赞美”。"},
{volumn:        23,chapter:        60,versenumber:        19,verse:"日头不再作你白昼的光；月亮也不再发光照耀你。耶和华却要作你永远的光；你　神要为你的荣耀。"},
{volumn:        23,chapter:        60,versenumber:        20,verse:"你的日头不再下落；你的月亮也不退缩；因为耶和华必作你永远的光。你悲哀的日子也完毕了。"},
{volumn:        23,chapter:        60,versenumber:        21,verse:"你的居民都成为义人，永远得地为业；是我种的栽子，我手的工作，使我得荣耀。"},
{volumn:        23,chapter:        60,versenumber:        22,verse:"至小的族要加增千倍；微弱的国必成为强盛。我耶和华要按定期速成这事。"},
{volumn:        23,chapter:        61,versenumber:         1,verse:"主耶和华的灵在我身上；因为耶和华用膏膏我，叫我传好信息给谦卑的人（或译：传福音给贫穷的人），差遣我医好伤心的人，报告被掳的得释放，被囚的出监牢；"},
{volumn:        23,chapter:        61,versenumber:         2,verse:"报告耶和华的恩年，和我们　神报仇的日子；安慰一切悲哀的人，"},
{volumn:        23,chapter:        61,versenumber:         3,verse:"赐华冠与锡安悲哀的人，代替灰尘；喜乐油代替悲哀；赞美衣代替忧伤之灵；使他们称为“公义树”，是耶和华所栽的，叫他得荣耀。"},
{volumn:        23,chapter:        61,versenumber:         4,verse:"他们必修造已久的荒场，建立先前凄凉之处，重修历代荒凉之城。"},
{volumn:        23,chapter:        61,versenumber:         5,verse:"那时，外人必起来牧放你们的羊群；外邦人必作你们耕种田地的，修理葡萄园的。"},
{volumn:        23,chapter:        61,versenumber:         6,verse:"你们倒要称为耶和华的祭司；人必称你们为我们　神的仆役。你们必吃用列国的财物，因得他们的荣耀自夸。"},
{volumn:        23,chapter:        61,versenumber:         7,verse:"你们必得加倍的好处，代替所受的羞辱；分中所得的喜乐，必代替所受的凌辱。在境内必得加倍的产业；永远之乐必归与你们（原文是他们）。"},
{volumn:        23,chapter:        61,versenumber:         8,verse:"因为我耶和华喜爱公平，恨恶抢夺和罪孽；我要凭诚实施行报应，并要与我的百姓立永约。"},
{volumn:        23,chapter:        61,versenumber:         9,verse:"他们的后裔必在列国中被人认识；他们的子孙在众民中也是如此。凡看见他们的必认他们是耶和华赐福的后裔。"},
{volumn:        23,chapter:        61,versenumber:        10,verse:"我因耶和华大大欢喜；我的心靠　神快乐。因他以拯救为衣给我穿上，以公义为袍给我披上，好像新郎戴上华冠，又像新妇佩戴妆饰。"},
{volumn:        23,chapter:        61,versenumber:        11,verse:"田地怎样使百谷发芽，园子怎样使所种的发生，主耶和华必照样使公义和赞美在万民中发出。"},
{volumn:        23,chapter:        62,versenumber:         1,verse:"我因锡安必不静默，为耶路撒冷必不息声，直到他的公义如光辉发出，他的救恩如明灯发亮。"},
{volumn:        23,chapter:        62,versenumber:         2,verse:"列国必见你的公义；列王必见你的荣耀。你必得新名的称呼，是耶和华亲口所起的。"},
{volumn:        23,chapter:        62,versenumber:         3,verse:"你在耶和华的手中要作为华冠，在你　神的掌上必作为冕旒。"},
{volumn:        23,chapter:        62,versenumber:         4,verse:"你必不再称为“撇弃的”；你的地也不再称为“荒凉的”。你却要称为“我所喜悦的”；你的地也必称为“有夫之妇”。因为耶和华喜悦你，你的地也必归他。"},
{volumn:        23,chapter:        62,versenumber:         5,verse:"少年人怎样娶处女，你的众民（民：原文是子）也要照样娶你；新郎怎样喜悦新妇，你的　神也要照样喜悦你。"},
{volumn:        23,chapter:        62,versenumber:         6,verse:"耶路撒冷啊，我在你城上设立守望的，他们昼夜必不静默。呼吁耶和华的，你们不要歇息，"},
{volumn:        23,chapter:        62,versenumber:         7,verse:"也不要使他歇息，直等他建立耶路撒冷，使耶路撒冷在地上成为可赞美的。"},
{volumn:        23,chapter:        62,versenumber:         8,verse:"耶和华指着自己的右手和大能的膀臂起誓说：“我必不再将你的五谷给你仇敌作食物；外邦人也不再喝你劳碌得来的新酒。"},
{volumn:        23,chapter:        62,versenumber:         9,verse:"惟有那收割的要吃，并赞美耶和华；那聚敛的要在我圣所的院内喝。”"},
{volumn:        23,chapter:        62,versenumber:        10,verse:"你们当从门经过经过，预备百姓的路；修筑修筑大道，捡去石头，为万民竖立大旗，"},
{volumn:        23,chapter:        62,versenumber:        11,verse:"看哪，耶和华曾宣告到地极，对锡安的居民（原文是女子）说：“你的拯救者来到。他的赏赐在他那里；他的报应在他面前。”"},
{volumn:        23,chapter:        62,versenumber:        12,verse:"人必称他们为“圣民”，为“耶和华的赎民”；你也必称为“被眷顾、不撇弃的城”。"},
{volumn:        23,chapter:        63,versenumber:         1,verse:"这从以东的波斯拉来，穿红衣服，装扮华美，能力广大，大步行走的是谁呢？就是我，是凭公义说话，以大能施行拯救。"},
{volumn:        23,chapter:        63,versenumber:         2,verse:"你的装扮为何有红色？你的衣服为何像踹酒榨的呢？"},
{volumn:        23,chapter:        63,versenumber:         3,verse:"我独自踹酒榨；众民中无一人与我同在。我发怒将他们踹下，发烈怒将他们践踏。他们的血溅在我衣服上，并且污染了我一切的衣裳。"},
{volumn:        23,chapter:        63,versenumber:         4,verse:"因为，报仇之日在我心中；救赎我民之年已经来到。"},
{volumn:        23,chapter:        63,versenumber:         5,verse:"我仰望，见无人帮助；我诧异，没有人扶持。所以，我自己的膀臂为我施行拯救；我的烈怒将我扶持。"},
{volumn:        23,chapter:        63,versenumber:         6,verse:"我发怒，踹下众民；发烈怒，使他们沉醉，又将他们的血倒在地上。"},
{volumn:        23,chapter:        63,versenumber:         7,verse:"我要照耶和华一切所赐给我们的，提起他的慈爱和美德，并他向以色列家所施的大恩；这恩是照他的怜恤和丰盛的慈爱赐给他们的。"},
{volumn:        23,chapter:        63,versenumber:         8,verse:"他说：“他们诚然是我的百姓，不行虚假的子民。”这样，他就作了他们的救主。"},
{volumn:        23,chapter:        63,versenumber:         9,verse:"他们在一切苦难中，他也同受苦难；并且他面前的使者拯救他们；他以慈爱和怜悯救赎他们；在古时的日子常保抱他们，怀搋他们。"},
{volumn:        23,chapter:        63,versenumber:        10,verse:"他们竟悖逆，使主的圣灵担忧。他就转作他们的仇敌，亲自攻击他们。"},
{volumn:        23,chapter:        63,versenumber:        11,verse:"那时，他们（原文是他）想起古时的日子，摩西和他百姓，说：“将百姓和牧养他全群的人从海里领上来的在哪里呢？将他的圣灵降在他们中间的在哪里呢？"},
{volumn:        23,chapter:        63,versenumber:        12,verse:"使他荣耀的膀臂在摩西的右手边行动，在他们前面将水分开，要建立自己永远的名，"},
{volumn:        23,chapter:        63,versenumber:        13,verse:"带领他们经过深处，如马行走旷野，使他们不致绊跌的在哪里呢？”"},
{volumn:        23,chapter:        63,versenumber:        14,verse:"耶和华的灵使他们得安息，仿佛牲畜下到山谷；照样，你也引导你的百姓，要建立自己荣耀的名。"},
{volumn:        23,chapter:        63,versenumber:        15,verse:"求你从天上垂顾，从你圣洁荣耀的居所观看。你的热心和你大能的作为在哪里呢？你爱慕的心肠和怜悯向我们止住了。"},
{volumn:        23,chapter:        63,versenumber:        16,verse:"亚伯拉罕虽然不认识我们，以色列也不承认我们，你却是我们的父。耶和华啊，你是我们的父；从万古以来，你名称为“我们的救赎主”。"},
{volumn:        23,chapter:        63,versenumber:        17,verse:"耶和华啊，你为何使我们走差离开你的道，使我们心里刚硬、不敬畏你呢？求你为你仆人，为你产业支派的缘故，转回来。"},
{volumn:        23,chapter:        63,versenumber:        18,verse:"你的圣民不过暂时得这产业；我们的敌人已经践踏你的圣所。"},
{volumn:        23,chapter:        63,versenumber:        19,verse:"我们好像你未曾治理的人，又像未曾得称你名下的人。"},
{volumn:        23,chapter:        64,versenumber:         1,verse:"愿你裂天而降；愿山在你面前震动，"},
{volumn:        23,chapter:        64,versenumber:         2,verse:"好像火烧干柴，又像火将水烧开，使你敌人知道你的名，使列国在你面前发颤！"},
{volumn:        23,chapter:        64,versenumber:         3,verse:"你曾行我们不能逆料可畏的事。那时你降临，山岭在你面前震动。"},
{volumn:        23,chapter:        64,versenumber:         4,verse:"从古以来，人未曾听见、未曾耳闻、未曾眼见在你以外有什么神为等候他的人行事。"},
{volumn:        23,chapter:        64,versenumber:         5,verse:"你迎接那欢喜行义、记念你道的人；你曾发怒，我们仍犯罪；这景况已久，我们还能得救吗？"},
{volumn:        23,chapter:        64,versenumber:         6,verse:"我们都像不洁净的人；所有的义都像污秽的衣服。我们都像叶子渐渐枯干；我们的罪孽好像风把我们吹去。"},
{volumn:        23,chapter:        64,versenumber:         7,verse:"并且无人求告你的名；无人奋力抓住你。原来你掩面不顾我们，使我们因罪孽消化。"},
{volumn:        23,chapter:        64,versenumber:         8,verse:"耶和华啊，现在你仍是我们的父！我们是泥，你是窑匠；我们都是你手的工作。"},
{volumn:        23,chapter:        64,versenumber:         9,verse:"耶和华啊，求你不要大发震怒，也不要永远记念罪孽。求你垂顾我们，我们都是你的百姓。"},
{volumn:        23,chapter:        64,versenumber:        10,verse:"你的圣邑变为旷野。锡安变为旷野；耶路撒冷成为荒场。"},
{volumn:        23,chapter:        64,versenumber:        11,verse:"我们圣洁华美的殿，就是我们列祖赞美你的所在被火焚烧；我们所羡慕的美地尽都荒废。"},
{volumn:        23,chapter:        64,versenumber:        12,verse:"耶和华啊，有这些事，你还忍得住吗？你仍静默使我们深受苦难吗？"},
{volumn:        23,chapter:        65,versenumber:         1,verse:"“素来没有访问我的，现在求问我；没有寻找我的，我叫他们遇见；没有称为我名下的，我对他们说：‘我在这里！我在这里！’"},
{volumn:        23,chapter:        65,versenumber:         2,verse:"我整天伸手招呼那悖逆的百姓；他们随自己的意念行不善之道。"},
{volumn:        23,chapter:        65,versenumber:         3,verse:"这百姓时常当面惹我发怒；在园中献祭，在坛（原文是砖）上烧香；"},
{volumn:        23,chapter:        65,versenumber:         4,verse:"在坟墓间坐着，在隐密处住宿，吃猪肉；他们器皿中有可憎之物做的汤；"},
{volumn:        23,chapter:        65,versenumber:         5,verse:"且对人说：‘你站开吧！不要挨近我，因为我比你圣洁。’主说：‘这些人是我鼻中的烟，是整天烧着的火。’"},
{volumn:        23,chapter:        65,versenumber:         6,verse:"看哪，这都写在我面前。我必不静默，必施行报应，必将你们的罪孽和你们列祖的罪孽，就是在山上烧香，在冈上亵渎我的罪孽，一同报应在他们后人怀中，我先要把他们所行的量给他们；这是耶和华说的。”"},
{volumn:        23,chapter:        65,versenumber:         7,verse:""},
{volumn:        23,chapter:        65,versenumber:         8,verse:"耶和华如此说：“葡萄中寻得新酒，人就说：‘不要毁坏，因为福在其中。’我因我仆人的缘故也必照样而行，不将他们全然毁灭。"},
{volumn:        23,chapter:        65,versenumber:         9,verse:"我必从雅各中领出后裔，从犹大中领出承受我众山的。我的选民必承受；我的仆人要在那里居住。"},
{volumn:        23,chapter:        65,versenumber:        10,verse:"沙仑平原必成为羊群的圈；亚割谷必成为牛群躺卧之处，都为寻求我的民所得。"},
{volumn:        23,chapter:        65,versenumber:        11,verse:"但你们这些离弃耶和华、忘记我的圣山、给时运摆筵席（原文是桌子）、给天命盛满调和酒的，"},
{volumn:        23,chapter:        65,versenumber:        12,verse:"我要命定你们归在刀下，都必屈身被杀；因为我呼唤，你们没有答应；我说话，你们没有听从；反倒行我眼中看为恶的，拣选我所不喜悦的。”"},
{volumn:        23,chapter:        65,versenumber:        13,verse:"所以，主耶和华如此说：“我的仆人必得吃，你们却饥饿；我的仆人必得喝，你们却干渴；我的仆人必欢喜，你们却蒙羞。"},
{volumn:        23,chapter:        65,versenumber:        14,verse:"我的仆人因心中高兴欢呼，你们却因心中忧愁哀哭，又因心里忧伤哀号。"},
{volumn:        23,chapter:        65,versenumber:        15,verse:"你们必留下自己的名，为我选民指着赌咒。主耶和华必杀你们，另起别名称呼他的仆人。"},
{volumn:        23,chapter:        65,versenumber:        16,verse:"这样，在地上为自己求福的，必凭真实的　神求福；在地上起誓的，必指真实的　神起誓。因为，从前的患难已经忘记，也从我眼前隐藏了。”"},
{volumn:        23,chapter:        65,versenumber:        17,verse:"“看哪！我造新天新地；从前的事不再被记念，也不再追想。"},
{volumn:        23,chapter:        65,versenumber:        18,verse:"你们当因我所造的永远欢喜快乐；因我造耶路撒冷为人所喜，造其中的居民为人所乐。"},
{volumn:        23,chapter:        65,versenumber:        19,verse:"我必因耶路撒冷欢喜，因我的百姓快乐；其中必不再听见哭泣的声音和哀号的声音。"},
{volumn:        23,chapter:        65,versenumber:        20,verse:"其中必没有数日夭亡的婴孩，也没有寿数不满的老者；因为百岁死的仍算孩童，有百岁死的罪人算被咒诅。"},
{volumn:        23,chapter:        65,versenumber:        21,verse:"他们要建造房屋，自己居住；栽种葡萄园，吃其中的果子。"},
{volumn:        23,chapter:        65,versenumber:        22,verse:"他们建造的，别人不得住；他们栽种的，别人不得吃；因为我民的日子必像树木的日子；我选民亲手劳碌得来的必长久享用。"},
{volumn:        23,chapter:        65,versenumber:        23,verse:"他们必不徒然劳碌，所生产的，也不遭灾害，因为都是蒙耶和华赐福的后裔；他们的子孙也是如此。"},
{volumn:        23,chapter:        65,versenumber:        24,verse:"他们尚未求告，我就应允；正说话的时候，我就垂听。"},
{volumn:        23,chapter:        65,versenumber:        25,verse:"豺狼必与羊羔同食；狮子必吃草与牛一样；尘土必作蛇的食物。在我圣山的遍处，这一切都不伤人，不害物。这是耶和华说的。”"},
{volumn:        23,chapter:        66,versenumber:         1,verse:"耶和华如此说：“天是我的座位；地是我的脚凳。你们要为我造何等的殿宇？哪里是我安息的地方呢？”"},
{volumn:        23,chapter:        66,versenumber:         2,verse:"耶和华说：“这一切都是我手所造的，所以就都有了。但我所看顾的，就是虚心（原文是贫穷）痛悔、因我话而战兢的人。"},
{volumn:        23,chapter:        66,versenumber:         3,verse:"假冒为善的宰牛，好像杀人，献羊羔，好像打折狗项，献供物，好像献猪血，烧乳香，好像称颂偶像。这等人拣选自己的道路，心里喜悦行可憎恶的事。"},
{volumn:        23,chapter:        66,versenumber:         4,verse:"我也必拣选迷惑他们的事，使他们所惧怕的临到他们；因为我呼唤，无人答应；我说话，他们不听从；反倒行我眼中看为恶的，拣选我所不喜悦的。”"},
{volumn:        23,chapter:        66,versenumber:         5,verse:"你们因耶和华言语战兢的人当听他的话：你们的弟兄，就是恨恶你们、因我名赶出你们的，曾说：“愿耶和华得荣耀，使我们得见你们的喜乐”，但蒙羞的究竟是他们！"},
{volumn:        23,chapter:        66,versenumber:         6,verse:"有喧哗的声音出自城中！有声音出于殿中！是耶和华向仇敌施行报应的声音！"},
{volumn:        23,chapter:        66,versenumber:         7,verse:"锡安未曾劬劳就生产，未觉疼痛就生出男孩。"},
{volumn:        23,chapter:        66,versenumber:         8,verse:"国岂能一日而生？民岂能一时而产？因为锡安一劬劳便生下儿女，这样的事谁曾听见？谁曾看见呢？"},
{volumn:        23,chapter:        66,versenumber:         9,verse:"耶和华说：“我既使她临产，岂不使她生产呢？你的　神说，我既使她生产，岂能使她闭胎不生呢？”"},
{volumn:        23,chapter:        66,versenumber:        10,verse:"你们爱慕耶路撒冷的都要与她一同欢喜快乐；你们为她悲哀的都要与她一同乐上加乐；"},
{volumn:        23,chapter:        66,versenumber:        11,verse:"使你们在她安慰的怀中吃奶得饱，使他们得她丰盛的荣耀，犹如挤奶，满心喜乐。"},
{volumn:        23,chapter:        66,versenumber:        12,verse:"耶和华如此说：“我要使平安延及她，好像江河，使列国的荣耀延及她，如同涨溢的河。你们要从中享受（原文是咂）；你们必蒙抱在肋旁，摇弄在膝上。"},
{volumn:        23,chapter:        66,versenumber:        13,verse:"母亲怎样安慰儿子，我就照样安慰你们；你们也必因（或译：在）耶路撒冷得安慰。"},
{volumn:        23,chapter:        66,versenumber:        14,verse:"你们看见，就心中快乐；你们的骨头必得滋润像嫩草一样；而且耶和华的手向他仆人所行的必被人知道；他也要向仇敌发恼恨。"},
{volumn:        23,chapter:        66,versenumber:        15,verse:"“看哪，耶和华必在火中降临；他的车辇像旋风，以烈怒施行报应，以火焰施行责罚；"},
{volumn:        23,chapter:        66,versenumber:        16,verse:"因为耶和华在一切有血气的人身上，必以火与刀施行审判；被耶和华所杀的必多。"},
{volumn:        23,chapter:        66,versenumber:        17,verse:"“那些分别为圣、洁净自己的，进入园内跟在其中一个人的后头，吃猪肉和仓鼠并可憎之物，他们必一同灭绝；这是耶和华说的。"},
{volumn:        23,chapter:        66,versenumber:        18,verse:"“我知道他们的行为和他们的意念。时候将到，我必将万民万族（族：原文是舌）聚来，看见我的荣耀，"},
{volumn:        23,chapter:        66,versenumber:        19,verse:"我要显神迹（或译：记号）在他们中间。逃脱的，我要差到列国去，就是到他施、普勒、拉弓的路德和土巴、雅完，并素来没有听见我名声、没有看见我荣耀辽远的海岛；他们必将我的荣耀传扬在列国中。"},
{volumn:        23,chapter:        66,versenumber:        20,verse:"他们必将你们的弟兄从列国中送回，使他们或骑马，或坐车，坐轿，骑骡子，骑独峰驼，到我的圣山耶路撒冷，作为供物献给耶和华，好像以色列人用洁净的器皿盛供物奉到耶和华的殿中；这是耶和华说的。”"},
{volumn:        23,chapter:        66,versenumber:        21,verse:"耶和华说：“我也必从他们中间取人为祭司，为利未人。”"},
{volumn:        23,chapter:        66,versenumber:        22,verse:"耶和华说：“我所要造的新天新地，怎样在我面前长存；你们的后裔和你们的名字也必照样长存。"},
{volumn:        23,chapter:        66,versenumber:        23,verse:"每逢月朔、安息日，凡有血气的必来在我面前下拜。这是耶和华说的。"},
{volumn:        23,chapter:        66,versenumber:        24,verse:"他们必出去观看那些违背我人的尸首；因为他们的虫是不死的；他们的火是不灭的；凡有血气的都必憎恶他们。”"},
{volumn:        24,chapter:         1,versenumber:         1,verse:"便雅悯地亚拿突城的祭司中，希勒家的儿子耶利米的话记在下面。"},
{volumn:        24,chapter:         1,versenumber:         2,verse:"犹大王亚们的儿子约西亚在位十三年，耶和华的话临到耶利米。"},
{volumn:        24,chapter:         1,versenumber:         3,verse:"从犹大王约西亚的儿子约雅敬在位的时候，直到犹大王约西亚的儿子西底家在位的末年，就是十一年五月间耶路撒冷人被掳的时候，耶和华的话也常临到耶利米。"},
{volumn:        24,chapter:         1,versenumber:         4,verse:"耶利米说，耶和华的话临到我说："},
{volumn:        24,chapter:         1,versenumber:         5,verse:"“我未将你造在腹中，我已晓得你；你未出母胎，我已分别你为圣；我已派你作列国的先知。”"},
{volumn:        24,chapter:         1,versenumber:         6,verse:"我就说：“主耶和华啊，我不知怎样说，因为我是年幼的。”"},
{volumn:        24,chapter:         1,versenumber:         7,verse:"耶和华对我说：“你不要说‘我是年幼的’，因为我差遣你到谁那里去，你都要去；我吩咐你说什么话，你都要说。"},
{volumn:        24,chapter:         1,versenumber:         8,verse:"你不要惧怕他们，因为我与你同在，要拯救你。这是耶和华说的。”"},
{volumn:        24,chapter:         1,versenumber:         9,verse:"于是耶和华伸手按我的口，对我说：“我已将当说的话传给你。"},
{volumn:        24,chapter:         1,versenumber:        10,verse:"看哪，我今日立你在列邦列国之上，为要施行拔出、拆毁、毁坏、倾覆，又要建立、栽植。”"},
{volumn:        24,chapter:         1,versenumber:        11,verse:"耶和华的话又临到我说：“耶利米，你看见什么？”我说：“我看见一根杏树枝。”"},
{volumn:        24,chapter:         1,versenumber:        12,verse:"耶和华对我说：“你看得不错；因为我留意保守我的话，使得成就。”"},
{volumn:        24,chapter:         1,versenumber:        13,verse:"耶和华的话第二次临到我说：“你看见什么？”我说：“我看见一个烧开的锅，从北而倾。”"},
{volumn:        24,chapter:         1,versenumber:        14,verse:"耶和华对我说：“必有灾祸从北方发出，临到这地的一切居民。”"},
{volumn:        24,chapter:         1,versenumber:        15,verse:"耶和华说：“看哪，我要召北方列国的众族；他们要来，各安座位在耶路撒冷的城门口，周围攻击城墙，又要攻击犹大的一切城邑。"},
{volumn:        24,chapter:         1,versenumber:        16,verse:"至于这民的一切恶，就是离弃我、向别神烧香、跪拜自己手所造的，我要发出我的判语，攻击他们。"},
{volumn:        24,chapter:         1,versenumber:        17,verse:"所以你当束腰，起来将我所吩咐你的一切话告诉他们；不要因他们惊惶，免得我使你在他们面前惊惶。"},
{volumn:        24,chapter:         1,versenumber:        18,verse:"看哪，我今日使你成为坚城、铁柱、铜墙，与全地和犹大的君王、首领、祭司，并地上的众民反对。"},
{volumn:        24,chapter:         1,versenumber:        19,verse:"他们要攻击你，却不能胜你；因为我与你同在，要拯救你。这是耶和华说的。”"},
{volumn:        24,chapter:         2,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        24,chapter:         2,versenumber:         2,verse:"“你去向耶路撒冷人的耳中喊叫说，耶和华如此说：‘你幼年的恩爱，婚姻的爱情，你怎样在旷野，在未曾耕种之地跟随我，我都记得。"},
{volumn:        24,chapter:         2,versenumber:         3,verse:"那时以色列归耶和华为圣，作为土产初熟的果子；凡吞吃它的必算为有罪，灾祸必临到他们。这是耶和华说的。’”"},
{volumn:        24,chapter:         2,versenumber:         4,verse:"雅各家、以色列家的各族啊，你们当听耶和华的话。"},
{volumn:        24,chapter:         2,versenumber:         5,verse:"耶和华如此说：“你们的列祖见我有什么不义，竟远离我，随从虚无的神，自己成为虚妄的呢？"},
{volumn:        24,chapter:         2,versenumber:         6,verse:"他们也不说：那领我们从埃及地上来，引导我们经过旷野，沙漠有深坑之地，和干旱死荫、无人经过、无人居住之地的耶和华在哪里呢？"},
{volumn:        24,chapter:         2,versenumber:         7,verse:"我领你们进入肥美之地，使你们得吃其中的果子和美物；但你们进入的时候就玷污我的地，使我的产业成为可憎的。"},
{volumn:        24,chapter:         2,versenumber:         8,verse:"祭司都不说：‘耶和华在哪里呢？’传讲律法的都不认识我。官长违背我；先知藉巴力说预言，随从无益的神。”"},
{volumn:        24,chapter:         2,versenumber:         9,verse:"耶和华说：“我因此必与你们争辩，也必与你们的子孙争辩。"},
{volumn:        24,chapter:         2,versenumber:        10,verse:"你们且过到基提海岛去察看，打发人往基达去留心查考，看曾有这样的事没有。"},
{volumn:        24,chapter:         2,versenumber:        11,verse:"岂有一国换了他的神吗？其实这不是神！但我的百姓将他们的荣耀换了那无益的神。"},
{volumn:        24,chapter:         2,versenumber:        12,verse:"诸天哪，要因此惊奇，极其恐慌，甚为凄凉！这是耶和华说的。"},
{volumn:        24,chapter:         2,versenumber:        13,verse:"因为我的百姓做了两件恶事，就是离弃我这活水的泉源，为自己凿出池子，是破裂不能存水的池子。”"},
{volumn:        24,chapter:         2,versenumber:        14,verse:"“以色列是仆人吗？是家中生的奴仆吗？为何成为掠物呢？"},
{volumn:        24,chapter:         2,versenumber:        15,verse:"少壮狮子向他咆哮，大声吼叫，使他的地荒凉；城邑也都焚烧，无人居住。"},
{volumn:        24,chapter:         2,versenumber:        16,verse:"挪弗人和答比匿人也打破你的头顶。"},
{volumn:        24,chapter:         2,versenumber:        17,verse:"这事临到你身上，不是你自招的吗？不是因耶和华你　神引你行路的时候，你离弃他吗？"},
{volumn:        24,chapter:         2,versenumber:        18,verse:"现今你为何在埃及路上要喝西曷的水呢？你为何在亚述路上要喝大河的水呢？"},
{volumn:        24,chapter:         2,versenumber:        19,verse:"你自己的恶必惩治你；你背道的事必责备你。由此可知可见，你离弃耶和华你的　神，不存敬畏我的心，乃为恶事，为苦事。这是主万军之耶和华说的。”"},
{volumn:        24,chapter:         2,versenumber:        20,verse:"“我在古时折断你的轭，解开你的绳索。你说：‘我必不侍奉耶和华’；因为你在各高冈上、各青翠树下屈身行淫（或译：我在古时折断你的轭，解开你的绳索，你就说：我必不侍奉别神。谁知你在各高冈上、各青翠树下仍屈身行淫）。"},
{volumn:        24,chapter:         2,versenumber:        21,verse:"然而，我栽你是上等的葡萄树，全然是真种子；你怎么向我变为外邦葡萄树的坏枝子呢？"},
{volumn:        24,chapter:         2,versenumber:        22,verse:"你虽用碱、多用肥皂洗濯，你罪孽的痕迹仍然在我面前显出。这是主耶和华说的。"},
{volumn:        24,chapter:         2,versenumber:        23,verse:"你怎能说：‘我没有玷污、没有随从众巴力’？你看你谷中的路，就知道你所行的如何。你是快行的独峰驼，狂奔乱走。"},
{volumn:        24,chapter:         2,versenumber:        24,verse:"你是野驴，惯在旷野，欲心发动就吸风；起性的时候谁能使它转去呢？凡寻找它的必不致疲乏；在它的月份必能寻见。"},
{volumn:        24,chapter:         2,versenumber:        25,verse:"我说：‘你不要使脚上无鞋，喉咙干渴。’你倒说：‘这是枉然。我喜爱别神，我必随从他们。’”"},
{volumn:        24,chapter:         2,versenumber:        26,verse:"“贼被捉拿，怎样羞愧，以色列家和他们的君王、首领、祭司、先知也都照样羞愧。"},
{volumn:        24,chapter:         2,versenumber:        27,verse:"他们向木头说：‘你是我的父’；向石头说：‘你是生我的。’他们以背向我，不以面向我；及至遭遇患难的时候却说：‘起来拯救我们。’"},
{volumn:        24,chapter:         2,versenumber:        28,verse:"你为自己做的神在哪里呢？你遭遇患难的时候，叫他们起来拯救你吧！犹大啊，你神的数目与你城的数目相等。”"},
{volumn:        24,chapter:         2,versenumber:        29,verse:"耶和华说：“你们为何与我争辩呢？你们都违背了我。"},
{volumn:        24,chapter:         2,versenumber:        30,verse:"我责打你们的儿女是徒然的，他们不受惩治。你们自己的刀吞灭你们的先知，好像残害的狮子。"},
{volumn:        24,chapter:         2,versenumber:        31,verse:"这世代的人哪，你们要看明耶和华的话。我岂向以色列作旷野呢？或作幽暗之地呢？我的百姓为何说：‘我们脱离约束，再不归向你了’？"},
{volumn:        24,chapter:         2,versenumber:        32,verse:"处女岂能忘记她的妆饰呢？新妇岂能忘记她的美衣呢？我的百姓却忘记了我无数的日子！"},
{volumn:        24,chapter:         2,versenumber:        33,verse:"“你怎么修饰你的道路要求爱情呢？就是恶劣的妇人你也叫她们行你的路。"},
{volumn:        24,chapter:         2,versenumber:        34,verse:"并且你的衣襟上有无辜穷人的血；你杀他们并不是遇见他们挖窟窿，乃是因这一切的事。"},
{volumn:        24,chapter:         2,versenumber:        35,verse:"你还说：‘我无辜；耶和华的怒气必定向我消了。’看哪，我必审问你；因你自说：‘我没有犯罪。’"},
{volumn:        24,chapter:         2,versenumber:        36,verse:"你为何东跑西奔要更换你的路呢？你必因埃及蒙羞，像从前因亚述蒙羞一样。"},
{volumn:        24,chapter:         2,versenumber:        37,verse:"你也必两手抱头从埃及出来；因为耶和华已经弃绝你所倚靠的，你必不因他们得顺利。”"},
{volumn:        24,chapter:         3,versenumber:         1,verse:"“有话说：人若休妻，妻离他而去，作了别人的妻，前夫岂能再收回她来？若收回她来，那地岂不是大大玷污了吗？但你和许多亲爱的行邪淫，还可以归向我。这是耶和华说的。"},
{volumn:        24,chapter:         3,versenumber:         2,verse:"“你向净光的高处举目观看，你在何处没有淫行呢？你坐在道旁等候，好像阿拉伯人在旷野埋伏一样，并且你的淫行邪恶玷污了全地。"},
{volumn:        24,chapter:         3,versenumber:         3,verse:"因此甘霖停止，春（原文是晚）雨不降。你还是有娼妓之脸，不顾羞耻。"},
{volumn:        24,chapter:         3,versenumber:         4,verse:"从今以后，你岂不向我呼叫说：‘我父啊，你是我幼年的恩主。"},
{volumn:        24,chapter:         3,versenumber:         5,verse:"耶和华岂永远怀怒，存留到底吗？’看哪，你又发恶言又行坏事，随自己的私意而行（或译：你虽这样说，还是行恶放纵欲心）。”"},
{volumn:        24,chapter:         3,versenumber:         6,verse:"约西亚王在位的时候，耶和华又对我说：“背道的以色列所行的，你看见没有？她上各高山，在各青翠树下行淫。"},
{volumn:        24,chapter:         3,versenumber:         7,verse:"她行这些事以后，我说她必归向我，她却不归向我。她奸诈的妹妹犹大也看见了。"},
{volumn:        24,chapter:         3,versenumber:         8,verse:"背道的以色列行淫，我为这缘故给她休书休她；我看见她奸诈的妹妹犹大，还不惧怕，也去行淫。"},
{volumn:        24,chapter:         3,versenumber:         9,verse:"因以色列轻忽了她的淫乱，和石头木头行淫，地就被玷污了。"},
{volumn:        24,chapter:         3,versenumber:        10,verse:"虽有这一切的事，她奸诈的妹妹犹大还不一心归向我，不过是假意归我。这是耶和华说的。”"},
{volumn:        24,chapter:         3,versenumber:        11,verse:"耶和华对我说：“背道的以色列比奸诈的犹大还显为义。"},
{volumn:        24,chapter:         3,versenumber:        12,verse:"你去向北方宣告说：“耶和华说：‘背道的以色列啊，回来吧！我必不怒目看你们；因为我是慈爱的，我必不永远存怒。这是耶和华说的。"},
{volumn:        24,chapter:         3,versenumber:        13,verse:"只要承认你的罪孽，就是你违背耶和华你的　神，在各青翠树下向别神东奔西跑，没有听从我的话。这是耶和华说的。’"},
{volumn:        24,chapter:         3,versenumber:        14,verse:"“耶和华说：‘背道的儿女啊，回来吧！因为我作你们的丈夫，并且我必将你们从一城取一人，从一族取两人，带到锡安。"},
{volumn:        24,chapter:         3,versenumber:        15,verse:"我也必将合我心的牧者赐给你们。他们必以知识和智慧牧养你们。’"},
{volumn:        24,chapter:         3,versenumber:        16,verse:"耶和华说：‘你们在国中生养众多；当那些日子，人必不再提说耶和华的约柜，不追想，不记念，不觉缺少，也不再制造。"},
{volumn:        24,chapter:         3,versenumber:        17,verse:"那时，人必称耶路撒冷为耶和华的宝座；万国必到耶路撒冷，在耶和华立名的地方聚集。他们必不再随从自己顽梗的恶心行事。"},
{volumn:        24,chapter:         3,versenumber:        18,verse:"当那些日子，犹大家要和以色列家同行，从北方之地一同来到我赐给你们列祖为业之地。’”"},
{volumn:        24,chapter:         3,versenumber:        19,verse:"“我说：‘我怎样将你安置在儿女之中，赐给你美地，就是万国中肥美的产业。我又说：你们必称我为父，也不再转去不跟从我。"},
{volumn:        24,chapter:         3,versenumber:        20,verse:"以色列家，你们向我行诡诈，真像妻子行诡诈离开她丈夫一样。这是耶和华说的。’"},
{volumn:        24,chapter:         3,versenumber:        21,verse:"“在净光的高处听见人声，就是以色列人哭泣恳求之声，乃因他们走弯曲之道，忘记耶和华他们的　神。"},
{volumn:        24,chapter:         3,versenumber:        22,verse:"你们这背道的儿女啊，回来吧！我要医治你们背道的病。看哪，我们来到你这里，因你是耶和华我们的　神。"},
{volumn:        24,chapter:         3,versenumber:        23,verse:"仰望从小山或从大山的喧嚷中得帮助，真是枉然的。以色列得救，诚然在乎耶和华我们的　神。"},
{volumn:        24,chapter:         3,versenumber:        24,verse:"从我们幼年以来，那可耻的偶像将我们列祖所劳碌得来的羊群、牛群，和他们的儿女都吞吃了。"},
{volumn:        24,chapter:         3,versenumber:        25,verse:"我们在羞耻中躺卧吧！愿惭愧将我们遮盖；因为从立国（原文是幼年）以来，我们和我们的列祖常常得罪耶和华我们的　神，没有听从耶和华我们　神的话。”"},
{volumn:        24,chapter:         4,versenumber:         1,verse:"耶和华说：“以色列啊，你若回来归向我，若从我眼前除掉你可憎的偶像，你就不被迁移。"},
{volumn:        24,chapter:         4,versenumber:         2,verse:"你必凭诚实、公平、公义，指着永生的耶和华起誓；列国必因耶和华称自己为有福，也必因他夸耀。”"},
{volumn:        24,chapter:         4,versenumber:         3,verse:"耶和华对犹大和耶路撒冷人如此说：“要开垦你们的荒地，不要撒种在荆棘中。"},
{volumn:        24,chapter:         4,versenumber:         4,verse:"犹大人和耶路撒冷的居民哪，你们当自行割礼，归耶和华，将心里的污秽除掉；恐怕我的忿怒因你们的恶行发作，如火着起，甚至无人能以熄灭！”"},
{volumn:        24,chapter:         4,versenumber:         5,verse:"“你们当传扬在犹大，宣告在耶路撒冷说：‘你们当在国中吹角，高声呼叫说：你们当聚集！我们好进入坚固城！’"},
{volumn:        24,chapter:         4,versenumber:         6,verse:"应当向锡安竖立大旗。要逃避，不要迟延，因我必使灾祸与大毁灭从北方来到。”"},
{volumn:        24,chapter:         4,versenumber:         7,verse:"有狮子从密林中上来，是毁坏列国的。它已经动身出离本处，要使你的地荒凉，使你的城邑变为荒场无人居住。"},
{volumn:        24,chapter:         4,versenumber:         8,verse:"因此，你们当腰束麻布，大声哀号，因为耶和华的烈怒没有向我们转消。"},
{volumn:        24,chapter:         4,versenumber:         9,verse:"耶和华说：“到那时，君王和首领的心都要消灭；祭司都要惊奇，先知都要诧异。”"},
{volumn:        24,chapter:         4,versenumber:        10,verse:"我说：“哀哉！主耶和华啊，你真是大大地欺哄这百姓和耶路撒冷，说：‘你们必得平安。’其实刀剑害及性命了。”"},
{volumn:        24,chapter:         4,versenumber:        11,verse:"那时，必有话对这百姓和耶路撒冷说：“有一阵热风从旷野净光的高处向我的众民（原文是民女）刮来，不是为簸扬，也不是为扬净。"},
{volumn:        24,chapter:         4,versenumber:        12,verse:"必有一阵更大的风从这些地方为我刮来；现在我又必发出判语，攻击他们。”"},
{volumn:        24,chapter:         4,versenumber:        13,verse:"看哪，仇敌必如云上来；他的战车如旋风，他的马匹比鹰更快。我们有祸了！我们败落了！"},
{volumn:        24,chapter:         4,versenumber:        14,verse:"耶路撒冷啊，你当洗去心中的恶，使你可以得救。恶念存在你心里要到几时呢？"},
{volumn:        24,chapter:         4,versenumber:        15,verse:"有声音从但传扬，从以法莲山报祸患。"},
{volumn:        24,chapter:         4,versenumber:        16,verse:"你们当传给列国，报告攻击耶路撒冷的事说：“有探望的人从远方来到，向犹大的城邑大声呐喊。"},
{volumn:        24,chapter:         4,versenumber:        17,verse:"他们周围攻击耶路撒冷，好像看守田园的，因为她背叛了我。这是耶和华说的。"},
{volumn:        24,chapter:         4,versenumber:        18,verse:"你的行动，你的作为，招惹这事；这是你罪恶的结果，实在是苦，是害及你心了！”"},
{volumn:        24,chapter:         4,versenumber:        19,verse:"我的肺腑啊，我的肺腑啊，我心疼痛！我心在我里面烦躁不安。我不能静默不言，因为我已经听见角声和打仗的喊声。"},
{volumn:        24,chapter:         4,versenumber:        20,verse:"毁坏的信息连络不绝，因为全地荒废。我的帐棚忽然毁坏；我的幔子顷刻破裂。"},
{volumn:        24,chapter:         4,versenumber:        21,verse:"我看见大旗，听见角声，要到几时呢？"},
{volumn:        24,chapter:         4,versenumber:        22,verse:"耶和华说：“我的百姓愚顽，不认识我；他们是愚昧无知的儿女，有智慧行恶，没有知识行善。”"},
{volumn:        24,chapter:         4,versenumber:        23,verse:"先知说：“我观看地，不料，地是空虚混沌；我观看天，天也无光。"},
{volumn:        24,chapter:         4,versenumber:        24,verse:"我观看大山，不料，尽都震动，小山也都摇来摇去。"},
{volumn:        24,chapter:         4,versenumber:        25,verse:"我观看，不料，无人；空中的飞鸟也都躲避。"},
{volumn:        24,chapter:         4,versenumber:        26,verse:"我观看，不料，肥田变为荒地；一切城邑在耶和华面前，因他的烈怒都被拆毁。”"},
{volumn:        24,chapter:         4,versenumber:        27,verse:"耶和华如此说：“全地必然荒凉，我却不毁灭净尽。"},
{volumn:        24,chapter:         4,versenumber:        28,verse:"因此，地要悲哀，在上的天也必黑暗；因为我言已出，我意已定，必不后悔，也不转意不做。"},
{volumn:        24,chapter:         4,versenumber:        29,verse:"各城的人因马兵和弓箭手的响声就都逃跑，进入密林，爬上磐石；各城被撇下，无人住在其中。"},
{volumn:        24,chapter:         4,versenumber:        30,verse:"你凄凉的时候要怎样行呢？你虽穿上朱红衣服，佩戴黄金装饰，用颜料修饰眼目，这样标致是枉然的！恋爱你的藐视你，并且寻索你的性命。”"},
{volumn:        24,chapter:         4,versenumber:        31,verse:"我听见有声音，仿佛妇人产难的声音，好像生头胎疼痛的声音，是锡安女子（就是指民的意思）的声音；她喘着气、挓挲手，说：“我有祸了！在杀人的跟前，我的心发昏了。”"},
{volumn:        24,chapter:         5,versenumber:         1,verse:"“你们当在耶路撒冷的街上跑来跑去，在宽阔处寻找，看看有一人行公义求诚实没有？若有，我就赦免这城。"},
{volumn:        24,chapter:         5,versenumber:         2,verse:"其中的人虽然指着永生的耶和华起誓，所起的誓实在是假的。”"},
{volumn:        24,chapter:         5,versenumber:         3,verse:"耶和华啊，你的眼目不是看顾诚实吗？你击打他们，他们却不伤恸；你毁灭他们，他们仍不受惩治。他们使脸刚硬过于磐石，不肯回头。"},
{volumn:        24,chapter:         5,versenumber:         4,verse:"我说：这些人实在是贫穷的，是愚昧的，因为不晓得耶和华的作为和他们　神的法则。"},
{volumn:        24,chapter:         5,versenumber:         5,verse:"我要去见尊大的人，对他们说话，因为他们晓得耶和华的作为和他们　神的法则。哪知，这些人齐心将轭折断，挣开绳索。"},
{volumn:        24,chapter:         5,versenumber:         6,verse:"因此，林中的狮子必害死他们；晚上（或译：野地）的豺狼必灭绝他们；豹子要在城外窥伺他们。凡出城的必被撕碎；因为他们的罪过极多，背道的事也加增了。"},
{volumn:        24,chapter:         5,versenumber:         7,verse:"“我怎能赦免你呢？你的儿女离弃我，又指着那不是神的起誓。我使他们饱足，他们就行奸淫，成群地聚集在娼妓家里。"},
{volumn:        24,chapter:         5,versenumber:         8,verse:"他们像喂饱的马到处乱跑，各向他邻舍的妻发嘶声。”"},
{volumn:        24,chapter:         5,versenumber:         9,verse:"耶和华说：“我岂不因这些事讨罪呢？岂不报复这样的国民呢？"},
{volumn:        24,chapter:         5,versenumber:        10,verse:"你们要上她葡萄园的墙施行毁坏，但不可毁坏净尽，只可除掉她的枝子，因为不属耶和华。"},
{volumn:        24,chapter:         5,versenumber:        11,verse:"原来以色列家和犹大家大行诡诈攻击我。这是耶和华说的。”"},
{volumn:        24,chapter:         5,versenumber:        12,verse:"他们不认耶和华，说：“这并不是他，灾祸必不临到我们；刀剑和饥荒，我们也看不见。”"},
{volumn:        24,chapter:         5,versenumber:        13,verse:"先知的话必成为风；道也不在他们里面。这灾必临到他们身上。"},
{volumn:        24,chapter:         5,versenumber:        14,verse:"所以耶和华万军之　神如此说：“因为百姓说这话，我必使我的话在你口中为火，使他们为柴；这火便将他们烧灭。”"},
{volumn:        24,chapter:         5,versenumber:        15,verse:"耶和华说：“以色列家啊，我必使一国的民从远方来攻击你，是强盛的国，是从古而有的国。他们的言语你不晓得，他们的话你不明白。"},
{volumn:        24,chapter:         5,versenumber:        16,verse:"他们的箭袋是敞开的坟墓；他们都是勇士。"},
{volumn:        24,chapter:         5,versenumber:        17,verse:"他们必吃尽你的庄稼和你的粮食，是你儿女该吃的；必吃尽你的牛羊，吃尽你的葡萄和无花果；又必用刀毁坏你所倚靠的坚固城。”"},
{volumn:        24,chapter:         5,versenumber:        18,verse:"耶和华说：“就是到那时，我也不将你们毁灭净尽。"},
{volumn:        24,chapter:         5,versenumber:        19,verse:"百姓若说：‘耶和华我们的　神为什么向我们行这一切事呢？’你就对他们说：‘你们怎样离弃耶和华（原文是我），在你们的地上侍奉外邦神，也必照样在不属你们的地上侍奉外邦人。’”"},
{volumn:        24,chapter:         5,versenumber:        20,verse:"当传扬在雅各家，报告在犹大说："},
{volumn:        24,chapter:         5,versenumber:        21,verse:"“愚昧无知的百姓啊，你们有眼不看，有耳不听，现在当听这话。"},
{volumn:        24,chapter:         5,versenumber:        22,verse:"耶和华说：你们怎么不惧怕我呢？我以永远的定例，用沙为海的界限，水不得越过。因此，你们在我面前还不战兢吗？波浪虽然翻腾，却不能逾越；虽然砰訇，却不能过去。"},
{volumn:        24,chapter:         5,versenumber:        23,verse:"但这百姓有背叛忤逆的心；他们叛我而去，"},
{volumn:        24,chapter:         5,versenumber:        24,verse:"心内也不说：‘我们应当敬畏耶和华我们的　神；他按时赐雨，就是秋雨春雨，又为我们定收割的节令，永存不废。’"},
{volumn:        24,chapter:         5,versenumber:        25,verse:"你们的罪孽使这些事转离你们；你们的罪恶使你们不能得福。"},
{volumn:        24,chapter:         5,versenumber:        26,verse:"因为在我民中有恶人。他们埋伏窥探，好像捕鸟的人；他们设立圈套陷害人。"},
{volumn:        24,chapter:         5,versenumber:        27,verse:"笼内怎样满了雀鸟，他们的房中也照样充满诡诈；所以他们得成为大，而且富足。"},
{volumn:        24,chapter:         5,versenumber:        28,verse:"他们肥胖光润，作恶过甚，不为人伸冤！就是不为孤儿伸冤，不使他亨通，也不为穷人辨屈。”"},
{volumn:        24,chapter:         5,versenumber:        29,verse:"耶和华说：“我岂不因这些事讨罪呢？岂不报复这样的国民呢？"},
{volumn:        24,chapter:         5,versenumber:        30,verse:"国中有可惊骇、可憎恶的事："},
{volumn:        24,chapter:         5,versenumber:        31,verse:"就是先知说假预言，祭司藉他们把持权柄；我的百姓也喜爱这些事，到了结局你们怎样行呢？”"},
{volumn:        24,chapter:         6,versenumber:         1,verse:"便雅悯人哪，你们要逃出耶路撒冷，在提哥亚吹角，在伯·哈基琳立号旗；因为有灾祸与大毁灭从北方张望。"},
{volumn:        24,chapter:         6,versenumber:         2,verse:"那秀美娇嫩的锡安女子（就是指民的意思），我必剪除。"},
{volumn:        24,chapter:         6,versenumber:         3,verse:"牧人必引他们的羊群到她那里，在她周围支搭帐棚，各在自己所占之地使羊吃草。"},
{volumn:        24,chapter:         6,versenumber:         4,verse:"你们要准备攻击她。起来吧，我们可以趁午时上去。哀哉！日已渐斜，晚影拖长了。"},
{volumn:        24,chapter:         6,versenumber:         5,verse:"起来吧，我们夜间上去，毁坏她的宫殿。"},
{volumn:        24,chapter:         6,versenumber:         6,verse:"因为万军之耶和华如此说：“你们要砍伐树木，筑垒攻打耶路撒冷。这就是那该罚的城，其中尽是欺压。"},
{volumn:        24,chapter:         6,versenumber:         7,verse:"井怎样涌出水来，这城也照样涌出恶来；在其间常听见有强暴毁灭的事，病患损伤也常在我面前。"},
{volumn:        24,chapter:         6,versenumber:         8,verse:"耶路撒冷啊，你当受教，免得我心与你生疏，免得我使你荒凉，成为无人居住之地。”"},
{volumn:        24,chapter:         6,versenumber:         9,verse:"万军之耶和华曾如此说：“敌人必掳尽以色列剩下的民，如同摘净葡萄一样。你要像摘葡萄的人摘了又摘，回手放在筐子里。”"},
{volumn:        24,chapter:         6,versenumber:        10,verse:"现在我可以向谁说话作见证，使他们听呢？他们的耳朵未受割礼，不能听见。看哪，耶和华的话他们以为羞辱，不以为喜悦。"},
{volumn:        24,chapter:         6,versenumber:        11,verse:"因此我被耶和华的忿怒充满，难以含忍。“我要倾在街中的孩童和聚会的少年人身上，连夫带妻，并年老的与日子满足的都必被擒拿。"},
{volumn:        24,chapter:         6,versenumber:        12,verse:"他们的房屋、田地，和妻子都必转归别人；我要伸手攻击这地的居民。这是耶和华说的。"},
{volumn:        24,chapter:         6,versenumber:        13,verse:"因为他们从最小的到至大的都一味地贪婪，从先知到祭司都行事虚谎。"},
{volumn:        24,chapter:         6,versenumber:        14,verse:"他们轻轻忽忽地医治我百姓的损伤，说：‘平安了！平安了！’其实没有平安。"},
{volumn:        24,chapter:         6,versenumber:        15,verse:"他们行可憎的事知道惭愧吗？不然，他们毫不惭愧，也不知羞耻。因此，他们必在仆倒的人中仆倒；我向他们讨罪的时候，他们必致跌倒。这是耶和华说的。”"},
{volumn:        24,chapter:         6,versenumber:        16,verse:"耶和华如此说：“你们当站在路上察看，访问古道，哪是善道，便行在其间；这样，你们心里必得安息。他们却说：‘我们不行在其间。’"},
{volumn:        24,chapter:         6,versenumber:        17,verse:"我设立守望的人照管你们，说：‘要听角声。’他们却说：‘我们不听。’"},
{volumn:        24,chapter:         6,versenumber:        18,verse:"列国啊，因此你们当听！会众啊，要知道他们必遭遇的事。"},
{volumn:        24,chapter:         6,versenumber:        19,verse:"地啊，当听！我必使灾祸临到这百姓，就是他们意念所结的果子；因为他们不听从我的言语，至于我的训诲（或译：律法），他们也厌弃了。"},
{volumn:        24,chapter:         6,versenumber:        20,verse:"从示巴出的乳香，从远方出的菖蒲（或译：甘蔗）奉来给我有何益呢？你们的燔祭不蒙悦纳；你们的平安祭，我也不喜悦。”"},
{volumn:        24,chapter:         6,versenumber:        21,verse:"所以耶和华如此说：“我要将绊脚石放在这百姓前面。父亲和儿子要一同跌在其上；邻舍与朋友也都灭亡。”"},
{volumn:        24,chapter:         6,versenumber:        22,verse:"耶和华如此说：“看哪，有一种民从北方而来，并有一大国被激动，从地极来到。"},
{volumn:        24,chapter:         6,versenumber:        23,verse:"他们拿弓和枪，性情残忍，不施怜悯；他们的声音像海浪砰訇。锡安城（原文是女子）啊，他们骑马都摆队伍，如上战场的人要攻击你。”"},
{volumn:        24,chapter:         6,versenumber:        24,verse:"我们听见他们的风声，手就发软；痛苦将我们抓住，疼痛仿佛产难的妇人。"},
{volumn:        24,chapter:         6,versenumber:        25,verse:"你们不要往田野去，也不要行在路上，因四围有仇敌的刀剑和惊吓。"},
{volumn:        24,chapter:         6,versenumber:        26,verse:"我民（原文是民女）哪，应当腰束麻布，滚在灰中。你要悲伤，如丧独生子痛痛哭号，因为灭命的要忽然临到我们。"},
{volumn:        24,chapter:         6,versenumber:        27,verse:"“我使你在我民中为高台（或译：试验人的），为保障，使你知道试验他们的行动。"},
{volumn:        24,chapter:         6,versenumber:        28,verse:"他们都是极悖逆的，往来谗谤人。他们是铜是铁，都行坏事。"},
{volumn:        24,chapter:         6,versenumber:        29,verse:"风箱吹火，铅被烧毁；他们炼而又炼，终是徒然；因为恶劣的还未除掉。"},
{volumn:        24,chapter:         6,versenumber:        30,verse:"人必称他们为被弃的银渣，因为耶和华已经弃掉他们。”"},
{volumn:        24,chapter:         7,versenumber:         1,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:         7,versenumber:         2,verse:"“你当站在耶和华殿的门口，在那里宣传这话说：你们进这些门敬拜耶和华的一切犹大人，当听耶和华的话。"},
{volumn:        24,chapter:         7,versenumber:         3,verse:"万军之耶和华以色列的　神如此说：你们改正行动作为，我就使你们在这地方仍然居住。"},
{volumn:        24,chapter:         7,versenumber:         4,verse:"你们不要倚靠虚谎的话，说：‘这些是耶和华的殿，是耶和华的殿，是耶和华的殿！’"},
{volumn:        24,chapter:         7,versenumber:         5,verse:"“你们若实在改正行动作为，在人和邻舍中间诚然施行公平，"},
{volumn:        24,chapter:         7,versenumber:         6,verse:"不欺压寄居的和孤儿寡妇，在这地方不流无辜人的血，也不随从别神陷害自己，"},
{volumn:        24,chapter:         7,versenumber:         7,verse:"我就使你们在这地方仍然居住，就是我古时所赐给你们列祖的地，直到永远。"},
{volumn:        24,chapter:         7,versenumber:         8,verse:"“看哪，你们倚靠虚谎无益的话。"},
{volumn:        24,chapter:         7,versenumber:         9,verse:"你们偷盗，杀害，奸淫，起假誓，向巴力烧香，并随从素不认识的别神，"},
{volumn:        24,chapter:         7,versenumber:        10,verse:"且来到这称为我名下的殿，在我面前敬拜；又说：‘我们可以自由了。’你们这样的举动是要行那些可憎的事吗？"},
{volumn:        24,chapter:         7,versenumber:        11,verse:"这称为我名下的殿在你们眼中岂可看为贼窝吗？我都看见了。这是耶和华说的。"},
{volumn:        24,chapter:         7,versenumber:        12,verse:"你们且往示罗去，就是我先前立为我名的居所，察看我因这百姓以色列的罪恶向那地所行的如何。”"},
{volumn:        24,chapter:         7,versenumber:        13,verse:"耶和华说：“现在因你们行了这一切的事，我也从早起来警戒你们，你们却不听从；呼唤你们，你们却不答应。"},
{volumn:        24,chapter:         7,versenumber:        14,verse:"所以我要向这称为我名下、你们所倚靠的殿，与我所赐给你们和你们列祖的地施行，照我从前向示罗所行的一样。"},
{volumn:        24,chapter:         7,versenumber:        15,verse:"我必将你们从我眼前赶出，正如赶出你们的众弟兄，就是以法莲的一切后裔。”"},
{volumn:        24,chapter:         7,versenumber:        16,verse:"“所以，你不要为这百姓祈祷；不要为他们呼求祷告；也不要向我为他们祈求，因我不听允你。"},
{volumn:        24,chapter:         7,versenumber:        17,verse:"他们在犹大城邑中和耶路撒冷街上所行的，你没有看见吗？"},
{volumn:        24,chapter:         7,versenumber:        18,verse:"孩子捡柴，父亲烧火，妇女抟面做饼，献给天后，又向别神浇奠祭，惹我发怒。”"},
{volumn:        24,chapter:         7,versenumber:        19,verse:"耶和华说：“他们岂是惹我发怒呢？不是自己惹祸，以致脸上惭愧吗？”"},
{volumn:        24,chapter:         7,versenumber:        20,verse:"所以主耶和华如此说：“看哪，我必将我的怒气和忿怒倾在这地方的人和牲畜身上，并田野的树木和地里的出产上，必如火着起，不能熄灭。”"},
{volumn:        24,chapter:         7,versenumber:        21,verse:"万军之耶和华以色列的　神如此说：“你们将燔祭加在平安祭上，吃肉吧！"},
{volumn:        24,chapter:         7,versenumber:        22,verse:"因为我将你们列祖从埃及地领出来的那日，燔祭平安祭的事我并没有提说，也没有吩咐他们。"},
{volumn:        24,chapter:         7,versenumber:        23,verse:"我只吩咐他们这一件说：‘你们当听从我的话，我就作你们的　神，你们也作我的子民。你们行我所吩咐的一切道，就可以得福。’"},
{volumn:        24,chapter:         7,versenumber:        24,verse:"他们却不听从，不侧耳而听，竟随从自己的计谋和顽梗的恶心，向后不向前。"},
{volumn:        24,chapter:         7,versenumber:        25,verse:"自从你们列祖出埃及地的那日，直到今日，我差遣我的仆人众先知到你们那里去，每日从早起来差遣他们。"},
{volumn:        24,chapter:         7,versenumber:        26,verse:"你们却不听从，不侧耳而听，竟硬着颈项行恶，比你们列祖更甚。"},
{volumn:        24,chapter:         7,versenumber:        27,verse:"“你要将这一切的话告诉他们，他们却不听从；呼唤他们，他们却不答应。"},
{volumn:        24,chapter:         7,versenumber:        28,verse:"你要对他们说：‘这就是不听从耶和华他们　神的话、不受教训的国民；从他们的口中，诚实灭绝了。’”"},
{volumn:        24,chapter:         7,versenumber:        29,verse:"“耶路撒冷啊，要剪发抛弃，在净光的高处举哀；因为耶和华丢掉离弃了惹他忿怒的世代。”"},
{volumn:        24,chapter:         7,versenumber:        30,verse:"耶和华说：“犹大人行我眼中看为恶的事，将可憎之物设立在称为我名下的殿中，污秽这殿。"},
{volumn:        24,chapter:         7,versenumber:        31,verse:"他们在欣嫩子谷建筑陀斐特的邱坛，好在火中焚烧自己的儿女。这并不是我所吩咐的，也不是我心所起的意。”"},
{volumn:        24,chapter:         7,versenumber:        32,verse:"耶和华说：“因此，日子将到，这地方不再称为陀斐特和欣嫩子谷，反倒称为杀戮谷。因为要在陀斐特葬埋尸首，甚至无处可葬；"},
{volumn:        24,chapter:         7,versenumber:        33,verse:"并且这百姓的尸首必给空中的飞鸟和地上的野兽作食物，并无人哄赶。"},
{volumn:        24,chapter:         7,versenumber:        34,verse:"那时，我必使犹大城邑中和耶路撒冷街上，欢喜和快乐的声音，新郎和新妇的声音，都止息了，因为地必成为荒场。”"},
{volumn:        24,chapter:         8,versenumber:         1,verse:"耶和华说：“到那时，人必将犹大王的骸骨和他首领的骸骨、祭司的骸骨、先知的骸骨，并耶路撒冷居民的骸骨，都从坟墓中取出来，"},
{volumn:        24,chapter:         8,versenumber:         2,verse:"抛散在日头、月亮，和天上众星之下，就是他们从前所喜爱、所侍奉、所随从、所求问、所敬拜的。这些骸骨不再收殓，不再葬埋，必在地面上成为粪土；"},
{volumn:        24,chapter:         8,versenumber:         3,verse:"并且这恶族所剩下的民在我所赶他们到的各处，宁可拣死不拣生。这是万军之耶和华说的。”"},
{volumn:        24,chapter:         8,versenumber:         4,verse:"你要对他们说，耶和华如此说：“人跌倒，不再起来吗？人转去，不再转来吗？"},
{volumn:        24,chapter:         8,versenumber:         5,verse:"这耶路撒冷的民，为何恒久背道呢？他们守定诡诈，不肯回头。"},
{volumn:        24,chapter:         8,versenumber:         6,verse:"我留心听，听见他们说不正直的话。无人悔改恶行，说：‘我做的是什么呢？’他们各人转奔己路，如马直闯战场。"},
{volumn:        24,chapter:         8,versenumber:         7,verse:"空中的鹳鸟知道来去的定期；斑鸠燕子与白鹤也守候当来的时令；我的百姓却不知道耶和华的法则。"},
{volumn:        24,chapter:         8,versenumber:         8,verse:"“你们怎么说：‘我们有智慧，耶和华的律法在我们这里’？看哪，文士的假笔舞弄虚假。"},
{volumn:        24,chapter:         8,versenumber:         9,verse:"智慧人惭愧，惊惶，被擒拿；他们弃掉耶和华的话，心里还有什么智慧呢？"},
{volumn:        24,chapter:         8,versenumber:        10,verse:"所以我必将他们的妻子给别人，将他们的田地给别人为业；因为他们从最小的到至大的都一味地贪婪，从先知到祭司都行事虚谎。"},
{volumn:        24,chapter:         8,versenumber:        11,verse:"他们轻轻忽忽地医治我百姓的损伤，说：‘平安了！平安了！’其实没有平安。"},
{volumn:        24,chapter:         8,versenumber:        12,verse:"他们行可憎的事知道惭愧吗？不然，他们毫不惭愧，也不知羞耻。因此他们必在仆倒的人中仆倒；我向他们讨罪的时候，他们必致跌倒。”这是耶和华说的。"},
{volumn:        24,chapter:         8,versenumber:        13,verse:"耶和华说：“我必使他们全然灭绝；葡萄树上必没有葡萄，无花果树上必没有果子，叶子也必枯干。我所赐给他们的，必离开他们过去。”"},
{volumn:        24,chapter:         8,versenumber:        14,verse:"我们为何静坐不动呢？我们当聚集，进入坚固城，在那里静默不言；因为耶和华我们的　神使我们静默不言，又将苦胆水给我们喝，都因我们得罪了耶和华。"},
{volumn:        24,chapter:         8,versenumber:        15,verse:"我们指望平安，却得不着好处；指望痊愈的时候，不料，受了惊惶。"},
{volumn:        24,chapter:         8,versenumber:        16,verse:"听见从但那里敌人的马喷鼻气，他的壮马发嘶声，全地就都震动；因为他们来吞灭这地和其上所有的，吞灭这城与其中的居民。"},
{volumn:        24,chapter:         8,versenumber:        17,verse:"看哪，我必使毒蛇到你们中间，是不服法术的，必咬你们。这是耶和华说的。"},
{volumn:        24,chapter:         8,versenumber:        18,verse:"我有忧愁，愿能自慰；我心在我里面发昏。"},
{volumn:        24,chapter:         8,versenumber:        19,verse:"听啊，是我百姓的哀声从极远之地而来，说：“耶和华不在锡安吗？锡安的王不在其中吗？”耶和华说：“他们为什么以雕刻的偶像和外邦虚无的神惹我发怒呢？”"},
{volumn:        24,chapter:         8,versenumber:        20,verse:"麦秋已过，夏令已完，我们还未得救！"},
{volumn:        24,chapter:         8,versenumber:        21,verse:"先知说：“因我百姓的损伤，我也受了损伤。我哀痛，惊惶将我抓住。"},
{volumn:        24,chapter:         8,versenumber:        22,verse:"在基列岂没有乳香呢？在那里岂没有医生呢？我百姓为何不得痊愈呢？”"},
{volumn:        24,chapter:         9,versenumber:         1,verse:"但愿我的头为水，我的眼为泪的泉源，我好为我百姓（原文是民女；七节同）中被杀的人昼夜哭泣。"},
{volumn:        24,chapter:         9,versenumber:         2,verse:"惟愿我在旷野有行路人住宿之处，使我可以离开我的民出去；因他们都是行奸淫的，是行诡诈的一党。"},
{volumn:        24,chapter:         9,versenumber:         3,verse:"他们弯起舌头像弓一样，为要说谎话。他们在国中增长势力，不是为行诚实，乃是恶上加恶，并不认识我。这是耶和华说的。"},
{volumn:        24,chapter:         9,versenumber:         4,verse:"你们各人当谨防邻舍，不可信靠弟兄；因为弟兄尽行欺骗，邻舍都往来谗谤人。"},
{volumn:        24,chapter:         9,versenumber:         5,verse:"他们各人欺哄邻舍，不说真话；他们教舌头学习说谎，劳劳碌碌地作孽。"},
{volumn:        24,chapter:         9,versenumber:         6,verse:"你的住处在诡诈的人中；他们因行诡诈，不肯认识我。这是耶和华说的。"},
{volumn:        24,chapter:         9,versenumber:         7,verse:"所以万军之耶和华如此说：“看哪，我要将他们熔化熬炼；不然，我因我百姓的罪该怎样行呢？"},
{volumn:        24,chapter:         9,versenumber:         8,verse:"他们的舌头是毒箭，说话诡诈；人与邻舍口说和平话，心却谋害他。”"},
{volumn:        24,chapter:         9,versenumber:         9,verse:"耶和华说：“我岂不因这些事讨他们的罪呢？岂不报复这样的国民呢？"},
{volumn:        24,chapter:         9,versenumber:        10,verse:"“我要为山岭哭泣悲哀，为旷野的草场扬声哀号；因为都已干焦，甚至无人经过。人也听不见牲畜鸣叫，空中的飞鸟和地上的野兽都已逃去。"},
{volumn:        24,chapter:         9,versenumber:        11,verse:"我必使耶路撒冷变为乱堆，为野狗的住处，也必使犹大的城邑变为荒场，无人居住。”"},
{volumn:        24,chapter:         9,versenumber:        12,verse:"谁是智慧人，可以明白这事？耶和华的口向谁说过，使他可以传说？遍地为何灭亡，干焦好像旷野，甚至无人经过呢？"},
{volumn:        24,chapter:         9,versenumber:        13,verse:"耶和华说：“因为这百姓离弃我在他们面前所设立的律法，没有遵行，也没有听从我的话；"},
{volumn:        24,chapter:         9,versenumber:        14,verse:"只随从自己顽梗的心行事，照他们列祖所教训的随从众巴力。”"},
{volumn:        24,chapter:         9,versenumber:        15,verse:"所以万军之耶和华以色列的　神如此说：“看哪，我必将茵陈给这百姓吃，又将苦胆水给他们喝。"},
{volumn:        24,chapter:         9,versenumber:        16,verse:"我要把他们散在列邦中，就是他们和他们列祖素不认识的列邦。我也要使刀剑追杀他们，直到将他们灭尽。”"},
{volumn:        24,chapter:         9,versenumber:        17,verse:"万军之耶和华如此说：“你们应当思想，将善唱哀歌的妇女召来，又打发人召善哭的妇女来，"},
{volumn:        24,chapter:         9,versenumber:        18,verse:"叫她们速速为我们举哀，使我们眼泪汪汪，使我们的眼皮涌出水来。"},
{volumn:        24,chapter:         9,versenumber:        19,verse:"因为听见哀声出于锡安，说：‘我们怎样败落了！我们大大地惭愧！我们撇下地土；人也拆毁了我们的房屋。’"},
{volumn:        24,chapter:         9,versenumber:        20,verse:"妇女们哪，你们当听耶和华的话，领受他口中的言语；又当教导你们的儿女举哀，各人教导邻舍唱哀歌。"},
{volumn:        24,chapter:         9,versenumber:        21,verse:"因为死亡上来，进了我们的窗户，入了我们的宫殿；要从外边剪除孩童，从街上剪除少年人。"},
{volumn:        24,chapter:         9,versenumber:        22,verse:"你当说，耶和华如此说：‘人的尸首必倒在田野像粪土，又像收割的人遗落的一把禾稼，无人收取。’”"},
{volumn:        24,chapter:         9,versenumber:        23,verse:"耶和华如此说：“智慧人不要因他的智慧夸口，勇士不要因他的勇力夸口，财主不要因他的财物夸口。"},
{volumn:        24,chapter:         9,versenumber:        24,verse:"夸口的却因他有聪明，认识我是耶和华，又知道我喜悦在世上施行慈爱、公平，和公义，以此夸口。这是耶和华说的。”"},
{volumn:        24,chapter:         9,versenumber:        25,verse:"耶和华说：“看哪，日子将到，我要刑罚一切受过割礼、心却未受割礼的，"},
{volumn:        24,chapter:         9,versenumber:        26,verse:"就是埃及、犹大、以东、亚扪人、摩押人，和一切住在旷野剃周围头发的；因为列国人都没有受割礼，以色列人心中也没有受割礼。”"},
{volumn:        24,chapter:        10,versenumber:         1,verse:"以色列家啊，要听耶和华对你们所说的话。"},
{volumn:        24,chapter:        10,versenumber:         2,verse:"耶和华如此说：“你们不要效法列国的行为，也不要为天象惊惶，因列国为此事惊惶。"},
{volumn:        24,chapter:        10,versenumber:         3,verse:"众民的风俗是虚空的；他们在树林中用斧子砍伐一棵树，匠人用手工造成偶像。"},
{volumn:        24,chapter:        10,versenumber:         4,verse:"他们用金银妆饰它，用钉子和锤子钉稳，使它不动摇。"},
{volumn:        24,chapter:        10,versenumber:         5,verse:"它好像棕树，是旋成的，不能说话，不能行走，必须有人抬着。你们不要怕它；它不能降祸，也无力降福。”"},
{volumn:        24,chapter:        10,versenumber:         6,verse:"耶和华啊，没有能比你的！你本为大，有大能大力的名。"},
{volumn:        24,chapter:        10,versenumber:         7,verse:"万国的王啊，谁不敬畏你？敬畏你本是合宜的；因为在列国的智慧人中，虽有政权的尊荣，也不能比你。"},
{volumn:        24,chapter:        10,versenumber:         8,verse:"他们尽都是畜类，是愚昧的。偶像的训诲算什么呢？偶像不过是木头。"},
{volumn:        24,chapter:        10,versenumber:         9,verse:"有银子打成片，是从他施带来的，并有从乌法来的金子，都是匠人和银匠的手工，又有蓝色紫色料的衣服，都是巧匠的工作。"},
{volumn:        24,chapter:        10,versenumber:        10,verse:"惟耶和华是真　神，是活　神，是永远的王。他一发怒，大地震动；他一恼恨，列国都担当不起。"},
{volumn:        24,chapter:        10,versenumber:        11,verse:"你们要对他们如此说：“不是那创造天地的神，必从地上从天下被除灭！”"},
{volumn:        24,chapter:        10,versenumber:        12,verse:"耶和华用能力创造大地，用智慧建立世界，用聪明铺张穹苍。"},
{volumn:        24,chapter:        10,versenumber:        13,verse:"他一发声，空中便有多水激动；他使云雾从地极上腾；他造电随雨而闪，从他府库中带出风来。"},
{volumn:        24,chapter:        10,versenumber:        14,verse:"各人都成了畜类，毫无知识；各银匠都因他雕刻的偶像羞愧。他所铸的偶像本是虚假的，其中并无气息，"},
{volumn:        24,chapter:        10,versenumber:        15,verse:"都是虚无的，是迷惑人的工作；到追讨的时候必被除灭。"},
{volumn:        24,chapter:        10,versenumber:        16,verse:"雅各的份不像这些，因他是造作万有的主。以色列也是他产业的支派，万军之耶和华是他的名。"},
{volumn:        24,chapter:        10,versenumber:        17,verse:"受围困的人哪，当收拾你的财物，从国中带出去。"},
{volumn:        24,chapter:        10,versenumber:        18,verse:"因为耶和华如此说：“这时候，我必将此地的居民，好像用机弦甩出去，又必加害在他们身上，使他们觉悟。”"},
{volumn:        24,chapter:        10,versenumber:        19,verse:"民说：“祸哉！我受损伤；我的伤痕极其重大。”我却说：“这真是我的痛苦，必须忍受。"},
{volumn:        24,chapter:        10,versenumber:        20,verse:"我的帐棚毁坏；我的绳索折断。我的儿女离我出去，没有了。无人再支搭我的帐棚，挂起我的幔子。"},
{volumn:        24,chapter:        10,versenumber:        21,verse:"因为牧人都成为畜类，没有求问耶和华，所以不得顺利；他们的羊群也都分散。"},
{volumn:        24,chapter:        10,versenumber:        22,verse:"有风声！看哪，敌人来了！有大扰乱从北方出来，要使犹大城邑变为荒凉，成为野狗的住处。”"},
{volumn:        24,chapter:        10,versenumber:        23,verse:"耶和华啊，我晓得人的道路不由自己，行路的人也不能定自己的脚步。"},
{volumn:        24,chapter:        10,versenumber:        24,verse:"耶和华啊，求你从宽惩治我，不要在你的怒中惩治我，恐怕使我归于无有。"},
{volumn:        24,chapter:        10,versenumber:        25,verse:"愿你将忿怒倾在不认识你的列国中，和不求告你名的各族上；因为他们吞了雅各，不但吞了，而且灭绝，把他的住处变为荒场。"},
{volumn:        24,chapter:        11,versenumber:         1,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        11,versenumber:         2,verse:"“当听这约的话，告诉犹大人和耶路撒冷的居民，"},
{volumn:        24,chapter:        11,versenumber:         3,verse:"对他们说，耶和华以色列的　神如此说：不听从这约之话的人必受咒诅。"},
{volumn:        24,chapter:        11,versenumber:         4,verse:"这约是我将你们列祖从埃及地领出来、脱离铁炉的那日所吩咐他们的，说：‘你们要听从我的话，照我一切所吩咐的去行。’这样，你们就作我的子民，我也作你们的　神；"},
{volumn:        24,chapter:        11,versenumber:         5,verse:"我好坚定向你们列祖所起的誓，给他们流奶与蜜之地，正如今日一样。”我就回答说：“耶和华啊，阿们！”"},
{volumn:        24,chapter:        11,versenumber:         6,verse:"耶和华对我说：“你要在犹大城邑中和耶路撒冷街市上，宣告这一切话说：你们当听从遵行这约的话。"},
{volumn:        24,chapter:        11,versenumber:         7,verse:"因为我将你们列祖从埃及地领出来的那日，直到今日，都是从早起来，切切告诫他们说：‘你们当听从我的话。’"},
{volumn:        24,chapter:        11,versenumber:         8,verse:"他们却不听从，不侧耳而听，竟随从自己顽梗的恶心去行。所以我使这约中一切咒诅的话临到他们身上；这约是我吩咐他们行的，他们却不去行。”"},
{volumn:        24,chapter:        11,versenumber:         9,verse:"耶和华对我说：“在犹大人和耶路撒冷居民中有同谋背叛的事。"},
{volumn:        24,chapter:        11,versenumber:        10,verse:"他们转去效法他们的先祖，不肯听我的话，犯罪作孽，又随从别神，侍奉它。以色列家和犹大家背了我与他们列祖所立的约。”"},
{volumn:        24,chapter:        11,versenumber:        11,verse:"所以耶和华如此说：“我必使灾祸临到他们，是他们不能逃脱的。他们必向我哀求，我却不听。"},
{volumn:        24,chapter:        11,versenumber:        12,verse:"那时，犹大城邑的人和耶路撒冷的居民要去哀求他们烧香所供奉的神；只是遭难的时候，这些神毫不拯救他们。"},
{volumn:        24,chapter:        11,versenumber:        13,verse:"犹大啊，你神的数目与你城的数目相等；你为那可耻的巴力所筑烧香的坛也与耶路撒冷街道的数目相等。"},
{volumn:        24,chapter:        11,versenumber:        14,verse:"“所以你不要为这百姓祈祷，不要为他们呼求祷告；因为他们遭难向我哀求的时候，我必不应允。"},
{volumn:        24,chapter:        11,versenumber:        15,verse:"我所亲爱的，既行许多淫乱，圣肉也离了你，你在我殿中做什么呢？你作恶就喜乐。"},
{volumn:        24,chapter:        11,versenumber:        16,verse:"从前耶和华给你起名叫青橄榄树，又华美又结好果子；如今他用哄嚷之声，点火在其上，枝子也被折断。"},
{volumn:        24,chapter:        11,versenumber:        17,verse:"原来栽培你的万军之耶和华已经说，要降祸攻击你，是因以色列家和犹大家行恶，向巴力烧香，惹我发怒，是自作自受。”"},
{volumn:        24,chapter:        11,versenumber:        18,verse:"耶和华指示我，我就知道；你将他们所行的给我指明。"},
{volumn:        24,chapter:        11,versenumber:        19,verse:"我却像柔顺的羊羔被牵到宰杀之地；我并不知道他们设计谋害我，说：“我们把树连果子都灭了吧！将他从活人之地剪除，使他的名不再被纪念。”"},
{volumn:        24,chapter:        11,versenumber:        20,verse:"按公义判断、察验人肺腑心肠的万军之耶和华啊，我却要见你在他们身上报仇，因我将我的案件向你禀明了。"},
{volumn:        24,chapter:        11,versenumber:        21,verse:"所以，耶和华论到寻索你命的亚拿突人如此说：“他们说：‘你不要奉耶和华的名说预言，免得你死在我们手中。’”"},
{volumn:        24,chapter:        11,versenumber:        22,verse:"所以万军之耶和华如此说：“看哪，我必刑罚他们；他们的少年人必被刀剑杀死，他们的儿女必因饥荒灭亡，"},
{volumn:        24,chapter:        11,versenumber:        23,verse:"并且没有余剩的人留给他们；因为在追讨之年，我必使灾祸临到亚拿突人。”"},
{volumn:        24,chapter:        12,versenumber:         1,verse:"耶和华啊，我与你争辩的时候，你显为义；但有一件，我还要与你理论：恶人的道路为何亨通呢？大行诡诈的为何得安逸呢？"},
{volumn:        24,chapter:        12,versenumber:         2,verse:"你栽培了他们，他们也扎了根，长大，而且结果。他们的口是与你相近，心却与你远离。"},
{volumn:        24,chapter:        12,versenumber:         3,verse:"耶和华啊，你晓得我，看见我，察验我向你是怎样的心。求你将他们拉出来，好像将宰的羊，叫他们等候杀戮的日子。"},
{volumn:        24,chapter:        12,versenumber:         4,verse:"这地悲哀，通国的青草枯干，要到几时呢？因其上居民的恶行，牲畜和飞鸟都灭绝了。他们曾说：“他看不见我们的结局。”"},
{volumn:        24,chapter:        12,versenumber:         5,verse:"耶和华说：“你若与步行的人同跑，尚且觉累，怎能与马赛跑呢？你在平安之地，虽然安稳，在约旦河边的丛林要怎样行呢？"},
{volumn:        24,chapter:        12,versenumber:         6,verse:"因为连你弟兄和你父家都用奸诈待你。他们也在你后边大声喊叫，虽向你说好话，你也不要信他们。”"},
{volumn:        24,chapter:        12,versenumber:         7,verse:"“我离了我的殿宇，撇弃我的产业，将我心里所亲爱的交在她仇敌的手中。"},
{volumn:        24,chapter:        12,versenumber:         8,verse:"我的产业向我如林中的狮子，她发声攻击我，因此我恨恶她。"},
{volumn:        24,chapter:        12,versenumber:         9,verse:"我的产业向我岂如斑点的鸷鸟呢？鸷鸟岂在她四围攻击她呢？你们要去聚集田野的百兽，带来吞吃吧！"},
{volumn:        24,chapter:        12,versenumber:        10,verse:"许多牧人毁坏我的葡萄园，践踏我的份，使我美好的份变为荒凉的旷野。"},
{volumn:        24,chapter:        12,versenumber:        11,verse:"他们使地荒凉；地既荒凉，便向我悲哀。全地荒凉，因无人介意。"},
{volumn:        24,chapter:        12,versenumber:        12,verse:"灭命的都来到旷野中一切净光的高处；耶和华的刀从地这边直到地那边尽行杀灭。凡有血气的都不得平安。"},
{volumn:        24,chapter:        12,versenumber:        13,verse:"他们种的是麦子，收的是荆棘；劳劳苦苦却毫无益处。因耶和华的烈怒，你们必为自己的土产羞愧。”"},
{volumn:        24,chapter:        12,versenumber:        14,verse:"耶和华如此说：“一切恶邻，就是占据我使百姓以色列所承受产业的，我要将他们拔出本地，又要将犹大家从他们中间拔出来。"},
{volumn:        24,chapter:        12,versenumber:        15,verse:"我拔出他们以后，我必转过来怜悯他们，把他们再带回来，各归本业，各归故土。"},
{volumn:        24,chapter:        12,versenumber:        16,verse:"他们若殷勤学习我百姓的道，指着我的名起誓说：‘我指着永生的耶和华起誓’，正如他们从前教我百姓指着巴力起誓，他们就必建立在我百姓中间。"},
{volumn:        24,chapter:        12,versenumber:        17,verse:"他们若是不听，我必拔出那国，拔出而且毁灭。这是耶和华说的。”"},
{volumn:        24,chapter:        13,versenumber:         1,verse:"耶和华对我如此说：“你去买一根麻布带子束腰，不可放在水中。”"},
{volumn:        24,chapter:        13,versenumber:         2,verse:"我就照着耶和华的话，买了一根带子束腰。"},
{volumn:        24,chapter:        13,versenumber:         3,verse:"耶和华的话第二次临到我说："},
{volumn:        24,chapter:        13,versenumber:         4,verse:"“要拿着你所买的腰带，就是你腰上的带子，起来往幼发拉底河去，将腰带藏在那里的磐石穴中。”"},
{volumn:        24,chapter:        13,versenumber:         5,verse:"我就去，照着耶和华所吩咐我的，将腰带藏在幼发拉底河边。"},
{volumn:        24,chapter:        13,versenumber:         6,verse:"过了多日，耶和华对我说：“你起来往幼发拉底河去，将我吩咐你藏在那里的腰带取出来。”"},
{volumn:        24,chapter:        13,versenumber:         7,verse:"我就往幼发拉底河去，将腰带从我所藏的地方刨出来，见腰带已经变坏，毫无用了。"},
{volumn:        24,chapter:        13,versenumber:         8,verse:"耶和华的话临到我说："},
{volumn:        24,chapter:        13,versenumber:         9,verse:"“耶和华如此说：我必照样败坏犹大的骄傲和耶路撒冷的大骄傲。"},
{volumn:        24,chapter:        13,versenumber:        10,verse:"这恶民不肯听我的话，按自己顽梗的心而行，随从别神，侍奉敬拜，他们也必像这腰带变为无用。”"},
{volumn:        24,chapter:        13,versenumber:        11,verse:"耶和华说：“腰带怎样紧贴人腰，照样，我也使以色列全家和犹大全家紧贴我，好叫他们属我为子民，使我得名声，得颂赞，得荣耀；他们却不肯听。”"},
{volumn:        24,chapter:        13,versenumber:        12,verse:"“所以你要对他们说：‘耶和华以色列的　神如此说：各坛都要盛满了酒。’他们必对你说：‘我们岂不确知各坛都要盛满了酒呢？’"},
{volumn:        24,chapter:        13,versenumber:        13,verse:"你就要对他们说：‘耶和华如此说：我必使这地的一切居民，就是坐大卫宝座的君王和祭司，与先知，并耶路撒冷的一切居民，都酩酊大醉。"},
{volumn:        24,chapter:        13,versenumber:        14,verse:"耶和华说：我要使他们彼此相碰，就是父与子彼此相碰；我必不可怜，不顾惜，不怜悯，以致灭绝他们。’”"},
{volumn:        24,chapter:        13,versenumber:        15,verse:"你们当听，当侧耳而听。不要骄傲，因为耶和华已经说了。"},
{volumn:        24,chapter:        13,versenumber:        16,verse:"耶和华你们的　神未使黑暗来到，你们的脚未在昏暗山上绊跌之先，当将荣耀归给他；免得你们盼望光明，他使光明变为死荫，成为幽暗。"},
{volumn:        24,chapter:        13,versenumber:        17,verse:"你们若不听这话，我必因你们的骄傲在暗地哭泣；我眼必痛哭流泪，因为耶和华的群众被掳去了。"},
{volumn:        24,chapter:        13,versenumber:        18,verse:"“你要对君王和太后说：你们当自卑，坐在下边；因你们的头巾，就是你们的华冠，已经脱落了。"},
{volumn:        24,chapter:        13,versenumber:        19,verse:"南方的城尽都关闭，无人开放；犹大全被掳掠，且掳掠净尽。"},
{volumn:        24,chapter:        13,versenumber:        20,verse:"你们要举目观看从北方来的人。先前赐给你的群众，就是你佳美的群众，如今在哪里呢？"},
{volumn:        24,chapter:        13,versenumber:        21,verse:"耶和华立你自己所交的朋友为首，辖制你，那时你还有什么话说呢？痛苦岂不将你抓住像产难的妇人吗？"},
{volumn:        24,chapter:        13,versenumber:        22,verse:"你若心里说：这一切事为何临到我呢？你的衣襟揭起，你的脚跟受伤，是因你的罪孽甚多。"},
{volumn:        24,chapter:        13,versenumber:        23,verse:"古实人岂能改变皮肤呢？豹岂能改变斑点呢？若能，你们这习惯行恶的便能行善了。"},
{volumn:        24,chapter:        13,versenumber:        24,verse:"所以我必用旷野的风吹散他们，像吹过的碎秸一样。"},
{volumn:        24,chapter:        13,versenumber:        25,verse:"耶和华说：这是你所当得的，是我量给你的份；因为你忘记我，倚靠虚假（或译：偶像）。"},
{volumn:        24,chapter:        13,versenumber:        26,verse:"所以我要揭起你的衣襟，蒙在你脸上，显出你的丑陋。"},
{volumn:        24,chapter:        13,versenumber:        27,verse:"你那些可憎恶之事，就是在田野的山上行奸淫，发嘶声，作淫乱的事，我都看见了。耶路撒冷啊，你有祸了！你不肯洁净，还要到几时呢？”"},
{volumn:        24,chapter:        14,versenumber:         1,verse:"耶和华论到干旱之灾的话临到耶利米："},
{volumn:        24,chapter:        14,versenumber:         2,verse:"“犹大悲哀，城门衰败。众人披上黑衣坐在地上；耶路撒冷的哀声上达。"},
{volumn:        24,chapter:        14,versenumber:         3,verse:"他们的贵胄打发家僮打水；他们来到水池，见没有水，就拿着空器皿，蒙羞惭愧，抱头而回。"},
{volumn:        24,chapter:        14,versenumber:         4,verse:"耕地的也蒙羞抱头；因为无雨降在地上，地都干裂。"},
{volumn:        24,chapter:        14,versenumber:         5,verse:"田野的母鹿生下小鹿，就撇弃，因为无草。"},
{volumn:        24,chapter:        14,versenumber:         6,verse:"野驴站在净光的高处，喘气好像野狗；因为无草，眼目失明。”"},
{volumn:        24,chapter:        14,versenumber:         7,verse:"耶和华啊，我们的罪孽虽然作见证告我们，还求你为你名的缘故行事。我们本是多次背道，得罪了你。"},
{volumn:        24,chapter:        14,versenumber:         8,verse:"以色列所盼望、在患难时作他救主的啊，你为何在这地像寄居的，又像行路的只住一宵呢？"},
{volumn:        24,chapter:        14,versenumber:         9,verse:"你为何像受惊的人，像不能救人的勇士呢？耶和华啊，你仍在我们中间；我们也称为你名下的人，求你不要离开我们。"},
{volumn:        24,chapter:        14,versenumber:        10,verse:"耶和华对这百姓如此说：“这百姓喜爱妄行（原文是飘流），不禁止脚步，所以耶和华不悦纳他们。现今要记念他们的罪孽，追讨他们的罪恶。”"},
{volumn:        24,chapter:        14,versenumber:        11,verse:"耶和华又对我说：“不要为这百姓祈祷求好处。"},
{volumn:        24,chapter:        14,versenumber:        12,verse:"他们禁食的时候，我不听他们的呼求；他们献燔祭和素祭，我也不悦纳；我却要用刀剑、饥荒、瘟疫灭绝他们。”"},
{volumn:        24,chapter:        14,versenumber:        13,verse:"我就说：“唉！主耶和华啊，那些先知常对他们说：‘你们必不看见刀剑，也不遭遇饥荒；耶和华要在这地方赐你们长久的平安。’”"},
{volumn:        24,chapter:        14,versenumber:        14,verse:"耶和华对我说：“那些先知托我的名说假预言，我并没有打发他们，没有吩咐他们，也没有对他们说话；他们向你们预言的，乃是虚假的异象和占卜，并虚无的事，以及本心的诡诈。"},
{volumn:        24,chapter:        14,versenumber:        15,verse:"所以耶和华如此说：论到托我名说预言的那些先知，我并没有打发他们；他们还说这地不能有刀剑饥荒，其实那些先知必被刀剑饥荒灭绝。"},
{volumn:        24,chapter:        14,versenumber:        16,verse:"听他们说预言的百姓必因饥荒刀剑抛在耶路撒冷的街道上，无人葬埋。他们连妻子带儿女，都是如此。我必将他们的恶倒在他们身上（或译：我必使他们罪恶的报应临到他们身上）。”"},
{volumn:        24,chapter:        14,versenumber:        17,verse:"你要将这话对他们说：‘愿我眼泪汪汪，昼夜不息，因为我百姓（原文是民的处女）受了裂口破坏的大伤。"},
{volumn:        24,chapter:        14,versenumber:        18,verse:"我若出往田间，就见有被刀杀的；我若进入城内，就见有因饥荒患病的；连先知带祭司在国中往来，也是毫无知识（或译：不知怎样才好）。’”"},
{volumn:        24,chapter:        14,versenumber:        19,verse:"你全然弃掉犹大吗？你心厌恶锡安吗？为何击打我们，以致无法医治呢？我们指望平安，却得不着好处；指望痊愈，不料，受了惊惶。"},
{volumn:        24,chapter:        14,versenumber:        20,verse:"耶和华啊，我们承认自己的罪恶，和我们列祖的罪孽，因我们得罪了你。"},
{volumn:        24,chapter:        14,versenumber:        21,verse:"求你为你名的缘故，不厌恶我们，不辱没你荣耀的宝座。求你追念，不要背了与我们所立的约。"},
{volumn:        24,chapter:        14,versenumber:        22,verse:"外邦人虚无的神中有能降雨的吗？天能自降甘霖吗？耶和华我们的　神啊，能如此的不是你吗？所以，我们仍要等候你，因为这一切都是你所造的。"},
{volumn:        24,chapter:        15,versenumber:         1,verse:"耶和华对我说：“虽有摩西和撒母耳站在我面前代求，我的心也不顾惜这百姓。你将他们从我眼前赶出，叫他们去吧！"},
{volumn:        24,chapter:        15,versenumber:         2,verse:"他们问你说：‘我们往哪里去呢？’你便告诉他们：‘耶和华如此说：定为死亡的，必致死亡；定为刀杀的，必交刀杀；定为饥荒的，必遭饥荒；定为掳掠的，必被掳掠。’”"},
{volumn:        24,chapter:        15,versenumber:         3,verse:"耶和华说：“我命定四样害他们，就是刀剑杀戮、狗类撕裂、空中的飞鸟，和地上的野兽吞吃毁灭；"},
{volumn:        24,chapter:        15,versenumber:         4,verse:"又必使他们在天下万国中抛来抛去，都因犹大王希西家的儿子玛拿西在耶路撒冷所行的事。”"},
{volumn:        24,chapter:        15,versenumber:         5,verse:"耶路撒冷啊，谁可怜你呢？谁为你悲伤呢？谁转身问你的安呢？"},
{volumn:        24,chapter:        15,versenumber:         6,verse:"耶和华说：“你弃绝了我，转身退后；因此我伸手攻击你，毁坏你。我后悔甚不耐烦。"},
{volumn:        24,chapter:        15,versenumber:         7,verse:"我在境内各城门口（或译：我在这地边界的关口），用簸箕簸了我的百姓，使他们丧掉儿女。我毁灭他们，他们仍不转离所行的道。"},
{volumn:        24,chapter:        15,versenumber:         8,verse:"他们的寡妇在我面前比海沙更多；我使灭命的午间来，攻击少年人的母亲，使痛苦惊吓忽然临到她身上。"},
{volumn:        24,chapter:        15,versenumber:         9,verse:"生过七子的妇人力衰气绝；尚在白昼，日头忽落；她抱愧蒙羞。其余的人，我必在他们敌人跟前，交与刀剑。这是耶和华说的。”"},
{volumn:        24,chapter:        15,versenumber:        10,verse:"我的母亲哪，我有祸了！因你生我作为遍地相争相竞的人。我素来没有借贷与人，人也没有借贷与我，人人却都咒骂我。"},
{volumn:        24,chapter:        15,versenumber:        11,verse:"耶和华说：“我必要坚固你，使你得好处。灾祸苦难临到的时候，我必要使仇敌央求你。（"},
{volumn:        24,chapter:        15,versenumber:        12,verse:"人岂能将铜与铁，就是北方的铁折断呢？）"},
{volumn:        24,chapter:        15,versenumber:        13,verse:"“我必因你在四境之内所犯的一切罪，把你的货物财宝当掠物，白白地交给仇敌。"},
{volumn:        24,chapter:        15,versenumber:        14,verse:"我也必使仇敌带这掠物到你所不认识的地去，因我怒中起的火要将你们焚烧。”"},
{volumn:        24,chapter:        15,versenumber:        15,verse:"耶和华啊，你是知道的；求你记念我，眷顾我，向逼迫我的人为我报仇；不要向他们忍怒取我的命，要知道我为你的缘故受了凌辱。"},
{volumn:        24,chapter:        15,versenumber:        16,verse:"耶和华万军之　神啊，我得着你的言语就当食物吃了；你的言语是我心中的欢喜快乐，因我是称为你名下的人。"},
{volumn:        24,chapter:        15,versenumber:        17,verse:"我没有坐在宴乐人的会中，也没有欢乐；我因你的感动（原文是手）独自静坐，因你使我满心愤恨。"},
{volumn:        24,chapter:        15,versenumber:        18,verse:"我的痛苦为何长久不止呢？我的伤痕为何无法医治、不能痊愈呢？难道你待我有诡诈，像流干的河道吗？"},
{volumn:        24,chapter:        15,versenumber:        19,verse:"耶和华如此说：“你若归回，我就将你再带来，使你站在我面前；你若将宝贵的和下贱的分别出来，你就可以当作我的口。他们必归向你，你却不可归向他们。"},
{volumn:        24,chapter:        15,versenumber:        20,verse:"我必使你向这百姓成为坚固的铜墙；他们必攻击你，却不能胜你；因我与你同在，要拯救你，搭救你。这是耶和华说的。"},
{volumn:        24,chapter:        15,versenumber:        21,verse:"我必搭救你脱离恶人的手，救赎你脱离强暴人的手。”"},
{volumn:        24,chapter:        16,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        24,chapter:        16,versenumber:         2,verse:"“你在这地方不可娶妻，生儿养女。"},
{volumn:        24,chapter:        16,versenumber:         3,verse:"因为论到在这地方所生的儿女，又论到在这国中生养他们的父母，耶和华如此说："},
{volumn:        24,chapter:        16,versenumber:         4,verse:"他们必死得甚苦，无人哀哭，必不得葬埋，必在地上像粪土，必被刀剑和饥荒灭绝；他们的尸首必给空中的飞鸟和地上的野兽作食物。”"},
{volumn:        24,chapter:        16,versenumber:         5,verse:"耶和华如此说：“不要进入丧家，不要去哀哭，也不要为他们悲伤，因我已将我的平安、慈爱、怜悯从这百姓夺去了。这是耶和华说的。"},
{volumn:        24,chapter:        16,versenumber:         6,verse:"连大带小，都必在这地死亡，不得葬埋。人必不为他们哀哭，不用刀划身，也不使头光秃。"},
{volumn:        24,chapter:        16,versenumber:         7,verse:"他们有丧事，人必不为他们擘饼，因死人安慰他们；他们丧父丧母，人也不给他们一杯酒安慰他们。"},
{volumn:        24,chapter:        16,versenumber:         8,verse:"你不可进入宴乐的家，与他们同坐吃喝。"},
{volumn:        24,chapter:        16,versenumber:         9,verse:"因为万军之耶和华以色列的　神如此说：你们还活着的日子在你们眼前，我必使欢喜和快乐的声音，新郎和新妇的声音，从这地方止息了。"},
{volumn:        24,chapter:        16,versenumber:        10,verse:"“你将这一切的话指示这百姓。他们问你说：‘耶和华为什么说，要降这大灾祸攻击我们呢？我们有什么罪孽呢？我们向耶和华我们的　神犯了什么罪呢？’"},
{volumn:        24,chapter:        16,versenumber:        11,verse:"你就对他们说：‘耶和华说：因为你们列祖离弃我，随从别神，侍奉敬拜，不遵守我的律法，"},
{volumn:        24,chapter:        16,versenumber:        12,verse:"而且你们行恶比你们列祖更甚；因为各人随从自己顽梗的恶心行事，甚至不听从我，"},
{volumn:        24,chapter:        16,versenumber:        13,verse:"所以我必将你们从这地赶出，直赶到你们和你们列祖素不认识的地。你们在那里必昼夜侍奉别神，因为我必不向你们施恩。’”"},
{volumn:        24,chapter:        16,versenumber:        14,verse:"耶和华说：“日子将到，人必不再指着那领以色列人从埃及地上来之永生的耶和华起誓，"},
{volumn:        24,chapter:        16,versenumber:        15,verse:"却要指着那领以色列人从北方之地，并赶他们到的各国上来之永生的耶和华起誓，并且我要领他们再入我从前赐给他们列祖之地。”"},
{volumn:        24,chapter:        16,versenumber:        16,verse:"耶和华说：“我要召许多打鱼的把以色列人打上来，然后我要召许多打猎的，从各山上、各冈上、各石穴中猎取他们。"},
{volumn:        24,chapter:        16,versenumber:        17,verse:"因我的眼目察看他们的一切行为；他们不能在我面前遮掩，他们的罪孽也不能在我眼前隐藏。"},
{volumn:        24,chapter:        16,versenumber:        18,verse:"我先要加倍报应他们的罪孽和罪恶；因为他们用可憎之尸玷污我的地土，又用可厌之物充满我的产业。”"},
{volumn:        24,chapter:        16,versenumber:        19,verse:"耶和华啊，你是我的力量，是我的保障；在苦难之日是我的避难所。列国人必从地极来到你这里，说：“我们列祖所承受的，不过是虚假，是虚空无益之物。"},
{volumn:        24,chapter:        16,versenumber:        20,verse:"人岂可为自己制造神呢？其实这不是神。”"},
{volumn:        24,chapter:        16,versenumber:        21,verse:"耶和华说：“我要使他们知道，就是这一次使他们知道我的手和我的能力。他们就知道我的名是耶和华了。”"},
{volumn:        24,chapter:        17,versenumber:         1,verse:"“犹大的罪是用铁笔、用金钢钻记录的，铭刻在他们的心版上和坛角上。"},
{volumn:        24,chapter:        17,versenumber:         2,verse:"他们的儿女记念他们高冈上、青翠树旁的坛和木偶。"},
{volumn:        24,chapter:        17,versenumber:         3,verse:"我田野的山哪，我必因你在四境之内所犯的罪，把你的货物、财宝，并邱坛当掠物交给仇敌；"},
{volumn:        24,chapter:        17,versenumber:         4,verse:"并且你因自己的罪必失去我所赐给你的产业。我也必使你在你所不认识的地上服侍你的仇敌；因为你使我怒中起火，直烧到永远。”"},
{volumn:        24,chapter:        17,versenumber:         5,verse:"耶和华如此说：倚靠人血肉的膀臂，心中离弃耶和华的，那人有祸了！"},
{volumn:        24,chapter:        17,versenumber:         6,verse:"因他必像沙漠的杜松，不见福乐来到，却要住旷野干旱之处，无人居住的碱地。"},
{volumn:        24,chapter:        17,versenumber:         7,verse:"倚靠耶和华、以耶和华为可靠的，那人有福了！"},
{volumn:        24,chapter:        17,versenumber:         8,verse:"他必像树栽于水旁，在河边扎根，炎热来到，并不惧怕，叶子仍必青翠，在干旱之年毫无挂虑，而且结果不止。"},
{volumn:        24,chapter:        17,versenumber:         9,verse:"人心比万物都诡诈，坏到极处，谁能识透呢？"},
{volumn:        24,chapter:        17,versenumber:        10,verse:"我耶和华是鉴察人心、试验人肺腑的，要照各人所行的和他作事的结果报应他。"},
{volumn:        24,chapter:        17,versenumber:        11,verse:"那不按正道得财的，好像鹧鸪抱不是自己下的蛋；到了中年，那财都必离开他，他终久成为愚顽人。"},
{volumn:        24,chapter:        17,versenumber:        12,verse:"我们的圣所是荣耀的宝座，从太初安置在高处。"},
{volumn:        24,chapter:        17,versenumber:        13,verse:"耶和华—以色列的盼望啊，凡离弃你的必致蒙羞。耶和华说：“离开我的，他们的名字必写在土里，因为他们离弃我这活水的泉源。”"},
{volumn:        24,chapter:        17,versenumber:        14,verse:"耶和华啊，求你医治我，我便痊愈，拯救我，我便得救；因你是我所赞美的。"},
{volumn:        24,chapter:        17,versenumber:        15,verse:"他们对我说：“耶和华的话在哪里呢？叫这话应验吧！”"},
{volumn:        24,chapter:        17,versenumber:        16,verse:"至于我，那跟从你作牧人的职分，我并没有急忙离弃，也没有想那灾殃的日子；这是你知道的。我口中所出的言语都在你面前。"},
{volumn:        24,chapter:        17,versenumber:        17,verse:"不要使我因你惊恐；当灾祸的日子，你是我的避难所。"},
{volumn:        24,chapter:        17,versenumber:        18,verse:"愿那些逼迫我的蒙羞，却不要使我蒙羞；使他们惊惶，却不要使我惊惶；使灾祸的日子临到他们，以加倍的毁坏毁坏他们。"},
{volumn:        24,chapter:        17,versenumber:        19,verse:"耶和华对我如此说：“你去站在平民的门口，就是犹大君王出入的门，又站在耶路撒冷的各门口，"},
{volumn:        24,chapter:        17,versenumber:        20,verse:"对他们说：‘你们这犹大君王和犹大众人，并耶路撒冷的一切居民，凡从这些门进入的都当听耶和华的话。"},
{volumn:        24,chapter:        17,versenumber:        21,verse:"耶和华如此说：你们要谨慎，不要在安息日担什么担子进入耶路撒冷的各门；"},
{volumn:        24,chapter:        17,versenumber:        22,verse:"也不要在安息日从家中担出担子去。无论何工都不可做，只要以安息日为圣日，正如我所吩咐你们列祖的。’"},
{volumn:        24,chapter:        17,versenumber:        23,verse:"他们却不听从，不侧耳而听，竟硬着颈项不听，不受教训。”"},
{volumn:        24,chapter:        17,versenumber:        24,verse:"耶和华说：“你们若留意听从我，在安息日不担什么担子进入这城的各门，只以安息日为圣日，在那日无论何工都不做；"},
{volumn:        24,chapter:        17,versenumber:        25,verse:"那时就有坐大卫宝座的君王和首领，他们与犹大人，并耶路撒冷的居民，或坐车，或骑马，进入这城的各门，而且这城必存到永远。"},
{volumn:        24,chapter:        17,versenumber:        26,verse:"也必有人从犹大城邑和耶路撒冷四围的各处，从便雅悯地、高原、山地，并南地而来，都带燔祭、平安祭、素祭，和乳香，并感谢祭，到耶和华的殿去。"},
{volumn:        24,chapter:        17,versenumber:        27,verse:"你们若不听从我，不以安息日为圣日，仍在安息日担担子，进入耶路撒冷的各门，我必在各门中点火；这火也必烧毁耶路撒冷的宫殿，不能熄灭。”"},
{volumn:        24,chapter:        18,versenumber:         1,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        18,versenumber:         2,verse:"“你起来，下到窑匠的家里去，我在那里要使你听我的话。”"},
{volumn:        24,chapter:        18,versenumber:         3,verse:"我就下到窑匠的家里去，正遇他转轮做器皿。"},
{volumn:        24,chapter:        18,versenumber:         4,verse:"窑匠用泥做的器皿，在他手中做坏了，他又用这泥另做别的器皿；窑匠看怎样好，就怎样做。"},
{volumn:        24,chapter:        18,versenumber:         5,verse:"耶和华的话就临到我说："},
{volumn:        24,chapter:        18,versenumber:         6,verse:"“耶和华说：以色列家啊，我待你们，岂不能照这窑匠弄泥吗？以色列家啊，泥在窑匠的手中怎样，你们在我的手中也怎样。"},
{volumn:        24,chapter:        18,versenumber:         7,verse:"我何时论到一邦或一国说，要拔出、拆毁、毁坏；"},
{volumn:        24,chapter:        18,versenumber:         8,verse:"我所说的那一邦，若是转意离开他们的恶，我就必后悔，不将我想要施行的灾祸降与他们。"},
{volumn:        24,chapter:        18,versenumber:         9,verse:"我何时论到一邦或一国说，要建立、栽植；"},
{volumn:        24,chapter:        18,versenumber:        10,verse:"他们若行我眼中看为恶的事，不听从我的话，我就必后悔，不将我所说的福气赐给他们。"},
{volumn:        24,chapter:        18,versenumber:        11,verse:"现在你要对犹大人和耶路撒冷的居民说：‘耶和华如此说：我造出灾祸攻击你们，定意刑罚你们。你们各人当回头离开所行的恶道，改正你们的行动作为。’"},
{volumn:        24,chapter:        18,versenumber:        12,verse:"“他们却说：‘这是枉然。我们要照自己的计谋去行，各人随自己顽梗的恶心做事。’”"},
{volumn:        24,chapter:        18,versenumber:        13,verse:"“所以，耶和华如此说：你们且往各国访问，有谁听见这样的事？以色列民（原文是处女）行了一件极可憎恶的事。"},
{volumn:        24,chapter:        18,versenumber:        14,verse:"黎巴嫩的雪从田野的磐石上岂能断绝呢？从远处流下的凉水岂能干涸呢？"},
{volumn:        24,chapter:        18,versenumber:        15,verse:"我的百姓竟忘记我，向假神烧香，使他们在所行的路上，在古道上绊跌，使他们行没有修筑的斜路，"},
{volumn:        24,chapter:        18,versenumber:        16,verse:"以致他们的地令人惊骇，常常嗤笑；凡经过这地的必惊骇摇头。"},
{volumn:        24,chapter:        18,versenumber:        17,verse:"我必在仇敌面前分散他们，好像用东风吹散一样。遭难的日子，我必以背向他们，不以面向他们。”"},
{volumn:        24,chapter:        18,versenumber:        18,verse:"他们就说：“来吧！我们可以设计谋害耶利米；因为我们有祭司讲律法，智慧人设谋略，先知说预言，都不能断绝。来吧！我们可以用舌头击打他，不要理会他的一切话。”"},
{volumn:        24,chapter:        18,versenumber:        19,verse:"耶和华啊，求你理会我，且听那些与我争竞之人的话。"},
{volumn:        24,chapter:        18,versenumber:        20,verse:"岂可以恶报善呢？他们竟挖坑要害我的性命！求你记念我怎样站在你面前为他们代求，要使你的忿怒向他们转消。"},
{volumn:        24,chapter:        18,versenumber:        21,verse:"故此，愿你将他们的儿女交与饥荒和刀剑；愿他们的妻无子，且作寡妇；又愿他们的男人被死亡所灭，他们的少年人在阵上被刀击杀。"},
{volumn:        24,chapter:        18,versenumber:        22,verse:"你使敌军忽然临到他们的时候，愿人听见哀声从他们的屋内发出；因他们挖坑要捉拿我，暗设网罗要绊我的脚。"},
{volumn:        24,chapter:        18,versenumber:        23,verse:"耶和华啊，他们要杀我的那一切计谋，你都知道。不要赦免他们的罪孽，也不要从你面前涂抹他们的罪恶，要叫他们在你面前跌倒；愿你发怒的时候罚办他们。"},
{volumn:        24,chapter:        19,versenumber:         1,verse:"耶和华如此说：“你去买窑匠的瓦瓶，又带百姓中的长老和祭司中的长老，"},
{volumn:        24,chapter:        19,versenumber:         2,verse:"出去到欣嫩子谷、哈珥西（就是瓦片的意思）的门口那里，宣告我所吩咐你的话，"},
{volumn:        24,chapter:        19,versenumber:         3,verse:"说：‘犹大君王和耶路撒冷的居民哪，当听耶和华的话。万军之耶和华以色列的　神如此说：我必使灾祸临到这地方，凡听见的人都必耳鸣；"},
{volumn:        24,chapter:        19,versenumber:         4,verse:"因为他们和他们列祖，并犹大君王离弃我，将这地方看为平常，在这里向素不认识的别神烧香，又使这地方满了无辜人的血，"},
{volumn:        24,chapter:        19,versenumber:         5,verse:"又建筑巴力的邱坛，好在火中焚烧自己的儿子，作为燔祭献给巴力。这不是我所吩咐的，不是我所提说的，也不是我心所起的意。"},
{volumn:        24,chapter:        19,versenumber:         6,verse:"耶和华说：因此，日子将到，这地方不再称为陀斐特和欣嫩子谷，反倒称为杀戮谷。"},
{volumn:        24,chapter:        19,versenumber:         7,verse:"我必在这地方使犹大和耶路撒冷的计谋落空，也必使他们在仇敌面前倒于刀下，并寻索其命的人手下。他们的尸首，我必给空中的飞鸟和地上的野兽作食物。"},
{volumn:        24,chapter:        19,versenumber:         8,verse:"我必使这城令人惊骇嗤笑；凡经过的人，必因这城所遭的灾惊骇嗤笑。"},
{volumn:        24,chapter:        19,versenumber:         9,verse:"我必使他们在围困窘迫之中，就是仇敌和寻索其命的人窘迫他们的时候，各人吃自己儿女的肉和朋友的肉。’"},
{volumn:        24,chapter:        19,versenumber:        10,verse:"“你要在同去的人眼前打碎那瓶，"},
{volumn:        24,chapter:        19,versenumber:        11,verse:"对他们说：‘万军之耶和华如此说：我要照样打碎这民和这城，正如人打碎窑匠的瓦器，以致不能再囫囵。并且人要在陀斐特葬埋尸首，甚至无处可葬。"},
{volumn:        24,chapter:        19,versenumber:        12,verse:"耶和华说：我必向这地方和其中的居民如此行，使这城与陀斐特一样。"},
{volumn:        24,chapter:        19,versenumber:        13,verse:"耶路撒冷的房屋和犹大君王的宫殿是已经被玷污的，就是他们在其上向天上的万象烧香、向别神浇奠祭的宫殿房屋，都必与陀斐特一样。’”"},
{volumn:        24,chapter:        19,versenumber:        14,verse:"耶利米从陀斐特，就是耶和华差他去说预言的地方回来，站在耶和华殿的院中，对众人说："},
{volumn:        24,chapter:        19,versenumber:        15,verse:"“万军之耶和华以色列的　神如此说：我必使我所说的一切灾祸临到这城和属城的一切城邑，因为他们硬着颈项不听我的话。”"},
{volumn:        24,chapter:        20,versenumber:         1,verse:"祭司音麦的儿子巴施户珥作耶和华殿的总管，听见耶利米预言这些事，"},
{volumn:        24,chapter:        20,versenumber:         2,verse:"他就打先知耶利米，用耶和华殿里便雅悯高门内的枷，将他枷在那里。"},
{volumn:        24,chapter:        20,versenumber:         3,verse:"次日，巴施户珥将耶利米开枷释放。于是耶利米对他说：“耶和华不是叫你的名为巴施户珥，乃是叫你玛歌珥·米撒毕（就是四面惊吓的意思），"},
{volumn:        24,chapter:        20,versenumber:         4,verse:"因耶和华如此说：‘我必使你自觉惊吓，你也必使众朋友惊吓；他们必倒在仇敌的刀下，你也必亲眼看见；我必将犹大人全交在巴比伦王的手中，他要将他们掳到巴比伦去，也要用刀将他们杀戮。"},
{volumn:        24,chapter:        20,versenumber:         5,verse:"并且我要将这城中的一切货财和劳碌得来的，并一切珍宝，以及犹大君王所有的宝物，都交在他们仇敌的手中；仇敌要当作掠物带到巴比伦去。"},
{volumn:        24,chapter:        20,versenumber:         6,verse:"你这巴施户珥和一切住在你家中的人都必被掳去；你和你的众朋友，就是你向他们说假预言的，都必到巴比伦去，要死在那里，葬在那里。’”"},
{volumn:        24,chapter:        20,versenumber:         7,verse:"耶和华啊，你曾劝导我，我也听了你的劝导。你比我有力量，且胜了我。我终日成为笑话，人人都戏弄我。"},
{volumn:        24,chapter:        20,versenumber:         8,verse:"我每逢讲论的时候，就发出哀声，我喊叫说：有强暴和毁灭！因为耶和华的话终日成了我的凌辱、讥刺。"},
{volumn:        24,chapter:        20,versenumber:         9,verse:"我若说：我不再提耶和华，也不再奉他的名讲论，我便心里觉得似乎有烧着的火闭塞在我骨中，我就含忍不住，不能自禁。"},
{volumn:        24,chapter:        20,versenumber:        10,verse:"我听见了许多人的谗谤，四围都是惊吓；就是我知己的朋友也都窥探我，愿我跌倒，说：“告他吧，我们也要告他！或者他被引诱，我们就能胜他，在他身上报仇。”"},
{volumn:        24,chapter:        20,versenumber:        11,verse:"然而，耶和华与我同在，好像甚可怕的勇士。因此，逼迫我的必都绊跌，不能得胜；他们必大大蒙羞，就是受永不忘记的羞辱，因为他们行事没有智慧。"},
{volumn:        24,chapter:        20,versenumber:        12,verse:"试验义人、察看人肺腑心肠的万军之耶和华啊，求你容我见你在他们身上报仇，因我将我的案件向你禀明了。"},
{volumn:        24,chapter:        20,versenumber:        13,verse:"你们要向耶和华唱歌；赞美耶和华！因他救了穷人的性命脱离恶人的手。"},
{volumn:        24,chapter:        20,versenumber:        14,verse:"愿我生的那日受咒诅；愿我母亲产我的那日不蒙福！"},
{volumn:        24,chapter:        20,versenumber:        15,verse:"给我父亲报信说“你得了儿子”，使我父亲甚欢喜的，愿那人受咒诅。"},
{volumn:        24,chapter:        20,versenumber:        16,verse:"愿那人像耶和华所倾覆而不后悔的城邑；愿他早晨听见哀声，晌午听见呐喊；"},
{volumn:        24,chapter:        20,versenumber:        17,verse:"因他在我未出胎的时候不杀我，使我母亲成了我的坟墓，胎就时常重大。"},
{volumn:        24,chapter:        20,versenumber:        18,verse:"我为何出胎见劳碌愁苦，使我的年日因羞愧消灭呢？"},
{volumn:        24,chapter:        21,versenumber:         1,verse:"耶和华的话临到耶利米。那时，西底家王打发玛基雅的儿子巴施户珥和玛西雅的儿子祭司西番雅去见耶利米，说："},
{volumn:        24,chapter:        21,versenumber:         2,verse:"“请你为我们求问耶和华；因为巴比伦王尼布甲尼撒来攻击我们，或者耶和华照他一切奇妙的作为待我们，使巴比伦王离开我们上去。”"},
{volumn:        24,chapter:        21,versenumber:         3,verse:"耶利米对他们说：“你们当对西底家这样说："},
{volumn:        24,chapter:        21,versenumber:         4,verse:"‘耶和华以色列的　神如此说：我要使你们手中的兵器，就是你们在城外与巴比伦王和围困你们的迦勒底人打仗的兵器翻转过来，又要使这些都聚集在这城中。"},
{volumn:        24,chapter:        21,versenumber:         5,verse:"并且我要在怒气、忿怒，和大恼恨中，用伸出来的手，并大能的膀臂，亲自攻击你们；"},
{volumn:        24,chapter:        21,versenumber:         6,verse:"又要击打这城的居民，连人带牲畜都必遭遇大瘟疫死亡。"},
{volumn:        24,chapter:        21,versenumber:         7,verse:"以后我要将犹大王西底家和他的臣仆百姓，就是在城内，从瘟疫、刀剑、饥荒中剩下的人，都交在巴比伦王尼布甲尼撒的手中和他们仇敌，并寻索其命的人手中。巴比伦王必用刀击杀他们，不顾惜，不可怜，不怜悯。这是耶和华说的。’"},
{volumn:        24,chapter:        21,versenumber:         8,verse:"“你要对这百姓说：‘耶和华如此说：看哪，我将生命的路和死亡的路摆在你们面前。"},
{volumn:        24,chapter:        21,versenumber:         9,verse:"住在这城里的必遭刀剑、饥荒、瘟疫而死；但出去归降围困你们迦勒底人的必得存活，要以自己的命为掠物。’"},
{volumn:        24,chapter:        21,versenumber:        10,verse:"耶和华说：我向这城变脸，降祸不降福；这城必交在巴比伦王的手中，他必用火焚烧。”"},
{volumn:        24,chapter:        21,versenumber:        11,verse:"“至于犹大王的家，你们当听耶和华的话。"},
{volumn:        24,chapter:        21,versenumber:        12,verse:"大卫家啊，耶和华如此说：‘你们每早晨要施行公平，拯救被抢夺的脱离欺压人的手，恐怕我的忿怒因你们的恶行发作，如火着起，甚至无人能以熄灭。’"},
{volumn:        24,chapter:        21,versenumber:        13,verse:"耶和华说：住山谷和平原磐石上的居民，你们说：‘谁能下来攻击我们？谁能进入我们的住处呢？’看哪，我与你们为敌。"},
{volumn:        24,chapter:        21,versenumber:        14,verse:"耶和华又说：我必按你们做事的结果刑罚你们；我也必使火在耶路撒冷的林中着起，将她四围所有的尽行烧灭。”"},
{volumn:        24,chapter:        22,versenumber:         1,verse:"耶和华如此说：“你下到犹大王的宫中，在那里说这话，"},
{volumn:        24,chapter:        22,versenumber:         2,verse:"说：‘坐大卫宝座的犹大王啊，你和你的臣仆，并进入城门的百姓，都当听耶和华的话。"},
{volumn:        24,chapter:        22,versenumber:         3,verse:"耶和华如此说：你们要施行公平和公义，拯救被抢夺的脱离欺压人的手，不可亏负寄居的和孤儿寡妇，不可以强暴待他们，在这地方也不可流无辜人的血。"},
{volumn:        24,chapter:        22,versenumber:         4,verse:"你们若认真行这事，就必有坐大卫宝座的君王和他的臣仆百姓，或坐车或骑马，从这城的各门进入。"},
{volumn:        24,chapter:        22,versenumber:         5,verse:"你们若不听这些话，耶和华说：我指着自己起誓，这城必变为荒场。’”"},
{volumn:        24,chapter:        22,versenumber:         6,verse:"耶和华论到犹大王的家如此说：“我看你如基列，如黎巴嫩顶；然而，我必使你变为旷野，为无人居住的城邑。"},
{volumn:        24,chapter:        22,versenumber:         7,verse:"我要预备行毁灭的人，各拿器械攻击你；他们要砍下你佳美的香柏树，扔在火中。"},
{volumn:        24,chapter:        22,versenumber:         8,verse:"许多国的民要经过这城，各人对邻舍说：‘耶和华为何向这大城如此行呢？’"},
{volumn:        24,chapter:        22,versenumber:         9,verse:"他们必回答说：‘是因离弃了耶和华他们　神的约，侍奉敬拜别神。’”"},
{volumn:        24,chapter:        22,versenumber:        10,verse:"不要为死人哭号；不要为他悲伤，却要为离家出外的人大大哭号；因为他不得再回来，也不得再见他的本国。"},
{volumn:        24,chapter:        22,versenumber:        11,verse:"因为耶和华论到从这地方出去的犹大王约西亚的儿子沙龙（在列王下二十三章三十节名约哈斯），就是接续他父亲约西亚作王的，这样说：“他必不得再回到这里来，"},
{volumn:        24,chapter:        22,versenumber:        12,verse:"却要死在被掳去的地方，必不得再见这地。”"},
{volumn:        24,chapter:        22,versenumber:        13,verse:"那行不义盖房、行不公造楼、白白使用人的手工不给工价的有祸了！"},
{volumn:        24,chapter:        22,versenumber:        14,verse:"他说：“我要为自己盖广大的房、宽敞的楼，为自己开窗户。这楼房的护墙板是香柏木的，楼房是丹色油漆的。”"},
{volumn:        24,chapter:        22,versenumber:        15,verse:"难道你作王是在乎造香柏木楼房争胜吗？你的父亲岂不是也吃也喝、也施行公平和公义吗？那时他得了福乐。"},
{volumn:        24,chapter:        22,versenumber:        16,verse:"他为困苦和穷乏人伸冤，那时就得了福乐。认识我不在乎此吗？这是耶和华说的。"},
{volumn:        24,chapter:        22,versenumber:        17,verse:"惟有你的眼和你的心专顾贪婪，流无辜人的血，行欺压和强暴。"},
{volumn:        24,chapter:        22,versenumber:        18,verse:"所以，耶和华论到犹大王约西亚的儿子约雅敬如此说：“人必不为他举哀说：‘哀哉！我的哥哥’；或说：‘哀哉！我的姐姐’；也不为他举哀说：‘哀哉！我的主’；或说：‘哀哉！我主的荣华。’"},
{volumn:        24,chapter:        22,versenumber:        19,verse:"他被埋葬，好像埋驴一样，要拉出去扔在耶路撒冷的城门之外。”"},
{volumn:        24,chapter:        22,versenumber:        20,verse:"“你要上黎巴嫩哀号，在巴珊扬声，从亚巴琳哀号，因为你所亲爱的都毁灭了。"},
{volumn:        24,chapter:        22,versenumber:        21,verse:"你兴盛的时候，我对你说话；你却说：‘我不听。’你自幼年以来总是这样，不听从我的话。"},
{volumn:        24,chapter:        22,versenumber:        22,verse:"你的牧人要被风吞吃，你所亲爱的必被掳去；那时你必因你一切的恶抱愧蒙羞。"},
{volumn:        24,chapter:        22,versenumber:        23,verse:"你这住黎巴嫩、在香柏树上搭窝的，有痛苦临到你，好像疼痛临到产难的妇人，那时你何等可怜！”"},
{volumn:        24,chapter:        22,versenumber:        24,verse:"耶和华说：“犹大王约雅敬的儿子哥尼雅（又名耶哥尼雅；下同），虽是我右手上带印的戒指，我凭我的永生起誓，也必将你从其上摘下来，"},
{volumn:        24,chapter:        22,versenumber:        25,verse:"并且我必将你交给寻索你命的人和你所惧怕的人手中，就是巴比伦王尼布甲尼撒和迦勒底人的手中。"},
{volumn:        24,chapter:        22,versenumber:        26,verse:"我也必将你和生你的母亲赶到别国，并不是你们生的地方；你们必死在那里，"},
{volumn:        24,chapter:        22,versenumber:        27,verse:"但心中甚想归回之地，必不得归回。”"},
{volumn:        24,chapter:        22,versenumber:        28,verse:"哥尼雅这人是被轻看、破坏的器皿吗？是无人喜爱的器皿吗？他和他的后裔为何被赶到不认识之地呢？"},
{volumn:        24,chapter:        22,versenumber:        29,verse:"地啊，地啊，地啊，当听耶和华的话！"},
{volumn:        24,chapter:        22,versenumber:        30,verse:"耶和华如此说：“要写明这人算为无子，是平生不得亨通的；因为他后裔中再无一人得亨通，能坐在大卫的宝座上治理犹大。”"},
{volumn:        24,chapter:        23,versenumber:         1,verse:"耶和华说：“那些残害、赶散我草场之羊的牧人有祸了！”"},
{volumn:        24,chapter:        23,versenumber:         2,verse:"耶和华以色列的　神斥责那些牧养他百姓的牧人，如此说：“你们赶散我的羊群，并没有看顾他们；我必讨你们这行恶的罪。这是耶和华说的。"},
{volumn:        24,chapter:        23,versenumber:         3,verse:"我要将我羊群中所余剩的，从我赶他们到的各国内招聚出来，领他们归回本圈；他们也必生养众多。"},
{volumn:        24,chapter:        23,versenumber:         4,verse:"我必设立照管他们的牧人，牧养他们。他们不再惧怕，不再惊惶，也不缺少一个；这是耶和华说的。”"},
{volumn:        24,chapter:        23,versenumber:         5,verse:"耶和华说：“日子将到，我要给大卫兴起一个公义的苗裔；他必掌王权，行事有智慧，在地上施行公平和公义。"},
{volumn:        24,chapter:        23,versenumber:         6,verse:"在他的日子，犹大必得救，以色列也安然居住。他的名必称为‘耶和华我们的义’。”"},
{volumn:        24,chapter:        23,versenumber:         7,verse:"耶和华说：“日子将到，人必不再指着那领以色列人从埃及地上来永生的耶和华起誓，"},
{volumn:        24,chapter:        23,versenumber:         8,verse:"却要指着那领以色列家的后裔从北方和赶他们到的各国中上来、永生的耶和华起誓。他们必住在本地。”"},
{volumn:        24,chapter:        23,versenumber:         9,verse:"论到那些先知，我心在我里面忧伤，我骨头都发颤；因耶和华和他的圣言，我像醉酒的人，像被酒所胜的人。"},
{volumn:        24,chapter:        23,versenumber:        10,verse:"地满了行淫的人！因妄自赌咒，地就悲哀；旷野的草场都枯干了。他们所行的道乃是恶的；他们的勇力使得不正。"},
{volumn:        24,chapter:        23,versenumber:        11,verse:"连先知带祭司都是亵渎的，就是在我殿中我也看见他们的恶。这是耶和华说的。"},
{volumn:        24,chapter:        23,versenumber:        12,verse:"因此，他们的道路必像黑暗中的滑地，他们必被追赶，在这路中仆倒；因为当追讨之年，我必使灾祸临到他们。这是耶和华说的。"},
{volumn:        24,chapter:        23,versenumber:        13,verse:"我在撒玛利亚的先知中曾见愚妄；他们藉巴力说预言，使我的百姓以色列走错了路。"},
{volumn:        24,chapter:        23,versenumber:        14,verse:"我在耶路撒冷的先知中曾见可憎恶的事；他们行奸淫，做事虚妄，又坚固恶人的手，甚至无人回头离开他的恶。他们在我面前都像所多玛；耶路撒冷的居民都像蛾摩拉。"},
{volumn:        24,chapter:        23,versenumber:        15,verse:"所以万军之耶和华论到先知如此说：我必将茵陈给他们吃，又将苦胆水给他们喝；因为亵渎的事出于耶路撒冷的先知，流行遍地。"},
{volumn:        24,chapter:        23,versenumber:        16,verse:"万军之耶和华如此说：“这些先知向你们说预言，你们不要听他们的话。他们以虚空教训你们，所说的异象是出于自己的心，不是出于耶和华的口。"},
{volumn:        24,chapter:        23,versenumber:        17,verse:"他们常对藐视我的人说：‘耶和华说：你们必享平安’；又对一切按自己顽梗之心而行的人说：‘必没有灾祸临到你们。’”"},
{volumn:        24,chapter:        23,versenumber:        18,verse:"有谁站在耶和华的会中得以听见并会悟他的话呢？有谁留心听他的话呢？"},
{volumn:        24,chapter:        23,versenumber:        19,verse:"看哪！耶和华的忿怒好像暴风，已经发出；是暴烈的旋风，必转到恶人的头上。"},
{volumn:        24,chapter:        23,versenumber:        20,verse:"耶和华的怒气必不转消，直到他心中所拟定的成就了。末后的日子你们要全然明白。"},
{volumn:        24,chapter:        23,versenumber:        21,verse:"“我没有打发那些先知，他们竟自奔跑；我没有对他们说话，他们竟自预言。"},
{volumn:        24,chapter:        23,versenumber:        22,verse:"他们若是站在我的会中，就必使我的百姓听我的话，又使他们回头离开恶道和他们所行的恶。”"},
{volumn:        24,chapter:        23,versenumber:        23,verse:"耶和华说：“我岂为近处的　神呢？不也为远处的　神吗？”"},
{volumn:        24,chapter:        23,versenumber:        24,verse:"耶和华说：“人岂能在隐密处藏身，使我看不见他呢？”耶和华说：“我岂不充满天地吗？"},
{volumn:        24,chapter:        23,versenumber:        25,verse:"我已听见那些先知所说的，就是托我名说的假预言，他们说：‘我做了梦！我做了梦！’"},
{volumn:        24,chapter:        23,versenumber:        26,verse:"说假预言的先知，就是预言本心诡诈的先知，他们这样存心要到几时呢？"},
{volumn:        24,chapter:        23,versenumber:        27,verse:"他们各人将所做的梦对邻舍述说，想要使我的百姓忘记我的名，正如他们列祖因巴力忘记我的名一样。"},
{volumn:        24,chapter:        23,versenumber:        28,verse:"得梦的先知可以述说那梦；得我话的人可以诚实讲说我的话。糠秕怎能与麦子比较呢？这是耶和华说的。”"},
{volumn:        24,chapter:        23,versenumber:        29,verse:"耶和华说：“我的话岂不像火，又像能打碎磐石的大锤吗？”"},
{volumn:        24,chapter:        23,versenumber:        30,verse:"耶和华说：“那些先知各从邻舍偷窃我的言语，因此我必与他们反对。”"},
{volumn:        24,chapter:        23,versenumber:        31,verse:"耶和华说：“那些先知用舌头，说是耶和华说的；我必与他们反对。”"},
{volumn:        24,chapter:        23,versenumber:        32,verse:"耶和华说：“那些以幻梦为预言，又述说这梦，以谎言和矜夸使我百姓走错了路的，我必与他们反对。我没有打发他们，也没有吩咐他们。他们与这百姓毫无益处。这是耶和华说的。”"},
{volumn:        24,chapter:        23,versenumber:        33,verse:"“无论是百姓，是先知，是祭司，问你说：‘耶和华有什么默示呢？’你就对他们说：‘什么默示啊？耶和华说：我要撇弃你们。’"},
{volumn:        24,chapter:        23,versenumber:        34,verse:"无论是先知，是祭司，是百姓，说‘耶和华的默示’，我必刑罚那人和他的家。"},
{volumn:        24,chapter:        23,versenumber:        35,verse:"你们各人要对邻舍，各人要对弟兄如此说：‘耶和华回答什么？’‘耶和华说了什么呢？’"},
{volumn:        24,chapter:        23,versenumber:        36,verse:"‘耶和华的默示’你们不可再提，各人所说的话必作自己的重担（重担和默示原文同），因为你们谬用永生　神、万军之耶和华我们　神的言语。"},
{volumn:        24,chapter:        23,versenumber:        37,verse:"你们要对先知如此说：‘耶和华回答你什么？’‘耶和华说了什么呢？’"},
{volumn:        24,chapter:        23,versenumber:        38,verse:"你们若说‘耶和华的默示’，耶和华就如此说：‘因你们说“耶和华的默示”这句话，我也打发人到你们那里去，告诉你们不可说“耶和华的默示”。’"},
{volumn:        24,chapter:        23,versenumber:        39,verse:"所以我必全然忘记你们，将你们和我所赐给你们并你们列祖的城撇弃了；"},
{volumn:        24,chapter:        23,versenumber:        40,verse:"又必使永远的凌辱和长久的羞耻临到你们，是不能忘记的。”"},
{volumn:        24,chapter:        24,versenumber:         1,verse:"巴比伦王尼布甲尼撒将犹大王约雅敬的儿子耶哥尼雅和犹大的首领，并工匠、铁匠从耶路撒冷掳去，带到巴比伦。这事以后，耶和华指给我看，有两筐无花果放在耶和华的殿前。"},
{volumn:        24,chapter:        24,versenumber:         2,verse:"一筐是极好的无花果，好像是初熟的；一筐是极坏的无花果，坏得不可吃。"},
{volumn:        24,chapter:        24,versenumber:         3,verse:"于是耶和华问我说：“耶利米你看见什么？”我说：“我看见无花果，好的极好，坏的极坏，坏得不可吃。”"},
{volumn:        24,chapter:        24,versenumber:         4,verse:"耶和华的话临到我说："},
{volumn:        24,chapter:        24,versenumber:         5,verse:"“耶和华以色列的　神如此说：被掳去的犹大人，就是我打发离开这地到迦勒底人之地去的，我必看顾他们如这好无花果，使他们得好处。"},
{volumn:        24,chapter:        24,versenumber:         6,verse:"我要眷顾他们，使他们得好处，领他们归回这地。我也要建立他们，必不拆毁；栽植他们，并不拔出。"},
{volumn:        24,chapter:        24,versenumber:         7,verse:"我要赐他们认识我的心，知道我是耶和华。他们要作我的子民，我要作他们的　神，因为他们要一心归向我。”"},
{volumn:        24,chapter:        24,versenumber:         8,verse:"耶和华如此说：“我必将犹大王西底家和他的首领，以及剩在这地耶路撒冷的余民，并住在埃及地的犹大人都交出来，好像那极坏、坏得不可吃的无花果。"},
{volumn:        24,chapter:        24,versenumber:         9,verse:"我必使他们交出来，在天下万国中抛来抛去，遭遇灾祸；在我赶逐他们到的各处成为凌辱、笑谈、讥刺、咒诅。"},
{volumn:        24,chapter:        24,versenumber:        10,verse:"我必使刀剑、饥荒、瘟疫临到他们，直到他们从我所赐给他们和他们列祖之地灭绝。”"},
{volumn:        24,chapter:        25,versenumber:         1,verse:"犹大王约西亚的儿子约雅敬第四年，就是巴比伦王尼布甲尼撒的元年，耶和华论犹大众民的话临到耶利米。"},
{volumn:        24,chapter:        25,versenumber:         2,verse:"先知耶利米就将这话对犹大众人和耶路撒冷的一切居民说："},
{volumn:        24,chapter:        25,versenumber:         3,verse:"“从犹大王亚们的儿子约西亚十三年直到今日，这二十三年之内，常有耶和华的话临到我；我也对你们传说，就是从早起来传说，只是你们没有听从。"},
{volumn:        24,chapter:        25,versenumber:         4,verse:"耶和华也从早起来，差遣他的仆人众先知到你们这里来（只是你们没有听从，也没有侧耳而听），"},
{volumn:        24,chapter:        25,versenumber:         5,verse:"说：‘你们各人当回头，离开恶道和所作的恶，便可居住耶和华古时所赐给你们和你们列祖之地，直到永远。"},
{volumn:        24,chapter:        25,versenumber:         6,verse:"不可随从别神侍奉敬拜，以你们手所做的惹我发怒；这样，我就不加害与你们。"},
{volumn:        24,chapter:        25,versenumber:         7,verse:"然而你们没有听从我，竟以手所做的惹我发怒，陷害自己。这是耶和华说的。’”"},
{volumn:        24,chapter:        25,versenumber:         8,verse:"所以万军之耶和华如此说：“因为你们没有听从我的话，"},
{volumn:        24,chapter:        25,versenumber:         9,verse:"我必召北方的众族和我仆人巴比伦王尼布甲尼撒来攻击这地和这地的居民，并四围一切的国民。我要将他们尽行灭绝，以致他们令人惊骇、嗤笑，并且永久荒凉。这是耶和华说的。"},
{volumn:        24,chapter:        25,versenumber:        10,verse:"我又要使欢喜和快乐的声音，新郎和新妇的声音，推磨的声音和灯的亮光，从他们中间止息。"},
{volumn:        24,chapter:        25,versenumber:        11,verse:"这全地必然荒凉，令人惊骇。这些国民要服侍巴比伦王七十年。"},
{volumn:        24,chapter:        25,versenumber:        12,verse:"七十年满了以后，我必刑罚巴比伦王和那国民，并迦勒底人之地，因他们的罪孽使那地永远荒凉。这是耶和华说的。"},
{volumn:        24,chapter:        25,versenumber:        13,verse:"我也必使我向那地所说的话，就是记在这书上的话，是耶利米向这些国民说的预言，都临到那地。"},
{volumn:        24,chapter:        25,versenumber:        14,verse:"因为有多国和大君王必使迦勒底人作奴仆；我也必照他们的行为，按他们手所做的报应他们。”"},
{volumn:        24,chapter:        25,versenumber:        15,verse:"耶和华以色列的　神对我如此说：“你从我手中接这杯忿怒的酒，使我所差遣你去的各国的民喝。"},
{volumn:        24,chapter:        25,versenumber:        16,verse:"他们喝了就要东倒西歪，并要发狂，因我使刀剑临到他们中间。”"},
{volumn:        24,chapter:        25,versenumber:        17,verse:"我就从耶和华的手中接了这杯，给耶和华所差遣我去的各国的民喝，"},
{volumn:        24,chapter:        25,versenumber:        18,verse:"就是耶路撒冷和犹大的城邑，并耶路撒冷的君王与首领，使这城邑荒凉，令人惊骇、嗤笑、咒诅，正如今日一样。"},
{volumn:        24,chapter:        25,versenumber:        19,verse:"又有埃及王法老和他的臣仆、首领，以及他的众民，"},
{volumn:        24,chapter:        25,versenumber:        20,verse:"并杂族的人民和乌斯地的诸王，与非利士地的诸王（亚实基伦、迦萨、以革伦，以及亚实突剩下的人）；"},
{volumn:        24,chapter:        25,versenumber:        21,verse:"以东、摩押、亚扪人、"},
{volumn:        24,chapter:        25,versenumber:        22,verse:"推罗的诸王、西顿的诸王、海岛的诸王、"},
{volumn:        24,chapter:        25,versenumber:        23,verse:"底但、提玛、布斯，和一切剃周围头发的；"},
{volumn:        24,chapter:        25,versenumber:        24,verse:"阿拉伯的诸王、住旷野杂族人民的诸王、"},
{volumn:        24,chapter:        25,versenumber:        25,verse:"心利的诸王、以拦的诸王、玛代的诸王、"},
{volumn:        24,chapter:        25,versenumber:        26,verse:"北方远近的诸王，以及天下地上的万国喝了，以后示沙克（就是巴比伦）王也要喝。"},
{volumn:        24,chapter:        25,versenumber:        27,verse:"“你要对他们说：‘万军之耶和华以色列的　神如此说：你们要喝，且要喝醉，要呕吐，且要跌倒，不得再起来，都因我使刀剑临到你们中间。’"},
{volumn:        24,chapter:        25,versenumber:        28,verse:"他们若不肯从你手接这杯喝，你就要对他们说：‘万军之耶和华如此说：你们一定要喝！"},
{volumn:        24,chapter:        25,versenumber:        29,verse:"我既从称为我名下的城起首施行灾祸，你们能尽免刑罚吗？你们必不能免，因为我要命刀剑临到地上一切的居民。这是万军之耶和华说的。’"},
{volumn:        24,chapter:        25,versenumber:        30,verse:"“所以你要向他们预言这一切的话，攻击他们，说：‘耶和华必从高天吼叫，从圣所发声，向自己的羊群大声吼叫；他要向地上一切的居民呐喊，像踹葡萄的一样。"},
{volumn:        24,chapter:        25,versenumber:        31,verse:"必有响声达到地极，因为耶和华与列国相争；凡有血气的，他必审问；至于恶人，他必交给刀剑。这是耶和华说的。’”"},
{volumn:        24,chapter:        25,versenumber:        32,verse:"万军之耶和华如此说：“看哪，必有灾祸从这国发到那国，并有大暴风从地极刮起。"},
{volumn:        24,chapter:        25,versenumber:        33,verse:"到那日，从地这边直到地那边都有耶和华所杀戮的。必无人哀哭，不得收殓，不得葬埋，必在地上成为粪土。"},
{volumn:        24,chapter:        25,versenumber:        34,verse:"牧人哪，你们当哀号，呼喊；群众的头目啊，你们要滚在灰中；因为你们被杀戮分散的日子足足来到。你们要跌碎，好像美器打碎一样。"},
{volumn:        24,chapter:        25,versenumber:        35,verse:"牧人无路逃跑；群众的头目也无法逃脱。"},
{volumn:        24,chapter:        25,versenumber:        36,verse:"听啊，有牧人呼喊，有群众头目哀号的声音，因为耶和华使他们的草场变为荒场。"},
{volumn:        24,chapter:        25,versenumber:        37,verse:"耶和华发出猛烈的怒气，平安的羊圈就都寂静无声。"},
{volumn:        24,chapter:        25,versenumber:        38,verse:"他离了隐密处像狮子一样，他们的地，因刀剑凶猛的欺压，又因他猛烈的怒气都成为可惊骇的。”"},
{volumn:        24,chapter:        26,versenumber:         1,verse:"犹大王约西亚的儿子约雅敬登基的时候，有这话从耶和华临到耶利米说："},
{volumn:        24,chapter:        26,versenumber:         2,verse:"“耶和华如此说：你站在耶和华殿的院内，对犹大众城邑的人，就是到耶和华殿来礼拜的，说我所吩咐你的一切话，一字不可删减。"},
{volumn:        24,chapter:        26,versenumber:         3,verse:"或者他们肯听从，各人回头离开恶道，使我后悔不将我因他们所行的恶，想要施行的灾祸降与他们。"},
{volumn:        24,chapter:        26,versenumber:         4,verse:"你要对他们说，耶和华如此说：‘你们若不听从我，不遵行我设立在你们面前的律法，"},
{volumn:        24,chapter:        26,versenumber:         5,verse:"不听我从早起来差遣到你们那里去我仆人众先知的话（你们还是没有听从），"},
{volumn:        24,chapter:        26,versenumber:         6,verse:"我就必使这殿如示罗，使这城为地上万国所咒诅的。’”"},
{volumn:        24,chapter:        26,versenumber:         7,verse:"耶利米在耶和华殿中说的这些话，祭司、先知与众民都听见了。"},
{volumn:        24,chapter:        26,versenumber:         8,verse:"耶利米说完了耶和华所吩咐他对众人说的一切话，祭司、先知与众民都来抓住他，说：“你必要死！"},
{volumn:        24,chapter:        26,versenumber:         9,verse:"你为何托耶和华的名预言，说这殿必如示罗，这城必变为荒场无人居住呢？”于是众民都在耶和华的殿中聚集到耶利米那里。"},
{volumn:        24,chapter:        26,versenumber:        10,verse:"犹大的首领听见这事，就从王宫上到耶和华的殿，坐在耶和华殿的新门口。"},
{volumn:        24,chapter:        26,versenumber:        11,verse:"祭司、先知对首领和众民说：“这人是该死的；因为他说预言攻击这城，正如你们亲耳所听见的。”"},
{volumn:        24,chapter:        26,versenumber:        12,verse:"耶利米就对众首领和众民说：“耶和华差遣我预言，攻击这殿和这城，说你们所听见的这一切话。"},
{volumn:        24,chapter:        26,versenumber:        13,verse:"现在要改正你们的行动作为，听从耶和华你们　神的话，他就必后悔，不将所说的灾祸降与你们。"},
{volumn:        24,chapter:        26,versenumber:        14,verse:"至于我，我在你们手中，你们眼看何为善，何为正，就那样待我吧！"},
{volumn:        24,chapter:        26,versenumber:        15,verse:"但你们要确实地知道，若把我治死，就使无辜人的血归到你们和这城，并其中的居民了；因为耶和华实在差遣我到你们这里来，将这一切话传与你们耳中。”"},
{volumn:        24,chapter:        26,versenumber:        16,verse:"首领和众民就对祭司、先知说：“这人是不该死的，因为他是奉耶和华我们　神的名向我们说话。”"},
{volumn:        24,chapter:        26,versenumber:        17,verse:"国中的长老就有几个人起来，对聚会的众民说："},
{volumn:        24,chapter:        26,versenumber:        18,verse:"“当犹大王希西家的日子，有摩利沙人弥迦对犹大众人预言说：‘万军之耶和华如此说：锡安必被耕种像一块田；耶路撒冷必变为乱堆；这殿的山必像丛林的高处。’"},
{volumn:        24,chapter:        26,versenumber:        19,verse:"犹大王希西家和犹大众人岂是把他治死呢？希西家岂不是敬畏耶和华、恳求他的恩吗？耶和华就后悔，不把自己所说的灾祸降与他们。若治死这人，我们就作了大恶，自害己命。”"},
{volumn:        24,chapter:        26,versenumber:        20,verse:"又有一个人奉耶和华的名说预言，是基列·耶琳人示玛雅的儿子乌利亚，他照耶利米的一切话说预言，攻击这城和这地。"},
{volumn:        24,chapter:        26,versenumber:        21,verse:"约雅敬王和他众勇士、众首领听见了乌利亚的话，王就想要把他治死。乌利亚听见就惧怕，逃往埃及去了。"},
{volumn:        24,chapter:        26,versenumber:        22,verse:"约雅敬王便打发亚革波的儿子以利拿单，带领几个人往埃及去。"},
{volumn:        24,chapter:        26,versenumber:        23,verse:"他们就从埃及将乌利亚带出来，送到约雅敬王那里；王用刀杀了他，把他的尸首抛在平民的坟地中。"},
{volumn:        24,chapter:        26,versenumber:        24,verse:"然而，沙番的儿子亚希甘保护耶利米，不交在百姓的手中治死他。"},
{volumn:        24,chapter:        27,versenumber:         1,verse:"犹大王约西亚的儿子约雅敬（约雅敬是西底家的别名，看第三节）登基的时候，有这话从耶和华临到耶利米说："},
{volumn:        24,chapter:        27,versenumber:         2,verse:"“耶和华对我如此说：你做绳索与轭，加在自己的颈项上，"},
{volumn:        24,chapter:        27,versenumber:         3,verse:"藉那些来到耶路撒冷见犹大王西底家的使臣之手，把绳索与轭送到以东王、摩押王、亚扪王、推罗王、西顿王那里，"},
{volumn:        24,chapter:        27,versenumber:         4,verse:"且嘱咐使臣，传与他们的主人说，万军之耶和华以色列的　神如此说："},
{volumn:        24,chapter:        27,versenumber:         5,verse:"我用大能和伸出来的膀臂，创造大地和地上的人民、牲畜。我看给谁相宜，就把地给谁。"},
{volumn:        24,chapter:        27,versenumber:         6,verse:"现在我将这些地都交给我仆人巴比伦王尼布甲尼撒的手，我也将田野的走兽给他使用。"},
{volumn:        24,chapter:        27,versenumber:         7,verse:"列国都必服侍他和他的儿孙，直到他本国遭报的日期来到。那时，多国和大君王要使他作他们的奴仆。"},
{volumn:        24,chapter:        27,versenumber:         8,verse:"“无论哪一邦哪一国，不肯服侍这巴比伦王尼布甲尼撒，也不把颈项放在巴比伦王的轭下，我必用刀剑、饥荒、瘟疫刑罚那邦，直到我藉巴比伦王的手将他们毁灭。这是耶和华说的。"},
{volumn:        24,chapter:        27,versenumber:         9,verse:"至于你们，不可听从你们的先知和占卜的、圆梦的、观兆的，以及行邪术的；他们告诉你们说：‘你们不致服侍巴比伦王。’"},
{volumn:        24,chapter:        27,versenumber:        10,verse:"他们向你们说假预言，要叫你们迁移，远离本地，以致我将你们赶出去，使你们灭亡。"},
{volumn:        24,chapter:        27,versenumber:        11,verse:"但哪一邦肯把颈项放在巴比伦王的轭下服侍他，我必使那邦仍在本地存留，得以耕种居住。这是耶和华说的。”"},
{volumn:        24,chapter:        27,versenumber:        12,verse:"我就照这一切的话对犹大王西底家说：“要把你们的颈项放在巴比伦王的轭下，服侍他和他的百姓，便得存活。"},
{volumn:        24,chapter:        27,versenumber:        13,verse:"你和你的百姓为何要因刀剑、饥荒、瘟疫死亡，正如耶和华论到不服侍巴比伦王的那国说的话呢？"},
{volumn:        24,chapter:        27,versenumber:        14,verse:"不可听那些先知对你们所说的话；他们说：‘你们不致服侍巴比伦王’，其实他们向你们说假预言。"},
{volumn:        24,chapter:        27,versenumber:        15,verse:"耶和华说：‘我并没有打发他们，他们却托我的名说假预言，好使我将你们和向你们说预言的那些先知赶出去，一同灭亡。’”"},
{volumn:        24,chapter:        27,versenumber:        16,verse:"我又对祭司和这众民说：“耶和华如此说：你们不可听那先知对你们所说的预言。他们说：‘耶和华殿中的器皿快要从巴比伦带回来’；其实他们向你们说假预言。"},
{volumn:        24,chapter:        27,versenumber:        17,verse:"不可听从他们，只管服侍巴比伦王便得存活。这城何致变为荒场呢？"},
{volumn:        24,chapter:        27,versenumber:        18,verse:"他们若果是先知，有耶和华的话临到他们，让他们祈求万军之耶和华，使那在耶和华殿中和犹大王宫内，并耶路撒冷剩下的器皿，不被带到巴比伦去。（"},
{volumn:        24,chapter:        27,versenumber:        19,verse:"因为万军之耶和华论到柱子、铜海、盆座，并剩在这城里的器皿，"},
{volumn:        24,chapter:        27,versenumber:        20,verse:"就是巴比伦王尼布甲尼撒掳掠犹大王约雅敬的儿子耶哥尼雅，和犹大、耶路撒冷一切贵胄的时候所没有掠去的器皿。）"},
{volumn:        24,chapter:        27,versenumber:        21,verse:"论到那在耶和华殿中和犹大王宫内，并耶路撒冷剩下的器皿，万军之耶和华以色列的　神如此说："},
{volumn:        24,chapter:        27,versenumber:        22,verse:"必被带到巴比伦存在那里，直到我眷顾以色列人的日子。那时，我必将这器皿带回来，交还此地。这是耶和华说的。”"},
{volumn:        24,chapter:        28,versenumber:         1,verse:"当年，就是犹大王西底家登基第四年五月，基遍人押朔的儿子，先知哈拿尼雅，在耶和华的殿中当着祭司和众民对我说："},
{volumn:        24,chapter:        28,versenumber:         2,verse:"“万军之耶和华以色列的　神如此说：我已经折断巴比伦王的轭。"},
{volumn:        24,chapter:        28,versenumber:         3,verse:"二年之内，我要将巴比伦王尼布甲尼撒从这地掠到巴比伦的器皿，就是耶和华殿中的一切器皿都带回此地。"},
{volumn:        24,chapter:        28,versenumber:         4,verse:"我又要将犹大王约雅敬的儿子耶哥尼雅和被掳到巴比伦去的一切犹大人带回此地，因为我要折断巴比伦王的轭。这是耶和华说的。”"},
{volumn:        24,chapter:        28,versenumber:         5,verse:"先知耶利米当着祭司和站在耶和华殿里的众民对先知哈拿尼雅说："},
{volumn:        24,chapter:        28,versenumber:         6,verse:"“阿们！愿耶和华如此行，愿耶和华成就你所预言的话，将耶和华殿中的器皿和一切被掳去的人从巴比伦带回此地。"},
{volumn:        24,chapter:        28,versenumber:         7,verse:"然而我向你和众民耳中所要说的话，你应当听。"},
{volumn:        24,chapter:        28,versenumber:         8,verse:"从古以来，在你我以前的先知，向多国和大邦说预言，论到争战、灾祸、瘟疫的事。"},
{volumn:        24,chapter:        28,versenumber:         9,verse:"先知预言的平安，到话语成就的时候，人便知道他真是耶和华所差来的。”"},
{volumn:        24,chapter:        28,versenumber:        10,verse:"于是，先知哈拿尼雅将先知耶利米颈项上的轭取下来，折断了。"},
{volumn:        24,chapter:        28,versenumber:        11,verse:"哈拿尼雅又当着众民说：“耶和华如此说：二年之内我必照样从列国人的颈项上折断巴比伦王尼布甲尼撒的轭。”于是先知耶利米就走了。"},
{volumn:        24,chapter:        28,versenumber:        12,verse:"先知哈拿尼雅把先知耶利米颈项上的轭折断以后，耶和华的话临到耶利米说："},
{volumn:        24,chapter:        28,versenumber:        13,verse:"“你去告诉哈拿尼雅说，耶和华如此说：你折断木轭，却换了铁轭！"},
{volumn:        24,chapter:        28,versenumber:        14,verse:"因为万军之耶和华以色列的　神如此说：我已将铁轭加在这些国的颈项上，使他们服侍巴比伦王尼布甲尼撒，他们总要服侍他；我也把田野的走兽给了他。”"},
{volumn:        24,chapter:        28,versenumber:        15,verse:"于是先知耶利米对先知哈拿尼雅说：“哈拿尼雅啊，你应当听！耶和华并没有差遣你，你竟使这百姓倚靠谎言。"},
{volumn:        24,chapter:        28,versenumber:        16,verse:"所以耶和华如此说：看哪，我要叫你去世，你今年必死，因为你向耶和华说了叛逆的话。”"},
{volumn:        24,chapter:        28,versenumber:        17,verse:"这样，先知哈拿尼雅当年七月间就死了。"},
{volumn:        24,chapter:        29,versenumber:         1,verse:"先知耶利米从耶路撒冷寄信与被掳的祭司、先知，和众民，并生存的长老，就是尼布甲尼撒从耶路撒冷掳到巴比伦去的。（"},
{volumn:        24,chapter:        29,versenumber:         2,verse:"这在耶哥尼雅王和太后、太监，并犹大、耶路撒冷的首领，以及工匠、铁匠都离了耶路撒冷以后。）"},
{volumn:        24,chapter:        29,versenumber:         3,verse:"他藉沙番的儿子以利亚萨和希勒家的儿子基玛利的手寄去。他们二人是犹大王西底家打发往巴比伦去见尼布甲尼撒王的。"},
{volumn:        24,chapter:        29,versenumber:         4,verse:"信上说：“万军之耶和华以色列的　神对一切被掳去的（就是我使他们从耶路撒冷被掳到巴比伦的人）如此说："},
{volumn:        24,chapter:        29,versenumber:         5,verse:"你们要盖造房屋，住在其中；栽种田园，吃其中所产的；"},
{volumn:        24,chapter:        29,versenumber:         6,verse:"娶妻生儿女，为你们的儿子娶妻，使你们的女儿嫁人，生儿养女。在那里生养众多，不致减少。"},
{volumn:        24,chapter:        29,versenumber:         7,verse:"我所使你们被掳到的那城，你们要为那城求平安，为那城祷告耶和华；因为那城得平安，你们也随着得平安。"},
{volumn:        24,chapter:        29,versenumber:         8,verse:"万军之耶和华以色列的　神如此说：不要被你们中间的先知和占卜的诱惑，也不要听信自己所做的梦；"},
{volumn:        24,chapter:        29,versenumber:         9,verse:"因为他们托我的名对你们说假预言，我并没有差遣他们。这是耶和华说的。"},
{volumn:        24,chapter:        29,versenumber:        10,verse:"“耶和华如此说：为巴比伦所定的七十年满了以后，我要眷顾你们，向你们成就我的恩言，使你们仍回此地。"},
{volumn:        24,chapter:        29,versenumber:        11,verse:"耶和华说：我知道我向你们所怀的意念是赐平安的意念，不是降灾祸的意念，要叫你们末后有指望。"},
{volumn:        24,chapter:        29,versenumber:        12,verse:"你们要呼求我，祷告我，我就应允你们。"},
{volumn:        24,chapter:        29,versenumber:        13,verse:"你们寻求我，若专心寻求我，就必寻见。"},
{volumn:        24,chapter:        29,versenumber:        14,verse:"耶和华说：我必被你们寻见，我也必使你们被掳的人归回，将你们从各国中和我所赶你们到的各处招聚了来，又将你们带回我使你们被掳掠离开的地方。这是耶和华说的。"},
{volumn:        24,chapter:        29,versenumber:        15,verse:"“你们说：‘耶和华在巴比伦为我们兴起先知’；"},
{volumn:        24,chapter:        29,versenumber:        16,verse:"所以耶和华论到坐大卫宝座的王和住在这城里的一切百姓，就是未曾与你们一同被掳的弟兄，"},
{volumn:        24,chapter:        29,versenumber:        17,verse:"万军之耶和华如此说：‘看哪，我必使刀剑、饥荒、瘟疫临到他们，使他们像极坏的无花果，坏得不可吃。"},
{volumn:        24,chapter:        29,versenumber:        18,verse:"我必用刀剑、饥荒、瘟疫追赶他们，使他们在天下万国抛来抛去，在我所赶他们到的各国中，令人咒诅、惊骇、嗤笑、羞辱。"},
{volumn:        24,chapter:        29,versenumber:        19,verse:"耶和华说：这是因为他们没有听从我的话，就是我从早起来差遣我仆人众先知去说的，无奈他们不听。这是耶和华说的。"},
{volumn:        24,chapter:        29,versenumber:        20,verse:"所以你们一切被掳去的，就是我从耶路撒冷打发到巴比伦去的，当听耶和华的话。’”"},
{volumn:        24,chapter:        29,versenumber:        21,verse:"万军之耶和华以色列的　神论到哥赖雅的儿子亚哈，并玛西雅的儿子西底家，如此说：“他们是托我名向你们说假预言的，我必将他们交在巴比伦王尼布甲尼撒的手中；他要在你们眼前杀害他们。"},
{volumn:        24,chapter:        29,versenumber:        22,verse:"住巴比伦一切被掳的犹大人必藉这二人赌咒说：‘愿耶和华使你像巴比伦王在火中烧的西底家和亚哈一样。’"},
{volumn:        24,chapter:        29,versenumber:        23,verse:"这二人是在以色列中行了丑事，与邻舍的妻行淫，又假托我名说我未曾吩咐他们的话。知道的是我，作见证的也是我。这是耶和华说的。”"},
{volumn:        24,chapter:        29,versenumber:        24,verse:"“论到尼希兰人示玛雅，你当说，"},
{volumn:        24,chapter:        29,versenumber:        25,verse:"万军之耶和华以色列的　神如此说：你曾用自己的名寄信给耶路撒冷的众民和祭司玛西雅的儿子西番雅，并众祭司，说："},
{volumn:        24,chapter:        29,versenumber:        26,verse:"‘耶和华已经立你西番雅为祭司，代替祭司耶何耶大，使耶和华殿中有官长，好将一切狂妄自称为先知的人用枷枷住，用锁锁住。"},
{volumn:        24,chapter:        29,versenumber:        27,verse:"现在亚拿突人耶利米向你们自称为先知，你们为何没有责备他呢？"},
{volumn:        24,chapter:        29,versenumber:        28,verse:"因为他寄信给我们在巴比伦的人说：被掳的事必长久。你们要盖造房屋，住在其中；栽种田园，吃其中所产的。’”"},
{volumn:        24,chapter:        29,versenumber:        29,verse:"祭司西番雅就把这信念给先知耶利米听。"},
{volumn:        24,chapter:        29,versenumber:        30,verse:"于是耶和华的话临到耶利米说："},
{volumn:        24,chapter:        29,versenumber:        31,verse:"“你当寄信给一切被掳的人说：‘耶和华论到尼希兰人示玛雅说：因为示玛雅向你们说预言，我并没有差遣他，他使你们倚靠谎言；"},
{volumn:        24,chapter:        29,versenumber:        32,verse:"所以耶和华如此说：我必刑罚尼希兰人示玛雅和他的后裔，他必无一人存留住在这民中，也不得见我所要赐与我百姓的福乐，因为他向耶和华说了叛逆的话。这是耶和华说的。’”"},
{volumn:        24,chapter:        30,versenumber:         1,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        30,versenumber:         2,verse:"“耶和华以色列的　神如此说：你将我对你说过的一切话都写在书上。"},
{volumn:        24,chapter:        30,versenumber:         3,verse:"耶和华说：日子将到，我要使我的百姓以色列和犹大被掳的人归回；我也要使他们回到我所赐给他们列祖之地，他们就得这地为业。这是耶和华说的。”"},
{volumn:        24,chapter:        30,versenumber:         4,verse:"以下是耶和华论到以色列和犹大所说的话。"},
{volumn:        24,chapter:        30,versenumber:         5,verse:"耶和华如此说：“我们听见声音，是战抖惧怕而不平安的声音。"},
{volumn:        24,chapter:        30,versenumber:         6,verse:"你们且访问看看，男人有产难吗？我怎么看见人人用手掐腰，像产难的妇人，脸面都变青了呢？"},
{volumn:        24,chapter:        30,versenumber:         7,verse:"哀哉！那日为大，无日可比；这是雅各遭难的时候，但他必被救出来。”"},
{volumn:        24,chapter:        30,versenumber:         8,verse:"万军之耶和华说：“到那日，我必从你颈项上折断仇敌的轭，扭开他的绳索；外邦人不得再使你作他们的奴仆。"},
{volumn:        24,chapter:        30,versenumber:         9,verse:"你们却要侍奉耶和华你们的　神和我为你们所要兴起的王大卫。”"},
{volumn:        24,chapter:        30,versenumber:        10,verse:"故此，耶和华说：“我的仆人雅各啊，不要惧怕；以色列啊，不要惊惶；因我要从远方拯救你，从被掳到之地拯救你的后裔；雅各必回来得享平靖安逸，无人使他害怕。"},
{volumn:        24,chapter:        30,versenumber:        11,verse:"因我与你同在，要拯救你，也要将所赶散你到的那些国灭绝净尽，却不将你灭绝净尽，倒要从宽惩治你，万不能不罚你（不罚你：或译以你为无罪）。这是耶和华说的。”"},
{volumn:        24,chapter:        30,versenumber:        12,verse:"耶和华如此说：“你的损伤无法医治；你的伤痕极其重大。"},
{volumn:        24,chapter:        30,versenumber:        13,verse:"无人为你分诉，使你的伤痕得以缠裹；你没有医治的良药。"},
{volumn:        24,chapter:        30,versenumber:        14,verse:"你所亲爱的都忘记你，不来探问（或译：理会）你。我因你的罪孽甚大，罪恶众多，曾用仇敌加的伤害伤害你，用残忍者的惩治惩治你。"},
{volumn:        24,chapter:        30,versenumber:        15,verse:"你为何因损伤哀号呢？你的痛苦无法医治。我因你的罪孽甚大，罪恶众多，曾将这些加在你身上。"},
{volumn:        24,chapter:        30,versenumber:        16,verse:"故此，凡吞吃你的必被吞吃；你的敌人个个都被掳去；掳掠你的必成为掳物；抢夺你的必成为掠物。"},
{volumn:        24,chapter:        30,versenumber:        17,verse:"耶和华说：我必使你痊愈，医好你的伤痕，都因人称你为被赶散的，说：‘这是锡安，无人来探问（或译：理会）的！’”"},
{volumn:        24,chapter:        30,versenumber:        18,verse:"耶和华如此说：“我必使雅各被掳去的帐棚归回，也必顾惜他的住处。城必建造在原旧的山冈；宫殿也照旧有人居住。"},
{volumn:        24,chapter:        30,versenumber:        19,verse:"必有感谢和欢乐的声音从其中发出，我要使他们增多，不致减少；使他们尊荣，不致卑微。"},
{volumn:        24,chapter:        30,versenumber:        20,verse:"他们的儿女要如往日；他们的会众坚立在我面前；凡欺压他们的，我必刑罚他。"},
{volumn:        24,chapter:        30,versenumber:        21,verse:"他们的君王必是属乎他们的；掌权的必从他们中间而出。我要使他就近我，他也要亲近我；不然，谁有胆量亲近我呢？这是耶和华说的。"},
{volumn:        24,chapter:        30,versenumber:        22,verse:"你们要作我的子民，我要作你们的　神。”"},
{volumn:        24,chapter:        30,versenumber:        23,verse:"看哪，耶和华的忿怒好像暴风已经发出；是扫灭的暴风，必转到恶人的头上。"},
{volumn:        24,chapter:        30,versenumber:        24,verse:"耶和华的烈怒必不转消，直到他心中所拟定的成就了；末后的日子你们要明白。"},
{volumn:        24,chapter:        31,versenumber:         1,verse:"耶和华说：“那时，我必作以色列各家的　神；他们必作我的子民。”"},
{volumn:        24,chapter:        31,versenumber:         2,verse:"耶和华如此说：“脱离刀剑的就是以色列人。我使他享安息的时候，他曾在旷野蒙恩。"},
{volumn:        24,chapter:        31,versenumber:         3,verse:"古时（或译：从远方）耶和华向以色列（原文是我）显现，说：‘我以永远的爱爱你，因此我以慈爱吸引你。’"},
{volumn:        24,chapter:        31,versenumber:         4,verse:"以色列的民（原文是处女）哪，我要再建立你，你就被建立；你必再以击鼓为美，与欢乐的人一同跳舞而出；"},
{volumn:        24,chapter:        31,versenumber:         5,verse:"又必在撒玛利亚的山上栽种葡萄园，栽种的人要享用所结的果子。"},
{volumn:        24,chapter:        31,versenumber:         6,verse:"日子必到，以法莲山上守望的人必呼叫说：‘起来吧！我们可以上锡安，到耶和华我们的　神那里去。’”"},
{volumn:        24,chapter:        31,versenumber:         7,verse:"耶和华如此说：“你们当为雅各欢乐歌唱，因万国中为首的欢呼。当传扬颂赞说：‘耶和华啊，求你拯救你的百姓以色列所剩下的人。’"},
{volumn:        24,chapter:        31,versenumber:         8,verse:"我必将他们从北方领来，从地极招聚；同着他们来的有瞎子、瘸子、孕妇、产妇；他们必成为大帮回到这里来。"},
{volumn:        24,chapter:        31,versenumber:         9,verse:"他们要哭泣而来。我要照他们恳求的引导他们，使他们在河水旁走正直的路，在其上不致绊跌；因为我是以色列的父，以法莲是我的长子。”"},
{volumn:        24,chapter:        31,versenumber:        10,verse:"列国啊，要听耶和华的话，传扬在远处的海岛说：“赶散以色列的必招聚他，又看守他，好像牧人看守羊群。"},
{volumn:        24,chapter:        31,versenumber:        11,verse:"因耶和华救赎了雅各，救赎他脱离比他更强之人的手。"},
{volumn:        24,chapter:        31,versenumber:        12,verse:"他们要来到锡安的高处歌唱，又流归耶和华施恩之地，就是有五谷、新酒，和油，并羊羔、牛犊之地。他们的心必像浇灌的园子；他们也不再有一点愁烦。"},
{volumn:        24,chapter:        31,versenumber:        13,verse:"那时，处女必欢乐跳舞；年少的、年老的，也必一同欢乐；因为我要使他们的悲哀变为欢喜，并要安慰他们，使他们的愁烦转为快乐。"},
{volumn:        24,chapter:        31,versenumber:        14,verse:"我必以肥油使祭司的心满足；我的百姓也要因我的恩惠知足。这是耶和华说的。”"},
{volumn:        24,chapter:        31,versenumber:        15,verse:"耶和华如此说：“在拉玛听见号啕痛哭的声音，是拉结哭她儿女，不肯受安慰，因为他们都不在了。”"},
{volumn:        24,chapter:        31,versenumber:        16,verse:"耶和华如此说：“你禁止声音不要哀哭，禁止眼目不要流泪，因你所做之工必有赏赐；他们必从敌国归回。这是耶和华说的。”"},
{volumn:        24,chapter:        31,versenumber:        17,verse:"耶和华说：“你末后必有指望；你的儿女必回到自己的境界。”"},
{volumn:        24,chapter:        31,versenumber:        18,verse:"我听见以法莲为自己悲叹说：“你责罚我，我便受责罚，像不惯负轭的牛犊一样。求你使我回转，我便回转，因为你是耶和华我的　神。"},
{volumn:        24,chapter:        31,versenumber:        19,verse:"我回转以后就真正懊悔；受教以后就拍腿叹息；我因担当幼年的凌辱就抱愧蒙羞。”"},
{volumn:        24,chapter:        31,versenumber:        20,verse:"耶和华说：“以法莲是我的爱子吗？是可喜悦的孩子吗？我每逢责备他，仍深顾念他；所以我的心肠恋慕他；我必要怜悯他。"},
{volumn:        24,chapter:        31,versenumber:        21,verse:"“以色列民（原文是处女）哪，你当为自己设立指路碑，竖起引路柱。你要留心向大路，就是你所去的原路；你当回转，回转到你这些城邑。"},
{volumn:        24,chapter:        31,versenumber:        22,verse:"背道的民（原文是女子）哪，你反来复去要到几时呢？耶和华在地上造了一件新事，就是女子护卫男子。”"},
{volumn:        24,chapter:        31,versenumber:        23,verse:"万军之耶和华以色列的　神如此说：“我使被掳之人归回的时候，他们在犹大地和其中的城邑必再这样说：‘公义的居所啊，圣山哪，愿耶和华赐福给你。’"},
{volumn:        24,chapter:        31,versenumber:        24,verse:"犹大和属犹大城邑的人，农夫和放羊的人，要一同住在其中。"},
{volumn:        24,chapter:        31,versenumber:        25,verse:"疲乏的人，我使他饱饫；愁烦的人，我使他知足。”"},
{volumn:        24,chapter:        31,versenumber:        26,verse:"先知说：“我醒了，觉着睡得香甜！”"},
{volumn:        24,chapter:        31,versenumber:        27,verse:"耶和华说：“日子将到，我要把人的种和牲畜的种播种在以色列家和犹大家。"},
{volumn:        24,chapter:        31,versenumber:        28,verse:"我先前怎样留意将他们拔出、拆毁、毁坏、倾覆、苦害，也必照样留意将他们建立、栽植。这是耶和华说的。"},
{volumn:        24,chapter:        31,versenumber:        29,verse:"当那些日子，人不再说：‘父亲吃了酸葡萄，儿子的牙酸倒了。’"},
{volumn:        24,chapter:        31,versenumber:        30,verse:"但各人必因自己的罪死亡；凡吃酸葡萄的，自己的牙必酸倒。”"},
{volumn:        24,chapter:        31,versenumber:        31,verse:"耶和华说：“日子将到，我要与以色列家和犹大家另立新约，"},
{volumn:        24,chapter:        31,versenumber:        32,verse:"不像我拉着他们祖宗的手，领他们出埃及地的时候，与他们所立的约。我虽作他们的丈夫，他们却背了我的约。这是耶和华说的。”"},
{volumn:        24,chapter:        31,versenumber:        33,verse:"耶和华说：“那些日子以后，我与以色列家所立的约乃是这样：我要将我的律法放在他们里面，写在他们心上。我要作他们的　神，他们要作我的子民。"},
{volumn:        24,chapter:        31,versenumber:        34,verse:"他们各人不再教导自己的邻舍和自己的弟兄说：‘你该认识耶和华’，因为他们从最小的到至大的都必认识我。我要赦免他们的罪孽，不再记念他们的罪恶。这是耶和华说的。”"},
{volumn:        24,chapter:        31,versenumber:        35,verse:"那使太阳白日发光，使星月有定例，黑夜发亮，又搅动大海，使海中波浪砰訇的，万军之耶和华是他的名。他如此说："},
{volumn:        24,chapter:        31,versenumber:        36,verse:"“这些定例若能在我面前废掉，以色列的后裔也就在我面前断绝，永远不再成国。这是耶和华说的。”"},
{volumn:        24,chapter:        31,versenumber:        37,verse:"耶和华如此说：“若能量度上天，寻察下地的根基，我就因以色列后裔一切所行的弃绝他们。这是耶和华说的。”"},
{volumn:        24,chapter:        31,versenumber:        38,verse:"耶和华说：“日子将到，这城必为耶和华建造，从哈楠业楼直到角门。"},
{volumn:        24,chapter:        31,versenumber:        39,verse:"准绳要往外量出，直到迦立山，又转到歌亚。"},
{volumn:        24,chapter:        31,versenumber:        40,verse:"抛尸的全谷和倒灰之处，并一切田地，直到汲沦溪，又直到东方马门的拐角，都要归耶和华为圣，不再拔出，不再倾覆，直到永远。”"},
{volumn:        24,chapter:        32,versenumber:         1,verse:"犹大王西底家第十年，就是尼布甲尼撒十八年，耶和华的话临到耶利米。"},
{volumn:        24,chapter:        32,versenumber:         2,verse:"那时巴比伦王的军队围困耶路撒冷，先知耶利米囚在护卫兵的院内，在犹大王的宫中；"},
{volumn:        24,chapter:        32,versenumber:         3,verse:"因为犹大王西底家已将他囚禁，说：“你为什么预言说，耶和华如此说：‘我必将这城交在巴比伦王的手中，他必攻取这城。"},
{volumn:        24,chapter:        32,versenumber:         4,verse:"犹大王西底家必不能逃脱迦勒底人的手，定要交在巴比伦王的手中，要口对口彼此说话，眼对眼彼此相看。"},
{volumn:        24,chapter:        32,versenumber:         5,verse:"巴比伦王必将西底家带到巴比伦；西底家必住在那里，直到我眷顾他的时候。你们虽与迦勒底人争战，却不顺利。这是耶和华说的。’”"},
{volumn:        24,chapter:        32,versenumber:         6,verse:"耶利米说：“耶和华的话临到我说："},
{volumn:        24,chapter:        32,versenumber:         7,verse:"‘你叔叔沙龙的儿子哈拿篾必来见你，说：我在亚拿突的那块地，求你买来；因你买这地是合乎赎回之理。’”"},
{volumn:        24,chapter:        32,versenumber:         8,verse:"我叔叔的儿子哈拿篾果然照耶和华的话来到护卫兵的院内，对我说：“我在便雅悯境内、亚拿突的那块地，求你买来；因你买来是合乎承受之理，是你当赎的。你为自己买来吧！”我耶利米就知道这是耶和华的话。"},
{volumn:        24,chapter:        32,versenumber:         9,verse:"我便向我叔叔的儿子哈拿篾买了亚拿突的那块地，平了十七舍客勒银子给他。"},
{volumn:        24,chapter:        32,versenumber:        10,verse:"我在契上画押，将契封缄，又请见证人来，并用天平将银子平给他。"},
{volumn:        24,chapter:        32,versenumber:        11,verse:"我便将照例按规所立的买契，就是封缄的那一张和敞着的那一张，"},
{volumn:        24,chapter:        32,versenumber:        12,verse:"当着我叔叔的儿子哈拿篾和画押作见证的人，并坐在护卫兵院内的一切犹大人眼前，交给玛西雅的孙子尼利亚的儿子巴录。"},
{volumn:        24,chapter:        32,versenumber:        13,verse:"当着他们众人眼前，我嘱咐巴录说："},
{volumn:        24,chapter:        32,versenumber:        14,verse:"“万军之耶和华以色列的　神如此说：要将这封缄的和敞着的两张契放在瓦器里，可以存留多日。"},
{volumn:        24,chapter:        32,versenumber:        15,verse:"因为万军之耶和华以色列的　神如此说：将来在这地必有人再买房屋、田地，和葡萄园。”"},
{volumn:        24,chapter:        32,versenumber:        16,verse:"我将买契交给尼利亚的儿子巴录以后，便祷告耶和华说："},
{volumn:        24,chapter:        32,versenumber:        17,verse:"“主耶和华啊，你曾用大能和伸出来的膀臂创造天地，在你没有难成的事。"},
{volumn:        24,chapter:        32,versenumber:        18,verse:"你施慈爱与千万人，又将父亲的罪孽报应在他后世子孙的怀中，是至大全能的　神，万军之耶和华是你的名。"},
{volumn:        24,chapter:        32,versenumber:        19,verse:"谋事有大略，行事有大能，注目观看世人一切的举动，为要照各人所行的和他做事的结果报应他。"},
{volumn:        24,chapter:        32,versenumber:        20,verse:"在埃及地显神迹奇事，直到今日在以色列和别人中间也是如此，使自己得了名声，正如今日一样。"},
{volumn:        24,chapter:        32,versenumber:        21,verse:"用神迹奇事和大能的手，并伸出来的膀臂与大可畏的事，领你的百姓以色列出了埃及。"},
{volumn:        24,chapter:        32,versenumber:        22,verse:"将这地赐给他们，就是你向他们列祖起誓应许赐给他们流奶与蜜之地。"},
{volumn:        24,chapter:        32,versenumber:        23,verse:"他们进入这地得了为业，却不听从你的话，也不遵行你的律法；你一切所吩咐他们行的，他们一无所行，因此你使这一切的灾祸临到他们。"},
{volumn:        24,chapter:        32,versenumber:        24,verse:"看哪，敌人已经来到，筑垒要攻取这城；城也因刀剑、饥荒、瘟疫交在攻城的迦勒底人手中。你所说的话都成就了，你也看见了。"},
{volumn:        24,chapter:        32,versenumber:        25,verse:"主耶和华啊，你对我说：要用银子为自己买那块地，又请见证人。其实这城已交在迦勒底人的手中了。”"},
{volumn:        24,chapter:        32,versenumber:        26,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        32,versenumber:        27,verse:"“我是耶和华，是凡有血气者的　神，岂有我难成的事吗？"},
{volumn:        24,chapter:        32,versenumber:        28,verse:"耶和华如此说：我必将这城交付迦勒底人的手和巴比伦王尼布甲尼撒的手，他必攻取这城。"},
{volumn:        24,chapter:        32,versenumber:        29,verse:"攻城的迦勒底人必来放火焚烧这城和其中的房屋。在这房屋上，人曾向巴力烧香，向别神浇奠，惹我发怒。"},
{volumn:        24,chapter:        32,versenumber:        30,verse:"以色列人和犹大人自从幼年以来，专行我眼中看为恶的事；以色列人尽以手所做的惹我发怒。这是耶和华说的。"},
{volumn:        24,chapter:        32,versenumber:        31,verse:"这城自从建造的那日直到今日，常惹我的怒气和忿怒，使我将这城从我面前除掉；"},
{volumn:        24,chapter:        32,versenumber:        32,verse:"是因以色列人和犹大人一切的邪恶，就是他们和他们的君王、首领、祭司、先知，并犹大的众人，以及耶路撒冷的居民所行的，惹我发怒。"},
{volumn:        24,chapter:        32,versenumber:        33,verse:"他们以背向我，不以面向我；我虽从早起来教训他们，他们却不听从，不受教训，"},
{volumn:        24,chapter:        32,versenumber:        34,verse:"竟把可憎之物设立在称为我名下的殿中，污秽了这殿。"},
{volumn:        24,chapter:        32,versenumber:        35,verse:"他们在欣嫩子谷建筑巴力的邱坛，好使自己的儿女经火归摩洛；他们行这可憎的事，使犹大陷在罪里，这并不是我所吩咐的，也不是我心所起的意。”"},
{volumn:        24,chapter:        32,versenumber:        36,verse:"现在论到这城，就是你们所说、已经因刀剑、饥荒、瘟疫交在巴比伦王手中的，耶和华以色列的　神如此说："},
{volumn:        24,chapter:        32,versenumber:        37,verse:"“我在怒气、忿怒，和大恼恨中，将以色列人赶到各国。日后我必从那里将他们招聚出来，领他们回到此地，使他们安然居住。"},
{volumn:        24,chapter:        32,versenumber:        38,verse:"他们要作我的子民，我要作他们的　神。"},
{volumn:        24,chapter:        32,versenumber:        39,verse:"我要使他们彼此同心同道，好叫他们永远敬畏我，使他们和他们后世的子孙得福乐，"},
{volumn:        24,chapter:        32,versenumber:        40,verse:"又要与他们立永远的约，必随着他们施恩，并不离开他们，且使他们有敬畏我的心，不离开我。"},
{volumn:        24,chapter:        32,versenumber:        41,verse:"我必欢喜施恩与他们，要尽心尽意、诚诚实实将他们栽于此地。"},
{volumn:        24,chapter:        32,versenumber:        42,verse:"“因为耶和华如此说：我怎样使这一切大祸临到这百姓，我也要照样使我所应许他们的一切福乐都临到他们。"},
{volumn:        24,chapter:        32,versenumber:        43,verse:"你们说，这地是荒凉、无人民、无牲畜，是交付迦勒底人手之地。日后在这境内，必有人置买田地。"},
{volumn:        24,chapter:        32,versenumber:        44,verse:"在便雅悯地、耶路撒冷四围的各处、犹大的城邑、山地的城邑、高原的城邑，并南地的城邑，人必用银子买田地，在契上画押，将契封缄，请出见证人，因为我必使被掳的人归回。这是耶和华说的。”"},
{volumn:        24,chapter:        33,versenumber:         1,verse:"耶利米还囚在护卫兵的院内，耶和华的话第二次临到他说："},
{volumn:        24,chapter:        33,versenumber:         2,verse:"“成就的是耶和华，造作、为要建立的也是耶和华；耶和华是他的名。他如此说："},
{volumn:        24,chapter:        33,versenumber:         3,verse:"你求告我，我就应允你，并将你所不知道、又大又难的事指示你。"},
{volumn:        24,chapter:        33,versenumber:         4,verse:"论到这城中的房屋和犹大王的宫室，就是拆毁为挡敌人高垒和刀剑的，耶和华以色列的　神如此说："},
{volumn:        24,chapter:        33,versenumber:         5,verse:"人要与迦勒底人争战，正是拿死尸充满这房屋，就是我在怒气和忿怒中所杀的人，因他们的一切恶，我就掩面不顾这城。"},
{volumn:        24,chapter:        33,versenumber:         6,verse:"看哪，我要使这城得以痊愈安舒，使城中的人得医治，又将丰盛的平安和诚实显明与他们。"},
{volumn:        24,chapter:        33,versenumber:         7,verse:"我也要使犹大被掳的和以色列被掳的归回，并建立他们和起初一样。"},
{volumn:        24,chapter:        33,versenumber:         8,verse:"我要除净他们的一切罪，就是向我所犯的罪；又要赦免他们的一切罪，就是干犯我、违背我的罪。"},
{volumn:        24,chapter:        33,versenumber:         9,verse:"这城要在地上万国人面前使我得颂赞，得荣耀，名为可喜可乐之城。万国人因听见我向这城所赐的福乐、所施的恩惠平安，就惧怕战兢。”"},
{volumn:        24,chapter:        33,versenumber:        10,verse:"耶和华如此说：“你们论这地方，说是荒废无人民无牲畜之地，但在这荒凉无人民无牲畜的犹大城邑和耶路撒冷的街上，"},
{volumn:        24,chapter:        33,versenumber:        11,verse:"必再听见有欢喜和快乐的声音、新郎和新妇的声音，并听见有人说：‘要称谢万军之耶和华，因耶和华本为善；他的慈爱永远长存！’又有奉感谢祭到耶和华殿中之人的声音；因为我必使这地被掳的人归回，和起初一样。这是耶和华说的。”"},
{volumn:        24,chapter:        33,versenumber:        12,verse:"万军之耶和华如此说：“在这荒废无人民无牲畜之地，并其中所有的城邑，必再有牧人的住处；他们要使羊群躺卧在那里。"},
{volumn:        24,chapter:        33,versenumber:        13,verse:"在山地的城邑、高原的城邑、南地的城邑、便雅悯地、耶路撒冷四围的各处，和犹大的城邑必再有羊群从数点的人手下经过。这是耶和华说的。”"},
{volumn:        24,chapter:        33,versenumber:        14,verse:"耶和华说：“日子将到，我应许以色列家和犹大家的恩言必然成就。"},
{volumn:        24,chapter:        33,versenumber:        15,verse:"当那日子，那时候，我必使大卫公义的苗裔长起来；他必在地上施行公平和公义。"},
{volumn:        24,chapter:        33,versenumber:        16,verse:"在那日子犹大必得救，耶路撒冷必安然居住，他的名必称为‘耶和华我们的义’。"},
{volumn:        24,chapter:        33,versenumber:        17,verse:"“因为耶和华如此说：大卫必永不断人坐在以色列家的宝座上；"},
{volumn:        24,chapter:        33,versenumber:        18,verse:"祭司、利未人在我面前也不断人献燔祭、烧素祭，时常办理献祭的事。”"},
{volumn:        24,chapter:        33,versenumber:        19,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        33,versenumber:        20,verse:"“耶和华如此说：你们若能废弃我所立白日黑夜的约，使白日黑夜不按时轮转，"},
{volumn:        24,chapter:        33,versenumber:        21,verse:"就能废弃我与我仆人大卫所立的约，使他没有儿子在他的宝座上为王，并能废弃我与侍奉我的祭司、利未人所立的约。"},
{volumn:        24,chapter:        33,versenumber:        22,verse:"天上的万象不能数算，海边的尘沙也不能斗量；我必照样使我仆人大卫的后裔和侍奉我的利未人多起来。”"},
{volumn:        24,chapter:        33,versenumber:        23,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        33,versenumber:        24,verse:"“你没有揣摩这百姓的话吗？他们说：‘耶和华所拣选的二族，他已经弃绝了。’他们这样藐视我的百姓，以为不再成国。"},
{volumn:        24,chapter:        33,versenumber:        25,verse:"耶和华如此说：‘若是我立白日黑夜的约不能存住，若是我未曾安排天地的定例，"},
{volumn:        24,chapter:        33,versenumber:        26,verse:"我就弃绝雅各的后裔和我仆人大卫的后裔，不使大卫的后裔治理亚伯拉罕、以撒、雅各的后裔；因为我必使他们被掳的人归回，也必怜悯他们。’”"},
{volumn:        24,chapter:        34,versenumber:         1,verse:"巴比伦王尼布甲尼撒率领他的全军和地上属他的各国各邦，攻打耶路撒冷和属耶路撒冷所有的城邑。那时，耶和华的话临到耶利米说："},
{volumn:        24,chapter:        34,versenumber:         2,verse:"“耶和华以色列的　神说，你去告诉犹大王西底家，耶和华如此说：‘我要将这城交付巴比伦王的手，他必用火焚烧。"},
{volumn:        24,chapter:        34,versenumber:         3,verse:"你必不能逃脱他的手，定被拿住，交在他的手中。你的眼要见巴比伦王的眼，他要口对口和你说话，你也必到巴比伦去。’"},
{volumn:        24,chapter:        34,versenumber:         4,verse:"犹大王西底家啊，你还要听耶和华的话。耶和华论到你如此说：‘你必不被刀剑杀死，"},
{volumn:        24,chapter:        34,versenumber:         5,verse:"你必平安而死，人必为你焚烧物件，好像为你列祖，就是在你以前的先王焚烧一般。人必为你举哀说：哀哉！我主啊。耶和华说，这话是我说的。’”"},
{volumn:        24,chapter:        34,versenumber:         6,verse:"于是，先知耶利米在耶路撒冷将这一切话告诉犹大王西底家。"},
{volumn:        24,chapter:        34,versenumber:         7,verse:"那时，巴比伦王的军队正攻打耶路撒冷，又攻打犹大所剩下的城邑，就是拉吉和亚西加。原来犹大的坚固城只剩下这两座。"},
{volumn:        24,chapter:        34,versenumber:         8,verse:"西底家王与耶路撒冷的众民立约，要向他们宣告自由，叫各人任他希伯来的仆人和婢女自由出去，谁也不可使他的一个犹大弟兄作奴仆。（此后，有耶和华的话临到耶利米。）"},
{volumn:        24,chapter:        34,versenumber:         9,verse:""},
{volumn:        24,chapter:        34,versenumber:        10,verse:"所有立约的首领和众民就任他的仆人婢女自由出去，谁也不再叫他们作奴仆。大家都顺从，将他们释放了；"},
{volumn:        24,chapter:        34,versenumber:        11,verse:"后来却又反悔，叫所任去自由的仆人婢女回来，勉强他们仍为仆婢。"},
{volumn:        24,chapter:        34,versenumber:        12,verse:"因此耶和华的话临到耶利米说："},
{volumn:        24,chapter:        34,versenumber:        13,verse:"“耶和华以色列的　神如此说：‘我将你们的列祖从埃及地为奴之家领出来的时候，与他们立约说："},
{volumn:        24,chapter:        34,versenumber:        14,verse:"‘你的一个希伯来弟兄若卖给你，服侍你六年，到第七年你们各人就要任他自由出去。’只是你们列祖不听从我，也不侧耳而听。"},
{volumn:        24,chapter:        34,versenumber:        15,verse:"如今你们回转，行我眼中看为正的事，各人向邻舍宣告自由，并且在称为我名下的殿中、在我面前立约。"},
{volumn:        24,chapter:        34,versenumber:        16,verse:"你们却又反悔，亵渎我的名，各人叫所任去随意自由的仆人婢女回来，勉强他们仍为仆婢。’"},
{volumn:        24,chapter:        34,versenumber:        17,verse:"所以耶和华如此说：‘你们没有听从我，各人向弟兄邻舍宣告自由。看哪！我向你们宣告一样自由，就是使你们自由于刀剑、饥荒、瘟疫之下，并且使你们在天下万国中抛来抛去。这是耶和华说的。"},
{volumn:        24,chapter:        34,versenumber:        18,verse:"犹大的首领、耶路撒冷的首领、太监、祭司，和国中的众民曾将牛犊劈开，分成两半，从其中经过，在我面前立约。后来又违背我的约，不遵行这约上的话。"},
{volumn:        24,chapter:        34,versenumber:        19,verse:""},
{volumn:        24,chapter:        34,versenumber:        20,verse:"我必将他们交在仇敌和寻索其命的人手中；他们的尸首必给空中的飞鸟和地上的野兽作食物。"},
{volumn:        24,chapter:        34,versenumber:        21,verse:"并且我必将犹大王西底家和他的首领交在他们仇敌和寻索其命的人，与那暂离你们而去巴比伦王军队的手中。’"},
{volumn:        24,chapter:        34,versenumber:        22,verse:"耶和华说：‘我必吩咐他们回到这城，攻打这城，将城攻取，用火焚烧。我也要使犹大的城邑变为荒场，无人居住。’”"},
{volumn:        24,chapter:        35,versenumber:         1,verse:"当犹大王约西亚之子约雅敬的时候，耶和华的话临到耶利米说："},
{volumn:        24,chapter:        35,versenumber:         2,verse:"“你去见利甲族的人，和他们说话，领他们进入耶和华殿的一间屋子，给他们酒喝。”"},
{volumn:        24,chapter:        35,versenumber:         3,verse:"我就将哈巴洗尼雅的孙子雅利米雅的儿子雅撒尼亚和他弟兄，并他众子，以及利甲全族的人，"},
{volumn:        24,chapter:        35,versenumber:         4,verse:"领到耶和华的殿，进入神人伊基大利的儿子哈难众子的屋子。那屋子在首领的屋子旁边，在沙龙之子把门的玛西雅屋子以上。"},
{volumn:        24,chapter:        35,versenumber:         5,verse:"于是我在利甲族人面前设摆盛满酒的碗和杯，对他们说：“请你们喝酒。”"},
{volumn:        24,chapter:        35,versenumber:         6,verse:"他们却说：“我们不喝酒；因为我们先祖利甲的儿子约拿达曾吩咐我们说：‘你们与你们的子孙永不可喝酒，"},
{volumn:        24,chapter:        35,versenumber:         7,verse:"也不可盖房、撒种、栽种葡萄园，但一生的年日要住帐棚，使你们的日子在寄居之地得以延长。’"},
{volumn:        24,chapter:        35,versenumber:         8,verse:"凡我们先祖利甲的儿子约拿达所吩咐我们的话，我们都听从了。我们和我们的妻子儿女一生的年日都不喝酒，"},
{volumn:        24,chapter:        35,versenumber:         9,verse:"也不盖房居住，也没有葡萄园、田地，和种子，"},
{volumn:        24,chapter:        35,versenumber:        10,verse:"但住帐棚，听从我们先祖约拿达的话，照他所吩咐我们的去行。"},
{volumn:        24,chapter:        35,versenumber:        11,verse:"巴比伦王尼布甲尼撒上此地来，我们因怕迦勒底的军队和亚兰的军队，就说：‘来吧，我们到耶路撒冷去。’这样，我们才住在耶路撒冷。”"},
{volumn:        24,chapter:        35,versenumber:        12,verse:"耶和华的话临到耶利米说："},
{volumn:        24,chapter:        35,versenumber:        13,verse:"“万军之耶和华以色列的　神如此说：你去对犹大人和耶路撒冷的居民说：‘耶和华说：你们不受教训，不听从我的话吗？"},
{volumn:        24,chapter:        35,versenumber:        14,verse:"利甲的儿子约拿达所吩咐他子孙不可喝酒的话，他们已经遵守，直到今日也不喝酒，因为他们听从先祖的吩咐。我从早起来警戒你们，你们却不听从我。"},
{volumn:        24,chapter:        35,versenumber:        15,verse:"我从早起来，差遣我的仆人众先知去，说：你们各人当回头，离开恶道，改正行为，不随从侍奉别神，就必住在我所赐给你们和你们列祖的地上。只是你们没有听从我，也没有侧耳而听。"},
{volumn:        24,chapter:        35,versenumber:        16,verse:"利甲的儿子约拿达的子孙能遵守先人所吩咐他们的命，这百姓却没有听从我！"},
{volumn:        24,chapter:        35,versenumber:        17,verse:"因此，耶和华万军之　神、以色列的　神如此说：我要使我所说的一切灾祸临到犹大人和耶路撒冷的一切居民。因为我对他们说话，他们没有听从；我呼唤他们，他们没有答应。’”"},
{volumn:        24,chapter:        35,versenumber:        18,verse:"耶利米对利甲族的人说：“万军之耶和华以色列的　神如此说：因你们听从你们先祖约拿达的吩咐，谨守他的一切诫命，照他所吩咐你们的去行，"},
{volumn:        24,chapter:        35,versenumber:        19,verse:"所以万军之耶和华以色列的　神如此说：利甲的儿子约拿达必永不缺人侍立在我面前。”"},
{volumn:        24,chapter:        36,versenumber:         1,verse:"犹大王约西亚的儿子约雅敬第四年，耶和华的话临到耶利米说："},
{volumn:        24,chapter:        36,versenumber:         2,verse:"“你取一书卷，将我对你说攻击以色列和犹大，并各国的一切话，从我对你说话的那日，就是从约西亚的日子起直到今日，都写在其上。"},
{volumn:        24,chapter:        36,versenumber:         3,verse:"或者犹大家听见我想要降与他们的一切灾祸，各人就回头，离开恶道，我好赦免他们的罪孽和罪恶。”"},
{volumn:        24,chapter:        36,versenumber:         4,verse:"所以，耶利米召了尼利亚的儿子巴录来；巴录就从耶利米口中，将耶和华对耶利米所说的一切话写在书卷上。"},
{volumn:        24,chapter:        36,versenumber:         5,verse:"耶利米吩咐巴录说：“我被拘管，不能进耶和华的殿。"},
{volumn:        24,chapter:        36,versenumber:         6,verse:"所以你要去趁禁食的日子，在耶和华殿中将耶和华的话，就是你从我口中所写在书卷上的话，念给百姓和一切从犹大城邑出来的人听。"},
{volumn:        24,chapter:        36,versenumber:         7,verse:"或者他们在耶和华面前恳求各人回头，离开恶道，因为耶和华向这百姓所说要发的怒气和忿怒是大的。”"},
{volumn:        24,chapter:        36,versenumber:         8,verse:"尼利亚的儿子巴录就照先知耶利米一切所吩咐的去行，在耶和华的殿中从书上念耶和华的话。"},
{volumn:        24,chapter:        36,versenumber:         9,verse:"犹大王约西亚的儿子约雅敬第五年九月，耶路撒冷的众民和那从犹大城邑来到耶路撒冷的众民，在耶和华面前宣告禁食的日子，"},
{volumn:        24,chapter:        36,versenumber:        10,verse:"巴录就在耶和华殿的上院，耶和华殿的新门口，沙番的儿子文士基玛利雅的屋内，念书上耶利米的话给众民听。"},
{volumn:        24,chapter:        36,versenumber:        11,verse:"沙番的孙子、基玛利雅的儿子米该亚听见书上耶和华的一切话，"},
{volumn:        24,chapter:        36,versenumber:        12,verse:"他就下到王宫，进入文士的屋子。众首领，就是文士以利沙玛、示玛雅的儿子第莱雅、亚革波的儿子以利拿单、沙番的儿子基玛利雅、哈拿尼雅的儿子西底家，和其余的首领都坐在那里。"},
{volumn:        24,chapter:        36,versenumber:        13,verse:"于是米该亚对他们述说他所听见的一切话，就是巴录向百姓念那书的时候所听见的。"},
{volumn:        24,chapter:        36,versenumber:        14,verse:"众首领就打发古示的曾孙、示利米雅的孙子、尼探雅的儿子犹底到巴录那里，对他说：“你将所念给百姓听的书卷拿在手中到我们这里来。”尼利亚的儿子巴录就手拿书卷来到他们那里。"},
{volumn:        24,chapter:        36,versenumber:        15,verse:"他们对他说：“请你坐下，念给我们听。”巴录就念给他们听。"},
{volumn:        24,chapter:        36,versenumber:        16,verse:"他们听见这一切话就害怕，面面相观，对巴录说：“我们必须将这一切话告诉王。”"},
{volumn:        24,chapter:        36,versenumber:        17,verse:"他们问巴录说：“请你告诉我们，你怎样从他口中写这一切话呢？”"},
{volumn:        24,chapter:        36,versenumber:        18,verse:"巴录回答说：“他用口向我说这一切话，我就用笔墨写在书上。”"},
{volumn:        24,chapter:        36,versenumber:        19,verse:"众首领对巴录说：“你和耶利米要去藏起来，不可叫人知道你们在哪里。”"},
{volumn:        24,chapter:        36,versenumber:        20,verse:"众首领进院见王，却先把书卷存在文士以利沙玛的屋内，以后将这一切话说给王听。"},
{volumn:        24,chapter:        36,versenumber:        21,verse:"王就打发犹底去拿这书卷来，他便从文士以利沙玛的屋内取来，念给王和王左右侍立的众首领听。"},
{volumn:        24,chapter:        36,versenumber:        22,verse:"那时正是九月，王坐在过冬的房屋里，王的前面火盆中有烧着的火。"},
{volumn:        24,chapter:        36,versenumber:        23,verse:"犹底念了三四篇（或译：行），王就用文士的刀将书卷割破，扔在火盆中，直到全卷在火中烧尽了。"},
{volumn:        24,chapter:        36,versenumber:        24,verse:"王和听见这一切话的臣仆都不惧怕，也不撕裂衣服。"},
{volumn:        24,chapter:        36,versenumber:        25,verse:"以利拿单和第莱雅，并基玛利雅恳求王不要烧这书卷，他却不听。"},
{volumn:        24,chapter:        36,versenumber:        26,verse:"王就吩咐哈米勒的儿子（或译：王的儿子）耶拉篾和亚斯列的儿子西莱雅，并亚伯叠的儿子示利米雅，去捉拿文士巴录和先知耶利米。耶和华却将他们隐藏。"},
{volumn:        24,chapter:        36,versenumber:        27,verse:"王烧了书卷（其上有巴录从耶利米口中所写的话）以后，耶和华的话临到耶利米说："},
{volumn:        24,chapter:        36,versenumber:        28,verse:"“你再取一卷，将犹大王约雅敬所烧第一卷上的一切话写在其上。"},
{volumn:        24,chapter:        36,versenumber:        29,verse:"论到犹大王约雅敬你要说，耶和华如此说：你烧了书卷，说：‘你为什么在其上写着，说巴比伦王必要来毁灭这地，使这地上绝了人民牲畜呢？’"},
{volumn:        24,chapter:        36,versenumber:        30,verse:"所以耶和华论到犹大王约雅敬说：他后裔中必没有人坐在大卫的宝座上；他的尸首必被抛弃，白日受炎热，黑夜受寒霜。"},
{volumn:        24,chapter:        36,versenumber:        31,verse:"我必因他和他后裔，并他臣仆的罪孽刑罚他们。我要使我所说的一切灾祸临到他们和耶路撒冷的居民，并犹大人；只是他们不听。”"},
{volumn:        24,chapter:        36,versenumber:        32,verse:"于是，耶利米又取一书卷交给尼利亚的儿子文士巴录，他就从耶利米的口中写了犹大王约雅敬所烧前卷上的一切话，另外又添了许多相仿的话。"},
{volumn:        24,chapter:        37,versenumber:         1,verse:"约西亚的儿子西底家代替约雅敬的儿子哥尼雅为王，是巴比伦王尼布甲尼撒立在犹大地作王的。"},
{volumn:        24,chapter:        37,versenumber:         2,verse:"但西底家和他的臣仆，并国中的百姓，都不听从耶和华藉先知耶利米所说的话。"},
{volumn:        24,chapter:        37,versenumber:         3,verse:"西底家王打发示利米雅的儿子犹甲和祭司玛西雅的儿子西番雅去见先知耶利米，说：“求你为我们祷告耶和华我们的　神。”"},
{volumn:        24,chapter:        37,versenumber:         4,verse:"那时耶利米在民中出入，因为他们还没有把他囚在监里。"},
{volumn:        24,chapter:        37,versenumber:         5,verse:"法老的军队已经从埃及出来，那围困耶路撒冷的迦勒底人听见他们的风声，就拔营离开耶路撒冷去了。"},
{volumn:        24,chapter:        37,versenumber:         6,verse:"耶和华的话临到先知耶利米说："},
{volumn:        24,chapter:        37,versenumber:         7,verse:"“耶和华以色列的　神如此说：犹大王打发你们来求问我，你们要如此对他说：‘那出来帮助你们法老的军队必回埃及本国去。"},
{volumn:        24,chapter:        37,versenumber:         8,verse:"迦勒底人必再来攻打这城，并要攻取，用火焚烧。"},
{volumn:        24,chapter:        37,versenumber:         9,verse:"耶和华如此说：你们不要自欺说“迦勒底人必定离开我们”，因为他们必不离开。"},
{volumn:        24,chapter:        37,versenumber:        10,verse:"你们即便杀败了与你们争战的迦勒底全军，但剩下受伤的人也必各人从帐棚里起来，用火焚烧这城。’”"},
{volumn:        24,chapter:        37,versenumber:        11,verse:"迦勒底的军队因怕法老的军队，拔营离开耶路撒冷的时候，"},
{volumn:        24,chapter:        37,versenumber:        12,verse:"耶利米就杂在民中出离耶路撒冷，要往便雅悯地去，在那里得自己的地业。"},
{volumn:        24,chapter:        37,versenumber:        13,verse:"他到了便雅悯门那里，有守门官名叫伊利雅，是哈拿尼亚的孙子、示利米雅的儿子，他就拿住先知耶利米，说：“你是投降迦勒底人哪！”"},
{volumn:        24,chapter:        37,versenumber:        14,verse:"耶利米说：“你这是谎话，我并不是投降迦勒底人。”伊利雅不听他的话，就拿住他，解到首领那里。"},
{volumn:        24,chapter:        37,versenumber:        15,verse:"首领恼怒耶利米，就打了他，将他囚在文士约拿单的房屋中，因为他们以这房屋当作监牢。"},
{volumn:        24,chapter:        37,versenumber:        16,verse:"耶利米来到狱中，进入牢房，在那里囚了多日。"},
{volumn:        24,chapter:        37,versenumber:        17,verse:"西底家王打发人提出他来，在自己的宫内私下问他说：“从耶和华有什么话临到没有？”耶利米说：“有！”又说：“你必交在巴比伦王手中。”"},
{volumn:        24,chapter:        37,versenumber:        18,verse:"耶利米又对西底家王说：“我在什么事上得罪你，或你的臣仆，或这百姓，你竟将我囚在监里呢？"},
{volumn:        24,chapter:        37,versenumber:        19,verse:"对你们预言巴比伦王必不来攻击你们和这地的先知，现今在哪里呢？"},
{volumn:        24,chapter:        37,versenumber:        20,verse:"主我的王啊，求你现在垂听，准我在你面前的恳求：不要使我回到文士约拿单的房屋中，免得我死在那里。”"},
{volumn:        24,chapter:        37,versenumber:        21,verse:"于是，西底家王下令，他们就把耶利米交在护卫兵的院中，每天从饼铺街取一个饼给他，直到城中的饼用尽了。这样，耶利米仍在护卫兵的院中。"},
{volumn:        24,chapter:        38,versenumber:         1,verse:"玛坦的儿子示法提雅、巴施户珥的儿子基大利、示利米雅的儿子犹甲、玛基雅的儿子巴施户珥听见耶利米对众人所说的话，说："},
{volumn:        24,chapter:        38,versenumber:         2,verse:"“耶和华如此说：住在这城里的必遭刀剑、饥荒、瘟疫而死；但出去归降迦勒底人的必得存活，就是以自己命为掠物的，必得存活。"},
{volumn:        24,chapter:        38,versenumber:         3,verse:"耶和华如此说：这城必要交在巴比伦王军队的手中，他必攻取这城。”"},
{volumn:        24,chapter:        38,versenumber:         4,verse:"于是首领对王说：“求你将这人治死；因他向城里剩下的兵丁和众民说这样的话，使他们的手发软。这人不是求这百姓得平安，乃是叫他们受灾祸。”"},
{volumn:        24,chapter:        38,versenumber:         5,verse:"西底家王说：“他在你们手中，无论何事，王也不能与你们反对。”"},
{volumn:        24,chapter:        38,versenumber:         6,verse:"他们就拿住耶利米，下在哈米勒的儿子（或译：王的儿子）玛基雅的牢狱里；那牢狱在护卫兵的院中。他们用绳子将耶利米系下去。牢狱里没有水，只有淤泥，耶利米就陷在淤泥中。"},
{volumn:        24,chapter:        38,versenumber:         7,verse:"在王宫的太监古实人以伯·米勒，听见他们将耶利米下了牢狱（那时王坐在便雅悯门口），"},
{volumn:        24,chapter:        38,versenumber:         8,verse:"以伯·米勒就从王宫里出来，对王说："},
{volumn:        24,chapter:        38,versenumber:         9,verse:"“主我的王啊，这些人向先知耶利米一味地行恶，将他下在牢狱中；他在那里必因饥饿而死，因为城中再没有粮食。”"},
{volumn:        24,chapter:        38,versenumber:        10,verse:"王就吩咐古实人以伯·米勒说：“你从这里带领三十人，趁着先知耶利米未死以前，将他从牢狱中提上来。”"},
{volumn:        24,chapter:        38,versenumber:        11,verse:"于是以伯·米勒带领这些人同去，进入王宫，到库房以下，从那里取了些碎布和破烂的衣服，用绳子缒下牢狱去到耶利米那里。"},
{volumn:        24,chapter:        38,versenumber:        12,verse:"古实人以伯·米勒对耶利米说：“你用这些碎布和破烂的衣服放在绳子上，垫你的胳肢窝。”耶利米就照样行了。"},
{volumn:        24,chapter:        38,versenumber:        13,verse:"这样，他们用绳子将耶利米从牢狱里拉上来。耶利米仍在护卫兵的院中。"},
{volumn:        24,chapter:        38,versenumber:        14,verse:"西底家王打发人带领先知耶利米，进耶和华殿中第三门里见王。王就对耶利米说：“我要问你一件事，你丝毫不可向我隐瞒。”"},
{volumn:        24,chapter:        38,versenumber:        15,verse:"耶利米对西底家说：“我若告诉你，你岂不定要杀我吗？我若劝戒你，你必不听从我。”"},
{volumn:        24,chapter:        38,versenumber:        16,verse:"西底家王就私下向耶利米说：“我指着那造我们生命之永生的耶和华起誓：我必不杀你，也不将你交在寻索你命的人手中。”"},
{volumn:        24,chapter:        38,versenumber:        17,verse:"耶利米对西底家说：“耶和华万军之　神、以色列的　神如此说：你若出去归降巴比伦王的首领，你的命就必存活，这城也不致被火焚烧，你和你的全家都必存活。"},
{volumn:        24,chapter:        38,versenumber:        18,verse:"你若不出去归降巴比伦王的首领，这城必交在迦勒底人手中。他们必用火焚烧，你也不得脱离他们的手。”"},
{volumn:        24,chapter:        38,versenumber:        19,verse:"西底家王对耶利米说：“我怕那些投降迦勒底人的犹大人，恐怕迦勒底人将我交在他们手中，他们戏弄我。”"},
{volumn:        24,chapter:        38,versenumber:        20,verse:"耶利米说：“迦勒底人必不将你交出。求你听从我对你所说耶和华的话，这样你必得好处，你的命也必存活。"},
{volumn:        24,chapter:        38,versenumber:        21,verse:"你若不肯出去，耶和华指示我的话乃是这样："},
{volumn:        24,chapter:        38,versenumber:        22,verse:"‘犹大王宫里所剩的妇女必都带到巴比伦王的首领那里。这些妇女必说：你知己的朋友催逼你，胜过你；见你的脚陷入淤泥中，就转身退后了。"},
{volumn:        24,chapter:        38,versenumber:        23,verse:"人必将你的后妃和你的儿女带到迦勒底人那里；你也不得脱离他们的手，必被巴比伦王的手捉住；你也必使这城被火焚烧。’”"},
{volumn:        24,chapter:        38,versenumber:        24,verse:"西底家对耶利米说：“不要使人知道这些话，你就不至于死。"},
{volumn:        24,chapter:        38,versenumber:        25,verse:"首领若听见了我与你说话，就来见你，问你说：‘你对王说什么话不要向我们隐瞒，我们就不杀你。王向你说什么话也要告诉我们。’"},
{volumn:        24,chapter:        38,versenumber:        26,verse:"你就对他们说：‘我在王面前恳求不要叫我回到约拿单的房屋死在那里。’”"},
{volumn:        24,chapter:        38,versenumber:        27,verse:"随后众首领来见耶利米，问他，他就照王所吩咐的一切话回答他们。他们不再与他说话，因为事情没有泄漏。"},
{volumn:        24,chapter:        38,versenumber:        28,verse:"于是耶利米仍在护卫兵的院中，直到耶路撒冷被攻取的日子。"},
{volumn:        24,chapter:        39,versenumber:         1,verse:"犹大王西底家第九年十月，巴比伦王尼布甲尼撒率领全军来围困耶路撒冷。"},
{volumn:        24,chapter:        39,versenumber:         2,verse:"西底家十一年四月初九日，城被攻破。"},
{volumn:        24,chapter:        39,versenumber:         3,verse:"耶路撒冷被攻取的时候，巴比伦王的首领尼甲·沙利薛、三甲·尼波、撒西金、拉撒力、尼甲·沙利薛、拉墨，并巴比伦王其余的一切首领都来坐在中门。"},
{volumn:        24,chapter:        39,versenumber:         4,verse:"犹大王西底家和一切兵丁看见他们，就在夜间从靠近王园两城中间的门出城逃跑，往亚拉巴逃去。"},
{volumn:        24,chapter:        39,versenumber:         5,verse:"迦勒底的军队追赶他们，在耶利哥的平原追上西底家，将他拿住，带到哈马地的利比拉、巴比伦王尼布甲尼撒那里；尼布甲尼撒就审判他。"},
{volumn:        24,chapter:        39,versenumber:         6,verse:"巴比伦王在利比拉、西底家眼前杀了他的众子，又杀了犹大的一切贵胄，"},
{volumn:        24,chapter:        39,versenumber:         7,verse:"并且剜西底家的眼睛，用铜链锁着他，要带到巴比伦去。"},
{volumn:        24,chapter:        39,versenumber:         8,verse:"迦勒底人用火焚烧王宫和百姓的房屋，又拆毁耶路撒冷的城墙。"},
{volumn:        24,chapter:        39,versenumber:         9,verse:"那时，护卫长尼布撒拉旦将城里所剩下的百姓和投降他的逃民，以及其余的民都掳到巴比伦去了。"},
{volumn:        24,chapter:        39,versenumber:        10,verse:"护卫长尼布撒拉旦却将民中毫无所有的穷人留在犹大地，当时给他们葡萄园和田地。"},
{volumn:        24,chapter:        39,versenumber:        11,verse:"巴比伦王尼布甲尼撒提到耶利米，嘱咐护卫长尼布撒拉旦说："},
{volumn:        24,chapter:        39,versenumber:        12,verse:"“你领他去，好好地看待他，切不可害他；他对你怎么说，你就向他怎么行。”"},
{volumn:        24,chapter:        39,versenumber:        13,verse:"护卫长尼布撒拉旦和尼布沙斯班、拉撒力、尼甲·沙利薛、拉墨，并巴比伦王的一切官长，"},
{volumn:        24,chapter:        39,versenumber:        14,verse:"打发人去，将耶利米从护卫兵院中提出来，交与沙番的孙子亚希甘的儿子基大利，带回家去。于是耶利米住在民中。"},
{volumn:        24,chapter:        39,versenumber:        15,verse:"耶利米还囚在护卫兵院中的时候，耶和华的话临到他说："},
{volumn:        24,chapter:        39,versenumber:        16,verse:"“你去告诉古实人以伯·米勒说，万军之耶和华以色列的　神如此说：我说降祸不降福的话必临到这城，到那时必在你面前成就了。"},
{volumn:        24,chapter:        39,versenumber:        17,verse:"耶和华说：到那日我必拯救你，你必不致交在你所怕的人手中。"},
{volumn:        24,chapter:        39,versenumber:        18,verse:"我定要搭救你，你不致倒在刀下，却要以自己的命为掠物，因你倚靠我。这是耶和华说的。”"},
{volumn:        24,chapter:        40,versenumber:         1,verse:"耶利米锁在耶路撒冷和犹大被掳到巴比伦的人中，护卫长尼布撒拉旦将他从拉玛释放以后，耶和华的话临到耶利米。"},
{volumn:        24,chapter:        40,versenumber:         2,verse:"护卫长将耶利米叫来，对他说：“耶和华你的　神曾说要降这祸与此地。"},
{volumn:        24,chapter:        40,versenumber:         3,verse:"耶和华使这祸临到，照他所说的行了；因为你们得罪耶和华，没有听从他的话，所以这事临到你们。"},
{volumn:        24,chapter:        40,versenumber:         4,verse:"现在我解开你手上的链子，你若看与我同往巴比伦去好，就可以去，我必厚待你；你若看与我同往巴比伦去不好，就不必去。看哪，全地在你面前，你以为哪里美好，哪里合宜，只管上那里去吧！”"},
{volumn:        24,chapter:        40,versenumber:         5,verse:"耶利米还没有回去，护卫长说：“你可以回到沙番的孙子亚希甘的儿子基大利那里去；现在巴比伦王立他作犹大城邑的省长。你可以在他那里住在民中，不然，你看哪里合宜就可以上那里去。”于是护卫长送他粮食和礼物，释放他去了。"},
{volumn:        24,chapter:        40,versenumber:         6,verse:"耶利米就到米斯巴见亚希甘的儿子基大利，在他那里住在境内剩下的民中。"},
{volumn:        24,chapter:        40,versenumber:         7,verse:"在田野的一切军长和属他们的人听见巴比伦王立了亚希甘的儿子基大利作境内的省长，并将没有掳到巴比伦的男人、妇女、孩童，和境内极穷的人全交给他。"},
{volumn:        24,chapter:        40,versenumber:         8,verse:"于是军长尼探雅的儿子以实玛利，加利亚的两个儿子约哈难和约拿单，单户篾的儿子西莱雅，并尼陀法人以斐的众子，玛迦人的儿子耶撒尼亚和属他们的人，都到米斯巴见基大利。"},
{volumn:        24,chapter:        40,versenumber:         9,verse:"沙番的孙子亚希甘的儿子基大利向他们和属他们的人起誓说：“不要怕服侍迦勒底人，只管住在这地，服侍巴比伦王，就可以得福。"},
{volumn:        24,chapter:        40,versenumber:        10,verse:"至于我，我要住在米斯巴，伺候那到我们这里来的迦勒底人；只是你们当积蓄酒、油，和夏天的果子，收在器皿里，住在你们所占的城邑中。”"},
{volumn:        24,chapter:        40,versenumber:        11,verse:"在摩押地和亚扪人中，在以东地和各国的一切犹大人，听见巴比伦王留下些犹大人，并立沙番的孙子亚希甘的儿子基大利管理他们。"},
{volumn:        24,chapter:        40,versenumber:        12,verse:"这一切犹大人就从所赶到的各处回来，到犹大地的米斯巴基大利那里，又积蓄了许多的酒，并夏天的果子。"},
{volumn:        24,chapter:        40,versenumber:        13,verse:"加利亚的儿子约哈难和在田野的一切军长来到米斯巴见基大利，"},
{volumn:        24,chapter:        40,versenumber:        14,verse:"对他说：“亚扪人的王巴利斯打发尼探雅的儿子以实玛利来要你的命，你知道吗？”亚希甘的儿子基大利却不信他们的话。"},
{volumn:        24,chapter:        40,versenumber:        15,verse:"加利亚的儿子约哈难在米斯巴私下对基大利说：“求你容我去杀尼探雅的儿子以实玛利，必无人知道。何必让他要你的命，使聚集到你这里来的犹大人都分散，以致犹大剩下的人都灭亡呢？”"},
{volumn:        24,chapter:        40,versenumber:        16,verse:"亚希甘的儿子基大利对加利亚的儿子约哈难说：“你不可行这事，你所论以实玛利的话是假的。”"},
{volumn:        24,chapter:        41,versenumber:         1,verse:"七月间，王的大臣宗室以利沙玛的孙子、尼探雅的儿子以实玛利带着十个人，来到米斯巴见亚希甘的儿子基大利。他们在米斯巴一同吃饭。"},
{volumn:        24,chapter:        41,versenumber:         2,verse:"尼探雅的儿子以实玛利和同他来的那十个人起来，用刀杀了沙番的孙子亚希甘的儿子基大利，就是巴比伦王所立为全地省长的。"},
{volumn:        24,chapter:        41,versenumber:         3,verse:"以实玛利又杀了在米斯巴、基大利那里的一切犹大人和所遇见的迦勒底兵丁。"},
{volumn:        24,chapter:        41,versenumber:         4,verse:"他杀了基大利，无人知道。"},
{volumn:        24,chapter:        41,versenumber:         5,verse:"第二天，有八十人从示剑和示罗，并撒玛利亚来，胡须剃去，衣服撕裂，身体划破，手拿素祭和乳香，要奉到耶和华的殿。"},
{volumn:        24,chapter:        41,versenumber:         6,verse:"尼探雅的儿子以实玛利出米斯巴迎接他们，随走随哭；遇见了他们，就对他们说：“你们可以来见亚希甘的儿子基大利。”"},
{volumn:        24,chapter:        41,versenumber:         7,verse:"他们到了城中，尼探雅的儿子以实玛利和同着他的人就将他们杀了，抛在坑中。"},
{volumn:        24,chapter:        41,versenumber:         8,verse:"只是他们中间有十个人对以实玛利说：“不要杀我们，因为我们有许多大麦、小麦、油、蜜藏在田间。”于是他住了手，没有将他们杀在弟兄中间。"},
{volumn:        24,chapter:        41,versenumber:         9,verse:"以实玛利将所杀之人的尸首都抛在坑里基大利的旁边；这坑是从前亚撒王因怕以色列王巴沙所挖的。尼探雅的儿子以实玛利将那些被杀的人填满了坑。"},
{volumn:        24,chapter:        41,versenumber:        10,verse:"以实玛利将米斯巴剩下的人，就是众公主和仍住在米斯巴所有的百姓，原是护卫长尼布撒拉旦交给亚希甘的儿子基大利的，都掳去了。尼探雅的儿子以实玛利掳了他们，要往亚扪人那里去。"},
{volumn:        24,chapter:        41,versenumber:        11,verse:"加利亚的儿子约哈难和同着他的众军长听见尼探雅的儿子以实玛利所行的一切恶，"},
{volumn:        24,chapter:        41,versenumber:        12,verse:"就带领众人前往，要和尼探雅的儿子以实玛利争战，在基遍的大水旁（或译：大水池旁）遇见他。"},
{volumn:        24,chapter:        41,versenumber:        13,verse:"以实玛利那里的众人看见加利亚的儿子约哈难和同着他的众军长，就都欢喜。"},
{volumn:        24,chapter:        41,versenumber:        14,verse:"这样，以实玛利从米斯巴所掳去的众人都转身归加利亚的儿子约哈难去了。"},
{volumn:        24,chapter:        41,versenumber:        15,verse:"尼探雅的儿子以实玛利和八个人脱离约哈难的手，逃往亚扪人那里去了。"},
{volumn:        24,chapter:        41,versenumber:        16,verse:"尼探雅的儿子以实玛利杀了亚希甘的儿子基大利，从米斯巴将剩下的一切百姓、兵丁、妇女、孩童、太监掳到基遍之后，加利亚的儿子约哈难和同着他的众军长将他们都夺回来，"},
{volumn:        24,chapter:        41,versenumber:        17,verse:"带到靠近伯利恒的金罕寓（或译：基罗特金罕）住下，要进入埃及去；"},
{volumn:        24,chapter:        41,versenumber:        18,verse:"因为尼探雅的儿子以实玛利杀了巴比伦王所立为省长的亚希甘的儿子基大利，约哈难惧怕迦勒底人。"},
{volumn:        24,chapter:        42,versenumber:         1,verse:"众军长和加利亚的儿子约哈难，并何沙雅的儿子耶撒尼亚（又名亚撒利雅，见四十三章二节）以及众百姓，从最小的到至大的都进前来，"},
{volumn:        24,chapter:        42,versenumber:         2,verse:"对先知耶利米说：“求你准我们在你面前祈求，为我们这剩下的人祷告耶和华你的　神。我们本来众多，现在剩下的极少，这是你亲眼所见的。"},
{volumn:        24,chapter:        42,versenumber:         3,verse:"愿耶和华你的　神指示我们所当走的路，所当做的事。”"},
{volumn:        24,chapter:        42,versenumber:         4,verse:"先知耶利米对他们说：“我已经听见你们了，我必照着你们的话祷告耶和华你们的　神。耶和华无论回答什么，我必都告诉你们，毫不隐瞒。”"},
{volumn:        24,chapter:        42,versenumber:         5,verse:"于是他们对耶利米说：“我们若不照耶和华你的　神差遣你来说的一切话行，愿耶和华在我们中间作真实诚信的见证。"},
{volumn:        24,chapter:        42,versenumber:         6,verse:"我们现在请你到耶和华我们的　神面前，他说的无论是好是歹，我们都必听从；我们听从耶和华我们　神的话，就可以得福。”"},
{volumn:        24,chapter:        42,versenumber:         7,verse:"过了十天，耶和华的话临到耶利米。"},
{volumn:        24,chapter:        42,versenumber:         8,verse:"他就将加利亚的儿子约哈难和同着他的众军长，并众百姓，从最小的到至大的都叫了来，"},
{volumn:        24,chapter:        42,versenumber:         9,verse:"对他们说：“耶和华以色列的　神，就是你们请我在他面前为你们祈求的主，如此说："},
{volumn:        24,chapter:        42,versenumber:        10,verse:"‘你们若仍住在这地，我就建立你们，必不拆毁，栽植你们，并不拔出，因我为降与你们的灾祸后悔了。"},
{volumn:        24,chapter:        42,versenumber:        11,verse:"不要怕你们所怕的巴比伦王。’”耶和华说：“不要怕他！因为我与你们同在，要拯救你们脱离他的手。"},
{volumn:        24,chapter:        42,versenumber:        12,verse:"我也要使他发怜悯，好怜悯你们，叫你们归回本地。"},
{volumn:        24,chapter:        42,versenumber:        13,verse:"倘若你们说：‘我们不住在这地’，以致不听从耶和华你们　神的话，"},
{volumn:        24,chapter:        42,versenumber:        14,verse:"说：‘我们不住这地，却要进入埃及地，在那里看不见争战，听不见角声，也不致无食饥饿。我们必住在那里。’"},
{volumn:        24,chapter:        42,versenumber:        15,verse:"你们所剩下的犹大人哪，现在要听耶和华的话。万军之耶和华以色列的　神如此说：‘你们若定意要进入埃及，在那里寄居，"},
{volumn:        24,chapter:        42,versenumber:        16,verse:"你们所惧怕的刀剑在埃及地必追上你们！你们所惧怕的饥荒在埃及要紧紧地跟随你们！你们必死在那里！"},
{volumn:        24,chapter:        42,versenumber:        17,verse:"凡定意要进入埃及在那里寄居的必遭刀剑、饥荒、瘟疫而死，无一人存留，逃脱我所降与他们的灾祸。’"},
{volumn:        24,chapter:        42,versenumber:        18,verse:"“万军之耶和华以色列的　神如此说：‘我怎样将我的怒气和忿怒倾在耶路撒冷的居民身上，你们进入埃及的时候，我也必照样将我的忿怒倾在你们身上，以致你们令人辱骂、惊骇、咒诅、羞辱，你们不得再见这地方。’"},
{volumn:        24,chapter:        42,versenumber:        19,verse:"所剩下的犹大人哪，耶和华论到你们说：‘不要进入埃及去。’你们要确实地知道我今日警教你们了。"},
{volumn:        24,chapter:        42,versenumber:        20,verse:"你们行诡诈自害；因为你们请我到耶和华你们的　神那里，说：‘求你为我们祷告耶和华我们　神，照耶和华我们的　神一切所说的告诉我们，我们就必遵行。’"},
{volumn:        24,chapter:        42,versenumber:        21,verse:"我今日将这话告诉你们，耶和华你们的　神为你们的事差遣我到你们那里说的，你们却一样没有听从。"},
{volumn:        24,chapter:        42,versenumber:        22,verse:"现在你们要确实地知道，你们在所要去寄居之地必遭刀剑、饥荒、瘟疫而死。”"},
{volumn:        24,chapter:        43,versenumber:         1,verse:"耶利米向众百姓说完了耶和华他们　神的一切话，就是耶和华他们　神差遣他去所说的一切话。"},
{volumn:        24,chapter:        43,versenumber:         2,verse:"何沙雅的儿子亚撒利雅和加利亚的儿子约哈难，并一切狂傲的人，就对耶利米说：“你说谎言！耶和华我们的　神并没有差遣你来说：‘你们不可进入埃及，在那里寄居。’"},
{volumn:        24,chapter:        43,versenumber:         3,verse:"这是尼利亚的儿子巴录挑唆你害我们，要将我们交在迦勒底人的手中，使我们有被杀的，有被掳到巴比伦去的。”"},
{volumn:        24,chapter:        43,versenumber:         4,verse:"于是加利亚的儿子约哈难和一切军长，并众百姓，不听从耶和华的话住在犹大地。"},
{volumn:        24,chapter:        43,versenumber:         5,verse:"加利亚的儿子约哈难和一切军长却将所剩下的犹大人，就是从被赶到各国回来在犹大地寄居的男人、妇女、孩童，和众公主，并护卫长尼布撒拉旦所留在沙番的孙子亚希甘的儿子基大利那里的众人，与先知耶利米，以及尼利亚的儿子巴录，"},
{volumn:        24,chapter:        43,versenumber:         6,verse:""},
{volumn:        24,chapter:        43,versenumber:         7,verse:"都带入埃及地，到了答比匿。这是因他们不听从耶和华的话。"},
{volumn:        24,chapter:        43,versenumber:         8,verse:"在答比匿，耶和华的话临到耶利米说："},
{volumn:        24,chapter:        43,versenumber:         9,verse:"“你在犹大人眼前要用手拿几块大石头，藏在砌砖的灰泥中，就是在答比匿法老的宫门那里，"},
{volumn:        24,chapter:        43,versenumber:        10,verse:"对他们说：‘万军之耶和华以色列的　神如此说：我必召我的仆人巴比伦王尼布甲尼撒来。在所藏的石头上我要安置他的宝座；他必将光华的宝帐支搭在其上。"},
{volumn:        24,chapter:        43,versenumber:        11,verse:"他要来攻击埃及地，定为死亡的，必致死亡；定为掳掠的，必被掳掠；定为刀杀的，必交刀杀。"},
{volumn:        24,chapter:        43,versenumber:        12,verse:"我要在埃及神的庙中使火着起，巴比伦王要将庙宇焚烧，神像掳去；他要得（原文是披上）埃及地，好像牧人披上外衣，从那里安然而去。"},
{volumn:        24,chapter:        43,versenumber:        13,verse:"他必打碎埃及地伯·示麦的柱像，用火焚烧埃及神的庙宇。’”"},
{volumn:        24,chapter:        44,versenumber:         1,verse:"有临到耶利米的话，论及一切住在埃及地的犹大人，就是住在密夺、答比匿、挪弗、巴忒罗境内的犹大人，说："},
{volumn:        24,chapter:        44,versenumber:         2,verse:"“万军之耶和华以色列的　神如此说：我所降与耶路撒冷和犹大各城的一切灾祸你们都看见了。那些城邑今日荒凉，无人居住；"},
{volumn:        24,chapter:        44,versenumber:         3,verse:"这是因居民所行的恶，去烧香侍奉别神，就是他们和你们，并你们列祖所不认识的神，惹我发怒。"},
{volumn:        24,chapter:        44,versenumber:         4,verse:"我从早起来差遣我的仆人众先知去说，你们切不要行我所厌恶这可憎之事。"},
{volumn:        24,chapter:        44,versenumber:         5,verse:"他们却不听从，不侧耳而听，不转离恶事，仍向别神烧香。"},
{volumn:        24,chapter:        44,versenumber:         6,verse:"因此，我的怒气和忿怒都倒出来，在犹大城邑中和耶路撒冷的街市上，如火着起，以致都荒废凄凉，正如今日一样。"},
{volumn:        24,chapter:        44,versenumber:         7,verse:"现在耶和华万军之　神、以色列的　神如此说：你们为何作这大恶自害己命，使你们的男人、妇女、婴孩，和吃奶的都从犹大中剪除、不留一人呢？"},
{volumn:        24,chapter:        44,versenumber:         8,verse:"就是因你们手所做的，在所去寄居的埃及地向别神烧香惹我发怒，使你们被剪除，在天下万国中令人咒诅羞辱。"},
{volumn:        24,chapter:        44,versenumber:         9,verse:"你们列祖的恶行，犹大列王和他们后妃的恶行，你们自己和你们妻子的恶行，就是在犹大地、耶路撒冷街上所行的，你们都忘了吗？"},
{volumn:        24,chapter:        44,versenumber:        10,verse:"到如今还没有懊悔，没有惧怕，没有遵行我在你们和你们列祖面前所设立的法度律例。"},
{volumn:        24,chapter:        44,versenumber:        11,verse:"“所以万军之耶和华以色列的　神如此说：我必向你们变脸降灾，以致剪除犹大众人。"},
{volumn:        24,chapter:        44,versenumber:        12,verse:"那定意进入埃及地、在那里寄居的，就是所剩下的犹大人，我必使他们尽都灭绝，必在埃及地仆倒，必因刀剑饥荒灭绝；从最小的到至大的都必遭刀剑饥荒而死，以致令人辱骂、惊骇、咒诅、羞辱。"},
{volumn:        24,chapter:        44,versenumber:        13,verse:"我怎样用刀剑、饥荒、瘟疫刑罚耶路撒冷，也必照样刑罚那些住在埃及地的犹大人；"},
{volumn:        24,chapter:        44,versenumber:        14,verse:"甚至那进入埃及地寄居的，就是所剩下的犹大人，都不得逃脱，也不得存留归回犹大地。他们心中甚想归回居住之地；除了逃脱的以外，一个都不能归回。”"},
{volumn:        24,chapter:        44,versenumber:        15,verse:"那些住在埃及地巴忒罗知道自己妻子向别神烧香的，与旁边站立的众妇女，聚集成群，回答耶利米说："},
{volumn:        24,chapter:        44,versenumber:        16,verse:"“论到你奉耶和华的名向我们所说的话，我们必不听从。"},
{volumn:        24,chapter:        44,versenumber:        17,verse:"我们定要成就我们口中所出的一切话，向天后烧香、浇奠祭，按着我们与我们列祖、君王、首领在犹大的城邑中和耶路撒冷的街市上素常所行的一样；因为那时我们吃饱饭、享福乐，并不见灾祸。"},
{volumn:        24,chapter:        44,versenumber:        18,verse:"自从我们停止向天后烧香、浇奠祭，我们倒缺乏一切，又因刀剑饥荒灭绝。”"},
{volumn:        24,chapter:        44,versenumber:        19,verse:"妇女说：“我们向天后烧香、浇奠祭，做天后像的饼供奉她，向她浇奠祭，是外乎我们的丈夫吗？”"},
{volumn:        24,chapter:        44,versenumber:        20,verse:"耶利米对一切那样回答他的男人妇女说："},
{volumn:        24,chapter:        44,versenumber:        21,verse:"“你们与你们列祖、君王、首领，并国内的百姓，在犹大城邑中和耶路撒冷街市上所烧的香，耶和华岂不记念，心中岂不思想吗？"},
{volumn:        24,chapter:        44,versenumber:        22,verse:"耶和华因你们所作的恶、所行可憎的事，不能再容忍，所以你们的地荒凉，令人惊骇咒诅，无人居住，正如今日一样。"},
{volumn:        24,chapter:        44,versenumber:        23,verse:"你们烧香，得罪耶和华，没有听从他的话，没有遵行他的律法、条例、法度，所以你们遭遇这灾祸，正如今日一样。”"},
{volumn:        24,chapter:        44,versenumber:        24,verse:"耶利米又对众民和众妇女说：“你们在埃及地的一切犹大人当听耶和华的话。"},
{volumn:        24,chapter:        44,versenumber:        25,verse:"万军之耶和华以色列的　神如此说：你们和你们的妻都口中说、手里做，说：‘我们定要偿还所许的愿，向天后烧香、浇奠祭。’现在你们只管坚定所许的愿而偿还吧！"},
{volumn:        24,chapter:        44,versenumber:        26,verse:"所以你们住在埃及地的一切犹大人当听耶和华的话。耶和华说：我指着我的大名起誓，在埃及全地，我的名不再被犹大一个人的口称呼说：‘我指着主永生的耶和华起誓。’"},
{volumn:        24,chapter:        44,versenumber:        27,verse:"我向他们留意降祸不降福；在埃及地的一切犹大人必因刀剑、饥荒所灭，直到灭尽。"},
{volumn:        24,chapter:        44,versenumber:        28,verse:"脱离刀剑、从埃及地归回犹大地的人数很少；那进入埃及地要在那里寄居的，就是所剩下的犹大人，必知道是谁的话立得住，是我的话呢？是他们的话呢？"},
{volumn:        24,chapter:        44,versenumber:        29,verse:"耶和华说：我在这地方刑罚你们，必有预兆，使你们知道我降祸与你们的话必要立得住。"},
{volumn:        24,chapter:        44,versenumber:        30,verse:"耶和华如此说：我必将埃及王法老合弗拉交在他仇敌和寻索其命的人手中，像我将犹大王西底家交在他仇敌和寻索其命的巴比伦王尼布甲尼撒手中一样。”"},
{volumn:        24,chapter:        45,versenumber:         1,verse:"犹大王约西亚的儿子约雅敬第四年，尼利亚的儿子巴录将先知耶利米口中所说的话写在书上。耶利米说："},
{volumn:        24,chapter:        45,versenumber:         2,verse:"“巴录啊，耶和华以色列的　神说："},
{volumn:        24,chapter:        45,versenumber:         3,verse:"‘巴录（原文是你）曾说：哀哉！耶和华将忧愁加在我的痛苦上，我因唉哼而困乏，不得安歇。’"},
{volumn:        24,chapter:        45,versenumber:         4,verse:"你要这样告诉他，耶和华如此说：我所建立的，我必拆毁；我所栽植的，我必拔出；在全地我都如此行。"},
{volumn:        24,chapter:        45,versenumber:         5,verse:"你为自己图谋大事吗？不要图谋！我必使灾祸临到凡有血气的。但你无论往哪里去，我必使你以自己的命为掠物。这是耶和华说的。”"},
{volumn:        24,chapter:        46,versenumber:         1,verse:"耶和华论列国的话临到先知耶利米。"},
{volumn:        24,chapter:        46,versenumber:         2,verse:"论到关乎埃及王法老尼哥的军队：这军队安营在幼发拉底河边的迦基米施，是巴比伦王尼布甲尼撒在犹大王约西亚的儿子约雅敬第四年所打败的。"},
{volumn:        24,chapter:        46,versenumber:         3,verse:"你们要预备大小盾牌，往前上阵。"},
{volumn:        24,chapter:        46,versenumber:         4,verse:"你们套上车，骑上马！顶盔站立，磨枪贯甲！"},
{volumn:        24,chapter:        46,versenumber:         5,verse:"我为何看见他们惊惶转身退后呢？他们的勇士打败了，急忙逃跑，并不回头；惊吓四围都有！这是耶和华说的。"},
{volumn:        24,chapter:        46,versenumber:         6,verse:"不要容快跑的逃避；不要容勇士逃脱（或译：快跑的不能逃避；勇士不能逃脱）；他们在北方幼发拉底河边绊跌仆倒。"},
{volumn:        24,chapter:        46,versenumber:         7,verse:"像尼罗河涨发，像江河之水翻腾的是谁呢？"},
{volumn:        24,chapter:        46,versenumber:         8,verse:"埃及像尼罗河涨发，像江河的水翻腾。他说：“我要涨发遮盖遍地；我要毁灭城邑和其中的居民。"},
{volumn:        24,chapter:        46,versenumber:         9,verse:"马匹上去吧！车辆急行吧！勇士，就是手拿盾牌的古实人和弗人（又作利比亚人），并拉弓的路德族，都出去吧！"},
{volumn:        24,chapter:        46,versenumber:        10,verse:"那日是主万军之耶和华报仇的日子，要向敌人报仇。刀剑必吞吃得饱，饮血饮足；因为主万军之耶和华在北方幼发拉底河边有献祭的事。"},
{volumn:        24,chapter:        46,versenumber:        11,verse:"埃及的民（原文是处女）哪，可以上基列取乳香去；你虽多服良药，总是徒然，不得治好。"},
{volumn:        24,chapter:        46,versenumber:        12,verse:"列国听见你的羞辱，遍地满了你的哀声；勇士与勇士彼此相碰，一齐跌倒。"},
{volumn:        24,chapter:        46,versenumber:        13,verse:"耶和华对先知耶利米所说的话，论到巴比伦王尼布甲尼撒要来攻击埃及地："},
{volumn:        24,chapter:        46,versenumber:        14,verse:"你们要传扬在埃及，宣告在密夺，报告在挪弗、答比匿说：要站起出队，自作准备，因为刀剑在你四围施行吞灭的事。"},
{volumn:        24,chapter:        46,versenumber:        15,verse:"你的壮士为何被冲去呢？他们站立不住；因为耶和华驱逐他们，"},
{volumn:        24,chapter:        46,versenumber:        16,verse:"使多人绊跌；他们也彼此撞倒，说：“起来吧！我们再往本民本地去，好躲避欺压的刀剑。”"},
{volumn:        24,chapter:        46,versenumber:        17,verse:"他们在那里喊叫说：“埃及王法老不过是个声音（或译：已经败亡）；他已错过所定的时候了。”"},
{volumn:        24,chapter:        46,versenumber:        18,verse:"君王，名为万军之耶和华的说：“我指着我的永生起誓：尼布甲尼撒（原文是他）来的势派必像他泊在众山之中，像迦密在海边一样。"},
{volumn:        24,chapter:        46,versenumber:        19,verse:"住在埃及的民哪（原文是女子），要预备掳去时所用的物件；因为挪弗必成为荒场，且被烧毁，无人居住。"},
{volumn:        24,chapter:        46,versenumber:        20,verse:"埃及是肥美的母牛犊；但出于北方的毁灭（或译：牛虻）来到了！来到了！"},
{volumn:        24,chapter:        46,versenumber:        21,verse:"其中的雇勇好像圈里的肥牛犊，他们转身退后，一齐逃跑，站立不住；因为他们遭难的日子、追讨的时候已经临到。"},
{volumn:        24,chapter:        46,versenumber:        22,verse:"其中的声音好像蛇行一样。敌人要成队而来，如砍伐树木的手拿斧子攻击他。"},
{volumn:        24,chapter:        46,versenumber:        23,verse:"耶和华说：埃及的树林虽然不能寻察（或译：穿不过），敌人却要砍伐，因他们多于蝗虫，不可胜数。"},
{volumn:        24,chapter:        46,versenumber:        24,verse:"埃及的民（原文是女子）必然蒙羞，必交在北方人的手中。"},
{volumn:        24,chapter:        46,versenumber:        25,verse:"万军之耶和华以色列的　神说：“我必刑罚挪的亚扪（埃及尊大之神）和法老，并埃及与埃及的神，以及君王，也必刑罚法老和倚靠他的人。"},
{volumn:        24,chapter:        46,versenumber:        26,verse:"我要将他们交付寻索其命之人的手和巴比伦王尼布甲尼撒与他臣仆的手；以后埃及必再有人居住，与从前一样。这是耶和华说的。”"},
{volumn:        24,chapter:        46,versenumber:        27,verse:"“我的仆人雅各啊，不要惧怕！以色列啊，不要惊惶！因我要从远方拯救你，从被掳到之地拯救你的后裔。雅各必回来，得享平靖安逸，无人使他害怕。"},
{volumn:        24,chapter:        46,versenumber:        28,verse:"我的仆人雅各啊，不要惧怕！因我与你同在。我要将我所赶你到的那些国灭绝净尽，却不将你灭绝净尽，倒要从宽惩治你，万不能不罚你（不罚你：或译以你为无罪）。这是耶和华说的。”"},
{volumn:        24,chapter:        47,versenumber:         1,verse:"法老攻击迦萨之先，有耶和华论非利士人的话临到先知耶利米。"},
{volumn:        24,chapter:        47,versenumber:         2,verse:"耶和华如此说：“有水从北方发起，成为涨溢的河，要涨过遍地和其中所有的，并城和其中所住的。人必呼喊；境内的居民都必哀号。"},
{volumn:        24,chapter:        47,versenumber:         3,verse:"听见敌人壮马蹄跳的响声和战车隆隆、车轮轰轰；为父的手就发软，不回头看顾儿女。"},
{volumn:        24,chapter:        47,versenumber:         4,verse:"因为日子将到，要毁灭一切非利士人，剪除帮助推罗、西顿所剩下的人。原来耶和华必毁灭非利士人，就是迦斐托海岛余剩的人。"},
{volumn:        24,chapter:        47,versenumber:         5,verse:"迦萨成了光秃；平原中所剩的亚实基伦归于无有。你用刀划身，要到几时呢？"},
{volumn:        24,chapter:        47,versenumber:         6,verse:"耶和华的刀剑哪，你到几时才止息呢？你要入鞘，安靖不动。"},
{volumn:        24,chapter:        47,versenumber:         7,verse:"耶和华既吩咐你攻击亚实基伦和海边之地，他已经派定你，焉能止息呢？”"},
{volumn:        24,chapter:        48,versenumber:         1,verse:"论摩押。万军之耶和华以色列的　神如此说：“尼波有祸了！因变为荒场。基列亭蒙羞被攻取；米斯迦蒙羞被毁坏；"},
{volumn:        24,chapter:        48,versenumber:         2,verse:"摩押不再被称赞。有人在希实本设计谋害她，说：‘来吧！我们将她剪除，不再成国。’玛得缅哪，你也必默默无声；刀剑必追赶你。"},
{volumn:        24,chapter:        48,versenumber:         3,verse:"“从何罗念有喊荒凉大毁灭的哀声："},
{volumn:        24,chapter:        48,versenumber:         4,verse:"摩押毁灭了！她的孩童（或译：家僮）发哀声，使人听见。"},
{volumn:        24,chapter:        48,versenumber:         5,verse:"人上鲁希坡随走随哭，因为在何罗念的下坡听见毁灭的哀声。"},
{volumn:        24,chapter:        48,versenumber:         6,verse:"你们要奔逃，自救性命，独自居住，好像旷野的杜松。"},
{volumn:        24,chapter:        48,versenumber:         7,verse:"你因倚靠自己所做的和自己的财宝必被攻取。基抹和属他的祭司、首领也要一同被掳去。"},
{volumn:        24,chapter:        48,versenumber:         8,verse:"行毁灭的必来到各城，并无一城得免。山谷必致败落，平原必被毁坏；正如耶和华所说的。"},
{volumn:        24,chapter:        48,versenumber:         9,verse:"“要将翅膀给摩押，使她可以飞去。她的城邑必致荒凉，无人居住。"},
{volumn:        24,chapter:        48,versenumber:        10,verse:"懒惰为耶和华行事的，必受咒诅；禁止刀剑不经血的，必受咒诅。”"},
{volumn:        24,chapter:        48,versenumber:        11,verse:"“摩押自幼年以来常享安逸，如酒在渣滓上澄清，没有从这器皿倒在那器皿里，也未曾被掳去。因此，它的原味尚存，香气未变。"},
{volumn:        24,chapter:        48,versenumber:        12,verse:"耶和华说：“日子将到，我必打发倒酒的往她那里去，将她倒出来，倒空她的器皿，打碎她的坛子。"},
{volumn:        24,chapter:        48,versenumber:        13,verse:"摩押必因基抹羞愧，像以色列家从前倚靠伯特利的神羞愧一样。"},
{volumn:        24,chapter:        48,versenumber:        14,verse:"你们怎么说：‘我们是勇士，是有勇力打仗的’呢？"},
{volumn:        24,chapter:        48,versenumber:        15,verse:"摩押变为荒场，敌人上去进了她的城邑。她所特选的少年人下去遭了杀戮；这是君王，名为万军之耶和华说的。"},
{volumn:        24,chapter:        48,versenumber:        16,verse:"摩押的灾殃临近；她的苦难速速来到。"},
{volumn:        24,chapter:        48,versenumber:        17,verse:"凡在她四围的和认识她名的，你们都要为她悲伤，说：‘那结实的杖和那美好的棍，何竟折断了呢？’"},
{volumn:        24,chapter:        48,versenumber:        18,verse:"“住在底本的民哪（原文是女子），要从你荣耀的位上下来，坐受干渴；因毁灭摩押的上来攻击你，毁坏了你的保障。"},
{volumn:        24,chapter:        48,versenumber:        19,verse:"住亚罗珥的啊，要站在道旁观望，问逃避的男人和逃脱的女人说：‘是什么事呢？’"},
{volumn:        24,chapter:        48,versenumber:        20,verse:"摩押因毁坏蒙羞；你们要哀号呼喊，要在亚嫩旁报告说：‘摩押变为荒场！’"},
{volumn:        24,chapter:        48,versenumber:        21,verse:"刑罚临到平原之地的何伦、雅杂、米法押、"},
{volumn:        24,chapter:        48,versenumber:        22,verse:"底本、尼波、伯·低比拉太音、"},
{volumn:        24,chapter:        48,versenumber:        23,verse:"基列亭、伯·迦末、伯·米恩、"},
{volumn:        24,chapter:        48,versenumber:        24,verse:"加略、波斯拉，和摩押地远近所有的城邑。"},
{volumn:        24,chapter:        48,versenumber:        25,verse:"摩押的角砍断了，摩押的膀臂折断了。这是耶和华说的。”"},
{volumn:        24,chapter:        48,versenumber:        26,verse:"“你们要使摩押沉醉，因她向耶和华夸大。她要在自己所吐之中打滚，又要被人嗤笑。"},
{volumn:        24,chapter:        48,versenumber:        27,verse:"摩押啊，你不曾嗤笑以色列吗？她岂是在贼中查出来的呢？你每逢提到她便摇头。"},
{volumn:        24,chapter:        48,versenumber:        28,verse:"摩押的居民哪，要离开城邑，住在山崖里，像鸽子在深渊口上搭窝。"},
{volumn:        24,chapter:        48,versenumber:        29,verse:"我们听说摩押人骄傲，是极其骄傲；听说他自高自傲，并且狂妄，居心自大。"},
{volumn:        24,chapter:        48,versenumber:        30,verse:"耶和华说：我知道他的忿怒是虚空的；他夸大的话一无所成。"},
{volumn:        24,chapter:        48,versenumber:        31,verse:"因此，我要为摩押哀号，为摩押全地呼喊；人必为吉珥·哈列设人叹息。"},
{volumn:        24,chapter:        48,versenumber:        32,verse:"西比玛的葡萄树啊，我为你哀哭，甚于雅谢人哀哭。你的枝子蔓延过海，直长到雅谢海。那行毁灭的已经临到你夏天的果子和你所摘的葡萄。"},
{volumn:        24,chapter:        48,versenumber:        33,verse:"肥田和摩押地的欢喜快乐都被夺去；我使酒榨的酒绝流，无人踹酒欢呼；那欢呼却变为仇敌的呐喊（原文是那欢呼却不是欢呼）。"},
{volumn:        24,chapter:        48,versenumber:        34,verse:"“希实本人发的哀声达到以利亚利，直达到雅杂；从琐珥达到何罗念，直到伊基拉·施利施亚，因为宁林的水必然干涸。”"},
{volumn:        24,chapter:        48,versenumber:        35,verse:"耶和华说：“我必在摩押地使那在邱坛献祭的，和那向他的神烧香的都断绝了。"},
{volumn:        24,chapter:        48,versenumber:        36,verse:"我心腹为摩押哀鸣如箫，我心肠为吉珥·哈列设人也是如此，因摩押人所得的财物都灭没了。"},
{volumn:        24,chapter:        48,versenumber:        37,verse:"“各人头上光秃，胡须剪短，手有划伤，腰束麻布。"},
{volumn:        24,chapter:        48,versenumber:        38,verse:"在摩押的各房顶上和街市上处处有人哀哭；因我打碎摩押，好像打碎无人喜悦的器皿。这是耶和华说的。"},
{volumn:        24,chapter:        48,versenumber:        39,verse:"摩押何等毁坏！何等哀号！何等羞愧转背！这样，摩押必令四围的人嗤笑惊骇。”"},
{volumn:        24,chapter:        48,versenumber:        40,verse:"耶和华如此说：“仇敌必如大鹰飞起，展开翅膀，攻击摩押。"},
{volumn:        24,chapter:        48,versenumber:        41,verse:"加略被攻取，保障也被占据。到那日，摩押的勇士心中疼痛如临产的妇人。"},
{volumn:        24,chapter:        48,versenumber:        42,verse:"摩押必被毁灭，不再成国，因她向耶和华夸大。”"},
{volumn:        24,chapter:        48,versenumber:        43,verse:"耶和华说：“摩押的居民哪，恐惧、陷坑、网罗都临近你。"},
{volumn:        24,chapter:        48,versenumber:        44,verse:"躲避恐惧的必坠入陷坑；从陷坑上来的必被网罗缠住；因我必使追讨之年临到摩押。这是耶和华说的。"},
{volumn:        24,chapter:        48,versenumber:        45,verse:"“躲避的人无力站在希实本的影下；因为有火从希实本发出，有火焰出于西宏的城，烧尽摩押的角和哄嚷人的头顶。"},
{volumn:        24,chapter:        48,versenumber:        46,verse:"摩押啊，你有祸了！属基抹的民灭亡了！因你的众子都被掳去，你的众女也被掳去。”"},
{volumn:        24,chapter:        48,versenumber:        47,verse:"耶和华说：“到末后，我还要使被掳的摩押人归回。”摩押受审判的话到此为止。"},
{volumn:        24,chapter:        49,versenumber:         1,verse:"论亚扪人。耶和华如此说：“以色列没有儿子吗？没有后嗣吗？玛勒堪为何得迦得之地为业呢？属他的民为何住其中的城邑呢？"},
{volumn:        24,chapter:        49,versenumber:         2,verse:"耶和华说：日子将到，我必使人听见打仗的喊声，是攻击亚扪人拉巴的喊声。拉巴要成为乱堆；属她的乡村（原文是女子）要被火焚烧。先前得以色列地为业的，此时以色列倒要得他们的地为业。这是耶和华说的。"},
{volumn:        24,chapter:        49,versenumber:         3,verse:"“希实本哪，你要哀号，因为爱地变为荒场。拉巴的居民（原文是女子）哪，要呼喊，以麻布束腰；要哭号，在篱笆中跑来跑去；因玛勒堪和属他的祭司、首领要一同被掳去。"},
{volumn:        24,chapter:        49,versenumber:         4,verse:"背道的民（原文是女子）哪，你们为何因有山谷，就是水流的山谷夸张呢？为何倚靠财宝说：‘谁能来到我们这里呢？’"},
{volumn:        24,chapter:        49,versenumber:         5,verse:"主万军之耶和华说：我要使恐吓从四围的人中临到你们；你们必被赶出，各人一直前往，没有人收聚逃民。"},
{volumn:        24,chapter:        49,versenumber:         6,verse:"后来我还要使被掳的亚扪人归回。这是耶和华说的。”"},
{volumn:        24,chapter:        49,versenumber:         7,verse:"论以东。万军之耶和华如此说：“提幔中再没有智慧吗？明哲人不再有谋略吗？他们的智慧尽归无有吗？"},
{volumn:        24,chapter:        49,versenumber:         8,verse:"底但的居民哪，要转身逃跑，住在深密处；因为我向以扫追讨的时候，必使灾殃临到他。"},
{volumn:        24,chapter:        49,versenumber:         9,verse:"摘葡萄的若来到他那里，岂不剩下些葡萄呢？盗贼若夜间而来，岂不毁坏直到够了呢？"},
{volumn:        24,chapter:        49,versenumber:        10,verse:"我却使以扫赤露，显出他的隐密处；他不能自藏。他的后裔、弟兄、邻舍尽都灭绝；他也归于无有。"},
{volumn:        24,chapter:        49,versenumber:        11,verse:"你撇下孤儿，我必保全他们的命；你的寡妇可以倚靠我。”"},
{volumn:        24,chapter:        49,versenumber:        12,verse:"耶和华如此说：“原不该喝那杯的一定要喝。你能尽免刑罚吗？你必不能免，一定要喝！"},
{volumn:        24,chapter:        49,versenumber:        13,verse:"耶和华说：我指着自己起誓，波斯拉必令人惊骇、羞辱、咒诅，并且荒凉。她的一切城邑必变为永远的荒场。”"},
{volumn:        24,chapter:        49,versenumber:        14,verse:"我从耶和华那里听见信息，并有使者被差往列国去，说：“你们聚集来攻击以东，要起来争战。"},
{volumn:        24,chapter:        49,versenumber:        15,verse:"我使你在列国中为最小，在世人中被藐视。"},
{volumn:        24,chapter:        49,versenumber:        16,verse:"住在山穴中据守山顶的啊，论到你的威吓，你因心中的狂傲自欺；你虽如大鹰高高搭窝，我却从那里拉下你来。这是耶和华说的。"},
{volumn:        24,chapter:        49,versenumber:        17,verse:"以东必令人惊骇；凡经过的人就受惊骇，又因她一切的灾祸嗤笑。"},
{volumn:        24,chapter:        49,versenumber:        18,verse:"耶和华说：必无人住在那里，也无人在其中寄居，要像所多玛、蛾摩拉，和邻近的城邑倾覆的时候一样。"},
{volumn:        24,chapter:        49,versenumber:        19,verse:"仇敌必像狮子从约旦河边的丛林上来，攻击坚固的居所。转眼之间，我要使以东人逃跑，离开这地。谁蒙拣选，我就派谁治理这地。谁能比我呢？谁能给我定规日期呢？有何牧人能在我面前站立得住呢？"},
{volumn:        24,chapter:        49,versenumber:        20,verse:"你们要听耶和华攻击以东所说的谋略和他攻击提幔居民所定的旨意。仇敌定要将他们群众微弱的拉去，定要使他们的居所荒凉。"},
{volumn:        24,chapter:        49,versenumber:        21,verse:"因他们仆倒的声音，地就震动。人在红海那里必听见呼喊的声音。"},
{volumn:        24,chapter:        49,versenumber:        22,verse:"仇敌必如大鹰飞起，展开翅膀攻击波斯拉。到那日，以东的勇士心中疼痛如临产的妇人。”"},
{volumn:        24,chapter:        49,versenumber:        23,verse:"论大马士革。“哈马和亚珥拔蒙羞，因他们听见凶恶的信息就消化了。海上有忧愁，不得平静。"},
{volumn:        24,chapter:        49,versenumber:        24,verse:"大马士革发软，转身逃跑。战兢将她捉住；痛苦忧愁将她抓住，如产难的妇人一样。"},
{volumn:        24,chapter:        49,versenumber:        25,verse:"我所喜乐可称赞的城，为何被撇弃了呢？"},
{volumn:        24,chapter:        49,versenumber:        26,verse:"她的少年人必仆倒在街上；当那日，一切兵丁必默默无声。这是万军之耶和华说的。"},
{volumn:        24,chapter:        49,versenumber:        27,verse:"我必在大马士革城中使火着起，烧灭便·哈达的宫殿。”"},
{volumn:        24,chapter:        49,versenumber:        28,verse:"论巴比伦王尼布甲尼撒所攻打的基达和夏琐的诸国。耶和华如此说：“迦勒底人哪，起来上基达去，毁灭东方人。"},
{volumn:        24,chapter:        49,versenumber:        29,verse:"他们的帐棚和羊群都要夺去，将幔子和一切器皿，并骆驼为自己掠去。人向他们喊着说：四围都有惊吓。”"},
{volumn:        24,chapter:        49,versenumber:        30,verse:"耶和华说：“夏琐的居民哪，要逃奔远方，住在深密处；因为巴比伦王尼布甲尼撒设计谋害你们，起意攻击你们。”"},
{volumn:        24,chapter:        49,versenumber:        31,verse:"耶和华说：“迦勒底人哪，起来！上安逸无虑的居民那里去；他们是无门无闩、独自居住的。"},
{volumn:        24,chapter:        49,versenumber:        32,verse:"他们的骆驼必成为掠物；他们众多的牲畜必成为掳物。我必将剃周围头发的人分散四方（原文是风），使灾殃从四围临到他们。这是耶和华说的。"},
{volumn:        24,chapter:        49,versenumber:        33,verse:"夏琐必成为野狗的住处，永远凄凉；必无人住在那里，也无人在其中寄居。”"},
{volumn:        24,chapter:        49,versenumber:        34,verse:"犹大王西底家登基的时候，耶和华论以拦的话临到先知耶利米说："},
{volumn:        24,chapter:        49,versenumber:        35,verse:"“万军之耶和华如此说：我必折断以拦人的弓，就是他们为首的权力。"},
{volumn:        24,chapter:        49,versenumber:        36,verse:"我要使四风从天的四方刮来，临到以拦人，将他们分散四方（原文是风）。这被赶散的人没有一国不到的。”"},
{volumn:        24,chapter:        49,versenumber:        37,verse:"耶和华说：“我必使以拦人在仇敌和寻索其命的人面前惊惶；我也必使灾祸，就是我的烈怒临到他们，又必使刀剑追杀他们，直到将他们灭尽。"},
{volumn:        24,chapter:        49,versenumber:        38,verse:"我要在以拦设立我的宝座，从那里除灭君王和首领。这是耶和华说的。"},
{volumn:        24,chapter:        49,versenumber:        39,verse:"“到末后，我还要使被掳的以拦人归回。这是耶和华说的。”"},
{volumn:        24,chapter:        50,versenumber:         1,verse:"耶和华藉先知耶利米论巴比伦和迦勒底人之地所说的话。"},
{volumn:        24,chapter:        50,versenumber:         2,verse:"你们要在万国中传扬报告，竖立大旗；要报告，不可隐瞒，说：“巴比伦被攻取，彼勒蒙羞，米罗达惊惶。巴比伦的神像都蒙羞；她的偶像都惊惶。"},
{volumn:        24,chapter:        50,versenumber:         3,verse:"因有一国从北方上来攻击她，使她的地荒凉，无人居住，连人带牲畜都逃走了。”"},
{volumn:        24,chapter:        50,versenumber:         4,verse:"耶和华说：“当那日子、那时候，以色列人要和犹大人同来，随走随哭，寻求耶和华他们的　神。"},
{volumn:        24,chapter:        50,versenumber:         5,verse:"他们必访问锡安，又面向这里，说：‘来吧，你们要与耶和华联合为永远不忘的约。’"},
{volumn:        24,chapter:        50,versenumber:         6,verse:"“我的百姓作了迷失的羊，牧人使他们走差路，使他们转到山上。他们从大山走到小山，竟忘了安歇之处。"},
{volumn:        24,chapter:        50,versenumber:         7,verse:"凡遇见他们的，就把他们吞灭。敌人说：‘我们没有罪；因他们得罪那作公义居所的耶和华，就是他们列祖所仰望的耶和华。’"},
{volumn:        24,chapter:        50,versenumber:         8,verse:"“我民哪，你们要从巴比伦中逃走，从迦勒底人之地出去，要像羊群前面走的公山羊。"},
{volumn:        24,chapter:        50,versenumber:         9,verse:"因我必激动联合的大国从北方上来攻击巴比伦，他们要摆阵攻击她；她必从那里被攻取。他们的箭好像善射之勇士的箭，一枝也不徒然返回。"},
{volumn:        24,chapter:        50,versenumber:        10,verse:"迦勒底必成为掠物；凡掳掠她的都必心满意足。这是耶和华说的。”"},
{volumn:        24,chapter:        50,versenumber:        11,verse:"“抢夺我产业的啊，你们因欢喜快乐，且像踹谷撒欢的母牛犊，又像发嘶声的壮马。"},
{volumn:        24,chapter:        50,versenumber:        12,verse:"你们的母巴比伦就极其抱愧，生你们的必然蒙羞。她要列在诸国之末，成为旷野、旱地、沙漠。"},
{volumn:        24,chapter:        50,versenumber:        13,verse:"因耶和华的忿怒，必无人居住，要全然荒凉。凡经过巴比伦的要受惊骇，又因她所遭的灾殃嗤笑。"},
{volumn:        24,chapter:        50,versenumber:        14,verse:"所有拉弓的，你们要在巴比伦的四围摆阵，射箭攻击她。不要爱惜箭枝，因她得罪了耶和华。"},
{volumn:        24,chapter:        50,versenumber:        15,verse:"你们要在她四围呐喊；她已经投降。外郭坍塌了，城墙拆毁了，因为这是耶和华报仇的事。你们要向巴比伦报仇；她怎样待人，也要怎样待她。"},
{volumn:        24,chapter:        50,versenumber:        16,verse:"你们要将巴比伦撒种的和收割时拿镰刀的都剪除了。他们各人因怕欺压的刀剑，必归回本族，逃到本土。”"},
{volumn:        24,chapter:        50,versenumber:        17,verse:"“以色列是打散的羊，是被狮子赶出的。首先是亚述王将他吞灭，末后是巴比伦王尼布甲尼撒将他的骨头折断。”"},
{volumn:        24,chapter:        50,versenumber:        18,verse:"所以万军之耶和华以色列的　神如此说：“我必罚巴比伦王和他的地，像我从前罚亚述王一样。"},
{volumn:        24,chapter:        50,versenumber:        19,verse:"我必再领以色列回他的草场，他必在迦密和巴珊吃草，又在以法莲山上和基列境内得以饱足。”"},
{volumn:        24,chapter:        50,versenumber:        20,verse:"耶和华说：“当那日子、那时候，虽寻以色列的罪孽，一无所有；虽寻犹大的罪恶，也无所见；因为我所留下的人，我必赦免。”"},
{volumn:        24,chapter:        50,versenumber:        21,verse:"耶和华说：“上去攻击米拉大翁之地，又攻击比割的居民。要追杀灭尽，照我一切所吩咐你的去行。"},
{volumn:        24,chapter:        50,versenumber:        22,verse:"境内有打仗和大毁灭的响声。"},
{volumn:        24,chapter:        50,versenumber:        23,verse:"全地的大锤何竟砍断破坏？巴比伦在列国中何竟荒凉？"},
{volumn:        24,chapter:        50,versenumber:        24,verse:"巴比伦哪，我为你设下网罗，你不知不觉被缠住。你被寻着，也被捉住；因为你与耶和华争竞。"},
{volumn:        24,chapter:        50,versenumber:        25,verse:"耶和华已经开了武库，拿出他恼恨的兵器；因为主万军之耶和华在迦勒底人之地有当做的事。"},
{volumn:        24,chapter:        50,versenumber:        26,verse:"你们要从极远的边界来攻击她，开她的仓廪，将她堆如高堆，毁灭净尽，丝毫不留。"},
{volumn:        24,chapter:        50,versenumber:        27,verse:"要杀她的一切牛犊，使他们下去遭遇杀戮。他们有祸了，因为追讨他们的日子已经来到。"},
{volumn:        24,chapter:        50,versenumber:        28,verse:"有从巴比伦之地逃避出来的人，在锡安扬声报告耶和华我们的　神报仇，就是为他的殿报仇。"},
{volumn:        24,chapter:        50,versenumber:        29,verse:"招集一切弓箭手来攻击巴比伦。要在巴比伦四围安营，不要容一人逃脱，照着她所做的报应她；她怎样待人，也要怎样待她，因为她向耶和华以色列的圣者发了狂傲。"},
{volumn:        24,chapter:        50,versenumber:        30,verse:"所以她的少年人必仆倒在街上。当那日，一切兵丁必默默无声。这是耶和华说的。”"},
{volumn:        24,chapter:        50,versenumber:        31,verse:"主万军之耶和华说：“你这狂傲的啊，我与你反对，因为我追讨你的日子已经来到。"},
{volumn:        24,chapter:        50,versenumber:        32,verse:"狂傲的必绊跌仆倒，无人扶起。我也必使火在他的城邑中着起来，将他四围所有的尽行烧灭。”"},
{volumn:        24,chapter:        50,versenumber:        33,verse:"万军之耶和华如此说：“以色列人和犹大人一同受欺压；凡掳掠他们的都紧紧抓住他们，不肯释放。"},
{volumn:        24,chapter:        50,versenumber:        34,verse:"他们的救赎主大有能力，万军之耶和华是他的名。他必伸清他们的冤，好使全地得平安，并搅扰巴比伦的居民。”"},
{volumn:        24,chapter:        50,versenumber:        35,verse:"耶和华说：““有刀剑临到迦勒底人和巴比伦的居民，并她的首领与智慧人。"},
{volumn:        24,chapter:        50,versenumber:        36,verse:"有刀剑临到矜夸的人，他们就成为愚昧；有刀剑临到她的勇士，他们就惊惶。"},
{volumn:        24,chapter:        50,versenumber:        37,verse:"有刀剑临到她的马匹、车辆，和其中杂族的人民；他们必像妇女一样。有刀剑临到她的宝物，就被抢夺。"},
{volumn:        24,chapter:        50,versenumber:        38,verse:"有干旱临到她的众水，就必干涸；因为这是有雕刻偶像之地，人因偶像而颠狂。"},
{volumn:        24,chapter:        50,versenumber:        39,verse:"所以旷野的走兽和豺狼必住在那里，鸵鸟也住在其中，永无人烟，世世代代无人居住。”"},
{volumn:        24,chapter:        50,versenumber:        40,verse:"耶和华说：“必无人住在那里，也无人在其中寄居，要像我倾覆所多玛、蛾摩拉，和邻近的城邑一样。"},
{volumn:        24,chapter:        50,versenumber:        41,verse:"看哪，有一种民从北方而来，并有一大国和许多君王被激动，从地极来到。"},
{volumn:        24,chapter:        50,versenumber:        42,verse:"他们拿弓和枪，性情残忍，不施怜悯；他们的声音像海浪砰訇。巴比伦城（原文是女子）啊，他们骑马，都摆队伍如上战场的人，要攻击你。"},
{volumn:        24,chapter:        50,versenumber:        43,verse:"“巴比伦王听见他们的风声，手就发软，痛苦将他抓住，疼痛仿佛产难的妇人。"},
{volumn:        24,chapter:        50,versenumber:        44,verse:"“仇敌必像狮子从约旦河边的丛林上来，攻击坚固的居所。转眼之间，我要使他们逃跑，离开这地。谁蒙拣选，我就派谁治理这地。谁能比我呢？谁能给我定规日期呢？有何牧人能在我面前站立得住呢？"},
{volumn:        24,chapter:        50,versenumber:        45,verse:"你们要听耶和华攻击巴比伦所说的谋略和他攻击迦勒底人之地所定的旨意。仇敌定要将他们群众微弱的拉去，定要使他们的居所荒凉。"},
{volumn:        24,chapter:        50,versenumber:        46,verse:"因巴比伦被取的声音，地就震动，人在列邦都听见呼喊的声音。”"},
{volumn:        24,chapter:        51,versenumber:         1,verse:"耶和华如此说：“我必使毁灭的风刮起，攻击巴比伦和住在立加米的人。"},
{volumn:        24,chapter:        51,versenumber:         2,verse:"我要打发外邦人来到巴比伦，簸扬他，使他的地空虚。在他遭祸的日子，他们要周围攻击她。"},
{volumn:        24,chapter:        51,versenumber:         3,verse:"拉弓的，要向拉弓的和贯甲挺身的射箭。不要怜惜她的少年人；要灭尽她的全军。"},
{volumn:        24,chapter:        51,versenumber:         4,verse:"他们必在迦勒底人之地被杀仆倒，在巴比伦的街上被刺透。”"},
{volumn:        24,chapter:        51,versenumber:         5,verse:"以色列和犹大虽然境内充满违背以色列圣者的罪，却没有被他的　神万军之耶和华丢弃。"},
{volumn:        24,chapter:        51,versenumber:         6,verse:"你们要从巴比伦中逃奔，各救自己的性命！不要陷在她的罪孽中一同灭亡；因为这是耶和华报仇的时候，他必向巴比伦施行报应。"},
{volumn:        24,chapter:        51,versenumber:         7,verse:"巴比伦素来是耶和华手中的金杯，使天下沉醉；万国喝了她的酒就颠狂了。"},
{volumn:        24,chapter:        51,versenumber:         8,verse:"巴比伦忽然倾覆毁坏；要为她哀号；为止她的疼痛，拿乳香或者可以治好。"},
{volumn:        24,chapter:        51,versenumber:         9,verse:"我们想医治巴比伦，她却没有治好。离开她吧！我们各人归回本国；因为她受的审判通于上天，达到穹苍。"},
{volumn:        24,chapter:        51,versenumber:        10,verse:"耶和华已经彰显我们的公义。来吧！我们可以在锡安报告耶和华我们　神的作为。"},
{volumn:        24,chapter:        51,versenumber:        11,verse:"你们要磨尖了箭头，抓住盾牌。耶和华定意攻击巴比伦，将她毁灭，所以激动了玛代君王的心；因这是耶和华报仇，就是为自己的殿报仇。"},
{volumn:        24,chapter:        51,versenumber:        12,verse:"你们要竖立大旗，攻击巴比伦的城墙；要坚固了望台，派定守望的设下埋伏；因为耶和华指着巴比伦居民所说的话、所定的意，他已经作成。"},
{volumn:        24,chapter:        51,versenumber:        13,verse:"住在众水之上多有财宝的啊，你的结局到了！你贪婪之量满了！"},
{volumn:        24,chapter:        51,versenumber:        14,verse:"万军之耶和华指着自己起誓说：“我必使敌人充满你，像蚂蚱一样；他们必呐喊攻击你。”"},
{volumn:        24,chapter:        51,versenumber:        15,verse:"耶和华用能力创造大地，用智慧建立世界，用聪明铺张穹苍。"},
{volumn:        24,chapter:        51,versenumber:        16,verse:"他一发声，空中便有多水激动；他使云雾从地极上腾。他造电随雨而闪，从他府库中带出风来。"},
{volumn:        24,chapter:        51,versenumber:        17,verse:"各人都成了畜类，毫无知识。各银匠都因他的偶像羞愧；他所铸的偶像本是虚假的，其中并无气息，"},
{volumn:        24,chapter:        51,versenumber:        18,verse:"都是虚无的，是迷惑人的工作，到追讨的时候，必被除灭。"},
{volumn:        24,chapter:        51,versenumber:        19,verse:"雅各的份不像这些，因他是造作万有的主；以色列也是他产业的支派。万军之耶和华是他的名。"},
{volumn:        24,chapter:        51,versenumber:        20,verse:"“你是我争战的斧子和打仗的兵器；我要用你打碎列国，用你毁灭列邦；"},
{volumn:        24,chapter:        51,versenumber:        21,verse:"用你打碎马和骑马的；用你打碎战车和坐在其上的；"},
{volumn:        24,chapter:        51,versenumber:        22,verse:"用你打碎男人和女人；用你打碎老年人和少年人；用你打碎壮丁和处女；"},
{volumn:        24,chapter:        51,versenumber:        23,verse:"用你打碎牧人和他的群畜；用你打碎农夫和他一对牛；用你打碎省长和副省长。”"},
{volumn:        24,chapter:        51,versenumber:        24,verse:"耶和华说：“我必在你们眼前报复巴比伦人和迦勒底居民在锡安所行的诸恶。”"},
{volumn:        24,chapter:        51,versenumber:        25,verse:"耶和华说：“你这行毁灭的山哪，就是毁灭天下的山，我与你反对。我必向你伸手，将你从山岩滚下去，使你成为烧毁的山。"},
{volumn:        24,chapter:        51,versenumber:        26,verse:"人必不从你那里取石头为房角石，也不取石头为根基石；你必永远荒凉。这是耶和华说的。”"},
{volumn:        24,chapter:        51,versenumber:        27,verse:"要在境内竖立大旗，在各国中吹角，使列国预备攻击巴比伦，将亚拉腊、米尼、亚实基拿各国招来攻击她；又派军长来攻击她，使马匹上来如蚂蚱，"},
{volumn:        24,chapter:        51,versenumber:        28,verse:"使列国和玛代君王，与省长和副省长，并他们所管全地之人，都预备攻击她。"},
{volumn:        24,chapter:        51,versenumber:        29,verse:"地必震动而瘠苦；因耶和华向巴比伦所定的旨意成立了，使巴比伦之地荒凉，无人居住。"},
{volumn:        24,chapter:        51,versenumber:        30,verse:"巴比伦的勇士止息争战，藏在坚垒之中。他们的勇力衰尽，好像妇女一样。巴比伦的住处有火着起，门闩都折断了。"},
{volumn:        24,chapter:        51,versenumber:        31,verse:"跑报的要彼此相遇，送信的要互相迎接，报告巴比伦王说：“城的四方被攻取了，"},
{volumn:        24,chapter:        51,versenumber:        32,verse:"渡口被占据了，苇塘被火烧了，兵丁也惊慌了。”"},
{volumn:        24,chapter:        51,versenumber:        33,verse:"万军之耶和华以色列的　神如此说：“巴比伦（原文是女子）城好像踹谷的禾场；再过片时，收割她的时候就到了。”"},
{volumn:        24,chapter:        51,versenumber:        34,verse:"以色列人说：“巴比伦王尼布甲尼撒吞灭我，压碎我，使我成为空虚的器皿。他像大鱼将我吞下，用我的美物充满他的肚腹，又将我赶出去。”"},
{volumn:        24,chapter:        51,versenumber:        35,verse:"锡安的居民要说：“巴比伦以强暴待我，损害我的身体，愿这罪归给她。”耶路撒冷人要说：“愿流我们血的罪，归到迦勒底的居民。”"},
{volumn:        24,chapter:        51,versenumber:        36,verse:"所以，耶和华如此说：“我必为你伸冤，为你报仇；我必使巴比伦的海枯竭，使她的泉源干涸。"},
{volumn:        24,chapter:        51,versenumber:        37,verse:"巴比伦必成为乱堆，为野狗的住处，令人惊骇、嗤笑，并且无人居住。"},
{volumn:        24,chapter:        51,versenumber:        38,verse:"他们要像少壮狮子咆哮，像小狮子吼叫。"},
{volumn:        24,chapter:        51,versenumber:        39,verse:"他们火热的时候，我必为他们设摆酒席，使他们沉醉，好叫他们快乐，睡了长觉，永不醒起。这是耶和华说的。"},
{volumn:        24,chapter:        51,versenumber:        40,verse:"我必使他们像羊羔、像公绵羊和公山羊下到宰杀之地。”"},
{volumn:        24,chapter:        51,versenumber:        41,verse:"“示沙克（就是巴比伦）何竟被攻取？天下所称赞的何竟被占据？巴比伦在列国中何竟变为荒场？"},
{volumn:        24,chapter:        51,versenumber:        42,verse:"海水涨起，漫过巴比伦；她被许多海浪遮盖。"},
{volumn:        24,chapter:        51,versenumber:        43,verse:"她的城邑变为荒场、旱地、沙漠，无人居住，无人经过之地。"},
{volumn:        24,chapter:        51,versenumber:        44,verse:"我必刑罚巴比伦的彼勒，使他吐出所吞的。万民必不再流归他那里；巴比伦的城墙也必坍塌了。"},
{volumn:        24,chapter:        51,versenumber:        45,verse:"“我的民哪，你们要从其中出去！各人拯救自己，躲避耶和华的烈怒。"},
{volumn:        24,chapter:        51,versenumber:        46,verse:"你们不要心惊胆怯，也不要因境内所听见的风声惧怕；因为这年有风声传来；那年也有风声传来，境内有强暴的事，官长攻击官长。"},
{volumn:        24,chapter:        51,versenumber:        47,verse:"“日子将到，我必刑罚巴比伦雕刻的偶像。她全地必然抱愧；她被杀的人必在其中仆倒。"},
{volumn:        24,chapter:        51,versenumber:        48,verse:"那时，天地和其中所有的，必因巴比伦欢呼，因为行毁灭的要从北方来到她那里。这是耶和华说的。"},
{volumn:        24,chapter:        51,versenumber:        49,verse:"巴比伦怎样使以色列被杀的人仆倒，照样，她全地被杀的人也必在巴比伦仆倒。”"},
{volumn:        24,chapter:        51,versenumber:        50,verse:"你们躲避刀剑的要快走，不要站住！要在远方记念耶和华，心中追想耶路撒冷。"},
{volumn:        24,chapter:        51,versenumber:        51,verse:"我们听见辱骂就蒙羞，满面惭愧，因为外邦人进入耶和华殿的圣所。"},
{volumn:        24,chapter:        51,versenumber:        52,verse:"耶和华说：“日子将到，我必刑罚巴比伦雕刻的偶像，通国受伤的人必唉哼。"},
{volumn:        24,chapter:        51,versenumber:        53,verse:"巴比伦虽升到天上，虽使她坚固的高处更坚固，还有行毁灭的从我这里到她那里。这是耶和华说的。”"},
{volumn:        24,chapter:        51,versenumber:        54,verse:"有哀号的声音从巴比伦出来；有大毁灭的响声从迦勒底人之地发出。"},
{volumn:        24,chapter:        51,versenumber:        55,verse:"因耶和华使巴比伦变为荒场，使其中的大声灭绝。仇敌仿佛众水，波浪砰訇，响声已经发出。"},
{volumn:        24,chapter:        51,versenumber:        56,verse:"这是行毁灭的临到巴比伦。巴比伦的勇士被捉住，他们的弓折断了；因为耶和华是施行报应的　神，必定施行报应。"},
{volumn:        24,chapter:        51,versenumber:        57,verse:"君王，名为万军之耶和华的说：“我必使巴比伦的首领、智慧人、省长、副省长，和勇士都沉醉，使他们睡了长觉，永不醒起。"},
{volumn:        24,chapter:        51,versenumber:        58,verse:"“万军之耶和华如此说：巴比伦宽阔的城墙必全然倾倒；她高大的城门必被火焚烧。众民所劳碌的必致虚空；列国所劳碌的被火焚烧，他们都必困乏。”"},
{volumn:        24,chapter:        51,versenumber:        59,verse:"犹大王西底家在位第四年，上巴比伦去的时候，玛西雅的孙子、尼利亚的儿子西莱雅与王同去（西莱雅是王宫的大臣），先知耶利米有话吩咐他。"},
{volumn:        24,chapter:        51,versenumber:        60,verse:"耶利米将一切要临到巴比伦的灾祸，就是论到巴比伦的一切话，写在书上。"},
{volumn:        24,chapter:        51,versenumber:        61,verse:"耶利米对西莱雅说：“你到了巴比伦务要念这书上的话。”"},
{volumn:        24,chapter:        51,versenumber:        62,verse:"又说：“耶和华啊，你曾论到这地方说：要剪除，甚至连人带牲畜没有在这里居住的，必永远荒凉。"},
{volumn:        24,chapter:        51,versenumber:        63,verse:"你念完了这书，就把一块石头拴在书上，扔在幼发拉底河中，"},
{volumn:        24,chapter:        51,versenumber:        64,verse:"说：‘巴比伦因耶和华所要降与她的灾祸，必如此沉下去，不再兴起，人民也必困乏。’”耶利米的话到此为止。"},
{volumn:        24,chapter:        52,versenumber:         1,verse:"西底家登基的时候年二十一岁，在耶路撒冷作王十一年。他母亲名叫哈慕他，是立拿人耶利米的女儿。"},
{volumn:        24,chapter:        52,versenumber:         2,verse:"西底家行耶和华眼中看为恶的事，是照约雅敬一切所行的。"},
{volumn:        24,chapter:        52,versenumber:         3,verse:"因此耶和华的怒气在耶路撒冷和犹大发作，以致将人民从自己的面前赶出。"},
{volumn:        24,chapter:        52,versenumber:         4,verse:"西底家背叛巴比伦王。他作王第九年十月初十日，巴比伦王尼布甲尼撒率领全军来攻击耶路撒冷，对城安营，四围筑垒攻城。"},
{volumn:        24,chapter:        52,versenumber:         5,verse:"于是城被围困直到西底家王十一年。"},
{volumn:        24,chapter:        52,versenumber:         6,verse:"四月初九日，城里有大饥荒，甚至百姓都没有粮食。"},
{volumn:        24,chapter:        52,versenumber:         7,verse:"城被攻破，一切兵丁就在夜间从靠近王园两城中间的门，出城逃跑；迦勒底人正在四围攻城。他们就往亚拉巴逃去。"},
{volumn:        24,chapter:        52,versenumber:         8,verse:"迦勒底的军队追赶西底家王，在耶利哥的平原追上他。他的全军都离开他四散了。"},
{volumn:        24,chapter:        52,versenumber:         9,verse:"迦勒底人就拿住王，带他到在哈马地利比拉的巴比伦王那里；巴比伦王便审判他。"},
{volumn:        24,chapter:        52,versenumber:        10,verse:"巴比伦王在西底家眼前杀了他的众子，又在利比拉杀了犹大的一切首领，"},
{volumn:        24,chapter:        52,versenumber:        11,verse:"并且剜了西底家的眼睛，用铜链锁着他，带到巴比伦去，将他囚在监里，直到他死的日子。"},
{volumn:        24,chapter:        52,versenumber:        12,verse:"巴比伦王尼布甲尼撒十九年五月初十日，在巴比伦王面前侍立的护卫长尼布撒拉旦进入耶路撒冷，"},
{volumn:        24,chapter:        52,versenumber:        13,verse:"用火焚烧耶和华的殿和王宫，又焚烧耶路撒冷的房屋，就是各大户家的房屋。"},
{volumn:        24,chapter:        52,versenumber:        14,verse:"跟从护卫长迦勒底的全军就拆毁耶路撒冷四围的城墙。"},
{volumn:        24,chapter:        52,versenumber:        15,verse:"那时护卫长尼布撒拉旦将民中最穷的和城里所剩下的百姓，并已经投降巴比伦王的人，以及大众所剩下的人，都掳去了。"},
{volumn:        24,chapter:        52,versenumber:        16,verse:"但护卫长尼布撒拉旦留下些民中最穷的，使他们修理葡萄园，耕种田地。"},
{volumn:        24,chapter:        52,versenumber:        17,verse:"耶和华殿的铜柱并殿内的盆座和铜海，迦勒底人都打碎了，将那铜运到巴比伦去了，"},
{volumn:        24,chapter:        52,versenumber:        18,verse:"又带去锅、铲子、蜡剪、盘子、调羹，并所用的一切铜器、"},
{volumn:        24,chapter:        52,versenumber:        19,verse:"杯、火鼎、碗、盆、灯台、调羹、爵，无论金的银的，护卫长也都带去了。"},
{volumn:        24,chapter:        52,versenumber:        20,verse:"所罗门为耶和华殿所造的两根铜柱、一个铜海，并座下的十二只铜牛，这一切的铜多得无法可称。"},
{volumn:        24,chapter:        52,versenumber:        21,verse:"这一根柱子高十八肘，厚四指，是空的，围十二肘。"},
{volumn:        24,chapter:        52,versenumber:        22,verse:"柱上有铜顶，高五肘；铜顶的周围有网子和石榴，都是铜的。那一根柱子照此一样，也有石榴。"},
{volumn:        24,chapter:        52,versenumber:        23,verse:"柱子四面有九十六个石榴，在网子周围，共有一百石榴。"},
{volumn:        24,chapter:        52,versenumber:        24,verse:"护卫长拿住大祭司西莱雅、副祭司西番亚，和三个把门的，"},
{volumn:        24,chapter:        52,versenumber:        25,verse:"又从城中拿住一个管理兵丁的官（或译：太监），并在城里所遇常见王面的七个人和检点国民军长的书记，以及城里所遇见的国民六十个人。"},
{volumn:        24,chapter:        52,versenumber:        26,verse:"护卫长尼布撒拉旦将这些人带到利比拉的巴比伦王那里。"},
{volumn:        24,chapter:        52,versenumber:        27,verse:"巴比伦王就把他们击杀在哈马地的利比拉。这样，犹大人被掳去离开本地。"},
{volumn:        24,chapter:        52,versenumber:        28,verse:"尼布甲尼撒所掳的民数记在下面：在他第七年掳去犹大人三千零二十三名；"},
{volumn:        24,chapter:        52,versenumber:        29,verse:"尼布甲尼撒十八年从耶路撒冷掳去八百三十二人；"},
{volumn:        24,chapter:        52,versenumber:        30,verse:"尼布甲尼撒二十三年，护卫长尼布撒拉旦掳去犹大人七百四十五名；共有四千六百人。"},
{volumn:        24,chapter:        52,versenumber:        31,verse:"犹大王约雅斤被掳后三十七年，巴比伦王以未·米罗达元年十二月二十五日，使犹大王约雅斤抬头，提他出监，"},
{volumn:        24,chapter:        52,versenumber:        32,verse:"又对他说恩言，使他的位高过与他一同在巴比伦众王的位，"},
{volumn:        24,chapter:        52,versenumber:        33,verse:"给他脱了囚服。他终身在巴比伦王面前吃饭。"},
{volumn:        24,chapter:        52,versenumber:        34,verse:"巴比伦王赐他所需用的食物，日日赐他一份，终身是这样，直到他死的日子。"},
{volumn:        25,chapter:         1,versenumber:         1,verse:"先前满有人民的城，现在何竟独坐！先前在列国中为大的，现在竟如寡妇；先前在诸省中为王后的，现在成为进贡的。"},
{volumn:        25,chapter:         1,versenumber:         2,verse:"她夜间痛哭，泪流满腮；在一切所亲爱的中间没有一个安慰她的。她的朋友都以诡诈待她，成为她的仇敌。"},
{volumn:        25,chapter:         1,versenumber:         3,verse:"犹大因遭遇苦难，又因多服劳苦就迁到外邦。她住在列国中，寻不着安息；追逼她的都在狭窄之地将她追上。"},
{volumn:        25,chapter:         1,versenumber:         4,verse:"锡安的路径因无人来守圣节就悲伤；她的城门凄凉；她的祭司叹息；她的处女受艰难，自己也愁苦。"},
{volumn:        25,chapter:         1,versenumber:         5,verse:"她的敌人为首；她的仇敌亨通；因耶和华为她许多的罪过使她受苦；她的孩童被敌人掳去。"},
{volumn:        25,chapter:         1,versenumber:         6,verse:"锡安城（原文是女子；下同）的威荣全都失去。她的首领像找不着草场的鹿；在追赶的人前无力行走。"},
{volumn:        25,chapter:         1,versenumber:         7,verse:"耶路撒冷在困苦窘迫之时，就追想古时一切的乐境。她百姓落在敌人手中，无人救济；敌人看见，就因她的荒凉嗤笑。"},
{volumn:        25,chapter:         1,versenumber:         8,verse:"耶路撒冷大大犯罪，所以成为不洁之物；素来尊敬她的，见她赤露就都藐视她；她自己也叹息退后。"},
{volumn:        25,chapter:         1,versenumber:         9,verse:"她的污秽是在衣襟上；她不思想自己的结局，所以非常地败落，无人安慰她。她说：“耶和华啊，求你看我的苦难，因为仇敌夸大。”"},
{volumn:        25,chapter:         1,versenumber:        10,verse:"敌人伸手，夺取她的美物；她眼见外邦人进入她的圣所。论这外邦人，你曾吩咐不可入你的会中。"},
{volumn:        25,chapter:         1,versenumber:        11,verse:"她的民都叹息，寻求食物；他们用美物换粮食，要救性命。他们说：“耶和华啊，求你观看，因为我甚是卑贱。”"},
{volumn:        25,chapter:         1,versenumber:        12,verse:"你们一切过路的人哪，这事你们不介意吗？你们要观看：有像这临到我的痛苦没有？就是耶和华在他发烈怒的日子使我所受的苦？"},
{volumn:        25,chapter:         1,versenumber:        13,verse:"他从高天使火进入我的骨头，克制了我；他铺下网罗，绊我的脚，使我转回；他使我终日凄凉发昏。"},
{volumn:        25,chapter:         1,versenumber:        14,verse:"我罪过的轭是他手所绑的，犹如轭绳缚在我颈项上；他使我的力量衰败。主将我交在我所不能敌挡的人手中。"},
{volumn:        25,chapter:         1,versenumber:        15,verse:"主轻弃我中间的一切勇士，招聚多人（原文是大会）攻击我，要压碎我的少年人。主将犹大居民踹下，像在酒榨中一样。"},
{volumn:        25,chapter:         1,versenumber:        16,verse:"我因这些事哭泣；我眼泪汪汪；因为那当安慰我、救我性命的，离我甚远。我的儿女孤苦，因为仇敌得了胜。"},
{volumn:        25,chapter:         1,versenumber:        17,verse:"锡安举手，无人安慰。耶和华论雅各已经出令，使四围的人作他仇敌；耶路撒冷在他们中间像不洁之物。"},
{volumn:        25,chapter:         1,versenumber:        18,verse:"耶和华是公义的！他这样待我，是因我违背他的命令。众民哪，请听我的话，看我的痛苦；我的处女和少年人都被掳去。"},
{volumn:        25,chapter:         1,versenumber:        19,verse:"我招呼我所亲爱的，他们却愚弄我。我的祭司和长老正寻求食物、救性命的时候，就在城中绝气。"},
{volumn:        25,chapter:         1,versenumber:        20,verse:"耶和华啊，求你观看，因为我在急难中。我心肠扰乱；我心在我里面翻转，因我大大悖逆。在外，刀剑使人丧子；在家，犹如死亡。"},
{volumn:        25,chapter:         1,versenumber:        21,verse:"听见我叹息的有人；安慰我的却无人！我的仇敌都听见我所遭的患难；因你做这事，他们都喜乐。你必使你报告的日子来到，他们就像我一样。"},
{volumn:        25,chapter:         1,versenumber:        22,verse:"愿他们的恶行都呈在你面前；你怎样因我的一切罪过待我，求你照样待他们；因我叹息甚多，心中发昏。"},
{volumn:        25,chapter:         2,versenumber:         1,verse:"主何竟发怒，使黑云遮蔽锡安城！他将以色列的华美从天扔在地上；在他发怒的日子并不记念自己的脚凳。"},
{volumn:        25,chapter:         2,versenumber:         2,verse:"主吞灭雅各一切的住处，并不顾惜。他发怒倾覆犹大民的保障，使这保障坍倒在地；他辱没这国和其中的首领。"},
{volumn:        25,chapter:         2,versenumber:         3,verse:"他发烈怒，把以色列的角全然砍断，在仇敌面前收回右手。他像火焰四围吞灭，将雅各烧毁。"},
{volumn:        25,chapter:         2,versenumber:         4,verse:"他张弓好像仇敌；他站着举起右手，如同敌人将悦人眼目的，尽行杀戮。在锡安百姓的帐棚上倒出他的忿怒，像火一样。"},
{volumn:        25,chapter:         2,versenumber:         5,verse:"主如仇敌吞灭以色列和锡安的一切宫殿，拆毁百姓的保障；在犹大民中加增悲伤哭号。"},
{volumn:        25,chapter:         2,versenumber:         6,verse:"他强取自己的帐幕，好像是园中的窝棚，毁坏他的聚会之处。耶和华使圣节和安息日在锡安都被忘记，又在怒气的愤恨中藐视君王和祭司。"},
{volumn:        25,chapter:         2,versenumber:         7,verse:"耶和华丢弃自己的祭坛，憎恶自己的圣所，将宫殿的墙垣交付仇敌。他们在耶和华的殿中喧嚷，像在圣会之日一样。"},
{volumn:        25,chapter:         2,versenumber:         8,verse:"耶和华定意拆毁锡安的城墙；他拉了准绳，不将手收回，定要毁灭。他使外郭和城墙都悲哀，一同衰败。"},
{volumn:        25,chapter:         2,versenumber:         9,verse:"锡安的门都陷入地内；主将她的门闩毁坏，折断。她的君王和首领落在没有律法的列国中；她的先知不得见耶和华的异象。"},
{volumn:        25,chapter:         2,versenumber:        10,verse:"锡安城的长老坐在地上默默无声；他们扬起尘土落在头上，腰束麻布；耶路撒冷的处女垂头至地。"},
{volumn:        25,chapter:         2,versenumber:        11,verse:"我眼中流泪，以致失明；我的心肠扰乱，肝胆涂地，都因我众民遭毁灭，又因孩童和吃奶的在城内街上发昏。"},
{volumn:        25,chapter:         2,versenumber:        12,verse:"那时，他们在城内街上发昏，好像受伤的，在母亲的怀里，将要丧命；对母亲说：“谷、酒在哪里呢？”"},
{volumn:        25,chapter:         2,versenumber:        13,verse:"耶路撒冷的民哪，我可用什么向你证明呢？我可用什么与你相比呢？锡安的民哪，我可拿什么和你比较，好安慰你呢？因为你的裂口大如海，谁能医治你呢？"},
{volumn:        25,chapter:         2,versenumber:        14,verse:"你的先知为你见虚假和愚昧的异象，并没有显露你的罪孽，使你被掳的归回；却为你见虚假的默示和使你被赶出本境的缘故。"},
{volumn:        25,chapter:         2,versenumber:        15,verse:"凡过路的都向你拍掌。他们向耶路撒冷城嗤笑，摇头，说：“难道人所称为全美的，称为全地所喜悦的，就是这城吗？”"},
{volumn:        25,chapter:         2,versenumber:        16,verse:"你的仇敌都向你大大张口；他们嗤笑，又切齿说：“我们吞灭她。这真是我们所盼望的日子临到了！我们亲眼看见了！”"},
{volumn:        25,chapter:         2,versenumber:        17,verse:"耶和华成就了他所定的，应验了他古时所命定的。他倾覆了，并不顾惜，使你的仇敌向你夸耀；使你敌人的角也被高举。"},
{volumn:        25,chapter:         2,versenumber:        18,verse:"锡安民的心哀求主。锡安的城墙啊，愿你流泪如河，昼夜不息；愿你眼中的瞳人泪流不止。"},
{volumn:        25,chapter:         2,versenumber:        19,verse:"夜间，每逢交更的时候要起来呼喊，在主面前倾心如水。你的孩童在各市口上受饿发昏；你要为他们的性命向主举手祷告。"},
{volumn:        25,chapter:         2,versenumber:        20,verse:"耶和华啊，求你观看！见你向谁这样行？妇人岂可吃自己所生育手里所摇弄的婴孩吗？祭司和先知岂可在主的圣所中被杀戮吗？"},
{volumn:        25,chapter:         2,versenumber:        21,verse:"少年人和老年人都在街上躺卧；我的处女和壮丁都倒在刀下；你发怒的日子杀死他们。你杀了，并不顾惜。"},
{volumn:        25,chapter:         2,versenumber:        22,verse:"你招聚四围惊吓我的，像在大会的日子招聚人一样。耶和华发怒的日子，无人逃脱，无人存留。我所摇弄所养育的婴孩，仇敌都杀净了。"},
{volumn:        25,chapter:         3,versenumber:         1,verse:"我是因耶和华忿怒的杖，遭遇困苦的人。"},
{volumn:        25,chapter:         3,versenumber:         2,verse:"他引导我，使我行在黑暗中，不行在光明里。"},
{volumn:        25,chapter:         3,versenumber:         3,verse:"他真是终日再三反手攻击我。"},
{volumn:        25,chapter:         3,versenumber:         4,verse:"他使我的皮肉枯干；他折断（或译：压伤）我的骨头。"},
{volumn:        25,chapter:         3,versenumber:         5,verse:"他筑垒攻击我，用苦楚（原文是苦胆）和艰难围困我。"},
{volumn:        25,chapter:         3,versenumber:         6,verse:"他使我住在幽暗之处，像死了许久的人一样。"},
{volumn:        25,chapter:         3,versenumber:         7,verse:"他用篱笆围住我，使我不能出去；他使我的铜链沉重。"},
{volumn:        25,chapter:         3,versenumber:         8,verse:"我哀号求救；他使我的祷告不得上达。"},
{volumn:        25,chapter:         3,versenumber:         9,verse:"他用凿过的石头挡住我的道；他使我的路弯曲。"},
{volumn:        25,chapter:         3,versenumber:        10,verse:"他向我如熊埋伏，如狮子在隐密处。"},
{volumn:        25,chapter:         3,versenumber:        11,verse:"他使我转离正路，将我撕碎，使我凄凉。"},
{volumn:        25,chapter:         3,versenumber:        12,verse:"他张弓将我当作箭靶子。"},
{volumn:        25,chapter:         3,versenumber:        13,verse:"他把箭袋中的箭射入我的肺腑。"},
{volumn:        25,chapter:         3,versenumber:        14,verse:"我成了众民的笑话；他们终日以我为歌曲。"},
{volumn:        25,chapter:         3,versenumber:        15,verse:"他用苦楚充满我，使我饱用茵陈。"},
{volumn:        25,chapter:         3,versenumber:        16,verse:"他又用沙石碜断我的牙，用灰尘将我蒙蔽。"},
{volumn:        25,chapter:         3,versenumber:        17,verse:"你使我远离平安，我忘记好处。"},
{volumn:        25,chapter:         3,versenumber:        18,verse:"我就说：“我的力量衰败；我在耶和华那里毫无指望！”"},
{volumn:        25,chapter:         3,versenumber:        19,verse:"耶和华啊，求你记念我如茵陈和苦胆的困苦窘迫。"},
{volumn:        25,chapter:         3,versenumber:        20,verse:"我心想念这些，就在里面忧闷。"},
{volumn:        25,chapter:         3,versenumber:        21,verse:"我想起这事，心里就有指望。"},
{volumn:        25,chapter:         3,versenumber:        22,verse:"我们不致消灭，是出于耶和华诸般的慈爱；是因他的怜悯不致断绝。"},
{volumn:        25,chapter:         3,versenumber:        23,verse:"每早晨，这都是新的；你的诚实极其广大！"},
{volumn:        25,chapter:         3,versenumber:        24,verse:"我心里说：“耶和华是我的份，因此，我要仰望他。”"},
{volumn:        25,chapter:         3,versenumber:        25,verse:"凡等候耶和华，心里寻求他的，耶和华必施恩给他。"},
{volumn:        25,chapter:         3,versenumber:        26,verse:"人仰望耶和华，静默等候他的救恩，这原是好的。"},
{volumn:        25,chapter:         3,versenumber:        27,verse:"人在幼年负轭，这原是好的。"},
{volumn:        25,chapter:         3,versenumber:        28,verse:"他当独坐无言，因为这是耶和华加在他身上的。"},
{volumn:        25,chapter:         3,versenumber:        29,verse:"他当口贴尘埃，或者有指望。"},
{volumn:        25,chapter:         3,versenumber:        30,verse:"他当由人打他的腮颊，要满受凌辱。"},
{volumn:        25,chapter:         3,versenumber:        31,verse:"因为主必不永远丢弃人。"},
{volumn:        25,chapter:         3,versenumber:        32,verse:"主虽使人忧愁，还要照他诸般的慈爱发怜悯。"},
{volumn:        25,chapter:         3,versenumber:        33,verse:"因他并不甘心使人受苦，使人忧愁。"},
{volumn:        25,chapter:         3,versenumber:        34,verse:"人将世上被囚的踹（原文是压）在脚下，"},
{volumn:        25,chapter:         3,versenumber:        35,verse:"或在至高者面前屈枉人，"},
{volumn:        25,chapter:         3,versenumber:        36,verse:"或在人的讼事上颠倒是非，这都是主看不上的。"},
{volumn:        25,chapter:         3,versenumber:        37,verse:"除非主命定，谁能说成就成呢？"},
{volumn:        25,chapter:         3,versenumber:        38,verse:"祸福不都出于至高者的口吗？"},
{volumn:        25,chapter:         3,versenumber:        39,verse:"活人因自己的罪受罚，为何发怨言呢？"},
{volumn:        25,chapter:         3,versenumber:        40,verse:"我们当深深考察自己的行为，再归向耶和华。"},
{volumn:        25,chapter:         3,versenumber:        41,verse:"我们当诚心向天上的　神举手祷告。"},
{volumn:        25,chapter:         3,versenumber:        42,verse:"我们犯罪背逆，你并不赦免。"},
{volumn:        25,chapter:         3,versenumber:        43,verse:"你自被怒气遮蔽，追赶我们；你施行杀戮，并不顾惜。"},
{volumn:        25,chapter:         3,versenumber:        44,verse:"你以黑云遮蔽自己，以致祷告不得透入。"},
{volumn:        25,chapter:         3,versenumber:        45,verse:"你使我们在万民中成为污秽和渣滓。"},
{volumn:        25,chapter:         3,versenumber:        46,verse:"我们的仇敌都向我们大大张口。"},
{volumn:        25,chapter:         3,versenumber:        47,verse:"恐惧和陷坑，残害和毁灭，都临近我们。"},
{volumn:        25,chapter:         3,versenumber:        48,verse:"因我众民遭的毁灭，我就眼泪下流如河。"},
{volumn:        25,chapter:         3,versenumber:        49,verse:"我的眼多多流泪，总不止息，"},
{volumn:        25,chapter:         3,versenumber:        50,verse:"直等耶和华垂顾，从天观看。"},
{volumn:        25,chapter:         3,versenumber:        51,verse:"因我本城的众民，我的眼，使我的心伤痛。"},
{volumn:        25,chapter:         3,versenumber:        52,verse:"无故与我为仇的追逼我，像追雀鸟一样。"},
{volumn:        25,chapter:         3,versenumber:        53,verse:"他们使我的命在牢狱中断绝，并将一块石头抛在我身上。"},
{volumn:        25,chapter:         3,versenumber:        54,verse:"众水流过我头，我说：“我命断绝了！”"},
{volumn:        25,chapter:         3,versenumber:        55,verse:"耶和华啊，我从深牢中求告你的名。"},
{volumn:        25,chapter:         3,versenumber:        56,verse:"你曾听见我的声音；我求你解救，你不要掩耳不听。"},
{volumn:        25,chapter:         3,versenumber:        57,verse:"我求告你的日子，你临近我，说：“不要惧怕！”"},
{volumn:        25,chapter:         3,versenumber:        58,verse:"主啊，你伸明了我的冤；你救赎了我的命。"},
{volumn:        25,chapter:         3,versenumber:        59,verse:"耶和华啊，你见了我受的委屈；求你为我伸冤。"},
{volumn:        25,chapter:         3,versenumber:        60,verse:"他们仇恨我，谋害我，你都看见了。"},
{volumn:        25,chapter:         3,versenumber:        61,verse:"耶和华啊，你听见他们辱骂我的话，知道他们向我所设的计，"},
{volumn:        25,chapter:         3,versenumber:        62,verse:"并那些起来攻击我的人口中所说的话，以及终日向我所设的计谋。"},
{volumn:        25,chapter:         3,versenumber:        63,verse:"求你观看，他们坐下，起来，都以我为歌曲。"},
{volumn:        25,chapter:         3,versenumber:        64,verse:"耶和华啊，你要按着他们手所做的向他们施行报应。"},
{volumn:        25,chapter:         3,versenumber:        65,verse:"你要使他们心里刚硬，使你的咒诅临到他们。"},
{volumn:        25,chapter:         3,versenumber:        66,verse:"你要发怒追赶他们，从耶和华的天下除灭他们。"},
{volumn:        25,chapter:         4,versenumber:         1,verse:"黄金何其失光！纯金何其变色！圣所的石头倒在各市口上。"},
{volumn:        25,chapter:         4,versenumber:         2,verse:"锡安宝贵的众子好比精金，现在何竟算为窑匠手所做的瓦瓶？"},
{volumn:        25,chapter:         4,versenumber:         3,verse:"野狗尚且把奶乳哺其子，我民的妇人倒成为残忍，好像旷野的鸵鸟一般。"},
{volumn:        25,chapter:         4,versenumber:         4,verse:"吃奶孩子的舌头因干渴贴住上膛；孩童求饼，无人擘给他们。"},
{volumn:        25,chapter:         4,versenumber:         5,verse:"素来吃美好食物的，现今在街上变为孤寒；素来卧朱红褥子的，现今躺卧粪堆。"},
{volumn:        25,chapter:         4,versenumber:         6,verse:"都因我众民的罪孽比所多玛的罪还大；所多玛虽然无人加手于她，还是转眼之间被倾覆。"},
{volumn:        25,chapter:         4,versenumber:         7,verse:"锡安的贵胄素来比雪纯净，比奶更白；他们的身体比红宝玉（或译：珊瑚）更红，像光润的蓝宝石一样。"},
{volumn:        25,chapter:         4,versenumber:         8,verse:"现在他们的面貌比煤炭更黑，以致在街上无人认识；他们的皮肤紧贴骨头，枯干如同槁木。"},
{volumn:        25,chapter:         4,versenumber:         9,verse:"饿死的不如被刀杀的，因为这是缺了田间的土产，就身体衰弱，渐渐消灭。"},
{volumn:        25,chapter:         4,versenumber:        10,verse:"慈心的妇人，当我众民被毁灭的时候，亲手煮自己的儿女作为食物。"},
{volumn:        25,chapter:         4,versenumber:        11,verse:"耶和华发怒成就他所定的，倒出他的烈怒；在锡安使火着起，烧毁锡安的根基。"},
{volumn:        25,chapter:         4,versenumber:        12,verse:"地上的君王和世上的居民都不信敌人和仇敌能进耶路撒冷的城门。"},
{volumn:        25,chapter:         4,versenumber:        13,verse:"这都因她先知的罪恶和祭司的罪孽；他们在城中流了义人的血。"},
{volumn:        25,chapter:         4,versenumber:        14,verse:"他们在街上如瞎子乱走，又被血玷污，以致人不能摸他们的衣服。"},
{volumn:        25,chapter:         4,versenumber:        15,verse:"人向他们喊着说：“不洁净的，躲开，躲开！不要挨近我！”他们逃走飘流的时候，列国中有人说：“他们不可仍在这里寄居。”"},
{volumn:        25,chapter:         4,versenumber:        16,verse:"耶和华发怒，将他们分散，不再眷顾他们；人不重看祭司，也不厚待长老。"},
{volumn:        25,chapter:         4,versenumber:        17,verse:"我们仰望人来帮助，以致眼目失明，还是枉然；我们所盼望的，竟盼望一个不能救人的国！"},
{volumn:        25,chapter:         4,versenumber:        18,verse:"仇敌追赶我们的脚步像打猎的，以致我们不敢在自己的街上行走。我们的结局临近；我们的日子满足；我们的结局来到了。"},
{volumn:        25,chapter:         4,versenumber:        19,verse:"追赶我们的比空中的鹰更快；他们在山上追逼我们，在旷野埋伏，等候我们。"},
{volumn:        25,chapter:         4,versenumber:        20,verse:"耶和华的受膏者好比我们鼻中的气，在他们的坑中被捉住；我们曾论到他说：“我们必在他荫下，在列国中存活。”"},
{volumn:        25,chapter:         4,versenumber:        21,verse:"住乌斯地的以东民哪，只管欢喜快乐；苦杯也必传到你那里；你必喝醉，以致露体。"},
{volumn:        25,chapter:         4,versenumber:        22,verse:"锡安的民哪，你罪孽的刑罚受足了，耶和华必不使你再被掳去。以东的民哪，他必追讨你的罪孽，显露你的罪恶。"},
{volumn:        25,chapter:         5,versenumber:         1,verse:"耶和华啊，求你记念我们所遭遇的事，观看我们所受的凌辱。"},
{volumn:        25,chapter:         5,versenumber:         2,verse:"我们的产业归与外邦人；我们的房屋归与外路人。"},
{volumn:        25,chapter:         5,versenumber:         3,verse:"我们是无父的孤儿；我们的母亲好像寡妇。"},
{volumn:        25,chapter:         5,versenumber:         4,verse:"我们出钱才得水喝；我们的柴是人卖给我们的。"},
{volumn:        25,chapter:         5,versenumber:         5,verse:"追赶我们的，到了我们的颈项上；我们疲乏不得歇息。"},
{volumn:        25,chapter:         5,versenumber:         6,verse:"我们投降埃及人和亚述人，为要得粮吃饱。"},
{volumn:        25,chapter:         5,versenumber:         7,verse:"我们列祖犯罪，而今不在了；我们担当他们的罪孽。"},
{volumn:        25,chapter:         5,versenumber:         8,verse:"奴仆辖制我们，无人救我们脱离他们的手。"},
{volumn:        25,chapter:         5,versenumber:         9,verse:"因为旷野的刀剑，我们冒着险才得粮食。"},
{volumn:        25,chapter:         5,versenumber:        10,verse:"因饥饿燥热，我们的皮肤就黑如炉。"},
{volumn:        25,chapter:         5,versenumber:        11,verse:"敌人在锡安玷污妇人，在犹大的城邑玷污处女。"},
{volumn:        25,chapter:         5,versenumber:        12,verse:"他们吊起首领的手，也不尊敬老人的面。"},
{volumn:        25,chapter:         5,versenumber:        13,verse:"少年人扛磨石，孩童背木柴，都绊跌了。"},
{volumn:        25,chapter:         5,versenumber:        14,verse:"老年人在城门口断绝；少年人不再作乐。"},
{volumn:        25,chapter:         5,versenumber:        15,verse:"我们心中的快乐止息，跳舞变为悲哀。"},
{volumn:        25,chapter:         5,versenumber:        16,verse:"冠冕从我们的头上落下；我们犯罪了，我们有祸了！"},
{volumn:        25,chapter:         5,versenumber:        17,verse:"这些事我们心里发昏，我们的眼睛昏花。"},
{volumn:        25,chapter:         5,versenumber:        18,verse:"锡安山荒凉，野狗（或译：狐狸）行在其上。"},
{volumn:        25,chapter:         5,versenumber:        19,verse:"耶和华啊，你存到永远；你的宝座存到万代。"},
{volumn:        25,chapter:         5,versenumber:        20,verse:"你为何永远忘记我们？为何许久离弃我们？"},
{volumn:        25,chapter:         5,versenumber:        21,verse:"耶和华啊，求你使我们向你回转，我们便得回转。求你复新我们的日子，像古时一样。"},
{volumn:        25,chapter:         5,versenumber:        22,verse:"你竟全然弃绝我们，向我们大发烈怒？"},
{volumn:        26,chapter:         1,versenumber:         1,verse:"当三十年四月初五日，以西结（原文是我）在迦巴鲁河边被掳的人中，天就开了，得见　神的异象。"},
{volumn:        26,chapter:         1,versenumber:         2,verse:"正是约雅斤王被掳去第五年四月初五日，"},
{volumn:        26,chapter:         1,versenumber:         3,verse:"在迦勒底人之地、迦巴鲁河边，耶和华的话特特临到布西的儿子祭司以西结；耶和华的灵（原文是手）降在他身上。"},
{volumn:        26,chapter:         1,versenumber:         4,verse:"我观看，见狂风从北方刮来，随着有一朵包括闪烁火的大云，周围有光辉；从其中的火内发出好像光耀的精金；"},
{volumn:        26,chapter:         1,versenumber:         5,verse:"又从其中显出四个活物的形像来。他们的形状是这样：有人的形像，"},
{volumn:        26,chapter:         1,versenumber:         6,verse:"各有四个脸面，四个翅膀。"},
{volumn:        26,chapter:         1,versenumber:         7,verse:"他们的腿是直的，脚掌好像牛犊之蹄，都灿烂如光明的铜。"},
{volumn:        26,chapter:         1,versenumber:         8,verse:"在四面的翅膀以下有人的手。这四个活物的脸和翅膀乃是这样："},
{volumn:        26,chapter:         1,versenumber:         9,verse:"翅膀彼此相接，行走并不转身，俱各直往前行。"},
{volumn:        26,chapter:         1,versenumber:        10,verse:"至于脸的形像：前面各有人的脸，右面各有狮子的脸，左面各有牛的脸，后面各有鹰的脸。"},
{volumn:        26,chapter:         1,versenumber:        11,verse:"各展开上边的两个翅膀相接，各以下边的两个翅膀遮体。"},
{volumn:        26,chapter:         1,versenumber:        12,verse:"他们俱各直往前行。灵往哪里去，他们就往那里去，行走并不转身。"},
{volumn:        26,chapter:         1,versenumber:        13,verse:"至于四活物的形像，就如烧着火炭的形状，又如火把的形状。火在四活物中间上去下来，这火有光辉，从火中发出闪电。"},
{volumn:        26,chapter:         1,versenumber:        14,verse:"这活物往来奔走，好像电光一闪。"},
{volumn:        26,chapter:         1,versenumber:        15,verse:"我正观看活物的时候，见活物的脸旁各有一轮在地上。"},
{volumn:        26,chapter:         1,versenumber:        16,verse:"轮的形状和颜色（原文是作法）好像水苍玉。四轮都是一个样式，形状和作法好像轮中套轮。"},
{volumn:        26,chapter:         1,versenumber:        17,verse:"轮行走的时候，向四方都能直行，并不掉转。"},
{volumn:        26,chapter:         1,versenumber:        18,verse:"至于轮辋，高而可畏；四个轮辋周围满有眼睛。"},
{volumn:        26,chapter:         1,versenumber:        19,verse:"活物行走，轮也在旁边行走；活物从地上升，轮也都上升。"},
{volumn:        26,chapter:         1,versenumber:        20,verse:"灵往哪里去，活物就往那里去；活物上升，轮也在活物旁边上升，因为活物的灵在轮中。"},
{volumn:        26,chapter:         1,versenumber:        21,verse:"那些行走，这些也行走；那些站住，这些也站住；那些从地上升，轮也在旁边上升，因为活物的灵在轮中。"},
{volumn:        26,chapter:         1,versenumber:        22,verse:"活物的头以上有穹苍的形像，看着像可畏的水晶，铺张在活物的头以上。"},
{volumn:        26,chapter:         1,versenumber:        23,verse:"穹苍以下，活物的翅膀直张，彼此相对；每活物有两个翅膀遮体。"},
{volumn:        26,chapter:         1,versenumber:        24,verse:"活物行走的时候，我听见翅膀的响声，像大水的声音，像全能者的声音，也像军队哄嚷的声音。活物站住的时候，便将翅膀垂下。"},
{volumn:        26,chapter:         1,versenumber:        25,verse:"在他们头以上的穹苍之上有声音。他们站住的时候，便将翅膀垂下。"},
{volumn:        26,chapter:         1,versenumber:        26,verse:"在他们头以上的穹苍之上有宝座的形像，仿佛蓝宝石；在宝座形像以上有仿佛人的形状。"},
{volumn:        26,chapter:         1,versenumber:        27,verse:"我见从他腰以上有仿佛光耀的精金，周围都有火的形状，又见从他腰以下有仿佛火的形状，周围也有光辉。"},
{volumn:        26,chapter:         1,versenumber:        28,verse:"下雨的日子，云中虹的形状怎样，周围光辉的形状也是怎样。这就是耶和华荣耀的形像。我一看见就俯伏在地，又听见一位说话的声音。"},
{volumn:        26,chapter:         2,versenumber:         1,verse:"他对我说：“人子啊，你站起来，我要和你说话。”"},
{volumn:        26,chapter:         2,versenumber:         2,verse:"他对我说话的时候，灵就进入我里面，使我站起来，我便听见那位对我说话的声音。"},
{volumn:        26,chapter:         2,versenumber:         3,verse:"他对我说：“人子啊，我差你往悖逆的国民以色列人那里去。他们是悖逆我的，他们和他们的列祖违背我，直到今日。"},
{volumn:        26,chapter:         2,versenumber:         4,verse:"这众子面无羞耻，心里刚硬。我差你往他们那里去，你要对他们说：主耶和华如此说。"},
{volumn:        26,chapter:         2,versenumber:         5,verse:"他们或听，或不听，（他们是悖逆之家），必知道在他们中间有了先知。"},
{volumn:        26,chapter:         2,versenumber:         6,verse:"人子啊，虽有荆棘和蒺藜在你那里，你又住在蝎子中间，总不要怕他们，也不要怕他们的话；他们虽是悖逆之家，还不要怕他们的话，也不要因他们的脸色惊惶。"},
{volumn:        26,chapter:         2,versenumber:         7,verse:"他们或听，或不听，你只管将我的话告诉他们；他们是极其悖逆的。"},
{volumn:        26,chapter:         2,versenumber:         8,verse:"“人子啊，要听我对你所说的话，不要悖逆像那悖逆之家，你要开口吃我所赐给你的。”"},
{volumn:        26,chapter:         2,versenumber:         9,verse:"我观看，见有一只手向我伸出来，手中有一书卷。"},
{volumn:        26,chapter:         2,versenumber:        10,verse:"他将书卷在我面前展开，内外都写着字，其上所写的有哀号、叹息、悲痛的话。"},
{volumn:        26,chapter:         3,versenumber:         1,verse:"他对我说：“人子啊，要吃你所得的，要吃这书卷，好去对以色列家讲说。”"},
{volumn:        26,chapter:         3,versenumber:         2,verse:"于是我开口，他就使我吃这书卷，"},
{volumn:        26,chapter:         3,versenumber:         3,verse:"又对我说：“人子啊，要吃我所赐给你的这书卷，充满你的肚腹。”我就吃了，口中觉得其甜如蜜。"},
{volumn:        26,chapter:         3,versenumber:         4,verse:"他对我说：“人子啊，你往以色列家那里去，将我的话对他们讲说。"},
{volumn:        26,chapter:         3,versenumber:         5,verse:"你奉差遣不是往那说话深奥、言语难懂的民那里去，乃是往以色列家去；"},
{volumn:        26,chapter:         3,versenumber:         6,verse:"不是往那说话深奥、言语难懂的多国去，他们的话语是你不懂得的。我若差你往他们那里去，他们必听从你。"},
{volumn:        26,chapter:         3,versenumber:         7,verse:"以色列家却不肯听从你，因为他们不肯听从我；原来以色列全家是额坚心硬的人。"},
{volumn:        26,chapter:         3,versenumber:         8,verse:"看哪，我使你的脸硬过他们的脸，使你的额硬过他们的额。"},
{volumn:        26,chapter:         3,versenumber:         9,verse:"我使你的额像金钢钻，比火石更硬。他们虽是悖逆之家，你不要怕他们，也不要因他们的脸色惊惶。”"},
{volumn:        26,chapter:         3,versenumber:        10,verse:"他又对我说：“人子啊，我对你所说的一切话，要心里领会，耳中听闻。"},
{volumn:        26,chapter:         3,versenumber:        11,verse:"你往你本国被掳的子民那里去，他们或听，或不听，你要对他们讲说，告诉他们这是主耶和华说的。”"},
{volumn:        26,chapter:         3,versenumber:        12,verse:"那时，灵将我举起，我就听见在我身后有震动轰轰的声音，说：“从耶和华的所在显出来的荣耀是该称颂的！”"},
{volumn:        26,chapter:         3,versenumber:        13,verse:"我又听见那活物翅膀相碰，与活物旁边轮子旋转震动轰轰的响声。"},
{volumn:        26,chapter:         3,versenumber:        14,verse:"于是灵将我举起，带我而去。我心中甚苦，灵性忿激，并且耶和华的灵（原文是手）在我身上大有能力。"},
{volumn:        26,chapter:         3,versenumber:        15,verse:"我就来到提勒·亚毕，住在迦巴鲁河边被掳的人那里，到他们所住的地方，在他们中间忧忧闷闷地坐了七日。"},
{volumn:        26,chapter:         3,versenumber:        16,verse:"过了七日，耶和华的话临到我说："},
{volumn:        26,chapter:         3,versenumber:        17,verse:"“人子啊，我立你作以色列家守望的人，所以你要听我口中的话，替我警戒他们。"},
{volumn:        26,chapter:         3,versenumber:        18,verse:"我何时指着恶人说：他必要死；你若不警戒他，也不劝戒他，使他离开恶行，拯救他的性命，这恶人必死在罪孽之中；我却要向你讨他丧命的罪（原文是血）。"},
{volumn:        26,chapter:         3,versenumber:        19,verse:"倘若你警戒恶人，他仍不转离罪恶，也不离开恶行，他必死在罪孽之中，你却救自己脱离了罪。"},
{volumn:        26,chapter:         3,versenumber:        20,verse:"再者，义人何时离义而犯罪，我将绊脚石放在他面前，他就必死；因你没有警戒他，他必死在罪中，他素来所行的义不被记念；我却要向你讨他丧命的罪（原文是血）。"},
{volumn:        26,chapter:         3,versenumber:        21,verse:"倘若你警戒义人，使他不犯罪，他就不犯罪；他因受警戒就必存活，你也救自己脱离了罪。”"},
{volumn:        26,chapter:         3,versenumber:        22,verse:"耶和华的灵（原文是手）在那里降在我身上。他对我说：“你起来往平原去，我要在那里和你说话。”"},
{volumn:        26,chapter:         3,versenumber:        23,verse:"于是我起来往平原去，不料，耶和华的荣耀正如我在迦巴鲁河边所见的一样，停在那里，我就俯伏于地。"},
{volumn:        26,chapter:         3,versenumber:        24,verse:"灵就进入我里面，使我站起来。耶和华对我说：“你进房屋去，将门关上。"},
{volumn:        26,chapter:         3,versenumber:        25,verse:"人子啊，人必用绳索捆绑你，你就不能出去在他们中间来往。"},
{volumn:        26,chapter:         3,versenumber:        26,verse:"我必使你的舌头贴住上膛，以致你哑口，不能作责备他们的人；他们原是悖逆之家。"},
{volumn:        26,chapter:         3,versenumber:        27,verse:"但我对你说话的时候，必使你开口，你就要对他们说：‘主耶和华如此说。’听的可以听，不听的任他不听，因为他们是悖逆之家。”"},
{volumn:        26,chapter:         4,versenumber:         1,verse:"“人子啊，你要拿一块砖，摆在你面前，将一座耶路撒冷城画在其上，"},
{volumn:        26,chapter:         4,versenumber:         2,verse:"又围困这城，造台筑垒，安营攻击，在四围安设撞锤攻城，"},
{volumn:        26,chapter:         4,versenumber:         3,verse:"又要拿个铁鏊，放在你和城的中间，作为铁墙。你要对面攻击这城，使城被困；这样，好作以色列家的预兆。"},
{volumn:        26,chapter:         4,versenumber:         4,verse:"“你要向左侧卧，承当以色列家的罪孽；要按你向左侧卧的日数，担当他们的罪孽。"},
{volumn:        26,chapter:         4,versenumber:         5,verse:"因为我已将他们作孽的年数定为你向左侧卧的日数，就是三百九十日，你要这样担当以色列家的罪孽。"},
{volumn:        26,chapter:         4,versenumber:         6,verse:"再者，你满了这些日子，还要向右侧卧，担当犹大家的罪孽。我给你定规侧卧四十日，一日顶一年。"},
{volumn:        26,chapter:         4,versenumber:         7,verse:"你要露出膀臂，面向被困的耶路撒冷，说预言攻击这城。"},
{volumn:        26,chapter:         4,versenumber:         8,verse:"我用绳索捆绑你，使你不能辗转，直等你满了困城的日子。"},
{volumn:        26,chapter:         4,versenumber:         9,verse:"“你要取小麦、大麦、豆子、红豆、小米、粗麦，装在一个器皿中，用以为自己做饼；要按你侧卧的三百九十日吃这饼。"},
{volumn:        26,chapter:         4,versenumber:        10,verse:"你所吃的要按分量吃，每日二十舍客勒，按时而吃。"},
{volumn:        26,chapter:         4,versenumber:        11,verse:"你喝水也要按制子，每日喝一欣六分之一，按时而喝。"},
{volumn:        26,chapter:         4,versenumber:        12,verse:"你吃这饼像吃大麦饼一样，要用人粪在众人眼前烧烤。”"},
{volumn:        26,chapter:         4,versenumber:        13,verse:"耶和华说：“以色列人在我所赶他们到的各国中，也必这样吃不洁净的食物。”"},
{volumn:        26,chapter:         4,versenumber:        14,verse:"我说：“哎！主耶和华啊，我素来未曾被玷污，从幼年到如今没有吃过自死的，或被野兽撕裂的，那可憎的肉也未曾入我的口。”"},
{volumn:        26,chapter:         4,versenumber:        15,verse:"于是他对我说：“看哪，我给你牛粪代替人粪，你要将你的饼烤在其上。”"},
{volumn:        26,chapter:         4,versenumber:        16,verse:"他又对我说：“人子啊，我必在耶路撒冷折断他们的杖，就是断绝他们的粮。他们吃饼要按分量，忧虑而吃；喝水也要按制子，惊惶而喝；"},
{volumn:        26,chapter:         4,versenumber:        17,verse:"使他们缺粮缺水，彼此惊惶，因自己的罪孽消灭。”"},
{volumn:        26,chapter:         5,versenumber:         1,verse:"“人子啊，你要拿一把快刀，当作剃头刀，用这刀剃你的头发和你的胡须，用天平将须发平分。"},
{volumn:        26,chapter:         5,versenumber:         2,verse:"围困城的日子满了，你要将三分之一在城中用火焚烧，将三分之一在城的四围用刀砍碎，将三分之一任风吹散；我也要拔刀追赶。"},
{volumn:        26,chapter:         5,versenumber:         3,verse:"你要从其中取几根包在衣襟里，"},
{volumn:        26,chapter:         5,versenumber:         4,verse:"再从这几根中取些扔在火中焚烧，从里面必有火出来烧入以色列全家。"},
{volumn:        26,chapter:         5,versenumber:         5,verse:"主耶和华如此说：这就是耶路撒冷。我曾将她安置在列邦之中；列国都在她的四围。"},
{volumn:        26,chapter:         5,versenumber:         6,verse:"她行恶，违背我的典章，过于列国；干犯我的律例，过于四围的列邦，因为她弃掉我的典章。至于我的律例，她并没有遵行。"},
{volumn:        26,chapter:         5,versenumber:         7,verse:"所以主耶和华如此说：因为你们纷争过于四围的列国，也不遵行我的律例，不谨守我的典章，并以遵从四围列国的恶规尚不满意，"},
{volumn:        26,chapter:         5,versenumber:         8,verse:"所以主耶和华如此说：看哪，我与你反对，必在列国的眼前，在你中间，施行审判；"},
{volumn:        26,chapter:         5,versenumber:         9,verse:"并且因你一切可憎的事，我要在你中间行我所未曾行的，以后我也不再照着行。"},
{volumn:        26,chapter:         5,versenumber:        10,verse:"在你中间父亲要吃儿子，儿子要吃父亲。我必向你施行审判，我必将你所剩下的分散四方（方：原文是风）。”"},
{volumn:        26,chapter:         5,versenumber:        11,verse:"主耶和华说：“我指着我的永生起誓，因你用一切可憎的物、可厌的事玷污了我的圣所，故此，我定要使你人数减少，我眼必不顾惜你，也不可怜你。"},
{volumn:        26,chapter:         5,versenumber:        12,verse:"你的民三分之一必遭瘟疫而死，在你中间必因饥荒消灭；三分之一必在你四围倒在刀下；我必将三分之一分散四方（方：原文是风），并要拔刀追赶他们。"},
{volumn:        26,chapter:         5,versenumber:        13,verse:"“我要这样成就怒中所定的；我向他们发的忿怒止息了，自己就得着安慰。我在他们身上成就怒中所定的那时，他们就知道我耶和华所说的是出于热心；"},
{volumn:        26,chapter:         5,versenumber:        14,verse:"并且我必使你在四围的列国中，在经过的众人眼前，成了荒凉和羞辱。"},
{volumn:        26,chapter:         5,versenumber:        15,verse:"这样，我必以怒气和忿怒，并烈怒的责备，向你施行审判。那时，你就在四围的列国中成为羞辱、讥刺、警戒、惊骇。这是我耶和华说的。"},
{volumn:        26,chapter:         5,versenumber:        16,verse:"那时，我要将灭人、使人饥荒的恶箭，就是射去灭人的，射在你们身上，并要加增你们的饥荒，断绝你们所倚靠的粮食；"},
{volumn:        26,chapter:         5,versenumber:        17,verse:"又要使饥荒和恶兽到你那里，叫你丧子，瘟疫和流血的事也必盛行在你那里；我也要使刀剑临到你。这是我耶和华说的。”"},
{volumn:        26,chapter:         6,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:         6,versenumber:         2,verse:"“人子啊，你要面向以色列的众山说预言，"},
{volumn:        26,chapter:         6,versenumber:         3,verse:"说：以色列的众山哪，要听主耶和华的话。主耶和华对大山、小冈、水沟、山谷如此说：我必使刀剑临到你们，也必毁灭你们的邱坛。"},
{volumn:        26,chapter:         6,versenumber:         4,verse:"你们的祭坛必然荒凉，你们的日像必被打碎。我要使你们被杀的人倒在你们的偶像面前；"},
{volumn:        26,chapter:         6,versenumber:         5,verse:"我也要将以色列人的尸首放在他们的偶像面前，将你们的骸骨抛散在你们祭坛的四围。"},
{volumn:        26,chapter:         6,versenumber:         6,verse:"在你们一切的住处，城邑要变为荒场，邱坛必然凄凉，使你们的祭坛荒废，将你们的偶像打碎。你们的日像被砍倒，你们的工作被毁灭。"},
{volumn:        26,chapter:         6,versenumber:         7,verse:"被杀的人必倒在你们中间，你们就知道我是耶和华。"},
{volumn:        26,chapter:         6,versenumber:         8,verse:"“你们分散在各国的时候，我必在列邦中使你们有剩下脱离刀剑的人。"},
{volumn:        26,chapter:         6,versenumber:         9,verse:"那脱离刀剑的人必在所掳到的各国中记念我，为他们心中何等伤破，是因他们起淫心，远离我，眼对偶像行邪淫。他们因行一切可憎的恶事，必厌恶自己。"},
{volumn:        26,chapter:         6,versenumber:        10,verse:"他们必知道我是耶和华；我说要使这灾祸临到他们身上，并非空话。"},
{volumn:        26,chapter:         6,versenumber:        11,verse:"“主耶和华如此说：你当拍手顿足，说：哀哉！以色列家行这一切可憎的恶事，他们必倒在刀剑、饥荒、瘟疫之下。"},
{volumn:        26,chapter:         6,versenumber:        12,verse:"在远处的，必遭瘟疫而死；在近处的，必倒在刀剑之下；那存留被围困的，必因饥荒而死；我必这样在他们身上成就我怒中所定的。"},
{volumn:        26,chapter:         6,versenumber:        13,verse:"他们被杀的人倒在他们祭坛四围的偶像中，就是各高冈、各山顶、各青翠树下、各茂密的橡树下，乃是他们献馨香的祭牲给一切偶像的地方。那时，他们就知道我是耶和华。"},
{volumn:        26,chapter:         6,versenumber:        14,verse:"我必伸手攻击他们，使他们的地从旷野到第伯拉他一切住处极其荒凉，他们就知道我是耶和华。”"},
{volumn:        26,chapter:         7,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:         7,versenumber:         2,verse:"“人子啊，主耶和华对以色列地如此说：结局到了，结局到了地的四境！"},
{volumn:        26,chapter:         7,versenumber:         3,verse:"现在你的结局已经临到，我必使我的怒气归与你，也必按你的行为审判你，照你一切可憎的事刑罚你。"},
{volumn:        26,chapter:         7,versenumber:         4,verse:"我眼必不顾惜你，也不可怜你，却要按你所行的报应你，照你中间可憎的事刑罚你。你就知道我是耶和华。"},
{volumn:        26,chapter:         7,versenumber:         5,verse:"“主耶和华如此说：有一灾，独有一灾；看哪，临近了！"},
{volumn:        26,chapter:         7,versenumber:         6,verse:"结局来了，结局来了，向你兴起。看哪，来到了！"},
{volumn:        26,chapter:         7,versenumber:         7,verse:"境内的居民哪，所定的灾临到你，时候到了，日子近了，乃是哄嚷并非在山上欢呼的日子。"},
{volumn:        26,chapter:         7,versenumber:         8,verse:"我快要将我的忿怒倾在你身上，向你成就我怒中所定的，按你的行为审判你，照你一切可憎的事刑罚你。"},
{volumn:        26,chapter:         7,versenumber:         9,verse:"我眼必不顾惜你，也不可怜你，必按你所行的报应你，照你中间可憎的事刑罚你。你就知道击打你的是我耶和华。"},
{volumn:        26,chapter:         7,versenumber:        10,verse:"“看哪，看哪，日子快到了；所定的灾已经发出！杖已经开花，骄傲已经发芽。"},
{volumn:        26,chapter:         7,versenumber:        11,verse:"强暴兴起，成了罚恶的杖。以色列人，或是他们的群众，或是他们的财宝，无一存留；他们中间也没有得尊荣的。"},
{volumn:        26,chapter:         7,versenumber:        12,verse:"时候到了，日子近了，买主不可欢喜，卖主不可愁烦，因为烈怒已经临到他们众人身上。"},
{volumn:        26,chapter:         7,versenumber:        13,verse:"卖主虽然存活，却不能归回再得所卖的，因为这异象关乎他们众人。谁都不得归回，也没有人在他的罪孽中坚立自己。”"},
{volumn:        26,chapter:         7,versenumber:        14,verse:"“他们已经吹角，预备齐全，却无一人出战，因为我的烈怒临到他们众人身上。"},
{volumn:        26,chapter:         7,versenumber:        15,verse:"在外有刀剑；在内有瘟疫、饥荒。在田野的，必遭刀剑而死；在城中的，必有饥荒、瘟疫吞灭他。"},
{volumn:        26,chapter:         7,versenumber:        16,verse:"其中所逃脱的就必逃脱，各人因自己的罪孽在山上发出悲声，好像谷中的鸽子哀鸣。"},
{volumn:        26,chapter:         7,versenumber:        17,verse:"手都发软，膝弱如水。"},
{volumn:        26,chapter:         7,versenumber:        18,verse:"要用麻布束腰，被战兢所盖；各人脸上羞愧，头上光秃。"},
{volumn:        26,chapter:         7,versenumber:        19,verse:"他们要将银子抛在街上，金子看如污秽之物。当耶和华发怒的日子，他们的金银不能救他们，不能使心里知足，也不能使肚腹饱满，因为这金银作了他们罪孽的绊脚石。"},
{volumn:        26,chapter:         7,versenumber:        20,verse:"论到耶和华妆饰华美的殿，他建立得威严，他们却在其中制造可憎可厌的偶像，所以这殿我使他们看如污秽之物。"},
{volumn:        26,chapter:         7,versenumber:        21,verse:"我必将这殿交付外邦人为掠物，交付地上的恶人为掳物；他们也必亵渎这殿。"},
{volumn:        26,chapter:         7,versenumber:        22,verse:"我必转脸不顾以色列人，他们亵渎我隐密之所，强盗也必进去亵渎。"},
{volumn:        26,chapter:         7,versenumber:        23,verse:"“要制造锁链；因为这地遍满流血的罪，城邑充满强暴的事。"},
{volumn:        26,chapter:         7,versenumber:        24,verse:"所以，我必使列国中最恶的人来占据他们的房屋；我必使强暴人的骄傲止息，他们的圣所都要被亵渎。"},
{volumn:        26,chapter:         7,versenumber:        25,verse:"毁灭临近了；他们要求平安，却无平安可得。"},
{volumn:        26,chapter:         7,versenumber:        26,verse:"灾害加上灾害，风声接连风声；他们必向先知求异象，但祭司讲的律法、长老设的谋略都必断绝。"},
{volumn:        26,chapter:         7,versenumber:        27,verse:"君要悲哀，王要披凄凉为衣，国民的手都发颤。我必照他们的行为待他们，按他们应得的审判他们，他们就知道我是耶和华。”"},
{volumn:        26,chapter:         8,versenumber:         1,verse:"第六年六月初五日，我坐在家中；犹大的众长老坐在我面前。在那里主耶和华的灵（原文是手）降在我身上。"},
{volumn:        26,chapter:         8,versenumber:         2,verse:"我观看，见有形像仿佛火的形状，从他腰以下的形状有火，从他腰以上有光辉的形状，仿佛光耀的精金。"},
{volumn:        26,chapter:         8,versenumber:         3,verse:"他伸出仿佛一只手的样式，抓住我的一绺头发，灵就将我举到天地中间，在　神的异象中，带我到耶路撒冷朝北的内院门口，在那里有触动主怒偶像的坐位，就是惹动忌邪的。"},
{volumn:        26,chapter:         8,versenumber:         4,verse:"谁知，在那里有以色列　神的荣耀，形状与我在平原所见的一样。"},
{volumn:        26,chapter:         8,versenumber:         5,verse:"神对我说：“人子啊，你举目向北观看。”我就举目向北观看，见祭坛门的北边，在门口有这惹忌邪的偶像；"},
{volumn:        26,chapter:         8,versenumber:         6,verse:"又对我说：“人子啊，以色列家所行的，就是在此行这大可憎的事，使我远离我的圣所，你看见了吗？你还要看见另有大可憎的事。”"},
{volumn:        26,chapter:         8,versenumber:         7,verse:"他领我到院门口。我观看，见墙上有个窟窿。"},
{volumn:        26,chapter:         8,versenumber:         8,verse:"他对我说：“人子啊，你要挖墙。”我一挖墙，见有一门。"},
{volumn:        26,chapter:         8,versenumber:         9,verse:"他说：“你进去，看他们在这里所行可憎的恶事。”"},
{volumn:        26,chapter:         8,versenumber:        10,verse:"我进去一看，谁知，在四面墙上画着各样爬物和可憎的走兽，并以色列家一切的偶像。"},
{volumn:        26,chapter:         8,versenumber:        11,verse:"在这些像前有以色列家的七十个长老站立，沙番的儿子雅撒尼亚也站在其中。各人手拿香炉，烟云的香气上腾。"},
{volumn:        26,chapter:         8,versenumber:        12,verse:"他对我说：“人子啊，以色列家的长老暗中在各人画像屋里所行的，你看见了吗？他们常说：‘耶和华看不见我们；耶和华已经离弃这地。’”"},
{volumn:        26,chapter:         8,versenumber:        13,verse:"他又说：“你还要看见他们另外行大可憎的事。”"},
{volumn:        26,chapter:         8,versenumber:        14,verse:"他领我到耶和华殿外院朝北的门口。谁知，在那里有妇女坐着，为搭模斯哭泣。"},
{volumn:        26,chapter:         8,versenumber:        15,verse:"他对我说：“人子啊，你看见了吗？你还要看见比这更可憎的事。”"},
{volumn:        26,chapter:         8,versenumber:        16,verse:"他又领我到耶和华殿的内院。谁知，在耶和华的殿门口、廊子和祭坛中间，约有二十五个人背向耶和华的殿，面向东方拜日头。"},
{volumn:        26,chapter:         8,versenumber:        17,verse:"他对我说：“人子啊，你看见了吗？犹大家在此行这可憎的事还算为小吗？他们在这地遍行强暴，再三惹我发怒，他们手拿枝条举向鼻前。"},
{volumn:        26,chapter:         8,versenumber:        18,verse:"因此，我也要以忿怒行事，我眼必不顾惜，也不可怜他们；他们虽向我耳中大声呼求，我还是不听。”"},
{volumn:        26,chapter:         9,versenumber:         1,verse:"他向我耳中大声喊叫说：“要使那监管这城的人手中各拿灭命的兵器前来。”"},
{volumn:        26,chapter:         9,versenumber:         2,verse:"忽然有六个人从朝北的上门而来，各人手拿杀人的兵器；内中有一人身穿细麻衣，腰间带着墨盒子。他们进来，站在铜祭坛旁。"},
{volumn:        26,chapter:         9,versenumber:         3,verse:"以色列　神的荣耀本在基路伯上，现今从那里升到殿的门槛。　神将那身穿细麻衣、腰间带着墨盒子的人召来。"},
{volumn:        26,chapter:         9,versenumber:         4,verse:"耶和华对他说：“你去走遍耶路撒冷全城，那些因城中所行可憎之事叹息哀哭的人，画记号在额上。”"},
{volumn:        26,chapter:         9,versenumber:         5,verse:"我耳中听见他对其余的人说：“要跟随他走遍全城，以行击杀。你们的眼不要顾惜，也不要可怜他们。"},
{volumn:        26,chapter:         9,versenumber:         6,verse:"要将年老的、年少的，并处女、婴孩，和妇女，从圣所起全都杀尽，只是凡有记号的人不要挨近他。”于是他们从殿前的长老杀起。"},
{volumn:        26,chapter:         9,versenumber:         7,verse:"他对他们说：“要污秽这殿，使院中充满被杀的人。你们出去吧！”他们就出去，在城中击杀。"},
{volumn:        26,chapter:         9,versenumber:         8,verse:"他们击杀的时候，我被留下，我就俯伏在地，说：“哎！主耶和华啊，你将忿怒倾在耶路撒冷，岂要将以色列所剩下的人都灭绝吗？”"},
{volumn:        26,chapter:         9,versenumber:         9,verse:"他对我说：“以色列家和犹大家的罪孽极其重大。遍地有流血的事，满城有冤屈，因为他们说：‘耶和华已经离弃这地，他看不见我们。’"},
{volumn:        26,chapter:         9,versenumber:        10,verse:"故此，我眼必不顾惜，也不可怜他们，要照他们所行的报应在他们头上。”"},
{volumn:        26,chapter:         9,versenumber:        11,verse:"那穿细麻衣、腰间带着墨盒子的人将这事回复说：“我已经照你所吩咐的行了。”"},
{volumn:        26,chapter:        10,versenumber:         1,verse:"我观看，见基路伯头上的穹苍之中，显出蓝宝石的形状，仿佛宝座的形像。"},
{volumn:        26,chapter:        10,versenumber:         2,verse:"主对那穿细麻衣的人说：“你进去，在旋转的轮内基路伯以下，从基路伯中间将火炭取满两手，撒在城上。”我就见他进去。"},
{volumn:        26,chapter:        10,versenumber:         3,verse:"那人进去的时候，基路伯站在殿的右边，云彩充满了内院。"},
{volumn:        26,chapter:        10,versenumber:         4,verse:"耶和华的荣耀从基路伯那里上升，停在门槛以上；殿内满了云彩，院宇也被耶和华荣耀的光辉充满。"},
{volumn:        26,chapter:        10,versenumber:         5,verse:"基路伯翅膀的响声听到外院，好像全能　神说话的声音。"},
{volumn:        26,chapter:        10,versenumber:         6,verse:"他吩咐那穿细麻衣的人说：“要从旋转的轮内基路伯中间取火。”那人就进去站在一个轮子旁边。"},
{volumn:        26,chapter:        10,versenumber:         7,verse:"有一个基路伯从基路伯中伸手到基路伯中间的火那里，取些放在那穿细麻衣的人两手中，那人就拿出去了。"},
{volumn:        26,chapter:        10,versenumber:         8,verse:"在基路伯翅膀之下，显出有人手的样式。"},
{volumn:        26,chapter:        10,versenumber:         9,verse:"我又观看，见基路伯旁边有四个轮子。这基路伯旁有一个轮子，那基路伯旁有一个轮子，每基路伯都是如此；轮子的颜色（原文是形状）仿佛水苍玉。"},
{volumn:        26,chapter:        10,versenumber:        10,verse:"至于四轮的形状，都是一个样式，仿佛轮中套轮。"},
{volumn:        26,chapter:        10,versenumber:        11,verse:"轮行走的时候，向四方都能直行，并不掉转。头向何方，他们也随向何方，行走的时候并不掉转。"},
{volumn:        26,chapter:        10,versenumber:        12,verse:"他们全身，连背带手和翅膀，并轮周围都满了眼睛。这四个基路伯的轮子都是如此。"},
{volumn:        26,chapter:        10,versenumber:        13,verse:"至于这些轮子，我耳中听见说是旋转的。"},
{volumn:        26,chapter:        10,versenumber:        14,verse:"基路伯各有四脸：第一是基路伯的脸，第二是人的脸，第三是狮子的脸，第四是鹰的脸。"},
{volumn:        26,chapter:        10,versenumber:        15,verse:"基路伯升上去了；这是我在迦巴鲁河边所见的活物。"},
{volumn:        26,chapter:        10,versenumber:        16,verse:"基路伯行走，轮也在旁边行走。基路伯展开翅膀，离地上升，轮也不转离他们旁边。"},
{volumn:        26,chapter:        10,versenumber:        17,verse:"那些站住，这些也站住；那些上升，这些也一同上升，因为活物的灵在轮中。"},
{volumn:        26,chapter:        10,versenumber:        18,verse:"耶和华的荣耀从殿的门槛那里出去，停在基路伯以上。"},
{volumn:        26,chapter:        10,versenumber:        19,verse:"基路伯出去的时候，就展开翅膀，在我眼前离地上升。轮也在他们的旁边，都停在耶和华殿的东门口。在他们以上有以色列　神的荣耀。"},
{volumn:        26,chapter:        10,versenumber:        20,verse:"这是我在迦巴鲁河边所见、以色列　神荣耀以下的活物，我就知道他们是基路伯。"},
{volumn:        26,chapter:        10,versenumber:        21,verse:"各有四个脸面，四个翅膀，翅膀以下有人手的样式。"},
{volumn:        26,chapter:        10,versenumber:        22,verse:"至于他们脸的模样，并身体的形像，是我从前在迦巴鲁河边所看见的。他们俱各直往前行。"},
{volumn:        26,chapter:        11,versenumber:         1,verse:"灵将我举起，带到耶和华殿向东的东门。谁知，在门口有二十五个人，我见其中有民间的首领押朔的儿子雅撒尼亚和比拿雅的儿子毗拉提。"},
{volumn:        26,chapter:        11,versenumber:         2,verse:"耶和华对我说：“人子啊，这就是图谋罪孽的人，在这城中给人设恶谋。"},
{volumn:        26,chapter:        11,versenumber:         3,verse:"他们说：‘盖房屋的时候尚未临近；这城是锅，我们是肉。’"},
{volumn:        26,chapter:        11,versenumber:         4,verse:"人子啊，因此你当说预言，说预言攻击他们。”"},
{volumn:        26,chapter:        11,versenumber:         5,verse:"耶和华的灵降在我身上，对我说：“你当说，耶和华如此说：以色列家啊，你们口中所说的，心里所想的，我都知道。"},
{volumn:        26,chapter:        11,versenumber:         6,verse:"你们在这城中杀人增多，使被杀的人充满街道。"},
{volumn:        26,chapter:        11,versenumber:         7,verse:"所以主耶和华如此说：你们杀在城中的人就是肉，这城就是锅；你们却要从其中被带出去。"},
{volumn:        26,chapter:        11,versenumber:         8,verse:"你们怕刀剑，我必使刀剑临到你们。这是主耶和华说的。"},
{volumn:        26,chapter:        11,versenumber:         9,verse:"我必从这城中带出你们去，交在外邦人的手中，且要在你们中间施行审判。"},
{volumn:        26,chapter:        11,versenumber:        10,verse:"你们必倒在刀下；我必在以色列的境界审判你们，你们就知道我是耶和华。"},
{volumn:        26,chapter:        11,versenumber:        11,verse:"这城必不作你们的锅，你们也不作其中的肉。我必在以色列的境界审判你们，"},
{volumn:        26,chapter:        11,versenumber:        12,verse:"你们就知道我是耶和华；因为你们没有遵行我的律例，也没有顺从我的典章，却随从你们四围列国的恶规。”"},
{volumn:        26,chapter:        11,versenumber:        13,verse:"我正说预言的时候，比拿雅的儿子毗拉提死了。于是我俯伏在地，大声呼叫说：“哎！主耶和华啊，你要将以色列剩下的人灭绝净尽吗？”"},
{volumn:        26,chapter:        11,versenumber:        14,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        11,versenumber:        15,verse:"“人子啊，耶路撒冷的居民对你的弟兄、你的本族、你的亲属、以色列全家，就是对大众说：‘你们远离耶和华吧！这地是赐给我们为业的。’"},
{volumn:        26,chapter:        11,versenumber:        16,verse:"所以你当说：‘耶和华如此说：我虽将以色列全家远远迁移到列国中，将他们分散在列邦内，我还要在他们所到的列邦，暂作他们的圣所。’"},
{volumn:        26,chapter:        11,versenumber:        17,verse:"你当说：‘主耶和华如此说：我必从万民中招聚你们，从分散的列国内聚集你们，又要将以色列地赐给你们。’"},
{volumn:        26,chapter:        11,versenumber:        18,verse:"他们必到那里，也必从其中除掉一切可憎可厌的物。"},
{volumn:        26,chapter:        11,versenumber:        19,verse:"我要使他们有合一的心，也要将新灵放在他们里面，又从他们肉体中除掉石心，赐给他们肉心，"},
{volumn:        26,chapter:        11,versenumber:        20,verse:"使他们顺从我的律例，谨守遵行我的典章。他们要作我的子民，我要作他们的　神。"},
{volumn:        26,chapter:        11,versenumber:        21,verse:"至于那些心中随从可憎可厌之物的，我必照他们所行的报应在他们头上。这是主耶和华说的。”"},
{volumn:        26,chapter:        11,versenumber:        22,verse:"于是，基路伯展开翅膀，轮子都在他们旁边；在他们以上有以色列　神的荣耀。"},
{volumn:        26,chapter:        11,versenumber:        23,verse:"耶和华的荣耀从城中上升，停在城东的那座山上。"},
{volumn:        26,chapter:        11,versenumber:        24,verse:"灵将我举起，在异象中藉着　神的灵将我带进迦勒底地，到被掳的人那里；我所见的异象就离我上升去了。"},
{volumn:        26,chapter:        11,versenumber:        25,verse:"我便将耶和华所指示我的一切事都说给被掳的人听。"},
{volumn:        26,chapter:        12,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        12,versenumber:         2,verse:"“人子啊，你住在悖逆的家中。他们有眼睛看不见，有耳朵听不见，因为他们是悖逆之家。"},
{volumn:        26,chapter:        12,versenumber:         3,verse:"所以人子啊，你要预备掳去使用的物件，在白日当他们眼前从你所住的地方移到别处去；他们虽是悖逆之家，或者可以揣摩思想。"},
{volumn:        26,chapter:        12,versenumber:         4,verse:"你要在白日当他们眼前带出你的物件去，好像预备掳去使用的物件。到了晚上，你要在他们眼前亲自出去，像被掳的人出去一样。"},
{volumn:        26,chapter:        12,versenumber:         5,verse:"你要在他们眼前挖通了墙，从其中将物件带出去。"},
{volumn:        26,chapter:        12,versenumber:         6,verse:"到天黑时，你要当他们眼前搭在肩头上带出去，并要蒙住脸看不见地，因为我立你作以色列家的预兆。”"},
{volumn:        26,chapter:        12,versenumber:         7,verse:"我就照着所吩咐的去行，白日带出我的物件，好像预备掳去使用的物件。到了晚上，我用手挖通了墙。天黑的时候，就当他们眼前搭在肩头上带出去。"},
{volumn:        26,chapter:        12,versenumber:         8,verse:"次日早晨，耶和华的话临到我说："},
{volumn:        26,chapter:        12,versenumber:         9,verse:"“人子啊，以色列家，就是那悖逆之家，岂不是问你说：‘你做什么呢？’"},
{volumn:        26,chapter:        12,versenumber:        10,verse:"你要对他们说：‘主耶和华如此说：这是关乎耶路撒冷的君王和他周围以色列全家的预表（原文是担子）。’"},
{volumn:        26,chapter:        12,versenumber:        11,verse:"你要说：‘我作你们的预兆：我怎样行，他们所遭遇的也必怎样，他们必被掳去。’"},
{volumn:        26,chapter:        12,versenumber:        12,verse:"他们中间的君王也必在天黑的时候将物件搭在肩头上带出去。他们要挖通了墙，从其中带出去。他必蒙住脸，眼看不见地。"},
{volumn:        26,chapter:        12,versenumber:        13,verse:"我必将我的网撒在他身上，他必在我的网罗中缠住。我必带他到迦勒底人之地的巴比伦；他虽死在那里，却看不见那地。"},
{volumn:        26,chapter:        12,versenumber:        14,verse:"周围一切帮助他的和他所有的军队，我必分散四方（方：原文是风），也要拔刀追赶他们。"},
{volumn:        26,chapter:        12,versenumber:        15,verse:"我将他们四散在列国、分散在列邦的时候，他们就知道我是耶和华。"},
{volumn:        26,chapter:        12,versenumber:        16,verse:"我却要留下他们几个人得免刀剑、饥荒、瘟疫，使他们在所到的各国中述说他们一切可憎的事，人就知道我是耶和华。”"},
{volumn:        26,chapter:        12,versenumber:        17,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        12,versenumber:        18,verse:"“人子啊，你吃饭必胆战，喝水必惶惶忧虑。"},
{volumn:        26,chapter:        12,versenumber:        19,verse:"你要对这地的百姓说：主耶和华论耶路撒冷和以色列地的居民如此说，他们吃饭必忧虑，喝水必惊惶。因其中居住的众人所行强暴的事，这地必然荒废，一无所存。"},
{volumn:        26,chapter:        12,versenumber:        20,verse:"有居民的城邑必变为荒场，地也必变为荒废；你们就知道我是耶和华。”"},
{volumn:        26,chapter:        12,versenumber:        21,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        12,versenumber:        22,verse:"“人子啊，在你们以色列地怎么有这俗语，说‘日子迟延，一切异象都落了空’呢？"},
{volumn:        26,chapter:        12,versenumber:        23,verse:"你要告诉他们说：‘主耶和华如此说：我必使这俗语止息，以色列中不再用这俗语。’你却要对他们说：‘日子临近，一切的异象必都应验。’"},
{volumn:        26,chapter:        12,versenumber:        24,verse:"从此，在以色列家中必不再有虚假的异象和奉承的占卜。"},
{volumn:        26,chapter:        12,versenumber:        25,verse:"我耶和华说话，所说的必定成就，不再耽延。你们这悖逆之家，我所说的话必趁你们在世的日子成就。这是主耶和华说的。”"},
{volumn:        26,chapter:        12,versenumber:        26,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        12,versenumber:        27,verse:"“人子啊，以色列家的人说：‘他所见的异象是关乎后来许多的日子，所说的预言是指着极远的时候。’"},
{volumn:        26,chapter:        12,versenumber:        28,verse:"所以你要对他们说：‘主耶和华如此说：我的话没有一句再耽延的，我所说的必定成就。这是主耶和华说的。’”"},
{volumn:        26,chapter:        13,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        13,versenumber:         2,verse:"“人子啊，你要说预言攻击以色列中说预言的先知，对那些本己心发预言的说：‘你们当听耶和华的话。’”"},
{volumn:        26,chapter:        13,versenumber:         3,verse:"主耶和华如此说：“愚顽的先知有祸了，他们随从自己的心意，却一无所见。"},
{volumn:        26,chapter:        13,versenumber:         4,verse:"以色列啊，你的先知好像荒场中的狐狸，"},
{volumn:        26,chapter:        13,versenumber:         5,verse:"没有上去堵挡破口，也没有为以色列家重修墙垣，使他们当耶和华的日子在阵上站立得住。"},
{volumn:        26,chapter:        13,versenumber:         6,verse:"这些人所见的是虚假，是谎诈的占卜。他们说是耶和华说的，其实耶和华并没有差遣他们，他们倒使人指望那话必然立定。"},
{volumn:        26,chapter:        13,versenumber:         7,verse:"你们岂不是见了虚假的异象吗？岂不是说了谎诈的占卜吗？你们说，这是耶和华说的，其实我没有说。”"},
{volumn:        26,chapter:        13,versenumber:         8,verse:"所以主耶和华如此说：“因你们说的是虚假，见的是谎诈，我就与你们反对。这是主耶和华说的。"},
{volumn:        26,chapter:        13,versenumber:         9,verse:"我的手必攻击那见虚假异象、用谎诈占卜的先知，他们必不列在我百姓的会中，不录在以色列家的册上，也不进入以色列地；你们就知道我是主耶和华。"},
{volumn:        26,chapter:        13,versenumber:        10,verse:"因为他们诱惑我的百姓，说：‘平安！’其实没有平安，就像有人立起墙壁，他们倒用未泡透的灰抹上。"},
{volumn:        26,chapter:        13,versenumber:        11,verse:"所以你要对那些抹上未泡透灰的人说：‘墙要倒塌，必有暴雨漫过。大冰雹啊，你们要降下，狂风也要吹裂这墙。’"},
{volumn:        26,chapter:        13,versenumber:        12,verse:"这墙倒塌之后，人岂不问你们说：‘你们抹上未泡透的灰在哪里呢？’”"},
{volumn:        26,chapter:        13,versenumber:        13,verse:"所以主耶和华如此说：“我要发怒，使狂风吹裂这墙，在怒中使暴雨漫过，又发怒降下大冰雹，毁灭这墙。"},
{volumn:        26,chapter:        13,versenumber:        14,verse:"我要这样拆毁你们那未泡透灰所抹的墙，拆平到地，以致根基露出，墙必倒塌，你们也必在其中灭亡；你们就知道我是耶和华。"},
{volumn:        26,chapter:        13,versenumber:        15,verse:"我要这样向墙和用未泡透灰抹墙的人成就我怒中所定的，并要对你们说：‘墙和抹墙的人都没有了。’"},
{volumn:        26,chapter:        13,versenumber:        16,verse:"这抹墙的就是以色列的先知，他们指着耶路撒冷说预言，为这城见了平安的异象，其实没有平安。这是主耶和华说的。”"},
{volumn:        26,chapter:        13,versenumber:        17,verse:"“人子啊，你要面向本民中、从己心发预言的女子说预言，攻击她们，"},
{volumn:        26,chapter:        13,versenumber:        18,verse:"说主耶和华如此说：‘这些妇女有祸了！她们为众人的膀臂缝靠枕，给高矮之人做下垂的头巾，为要猎取人的性命。难道你们要猎取我百姓的性命，为利己将人救活吗？"},
{volumn:        26,chapter:        13,versenumber:        19,verse:"你们为两把大麦，为几块饼，在我民中亵渎我，对肯听谎言的民说谎，杀死不该死的人，救活不该活的人。’”"},
{volumn:        26,chapter:        13,versenumber:        20,verse:"所以主耶和华如此说：“看哪，我与你们的靠枕反对，就是你们用以猎取人、使人的性命如鸟飞的。我要将靠枕从你们的膀臂上扯去，释放你们猎取如鸟飞的人。"},
{volumn:        26,chapter:        13,versenumber:        21,verse:"我也必撕裂你们下垂的头巾，救我百姓脱离你们的手，不再被猎取，落在你们手中。你们就知道我是耶和华。"},
{volumn:        26,chapter:        13,versenumber:        22,verse:"我不使义人伤心，你们却以谎话使他伤心，又坚固恶人的手，使他不回头离开恶道得以救活。"},
{volumn:        26,chapter:        13,versenumber:        23,verse:"你们就不再见虚假的异象，也不再行占卜的事；我必救我的百姓脱离你们的手；你们就知道我是耶和华。”"},
{volumn:        26,chapter:        14,versenumber:         1,verse:"有几个以色列长老到我这里来，坐在我面前。"},
{volumn:        26,chapter:        14,versenumber:         2,verse:"耶和华的话就临到我说："},
{volumn:        26,chapter:        14,versenumber:         3,verse:"“人子啊，这些人已将他们的假神接到心里，把陷于罪的绊脚石放在面前，我岂能丝毫被他们求问吗？"},
{volumn:        26,chapter:        14,versenumber:         4,verse:"所以你要告诉他们：‘主耶和华如此说：以色列家的人中，凡将他的假神接到心里，把陷于罪的绊脚石放在面前，又就了先知来的，我耶和华在他所求的事上，必按他众多的假神回答他（或译：必按他拜许多假神的罪报应他），"},
{volumn:        26,chapter:        14,versenumber:         5,verse:"好在以色列家的心事上捉住他们，因为他们都藉着假神与我生疏。’"},
{volumn:        26,chapter:        14,versenumber:         6,verse:"“所以你要告诉以色列家说：‘主耶和华如此说：回头吧！离开你们的偶像，转脸莫从你们一切可憎的事。’"},
{volumn:        26,chapter:        14,versenumber:         7,verse:"因为以色列家的人，或在以色列中寄居的外人，凡与我隔绝，将他的假神接到心里，把陷于罪的绊脚石放在面前，又就了先知来要为自己的事求问我的，我耶和华必亲自回答他。"},
{volumn:        26,chapter:        14,versenumber:         8,verse:"我必向那人变脸，使他作了警戒，笑谈，令人惊骇，并且我要将他从我民中剪除；你们就知道我是耶和华。"},
{volumn:        26,chapter:        14,versenumber:         9,verse:"先知若被迷惑说一句预言，是我耶和华任那先知受迷惑，我也必向他伸手，将他从我民以色列中除灭。"},
{volumn:        26,chapter:        14,versenumber:        10,verse:"他们必担当自己的罪孽。先知的罪孽和求问之人的罪孽都是一样，"},
{volumn:        26,chapter:        14,versenumber:        11,verse:"好使以色列家不再走迷离开我，不再因各样的罪过玷污自己，只要作我的子民，我作他们的　神。这是主耶和华说的。”"},
{volumn:        26,chapter:        14,versenumber:        12,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        14,versenumber:        13,verse:"“人子啊，若有一国犯罪干犯我，我也向他伸手折断他们的杖，就是断绝他们的粮，使饥荒临到那地，将人与牲畜从其中剪除；"},
{volumn:        26,chapter:        14,versenumber:        14,verse:"其中虽有挪亚、但以理、约伯这三人，他们只能因他们的义救自己的性命。这是主耶和华说的。"},
{volumn:        26,chapter:        14,versenumber:        15,verse:"我若使恶兽经过糟践那地，使地荒凉，以致因这些兽，人都不得经过；"},
{volumn:        26,chapter:        14,versenumber:        16,verse:"虽有这三人在其中，主耶和华说：我指着我的永生起誓，他们连儿带女都不能得救，只能自己得救，那地仍然荒凉。"},
{volumn:        26,chapter:        14,versenumber:        17,verse:"或者我使刀剑临到那地，说：刀剑哪，要经过那地，以致我将人与牲畜从其中剪除；"},
{volumn:        26,chapter:        14,versenumber:        18,verse:"虽有这三人在其中，主耶和华说：我指着我的永生起誓，他们连儿带女都不能得救，只能自己得救。"},
{volumn:        26,chapter:        14,versenumber:        19,verse:"或者我叫瘟疫流行那地，使我灭命（原文是带血）的忿怒倾在其上，好将人与牲畜从其中剪除；"},
{volumn:        26,chapter:        14,versenumber:        20,verse:"虽有挪亚、但以理、约伯在其中，主耶和华说：我指着我的永生起誓，他们连儿带女都不能救，只能因他们的义救自己的性命。”"},
{volumn:        26,chapter:        14,versenumber:        21,verse:"主耶和华如此说：“我将这四样大灾，就是刀剑、饥荒、恶兽、瘟疫降在耶路撒冷，将人与牲畜从其中剪除，岂不更重吗？"},
{volumn:        26,chapter:        14,versenumber:        22,verse:"然而其中必有剩下的人，他们连儿带女必带到你们这里来，你们看见他们所行所为的，要因我降给耶路撒冷的一切灾祸，便得了安慰。"},
{volumn:        26,chapter:        14,versenumber:        23,verse:"你们看见他们所行所为的，得了安慰，就知道我在耶路撒冷中所行的并非无故。这是主耶和华说的。”"},
{volumn:        26,chapter:        15,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        15,versenumber:         2,verse:"“人子啊，葡萄树比别样树有什么强处？葡萄枝比众树枝有什么好处？"},
{volumn:        26,chapter:        15,versenumber:         3,verse:"其上可以取木料做什么工用，可以取来做钉子挂什么器皿吗？"},
{volumn:        26,chapter:        15,versenumber:         4,verse:"看哪，已经抛在火中当作柴烧，火既烧了两头，中间也被烧了，还有益于工用吗？"},
{volumn:        26,chapter:        15,versenumber:         5,verse:"完全的时候尚且不合乎什么工用，何况被火烧坏，还能合乎什么工用吗？”"},
{volumn:        26,chapter:        15,versenumber:         6,verse:"所以，主耶和华如此说：“众树以内的葡萄树，我怎样使它在火中当柴，也必照样待耶路撒冷的居民。"},
{volumn:        26,chapter:        15,versenumber:         7,verse:"我必向他们变脸；他们虽从火中出来，火却要烧灭他们。我向他们变脸的时候，你们就知道我是耶和华。"},
{volumn:        26,chapter:        15,versenumber:         8,verse:"我必使地土荒凉，因为他们行事干犯我。这是主耶和华说的。”"},
{volumn:        26,chapter:        16,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        16,versenumber:         2,verse:"“人子啊，你要使耶路撒冷知道她那些可憎的事，"},
{volumn:        26,chapter:        16,versenumber:         3,verse:"说主耶和华对耶路撒冷如此说：你根本，你出世，是在迦南地；你父亲是亚摩利人，你母亲是赫人。"},
{volumn:        26,chapter:        16,versenumber:         4,verse:"论到你出世的景况，在你初生的日子没有为你断脐带，也没有用水洗你，使你洁净，丝毫没有撒盐在你身上，也没有用布裹你。"},
{volumn:        26,chapter:        16,versenumber:         5,verse:"谁的眼也不可怜你，为你做一件这样的事怜恤你；但你初生的日子扔在田野，是因你被厌恶。"},
{volumn:        26,chapter:        16,versenumber:         6,verse:"“我从你旁边经过，见你滚在血中，就对你说：你虽在血中，仍可存活；你虽在血中，仍可存活。"},
{volumn:        26,chapter:        16,versenumber:         7,verse:"我使你生长好像田间所长的，你就渐渐长大，以致极其俊美，两乳成形，头发长成，你却仍然赤身露体。"},
{volumn:        26,chapter:        16,versenumber:         8,verse:"“我从你旁边经过，看见你的时候正动爱情，便用衣襟搭在你身上，遮盖你的赤体；又向你起誓，与你结盟，你就归于我。这是主耶和华说的。"},
{volumn:        26,chapter:        16,versenumber:         9,verse:"那时我用水洗你，洗净你身上的血，又用油抹你。"},
{volumn:        26,chapter:        16,versenumber:        10,verse:"我也使你身穿绣花衣服，脚穿海狗皮鞋，并用细麻布给你束腰，用丝绸为衣披在你身上，"},
{volumn:        26,chapter:        16,versenumber:        11,verse:"又用妆饰打扮你，将镯子戴在你手上，将金链戴在你项上。"},
{volumn:        26,chapter:        16,versenumber:        12,verse:"我也将环子戴在你鼻子上，将耳环戴在你耳朵上，将华冠戴在你头上。"},
{volumn:        26,chapter:        16,versenumber:        13,verse:"这样，你就有金银的妆饰，穿的是细麻衣和丝绸，并绣花衣；吃的是细面、蜂蜜，并油。你也极其美貌，发达到王后的尊荣。"},
{volumn:        26,chapter:        16,versenumber:        14,verse:"你美貌的名声传在列邦中，你十分美貌，是因我加在你身上的威荣。这是主耶和华说的。"},
{volumn:        26,chapter:        16,versenumber:        15,verse:"“只是你仗着自己的美貌，又因你的名声就行邪淫。你纵情淫乱，使过路的任意而行。"},
{volumn:        26,chapter:        16,versenumber:        16,verse:"你用衣服为自己在高处结彩，在其上行邪淫。这样的事将来必没有，也必不再行了。"},
{volumn:        26,chapter:        16,versenumber:        17,verse:"你又将我所给你那华美的金银、宝器为自己制造人像，与他行邪淫；"},
{volumn:        26,chapter:        16,versenumber:        18,verse:"又用你的绣花衣服给他披上，并将我的膏油和香料摆在他跟前；"},
{volumn:        26,chapter:        16,versenumber:        19,verse:"又将我赐给你的食物，就是我赐给你吃的细面、油，和蜂蜜，都摆在他跟前为馨香的供物。这是主耶和华说的。"},
{volumn:        26,chapter:        16,versenumber:        20,verse:"并且你将给我所生的儿女焚献给他。"},
{volumn:        26,chapter:        16,versenumber:        21,verse:"你行淫乱岂是小事，竟将我的儿女杀了，使他们经火归与他吗？"},
{volumn:        26,chapter:        16,versenumber:        22,verse:"你行这一切可憎和淫乱的事，并未追念你幼年赤身露体滚在血中的日子。”"},
{volumn:        26,chapter:        16,versenumber:        23,verse:"“你行这一切恶事之后（主耶和华说：你有祸了！有祸了！）"},
{volumn:        26,chapter:        16,versenumber:        24,verse:"又为自己建造圆顶花楼，在各街上做了高台。"},
{volumn:        26,chapter:        16,versenumber:        25,verse:"你在一切市口上建造高台，使你的美貌变为可憎的，又与一切过路的多行淫乱。"},
{volumn:        26,chapter:        16,versenumber:        26,verse:"你也和你邻邦放纵情欲的埃及人行淫，加增你的淫乱，惹我发怒。"},
{volumn:        26,chapter:        16,versenumber:        27,verse:"因此我伸手攻击你，减少你应用的粮食，又将你交给恨你的非利士众女（众女是城邑的意思；本章下同），使她们任意待你。她们见你的淫行，为你羞耻。"},
{volumn:        26,chapter:        16,versenumber:        28,verse:"你因贪色无厌，又与亚述人行淫，与他们行淫之后，仍不满意，"},
{volumn:        26,chapter:        16,versenumber:        29,verse:"并且多行淫乱，直到那贸易之地，就是迦勒底，你仍不满意。"},
{volumn:        26,chapter:        16,versenumber:        30,verse:"“主耶和华说：你行这一切事，都是不知羞耻妓女所行的，可见你的心是何等懦弱！"},
{volumn:        26,chapter:        16,versenumber:        31,verse:"因你在一切市口上建造圆顶花楼，在各街上做了高台，你却藐视赏赐，不像妓女。"},
{volumn:        26,chapter:        16,versenumber:        32,verse:"哎！你这行淫的妻啊，宁肯接外人，不接丈夫。"},
{volumn:        26,chapter:        16,versenumber:        33,verse:"凡妓女是得人赠送，你反倒赠送你所爱的人，贿赂他们从四围来与你行淫。"},
{volumn:        26,chapter:        16,versenumber:        34,verse:"你行淫与别的妇女相反，因为不是人从你行淫；你既赠送人，人并不赠送你；所以你与别的妇女相反。”"},
{volumn:        26,chapter:        16,versenumber:        35,verse:"“你这妓女啊，要听耶和华的话。"},
{volumn:        26,chapter:        16,versenumber:        36,verse:"主耶和华如此说：因你的污秽倾泄了，你与你所爱的行淫露出下体，又因你拜一切可憎的偶像，流儿女的血献给他，"},
{volumn:        26,chapter:        16,versenumber:        37,verse:"我就要将你一切相欢相爱的和你一切所恨的都聚集来，从四围攻击你；又将你的下体露出，使他们看尽了。"},
{volumn:        26,chapter:        16,versenumber:        38,verse:"我也要审判你，好像官长审判淫妇和流人血的妇女一样。我因忿怒忌恨，使流血的罪归到你身上。"},
{volumn:        26,chapter:        16,versenumber:        39,verse:"我又要将你交在他们手中；他们必拆毁你的圆顶花楼，毁坏你的高台，剥去你的衣服，夺取你的华美宝器，留下你赤身露体。"},
{volumn:        26,chapter:        16,versenumber:        40,verse:"他们也必带多人来攻击你，用石头打死你，用刀剑刺透你，"},
{volumn:        26,chapter:        16,versenumber:        41,verse:"用火焚烧你的房屋，在许多妇人眼前向你施行审判。我必使你不再行淫，也不再赠送与人。"},
{volumn:        26,chapter:        16,versenumber:        42,verse:"这样，我就止息向你发的忿怒，我的忌恨也要离开你，我要安静不再恼怒。"},
{volumn:        26,chapter:        16,versenumber:        43,verse:"因你不追念你幼年的日子，在这一切的事上向我发烈怒，所以我必照你所行的报应在你头上，你就不再贪淫，行那一切可憎的事。这是主耶和华说的。”"},
{volumn:        26,chapter:        16,versenumber:        44,verse:"“凡说俗语的必用俗语攻击你，说：‘母亲怎样，女儿也怎样。’"},
{volumn:        26,chapter:        16,versenumber:        45,verse:"你正是你母亲的女儿，厌弃丈夫和儿女；你正是你姐妹的姐妹，厌弃丈夫和儿女。你母亲是赫人，你父亲是亚摩利人。"},
{volumn:        26,chapter:        16,versenumber:        46,verse:"你的姐姐是撒玛利亚，她和她的众女住在你左边；你的妹妹是所多玛，她和她的众女住在你右边。"},
{volumn:        26,chapter:        16,versenumber:        47,verse:"你没有效法她们的行为，也没有照她们可憎的事去做，你以那为小事，你一切所行的倒比她们更坏。"},
{volumn:        26,chapter:        16,versenumber:        48,verse:"主耶和华说：我指着我的永生起誓，你妹妹所多玛与她的众女尚未行你和你众女所行的事。"},
{volumn:        26,chapter:        16,versenumber:        49,verse:"看哪，你妹妹所多玛的罪孽是这样：她和她的众女都心骄气傲，粮食饱足，大享安逸，并没有扶助困苦和穷乏人的手。"},
{volumn:        26,chapter:        16,versenumber:        50,verse:"她们狂傲，在我面前行可憎的事，我看见便将她们除掉。"},
{volumn:        26,chapter:        16,versenumber:        51,verse:"撒玛利亚没有犯你一半的罪，你行可憎的事比她更多，使你的姐妹因你所行一切可憎的事，倒显为义。"},
{volumn:        26,chapter:        16,versenumber:        52,verse:"你既断定你姐妹为义（为义：或译当受羞辱），就要担当自己的羞辱；因你所犯的罪比她们更为可憎，她们就比你更显为义；你既使你的姐妹显为义，你就要抱愧担当自己的羞辱。”"},
{volumn:        26,chapter:        16,versenumber:        53,verse:"“我必叫她们被掳的归回，就是叫所多玛和她的众女，撒玛利亚和她的众女，并你们中间被掳的，都要归回，"},
{volumn:        26,chapter:        16,versenumber:        54,verse:"好使你担当自己的羞辱，并因你一切所行的使她们得安慰，你就抱愧。"},
{volumn:        26,chapter:        16,versenumber:        55,verse:"你的妹妹所多玛和她的众女必归回原位；撒玛利亚和她的众女，你和你的众女，也必归回原位。"},
{volumn:        26,chapter:        16,versenumber:        56,verse:"在你骄傲的日子，你的恶行没有显露以先，你的口就不提你的妹妹所多玛。那受了凌辱的亚兰众女和亚兰四围非利士的众女都恨恶你，藐视你。"},
{volumn:        26,chapter:        16,versenumber:        57,verse:""},
{volumn:        26,chapter:        16,versenumber:        58,verse:"耶和华说：你贪淫和可憎的事，你已经担当了。”"},
{volumn:        26,chapter:        16,versenumber:        59,verse:"“主耶和华如此说：你这轻看誓言、背弃盟约的，我必照你所行的待你。"},
{volumn:        26,chapter:        16,versenumber:        60,verse:"然而我要追念在你幼年时与你所立的约，也要与你立定永约。"},
{volumn:        26,chapter:        16,versenumber:        61,verse:"你接待你姐姐和你妹妹的时候，你要追念你所行的，自觉惭愧；并且我要将她们赐你为女儿，却不是按着前约。"},
{volumn:        26,chapter:        16,versenumber:        62,verse:"我要坚定与你所立的约（你就知道我是耶和华），"},
{volumn:        26,chapter:        16,versenumber:        63,verse:"好使你在我赦免你一切所行的时候，心里追念，自觉抱愧，又因你的羞辱就不再开口。这是主耶和华说的。”"},
{volumn:        26,chapter:        17,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        17,versenumber:         2,verse:"“人子啊，你要向以色列家出谜语，设比喻，"},
{volumn:        26,chapter:        17,versenumber:         3,verse:"说主耶和华如此说：有一大鹰，翅膀大，翎毛长，羽毛丰满，彩色俱备，来到黎巴嫩，将香柏树梢拧去，"},
{volumn:        26,chapter:        17,versenumber:         4,verse:"就是折去香柏树尽尖的嫩枝，叼到贸易之地，放在买卖城中；"},
{volumn:        26,chapter:        17,versenumber:         5,verse:"又将以色列地的枝子栽于肥田里，插在大水旁，如插柳树，"},
{volumn:        26,chapter:        17,versenumber:         6,verse:"就渐渐生长，成为蔓延矮小的葡萄树。其枝转向那鹰，其根在鹰以下，于是成了葡萄树，生出枝子，发出小枝。"},
{volumn:        26,chapter:        17,versenumber:         7,verse:"“又有一大鹰，翅膀大，羽毛多。这葡萄树从栽种的畦中向这鹰弯过根来，发出枝子，好得它的浇灌。"},
{volumn:        26,chapter:        17,versenumber:         8,verse:"这树栽于肥田多水的旁边，好生枝子，结果子，成为佳美的葡萄树。"},
{volumn:        26,chapter:        17,versenumber:         9,verse:"你要说，主耶和华如此说：这葡萄树岂能发旺呢？鹰岂不拔出它的根来，芟除它的果子，使它枯干，使它发的嫩叶都枯干了吗？也不用大力和多民，就拔出它的根来。"},
{volumn:        26,chapter:        17,versenumber:        10,verse:"葡萄树虽然栽种，岂能发旺呢？一经东风，岂不全然枯干吗？必在生长的畦中枯干了。”"},
{volumn:        26,chapter:        17,versenumber:        11,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        17,versenumber:        12,verse:"“你对那悖逆之家说：你们不知道这些事是什么意思吗？你要告诉他们说，巴比伦王曾到耶路撒冷，将其中的君王和首领带到巴比伦自己那里去。"},
{volumn:        26,chapter:        17,versenumber:        13,verse:"从以色列的宗室中取一人与他立约，使他发誓，并将国中有势力的人掳去，"},
{volumn:        26,chapter:        17,versenumber:        14,verse:"使国低微不能自强，惟因守盟约得以存立。"},
{volumn:        26,chapter:        17,versenumber:        15,verse:"他却背叛巴比伦王，打发使者往埃及去，要他们给他马匹和多民。他岂能亨通呢？行这样事的人岂能逃脱呢？他背约岂能逃脱呢？"},
{volumn:        26,chapter:        17,versenumber:        16,verse:"他轻看向王所起的誓，背弃王与他所立的约。主耶和华说：我指着我的永生起誓，他定要死在立他作王、巴比伦王的京都。"},
{volumn:        26,chapter:        17,versenumber:        17,verse:"敌人筑垒造台，与他打仗的时候，为要剪除多人，法老虽领大军队和大群众，还是不能帮助他。"},
{volumn:        26,chapter:        17,versenumber:        18,verse:"他轻看誓言，背弃盟约，已经投降，却又做这一切的事，他必不能逃脱。”"},
{volumn:        26,chapter:        17,versenumber:        19,verse:"所以主耶和华如此说：“我指着我的永生起誓，他既轻看指我所起的誓，背弃指我所立的约，我必要使这罪归在他头上。"},
{volumn:        26,chapter:        17,versenumber:        20,verse:"我必将我的网撒在他身上，他必在我的网罗中缠住。我必带他到巴比伦，并要在那里因他干犯我的罪刑罚他。"},
{volumn:        26,chapter:        17,versenumber:        21,verse:"他的一切军队，凡逃跑的，都必倒在刀下；所剩下的，也必分散四方（方：原文是风）。你们就知道说这话的是我耶和华。”"},
{volumn:        26,chapter:        17,versenumber:        22,verse:"主耶和华如此说：“我要将香柏树梢拧去栽上，就是从尽尖的嫩枝中折一嫩枝，栽于极高的山上；"},
{volumn:        26,chapter:        17,versenumber:        23,verse:"在以色列高处的山栽上。它就生枝子，结果子，成为佳美的香柏树，各类飞鸟都必宿在其下，就是宿在枝子的荫下。"},
{volumn:        26,chapter:        17,versenumber:        24,verse:"田野的树木都必知道我耶和华使高树矮小，矮树高大；青树枯干，枯树发旺。我耶和华如此说，也如此行了。”"},
{volumn:        26,chapter:        18,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        18,versenumber:         2,verse:"“你们在以色列地怎么用这俗语说‘父亲吃了酸葡萄，儿子的牙酸倒了’呢？”"},
{volumn:        26,chapter:        18,versenumber:         3,verse:"主耶和华说：“我指着我的永生起誓，你们在以色列中，必不再有用这俗语的因由。"},
{volumn:        26,chapter:        18,versenumber:         4,verse:"看哪，世人都是属我的；为父的怎样属我，为子的也照样属我；犯罪的，他必死亡。"},
{volumn:        26,chapter:        18,versenumber:         5,verse:"“人若是公义，且行正直与合理的事："},
{volumn:        26,chapter:        18,versenumber:         6,verse:"未曾在山上吃过祭偶像之物，未曾仰望以色列家的偶像，未曾玷污邻舍的妻，未曾在妇人的经期内亲近她，"},
{volumn:        26,chapter:        18,versenumber:         7,verse:"未曾亏负人，乃将欠债之人的当头还给他；未曾抢夺人的物件，却将食物给饥饿的人吃，将衣服给赤身的人穿；"},
{volumn:        26,chapter:        18,versenumber:         8,verse:"未曾向借钱的弟兄取利，也未曾向借粮的弟兄多要，缩手不作罪孽，在两人之间，按至理判断；"},
{volumn:        26,chapter:        18,versenumber:         9,verse:"遵行我的律例，谨守我的典章，按诚实行事。这人是公义的，必定存活。这是主耶和华说的。"},
{volumn:        26,chapter:        18,versenumber:        10,verse:"“他若生一个儿子，作强盗，是流人血的，不行以上所说之善，反行其中之恶，乃在山上吃过祭偶像之物，并玷污邻舍的妻，"},
{volumn:        26,chapter:        18,versenumber:        11,verse:""},
{volumn:        26,chapter:        18,versenumber:        12,verse:"亏负困苦和穷乏的人，抢夺人的物，未曾将当头还给人，仰望偶像，并行可憎的事，"},
{volumn:        26,chapter:        18,versenumber:        13,verse:"向借钱的弟兄取利，向借粮的弟兄多要。这人岂能存活呢？他必不能存活。他行这一切可憎的事，必要死亡，他的罪（原文是血）必归到他身上。"},
{volumn:        26,chapter:        18,versenumber:        14,verse:"“他若生一个儿子，见父亲所犯的一切罪便惧怕（有古卷：思量），不照样去做；"},
{volumn:        26,chapter:        18,versenumber:        15,verse:"未曾在山上吃过祭偶像之物，未曾仰望以色列家的偶像，未曾玷污邻舍的妻，"},
{volumn:        26,chapter:        18,versenumber:        16,verse:"未曾亏负人，未曾取人的当头，未曾抢夺人的物件，却将食物给饥饿的人吃，将衣服给赤身的人穿，"},
{volumn:        26,chapter:        18,versenumber:        17,verse:"缩手不害贫穷人，未曾向借钱的弟兄取利，也未曾向借粮的弟兄多要；他顺从我的典章，遵行我的律例，就不因父亲的罪孽死亡，定要存活。"},
{volumn:        26,chapter:        18,versenumber:        18,verse:"至于他父亲；因为欺人太甚，抢夺弟兄，在本国的民中行不善，他必因自己的罪孽死亡。"},
{volumn:        26,chapter:        18,versenumber:        19,verse:"“你们还说：‘儿子为何不担当父亲的罪孽呢？’儿子行正直与合理的事，谨守遵行我的一切律例，他必定存活。"},
{volumn:        26,chapter:        18,versenumber:        20,verse:"惟有犯罪的，他必死亡。儿子必不担当父亲的罪孽，父亲也不担当儿子的罪孽。义人的善果必归自己，恶人的恶报也必归自己。"},
{volumn:        26,chapter:        18,versenumber:        21,verse:"“恶人若回头离开所做的一切罪恶，谨守我一切的律例，行正直与合理的事，他必定存活，不致死亡。"},
{volumn:        26,chapter:        18,versenumber:        22,verse:"他所犯的一切罪过都不被记念，因所行的义，他必存活。"},
{volumn:        26,chapter:        18,versenumber:        23,verse:"主耶和华说：恶人死亡，岂是我喜悦的吗？不是喜悦他回头离开所行的道存活吗？"},
{volumn:        26,chapter:        18,versenumber:        24,verse:"义人若转离义行而作罪孽，照着恶人所行一切可憎的事而行，他岂能存活吗？他所行的一切义都不被记念；他必因所犯的罪、所行的恶死亡。"},
{volumn:        26,chapter:        18,versenumber:        25,verse:"“你们还说：‘主的道不公平！’以色列家啊，你们当听，我的道岂不公平吗？你们的道岂不是不公平吗？"},
{volumn:        26,chapter:        18,versenumber:        26,verse:"义人若转离义行而作罪孽死亡，他是因所作的罪孽死亡。"},
{volumn:        26,chapter:        18,versenumber:        27,verse:"再者，恶人若回头离开所行的恶，行正直与合理的事，他必将性命救活了。"},
{volumn:        26,chapter:        18,versenumber:        28,verse:"因为他思量，回头离开所犯的一切罪过，必定存活，不致死亡。"},
{volumn:        26,chapter:        18,versenumber:        29,verse:"以色列家还说：‘主的道不公平！’以色列家啊，我的道岂不公平吗？你们的道岂不是不公平吗？”"},
{volumn:        26,chapter:        18,versenumber:        30,verse:"所以主耶和华说：“以色列家啊，我必按你们各人所行的审判你们。你们当回头离开所犯的一切罪过。这样，罪孽必不使你们败亡。"},
{volumn:        26,chapter:        18,versenumber:        31,verse:"你们要将所犯的一切罪过尽行抛弃，自做一个新心和新灵。以色列家啊，你们何必死亡呢？"},
{volumn:        26,chapter:        18,versenumber:        32,verse:"主耶和华说：我不喜悦那死人之死，所以你们当回头而存活。”"},
{volumn:        26,chapter:        19,versenumber:         1,verse:"“你当为以色列的王作起哀歌，"},
{volumn:        26,chapter:        19,versenumber:         2,verse:"说：你的母亲是什么呢？是个母狮子，蹲伏在狮子中间，在少壮狮子中养育小狮子。"},
{volumn:        26,chapter:        19,versenumber:         3,verse:"在它小狮子中养大一个，成了少壮狮子，学会抓食而吃人。"},
{volumn:        26,chapter:        19,versenumber:         4,verse:"列国听见了就把它捉在他们的坑中，用钩子拉到埃及地去。"},
{volumn:        26,chapter:        19,versenumber:         5,verse:"母狮见自己等候失了指望，就从它小狮子中又将一个养为少壮狮子。"},
{volumn:        26,chapter:        19,versenumber:         6,verse:"它在众狮子中走来走去，成了少壮狮子，学会抓食而吃人。"},
{volumn:        26,chapter:        19,versenumber:         7,verse:"它知道列国的宫殿，又使他们的城邑变为荒场；因它咆哮的声音，遍地和其中所有的就都荒废。"},
{volumn:        26,chapter:        19,versenumber:         8,verse:"于是四围邦国各省的人来攻击它，将网撒在它身上，捉在他们的坑中。"},
{volumn:        26,chapter:        19,versenumber:         9,verse:"他们用钩子钩住它，将它放在笼中，带到巴比伦王那里，将它放入坚固之所，使它的声音在以色列山上不再听见。"},
{volumn:        26,chapter:        19,versenumber:        10,verse:"“你的母亲先前如葡萄树，极其茂盛（原文是在你血中），栽于水旁。因为水多，就多结果子，满生枝子；"},
{volumn:        26,chapter:        19,versenumber:        11,verse:"生出坚固的枝干，可作掌权者的杖。这枝干高举在茂密的枝中，而且它生长高大，枝子繁多，远远可见。"},
{volumn:        26,chapter:        19,versenumber:        12,verse:"但这葡萄树因忿怒被拔出摔在地上；东风吹干其上的果子，坚固的枝干折断枯干，被火烧毁了；"},
{volumn:        26,chapter:        19,versenumber:        13,verse:"如今栽于旷野干旱无水之地。"},
{volumn:        26,chapter:        19,versenumber:        14,verse:"火也从它枝干中发出，烧灭果子，以致没有坚固的枝干可做掌权者的杖。“这是哀歌，也必用以作哀歌。”"},
{volumn:        26,chapter:        20,versenumber:         1,verse:"第七年五月初十日，有以色列的几个长老来求问耶和华，坐在我面前。"},
{volumn:        26,chapter:        20,versenumber:         2,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        20,versenumber:         3,verse:"“人子啊，你要告诉以色列的长老说，主耶和华如此说：你们来是求问我吗？主耶和华说：我指着我的永生起誓，我必不被你们求问。"},
{volumn:        26,chapter:        20,versenumber:         4,verse:"人子啊，你要审问审问他们吗？你当使他们知道他们列祖那些可憎的事，"},
{volumn:        26,chapter:        20,versenumber:         5,verse:"对他们说，主耶和华如此说：当日我拣选以色列，向雅各家的后裔起誓，在埃及地将自己向他们显现，说：我是耶和华你们的　神。"},
{volumn:        26,chapter:        20,versenumber:         6,verse:"那日我向他们起誓，必领他们出埃及地，到我为他们察看的流奶与蜜之地；那地在万国中是有荣耀的。"},
{volumn:        26,chapter:        20,versenumber:         7,verse:"我对他们说，你们各人要抛弃眼所喜爱那可憎之物，不可因埃及的偶像玷污自己。我是耶和华你们的　神。"},
{volumn:        26,chapter:        20,versenumber:         8,verse:"他们却悖逆我，不肯听从我，不抛弃他们眼所喜爱那可憎之物，不离弃埃及的偶像。“我就说，我要将我的忿怒倾在他们身上，在埃及地向他们成就我怒中所定的。"},
{volumn:        26,chapter:        20,versenumber:         9,verse:"我却为我名的缘故没有这样行，免得我名在他们所住的列国人眼前被亵渎；我领他们出埃及地，在这列国人的眼前将自己向他们显现。"},
{volumn:        26,chapter:        20,versenumber:        10,verse:"这样，我就使他们出埃及地，领他们到旷野，"},
{volumn:        26,chapter:        20,versenumber:        11,verse:"将我的律例赐给他们，将我的典章指示他们；人若遵行就必因此活着。"},
{volumn:        26,chapter:        20,versenumber:        12,verse:"又将我的安息日赐给他们，好在我与他们中间为证据，使他们知道我耶和华是叫他们成为圣的。"},
{volumn:        26,chapter:        20,versenumber:        13,verse:"以色列家却在旷野悖逆我，不顺从我的律例，厌弃我的典章（人若遵行就必因此活着），大大干犯我的安息日。“我就说，要在旷野将我的忿怒倾在他们身上，灭绝他们。"},
{volumn:        26,chapter:        20,versenumber:        14,verse:"我却为我名的缘故，没有这样行，免得我的名在我领他们出埃及的列国人眼前被亵渎。"},
{volumn:        26,chapter:        20,versenumber:        15,verse:"并且我在旷野向他们起誓，必不领他们进入我所赐给他们流奶与蜜之地（那地在万国中是有荣耀的）；"},
{volumn:        26,chapter:        20,versenumber:        16,verse:"因为他们厌弃我的典章，不顺从我的律例，干犯我的安息日，他们的心随从自己的偶像。"},
{volumn:        26,chapter:        20,versenumber:        17,verse:"虽然如此，我眼仍顾惜他们，不毁灭他们，不在旷野将他们灭绝净尽。"},
{volumn:        26,chapter:        20,versenumber:        18,verse:"“我在旷野对他们的儿女说：不要遵行你们父亲的律例，不要谨守他们的恶规，也不要因他们的偶像玷污自己。"},
{volumn:        26,chapter:        20,versenumber:        19,verse:"我是耶和华你们的　神，你们要顺从我的律例，谨守遵行我的典章，"},
{volumn:        26,chapter:        20,versenumber:        20,verse:"且以我的安息日为圣。这日在我与你们中间为证据，使你们知道我是耶和华你们的　神。"},
{volumn:        26,chapter:        20,versenumber:        21,verse:"只是他们的儿女悖逆我，不顺从我的律例，也不谨守遵行我的典章（人若遵行就必因此活着），干犯我的安息日。“我就说，要将我的忿怒倾在他们身上，在旷野向他们成就我怒中所定的。"},
{volumn:        26,chapter:        20,versenumber:        22,verse:"虽然如此，我却为我名的缘故缩手没有这样行，免得我的名在我领他们出埃及的列国人眼前被亵渎。"},
{volumn:        26,chapter:        20,versenumber:        23,verse:"并且我在旷野向他们起誓，必将他们分散在列国，四散在列邦；"},
{volumn:        26,chapter:        20,versenumber:        24,verse:"因为他们不遵行我的典章，竟厌弃我的律例，干犯我的安息日，眼目仰望他们父亲的偶像。"},
{volumn:        26,chapter:        20,versenumber:        25,verse:"我也任他们遵行不美的律例，谨守不能使人活着的恶规。"},
{volumn:        26,chapter:        20,versenumber:        26,verse:"因他们将一切头生的经火，我就任凭他们在这供献的事上玷污自己，好叫他们凄凉，使他们知道我是耶和华。"},
{volumn:        26,chapter:        20,versenumber:        27,verse:"“人子啊，你要告诉以色列家说，主耶和华如此说：你们的列祖在得罪我的事上亵渎我；"},
{volumn:        26,chapter:        20,versenumber:        28,verse:"因为我领他们到了我起誓应许赐给他们的地，他们看见各高山、各茂密树，就在那里献祭，奉上惹我发怒的供物，也在那里焚烧馨香的祭牲，并浇上奠祭。"},
{volumn:        26,chapter:        20,versenumber:        29,verse:"我就对他们说：你们所上的那高处叫什么呢？（那高处的名字叫巴麻直到今日。）"},
{volumn:        26,chapter:        20,versenumber:        30,verse:"所以你要对以色列家说，主耶和华如此说：你们仍照你们列祖所行的玷污自己吗？仍照他们可憎的事行邪淫吗？"},
{volumn:        26,chapter:        20,versenumber:        31,verse:"你们奉上供物使你们儿子经火的时候，仍将一切偶像玷污自己，直到今日吗？以色列家啊，我岂被你们求问吗？主耶和华说：我指着我的永生起誓，我必不被你们求问。"},
{volumn:        26,chapter:        20,versenumber:        32,verse:"“你们说：我们要像外邦人和列国的宗族一样，去侍奉木头与石头。你们所起的这心意万不能成就。”"},
{volumn:        26,chapter:        20,versenumber:        33,verse:"主耶和华说：“我指着我的永生起誓，我总要作王，用大能的手和伸出来的膀臂，并倾出来的忿怒，治理你们。"},
{volumn:        26,chapter:        20,versenumber:        34,verse:"我必用大能的手和伸出来的膀臂，并倾出来的忿怒，将你们从万民中领出来，从分散的列国内聚集你们。"},
{volumn:        26,chapter:        20,versenumber:        35,verse:"我必带你们到外邦人的旷野，在那里当面刑罚你们。"},
{volumn:        26,chapter:        20,versenumber:        36,verse:"我怎样在埃及地的旷野刑罚你们的列祖，也必照样刑罚你们。这是主耶和华说的。"},
{volumn:        26,chapter:        20,versenumber:        37,verse:"我必使你们从杖下经过，使你们被约拘束。"},
{volumn:        26,chapter:        20,versenumber:        38,verse:"我必从你们中间除净叛逆和得罪我的人，将他们从所寄居的地方领出来，他们却不得入以色列地。你们就知道我是耶和华。"},
{volumn:        26,chapter:        20,versenumber:        39,verse:"“以色列家啊，至于你们，主耶和华如此说：从此以后若不听从我，就任凭你们去侍奉偶像，只是不可再因你们的供物和偶像亵渎我的圣名。"},
{volumn:        26,chapter:        20,versenumber:        40,verse:"“主耶和华说：在我的圣山，就是以色列高处的山，所有以色列的全家都要侍奉我。我要在那里悦纳你们，向你们要供物和初熟的土产，并一切的圣物。"},
{volumn:        26,chapter:        20,versenumber:        41,verse:"我从万民中领你们出来，从分散的列国内聚集你们，那时我必悦纳你们好像馨香之祭，要在外邦人眼前在你们身上显为圣。"},
{volumn:        26,chapter:        20,versenumber:        42,verse:"我领你们进入以色列地，就是我起誓应许赐给你们列祖之地，那时你们就知道我是耶和华。"},
{volumn:        26,chapter:        20,versenumber:        43,verse:"你们在那里要追念玷污自己的行动作为，又要因所做的一切恶事厌恶自己。"},
{volumn:        26,chapter:        20,versenumber:        44,verse:"主耶和华说：以色列家啊，我为我名的缘故，不照着你们的恶行和你们的坏事待你们；你们就知道我是耶和华。”"},
{volumn:        26,chapter:        20,versenumber:        45,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        20,versenumber:        46,verse:"“人子啊，你要面向南方，向南滴下预言攻击南方田野的树林。"},
{volumn:        26,chapter:        20,versenumber:        47,verse:"对南方的树林说，要听耶和华的话。主耶和华如此说：我必使火在你中间着起，烧灭你中间的一切青树和枯树，猛烈的火焰必不熄灭。从南到北，人的脸面都被烧焦。"},
{volumn:        26,chapter:        20,versenumber:        48,verse:"凡有血气的都必知道是我耶和华使火着起，这火必不熄灭。”"},
{volumn:        26,chapter:        20,versenumber:        49,verse:"于是我说：“哎！主耶和华啊，人都指着我说：他岂不是说比喻的吗？”"},
{volumn:        26,chapter:        21,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        21,versenumber:         2,verse:"“人子啊，你要面向耶路撒冷和圣所滴下预言，攻击以色列地。"},
{volumn:        26,chapter:        21,versenumber:         3,verse:"对以色列地说，耶和华如此说：我与你为敌，并要拔刀出鞘，从你中间将义人和恶人一并剪除。"},
{volumn:        26,chapter:        21,versenumber:         4,verse:"我既要从你中间剪除义人和恶人，所以我的刀要出鞘，自南至北攻击一切有血气的；"},
{volumn:        26,chapter:        21,versenumber:         5,verse:"一切有血气的就知道我耶和华已经拔刀出鞘，必不再入鞘。"},
{volumn:        26,chapter:        21,versenumber:         6,verse:"人子啊，你要叹息，在他们眼前弯着腰，苦苦地叹息。"},
{volumn:        26,chapter:        21,versenumber:         7,verse:"他们问你说：‘为何叹息呢？’你就说：‘因为有风声、灾祸要来。人心都必消化，手都发软，精神衰败，膝弱如水。看哪，这灾祸临近，必然成就。这是主耶和华说的。’”"},
{volumn:        26,chapter:        21,versenumber:         8,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        21,versenumber:         9,verse:"“人子啊，你要预言。耶和华吩咐我如此说：有刀、有刀，是磨快擦亮的；"},
{volumn:        26,chapter:        21,versenumber:        10,verse:"磨快为要行杀戮，擦亮为要像闪电。我们岂可快乐吗？罚我子的杖藐视各树。"},
{volumn:        26,chapter:        21,versenumber:        11,verse:"这刀已经交给人擦亮，为要应手使用。这刀已经磨快擦亮，好交在行杀戮的人手中。"},
{volumn:        26,chapter:        21,versenumber:        12,verse:"人子啊，你要呼喊哀号，因为这刀临到我的百姓和以色列一切的首领。他们和我的百姓都交在刀下，所以你要拍腿叹息。"},
{volumn:        26,chapter:        21,versenumber:        13,verse:"有试验的事；若那藐视的杖归于无有，怎么样呢？这是主耶和华说的。"},
{volumn:        26,chapter:        21,versenumber:        14,verse:"“人子啊，你要拍掌预言。我耶和华要使这刀，就是致死伤的刀，一连三次加倍刺人，进入他们的内屋，使大人受死伤的就是这刀。"},
{volumn:        26,chapter:        21,versenumber:        15,verse:"我设立这恐吓人的刀，攻击他们的一切城门，使他们的心消化，加增他们跌倒的事。哎！这刀造得像闪电，磨得尖利，要行杀戮。"},
{volumn:        26,chapter:        21,versenumber:        16,verse:"刀啊，你归在右边，摆在左边；你面向哪方，就向那方杀戮。"},
{volumn:        26,chapter:        21,versenumber:        17,verse:"我也要拍掌，并要使我的忿怒止息。这是我耶和华说的。”"},
{volumn:        26,chapter:        21,versenumber:        18,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        21,versenumber:        19,verse:"“人子啊，你要定出两条路，好使巴比伦王的刀来。这两条路必从一地分出来，又要在通城的路口上画出一只手来。"},
{volumn:        26,chapter:        21,versenumber:        20,verse:"你要定出一条路，使刀来到亚扪人的拉巴；又要定出一条路，使刀来到犹大的坚固城耶路撒冷。"},
{volumn:        26,chapter:        21,versenumber:        21,verse:"因为巴比伦王站在岔路那里，在两条路口上要占卜。他摇签（原文是箭）求问神像，察看牺牲的肝；"},
{volumn:        26,chapter:        21,versenumber:        22,verse:"在右手中拿着为耶路撒冷占卜的签，使他安设撞城锤，张口叫杀，扬声呐喊，筑垒造台，以撞城锤，攻打城门。"},
{volumn:        26,chapter:        21,versenumber:        23,verse:"据那些曾起誓的犹大人看来，这是虚假的占卜；但巴比伦王要使他们想起罪孽，以致将他们捉住。”"},
{volumn:        26,chapter:        21,versenumber:        24,verse:"主耶和华如此说：“因你们的过犯显露，使你们的罪孽被记念，以致你们的罪恶在行为上都彰显出来；又因你们被记念，就被捉住。"},
{volumn:        26,chapter:        21,versenumber:        25,verse:"你这受死伤行恶的以色列王啊，罪孽的尽头到了，受报的日子已到。"},
{volumn:        26,chapter:        21,versenumber:        26,verse:"主耶和华如此说：当除掉冠，摘下冕，景况必不再像先前；要使卑者升为高，使高者降为卑。"},
{volumn:        26,chapter:        21,versenumber:        27,verse:"我要将这国倾覆，倾覆，而又倾覆；这国也必不再有，直等到那应得的人来到，我就赐给他。”"},
{volumn:        26,chapter:        21,versenumber:        28,verse:"“人子啊，要发预言说：主耶和华论到亚扪人和他们的凌辱，吩咐我如此说：有刀，有拔出来的刀，已经擦亮，为行杀戮，使他像闪电以行吞灭。"},
{volumn:        26,chapter:        21,versenumber:        29,verse:"人为你见虚假的异象，行谎诈的占卜，使你倒在受死伤之恶人的颈项上。他们罪孽到了尽头，受报的日子已到。"},
{volumn:        26,chapter:        21,versenumber:        30,verse:"你将刀收入鞘吧！在你受造之处、生长之地，我必刑罚你。"},
{volumn:        26,chapter:        21,versenumber:        31,verse:"我必将我的恼恨倒在你身上，将我烈怒的火喷在你身上；又将你交在善于杀灭的畜类人手中。"},
{volumn:        26,chapter:        21,versenumber:        32,verse:"你必当柴被火焚烧；你的血必流在国中。你必不再被记念，因为这是我耶和华说的。”"},
{volumn:        26,chapter:        22,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        22,versenumber:         2,verse:"“人子啊，你要审问审问这流人血的城吗？当使她知道她一切可憎的事。"},
{volumn:        26,chapter:        22,versenumber:         3,verse:"你要说，主耶和华如此说：哎！这城有流人血的事在其中，叫她受报的日期来到，又做偶像玷污自己，陷害自己。"},
{volumn:        26,chapter:        22,versenumber:         4,verse:"你因流了人的血，就为有罪；你做了偶像，就玷污自己，使你受报之日临近，报应之年来到。所以我叫你受列国的凌辱和列邦的讥诮。"},
{volumn:        26,chapter:        22,versenumber:         5,verse:"你这名臭、多乱的城啊，那些离你近、离你远的都必讥诮你。"},
{volumn:        26,chapter:        22,versenumber:         6,verse:"“看哪，以色列的首领各逞其能，在你中间流人之血。"},
{volumn:        26,chapter:        22,versenumber:         7,verse:"在你中间有轻慢父母的，有欺压寄居的，有亏负孤儿寡妇的。"},
{volumn:        26,chapter:        22,versenumber:         8,verse:"你藐视了我的圣物，干犯了我的安息日。"},
{volumn:        26,chapter:        22,versenumber:         9,verse:"在你中间有谗谤人、流人血的；有在山上吃过祭偶像之物的，有行淫乱的。"},
{volumn:        26,chapter:        22,versenumber:        10,verse:"在你中间有露继母下体羞辱父亲的，有玷辱月经不洁净之妇人的。"},
{volumn:        26,chapter:        22,versenumber:        11,verse:"这人与邻舍的妻行可憎的事；那人贪淫玷污儿妇；还有玷辱同父之姐妹的。"},
{volumn:        26,chapter:        22,versenumber:        12,verse:"在你中间有为流人血受贿赂的；有向借钱的弟兄取利，向借粮的弟兄多要的。且因贪得无厌，欺压邻舍夺取财物，竟忘了我。这是主耶和华说的。"},
{volumn:        26,chapter:        22,versenumber:        13,verse:"“看哪，我因你所得不义之财和你中间所流的血，就拍掌叹息。"},
{volumn:        26,chapter:        22,versenumber:        14,verse:"到了我惩罚你的日子，你的心还能忍受吗？你的手还能有力吗？我耶和华说了这话，就必照着行。"},
{volumn:        26,chapter:        22,versenumber:        15,verse:"我必将你分散在列国，四散在列邦。我也必从你中间除掉你的污秽。"},
{volumn:        26,chapter:        22,versenumber:        16,verse:"你必在列国人的眼前因自己所行的被亵渎，你就知道我是耶和华。”"},
{volumn:        26,chapter:        22,versenumber:        17,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        22,versenumber:        18,verse:"“人子啊，以色列家在我看为渣滓。他们都是炉中的铜、锡、铁、铅，都是银渣滓。"},
{volumn:        26,chapter:        22,versenumber:        19,verse:"所以主耶和华如此说：因你们都成为渣滓，我必聚集你们在耶路撒冷中。"},
{volumn:        26,chapter:        22,versenumber:        20,verse:"人怎样将银、铜、铁、铅、锡聚在炉中，吹火熔化；照样，我也要发怒气和忿怒，将你们聚集放在城中，熔化你们。"},
{volumn:        26,chapter:        22,versenumber:        21,verse:"我必聚集你们，把我烈怒的火吹在你们身上，你们就在其中熔化。"},
{volumn:        26,chapter:        22,versenumber:        22,verse:"银子怎样熔化在炉中，你们也必照样熔化在城中，你们就知道我耶和华是将忿怒倒在你们身上了。”"},
{volumn:        26,chapter:        22,versenumber:        23,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        22,versenumber:        24,verse:"“人子啊，你要对这地说：你是未得洁净之地，在恼恨的日子也没有雨下在你以上。"},
{volumn:        26,chapter:        22,versenumber:        25,verse:"其中的先知同谋背叛，如咆哮的狮子抓撕掠物。他们吞灭人民，抢夺财宝，使这地多有寡妇。"},
{volumn:        26,chapter:        22,versenumber:        26,verse:"其中的祭司强解我的律法，亵渎我的圣物，不分别圣的和俗的，也不使人分辨洁净的和不洁净的，又遮眼不顾我的安息日；我也在他们中间被亵慢。"},
{volumn:        26,chapter:        22,versenumber:        27,verse:"其中的首领仿佛豺狼抓撕掠物，杀人流血，伤害人命，要得不义之财。"},
{volumn:        26,chapter:        22,versenumber:        28,verse:"其中的先知为百姓用未泡透的灰抹墙，就是为他们见虚假的异象，用谎诈的占卜，说：‘主耶和华如此说’，其实耶和华没有说。"},
{volumn:        26,chapter:        22,versenumber:        29,verse:"国内众民一味地欺压，惯行抢夺，亏负困苦穷乏的，背理欺压寄居的。"},
{volumn:        26,chapter:        22,versenumber:        30,verse:"我在他们中间寻找一人重修墙垣，在我面前为这国站在破口防堵，使我不灭绝这国，却找不着一个。"},
{volumn:        26,chapter:        22,versenumber:        31,verse:"所以我将恼恨倒在他们身上，用烈怒的火灭了他们，照他们所行的报应在他们头上。这是主耶和华说的。”"},
{volumn:        26,chapter:        23,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        23,versenumber:         2,verse:"“人子啊，有两个女子，是一母所生，"},
{volumn:        26,chapter:        23,versenumber:         3,verse:"她们在埃及行邪淫，在幼年时行邪淫。她们在那里作处女的时候，有人拥抱她们的怀，抚摸她们的乳。"},
{volumn:        26,chapter:        23,versenumber:         4,verse:"她们的名字，姐姐名叫阿荷拉，妹妹名叫阿荷利巴。她们都归于我，生了儿女。论到她们的名字，阿荷拉就是撒玛利亚，阿荷利巴就是耶路撒冷。"},
{volumn:        26,chapter:        23,versenumber:         5,verse:"“阿荷拉归我之后行邪淫，贪恋所爱的人，就是她的邻邦亚述人。"},
{volumn:        26,chapter:        23,versenumber:         6,verse:"这些人都穿蓝衣，作省长、副省长，都骑着马，是可爱的少年人。"},
{volumn:        26,chapter:        23,versenumber:         7,verse:"阿荷拉就与亚述人中最美的男子放纵淫行，她因所恋爱之人的一切偶像，玷污自己。"},
{volumn:        26,chapter:        23,versenumber:         8,verse:"自从在埃及的时候，她就没有离开淫乱，因为她年幼作处女的时候，埃及人与她行淫，抚摸她的乳，纵欲与她行淫。"},
{volumn:        26,chapter:        23,versenumber:         9,verse:"因此，我将她交在她所爱的人手中，就是她所恋爱的亚述人手中。"},
{volumn:        26,chapter:        23,versenumber:        10,verse:"他们就露了她的下体，掳掠她的儿女，用刀杀了她，使她在妇女中留下臭名，因他们向她施行审判。"},
{volumn:        26,chapter:        23,versenumber:        11,verse:"“她妹妹阿荷利巴虽然看见了，却还贪恋，比她姐姐更丑；行淫乱比她姐姐更多。"},
{volumn:        26,chapter:        23,versenumber:        12,verse:"她贪恋邻邦的亚述人，就是穿极华美的衣服，骑着马的省长、副省长，都是可爱的少年人。"},
{volumn:        26,chapter:        23,versenumber:        13,verse:"我看见她被玷污了，她姐妹二人同行一路。"},
{volumn:        26,chapter:        23,versenumber:        14,verse:"阿荷利巴又加增淫行，因她看见人像画在墙上，就是用丹色所画迦勒底人的像，"},
{volumn:        26,chapter:        23,versenumber:        15,verse:"腰间系着带子，头上有下垂的裹头巾，都是军长的形状，仿照巴比伦人的形像；他们的故土就是迦勒底。"},
{volumn:        26,chapter:        23,versenumber:        16,verse:"阿荷利巴一看见就贪恋他们，打发使者往迦勒底去见他们。"},
{volumn:        26,chapter:        23,versenumber:        17,verse:"巴比伦人就来登她爱情的床，与她行淫玷污她。她被玷污，随后心里与他们生疏。"},
{volumn:        26,chapter:        23,versenumber:        18,verse:"这样，她显露淫行，又显露下体；我心就与她生疏，像先前与她姐姐生疏一样。"},
{volumn:        26,chapter:        23,versenumber:        19,verse:"她还加增她的淫行，追念她幼年在埃及地行邪淫的日子，"},
{volumn:        26,chapter:        23,versenumber:        20,verse:"贪恋情人身壮精足，如驴如马。"},
{volumn:        26,chapter:        23,versenumber:        21,verse:"这样，你就想起你幼年的淫行。那时，埃及人拥抱你的怀，抚摸你的乳。”"},
{volumn:        26,chapter:        23,versenumber:        22,verse:"阿荷利巴啊，主耶和华如此说：“我必激动你先爱而后生疏的人来攻击你。我必使他们来，在你四围攻击你。"},
{volumn:        26,chapter:        23,versenumber:        23,verse:"所来的就是巴比伦人、迦勒底的众人、比割人、书亚人、哥亚人，同着他们的还有亚述众人，乃是作省长、副省长、作军长有名声的，都骑着马，是可爱的少年人。"},
{volumn:        26,chapter:        23,versenumber:        24,verse:"他们必带兵器、战车、辎重车，率领大众来攻击你。他们要拿大小盾牌，顶盔摆阵，在你四围攻击你。我要将审判的事交给他们，他们必按着自己的条例审判你。"},
{volumn:        26,chapter:        23,versenumber:        25,verse:"我必以忌恨攻击你；他们必以忿怒办你。他们必割去你的鼻子和耳朵；你遗留（或译：余剩；下同）的人必倒在刀下。他们必掳去你的儿女；你所遗留的必被火焚烧。"},
{volumn:        26,chapter:        23,versenumber:        26,verse:"他们必剥去你的衣服，夺取你华美的宝器。"},
{volumn:        26,chapter:        23,versenumber:        27,verse:"这样，我必使你的淫行和你从埃及地染来的淫乱止息了，使你不再仰望亚述，也不再追念埃及。"},
{volumn:        26,chapter:        23,versenumber:        28,verse:"主耶和华如此说：我必将你交在你所恨恶的人手中，就是你心与他生疏的人手中。"},
{volumn:        26,chapter:        23,versenumber:        29,verse:"他们必以恨恶办你，夺取你一切劳碌得来的，留下你赤身露体。你淫乱的下体，连你的淫行，带你的淫乱，都被显露。"},
{volumn:        26,chapter:        23,versenumber:        30,verse:"人必向你行这些事；因为你随从外邦人行邪淫，被他们的偶像玷污了。"},
{volumn:        26,chapter:        23,versenumber:        31,verse:"你走了你姐姐所走的路，所以我必将她的杯交在你手中。”"},
{volumn:        26,chapter:        23,versenumber:        32,verse:"主耶和华如此说：“你必喝你姐姐所喝的杯；那杯又深又广，盛得甚多，使你被人嗤笑讥刺。"},
{volumn:        26,chapter:        23,versenumber:        33,verse:"你必酩酊大醉，满有愁苦，喝干你姐姐撒玛利亚的杯，就是令人惊骇凄凉的杯。"},
{volumn:        26,chapter:        23,versenumber:        34,verse:"你必喝这杯，以致喝尽。杯破又啃杯片，撕裂自己的乳；因为这事我曾说过。这是主耶和华说的。”"},
{volumn:        26,chapter:        23,versenumber:        35,verse:"主耶和华如此说：“因你忘记我，将我丢在背后，所以你要担当你淫行和淫乱的报应。”"},
{volumn:        26,chapter:        23,versenumber:        36,verse:"耶和华又对我说：“人子啊，你要审问阿荷拉与阿荷利巴吗？当指出她们所行可憎的事。"},
{volumn:        26,chapter:        23,versenumber:        37,verse:"她们行淫，手中有杀人的血，又与偶像行淫，并使她们为我所生的儿女经火烧给偶像。"},
{volumn:        26,chapter:        23,versenumber:        38,verse:"此外，她们还有向我所行的，就是同日玷污我的圣所，干犯我的安息日。"},
{volumn:        26,chapter:        23,versenumber:        39,verse:"她们杀了儿女献与偶像，当天又入我的圣所，将圣所亵渎了。她们在我殿中所行的乃是如此。"},
{volumn:        26,chapter:        23,versenumber:        40,verse:"“况且你们二妇打发使者去请远方人。使者到他们那里，他们就来了。你们为他们沐浴己身，粉饰眼目，佩戴妆饰，"},
{volumn:        26,chapter:        23,versenumber:        41,verse:"坐在华美的床上，前面摆设桌案，将我的香料膏油摆在其上。"},
{volumn:        26,chapter:        23,versenumber:        42,verse:"在那里有群众安逸欢乐的声音，并有粗俗的人和酒徒从旷野同来，把镯子戴在二妇的手上，把华冠戴在她们的头上。"},
{volumn:        26,chapter:        23,versenumber:        43,verse:"“我论这行淫衰老的妇人说：现在人还要与她行淫，她也要与人行淫。"},
{volumn:        26,chapter:        23,versenumber:        44,verse:"人与阿荷拉，并阿荷利巴二淫妇苟合，好像与妓女苟合。"},
{volumn:        26,chapter:        23,versenumber:        45,verse:"必有义人，照审判淫妇和流人血的妇人之例，审判她们；因为她们是淫妇，手中有杀人的血。”"},
{volumn:        26,chapter:        23,versenumber:        46,verse:"主耶和华如此说：“我必使多人来攻击她们，使她们抛来抛去，被人抢夺。"},
{volumn:        26,chapter:        23,versenumber:        47,verse:"这些人必用石头打死她们，用刀剑杀害她们，又杀戮她们的儿女，用火焚烧她们的房屋。"},
{volumn:        26,chapter:        23,versenumber:        48,verse:"这样，我必使淫行从境内止息，好叫一切妇人都受警戒，不效法你们的淫行。"},
{volumn:        26,chapter:        23,versenumber:        49,verse:"人必照着你们的淫行报应你们；你们要担当拜偶像的罪，就知道我是主耶和华。”"},
{volumn:        26,chapter:        24,versenumber:         1,verse:"第九年十月初十日，耶和华的话又临到我说："},
{volumn:        26,chapter:        24,versenumber:         2,verse:"“人子啊，今日正是巴比伦王就近耶路撒冷的日子，你要将这日记下，"},
{volumn:        26,chapter:        24,versenumber:         3,verse:"要向这悖逆之家设比喻说，主耶和华如此说：将锅放在火上，放好了，就倒水在其中；"},
{volumn:        26,chapter:        24,versenumber:         4,verse:"将肉块，就是一切肥美的肉块、腿，和肩都聚在其中，拿美好的骨头把锅装满；"},
{volumn:        26,chapter:        24,versenumber:         5,verse:"取羊群中最好的，将柴堆在锅下，使锅开滚，好把骨头煮在其中。”"},
{volumn:        26,chapter:        24,versenumber:         6,verse:"主耶和华如此说：“祸哉！这流人血的城，就是长锈的锅。其中的锈未曾除掉，须要将肉块从其中一一取出来，不必为它拈阄。"},
{volumn:        26,chapter:        24,versenumber:         7,verse:"城中所流的血倒在净光的磐石上，不倒在地上，用土掩盖。"},
{volumn:        26,chapter:        24,versenumber:         8,verse:"这城中所流的血倒在净光的磐石上，不得掩盖，乃是出于我，为要发忿怒施行报应。"},
{volumn:        26,chapter:        24,versenumber:         9,verse:"所以主耶和华如此说：祸哉！这流人血的城，我也必大堆火柴，"},
{volumn:        26,chapter:        24,versenumber:        10,verse:"添上木柴，使火着旺，将肉煮烂，把汤熬浓，使骨头烤焦；"},
{volumn:        26,chapter:        24,versenumber:        11,verse:"把锅倒空坐在炭火上，使锅烧热，使铜烧红，熔化其中的污秽，除净其上的锈。"},
{volumn:        26,chapter:        24,versenumber:        12,verse:"这锅劳碌疲乏，所长的大锈仍未除掉；这锈就是用火也不能除掉。"},
{volumn:        26,chapter:        24,versenumber:        13,verse:"在你污秽中有淫行，我洁净你，你却不洁净。你的污秽再不能洁净，直等我向你发的忿怒止息。"},
{volumn:        26,chapter:        24,versenumber:        14,verse:"我耶和华说过的必定成就，必照话而行，必不返回，必不顾惜，也不后悔。人必照你的举动行为审判你。这是主耶和华说的。”"},
{volumn:        26,chapter:        24,versenumber:        15,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        24,versenumber:        16,verse:"“人子啊，我要将你眼目所喜爱的忽然取去，你却不可悲哀哭泣，也不可流泪，"},
{volumn:        26,chapter:        24,versenumber:        17,verse:"只可叹息，不可出声，不可办理丧事；头上仍勒裹头巾，脚上仍穿鞋，不可蒙着嘴唇，也不可吃吊丧的食物。”"},
{volumn:        26,chapter:        24,versenumber:        18,verse:"于是我将这事早晨告诉百姓，晚上我的妻就死了。次日早晨我便遵命而行。"},
{volumn:        26,chapter:        24,versenumber:        19,verse:"百姓问我说：“你这样行与我们有什么关系，你不告诉我们吗？”"},
{volumn:        26,chapter:        24,versenumber:        20,verse:"我回答他们：“耶和华的话临到我说："},
{volumn:        26,chapter:        24,versenumber:        21,verse:"‘你告诉以色列家，主耶和华如此说：我必使我的圣所，就是你们势力所夸耀、眼里所喜爱、心中所爱惜的被亵渎，并且你们所遗留的儿女必倒在刀下。"},
{volumn:        26,chapter:        24,versenumber:        22,verse:"那时，你们必行我仆人所行的，不蒙着嘴唇，也不吃吊丧的食物。"},
{volumn:        26,chapter:        24,versenumber:        23,verse:"你们仍要头上勒裹头巾，脚上穿鞋；不可悲哀哭泣。你们必因自己的罪孽相对叹息，渐渐消灭。"},
{volumn:        26,chapter:        24,versenumber:        24,verse:"以西结必这样为你们作预兆；凡他所行的，你们也必照样行。那事来到，你们就知道我是主耶和华。’"},
{volumn:        26,chapter:        24,versenumber:        25,verse:"“人子啊，我除掉他们所倚靠、所欢喜的荣耀，并眼中所喜爱、心里所重看的儿女。"},
{volumn:        26,chapter:        24,versenumber:        26,verse:"那日逃脱的人岂不来到你这里，使你耳闻这事吗？"},
{volumn:        26,chapter:        24,versenumber:        27,verse:"你必向逃脱的人开口说话，不再哑口。你必这样为他们作预兆，他们就知道我是耶和华。”"},
{volumn:        26,chapter:        25,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        25,versenumber:         2,verse:"“人子啊，你要面向亚扪人说预言，攻击他们，"},
{volumn:        26,chapter:        25,versenumber:         3,verse:"说：你们当听主耶和华的话。主耶和华如此说：我的圣所被亵渎，以色列地变荒凉，犹大家被掳掠；那时，你便因这些事说：‘阿哈！’"},
{volumn:        26,chapter:        25,versenumber:         4,verse:"所以我必将你的地交给东方人为业；他们必在你的地上安营居住，吃你的果子，喝你的奶。"},
{volumn:        26,chapter:        25,versenumber:         5,verse:"我必使拉巴为骆驼场，使亚扪人的地为羊群躺卧之处，你们就知道我是耶和华。”"},
{volumn:        26,chapter:        25,versenumber:         6,verse:"主耶和华如此说：“因你拍手顿足，以满心的恨恶向以色列地欢喜，"},
{volumn:        26,chapter:        25,versenumber:         7,verse:"所以我伸手攻击你，将你交给列国作为掳物。我必从万民中剪除你，使你从万国中败亡。我必除灭你，你就知道我是耶和华。”"},
{volumn:        26,chapter:        25,versenumber:         8,verse:"主耶和华如此说：“因摩押和西珥人说：‘看哪，犹大家与列国无异’，"},
{volumn:        26,chapter:        25,versenumber:         9,verse:"所以我要破开摩押边界上的城邑，就是摩押人看为本国之荣耀的伯·耶西末、巴力·免、基列亭，"},
{volumn:        26,chapter:        25,versenumber:        10,verse:"好使东方人来攻击亚扪人。我必将亚扪人之地交给他们为业，使亚扪人在列国中不再被记念。"},
{volumn:        26,chapter:        25,versenumber:        11,verse:"我必向摩押施行审判，他们就知道我是耶和华。”"},
{volumn:        26,chapter:        25,versenumber:        12,verse:"主耶和华如此说：“因为以东报仇雪恨，攻击犹大家，向他们报仇，大大有罪，"},
{volumn:        26,chapter:        25,versenumber:        13,verse:"所以主耶和华如此说：我必伸手攻击以东，剪除人与牲畜，使以东从提幔起，人必倒在刀下，地要变为荒凉，直到底但。"},
{volumn:        26,chapter:        25,versenumber:        14,verse:"我必藉我民以色列的手报复以东；以色列民必照我的怒气，按我的忿怒在以东施报，以东人就知道是我施报。这是主耶和华说的。”"},
{volumn:        26,chapter:        25,versenumber:        15,verse:"主耶和华如此说：“因非利士人向犹大人报仇，就是以恨恶的心报仇雪恨，永怀仇恨，要毁灭他们，"},
{volumn:        26,chapter:        25,versenumber:        16,verse:"所以主耶和华如此说：我必伸手攻击非利士人，剪除基利提人，灭绝沿海剩下的居民。"},
{volumn:        26,chapter:        25,versenumber:        17,verse:"我向他们大施报应，发怒斥责他们。我报复他们的时候，他们就知道我是耶和华。”"},
{volumn:        26,chapter:        26,versenumber:         1,verse:"第十一年十一月初一日，耶和华的话临到我说："},
{volumn:        26,chapter:        26,versenumber:         2,verse:"“人子啊，因推罗向耶路撒冷说：‘阿哈，那作众民之门的已经破坏，向我开放；她既变为荒场，我必丰盛。’"},
{volumn:        26,chapter:        26,versenumber:         3,verse:"所以，主耶和华如此说：推罗啊，我必与你为敌，使许多国民上来攻击你，如同海使波浪涌上来一样。"},
{volumn:        26,chapter:        26,versenumber:         4,verse:"他们必破坏推罗的墙垣，拆毁她的城楼。我也要刮净尘土，使她成为净光的磐石。"},
{volumn:        26,chapter:        26,versenumber:         5,verse:"她必在海中作晒网的地方，也必成为列国的掳物。这是主耶和华说的。"},
{volumn:        26,chapter:        26,versenumber:         6,verse:"属推罗城邑的居民（原文是田间的众女；八节同）必被刀剑杀灭，他们就知道我是耶和华。”"},
{volumn:        26,chapter:        26,versenumber:         7,verse:"主耶和华如此说：“我必使诸王之王的巴比伦王尼布甲尼撒率领马匹车辆、马兵、军队，和许多人民从北方来攻击你推罗。"},
{volumn:        26,chapter:        26,versenumber:         8,verse:"他必用刀剑杀灭属你城邑的居民，也必造台筑垒举盾牌攻击你。"},
{volumn:        26,chapter:        26,versenumber:         9,verse:"他必安设撞城锤攻破你的墙垣，用铁器拆毁你的城楼。"},
{volumn:        26,chapter:        26,versenumber:        10,verse:"因他的马匹众多，尘土扬起遮蔽你。他进入你的城门，好像人进入已有破口之城。那时，你的墙垣必因骑马的和战车、辎重车的响声震动。"},
{volumn:        26,chapter:        26,versenumber:        11,verse:"他的马蹄必践踏你一切的街道；他必用刀杀戮你的居民。你坚固的柱子（或译：柱像）必倒在地上。"},
{volumn:        26,chapter:        26,versenumber:        12,verse:"人必以你的财宝为掳物，以你的货财为掠物，破坏你的墙垣，拆毁你华美的房屋，将你的石头、木头、尘土都抛在水中。"},
{volumn:        26,chapter:        26,versenumber:        13,verse:"我必使你唱歌的声音止息；人也不再听见你弹琴的声音。"},
{volumn:        26,chapter:        26,versenumber:        14,verse:"我必使你成为净光的磐石，作晒网的地方。你不得再被建造，因为这是主耶和华说的。”"},
{volumn:        26,chapter:        26,versenumber:        15,verse:"主耶和华对推罗如此说：“在你中间行杀戮，受伤之人唉哼的时候，因你倾倒的响声，海岛岂不都震动吗？"},
{volumn:        26,chapter:        26,versenumber:        16,verse:"那时靠海的君王必都下位，除去朝服，脱下花衣，披上战兢，坐在地上，时刻发抖，为你惊骇。"},
{volumn:        26,chapter:        26,versenumber:        17,verse:"他们必为你作起哀歌说：‘你这有名之城，素为航海之人居住，在海上为最坚固的；平日你和居民使一切住在那里的人无不惊恐；现在何竟毁灭了？"},
{volumn:        26,chapter:        26,versenumber:        18,verse:"如今在你这倾覆的日子，海岛都必战兢；海中的群岛见你归于无有就都惊惶。’”"},
{volumn:        26,chapter:        26,versenumber:        19,verse:"主耶和华如此说：“推罗啊，我使你变为荒凉，如无人居住的城邑；又使深水漫过你，大水淹没你。"},
{volumn:        26,chapter:        26,versenumber:        20,verse:"那时，我要叫你下入阴府，与古时的人一同在地的深处、久已荒凉之地居住，使你不再有居民。我也要在活人之地显荣耀（我也......荣耀：或译在活人之地不再有荣耀）。"},
{volumn:        26,chapter:        26,versenumber:        21,verse:"我必叫你令人惊恐，不再存留于世；人虽寻找你，却永寻不见。这是主耶和华说的。”"},
{volumn:        26,chapter:        27,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        27,versenumber:         2,verse:"“人子啊，要为推罗作起哀歌，"},
{volumn:        26,chapter:        27,versenumber:         3,verse:"说：你居住海口，是众民的商埠；你的交易通到许多的海岛。主耶和华如此说：推罗啊，你曾说：‘我是全然美丽的。’"},
{volumn:        26,chapter:        27,versenumber:         4,verse:"你的境界在海中，造你的使你全然美丽。"},
{volumn:        26,chapter:        27,versenumber:         5,verse:"他们用示尼珥的松树做你的一切板，用黎巴嫩的香柏树做桅杆，"},
{volumn:        26,chapter:        27,versenumber:         6,verse:"用巴珊的橡树做你的桨，用象牙镶嵌基提海岛的黄杨木为坐板（或译：舱板）。"},
{volumn:        26,chapter:        27,versenumber:         7,verse:"你的篷帆是用埃及绣花细麻布做的，可以做你的大旗；你的凉棚是用以利沙岛的蓝色、紫色布做的。"},
{volumn:        26,chapter:        27,versenumber:         8,verse:"西顿和亚发的居民作你荡桨的。推罗啊，你中间的智慧人作掌舵的。"},
{volumn:        26,chapter:        27,versenumber:         9,verse:"迦巴勒的老者和聪明人都在你中间作补缝的；一切泛海的船只和水手都在你中间经营交易的事。"},
{volumn:        26,chapter:        27,versenumber:        10,verse:"“波斯人、路德人、弗人在你军营中作战士；他们在你中间悬挂盾牌和头盔，彰显你的尊荣。"},
{volumn:        26,chapter:        27,versenumber:        11,verse:"亚发人和你的军队都在你四围的墙上，你的望楼也有勇士；他们悬挂盾牌，成全你的美丽。"},
{volumn:        26,chapter:        27,versenumber:        12,verse:"“他施人因你多有各类的财物，就作你的客商，拿银、铁、锡、铅兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        13,verse:"雅完人、土巴人、米设人都与你交易；他们用人口和铜器兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        14,verse:"陀迦玛族用马和战马并骡子兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        15,verse:"底但人与你交易，许多海岛作你的码头；他们拿象牙、乌木与你兑换（或译：进贡）。"},
{volumn:        26,chapter:        27,versenumber:        16,verse:"亚兰人因你的工作很多，就作你的客商；他们用绿宝石、紫色布绣货、细麻布、珊瑚、红宝石兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        17,verse:"犹大和以色列地的人都与你交易；他们用米匿的麦子、饼、蜜、油、乳香兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        18,verse:"大马士革人因你的工作很多，又因你多有各类的财物，就拿黑本酒和白羊毛与你交易。"},
{volumn:        26,chapter:        27,versenumber:        19,verse:"威但人和雅完人拿纺成的线、亮铁、桂皮、菖蒲兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        20,verse:"底但人用高贵的毯子、鞍、屉与你交易。"},
{volumn:        26,chapter:        27,versenumber:        21,verse:"阿拉伯人和基达的一切首领都作你的客商，用羊羔、公绵羊、公山羊与你交易。"},
{volumn:        26,chapter:        27,versenumber:        22,verse:"示巴和拉玛的商人与你交易，他们用各类上好的香料、各类的宝石，和黄金兑换你的货物。"},
{volumn:        26,chapter:        27,versenumber:        23,verse:"哈兰人、干尼人、伊甸人、示巴的商人，和亚述人、基抹人与你交易。"},
{volumn:        26,chapter:        27,versenumber:        24,verse:"这些商人以美好的货物包在绣花蓝色包袱内，又有华丽的衣服装在香柏木的箱子里，用绳捆着与你交易。"},
{volumn:        26,chapter:        27,versenumber:        25,verse:"他施的船只接连成帮为你运货，你便在海中丰富极其荣华。"},
{volumn:        26,chapter:        27,versenumber:        26,verse:"荡桨的已经把你荡到大水之处，东风在海中将你打破。"},
{volumn:        26,chapter:        27,versenumber:        27,verse:"你的资财、物件、货物、水手、掌舵的、补缝的、经营交易的，并你中间的战士和人民，在你破坏的日子必都沉在海中。"},
{volumn:        26,chapter:        27,versenumber:        28,verse:"你掌舵的呼号之声一发，郊野都必震动。"},
{volumn:        26,chapter:        27,versenumber:        29,verse:"凡荡桨的和水手，并一切泛海掌舵的，都必下船登岸。"},
{volumn:        26,chapter:        27,versenumber:        30,verse:"他们必为你放声痛哭，把尘土撒在头上，在灰中打滚；"},
{volumn:        26,chapter:        27,versenumber:        31,verse:"又为你使头上光秃，用麻布束腰，号啕痛哭，苦苦悲哀。"},
{volumn:        26,chapter:        27,versenumber:        32,verse:"他们哀号的时候，为你作起哀歌哀哭，说：‘有何城如推罗？有何城如她在海中成为寂寞的呢？’"},
{volumn:        26,chapter:        27,versenumber:        33,verse:"你由海上运出货物，就使许多国民充足；你以许多资财、货物使地上的君王丰富。"},
{volumn:        26,chapter:        27,versenumber:        34,verse:"你在深水中被海浪打破的时候，你的货物和你中间的一切人民，就都沉下去了。"},
{volumn:        26,chapter:        27,versenumber:        35,verse:"海岛的居民为你惊奇；他们的君王都甚恐慌，面带愁容。"},
{volumn:        26,chapter:        27,versenumber:        36,verse:"各国民中的客商都向你发嘶声；你令人惊恐，不再存留于世，直到永远。”"},
{volumn:        26,chapter:        28,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        28,versenumber:         2,verse:"“人子啊，你对推罗君王说，主耶和华如此说：因你心里高傲，说：‘我是神；我在海中坐神之位。’你虽然居心自比神，也不过是人，并不是神！"},
{volumn:        26,chapter:        28,versenumber:         3,verse:"看哪，你比但以理更有智慧，什么秘事都不能向你隐藏。"},
{volumn:        26,chapter:        28,versenumber:         4,verse:"你靠自己的智慧聪明得了金银财宝，收入库中。"},
{volumn:        26,chapter:        28,versenumber:         5,verse:"你靠自己的大智慧和贸易增添资财，又因资财心里高傲。"},
{volumn:        26,chapter:        28,versenumber:         6,verse:"所以主耶和华如此说：因你居心自比神，"},
{volumn:        26,chapter:        28,versenumber:         7,verse:"我必使外邦人，就是列国中的强暴人临到你这里；他们必拔刀砍坏你用智慧得来的美物，亵渎你的荣光。"},
{volumn:        26,chapter:        28,versenumber:         8,verse:"他们必使你下坑；你必死在海中，与被杀的人一样。"},
{volumn:        26,chapter:        28,versenumber:         9,verse:"在杀你的人面前你还能说‘我是　神’吗？其实你在杀害你的人手中，不过是人，并不是神。"},
{volumn:        26,chapter:        28,versenumber:        10,verse:"你必死在外邦人手中，与未受割礼（或译：不洁；下同）的人一样，因为这是主耶和华说的。”"},
{volumn:        26,chapter:        28,versenumber:        11,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        28,versenumber:        12,verse:"“人子啊，你为推罗王作起哀歌，说主耶和华如此说：你无所不备，智慧充足，全然美丽。"},
{volumn:        26,chapter:        28,versenumber:        13,verse:"你曾在伊甸　神的园中，佩戴各样宝石，就是红宝石、红璧玺、金钢石、水苍玉、红玛瑙、碧玉、蓝宝石、绿宝石、红玉，和黄金；又有精美的鼓笛在你那里，都是在你受造之日预备齐全的。"},
{volumn:        26,chapter:        28,versenumber:        14,verse:"你是那受膏遮掩约柜的基路伯；我将你安置在　神的圣山上；你在发光如火的宝石中间往来。"},
{volumn:        26,chapter:        28,versenumber:        15,verse:"你从受造之日所行的都完全，后来在你中间又察出不义。"},
{volumn:        26,chapter:        28,versenumber:        16,verse:"因你贸易很多，就被强暴的事充满，以致犯罪，所以我因你亵渎圣地，就从　神的山驱逐你。遮掩约柜的基路伯啊，我已将你从发光如火的宝石中除灭。"},
{volumn:        26,chapter:        28,versenumber:        17,verse:"你因美丽心中高傲，又因荣光败坏智慧，我已将你摔倒在地，使你倒在君王面前，好叫他们目睹眼见。"},
{volumn:        26,chapter:        28,versenumber:        18,verse:"你因罪孽众多，贸易不公，就亵渎你那里的圣所。故此，我使火从你中间发出，烧灭你，使你在所有观看的人眼前变为地上的炉灰。"},
{volumn:        26,chapter:        28,versenumber:        19,verse:"各国民中，凡认识你的，都必为你惊奇。你令人惊恐，不再存留于世，直到永远。”"},
{volumn:        26,chapter:        28,versenumber:        20,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        28,versenumber:        21,verse:"“人子啊，你要向西顿预言攻击她，"},
{volumn:        26,chapter:        28,versenumber:        22,verse:"说主耶和华如此说：西顿哪，我与你为敌，我必在你中间得荣耀。我在你中间施行审判、显为圣的时候，人就知道我是耶和华。"},
{volumn:        26,chapter:        28,versenumber:        23,verse:"我必使瘟疫进入西顿，使血流在她街上。被杀的必在其中仆倒，四围有刀剑临到她，人就知道我是耶和华。”"},
{volumn:        26,chapter:        28,versenumber:        24,verse:"“四围恨恶以色列家的人，必不再向他们作刺人的荆棘，伤人的蒺藜，人就知道我是主耶和华。”"},
{volumn:        26,chapter:        28,versenumber:        25,verse:"主耶和华如此说：“我将分散在万民中的以色列家招聚回来，向他们在列邦人眼前显为圣的时候，他们就在我赐给我仆人雅各之地，仍然居住。"},
{volumn:        26,chapter:        28,versenumber:        26,verse:"他们要在这地上安然居住。我向四围恨恶他们的众人施行审判以后，他们要盖造房屋，栽种葡萄园，安然居住，就知道我是耶和华他们的　神。”"},
{volumn:        26,chapter:        29,versenumber:         1,verse:"第十年十月十二日，耶和华的话临到我说："},
{volumn:        26,chapter:        29,versenumber:         2,verse:"“人子啊，你要向埃及王法老预言攻击他和埃及全地，"},
{volumn:        26,chapter:        29,versenumber:         3,verse:"说主耶和华如此说：埃及王法老啊，我与你这卧在自己河中的大鱼为敌。你曾说：‘这河是我的，是我为自己造的。’"},
{volumn:        26,chapter:        29,versenumber:         4,verse:"我耶和华必用钩子钩住你的腮颊，又使江河中的鱼贴住你的鳞甲；我必将你和所有贴住你鳞甲的鱼，从江河中拉上来，"},
{volumn:        26,chapter:        29,versenumber:         5,verse:"把你并江河中的鱼都抛在旷野；你必倒在田间，不被收殓，不被掩埋。我已将你给地上野兽、空中飞鸟作食物。"},
{volumn:        26,chapter:        29,versenumber:         6,verse:"“埃及一切的居民，因向以色列家成了芦苇的杖，就知道我是耶和华。"},
{volumn:        26,chapter:        29,versenumber:         7,verse:"他们用手持住你，你就断折，伤了他们的肩；他们倚靠你，你就断折，闪了他们的腰。"},
{volumn:        26,chapter:        29,versenumber:         8,verse:"所以主耶和华如此说：我必使刀剑临到你，从你中间将人与牲畜剪除。"},
{volumn:        26,chapter:        29,versenumber:         9,verse:"埃及地必荒废凄凉，他们就知道我是耶和华。“因为法老说：‘这河是我的，是我所造的’，"},
{volumn:        26,chapter:        29,versenumber:        10,verse:"所以我必与你并你的江河为敌，使埃及地，从色弗尼塔直到古实境界，全然荒废凄凉。"},
{volumn:        26,chapter:        29,versenumber:        11,verse:"人的脚、兽的蹄都不经过，四十年之久并无人居住。"},
{volumn:        26,chapter:        29,versenumber:        12,verse:"我必使埃及地在荒凉的国中成为荒凉，使埃及城在荒废的城中变为荒废，共有四十年。我必将埃及人分散在列国，四散在列邦。”"},
{volumn:        26,chapter:        29,versenumber:        13,verse:"主耶和华如此说：“满了四十年，我必招聚分散在各国民中的埃及人。"},
{volumn:        26,chapter:        29,versenumber:        14,verse:"我必叫埃及被掳的人回来，使他们归回本地巴忒罗。在那里必成为低微的国，"},
{volumn:        26,chapter:        29,versenumber:        15,verse:"必为列国中最低微的，也不再自高于列国之上。我必减少他们，以致不再辖制列国。"},
{volumn:        26,chapter:        29,versenumber:        16,verse:"埃及必不再作以色列家所倚靠的；以色列家仰望埃及人的时候，便思念罪孽。他们就知道我是主耶和华。”"},
{volumn:        26,chapter:        29,versenumber:        17,verse:"二十七年正月初一日，耶和华的话临到我说："},
{volumn:        26,chapter:        29,versenumber:        18,verse:"“人子啊，巴比伦王尼布甲尼撒使他的军兵大大效劳，攻打推罗，以致头都光秃，肩都磨破；然而他和他的军兵攻打推罗，并没有从那里得什么酬劳。"},
{volumn:        26,chapter:        29,versenumber:        19,verse:"所以主耶和华如此说：我必将埃及地赐给巴比伦王尼布甲尼撒；他必掳掠埃及群众，抢其中的财为掳物，夺其中的货为掠物，这就可以作他军兵的酬劳。"},
{volumn:        26,chapter:        29,versenumber:        20,verse:"我将埃及地赐给他，酬他所效的劳，因王与军兵是为我勤劳。这是主耶和华说的。"},
{volumn:        26,chapter:        29,versenumber:        21,verse:"“当那日，我必使以色列家的角发生，又必使你以西结在他们中间得以开口；他们就知道我是耶和华。”"},
{volumn:        26,chapter:        30,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        30,versenumber:         2,verse:"“人子啊，你要发预言说，主耶和华如此说：哀哉这日！你们应当哭号。"},
{volumn:        26,chapter:        30,versenumber:         3,verse:"因为耶和华的日子临近，就是密云之日，列国受罚之期。"},
{volumn:        26,chapter:        30,versenumber:         4,verse:"必有刀剑临到埃及；在埃及被杀之人仆倒的时候，古实人就有痛苦，人民必被掳掠，基址必被拆毁。"},
{volumn:        26,chapter:        30,versenumber:         5,verse:"古实人、弗人（或译：利比亚）、路德人、杂族的人民，并古巴人，以及同盟之地的人都要与埃及人一同倒在刀下。”"},
{volumn:        26,chapter:        30,versenumber:         6,verse:"耶和华如此说：“扶助埃及的也必倾倒。埃及因势力而有的骄傲必降低微；其中的人民，从色弗尼塔起（见二十九章十节）必倒在刀下。这是主耶和华说的。"},
{volumn:        26,chapter:        30,versenumber:         7,verse:"埃及地在荒凉的国中必成为荒凉；埃及城在荒废的城中也变为荒废。"},
{volumn:        26,chapter:        30,versenumber:         8,verse:"我在埃及中使火着起；帮助埃及的，都被灭绝。那时，他们就知道我是耶和华。"},
{volumn:        26,chapter:        30,versenumber:         9,verse:"“到那日，必有使者坐船，从我面前出去，使安逸无虑的古实人惊惧；必有痛苦临到他们，好像埃及遭灾的日子一样。看哪，这事临近了！”"},
{volumn:        26,chapter:        30,versenumber:        10,verse:"主耶和华如此说：“我必藉巴比伦王尼布甲尼撒的手，除灭埃及众人。"},
{volumn:        26,chapter:        30,versenumber:        11,verse:"他和随从他的人，就是列国中强暴的，必进来毁灭这地。他们必拔刀攻击埃及，使遍地有被杀的人。"},
{volumn:        26,chapter:        30,versenumber:        12,verse:"我必使江河干涸，将地卖在恶人的手中；我必藉外邦人的手，使这地和其中所有的变为凄凉。这是我耶和华说的。”"},
{volumn:        26,chapter:        30,versenumber:        13,verse:"主耶和华如此说：“我必毁灭偶像，从挪弗除灭神像；必不再有君王出自埃及地。我要使埃及地的人惧怕。"},
{volumn:        26,chapter:        30,versenumber:        14,verse:"我必使巴忒罗荒凉，在琐安中使火着起，向挪施行审判。"},
{volumn:        26,chapter:        30,versenumber:        15,verse:"我必将我的忿怒倒在埃及的保障上，就是训上，并要剪除挪的众人。"},
{volumn:        26,chapter:        30,versenumber:        16,verse:"我必在埃及中使火着起；训必大大痛苦；挪必被攻破；挪弗白日（或译：终日）见仇敌。"},
{volumn:        26,chapter:        30,versenumber:        17,verse:"亚文和比伯实的少年人必倒在刀下；这些城的人必被掳掠。"},
{volumn:        26,chapter:        30,versenumber:        18,verse:"我在答比匿折断埃及的诸轭，使她因势力而有的骄傲在其中止息。那时，日光必退去；至于这城，必有密云遮蔽，其中的女子必被掳掠。"},
{volumn:        26,chapter:        30,versenumber:        19,verse:"我必这样向埃及施行审判，他们就知道我是耶和华。”"},
{volumn:        26,chapter:        30,versenumber:        20,verse:"十一年正月初七日，耶和华的话临到我说："},
{volumn:        26,chapter:        30,versenumber:        21,verse:"“人子啊，我已打折埃及王法老的膀臂；没有敷药，也没有用布缠好，使他有力持刀。"},
{volumn:        26,chapter:        30,versenumber:        22,verse:"所以主耶和华如此说：看哪，我与埃及王法老为敌，必将他有力的膀臂和已打折的膀臂全行打断，使刀从他手中坠落。"},
{volumn:        26,chapter:        30,versenumber:        23,verse:"我必将埃及人分散在列国，四散在列邦。"},
{volumn:        26,chapter:        30,versenumber:        24,verse:"我必使巴比伦王的膀臂有力，将我的刀交在他手中；却要打断法老的膀臂，他就在巴比伦王面前唉哼，如同受死伤的人一样。"},
{volumn:        26,chapter:        30,versenumber:        25,verse:"我必扶持巴比伦王的膀臂，法老的膀臂却要下垂；我将我的刀交在巴比伦王手中，他必举刀攻击埃及地，他们就知道我是耶和华。"},
{volumn:        26,chapter:        30,versenumber:        26,verse:"我必将埃及人分散在列国，四散在列邦；他们就知道我是耶和华。”"},
{volumn:        26,chapter:        31,versenumber:         1,verse:"十一年三月初一日，耶和华的话临到我说："},
{volumn:        26,chapter:        31,versenumber:         2,verse:"“人子啊，你要向埃及王法老和他的众人说：在威势上谁能与你相比呢？"},
{volumn:        26,chapter:        31,versenumber:         3,verse:"亚述王曾如黎巴嫩中的香柏树，枝条荣美，影密如林，极其高大，树尖插入云中。"},
{volumn:        26,chapter:        31,versenumber:         4,verse:"众水使它生长；深水使它长大。所栽之地有江河围流，汊出的水道延到田野诸树。"},
{volumn:        26,chapter:        31,versenumber:         5,verse:"所以它高大超过田野诸树；发旺的时候，枝子繁多，因得大水之力枝条长长。"},
{volumn:        26,chapter:        31,versenumber:         6,verse:"空中的飞鸟都在枝子上搭窝；田野的走兽都在枝条下生子；所有大国的人民都在它荫下居住。"},
{volumn:        26,chapter:        31,versenumber:         7,verse:"树大条长，成为荣美，因为根在众水之旁。"},
{volumn:        26,chapter:        31,versenumber:         8,verse:"神园中的香柏树不能遮蔽它；松树不及它的枝子；枫树不及它的枝条；　神园中的树都没有它荣美。"},
{volumn:        26,chapter:        31,versenumber:         9,verse:"我使它的枝条蕃多，成为荣美，以致　神伊甸园中的树都嫉妒它。”"},
{volumn:        26,chapter:        31,versenumber:        10,verse:"所以主耶和华如此说：“因它高大，树尖插入云中，心骄气傲，"},
{volumn:        26,chapter:        31,versenumber:        11,verse:"我就必将它交给列国中大有威势的人；他必定办它。我因它的罪恶，已经驱逐它。"},
{volumn:        26,chapter:        31,versenumber:        12,verse:"外邦人，就是列邦中强暴的，将它砍断弃掉。它的枝条落在山间和一切谷中，它的枝子折断，落在地的一切河旁。地上的众民已经走去，离开它的荫下。"},
{volumn:        26,chapter:        31,versenumber:        13,verse:"空中的飞鸟都要宿在这败落的树上，田野的走兽都要卧在它的枝条下，"},
{volumn:        26,chapter:        31,versenumber:        14,verse:"好使水旁的诸树不因高大而自尊，也不将树尖插入云中，并且那些得水滋润、有势力的，也不得高大自立。因为它们在世人中，和下坑的人都被交与死亡，到阴府去了。”"},
{volumn:        26,chapter:        31,versenumber:        15,verse:"主耶和华如此说：“它下阴间的那日，我便使人悲哀。我为它遮盖深渊，使江河凝结，大水停流；我也使黎巴嫩为它凄惨，田野的诸树都因它发昏。"},
{volumn:        26,chapter:        31,versenumber:        16,verse:"我将它扔到阴间，与下坑的人一同下去。那时，列国听见它坠落的响声就都震动，并且伊甸的一切树，就是黎巴嫩得水滋润、最佳最美的树，都在阴府受了安慰。"},
{volumn:        26,chapter:        31,versenumber:        17,verse:"它们也与它同下阴间，到被杀的人那里。它们曾作它的膀臂，在列国中它的荫下居住。"},
{volumn:        26,chapter:        31,versenumber:        18,verse:"在这样荣耀威势上，在伊甸园诸树中，谁能与你相比呢？然而你要与伊甸的诸树一同下到阴府，在未受割礼的人中，与被杀的人一同躺卧。“法老和他的群众乃是如此。这是主耶和华说的。”"},
{volumn:        26,chapter:        32,versenumber:         1,verse:"十二年十二月初一日，耶和华的话临到我说："},
{volumn:        26,chapter:        32,versenumber:         2,verse:"“人子啊，你要为埃及王法老作哀歌，说：从前你在列国中，如同少壮狮子；现在你却像海中的大鱼。你冲出江河，用爪搅动诸水，使江河浑浊。"},
{volumn:        26,chapter:        32,versenumber:         3,verse:"主耶和华如此说：我必用多国的人民，将我的网撒在你身上，把你拉上来。"},
{volumn:        26,chapter:        32,versenumber:         4,verse:"我必将你丢在地上，抛在田野，使空中的飞鸟都落在你身上，使遍地的野兽吃你得饱。"},
{volumn:        26,chapter:        32,versenumber:         5,verse:"我必将你的肉丢在山间，用你高大的尸首填满山谷。"},
{volumn:        26,chapter:        32,versenumber:         6,verse:"我又必用你的血浇灌你所游泳之地，漫过山顶；河道都必充满。"},
{volumn:        26,chapter:        32,versenumber:         7,verse:"我将你扑灭的时候，要把天遮蔽，使众星昏暗，以密云遮掩太阳，月亮也不放光。"},
{volumn:        26,chapter:        32,versenumber:         8,verse:"我必使天上的亮光都在你以上变为昏暗，使你的地上黑暗。这是主耶和华说的。"},
{volumn:        26,chapter:        32,versenumber:         9,verse:"“我使你败亡的风声传到你所不认识的各国。那时，我必使多民的心因你愁烦。"},
{volumn:        26,chapter:        32,versenumber:        10,verse:"我在许多国民和君王面前向你抡我的刀，国民就必因你惊奇，君王也必因你极其恐慌。在你仆倒的日子，他们各人为自己的性命时刻战兢。”"},
{volumn:        26,chapter:        32,versenumber:        11,verse:"主耶和华如此说：“巴比伦王的刀必临到你。"},
{volumn:        26,chapter:        32,versenumber:        12,verse:"我必藉勇士的刀使你的众民仆倒；这勇士都是列国中强暴的。他们必使埃及的骄傲归于无有；埃及的众民必被灭绝。"},
{volumn:        26,chapter:        32,versenumber:        13,verse:"我必从埃及多水旁除灭所有的走兽；人脚兽蹄必不再搅浑这水。"},
{volumn:        26,chapter:        32,versenumber:        14,verse:"那时，我必使埃及河澄清，江河像油缓流。这是主耶和华说的。"},
{volumn:        26,chapter:        32,versenumber:        15,verse:"我使埃及地变为荒废凄凉；这地缺少从前所充满的，又击杀其中一切的居民。那时，他们就知道我是耶和华。"},
{volumn:        26,chapter:        32,versenumber:        16,verse:"“人必用这哀歌去哀哭，列国的女子为埃及和她的群众也必以此悲哀。这是主耶和华说的。”"},
{volumn:        26,chapter:        32,versenumber:        17,verse:"十二年十二月十五日，耶和华的话临到我说："},
{volumn:        26,chapter:        32,versenumber:        18,verse:"“人子啊，你要为埃及群众哀号，又要将埃及和有名之国的女子，并下坑的人，一同扔到阴府去。"},
{volumn:        26,chapter:        32,versenumber:        19,verse:"“你埃及的美丽胜过谁呢？你下去与未受割礼的人一同躺卧吧！"},
{volumn:        26,chapter:        32,versenumber:        20,verse:"“他们必在被杀的人中仆到。她被交给刀剑，要把她和她的群众拉去。"},
{volumn:        26,chapter:        32,versenumber:        21,verse:"强盛的勇士要在阴间对埃及王和帮助他的说话：‘他们是未受割礼被杀的人，已经下去，躺卧不动。’"},
{volumn:        26,chapter:        32,versenumber:        22,verse:"“亚述和她的众民都在那里，她民的坟墓在她四围；他们都是被杀倒在刀下的。"},
{volumn:        26,chapter:        32,versenumber:        23,verse:"他们的坟墓在坑中极深之处。她的众民在她坟墓的四围，都是被杀倒在刀下的；他们曾在活人之地使人惊恐。"},
{volumn:        26,chapter:        32,versenumber:        24,verse:"“以拦也在那里，她的群众在她坟墓的四围，都是被杀倒在刀下、未受割礼而下阴府的；他们曾在活人之地使人惊恐，并且与下坑的人一同担当羞辱。"},
{volumn:        26,chapter:        32,versenumber:        25,verse:"人给她和她的群众在被杀的人中设立床榻。她民的坟墓在她四围，他们都是未受割礼被刀杀的；他们曾在活人之地使人惊恐，并且与下坑的人一同担当羞辱。以拦已经放在被杀的人中。"},
{volumn:        26,chapter:        32,versenumber:        26,verse:"“米设、土巴，和她们的群众都在那里。她民的坟墓在她四围，他们都是未受割礼被刀杀的；他们曾在活人之地使人惊恐。"},
{volumn:        26,chapter:        32,versenumber:        27,verse:"他们不得与那未受割礼仆倒的勇士一同躺卧；这些勇士带着兵器下阴间，头枕刀剑，骨头上有本身的罪孽；他们曾在活人之地使勇士惊恐。"},
{volumn:        26,chapter:        32,versenumber:        28,verse:"“法老啊，你必在未受割礼的人中败坏，与那些被杀的人一同躺卧。"},
{volumn:        26,chapter:        32,versenumber:        29,verse:"“以东也在那里。她君王和一切首领虽然仗着势力，还是放在被杀的人中；他们必与未受割礼的和下坑的人一同躺卧。"},
{volumn:        26,chapter:        32,versenumber:        30,verse:"“在那里有北方的众王子和一切西顿人，都与被杀的人下去。他们虽然仗着势力使人惊恐，还是蒙羞。他们未受割礼，和被刀杀的一同躺卧，与下坑的人一同担当羞辱。"},
{volumn:        26,chapter:        32,versenumber:        31,verse:"“法老看见他们，便为他被杀的军队受安慰。这是主耶和华说的。"},
{volumn:        26,chapter:        32,versenumber:        32,verse:"我任凭法老在活人之地使人惊恐，法老和他的群众必放在未受割礼和被杀的人中。这是主耶和华说的。”"},
{volumn:        26,chapter:        33,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        33,versenumber:         2,verse:"“人子啊，你要告诉本国的子民说：我使刀剑临到哪一国，那一国的民从他们中间选立一人为守望的。"},
{volumn:        26,chapter:        33,versenumber:         3,verse:"他见刀剑临到那地，若吹角警戒众民，"},
{volumn:        26,chapter:        33,versenumber:         4,verse:"凡听见角声不受警戒的，刀剑若来除灭了他，他的罪（原文是血）就必归到自己的头上。"},
{volumn:        26,chapter:        33,versenumber:         5,verse:"他听见角声，不受警戒，他的罪必归到自己的身上；他若受警戒，便是救了自己的性命。"},
{volumn:        26,chapter:        33,versenumber:         6,verse:"倘若守望的人见刀剑临到，不吹角，以致民不受警戒，刀剑来杀了他们中间的一个人，他虽然死在罪孽之中，我却要向守望的人讨他丧命的罪（原文是血）。"},
{volumn:        26,chapter:        33,versenumber:         7,verse:"“人子啊，我照样立你作以色列家守望的人。所以你要听我口中的话，替我警戒他们。"},
{volumn:        26,chapter:        33,versenumber:         8,verse:"我对恶人说：‘恶人哪，你必要死！’你以西结若不开口警戒恶人，使他离开所行的道，这恶人必死在罪孽之中，我却要向你讨他丧命的罪（原文是血）。"},
{volumn:        26,chapter:        33,versenumber:         9,verse:"倘若你警戒恶人转离所行的道，他仍不转离，他必死在罪孽之中，你却救自己脱离了罪。”"},
{volumn:        26,chapter:        33,versenumber:        10,verse:"“人子啊，你要对以色列家说：‘你们常说：我们的过犯罪恶在我们身上，我们必因此消灭，怎能存活呢？’"},
{volumn:        26,chapter:        33,versenumber:        11,verse:"你对他们说，主耶和华说：我指着我的永生起誓，我断不喜悦恶人死亡，惟喜悦恶人转离所行的道而活。以色列家啊，你们转回，转回吧！离开恶道，何必死亡呢？"},
{volumn:        26,chapter:        33,versenumber:        12,verse:"人子啊，你要对本国的人民说：义人的义，在犯罪之日不能救他；至于恶人的恶，在他转离恶行之日也不能使他倾倒；义人在犯罪之日也不能因他的义存活。"},
{volumn:        26,chapter:        33,versenumber:        13,verse:"我对义人说：‘你必定存活！’他若倚靠他的义而作罪孽，他所行的义都不被记念。他必因所作的罪孽死亡。"},
{volumn:        26,chapter:        33,versenumber:        14,verse:"再者，我对恶人说：‘你必定死亡！’他若转离他的罪，行正直与合理的事："},
{volumn:        26,chapter:        33,versenumber:        15,verse:"还人的当头和所抢夺的，遵行生命的律例，不作罪孽，他必定存活，不致死亡。"},
{volumn:        26,chapter:        33,versenumber:        16,verse:"他所犯的一切罪必不被记念。他行了正直与合理的事，必定存活。"},
{volumn:        26,chapter:        33,versenumber:        17,verse:"“你本国的子民还说：‘主的道不公平。’其实他们的道不公平。"},
{volumn:        26,chapter:        33,versenumber:        18,verse:"义人转离他的义而作罪孽，就必因此死亡。"},
{volumn:        26,chapter:        33,versenumber:        19,verse:"恶人转离他的恶，行正直与合理的事，就必因此存活。"},
{volumn:        26,chapter:        33,versenumber:        20,verse:"你们还说：‘主的道不公平。’以色列家啊，我必按你们各人所行的审判你们。”"},
{volumn:        26,chapter:        33,versenumber:        21,verse:"我们被掳之后十二年十月初五日，有人从耶路撒冷逃到我这里，说：“城已攻破。”"},
{volumn:        26,chapter:        33,versenumber:        22,verse:"逃来的人未到前一日的晚上，耶和华的灵（原文是手）降在我身上，开我的口。到第二日早晨，那人来到我这里，我口就开了，不再缄默。"},
{volumn:        26,chapter:        33,versenumber:        23,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        33,versenumber:        24,verse:"“人子啊，住在以色列荒废之地的人说：‘亚伯拉罕独自一人能得这地为业，我们人数众多，这地更是给我们为业的。’"},
{volumn:        26,chapter:        33,versenumber:        25,verse:"所以你要对他们说，主耶和华如此说：你们吃带血的物，仰望偶像，并且杀人流血，你们还能得这地为业吗？"},
{volumn:        26,chapter:        33,versenumber:        26,verse:"你们倚仗自己的刀剑行可憎的事，人人玷污邻舍的妻，你们还能得这地为业吗？"},
{volumn:        26,chapter:        33,versenumber:        27,verse:"你要对他们这样说，主耶和华如此说：我指着我的永生起誓，在荒场中的，必倒在刀下；在田野间的，必交给野兽吞吃；在保障和洞里的，必遭瘟疫而死。"},
{volumn:        26,chapter:        33,versenumber:        28,verse:"我必使这地荒凉，令人惊骇；她因势力而有的骄傲也必止息。以色列的山都必荒凉，无人经过。"},
{volumn:        26,chapter:        33,versenumber:        29,verse:"我因他们所行一切可憎的事使地荒凉，令人惊骇。那时，他们就知道我是耶和华。”"},
{volumn:        26,chapter:        33,versenumber:        30,verse:"“人子啊，你本国的子民在墙垣旁边、在房屋门口谈论你。弟兄对弟兄彼此说：‘来吧！听听有什么话从耶和华而出。’"},
{volumn:        26,chapter:        33,versenumber:        31,verse:"他们来到你这里如同民来聚会，坐在你面前仿佛是我的民。他们听你的话却不去行；因为他们的口多显爱情，心却追随财利。"},
{volumn:        26,chapter:        33,versenumber:        32,verse:"他们看你如善于奏乐、声音幽雅之人所唱的雅歌，他们听你的话却不去行。"},
{volumn:        26,chapter:        33,versenumber:        33,verse:"看哪，所说的快要应验；应验了，他们就知道在他们中间有了先知。”"},
{volumn:        26,chapter:        34,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        34,versenumber:         2,verse:"“人子啊，你要向以色列的牧人发预言，攻击他们，说，主耶和华如此说：祸哉！以色列的牧人只知牧养自己。牧人岂不当牧养群羊吗？"},
{volumn:        26,chapter:        34,versenumber:         3,verse:"你们吃脂油、穿羊毛、宰肥壮的，却不牧养群羊。"},
{volumn:        26,chapter:        34,versenumber:         4,verse:"瘦弱的，你们没有养壮；有病的，你们没有医治；受伤的，你们没有缠裹；被逐的，你们没有领回；失丧的，你们没有寻找；但用强暴严严地辖制。"},
{volumn:        26,chapter:        34,versenumber:         5,verse:"因无牧人，羊就分散；既分散，便作了一切野兽的食物。"},
{volumn:        26,chapter:        34,versenumber:         6,verse:"我的羊在诸山间、在各高冈上流离，在全地上分散，无人去寻，无人去找。"},
{volumn:        26,chapter:        34,versenumber:         7,verse:"“所以，你们这些牧人要听耶和华的话。"},
{volumn:        26,chapter:        34,versenumber:         8,verse:"主耶和华说：我指着我的永生起誓，我的羊因无牧人就成为掠物，也作了一切野兽的食物。我的牧人不寻找我的羊；这些牧人只知牧养自己，并不牧养我的羊。"},
{volumn:        26,chapter:        34,versenumber:         9,verse:"所以你们这些牧人要听耶和华的话。"},
{volumn:        26,chapter:        34,versenumber:        10,verse:"主耶和华如此说：我必与牧人为敌，必向他们的手追讨我的羊，使他们不再牧放群羊；牧人也不再牧养自己。我必救我的羊脱离他们的口，不再作他们的食物。”"},
{volumn:        26,chapter:        34,versenumber:        11,verse:"“主耶和华如此说：看哪，我必亲自寻找我的羊，将它们寻见。"},
{volumn:        26,chapter:        34,versenumber:        12,verse:"牧人在羊群四散的日子怎样寻找他的羊，我必照样寻找我的羊。这些羊在密云黑暗的日子散到各处，我必从那里救回它们来。"},
{volumn:        26,chapter:        34,versenumber:        13,verse:"我必从万民中领出它们，从各国内聚集它们，引导它们归回故土，也必在以色列山上，一切溪水旁边、境内一切可居之处牧养它们。"},
{volumn:        26,chapter:        34,versenumber:        14,verse:"我必在美好的草场牧养它们。它们的圈必在以色列高处的山上，它们必在佳美之圈中躺卧，也在以色列山肥美的草场吃草。"},
{volumn:        26,chapter:        34,versenumber:        15,verse:"主耶和华说：我必亲自作我羊的牧人，使它们得以躺卧。"},
{volumn:        26,chapter:        34,versenumber:        16,verse:"失丧的，我必寻找；被逐的，我必领回；受伤的，我必缠裹；有病的，我必医治；只是肥的壮的，我必除灭，也要秉公牧养它们。"},
{volumn:        26,chapter:        34,versenumber:        17,verse:"“我的羊群哪，论到你们，主耶和华如此说：我必在羊与羊中间、公绵羊与公山羊中间施行判断。"},
{volumn:        26,chapter:        34,versenumber:        18,verse:"你们这些肥壮的羊，在美好的草场吃草还以为小事吗？剩下的草，你们竟用蹄践踏了；你们喝清水，剩下的水，你们竟用蹄搅浑了。"},
{volumn:        26,chapter:        34,versenumber:        19,verse:"至于我的羊，只得吃你们所践踏的，喝你们所搅浑的。"},
{volumn:        26,chapter:        34,versenumber:        20,verse:"“所以，主耶和华如此说：我必在肥羊和瘦羊中间施行判断。"},
{volumn:        26,chapter:        34,versenumber:        21,verse:"因为你们用胁用肩拥挤一切瘦弱的，又用角抵触，以致使它们四散。"},
{volumn:        26,chapter:        34,versenumber:        22,verse:"所以，我必拯救我的群羊不再作掠物；我也必在羊和羊中间施行判断。"},
{volumn:        26,chapter:        34,versenumber:        23,verse:"我必立一牧人照管他们，牧养他们，就是我的仆人大卫。他必牧养他们，作他们的牧人。"},
{volumn:        26,chapter:        34,versenumber:        24,verse:"我耶和华必作他们的　神，我的仆人大卫必在他们中间作王。这是耶和华说的。"},
{volumn:        26,chapter:        34,versenumber:        25,verse:"“我必与他们立平安的约，使恶兽从境内断绝，他们就必安居在旷野，躺卧在林中。"},
{volumn:        26,chapter:        34,versenumber:        26,verse:"我必使他们与我山的四围成为福源，我也必叫时雨落下，必有福如甘霖而降。"},
{volumn:        26,chapter:        34,versenumber:        27,verse:"田野的树必结果，地也必有出产；他们必在故土安然居住。我折断他们所负的轭，救他们脱离那以他们为奴之人的手；那时，他们就知道我是耶和华。"},
{volumn:        26,chapter:        34,versenumber:        28,verse:"他们必不再作外邦人的掠物，地上的野兽也不再吞吃他们；却要安然居住，无人惊吓。"},
{volumn:        26,chapter:        34,versenumber:        29,verse:"我必给他们兴起有名的植物；他们在境内不再为饥荒所灭，也不再受外邦人的羞辱，"},
{volumn:        26,chapter:        34,versenumber:        30,verse:"必知道我、耶和华他们的　神是与他们同在的，并知道他们以色列家是我的民。这是主耶和华说的。"},
{volumn:        26,chapter:        34,versenumber:        31,verse:"你们作我的羊，我草场上的羊，乃是以色列人，我也是你们的　神。这是主耶和华说的。”"},
{volumn:        26,chapter:        35,versenumber:         1,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        35,versenumber:         2,verse:"“人子啊，你要面向西珥山发预言，攻击它，"},
{volumn:        26,chapter:        35,versenumber:         3,verse:"对它说，主耶和华如此说：西珥山哪，我与你为敌，必向你伸手攻击你，使你荒凉，令人惊骇。"},
{volumn:        26,chapter:        35,versenumber:         4,verse:"我必使你的城邑变为荒场，成为凄凉。你就知道我是耶和华。"},
{volumn:        26,chapter:        35,versenumber:         5,verse:"因为你永怀仇恨，在以色列人遭灾、罪孽到了尽头的时候，将他们交与刀剑，"},
{volumn:        26,chapter:        35,versenumber:         6,verse:"所以主耶和华说：我指着我的永生起誓，我必使你遭遇流血的报应，罪（原文是血；本节同）必追赶你；你既不恨恶杀人流血，所以这罪必追赶你。"},
{volumn:        26,chapter:        35,versenumber:         7,verse:"我必使西珥山荒凉，令人惊骇，来往经过的人我必剪除。"},
{volumn:        26,chapter:        35,versenumber:         8,verse:"我必使西珥山满有被杀的人。被刀杀的，必倒在你小山和山谷，并一切的溪水中。"},
{volumn:        26,chapter:        35,versenumber:         9,verse:"我必使你永远荒凉，使你的城邑无人居住，你的民就知道我是耶和华。"},
{volumn:        26,chapter:        35,versenumber:        10,verse:"“因为你曾说：‘这二国这二邦必归于我，我必得为业’（其实耶和华仍在那里），"},
{volumn:        26,chapter:        35,versenumber:        11,verse:"所以主耶和华说：我指着我的永生起誓，我必照你的怒气和你从仇恨中向他们所发的嫉妒待你。我审判你的时候，必将自己显明在他们中间。"},
{volumn:        26,chapter:        35,versenumber:        12,verse:"你也必知道我耶和华听见了你的一切毁谤，就是你攻击以色列山的话，说：‘这些山荒凉，是归我们吞灭的。’"},
{volumn:        26,chapter:        35,versenumber:        13,verse:"你们也用口向我夸大，增添与我反对的话，我都听见了。"},
{volumn:        26,chapter:        35,versenumber:        14,verse:"主耶和华如此说：全地欢乐的时候，我必使你荒凉。"},
{volumn:        26,chapter:        35,versenumber:        15,verse:"你怎样因以色列家的地业荒凉而喜乐，我必照你所行的待你。西珥山哪，你和以东全地必都荒凉。你们就知道我是耶和华。”"},
{volumn:        26,chapter:        36,versenumber:         1,verse:"“人子啊，你要对以色列山发预言说：以色列山哪，要听耶和华的话。"},
{volumn:        26,chapter:        36,versenumber:         2,verse:"主耶和华如此说：因仇敌说：‘阿哈！这永久的山冈都归我们为业了！’"},
{volumn:        26,chapter:        36,versenumber:         3,verse:"所以要发预言说，主耶和华如此说：因为敌人使你荒凉，四围吞吃，好叫你归与其余的外邦人为业，并且多嘴多舌的人提起你来，百姓也说你有臭名。"},
{volumn:        26,chapter:        36,versenumber:         4,verse:"故此，以色列山要听主耶和华的话。大山小冈、水沟山谷、荒废之地、被弃之城，为四围其余的外邦人所占据、所讥刺的，"},
{volumn:        26,chapter:        36,versenumber:         5,verse:"主耶和华对你们如此说：我真发愤恨如火，责备那其余的外邦人和以东的众人。他们快乐满怀，心存恨恶，将我的地归自己为业，又看为被弃的掠物。"},
{volumn:        26,chapter:        36,versenumber:         6,verse:"所以，你要指着以色列地说预言，对大山小冈、水沟山谷说，主耶和华如此说：我发愤恨和忿怒说，因你们曾受外邦人的羞辱，"},
{volumn:        26,chapter:        36,versenumber:         7,verse:"所以我起誓说：你们四围的外邦人总要担当自己的羞辱。这是主耶和华说的。"},
{volumn:        26,chapter:        36,versenumber:         8,verse:"“以色列山哪，你必发枝条，为我的民以色列结果子，因为他们快要来到。"},
{volumn:        26,chapter:        36,versenumber:         9,verse:"看哪，我是帮助你的，也必向你转意，使你得以耕种。"},
{volumn:        26,chapter:        36,versenumber:        10,verse:"我必使以色列全家的人数在你上面增多，城邑有人居住，荒场再被建造。"},
{volumn:        26,chapter:        36,versenumber:        11,verse:"我必使人和牲畜在你上面加增；他们必生养众多。我要使你照旧有人居住，并要赐福与你比先前更多，你就知道我是耶和华。"},
{volumn:        26,chapter:        36,versenumber:        12,verse:"我必使人，就是我的民以色列，行在你上面。他们必得你为业；你也不再使他们丧子。"},
{volumn:        26,chapter:        36,versenumber:        13,verse:"主耶和华如此说：因为人对你说：‘你是吞吃人的，又使国民丧子’，"},
{volumn:        26,chapter:        36,versenumber:        14,verse:"所以主耶和华说：你必不再吞吃人，也不再使国民丧子。"},
{volumn:        26,chapter:        36,versenumber:        15,verse:"我使你不再听见各国的羞辱，不再受万民的辱骂，也不再使国民绊跌。这是主耶和华说的。”"},
{volumn:        26,chapter:        36,versenumber:        16,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        36,versenumber:        17,verse:"“人子啊，以色列家住在本地的时候，在行动作为上玷污那地。他们的行为在我面前，好像正在经期的妇人那样污秽。"},
{volumn:        26,chapter:        36,versenumber:        18,verse:"所以我因他们在那地上流人的血，又因他们以偶像玷污那地，就把我的忿怒倾在他们身上。"},
{volumn:        26,chapter:        36,versenumber:        19,verse:"我将他们分散在列国，四散在列邦，按他们的行动作为惩罚他们。"},
{volumn:        26,chapter:        36,versenumber:        20,verse:"他们到了所去的列国，就使我的圣名被亵渎；因为人谈论他们说，这是耶和华的民，是从耶和华的地出来的。"},
{volumn:        26,chapter:        36,versenumber:        21,verse:"我却顾惜我的圣名，就是以色列家在所到的列国中所亵渎的。"},
{volumn:        26,chapter:        36,versenumber:        22,verse:"“所以，你要对以色列家说，主耶和华如此说：以色列家啊，我行这事不是为你们，乃是为我的圣名，就是在你们到的列国中所亵渎的。"},
{volumn:        26,chapter:        36,versenumber:        23,verse:"我要使我的大名显为圣；这名在列国中已被亵渎，就是你们在他们中间所亵渎的。我在他们眼前，在你们身上显为圣的时候，他们就知道我是耶和华。这是主耶和华说的。"},
{volumn:        26,chapter:        36,versenumber:        24,verse:"我必从各国收取你们，从列邦聚集你们，引导你们归回本地。"},
{volumn:        26,chapter:        36,versenumber:        25,verse:"我必用清水洒在你们身上，你们就洁净了。我要洁净你们，使你们脱离一切的污秽，弃掉一切的偶像。"},
{volumn:        26,chapter:        36,versenumber:        26,verse:"我也要赐给你们一个新心，将新灵放在你们里面，又从你们的肉体中除掉石心，赐给你们肉心。"},
{volumn:        26,chapter:        36,versenumber:        27,verse:"我必将我的灵放在你们里面，使你们顺从我的律例，谨守遵行我的典章。"},
{volumn:        26,chapter:        36,versenumber:        28,verse:"你们必住在我所赐给你们列祖之地。你们要作我的子民，我要作你们的　神。"},
{volumn:        26,chapter:        36,versenumber:        29,verse:"我必救你们脱离一切的污秽，也必命五谷丰登，不使你们遭遇饥荒。"},
{volumn:        26,chapter:        36,versenumber:        30,verse:"我必使树木多结果子，田地多出土产，好叫你们不再因饥荒受外邦人的讥诮。"},
{volumn:        26,chapter:        36,versenumber:        31,verse:"那时，你们必追想你们的恶行和你们不善的作为，就因你们的罪孽和可憎的事厌恶自己。"},
{volumn:        26,chapter:        36,versenumber:        32,verse:"主耶和华说：你们要知道，我这样行不是为你们。以色列家啊，当为自己的行为抱愧蒙羞。"},
{volumn:        26,chapter:        36,versenumber:        33,verse:"“主耶和华如此说：我洁净你们，使你们脱离一切罪孽的日子，必使城邑有人居住，荒场再被建造。"},
{volumn:        26,chapter:        36,versenumber:        34,verse:"过路的人虽看为荒废之地，现今这荒废之地仍得耕种。"},
{volumn:        26,chapter:        36,versenumber:        35,verse:"他们必说：‘这先前为荒废之地，现在成如伊甸园；这荒废凄凉、毁坏的城邑现在坚固有人居住。’"},
{volumn:        26,chapter:        36,versenumber:        36,verse:"那时，在你们四围其余的外邦人必知道我耶和华修造那毁坏之处，培植那荒废之地。我耶和华说过，也必成就。"},
{volumn:        26,chapter:        36,versenumber:        37,verse:"“主耶和华如此说：我要加增以色列家的人数，多如羊群。他们必为这事向我求问，我要给他们成就。"},
{volumn:        26,chapter:        36,versenumber:        38,verse:"耶路撒冷在守节作祭物所献的羊群怎样多，照样，荒凉的城邑必被人群充满。他们就知道我是耶和华。”"},
{volumn:        26,chapter:        37,versenumber:         1,verse:"耶和华的灵（原文是手）降在我身上。耶和华藉他的灵带我出去，将我放在平原中；这平原遍满骸骨。"},
{volumn:        26,chapter:        37,versenumber:         2,verse:"他使我从骸骨的四围经过，谁知在平原的骸骨甚多，而且极其枯干。"},
{volumn:        26,chapter:        37,versenumber:         3,verse:"他对我说：“人子啊，这些骸骨能复活吗？”我说：“主耶和华啊，你是知道的。”"},
{volumn:        26,chapter:        37,versenumber:         4,verse:"他又对我说：“你向这些骸骨发预言说：枯干的骸骨啊，要听耶和华的话。"},
{volumn:        26,chapter:        37,versenumber:         5,verse:"主耶和华对这些骸骨如此说：‘我必使气息进入你们里面，你们就要活了。"},
{volumn:        26,chapter:        37,versenumber:         6,verse:"我必给你们加上筋，使你们长肉，又将皮遮蔽你们，使气息进入你们里面，你们就要活了；你们便知道我是耶和华。’”"},
{volumn:        26,chapter:        37,versenumber:         7,verse:"于是，我遵命说预言。正说预言的时候，不料，有响声，有地震；骨与骨互相联络。"},
{volumn:        26,chapter:        37,versenumber:         8,verse:"我观看，见骸骨上有筋，也长了肉，又有皮遮蔽其上，只是还没有气息。"},
{volumn:        26,chapter:        37,versenumber:         9,verse:"主对我说：“人子啊，你要发预言，向风发预言，说主耶和华如此说：气息啊，要从四方（原文是风）而来，吹在这些被杀的人身上，使他们活了。”"},
{volumn:        26,chapter:        37,versenumber:        10,verse:"于是我遵命说预言，气息就进入骸骨，骸骨便活了，并且站起来，成为极大的军队。"},
{volumn:        26,chapter:        37,versenumber:        11,verse:"主对我说：“人子啊，这些骸骨就是以色列全家。他们说：‘我们的骨头枯干了，我们的指望失去了，我们灭绝净尽了。’"},
{volumn:        26,chapter:        37,versenumber:        12,verse:"所以你要发预言对他们说，主耶和华如此说：‘我的民哪，我必开你们的坟墓，使你们从坟墓中出来，领你们进入以色列地。"},
{volumn:        26,chapter:        37,versenumber:        13,verse:"我的民哪，我开你们的坟墓，使你们从坟墓中出来，你们就知道我是耶和华。"},
{volumn:        26,chapter:        37,versenumber:        14,verse:"我必将我的灵放在你们里面，你们就要活了。我将你们安置在本地，你们就知道我耶和华如此说，也如此成就了。这是耶和华说的。’”"},
{volumn:        26,chapter:        37,versenumber:        15,verse:"耶和华的话又临到我说："},
{volumn:        26,chapter:        37,versenumber:        16,verse:"“人子啊，你要取一根木杖，在其上写‘为犹大和他的同伴以色列人’；又取一根木杖，在其上写‘为约瑟，就是为以法莲，又为他的同伴以色列全家’。"},
{volumn:        26,chapter:        37,versenumber:        17,verse:"你要使这两根木杖接连为一，在你手中成为一根。"},
{volumn:        26,chapter:        37,versenumber:        18,verse:"你本国的子民问你说：‘这是什么意思？你不指示我们吗？’"},
{volumn:        26,chapter:        37,versenumber:        19,verse:"你就对他们说：‘主耶和华如此说：我要将约瑟和他同伴以色列支派的杖，就是那在以法莲手中的，与犹大的杖一同接连为一，在我手中成为一根。’"},
{volumn:        26,chapter:        37,versenumber:        20,verse:"你所写的那两根杖要在他们眼前拿在手中，"},
{volumn:        26,chapter:        37,versenumber:        21,verse:"要对他们说，主耶和华如此说：我要将以色列人从他们所到的各国收取，又从四围聚集他们，引导他们归回本地。"},
{volumn:        26,chapter:        37,versenumber:        22,verse:"我要使他们在那地，在以色列山上成为一国，有一王作他们众民的王。他们不再为二国，决不再分为二国；"},
{volumn:        26,chapter:        37,versenumber:        23,verse:"也不再因偶像和可憎的物，并一切的罪过玷污自己。我却要救他们出离一切的住处，就是他们犯罪的地方；我要洁净他们，如此，他们要作我的子民，我要作他们的　神。"},
{volumn:        26,chapter:        37,versenumber:        24,verse:"“我的仆人大卫必作他们的王；众民必归一个牧人。他们必顺从我的典章，谨守遵行我的律例。"},
{volumn:        26,chapter:        37,versenumber:        25,verse:"他们必住在我赐给我仆人雅各的地上，就是你们列祖所住之地。他们和他们的子孙，并子孙的子孙，都永远住在那里。我的仆人大卫必作他们的王，直到永远。"},
{volumn:        26,chapter:        37,versenumber:        26,verse:"并且我要与他们立平安的约，作为永约。我也要将他们安置在本地，使他们的人数增多，又在他们中间设立我的圣所，直到永远。"},
{volumn:        26,chapter:        37,versenumber:        27,verse:"我的居所必在他们中间；我要作他们的　神，他们要作我的子民。"},
{volumn:        26,chapter:        37,versenumber:        28,verse:"我的圣所在以色列人中间直到永远，外邦人就必知道我是叫以色列成为圣的耶和华。”"},
{volumn:        26,chapter:        38,versenumber:         1,verse:"耶和华的话临到我说："},
{volumn:        26,chapter:        38,versenumber:         2,verse:"“人子啊，你要面向玛各地的歌革，就是罗施、米设、土巴的王发预言攻击他，"},
{volumn:        26,chapter:        38,versenumber:         3,verse:"说主耶和华如此说：罗施、米设、土巴的王歌革啊，我与你为敌。"},
{volumn:        26,chapter:        38,versenumber:         4,verse:"我必用钩子钩住你的腮颊，调转你，将你和你的军兵、马匹、马兵带出来，都披挂整齐，成了大队，有大小盾牌，各拿刀剑。"},
{volumn:        26,chapter:        38,versenumber:         5,verse:"波斯人、古实人，和弗人（又作利比亚人），各拿盾牌，头上戴盔；"},
{volumn:        26,chapter:        38,versenumber:         6,verse:"歌篾人和他的军队，北方极处的陀迦玛族和他的军队，这许多国的民都同着你。"},
{volumn:        26,chapter:        38,versenumber:         7,verse:"“那聚集到你这里的各队都当准备；你自己也要准备，作他们的大帅。"},
{volumn:        26,chapter:        38,versenumber:         8,verse:"过了多日，你必被差派。到末后之年，你必来到脱离刀剑从列国收回之地，到以色列常久荒凉的山上；但那从列国中招聚出来的必在其上安然居住。"},
{volumn:        26,chapter:        38,versenumber:         9,verse:"你和你的军队，并同着你许多国的民，必如暴风上来，如密云遮盖地面。”"},
{volumn:        26,chapter:        38,versenumber:        10,verse:"主耶和华如此说：“到那时，你心必起意念，图谋恶计，"},
{volumn:        26,chapter:        38,versenumber:        11,verse:"说：‘我要上那无城墙的乡村，我要到那安静的民那里，他们都没有城墙，无门、无闩，安然居住。"},
{volumn:        26,chapter:        38,versenumber:        12,verse:"我去要抢财为掳物，夺货为掠物，反手攻击那从前荒凉、现在有人居住之地，又攻击那住世界中间、从列国招聚、得了牲畜财货的民。’"},
{volumn:        26,chapter:        38,versenumber:        13,verse:"示巴人、底但人、他施的客商，和其间的少壮狮子都必问你说：‘你来要抢财为掳物吗？你聚集军队要夺货为掠物吗？要夺取金银，掳去牲畜、财货吗？要抢夺许多财宝为掳物吗？’"},
{volumn:        26,chapter:        38,versenumber:        14,verse:"“人子啊，你要因此发预言，对歌革说，主耶和华如此说：到我民以色列安然居住之日，你岂不知道吗？"},
{volumn:        26,chapter:        38,versenumber:        15,verse:"你必从本地，从北方的极处率领许多国的民来，都骑着马，乃一大队极多的军兵。"},
{volumn:        26,chapter:        38,versenumber:        16,verse:"歌革啊，你必上来攻击我的民以色列，如密云遮盖地面。末后的日子，我必带你来攻击我的地，到我在外邦人眼前，在你身上显为圣的时候，好叫他们认识我。"},
{volumn:        26,chapter:        38,versenumber:        17,verse:"主耶和华如此说：我在古时藉我的仆人以色列的先知所说的，就是你吗？当日他们多年预言我必带你来攻击以色列人。”"},
{volumn:        26,chapter:        38,versenumber:        18,verse:"主耶和华说：“歌革上来攻击以色列地的时候，我的怒气要从鼻孔里发出。"},
{volumn:        26,chapter:        38,versenumber:        19,verse:"我发愤恨和烈怒如火说：那日在以色列地必有大震动，"},
{volumn:        26,chapter:        38,versenumber:        20,verse:"甚至海中的鱼、天空的鸟、田野的兽，并地上的一切昆虫，和其上的众人，因见我的面就都震动；山岭必崩裂，陡岩必塌陷，墙垣都必坍倒。”"},
{volumn:        26,chapter:        38,versenumber:        21,verse:"主耶和华说：“我必命我的诸山发刀剑来攻击歌革；人都要用刀剑杀害弟兄。"},
{volumn:        26,chapter:        38,versenumber:        22,verse:"我必用瘟疫和流血的事刑罚他。我也必将暴雨、大雹与火，并硫磺降与他和他的军队，并他所率领的众民。"},
{volumn:        26,chapter:        38,versenumber:        23,verse:"我必显为大，显为圣，在多国人的眼前显现；他们就知道我是耶和华。”"},
{volumn:        26,chapter:        39,versenumber:         1,verse:"“人子啊，你要向歌革发预言攻击他，说主耶和华如此说：罗施、米设、土巴的王歌革啊，我与你为敌。"},
{volumn:        26,chapter:        39,versenumber:         2,verse:"我必调转你，领你前往，使你从北方的极处上来，带你到以色列的山上。"},
{volumn:        26,chapter:        39,versenumber:         3,verse:"我必从你左手打落你的弓，从你右手打掉你的箭。"},
{volumn:        26,chapter:        39,versenumber:         4,verse:"你和你的军队，并同着你的列国人，都必倒在以色列的山上。我必将你给各类的鸷鸟和田野的走兽作食物。"},
{volumn:        26,chapter:        39,versenumber:         5,verse:"你必倒在田野，因为我曾说过。这是主耶和华说的。"},
{volumn:        26,chapter:        39,versenumber:         6,verse:"我要降火在玛各和海岛安然居住的人身上，他们就知道我是耶和华。"},
{volumn:        26,chapter:        39,versenumber:         7,verse:"“我要在我民以色列中显出我的圣名，也不容我的圣名再被亵渎，列国人就知道我是耶和华以色列中的圣者。"},
{volumn:        26,chapter:        39,versenumber:         8,verse:"主耶和华说：这日事情临近，也必成就，乃是我所说的日子。"},
{volumn:        26,chapter:        39,versenumber:         9,verse:"“住以色列城邑的人必出去和捡器械，就是大小盾牌、弓箭、梃杖、枪矛，都当柴烧火，直烧七年，"},
{volumn:        26,chapter:        39,versenumber:        10,verse:"甚至他们不必从田野捡柴，也不必从树林伐木；因为他们要用器械烧火，并且抢夺那抢夺他们的人，掳掠那掳掠他们的人。这是主耶和华说的。”"},
{volumn:        26,chapter:        39,versenumber:        11,verse:"“当那日，我必将以色列地的谷，就是海东人所经过的谷，赐给歌革为坟地，使经过的人到此停步。在那里人必葬埋歌革和他的群众，就称那地为哈们·歌革谷。"},
{volumn:        26,chapter:        39,versenumber:        12,verse:"以色列家的人必用七个月葬埋他们，为要洁净全地。"},
{volumn:        26,chapter:        39,versenumber:        13,verse:"全地的居民都必葬埋他们。当我得荣耀的日子，这事必叫他们得名声。这是主耶和华说的。"},
{volumn:        26,chapter:        39,versenumber:        14,verse:"他们必分派人时常巡查遍地，与过路的人一同葬埋那剩在地面上的尸首，好洁净全地。过了七个月，他们还要巡查。"},
{volumn:        26,chapter:        39,versenumber:        15,verse:"巡查遍地的人要经过全地，见有人的骸骨，就在旁边立一标记，等葬埋的人来将骸骨葬在哈们·歌革谷。"},
{volumn:        26,chapter:        39,versenumber:        16,verse:"他们必这样洁净那地，并有一城名叫哈摩那。"},
{volumn:        26,chapter:        39,versenumber:        17,verse:"“人子啊，主耶和华如此说：你要对各类的飞鸟和田野的走兽说：你们聚集来吧，要从四方聚到我为你们献祭之地，就是在以色列山上献大祭之地，好叫你们吃肉、喝血。"},
{volumn:        26,chapter:        39,versenumber:        18,verse:"你们必吃勇士的肉，喝地上首领的血，就如吃公绵羊、羊羔、公山羊、公牛，都是巴珊的肥畜。"},
{volumn:        26,chapter:        39,versenumber:        19,verse:"你们吃我为你们所献的祭，必吃饱了脂油，喝醉了血。"},
{volumn:        26,chapter:        39,versenumber:        20,verse:"你们必在我席上饱吃马匹和坐车的人，并勇士和一切的战士。这是主耶和华说的。”"},
{volumn:        26,chapter:        39,versenumber:        21,verse:"“我必显我的荣耀在列国中；万民就必看见我所行的审判与我在他们身上所加的手。"},
{volumn:        26,chapter:        39,versenumber:        22,verse:"这样，从那日以后，以色列家必知道我是耶和华他们的　神。"},
{volumn:        26,chapter:        39,versenumber:        23,verse:"列国人也必知道以色列家被掳掠是因他们的罪孽。他们得罪我，我就掩面不顾，将他们交在敌人手中，他们便都倒在刀下。"},
{volumn:        26,chapter:        39,versenumber:        24,verse:"我是照他们的污秽和罪过待他们，并且我掩面不顾他们。”"},
{volumn:        26,chapter:        39,versenumber:        25,verse:"主耶和华如此说：“我要使雅各被掳的人归回，要怜悯以色列全家，又为我的圣名发热心。"},
{volumn:        26,chapter:        39,versenumber:        26,verse:"他们在本地安然居住，无人惊吓，是我将他们从万民中领回，从仇敌之地召来。我在许多国的民眼前，在他们身上显为圣的时候，他们要担当自己的羞辱和干犯我的一切罪。"},
{volumn:        26,chapter:        39,versenumber:        27,verse:""},
{volumn:        26,chapter:        39,versenumber:        28,verse:"因我使他们被掳到外邦人中，后又聚集他们归回本地，他们就知道我是耶和华他们的　神；我必不再留他们一人在外邦。"},
{volumn:        26,chapter:        39,versenumber:        29,verse:"我也不再掩面不顾他们，因我已将我的灵浇灌以色列家。这是主耶和华说的。”"},
{volumn:        26,chapter:        40,versenumber:         1,verse:"我们被掳掠第二十五年，耶路撒冷城攻破后十四年，正在年初，月之初十日，耶和华的灵（原文是手）降在我身上，他把我带到以色列地。"},
{volumn:        26,chapter:        40,versenumber:         2,verse:"在　神的异象中带我到以色列地，安置在至高的山上；在山上的南边有仿佛一座城建立。"},
{volumn:        26,chapter:        40,versenumber:         3,verse:"他带我到那里，见有一人，颜色（原文是形状）如铜，手拿麻绳和量度的竿，站在门口。"},
{volumn:        26,chapter:        40,versenumber:         4,verse:"那人对我说：“人子啊，凡我所指示你的，你都要用眼看，用耳听，并要放在心上。我带你到这里来，特为要指示你；凡你所见的，你都要告诉以色列家。”"},
{volumn:        26,chapter:        40,versenumber:         5,verse:"我见殿四围有墙。那人手拿量度的竿，长六肘，每肘是一肘零一掌。他用竿量墙，厚一竿，高一竿。"},
{volumn:        26,chapter:        40,versenumber:         6,verse:"他到了朝东的门，就上门的台阶，量门的这槛，宽一竿；又量门的那槛，宽一竿。"},
{volumn:        26,chapter:        40,versenumber:         7,verse:"又有卫房，每房长一竿，宽一竿，相隔五肘。门槛，就是挨着向殿的廊门槛，宽一竿。"},
{volumn:        26,chapter:        40,versenumber:         8,verse:"他又量向殿门的廊子，宽一竿。"},
{volumn:        26,chapter:        40,versenumber:         9,verse:"又量门廊，宽八肘，墙柱厚二肘；那门的廊子向着殿。"},
{volumn:        26,chapter:        40,versenumber:        10,verse:"东门洞有卫房：这旁三间，那旁三间，都是一样的尺寸；这边的柱子和那边的柱子，也是一样的尺寸。"},
{volumn:        26,chapter:        40,versenumber:        11,verse:"他量门口，宽十肘，长十三肘。"},
{volumn:        26,chapter:        40,versenumber:        12,verse:"卫房前展出的境界：这边一肘，那边一肘；卫房这边六肘，那边六肘。"},
{volumn:        26,chapter:        40,versenumber:        13,verse:"又量门洞，从这卫房顶的后檐到那卫房顶的后檐，宽二十五肘；卫房门与门相对。"},
{volumn:        26,chapter:        40,versenumber:        14,verse:"又量（原文是造）廊子六十肘（七十士译本是二十肘），墙柱外是院子，有廊为界，在门洞两边。"},
{volumn:        26,chapter:        40,versenumber:        15,verse:"从大门口到内廊前，共五十肘。"},
{volumn:        26,chapter:        40,versenumber:        16,verse:"卫房和门洞两旁柱间并廊子，都有严紧的窗棂；里边都有窗棂，柱上有雕刻的棕树。"},
{volumn:        26,chapter:        40,versenumber:        17,verse:"他带我到外院，见院的四围有铺石地；铺石地上有屋子三十间。"},
{volumn:        26,chapter:        40,versenumber:        18,verse:"铺石地，就是矮铺石地在各门洞两旁，以门洞的长短为度。"},
{volumn:        26,chapter:        40,versenumber:        19,verse:"他从下门量到内院外，共宽一百肘，东面北面都是如此。"},
{volumn:        26,chapter:        40,versenumber:        20,verse:"他量外院朝北的门，长宽若干。"},
{volumn:        26,chapter:        40,versenumber:        21,verse:"门洞的卫房，这旁三间，那旁三间。门洞的柱子和廊子，与第一门的尺寸一样。门洞长五十肘，宽二十五肘。"},
{volumn:        26,chapter:        40,versenumber:        22,verse:"其窗棂和廊子，并雕刻的棕树，与朝东的门尺寸一样。登七层台阶上到这门，前面有廊子。"},
{volumn:        26,chapter:        40,versenumber:        23,verse:"内院有门与这门相对，北面东面都是如此。他从这门量到那门，共一百肘。"},
{volumn:        26,chapter:        40,versenumber:        24,verse:"他带我往南去，见朝南有门，又照先前的尺寸量门洞的柱子和廊子。"},
{volumn:        26,chapter:        40,versenumber:        25,verse:"门洞两旁与廊子的周围都有窗棂，和先量的窗棂一样。门洞长五十肘，宽二十五肘。"},
{volumn:        26,chapter:        40,versenumber:        26,verse:"登七层台阶上到这门，前面有廊子；柱上有雕刻的棕树，这边一棵，那边一棵。"},
{volumn:        26,chapter:        40,versenumber:        27,verse:"内院朝南有门。从这门量到朝南的那门，共一百肘。"},
{volumn:        26,chapter:        40,versenumber:        28,verse:"他带我从南门到内院，就照先前的尺寸量南门。"},
{volumn:        26,chapter:        40,versenumber:        29,verse:"卫房和柱子，并廊子都照先前的尺寸。门洞两旁与廊子的周围都有窗棂。门洞长五十肘，宽二十五肘。"},
{volumn:        26,chapter:        40,versenumber:        30,verse:"周围有廊子，长二十五肘，宽五肘。"},
{volumn:        26,chapter:        40,versenumber:        31,verse:"廊子朝着外院，柱上有雕刻的棕树。登八层台阶上到这门。"},
{volumn:        26,chapter:        40,versenumber:        32,verse:"他带我到内院的东面，就照先前的尺寸量东门。"},
{volumn:        26,chapter:        40,versenumber:        33,verse:"卫房和柱子，并廊子都照先前的尺寸。门洞两旁与廊子的周围都有窗棂。门洞长五十肘，宽二十五肘。"},
{volumn:        26,chapter:        40,versenumber:        34,verse:"廊子朝着外院。门洞两旁的柱子都有雕刻的棕树。登八层台阶上到这门。"},
{volumn:        26,chapter:        40,versenumber:        35,verse:"他带我到北门，就照先前的尺寸量那门，"},
{volumn:        26,chapter:        40,versenumber:        36,verse:"就是量卫房和柱子，并廊子。门洞周围都有窗棂；门洞长五十肘，宽二十五肘。"},
{volumn:        26,chapter:        40,versenumber:        37,verse:"廊柱朝着外院。门洞两旁的柱子都有雕刻的棕树。登八层台阶上到这门。"},
{volumn:        26,chapter:        40,versenumber:        38,verse:"门洞的柱旁有屋子和门；祭司（原文是他们）在那里洗燔祭牲。"},
{volumn:        26,chapter:        40,versenumber:        39,verse:"在门廊内，这边有两张桌子，那边有两张桌子，在其上可以宰杀燔祭牲、赎罪祭牲，和赎愆祭牲。"},
{volumn:        26,chapter:        40,versenumber:        40,verse:"上到朝北的门口，这边有两张桌子，门廊那边也有两张桌子。"},
{volumn:        26,chapter:        40,versenumber:        41,verse:"门这边有四张桌子，那边有四张桌子，共八张；在其上祭司宰杀牺牲。"},
{volumn:        26,chapter:        40,versenumber:        42,verse:"为燔祭牲有四张桌子，是凿过的石头做成的，长一肘半，宽一肘半，高一肘。祭司将宰杀燔祭牲和平安祭牲所用的器皿放在其上。"},
{volumn:        26,chapter:        40,versenumber:        43,verse:"有钩子，宽一掌，钉在廊内的四围。桌子上有牺牲的肉。"},
{volumn:        26,chapter:        40,versenumber:        44,verse:"在北门旁，内院里有屋子，为歌唱的人而设。这屋子朝南；在南（南：原文是东）门旁，又有一间朝北。"},
{volumn:        26,chapter:        40,versenumber:        45,verse:"他对我说：“这朝南的屋子是为看守殿宇的祭司；"},
{volumn:        26,chapter:        40,versenumber:        46,verse:"那朝北的屋子是为看守祭坛的祭司。这些祭司是利未人中撒督的子孙，近前来侍奉耶和华的。”"},
{volumn:        26,chapter:        40,versenumber:        47,verse:"他又量内院，长一百肘，宽一百肘，是见方的。祭坛在殿前。"},
{volumn:        26,chapter:        40,versenumber:        48,verse:"于是他带我到殿前的廊子，量廊子的墙柱。这面厚五肘，那面厚五肘。门两旁，这边三肘，那边三肘。"},
{volumn:        26,chapter:        40,versenumber:        49,verse:"廊子长二十肘，宽十一肘。上廊子有台阶。靠近墙柱又有柱子，这边一根，那边一根。"},
{volumn:        26,chapter:        41,versenumber:         1,verse:"他带我到殿那里量墙柱：这面厚六肘，那面厚六肘，宽窄与会幕相同。"},
{volumn:        26,chapter:        41,versenumber:         2,verse:"门口宽十肘。门两旁，这边五肘，那边五肘。他量殿长四十肘，宽二十肘。"},
{volumn:        26,chapter:        41,versenumber:         3,verse:"他到内殿量墙柱，各厚二肘。门口宽六肘，门两旁各宽七肘。"},
{volumn:        26,chapter:        41,versenumber:         4,verse:"他量内殿，长二十肘，宽二十肘。他对我说：“这是至圣所。”"},
{volumn:        26,chapter:        41,versenumber:         5,verse:"他又量殿墙，厚六肘；围着殿有旁屋，各宽四肘。"},
{volumn:        26,chapter:        41,versenumber:         6,verse:"旁屋有三层，层叠而上，每层排列三十间。旁屋的梁木搁在殿墙坎上，免得插入殿墙。"},
{volumn:        26,chapter:        41,versenumber:         7,verse:"这围殿的旁屋越高越宽；因旁屋围殿悬叠而上，所以越上越宽，从下一层，由中一层，到上一层。"},
{volumn:        26,chapter:        41,versenumber:         8,verse:"我又见围着殿有高月台。旁屋的根基，高足一竿，就是六大肘。"},
{volumn:        26,chapter:        41,versenumber:         9,verse:"旁屋的外墙厚五肘。旁屋之外还有余地。"},
{volumn:        26,chapter:        41,versenumber:        10,verse:"在旁屋与对面的房屋中间有空地，宽二十肘。"},
{volumn:        26,chapter:        41,versenumber:        11,verse:"旁屋的门都向余地：一门向北，一门向南。周围的余地宽五肘。"},
{volumn:        26,chapter:        41,versenumber:        12,verse:"在西面空地之后有房子，宽七十肘，长九十肘，墙四围厚五肘。"},
{volumn:        26,chapter:        41,versenumber:        13,verse:"这样，他量殿，长一百肘，又量空地和那房子并墙，共长一百肘。"},
{volumn:        26,chapter:        41,versenumber:        14,verse:"殿的前面和两旁的空地，宽一百肘。"},
{volumn:        26,chapter:        41,versenumber:        15,verse:"他量空地后面的那房子，并两旁的楼廊，共长一百肘。"},
{volumn:        26,chapter:        41,versenumber:        16,verse:"内殿、院廊、门槛、严紧的窗棂，并对着门槛的三层楼廊，从地到窗棂（窗棂都有蔽子），"},
{volumn:        26,chapter:        41,versenumber:        17,verse:"直到门以上，就是到内殿和外殿内外四围墙壁，都按尺寸用木板遮蔽。"},
{volumn:        26,chapter:        41,versenumber:        18,verse:"墙上雕刻基路伯和棕树。每二基路伯中间有一棵棕树，每基路伯有二脸。"},
{volumn:        26,chapter:        41,versenumber:        19,verse:"这边有人脸向着棕树，那边有狮子脸向着棕树，殿内周围都是如此。"},
{volumn:        26,chapter:        41,versenumber:        20,verse:"从地至门以上，都有基路伯和棕树。殿墙就是这样。"},
{volumn:        26,chapter:        41,versenumber:        21,verse:"殿的门柱是方的。至圣所的前面，形状和殿的形状一样。"},
{volumn:        26,chapter:        41,versenumber:        22,verse:"坛是木头做的，高三肘，长二肘。坛角和坛面，并四旁，都是木头做的。他对我说：“这是耶和华面前的桌子。”"},
{volumn:        26,chapter:        41,versenumber:        23,verse:"殿和至圣所的门各有两扇。"},
{volumn:        26,chapter:        41,versenumber:        24,verse:"每扇分两扇，这两扇是折叠的。这边门分两扇，那边门也分两扇。"},
{volumn:        26,chapter:        41,versenumber:        25,verse:"殿的门扇上雕刻基路伯和棕树，与刻在墙上的一般。在外头廊前有木槛。"},
{volumn:        26,chapter:        41,versenumber:        26,verse:"廊这边那边都有严紧的窗棂和棕树；殿的旁屋和槛就是这样。"},
{volumn:        26,chapter:        42,versenumber:         1,verse:"他带我出来向北，到外院，又带我进入圣屋；这圣屋一排顺着空地，一排与北边铺石地之屋相对。"},
{volumn:        26,chapter:        42,versenumber:         2,verse:"这圣屋长一百肘，宽五十肘，有向北的门。"},
{volumn:        26,chapter:        42,versenumber:         3,verse:"对着内院那二十肘宽之空地，又对着外院的铺石地，在第三层楼上有楼廊对着楼廊。"},
{volumn:        26,chapter:        42,versenumber:         4,verse:"在圣屋前有一条夹道，宽十肘，长一百肘。房门都向北。"},
{volumn:        26,chapter:        42,versenumber:         5,verse:"圣屋因为楼廊占去些地方，所以上层比中下两层窄些。"},
{volumn:        26,chapter:        42,versenumber:         6,verse:"圣屋有三层，却无柱子，不像外院的屋子有柱子；所以上层比中下两层更窄。"},
{volumn:        26,chapter:        42,versenumber:         7,verse:"圣屋外，东边有墙，靠着外院，长五十肘。"},
{volumn:        26,chapter:        42,versenumber:         8,verse:"靠着外院的圣屋长五十肘。殿北面的圣屋长一百肘。"},
{volumn:        26,chapter:        42,versenumber:         9,verse:"在圣屋以下，东头有进入之处，就是从外院进入之处。"},
{volumn:        26,chapter:        42,versenumber:        10,verse:"向南（原文是东）在内院墙里有圣屋，一排与铺石地之屋相对，一排顺着空地。"},
{volumn:        26,chapter:        42,versenumber:        11,verse:"这圣屋前的夹道与北边圣屋的夹道长宽一样；出入之处与北屋门的样式相同。"},
{volumn:        26,chapter:        42,versenumber:        12,verse:"正在墙前、夹道的东头，有门可以进入，与向南圣屋的门一样。"},
{volumn:        26,chapter:        42,versenumber:        13,verse:"他对我说：“顺着空地的南屋北屋，都是圣屋；亲近耶和华的祭司当在那里吃至圣的物，也当在那里放至圣的物，就是素祭、赎罪祭，和赎愆祭，因此处为圣。"},
{volumn:        26,chapter:        42,versenumber:        14,verse:"祭司进去出了圣所的时候，不可直到外院，但要在圣屋放下他们供职的衣服，因为是圣衣；要穿上别的衣服才可以到属民的外院。”"},
{volumn:        26,chapter:        42,versenumber:        15,verse:"他量完了内殿，就带我出朝东的门，量院的四围。"},
{volumn:        26,chapter:        42,versenumber:        16,verse:"他用量度的竿量四围，量东面五百肘（原文是竿；本章下同），"},
{volumn:        26,chapter:        42,versenumber:        17,verse:"用竿量北面五百肘，"},
{volumn:        26,chapter:        42,versenumber:        18,verse:"用竿量南面五百肘，"},
{volumn:        26,chapter:        42,versenumber:        19,verse:"又转到西面，用竿量五百肘。"},
{volumn:        26,chapter:        42,versenumber:        20,verse:"他量四面，四围有墙，长五百肘，宽五百肘，为要分别圣地与俗地。"},
{volumn:        26,chapter:        43,versenumber:         1,verse:"以后，他带我到一座门，就是朝东的门。"},
{volumn:        26,chapter:        43,versenumber:         2,verse:"以色列　神的荣光从东而来。他的声音如同多水的声音；地就因他的荣耀发光。"},
{volumn:        26,chapter:        43,versenumber:         3,verse:"其状如从前他来灭城的时候我所见的异象，那异象如我在迦巴鲁河边所见的异象，我就俯伏在地。"},
{volumn:        26,chapter:        43,versenumber:         4,verse:"耶和华的荣光从朝东的门照入殿中。"},
{volumn:        26,chapter:        43,versenumber:         5,verse:"灵将我举起，带入内院，不料，耶和华的荣光充满了殿。"},
{volumn:        26,chapter:        43,versenumber:         6,verse:"我听见有一位从殿中对我说话。有一人站在我旁边。"},
{volumn:        26,chapter:        43,versenumber:         7,verse:"他对我说：“人子啊，这是我宝座之地，是我脚掌所踏之地。我要在这里住，在以色列人中直到永远。以色列家和他们的君王必不再玷污我的圣名，就是行邪淫、在锡安的高处葬埋他们君王的尸首，"},
{volumn:        26,chapter:        43,versenumber:         8,verse:"使他们的门槛挨近我的门槛，他们的门框挨近我的门框；他们与我中间仅隔一墙，并且行可憎的事，玷污了我的圣名，所以我发怒灭绝他们。"},
{volumn:        26,chapter:        43,versenumber:         9,verse:"现在他们当从我面前远除邪淫和他们君王的尸首，我就住在他们中间直到永远。"},
{volumn:        26,chapter:        43,versenumber:        10,verse:"“人子啊，你要将这殿指示以色列家，使他们因自己的罪孽惭愧，也要他们量殿的尺寸。"},
{volumn:        26,chapter:        43,versenumber:        11,verse:"他们若因自己所行的一切事惭愧，你就将殿的规模、样式、出入之处，和一切形状、典章、礼仪、法则指示他们，在他们眼前写上，使他们遵照殿的一切规模典章去做。"},
{volumn:        26,chapter:        43,versenumber:        12,verse:"殿的法则乃是如此：殿在山顶上，四围的全界要称为至圣。这就是殿的法则。”"},
{volumn:        26,chapter:        43,versenumber:        13,verse:"以下量祭坛，是以肘为度（这肘是一肘零一掌）。底座高一肘，边宽一肘，四围起边高一掌，这是坛的座。"},
{volumn:        26,chapter:        43,versenumber:        14,verse:"从底座到下层磴台，高二肘，边宽一肘。从小磴台到大磴台，高四肘，边宽一肘。"},
{volumn:        26,chapter:        43,versenumber:        15,verse:"坛上的供台，高四肘。供台的四拐角上都有角。"},
{volumn:        26,chapter:        43,versenumber:        16,verse:"供台长十二肘，宽十二肘，四面见方。"},
{volumn:        26,chapter:        43,versenumber:        17,verse:"磴台长十四肘，宽十四肘，四面见方。四围起边高半肘，底座四围的边宽一肘。台阶朝东。"},
{volumn:        26,chapter:        43,versenumber:        18,verse:"他对我说：“人子啊，主耶和华如此说：建造祭坛，为要在其上献燔祭洒血，造成的时候典章如下："},
{volumn:        26,chapter:        43,versenumber:        19,verse:"主耶和华说，你要将一只公牛犊作为赎罪祭，给祭司利未人撒督的后裔，就是那亲近我、侍奉我的。"},
{volumn:        26,chapter:        43,versenumber:        20,verse:"你要取些公牛的血，抹在坛的四角和磴台的四拐角，并四围所起的边上。你这样洁净坛，坛就洁净了。"},
{volumn:        26,chapter:        43,versenumber:        21,verse:"你又要将那作赎罪祭的公牛犊烧在殿外、圣地之外预定之处。"},
{volumn:        26,chapter:        43,versenumber:        22,verse:"次日，要将无残疾的公山羊献为赎罪祭；要洁净坛，像用公牛犊洁净的一样。"},
{volumn:        26,chapter:        43,versenumber:        23,verse:"洁净了坛，就要将一只无残疾的公牛犊和羊群中一只无残疾的公绵羊"},
{volumn:        26,chapter:        43,versenumber:        24,verse:"奉到耶和华前。祭司要撒盐在其上，献与耶和华为燔祭。"},
{volumn:        26,chapter:        43,versenumber:        25,verse:"七日内，每日要预备一只公山羊为赎罪祭，也要预备一只公牛犊和羊群中的一只公绵羊，都要没有残疾的。"},
{volumn:        26,chapter:        43,versenumber:        26,verse:"七日祭司洁净坛，坛就洁净了；要这样把坛分别为圣。"},
{volumn:        26,chapter:        43,versenumber:        27,verse:"满了七日，自八日以后，祭司要在坛上献你们的燔祭和平安祭；我必悦纳你们。这是主耶和华说的。”"},
{volumn:        26,chapter:        44,versenumber:         1,verse:"他又带我回到圣地朝东的外门；那门关闭了。"},
{volumn:        26,chapter:        44,versenumber:         2,verse:"耶和华对我说：“这门必须关闭，不可敞开，谁也不可由其中进入；因为耶和华以色列的　神已经由其中进入，所以必须关闭。"},
{volumn:        26,chapter:        44,versenumber:         3,verse:"至于王，他必按王的位分，坐在其内，在耶和华面前吃饼。他必由这门的廊而入，也必由此而出。”"},
{volumn:        26,chapter:        44,versenumber:         4,verse:"他又带我由北门来到殿前。我观看，见耶和华的荣光充满耶和华的殿，我就俯伏在地。"},
{volumn:        26,chapter:        44,versenumber:         5,verse:"耶和华对我说：“人子啊，我对你所说耶和华殿中的一切典章法则，你要放在心上，用眼看，用耳听，并要留心殿宇和圣地一切出入之处。"},
{volumn:        26,chapter:        44,versenumber:         6,verse:"你要对那悖逆的以色列家说，主耶和华如此说：以色列家啊，你们行一切可憎的事，当够了吧！"},
{volumn:        26,chapter:        44,versenumber:         7,verse:"你们把我的食物，就是脂油和血献上的时候，将身心未受割礼的外邦人领进我的圣地，玷污了我的殿；又背了我的约，在你们一切可憎的事上，加上这一层。"},
{volumn:        26,chapter:        44,versenumber:         8,verse:"你们也没有看守我的圣物，却派别人在圣地替你们看守我所吩咐你们的。"},
{volumn:        26,chapter:        44,versenumber:         9,verse:"“主耶和华如此说：以色列中的外邦人，就是身心未受割礼的，都不可入我的圣地。”"},
{volumn:        26,chapter:        44,versenumber:        10,verse:"“当以色列人走迷的时候，有利未人远离我，就是走迷离开我、随从他们的偶像，他们必担当自己的罪孽。"},
{volumn:        26,chapter:        44,versenumber:        11,verse:"然而他们必在我的圣地当仆役，照管殿门，在殿中供职；必为民宰杀燔祭牲和平安祭牲，必站在民前伺候他们。"},
{volumn:        26,chapter:        44,versenumber:        12,verse:"因为这些利未人曾在偶像前伺候这民，成了以色列家罪孽的绊脚石，所以我向他们起誓：他们必担当自己的罪孽。这是主耶和华说的。"},
{volumn:        26,chapter:        44,versenumber:        13,verse:"他们不可亲近我，给我供祭司的职分，也不可挨近我的一件圣物，就是至圣的物；他们却要担当自己的羞辱和所行可憎之事的报应。"},
{volumn:        26,chapter:        44,versenumber:        14,verse:"然而我要使他们看守殿宇，办理其中的一切事，并做其内一切当做之工。”"},
{volumn:        26,chapter:        44,versenumber:        15,verse:"“以色列人走迷离开我的时候，祭司利未人撒督的子孙仍看守我的圣所。他们必亲近我，侍奉我，并且侍立在我面前，将脂油与血献给我。这是主耶和华说的。"},
{volumn:        26,chapter:        44,versenumber:        16,verse:"他们必进入我的圣所，就近我的桌前侍奉我，守我所吩咐的。"},
{volumn:        26,chapter:        44,versenumber:        17,verse:"他们进内院门必穿细麻衣。在内院门和殿内供职的时候不可穿羊毛衣服。"},
{volumn:        26,chapter:        44,versenumber:        18,verse:"他们头上要戴细麻布裹头巾，腰穿细麻布裤子；不可穿使身体出汗的衣服。"},
{volumn:        26,chapter:        44,versenumber:        19,verse:"他们出到外院的民那里，当脱下供职的衣服，放在圣屋内，穿上别的衣服，免得因圣衣使民成圣。"},
{volumn:        26,chapter:        44,versenumber:        20,verse:"不可剃头，也不可容发绺长长，只可剪发。"},
{volumn:        26,chapter:        44,versenumber:        21,verse:"祭司进内院的时候都不可喝酒。"},
{volumn:        26,chapter:        44,versenumber:        22,verse:"不可娶寡妇和被休的妇人为妻，只可娶以色列后裔中的处女，或是祭司遗留的寡妇。"},
{volumn:        26,chapter:        44,versenumber:        23,verse:"他们要使我的民知道圣俗的分别，又使他们分辨洁净的和不洁净的。"},
{volumn:        26,chapter:        44,versenumber:        24,verse:"有争讼的事，他们应当站立判断，要按我的典章判断。在我一切的节期必守我的律法、条例，也必以我的安息日为圣日。"},
{volumn:        26,chapter:        44,versenumber:        25,verse:"他们不可挨近死尸沾染自己，只可为父亲、母亲、儿子、女儿、弟兄，和未嫁人的姐妹沾染自己。"},
{volumn:        26,chapter:        44,versenumber:        26,verse:"祭司洁净之后，必再计算七日。"},
{volumn:        26,chapter:        44,versenumber:        27,verse:"当他进内院，进圣所，在圣所中侍奉的日子，要为自己献赎罪祭。这是主耶和华说的。"},
{volumn:        26,chapter:        44,versenumber:        28,verse:"“祭司必有产业，我是他们的产业。不可在以色列中给他们基业；我是他们的基业。"},
{volumn:        26,chapter:        44,versenumber:        29,verse:"素祭、赎罪祭，和赎愆祭他们都可以吃，以色列中一切永献的物都要归他们。"},
{volumn:        26,chapter:        44,versenumber:        30,verse:"首先初熟之物和一切所献的供物都要归给祭司。你们也要用初熟的麦子磨面给祭司；这样，福气就必临到你们的家了。"},
{volumn:        26,chapter:        44,versenumber:        31,verse:"无论是鸟是兽，凡自死的，或是撕裂的，祭司都不可吃。”"},
{volumn:        26,chapter:        45,versenumber:         1,verse:"你们拈阄分地为业，要献上一份给耶和华为圣供地，长二万五千肘，宽一万肘。这份以内，四围都为圣地。"},
{volumn:        26,chapter:        45,versenumber:         2,verse:"其中有作为圣所之地，长五百肘，宽五百肘，四面见方。四围再有五十肘为郊野之地。"},
{volumn:        26,chapter:        45,versenumber:         3,verse:"要以肘为度量地，长二万五千肘，宽一万肘。其中有圣所，是至圣的。"},
{volumn:        26,chapter:        45,versenumber:         4,verse:"这是全地的一份圣地，要归与供圣所职事的祭司，就是亲近侍奉耶和华的，作为他们房屋之地与圣所之圣地。"},
{volumn:        26,chapter:        45,versenumber:         5,verse:"又有一份，长二万五千肘，宽一万肘，要归与在殿中供职的利未人，作为二十间房屋之业。"},
{volumn:        26,chapter:        45,versenumber:         6,verse:"也要分定属城的地业，宽五千肘，长二万五千肘，挨着那份圣供地，要归以色列全家。"},
{volumn:        26,chapter:        45,versenumber:         7,verse:"归王之地要在圣供地和属城之地的两旁，就是圣供地和属城之地的旁边，西至西头，东至东头，从西到东，其长与每支派的份一样。"},
{volumn:        26,chapter:        45,versenumber:         8,verse:"这地在以色列中必归王为业。我所立的王必不再欺压我的民，却要按支派将地分给以色列家。"},
{volumn:        26,chapter:        45,versenumber:         9,verse:"主耶和华如此说：“以色列的王啊，你们应当知足，要除掉强暴和抢夺的事，施行公平和公义，不再勒索我的民。这是主耶和华说的。"},
{volumn:        26,chapter:        45,versenumber:        10,verse:"“你们要用公道天平、公道伊法、公道罢特。"},
{volumn:        26,chapter:        45,versenumber:        11,verse:"伊法与罢特大小要一样。罢特可盛贺梅珥十分之一，伊法也可盛贺梅珥十分之一，都以贺梅珥的大小为准。"},
{volumn:        26,chapter:        45,versenumber:        12,verse:"舍客勒是二十季拉；二十舍客勒，二十五舍客勒，十五舍客勒，为你们的弥那。"},
{volumn:        26,chapter:        45,versenumber:        13,verse:"“你们当献的供物乃是这样：一贺梅珥麦子要献伊法六分之一；一贺梅珥大麦要献伊法六分之一。"},
{volumn:        26,chapter:        45,versenumber:        14,verse:"你们献所分定的油，按油的罢特，一柯珥油要献罢特十分之一（原来十罢特就是一贺梅珥）。"},
{volumn:        26,chapter:        45,versenumber:        15,verse:"从以色列滋润的草场上每二百羊中，要献一只羊羔。这都可作素祭、燔祭、平安祭，为民赎罪。这是主耶和华说的。"},
{volumn:        26,chapter:        45,versenumber:        16,verse:"此地的民都要奉上这供物给以色列中的王。"},
{volumn:        26,chapter:        45,versenumber:        17,verse:"王的本分是在节期、月朔、安息日，就是以色列家一切的节期，奉上燔祭、素祭、奠祭。他要预备赎罪祭、素祭、燔祭，和平安祭，为以色列家赎罪。”"},
{volumn:        26,chapter:        45,versenumber:        18,verse:"主耶和华如此说：“正月初一日，你要取无残疾的公牛犊，洁净圣所。"},
{volumn:        26,chapter:        45,versenumber:        19,verse:"祭司要取些赎罪祭牲的血，抹在殿的门柱上和坛磴台的四角上，并内院的门框上。"},
{volumn:        26,chapter:        45,versenumber:        20,verse:"本月初七日（七十士译本是七月初一日）也要为误犯罪的和愚蒙犯罪的如此行，为殿赎罪。"},
{volumn:        26,chapter:        45,versenumber:        21,verse:"“正月十四日，你们要守逾越节，守节七日，要吃无酵饼。"},
{volumn:        26,chapter:        45,versenumber:        22,verse:"当日，王要为自己和国内的众民预备一只公牛作赎罪祭。"},
{volumn:        26,chapter:        45,versenumber:        23,verse:"这节的七日，每日他要为耶和华预备无残疾的公牛七只、公绵羊七只为燔祭。每日又要预备公山羊一只为赎罪祭。"},
{volumn:        26,chapter:        45,versenumber:        24,verse:"他也要预备素祭，就是为一只公牛同献一伊法细面，为一只公绵羊同献一伊法细面，每一伊法细面加油一欣。"},
{volumn:        26,chapter:        45,versenumber:        25,verse:"七月十五日守节的时候，七日他都要如此行，照逾越节的赎罪祭、燔祭、素祭，和油的条例一样。”"},
{volumn:        26,chapter:        46,versenumber:         1,verse:"主耶和华如此说：“内院朝东的门，在办理事务的六日内必须关闭；惟有安息日和月朔必须敞开。"},
{volumn:        26,chapter:        46,versenumber:         2,verse:"王要从这门的廊进入，站在门框旁边。祭司要为他预备燔祭和平安祭，他就要在门槛那里敬拜，然后出去。这门直到晚上不可关闭。"},
{volumn:        26,chapter:        46,versenumber:         3,verse:"在安息日和月朔，国内的居民要在这门口，耶和华面前敬拜。"},
{volumn:        26,chapter:        46,versenumber:         4,verse:"安息日，王所献与耶和华的燔祭要用无残疾的羊羔六只，无残疾的公绵羊一只；"},
{volumn:        26,chapter:        46,versenumber:         5,verse:"同献的素祭要为公绵羊献一伊法细面，为羊羔照他的力量而献，一伊法细面加油一欣。"},
{volumn:        26,chapter:        46,versenumber:         6,verse:"当月朔，要献无残疾的公牛犊一只，羊羔六只，公绵羊一只，都要无残疾的。"},
{volumn:        26,chapter:        46,versenumber:         7,verse:"他也要预备素祭，为公牛献一伊法细面，为公绵羊献一伊法细面，为羊羔照他的力量而献，一伊法细面加油一欣。"},
{volumn:        26,chapter:        46,versenumber:         8,verse:"王进入的时候必由这门的廊而入，也必由此而出。"},
{volumn:        26,chapter:        46,versenumber:         9,verse:"“在各节期，国内居民朝见耶和华的时候，从北门进入敬拜的，必由南门而出；从南门进入的，必由北门而出。不可从所入的门而出，必要直往前行，由对门而出。"},
{volumn:        26,chapter:        46,versenumber:        10,verse:"民进入，王也要在民中进入；民出去，王也要一同出去。"},
{volumn:        26,chapter:        46,versenumber:        11,verse:"“在节期和圣会的日子同献的素祭，要为一只公牛献一伊法细面，为一只公绵羊献一伊法细面，为羊羔照他的力量而献，一伊法细面加油一欣。"},
{volumn:        26,chapter:        46,versenumber:        12,verse:"王预备甘心献的燔祭或平安祭，就是向耶和华甘心献的，当有人为他开朝东的门。他就预备燔祭和平安祭，与安息日预备的一样，献毕就出去。他出去之后，当有人将门关闭。”"},
{volumn:        26,chapter:        46,versenumber:        13,verse:"“每日，你要预备无残疾一岁的羊羔一只，献与耶和华为燔祭；要每早晨预备。"},
{volumn:        26,chapter:        46,versenumber:        14,verse:"每早晨也要预备同献的素祭，细面一伊法六分之一，并油一欣三分之一，调和细面。这素祭要常献与耶和华为永远的定例。"},
{volumn:        26,chapter:        46,versenumber:        15,verse:"每早晨要这样预备羊羔、素祭，并油为常献的燔祭。”"},
{volumn:        26,chapter:        46,versenumber:        16,verse:"主耶和华如此说：“王若将产业赐给他的儿子，就成了他儿子的产业，那是他们承受为业的。"},
{volumn:        26,chapter:        46,versenumber:        17,verse:"倘若王将一份产业赐给他的臣仆，就成了他臣仆的产业；到自由之年仍要归与王。至于王的产业，必归与他的儿子。"},
{volumn:        26,chapter:        46,versenumber:        18,verse:"王不可夺取民的产业，以致驱逐他们离开所承受的；他要从自己的地业中，将产业赐给他儿子，免得我的民分散，各人离开所承受的。”"},
{volumn:        26,chapter:        46,versenumber:        19,verse:"那带我的，将我从门旁进入之处、领进为祭司预备的圣屋，是朝北的，见后头西边有一块地。"},
{volumn:        26,chapter:        46,versenumber:        20,verse:"他对我说：“这是祭司煮赎愆祭、赎罪祭，烤素祭之地，免得带到外院，使民成圣。”"},
{volumn:        26,chapter:        46,versenumber:        21,verse:"他又带我到外院，使我经过院子的四拐角，见每拐角各有一个院子。"},
{volumn:        26,chapter:        46,versenumber:        22,verse:"院子四拐角的院子，周围有墙，每院长四十肘，宽三十肘。四拐角院子的尺寸都是一样，"},
{volumn:        26,chapter:        46,versenumber:        23,verse:"其中周围有一排房子，房子内有煮肉的地方。"},
{volumn:        26,chapter:        46,versenumber:        24,verse:"他对我说：“这都是煮肉的房子，殿内的仆役要在这里煮民的祭物。”"},
{volumn:        26,chapter:        47,versenumber:         1,verse:"他带我回到殿门，见殿的门槛下有水往东流出（原来殿面朝东）。这水从槛下，由殿的右边，在祭坛的南边往下流。"},
{volumn:        26,chapter:        47,versenumber:         2,verse:"他带我出北门，又领我从外边转到朝东的外门，见水从右边流出。"},
{volumn:        26,chapter:        47,versenumber:         3,verse:"他手拿准绳往东出去的时候，量了一千肘，使我趟过水，水到踝子骨。"},
{volumn:        26,chapter:        47,versenumber:         4,verse:"他又量了一千肘，使我趟过水，水就到膝；再量了一千肘，使我趟过水，水便到腰；"},
{volumn:        26,chapter:        47,versenumber:         5,verse:"又量了一千肘，水便成了河，使我不能趟过。因为水势涨起，成为可洑的水，不可趟的河。"},
{volumn:        26,chapter:        47,versenumber:         6,verse:"他对我说：“人子啊，你看见了什么？”他就带我回到河边。"},
{volumn:        26,chapter:        47,versenumber:         7,verse:"我回到河边的时候，见在河这边与那边的岸上有极多的树木。"},
{volumn:        26,chapter:        47,versenumber:         8,verse:"他对我说：“这水往东方流去，必下到亚拉巴，直到海。所发出来的水必流入盐海，使水变甜（原文是得医治；下同）。"},
{volumn:        26,chapter:        47,versenumber:         9,verse:"这河水所到之处，凡滋生的动物都必生活，并且因这流来的水必有极多的鱼，海水也变甜了。这河水所到之处，百物都必生活。"},
{volumn:        26,chapter:        47,versenumber:        10,verse:"必有渔夫站在河边，从隐·基底直到隐·以革莲，都作晒（或译：张）网之处。那鱼各从其类，好像大海的鱼甚多。"},
{volumn:        26,chapter:        47,versenumber:        11,verse:"只是泥泞之地与洼湿之处不得治好，必为盐地。"},
{volumn:        26,chapter:        47,versenumber:        12,verse:"在河这边与那边的岸上必生长各类的树木；其果可作食物，叶子不枯干，果子不断绝。每月必结新果子，因为这水是从圣所流出来的。树上的果子必作食物，叶子乃为治病。”"},
{volumn:        26,chapter:        47,versenumber:        13,verse:"主耶和华如此说：“你们要照地的境界，按以色列十二支派分地为业。约瑟必得两份。"},
{volumn:        26,chapter:        47,versenumber:        14,verse:"你们承受这地为业，要彼此均分；因为我曾起誓应许将这地赐与你们的列祖；这地必归你们为业。"},
{volumn:        26,chapter:        47,versenumber:        15,verse:"“地的四界乃是如此：北界从大海往希特伦，直到西达达口。"},
{volumn:        26,chapter:        47,versenumber:        16,verse:"又往哈马、比罗他、西伯莲（西伯莲在大马士革与哈马两界中间），到浩兰边界的哈撒·哈提干。"},
{volumn:        26,chapter:        47,versenumber:        17,verse:"这样，境界从海边往大马士革地界上的哈萨·以难，北边以哈马地为界。这是北界。"},
{volumn:        26,chapter:        47,versenumber:        18,verse:"“东界在浩兰、大马士革、基列，和以色列地的中间，就是约旦河。你们要从北界量到东海。这是东界。"},
{volumn:        26,chapter:        47,versenumber:        19,verse:"“南界是从他玛到米利巴·加低斯的水，延到埃及小河，直到大海。这是南界。"},
{volumn:        26,chapter:        47,versenumber:        20,verse:"“西界就是大海，从南界直到哈马口对面之地。这是西界。"},
{volumn:        26,chapter:        47,versenumber:        21,verse:"“你们要按着以色列的支派彼此分这地。"},
{volumn:        26,chapter:        47,versenumber:        22,verse:"要拈阄分这地为业，归与自己和你们中间寄居的外人，就是在你们中间生养儿女的外人。你们要看他们如同以色列人中所生的一样；他们在以色列支派中要与你们同得地业。"},
{volumn:        26,chapter:        47,versenumber:        23,verse:"外人寄居在哪支派中，你们就在那里分给他地业。这是主耶和华说的。”"},
{volumn:        26,chapter:        48,versenumber:         1,verse:"众支派按名所得之地记在下面：从北头，由希特伦往哈马口，到大马士革地界上的哈萨·以难。北边靠着哈马地（各支派的地都有东西的边界），是但的一份。"},
{volumn:        26,chapter:        48,versenumber:         2,verse:"挨着但的地界，从东到西，是亚设的一份。"},
{volumn:        26,chapter:        48,versenumber:         3,verse:"挨着亚设的地界，从东到西，是拿弗他利的一份。"},
{volumn:        26,chapter:        48,versenumber:         4,verse:"挨着拿弗他利的地界，从东到西，是玛拿西的一份。"},
{volumn:        26,chapter:        48,versenumber:         5,verse:"挨着玛拿西的地界，从东到西，是以法莲的一份。"},
{volumn:        26,chapter:        48,versenumber:         6,verse:"挨着以法莲的地界，从东到西，是流便的一份。"},
{volumn:        26,chapter:        48,versenumber:         7,verse:"挨着流便的地界，从东到西，是犹大的一份。"},
{volumn:        26,chapter:        48,versenumber:         8,verse:"挨着犹大的地界，从东到西，必有你们所当献的供地，宽二万五千肘。从东界到西界，长短与各份之地相同，圣地当在其中。"},
{volumn:        26,chapter:        48,versenumber:         9,verse:"你们献与耶和华的供地要长二万五千肘，宽一万肘。"},
{volumn:        26,chapter:        48,versenumber:        10,verse:"这圣供地要归与祭司，北长二万五千肘，西宽一万肘，东宽一万肘，南长二万五千肘。耶和华的圣地当在其中。"},
{volumn:        26,chapter:        48,versenumber:        11,verse:"这地要归与撒督的子孙中成为圣的祭司，就是那守我所吩咐的。当以色列人走迷的时候，他们不像那些利未人走迷了。"},
{volumn:        26,chapter:        48,versenumber:        12,verse:"这要归与他们为供地，是全地中至圣的。供地挨着利未人的地界。"},
{volumn:        26,chapter:        48,versenumber:        13,verse:"利未人所得的地要长二万五千肘，宽一万肘，与祭司的地界相等，都长二万五千肘，宽一万肘。"},
{volumn:        26,chapter:        48,versenumber:        14,verse:"这地不可卖，不可换，初熟之物也不可归与别人，因为是归耶和华为圣的。"},
{volumn:        26,chapter:        48,versenumber:        15,verse:"这二万五千肘前面所剩下五千肘宽之地要作俗用，作为造城盖房郊野之地。城要在当中。"},
{volumn:        26,chapter:        48,versenumber:        16,verse:"城的尺寸乃是如此：北面四千五百肘，南面四千五百肘，东面四千五百肘，西面四千五百肘。"},
{volumn:        26,chapter:        48,versenumber:        17,verse:"城必有郊野，向北二百五十肘，向南二百五十肘，向东二百五十肘，向西二百五十肘。"},
{volumn:        26,chapter:        48,versenumber:        18,verse:"靠着圣供地的余地，东长一万肘，西长一万肘，要与圣供地相等；其中的土产要作城内工人的食物。"},
{volumn:        26,chapter:        48,versenumber:        19,verse:"所有以色列支派中，在城内做工的，都要耕种这地。"},
{volumn:        26,chapter:        48,versenumber:        20,verse:"你们所献的圣供地连归城之地，是四方的：长二万五千肘，宽二万五千肘。"},
{volumn:        26,chapter:        48,versenumber:        21,verse:"圣供地连归城之地，两边的余地要归与王。供地东边，南北二万五千肘，东至东界，西边南北二万五千肘，西至西界，与各份之地相同，都要归王。圣供地和殿的圣地要在其中，"},
{volumn:        26,chapter:        48,versenumber:        22,verse:"并且利未人之地，与归城之地的东西两边延长之地（这两地在王地中间），就是在犹大和便雅悯两界中间，要归与王。"},
{volumn:        26,chapter:        48,versenumber:        23,verse:"论到其余的支派，从东到西，是便雅悯的一份。"},
{volumn:        26,chapter:        48,versenumber:        24,verse:"挨着便雅悯的地界，从东到西，是西缅的一份。"},
{volumn:        26,chapter:        48,versenumber:        25,verse:"挨着西缅的地界，从东到西，是以萨迦的一份。"},
{volumn:        26,chapter:        48,versenumber:        26,verse:"挨着以萨迦的地界，从东到西，是西布伦的一份。"},
{volumn:        26,chapter:        48,versenumber:        27,verse:"挨着西布伦的地界，从东到西，是迦得的一份。"},
{volumn:        26,chapter:        48,versenumber:        28,verse:"迦得地的南界是从他玛到米利巴·加低斯的水，延到埃及小河，直到大海。"},
{volumn:        26,chapter:        48,versenumber:        29,verse:"这就是你们要拈阄分给以色列支派为业之地，乃是他们各支派所得之份。这是主耶和华说的。"},
{volumn:        26,chapter:        48,versenumber:        30,verse:"城的北面四千五百肘。出城之处如下；"},
{volumn:        26,chapter:        48,versenumber:        31,verse:"城的各门要按以色列支派的名字。北面有三门，一为流便门，一为犹大门，一为利未门；"},
{volumn:        26,chapter:        48,versenumber:        32,verse:"东面四千五百肘，有三门，一为约瑟门，一为便雅悯门，一为但门；"},
{volumn:        26,chapter:        48,versenumber:        33,verse:"南面四千五百肘，有三门，一为西缅门，一为以萨迦门，一为西布伦门；"},
{volumn:        26,chapter:        48,versenumber:        34,verse:"西面四千五百肘，有三门，一为迦得门，一为亚设门，一为拿弗他利门。"},
{volumn:        26,chapter:        48,versenumber:        35,verse:"城四围共一万八千肘。从此以后，这城的名字必称为“耶和华的所在”。"},
{volumn:        27,chapter:         1,versenumber:         1,verse:"犹大王约雅敬在位第三年，巴比伦王尼布甲尼撒来到耶路撒冷，将城围困。"},
{volumn:        27,chapter:         1,versenumber:         2,verse:"主将犹大王约雅敬，并　神殿中器皿的几份交付他手。他就把这器皿带到示拿地，收入他神的庙里，放在他神的库中。"},
{volumn:        27,chapter:         1,versenumber:         3,verse:"王吩咐太监长亚施毗拿，从以色列人的宗室和贵胄中带进几个人来，"},
{volumn:        27,chapter:         1,versenumber:         4,verse:"就是年少没有残疾、相貌俊美、通达各样学问、知识聪明俱备、足能侍立在王宫里的，要教他们迦勒底的文字言语。"},
{volumn:        27,chapter:         1,versenumber:         5,verse:"王派定将自己所用的膳和所饮的酒，每日赐他们一份，养他们三年。满了三年，好叫他们在王面前侍立。"},
{volumn:        27,chapter:         1,versenumber:         6,verse:"他们中间有犹大族的人：但以理、哈拿尼雅、米沙利、亚撒利雅。"},
{volumn:        27,chapter:         1,versenumber:         7,verse:"太监长给他们起名：称但以理为伯提沙撒，称哈拿尼雅为沙得拉，称米沙利为米煞，称亚撒利雅为亚伯尼歌。"},
{volumn:        27,chapter:         1,versenumber:         8,verse:"但以理却立志不以王的膳和王所饮的酒玷污自己，所以求太监长容他不玷污自己。"},
{volumn:        27,chapter:         1,versenumber:         9,verse:"神使但以理在太监长眼前蒙恩惠，受怜悯。"},
{volumn:        27,chapter:         1,versenumber:        10,verse:"太监长对但以理说：“我惧怕我主我王，他已经派定你们的饮食，倘若他见你们的面貌比你们同岁的少年人肌瘦，怎么好呢？这样，你们就使我的头在王那里难保。”"},
{volumn:        27,chapter:         1,versenumber:        11,verse:"但以理对太监长所派管理但以理、哈拿尼雅、米沙利、亚撒利雅的委办说："},
{volumn:        27,chapter:         1,versenumber:        12,verse:"“求你试试仆人们十天，给我们素菜吃，白水喝，"},
{volumn:        27,chapter:         1,versenumber:        13,verse:"然后看看我们的面貌和用王膳那少年人的面貌，就照你所看的待仆人吧！”"},
{volumn:        27,chapter:         1,versenumber:        14,verse:"委办便允准他们这件事，试看他们十天。"},
{volumn:        27,chapter:         1,versenumber:        15,verse:"过了十天，见他们的面貌比用王膳的一切少年人更加俊美肥胖。"},
{volumn:        27,chapter:         1,versenumber:        16,verse:"于是委办撤去派他们用的膳，饮的酒，给他们素菜吃。"},
{volumn:        27,chapter:         1,versenumber:        17,verse:"这四个少年人，　神在各样文字学问（学问：原文是智慧）上赐给他们聪明知识；但以理又明白各样的异象和梦兆。"},
{volumn:        27,chapter:         1,versenumber:        18,verse:"尼布甲尼撒王预定带进少年人来的日期满了，太监长就把他们带到王面前。"},
{volumn:        27,chapter:         1,versenumber:        19,verse:"王与他们谈论，见少年人中无一人能比但以理、哈拿尼雅、米沙利、亚撒利雅，所以留他们在王面前侍立。"},
{volumn:        27,chapter:         1,versenumber:        20,verse:"王考问他们一切事，就见他们的智慧聪明比通国的术士和用法术的胜过十倍。"},
{volumn:        27,chapter:         1,versenumber:        21,verse:"到居鲁士王元年，但以理还在。"},
{volumn:        27,chapter:         2,versenumber:         1,verse:"尼布甲尼撒在位第二年，他做了梦，心里烦乱，不能睡觉。"},
{volumn:        27,chapter:         2,versenumber:         2,verse:"王吩咐人将术士、用法术的、行邪术的，和迦勒底人召来，要他们将王的梦告诉王，他们就来站在王前。"},
{volumn:        27,chapter:         2,versenumber:         3,verse:"王对他们说：“我做了一梦，心里烦乱，要知道这是什么梦。”"},
{volumn:        27,chapter:         2,versenumber:         4,verse:"迦勒底人用亚兰的言语对王说：“愿王万岁！请将那梦告诉仆人，仆人就可以讲解。”"},
{volumn:        27,chapter:         2,versenumber:         5,verse:"王回答迦勒底人说：“梦我已经忘了（或译：我已定命；八节同），你们若不将梦和梦的讲解告诉我，就必被凌迟，你们的房屋必成为粪堆；"},
{volumn:        27,chapter:         2,versenumber:         6,verse:"你们若将梦和梦的讲解告诉我，就必从我这里得赠品和赏赐，并大尊荣。现在你们要将梦和梦的讲解告诉我。”"},
{volumn:        27,chapter:         2,versenumber:         7,verse:"他们第二次对王说：“请王将梦告诉仆人，仆人就可以讲解。”"},
{volumn:        27,chapter:         2,versenumber:         8,verse:"王回答说：“我准知道你们是故意迟延，因为你们知道那梦我已经忘了。"},
{volumn:        27,chapter:         2,versenumber:         9,verse:"你们若不将梦告诉我，只有一法待你们；因为你们预备了谎言乱语向我说，要等候时势改变。现在你们要将梦告诉我，因我知道你们能将梦的讲解告诉我。”"},
{volumn:        27,chapter:         2,versenumber:        10,verse:"迦勒底人在王面前回答说：“世上没有人能将王所问的事说出来；因为没有君王、大臣、掌权的向术士，或用法术的，或迦勒底人问过这样的事。"},
{volumn:        27,chapter:         2,versenumber:        11,verse:"王所问的事甚难。除了不与世人同居的神明，没有人在王面前能说出来。”"},
{volumn:        27,chapter:         2,versenumber:        12,verse:"因此，王气忿忿地大发烈怒，吩咐灭绝巴比伦所有的哲士。"},
{volumn:        27,chapter:         2,versenumber:        13,verse:"于是命令发出，哲士将要见杀，人就寻找但以理和他的同伴，要杀他们。"},
{volumn:        27,chapter:         2,versenumber:        14,verse:"王的护卫长亚略出来，要杀巴比伦的哲士，但以理就用婉言回答他，"},
{volumn:        27,chapter:         2,versenumber:        15,verse:"向王的护卫长亚略说：“王的命令为何这样紧急呢？”亚略就将情节告诉但以理。"},
{volumn:        27,chapter:         2,versenumber:        16,verse:"但以理遂进去求王宽限，就可以将梦的讲解告诉王。"},
{volumn:        27,chapter:         2,versenumber:        17,verse:"但以理回到他的居所，将这事告诉他的同伴哈拿尼雅、米沙利、亚撒利雅，"},
{volumn:        27,chapter:         2,versenumber:        18,verse:"要他们祈求天上的　神施怜悯，将这奥秘的事指明，免得但以理和他的同伴与巴比伦其余的哲士一同灭亡。"},
{volumn:        27,chapter:         2,versenumber:        19,verse:"这奥秘的事就在夜间异象中给但以理显明，但以理便称颂天上的　神。"},
{volumn:        27,chapter:         2,versenumber:        20,verse:"但以理说：“　神的名是应当称颂的！从亘古直到永远，因为智慧能力都属乎他。"},
{volumn:        27,chapter:         2,versenumber:        21,verse:"他改变时候、日期，废王，立王，将智慧赐与智慧人，将知识赐与聪明人。"},
{volumn:        27,chapter:         2,versenumber:        22,verse:"他显明深奥隐秘的事，知道暗中所有的，光明也与他同居。"},
{volumn:        27,chapter:         2,versenumber:        23,verse:"我列祖的　神啊，我感谢你，赞美你；因你将智慧才能赐给我，允准我们所求的，把王的事给我们指明。”"},
{volumn:        27,chapter:         2,versenumber:        24,verse:"于是，但以理进去见亚略，就是王所派灭绝巴比伦哲士的，对他说：“不要灭绝巴比伦的哲士，求你领我到王面前，我要将梦的讲解告诉王。”"},
{volumn:        27,chapter:         2,versenumber:        25,verse:"亚略就急忙将但以理领到王面前，对王说：“我在被掳的犹大人中遇见一人，他能将梦的讲解告诉王。”"},
{volumn:        27,chapter:         2,versenumber:        26,verse:"王问称为伯提沙撒的但以理说：“你能将我所做的梦和梦的讲解告诉我吗？”"},
{volumn:        27,chapter:         2,versenumber:        27,verse:"但以理在王面前回答说：“王所问的那奥秘事，哲士、用法术的、术士、观兆的都不能告诉王，"},
{volumn:        27,chapter:         2,versenumber:        28,verse:"只有一位在天上的　神能显明奥秘的事。他已将日后必有的事指示尼布甲尼撒王。你的梦和你在床上脑中的异象是这样："},
{volumn:        27,chapter:         2,versenumber:        29,verse:"王啊，你在床上想到后来的事，那显明奥秘事的主把将来必有的事指示你。"},
{volumn:        27,chapter:         2,versenumber:        30,verse:"至于那奥秘的事显明给我，并非因我的智慧胜过一切活人，乃为使王知道梦的讲解和心里的思念。"},
{volumn:        27,chapter:         2,versenumber:        31,verse:"“王啊，你梦见一个大像，这像甚高，极其光耀，站在你面前，形状甚是可怕。"},
{volumn:        27,chapter:         2,versenumber:        32,verse:"这像的头是精金的，胸膛和膀臂是银的，肚腹和腰是铜的，"},
{volumn:        27,chapter:         2,versenumber:        33,verse:"腿是铁的，脚是半铁半泥的。"},
{volumn:        27,chapter:         2,versenumber:        34,verse:"你观看，见有一块非人手凿出来的石头打在这像半铁半泥的脚上，把脚砸碎；"},
{volumn:        27,chapter:         2,versenumber:        35,verse:"于是金、银、铜、铁、泥都一同砸得粉碎，成如夏天禾场上的糠秕，被风吹散，无处可寻。打碎这像的石头变成一座大山，充满天下。"},
{volumn:        27,chapter:         2,versenumber:        36,verse:"“这就是那梦；我们在王面前要讲解那梦。"},
{volumn:        27,chapter:         2,versenumber:        37,verse:"王啊，你是诸王之王。天上的　神已将国度、权柄、能力、尊荣都赐给你。"},
{volumn:        27,chapter:         2,versenumber:        38,verse:"凡世人所住之地的走兽，并天空的飞鸟，他都交付你手，使你掌管这一切。你就是那金头。"},
{volumn:        27,chapter:         2,versenumber:        39,verse:"在你以后必另兴一国，不及于你；又有第三国，就是铜的，必掌管天下。"},
{volumn:        27,chapter:         2,versenumber:        40,verse:"第四国，必坚壮如铁，铁能打碎克制百物，又能压碎一切，那国也必打碎压制列国。"},
{volumn:        27,chapter:         2,versenumber:        41,verse:"你既见像的脚和脚指头，一半是窑匠的泥，一半是铁，那国将来也必分开。你既见铁与泥搀杂，那国也必有铁的力量。"},
{volumn:        27,chapter:         2,versenumber:        42,verse:"那脚指头，既是半铁半泥，那国也必半强半弱。"},
{volumn:        27,chapter:         2,versenumber:        43,verse:"你既见铁与泥搀杂，那国民也必与各种人搀杂，却不能彼此相合，正如铁与泥不能相合一样。"},
{volumn:        27,chapter:         2,versenumber:        44,verse:"当那列王在位的时候，天上的　神必另立一国，永不败坏，也不归别国的人，却要打碎灭绝那一切国，这国必存到永远。"},
{volumn:        27,chapter:         2,versenumber:        45,verse:"你既看见非人手凿出来的一块石头从山而出，打碎金、银、铜、铁、泥，那就是至大的　神把后来必有的事给王指明。这梦准是这样，这讲解也是确实的。”"},
{volumn:        27,chapter:         2,versenumber:        46,verse:"当时，尼布甲尼撒王俯伏在地，向但以理下拜，并且吩咐人给他奉上供物和香品。"},
{volumn:        27,chapter:         2,versenumber:        47,verse:"王对但以理说：“你既能显明这奥秘的事，你们的　神诚然是万神之神、万王之主，又是显明奥秘事的。”"},
{volumn:        27,chapter:         2,versenumber:        48,verse:"于是王高抬但以理，赏赐他许多上等礼物，派他管理巴比伦全省，又立他为总理，掌管巴比伦的一切哲士。"},
{volumn:        27,chapter:         2,versenumber:        49,verse:"但以理求王，王就派沙得拉、米煞、亚伯尼歌管理巴比伦省的事务，只是但以理常在朝中侍立。"},
{volumn:        27,chapter:         3,versenumber:         1,verse:"尼布甲尼撒王造了一个金像，高六十肘，宽六肘，立在巴比伦省杜拉平原。"},
{volumn:        27,chapter:         3,versenumber:         2,verse:"尼布甲尼撒王差人将总督、钦差、巡抚、臬司、藩司、谋士、法官，和各省的官员都召了来，为尼布甲尼撒王所立的像行开光之礼。"},
{volumn:        27,chapter:         3,versenumber:         3,verse:"于是总督、钦差、巡抚、臬司、藩司、谋士、法官，和各省的官员都聚集了来，要为尼布甲尼撒王所立的像行开光之礼，就站在尼布甲尼撒所立的像前。"},
{volumn:        27,chapter:         3,versenumber:         4,verse:"那时传令的大声呼叫说：“各方、各国、各族（原文是舌；下同）的人哪，有令传与你们："},
{volumn:        27,chapter:         3,versenumber:         5,verse:"你们一听见角、笛、琵琶、琴、瑟、笙，和各样乐器的声音，就当俯伏敬拜尼布甲尼撒王所立的金像。"},
{volumn:        27,chapter:         3,versenumber:         6,verse:"凡不俯伏敬拜的，必立时扔在烈火的窑中。”"},
{volumn:        27,chapter:         3,versenumber:         7,verse:"因此各方、各国、各族的人民一听见角、笛、琵琶、琴、瑟，和各样乐器的声音，就都俯伏敬拜尼布甲尼撒王所立的金像。"},
{volumn:        27,chapter:         3,versenumber:         8,verse:"那时，有几个迦勒底人进前来控告犹大人。"},
{volumn:        27,chapter:         3,versenumber:         9,verse:"他们对尼布甲尼撒王说：“愿王万岁！"},
{volumn:        27,chapter:         3,versenumber:        10,verse:"王啊，你曾降旨说，凡听见角、笛、琵琶、琴、瑟、笙，和各样乐器声音的都当俯伏敬拜金像。"},
{volumn:        27,chapter:         3,versenumber:        11,verse:"凡不俯伏敬拜的，必扔在烈火的窑中。"},
{volumn:        27,chapter:         3,versenumber:        12,verse:"现在有几个犹大人，就是王所派管理巴比伦省事务的沙得拉、米煞、亚伯尼歌；王啊，这些人不理你，不侍奉你的神，也不敬拜你所立的金像。”"},
{volumn:        27,chapter:         3,versenumber:        13,verse:"当时，尼布甲尼撒冲冲大怒，吩咐人把沙得拉、米煞、亚伯尼歌带过来，他们就把那些人带到王面前。"},
{volumn:        27,chapter:         3,versenumber:        14,verse:"尼布甲尼撒问他们说：“沙得拉、米煞、亚伯尼歌，你们不侍奉我的神，也不敬拜我所立的金像，是故意的吗？"},
{volumn:        27,chapter:         3,versenumber:        15,verse:"你们再听见角、笛、琵琶、琴、瑟、笙，和各样乐器的声音，若俯伏敬拜我所造的像，却还可以；若不敬拜，必立时扔在烈火的窑中，有何神能救你们脱离我手呢？”"},
{volumn:        27,chapter:         3,versenumber:        16,verse:"沙得拉、米煞、亚伯尼歌对王说：“尼布甲尼撒啊，这件事我们不必回答你，"},
{volumn:        27,chapter:         3,versenumber:        17,verse:"即便如此，我们所侍奉的　神能将我们从烈火的窑中救出来。王啊，他也必救我们脱离你的手；"},
{volumn:        27,chapter:         3,versenumber:        18,verse:"即或不然，王啊，你当知道我们决不侍奉你的神，也不敬拜你所立的金像。”"},
{volumn:        27,chapter:         3,versenumber:        19,verse:"当时，尼布甲尼撒怒气填胸，向沙得拉、米煞、亚伯尼歌变了脸色，吩咐人把窑烧热，比寻常更加七倍；"},
{volumn:        27,chapter:         3,versenumber:        20,verse:"又吩咐他军中的几个壮士，将沙得拉、米煞、亚伯尼歌捆起来，扔在烈火的窑中。"},
{volumn:        27,chapter:         3,versenumber:        21,verse:"这三人穿着裤子、内袍、外衣，和别的衣服，被捆起来扔在烈火的窑中。"},
{volumn:        27,chapter:         3,versenumber:        22,verse:"因为王命紧急，窑又甚热，那抬沙得拉、米煞、亚伯尼歌的人都被火焰烧死。"},
{volumn:        27,chapter:         3,versenumber:        23,verse:"沙得拉、米煞、亚伯尼歌这三个人都被捆着落在烈火的窑中。"},
{volumn:        27,chapter:         3,versenumber:        24,verse:"那时，尼布甲尼撒王惊奇，急忙起来，对谋士说：“我们捆起来扔在火里的不是三个人吗？”他们回答王说：“王啊，是。”"},
{volumn:        27,chapter:         3,versenumber:        25,verse:"王说：“看哪，我见有四个人，并没有捆绑，在火中游行，也没有受伤；那第四个的相貌好像神子。”"},
{volumn:        27,chapter:         3,versenumber:        26,verse:"于是，尼布甲尼撒就近烈火窑门，说：“至高　神的仆人沙得拉、米煞、亚伯尼歌出来，上这里来吧！”沙得拉、米煞、亚伯尼歌就从火中出来了。"},
{volumn:        27,chapter:         3,versenumber:        27,verse:"那些总督、钦差、巡抚，和王的谋士一同聚集看这三个人，见火无力伤他们的身体，头发也没有烧焦，衣裳也没有变色，并没有火燎的气味。"},
{volumn:        27,chapter:         3,versenumber:        28,verse:"尼布甲尼撒说：“沙得拉、米煞、亚伯尼歌的　神是应当称颂的！他差遣使者救护倚靠他的仆人，他们不遵王命，舍去己身，在他们　神以外不肯侍奉敬拜别神。"},
{volumn:        27,chapter:         3,versenumber:        29,verse:"现在我降旨，无论何方、何国、何族的人，谤讟沙得拉、米煞、亚伯尼歌之　神的，必被凌迟，他的房屋必成粪堆，因为没有别神能这样施行拯救。”"},
{volumn:        27,chapter:         3,versenumber:        30,verse:"那时王在巴比伦省，高升了沙得拉、米煞、亚伯尼歌。"},
{volumn:        27,chapter:         4,versenumber:         1,verse:"尼布甲尼撒王晓谕住在全地各方、各国、各族的人说：“愿你们大享平安！"},
{volumn:        27,chapter:         4,versenumber:         2,verse:"我乐意将至高的　神向我所行的神迹奇事宣扬出来。"},
{volumn:        27,chapter:         4,versenumber:         3,verse:"他的神迹何其大！他的奇事何其盛！他的国是永远的；他的权柄存到万代！"},
{volumn:        27,chapter:         4,versenumber:         4,verse:"“我尼布甲尼撒安居在宫中，平顺在殿内。"},
{volumn:        27,chapter:         4,versenumber:         5,verse:"我做了一梦，使我惧怕。我在床上的思念，并脑中的异象，使我惊惶。"},
{volumn:        27,chapter:         4,versenumber:         6,verse:"所以我降旨召巴比伦的一切哲士到我面前，叫他们把梦的讲解告诉我。"},
{volumn:        27,chapter:         4,versenumber:         7,verse:"于是那些术士、用法术的、迦勒底人、观兆的都进来，我将那梦告诉了他们，他们却不能把梦的讲解告诉我。"},
{volumn:        27,chapter:         4,versenumber:         8,verse:"末后那照我　神的名，称为伯提沙撒的但以理来到我面前，他里头有圣神的灵，我将梦告诉他说："},
{volumn:        27,chapter:         4,versenumber:         9,verse:"‘术士的领袖伯提沙撒啊，因我知道你里头有圣神的灵，什么奥秘的事都不能使你为难。现在要把我梦中所见的异象和梦的讲解告诉我。’"},
{volumn:        27,chapter:         4,versenumber:        10,verse:"“我在床上脑中的异象是这样：我看见地当中有一棵树，极其高大。"},
{volumn:        27,chapter:         4,versenumber:        11,verse:"那树渐长，而且坚固，高得顶天，从地极都能看见，"},
{volumn:        27,chapter:         4,versenumber:        12,verse:"叶子华美，果子甚多，可作众生的食物；田野的走兽卧在荫下，天空的飞鸟宿在枝上；凡有血气的都从这树得食。"},
{volumn:        27,chapter:         4,versenumber:        13,verse:"“我在床上脑中的异象，见有一位守望的圣者从天而降。"},
{volumn:        27,chapter:         4,versenumber:        14,verse:"大声呼叫说：‘伐倒这树！砍下枝子！摇掉叶子！抛散果子！使走兽离开树下，飞鸟躲开树枝。"},
{volumn:        27,chapter:         4,versenumber:        15,verse:"树墩却要留在地内，用铁圈和铜圈箍住，在田野的青草中让天露滴湿，使他与地上的兽一同吃草，"},
{volumn:        27,chapter:         4,versenumber:        16,verse:"使他的心改变，不如人心，给他一个兽心，使他经过七期（期：或译年；本章同）。"},
{volumn:        27,chapter:         4,versenumber:        17,verse:"这是守望者所发的命，圣者所出的令，好叫世人知道至高者在人的国中掌权，要将国赐与谁就赐与谁，或立极卑微的人执掌国权。’"},
{volumn:        27,chapter:         4,versenumber:        18,verse:"“这是我尼布甲尼撒王所做的梦。伯提沙撒啊，你要说明这梦的讲解；因为我国中的一切哲士都不能将梦的讲解告诉我，惟独你能，因你里头有圣神的灵。”"},
{volumn:        27,chapter:         4,versenumber:        19,verse:"于是称为伯提沙撒的但以理惊讶片时，心意惊惶。王说：“伯提沙撒啊，不要因梦和梦的讲解惊惶。”伯提沙撒回答说：“我主啊，愿这梦归与恨恶你的人，讲解归与你的敌人。"},
{volumn:        27,chapter:         4,versenumber:        20,verse:"你所见的树渐长，而且坚固，高得顶天，从地极都能看见；"},
{volumn:        27,chapter:         4,versenumber:        21,verse:"叶子华美，果子甚多，可作众生的食物；田野的走兽住在其下；天空的飞鸟宿在枝上。"},
{volumn:        27,chapter:         4,versenumber:        22,verse:"“王啊，这渐长又坚固的树就是你。你的威势渐长及天，你的权柄管到地极。"},
{volumn:        27,chapter:         4,versenumber:        23,verse:"王既看见一位守望的圣者从天而降，说：‘将这树砍伐毁坏，树墩却要留在地内，用铁圈和铜圈箍住；在田野的青草中，让天露滴湿，使他与地上的兽一同吃草，直到经过七期。’"},
{volumn:        27,chapter:         4,versenumber:        24,verse:"“王啊，讲解就是这样：临到我主我王的事是出于至高者的命。"},
{volumn:        27,chapter:         4,versenumber:        25,verse:"你必被赶出离开世人，与野地的兽同居，吃草如牛，被天露滴湿，且要经过七期。等你知道至高者在人的国中掌权，要将国赐与谁就赐与谁。"},
{volumn:        27,chapter:         4,versenumber:        26,verse:"守望者既吩咐存留树墩，等你知道诸天掌权，以后你的国必定归你。"},
{volumn:        27,chapter:         4,versenumber:        27,verse:"王啊，求你悦纳我的谏言，以施行公义断绝罪过，以怜悯穷人除掉罪孽，或者你的平安可以延长。”"},
{volumn:        27,chapter:         4,versenumber:        28,verse:"这事都临到尼布甲尼撒王。"},
{volumn:        27,chapter:         4,versenumber:        29,verse:"过了十二个月，他游行在巴比伦王宫里（原文是上）。"},
{volumn:        27,chapter:         4,versenumber:        30,verse:"他说：“这大巴比伦不是我用大能大力建为京都，要显我威严的荣耀吗？”"},
{volumn:        27,chapter:         4,versenumber:        31,verse:"这话在王口中尚未说完，有声音从天降下，说：“尼布甲尼撒王啊，有话对你说，你的国位离开你了。"},
{volumn:        27,chapter:         4,versenumber:        32,verse:"你必被赶出离开世人，与野地的兽同居，吃草如牛，且要经过七期。等你知道至高者在人的国中掌权，要将国赐与谁就赐与谁。”"},
{volumn:        27,chapter:         4,versenumber:        33,verse:"当时这话就应验在尼布甲尼撒的身上，他被赶出离开世人，吃草如牛，身被天露滴湿，头发长长，好像鹰毛；指甲长长，如同鸟爪。"},
{volumn:        27,chapter:         4,versenumber:        34,verse:"日子满足，我尼布甲尼撒举目望天，我的聪明复归于我，我便称颂至高者，赞美尊敬活到永远的　神。他的权柄是永有的；他的国存到万代。"},
{volumn:        27,chapter:         4,versenumber:        35,verse:"世上所有的居民都算为虚无；在天上的万军和世上的居民中，他都凭自己的意旨行事。无人能拦住他手，或问他说：“你做什么呢？”"},
{volumn:        27,chapter:         4,versenumber:        36,verse:"那时，我的聪明复归于我，为我国的荣耀、威严，和光耀也都复归于我；并且我的谋士和大臣也来朝见我。我又得坚立在国位上，至大的权柄加增于我。"},
{volumn:        27,chapter:         4,versenumber:        37,verse:"现在我尼布甲尼撒赞美、尊崇、恭敬天上的王；因为他所做的全都诚实，他所行的也都公平。那行动骄傲的，他能降为卑。"},
{volumn:        27,chapter:         5,versenumber:         1,verse:"伯沙撒王为他的一千大臣设摆盛筵，与这一千人对面饮酒。"},
{volumn:        27,chapter:         5,versenumber:         2,verse:"伯沙撒欢饮之间，吩咐人将他父（或译：祖；下同）尼布甲尼撒从耶路撒冷殿中所掠的金银器皿拿来，王与大臣、皇后、妃嫔好用这器皿饮酒。"},
{volumn:        27,chapter:         5,versenumber:         3,verse:"于是他们把耶路撒冷　神殿库房中所掠的金器皿拿来，王和大臣、皇后、妃嫔就用这器皿饮酒。"},
{volumn:        27,chapter:         5,versenumber:         4,verse:"他们饮酒，赞美金、银、铜、铁、木、石所造的神。"},
{volumn:        27,chapter:         5,versenumber:         5,verse:"当时，忽有人的指头显出，在王宫与灯台相对的粉墙上写字。王看见写字的指头"},
{volumn:        27,chapter:         5,versenumber:         6,verse:"就变了脸色，心意惊惶，腰骨好像脱节，双膝彼此相碰，"},
{volumn:        27,chapter:         5,versenumber:         7,verse:"大声吩咐将用法术的和迦勒底人并观兆的领进来，对巴比伦的哲士说，谁能读这文字，把讲解告诉我，他必身穿紫袍，项带金链，在我国中位列第三。"},
{volumn:        27,chapter:         5,versenumber:         8,verse:"于是王的一切哲士都进来，却不能读那文字，也不能把讲解告诉王。"},
{volumn:        27,chapter:         5,versenumber:         9,verse:"伯沙撒王就甚惊惶，脸色改变，他的大臣也都惊奇。"},
{volumn:        27,chapter:         5,versenumber:        10,verse:"太后（或译：皇后；下同）因王和他大臣所说的话，就进入宴宫，说：“愿王万岁！你心意不要惊惶，脸面不要变色。"},
{volumn:        27,chapter:         5,versenumber:        11,verse:"在你国中有一人，他里头有圣神的灵，你父在世的日子，这人心中光明，又有聪明智慧，好像神的智慧。你父尼布甲尼撒王，就是王的父，立他为术士、用法术的，和迦勒底人，并观兆的领袖。"},
{volumn:        27,chapter:         5,versenumber:        12,verse:"在他里头有美好的灵性，又有知识聪明，能圆梦，释谜语，解疑惑。这人名叫但以理，尼布甲尼撒王又称他为伯提沙撒，现在可以召他来，他必解明这意思。”"},
{volumn:        27,chapter:         5,versenumber:        13,verse:"但以理就被领到王前。王问但以理说：“你是被掳之犹大人中的但以理吗？就是我父王从犹大掳来的吗？"},
{volumn:        27,chapter:         5,versenumber:        14,verse:"我听说你里头有神的灵，心中光明，又有聪明和美好的智慧。"},
{volumn:        27,chapter:         5,versenumber:        15,verse:"现在哲士和用法术的都领到我面前，为叫他们读这文字，把讲解告诉我，无奈他们都不能把讲解说出来。"},
{volumn:        27,chapter:         5,versenumber:        16,verse:"我听说你善于讲解，能解疑惑；现在你若能读这文字，把讲解告诉我，就必身穿紫袍，项戴金链，在我国中位列第三。”"},
{volumn:        27,chapter:         5,versenumber:        17,verse:"但以理在王面前回答说：“你的赠品可以归你自己，你的赏赐可以归给别人；我却要为王读这文字，把讲解告诉王。"},
{volumn:        27,chapter:         5,versenumber:        18,verse:"王啊，至高的　神曾将国位、大权、荣耀、威严赐与你父尼布甲尼撒；"},
{volumn:        27,chapter:         5,versenumber:        19,verse:"因　神所赐他的大权，各方、各国、各族的人都在他面前战兢恐惧。他可以随意生杀，随意升降。"},
{volumn:        27,chapter:         5,versenumber:        20,verse:"但他心高气傲，灵也刚愎，甚至行事狂傲，就被革去王位，夺去荣耀。"},
{volumn:        27,chapter:         5,versenumber:        21,verse:"他被赶出离开世人，他的心变如兽心，与野驴同居，吃草如牛，身被天露滴湿，等他知道至高的　神在人的国中掌权，凭自己的意旨立人治国。"},
{volumn:        27,chapter:         5,versenumber:        22,verse:"伯沙撒啊，你是他的儿子（或译：孙子），你虽知道这一切，你心仍不自卑，"},
{volumn:        27,chapter:         5,versenumber:        23,verse:"竟向天上的主自高，使人将他殿中的器皿拿到你面前，你和大臣、皇后、妃嫔用这器皿饮酒。你又赞美那不能看、不能听、无知无识、金、银、铜、铁、木、石所造的神，却没有将荣耀归与那手中有你气息，管理你一切行动的　神。"},
{volumn:        27,chapter:         5,versenumber:        24,verse:"因此从　神那里显出指头来写这文字。"},
{volumn:        27,chapter:         5,versenumber:        25,verse:"“所写的文字是：‘弥尼，弥尼，提客勒，乌法珥新。’"},
{volumn:        27,chapter:         5,versenumber:        26,verse:"讲解是这样：弥尼，就是　神已经数算你国的年日到此完毕。"},
{volumn:        27,chapter:         5,versenumber:        27,verse:"提客勒，就是你被称在天平里，显出你的亏欠。"},
{volumn:        27,chapter:         5,versenumber:        28,verse:"毗勒斯（与乌法珥新同义），就是你的国分裂，归与玛代人和波斯人。”"},
{volumn:        27,chapter:         5,versenumber:        29,verse:"伯沙撒下令，人就把紫袍给但以理穿上，把金链给他戴在颈项上，又传令使他在国中位列第三。"},
{volumn:        27,chapter:         5,versenumber:        30,verse:"当夜，迦勒底王伯沙撒被杀。"},
{volumn:        27,chapter:         5,versenumber:        31,verse:"玛代人大流士年六十二岁，取了迦勒底国。"},
{volumn:        27,chapter:         6,versenumber:         1,verse:"大流士随心所愿，立一百二十个总督，治理通国。"},
{volumn:        27,chapter:         6,versenumber:         2,verse:"又在他们以上立总长三人（但以理在其中），使总督在他们三人面前回复事务，免得王受亏损。"},
{volumn:        27,chapter:         6,versenumber:         3,verse:"因这但以理有美好的灵性，所以显然超乎其余的总长和总督，王又想立他治理通国。"},
{volumn:        27,chapter:         6,versenumber:         4,verse:"那时，总长和总督寻找但以理误国的把柄，为要参他；只是找不着他的错误过失，因他忠心办事，毫无错误过失。"},
{volumn:        27,chapter:         6,versenumber:         5,verse:"那些人便说：“我们要找参这但以理的把柄，除非在他　神的律法中就寻不着。”"},
{volumn:        27,chapter:         6,versenumber:         6,verse:"于是，总长和总督纷纷聚集来见王，说：“愿大流士王万岁！"},
{volumn:        27,chapter:         6,versenumber:         7,verse:"国中的总长、钦差、总督、谋士，和巡抚彼此商议，要立一条坚定的禁令（或译：求王下旨要立一条......），三十日内，不拘何人，若在王以外，或向神或向人求什么，就必扔在狮子坑中。"},
{volumn:        27,chapter:         6,versenumber:         8,verse:"王啊，现在求你立这禁令，加盖玉玺，使禁令决不更改；照玛代和波斯人的例是不可更改的。”"},
{volumn:        27,chapter:         6,versenumber:         9,verse:"于是大流士王立这禁令，加盖玉玺。"},
{volumn:        27,chapter:         6,versenumber:        10,verse:"但以理知道这禁令盖了玉玺，就到自己家里（他楼上的窗户开向耶路撒冷），一日三次，双膝跪在他　神面前，祷告感谢，与素常一样。"},
{volumn:        27,chapter:         6,versenumber:        11,verse:"那些人就纷纷聚集，见但以理在他　神面前祈祷恳求。"},
{volumn:        27,chapter:         6,versenumber:        12,verse:"他们便进到王前，提王的禁令，说：“王啊，三十日内不拘何人，若在王以外，或向神或向人求什么，必被扔在狮子坑中。王不是在这禁令上盖了玉玺吗？”王回答说：“实有这事，照玛代和波斯人的例是不可更改的。”"},
{volumn:        27,chapter:         6,versenumber:        13,verse:"他们对王说：“王啊，那被掳之犹大人中的但以理不理你，也不遵你盖了玉玺的禁令，他竟一日三次祈祷。”"},
{volumn:        27,chapter:         6,versenumber:        14,verse:"王听见这话，就甚愁烦，一心要救但以理，筹划解救他，直到日落的时候。"},
{volumn:        27,chapter:         6,versenumber:        15,verse:"那些人就纷纷聚集来见王，说：“王啊，当知道玛代人和波斯人有例，凡王所立的禁令和律例都不可更改。”"},
{volumn:        27,chapter:         6,versenumber:        16,verse:"王下令，人就把但以理带来，扔在狮子坑中。王对但以理说：“你所常侍奉的　神，他必救你。”"},
{volumn:        27,chapter:         6,versenumber:        17,verse:"有人搬石头放在坑口，王用自己的玺和大臣的印，封闭那坑，使惩办但以理的事毫无更改。"},
{volumn:        27,chapter:         6,versenumber:        18,verse:"王回宫，终夜禁食，无人拿乐器到他面前，并且睡不着觉。"},
{volumn:        27,chapter:         6,versenumber:        19,verse:"次日黎明，王就起来，急忙往狮子坑那里去。"},
{volumn:        27,chapter:         6,versenumber:        20,verse:"临近坑边，哀声呼叫但以理，对但以理说：“永生　神的仆人但以理啊，你所常侍奉的　神能救你脱离狮子吗？”"},
{volumn:        27,chapter:         6,versenumber:        21,verse:"但以理对王说：“愿王万岁！"},
{volumn:        27,chapter:         6,versenumber:        22,verse:"我的　神差遣使者，封住狮子的口，叫狮子不伤我；因我在　神面前无辜，我在王面前也没有行过亏损的事。”"},
{volumn:        27,chapter:         6,versenumber:        23,verse:"王就甚喜乐，吩咐人将但以理从坑里系上来。于是但以理从坑里被系上来，身上毫无伤损，因为信靠他的　神。"},
{volumn:        27,chapter:         6,versenumber:        24,verse:"王下令，人就把那些控告但以理的人，连他们的妻子儿女都带来，扔在狮子坑中。他们还没有到坑底，狮子就抓住（原文是胜了）他们，咬碎他们的骨头。"},
{volumn:        27,chapter:         6,versenumber:        25,verse:"那时，大流士王传旨，晓谕住在全地各方、各国、各族的人说：“愿你们大享平安！"},
{volumn:        27,chapter:         6,versenumber:        26,verse:"现在我降旨晓谕我所统辖的全国人民，要在但以理的　神面前，战兢恐惧。因为他是永远长存的活　神，他的国永不败坏；他的权柄永存无极！"},
{volumn:        27,chapter:         6,versenumber:        27,verse:"他护庇人，搭救人，在天上地下施行神迹奇事，救了但以理脱离狮子的口。”"},
{volumn:        27,chapter:         6,versenumber:        28,verse:"如此，这但以理，当大流士王在位的时候和波斯王居鲁士在位的时候，大享亨通。"},
{volumn:        27,chapter:         7,versenumber:         1,verse:"巴比伦王伯沙撒元年，但以理在床上做梦，见了脑中的异象，就记录这梦，述说其中的大意。"},
{volumn:        27,chapter:         7,versenumber:         2,verse:"但以理说：我夜里见异象，看见天的四风陡起，刮在大海之上。"},
{volumn:        27,chapter:         7,versenumber:         3,verse:"有四个大兽从海中上来，形状各有不同："},
{volumn:        27,chapter:         7,versenumber:         4,verse:"头一个像狮子，有鹰的翅膀；我正观看的时候，兽的翅膀被拔去，兽从地上得立起来，用两脚站立，像人一样，又得了人心。"},
{volumn:        27,chapter:         7,versenumber:         5,verse:"又有一兽如熊，就是第二兽，旁跨而坐，口齿内衔着三根肋骨。有吩咐这兽的说：“起来吞吃多肉。”"},
{volumn:        27,chapter:         7,versenumber:         6,verse:"此后我观看，又有一兽如豹，背上有鸟的四个翅膀；这兽有四个头，又得了权柄。"},
{volumn:        27,chapter:         7,versenumber:         7,verse:"其后我在夜间的异象中观看，见第四兽甚是可怕，极其强壮，大有力量，有大铁牙，吞吃嚼碎，所剩下的用脚践踏。这兽与前三兽大不相同，头有十角。"},
{volumn:        27,chapter:         7,versenumber:         8,verse:"我正观看这些角，见其中又长起一个小角；先前的角中有三角在这角前，连根被它拔出来。这角有眼，像人的眼，有口说夸大的话。"},
{volumn:        27,chapter:         7,versenumber:         9,verse:"我观看，见有宝座设立，上头坐着亘古常在者。他的衣服洁白如雪，头发如纯净的羊毛。宝座乃火焰，其轮乃烈火。"},
{volumn:        27,chapter:         7,versenumber:        10,verse:"从他面前有火，像河发出；侍奉他的有千千，在他面前侍立的有万万；他坐着要行审判，案卷都展开了。"},
{volumn:        27,chapter:         7,versenumber:        11,verse:"那时我观看，见那兽因小角说夸大话的声音被杀，身体损坏，扔在火中焚烧。"},
{volumn:        27,chapter:         7,versenumber:        12,verse:"其余的兽，权柄都被夺去，生命却仍存留，直到所定的时候和日期。"},
{volumn:        27,chapter:         7,versenumber:        13,verse:"我在夜间的异象中观看，见有一位像人子的，驾着天云而来，被领到亘古常在者面前，"},
{volumn:        27,chapter:         7,versenumber:        14,verse:"得了权柄、荣耀、国度，使各方、各国、各族的人都侍奉他。他的权柄是永远的，不能废去；他的国必不败坏。"},
{volumn:        27,chapter:         7,versenumber:        15,verse:"至于我但以理，我的灵在我里面愁烦，我脑中的异象使我惊惶。"},
{volumn:        27,chapter:         7,versenumber:        16,verse:"我就近一位侍立者，问他这一切的真情。他就告诉我，将那事的讲解给我说明："},
{volumn:        27,chapter:         7,versenumber:        17,verse:"这四个大兽就是四王将要在世上兴起。"},
{volumn:        27,chapter:         7,versenumber:        18,verse:"然而，至高者的圣民，必要得国享受，直到永永远远。"},
{volumn:        27,chapter:         7,versenumber:        19,verse:"那时我愿知道第四兽的真情，它为何与那三兽的真情大不相同，甚是可怕，有铁牙铜爪，吞吃嚼碎，所剩下的用脚践踏；"},
{volumn:        27,chapter:         7,versenumber:        20,verse:"头有十角和那另长的一角，在这角前有三角被它打落。这角有眼，有说夸大话的口，形状强横，过于它的同类。"},
{volumn:        27,chapter:         7,versenumber:        21,verse:"我观看，见这角与圣民争战，胜了他们。"},
{volumn:        27,chapter:         7,versenumber:        22,verse:"直到亘古常在者来给至高者的圣民伸冤，圣民得国的时候就到了。"},
{volumn:        27,chapter:         7,versenumber:        23,verse:"那侍立者这样说：“第四兽就是世上必有的第四国，与一切国大不相同，必吞吃全地，并且践踏嚼碎。"},
{volumn:        27,chapter:         7,versenumber:        24,verse:"至于那十角，就是从这国中必兴起的十王，后来又兴起一王，与先前的不同；他必制伏三王。"},
{volumn:        27,chapter:         7,versenumber:        25,verse:"他必向至高者说夸大的话，必折磨至高者的圣民，必想改变节期和律法。圣民必交付他手一载、二载、半载。"},
{volumn:        27,chapter:         7,versenumber:        26,verse:"然而，审判者必坐着行审判；他的权柄必被夺去，毁坏，灭绝，一直到底。"},
{volumn:        27,chapter:         7,versenumber:        27,verse:"国度、权柄，和天下诸国的大权必赐给至高者的圣民。他的国是永远的；一切掌权的都必侍奉他，顺从他。”"},
{volumn:        27,chapter:         7,versenumber:        28,verse:"那事至此完毕。至于我但以理，心中甚是惊惶，脸色也改变了，却将那事存记在心。"},
{volumn:        27,chapter:         8,versenumber:         1,verse:"伯沙撒王在位第三年，有异象现与我但以理，是在先前所见的异象之后。"},
{volumn:        27,chapter:         8,versenumber:         2,verse:"我见了异象的时候，我以为在以拦省书珊城（或译：宫）中；我见异象又如在乌莱河边。"},
{volumn:        27,chapter:         8,versenumber:         3,verse:"我举目观看，见有双角的公绵羊站在河边，两角都高。这角高过那角，更高的是后长的。"},
{volumn:        27,chapter:         8,versenumber:         4,verse:"我见那公绵羊往西、往北、往南抵触。兽在它面前都站立不住，也没有能救护脱离它手的；但它任意而行，自高自大。"},
{volumn:        27,chapter:         8,versenumber:         5,verse:"我正思想的时候，见有一只公山羊从西而来，遍行全地，脚不沾尘。这山羊两眼当中有一非常的角。"},
{volumn:        27,chapter:         8,versenumber:         6,verse:"它往我所看见、站在河边有双角的公绵羊那里去，大发忿怒，向它直闯。"},
{volumn:        27,chapter:         8,versenumber:         7,verse:"我见公山羊就近公绵羊，向它发烈怒，抵触它，折断它的两角。绵羊在它面前站立不住；它将绵羊触倒在地，用脚践踏，没有能救绵羊脱离它手的。"},
{volumn:        27,chapter:         8,versenumber:         8,verse:"这山羊极其自高自大，正强盛的时候，那大角折断了，又在角根上向天的四方（原文是风）长出四个非常的角来。"},
{volumn:        27,chapter:         8,versenumber:         9,verse:"四角之中有一角长出一个小角，向南、向东、向荣美之地，渐渐成为强大。"},
{volumn:        27,chapter:         8,versenumber:        10,verse:"它渐渐强大，高及天象，将些天象和星宿抛落在地，用脚践踏。"},
{volumn:        27,chapter:         8,versenumber:        11,verse:"并且它自高自大，以为高及天象之君；除掉常献给君的燔祭，毁坏君的圣所。"},
{volumn:        27,chapter:         8,versenumber:        12,verse:"因罪过的缘故，有军旅和常献的燔祭交付它。它将真理抛在地上，任意而行，无不顺利。"},
{volumn:        27,chapter:         8,versenumber:        13,verse:"我听见有一位圣者说话，又有一位圣者问那说话的圣者说：“这除掉常献的燔祭和施行毁坏的罪过，将圣所与军旅（或译：以色列的军）践踏的异象，要到几时才应验呢？”"},
{volumn:        27,chapter:         8,versenumber:        14,verse:"他对我说：“到二千三百日，圣所就必洁净。”"},
{volumn:        27,chapter:         8,versenumber:        15,verse:"我但以理见了这异象，愿意明白其中的意思。忽有一位形状像人的站在我面前。"},
{volumn:        27,chapter:         8,versenumber:        16,verse:"我又听见乌莱河两岸中有人声呼叫说：“加百列啊，要使此人明白这异象。”"},
{volumn:        27,chapter:         8,versenumber:        17,verse:"他便来到我所站的地方。他一来，我就惊慌俯伏在地；他对我说：“人子啊，你要明白，因为这是关乎末后的异象。”"},
{volumn:        27,chapter:         8,versenumber:        18,verse:"他与我说话的时候，我面伏在地沉睡；他就摸我，扶我站起来，"},
{volumn:        27,chapter:         8,versenumber:        19,verse:"说：“我要指示你恼怒临完必有的事，因为这是关乎末后的定期。"},
{volumn:        27,chapter:         8,versenumber:        20,verse:"你所看见双角的公绵羊，就是玛代和波斯王。"},
{volumn:        27,chapter:         8,versenumber:        21,verse:"那公山羊就是希腊王（希腊：原文是雅完；下同）；两眼当中的大角就是头一王。"},
{volumn:        27,chapter:         8,versenumber:        22,verse:"至于那折断了的角，在其根上又长出四角，这四角就是四国，必从这国里兴起来，只是权势都不及他。"},
{volumn:        27,chapter:         8,versenumber:        23,verse:"这四国末时，犯法的人罪恶满盈，必有一王兴起，面貌凶恶，能用双关的诈语。"},
{volumn:        27,chapter:         8,versenumber:        24,verse:"他的权柄必大，却不是因自己的能力；他必行非常的毁灭，事情顺利，任意而行；又必毁灭有能力的和圣民。"},
{volumn:        27,chapter:         8,versenumber:        25,verse:"他用权术成就手中的诡计，心里自高自大，在人坦然无备的时候，毁灭多人；又要站起来攻击万君之君，至终却非因人手而灭亡。"},
{volumn:        27,chapter:         8,versenumber:        26,verse:"所说二千三百日的异象是真的，但你要将这异象封住，因为关乎后来许多的日子。”"},
{volumn:        27,chapter:         8,versenumber:        27,verse:"于是我但以理昏迷不醒，病了数日，然后起来办理王的事务。我因这异象惊奇，却无人能明白其中的意思。"},
{volumn:        27,chapter:         9,versenumber:         1,verse:"玛代族亚哈随鲁的儿子大流士立为迦勒底国的王元年，"},
{volumn:        27,chapter:         9,versenumber:         2,verse:"就是他在位第一年，我但以理从书上得知耶和华的话临到先知耶利米，论耶路撒冷荒凉的年数，七十年为满。"},
{volumn:        27,chapter:         9,versenumber:         3,verse:"我便禁食，披麻蒙灰，定意向主　神祈祷恳求。"},
{volumn:        27,chapter:         9,versenumber:         4,verse:"我向耶和华我的　神祈祷、认罪，说：“主啊，大而可畏的　神，向爱主、守主诫命的人守约施慈爱。"},
{volumn:        27,chapter:         9,versenumber:         5,verse:"我们犯罪作孽，行恶叛逆，偏离你的诫命典章，"},
{volumn:        27,chapter:         9,versenumber:         6,verse:"没有听从你仆人众先知奉你名向我们君王、首领、列祖，和国中一切百姓所说的话。"},
{volumn:        27,chapter:         9,versenumber:         7,verse:"主啊，你是公义的，我们是脸上蒙羞的；因我们犹大人和耶路撒冷的居民，并以色列众人，或在近处，或在远处，被你赶到各国的人，都得罪了你，正如今日一样。"},
{volumn:        27,chapter:         9,versenumber:         8,verse:"主啊，我们和我们的君王、首领、列祖因得罪了你，就都脸上蒙羞。"},
{volumn:        27,chapter:         9,versenumber:         9,verse:"主我们的　神是怜悯饶恕人的，我们却违背了他，"},
{volumn:        27,chapter:         9,versenumber:        10,verse:"也没有听从耶和华我们　神的话，没有遵行他藉仆人众先知向我们所陈明的律法。"},
{volumn:        27,chapter:         9,versenumber:        11,verse:"以色列众人都犯了你的律法，偏行，不听从你的话；因此，在你仆人摩西律法上所写的咒诅和誓言都倾在我们身上，因我们得罪了　神。"},
{volumn:        27,chapter:         9,versenumber:        12,verse:"他使大灾祸临到我们，成就了警戒我们和审判我们官长的话；原来在普天之下未曾行过像在耶路撒冷所行的。"},
{volumn:        27,chapter:         9,versenumber:        13,verse:"这一切灾祸临到我们身上是照摩西律法上所写的，我们却没有求耶和华我们　神的恩典，使我们回头离开罪孽，明白你的真理。"},
{volumn:        27,chapter:         9,versenumber:        14,verse:"所以耶和华留意使这灾祸临到我们身上，因为耶和华我们的　神在他所行的事上都是公义；我们并没有听从他的话。"},
{volumn:        27,chapter:         9,versenumber:        15,verse:"主我们的　神啊，你曾用大能的手领你的子民出埃及地，使自己得了名，正如今日一样。我们犯了罪，作了恶。"},
{volumn:        27,chapter:         9,versenumber:        16,verse:"主啊，求你按你的大仁大义，使你的怒气和忿怒转离你的城耶路撒冷，就是你的圣山。耶路撒冷和你的子民，因我们的罪恶和我们列祖的罪孽被四围的人羞辱。"},
{volumn:        27,chapter:         9,versenumber:        17,verse:"我们的　神啊，现在求你垂听仆人的祈祷恳求，为自己使脸光照你荒凉的圣所。"},
{volumn:        27,chapter:         9,versenumber:        18,verse:"我的　神啊，求你侧耳而听，睁眼而看，眷顾我们荒凉之地和称为你名下的城。我们在你面前恳求，原不是因自己的义，乃因你的大怜悯。"},
{volumn:        27,chapter:         9,versenumber:        19,verse:"求主垂听，求主赦免，求主应允而行，为你自己不要迟延。我的　神啊，因这城和这民都是称为你名下的。”"},
{volumn:        27,chapter:         9,versenumber:        20,verse:"我说话，祷告，承认我的罪和本国之民以色列的罪，为我　神的圣山，在耶和华我　神面前恳求。"},
{volumn:        27,chapter:         9,versenumber:        21,verse:"我正祷告的时候，先前在异象中所见的那位加百列，奉命迅速飞来，约在献晚祭的时候，按手在我身上。"},
{volumn:        27,chapter:         9,versenumber:        22,verse:"他指教我说：“但以理啊，现在我出来要使你有智慧，有聪明。"},
{volumn:        27,chapter:         9,versenumber:        23,verse:"你初恳求的时候，就发出命令，我来告诉你，因你大蒙眷爱；所以你要思想明白这以下的事和异象。"},
{volumn:        27,chapter:         9,versenumber:        24,verse:"“为你本国之民和你圣城，已经定了七十个七。要止住罪过，除净罪恶，赎尽罪孽，引进（或译：彰显）永义，封住异象和预言，并膏至圣者（者：或译所）。"},
{volumn:        27,chapter:         9,versenumber:        25,verse:"你当知道，当明白，从出令重新建造耶路撒冷，直到有受膏君的时候，必有七个七和六十二个七。正在艰难的时候，耶路撒冷城连街带濠都必重新建造。"},
{volumn:        27,chapter:         9,versenumber:        26,verse:"过了六十二个七，那（或译：有）受膏者必被剪除，一无所有；必有一王的民来毁灭这城和圣所，至终必如洪水冲没。必有争战，一直到底，荒凉的事已经定了。"},
{volumn:        27,chapter:         9,versenumber:        27,verse:"一七之内，他必与许多人坚定盟约；一七之半，他必使祭祀与供献止息。那行毁坏可憎的（或译：使地荒凉的）如飞而来，并且有忿怒倾在那行毁坏的身上（或译：倾在那荒凉之地），直到所定的结局。”"},
{volumn:        27,chapter:        10,versenumber:         1,verse:"波斯王居鲁士第三年，有事显给称为伯提沙撒的但以理。这事是真的，是指着大争战；但以理通达这事，明白这异象。"},
{volumn:        27,chapter:        10,versenumber:         2,verse:"当那时，我但以理悲伤了三个七日。"},
{volumn:        27,chapter:        10,versenumber:         3,verse:"美味我没有吃，酒肉没有入我的口，也没有用油抹我的身，直到满了三个七日。"},
{volumn:        27,chapter:        10,versenumber:         4,verse:"正月二十四日，我在底格里斯大河边，"},
{volumn:        27,chapter:        10,versenumber:         5,verse:"举目观看，见有一人身穿细麻衣，腰束乌法精金带。"},
{volumn:        27,chapter:        10,versenumber:         6,verse:"他身体如水苍玉，面貌如闪电，眼目如火把，手和脚如光明的铜，说话的声音如大众的声音。"},
{volumn:        27,chapter:        10,versenumber:         7,verse:"这异象惟有我但以理一人看见，同着我的人没有看见。他们却大大战兢，逃跑隐藏，"},
{volumn:        27,chapter:        10,versenumber:         8,verse:"只剩下我一人。我见了这大异象便浑身无力，面貌失色，毫无气力。"},
{volumn:        27,chapter:        10,versenumber:         9,verse:"我却听见他说话的声音，一听见就面伏在地沉睡了。"},
{volumn:        27,chapter:        10,versenumber:        10,verse:"忽然，有一手按在我身上，使我用膝和手掌支持微起。"},
{volumn:        27,chapter:        10,versenumber:        11,verse:"他对我说：“大蒙眷爱的但以理啊，要明白我与你所说的话，只管站起来，因为我现在奉差遣来到你这里。”他对我说这话，我便战战兢兢地立起来。"},
{volumn:        27,chapter:        10,versenumber:        12,verse:"他就说：“但以理啊，不要惧怕！因为从你第一日专心求明白将来的事，又在你　神面前刻苦己心，你的言语已蒙应允；我是因你的言语而来。"},
{volumn:        27,chapter:        10,versenumber:        13,verse:"但波斯国的魔君拦阻我二十一日。忽然有大君（就是天使长；二十一节同）中的一位米迦勒来帮助我，我就停留在波斯诸王那里。"},
{volumn:        27,chapter:        10,versenumber:        14,verse:"现在我来，要使你明白本国之民日后必遭遇的事，因为这异象关乎后来许多的日子。”"},
{volumn:        27,chapter:        10,versenumber:        15,verse:"他向我这样说，我就脸面朝地，哑口无声。"},
{volumn:        27,chapter:        10,versenumber:        16,verse:"不料，有一位像人的，摸我的嘴唇，我便开口向那站在我面前的说：“我主啊，因见这异象，我大大愁苦，毫无气力。"},
{volumn:        27,chapter:        10,versenumber:        17,verse:"我主的仆人怎能与我主说话呢？我一见异象就浑身无力，毫无气息。”"},
{volumn:        27,chapter:        10,versenumber:        18,verse:"有一位形状像人的又摸我，使我有力量。"},
{volumn:        27,chapter:        10,versenumber:        19,verse:"他说：“大蒙眷爱的人哪，不要惧怕，愿你平安！你总要坚强。”他一向我说话，我便觉得有力量，说：“我主请说，因你使我有力量。”"},
{volumn:        27,chapter:        10,versenumber:        20,verse:"他就说：“你知道我为何来见你吗？现在我要回去与波斯的魔君争战，我去后，希腊（原文是雅完）的魔君必来。"},
{volumn:        27,chapter:        10,versenumber:        21,verse:"但我要将那录在真确书上的事告诉你。除了你们的大君米迦勒之外，没有帮助我抵挡这两魔君的。”"},
{volumn:        27,chapter:        11,versenumber:         1,verse:"又说：“当玛代王大流士元年，我曾起来扶助米迦勒，使他坚强。”"},
{volumn:        27,chapter:        11,versenumber:         2,verse:"现在我将真事指示你：“波斯还有三王兴起，第四王必富足远胜诸王。他因富足成为强盛，就必激动大众攻击希腊国。"},
{volumn:        27,chapter:        11,versenumber:         3,verse:"必有一个勇敢的王兴起，执掌大权，随意而行。"},
{volumn:        27,chapter:        11,versenumber:         4,verse:"他兴起的时候，他的国必破裂，向天的四方（方：原文是风）分开，却不归他的后裔，治国的权势也都不及他；因为他的国必被拔出，归与他后裔之外的人。"},
{volumn:        27,chapter:        11,versenumber:         5,verse:"“南方的王必强盛，他将帅中必有一个比他更强盛，执掌权柄，他的权柄甚大。"},
{volumn:        27,chapter:        11,versenumber:         6,verse:"过些年后，他们必互相连合，南方王的女儿必就了北方王来立约；但这女子帮助之力存立不住，王和他所倚靠之力也不能存立。这女子和引导她来的，并生她的，以及当时扶助她的，都必交与死地。"},
{volumn:        27,chapter:        11,versenumber:         7,verse:"但这女子的本家（原文是根）必另生一子（子：原文是枝）继续王位，他必率领军队进入北方王的保障，攻击他们，而且得胜；"},
{volumn:        27,chapter:        11,versenumber:         8,verse:"并将他们的神像和铸成的偶像，与金银的宝器掠到埃及去。数年之内，他不去攻击北方的王。"},
{volumn:        27,chapter:        11,versenumber:         9,verse:"北方的王（原文是他）必入南方王的国，却要仍回本地。"},
{volumn:        27,chapter:        11,versenumber:        10,verse:"“北方王（原文是他）的二子必动干戈，招聚许多军兵。这军兵前去，如洪水泛滥，又必再去争战，直到南方王的保障。"},
{volumn:        27,chapter:        11,versenumber:        11,verse:"南方王必发烈怒，出来与北方王争战，摆列大军；北方王的军兵必交付他手。"},
{volumn:        27,chapter:        11,versenumber:        12,verse:"他的众军高傲，他的心也必自高；他虽使数万人仆倒，却不得常胜。"},
{volumn:        27,chapter:        11,versenumber:        13,verse:"“北方王必回来摆列大军，比先前的更多。满了所定的年数，他必率领大军，带极多的军装来。"},
{volumn:        27,chapter:        11,versenumber:        14,verse:"那时，必有许多人起来攻击南方王，并且你本国的强暴人必兴起，要应验那异象，他们却要败亡。"},
{volumn:        27,chapter:        11,versenumber:        15,verse:"北方王必来筑垒攻取坚固城；南方的军兵必站立不住，就是选择的精兵（原文是民）也无力站住。"},
{volumn:        27,chapter:        11,versenumber:        16,verse:"来攻击他的，必任意而行，无人在北方王（原文是他）面前站立得住。他必站在那荣美之地，用手施行毁灭。"},
{volumn:        27,chapter:        11,versenumber:        17,verse:"“他必定意用全国之力而来，立公正的约，照约而行，将自己的女儿给南方王为妻，想要败坏他（或译：埃及），这计却不得成就，与自己毫无益处。"},
{volumn:        27,chapter:        11,versenumber:        18,verse:"其后他必转回夺取了许多海岛。但有一大帅，除掉他令人受的羞辱，并且使这羞辱归他本身。"},
{volumn:        27,chapter:        11,versenumber:        19,verse:"他就必转向本地的保障，却要绊跌仆倒，归于无有。"},
{volumn:        27,chapter:        11,versenumber:        20,verse:"“那时，必有一人兴起接续他为王，使横征暴敛的人通行国中的荣美地。这王不多日就必灭亡，却不因忿怒，也不因争战。”"},
{volumn:        27,chapter:        11,versenumber:        21,verse:"“必有一个卑鄙的人兴起接续为王，人未曾将国的尊荣给他，他却趁人坦然无备的时候，用谄媚的话得国。"},
{volumn:        27,chapter:        11,versenumber:        22,verse:"必有无数的军兵势如洪水，在他面前冲没败坏；同盟的君也必如此。"},
{volumn:        27,chapter:        11,versenumber:        23,verse:"与那君结盟之后，他必行诡诈，因为他必上来以微小的军（原文是民）成为强盛。"},
{volumn:        27,chapter:        11,versenumber:        24,verse:"趁人坦然无备的时候，他必来到国中极肥美之地，行他列祖和他列祖之祖所未曾行的，将掳物、掠物，和财宝散给众人，又要设计攻打保障，然而这都是暂时的。"},
{volumn:        27,chapter:        11,versenumber:        25,verse:"“他必奋勇向前，率领大军攻击南方王；南方王也必以极大极强的军兵与他争战，却站立不住，因为有人设计谋害南方王。"},
{volumn:        27,chapter:        11,versenumber:        26,verse:"吃王膳的，必败坏他；他的军队必被冲没，而且被杀的甚多。"},
{volumn:        27,chapter:        11,versenumber:        27,verse:"至于这二王，他们心怀恶计，同席说谎，计谋却不成就；因为到了定期，事就了结。"},
{volumn:        27,chapter:        11,versenumber:        28,verse:"北方王（原文是他）必带许多财宝回往本国，他的心反对圣约，任意而行，回到本地。"},
{volumn:        27,chapter:        11,versenumber:        29,verse:"“到了定期，他必返回，来到南方。后一次却不如前一次，"},
{volumn:        27,chapter:        11,versenumber:        30,verse:"因为基提战船必来攻击他，他就丧胆而回，又要恼恨圣约，任意而行；他必回来联络背弃圣约的人。"},
{volumn:        27,chapter:        11,versenumber:        31,verse:"他必兴兵，这兵必亵渎圣地，就是保障，除掉常献的燔祭，设立那行毁坏可憎的。"},
{volumn:        27,chapter:        11,versenumber:        32,verse:"作恶违背圣约的人，他必用巧言勾引；惟独认识　神的子民必刚强行事。"},
{volumn:        27,chapter:        11,versenumber:        33,verse:"民间的智慧人必训诲多人；然而他们多日必倒在刀下，或被火烧，或被掳掠抢夺。"},
{volumn:        27,chapter:        11,versenumber:        34,verse:"他们仆倒的时候，稍得扶助，却有许多人用谄媚的话亲近他们。"},
{volumn:        27,chapter:        11,versenumber:        35,verse:"智慧人中有些仆倒的，为要熬炼其余的人，使他们清净洁白，直到末了；因为到了定期，事就了结。"},
{volumn:        27,chapter:        11,versenumber:        36,verse:"“王必任意而行，自高自大，超过所有的神，又用奇异的话攻击万神之神。他必行事亨通，直到主的忿怒完毕，因为所定的事必然成就。"},
{volumn:        27,chapter:        11,versenumber:        37,verse:"他必不顾他列祖的神，也不顾妇女所羡慕的神，无论何神他都不顾；因为他必自大，高过一切。"},
{volumn:        27,chapter:        11,versenumber:        38,verse:"他倒要敬拜保障的　神，用金、银、宝石和可爱之物敬奉他列祖所不认识的神。"},
{volumn:        27,chapter:        11,versenumber:        39,verse:"他必靠外邦神的帮助，攻破最坚固的保障。凡承认他的，他必将荣耀加给他们，使他们管辖许多人，又为贿赂分地与他们。"},
{volumn:        27,chapter:        11,versenumber:        40,verse:"“到末了，南方王要与他交战。北方王必用战车、马兵，和许多战船，势如暴风来攻击他，也必进入列国，如洪水泛滥。"},
{volumn:        27,chapter:        11,versenumber:        41,verse:"又必进入那荣美之地，有许多国就被倾覆，但以东人、摩押人，和一大半亚扪人必脱离他的手。"},
{volumn:        27,chapter:        11,versenumber:        42,verse:"他必伸手攻击列国；埃及地也不得脱离。"},
{volumn:        27,chapter:        11,versenumber:        43,verse:"他必把持埃及的金银财宝和各样的宝物。利比亚人和古实人都必跟从他。"},
{volumn:        27,chapter:        11,versenumber:        44,verse:"但从东方和北方必有消息扰乱他，他就大发烈怒出去，要将多人杀灭净尽。"},
{volumn:        27,chapter:        11,versenumber:        45,verse:"他必在海和荣美的圣山中间设立他如宫殿的帐幕；然而到了他的结局，必无人能帮助他。”"},
{volumn:        27,chapter:        12,versenumber:         1,verse:"“那时，保佑你本国之民的天使长（原文是大君）米迦勒必站起来，并且有大艰难，从有国以来直到此时，没有这样的。你本国的民中，凡名录在册上的，必得拯救。"},
{volumn:        27,chapter:        12,versenumber:         2,verse:"睡在尘埃中的，必有多人复醒。其中有得永生的，有受羞辱永远被憎恶的。"},
{volumn:        27,chapter:        12,versenumber:         3,verse:"智慧人必发光如同天上的光；那使多人归义的，必发光如星，直到永永远远。"},
{volumn:        27,chapter:        12,versenumber:         4,verse:"但以理啊，你要隐藏这话，封闭这书，直到末时。必有多人来往奔跑（或译：切心研究），知识就必增长。”"},
{volumn:        27,chapter:        12,versenumber:         5,verse:"我但以理观看，见另有两个人站立：一个在河这边，一个在河那边。"},
{volumn:        27,chapter:        12,versenumber:         6,verse:"有一个问那站在河水以上、穿细麻衣的说：“这奇异的事到几时才应验呢？”"},
{volumn:        27,chapter:        12,versenumber:         7,verse:"我听见那站在河水以上、穿细麻衣的，向天举起左右手，指着活到永远的主起誓说：“要到一载、二载、半载，打破圣民权力的时候，这一切事就都应验了。”"},
{volumn:        27,chapter:        12,versenumber:         8,verse:"我听见这话，却不明白，就说：“我主啊，这些事的结局是怎样呢？”"},
{volumn:        27,chapter:        12,versenumber:         9,verse:"他说：“但以理啊，你只管去；因为这话已经隐藏封闭，直到末时。"},
{volumn:        27,chapter:        12,versenumber:        10,verse:"必有许多人使自己清净洁白，且被熬炼；但恶人仍必行恶，一切恶人都不明白，惟独智慧人能明白。"},
{volumn:        27,chapter:        12,versenumber:        11,verse:"从除掉常献的燔祭，并设立那行毁坏可憎之物的时候，必有一千二百九十日。"},
{volumn:        27,chapter:        12,versenumber:        12,verse:"等到一千三百三十五日的，那人便为有福。"},
{volumn:        27,chapter:        12,versenumber:        13,verse:"“你且去等候结局，因为你必安歇。到了末期，你必起来，享受你的福分。”"},
{volumn:        28,chapter:         1,versenumber:         1,verse:"当乌西雅、约坦、亚哈斯、希西家作犹大王，约阿施的儿子耶罗波安作以色列王的时候，耶和华的话临到备利的儿子何西阿。"},
{volumn:        28,chapter:         1,versenumber:         2,verse:"耶和华初次与何西阿说话，对他说：“你去娶淫妇为妻，也收那从淫乱所生的儿女；因为这地大行淫乱，离弃耶和华。”"},
{volumn:        28,chapter:         1,versenumber:         3,verse:"于是，何西阿去娶了滴拉音的女儿歌篾。这妇人怀孕，给他生了一个儿子。"},
{volumn:        28,chapter:         1,versenumber:         4,verse:"耶和华对何西阿说：“给他起名叫耶斯列；因为再过片时，我必讨耶户家在耶斯列杀人流血的罪，也必使以色列家的国灭绝。"},
{volumn:        28,chapter:         1,versenumber:         5,verse:"到那日，我必在耶斯列平原折断以色列的弓。”"},
{volumn:        28,chapter:         1,versenumber:         6,verse:"歌篾又怀孕生了一个女儿，耶和华对何西阿说：“给她起名叫罗·路哈玛（就是不蒙怜悯的意思）；因为我必不再怜悯以色列家，决不赦免他们。"},
{volumn:        28,chapter:         1,versenumber:         7,verse:"我却要怜悯犹大家，使他们靠耶和华他们的　神得救，不使他们靠弓、刀、争战、马匹，与马兵得救。”"},
{volumn:        28,chapter:         1,versenumber:         8,verse:"歌篾给罗·路哈玛断奶以后，又怀孕生了一个儿子。"},
{volumn:        28,chapter:         1,versenumber:         9,verse:"耶和华说：“给他起名叫罗·阿米（就是非我民的意思）；因为你们不作我的子民，我也不作你们的　神。”"},
{volumn:        28,chapter:         1,versenumber:        10,verse:"“然而，以色列的人数必如海沙，不可量，不可数。从前在什么地方对他们说：‘你们不是我的子民’，将来在那里必对他们说：‘你们是永生　神的儿子’。"},
{volumn:        28,chapter:         1,versenumber:        11,verse:"犹大人和以色列人必一同聚集，为自己立一个首领，从这地上去（或译：从被掳之地上来），因为耶斯列的日子必为大日。”"},
{volumn:        28,chapter:         2,versenumber:         1,verse:"“你们要称你们的弟兄为阿米（就是我民的意思），称你们的姐妹为路哈玛（就是蒙怜悯的意思）。”"},
{volumn:        28,chapter:         2,versenumber:         2,verse:"“你们要与你们的母亲大大争辩；因为她不是我的妻子，我也不是她的丈夫。叫她除掉脸上的淫像和胸间的淫态，"},
{volumn:        28,chapter:         2,versenumber:         3,verse:"免得我剥她的衣服，使她赤体，与才生的时候一样，使她如旷野，如干旱之地，因渴而死。"},
{volumn:        28,chapter:         2,versenumber:         4,verse:"我必不怜悯她的儿女，因为他们是从淫乱而生的。"},
{volumn:        28,chapter:         2,versenumber:         5,verse:"他们的母亲行了淫乱，怀他们的母做了可羞耻的事，因为她说：‘我要随从所爱的；我的饼、水、羊毛、麻、油、酒都是他们给的。’"},
{volumn:        28,chapter:         2,versenumber:         6,verse:"因此，我必用荆棘堵塞她的道，筑墙挡住她，使她找不着路。"},
{volumn:        28,chapter:         2,versenumber:         7,verse:"她必追随所爱的，却追不上；她必寻找他们，却寻不见，便说：‘我要归回前夫，因我那时的光景比如今还好。’"},
{volumn:        28,chapter:         2,versenumber:         8,verse:"她不知道是我给她五谷、新酒，和油，又加增她的金银；她却以此供奉（或译：制造）巴力。"},
{volumn:        28,chapter:         2,versenumber:         9,verse:"因此到了收割的日子，出酒的时候，我必将我的五谷、新酒收回，也必将她应当遮体的羊毛和麻夺回来。"},
{volumn:        28,chapter:         2,versenumber:        10,verse:"如今我必在她所爱的眼前显露她的丑态；必无人能救她脱离我的手。"},
{volumn:        28,chapter:         2,versenumber:        11,verse:"我也必使她的宴乐、节期、月朔、安息日，并她的一切大会都止息了。"},
{volumn:        28,chapter:         2,versenumber:        12,verse:"我也必毁坏她的葡萄树和无花果树，就是她说‘这是我所爱的给我为赏赐’的。我必使这些树变为荒林，为田野的走兽所吃。"},
{volumn:        28,chapter:         2,versenumber:        13,verse:"我必追讨她素日给诸巴力烧香的罪；那时她佩带耳环和别样妆饰，随从她所爱的，却忘记我。这是耶和华说的。”"},
{volumn:        28,chapter:         2,versenumber:        14,verse:"“后来我必劝导她，领她到旷野，对她说安慰的话。"},
{volumn:        28,chapter:         2,versenumber:        15,verse:"她从那里出来，我必赐她葡萄园，又赐她亚割谷作为指望的门。她必在那里应声（或译：歌唱），与幼年的日子一样，与从埃及地上来的时候相同。”"},
{volumn:        28,chapter:         2,versenumber:        16,verse:"耶和华说：“那日你必称呼我伊施（就是我夫的意思），不再称呼我巴力（就是我主的意思）；"},
{volumn:        28,chapter:         2,versenumber:        17,verse:"因为我必从我民的口中除掉诸巴力的名号，这名号不再提起。"},
{volumn:        28,chapter:         2,versenumber:        18,verse:"当那日，我必为我的民，与田野的走兽和空中的飞鸟，并地上的昆虫立约；又必在国中折断弓刀，止息争战，使他们安然躺卧。"},
{volumn:        28,chapter:         2,versenumber:        19,verse:"我必聘你永远归我为妻，以仁义、公平、慈爱、怜悯聘你归我；"},
{volumn:        28,chapter:         2,versenumber:        20,verse:"也以诚实聘你归我，你就必认识我耶和华。”"},
{volumn:        28,chapter:         2,versenumber:        21,verse:"耶和华说：“那日我必应允，我必应允天，天必应允地；"},
{volumn:        28,chapter:         2,versenumber:        22,verse:"地必应允五谷、新酒，和油，这些必应允耶斯列民（耶斯列就是神栽种的意思）。"},
{volumn:        28,chapter:         2,versenumber:        23,verse:"我必将她种在这地。素不蒙怜悯的，我必怜悯；本非我民的，我必对他说：‘你是我的民’；他必说：‘你是我的　神。’”"},
{volumn:        28,chapter:         3,versenumber:         1,verse:"耶和华对我说：“你再去爱一个淫妇，就是她情人所爱的；好像以色列人，虽然偏向别神，喜爱葡萄饼，耶和华还是爱他们。”"},
{volumn:        28,chapter:         3,versenumber:         2,verse:"我便用银子十五舍客勒，大麦一贺梅珥半，买她归我。"},
{volumn:        28,chapter:         3,versenumber:         3,verse:"我对她说：“你当多日为我独居，不可行淫，不可归别人为妻，我向你也必这样。”"},
{volumn:        28,chapter:         3,versenumber:         4,verse:"以色列人也必多日独居，无君王，无首领，无祭祀，无柱像，无以弗得，无家中的神像。"},
{volumn:        28,chapter:         3,versenumber:         5,verse:"后来以色列人必归回（或译：回心转意），寻求他们的　神耶和华和他们的王大卫。在末后的日子，必以敬畏的心归向耶和华，领受他的恩惠。"},
{volumn:        28,chapter:         4,versenumber:         1,verse:"“以色列人哪，你们当听耶和华的话。耶和华与这地的居民争辩，因这地上无诚实，无良善，无人认识　神。"},
{volumn:        28,chapter:         4,versenumber:         2,verse:"但起假誓，不践前言，杀害，偷盗，奸淫，行强暴，杀人流血，接连不断。"},
{volumn:        28,chapter:         4,versenumber:         3,verse:"因此，这地悲哀，其上的民、田野的兽、空中的鸟必都衰微，海中的鱼也必消灭。”"},
{volumn:        28,chapter:         4,versenumber:         4,verse:"“然而，人都不必争辩，也不必指责，因为这民与抗拒祭司的人一样。"},
{volumn:        28,chapter:         4,versenumber:         5,verse:"你这祭司必日间跌倒；先知也必夜间与你一同跌倒；我必灭绝你的母亲。"},
{volumn:        28,chapter:         4,versenumber:         6,verse:"我的民因无知识而灭亡。你弃掉知识，我也必弃掉你，使你不再给我作祭司。你既忘了你　神的律法，我也必忘记你的儿女。"},
{volumn:        28,chapter:         4,versenumber:         7,verse:"祭司越发增多，就越发得罪我；我必使他们的荣耀变为羞辱。"},
{volumn:        28,chapter:         4,versenumber:         8,verse:"他们吃我民的赎罪祭，满心愿意我民犯罪。"},
{volumn:        28,chapter:         4,versenumber:         9,verse:"将来民如何，祭司也必如何；我必因他们所行的惩罚他们，照他们所做的报应他们。"},
{volumn:        28,chapter:         4,versenumber:        10,verse:"他们吃，却不得饱；行淫，而不得立后；因为他们离弃耶和华，不遵他的命。”"},
{volumn:        28,chapter:         4,versenumber:        11,verse:"“奸淫和酒，并新酒，夺去人的心。"},
{volumn:        28,chapter:         4,versenumber:        12,verse:"我的民求问木偶，以为木杖能指示他们；因为他们的淫心使他们失迷，他们就行淫离弃　神，不守约束，"},
{volumn:        28,chapter:         4,versenumber:        13,verse:"在各山顶，各高冈的橡树、杨树、栗树之下，献祭烧香，因为树影美好。“所以，你们的女儿淫乱，你们的新妇（或译：儿妇；下同）行淫。"},
{volumn:        28,chapter:         4,versenumber:        14,verse:"你们的女儿淫乱，你们的新妇行淫，我却不惩罚她们；因为你们自己离群与娼妓同居，与妓女一同献祭。这无知的民必致倾倒。"},
{volumn:        28,chapter:         4,versenumber:        15,verse:"“以色列啊，你虽然行淫，犹大却不可犯罪。不要往吉甲去，不要上到伯·亚文，也不要指着永生的耶和华起誓。"},
{volumn:        28,chapter:         4,versenumber:        16,verse:"以色列倔强，犹如倔强的母牛；现在耶和华要放他们，如同放羊羔在宽阔之地。"},
{volumn:        28,chapter:         4,versenumber:        17,verse:"“以法莲亲近偶像，任凭他吧！"},
{volumn:        28,chapter:         4,versenumber:        18,verse:"他们所喝的已经发酸，他们时常行淫，他们的官长最爱羞耻的事。"},
{volumn:        28,chapter:         4,versenumber:        19,verse:"风把他们裹在翅膀里；他们因所献的祭必致蒙羞。”"},
{volumn:        28,chapter:         5,versenumber:         1,verse:"“众祭司啊，要听我的话！以色列家啊，要留心听！王家啊，要侧耳而听！审判要临到你们，因你们在米斯巴如网罗，在他泊山如铺张的网。"},
{volumn:        28,chapter:         5,versenumber:         2,verse:"这些悖逆的人肆行杀戮，罪孽极深；我却斥责他们众人。"},
{volumn:        28,chapter:         5,versenumber:         3,verse:"以法莲为我所知；以色列不能向我隐藏。以法莲哪，现在你行淫了，以色列被玷污了。”"},
{volumn:        28,chapter:         5,versenumber:         4,verse:"“他们所行的使他们不能归向　神；因有淫心在他们里面，他们也不认识耶和华。"},
{volumn:        28,chapter:         5,versenumber:         5,verse:"以色列的骄傲当面见证自己。故此，以色列和以法莲必因自己的罪孽跌倒；犹大也必与他们一同跌倒。"},
{volumn:        28,chapter:         5,versenumber:         6,verse:"他们必牵着牛羊去寻求耶和华，却寻不见；他已经转去离开他们。"},
{volumn:        28,chapter:         5,versenumber:         7,verse:"他们向耶和华行事诡诈，生了私子。到了月朔，他们与他们的地业必被吞灭。”"},
{volumn:        28,chapter:         5,versenumber:         8,verse:"“你们当在基比亚吹角，在拉玛吹号，在伯·亚文吹出大声，说：‘便雅悯哪，有仇敌在你后头！"},
{volumn:        28,chapter:         5,versenumber:         9,verse:"在责罚的日子，以法莲必变为荒场。’我在以色列支派中，指示将来必成的事。"},
{volumn:        28,chapter:         5,versenumber:        10,verse:"犹大的首领如同挪移地界的人，我必将忿怒倒在他们身上，如水一般。"},
{volumn:        28,chapter:         5,versenumber:        11,verse:"以法莲因乐从人的命令，就受欺压，被审判压碎。"},
{volumn:        28,chapter:         5,versenumber:        12,verse:"我使以法莲如虫蛀之物，使犹大家如朽烂之木。"},
{volumn:        28,chapter:         5,versenumber:        13,verse:"“以法莲见自己有病，犹大见自己有伤，他们就打发人往亚述去见耶雷布王，他却不能医治你们，不能治好你们的伤。"},
{volumn:        28,chapter:         5,versenumber:        14,verse:"我必向以法莲如狮子，向犹大家如少壮狮子。我必撕裂而去，我要夺去，无人搭救。"},
{volumn:        28,chapter:         5,versenumber:        15,verse:"“我要回到原处，等他们自觉有罪（或译：承认己罪），寻求我面；他们在急难的时候必切切寻求我。”"},
{volumn:        28,chapter:         6,versenumber:         1,verse:"来吧，我们归向耶和华！他撕裂我们，也必医治；他打伤我们，也必缠裹。"},
{volumn:        28,chapter:         6,versenumber:         2,verse:"过两天他必使我们苏醒，第三天他必使我们兴起，我们就在他面前得以存活。"},
{volumn:        28,chapter:         6,versenumber:         3,verse:"我们务要认识耶和华，竭力追求认识他。他出现确如晨光；他必临到我们像甘雨，像滋润田地的春雨。"},
{volumn:        28,chapter:         6,versenumber:         4,verse:"主说：“以法莲哪，我可向你怎样行呢？犹大啊，我可向你怎样做呢？因为你们的良善如同早晨的云雾，又如速散的甘露。"},
{volumn:        28,chapter:         6,versenumber:         5,verse:"因此，我藉先知砍伐他们，以我口中的话杀戮他们；我施行的审判如光发出。"},
{volumn:        28,chapter:         6,versenumber:         6,verse:"我喜爱良善（或译：怜恤），不喜爱祭祀；喜爱认识　神，胜于燔祭。"},
{volumn:        28,chapter:         6,versenumber:         7,verse:"“他们却如亚当背约，在境内向我行事诡诈。"},
{volumn:        28,chapter:         6,versenumber:         8,verse:"基列是作孽之人的城，被血沾染。"},
{volumn:        28,chapter:         6,versenumber:         9,verse:"强盗成群，怎样埋伏杀人，祭司结党，也照样在示剑的路上杀戮，行了邪恶。"},
{volumn:        28,chapter:         6,versenumber:        10,verse:"在以色列家，我见了可憎的事；在以法莲那里有淫行，以色列被玷污。"},
{volumn:        28,chapter:         6,versenumber:        11,verse:"“犹大啊，我使被掳之民归回的时候，必有为你所命定的收场。”"},
{volumn:        28,chapter:         7,versenumber:         1,verse:"“我想医治以色列的时候，以法莲的罪孽和撒玛利亚的罪恶就显露出来。他们行事虚谎，内有贼人入室偷窃，外有强盗成群骚扰。"},
{volumn:        28,chapter:         7,versenumber:         2,verse:"他们心里并不思想我记念他们的一切恶；他们所行的现在缠绕他们，都在我面前。”"},
{volumn:        28,chapter:         7,versenumber:         3,verse:"“他们行恶使君王欢喜，说谎使首领喜乐。"},
{volumn:        28,chapter:         7,versenumber:         4,verse:"他们都是行淫的，像火炉被烤饼的烧热，从抟面到发面的时候，暂不使火着旺。"},
{volumn:        28,chapter:         7,versenumber:         5,verse:"在我们王宴乐的日子，首领因酒的烈性成病；王与亵慢人拉手。"},
{volumn:        28,chapter:         7,versenumber:         6,verse:"首领埋伏的时候，心中热如火炉，就如烤饼的整夜睡卧，到了早晨火气炎炎。"},
{volumn:        28,chapter:         7,versenumber:         7,verse:"众民也热如火炉，烧灭他们的官长。他们的君王都仆倒而死；他们中间无一人求告我。”"},
{volumn:        28,chapter:         7,versenumber:         8,verse:"“以法莲与列邦人搀杂；以法莲是没有翻过的饼。"},
{volumn:        28,chapter:         7,versenumber:         9,verse:"外邦人吞吃他劳力得来的，他却不知道；头发斑白，他也不觉得。"},
{volumn:        28,chapter:         7,versenumber:        10,verse:"以色列的骄傲当面见证自己，虽遭遇这一切，他们仍不归向耶和华他们的　神，也不寻求他。"},
{volumn:        28,chapter:         7,versenumber:        11,verse:"以法莲好像鸽子愚蠢无知；他们求告埃及，投奔亚述。"},
{volumn:        28,chapter:         7,versenumber:        12,verse:"他们去的时候，我必将我的网撒在他们身上；我要打下他们，如同空中的鸟。我必按他们会众所听见的惩罚他们。"},
{volumn:        28,chapter:         7,versenumber:        13,verse:"他们因离弃我，必定有祸；因违背我，必被毁灭。我虽要救赎他们，他们却向我说谎。"},
{volumn:        28,chapter:         7,versenumber:        14,verse:"“他们并不诚心哀求我，乃在床上呼号；他们为求五谷新酒聚集，仍然悖逆我。"},
{volumn:        28,chapter:         7,versenumber:        15,verse:"我虽教导他们，坚固他们的膀臂，他们竟图谋抗拒我。"},
{volumn:        28,chapter:         7,versenumber:        16,verse:"他们归向，却不归向至上者；他们如同翻背的弓。他们的首领必因舌头的狂傲倒在刀下；这在埃及地必作人的讥笑。”"},
{volumn:        28,chapter:         8,versenumber:         1,verse:"“你用口吹角吧！敌人如鹰来攻打耶和华的家；因为这民违背我的约，干犯我的律法。"},
{volumn:        28,chapter:         8,versenumber:         2,verse:"他们必呼叫我说：‘我的　神啊，我们以色列认识你了。’"},
{volumn:        28,chapter:         8,versenumber:         3,verse:"以色列丢弃良善（或译：福分）；仇敌必追逼他。"},
{volumn:        28,chapter:         8,versenumber:         4,verse:"他们立君王，却不由我；他们立首领，我却不认。他们用金银为自己制造偶像，以致被剪除。"},
{volumn:        28,chapter:         8,versenumber:         5,verse:"撒玛利亚啊，耶和华已经丢弃你的牛犊；我的怒气向拜牛犊的人发作。他们到几时方能无罪呢？"},
{volumn:        28,chapter:         8,versenumber:         6,verse:"这牛犊出于以色列，是匠人所造的，并不是神。撒玛利亚的牛犊必被打碎。"},
{volumn:        28,chapter:         8,versenumber:         7,verse:"他们所种的是风，所收的是暴风；所种的不成禾稼，就是发苗也不结实；即便结实，外邦人必吞吃。"},
{volumn:        28,chapter:         8,versenumber:         8,verse:"以色列被吞吃；现今在列国中，好像人不喜悦的器皿。"},
{volumn:        28,chapter:         8,versenumber:         9,verse:"他们投奔亚述，如同独行的野驴；以法莲贿买朋党。"},
{volumn:        28,chapter:         8,versenumber:        10,verse:"他们虽在列邦中贿买人，现在我却要聚集惩罚他们；他们因君王和首领所加的重担日渐衰微。"},
{volumn:        28,chapter:         8,versenumber:        11,verse:"“以法莲增添祭坛取罪；因此，祭坛使他犯罪。"},
{volumn:        28,chapter:         8,versenumber:        12,verse:"我为他写了律法万条，他却以为与他毫无关涉。"},
{volumn:        28,chapter:         8,versenumber:        13,verse:"至于献给我的祭物，他们自食其肉，耶和华却不悦纳他们。现在必记念他们的罪孽，追讨他们的罪恶；他们必归回埃及。"},
{volumn:        28,chapter:         8,versenumber:        14,verse:"以色列忘记造他的主，建造宫殿；犹大多造坚固城，我却要降火焚烧他的城邑，烧灭其中的宫殿。”"},
{volumn:        28,chapter:         9,versenumber:         1,verse:"以色列啊，不要像外邦人欢喜快乐；因为你行邪淫离弃你的　神，在各谷场上如妓女喜爱赏赐。"},
{volumn:        28,chapter:         9,versenumber:         2,verse:"谷场和酒榨都不够以色列人使用；新酒也必缺乏。"},
{volumn:        28,chapter:         9,versenumber:         3,verse:"他们必不得住耶和华的地；以法莲却要归回埃及，必在亚述吃不洁净的食物。"},
{volumn:        28,chapter:         9,versenumber:         4,verse:"他们必不得向耶和华奠酒，即便奠酒也不蒙悦纳。他们的祭物必如居丧者的食物，凡吃的必被玷污；因他们的食物只为自己的口腹，必不奉入耶和华的殿。"},
{volumn:        28,chapter:         9,versenumber:         5,verse:"在大会的日子，到耶和华的节期，你们怎样行呢？"},
{volumn:        28,chapter:         9,versenumber:         6,verse:"看哪，他们逃避灾难；埃及人必收殓他们的尸首，摩弗人必葬埋他们的骸骨。他们用银子做的美物上必长蒺藜；他们的帐棚中必生荆棘。"},
{volumn:        28,chapter:         9,versenumber:         7,verse:"以色列人必知道降罚的日子临近，报应的时候来到。民说：作先知的是愚昧；受灵感的是狂妄，皆因他们多多作孽，大怀怨恨。"},
{volumn:        28,chapter:         9,versenumber:         8,verse:"以法莲曾作我　神守望的；至于先知，在他一切的道上作为捕鸟人的网罗，在他　神的家中怀怨恨。"},
{volumn:        28,chapter:         9,versenumber:         9,verse:"以法莲深深地败坏，如在基比亚的日子一样。耶和华必记念他们的罪孽，追讨他们的罪恶。"},
{volumn:        28,chapter:         9,versenumber:        10,verse:"主说：“我遇见以色列如葡萄在旷野；我看见你们的列祖如无花果树上春季初熟的果子。他们却来到巴力·毗珥专拜那可羞耻的，就成为可憎恶的，与他们所爱的一样。"},
{volumn:        28,chapter:         9,versenumber:        11,verse:"至于以法莲人，他们的荣耀必如鸟飞去，必不生产，不怀胎，不成孕。"},
{volumn:        28,chapter:         9,versenumber:        12,verse:"纵然养大儿女，我却必使他们丧子，甚至不留一个。我离弃他们，他们就有祸了。”"},
{volumn:        28,chapter:         9,versenumber:        13,verse:"我看以法莲如推罗栽于美地。以法莲却要将自己的儿女带出来，交与行杀戮的人。"},
{volumn:        28,chapter:         9,versenumber:        14,verse:"耶和华啊，求你加给他们，加什么呢？要使他们胎坠乳干。"},
{volumn:        28,chapter:         9,versenumber:        15,verse:"耶和华说：“他们一切的恶事都在吉甲；我在那里憎恶他们。因他们所行的恶，我必从我地上赶出他们去，不再怜爱他们；他们的首领都是悖逆的。"},
{volumn:        28,chapter:         9,versenumber:        16,verse:"以法莲受责罚，根本枯干，必不能结果，即或生产，我必杀他们所生的爱子。”"},
{volumn:        28,chapter:         9,versenumber:        17,verse:"我的　神必弃绝他们，因为他们不听从他；他们也必飘流在列国中。"},
{volumn:        28,chapter:        10,versenumber:         1,verse:"以色列是茂盛的葡萄树，结果繁多。果子越多，就越增添祭坛；地土越肥美，就越造美丽的柱像。"},
{volumn:        28,chapter:        10,versenumber:         2,verse:"他们心怀二意，现今要定为有罪。耶和华必拆毁他们的祭坛，毁坏他们的柱像。"},
{volumn:        28,chapter:        10,versenumber:         3,verse:"他们必说：“我们没有王，因为我们不敬畏耶和华。王能为我们做什么呢？”"},
{volumn:        28,chapter:        10,versenumber:         4,verse:"他们为立约说谎言，起假誓；因此，灾罚如苦菜滋生在田间的犁沟中。"},
{volumn:        28,chapter:        10,versenumber:         5,verse:"撒玛利亚的居民必因伯·亚文的牛犊惊恐；崇拜牛犊的民和喜爱牛犊的祭司都必因荣耀离开它，为它悲哀。"},
{volumn:        28,chapter:        10,versenumber:         6,verse:"人必将牛犊带到亚述当作礼物，献给耶雷布王。以法莲必蒙羞；以色列必因自己的计谋惭愧。"},
{volumn:        28,chapter:        10,versenumber:         7,verse:"至于撒玛利亚，她的王必灭没，如水面的沫子一样。"},
{volumn:        28,chapter:        10,versenumber:         8,verse:"伯·亚文的邱坛，就是以色列取罪的地方必被毁灭；荆棘和蒺藜必长在他们的祭坛上。他们必对大山说：“遮盖我们！”对小山说：“倒在我们身上！”"},
{volumn:        28,chapter:        10,versenumber:         9,verse:"“以色列啊，你从基比亚的日子以来时常犯罪。你们的先人曾站在那里，现今住基比亚的人以为攻击罪孽之辈的战事临不到自己。"},
{volumn:        28,chapter:        10,versenumber:        10,verse:"我必随意惩罚他们。他们为两样的罪所缠；列邦的民必聚集攻击他们。"},
{volumn:        28,chapter:        10,versenumber:        11,verse:"“以法莲是驯良的母牛犊，喜爱踹谷，我却将轭加在它肥美的颈项上，我要使以法莲拉套（或译：被骑）。犹大必耕田；雅各必耙地。"},
{volumn:        28,chapter:        10,versenumber:        12,verse:"你们要为自己栽种公义，就能收割慈爱。现今正是寻求耶和华的时候；你们要开垦荒地，等他临到，使公义如雨降在你们身上。"},
{volumn:        28,chapter:        10,versenumber:        13,verse:"“你们耕种的是奸恶，收割的是罪孽，吃的是谎话的果子。因你倚靠自己的行为，仰赖勇士众多，"},
{volumn:        28,chapter:        10,versenumber:        14,verse:"所以在这民中必有哄嚷之声，你一切的保障必被拆毁，就如沙勒幔在争战的日子拆毁伯·亚比勒，将其中的母子一同摔死。"},
{volumn:        28,chapter:        10,versenumber:        15,verse:"因他们的大恶，伯特利必使你们遭遇如此。到了黎明，以色列的王必全然灭绝。”"},
{volumn:        28,chapter:        11,versenumber:         1,verse:"“以色列年幼的时候，我爱他，就从埃及召出我的儿子来。"},
{volumn:        28,chapter:        11,versenumber:         2,verse:"先知越发招呼他们，他们越发走开，向诸巴力献祭，给雕刻的偶像烧香。"},
{volumn:        28,chapter:        11,versenumber:         3,verse:"我原教导以法莲行走，用膀臂抱着他们，他们却不知道是我医治他们。"},
{volumn:        28,chapter:        11,versenumber:         4,verse:"我用慈绳（慈：原文是人的）爱索牵引他们；我待他们如人放松牛的两腮夹板，把粮食放在他们面前。"},
{volumn:        28,chapter:        11,versenumber:         5,verse:"“他们必不归回埃及地，亚述人却要作他们的王，因他们不肯归向我。"},
{volumn:        28,chapter:        11,versenumber:         6,verse:"刀剑必临到他们的城邑，毁坏门闩，把人吞灭，都因他们随从自己的计谋。"},
{volumn:        28,chapter:        11,versenumber:         7,verse:"我的民偏要背道离开我；众先知虽然招呼他们归向至上的主，却无人尊崇主。"},
{volumn:        28,chapter:        11,versenumber:         8,verse:"“以法莲哪，我怎能舍弃你？以色列啊，我怎能弃绝你？我怎能使你如押玛？怎能使你如洗扁？我回心转意，我的怜爱大大发动。"},
{volumn:        28,chapter:        11,versenumber:         9,verse:"我必不发猛烈的怒气，也不再毁灭以法莲。因我是　神，并非世人，是你们中间的圣者；我必不在怒中临到你们。"},
{volumn:        28,chapter:        11,versenumber:        10,verse:"“耶和华必如狮子吼叫，子民必跟随他。他一吼叫，他们就从西方急速而来。"},
{volumn:        28,chapter:        11,versenumber:        11,verse:"他们必如雀鸟从埃及急速而来，又如鸽子从亚述地来到。我必使他们住自己的房屋。这是耶和华说的。”"},
{volumn:        28,chapter:        11,versenumber:        12,verse:"“以法莲用谎话，以色列家用诡计围绕我；犹大却靠　神掌权，向圣者有忠心（或译：犹大向　神，向诚实的圣者犹疑不定）。”"},
{volumn:        28,chapter:        12,versenumber:         1,verse:"以法莲吃风，且追赶东风，时常增添虚谎和强暴，与亚述立约，把油送到埃及。"},
{volumn:        28,chapter:        12,versenumber:         2,verse:"耶和华与犹大争辩，必照雅各所行的惩罚他，按他所做的报应他。"},
{volumn:        28,chapter:        12,versenumber:         3,verse:"他在腹中抓住哥哥的脚跟，壮年的时候与　神较力，"},
{volumn:        28,chapter:        12,versenumber:         4,verse:"与天使较力，并且得胜，哭泣恳求，在伯特利遇见耶和华。耶和华万军之　神在那里晓谕我们以色列人；耶和华是他可记念的名。"},
{volumn:        28,chapter:        12,versenumber:         5,verse:""},
{volumn:        28,chapter:        12,versenumber:         6,verse:"所以你当归向你的　神，谨守仁爱、公平，常常等候你的　神。"},
{volumn:        28,chapter:        12,versenumber:         7,verse:"“以法莲是商人，手里有诡诈的天平，爱行欺骗。"},
{volumn:        28,chapter:        12,versenumber:         8,verse:"以法莲说：‘我果然成了富足，得了财宝；我所劳碌得来的，人必不见有什么不义，可算为罪的。’"},
{volumn:        28,chapter:        12,versenumber:         9,verse:"自从你出埃及地以来，我就是耶和华你的　神；我必使你再住帐棚，如在大会的日子一样。"},
{volumn:        28,chapter:        12,versenumber:        10,verse:"“我已晓谕众先知，并且加增默示，藉先知设立比喻。”"},
{volumn:        28,chapter:        12,versenumber:        11,verse:"基列人没有罪孽吗？他们全然是虚假的。人在吉甲献牛犊为祭，他们的祭坛好像田间犁沟中的乱堆。"},
{volumn:        28,chapter:        12,versenumber:        12,verse:"从前雅各逃到亚兰地，以色列为得妻服侍人，为得妻与人放羊。"},
{volumn:        28,chapter:        12,versenumber:        13,verse:"耶和华藉先知领以色列从埃及上来；以色列也藉先知而得保存。"},
{volumn:        28,chapter:        12,versenumber:        14,verse:"以法莲大大惹动主怒，所以他流血的罪必归在他身上。主必将那因以法莲所受的羞辱归还他。"},
{volumn:        28,chapter:        13,versenumber:         1,verse:"从前以法莲说话，人都战兢，他在以色列中居处高位；但他在侍奉巴力的事上犯罪就死了。"},
{volumn:        28,chapter:        13,versenumber:         2,verse:"现今他们罪上加罪，用银子为自己铸造偶像，就是照自己的聪明制造，都是匠人的工作。有人论说，献祭的人可以向牛犊亲嘴。"},
{volumn:        28,chapter:        13,versenumber:         3,verse:"因此，他们必如早晨的云雾，又如速散的甘露，像场上的糠秕被狂风吹去，又像烟气腾于窗外。"},
{volumn:        28,chapter:        13,versenumber:         4,verse:"“自从你出埃及地以来，我就是耶和华你的　神。在我以外，你不可认识别神；除我以外并没有救主。"},
{volumn:        28,chapter:        13,versenumber:         5,verse:"我曾在旷野干旱之地认识你。"},
{volumn:        28,chapter:        13,versenumber:         6,verse:"这些民照我所赐的食物得了饱足；既得饱足，心就高傲，忘记了我。"},
{volumn:        28,chapter:        13,versenumber:         7,verse:"因此，我向他们如狮子，又如豹伏在道旁。"},
{volumn:        28,chapter:        13,versenumber:         8,verse:"我遇见他们必像丢崽子的母熊，撕裂他们的胸膛（或译：心膜）。在那里，我必像母狮吞吃他们；野兽必撕裂他们。"},
{volumn:        28,chapter:        13,versenumber:         9,verse:"“以色列啊，你与我反对，就是反对帮助你的，自取败坏。"},
{volumn:        28,chapter:        13,versenumber:        10,verse:"你曾求我说：‘给我立王和首领。’现在你的王在哪里呢？治理你的在哪里呢？让他在你所有的城中拯救你吧！"},
{volumn:        28,chapter:        13,versenumber:        11,verse:"我在怒气中将王赐你，又在烈怒中将王废去。"},
{volumn:        28,chapter:        13,versenumber:        12,verse:"“以法莲的罪孽包裹；他的罪恶收藏。"},
{volumn:        28,chapter:        13,versenumber:        13,verse:"产妇的疼痛必临到他身上；他是无智慧之子，到了产期不当迟延。"},
{volumn:        28,chapter:        13,versenumber:        14,verse:"我必救赎他们脱离阴间，救赎他们脱离死亡。死亡啊，你的灾害在哪里呢？阴间哪，你的毁灭在哪里呢？在我眼前绝无后悔之事。"},
{volumn:        28,chapter:        13,versenumber:        15,verse:"他在弟兄中虽然茂盛，必有东风刮来，就是耶和华的风从旷野上来。他的泉源必干；他的源头必竭；仇敌必掳掠他所积蓄的一切宝器。"},
{volumn:        28,chapter:        13,versenumber:        16,verse:"撒玛利亚必担当自己的罪，因为悖逆她的　神。她必倒在刀下；婴孩必被摔死；孕妇必被剖开。”"},
{volumn:        28,chapter:        14,versenumber:         1,verse:"以色列啊，你要归向耶和华你的　神；你是因自己的罪孽跌倒了。"},
{volumn:        28,chapter:        14,versenumber:         2,verse:"当归向耶和华，用言语祷告他说：“求你除净罪孽，悦纳善行；这样，我们就把嘴唇的祭代替牛犊献上。"},
{volumn:        28,chapter:        14,versenumber:         3,verse:"我们不向亚述求救，不骑埃及的马，也不再对我们手所造的说：‘你是我们的　神。’因为孤儿在你耶和华那里得蒙怜悯。”"},
{volumn:        28,chapter:        14,versenumber:         4,verse:"“我必医治他们背道的病，甘心爱他们；因为我的怒气向他们转消。"},
{volumn:        28,chapter:        14,versenumber:         5,verse:"我必向以色列如甘露；他必如百合花开放，如黎巴嫩的树木扎根。"},
{volumn:        28,chapter:        14,versenumber:         6,verse:"他的枝条必延长；他的荣华如橄榄树；他的香气如黎巴嫩的香柏树。"},
{volumn:        28,chapter:        14,versenumber:         7,verse:"曾住在他荫下的必归回，发旺如五谷，开花如葡萄树。他的香气如黎巴嫩的酒。"},
{volumn:        28,chapter:        14,versenumber:         8,verse:"以法莲必说：‘我与偶像还有什么关涉呢？’我耶和华回答他，也必顾念他。我如青翠的松树；你的果子从我而得。”"},
{volumn:        28,chapter:        14,versenumber:         9,verse:"谁是智慧人？可以明白这些事；谁是通达人？可以知道这一切。因为，耶和华的道是正直的；义人必在其中行走，罪人却在其上跌倒。"},
{volumn:        29,chapter:         1,versenumber:         1,verse:"耶和华的话临到毗土珥的儿子约珥。"},
{volumn:        29,chapter:         1,versenumber:         2,verse:"老年人哪，当听我的话；国中的居民哪，都要侧耳而听。在你们的日子，或你们列祖的日子，曾有这样的事吗？"},
{volumn:        29,chapter:         1,versenumber:         3,verse:"你们要将这事传与子，子传与孙，孙传与后代。"},
{volumn:        29,chapter:         1,versenumber:         4,verse:"剪虫剩下的，蝗虫来吃；蝗虫剩下的，蝻子来吃；蝻子剩下的，蚂蚱来吃。"},
{volumn:        29,chapter:         1,versenumber:         5,verse:"酒醉的人哪，要清醒哭泣；好酒的人哪，都要为甜酒哀号，因为从你们的口中断绝了。"},
{volumn:        29,chapter:         1,versenumber:         6,verse:"有一队蝗虫（原文是民）又强盛又无数，侵犯我的地；它的牙齿如狮子的牙齿，大牙如母狮的大牙。"},
{volumn:        29,chapter:         1,versenumber:         7,verse:"它毁坏我的葡萄树，剥了我无花果树的皮，剥尽而丢弃，使枝条露白。"},
{volumn:        29,chapter:         1,versenumber:         8,verse:"我的民哪，你当哀号，像处女腰束麻布，为幼年的丈夫哀号。"},
{volumn:        29,chapter:         1,versenumber:         9,verse:"素祭和奠祭从耶和华的殿中断绝；侍奉耶和华的祭司都悲哀。"},
{volumn:        29,chapter:         1,versenumber:        10,verse:"田荒凉，地悲哀；因为五谷毁坏，新酒干竭，油也缺乏。"},
{volumn:        29,chapter:         1,versenumber:        11,verse:"农夫啊，你们要惭愧；修理葡萄园的啊，你们要哀号；因为大麦小麦与田间的庄稼都灭绝了。"},
{volumn:        29,chapter:         1,versenumber:        12,verse:"葡萄树枯干；无花果树衰残。石榴树、棕树、苹果树，连田野一切的树木也都枯干；众人的喜乐尽都消灭。"},
{volumn:        29,chapter:         1,versenumber:        13,verse:"祭司啊，你们当腰束麻布痛哭；伺候祭坛的啊，你们要哀号；侍奉我　神的啊，你们要来披上麻布过夜，因为素祭和奠祭从你们　神的殿中断绝了。"},
{volumn:        29,chapter:         1,versenumber:        14,verse:"你们要分定禁食的日子，宣告严肃会，招聚长老和国中的一切居民到耶和华你们　神的殿，向耶和华哀求。"},
{volumn:        29,chapter:         1,versenumber:        15,verse:"哀哉！耶和华的日子临近了。这日来到，好像毁灭从全能者来到。"},
{volumn:        29,chapter:         1,versenumber:        16,verse:"粮食不是在我们眼前断绝了吗？欢喜快乐不是从我们　神的殿中止息了吗？"},
{volumn:        29,chapter:         1,versenumber:        17,verse:"谷种在土块下朽烂；仓也荒凉，廪也破坏；因为五谷枯干了。"},
{volumn:        29,chapter:         1,versenumber:        18,verse:"牲畜哀鸣；牛群混乱，因为无草；羊群也受了困苦。"},
{volumn:        29,chapter:         1,versenumber:        19,verse:"耶和华啊，我向你求告，因为火烧灭旷野的草场；火焰烧尽田野的树木。"},
{volumn:        29,chapter:         1,versenumber:        20,verse:"田野的走兽向你发喘；因为溪水干涸，火也烧灭旷野的草场。"},
{volumn:        29,chapter:         2,versenumber:         1,verse:"你们要在锡安吹角，在我圣山吹出大声。国中的居民都要发颤；因为耶和华的日子将到，已经临近。"},
{volumn:        29,chapter:         2,versenumber:         2,verse:"那日是黑暗、幽冥、密云、乌黑的日子，好像晨光铺满山岭。有一队蝗虫（原文是民）又大又强；从来没有这样的，以后直到万代也必没有。"},
{volumn:        29,chapter:         2,versenumber:         3,verse:"它们前面如火烧灭，后面如火焰烧尽。未到以前，地如伊甸园；过去以后，成了荒凉的旷野；没有一样能躲避它们的。"},
{volumn:        29,chapter:         2,versenumber:         4,verse:"它们的形状如马，奔跑如马兵。"},
{volumn:        29,chapter:         2,versenumber:         5,verse:"在山顶蹦跳的响声如车辆的响声，又如火焰烧碎秸的响声，好像强盛的民摆阵预备打仗。"},
{volumn:        29,chapter:         2,versenumber:         6,verse:"它们一来，众民伤恸，脸都变色。"},
{volumn:        29,chapter:         2,versenumber:         7,verse:"它们如勇士奔跑，像战士爬城；各都步行，不乱队伍。"},
{volumn:        29,chapter:         2,versenumber:         8,verse:"彼此并不拥挤，向前各行其路，直闯兵器，不偏左右。"},
{volumn:        29,chapter:         2,versenumber:         9,verse:"它们蹦上城，蹿上墙，爬上房屋，进入窗户如同盗贼。"},
{volumn:        29,chapter:         2,versenumber:        10,verse:"它们一来，地震天动，日月昏暗，星宿无光。"},
{volumn:        29,chapter:         2,versenumber:        11,verse:"耶和华在他军旅前发声，他的队伍甚大；成就他命的是强盛者。因为耶和华的日子大而可畏，谁能当得起呢？"},
{volumn:        29,chapter:         2,versenumber:        12,verse:"耶和华说：“虽然如此，你们应当禁食、哭泣、悲哀，一心归向我。”"},
{volumn:        29,chapter:         2,versenumber:        13,verse:"你们要撕裂心肠，不撕裂衣服。归向耶和华你们的　神；因为他有恩典，有怜悯，不轻易发怒，有丰盛的慈爱，并且后悔不降所说的灾。"},
{volumn:        29,chapter:         2,versenumber:        14,verse:"或者他转意后悔，留下余福，就是留下献给耶和华你们　神的素祭和奠祭，也未可知。"},
{volumn:        29,chapter:         2,versenumber:        15,verse:"你们要在锡安吹角，分定禁食的日子，宣告严肃会。"},
{volumn:        29,chapter:         2,versenumber:        16,verse:"聚集众民，使会众自洁：招聚老者，聚集孩童和吃奶的；使新郎出离洞房，新妇出离内室。"},
{volumn:        29,chapter:         2,versenumber:        17,verse:"侍奉耶和华的祭司要在廊子和祭坛中间哭泣，说：“耶和华啊，求你顾惜你的百姓，不要使你的产业受羞辱，列邦管辖他们。为何容列国的人说：‘他们的　神在哪里呢？’”"},
{volumn:        29,chapter:         2,versenumber:        18,verse:"耶和华就为自己的地发热心，怜恤他的百姓。"},
{volumn:        29,chapter:         2,versenumber:        19,verse:"耶和华应允他的百姓说：“我必赐给你们五谷、新酒，和油，使你们饱足；我也不再使你们受列国的羞辱；"},
{volumn:        29,chapter:         2,versenumber:        20,verse:"却要使北方来的军队远离你们，将他们赶到干旱荒废之地：前队赶入东海，后队赶入西海；因为他们所行的大恶（原文是事），臭气上升，腥味腾空。”"},
{volumn:        29,chapter:         2,versenumber:        21,verse:"“地土啊，不要惧怕；要欢喜快乐，因为耶和华行了大事。"},
{volumn:        29,chapter:         2,versenumber:        22,verse:"田野的走兽啊，不要惧怕；因为，旷野的草发生，树木结果，无花果树、葡萄树也都效力。"},
{volumn:        29,chapter:         2,versenumber:        23,verse:"“锡安的民哪，你们要快乐，为耶和华你们的　神欢喜；因他赐给你们合宜的秋雨，为你们降下甘霖，就是秋雨、春雨，和先前一样。"},
{volumn:        29,chapter:         2,versenumber:        24,verse:"“禾场必满了麦子；酒榨与油榨必有新酒和油盈溢。"},
{volumn:        29,chapter:         2,versenumber:        25,verse:"我打发到你们中间的大军队，就是蝗虫、蝻子、蚂蚱、剪虫，那些年所吃的，我要补还你们。"},
{volumn:        29,chapter:         2,versenumber:        26,verse:"“你们必多吃而得饱足，就赞美为你们行奇妙事之耶和华你们　神的名。我的百姓必永远不至羞愧。"},
{volumn:        29,chapter:         2,versenumber:        27,verse:"你们必知道我是在以色列中间，又知道我是耶和华你们的　神；在我以外并无别神。我的百姓必永远不致羞愧。”"},
{volumn:        29,chapter:         2,versenumber:        28,verse:"“以后，我要将我的灵浇灌凡有血气的。你们的儿女要说预言；你们的老年人要做异梦，少年人要见异象。"},
{volumn:        29,chapter:         2,versenumber:        29,verse:"在那些日子，我要将我的灵浇灌我的仆人和使女。"},
{volumn:        29,chapter:         2,versenumber:        30,verse:"“在天上地下，我要显出奇事，有血，有火，有烟柱。"},
{volumn:        29,chapter:         2,versenumber:        31,verse:"日头要变为黑暗，月亮要变为血，这都在耶和华大而可畏的日子未到以前。"},
{volumn:        29,chapter:         2,versenumber:        32,verse:"到那时候，凡求告耶和华名的就必得救；因为照耶和华所说的，在锡安山，耶路撒冷必有逃脱的人，在剩下的人中必有耶和华所召的。”"},
{volumn:        29,chapter:         3,versenumber:         1,verse:"“到那日，我使犹大和耶路撒冷被掳之人归回的时候，"},
{volumn:        29,chapter:         3,versenumber:         2,verse:"我要聚集万民，带他们下到约沙法谷，在那里施行审判；因为他们将我的百姓，就是我的产业以色列，分散在列国中，又分取我的地土，"},
{volumn:        29,chapter:         3,versenumber:         3,verse:"且为我的百姓拈阄，将童子换妓女，卖童女买酒喝。"},
{volumn:        29,chapter:         3,versenumber:         4,verse:"“推罗、西顿，和非利士四境的人哪，你们与我何干？你们要报复我吗？若报复我，我必使报应速速归到你们的头上。"},
{volumn:        29,chapter:         3,versenumber:         5,verse:"你们既然夺取我的金银，又将我可爱的宝物带入你们宫殿（或译：庙中），"},
{volumn:        29,chapter:         3,versenumber:         6,verse:"并将犹大人和耶路撒冷人卖给希腊人（原文是雅完人），使他们远离自己的境界。"},
{volumn:        29,chapter:         3,versenumber:         7,verse:"我必激动他们离开你们所卖到之地，又必使报应归到你们的头上。"},
{volumn:        29,chapter:         3,versenumber:         8,verse:"我必将你们的儿女卖在犹大人的手中，他们必卖给远方示巴国的人。这是耶和华说的。”"},
{volumn:        29,chapter:         3,versenumber:         9,verse:"当在万民中宣告说：要预备打仗；激动勇士，使一切战士上前来。"},
{volumn:        29,chapter:         3,versenumber:        10,verse:"要将犁头打成刀剑，将镰刀打成戈矛；软弱的要说：我有勇力。"},
{volumn:        29,chapter:         3,versenumber:        11,verse:"四围的列国啊，你们要速速地来，一同聚集。耶和华啊，求你使你的大能者降临。"},
{volumn:        29,chapter:         3,versenumber:        12,verse:"“万民都当兴起，上到约沙法谷；因为我必坐在那里，审判四围的列国。"},
{volumn:        29,chapter:         3,versenumber:        13,verse:"开镰吧！因为庄稼熟了；践踏吧！因为酒榨满了。酒池盈溢；他们的罪恶甚大。"},
{volumn:        29,chapter:         3,versenumber:        14,verse:"“许多许多的人在断定谷，因为耶和华的日子临近断定谷。"},
{volumn:        29,chapter:         3,versenumber:        15,verse:"日月昏暗，星宿无光。”"},
{volumn:        29,chapter:         3,versenumber:        16,verse:"“耶和华必从锡安吼叫，从耶路撒冷发声，天地就震动。耶和华却要作他百姓的避难所，作以色列人的保障。"},
{volumn:        29,chapter:         3,versenumber:        17,verse:"“你们就知道我是耶和华你们的　神，且又住在锡安我的圣山。那时，耶路撒冷必成为圣；外邦人不再从其中经过。"},
{volumn:        29,chapter:         3,versenumber:        18,verse:"“到那日，大山要滴甜酒；小山要流奶子；犹大溪河都有水流。必有泉源从耶和华的殿中流出来，滋润什亭谷。"},
{volumn:        29,chapter:         3,versenumber:        19,verse:"“埃及必然荒凉，以东变为凄凉的旷野，都因向犹大人所行的强暴，又因在本地流无辜人的血。"},
{volumn:        29,chapter:         3,versenumber:        20,verse:"但犹大必存到永远；耶路撒冷必存到万代。"},
{volumn:        29,chapter:         3,versenumber:        21,verse:"我未曾报复（或译：洗除；下同）流血的罪，现在我要报复，因为耶和华住在锡安。”"},
{volumn:        30,chapter:         1,versenumber:         1,verse:"当犹大王乌西雅，以色列王约阿施的儿子耶罗波安在位的时候，大地震前二年，提哥亚牧人中的阿摩司得默示论以色列。"},
{volumn:        30,chapter:         1,versenumber:         2,verse:"他说：“耶和华必从锡安吼叫，从耶路撒冷发声；牧人的草场要悲哀；迦密的山顶要枯干。”"},
{volumn:        30,chapter:         1,versenumber:         3,verse:"耶和华如此说：“大马士革三番四次地犯罪，我必不免去她的刑罚；因为她以打粮食的铁器打过基列。"},
{volumn:        30,chapter:         1,versenumber:         4,verse:"我却要降火在哈薛的家中，烧灭便·哈达的宫殿。"},
{volumn:        30,chapter:         1,versenumber:         5,verse:"我必折断大马士革的门闩，剪除亚文平原的居民和伯·伊甸掌权的。亚兰人必被掳到吉珥。”这是耶和华说的。"},
{volumn:        30,chapter:         1,versenumber:         6,verse:"耶和华如此说：“迦萨三番四次地犯罪，我必不免去她的刑罚；因为她掳掠众民交给以东。"},
{volumn:        30,chapter:         1,versenumber:         7,verse:"我却要降火在迦萨的城内，烧灭其中的宫殿。"},
{volumn:        30,chapter:         1,versenumber:         8,verse:"我必剪除亚实突的居民和亚实基伦掌权的，也必反手攻击以革伦。非利士人所余剩的必都灭亡。”这是主耶和华说的。"},
{volumn:        30,chapter:         1,versenumber:         9,verse:"耶和华如此说：“推罗三番四次地犯罪，我必不免去她的刑罚；因为她将众民交给以东，并不记念弟兄的盟约。"},
{volumn:        30,chapter:         1,versenumber:        10,verse:"我却要降火在推罗的城内，烧灭其中的宫殿。”"},
{volumn:        30,chapter:         1,versenumber:        11,verse:"耶和华如此说：“以东三番四次地犯罪，我必不免去她的刑罚；因为她拿刀追赶兄弟，毫无怜悯，发怒撕裂，永怀忿怒。"},
{volumn:        30,chapter:         1,versenumber:        12,verse:"我却要降火在提幔，烧灭波斯拉的宫殿。”"},
{volumn:        30,chapter:         1,versenumber:        13,verse:"耶和华如此说：“亚扪人三番四次地犯罪，我必不免去他们的刑罚；因为他们剖开基列的孕妇，扩张自己的境界。"},
{volumn:        30,chapter:         1,versenumber:        14,verse:"我却要在争战呐喊的日子，旋风狂暴的时候，点火在拉巴的城内，烧灭其中的宫殿。"},
{volumn:        30,chapter:         1,versenumber:        15,verse:"他们的王和首领必一同被掳去。”这是耶和华说的。"},
{volumn:        30,chapter:         2,versenumber:         1,verse:"耶和华如此说：“摩押三番四次地犯罪，我必不免去她的刑罚；因为她将以东王的骸骨焚烧成灰。"},
{volumn:        30,chapter:         2,versenumber:         2,verse:"我却要降火在摩押，烧灭加略的宫殿。摩押必在哄嚷呐喊吹角之中死亡。"},
{volumn:        30,chapter:         2,versenumber:         3,verse:"我必剪除摩押中的审判者，将其中的一切首领和他一同杀戮。”这是耶和华说的。"},
{volumn:        30,chapter:         2,versenumber:         4,verse:"耶和华如此说：“犹大人三番四次地犯罪，我必不免去他们的刑罚；因为他们厌弃耶和华的训诲，不遵守他的律例。他们列祖所随从虚假的偶像使他们走迷了。"},
{volumn:        30,chapter:         2,versenumber:         5,verse:"我却要降火在犹大，烧灭耶路撒冷的宫殿。”"},
{volumn:        30,chapter:         2,versenumber:         6,verse:"耶和华如此说：“以色列人三番四次地犯罪，我必不免去他们的刑罚；因他们为银子卖了义人，为一双鞋卖了穷人。"},
{volumn:        30,chapter:         2,versenumber:         7,verse:"他们见穷人头上所蒙的灰也都垂涎，阻碍谦卑人的道路。父子同一个女子行淫，亵渎我的圣名。"},
{volumn:        30,chapter:         2,versenumber:         8,verse:"他们在各坛旁铺人所当的衣服，卧在其上，又在他们神的庙中喝受罚之人的酒。"},
{volumn:        30,chapter:         2,versenumber:         9,verse:"“我从以色列人面前除灭亚摩利人。他虽高大如香柏树，坚固如橡树，我却上灭他的果子，下绝他的根本。"},
{volumn:        30,chapter:         2,versenumber:        10,verse:"我也将你们从埃及地领上来，在旷野引导你们四十年，使你们得亚摩利人之地为业。"},
{volumn:        30,chapter:         2,versenumber:        11,verse:"我从你们子弟中兴起先知，又从你们少年人中兴起拿细耳人。以色列人哪，不是这样吗？”这是耶和华说的。"},
{volumn:        30,chapter:         2,versenumber:        12,verse:"“你们却给拿细耳人酒喝，嘱咐先知说：‘不要说预言。’"},
{volumn:        30,chapter:         2,versenumber:        13,verse:"看哪，在你们所住之地，我必压你们，如同装满禾捆的车压物一样。"},
{volumn:        30,chapter:         2,versenumber:        14,verse:"快跑的不能逃脱；有力的不能用力；刚勇的也不能自救。"},
{volumn:        30,chapter:         2,versenumber:        15,verse:"拿弓的不能站立；腿快的不能逃脱；骑马的也不能自救。"},
{volumn:        30,chapter:         2,versenumber:        16,verse:"到那日，勇士中最有胆量的，必赤身逃跑。”这是耶和华说的。"},
{volumn:        30,chapter:         3,versenumber:         1,verse:"“以色列人哪，你们全家是我从埃及地领上来的，当听耶和华攻击你们的话："},
{volumn:        30,chapter:         3,versenumber:         2,verse:"在地上万族中，我只认识你们；因此，我必追讨你们的一切罪孽。”"},
{volumn:        30,chapter:         3,versenumber:         3,verse:"“二人若不同心，岂能同行呢？"},
{volumn:        30,chapter:         3,versenumber:         4,verse:"狮子若非抓食，岂能在林中咆哮呢？少壮狮子若无所得，岂能从洞中发声呢？"},
{volumn:        30,chapter:         3,versenumber:         5,verse:"若没有机槛，雀鸟岂能陷在网罗里呢？网罗若无所得，岂能从地上翻起呢？"},
{volumn:        30,chapter:         3,versenumber:         6,verse:"城中若吹角，百姓岂不惊恐呢？灾祸若临到一城，岂非耶和华所降的吗？"},
{volumn:        30,chapter:         3,versenumber:         7,verse:"主耶和华若不将奥秘指示他的仆人众先知，就一无所行。"},
{volumn:        30,chapter:         3,versenumber:         8,verse:"狮子吼叫，谁不惧怕呢？主耶和华发命，谁能不说预言呢？”"},
{volumn:        30,chapter:         3,versenumber:         9,verse:"“要在亚实突的宫殿中和埃及地的宫殿里传扬说：你们要聚集在撒玛利亚的山上，就看见城中有何等大的扰乱与欺压的事。"},
{volumn:        30,chapter:         3,versenumber:        10,verse:"那些以强暴抢夺财物、积蓄在自己家中的人不知道行正直的事。”这是耶和华说的。"},
{volumn:        30,chapter:         3,versenumber:        11,verse:"所以主耶和华如此说：“敌人必来围攻这地，使你的势力衰微，抢掠你的家宅。”"},
{volumn:        30,chapter:         3,versenumber:        12,verse:"耶和华如此说：“牧人怎样从狮子口中抢回两条羊腿或半个耳朵，住撒玛利亚的以色列人躺卧在床角上或铺绣花毯的榻上，他们得救也不过如此。”"},
{volumn:        30,chapter:         3,versenumber:        13,verse:"主耶和华万军之　神说：“当听这话，警戒雅各家。"},
{volumn:        30,chapter:         3,versenumber:        14,verse:"我讨以色列罪的日子，也要讨伯特利祭坛的罪；坛角必被砍下，坠落于地。"},
{volumn:        30,chapter:         3,versenumber:        15,verse:"我要拆毁过冬和过夏的房屋。象牙的房屋也必毁灭；高大的房屋都归无有。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:         1,verse:"“你们住撒玛利亚山如巴珊母牛的啊，当听我的话！你们欺负贫寒的，压碎穷乏的，对家主说：‘拿酒来，我们喝吧！’"},
{volumn:        30,chapter:         4,versenumber:         2,verse:"主耶和华指着自己的圣洁起誓说：日子快到，人必用钩子将你们钩去，用鱼钩将你们余剩的钩去。"},
{volumn:        30,chapter:         4,versenumber:         3,verse:"你们各人必从破口直往前行，投入哈门。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:         4,verse:"“以色列人哪，任你们往伯特利去犯罪，到吉甲加增罪过；每日早晨献上你们的祭物，每三日奉上你们的十分之一。"},
{volumn:        30,chapter:         4,versenumber:         5,verse:"任你们献有酵的感谢祭，把甘心祭宣传报告给众人，因为是你们所喜爱的。”这是主耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:         6,verse:"“我使你们在一切城中牙齿干净，在你们各处粮食缺乏，你们仍不归向我。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:         7,verse:"“在收割的前三月，我使雨停止，不降在你们那里；我降雨在这城，不降雨在那城；这块地有雨，那块地无雨；无雨的就枯干了。"},
{volumn:        30,chapter:         4,versenumber:         8,verse:"这样，两三城的人凑到一城去找水，却喝不足；你们仍不归向我。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:         9,verse:"“我以旱风、霉烂攻击你们，你们园中许多菜蔬、葡萄树、无花果树、橄榄树都被剪虫所吃；你们仍不归向我。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:        10,verse:"“我降瘟疫在你们中间，像在埃及一样；用刀杀戮你们的少年人，使你们的马匹被掳掠，营中尸首的臭气扑鼻；你们仍不归向我。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:        11,verse:"“我倾覆你们中间的城邑，如同我从前倾覆所多玛、蛾摩拉一样，使你们好像从火中抽出来的一根柴；你们仍不归向我。”这是耶和华说的。"},
{volumn:        30,chapter:         4,versenumber:        12,verse:"“以色列啊，我必向你如此行；以色列啊，我既这样行，你当预备迎见你的　神。"},
{volumn:        30,chapter:         4,versenumber:        13,verse:"那创山、造风、将心意指示人、使晨光变为幽暗、脚踏在地之高处的，他的名是耶和华万军之　神。”"},
{volumn:        30,chapter:         5,versenumber:         1,verse:"以色列家啊，要听我为你们所作的哀歌："},
{volumn:        30,chapter:         5,versenumber:         2,verse:"以色列民（原文是处女）跌倒，不得再起；躺在地上，无人搀扶。"},
{volumn:        30,chapter:         5,versenumber:         3,verse:"主耶和华如此说：“以色列家的城发出一千兵的，只剩一百；发出一百的，只剩十个。”"},
{volumn:        30,chapter:         5,versenumber:         4,verse:"耶和华向以色列家如此说：“你们要寻求我，就必存活。"},
{volumn:        30,chapter:         5,versenumber:         5,verse:"不要往伯特利寻求，不要进入吉甲，不要过到别是巴；因为吉甲必被掳掠，伯特利也必归于无有。”"},
{volumn:        30,chapter:         5,versenumber:         6,verse:"要寻求耶和华，就必存活，免得他在约瑟家像火发出，在伯特利焚烧，无人扑灭。"},
{volumn:        30,chapter:         5,versenumber:         7,verse:"你们这使公平变为茵陈、将公义丢弃于地的，"},
{volumn:        30,chapter:         5,versenumber:         8,verse:"要寻求那造昴星和参星，使死荫变为晨光，使白日变为黑夜，命海水来浇在地上的（耶和华是他的名）。"},
{volumn:        30,chapter:         5,versenumber:         9,verse:"他使力强的忽遭灭亡，以致保障遭遇毁坏。"},
{volumn:        30,chapter:         5,versenumber:        10,verse:"你们怨恨那在城门口责备人的，憎恶那说正直话的。"},
{volumn:        30,chapter:         5,versenumber:        11,verse:"你们践踏贫民，向他们勒索麦子；你们用凿过的石头建造房屋，却不得住在其内；栽种美好的葡萄园，却不得喝所出的酒。"},
{volumn:        30,chapter:         5,versenumber:        12,verse:"我知道你们的罪过何等多，你们的罪恶何等大。你们苦待义人，收受贿赂，在城门口屈枉穷乏人。"},
{volumn:        30,chapter:         5,versenumber:        13,verse:"所以通达人见这样的时势必静默不言，因为时势真恶。"},
{volumn:        30,chapter:         5,versenumber:        14,verse:"你们要求善，不要求恶，就必存活。这样，耶和华万军之　神必照你们所说的与你们同在。"},
{volumn:        30,chapter:         5,versenumber:        15,verse:"要恶恶好善，在城门口秉公行义；或者耶和华万军之　神向约瑟的余民施恩。"},
{volumn:        30,chapter:         5,versenumber:        16,verse:"主耶和华万军之　神如此说：“在一切宽阔处必有哀号的声音；在各街市上必有人说：‘哀哉！哀哉！’又必叫农夫来哭号，叫善唱哀歌的来举哀。"},
{volumn:        30,chapter:         5,versenumber:        17,verse:"在各葡萄园必有哀号的声音，因为我必从你中间经过。”这是耶和华说的。"},
{volumn:        30,chapter:         5,versenumber:        18,verse:"想望耶和华日子来到的有祸了！你们为何想望耶和华的日子呢？那日黑暗没有光明，"},
{volumn:        30,chapter:         5,versenumber:        19,verse:"景况好像人躲避狮子又遇见熊，或是进房屋以手靠墙，就被蛇咬。"},
{volumn:        30,chapter:         5,versenumber:        20,verse:"耶和华的日子不是黑暗没有光明吗？不是幽暗毫无光辉吗？"},
{volumn:        30,chapter:         5,versenumber:        21,verse:"“我厌恶你们的节期，也不喜悦你们的严肃会。"},
{volumn:        30,chapter:         5,versenumber:        22,verse:"你们虽然向我献燔祭和素祭，我却不悦纳，也不顾你们用肥畜献的平安祭；"},
{volumn:        30,chapter:         5,versenumber:        23,verse:"要使你们歌唱的声音远离我，因为我不听你们弹琴的响声。"},
{volumn:        30,chapter:         5,versenumber:        24,verse:"惟愿公平如大水滚滚，使公义如江河滔滔。"},
{volumn:        30,chapter:         5,versenumber:        25,verse:"“以色列家啊，你们在旷野四十年，岂是将祭物和供物献给我呢？"},
{volumn:        30,chapter:         5,versenumber:        26,verse:"你们抬着为自己所造之摩洛的帐幕和偶像的龛，并你们的神星。"},
{volumn:        30,chapter:         5,versenumber:        27,verse:"所以我要把你们掳到大马士革以外。”这是耶和华、名为万军之　神说的。"},
{volumn:        30,chapter:         6,versenumber:         1,verse:"国为列国之首，人最著名，且为以色列家所归向，在锡安和撒玛利亚山安逸无虑的，有祸了！"},
{volumn:        30,chapter:         6,versenumber:         2,verse:"你们要过到甲尼察看，从那里往大城哈马去，又下到非利士人的迦特，看那些国比你们的国还强吗？境界比你们的境界还宽吗？"},
{volumn:        30,chapter:         6,versenumber:         3,verse:"你们以为降祸的日子还远，坐在位上尽行强暴（或译：行强暴使审判临近）。"},
{volumn:        30,chapter:         6,versenumber:         4,verse:"你们躺卧在象牙床上，舒身在榻上，吃群中的羊羔，棚里的牛犊；"},
{volumn:        30,chapter:         6,versenumber:         5,verse:"弹琴鼓瑟唱消闲的歌曲，为自己制造乐器，如同大卫所造的；"},
{volumn:        30,chapter:         6,versenumber:         6,verse:"以大碗喝酒，用上等的油抹身，却不为约瑟的苦难担忧。"},
{volumn:        30,chapter:         6,versenumber:         7,verse:"所以这些人必在被掳的人中首先被掳；舒身的人荒宴之乐必消灭了。"},
{volumn:        30,chapter:         6,versenumber:         8,verse:"主耶和华万军之　神指着自己起誓说：“我憎恶雅各的荣华，厌弃他的宫殿；因此，我必将城和其中所有的都交付敌人。”"},
{volumn:        30,chapter:         6,versenumber:         9,verse:"那时，若在一房之内剩下十个人，也都必死。"},
{volumn:        30,chapter:         6,versenumber:        10,verse:"死人的伯叔，就是烧他尸首的，要将这尸首搬到房外，问房屋内间的人说：“你那里还有人没有？”他必说：“没有”；又说：“不要作声，因为我们不可提耶和华的名。”"},
{volumn:        30,chapter:         6,versenumber:        11,verse:"看哪，耶和华出令，大房就被攻破，小屋就被打裂。"},
{volumn:        30,chapter:         6,versenumber:        12,verse:"马岂能在崖石上奔跑？人岂能在那里用牛耕种呢？你们却使公平变为苦胆，使公义的果子变为茵陈。"},
{volumn:        30,chapter:         6,versenumber:        13,verse:"你们喜爱虚浮的事，自夸说：“我们不是凭自己的力量取了角吗？”"},
{volumn:        30,chapter:         6,versenumber:        14,verse:"耶和华、万军之　神说：“以色列家啊，我必兴起一国攻击你们；他们必欺压你们，从哈马口直到亚拉巴的河。”"},
{volumn:        30,chapter:         7,versenumber:         1,verse:"主耶和华指示我一件事：为王割菜（或译：草）之后，菜又发生；刚发生的时候，主造蝗虫。"},
{volumn:        30,chapter:         7,versenumber:         2,verse:"蝗虫吃尽那地的青物，我就说：“主耶和华啊，求你赦免；因为雅各微弱，他怎能站立得住呢？”"},
{volumn:        30,chapter:         7,versenumber:         3,verse:"耶和华就后悔说：“这灾可以免了。”"},
{volumn:        30,chapter:         7,versenumber:         4,verse:"主耶和华又指示我一件事：他命火来惩罚以色列，火就吞灭深渊，险些将地烧灭。"},
{volumn:        30,chapter:         7,versenumber:         5,verse:"我就说：“主耶和华啊，求你止息；因为雅各微弱，他怎能站立得住呢？”"},
{volumn:        30,chapter:         7,versenumber:         6,verse:"耶和华就后悔说：“这灾也可免了。”"},
{volumn:        30,chapter:         7,versenumber:         7,verse:"他又指示我一件事：有一道墙是按准绳建筑的，主手拿准绳站在其上。"},
{volumn:        30,chapter:         7,versenumber:         8,verse:"耶和华对我说：“阿摩司啊，你看见什么？”我说：“看见准绳。”主说：“我要吊起准绳在我民以色列中，我必不再宽恕他们。"},
{volumn:        30,chapter:         7,versenumber:         9,verse:"以撒的邱坛必然凄凉；以色列的圣所必然荒废。我必兴起，用刀攻击耶罗波安的家。”"},
{volumn:        30,chapter:         7,versenumber:        10,verse:"伯特利的祭司亚玛谢打发人到以色列王耶罗波安那里，说：“阿摩司在以色列家中图谋背叛你；他所说的一切话，这国担当不起；"},
{volumn:        30,chapter:         7,versenumber:        11,verse:"因为阿摩司如此说：‘耶罗波安必被刀杀；以色列民定被掳去离开本地。’”"},
{volumn:        30,chapter:         7,versenumber:        12,verse:"亚玛谢又对阿摩司说：“你这先见哪，要逃往犹大地去，在那里糊口，在那里说预言，"},
{volumn:        30,chapter:         7,versenumber:        13,verse:"却不要在伯特利再说预言；因为这里有王的圣所，有王的宫殿。”"},
{volumn:        30,chapter:         7,versenumber:        14,verse:"阿摩司对亚玛谢说：“我原不是先知，也不是先知的门徒（原文是儿子）。我是牧人，又是修理桑树的。"},
{volumn:        30,chapter:         7,versenumber:        15,verse:"耶和华选召我，使我不跟从羊群，对我说：‘你去向我民以色列说预言。’"},
{volumn:        30,chapter:         7,versenumber:        16,verse:"亚玛谢啊，现在你要听耶和华的话。你说：‘不要向以色列说预言，也不要向以撒家滴下预言。’"},
{volumn:        30,chapter:         7,versenumber:        17,verse:"所以耶和华如此说：‘你的妻子必在城中作妓女；你的儿女必倒在刀下。你的地必有人用绳子量了分取；你自己必死在污秽之地。以色列民定被掳去离开本地。’”"},
{volumn:        30,chapter:         8,versenumber:         1,verse:"主耶和华又指示我一件事：我看见一筐夏天的果子。"},
{volumn:        30,chapter:         8,versenumber:         2,verse:"他说：“阿摩司啊，你看见什么？”我说：“看见一筐夏天的果子。”耶和华说：“我民以色列的结局到了，我必不再宽恕他们。”"},
{volumn:        30,chapter:         8,versenumber:         3,verse:"主耶和华说：“那日，殿中的诗歌变为哀号；必有许多尸首在各处抛弃，无人作声。”"},
{volumn:        30,chapter:         8,versenumber:         4,verse:"你们这些要吞吃穷乏人、使困苦人衰败的，当听我的话！"},
{volumn:        30,chapter:         8,versenumber:         5,verse:"你们说：“月朔几时过去，我们好卖粮；安息日几时过去，我们好摆开麦子；卖出用小升斗，收银用大戥子，用诡诈的天平欺哄人，"},
{volumn:        30,chapter:         8,versenumber:         6,verse:"好用银子买贫寒人，用一双鞋换穷乏人，将坏了的麦子卖给人。”"},
{volumn:        30,chapter:         8,versenumber:         7,verse:"耶和华指着雅各的荣耀起誓说：“他们的一切行为，我必永远不忘。"},
{volumn:        30,chapter:         8,versenumber:         8,verse:"地岂不因这事震动？其上的居民不也悲哀吗？地必全然像尼罗河涨起，如同埃及河涌上落下。”"},
{volumn:        30,chapter:         8,versenumber:         9,verse:"主耶和华说：“到那日，我必使日头在午间落下，使地在白昼黑暗。"},
{volumn:        30,chapter:         8,versenumber:        10,verse:"我必使你们的节期变为悲哀，歌曲变为哀歌。众人腰束麻布，头上光秃，使这场悲哀如丧独生子，至终如痛苦的日子一样。”"},
{volumn:        30,chapter:         8,versenumber:        11,verse:"主耶和华说：“日子将到，我必命饥荒降在地上。人饥饿非因无饼，干渴非因无水，乃因不听耶和华的话。"},
{volumn:        30,chapter:         8,versenumber:        12,verse:"他们必飘流，从这海到那海，从北边到东边，往来奔跑，寻求耶和华的话，却寻不着。"},
{volumn:        30,chapter:         8,versenumber:        13,verse:"“当那日，美貌的处女和少年的男子必因干渴发昏。"},
{volumn:        30,chapter:         8,versenumber:        14,verse:"那指着撒玛利亚牛犊（原文是罪）起誓的说：‘但哪，我们指着你那里的活神起誓。’又说：‘我们指着别是巴的神道（神：原文是活）起誓。’这些人都必仆倒，永不再起来。”"},
{volumn:        30,chapter:         9,versenumber:         1,verse:"我看见主站在祭坛旁边；他说：“你要击打柱顶，使门槛震动，打碎柱顶，落在众人头上；所剩下的人，我必用刀杀戮，无一人能逃避，无一人能逃脱。"},
{volumn:        30,chapter:         9,versenumber:         2,verse:"他们虽然挖透阴间，我的手必取出他们来；虽然爬上天去，我必拿下他们来；"},
{volumn:        30,chapter:         9,versenumber:         3,verse:"虽然藏在迦密山顶，我必搜寻，捉出他们来；虽然从我眼前藏在海底，我必命蛇咬他们；"},
{volumn:        30,chapter:         9,versenumber:         4,verse:"虽被仇敌掳去，我必命刀剑杀戮他们；我必向他们定住眼目，降祸不降福。”"},
{volumn:        30,chapter:         9,versenumber:         5,verse:"主万军之耶和华摸地，地就消化，凡住在地上的都必悲哀。地必全然像尼罗河涨起，如同埃及河落下。"},
{volumn:        30,chapter:         9,versenumber:         6,verse:"那在天上建造楼阁、在地上安定穹苍、命海水浇在地上的，耶和华是他的名。"},
{volumn:        30,chapter:         9,versenumber:         7,verse:"耶和华说：“以色列人哪，我岂不看你们如古实人吗？我岂不是领以色列人出埃及地，领非利士人出迦斐托，领亚兰人出吉珥吗？"},
{volumn:        30,chapter:         9,versenumber:         8,verse:"主耶和华的眼目察看这有罪的国，必将这国从地上灭绝，却不将雅各家灭绝净尽。”这是耶和华说的。"},
{volumn:        30,chapter:         9,versenumber:         9,verse:"“我必出令，将以色列家分散在列国中，好像用筛子筛谷，连一粒也不落在地上。"},
{volumn:        30,chapter:         9,versenumber:        10,verse:"我民中的一切罪人说：‘灾祸必追不上我们，也迎不着我们。’他们必死在刀下。”"},
{volumn:        30,chapter:         9,versenumber:        11,verse:"“到那日，我必建立大卫倒塌的帐幕，堵住其中的破口，把那破坏的建立起来，重新修造，像古时一样，"},
{volumn:        30,chapter:         9,versenumber:        12,verse:"使以色列人得以东所余剩的和所有称为我名下的国。”此乃行这事的耶和华说的。"},
{volumn:        30,chapter:         9,versenumber:        13,verse:"耶和华说：“日子将到，耕种的必接续收割的；踹葡萄的必接续撒种的；大山要滴下甜酒；小山都必流奶（原文是消化，见约珥三章十八节）。"},
{volumn:        30,chapter:         9,versenumber:        14,verse:"我必使我民以色列被掳的归回；他们必重修荒废的城邑居住，栽种葡萄园，喝其中所出的酒，修造果木园，吃其中的果子。"},
{volumn:        30,chapter:         9,versenumber:        15,verse:"我要将他们栽于本地，他们不再从我所赐给他们的地上拔出来。”这是耶和华你的　神说的。"},
{volumn:        31,chapter:         1,versenumber:         1,verse:"俄巴底亚得了耶和华的默示。耶和华要惩罚以东论以东说：“我从耶和华那里听见信息，并有使者被差往列国去，说：起来吧，一同起来与以东争战！"},
{volumn:        31,chapter:         1,versenumber:         2,verse:"我使你以东在列国中为最小的，被人大大藐视。"},
{volumn:        31,chapter:         1,versenumber:         3,verse:"住在山穴中、居所在高处的啊，你因狂傲自欺，心里说：‘谁能将我拉下地去呢？’"},
{volumn:        31,chapter:         1,versenumber:         4,verse:"你虽如大鹰高飞，在星宿之间搭窝，我必从那里拉下你来。这是耶和华说的。"},
{volumn:        31,chapter:         1,versenumber:         5,verse:"“盗贼若来在你那里，或强盗夜间而来（你何竟被剪除），岂不偷窃直到够了呢？摘葡萄的若来到你那里，岂不剩下些葡萄呢？"},
{volumn:        31,chapter:         1,versenumber:         6,verse:"以扫的隐密处何竟被搜寻？他隐藏的宝物何竟被查出？"},
{volumn:        31,chapter:         1,versenumber:         7,verse:"与你结盟的都送你上路，直到交界；与你和好的欺骗你，且胜过你；与你一同吃饭的设下网罗陷害你；在你心里毫无聪明。"},
{volumn:        31,chapter:         1,versenumber:         8,verse:"耶和华说：到那日，我岂不从以东除灭智慧人？从以扫山除灭聪明人？"},
{volumn:        31,chapter:         1,versenumber:         9,verse:"提幔哪，你的勇士必惊惶，甚至以扫山的人都被杀戮剪除。”"},
{volumn:        31,chapter:         1,versenumber:        10,verse:"“因你向兄弟雅各行强暴，羞愧必遮盖你，你也必永远断绝。"},
{volumn:        31,chapter:         1,versenumber:        11,verse:"当外人掳掠雅各的财物，外邦人进入他的城门，为耶路撒冷拈阄的日子，你竟站在一旁，像与他们同伙。"},
{volumn:        31,chapter:         1,versenumber:        12,verse:"你兄弟遭难的日子，你不当瞪眼看着；犹大人被灭的日子，你不当因此欢乐；他们遭难的日子，你不当说狂傲的话。"},
{volumn:        31,chapter:         1,versenumber:        13,verse:"我民遭灾的日子，你不当进他们的城门；他们遭灾的日子，你不当瞪眼看着他们受苦；他们遭灾的日子，你不当伸手抢他们的财物；"},
{volumn:        31,chapter:         1,versenumber:        14,verse:"你不当站在岔路口剪除他们中间逃脱的；他们遭难的日子，你不当将他们剩下的人交付仇敌。”"},
{volumn:        31,chapter:         1,versenumber:        15,verse:"“耶和华降罚的日子临近万国。你怎样行，他也必照样向你行；你的报应必归到你头上。"},
{volumn:        31,chapter:         1,versenumber:        16,verse:"你们犹大人在我圣山怎样喝了苦杯，万国也必照样常常地喝；且喝且咽，他们就归于无有。”"},
{volumn:        31,chapter:         1,versenumber:        17,verse:"“在锡安山必有逃脱的人，那山也必成圣；雅各家必得原有的产业。"},
{volumn:        31,chapter:         1,versenumber:        18,verse:"雅各家必成为大火；约瑟家必为火焰；以扫家必如碎秸；火必将他烧着吞灭。以扫家必无余剩的。”这是耶和华说的。"},
{volumn:        31,chapter:         1,versenumber:        19,verse:"“南地的人必得以扫山；高原的人必得非利士地，也得以法莲地和撒玛利亚地；便雅悯人必得基列。"},
{volumn:        31,chapter:         1,versenumber:        20,verse:"在迦南人中被掳的以色列众人必得地直到撒勒法；在西法拉中被掳的耶路撒冷人必得南地的城邑。"},
{volumn:        31,chapter:         1,versenumber:        21,verse:"必有拯救者上到锡安山，审判以扫山；国度就归耶和华了。”"},
{volumn:        32,chapter:         1,versenumber:         1,verse:"耶和华的话临到亚米太的儿子约拿，说："},
{volumn:        32,chapter:         1,versenumber:         2,verse:"“你起来往尼尼微大城去，向其中的居民呼喊，因为他们的恶达到我面前。”"},
{volumn:        32,chapter:         1,versenumber:         3,verse:"约拿却起来，逃往他施去躲避耶和华；下到约帕，遇见一只船，要往他施去。他就给了船价，上了船，要与船上的人同往他施去躲避耶和华。"},
{volumn:        32,chapter:         1,versenumber:         4,verse:"然而耶和华使海中起大风，海就狂风大作，甚至船几乎破坏。"},
{volumn:        32,chapter:         1,versenumber:         5,verse:"水手便惧怕，各人哀求自己的神。他们将船上的货物抛在海中，为要使船轻些。约拿已下到底舱，躺卧沉睡。"},
{volumn:        32,chapter:         1,versenumber:         6,verse:"船主到他那里对他说：“你这沉睡的人哪，为何这样呢？起来，求告你的神，或者神顾念我们，使我们不致灭亡。”"},
{volumn:        32,chapter:         1,versenumber:         7,verse:"船上的人彼此说：“来吧，我们掣签，看看这灾临到我们是因谁的缘故。”于是他们掣签，掣出约拿来。"},
{volumn:        32,chapter:         1,versenumber:         8,verse:"众人对他说：“请你告诉我们，这灾临到我们是因谁的缘故？你以何事为业？你从哪里来？你是哪一国？属哪一族的人？”"},
{volumn:        32,chapter:         1,versenumber:         9,verse:"他说：“我是希伯来人。我敬畏耶和华那创造沧海旱地之天上的　神。”"},
{volumn:        32,chapter:         1,versenumber:        10,verse:"他们就大大惧怕，对他说：“你做的是什么事呢？”他们已经知道他躲避耶和华，因为他告诉了他们。"},
{volumn:        32,chapter:         1,versenumber:        11,verse:"他们问他说：“我们当向你怎样行，使海浪平静呢？”这话是因海浪越发翻腾。"},
{volumn:        32,chapter:         1,versenumber:        12,verse:"他对他们说：“你们将我抬起来，抛在海中，海就平静了；我知道你们遭这大风是因我的缘故。”"},
{volumn:        32,chapter:         1,versenumber:        13,verse:"然而那些人竭力荡桨，要把船拢岸，却是不能，因为海浪越发向他们翻腾。"},
{volumn:        32,chapter:         1,versenumber:        14,verse:"他们便求告耶和华说：“耶和华啊，我们恳求你，不要因这人的性命使我们死亡，不要使流无辜血的罪归与我们；因为你耶和华是随自己的意旨行事。”"},
{volumn:        32,chapter:         1,versenumber:        15,verse:"他们遂将约拿抬起，抛在海中，海的狂浪就平息了。"},
{volumn:        32,chapter:         1,versenumber:        16,verse:"那些人便大大敬畏耶和华，向耶和华献祭，并且许愿。"},
{volumn:        32,chapter:         1,versenumber:        17,verse:"耶和华安排一条大鱼吞了约拿，他在鱼腹中三日三夜。"},
{volumn:        32,chapter:         2,versenumber:         1,verse:"约拿在鱼腹中祷告耶和华他的　神，"},
{volumn:        32,chapter:         2,versenumber:         2,verse:"说：“我遭遇患难求告耶和华，你就应允我；从阴间的深处呼求，你就俯听我的声音。"},
{volumn:        32,chapter:         2,versenumber:         3,verse:"你将我投下深渊，就是海的深处；大水环绕我，你的波浪洪涛都漫过我身。"},
{volumn:        32,chapter:         2,versenumber:         4,verse:"我说：我从你眼前虽被驱逐，我仍要仰望你的圣殿。"},
{volumn:        32,chapter:         2,versenumber:         5,verse:"诸水环绕我，几乎淹没我；深渊围住我；海草缠绕我的头。"},
{volumn:        32,chapter:         2,versenumber:         6,verse:"我下到山根，地的门将我永远关住。耶和华我的　神啊，你却将我的性命从坑中救出来。"},
{volumn:        32,chapter:         2,versenumber:         7,verse:"我心在我里面发昏的时候，我就想念耶和华。我的祷告进入你的圣殿，达到你的面前。"},
{volumn:        32,chapter:         2,versenumber:         8,verse:"那信奉虚无之神的人，离弃怜爱他们的主；"},
{volumn:        32,chapter:         2,versenumber:         9,verse:"但我必用感谢的声音献祭与你。我所许的愿，我必偿还。救恩出于耶和华。”"},
{volumn:        32,chapter:         2,versenumber:        10,verse:"耶和华吩咐鱼，鱼就把约拿吐在旱地上。"},
{volumn:        32,chapter:         3,versenumber:         1,verse:"耶和华的话二次临到约拿说："},
{volumn:        32,chapter:         3,versenumber:         2,verse:"“你起来！往尼尼微大城去，向其中的居民宣告我所吩咐你的话。”"},
{volumn:        32,chapter:         3,versenumber:         3,verse:"约拿便照耶和华的话起来，往尼尼微去。这尼尼微是极大的城，有三日的路程。"},
{volumn:        32,chapter:         3,versenumber:         4,verse:"约拿进城走了一日，宣告说：“再等四十日，尼尼微必倾覆了！”"},
{volumn:        32,chapter:         3,versenumber:         5,verse:"尼尼微人信服　神，便宣告禁食，从最大的到至小的都穿麻衣（或译：披上麻布）。"},
{volumn:        32,chapter:         3,versenumber:         6,verse:"这信息传到尼尼微王的耳中，他就下了宝座，脱下朝服，披上麻布，坐在灰中。"},
{volumn:        32,chapter:         3,versenumber:         7,verse:"他又使人遍告尼尼微通城，说：“王和大臣有令，人不可尝什么，牲畜、牛羊不可吃草，也不可喝水。"},
{volumn:        32,chapter:         3,versenumber:         8,verse:"人与牲畜都当披上麻布；人要切切求告　神。各人回头离开所行的恶道，丢弃手中的强暴。"},
{volumn:        32,chapter:         3,versenumber:         9,verse:"或者　神转意后悔，不发烈怒，使我们不致灭亡，也未可知。”"},
{volumn:        32,chapter:         3,versenumber:        10,verse:"于是　神察看他们的行为，见他们离开恶道，他就后悔，不把所说的灾祸降与他们了。"},
{volumn:        32,chapter:         4,versenumber:         1,verse:"这事约拿大大不悦，且甚发怒，"},
{volumn:        32,chapter:         4,versenumber:         2,verse:"就祷告耶和华说：“耶和华啊，我在本国的时候岂不是这样说吗？我知道你是有恩典、有怜悯的　神，不轻易发怒，有丰盛的慈爱，并且后悔不降所说的灾，所以我急速逃往他施去。"},
{volumn:        32,chapter:         4,versenumber:         3,verse:"耶和华啊，现在求你取我的命吧！因为我死了比活着还好。”"},
{volumn:        32,chapter:         4,versenumber:         4,verse:"耶和华说：“你这样发怒合乎理吗？”"},
{volumn:        32,chapter:         4,versenumber:         5,verse:"于是约拿出城，坐在城的东边，在那里为自己搭了一座棚，坐在棚的荫下，要看看那城究竟如何。"},
{volumn:        32,chapter:         4,versenumber:         6,verse:"耶和华　神安排一棵蓖麻，使其发生高过约拿，影儿遮盖他的头，救他脱离苦楚；约拿因这棵蓖麻大大喜乐。"},
{volumn:        32,chapter:         4,versenumber:         7,verse:"次日黎明，　神却安排一条虫子咬这蓖麻，以致枯槁。"},
{volumn:        32,chapter:         4,versenumber:         8,verse:"日头出来的时候，　神安排炎热的东风，日头曝晒约拿的头，使他发昏，他就为自己求死，说：“我死了比活着还好！”"},
{volumn:        32,chapter:         4,versenumber:         9,verse:"神对约拿说：“你因这棵蓖麻发怒合乎理吗？”他说：“我发怒以至于死，都合乎理！”"},
{volumn:        32,chapter:         4,versenumber:        10,verse:"耶和华说：“这蓖麻不是你栽种的，也不是你培养的；一夜发生，一夜干死，你尚且爱惜；"},
{volumn:        32,chapter:         4,versenumber:        11,verse:"何况这尼尼微大城，其中不能分辨左手右手的有十二万多人，并有许多牲畜，我岂能不爱惜呢？”"},
{volumn:        33,chapter:         1,versenumber:         1,verse:"当犹大王约坦、亚哈斯、希西家在位的时候，摩利沙人弥迦得耶和华的默示，论撒玛利亚和耶路撒冷。"},
{volumn:        33,chapter:         1,versenumber:         2,verse:"万民哪，你们都要听！地和其上所有的，也都要侧耳而听！主耶和华从他的圣殿要见证你们的不是。"},
{volumn:        33,chapter:         1,versenumber:         3,verse:"看哪，耶和华出了他的居所，降临步行地的高处。"},
{volumn:        33,chapter:         1,versenumber:         4,verse:"众山在他以下必消化，诸谷必崩裂，如蜡化在火中，如水冲下山坡。"},
{volumn:        33,chapter:         1,versenumber:         5,verse:"这都因雅各的罪过，以色列家的罪恶。雅各的罪过在哪里呢？岂不是在撒玛利亚吗？犹大的邱坛在哪里呢？岂不是在耶路撒冷吗？"},
{volumn:        33,chapter:         1,versenumber:         6,verse:"所以我必使撒玛利亚变为田野的乱堆，又作为种葡萄之处；也必将她的石头倒在谷中，露出根基来。"},
{volumn:        33,chapter:         1,versenumber:         7,verse:"她一切雕刻的偶像必被打碎；她所得的财物必被火烧；所有的偶像我必毁灭；因为是从妓女雇价所聚来的，后必归为妓女的雇价。"},
{volumn:        33,chapter:         1,versenumber:         8,verse:"先知说：因此我必大声哀号，赤脚露体而行；又要呼号如野狗，哀鸣如鸵鸟。"},
{volumn:        33,chapter:         1,versenumber:         9,verse:"因为撒玛利亚的伤痕无法医治，延及犹大和耶路撒冷我民的城门。"},
{volumn:        33,chapter:         1,versenumber:        10,verse:"不要在迦特报告这事，总不要哭泣；我在伯·亚弗拉滚于灰尘之中。"},
{volumn:        33,chapter:         1,versenumber:        11,verse:"沙斐的居民哪，你们要赤身蒙羞过去。撒南的居民不敢出来。伯·以薛人的哀哭使你们无处可站。"},
{volumn:        33,chapter:         1,versenumber:        12,verse:"玛律的居民心甚忧急，切望得好处，因为灾祸从耶和华那里临到耶路撒冷的城门。"},
{volumn:        33,chapter:         1,versenumber:        13,verse:"拉吉的居民哪，要用快马套车；锡安民（原文是女子）的罪由你而起；以色列人的罪过在你那里显出。"},
{volumn:        33,chapter:         1,versenumber:        14,verse:"犹大啊，你要将礼物送给摩利设·迦特。亚革悉的众族必用诡诈待以色列诸王。"},
{volumn:        33,chapter:         1,versenumber:        15,verse:"玛利沙的居民哪，我必使那夺取你的来到你这里；以色列的尊贵人（原文是荣耀）必到亚杜兰。"},
{volumn:        33,chapter:         1,versenumber:        16,verse:"犹大啊，要为你所喜爱的儿女剪除你的头发，使头光秃，要大大地光秃，如同秃鹰，因为他们都被掳去离开你。"},
{volumn:        33,chapter:         2,versenumber:         1,verse:"祸哉，那些在床上图谋罪孽、造作奸恶的！天一发亮，因手有能力就行出来了。"},
{volumn:        33,chapter:         2,versenumber:         2,verse:"他们贪图田地就占据，贪图房屋便夺取；他们欺压人，霸占房屋和产业。"},
{volumn:        33,chapter:         2,versenumber:         3,verse:"所以耶和华如此说：“我筹划灾祸降与这族；这祸在你们的颈项上不能解脱；你们也不能昂首而行，因为这时势是恶的。"},
{volumn:        33,chapter:         2,versenumber:         4,verse:"到那日，必有人向你们提起悲惨的哀歌，讥刺说：‘我们全然败落了！耶和华将我们的份转归别人，何竟使这份离开我们？他将我们的田地分给悖逆的人。’"},
{volumn:        33,chapter:         2,versenumber:         5,verse:"所以在耶和华的会中，你必没有人拈阄拉准绳。"},
{volumn:        33,chapter:         2,versenumber:         6,verse:"“他们（或译：假先知）说：‘你们不可说预言；不可向这些人说预言，不住地羞辱我们。’"},
{volumn:        33,chapter:         2,versenumber:         7,verse:"雅各家啊，岂可说耶和华的心不忍耐吗（或译：心肠狭窄吗）？这些事是他所行的吗？我耶和华的言语岂不是与行动正直的人有益吗？"},
{volumn:        33,chapter:         2,versenumber:         8,verse:"“然而，近来我的民兴起如仇敌，从那些安然经过不愿打仗之人身上剥去外衣。"},
{volumn:        33,chapter:         2,versenumber:         9,verse:"你们将我民中的妇人从安乐家中赶出，又将我的荣耀从她们的小孩子尽行夺去。"},
{volumn:        33,chapter:         2,versenumber:        10,verse:"你们起来去吧！这不是你们安息之所；因为污秽使人（或译：地）毁灭，而且大大毁灭。"},
{volumn:        33,chapter:         2,versenumber:        11,verse:"若有人心存虚假，用谎言说：‘我要向你们预言得清酒和浓酒。’那人就必作这民的先知。"},
{volumn:        33,chapter:         2,versenumber:        12,verse:"“雅各家啊，我必要聚集你们，必要招聚以色列剩下的人，安置在一处，如波斯拉的羊，又如草场上的羊群；因为人数众多就必大大喧哗。"},
{volumn:        33,chapter:         2,versenumber:        13,verse:"开路的（或译：破城的）在他们前面上去；他们直闯过城门，从城门出去。他们的王在前面行；耶和华引导他们。”"},
{volumn:        33,chapter:         3,versenumber:         1,verse:"我说：雅各的首领，以色列家的官长啊，你们要听！你们不当知道公平吗？"},
{volumn:        33,chapter:         3,versenumber:         2,verse:"你们恶善好恶，从人身上剥皮，从人骨头上剔肉，"},
{volumn:        33,chapter:         3,versenumber:         3,verse:"吃我民的肉，剥他们的皮，打折他们的骨头，分成块子像要下锅，又像釜中的肉。"},
{volumn:        33,chapter:         3,versenumber:         4,verse:"到了遭灾的时候，这些人必哀求耶和华，他却不应允他们。那时他必照他们所行的恶事向他们掩面。"},
{volumn:        33,chapter:         3,versenumber:         5,verse:"论到使我民走差路的先知，他们牙齿有所嚼的，他们就呼喊说：“平安了！”凡不供给他们吃的，他们就预备攻击他（预备攻击他：或译说必遭遇刀兵）。耶和华如此说："},
{volumn:        33,chapter:         3,versenumber:         6,verse:"“你们必遭遇黑夜，以致不见异象；又必遭遇幽暗，以致不能占卜。日头必向你们沉落，白昼变为黑暗。"},
{volumn:        33,chapter:         3,versenumber:         7,verse:"先见必抱愧，占卜的必蒙羞，都必捂着嘴唇，因为　神不应允他们。”"},
{volumn:        33,chapter:         3,versenumber:         8,verse:"至于我，我藉耶和华的灵，满有力量、公平、才能，可以向雅各说明他的过犯，向以色列指出他的罪恶。"},
{volumn:        33,chapter:         3,versenumber:         9,verse:"雅各家的首领、以色列家的官长啊，当听我的话！你们厌恶公平，在一切事上屈枉正直；"},
{volumn:        33,chapter:         3,versenumber:        10,verse:"以人血建立锡安，以罪孽建造耶路撒冷。"},
{volumn:        33,chapter:         3,versenumber:        11,verse:"首领为贿赂行审判；祭司为雇价施训诲；先知为银钱行占卜。他们却倚赖耶和华，说：“耶和华不是在我们中间吗？灾祸必不临到我们。”"},
{volumn:        33,chapter:         3,versenumber:        12,verse:"所以因你们的缘故，锡安必被耕种像一块田，耶路撒冷必变为乱堆；这殿的山必像丛林的高处。"},
{volumn:        33,chapter:         4,versenumber:         1,verse:"末后的日子，耶和华殿的山必坚立，超乎诸山，高举过于万岭；万民都要流归这山。"},
{volumn:        33,chapter:         4,versenumber:         2,verse:"必有许多国的民前往，说：“来吧，我们登耶和华的山，奔雅各　神的殿。主必将他的道教训我们；我们也要行他的路。因为训诲必出于锡安；耶和华的言语必出于耶路撒冷。”"},
{volumn:        33,chapter:         4,versenumber:         3,verse:"他必在多国的民中施行审判，为远方强盛的国断定是非。他们要将刀打成犁头，把枪打成镰刀。这国不举刀攻击那国；他们也不再学习战事。"},
{volumn:        33,chapter:         4,versenumber:         4,verse:"人人都要坐在自己葡萄树下和无花果树下，无人惊吓。这是万军之耶和华亲口说的。"},
{volumn:        33,chapter:         4,versenumber:         5,verse:"万民各奉己神的名而行；我们却永永远远奉耶和华我们　神的名而行。"},
{volumn:        33,chapter:         4,versenumber:         6,verse:"耶和华说：“到那日，我必聚集瘸腿的，招聚被赶出的和我所惩治的。"},
{volumn:        33,chapter:         4,versenumber:         7,verse:"我必使瘸腿的为余剩之民，使赶到远方的为强盛之民。耶和华要在锡安山作王治理他们，从今直到永远。"},
{volumn:        33,chapter:         4,versenumber:         8,verse:"“你这羊群的高台、锡安城（原文是女子）的山哪，从前的权柄，就是耶路撒冷民（原文是女子）的国权，必归与你。”"},
{volumn:        33,chapter:         4,versenumber:         9,verse:"现在你为何大声哭号呢？疼痛抓住你仿佛产难的妇人，是因你中间没有君王吗？你的谋士灭亡了吗？"},
{volumn:        33,chapter:         4,versenumber:        10,verse:"锡安的民（原文是女子）哪，你要疼痛劬劳，仿佛产难的妇人；因为你必从城里出来，住在田野，到巴比伦去。在那里要蒙解救；在那里耶和华必救赎你脱离仇敌的手。"},
{volumn:        33,chapter:         4,versenumber:        11,verse:"现在有许多国的民聚集攻击你，说：“愿锡安被玷污！愿我们亲眼见她遭报！”"},
{volumn:        33,chapter:         4,versenumber:        12,verse:"他们却不知道耶和华的意念，也不明白他的筹划。他聚集他们，好像把禾捆聚到禾场一样。"},
{volumn:        33,chapter:         4,versenumber:        13,verse:"锡安的民（原文是女子）哪，起来踹谷吧！我必使你的角成为铁，使你的蹄成为铜。你必打碎多国的民，将他们的财献与耶和华，将他们的货献与普天下的主。"},
{volumn:        33,chapter:         5,versenumber:         1,verse:"成群的民（原文是女子）哪，现在你要聚集成队；因为仇敌围攻我们，要用杖击打以色列审判者的脸。"},
{volumn:        33,chapter:         5,versenumber:         2,verse:"伯利恒的以法他啊，你在犹大诸城中为小，将来必有一位从你那里出来，在以色列中为我作掌权的；他的根源从亘古，从太初就有。"},
{volumn:        33,chapter:         5,versenumber:         3,verse:"耶和华必将以色列人交付敌人，直等那生产的妇人生下子来。那时掌权者（原文是他）其余的弟兄必归到以色列人那里。"},
{volumn:        33,chapter:         5,versenumber:         4,verse:"他必起来，倚靠耶和华的大能，并耶和华他　神之名的威严，牧养他的羊群。他们要安然居住；因为他必日见尊大，直到地极。"},
{volumn:        33,chapter:         5,versenumber:         5,verse:"这位必作我们的平安。当亚述人进入我们的地境，践踏宫殿的时候，我们就立起七个牧者，八个首领攻击他。"},
{volumn:        33,chapter:         5,versenumber:         6,verse:"他们必用刀剑毁坏亚述地和宁录地的关口。亚述人进入我们的地境践踏的时候，他必拯救我们。"},
{volumn:        33,chapter:         5,versenumber:         7,verse:"雅各余剩的人必在多国的民中，如从耶和华那里降下的露水，又如甘霖降在草上；不仗赖人力，也不等候世人之功。"},
{volumn:        33,chapter:         5,versenumber:         8,verse:"雅各余剩的人必在多国多民中，如林间百兽中的狮子，又如少壮狮子在羊群中。他若经过就必践踏撕裂，无人搭救。"},
{volumn:        33,chapter:         5,versenumber:         9,verse:"愿你的手举起，高过敌人！愿你的仇敌都被剪除！"},
{volumn:        33,chapter:         5,versenumber:        10,verse:"耶和华说：“到那日，我必从你中间剪除马匹，毁坏车辆，"},
{volumn:        33,chapter:         5,versenumber:        11,verse:"也必从你国中除灭城邑，拆毁一切的保障，"},
{volumn:        33,chapter:         5,versenumber:        12,verse:"又必除掉你手中的邪术；你那里也不再有占卜的。"},
{volumn:        33,chapter:         5,versenumber:        13,verse:"我必从你中间除灭雕刻的偶像和柱像，你就不再跪拜自己手所造的。"},
{volumn:        33,chapter:         5,versenumber:        14,verse:"我必从你中间拔出木偶，又毁灭你的城邑。"},
{volumn:        33,chapter:         5,versenumber:        15,verse:"我也必在怒气和忿怒中向那不听从的列国施报。”"},
{volumn:        33,chapter:         6,versenumber:         1,verse:"以色列人哪，当听耶和华的话！要起来向山岭争辩，使冈陵听你的话。"},
{volumn:        33,chapter:         6,versenumber:         2,verse:"山岭和地永久的根基啊，要听耶和华争辩的话！因为耶和华要与他的百姓争辩，与以色列争论。"},
{volumn:        33,chapter:         6,versenumber:         3,verse:"“我的百姓啊，我向你做了什么呢？我在什么事上使你厌烦？你可以对我证明。"},
{volumn:        33,chapter:         6,versenumber:         4,verse:"我曾将你从埃及地领出来，从作奴仆之家救赎你；我也差遣摩西、亚伦，和米利暗在你前面行。"},
{volumn:        33,chapter:         6,versenumber:         5,verse:"我的百姓啊，你们当追念摩押王巴勒所设的谋和比珥的儿子巴兰回答他的话，并你们从什亭到吉甲所遇见的事，好使你们知道耶和华公义的作为。”"},
{volumn:        33,chapter:         6,versenumber:         6,verse:"我朝见耶和华，在至高　神面前跪拜，当献上什么呢？岂可献一岁的牛犊为燔祭吗？"},
{volumn:        33,chapter:         6,versenumber:         7,verse:"耶和华岂喜悦千千的公羊，或是万万的油河吗？我岂可为自己的罪过献我的长子吗？为心中的罪恶献我身所生的吗？"},
{volumn:        33,chapter:         6,versenumber:         8,verse:"世人哪，耶和华已指示你何为善。他向你所要的是什么呢？只要你行公义，好怜悯，存谦卑的心，与你的　神同行。"},
{volumn:        33,chapter:         6,versenumber:         9,verse:"耶和华向这城呼叫，智慧人必敬畏他的名：“你们当听是谁派定刑杖的惩罚。"},
{volumn:        33,chapter:         6,versenumber:        10,verse:"恶人家中不仍有非义之财和可恶的小升斗吗？"},
{volumn:        33,chapter:         6,versenumber:        11,verse:"我若用不公道的天平和囊中诡诈的法码，岂可算为清洁呢？"},
{volumn:        33,chapter:         6,versenumber:        12,verse:"城里的富户满行强暴；其中的居民也说谎言，口中的舌头是诡诈的。"},
{volumn:        33,chapter:         6,versenumber:        13,verse:"因此，我击打你，使你的伤痕甚重，使你因你的罪恶荒凉。"},
{volumn:        33,chapter:         6,versenumber:        14,verse:"你要吃，却吃不饱；你的虚弱必显在你中间。你必挪去，却不得救护；所救护的，我必交给刀剑。"},
{volumn:        33,chapter:         6,versenumber:        15,verse:"你必撒种，却不得收割；踹橄榄，却不得油抹身；踹葡萄，却不得酒喝。"},
{volumn:        33,chapter:         6,versenumber:        16,verse:"因为你守暗利的恶规，行亚哈家一切所行的，顺从他们的计谋；因此，我必使你荒凉，使你的居民令人嗤笑，你们也必担当我民的羞辱。”"},
{volumn:        33,chapter:         7,versenumber:         1,verse:"哀哉！我（或译：以色列）好像夏天的果子已被收尽，又像摘了葡萄所剩下的，没有一挂可吃的；我心羡慕初熟的无花果。"},
{volumn:        33,chapter:         7,versenumber:         2,verse:"地上虔诚人灭尽；世间没有正直人；各人埋伏，要杀人流血，都用网罗猎取弟兄。"},
{volumn:        33,chapter:         7,versenumber:         3,verse:"他们双手作恶；君王徇情面，审判官要贿赂；位分大的吐出恶意，都彼此结联行恶。"},
{volumn:        33,chapter:         7,versenumber:         4,verse:"他们最好的，不过是蒺藜；最正直的，不过是荆棘篱笆。你守望者说，降罚的日子已经来到。他们必扰乱不安。"},
{volumn:        33,chapter:         7,versenumber:         5,verse:"不要倚赖邻舍；不要信靠密友。要守住你的口；不要向你怀中的妻提说。"},
{volumn:        33,chapter:         7,versenumber:         6,verse:"因为，儿子藐视父亲；女儿抗拒母亲；媳妇抗拒婆婆；人的仇敌就是自己家里的人。"},
{volumn:        33,chapter:         7,versenumber:         7,verse:"至于我，我要仰望耶和华，要等候那救我的　神；我的　神必应允我。"},
{volumn:        33,chapter:         7,versenumber:         8,verse:"我的仇敌啊，不要向我夸耀。我虽跌倒，却要起来；我虽坐在黑暗里，耶和华却作我的光。"},
{volumn:        33,chapter:         7,versenumber:         9,verse:"我要忍受耶和华的恼怒；因我得罪了他，直等他为我辨屈，为我伸冤。他必领我到光明中；我必得见他的公义。"},
{volumn:        33,chapter:         7,versenumber:        10,verse:"那时我的仇敌，就是曾对我说“耶和华你　神在哪里”的，他一看见这事就被羞愧遮盖。我必亲眼见他遭报；他必被践踏，如同街上的泥土。"},
{volumn:        33,chapter:         7,versenumber:        11,verse:"以色列啊，日子必到，你的墙垣必重修；到那日，你的境界必开展（或译：命令必传到远方）。"},
{volumn:        33,chapter:         7,versenumber:        12,verse:"当那日，人必从亚述，从埃及的城邑，从埃及到大河，从这海到那海，从这山到那山，都归到你这里。"},
{volumn:        33,chapter:         7,versenumber:        13,verse:"然而，这地因居民的缘故，又因他们行事的结果，必然荒凉。"},
{volumn:        33,chapter:         7,versenumber:        14,verse:"求耶和华在迦密山的树林中，用你的杖牧放你独居的民，就是你产业的羊群。求你容他们在巴珊和基列得食物，像古时一样。"},
{volumn:        33,chapter:         7,versenumber:        15,verse:"耶和华说：“我要把奇事显给他们看，好像出埃及地的时候一样。”"},
{volumn:        33,chapter:         7,versenumber:        16,verse:"列国看见这事就必为自己的势力惭愧；他们必用手捂口，掩耳不听。"},
{volumn:        33,chapter:         7,versenumber:        17,verse:"他们必舔土如蛇，又如土中腹行的物，战战兢兢地出他们的营寨。他们必战惧投降耶和华，也必因我们的　神而惧怕。"},
{volumn:        33,chapter:         7,versenumber:        18,verse:"神啊，有何神像你，赦免罪孽，饶恕你产业之余民的罪过，不永远怀怒，喜爱施恩？"},
{volumn:        33,chapter:         7,versenumber:        19,verse:"必再怜悯我们，将我们的罪孽踏在脚下，又将我们的一切罪投于深海。"},
{volumn:        33,chapter:         7,versenumber:        20,verse:"你必按古时起誓应许我们列祖的话，向雅各发诚实，向亚伯拉罕施慈爱。"},
{volumn:        34,chapter:         1,versenumber:         1,verse:"论尼尼微的默示，就是伊勒歌斯人那鸿所得的默示。"},
{volumn:        34,chapter:         1,versenumber:         2,verse:"耶和华是忌邪施报的　神。耶和华施报大有忿怒；向他的敌人施报，向他的仇敌怀怒。"},
{volumn:        34,chapter:         1,versenumber:         3,verse:"耶和华不轻易发怒，大有能力，万不以有罪的为无罪。他乘旋风和暴风而来，云彩为他脚下的尘土。"},
{volumn:        34,chapter:         1,versenumber:         4,verse:"他斥责海，使海干了，使一切江河干涸。巴珊和迦密的树林衰残；黎巴嫩的花草也衰残了。"},
{volumn:        34,chapter:         1,versenumber:         5,verse:"大山因他震动；小山也都消化。大地在他面前突起；世界和住在其间的也都如此。"},
{volumn:        34,chapter:         1,versenumber:         6,verse:"他发忿恨，谁能立得住呢？他发烈怒，谁能当得起呢？他的忿怒如火倾倒；磐石因他崩裂。"},
{volumn:        34,chapter:         1,versenumber:         7,verse:"耶和华本为善，在患难的日子为人的保障，并且认得那些投靠他的人。"},
{volumn:        34,chapter:         1,versenumber:         8,verse:"但他必以涨溢的洪水淹没尼尼微，又驱逐仇敌进入黑暗。"},
{volumn:        34,chapter:         1,versenumber:         9,verse:"尼尼微人哪，设何谋攻击耶和华呢？他必将你们灭绝净尽；灾难不再兴起。"},
{volumn:        34,chapter:         1,versenumber:        10,verse:"你们像丛杂的荆棘，像喝醉了的人，又如枯干的碎秸全然烧灭。"},
{volumn:        34,chapter:         1,versenumber:        11,verse:"有一人从你那里出来，图谋邪恶，设恶计攻击耶和华。"},
{volumn:        34,chapter:         1,versenumber:        12,verse:"耶和华如此说：“尼尼微虽然势力充足，人数繁多，也被剪除，归于无有。犹大啊，我虽然使你受苦，却不再使你受苦。"},
{volumn:        34,chapter:         1,versenumber:        13,verse:"现在我必从你颈项上折断他的轭，扭开他的绳索。”"},
{volumn:        34,chapter:         1,versenumber:        14,verse:"耶和华已经出令，指着尼尼微说：“你名下的人必不留后；我必从你神的庙中除灭雕刻的偶像和铸造的偶像；我必因你鄙陋，使你归于坟墓。”"},
{volumn:        34,chapter:         1,versenumber:        15,verse:"看哪，有报好信传平安之人的脚登山，说：“犹大啊，可以守你的节期，还你所许的愿吧！因为那恶人不再从你中间经过，他已灭绝净尽了。”"},
{volumn:        34,chapter:         2,versenumber:         1,verse:"尼尼微啊，那打碎邦国的上来攻击你。你要看守保障，谨防道路，使腰强壮，大大勉力。"},
{volumn:        34,chapter:         2,versenumber:         2,verse:"耶和华复兴雅各的荣华，好像以色列的荣华一样；因为使地空虚的，已经使雅各和以色列空虚，将他们的葡萄枝毁坏了。"},
{volumn:        34,chapter:         2,versenumber:         3,verse:"他勇士的盾牌是红的，精兵都穿朱红衣服。在他预备争战的日子，战车上的钢铁闪烁如火，柏木把的枪也抡起来了。"},
{volumn:        34,chapter:         2,versenumber:         4,verse:"车辆在街上（或译：城外）急行，在宽阔处奔来奔去，形状如火把，飞跑如闪电。"},
{volumn:        34,chapter:         2,versenumber:         5,verse:"尼尼微王招聚他的贵胄；他们步行绊跌，速上城墙，预备挡牌。"},
{volumn:        34,chapter:         2,versenumber:         6,verse:"河闸开放，宫殿冲没。"},
{volumn:        34,chapter:         2,versenumber:         7,verse:"王后蒙羞，被人掳去；宫女捶胸，哀鸣如鸽。此乃命定之事。"},
{volumn:        34,chapter:         2,versenumber:         8,verse:"尼尼微自古以来充满人民，如同聚水的池子；现在居民却都逃跑。虽有人呼喊说：“站住！站住！”却无人回顾。"},
{volumn:        34,chapter:         2,versenumber:         9,verse:"你们抢掠金银吧！因为所积蓄的无穷，华美的宝器无数。"},
{volumn:        34,chapter:         2,versenumber:        10,verse:"尼尼微现在空虚荒凉，人心消化，双膝相碰，腰都疼痛，脸都变色。"},
{volumn:        34,chapter:         2,versenumber:        11,verse:"狮子的洞和少壮狮子喂养之处在哪里呢？公狮母狮小狮游行、无人惊吓之地在哪里呢？"},
{volumn:        34,chapter:         2,versenumber:        12,verse:"公狮为小狮撕碎许多食物，为母狮掐死活物，把撕碎的、掐死的充满它的洞穴。"},
{volumn:        34,chapter:         2,versenumber:        13,verse:"万军之耶和华说：“我与你为敌，必将你的车辆焚烧成烟，刀剑也必吞灭你的少壮狮子。我必从地上除灭你所撕碎的，你使者的声音必不再听见。”"},
{volumn:        34,chapter:         3,versenumber:         1,verse:"祸哉！这流人血的城，充满谎诈和强暴，抢夺的事总不止息。"},
{volumn:        34,chapter:         3,versenumber:         2,verse:"鞭声响亮，车轮轰轰，马匹踢跳，车辆奔腾，"},
{volumn:        34,chapter:         3,versenumber:         3,verse:"马兵争先，刀剑发光，枪矛闪烁，被杀的甚多，尸首成了大堆，尸骸无数，人碰着而跌倒，"},
{volumn:        34,chapter:         3,versenumber:         4,verse:"都因那美貌的妓女多有淫行，惯行邪术，藉淫行诱惑列国，用邪术诱惑（原文是卖）多族。"},
{volumn:        34,chapter:         3,versenumber:         5,verse:"万军之耶和华说：“我与你为敌；我必揭起你的衣襟，蒙在你脸上，使列国看见你的赤体，使列邦观看你的丑陋。"},
{volumn:        34,chapter:         3,versenumber:         6,verse:"我必将可憎污秽之物抛在你身上，辱没你，为众目所观。"},
{volumn:        34,chapter:         3,versenumber:         7,verse:"凡看见你的，都必逃跑离开你，说：‘尼尼微荒凉了！’有谁为你悲伤呢？我何处寻得安慰你的人呢？”"},
{volumn:        34,chapter:         3,versenumber:         8,verse:"你岂比挪亚们强呢？挪亚们坐落在众河之间，周围有水；海（指尼罗河）作她的濠沟，又作她的城墙。"},
{volumn:        34,chapter:         3,versenumber:         9,verse:"古实和埃及是她无穷的力量；弗人和路比族是她的帮手。"},
{volumn:        34,chapter:         3,versenumber:        10,verse:"但她被迁移，被掳去；她的婴孩在各市口上也被摔死。人为她的尊贵人拈阄；她所有的大人都被链子锁着。"},
{volumn:        34,chapter:         3,versenumber:        11,verse:"你也必喝醉，必被埋藏，并因仇敌的缘故寻求避难所。"},
{volumn:        34,chapter:         3,versenumber:        12,verse:"你一切保障必像无花果树上初熟的无花果，若一摇撼就落在想吃之人的口中。"},
{volumn:        34,chapter:         3,versenumber:        13,verse:"你地上的人民如同妇女；你国中的关口向仇敌敞开；你的门闩被火焚烧。"},
{volumn:        34,chapter:         3,versenumber:        14,verse:"你要打水预备受困；要坚固你的保障，踹土和泥，修补砖窑。"},
{volumn:        34,chapter:         3,versenumber:        15,verse:"在那里，火必烧灭你；刀必杀戮你，吞灭你如同蝻子。任你加增人数多如蝻子，多如蝗虫吧！"},
{volumn:        34,chapter:         3,versenumber:        16,verse:"你增添商贾，多过天上的星；蝻子吃尽而去。"},
{volumn:        34,chapter:         3,versenumber:        17,verse:"你的首领多如蝗虫；你的军长仿佛成群的蚂蚱，天凉的时候齐落在篱笆上，日头一出便都飞去，人不知道落在何处。"},
{volumn:        34,chapter:         3,versenumber:        18,verse:"亚述王啊，你的牧人睡觉；你的贵胄安歇；你的人民散在山间，无人招聚。"},
{volumn:        34,chapter:         3,versenumber:        19,verse:"你的损伤无法医治；你的伤痕极其重大。凡听你信息的必都因此向你拍掌。你所行的恶谁没有时常遭遇呢？"},
{volumn:        35,chapter:         1,versenumber:         1,verse:"先知哈巴谷所得的默示。"},
{volumn:        35,chapter:         1,versenumber:         2,verse:"他说：“耶和华啊！我呼求你，你不应允，要到几时呢？我因强暴哀求你，你还不拯救。"},
{volumn:        35,chapter:         1,versenumber:         3,verse:"你为何使我看见罪孽？你为何看着奸恶而不理呢？毁灭和强暴在我面前，又起了争端和相斗的事。"},
{volumn:        35,chapter:         1,versenumber:         4,verse:"因此律法放松，公理也不显明；恶人围困义人，所以公理显然颠倒。”"},
{volumn:        35,chapter:         1,versenumber:         5,verse:"耶和华说：“你们要向列国中观看，大大惊奇；因为在你们的时候，我行一件事，虽有人告诉你们，你们总是不信。"},
{volumn:        35,chapter:         1,versenumber:         6,verse:"我必兴起迦勒底人，就是那残忍暴躁之民，通行遍地，占据那不属自己的住处。"},
{volumn:        35,chapter:         1,versenumber:         7,verse:"他威武可畏，判断和势力都任意发出。"},
{volumn:        35,chapter:         1,versenumber:         8,verse:"“他的马比豹更快，比晚上的豺狼更猛。马兵踊跃争先，都从远方而来；他们飞跑如鹰抓食，"},
{volumn:        35,chapter:         1,versenumber:         9,verse:"都为行强暴而来，定住脸面向前，将掳掠的人聚集，多如尘沙。"},
{volumn:        35,chapter:         1,versenumber:        10,verse:"他们讥诮君王，笑话首领，嗤笑一切保障，筑垒攻取。"},
{volumn:        35,chapter:         1,versenumber:        11,verse:"他以自己的势力为神，像风猛然扫过，显为有罪。”"},
{volumn:        35,chapter:         1,versenumber:        12,verse:"耶和华我的　神，我的圣者啊，你不是从亘古而有吗？我们必不致死。耶和华啊，你派定他为要刑罚人；磐石啊，你设立他为要惩治人。"},
{volumn:        35,chapter:         1,versenumber:        13,verse:"你眼目清洁，不看邪僻，不看奸恶；行诡诈的，你为何看着不理呢？恶人吞灭比自己公义的，你为何静默不语呢？"},
{volumn:        35,chapter:         1,versenumber:        14,verse:"你为何使人如海中的鱼，又如没有管辖的爬物呢？"},
{volumn:        35,chapter:         1,versenumber:        15,verse:"他用钩钩住，用网捕获，用拉网聚集他们；因此，他欢喜快乐，"},
{volumn:        35,chapter:         1,versenumber:        16,verse:"就向网献祭，向网烧香，因他由此得肥美的份和富裕的食物。"},
{volumn:        35,chapter:         1,versenumber:        17,verse:"他岂可屡次倒空网罗，将列国的人时常杀戮，毫不顾惜呢？"},
{volumn:        35,chapter:         2,versenumber:         1,verse:"我要站在守望所，立在望楼上观看，看耶和华对我说什么话，我可用什么话向他诉冤（向他诉冤：或译回答所疑问的）。"},
{volumn:        35,chapter:         2,versenumber:         2,verse:"他对我说：“将这默示明明地写在版上，使读的人容易读（或译：随跑随读）。"},
{volumn:        35,chapter:         2,versenumber:         3,verse:"因为这默示有一定的日期，快要应验，并不虚谎。虽然迟延，还要等候；因为必然临到，不再迟延。”"},
{volumn:        35,chapter:         2,versenumber:         4,verse:"迦勒底人自高自大，心不正直；惟义人因信得生。"},
{volumn:        35,chapter:         2,versenumber:         5,verse:"迦勒底人因酒诡诈，狂傲，不住在家中，扩充心欲，好像阴间。他如死不能知足，聚集万国，堆积万民都归自己。"},
{volumn:        35,chapter:         2,versenumber:         6,verse:"这些国的民岂不都要提起诗歌并俗语讥刺他说：“祸哉！迦勒底人，你增添不属自己的财物，多多取人的当头，要到几时为止呢？”"},
{volumn:        35,chapter:         2,versenumber:         7,verse:"咬伤你的岂不忽然起来，扰害你的岂不兴起，你就作他们的掳物吗？"},
{volumn:        35,chapter:         2,versenumber:         8,verse:"因你抢夺许多的国，杀人流血，向国内的城并城中一切居民施行强暴，所以各国剩下的民都必抢夺你。"},
{volumn:        35,chapter:         2,versenumber:         9,verse:"为本家积蓄不义之财、在高处搭窝、指望免灾的有祸了！"},
{volumn:        35,chapter:         2,versenumber:        10,verse:"你图谋剪除多国的民，犯了罪，使你的家蒙羞，自害己命。"},
{volumn:        35,chapter:         2,versenumber:        11,verse:"墙里的石头必呼叫；房内的栋梁必应声。"},
{volumn:        35,chapter:         2,versenumber:        12,verse:"以人血建城、以罪孽立邑的有祸了！"},
{volumn:        35,chapter:         2,versenumber:        13,verse:"众民所劳碌得来的被火焚烧，列国由劳乏而得的归于虚空，不都是出于万军之耶和华吗？"},
{volumn:        35,chapter:         2,versenumber:        14,verse:"认识耶和华荣耀的知识要充满遍地，好像水充满洋海一般。"},
{volumn:        35,chapter:         2,versenumber:        15,verse:"给人酒喝、又加上毒物、使他喝醉、好看见他下体的有祸了！"},
{volumn:        35,chapter:         2,versenumber:        16,verse:"你满受羞辱，不得荣耀；你也喝吧，显出是未受割礼的！耶和华右手的杯必传到你那里；你的荣耀就变为大大地羞辱。"},
{volumn:        35,chapter:         2,versenumber:        17,verse:"你向黎巴嫩行强暴与残害惊吓野兽的事必遮盖你；因你杀人流血，向国内的城并城中一切居民施行强暴。"},
{volumn:        35,chapter:         2,versenumber:        18,verse:"雕刻的偶像，人将它刻出来，有什么益处呢？铸造的偶像就是虚谎的师傅。制造者倚靠这哑巴偶像有什么益处呢？"},
{volumn:        35,chapter:         2,versenumber:        19,verse:"对木偶说：“醒起！”对哑巴石像说：“起来！”那人有祸了！这个还能教训人吗？看哪，是包裹金银的，其中毫无气息。"},
{volumn:        35,chapter:         2,versenumber:        20,verse:"惟耶和华在他的圣殿中；全地的人都当在他面前肃敬静默。"},
{volumn:        35,chapter:         3,versenumber:         1,verse:"先知哈巴谷的祷告，调用流离歌。"},
{volumn:        35,chapter:         3,versenumber:         2,verse:"耶和华啊，我听见你的名声（或译：言语）就惧怕。耶和华啊，求你在这些年间复兴你的作为，在这些年间显明出来；在发怒的时候以怜悯为念。"},
{volumn:        35,chapter:         3,versenumber:         3,verse:"神从提幔而来；圣者从巴兰山临到。（细拉）他的荣光遮蔽诸天；颂赞充满大地。"},
{volumn:        35,chapter:         3,versenumber:         4,verse:"他的辉煌如同日光；从他手里射出光线，在其中藏着他的能力。"},
{volumn:        35,chapter:         3,versenumber:         5,verse:"在他前面有瘟疫流行；在他脚下有热症发出。"},
{volumn:        35,chapter:         3,versenumber:         6,verse:"他站立，量了大地（或译：使地震动），观看，赶散万民。永久的山崩裂；长存的岭塌陷；他的作为与古时一样。"},
{volumn:        35,chapter:         3,versenumber:         7,verse:"我见古珊的帐棚遭难，米甸的幔子战兢。"},
{volumn:        35,chapter:         3,versenumber:         8,verse:"耶和华啊，你乘在马上，坐在得胜的车上，岂是不喜悦江河、向江河发怒气、向洋海发愤恨吗？"},
{volumn:        35,chapter:         3,versenumber:         9,verse:"你的弓全然显露，向众支派所起的誓都是可信的。（细拉）你以江河分开大地。"},
{volumn:        35,chapter:         3,versenumber:        10,verse:"山岭见你，无不战惧；大水泛滥过去，深渊发声，汹涌翻腾（原文是向上举手）。"},
{volumn:        35,chapter:         3,versenumber:        11,verse:"因你的箭射出发光，你的枪闪出光耀，日月都在本宫停住。"},
{volumn:        35,chapter:         3,versenumber:        12,verse:"你发忿恨通行大地，发怒气责打列国，如同打粮。"},
{volumn:        35,chapter:         3,versenumber:        13,verse:"你出来要拯救你的百姓，拯救你的受膏者，打破恶人家长的头，露出他的脚（原文是根基），直到颈项。（细拉）"},
{volumn:        35,chapter:         3,versenumber:        14,verse:"你用敌人的戈矛刺透他战士的头；他们来如旋风，要将我们分散。他们所喜爱的是暗中吞吃贫民。"},
{volumn:        35,chapter:         3,versenumber:        15,verse:"你乘马践踏红海，就是践踏汹涌的大水。"},
{volumn:        35,chapter:         3,versenumber:        16,verse:"我听见耶和华的声音，身体战兢，嘴唇发颤，骨中朽烂；我在所立之处战兢。我只可安静等候灾难之日临到，犯境之民上来。"},
{volumn:        35,chapter:         3,versenumber:        17,verse:"虽然无花果树不发旺，葡萄树不结果，橄榄树也不效力，田地不出粮食，圈中绝了羊，棚内也没有牛；"},
{volumn:        35,chapter:         3,versenumber:        18,verse:"然而，我要因耶和华欢欣，因救我的　神喜乐。"},
{volumn:        35,chapter:         3,versenumber:        19,verse:"主耶和华是我的力量；他使我的脚快如母鹿的蹄，又使我稳行在高处。这歌交与伶长，用丝弦的乐器。"},
{volumn:        36,chapter:         1,versenumber:         1,verse:"当犹大王亚们的儿子约西亚在位的时候，耶和华的话临到希西家的玄孙，亚玛利雅的曾孙，基大利的孙子，古示的儿子西番雅。"},
{volumn:        36,chapter:         1,versenumber:         2,verse:"耶和华说：“我必从地上除灭万类。"},
{volumn:        36,chapter:         1,versenumber:         3,verse:"我必除灭人和牲畜，与空中的鸟、海里的鱼，以及绊脚石和恶人；我必将人从地上剪除。”这是耶和华说的。"},
{volumn:        36,chapter:         1,versenumber:         4,verse:"“我必伸手攻击犹大和耶路撒冷的一切居民，也必从这地方剪除所剩下的巴力，并基玛林的名和祭司，"},
{volumn:        36,chapter:         1,versenumber:         5,verse:"与那些在房顶上敬拜天上万象的，并那些敬拜耶和华指着他起誓，又指着玛勒堪起誓的，"},
{volumn:        36,chapter:         1,versenumber:         6,verse:"与那些转去不跟从耶和华的，和不寻求耶和华也不访问他的。”"},
{volumn:        36,chapter:         1,versenumber:         7,verse:"你要在主耶和华面前静默无声，因为耶和华的日子快到。耶和华已经预备祭物，将他的客分别为圣。"},
{volumn:        36,chapter:         1,versenumber:         8,verse:"“到了我耶和华献祭的日子，必惩罚首领和王子，并一切穿外邦衣服的。"},
{volumn:        36,chapter:         1,versenumber:         9,verse:"到那日，我必惩罚一切跳过门槛、将强暴和诡诈得来之物充满主人房屋的。”"},
{volumn:        36,chapter:         1,versenumber:        10,verse:"耶和华说：“当那日，从鱼门必发出悲哀的声音，从二城发出哀号的声音，从山间发出大破裂的响声。"},
{volumn:        36,chapter:         1,versenumber:        11,verse:"玛革提施的居民哪，你们要哀号，因为迦南的商民都灭亡了！凡搬运银子的都被剪除。"},
{volumn:        36,chapter:         1,versenumber:        12,verse:"那时，我必用灯巡查耶路撒冷；我必惩罚那些如酒在渣滓上澄清的；他们心里说：‘耶和华必不降福，也不降祸。’"},
{volumn:        36,chapter:         1,versenumber:        13,verse:"他们的财宝必成为掠物；他们的房屋必变为荒场。他们必建造房屋，却不得住在其内；栽种葡萄园，却不得喝所出的酒。”"},
{volumn:        36,chapter:         1,versenumber:        14,verse:"耶和华的大日临近，临近而且甚快，乃是耶和华日子的风声；勇士必痛痛地哭号。"},
{volumn:        36,chapter:         1,versenumber:        15,verse:"那日是忿怒的日子，是急难困苦的日子，是荒废凄凉的日子，是黑暗幽冥、密云乌黑的日子，"},
{volumn:        36,chapter:         1,versenumber:        16,verse:"是吹角呐喊的日子，要攻击坚固城和高大的城楼。"},
{volumn:        36,chapter:         1,versenumber:        17,verse:"我必使灾祸临到人身上，使他们行走如同瞎眼的，因为得罪了我。他们的血必倒出如灰尘；他们的肉必抛弃如粪土。"},
{volumn:        36,chapter:         1,versenumber:        18,verse:"当耶和华发怒的日子，他们的金银不能救他们；他的忿怒如火必烧灭全地，毁灭这地的一切居民，而且大大毁灭。"},
{volumn:        36,chapter:         2,versenumber:         1,verse:"不知羞耻的国民哪，你们应当聚集！趁命令没有发出，日子过去如风前的糠，耶和华的烈怒未临到你们，他发怒的日子未到以先，你们应当聚集前来。"},
{volumn:        36,chapter:         2,versenumber:         2,verse:""},
{volumn:        36,chapter:         2,versenumber:         3,verse:"世上遵守耶和华典章的谦卑人哪，你们都当寻求耶和华！当寻求公义谦卑，或者在耶和华发怒的日子可以隐藏起来。"},
{volumn:        36,chapter:         2,versenumber:         4,verse:"迦萨必致见弃；亚实基伦必然荒凉。人在正午必赶出亚实突的民；以革伦也被拔出根来。"},
{volumn:        36,chapter:         2,versenumber:         5,verse:"住沿海之地的基利提族有祸了！迦南、非利士人之地啊，耶和华的话与你反对，说：“我必毁灭你，以致无人居住。"},
{volumn:        36,chapter:         2,versenumber:         6,verse:"沿海之地要变为草场，其上有牧人的住处和羊群的圈。”"},
{volumn:        36,chapter:         2,versenumber:         7,verse:"这地必为犹大家剩下的人所得；他们必在那里牧放群羊，晚上必躺卧在亚实基伦的房屋中；因为耶和华他们的　神必眷顾他们，使他们被掳的人归回。"},
{volumn:        36,chapter:         2,versenumber:         8,verse:"“我听见摩押人的毁谤和亚扪人的辱骂，就是毁谤我的百姓，自夸自大，侵犯他们的境界。”"},
{volumn:        36,chapter:         2,versenumber:         9,verse:"万军之耶和华以色列的　神说：“我指着我的永生起誓：摩押必像所多玛，亚扪人必像蛾摩拉，都变为刺草、盐坑、永远荒废之地。我百姓所剩下的必掳掠他们；我国中所余剩的必得着他们的地。”"},
{volumn:        36,chapter:         2,versenumber:        10,verse:"这事临到他们是因他们骄傲，自夸自大，毁谤万军之耶和华的百姓。"},
{volumn:        36,chapter:         2,versenumber:        11,verse:"耶和华必向他们显可畏之威，因他必叫世上的诸神瘦弱。列国海岛的居民各在自己的地方敬拜他。"},
{volumn:        36,chapter:         2,versenumber:        12,verse:"古实人哪，你们必被我的刀所杀。"},
{volumn:        36,chapter:         2,versenumber:        13,verse:"耶和华必伸手攻击北方，毁灭亚述，使尼尼微荒凉，又干旱如旷野。"},
{volumn:        36,chapter:         2,versenumber:        14,verse:"群畜，就是各国（国：或译类）的走兽必卧在其中；鹈鹕和箭猪要宿在柱顶上。在窗户内有鸣叫的声音；门槛都必毁坏，香柏木已经露出。"},
{volumn:        36,chapter:         2,versenumber:        15,verse:"这是素来欢乐安然居住的城，心里说：“惟有我，除我以外再没有别的。”现在何竟荒凉成为野兽躺卧之处！凡经过的人都必摇手嗤笑她。"},
{volumn:        36,chapter:         3,versenumber:         1,verse:"这悖逆、污秽、欺压的城有祸了！"},
{volumn:        36,chapter:         3,versenumber:         2,verse:"她不听从命令，不领受训诲，不倚靠耶和华，不亲近她的　神。"},
{volumn:        36,chapter:         3,versenumber:         3,verse:"她中间的首领是咆哮的狮子；她的审判官是晚上的豺狼，一点食物也不留到早晨。"},
{volumn:        36,chapter:         3,versenumber:         4,verse:"她的先知是虚浮诡诈的人；她的祭司亵渎圣所，强解律法。"},
{volumn:        36,chapter:         3,versenumber:         5,verse:"耶和华在她中间是公义的，断不做非义的事，每早晨显明他的公义，无日不然；只是不义的人不知羞耻。"},
{volumn:        36,chapter:         3,versenumber:         6,verse:"“我耶和华已经除灭列国的民；他们的城楼毁坏。我使他们的街道荒凉，以致无人经过；他们的城邑毁灭，以致无人，也无居民。"},
{volumn:        36,chapter:         3,versenumber:         7,verse:"我说：你只要敬畏我，领受训诲；如此，你的住处不致照我所拟定的除灭。只是你们从早起来，就在一切事上败坏自己。”"},
{volumn:        36,chapter:         3,versenumber:         8,verse:"耶和华说：“你们要等候我，直到我兴起掳掠的日子；因为我已定意招聚列国，聚集列邦，将我的恼怒，就是我的烈怒，都倾在她们身上。我的忿怒如火，必烧灭全地。"},
{volumn:        36,chapter:         3,versenumber:         9,verse:"“那时，我必使万民用清洁的言语好求告我耶和华的名，同心合意地侍奉我。"},
{volumn:        36,chapter:         3,versenumber:        10,verse:"祈祷我的，就是我所分散的民（原文是女子；下同），必从古实河外来，给我献供物。"},
{volumn:        36,chapter:         3,versenumber:        11,verse:"当那日，你必不因你一切得罪我的事自觉羞愧；因为那时我必从你中间除掉矜夸高傲之辈，你也不再于我的圣山狂傲。"},
{volumn:        36,chapter:         3,versenumber:        12,verse:"我却要在你中间留下困苦贫寒的民；他们必投靠我耶和华的名。"},
{volumn:        36,chapter:         3,versenumber:        13,verse:"以色列所剩下的人必不作罪孽，不说谎言，口中也没有诡诈的舌头；而且吃喝躺卧，无人惊吓。”"},
{volumn:        36,chapter:         3,versenumber:        14,verse:"锡安的民哪，应当歌唱！以色列啊，应当欢呼！耶路撒冷的民哪，应当满心欢喜快乐！"},
{volumn:        36,chapter:         3,versenumber:        15,verse:"耶和华已经除去你的刑罚，赶出你的仇敌。以色列的王耶和华在你中间；你必不再惧怕灾祸。"},
{volumn:        36,chapter:         3,versenumber:        16,verse:"当那日，必有话向耶路撒冷说：“不要惧怕！锡安哪；不要手软！"},
{volumn:        36,chapter:         3,versenumber:        17,verse:"耶和华你的　神是施行拯救、大有能力的主。他在你中间必因你欢欣喜乐，默然爱你，且因你喜乐而欢呼。"},
{volumn:        36,chapter:         3,versenumber:        18,verse:"那些属你、为无大会愁烦、因你担当羞辱的，我必聚集他们。"},
{volumn:        36,chapter:         3,versenumber:        19,verse:"那时，我必罚办一切苦待你的人，又拯救你瘸腿的，聚集你被赶出的。那些在全地受羞辱的，我必使他们得称赞，有名声。"},
{volumn:        36,chapter:         3,versenumber:        20,verse:"那时，我必领你们进来，聚集你们；我使你们被掳之人归回的时候，就必使你们在地上的万民中有名声，得称赞。”这是耶和华说的。"},
{volumn:        37,chapter:         1,versenumber:         1,verse:"大流士王第二年六月初一日，耶和华的话藉先知哈该向犹大省长撒拉铁的儿子所罗巴伯和约撒答的儿子大祭司约书亚说："},
{volumn:        37,chapter:         1,versenumber:         2,verse:"万军之耶和华如此说：“这百姓说，建造耶和华殿的时候尚未来到。”"},
{volumn:        37,chapter:         1,versenumber:         3,verse:"那时耶和华的话临到先知哈该说："},
{volumn:        37,chapter:         1,versenumber:         4,verse:"“这殿仍然荒凉，你们自己还住天花板的房屋吗？"},
{volumn:        37,chapter:         1,versenumber:         5,verse:"现在万军之耶和华如此说：你们要省察自己的行为。"},
{volumn:        37,chapter:         1,versenumber:         6,verse:"你们撒的种多，收的却少；你们吃，却不得饱；喝，却不得足；穿衣服，却不得暖；得工钱的，将工钱装在破漏的囊中。”"},
{volumn:        37,chapter:         1,versenumber:         7,verse:"万军之耶和华如此说：“你们要省察自己的行为。"},
{volumn:        37,chapter:         1,versenumber:         8,verse:"你们要上山取木料，建造这殿，我就因此喜乐，且得荣耀。这是耶和华说的。"},
{volumn:        37,chapter:         1,versenumber:         9,verse:"你们盼望多得，所得的却少；你们收到家中，我就吹去。这是为什么呢？因为我的殿荒凉，你们各人却顾（原文是奔）自己的房屋。这是万军之耶和华说的。"},
{volumn:        37,chapter:         1,versenumber:        10,verse:"所以为你们的缘故，天就不降甘露，地也不出土产。"},
{volumn:        37,chapter:         1,versenumber:        11,verse:"我命干旱临到地土、山冈、五谷、新酒，和油，并地上的出产、人民、牲畜，以及人手一切劳碌得来的。”"},
{volumn:        37,chapter:         1,versenumber:        12,verse:"那时，撒拉铁的儿子所罗巴伯和约撒答的儿子大祭司约书亚，并剩下的百姓，都听从耶和华他们　神的话和先知哈该奉耶和华他们　神差来所说的话；百姓也在耶和华面前存敬畏的心。"},
{volumn:        37,chapter:         1,versenumber:        13,verse:"耶和华的使者哈该奉耶和华差遣对百姓说：“耶和华说：我与你们同在。”"},
{volumn:        37,chapter:         1,versenumber:        14,verse:"耶和华激动犹大省长撒拉铁的儿子所罗巴伯和约撒答的儿子大祭司约书亚，并剩下之百姓的心，他们就来为万军之耶和华他们　神的殿做工。"},
{volumn:        37,chapter:         1,versenumber:        15,verse:"这是在大流士王第二年六月二十四日。"},
{volumn:        37,chapter:         2,versenumber:         1,verse:"七月二十一日，耶和华的话临到先知哈该说："},
{volumn:        37,chapter:         2,versenumber:         2,verse:"“你要晓谕犹大省长撒拉铁的儿子所罗巴伯和约撒答的儿子大祭司约书亚，并剩下的百姓，说："},
{volumn:        37,chapter:         2,versenumber:         3,verse:"‘你们中间存留的，有谁见过这殿从前的荣耀呢？现在你们看着如何？岂不在眼中看如无有吗？’”"},
{volumn:        37,chapter:         2,versenumber:         4,verse:"耶和华说：“所罗巴伯啊，虽然如此，你当刚强！约撒答的儿子大祭司约书亚啊，你也当刚强！这地的百姓，你们都当刚强做工，因为我与你们同在。这是万军之耶和华说的。"},
{volumn:        37,chapter:         2,versenumber:         5,verse:"这是照着你们出埃及我与你们立约的话。那时，我的灵住在你们中间，你们不要惧怕。”"},
{volumn:        37,chapter:         2,versenumber:         6,verse:"万军之耶和华如此说：“过不多时，我必再一次震动天地、沧海，与旱地。"},
{volumn:        37,chapter:         2,versenumber:         7,verse:"我必震动万国；万国的珍宝必都运来（或译：万国所羡慕的必来到），我就使这殿满了荣耀。这是万军之耶和华说的。”"},
{volumn:        37,chapter:         2,versenumber:         8,verse:"万军之耶和华说：“银子是我的，金子也是我的。"},
{volumn:        37,chapter:         2,versenumber:         9,verse:"这殿后来的荣耀必大过先前的荣耀；在这地方我必赐平安。这是万军之耶和华说的。”"},
{volumn:        37,chapter:         2,versenumber:        10,verse:"大流士王第二年九月二十四日，耶和华的话临到先知哈该说："},
{volumn:        37,chapter:         2,versenumber:        11,verse:"“万军之耶和华如此说：你要向祭司问律法，"},
{volumn:        37,chapter:         2,versenumber:        12,verse:"说：若有人用衣襟兜圣肉，这衣襟挨着饼，或汤，或酒，或油，或别的食物，便算为圣吗？”祭司说：“不算为圣。”"},
{volumn:        37,chapter:         2,versenumber:        13,verse:"哈该又说：“若有人因摸死尸染了污秽，然后挨着这些物的哪一样，这物算污秽吗？”祭司说：“必算污秽。”"},
{volumn:        37,chapter:         2,versenumber:        14,verse:"于是哈该说：“耶和华说：这民这国，在我面前也是如此；他们手下的各样工作都是如此；他们在坛上所献的也是如此。”"},
{volumn:        37,chapter:         2,versenumber:        15,verse:"“现在你们要追想，此日以前，耶和华的殿没有一块石头垒在石头上的光景。"},
{volumn:        37,chapter:         2,versenumber:        16,verse:"在那一切日子，有人来到谷堆，想得二十斗，只得了十斗。有人来到酒池，想得五十桶，只得了二十桶。"},
{volumn:        37,chapter:         2,versenumber:        17,verse:"在你们手下的各样工作上，我以旱风、霉烂、冰雹攻击你们，你们仍不归向我。这是耶和华说的。"},
{volumn:        37,chapter:         2,versenumber:        18,verse:"你们要追想此日以前，就是从这九月二十四日起，追想到立耶和华殿根基的日子。"},
{volumn:        37,chapter:         2,versenumber:        19,verse:"仓里有谷种吗？葡萄树、无花果树、石榴树、橄榄树都没有结果子。从今日起，我必赐福与你们。”"},
{volumn:        37,chapter:         2,versenumber:        20,verse:"这月二十四日，耶和华的话二次临到哈该说："},
{volumn:        37,chapter:         2,versenumber:        21,verse:"“你要告诉犹大省长所罗巴伯说：我必震动天地。"},
{volumn:        37,chapter:         2,versenumber:        22,verse:"我必倾覆列国的宝座，除灭列邦的势力，并倾覆战车和坐在其上的。马必跌倒，骑马的败落，各人被弟兄的刀所杀。"},
{volumn:        37,chapter:         2,versenumber:        23,verse:"万军之耶和华说：我仆人撒拉铁的儿子所罗巴伯啊，到那日，我必以你为印，因我拣选了你。这是万军之耶和华说的。”"},
{volumn:        38,chapter:         1,versenumber:         1,verse:"大流士王第二年八月，耶和华的话临到易多的孙子、比利家的儿子先知撒迦利亚，说："},
{volumn:        38,chapter:         1,versenumber:         2,verse:"“耶和华曾向你们列祖大大发怒。"},
{volumn:        38,chapter:         1,versenumber:         3,verse:"所以你要对以色列人说，万军之耶和华如此说：你们要转向我，我就转向你们。这是万军之耶和华说的。"},
{volumn:        38,chapter:         1,versenumber:         4,verse:"不要效法你们列祖。从前的先知呼叫他们说：‘万军之耶和华如此说：你们要回头离开你们的恶道恶行。’他们却不听，也不顺从我。这是耶和华说的。"},
{volumn:        38,chapter:         1,versenumber:         5,verse:"你们的列祖在哪里呢？那些先知能永远存活吗？"},
{volumn:        38,chapter:         1,versenumber:         6,verse:"只是我的言语和律例，就是所吩咐我仆人众先知的，岂不临到你们列祖吗？他们就回头说：‘万军之耶和华定意按我们的行动作为向我们怎样行，他已照样行了。’”"},
{volumn:        38,chapter:         1,versenumber:         7,verse:"大流士第二年十一月，就是细罢特月二十四日，耶和华的话临到易多的孙子、比利家的儿子先知撒迦利亚，说："},
{volumn:        38,chapter:         1,versenumber:         8,verse:"“我夜间观看，见一人骑着红马，站在洼地番石榴树中间。在他身后又有红马、黄马，和白马。”"},
{volumn:        38,chapter:         1,versenumber:         9,verse:"我对与我说话的天使说：“主啊，这是什么意思？”他说：“我要指示你这是什么意思。”"},
{volumn:        38,chapter:         1,versenumber:        10,verse:"那站在番石榴树中间的人说：“这是奉耶和华差遣在遍地走来走去的。”"},
{volumn:        38,chapter:         1,versenumber:        11,verse:"那些骑马的对站在番石榴树中间耶和华的使者说：“我们已在遍地走来走去，见全地都安息平静。”"},
{volumn:        38,chapter:         1,versenumber:        12,verse:"于是，耶和华的使者说：“万军之耶和华啊，你恼恨耶路撒冷和犹大的城邑已经七十年，你不施怜悯要到几时呢？”"},
{volumn:        38,chapter:         1,versenumber:        13,verse:"耶和华就用美善的安慰话回答那与我说话的天使。"},
{volumn:        38,chapter:         1,versenumber:        14,verse:"与我说话的天使对我说：“你要宣告说，万军之耶和华如此说：‘我为耶路撒冷为锡安，心里极其火热。"},
{volumn:        38,chapter:         1,versenumber:        15,verse:"我甚恼怒那安逸的列国，因我从前稍微恼怒我民，他们就加害过分。’"},
{volumn:        38,chapter:         1,versenumber:        16,verse:"所以耶和华如此说：‘现今我回到耶路撒冷，仍施怜悯，我的殿必重建在其中，准绳必拉在耶路撒冷之上。’这是万军之耶和华说的。"},
{volumn:        38,chapter:         1,versenumber:        17,verse:"你要再宣告说，万军之耶和华如此说：‘我的城邑必再丰盛发达。耶和华必再安慰锡安，拣选耶路撒冷。’”"},
{volumn:        38,chapter:         1,versenumber:        18,verse:"我举目观看，见有四角。"},
{volumn:        38,chapter:         1,versenumber:        19,verse:"我就问与我说话的天使说：“这是什么意思？”他回答说：“这是打散犹大、以色列，和耶路撒冷的角。”"},
{volumn:        38,chapter:         1,versenumber:        20,verse:"耶和华又指四个匠人给我看。"},
{volumn:        38,chapter:         1,versenumber:        21,verse:"我说：“他们来做什么呢？”他说：“这是打散犹大的角，使人不敢抬头；但这些匠人来威吓列国，打掉他们的角，就是举起打散犹大地的角。”"},
{volumn:        38,chapter:         2,versenumber:         1,verse:"我又举目观看，见一人手拿准绳。"},
{volumn:        38,chapter:         2,versenumber:         2,verse:"我说：“你往哪里去？”他对我说：“要去量耶路撒冷，看有多宽多长。”"},
{volumn:        38,chapter:         2,versenumber:         3,verse:"与我说话的天使去的时候，又有一位天使迎着他来，"},
{volumn:        38,chapter:         2,versenumber:         4,verse:"对他说：“你跑去告诉那少年人说，耶路撒冷必有人居住，好像无城墙的乡村，因为人民和牲畜甚多。"},
{volumn:        38,chapter:         2,versenumber:         5,verse:"耶和华说：我要作耶路撒冷四围的火城，并要作其中的荣耀。”"},
{volumn:        38,chapter:         2,versenumber:         6,verse:"耶和华说：“我从前分散你们在天的四方（原文是犹如天的四风），现在你们要从北方之地逃回。这是耶和华说的。"},
{volumn:        38,chapter:         2,versenumber:         7,verse:"与巴比伦人同住的锡安民哪，应当逃脱。"},
{volumn:        38,chapter:         2,versenumber:         8,verse:"万军之耶和华说，在显出荣耀之后，差遣我去惩罚那掳掠你们的列国，摸你们的就是摸他眼中的瞳人。"},
{volumn:        38,chapter:         2,versenumber:         9,verse:"看哪，我（或译：他）要向他们抡手，他们就必作服侍他们之人的掳物，你们便知道万军之耶和华差遣我了。"},
{volumn:        38,chapter:         2,versenumber:        10,verse:"锡安城啊，应当欢乐歌唱，因为我来要住在你中间。这是耶和华说的。”"},
{volumn:        38,chapter:         2,versenumber:        11,verse:"那时，必有许多国归附耶和华，作他（原文是我）的子民。他（原文是我）要住在你中间，你就知道万军之耶和华差遣我到你那里去了。"},
{volumn:        38,chapter:         2,versenumber:        12,verse:"耶和华必收回犹大作他圣地的份，也必再拣选耶路撒冷。"},
{volumn:        38,chapter:         2,versenumber:        13,verse:"凡有血气的都当在耶和华面前静默无声；因为他兴起，从圣所出来了。"},
{volumn:        38,chapter:         3,versenumber:         1,verse:"天使（原文是他）又指给我看：大祭司约书亚站在耶和华的使者面前；撒但也站在约书亚的右边，与他作对。"},
{volumn:        38,chapter:         3,versenumber:         2,verse:"耶和华向撒但说：“撒但哪，耶和华责备你！就是拣选耶路撒冷的耶和华责备你！这不是从火中抽出来的一根柴吗？”"},
{volumn:        38,chapter:         3,versenumber:         3,verse:"约书亚穿着污秽的衣服站在使者面前。"},
{volumn:        38,chapter:         3,versenumber:         4,verse:"使者吩咐站在面前的说：“你们要脱去他污秽的衣服”；又对约书亚说：“我使你脱离罪孽，要给你穿上华美的衣服。”"},
{volumn:        38,chapter:         3,versenumber:         5,verse:"我说：“要将洁净的冠冕戴在他头上。”他们就把洁净的冠冕戴在他头上，给他穿上华美的衣服，耶和华的使者在旁边站立。"},
{volumn:        38,chapter:         3,versenumber:         6,verse:"耶和华的使者告诫约书亚说："},
{volumn:        38,chapter:         3,versenumber:         7,verse:"“万军之耶和华如此说：你若遵行我的道，谨守我的命令，你就可以管理我的家，看守我的院宇；我也要使你在这些站立的人中间来往。"},
{volumn:        38,chapter:         3,versenumber:         8,verse:"大祭司约书亚啊，你和坐在你面前的同伴都当听。（他们是作预兆的。）我必使我仆人大卫的苗裔发出。"},
{volumn:        38,chapter:         3,versenumber:         9,verse:"看哪，我在约书亚面前所立的石头，在一块石头上有七眼。万军之耶和华说：我要亲自雕刻这石头，并要在一日之间除掉这地的罪孽。"},
{volumn:        38,chapter:         3,versenumber:        10,verse:"当那日，你们各人要请邻舍坐在葡萄树和无花果树下。这是万军之耶和华说的。”"},
{volumn:        38,chapter:         4,versenumber:         1,verse:"那与我说话的天使又来叫醒我，好像人睡觉被唤醒一样。"},
{volumn:        38,chapter:         4,versenumber:         2,verse:"他问我说：“你看见了什么？”我说：“我看见了一个纯金的灯台，顶上有灯盏，灯台上有七盏灯，每盏有七个管子。"},
{volumn:        38,chapter:         4,versenumber:         3,verse:"旁边有两棵橄榄树，一棵在灯盏的右边，一棵在灯盏的左边。”"},
{volumn:        38,chapter:         4,versenumber:         4,verse:"我问与我说话的天使说：“主啊，这是什么意思？”"},
{volumn:        38,chapter:         4,versenumber:         5,verse:"与我说话的天使回答我说：“你不知道这是什么意思吗？”我说：“主啊，我不知道。”"},
{volumn:        38,chapter:         4,versenumber:         6,verse:"他对我说：“这是耶和华指示所罗巴伯的。万军之耶和华说：不是倚靠势力，不是倚靠才能，乃是倚靠我的灵方能成事。"},
{volumn:        38,chapter:         4,versenumber:         7,verse:"大山哪，你算什么呢？在所罗巴伯面前，你必成为平地。他必搬出一块石头，安在殿顶上。人且大声欢呼说：‘愿恩惠恩惠归与这殿（殿：或译石）！’”"},
{volumn:        38,chapter:         4,versenumber:         8,verse:"耶和华的话又临到我说："},
{volumn:        38,chapter:         4,versenumber:         9,verse:"“所罗巴伯的手立了这殿的根基，他的手也必完成这工，你就知道万军之耶和华差遣我到你们这里来了。"},
{volumn:        38,chapter:         4,versenumber:        10,verse:"谁藐视这日的事为小呢？这七眼乃是耶和华的眼睛，遍察全地，见所罗巴伯手拿线铊就欢喜。”"},
{volumn:        38,chapter:         4,versenumber:        11,verse:"我又问天使说：“这灯台左右的两棵橄榄树是什么意思？”"},
{volumn:        38,chapter:         4,versenumber:        12,verse:"我二次问他说：“这两根橄榄枝在两个流出金色油的金嘴旁边是什么意思？”"},
{volumn:        38,chapter:         4,versenumber:        13,verse:"他对我说：“你不知道这是什么意思吗？”我说：“主啊，我不知道。”"},
{volumn:        38,chapter:         4,versenumber:        14,verse:"他说：“这是两个受膏者站在普天下主的旁边。”"},
{volumn:        38,chapter:         5,versenumber:         1,verse:"我又举目观看，见有一飞行的书卷。"},
{volumn:        38,chapter:         5,versenumber:         2,verse:"他问我说：“你看见什么？”我回答说：“我看见一飞行的书卷，长二十肘，宽十肘。”"},
{volumn:        38,chapter:         5,versenumber:         3,verse:"他对我说：“这是发出行在遍地上的咒诅。凡偷窃的必按卷上这面的话除灭；凡起假誓的必按卷上那面的话除灭。"},
{volumn:        38,chapter:         5,versenumber:         4,verse:"万军之耶和华说：我必使这书卷出去，进入偷窃人的家和指我名起假誓人的家，必常在他家里，连房屋带木石都毁灭了。”"},
{volumn:        38,chapter:         5,versenumber:         5,verse:"与我说话的天使出来，对我说：“你要举目观看，见所出来的是什么？”"},
{volumn:        38,chapter:         5,versenumber:         6,verse:"我说：“这是什么呢？”他说：“这出来的是量器。”他又说：“这是恶人在遍地的形状。”"},
{volumn:        38,chapter:         5,versenumber:         7,verse:"（我见有一片圆铅被举起来。）这坐在量器中的是个妇人。"},
{volumn:        38,chapter:         5,versenumber:         8,verse:"天使说：“这是罪恶。”他就把妇人扔在量器中，将那片圆铅扔在量器的口上。"},
{volumn:        38,chapter:         5,versenumber:         9,verse:"我又举目观看，见有两个妇人出来，在她们翅膀中有风，飞得甚快，翅膀如同鹳鸟的翅膀。她们将量器抬起来，悬在天地中间。"},
{volumn:        38,chapter:         5,versenumber:        10,verse:"我问与我说话的天使说：“她们要将量器抬到哪里去呢？”"},
{volumn:        38,chapter:         5,versenumber:        11,verse:"他对我说：“要往示拿地去，为它盖造房屋；等房屋齐备，就把它安置在自己的地方。”"},
{volumn:        38,chapter:         6,versenumber:         1,verse:"我又举目观看，见有四辆车从两山中间出来；那山是铜山。"},
{volumn:        38,chapter:         6,versenumber:         2,verse:"第一辆车套着红马，第二辆车套着黑马。"},
{volumn:        38,chapter:         6,versenumber:         3,verse:"第三辆车套着白马，第四辆车套着有斑点的壮马。"},
{volumn:        38,chapter:         6,versenumber:         4,verse:"我就问与我说话的天使说：“主啊，这是什么意思？”"},
{volumn:        38,chapter:         6,versenumber:         5,verse:"天使回答我说：“这是天的四风，是从普天下的主面前出来的。”"},
{volumn:        38,chapter:         6,versenumber:         6,verse:"套着黑马的车往北方去，白马跟随在后；有斑点的马往南方去。"},
{volumn:        38,chapter:         6,versenumber:         7,verse:"壮马出来，要在遍地走来走去。天使说：“你们只管在遍地走来走去。”它们就照样行了。"},
{volumn:        38,chapter:         6,versenumber:         8,verse:"他又呼叫我说：“看哪，往北方去的已在北方安慰我的心。”"},
{volumn:        38,chapter:         6,versenumber:         9,verse:"耶和华的话临到我说："},
{volumn:        38,chapter:         6,versenumber:        10,verse:"“你要从被掳之人中取黑玳、多比雅、耶大雅的金银。这三人是从巴比伦来到西番雅的儿子约西亚的家里。当日你要进他的家，"},
{volumn:        38,chapter:         6,versenumber:        11,verse:"取这金银做冠冕，戴在约撒答的儿子大祭司约书亚的头上，"},
{volumn:        38,chapter:         6,versenumber:        12,verse:"对他说，万军之耶和华如此说：看哪，那名称为大卫苗裔的，他要在本处长起来，并要建造耶和华的殿。"},
{volumn:        38,chapter:         6,versenumber:        13,verse:"他要建造耶和华的殿，并担负尊荣，坐在位上掌王权；又必在位上作祭司，使两职之间筹定和平。"},
{volumn:        38,chapter:         6,versenumber:        14,verse:"这冠冕要归希连（就是黑玳）、多比雅、耶大雅，和西番雅的儿子贤（就是约西亚），放在耶和华的殿里为记念。”"},
{volumn:        38,chapter:         6,versenumber:        15,verse:"远方的人也要来建造耶和华的殿，你们就知道万军之耶和华差遣我到你们这里来。你们若留意听从耶和华你们　神的话，这事必然成就。"},
{volumn:        38,chapter:         7,versenumber:         1,verse:"大流士王第四年九月，就是基斯流月初四日，耶和华的话临到撒迦利亚。"},
{volumn:        38,chapter:         7,versenumber:         2,verse:"那时伯特利人已经打发沙利色和利坚米勒，并跟从他们的人，去恳求耶和华的恩，"},
{volumn:        38,chapter:         7,versenumber:         3,verse:"并问万军之耶和华殿中的祭司和先知说：“我历年以来，在五月间哭泣斋戒，现在还当这样行吗？”"},
{volumn:        38,chapter:         7,versenumber:         4,verse:"万军之耶和华的话就临到我说："},
{volumn:        38,chapter:         7,versenumber:         5,verse:"“你要宣告国内的众民和祭司，说：‘你们这七十年，在五月、七月禁食悲哀，岂是丝毫向我禁食吗？"},
{volumn:        38,chapter:         7,versenumber:         6,verse:"你们吃喝，不是为自己吃，为自己喝吗？"},
{volumn:        38,chapter:         7,versenumber:         7,verse:"当耶路撒冷和四围的城邑有居民，正兴盛，南地高原有人居住的时候，耶和华藉从前的先知所宣告的话，你们不当听吗？’”"},
{volumn:        38,chapter:         7,versenumber:         8,verse:"耶和华的话又临到撒迦利亚说："},
{volumn:        38,chapter:         7,versenumber:         9,verse:"“万军之耶和华曾对你们的列祖如此说：‘要按至理判断，各人以慈爱怜悯弟兄。"},
{volumn:        38,chapter:         7,versenumber:        10,verse:"不可欺压寡妇、孤儿、寄居的，和贫穷人。谁都不可心里谋害弟兄。’”"},
{volumn:        38,chapter:         7,versenumber:        11,verse:"他们却不肯听从，扭转肩头，塞耳不听，"},
{volumn:        38,chapter:         7,versenumber:        12,verse:"使心硬如金钢石，不听律法和万军之耶和华用灵藉从前的先知所说的话。故此，万军之耶和华大发烈怒。"},
{volumn:        38,chapter:         7,versenumber:        13,verse:"万军之耶和华说：“我曾呼唤他们，他们不听；将来他们呼求我，我也不听！"},
{volumn:        38,chapter:         7,versenumber:        14,verse:"我必以旋风吹散他们到素不认识的万国中。这样，他们的地就荒凉，甚至无人来往经过，因为他们使美好之地荒凉了。”"},
{volumn:        38,chapter:         8,versenumber:         1,verse:"万军之耶和华的话临到我说："},
{volumn:        38,chapter:         8,versenumber:         2,verse:"“万军之耶和华如此说：我为锡安心里极其火热，我为她火热，向她的仇敌发烈怒。"},
{volumn:        38,chapter:         8,versenumber:         3,verse:"耶和华如此说：我现在回到锡安，要住在耶路撒冷中。耶路撒冷必称为诚实的城，万军之耶和华的山必称为圣山。"},
{volumn:        38,chapter:         8,versenumber:         4,verse:"万军之耶和华如此说：将来必有年老的男女坐在耶路撒冷街上，因为年纪老迈就手拿拐杖。"},
{volumn:        38,chapter:         8,versenumber:         5,verse:"城中街上必满有男孩女孩玩耍。"},
{volumn:        38,chapter:         8,versenumber:         6,verse:"万军之耶和华如此说：到那日，这事在余剩的民眼中看为希奇，在我眼中也看为希奇吗？这是万军之耶和华说的。"},
{volumn:        38,chapter:         8,versenumber:         7,verse:"万军之耶和华如此说：我要从东方从西方救回我的民。"},
{volumn:        38,chapter:         8,versenumber:         8,verse:"我要领他们来，使他们住在耶路撒冷中。他们要作我的子民，我要作他们的　神，都凭诚实和公义。”"},
{volumn:        38,chapter:         8,versenumber:         9,verse:"万军之耶和华如此说：“当建造万军之耶和华的殿，立根基之日的先知所说的话，现在你们听见，应当手里强壮。"},
{volumn:        38,chapter:         8,versenumber:        10,verse:"那日以先，人得不着雇价，牲畜也是如此；且因敌人的缘故，出入之人不得平安，乃因我使众人互相攻击。"},
{volumn:        38,chapter:         8,versenumber:        11,verse:"但如今，我待这余剩的民必不像从前。这是万军之耶和华说的。"},
{volumn:        38,chapter:         8,versenumber:        12,verse:"因为他们必平安撒种，葡萄树必结果子，地土必有出产，天也必降甘露。我要使这余剩的民享受这一切的福。"},
{volumn:        38,chapter:         8,versenumber:        13,verse:"犹大家和以色列家啊，你们从前在列国中怎样成为可咒诅的；照样，我要拯救你们，使人称你们为有福的（或译：使你们叫人得福）。你们不要惧怕，手要强壮。”"},
{volumn:        38,chapter:         8,versenumber:        14,verse:"万军之耶和华如此说：“你们列祖惹我发怒的时候，我怎样定意降祸，并不后悔。"},
{volumn:        38,chapter:         8,versenumber:        15,verse:"现在我照样定意施恩与耶路撒冷和犹大家，你们不要惧怕。"},
{volumn:        38,chapter:         8,versenumber:        16,verse:"你们所当行的是这样：各人与邻舍说话诚实，在城门口按至理判断，使人和睦。"},
{volumn:        38,chapter:         8,versenumber:        17,verse:"谁都不可心里谋害邻舍，也不可喜爱起假誓，因为这些事都为我所恨恶。这是耶和华说的。”"},
{volumn:        38,chapter:         8,versenumber:        18,verse:"万军之耶和华的话临到我说："},
{volumn:        38,chapter:         8,versenumber:        19,verse:"“万军之耶和华如此说：四月、五月禁食的日子，七月、十月禁食的日子，必变为犹大家欢喜快乐的日子和欢乐的节期；所以你们要喜爱诚实与和平。”"},
{volumn:        38,chapter:         8,versenumber:        20,verse:"万军之耶和华如此说：“将来必有列国的人和多城的居民来到。"},
{volumn:        38,chapter:         8,versenumber:        21,verse:"这城的居民必到那城，说：‘我们要快去恳求耶和华的恩，寻求万军之耶和华；我也要去。’"},
{volumn:        38,chapter:         8,versenumber:        22,verse:"必有列邦的人和强国的民来到耶路撒冷寻求万军之耶和华，恳求耶和华的恩。"},
{volumn:        38,chapter:         8,versenumber:        23,verse:"万军之耶和华如此说：在那些日子，必有十个人从列国诸族（原文是方言）中出来，拉住一个犹大人的衣襟，说：‘我们要与你们同去，因为我们听见　神与你们同在了。’”"},
{volumn:        38,chapter:         9,versenumber:         1,verse:"耶和华的默示应验在哈得拉地大马士革（世人和以色列各支派的眼目都仰望耶和华），"},
{volumn:        38,chapter:         9,versenumber:         2,verse:"和靠近的哈马，并推罗、西顿；因为这二城的人大有智慧。"},
{volumn:        38,chapter:         9,versenumber:         3,verse:"推罗为自己修筑保障，积蓄银子如尘沙，堆起精金如街上的泥土。"},
{volumn:        38,chapter:         9,versenumber:         4,verse:"主必赶出她，打败她海上的权利；她必被火烧灭。"},
{volumn:        38,chapter:         9,versenumber:         5,verse:"亚实基伦看见必惧怕；迦萨看见甚痛苦；以革伦因失了盼望蒙羞。迦萨必不再有君王；亚实基伦也不再有居民。"},
{volumn:        38,chapter:         9,versenumber:         6,verse:"私生子（或译：外族人）必住在亚实突；我必除灭非利士人的骄傲。"},
{volumn:        38,chapter:         9,versenumber:         7,verse:"我必除去他口中带血之肉和牙齿内可憎之物。他必作为余剩的人归与我们的　神，必在犹大像族长；以革伦人必如耶布斯人。"},
{volumn:        38,chapter:         9,versenumber:         8,verse:"我必在我家的四围安营，使敌军不得任意往来，暴虐的人也不再经过，因为我亲眼看顾我的家。"},
{volumn:        38,chapter:         9,versenumber:         9,verse:"锡安的民哪，应当大大喜乐；耶路撒冷的民哪，应当欢呼。看哪，你的王来到你这里！他是公义的，并且施行拯救，谦谦和和地骑着驴，就是骑着驴的驹子。"},
{volumn:        38,chapter:         9,versenumber:        10,verse:"我必除灭以法莲的战车和耶路撒冷的战马；争战的弓也必除灭。他必向列国讲和平；他的权柄必从这海管到那海，从大河管到地极。"},
{volumn:        38,chapter:         9,versenumber:        11,verse:"锡安哪，我因与你立约的血，将你中间被掳而囚的人从无水的坑中释放出来。"},
{volumn:        38,chapter:         9,versenumber:        12,verse:"你们被囚而有指望的人都要转回保障。我今日说明，我必加倍赐福给你们。"},
{volumn:        38,chapter:         9,versenumber:        13,verse:"我拿犹大作上弦的弓；我拿以法莲为张弓的箭。锡安哪，我要激发你的众子，攻击希腊（原文是雅完）的众子，使你如勇士的刀。"},
{volumn:        38,chapter:         9,versenumber:        14,verse:"耶和华必显现在他们以上；他的箭必射出像闪电。主耶和华必吹角，乘南方的旋风而行。"},
{volumn:        38,chapter:         9,versenumber:        15,verse:"万军之耶和华必保护他们；他们必吞灭仇敌，践踏弹石。他们必喝血呐喊，犹如饮酒；他们必像盛满血的碗，又像坛的四角满了血。"},
{volumn:        38,chapter:         9,versenumber:        16,verse:"当那日，耶和华他们的　神必看他的民如群羊，拯救他们；因为他们必像冠冕上的宝石，高举在他的地以上（或译：在他的地上发光辉）。"},
{volumn:        38,chapter:         9,versenumber:        17,verse:"他的恩慈何等大！他的荣美何其盛！五谷健壮少男；新酒培养处女。"},
{volumn:        38,chapter:        10,versenumber:         1,verse:"当春雨的时候，你们要向发闪电的耶和华求雨。他必为众人降下甘霖，使田园生长菜蔬。"},
{volumn:        38,chapter:        10,versenumber:         2,verse:"因为，家神所言的是虚空；卜士所见的是虚假；做梦者所说的是假梦。他们白白地安慰人，所以众人如羊流离，因无牧人就受苦。"},
{volumn:        38,chapter:        10,versenumber:         3,verse:"我的怒气向牧人发作；我必惩罚公山羊；因我万军之耶和华眷顾自己的羊群，就是犹大家，必使他们如骏马在阵上。"},
{volumn:        38,chapter:        10,versenumber:         4,verse:"房角石、钉子、争战的弓，和一切掌权的都从他而出。"},
{volumn:        38,chapter:        10,versenumber:         5,verse:"他们必如勇士在阵上将仇敌践踏在街上的泥土中。他们必争战，因为耶和华与他们同在；骑马的也必羞愧。"},
{volumn:        38,chapter:        10,versenumber:         6,verse:"“我要坚固犹大家，拯救约瑟家，要领他们归回。我要怜恤他们；他们必像未曾弃绝的一样，都因我是耶和华他们的　神，我必应允他们的祷告。"},
{volumn:        38,chapter:        10,versenumber:         7,verse:"以法莲人必如勇士；他们心中畅快如同喝酒；他们的儿女必看见而快活；他们的心必因耶和华喜乐。"},
{volumn:        38,chapter:        10,versenumber:         8,verse:"“我要发嘶声，聚集他们，因我已经救赎他们。他们的人数必加增，如从前加增一样。"},
{volumn:        38,chapter:        10,versenumber:         9,verse:"我虽然（或译：必）播散他们在列国中，他们必在远方记念我。他们与儿女都必存活，且得归回。"},
{volumn:        38,chapter:        10,versenumber:        10,verse:"我必再领他们出埃及地，招聚他们出亚述，领他们到基列和黎巴嫩；这地尚且不够他们居住。"},
{volumn:        38,chapter:        10,versenumber:        11,verse:"耶和华必经过苦海，击打海浪，使尼罗河的深处都枯干。亚述的骄傲必致卑微；埃及的权柄必然灭没。"},
{volumn:        38,chapter:        10,versenumber:        12,verse:"我必使他们倚靠我，得以坚固；一举一动必奉我的名。”这是耶和华说的。"},
{volumn:        38,chapter:        11,versenumber:         1,verse:"黎巴嫩哪，开开你的门，任火烧灭你的香柏树。"},
{volumn:        38,chapter:        11,versenumber:         2,verse:"松树啊，应当哀号；因为香柏树倾倒，佳美的树毁坏。巴珊的橡树啊，应当哀号，因为茂盛的树林已经倒了。"},
{volumn:        38,chapter:        11,versenumber:         3,verse:"听啊，有牧人哀号的声音，因他们荣华的草场毁坏了。有少壮狮子咆哮的声音，因约旦河旁的丛林荒废了。"},
{volumn:        38,chapter:        11,versenumber:         4,verse:"耶和华我的　神如此说：“你撒迦利亚要牧养这将宰的群羊。"},
{volumn:        38,chapter:        11,versenumber:         5,verse:"买他们的宰了他们，以自己为无罪；卖他们的说：‘耶和华是应当称颂的，因我成为富足。’牧养他们的并不怜恤他们。"},
{volumn:        38,chapter:        11,versenumber:         6,verse:"耶和华说：‘我不再怜恤这地的居民，必将这民交给各人的邻舍和他们王的手中。他们必毁灭这地，我也不救这民脱离他们的手。’”"},
{volumn:        38,chapter:        11,versenumber:         7,verse:"于是，我牧养这将宰的群羊，就是群中最困苦的羊。我拿着两根杖，一根我称为“荣美”，一根我称为“联索”。这样，我牧养了群羊。"},
{volumn:        38,chapter:        11,versenumber:         8,verse:"一月之内，我除灭三个牧人，因为我的心厌烦他们；他们的心也憎嫌我。"},
{volumn:        38,chapter:        11,versenumber:         9,verse:"我就说：“我不牧养你们。要死的，由他死；要丧亡的，由他丧亡；余剩的，由他们彼此相食。”"},
{volumn:        38,chapter:        11,versenumber:        10,verse:"我折断那称为“荣美”的杖，表明我废弃与万民所立的约。"},
{volumn:        38,chapter:        11,versenumber:        11,verse:"当日就废弃了。这样，那些仰望我的困苦羊就知道所说的是耶和华的话。"},
{volumn:        38,chapter:        11,versenumber:        12,verse:"我对他们说：“你们若以为美，就给我工价。不然，就罢了！”于是他们给了三十块钱作为我的工价。"},
{volumn:        38,chapter:        11,versenumber:        13,verse:"耶和华吩咐我说：“要把众人所估定美好的价值丢给窑户。”我便将这三十块钱，在耶和华的殿中丢给窑户了。"},
{volumn:        38,chapter:        11,versenumber:        14,verse:"我又折断称为“联索”的那根杖，表明我废弃犹大与以色列弟兄的情谊。"},
{volumn:        38,chapter:        11,versenumber:        15,verse:"耶和华又吩咐我说：“你再取愚昧牧人所用的器具，"},
{volumn:        38,chapter:        11,versenumber:        16,verse:"因我要在这地兴起一个牧人。他不看顾丧亡的，不寻找分散的，不医治受伤的，也不牧养强壮的；却要吃肥羊的肉，撕裂它的蹄子。”"},
{volumn:        38,chapter:        11,versenumber:        17,verse:"无用的牧人丢弃羊群有祸了！刀必临到他的膀臂和右眼上。他的膀臂必全然枯干；他的右眼也必昏暗失明。"},
{volumn:        38,chapter:        12,versenumber:         1,verse:"耶和华论以色列的默示。铺张诸天、建立地基、造人里面之灵的耶和华说："},
{volumn:        38,chapter:        12,versenumber:         2,verse:"“我必使耶路撒冷被围困的时候，向四围列国的民成为令人昏醉的杯；这默示也论到犹大（或译：犹大也是如此）。"},
{volumn:        38,chapter:        12,versenumber:         3,verse:"那日，我必使耶路撒冷向聚集攻击他的万民当作一块重石头；凡举起的必受重伤。"},
{volumn:        38,chapter:        12,versenumber:         4,verse:"耶和华说：到那日，我必使一切马匹惊惶，使骑马的颠狂。我必看顾犹大家，使列国的一切马匹瞎眼。"},
{volumn:        38,chapter:        12,versenumber:         5,verse:"犹大的族长必心里说：‘耶路撒冷的居民倚靠万军之耶和华他们的　神，就作我们的能力。’"},
{volumn:        38,chapter:        12,versenumber:         6,verse:"“那日，我必使犹大的族长如火盆在木柴中，又如火把在禾捆里；他们必左右烧灭四围列国的民。耶路撒冷人必仍住本处，就是耶路撒冷。"},
{volumn:        38,chapter:        12,versenumber:         7,verse:"“耶和华必先拯救犹大的帐棚，免得大卫家的荣耀和耶路撒冷居民的荣耀胜过犹大。"},
{volumn:        38,chapter:        12,versenumber:         8,verse:"那日，耶和华必保护耶路撒冷的居民。他们中间软弱的必如大卫；大卫的家必如　神，如行在他们前面之耶和华的使者。"},
{volumn:        38,chapter:        12,versenumber:         9,verse:"那日，我必定意灭绝来攻击耶路撒冷各国的民。"},
{volumn:        38,chapter:        12,versenumber:        10,verse:"“我必将那施恩叫人恳求的灵，浇灌大卫家和耶路撒冷的居民。他们必仰望我（或译：他；本节同），就是他们所扎的；必为我悲哀，如丧独生子，又为我愁苦，如丧长子。"},
{volumn:        38,chapter:        12,versenumber:        11,verse:"那日，耶路撒冷必有大大的悲哀，如米吉多平原之哈达临门的悲哀。"},
{volumn:        38,chapter:        12,versenumber:        12,verse:"境内一家一家地都必悲哀。大卫家，男的独在一处，女的独在一处。拿单家，男的独在一处，女的独在一处。"},
{volumn:        38,chapter:        12,versenumber:        13,verse:"利未家，男的独在一处，女的独在一处。示每家，男的独在一处，女的独在一处。"},
{volumn:        38,chapter:        12,versenumber:        14,verse:"其余的各家，男的独在一处，女的独在一处。”"},
{volumn:        38,chapter:        13,versenumber:         1,verse:"“那日，必给大卫家和耶路撒冷的居民开一个泉源，洗除罪恶与污秽。”"},
{volumn:        38,chapter:        13,versenumber:         2,verse:"万军之耶和华说：“那日，我必从地上除灭偶像的名，不再被人记念；也必使这地不再有假先知与污秽的灵。"},
{volumn:        38,chapter:        13,versenumber:         3,verse:"若再有人说预言，生他的父母必对他说：‘你不得存活，因为你托耶和华的名说假预言。’生他的父母在他说预言的时候，要将他刺透。"},
{volumn:        38,chapter:        13,versenumber:         4,verse:"那日，凡作先知说预言的必因他所论的异象羞愧，不再穿毛衣哄骗人。"},
{volumn:        38,chapter:        13,versenumber:         5,verse:"他必说：‘我不是先知，我是耕地的；我从幼年作人的奴仆。’"},
{volumn:        38,chapter:        13,versenumber:         6,verse:"必有人问他说：‘你两臂中间是什么伤呢？’他必回答说：‘这是我在亲友家中所受的伤。’”"},
{volumn:        38,chapter:        13,versenumber:         7,verse:"万军之耶和华说：“刀剑哪，应当兴起，攻击我的牧人和我的同伴。击打牧人，羊就分散；我必反手加在微小者的身上。”"},
{volumn:        38,chapter:        13,versenumber:         8,verse:"耶和华说：“这全地的人，三分之二必剪除而死，三分之一仍必存留。"},
{volumn:        38,chapter:        13,versenumber:         9,verse:"我要使这三分之一经火，熬炼他们，如熬炼银子；试炼他们，如试炼金子。他们必求告我的名，我必应允他们。我要说：‘这是我的子民。’他们也要说：‘耶和华是我们的　神。’”"},
{volumn:        38,chapter:        14,versenumber:         1,verse:"耶和华的日子临近，你的财物必被抢掠，在你中间分散。"},
{volumn:        38,chapter:        14,versenumber:         2,verse:"因为我必聚集万国与耶路撒冷争战，城必被攻取，房屋被抢夺，妇女被玷污，城中的民一半被掳去；剩下的民仍在城中，不致剪除。"},
{volumn:        38,chapter:        14,versenumber:         3,verse:"那时，耶和华必出去与那些国争战，好像从前争战一样。"},
{volumn:        38,chapter:        14,versenumber:         4,verse:"那日，他的脚必站在耶路撒冷前面朝东的橄榄山上。这山必从中间分裂，自东至西成为极大的谷。山的一半向北挪移，一半向南挪移。"},
{volumn:        38,chapter:        14,versenumber:         5,verse:"你们要从我山的谷中逃跑，因为山谷必延到亚萨。你们逃跑，必如犹大王乌西雅年间的人逃避大地震一样。耶和华我的　神必降临，有一切圣者同来。"},
{volumn:        38,chapter:        14,versenumber:         6,verse:"那日，必没有光，三光必退缩。"},
{volumn:        38,chapter:        14,versenumber:         7,verse:"那日，必是耶和华所知道的，不是白昼，也不是黑夜，到了晚上才有光明。"},
{volumn:        38,chapter:        14,versenumber:         8,verse:"那日，必有活水从耶路撒冷出来，一半往东海流，一半往西海流；冬夏都是如此。"},
{volumn:        38,chapter:        14,versenumber:         9,verse:"耶和华必作全地的王。那日耶和华必为独一无二的，他的名也是独一无二的。"},
{volumn:        38,chapter:        14,versenumber:        10,verse:"全地，从迦巴直到耶路撒冷南方的临门，要变为亚拉巴。耶路撒冷必仍居高位，就是从便雅悯门到第一门之处，又到角门，并从哈楠业楼，直到王的酒榨。"},
{volumn:        38,chapter:        14,versenumber:        11,verse:"人必住在其中，不再有咒诅。耶路撒冷人必安然居住。"},
{volumn:        38,chapter:        14,versenumber:        12,verse:"耶和华用灾殃攻击那与耶路撒冷争战的列国人，必是这样：他们两脚站立的时候，肉必消没，眼在眶中干瘪，舌在口中溃烂。"},
{volumn:        38,chapter:        14,versenumber:        13,verse:"那日，耶和华必使他们大大扰乱。他们各人彼此揪住，举手攻击。"},
{volumn:        38,chapter:        14,versenumber:        14,verse:"犹大也必在耶路撒冷争战。那时四围各国的财物，就是许多金银衣服，必被收聚。"},
{volumn:        38,chapter:        14,versenumber:        15,verse:"那临到马匹、骡子、骆驼、驴，和营中一切牲畜的灾殃是与那灾殃一般。"},
{volumn:        38,chapter:        14,versenumber:        16,verse:"所有来攻击耶路撒冷列国中剩下的人，必年年上来敬拜大君王万军之耶和华，并守住棚节。"},
{volumn:        38,chapter:        14,versenumber:        17,verse:"地上万族中，凡不上耶路撒冷敬拜大君王万军之耶和华的，必无雨降在他们的地上。"},
{volumn:        38,chapter:        14,versenumber:        18,verse:"埃及族若不上来，雨也不降在他们的地上；凡不上来守住棚节的列国人，耶和华也必用这灾攻击他们。"},
{volumn:        38,chapter:        14,versenumber:        19,verse:"这就是埃及的刑罚和那不上来守住棚节之列国的刑罚。"},
{volumn:        38,chapter:        14,versenumber:        20,verse:"当那日，马的铃铛上必有“归耶和华为圣”的这句话。耶和华殿内的锅必如祭坛前的碗一样。"},
{volumn:        38,chapter:        14,versenumber:        21,verse:"凡耶路撒冷和犹大的锅都必归万军之耶和华为圣。凡献祭的都必来取这锅，煮肉在其中。当那日，在万军之耶和华的殿中必不再有迦南人。"},
{volumn:        39,chapter:         1,versenumber:         1,verse:"耶和华藉玛拉基传给以色列的默示。"},
{volumn:        39,chapter:         1,versenumber:         2,verse:"耶和华说：“我曾爱你们。”你们却说：“你在何事上爱我们呢？”耶和华说：“以扫不是雅各的哥哥吗？我却爱雅各，"},
{volumn:        39,chapter:         1,versenumber:         3,verse:"恶以扫，使他的山岭荒凉，把他的地业交给旷野的野狗。”"},
{volumn:        39,chapter:         1,versenumber:         4,verse:"以东人说：“我们现在虽被毁坏，却要重建荒废之处。”万军之耶和华如此说：“任他们建造，我必拆毁；人必称他们的地为‘罪恶之境’；称他们的民为‘耶和华永远恼怒之民’。”"},
{volumn:        39,chapter:         1,versenumber:         5,verse:"你们必亲眼看见，也必说：“愿耶和华在以色列境界之外被尊为大！”"},
{volumn:        39,chapter:         1,versenumber:         6,verse:"“藐视我名的祭司啊，万军之耶和华对你们说：儿子尊敬父亲，仆人敬畏主人；我既为父亲，尊敬我的在哪里呢？我既为主人，敬畏我的在哪里呢？你们却说：‘我们在何事上藐视你的名呢？’"},
{volumn:        39,chapter:         1,versenumber:         7,verse:"你们将污秽的食物献在我的坛上，且说：‘我们在何事上污秽你呢？’因你们说，耶和华的桌子是可藐视的。"},
{volumn:        39,chapter:         1,versenumber:         8,verse:"你们将瞎眼的献为祭物，这不为恶吗？将瘸腿的、有病的献上，这不为恶吗？你献给你的省长，他岂喜悦你，岂能看你的情面吗？这是万军之耶和华说的。"},
{volumn:        39,chapter:         1,versenumber:         9,verse:"现在我劝你们恳求　神，他好施恩与我们。这妄献的事，既由你们经手，他岂能看你们的情面吗？这是万军之耶和华说的。"},
{volumn:        39,chapter:         1,versenumber:        10,verse:"甚愿你们中间有一人关上殿门，免得你们徒然在我坛上烧火。万军之耶和华说：“我不喜悦你们，也不从你们手中收纳供物。”"},
{volumn:        39,chapter:         1,versenumber:        11,verse:"万军之耶和华说：“从日出之地到日落之处，我的名在外邦中必尊为大。在各处，人必奉我的名烧香，献洁净的供物，因为我的名在外邦中必尊为大。"},
{volumn:        39,chapter:         1,versenumber:        12,verse:"你们却亵渎我的名，说：‘耶和华的桌子是污秽的，其上的食物是可藐视的。’"},
{volumn:        39,chapter:         1,versenumber:        13,verse:"你们又说：‘这些事何等烦琐！’并嗤之以鼻。这是万军之耶和华说的。你们把抢夺的、瘸腿的、有病的拿来献上为祭。我岂能从你们手中收纳呢？这是耶和华说的。"},
{volumn:        39,chapter:         1,versenumber:        14,verse:"行诡诈的在群中有公羊，他许愿却用有残疾的献给主，这人是可咒诅的。因为我是大君王，我的名在外邦中是可畏的。这是万军之耶和华说的。”"},
{volumn:        39,chapter:         2,versenumber:         1,verse:"“众祭司啊，这诫命是传给你们的。"},
{volumn:        39,chapter:         2,versenumber:         2,verse:"万军之耶和华说：你们若不听从，也不放在心上，将荣耀归与我的名，我就使咒诅临到你们，使你们的福分变为咒诅；因你们不把诫命放在心上，我已经咒诅你们了。"},
{volumn:        39,chapter:         2,versenumber:         3,verse:"我必斥责你们的种子，又把你们牺牲的粪抹在你们的脸上；你们要与粪一同除掉。"},
{volumn:        39,chapter:         2,versenumber:         4,verse:"你们就知道我传这诫命给你们，使我与利未（或译：利未人）所立的约可以常存。这是万军之耶和华说的。"},
{volumn:        39,chapter:         2,versenumber:         5,verse:"我曾与他立生命和平安的约。我将这两样赐给他，使他存敬畏的心，他就敬畏我，惧怕我的名。"},
{volumn:        39,chapter:         2,versenumber:         6,verse:"真实的律法在他口中，他嘴里没有不义的话。他以平安和正直与我同行，使多人回头离开罪孽。"},
{volumn:        39,chapter:         2,versenumber:         7,verse:"祭司的嘴里当存知识，人也当由他口中寻求律法，因为他是万军之耶和华的使者。"},
{volumn:        39,chapter:         2,versenumber:         8,verse:"你们却偏离正道，使许多人在律法上跌倒。你们废弃我与利未所立的约。这是万军之耶和华说的。"},
{volumn:        39,chapter:         2,versenumber:         9,verse:"所以我使你们被众人藐视，看为下贱；因你们不守我的道，竟在律法上瞻徇情面。”"},
{volumn:        39,chapter:         2,versenumber:        10,verse:"我们岂不都是一位父吗？岂不是一位　神所造的吗？我们各人怎么以诡诈待弟兄，背弃了　神与我们列祖所立的约呢？"},
{volumn:        39,chapter:         2,versenumber:        11,verse:"犹大人行事诡诈，并且在以色列和耶路撒冷中行一件可憎的事；因为犹大人亵渎耶和华所喜爱的圣洁（或译：圣地），娶侍奉外邦神的女子为妻。"},
{volumn:        39,chapter:         2,versenumber:        12,verse:"凡行这事的，无论何人（何人：原文是叫醒的，答应的），就是献供物给万军之耶和华，耶和华也必从雅各的帐棚中剪除他。"},
{volumn:        39,chapter:         2,versenumber:        13,verse:"你们又行了一件这样的事，使前妻叹息哭泣的眼泪遮盖耶和华的坛，以致耶和华不再看顾那供物，也不乐意从你们手中收纳。"},
{volumn:        39,chapter:         2,versenumber:        14,verse:"你们还说：“这是为什么呢？”因耶和华在你和你幼年所娶的妻中间作见证。她虽是你的配偶，又是你盟约的妻，你却以诡诈待她。"},
{volumn:        39,chapter:         2,versenumber:        15,verse:"虽然　神有灵的余力能造多人，他不是单造一人吗？为何只造一人呢？乃是他愿人得虔诚的后裔。所以当谨守你们的心，谁也不可以诡诈待幼年所娶的妻。"},
{volumn:        39,chapter:         2,versenumber:        16,verse:"耶和华以色列的　神说：“休妻的事和以强暴待妻的人都是我所恨恶的！所以当谨守你们的心，不可行诡诈。”这是万军之耶和华说的。"},
{volumn:        39,chapter:         2,versenumber:        17,verse:"你们用言语烦琐耶和华，你们还说：“我们在何事上烦琐他呢？”因为你们说：“凡行恶的，耶和华眼看为善，并且他喜悦他们”；或说：“公义的　神在哪里呢？”"},
{volumn:        39,chapter:         3,versenumber:         1,verse:"万军之耶和华说：“我要差遣我的使者在我前面预备道路。你们所寻求的主必忽然进入他的殿；立约的使者，就是你们所仰慕的，快要来到。”"},
{volumn:        39,chapter:         3,versenumber:         2,verse:"他来的日子，谁能当得起呢？他显现的时候，谁能立得住呢？因为他如炼金之人的火，如漂布之人的碱。"},
{volumn:        39,chapter:         3,versenumber:         3,verse:"他必坐下如炼净银子的，必洁净利未人，熬炼他们像金银一样；他们就凭公义献供物给耶和华。"},
{volumn:        39,chapter:         3,versenumber:         4,verse:"那时，犹大和耶路撒冷所献的供物必蒙耶和华悦纳，仿佛古时之日、上古之年。"},
{volumn:        39,chapter:         3,versenumber:         5,verse:"万军之耶和华说：“我必临近你们，施行审判。我必速速作见证，警戒行邪术的、犯奸淫的、起假誓的、亏负人之工价的、欺压寡妇孤儿的、屈枉寄居的，和不敬畏我的。”"},
{volumn:        39,chapter:         3,versenumber:         6,verse:"“因我耶和华是不改变的，所以你们雅各之子没有灭亡。"},
{volumn:        39,chapter:         3,versenumber:         7,verse:"万军之耶和华说：从你们列祖的日子以来，你们常常偏离我的典章而不遵守。现在你们要转向我，我就转向你们。你们却问说：‘我们如何才是转向呢？’"},
{volumn:        39,chapter:         3,versenumber:         8,verse:"人岂可夺取　神之物呢？你们竟夺取我的供物。你们却说：‘我们在何事上夺取你的供物呢？’就是你们在当纳的十分之一和当献的供物上。"},
{volumn:        39,chapter:         3,versenumber:         9,verse:"因你们通国的人都夺取我的供物，咒诅就临到你们身上。"},
{volumn:        39,chapter:         3,versenumber:        10,verse:"万军之耶和华说：你们要将当纳的十分之一全然送入仓库，使我家有粮，以此试试我，是否为你们敞开天上的窗户，倾福与你们，甚至无处可容。"},
{volumn:        39,chapter:         3,versenumber:        11,verse:"万军之耶和华说：我必为你们斥责蝗虫（原文是吞噬者），不容它毁坏你们的土产。你们田间的葡萄树在未熟之先也不掉果子。"},
{volumn:        39,chapter:         3,versenumber:        12,verse:"万军之耶和华说：万国必称你们为有福的，因你们的地必成为喜乐之地。”"},
{volumn:        39,chapter:         3,versenumber:        13,verse:"耶和华说：“你们用话顶撞我，你们还说：‘我们用什么话顶撞了你呢？’"},
{volumn:        39,chapter:         3,versenumber:        14,verse:"你们说：‘侍奉　神是徒然的，遵守　神所吩咐的，在万军之耶和华面前苦苦斋戒，有什么益处呢？"},
{volumn:        39,chapter:         3,versenumber:        15,verse:"如今我们称狂傲的人为有福，并且行恶的人得建立；他们虽然试探　神，却得脱离灾难。’”"},
{volumn:        39,chapter:         3,versenumber:        16,verse:"那时，敬畏耶和华的彼此谈论，耶和华侧耳而听，且有纪念册在他面前，记录那敬畏耶和华、思念他名的人。"},
{volumn:        39,chapter:         3,versenumber:        17,verse:"万军之耶和华说：“在我所定的日子，他们必属我，特特归我。我必怜恤他们，如同人怜恤服侍自己的儿子。"},
{volumn:        39,chapter:         3,versenumber:        18,verse:"那时你们必归回，将善人和恶人，侍奉　神的和不侍奉　神的，分别出来。”"},
{volumn:        39,chapter:         4,versenumber:         1,verse:"万军之耶和华说：“那日临近，势如烧着的火炉，凡狂傲的和行恶的必如碎秸，在那日必被烧尽，根本枝条一无存留。"},
{volumn:        39,chapter:         4,versenumber:         2,verse:"但向你们敬畏我名的人必有公义的日头出现，其光线（原文是翅膀）有医治之能。你们必出来跳跃如圈里的肥犊。"},
{volumn:        39,chapter:         4,versenumber:         3,verse:"你们必践踏恶人；在我所定的日子，他们必如灰尘在你们脚掌之下。这是万军之耶和华说的。"},
{volumn:        39,chapter:         4,versenumber:         4,verse:"“你们当记念我仆人摩西的律法，就是我在何烈山为以色列众人所吩咐他的律例典章。"},
{volumn:        39,chapter:         4,versenumber:         5,verse:"“看哪，耶和华大而可畏之日未到以前，我必差遣先知以利亚到你们那里去。"},
{volumn:        39,chapter:         4,versenumber:         6,verse:"他必使父亲的心转向儿女，儿女的心转向父亲，免得我来咒诅遍地。”"},
{volumn:        40,chapter:         1,versenumber:         1,verse:"亚伯拉罕的后裔、大卫的子孙、耶稣基督的家谱（“后裔”、“子孙”：原文都作“儿子”。下同）："},
{volumn:        40,chapter:         1,versenumber:         2,verse:"亚伯拉罕生以撒；以撒生雅各；雅各生犹大和他的弟兄；"},
{volumn:        40,chapter:         1,versenumber:         3,verse:"犹大从他玛氏生法勒斯和谢拉；法勒斯生希斯仑；希斯仑生亚兰；"},
{volumn:        40,chapter:         1,versenumber:         4,verse:"亚兰生亚米拿达；亚米拿达生拿顺；拿顺生撒门；"},
{volumn:        40,chapter:         1,versenumber:         5,verse:"撒门从喇合氏生波阿斯；波阿斯从路得氏生俄备得；俄备得生耶西；"},
{volumn:        40,chapter:         1,versenumber:         6,verse:"耶西生大卫王。大卫从乌利亚的妻子生所罗门；"},
{volumn:        40,chapter:         1,versenumber:         7,verse:"所罗门生罗波安；罗波安生亚比雅；亚比雅生亚撒；"},
{volumn:        40,chapter:         1,versenumber:         8,verse:"亚撒生约沙法；约沙法生约兰；约兰生乌西亚；"},
{volumn:        40,chapter:         1,versenumber:         9,verse:"乌西亚生约坦；约坦生亚哈斯；亚哈斯生希西家；"},
{volumn:        40,chapter:         1,versenumber:        10,verse:"希西家生玛拿西；玛拿西生亚们；亚们生约西亚；"},
{volumn:        40,chapter:         1,versenumber:        11,verse:"百姓被迁到巴比伦的时候，约西亚生耶哥尼雅和他的弟兄。"},
{volumn:        40,chapter:         1,versenumber:        12,verse:"迁到巴比伦之后，耶哥尼雅生撒拉铁；撒拉铁生所罗巴伯；"},
{volumn:        40,chapter:         1,versenumber:        13,verse:"所罗巴伯生亚比玉；亚比玉生以利亚敬；以利亚敬生亚所；"},
{volumn:        40,chapter:         1,versenumber:        14,verse:"亚所生撒督；撒督生亚金；亚金生以律；"},
{volumn:        40,chapter:         1,versenumber:        15,verse:"以律生以利亚撒；以利亚撒生马但；马但生雅各；"},
{volumn:        40,chapter:         1,versenumber:        16,verse:"雅各生约瑟，就是马利亚的丈夫。那称为基督的耶稣是从马利亚生的。"},
{volumn:        40,chapter:         1,versenumber:        17,verse:"这样，从亚伯拉罕到大卫共有十四代；从大卫到迁至巴比伦的时候也有十四代；从迁至巴比伦的时候到基督又有十四代。"},
{volumn:        40,chapter:         1,versenumber:        18,verse:"耶稣基督降生的事记在下面：他母亲马利亚已经许配了约瑟，还没有迎娶，马利亚就从圣灵怀了孕。"},
{volumn:        40,chapter:         1,versenumber:        19,verse:"她丈夫约瑟是个义人，不愿意明明地羞辱她，想要暗暗地把她休了。"},
{volumn:        40,chapter:         1,versenumber:        20,verse:"正思念这事的时候，有主的使者向他梦中显现，说：“大卫的子孙约瑟，不要怕！只管娶过你的妻子马利亚来，因她所怀的孕是从圣灵来的。"},
{volumn:        40,chapter:         1,versenumber:        21,verse:"她将要生一个儿子，你要给他起名叫耶稣，因他要将自己的百姓从罪恶里救出来。”"},
{volumn:        40,chapter:         1,versenumber:        22,verse:"这一切的事成就是要应验主藉先知所说的话，"},
{volumn:        40,chapter:         1,versenumber:        23,verse:"说：“必有童女怀孕生子；人要称他的名为以马内利。”（以马内利翻出来就是“　神与我们同在”。）"},
{volumn:        40,chapter:         1,versenumber:        24,verse:"约瑟醒了，起来，就遵着主使者的吩咐把妻子娶过来；"},
{volumn:        40,chapter:         1,versenumber:        25,verse:"只是没有和她同房，等她生了儿子（有古卷：等她生了头胎的儿子），就给他起名叫耶稣。"},
{volumn:        40,chapter:         2,versenumber:         1,verse:"当希律王的时候，耶稣生在犹太的伯利恒。有几个博士从东方来到耶路撒冷，说："},
{volumn:        40,chapter:         2,versenumber:         2,verse:"“那生下来作犹太人之王的在哪里？我们在东方看见他的星，特来拜他。”"},
{volumn:        40,chapter:         2,versenumber:         3,verse:"希律王听见了，就心里不安；耶路撒冷合城的人也都不安。"},
{volumn:        40,chapter:         2,versenumber:         4,verse:"他就召齐了祭司长和民间的文士，问他们说：“基督当生在何处？”"},
{volumn:        40,chapter:         2,versenumber:         5,verse:"他们回答说：“在犹太的伯利恒。因为有先知记着，说："},
{volumn:        40,chapter:         2,versenumber:         6,verse:"‘犹大地的伯利恒啊，你在犹大诸城中并不是最小的；因为将来有一位君王要从你那里出来，牧养我以色列民。’”"},
{volumn:        40,chapter:         2,versenumber:         7,verse:"当下，希律暗暗地召了博士来，细问那星是什么时候出现的，"},
{volumn:        40,chapter:         2,versenumber:         8,verse:"就差他们往伯利恒去，说：“你们去仔细寻访那小孩子，寻到了就来报信，我也好去拜他。”"},
{volumn:        40,chapter:         2,versenumber:         9,verse:"他们听见王的话就去了。在东方所看见的那星忽然在他们前头行，直行到小孩子的地方，就在上头停住了。"},
{volumn:        40,chapter:         2,versenumber:        10,verse:"他们看见那星，就大大地欢喜；"},
{volumn:        40,chapter:         2,versenumber:        11,verse:"进了房子，看见小孩子和他母亲马利亚，就俯伏拜那小孩子，揭开宝盒，拿黄金、乳香、没药为礼物献给他。"},
{volumn:        40,chapter:         2,versenumber:        12,verse:"博士因为在梦中被主指示不要回去见希律，就从别的路回本地去了。"},
{volumn:        40,chapter:         2,versenumber:        13,verse:"他们去后，有主的使者向约瑟梦中显现，说：“起来！带着小孩子同他母亲逃往埃及，住在那里，等我吩咐你；因为希律必寻找小孩子，要除灭他。”"},
{volumn:        40,chapter:         2,versenumber:        14,verse:"约瑟就起来，夜间带着小孩子和他母亲往埃及去，"},
{volumn:        40,chapter:         2,versenumber:        15,verse:"住在那里，直到希律死了。这是要应验主藉先知所说的话，说：“我从埃及召出我的儿子来。”"},
{volumn:        40,chapter:         2,versenumber:        16,verse:"希律见自己被博士愚弄，就大大发怒，差人将伯利恒城里并四境所有的男孩，照着他向博士仔细查问的时候，凡两岁以里的，都杀尽了。"},
{volumn:        40,chapter:         2,versenumber:        17,verse:"这就应了先知耶利米的话，说："},
{volumn:        40,chapter:         2,versenumber:        18,verse:"“在拉玛听见号啕大哭的声音，是拉结哭她儿女，不肯受安慰，因为他们都不在了。”"},
{volumn:        40,chapter:         2,versenumber:        19,verse:"希律死了以后，有主的使者在埃及向约瑟梦中显现，说："},
{volumn:        40,chapter:         2,versenumber:        20,verse:"“起来！带着小孩子和他母亲往以色列地去，因为要害小孩子性命的人已经死了。”"},
{volumn:        40,chapter:         2,versenumber:        21,verse:"约瑟就起来，把小孩子和他母亲带到以色列地去；"},
{volumn:        40,chapter:         2,versenumber:        22,verse:"只因听见亚基老接着他父亲希律作了犹太王，就怕往那里去，又在梦中被主指示，便往加利利境内去了，"},
{volumn:        40,chapter:         2,versenumber:        23,verse:"到了一座城，名叫拿撒勒，就住在那里。这是要应验先知所说，他将称为拿撒勒人的话了。"},
{volumn:        40,chapter:         3,versenumber:         1,verse:"那时，有施洗的约翰出来，在犹太的旷野传道，说："},
{volumn:        40,chapter:         3,versenumber:         2,verse:"“天国近了，你们应当悔改！”"},
{volumn:        40,chapter:         3,versenumber:         3,verse:"这人就是先知以赛亚所说的。他说：“在旷野有人声喊着说：‘预备主的道，修直他的路！’”"},
{volumn:        40,chapter:         3,versenumber:         4,verse:"这约翰身穿骆驼毛的衣服，腰束皮带，吃的是蝗虫、野蜜。"},
{volumn:        40,chapter:         3,versenumber:         5,verse:"那时，耶路撒冷和犹太全地，并约旦河一带地方的人，都出去到约翰那里，"},
{volumn:        40,chapter:         3,versenumber:         6,verse:"承认他们的罪，在约旦河里受他的洗。"},
{volumn:        40,chapter:         3,versenumber:         7,verse:"约翰看见许多法利赛人和撒都该人也来受洗，就对他们说：“毒蛇的种类！谁指示你们逃避将来的愤怒呢？"},
{volumn:        40,chapter:         3,versenumber:         8,verse:"你们要结出果子来，与悔改的心相称。"},
{volumn:        40,chapter:         3,versenumber:         9,verse:"不要自己心里说：‘有亚伯拉罕为我们的祖宗。’我告诉你们，　神能从这些石头中给亚伯拉罕兴起子孙来。"},
{volumn:        40,chapter:         3,versenumber:        10,verse:"现在斧子已经放在树根上，凡不结好果子的树就砍下来，丢在火里。"},
{volumn:        40,chapter:         3,versenumber:        11,verse:"我是用水给你们施洗，叫你们悔改。但那在我以后来的，能力比我更大，我就是给他提鞋也不配。他要用圣灵与火给你们施洗。"},
{volumn:        40,chapter:         3,versenumber:        12,verse:"他手里拿着簸箕，要扬净他的场，把麦子收在仓里，把糠用不灭的火烧尽了。”"},
{volumn:        40,chapter:         3,versenumber:        13,verse:"当下，耶稣从加利利来到约旦河，见了约翰，要受他的洗。"},
{volumn:        40,chapter:         3,versenumber:        14,verse:"约翰想要拦住他，说：“我当受你的洗，你反倒上我这里来吗？”"},
{volumn:        40,chapter:         3,versenumber:        15,verse:"耶稣回答说：“你暂且许我，因为我们理当这样尽诸般的义（或译：礼）。”于是约翰许了他。"},
{volumn:        40,chapter:         3,versenumber:        16,verse:"耶稣受了洗，随即从水里上来。天忽然为他开了，他就看见　神的灵仿佛鸽子降下，落在他身上。"},
{volumn:        40,chapter:         3,versenumber:        17,verse:"从天上有声音说：“这是我的爱子，我所喜悦的。”"},
{volumn:        40,chapter:         4,versenumber:         1,verse:"当时，耶稣被圣灵引到旷野，受魔鬼的试探。"},
{volumn:        40,chapter:         4,versenumber:         2,verse:"他禁食四十昼夜，后来就饿了。"},
{volumn:        40,chapter:         4,versenumber:         3,verse:"那试探人的进前来，对他说：“你若是　神的儿子，可以吩咐这些石头变成食物。”"},
{volumn:        40,chapter:         4,versenumber:         4,verse:"耶稣却回答说：“经上记着说：‘人活着，不是单靠食物，乃是靠　神口里所出的一切话。’”"},
{volumn:        40,chapter:         4,versenumber:         5,verse:"魔鬼就带他进了圣城，叫他站在殿顶（顶：原文是翅）上，"},
{volumn:        40,chapter:         4,versenumber:         6,verse:"对他说：“你若是　神的儿子，可以跳下去，因为经上记着说：‘主要为你吩咐他的使者用手托着你，免得你的脚碰在石头上。’”"},
{volumn:        40,chapter:         4,versenumber:         7,verse:"耶稣对他说：“经上又记着说：‘不可试探主你的　神。’”"},
{volumn:        40,chapter:         4,versenumber:         8,verse:"魔鬼又带他上了一座最高的山，将世上的万国与万国的荣华都指给他看，"},
{volumn:        40,chapter:         4,versenumber:         9,verse:"对他说：“你若俯伏拜我，我就把这一切都赐给你。”"},
{volumn:        40,chapter:         4,versenumber:        10,verse:"耶稣说：“撒但（就是抵挡的意思，乃魔鬼的别名），退去吧！因为经上记着说：‘当拜主你的　神，单要侍奉他。’”"},
{volumn:        40,chapter:         4,versenumber:        11,verse:"于是，魔鬼离了耶稣，有天使来伺候他。"},
{volumn:        40,chapter:         4,versenumber:        12,verse:"耶稣听见约翰下了监，就退到加利利去；"},
{volumn:        40,chapter:         4,versenumber:        13,verse:"后又离开拿撒勒，往迦百农去，就住在那里。那地方靠海，在西布伦和拿弗他利的边界上。"},
{volumn:        40,chapter:         4,versenumber:        14,verse:"这是要应验先知以赛亚的话，"},
{volumn:        40,chapter:         4,versenumber:        15,verse:"说：“西布伦地，拿弗他利地，就是沿海的路，约旦河外，外邦人的加利利地。"},
{volumn:        40,chapter:         4,versenumber:        16,verse:"那坐在黑暗里的百姓看见了大光；坐在死荫之地的人有光发现照着他们。”"},
{volumn:        40,chapter:         4,versenumber:        17,verse:"从那时候，耶稣就传起道来，说：“天国近了，你们应当悔改！”"},
{volumn:        40,chapter:         4,versenumber:        18,verse:"耶稣在加利利海边行走，看见弟兄二人，就是那称呼彼得的西门和他兄弟安得烈，在海里撒网；他们本是打鱼的。"},
{volumn:        40,chapter:         4,versenumber:        19,verse:"耶稣对他们说：“来跟从我，我要叫你们得人如得鱼一样。”"},
{volumn:        40,chapter:         4,versenumber:        20,verse:"他们就立刻舍了网，跟从了他。"},
{volumn:        40,chapter:         4,versenumber:        21,verse:"从那里往前走，又看见弟兄二人，就是西庇太的儿子雅各和他兄弟约翰，同他们的父亲西庇太在船上补网，耶稣就招呼他们，"},
{volumn:        40,chapter:         4,versenumber:        22,verse:"他们立刻舍了船，别了父亲，跟从了耶稣。"},
{volumn:        40,chapter:         4,versenumber:        23,verse:"耶稣走遍加利利，在各会堂里教训人，传天国的福音，医治百姓各样的病症。"},
{volumn:        40,chapter:         4,versenumber:        24,verse:"他的名声就传遍了叙利亚。那里的人把一切害病的，就是害各样疾病、各样疼痛的和被鬼附的、癫痫的、瘫痪的，都带了来，耶稣就治好了他们。"},
{volumn:        40,chapter:         4,versenumber:        25,verse:"当下，有许多人从加利利、低加波利、耶路撒冷、犹太、约旦河外来跟着他。"},
{volumn:        40,chapter:         5,versenumber:         1,verse:"耶稣看见这许多的人，就上了山，既已坐下，门徒到他跟前来，"},
{volumn:        40,chapter:         5,versenumber:         2,verse:"他就开口教训他们，说："},
{volumn:        40,chapter:         5,versenumber:         3,verse:"“虚心的人有福了！因为天国是他们的。"},
{volumn:        40,chapter:         5,versenumber:         4,verse:"哀恸的人有福了！因为他们必得安慰。"},
{volumn:        40,chapter:         5,versenumber:         5,verse:"温柔的人有福了！因为他们必承受地土。"},
{volumn:        40,chapter:         5,versenumber:         6,verse:"饥渴慕义的人有福了！因为他们必得饱足。"},
{volumn:        40,chapter:         5,versenumber:         7,verse:"怜恤人的人有福了！因为他们必蒙怜恤。"},
{volumn:        40,chapter:         5,versenumber:         8,verse:"清心的人有福了！因为他们必得见　神。"},
{volumn:        40,chapter:         5,versenumber:         9,verse:"使人和睦的人有福了！因为他们必称为　神的儿子。"},
{volumn:        40,chapter:         5,versenumber:        10,verse:"为义受逼迫的人有福了！因为天国是他们的。"},
{volumn:        40,chapter:         5,versenumber:        11,verse:"人若因我辱骂你们，逼迫你们，捏造各样坏话毁谤你们，你们就有福了！"},
{volumn:        40,chapter:         5,versenumber:        12,verse:"应当欢喜快乐，因为你们在天上的赏赐是大的。在你们以前的先知，人也是这样逼迫他们。”"},
{volumn:        40,chapter:         5,versenumber:        13,verse:"“你们是世上的盐。盐若失了味，怎能叫它再咸呢？以后无用，不过丢在外面，被人践踏了。"},
{volumn:        40,chapter:         5,versenumber:        14,verse:"你们是世上的光。城造在山上是不能隐藏的。"},
{volumn:        40,chapter:         5,versenumber:        15,verse:"人点灯，不放在斗底下，是放在灯台上，就照亮一家的人。"},
{volumn:        40,chapter:         5,versenumber:        16,verse:"你们的光也当这样照在人前，叫他们看见你们的好行为，便将荣耀归给你们在天上的父。”"},
{volumn:        40,chapter:         5,versenumber:        17,verse:"“莫想我来要废掉律法和先知。我来不是要废掉，乃是要成全。"},
{volumn:        40,chapter:         5,versenumber:        18,verse:"我实在告诉你们，就是到天地都废去了，律法的一点一画也不能废去，都要成全。"},
{volumn:        40,chapter:         5,versenumber:        19,verse:"所以，无论何人废掉这诫命中最小的一条，又教训人这样做，他在天国要称为最小的。但无论何人遵行这诫命，又教训人遵行，他在天国要称为大的。"},
{volumn:        40,chapter:         5,versenumber:        20,verse:"我告诉你们，你们的义若不胜于文士和法利赛人的义，断不能进天国。”"},
{volumn:        40,chapter:         5,versenumber:        21,verse:"“你们听见有吩咐古人的话，说：‘不可杀人’；又说：‘凡杀人的难免受审判。’"},
{volumn:        40,chapter:         5,versenumber:        22,verse:"只是我告诉你们，凡（有古卷在凡字下加：无缘无故地）向弟兄动怒的，难免受审判；凡骂弟兄是拉加的，难免公会的审断；凡骂弟兄是魔利的，难免地狱的火。"},
{volumn:        40,chapter:         5,versenumber:        23,verse:"所以，你在祭坛上献礼物的时候，若想起弟兄向你怀怨，"},
{volumn:        40,chapter:         5,versenumber:        24,verse:"就把礼物留在坛前，先去同弟兄和好，然后来献礼物。"},
{volumn:        40,chapter:         5,versenumber:        25,verse:"你同告你的对头还在路上，就赶紧与他和息，恐怕他把你送给审判官，审判官交付衙役，你就下在监里了。"},
{volumn:        40,chapter:         5,versenumber:        26,verse:"我实在告诉你，若有一文钱没有还清，你断不能从那里出来。”"},
{volumn:        40,chapter:         5,versenumber:        27,verse:"“你们听见有话说：‘不可奸淫。’"},
{volumn:        40,chapter:         5,versenumber:        28,verse:"只是我告诉你们，凡看见妇女就动淫念的，这人心里已经与她犯奸淫了。"},
{volumn:        40,chapter:         5,versenumber:        29,verse:"若是你的右眼叫你跌倒，就剜出来丢掉，宁可失去百体中的一体，不叫全身丢在地狱里。"},
{volumn:        40,chapter:         5,versenumber:        30,verse:"若是右手叫你跌倒，就砍下来丢掉，宁可失去百体中的一体，不叫全身下入地狱。”"},
{volumn:        40,chapter:         5,versenumber:        31,verse:"“又有话说：‘人若休妻，就当给她休书。’"},
{volumn:        40,chapter:         5,versenumber:        32,verse:"只是我告诉你们，凡休妻的，若不是为淫乱的缘故，就是叫她作淫妇了；人若娶这被休的妇人，也是犯奸淫了。”"},
{volumn:        40,chapter:         5,versenumber:        33,verse:"“你们又听见有吩咐古人的话，说：‘不可背誓，所起的誓总要向主谨守。’"},
{volumn:        40,chapter:         5,versenumber:        34,verse:"只是我告诉你们，什么誓都不可起。不可指着天起誓，因为天是　神的座位；"},
{volumn:        40,chapter:         5,versenumber:        35,verse:"不可指着地起誓，因为地是他的脚凳；也不可指着耶路撒冷起誓，因为耶路撒冷是大君的京城；"},
{volumn:        40,chapter:         5,versenumber:        36,verse:"又不可指着你的头起誓，因为你不能使一根头发变黑变白了。"},
{volumn:        40,chapter:         5,versenumber:        37,verse:"你们的话，是，就说是；不是，就说不是；若再多说就是出于那恶者（或译：就是从恶里出来的）。”"},
{volumn:        40,chapter:         5,versenumber:        38,verse:"“你们听见有话说：‘以眼还眼，以牙还牙。’"},
{volumn:        40,chapter:         5,versenumber:        39,verse:"只是我告诉你们，不要与恶人作对。有人打你的右脸，连左脸也转过来由他打；"},
{volumn:        40,chapter:         5,versenumber:        40,verse:"有人想要告你，要拿你的里衣，连外衣也由他拿去；"},
{volumn:        40,chapter:         5,versenumber:        41,verse:"有人强逼你走一里路，你就同他走二里；"},
{volumn:        40,chapter:         5,versenumber:        42,verse:"有求你的，就给他；有向你借贷的，不可推辞。”"},
{volumn:        40,chapter:         5,versenumber:        43,verse:"“你们听见有话说：‘当爱你的邻舍，恨你的仇敌。’"},
{volumn:        40,chapter:         5,versenumber:        44,verse:"只是我告诉你们，要爱你们的仇敌，为那逼迫你们的祷告。"},
{volumn:        40,chapter:         5,versenumber:        45,verse:"这样就可以作你们天父的儿子；因为他叫日头照好人，也照歹人；降雨给义人，也给不义的人。"},
{volumn:        40,chapter:         5,versenumber:        46,verse:"你们若单爱那爱你们的人，有什么赏赐呢？就是税吏不也是这样行吗？"},
{volumn:        40,chapter:         5,versenumber:        47,verse:"你们若单请你弟兄的安，比人有什么长处呢？就是外邦人不也是这样行吗？"},
{volumn:        40,chapter:         5,versenumber:        48,verse:"所以，你们要完全，像你们的天父完全一样。”"},
{volumn:        40,chapter:         6,versenumber:         1,verse:"“你们要小心，不可将善事行在人的面前，故意叫他们看见，若是这样，就不能得你们天父的赏赐了。"},
{volumn:        40,chapter:         6,versenumber:         2,verse:"所以，你施舍的时候，不可在你前面吹号，像那假冒为善的人在会堂里和街道上所行的，故意要得人的荣耀。我实在告诉你们，他们已经得了他们的赏赐。"},
{volumn:        40,chapter:         6,versenumber:         3,verse:"你施舍的时候，不要叫左手知道右手所做的，"},
{volumn:        40,chapter:         6,versenumber:         4,verse:"要叫你施舍的事行在暗中。你父在暗中察看，必然报答你（有古卷：必在明处报答你）。”"},
{volumn:        40,chapter:         6,versenumber:         5,verse:"“你们祷告的时候，不可像那假冒为善的人，爱站在会堂里和十字路口上祷告，故意叫人看见。我实在告诉你们，他们已经得了他们的赏赐。"},
{volumn:        40,chapter:         6,versenumber:         6,verse:"你祷告的时候，要进你的内屋，关上门，祷告你在暗中的父；你父在暗中察看，必然报答你。"},
{volumn:        40,chapter:         6,versenumber:         7,verse:"你们祷告，不可像外邦人，用许多重复话，他们以为话多了必蒙垂听。"},
{volumn:        40,chapter:         6,versenumber:         8,verse:"你们不可效法他们；因为你们没有祈求以先，你们所需用的，你们的父早已知道了。”"},
{volumn:        40,chapter:         6,versenumber:         9,verse:"“所以，你们祷告要这样说：‘我们在天上的父：愿人都尊你的名为圣。"},
{volumn:        40,chapter:         6,versenumber:        10,verse:"愿你的国降临；愿你的旨意行在地上，如同行在天上。"},
{volumn:        40,chapter:         6,versenumber:        11,verse:"我们日用的饮食，今日赐给我们。"},
{volumn:        40,chapter:         6,versenumber:        12,verse:"免我们的债，如同我们免了人的债。"},
{volumn:        40,chapter:         6,versenumber:        13,verse:"不叫我们遇见试探；救我们脱离凶恶（或译：脱离恶者）。因为国度、权柄、荣耀，全是你的，直到永远。阿们（有古卷没有因为…阿们等字）！’”"},
{volumn:        40,chapter:         6,versenumber:        14,verse:"“你们饶恕人的过犯，你们的天父也必饶恕你们的过犯；"},
{volumn:        40,chapter:         6,versenumber:        15,verse:"你们不饶恕人的过犯，你们的天父也必不饶恕你们的过犯。”"},
{volumn:        40,chapter:         6,versenumber:        16,verse:"“你们禁食的时候，不可像那假冒为善的人，脸上带着愁容；因为他们把脸弄得难看，故意叫人看出他们是禁食。我实在告诉你们，他们已经得了他们的赏赐。"},
{volumn:        40,chapter:         6,versenumber:        17,verse:"你禁食的时候，要梳头洗脸，"},
{volumn:        40,chapter:         6,versenumber:        18,verse:"不叫人看出你禁食来，只叫你暗中的父看见；你父在暗中察看，必然报答你。”"},
{volumn:        40,chapter:         6,versenumber:        19,verse:"“不要为自己积攒财宝在地上；地上有虫子咬，能锈坏，也有贼挖窟窿来偷。"},
{volumn:        40,chapter:         6,versenumber:        20,verse:"只要积攒财宝在天上；天上没有虫子咬，不能锈坏，也没有贼挖窟窿来偷。"},
{volumn:        40,chapter:         6,versenumber:        21,verse:"因为你的财宝在哪里，你的心也在那里。”"},
{volumn:        40,chapter:         6,versenumber:        22,verse:"“眼睛就是身上的灯。你的眼睛若了亮，全身就光明；"},
{volumn:        40,chapter:         6,versenumber:        23,verse:"你的眼睛若昏花，全身就黑暗。你里头的光若黑暗了，那黑暗是何等大呢！”"},
{volumn:        40,chapter:         6,versenumber:        24,verse:"“一个人不能侍奉两个主；不是恶这个、爱那个，就是重这个、轻那个。你们不能又侍奉　神，又侍奉玛门（玛门：财利的意思）。”"},
{volumn:        40,chapter:         6,versenumber:        25,verse:"“所以我告诉你们，不要为生命忧虑吃什么，喝什么；为身体忧虑穿什么。生命不胜于饮食吗？身体不胜于衣裳吗？"},
{volumn:        40,chapter:         6,versenumber:        26,verse:"你们看那天上的飞鸟，也不种，也不收，也不积蓄在仓里，你们的天父尚且养活它。你们不比飞鸟贵重得多吗？"},
{volumn:        40,chapter:         6,versenumber:        27,verse:"你们哪一个能用思虑使寿数多加一刻呢（或译：使身量多加一肘呢）？"},
{volumn:        40,chapter:         6,versenumber:        28,verse:"何必为衣裳忧虑呢？你想野地里的百合花怎么长起来；它也不劳苦，也不纺线。"},
{volumn:        40,chapter:         6,versenumber:        29,verse:"然而我告诉你们，就是所罗门极荣华的时候，他所穿戴的，还不如这花一朵呢！"},
{volumn:        40,chapter:         6,versenumber:        30,verse:"你们这小信的人哪！野地里的草今天还在，明天就丢在炉里，　神还给它这样的妆饰，何况你们呢！"},
{volumn:        40,chapter:         6,versenumber:        31,verse:"所以，不要忧虑说，吃什么？喝什么？穿什么？"},
{volumn:        40,chapter:         6,versenumber:        32,verse:"这都是外邦人所求的。你们需用的这一切东西，你们的天父是知道的。"},
{volumn:        40,chapter:         6,versenumber:        33,verse:"你们要先求他的国和他的义，这些东西都要加给你们了。"},
{volumn:        40,chapter:         6,versenumber:        34,verse:"所以，不要为明天忧虑，因为明天自有明天的忧虑；一天的难处一天当就够了。”"},
{volumn:        40,chapter:         7,versenumber:         1,verse:"“你们不要论断人，免得你们被论断。"},
{volumn:        40,chapter:         7,versenumber:         2,verse:"因为你们怎样论断人，也必怎样被论断；你们用什么量器量给人，也必用什么量器量给你们。"},
{volumn:        40,chapter:         7,versenumber:         3,verse:"为什么看见你弟兄眼中有刺，却不想自己眼中有梁木呢？"},
{volumn:        40,chapter:         7,versenumber:         4,verse:"你自己眼中有梁木，怎能对你弟兄说：‘容我去掉你眼中的刺’呢？"},
{volumn:        40,chapter:         7,versenumber:         5,verse:"你这假冒为善的人！先去掉自己眼中的梁木，然后才能看得清楚，去掉你弟兄眼中的刺。"},
{volumn:        40,chapter:         7,versenumber:         6,verse:"不要把圣物给狗，也不要把你们的珍珠丢在猪前，恐怕它践踏了珍珠，转过来咬你们。”"},
{volumn:        40,chapter:         7,versenumber:         7,verse:"“你们祈求，就给你们；寻找，就寻见；叩门，就给你们开门。"},
{volumn:        40,chapter:         7,versenumber:         8,verse:"因为凡祈求的，就得着；寻找的，就寻见；叩门的，就给他开门。"},
{volumn:        40,chapter:         7,versenumber:         9,verse:"你们中间谁有儿子求饼，反给他石头呢？"},
{volumn:        40,chapter:         7,versenumber:        10,verse:"求鱼，反给他蛇呢？"},
{volumn:        40,chapter:         7,versenumber:        11,verse:"你们虽然不好，尚且知道拿好东西给儿女，何况你们在天上的父，岂不更把好东西给求他的人吗？"},
{volumn:        40,chapter:         7,versenumber:        12,verse:"所以，无论何事，你们愿意人怎样待你们，你们也要怎样待人，因为这就是律法和先知的道理。”"},
{volumn:        40,chapter:         7,versenumber:        13,verse:"“你们要进窄门。因为引到灭亡，那门是宽的，路是大的，进去的人也多；"},
{volumn:        40,chapter:         7,versenumber:        14,verse:"引到永生，那门是窄的，路是小的，找着的人也少。”"},
{volumn:        40,chapter:         7,versenumber:        15,verse:"“你们要防备假先知。他们到你们这里来，外面披着羊皮，里面却是残暴的狼。"},
{volumn:        40,chapter:         7,versenumber:        16,verse:"凭着他们的果子，就可以认出他们来。荆棘上岂能摘葡萄呢？蒺藜里岂能摘无花果呢？"},
{volumn:        40,chapter:         7,versenumber:        17,verse:"这样，凡好树都结好果子，惟独坏树结坏果子。"},
{volumn:        40,chapter:         7,versenumber:        18,verse:"好树不能结坏果子；坏树不能结好果子。"},
{volumn:        40,chapter:         7,versenumber:        19,verse:"凡不结好果子的树就砍下来，丢在火里。"},
{volumn:        40,chapter:         7,versenumber:        20,verse:"所以，凭着他们的果子就可以认出他们来。”"},
{volumn:        40,chapter:         7,versenumber:        21,verse:"“凡称呼我‘主啊，主啊’的人不能都进天国；惟独遵行我天父旨意的人才能进去。"},
{volumn:        40,chapter:         7,versenumber:        22,verse:"当那日必有许多人对我说：‘主啊，主啊，我们不是奉你的名传道，奉你的名赶鬼，奉你的名行许多异能吗？’"},
{volumn:        40,chapter:         7,versenumber:        23,verse:"我就明明地告诉他们说：‘我从来不认识你们，你们这些作恶的人，离开我去吧！’”"},
{volumn:        40,chapter:         7,versenumber:        24,verse:"“所以，凡听见我这话就去行的，好比一个聪明人，把房子盖在磐石上；"},
{volumn:        40,chapter:         7,versenumber:        25,verse:"雨淋，水冲，风吹，撞着那房子，房子总不倒塌，因为根基立在磐石上。"},
{volumn:        40,chapter:         7,versenumber:        26,verse:"凡听见我这话不去行的，好比一个无知的人，把房子盖在沙土上；"},
{volumn:        40,chapter:         7,versenumber:        27,verse:"雨淋，水冲，风吹，撞着那房子，房子就倒塌了，并且倒塌得很大。”"},
{volumn:        40,chapter:         7,versenumber:        28,verse:"耶稣讲完了这些话，众人都希奇他的教训；"},
{volumn:        40,chapter:         7,versenumber:        29,verse:"因为他教训他们，正像有权柄的人，不像他们的文士。"},
{volumn:        40,chapter:         8,versenumber:         1,verse:"耶稣下了山，有许多人跟着他。"},
{volumn:        40,chapter:         8,versenumber:         2,verse:"有一个长大麻风的来拜他，说：“主若肯，必能叫我洁净了。”"},
{volumn:        40,chapter:         8,versenumber:         3,verse:"耶稣伸手摸他，说：“我肯，你洁净了吧！”他的大麻风立刻就洁净了。"},
{volumn:        40,chapter:         8,versenumber:         4,verse:"耶稣对他说：“你切不可告诉人，只要去把身体给祭司察看，献上摩西所吩咐的礼物，对众人作证据。”"},
{volumn:        40,chapter:         8,versenumber:         5,verse:"耶稣进了迦百农，有一个百夫长进前来，求他说："},
{volumn:        40,chapter:         8,versenumber:         6,verse:"“主啊，我的仆人害瘫痪病，躺在家里，甚是痛苦。”"},
{volumn:        40,chapter:         8,versenumber:         7,verse:"耶稣说：“我去医治他。”"},
{volumn:        40,chapter:         8,versenumber:         8,verse:"百夫长回答说：“主啊，你到我舍下，我不敢当；只要你说一句话，我的仆人就必好了。"},
{volumn:        40,chapter:         8,versenumber:         9,verse:"因为我在人的权下，也有兵在我以下；对这个说‘去！’他就去；对那个说‘来！’他就来；对我的仆人说：‘你做这事！’他就去做。”"},
{volumn:        40,chapter:         8,versenumber:        10,verse:"耶稣听见就希奇，对跟从的人说：“我实在告诉你们，这么大的信心，就是在以色列中，我也没有遇见过。"},
{volumn:        40,chapter:         8,versenumber:        11,verse:"我又告诉你们，从东从西，将有许多人来，在天国里与亚伯拉罕、以撒、雅各一同坐席；"},
{volumn:        40,chapter:         8,versenumber:        12,verse:"惟有本国的子民竟被赶到外边黑暗里去，在那里必要哀哭切齿了。”"},
{volumn:        40,chapter:         8,versenumber:        13,verse:"耶稣对百夫长说：“你回去吧！照你的信心，给你成全了。”那时，他的仆人就好了。"},
{volumn:        40,chapter:         8,versenumber:        14,verse:"耶稣到了彼得家里，见彼得的岳母害热病躺着。"},
{volumn:        40,chapter:         8,versenumber:        15,verse:"耶稣把她的手一摸，热就退了；她就起来服侍耶稣。"},
{volumn:        40,chapter:         8,versenumber:        16,verse:"到了晚上，有人带着许多被鬼附的来到耶稣跟前，他只用一句话就把鬼都赶出去，并且治好了一切有病的人。"},
{volumn:        40,chapter:         8,versenumber:        17,verse:"这是要应验先知以赛亚的话，说：“他代替我们的软弱，担当我们的疾病。”"},
{volumn:        40,chapter:         8,versenumber:        18,verse:"耶稣见许多人围着他，就吩咐渡到那边去。"},
{volumn:        40,chapter:         8,versenumber:        19,verse:"有一个文士来，对他说：“夫子，你无论往哪里去，我要跟从你。”"},
{volumn:        40,chapter:         8,versenumber:        20,verse:"耶稣说：“狐狸有洞，天空的飞鸟有窝，人子却没有枕头的地方。”"},
{volumn:        40,chapter:         8,versenumber:        21,verse:"又有一个门徒对耶稣说：“主啊，容我先回去埋葬我的父亲。”"},
{volumn:        40,chapter:         8,versenumber:        22,verse:"耶稣说：“任凭死人埋葬他们的死人；你跟从我吧！”"},
{volumn:        40,chapter:         8,versenumber:        23,verse:"耶稣上了船，门徒跟着他。"},
{volumn:        40,chapter:         8,versenumber:        24,verse:"海里忽然起了暴风，甚至船被波浪掩盖；耶稣却睡着了。"},
{volumn:        40,chapter:         8,versenumber:        25,verse:"门徒来叫醒了他，说：“主啊，救我们，我们丧命啦！”"},
{volumn:        40,chapter:         8,versenumber:        26,verse:"耶稣说：“你们这小信的人哪，为什么胆怯呢？”于是起来，斥责风和海，风和海就大大地平静了。"},
{volumn:        40,chapter:         8,versenumber:        27,verse:"众人希奇，说：“这是怎样的人？连风和海也听从他了！”"},
{volumn:        40,chapter:         8,versenumber:        28,verse:"耶稣既渡到那边去，来到加大拉人的地方，就有两个被鬼附的人从坟茔里出来迎着他，极其凶猛，甚至没有人能从那条路上经过。"},
{volumn:        40,chapter:         8,versenumber:        29,verse:"他们喊着说：“　神的儿子，我们与你有什么相干？时候还没有到，你就上这里来叫我们受苦吗？”"},
{volumn:        40,chapter:         8,versenumber:        30,verse:"离他们很远，有一大群猪吃食。"},
{volumn:        40,chapter:         8,versenumber:        31,verse:"鬼就央求耶稣，说：“若把我们赶出去，就打发我们进入猪群吧！”"},
{volumn:        40,chapter:         8,versenumber:        32,verse:"耶稣说：“去吧！”鬼就出来，进入猪群。全群忽然闯下山崖，投在海里淹死了。"},
{volumn:        40,chapter:         8,versenumber:        33,verse:"放猪的就逃跑进城，将这一切事和被鬼附的人所遭遇的都告诉人。"},
{volumn:        40,chapter:         8,versenumber:        34,verse:"合城的人都出来迎见耶稣，既见了就央求他离开他们的境界。"},
{volumn:        40,chapter:         9,versenumber:         1,verse:"耶稣上了船，渡过海，来到自己的城里。"},
{volumn:        40,chapter:         9,versenumber:         2,verse:"有人用褥子抬着一个瘫子到耶稣跟前来。耶稣见他们的信心，就对瘫子说：“小子，放心吧！你的罪赦了。”"},
{volumn:        40,chapter:         9,versenumber:         3,verse:"有几个文士心里说：“这个人说僭妄的话了。”"},
{volumn:        40,chapter:         9,versenumber:         4,verse:"耶稣知道他们的心意，就说：“你们为什么心里怀着恶念呢？"},
{volumn:        40,chapter:         9,versenumber:         5,verse:"或说‘你的罪赦了’，或说‘你起来行走’，哪一样容易呢？"},
{volumn:        40,chapter:         9,versenumber:         6,verse:"但要叫你们知道，人子在地上有赦罪的权柄”；就对瘫子说：“起来！拿你的褥子回家去吧。”"},
{volumn:        40,chapter:         9,versenumber:         7,verse:"那人就起来，回家去了。"},
{volumn:        40,chapter:         9,versenumber:         8,verse:"众人看见都惊奇，就归荣耀与　神，因为他将这样的权柄赐给人。"},
{volumn:        40,chapter:         9,versenumber:         9,verse:"耶稣从那里往前走，看见一个人名叫马太，坐在税关上，就对他说：“你跟从我来。”他就起来跟从了耶稣。"},
{volumn:        40,chapter:         9,versenumber:        10,verse:"耶稣在屋里坐席的时候，有好些税吏和罪人来，与耶稣和他的门徒一同坐席。"},
{volumn:        40,chapter:         9,versenumber:        11,verse:"法利赛人看见，就对耶稣的门徒说：“你们的先生为什么和税吏并罪人一同吃饭呢？”"},
{volumn:        40,chapter:         9,versenumber:        12,verse:"耶稣听见，就说：“康健的人用不着医生，有病的人才用得着。"},
{volumn:        40,chapter:         9,versenumber:        13,verse:"经上说：‘我喜爱怜恤，不喜爱祭祀。’这句话的意思，你们且去揣摩。我来本不是召义人，乃是召罪人。”"},
{volumn:        40,chapter:         9,versenumber:        14,verse:"那时，约翰的门徒来见耶稣，说：“我们和法利赛人常常禁食，你的门徒倒不禁食，这是为什么呢？”"},
{volumn:        40,chapter:         9,versenumber:        15,verse:"耶稣对他们说：“新郎和陪伴之人同在的时候，陪伴之人岂能哀恸呢？但日子将到，新郎要离开他们，那时候他们就要禁食。"},
{volumn:        40,chapter:         9,versenumber:        16,verse:"没有人把新布补在旧衣服上；因为所补上的反带坏了那衣服，破的就更大了。"},
{volumn:        40,chapter:         9,versenumber:        17,verse:"也没有人把新酒装在旧皮袋里；若是这样，皮袋就裂开，酒漏出来，连皮袋也坏了。惟独把新酒装在新皮袋里，两样就都保全了。”"},
{volumn:        40,chapter:         9,versenumber:        18,verse:"耶稣说这话的时候，有一个管会堂的来拜他，说：“我女儿刚才死了，求你去按手在她身上，她就必活了。”"},
{volumn:        40,chapter:         9,versenumber:        19,verse:"耶稣便起来跟着他去；门徒也跟了去。"},
{volumn:        40,chapter:         9,versenumber:        20,verse:"有一个女人，患了十二年的血漏，来到耶稣背后，摸他的衣裳繸子；"},
{volumn:        40,chapter:         9,versenumber:        21,verse:"因为她心里说：“我只摸他的衣裳，就必痊愈。”"},
{volumn:        40,chapter:         9,versenumber:        22,verse:"耶稣转过来，看见她，就说：“女儿，放心！你的信救了你。”从那时候，女人就痊愈了。"},
{volumn:        40,chapter:         9,versenumber:        23,verse:"耶稣到了管会堂的家里，看见有吹手，又有许多人乱嚷，"},
{volumn:        40,chapter:         9,versenumber:        24,verse:"就说：“退去吧！这闺女不是死了，是睡着了。”他们就嗤笑他。"},
{volumn:        40,chapter:         9,versenumber:        25,verse:"众人既被撵出，耶稣就进去，拉着闺女的手，闺女便起来了。"},
{volumn:        40,chapter:         9,versenumber:        26,verse:"于是这风声传遍了那地方。"},
{volumn:        40,chapter:         9,versenumber:        27,verse:"耶稣从那里往前走，有两个瞎子跟着他，喊叫说：“大卫的子孙，可怜我们吧！”"},
{volumn:        40,chapter:         9,versenumber:        28,verse:"耶稣进了房子，瞎子就来到他跟前。耶稣说：“你们信我能做这事吗？”他们说：“主啊，我们信。”"},
{volumn:        40,chapter:         9,versenumber:        29,verse:"耶稣就摸他们的眼睛，说：“照着你们的信给你们成全了吧。”"},
{volumn:        40,chapter:         9,versenumber:        30,verse:"他们的眼睛就开了。耶稣切切地嘱咐他们说：“你们要小心，不可叫人知道。”"},
{volumn:        40,chapter:         9,versenumber:        31,verse:"他们出去，竟把他的名声传遍了那地方。"},
{volumn:        40,chapter:         9,versenumber:        32,verse:"他们出去的时候，有人将鬼所附的一个哑巴带到耶稣跟前来。"},
{volumn:        40,chapter:         9,versenumber:        33,verse:"鬼被赶出去，哑巴就说出话来。众人都希奇，说：“在以色列中，从来没有见过这样的事。”"},
{volumn:        40,chapter:         9,versenumber:        34,verse:"法利赛人却说：“他是靠着鬼王赶鬼。”"},
{volumn:        40,chapter:         9,versenumber:        35,verse:"耶稣走遍各城各乡，在会堂里教训人，宣讲天国的福音，又医治各样的病症。"},
{volumn:        40,chapter:         9,versenumber:        36,verse:"他看见许多的人，就怜悯他们；因为他们困苦流离，如同羊没有牧人一般。"},
{volumn:        40,chapter:         9,versenumber:        37,verse:"于是对门徒说：“要收的庄稼多，做工的人少。"},
{volumn:        40,chapter:         9,versenumber:        38,verse:"所以，你们当求庄稼的主打发工人出去收他的庄稼。”"},
{volumn:        40,chapter:        10,versenumber:         1,verse:"耶稣叫了十二个门徒来，给他们权柄，能赶逐污鬼，并医治各样的病症。"},
{volumn:        40,chapter:        10,versenumber:         2,verse:"这十二使徒的名：头一个叫西门（又称彼得），还有他兄弟安得烈，西庇太的儿子雅各和雅各的兄弟约翰，"},
{volumn:        40,chapter:        10,versenumber:         3,verse:"腓力和巴多罗买，多马和税吏马太，亚勒腓的儿子雅各，和达太，"},
{volumn:        40,chapter:        10,versenumber:         4,verse:"奋锐党的西门，还有卖耶稣的加略人犹大。"},
{volumn:        40,chapter:        10,versenumber:         5,verse:"耶稣差这十二个人去，吩咐他们说：“外邦人的路，你们不要走；撒玛利亚人的城，你们不要进；"},
{volumn:        40,chapter:        10,versenumber:         6,verse:"宁可往以色列家迷失的羊那里去。"},
{volumn:        40,chapter:        10,versenumber:         7,verse:"随走随传，说‘天国近了！’"},
{volumn:        40,chapter:        10,versenumber:         8,verse:"医治病人，叫死人复活，叫长大麻风的洁净，把鬼赶出去。你们白白地得来，也要白白地舍去。"},
{volumn:        40,chapter:        10,versenumber:         9,verse:"腰袋里不要带金银铜钱；"},
{volumn:        40,chapter:        10,versenumber:        10,verse:"行路不要带口袋；不要带两件褂子，也不要带鞋和拐杖。因为工人得饮食是应当的。"},
{volumn:        40,chapter:        10,versenumber:        11,verse:"你们无论进哪一城，哪一村，要打听那里谁是好人，就住在他家，直住到走的时候。"},
{volumn:        40,chapter:        10,versenumber:        12,verse:"进他家里去，要请他的安。"},
{volumn:        40,chapter:        10,versenumber:        13,verse:"那家若配得平安，你们所求的平安就必临到那家；若不配得，你们所求的平安仍归你们。"},
{volumn:        40,chapter:        10,versenumber:        14,verse:"凡不接待你们、不听你们话的人，你们离开那家，或是那城的时候，就把脚上的尘土跺下去。"},
{volumn:        40,chapter:        10,versenumber:        15,verse:"我实在告诉你们，当审判的日子，所多玛和蛾摩拉所受的，比那城还容易受呢！”"},
{volumn:        40,chapter:        10,versenumber:        16,verse:"“我差你们去，如同羊进入狼群；所以你们要灵巧像蛇，驯良像鸽子。"},
{volumn:        40,chapter:        10,versenumber:        17,verse:"你们要防备人；因为他们要把你们交给公会，也要在会堂里鞭打你们，"},
{volumn:        40,chapter:        10,versenumber:        18,verse:"并且你们要为我的缘故被送到诸侯君王面前，对他们和外邦人作见证。"},
{volumn:        40,chapter:        10,versenumber:        19,verse:"你们被交的时候，不要思虑怎样说话，或说什么话。到那时候，必赐给你们当说的话；"},
{volumn:        40,chapter:        10,versenumber:        20,verse:"因为不是你们自己说的，乃是你们父的灵在你们里头说的。"},
{volumn:        40,chapter:        10,versenumber:        21,verse:"弟兄要把弟兄，父亲要把儿子，送到死地；儿女要与父母为敌，害死他们；"},
{volumn:        40,chapter:        10,versenumber:        22,verse:"并且你们要为我的名被众人恨恶。惟有忍耐到底的必然得救。"},
{volumn:        40,chapter:        10,versenumber:        23,verse:"有人在这城里逼迫你们，就逃到那城里去。我实在告诉你们，以色列的城邑，你们还没有走遍，人子就到了。"},
{volumn:        40,chapter:        10,versenumber:        24,verse:"学生不能高过先生；仆人不能高过主人。"},
{volumn:        40,chapter:        10,versenumber:        25,verse:"学生和先生一样，仆人和主人一样，也就罢了。人既骂家主是别西卜（别西卜：是鬼王的名），何况他的家人呢？”"},
{volumn:        40,chapter:        10,versenumber:        26,verse:"“所以，不要怕他们；因为掩盖的事没有不露出来的，隐藏的事没有不被人知道的。"},
{volumn:        40,chapter:        10,versenumber:        27,verse:"我在暗中告诉你们的，你们要在明处说出来；你们耳中所听的，要在房上宣扬出来。"},
{volumn:        40,chapter:        10,versenumber:        28,verse:"那杀身体、不能杀灵魂的，不要怕他们；惟有能把身体和灵魂都灭在地狱里的，正要怕他。"},
{volumn:        40,chapter:        10,versenumber:        29,verse:"两个麻雀不是卖一分银子吗？若是你们的父不许，一个也不能掉在地上；"},
{volumn:        40,chapter:        10,versenumber:        30,verse:"就是你们的头发也都被数过了。"},
{volumn:        40,chapter:        10,versenumber:        31,verse:"所以，不要惧怕，你们比许多麻雀还贵重！”"},
{volumn:        40,chapter:        10,versenumber:        32,verse:"“凡在人面前认我的，我在我天上的父面前也必认他；"},
{volumn:        40,chapter:        10,versenumber:        33,verse:"凡在人面前不认我的，我在我天上的父面前也必不认他。”"},
{volumn:        40,chapter:        10,versenumber:        34,verse:"“你们不要想我来是叫地上太平；我来并不是叫地上太平，乃是叫地上动刀兵。"},
{volumn:        40,chapter:        10,versenumber:        35,verse:"因为我来是叫人与父亲生疏，女儿与母亲生疏，媳妇与婆婆生疏。"},
{volumn:        40,chapter:        10,versenumber:        36,verse:"人的仇敌就是自己家里的人。"},
{volumn:        40,chapter:        10,versenumber:        37,verse:"爱父母过于爱我的，不配作我的门徒；爱儿女过于爱我的，不配作我的门徒；"},
{volumn:        40,chapter:        10,versenumber:        38,verse:"不背着他的十字架跟从我的，也不配作我的门徒。"},
{volumn:        40,chapter:        10,versenumber:        39,verse:"得着生命的，将要失丧生命；为我失丧生命的，将要得着生命。”"},
{volumn:        40,chapter:        10,versenumber:        40,verse:"“人接待你们就是接待我；接待我就是接待那差我来的。"},
{volumn:        40,chapter:        10,versenumber:        41,verse:"人因为先知的名接待先知，必得先知所得的赏赐；人因为义人的名接待义人，必得义人所得的赏赐。"},
{volumn:        40,chapter:        10,versenumber:        42,verse:"无论何人，因为门徒的名，只把一杯凉水给这小子里的一个喝，我实在告诉你们，这人不能不得赏赐。”"},
{volumn:        40,chapter:        11,versenumber:         1,verse:"耶稣吩咐完了十二个门徒，就离开那里，往各城去传道、教训人。"},
{volumn:        40,chapter:        11,versenumber:         2,verse:"约翰在监里听见基督所做的事，就打发两个门徒去，"},
{volumn:        40,chapter:        11,versenumber:         3,verse:"问他说：“那将要来的是你吗？还是我们等候别人呢？”"},
{volumn:        40,chapter:        11,versenumber:         4,verse:"耶稣回答说：“你们去，把所听见、所看见的事告诉约翰。"},
{volumn:        40,chapter:        11,versenumber:         5,verse:"就是瞎子看见，瘸子行走，长大麻风的洁净，聋子听见，死人复活，穷人有福音传给他们。"},
{volumn:        40,chapter:        11,versenumber:         6,verse:"凡不因我跌倒的就有福了！”"},
{volumn:        40,chapter:        11,versenumber:         7,verse:"他们走的时候，耶稣就对众人讲论约翰说：“你们从前出到旷野是要看什么呢？要看风吹动的芦苇吗？"},
{volumn:        40,chapter:        11,versenumber:         8,verse:"你们出去到底是要看什么？要看穿细软衣服的人吗？那穿细软衣服的人是在王宫里。"},
{volumn:        40,chapter:        11,versenumber:         9,verse:"你们出去究竟是为什么？是要看先知吗？我告诉你们，是的，他比先知大多了。"},
{volumn:        40,chapter:        11,versenumber:        10,verse:"经上记着说：‘我要差遣我的使者在你前面预备道路’，所说的就是这个人。"},
{volumn:        40,chapter:        11,versenumber:        11,verse:"我实在告诉你们，凡妇人所生的，没有一个兴起来大过施洗约翰的；然而天国里最小的比他还大。"},
{volumn:        40,chapter:        11,versenumber:        12,verse:"从施洗约翰的时候到如今，天国是努力进入的，努力的人就得着了。"},
{volumn:        40,chapter:        11,versenumber:        13,verse:"因为众先知和律法说预言，到约翰为止。"},
{volumn:        40,chapter:        11,versenumber:        14,verse:"你们若肯领受，这人就是那应当来的以利亚。"},
{volumn:        40,chapter:        11,versenumber:        15,verse:"有耳可听的，就应当听！"},
{volumn:        40,chapter:        11,versenumber:        16,verse:"我可用什么比这世代呢？好像孩童坐在街市上招呼同伴，说："},
{volumn:        40,chapter:        11,versenumber:        17,verse:"‘我们向你们吹笛，你们不跳舞；我们向你们举哀，你们不捶胸。’"},
{volumn:        40,chapter:        11,versenumber:        18,verse:"约翰来了，也不吃也不喝，人就说他是被鬼附着的；"},
{volumn:        40,chapter:        11,versenumber:        19,verse:"人子来了，也吃也喝，人又说他是贪食好酒的人，是税吏和罪人的朋友。但智慧之子总以智慧为是（有古卷：但智慧在行为上就显为是）。”"},
{volumn:        40,chapter:        11,versenumber:        20,verse:"耶稣在诸城中行了许多异能，那些城的人终不悔改，就在那时候责备他们，说："},
{volumn:        40,chapter:        11,versenumber:        21,verse:"“哥拉汛哪，你有祸了！伯赛大啊，你有祸了！因为在你们中间所行的异能，若行在推罗、西顿，他们早已披麻蒙灰悔改了。"},
{volumn:        40,chapter:        11,versenumber:        22,verse:"但我告诉你们，当审判的日子，推罗、西顿所受的，比你们还容易受呢！"},
{volumn:        40,chapter:        11,versenumber:        23,verse:"迦百农啊，你已经升到天上（或译：你将要升到天上吗），将来必坠落阴间；因为在你那里所行的异能，若行在所多玛，它还可以存到今日。"},
{volumn:        40,chapter:        11,versenumber:        24,verse:"但我告诉你们，当审判的日子，所多玛所受的，比你还容易受呢！”"},
{volumn:        40,chapter:        11,versenumber:        25,verse:"那时，耶稣说：“父啊，天地的主，我感谢你！因为你将这些事向聪明通达人就藏起来，向婴孩就显出来。"},
{volumn:        40,chapter:        11,versenumber:        26,verse:"父啊，是的，因为你的美意本是如此。"},
{volumn:        40,chapter:        11,versenumber:        27,verse:"一切所有的，都是我父交付我的；除了父，没有人知道子；除了子和子所愿意指示的，没有人知道父。"},
{volumn:        40,chapter:        11,versenumber:        28,verse:"凡劳苦担重担的人可以到我这里来，我就使你们得安息。"},
{volumn:        40,chapter:        11,versenumber:        29,verse:"我心里柔和谦卑，你们当负我的轭，学我的样式；这样，你们心里就必得享安息。"},
{volumn:        40,chapter:        11,versenumber:        30,verse:"因为我的轭是容易的，我的担子是轻省的。”"},
{volumn:        40,chapter:        12,versenumber:         1,verse:"那时，耶稣在安息日从麦地经过。他的门徒饿了，就掐起麦穗来吃。"},
{volumn:        40,chapter:        12,versenumber:         2,verse:"法利赛人看见，就对耶稣说：“看哪，你的门徒做安息日不可做的事了！”"},
{volumn:        40,chapter:        12,versenumber:         3,verse:"耶稣对他们说：“经上记着大卫和跟从他的人饥饿之时所做的事，你们没有念过吗？"},
{volumn:        40,chapter:        12,versenumber:         4,verse:"他怎么进了　神的殿，吃了陈设饼，这饼不是他和跟从他的人可以吃得，惟独祭司才可以吃。"},
{volumn:        40,chapter:        12,versenumber:         5,verse:"再者，律法上所记的，当安息日，祭司在殿里犯了安息日还是没有罪，你们没有念过吗？"},
{volumn:        40,chapter:        12,versenumber:         6,verse:"但我告诉你们，在这里有一人比殿更大。"},
{volumn:        40,chapter:        12,versenumber:         7,verse:"‘我喜爱怜恤，不喜爱祭祀。’你们若明白这话的意思，就不将无罪的当作有罪的了。"},
{volumn:        40,chapter:        12,versenumber:         8,verse:"因为人子是安息日的主。”"},
{volumn:        40,chapter:        12,versenumber:         9,verse:"耶稣离开那地方，进了一个会堂。"},
{volumn:        40,chapter:        12,versenumber:        10,verse:"那里有一个人枯干了一只手。有人问耶稣说：“安息日治病可以不可以？”意思是要控告他。"},
{volumn:        40,chapter:        12,versenumber:        11,verse:"耶稣说：“你们中间谁有一只羊，当安息日掉在坑里，不把它抓住、拉上来呢？"},
{volumn:        40,chapter:        12,versenumber:        12,verse:"人比羊何等贵重呢！所以，在安息日做善事是可以的。”"},
{volumn:        40,chapter:        12,versenumber:        13,verse:"于是对那人说：“伸出手来！”他把手一伸，手就复了原，和那只手一样。"},
{volumn:        40,chapter:        12,versenumber:        14,verse:"法利赛人出去，商议怎样可以除灭耶稣。"},
{volumn:        40,chapter:        12,versenumber:        15,verse:"耶稣知道了，就离开那里，有许多人跟着他。他把其中有病的人都治好了；"},
{volumn:        40,chapter:        12,versenumber:        16,verse:"又嘱咐他们，不要给他传名。"},
{volumn:        40,chapter:        12,versenumber:        17,verse:"这是要应验先知以赛亚的话，说："},
{volumn:        40,chapter:        12,versenumber:        18,verse:"“看哪！我的仆人，我所拣选、所亲爱、心里所喜悦的，我要将我的灵赐给他；他必将公理传给外邦。"},
{volumn:        40,chapter:        12,versenumber:        19,verse:"他不争竞，不喧嚷；街上也没有人听见他的声音。"},
{volumn:        40,chapter:        12,versenumber:        20,verse:"压伤的芦苇，他不折断；将残的灯火，他不吹灭；等他施行公理，叫公理得胜。"},
{volumn:        40,chapter:        12,versenumber:        21,verse:"外邦人都要仰望他的名。”"},
{volumn:        40,chapter:        12,versenumber:        22,verse:"当下，有人将一个被鬼附着、又瞎又哑的人带到耶稣那里，耶稣就医治他，甚至那哑巴又能说话，又能看见。"},
{volumn:        40,chapter:        12,versenumber:        23,verse:"众人都惊奇，说：“这不是大卫的子孙吗？”"},
{volumn:        40,chapter:        12,versenumber:        24,verse:"但法利赛人听见，就说：“这个人赶鬼，无非是靠着鬼王别西卜啊。”"},
{volumn:        40,chapter:        12,versenumber:        25,verse:"耶稣知道他们的意念，就对他们说：“凡一国自相纷争，就成为荒场；一城一家自相纷争，必站立不住；"},
{volumn:        40,chapter:        12,versenumber:        26,verse:"若撒但赶逐撒但，就是自相纷争，他的国怎能站得住呢？"},
{volumn:        40,chapter:        12,versenumber:        27,verse:"我若靠着别西卜赶鬼，你们的子弟赶鬼又靠着谁呢？这样，他们就要断定你们的是非。"},
{volumn:        40,chapter:        12,versenumber:        28,verse:"我若靠着　神的灵赶鬼，这就是　神的国临到你们了。"},
{volumn:        40,chapter:        12,versenumber:        29,verse:"人怎能进壮士家里，抢夺他的家具呢？除非先捆住那壮士，才可以抢夺他的家财。"},
{volumn:        40,chapter:        12,versenumber:        30,verse:"不与我相合的，就是敌我的；不同我收聚的，就是分散的。”"},
{volumn:        40,chapter:        12,versenumber:        31,verse:"所以我告诉你们：“人一切的罪和亵渎的话都可得赦免，惟独亵渎圣灵，总不得赦免。"},
{volumn:        40,chapter:        12,versenumber:        32,verse:"凡说话干犯人子的，还可得赦免；惟独说话干犯圣灵的，今世来世总不得赦免。”"},
{volumn:        40,chapter:        12,versenumber:        33,verse:"“你们或以为树好，果子也好；树坏，果子也坏；因为看果子就可以知道树。"},
{volumn:        40,chapter:        12,versenumber:        34,verse:"毒蛇的种类！你们既是恶人，怎能说出好话来呢？因为心里所充满的，口里就说出来。"},
{volumn:        40,chapter:        12,versenumber:        35,verse:"善人从他心里所存的善就发出善来；恶人从他心里所存的恶就发出恶来。"},
{volumn:        40,chapter:        12,versenumber:        36,verse:"我又告诉你们，凡人所说的闲话，当审判的日子，必要句句供出来；"},
{volumn:        40,chapter:        12,versenumber:        37,verse:"因为要凭你的话定你为义，也要凭你的话定你有罪。”"},
{volumn:        40,chapter:        12,versenumber:        38,verse:"当时，有几个文士和法利赛人对耶稣说：“夫子，我们愿意你显个神迹给我们看。”"},
{volumn:        40,chapter:        12,versenumber:        39,verse:"耶稣回答说：“一个邪恶淫乱的世代求看神迹，除了先知约拿的神迹以外，再没有神迹给他们看。"},
{volumn:        40,chapter:        12,versenumber:        40,verse:"约拿三日三夜在大鱼肚腹中，人子也要这样三日三夜在地里头。"},
{volumn:        40,chapter:        12,versenumber:        41,verse:"当审判的时候，尼尼微人要起来定这世代的罪，因为尼尼微人听了约拿所传的就悔改了。看哪，在这里有一人比约拿更大！"},
{volumn:        40,chapter:        12,versenumber:        42,verse:"当审判的时候，南方的女王要起来定这世代的罪；因为她从地极而来，要听所罗门的智慧话。看哪，在这里有一人比所罗门更大！”"},
{volumn:        40,chapter:        12,versenumber:        43,verse:"“污鬼离了人身，就在无水之地过来过去，寻求安歇之处，却寻不着。"},
{volumn:        40,chapter:        12,versenumber:        44,verse:"于是说：‘我要回到我所出来的屋里去。’到了，就看见里面空闲，打扫干净，修饰好了，"},
{volumn:        40,chapter:        12,versenumber:        45,verse:"便去另带了七个比自己更恶的鬼来，都进去住在那里。那人末后的景况比先前更不好了。这邪恶的世代也要如此。”"},
{volumn:        40,chapter:        12,versenumber:        46,verse:"耶稣还对众人说话的时候，不料他母亲和他弟兄站在外边，要与他说话。"},
{volumn:        40,chapter:        12,versenumber:        47,verse:"有人告诉他说：“看哪，你母亲和你弟兄站在外边，要与你说话。”"},
{volumn:        40,chapter:        12,versenumber:        48,verse:"他却回答那人说：“谁是我的母亲？谁是我的弟兄？”"},
{volumn:        40,chapter:        12,versenumber:        49,verse:"就伸手指着门徒，说：“看哪，我的母亲，我的弟兄。"},
{volumn:        40,chapter:        12,versenumber:        50,verse:"凡遵行我天父旨意的人，就是我的弟兄姐妹和母亲了。”"},
{volumn:        40,chapter:        13,versenumber:         1,verse:"当那一天，耶稣从房子里出来，坐在海边。"},
{volumn:        40,chapter:        13,versenumber:         2,verse:"有许多人到他那里聚集，他只得上船坐下，众人都站在岸上。"},
{volumn:        40,chapter:        13,versenumber:         3,verse:"他用比喻对他们讲许多道理，说：“有一个撒种的出去撒种；"},
{volumn:        40,chapter:        13,versenumber:         4,verse:"撒的时候，有落在路旁的，飞鸟来吃尽了；"},
{volumn:        40,chapter:        13,versenumber:         5,verse:"有落在土浅石头地上的，土既不深，发苗最快，"},
{volumn:        40,chapter:        13,versenumber:         6,verse:"日头出来一晒，因为没有根，就枯干了；"},
{volumn:        40,chapter:        13,versenumber:         7,verse:"有落在荆棘里的，荆棘长起来，把它挤住了；"},
{volumn:        40,chapter:        13,versenumber:         8,verse:"又有落在好土里的，就结实，有一百倍的，有六十倍的，有三十倍的。"},
{volumn:        40,chapter:        13,versenumber:         9,verse:"有耳可听的，就应当听！”"},
{volumn:        40,chapter:        13,versenumber:        10,verse:"门徒进前来，问耶稣说：“对众人讲话，为什么用比喻呢？”"},
{volumn:        40,chapter:        13,versenumber:        11,verse:"耶稣回答说：“因为天国的奥秘只叫你们知道，不叫他们知道。"},
{volumn:        40,chapter:        13,versenumber:        12,verse:"凡有的，还要加给他，叫他有余；凡没有的，连他所有的，也要夺去。"},
{volumn:        40,chapter:        13,versenumber:        13,verse:"所以我用比喻对他们讲，是因他们看也看不见，听也听不见，也不明白。"},
{volumn:        40,chapter:        13,versenumber:        14,verse:"在他们身上，正应了以赛亚的预言，说：‘你们听是要听见，却不明白；看是要看见，却不晓得；"},
{volumn:        40,chapter:        13,versenumber:        15,verse:"因为这百姓油蒙了心，耳朵发沉，眼睛闭着，恐怕眼睛看见，耳朵听见，心里明白，回转过来，我就医治他们。’"},
{volumn:        40,chapter:        13,versenumber:        16,verse:"“但你们的眼睛是有福的，因为看见了；你们的耳朵也是有福的，因为听见了。"},
{volumn:        40,chapter:        13,versenumber:        17,verse:"我实在告诉你们，从前有许多先知和义人要看你们所看的，却没有看见，要听你们所听的，却没有听见。”"},
{volumn:        40,chapter:        13,versenumber:        18,verse:"“所以，你们当听这撒种的比喻。"},
{volumn:        40,chapter:        13,versenumber:        19,verse:"凡听见天国道理不明白的，那恶者就来，把所撒在他心里的夺了去；这就是撒在路旁的了。"},
{volumn:        40,chapter:        13,versenumber:        20,verse:"撒在石头地上的，就是人听了道，当下欢喜领受，"},
{volumn:        40,chapter:        13,versenumber:        21,verse:"只因心里没有根，不过是暂时的，及至为道遭了患难，或是受了逼迫，立刻就跌倒了。"},
{volumn:        40,chapter:        13,versenumber:        22,verse:"撒在荆棘里的，就是人听了道，后来有世上的思虑、钱财的迷惑把道挤住了，不能结实。"},
{volumn:        40,chapter:        13,versenumber:        23,verse:"撒在好地上的，就是人听道明白了，后来结实，有一百倍的，有六十倍的，有三十倍的。”"},
{volumn:        40,chapter:        13,versenumber:        24,verse:"耶稣又设个比喻对他们说：“天国好像人撒好种在田里，"},
{volumn:        40,chapter:        13,versenumber:        25,verse:"及至人睡觉的时候，有仇敌来，将稗子撒在麦子里就走了。"},
{volumn:        40,chapter:        13,versenumber:        26,verse:"到长苗吐穗的时候，稗子也显出来。"},
{volumn:        40,chapter:        13,versenumber:        27,verse:"田主的仆人来告诉他说：‘主啊，你不是撒好种在田里吗？从哪里来的稗子呢？’"},
{volumn:        40,chapter:        13,versenumber:        28,verse:"主人说：‘这是仇敌做的。’仆人说：‘你要我们去薅出来吗？’"},
{volumn:        40,chapter:        13,versenumber:        29,verse:"主人说：‘不必，恐怕薅稗子，连麦子也拔出来。"},
{volumn:        40,chapter:        13,versenumber:        30,verse:"容这两样一齐长，等着收割。当收割的时候，我要对收割的人说，先将稗子薅出来，捆成捆，留着烧；惟有麦子要收在仓里。’”"},
{volumn:        40,chapter:        13,versenumber:        31,verse:"他又设个比喻对他们说：“天国好像一粒芥菜种，有人拿去种在田里。"},
{volumn:        40,chapter:        13,versenumber:        32,verse:"这原是百种里最小的，等到长起来，却比各样的菜都大，且成了树，天上的飞鸟来宿在它的枝上。”"},
{volumn:        40,chapter:        13,versenumber:        33,verse:"他又对他们讲个比喻说：“天国好像面酵，有妇人拿来，藏在三斗面里，直等全团都发起来。”"},
{volumn:        40,chapter:        13,versenumber:        34,verse:"这都是耶稣用比喻对众人说的话；若不用比喻，就不对他们说什么。"},
{volumn:        40,chapter:        13,versenumber:        35,verse:"这是要应验先知的话，说：“我要开口用比喻，把创世以来所隐藏的事发明出来。”"},
{volumn:        40,chapter:        13,versenumber:        36,verse:"当下，耶稣离开众人，进了房子。他的门徒进前来，说：“请把田间稗子的比喻讲给我们听。”"},
{volumn:        40,chapter:        13,versenumber:        37,verse:"他回答说：“那撒好种的就是人子；"},
{volumn:        40,chapter:        13,versenumber:        38,verse:"田地就是世界；好种就是天国之子；稗子就是那恶者之子；"},
{volumn:        40,chapter:        13,versenumber:        39,verse:"撒稗子的仇敌就是魔鬼；收割的时候就是世界的末了；收割的人就是天使。"},
{volumn:        40,chapter:        13,versenumber:        40,verse:"将稗子薅出来用火焚烧，世界的末了也要如此。"},
{volumn:        40,chapter:        13,versenumber:        41,verse:"人子要差遣使者，把一切叫人跌倒的和作恶的，从他国里挑出来，"},
{volumn:        40,chapter:        13,versenumber:        42,verse:"丢在火炉里；在那里必要哀哭切齿了。"},
{volumn:        40,chapter:        13,versenumber:        43,verse:"那时，义人在他们父的国里，要发出光来，像太阳一样。有耳可听的，就应当听！”"},
{volumn:        40,chapter:        13,versenumber:        44,verse:"“天国好像宝贝藏在地里，人遇见了就把它藏起来，欢欢喜喜地去变卖一切所有的，买这块地。”"},
{volumn:        40,chapter:        13,versenumber:        45,verse:"“天国又好像买卖人寻找好珠子，"},
{volumn:        40,chapter:        13,versenumber:        46,verse:"遇见一颗重价的珠子，就去变卖他一切所有的，买了这颗珠子。”"},
{volumn:        40,chapter:        13,versenumber:        47,verse:"“天国又好像网撒在海里，聚拢各样水族，"},
{volumn:        40,chapter:        13,versenumber:        48,verse:"网既满了，人就拉上岸来，坐下，拣好的收在器具里，将不好的丢弃了。"},
{volumn:        40,chapter:        13,versenumber:        49,verse:"世界的末了也要这样。天使要出来，从义人中把恶人分别出来，"},
{volumn:        40,chapter:        13,versenumber:        50,verse:"丢在火炉里；在那里必要哀哭切齿了。”"},
{volumn:        40,chapter:        13,versenumber:        51,verse:"耶稣说：“这一切的话你们都明白了吗？”他们说：“我们明白了。”"},
{volumn:        40,chapter:        13,versenumber:        52,verse:"他说：“凡文士受教作天国的门徒，就像一个家主从他库里拿出新旧的东西来。”"},
{volumn:        40,chapter:        13,versenumber:        53,verse:"耶稣说完了这些比喻，就离开那里，"},
{volumn:        40,chapter:        13,versenumber:        54,verse:"来到自己的家乡，在会堂里教训人，甚至他们都希奇，说：“这人从哪里有这等智慧和异能呢？"},
{volumn:        40,chapter:        13,versenumber:        55,verse:"这不是木匠的儿子吗？他母亲不是叫马利亚吗？他弟兄们不是叫雅各、约西（有古卷：约瑟）、西门、犹大吗？"},
{volumn:        40,chapter:        13,versenumber:        56,verse:"他妹妹们不是都在我们这里吗？这人从哪里有这一切的事呢？”"},
{volumn:        40,chapter:        13,versenumber:        57,verse:"他们就厌弃他（厌弃他：原文是因他跌倒）。耶稣对他们说：“大凡先知，除了本地本家之外，没有不被人尊敬的。”"},
{volumn:        40,chapter:        13,versenumber:        58,verse:"耶稣因为他们不信，就在那里不多行异能了。"},
{volumn:        40,chapter:        14,versenumber:         1,verse:"那时，分封的王希律听见耶稣的名声，"},
{volumn:        40,chapter:        14,versenumber:         2,verse:"就对臣仆说：“这是施洗的约翰从死里复活，所以这些异能从他里面发出来。”"},
{volumn:        40,chapter:        14,versenumber:         3,verse:"起先，希律为他兄弟腓力的妻子希罗底的缘故，把约翰拿住，锁在监里。"},
{volumn:        40,chapter:        14,versenumber:         4,verse:"因为约翰曾对他说：“你娶这妇人是不合理的。”"},
{volumn:        40,chapter:        14,versenumber:         5,verse:"希律就想要杀他，只是怕百姓，因为他们以约翰为先知。"},
{volumn:        40,chapter:        14,versenumber:         6,verse:"到了希律的生日，希罗底的女儿在众人面前跳舞，使希律欢喜。"},
{volumn:        40,chapter:        14,versenumber:         7,verse:"希律就起誓，应许随她所求的给她。"},
{volumn:        40,chapter:        14,versenumber:         8,verse:"女儿被母亲所使，就说：“请把施洗约翰的头放在盘子里，拿来给我。”"},
{volumn:        40,chapter:        14,versenumber:         9,verse:"王便忧愁，但因他所起的誓，又因同席的人，就吩咐给她；"},
{volumn:        40,chapter:        14,versenumber:        10,verse:"于是打发人去，在监里斩了约翰，"},
{volumn:        40,chapter:        14,versenumber:        11,verse:"把头放在盘子里，拿来给了女子；女子拿去给她母亲。"},
{volumn:        40,chapter:        14,versenumber:        12,verse:"约翰的门徒来，把尸首领去埋葬了，就去告诉耶稣。"},
{volumn:        40,chapter:        14,versenumber:        13,verse:"耶稣听见了，就上船从那里独自退到野地里去。众人听见，就从各城里步行跟随他。"},
{volumn:        40,chapter:        14,versenumber:        14,verse:"耶稣出来，见有许多的人，就怜悯他们，治好了他们的病人。"},
{volumn:        40,chapter:        14,versenumber:        15,verse:"天将晚的时候，门徒进前来，说：“这是野地，时候已经过了，请叫众人散开，他们好往村子里去，自己买吃的。”"},
{volumn:        40,chapter:        14,versenumber:        16,verse:"耶稣说：“不用他们去，你们给他们吃吧！”"},
{volumn:        40,chapter:        14,versenumber:        17,verse:"门徒说：“我们这里只有五个饼，两条鱼。”"},
{volumn:        40,chapter:        14,versenumber:        18,verse:"耶稣说：“拿过来给我。”"},
{volumn:        40,chapter:        14,versenumber:        19,verse:"于是吩咐众人坐在草地上，就拿着这五个饼，两条鱼，望着天祝福，擘开饼，递给门徒，门徒又递给众人。"},
{volumn:        40,chapter:        14,versenumber:        20,verse:"他们都吃，并且吃饱了，把剩下的零碎收拾起来，装满了十二个篮子。"},
{volumn:        40,chapter:        14,versenumber:        21,verse:"吃的人，除了妇女孩子，约有五千。"},
{volumn:        40,chapter:        14,versenumber:        22,verse:"耶稣随即催门徒上船，先渡到那边去，等他叫众人散开。"},
{volumn:        40,chapter:        14,versenumber:        23,verse:"散了众人以后，他就独自上山去祷告。到了晚上，只有他一人在那里。"},
{volumn:        40,chapter:        14,versenumber:        24,verse:"那时船在海中，因风不顺，被浪摇撼。"},
{volumn:        40,chapter:        14,versenumber:        25,verse:"夜里四更天，耶稣在海面上走，往门徒那里去。"},
{volumn:        40,chapter:        14,versenumber:        26,verse:"门徒看见他在海面上走，就惊慌了，说：“是个鬼怪！”便害怕，喊叫起来。"},
{volumn:        40,chapter:        14,versenumber:        27,verse:"耶稣连忙对他们说：“你们放心，是我，不要怕！”"},
{volumn:        40,chapter:        14,versenumber:        28,verse:"彼得说：“主，如果是你，请叫我从水面上走到你那里去。”"},
{volumn:        40,chapter:        14,versenumber:        29,verse:"耶稣说：“你来吧。”彼得就从船上下去，在水面上走，要到耶稣那里去；"},
{volumn:        40,chapter:        14,versenumber:        30,verse:"只因见风甚大，就害怕，将要沉下去，便喊着说：“主啊，救我！”"},
{volumn:        40,chapter:        14,versenumber:        31,verse:"耶稣赶紧伸手拉住他，说：“你这小信的人哪，为什么疑惑呢？”"},
{volumn:        40,chapter:        14,versenumber:        32,verse:"他们上了船，风就住了。"},
{volumn:        40,chapter:        14,versenumber:        33,verse:"在船上的人都拜他，说：“你真是　神的儿子了。”"},
{volumn:        40,chapter:        14,versenumber:        34,verse:"他们过了海，来到革尼撒勒地方。"},
{volumn:        40,chapter:        14,versenumber:        35,verse:"那里的人一认出是耶稣，就打发人到周围地方去，把所有的病人带到他那里，"},
{volumn:        40,chapter:        14,versenumber:        36,verse:"只求耶稣准他们摸他的衣裳繸子；摸着的人就都好了。"},
{volumn:        40,chapter:        15,versenumber:         1,verse:"那时，有法利赛人和文士从耶路撒冷来见耶稣，说："},
{volumn:        40,chapter:        15,versenumber:         2,verse:"“你的门徒为什么犯古人的遗传呢？因为吃饭的时候，他们不洗手。”"},
{volumn:        40,chapter:        15,versenumber:         3,verse:"耶稣回答说：“你们为什么因着你们的遗传犯　神的诫命呢？"},
{volumn:        40,chapter:        15,versenumber:         4,verse:"神说：‘当孝敬父母’；又说：‘咒骂父母的，必治死他。’"},
{volumn:        40,chapter:        15,versenumber:         5,verse:"你们倒说：‘无论何人对父母说：我所当奉给你的已经作了供献，"},
{volumn:        40,chapter:        15,versenumber:         6,verse:"他就可以不孝敬父母。’这就是你们藉着遗传，废了　神的诫命。"},
{volumn:        40,chapter:        15,versenumber:         7,verse:"假冒为善的人哪，以赛亚指着你们说的预言是不错的。他说："},
{volumn:        40,chapter:        15,versenumber:         8,verse:"‘这百姓用嘴唇尊敬我，心却远离我；"},
{volumn:        40,chapter:        15,versenumber:         9,verse:"他们将人的吩咐当作道理教导人，所以拜我也是枉然。’”"},
{volumn:        40,chapter:        15,versenumber:        10,verse:"耶稣就叫了众人来，对他们说：“你们要听，也要明白。"},
{volumn:        40,chapter:        15,versenumber:        11,verse:"入口的不能污秽人，出口的乃能污秽人。”"},
{volumn:        40,chapter:        15,versenumber:        12,verse:"当时，门徒进前来对他说：“法利赛人听见这话，不服（原文是跌倒），你知道吗？”"},
{volumn:        40,chapter:        15,versenumber:        13,verse:"耶稣回答说：“凡栽种的物，若不是我天父栽种的，必要拔出来。"},
{volumn:        40,chapter:        15,versenumber:        14,verse:"任凭他们吧！他们是瞎眼领路的；若是瞎子领瞎子，两个人都要掉在坑里。”"},
{volumn:        40,chapter:        15,versenumber:        15,verse:"彼得对耶稣说：“请将这比喻讲给我们听。”"},
{volumn:        40,chapter:        15,versenumber:        16,verse:"耶稣说：“你们到如今还不明白吗？"},
{volumn:        40,chapter:        15,versenumber:        17,verse:"岂不知凡入口的，是运到肚子里，又落在茅厕里吗？"},
{volumn:        40,chapter:        15,versenumber:        18,verse:"惟独出口的，是从心里发出来的，这才污秽人。"},
{volumn:        40,chapter:        15,versenumber:        19,verse:"因为从心里发出来的，有恶念、凶杀、奸淫、苟合、偷盗、妄证、谤讟。"},
{volumn:        40,chapter:        15,versenumber:        20,verse:"这都是污秽人的；至于不洗手吃饭，那却不污秽人。”"},
{volumn:        40,chapter:        15,versenumber:        21,verse:"耶稣离开那里，退到推罗、西顿的境内去。"},
{volumn:        40,chapter:        15,versenumber:        22,verse:"有一个迦南妇人，从那地方出来，喊着说：“主啊，大卫的子孙，可怜我！我女儿被鬼附得甚苦。”"},
{volumn:        40,chapter:        15,versenumber:        23,verse:"耶稣却一言不答。门徒进前来，求他说：“这妇人在我们后头喊叫，请打发她走吧。”"},
{volumn:        40,chapter:        15,versenumber:        24,verse:"耶稣说：“我奉差遣不过是到以色列家迷失的羊那里去。”"},
{volumn:        40,chapter:        15,versenumber:        25,verse:"那妇人来拜他，说：“主啊，帮助我！”"},
{volumn:        40,chapter:        15,versenumber:        26,verse:"他回答说：“不好拿儿女的饼丢给狗吃。”"},
{volumn:        40,chapter:        15,versenumber:        27,verse:"妇人说：“主啊，不错；但是狗也吃它主人桌子上掉下来的碎渣儿。”"},
{volumn:        40,chapter:        15,versenumber:        28,verse:"耶稣说：“妇人，你的信心是大的！照你所要的，给你成全了吧。”从那时候，她女儿就好了。"},
{volumn:        40,chapter:        15,versenumber:        29,verse:"耶稣离开那地方，来到靠近加利利的海边，就上山坐下。"},
{volumn:        40,chapter:        15,versenumber:        30,verse:"有许多人到他那里，带着瘸子、瞎子、哑巴、有残疾的，和好些别的病人，都放在他脚前；他就治好了他们。"},
{volumn:        40,chapter:        15,versenumber:        31,verse:"甚至众人都希奇；因为看见哑巴说话，残疾的痊愈，瘸子行走，瞎子看见，他们就归荣耀给以色列的　神。"},
{volumn:        40,chapter:        15,versenumber:        32,verse:"耶稣叫门徒来，说：“我怜悯这众人；因为他们同我在这里已经三天，也没有吃的了。我不愿意叫他们饿着回去，恐怕在路上困乏。”"},
{volumn:        40,chapter:        15,versenumber:        33,verse:"门徒说：“我们在这野地，哪里有这么多的饼叫这许多人吃饱呢？”"},
{volumn:        40,chapter:        15,versenumber:        34,verse:"耶稣说：“你们有多少饼？”他们说：“有七个，还有几条小鱼。”"},
{volumn:        40,chapter:        15,versenumber:        35,verse:"他就吩咐众人坐在地上，"},
{volumn:        40,chapter:        15,versenumber:        36,verse:"拿着这七个饼和几条鱼，祝谢了，擘开，递给门徒；门徒又递给众人。"},
{volumn:        40,chapter:        15,versenumber:        37,verse:"众人都吃，并且吃饱了，收拾剩下的零碎，装满了七个筐子。"},
{volumn:        40,chapter:        15,versenumber:        38,verse:"吃的人，除了妇女孩子，共有四千。"},
{volumn:        40,chapter:        15,versenumber:        39,verse:"耶稣叫众人散去，就上船，来到马加丹的境界。"},
{volumn:        40,chapter:        16,versenumber:         1,verse:"法利赛人和撒都该人来试探耶稣，请他从天上显个神迹给他们看。"},
{volumn:        40,chapter:        16,versenumber:         2,verse:"耶稣回答说：“晚上天发红，你们就说：‘天必要晴。’"},
{volumn:        40,chapter:        16,versenumber:         3,verse:"早晨天发红，又发黑，你们就说：‘今日必有风雨。’你们知道分辨天上的气色，倒不能分辨这时候的神迹。"},
{volumn:        40,chapter:        16,versenumber:         4,verse:"一个邪恶淫乱的世代求神迹，除了约拿的神迹以外，再没有神迹给他看。”耶稣就离开他们去了。"},
{volumn:        40,chapter:        16,versenumber:         5,verse:"门徒渡到那边去，忘了带饼。"},
{volumn:        40,chapter:        16,versenumber:         6,verse:"耶稣对他们说：“你们要谨慎，防备法利赛人和撒都该人的酵。”"},
{volumn:        40,chapter:        16,versenumber:         7,verse:"门徒彼此议论说：“这是因为我们没有带饼吧。”"},
{volumn:        40,chapter:        16,versenumber:         8,verse:"耶稣看出来，就说：“你们这小信的人，为什么因为没有饼彼此议论呢？"},
{volumn:        40,chapter:        16,versenumber:         9,verse:"你们还不明白吗？不记得那五个饼分给五千人、又收拾了多少篮子的零碎吗？"},
{volumn:        40,chapter:        16,versenumber:        10,verse:"也不记得那七个饼分给四千人、又收拾了多少筐子的零碎吗？"},
{volumn:        40,chapter:        16,versenumber:        11,verse:"我对你们说：‘要防备法利赛人和撒都该人的酵’，这话不是指着饼说的，你们怎么不明白呢？”"},
{volumn:        40,chapter:        16,versenumber:        12,verse:"门徒这才晓得他说的不是叫他们防备饼的酵，乃是防备法利赛人和撒都该人的教训。"},
{volumn:        40,chapter:        16,versenumber:        13,verse:"耶稣到了凯撒利亚·腓立比的境内，就问门徒说：“人说我（有古卷没有我字）人子是谁？”"},
{volumn:        40,chapter:        16,versenumber:        14,verse:"他们说：“有人说是施洗的约翰；有人说是以利亚；又有人说是耶利米或是先知里的一位。”"},
{volumn:        40,chapter:        16,versenumber:        15,verse:"耶稣说：“你们说我是谁？”"},
{volumn:        40,chapter:        16,versenumber:        16,verse:"西门·彼得回答说：“你是基督，是永生　神的儿子。”"},
{volumn:        40,chapter:        16,versenumber:        17,verse:"耶稣对他说：“西门·巴·约拿，你是有福的！因为这不是属血肉的指示你的，乃是我在天上的父指示的。"},
{volumn:        40,chapter:        16,versenumber:        18,verse:"我还告诉你，你是彼得，我要把我的教会建造在这磐石上；阴间的权柄（权柄：原文是门）不能胜过他。"},
{volumn:        40,chapter:        16,versenumber:        19,verse:"我要把天国的钥匙给你，凡你在地上所捆绑的，在天上也要捆绑；凡你在地上所释放的，在天上也要释放。”"},
{volumn:        40,chapter:        16,versenumber:        20,verse:"当下，耶稣嘱咐门徒，不可对人说他是基督。"},
{volumn:        40,chapter:        16,versenumber:        21,verse:"从此，耶稣才指示门徒，他必须上耶路撒冷去，受长老、祭司长、文士许多的苦，并且被杀，第三日复活。"},
{volumn:        40,chapter:        16,versenumber:        22,verse:"彼得就拉着他，劝他说：“主啊，万不可如此！这事必不临到你身上。”"},
{volumn:        40,chapter:        16,versenumber:        23,verse:"耶稣转过来，对彼得说：“撒但，退我后边去吧！你是绊我脚的；因为你不体贴　神的意思，只体贴人的意思。”"},
{volumn:        40,chapter:        16,versenumber:        24,verse:"于是耶稣对门徒说：“若有人要跟从我，就当舍己，背起他的十字架来跟从我。"},
{volumn:        40,chapter:        16,versenumber:        25,verse:"因为，凡要救自己生命（生命：或译灵魂；下同）的，必丧掉生命；凡为我丧掉生命的，必得着生命。"},
{volumn:        40,chapter:        16,versenumber:        26,verse:"人若赚得全世界，赔上自己的生命，有什么益处呢？人还能拿什么换生命呢？"},
{volumn:        40,chapter:        16,versenumber:        27,verse:"人子要在他父的荣耀里，同着众使者降临；那时候，他要照各人的行为报应各人。"},
{volumn:        40,chapter:        16,versenumber:        28,verse:"我实在告诉你们，站在这里的，有人在没尝死味以前必看见人子降临在他的国里。”"},
{volumn:        40,chapter:        17,versenumber:         1,verse:"过了六天，耶稣带着彼得、雅各，和雅各的兄弟约翰，暗暗地上了高山，"},
{volumn:        40,chapter:        17,versenumber:         2,verse:"就在他们面前变了形像，脸面明亮如日头，衣裳洁白如光。"},
{volumn:        40,chapter:        17,versenumber:         3,verse:"忽然，有摩西、以利亚向他们显现，同耶稣说话。"},
{volumn:        40,chapter:        17,versenumber:         4,verse:"彼得对耶稣说：“主啊，我们在这里真好！你若愿意，我就在这里搭三座棚，一座为你，一座为摩西，一座为以利亚。”"},
{volumn:        40,chapter:        17,versenumber:         5,verse:"说话之间，忽然有一朵光明的云彩遮盖他们，且有声音从云彩里出来，说：“这是我的爱子，我所喜悦的。你们要听他！”"},
{volumn:        40,chapter:        17,versenumber:         6,verse:"门徒听见，就俯伏在地，极其害怕。"},
{volumn:        40,chapter:        17,versenumber:         7,verse:"耶稣进前来，摸他们，说：“起来，不要害怕！”"},
{volumn:        40,chapter:        17,versenumber:         8,verse:"他们举目不见一人，只见耶稣在那里。"},
{volumn:        40,chapter:        17,versenumber:         9,verse:"下山的时候，耶稣吩咐他们说：“人子还没有从死里复活，你们不要将所看见的告诉人。”"},
{volumn:        40,chapter:        17,versenumber:        10,verse:"门徒问耶稣说：“文士为什么说以利亚必须先来？”"},
{volumn:        40,chapter:        17,versenumber:        11,verse:"耶稣回答说：“以利亚固然先来，并要复兴万事；"},
{volumn:        40,chapter:        17,versenumber:        12,verse:"只是我告诉你们，以利亚已经来了，人却不认识他，竟任意待他。人子也将要这样受他们的害。”"},
{volumn:        40,chapter:        17,versenumber:        13,verse:"门徒这才明白耶稣所说的是指着施洗的约翰。"},
{volumn:        40,chapter:        17,versenumber:        14,verse:"耶稣和门徒到了众人那里，有一个人来见耶稣，跪下，"},
{volumn:        40,chapter:        17,versenumber:        15,verse:"说：“主啊，怜悯我的儿子！他害癫痫的病很苦，屡次跌在火里，屡次跌在水里。"},
{volumn:        40,chapter:        17,versenumber:        16,verse:"我带他到你门徒那里，他们却不能医治他。”"},
{volumn:        40,chapter:        17,versenumber:        17,verse:"耶稣说：“嗳！这又不信又悖谬的世代啊，我在你们这里要到几时呢？我忍耐你们要到几时呢？把他带到我这里来吧！”"},
{volumn:        40,chapter:        17,versenumber:        18,verse:"耶稣斥责那鬼，鬼就出来；从此孩子就痊愈了。"},
{volumn:        40,chapter:        17,versenumber:        19,verse:"门徒暗暗地到耶稣跟前，说：“我们为什么不能赶出那鬼呢？”"},
{volumn:        40,chapter:        17,versenumber:        20,verse:"耶稣说：“是因你们的信心小。我实在告诉你们，你们若有信心，像一粒芥菜种，就是对这座山说：‘你从这边挪到那边’，它也必挪去；并且你们没有一件不能做的事了。"},
{volumn:        40,chapter:        17,versenumber:        21,verse:"至于这一类的鬼，若不祷告、禁食，他就不出来（或译：不能赶他出来）。”"},
{volumn:        40,chapter:        17,versenumber:        22,verse:"他们还住在加利利的时候，耶稣对门徒说：“人子将要被交在人手里。"},
{volumn:        40,chapter:        17,versenumber:        23,verse:"他们要杀害他，第三日他要复活。”门徒就大大地忧愁。"},
{volumn:        40,chapter:        17,versenumber:        24,verse:"到了迦百农，有收丁税的人来见彼得，说：“你们的先生不纳丁税（丁税约有半块钱）吗？”"},
{volumn:        40,chapter:        17,versenumber:        25,verse:"彼得说：“纳。”他进了屋子，耶稣先向他说：“西门，你的意思如何？世上的君王向谁征收关税、丁税？是向自己的儿子呢？是向外人呢？”"},
{volumn:        40,chapter:        17,versenumber:        26,verse:"彼得说：“是向外人。”耶稣说：“既然如此，儿子就可以免税了。"},
{volumn:        40,chapter:        17,versenumber:        27,verse:"但恐怕触犯（触犯：原文是绊倒）他们，你且往海边去钓鱼，把先钓上来的鱼拿起来，开了它的口，必得一块钱，可以拿去给他们，作你我的税银。”"},
{volumn:        40,chapter:        18,versenumber:         1,verse:"当时，门徒进前来，问耶稣说：“天国里谁是最大的？”"},
{volumn:        40,chapter:        18,versenumber:         2,verse:"耶稣便叫一个小孩子来，使他站在他们当中，"},
{volumn:        40,chapter:        18,versenumber:         3,verse:"说：“我实在告诉你们，你们若不回转，变成小孩子的样式，断不得进天国。"},
{volumn:        40,chapter:        18,versenumber:         4,verse:"所以，凡自己谦卑像这小孩子的，他在天国里就是最大的。"},
{volumn:        40,chapter:        18,versenumber:         5,verse:"凡为我的名接待一个像这小孩子的，就是接待我。”"},
{volumn:        40,chapter:        18,versenumber:         6,verse:"“凡使这信我的一个小子跌倒的，倒不如把大磨石拴在这人的颈项上，沉在深海里。"},
{volumn:        40,chapter:        18,versenumber:         7,verse:"这世界有祸了，因为将人绊倒；绊倒人的事是免不了的，但那绊倒人的有祸了！"},
{volumn:        40,chapter:        18,versenumber:         8,verse:"倘若你一只手，或是一只脚，叫你跌倒，就砍下来丢掉。你缺一只手，或是一只脚，进入永生，强如有两手两脚被丢在永火里。"},
{volumn:        40,chapter:        18,versenumber:         9,verse:"倘若你一只眼叫你跌倒，就把它剜出来丢掉。你只有一只眼进入永生，强如有两只眼被丢在地狱的火里。”"},
{volumn:        40,chapter:        18,versenumber:        10,verse:"“你们要小心，不可轻看这小子里的一个；我告诉你们，他们的使者在天上，常见我天父的面。（有古卷加：11人子来，为要拯救失丧的人。）"},
{volumn:        40,chapter:        18,versenumber:        11,verse:""},
{volumn:        40,chapter:        18,versenumber:        12,verse:"一个人若有一百只羊，一只走迷了路，你们的意思如何？他岂不撇下这九十九只，往山里去找那只迷路的羊吗？"},
{volumn:        40,chapter:        18,versenumber:        13,verse:"若是找着了，我实在告诉你们，他为这一只羊欢喜，比为那没有迷路的九十九只欢喜还大呢！"},
{volumn:        40,chapter:        18,versenumber:        14,verse:"你们在天上的父也是这样，不愿意这小子里失丧一个。”"},
{volumn:        40,chapter:        18,versenumber:        15,verse:"“倘若你的弟兄得罪你，你就去，趁着只有他和你在一处的时候，指出他的错来。他若听你，你便得了你的弟兄；"},
{volumn:        40,chapter:        18,versenumber:        16,verse:"他若不听，你就另外带一两个人同去，要凭两三个人的口作见证，句句都可定准。"},
{volumn:        40,chapter:        18,versenumber:        17,verse:"若是不听他们，就告诉教会；若是不听教会，就看他像外邦人和税吏一样。"},
{volumn:        40,chapter:        18,versenumber:        18,verse:"“我实在告诉你们，凡你们在地上所捆绑的，在天上也要捆绑；凡你们在地上所释放的，在天上也要释放。"},
{volumn:        40,chapter:        18,versenumber:        19,verse:"我又告诉你们，若是你们中间有两个人在地上同心合意地求什么事，我在天上的父必为他们成全。"},
{volumn:        40,chapter:        18,versenumber:        20,verse:"因为无论在哪里，有两三个人奉我的名聚会，那里就有我在他们中间。”"},
{volumn:        40,chapter:        18,versenumber:        21,verse:"那时，彼得进前来，对耶稣说：“主啊，我弟兄得罪我，我当饶恕他几次呢？到七次可以吗？”"},
{volumn:        40,chapter:        18,versenumber:        22,verse:"耶稣说：“我对你说，不是到七次，乃是到七十个七次。"},
{volumn:        40,chapter:        18,versenumber:        23,verse:"天国好像一个王要和他仆人算帐。"},
{volumn:        40,chapter:        18,versenumber:        24,verse:"才算的时候，有人带了一个欠一千万银子的来。"},
{volumn:        40,chapter:        18,versenumber:        25,verse:"因为他没有什么偿还之物，主人吩咐把他和他妻子儿女，并一切所有的都卖了偿还。"},
{volumn:        40,chapter:        18,versenumber:        26,verse:"那仆人就俯伏拜他，说：‘主啊，宽容我，将来我都要还清。’"},
{volumn:        40,chapter:        18,versenumber:        27,verse:"那仆人的主人就动了慈心，把他释放了，并且免了他的债。"},
{volumn:        40,chapter:        18,versenumber:        28,verse:"“那仆人出来，遇见他的一个同伴欠他十两银子，便揪着他，掐住他的喉咙，说：‘你把所欠的还我！’"},
{volumn:        40,chapter:        18,versenumber:        29,verse:"他的同伴就俯伏央求他，说：‘宽容我吧，将来我必还清。’"},
{volumn:        40,chapter:        18,versenumber:        30,verse:"他不肯，竟去把他下在监里，等他还了所欠的债。"},
{volumn:        40,chapter:        18,versenumber:        31,verse:"众同伴看见他所做的事就甚忧愁，去把这事都告诉了主人。"},
{volumn:        40,chapter:        18,versenumber:        32,verse:"于是主人叫了他来，对他说：‘你这恶奴才！你央求我，我就把你所欠的都免了，"},
{volumn:        40,chapter:        18,versenumber:        33,verse:"你不应当怜恤你的同伴，像我怜恤你吗？’"},
{volumn:        40,chapter:        18,versenumber:        34,verse:"主人就大怒，把他交给掌刑的，等他还清了所欠的债。"},
{volumn:        40,chapter:        18,versenumber:        35,verse:"你们各人若不从心里饶恕你的弟兄，我天父也要这样待你们了。”"},
{volumn:        40,chapter:        19,versenumber:         1,verse:"耶稣说完了这些话，就离开加利利，来到犹太的境界约旦河外。"},
{volumn:        40,chapter:        19,versenumber:         2,verse:"有许多人跟着他，他就在那里把他们的病人治好了。"},
{volumn:        40,chapter:        19,versenumber:         3,verse:"有法利赛人来试探耶稣，说：“人无论什么缘故都可以休妻吗？”"},
{volumn:        40,chapter:        19,versenumber:         4,verse:"耶稣回答说：“那起初造人的，是造男造女，"},
{volumn:        40,chapter:        19,versenumber:         5,verse:"并且说：‘因此，人要离开父母，与妻子连合，二人成为一体。’这经你们没有念过吗？"},
{volumn:        40,chapter:        19,versenumber:         6,verse:"既然如此，夫妻不再是两个人，乃是一体的了。所以，　神配合的，人不可分开。”"},
{volumn:        40,chapter:        19,versenumber:         7,verse:"法利赛人说：“这样，摩西为什么吩咐给妻子休书，就可以休她呢？”"},
{volumn:        40,chapter:        19,versenumber:         8,verse:"耶稣说：“摩西因为你们的心硬，所以许你们休妻，但起初并不是这样。"},
{volumn:        40,chapter:        19,versenumber:         9,verse:"我告诉你们，凡休妻另娶的，若不是为淫乱的缘故，就是犯奸淫了；有人娶那被休的妇人，也是犯奸淫了。”"},
{volumn:        40,chapter:        19,versenumber:        10,verse:"门徒对耶稣说：“人和妻子既是这样，倒不如不娶。”"},
{volumn:        40,chapter:        19,versenumber:        11,verse:"耶稣说：“这话不是人都能领受的，惟独赐给谁，谁才能领受。"},
{volumn:        40,chapter:        19,versenumber:        12,verse:"因为有生来是阉人，也有被人阉的，并有为天国的缘故自阉的。这话谁能领受就可以领受。”"},
{volumn:        40,chapter:        19,versenumber:        13,verse:"那时，有人带着小孩子来见耶稣，要耶稣给他们按手祷告，门徒就责备那些人。"},
{volumn:        40,chapter:        19,versenumber:        14,verse:"耶稣说：“让小孩子到我这里来，不要禁止他们；因为在天国的，正是这样的人。”"},
{volumn:        40,chapter:        19,versenumber:        15,verse:"耶稣给他们按手，就离开那地方去了。"},
{volumn:        40,chapter:        19,versenumber:        16,verse:"有一个人来见耶稣，说：“夫子（有古卷：良善的夫子），我该做什么善事才能得永生？”"},
{volumn:        40,chapter:        19,versenumber:        17,verse:"耶稣对他说：“你为什么以善事问我呢？只有一位是善的（有古卷：你为什么称我是良善的？除了　神以外，没有一个良善的）。你若要进入永生，就当遵守诫命。”"},
{volumn:        40,chapter:        19,versenumber:        18,verse:"他说：“什么诫命？”耶稣说：“就是不可杀人；不可奸淫；不可偷盗；不可作假见证；"},
{volumn:        40,chapter:        19,versenumber:        19,verse:"当孝敬父母；又当爱人如己。”"},
{volumn:        40,chapter:        19,versenumber:        20,verse:"那少年人说：“这一切我都遵守了，还缺少什么呢？”"},
{volumn:        40,chapter:        19,versenumber:        21,verse:"耶稣说：“你若愿意作完全人，可去变卖你所有的，分给穷人，就必有财宝在天上；你还要来跟从我。”"},
{volumn:        40,chapter:        19,versenumber:        22,verse:"那少年人听见这话，就忧忧愁愁地走了，因为他的产业很多。"},
{volumn:        40,chapter:        19,versenumber:        23,verse:"耶稣对门徒说：“我实在告诉你们，财主进天国是难的。"},
{volumn:        40,chapter:        19,versenumber:        24,verse:"我又告诉你们，骆驼穿过针的眼，比财主进　神的国还容易呢！”"},
{volumn:        40,chapter:        19,versenumber:        25,verse:"门徒听见这话，就希奇得很，说：“这样谁能得救呢？”"},
{volumn:        40,chapter:        19,versenumber:        26,verse:"耶稣看着他们，说：“在人这是不能的，在　神凡事都能。”"},
{volumn:        40,chapter:        19,versenumber:        27,verse:"彼得就对他说：“看哪，我们已经撇下所有的跟从你，将来我们要得什么呢？”"},
{volumn:        40,chapter:        19,versenumber:        28,verse:"耶稣说：“我实在告诉你们，你们这跟从我的人，到复兴的时候，人子坐在他荣耀的宝座上，你们也要坐在十二个宝座上，审判以色列十二个支派。"},
{volumn:        40,chapter:        19,versenumber:        29,verse:"凡为我的名撇下房屋，或是弟兄、姐妹、父亲、母亲、（有古卷加：妻子、）儿女、田地的，必要得着百倍，并且承受永生。"},
{volumn:        40,chapter:        19,versenumber:        30,verse:"然而，有许多在前的，将要在后；在后的，将要在前。”"},
{volumn:        40,chapter:        20,versenumber:         1,verse:"“因为天国好像家主清早去雇人进他的葡萄园做工，"},
{volumn:        40,chapter:        20,versenumber:         2,verse:"和工人讲定一天一钱银子，就打发他们进葡萄园去。"},
{volumn:        40,chapter:        20,versenumber:         3,verse:"约在巳初出去，看见市上还有闲站的人，"},
{volumn:        40,chapter:        20,versenumber:         4,verse:"就对他们说：‘你们也进葡萄园去，所当给的，我必给你们。’他们也进去了。"},
{volumn:        40,chapter:        20,versenumber:         5,verse:"约在午正和申初又出去，也是这样行。"},
{volumn:        40,chapter:        20,versenumber:         6,verse:"约在酉初出去，看见还有人站在那里，就问他们说：‘你们为什么整天在这里闲站呢？’"},
{volumn:        40,chapter:        20,versenumber:         7,verse:"他们说：‘因为没有人雇我们。’他说：‘你们也进葡萄园去。’"},
{volumn:        40,chapter:        20,versenumber:         8,verse:"到了晚上，园主对管事的说：‘叫工人都来，给他们工钱，从后来的起，到先来的为止。"},
{volumn:        40,chapter:        20,versenumber:         9,verse:"约在酉初雇的人来了，各人得了一钱银子。"},
{volumn:        40,chapter:        20,versenumber:        10,verse:"及至那先雇的来了，他们以为必要多得；谁知也是各得一钱。"},
{volumn:        40,chapter:        20,versenumber:        11,verse:"他们得了，就埋怨家主说："},
{volumn:        40,chapter:        20,versenumber:        12,verse:"‘我们整天劳苦受热，那后来的只做了一小时，你竟叫他们和我们一样吗？’"},
{volumn:        40,chapter:        20,versenumber:        13,verse:"家主回答其中的一人说：‘朋友，我不亏负你，你与我讲定的不是一钱银子吗？"},
{volumn:        40,chapter:        20,versenumber:        14,verse:"拿你的走吧！我给那后来的和给你一样，这是我愿意的。"},
{volumn:        40,chapter:        20,versenumber:        15,verse:"我的东西难道不可随我的意思用吗？因为我作好人，你就红了眼吗？’"},
{volumn:        40,chapter:        20,versenumber:        16,verse:"这样，那在后的，将要在前；在前的，将要在后了。（有古卷加：因为被召的人多，选上的人少。）”"},
{volumn:        40,chapter:        20,versenumber:        17,verse:"耶稣上耶路撒冷去的时候，在路上把十二个门徒带到一边，对他们说："},
{volumn:        40,chapter:        20,versenumber:        18,verse:"“看哪，我们上耶路撒冷去，人子要被交给祭司长和文士。他们要定他死罪，"},
{volumn:        40,chapter:        20,versenumber:        19,verse:"又交给外邦人，将他戏弄，鞭打，钉在十字架上；第三日他要复活。”"},
{volumn:        40,chapter:        20,versenumber:        20,verse:"那时，西庇太儿子的母亲同她两个儿子上前来拜耶稣，求他一件事。"},
{volumn:        40,chapter:        20,versenumber:        21,verse:"耶稣说：“你要什么呢？”她说：“愿你叫我这两个儿子在你国里，一个坐在你右边，一个坐在你左边。”"},
{volumn:        40,chapter:        20,versenumber:        22,verse:"耶稣回答说：“你们不知道所求的是什么；我将要喝的杯，你们能喝吗？”他们说：“我们能。”"},
{volumn:        40,chapter:        20,versenumber:        23,verse:"耶稣说：“我所喝的杯，你们必要喝；只是坐在我的左右，不是我可以赐的，乃是我父为谁预备的，就赐给谁。”"},
{volumn:        40,chapter:        20,versenumber:        24,verse:"那十个门徒听见，就恼怒他们弟兄二人。"},
{volumn:        40,chapter:        20,versenumber:        25,verse:"耶稣叫了他们来，说：“你们知道外邦人有君王为主治理他们，有大臣操权管束他们。"},
{volumn:        40,chapter:        20,versenumber:        26,verse:"只是在你们中间，不可这样；你们中间谁愿为大，就必作你们的用人；"},
{volumn:        40,chapter:        20,versenumber:        27,verse:"谁愿为首，就必作你们的仆人。"},
{volumn:        40,chapter:        20,versenumber:        28,verse:"正如人子来，不是要受人的服侍，乃是要服侍人，并且要舍命，作多人的赎价。”"},
{volumn:        40,chapter:        20,versenumber:        29,verse:"他们出耶利哥的时候，有极多的人跟随他。"},
{volumn:        40,chapter:        20,versenumber:        30,verse:"有两个瞎子坐在路旁，听说是耶稣经过，就喊着说：“主啊，大卫的子孙，可怜我们吧！”"},
{volumn:        40,chapter:        20,versenumber:        31,verse:"众人责备他们，不许他们作声；他们却越发喊着说：“主啊，大卫的子孙，可怜我们吧！”"},
{volumn:        40,chapter:        20,versenumber:        32,verse:"耶稣就站住，叫他们来，说：“要我为你们做什么？”"},
{volumn:        40,chapter:        20,versenumber:        33,verse:"他们说：“主啊，要我们的眼睛能看见！”"},
{volumn:        40,chapter:        20,versenumber:        34,verse:"耶稣就动了慈心，把他们的眼睛一摸，他们立刻看见，就跟从了耶稣。"},
{volumn:        40,chapter:        21,versenumber:         1,verse:"耶稣和门徒将近耶路撒冷，到了伯法其，在橄榄山那里。"},
{volumn:        40,chapter:        21,versenumber:         2,verse:"耶稣就打发两个门徒，对他们说：“你们往对面村子里去，必看见一匹驴拴在那里，还有驴驹同在一处；你们解开，牵到我这里来。"},
{volumn:        40,chapter:        21,versenumber:         3,verse:"若有人对你们说什么，你们就说：‘主要用它。’那人必立时让你们牵来。”"},
{volumn:        40,chapter:        21,versenumber:         4,verse:"这事成就是要应验先知的话，说："},
{volumn:        40,chapter:        21,versenumber:         5,verse:"“要对锡安的居民（原文是女子）说：‘看哪，你的王来到你这里，是温柔的，又骑着驴，就是骑着驴驹子。’”"},
{volumn:        40,chapter:        21,versenumber:         6,verse:"门徒就照耶稣所吩咐的去行，"},
{volumn:        40,chapter:        21,versenumber:         7,verse:"牵了驴和驴驹来，把自己的衣服搭在上面，耶稣就骑上。"},
{volumn:        40,chapter:        21,versenumber:         8,verse:"众人多半把衣服铺在路上；还有人砍下树枝来铺在路上。"},
{volumn:        40,chapter:        21,versenumber:         9,verse:"前行后随的众人喊着说：“和散那（原有求救的意思，在此是称颂的话）归于大卫的子孙！奉主名来的是应当称颂的！高高在上和散那！”"},
{volumn:        40,chapter:        21,versenumber:        10,verse:"耶稣既进了耶路撒冷，合城都惊动了，说：“这是谁？”"},
{volumn:        40,chapter:        21,versenumber:        11,verse:"众人说：“这是加利利拿撒勒的先知耶稣。”"},
{volumn:        40,chapter:        21,versenumber:        12,verse:"耶稣进了　神的殿，赶出殿里一切做买卖的人，推倒兑换银钱之人的桌子，和卖鸽子之人的凳子，"},
{volumn:        40,chapter:        21,versenumber:        13,verse:"对他们说：“经上记着说：‘我的殿必称为祷告的殿，你们倒使它成为贼窝了。’”"},
{volumn:        40,chapter:        21,versenumber:        14,verse:"在殿里有瞎子、瘸子到耶稣跟前，他就治好了他们。"},
{volumn:        40,chapter:        21,versenumber:        15,verse:"祭司长和文士看见耶稣所行的奇事，又见小孩子在殿里喊着说：“和散那归于大卫的子孙！”就甚恼怒，"},
{volumn:        40,chapter:        21,versenumber:        16,verse:"对他说：“这些人所说的，你听见了吗？”耶稣说：“是的。经上说‘你从婴孩和吃奶的口中完全了赞美’的话，你们没有念过吗？”"},
{volumn:        40,chapter:        21,versenumber:        17,verse:"于是离开他们，出城到伯大尼去，在那里住宿。"},
{volumn:        40,chapter:        21,versenumber:        18,verse:"早晨回城的时候，他饿了，"},
{volumn:        40,chapter:        21,versenumber:        19,verse:"看见路旁有一棵无花果树，就走到跟前，在树上找不着什么，不过有叶子，就对树说：“从今以后，你永不结果子。”那无花果树就立刻枯干了。"},
{volumn:        40,chapter:        21,versenumber:        20,verse:"门徒看见了，便希奇说：“无花果树怎么立刻枯干了呢？”"},
{volumn:        40,chapter:        21,versenumber:        21,verse:"耶稣回答说：“我实在告诉你们，你们若有信心，不疑惑，不但能行无花果树上所行的事，就是对这座山说：‘你挪开此地，投在海里！’也必成就。"},
{volumn:        40,chapter:        21,versenumber:        22,verse:"你们祷告，无论求什么，只要信，就必得着。”"},
{volumn:        40,chapter:        21,versenumber:        23,verse:"耶稣进了殿，正教训人的时候，祭司长和民间的长老来问他说：“你仗着什么权柄做这些事？给你这权柄的是谁呢？”"},
{volumn:        40,chapter:        21,versenumber:        24,verse:"耶稣回答说：“我也要问你们一句话，你们若告诉我，我就告诉你们我仗着什么权柄做这些事。"},
{volumn:        40,chapter:        21,versenumber:        25,verse:"约翰的洗礼是从哪里来的？是从天上来的？是从人间来的呢？”他们彼此商议说：“我们若说‘从天上来’，他必对我们说：‘这样，你们为什么不信他呢？’"},
{volumn:        40,chapter:        21,versenumber:        26,verse:"若说‘从人间来’，我们又怕百姓，因为他们都以约翰为先知。”"},
{volumn:        40,chapter:        21,versenumber:        27,verse:"于是回答耶稣说：“我们不知道。”耶稣说：“我也不告诉你们我仗着什么权柄做这些事。”"},
{volumn:        40,chapter:        21,versenumber:        28,verse:"又说：“一个人有两个儿子。他来对大儿子说：‘我儿，你今天到葡萄园里去做工。’"},
{volumn:        40,chapter:        21,versenumber:        29,verse:"他回答说：‘我不去’，以后自己懊悔，就去了。"},
{volumn:        40,chapter:        21,versenumber:        30,verse:"又来对小儿子也是这样说。他回答说：‘父啊，我去’，他却不去。"},
{volumn:        40,chapter:        21,versenumber:        31,verse:"你们想，这两个儿子是哪一个遵行父命呢？”他们说：“大儿子。”耶稣说：“我实在告诉你们，税吏和娼妓倒比你们先进　神的国。"},
{volumn:        40,chapter:        21,versenumber:        32,verse:"因为约翰遵着义路到你们这里来，你们却不信他；税吏和娼妓倒信他。你们看见了，后来还是不懊悔去信他。”"},
{volumn:        40,chapter:        21,versenumber:        33,verse:"“你们再听一个比喻：有个家主栽了一个葡萄园，周围圈上篱笆，里面挖了一个压酒池，盖了一座楼，租给园户，就往外国去了。"},
{volumn:        40,chapter:        21,versenumber:        34,verse:"收果子的时候近了，就打发仆人到园户那里去收果子。"},
{volumn:        40,chapter:        21,versenumber:        35,verse:"园户拿住仆人，打了一个，杀了一个，用石头打死一个。"},
{volumn:        40,chapter:        21,versenumber:        36,verse:"主人又打发别的仆人去，比先前更多；园户还是照样待他们。"},
{volumn:        40,chapter:        21,versenumber:        37,verse:"后来打发他的儿子到他们那里去，意思说：‘他们必尊敬我的儿子。’"},
{volumn:        40,chapter:        21,versenumber:        38,verse:"不料，园户看见他儿子，就彼此说：‘这是承受产业的。来吧，我们杀他，占他的产业！’"},
{volumn:        40,chapter:        21,versenumber:        39,verse:"他们就拿住他，推出葡萄园外，杀了。"},
{volumn:        40,chapter:        21,versenumber:        40,verse:"园主来的时候要怎样处治这些园户呢？”"},
{volumn:        40,chapter:        21,versenumber:        41,verse:"他们说：“要下毒手除灭那些恶人，将葡萄园另租给那按着时候交果子的园户。”"},
{volumn:        40,chapter:        21,versenumber:        42,verse:"耶稣说：“经上写着：‘匠人所弃的石头已作了房角的头块石头。这是主所做的，在我们眼中看为希奇。’"},
{volumn:        40,chapter:        21,versenumber:        43,verse:"所以我告诉你们，　神的国必从你们夺去，赐给那能结果子的百姓。"},
{volumn:        40,chapter:        21,versenumber:        44,verse:"谁掉在这石头上，必要跌碎；这石头掉在谁的身上，就要把谁砸得稀烂。”"},
{volumn:        40,chapter:        21,versenumber:        45,verse:"祭司长和法利赛人听见他的比喻，就看出他是指着他们说的。"},
{volumn:        40,chapter:        21,versenumber:        46,verse:"他们想要捉拿他，只是怕众人，因为众人以他为先知。"},
{volumn:        40,chapter:        22,versenumber:         1,verse:"耶稣又用比喻对他们说："},
{volumn:        40,chapter:        22,versenumber:         2,verse:"“天国好比一个王为他儿子摆设娶亲的筵席，"},
{volumn:        40,chapter:        22,versenumber:         3,verse:"就打发仆人去，请那些被召的人来赴席，他们却不肯来。"},
{volumn:        40,chapter:        22,versenumber:         4,verse:"王又打发别的仆人，说：‘你们告诉那被召的人，我的筵席已经预备好了，牛和肥畜已经宰了，各样都齐备，请你们来赴席。’"},
{volumn:        40,chapter:        22,versenumber:         5,verse:"那些人不理就走了；一个到自己田里去；一个做买卖去；"},
{volumn:        40,chapter:        22,versenumber:         6,verse:"其余的拿住仆人，凌辱他们，把他们杀了。"},
{volumn:        40,chapter:        22,versenumber:         7,verse:"王就大怒，发兵除灭那些凶手，烧毁他们的城。"},
{volumn:        40,chapter:        22,versenumber:         8,verse:"于是对仆人说：‘喜筵已经齐备，只是所召的人不配。"},
{volumn:        40,chapter:        22,versenumber:         9,verse:"所以你们要往岔路口上去，凡遇见的，都召来赴席。’"},
{volumn:        40,chapter:        22,versenumber:        10,verse:"那些仆人就出去，到大路上，凡遇见的，不论善恶都召聚了来，筵席上就坐满了客。"},
{volumn:        40,chapter:        22,versenumber:        11,verse:"王进来观看宾客，见那里有一个没有穿礼服的，"},
{volumn:        40,chapter:        22,versenumber:        12,verse:"就对他说：‘朋友，你到这里来怎么不穿礼服呢？’那人无言可答。"},
{volumn:        40,chapter:        22,versenumber:        13,verse:"于是王对使唤的人说：‘捆起他的手脚来，把他丢在外边的黑暗里；在那里必要哀哭切齿了。’"},
{volumn:        40,chapter:        22,versenumber:        14,verse:"因为被召的人多，选上的人少。”"},
{volumn:        40,chapter:        22,versenumber:        15,verse:"当时，法利赛人出去商议，怎样就着耶稣的话陷害他，"},
{volumn:        40,chapter:        22,versenumber:        16,verse:"就打发他们的门徒同希律党的人去见耶稣，说：“夫子，我们知道你是诚实人，并且诚诚实实传　神的道，什么人你都不徇情面，因为你不看人的外貌。"},
{volumn:        40,chapter:        22,versenumber:        17,verse:"请告诉我们，你的意见如何？纳税给凯撒可以不可以？”"},
{volumn:        40,chapter:        22,versenumber:        18,verse:"耶稣看出他们的恶意，就说：“假冒为善的人哪，为什么试探我？"},
{volumn:        40,chapter:        22,versenumber:        19,verse:"拿一个上税的钱给我看！”他们就拿一个银钱来给他。"},
{volumn:        40,chapter:        22,versenumber:        20,verse:"耶稣说：“这像和这号是谁的？”"},
{volumn:        40,chapter:        22,versenumber:        21,verse:"他们说：“是凯撒的。”耶稣说：“这样，凯撒的物当归给凯撒；　神的物当归给神。”"},
{volumn:        40,chapter:        22,versenumber:        22,verse:"他们听见就希奇，离开他走了。"},
{volumn:        40,chapter:        22,versenumber:        23,verse:"撒都该人常说没有复活的事。那天，他们来问耶稣说："},
{volumn:        40,chapter:        22,versenumber:        24,verse:"“夫子，摩西说：‘人若死了，没有孩子，他兄弟当娶他的妻，为哥哥生子立后。’"},
{volumn:        40,chapter:        22,versenumber:        25,verse:"从前，在我们这里有弟兄七人，第一个娶了妻，死了，没有孩子，撇下妻子给兄弟。"},
{volumn:        40,chapter:        22,versenumber:        26,verse:"第二、第三，直到第七个，都是如此。"},
{volumn:        40,chapter:        22,versenumber:        27,verse:"末后，妇人也死了。"},
{volumn:        40,chapter:        22,versenumber:        28,verse:"这样，当复活的时候，她是七个人中哪一个的妻子呢？因为他们都娶过她。”"},
{volumn:        40,chapter:        22,versenumber:        29,verse:"耶稣回答说：“你们错了；因为不明白圣经，也不晓得　神的大能。"},
{volumn:        40,chapter:        22,versenumber:        30,verse:"当复活的时候，人也不娶也不嫁，乃像天上的使者一样。"},
{volumn:        40,chapter:        22,versenumber:        31,verse:"论到死人复活，　神在经上向你们所说的，你们没有念过吗？"},
{volumn:        40,chapter:        22,versenumber:        32,verse:"他说：‘我是亚伯拉罕的　神，以撒的　神，雅各的神。’神不是死人的　神，乃是活人的神。”"},
{volumn:        40,chapter:        22,versenumber:        33,verse:"众人听见这话，就希奇他的教训。"},
{volumn:        40,chapter:        22,versenumber:        34,verse:"法利赛人听见耶稣堵住了撒都该人的口，他们就聚集。"},
{volumn:        40,chapter:        22,versenumber:        35,verse:"内中有一个人是律法师，要试探耶稣，就问他说："},
{volumn:        40,chapter:        22,versenumber:        36,verse:"“夫子，律法上的诫命，哪一条是最大的呢？”"},
{volumn:        40,chapter:        22,versenumber:        37,verse:"耶稣对他说：“你要尽心、尽性、尽意，爱主你的　神。"},
{volumn:        40,chapter:        22,versenumber:        38,verse:"这是诫命中的第一，且是最大的。"},
{volumn:        40,chapter:        22,versenumber:        39,verse:"其次也相仿，就是要爱人如己。"},
{volumn:        40,chapter:        22,versenumber:        40,verse:"这两条诫命是律法和先知一切道理的总纲。”"},
{volumn:        40,chapter:        22,versenumber:        41,verse:"法利赛人聚集的时候，耶稣问他们说："},
{volumn:        40,chapter:        22,versenumber:        42,verse:"“论到基督，你们的意见如何？他是谁的子孙呢？”他们回答说：“是大卫的子孙。”"},
{volumn:        40,chapter:        22,versenumber:        43,verse:"耶稣说：“这样，大卫被圣灵感动，怎么还称他为主，说："},
{volumn:        40,chapter:        22,versenumber:        44,verse:"‘主对我主说：你坐在我的右边，等我把你仇敌放在你的脚下’？"},
{volumn:        40,chapter:        22,versenumber:        45,verse:"大卫既称他为主，他怎么又是大卫的子孙呢？”"},
{volumn:        40,chapter:        22,versenumber:        46,verse:"他们没有一个人能回答一言。从那日以后，也没有人敢再问他什么。"},
{volumn:        40,chapter:        23,versenumber:         1,verse:"那时，耶稣对众人和门徒讲论，"},
{volumn:        40,chapter:        23,versenumber:         2,verse:"说：“文士和法利赛人坐在摩西的位上，"},
{volumn:        40,chapter:        23,versenumber:         3,verse:"凡他们所吩咐你们的，你们都要谨守遵行。但不要效法他们的行为；因为他们能说，不能行。"},
{volumn:        40,chapter:        23,versenumber:         4,verse:"他们把难担的重担捆起来，搁在人的肩上，但自己一个指头也不肯动。"},
{volumn:        40,chapter:        23,versenumber:         5,verse:"他们一切所做的事都是要叫人看见，所以将佩戴的经文做宽了，衣裳的繸子做长了，"},
{volumn:        40,chapter:        23,versenumber:         6,verse:"喜爱筵席上的首座，会堂里的高位，"},
{volumn:        40,chapter:        23,versenumber:         7,verse:"又喜爱人在街市上问他安，称呼他拉比（拉比就是夫子）。"},
{volumn:        40,chapter:        23,versenumber:         8,verse:"但你们不要受拉比的称呼，因为只有一位是你们的夫子；你们都是弟兄。"},
{volumn:        40,chapter:        23,versenumber:         9,verse:"也不要称呼地上的人为父，因为只有一位是你们的父，就是在天上的父。"},
{volumn:        40,chapter:        23,versenumber:        10,verse:"也不要受师尊的称呼，因为只有一位是你们的师尊，就是基督。"},
{volumn:        40,chapter:        23,versenumber:        11,verse:"你们中间谁为大，谁就要作你们的用人。"},
{volumn:        40,chapter:        23,versenumber:        12,verse:"凡自高的，必降为卑；自卑的，必升为高。"},
{volumn:        40,chapter:        23,versenumber:        13,verse:"“你们这假冒为善的文士和法利赛人有祸了！因为你们正当人前，把天国的门关了，自己不进去，正要进去的人，你们也不容他们进去。（有古卷加：14你们这假冒为善的文士和法利赛人有祸了！因为你们侵吞寡妇的家产，假意做很长的祷告，所以要受更重的刑罚。）"},
{volumn:        40,chapter:        23,versenumber:        14,verse:""},
{volumn:        40,chapter:        23,versenumber:        15,verse:"“你们这假冒为善的文士和法利赛人有祸了！因为你们走遍洋海陆地，勾引一个人入教，既入了教，却使他作地狱之子，比你们还加倍。"},
{volumn:        40,chapter:        23,versenumber:        16,verse:"“你们这瞎眼领路的有祸了！你们说：‘凡指着殿起誓的，这算不得什么；只是凡指着殿中金子起誓的，他就该谨守。’"},
{volumn:        40,chapter:        23,versenumber:        17,verse:"你们这无知瞎眼的人哪，什么是大的？是金子呢？还是叫金子成圣的殿呢？"},
{volumn:        40,chapter:        23,versenumber:        18,verse:"你们又说：‘凡指着坛起誓的，这算不得什么；只是凡指着坛上礼物起誓的，他就该谨守。’"},
{volumn:        40,chapter:        23,versenumber:        19,verse:"你们这瞎眼的人哪，什么是大的？是礼物呢？还是叫礼物成圣的坛呢？"},
{volumn:        40,chapter:        23,versenumber:        20,verse:"所以，人指着坛起誓，就是指着坛和坛上一切所有的起誓；"},
{volumn:        40,chapter:        23,versenumber:        21,verse:"人指着殿起誓，就是指着殿和那住在殿里的起誓；"},
{volumn:        40,chapter:        23,versenumber:        22,verse:"人指着天起誓，就是指着　神的宝座和那坐在上面的起誓。"},
{volumn:        40,chapter:        23,versenumber:        23,verse:"“你们这假冒为善的文士和法利赛人有祸了！因为你们将薄荷、茴香、芹菜献上十分之一，那律法上更重的事，就是公义、怜悯、信实，反倒不行了。这更重的是你们当行的；那也是不可不行的。"},
{volumn:        40,chapter:        23,versenumber:        24,verse:"你们这瞎眼领路的，蠓虫你们就滤出来，骆驼你们倒吞下去。"},
{volumn:        40,chapter:        23,versenumber:        25,verse:"“你们这假冒为善的文士和法利赛人有祸了！因为你们洗净杯盘的外面，里面却盛满了勒索和放荡。"},
{volumn:        40,chapter:        23,versenumber:        26,verse:"你这瞎眼的法利赛人，先洗净杯盘的里面，好叫外面也干净了。"},
{volumn:        40,chapter:        23,versenumber:        27,verse:"“你们这假冒为善的文士和法利赛人有祸了！因为你们好像粉饰的坟墓，外面好看，里面却装满了死人的骨头和一切的污秽。"},
{volumn:        40,chapter:        23,versenumber:        28,verse:"你们也是如此，在人前，外面显出公义来，里面却装满了假善和不法的事。"},
{volumn:        40,chapter:        23,versenumber:        29,verse:"“你们这假冒为善的文士和法利赛人有祸了！因为你们建造先知的坟，修饰义人的墓，说："},
{volumn:        40,chapter:        23,versenumber:        30,verse:"‘若是我们在我们祖宗的时候，必不和他们同流先知的血。’"},
{volumn:        40,chapter:        23,versenumber:        31,verse:"这就是你们自己证明是杀害先知者的子孙了。"},
{volumn:        40,chapter:        23,versenumber:        32,verse:"你们去充满你们祖宗的恶贯吧！"},
{volumn:        40,chapter:        23,versenumber:        33,verse:"你们这些蛇类、毒蛇之种啊，怎能逃脱地狱的刑罚呢？"},
{volumn:        40,chapter:        23,versenumber:        34,verse:"所以我差遣先知和智慧人并文士到你们这里来，有的你们要杀害，要钉十字架；有的你们要在会堂里鞭打，从这城追逼到那城，"},
{volumn:        40,chapter:        23,versenumber:        35,verse:"叫世上所流义人的血都归到你们身上，从义人亚伯的血起，直到你们在殿和坛中间所杀的巴拉加的儿子撒迦利亚的血为止。"},
{volumn:        40,chapter:        23,versenumber:        36,verse:"我实在告诉你们，这一切的罪都要归到这世代了。”"},
{volumn:        40,chapter:        23,versenumber:        37,verse:"“耶路撒冷啊，耶路撒冷啊，你常杀害先知，又用石头打死那奉差遣到你这里来的人。我多次愿意聚集你的儿女，好像母鸡把小鸡聚集在翅膀底下，只是你们不愿意。"},
{volumn:        40,chapter:        23,versenumber:        38,verse:"看哪，你们的家成为荒场留给你们。"},
{volumn:        40,chapter:        23,versenumber:        39,verse:"我告诉你们，从今以后，你们不得再见我，直等到你们说：‘奉主名来的是应当称颂的。’”"},
{volumn:        40,chapter:        24,versenumber:         1,verse:"耶稣出了圣殿，正走的时候，门徒进前来，把殿宇指给他看。"},
{volumn:        40,chapter:        24,versenumber:         2,verse:"耶稣对他们说：“你们不是看见这殿宇吗？我实在告诉你们，将来在这里没有一块石头留在石头上，不被拆毁了。”"},
{volumn:        40,chapter:        24,versenumber:         3,verse:"耶稣在橄榄山上坐着，门徒暗暗地来说：“请告诉我们，什么时候有这些事？你降临和世界的末了有什么预兆呢？”"},
{volumn:        40,chapter:        24,versenumber:         4,verse:"耶稣回答说：“你们要谨慎，免得有人迷惑你们。"},
{volumn:        40,chapter:        24,versenumber:         5,verse:"因为将来有好些人冒我的名来，说：‘我是基督’，并且要迷惑许多人。"},
{volumn:        40,chapter:        24,versenumber:         6,verse:"你们也要听见打仗和打仗的风声，总不要惊慌；因为这些事是必须有的，只是末期还没有到。"},
{volumn:        40,chapter:        24,versenumber:         7,verse:"民要攻打民，国要攻打国；多处必有饥荒、地震。"},
{volumn:        40,chapter:        24,versenumber:         8,verse:"这都是灾难（灾难：原文是生产之难）的起头。"},
{volumn:        40,chapter:        24,versenumber:         9,verse:"那时，人要把你们陷在患难里，也要杀害你们；你们又要为我的名被万民恨恶。"},
{volumn:        40,chapter:        24,versenumber:        10,verse:"那时，必有许多人跌倒，也要彼此陷害，彼此恨恶；"},
{volumn:        40,chapter:        24,versenumber:        11,verse:"且有好些假先知起来，迷惑多人。"},
{volumn:        40,chapter:        24,versenumber:        12,verse:"只因不法的事增多，许多人的爱心才渐渐冷淡了。"},
{volumn:        40,chapter:        24,versenumber:        13,verse:"惟有忍耐到底的，必然得救。"},
{volumn:        40,chapter:        24,versenumber:        14,verse:"这天国的福音要传遍天下，对万民作见证，然后末期才来到。”"},
{volumn:        40,chapter:        24,versenumber:        15,verse:"“你们看见先知但以理所说的‘那行毁坏可憎的’站在圣地（读这经的人须要会意）。"},
{volumn:        40,chapter:        24,versenumber:        16,verse:"那时，在犹太的，应当逃到山上；"},
{volumn:        40,chapter:        24,versenumber:        17,verse:"在房上的，不要下来拿家里的东西；"},
{volumn:        40,chapter:        24,versenumber:        18,verse:"在田里的，也不要回去取衣裳。"},
{volumn:        40,chapter:        24,versenumber:        19,verse:"当那些日子，怀孕的和奶孩子的有祸了。"},
{volumn:        40,chapter:        24,versenumber:        20,verse:"你们应当祈求，叫你们逃走的时候，不遇见冬天或是安息日。"},
{volumn:        40,chapter:        24,versenumber:        21,verse:"因为那时必有大灾难，从世界的起头直到如今，没有这样的灾难，后来也必没有。"},
{volumn:        40,chapter:        24,versenumber:        22,verse:"若不减少那日子，凡有血气的总没有一个得救的；只是为选民，那日子必减少了。"},
{volumn:        40,chapter:        24,versenumber:        23,verse:"那时，若有人对你们说：‘基督在这里’，或说：‘基督在那里’，你们不要信！"},
{volumn:        40,chapter:        24,versenumber:        24,verse:"因为假基督、假先知将要起来，显大神迹、大奇事，倘若能行，连选民也就迷惑了。"},
{volumn:        40,chapter:        24,versenumber:        25,verse:"看哪，我预先告诉你们了。"},
{volumn:        40,chapter:        24,versenumber:        26,verse:"若有人对你们说：‘看哪，基督在旷野里’，你们不要出去！或说：‘看哪，基督在内屋中’，你们不要信！"},
{volumn:        40,chapter:        24,versenumber:        27,verse:"闪电从东边发出，直照到西边。人子降临也要这样。"},
{volumn:        40,chapter:        24,versenumber:        28,verse:"尸首在哪里，鹰也必聚在那里。”"},
{volumn:        40,chapter:        24,versenumber:        29,verse:"“那些日子的灾难一过去，日头就变黑了，月亮也不放光，众星要从天上坠落，天势都要震动。"},
{volumn:        40,chapter:        24,versenumber:        30,verse:"那时，人子的兆头要显在天上，地上的万族都要哀哭。他们要看见人子，有能力，有大荣耀，驾着天上的云降临。"},
{volumn:        40,chapter:        24,versenumber:        31,verse:"他要差遣使者，用号筒的大声，将他的选民，从四方（方：原文是风），从天这边到天那边，都招聚了来。”"},
{volumn:        40,chapter:        24,versenumber:        32,verse:"“你们可以从无花果树学个比方：当树枝发嫩长叶的时候，你们就知道夏天近了。"},
{volumn:        40,chapter:        24,versenumber:        33,verse:"这样，你们看见这一切的事，也该知道人子近了，正在门口了。"},
{volumn:        40,chapter:        24,versenumber:        34,verse:"我实在告诉你们，这世代还没有过去，这些事都要成就。"},
{volumn:        40,chapter:        24,versenumber:        35,verse:"天地要废去，我的话却不能废去。”"},
{volumn:        40,chapter:        24,versenumber:        36,verse:"“但那日子，那时辰，没有人知道，连天上的使者也不知道，子也不知道，惟独父知道。"},
{volumn:        40,chapter:        24,versenumber:        37,verse:"挪亚的日子怎样，人子降临也要怎样。"},
{volumn:        40,chapter:        24,versenumber:        38,verse:"当洪水以前的日子，人照常吃喝嫁娶，直到挪亚进方舟的那日；"},
{volumn:        40,chapter:        24,versenumber:        39,verse:"不知不觉洪水来了，把他们全都冲去。人子降临也要这样。"},
{volumn:        40,chapter:        24,versenumber:        40,verse:"那时，两个人在田里，取去一个，撇下一个。"},
{volumn:        40,chapter:        24,versenumber:        41,verse:"两个女人推磨，取去一个，撇下一个。"},
{volumn:        40,chapter:        24,versenumber:        42,verse:"所以，你们要警醒，因为不知道你们的主是哪一天来到。"},
{volumn:        40,chapter:        24,versenumber:        43,verse:"家主若知道几更天有贼来，就必警醒，不容人挖透房屋；这是你们所知道的。"},
{volumn:        40,chapter:        24,versenumber:        44,verse:"所以，你们也要预备，因为你们想不到的时候，人子就来了。”"},
{volumn:        40,chapter:        24,versenumber:        45,verse:"“谁是忠心有见识的仆人，为主人所派，管理家里的人，按时分粮给他们呢？"},
{volumn:        40,chapter:        24,versenumber:        46,verse:"主人来到，看见他这样行，那仆人就有福了。"},
{volumn:        40,chapter:        24,versenumber:        47,verse:"我实在告诉你们，主人要派他管理一切所有的。"},
{volumn:        40,chapter:        24,versenumber:        48,verse:"倘若那恶仆心里说：‘我的主人必来得迟’，"},
{volumn:        40,chapter:        24,versenumber:        49,verse:"就动手打他的同伴，又和酒醉的人一同吃喝。"},
{volumn:        40,chapter:        24,versenumber:        50,verse:"在想不到的日子，不知道的时辰，那仆人的主人要来，"},
{volumn:        40,chapter:        24,versenumber:        51,verse:"重重地处治他（或译：把他腰斩了），定他和假冒为善的人同罪；在那里必要哀哭切齿了。”"},
{volumn:        40,chapter:        25,versenumber:         1,verse:"“那时，天国好比十个童女拿着灯出去迎接新郎。"},
{volumn:        40,chapter:        25,versenumber:         2,verse:"其中有五个是愚拙的，五个是聪明的。"},
{volumn:        40,chapter:        25,versenumber:         3,verse:"愚拙的拿着灯，却不预备油；"},
{volumn:        40,chapter:        25,versenumber:         4,verse:"聪明的拿着灯，又预备油在器皿里。"},
{volumn:        40,chapter:        25,versenumber:         5,verse:"新郎迟延的时候，她们都打盹，睡着了。"},
{volumn:        40,chapter:        25,versenumber:         6,verse:"半夜有人喊着说：‘新郎来了，你们出来迎接他！’"},
{volumn:        40,chapter:        25,versenumber:         7,verse:"那些童女就都起来收拾灯。"},
{volumn:        40,chapter:        25,versenumber:         8,verse:"愚拙的对聪明的说：‘请分点油给我们，因为我们的灯要灭了。’"},
{volumn:        40,chapter:        25,versenumber:         9,verse:"聪明的回答说：‘恐怕不够你我用的；不如你们自己到卖油的那里去买吧。’"},
{volumn:        40,chapter:        25,versenumber:        10,verse:"她们去买的时候，新郎到了。那预备好了的，同他进去坐席，门就关了。"},
{volumn:        40,chapter:        25,versenumber:        11,verse:"其余的童女随后也来了，说：‘主啊，主啊，给我们开门！’"},
{volumn:        40,chapter:        25,versenumber:        12,verse:"他却回答说：‘我实在告诉你们，我不认识你们。’"},
{volumn:        40,chapter:        25,versenumber:        13,verse:"所以，你们要警醒；因为那日子，那时辰，你们不知道。”"},
{volumn:        40,chapter:        25,versenumber:        14,verse:"“天国又好比一个人要往外国去，就叫了仆人来，把他的家业交给他们，"},
{volumn:        40,chapter:        25,versenumber:        15,verse:"按着各人的才干给他们银子：一个给了五千，一个给了二千，一个给了一千，就往外国去了。"},
{volumn:        40,chapter:        25,versenumber:        16,verse:"那领五千的随即拿去做买卖，另外赚了五千。"},
{volumn:        40,chapter:        25,versenumber:        17,verse:"那领二千的也照样另赚了二千。"},
{volumn:        40,chapter:        25,versenumber:        18,verse:"但那领一千的去掘开地，把主人的银子埋藏了。"},
{volumn:        40,chapter:        25,versenumber:        19,verse:"过了许久，那些仆人的主人来了，和他们算帐。"},
{volumn:        40,chapter:        25,versenumber:        20,verse:"那领五千银子的又带着那另外的五千来，说：‘主啊，你交给我五千银子。请看，我又赚了五千。’"},
{volumn:        40,chapter:        25,versenumber:        21,verse:"主人说：‘好，你这又良善又忠心的仆人，你在不多的事上有忠心，我要把许多事派你管理；可以进来享受你主人的快乐。’"},
{volumn:        40,chapter:        25,versenumber:        22,verse:"那领二千的也来，说：‘主啊，你交给我二千银子。请看，我又赚了二千。’"},
{volumn:        40,chapter:        25,versenumber:        23,verse:"主人说：‘好，你这又良善又忠心的仆人，你在不多的事上有忠心，我要把许多事派你管理；可以进来享受你主人的快乐。’"},
{volumn:        40,chapter:        25,versenumber:        24,verse:"那领一千的也来，说：‘主啊，我知道你是忍心的人，没有种的地方要收割，没有散的地方要聚敛，"},
{volumn:        40,chapter:        25,versenumber:        25,verse:"我就害怕，去把你的一千银子埋藏在地里。请看，你的原银子在这里。’"},
{volumn:        40,chapter:        25,versenumber:        26,verse:"主人回答说：‘你这又恶又懒的仆人，你既知道我没有种的地方要收割，没有散的地方要聚敛，"},
{volumn:        40,chapter:        25,versenumber:        27,verse:"就当把我的银子放给兑换银钱的人，到我来的时候，可以连本带利收回。"},
{volumn:        40,chapter:        25,versenumber:        28,verse:"夺过他这一千来，给那有一万的。"},
{volumn:        40,chapter:        25,versenumber:        29,verse:"因为凡有的，还要加给他，叫他有余；没有的，连他所有的也要夺过来。"},
{volumn:        40,chapter:        25,versenumber:        30,verse:"把这无用的仆人丢在外面黑暗里；在那里必要哀哭切齿了。’”"},
{volumn:        40,chapter:        25,versenumber:        31,verse:"“当人子在他荣耀里、同着众天使降临的时候，要坐在他荣耀的宝座上。"},
{volumn:        40,chapter:        25,versenumber:        32,verse:"万民都要聚集在他面前。他要把他们分别出来，好像牧羊的分别绵羊山羊一般，"},
{volumn:        40,chapter:        25,versenumber:        33,verse:"把绵羊安置在右边，山羊在左边。"},
{volumn:        40,chapter:        25,versenumber:        34,verse:"于是王要向那右边的说：‘你们这蒙我父赐福的，可来承受那创世以来为你们所预备的国；"},
{volumn:        40,chapter:        25,versenumber:        35,verse:"因为我饿了，你们给我吃，渴了，你们给我喝；我作客旅，你们留我住；"},
{volumn:        40,chapter:        25,versenumber:        36,verse:"我赤身露体，你们给我穿；我病了，你们看顾我；我在监里，你们来看我。’"},
{volumn:        40,chapter:        25,versenumber:        37,verse:"义人就回答说：‘主啊，我们什么时候见你饿了，给你吃，渴了，给你喝？"},
{volumn:        40,chapter:        25,versenumber:        38,verse:"什么时候见你作客旅，留你住，或是赤身露体，给你穿？"},
{volumn:        40,chapter:        25,versenumber:        39,verse:"又什么时候见你病了，或是在监里，来看你呢？’"},
{volumn:        40,chapter:        25,versenumber:        40,verse:"王要回答说：‘我实在告诉你们，这些事你们既做在我这弟兄中一个最小的身上，就是做在我身上了。’"},
{volumn:        40,chapter:        25,versenumber:        41,verse:"王又要向那左边的说：‘你们这被咒诅的人，离开我！进入那为魔鬼和他的使者所预备的永火里去！"},
{volumn:        40,chapter:        25,versenumber:        42,verse:"因为我饿了，你们不给我吃，渴了，你们不给我喝；"},
{volumn:        40,chapter:        25,versenumber:        43,verse:"我作客旅，你们不留我住；我赤身露体，你们不给我穿；我病了，我在监里，你们不来看顾我。’"},
{volumn:        40,chapter:        25,versenumber:        44,verse:"他们也要回答说：‘主啊，我们什么时候见你饿了，或渴了，或作客旅，或赤身露体，或病了，或在监里，不伺候你呢？’"},
{volumn:        40,chapter:        25,versenumber:        45,verse:"王要回答说：‘我实在告诉你们，这些事你们既不做在我这弟兄中一个最小的身上，就是不做在我身上了。’"},
{volumn:        40,chapter:        25,versenumber:        46,verse:"这些人要往永刑里去；那些义人要往永生里去。”"},
{volumn:        40,chapter:        26,versenumber:         1,verse:"耶稣说完了这一切的话，就对门徒说："},
{volumn:        40,chapter:        26,versenumber:         2,verse:"“你们知道，过两天是逾越节，人子将要被交给人，钉在十字架上。”"},
{volumn:        40,chapter:        26,versenumber:         3,verse:"那时，祭司长和民间的长老聚集在大祭司称为该亚法的院里。"},
{volumn:        40,chapter:        26,versenumber:         4,verse:"大家商议要用诡计拿住耶稣，杀他，"},
{volumn:        40,chapter:        26,versenumber:         5,verse:"只是说：“当节的日子不可，恐怕民间生乱。”"},
{volumn:        40,chapter:        26,versenumber:         6,verse:"耶稣在伯大尼长大麻风的西门家里，"},
{volumn:        40,chapter:        26,versenumber:         7,verse:"有一个女人拿着一玉瓶极贵的香膏来，趁耶稣坐席的时候，浇在他的头上。"},
{volumn:        40,chapter:        26,versenumber:         8,verse:"门徒看见就很不喜悦，说：“何用这样的枉费呢！"},
{volumn:        40,chapter:        26,versenumber:         9,verse:"这香膏可以卖许多钱，周济穷人。”"},
{volumn:        40,chapter:        26,versenumber:        10,verse:"耶稣看出他们的意思，就说：“为什么难为这女人呢？她在我身上做的是一件美事。"},
{volumn:        40,chapter:        26,versenumber:        11,verse:"因为常有穷人和你们同在；只是你们不常有我。"},
{volumn:        40,chapter:        26,versenumber:        12,verse:"她将这香膏浇在我身上是为我安葬做的。"},
{volumn:        40,chapter:        26,versenumber:        13,verse:"我实在告诉你们，普天之下，无论在什么地方传这福音，也要述说这女人所行的，作个纪念。”"},
{volumn:        40,chapter:        26,versenumber:        14,verse:"当下，十二门徒里有一个称为加略人犹大的，去见祭司长，"},
{volumn:        40,chapter:        26,versenumber:        15,verse:"说：“我把他交给你们，你们愿意给我多少钱？”他们就给了他三十块钱。"},
{volumn:        40,chapter:        26,versenumber:        16,verse:"从那时候，他就找机会要把耶稣交给他们。"},
{volumn:        40,chapter:        26,versenumber:        17,verse:"除酵节的第一天，门徒来问耶稣说：“你吃逾越节的筵席，要我们在哪里给你预备？”"},
{volumn:        40,chapter:        26,versenumber:        18,verse:"耶稣说：“你们进城去，到某人那里，对他说：‘夫子说：我的时候快到了，我与门徒要在你家里守逾越节。’”"},
{volumn:        40,chapter:        26,versenumber:        19,verse:"门徒遵着耶稣所吩咐的就去预备了逾越节的筵席。"},
{volumn:        40,chapter:        26,versenumber:        20,verse:"到了晚上，耶稣和十二个门徒坐席。"},
{volumn:        40,chapter:        26,versenumber:        21,verse:"正吃的时候，耶稣说：“我实在告诉你们，你们中间有一个人要卖我了。”"},
{volumn:        40,chapter:        26,versenumber:        22,verse:"他们就甚忧愁，一个一个地问他说：“主，是我吗？”"},
{volumn:        40,chapter:        26,versenumber:        23,verse:"耶稣回答说：“同我蘸手在盘子里的，就是他要卖我。"},
{volumn:        40,chapter:        26,versenumber:        24,verse:"人子必要去世，正如经上指着他所写的；但卖人子的人有祸了！那人不生在世上倒好。”"},
{volumn:        40,chapter:        26,versenumber:        25,verse:"卖耶稣的犹大问他说：“拉比，是我吗？”耶稣说：“你说的是。”"},
{volumn:        40,chapter:        26,versenumber:        26,verse:"他们吃的时候，耶稣拿起饼来，祝福，就擘开，递给门徒，说：“你们拿着吃，这是我的身体”；"},
{volumn:        40,chapter:        26,versenumber:        27,verse:"又拿起杯来，祝谢了，递给他们，说：“你们都喝这个；"},
{volumn:        40,chapter:        26,versenumber:        28,verse:"因为这是我立约的血，为多人流出来，使罪得赦。"},
{volumn:        40,chapter:        26,versenumber:        29,verse:"但我告诉你们，从今以后，我不再喝这葡萄汁，直到我在我父的国里同你们喝新的那日子。”"},
{volumn:        40,chapter:        26,versenumber:        30,verse:"他们唱了诗，就出来往橄榄山去。"},
{volumn:        40,chapter:        26,versenumber:        31,verse:"那时，耶稣对他们说：“今夜，你们为我的缘故都要跌倒。因为经上记着说：‘我要击打牧人，羊就分散了。’"},
{volumn:        40,chapter:        26,versenumber:        32,verse:"但我复活以后，要在你们以先往加利利去。”"},
{volumn:        40,chapter:        26,versenumber:        33,verse:"彼得说：“众人虽然为你的缘故跌倒，我却永不跌倒。”"},
{volumn:        40,chapter:        26,versenumber:        34,verse:"耶稣说：“我实在告诉你，今夜鸡叫以先，你要三次不认我。”"},
{volumn:        40,chapter:        26,versenumber:        35,verse:"彼得说：“我就是必须和你同死，也总不能不认你。”众门徒都是这样说。"},
{volumn:        40,chapter:        26,versenumber:        36,verse:"耶稣同门徒来到一个地方，名叫客西马尼，就对他们说：“你们坐在这里，等我到那边去祷告。”"},
{volumn:        40,chapter:        26,versenumber:        37,verse:"于是带着彼得和西庇太的两个儿子同去，就忧愁起来，极其难过，"},
{volumn:        40,chapter:        26,versenumber:        38,verse:"便对他们说：“我心里甚是忧伤，几乎要死；你们在这里等候，和我一同警醒。”"},
{volumn:        40,chapter:        26,versenumber:        39,verse:"他就稍往前走，俯伏在地，祷告说：“我父啊，倘若可行，求你叫这杯离开我。然而，不要照我的意思，只要照你的意思。”"},
{volumn:        40,chapter:        26,versenumber:        40,verse:"来到门徒那里，见他们睡着了，就对彼得说：“怎么样？你们不能同我警醒片时吗？"},
{volumn:        40,chapter:        26,versenumber:        41,verse:"总要警醒祷告，免得入了迷惑。你们心灵固然愿意，肉体却软弱了。”"},
{volumn:        40,chapter:        26,versenumber:        42,verse:"第二次又去祷告说：“我父啊，这杯若不能离开我，必要我喝，就愿你的意旨成全。”"},
{volumn:        40,chapter:        26,versenumber:        43,verse:"又来，见他们睡着了，因为他们的眼睛困倦。"},
{volumn:        40,chapter:        26,versenumber:        44,verse:"耶稣又离开他们去了。第三次祷告，说的话还是与先前一样。"},
{volumn:        40,chapter:        26,versenumber:        45,verse:"于是来到门徒那里，对他们说：“现在你们仍然睡觉安歇吧（吧：或译吗？）！时候到了，人子被卖在罪人手里了。"},
{volumn:        40,chapter:        26,versenumber:        46,verse:"起来！我们走吧。看哪，卖我的人近了！”"},
{volumn:        40,chapter:        26,versenumber:        47,verse:"说话之间，那十二个门徒里的犹大来了，并有许多人带着刀棒，从祭司长和民间的长老那里与他同来。"},
{volumn:        40,chapter:        26,versenumber:        48,verse:"那卖耶稣的给了他们一个暗号，说：“我与谁亲嘴，谁就是他。你们可以拿住他。”"},
{volumn:        40,chapter:        26,versenumber:        49,verse:"犹大随即到耶稣跟前，说：“请拉比安”，就与他亲嘴。"},
{volumn:        40,chapter:        26,versenumber:        50,verse:"耶稣对他说：“朋友，你来要做的事，就做吧。”于是那些人上前，下手拿住耶稣。"},
{volumn:        40,chapter:        26,versenumber:        51,verse:"有跟随耶稣的一个人伸手拔出刀来，将大祭司的仆人砍了一刀，削掉了他一个耳朵。"},
{volumn:        40,chapter:        26,versenumber:        52,verse:"耶稣对他说：“收刀入鞘吧！凡动刀的，必死在刀下。"},
{volumn:        40,chapter:        26,versenumber:        53,verse:"你想，我不能求我父现在为我差遣十二营多天使来吗？"},
{volumn:        40,chapter:        26,versenumber:        54,verse:"若是这样，经上所说，事情必须如此的话怎么应验呢？”"},
{volumn:        40,chapter:        26,versenumber:        55,verse:"当时，耶稣对众人说：“你们带着刀棒出来拿我，如同拿强盗吗？我天天坐在殿里教训人，你们并没有拿我。"},
{volumn:        40,chapter:        26,versenumber:        56,verse:"但这一切的事成就了，为要应验先知书上的话。”当下，门徒都离开他，逃走了。"},
{volumn:        40,chapter:        26,versenumber:        57,verse:"拿耶稣的人把他带到大祭司该亚法那里去；文士和长老已经在那里聚会。"},
{volumn:        40,chapter:        26,versenumber:        58,verse:"彼得远远地跟着耶稣，直到大祭司的院子，进到里面，就和差役同坐，要看这事到底怎样。"},
{volumn:        40,chapter:        26,versenumber:        59,verse:"祭司长和全公会寻找假见证控告耶稣，要治死他。"},
{volumn:        40,chapter:        26,versenumber:        60,verse:"虽有好些人来作假见证，总得不着实据。末后有两个人前来，说："},
{volumn:        40,chapter:        26,versenumber:        61,verse:"“这个人曾说：‘我能拆毁　神的殿，三日内又建造起来。’”"},
{volumn:        40,chapter:        26,versenumber:        62,verse:"大祭司就站起来，对耶稣说：“你什么都不回答吗？这些人作见证告你的是什么呢？”"},
{volumn:        40,chapter:        26,versenumber:        63,verse:"耶稣却不言语。大祭司对他说：“我指着永生　神叫你起誓告诉我们，你是　神的儿子基督不是？”"},
{volumn:        40,chapter:        26,versenumber:        64,verse:"耶稣对他说：“你说的是。然而，我告诉你们，后来你们要看见人子坐在那权能者的右边，驾着天上的云降临。”"},
{volumn:        40,chapter:        26,versenumber:        65,verse:"大祭司就撕开衣服，说：“他说了僭妄的话，我们何必再用见证人呢？这僭妄的话，现在你们都听见了。"},
{volumn:        40,chapter:        26,versenumber:        66,verse:"你们的意见如何？”他们回答说：“他是该死的。”"},
{volumn:        40,chapter:        26,versenumber:        67,verse:"他们就吐唾沫在他脸上，用拳头打他，也有用手掌打他的，说："},
{volumn:        40,chapter:        26,versenumber:        68,verse:"“基督啊！你是先知，告诉我们打你的是谁？”"},
{volumn:        40,chapter:        26,versenumber:        69,verse:"彼得在外面院子里坐着，有一个使女前来，说：“你素来也是同那加利利人耶稣一伙的。”"},
{volumn:        40,chapter:        26,versenumber:        70,verse:"彼得在众人面前却不承认，说：“我不知道你说的是什么！”"},
{volumn:        40,chapter:        26,versenumber:        71,verse:"既出去，到了门口，又有一个使女看见他，就对那里的人说：“这个人也是同拿撒勒人耶稣一伙的。”"},
{volumn:        40,chapter:        26,versenumber:        72,verse:"彼得又不承认，并且起誓说：“我不认得那个人。”"},
{volumn:        40,chapter:        26,versenumber:        73,verse:"过了不多的时候，旁边站着的人前来，对彼得说：“你真是他们一党的，你的口音把你露出来了。”"},
{volumn:        40,chapter:        26,versenumber:        74,verse:"彼得就发咒起誓地说：“我不认得那个人。”立时，鸡就叫了。"},
{volumn:        40,chapter:        26,versenumber:        75,verse:"彼得想起耶稣所说的话：“鸡叫以先，你要三次不认我。”他就出去痛哭。"},
{volumn:        40,chapter:        27,versenumber:         1,verse:"到了早晨，众祭司长和民间的长老大家商议要治死耶稣，"},
{volumn:        40,chapter:        27,versenumber:         2,verse:"就把他捆绑，解去，交给巡抚彼拉多。"},
{volumn:        40,chapter:        27,versenumber:         3,verse:"这时候，卖耶稣的犹大看见耶稣已经定了罪，就后悔，把那三十块钱拿回来给祭司长和长老，说："},
{volumn:        40,chapter:        27,versenumber:         4,verse:"“我卖了无辜之人的血是有罪了。”他们说：“那与我们有什么相干？你自己承当吧！”"},
{volumn:        40,chapter:        27,versenumber:         5,verse:"犹大就把那银钱丢在殿里，出去吊死了。"},
{volumn:        40,chapter:        27,versenumber:         6,verse:"祭司长拾起银钱来，说：“这是血价，不可放在库里。”"},
{volumn:        40,chapter:        27,versenumber:         7,verse:"他们商议，就用那银钱买了窑户的一块田，为要埋葬外乡人。"},
{volumn:        40,chapter:        27,versenumber:         8,verse:"所以那块田直到今日还叫做“血田”。"},
{volumn:        40,chapter:        27,versenumber:         9,verse:"这就应了先知耶利米的话，说：“他们用那三十块钱，就是被估定之人的价钱，是以色列人中所估定的，"},
{volumn:        40,chapter:        27,versenumber:        10,verse:"买了窑户的一块田；这是照着主所吩咐我的。”"},
{volumn:        40,chapter:        27,versenumber:        11,verse:"耶稣站在巡抚面前；巡抚问他说：“你是犹太人的王吗？”耶稣说：“你说的是。”"},
{volumn:        40,chapter:        27,versenumber:        12,verse:"他被祭司长和长老控告的时候，什么都不回答。"},
{volumn:        40,chapter:        27,versenumber:        13,verse:"彼拉多就对他说：“他们作见证告你这么多的事，你没有听见吗？”"},
{volumn:        40,chapter:        27,versenumber:        14,verse:"耶稣仍不回答，连一句话也不说，以致巡抚甚觉希奇。"},
{volumn:        40,chapter:        27,versenumber:        15,verse:"巡抚有一个常例，每逢这节期，随众人所要的释放一个囚犯给他们。"},
{volumn:        40,chapter:        27,versenumber:        16,verse:"当时有一个出名的囚犯叫巴拉巴。"},
{volumn:        40,chapter:        27,versenumber:        17,verse:"众人聚集的时候，彼拉多就对他们说：“你们要我释放哪一个给你们？是巴拉巴呢？是称为基督的耶稣呢？”"},
{volumn:        40,chapter:        27,versenumber:        18,verse:"巡抚原知道他们是因为嫉妒才把他解了来。"},
{volumn:        40,chapter:        27,versenumber:        19,verse:"正坐堂的时候，他的夫人打发人来说：“这义人的事，你一点不可管，因为我今天在梦中为他受了许多的苦。”"},
{volumn:        40,chapter:        27,versenumber:        20,verse:"祭司长和长老挑唆众人，求释放巴拉巴，除灭耶稣。"},
{volumn:        40,chapter:        27,versenumber:        21,verse:"巡抚对众人说：“这两个人，你们要我释放哪一个给你们呢？”他们说：“巴拉巴。”"},
{volumn:        40,chapter:        27,versenumber:        22,verse:"彼拉多说：“这样，那称为基督的耶稣我怎么办他呢？”他们都说：“把他钉十字架！”"},
{volumn:        40,chapter:        27,versenumber:        23,verse:"巡抚说：“为什么呢？他做了什么恶事呢？”他们便极力地喊着说：“把他钉十字架！”"},
{volumn:        40,chapter:        27,versenumber:        24,verse:"彼拉多见说也无济于事，反要生乱，就拿水在众人面前洗手，说：“流这义人的血，罪不在我，你们承当吧。”"},
{volumn:        40,chapter:        27,versenumber:        25,verse:"众人都回答说：“他的血归到我们和我们的子孙身上。”"},
{volumn:        40,chapter:        27,versenumber:        26,verse:"于是彼拉多释放巴拉巴给他们，把耶稣鞭打了，交给人钉十字架。"},
{volumn:        40,chapter:        27,versenumber:        27,verse:"巡抚的兵就把耶稣带进衙门，叫全营的兵都聚集在他那里。"},
{volumn:        40,chapter:        27,versenumber:        28,verse:"他们给他脱了衣服，穿上一件朱红色袍子，"},
{volumn:        40,chapter:        27,versenumber:        29,verse:"用荆棘编做冠冕，戴在他头上，拿一根苇子放在他右手里，跪在他面前，戏弄他，说：“恭喜，犹太人的王啊！”"},
{volumn:        40,chapter:        27,versenumber:        30,verse:"又吐唾沫在他脸上，拿苇子打他的头。"},
{volumn:        40,chapter:        27,versenumber:        31,verse:"戏弄完了，就给他脱了袍子，仍穿上他自己的衣服，带他出去，要钉十字架。"},
{volumn:        40,chapter:        27,versenumber:        32,verse:"他们出来的时候，遇见一个古利奈人，名叫西门，就勉强他同去，好背着耶稣的十字架。"},
{volumn:        40,chapter:        27,versenumber:        33,verse:"到了一个地方名叫各各他，意思就是“髑髅地”。"},
{volumn:        40,chapter:        27,versenumber:        34,verse:"兵丁拿苦胆调和的酒给耶稣喝；他尝了，就不肯喝。"},
{volumn:        40,chapter:        27,versenumber:        35,verse:"他们既将他钉在十字架上，就拈阄分他的衣服，"},
{volumn:        40,chapter:        27,versenumber:        36,verse:"又坐在那里看守他。"},
{volumn:        40,chapter:        27,versenumber:        37,verse:"在他头以上安一个牌子，写着他的罪状，说：“这是犹太人的王耶稣。”"},
{volumn:        40,chapter:        27,versenumber:        38,verse:"当时，有两个强盗和他同钉十字架，一个在右边，一个在左边。"},
{volumn:        40,chapter:        27,versenumber:        39,verse:"从那里经过的人讥诮他，摇着头，说："},
{volumn:        40,chapter:        27,versenumber:        40,verse:"“你这拆毁圣殿、三日又建造起来的，可以救自己吧！你如果是　神的儿子，就从十字架上下来吧！”"},
{volumn:        40,chapter:        27,versenumber:        41,verse:"祭司长和文士并长老也是这样戏弄他，说："},
{volumn:        40,chapter:        27,versenumber:        42,verse:"“他救了别人，不能救自己。他是以色列的王，现在可以从十字架上下来，我们就信他。"},
{volumn:        40,chapter:        27,versenumber:        43,verse:"他倚靠　神，　神若喜悦他，现在可以救他；因为他曾说：‘我是　神的儿子。’”"},
{volumn:        40,chapter:        27,versenumber:        44,verse:"那和他同钉的强盗也是这样地讥诮他。"},
{volumn:        40,chapter:        27,versenumber:        45,verse:"从午正到申初，遍地都黑暗了。"},
{volumn:        40,chapter:        27,versenumber:        46,verse:"约在申初，耶稣大声喊着说：“以利！以利！拉马撒巴各大尼？”就是说：“我的　神！我的　神！为什么离弃我？”"},
{volumn:        40,chapter:        27,versenumber:        47,verse:"站在那里的人，有的听见就说：“这个人呼叫以利亚呢！”"},
{volumn:        40,chapter:        27,versenumber:        48,verse:"内中有一个人赶紧跑去，拿海绒蘸满了醋，绑在苇子上，送给他喝。"},
{volumn:        40,chapter:        27,versenumber:        49,verse:"其余的人说：“且等着，看以利亚来救他不来。”"},
{volumn:        40,chapter:        27,versenumber:        50,verse:"耶稣又大声喊叫，气就断了。"},
{volumn:        40,chapter:        27,versenumber:        51,verse:"忽然，殿里的幔子从上到下裂为两半，地也震动，磐石也崩裂，"},
{volumn:        40,chapter:        27,versenumber:        52,verse:"坟墓也开了，已睡圣徒的身体多有起来的。"},
{volumn:        40,chapter:        27,versenumber:        53,verse:"到耶稣复活以后，他们从坟墓里出来，进了圣城，向许多人显现。"},
{volumn:        40,chapter:        27,versenumber:        54,verse:"百夫长和一同看守耶稣的人看见地震并所经历的事，就极其害怕，说：“这真是　神的儿子了！”"},
{volumn:        40,chapter:        27,versenumber:        55,verse:"有好些妇女在那里，远远地观看；她们是从加利利跟随耶稣来服侍他的。"},
{volumn:        40,chapter:        27,versenumber:        56,verse:"内中有抹大拉的马利亚，又有雅各和约西的母亲马利亚，并有西庇太两个儿子的母亲。"},
{volumn:        40,chapter:        27,versenumber:        57,verse:"到了晚上，有一个财主，名叫约瑟，是亚利马太来的，他也是耶稣的门徒。"},
{volumn:        40,chapter:        27,versenumber:        58,verse:"这人去见彼拉多，求耶稣的身体；彼拉多就吩咐给他。"},
{volumn:        40,chapter:        27,versenumber:        59,verse:"约瑟取了身体，用干净细麻布裹好，"},
{volumn:        40,chapter:        27,versenumber:        60,verse:"安放在自己的新坟墓里，就是他凿在磐石里的。他又把大石头滚到墓门口，就去了。"},
{volumn:        40,chapter:        27,versenumber:        61,verse:"有抹大拉的马利亚和那个马利亚在那里，对着坟墓坐着。"},
{volumn:        40,chapter:        27,versenumber:        62,verse:"次日，就是预备日的第二天，祭司长和法利赛人聚集来见彼拉多，说："},
{volumn:        40,chapter:        27,versenumber:        63,verse:"“大人，我们记得那诱惑人的还活着的时候曾说：‘三日后我要复活。’"},
{volumn:        40,chapter:        27,versenumber:        64,verse:"因此，请吩咐人将坟墓把守妥当，直到第三日，恐怕他的门徒来，把他偷了去，就告诉百姓说：‘他从死里复活了。’这样，那后来的迷惑比先前的更利害了！”"},
{volumn:        40,chapter:        27,versenumber:        65,verse:"彼拉多说：“你们有看守的兵，去吧！尽你们所能的把守妥当。”"},
{volumn:        40,chapter:        27,versenumber:        66,verse:"他们就带着看守的兵同去，封了石头，将坟墓把守妥当。"},
{volumn:        40,chapter:        28,versenumber:         1,verse:"安息日将尽，七日的头一日，天快亮的时候，抹大拉的马利亚和那个马利亚来看坟墓。"},
{volumn:        40,chapter:        28,versenumber:         2,verse:"忽然，地大震动；因为有主的使者从天上下来，把石头滚开，坐在上面。"},
{volumn:        40,chapter:        28,versenumber:         3,verse:"他的相貌如同闪电，衣服洁白如雪。"},
{volumn:        40,chapter:        28,versenumber:         4,verse:"看守的人就因他吓得浑身乱战，甚至和死人一样。"},
{volumn:        40,chapter:        28,versenumber:         5,verse:"天使对妇女说：“不要害怕！我知道你们是寻找那钉十字架的耶稣。"},
{volumn:        40,chapter:        28,versenumber:         6,verse:"他不在这里，照他所说的，已经复活了。你们来看安放主的地方。"},
{volumn:        40,chapter:        28,versenumber:         7,verse:"快去告诉他的门徒，说他从死里复活了，并且在你们以先往加利利去，在那里你们要见他。看哪，我已经告诉你们了。”"},
{volumn:        40,chapter:        28,versenumber:         8,verse:"妇女们就急忙离开坟墓，又害怕，又大大地欢喜，跑去要报给他的门徒。"},
{volumn:        40,chapter:        28,versenumber:         9,verse:"忽然，耶稣遇见她们，说：“愿你们平安！”她们就上前抱住他的脚拜他。"},
{volumn:        40,chapter:        28,versenumber:        10,verse:"耶稣对她们说：“不要害怕！你们去告诉我的弟兄，叫他们往加利利去，在那里必见我。”"},
{volumn:        40,chapter:        28,versenumber:        11,verse:"她们去的时候，看守的兵有几个进城去，将所经历的事都报给祭司长。"},
{volumn:        40,chapter:        28,versenumber:        12,verse:"祭司长和长老聚集商议，就拿许多银钱给兵丁，说："},
{volumn:        40,chapter:        28,versenumber:        13,verse:"“你们要这样说：‘夜间我们睡觉的时候，他的门徒来，把他偷去了。’"},
{volumn:        40,chapter:        28,versenumber:        14,verse:"倘若这话被巡抚听见，有我们劝他，保你们无事。”"},
{volumn:        40,chapter:        28,versenumber:        15,verse:"兵丁受了银钱，就照所嘱咐他们的去行。这话就传说在犹太人中间，直到今日。"},
{volumn:        40,chapter:        28,versenumber:        16,verse:"十一个门徒往加利利去，到了耶稣约定的山上。"},
{volumn:        40,chapter:        28,versenumber:        17,verse:"他们见了耶稣就拜他，然而还有人疑惑。"},
{volumn:        40,chapter:        28,versenumber:        18,verse:"耶稣进前来，对他们说：“天上地下所有的权柄都赐给我了。"},
{volumn:        40,chapter:        28,versenumber:        19,verse:"所以，你们要去，使万民作我的门徒，奉父、子、圣灵的名给他们施洗（或译：给他们施洗，归于父、子、圣灵的名）。"},
{volumn:        40,chapter:        28,versenumber:        20,verse:"凡我所吩咐你们的，都教训他们遵守，我就常与你们同在，直到世界的末了。”"},
{volumn:        41,chapter:         1,versenumber:         1,verse:"神的儿子，耶稣基督福音的起头。"},
{volumn:        41,chapter:         1,versenumber:         2,verse:"正如先知以赛亚（有古卷没有以赛亚三个字）书上记着说：“看哪，我要差遣我的使者在你前面，预备道路。"},
{volumn:        41,chapter:         1,versenumber:         3,verse:"在旷野有人声喊着说：‘预备主的道，修直他的路。’”"},
{volumn:        41,chapter:         1,versenumber:         4,verse:"照这话，约翰来了，在旷野施洗，传悔改的洗礼，使罪得赦。"},
{volumn:        41,chapter:         1,versenumber:         5,verse:"犹太全地和耶路撒冷的人都出去到约翰那里，承认他们的罪，在约旦河里受他的洗。"},
{volumn:        41,chapter:         1,versenumber:         6,verse:"约翰穿骆驼毛的衣服，腰束皮带，吃的是蝗虫、野蜜。"},
{volumn:        41,chapter:         1,versenumber:         7,verse:"他传道说：“有一位在我以后来的，能力比我更大，我就是弯腰给他解鞋带也是不配的。"},
{volumn:        41,chapter:         1,versenumber:         8,verse:"我是用水给你们施洗，他却要用圣灵给你们施洗。”"},
{volumn:        41,chapter:         1,versenumber:         9,verse:"那时，耶稣从加利利的拿撒勒来，在约旦河里受了约翰的洗。"},
{volumn:        41,chapter:         1,versenumber:        10,verse:"他从水里一上来，就看见天裂开了，圣灵仿佛鸽子，降在他身上。"},
{volumn:        41,chapter:         1,versenumber:        11,verse:"又有声音从天上来，说：“你是我的爱子，我喜悦你。”"},
{volumn:        41,chapter:         1,versenumber:        12,verse:"圣灵就把耶稣催到旷野里去。"},
{volumn:        41,chapter:         1,versenumber:        13,verse:"他在旷野四十天，受撒但的试探，并与野兽同在一处，且有天使来伺候他。"},
{volumn:        41,chapter:         1,versenumber:        14,verse:"约翰下监以后，耶稣来到加利利，宣传　神的福音，"},
{volumn:        41,chapter:         1,versenumber:        15,verse:"说：“日期满了，　神的国近了。你们当悔改，信福音！”"},
{volumn:        41,chapter:         1,versenumber:        16,verse:"耶稣顺着加利利的海边走，看见西门和西门的兄弟安得烈在海里撒网；他们本是打鱼的。"},
{volumn:        41,chapter:         1,versenumber:        17,verse:"耶稣对他们说：“来跟从我，我要叫你们得人如得鱼一样。”"},
{volumn:        41,chapter:         1,versenumber:        18,verse:"他们就立刻舍了网，跟从了他。"},
{volumn:        41,chapter:         1,versenumber:        19,verse:"耶稣稍往前走，又见西庇太的儿子雅各和雅各的兄弟约翰在船上补网。"},
{volumn:        41,chapter:         1,versenumber:        20,verse:"耶稣随即招呼他们，他们就把父亲西庇太和雇工人留在船上，跟从耶稣去了。"},
{volumn:        41,chapter:         1,versenumber:        21,verse:"到了迦百农，耶稣就在安息日进了会堂教训人。"},
{volumn:        41,chapter:         1,versenumber:        22,verse:"众人很希奇他的教训；因为他教训他们，正像有权柄的人，不像文士。"},
{volumn:        41,chapter:         1,versenumber:        23,verse:"在会堂里，有一个人被污鬼附着。他喊叫说："},
{volumn:        41,chapter:         1,versenumber:        24,verse:"“拿撒勒人耶稣，我们与你有什么相干？你来灭我们吗？我知道你是谁，乃是　神的圣者。”"},
{volumn:        41,chapter:         1,versenumber:        25,verse:"耶稣责备他说：“不要作声！从这人身上出来吧。”"},
{volumn:        41,chapter:         1,versenumber:        26,verse:"污鬼叫那人抽了一阵风，大声喊叫，就出来了。"},
{volumn:        41,chapter:         1,versenumber:        27,verse:"众人都惊讶，以致彼此对问说：“这是什么事？是个新道理啊！他用权柄吩咐污鬼，连污鬼也听从了他。”"},
{volumn:        41,chapter:         1,versenumber:        28,verse:"耶稣的名声就传遍了加利利的四方。"},
{volumn:        41,chapter:         1,versenumber:        29,verse:"他们一出会堂，就同着雅各、约翰，进了西门和安得烈的家。"},
{volumn:        41,chapter:         1,versenumber:        30,verse:"西门的岳母正害热病躺着，就有人告诉耶稣。"},
{volumn:        41,chapter:         1,versenumber:        31,verse:"耶稣进前拉着她的手，扶她起来，热就退了，她就服侍他们。"},
{volumn:        41,chapter:         1,versenumber:        32,verse:"天晚日落的时候，有人带着一切害病的，和被鬼附的，来到耶稣跟前。"},
{volumn:        41,chapter:         1,versenumber:        33,verse:"合城的人都聚集在门前。"},
{volumn:        41,chapter:         1,versenumber:        34,verse:"耶稣治好了许多害各样病的人，又赶出许多鬼，不许鬼说话，因为鬼认识他。"},
{volumn:        41,chapter:         1,versenumber:        35,verse:"次日早晨，天未亮的时候，耶稣起来，到旷野地方去，在那里祷告。"},
{volumn:        41,chapter:         1,versenumber:        36,verse:"西门和同伴追了他去，"},
{volumn:        41,chapter:         1,versenumber:        37,verse:"遇见了就对他说：“众人都找你。”"},
{volumn:        41,chapter:         1,versenumber:        38,verse:"耶稣对他们说：“我们可以往别处去，到邻近的乡村，我也好在那里传道，因为我是为这事出来的。”"},
{volumn:        41,chapter:         1,versenumber:        39,verse:"于是在加利利全地，进了会堂，传道，赶鬼。"},
{volumn:        41,chapter:         1,versenumber:        40,verse:"有一个长大麻风的来求耶稣，向他跪下，说：“你若肯，必能叫我洁净了。”"},
{volumn:        41,chapter:         1,versenumber:        41,verse:"耶稣动了慈心，就伸手摸他，说：“我肯，你洁净了吧！”"},
{volumn:        41,chapter:         1,versenumber:        42,verse:"大麻风即时离开他，他就洁净了。"},
{volumn:        41,chapter:         1,versenumber:        43,verse:"耶稣严严地嘱咐他，就打发他走，"},
{volumn:        41,chapter:         1,versenumber:        44,verse:"对他说：“你要谨慎，什么话都不可告诉人，只要去把身体给祭司察看，又因为你洁净了，献上摩西所吩咐的礼物，对众人作证据。”"},
{volumn:        41,chapter:         1,versenumber:        45,verse:"那人出去，倒说许多的话，把这件事传扬开了，叫耶稣以后不得再明明地进城，只好在外边旷野地方。人从各处都就了他来。"},
{volumn:        41,chapter:         2,versenumber:         1,verse:"过了些日子，耶稣又进了迦百农。人听见他在房子里，"},
{volumn:        41,chapter:         2,versenumber:         2,verse:"就有许多人聚集，甚至连门前都没有空地；耶稣就对他们讲道。"},
{volumn:        41,chapter:         2,versenumber:         3,verse:"有人带着一个瘫子来见耶稣，是用四个人抬来的；"},
{volumn:        41,chapter:         2,versenumber:         4,verse:"因为人多，不得近前，就把耶稣所在的房子，拆了房顶，既拆通了，就把瘫子连所躺卧的褥子都缒下来。"},
{volumn:        41,chapter:         2,versenumber:         5,verse:"耶稣见他们的信心，就对瘫子说：“小子，你的罪赦了。”"},
{volumn:        41,chapter:         2,versenumber:         6,verse:"有几个文士坐在那里，心里议论，说："},
{volumn:        41,chapter:         2,versenumber:         7,verse:"“这个人为什么这样说呢？他说僭妄的话了。除了　神以外，谁能赦罪呢？”"},
{volumn:        41,chapter:         2,versenumber:         8,verse:"耶稣心中知道他们心里这样议论，就说：“你们心里为什么这样议论呢？"},
{volumn:        41,chapter:         2,versenumber:         9,verse:"或对瘫子说‘你的罪赦了’，或说‘起来！拿你的褥子行走’，哪一样容易呢？"},
{volumn:        41,chapter:         2,versenumber:        10,verse:"但要叫你们知道，人子在地上有赦罪的权柄。”就对瘫子说："},
{volumn:        41,chapter:         2,versenumber:        11,verse:"“我吩咐你，起来！拿你的褥子回家去吧。”"},
{volumn:        41,chapter:         2,versenumber:        12,verse:"那人就起来，立刻拿着褥子，当众人面前出去了，以致众人都惊奇，归荣耀与　神，说：“我们从来没有见过这样的事！”"},
{volumn:        41,chapter:         2,versenumber:        13,verse:"耶稣又出到海边去，众人都就了他来，他便教训他们。"},
{volumn:        41,chapter:         2,versenumber:        14,verse:"耶稣经过的时候，看见亚勒腓的儿子利未坐在税关上，就对他说：“你跟从我来。”他就起来，跟从了耶稣。"},
{volumn:        41,chapter:         2,versenumber:        15,verse:"耶稣在利未家里坐席的时候，有好些税吏和罪人与耶稣并门徒一同坐席；因为这样的人多，他们也跟随耶稣。"},
{volumn:        41,chapter:         2,versenumber:        16,verse:"法利赛人中的文士（有古卷：文士和法利赛人）看见耶稣和罪人并税吏一同吃饭，就对他门徒说：“他和税吏并罪人一同吃喝吗？”"},
{volumn:        41,chapter:         2,versenumber:        17,verse:"耶稣听见，就对他们说：“康健的人用不着医生，有病的人才用得着。我来本不是召义人，乃是召罪人。”"},
{volumn:        41,chapter:         2,versenumber:        18,verse:"当下，约翰的门徒和法利赛人禁食。他们来问耶稣说：“约翰的门徒和法利赛人的门徒禁食，你的门徒倒不禁食，这是为什么呢？”"},
{volumn:        41,chapter:         2,versenumber:        19,verse:"耶稣对他们说：“新郎和陪伴之人同在的时候，陪伴之人岂能禁食呢？新郎还同在，他们不能禁食。"},
{volumn:        41,chapter:         2,versenumber:        20,verse:"但日子将到，新郎要离开他们，那日他们就要禁食。"},
{volumn:        41,chapter:         2,versenumber:        21,verse:"没有人把新布缝在旧衣服上，恐怕所补上的新布带坏了旧衣服，破的就更大了。"},
{volumn:        41,chapter:         2,versenumber:        22,verse:"也没有人把新酒装在旧皮袋里，恐怕酒把皮袋裂开，酒和皮袋就都坏了；惟把新酒装在新皮袋里。”"},
{volumn:        41,chapter:         2,versenumber:        23,verse:"耶稣当安息日从麦地经过。他门徒行路的时候，掐了麦穗。"},
{volumn:        41,chapter:         2,versenumber:        24,verse:"法利赛人对耶稣说：“看哪，他们在安息日为什么做不可做的事呢？”"},
{volumn:        41,chapter:         2,versenumber:        25,verse:"耶稣对他们说：“经上记着大卫和跟从他的人缺乏饥饿之时所做的事，你们没有念过吗？"},
{volumn:        41,chapter:         2,versenumber:        26,verse:"他当亚比亚他作大祭司的时候，怎么进了　神的殿，吃了陈设饼，又给跟从他的人吃。这饼除了祭司以外，人都不可吃。”"},
{volumn:        41,chapter:         2,versenumber:        27,verse:"又对他们说：“安息日是为人设立的，人不是为安息日设立的。"},
{volumn:        41,chapter:         2,versenumber:        28,verse:"所以，人子也是安息日的主。”"},
{volumn:        41,chapter:         3,versenumber:         1,verse:"耶稣又进了会堂，在那里有一个人枯干了一只手。"},
{volumn:        41,chapter:         3,versenumber:         2,verse:"众人窥探耶稣，在安息日医治不医治，意思是要控告耶稣。"},
{volumn:        41,chapter:         3,versenumber:         3,verse:"耶稣对那枯干一只手的人说：“起来，站在当中。”"},
{volumn:        41,chapter:         3,versenumber:         4,verse:"又问众人说：“在安息日行善行恶，救命害命，哪样是可以的呢？”他们都不作声。"},
{volumn:        41,chapter:         3,versenumber:         5,verse:"耶稣怒目周围看他们，忧愁他们的心刚硬，就对那人说：“伸出手来！”他把手一伸，手就复了原。"},
{volumn:        41,chapter:         3,versenumber:         6,verse:"法利赛人出去，同希律一党的人商议怎样可以除灭耶稣。"},
{volumn:        41,chapter:         3,versenumber:         7,verse:"耶稣和门徒退到海边去，有许多人从加利利跟随他。"},
{volumn:        41,chapter:         3,versenumber:         8,verse:"还有许多人听见他所做的大事，就从犹太、耶路撒冷、以土买、约旦河外，并推罗、西顿的四方来到他那里。"},
{volumn:        41,chapter:         3,versenumber:         9,verse:"他因为人多，就吩咐门徒叫一只小船伺候着，免得众人拥挤他。"},
{volumn:        41,chapter:         3,versenumber:        10,verse:"他治好了许多人，所以凡有灾病的，都挤进来要摸他。"},
{volumn:        41,chapter:         3,versenumber:        11,verse:"污鬼无论何时看见他，就俯伏在他面前，喊着说：“你是　神的儿子。”"},
{volumn:        41,chapter:         3,versenumber:        12,verse:"耶稣再三地嘱咐他们，不要把他显露出来。"},
{volumn:        41,chapter:         3,versenumber:        13,verse:"耶稣上了山，随自己的意思叫人来；他们便来到他那里。"},
{volumn:        41,chapter:         3,versenumber:        14,verse:"他就设立十二个人，要他们常和自己同在，也要差他们去传道，"},
{volumn:        41,chapter:         3,versenumber:        15,verse:"并给他们权柄赶鬼。"},
{volumn:        41,chapter:         3,versenumber:        16,verse:"这十二个人有西门（耶稣又给他起名叫彼得），"},
{volumn:        41,chapter:         3,versenumber:        17,verse:"还有西庇太的儿子雅各和雅各的兄弟约翰（又给这两个人起名叫半尼其，就是雷子的意思），"},
{volumn:        41,chapter:         3,versenumber:        18,verse:"又有安得烈、腓力、巴多罗买、马太、多马、亚勒腓的儿子雅各，和达太，并奋锐党的西门，"},
{volumn:        41,chapter:         3,versenumber:        19,verse:"还有卖耶稣的加略人犹大。"},
{volumn:        41,chapter:         3,versenumber:        20,verse:"耶稣进了一个屋子，众人又聚集，甚至他连饭也顾不得吃。"},
{volumn:        41,chapter:         3,versenumber:        21,verse:"耶稣的亲属听见，就出来要拉住他，因为他们说他癫狂了。"},
{volumn:        41,chapter:         3,versenumber:        22,verse:"从耶路撒冷下来的文士说：“他是被别西卜附着”；又说：“他是靠着鬼王赶鬼。”"},
{volumn:        41,chapter:         3,versenumber:        23,verse:"耶稣叫他们来，用比喻对他们说：“撒但怎能赶出撒但呢？"},
{volumn:        41,chapter:         3,versenumber:        24,verse:"若一国自相纷争，那国就站立不住；"},
{volumn:        41,chapter:         3,versenumber:        25,verse:"若一家自相纷争，那家就站立不住。"},
{volumn:        41,chapter:         3,versenumber:        26,verse:"若撒但自相攻打纷争，他就站立不住，必要灭亡。"},
{volumn:        41,chapter:         3,versenumber:        27,verse:"没有人能进壮士家里，抢夺他的家具；必先捆住那壮士，才可以抢夺他的家。"},
{volumn:        41,chapter:         3,versenumber:        28,verse:"我实在告诉你们，世人一切的罪和一切亵渎的话都可得赦免；"},
{volumn:        41,chapter:         3,versenumber:        29,verse:"凡亵渎圣灵的，却永不得赦免，乃要担当永远的罪。”"},
{volumn:        41,chapter:         3,versenumber:        30,verse:"这话是因为他们说：“他是被污鬼附着的。”"},
{volumn:        41,chapter:         3,versenumber:        31,verse:"当下，耶稣的母亲和弟兄来，站在外边，打发人去叫他。"},
{volumn:        41,chapter:         3,versenumber:        32,verse:"有许多人在耶稣周围坐着，他们就告诉他说：“看哪，你母亲和你弟兄在外边找你。”"},
{volumn:        41,chapter:         3,versenumber:        33,verse:"耶稣回答说：“谁是我的母亲？谁是我的弟兄？”"},
{volumn:        41,chapter:         3,versenumber:        34,verse:"就四面观看那周围坐着的人，说：“看哪，我的母亲，我的弟兄。"},
{volumn:        41,chapter:         3,versenumber:        35,verse:"凡遵行　神旨意的人就是我的弟兄姐妹和母亲了。”"},
{volumn:        41,chapter:         4,versenumber:         1,verse:"耶稣又在海边教训人。有许多人到他那里聚集，他只得上船坐下。船在海里，众人都靠近海，站在岸上。"},
{volumn:        41,chapter:         4,versenumber:         2,verse:"耶稣就用比喻教训他们许多道理。在教训之间，对他们说："},
{volumn:        41,chapter:         4,versenumber:         3,verse:"“你们听啊！有一个撒种的出去撒种。"},
{volumn:        41,chapter:         4,versenumber:         4,verse:"撒的时候，有落在路旁的，飞鸟来吃尽了；"},
{volumn:        41,chapter:         4,versenumber:         5,verse:"有落在土浅石头地上的，土既不深，发苗最快，"},
{volumn:        41,chapter:         4,versenumber:         6,verse:"日头出来一晒，因为没有根，就枯干了；"},
{volumn:        41,chapter:         4,versenumber:         7,verse:"有落在荆棘里的，荆棘长起来，把它挤住了，就不结实；"},
{volumn:        41,chapter:         4,versenumber:         8,verse:"又有落在好土里的，就发生长大，结实有三十倍的，有六十倍的，有一百倍的”；"},
{volumn:        41,chapter:         4,versenumber:         9,verse:"又说：“有耳可听的，就应当听！”"},
{volumn:        41,chapter:         4,versenumber:        10,verse:"无人的时候，跟随耶稣的人和十二个门徒问他这比喻的意思。"},
{volumn:        41,chapter:         4,versenumber:        11,verse:"耶稣对他们说：“　神国的奥秘只叫你们知道，若是对外人讲，凡事就用比喻，"},
{volumn:        41,chapter:         4,versenumber:        12,verse:"叫他们看是看见，却不晓得；听是听见，却不明白；恐怕他们回转过来，就得赦免。”"},
{volumn:        41,chapter:         4,versenumber:        13,verse:"又对他们说：“你们不明白这比喻吗？这样怎能明白一切的比喻呢？"},
{volumn:        41,chapter:         4,versenumber:        14,verse:"撒种之人所撒的就是道。"},
{volumn:        41,chapter:         4,versenumber:        15,verse:"那撒在路旁的，就是人听了道，撒但立刻来，把撒在他心里的道夺了去。"},
{volumn:        41,chapter:         4,versenumber:        16,verse:"那撒在石头地上的，就是人听了道，立刻欢喜领受，"},
{volumn:        41,chapter:         4,versenumber:        17,verse:"但他心里没有根，不过是暂时的，及至为道遭了患难，或是受了逼迫，立刻就跌倒了。"},
{volumn:        41,chapter:         4,versenumber:        18,verse:"还有那撒在荆棘里的，就是人听了道，"},
{volumn:        41,chapter:         4,versenumber:        19,verse:"后来有世上的思虑、钱财的迷惑，和别样的私欲进来，把道挤住了，就不能结实。"},
{volumn:        41,chapter:         4,versenumber:        20,verse:"那撒在好地上的，就是人听道，又领受，并且结实，有三十倍的，有六十倍的，有一百倍的。”"},
{volumn:        41,chapter:         4,versenumber:        21,verse:"耶稣又对他们说：“人拿灯来，岂是要放在斗底下，床底下，不放在灯台上吗？"},
{volumn:        41,chapter:         4,versenumber:        22,verse:"因为掩藏的事，没有不显出来的；隐瞒的事，没有不露出来的。"},
{volumn:        41,chapter:         4,versenumber:        23,verse:"有耳可听的，就应当听！”"},
{volumn:        41,chapter:         4,versenumber:        24,verse:"又说：“你们所听的要留心。你们用什么量器量给人，也必用什么量器量给你们，并且要多给你们。"},
{volumn:        41,chapter:         4,versenumber:        25,verse:"因为有的，还要给他；没有的，连他所有的也要夺去。”"},
{volumn:        41,chapter:         4,versenumber:        26,verse:"又说：“　神的国如同人把种撒在地上。"},
{volumn:        41,chapter:         4,versenumber:        27,verse:"黑夜睡觉，白日起来，这种就发芽渐长，那人却不晓得如何这样。"},
{volumn:        41,chapter:         4,versenumber:        28,verse:"地生五谷是出于自然的：先发苗，后长穗，再后穗上结成饱满的子粒；"},
{volumn:        41,chapter:         4,versenumber:        29,verse:"谷既熟了，就用镰刀去割，因为收成的时候到了。”"},
{volumn:        41,chapter:         4,versenumber:        30,verse:"又说：“　神的国，我们可用什么比较呢？可用什么比喻表明呢？"},
{volumn:        41,chapter:         4,versenumber:        31,verse:"好像一粒芥菜种，种在地里的时候，虽比地上的百种都小，"},
{volumn:        41,chapter:         4,versenumber:        32,verse:"但种上以后，就长起来，比各样的菜都大，又长出大枝来，甚至天上的飞鸟可以宿在它的荫下。”"},
{volumn:        41,chapter:         4,versenumber:        33,verse:"耶稣用许多这样的比喻，照他们所能听的，对他们讲道。"},
{volumn:        41,chapter:         4,versenumber:        34,verse:"若不用比喻，就不对他们讲；没有人的时候，就把一切的道讲给门徒听。"},
{volumn:        41,chapter:         4,versenumber:        35,verse:"当那天晚上，耶稣对门徒说：“我们渡到那边去吧。”"},
{volumn:        41,chapter:         4,versenumber:        36,verse:"门徒离开众人，耶稣仍在船上，他们就把他一同带去；也有别的船和他同行。"},
{volumn:        41,chapter:         4,versenumber:        37,verse:"忽然起了暴风，波浪打入船内，甚至船要满了水。"},
{volumn:        41,chapter:         4,versenumber:        38,verse:"耶稣在船尾上，枕着枕头睡觉。门徒叫醒了他，说：“夫子！我们丧命，你不顾吗？”"},
{volumn:        41,chapter:         4,versenumber:        39,verse:"耶稣醒了，斥责风，向海说：“住了吧！静了吧！”风就止住，大大地平静了。"},
{volumn:        41,chapter:         4,versenumber:        40,verse:"耶稣对他们说：“为什么胆怯？你们还没有信心吗？”"},
{volumn:        41,chapter:         4,versenumber:        41,verse:"他们就大大地惧怕，彼此说：“这到底是谁，连风和海也听从他了。”"},
{volumn:        41,chapter:         5,versenumber:         1,verse:"他们来到海那边格拉森人的地方。"},
{volumn:        41,chapter:         5,versenumber:         2,verse:"耶稣一下船，就有一个被污鬼附着的人从坟茔里出来迎着他。"},
{volumn:        41,chapter:         5,versenumber:         3,verse:"那人常住在坟茔里，没有人能捆住他，就是用铁链也不能；"},
{volumn:        41,chapter:         5,versenumber:         4,verse:"因为人屡次用脚镣和铁链捆锁他，铁链竟被他挣断了，脚镣也被他弄碎了；总没有人能制伏他。"},
{volumn:        41,chapter:         5,versenumber:         5,verse:"他昼夜常在坟茔里和山中喊叫，又用石头砍自己。"},
{volumn:        41,chapter:         5,versenumber:         6,verse:"他远远地看见耶稣，就跑过去拜他，"},
{volumn:        41,chapter:         5,versenumber:         7,verse:"大声呼叫说：“至高　神的儿子耶稣，我与你有什么相干？我指着　神恳求你，不要叫我受苦！”"},
{volumn:        41,chapter:         5,versenumber:         8,verse:"是因耶稣曾吩咐他说：“污鬼啊，从这人身上出来吧！”"},
{volumn:        41,chapter:         5,versenumber:         9,verse:"耶稣问他说：“你名叫什么？”回答说：“我名叫‘群’，因为我们多的缘故”；"},
{volumn:        41,chapter:         5,versenumber:        10,verse:"就再三地求耶稣，不要叫他们离开那地方。"},
{volumn:        41,chapter:         5,versenumber:        11,verse:"在那里山坡上，有一大群猪吃食；"},
{volumn:        41,chapter:         5,versenumber:        12,verse:"鬼就央求耶稣说：“求你打发我们往猪群里，附着猪去。”"},
{volumn:        41,chapter:         5,versenumber:        13,verse:"耶稣准了他们，污鬼就出来，进入猪里去。于是那群猪闯下山崖，投在海里，淹死了。猪的数目约有二千。"},
{volumn:        41,chapter:         5,versenumber:        14,verse:"放猪的就逃跑了，去告诉城里和乡下的人。众人就来，要看是什么事。"},
{volumn:        41,chapter:         5,versenumber:        15,verse:"他们来到耶稣那里，看见那被鬼附着的人，就是从前被群鬼所附的，坐着，穿上衣服，心里明白过来，他们就害怕。"},
{volumn:        41,chapter:         5,versenumber:        16,verse:"看见这事的，便将鬼附之人所遇见的和那群猪的事都告诉了众人；"},
{volumn:        41,chapter:         5,versenumber:        17,verse:"众人就央求耶稣离开他们的境界。"},
{volumn:        41,chapter:         5,versenumber:        18,verse:"耶稣上船的时候，那从前被鬼附着的人恳求和耶稣同在。"},
{volumn:        41,chapter:         5,versenumber:        19,verse:"耶稣不许，却对他说：“你回家去，到你的亲属那里，将主为你所做的是何等大的事，是怎样怜悯你，都告诉他们。”"},
{volumn:        41,chapter:         5,versenumber:        20,verse:"那人就走了，在低加波利传扬耶稣为他做了何等大的事，众人就都希奇。"},
{volumn:        41,chapter:         5,versenumber:        21,verse:"耶稣坐船又渡到那边去，就有许多人到他那里聚集；他正在海边上。"},
{volumn:        41,chapter:         5,versenumber:        22,verse:"有一个管会堂的人，名叫睚鲁，来见耶稣，就俯伏在他脚前，"},
{volumn:        41,chapter:         5,versenumber:        23,verse:"再三地求他，说：“我的小女儿快要死了，求你去按手在她身上，使她痊愈，得以活了。”"},
{volumn:        41,chapter:         5,versenumber:        24,verse:"耶稣就和他同去。有许多人跟随拥挤他。"},
{volumn:        41,chapter:         5,versenumber:        25,verse:"有一个女人，患了十二年的血漏，"},
{volumn:        41,chapter:         5,versenumber:        26,verse:"在好些医生手里受了许多的苦，又花尽了她所有的，一点也不见好，病势反倒更重了。"},
{volumn:        41,chapter:         5,versenumber:        27,verse:"她听见耶稣的事，就从后头来，杂在众人中间，摸耶稣的衣裳，"},
{volumn:        41,chapter:         5,versenumber:        28,verse:"意思说：“我只摸他的衣裳，就必痊愈。”"},
{volumn:        41,chapter:         5,versenumber:        29,verse:"于是她血漏的源头立刻干了；她便觉得身上的灾病好了。"},
{volumn:        41,chapter:         5,versenumber:        30,verse:"耶稣顿时心里觉得有能力从自己身上出去，就在众人中间转过来，说：“谁摸我的衣裳？”"},
{volumn:        41,chapter:         5,versenumber:        31,verse:"门徒对他说：“你看众人拥挤你，还说‘谁摸我’吗？”"},
{volumn:        41,chapter:         5,versenumber:        32,verse:"耶稣周围观看，要见做这事的女人。"},
{volumn:        41,chapter:         5,versenumber:        33,verse:"那女人知道在自己身上所成的事，就恐惧战兢，来俯伏在耶稣跟前，将实情全告诉他。"},
{volumn:        41,chapter:         5,versenumber:        34,verse:"耶稣对她说：“女儿，你的信救了你，平平安安地回去吧！你的灾病痊愈了。”"},
{volumn:        41,chapter:         5,versenumber:        35,verse:"还说话的时候，有人从管会堂的家里来，说：“你的女儿死了，何必还劳动先生呢？”"},
{volumn:        41,chapter:         5,versenumber:        36,verse:"耶稣听见所说的话，就对管会堂的说：“不要怕，只要信！”"},
{volumn:        41,chapter:         5,versenumber:        37,verse:"于是带着彼得、雅各，和雅各的兄弟约翰同去，不许别人跟随他。"},
{volumn:        41,chapter:         5,versenumber:        38,verse:"他们来到管会堂的家里；耶稣看见那里乱嚷，并有人大大地哭泣哀号，"},
{volumn:        41,chapter:         5,versenumber:        39,verse:"进到里面，就对他们说：“为什么乱嚷哭泣呢？孩子不是死了，是睡着了。”"},
{volumn:        41,chapter:         5,versenumber:        40,verse:"他们就嗤笑耶稣。耶稣把他们都撵出去，就带着孩子的父母和跟随的人进了孩子所在的地方，"},
{volumn:        41,chapter:         5,versenumber:        41,verse:"就拉着孩子的手，对她说：“大利大，古米！”（翻出来就是说：“闺女，我吩咐你起来！”）"},
{volumn:        41,chapter:         5,versenumber:        42,verse:"那闺女立时起来走。他们就大大地惊奇；闺女已经十二岁了。"},
{volumn:        41,chapter:         5,versenumber:        43,verse:"耶稣切切地嘱咐他们，不要叫人知道这事，又吩咐给她东西吃。"},
{volumn:        41,chapter:         6,versenumber:         1,verse:"耶稣离开那里，来到自己的家乡；门徒也跟从他。"},
{volumn:        41,chapter:         6,versenumber:         2,verse:"到了安息日，他在会堂里教训人。众人听见，就甚希奇，说：“这人从哪里有这些事呢？所赐给他的是什么智慧？他手所做的是何等的异能呢？"},
{volumn:        41,chapter:         6,versenumber:         3,verse:"这不是那木匠吗？不是马利亚的儿子雅各、约西、犹大、西门的长兄吗？他妹妹们不也是在我们这里吗？”他们就厌弃他（厌弃他：原文是因他跌倒）。"},
{volumn:        41,chapter:         6,versenumber:         4,verse:"耶稣对他们说：“大凡先知，除了本地、亲属、本家之外，没有不被人尊敬的。”"},
{volumn:        41,chapter:         6,versenumber:         5,verse:"耶稣就在那里不得行什么异能，不过按手在几个病人身上，治好他们。"},
{volumn:        41,chapter:         6,versenumber:         6,verse:"他也诧异他们不信，就往周围乡村教训人去了。"},
{volumn:        41,chapter:         6,versenumber:         7,verse:"耶稣叫了十二个门徒来，差遣他们两个两个地出去，也赐给他们权柄，制伏污鬼；"},
{volumn:        41,chapter:         6,versenumber:         8,verse:"并且嘱咐他们：“行路的时候不要带食物和口袋，腰袋里也不要带钱，除了拐杖以外，什么都不要带；"},
{volumn:        41,chapter:         6,versenumber:         9,verse:"只要穿鞋，也不要穿两件褂子”；"},
{volumn:        41,chapter:         6,versenumber:        10,verse:"又对他们说：“你们无论到何处，进了人的家，就住在那里，直到离开那地方。"},
{volumn:        41,chapter:         6,versenumber:        11,verse:"何处的人不接待你们，不听你们，你们离开那里的时候，就把脚上的尘土跺下去，对他们作见证。”"},
{volumn:        41,chapter:         6,versenumber:        12,verse:"门徒就出去传道，叫人悔改，"},
{volumn:        41,chapter:         6,versenumber:        13,verse:"又赶出许多的鬼，用油抹了许多病人，治好他们。"},
{volumn:        41,chapter:         6,versenumber:        14,verse:"耶稣的名声传扬出来。希律王听见了，就说：“施洗的约翰从死里复活了，所以这些异能由他里面发出来。”"},
{volumn:        41,chapter:         6,versenumber:        15,verse:"但别人说：“是以利亚。”又有人说：“是先知，正像先知中的一位。”"},
{volumn:        41,chapter:         6,versenumber:        16,verse:"希律听见却说：“是我所斩的约翰，他复活了。”"},
{volumn:        41,chapter:         6,versenumber:        17,verse:"先是希律为他兄弟腓力的妻子希罗底的缘故，差人去拿住约翰，锁在监里，因为希律已经娶了那妇人。"},
{volumn:        41,chapter:         6,versenumber:        18,verse:"约翰曾对希律说：“你娶你兄弟的妻子是不合理的。”"},
{volumn:        41,chapter:         6,versenumber:        19,verse:"于是希罗底怀恨他，想要杀他，只是不能；"},
{volumn:        41,chapter:         6,versenumber:        20,verse:"因为希律知道约翰是义人，是圣人，所以敬畏他，保护他，听他讲论，就多照着行（有古卷：游移不定），并且乐意听他。"},
{volumn:        41,chapter:         6,versenumber:        21,verse:"有一天，恰巧是希律的生日，希律摆设筵席，请了大臣和千夫长，并加利利作首领的。"},
{volumn:        41,chapter:         6,versenumber:        22,verse:"希罗底的女儿进来跳舞，使希律和同席的人都欢喜。王就对女子说：“你随意向我求什么，我必给你。”"},
{volumn:        41,chapter:         6,versenumber:        23,verse:"又对她起誓说：“随你向我求什么，就是我国的一半，我也必给你。”"},
{volumn:        41,chapter:         6,versenumber:        24,verse:"她就出去对她母亲说：“我可以求什么呢？”她母亲说：“施洗约翰的头。”"},
{volumn:        41,chapter:         6,versenumber:        25,verse:"她就急忙进去见王，求他说：“我愿王立时把施洗约翰的头放在盘子里给我。”"},
{volumn:        41,chapter:         6,versenumber:        26,verse:"王就甚忧愁；但因他所起的誓，又因同席的人，就不肯推辞，"},
{volumn:        41,chapter:         6,versenumber:        27,verse:"随即差一个护卫兵，吩咐拿约翰的头来。护卫兵就去，在监里斩了约翰，"},
{volumn:        41,chapter:         6,versenumber:        28,verse:"把头放在盘子里，拿来给女子，女子就给她母亲。"},
{volumn:        41,chapter:         6,versenumber:        29,verse:"约翰的门徒听见了，就来把他的尸首领去，葬在坟墓里。"},
{volumn:        41,chapter:         6,versenumber:        30,verse:"使徒聚集到耶稣那里，将一切所做的事、所传的道全告诉他。"},
{volumn:        41,chapter:         6,versenumber:        31,verse:"他就说：“你们来，同我暗暗地到旷野地方去歇一歇。”这是因为来往的人多，他们连吃饭也没有工夫。"},
{volumn:        41,chapter:         6,versenumber:        32,verse:"他们就坐船，暗暗地往旷野地方去。"},
{volumn:        41,chapter:         6,versenumber:        33,verse:"众人看见他们去，有许多认识他们的，就从各城步行，一同跑到那里，比他们先赶到了。"},
{volumn:        41,chapter:         6,versenumber:        34,verse:"耶稣出来，见有许多的人，就怜悯他们，因为他们如同羊没有牧人一般，于是开口教训他们许多道理。"},
{volumn:        41,chapter:         6,versenumber:        35,verse:"天已经晚了，门徒进前来，说：“这是野地，天已经晚了，"},
{volumn:        41,chapter:         6,versenumber:        36,verse:"请叫众人散开，他们好往四面乡村里去，自己买什么吃。”"},
{volumn:        41,chapter:         6,versenumber:        37,verse:"耶稣回答说：“你们给他们吃吧。”门徒说：“我们可以去买二十两银子的饼给他们吃吗？”"},
{volumn:        41,chapter:         6,versenumber:        38,verse:"耶稣说：“你们有多少饼，可以去看看。”他们知道了，就说：“五个饼，两条鱼。”"},
{volumn:        41,chapter:         6,versenumber:        39,verse:"耶稣吩咐他们，叫众人一帮一帮地坐在青草地上。"},
{volumn:        41,chapter:         6,versenumber:        40,verse:"众人就一排一排地坐下，有一百一排的，有五十一排的。"},
{volumn:        41,chapter:         6,versenumber:        41,verse:"耶稣拿着这五个饼，两条鱼，望着天祝福，擘开饼，递给门徒，摆在众人面前，也把那两条鱼分给众人。"},
{volumn:        41,chapter:         6,versenumber:        42,verse:"他们都吃，并且吃饱了。"},
{volumn:        41,chapter:         6,versenumber:        43,verse:"门徒就把碎饼碎鱼收拾起来，装满了十二个篮子。"},
{volumn:        41,chapter:         6,versenumber:        44,verse:"吃饼的男人共有五千。"},
{volumn:        41,chapter:         6,versenumber:        45,verse:"耶稣随即催门徒上船，先渡到那边伯赛大去，等他叫众人散开。"},
{volumn:        41,chapter:         6,versenumber:        46,verse:"他既辞别了他们，就往山上去祷告。"},
{volumn:        41,chapter:         6,versenumber:        47,verse:"到了晚上，船在海中，耶稣独自在岸上；"},
{volumn:        41,chapter:         6,versenumber:        48,verse:"看见门徒因风不顺，摇橹甚苦。夜里约有四更天，就在海面上走，往他们那里去，意思要走过他们去。"},
{volumn:        41,chapter:         6,versenumber:        49,verse:"但门徒看见他在海面上走，以为是鬼怪，就喊叫起来；"},
{volumn:        41,chapter:         6,versenumber:        50,verse:"因为他们都看见了他，且甚惊慌。耶稣连忙对他们说：“你们放心！是我，不要怕！”"},
{volumn:        41,chapter:         6,versenumber:        51,verse:"于是到他们那里，上了船，风就住了；他们心里十分惊奇。"},
{volumn:        41,chapter:         6,versenumber:        52,verse:"这是因为他们不明白那分饼的事，心里还是愚顽。"},
{volumn:        41,chapter:         6,versenumber:        53,verse:"既渡过去，来到革尼撒勒地方，就靠了岸，"},
{volumn:        41,chapter:         6,versenumber:        54,verse:"一下船，众人认得是耶稣，"},
{volumn:        41,chapter:         6,versenumber:        55,verse:"就跑遍那一带地方，听见他在何处，便将有病的人用褥子抬到那里。"},
{volumn:        41,chapter:         6,versenumber:        56,verse:"凡耶稣所到的地方，或村中，或城里，或乡间，他们都将病人放在街市上，求耶稣只容他们摸他的衣裳繸子；凡摸着的人就都好了。"},
{volumn:        41,chapter:         7,versenumber:         1,verse:"有法利赛人和几个文士从耶路撒冷来，到耶稣那里聚集。"},
{volumn:        41,chapter:         7,versenumber:         2,verse:"他们曾看见他的门徒中有人用俗手，就是没有洗的手，吃饭。（"},
{volumn:        41,chapter:         7,versenumber:         3,verse:"原来法利赛人和犹太人都拘守古人的遗传，若不仔细洗手就不吃饭；"},
{volumn:        41,chapter:         7,versenumber:         4,verse:"从市上来，若不洗浴也不吃饭；还有好些别的规矩，他们历代拘守，就是洗杯、罐、铜器等物。）"},
{volumn:        41,chapter:         7,versenumber:         5,verse:"法利赛人和文士问他说：“你的门徒为什么不照古人的遗传，用俗手吃饭呢？”"},
{volumn:        41,chapter:         7,versenumber:         6,verse:"耶稣说：“以赛亚指着你们假冒为善之人所说的预言是不错的。如经上说：‘这百姓用嘴唇尊敬我，心却远离我。"},
{volumn:        41,chapter:         7,versenumber:         7,verse:"他们将人的吩咐，当作道理教导人，所以拜我也是枉然。’"},
{volumn:        41,chapter:         7,versenumber:         8,verse:"你们是离弃　神的诫命，拘守人的遗传”；"},
{volumn:        41,chapter:         7,versenumber:         9,verse:"又说：“你们诚然是废弃　神的诫命，要守自己的遗传。"},
{volumn:        41,chapter:         7,versenumber:        10,verse:"摩西说：‘当孝敬父母’；又说：‘咒骂父母的，必治死他。’"},
{volumn:        41,chapter:         7,versenumber:        11,verse:"你们倒说：‘人若对父母说：我所当奉给你的，已经作了各耳板’（各耳板就是供献的意思），"},
{volumn:        41,chapter:         7,versenumber:        12,verse:"以后你们就不容他再奉养父母。"},
{volumn:        41,chapter:         7,versenumber:        13,verse:"这就是你们承接遗传，废了　神的道。你们还做许多这样的事。”"},
{volumn:        41,chapter:         7,versenumber:        14,verse:"耶稣又叫众人来，对他们说：“你们都要听我的话，也要明白。"},
{volumn:        41,chapter:         7,versenumber:        15,verse:"从外面进去的不能污秽人，惟有从里面出来的乃能污秽人。”（有古卷加：16有耳可听的，就应当听！）"},
{volumn:        41,chapter:         7,versenumber:        16,verse:""},
{volumn:        41,chapter:         7,versenumber:        17,verse:"耶稣离开众人，进了屋子，门徒就问他这比喻的意思。"},
{volumn:        41,chapter:         7,versenumber:        18,verse:"耶稣对他们说：“你们也是这样不明白吗？岂不晓得凡从外面进入的，不能污秽人，"},
{volumn:        41,chapter:         7,versenumber:        19,verse:"因为不是入他的心，乃是入他的肚腹，又落到茅厕里（这是说，各样的食物都是洁净的）”；"},
{volumn:        41,chapter:         7,versenumber:        20,verse:"又说：“从人里面出来的，那才能污秽人；"},
{volumn:        41,chapter:         7,versenumber:        21,verse:"因为从里面，就是从人心里，发出恶念、苟合、"},
{volumn:        41,chapter:         7,versenumber:        22,verse:"偷盗、凶杀、奸淫、贪婪、邪恶、诡诈、淫荡、嫉妒、谤讟、骄傲、狂妄。"},
{volumn:        41,chapter:         7,versenumber:        23,verse:"这一切的恶都是从里面出来，且能污秽人。”"},
{volumn:        41,chapter:         7,versenumber:        24,verse:"耶稣从那里起身，往推罗、西顿的境内去，进了一家，不愿意人知道，却隐藏不住。"},
{volumn:        41,chapter:         7,versenumber:        25,verse:"当下，有一个妇人，她的小女儿被污鬼附着，听见耶稣的事，就来俯伏在他脚前。"},
{volumn:        41,chapter:         7,versenumber:        26,verse:"这妇人是希腊人，属叙利腓尼基族。她求耶稣赶出那鬼离开她的女儿。"},
{volumn:        41,chapter:         7,versenumber:        27,verse:"耶稣对她说：“让儿女们先吃饱，不好拿儿女的饼丢给狗吃。”"},
{volumn:        41,chapter:         7,versenumber:        28,verse:"妇人回答说：“主啊，不错；但是狗在桌子底下也吃孩子们的碎渣儿。”"},
{volumn:        41,chapter:         7,versenumber:        29,verse:"耶稣对她说：“因这句话，你回去吧；鬼已经离开你的女儿了。”"},
{volumn:        41,chapter:         7,versenumber:        30,verse:"她就回家去，见小孩子躺在床上，鬼已经出去了。"},
{volumn:        41,chapter:         7,versenumber:        31,verse:"耶稣又离了推罗的境界，经过西顿，就从低加波利境内来到加利利海。"},
{volumn:        41,chapter:         7,versenumber:        32,verse:"有人带着一个耳聋舌结的人来见耶稣，求他按手在他身上。"},
{volumn:        41,chapter:         7,versenumber:        33,verse:"耶稣领他离开众人，到一边去，就用指头探他的耳朵，吐唾沫抹他的舌头，"},
{volumn:        41,chapter:         7,versenumber:        34,verse:"望天叹息，对他说：“以法大！”就是说：“开了吧！”"},
{volumn:        41,chapter:         7,versenumber:        35,verse:"他的耳朵就开了，舌结也解了，说话也清楚了。"},
{volumn:        41,chapter:         7,versenumber:        36,verse:"耶稣嘱咐他们不要告诉人；但他越发嘱咐，他们越发传扬开了。"},
{volumn:        41,chapter:         7,versenumber:        37,verse:"众人分外希奇，说：“他所做的事都好，他连聋子也叫他们听见，哑巴也叫他们说话。”"},
{volumn:        41,chapter:         8,versenumber:         1,verse:"那时，又有许多人聚集，并没有什么吃的。耶稣叫门徒来，说："},
{volumn:        41,chapter:         8,versenumber:         2,verse:"“我怜悯这众人；因为他们同我在这里已经三天，也没有吃的了。"},
{volumn:        41,chapter:         8,versenumber:         3,verse:"我若打发他们饿着回家，就必在路上困乏，因为其中有从远处来的。”"},
{volumn:        41,chapter:         8,versenumber:         4,verse:"门徒回答说：“在这野地，从哪里能得饼，叫这些人吃饱呢？”"},
{volumn:        41,chapter:         8,versenumber:         5,verse:"耶稣问他们说：“你们有多少饼？”他们说：“七个。”"},
{volumn:        41,chapter:         8,versenumber:         6,verse:"他吩咐众人坐在地上，就拿着这七个饼祝谢了，擘开，递给门徒，叫他们摆开，门徒就摆在众人面前。"},
{volumn:        41,chapter:         8,versenumber:         7,verse:"又有几条小鱼；耶稣祝了福，就吩咐也摆在众人面前。"},
{volumn:        41,chapter:         8,versenumber:         8,verse:"众人都吃，并且吃饱了，收拾剩下的零碎，有七筐子。"},
{volumn:        41,chapter:         8,versenumber:         9,verse:"人数约有四千。耶稣打发他们走了，"},
{volumn:        41,chapter:         8,versenumber:        10,verse:"随即同门徒上船，来到大玛努他境内。"},
{volumn:        41,chapter:         8,versenumber:        11,verse:"法利赛人出来盘问耶稣，求他从天上显个神迹给他们看，想要试探他。"},
{volumn:        41,chapter:         8,versenumber:        12,verse:"耶稣心里深深地叹息，说：“这世代为什么求神迹呢？我实在告诉你们，没有神迹给这世代看。”"},
{volumn:        41,chapter:         8,versenumber:        13,verse:"他就离开他们，又上船往海那边去了。"},
{volumn:        41,chapter:         8,versenumber:        14,verse:"门徒忘了带饼；在船上除了一个饼，没有别的食物。"},
{volumn:        41,chapter:         8,versenumber:        15,verse:"耶稣嘱咐他们说：“你们要谨慎，防备法利赛人的酵和希律的酵。”"},
{volumn:        41,chapter:         8,versenumber:        16,verse:"他们彼此议论说：“这是因为我们没有饼吧。”"},
{volumn:        41,chapter:         8,versenumber:        17,verse:"耶稣看出来，就说：“你们为什么因为没有饼就议论呢？你们还不省悟，还不明白吗？你们的心还是愚顽吗？"},
{volumn:        41,chapter:         8,versenumber:        18,verse:"你们有眼睛，看不见吗？有耳朵，听不见吗？也不记得吗？"},
{volumn:        41,chapter:         8,versenumber:        19,verse:"我擘开那五个饼分给五千人，你们收拾的零碎装满了多少篮子呢？”他们说：“十二个。”"},
{volumn:        41,chapter:         8,versenumber:        20,verse:"“又擘开那七个饼分给四千人，你们收拾的零碎装满了多少筐子呢？”他们说：“七个。”"},
{volumn:        41,chapter:         8,versenumber:        21,verse:"耶稣说：“你们还是不明白吗？”"},
{volumn:        41,chapter:         8,versenumber:        22,verse:"他们来到伯赛大，有人带一个瞎子来，求耶稣摸他。"},
{volumn:        41,chapter:         8,versenumber:        23,verse:"耶稣拉着瞎子的手，领他到村外，就吐唾沫在他眼睛上，按手在他身上，问他说：“你看见什么了？”"},
{volumn:        41,chapter:         8,versenumber:        24,verse:"他就抬头一看，说：“我看见人了；他们好像树木，并且行走。”"},
{volumn:        41,chapter:         8,versenumber:        25,verse:"随后又按手在他眼睛上，他定睛一看，就复了原，样样都看得清楚了。"},
{volumn:        41,chapter:         8,versenumber:        26,verse:"耶稣打发他回家，说：“连这村子你也不要进去。”"},
{volumn:        41,chapter:         8,versenumber:        27,verse:"耶稣和门徒出去，往凯撒利亚·腓立比村庄去；在路上问门徒说：“人说我是谁？”"},
{volumn:        41,chapter:         8,versenumber:        28,verse:"他们说：“有人说是施洗的约翰；有人说是以利亚；又有人说是先知里的一位。”"},
{volumn:        41,chapter:         8,versenumber:        29,verse:"又问他们说：“你们说我是谁？”彼得回答说：“你是基督。”"},
{volumn:        41,chapter:         8,versenumber:        30,verse:"耶稣就禁戒他们，不要告诉人。"},
{volumn:        41,chapter:         8,versenumber:        31,verse:"从此，他教训他们说：“人子必须受许多的苦，被长老、祭司长，和文士弃绝，并且被杀，过三天复活。”"},
{volumn:        41,chapter:         8,versenumber:        32,verse:"耶稣明明地说这话，彼得就拉着他，劝他。"},
{volumn:        41,chapter:         8,versenumber:        33,verse:"耶稣转过来，看着门徒，就责备彼得说：“撒但，退我后边去吧！因为你不体贴　神的意思，只体贴人的意思。”"},
{volumn:        41,chapter:         8,versenumber:        34,verse:"于是叫众人和门徒来，对他们说：“若有人要跟从我，就当舍己，背起他的十字架来跟从我。"},
{volumn:        41,chapter:         8,versenumber:        35,verse:"因为，凡要救自己生命（或译：灵魂；下同）的，必丧掉生命；凡为我和福音丧掉生命的，必救了生命。"},
{volumn:        41,chapter:         8,versenumber:        36,verse:"人就是赚得全世界，赔上自己的生命，有什么益处呢？"},
{volumn:        41,chapter:         8,versenumber:        37,verse:"人还能拿什么换生命呢？"},
{volumn:        41,chapter:         8,versenumber:        38,verse:"凡在这淫乱罪恶的世代，把我和我的道当作可耻的，人子在他父的荣耀里，同圣天使降临的时候，也要把那人当作可耻的。”"},
{volumn:        41,chapter:         9,versenumber:         1,verse:"耶稣又对他们说：“我实在告诉你们，站在这里的，有人在没尝死味以前，必要看见　神的国大有能力临到。”"},
{volumn:        41,chapter:         9,versenumber:         2,verse:"过了六天，耶稣带着彼得、雅各、约翰暗暗地上了高山，就在他们面前变了形像，"},
{volumn:        41,chapter:         9,versenumber:         3,verse:"衣服放光，极其洁白，地上漂布的，没有一个能漂得那样白。"},
{volumn:        41,chapter:         9,versenumber:         4,verse:"忽然，有以利亚同摩西向他们显现，并且和耶稣说话。"},
{volumn:        41,chapter:         9,versenumber:         5,verse:"彼得对耶稣说：“拉比（就是夫子），我们在这里真好！可以搭三座棚，一座为你，一座为摩西，一座为以利亚。”"},
{volumn:        41,chapter:         9,versenumber:         6,verse:"彼得不知道说什么才好，因为他们甚是惧怕。"},
{volumn:        41,chapter:         9,versenumber:         7,verse:"有一朵云彩来遮盖他们；也有声音从云彩里出来，说：“这是我的爱子，你们要听他。”"},
{volumn:        41,chapter:         9,versenumber:         8,verse:"门徒忽然周围一看，不再见一人，只见耶稣同他们在那里。"},
{volumn:        41,chapter:         9,versenumber:         9,verse:"下山的时候，耶稣嘱咐他们说：“人子还没有从死里复活，你们不要将所看见的告诉人。”"},
{volumn:        41,chapter:         9,versenumber:        10,verse:"门徒将这话存记在心，彼此议论“从死里复活”是什么意思。"},
{volumn:        41,chapter:         9,versenumber:        11,verse:"他们就问耶稣说：“文士为什么说以利亚必须先来？”"},
{volumn:        41,chapter:         9,versenumber:        12,verse:"耶稣说：“以利亚固然先来复兴万事；经上不是指着人子说，他要受许多的苦被人轻慢呢？"},
{volumn:        41,chapter:         9,versenumber:        13,verse:"我告诉你们，以利亚已经来了，他们也任意待他，正如经上所指着他的话。”"},
{volumn:        41,chapter:         9,versenumber:        14,verse:"耶稣到了门徒那里，看见有许多人围着他们，又有文士和他们辩论。"},
{volumn:        41,chapter:         9,versenumber:        15,verse:"众人一见耶稣，都甚希奇，就跑上去问他的安。"},
{volumn:        41,chapter:         9,versenumber:        16,verse:"耶稣问他们说：“你们和他们辩论的是什么？”"},
{volumn:        41,chapter:         9,versenumber:        17,verse:"众人中间有一个人回答说：“夫子，我带了我的儿子到你这里来，他被哑巴鬼附着。"},
{volumn:        41,chapter:         9,versenumber:        18,verse:"无论在哪里，鬼捉弄他，把他摔倒，他就口中流沫，咬牙切齿，身体枯干。我请过你的门徒把鬼赶出去，他们却是不能。”"},
{volumn:        41,chapter:         9,versenumber:        19,verse:"耶稣说：“嗳！不信的世代啊，我在你们这里要到几时呢？我忍耐你们要到几时呢？把他带到我这里来吧。”"},
{volumn:        41,chapter:         9,versenumber:        20,verse:"他们就带了他来。他一见耶稣，鬼便叫他重重地抽风，倒在地上，翻来覆去，口中流沫。"},
{volumn:        41,chapter:         9,versenumber:        21,verse:"耶稣问他父亲说：“他得这病有多少日子呢？”回答说：“从小的时候。"},
{volumn:        41,chapter:         9,versenumber:        22,verse:"鬼屡次把他扔在火里、水里，要灭他。你若能做什么，求你怜悯我们，帮助我们。”"},
{volumn:        41,chapter:         9,versenumber:        23,verse:"耶稣对他说：“你若能信，在信的人，凡事都能。”"},
{volumn:        41,chapter:         9,versenumber:        24,verse:"孩子的父亲立时喊着说（有古卷：立时流泪地喊着说）：“我信！但我信不足，求主帮助。”"},
{volumn:        41,chapter:         9,versenumber:        25,verse:"耶稣看见众人都跑上来，就斥责那污鬼，说：“你这聋哑的鬼，我吩咐你从他里头出来，再不要进去！”"},
{volumn:        41,chapter:         9,versenumber:        26,verse:"那鬼喊叫，使孩子大大地抽了一阵风，就出来了。孩子好像死了一般，以致众人多半说：“他是死了。”"},
{volumn:        41,chapter:         9,versenumber:        27,verse:"但耶稣拉着他的手，扶他起来，他就站起来了。"},
{volumn:        41,chapter:         9,versenumber:        28,verse:"耶稣进了屋子，门徒就暗暗地问他说：“我们为什么不能赶出他去呢？”"},
{volumn:        41,chapter:         9,versenumber:        29,verse:"耶稣说：“非用祷告（有古卷加：禁食二字），这一类的鬼总不能出来（或译：不能赶他出来）。”"},
{volumn:        41,chapter:         9,versenumber:        30,verse:"他们离开那地方，经过加利利；耶稣不愿意人知道。"},
{volumn:        41,chapter:         9,versenumber:        31,verse:"于是教训门徒，说：“人子将要被交在人手里，他们要杀害他；被杀以后，过三天他要复活。”"},
{volumn:        41,chapter:         9,versenumber:        32,verse:"门徒却不明白这话，又不敢问他。"},
{volumn:        41,chapter:         9,versenumber:        33,verse:"他们来到迦百农。耶稣在屋里问门徒说：“你们在路上议论的是什么？”"},
{volumn:        41,chapter:         9,versenumber:        34,verse:"门徒不作声，因为他们在路上彼此争论谁为大。"},
{volumn:        41,chapter:         9,versenumber:        35,verse:"耶稣坐下，叫十二个门徒来，说：“若有人愿意作首先的，他必作众人末后的，作众人的用人。”"},
{volumn:        41,chapter:         9,versenumber:        36,verse:"于是领过一个小孩子来，叫他站在门徒中间，又抱起他来，对他们说："},
{volumn:        41,chapter:         9,versenumber:        37,verse:"“凡为我名接待一个像这小孩子的，就是接待我；凡接待我的，不是接待我，乃是接待那差我来的。”"},
{volumn:        41,chapter:         9,versenumber:        38,verse:"约翰对耶稣说：“夫子，我们看见一个人奉你的名赶鬼，我们就禁止他，因为他不跟从我们。”"},
{volumn:        41,chapter:         9,versenumber:        39,verse:"耶稣说：“不要禁止他；因为没有人奉我名行异能，反倒轻易毁谤我。"},
{volumn:        41,chapter:         9,versenumber:        40,verse:"不敌挡我们的，就是帮助我们的。"},
{volumn:        41,chapter:         9,versenumber:        41,verse:"凡因你们是属基督，给你们一杯水喝的，我实在告诉你们，他不能不得赏赐。”"},
{volumn:        41,chapter:         9,versenumber:        42,verse:"“凡使这信我的一个小子跌倒的，倒不如把大磨石拴在这人的颈项上，扔在海里。"},
{volumn:        41,chapter:         9,versenumber:        43,verse:"倘若你一只手叫你跌倒，就把它砍下来；"},
{volumn:        41,chapter:         9,versenumber:        44,verse:"你缺了肢体进入永生，强如有两只手落到地狱，入那不灭的火里去。"},
{volumn:        41,chapter:         9,versenumber:        45,verse:"倘若你一只脚叫你跌倒，就把它砍下来；"},
{volumn:        41,chapter:         9,versenumber:        46,verse:"你瘸腿进入永生，强如有两只脚被丢在地狱里。"},
{volumn:        41,chapter:         9,versenumber:        47,verse:"倘若你一只眼叫你跌倒，就去掉它；你只有一只眼进入　神的国，强如有两只眼被丢在地狱里。"},
{volumn:        41,chapter:         9,versenumber:        48,verse:"在那里，虫是不死的，火是不灭的。"},
{volumn:        41,chapter:         9,versenumber:        49,verse:"因为必用火当盐腌各人。（有古卷加：凡祭物必用盐腌。）"},
{volumn:        41,chapter:         9,versenumber:        50,verse:"盐本是好的，若失了味，可用什么叫它再咸呢？你们里头应当有盐，彼此和睦。”"},
{volumn:        41,chapter:        10,versenumber:         1,verse:"耶稣从那里起身，来到犹太的境界并约旦河外。众人又聚集到他那里，他又照常教训他们。"},
{volumn:        41,chapter:        10,versenumber:         2,verse:"有法利赛人来问他说：“人休妻可以不可以？”意思要试探他。"},
{volumn:        41,chapter:        10,versenumber:         3,verse:"耶稣回答说：“摩西吩咐你们的是什么？”"},
{volumn:        41,chapter:        10,versenumber:         4,verse:"他们说：“摩西许人写了休书便可以休妻。”"},
{volumn:        41,chapter:        10,versenumber:         5,verse:"耶稣说：“摩西因为你们的心硬，所以写这条例给你们；"},
{volumn:        41,chapter:        10,versenumber:         6,verse:"但从起初创造的时候，　神造人是造男造女。"},
{volumn:        41,chapter:        10,versenumber:         7,verse:"因此，人要离开父母，与妻子连合，二人成为一体。"},
{volumn:        41,chapter:        10,versenumber:         8,verse:"既然如此，夫妻不再是两个人，乃是一体的了。"},
{volumn:        41,chapter:        10,versenumber:         9,verse:"所以，　神配合的，人不可分开。”"},
{volumn:        41,chapter:        10,versenumber:        10,verse:"到了屋里，门徒就问他这事。"},
{volumn:        41,chapter:        10,versenumber:        11,verse:"耶稣对他们说：“凡休妻另娶的，就是犯奸淫，辜负他的妻子；"},
{volumn:        41,chapter:        10,versenumber:        12,verse:"妻子若离弃丈夫另嫁，也是犯奸淫了。”"},
{volumn:        41,chapter:        10,versenumber:        13,verse:"有人带着小孩子来见耶稣，要耶稣摸他们，门徒便责备那些人。"},
{volumn:        41,chapter:        10,versenumber:        14,verse:"耶稣看见就恼怒，对门徒说：“让小孩子到我这里来，不要禁止他们；因为在　神国的，正是这样的人。"},
{volumn:        41,chapter:        10,versenumber:        15,verse:"我实在告诉你们，凡要承受　神国的，若不像小孩子，断不能进去。”"},
{volumn:        41,chapter:        10,versenumber:        16,verse:"于是抱着小孩子，给他们按手，为他们祝福。"},
{volumn:        41,chapter:        10,versenumber:        17,verse:"耶稣出来行路的时候，有一个人跑来，跪在他面前，问他说：“良善的夫子，我当做什么事才可以承受永生？”"},
{volumn:        41,chapter:        10,versenumber:        18,verse:"耶稣对他说：“你为什么称我是良善的？除了　神一位之外，再没有良善的。"},
{volumn:        41,chapter:        10,versenumber:        19,verse:"诫命你是晓得的：不可杀人；不可奸淫；不可偷盗；不可作假见证；不可亏负人；当孝敬父母。”"},
{volumn:        41,chapter:        10,versenumber:        20,verse:"他对耶稣说：“夫子，这一切我从小都遵守了。”"},
{volumn:        41,chapter:        10,versenumber:        21,verse:"耶稣看着他，就爱他，对他说：“你还缺少一件：去变卖你所有的，分给穷人，就必有财宝在天上；你还要来跟从我。”"},
{volumn:        41,chapter:        10,versenumber:        22,verse:"他听见这话，脸上就变了色，忧忧愁愁地走了，因为他的产业很多。"},
{volumn:        41,chapter:        10,versenumber:        23,verse:"耶稣周围一看，对门徒说：“有钱财的人进　神的国是何等地难哪！”"},
{volumn:        41,chapter:        10,versenumber:        24,verse:"门徒希奇他的话。耶稣又对他们说：“小子，倚靠钱财的人进　神的国是何等地难哪！"},
{volumn:        41,chapter:        10,versenumber:        25,verse:"骆驼穿过针的眼，比财主进　神的国还容易呢。”"},
{volumn:        41,chapter:        10,versenumber:        26,verse:"门徒就分外希奇，对他说：“这样谁能得救呢？”"},
{volumn:        41,chapter:        10,versenumber:        27,verse:"耶稣看着他们，说：“在人是不能，在　神却不然，因为　神凡事都能。”"},
{volumn:        41,chapter:        10,versenumber:        28,verse:"彼得就对他说：“看哪，我们已经撇下所有的跟从你了。”"},
{volumn:        41,chapter:        10,versenumber:        29,verse:"耶稣说：“我实在告诉你们，人为我和福音撇下房屋，或是弟兄、姐妹、父母、儿女、田地，"},
{volumn:        41,chapter:        10,versenumber:        30,verse:"没有不在今世得百倍的，就是房屋、弟兄、姐妹、母亲、儿女、田地，并且要受逼迫，在来世必得永生。"},
{volumn:        41,chapter:        10,versenumber:        31,verse:"然而，有许多在前的，将要在后，在后的，将要在前。”"},
{volumn:        41,chapter:        10,versenumber:        32,verse:"他们行路上耶路撒冷去。耶稣在前头走，门徒就希奇，跟从的人也害怕。耶稣又叫过十二个门徒来，把自己将要遭遇的事告诉他们说："},
{volumn:        41,chapter:        10,versenumber:        33,verse:"“看哪，我们上耶路撒冷去，人子将要被交给祭司长和文士，他们要定他死罪，交给外邦人。"},
{volumn:        41,chapter:        10,versenumber:        34,verse:"他们要戏弄他，吐唾沫在他脸上，鞭打他，杀害他。过了三天，他要复活。”"},
{volumn:        41,chapter:        10,versenumber:        35,verse:"西庇太的儿子雅各、约翰进前来，对耶稣说：“夫子，我们无论求你什么，愿你给我们做。”"},
{volumn:        41,chapter:        10,versenumber:        36,verse:"耶稣说：“要我给你们做什么？”"},
{volumn:        41,chapter:        10,versenumber:        37,verse:"他们说：“赐我们在你的荣耀里，一个坐在你右边，一个坐在你左边。”"},
{volumn:        41,chapter:        10,versenumber:        38,verse:"耶稣说：“你们不知道所求的是什么。我所喝的杯，你们能喝吗？我所受的洗，你们能受吗？”"},
{volumn:        41,chapter:        10,versenumber:        39,verse:"他们说：“我们能。”耶稣说：“我所喝的杯，你们也要喝；我所受的洗，你们也要受；"},
{volumn:        41,chapter:        10,versenumber:        40,verse:"只是坐在我的左右，不是我可以赐的，乃是为谁预备的，就赐给谁。”"},
{volumn:        41,chapter:        10,versenumber:        41,verse:"那十个门徒听见，就恼怒雅各、约翰。"},
{volumn:        41,chapter:        10,versenumber:        42,verse:"耶稣叫他们来，对他们说：“你们知道，外邦人有尊为君王的，治理他们，有大臣操权管束他们。"},
{volumn:        41,chapter:        10,versenumber:        43,verse:"只是在你们中间，不是这样。你们中间，谁愿为大，就必作你们的用人；"},
{volumn:        41,chapter:        10,versenumber:        44,verse:"在你们中间，谁愿为首，就必作众人的仆人。"},
{volumn:        41,chapter:        10,versenumber:        45,verse:"因为人子来，并不是要受人的服侍，乃是要服侍人，并且要舍命作多人的赎价。”"},
{volumn:        41,chapter:        10,versenumber:        46,verse:"到了耶利哥；耶稣同门徒并许多人出耶利哥的时候，有一个讨饭的瞎子，是底买的儿子巴底买，坐在路旁。"},
{volumn:        41,chapter:        10,versenumber:        47,verse:"他听见是拿撒勒的耶稣，就喊着说：“大卫的子孙耶稣啊！可怜我吧！”"},
{volumn:        41,chapter:        10,versenumber:        48,verse:"有许多人责备他，不许他作声。他却越发大声喊着说：“大卫的子孙哪，可怜我吧！”"},
{volumn:        41,chapter:        10,versenumber:        49,verse:"耶稣就站住，说：“叫过他来。”他们就叫那瞎子，对他说：“放心，起来！他叫你啦。”"},
{volumn:        41,chapter:        10,versenumber:        50,verse:"瞎子就丢下衣服，跳起来，走到耶稣那里。"},
{volumn:        41,chapter:        10,versenumber:        51,verse:"耶稣说：“要我为你做什么？”瞎子说：“拉波尼（就是夫子），我要能看见。”"},
{volumn:        41,chapter:        10,versenumber:        52,verse:"耶稣说：“你去吧！你的信救了你了。”瞎子立刻看见了，就在路上跟随耶稣。"},
{volumn:        41,chapter:        11,versenumber:         1,verse:"耶稣和门徒将近耶路撒冷，到了伯法其和伯大尼，在橄榄山那里；耶稣就打发两个门徒，"},
{volumn:        41,chapter:        11,versenumber:         2,verse:"对他们说：“你们往对面村子里去，一进去的时候，必看见一匹驴驹拴在那里，是从来没有人骑过的，可以解开，牵来。"},
{volumn:        41,chapter:        11,versenumber:         3,verse:"若有人对你们说：‘为什么做这事？’你们就说：‘主要用它。’那人必立时让你们牵来。”"},
{volumn:        41,chapter:        11,versenumber:         4,verse:"他们去了，便看见一匹驴驹拴在门外街道上，就把它解开。"},
{volumn:        41,chapter:        11,versenumber:         5,verse:"在那里站着的人，有几个说：“你们解驴驹做什么？”"},
{volumn:        41,chapter:        11,versenumber:         6,verse:"门徒照着耶稣所说的回答，那些人就任凭他们牵去了。"},
{volumn:        41,chapter:        11,versenumber:         7,verse:"他们把驴驹牵到耶稣那里，把自己的衣服搭在上面，耶稣就骑上。"},
{volumn:        41,chapter:        11,versenumber:         8,verse:"有许多人把衣服铺在路上，也有人把田间的树枝砍下来，铺在路上。"},
{volumn:        41,chapter:        11,versenumber:         9,verse:"前行后随的人都喊着说：“和散那（“和散那”原有求救的意思，在此乃是称颂的话）！奉主名来的是应当称颂的！"},
{volumn:        41,chapter:        11,versenumber:        10,verse:"那将要来的我祖大卫之国是应当称颂的！高高在上，和散那！”"},
{volumn:        41,chapter:        11,versenumber:        11,verse:"耶稣进了耶路撒冷，入了圣殿，周围看了各样物件。天色已晚，就和十二个门徒出城，往伯大尼去了。"},
{volumn:        41,chapter:        11,versenumber:        12,verse:"第二天，他们从伯大尼出来，耶稣饿了。"},
{volumn:        41,chapter:        11,versenumber:        13,verse:"远远地看见一棵无花果树，树上有叶子，就往那里去，或者在树上可以找着什么。到了树下，竟找不着什么，不过有叶子，因为不是收无花果的时候。"},
{volumn:        41,chapter:        11,versenumber:        14,verse:"耶稣就对树说：“从今以后，永没有人吃你的果子。”他的门徒也听见了。"},
{volumn:        41,chapter:        11,versenumber:        15,verse:"他们来到耶路撒冷。耶稣进入圣殿，赶出殿里做买卖的人，推倒兑换银钱之人的桌子和卖鸽子之人的凳子；"},
{volumn:        41,chapter:        11,versenumber:        16,verse:"也不许人拿着器具从殿里经过；"},
{volumn:        41,chapter:        11,versenumber:        17,verse:"便教训他们说：“经上不是记着说：‘我的殿必称为万国祷告的殿’吗？你们倒使它成为贼窝了。”"},
{volumn:        41,chapter:        11,versenumber:        18,verse:"祭司长和文士听见这话，就想法子要除灭耶稣，却又怕他，因为众人都希奇他的教训。"},
{volumn:        41,chapter:        11,versenumber:        19,verse:"每天晚上，耶稣出城去。"},
{volumn:        41,chapter:        11,versenumber:        20,verse:"早晨，他们从那里经过，看见无花果树连根都枯干了。"},
{volumn:        41,chapter:        11,versenumber:        21,verse:"彼得想起耶稣的话来，就对他说：“拉比，请看！你所咒诅的无花果树已经枯干了。”"},
{volumn:        41,chapter:        11,versenumber:        22,verse:"耶稣回答说：“你们当信服　神。"},
{volumn:        41,chapter:        11,versenumber:        23,verse:"我实在告诉你们，无论何人对这座山说：‘你挪开此地，投在海里！’他若心里不疑惑，只信他所说的必成，就必给他成了。"},
{volumn:        41,chapter:        11,versenumber:        24,verse:"所以我告诉你们，凡你们祷告祈求的，无论是什么，只要信是得着的，就必得着。"},
{volumn:        41,chapter:        11,versenumber:        25,verse:"你们站着祷告的时候，若想起有人得罪你们，就当饶恕他，好叫你们在天上的父也饶恕你们的过犯。"},
{volumn:        41,chapter:        11,versenumber:        26,verse:"你们若不饶恕人，你们在天上的父也不饶恕你们的过犯。（有古卷无此节）”"},
{volumn:        41,chapter:        11,versenumber:        27,verse:"他们又来到耶路撒冷。耶稣在殿里行走的时候，祭司长和文士并长老进前来，"},
{volumn:        41,chapter:        11,versenumber:        28,verse:"问他说：“你仗着什么权柄做这些事？给你这权柄的是谁呢？”"},
{volumn:        41,chapter:        11,versenumber:        29,verse:"耶稣对他们说：“我要问你们一句话，你们回答我，我就告诉你们我仗着什么权柄做这些事。"},
{volumn:        41,chapter:        11,versenumber:        30,verse:"约翰的洗礼是从天上来的？是从人间来的呢？你们可以回答我。”"},
{volumn:        41,chapter:        11,versenumber:        31,verse:"他们彼此商议说：“我们若说‘从天上来’，他必说：‘这样，你们为什么不信他呢？’"},
{volumn:        41,chapter:        11,versenumber:        32,verse:"若说‘从人间来’，却又怕百姓，因为众人真以约翰为先知。”"},
{volumn:        41,chapter:        11,versenumber:        33,verse:"于是回答耶稣说：“我们不知道。”耶稣说：“我也不告诉你们我仗着什么权柄做这些事。”"},
{volumn:        41,chapter:        12,versenumber:         1,verse:"耶稣就用比喻对他们说：“有人栽了一个葡萄园，周围圈上篱笆，挖了一个压酒池，盖了一座楼，租给园户，就往外国去了。"},
{volumn:        41,chapter:        12,versenumber:         2,verse:"到了时候，打发一个仆人到园户那里，要从园户收葡萄园的果子。"},
{volumn:        41,chapter:        12,versenumber:         3,verse:"园户拿住他，打了他，叫他空手回去。"},
{volumn:        41,chapter:        12,versenumber:         4,verse:"再打发一个仆人到他们那里。他们打伤他的头，并且凌辱他。"},
{volumn:        41,chapter:        12,versenumber:         5,verse:"又打发一个仆人去，他们就杀了他。后又打发好些仆人去，有被他们打的，有被他们杀的。"},
{volumn:        41,chapter:        12,versenumber:         6,verse:"园主还有一位是他的爱子，末后又打发他去，意思说：‘他们必尊敬我的儿子。’"},
{volumn:        41,chapter:        12,versenumber:         7,verse:"不料，那些园户彼此说：‘这是承受产业的。来吧，我们杀他，产业就归我们了！’"},
{volumn:        41,chapter:        12,versenumber:         8,verse:"于是拿住他，杀了他，把他丢在园外。"},
{volumn:        41,chapter:        12,versenumber:         9,verse:"这样，葡萄园的主人要怎么办呢？他要来除灭那些园户，将葡萄园转给别人。"},
{volumn:        41,chapter:        12,versenumber:        10,verse:"经上写着说：‘匠人所弃的石头已作了房角的头块石头。"},
{volumn:        41,chapter:        12,versenumber:        11,verse:"这是主所做的，在我们眼中看为希奇。’这经你们没有念过吗？”"},
{volumn:        41,chapter:        12,versenumber:        12,verse:"他们看出这比喻是指着他们说的，就想要捉拿他，只是惧怕百姓，于是离开他走了。"},
{volumn:        41,chapter:        12,versenumber:        13,verse:"后来，他们打发几个法利赛人和几个希律党的人到耶稣那里，要就着他的话陷害他。"},
{volumn:        41,chapter:        12,versenumber:        14,verse:"他们来了，就对他说：“夫子，我们知道你是诚实的，什么人你都不徇情面；因为你不看人的外貌，乃是诚诚实实传　神的道。纳税给凯撒可以不可以？"},
{volumn:        41,chapter:        12,versenumber:        15,verse:"我们该纳不该纳？”耶稣知道他们的假意，就对他们说：“你们为什么试探我？拿一个银钱来给我看！”"},
{volumn:        41,chapter:        12,versenumber:        16,verse:"他们就拿了来。耶稣说：“这像和这号是谁的？”他们说：“是凯撒的。”"},
{volumn:        41,chapter:        12,versenumber:        17,verse:"耶稣说：“凯撒的物当归给凯撒，　神的物当归给神。”他们就很希奇他。"},
{volumn:        41,chapter:        12,versenumber:        18,verse:"撒都该人常说没有复活的事。他们来问耶稣说："},
{volumn:        41,chapter:        12,versenumber:        19,verse:"“夫子，摩西为我们写着说：‘人若死了，撇下妻子，没有孩子，他兄弟当娶他的妻，为哥哥生子立后。’"},
{volumn:        41,chapter:        12,versenumber:        20,verse:"有弟兄七人，第一个娶了妻，死了，没有留下孩子。"},
{volumn:        41,chapter:        12,versenumber:        21,verse:"第二个娶了她，也死了，没有留下孩子。第三个也是这样。"},
{volumn:        41,chapter:        12,versenumber:        22,verse:"那七个人都没有留下孩子；末了，那妇人也死了。"},
{volumn:        41,chapter:        12,versenumber:        23,verse:"当复活的时候，她是哪一个的妻子呢？因为他们七个人都娶过她。”"},
{volumn:        41,chapter:        12,versenumber:        24,verse:"耶稣说：“你们所以错了，岂不是因为不明白圣经，不晓得　神的大能吗？"},
{volumn:        41,chapter:        12,versenumber:        25,verse:"人从死里复活，也不娶也不嫁，乃像天上的使者一样。"},
{volumn:        41,chapter:        12,versenumber:        26,verse:"论到死人复活，你们没有念过摩西的书荆棘篇上所载的吗？　神对摩西说：‘我是亚伯拉罕的　神，以撒的　神，雅各的神。’"},
{volumn:        41,chapter:        12,versenumber:        27,verse:"神不是死人的　神，乃是活人的　神。你们是大错了。”"},
{volumn:        41,chapter:        12,versenumber:        28,verse:"有一个文士来，听见他们辩论，晓得耶稣回答的好，就问他说：“诫命中哪是第一要紧的呢？”"},
{volumn:        41,chapter:        12,versenumber:        29,verse:"耶稣回答说：“第一要紧的，就是说：‘以色列啊，你要听，主我们　神是独一的主。"},
{volumn:        41,chapter:        12,versenumber:        30,verse:"你要尽心、尽性、尽意、尽力爱主你的　神。’"},
{volumn:        41,chapter:        12,versenumber:        31,verse:"其次就是说：‘要爱人如己。’再没有比这两条诫命更大的了。”"},
{volumn:        41,chapter:        12,versenumber:        32,verse:"那文士对耶稣说：“夫子说，　神是一位，实在不错；除了他以外，再没有别的　神；"},
{volumn:        41,chapter:        12,versenumber:        33,verse:"并且尽心、尽智、尽力爱他，又爱人如己，就比一切燔祭和各样祭祀好的多。”"},
{volumn:        41,chapter:        12,versenumber:        34,verse:"耶稣见他回答的有智慧，就对他说：“你离　神的国不远了。”从此以后，没有人敢再问他什么。"},
{volumn:        41,chapter:        12,versenumber:        35,verse:"耶稣在殿里教训人，就问他们说：“文士怎么说基督是大卫的子孙呢？"},
{volumn:        41,chapter:        12,versenumber:        36,verse:"大卫被圣灵感动，说：‘主对我主说：你坐在我的右边，等我使你仇敌作你的脚凳。’"},
{volumn:        41,chapter:        12,versenumber:        37,verse:"大卫既自己称他为主，他怎么又是大卫的子孙呢？”众人都喜欢听他。"},
{volumn:        41,chapter:        12,versenumber:        38,verse:"耶稣在教训之间，说：“你们要防备文士；他们好穿长衣游行，喜爱人在街市上问他们的安，"},
{volumn:        41,chapter:        12,versenumber:        39,verse:"又喜爱会堂里的高位，筵席上的首座。"},
{volumn:        41,chapter:        12,versenumber:        40,verse:"他们侵吞寡妇的家产，假意作很长的祷告。这些人要受更重的刑罚！”"},
{volumn:        41,chapter:        12,versenumber:        41,verse:"耶稣对银库坐着，看众人怎样投钱入库。有好些财主往里投了若干的钱。"},
{volumn:        41,chapter:        12,versenumber:        42,verse:"有一个穷寡妇来，往里投了两个小钱，就是一个大钱。"},
{volumn:        41,chapter:        12,versenumber:        43,verse:"耶稣叫门徒来，说：“我实在告诉你们，这穷寡妇投入库里的，比众人所投的更多。"},
{volumn:        41,chapter:        12,versenumber:        44,verse:"因为，他们都是自己有余，拿出来投在里头；但这寡妇是自己不足，把她一切养生的都投上了。”"},
{volumn:        41,chapter:        13,versenumber:         1,verse:"耶稣从殿里出来的时候，有一个门徒对他说：“夫子，请看，这是何等的石头！何等的殿宇！”"},
{volumn:        41,chapter:        13,versenumber:         2,verse:"耶稣对他说：“你看见这大殿宇吗？将来在这里没有一块石头留在石头上，不被拆毁了。”"},
{volumn:        41,chapter:        13,versenumber:         3,verse:"耶稣在橄榄山上对圣殿而坐。彼得、雅各、约翰，和安得烈暗暗地问他说："},
{volumn:        41,chapter:        13,versenumber:         4,verse:"“请告诉我们，什么时候有这些事呢？这一切事将成的时候有什么预兆呢？”"},
{volumn:        41,chapter:        13,versenumber:         5,verse:"耶稣说：“你们要谨慎，免得有人迷惑你们。"},
{volumn:        41,chapter:        13,versenumber:         6,verse:"将来有好些人冒我的名来，说：‘我是基督’，并且要迷惑许多人。"},
{volumn:        41,chapter:        13,versenumber:         7,verse:"你们听见打仗和打仗的风声，不要惊慌。这些事是必须有的，只是末期还没有到。"},
{volumn:        41,chapter:        13,versenumber:         8,verse:"民要攻打民，国要攻打国；多处必有地震、饥荒。这都是灾难（灾难：原文是生产之难）的起头。"},
{volumn:        41,chapter:        13,versenumber:         9,verse:"但你们要谨慎；因为人要把你们交给公会，并且你们在会堂里要受鞭打，又为我的缘故站在诸侯与君王面前，对他们作见证。"},
{volumn:        41,chapter:        13,versenumber:        10,verse:"然而，福音必须先传给万民。"},
{volumn:        41,chapter:        13,versenumber:        11,verse:"人把你们拉去交官的时候，不要预先思虑说什么；到那时候，赐给你们什么话，你们就说什么；因为说话的不是你们，乃是圣灵。"},
{volumn:        41,chapter:        13,versenumber:        12,verse:"弟兄要把弟兄，父亲要把儿子，送到死地；儿女要起来与父母为敌，害死他们；"},
{volumn:        41,chapter:        13,versenumber:        13,verse:"并且你们要为我的名被众人恨恶。惟有忍耐到底的，必然得救。”"},
{volumn:        41,chapter:        13,versenumber:        14,verse:"“你们看见那行毁坏可憎的，站在不当站的地方（读这经的人须要会意）。那时，在犹太的，应当逃到山上；"},
{volumn:        41,chapter:        13,versenumber:        15,verse:"在房上的，不要下来，也不要进去拿家里的东西；"},
{volumn:        41,chapter:        13,versenumber:        16,verse:"在田里的，也不要回去取衣裳。"},
{volumn:        41,chapter:        13,versenumber:        17,verse:"当那些日子，怀孕的和奶孩子的有祸了！"},
{volumn:        41,chapter:        13,versenumber:        18,verse:"你们应当祈求，叫这些事不在冬天临到。"},
{volumn:        41,chapter:        13,versenumber:        19,verse:"因为在那些日子必有灾难，自从　神创造万物直到如今，并没有这样的灾难，后来也必没有。"},
{volumn:        41,chapter:        13,versenumber:        20,verse:"若不是主减少那日子，凡有血气的，总没有一个得救的；只是为主的选民，他将那日子减少了。"},
{volumn:        41,chapter:        13,versenumber:        21,verse:"那时若有人对你们说：‘看哪，基督在这里’，或说：‘基督在那里’，你们不要信！"},
{volumn:        41,chapter:        13,versenumber:        22,verse:"因为假基督、假先知将要起来，显神迹奇事，倘若能行，就把选民迷惑了。"},
{volumn:        41,chapter:        13,versenumber:        23,verse:"你们要谨慎。看哪，凡事我都预先告诉你们了。”"},
{volumn:        41,chapter:        13,versenumber:        24,verse:"“在那些日子，那灾难以后，日头要变黑了，月亮也不放光，"},
{volumn:        41,chapter:        13,versenumber:        25,verse:"众星要从天上坠落，天势都要震动。"},
{volumn:        41,chapter:        13,versenumber:        26,verse:"那时，他们（马太24章30节是“地上的万族”）要看见人子有大能力、大荣耀，驾云降临。"},
{volumn:        41,chapter:        13,versenumber:        27,verse:"他要差遣天使，把他的选民，从四方（方：原文是风），从地极直到天边，都招聚了来。”"},
{volumn:        41,chapter:        13,versenumber:        28,verse:"“你们可以从无花果树学个比方：当树枝发嫩长叶的时候，你们就知道夏天近了。"},
{volumn:        41,chapter:        13,versenumber:        29,verse:"这样，你们几时看见这些事成就，也该知道人子（人子：或译　神的国）近了，正在门口了。"},
{volumn:        41,chapter:        13,versenumber:        30,verse:"我实在告诉你们，这世代还没有过去，这些事都要成就。"},
{volumn:        41,chapter:        13,versenumber:        31,verse:"天地要废去，我的话却不能废去。”"},
{volumn:        41,chapter:        13,versenumber:        32,verse:"“但那日子，那时辰，没有人知道，连天上的使者也不知道，子也不知道，惟有父知道。"},
{volumn:        41,chapter:        13,versenumber:        33,verse:"你们要谨慎，警醒祈祷，因为你们不晓得那日期几时来到。"},
{volumn:        41,chapter:        13,versenumber:        34,verse:"这事正如一个人离开本家，寄居外邦，把权柄交给仆人，分派各人当做的工，又吩咐看门的警醒。"},
{volumn:        41,chapter:        13,versenumber:        35,verse:"所以，你们要警醒；因为你们不知道家主什么时候来，或晚上，或半夜，或鸡叫，或早晨；"},
{volumn:        41,chapter:        13,versenumber:        36,verse:"恐怕他忽然来到，看见你们睡着了。"},
{volumn:        41,chapter:        13,versenumber:        37,verse:"我对你们所说的话，也是对众人说：要警醒！”"},
{volumn:        41,chapter:        14,versenumber:         1,verse:"过两天是逾越节，又是除酵节，祭司长和文士想法子怎么用诡计捉拿耶稣，杀他。"},
{volumn:        41,chapter:        14,versenumber:         2,verse:"只是说：“当节的日子不可，恐怕百姓生乱。”"},
{volumn:        41,chapter:        14,versenumber:         3,verse:"耶稣在伯大尼长大麻风的西门家里坐席的时候，有一个女人拿着一玉瓶至贵的真哪哒香膏来，打破玉瓶，把膏浇在耶稣的头上。"},
{volumn:        41,chapter:        14,versenumber:         4,verse:"有几个人心中很不喜悦，说：“何用这样枉费香膏呢？"},
{volumn:        41,chapter:        14,versenumber:         5,verse:"这香膏可以卖三十多两银子周济穷人。”他们就向那女人生气。"},
{volumn:        41,chapter:        14,versenumber:         6,verse:"耶稣说：“由她吧！为什么难为她呢？她在我身上做的是一件美事。"},
{volumn:        41,chapter:        14,versenumber:         7,verse:"因为常有穷人和你们同在，要向他们行善随时都可以；只是你们不常有我。"},
{volumn:        41,chapter:        14,versenumber:         8,verse:"她所做的，是尽她所能的；她是为我安葬的事把香膏预先浇在我身上。"},
{volumn:        41,chapter:        14,versenumber:         9,verse:"我实在告诉你们，普天之下，无论在什么地方传这福音，也要述说这女人所做的，以为记念。”"},
{volumn:        41,chapter:        14,versenumber:        10,verse:"十二门徒之中，有一个加略人犹大去见祭司长，要把耶稣交给他们。"},
{volumn:        41,chapter:        14,versenumber:        11,verse:"他们听见就欢喜，又应许给他银子；他就寻思如何得便把耶稣交给他们。"},
{volumn:        41,chapter:        14,versenumber:        12,verse:"除酵节的第一天，就是宰逾越羊羔的那一天，门徒对耶稣说：“你吃逾越节的筵席要我们往哪里去预备呢？”"},
{volumn:        41,chapter:        14,versenumber:        13,verse:"耶稣就打发两个门徒，对他们说：“你们进城去，必有人拿着一瓶水迎面而来，你们就跟着他。"},
{volumn:        41,chapter:        14,versenumber:        14,verse:"他进哪家去，你们就对那家的主人说：‘夫子说：客房在哪里？我与门徒好在那里吃逾越节的筵席。’"},
{volumn:        41,chapter:        14,versenumber:        15,verse:"他必指给你们摆设整齐的一间大楼，你们就在那里为我们预备。”"},
{volumn:        41,chapter:        14,versenumber:        16,verse:"门徒出去，进了城，所遇见的正如耶稣所说的。他们就预备了逾越节的筵席。"},
{volumn:        41,chapter:        14,versenumber:        17,verse:"到了晚上，耶稣和十二个门徒都来了。"},
{volumn:        41,chapter:        14,versenumber:        18,verse:"他们坐席正吃的时候，耶稣说：“我实在告诉你们，你们中间有一个与我同吃的人要卖我了。”"},
{volumn:        41,chapter:        14,versenumber:        19,verse:"他们就忧愁起来，一个一个地问他说：“是我吗？”"},
{volumn:        41,chapter:        14,versenumber:        20,verse:"耶稣对他们说：“是十二个门徒中同我蘸手在盘子里的那个人。"},
{volumn:        41,chapter:        14,versenumber:        21,verse:"人子必要去世，正如经上指着他所写的；但卖人子的人有祸了！那人不生在世上倒好。”"},
{volumn:        41,chapter:        14,versenumber:        22,verse:"他们吃的时候，耶稣拿起饼来，祝了福，就擘开，递给他们，说：“你们拿着吃，这是我的身体”；"},
{volumn:        41,chapter:        14,versenumber:        23,verse:"又拿起杯来，祝谢了，递给他们；他们都喝了。"},
{volumn:        41,chapter:        14,versenumber:        24,verse:"耶稣说：“这是我立约的血，为多人流出来的。"},
{volumn:        41,chapter:        14,versenumber:        25,verse:"我实在告诉你们，我不再喝这葡萄汁，直到我在　神的国里喝新的那日子。”"},
{volumn:        41,chapter:        14,versenumber:        26,verse:"他们唱了诗，就出来，往橄榄山去。"},
{volumn:        41,chapter:        14,versenumber:        27,verse:"耶稣对他们说：“你们都要跌倒了，因为经上记着说：‘我要击打牧人，羊就分散了。’"},
{volumn:        41,chapter:        14,versenumber:        28,verse:"但我复活以后，要在你们以先往加利利去。”"},
{volumn:        41,chapter:        14,versenumber:        29,verse:"彼得说：“众人虽然跌倒，我总不能。”"},
{volumn:        41,chapter:        14,versenumber:        30,verse:"耶稣对他说：“我实在告诉你，就在今天夜里，鸡叫两遍以先，你要三次不认我。”"},
{volumn:        41,chapter:        14,versenumber:        31,verse:"彼得却极力地说：“我就是必须和你同死，也总不能不认你。”众门徒都是这样说。"},
{volumn:        41,chapter:        14,versenumber:        32,verse:"他们来到一个地方，名叫客西马尼。耶稣对门徒说：“你们坐在这里，等我祷告。”"},
{volumn:        41,chapter:        14,versenumber:        33,verse:"于是带着彼得、雅各、约翰同去，就惊恐起来，极其难过，"},
{volumn:        41,chapter:        14,versenumber:        34,verse:"对他们说：“我心里甚是忧伤，几乎要死；你们在这里等候，警醒。”"},
{volumn:        41,chapter:        14,versenumber:        35,verse:"他就稍往前走，俯伏在地，祷告说：“倘若可行，便叫那时候过去。”"},
{volumn:        41,chapter:        14,versenumber:        36,verse:"他说：“阿爸！父啊！在你凡事都能；求你将这杯撤去。然而，不要从我的意思，只要从你的意思。”"},
{volumn:        41,chapter:        14,versenumber:        37,verse:"耶稣回来，见他们睡着了，就对彼得说：“西门，你睡觉吗？不能警醒片时吗？"},
{volumn:        41,chapter:        14,versenumber:        38,verse:"总要警醒祷告，免得入了迷惑。你们心灵固然愿意，肉体却软弱了。”"},
{volumn:        41,chapter:        14,versenumber:        39,verse:"耶稣又去祷告，说的话还是与先前一样，"},
{volumn:        41,chapter:        14,versenumber:        40,verse:"又来见他们睡着了，因为他们的眼睛甚是困倦；他们也不知道怎么回答。"},
{volumn:        41,chapter:        14,versenumber:        41,verse:"第三次来，对他们说：“现在你们仍然睡觉安歇吧（吧：或译吗？）！够了，时候到了。看哪，人子被卖在罪人手里了。"},
{volumn:        41,chapter:        14,versenumber:        42,verse:"起来！我们走吧。看哪，那卖我的人近了！”"},
{volumn:        41,chapter:        14,versenumber:        43,verse:"说话之间，忽然那十二个门徒里的犹大来了，并有许多人带着刀棒，从祭司长和文士并长老那里与他同来。"},
{volumn:        41,chapter:        14,versenumber:        44,verse:"卖耶稣的人曾给他们一个暗号，说：“我与谁亲嘴，谁就是他。你们把他拿住，牢牢靠靠地带去。”"},
{volumn:        41,chapter:        14,versenumber:        45,verse:"犹大来了，随即到耶稣跟前，说：“拉比”，便与他亲嘴。"},
{volumn:        41,chapter:        14,versenumber:        46,verse:"他们就下手拿住他。"},
{volumn:        41,chapter:        14,versenumber:        47,verse:"旁边站着的人，有一个拔出刀来，将大祭司的仆人砍了一刀，削掉了他一个耳朵。"},
{volumn:        41,chapter:        14,versenumber:        48,verse:"耶稣对他们说：“你们带着刀棒出来拿我，如同拿强盗吗？"},
{volumn:        41,chapter:        14,versenumber:        49,verse:"我天天教训人，同你们在殿里，你们并没有拿我。但这事成就，为要应验经上的话。”"},
{volumn:        41,chapter:        14,versenumber:        50,verse:"门徒都离开他，逃走了。"},
{volumn:        41,chapter:        14,versenumber:        51,verse:"有一个少年人，赤身披着一块麻布，跟随耶稣，众人就捉拿他。"},
{volumn:        41,chapter:        14,versenumber:        52,verse:"他却丢了麻布，赤身逃走了。"},
{volumn:        41,chapter:        14,versenumber:        53,verse:"他们把耶稣带到大祭司那里，又有众祭司长和长老并文士都来和大祭司一同聚集。"},
{volumn:        41,chapter:        14,versenumber:        54,verse:"彼得远远地跟着耶稣，一直进入大祭司的院里，和差役一同坐在火光里烤火。"},
{volumn:        41,chapter:        14,versenumber:        55,verse:"祭司长和全公会寻找见证控告耶稣，要治死他，却寻不着。"},
{volumn:        41,chapter:        14,versenumber:        56,verse:"因为有好些人作假见证告他，只是他们的见证各不相合。"},
{volumn:        41,chapter:        14,versenumber:        57,verse:"又有几个人站起来作假见证告他，说："},
{volumn:        41,chapter:        14,versenumber:        58,verse:"“我们听见他说：‘我要拆毁这人手所造的殿，三日内就另造一座不是人手所造的。’”"},
{volumn:        41,chapter:        14,versenumber:        59,verse:"他们就是这么作见证，也是各不相合。"},
{volumn:        41,chapter:        14,versenumber:        60,verse:"大祭司起来站在中间，问耶稣说：“你什么都不回答吗？这些人作见证告你的是什么呢？”"},
{volumn:        41,chapter:        14,versenumber:        61,verse:"耶稣却不言语，一句也不回答。大祭司又问他说：“你是那当称颂者的儿子基督不是？”"},
{volumn:        41,chapter:        14,versenumber:        62,verse:"耶稣说：“我是。你们必看见人子坐在那权能者的右边，驾着天上的云降临。”"},
{volumn:        41,chapter:        14,versenumber:        63,verse:"大祭司就撕开衣服，说：“我们何必再用见证人呢？"},
{volumn:        41,chapter:        14,versenumber:        64,verse:"你们已经听见他这僭妄的话了。你们的意见如何？”他们都定他该死的罪。"},
{volumn:        41,chapter:        14,versenumber:        65,verse:"就有人吐唾沫在他脸上，又蒙着他的脸，用拳头打他，对他说：“你说预言吧！”差役接过他来，用手掌打他。"},
{volumn:        41,chapter:        14,versenumber:        66,verse:"彼得在下边院子里；来了大祭司的一个使女，"},
{volumn:        41,chapter:        14,versenumber:        67,verse:"见彼得烤火，就看着他，说：“你素来也是同拿撒勒人耶稣一伙的。”"},
{volumn:        41,chapter:        14,versenumber:        68,verse:"彼得却不承认，说：“我不知道，也不明白你说的是什么。”于是出来，到了前院，鸡就叫了。"},
{volumn:        41,chapter:        14,versenumber:        69,verse:"那使女看见他，又对旁边站着的人说：“这也是他们一党的。”"},
{volumn:        41,chapter:        14,versenumber:        70,verse:"彼得又不承认。过了不多的时候，旁边站着的人又对彼得说：“你真是他们一党的！因为你是加利利人。”"},
{volumn:        41,chapter:        14,versenumber:        71,verse:"彼得就发咒起誓地说：“我不认得你们说的这个人。”"},
{volumn:        41,chapter:        14,versenumber:        72,verse:"立时鸡叫了第二遍。彼得想起耶稣对他所说的话：“鸡叫两遍以先，你要三次不认我。”思想起来，就哭了。"},
{volumn:        41,chapter:        15,versenumber:         1,verse:"一到早晨，祭司长和长老、文士、全公会的人大家商议，就把耶稣捆绑，解去交给彼拉多。"},
{volumn:        41,chapter:        15,versenumber:         2,verse:"彼拉多问他说：“你是犹太人的王吗？”耶稣回答说：“你说的是。”"},
{volumn:        41,chapter:        15,versenumber:         3,verse:"祭司长告他许多的事。"},
{volumn:        41,chapter:        15,versenumber:         4,verse:"彼拉多又问他说：“你看，他们告你这么多的事，你什么都不回答吗？”"},
{volumn:        41,chapter:        15,versenumber:         5,verse:"耶稣仍不回答，以致彼拉多觉得希奇。"},
{volumn:        41,chapter:        15,versenumber:         6,verse:"每逢这节期，巡抚照众人所求的，释放一个囚犯给他们。"},
{volumn:        41,chapter:        15,versenumber:         7,verse:"有一个人名叫巴拉巴，和作乱的人一同捆绑。他们作乱的时候，曾杀过人。"},
{volumn:        41,chapter:        15,versenumber:         8,verse:"众人上去求巡抚，照常例给他们办。"},
{volumn:        41,chapter:        15,versenumber:         9,verse:"彼拉多说：“你们要我释放犹太人的王给你们吗？”"},
{volumn:        41,chapter:        15,versenumber:        10,verse:"他原晓得，祭司长是因为嫉妒才把耶稣解了来。"},
{volumn:        41,chapter:        15,versenumber:        11,verse:"只是祭司长挑唆众人，宁可释放巴拉巴给他们。"},
{volumn:        41,chapter:        15,versenumber:        12,verse:"彼拉多又说：“那么样，你们所称为犹太人的王，我怎么办他呢？”"},
{volumn:        41,chapter:        15,versenumber:        13,verse:"他们又喊着说：“把他钉十字架！”"},
{volumn:        41,chapter:        15,versenumber:        14,verse:"彼拉多说：“为什么呢？他做了什么恶事呢？”他们便极力地喊着说：“把他钉十字架！”"},
{volumn:        41,chapter:        15,versenumber:        15,verse:"彼拉多要叫众人喜悦，就释放巴拉巴给他们，将耶稣鞭打了，交给人钉十字架。"},
{volumn:        41,chapter:        15,versenumber:        16,verse:"兵丁把耶稣带进衙门院里，叫齐了全营的兵。"},
{volumn:        41,chapter:        15,versenumber:        17,verse:"他们给他穿上紫袍，又用荆棘编做冠冕给他戴上，"},
{volumn:        41,chapter:        15,versenumber:        18,verse:"就庆贺他说：“恭喜，犹太人的王啊！”"},
{volumn:        41,chapter:        15,versenumber:        19,verse:"又拿一根苇子打他的头，吐唾沫在他脸上，屈膝拜他。"},
{volumn:        41,chapter:        15,versenumber:        20,verse:"戏弄完了，就给他脱了紫袍，仍穿上他自己的衣服，带他出去，要钉十字架。"},
{volumn:        41,chapter:        15,versenumber:        21,verse:"有一个古利奈人西门，就是亚历山大和鲁孚的父亲，从乡下来，经过那地方，他们就勉强他同去，好背着耶稣的十字架。"},
{volumn:        41,chapter:        15,versenumber:        22,verse:"他们带耶稣到了各各他地方（各各他翻出来就是髑髅地），"},
{volumn:        41,chapter:        15,versenumber:        23,verse:"拿没药调和的酒给耶稣，他却不受。"},
{volumn:        41,chapter:        15,versenumber:        24,verse:"于是将他钉在十字架上，拈阄分他的衣服，看是谁得什么。"},
{volumn:        41,chapter:        15,versenumber:        25,verse:"钉他在十字架上是巳初的时候。"},
{volumn:        41,chapter:        15,versenumber:        26,verse:"在上面有他的罪状，写的是：“犹太人的王。”"},
{volumn:        41,chapter:        15,versenumber:        27,verse:"他们又把两个强盗和他同钉十字架，一个在右边，一个在左边。（有古卷加：28这就应了经上的话说：他被列在罪犯之中。）"},
{volumn:        41,chapter:        15,versenumber:        28,verse:""},
{volumn:        41,chapter:        15,versenumber:        29,verse:"从那里经过的人辱骂他，摇着头说：“咳！你这拆毁圣殿、三日又建造起来的，"},
{volumn:        41,chapter:        15,versenumber:        30,verse:"可以救自己，从十字架上下来吧！”"},
{volumn:        41,chapter:        15,versenumber:        31,verse:"祭司长和文士也是这样戏弄他，彼此说：“他救了别人，不能救自己。"},
{volumn:        41,chapter:        15,versenumber:        32,verse:"以色列的王基督，现在可以从十字架上下来，叫我们看见，就信了。”那和他同钉的人也是讥诮他。"},
{volumn:        41,chapter:        15,versenumber:        33,verse:"从午正到申初，遍地都黑暗了。"},
{volumn:        41,chapter:        15,versenumber:        34,verse:"申初的时候，耶稣大声喊着说：“以罗伊！以罗伊！拉马撒巴各大尼？”（翻出来就是：我的　神！我的　神！为什么离弃我？）"},
{volumn:        41,chapter:        15,versenumber:        35,verse:"旁边站着的人，有的听见就说：“看哪，他叫以利亚呢！”"},
{volumn:        41,chapter:        15,versenumber:        36,verse:"有一个人跑去，把海绒蘸满了醋，绑在苇子上，送给他喝，说：“且等着，看以利亚来不来把他取下。”"},
{volumn:        41,chapter:        15,versenumber:        37,verse:"耶稣大声喊叫，气就断了。"},
{volumn:        41,chapter:        15,versenumber:        38,verse:"殿里的幔子从上到下裂为两半。"},
{volumn:        41,chapter:        15,versenumber:        39,verse:"对面站着的百夫长看见耶稣这样喊叫（有古卷没有喊叫二字）断气，就说：“这人真是　神的儿子！”"},
{volumn:        41,chapter:        15,versenumber:        40,verse:"还有些妇女远远地观看；内中有抹大拉的马利亚，又有小雅各和约西的母亲马利亚，并有撒罗米，"},
{volumn:        41,chapter:        15,versenumber:        41,verse:"就是耶稣在加利利的时候，跟随他、服侍他的那些人，还有同耶稣上耶路撒冷的好些妇女在那里观看。"},
{volumn:        41,chapter:        15,versenumber:        42,verse:"到了晚上，因为这是预备日，就是安息日的前一日，"},
{volumn:        41,chapter:        15,versenumber:        43,verse:"有亚利马太的约瑟前来，他是尊贵的议士，也是等候　神国的。他放胆进去见彼拉多，求耶稣的身体；"},
{volumn:        41,chapter:        15,versenumber:        44,verse:"彼拉多诧异耶稣已经死了，便叫百夫长来，问他耶稣死了久不久。"},
{volumn:        41,chapter:        15,versenumber:        45,verse:"既从百夫长得知实情，就把耶稣的尸首赐给约瑟。"},
{volumn:        41,chapter:        15,versenumber:        46,verse:"约瑟买了细麻布，把耶稣取下来，用细麻布裹好，安放在磐石中凿出来的坟墓里，又滚过一块石头来挡住墓门。"},
{volumn:        41,chapter:        15,versenumber:        47,verse:"抹大拉的马利亚和约西的母亲马利亚都看见安放他的地方。"},
{volumn:        41,chapter:        16,versenumber:         1,verse:"过了安息日，抹大拉的马利亚和雅各的母亲马利亚并撒罗米，买了香膏要去膏耶稣的身体。"},
{volumn:        41,chapter:        16,versenumber:         2,verse:"七日的第一日清早，出太阳的时候，她们来到坟墓那里，"},
{volumn:        41,chapter:        16,versenumber:         3,verse:"彼此说：“谁给我们把石头从墓门滚开呢？”"},
{volumn:        41,chapter:        16,versenumber:         4,verse:"那石头原来很大，她们抬头一看，却见石头已经滚开了。"},
{volumn:        41,chapter:        16,versenumber:         5,verse:"她们进了坟墓，看见一个少年人坐在右边，穿着白袍，就甚惊恐。"},
{volumn:        41,chapter:        16,versenumber:         6,verse:"那少年人对她们说：“不要惊恐！你们寻找那钉十字架的拿撒勒人耶稣，他已经复活了，不在这里。请看安放他的地方。"},
{volumn:        41,chapter:        16,versenumber:         7,verse:"你们可以去告诉他的门徒和彼得，说：‘他在你们以先往加利利去。在那里你们要见他，正如他从前所告诉你们的。’”"},
{volumn:        41,chapter:        16,versenumber:         8,verse:"她们就出来，从坟墓那里逃跑，又发抖又惊奇，什么也不告诉人，因为她们害怕。"},
{volumn:        41,chapter:        16,versenumber:         9,verse:"在七日的第一日清早，耶稣复活了，就先向抹大拉的马利亚显现（耶稣从她身上曾赶出七个鬼）。"},
{volumn:        41,chapter:        16,versenumber:        10,verse:"她去告诉那向来跟随耶稣的人；那时他们正哀恸哭泣。"},
{volumn:        41,chapter:        16,versenumber:        11,verse:"他们听见耶稣活了，被马利亚看见，却是不信。"},
{volumn:        41,chapter:        16,versenumber:        12,verse:"这事以后，门徒中间有两个人往乡下去。走路的时候，耶稣变了形像，向他们显现。"},
{volumn:        41,chapter:        16,versenumber:        13,verse:"他们就去告诉其余的门徒；其余的门徒也是不信。"},
{volumn:        41,chapter:        16,versenumber:        14,verse:"后来，十一个门徒坐席的时候，耶稣向他们显现，责备他们不信，心里刚硬，因为他们不信那些在他复活以后看见他的人。"},
{volumn:        41,chapter:        16,versenumber:        15,verse:"他又对他们说：“你们往普天下去，传福音给万民（万民：原文是凡受造的）听。"},
{volumn:        41,chapter:        16,versenumber:        16,verse:"信而受洗的，必然得救；不信的，必被定罪。"},
{volumn:        41,chapter:        16,versenumber:        17,verse:"信的人必有神迹随着他们，就是奉我的名赶鬼；说新方言；"},
{volumn:        41,chapter:        16,versenumber:        18,verse:"手能拿蛇；若喝了什么毒物，也必不受害；手按病人，病人就必好了。”"},
{volumn:        41,chapter:        16,versenumber:        19,verse:"主耶稣和他们说完了话，后来被接到天上，坐在　神的右边。"},
{volumn:        41,chapter:        16,versenumber:        20,verse:"门徒出去，到处宣传福音。主和他们同工，用神迹随着，证实所传的道。阿们！"},
{volumn:        42,chapter:         1,versenumber:         1,verse:"提阿非罗大人哪，有好些人提笔作书，述说在我们中间所成就的事，是照传道的人从起初亲眼看见又传给我们的。"},
{volumn:        42,chapter:         1,versenumber:         2,verse:""},
{volumn:        42,chapter:         1,versenumber:         3,verse:"这些事我既从起头都详细考察了，就定意要按着次序写给你，"},
{volumn:        42,chapter:         1,versenumber:         4,verse:"使你知道所学之道都是确实的。"},
{volumn:        42,chapter:         1,versenumber:         5,verse:"当犹太王希律的时候，亚比雅班里有一个祭司，名叫撒迦利亚；他妻子是亚伦的后人，名叫伊利莎白。"},
{volumn:        42,chapter:         1,versenumber:         6,verse:"他们二人在　神面前都是义人，遵行主的一切诫命礼仪，没有可指摘的，"},
{volumn:        42,chapter:         1,versenumber:         7,verse:"只是没有孩子；因为伊利莎白不生育，两个人又年纪老迈了。"},
{volumn:        42,chapter:         1,versenumber:         8,verse:"撒迦利亚按班次在　神面前供祭司的职分，"},
{volumn:        42,chapter:         1,versenumber:         9,verse:"照祭司的规矩掣签，得进主殿烧香。"},
{volumn:        42,chapter:         1,versenumber:        10,verse:"烧香的时候，众百姓在外面祷告。"},
{volumn:        42,chapter:         1,versenumber:        11,verse:"有主的使者站在香坛的右边，向他显现。"},
{volumn:        42,chapter:         1,versenumber:        12,verse:"撒迦利亚看见，就惊慌害怕。"},
{volumn:        42,chapter:         1,versenumber:        13,verse:"天使对他说：“撒迦利亚，不要害怕，因为你的祈祷已经被听见了。你的妻子伊利莎白要给你生一个儿子，你要给他起名叫约翰。"},
{volumn:        42,chapter:         1,versenumber:        14,verse:"你必欢喜快乐；有许多人因他出世，也必喜乐。"},
{volumn:        42,chapter:         1,versenumber:        15,verse:"他在主面前将要为大，淡酒浓酒都不喝，从母腹里就被圣灵充满了。"},
{volumn:        42,chapter:         1,versenumber:        16,verse:"他要使许多以色列人回转，归于主他们的　神。"},
{volumn:        42,chapter:         1,versenumber:        17,verse:"他必有以利亚的心志能力，行在主的前面，叫为父的心转向儿女，叫悖逆的人转从义人的智慧，又为主预备合用的百姓。”"},
{volumn:        42,chapter:         1,versenumber:        18,verse:"撒迦利亚对天使说：“我凭着什么可知道这事呢？我已经老了，我的妻子也年纪老迈了。”"},
{volumn:        42,chapter:         1,versenumber:        19,verse:"天使回答说：“我是站在　神面前的加百列，奉差而来对你说话，将这好信息报给你。"},
{volumn:        42,chapter:         1,versenumber:        20,verse:"到了时候，这话必然应验；只因你不信，你必哑巴，不能说话，直到这事成就的日子。”"},
{volumn:        42,chapter:         1,versenumber:        21,verse:"百姓等候撒迦利亚，诧异他许久在殿里。"},
{volumn:        42,chapter:         1,versenumber:        22,verse:"及至他出来，不能和他们说话，他们就知道他在殿里见了异象；因为他直向他们打手式，竟成了哑巴。"},
{volumn:        42,chapter:         1,versenumber:        23,verse:"他供职的日子已满，就回家去了。"},
{volumn:        42,chapter:         1,versenumber:        24,verse:"这些日子以后，他的妻子伊利莎白怀了孕，就隐藏了五个月，"},
{volumn:        42,chapter:         1,versenumber:        25,verse:"说：“主在眷顾我的日子，这样看待我，要把我在人间的羞耻除掉。”"},
{volumn:        42,chapter:         1,versenumber:        26,verse:"到了第六个月，天使加百列奉　神的差遣往加利利的一座城去（这城名叫拿撒勒），"},
{volumn:        42,chapter:         1,versenumber:        27,verse:"到一个童女那里，是已经许配大卫家的一个人，名叫约瑟。童女的名字叫马利亚；"},
{volumn:        42,chapter:         1,versenumber:        28,verse:"天使进去，对她说：“蒙大恩的女子，我问你安，主和你同在了！”"},
{volumn:        42,chapter:         1,versenumber:        29,verse:"马利亚因这话就很惊慌，又反复思想这样问安是什么意思。"},
{volumn:        42,chapter:         1,versenumber:        30,verse:"天使对她说：“马利亚，不要怕！你在　神面前已经蒙恩了。"},
{volumn:        42,chapter:         1,versenumber:        31,verse:"你要怀孕生子，可以给他起名叫耶稣。"},
{volumn:        42,chapter:         1,versenumber:        32,verse:"他要为大，称为至高者的儿子；主　神要把他祖大卫的位给他。"},
{volumn:        42,chapter:         1,versenumber:        33,verse:"他要作雅各家的王，直到永远；他的国也没有穷尽。”"},
{volumn:        42,chapter:         1,versenumber:        34,verse:"马利亚对天使说：“我没有出嫁，怎么有这事呢？”"},
{volumn:        42,chapter:         1,versenumber:        35,verse:"天使回答说：“圣灵要临到你身上，至高者的能力要荫庇你，因此所要生的圣者必称为　神的儿子（或译：所要生的，必称为圣，称为　神的儿子）。"},
{volumn:        42,chapter:         1,versenumber:        36,verse:"况且你的亲戚伊利莎白，在年老的时候也怀了男胎，就是那素来称为不生育的，现在有孕六个月了。"},
{volumn:        42,chapter:         1,versenumber:        37,verse:"因为，出于　神的话，没有一句不带能力的。”"},
{volumn:        42,chapter:         1,versenumber:        38,verse:"马利亚说：“我是主的使女，情愿照你的话成就在我身上。”天使就离开她去了。"},
{volumn:        42,chapter:         1,versenumber:        39,verse:"那时候，马利亚起身，急忙往山地里去，来到犹大的一座城；"},
{volumn:        42,chapter:         1,versenumber:        40,verse:"进了撒迦利亚的家，问伊利莎白安。"},
{volumn:        42,chapter:         1,versenumber:        41,verse:"伊利莎白一听马利亚问安，所怀的胎就在腹里跳动。伊利莎白且被圣灵充满，"},
{volumn:        42,chapter:         1,versenumber:        42,verse:"高声喊着说：“你在妇女中是有福的！你所怀的胎也是有福的！"},
{volumn:        42,chapter:         1,versenumber:        43,verse:"我主的母到我这里来，这是从哪里得的呢？"},
{volumn:        42,chapter:         1,versenumber:        44,verse:"因为你问安的声音一入我耳，我腹里的胎就欢喜跳动。"},
{volumn:        42,chapter:         1,versenumber:        45,verse:"这相信的女子是有福的！因为主对她所说的话都要应验。”"},
{volumn:        42,chapter:         1,versenumber:        46,verse:"马利亚说：“我心尊主为大；"},
{volumn:        42,chapter:         1,versenumber:        47,verse:"我灵以　神我的救主为乐；"},
{volumn:        42,chapter:         1,versenumber:        48,verse:"因为他顾念他使女的卑微；从今以后，万代要称我有福。"},
{volumn:        42,chapter:         1,versenumber:        49,verse:"那有权能的，为我成就了大事；他的名为圣。"},
{volumn:        42,chapter:         1,versenumber:        50,verse:"他怜悯敬畏他的人，直到世世代代。"},
{volumn:        42,chapter:         1,versenumber:        51,verse:"他用膀臂施展大能；那狂傲的人正心里妄想就被他赶散了。"},
{volumn:        42,chapter:         1,versenumber:        52,verse:"他叫有权柄的失位，叫卑贱的升高；"},
{volumn:        42,chapter:         1,versenumber:        53,verse:"叫饥饿的得饱美食，叫富足的空手回去。"},
{volumn:        42,chapter:         1,versenumber:        54,verse:"他扶助了他的仆人以色列，"},
{volumn:        42,chapter:         1,versenumber:        55,verse:"为要记念亚伯拉罕和他的后裔，施怜悯直到永远，正如从前对我们列祖所说的话。”"},
{volumn:        42,chapter:         1,versenumber:        56,verse:"马利亚和伊利莎白同住，约有三个月，就回家去了。"},
{volumn:        42,chapter:         1,versenumber:        57,verse:"伊利莎白的产期到了，就生了一个儿子。"},
{volumn:        42,chapter:         1,versenumber:        58,verse:"邻里亲族听见主向她大施怜悯，就和她一同欢乐。"},
{volumn:        42,chapter:         1,versenumber:        59,verse:"到了第八日，他们来要给孩子行割礼，并要照他父亲的名字叫他撒迦利亚。"},
{volumn:        42,chapter:         1,versenumber:        60,verse:"他母亲说：“不可！要叫他约翰。”"},
{volumn:        42,chapter:         1,versenumber:        61,verse:"他们说：“你亲族中没有叫这名字的。”"},
{volumn:        42,chapter:         1,versenumber:        62,verse:"他们就向他父亲打手式，问他要叫这孩子什么名字。"},
{volumn:        42,chapter:         1,versenumber:        63,verse:"他要了一块写字的板，就写上，说：“他的名字是约翰。”他们便都希奇。"},
{volumn:        42,chapter:         1,versenumber:        64,verse:"撒迦利亚的口立时开了，舌头也舒展了，就说出话来，称颂　神。"},
{volumn:        42,chapter:         1,versenumber:        65,verse:"周围居住的人都惧怕；这一切的事就传遍了犹太的山地。"},
{volumn:        42,chapter:         1,versenumber:        66,verse:"凡听见的人都将这事放在心里，说：“这个孩子将来怎么样呢？”因为有主与他同在。"},
{volumn:        42,chapter:         1,versenumber:        67,verse:"他父亲撒迦利亚被圣灵充满了，就预言说："},
{volumn:        42,chapter:         1,versenumber:        68,verse:"“主以色列的　神是应当称颂的！因他眷顾他的百姓，为他们施行救赎，"},
{volumn:        42,chapter:         1,versenumber:        69,verse:"在他仆人大卫家中，为我们兴起了拯救的角，"},
{volumn:        42,chapter:         1,versenumber:        70,verse:"正如主藉着从创世以来圣先知的口所说的话，"},
{volumn:        42,chapter:         1,versenumber:        71,verse:"拯救我们脱离仇敌和一切恨我们之人的手，"},
{volumn:        42,chapter:         1,versenumber:        72,verse:"向我们列祖施怜悯，记念他的圣约，"},
{volumn:        42,chapter:         1,versenumber:        73,verse:"就是他对我们祖宗亚伯拉罕所起的誓，"},
{volumn:        42,chapter:         1,versenumber:        74,verse:"叫我们既从仇敌手中被救出来，"},
{volumn:        42,chapter:         1,versenumber:        75,verse:"就可以终身在他面前，坦然无惧地用圣洁、公义侍奉他。"},
{volumn:        42,chapter:         1,versenumber:        76,verse:"孩子啊！你要称为至高者的先知；因为你要行在主的前面，预备他的道路，"},
{volumn:        42,chapter:         1,versenumber:        77,verse:"叫他的百姓因罪得赦，就知道救恩。"},
{volumn:        42,chapter:         1,versenumber:        78,verse:"因我们　神怜悯的心肠，叫清晨的日光从高天临到我们，"},
{volumn:        42,chapter:         1,versenumber:        79,verse:"要照亮坐在黑暗中死荫里的人，把我们的脚引到平安的路上。”"},
{volumn:        42,chapter:         1,versenumber:        80,verse:"那孩子渐渐长大，心灵强健，住在旷野，直到他显明在以色列人面前的日子。"},
{volumn:        42,chapter:         2,versenumber:         1,verse:"当那些日子，凯撒奥古斯都有旨意下来，叫天下人民都报名上册。"},
{volumn:        42,chapter:         2,versenumber:         2,verse:"这是居里扭作叙利亚巡抚的时候，头一次行报名上册的事。"},
{volumn:        42,chapter:         2,versenumber:         3,verse:"众人各归各城，报名上册。"},
{volumn:        42,chapter:         2,versenumber:         4,verse:"约瑟也从加利利的拿撒勒城上犹太去，到了大卫的城，名叫伯利恒，因他本是大卫一族一家的人，"},
{volumn:        42,chapter:         2,versenumber:         5,verse:"要和他所聘之妻马利亚一同报名上册。那时马利亚的身孕已经重了。"},
{volumn:        42,chapter:         2,versenumber:         6,verse:"他们在那里的时候，马利亚的产期到了，"},
{volumn:        42,chapter:         2,versenumber:         7,verse:"就生了头胎的儿子，用布包起来，放在马槽里，因为客店里没有地方。"},
{volumn:        42,chapter:         2,versenumber:         8,verse:"在伯利恒之野地里有牧羊的人，夜间按着更次看守羊群。"},
{volumn:        42,chapter:         2,versenumber:         9,verse:"有主的使者站在他们旁边，主的荣光四面照着他们；牧羊的人就甚惧怕。"},
{volumn:        42,chapter:         2,versenumber:        10,verse:"那天使对他们说：“不要惧怕！我报给你们大喜的信息，是关乎万民的；"},
{volumn:        42,chapter:         2,versenumber:        11,verse:"因今天在大卫的城里，为你们生了救主，就是主基督。"},
{volumn:        42,chapter:         2,versenumber:        12,verse:"你们要看见一个婴孩，包着布，卧在马槽里，那就是记号了。”"},
{volumn:        42,chapter:         2,versenumber:        13,verse:"忽然，有一大队天兵同那天使赞美　神说："},
{volumn:        42,chapter:         2,versenumber:        14,verse:"“在至高之处荣耀归与　神！在地上平安归与他所喜悦的人（有古卷：喜悦归与人）！”"},
{volumn:        42,chapter:         2,versenumber:        15,verse:"众天使离开他们，升天去了。牧羊的人彼此说：“我们往伯利恒去，看看所成的事，就是主所指示我们的。”"},
{volumn:        42,chapter:         2,versenumber:        16,verse:"他们急忙去了，就寻见马利亚和约瑟，又有那婴孩卧在马槽里；"},
{volumn:        42,chapter:         2,versenumber:        17,verse:"既然看见，就把天使论这孩子的话传开了。"},
{volumn:        42,chapter:         2,versenumber:        18,verse:"凡听见的，就诧异牧羊之人对他们所说的话。"},
{volumn:        42,chapter:         2,versenumber:        19,verse:"马利亚却把这一切的事存在心里，反复思想。"},
{volumn:        42,chapter:         2,versenumber:        20,verse:"牧羊的人回去了，因所听见所看见的一切事，正如天使向他们所说的，就归荣耀与　神，赞美他。"},
{volumn:        42,chapter:         2,versenumber:        21,verse:"满了八天，就给孩子行割礼，与他起名叫耶稣；这就是没有成胎以前，天使所起的名。"},
{volumn:        42,chapter:         2,versenumber:        22,verse:"按摩西律法满了洁净的日子，他们带着孩子上耶路撒冷去，要把他献与主，（"},
{volumn:        42,chapter:         2,versenumber:        23,verse:"正如主的律法上所记：“凡头生的男子必称圣归主。”）"},
{volumn:        42,chapter:         2,versenumber:        24,verse:"又要照主的律法上所说，或用一对斑鸠，或用两只雏鸽献祭。"},
{volumn:        42,chapter:         2,versenumber:        25,verse:"在耶路撒冷有一个人，名叫西面；这人又公义又虔诚，素常盼望以色列的安慰者来到，又有圣灵在他身上。"},
{volumn:        42,chapter:         2,versenumber:        26,verse:"他得了圣灵的启示，知道自己未死以前，必看见主所立的基督。"},
{volumn:        42,chapter:         2,versenumber:        27,verse:"他受了圣灵的感动，进入圣殿，正遇见耶稣的父母抱着孩子进来，要照律法的规矩办理。"},
{volumn:        42,chapter:         2,versenumber:        28,verse:"西面就用手接过他来，称颂　神说："},
{volumn:        42,chapter:         2,versenumber:        29,verse:"“主啊！如今可以照你的话，释放仆人安然去世；"},
{volumn:        42,chapter:         2,versenumber:        30,verse:"因为我的眼睛已经看见你的救恩，"},
{volumn:        42,chapter:         2,versenumber:        31,verse:"就是你在万民面前所预备的，"},
{volumn:        42,chapter:         2,versenumber:        32,verse:"是照亮外邦人的光，又是你民以色列的荣耀。”"},
{volumn:        42,chapter:         2,versenumber:        33,verse:"孩子的父母因这论耶稣的话就希奇。"},
{volumn:        42,chapter:         2,versenumber:        34,verse:"西面给他们祝福，又对孩子的母亲马利亚说：“这孩子被立，是要叫以色列中许多人跌倒，许多人兴起；又要作毁谤的话柄，叫许多人心里的意念显露出来；你自己的心也要被刀刺透。”"},
{volumn:        42,chapter:         2,versenumber:        35,verse:""},
{volumn:        42,chapter:         2,versenumber:        36,verse:"又有女先知，名叫亚拿，是亚设支派法内力的女儿，年纪已经老迈，从作童女出嫁的时候，同丈夫住了七年就寡居了，"},
{volumn:        42,chapter:         2,versenumber:        37,verse:"现在已经八十四岁（或译：就寡居了八十四年），并不离开圣殿，禁食祈求，昼夜侍奉　神。"},
{volumn:        42,chapter:         2,versenumber:        38,verse:"正当那时，她进前来称谢　神，将孩子的事对一切盼望耶路撒冷得救赎的人讲说。"},
{volumn:        42,chapter:         2,versenumber:        39,verse:"约瑟和马利亚照主的律法办完了一切的事，就回加利利，到自己的城拿撒勒去了。"},
{volumn:        42,chapter:         2,versenumber:        40,verse:"孩子渐渐长大，强健起来，充满智慧，又有　神的恩在他身上。"},
{volumn:        42,chapter:         2,versenumber:        41,verse:"每年到逾越节，他父母就上耶路撒冷去。"},
{volumn:        42,chapter:         2,versenumber:        42,verse:"当他十二岁的时候，他们按着节期的规矩上去。"},
{volumn:        42,chapter:         2,versenumber:        43,verse:"守满了节期，他们回去，孩童耶稣仍旧在耶路撒冷。他的父母并不知道，"},
{volumn:        42,chapter:         2,versenumber:        44,verse:"以为他在同行的人中间，走了一天的路程，就在亲族和熟识的人中找他，"},
{volumn:        42,chapter:         2,versenumber:        45,verse:"既找不着，就回耶路撒冷去找他。"},
{volumn:        42,chapter:         2,versenumber:        46,verse:"过了三天，就遇见他在殿里，坐在教师中间，一面听，一面问。"},
{volumn:        42,chapter:         2,versenumber:        47,verse:"凡听见他的，都希奇他的聪明和他的应对。"},
{volumn:        42,chapter:         2,versenumber:        48,verse:"他父母看见就很希奇。他母亲对他说：“我儿！为什么向我们这样行呢？看哪，你父亲和我伤心来找你！”"},
{volumn:        42,chapter:         2,versenumber:        49,verse:"耶稣说：“为什么找我呢？岂不知我应当以我父的事为念吗（或译：岂不知我应当在我父的家里吗）？”"},
{volumn:        42,chapter:         2,versenumber:        50,verse:"他所说的这话，他们不明白。"},
{volumn:        42,chapter:         2,versenumber:        51,verse:"他就同他们下去，回到拿撒勒，并且顺从他们。他母亲把这一切的事都存在心里。"},
{volumn:        42,chapter:         2,versenumber:        52,verse:"耶稣的智慧和身量（或译：年纪），并　神和人喜爱他的心，都一齐增长。"},
{volumn:        42,chapter:         3,versenumber:         1,verse:"凯撒提庇留在位第十五年，本丢·彼拉多作犹太巡抚，希律作加利利分封的王，他兄弟腓力作以土利亚和特拉可尼地方分封的王，吕撒聂作亚比利尼分封的王，"},
{volumn:        42,chapter:         3,versenumber:         2,verse:"亚那和该亚法作大祭司。那时，撒迦利亚的儿子约翰在旷野里，　神的话临到他。"},
{volumn:        42,chapter:         3,versenumber:         3,verse:"他就来到约旦河一带地方，宣讲悔改的洗礼，使罪得赦。"},
{volumn:        42,chapter:         3,versenumber:         4,verse:"正如先知以赛亚书上所记的话，说：“在旷野有人声喊着说：‘预备主的道，修直他的路！"},
{volumn:        42,chapter:         3,versenumber:         5,verse:"一切山洼都要填满；大小山冈都要削平！弯弯曲曲的地方要改为正直；高高低低的道路要改为平坦！"},
{volumn:        42,chapter:         3,versenumber:         6,verse:"凡有血气的，都要见　神的救恩！’”"},
{volumn:        42,chapter:         3,versenumber:         7,verse:"约翰对那出来要受他洗的众人说：“毒蛇的种类！谁指示你们逃避将来的忿怒呢？"},
{volumn:        42,chapter:         3,versenumber:         8,verse:"你们要结出果子来，与悔改的心相称。不要自己心里说：‘有亚伯拉罕为我们的祖宗。’我告诉你们，　神能从这些石头中，给亚伯拉罕兴起子孙来。"},
{volumn:        42,chapter:         3,versenumber:         9,verse:"现在斧子已经放在树根上，凡不结好果子的树就砍下来，丢在火里。”"},
{volumn:        42,chapter:         3,versenumber:        10,verse:"众人问他说：“这样，我们当做什么呢？”"},
{volumn:        42,chapter:         3,versenumber:        11,verse:"约翰回答说：“有两件衣裳的，就分给那没有的；有食物的，也当这样行。”"},
{volumn:        42,chapter:         3,versenumber:        12,verse:"又有税吏来要受洗，问他说：“夫子，我们当做什么呢？”"},
{volumn:        42,chapter:         3,versenumber:        13,verse:"约翰说：“除了例定的数目，不要多取。”"},
{volumn:        42,chapter:         3,versenumber:        14,verse:"又有兵丁问他说：“我们当做什么呢？”约翰说：“不要以强暴待人，也不要讹诈人，自己有钱粮就当知足。”"},
{volumn:        42,chapter:         3,versenumber:        15,verse:"百姓指望基督来的时候，人都心里猜疑，或者约翰是基督。"},
{volumn:        42,chapter:         3,versenumber:        16,verse:"约翰说：“我是用水给你们施洗，但有一位能力比我更大的要来，我就是给他解鞋带也不配。他要用圣灵与火给你们施洗。"},
{volumn:        42,chapter:         3,versenumber:        17,verse:"他手里拿着簸箕，要扬净他的场，把麦子收在仓里，把糠用不灭的火烧尽了。”"},
{volumn:        42,chapter:         3,versenumber:        18,verse:"约翰又用许多别的话劝百姓，向他们传福音。"},
{volumn:        42,chapter:         3,versenumber:        19,verse:"只是分封的王希律，因他兄弟之妻希罗底的缘故，并因他所行的一切恶事，受了约翰的责备；"},
{volumn:        42,chapter:         3,versenumber:        20,verse:"又另外添了一件，就是把约翰收在监里。"},
{volumn:        42,chapter:         3,versenumber:        21,verse:"众百姓都受了洗，耶稣也受了洗。正祷告的时候，天就开了，"},
{volumn:        42,chapter:         3,versenumber:        22,verse:"圣灵降临在他身上，形状仿佛鸽子；又有声音从天上来，说：“你是我的爱子，我喜悦你。”"},
{volumn:        42,chapter:         3,versenumber:        23,verse:"耶稣开头传道，年纪约有三十岁。依人看来，他是约瑟的儿子；约瑟是希里的儿子；"},
{volumn:        42,chapter:         3,versenumber:        24,verse:"希里是玛塔的儿子；玛塔是利未的儿子；利未是麦基的儿子；麦基是雅拿的儿子；雅拿是约瑟的儿子；"},
{volumn:        42,chapter:         3,versenumber:        25,verse:"约瑟是玛他提亚的儿子；玛他提亚是亚摩斯的儿子；亚摩斯是拿鸿的儿子；拿鸿是以斯利的儿子；以斯利是拿该的儿子；"},
{volumn:        42,chapter:         3,versenumber:        26,verse:"拿该是玛押的儿子；玛押是玛他提亚的儿子；玛他提亚是西美的儿子；西美是约瑟的儿子；约瑟是犹大的儿子；犹大是约亚拿的儿子；"},
{volumn:        42,chapter:         3,versenumber:        27,verse:"约亚拿是利撒的儿子；利撒是所罗巴伯的儿子；所罗巴伯是撒拉铁的儿子；撒拉铁是尼利的儿子；尼利是麦基的儿子；"},
{volumn:        42,chapter:         3,versenumber:        28,verse:"麦基是亚底的儿子；亚底是哥桑的儿子；哥桑是以摩当的儿子；以摩当是珥的儿子；珥是约细的儿子；"},
{volumn:        42,chapter:         3,versenumber:        29,verse:"约细是以利以谢的儿子；以利以谢是约令的儿子；约令是玛塔的儿子；玛塔是利未的儿子；"},
{volumn:        42,chapter:         3,versenumber:        30,verse:"利未是西缅的儿子；西缅是犹大的儿子；犹大是约瑟的儿子；约瑟是约南的儿子；约南是以利亚敬的儿子；"},
{volumn:        42,chapter:         3,versenumber:        31,verse:"以利亚敬是米利亚的儿子；米利亚是买南的儿子；买南是玛达他的儿子；玛达他是拿单的儿子；拿单是大卫的儿子；"},
{volumn:        42,chapter:         3,versenumber:        32,verse:"大卫是耶西的儿子；耶西是俄备得的儿子；俄备得是波阿斯的儿子；波阿斯是撒门的儿子；撒门是拿顺的儿子；"},
{volumn:        42,chapter:         3,versenumber:        33,verse:"拿顺是亚米拿达的儿子；亚米拿达是亚兰的儿子；亚兰是希斯仑的儿子；希斯仑是法勒斯的儿子；法勒斯是犹大的儿子；"},
{volumn:        42,chapter:         3,versenumber:        34,verse:"犹大是雅各的儿子；雅各是以撒的儿子；以撒是亚伯拉罕的儿子；亚伯拉罕是他拉的儿子；他拉是拿鹤的儿子；"},
{volumn:        42,chapter:         3,versenumber:        35,verse:"拿鹤是西鹿的儿子；西鹿是拉吴的儿子；拉吴是法勒的儿子；法勒是希伯的儿子；希伯是沙拉的儿子；"},
{volumn:        42,chapter:         3,versenumber:        36,verse:"沙拉是该南的儿子；该南是亚法撒的儿子；亚法撒是闪的儿子；闪是挪亚的儿子；挪亚是拉麦的儿子；"},
{volumn:        42,chapter:         3,versenumber:        37,verse:"拉麦是玛土撒拉的儿子；玛土撒拉是以诺的儿子；以诺是雅列的儿子；雅列是玛勒列的儿子；玛勒列是该南的儿子；该南是以挪士的儿子；"},
{volumn:        42,chapter:         3,versenumber:        38,verse:"以挪士是塞特的儿子；塞特是亚当的儿子；亚当是　神的儿子。"},
{volumn:        42,chapter:         4,versenumber:         1,verse:"耶稣被圣灵充满，从约旦河回来，圣灵将他引到旷野，"},
{volumn:        42,chapter:         4,versenumber:         2,verse:"四十天受魔鬼的试探。那些日子没有吃什么；日子满了，他就饿了。"},
{volumn:        42,chapter:         4,versenumber:         3,verse:"魔鬼对他说：“你若是　神的儿子，可以吩咐这块石头变成食物。”"},
{volumn:        42,chapter:         4,versenumber:         4,verse:"耶稣回答说：“经上记着说：‘人活着不是单靠食物，乃是靠　神口里所出的一切话。’”"},
{volumn:        42,chapter:         4,versenumber:         5,verse:"魔鬼又领他上了高山，霎时间把天下的万国都指给他看，"},
{volumn:        42,chapter:         4,versenumber:         6,verse:"对他说：“这一切权柄、荣华，我都要给你，因为这原是交付我的，我愿意给谁就给谁。"},
{volumn:        42,chapter:         4,versenumber:         7,verse:"你若在我面前下拜，这都要归你。”"},
{volumn:        42,chapter:         4,versenumber:         8,verse:"耶稣说：“经上记着说：‘当拜主你的　神，单要侍奉他。’”"},
{volumn:        42,chapter:         4,versenumber:         9,verse:"魔鬼又领他到耶路撒冷去，叫他站在殿顶（顶：原文是翅）上，对他说：“你若是　神的儿子，可以从这里跳下去；"},
{volumn:        42,chapter:         4,versenumber:        10,verse:"因为经上记着说：‘主要为你吩咐他的使者保护你；"},
{volumn:        42,chapter:         4,versenumber:        11,verse:"他们要用手托着你，免得你的脚碰在石头上。’”"},
{volumn:        42,chapter:         4,versenumber:        12,verse:"耶稣对他说：“经上说：‘不可试探主你的　神。’”"},
{volumn:        42,chapter:         4,versenumber:        13,verse:"魔鬼用完了各样的试探，就暂时离开耶稣。"},
{volumn:        42,chapter:         4,versenumber:        14,verse:"耶稣满有圣灵的能力，回到加利利；他的名声就传遍了四方。"},
{volumn:        42,chapter:         4,versenumber:        15,verse:"他在各会堂里教训人，众人都称赞他。"},
{volumn:        42,chapter:         4,versenumber:        16,verse:"耶稣来到拿撒勒，就是他长大的地方。在安息日，照他平常的规矩进了会堂，站起来要念圣经。"},
{volumn:        42,chapter:         4,versenumber:        17,verse:"有人把先知以赛亚的书交给他，他就打开，找到一处写着说："},
{volumn:        42,chapter:         4,versenumber:        18,verse:"“主的灵在我身上，因为他用膏膏我，叫我传福音给贫穷的人；差遣我报告：被掳的得释放，瞎眼的得看见，叫那受压制的得自由，"},
{volumn:        42,chapter:         4,versenumber:        19,verse:"报告　神悦纳人的禧年。”"},
{volumn:        42,chapter:         4,versenumber:        20,verse:"于是把书卷起来，交还执事，就坐下。会堂里的人都定睛看他。"},
{volumn:        42,chapter:         4,versenumber:        21,verse:"耶稣对他们说：“今天这经应验在你们耳中了。”"},
{volumn:        42,chapter:         4,versenumber:        22,verse:"众人都称赞他，并希奇他口中所出的恩言；又说：“这不是约瑟的儿子吗？”"},
{volumn:        42,chapter:         4,versenumber:        23,verse:"耶稣对他们说：“你们必引这俗语向我说：‘医生，你医治自己吧！我们听见你在迦百农所行的事，也当行在你自己家乡里’”；"},
{volumn:        42,chapter:         4,versenumber:        24,verse:"又说：“我实在告诉你们，没有先知在自己家乡被人悦纳的。"},
{volumn:        42,chapter:         4,versenumber:        25,verse:"我对你们说实话，当以利亚的时候，天闭塞了三年零六个月，遍地有大饥荒，那时，以色列中有许多寡妇，"},
{volumn:        42,chapter:         4,versenumber:        26,verse:"以利亚并没有奉差往她们一个人那里去，只奉差往西顿的撒勒法一个寡妇那里去。"},
{volumn:        42,chapter:         4,versenumber:        27,verse:"先知以利沙的时候，以色列中有许多长大麻风的，但内中除了叙利亚国的乃缦，没有一个得洁净的。”"},
{volumn:        42,chapter:         4,versenumber:        28,verse:"会堂里的人听见这话，都怒气满胸，"},
{volumn:        42,chapter:         4,versenumber:        29,verse:"就起来撵他出城（他们的城造在山上）；他们带他到山崖，要把他推下去。"},
{volumn:        42,chapter:         4,versenumber:        30,verse:"他却从他们中间直行，过去了。"},
{volumn:        42,chapter:         4,versenumber:        31,verse:"耶稣下到迦百农，就是加利利的一座城，在安息日教训众人。"},
{volumn:        42,chapter:         4,versenumber:        32,verse:"他们很希奇他的教训，因为他的话里有权柄。"},
{volumn:        42,chapter:         4,versenumber:        33,verse:"在会堂里有一个人，被污鬼的精气附着，大声喊叫说："},
{volumn:        42,chapter:         4,versenumber:        34,verse:"“唉！拿撒勒的耶稣，我们与你有什么相干？你来灭我们吗？我知道你是谁，乃是　神的圣者。”"},
{volumn:        42,chapter:         4,versenumber:        35,verse:"耶稣责备他说：“不要作声，从这人身上出来吧！”鬼把那人摔倒在众人中间，就出来了，却也没有害他。"},
{volumn:        42,chapter:         4,versenumber:        36,verse:"众人都惊讶，彼此对问说：“这是什么道理呢？因为他用权柄能力吩咐污鬼，污鬼就出来。”"},
{volumn:        42,chapter:         4,versenumber:        37,verse:"于是耶稣的名声传遍了周围地方。"},
{volumn:        42,chapter:         4,versenumber:        38,verse:"耶稣出了会堂，进了西门的家。西门的岳母害热病甚重，有人为她求耶稣。"},
{volumn:        42,chapter:         4,versenumber:        39,verse:"耶稣站在她旁边，斥责那热病，热就退了。她立刻起来服侍他们。"},
{volumn:        42,chapter:         4,versenumber:        40,verse:"日落的时候，凡有病人的，不论害什么病，都带到耶稣那里。耶稣按手在他们各人身上，医好他们。"},
{volumn:        42,chapter:         4,versenumber:        41,verse:"又有鬼从好些人身上出来，喊着说：“你是　神的儿子。”耶稣斥责他们，不许他们说话，因为他们知道他是基督。"},
{volumn:        42,chapter:         4,versenumber:        42,verse:"天亮的时候，耶稣出来，走到旷野地方。众人去找他，到了他那里，要留住他，不要他离开他们。"},
{volumn:        42,chapter:         4,versenumber:        43,verse:"但耶稣对他们说：“我也必须在别城传　神国的福音，因我奉差原是为此。”"},
{volumn:        42,chapter:         4,versenumber:        44,verse:"于是耶稣在加利利的各会堂传道。"},
{volumn:        42,chapter:         5,versenumber:         1,verse:"耶稣站在革尼撒勒湖边，众人拥挤他，要听　神的道。"},
{volumn:        42,chapter:         5,versenumber:         2,verse:"他见有两只船湾在湖边；打鱼的人却离开船洗网去了。"},
{volumn:        42,chapter:         5,versenumber:         3,verse:"有一只船是西门的，耶稣就上去，请他把船撑开，稍微离岸，就坐下，从船上教训众人。"},
{volumn:        42,chapter:         5,versenumber:         4,verse:"讲完了，对西门说：“把船开到水深之处，下网打鱼。”"},
{volumn:        42,chapter:         5,versenumber:         5,verse:"西门说：“夫子，我们整夜劳力，并没有打着什么。但依从你的话，我就下网。”"},
{volumn:        42,chapter:         5,versenumber:         6,verse:"他们下了网，就圈住许多鱼，网险些裂开，"},
{volumn:        42,chapter:         5,versenumber:         7,verse:"便招呼那只船上的同伴来帮助。他们就来，把鱼装满了两只船，甚至船要沉下去。"},
{volumn:        42,chapter:         5,versenumber:         8,verse:"西门·彼得看见，就俯伏在耶稣膝前，说：“主啊，离开我，我是个罪人！”"},
{volumn:        42,chapter:         5,versenumber:         9,verse:"他和一切同在的人都惊讶这一网所打的鱼。"},
{volumn:        42,chapter:         5,versenumber:        10,verse:"他的伙伴西庇太的儿子雅各、约翰，也是这样。耶稣对西门说：“不要怕！从今以后，你要得人了。”"},
{volumn:        42,chapter:         5,versenumber:        11,verse:"他们把两只船拢了岸，就撇下所有的，跟从了耶稣。"},
{volumn:        42,chapter:         5,versenumber:        12,verse:"有一回，耶稣在一个城里，有人满身长了大麻风，看见他，就俯伏在地，求他说：“主若肯，必能叫我洁净了。”"},
{volumn:        42,chapter:         5,versenumber:        13,verse:"耶稣伸手摸他，说：“我肯，你洁净了吧！”大麻风立刻就离了他的身。"},
{volumn:        42,chapter:         5,versenumber:        14,verse:"耶稣嘱咐他：“你切不可告诉人，只要去把身体给祭司察看，又要为你得了洁净，照摩西所吩咐的献上礼物，对众人作证据。”"},
{volumn:        42,chapter:         5,versenumber:        15,verse:"但耶稣的名声越发传扬出去。有极多的人聚集来听道，也指望医治他们的病。"},
{volumn:        42,chapter:         5,versenumber:        16,verse:"耶稣却退到旷野去祷告。"},
{volumn:        42,chapter:         5,versenumber:        17,verse:"有一天，耶稣教训人，有法利赛人和教法师在旁边坐着；他们是从加利利各乡村和犹太并耶路撒冷来的。主的能力与耶稣同在，使他能医治病人。"},
{volumn:        42,chapter:         5,versenumber:        18,verse:"有人用褥子抬着一个瘫子，要抬进去放在耶稣面前，"},
{volumn:        42,chapter:         5,versenumber:        19,verse:"却因人多，寻不出法子抬进去，就上了房顶，从瓦间把他连褥子缒到当中，正在耶稣面前。"},
{volumn:        42,chapter:         5,versenumber:        20,verse:"耶稣见他们的信心，就对瘫子说：“你的罪赦了。”"},
{volumn:        42,chapter:         5,versenumber:        21,verse:"文士和法利赛人就议论说：“这说僭妄话的是谁？除了　神以外，谁能赦罪呢？”"},
{volumn:        42,chapter:         5,versenumber:        22,verse:"耶稣知道他们所议论的，就说：“你们心里议论的是什么呢？"},
{volumn:        42,chapter:         5,versenumber:        23,verse:"或说‘你的罪赦了’，或说‘你起来行走’，哪一样容易呢？"},
{volumn:        42,chapter:         5,versenumber:        24,verse:"但要叫你们知道，人子在地上有赦罪的权柄。”就对瘫子说：“我吩咐你，起来，拿你的褥子回家去吧！”"},
{volumn:        42,chapter:         5,versenumber:        25,verse:"那人当众人面前立刻起来，拿着他所躺卧的褥子回家去，归荣耀与　神。"},
{volumn:        42,chapter:         5,versenumber:        26,verse:"众人都惊奇，也归荣耀与　神，并且满心惧怕，说：“我们今日看见非常的事了。”"},
{volumn:        42,chapter:         5,versenumber:        27,verse:"这事以后，耶稣出去，看见一个税吏，名叫利未，坐在税关上，就对他说：“你跟从我来。”"},
{volumn:        42,chapter:         5,versenumber:        28,verse:"他就撇下所有的，起来，跟从了耶稣。"},
{volumn:        42,chapter:         5,versenumber:        29,verse:"利未在自己家里为耶稣大摆筵席，有许多税吏和别人与他们一同坐席。"},
{volumn:        42,chapter:         5,versenumber:        30,verse:"法利赛人和文士就向耶稣的门徒发怨言说：“你们为什么和税吏并罪人一同吃喝呢？”"},
{volumn:        42,chapter:         5,versenumber:        31,verse:"耶稣对他们说：“无病的人用不着医生；有病的人才用得着。"},
{volumn:        42,chapter:         5,versenumber:        32,verse:"我来本不是召义人悔改，乃是召罪人悔改。”"},
{volumn:        42,chapter:         5,versenumber:        33,verse:"他们说：“约翰的门徒屡次禁食祈祷，法利赛人的门徒也是这样；惟独你的门徒又吃又喝。”"},
{volumn:        42,chapter:         5,versenumber:        34,verse:"耶稣对他们说：“新郎和陪伴之人同在的时候，岂能叫陪伴之人禁食呢？"},
{volumn:        42,chapter:         5,versenumber:        35,verse:"但日子将到，新郎要离开他们，那日他们就要禁食了。”"},
{volumn:        42,chapter:         5,versenumber:        36,verse:"耶稣又设一个比喻，对他们说：“没有人把新衣服撕下一块来补在旧衣服上；若是这样，就把新的撕破了，并且所撕下来的那块新的和旧的也不相称。"},
{volumn:        42,chapter:         5,versenumber:        37,verse:"也没有人把新酒装在旧皮袋里；若是这样，新酒必将皮袋裂开，酒便漏出来，皮袋也就坏了。"},
{volumn:        42,chapter:         5,versenumber:        38,verse:"但新酒必须装在新皮袋里。"},
{volumn:        42,chapter:         5,versenumber:        39,verse:"没有人喝了陈酒又想喝新的；他总说陈的好。”"},
{volumn:        42,chapter:         6,versenumber:         1,verse:"有一个安息日，耶稣从麦地经过。他的门徒掐了麦穗，用手搓着吃。"},
{volumn:        42,chapter:         6,versenumber:         2,verse:"有几个法利赛人说：“你们为什么做安息日不可做的事呢？”"},
{volumn:        42,chapter:         6,versenumber:         3,verse:"耶稣对他们说：“经上记着大卫和跟从他的人饥饿之时所做的事，连这个你们也没有念过吗？"},
{volumn:        42,chapter:         6,versenumber:         4,verse:"他怎么进了　神的殿，拿陈设饼吃，又给跟从的人吃？这饼除了祭司以外，别人都不可吃。”"},
{volumn:        42,chapter:         6,versenumber:         5,verse:"又对他们说：“人子是安息日的主。”"},
{volumn:        42,chapter:         6,versenumber:         6,verse:"又有一个安息日，耶稣进了会堂教训人，在那里有一个人右手枯干了。"},
{volumn:        42,chapter:         6,versenumber:         7,verse:"文士和法利赛人窥探耶稣，在安息日治病不治病，要得把柄去告他。"},
{volumn:        42,chapter:         6,versenumber:         8,verse:"耶稣却知道他们的意念，就对那枯干一只手的人说：“起来！站在当中。”那人就起来，站着。"},
{volumn:        42,chapter:         6,versenumber:         9,verse:"耶稣对他们说：“我问你们，在安息日行善行恶，救命害命，哪样是可以的呢？”"},
{volumn:        42,chapter:         6,versenumber:        10,verse:"他就周围看着他们众人，对那人说：“伸出手来！”他把手一伸，手就复了原。"},
{volumn:        42,chapter:         6,versenumber:        11,verse:"他们就满心大怒，彼此商议怎样处治耶稣。"},
{volumn:        42,chapter:         6,versenumber:        12,verse:"那时，耶稣出去，上山祷告，整夜祷告　神；"},
{volumn:        42,chapter:         6,versenumber:        13,verse:"到了天亮，叫他的门徒来，就从他们中间挑选十二个人，称他们为使徒。"},
{volumn:        42,chapter:         6,versenumber:        14,verse:"这十二个人有西门（耶稣又给他起名叫彼得），还有他兄弟安得烈，又有雅各和约翰，腓力和巴多罗买，"},
{volumn:        42,chapter:         6,versenumber:        15,verse:"马太和多马，亚勒腓的儿子雅各和奋锐党的西门，"},
{volumn:        42,chapter:         6,versenumber:        16,verse:"雅各的儿子（或译：兄弟）犹大，和卖主的加略人犹大。"},
{volumn:        42,chapter:         6,versenumber:        17,verse:"耶稣和他们下了山，站在一块平地上；同站的有许多门徒，又有许多百姓，从犹太全地和耶路撒冷，并推罗、西顿的海边来，都要听他讲道，又指望医治他们的病；"},
{volumn:        42,chapter:         6,versenumber:        18,verse:"还有被污鬼缠磨的，也得了医治。"},
{volumn:        42,chapter:         6,versenumber:        19,verse:"众人都想要摸他；因为有能力从他身上发出来，医好了他们。"},
{volumn:        42,chapter:         6,versenumber:        20,verse:"耶稣举目看着门徒，说：“你们贫穷的人有福了！因为　神的国是你们的。"},
{volumn:        42,chapter:         6,versenumber:        21,verse:"你们饥饿的人有福了！因为你们将要饱足。你们哀哭的人有福了！因为你们将要喜笑。"},
{volumn:        42,chapter:         6,versenumber:        22,verse:"人为人子恨恶你们，拒绝你们，辱骂你们，弃掉你们的名，以为是恶，你们就有福了！"},
{volumn:        42,chapter:         6,versenumber:        23,verse:"当那日，你们要欢喜跳跃，因为你们在天上的赏赐是大的。他们的祖宗待先知也是这样。”"},
{volumn:        42,chapter:         6,versenumber:        24,verse:"“但你们富足的人有祸了！因为你们受过你们的安慰。"},
{volumn:        42,chapter:         6,versenumber:        25,verse:"你们饱足的人有祸了！因为你们将要饥饿。你们喜笑的人有祸了！因为你们将要哀恸哭泣。"},
{volumn:        42,chapter:         6,versenumber:        26,verse:"人都说你们好的时候，你们就有祸了！因为他们的祖宗待假先知也是这样。”"},
{volumn:        42,chapter:         6,versenumber:        27,verse:"“只是我告诉你们这听道的人，你们的仇敌，要爱他！恨你们的，要待他好！"},
{volumn:        42,chapter:         6,versenumber:        28,verse:"咒诅你们的，要为他祝福！凌辱你们的，要为他祷告！"},
{volumn:        42,chapter:         6,versenumber:        29,verse:"有人打你这边的脸，连那边的脸也由他打。有人夺你的外衣，连里衣也由他拿去。"},
{volumn:        42,chapter:         6,versenumber:        30,verse:"凡求你的，就给他。有人夺你的东西去，不用再要回来。"},
{volumn:        42,chapter:         6,versenumber:        31,verse:"你们愿意人怎样待你们，你们也要怎样待人。"},
{volumn:        42,chapter:         6,versenumber:        32,verse:"你们若单爱那爱你们的人，有什么可酬谢的呢？就是罪人也爱那爱他们的人。"},
{volumn:        42,chapter:         6,versenumber:        33,verse:"你们若善待那善待你们的人，有什么可酬谢的呢？就是罪人也是这样行。"},
{volumn:        42,chapter:         6,versenumber:        34,verse:"你们若借给人，指望从他收回，有什么可酬谢的呢？就是罪人也借给罪人，要如数收回。"},
{volumn:        42,chapter:         6,versenumber:        35,verse:"你们倒要爱仇敌，也要善待他们，并要借给人不指望偿还，你们的赏赐就必大了，你们也必作至高者的儿子，因为他恩待那忘恩的和作恶的。"},
{volumn:        42,chapter:         6,versenumber:        36,verse:"你们要慈悲，像你们的父慈悲一样。”"},
{volumn:        42,chapter:         6,versenumber:        37,verse:"“你们不要论断人，就不被论断；你们不要定人的罪，就不被定罪；你们要饶恕人，就必蒙饶恕（饶恕：原文是释放）；"},
{volumn:        42,chapter:         6,versenumber:        38,verse:"你们要给人，就必有给你们的，并且用十足的升斗，连摇带按，上尖下流地倒在你们怀里；因为你们用什么量器量给人，也必用什么量器量给你们。”"},
{volumn:        42,chapter:         6,versenumber:        39,verse:"耶稣又用比喻对他们说：“瞎子岂能领瞎子，两个人不是都要掉在坑里吗？"},
{volumn:        42,chapter:         6,versenumber:        40,verse:"学生不能高过先生；凡学成了的不过和先生一样。"},
{volumn:        42,chapter:         6,versenumber:        41,verse:"为什么看见你弟兄眼中有刺，却不想自己眼中有梁木呢？"},
{volumn:        42,chapter:         6,versenumber:        42,verse:"你不见自己眼中有梁木，怎能对你弟兄说：‘容我去掉你眼中的刺’呢？你这假冒为善的人！先去掉自己眼中的梁木，然后才能看得清楚，去掉你弟兄眼中的刺。”"},
{volumn:        42,chapter:         6,versenumber:        43,verse:"“因为，没有好树结坏果子，也没有坏树结好果子。"},
{volumn:        42,chapter:         6,versenumber:        44,verse:"凡树木看果子，就可以认出它来。人不是从荆棘上摘无花果，也不是从蒺藜里摘葡萄。"},
{volumn:        42,chapter:         6,versenumber:        45,verse:"善人从他心里所存的善就发出善来；恶人从他心里所存的恶就发出恶来；因为心里所充满的，口里就说出来。”"},
{volumn:        42,chapter:         6,versenumber:        46,verse:"“你们为什么称呼我‘主啊，主啊’却不遵我的话行呢？"},
{volumn:        42,chapter:         6,versenumber:        47,verse:"凡到我这里来，听见我的话就去行的，我要告诉你们他像什么人："},
{volumn:        42,chapter:         6,versenumber:        48,verse:"他像一个人盖房子，深深地挖地，把根基安在磐石上；到发大水的时候，水冲那房子，房子总不能摇动，因为根基立在磐石上（有古卷：因为盖造得好）。"},
{volumn:        42,chapter:         6,versenumber:        49,verse:"惟有听见不去行的，就像一个人在土地上盖房子，没有根基；水一冲，随即倒塌了，并且那房子坏的很大。”"},
{volumn:        42,chapter:         7,versenumber:         1,verse:"耶稣对百姓讲完了这一切的话，就进了迦百农。"},
{volumn:        42,chapter:         7,versenumber:         2,verse:"有一个百夫长所宝贵的仆人害病，快要死了。"},
{volumn:        42,chapter:         7,versenumber:         3,verse:"百夫长风闻耶稣的事，就托犹太人的几个长老去求耶稣来救他的仆人。"},
{volumn:        42,chapter:         7,versenumber:         4,verse:"他们到了耶稣那里，就切切地求他说：“你给他行这事是他所配得的；"},
{volumn:        42,chapter:         7,versenumber:         5,verse:"因为他爱我们的百姓，给我们建造会堂。”"},
{volumn:        42,chapter:         7,versenumber:         6,verse:"耶稣就和他们同去。离那家不远，百夫长托几个朋友去见耶稣，对他说：“主啊！不要劳动；因你到我舍下，我不敢当。"},
{volumn:        42,chapter:         7,versenumber:         7,verse:"我也自以为不配去见你，只要你说一句话，我的仆人就必好了。"},
{volumn:        42,chapter:         7,versenumber:         8,verse:"因为我在人的权下，也有兵在我以下，对这个说：‘去！’他就去；对那个说：‘来！’他就来；对我的仆人说：‘你做这事！’他就去做。”"},
{volumn:        42,chapter:         7,versenumber:         9,verse:"耶稣听见这话，就希奇他，转身对跟随的众人说：“我告诉你们，这么大的信心，就是在以色列中，我也没有遇见过。”"},
{volumn:        42,chapter:         7,versenumber:        10,verse:"那托来的人回到百夫长家里，看见仆人已经好了。"},
{volumn:        42,chapter:         7,versenumber:        11,verse:"过了不多时（有古卷：次日），耶稣往一座城去，这城名叫拿因，他的门徒和极多的人与他同行。"},
{volumn:        42,chapter:         7,versenumber:        12,verse:"将近城门，有一个死人被抬出来。这人是他母亲独生的儿子；他母亲又是寡妇。有城里的许多人同着寡妇送殡。"},
{volumn:        42,chapter:         7,versenumber:        13,verse:"主看见那寡妇，就怜悯她，对她说：“不要哭！”"},
{volumn:        42,chapter:         7,versenumber:        14,verse:"于是进前按着杠，抬的人就站住了。耶稣说：“少年人，我吩咐你，起来！”"},
{volumn:        42,chapter:         7,versenumber:        15,verse:"那死人就坐起，并且说话。耶稣便把他交给他母亲。"},
{volumn:        42,chapter:         7,versenumber:        16,verse:"众人都惊奇，归荣耀与　神，说：“有大先知在我们中间兴起来了！”又说：“神眷顾了他的百姓！”"},
{volumn:        42,chapter:         7,versenumber:        17,verse:"他这事的风声就传遍了犹太和周围地方。"},
{volumn:        42,chapter:         7,versenumber:        18,verse:"约翰的门徒把这些事都告诉约翰。"},
{volumn:        42,chapter:         7,versenumber:        19,verse:"他便叫了两个门徒来，打发他们到主那里去，说：“那将要来的是你吗？还是我们等候别人呢？”"},
{volumn:        42,chapter:         7,versenumber:        20,verse:"那两个人来到耶稣那里，说：“施洗的约翰打发我们来问你：‘那将要来的是你吗？还是我们等候别人呢？’”"},
{volumn:        42,chapter:         7,versenumber:        21,verse:"正当那时候，耶稣治好了许多有疾病的，受灾患的，被恶鬼附着的，又开恩叫好些瞎子能看见。"},
{volumn:        42,chapter:         7,versenumber:        22,verse:"耶稣回答说：“你们去，把所看见所听见的事告诉约翰，就是瞎子看见，瘸子行走，长大麻风的洁净，聋子听见，死人复活，穷人有福音传给他们。"},
{volumn:        42,chapter:         7,versenumber:        23,verse:"凡不因我跌倒的，就有福了！”"},
{volumn:        42,chapter:         7,versenumber:        24,verse:"约翰所差来的人既走了，耶稣就对众人讲论约翰说：“你们从前出去到旷野，是要看什么呢？要看风吹动的芦苇吗？"},
{volumn:        42,chapter:         7,versenumber:        25,verse:"你们出去，到底是要看什么？要看穿细软衣服的人吗？那穿华丽衣服、宴乐度日的人是在王宫里。"},
{volumn:        42,chapter:         7,versenumber:        26,verse:"你们出去，究竟是要看什么？要看先知吗？我告诉你们，是的，他比先知大多了。"},
{volumn:        42,chapter:         7,versenumber:        27,verse:"经上记着说：‘我要差遣我的使者在你前面预备道路’，所说的就是这个人。"},
{volumn:        42,chapter:         7,versenumber:        28,verse:"我告诉你们，凡妇人所生的，没有一个大过约翰的；然而　神国里最小的比他还大。”"},
{volumn:        42,chapter:         7,versenumber:        29,verse:"众百姓和税吏既受过约翰的洗，听见这话，就以　神为义；"},
{volumn:        42,chapter:         7,versenumber:        30,verse:"但法利赛人和律法师没有受过约翰的洗，竟为自己废弃了　神的旨意。（29、30两节或译“众百姓和税吏听见了约翰的话，就受了他的洗，便以　神为义；但法利赛人和律法师不受约翰的洗，竟为自己废弃了　神的旨意”）。"},
{volumn:        42,chapter:         7,versenumber:        31,verse:"主又说：“这样，我可用什么比这世代的人呢？他们好像什么呢？"},
{volumn:        42,chapter:         7,versenumber:        32,verse:"好像孩童坐在街市上，彼此呼叫说：‘我们向你们吹笛，你们不跳舞；我们向你们举哀，你们不啼哭。’"},
{volumn:        42,chapter:         7,versenumber:        33,verse:"施洗的约翰来，不吃饼，不喝酒，你们说他是被鬼附着的。"},
{volumn:        42,chapter:         7,versenumber:        34,verse:"人子来，也吃也喝，你们说他是贪食好酒的人，是税吏和罪人的朋友。"},
{volumn:        42,chapter:         7,versenumber:        35,verse:"但智慧之子都以智慧为是。”"},
{volumn:        42,chapter:         7,versenumber:        36,verse:"有一个法利赛人请耶稣和他吃饭；耶稣就到法利赛人家里去坐席。"},
{volumn:        42,chapter:         7,versenumber:        37,verse:"那城里有一个女人，是个罪人，知道耶稣在法利赛人家里坐席，就拿着盛香膏的玉瓶，"},
{volumn:        42,chapter:         7,versenumber:        38,verse:"站在耶稣背后，挨着他的脚哭，眼泪湿了耶稣的脚，就用自己的头发擦干，又用嘴连连亲他的脚，把香膏抹上。"},
{volumn:        42,chapter:         7,versenumber:        39,verse:"请耶稣的法利赛人看见这事，心里说：“这人若是先知，必知道摸他的是谁，是个怎样的女人；乃是个罪人。”"},
{volumn:        42,chapter:         7,versenumber:        40,verse:"耶稣对他说：“西门！我有句话要对你说。”西门说：“夫子，请说。”"},
{volumn:        42,chapter:         7,versenumber:        41,verse:"耶稣说：“一个债主有两个人欠他的债；一个欠五十两银子，一个欠五两银子；"},
{volumn:        42,chapter:         7,versenumber:        42,verse:"因为他们无力偿还，债主就开恩免了他们两个人的债。这两个人哪一个更爱他呢？”"},
{volumn:        42,chapter:         7,versenumber:        43,verse:"西门回答说：“我想是那多得恩免的人。”耶稣说：“你断的不错。”"},
{volumn:        42,chapter:         7,versenumber:        44,verse:"于是转过来向着那女人，便对西门说：“你看见这女人吗？我进了你的家，你没有给我水洗脚；但这女人用眼泪湿了我的脚，用头发擦干。"},
{volumn:        42,chapter:         7,versenumber:        45,verse:"你没有与我亲嘴；但这女人从我进来的时候就不住地用嘴亲我的脚。"},
{volumn:        42,chapter:         7,versenumber:        46,verse:"你没有用油抹我的头；但这女人用香膏抹我的脚。"},
{volumn:        42,chapter:         7,versenumber:        47,verse:"所以我告诉你，她许多的罪都赦免了，因为她的爱多；但那赦免少的，他的爱就少。”"},
{volumn:        42,chapter:         7,versenumber:        48,verse:"于是对那女人说：“你的罪赦免了。”"},
{volumn:        42,chapter:         7,versenumber:        49,verse:"同席的人心里说：“这是什么人，竟赦免人的罪呢？”"},
{volumn:        42,chapter:         7,versenumber:        50,verse:"耶稣对那女人说：“你的信救了你；平平安安回去吧！”"},
{volumn:        42,chapter:         8,versenumber:         1,verse:"过了不多日，耶稣周游各城各乡传道，宣讲　神国的福音。和他同去的有十二个门徒，"},
{volumn:        42,chapter:         8,versenumber:         2,verse:"还有被恶鬼所附、被疾病所累、已经治好的几个妇女，内中有称为抹大拉的马利亚（曾有七个鬼从她身上赶出来），"},
{volumn:        42,chapter:         8,versenumber:         3,verse:"又有希律的家宰苦撒的妻子约亚拿，并苏撒拿，和好些别的妇女，都是用自己的财物供给耶稣和门徒。"},
{volumn:        42,chapter:         8,versenumber:         4,verse:"当许多人聚集、又有人从各城里出来见耶稣的时候，耶稣就用比喻说："},
{volumn:        42,chapter:         8,versenumber:         5,verse:"“有一个撒种的出去撒种。撒的时候，有落在路旁的，被人践踏，天上的飞鸟又来吃尽了。"},
{volumn:        42,chapter:         8,versenumber:         6,verse:"有落在磐石上的，一出来就枯干了，因为得不着滋润。"},
{volumn:        42,chapter:         8,versenumber:         7,verse:"有落在荆棘里的，荆棘一同生长，把它挤住了。"},
{volumn:        42,chapter:         8,versenumber:         8,verse:"又有落在好土里的，生长起来，结实百倍。”耶稣说了这些话，就大声说：“有耳可听的，就应当听！”"},
{volumn:        42,chapter:         8,versenumber:         9,verse:"门徒问耶稣说：“这比喻是什么意思呢？”"},
{volumn:        42,chapter:         8,versenumber:        10,verse:"他说：“　神国的奥秘只叫你们知道；至于别人，就用比喻，叫他们看也看不见，听也听不明。”"},
{volumn:        42,chapter:         8,versenumber:        11,verse:"“这比喻乃是这样：种子就是　神的道。"},
{volumn:        42,chapter:         8,versenumber:        12,verse:"那些在路旁的，就是人听了道，随后魔鬼来，从他们心里把道夺去，恐怕他们信了得救。"},
{volumn:        42,chapter:         8,versenumber:        13,verse:"那些在磐石上的，就是人听道，欢喜领受，但心中没有根，不过暂时相信，及至遇见试炼就退后了。"},
{volumn:        42,chapter:         8,versenumber:        14,verse:"那落在荆棘里的，就是人听了道，走开以后，被今生的思虑、钱财、宴乐挤住了，便结不出成熟的子粒来。"},
{volumn:        42,chapter:         8,versenumber:        15,verse:"那落在好土里的，就是人听了道，持守在诚实善良的心里，并且忍耐着结实。”"},
{volumn:        42,chapter:         8,versenumber:        16,verse:"“没有人点灯用器皿盖上，或放在床底下，乃是放在灯台上，叫进来的人看见亮光。"},
{volumn:        42,chapter:         8,versenumber:        17,verse:"因为掩藏的事没有不显出来的；隐瞒的事没有不露出来被人知道的。"},
{volumn:        42,chapter:         8,versenumber:        18,verse:"所以，你们应当小心怎样听；因为凡有的，还要加给他；凡没有的，连他自以为有的，也要夺去。”"},
{volumn:        42,chapter:         8,versenumber:        19,verse:"耶稣的母亲和他弟兄来了，因为人多，不得到他跟前。"},
{volumn:        42,chapter:         8,versenumber:        20,verse:"有人告诉他说：“你母亲和你弟兄站在外边，要见你。”"},
{volumn:        42,chapter:         8,versenumber:        21,verse:"耶稣回答说：“听了　神之道而遵行的人就是我的母亲，我的弟兄了。”"},
{volumn:        42,chapter:         8,versenumber:        22,verse:"有一天，耶稣和门徒上了船，对门徒说：“我们可以渡到湖那边去。”他们就开了船。"},
{volumn:        42,chapter:         8,versenumber:        23,verse:"正行的时候，耶稣睡着了。湖上忽然起了暴风，船将满了水，甚是危险。"},
{volumn:        42,chapter:         8,versenumber:        24,verse:"门徒来叫醒了他，说：“夫子！夫子！我们丧命啦！”耶稣醒了，斥责那狂风大浪；风浪就止住，平静了。"},
{volumn:        42,chapter:         8,versenumber:        25,verse:"耶稣对他们说：“你们的信心在哪里呢？”他们又惧怕又希奇，彼此说：“这到底是谁？他吩咐风和水，连风和水也听从他了。”"},
{volumn:        42,chapter:         8,versenumber:        26,verse:"他们到了格拉森（有古卷：加大拉）人的地方，就是加利利的对面。"},
{volumn:        42,chapter:         8,versenumber:        27,verse:"耶稣上了岸，就有城里一个被鬼附着的人迎面而来。这个人许久不穿衣服，不住房子，只住在坟茔里。"},
{volumn:        42,chapter:         8,versenumber:        28,verse:"他见了耶稣，就俯伏在他面前，大声喊叫，说：“至高　神的儿子耶稣，我与你有什么相干？求你不要叫我受苦！”"},
{volumn:        42,chapter:         8,versenumber:        29,verse:"是因耶稣曾吩咐污鬼从那人身上出来。原来这鬼屡次抓住他；他常被人看守，又被铁链和脚镣捆锁，他竟把锁链挣断，被鬼赶到旷野去。"},
{volumn:        42,chapter:         8,versenumber:        30,verse:"耶稣问他说：“你名叫什么？”他说：“我名叫‘群’”；这是因为附着他的鬼多。"},
{volumn:        42,chapter:         8,versenumber:        31,verse:"鬼就央求耶稣，不要吩咐他们到无底坑里去。"},
{volumn:        42,chapter:         8,versenumber:        32,verse:"那里有一大群猪在山上吃食。鬼央求耶稣，准他们进入猪里去。耶稣准了他们，"},
{volumn:        42,chapter:         8,versenumber:        33,verse:"鬼就从那人出来，进入猪里去。于是那群猪闯下山崖，投在湖里淹死了。"},
{volumn:        42,chapter:         8,versenumber:        34,verse:"放猪的看见这事就逃跑了，去告诉城里和乡下的人。"},
{volumn:        42,chapter:         8,versenumber:        35,verse:"众人出来要看是什么事；到了耶稣那里，看见鬼所离开的那人，坐在耶稣脚前，穿着衣服，心里明白过来，他们就害怕。"},
{volumn:        42,chapter:         8,versenumber:        36,verse:"看见这事的便将被鬼附着的人怎么得救告诉他们。"},
{volumn:        42,chapter:         8,versenumber:        37,verse:"格拉森四围的人，因为害怕得很，都求耶稣离开他们；耶稣就上船回去了。"},
{volumn:        42,chapter:         8,versenumber:        38,verse:"鬼所离开的那人恳求和耶稣同在；耶稣却打发他回去，说："},
{volumn:        42,chapter:         8,versenumber:        39,verse:"“你回家去，传说　神为你做了何等大的事。”他就去，满城里传扬耶稣为他做了何等大的事。"},
{volumn:        42,chapter:         8,versenumber:        40,verse:"耶稣回来的时候，众人迎接他，因为他们都等候他。"},
{volumn:        42,chapter:         8,versenumber:        41,verse:"有一个管会堂的，名叫睚鲁，来俯伏在耶稣脚前，求耶稣到他家里去；"},
{volumn:        42,chapter:         8,versenumber:        42,verse:"因他有一个独生女儿，约有十二岁，快要死了。耶稣去的时候，众人拥挤他。"},
{volumn:        42,chapter:         8,versenumber:        43,verse:"有一个女人，患了十二年的血漏，在医生手里花尽了她一切养生的，并没有一人能医好她。"},
{volumn:        42,chapter:         8,versenumber:        44,verse:"她来到耶稣背后，摸他的衣裳繸子，血漏立刻就止住了。"},
{volumn:        42,chapter:         8,versenumber:        45,verse:"耶稣说：“摸我的是谁？”众人都不承认。彼得和同行的人都说：“夫子，众人拥拥挤挤紧靠着你。（有古卷加：你还问摸我的是谁吗？）”"},
{volumn:        42,chapter:         8,versenumber:        46,verse:"耶稣说：“总有人摸我，因我觉得有能力从我身上出去。”"},
{volumn:        42,chapter:         8,versenumber:        47,verse:"那女人知道不能隐藏，就战战兢兢地来俯伏在耶稣脚前，把摸他的缘故和怎样立刻得好了，当着众人都说出来。"},
{volumn:        42,chapter:         8,versenumber:        48,verse:"耶稣对她说：“女儿，你的信救了你；平平安安地去吧！”"},
{volumn:        42,chapter:         8,versenumber:        49,verse:"还说话的时候，有人从管会堂的家里来，说：“你的女儿死了，不要劳动夫子。”"},
{volumn:        42,chapter:         8,versenumber:        50,verse:"耶稣听见就对他说：“不要怕，只要信！你的女儿就必得救。”"},
{volumn:        42,chapter:         8,versenumber:        51,verse:"耶稣到了他的家，除了彼得、约翰、雅各，和女儿的父母，不许别人同他进去。"},
{volumn:        42,chapter:         8,versenumber:        52,verse:"众人都为这女儿哀哭捶胸。耶稣说：“不要哭！她不是死了，是睡着了。”"},
{volumn:        42,chapter:         8,versenumber:        53,verse:"他们晓得女儿已经死了，就嗤笑耶稣。"},
{volumn:        42,chapter:         8,versenumber:        54,verse:"耶稣拉着她的手，呼叫说：“女儿，起来吧！”"},
{volumn:        42,chapter:         8,versenumber:        55,verse:"她的灵魂便回来，她就立刻起来了。耶稣吩咐给她东西吃。"},
{volumn:        42,chapter:         8,versenumber:        56,verse:"她的父母惊奇得很；耶稣嘱咐他们，不要把所做的事告诉人。"},
{volumn:        42,chapter:         9,versenumber:         1,verse:"耶稣叫齐了十二个门徒，给他们能力、权柄，制伏一切的鬼，医治各样的病，"},
{volumn:        42,chapter:         9,versenumber:         2,verse:"又差遣他们去宣传　神国的道，医治病人，"},
{volumn:        42,chapter:         9,versenumber:         3,verse:"对他们说：“行路的时候，不要带拐杖和口袋，不要带食物和银子，也不要带两件褂子。"},
{volumn:        42,chapter:         9,versenumber:         4,verse:"无论进哪一家，就住在那里，也从那里起行。"},
{volumn:        42,chapter:         9,versenumber:         5,verse:"凡不接待你们的，你们离开那城的时候，要把脚上的尘土跺下去，见证他们的不是。”"},
{volumn:        42,chapter:         9,versenumber:         6,verse:"门徒就出去，走遍各乡宣传福音，到处治病。"},
{volumn:        42,chapter:         9,versenumber:         7,verse:"分封的王希律听见耶稣所做的一切事，就游移不定；因为有人说：“是约翰从死里复活”；"},
{volumn:        42,chapter:         9,versenumber:         8,verse:"又有人说：“是以利亚显现”；还有人说：“是古时的一个先知又活了。”"},
{volumn:        42,chapter:         9,versenumber:         9,verse:"希律说：“约翰我已经斩了，这却是什么人？我竟听见他这样的事呢？”就想要见他。"},
{volumn:        42,chapter:         9,versenumber:        10,verse:"使徒回来，将所做的事告诉耶稣，耶稣就带他们暗暗地离开那里，往一座城去；那城名叫伯赛大。"},
{volumn:        42,chapter:         9,versenumber:        11,verse:"但众人知道了，就跟着他去；耶稣便接待他们，对他们讲论　神国的道，医治那些需医的人。"},
{volumn:        42,chapter:         9,versenumber:        12,verse:"日头快要平西，十二个门徒来对他说：“请叫众人散开，他们好往四面乡村里去借宿找吃的，因为我们这里是野地。”"},
{volumn:        42,chapter:         9,versenumber:        13,verse:"耶稣说：“你们给他们吃吧！”门徒说：“我们不过有五个饼，两条鱼，若不去为这许多人买食物就不够。”"},
{volumn:        42,chapter:         9,versenumber:        14,verse:"那时，人数约有五千。耶稣对门徒说：“叫他们一排一排地坐下，每排大约五十个人。”"},
{volumn:        42,chapter:         9,versenumber:        15,verse:"门徒就如此行，叫众人都坐下。"},
{volumn:        42,chapter:         9,versenumber:        16,verse:"耶稣拿着这五个饼，两条鱼，望着天祝福，擘开，递给门徒，摆在众人面前。"},
{volumn:        42,chapter:         9,versenumber:        17,verse:"他们就吃，并且都吃饱了；把剩下的零碎收拾起来，装满了十二篮子。"},
{volumn:        42,chapter:         9,versenumber:        18,verse:"耶稣自己祷告的时候，门徒也同他在那里。耶稣问他们说：“众人说我是谁？”"},
{volumn:        42,chapter:         9,versenumber:        19,verse:"他们说：“有人说是施洗的约翰；有人说是以利亚；还有人说是古时的一个先知又活了。”"},
{volumn:        42,chapter:         9,versenumber:        20,verse:"耶稣说：“你们说我是谁？”彼得回答说：“是　神所立的基督。”"},
{volumn:        42,chapter:         9,versenumber:        21,verse:"耶稣切切地嘱咐他们，不可将这事告诉人，"},
{volumn:        42,chapter:         9,versenumber:        22,verse:"又说：“人子必须受许多的苦，被长老、祭司长，和文士弃绝，并且被杀，第三日复活。”"},
{volumn:        42,chapter:         9,versenumber:        23,verse:"耶稣又对众人说：“若有人要跟从我，就当舍己，天天背起他的十字架来跟从我。"},
{volumn:        42,chapter:         9,versenumber:        24,verse:"因为，凡要救自己生命（生命：或译灵魂；下同）的，必丧掉生命；凡为我丧掉生命的，必救了生命。"},
{volumn:        42,chapter:         9,versenumber:        25,verse:"人若赚得全世界，却丧了自己，赔上自己，有什么益处呢？"},
{volumn:        42,chapter:         9,versenumber:        26,verse:"凡把我和我的道当作可耻的，人子在自己的荣耀里，并天父与圣天使的荣耀里降临的时候，也要把那人当作可耻的。"},
{volumn:        42,chapter:         9,versenumber:        27,verse:"我实在告诉你们，站在这里的，有人在没尝死味以前，必看见　神的国。”"},
{volumn:        42,chapter:         9,versenumber:        28,verse:"说了这话以后约有八天，耶稣带着彼得、约翰、雅各上山去祷告。"},
{volumn:        42,chapter:         9,versenumber:        29,verse:"正祷告的时候，他的面貌就改变了，衣服洁白放光。"},
{volumn:        42,chapter:         9,versenumber:        30,verse:"忽然有摩西、以利亚两个人同耶稣说话；"},
{volumn:        42,chapter:         9,versenumber:        31,verse:"他们在荣光里显现，谈论耶稣去世的事，就是他在耶路撒冷将要成的事。"},
{volumn:        42,chapter:         9,versenumber:        32,verse:"彼得和他的同伴都打盹，既清醒了，就看见耶稣的荣光，并同他站着的那两个人。"},
{volumn:        42,chapter:         9,versenumber:        33,verse:"二人正要和耶稣分离的时候，彼得对耶稣说：“夫子，我们在这里真好！可以搭三座棚，一座为你，一座为摩西，一座为以利亚。”他却不知道所说的是什么。"},
{volumn:        42,chapter:         9,versenumber:        34,verse:"说这话的时候，有一朵云彩来遮盖他们；他们进入云彩里就惧怕。"},
{volumn:        42,chapter:         9,versenumber:        35,verse:"有声音从云彩里出来，说：“这是我的儿子，我所拣选的（有古卷：这是我的爱子），你们要听他。”"},
{volumn:        42,chapter:         9,versenumber:        36,verse:"声音住了，只见耶稣一人在那里。当那些日子，门徒不提所看见的事，一样也不告诉人。"},
{volumn:        42,chapter:         9,versenumber:        37,verse:"第二天，他们下了山，就有许多人迎见耶稣。"},
{volumn:        42,chapter:         9,versenumber:        38,verse:"其中有一人喊叫说：“夫子！求你看顾我的儿子，因为他是我的独生子。"},
{volumn:        42,chapter:         9,versenumber:        39,verse:"他被鬼抓住就忽然喊叫；鬼又叫他抽风，口中流沫，并且重重地伤害他，难以离开他。"},
{volumn:        42,chapter:         9,versenumber:        40,verse:"我求过你的门徒，把鬼赶出去，他们却是不能。”"},
{volumn:        42,chapter:         9,versenumber:        41,verse:"耶稣说：“嗳！这又不信又悖谬的世代啊，我在你们这里，忍耐你们要到几时呢？将你的儿子带到这里来吧！”"},
{volumn:        42,chapter:         9,versenumber:        42,verse:"正来的时候，鬼把他摔倒，叫他重重地抽风。耶稣就斥责那污鬼，把孩子治好了，交给他父亲。"},
{volumn:        42,chapter:         9,versenumber:        43,verse:"众人都诧异　神的大能（大能：或译威荣）。耶稣所做的一切事，众人正希奇的时候，耶稣对门徒说："},
{volumn:        42,chapter:         9,versenumber:        44,verse:"“你们要把这些话存在耳中，因为人子将要被交在人手里。”"},
{volumn:        42,chapter:         9,versenumber:        45,verse:"他们不明白这话，意思乃是隐藏的，叫他们不能明白，他们也不敢问这话的意思。"},
{volumn:        42,chapter:         9,versenumber:        46,verse:"门徒中间起了议论，谁将为大。"},
{volumn:        42,chapter:         9,versenumber:        47,verse:"耶稣看出他们心中的议论，就领一个小孩子来，叫他站在自己旁边，"},
{volumn:        42,chapter:         9,versenumber:        48,verse:"对他们说：“凡为我名接待这小孩子的，就是接待我；凡接待我的，就是接待那差我来的。你们中间最小的，他便为大。”"},
{volumn:        42,chapter:         9,versenumber:        49,verse:"约翰说：“夫子，我们看见一个人奉你的名赶鬼，我们就禁止他，因为他不与我们一同跟从你。”"},
{volumn:        42,chapter:         9,versenumber:        50,verse:"耶稣说：“不要禁止他；因为不敌挡你们的，就是帮助你们的。”"},
{volumn:        42,chapter:         9,versenumber:        51,verse:"耶稣被接上升的日子将到，他就定意向耶路撒冷去，"},
{volumn:        42,chapter:         9,versenumber:        52,verse:"便打发使者在他前头走。他们到了撒玛利亚的一个村庄，要为他预备。"},
{volumn:        42,chapter:         9,versenumber:        53,verse:"那里的人不接待他，因他面向耶路撒冷去。"},
{volumn:        42,chapter:         9,versenumber:        54,verse:"他的门徒雅各、约翰看见了，就说：“主啊，你要我们吩咐火从天上降下来烧灭他们，像以利亚所做的（有古卷没有“像以利亚所做的”这几个字）吗？”"},
{volumn:        42,chapter:         9,versenumber:        55,verse:"耶稣转身责备两个门徒，说：“你们的心如何，你们并不知道。"},
{volumn:        42,chapter:         9,versenumber:        56,verse:"人子来不是要灭人的性命（性命：或译灵魂；下同），是要救人的性命。”说着就往别的村庄去了（有古卷只有55节首句，56节末句）。"},
{volumn:        42,chapter:         9,versenumber:        57,verse:"他们走路的时候，有一人对耶稣说：“你无论往哪里去，我要跟从你。”"},
{volumn:        42,chapter:         9,versenumber:        58,verse:"耶稣说：“狐狸有洞，天空的飞鸟有窝，只是人子没有枕头的地方。”"},
{volumn:        42,chapter:         9,versenumber:        59,verse:"又对一个人说：“跟从我来！”那人说：“主，容我先回去埋葬我的父亲。”"},
{volumn:        42,chapter:         9,versenumber:        60,verse:"耶稣说：“任凭死人埋葬他们的死人，你只管去传扬　神国的道。”"},
{volumn:        42,chapter:         9,versenumber:        61,verse:"又有一人说：“主，我要跟从你，但容我先去辞别我家里的人。”"},
{volumn:        42,chapter:         9,versenumber:        62,verse:"耶稣说：“手扶着犁向后看的，不配进　神的国。”"},
{volumn:        42,chapter:        10,versenumber:         1,verse:"这事以后，主又设立七十个人，差遣他们两个两个地在他前面，往自己所要到的各城各地方去，"},
{volumn:        42,chapter:        10,versenumber:         2,verse:"就对他们说：“要收的庄稼多，做工的人少。所以，你们当求庄稼的主打发工人出去收他的庄稼。"},
{volumn:        42,chapter:        10,versenumber:         3,verse:"你们去吧！我差你们出去，如同羊羔进入狼群。"},
{volumn:        42,chapter:        10,versenumber:         4,verse:"不要带钱囊，不要带口袋，不要带鞋；在路上也不要问人的安。"},
{volumn:        42,chapter:        10,versenumber:         5,verse:"无论进哪一家，先要说：‘愿这一家平安。’"},
{volumn:        42,chapter:        10,versenumber:         6,verse:"那里若有当得平安的人（当得平安的人：原文是平安之子），你们所求的平安就必临到那家；不然，就归与你们了。"},
{volumn:        42,chapter:        10,versenumber:         7,verse:"你们要住在那家，吃喝他们所供给的，因为工人得工价是应当的；不要从这家搬到那家。"},
{volumn:        42,chapter:        10,versenumber:         8,verse:"无论进哪一城，人若接待你们，给你们摆上什么，你们就吃什么。"},
{volumn:        42,chapter:        10,versenumber:         9,verse:"要医治那城里的病人，对他们说：‘　神的国临近你们了。’"},
{volumn:        42,chapter:        10,versenumber:        10,verse:"无论进哪一城，人若不接待你们，你们就到街上去，"},
{volumn:        42,chapter:        10,versenumber:        11,verse:"说：‘就是你们城里的尘土黏在我们的脚上，我们也当着你们擦去。虽然如此，你们该知道　神的国临近了。’"},
{volumn:        42,chapter:        10,versenumber:        12,verse:"我告诉你们，当审判的日子，所多玛所受的，比那城还容易受呢！”"},
{volumn:        42,chapter:        10,versenumber:        13,verse:"“哥拉汛哪，你有祸了！伯赛大啊，你有祸了！因为在你们中间所行的异能若行在推罗、西顿，他们早已披麻蒙灰，坐在地上悔改了。"},
{volumn:        42,chapter:        10,versenumber:        14,verse:"当审判的日子，推罗、西顿所受的，比你们还容易受呢！"},
{volumn:        42,chapter:        10,versenumber:        15,verse:"迦百农啊，你已经升到天上（或译：你将要升到天上吗），将来必推下阴间。”"},
{volumn:        42,chapter:        10,versenumber:        16,verse:"又对门徒说：“听从你们的就是听从我；弃绝你们的就是弃绝我；弃绝我的就是弃绝那差我来的。”"},
{volumn:        42,chapter:        10,versenumber:        17,verse:"那七十个人欢欢喜喜地回来，说：“主啊！因你的名，就是鬼也服了我们。”"},
{volumn:        42,chapter:        10,versenumber:        18,verse:"耶稣对他们说：“我曾看见撒但从天上坠落，像闪电一样。"},
{volumn:        42,chapter:        10,versenumber:        19,verse:"我已经给你们权柄可以践踏蛇和蝎子，又胜过仇敌一切的能力，断没有什么能害你们。"},
{volumn:        42,chapter:        10,versenumber:        20,verse:"然而，不要因鬼服了你们就欢喜，要因你们的名记录在天上欢喜。”"},
{volumn:        42,chapter:        10,versenumber:        21,verse:"正当那时，耶稣被圣灵感动就欢乐，说：“父啊，天地的主，我感谢你！因为你将这些事向聪明通达人就藏起来，向婴孩就显出来。父啊！是的，因为你的美意本是如此。"},
{volumn:        42,chapter:        10,versenumber:        22,verse:"一切所有的都是我父交付我的；除了父，没有人知道子是谁；除了子和子所愿意指示的，没有人知道父是谁。”"},
{volumn:        42,chapter:        10,versenumber:        23,verse:"耶稣转身暗暗地对门徒说：“看见你们所看见的，那眼睛就有福了。"},
{volumn:        42,chapter:        10,versenumber:        24,verse:"我告诉你们，从前有许多先知和君王要看你们所看的，却没有看见，要听你们所听的，却没有听见。”"},
{volumn:        42,chapter:        10,versenumber:        25,verse:"有一个律法师起来试探耶稣，说：“夫子！我该做什么才可以承受永生？”"},
{volumn:        42,chapter:        10,versenumber:        26,verse:"耶稣对他说：“律法上写的是什么？你念的是怎样呢？”"},
{volumn:        42,chapter:        10,versenumber:        27,verse:"他回答说：“你要尽心、尽性、尽力、尽意爱主你的　神；又要爱邻舍如同自己。”"},
{volumn:        42,chapter:        10,versenumber:        28,verse:"耶稣说：“你回答的是；你这样行，就必得永生。”"},
{volumn:        42,chapter:        10,versenumber:        29,verse:"那人要显明自己有理，就对耶稣说：“谁是我的邻舍呢？”"},
{volumn:        42,chapter:        10,versenumber:        30,verse:"耶稣回答说：“有一个人从耶路撒冷下耶利哥去，落在强盗手中。他们剥去他的衣裳，把他打个半死，就丢下他走了。"},
{volumn:        42,chapter:        10,versenumber:        31,verse:"偶然有一个祭司从这条路下来，看见他就从那边过去了。"},
{volumn:        42,chapter:        10,versenumber:        32,verse:"又有一个利未人来到这地方，看见他，也照样从那边过去了。"},
{volumn:        42,chapter:        10,versenumber:        33,verse:"惟有一个撒玛利亚人行路来到那里，看见他就动了慈心，"},
{volumn:        42,chapter:        10,versenumber:        34,verse:"上前用油和酒倒在他的伤处，包裹好了，扶他骑上自己的牲口，带到店里去照应他。"},
{volumn:        42,chapter:        10,versenumber:        35,verse:"第二天拿出二钱银子来，交给店主，说：‘你且照应他；此外所费用的，我回来必还你。’"},
{volumn:        42,chapter:        10,versenumber:        36,verse:"你想，这三个人哪一个是落在强盗手中的邻舍呢？”"},
{volumn:        42,chapter:        10,versenumber:        37,verse:"他说：“是怜悯他的。”耶稣说：“你去照样行吧。”"},
{volumn:        42,chapter:        10,versenumber:        38,verse:"他们走路的时候，耶稣进了一个村庄。有一个女人，名叫马大，接他到自己家里。"},
{volumn:        42,chapter:        10,versenumber:        39,verse:"她有一个妹子，名叫马利亚，在耶稣脚前坐着听他的道。"},
{volumn:        42,chapter:        10,versenumber:        40,verse:"马大伺候的事多，心里忙乱，就进前来，说：“主啊，我的妹子留下我一个人伺候，你不在意吗？请吩咐她来帮助我。”"},
{volumn:        42,chapter:        10,versenumber:        41,verse:"耶稣回答说：“马大！马大！你为许多的事思虑烦扰，"},
{volumn:        42,chapter:        10,versenumber:        42,verse:"但是不可少的只有一件；马利亚已经选择那上好的福分，是不能夺去的。”"},
{volumn:        42,chapter:        11,versenumber:         1,verse:"耶稣在一个地方祷告；祷告完了，有个门徒对他说：“求主教导我们祷告，像约翰教导他的门徒。”"},
{volumn:        42,chapter:        11,versenumber:         2,verse:"耶稣说：“你们祷告的时候，要说：‘我们在天上的父（有古卷作“父啊”），愿人都尊你的名为圣。愿你的国降临；愿你的旨意行在地上，如同行在天上（有古卷没有“愿你的旨意…”）。"},
{volumn:        42,chapter:        11,versenumber:         3,verse:"我们日用的饮食，天天赐给我们。"},
{volumn:        42,chapter:        11,versenumber:         4,verse:"赦免我们的罪，因为我们也赦免凡亏欠我们的人。不叫我们遇见试探；救我们脱离凶恶（有古卷没有末句）。’”"},
{volumn:        42,chapter:        11,versenumber:         5,verse:"耶稣又说：“你们中间谁有一个朋友半夜到他那里去，说：‘朋友！请借给我三个饼；"},
{volumn:        42,chapter:        11,versenumber:         6,verse:"因为我有一个朋友行路，来到我这里，我没有什么给他摆上。’"},
{volumn:        42,chapter:        11,versenumber:         7,verse:"那人在里面回答说：‘不要搅扰我，门已经关闭，孩子们也同我在床上了，我不能起来给你。’"},
{volumn:        42,chapter:        11,versenumber:         8,verse:"我告诉你们，虽不因他是朋友起来给他，但因他情词迫切地直求，就必起来照他所需用的给他。"},
{volumn:        42,chapter:        11,versenumber:         9,verse:"我又告诉你们，你们祈求，就给你们；寻找，就寻见；叩门，就给你们开门。"},
{volumn:        42,chapter:        11,versenumber:        10,verse:"因为，凡祈求的，就得着；寻找的，就寻见；叩门的，就给他开门。"},
{volumn:        42,chapter:        11,versenumber:        11,verse:"你们中间作父亲的，谁有儿子求饼，反给他石头呢？求鱼，反拿蛇当鱼给他呢？"},
{volumn:        42,chapter:        11,versenumber:        12,verse:"求鸡蛋，反给他蝎子呢？"},
{volumn:        42,chapter:        11,versenumber:        13,verse:"你们虽然不好，尚且知道拿好东西给儿女；何况天父，岂不更将圣灵给求他的人吗？”"},
{volumn:        42,chapter:        11,versenumber:        14,verse:"耶稣赶出一个叫人哑巴的鬼；鬼出去了，哑巴就说出话来；众人都希奇。"},
{volumn:        42,chapter:        11,versenumber:        15,verse:"内中却有人说：“他是靠着鬼王别西卜赶鬼。”"},
{volumn:        42,chapter:        11,versenumber:        16,verse:"又有人试探耶稣，向他求从天上来的神迹。"},
{volumn:        42,chapter:        11,versenumber:        17,verse:"他晓得他们的意念，便对他们说：“凡一国自相纷争，就成为荒场；凡一家自相纷争，就必败落。"},
{volumn:        42,chapter:        11,versenumber:        18,verse:"若撒但自相纷争，他的国怎能站得住呢？因为你们说我是靠着别西卜赶鬼。"},
{volumn:        42,chapter:        11,versenumber:        19,verse:"我若靠着别西卜赶鬼，你们的子弟赶鬼又靠着谁呢？这样，他们就要断定你们的是非。"},
{volumn:        42,chapter:        11,versenumber:        20,verse:"我若靠着　神的能力赶鬼，这就是　神的国临到你们了。"},
{volumn:        42,chapter:        11,versenumber:        21,verse:"壮士披挂整齐，看守自己的住宅，他所有的都平安无事；"},
{volumn:        42,chapter:        11,versenumber:        22,verse:"但有一个比他更壮的来，胜过他，就夺去他所倚靠的盔甲兵器，又分了他的赃。"},
{volumn:        42,chapter:        11,versenumber:        23,verse:"不与我相合的，就是敌我的；不同我收聚的，就是分散的。”"},
{volumn:        42,chapter:        11,versenumber:        24,verse:"“污鬼离了人身，就在无水之地过来过去，寻求安歇之处；既寻不着，便说：‘我要回到我所出来的屋里去。’"},
{volumn:        42,chapter:        11,versenumber:        25,verse:"到了，就看见里面打扫干净，修饰好了，"},
{volumn:        42,chapter:        11,versenumber:        26,verse:"便去另带了七个比自己更恶的鬼来，都进去住在那里。那人末后的景况比先前更不好了。”"},
{volumn:        42,chapter:        11,versenumber:        27,verse:"耶稣正说这话的时候，众人中间有一个女人大声说：“怀你胎的和乳养你的有福了！”"},
{volumn:        42,chapter:        11,versenumber:        28,verse:"耶稣说：“是，却还不如听　神之道而遵守的人有福。”"},
{volumn:        42,chapter:        11,versenumber:        29,verse:"当众人聚集的时候，耶稣开讲说：“这世代是一个邪恶的世代。他们求看神迹，除了约拿的神迹以外，再没有神迹给他们看。"},
{volumn:        42,chapter:        11,versenumber:        30,verse:"约拿怎样为尼尼微人成了神迹，人子也要照样为这世代的人成了神迹。"},
{volumn:        42,chapter:        11,versenumber:        31,verse:"当审判的时候，南方的女王要起来定这世代的罪；因为她从地极而来，要听所罗门的智慧话。看哪，在这里有一人比所罗门更大。"},
{volumn:        42,chapter:        11,versenumber:        32,verse:"当审判的时候，尼尼微人要起来定这世代的罪，因为尼尼微人听了约拿所传的就悔改了。看哪，在这里有一人比约拿更大。”"},
{volumn:        42,chapter:        11,versenumber:        33,verse:"“没有人点灯放在地窨子里，或是斗底下，总是放在灯台上，使进来的人得见亮光。"},
{volumn:        42,chapter:        11,versenumber:        34,verse:"你眼睛就是身上的灯。你的眼睛若了亮，全身就光明；眼睛若昏花，全身就黑暗。"},
{volumn:        42,chapter:        11,versenumber:        35,verse:"所以，你要省察，恐怕你里头的光或者黑暗了。"},
{volumn:        42,chapter:        11,versenumber:        36,verse:"若是你全身光明，毫无黑暗，就必全然光明，如同灯的明光照亮你。”"},
{volumn:        42,chapter:        11,versenumber:        37,verse:"说话的时候，有一个法利赛人请耶稣同他吃饭，耶稣就进去坐席。"},
{volumn:        42,chapter:        11,versenumber:        38,verse:"这法利赛人看见耶稣饭前不洗手便诧异。"},
{volumn:        42,chapter:        11,versenumber:        39,verse:"主对他说：“如今你们法利赛人洗净杯盘的外面，你们里面却满了勒索和邪恶。"},
{volumn:        42,chapter:        11,versenumber:        40,verse:"无知的人哪，造外面的，不也造里面吗？"},
{volumn:        42,chapter:        11,versenumber:        41,verse:"只要把里面的施舍给人，凡物于你们就都洁净了。"},
{volumn:        42,chapter:        11,versenumber:        42,verse:"“你们法利赛人有祸了！因为你们将薄荷、芸香并各样菜蔬献上十分之一，那公义和爱　神的事反倒不行了。这原是你们当行的；那也是不可不行的。"},
{volumn:        42,chapter:        11,versenumber:        43,verse:"你们法利赛人有祸了！因为你们喜爱会堂里的首位，又喜爱人在街市上问你们的安。"},
{volumn:        42,chapter:        11,versenumber:        44,verse:"你们有祸了！因为你们如同不显露的坟墓，走在上面的人并不知道。”"},
{volumn:        42,chapter:        11,versenumber:        45,verse:"律法师中有一个回答耶稣说：“夫子！你这样说也把我们糟蹋了。”"},
{volumn:        42,chapter:        11,versenumber:        46,verse:"耶稣说：“你们律法师也有祸了！因为你们把难担的担子放在人身上，自己一个指头却不肯动。"},
{volumn:        42,chapter:        11,versenumber:        47,verse:"你们有祸了！因为你们修造先知的坟墓，那先知正是你们的祖宗所杀的。"},
{volumn:        42,chapter:        11,versenumber:        48,verse:"可见你们祖宗所做的事，你们又证明又喜欢；因为他们杀了先知，你们修造先知的坟墓。"},
{volumn:        42,chapter:        11,versenumber:        49,verse:"所以　神用智慧（用智慧：或译的智者）曾说：‘我要差遣先知和使徒到他们那里去，有的他们要杀害，有的他们要逼迫’，"},
{volumn:        42,chapter:        11,versenumber:        50,verse:"使创世以来所流众先知血的罪都要问在这世代的人身上，"},
{volumn:        42,chapter:        11,versenumber:        51,verse:"就是从亚伯的血起，直到被杀在坛和殿中间撒迦利亚的血为止。我实在告诉你们，这都要问在这世代的人身上。"},
{volumn:        42,chapter:        11,versenumber:        52,verse:"你们律法师有祸了！因为你们把知识的钥匙夺了去，自己不进去，正要进去的人你们也阻挡他们。”"},
{volumn:        42,chapter:        11,versenumber:        53,verse:"耶稣从那里出来，文士和法利赛人就极力地催逼他，引动他多说话，"},
{volumn:        42,chapter:        11,versenumber:        54,verse:"私下窥听，要拿他的话柄。"},
{volumn:        42,chapter:        12,versenumber:         1,verse:"这时，有几万人聚集，甚至彼此践踏。耶稣开讲，先对门徒说：“你们要防备法利赛人的酵，就是假冒为善。"},
{volumn:        42,chapter:        12,versenumber:         2,verse:"掩盖的事没有不露出来的；隐藏的事没有不被人知道的。"},
{volumn:        42,chapter:        12,versenumber:         3,verse:"因此，你们在暗中所说的，将要在明处被人听见；在内室附耳所说的，将要在房上被人宣扬。”"},
{volumn:        42,chapter:        12,versenumber:         4,verse:"“我的朋友，我对你们说，那杀身体以后不能再做什么的，不要怕他们。"},
{volumn:        42,chapter:        12,versenumber:         5,verse:"我要指示你们当怕的是谁：当怕那杀了以后又有权柄丢在地狱里的。我实在告诉你们，正要怕他。"},
{volumn:        42,chapter:        12,versenumber:         6,verse:"五个麻雀不是卖二分银子吗？但在　神面前，一个也不忘记；"},
{volumn:        42,chapter:        12,versenumber:         7,verse:"就是你们的头发，也都被数过了。不要惧怕，你们比许多麻雀还贵重！”"},
{volumn:        42,chapter:        12,versenumber:         8,verse:"“我又告诉你们，凡在人面前认我的，人子在　神的使者面前也必认他；"},
{volumn:        42,chapter:        12,versenumber:         9,verse:"在人面前不认我的，人子在　神的使者面前也必不认他。"},
{volumn:        42,chapter:        12,versenumber:        10,verse:"凡说话干犯人子的，还可得赦免；惟独亵渎圣灵的，总不得赦免。"},
{volumn:        42,chapter:        12,versenumber:        11,verse:"人带你们到会堂，并官府和有权柄的人面前，不要思虑怎么分诉，说什么话；"},
{volumn:        42,chapter:        12,versenumber:        12,verse:"因为正在那时候，圣灵要指教你们当说的话。”"},
{volumn:        42,chapter:        12,versenumber:        13,verse:"众人中有一个人对耶稣说：“夫子！请你吩咐我的兄长和我分开家业。”"},
{volumn:        42,chapter:        12,versenumber:        14,verse:"耶稣说：“你这个人！谁立我作你们断事的官，给你们分家业呢？”"},
{volumn:        42,chapter:        12,versenumber:        15,verse:"于是对众人说：“你们要谨慎自守，免去一切的贪心，因为人的生命不在乎家道丰富。”"},
{volumn:        42,chapter:        12,versenumber:        16,verse:"就用比喻对他们说：“有一个财主田产丰盛；"},
{volumn:        42,chapter:        12,versenumber:        17,verse:"自己心里思想说：‘我的出产没有地方收藏，怎么办呢？’"},
{volumn:        42,chapter:        12,versenumber:        18,verse:"又说：‘我要这么办：要把我的仓房拆了，另盖更大的，在那里好收藏我一切的粮食和财物，"},
{volumn:        42,chapter:        12,versenumber:        19,verse:"然后要对我的灵魂说：灵魂哪，你有许多财物积存，可作多年的费用，只管安安逸逸地吃喝快乐吧！’"},
{volumn:        42,chapter:        12,versenumber:        20,verse:"神却对他说：‘无知的人哪，今夜必要你的灵魂；你所预备的要归谁呢？’"},
{volumn:        42,chapter:        12,versenumber:        21,verse:"凡为自己积财，在　神面前却不富足的，也是这样。”"},
{volumn:        42,chapter:        12,versenumber:        22,verse:"耶稣又对门徒说：“所以我告诉你们，不要为生命忧虑吃什么，为身体忧虑穿什么；"},
{volumn:        42,chapter:        12,versenumber:        23,verse:"因为生命胜于饮食，身体胜于衣裳。"},
{volumn:        42,chapter:        12,versenumber:        24,verse:"你想乌鸦，也不种也不收，又没有仓又没有库，　神尚且养活它。你们比飞鸟是何等地贵重呢！"},
{volumn:        42,chapter:        12,versenumber:        25,verse:"你们哪一个能用思虑使寿数多加一刻呢（或译：使身量多加一肘呢）？"},
{volumn:        42,chapter:        12,versenumber:        26,verse:"这最小的事，你们尚且不能做，为什么还忧虑其余的事呢？"},
{volumn:        42,chapter:        12,versenumber:        27,verse:"你想百合花怎么长起来；它也不劳苦，也不纺线。然而我告诉你们，就是所罗门极荣华的时候，他所穿戴的，还不如这花一朵呢！"},
{volumn:        42,chapter:        12,versenumber:        28,verse:"你们这小信的人哪，野地里的草今天还在，明天就丢在炉里，　神还给它这样的妆饰，何况你们呢！"},
{volumn:        42,chapter:        12,versenumber:        29,verse:"你们不要求吃什么，喝什么，也不要挂心；"},
{volumn:        42,chapter:        12,versenumber:        30,verse:"这都是外邦人所求的。你们必须用这些东西，你们的父是知道的。"},
{volumn:        42,chapter:        12,versenumber:        31,verse:"你们只要求他的国，这些东西就必加给你们了。"},
{volumn:        42,chapter:        12,versenumber:        32,verse:"你们这小群，不要惧怕，因为你们的父乐意把国赐给你们。"},
{volumn:        42,chapter:        12,versenumber:        33,verse:"你们要变卖所有的周济人，为自己预备永不坏的钱囊，用不尽的财宝在天上，就是贼不能近、虫不能蛀的地方。"},
{volumn:        42,chapter:        12,versenumber:        34,verse:"因为，你们的财宝在哪里，你们的心也在那里。”"},
{volumn:        42,chapter:        12,versenumber:        35,verse:"“你们腰里要束上带，灯也要点着，"},
{volumn:        42,chapter:        12,versenumber:        36,verse:"自己好像仆人等候主人从婚姻的筵席上回来。他来到，叩门，就立刻给他开门。"},
{volumn:        42,chapter:        12,versenumber:        37,verse:"主人来了，看见仆人警醒，那仆人就有福了。我实在告诉你们，主人必叫他们坐席，自己束上带，进前伺候他们。"},
{volumn:        42,chapter:        12,versenumber:        38,verse:"或是二更天来，或是三更天来，看见仆人这样，那仆人就有福了。"},
{volumn:        42,chapter:        12,versenumber:        39,verse:"家主若知道贼什么时候来，就必警醒，不容贼挖透房屋，这是你们所知道的。"},
{volumn:        42,chapter:        12,versenumber:        40,verse:"你们也要预备；因为你们想不到的时候，人子就来了。”"},
{volumn:        42,chapter:        12,versenumber:        41,verse:"彼得说：“主啊，这比喻是为我们说的呢？还是为众人呢？”"},
{volumn:        42,chapter:        12,versenumber:        42,verse:"主说：“谁是那忠心有见识的管家，主人派他管理家里的人，按时分粮给他们呢？"},
{volumn:        42,chapter:        12,versenumber:        43,verse:"主人来到，看见仆人这样行，那仆人就有福了。"},
{volumn:        42,chapter:        12,versenumber:        44,verse:"我实在告诉你们，主人要派他管理一切所有的。"},
{volumn:        42,chapter:        12,versenumber:        45,verse:"那仆人若心里说：‘我的主人必来得迟’，就动手打仆人和使女，并且吃喝醉酒；"},
{volumn:        42,chapter:        12,versenumber:        46,verse:"在他想不到的日子，不知道的时辰，那仆人的主人要来，重重地处治他（或译：把他腰斩了），定他和不忠心的人同罪。"},
{volumn:        42,chapter:        12,versenumber:        47,verse:"仆人知道主人的意思，却不预备，又不顺他的意思行，那仆人必多受责打；"},
{volumn:        42,chapter:        12,versenumber:        48,verse:"惟有那不知道的，做了当受责打的事，必少受责打；因为多给谁，就向谁多取；多托谁，就向谁多要。”"},
{volumn:        42,chapter:        12,versenumber:        49,verse:"“我来要把火丢在地上，倘若已经着起来，不也是我所愿意的吗？"},
{volumn:        42,chapter:        12,versenumber:        50,verse:"我有当受的洗还没有成就，我是何等地迫切呢？"},
{volumn:        42,chapter:        12,versenumber:        51,verse:"你们以为我来，是叫地上太平吗？我告诉你们，不是，乃是叫人纷争。"},
{volumn:        42,chapter:        12,versenumber:        52,verse:"从今以后，一家五个人将要纷争：三个人和两个人相争，两个人和三个人相争；"},
{volumn:        42,chapter:        12,versenumber:        53,verse:"父亲和儿子相争，儿子和父亲相争；母亲和女儿相争，女儿和母亲相争；婆婆和媳妇相争，媳妇和婆婆相争。”"},
{volumn:        42,chapter:        12,versenumber:        54,verse:"耶稣又对众人说：“你们看见西边起了云彩，就说：‘要下一阵雨’；果然就有。"},
{volumn:        42,chapter:        12,versenumber:        55,verse:"起了南风，就说：‘将要燥热’；也就有了。"},
{volumn:        42,chapter:        12,versenumber:        56,verse:"假冒为善的人哪，你们知道分辨天地的气色，怎么不知道分辨这时候呢？”"},
{volumn:        42,chapter:        12,versenumber:        57,verse:"“你们又为何不自己审量什么是合理的呢？"},
{volumn:        42,chapter:        12,versenumber:        58,verse:"你同告你的对头去见官，还在路上，务要尽力地和他了结；恐怕他拉你到官面前，官交付差役，差役把你下在监里。"},
{volumn:        42,chapter:        12,versenumber:        59,verse:"我告诉你，若有半文钱没有还清，你断不能从那里出来。”"},
{volumn:        42,chapter:        13,versenumber:         1,verse:"正当那时，有人将彼拉多使加利利人的血搀杂在他们祭物中的事告诉耶稣。"},
{volumn:        42,chapter:        13,versenumber:         2,verse:"耶稣说：“你们以为这些加利利人比众加利利人更有罪，所以受这害吗？"},
{volumn:        42,chapter:        13,versenumber:         3,verse:"我告诉你们，不是的！你们若不悔改，都要如此灭亡！"},
{volumn:        42,chapter:        13,versenumber:         4,verse:"从前西罗亚楼倒塌了，压死十八个人；你们以为那些人比一切住在耶路撒冷的人更有罪吗？"},
{volumn:        42,chapter:        13,versenumber:         5,verse:"我告诉你们，不是的！你们若不悔改，都要如此灭亡！”"},
{volumn:        42,chapter:        13,versenumber:         6,verse:"于是用比喻说：“一个人有一棵无花果树栽在葡萄园里。他来到树前找果子，却找不着。"},
{volumn:        42,chapter:        13,versenumber:         7,verse:"就对管园的说：‘看哪，我这三年来到这无花果树前找果子，竟找不着。把它砍了吧，何必白占地土呢！’"},
{volumn:        42,chapter:        13,versenumber:         8,verse:"管园的说：‘主啊，今年且留着，等我周围掘开土，加上粪；"},
{volumn:        42,chapter:        13,versenumber:         9,verse:"以后若结果子便罢，不然再把它砍了。’”"},
{volumn:        42,chapter:        13,versenumber:        10,verse:"安息日，耶稣在会堂里教训人。"},
{volumn:        42,chapter:        13,versenumber:        11,verse:"有一个女人被鬼附着，病了十八年，腰弯得一点直不起来。"},
{volumn:        42,chapter:        13,versenumber:        12,verse:"耶稣看见，便叫过她来，对她说：“女人，你脱离这病了！”"},
{volumn:        42,chapter:        13,versenumber:        13,verse:"于是用两只手按着她；她立刻直起腰来，就归荣耀与　神。"},
{volumn:        42,chapter:        13,versenumber:        14,verse:"管会堂的因为耶稣在安息日治病，就气忿忿地对众人说：“有六日应当做工；那六日之内可以来求医，在安息日却不可。”"},
{volumn:        42,chapter:        13,versenumber:        15,verse:"主说：“假冒为善的人哪，难道你们各人在安息日不解开槽上的牛、驴，牵去饮吗？"},
{volumn:        42,chapter:        13,versenumber:        16,verse:"况且这女人本是亚伯拉罕的后裔，被撒但捆绑了这十八年，不当在安息日解开她的绑吗？”"},
{volumn:        42,chapter:        13,versenumber:        17,verse:"耶稣说这话，他的敌人都惭愧了；众人因他所行一切荣耀的事，就都欢喜了。"},
{volumn:        42,chapter:        13,versenumber:        18,verse:"耶稣说：“　神的国好像什么？我拿什么来比较呢？"},
{volumn:        42,chapter:        13,versenumber:        19,verse:"好像一粒芥菜种，有人拿去种在园子里，长大成树，天上的飞鸟宿在它的枝上。”"},
{volumn:        42,chapter:        13,versenumber:        20,verse:"又说：“我拿什么来比　神的国呢？"},
{volumn:        42,chapter:        13,versenumber:        21,verse:"好比面酵，有妇人拿来藏在三斗面里，直等全团都发起来。”"},
{volumn:        42,chapter:        13,versenumber:        22,verse:"耶稣往耶路撒冷去，在所经过的各城各乡教训人。"},
{volumn:        42,chapter:        13,versenumber:        23,verse:"有一个人问他说：“主啊，得救的人少吗？”"},
{volumn:        42,chapter:        13,versenumber:        24,verse:"耶稣对众人说：“你们要努力进窄门。我告诉你们，将来有许多人想要进去，却是不能。"},
{volumn:        42,chapter:        13,versenumber:        25,verse:"及至家主起来关了门，你们站在外面叩门，说：‘主啊，给我们开门！’他就回答说：‘我不认识你们，不晓得你们是哪里来的！’"},
{volumn:        42,chapter:        13,versenumber:        26,verse:"那时，你们要说：‘我们在你面前吃过喝过，你也在我们的街上教训过人。’"},
{volumn:        42,chapter:        13,versenumber:        27,verse:"他要说：‘我告诉你们，我不晓得你们是哪里来的。你们这一切作恶的人，离开我去吧！’"},
{volumn:        42,chapter:        13,versenumber:        28,verse:"你们要看见亚伯拉罕、以撒、雅各，和众先知都在　神的国里，你们却被赶到外面，在那里必要哀哭切齿了。"},
{volumn:        42,chapter:        13,versenumber:        29,verse:"从东、从西、从南、从北将有人来，在　神的国里坐席。"},
{volumn:        42,chapter:        13,versenumber:        30,verse:"只是有在后的，将要在前；有在前的，将要在后。”"},
{volumn:        42,chapter:        13,versenumber:        31,verse:"正当那时，有几个法利赛人来对耶稣说：“离开这里去吧，因为希律想要杀你。”"},
{volumn:        42,chapter:        13,versenumber:        32,verse:"耶稣说：“你们去告诉那个狐狸说：‘今天、明天我赶鬼治病，第三天我的事就成全了。’"},
{volumn:        42,chapter:        13,versenumber:        33,verse:"虽然这样，今天、明天、后天，我必须前行，因为先知在耶路撒冷之外丧命是不能的。"},
{volumn:        42,chapter:        13,versenumber:        34,verse:"耶路撒冷啊！耶路撒冷啊！你常杀害先知，又用石头打死那奉差遣到你这里来的人。我多次愿意聚集你的儿女，好像母鸡把小鸡聚集在翅膀底下；只是你们不愿意。"},
{volumn:        42,chapter:        13,versenumber:        35,verse:"看哪，你们的家成为荒场留给你们。我告诉你们，从今以后你们不得再见我，直等到你们说：‘奉主名来的是应当称颂的。’”"},
{volumn:        42,chapter:        14,versenumber:         1,verse:"安息日，耶稣到一个法利赛人的首领家里去吃饭，他们就窥探他。"},
{volumn:        42,chapter:        14,versenumber:         2,verse:"在他面前有一个患水臌的人。"},
{volumn:        42,chapter:        14,versenumber:         3,verse:"耶稣对律法师和法利赛人说：“安息日治病可以不可以？”"},
{volumn:        42,chapter:        14,versenumber:         4,verse:"他们却不言语。耶稣就治好那人，叫他走了；"},
{volumn:        42,chapter:        14,versenumber:         5,verse:"便对他们说：“你们中间谁有驴或有牛，在安息日掉在井里，不立时拉它上来呢？”"},
{volumn:        42,chapter:        14,versenumber:         6,verse:"他们不能对答这话。"},
{volumn:        42,chapter:        14,versenumber:         7,verse:"耶稣见所请的客拣择首位，就用比喻对他们说："},
{volumn:        42,chapter:        14,versenumber:         8,verse:"“你被人请去赴婚姻的筵席，不要坐在首位上，恐怕有比你尊贵的客被他请来；"},
{volumn:        42,chapter:        14,versenumber:         9,verse:"那请你们的人前来对你说：‘让座给这一位吧！’你就羞羞惭惭地退到末位上去了。"},
{volumn:        42,chapter:        14,versenumber:        10,verse:"你被请的时候，就去坐在末位上，好叫那请你的人来对你说：‘朋友，请上座。’那时，你在同席的人面前就有光彩了。"},
{volumn:        42,chapter:        14,versenumber:        11,verse:"因为，凡自高的，必降为卑；自卑的，必升为高。”"},
{volumn:        42,chapter:        14,versenumber:        12,verse:"耶稣又对请他的人说：“你摆设午饭或晚饭，不要请你的朋友、弟兄、亲属，和富足的邻舍，恐怕他们也请你，你就得了报答。"},
{volumn:        42,chapter:        14,versenumber:        13,verse:"你摆设筵席，倒要请那贫穷的、残废的、瘸腿的、瞎眼的，你就有福了！"},
{volumn:        42,chapter:        14,versenumber:        14,verse:"因为他们没有什么可报答你。到义人复活的时候，你要得着报答。”"},
{volumn:        42,chapter:        14,versenumber:        15,verse:"同席的有一人听见这话，就对耶稣说：“在　神国里吃饭的有福了！”"},
{volumn:        42,chapter:        14,versenumber:        16,verse:"耶稣对他说：“有一人摆设大筵席，请了许多客。"},
{volumn:        42,chapter:        14,versenumber:        17,verse:"到了坐席的时候，打发仆人去对所请的人说：‘请来吧！样样都齐备了。’"},
{volumn:        42,chapter:        14,versenumber:        18,verse:"众人一口同音地推辞。头一个说：‘我买了一块地，必须去看看。请你准我辞了。’"},
{volumn:        42,chapter:        14,versenumber:        19,verse:"又有一个说：‘我买了五对牛，要去试一试。请你准我辞了。’"},
{volumn:        42,chapter:        14,versenumber:        20,verse:"又有一个说：‘我才娶了妻，所以不能去。’"},
{volumn:        42,chapter:        14,versenumber:        21,verse:"那仆人回来，把这事都告诉了主人。家主就动怒，对仆人说：‘快出去，到城里大街小巷，领那贫穷的、残废的、瞎眼的、瘸腿的来。’"},
{volumn:        42,chapter:        14,versenumber:        22,verse:"仆人说：‘主啊，你所吩咐的已经办了，还有空座。’"},
{volumn:        42,chapter:        14,versenumber:        23,verse:"主人对仆人说：‘你出去到路上和篱笆那里，勉强人进来，坐满我的屋子。"},
{volumn:        42,chapter:        14,versenumber:        24,verse:"我告诉你们，先前所请的人没有一个得尝我的筵席。’”"},
{volumn:        42,chapter:        14,versenumber:        25,verse:"有极多的人和耶稣同行。他转过来对他们说："},
{volumn:        42,chapter:        14,versenumber:        26,verse:"“人到我这里来，若不爱我胜过爱（爱我胜过爱：原文是恨）自己的父母、妻子、儿女、弟兄、姐妹，和自己的性命，就不能作我的门徒。"},
{volumn:        42,chapter:        14,versenumber:        27,verse:"凡不背着自己十字架跟从我的，也不能作我的门徒。"},
{volumn:        42,chapter:        14,versenumber:        28,verse:"你们哪一个要盖一座楼，不先坐下算计花费，能盖成不能呢？"},
{volumn:        42,chapter:        14,versenumber:        29,verse:"恐怕安了地基，不能成功，看见的人都笑话他，说："},
{volumn:        42,chapter:        14,versenumber:        30,verse:"‘这个人开了工，却不能完工。’"},
{volumn:        42,chapter:        14,versenumber:        31,verse:"或是一个王出去和别的王打仗，岂不先坐下酌量，能用一万兵去敌那领二万兵来攻打他的吗？"},
{volumn:        42,chapter:        14,versenumber:        32,verse:"若是不能，就趁敌人还远的时候，派使者去求和息的条款。"},
{volumn:        42,chapter:        14,versenumber:        33,verse:"这样，你们无论什么人，若不撇下一切所有的，就不能作我的门徒。”"},
{volumn:        42,chapter:        14,versenumber:        34,verse:"“盐本是好的；盐若失了味，可用什么叫它再咸呢？"},
{volumn:        42,chapter:        14,versenumber:        35,verse:"或用在田里，或堆在粪里，都不合适，只好丢在外面。有耳可听的，就应当听！”"},
{volumn:        42,chapter:        15,versenumber:         1,verse:"众税吏和罪人都挨近耶稣，要听他讲道。"},
{volumn:        42,chapter:        15,versenumber:         2,verse:"法利赛人和文士私下议论说：“这个人接待罪人，又同他们吃饭。”"},
{volumn:        42,chapter:        15,versenumber:         3,verse:"耶稣就用比喻说："},
{volumn:        42,chapter:        15,versenumber:         4,verse:"“你们中间谁有一百只羊失去一只，不把这九十九只撇在旷野、去找那失去的羊，直到找着呢？"},
{volumn:        42,chapter:        15,versenumber:         5,verse:"找着了，就欢欢喜喜地扛在肩上，回到家里，"},
{volumn:        42,chapter:        15,versenumber:         6,verse:"就请朋友邻舍来，对他们说：‘我失去的羊已经找着了，你们和我一同欢喜吧！’"},
{volumn:        42,chapter:        15,versenumber:         7,verse:"我告诉你们，一个罪人悔改，在天上也要这样为他欢喜，较比为九十九个不用悔改的义人欢喜更大。”"},
{volumn:        42,chapter:        15,versenumber:         8,verse:"“或是一个妇人有十块钱，若失落一块，岂不点上灯，打扫屋子，细细地找，直到找着吗？"},
{volumn:        42,chapter:        15,versenumber:         9,verse:"找着了，就请朋友邻舍来，对他们说：‘我失落的那块钱已经找着了，你们和我一同欢喜吧！’"},
{volumn:        42,chapter:        15,versenumber:        10,verse:"我告诉你们，一个罪人悔改，在　神的使者面前也是这样为他欢喜。”"},
{volumn:        42,chapter:        15,versenumber:        11,verse:"耶稣又说：“一个人有两个儿子。"},
{volumn:        42,chapter:        15,versenumber:        12,verse:"小儿子对父亲说：‘父亲，请你把我应得的家业分给我。’他父亲就把产业分给他们。"},
{volumn:        42,chapter:        15,versenumber:        13,verse:"过了不多几日，小儿子就把他一切所有的都收拾起来，往远方去了。在那里任意放荡，浪费资财。"},
{volumn:        42,chapter:        15,versenumber:        14,verse:"既耗尽了一切所有的，又遇着那地方大遭饥荒，就穷苦起来。"},
{volumn:        42,chapter:        15,versenumber:        15,verse:"于是去投靠那地方的一个人；那人打发他到田里去放猪。"},
{volumn:        42,chapter:        15,versenumber:        16,verse:"他恨不得拿猪所吃的豆荚充饥，也没有人给他。"},
{volumn:        42,chapter:        15,versenumber:        17,verse:"他醒悟过来，就说：‘我父亲有多少的雇工，口粮有余，我倒在这里饿死吗？"},
{volumn:        42,chapter:        15,versenumber:        18,verse:"我要起来，到我父亲那里去，向他说：父亲！我得罪了天，又得罪了你；"},
{volumn:        42,chapter:        15,versenumber:        19,verse:"从今以后，我不配称为你的儿子，把我当作一个雇工吧！’"},
{volumn:        42,chapter:        15,versenumber:        20,verse:"于是起来，往他父亲那里去。相离还远，他父亲看见，就动了慈心，跑去抱着他的颈项，连连与他亲嘴。"},
{volumn:        42,chapter:        15,versenumber:        21,verse:"儿子说：‘父亲！我得罪了天，又得罪了你；从今以后，我不配称为你的儿子。’"},
{volumn:        42,chapter:        15,versenumber:        22,verse:"父亲却吩咐仆人说：‘把那上好的袍子快拿出来给他穿；把戒指戴在他指头上；把鞋穿在他脚上；"},
{volumn:        42,chapter:        15,versenumber:        23,verse:"把那肥牛犊牵来宰了，我们可以吃喝快乐；"},
{volumn:        42,chapter:        15,versenumber:        24,verse:"因为我这个儿子是死而复活，失而又得的。’他们就快乐起来。"},
{volumn:        42,chapter:        15,versenumber:        25,verse:"那时，大儿子正在田里。他回来，离家不远，听见作乐跳舞的声音，"},
{volumn:        42,chapter:        15,versenumber:        26,verse:"便叫过一个仆人来，问是什么事。"},
{volumn:        42,chapter:        15,versenumber:        27,verse:"仆人说：‘你兄弟来了；你父亲因为得他无灾无病地回来，把肥牛犊宰了。’"},
{volumn:        42,chapter:        15,versenumber:        28,verse:"大儿子却生气，不肯进去；他父亲就出来劝他。"},
{volumn:        42,chapter:        15,versenumber:        29,verse:"他对父亲说：‘我服侍你这多年，从来没有违背过你的命，你并没有给我一只山羊羔，叫我和朋友一同快乐。"},
{volumn:        42,chapter:        15,versenumber:        30,verse:"但你这个儿子和娼妓吞尽了你的产业，他一来了，你倒为他宰了肥牛犊。’"},
{volumn:        42,chapter:        15,versenumber:        31,verse:"父亲对他说：‘儿啊！你常和我同在，我一切所有的都是你的；"},
{volumn:        42,chapter:        15,versenumber:        32,verse:"只是你这个兄弟是死而复活、失而又得的，所以我们理当欢喜快乐。’”"},
{volumn:        42,chapter:        16,versenumber:         1,verse:"耶稣又对门徒说：“有一个财主的管家，别人向他主人告他浪费主人的财物。"},
{volumn:        42,chapter:        16,versenumber:         2,verse:"主人叫他来，对他说：‘我听见你这事怎么样呢？把你所经管的交代明白，因你不能再作我的管家。’"},
{volumn:        42,chapter:        16,versenumber:         3,verse:"那管家心里说：‘主人辞我，不用我再作管家，我将来做什么？锄地呢？无力；讨饭呢？怕羞。"},
{volumn:        42,chapter:        16,versenumber:         4,verse:"我知道怎么行，好叫人在我不作管家之后，接我到他们家里去。’"},
{volumn:        42,chapter:        16,versenumber:         5,verse:"于是把欠他主人债的，一个一个地叫了来，问头一个说：‘你欠我主人多少？’"},
{volumn:        42,chapter:        16,versenumber:         6,verse:"他说：‘一百篓（每篓约五十斤）油。’管家说：‘拿你的帐，快坐下，写五十。’"},
{volumn:        42,chapter:        16,versenumber:         7,verse:"又问一个说：‘你欠多少？’他说：‘一百石麦子。’管家说：‘拿你的帐，写八十。’"},
{volumn:        42,chapter:        16,versenumber:         8,verse:"主人就夸奖这不义的管家做事聪明；因为今世之子，在世事之上，较比光明之子更加聪明。"},
{volumn:        42,chapter:        16,versenumber:         9,verse:"我又告诉你们，要藉着那不义的钱财结交朋友，到了钱财无用的时候，他们可以接你们到永存的帐幕里去。"},
{volumn:        42,chapter:        16,versenumber:        10,verse:"人在最小的事上忠心，在大事上也忠心；在最小的事上不义，在大事上也不义。"},
{volumn:        42,chapter:        16,versenumber:        11,verse:"倘若你们在不义的钱财上不忠心，谁还把那真实的钱财托付你们呢？"},
{volumn:        42,chapter:        16,versenumber:        12,verse:"倘若你们在别人的东西上不忠心，谁还把你们自己的东西给你们呢？"},
{volumn:        42,chapter:        16,versenumber:        13,verse:"一个仆人不能侍奉两个主；不是恶这个爱那个，就是重这个轻那个。你们不能又侍奉　神，又侍奉玛门。”"},
{volumn:        42,chapter:        16,versenumber:        14,verse:"法利赛人是贪爱钱财的；他们听见这一切话，就嗤笑耶稣。"},
{volumn:        42,chapter:        16,versenumber:        15,verse:"耶稣对他们说：“你们是在人面前自称为义的，你们的心，　神却知道；因为人所尊贵的，是　神看为可憎恶的。"},
{volumn:        42,chapter:        16,versenumber:        16,verse:"律法和先知到约翰为止，从此　神国的福音传开了，人人努力要进去。"},
{volumn:        42,chapter:        16,versenumber:        17,verse:"天地废去较比律法的一点一画落空还容易。"},
{volumn:        42,chapter:        16,versenumber:        18,verse:"凡休妻另娶的就是犯奸淫；娶被休之妻的也是犯奸淫。”"},
{volumn:        42,chapter:        16,versenumber:        19,verse:"“有一个财主穿着紫色袍和细麻布衣服，天天奢华宴乐。"},
{volumn:        42,chapter:        16,versenumber:        20,verse:"又有一个讨饭的，名叫拉撒路，浑身生疮，被人放在财主门口，"},
{volumn:        42,chapter:        16,versenumber:        21,verse:"要得财主桌子上掉下来的零碎充饥，并且狗来舔他的疮。"},
{volumn:        42,chapter:        16,versenumber:        22,verse:"后来那讨饭的死了，被天使带去放在亚伯拉罕的怀里。财主也死了，并且埋葬了。"},
{volumn:        42,chapter:        16,versenumber:        23,verse:"他在阴间受痛苦，举目远远地望见亚伯拉罕，又望见拉撒路在他怀里，"},
{volumn:        42,chapter:        16,versenumber:        24,verse:"就喊着说：‘我祖亚伯拉罕哪，可怜我吧！打发拉撒路来，用指头尖蘸点水，凉凉我的舌头；因为我在这火焰里，极其痛苦。’"},
{volumn:        42,chapter:        16,versenumber:        25,verse:"亚伯拉罕说：‘儿啊，你该回想你生前享过福，拉撒路也受过苦；如今他在这里得安慰，你倒受痛苦。"},
{volumn:        42,chapter:        16,versenumber:        26,verse:"不但这样，并且在你我之间，有深渊限定，以致人要从这边过到你们那边是不能的；要从那边过到我们这边也是不能的。’"},
{volumn:        42,chapter:        16,versenumber:        27,verse:"财主说：‘我祖啊！既是这样，求你打发拉撒路到我父家去；"},
{volumn:        42,chapter:        16,versenumber:        28,verse:"因为我还有五个弟兄，他可以对他们作见证，免得他们也来到这痛苦的地方。’"},
{volumn:        42,chapter:        16,versenumber:        29,verse:"亚伯拉罕说：‘他们有摩西和先知的话可以听从。’"},
{volumn:        42,chapter:        16,versenumber:        30,verse:"他说：‘我祖亚伯拉罕哪，不是的，若有一个从死里复活的，到他们那里去的，他们必要悔改。’"},
{volumn:        42,chapter:        16,versenumber:        31,verse:"亚伯拉罕说：‘若不听从摩西和先知的话，就是有一个从死里复活的，他们也是不听劝。’”"},
{volumn:        42,chapter:        17,versenumber:         1,verse:"耶稣又对门徒说：“绊倒人的事是免不了的；但那绊倒人的有祸了。"},
{volumn:        42,chapter:        17,versenumber:         2,verse:"就是把磨石拴在这人的颈项上，丢在海里，还强如他把这小子里的一个绊倒了。"},
{volumn:        42,chapter:        17,versenumber:         3,verse:"你们要谨慎！若是你的弟兄得罪你，就劝戒他；他若懊悔，就饶恕他。"},
{volumn:        42,chapter:        17,versenumber:         4,verse:"倘若他一天七次得罪你，又七次回转，说：‘我懊悔了’，你总要饶恕他。”"},
{volumn:        42,chapter:        17,versenumber:         5,verse:"使徒对主说：“求主加增我们的信心。”"},
{volumn:        42,chapter:        17,versenumber:         6,verse:"主说：“你们若有信心像一粒芥菜种，就是对这棵桑树说：‘你要拔起根来，栽在海里’，它也必听从你们。"},
{volumn:        42,chapter:        17,versenumber:         7,verse:"你们谁有仆人耕地或是放羊，从田里回来，就对他说：‘你快来坐下吃饭’呢？"},
{volumn:        42,chapter:        17,versenumber:         8,verse:"岂不对他说：‘你给我预备晚饭，束上带子伺候我，等我吃喝完了，你才可以吃喝’吗？"},
{volumn:        42,chapter:        17,versenumber:         9,verse:"仆人照所吩咐的去做，主人还谢谢他吗？"},
{volumn:        42,chapter:        17,versenumber:        10,verse:"这样，你们做完了一切所吩咐的，只当说：‘我们是无用的仆人，所做的本是我们应分作的。’”"},
{volumn:        42,chapter:        17,versenumber:        11,verse:"耶稣往耶路撒冷去，经过撒玛利亚和加利利。"},
{volumn:        42,chapter:        17,versenumber:        12,verse:"进入一个村子，有十个长大麻风的，迎面而来，远远地站着，"},
{volumn:        42,chapter:        17,versenumber:        13,verse:"高声说：“耶稣，夫子，可怜我们吧！”"},
{volumn:        42,chapter:        17,versenumber:        14,verse:"耶稣看见，就对他们说：“你们去把身体给祭司察看。”他们去的时候就洁净了。"},
{volumn:        42,chapter:        17,versenumber:        15,verse:"内中有一个见自己已经好了，就回来大声归荣耀与　神，"},
{volumn:        42,chapter:        17,versenumber:        16,verse:"又俯伏在耶稣脚前感谢他；这人是撒玛利亚人。"},
{volumn:        42,chapter:        17,versenumber:        17,verse:"耶稣说：“洁净了的不是十个人吗？那九个在哪里呢？"},
{volumn:        42,chapter:        17,versenumber:        18,verse:"除了这外族人，再没有别人回来归荣耀与　神吗？”"},
{volumn:        42,chapter:        17,versenumber:        19,verse:"就对那人说：“起来，走吧！你的信救了你了。”"},
{volumn:        42,chapter:        17,versenumber:        20,verse:"法利赛人问：“神的国几时来到？”耶稣回答说：“　神的国来到不是眼所能见的。"},
{volumn:        42,chapter:        17,versenumber:        21,verse:"人也不得说：‘看哪，在这里！看哪，在那里！’因为　神的国就在你们心里（心里：或译中间）。”"},
{volumn:        42,chapter:        17,versenumber:        22,verse:"他又对门徒说：“日子将到，你们巴不得看见人子的一个日子，却不得看见。"},
{volumn:        42,chapter:        17,versenumber:        23,verse:"人将要对你们说：‘看哪，在那里！看哪，在这里！’你们不要出去，也不要跟随他们！"},
{volumn:        42,chapter:        17,versenumber:        24,verse:"因为人子在他降临的日子，好像闪电从天这边一闪直照到天那边。"},
{volumn:        42,chapter:        17,versenumber:        25,verse:"只是他必须先受许多苦，又被这世代弃绝。"},
{volumn:        42,chapter:        17,versenumber:        26,verse:"挪亚的日子怎样，人子的日子也要怎样。"},
{volumn:        42,chapter:        17,versenumber:        27,verse:"那时候的人又吃又喝，又娶又嫁，到挪亚进方舟的那日，洪水就来，把他们全都灭了。"},
{volumn:        42,chapter:        17,versenumber:        28,verse:"又好像罗得的日子；人又吃又喝，又买又卖，又耕种又盖造。"},
{volumn:        42,chapter:        17,versenumber:        29,verse:"到罗得出所多玛的那日，就有火与硫磺从天上降下来，把他们全都灭了。"},
{volumn:        42,chapter:        17,versenumber:        30,verse:"人子显现的日子也要这样。"},
{volumn:        42,chapter:        17,versenumber:        31,verse:"当那日，人在房上，器具在屋里，不要下来拿；人在田里，也不要回家。"},
{volumn:        42,chapter:        17,versenumber:        32,verse:"你们要回想罗得的妻子。"},
{volumn:        42,chapter:        17,versenumber:        33,verse:"凡想要保全生命的，必丧掉生命；凡丧掉生命的，必救活生命。"},
{volumn:        42,chapter:        17,versenumber:        34,verse:"我对你们说，当那一夜，两个人在一个床上，要取去一个，撇下一个。"},
{volumn:        42,chapter:        17,versenumber:        35,verse:"两个女人一同推磨，要取去一个，撇下一个。（有古卷加：36两个人在田里，要取去一个，撇下一个。）”"},
{volumn:        42,chapter:        17,versenumber:        36,verse:""},
{volumn:        42,chapter:        17,versenumber:        37,verse:"门徒说：“主啊，在哪里有这事呢？”耶稣说：“尸首在哪里，鹰也必聚在那里。”"},
{volumn:        42,chapter:        18,versenumber:         1,verse:"耶稣设一个比喻，是要人常常祷告，不可灰心。"},
{volumn:        42,chapter:        18,versenumber:         2,verse:"说：“某城里有一个官，不惧怕　神，也不尊重世人。"},
{volumn:        42,chapter:        18,versenumber:         3,verse:"那城里有个寡妇，常到他那里，说：‘我有一个对头，求你给我伸冤。’"},
{volumn:        42,chapter:        18,versenumber:         4,verse:"他多日不准，后来心里说：‘我虽不惧怕　神，也不尊重世人，"},
{volumn:        42,chapter:        18,versenumber:         5,verse:"只因这寡妇烦扰我，我就给她伸冤吧，免得她常来缠磨我！’”"},
{volumn:        42,chapter:        18,versenumber:         6,verse:"主说：“你们听这不义之官所说的话。"},
{volumn:        42,chapter:        18,versenumber:         7,verse:"神的选民昼夜呼吁他，他纵然为他们忍了多时，岂不终久给他们伸冤吗？"},
{volumn:        42,chapter:        18,versenumber:         8,verse:"我告诉你们，要快快地给他们伸冤了。然而，人子来的时候，遇得见世上有信德吗？”"},
{volumn:        42,chapter:        18,versenumber:         9,verse:"耶稣向那些仗着自己是义人，藐视别人的，设一个比喻，"},
{volumn:        42,chapter:        18,versenumber:        10,verse:"说：“有两个人上殿里去祷告：一个是法利赛人，一个是税吏。"},
{volumn:        42,chapter:        18,versenumber:        11,verse:"法利赛人站着，自言自语地祷告说：‘　神啊，我感谢你，我不像别人勒索、不义、奸淫，也不像这个税吏。"},
{volumn:        42,chapter:        18,versenumber:        12,verse:"我一个礼拜禁食两次，凡我所得的都捐上十分之一。’"},
{volumn:        42,chapter:        18,versenumber:        13,verse:"那税吏远远地站着，连举目望天也不敢，只捶着胸说：‘　神啊，开恩可怜我这个罪人！’"},
{volumn:        42,chapter:        18,versenumber:        14,verse:"我告诉你们，这人回家去比那人倒算为义了；因为，凡自高的，必降为卑；自卑的，必升为高。”"},
{volumn:        42,chapter:        18,versenumber:        15,verse:"有人抱着自己的婴孩来见耶稣，要他摸他们；门徒看见就责备那些人。"},
{volumn:        42,chapter:        18,versenumber:        16,verse:"耶稣却叫他们来，说：“让小孩子到我这里来，不要禁止他们，因为在　神国的正是这样的人。"},
{volumn:        42,chapter:        18,versenumber:        17,verse:"我实在告诉你们，凡要承受　神国的，若不像小孩子，断不能进去。”"},
{volumn:        42,chapter:        18,versenumber:        18,verse:"有一个官问耶稣说：“良善的夫子，我该做什么事才可以承受永生？”"},
{volumn:        42,chapter:        18,versenumber:        19,verse:"耶稣对他说：“你为什么称我是良善的？除了　神一位之外，再没有良善的。"},
{volumn:        42,chapter:        18,versenumber:        20,verse:"诫命你是晓得的：‘不可奸淫；不可杀人；不可偷盗；不可作假见证；当孝敬父母。’”"},
{volumn:        42,chapter:        18,versenumber:        21,verse:"那人说：“这一切我从小都遵守了。”"},
{volumn:        42,chapter:        18,versenumber:        22,verse:"耶稣听见了，就说：“你还缺少一件：要变卖你一切所有的，分给穷人，就必有财宝在天上；你还要来跟从我。”"},
{volumn:        42,chapter:        18,versenumber:        23,verse:"他听见这话，就甚忧愁，因为他很富足。"},
{volumn:        42,chapter:        18,versenumber:        24,verse:"耶稣看见他，就说：“有钱财的人进　神的国是何等的难哪！"},
{volumn:        42,chapter:        18,versenumber:        25,verse:"骆驼穿过针的眼比财主进　神的国还容易呢！”"},
{volumn:        42,chapter:        18,versenumber:        26,verse:"听见的人说：“这样，谁能得救呢？”"},
{volumn:        42,chapter:        18,versenumber:        27,verse:"耶稣说：“在人所不能的事，在　神却能。”"},
{volumn:        42,chapter:        18,versenumber:        28,verse:"彼得说：“看哪，我们已经撇下自己所有的跟从你了。”"},
{volumn:        42,chapter:        18,versenumber:        29,verse:"耶稣说：“我实在告诉你们，人为　神的国撇下房屋，或是妻子、弟兄、父母、儿女，"},
{volumn:        42,chapter:        18,versenumber:        30,verse:"没有在今世不得百倍，在来世不得永生的。”"},
{volumn:        42,chapter:        18,versenumber:        31,verse:"耶稣带着十二个门徒，对他们说：“看哪，我们上耶路撒冷去，先知所写的一切事都要成就在人子身上。"},
{volumn:        42,chapter:        18,versenumber:        32,verse:"他将要被交给外邦人；他们要戏弄他，凌辱他，吐唾沫在他脸上，"},
{volumn:        42,chapter:        18,versenumber:        33,verse:"并要鞭打他，杀害他；第三日他要复活。”"},
{volumn:        42,chapter:        18,versenumber:        34,verse:"这些事门徒一样也不懂得，意思乃是隐藏的；他们不晓得所说的是什么。"},
{volumn:        42,chapter:        18,versenumber:        35,verse:"耶稣将近耶利哥的时候，有一个瞎子坐在路旁讨饭。"},
{volumn:        42,chapter:        18,versenumber:        36,verse:"听见许多人经过，就问是什么事。"},
{volumn:        42,chapter:        18,versenumber:        37,verse:"他们告诉他，是拿撒勒人耶稣经过。"},
{volumn:        42,chapter:        18,versenumber:        38,verse:"他就呼叫说：“大卫的子孙耶稣啊，可怜我吧！”"},
{volumn:        42,chapter:        18,versenumber:        39,verse:"在前头走的人就责备他，不许他作声；他却越发喊叫说：“大卫的子孙，可怜我吧！”"},
{volumn:        42,chapter:        18,versenumber:        40,verse:"耶稣站住，吩咐把他领过来，到了跟前，就问他说："},
{volumn:        42,chapter:        18,versenumber:        41,verse:"“你要我为你做什么？”他说：“主啊，我要能看见。”"},
{volumn:        42,chapter:        18,versenumber:        42,verse:"耶稣说：“你可以看见！你的信救了你了。”"},
{volumn:        42,chapter:        18,versenumber:        43,verse:"瞎子立刻看见了，就跟随耶稣，一路归荣耀与　神。众人看见这事，也赞美　神。"},
{volumn:        42,chapter:        19,versenumber:         1,verse:"耶稣进了耶利哥，正经过的时候，"},
{volumn:        42,chapter:        19,versenumber:         2,verse:"有一个人名叫撒该，作税吏长，是个财主。"},
{volumn:        42,chapter:        19,versenumber:         3,verse:"他要看看耶稣是怎样的人；只因人多，他的身量又矮，所以不得看见，"},
{volumn:        42,chapter:        19,versenumber:         4,verse:"就跑到前头，爬上桑树，要看耶稣，因为耶稣必从那里经过。"},
{volumn:        42,chapter:        19,versenumber:         5,verse:"耶稣到了那里，抬头一看，对他说：“撒该，快下来！今天我必住在你家里。”"},
{volumn:        42,chapter:        19,versenumber:         6,verse:"他就急忙下来，欢欢喜喜地接待耶稣。"},
{volumn:        42,chapter:        19,versenumber:         7,verse:"众人看见，都私下议论说：“他竟到罪人家里去住宿。”"},
{volumn:        42,chapter:        19,versenumber:         8,verse:"撒该站着对主说：“主啊，我把所有的一半给穷人；我若讹诈了谁，就还他四倍。”"},
{volumn:        42,chapter:        19,versenumber:         9,verse:"耶稣说：“今天救恩到了这家，因为他也是亚伯拉罕的子孙。"},
{volumn:        42,chapter:        19,versenumber:        10,verse:"人子来，为要寻找、拯救失丧的人。”"},
{volumn:        42,chapter:        19,versenumber:        11,verse:"众人正在听见这些话的时候，耶稣因为将近耶路撒冷，又因他们以为　神的国快要显出来，就另设一个比喻，说："},
{volumn:        42,chapter:        19,versenumber:        12,verse:"“有一个贵胄往远方去，要得国回来，"},
{volumn:        42,chapter:        19,versenumber:        13,verse:"便叫了他的十个仆人来，交给他们十锭（锭：原文作弥拿，一弥拿约银十两）银子，说：‘你们去做生意，直等我回来。’"},
{volumn:        42,chapter:        19,versenumber:        14,verse:"他本国的人却恨他，打发使者随后去，说：‘我们不愿意这个人作我们的王。’"},
{volumn:        42,chapter:        19,versenumber:        15,verse:"他既得国回来，就吩咐叫那领银子的仆人来，要知道他们做生意赚了多少。"},
{volumn:        42,chapter:        19,versenumber:        16,verse:"头一个上来，说：‘主啊，你的一锭银子已经赚了十锭。’"},
{volumn:        42,chapter:        19,versenumber:        17,verse:"主人说：‘好！良善的仆人，你既在最小的事上有忠心，可以有权柄管十座城。’"},
{volumn:        42,chapter:        19,versenumber:        18,verse:"第二个来，说：‘主啊，你的一锭银子已经赚了五锭。’"},
{volumn:        42,chapter:        19,versenumber:        19,verse:"主人说：‘你也可以管五座城。’"},
{volumn:        42,chapter:        19,versenumber:        20,verse:"又有一个来说：‘主啊，看哪，你的一锭银子在这里，我把它包在手巾里存着。"},
{volumn:        42,chapter:        19,versenumber:        21,verse:"我原是怕你，因为你是严厉的人；没有放下的，还要去拿，没有种下的，还要去收。’"},
{volumn:        42,chapter:        19,versenumber:        22,verse:"主人对他说：‘你这恶仆，我要凭你的口定你的罪。你既知道我是严厉的人，没有放下的，还要去拿，没有种下的，还要去收，"},
{volumn:        42,chapter:        19,versenumber:        23,verse:"为什么不把我的银子交给银行，等我来的时候，连本带利都可以要回来呢？’"},
{volumn:        42,chapter:        19,versenumber:        24,verse:"就对旁边站着的人说：‘夺过他这一锭来，给那有十锭的。’"},
{volumn:        42,chapter:        19,versenumber:        25,verse:"他们说：‘主啊，他已经有十锭了。’"},
{volumn:        42,chapter:        19,versenumber:        26,verse:"主人说：‘我告诉你们，凡有的，还要加给他；没有的，连他所有的也要夺过来。"},
{volumn:        42,chapter:        19,versenumber:        27,verse:"至于我那些仇敌，不要我作他们王的，把他们拉来，在我面前杀了吧！’”"},
{volumn:        42,chapter:        19,versenumber:        28,verse:"耶稣说完了这话，就在前面走，上耶路撒冷去。"},
{volumn:        42,chapter:        19,versenumber:        29,verse:"将近伯法其和伯大尼，在一座山名叫橄榄山那里，就打发两个门徒，说："},
{volumn:        42,chapter:        19,versenumber:        30,verse:"“你们往对面村子里去，进去的时候，必看见一匹驴驹拴在那里，是从来没有人骑过的，可以解开牵来。"},
{volumn:        42,chapter:        19,versenumber:        31,verse:"若有人问为什么解它，你们就说：‘主要用它。’”"},
{volumn:        42,chapter:        19,versenumber:        32,verse:"打发的人去了，所遇见的正如耶稣所说的。"},
{volumn:        42,chapter:        19,versenumber:        33,verse:"他们解驴驹的时候，主人问他们说：“解驴驹做什么？”"},
{volumn:        42,chapter:        19,versenumber:        34,verse:"他们说：“主要用它。”"},
{volumn:        42,chapter:        19,versenumber:        35,verse:"他们牵到耶稣那里，把自己的衣服搭在上面，扶着耶稣骑上。"},
{volumn:        42,chapter:        19,versenumber:        36,verse:"走的时候，众人把衣服铺在路上。"},
{volumn:        42,chapter:        19,versenumber:        37,verse:"将近耶路撒冷，正下橄榄山的时候，众门徒因所见过的一切异能，都欢乐起来，大声赞美　神，"},
{volumn:        42,chapter:        19,versenumber:        38,verse:"说：“奉主名来的王是应当称颂的！在天上有和平；在至高之处有荣光。”"},
{volumn:        42,chapter:        19,versenumber:        39,verse:"众人中有几个法利赛人对耶稣说：“夫子，责备你的门徒吧！”"},
{volumn:        42,chapter:        19,versenumber:        40,verse:"耶稣说：“我告诉你们，若是他们闭口不说，这些石头必要呼叫起来。”"},
{volumn:        42,chapter:        19,versenumber:        41,verse:"耶稣快到耶路撒冷，看见城，就为它哀哭，"},
{volumn:        42,chapter:        19,versenumber:        42,verse:"说：“巴不得你在这日子知道关系你平安的事；无奈这事现在是隐藏的，叫你的眼看不出来。"},
{volumn:        42,chapter:        19,versenumber:        43,verse:"因为日子将到，你的仇敌必筑起土垒，周围环绕你，四面困住你，"},
{volumn:        42,chapter:        19,versenumber:        44,verse:"并要扫灭你和你里头的儿女，连一块石头也不留在石头上，因你不知道眷顾你的时候。”"},
{volumn:        42,chapter:        19,versenumber:        45,verse:"耶稣进了殿，赶出里头做买卖的人，"},
{volumn:        42,chapter:        19,versenumber:        46,verse:"对他们说：“经上说：‘我的殿必作祷告的殿’，你们倒使它成为贼窝了。”"},
{volumn:        42,chapter:        19,versenumber:        47,verse:"耶稣天天在殿里教训人。祭司长和文士与百姓的尊长都想要杀他，"},
{volumn:        42,chapter:        19,versenumber:        48,verse:"但寻不出法子来，因为百姓都侧耳听他。"},
{volumn:        42,chapter:        20,versenumber:         1,verse:"有一天，耶稣在殿里教训百姓，讲福音的时候，祭司长和文士并长老上前来，"},
{volumn:        42,chapter:        20,versenumber:         2,verse:"问他说：“你告诉我们，你仗着什么权柄做这些事？给你这权柄的是谁呢？”"},
{volumn:        42,chapter:        20,versenumber:         3,verse:"耶稣回答说：“我也要问你们一句话，你们且告诉我。"},
{volumn:        42,chapter:        20,versenumber:         4,verse:"约翰的洗礼是从天上来的？是从人间来的呢？”"},
{volumn:        42,chapter:        20,versenumber:         5,verse:"他们彼此商议说：“我们若说‘从天上来’，他必说：‘你们为什么不信他呢？’"},
{volumn:        42,chapter:        20,versenumber:         6,verse:"若说‘从人间来’，百姓都要用石头打死我们，因为他们信约翰是先知。”"},
{volumn:        42,chapter:        20,versenumber:         7,verse:"于是回答说：“不知道是从哪里来的。”"},
{volumn:        42,chapter:        20,versenumber:         8,verse:"耶稣说：“我也不告诉你们，我仗着什么权柄做这些事。”"},
{volumn:        42,chapter:        20,versenumber:         9,verse:"耶稣就设比喻对百姓说：“有人栽了一个葡萄园，租给园户，就往外国去住了许久。"},
{volumn:        42,chapter:        20,versenumber:        10,verse:"到了时候，打发一个仆人到园户那里去，叫他们把园中当纳的果子交给他；园户竟打了他，叫他空手回去。"},
{volumn:        42,chapter:        20,versenumber:        11,verse:"又打发一个仆人去，他们也打了他，并且凌辱他，叫他空手回去。"},
{volumn:        42,chapter:        20,versenumber:        12,verse:"又打发第三个仆人去，他们也打伤了他，把他推出去了。"},
{volumn:        42,chapter:        20,versenumber:        13,verse:"园主说：‘我怎么办呢？我要打发我的爱子去，或者他们尊敬他。’"},
{volumn:        42,chapter:        20,versenumber:        14,verse:"不料，园户看见他，就彼此商量说：‘这是承受产业的，我们杀他吧，使产业归于我们！’"},
{volumn:        42,chapter:        20,versenumber:        15,verse:"于是把他推出葡萄园外，杀了。这样，葡萄园的主人要怎样处治他们呢？"},
{volumn:        42,chapter:        20,versenumber:        16,verse:"他要来除灭这些园户，将葡萄园转给别人。”听见的人说：“这是万不可的！”"},
{volumn:        42,chapter:        20,versenumber:        17,verse:"耶稣看着他们说：“经上记着：‘匠人所弃的石头已作了房角的头块石头。’这是什么意思呢？"},
{volumn:        42,chapter:        20,versenumber:        18,verse:"凡掉在那石头上的，必要跌碎；那石头掉在谁的身上，就要把谁砸得稀烂。”"},
{volumn:        42,chapter:        20,versenumber:        19,verse:"文士和祭司长看出这比喻是指着他们说的，当时就想要下手拿他，只是惧怕百姓。"},
{volumn:        42,chapter:        20,versenumber:        20,verse:"于是窥探耶稣，打发奸细装作好人，要在他的话上得把柄，好将他交在巡抚的政权之下。"},
{volumn:        42,chapter:        20,versenumber:        21,verse:"奸细就问耶稣说：“夫子，我们晓得你所讲所传都是正道，也不取人的外貌，乃是诚诚实实传　神的道。"},
{volumn:        42,chapter:        20,versenumber:        22,verse:"我们纳税给凯撒，可以不可以？”"},
{volumn:        42,chapter:        20,versenumber:        23,verse:"耶稣看出他们的诡诈，就对他们说："},
{volumn:        42,chapter:        20,versenumber:        24,verse:"“拿一个银钱来给我看。这像和这号是谁的？”他们说：“是凯撒的。”"},
{volumn:        42,chapter:        20,versenumber:        25,verse:"耶稣说：“这样，凯撒的物当归给凯撒，　神的物当归给神。”"},
{volumn:        42,chapter:        20,versenumber:        26,verse:"他们当着百姓，在这话上得不着把柄，又希奇他的应对，就闭口无言了。"},
{volumn:        42,chapter:        20,versenumber:        27,verse:"撒都该人常说没有复活的事。有几个来问耶稣说："},
{volumn:        42,chapter:        20,versenumber:        28,verse:"“夫子！摩西为我们写着说：‘人若有妻无子就死了，他兄弟当娶他的妻，为哥哥生子立后。’"},
{volumn:        42,chapter:        20,versenumber:        29,verse:"有弟兄七人，第一个娶了妻，没有孩子死了；"},
{volumn:        42,chapter:        20,versenumber:        30,verse:"第二个、第三个也娶过她；"},
{volumn:        42,chapter:        20,versenumber:        31,verse:"那七个人都娶过她，没有留下孩子就死了。"},
{volumn:        42,chapter:        20,versenumber:        32,verse:"后来妇人也死了。"},
{volumn:        42,chapter:        20,versenumber:        33,verse:"这样，当复活的时候，她是哪一个的妻子呢？因为他们七个人都娶过她。”"},
{volumn:        42,chapter:        20,versenumber:        34,verse:"耶稣说：“这世界的人有娶有嫁；"},
{volumn:        42,chapter:        20,versenumber:        35,verse:"惟有算为配得那世界，与从死里复活的人也不娶也不嫁；"},
{volumn:        42,chapter:        20,versenumber:        36,verse:"因为他们不能再死，和天使一样；既是复活的人，就为　神的儿子。"},
{volumn:        42,chapter:        20,versenumber:        37,verse:"至于死人复活，摩西在荆棘篇上，称主是亚伯拉罕的　神，以撒的　神，雅各的　神，就指示明白了。"},
{volumn:        42,chapter:        20,versenumber:        38,verse:"神原不是死人的　神，乃是活人的　神；因为在他那里（那里：或译看来），人都是活的。”"},
{volumn:        42,chapter:        20,versenumber:        39,verse:"有几个文士说：“夫子！你说得好。”"},
{volumn:        42,chapter:        20,versenumber:        40,verse:"以后他们不敢再问他什么。"},
{volumn:        42,chapter:        20,versenumber:        41,verse:"耶稣对他们说：“人怎么说基督是大卫的子孙呢？"},
{volumn:        42,chapter:        20,versenumber:        42,verse:"诗篇上大卫自己说：‘主对我主说：你坐在我的右边，"},
{volumn:        42,chapter:        20,versenumber:        43,verse:"等我使你仇敌作你的脚凳。’"},
{volumn:        42,chapter:        20,versenumber:        44,verse:"大卫既称他为主，他怎么又是大卫的子孙呢？”"},
{volumn:        42,chapter:        20,versenumber:        45,verse:"众百姓听的时候，耶稣对门徒说："},
{volumn:        42,chapter:        20,versenumber:        46,verse:"“你们要防备文士。他们好穿长衣游行，喜爱人在街市上问他们安，又喜爱会堂里的高位，筵席上的首座；"},
{volumn:        42,chapter:        20,versenumber:        47,verse:"他们侵吞寡妇的家产，假意作很长的祷告。这些人要受更重的刑罚！”"},
{volumn:        42,chapter:        21,versenumber:         1,verse:"耶稣抬头观看，见财主把捐项投在库里，"},
{volumn:        42,chapter:        21,versenumber:         2,verse:"又见一个穷寡妇投了两个小钱，"},
{volumn:        42,chapter:        21,versenumber:         3,verse:"就说：“我实在告诉你们，这穷寡妇所投的比众人还多；"},
{volumn:        42,chapter:        21,versenumber:         4,verse:"因为众人都是自己有余，拿出来投在捐项里，但这寡妇是自己不足，把她一切养生的都投上了。”"},
{volumn:        42,chapter:        21,versenumber:         5,verse:"有人谈论圣殿是用美石和供物妆饰的；"},
{volumn:        42,chapter:        21,versenumber:         6,verse:"耶稣就说：“论到你们所看见的这一切，将来日子到了，在这里没有一块石头留在石头上，不被拆毁了。”"},
{volumn:        42,chapter:        21,versenumber:         7,verse:"他们问他说：“夫子！什么时候有这事呢？这事将到的时候有什么预兆呢？”"},
{volumn:        42,chapter:        21,versenumber:         8,verse:"耶稣说：“你们要谨慎，不要受迷惑；因为将来有好些人冒我的名来，说：‘我是基督’，又说：‘时候近了’，你们不要跟从他们！"},
{volumn:        42,chapter:        21,versenumber:         9,verse:"你们听见打仗和扰乱的事，不要惊惶；因为这些事必须先有，只是末期不能立时就到。”"},
{volumn:        42,chapter:        21,versenumber:        10,verse:"当时，耶稣对他们说：“民要攻打民，国要攻打国；"},
{volumn:        42,chapter:        21,versenumber:        11,verse:"地要大大震动，多处必有饥荒、瘟疫，又有可怕的异象和大神迹从天上显现。"},
{volumn:        42,chapter:        21,versenumber:        12,verse:"但这一切的事以先，人要下手拿住你们，逼迫你们，把你们交给会堂，并且收在监里，又为我的名拉你们到君王诸侯面前。"},
{volumn:        42,chapter:        21,versenumber:        13,verse:"但这些事终必为你们的见证。"},
{volumn:        42,chapter:        21,versenumber:        14,verse:"所以，你们当立定心意，不要预先思想怎样分诉；"},
{volumn:        42,chapter:        21,versenumber:        15,verse:"因为我必赐你们口才、智慧，是你们一切敌人所敌不住、驳不倒的。"},
{volumn:        42,chapter:        21,versenumber:        16,verse:"连你们的父母、弟兄、亲族、朋友也要把你们交官；你们也有被他们害死的。"},
{volumn:        42,chapter:        21,versenumber:        17,verse:"你们要为我的名被众人恨恶，"},
{volumn:        42,chapter:        21,versenumber:        18,verse:"然而，你们连一根头发也必不损坏。"},
{volumn:        42,chapter:        21,versenumber:        19,verse:"你们常存忍耐，就必保全灵魂（或译：必得生命）。”"},
{volumn:        42,chapter:        21,versenumber:        20,verse:"“你们看见耶路撒冷被兵围困，就可知道它成荒场的日子近了。"},
{volumn:        42,chapter:        21,versenumber:        21,verse:"那时，在犹太的应当逃到山上；在城里的应当出来；在乡下的不要进城；"},
{volumn:        42,chapter:        21,versenumber:        22,verse:"因为这是报应的日子，使经上所写的都得应验。"},
{volumn:        42,chapter:        21,versenumber:        23,verse:"当那些日子，怀孕的和奶孩子的有祸了！因为将有大灾难降在这地方，也有震怒临到这百姓。"},
{volumn:        42,chapter:        21,versenumber:        24,verse:"他们要倒在刀下，又被掳到各国去。耶路撒冷要被外邦人践踏，直到外邦人的日期满了。”"},
{volumn:        42,chapter:        21,versenumber:        25,verse:"“日、月、星辰要显出异兆，地上的邦国也有困苦；因海中波浪的响声，就慌慌不定。"},
{volumn:        42,chapter:        21,versenumber:        26,verse:"天势都要震动，人想起那将要临到世界的事，就都吓得魂不附体。"},
{volumn:        42,chapter:        21,versenumber:        27,verse:"那时，他们要看见人子有能力，有大荣耀驾云降临。"},
{volumn:        42,chapter:        21,versenumber:        28,verse:"一有这些事，你们就当挺身昂首，因为你们得赎的日子近了。”"},
{volumn:        42,chapter:        21,versenumber:        29,verse:"耶稣又设比喻对他们说：“你们看无花果树和各样的树；它发芽的时候，你们一看见，自然晓得夏天近了。"},
{volumn:        42,chapter:        21,versenumber:        30,verse:""},
{volumn:        42,chapter:        21,versenumber:        31,verse:"这样，你们看见这些事渐渐地成就，也该晓得　神的国近了。"},
{volumn:        42,chapter:        21,versenumber:        32,verse:"我实在告诉你们，这世代还没有过去，这些事都要成就。"},
{volumn:        42,chapter:        21,versenumber:        33,verse:"天地要废去，我的话却不能废去。”"},
{volumn:        42,chapter:        21,versenumber:        34,verse:"“你们要谨慎，恐怕因贪食、醉酒，并今生的思虑累住你们的心，那日子就如同网罗忽然临到你们；"},
{volumn:        42,chapter:        21,versenumber:        35,verse:"因为那日子要这样临到全地上一切居住的人。"},
{volumn:        42,chapter:        21,versenumber:        36,verse:"你们要时时警醒，常常祈求，使你们能逃避这一切要来的事，得以站立在人子面前。”"},
{volumn:        42,chapter:        21,versenumber:        37,verse:"耶稣每日在殿里教训人，每夜出城在一座山，名叫橄榄山住宿。"},
{volumn:        42,chapter:        21,versenumber:        38,verse:"众百姓清早上圣殿，到耶稣那里，要听他讲道。"},
{volumn:        42,chapter:        22,versenumber:         1,verse:"除酵节（又名逾越节）近了。"},
{volumn:        42,chapter:        22,versenumber:         2,verse:"祭司长和文士想法子怎么才能杀害耶稣，是因他们惧怕百姓。"},
{volumn:        42,chapter:        22,versenumber:         3,verse:"这时，撒但入了那称为加略人犹大的心；他本是十二门徒里的一个。"},
{volumn:        42,chapter:        22,versenumber:         4,verse:"他去和祭司长并守殿官商量，怎么可以把耶稣交给他们。"},
{volumn:        42,chapter:        22,versenumber:         5,verse:"他们欢喜，就约定给他银子。"},
{volumn:        42,chapter:        22,versenumber:         6,verse:"他应允了，就找机会，要趁众人不在跟前的时候把耶稣交给他们。"},
{volumn:        42,chapter:        22,versenumber:         7,verse:"除酵节，须宰逾越羊羔的那一天到了。"},
{volumn:        42,chapter:        22,versenumber:         8,verse:"耶稣打发彼得、约翰，说：“你们去为我们预备逾越节的筵席，好叫我们吃。”"},
{volumn:        42,chapter:        22,versenumber:         9,verse:"他们问他说：“要我们在哪里预备？”"},
{volumn:        42,chapter:        22,versenumber:        10,verse:"耶稣说：“你们进了城，必有人拿着一瓶水迎面而来，你们就跟着他，到他所进的房子里去，"},
{volumn:        42,chapter:        22,versenumber:        11,verse:"对那家的主人说：‘夫子说：客房在哪里？我与门徒好在那里吃逾越节的筵席。’"},
{volumn:        42,chapter:        22,versenumber:        12,verse:"他必指给你们摆设整齐的一间大楼，你们就在那里预备。”"},
{volumn:        42,chapter:        22,versenumber:        13,verse:"他们去了，所遇见的正如耶稣所说的；他们就预备了逾越节的筵席。"},
{volumn:        42,chapter:        22,versenumber:        14,verse:"时候到了，耶稣坐席，使徒也和他同坐。"},
{volumn:        42,chapter:        22,versenumber:        15,verse:"耶稣对他们说：“我很愿意在受害以先和你们吃这逾越节的筵席。"},
{volumn:        42,chapter:        22,versenumber:        16,verse:"我告诉你们，我不再吃这筵席，直到成就在　神的国里。”"},
{volumn:        42,chapter:        22,versenumber:        17,verse:"耶稣接过杯来，祝谢了，说：“你们拿这个，大家分着喝。"},
{volumn:        42,chapter:        22,versenumber:        18,verse:"我告诉你们，从今以后，我不再喝这葡萄汁，直等　神的国来到。”"},
{volumn:        42,chapter:        22,versenumber:        19,verse:"又拿起饼来，祝谢了，就擘开，递给他们，说：“这是我的身体，为你们舍的，你们也应当如此行，为的是记念我。”"},
{volumn:        42,chapter:        22,versenumber:        20,verse:"饭后也照样拿起杯来，说：“这杯是用我血所立的新约，是为你们流出来的。"},
{volumn:        42,chapter:        22,versenumber:        21,verse:"看哪，那卖我之人的手与我一同在桌子上。"},
{volumn:        42,chapter:        22,versenumber:        22,verse:"人子固然要照所预定的去世，但卖人子的人有祸了！”"},
{volumn:        42,chapter:        22,versenumber:        23,verse:"他们就彼此对问，是哪一个要做这事。"},
{volumn:        42,chapter:        22,versenumber:        24,verse:"门徒起了争论，他们中间哪一个可算为大。"},
{volumn:        42,chapter:        22,versenumber:        25,verse:"耶稣说：“外邦人有君王为主治理他们，那掌权管他们的称为恩主。"},
{volumn:        42,chapter:        22,versenumber:        26,verse:"但你们不可这样；你们里头为大的，倒要像年幼的，为首领的，倒要像服侍人的。"},
{volumn:        42,chapter:        22,versenumber:        27,verse:"是谁为大？是坐席的呢？是服侍人的呢？不是坐席的大吗？然而，我在你们中间如同服侍人的。"},
{volumn:        42,chapter:        22,versenumber:        28,verse:"我在磨炼之中，常和我同在的就是你们。"},
{volumn:        42,chapter:        22,versenumber:        29,verse:"我将国赐给你们，正如我父赐给我一样，"},
{volumn:        42,chapter:        22,versenumber:        30,verse:"叫你们在我国里，坐在我的席上吃喝，并且坐在宝座上，审判以色列十二个支派。”"},
{volumn:        42,chapter:        22,versenumber:        31,verse:"主又说：“西门！西门！撒但想要得着你们，好筛你们像筛麦子一样；"},
{volumn:        42,chapter:        22,versenumber:        32,verse:"但我已经为你祈求，叫你不至于失了信心。你回头以后，要坚固你的弟兄。”"},
{volumn:        42,chapter:        22,versenumber:        33,verse:"彼得说：“主啊，我就是同你下监，同你受死，也是甘心！”"},
{volumn:        42,chapter:        22,versenumber:        34,verse:"耶稣说：“彼得，我告诉你，今日鸡还没有叫，你要三次说不认得我。”"},
{volumn:        42,chapter:        22,versenumber:        35,verse:"耶稣又对他们说：“我差你们出去的时候，没有钱囊，没有口袋，没有鞋，你们缺少什么没有？”他们说：“没有。”"},
{volumn:        42,chapter:        22,versenumber:        36,verse:"耶稣说：“但如今有钱囊的可以带着，有口袋的也可以带着，没有刀的要卖衣服买刀。"},
{volumn:        42,chapter:        22,versenumber:        37,verse:"我告诉你们，经上写着说：‘他被列在罪犯之中。’这话必应验在我身上，因为那关系我的事必然成就。”"},
{volumn:        42,chapter:        22,versenumber:        38,verse:"他们说：“主啊，请看！这里有两把刀。”耶稣说：“够了。”"},
{volumn:        42,chapter:        22,versenumber:        39,verse:"耶稣出来，照常往橄榄山去，门徒也跟随他。"},
{volumn:        42,chapter:        22,versenumber:        40,verse:"到了那地方，就对他们说：“你们要祷告，免得入了迷惑。”"},
{volumn:        42,chapter:        22,versenumber:        41,verse:"于是离开他们约有扔一块石头那么远，跪下祷告，"},
{volumn:        42,chapter:        22,versenumber:        42,verse:"说：“父啊！你若愿意，就把这杯撤去；然而，不要成就我的意思，只要成就你的意思。”"},
{volumn:        42,chapter:        22,versenumber:        43,verse:"有一位天使从天上显现，加添他的力量。"},
{volumn:        42,chapter:        22,versenumber:        44,verse:"耶稣极其伤痛，祷告更加恳切，汗珠如大血点滴在地上。"},
{volumn:        42,chapter:        22,versenumber:        45,verse:"祷告完了，就起来，到门徒那里，见他们因为忧愁都睡着了，"},
{volumn:        42,chapter:        22,versenumber:        46,verse:"就对他们说：“你们为什么睡觉呢？起来祷告，免得入了迷惑！”"},
{volumn:        42,chapter:        22,versenumber:        47,verse:"说话之间，来了许多人。那十二个门徒里名叫犹大的，走在前头，就近耶稣，要与他亲嘴。"},
{volumn:        42,chapter:        22,versenumber:        48,verse:"耶稣对他说：“犹大！你用亲嘴的暗号卖人子吗？”"},
{volumn:        42,chapter:        22,versenumber:        49,verse:"左右的人见光景不好，就说：“主啊！我们拿刀砍可以不可以？”"},
{volumn:        42,chapter:        22,versenumber:        50,verse:"内中有一个人把大祭司的仆人砍了一刀，削掉了他的右耳。"},
{volumn:        42,chapter:        22,versenumber:        51,verse:"耶稣说：“到了这个地步，由他们吧！”就摸那人的耳朵，把他治好了。"},
{volumn:        42,chapter:        22,versenumber:        52,verse:"耶稣对那些来拿他的祭司长和守殿官并长老说：“你们带着刀棒出来拿我，如同拿强盗吗？"},
{volumn:        42,chapter:        22,versenumber:        53,verse:"我天天同你们在殿里，你们不下手拿我。现在却是你们的时候，黑暗掌权了。”"},
{volumn:        42,chapter:        22,versenumber:        54,verse:"他们拿住耶稣，把他带到大祭司的宅里。彼得远远地跟着。"},
{volumn:        42,chapter:        22,versenumber:        55,verse:"他们在院子里生了火，一同坐着；彼得也坐在他们中间。"},
{volumn:        42,chapter:        22,versenumber:        56,verse:"有一个使女看见彼得坐在火光里，就定睛看他，说：“这个人素来也是同那人一伙的。”"},
{volumn:        42,chapter:        22,versenumber:        57,verse:"彼得却不承认，说：“女子，我不认得他。”"},
{volumn:        42,chapter:        22,versenumber:        58,verse:"过了不多的时候，又有一个人看见他，说：“你也是他们一党的。”彼得说：“你这个人！我不是。”"},
{volumn:        42,chapter:        22,versenumber:        59,verse:"约过了一小时，又有一个人极力地说：“他实在是同那人一伙的，因为他也是加利利人。”"},
{volumn:        42,chapter:        22,versenumber:        60,verse:"彼得说：“你这个人！我不晓得你说的是什么！”正说话之间，鸡就叫了。"},
{volumn:        42,chapter:        22,versenumber:        61,verse:"主转过身来看彼得，彼得便想起主对他所说的话：“今日鸡叫以先，你要三次不认我。”"},
{volumn:        42,chapter:        22,versenumber:        62,verse:"他就出去痛哭。"},
{volumn:        42,chapter:        22,versenumber:        63,verse:"看守耶稣的人戏弄他，打他，"},
{volumn:        42,chapter:        22,versenumber:        64,verse:"又蒙着他的眼，问他说：“你是先知，告诉我们打你的是谁？”"},
{volumn:        42,chapter:        22,versenumber:        65,verse:"他们还用许多别的话辱骂他。"},
{volumn:        42,chapter:        22,versenumber:        66,verse:"天一亮，民间的众长老连祭司长带文士都聚会，把耶稣带到他们的公会里，"},
{volumn:        42,chapter:        22,versenumber:        67,verse:"说：“你若是基督，就告诉我们。”耶稣说：“我若告诉你们，你们也不信；"},
{volumn:        42,chapter:        22,versenumber:        68,verse:"我若问你们，你们也不回答。"},
{volumn:        42,chapter:        22,versenumber:        69,verse:"从今以后，人子要坐在　神权能的右边。”"},
{volumn:        42,chapter:        22,versenumber:        70,verse:"他们都说：“这样，你是　神的儿子吗？”耶稣说：“你们所说的是。”"},
{volumn:        42,chapter:        22,versenumber:        71,verse:"他们说：“何必再用见证呢？他亲口所说的，我们都亲自听见了。”"},
{volumn:        42,chapter:        23,versenumber:         1,verse:"众人都起来，把耶稣解到彼拉多面前，"},
{volumn:        42,chapter:        23,versenumber:         2,verse:"就告他说：“我们见这人诱惑国民，禁止纳税给凯撒，并说自己是基督，是王。”"},
{volumn:        42,chapter:        23,versenumber:         3,verse:"彼拉多问耶稣说：“你是犹太人的王吗？”耶稣回答说：“你说的是。”"},
{volumn:        42,chapter:        23,versenumber:         4,verse:"彼拉多对祭司长和众人说：“我查不出这人有什么罪来。”"},
{volumn:        42,chapter:        23,versenumber:         5,verse:"但他们越发极力地说：“他煽惑百姓，在犹太遍地传道，从加利利起，直到这里了。”"},
{volumn:        42,chapter:        23,versenumber:         6,verse:"彼拉多一听见，就问：“这人是加利利人吗？”"},
{volumn:        42,chapter:        23,versenumber:         7,verse:"既晓得耶稣属希律所管，就把他送到希律那里去。那时希律正在耶路撒冷。"},
{volumn:        42,chapter:        23,versenumber:         8,verse:"希律看见耶稣，就很欢喜；因为听见过他的事，久已想要见他，并且指望看他行一件神迹，"},
{volumn:        42,chapter:        23,versenumber:         9,verse:"于是问他许多的话，耶稣却一言不答。"},
{volumn:        42,chapter:        23,versenumber:        10,verse:"祭司长和文士都站着，极力地告他。"},
{volumn:        42,chapter:        23,versenumber:        11,verse:"希律和他的兵丁就藐视耶稣，戏弄他，给他穿上华丽衣服，把他送回彼拉多那里去。"},
{volumn:        42,chapter:        23,versenumber:        12,verse:"从前希律和彼拉多彼此有仇，在那一天就成了朋友。"},
{volumn:        42,chapter:        23,versenumber:        13,verse:"彼拉多传齐了祭司长和官府并百姓，"},
{volumn:        42,chapter:        23,versenumber:        14,verse:"就对他们说：“你们解这人到我这里，说他是诱惑百姓的。看哪，我也曾将你们告他的事，在你们面前审问他，并没有查出他什么罪来；"},
{volumn:        42,chapter:        23,versenumber:        15,verse:"就是希律也是如此，所以把他送回来。可见他没有做什么该死的事。"},
{volumn:        42,chapter:        23,versenumber:        16,verse:"故此，我要责打他，把他释放了。”（有古卷在此有：每逢这节期，巡抚必须释放一个囚犯给他们。）"},
{volumn:        42,chapter:        23,versenumber:        17,verse:""},
{volumn:        42,chapter:        23,versenumber:        18,verse:"众人却一齐喊着说：“除掉这个人！释放巴拉巴给我们！”"},
{volumn:        42,chapter:        23,versenumber:        19,verse:"这巴拉巴是因在城里作乱杀人，下在监里的。"},
{volumn:        42,chapter:        23,versenumber:        20,verse:"彼拉多愿意释放耶稣，就又劝解他们。"},
{volumn:        42,chapter:        23,versenumber:        21,verse:"无奈他们喊着说：“钉他十字架！钉他十字架！”"},
{volumn:        42,chapter:        23,versenumber:        22,verse:"彼拉多第三次对他们说：“为什么呢？这人做了什么恶事呢？我并没有查出他什么该死的罪来。所以，我要责打他，把他释放了。”"},
{volumn:        42,chapter:        23,versenumber:        23,verse:"他们大声催逼彼拉多，求他把耶稣钉在十字架上。他们的声音就得了胜。"},
{volumn:        42,chapter:        23,versenumber:        24,verse:"彼拉多这才照他们所求的定案，"},
{volumn:        42,chapter:        23,versenumber:        25,verse:"把他们所求的那作乱杀人、下在监里的释放了，把耶稣交给他们，任凭他们的意思行。"},
{volumn:        42,chapter:        23,versenumber:        26,verse:"带耶稣去的时候，有一个古利奈人西门，从乡下来；他们就抓住他，把十字架搁在他身上，叫他背着跟随耶稣。"},
{volumn:        42,chapter:        23,versenumber:        27,verse:"有许多百姓跟随耶稣，内中有好些妇女；妇女们为他号啕痛哭。"},
{volumn:        42,chapter:        23,versenumber:        28,verse:"耶稣转身对她们说：“耶路撒冷的女子，不要为我哭，当为自己和自己的儿女哭。"},
{volumn:        42,chapter:        23,versenumber:        29,verse:"因为日子要到，人必说：‘不生育的，和未曾怀胎的，未曾乳养婴孩的，有福了！’"},
{volumn:        42,chapter:        23,versenumber:        30,verse:"那时，人要向大山说：‘倒在我们身上！’向小山说：‘遮盖我们！’"},
{volumn:        42,chapter:        23,versenumber:        31,verse:"“这些事既行在有汁水的树上，那枯干的树将来怎么样呢？”"},
{volumn:        42,chapter:        23,versenumber:        32,verse:"又有两个犯人，和耶稣一同带来处死。"},
{volumn:        42,chapter:        23,versenumber:        33,verse:"到了一个地方，名叫“髑髅地”，就在那里把耶稣钉在十字架上，又钉了两个犯人：一个在左边，一个在右边。"},
{volumn:        42,chapter:        23,versenumber:        34,verse:"当下耶稣说：“父啊！赦免他们；因为他们所做的，他们不晓得。”兵丁就拈阄分他的衣服。"},
{volumn:        42,chapter:        23,versenumber:        35,verse:"百姓站在那里观看。官府也嗤笑他，说：“他救了别人；他若是基督，　神所拣选的，可以救自己吧！”"},
{volumn:        42,chapter:        23,versenumber:        36,verse:"兵丁也戏弄他，上前拿醋送给他喝，"},
{volumn:        42,chapter:        23,versenumber:        37,verse:"说：“你若是犹太人的王，可以救自己吧！”"},
{volumn:        42,chapter:        23,versenumber:        38,verse:"在耶稣以上有一个牌子（有古卷加：用希腊、罗马、希伯来的文字）写着：“这是犹太人的王。”"},
{volumn:        42,chapter:        23,versenumber:        39,verse:"那同钉的两个犯人有一个讥笑他，说：“你不是基督吗？可以救自己和我们吧！”"},
{volumn:        42,chapter:        23,versenumber:        40,verse:"那一个就应声责备他，说：“你既是一样受刑的，还不怕　神吗？"},
{volumn:        42,chapter:        23,versenumber:        41,verse:"我们是应该的，因我们所受的与我们所做的相称，但这个人没有做过一件不好的事。”"},
{volumn:        42,chapter:        23,versenumber:        42,verse:"就说：“耶稣啊，你得国降临的时候，求你记念我！”"},
{volumn:        42,chapter:        23,versenumber:        43,verse:"耶稣对他说：“我实在告诉你，今日你要同我在乐园里了。”"},
{volumn:        42,chapter:        23,versenumber:        44,verse:"那时约有午正，遍地都黑暗了，直到申初，"},
{volumn:        42,chapter:        23,versenumber:        45,verse:"日头变黑了；殿里的幔子从当中裂为两半。"},
{volumn:        42,chapter:        23,versenumber:        46,verse:"耶稣大声喊着说：“父啊！我将我的灵魂交在你手里。”说了这话，气就断了。"},
{volumn:        42,chapter:        23,versenumber:        47,verse:"百夫长看见所成的事，就归荣耀与　神，说：“这真是个义人！”"},
{volumn:        42,chapter:        23,versenumber:        48,verse:"聚集观看的众人见了这所成的事都捶着胸回去了。"},
{volumn:        42,chapter:        23,versenumber:        49,verse:"还有一切与耶稣熟识的人，和从加利利跟着他来的妇女们，都远远地站着看这些事。"},
{volumn:        42,chapter:        23,versenumber:        50,verse:"有一个人名叫约瑟，是个议士，为人善良公义；"},
{volumn:        42,chapter:        23,versenumber:        51,verse:"众人所谋所为，他并没有附从。他本是犹太、亚利马太城里素常盼望　神国的人。"},
{volumn:        42,chapter:        23,versenumber:        52,verse:"这人去见彼拉多，求耶稣的身体，"},
{volumn:        42,chapter:        23,versenumber:        53,verse:"就取下来，用细麻布裹好，安放在石头凿成的坟墓里；那里头从来没有葬过人。"},
{volumn:        42,chapter:        23,versenumber:        54,verse:"那日是预备日，安息日也快到了。"},
{volumn:        42,chapter:        23,versenumber:        55,verse:"那些从加利利和耶稣同来的妇女跟在后面，看见了坟墓和他的身体怎样安放。"},
{volumn:        42,chapter:        23,versenumber:        56,verse:"她们就回去，预备了香料香膏。她们在安息日，便遵着诫命安息了。"},
{volumn:        42,chapter:        24,versenumber:         1,verse:"七日的头一日，黎明的时候，那些妇女带着所预备的香料来到坟墓前，"},
{volumn:        42,chapter:        24,versenumber:         2,verse:"看见石头已经从坟墓滚开了，"},
{volumn:        42,chapter:        24,versenumber:         3,verse:"她们就进去，只是不见主耶稣的身体。"},
{volumn:        42,chapter:        24,versenumber:         4,verse:"正在猜疑之间，忽然有两个人站在旁边，衣服放光。"},
{volumn:        42,chapter:        24,versenumber:         5,verse:"妇女们惊怕，将脸伏地。那两个人就对她们说：“为什么在死人中找活人呢？"},
{volumn:        42,chapter:        24,versenumber:         6,verse:"他不在这里，已经复活了。当记念他还在加利利的时候怎样告诉你们，"},
{volumn:        42,chapter:        24,versenumber:         7,verse:"说：‘人子必须被交在罪人手里，钉在十字架上，第三日复活。’”"},
{volumn:        42,chapter:        24,versenumber:         8,verse:"她们就想起耶稣的话来，"},
{volumn:        42,chapter:        24,versenumber:         9,verse:"便从坟墓那里回去，把这一切的事告诉十一个使徒和其余的人。"},
{volumn:        42,chapter:        24,versenumber:        10,verse:"那告诉使徒的就是抹大拉的马利亚和约亚拿，并雅各的母亲马利亚，还有与她们在一处的妇女。"},
{volumn:        42,chapter:        24,versenumber:        11,verse:"她们这些话，使徒以为是胡言，就不相信。"},
{volumn:        42,chapter:        24,versenumber:        12,verse:"彼得起来，跑到坟墓前，低头往里看，见细麻布独在一处，就回去了，心里希奇所成的事。"},
{volumn:        42,chapter:        24,versenumber:        13,verse:"正当那日，门徒中有两个人往一个村子去；这村子名叫以马忤斯，离耶路撒冷约有二十五里。"},
{volumn:        42,chapter:        24,versenumber:        14,verse:"他们彼此谈论所遇见的这一切事。"},
{volumn:        42,chapter:        24,versenumber:        15,verse:"正谈论相问的时候，耶稣亲自就近他们，和他们同行；"},
{volumn:        42,chapter:        24,versenumber:        16,verse:"只是他们的眼睛迷糊了，不认识他。"},
{volumn:        42,chapter:        24,versenumber:        17,verse:"耶稣对他们说：“你们走路彼此谈论的是什么事呢？”他们就站住，脸上带着愁容。"},
{volumn:        42,chapter:        24,versenumber:        18,verse:"二人中有一个名叫革流巴的回答说：“你在耶路撒冷作客，还不知道这几天在那里所出的事吗？”"},
{volumn:        42,chapter:        24,versenumber:        19,verse:"耶稣说：“什么事呢？”他们说：“就是拿撒勒人耶稣的事。他是个先知，在　神和众百姓面前，说话行事都有大能。"},
{volumn:        42,chapter:        24,versenumber:        20,verse:"祭司长和我们的官府竟把他解去，定了死罪，钉在十字架上。"},
{volumn:        42,chapter:        24,versenumber:        21,verse:"但我们素来所盼望、要赎以色列民的就是他！不但如此，而且这事成就，现在已经三天了。"},
{volumn:        42,chapter:        24,versenumber:        22,verse:"再者，我们中间有几个妇女使我们惊奇；她们清早到了坟墓那里，"},
{volumn:        42,chapter:        24,versenumber:        23,verse:"不见他的身体，就回来告诉我们，说看见了天使显现，说他活了。"},
{volumn:        42,chapter:        24,versenumber:        24,verse:"又有我们的几个人往坟墓那里去，所遇见的正如妇女们所说的，只是没有看见他。”"},
{volumn:        42,chapter:        24,versenumber:        25,verse:"耶稣对他们说：“无知的人哪，先知所说的一切话，你们的心信得太迟钝了。"},
{volumn:        42,chapter:        24,versenumber:        26,verse:"基督这样受害，又进入他的荣耀，岂不是应当的吗？”"},
{volumn:        42,chapter:        24,versenumber:        27,verse:"于是从摩西和众先知起，凡经上所指着自己的话都给他们讲解明白了。"},
{volumn:        42,chapter:        24,versenumber:        28,verse:"将近他们所去的村子，耶稣好像还要往前行，"},
{volumn:        42,chapter:        24,versenumber:        29,verse:"他们却强留他，说：“时候晚了，日头已经平西了，请你同我们住下吧！”耶稣就进去，要同他们住下。"},
{volumn:        42,chapter:        24,versenumber:        30,verse:"到了坐席的时候，耶稣拿起饼来，祝谢了，擘开，递给他们。"},
{volumn:        42,chapter:        24,versenumber:        31,verse:"他们的眼睛明亮了，这才认出他来。忽然耶稣不见了。"},
{volumn:        42,chapter:        24,versenumber:        32,verse:"他们彼此说：“在路上，他和我们说话，给我们讲解圣经的时候，我们的心岂不是火热的吗？”"},
{volumn:        42,chapter:        24,versenumber:        33,verse:"他们就立时起身，回耶路撒冷去，正遇见十一个使徒和他们的同人聚集在一处，"},
{volumn:        42,chapter:        24,versenumber:        34,verse:"说：“主果然复活，已经现给西门看了。”"},
{volumn:        42,chapter:        24,versenumber:        35,verse:"两个人就把路上所遇见，和擘饼的时候怎么被他们认出来的事，都述说了一遍。"},
{volumn:        42,chapter:        24,versenumber:        36,verse:"正说这话的时候，耶稣亲自站在他们当中，说：“愿你们平安！”"},
{volumn:        42,chapter:        24,versenumber:        37,verse:"他们却惊慌害怕，以为所看见的是魂。"},
{volumn:        42,chapter:        24,versenumber:        38,verse:"耶稣说：“你们为什么愁烦？为什么心里起疑念呢？"},
{volumn:        42,chapter:        24,versenumber:        39,verse:"你们看我的手，我的脚，就知道实在是我了。摸我看看！魂无骨无肉，你们看，我是有的。”"},
{volumn:        42,chapter:        24,versenumber:        40,verse:"说了这话，就把手和脚给他们看。"},
{volumn:        42,chapter:        24,versenumber:        41,verse:"他们正喜得不敢信，并且希奇；耶稣就说：“你们这里有什么吃的没有？”"},
{volumn:        42,chapter:        24,versenumber:        42,verse:"他们便给他一片烧鱼。（有古卷加：和一块蜜房。）"},
{volumn:        42,chapter:        24,versenumber:        43,verse:"他接过来，在他们面前吃了。"},
{volumn:        42,chapter:        24,versenumber:        44,verse:"耶稣对他们说：“这就是我从前与你们同在之时所告诉你们的话说：摩西的律法、先知的书，和诗篇上所记的，凡指着我的话都必须应验。”"},
{volumn:        42,chapter:        24,versenumber:        45,verse:"于是耶稣开他们的心窍，使他们能明白圣经，"},
{volumn:        42,chapter:        24,versenumber:        46,verse:"又对他们说：“照经上所写的，基督必受害，第三日从死里复活，"},
{volumn:        42,chapter:        24,versenumber:        47,verse:"并且人要奉他的名传悔改、赦罪的道，从耶路撒冷起直传到万邦。"},
{volumn:        42,chapter:        24,versenumber:        48,verse:"你们就是这些事的见证。"},
{volumn:        42,chapter:        24,versenumber:        49,verse:"我要将我父所应许的降在你们身上，你们要在城里等候，直到你们领受从上头来的能力。”"},
{volumn:        42,chapter:        24,versenumber:        50,verse:"耶稣领他们到伯大尼的对面，就举手给他们祝福。"},
{volumn:        42,chapter:        24,versenumber:        51,verse:"正祝福的时候，他就离开他们，被带到天上去了。"},
{volumn:        42,chapter:        24,versenumber:        52,verse:"他们就拜他，大大地欢喜，回耶路撒冷去，"},
{volumn:        42,chapter:        24,versenumber:        53,verse:"常在殿里称颂　神。"},
{volumn:        43,chapter:         1,versenumber:         1,verse:"太初有道，道与　神同在，道就是　神。"},
{volumn:        43,chapter:         1,versenumber:         2,verse:"这道太初与　神同在。"},
{volumn:        43,chapter:         1,versenumber:         3,verse:"万物是藉着他造的；凡被造的，没有一样不是藉着他造的。"},
{volumn:        43,chapter:         1,versenumber:         4,verse:"生命在他里头，这生命就是人的光。"},
{volumn:        43,chapter:         1,versenumber:         5,verse:"光照在黑暗里，黑暗却不接受光。"},
{volumn:        43,chapter:         1,versenumber:         6,verse:"有一个人，是从　神那里差来的，名叫约翰。"},
{volumn:        43,chapter:         1,versenumber:         7,verse:"这人来，为要作见证，就是为光作见证，叫众人因他可以信。"},
{volumn:        43,chapter:         1,versenumber:         8,verse:"他不是那光，乃是要为光作见证。"},
{volumn:        43,chapter:         1,versenumber:         9,verse:"那光是真光，照亮一切生在世上的人。"},
{volumn:        43,chapter:         1,versenumber:        10,verse:"他在世界，世界也是藉着他造的，世界却不认识他。"},
{volumn:        43,chapter:         1,versenumber:        11,verse:"他到自己的地方来，自己的人倒不接待他。"},
{volumn:        43,chapter:         1,versenumber:        12,verse:"凡接待他的，就是信他名的人，他就赐他们权柄作　神的儿女。"},
{volumn:        43,chapter:         1,versenumber:        13,verse:"这等人不是从血气生的，不是从情欲生的，也不是从人意生的，乃是从　神生的。"},
{volumn:        43,chapter:         1,versenumber:        14,verse:"道成了肉身，住在我们中间，充充满满地有恩典有真理。我们也见过他的荣光，正是父独生子的荣光。"},
{volumn:        43,chapter:         1,versenumber:        15,verse:"约翰为他作见证，喊着说：“这就是我曾说：‘那在我以后来的，反成了在我以前的，因他本来在我以前。’”"},
{volumn:        43,chapter:         1,versenumber:        16,verse:"从他丰满的恩典里，我们都领受了，而且恩上加恩。"},
{volumn:        43,chapter:         1,versenumber:        17,verse:"律法本是藉着摩西传的；恩典和真理都是由耶稣基督来的。"},
{volumn:        43,chapter:         1,versenumber:        18,verse:"从来没有人看见　神，只有在父怀里的独生子将他表明出来。"},
{volumn:        43,chapter:         1,versenumber:        19,verse:"约翰所作的见证记在下面：犹太人从耶路撒冷差祭司和利未人到约翰那里，问他说：“你是谁？”"},
{volumn:        43,chapter:         1,versenumber:        20,verse:"他就明说，并不隐瞒，明说：“我不是基督。”"},
{volumn:        43,chapter:         1,versenumber:        21,verse:"他们又问他说：“这样，你是谁呢？是以利亚吗？”他说：“我不是。”“是那先知吗？”他回答说：“不是。”"},
{volumn:        43,chapter:         1,versenumber:        22,verse:"于是他们说：“你到底是谁，叫我们好回复差我们来的人。你自己说，你是谁？”"},
{volumn:        43,chapter:         1,versenumber:        23,verse:"他说：“我就是那在旷野有人声喊着说：‘修直主的道路’，正如先知以赛亚所说的。”"},
{volumn:        43,chapter:         1,versenumber:        24,verse:"那些人是法利赛人差来的（或译：那差来的是法利赛人）；"},
{volumn:        43,chapter:         1,versenumber:        25,verse:"他们就问他说：“你既不是基督，不是以利亚，也不是那先知，为什么施洗呢？”"},
{volumn:        43,chapter:         1,versenumber:        26,verse:"约翰回答说：“我是用水施洗，但有一位站在你们中间，是你们不认识的，"},
{volumn:        43,chapter:         1,versenumber:        27,verse:"就是那在我以后来的，我给他解鞋带也不配。”"},
{volumn:        43,chapter:         1,versenumber:        28,verse:"这是在约旦河外伯大尼（有古卷：伯大巴喇），约翰施洗的地方作的见证。"},
{volumn:        43,chapter:         1,versenumber:        29,verse:"次日，约翰看见耶稣来到他那里，就说：“看哪，　神的羔羊，除去（或译：背负）世人罪孽的！"},
{volumn:        43,chapter:         1,versenumber:        30,verse:"这就是我曾说：‘有一位在我以后来、反成了在我以前的，因他本来在我以前。’"},
{volumn:        43,chapter:         1,versenumber:        31,verse:"我先前不认识他，如今我来用水施洗，为要叫他显明给以色列人。”"},
{volumn:        43,chapter:         1,versenumber:        32,verse:"约翰又作见证说：“我曾看见圣灵，仿佛鸽子从天降下，住在他的身上。"},
{volumn:        43,chapter:         1,versenumber:        33,verse:"我先前不认识他，只是那差我来用水施洗的、对我说：‘你看见圣灵降下来，住在谁的身上，谁就是用圣灵施洗的。’"},
{volumn:        43,chapter:         1,versenumber:        34,verse:"我看见了，就证明这是　神的儿子。”"},
{volumn:        43,chapter:         1,versenumber:        35,verse:"再次日，约翰同两个门徒站在那里。"},
{volumn:        43,chapter:         1,versenumber:        36,verse:"他见耶稣行走，就说：“看哪，这是　神的羔羊！”"},
{volumn:        43,chapter:         1,versenumber:        37,verse:"两个门徒听见他的话，就跟从了耶稣。"},
{volumn:        43,chapter:         1,versenumber:        38,verse:"耶稣转过身来，看见他们跟着，就问他们说：“你们要什么？”他们说：“拉比，在哪里住？”（拉比翻出来就是夫子。）"},
{volumn:        43,chapter:         1,versenumber:        39,verse:"耶稣说：“你们来看。”他们就去看他在哪里住，这一天便与他同住；那时约有申正了。"},
{volumn:        43,chapter:         1,versenumber:        40,verse:"听见约翰的话跟从耶稣的那两个人，一个是西门·彼得的兄弟安得烈。"},
{volumn:        43,chapter:         1,versenumber:        41,verse:"他先找着自己的哥哥西门，对他说：“我们遇见弥赛亚了。”（弥赛亚翻出来就是基督。）"},
{volumn:        43,chapter:         1,versenumber:        42,verse:"于是领他去见耶稣。耶稣看着他，说：“你是约翰的儿子西门（约翰在马太十六章十七节称约拿），你要称为矶法。”（矶法翻出来就是彼得。）"},
{volumn:        43,chapter:         1,versenumber:        43,verse:"又次日，耶稣想要往加利利去，遇见腓力，就对他说：“来跟从我吧。”"},
{volumn:        43,chapter:         1,versenumber:        44,verse:"这腓力是伯赛大人，和安得烈、彼得同城。"},
{volumn:        43,chapter:         1,versenumber:        45,verse:"腓力找着拿但业，对他说：“摩西在律法上所写的和众先知所记的那一位，我们遇见了，就是约瑟的儿子拿撒勒人耶稣。”"},
{volumn:        43,chapter:         1,versenumber:        46,verse:"拿但业对他说：“拿撒勒还能出什么好的吗？”腓力说：“你来看！”"},
{volumn:        43,chapter:         1,versenumber:        47,verse:"耶稣看见拿但业来，就指着他说：“看哪，这是个真以色列人，他心里是没有诡诈的。”"},
{volumn:        43,chapter:         1,versenumber:        48,verse:"拿但业对耶稣说：“你从哪里知道我呢？”耶稣回答说：“腓力还没有招呼你，你在无花果树底下，我就看见你了。”"},
{volumn:        43,chapter:         1,versenumber:        49,verse:"拿但业说：“拉比，你是　神的儿子，你是以色列的王！”"},
{volumn:        43,chapter:         1,versenumber:        50,verse:"耶稣对他说：“因为我说‘在无花果树底下看见你’，你就信吗？你将要看见比这更大的事”；"},
{volumn:        43,chapter:         1,versenumber:        51,verse:"又说：“我实实在在地告诉你们，你们将要看见天开了，　神的使者上去下来在人子身上。”"},
{volumn:        43,chapter:         2,versenumber:         1,verse:"第三日，在加利利的迦拿有娶亲的筵席，耶稣的母亲在那里。"},
{volumn:        43,chapter:         2,versenumber:         2,verse:"耶稣和他的门徒也被请去赴席。"},
{volumn:        43,chapter:         2,versenumber:         3,verse:"酒用尽了，耶稣的母亲对他说：“他们没有酒了。”"},
{volumn:        43,chapter:         2,versenumber:         4,verse:"耶稣说：“母亲（原文是妇人），我与你有什么相干？我的时候还没有到。”"},
{volumn:        43,chapter:         2,versenumber:         5,verse:"他母亲对用人说：“他告诉你们什么，你们就做什么。”"},
{volumn:        43,chapter:         2,versenumber:         6,verse:"照犹太人洁净的规矩，有六口石缸摆在那里，每口可以盛两三桶水。"},
{volumn:        43,chapter:         2,versenumber:         7,verse:"耶稣对用人说：“把缸倒满了水。”他们就倒满了，直到缸口。"},
{volumn:        43,chapter:         2,versenumber:         8,verse:"耶稣又说：“现在可以舀出来，送给管筵席的。”他们就送了去。"},
{volumn:        43,chapter:         2,versenumber:         9,verse:"管筵席的尝了那水变的酒，并不知道是哪里来的，只有舀水的用人知道。管筵席的便叫新郎来，"},
{volumn:        43,chapter:         2,versenumber:        10,verse:"对他说：“人都是先摆上好酒，等客喝足了，才摆上次的，你倒把好酒留到如今！”"},
{volumn:        43,chapter:         2,versenumber:        11,verse:"这是耶稣所行的头一件神迹，是在加利利的迦拿行的，显出他的荣耀来；他的门徒就信他了。"},
{volumn:        43,chapter:         2,versenumber:        12,verse:"这事以后，耶稣与他的母亲、弟兄，和门徒都下迦百农去，在那里住了不多几日。"},
{volumn:        43,chapter:         2,versenumber:        13,verse:"犹太人的逾越节近了，耶稣就上耶路撒冷去。"},
{volumn:        43,chapter:         2,versenumber:        14,verse:"看见殿里有卖牛、羊、鸽子的，并有兑换银钱的人坐在那里，"},
{volumn:        43,chapter:         2,versenumber:        15,verse:"耶稣就拿绳子做成鞭子，把牛羊都赶出殿去，倒出兑换银钱之人的银钱，推翻他们的桌子，"},
{volumn:        43,chapter:         2,versenumber:        16,verse:"又对卖鸽子的说：“把这些东西拿去！不要将我父的殿当作买卖的地方。”"},
{volumn:        43,chapter:         2,versenumber:        17,verse:"他的门徒就想起经上记着说：“我为你的殿心里焦急，如同火烧。”"},
{volumn:        43,chapter:         2,versenumber:        18,verse:"因此犹太人问他说：“你既做这些事，还显什么神迹给我们看呢？”"},
{volumn:        43,chapter:         2,versenumber:        19,verse:"耶稣回答说：“你们拆毁这殿，我三日内要再建立起来。”"},
{volumn:        43,chapter:         2,versenumber:        20,verse:"犹太人便说：“这殿是四十六年才造成的，你三日内就再建立起来吗？”"},
{volumn:        43,chapter:         2,versenumber:        21,verse:"但耶稣这话是以他的身体为殿。"},
{volumn:        43,chapter:         2,versenumber:        22,verse:"所以到他从死里复活以后，门徒就想起他说过这话，便信了圣经和耶稣所说的。"},
{volumn:        43,chapter:         2,versenumber:        23,verse:"当耶稣在耶路撒冷过逾越节的时候，有许多人看见他所行的神迹，就信了他的名。"},
{volumn:        43,chapter:         2,versenumber:        24,verse:"耶稣却不将自己交托他们；因为他知道万人，"},
{volumn:        43,chapter:         2,versenumber:        25,verse:"也用不着谁见证人怎样，因他知道人心里所存的。"},
{volumn:        43,chapter:         3,versenumber:         1,verse:"有一个法利赛人，名叫尼哥底母，是犹太人的官。"},
{volumn:        43,chapter:         3,versenumber:         2,verse:"这人夜里来见耶稣，说：“拉比，我们知道你是由神那里来作师傅的；因为你所行的神迹，若没有　神同在，无人能行。”"},
{volumn:        43,chapter:         3,versenumber:         3,verse:"耶稣回答说：“我实实在在地告诉你，人若不重生，就不能见　神的国。”"},
{volumn:        43,chapter:         3,versenumber:         4,verse:"尼哥底母说：“人已经老了，如何能重生呢？岂能再进母腹生出来吗？”"},
{volumn:        43,chapter:         3,versenumber:         5,verse:"耶稣说：“我实实在在地告诉你，人若不是从水和圣灵生的，就不能进　神的国。"},
{volumn:        43,chapter:         3,versenumber:         6,verse:"从肉身生的就是肉身；从灵生的就是灵。"},
{volumn:        43,chapter:         3,versenumber:         7,verse:"我说：‘你们必须重生’，你不要以为希奇。"},
{volumn:        43,chapter:         3,versenumber:         8,verse:"风随着意思吹，你听见风的响声，却不晓得从哪里来，往哪里去；凡从圣灵生的，也是如此。”"},
{volumn:        43,chapter:         3,versenumber:         9,verse:"尼哥底母问他说：“怎能有这事呢？”"},
{volumn:        43,chapter:         3,versenumber:        10,verse:"耶稣回答说：“你是以色列人的先生，还不明白这事吗？"},
{volumn:        43,chapter:         3,versenumber:        11,verse:"我实实在在地告诉你，我们所说的是我们知道的；我们所见证的是我们见过的；你们却不领受我们的见证。"},
{volumn:        43,chapter:         3,versenumber:        12,verse:"我对你们说地上的事，你们尚且不信，若说天上的事，如何能信呢？"},
{volumn:        43,chapter:         3,versenumber:        13,verse:"除了从天降下、仍旧在天的人子，没有人升过天。"},
{volumn:        43,chapter:         3,versenumber:        14,verse:"摩西在旷野怎样举蛇，人子也必照样被举起来，"},
{volumn:        43,chapter:         3,versenumber:        15,verse:"叫一切信他的都得永生（或译：叫一切信的人在他里面得永生）。"},
{volumn:        43,chapter:         3,versenumber:        16,verse:"“　神爱世人，甚至将他的独生子赐给他们，叫一切信他的，不至灭亡，反得永生。"},
{volumn:        43,chapter:         3,versenumber:        17,verse:"因为　神差他的儿子降世，不是要定世人的罪（或译：审判世人；下同），乃是要叫世人因他得救。"},
{volumn:        43,chapter:         3,versenumber:        18,verse:"信他的人，不被定罪；不信的人，罪已经定了，因为他不信　神独生子的名。"},
{volumn:        43,chapter:         3,versenumber:        19,verse:"光来到世间，世人因自己的行为是恶的，不爱光，倒爱黑暗，定他们的罪就是在此。"},
{volumn:        43,chapter:         3,versenumber:        20,verse:"凡作恶的便恨光，并不来就光，恐怕他的行为受责备。"},
{volumn:        43,chapter:         3,versenumber:        21,verse:"但行真理的必来就光，要显明他所行的是靠　神而行。”"},
{volumn:        43,chapter:         3,versenumber:        22,verse:"这事以后，耶稣和门徒到了犹太地，在那里居住，施洗。"},
{volumn:        43,chapter:         3,versenumber:        23,verse:"约翰在靠近撒冷的哀嫩也施洗；因为那里水多，众人都去受洗。（"},
{volumn:        43,chapter:         3,versenumber:        24,verse:"那时约翰还没有下在监里。）"},
{volumn:        43,chapter:         3,versenumber:        25,verse:"约翰的门徒和一个犹太人辩论洁净的礼，"},
{volumn:        43,chapter:         3,versenumber:        26,verse:"就来见约翰，说：“拉比，从前同你在约旦河外、你所见证的那位，现在施洗，众人都往他那里去了。”"},
{volumn:        43,chapter:         3,versenumber:        27,verse:"约翰说：“若不是从天上赐的，人就不能得什么。"},
{volumn:        43,chapter:         3,versenumber:        28,verse:"我曾说：‘我不是基督，是奉差遣在他前面的’，你们自己可以给我作见证。"},
{volumn:        43,chapter:         3,versenumber:        29,verse:"娶新妇的就是新郎；新郎的朋友站着，听见新郎的声音就甚喜乐。故此，我这喜乐满足了。"},
{volumn:        43,chapter:         3,versenumber:        30,verse:"他必兴旺，我必衰微。”"},
{volumn:        43,chapter:         3,versenumber:        31,verse:"“从天上来的是在万有之上；从地上来的是属乎地，他所说的也是属乎地。从天上来的是在万有之上。"},
{volumn:        43,chapter:         3,versenumber:        32,verse:"他将所见所闻的见证出来，只是没有人领受他的见证。"},
{volumn:        43,chapter:         3,versenumber:        33,verse:"那领受他见证的，就印上印，证明　神是真的。"},
{volumn:        43,chapter:         3,versenumber:        34,verse:"神所差来的就说　神的话，因为　神赐圣灵给他是没有限量的。"},
{volumn:        43,chapter:         3,versenumber:        35,verse:"父爱子，已将万有交在他手里。"},
{volumn:        43,chapter:         3,versenumber:        36,verse:"信子的人有永生；不信子的人得不着永生（原文是不得见永生），　神的震怒常在他身上。”"},
{volumn:        43,chapter:         4,versenumber:         1,verse:"主知道法利赛人听见他收门徒施洗，比约翰还多，（"},
{volumn:        43,chapter:         4,versenumber:         2,verse:"其实不是耶稣亲自施洗，乃是他的门徒施洗，）"},
{volumn:        43,chapter:         4,versenumber:         3,verse:"他就离了犹太，又往加利利去。"},
{volumn:        43,chapter:         4,versenumber:         4,verse:"必须经过撒玛利亚，"},
{volumn:        43,chapter:         4,versenumber:         5,verse:"于是到了撒玛利亚的一座城，名叫叙加，靠近雅各给他儿子约瑟的那块地。"},
{volumn:        43,chapter:         4,versenumber:         6,verse:"在那里有雅各井；耶稣因走路困乏，就坐在井旁。那时约有午正。"},
{volumn:        43,chapter:         4,versenumber:         7,verse:"有一个撒玛利亚的妇人来打水。耶稣对她说：“请你给我水喝。”（"},
{volumn:        43,chapter:         4,versenumber:         8,verse:"那时门徒进城买食物去了。）"},
{volumn:        43,chapter:         4,versenumber:         9,verse:"撒玛利亚的妇人对他说：“你既是犹太人，怎么向我一个撒玛利亚妇人要水喝呢？”原来犹太人和撒玛利亚人没有来往。"},
{volumn:        43,chapter:         4,versenumber:        10,verse:"耶稣回答说：“你若知道　神的恩赐，和对你说‘给我水喝’的是谁，你必早求他，他也必早给了你活水。”"},
{volumn:        43,chapter:         4,versenumber:        11,verse:"妇人说：“先生，没有打水的器具，井又深，你从哪里得活水呢？"},
{volumn:        43,chapter:         4,versenumber:        12,verse:"我们的祖宗雅各将这井留给我们，他自己和儿子并牲畜也都喝这井里的水，难道你比他还大吗？”"},
{volumn:        43,chapter:         4,versenumber:        13,verse:"耶稣回答说：“凡喝这水的还要再渴；"},
{volumn:        43,chapter:         4,versenumber:        14,verse:"人若喝我所赐的水就永远不渴。我所赐的水要在他里头成为泉源，直涌到永生。”"},
{volumn:        43,chapter:         4,versenumber:        15,verse:"妇人说：“先生，请把这水赐给我，叫我不渴，也不用来这么远打水。”"},
{volumn:        43,chapter:         4,versenumber:        16,verse:"耶稣说：“你去叫你丈夫也到这里来。”"},
{volumn:        43,chapter:         4,versenumber:        17,verse:"妇人说：“我没有丈夫。”耶稣说：“你说没有丈夫是不错的。"},
{volumn:        43,chapter:         4,versenumber:        18,verse:"你已经有五个丈夫，你现在有的并不是你的丈夫。你这话是真的。”"},
{volumn:        43,chapter:         4,versenumber:        19,verse:"妇人说：“先生，我看出你是先知。"},
{volumn:        43,chapter:         4,versenumber:        20,verse:"我们的祖宗在这山上礼拜，你们倒说，应当礼拜的地方是在耶路撒冷。”"},
{volumn:        43,chapter:         4,versenumber:        21,verse:"耶稣说：“妇人，你当信我。时候将到，你们拜父，也不在这山上，也不在耶路撒冷。"},
{volumn:        43,chapter:         4,versenumber:        22,verse:"你们所拜的，你们不知道；我们所拜的，我们知道，因为救恩是从犹太人出来的。"},
{volumn:        43,chapter:         4,versenumber:        23,verse:"时候将到，如今就是了，那真正拜父的，要用心灵和诚实拜他，因为父要这样的人拜他。”"},
{volumn:        43,chapter:         4,versenumber:        24,verse:"神是个灵（或无个字），所以拜他的必须用心灵和诚实拜他。"},
{volumn:        43,chapter:         4,versenumber:        25,verse:"妇人说：“我知道弥赛亚（就是那称为基督的）要来；他来了，必将一切的事都告诉我们。”"},
{volumn:        43,chapter:         4,versenumber:        26,verse:"耶稣说：“这和你说话的就是他！”"},
{volumn:        43,chapter:         4,versenumber:        27,verse:"当下门徒回来，就希奇耶稣和一个妇人说话；只是没有人说：“你是要什么？”或说：“你为什么和她说话？”"},
{volumn:        43,chapter:         4,versenumber:        28,verse:"那妇人就留下水罐子，往城里去，对众人说："},
{volumn:        43,chapter:         4,versenumber:        29,verse:"“你们来看！有一个人将我素来所行的一切事都给我说出来了，莫非这就是基督吗？”"},
{volumn:        43,chapter:         4,versenumber:        30,verse:"众人就出城，往耶稣那里去。"},
{volumn:        43,chapter:         4,versenumber:        31,verse:"这其间，门徒对耶稣说：“拉比，请吃。”"},
{volumn:        43,chapter:         4,versenumber:        32,verse:"耶稣说：“我有食物吃，是你们不知道的。”"},
{volumn:        43,chapter:         4,versenumber:        33,verse:"门徒就彼此对问说：“莫非有人拿什么给他吃吗？”"},
{volumn:        43,chapter:         4,versenumber:        34,verse:"耶稣说：“我的食物就是遵行差我来者的旨意，做成他的工。"},
{volumn:        43,chapter:         4,versenumber:        35,verse:"你们岂不说‘到收割的时候还有四个月’吗？我告诉你们，举目向田观看，庄稼已经熟了（原文是发白），可以收割了。"},
{volumn:        43,chapter:         4,versenumber:        36,verse:"收割的人得工价，积蓄五谷到永生，叫撒种的和收割的一同快乐。"},
{volumn:        43,chapter:         4,versenumber:        37,verse:"俗语说：‘那人撒种，这人收割’，这话可见是真的。"},
{volumn:        43,chapter:         4,versenumber:        38,verse:"我差你们去收你们所没有劳苦的；别人劳苦，你们享受他们所劳苦的。”"},
{volumn:        43,chapter:         4,versenumber:        39,verse:"那城里有好些撒玛利亚人信了耶稣，因为那妇人作见证说：“他将我素来所行的一切事都给我说出来了。”"},
{volumn:        43,chapter:         4,versenumber:        40,verse:"于是撒玛利亚人来见耶稣，求他在他们那里住下，他便在那里住了两天。"},
{volumn:        43,chapter:         4,versenumber:        41,verse:"因耶稣的话，信的人就更多了，"},
{volumn:        43,chapter:         4,versenumber:        42,verse:"便对妇人说：“现在我们信，不是因为你的话，是我们亲自听见了，知道这真是救世主。”"},
{volumn:        43,chapter:         4,versenumber:        43,verse:"过了那两天，耶稣离了那地方，往加利利去。"},
{volumn:        43,chapter:         4,versenumber:        44,verse:"因为耶稣自己作过见证说：“先知在本地是没有人尊敬的。”"},
{volumn:        43,chapter:         4,versenumber:        45,verse:"到了加利利，加利利人既然看见他在耶路撒冷过节所行的一切事，就接待他，因为他们也是上去过节。"},
{volumn:        43,chapter:         4,versenumber:        46,verse:"耶稣又到了加利利的迦拿，就是他从前变水为酒的地方。有一个大臣，他的儿子在迦百农患病。"},
{volumn:        43,chapter:         4,versenumber:        47,verse:"他听见耶稣从犹太到了加利利，就来见他，求他下去医治他的儿子，因为他儿子快要死了。"},
{volumn:        43,chapter:         4,versenumber:        48,verse:"耶稣就对他说：“若不看见神迹奇事，你们总是不信。”"},
{volumn:        43,chapter:         4,versenumber:        49,verse:"那大臣说：“先生，求你趁着我的孩子还没有死就下去。”"},
{volumn:        43,chapter:         4,versenumber:        50,verse:"耶稣对他说：“回去吧，你的儿子活了！”那人信耶稣所说的话就回去了。"},
{volumn:        43,chapter:         4,versenumber:        51,verse:"正下去的时候，他的仆人迎见他，说他的儿子活了。"},
{volumn:        43,chapter:         4,versenumber:        52,verse:"他就问什么时候见好的。他们说：“昨日未时热就退了。”"},
{volumn:        43,chapter:         4,versenumber:        53,verse:"他便知道这正是耶稣对他说“你儿子活了”的时候；他自己和全家就都信了。"},
{volumn:        43,chapter:         4,versenumber:        54,verse:"这是耶稣在加利利行的第二件神迹，是他从犹太回去以后行的。"},
{volumn:        43,chapter:         5,versenumber:         1,verse:"这事以后，到了犹太人的一个节期，耶稣就上耶路撒冷去。"},
{volumn:        43,chapter:         5,versenumber:         2,verse:"在耶路撒冷，靠近羊门有一个池子，希伯来话叫作毕士大，旁边有五个廊子；"},
{volumn:        43,chapter:         5,versenumber:         3,verse:"里面躺着瞎眼的、瘸腿的、血气枯干的许多病人。（有古卷在此有：等候水动，因为有天使按时下池子搅动那水，水动之后，谁先下去，无论害什么病就痊愈了。）"},
{volumn:        43,chapter:         5,versenumber:         4,verse:""},
{volumn:        43,chapter:         5,versenumber:         5,verse:"在那里有一个人，病了三十八年。"},
{volumn:        43,chapter:         5,versenumber:         6,verse:"耶稣看见他躺着，知道他病了许久，就问他说：“你要痊愈吗？”"},
{volumn:        43,chapter:         5,versenumber:         7,verse:"病人回答说：“先生，水动的时候，没有人把我放在池子里；我正去的时候，就有别人比我先下去。”"},
{volumn:        43,chapter:         5,versenumber:         8,verse:"耶稣对他说：“起来，拿你的褥子走吧！”"},
{volumn:        43,chapter:         5,versenumber:         9,verse:"那人立刻痊愈，就拿起褥子来走了。"},
{volumn:        43,chapter:         5,versenumber:        10,verse:"那天是安息日，所以犹太人对那医好的人说：“今天是安息日，你拿褥子是不可的。”"},
{volumn:        43,chapter:         5,versenumber:        11,verse:"他却回答说：“那使我痊愈的，对我说：‘拿你的褥子走吧。’”"},
{volumn:        43,chapter:         5,versenumber:        12,verse:"他们问他说：“对你说‘拿褥子走’的是什么人？”"},
{volumn:        43,chapter:         5,versenumber:        13,verse:"那医好的人不知道是谁；因为那里的人多，耶稣已经躲开了。"},
{volumn:        43,chapter:         5,versenumber:        14,verse:"后来耶稣在殿里遇见他，对他说：“你已经痊愈了，不要再犯罪，恐怕你遭遇的更加利害。”"},
{volumn:        43,chapter:         5,versenumber:        15,verse:"那人就去告诉犹太人，使他痊愈的是耶稣。"},
{volumn:        43,chapter:         5,versenumber:        16,verse:"所以犹太人逼迫耶稣，因为他在安息日做了这事。"},
{volumn:        43,chapter:         5,versenumber:        17,verse:"耶稣就对他们说：“我父做事直到如今，我也做事。”"},
{volumn:        43,chapter:         5,versenumber:        18,verse:"所以犹太人越发想要杀他；因他不但犯了安息日，并且称　神为他的父，将自己和　神当作平等。"},
{volumn:        43,chapter:         5,versenumber:        19,verse:"耶稣对他们说：“我实实在在地告诉你们，子凭着自己不能做什么，惟有看见父所做的，子才能做；父所做的事，子也照样做。"},
{volumn:        43,chapter:         5,versenumber:        20,verse:"父爱子，将自己所做的一切事指给他看，还要将比这更大的事指给他看，叫你们希奇。"},
{volumn:        43,chapter:         5,versenumber:        21,verse:"父怎样叫死人起来，使他们活着，子也照样随自己的意思使人活着。"},
{volumn:        43,chapter:         5,versenumber:        22,verse:"父不审判什么人，乃将审判的事全交与子，"},
{volumn:        43,chapter:         5,versenumber:        23,verse:"叫人都尊敬子如同尊敬父一样。不尊敬子的，就是不尊敬差子来的父。"},
{volumn:        43,chapter:         5,versenumber:        24,verse:"我实实在在地告诉你们，那听我话、又信差我来者的，就有永生；不至于定罪，是已经出死入生了。"},
{volumn:        43,chapter:         5,versenumber:        25,verse:"我实实在在地告诉你们，时候将到，现在就是了，死人要听见　神儿子的声音，听见的人就要活了。"},
{volumn:        43,chapter:         5,versenumber:        26,verse:"因为父怎样在自己有生命，就赐给他儿子也照样在自己有生命，"},
{volumn:        43,chapter:         5,versenumber:        27,verse:"并且因为他是人子，就赐给他行审判的权柄。"},
{volumn:        43,chapter:         5,versenumber:        28,verse:"你们不要把这事看作希奇。时候要到，凡在坟墓里的，都要听见他的声音，就出来："},
{volumn:        43,chapter:         5,versenumber:        29,verse:"行善的，复活得生；作恶的，复活定罪。"},
{volumn:        43,chapter:         5,versenumber:        30,verse:"“我凭着自己不能做什么，我怎么听见就怎么审判。我的审判也是公平的；因为我不求自己的意思，只求那差我来者的意思。”"},
{volumn:        43,chapter:         5,versenumber:        31,verse:"“我若为自己作见证，我的见证就不真。"},
{volumn:        43,chapter:         5,versenumber:        32,verse:"另有一位给我作见证，我也知道他给我作的见证是真的。"},
{volumn:        43,chapter:         5,versenumber:        33,verse:"你们曾差人到约翰那里，他为真理作过见证。"},
{volumn:        43,chapter:         5,versenumber:        34,verse:"其实，我所受的见证不是从人来的；然而，我说这些话，为要叫你们得救。"},
{volumn:        43,chapter:         5,versenumber:        35,verse:"约翰是点着的明灯，你们情愿暂时喜欢他的光。"},
{volumn:        43,chapter:         5,versenumber:        36,verse:"但我有比约翰更大的见证；因为父交给我要我成就的事，就是我所做的事，这便见证我是父所差来的。"},
{volumn:        43,chapter:         5,versenumber:        37,verse:"差我来的父也为我作过见证。你们从来没有听见他的声音，也没有看见他的形像。"},
{volumn:        43,chapter:         5,versenumber:        38,verse:"你们并没有他的道存在心里；因为他所差来的，你们不信。"},
{volumn:        43,chapter:         5,versenumber:        39,verse:"你们查考圣经（或译：应当查考圣经），因你们以为内中有永生；给我作见证的就是这经。"},
{volumn:        43,chapter:         5,versenumber:        40,verse:"然而，你们不肯到我这里来得生命。"},
{volumn:        43,chapter:         5,versenumber:        41,verse:"“我不受从人来的荣耀。"},
{volumn:        43,chapter:         5,versenumber:        42,verse:"但我知道，你们心里没有　神的爱。"},
{volumn:        43,chapter:         5,versenumber:        43,verse:"我奉我父的名来，你们并不接待我；若有别人奉自己的名来，你们倒要接待他。"},
{volumn:        43,chapter:         5,versenumber:        44,verse:"你们互相受荣耀，却不求从独一之　神来的荣耀，怎能信我呢？"},
{volumn:        43,chapter:         5,versenumber:        45,verse:"不要想我在父面前要告你们；有一位告你们的，就是你们所仰赖的摩西。"},
{volumn:        43,chapter:         5,versenumber:        46,verse:"你们如果信摩西，也必信我，因为他书上有指着我写的话。"},
{volumn:        43,chapter:         5,versenumber:        47,verse:"你们若不信他的书，怎能信我的话呢？”"},
{volumn:        43,chapter:         6,versenumber:         1,verse:"这事以后，耶稣渡过加利利海，就是提比哩亚海。"},
{volumn:        43,chapter:         6,versenumber:         2,verse:"有许多人因为看见他在病人身上所行的神迹，就跟随他。"},
{volumn:        43,chapter:         6,versenumber:         3,verse:"耶稣上了山，和门徒一同坐在那里。"},
{volumn:        43,chapter:         6,versenumber:         4,verse:"那时犹太人的逾越节近了。"},
{volumn:        43,chapter:         6,versenumber:         5,verse:"耶稣举目看见许多人来，就对腓力说：“我们从哪里买饼叫这些人吃呢？”（"},
{volumn:        43,chapter:         6,versenumber:         6,verse:"他说这话是要试验腓力；他自己原知道要怎样行。）"},
{volumn:        43,chapter:         6,versenumber:         7,verse:"腓力回答说：“就是二十两银子的饼，叫他们各人吃一点也是不够的。”"},
{volumn:        43,chapter:         6,versenumber:         8,verse:"有一个门徒，就是西门·彼得的兄弟安得烈，对耶稣说："},
{volumn:        43,chapter:         6,versenumber:         9,verse:"“在这里有一个孩童，带着五个大麦饼、两条鱼，只是分给这许多人还算什么呢？”"},
{volumn:        43,chapter:         6,versenumber:        10,verse:"耶稣说：“你们叫众人坐下。”原来那地方的草多，众人就坐下，数目约有五千。"},
{volumn:        43,chapter:         6,versenumber:        11,verse:"耶稣拿起饼来，祝谢了，就分给那坐着的人；分鱼也是这样，都随着他们所要的。"},
{volumn:        43,chapter:         6,versenumber:        12,verse:"他们吃饱了，耶稣对门徒说：“把剩下的零碎收拾起来，免得有糟蹋的。”"},
{volumn:        43,chapter:         6,versenumber:        13,verse:"他们便将那五个大麦饼的零碎，就是众人吃了剩下的，收拾起来，装满了十二个篮子。"},
{volumn:        43,chapter:         6,versenumber:        14,verse:"众人看见耶稣所行的神迹，就说：“这真是那要到世间来的先知！”"},
{volumn:        43,chapter:         6,versenumber:        15,verse:"耶稣既知道众人要来强逼他作王，就独自又退到山上去了。"},
{volumn:        43,chapter:         6,versenumber:        16,verse:"到了晚上，他的门徒下海边去，"},
{volumn:        43,chapter:         6,versenumber:        17,verse:"上了船，要过海往迦百农去。天已经黑了，耶稣还没有来到他们那里。"},
{volumn:        43,chapter:         6,versenumber:        18,verse:"忽然狂风大作，海就翻腾起来。"},
{volumn:        43,chapter:         6,versenumber:        19,verse:"门徒摇橹，约行了十里多路，看见耶稣在海面上走，渐渐近了船，他们就害怕。"},
{volumn:        43,chapter:         6,versenumber:        20,verse:"耶稣对他们说：“是我，不要怕！”"},
{volumn:        43,chapter:         6,versenumber:        21,verse:"门徒就喜欢接他上船，船立时到了他们所要去的地方。"},
{volumn:        43,chapter:         6,versenumber:        22,verse:"第二日，站在海那边的众人知道那里没有别的船，只有一只小船，又知道耶稣没有同他的门徒上船，乃是门徒自己去的。"},
{volumn:        43,chapter:         6,versenumber:        23,verse:"然而，有几只小船从提比哩亚来，靠近主祝谢后分饼给人吃的地方。"},
{volumn:        43,chapter:         6,versenumber:        24,verse:"众人见耶稣和门徒都不在那里，就上了船，往迦百农去找耶稣。"},
{volumn:        43,chapter:         6,versenumber:        25,verse:"既在海那边找着了，就对他说：“拉比，是几时到这里来的？”"},
{volumn:        43,chapter:         6,versenumber:        26,verse:"耶稣回答说：“我实实在在地告诉你们，你们找我，并不是因见了神迹，乃是因吃饼得饱。"},
{volumn:        43,chapter:         6,versenumber:        27,verse:"不要为那必坏的食物劳力，要为那存到永生的食物劳力，就是人子要赐给你们的，因为人子是父　神所印证的。”"},
{volumn:        43,chapter:         6,versenumber:        28,verse:"众人问他说：“我们当行什么才算做　神的工呢？”"},
{volumn:        43,chapter:         6,versenumber:        29,verse:"耶稣回答说：“信神所差来的，这就是做　神的工。”"},
{volumn:        43,chapter:         6,versenumber:        30,verse:"他们又说：“你行什么神迹，叫我们看见就信你；你到底做什么事呢？"},
{volumn:        43,chapter:         6,versenumber:        31,verse:"我们的祖宗在旷野吃过吗哪，如经上写着说：‘他从天上赐下粮来给他们吃。’”"},
{volumn:        43,chapter:         6,versenumber:        32,verse:"耶稣说：“我实实在在地告诉你们，那从天上来的粮不是摩西赐给你们的，乃是我父将天上来的真粮赐给你们。"},
{volumn:        43,chapter:         6,versenumber:        33,verse:"因为　神的粮就是那从天上降下来、赐生命给世界的。”"},
{volumn:        43,chapter:         6,versenumber:        34,verse:"他们说：“主啊，常将这粮赐给我们！”"},
{volumn:        43,chapter:         6,versenumber:        35,verse:"耶稣说：“我就是生命的粮。到我这里来的，必定不饿；信我的，永远不渴。"},
{volumn:        43,chapter:         6,versenumber:        36,verse:"只是我对你们说过，你们已经看见我，还是不信。"},
{volumn:        43,chapter:         6,versenumber:        37,verse:"凡父所赐给我的人必到我这里来；到我这里来的，我总不丢弃他。"},
{volumn:        43,chapter:         6,versenumber:        38,verse:"因为我从天上降下来，不是要按自己的意思行，乃是要按那差我来者的意思行。"},
{volumn:        43,chapter:         6,versenumber:        39,verse:"差我来者的意思就是：他所赐给我的，叫我一个也不失落，在末日却叫他复活。"},
{volumn:        43,chapter:         6,versenumber:        40,verse:"因为我父的意思是叫一切见子而信的人得永生，并且在末日我要叫他复活。”"},
{volumn:        43,chapter:         6,versenumber:        41,verse:"犹太人因为耶稣说“我是从天上降下来的粮”，就私下议论他，"},
{volumn:        43,chapter:         6,versenumber:        42,verse:"说：“这不是约瑟的儿子耶稣吗？他的父母我们岂不认得吗？他如今怎么说‘我是从天上降下来的’呢？”"},
{volumn:        43,chapter:         6,versenumber:        43,verse:"耶稣回答说：“你们不要大家议论。"},
{volumn:        43,chapter:         6,versenumber:        44,verse:"若不是差我来的父吸引人，就没有能到我这里来的；到我这里来的，在末日我要叫他复活。"},
{volumn:        43,chapter:         6,versenumber:        45,verse:"在先知书上写着说：‘他们都要蒙　神的教训。’凡听见父之教训又学习的，就到我这里来。"},
{volumn:        43,chapter:         6,versenumber:        46,verse:"这不是说有人看见过父，惟独从　神来的，他看见过父。"},
{volumn:        43,chapter:         6,versenumber:        47,verse:"我实实在在地告诉你们，信的人有永生。"},
{volumn:        43,chapter:         6,versenumber:        48,verse:"我就是生命的粮。"},
{volumn:        43,chapter:         6,versenumber:        49,verse:"你们的祖宗在旷野吃过吗哪，还是死了。"},
{volumn:        43,chapter:         6,versenumber:        50,verse:"这是从天上降下来的粮，叫人吃了就不死。"},
{volumn:        43,chapter:         6,versenumber:        51,verse:"我是从天上降下来生命的粮；人若吃这粮，就必永远活着。我所要赐的粮就是我的肉，为世人之生命所赐的。”"},
{volumn:        43,chapter:         6,versenumber:        52,verse:"因此，犹太人彼此争论说：“这个人怎能把他的肉给我们吃呢？”"},
{volumn:        43,chapter:         6,versenumber:        53,verse:"耶稣说：“我实实在在地告诉你们，你们若不吃人子的肉，不喝人子的血，就没有生命在你们里面。"},
{volumn:        43,chapter:         6,versenumber:        54,verse:"吃我肉、喝我血的人就有永生，在末日我要叫他复活。"},
{volumn:        43,chapter:         6,versenumber:        55,verse:"我的肉真是可吃的，我的血真是可喝的。"},
{volumn:        43,chapter:         6,versenumber:        56,verse:"吃我肉、喝我血的人常在我里面，我也常在他里面。"},
{volumn:        43,chapter:         6,versenumber:        57,verse:"永活的父怎样差我来，我又因父活着；照样，吃我肉的人也要因我活着。"},
{volumn:        43,chapter:         6,versenumber:        58,verse:"这就是从天上降下来的粮。吃这粮的人就永远活着，不像你们的祖宗吃过吗哪还是死了。”"},
{volumn:        43,chapter:         6,versenumber:        59,verse:"这些话是耶稣在迦百农会堂里教训人说的。"},
{volumn:        43,chapter:         6,versenumber:        60,verse:"他的门徒中有好些人听见了，就说：“这话甚难，谁能听呢？”"},
{volumn:        43,chapter:         6,versenumber:        61,verse:"耶稣心里知道门徒为这话议论，就对他们说：“这话是叫你们厌弃（原文是跌倒）吗？"},
{volumn:        43,chapter:         6,versenumber:        62,verse:"倘或你们看见人子升到他原来所在之处，怎么样呢？"},
{volumn:        43,chapter:         6,versenumber:        63,verse:"叫人活着的乃是灵，肉体是无益的。我对你们所说的话就是灵，就是生命。"},
{volumn:        43,chapter:         6,versenumber:        64,verse:"只是你们中间有不信的人。”耶稣从起头就知道谁不信他，谁要卖他。"},
{volumn:        43,chapter:         6,versenumber:        65,verse:"耶稣又说：“所以我对你们说过，若不是蒙我父的恩赐，没有人能到我这里来。”"},
{volumn:        43,chapter:         6,versenumber:        66,verse:"从此，他门徒中多有退去的，不再和他同行。"},
{volumn:        43,chapter:         6,versenumber:        67,verse:"耶稣就对那十二个门徒说：“你们也要去吗？”"},
{volumn:        43,chapter:         6,versenumber:        68,verse:"西门·彼得回答说：“主啊，你有永生之道，我们还归从谁呢？"},
{volumn:        43,chapter:         6,versenumber:        69,verse:"我们已经信了，又知道你是　神的圣者。”"},
{volumn:        43,chapter:         6,versenumber:        70,verse:"耶稣说：“我不是拣选了你们十二个门徒吗？但你们中间有一个是魔鬼。”"},
{volumn:        43,chapter:         6,versenumber:        71,verse:"耶稣这话是指着加略人西门的儿子犹大说的；他本是十二个门徒里的一个，后来要卖耶稣的。"},
{volumn:        43,chapter:         7,versenumber:         1,verse:"这事以后，耶稣在加利利游行，不愿在犹太游行，因为犹太人想要杀他。"},
{volumn:        43,chapter:         7,versenumber:         2,verse:"当时犹太人的住棚节近了。"},
{volumn:        43,chapter:         7,versenumber:         3,verse:"耶稣的弟兄就对他说：“你离开这里上犹太去吧，叫你的门徒也看见你所行的事。"},
{volumn:        43,chapter:         7,versenumber:         4,verse:"人要显扬名声，没有在暗处行事的；你如果行这些事，就当将自己显明给世人看。”"},
{volumn:        43,chapter:         7,versenumber:         5,verse:"因为连他的弟兄说这话，是因为不信他。"},
{volumn:        43,chapter:         7,versenumber:         6,verse:"耶稣就对他们说：“我的时候还没有到；你们的时候常是方便的。"},
{volumn:        43,chapter:         7,versenumber:         7,verse:"世人不能恨你们，却是恨我，因为我指证他们所做的事是恶的。"},
{volumn:        43,chapter:         7,versenumber:         8,verse:"你们上去过节吧，我现在不上去过这节，因为我的时候还没有满。”"},
{volumn:        43,chapter:         7,versenumber:         9,verse:"耶稣说了这话，仍旧住在加利利。"},
{volumn:        43,chapter:         7,versenumber:        10,verse:"但他弟兄上去以后，他也上去过节，不是明去，似乎是暗去的。"},
{volumn:        43,chapter:         7,versenumber:        11,verse:"正在节期，犹太人寻找耶稣，说：“他在哪里？”"},
{volumn:        43,chapter:         7,versenumber:        12,verse:"众人为他纷纷议论，有的说：“他是好人。”有的说：“不然，他是迷惑众人的。”"},
{volumn:        43,chapter:         7,versenumber:        13,verse:"只是没有人明明地讲论他，因为怕犹太人。"},
{volumn:        43,chapter:         7,versenumber:        14,verse:"到了节期，耶稣上殿里去教训人。"},
{volumn:        43,chapter:         7,versenumber:        15,verse:"犹太人就希奇，说：“这个人没有学过，怎么明白书呢？”"},
{volumn:        43,chapter:         7,versenumber:        16,verse:"耶稣说：“我的教训不是我自己的，乃是那差我来者的。"},
{volumn:        43,chapter:         7,versenumber:        17,verse:"人若立志遵着他的旨意行，就必晓得这教训或是出于　神，或是我凭着自己说的。"},
{volumn:        43,chapter:         7,versenumber:        18,verse:"人凭着自己说，是求自己的荣耀；惟有求那差他来者的荣耀，这人是真的，在他心里没有不义。"},
{volumn:        43,chapter:         7,versenumber:        19,verse:"摩西岂不是传律法给你们吗？你们却没有一个人守律法。为什么想要杀我呢？”"},
{volumn:        43,chapter:         7,versenumber:        20,verse:"众人回答说：“你是被鬼附着了！谁想要杀你？”"},
{volumn:        43,chapter:         7,versenumber:        21,verse:"耶稣说：“我做了一件事，你们都以为希奇。"},
{volumn:        43,chapter:         7,versenumber:        22,verse:"摩西传割礼给你们（其实不是从摩西起的，乃是从祖先起的），因此你们也在安息日给人行割礼。"},
{volumn:        43,chapter:         7,versenumber:        23,verse:"人若在安息日受割礼，免得违背摩西的律法，我在安息日叫一个人全然好了，你们就向我生气吗？"},
{volumn:        43,chapter:         7,versenumber:        24,verse:"不可按外貌断定是非，总要按公平断定是非。”"},
{volumn:        43,chapter:         7,versenumber:        25,verse:"耶路撒冷人中有的说：“这不是他们想要杀的人吗？"},
{volumn:        43,chapter:         7,versenumber:        26,verse:"你看他还明明地讲道，他们也不向他说什么，难道官长真知道这是基督吗？"},
{volumn:        43,chapter:         7,versenumber:        27,verse:"然而，我们知道这个人从哪里来；只是基督来的时候，没有人知道他从哪里来。”"},
{volumn:        43,chapter:         7,versenumber:        28,verse:"那时，耶稣在殿里教训人，大声说：“你们也知道我，也知道我从哪里来；我来并不是由于自己。但那差我来的是真的。你们不认识他，"},
{volumn:        43,chapter:         7,versenumber:        29,verse:"我却认识他；因为我是从他来的，他也是差了我来。”"},
{volumn:        43,chapter:         7,versenumber:        30,verse:"他们就想要捉拿耶稣；只是没有人下手，因为他的时候还没有到。"},
{volumn:        43,chapter:         7,versenumber:        31,verse:"但众人中间有好些信他的，说：“基督来的时候，他所行的神迹岂能比这人所行的更多吗？”"},
{volumn:        43,chapter:         7,versenumber:        32,verse:"法利赛人听见众人为耶稣这样纷纷议论，祭司长和法利赛人就打发差役去捉拿他。"},
{volumn:        43,chapter:         7,versenumber:        33,verse:"于是耶稣说：“我还有不多的时候和你们同在，以后就回到差我来的那里去。"},
{volumn:        43,chapter:         7,versenumber:        34,verse:"你们要找我，却找不着；我所在的地方你们不能到。”"},
{volumn:        43,chapter:         7,versenumber:        35,verse:"犹太人就彼此对问说：“这人要往哪里去，叫我们找不着呢？难道他要往散住希腊中的犹太人那里去教训希腊人吗？"},
{volumn:        43,chapter:         7,versenumber:        36,verse:"他说：‘你们要找我，却找不着；我所在的地方，你们不能到’，这话是什么意思呢？”"},
{volumn:        43,chapter:         7,versenumber:        37,verse:"节期的末日，就是最大之日，耶稣站着高声说：“人若渴了，可以到我这里来喝。"},
{volumn:        43,chapter:         7,versenumber:        38,verse:"信我的人就如经上所说：‘从他腹中要流出活水的江河来。’”"},
{volumn:        43,chapter:         7,versenumber:        39,verse:"耶稣这话是指着信他之人要受圣灵说的。那时还没有赐下圣灵来，因为耶稣尚未得着荣耀。"},
{volumn:        43,chapter:         7,versenumber:        40,verse:"众人听见这话，有的说：“这真是那先知。”"},
{volumn:        43,chapter:         7,versenumber:        41,verse:"有的说：“这是基督。”但也有的说：“基督岂是从加利利出来的吗？"},
{volumn:        43,chapter:         7,versenumber:        42,verse:"经上岂不是说‘基督是大卫的后裔，从大卫本乡伯利恒出来的’吗？”"},
{volumn:        43,chapter:         7,versenumber:        43,verse:"于是众人因着耶稣起了纷争。"},
{volumn:        43,chapter:         7,versenumber:        44,verse:"其中有人要捉拿他，只是无人下手。"},
{volumn:        43,chapter:         7,versenumber:        45,verse:"差役回到祭司长和法利赛人那里。他们对差役说：“你们为什么没有带他来呢？”"},
{volumn:        43,chapter:         7,versenumber:        46,verse:"差役回答说：“从来没有像他这样说话的！”"},
{volumn:        43,chapter:         7,versenumber:        47,verse:"法利赛人说：“你们也受了迷惑吗？"},
{volumn:        43,chapter:         7,versenumber:        48,verse:"官长或是法利赛人岂有信他的呢？"},
{volumn:        43,chapter:         7,versenumber:        49,verse:"但这些不明白律法的百姓是被咒诅的！”"},
{volumn:        43,chapter:         7,versenumber:        50,verse:"内中有尼哥底母，就是从前去见耶稣的，对他们说："},
{volumn:        43,chapter:         7,versenumber:        51,verse:"“不先听本人的口供，不知道他所做的事，难道我们的律法还定他的罪吗？”"},
{volumn:        43,chapter:         7,versenumber:        52,verse:"他们回答说：“你也是出于加利利吗？你且去查考，就可知道加利利没有出过先知。”"},
{volumn:        43,chapter:         8,versenumber:         1,verse:"于是各人都回家去了；耶稣却往橄榄山去，"},
{volumn:        43,chapter:         8,versenumber:         2,verse:"清早又回到殿里。众百姓都到他那里去，他就坐下，教训他们。"},
{volumn:        43,chapter:         8,versenumber:         3,verse:"文士和法利赛人带着一个行淫时被拿的妇人来，叫她站在当中，"},
{volumn:        43,chapter:         8,versenumber:         4,verse:"就对耶稣说：“夫子，这妇人是正行淫之时被拿的。"},
{volumn:        43,chapter:         8,versenumber:         5,verse:"摩西在律法上吩咐我们把这样的妇人用石头打死。你说该把她怎么样呢？”"},
{volumn:        43,chapter:         8,versenumber:         6,verse:"他们说这话，乃试探耶稣，要得着告他的把柄。耶稣却弯着腰，用指头在地上画字。"},
{volumn:        43,chapter:         8,versenumber:         7,verse:"他们还是不住地问他，耶稣就直起腰来，对他们说：“你们中间谁是没有罪的，谁就可以先拿石头打她。”"},
{volumn:        43,chapter:         8,versenumber:         8,verse:"于是又弯着腰，用指头在地上画字。"},
{volumn:        43,chapter:         8,versenumber:         9,verse:"他们听见这话，就从老到少，一个一个地都出去了，只剩下耶稣一人，还有那妇人仍然站在当中。"},
{volumn:        43,chapter:         8,versenumber:        10,verse:"耶稣就直起腰来，对她说：“妇人，那些人在哪里呢？没有人定你的罪吗？”"},
{volumn:        43,chapter:         8,versenumber:        11,verse:"她说：“主啊，没有。”耶稣说：“我也不定你的罪。去吧，从此不要再犯罪了！”"},
{volumn:        43,chapter:         8,versenumber:        12,verse:"耶稣又对众人说：“我是世界的光。跟从我的，就不在黑暗里走，必要得着生命的光。”"},
{volumn:        43,chapter:         8,versenumber:        13,verse:"法利赛人对他说：“你是为自己作见证，你的见证不真。”"},
{volumn:        43,chapter:         8,versenumber:        14,verse:"耶稣说：“我虽然为自己作见证，我的见证还是真的；因我知道我从哪里来，往哪里去；你们却不知道我从哪里来，往哪里去。"},
{volumn:        43,chapter:         8,versenumber:        15,verse:"你们是以外貌（原文是凭肉身）判断人，我却不判断人。"},
{volumn:        43,chapter:         8,versenumber:        16,verse:"就是判断人，我的判断也是真的；因为不是我独自在这里，还有差我来的父与我同在。"},
{volumn:        43,chapter:         8,versenumber:        17,verse:"你们的律法上也记着说：‘两个人的见证是真的。’"},
{volumn:        43,chapter:         8,versenumber:        18,verse:"我是为自己作见证，还有差我来的父也是为我作见证。”"},
{volumn:        43,chapter:         8,versenumber:        19,verse:"他们就问他说：“你的父在哪里？”耶稣回答说：“你们不认识我，也不认识我的父；若是认识我，也就认识我的父。”"},
{volumn:        43,chapter:         8,versenumber:        20,verse:"这些话是耶稣在殿里的库房、教训人时所说的，也没有人拿他，因为他的时候还没有到。"},
{volumn:        43,chapter:         8,versenumber:        21,verse:"耶稣又对他们说：“我要去了，你们要找我，并且你们要死在罪中。我所去的地方，你们不能到。”"},
{volumn:        43,chapter:         8,versenumber:        22,verse:"犹太人说：“他说‘我所去的地方，你们不能到’，难道他要自尽吗？”"},
{volumn:        43,chapter:         8,versenumber:        23,verse:"耶稣对他们说：“你们是从下头来的，我是从上头来的；你们是属这世界的，我不是属这世界的。"},
{volumn:        43,chapter:         8,versenumber:        24,verse:"所以我对你们说，你们要死在罪中。你们若不信我是基督，必要死在罪中。”"},
{volumn:        43,chapter:         8,versenumber:        25,verse:"他们就问他说：“你是谁？”耶稣对他们说：“就是我从起初所告诉你们的。"},
{volumn:        43,chapter:         8,versenumber:        26,verse:"我有许多事讲论你们，判断你们；但那差我来的是真的，我在他那里所听见的，我就传给世人。”"},
{volumn:        43,chapter:         8,versenumber:        27,verse:"他们不明白耶稣是指着父说的。"},
{volumn:        43,chapter:         8,versenumber:        28,verse:"所以耶稣说：“你们举起人子以后，必知道我是基督，并且知道我没有一件事是凭着自己做的。我说这些话乃是照着父所教训我的。"},
{volumn:        43,chapter:         8,versenumber:        29,verse:"那差我来的是与我同在；他没有撇下我独自在这里，因为我常做他所喜悦的事。”"},
{volumn:        43,chapter:         8,versenumber:        30,verse:"耶稣说这话的时候，就有许多人信他。"},
{volumn:        43,chapter:         8,versenumber:        31,verse:"耶稣对信他的犹太人说：“你们若常常遵守我的道，就真是我的门徒；"},
{volumn:        43,chapter:         8,versenumber:        32,verse:"你们必晓得真理，真理必叫你们得以自由。”"},
{volumn:        43,chapter:         8,versenumber:        33,verse:"他们回答说：“我们是亚伯拉罕的后裔，从来没有作过谁的奴仆。你怎么说‘你们必得以自由’呢？”"},
{volumn:        43,chapter:         8,versenumber:        34,verse:"耶稣回答说：“我实实在在地告诉你们，所有犯罪的就是罪的奴仆。"},
{volumn:        43,chapter:         8,versenumber:        35,verse:"奴仆不能永远住在家里；儿子是永远住在家里。"},
{volumn:        43,chapter:         8,versenumber:        36,verse:"所以天父的儿子若叫你们自由，你们就真自由了。"},
{volumn:        43,chapter:         8,versenumber:        37,verse:"我知道你们是亚伯拉罕的子孙，你们却想要杀我，因为你们心里容不下我的道。"},
{volumn:        43,chapter:         8,versenumber:        38,verse:"我所说的是在我父那里看见的；你们所行的是在你们的父那里听见的。”"},
{volumn:        43,chapter:         8,versenumber:        39,verse:"他们说：“我们的父就是亚伯拉罕。”耶稣说：“你们若是亚伯拉罕的儿子，就必行亚伯拉罕所行的事。"},
{volumn:        43,chapter:         8,versenumber:        40,verse:"我将在　神那里所听见的真理告诉了你们，现在你们却想要杀我，这不是亚伯拉罕所行的事。"},
{volumn:        43,chapter:         8,versenumber:        41,verse:"你们是行你们父所行的事。”他们说：“我们不是从淫乱生的；我们只有一位父，就是　神。”"},
{volumn:        43,chapter:         8,versenumber:        42,verse:"耶稣说：“倘若　神是你们的父，你们就必爱我；因为我本是出于　神，也是从　神而来，并不是由着自己来，乃是他差我来。"},
{volumn:        43,chapter:         8,versenumber:        43,verse:"你们为什么不明白我的话呢？无非是因你们不能听我的道。"},
{volumn:        43,chapter:         8,versenumber:        44,verse:"你们是出于你们的父魔鬼，你们父的私欲你们偏要行。他从起初是杀人的，不守真理，因他心里没有真理。他说谎是出于自己；因他本来是说谎的，也是说谎之人的父。"},
{volumn:        43,chapter:         8,versenumber:        45,verse:"我将真理告诉你们，你们就因此不信我。"},
{volumn:        43,chapter:         8,versenumber:        46,verse:"你们中间谁能指证我有罪呢？我既然将真理告诉你们，为什么不信我呢？"},
{volumn:        43,chapter:         8,versenumber:        47,verse:"出于　神的，必听　神的话；你们不听，因为你们不是出于神。”"},
{volumn:        43,chapter:         8,versenumber:        48,verse:"犹太人回答说：“我们说你是撒玛利亚人，并且是鬼附着的，这话岂不正对吗？”"},
{volumn:        43,chapter:         8,versenumber:        49,verse:"耶稣说：“我不是鬼附着的；我尊敬我的父，你们倒轻慢我。"},
{volumn:        43,chapter:         8,versenumber:        50,verse:"我不求自己的荣耀，有一位为我求荣耀、定是非的。"},
{volumn:        43,chapter:         8,versenumber:        51,verse:"我实实在在地告诉你们，人若遵守我的道，就永远不见死。”"},
{volumn:        43,chapter:         8,versenumber:        52,verse:"犹太人对他说：“现在我们知道你是鬼附着的。亚伯拉罕死了，众先知也死了，你还说：‘人若遵守我的道，就永远不尝死味。’"},
{volumn:        43,chapter:         8,versenumber:        53,verse:"难道你比我们的祖宗亚伯拉罕还大吗？他死了，众先知也死了，你将自己当作什么人呢？”"},
{volumn:        43,chapter:         8,versenumber:        54,verse:"耶稣回答说：“我若荣耀自己，我的荣耀就算不得什么；荣耀我的乃是我的父，就是你们所说是你们的　神。"},
{volumn:        43,chapter:         8,versenumber:        55,verse:"你们未曾认识他；我却认识他。我若说不认识他，我就是说谎的，像你们一样；但我认识他，也遵守他的道。"},
{volumn:        43,chapter:         8,versenumber:        56,verse:"你们的祖宗亚伯拉罕欢欢喜喜地仰望我的日子，既看见了就快乐。”"},
{volumn:        43,chapter:         8,versenumber:        57,verse:"犹太人说：“你还没有五十岁，岂见过亚伯拉罕呢？”"},
{volumn:        43,chapter:         8,versenumber:        58,verse:"耶稣说：“我实实在在地告诉你们，还没有亚伯拉罕就有了我。”"},
{volumn:        43,chapter:         8,versenumber:        59,verse:"于是他们拿石头要打他；耶稣却躲藏，从殿里出去了。"},
{volumn:        43,chapter:         9,versenumber:         1,verse:"耶稣过去的时候，看见一个人生来是瞎眼的。"},
{volumn:        43,chapter:         9,versenumber:         2,verse:"门徒问耶稣说：“拉比，这人生来是瞎眼的，是谁犯了罪？是这人呢？是他父母呢？”"},
{volumn:        43,chapter:         9,versenumber:         3,verse:"耶稣回答说：“也不是这人犯了罪，也不是他父母犯了罪，是要在他身上显出　神的作为来。"},
{volumn:        43,chapter:         9,versenumber:         4,verse:"趁着白日，我们必须做那差我来者的工；黑夜将到，就没有人能做工了。"},
{volumn:        43,chapter:         9,versenumber:         5,verse:"我在世上的时候，是世上的光。”"},
{volumn:        43,chapter:         9,versenumber:         6,verse:"耶稣说了这话，就吐唾沫在地上，用唾沫和泥抹在瞎子的眼睛上，"},
{volumn:        43,chapter:         9,versenumber:         7,verse:"对他说：“你往西罗亚池子里去洗。”（西罗亚翻出来就是“奉差遣”。）他去一洗，回头就看见了。"},
{volumn:        43,chapter:         9,versenumber:         8,verse:"他的邻舍和那素常见他是讨饭的，就说：“这不是那从前坐着讨饭的人吗？”"},
{volumn:        43,chapter:         9,versenumber:         9,verse:"有人说：“是他”；又有人说：“不是，却是像他。”他自己说：“是我。”"},
{volumn:        43,chapter:         9,versenumber:        10,verse:"他们对他说：“你的眼睛是怎么开的呢？”"},
{volumn:        43,chapter:         9,versenumber:        11,verse:"他回答说：“有一个人，名叫耶稣，他和泥抹我的眼睛，对我说：‘你往西罗亚池子去洗。’我去一洗，就看见了。”"},
{volumn:        43,chapter:         9,versenumber:        12,verse:"他们说：“那个人在哪里？”他说：“我不知道。”"},
{volumn:        43,chapter:         9,versenumber:        13,verse:"他们把从前瞎眼的人带到法利赛人那里。"},
{volumn:        43,chapter:         9,versenumber:        14,verse:"耶稣和泥开他眼睛的日子是安息日。"},
{volumn:        43,chapter:         9,versenumber:        15,verse:"法利赛人也问他是怎么得看见的。瞎子对他们说：“他把泥抹在我的眼睛上，我去一洗，就看见了。”"},
{volumn:        43,chapter:         9,versenumber:        16,verse:"法利赛人中有的说：“这个人不是从　神来的，因为他不守安息日。”又有人说：“一个罪人怎能行这样的神迹呢？”他们就起了纷争。"},
{volumn:        43,chapter:         9,versenumber:        17,verse:"他们又对瞎子说：“他既然开了你的眼睛，你说他是怎样的人呢？”他说：“是个先知。”"},
{volumn:        43,chapter:         9,versenumber:        18,verse:"犹太人不信他从前是瞎眼，后来能看见的，等到叫了他的父母来，"},
{volumn:        43,chapter:         9,versenumber:        19,verse:"问他们说：“这是你们的儿子吗？你们说他生来是瞎眼的，如今怎么能看见了呢？”"},
{volumn:        43,chapter:         9,versenumber:        20,verse:"他父母回答说：“他是我们的儿子，生来就瞎眼，这是我们知道的。"},
{volumn:        43,chapter:         9,versenumber:        21,verse:"至于他如今怎么能看见，我们却不知道；是谁开了他的眼睛，我们也不知道。他已经成了人，你们问他吧，他自己必能说。”"},
{volumn:        43,chapter:         9,versenumber:        22,verse:"他父母说这话，是怕犹太人；因为犹太人已经商议定了，若有认耶稣是基督的，要把他赶出会堂。"},
{volumn:        43,chapter:         9,versenumber:        23,verse:"因此他父母说：“他已经成了人，你们问他吧。”"},
{volumn:        43,chapter:         9,versenumber:        24,verse:"所以法利赛人第二次叫了那从前瞎眼的人来，对他说：“你该将荣耀归给　神，我们知道这人是个罪人。”"},
{volumn:        43,chapter:         9,versenumber:        25,verse:"他说：“他是个罪人不是，我不知道；有一件事我知道，从前我是眼瞎的，如今能看见了。”"},
{volumn:        43,chapter:         9,versenumber:        26,verse:"他们就问他说：“他向你做什么？是怎么开了你的眼睛呢？”"},
{volumn:        43,chapter:         9,versenumber:        27,verse:"他回答说：“我方才告诉你们，你们不听，为什么又要听呢？莫非你们也要作他的门徒吗？”"},
{volumn:        43,chapter:         9,versenumber:        28,verse:"他们就骂他说：“你是他的门徒；我们是摩西的门徒。"},
{volumn:        43,chapter:         9,versenumber:        29,verse:"神对摩西说话是我们知道的；只是这个人，我们不知道他从哪里来！”"},
{volumn:        43,chapter:         9,versenumber:        30,verse:"那人回答说：“他开了我的眼睛，你们竟不知道他从哪里来，这真是奇怪！"},
{volumn:        43,chapter:         9,versenumber:        31,verse:"我们知道　神不听罪人，惟有敬奉　神、遵行他旨意的，　神才听他。"},
{volumn:        43,chapter:         9,versenumber:        32,verse:"从创世以来，未曾听见有人把生来是瞎子的眼睛开了。"},
{volumn:        43,chapter:         9,versenumber:        33,verse:"这人若不是从　神来的，什么也不能做。”"},
{volumn:        43,chapter:         9,versenumber:        34,verse:"他们回答说：“你全然生在罪孽中，还要教训我们吗？”于是把他赶出去了。"},
{volumn:        43,chapter:         9,versenumber:        35,verse:"耶稣听说他们把他赶出去，后来遇见他，就说：“你信　神的儿子吗？”"},
{volumn:        43,chapter:         9,versenumber:        36,verse:"他回答说：“主啊，谁是　神的儿子，叫我信他呢？”"},
{volumn:        43,chapter:         9,versenumber:        37,verse:"耶稣说：“你已经看见他，现在和你说话的就是他。”"},
{volumn:        43,chapter:         9,versenumber:        38,verse:"他说：“主啊，我信！”就拜耶稣。"},
{volumn:        43,chapter:         9,versenumber:        39,verse:"耶稣说：“我为审判到这世上来，叫不能看见的，可以看见；能看见的，反瞎了眼。”"},
{volumn:        43,chapter:         9,versenumber:        40,verse:"同他在那里的法利赛人听见这话，就说：“难道我们也瞎了眼吗？”"},
{volumn:        43,chapter:         9,versenumber:        41,verse:"耶稣对他们说：“你们若瞎了眼，就没有罪了；但如今你们说‘我们能看见’，所以你们的罪还在。”"},
{volumn:        43,chapter:        10,versenumber:         1,verse:"“我实实在在地告诉你们，人进羊圈，不从门进去，倒从别处爬进去，那人就是贼，就是强盗。"},
{volumn:        43,chapter:        10,versenumber:         2,verse:"从门进去的，才是羊的牧人。"},
{volumn:        43,chapter:        10,versenumber:         3,verse:"看门的就给他开门；羊也听他的声音。他按着名叫自己的羊，把羊领出来。"},
{volumn:        43,chapter:        10,versenumber:         4,verse:"既放出自己的羊来，就在前头走，羊也跟着他，因为认得他的声音。"},
{volumn:        43,chapter:        10,versenumber:         5,verse:"羊不跟着生人；因为不认得他的声音，必要逃跑。”"},
{volumn:        43,chapter:        10,versenumber:         6,verse:"耶稣将这比喻告诉他们，但他们不明白所说的是什么意思。"},
{volumn:        43,chapter:        10,versenumber:         7,verse:"所以，耶稣又对他们说：“我实实在在地告诉你们，我就是羊的门。"},
{volumn:        43,chapter:        10,versenumber:         8,verse:"凡在我以先来的都是贼，是强盗；羊却不听他们。"},
{volumn:        43,chapter:        10,versenumber:         9,verse:"我就是门；凡从我进来的，必然得救，并且出入得草吃。"},
{volumn:        43,chapter:        10,versenumber:        10,verse:"盗贼来，无非要偷窃，杀害，毁坏；我来了，是要叫羊（或译：人）得生命，并且得的更丰盛。"},
{volumn:        43,chapter:        10,versenumber:        11,verse:"我是好牧人；好牧人为羊舍命。"},
{volumn:        43,chapter:        10,versenumber:        12,verse:"若是雇工，不是牧人，羊也不是他自己的，他看见狼来，就撇下羊逃走；狼抓住羊，赶散了羊群。"},
{volumn:        43,chapter:        10,versenumber:        13,verse:"雇工逃走，因他是雇工，并不顾念羊。"},
{volumn:        43,chapter:        10,versenumber:        14,verse:"我是好牧人；我认识我的羊，我的羊也认识我，"},
{volumn:        43,chapter:        10,versenumber:        15,verse:"正如父认识我，我也认识父一样；并且我为羊舍命。"},
{volumn:        43,chapter:        10,versenumber:        16,verse:"我另外有羊，不是这圈里的；我必须领他们来，他们也要听我的声音，并且要合成一群，归一个牧人了。"},
{volumn:        43,chapter:        10,versenumber:        17,verse:"我父爱我；因我将命舍去，好再取回来。"},
{volumn:        43,chapter:        10,versenumber:        18,verse:"没有人夺我的命去，是我自己舍的。我有权柄舍了，也有权柄取回来。这是我从我父所受的命令。”"},
{volumn:        43,chapter:        10,versenumber:        19,verse:"犹太人为这些话又起了纷争。"},
{volumn:        43,chapter:        10,versenumber:        20,verse:"内中有好些人说：“他是被鬼附着，而且疯了，为什么听他呢？”"},
{volumn:        43,chapter:        10,versenumber:        21,verse:"又有人说：“这不是鬼附之人所说的话。鬼岂能叫瞎子的眼睛开了呢？”"},
{volumn:        43,chapter:        10,versenumber:        22,verse:"在耶路撒冷有修殿节，是冬天的时候。"},
{volumn:        43,chapter:        10,versenumber:        23,verse:"耶稣在殿里所罗门的廊下行走。"},
{volumn:        43,chapter:        10,versenumber:        24,verse:"犹太人围着他，说：“你叫我们犹疑不定到几时呢？你若是基督，就明明地告诉我们。”"},
{volumn:        43,chapter:        10,versenumber:        25,verse:"耶稣回答说：“我已经告诉你们，你们不信。我奉我父之名所行的事可以为我作见证；"},
{volumn:        43,chapter:        10,versenumber:        26,verse:"只是你们不信，因为你们不是我的羊。"},
{volumn:        43,chapter:        10,versenumber:        27,verse:"我的羊听我的声音，我也认识他们，他们也跟着我。"},
{volumn:        43,chapter:        10,versenumber:        28,verse:"我又赐给他们永生；他们永不灭亡，谁也不能从我手里把他们夺去。"},
{volumn:        43,chapter:        10,versenumber:        29,verse:"我父把羊赐给我，他比万有都大，谁也不能从我父手里把他们夺去。"},
{volumn:        43,chapter:        10,versenumber:        30,verse:"我与父原为一。”"},
{volumn:        43,chapter:        10,versenumber:        31,verse:"犹太人又拿起石头来要打他。"},
{volumn:        43,chapter:        10,versenumber:        32,verse:"耶稣对他们说：“我从父显出许多善事给你们看，你们是为哪一件拿石头打我呢？”"},
{volumn:        43,chapter:        10,versenumber:        33,verse:"犹太人回答说：“我们不是为善事拿石头打你，是为你说僭妄的话；又为你是个人，反将自己当作　神。”"},
{volumn:        43,chapter:        10,versenumber:        34,verse:"耶稣说：“你们的律法上岂不是写着‘我曾说你们是神’吗？"},
{volumn:        43,chapter:        10,versenumber:        35,verse:"经上的话是不能废的；若那些承受　神道的人尚且称为神，"},
{volumn:        43,chapter:        10,versenumber:        36,verse:"父所分别为圣、又差到世间来的，他自称是　神的儿子，你们还向他说‘你说僭妄的话’吗？"},
{volumn:        43,chapter:        10,versenumber:        37,verse:"我若不行我父的事，你们就不必信我；"},
{volumn:        43,chapter:        10,versenumber:        38,verse:"我若行了，你们纵然不信我，也当信这些事，叫你们又知道又明白父在我里面，我也在父里面。”"},
{volumn:        43,chapter:        10,versenumber:        39,verse:"他们又要拿他，他却逃出他们的手走了。"},
{volumn:        43,chapter:        10,versenumber:        40,verse:"耶稣又往约旦河外去，到了约翰起初施洗的地方，就住在那里。"},
{volumn:        43,chapter:        10,versenumber:        41,verse:"有许多人来到他那里。他们说：“约翰一件神迹没有行过，但约翰指着这人所说的一切话都是真的。”"},
{volumn:        43,chapter:        10,versenumber:        42,verse:"在那里，信耶稣的人就多了。"},
{volumn:        43,chapter:        11,versenumber:         1,verse:"有一个患病的人，名叫拉撒路，住在伯大尼，就是马利亚和她姐姐马大的村庄。"},
{volumn:        43,chapter:        11,versenumber:         2,verse:"这马利亚就是那用香膏抹主，又用头发擦他脚的；患病的拉撒路是她的兄弟。"},
{volumn:        43,chapter:        11,versenumber:         3,verse:"她姐妹两个就打发人去见耶稣，说：“主啊，你所爱的人病了。”"},
{volumn:        43,chapter:        11,versenumber:         4,verse:"耶稣听见，就说：“这病不至于死，乃是为　神的荣耀，叫　神的儿子因此得荣耀。”"},
{volumn:        43,chapter:        11,versenumber:         5,verse:"耶稣素来爱马大和她妹子并拉撒路。"},
{volumn:        43,chapter:        11,versenumber:         6,verse:"听见拉撒路病了，就在所居之地仍住了两天。"},
{volumn:        43,chapter:        11,versenumber:         7,verse:"然后对门徒说：“我们再往犹太去吧。”"},
{volumn:        43,chapter:        11,versenumber:         8,verse:"门徒说：“拉比，犹太人近来要拿石头打你，你还往那里去吗？”"},
{volumn:        43,chapter:        11,versenumber:         9,verse:"耶稣回答说：“白日不是有十二小时吗？人在白日走路，就不至跌倒，因为看见这世上的光。"},
{volumn:        43,chapter:        11,versenumber:        10,verse:"若在黑夜走路，就必跌倒，因为他没有光。”"},
{volumn:        43,chapter:        11,versenumber:        11,verse:"耶稣说了这话，随后对他们说：“我们的朋友拉撒路睡了，我去叫醒他。”"},
{volumn:        43,chapter:        11,versenumber:        12,verse:"门徒说：“主啊，他若睡了，就必好了。”"},
{volumn:        43,chapter:        11,versenumber:        13,verse:"耶稣这话是指着他死说的，他们却以为是说照常睡了。"},
{volumn:        43,chapter:        11,versenumber:        14,verse:"耶稣就明明地告诉他们说：“拉撒路死了。"},
{volumn:        43,chapter:        11,versenumber:        15,verse:"我没有在那里就欢喜，这是为你们的缘故，好叫你们相信。如今我们可以往他那里去吧。”"},
{volumn:        43,chapter:        11,versenumber:        16,verse:"多马，又称为低土马，就对那同作门徒的说：“我们也去和他同死吧。”"},
{volumn:        43,chapter:        11,versenumber:        17,verse:"耶稣到了，就知道拉撒路在坟墓里已经四天了。"},
{volumn:        43,chapter:        11,versenumber:        18,verse:"伯大尼离耶路撒冷不远，约有六里路。"},
{volumn:        43,chapter:        11,versenumber:        19,verse:"有好些犹太人来看马大和马利亚，要为她们的兄弟安慰她们。"},
{volumn:        43,chapter:        11,versenumber:        20,verse:"马大听见耶稣来了，就出去迎接他；马利亚却仍然坐在家里。"},
{volumn:        43,chapter:        11,versenumber:        21,verse:"马大对耶稣说：“主啊，你若早在这里，我兄弟必不死。"},
{volumn:        43,chapter:        11,versenumber:        22,verse:"就是现在，我也知道，你无论向神求什么，　神也必赐给你。”"},
{volumn:        43,chapter:        11,versenumber:        23,verse:"耶稣说：“你兄弟必然复活。”"},
{volumn:        43,chapter:        11,versenumber:        24,verse:"马大说：“我知道在末日复活的时候，他必复活。”"},
{volumn:        43,chapter:        11,versenumber:        25,verse:"耶稣对她说：“复活在我，生命也在我。信我的人虽然死了，也必复活；"},
{volumn:        43,chapter:        11,versenumber:        26,verse:"凡活着信我的人必永远不死。你信这话吗？”"},
{volumn:        43,chapter:        11,versenumber:        27,verse:"马大说：“主啊，是的，我信你是基督，是　神的儿子，就是那要临到世界的。”"},
{volumn:        43,chapter:        11,versenumber:        28,verse:"马大说了这话，就回去暗暗地叫她妹子马利亚，说：“夫子来了，叫你。”"},
{volumn:        43,chapter:        11,versenumber:        29,verse:"马利亚听见了，就急忙起来，到耶稣那里去。"},
{volumn:        43,chapter:        11,versenumber:        30,verse:"那时，耶稣还没有进村子，仍在马大迎接他的地方。"},
{volumn:        43,chapter:        11,versenumber:        31,verse:"那些同马利亚在家里安慰她的犹太人，见她急忙起来出去，就跟着她，以为她要往坟墓那里去哭。"},
{volumn:        43,chapter:        11,versenumber:        32,verse:"马利亚到了耶稣那里，看见他，就俯伏在他脚前，说：“主啊，你若早在这里，我兄弟必不死。”"},
{volumn:        43,chapter:        11,versenumber:        33,verse:"耶稣看见她哭，并看见与她同来的犹太人也哭，就心里悲叹，又甚忧愁，"},
{volumn:        43,chapter:        11,versenumber:        34,verse:"便说：“你们把他安放在哪里？”他们回答说：“请主来看。”"},
{volumn:        43,chapter:        11,versenumber:        35,verse:"耶稣哭了。"},
{volumn:        43,chapter:        11,versenumber:        36,verse:"犹太人就说：“你看他爱这人是何等恳切。”"},
{volumn:        43,chapter:        11,versenumber:        37,verse:"其中有人说：“他既然开了瞎子的眼睛，岂不能叫这人不死吗？”"},
{volumn:        43,chapter:        11,versenumber:        38,verse:"耶稣又心里悲叹，来到坟墓前；那坟墓是个洞，有一块石头挡着。"},
{volumn:        43,chapter:        11,versenumber:        39,verse:"耶稣说：“你们把石头挪开。”那死人的姐姐马大对他说：“主啊，他现在必是臭了，因为他死了已经四天了。”"},
{volumn:        43,chapter:        11,versenumber:        40,verse:"耶稣说：“我不是对你说过，你若信，就必看见　神的荣耀吗？”"},
{volumn:        43,chapter:        11,versenumber:        41,verse:"他们就把石头挪开。耶稣举目望天，说：“父啊，我感谢你，因为你已经听我。"},
{volumn:        43,chapter:        11,versenumber:        42,verse:"我也知道你常听我，但我说这话是为周围站着的众人，叫他们信是你差了我来。”"},
{volumn:        43,chapter:        11,versenumber:        43,verse:"说了这话，就大声呼叫说：“拉撒路出来！”"},
{volumn:        43,chapter:        11,versenumber:        44,verse:"那死人就出来了，手脚裹着布，脸上包着手巾。耶稣对他们说：“解开，叫他走！”"},
{volumn:        43,chapter:        11,versenumber:        45,verse:"那些来看马利亚的犹太人见了耶稣所做的事，就多有信他的；"},
{volumn:        43,chapter:        11,versenumber:        46,verse:"但其中也有去见法利赛人的，将耶稣所做的事告诉他们。"},
{volumn:        43,chapter:        11,versenumber:        47,verse:"祭司长和法利赛人聚集公会，说：“这人行好些神迹，我们怎么办呢？"},
{volumn:        43,chapter:        11,versenumber:        48,verse:"若这样由着他，人人都要信他，罗马人也要来夺我们的地土和我们的百姓。”"},
{volumn:        43,chapter:        11,versenumber:        49,verse:"内中有一个人，名叫该亚法，本年作大祭司，对他们说：“你们不知道什么。"},
{volumn:        43,chapter:        11,versenumber:        50,verse:"独不想一个人替百姓死，免得通国灭亡，就是你们的益处。”"},
{volumn:        43,chapter:        11,versenumber:        51,verse:"他这话不是出于自己，是因他本年作大祭司，所以预言耶稣将要替这一国死；"},
{volumn:        43,chapter:        11,versenumber:        52,verse:"也不但替这一国死，并要将　神四散的子民都聚集归一。"},
{volumn:        43,chapter:        11,versenumber:        53,verse:"从那日起，他们就商议要杀耶稣。"},
{volumn:        43,chapter:        11,versenumber:        54,verse:"所以，耶稣不再显然行在犹太人中间，就离开那里往靠近旷野的地方去，到了一座城，名叫以法莲，就在那里和门徒同住。"},
{volumn:        43,chapter:        11,versenumber:        55,verse:"犹太人的逾越节近了，有许多人从乡下上耶路撒冷去，要在节前洁净自己。"},
{volumn:        43,chapter:        11,versenumber:        56,verse:"他们就寻找耶稣，站在殿里彼此说：“你们的意思如何，他不来过节吗？”"},
{volumn:        43,chapter:        11,versenumber:        57,verse:"那时，祭司长和法利赛人早已吩咐说，若有人知道耶稣在哪里，就要报明，好去拿他。"},
{volumn:        43,chapter:        12,versenumber:         1,verse:"逾越节前六日，耶稣来到伯大尼，就是他叫拉撒路从死里复活之处。"},
{volumn:        43,chapter:        12,versenumber:         2,verse:"有人在那里给耶稣预备筵席；马大伺候，拉撒路也在那同耶稣坐席的人中。"},
{volumn:        43,chapter:        12,versenumber:         3,verse:"马利亚就拿着一斤极贵的真哪哒香膏，抹耶稣的脚，又用自己头发去擦，屋里就满了膏的香气。"},
{volumn:        43,chapter:        12,versenumber:         4,verse:"有一个门徒，就是那将要卖耶稣的加略人犹大，"},
{volumn:        43,chapter:        12,versenumber:         5,verse:"说：“这香膏为什么不卖三十两银子周济穷人呢？”"},
{volumn:        43,chapter:        12,versenumber:         6,verse:"他说这话，并不是挂念穷人，乃因他是个贼，又带着钱囊，常取其中所存的。"},
{volumn:        43,chapter:        12,versenumber:         7,verse:"耶稣说：“由她吧！她是为我安葬之日存留的。"},
{volumn:        43,chapter:        12,versenumber:         8,verse:"因为常有穷人和你们同在，只是你们不常有我。”"},
{volumn:        43,chapter:        12,versenumber:         9,verse:"有许多犹太人知道耶稣在那里，就来了，不但是为耶稣的缘故，也是要看他从死里所复活的拉撒路。"},
{volumn:        43,chapter:        12,versenumber:        10,verse:"但祭司长商议连拉撒路也要杀了；"},
{volumn:        43,chapter:        12,versenumber:        11,verse:"因有好些犹太人为拉撒路的缘故，回去信了耶稣。"},
{volumn:        43,chapter:        12,versenumber:        12,verse:"第二天，有许多上来过节的人听见耶稣将到耶路撒冷，"},
{volumn:        43,chapter:        12,versenumber:        13,verse:"就拿着棕树枝出去迎接他，喊着说：“和散那！奉主名来的以色列王是应当称颂的！”"},
{volumn:        43,chapter:        12,versenumber:        14,verse:"耶稣得了一个驴驹，就骑上，如经上所记的说："},
{volumn:        43,chapter:        12,versenumber:        15,verse:"“锡安的民（原文是女子）哪，不要惧怕！你的王骑着驴驹来了。”"},
{volumn:        43,chapter:        12,versenumber:        16,verse:"这些事门徒起先不明白，等到耶稣得了荣耀以后才想起这话是指着他写的，并且众人果然向他这样行了。"},
{volumn:        43,chapter:        12,versenumber:        17,verse:"当耶稣呼唤拉撒路，叫他从死复活出坟墓的时候，同耶稣在那里的众人就作见证。"},
{volumn:        43,chapter:        12,versenumber:        18,verse:"众人因听见耶稣行了这神迹，就去迎接他。"},
{volumn:        43,chapter:        12,versenumber:        19,verse:"法利赛人彼此说：“看哪，你们是徒劳无益，世人都随从他去了。”"},
{volumn:        43,chapter:        12,versenumber:        20,verse:"那时，上来过节礼拜的人中，有几个希腊人。"},
{volumn:        43,chapter:        12,versenumber:        21,verse:"他们来见加利利、伯赛大的腓力，求他说：“先生，我们愿意见耶稣。”"},
{volumn:        43,chapter:        12,versenumber:        22,verse:"腓力去告诉安得烈，安得烈同腓力去告诉耶稣。"},
{volumn:        43,chapter:        12,versenumber:        23,verse:"耶稣说：“人子得荣耀的时候到了。"},
{volumn:        43,chapter:        12,versenumber:        24,verse:"我实实在在地告诉你们，一粒麦子不落在地里死了，仍旧是一粒，若是死了，就结出许多子粒来。"},
{volumn:        43,chapter:        12,versenumber:        25,verse:"爱惜自己生命的，就失丧生命；在这世上恨恶自己生命的，就要保守生命到永生。"},
{volumn:        43,chapter:        12,versenumber:        26,verse:"若有人服侍我，就当跟从我；我在哪里，服侍我的人也要在那里；若有人服侍我，我父必尊重他。”"},
{volumn:        43,chapter:        12,versenumber:        27,verse:"“我现在心里忧愁，我说什么才好呢？父啊，救我脱离这时候；但我原是为这时候来的。"},
{volumn:        43,chapter:        12,versenumber:        28,verse:"父啊，愿你荣耀你的名！”当时就有声音从天上来，说：“我已经荣耀了我的名，还要再荣耀。”"},
{volumn:        43,chapter:        12,versenumber:        29,verse:"站在旁边的众人听见，就说：“打雷了。”还有人说：“有天使对他说话。”"},
{volumn:        43,chapter:        12,versenumber:        30,verse:"耶稣说：“这声音不是为我，是为你们来的。"},
{volumn:        43,chapter:        12,versenumber:        31,verse:"现在这世界受审判，这世界的王要被赶出去。"},
{volumn:        43,chapter:        12,versenumber:        32,verse:"我若从地上被举起来，就要吸引万人来归我。”"},
{volumn:        43,chapter:        12,versenumber:        33,verse:"耶稣这话原是指着自己将要怎样死说的。"},
{volumn:        43,chapter:        12,versenumber:        34,verse:"众人回答说：“我们听见律法上有话说，基督是永存的，你怎么说‘人子必须被举起来’呢？这人子是谁呢？”"},
{volumn:        43,chapter:        12,versenumber:        35,verse:"耶稣对他们说：“光在你们中间还有不多的时候，应当趁着有光行走，免得黑暗临到你们；那在黑暗里行走的，不知道往何处去。"},
{volumn:        43,chapter:        12,versenumber:        36,verse:"你们应当趁着有光，信从这光，使你们成为光明之子。”耶稣说了这话，就离开他们，隐藏了。"},
{volumn:        43,chapter:        12,versenumber:        37,verse:"他虽然在他们面前行了许多神迹，他们还是不信他。"},
{volumn:        43,chapter:        12,versenumber:        38,verse:"这是要应验先知以赛亚的话，说：“主啊，我们所传的有谁信呢？主的膀臂向谁显露呢？”"},
{volumn:        43,chapter:        12,versenumber:        39,verse:"他们所以不能信，因为以赛亚又说："},
{volumn:        43,chapter:        12,versenumber:        40,verse:"“主叫他们瞎了眼，硬了心，免得他们眼睛看见，心里明白，回转过来，我就医治他们。”"},
{volumn:        43,chapter:        12,versenumber:        41,verse:"以赛亚因为看见他的荣耀，就指着他说这话。"},
{volumn:        43,chapter:        12,versenumber:        42,verse:"虽然如此，官长中却有好些信他的，只因法利赛人的缘故，就不承认，恐怕被赶出会堂。"},
{volumn:        43,chapter:        12,versenumber:        43,verse:"这是因他们爱人的荣耀过于爱　神的荣耀。"},
{volumn:        43,chapter:        12,versenumber:        44,verse:"耶稣大声说：“信我的，不是信我，乃是信那差我来的。"},
{volumn:        43,chapter:        12,versenumber:        45,verse:"人看见我，就是看见那差我来的。"},
{volumn:        43,chapter:        12,versenumber:        46,verse:"我到世上来，乃是光，叫凡信我的，不住在黑暗里。"},
{volumn:        43,chapter:        12,versenumber:        47,verse:"若有人听见我的话不遵守，我不审判他。我来本不是要审判世界，乃是要拯救世界。"},
{volumn:        43,chapter:        12,versenumber:        48,verse:"弃绝我、不领受我话的人，有审判他的，就是我所讲的道在末日要审判他。"},
{volumn:        43,chapter:        12,versenumber:        49,verse:"因为我没有凭着自己讲，惟有差我来的父已经给我命令，叫我说什么，讲什么。"},
{volumn:        43,chapter:        12,versenumber:        50,verse:"我也知道他的命令就是永生。故此，我所讲的话正是照着父对我所说的。”"},
{volumn:        43,chapter:        13,versenumber:         1,verse:"逾越节以前，耶稣知道自己离世归父的时候到了。他既然爱世间属自己的人，就爱他们到底。"},
{volumn:        43,chapter:        13,versenumber:         2,verse:"吃晚饭的时候，魔鬼已将卖耶稣的意思放在西门的儿子加略人犹大心里。"},
{volumn:        43,chapter:        13,versenumber:         3,verse:"耶稣知道父已将万有交在他手里，且知道自己是从　神出来的，又要归到　神那里去，"},
{volumn:        43,chapter:        13,versenumber:         4,verse:"就离席站起来，脱了衣服，拿一条手巾束腰，"},
{volumn:        43,chapter:        13,versenumber:         5,verse:"随后把水倒在盆里，就洗门徒的脚，并用自己所束的手巾擦干。"},
{volumn:        43,chapter:        13,versenumber:         6,verse:"挨到西门·彼得，彼得对他说：“主啊，你洗我的脚吗？”"},
{volumn:        43,chapter:        13,versenumber:         7,verse:"耶稣回答说：“我所做的，你如今不知道，后来必明白。”"},
{volumn:        43,chapter:        13,versenumber:         8,verse:"彼得说：“你永不可洗我的脚！”耶稣说：“我若不洗你，你就与我无份了。”"},
{volumn:        43,chapter:        13,versenumber:         9,verse:"西门·彼得说：“主啊，不但我的脚，连手和头也要洗。”"},
{volumn:        43,chapter:        13,versenumber:        10,verse:"耶稣说：“凡洗过澡的人，只要把脚一洗，全身就干净了。你们是干净的，然而不都是干净的。”"},
{volumn:        43,chapter:        13,versenumber:        11,verse:"耶稣原知道要卖他的是谁，所以说：“你们不都是干净的。”"},
{volumn:        43,chapter:        13,versenumber:        12,verse:"耶稣洗完了他们的脚，就穿上衣服，又坐下，对他们说：“我向你们所做的，你们明白吗？"},
{volumn:        43,chapter:        13,versenumber:        13,verse:"你们称呼我夫子，称呼我主，你们说的不错，我本来是。"},
{volumn:        43,chapter:        13,versenumber:        14,verse:"我是你们的主，你们的夫子，尚且洗你们的脚，你们也当彼此洗脚。"},
{volumn:        43,chapter:        13,versenumber:        15,verse:"我给你们作了榜样，叫你们照着我向你们所做的去做。"},
{volumn:        43,chapter:        13,versenumber:        16,verse:"我实实在在地告诉你们，仆人不能大于主人，差人也不能大于差他的人。"},
{volumn:        43,chapter:        13,versenumber:        17,verse:"你们既知道这事，若是去行就有福了。"},
{volumn:        43,chapter:        13,versenumber:        18,verse:"我这话不是指着你们众人说的，我知道我所拣选的是谁。现在要应验经上的话，说：‘同我吃饭的人用脚踢我。’"},
{volumn:        43,chapter:        13,versenumber:        19,verse:"如今事情还没有成就，我要先告诉你们，叫你们到事情成就的时候可以信我是基督。"},
{volumn:        43,chapter:        13,versenumber:        20,verse:"我实实在在地告诉你们，有人接待我所差遣的，就是接待我；接待我，就是接待那差遣我的。”"},
{volumn:        43,chapter:        13,versenumber:        21,verse:"耶稣说了这话，心里忧愁，就明说：“我实实在在地告诉你们，你们中间有一个人要卖我了。”"},
{volumn:        43,chapter:        13,versenumber:        22,verse:"门徒彼此对看，猜不透所说的是谁。"},
{volumn:        43,chapter:        13,versenumber:        23,verse:"有一个门徒，是耶稣所爱的，侧身挨近耶稣的怀里。"},
{volumn:        43,chapter:        13,versenumber:        24,verse:"西门·彼得点头对他说：“你告诉我们，主是指着谁说的。”"},
{volumn:        43,chapter:        13,versenumber:        25,verse:"那门徒便就势靠着耶稣的胸膛，问他说：“主啊，是谁呢？”"},
{volumn:        43,chapter:        13,versenumber:        26,verse:"耶稣回答说：“我蘸一点饼给谁，就是谁。”耶稣就蘸了一点饼，递给加略人西门的儿子犹大。"},
{volumn:        43,chapter:        13,versenumber:        27,verse:"他吃了以后，撒但就入了他的心。耶稣便对他说：“你所做的，快做吧！”"},
{volumn:        43,chapter:        13,versenumber:        28,verse:"同席的人没有一个知道是为什么对他说这话。"},
{volumn:        43,chapter:        13,versenumber:        29,verse:"有人因犹大带着钱囊，以为耶稣是对他说：“你去买我们过节所应用的东西”，或是叫他拿什么周济穷人。"},
{volumn:        43,chapter:        13,versenumber:        30,verse:"犹大受了那点饼，立刻就出去。那时候是夜间了。"},
{volumn:        43,chapter:        13,versenumber:        31,verse:"他既出去，耶稣就说：“如今人子得了荣耀，　神在人子身上也得了荣耀。"},
{volumn:        43,chapter:        13,versenumber:        32,verse:"神要因自己荣耀人子，并且要快快地荣耀他。"},
{volumn:        43,chapter:        13,versenumber:        33,verse:"小子们，我还有不多的时候与你们同在；后来你们要找我，但我所去的地方你们不能到。这话我曾对犹太人说过，如今也照样对你们说。"},
{volumn:        43,chapter:        13,versenumber:        34,verse:"我赐给你们一条新命令，乃是叫你们彼此相爱；我怎样爱你们，你们也要怎样相爱。"},
{volumn:        43,chapter:        13,versenumber:        35,verse:"你们若有彼此相爱的心，众人因此就认出你们是我的门徒了。”"},
{volumn:        43,chapter:        13,versenumber:        36,verse:"西门·彼得问耶稣说：“主往哪里去？”耶稣回答说：“我所去的地方，你现在不能跟我去，后来却要跟我去。”"},
{volumn:        43,chapter:        13,versenumber:        37,verse:"彼得说：“主啊，我为什么现在不能跟你去？我愿意为你舍命！”"},
{volumn:        43,chapter:        13,versenumber:        38,verse:"耶稣说：“你愿意为我舍命吗？我实实在在地告诉你，鸡叫以先，你要三次不认我。”"},
{volumn:        43,chapter:        14,versenumber:         1,verse:"“你们心里不要忧愁；你们信　神，也当信我。"},
{volumn:        43,chapter:        14,versenumber:         2,verse:"在我父的家里有许多住处；若是没有，我就早已告诉你们了。我去原是为你们预备地方去。"},
{volumn:        43,chapter:        14,versenumber:         3,verse:"我若去为你们预备了地方，就必再来接你们到我那里去，我在哪里，叫你们也在那里。"},
{volumn:        43,chapter:        14,versenumber:         4,verse:"我往哪里去，你们知道；那条路，你们也知道（有古卷：我往哪里去，你们知道那条路）。”"},
{volumn:        43,chapter:        14,versenumber:         5,verse:"多马对他说：“主啊，我们不知道你往哪里去，怎么知道那条路呢？”"},
{volumn:        43,chapter:        14,versenumber:         6,verse:"耶稣说：“我就是道路、真理、生命；若不藉着我，没有人能到父那里去。"},
{volumn:        43,chapter:        14,versenumber:         7,verse:"你们若认识我，也就认识我的父。从今以后，你们认识他，并且已经看见他。”"},
{volumn:        43,chapter:        14,versenumber:         8,verse:"腓力对他说：“求主将父显给我们看，我们就知足了。”"},
{volumn:        43,chapter:        14,versenumber:         9,verse:"耶稣对他说：“腓力，我与你们同在这样长久，你还不认识我吗？人看见了我，就是看见了父；你怎么说‘将父显给我们看’呢？"},
{volumn:        43,chapter:        14,versenumber:        10,verse:"我在父里面，父在我里面，你不信吗？我对你们所说的话，不是凭着自己说的，乃是住在我里面的父做他自己的事。"},
{volumn:        43,chapter:        14,versenumber:        11,verse:"你们当信我，我在父里面，父在我里面；即或不信，也当因我所做的事信我。"},
{volumn:        43,chapter:        14,versenumber:        12,verse:"我实实在在地告诉你们，我所做的事，信我的人也要做，并且要做比这更大的事，因为我往父那里去。"},
{volumn:        43,chapter:        14,versenumber:        13,verse:"你们奉我的名无论求什么，我必成就，叫父因儿子得荣耀。"},
{volumn:        43,chapter:        14,versenumber:        14,verse:"你们若奉我的名求什么，我必成就。”"},
{volumn:        43,chapter:        14,versenumber:        15,verse:"“你们若爱我，就必遵守我的命令。"},
{volumn:        43,chapter:        14,versenumber:        16,verse:"我要求父，父就另外赐给你们一位保惠师（或译：训慰师；下同），叫他永远与你们同在，"},
{volumn:        43,chapter:        14,versenumber:        17,verse:"就是真理的圣灵，乃世人不能接受的；因为不见他，也不认识他。你们却认识他，因他常与你们同在，也要在你们里面。"},
{volumn:        43,chapter:        14,versenumber:        18,verse:"我不撇下你们为孤儿，我必到你们这里来。"},
{volumn:        43,chapter:        14,versenumber:        19,verse:"还有不多的时候，世人不再看见我，你们却看见我；因为我活着，你们也要活着。"},
{volumn:        43,chapter:        14,versenumber:        20,verse:"到那日，你们就知道我在父里面，你们在我里面，我也在你们里面。"},
{volumn:        43,chapter:        14,versenumber:        21,verse:"有了我的命令又遵守的，这人就是爱我的；爱我的必蒙我父爱他，我也要爱他，并且要向他显现。”"},
{volumn:        43,chapter:        14,versenumber:        22,verse:"犹大（不是加略人犹大）问耶稣说：“主啊，为什么要向我们显现，不向世人显现呢？”"},
{volumn:        43,chapter:        14,versenumber:        23,verse:"耶稣回答说：“人若爱我，就必遵守我的道；我父也必爱他，并且我们要到他那里去，与他同住。"},
{volumn:        43,chapter:        14,versenumber:        24,verse:"不爱我的人就不遵守我的道。你们所听见的道不是我的，乃是差我来之父的道。"},
{volumn:        43,chapter:        14,versenumber:        25,verse:"“我还与你们同住的时候，已将这些话对你们说了。"},
{volumn:        43,chapter:        14,versenumber:        26,verse:"但保惠师，就是父因我的名所要差来的圣灵，他要将一切的事指教你们，并且要叫你们想起我对你们所说的一切话。"},
{volumn:        43,chapter:        14,versenumber:        27,verse:"我留下平安给你们；我将我的平安赐给你们。我所赐的，不像世人所赐的。你们心里不要忧愁，也不要胆怯。"},
{volumn:        43,chapter:        14,versenumber:        28,verse:"你们听见我对你们说了，我去还要到你们这里来。你们若爱我，因我到父那里去，就必喜乐，因为父是比我大的。"},
{volumn:        43,chapter:        14,versenumber:        29,verse:"现在事情还没有成就，我预先告诉你们，叫你们到事情成就的时候就可以信。"},
{volumn:        43,chapter:        14,versenumber:        30,verse:"以后我不再和你们多说话，因为这世界的王将到。他在我里面是毫无所有；"},
{volumn:        43,chapter:        14,versenumber:        31,verse:"但要叫世人知道我爱父，并且父怎样吩咐我，我就怎样行。起来，我们走吧！”"},
{volumn:        43,chapter:        15,versenumber:         1,verse:"“我是真葡萄树，我父是栽培的人。"},
{volumn:        43,chapter:        15,versenumber:         2,verse:"凡属我不结果子的枝子，他就剪去；凡结果子的，他就修理干净，使枝子结果子更多。"},
{volumn:        43,chapter:        15,versenumber:         3,verse:"现在你们因我讲给你们的道，已经干净了。"},
{volumn:        43,chapter:        15,versenumber:         4,verse:"你们要常在我里面，我也常在你们里面。枝子若不常在葡萄树上，自己就不能结果子；你们若不常在我里面，也是这样。"},
{volumn:        43,chapter:        15,versenumber:         5,verse:"我是葡萄树，你们是枝子。常在我里面的，我也常在他里面，这人就多结果子；因为离了我，你们就不能做什么。"},
{volumn:        43,chapter:        15,versenumber:         6,verse:"人若不常在我里面，就像枝子丢在外面枯干，人拾起来，扔在火里烧了。"},
{volumn:        43,chapter:        15,versenumber:         7,verse:"你们若常在我里面，我的话也常在你们里面，凡你们所愿意的，祈求，就给你们成就。"},
{volumn:        43,chapter:        15,versenumber:         8,verse:"你们多结果子，我父就因此得荣耀，你们也就是我的门徒了。"},
{volumn:        43,chapter:        15,versenumber:         9,verse:"我爱你们，正如父爱我一样；你们要常在我的爱里。"},
{volumn:        43,chapter:        15,versenumber:        10,verse:"你们若遵守我的命令，就常在我的爱里，正如我遵守了我父的命令，常在他的爱里。"},
{volumn:        43,chapter:        15,versenumber:        11,verse:"“这些事我已经对你们说了，是要叫我的喜乐存在你们心里，并叫你们的喜乐可以满足。"},
{volumn:        43,chapter:        15,versenumber:        12,verse:"你们要彼此相爱，像我爱你们一样；这就是我的命令。"},
{volumn:        43,chapter:        15,versenumber:        13,verse:"人为朋友舍命，人的爱心没有比这个大的。"},
{volumn:        43,chapter:        15,versenumber:        14,verse:"你们若遵行我所吩咐的，就是我的朋友了。"},
{volumn:        43,chapter:        15,versenumber:        15,verse:"以后我不再称你们为仆人，因仆人不知道主人所做的事。我乃称你们为朋友；因我从我父所听见的，已经都告诉你们了。"},
{volumn:        43,chapter:        15,versenumber:        16,verse:"不是你们拣选了我，是我拣选了你们，并且分派你们去结果子，叫你们的果子常存，使你们奉我的名，无论向父求什么，他就赐给你们。"},
{volumn:        43,chapter:        15,versenumber:        17,verse:"我这样吩咐你们，是要叫你们彼此相爱。”"},
{volumn:        43,chapter:        15,versenumber:        18,verse:"“世人若恨你们，你们知道（或译：该知道），恨你们以先已经恨我了。"},
{volumn:        43,chapter:        15,versenumber:        19,verse:"你们若属世界，世界必爱属自己的；只因你们不属世界，乃是我从世界中拣选了你们，所以世界就恨你们。"},
{volumn:        43,chapter:        15,versenumber:        20,verse:"你们要记念我从前对你们所说的话：‘仆人不能大于主人。’他们若逼迫了我，也要逼迫你们；若遵守了我的话，也要遵守你们的话。"},
{volumn:        43,chapter:        15,versenumber:        21,verse:"但他们因我的名要向你们行这一切的事，因为他们不认识那差我来的。"},
{volumn:        43,chapter:        15,versenumber:        22,verse:"我若没有来教训他们，他们就没有罪；但如今他们的罪无可推诿了。"},
{volumn:        43,chapter:        15,versenumber:        23,verse:"恨我的，也恨我的父。"},
{volumn:        43,chapter:        15,versenumber:        24,verse:"我若没有在他们中间行过别人未曾行的事，他们就没有罪；但如今连我与我的父，他们也看见也恨恶了。"},
{volumn:        43,chapter:        15,versenumber:        25,verse:"这要应验他们律法上所写的话，说：‘他们无故地恨我。’"},
{volumn:        43,chapter:        15,versenumber:        26,verse:"但我要从父那里差保惠师来，就是从父出来真理的圣灵；他来了，就要为我作见证。"},
{volumn:        43,chapter:        15,versenumber:        27,verse:"你们也要作见证，因为你们从起头就与我同在。”"},
{volumn:        43,chapter:        16,versenumber:         1,verse:"“我已将这些事告诉你们，使你们不至于跌倒。"},
{volumn:        43,chapter:        16,versenumber:         2,verse:"人要把你们赶出会堂，并且时候将到，凡杀你们的就以为是侍奉　神。"},
{volumn:        43,chapter:        16,versenumber:         3,verse:"他们这样行，是因未曾认识父，也未曾认识我。"},
{volumn:        43,chapter:        16,versenumber:         4,verse:"我将这事告诉你们，是叫你们到了时候，可以想起我对你们说过了。我起先没有将这事告诉你们，因为我与你们同在。"},
{volumn:        43,chapter:        16,versenumber:         5,verse:"现今我往差我来的父那里去，你们中间并没有人问我：‘你往哪里去？’"},
{volumn:        43,chapter:        16,versenumber:         6,verse:"只因我将这事告诉你们，你们就满心忧愁。"},
{volumn:        43,chapter:        16,versenumber:         7,verse:"然而，我将真情告诉你们，我去是与你们有益的；我若不去，保惠师就不到你们这里来；我若去，就差他来。"},
{volumn:        43,chapter:        16,versenumber:         8,verse:"他既来了，就要叫世人为罪、为义、为审判，自己责备自己。"},
{volumn:        43,chapter:        16,versenumber:         9,verse:"为罪，是因他们不信我；"},
{volumn:        43,chapter:        16,versenumber:        10,verse:"为义，是因我往父那里去，你们就不再见我；"},
{volumn:        43,chapter:        16,versenumber:        11,verse:"为审判，是因这世界的王受了审判。"},
{volumn:        43,chapter:        16,versenumber:        12,verse:"“我还有好些事要告诉你们，但你们现在担当不了（或译：不能领会）。"},
{volumn:        43,chapter:        16,versenumber:        13,verse:"只等真理的圣灵来了，他要引导你们明白（原文是进入）一切的真理；因为他不是凭自己说的，乃是把他所听见的都说出来，并要把将来的事告诉你们。"},
{volumn:        43,chapter:        16,versenumber:        14,verse:"他要荣耀我，因为他要将受于我的告诉你们。"},
{volumn:        43,chapter:        16,versenumber:        15,verse:"凡父所有的，都是我的；所以我说，他要将受于我的告诉你们。”"},
{volumn:        43,chapter:        16,versenumber:        16,verse:"“等不多时，你们就不得见我；再等不多时，你们还要见我。”"},
{volumn:        43,chapter:        16,versenumber:        17,verse:"有几个门徒就彼此说：“他对我们说：‘等不多时，你们就不得见我；再等不多时，你们还要见我’；又说：‘因我往父那里去。’这是什么意思呢？”"},
{volumn:        43,chapter:        16,versenumber:        18,verse:"门徒彼此说：“他说‘等不多时’到底是什么意思呢？我们不明白他所说的话。”"},
{volumn:        43,chapter:        16,versenumber:        19,verse:"耶稣看出他们要问他，就说：“我说‘等不多时，你们就不得见我；再等不多时，你们还要见我’，你们为这话彼此相问吗？"},
{volumn:        43,chapter:        16,versenumber:        20,verse:"我实实在在地告诉你们，你们将要痛哭、哀号，世人倒要喜乐；你们将要忧愁，然而你们的忧愁要变为喜乐。"},
{volumn:        43,chapter:        16,versenumber:        21,verse:"妇人生产的时候就忧愁，因为她的时候到了；既生了孩子，就不再记念那苦楚，因为欢喜世上生了一个人。"},
{volumn:        43,chapter:        16,versenumber:        22,verse:"你们现在也是忧愁，但我要再见你们，你们的心就喜乐了；这喜乐也没有人能夺去。"},
{volumn:        43,chapter:        16,versenumber:        23,verse:"到那日，你们什么也就不问我了。我实实在在地告诉你们，你们若向父求什么，他必因我的名赐给你们。"},
{volumn:        43,chapter:        16,versenumber:        24,verse:"向来你们没有奉我的名求什么，如今你们求，就必得着，叫你们的喜乐可以满足。”"},
{volumn:        43,chapter:        16,versenumber:        25,verse:"“这些事，我是用比喻对你们说的；时候将到，我不再用比喻对你们说，乃要将父明明地告诉你们。"},
{volumn:        43,chapter:        16,versenumber:        26,verse:"到那日，你们要奉我的名祈求；我并不对你们说，我要为你们求父。"},
{volumn:        43,chapter:        16,versenumber:        27,verse:"父自己爱你们；因为你们已经爱我，又信我是从父出来的。"},
{volumn:        43,chapter:        16,versenumber:        28,verse:"我从父出来，到了世界；我又离开世界，往父那里去。”"},
{volumn:        43,chapter:        16,versenumber:        29,verse:"门徒说：“如今你是明说，并不用比喻了。"},
{volumn:        43,chapter:        16,versenumber:        30,verse:"现在我们晓得你凡事都知道，也不用人问你，因此我们信你是从　神出来的。”"},
{volumn:        43,chapter:        16,versenumber:        31,verse:"耶稣说：“现在你们信吗？"},
{volumn:        43,chapter:        16,versenumber:        32,verse:"看哪，时候将到，且是已经到了，你们要分散，各归自己的地方去，留下我独自一人；其实我不是独自一人，因为有父与我同在。"},
{volumn:        43,chapter:        16,versenumber:        33,verse:"我将这些事告诉你们，是要叫你们在我里面有平安。在世上，你们有苦难；但你们可以放心，我已经胜了世界。”"},
{volumn:        43,chapter:        17,versenumber:         1,verse:"耶稣说了这话，就举目望天，说：“父啊，时候到了，愿你荣耀你的儿子，使儿子也荣耀你；"},
{volumn:        43,chapter:        17,versenumber:         2,verse:"正如你曾赐给他权柄管理凡有血气的，叫他将永生赐给你所赐给他的人。"},
{volumn:        43,chapter:        17,versenumber:         3,verse:"认识你独一的真　神，并且认识你所差来的耶稣基督，这就是永生。"},
{volumn:        43,chapter:        17,versenumber:         4,verse:"我在地上已经荣耀你，你所托付我的事，我已成全了。"},
{volumn:        43,chapter:        17,versenumber:         5,verse:"父啊，现在求你使我同你享荣耀，就是未有世界以先，我同你所有的荣耀。"},
{volumn:        43,chapter:        17,versenumber:         6,verse:"“你从世上赐给我的人，我已将你的名显明与他们。他们本是你的，你将他们赐给我，他们也遵守了你的道。"},
{volumn:        43,chapter:        17,versenumber:         7,verse:"如今他们知道，凡你所赐给我的，都是从你那里来的；"},
{volumn:        43,chapter:        17,versenumber:         8,verse:"因为你所赐给我的道，我已经赐给他们，他们也领受了，又确实知道，我是从你出来的，并且信你差了我来。"},
{volumn:        43,chapter:        17,versenumber:         9,verse:"我为他们祈求，不为世人祈求，却为你所赐给我的人祈求，因他们本是你的。"},
{volumn:        43,chapter:        17,versenumber:        10,verse:"凡是我的，都是你的；你的也是我的，并且我因他们得了荣耀。"},
{volumn:        43,chapter:        17,versenumber:        11,verse:"从今以后，我不在世上，他们却在世上；我往你那里去。圣父啊，求你因你所赐给我的名保守他们，叫他们合而为一像我们一样。"},
{volumn:        43,chapter:        17,versenumber:        12,verse:"我与他们同在的时候，因你所赐给我的名保守了他们，我也护卫了他们；其中除了那灭亡之子，没有一个灭亡的，好叫经上的话得应验。"},
{volumn:        43,chapter:        17,versenumber:        13,verse:"现在我往你那里去，我还在世上说这话，是叫他们心里充满我的喜乐。"},
{volumn:        43,chapter:        17,versenumber:        14,verse:"我已将你的道赐给他们。世界又恨他们；因为他们不属世界，正如我不属世界一样。"},
{volumn:        43,chapter:        17,versenumber:        15,verse:"我不求你叫他们离开世界，只求你保守他们脱离那恶者（或译：脱离罪恶）。"},
{volumn:        43,chapter:        17,versenumber:        16,verse:"他们不属世界，正如我不属世界一样。"},
{volumn:        43,chapter:        17,versenumber:        17,verse:"求你用真理使他们成圣；你的道就是真理。"},
{volumn:        43,chapter:        17,versenumber:        18,verse:"你怎样差我到世上，我也照样差他们到世上。"},
{volumn:        43,chapter:        17,versenumber:        19,verse:"我为他们的缘故，自己分别为圣，叫他们也因真理成圣。”"},
{volumn:        43,chapter:        17,versenumber:        20,verse:"“我不但为这些人祈求，也为那些因他们的话信我的人祈求，"},
{volumn:        43,chapter:        17,versenumber:        21,verse:"使他们都合而为一。正如你父在我里面，我在你里面，使他们也在我们里面，叫世人可以信你差了我来。"},
{volumn:        43,chapter:        17,versenumber:        22,verse:"你所赐给我的荣耀，我已赐给他们，使他们合而为一，像我们合而为一。"},
{volumn:        43,chapter:        17,versenumber:        23,verse:"我在他们里面，你在我里面，使他们完完全全地合而为一，叫世人知道你差了我来，也知道你爱他们如同爱我一样。"},
{volumn:        43,chapter:        17,versenumber:        24,verse:"父啊，我在哪里，愿你所赐给我的人也同我在那里，叫他们看见你所赐给我的荣耀；因为创立世界以前，你已经爱我了。"},
{volumn:        43,chapter:        17,versenumber:        25,verse:"公义的父啊，世人未曾认识你，我却认识你；这些人也知道你差了我来。"},
{volumn:        43,chapter:        17,versenumber:        26,verse:"我已将你的名指示他们，还要指示他们，使你所爱我的爱在他们里面，我也在他们里面。”"},
{volumn:        43,chapter:        18,versenumber:         1,verse:"耶稣说了这话，就同门徒出去，过了汲沦溪。在那里有一个园子，他和门徒进去了。"},
{volumn:        43,chapter:        18,versenumber:         2,verse:"卖耶稣的犹大也知道那地方，因为耶稣和门徒屡次上那里去聚集。"},
{volumn:        43,chapter:        18,versenumber:         3,verse:"犹大领了一队兵，和祭司长并法利赛人的差役，拿着灯笼、火把、兵器，就来到园里。"},
{volumn:        43,chapter:        18,versenumber:         4,verse:"耶稣知道将要临到自己的一切事，就出来对他们说：“你们找谁？”"},
{volumn:        43,chapter:        18,versenumber:         5,verse:"他们回答说：“找拿撒勒人耶稣。”耶稣说：“我就是！”卖他的犹大也同他们站在那里。"},
{volumn:        43,chapter:        18,versenumber:         6,verse:"耶稣一说“我就是”，他们就退后倒在地上。"},
{volumn:        43,chapter:        18,versenumber:         7,verse:"他又问他们说：“你们找谁？”他们说：“找拿撒勒人耶稣。”"},
{volumn:        43,chapter:        18,versenumber:         8,verse:"耶稣说：“我已经告诉你们，我就是。你们若找我，就让这些人去吧。”"},
{volumn:        43,chapter:        18,versenumber:         9,verse:"这要应验耶稣从前的话，说：“你所赐给我的人，我没有失落一个。”"},
{volumn:        43,chapter:        18,versenumber:        10,verse:"西门·彼得带着一把刀，就拔出来，将大祭司的仆人砍了一刀，削掉他的右耳；那仆人名叫马勒古。"},
{volumn:        43,chapter:        18,versenumber:        11,verse:"耶稣就对彼得说：“收刀入鞘吧，我父所给我的那杯，我岂可不喝呢？”"},
{volumn:        43,chapter:        18,versenumber:        12,verse:"那队兵和千夫长，并犹太人的差役就拿住耶稣，把他捆绑了，"},
{volumn:        43,chapter:        18,versenumber:        13,verse:"先带到亚那面前，因为亚那是本年作大祭司该亚法的岳父。"},
{volumn:        43,chapter:        18,versenumber:        14,verse:"这该亚法就是从前向犹太人发议论说“一个人替百姓死是有益的”那位。"},
{volumn:        43,chapter:        18,versenumber:        15,verse:"西门·彼得跟着耶稣，还有一个门徒跟着。那门徒是大祭司所认识的，他就同耶稣进了大祭司的院子。"},
{volumn:        43,chapter:        18,versenumber:        16,verse:"彼得却站在门外。大祭司所认识的那个门徒出来，和看门的使女说了一声，就领彼得进去。"},
{volumn:        43,chapter:        18,versenumber:        17,verse:"那看门的使女对彼得说：“你不也是这人的门徒吗？”他说：“我不是。”"},
{volumn:        43,chapter:        18,versenumber:        18,verse:"仆人和差役因为天冷，就生了炭火，站在那里烤火；彼得也同他们站着烤火。"},
{volumn:        43,chapter:        18,versenumber:        19,verse:"大祭司就以耶稣的门徒和他的教训盘问他。"},
{volumn:        43,chapter:        18,versenumber:        20,verse:"耶稣回答说：“我从来是明明地对世人说话。我常在会堂和殿里，就是犹太人聚集的地方教训人；我在暗地里并没有说什么。"},
{volumn:        43,chapter:        18,versenumber:        21,verse:"你为什么问我呢？可以问那听见的人，我对他们说的是什么；我所说的，他们都知道。”"},
{volumn:        43,chapter:        18,versenumber:        22,verse:"耶稣说了这话，旁边站着的一个差役用手掌打他，说：“你这样回答大祭司吗？”"},
{volumn:        43,chapter:        18,versenumber:        23,verse:"耶稣说：“我若说的不是，你可以指证那不是；我若说的是，你为什么打我呢？”"},
{volumn:        43,chapter:        18,versenumber:        24,verse:"亚那就把耶稣解到大祭司该亚法那里，仍是捆着解去的。"},
{volumn:        43,chapter:        18,versenumber:        25,verse:"西门·彼得正站着烤火，有人对他说：“你不也是他的门徒吗？”彼得不承认，说：“我不是。”"},
{volumn:        43,chapter:        18,versenumber:        26,verse:"有大祭司的一个仆人，是彼得削掉耳朵那人的亲属，说：“我不是看见你同他在园子里吗？”"},
{volumn:        43,chapter:        18,versenumber:        27,verse:"彼得又不承认。立时鸡就叫了。"},
{volumn:        43,chapter:        18,versenumber:        28,verse:"众人将耶稣从该亚法那里往衙门内解去，那时天还早。他们自己却不进衙门，恐怕染了污秽，不能吃逾越节的筵席。"},
{volumn:        43,chapter:        18,versenumber:        29,verse:"彼拉多就出来，到他们那里，说：“你们告这人是为什么事呢？”"},
{volumn:        43,chapter:        18,versenumber:        30,verse:"他们回答说：“这人若不是作恶的，我们就不把他交给你。”"},
{volumn:        43,chapter:        18,versenumber:        31,verse:"彼拉多说：“你们自己带他去，按着你们的律法审问他吧。”犹太人说：“我们没有杀人的权柄。”"},
{volumn:        43,chapter:        18,versenumber:        32,verse:"这要应验耶稣所说自己将要怎样死的话了。"},
{volumn:        43,chapter:        18,versenumber:        33,verse:"彼拉多又进了衙门，叫耶稣来，对他说：“你是犹太人的王吗？”"},
{volumn:        43,chapter:        18,versenumber:        34,verse:"耶稣回答说：“这话是你自己说的，还是别人论我对你说的呢？”"},
{volumn:        43,chapter:        18,versenumber:        35,verse:"彼拉多说：“我岂是犹太人呢？你本国的人和祭司长把你交给我。你做了什么事呢？”"},
{volumn:        43,chapter:        18,versenumber:        36,verse:"耶稣回答说：“我的国不属这世界；我的国若属这世界，我的臣仆必要争战，使我不至于被交给犹太人。只是我的国不属这世界。”"},
{volumn:        43,chapter:        18,versenumber:        37,verse:"彼拉多就对他说：“这样，你是王吗？”耶稣回答说：“你说我是王。我为此而生，也为此来到世间，特为给真理作见证。凡属真理的人就听我的话。”"},
{volumn:        43,chapter:        18,versenumber:        38,verse:"彼拉多说：“真理是什么呢？”耶稣被判死刑（太27·15—31；可15·6—20；路23·13—25）说了这话，又出来到犹太人那里，对他们说：“我查不出他有什么罪来。"},
{volumn:        43,chapter:        18,versenumber:        39,verse:"但你们有个规矩，在逾越节要我给你们释放一个人，你们要我给你们释放犹太人的王吗？”"},
{volumn:        43,chapter:        18,versenumber:        40,verse:"他们又喊着说：“不要这人，要巴拉巴！”这巴拉巴是个强盗。"},
{volumn:        43,chapter:        19,versenumber:         1,verse:"当下彼拉多将耶稣鞭打了。"},
{volumn:        43,chapter:        19,versenumber:         2,verse:"兵丁用荆棘编做冠冕戴在他头上，给他穿上紫袍，"},
{volumn:        43,chapter:        19,versenumber:         3,verse:"又挨近他，说：“恭喜，犹太人的王啊！”他们就用手掌打他。"},
{volumn:        43,chapter:        19,versenumber:         4,verse:"彼拉多又出来对众人说：“我带他出来见你们，叫你们知道我查不出他有什么罪来。”"},
{volumn:        43,chapter:        19,versenumber:         5,verse:"耶稣出来，戴着荆棘冠冕，穿着紫袍。彼拉多对他们说：“你们看这个人！”"},
{volumn:        43,chapter:        19,versenumber:         6,verse:"祭司长和差役看见他，就喊着说：“钉他十字架！钉他十字架！”彼拉多说：“你们自己把他钉十字架吧！我查不出他有什么罪来。”"},
{volumn:        43,chapter:        19,versenumber:         7,verse:"犹太人回答说：“我们有律法，按那律法，他是该死的，因他以自己为　神的儿子。”"},
{volumn:        43,chapter:        19,versenumber:         8,verse:"彼拉多听见这话，越发害怕，"},
{volumn:        43,chapter:        19,versenumber:         9,verse:"又进衙门，对耶稣说：“你是哪里来的？”耶稣却不回答。"},
{volumn:        43,chapter:        19,versenumber:        10,verse:"彼拉多说：“你不对我说话吗？你岂不知我有权柄释放你，也有权柄把你钉十字架吗？”"},
{volumn:        43,chapter:        19,versenumber:        11,verse:"耶稣回答说：“若不是从上头赐给你的，你就毫无权柄办我。所以，把我交给你的那人罪更重了。”"},
{volumn:        43,chapter:        19,versenumber:        12,verse:"从此，彼拉多想要释放耶稣，无奈犹太人喊着说：“你若释放这个人，就不是凯撒的忠臣（原文是朋友）。凡以自己为王的，就是背叛凯撒了。”"},
{volumn:        43,chapter:        19,versenumber:        13,verse:"彼拉多听见这话，就带耶稣出来，到了一个地方，名叫“铺华石处”，希伯来话叫厄巴大，就在那里坐堂。"},
{volumn:        43,chapter:        19,versenumber:        14,verse:"那日是预备逾越节的日子，约有午正。彼拉多对犹太人说：“看哪，这是你们的王！”"},
{volumn:        43,chapter:        19,versenumber:        15,verse:"他们喊着说：“除掉他！除掉他！钉他在十字架上！”彼拉多说：“我可以把你们的王钉十字架吗？”祭司长回答说：“除了凯撒，我们没有王。”"},
{volumn:        43,chapter:        19,versenumber:        16,verse:"于是彼拉多将耶稣交给他们去钉十字架。"},
{volumn:        43,chapter:        19,versenumber:        17,verse:"他们就把耶稣带了去。耶稣背着自己的十字架出来，到了一个地方，名叫“髑髅地”，希伯来话叫各各他。"},
{volumn:        43,chapter:        19,versenumber:        18,verse:"他们就在那里钉他在十字架上，还有两个人和他一同钉着，一边一个，耶稣在中间。"},
{volumn:        43,chapter:        19,versenumber:        19,verse:"彼拉多又用牌子写了一个名号，安在十字架上，写的是：“犹太人的王，拿撒勒人耶稣。”"},
{volumn:        43,chapter:        19,versenumber:        20,verse:"有许多犹太人念这名号；因为耶稣被钉十字架的地方与城相近，并且是用希伯来、罗马、希腊三样文字写的。"},
{volumn:        43,chapter:        19,versenumber:        21,verse:"犹太人的祭司长就对彼拉多说：“不要写‘犹太人的王’，要写‘他自己说：我是犹太人的王’。”"},
{volumn:        43,chapter:        19,versenumber:        22,verse:"彼拉多说：“我所写的，我已经写上了。”"},
{volumn:        43,chapter:        19,versenumber:        23,verse:"兵丁既然将耶稣钉在十字架上，就拿他的衣服分为四份，每兵一份；又拿他的里衣，这件里衣原来没有缝儿，是上下一片织成的。"},
{volumn:        43,chapter:        19,versenumber:        24,verse:"他们就彼此说：“我们不要撕开，只要拈阄，看谁得着。”这要应验经上的话说：“他们分了我的外衣，为我的里衣拈阄。”兵丁果然做了这事。"},
{volumn:        43,chapter:        19,versenumber:        25,verse:"站在耶稣十字架旁边的，有他母亲与他母亲的姐妹，并革罗罢的妻子马利亚，和抹大拉的马利亚。"},
{volumn:        43,chapter:        19,versenumber:        26,verse:"耶稣见母亲和他所爱的那门徒站在旁边，就对他母亲说：“母亲（原文是妇人），看，你的儿子！”"},
{volumn:        43,chapter:        19,versenumber:        27,verse:"又对那门徒说：“看，你的母亲！”从此，那门徒就接她到自己家里去了。"},
{volumn:        43,chapter:        19,versenumber:        28,verse:"这事以后，耶稣知道各样的事已经成了，为要使经上的话应验，就说：“我渴了。”"},
{volumn:        43,chapter:        19,versenumber:        29,verse:"有一个器皿盛满了醋，放在那里；他们就拿海绒蘸满了醋，绑在牛膝草上，送到他口。"},
{volumn:        43,chapter:        19,versenumber:        30,verse:"耶稣尝（原文是受）了那醋，就说：“成了！”便低下头，将灵魂交付　神了。"},
{volumn:        43,chapter:        19,versenumber:        31,verse:"犹太人因这日是预备日，又因那安息日是个大日，就求彼拉多叫人打断他们的腿，把他们拿去，免得尸首当安息日留在十字架上。"},
{volumn:        43,chapter:        19,versenumber:        32,verse:"于是兵丁来，把头一个人的腿，并与耶稣同钉第二个人的腿，都打断了。"},
{volumn:        43,chapter:        19,versenumber:        33,verse:"只是来到耶稣那里，见他已经死了，就不打断他的腿。"},
{volumn:        43,chapter:        19,versenumber:        34,verse:"惟有一个兵拿枪扎他的肋旁，随即有血和水流出来。"},
{volumn:        43,chapter:        19,versenumber:        35,verse:"看见这事的那人就作见证，他的见证也是真的，并且他知道自己所说的是真的，叫你们也可以信。"},
{volumn:        43,chapter:        19,versenumber:        36,verse:"这些事成了，为要应验经上的话说：“他的骨头一根也不可折断。”"},
{volumn:        43,chapter:        19,versenumber:        37,verse:"经上又有一句说：“他们要仰望自己所扎的人。”"},
{volumn:        43,chapter:        19,versenumber:        38,verse:"这些事以后，有亚利马太人约瑟，是耶稣的门徒，只因怕犹太人，就暗暗地作门徒。他来求彼拉多，要把耶稣的身体领去。彼拉多允准，他就把耶稣的身体领去了。"},
{volumn:        43,chapter:        19,versenumber:        39,verse:"又有尼哥底母，就是先前夜里去见耶稣的，带着没药和沉香约有一百斤前来。"},
{volumn:        43,chapter:        19,versenumber:        40,verse:"他们就照犹太人殡葬的规矩，把耶稣的身体用细麻布加上香料裹好了。"},
{volumn:        43,chapter:        19,versenumber:        41,verse:"在耶稣钉十字架的地方有一个园子，园子里有一座新坟墓，是从来没有葬过人的。"},
{volumn:        43,chapter:        19,versenumber:        42,verse:"只因是犹太人的预备日，又因那坟墓近，他们就把耶稣安放在那里。"},
{volumn:        43,chapter:        20,versenumber:         1,verse:"七日的第一日清早，天还黑的时候，抹大拉的马利亚来到坟墓那里，看见石头从坟墓挪开了，"},
{volumn:        43,chapter:        20,versenumber:         2,verse:"就跑来见西门·彼得和耶稣所爱的那个门徒，对他们说：“有人把主从坟墓里挪了去，我们不知道放在哪里。”"},
{volumn:        43,chapter:        20,versenumber:         3,verse:"彼得和那门徒就出来，往坟墓那里去。"},
{volumn:        43,chapter:        20,versenumber:         4,verse:"两个人同跑，那门徒比彼得跑得更快，先到了坟墓，"},
{volumn:        43,chapter:        20,versenumber:         5,verse:"低头往里看，就见细麻布还放在那里，只是没有进去。"},
{volumn:        43,chapter:        20,versenumber:         6,verse:"西门·彼得随后也到了，进坟墓里去，就看见细麻布还放在那里，"},
{volumn:        43,chapter:        20,versenumber:         7,verse:"又看见耶稣的裹头巾没有和细麻布放在一处，是另在一处卷着。"},
{volumn:        43,chapter:        20,versenumber:         8,verse:"先到坟墓的那门徒也进去，看见就信了。（"},
{volumn:        43,chapter:        20,versenumber:         9,verse:"因为他们还不明白圣经的意思，就是耶稣必要从死里复活。）"},
{volumn:        43,chapter:        20,versenumber:        10,verse:"于是两个门徒回自己的住处去了。"},
{volumn:        43,chapter:        20,versenumber:        11,verse:"马利亚却站在坟墓外面哭。哭的时候，低头往坟墓里看，"},
{volumn:        43,chapter:        20,versenumber:        12,verse:"就见两个天使，穿着白衣，在安放耶稣身体的地方坐着，一个在头，一个在脚。"},
{volumn:        43,chapter:        20,versenumber:        13,verse:"天使对她说：“妇人，你为什么哭？”她说：“因为有人把我主挪了去，我不知道放在哪里。”"},
{volumn:        43,chapter:        20,versenumber:        14,verse:"说了这话，就转过身来，看见耶稣站在那里，却不知道是耶稣。"},
{volumn:        43,chapter:        20,versenumber:        15,verse:"耶稣问她说：“妇人，为什么哭？你找谁呢？”马利亚以为是看园的，就对他说：“先生，若是你把他移了去，请告诉我，你把他放在哪里，我便去取他。”"},
{volumn:        43,chapter:        20,versenumber:        16,verse:"耶稣说：“马利亚。”马利亚就转过来，用希伯来话对他说：“拉波尼！”（拉波尼就是夫子的意思。）"},
{volumn:        43,chapter:        20,versenumber:        17,verse:"耶稣说：“不要摸我，因我还没有升上去见我的父。你往我弟兄那里去，告诉他们说，我要升上去见我的父，也是你们的父，见我的　神，也是你们的神。”"},
{volumn:        43,chapter:        20,versenumber:        18,verse:"抹大拉的马利亚就去告诉门徒说：“我已经看见了主。”她又将主对她说的这话告诉他们。"},
{volumn:        43,chapter:        20,versenumber:        19,verse:"那日（就是七日的第一日）晚上，门徒所在的地方，因怕犹太人，门都关了。耶稣来，站在当中，对他们说：“愿你们平安！”"},
{volumn:        43,chapter:        20,versenumber:        20,verse:"说了这话，就把手和肋旁指给他们看。门徒看见主，就喜乐了。"},
{volumn:        43,chapter:        20,versenumber:        21,verse:"耶稣又对他们说：“愿你们平安！父怎样差遣了我，我也照样差遣你们。”"},
{volumn:        43,chapter:        20,versenumber:        22,verse:"说了这话，就向他们吹一口气，说：“你们受圣灵！"},
{volumn:        43,chapter:        20,versenumber:        23,verse:"你们赦免谁的罪，谁的罪就赦免了；你们留下谁的罪，谁的罪就留下了。”"},
{volumn:        43,chapter:        20,versenumber:        24,verse:"那十二个门徒中，有称为低土马的多马；耶稣来的时候，他没有和他们同在。"},
{volumn:        43,chapter:        20,versenumber:        25,verse:"那些门徒就对他说：“我们已经看见主了。”多马却说：“我非看见他手上的钉痕，用指头探入那钉痕，又用手探入他的肋旁，我总不信。”"},
{volumn:        43,chapter:        20,versenumber:        26,verse:"过了八日，门徒又在屋里，多马也和他们同在，门都关了。耶稣来，站在当中说：“愿你们平安！”"},
{volumn:        43,chapter:        20,versenumber:        27,verse:"就对多马说：“伸过你的指头来，摸（原文是看）我的手；伸出你的手来，探入我的肋旁。不要疑惑，总要信！”"},
{volumn:        43,chapter:        20,versenumber:        28,verse:"多马说：“我的主！我的　神！”"},
{volumn:        43,chapter:        20,versenumber:        29,verse:"耶稣对他说：“你因看见了我才信；那没有看见就信的有福了。”"},
{volumn:        43,chapter:        20,versenumber:        30,verse:"耶稣在门徒面前另外行了许多神迹，没有记在这书上。"},
{volumn:        43,chapter:        20,versenumber:        31,verse:"但记这些事要叫你们信耶稣是基督，是　神的儿子，并且叫你们信了他，就可以因他的名得生命。"},
{volumn:        43,chapter:        21,versenumber:         1,verse:"这些事以后，耶稣在提比哩亚海边又向门徒显现。他怎样显现记在下面："},
{volumn:        43,chapter:        21,versenumber:         2,verse:"有西门·彼得和称为低土马的多马，并加利利的迦拿人拿但业，还有西庇太的两个儿子，又有两个门徒，都在一处。"},
{volumn:        43,chapter:        21,versenumber:         3,verse:"西门·彼得对他们说：“我打鱼去。”他们说：“我们也和你同去。”他们就出去，上了船；那一夜并没有打着什么。"},
{volumn:        43,chapter:        21,versenumber:         4,verse:"天将亮的时候，耶稣站在岸上，门徒却不知道是耶稣。"},
{volumn:        43,chapter:        21,versenumber:         5,verse:"耶稣就对他们说：“小子！你们有吃的没有？”他们回答说：“没有。”"},
{volumn:        43,chapter:        21,versenumber:         6,verse:"耶稣说：“你们把网撒在船的右边，就必得着。”他们便撒下网去，竟拉不上来了，因为鱼甚多。"},
{volumn:        43,chapter:        21,versenumber:         7,verse:"耶稣所爱的那门徒对彼得说：“是主！”那时西门·彼得赤着身子，一听见是主，就束上一件外衣，跳在海里。"},
{volumn:        43,chapter:        21,versenumber:         8,verse:"其余的门徒离岸不远，约有二百肘（古时以肘为尺，一肘约有今时尺半），就在小船上把那网鱼拉过来。"},
{volumn:        43,chapter:        21,versenumber:         9,verse:"他们上了岸，就看见那里有炭火，上面有鱼，又有饼。"},
{volumn:        43,chapter:        21,versenumber:        10,verse:"耶稣对他们说：“把刚才打的鱼拿几条来。”"},
{volumn:        43,chapter:        21,versenumber:        11,verse:"西门·彼得就去（或译：上船），把网拉到岸上。那网满了大鱼，共一百五十三条；鱼虽这样多，网却没有破。"},
{volumn:        43,chapter:        21,versenumber:        12,verse:"耶稣说：“你们来吃早饭。”门徒中没有一个敢问他：“你是谁？”因为知道是主。"},
{volumn:        43,chapter:        21,versenumber:        13,verse:"耶稣就来拿饼和鱼给他们。"},
{volumn:        43,chapter:        21,versenumber:        14,verse:"耶稣从死里复活以后，向门徒显现，这是第三次。"},
{volumn:        43,chapter:        21,versenumber:        15,verse:"他们吃完了早饭，耶稣对西门·彼得说：“约翰（在马太十六章十七节称约拿）的儿子西门，你爱我比这些更深吗？”彼得说：“主啊，是的，你知道我爱你。”耶稣对他说：“你喂养我的小羊。”"},
{volumn:        43,chapter:        21,versenumber:        16,verse:"耶稣第二次又对他说：“约翰的儿子西门，你爱我吗？”彼得说：“主啊，是的，你知道我爱你。”耶稣说：“你牧养我的羊。”"},
{volumn:        43,chapter:        21,versenumber:        17,verse:"第三次对他说：“约翰的儿子西门，你爱我吗？”彼得因为耶稣第三次对他说“你爱我吗”，就忧愁，对耶稣说：“主啊，你是无所不知的；你知道我爱你。”耶稣说：“你喂养我的羊。"},
{volumn:        43,chapter:        21,versenumber:        18,verse:"我实实在在地告诉你，你年少的时候，自己束上带子，随意往来；但年老的时候，你要伸出手来，别人要把你束上，带你到不愿意去的地方。”"},
{volumn:        43,chapter:        21,versenumber:        19,verse:"（耶稣说这话是指着彼得要怎样死，荣耀　神。）说了这话，就对他说：“你跟从我吧！”"},
{volumn:        43,chapter:        21,versenumber:        20,verse:"彼得转过来，看见耶稣所爱的那门徒跟着，（就是在晚饭的时候，靠着耶稣胸膛说：“主啊，卖你的是谁？”的那门徒。）"},
{volumn:        43,chapter:        21,versenumber:        21,verse:"彼得看见他，就问耶稣说：“主啊，这人将来如何？”"},
{volumn:        43,chapter:        21,versenumber:        22,verse:"耶稣对他说：“我若要他等到我来的时候，与你何干？你跟从我吧！”"},
{volumn:        43,chapter:        21,versenumber:        23,verse:"于是这话传在弟兄中间，说那门徒不死。其实，耶稣不是说他不死，乃是说：“我若要他等到我来的时候，与你何干？”"},
{volumn:        43,chapter:        21,versenumber:        24,verse:"为这些事作见证，并且记载这些事的就是这门徒；我们也知道他的见证是真的。"},
{volumn:        43,chapter:        21,versenumber:        25,verse:"耶稣所行的事还有许多，若是一一地都写出来，我想，所写的书就是世界也容不下了。"},
{volumn:        44,chapter:         1,versenumber:         1,verse:"提阿非罗啊，我已经作了前书，论到耶稣开头一切所行所教训的，"},
{volumn:        44,chapter:         1,versenumber:         2,verse:"直到他藉着圣灵吩咐所拣选的使徒，以后被接上升的日子为止。"},
{volumn:        44,chapter:         1,versenumber:         3,verse:"他受害之后，用许多的凭据将自己活活地显给使徒看，四十天之久向他们显现，讲说　神国的事。"},
{volumn:        44,chapter:         1,versenumber:         4,verse:"耶稣和他们聚集的时候，嘱咐他们说：“不要离开耶路撒冷，要等候父所应许的，就是你们听见我说过的。"},
{volumn:        44,chapter:         1,versenumber:         5,verse:"约翰是用水施洗，但不多几日，你们要受圣灵的洗。”"},
{volumn:        44,chapter:         1,versenumber:         6,verse:"他们聚集的时候，问耶稣说：“主啊，你复兴以色列国就在这时候吗？”"},
{volumn:        44,chapter:         1,versenumber:         7,verse:"耶稣对他们说：“父凭着自己的权柄所定的时候、日期，不是你们可以知道的。"},
{volumn:        44,chapter:         1,versenumber:         8,verse:"但圣灵降临在你们身上，你们就必得着能力，并要在耶路撒冷、犹太全地，和撒玛利亚，直到地极，作我的见证。”"},
{volumn:        44,chapter:         1,versenumber:         9,verse:"说了这话，他们正看的时候，他就被取上升，有一朵云彩把他接去，便看不见他了。"},
{volumn:        44,chapter:         1,versenumber:        10,verse:"当他往上去，他们定睛望天的时候，忽然有两个人身穿白衣，站在旁边，说："},
{volumn:        44,chapter:         1,versenumber:        11,verse:"“加利利人哪，你们为什么站着望天呢？这离开你们被接升天的耶稣，你们见他怎样往天上去，他还要怎样来。”"},
{volumn:        44,chapter:         1,versenumber:        12,verse:"有一座山，名叫橄榄山，离耶路撒冷不远，约有安息日可走的路程。当下，门徒从那里回耶路撒冷去，"},
{volumn:        44,chapter:         1,versenumber:        13,verse:"进了城，就上了所住的一间楼房；在那里有彼得、约翰、雅各、安得烈、腓力、多马、巴多罗买、马太、亚勒腓的儿子雅各、奋锐党的西门，和雅各的儿子（或译：兄弟）犹大。"},
{volumn:        44,chapter:         1,versenumber:        14,verse:"这些人同着几个妇人和耶稣的母亲马利亚，并耶稣的弟兄，都同心合意地恒切祷告。"},
{volumn:        44,chapter:         1,versenumber:        15,verse:"那时，有许多人聚会，约有一百二十名，彼得就在弟兄中间站起来，"},
{volumn:        44,chapter:         1,versenumber:        16,verse:"说：“弟兄们！圣灵藉大卫的口，在圣经上预言领人捉拿耶稣的犹大，这话是必须应验的。"},
{volumn:        44,chapter:         1,versenumber:        17,verse:"他本来列在我们数中，并且在使徒的职任上得了一份。"},
{volumn:        44,chapter:         1,versenumber:        18,verse:"这人用他作恶的工价买了一块田，以后身子仆倒，肚腹崩裂，肠子都流出来。"},
{volumn:        44,chapter:         1,versenumber:        19,verse:"住在耶路撒冷的众人都知道这事，所以按着他们那里的话给那块田起名叫亚革大马，就是“血田”的意思。"},
{volumn:        44,chapter:         1,versenumber:        20,verse:"因为诗篇上写着，说：‘愿他的住处变为荒场，无人在内居住’，又说：‘愿别人得他的职分。’"},
{volumn:        44,chapter:         1,versenumber:        21,verse:"所以，主耶稣在我们中间始终出入的时候，"},
{volumn:        44,chapter:         1,versenumber:        22,verse:"就是从约翰施洗起，直到主离开我们被接上升的日子为止，必须从那常与我们作伴的人中立一位与我们同作耶稣复活的见证。”"},
{volumn:        44,chapter:         1,versenumber:        23,verse:"于是选举两个人，就是那叫做巴撒巴，又称呼犹士都的约瑟，和马提亚。"},
{volumn:        44,chapter:         1,versenumber:        24,verse:"众人就祷告说：“主啊，你知道万人的心，求你从这两个人中，指明你所拣选的是谁，叫他得这使徒的位分。这位分犹大已经丢弃，往自己的地方去了。”"},
{volumn:        44,chapter:         1,versenumber:        25,verse:""},
{volumn:        44,chapter:         1,versenumber:        26,verse:"于是众人为他们摇签，摇出马提亚来；他就和十一个使徒同列。"},
{volumn:        44,chapter:         2,versenumber:         1,verse:"五旬节到了，门徒都聚集在一处。"},
{volumn:        44,chapter:         2,versenumber:         2,verse:"忽然，从天上有响声下来，好像一阵大风吹过，充满了他们所坐的屋子，"},
{volumn:        44,chapter:         2,versenumber:         3,verse:"又有舌头如火焰显现出来，分开落在他们各人头上。"},
{volumn:        44,chapter:         2,versenumber:         4,verse:"他们就都被圣灵充满，按着圣灵所赐的口才说起别国的话来。"},
{volumn:        44,chapter:         2,versenumber:         5,verse:"那时，有虔诚的犹太人从天下各国来，住在耶路撒冷。"},
{volumn:        44,chapter:         2,versenumber:         6,verse:"这声音一响，众人都来聚集，各人听见门徒用众人的乡谈说话，就甚纳闷；"},
{volumn:        44,chapter:         2,versenumber:         7,verse:"都惊讶希奇说：“看哪，这说话的不都是加利利人吗？"},
{volumn:        44,chapter:         2,versenumber:         8,verse:"我们各人怎么听见他们说我们生来所用的乡谈呢？"},
{volumn:        44,chapter:         2,versenumber:         9,verse:"我们帕提亚人、玛代人、以拦人，和住在美索不达米亚、犹太、加帕多家、本都、亚细亚、"},
{volumn:        44,chapter:         2,versenumber:        10,verse:"弗吕家、旁非利亚、埃及的人，并靠近古利奈的利比亚一带地方的人，从罗马来的客旅中，或是犹太人，或是进犹太教的人，"},
{volumn:        44,chapter:         2,versenumber:        11,verse:"克里特和阿拉伯人，都听见他们用我们的乡谈，讲说　神的大作为。”"},
{volumn:        44,chapter:         2,versenumber:        12,verse:"众人就都惊讶猜疑，彼此说：“这是什么意思呢？”"},
{volumn:        44,chapter:         2,versenumber:        13,verse:"还有人讥诮说：“他们无非是新酒灌满了。”"},
{volumn:        44,chapter:         2,versenumber:        14,verse:"彼得和十一个使徒站起，高声说：“犹太人和一切住在耶路撒冷的人哪，这件事你们当知道，也当侧耳听我的话。"},
{volumn:        44,chapter:         2,versenumber:        15,verse:"你们想这些人是醉了；其实不是醉了，因为时候刚到巳初。"},
{volumn:        44,chapter:         2,versenumber:        16,verse:"这正是先知约珥所说的："},
{volumn:        44,chapter:         2,versenumber:        17,verse:"‘　神说：在末后的日子，我要将我的灵浇灌凡有血气的。你们的儿女要说预言；你们的少年人要见异象；老年人要做异梦。"},
{volumn:        44,chapter:         2,versenumber:        18,verse:"在那些日子，我要将我的灵浇灌我的仆人和使女，他们就要说预言。"},
{volumn:        44,chapter:         2,versenumber:        19,verse:"在天上，我要显出奇事；在地下，我要显出神迹；有血，有火，有烟雾。"},
{volumn:        44,chapter:         2,versenumber:        20,verse:"日头要变为黑暗，月亮要变为血；这都在主大而明显的日子未到以前。"},
{volumn:        44,chapter:         2,versenumber:        21,verse:"到那时候，凡求告主名的，就必得救。’"},
{volumn:        44,chapter:         2,versenumber:        22,verse:"“以色列人哪，请听我的话：神藉着拿撒勒人耶稣在你们中间施行异能、奇事、神迹，将他证明出来，这是你们自己知道的。"},
{volumn:        44,chapter:         2,versenumber:        23,verse:"他既按着　神的定旨先见被交与人，你们就藉着无法之人的手，把他钉在十字架上，杀了。"},
{volumn:        44,chapter:         2,versenumber:        24,verse:"神却将死的痛苦解释了，叫他复活，因为他原不能被死拘禁。"},
{volumn:        44,chapter:         2,versenumber:        25,verse:"大卫指着他说：‘我看见主常在我眼前；他在我右边，叫我不至于摇动。"},
{volumn:        44,chapter:         2,versenumber:        26,verse:"所以，我心里欢喜，我的灵（原文是舌）快乐；并且我的肉身要安居在指望中。"},
{volumn:        44,chapter:         2,versenumber:        27,verse:"因你必不将我的灵魂撇在阴间，也不叫你的圣者见朽坏。"},
{volumn:        44,chapter:         2,versenumber:        28,verse:"你已将生命的道路指示我，必叫我因见你的面（或译：叫我在你面前）得着满足的快乐。’"},
{volumn:        44,chapter:         2,versenumber:        29,verse:"“弟兄们！先祖大卫的事，我可以明明地对你们说：他死了，也埋葬了，并且他的坟墓直到今日还在我们这里。"},
{volumn:        44,chapter:         2,versenumber:        30,verse:"大卫既是先知，又晓得　神曾向他起誓，要从他的后裔中立一位坐在他的宝座上，"},
{volumn:        44,chapter:         2,versenumber:        31,verse:"就预先看明这事，讲论基督复活说：‘他的灵魂不撇在阴间；他的肉身也不见朽坏。’"},
{volumn:        44,chapter:         2,versenumber:        32,verse:"这耶稣，　神已经叫他复活了，我们都为这事作见证。"},
{volumn:        44,chapter:         2,versenumber:        33,verse:"他既被　神的右手高举（或译：他既高举在　神的右边），又从父受了所应许的圣灵，就把你们所看见所听见的，浇灌下来。"},
{volumn:        44,chapter:         2,versenumber:        34,verse:"大卫并没有升到天上，但自己说：‘主对我主说：你坐在我的右边，"},
{volumn:        44,chapter:         2,versenumber:        35,verse:"等我使你仇敌作你的脚凳。’"},
{volumn:        44,chapter:         2,versenumber:        36,verse:"“故此，以色列全家当确实地知道，你们钉在十字架上的这位耶稣，　神已经立他为主，为基督了。”"},
{volumn:        44,chapter:         2,versenumber:        37,verse:"众人听见这话，觉得扎心，就对彼得和其余的使徒说：“弟兄们，我们当怎样行？”"},
{volumn:        44,chapter:         2,versenumber:        38,verse:"彼得说：“你们各人要悔改，奉耶稣基督的名受洗，叫你们的罪得赦，就必领受所赐的圣灵；"},
{volumn:        44,chapter:         2,versenumber:        39,verse:"因为这应许是给你们和你们的儿女，并一切在远方的人，就是主我们　神所召来的。”"},
{volumn:        44,chapter:         2,versenumber:        40,verse:"彼得还用许多话作见证，劝勉他们说：“你们当救自己脱离这弯曲的世代。”"},
{volumn:        44,chapter:         2,versenumber:        41,verse:"于是领受他话的人就受了洗。那一天，门徒约添了三千人，"},
{volumn:        44,chapter:         2,versenumber:        42,verse:"都恒心遵守使徒的教训，彼此交接，擘饼，祈祷。"},
{volumn:        44,chapter:         2,versenumber:        43,verse:"众人都惧怕；使徒又行了许多奇事神迹。"},
{volumn:        44,chapter:         2,versenumber:        44,verse:"信的人都在一处，凡物公用，"},
{volumn:        44,chapter:         2,versenumber:        45,verse:"并且卖了田产、家业，照各人所需用的分给各人。"},
{volumn:        44,chapter:         2,versenumber:        46,verse:"他们天天同心合意恒切地在殿里，且在家中擘饼，存着欢喜、诚实的心用饭，"},
{volumn:        44,chapter:         2,versenumber:        47,verse:"赞美　神，得众民的喜爱。主将得救的人天天加给他们。"},
{volumn:        44,chapter:         3,versenumber:         1,verse:"申初祷告的时候，彼得、约翰上圣殿去。"},
{volumn:        44,chapter:         3,versenumber:         2,verse:"有一个人，生来是瘸腿的，天天被人抬来，放在殿的一个门口（那门名叫美门），要求进殿的人周济。"},
{volumn:        44,chapter:         3,versenumber:         3,verse:"他看见彼得、约翰将要进殿，就求他们周济。"},
{volumn:        44,chapter:         3,versenumber:         4,verse:"彼得、约翰定睛看他；彼得说：“你看我们！”"},
{volumn:        44,chapter:         3,versenumber:         5,verse:"那人就留意看他们，指望得着什么。"},
{volumn:        44,chapter:         3,versenumber:         6,verse:"彼得说：“金银我都没有，只把我所有的给你：我奉拿撒勒人耶稣基督的名，叫你起来行走！”"},
{volumn:        44,chapter:         3,versenumber:         7,verse:"于是拉着他的右手，扶他起来；他的脚和踝子骨立刻健壮了，"},
{volumn:        44,chapter:         3,versenumber:         8,verse:"就跳起来，站着，又行走，同他们进了殿，走着，跳着，赞美　神。"},
{volumn:        44,chapter:         3,versenumber:         9,verse:"百姓都看见他行走，赞美　神；"},
{volumn:        44,chapter:         3,versenumber:        10,verse:"认得他是那素常坐在殿的美门口求周济的，就因他所遇着的事满心希奇、惊讶。"},
{volumn:        44,chapter:         3,versenumber:        11,verse:"那人正在称为所罗门的廊下，拉着彼得、约翰；众百姓一齐跑到他们那里，很觉希奇。"},
{volumn:        44,chapter:         3,versenumber:        12,verse:"彼得看见，就对百姓说：“以色列人哪，为什么把这事当作希奇呢？为什么定睛看我们，以为我们凭自己的能力和虔诚使这人行走呢？"},
{volumn:        44,chapter:         3,versenumber:        13,verse:"亚伯拉罕、以撒、雅各的　神，就是我们列祖的　神，已经荣耀了他的仆人（或译：儿子）耶稣；你们却把他交付彼拉多。彼拉多定意要释放他，你们竟在彼拉多面前弃绝了他。"},
{volumn:        44,chapter:         3,versenumber:        14,verse:"你们弃绝了那圣洁公义者，反求着释放一个凶手给你们。"},
{volumn:        44,chapter:         3,versenumber:        15,verse:"你们杀了那生命的主，　神却叫他从死里复活了；我们都是为这事作见证。"},
{volumn:        44,chapter:         3,versenumber:        16,verse:"我们因信他的名，他的名便叫你们所看见所认识的这人健壮了；正是他所赐的信心，叫这人在你们众人面前全然好了。"},
{volumn:        44,chapter:         3,versenumber:        17,verse:"弟兄们，我晓得你们做这事是出于不知，你们的官长也是如此。"},
{volumn:        44,chapter:         3,versenumber:        18,verse:"但　神曾藉众先知的口，预言基督将要受害，就这样应验了。"},
{volumn:        44,chapter:         3,versenumber:        19,verse:"所以，你们当悔改归正，使你们的罪得以涂抹。这样，那安舒的日子就必从主面前来到；"},
{volumn:        44,chapter:         3,versenumber:        20,verse:"主也必差遣所预定给你们的基督（耶稣）降临。"},
{volumn:        44,chapter:         3,versenumber:        21,verse:"天必留他，等到万物复兴的时候，就是　神从创世以来、藉着圣先知的口所说的。"},
{volumn:        44,chapter:         3,versenumber:        22,verse:"摩西曾说：‘主　神要从你们弟兄中间给你们兴起一位先知像我，凡他向你们所说的，你们都要听从。"},
{volumn:        44,chapter:         3,versenumber:        23,verse:"凡不听从那先知的，必要从民中全然灭绝。’"},
{volumn:        44,chapter:         3,versenumber:        24,verse:"从撒母耳以来的众先知，凡说预言的，也都说到这些日子。"},
{volumn:        44,chapter:         3,versenumber:        25,verse:"你们是先知的子孙，也承受　神与你们祖宗所立的约，就是对亚伯拉罕说：‘地上万族都要因你的后裔得福。’"},
{volumn:        44,chapter:         3,versenumber:        26,verse:"神既兴起他的仆人（或译：儿子），就先差他到你们这里来，赐福给你们，叫你们各人回转，离开罪恶。”"},
{volumn:        44,chapter:         4,versenumber:         1,verse:"使徒对百姓说话的时候，祭司们和守殿官，并撒都该人忽然来了。"},
{volumn:        44,chapter:         4,versenumber:         2,verse:"因他们教训百姓，本着耶稣，传说死人复活，就很烦恼，"},
{volumn:        44,chapter:         4,versenumber:         3,verse:"于是下手拿住他们；因为天已经晚了，就把他们押到第二天。"},
{volumn:        44,chapter:         4,versenumber:         4,verse:"但听道之人有许多信的，男丁数目约到五千。"},
{volumn:        44,chapter:         4,versenumber:         5,verse:"第二天，官府、长老，和文士在耶路撒冷聚会，"},
{volumn:        44,chapter:         4,versenumber:         6,verse:"又有大祭司亚那和该亚法、约翰、亚历山大，并大祭司的亲族都在那里，"},
{volumn:        44,chapter:         4,versenumber:         7,verse:"叫使徒站在当中，就问他们说：“你们用什么能力，奉谁的名做这事呢？”"},
{volumn:        44,chapter:         4,versenumber:         8,verse:"那时彼得被圣灵充满，对他们说："},
{volumn:        44,chapter:         4,versenumber:         9,verse:"“治民的官府和长老啊，倘若今日因为在残疾人身上所行的善事查问我们他是怎么得了痊愈，"},
{volumn:        44,chapter:         4,versenumber:        10,verse:"你们众人和以色列百姓都当知道，站在你们面前的这人得痊愈是因你们所钉十字架、　神叫他从死里复活的拿撒勒人耶稣基督的名。"},
{volumn:        44,chapter:         4,versenumber:        11,verse:"他是你们匠人所弃的石头，已成了房角的头块石头。"},
{volumn:        44,chapter:         4,versenumber:        12,verse:"除他以外，别无拯救；因为在天下人间，没有赐下别的名，我们可以靠着得救。”"},
{volumn:        44,chapter:         4,versenumber:        13,verse:"他们见彼得、约翰的胆量，又看出他们原是没有学问的小民，就希奇，认明他们是跟过耶稣的；"},
{volumn:        44,chapter:         4,versenumber:        14,verse:"又看见那治好了的人和他们一同站着，就无话可驳。"},
{volumn:        44,chapter:         4,versenumber:        15,verse:"于是吩咐他们从公会出去，就彼此商议说："},
{volumn:        44,chapter:         4,versenumber:        16,verse:"“我们当怎样办这两个人呢？因为他们诚然行了一件明显的神迹，凡住耶路撒冷的人都知道，我们也不能说没有。"},
{volumn:        44,chapter:         4,versenumber:        17,verse:"惟恐这事越发传扬在民间，我们必须恐吓他们，叫他们不再奉这名对人讲论。”"},
{volumn:        44,chapter:         4,versenumber:        18,verse:"于是叫了他们来，禁止他们总不可奉耶稣的名讲论教训人。"},
{volumn:        44,chapter:         4,versenumber:        19,verse:"彼得、约翰说：“听从你们，不听从　神，这在　神面前合理不合理，你们自己酌量吧！"},
{volumn:        44,chapter:         4,versenumber:        20,verse:"我们所看见所听见的，不能不说。”"},
{volumn:        44,chapter:         4,versenumber:        21,verse:"官长为百姓的缘故，想不出法子刑罚他们，又恐吓一番，把他们释放了。这是因众人为所行的奇事都归荣耀与　神。"},
{volumn:        44,chapter:         4,versenumber:        22,verse:"原来藉着神迹医好的那人有四十多岁了。"},
{volumn:        44,chapter:         4,versenumber:        23,verse:"二人既被释放，就到会友那里去，把祭司长和长老所说的话都告诉他们。"},
{volumn:        44,chapter:         4,versenumber:        24,verse:"他们听见了，就同心合意地高声向　神说：“主啊！你是造天、地、海，和其中万物的，"},
{volumn:        44,chapter:         4,versenumber:        25,verse:"你曾藉着圣灵，托你仆人、我们祖宗大卫的口，说：‘外邦为什么争闹？万民为什么谋算虚妄的事？"},
{volumn:        44,chapter:         4,versenumber:        26,verse:"世上的君王一齐起来，臣宰也聚集，要敌挡主，并主的受膏者（或译：基督）。’"},
{volumn:        44,chapter:         4,versenumber:        27,verse:"希律和本丢·彼拉多，外邦人和以色列民，果然在这城里聚集，要攻打你所膏的圣仆（仆：或译子）耶稣，"},
{volumn:        44,chapter:         4,versenumber:        28,verse:"成就你手和你意旨所预定必有的事。"},
{volumn:        44,chapter:         4,versenumber:        29,verse:"他们恐吓我们，现在求主鉴察，一面叫你仆人大放胆量讲你的道，一面伸出你的手来医治疾病，并且使神迹奇事因着你圣仆（仆：或译子）耶稣的名行出来。”"},
{volumn:        44,chapter:         4,versenumber:        30,verse:""},
{volumn:        44,chapter:         4,versenumber:        31,verse:"祷告完了，聚会的地方震动，他们就都被圣灵充满，放胆讲论　神的道。"},
{volumn:        44,chapter:         4,versenumber:        32,verse:"那许多信的人都是一心一意的，没有一人说他的东西有一样是自己的，都是大家公用。"},
{volumn:        44,chapter:         4,versenumber:        33,verse:"使徒大有能力，见证主耶稣复活；众人也都蒙大恩。"},
{volumn:        44,chapter:         4,versenumber:        34,verse:"内中也没有一个缺乏的；因为人人将田产房屋都卖了，把所卖的价银拿来，"},
{volumn:        44,chapter:         4,versenumber:        35,verse:"放在使徒脚前，照各人所需用的，分给各人。"},
{volumn:        44,chapter:         4,versenumber:        36,verse:"有一个利未人，生在塞浦路斯，名叫约瑟，使徒称他为巴拿巴（巴拿巴翻出来就是劝慰子）。"},
{volumn:        44,chapter:         4,versenumber:        37,verse:"他有田地，也卖了，把价银拿来，放在使徒脚前。"},
{volumn:        44,chapter:         5,versenumber:         1,verse:"有一个人，名叫亚拿尼亚，同他的妻子撒非喇卖了田产，"},
{volumn:        44,chapter:         5,versenumber:         2,verse:"把价银私自留下几份，他的妻子也知道，其余的几份拿来放在使徒脚前。"},
{volumn:        44,chapter:         5,versenumber:         3,verse:"彼得说：“亚拿尼亚！为什么撒但充满了你的心，叫你欺哄圣灵，把田地的价银私自留下几份呢？"},
{volumn:        44,chapter:         5,versenumber:         4,verse:"田地还没有卖，不是你自己的吗？既卖了，价银不是你作主吗？你怎么心里起这意念呢？你不是欺哄人，是欺哄　神了。”"},
{volumn:        44,chapter:         5,versenumber:         5,verse:"亚拿尼亚听见这话，就仆倒，断了气；听见的人都甚惧怕。"},
{volumn:        44,chapter:         5,versenumber:         6,verse:"有些少年人起来，把他包裹，抬出去埋葬了。"},
{volumn:        44,chapter:         5,versenumber:         7,verse:"约过了三小时，他的妻子进来，还不知道这事。"},
{volumn:        44,chapter:         5,versenumber:         8,verse:"彼得对她说：“你告诉我，你们卖田地的价银就是这些吗？”她说：“就是这些。”"},
{volumn:        44,chapter:         5,versenumber:         9,verse:"彼得说：“你们为什么同心试探主的灵呢？埋葬你丈夫之人的脚已到门口，他们也要把你抬出去。”"},
{volumn:        44,chapter:         5,versenumber:        10,verse:"妇人立刻仆倒在彼得脚前，断了气。那些少年人进来，见她已经死了，就抬出去，埋在她丈夫旁边。"},
{volumn:        44,chapter:         5,versenumber:        11,verse:"全教会和听见这事的人都甚惧怕。"},
{volumn:        44,chapter:         5,versenumber:        12,verse:"主藉使徒的手在民间行了许多神迹奇事；他们（或译：信的人）都同心合意地在所罗门的廊下。"},
{volumn:        44,chapter:         5,versenumber:        13,verse:"其余的人没有一个敢贴近他们，百姓却尊重他们。"},
{volumn:        44,chapter:         5,versenumber:        14,verse:"信而归主的人越发增添，连男带女很多。"},
{volumn:        44,chapter:         5,versenumber:        15,verse:"甚至有人将病人抬到街上，放在床上或褥子上，指望彼得过来的时候，或者得他的影儿照在什么人身上。"},
{volumn:        44,chapter:         5,versenumber:        16,verse:"还有许多人带着病人和被污鬼缠磨的，从耶路撒冷四围的城邑来，全都得了医治。"},
{volumn:        44,chapter:         5,versenumber:        17,verse:"大祭司和他的一切同人，就是撒都该教门的人，都起来，满心忌恨，"},
{volumn:        44,chapter:         5,versenumber:        18,verse:"就下手拿住使徒，收在外监。"},
{volumn:        44,chapter:         5,versenumber:        19,verse:"但主的使者夜间开了监门，领他们出来，"},
{volumn:        44,chapter:         5,versenumber:        20,verse:"说：“你们去站在殿里，把这生命的道都讲给百姓听。”"},
{volumn:        44,chapter:         5,versenumber:        21,verse:"使徒听了这话，天将亮的时候就进殿里去教训人。大祭司和他的同人来了，叫齐公会的人和以色列族的众长老，就差人到监里去，要把使徒提出来。"},
{volumn:        44,chapter:         5,versenumber:        22,verse:"但差役到了，不见他们在监里，就回来禀报说："},
{volumn:        44,chapter:         5,versenumber:        23,verse:"“我们看见监牢关得极妥当，看守的人也站在门外；及至开了门，里面一个人都不见。”"},
{volumn:        44,chapter:         5,versenumber:        24,verse:"守殿官和祭司长听见这话，心里犯难，不知这事将来如何。"},
{volumn:        44,chapter:         5,versenumber:        25,verse:"有一个人来禀报说：“你们收在监里的人，现在站在殿里教训百姓。”"},
{volumn:        44,chapter:         5,versenumber:        26,verse:"于是守殿官和差役去带使徒来，并没有用强暴，因为怕百姓用石头打他们。"},
{volumn:        44,chapter:         5,versenumber:        27,verse:"带到了，便叫使徒站在公会前；大祭司问他们说："},
{volumn:        44,chapter:         5,versenumber:        28,verse:"“我们不是严严地禁止你们，不可奉这名教训人吗？你们倒把你们的道理充满了耶路撒冷，想要叫这人的血归到我们身上！”"},
{volumn:        44,chapter:         5,versenumber:        29,verse:"彼得和众使徒回答说：“顺从　神，不顺从人，是应当的。"},
{volumn:        44,chapter:         5,versenumber:        30,verse:"你们挂在木头上杀害的耶稣，我们祖宗的　神已经叫他复活。"},
{volumn:        44,chapter:         5,versenumber:        31,verse:"神且用右手将他高举（或译：他就是　神高举在自己的右边），叫他作君王，作救主，将悔改的心和赦罪的恩赐给以色列人。"},
{volumn:        44,chapter:         5,versenumber:        32,verse:"我们为这事作见证；　神赐给顺从之人的圣灵也为这事作见证。”"},
{volumn:        44,chapter:         5,versenumber:        33,verse:"公会的人听见就极其恼怒，想要杀他们。"},
{volumn:        44,chapter:         5,versenumber:        34,verse:"但有一个法利赛人，名叫迦玛列，是众百姓所敬重的教法师，在公会中站起来，吩咐人把使徒暂且带到外面去，"},
{volumn:        44,chapter:         5,versenumber:        35,verse:"就对众人说：“以色列人哪，论到这些人，你们应当小心怎样办理。"},
{volumn:        44,chapter:         5,versenumber:        36,verse:"从前丢大起来，自夸为大，附从他的人约有四百，他被杀后，附从他的全都散了，归于无有。"},
{volumn:        44,chapter:         5,versenumber:        37,verse:"此后，报名上册的时候，又有加利利的犹大起来，引诱些百姓跟从他；他也灭亡，附从他的人也都四散了。"},
{volumn:        44,chapter:         5,versenumber:        38,verse:"现在，我劝你们不要管这些人，任凭他们吧！他们所谋的、所行的，若是出于人，必要败坏；"},
{volumn:        44,chapter:         5,versenumber:        39,verse:"若是出于　神，你们就不能败坏他们，恐怕你们倒是攻击　神了。”"},
{volumn:        44,chapter:         5,versenumber:        40,verse:"公会的人听从了他，便叫使徒来，把他们打了，又吩咐他们不可奉耶稣的名讲道，就把他们释放了。"},
{volumn:        44,chapter:         5,versenumber:        41,verse:"他们离开公会，心里欢喜，因被算是配为这名受辱。"},
{volumn:        44,chapter:         5,versenumber:        42,verse:"他们就每日在殿里、在家里不住地教训人，传耶稣是基督。"},
{volumn:        44,chapter:         6,versenumber:         1,verse:"那时，门徒增多，有说希腊话的犹太人向希伯来人发怨言，因为在天天的供给上忽略了他们的寡妇。"},
{volumn:        44,chapter:         6,versenumber:         2,verse:"十二使徒叫众门徒来，对他们说：“我们撇下　神的道去管理饭食，原是不合宜的。"},
{volumn:        44,chapter:         6,versenumber:         3,verse:"所以弟兄们，当从你们中间选出七个有好名声、被圣灵充满、智慧充足的人，我们就派他们管理这事。"},
{volumn:        44,chapter:         6,versenumber:         4,verse:"但我们要专心以祈祷、传道为事。”"},
{volumn:        44,chapter:         6,versenumber:         5,verse:"大众都喜悦这话，就拣选了司提反，乃是大有信心、圣灵充满的人，又拣选腓利、伯罗哥罗、尼迦挪、提门、巴米拿，并进犹太教的安提阿人尼哥拉，"},
{volumn:        44,chapter:         6,versenumber:         6,verse:"叫他们站在使徒面前。使徒祷告了，就按手在他们头上。"},
{volumn:        44,chapter:         6,versenumber:         7,verse:"神的道兴旺起来；在耶路撒冷门徒数目加增的甚多，也有许多祭司信从了这道。"},
{volumn:        44,chapter:         6,versenumber:         8,verse:"司提反满得恩惠、能力，在民间行了大奇事和神迹。"},
{volumn:        44,chapter:         6,versenumber:         9,verse:"当时有称利百地拿会堂的几个人，并有古利奈、亚历山大、基利家、亚细亚各处会堂的几个人，都起来和司提反辩论。"},
{volumn:        44,chapter:         6,versenumber:        10,verse:"司提反是以智慧和圣灵说话，众人敌挡不住，"},
{volumn:        44,chapter:         6,versenumber:        11,verse:"就买出人来说：“我们听见他说谤讟摩西和　神的话。”"},
{volumn:        44,chapter:         6,versenumber:        12,verse:"他们又耸动了百姓、长老，并文士，就忽然来捉拿他，把他带到公会去，"},
{volumn:        44,chapter:         6,versenumber:        13,verse:"设下假见证，说：“这个人说话，不住地糟践圣所和律法。"},
{volumn:        44,chapter:         6,versenumber:        14,verse:"我们曾听见他说，这拿撒勒人耶稣要毁坏此地，也要改变摩西所交给我们的规条。”"},
{volumn:        44,chapter:         6,versenumber:        15,verse:"在公会里坐着的人都定睛看他，见他的面貌，好像天使的面貌。"},
{volumn:        44,chapter:         7,versenumber:         1,verse:"大祭司就说：“这些事果然有吗？”"},
{volumn:        44,chapter:         7,versenumber:         2,verse:"司提反说：“诸位父兄请听！当日我们的祖宗亚伯拉罕在美索不达米亚还未住哈兰的时候，荣耀的　神向他显现，"},
{volumn:        44,chapter:         7,versenumber:         3,verse:"对他说：‘你要离开本地和亲族，往我所要指示你的地方去。’"},
{volumn:        44,chapter:         7,versenumber:         4,verse:"他就离开迦勒底人之地，住在哈兰。他父亲死了以后，　神使他从那里搬到你们现在所住之地。"},
{volumn:        44,chapter:         7,versenumber:         5,verse:"在这地方，　神并没有给他产业，连立足之地也没有给他；但应许要将这地赐给他和他的后裔为业；那时他还没有儿子。"},
{volumn:        44,chapter:         7,versenumber:         6,verse:"神说：‘他的后裔必寄居外邦，那里的人要叫他们作奴仆，苦待他们四百年。’"},
{volumn:        44,chapter:         7,versenumber:         7,verse:"神又说：‘使他们作奴仆的那国，我要惩罚。以后他们要出来，在这地方侍奉我。’"},
{volumn:        44,chapter:         7,versenumber:         8,verse:"神又赐他割礼的约。于是亚伯拉罕生了以撒，第八日给他行了割礼。以撒生雅各；雅各生十二位先祖。"},
{volumn:        44,chapter:         7,versenumber:         9,verse:"“先祖嫉妒约瑟，把他卖到埃及去；　神却与他同在，"},
{volumn:        44,chapter:         7,versenumber:        10,verse:"救他脱离一切苦难，又使他在埃及王法老面前得恩典，有智慧。法老就派他作埃及国的宰相兼管全家。"},
{volumn:        44,chapter:         7,versenumber:        11,verse:"后来埃及和迦南全地遭遇饥荒，大受艰难，我们的祖宗就绝了粮。"},
{volumn:        44,chapter:         7,versenumber:        12,verse:"雅各听见在埃及有粮，就打发我们的祖宗初次往那里去。"},
{volumn:        44,chapter:         7,versenumber:        13,verse:"第二次约瑟与弟兄们相认，他的亲族也被法老知道了。"},
{volumn:        44,chapter:         7,versenumber:        14,verse:"约瑟就打发弟兄请父亲雅各和全家七十五个人都来。"},
{volumn:        44,chapter:         7,versenumber:        15,verse:"于是雅各下了埃及，后来他和我们的祖宗都死在那里；"},
{volumn:        44,chapter:         7,versenumber:        16,verse:"又被带到示剑，葬于亚伯拉罕在示剑用银子从哈抹子孙买来的坟墓里。"},
{volumn:        44,chapter:         7,versenumber:        17,verse:"“及至　神应许亚伯拉罕的日期将到，以色列民在埃及兴盛众多，"},
{volumn:        44,chapter:         7,versenumber:        18,verse:"直到有不晓得约瑟的新王兴起。"},
{volumn:        44,chapter:         7,versenumber:        19,verse:"他用诡计待我们的宗族，苦害我们的祖宗，叫他们丢弃婴孩，使婴孩不能存活。"},
{volumn:        44,chapter:         7,versenumber:        20,verse:"那时，摩西生下来，俊美非凡，在他父亲家里抚养了三个月。"},
{volumn:        44,chapter:         7,versenumber:        21,verse:"他被丢弃的时候，法老的女儿拾了去，养为自己的儿子。"},
{volumn:        44,chapter:         7,versenumber:        22,verse:"摩西学了埃及人一切的学问，说话行事都有才能。"},
{volumn:        44,chapter:         7,versenumber:        23,verse:"“他将到四十岁，心中起意去看望他的弟兄以色列人；"},
{volumn:        44,chapter:         7,versenumber:        24,verse:"到了那里，见他们一个人受冤屈，就护庇他，为那受欺压的人报仇，打死了那埃及人。"},
{volumn:        44,chapter:         7,versenumber:        25,verse:"他以为弟兄必明白　神是藉他的手搭救他们；他们却不明白。"},
{volumn:        44,chapter:         7,versenumber:        26,verse:"第二天，遇见两个以色列人争斗，就劝他们和睦，说：‘你们二位是弟兄，为什么彼此欺负呢？’"},
{volumn:        44,chapter:         7,versenumber:        27,verse:"那欺负邻舍的把他推开，说：‘谁立你作我们的首领和审判官呢？"},
{volumn:        44,chapter:         7,versenumber:        28,verse:"难道你要杀我，像昨天杀那埃及人吗？’"},
{volumn:        44,chapter:         7,versenumber:        29,verse:"摩西听见这话就逃走了，寄居于米甸；在那里生了两个儿子。"},
{volumn:        44,chapter:         7,versenumber:        30,verse:"“过了四十年，在西奈山的旷野，有一位天使从荆棘火焰中向摩西显现。"},
{volumn:        44,chapter:         7,versenumber:        31,verse:"摩西见了那异象，便觉希奇，正进前观看的时候，有主的声音说："},
{volumn:        44,chapter:         7,versenumber:        32,verse:"‘我是你列祖的　神，就是亚伯拉罕的　神，以撒的　神，雅各的神。’摩西战战兢兢，不敢观看。"},
{volumn:        44,chapter:         7,versenumber:        33,verse:"主对他说：‘把你脚上的鞋脱下来，因为你所站之地是圣地。"},
{volumn:        44,chapter:         7,versenumber:        34,verse:"我的百姓在埃及所受的困苦，我实在看见了，他们悲叹的声音，我也听见了。我下来要救他们。你来！我要差你往埃及去。’"},
{volumn:        44,chapter:         7,versenumber:        35,verse:"这摩西就是百姓弃绝说‘谁立你作我们的首领和审判官’的；　神却藉那在荆棘中显现之使者的手差派他作首领、作救赎的。"},
{volumn:        44,chapter:         7,versenumber:        36,verse:"这人领百姓出来，在埃及，在红海，在旷野，四十年间行了奇事神迹。"},
{volumn:        44,chapter:         7,versenumber:        37,verse:"那曾对以色列人说‘　神要从你们弟兄中间给你们兴起一位先知像我’的，就是这位摩西。"},
{volumn:        44,chapter:         7,versenumber:        38,verse:"这人曾在旷野会中和西奈山上，与那对他说话的天使同在，又与我们的祖宗同在，并且领受活泼的圣言传给我们。"},
{volumn:        44,chapter:         7,versenumber:        39,verse:"我们的祖宗不肯听从，反弃绝他，心里归向埃及，"},
{volumn:        44,chapter:         7,versenumber:        40,verse:"对亚伦说：‘你且为我们造些神像，在我们前面引路；因为领我们出埃及地的那个摩西，我们不知道他遭了什么事。’"},
{volumn:        44,chapter:         7,versenumber:        41,verse:"那时，他们造了一个牛犊，又拿祭物献给那像，欢喜自己手中的工作。"},
{volumn:        44,chapter:         7,versenumber:        42,verse:"神就转脸不顾，任凭他们侍奉天上的日月星辰，正如先知书上所写的说：‘以色列家啊，你们四十年间在旷野，岂是将牺牲和祭物献给我吗？"},
{volumn:        44,chapter:         7,versenumber:        43,verse:"你们抬着摩洛的帐幕和理番神的星，就是你们所造为要敬拜的像。因此，我要把你们迁到巴比伦外去。’"},
{volumn:        44,chapter:         7,versenumber:        44,verse:"“我们的祖宗在旷野，有法柜的帐幕，是　神吩咐摩西叫他照所看见的样式做的。"},
{volumn:        44,chapter:         7,versenumber:        45,verse:"这帐幕，我们的祖宗相继承受。当　神在他们面前赶出外邦人去的时候，他们同约书亚把帐幕搬进承受为业之地，直存到大卫的日子。"},
{volumn:        44,chapter:         7,versenumber:        46,verse:"大卫在　神面前蒙恩，祈求为雅各的　神预备居所；"},
{volumn:        44,chapter:         7,versenumber:        47,verse:"却是所罗门为　神造成殿宇。"},
{volumn:        44,chapter:         7,versenumber:        48,verse:"其实，至高者并不住人手所造的，就如先知所言："},
{volumn:        44,chapter:         7,versenumber:        49,verse:"‘主说：天是我的座位，地是我的脚凳；你们要为我造何等的殿宇？哪里是我安息的地方呢？"},
{volumn:        44,chapter:         7,versenumber:        50,verse:"这一切不都是我手所造的吗？’"},
{volumn:        44,chapter:         7,versenumber:        51,verse:"“你们这硬着颈项、心与耳未受割礼的人，时常抗拒圣灵！你们的祖宗怎样，你们也怎样。"},
{volumn:        44,chapter:         7,versenumber:        52,verse:"哪一个先知不是你们祖宗逼迫呢？他们也把预先传说那义者要来的人杀了；如今你们又把那义者卖了，杀了。"},
{volumn:        44,chapter:         7,versenumber:        53,verse:"你们受了天使所传的律法，竟不遵守。”"},
{volumn:        44,chapter:         7,versenumber:        54,verse:"众人听见这话就极其恼怒，向司提反咬牙切齿。"},
{volumn:        44,chapter:         7,versenumber:        55,verse:"但司提反被圣灵充满，定睛望天，看见　神的荣耀，又看见耶稣站在　神的右边，"},
{volumn:        44,chapter:         7,versenumber:        56,verse:"就说：“我看见天开了，人子站在　神的右边。”"},
{volumn:        44,chapter:         7,versenumber:        57,verse:"众人大声喊叫，捂着耳朵，齐心拥上前去，"},
{volumn:        44,chapter:         7,versenumber:        58,verse:"把他推到城外，用石头打他。作见证的人把衣裳放在一个少年人名叫扫罗的脚前。"},
{volumn:        44,chapter:         7,versenumber:        59,verse:"他们正用石头打的时候，司提反呼吁主说：“求主耶稣接收我的灵魂！”"},
{volumn:        44,chapter:         7,versenumber:        60,verse:"又跪下大声喊着说：“主啊，不要将这罪归于他们！”说了这话，就睡了。扫罗也喜悦他被害。"},
{volumn:        44,chapter:         8,versenumber:         1,verse:"从这日起，耶路撒冷的教会大遭逼迫，除了使徒以外，门徒都分散在犹太和撒玛利亚各处。"},
{volumn:        44,chapter:         8,versenumber:         2,verse:"有虔诚的人把司提反埋葬了，为他捶胸大哭。"},
{volumn:        44,chapter:         8,versenumber:         3,verse:"扫罗却残害教会，进各人的家，拉着男女下在监里。"},
{volumn:        44,chapter:         8,versenumber:         4,verse:"那些分散的人往各处去传道。"},
{volumn:        44,chapter:         8,versenumber:         5,verse:"腓利下撒玛利亚城去，宣讲基督。"},
{volumn:        44,chapter:         8,versenumber:         6,verse:"众人听见了，又看见腓利所行的神迹，就同心合意地听从他的话。"},
{volumn:        44,chapter:         8,versenumber:         7,verse:"因为有许多人被污鬼附着，那些鬼大声呼叫，从他们身上出来；还有许多瘫痪的、瘸腿的，都得了医治。"},
{volumn:        44,chapter:         8,versenumber:         8,verse:"在那城里，就大有欢喜。"},
{volumn:        44,chapter:         8,versenumber:         9,verse:"有一个人，名叫西门，向来在那城里行邪术，妄自尊大，使撒玛利亚的百姓惊奇；"},
{volumn:        44,chapter:         8,versenumber:        10,verse:"无论大小都听从他，说：“这人就是那称为　神的大能者。”"},
{volumn:        44,chapter:         8,versenumber:        11,verse:"他们听从他，因他久用邪术，使他们惊奇。"},
{volumn:        44,chapter:         8,versenumber:        12,verse:"及至他们信了腓利所传　神国的福音和耶稣基督的名，连男带女就受了洗。"},
{volumn:        44,chapter:         8,versenumber:        13,verse:"西门自己也信了；既受了洗，就常与腓利在一处，看见他所行的神迹和大异能，就甚惊奇。"},
{volumn:        44,chapter:         8,versenumber:        14,verse:"使徒在耶路撒冷听见撒玛利亚人领受了　神的道，就打发彼得、约翰往他们那里去。"},
{volumn:        44,chapter:         8,versenumber:        15,verse:"两个人到了，就为他们祷告，要叫他们受圣灵。"},
{volumn:        44,chapter:         8,versenumber:        16,verse:"因为圣灵还没有降在他们一个人身上，他们只奉主耶稣的名受了洗。"},
{volumn:        44,chapter:         8,versenumber:        17,verse:"于是使徒按手在他们头上，他们就受了圣灵。"},
{volumn:        44,chapter:         8,versenumber:        18,verse:"西门看见使徒按手，便有圣灵赐下，就拿钱给使徒，"},
{volumn:        44,chapter:         8,versenumber:        19,verse:"说：“把这权柄也给我，叫我手按着谁，谁就可以受圣灵。”"},
{volumn:        44,chapter:         8,versenumber:        20,verse:"彼得说：“你的银子和你一同灭亡吧！因你想　神的恩赐是可以用钱买的。"},
{volumn:        44,chapter:         8,versenumber:        21,verse:"你在这道上无份无关；因为在　神面前，你的心不正。"},
{volumn:        44,chapter:         8,versenumber:        22,verse:"你当懊悔你这罪恶，祈求主，或者你心里的意念可得赦免。"},
{volumn:        44,chapter:         8,versenumber:        23,verse:"我看出你正在苦胆之中，被罪恶捆绑。”"},
{volumn:        44,chapter:         8,versenumber:        24,verse:"西门说：“愿你们为我求主，叫你们所说的，没有一样临到我身上。”"},
{volumn:        44,chapter:         8,versenumber:        25,verse:"使徒既证明主道，而且传讲，就回耶路撒冷去，一路在撒玛利亚好些村庄传扬福音。"},
{volumn:        44,chapter:         8,versenumber:        26,verse:"有主的一个使者对腓利说：“起来！向南走，往那从耶路撒冷下迦萨的路上去。”那路是旷野。"},
{volumn:        44,chapter:         8,versenumber:        27,verse:"腓利就起身去了，不料，有一个埃塞俄比亚（就是古实，见以赛亚十八章一节）人，是个有大权的太监，在埃塞俄比亚女王干大基的手下总管银库，他上耶路撒冷礼拜去了。"},
{volumn:        44,chapter:         8,versenumber:        28,verse:"现在回来，在车上坐着，念先知以赛亚的书。"},
{volumn:        44,chapter:         8,versenumber:        29,verse:"圣灵对腓利说：“你去！贴近那车走。”"},
{volumn:        44,chapter:         8,versenumber:        30,verse:"腓利就跑到太监那里，听见他念先知以赛亚的书，便问他说：“你所念的，你明白吗？”"},
{volumn:        44,chapter:         8,versenumber:        31,verse:"他说：“没有人指教我，怎能明白呢？”于是请腓利上车，与他同坐。"},
{volumn:        44,chapter:         8,versenumber:        32,verse:"他所念的那段经，说：“他像羊被牵到宰杀之地，又像羊羔在剪毛的人手下无声；他也是这样不开口。"},
{volumn:        44,chapter:         8,versenumber:        33,verse:"他卑微的时候，人不按公义审判他（原文是他的审判被夺去）；谁能述说他的世代？因为他的生命从地上夺去。”"},
{volumn:        44,chapter:         8,versenumber:        34,verse:"太监对腓利说：“请问，先知说这话是指着谁？是指着自己呢？是指着别人呢？”"},
{volumn:        44,chapter:         8,versenumber:        35,verse:"腓利就开口从这经上起，对他传讲耶稣。"},
{volumn:        44,chapter:         8,versenumber:        36,verse:"二人正往前走，到了有水的地方，太监说：“看哪，这里有水，我受洗有什么妨碍呢？”（有古卷加：37腓利说：“你若是一心相信，就可以。”他回答说：“我信耶稣基督是　神的儿子。”）"},
{volumn:        44,chapter:         8,versenumber:        37,verse:""},
{volumn:        44,chapter:         8,versenumber:        38,verse:"于是吩咐车站住，腓利和太监二人同下水里去，腓利就给他施洗。"},
{volumn:        44,chapter:         8,versenumber:        39,verse:"从水里上来，主的灵把腓利提了去，太监也不再见他了，就欢欢喜喜地走路。"},
{volumn:        44,chapter:         8,versenumber:        40,verse:"后来有人在亚锁都遇见腓利；他走遍那地方，在各城宣传福音，直到凯撒利亚。"},
{volumn:        44,chapter:         9,versenumber:         1,verse:"扫罗仍然向主的门徒口吐威吓凶杀的话，去见大祭司，"},
{volumn:        44,chapter:         9,versenumber:         2,verse:"求文书给大马士革的各会堂，若是找着信奉这道的人，无论男女，都准他捆绑带到耶路撒冷。"},
{volumn:        44,chapter:         9,versenumber:         3,verse:"扫罗行路，将到大马士革，忽然从天上发光，四面照着他；"},
{volumn:        44,chapter:         9,versenumber:         4,verse:"他就仆倒在地，听见有声音对他说：“扫罗！扫罗！你为什么逼迫我？”"},
{volumn:        44,chapter:         9,versenumber:         5,verse:"他说：“主啊！你是谁？”主说：“我就是你所逼迫的耶稣。"},
{volumn:        44,chapter:         9,versenumber:         6,verse:"起来！进城去，你所当做的事，必有人告诉你。”"},
{volumn:        44,chapter:         9,versenumber:         7,verse:"同行的人站在那里，说不出话来，听见声音，却看不见人。"},
{volumn:        44,chapter:         9,versenumber:         8,verse:"扫罗从地上起来，睁开眼睛，竟不能看见什么。有人拉他的手，领他进了大马士革；"},
{volumn:        44,chapter:         9,versenumber:         9,verse:"三日不能看见，也不吃也不喝。"},
{volumn:        44,chapter:         9,versenumber:        10,verse:"当下，在大马士革有一个门徒，名叫亚拿尼亚。主在异象中对他说：“亚拿尼亚。”他说：“主，我在这里。”"},
{volumn:        44,chapter:         9,versenumber:        11,verse:"主对他说：“起来！往直街去，在犹大的家里，访问一个大数人，名叫扫罗。他正祷告，"},
{volumn:        44,chapter:         9,versenumber:        12,verse:"又看见了一个人，名叫亚拿尼亚，进来按手在他身上，叫他能看见。”"},
{volumn:        44,chapter:         9,versenumber:        13,verse:"亚拿尼亚回答说：“主啊，我听见许多人说，这人怎样在耶路撒冷多多苦害你的圣徒，"},
{volumn:        44,chapter:         9,versenumber:        14,verse:"并且他在这里有从祭司长得来的权柄捆绑一切求告你名的人。”"},
{volumn:        44,chapter:         9,versenumber:        15,verse:"主对亚拿尼亚说：“你只管去！他是我所拣选的器皿，要在外邦人和君王，并以色列人面前宣扬我的名。"},
{volumn:        44,chapter:         9,versenumber:        16,verse:"我也要指示他，为我的名必须受许多的苦难。”"},
{volumn:        44,chapter:         9,versenumber:        17,verse:"亚拿尼亚就去了，进入那家，把手按在扫罗身上，说：“兄弟扫罗，在你来的路上向你显现的主，就是耶稣，打发我来，叫你能看见，又被圣灵充满。”"},
{volumn:        44,chapter:         9,versenumber:        18,verse:"扫罗的眼睛上，好像有鳞立刻掉下来，他就能看见。于是起来受了洗；"},
{volumn:        44,chapter:         9,versenumber:        19,verse:"吃过饭就健壮了。扫罗和大马士革的门徒同住了些日子，"},
{volumn:        44,chapter:         9,versenumber:        20,verse:"就在各会堂里宣传耶稣，说他是　神的儿子。"},
{volumn:        44,chapter:         9,versenumber:        21,verse:"凡听见的人都惊奇，说：“在耶路撒冷残害求告这名的，不是这人吗？并且他到这里来，特要捆绑他们，带到祭司长那里。”"},
{volumn:        44,chapter:         9,versenumber:        22,verse:"但扫罗越发有能力，驳倒住大马士革的犹太人，证明耶稣是基督。"},
{volumn:        44,chapter:         9,versenumber:        23,verse:"过了好些日子，犹太人商议要杀扫罗，"},
{volumn:        44,chapter:         9,versenumber:        24,verse:"但他们的计谋被扫罗知道了。他们又昼夜在城门守候，要杀他。"},
{volumn:        44,chapter:         9,versenumber:        25,verse:"他的门徒就在夜间用筐子把他从城墙上缒下去。"},
{volumn:        44,chapter:         9,versenumber:        26,verse:"扫罗到了耶路撒冷，想与门徒结交，他们却都怕他，不信他是门徒。"},
{volumn:        44,chapter:         9,versenumber:        27,verse:"惟有巴拿巴接待他，领去见使徒，把他在路上怎么看见主，主怎么向他说话，他在大马士革怎么奉耶稣的名放胆传道，都述说出来。"},
{volumn:        44,chapter:         9,versenumber:        28,verse:"于是扫罗在耶路撒冷和门徒出入来往，"},
{volumn:        44,chapter:         9,versenumber:        29,verse:"奉主的名放胆传道，并与说希腊话的犹太人讲论辩驳；他们却想法子要杀他。"},
{volumn:        44,chapter:         9,versenumber:        30,verse:"弟兄们知道了就送他下凯撒利亚，打发他往大数去。"},
{volumn:        44,chapter:         9,versenumber:        31,verse:"那时，犹太、加利利、撒玛利亚各处的教会都得平安，被建立；凡事敬畏主，蒙圣灵的安慰，人数就增多了。"},
{volumn:        44,chapter:         9,versenumber:        32,verse:"彼得周流四方的时候，也到了居住吕大的圣徒那里；"},
{volumn:        44,chapter:         9,versenumber:        33,verse:"遇见一个人，名叫以尼雅，得了瘫痪，在褥子上躺卧八年。"},
{volumn:        44,chapter:         9,versenumber:        34,verse:"彼得对他说：“以尼雅，耶稣基督医好你了，起来！收拾你的褥子。”他就立刻起来了。"},
{volumn:        44,chapter:         9,versenumber:        35,verse:"凡住吕大和沙仑的人都看见了他，就归服主。"},
{volumn:        44,chapter:         9,versenumber:        36,verse:"在约帕有一个女徒，名叫大比大，翻希腊话就是多加（就是羚羊的意思）；她广行善事，多施周济。"},
{volumn:        44,chapter:         9,versenumber:        37,verse:"当时，她患病而死，有人把她洗了，停在楼上。"},
{volumn:        44,chapter:         9,versenumber:        38,verse:"吕大原与约帕相近；门徒听见彼得在那里，就打发两个人去见他，央求他说：“快到我们那里去，不要耽延。”"},
{volumn:        44,chapter:         9,versenumber:        39,verse:"彼得就起身和他们同去；到了，便有人领他上楼。众寡妇都站在彼得旁边哭，拿多加与她们同在时所做的里衣外衣给他看。"},
{volumn:        44,chapter:         9,versenumber:        40,verse:"彼得叫她们都出去，就跪下祷告，转身对着死人说：“大比大，起来！”她就睁开眼睛，见了彼得，便坐起来。"},
{volumn:        44,chapter:         9,versenumber:        41,verse:"彼得伸手扶她起来，叫众圣徒和寡妇进去，把多加活活地交给他们。"},
{volumn:        44,chapter:         9,versenumber:        42,verse:"这事传遍了约帕，就有许多人信了主。"},
{volumn:        44,chapter:         9,versenumber:        43,verse:"此后，彼得在约帕一个硝皮匠西门的家里住了多日。"},
{volumn:        44,chapter:        10,versenumber:         1,verse:"在凯撒利亚有一个人，名叫哥尼流，是“意大利营”的百夫长。"},
{volumn:        44,chapter:        10,versenumber:         2,verse:"他是个虔诚人，他和全家都敬畏　神，多多周济百姓，常常祷告　神。"},
{volumn:        44,chapter:        10,versenumber:         3,verse:"有一天，约在申初，他在异象中明明看见　神的一个使者进去，到他那里，说：“哥尼流。”"},
{volumn:        44,chapter:        10,versenumber:         4,verse:"哥尼流定睛看他，惊怕说：“主啊，什么事呢？”天使说：“你的祷告和你的周济达到　神面前，已蒙记念了。"},
{volumn:        44,chapter:        10,versenumber:         5,verse:"现在你当打发人往约帕去，请那称呼彼得的西门来。"},
{volumn:        44,chapter:        10,versenumber:         6,verse:"他住在海边一个硝皮匠西门的家里，房子在海边上。”"},
{volumn:        44,chapter:        10,versenumber:         7,verse:"向他说话的天使去后，哥尼流叫了两个家人和常伺候他的一个虔诚兵来，"},
{volumn:        44,chapter:        10,versenumber:         8,verse:"把这事都述说给他们听，就打发他们往约帕去。"},
{volumn:        44,chapter:        10,versenumber:         9,verse:"第二天，他们行路将近那城。彼得约在午正，上房顶去祷告，"},
{volumn:        44,chapter:        10,versenumber:        10,verse:"觉得饿了，想要吃。那家的人正预备饭的时候，彼得魂游象外，"},
{volumn:        44,chapter:        10,versenumber:        11,verse:"看见天开了，有一物降下，好像一块大布，系着四角，缒在地上，"},
{volumn:        44,chapter:        10,versenumber:        12,verse:"里面有地上各样四足的走兽和昆虫，并天上的飞鸟；"},
{volumn:        44,chapter:        10,versenumber:        13,verse:"又有声音向他说：“彼得，起来，宰了吃！”"},
{volumn:        44,chapter:        10,versenumber:        14,verse:"彼得却说：“主啊，这是不可的！凡俗物和不洁净的物，我从来没有吃过。”"},
{volumn:        44,chapter:        10,versenumber:        15,verse:"第二次有声音向他说：“　神所洁净的，你不可当作俗物。”"},
{volumn:        44,chapter:        10,versenumber:        16,verse:"这样一连三次，那物随即收回天上去了。"},
{volumn:        44,chapter:        10,versenumber:        17,verse:"彼得心里正在猜疑之间，不知所看见的异象是什么意思。哥尼流所差来的人已经访问到西门的家，站在门外，"},
{volumn:        44,chapter:        10,versenumber:        18,verse:"喊着问：“有称呼彼得的西门住在这里没有？”"},
{volumn:        44,chapter:        10,versenumber:        19,verse:"彼得还思想那异象的时候，圣灵向他说：“有三个人来找你。"},
{volumn:        44,chapter:        10,versenumber:        20,verse:"起来，下去，和他们同往，不要疑惑，因为是我差他们来的。”"},
{volumn:        44,chapter:        10,versenumber:        21,verse:"于是彼得下去见那些人，说：“我就是你们所找的人。你们来是为什么缘故？”"},
{volumn:        44,chapter:        10,versenumber:        22,verse:"他们说：“百夫长哥尼流是个义人，敬畏　神，为犹太通国所称赞。他蒙一位圣天使所指示，叫他请你到他家里去，听你的话。”"},
{volumn:        44,chapter:        10,versenumber:        23,verse:"彼得就请他们进去，住了一宿。次日，起身和他们同去，还有约帕的几个弟兄同着他去；"},
{volumn:        44,chapter:        10,versenumber:        24,verse:"又次日，他们进入凯撒利亚，哥尼流已经请了他的亲属密友等候他们。"},
{volumn:        44,chapter:        10,versenumber:        25,verse:"彼得一进去，哥尼流就迎接他，俯伏在他脚前拜他。"},
{volumn:        44,chapter:        10,versenumber:        26,verse:"彼得却拉他，说：“你起来，我也是人。”"},
{volumn:        44,chapter:        10,versenumber:        27,verse:"彼得和他说着话进去，见有好些人在那里聚集，"},
{volumn:        44,chapter:        10,versenumber:        28,verse:"就对他们说：“你们知道，犹太人和别国的人亲近来往本是不合例的，但　神已经指示我，无论什么人都不可看作俗而不洁净的。"},
{volumn:        44,chapter:        10,versenumber:        29,verse:"所以我被请的时候，就不推辞而来。现在请问：你们叫我来有什么意思呢？”"},
{volumn:        44,chapter:        10,versenumber:        30,verse:"哥尼流说：“前四天，这个时候，我在家中守着申初的祷告，忽然有一个人穿着光明的衣裳，站在我面前，"},
{volumn:        44,chapter:        10,versenumber:        31,verse:"说：‘哥尼流，你的祷告已蒙垂听，你的周济达到　神面前已蒙记念了。"},
{volumn:        44,chapter:        10,versenumber:        32,verse:"你当打发人往约帕去，请那称呼彼得的西门来，他住在海边一个硝皮匠西门的家里。’"},
{volumn:        44,chapter:        10,versenumber:        33,verse:"所以我立时打发人去请你。你来了很好；现今我们都在　神面前，要听主所吩咐你的一切话。”"},
{volumn:        44,chapter:        10,versenumber:        34,verse:"彼得就开口说：“我真看出　神是不偏待人。"},
{volumn:        44,chapter:        10,versenumber:        35,verse:"原来，各国中那敬畏主、行义的人都为主所悦纳。"},
{volumn:        44,chapter:        10,versenumber:        36,verse:"神藉着耶稣基督（他是万有的主）传和平的福音，将这道赐给以色列人。"},
{volumn:        44,chapter:        10,versenumber:        37,verse:"这话在约翰宣传洗礼以后，从加利利起，传遍了犹太。"},
{volumn:        44,chapter:        10,versenumber:        38,verse:"神怎样以圣灵和能力膏拿撒勒人耶稣，这都是你们知道的。他周流四方，行善事，医好凡被魔鬼压制的人，因为　神与他同在。"},
{volumn:        44,chapter:        10,versenumber:        39,verse:"他在犹太人之地，并耶路撒冷所行的一切事，有我们作见证。他们竟把他挂在木头上杀了。"},
{volumn:        44,chapter:        10,versenumber:        40,verse:"第三日，　神叫他复活，显现出来；"},
{volumn:        44,chapter:        10,versenumber:        41,verse:"不是显现给众人看，乃是显现给　神预先所拣选为他作见证的人看，就是我们这些在他从死里复活以后和他同吃同喝的人。"},
{volumn:        44,chapter:        10,versenumber:        42,verse:"他吩咐我们传道给众人，证明他是　神所立定的，要作审判活人、死人的主。"},
{volumn:        44,chapter:        10,versenumber:        43,verse:"众先知也为他作见证说：‘凡信他的人必因他的名得蒙赦罪。’”"},
{volumn:        44,chapter:        10,versenumber:        44,verse:"彼得还说这话的时候，圣灵降在一切听道的人身上。"},
{volumn:        44,chapter:        10,versenumber:        45,verse:"那些奉割礼、和彼得同来的信徒，见圣灵的恩赐也浇在外邦人身上，就都希奇；"},
{volumn:        44,chapter:        10,versenumber:        46,verse:"因听见他们说方言，称赞　神为大。"},
{volumn:        44,chapter:        10,versenumber:        47,verse:"于是彼得说：“这些人既受了圣灵，与我们一样，谁能禁止用水给他们施洗呢？”"},
{volumn:        44,chapter:        10,versenumber:        48,verse:"就吩咐奉耶稣基督的名给他们施洗。他们又请彼得住了几天。"},
{volumn:        44,chapter:        11,versenumber:         1,verse:"使徒和在犹太的众弟兄听说外邦人也领受了　神的道。"},
{volumn:        44,chapter:        11,versenumber:         2,verse:"及至彼得上了耶路撒冷，那些奉割礼的门徒和他争辩说："},
{volumn:        44,chapter:        11,versenumber:         3,verse:"“你进入未受割礼之人的家和他们一同吃饭了。”"},
{volumn:        44,chapter:        11,versenumber:         4,verse:"彼得就开口把这事挨次给他们讲解说："},
{volumn:        44,chapter:        11,versenumber:         5,verse:"“我在约帕城里祷告的时候，魂游象外，看见异象，有一物降下，好像一块大布，系着四角，从天缒下，直来到我跟前。"},
{volumn:        44,chapter:        11,versenumber:         6,verse:"我定睛观看，见内中有地上四足的牲畜和野兽、昆虫，并天上的飞鸟。"},
{volumn:        44,chapter:        11,versenumber:         7,verse:"我且听见有声音向我说：‘彼得，起来，宰了吃！’"},
{volumn:        44,chapter:        11,versenumber:         8,verse:"我说：‘主啊，这是不可的！凡俗而不洁净的物从来没有入过我的口。’"},
{volumn:        44,chapter:        11,versenumber:         9,verse:"第二次，有声音从天上说：‘　神所洁净的，你不可当作俗物。’"},
{volumn:        44,chapter:        11,versenumber:        10,verse:"这样一连三次，就都收回天上去了。"},
{volumn:        44,chapter:        11,versenumber:        11,verse:"正当那时，有三个人站在我们所住的房门前，是从凯撒利亚差来见我的。"},
{volumn:        44,chapter:        11,versenumber:        12,verse:"圣灵吩咐我和他们同去，不要疑惑（或译：不要分别等类）。同着我去的，还有这六位弟兄，我们都进了那人的家。"},
{volumn:        44,chapter:        11,versenumber:        13,verse:"那人就告诉我们，他如何看见一位天使站在他屋里，说：‘你打发人往约帕去，请那称呼彼得的西门来，"},
{volumn:        44,chapter:        11,versenumber:        14,verse:"他有话告诉你，可以叫你和你的全家得救。’"},
{volumn:        44,chapter:        11,versenumber:        15,verse:"我一开讲，圣灵便降在他们身上，正像当初降在我们身上一样。"},
{volumn:        44,chapter:        11,versenumber:        16,verse:"我就想起主的话说：‘约翰是用水施洗，但你们要受圣灵的洗。’"},
{volumn:        44,chapter:        11,versenumber:        17,verse:"神既然给他们恩赐，像在我们信主耶稣基督的时候给了我们一样；我是谁，能拦阻神呢？”"},
{volumn:        44,chapter:        11,versenumber:        18,verse:"众人听见这话，就不言语了，只归荣耀与　神，说：“这样看来，　神也赐恩给外邦人，叫他们悔改得生命了。”"},
{volumn:        44,chapter:        11,versenumber:        19,verse:"那些因司提反的事遭患难四散的门徒直走到腓尼基和塞浦路斯，并安提阿；他们不向别人讲道，只向犹太人讲。"},
{volumn:        44,chapter:        11,versenumber:        20,verse:"但内中有塞浦路斯和古利奈人，他们到了安提阿也向希腊人传讲主耶稣（有古卷：也向说希腊话的犹太人传讲主耶稣）。"},
{volumn:        44,chapter:        11,versenumber:        21,verse:"主与他们同在，信而归主的人就很多了。"},
{volumn:        44,chapter:        11,versenumber:        22,verse:"这风声传到耶路撒冷教会人的耳中，他们就打发巴拿巴出去，走到安提阿为止。"},
{volumn:        44,chapter:        11,versenumber:        23,verse:"他到了那里，看见　神所赐的恩就欢喜，劝勉众人，立定心志，恒久靠主。"},
{volumn:        44,chapter:        11,versenumber:        24,verse:"这巴拿巴原是个好人，被圣灵充满，大有信心。于是有许多人归服了主。"},
{volumn:        44,chapter:        11,versenumber:        25,verse:"他又往大数去找扫罗，"},
{volumn:        44,chapter:        11,versenumber:        26,verse:"找着了，就带他到安提阿去。他们足有一年的工夫和教会一同聚集，教训了许多人。门徒称为“基督徒”是从安提阿起首。"},
{volumn:        44,chapter:        11,versenumber:        27,verse:"当那些日子，有几位先知从耶路撒冷下到安提阿。"},
{volumn:        44,chapter:        11,versenumber:        28,verse:"内中有一位，名叫亚迦布，站起来，藉着圣灵指明天下将有大饥荒。（这事到革老丢年间果然有了。）"},
{volumn:        44,chapter:        11,versenumber:        29,verse:"于是门徒定意照各人的力量捐钱，送去供给住在犹太的弟兄。"},
{volumn:        44,chapter:        11,versenumber:        30,verse:"他们就这样行，把捐项托巴拿巴和扫罗送到众长老那里。"},
{volumn:        44,chapter:        12,versenumber:         1,verse:"那时，希律王下手苦害教会中几个人，"},
{volumn:        44,chapter:        12,versenumber:         2,verse:"用刀杀了约翰的哥哥雅各。"},
{volumn:        44,chapter:        12,versenumber:         3,verse:"他见犹太人喜欢这事，又去捉拿彼得。那时正是除酵的日子。"},
{volumn:        44,chapter:        12,versenumber:         4,verse:"希律拿了彼得，收在监里，交付四班兵丁看守，每班四个人，意思要在逾越节后把他提出来，当着百姓办他。"},
{volumn:        44,chapter:        12,versenumber:         5,verse:"于是彼得被囚在监里；教会却为他切切地祷告　神。"},
{volumn:        44,chapter:        12,versenumber:         6,verse:"希律将要提他出来的前一夜，彼得被两条铁链锁着，睡在两个兵丁当中；看守的人也在门外看守。"},
{volumn:        44,chapter:        12,versenumber:         7,verse:"忽然，有主的一个使者站在旁边，屋里有光照耀，天使拍彼得的肋旁，拍醒了他，说：“快快起来！”那铁链就从他手上脱落下来。"},
{volumn:        44,chapter:        12,versenumber:         8,verse:"天使对他说：“束上带子，穿上鞋。”他就那样做。天使又说：“披上外衣，跟着我来。”"},
{volumn:        44,chapter:        12,versenumber:         9,verse:"彼得就出来跟着他，不知道天使所做是真的，只当见了异象。"},
{volumn:        44,chapter:        12,versenumber:        10,verse:"过了第一层第二层监牢，就来到临街的铁门，那门自己开了。他们出来，走过一条街，天使便离开他去了。"},
{volumn:        44,chapter:        12,versenumber:        11,verse:"彼得醒悟过来，说：“我现在真知道主差遣他的使者，救我脱离希律的手和犹太百姓一切所盼望的。”"},
{volumn:        44,chapter:        12,versenumber:        12,verse:"想了一想，就往那称呼马可的约翰、他母亲马利亚家去，在那里有好些人聚集祷告。"},
{volumn:        44,chapter:        12,versenumber:        13,verse:"彼得敲外门，有一个使女，名叫罗大，出来探听，"},
{volumn:        44,chapter:        12,versenumber:        14,verse:"听得是彼得的声音，就欢喜的顾不得开门，跑进去告诉众人说：“彼得站在门外。”"},
{volumn:        44,chapter:        12,versenumber:        15,verse:"他们说：“你是疯了！”使女极力地说：“真是他！”他们说：“必是他的天使！”"},
{volumn:        44,chapter:        12,versenumber:        16,verse:"彼得不住地敲门。他们开了门，看见他，就甚惊奇。"},
{volumn:        44,chapter:        12,versenumber:        17,verse:"彼得摆手，不要他们作声，就告诉他们主怎样领他出监；又说：“你们把这事告诉雅各和众弟兄。”于是出去，往别处去了。"},
{volumn:        44,chapter:        12,versenumber:        18,verse:"到了天亮，兵丁扰乱得很，不知道彼得往哪里去了。"},
{volumn:        44,chapter:        12,versenumber:        19,verse:"希律找他，找不着，就审问看守的人，吩咐把他们拉去杀了。后来希律离开犹太，下凯撒利亚去，住在那里。"},
{volumn:        44,chapter:        12,versenumber:        20,verse:"希律恼怒推罗、西顿的人。他们那一带地方是从王的地土得粮，因此就托了王的内侍臣伯拉斯都的情，一心来求和。"},
{volumn:        44,chapter:        12,versenumber:        21,verse:"希律在所定的日子，穿上朝服，坐在位上，对他们讲论一番。"},
{volumn:        44,chapter:        12,versenumber:        22,verse:"百姓喊着说：“这是　神的声音，不是人的声音。”"},
{volumn:        44,chapter:        12,versenumber:        23,verse:"希律不归荣耀给　神，所以主的使者立刻罚他，他被虫所咬，气就绝了。"},
{volumn:        44,chapter:        12,versenumber:        24,verse:"神的道日见兴旺，越发广传。"},
{volumn:        44,chapter:        12,versenumber:        25,verse:"巴拿巴和扫罗办完了他们供给的事，就从耶路撒冷回来，带着称呼马可的约翰同去。"},
{volumn:        44,chapter:        13,versenumber:         1,verse:"在安提阿的教会中，有几位先知和教师，就是巴拿巴和称呼尼结的西面、古利奈人路求，与分封之王希律同养的马念，并扫罗。"},
{volumn:        44,chapter:        13,versenumber:         2,verse:"他们侍奉主、禁食的时候，圣灵说：“要为我分派巴拿巴和扫罗，去做我召他们所做的工。”"},
{volumn:        44,chapter:        13,versenumber:         3,verse:"于是禁食祷告，按手在他们头上，就打发他们去了。"},
{volumn:        44,chapter:        13,versenumber:         4,verse:"他们既被圣灵差遣，就下到西流基，从那里坐船往塞浦路斯去。"},
{volumn:        44,chapter:        13,versenumber:         5,verse:"到了撒拉米，就在犹太人各会堂里传讲　神的道，也有约翰作他们的帮手。"},
{volumn:        44,chapter:        13,versenumber:         6,verse:"经过全岛，直到帕弗，在那里遇见一个有法术、假充先知的犹太人，名叫巴·耶稣。"},
{volumn:        44,chapter:        13,versenumber:         7,verse:"这人常和方伯士求·保罗同在。士求·保罗是个通达人，他请了巴拿巴和扫罗来，要听　神的道。"},
{volumn:        44,chapter:        13,versenumber:         8,verse:"只是那行法术的以吕马（这名翻出来就是行法术的意思）敌挡使徒，要叫方伯不信真道。"},
{volumn:        44,chapter:        13,versenumber:         9,verse:"扫罗又名保罗，被圣灵充满，定睛看他，"},
{volumn:        44,chapter:        13,versenumber:        10,verse:"说：“你这充满各样诡诈奸恶，魔鬼的儿子，众善的仇敌，你混乱主的正道还不止住吗？"},
{volumn:        44,chapter:        13,versenumber:        11,verse:"现在主的手加在你身上，你要瞎眼，暂且不见日光。”他的眼睛立刻昏蒙黑暗，四下里求人拉着手领他。"},
{volumn:        44,chapter:        13,versenumber:        12,verse:"方伯看见所做的事，很希奇主的道，就信了。"},
{volumn:        44,chapter:        13,versenumber:        13,verse:"保罗和他的同人从帕弗开船，来到旁非利亚的别加，约翰就离开他们，回耶路撒冷去。"},
{volumn:        44,chapter:        13,versenumber:        14,verse:"他们离了别加往前行，来到彼西底的安提阿，在安息日进会堂坐下。"},
{volumn:        44,chapter:        13,versenumber:        15,verse:"读完了律法和先知的书，管会堂的叫人过去，对他们说：“二位兄台，若有什么劝勉众人的话，请说。”"},
{volumn:        44,chapter:        13,versenumber:        16,verse:"保罗就站起来，举手，说：“以色列人和一切敬畏　神的人，请听。"},
{volumn:        44,chapter:        13,versenumber:        17,verse:"这以色列民的　神拣选了我们的祖宗，当民寄居埃及的时候抬举他们，用大能的手领他们出来；"},
{volumn:        44,chapter:        13,versenumber:        18,verse:"又在旷野容忍（或译：抚养）他们，约有四十年。"},
{volumn:        44,chapter:        13,versenumber:        19,verse:"既灭了迦南地七族的人，就把那地分给他们为业；"},
{volumn:        44,chapter:        13,versenumber:        20,verse:"此后给他们设立士师，约有四百五十年，直到先知撒母耳的时候。"},
{volumn:        44,chapter:        13,versenumber:        21,verse:"后来他们求一个王，　神就将便雅悯支派中基士的儿子扫罗，给他们作王四十年。"},
{volumn:        44,chapter:        13,versenumber:        22,verse:"既废了扫罗，就选立大卫作他们的王，又为他作见证说：‘我寻得耶西的儿子大卫，他是合我心意的人，凡事要遵行我的旨意。’"},
{volumn:        44,chapter:        13,versenumber:        23,verse:"从这人的后裔中，　神已经照着所应许的，为以色列人立了一位救主，就是耶稣。"},
{volumn:        44,chapter:        13,versenumber:        24,verse:"在他没有出来以先，约翰向以色列众民宣讲悔改的洗礼。"},
{volumn:        44,chapter:        13,versenumber:        25,verse:"约翰将行尽他的程途说：‘你们以为我是谁？我不是基督；只是有一位在我以后来的，我解他脚上的鞋带也是不配的。’"},
{volumn:        44,chapter:        13,versenumber:        26,verse:"“弟兄们，亚伯拉罕的子孙和你们中间敬畏　神的人哪，这救世的道是传给我们的。"},
{volumn:        44,chapter:        13,versenumber:        27,verse:"耶路撒冷居住的人和他们的官长，因为不认识基督，也不明白每安息日所读众先知的书，就把基督定了死罪，正应了先知的预言；"},
{volumn:        44,chapter:        13,versenumber:        28,verse:"虽然查不出他有当死的罪来，还是求彼拉多杀他；"},
{volumn:        44,chapter:        13,versenumber:        29,verse:"既成就了经上指着他所记的一切话，就把他从木头上取下来，放在坟墓里。"},
{volumn:        44,chapter:        13,versenumber:        30,verse:"神却叫他从死里复活。"},
{volumn:        44,chapter:        13,versenumber:        31,verse:"那从加利利同他上耶路撒冷的人多日看见他，这些人如今在民间是他的见证。"},
{volumn:        44,chapter:        13,versenumber:        32,verse:"我们也报好信息给你们，就是那应许祖宗的话，"},
{volumn:        44,chapter:        13,versenumber:        33,verse:"神已经向我们这作儿女的应验，叫耶稣复活了。正如诗篇第二篇上记着说：‘你是我的儿子，我今日生你。’"},
{volumn:        44,chapter:        13,versenumber:        34,verse:"论到　神叫他从死里复活，不再归于朽坏，就这样说：‘我必将所应许大卫那圣洁、可靠的恩典赐给你们。’"},
{volumn:        44,chapter:        13,versenumber:        35,verse:"“又有一篇上说：‘你必不叫你的圣者见朽坏。’"},
{volumn:        44,chapter:        13,versenumber:        36,verse:"“大卫在世的时候遵行了　神的旨意，就睡了（或译：大卫按　神的旨意服侍了他那一世的人，就睡了），归到他祖宗那里，已见朽坏；"},
{volumn:        44,chapter:        13,versenumber:        37,verse:"惟独　神所复活的，他并未见朽坏。"},
{volumn:        44,chapter:        13,versenumber:        38,verse:"所以，弟兄们，你们当晓得：赦罪的道是由这人传给你们的。"},
{volumn:        44,chapter:        13,versenumber:        39,verse:"你们靠摩西的律法，在一切不得称义的事上信靠这人，就都得称义了。"},
{volumn:        44,chapter:        13,versenumber:        40,verse:"所以，你们务要小心，免得先知书上所说的临到你们。"},
{volumn:        44,chapter:        13,versenumber:        41,verse:"主说：‘你们这轻慢的人要观看，要惊奇，要灭亡；因为在你们的时候，我行一件事，虽有人告诉你们，你们总是不信。’”"},
{volumn:        44,chapter:        13,versenumber:        42,verse:"他们出会堂的时候，众人请他们到下安息日再讲这话给他们听。"},
{volumn:        44,chapter:        13,versenumber:        43,verse:"散会以后，犹太人和敬虔进犹太教的人多有跟从保罗、巴拿巴的。二人对他们讲道，劝他们务要恒久在　神的恩中。"},
{volumn:        44,chapter:        13,versenumber:        44,verse:"到下安息日，合城的人几乎都来聚集，要听　神的道。"},
{volumn:        44,chapter:        13,versenumber:        45,verse:"但犹太人看见人这样多，就满心嫉妒，硬驳保罗所说的话，并且毁谤。"},
{volumn:        44,chapter:        13,versenumber:        46,verse:"保罗和巴拿巴放胆说：“　神的道先讲给你们原是应当的；只因你们弃绝这道，断定自己不配得永生，我们就转向外邦人去。"},
{volumn:        44,chapter:        13,versenumber:        47,verse:"因为主曾这样吩咐我们说：‘我已经立你作外邦人的光，叫你施行救恩，直到地极。’”"},
{volumn:        44,chapter:        13,versenumber:        48,verse:"外邦人听见这话，就欢喜了，赞美　神的道；凡预定得永生的人都信了。"},
{volumn:        44,chapter:        13,versenumber:        49,verse:"于是主的道传遍了那一带地方。"},
{volumn:        44,chapter:        13,versenumber:        50,verse:"但犹太人挑唆虔敬、尊贵的妇女和城内有名望的人，逼迫保罗、巴拿巴，将他们赶出境外。"},
{volumn:        44,chapter:        13,versenumber:        51,verse:"二人对着众人跺下脚上的尘土，就往以哥念去了。"},
{volumn:        44,chapter:        13,versenumber:        52,verse:"门徒满心喜乐，又被圣灵充满。"},
{volumn:        44,chapter:        14,versenumber:         1,verse:"二人在以哥念同进犹太人的会堂，在那里讲的，叫犹太人和希腊人信的很多。"},
{volumn:        44,chapter:        14,versenumber:         2,verse:"但那不顺从的犹太人耸动外邦人，叫他们心里恼恨弟兄。"},
{volumn:        44,chapter:        14,versenumber:         3,verse:"二人在那里住了多日，倚靠主放胆讲道，主藉他们的手施行神迹奇事，证明他的恩道。"},
{volumn:        44,chapter:        14,versenumber:         4,verse:"城里的众人就分了党，有附从犹太人的，有附从使徒的。"},
{volumn:        44,chapter:        14,versenumber:         5,verse:"那时，外邦人和犹太人，并他们的官长，一齐拥上来，要凌辱使徒，用石头打他们。"},
{volumn:        44,chapter:        14,versenumber:         6,verse:"使徒知道了，就逃往吕高尼的路司得、特庇两个城和周围地方去，"},
{volumn:        44,chapter:        14,versenumber:         7,verse:"在那里传福音。"},
{volumn:        44,chapter:        14,versenumber:         8,verse:"路司得城里坐着一个两脚无力的人，生来是瘸腿的，从来没有走过。"},
{volumn:        44,chapter:        14,versenumber:         9,verse:"他听保罗讲道，保罗定睛看他，见他有信心，可得痊愈，"},
{volumn:        44,chapter:        14,versenumber:        10,verse:"就大声说：“你起来，两脚站直！”那人就跳起来，而且行走。"},
{volumn:        44,chapter:        14,versenumber:        11,verse:"众人看见保罗所做的事，就用吕高尼的话大声说：“有　神藉着人形降临在我们中间了。”"},
{volumn:        44,chapter:        14,versenumber:        12,verse:"于是称巴拿巴为宙斯，称保罗为希耳米，因为他说话领首。"},
{volumn:        44,chapter:        14,versenumber:        13,verse:"有城外宙斯庙的祭司牵着牛，拿着花圈，来到门前，要同众人向使徒献祭。"},
{volumn:        44,chapter:        14,versenumber:        14,verse:"巴拿巴、保罗二使徒听见，就撕开衣裳，跳进众人中间，喊着说："},
{volumn:        44,chapter:        14,versenumber:        15,verse:"“诸君，为什么做这事呢？我们也是人，性情和你们一样。我们传福音给你们，是叫你们离弃这些虚妄，归向那创造天、地、海，和其中万物的永生　神。"},
{volumn:        44,chapter:        14,versenumber:        16,verse:"他在从前的世代，任凭万国各行其道；"},
{volumn:        44,chapter:        14,versenumber:        17,verse:"然而为自己未尝不显出证据来，就如常施恩惠，从天降雨，赏赐丰年，叫你们饮食饱足，满心喜乐。”"},
{volumn:        44,chapter:        14,versenumber:        18,verse:"二人说了这些话，仅仅地拦住众人不献祭与他们。"},
{volumn:        44,chapter:        14,versenumber:        19,verse:"但有些犹太人从安提阿和以哥念来，挑唆众人，就用石头打保罗，以为他是死了，便拖到城外。"},
{volumn:        44,chapter:        14,versenumber:        20,verse:"门徒正围着他，他就起来，走进城去。第二天，同巴拿巴往特庇去，"},
{volumn:        44,chapter:        14,versenumber:        21,verse:"对那城里的人传了福音，使好些人作门徒，就回路司得、以哥念、安提阿去，"},
{volumn:        44,chapter:        14,versenumber:        22,verse:"坚固门徒的心，劝他们恒守所信的道；又说：“我们进入　神的国，必须经历许多艰难。”"},
{volumn:        44,chapter:        14,versenumber:        23,verse:"二人在各教会中选立了长老，又禁食祷告，就把他们交托所信的主。"},
{volumn:        44,chapter:        14,versenumber:        24,verse:"二人经过彼西底，来到旁非利亚。"},
{volumn:        44,chapter:        14,versenumber:        25,verse:"在别加讲了道，就下亚大利去，"},
{volumn:        44,chapter:        14,versenumber:        26,verse:"从那里坐船，往安提阿去。当初，他们被众人所托、蒙　神之恩、要办现在所做之工，就是在这地方。"},
{volumn:        44,chapter:        14,versenumber:        27,verse:"到了那里，聚集了会众，就述说神藉他们所行的一切事，并　神怎样为外邦人开了信道的门。"},
{volumn:        44,chapter:        14,versenumber:        28,verse:"二人就在那里同门徒住了多日。"},
{volumn:        44,chapter:        15,versenumber:         1,verse:"有几个人从犹太下来，教训弟兄们说：“你们若不按摩西的规条受割礼，不能得救。”"},
{volumn:        44,chapter:        15,versenumber:         2,verse:"保罗、巴拿巴与他们大大地纷争辩论；众门徒就定规，叫保罗、巴拿巴和本会中几个人，为所辩论的，上耶路撒冷去见使徒和长老。"},
{volumn:        44,chapter:        15,versenumber:         3,verse:"于是教会送他们起行。他们经过腓尼基、撒玛利亚，随处传说外邦人归主的事，叫众弟兄都甚欢喜。"},
{volumn:        44,chapter:        15,versenumber:         4,verse:"到了耶路撒冷，教会和使徒并长老都接待他们，他们就述说　神同他们所行的一切事。"},
{volumn:        44,chapter:        15,versenumber:         5,verse:"惟有几个信徒是法利赛教门的人，起来说：“必须给外邦人行割礼，吩咐他们遵守摩西的律法。”"},
{volumn:        44,chapter:        15,versenumber:         6,verse:"使徒和长老聚会商议这事；"},
{volumn:        44,chapter:        15,versenumber:         7,verse:"辩论已经多了，彼得就起来，说：“诸位弟兄，你们知道　神早已在你们中间拣选了我，叫外邦人从我口中得听福音之道，而且相信。"},
{volumn:        44,chapter:        15,versenumber:         8,verse:"知道人心的　神也为他们作了见证，赐圣灵给他们，正如给我们一样；"},
{volumn:        44,chapter:        15,versenumber:         9,verse:"又藉着信洁净了他们的心，并不分他们我们。"},
{volumn:        44,chapter:        15,versenumber:        10,verse:"现在为什么试探　神，要把我们祖宗和我们所不能负的轭放在门徒的颈项上呢？"},
{volumn:        44,chapter:        15,versenumber:        11,verse:"我们得救乃是因主耶稣的恩，和他们一样，这是我们所信的。”"},
{volumn:        44,chapter:        15,versenumber:        12,verse:"众人都默默无声，听巴拿巴和保罗述说神藉他们在外邦人中所行的神迹奇事。"},
{volumn:        44,chapter:        15,versenumber:        13,verse:"他们住了声，雅各就说：“诸位弟兄，请听我的话。"},
{volumn:        44,chapter:        15,versenumber:        14,verse:"方才西门述说　神当初怎样眷顾外邦人，从他们中间选取百姓归于自己的名下；"},
{volumn:        44,chapter:        15,versenumber:        15,verse:"众先知的话也与这意思相合。"},
{volumn:        44,chapter:        15,versenumber:        16,verse:"正如经上所写的：‘此后，我要回来，重新修造大卫倒塌的帐幕，把那破坏的重新修造建立起来，"},
{volumn:        44,chapter:        15,versenumber:        17,verse:"叫余剩的人，就是凡称为我名下的外邦人，都寻求主。"},
{volumn:        44,chapter:        15,versenumber:        18,verse:"这话是从创世以来显明这事的主说的。’"},
{volumn:        44,chapter:        15,versenumber:        19,verse:"“所以据我的意见，不可难为那归服　神的外邦人；"},
{volumn:        44,chapter:        15,versenumber:        20,verse:"只要写信，吩咐他们禁戒偶像的污秽和奸淫，并勒死的牲畜和血。"},
{volumn:        44,chapter:        15,versenumber:        21,verse:"因为从古以来，摩西的书在各城有人传讲，每逢安息日，在会堂里诵读。”"},
{volumn:        44,chapter:        15,versenumber:        22,verse:"那时，使徒和长老并全教会定意从他们中间拣选人，差他们和保罗、巴拿巴同往安提阿去；所拣选的就是称呼巴撒巴的犹大和西拉。这两个人在弟兄中是作首领的。"},
{volumn:        44,chapter:        15,versenumber:        23,verse:"于是写信交付他们，内中说：“使徒和作长老的弟兄们问安提阿、叙利亚、基利家外邦众弟兄的安。"},
{volumn:        44,chapter:        15,versenumber:        24,verse:"我们听说，有几个人从我们这里出去，用言语搅扰你们，惑乱你们的心。（有古卷加：你们必须受割礼，守摩西的律法。）其实我们并没有吩咐他们。"},
{volumn:        44,chapter:        15,versenumber:        25,verse:"所以，我们同心定意，拣选几个人，差他们同我们所亲爱的巴拿巴和保罗往你们那里去。"},
{volumn:        44,chapter:        15,versenumber:        26,verse:"这二人是为我主耶稣基督的名不顾性命的。"},
{volumn:        44,chapter:        15,versenumber:        27,verse:"我们就差了犹大和西拉，他们也要亲口诉说这些事。"},
{volumn:        44,chapter:        15,versenumber:        28,verse:"因为圣灵和我们定意不将别的重担放在你们身上，惟有几件事是不可少的，"},
{volumn:        44,chapter:        15,versenumber:        29,verse:"就是禁戒祭偶像的物和血，并勒死的牲畜和奸淫。这几件你们若能自己禁戒不犯就好了。愿你们平安！”"},
{volumn:        44,chapter:        15,versenumber:        30,verse:"他们既奉了差遣，就下安提阿去，聚集众人，交付书信。"},
{volumn:        44,chapter:        15,versenumber:        31,verse:"众人念了，因为信上安慰的话就欢喜了。"},
{volumn:        44,chapter:        15,versenumber:        32,verse:"犹大和西拉也是先知，就用许多话劝勉弟兄，坚固他们。"},
{volumn:        44,chapter:        15,versenumber:        33,verse:"住了些日子，弟兄们打发他们平平安安地回到差遣他们的人那里去。（有古卷加：34惟有西拉定意仍住在那里。）"},
{volumn:        44,chapter:        15,versenumber:        34,verse:""},
{volumn:        44,chapter:        15,versenumber:        35,verse:"但保罗和巴拿巴仍住在安提阿，和许多别人一同教训人，传主的道。"},
{volumn:        44,chapter:        15,versenumber:        36,verse:"过了些日子，保罗对巴拿巴说：“我们可以回到从前宣传主道的各城，看望弟兄们景况如何。”"},
{volumn:        44,chapter:        15,versenumber:        37,verse:"巴拿巴有意要带称呼马可的约翰同去；"},
{volumn:        44,chapter:        15,versenumber:        38,verse:"但保罗因为马可从前在旁非利亚离开他们，不和他们同去做工，就以为不可带他去。"},
{volumn:        44,chapter:        15,versenumber:        39,verse:"于是二人起了争论，甚至彼此分开。巴拿巴带着马可，坐船往塞浦路斯去；"},
{volumn:        44,chapter:        15,versenumber:        40,verse:"保罗拣选了西拉，也出去，蒙弟兄们把他交于主的恩中。"},
{volumn:        44,chapter:        15,versenumber:        41,verse:"他就走遍叙利亚、基利家，坚固众教会。"},
{volumn:        44,chapter:        16,versenumber:         1,verse:"保罗来到特庇，又到路司得。在那里有一个门徒，名叫提摩太，是信主之犹太妇人的儿子，他父亲却是希腊人。"},
{volumn:        44,chapter:        16,versenumber:         2,verse:"路司得和以哥念的弟兄都称赞他。"},
{volumn:        44,chapter:        16,versenumber:         3,verse:"保罗要带他同去，只因那些地方的犹太人都知道他父亲是希腊人，就给他行了割礼。"},
{volumn:        44,chapter:        16,versenumber:         4,verse:"他们经过各城，把耶路撒冷使徒和长老所定的条规交给门徒遵守。"},
{volumn:        44,chapter:        16,versenumber:         5,verse:"于是众教会信心越发坚固，人数天天加增。"},
{volumn:        44,chapter:        16,versenumber:         6,verse:"圣灵既然禁止他们在亚细亚讲道，他们就经过弗吕家、加拉太一带地方。"},
{volumn:        44,chapter:        16,versenumber:         7,verse:"到了每西亚的边界，他们想要往庇推尼去，耶稣的灵却不许。"},
{volumn:        44,chapter:        16,versenumber:         8,verse:"他们就越过每西亚，下到特罗亚去。"},
{volumn:        44,chapter:        16,versenumber:         9,verse:"在夜间有异象现与保罗。有一个马其顿人站着求他说：“请你过到马其顿来帮助我们。”"},
{volumn:        44,chapter:        16,versenumber:        10,verse:"保罗既看见这异象，我们随即想要往马其顿去，以为　神召我们传福音给那里的人听。"},
{volumn:        44,chapter:        16,versenumber:        11,verse:"于是从特罗亚开船，一直行到撒摩特喇，第二天到了尼亚坡里。"},
{volumn:        44,chapter:        16,versenumber:        12,verse:"从那里来到腓立比，就是马其顿这一方的头一个城，也是罗马的驻防城。我们在这城里住了几天。"},
{volumn:        44,chapter:        16,versenumber:        13,verse:"当安息日，我们出城门，到了河边，知道那里有一个祷告的地方，我们就坐下对那聚会的妇女讲道。"},
{volumn:        44,chapter:        16,versenumber:        14,verse:"有一个卖紫色布匹的妇人，名叫吕底亚，是推雅推喇城的人，素来敬拜　神。她听见了，主就开导她的心，叫她留心听保罗所讲的话。"},
{volumn:        44,chapter:        16,versenumber:        15,verse:"她和她一家既领了洗，便求我们说：“你们若以为我是真信主的（或译：你们若以为我是忠心事主的），请到我家里来住”；于是强留我们。"},
{volumn:        44,chapter:        16,versenumber:        16,verse:"后来，我们往那祷告的地方去。有一个使女迎着面来，她被巫鬼所附，用法术，叫她主人们大得财利。"},
{volumn:        44,chapter:        16,versenumber:        17,verse:"她跟随保罗和我们，喊着说：“这些人是至高　神的仆人，对你们传说救人的道。”"},
{volumn:        44,chapter:        16,versenumber:        18,verse:"她一连多日这样喊叫，保罗就心中厌烦，转身对那鬼说：“我奉耶稣基督的名，吩咐你从她身上出来！”那鬼当时就出来了。"},
{volumn:        44,chapter:        16,versenumber:        19,verse:"使女的主人们见得利的指望没有了，便揪住保罗和西拉，拉他们到市上去见首领；"},
{volumn:        44,chapter:        16,versenumber:        20,verse:"又带到官长面前说：“这些人原是犹太人，竟骚扰我们的城，"},
{volumn:        44,chapter:        16,versenumber:        21,verse:"传我们罗马人所不可受不可行的规矩。”"},
{volumn:        44,chapter:        16,versenumber:        22,verse:"众人就一同起来攻击他们。官长吩咐剥了他们的衣裳，用棍打；"},
{volumn:        44,chapter:        16,versenumber:        23,verse:"打了许多棍，便将他们下在监里，嘱咐禁卒严紧看守。"},
{volumn:        44,chapter:        16,versenumber:        24,verse:"禁卒领了这样的命，就把他们下在内监里，两脚上了木狗。"},
{volumn:        44,chapter:        16,versenumber:        25,verse:"约在半夜，保罗和西拉祷告，唱诗赞美　神，众囚犯也侧耳而听。"},
{volumn:        44,chapter:        16,versenumber:        26,verse:"忽然，地大震动，甚至监牢的地基都摇动了，监门立刻全开，众囚犯的锁链也都松开了。"},
{volumn:        44,chapter:        16,versenumber:        27,verse:"禁卒一醒，看见监门全开，以为囚犯已经逃走，就拔刀要自杀。"},
{volumn:        44,chapter:        16,versenumber:        28,verse:"保罗大声呼叫说：“不要伤害自己！我们都在这里。”"},
{volumn:        44,chapter:        16,versenumber:        29,verse:"禁卒叫人拿灯来，就跳进去，战战兢兢地俯伏在保罗、西拉面前；"},
{volumn:        44,chapter:        16,versenumber:        30,verse:"又领他们出来，说：“二位先生，我当怎样行才可以得救？”"},
{volumn:        44,chapter:        16,versenumber:        31,verse:"他们说：“当信主耶稣，你和你一家都必得救。”"},
{volumn:        44,chapter:        16,versenumber:        32,verse:"他们就把主的道讲给他和他全家的人听。"},
{volumn:        44,chapter:        16,versenumber:        33,verse:"当夜，就在那时候，禁卒把他们带去，洗他们的伤；他和属乎他的人立时都受了洗。"},
{volumn:        44,chapter:        16,versenumber:        34,verse:"于是禁卒领他们上自己家里去，给他们摆上饭。他和全家，因为信了　神，都很喜乐。"},
{volumn:        44,chapter:        16,versenumber:        35,verse:"到了天亮，官长打发差役来，说：“释放那两个人吧。”"},
{volumn:        44,chapter:        16,versenumber:        36,verse:"禁卒就把这话告诉保罗说：“官长打发人来叫释放你们，如今可以出监，平平安安地去吧。”"},
{volumn:        44,chapter:        16,versenumber:        37,verse:"保罗却说：“我们是罗马人，并没有定罪，他们就在众人面前打了我们，又把我们下在监里，现在要私下撵我们出去吗？这是不行的。叫他们自己来领我们出去吧！”"},
{volumn:        44,chapter:        16,versenumber:        38,verse:"差役把这话回禀官长。官长听见他们是罗马人，就害怕了，"},
{volumn:        44,chapter:        16,versenumber:        39,verse:"于是来劝他们，领他们出来，请他们离开那城。"},
{volumn:        44,chapter:        16,versenumber:        40,verse:"二人出了监，往吕底亚家里去，见了弟兄们，劝慰他们一番，就走了。"},
{volumn:        44,chapter:        17,versenumber:         1,verse:"保罗和西拉经过暗妃波里、亚波罗尼亚，来到帖撒罗尼迦，在那里有犹太人的会堂。"},
{volumn:        44,chapter:        17,versenumber:         2,verse:"保罗照他素常的规矩进去，一连三个安息日，本着圣经与他们辩论，"},
{volumn:        44,chapter:        17,versenumber:         3,verse:"讲解陈明基督必须受害，从死里复活；又说：“我所传与你们的这位耶稣就是基督。”"},
{volumn:        44,chapter:        17,versenumber:         4,verse:"他们中间有些人听了劝，就附从保罗和西拉，并有许多虔敬的希腊人，尊贵的妇女也不少。"},
{volumn:        44,chapter:        17,versenumber:         5,verse:"但那不信的犹太人心里嫉妒，招聚了些市井匪类，搭伙成群，耸动合城的人闯进耶孙的家，要将保罗、西拉带到百姓那里。"},
{volumn:        44,chapter:        17,versenumber:         6,verse:"找不着他们，就把耶孙和几个弟兄拉到地方官那里，喊叫说：“那搅乱天下的也到这里来了，"},
{volumn:        44,chapter:        17,versenumber:         7,verse:"耶孙收留他们。这些人都违背凯撒的命令，说另有一个王耶稣。”"},
{volumn:        44,chapter:        17,versenumber:         8,verse:"众人和地方官听见这话，就惊慌了；"},
{volumn:        44,chapter:        17,versenumber:         9,verse:"于是取了耶孙和其余之人的保状，就释放了他们。"},
{volumn:        44,chapter:        17,versenumber:        10,verse:"弟兄们随即在夜间打发保罗和西拉往庇哩亚去。二人到了，就进入犹太人的会堂。"},
{volumn:        44,chapter:        17,versenumber:        11,verse:"这地方的人贤于帖撒罗尼迦的人，甘心领受这道，天天考查圣经，要晓得这道是与不是。"},
{volumn:        44,chapter:        17,versenumber:        12,verse:"所以他们中间多有相信的，又有希腊尊贵的妇女，男子也不少。"},
{volumn:        44,chapter:        17,versenumber:        13,verse:"但帖撒罗尼迦的犹太人知道保罗又在庇哩亚传　神的道，也就往那里去，耸动搅扰众人。"},
{volumn:        44,chapter:        17,versenumber:        14,verse:"当时弟兄们便打发保罗往海边去，西拉和提摩太仍住在庇哩亚。"},
{volumn:        44,chapter:        17,versenumber:        15,verse:"送保罗的人带他到了雅典，既领了保罗的命，叫西拉和提摩太速速到他这里来，就回去了。"},
{volumn:        44,chapter:        17,versenumber:        16,verse:"保罗在雅典等候他们的时候，看见满城都是偶像，就心里着急；"},
{volumn:        44,chapter:        17,versenumber:        17,verse:"于是在会堂里与犹太人和虔敬的人，并每日在市上所遇见的人，辩论。"},
{volumn:        44,chapter:        17,versenumber:        18,verse:"还有以彼古罗和斯多亚两门的学士，与他争论。有的说：“这胡言乱语的要说什么？”有的说：“他似乎是传说外邦鬼神的。”这话是因保罗传讲耶稣与复活的道。"},
{volumn:        44,chapter:        17,versenumber:        19,verse:"他们就把他带到亚略·巴古，说：“你所讲的这新道，我们也可以知道吗？"},
{volumn:        44,chapter:        17,versenumber:        20,verse:"因为你有些奇怪的事传到我们耳中，我们愿意知道这些事是什么意思。”（"},
{volumn:        44,chapter:        17,versenumber:        21,verse:"雅典人和住在那里的客人都不顾别的事，只将新闻说说听听。）"},
{volumn:        44,chapter:        17,versenumber:        22,verse:"保罗站在亚略·巴古当中，说：“众位雅典人哪，我看你们凡事很敬畏鬼神。"},
{volumn:        44,chapter:        17,versenumber:        23,verse:"我游行的时候，观看你们所敬拜的，遇见一座坛，上面写着‘未识之神’。你们所不认识而敬拜的，我现在告诉你们。"},
{volumn:        44,chapter:        17,versenumber:        24,verse:"创造宇宙和其中万物的　神，既是天地的主，就不住人手所造的殿，"},
{volumn:        44,chapter:        17,versenumber:        25,verse:"也不用人手服侍，好像缺少什么；自己倒将生命、气息、万物，赐给万人。"},
{volumn:        44,chapter:        17,versenumber:        26,verse:"他从一本（本：有古卷是血脉）造出万族的人，住在全地上，并且预先定准他们的年限和所住的疆界，"},
{volumn:        44,chapter:        17,versenumber:        27,verse:"要叫他们寻求　神，或者可以揣摩而得，其实他离我们各人不远；"},
{volumn:        44,chapter:        17,versenumber:        28,verse:"我们生活、动作、存留，都在乎他。就如你们作诗的，有人说：‘我们也是他所生的。’"},
{volumn:        44,chapter:        17,versenumber:        29,verse:"我们既是　神所生的，就不当以为　神的神性像人用手艺、心思所雕刻的金、银、石。"},
{volumn:        44,chapter:        17,versenumber:        30,verse:"世人蒙昧无知的时候，　神并不监察，如今却吩咐各处的人都要悔改。"},
{volumn:        44,chapter:        17,versenumber:        31,verse:"因为他已经定了日子，要藉着他所设立的人按公义审判天下，并且叫他从死里复活，给万人作可信的凭据。”"},
{volumn:        44,chapter:        17,versenumber:        32,verse:"众人听见从死里复活的话，就有讥诮他的；又有人说：“我们再听你讲这个吧！”"},
{volumn:        44,chapter:        17,versenumber:        33,verse:"于是保罗从他们当中出去了。"},
{volumn:        44,chapter:        17,versenumber:        34,verse:"但有几个人贴近他，信了主，其中有亚略·巴古的官丢尼修，并一个妇人，名叫大马哩，还有别人一同信从。"},
{volumn:        44,chapter:        18,versenumber:         1,verse:"这事以后，保罗离了雅典，来到哥林多。"},
{volumn:        44,chapter:        18,versenumber:         2,verse:"遇见一个犹太人，名叫亚居拉，他生在本都；因为革老丢命犹太人都离开罗马，新近带着妻百基拉，从意大利来。保罗就投奔了他们。"},
{volumn:        44,chapter:        18,versenumber:         3,verse:"他们本是制造帐棚为业。保罗因与他们同业，就和他们同住做工。"},
{volumn:        44,chapter:        18,versenumber:         4,verse:"每逢安息日，保罗在会堂里辩论，劝化犹太人和希腊人。"},
{volumn:        44,chapter:        18,versenumber:         5,verse:"西拉和提摩太从马其顿来的时候，保罗为道迫切，向犹太人证明耶稣是基督。"},
{volumn:        44,chapter:        18,versenumber:         6,verse:"他们既抗拒、毁谤，保罗就抖着衣裳，说：“你们的罪（原文是血）归到你们自己头上，与我无干（原文是我却干净）。从今以后，我要往外邦人那里去。”"},
{volumn:        44,chapter:        18,versenumber:         7,verse:"于是离开那里，到了一个人的家中；这人名叫提多·犹士都，是敬拜　神的，他的家靠近会堂。"},
{volumn:        44,chapter:        18,versenumber:         8,verse:"管会堂的基利司布和全家都信了主，还有许多哥林多人听了，就相信受洗。"},
{volumn:        44,chapter:        18,versenumber:         9,verse:"夜间，主在异象中对保罗说：“不要怕，只管讲，不要闭口，"},
{volumn:        44,chapter:        18,versenumber:        10,verse:"有我与你同在，必没有人下手害你，因为在这城里我有许多的百姓。”"},
{volumn:        44,chapter:        18,versenumber:        11,verse:"保罗在那里住了一年零六个月，将　神的道教训他们。"},
{volumn:        44,chapter:        18,versenumber:        12,verse:"到迦流作亚该亚方伯的时候，犹太人同心起来攻击保罗，拉他到公堂，"},
{volumn:        44,chapter:        18,versenumber:        13,verse:"说：“这个人劝人不按着律法敬拜　神。”"},
{volumn:        44,chapter:        18,versenumber:        14,verse:"保罗刚要开口，迦流就对犹太人说：“你们这些犹太人！如果是为冤枉或奸恶的事，我理当耐性听你们。"},
{volumn:        44,chapter:        18,versenumber:        15,verse:"但所争论的，若是关乎言语、名目，和你们的律法，你们自己去办吧！这样的事我不愿意审问”；"},
{volumn:        44,chapter:        18,versenumber:        16,verse:"就把他们撵出公堂。"},
{volumn:        44,chapter:        18,versenumber:        17,verse:"众人便揪住管会堂的所提尼，在堂前打他。这些事迦流都不管。"},
{volumn:        44,chapter:        18,versenumber:        18,verse:"保罗又住了多日，就辞别了弟兄，坐船往叙利亚去；百基拉、亚居拉和他同去。他因为许过愿，就在坚革哩剪了头发。"},
{volumn:        44,chapter:        18,versenumber:        19,verse:"到了以弗所，保罗就把他们留在那里，自己进了会堂，和犹太人辩论。"},
{volumn:        44,chapter:        18,versenumber:        20,verse:"众人请他多住些日子，他却不允，"},
{volumn:        44,chapter:        18,versenumber:        21,verse:"就辞别他们，说：“　神若许我，我还要回到你们这里”；于是开船离了以弗所。"},
{volumn:        44,chapter:        18,versenumber:        22,verse:"在凯撒利亚下了船，就上耶路撒冷去问教会安，随后下安提阿去。"},
{volumn:        44,chapter:        18,versenumber:        23,verse:"住了些日子，又离开那里，挨次经过加拉太和弗吕家地方，坚固众门徒。"},
{volumn:        44,chapter:        18,versenumber:        24,verse:"有一个犹太人，名叫亚波罗，来到以弗所。他生在亚历山大，是有学问（或译：口才）的，最能讲解圣经。"},
{volumn:        44,chapter:        18,versenumber:        25,verse:"这人已经在主的道上受了教训，心里火热，将耶稣的事详细讲论教训人；只是他单晓得约翰的洗礼。"},
{volumn:        44,chapter:        18,versenumber:        26,verse:"他在会堂里放胆讲道；百基拉、亚居拉听见，就接他来，将　神的道给他讲解更加详细。"},
{volumn:        44,chapter:        18,versenumber:        27,verse:"他想要往亚该亚去，弟兄们就勉励他，并写信请门徒接待他（或译：弟兄们就写信劝门徒接待他）。他到了那里，多帮助那蒙恩信主的人，"},
{volumn:        44,chapter:        18,versenumber:        28,verse:"在众人面前极有能力驳倒犹太人，引圣经证明耶稣是基督。"},
{volumn:        44,chapter:        19,versenumber:         1,verse:"亚波罗在哥林多的时候，保罗经过了上边一带地方，就来到以弗所；在那里遇见几个门徒，"},
{volumn:        44,chapter:        19,versenumber:         2,verse:"问他们说：“你们信的时候受了圣灵没有？”他们回答说：“没有，也未曾听见有圣灵赐下来。”"},
{volumn:        44,chapter:        19,versenumber:         3,verse:"保罗说：“这样，你们受的是什么洗呢？”他们说：“是约翰的洗。”"},
{volumn:        44,chapter:        19,versenumber:         4,verse:"保罗说：“约翰所行的是悔改的洗，告诉百姓当信那在他以后要来的，就是耶稣。”"},
{volumn:        44,chapter:        19,versenumber:         5,verse:"他们听见这话，就奉主耶稣的名受洗。"},
{volumn:        44,chapter:        19,versenumber:         6,verse:"保罗按手在他们头上，圣灵便降在他们身上，他们就说方言，又说预言（或译：又讲道）。"},
{volumn:        44,chapter:        19,versenumber:         7,verse:"一共约有十二个人。"},
{volumn:        44,chapter:        19,versenumber:         8,verse:"保罗进会堂，放胆讲道，一连三个月，辩论　神国的事，劝化众人。"},
{volumn:        44,chapter:        19,versenumber:         9,verse:"后来，有些人心里刚硬不信，在众人面前毁谤这道，保罗就离开他们，也叫门徒与他们分离，便在推喇奴的学房天天辩论。"},
{volumn:        44,chapter:        19,versenumber:        10,verse:"这样有两年之久，叫一切住在亚细亚的，无论是犹太人，是希腊人，都听见主的道。"},
{volumn:        44,chapter:        19,versenumber:        11,verse:"神藉保罗的手行了些非常的奇事；"},
{volumn:        44,chapter:        19,versenumber:        12,verse:"甚至有人从保罗身上拿手巾或围裙放在病人身上，病就退了，恶鬼也出去了。"},
{volumn:        44,chapter:        19,versenumber:        13,verse:"那时，有几个游行各处、念咒赶鬼的犹太人，向那被恶鬼附的人擅自称主耶稣的名，说：“我奉保罗所传的耶稣敕令你们出来！”"},
{volumn:        44,chapter:        19,versenumber:        14,verse:"做这事的，有犹太祭司长士基瓦的七个儿子。"},
{volumn:        44,chapter:        19,versenumber:        15,verse:"恶鬼回答他们说：“耶稣我认识，保罗我也知道。你们却是谁呢？”"},
{volumn:        44,chapter:        19,versenumber:        16,verse:"恶鬼所附的人就跳在他们身上，胜了其中二人，制伏他们，叫他们赤着身子受了伤，从那房子里逃出去了。"},
{volumn:        44,chapter:        19,versenumber:        17,verse:"凡住在以弗所的，无论是犹太人，是希腊人，都知道这事，也都惧怕；主耶稣的名从此就尊大了。"},
{volumn:        44,chapter:        19,versenumber:        18,verse:"那已经信的，多有人来承认诉说自己所行的事。"},
{volumn:        44,chapter:        19,versenumber:        19,verse:"平素行邪术的，也有许多人把书拿来，堆积在众人面前焚烧。他们算计书价，便知道共合五万块钱。"},
{volumn:        44,chapter:        19,versenumber:        20,verse:"主的道大大兴旺，而且得胜，就是这样。"},
{volumn:        44,chapter:        19,versenumber:        21,verse:"这些事完了，保罗心里定意经过了马其顿、亚该亚，就往耶路撒冷去；又说：“我到了那里以后，也必须往罗马去看看。”"},
{volumn:        44,chapter:        19,versenumber:        22,verse:"于是从帮助他的人中打发提摩太、以拉都二人往马其顿去，自己暂时等在亚细亚。"},
{volumn:        44,chapter:        19,versenumber:        23,verse:"那时，因为这道起的扰乱不小。"},
{volumn:        44,chapter:        19,versenumber:        24,verse:"有一个银匠，名叫底米丢，是制造亚底米神银龛的，他使这样手艺人生意发达。"},
{volumn:        44,chapter:        19,versenumber:        25,verse:"他聚集他们和同行的工人，说：“众位，你们知道我们是倚靠这生意发财。"},
{volumn:        44,chapter:        19,versenumber:        26,verse:"这保罗不但在以弗所，也几乎在亚细亚全地，引诱迷惑许多人，说：‘人手所做的，不是神。’这是你们所看见所听见的。"},
{volumn:        44,chapter:        19,versenumber:        27,verse:"这样，不独我们这事业被人藐视，就是大女神亚底米的庙也要被人轻忽，连亚细亚全地和普天下所敬拜的大女神之威荣也要消灭了。”"},
{volumn:        44,chapter:        19,versenumber:        28,verse:"众人听见，就怒气填胸，喊着说：“大哉，以弗所人的亚底米啊！”"},
{volumn:        44,chapter:        19,versenumber:        29,verse:"满城都轰动起来。众人拿住与保罗同行的马其顿人该犹和亚里达古，齐心拥进戏园里去。"},
{volumn:        44,chapter:        19,versenumber:        30,verse:"保罗想要进去，到百姓那里，门徒却不许他去。"},
{volumn:        44,chapter:        19,versenumber:        31,verse:"还有亚细亚几位首领，是保罗的朋友，打发人来劝他，不要冒险到戏园里去。"},
{volumn:        44,chapter:        19,versenumber:        32,verse:"聚集的人纷纷乱乱，有喊叫这个的，有喊叫那个的；大半不知道是为什么聚集。"},
{volumn:        44,chapter:        19,versenumber:        33,verse:"有人把亚历山大从众人中带出来，犹太人推他往前，亚历山大就摆手，要向百姓分诉；"},
{volumn:        44,chapter:        19,versenumber:        34,verse:"只因他们认出他是犹太人，就大家同声喊着说：“大哉！以弗所人的亚底米啊。”如此约有两小时。"},
{volumn:        44,chapter:        19,versenumber:        35,verse:"那城里的书记安抚了众人，就说：“以弗所人哪，谁不知道以弗所人的城是看守大亚底米的庙和从宙斯那里落下来的像呢？"},
{volumn:        44,chapter:        19,versenumber:        36,verse:"这事既是驳不倒的，你们就当安静，不可造次。"},
{volumn:        44,chapter:        19,versenumber:        37,verse:"你们把这些人带来，他们并没有偷窃庙中之物，也没有谤讟我们的女神。"},
{volumn:        44,chapter:        19,versenumber:        38,verse:"若是底米丢和他同行的人有控告人的事，自有放告的日子（或译：自有公堂），也有方伯可以彼此对告。"},
{volumn:        44,chapter:        19,versenumber:        39,verse:"你们若问别的事，就可以照常例聚集断定。"},
{volumn:        44,chapter:        19,versenumber:        40,verse:"今日的扰乱本是无缘无故，我们难免被查问。论到这样聚众，我们也说不出所以然来。”"},
{volumn:        44,chapter:        19,versenumber:        41,verse:"说了这话，便叫众人散去。"},
{volumn:        44,chapter:        20,versenumber:         1,verse:"乱定之后，保罗请门徒来，劝勉他们，就辞别起行，往马其顿去。"},
{volumn:        44,chapter:        20,versenumber:         2,verse:"走遍了那一带地方，用许多话劝勉门徒（或译：众人），然后来到希腊。"},
{volumn:        44,chapter:        20,versenumber:         3,verse:"在那里住了三个月，将要坐船往叙利亚去，犹太人设计要害他，他就定意从马其顿回去。"},
{volumn:        44,chapter:        20,versenumber:         4,verse:"同他到亚细亚去的，有庇哩亚人毕罗斯的儿子所巴特，帖撒罗尼迦人亚里达古和西公都，还有特庇人该犹，并提摩太，又有亚细亚人推基古和特罗非摩。"},
{volumn:        44,chapter:        20,versenumber:         5,verse:"这些人先走，在特罗亚等候我们。"},
{volumn:        44,chapter:        20,versenumber:         6,verse:"过了除酵的日子，我们从腓立比开船，五天到了特罗亚，和他们相会，在那里住了七天。"},
{volumn:        44,chapter:        20,versenumber:         7,verse:"七日的第一日，我们聚会擘饼的时候，保罗因为要次日起行，就与他们讲论，直讲到半夜。"},
{volumn:        44,chapter:        20,versenumber:         8,verse:"我们聚会的那座楼上，有好些灯烛。"},
{volumn:        44,chapter:        20,versenumber:         9,verse:"有一个少年人，名叫犹推古，坐在窗台上，困倦沉睡。保罗讲了多时，少年人睡熟了，就从三层楼上掉下去；扶起他来，已经死了。"},
{volumn:        44,chapter:        20,versenumber:        10,verse:"保罗下去，伏在他身上，抱着他，说：“你们不要发慌，他的灵魂还在身上。”"},
{volumn:        44,chapter:        20,versenumber:        11,verse:"保罗又上去，擘饼，吃了，谈论许久，直到天亮，这才走了。"},
{volumn:        44,chapter:        20,versenumber:        12,verse:"有人把那童子活活地领来，得的安慰不小。"},
{volumn:        44,chapter:        20,versenumber:        13,verse:"我们先上船，开往亚朔去，意思要在那里接保罗；因为他是这样安排的，他自己打算要步行。"},
{volumn:        44,chapter:        20,versenumber:        14,verse:"他既在亚朔与我们相会，我们就接他上船，来到米推利尼。"},
{volumn:        44,chapter:        20,versenumber:        15,verse:"从那里开船，次日到了基阿的对面；又次日，在撒摩靠岸；又次日，来到米利都。"},
{volumn:        44,chapter:        20,versenumber:        16,verse:"乃因保罗早已定意越过以弗所，免得在亚细亚耽延，他急忙前走，巴不得赶五旬节能到耶路撒冷。"},
{volumn:        44,chapter:        20,versenumber:        17,verse:"保罗从米利都打发人往以弗所去，请教会的长老来。"},
{volumn:        44,chapter:        20,versenumber:        18,verse:"他们来了，保罗就说：“你们知道，自从我到亚细亚的日子以来，在你们中间始终为人如何，"},
{volumn:        44,chapter:        20,versenumber:        19,verse:"服侍主，凡事谦卑，眼中流泪，又因犹太人的谋害，经历试炼。"},
{volumn:        44,chapter:        20,versenumber:        20,verse:"你们也知道，凡与你们有益的，我没有一样避讳不说的，或在众人面前，或在各人家里，我都教导你们；"},
{volumn:        44,chapter:        20,versenumber:        21,verse:"又对犹太人和希腊人证明当向　神悔改，信靠我主耶稣基督。"},
{volumn:        44,chapter:        20,versenumber:        22,verse:"现在我往耶路撒冷去，心甚迫切（原文是心被捆绑），不知道在那里要遇见什么事；"},
{volumn:        44,chapter:        20,versenumber:        23,verse:"但知道圣灵在各城里向我指证，说有捆锁与患难等待我。"},
{volumn:        44,chapter:        20,versenumber:        24,verse:"我却不以性命为念，也不看为宝贵，只要行完我的路程，成就我从主耶稣所领受的职事，证明　神恩惠的福音。"},
{volumn:        44,chapter:        20,versenumber:        25,verse:"“我素常在你们中间来往，传讲　神国的道；如今我晓得，你们以后都不得再见我的面了。"},
{volumn:        44,chapter:        20,versenumber:        26,verse:"所以我今日向你们证明，你们中间无论何人死亡，罪不在我身上（原文是我于众人的血是洁净的）。"},
{volumn:        44,chapter:        20,versenumber:        27,verse:"因为　神的旨意，我并没有一样避讳不传给你们的。"},
{volumn:        44,chapter:        20,versenumber:        28,verse:"圣灵立你们作全群的监督，你们就当为自己谨慎，也为全群谨慎，牧养　神的教会，就是他用自己血所买来的（或译：救赎的）。"},
{volumn:        44,chapter:        20,versenumber:        29,verse:"我知道，我去之后必有凶暴的豺狼进入你们中间，不爱惜羊群。"},
{volumn:        44,chapter:        20,versenumber:        30,verse:"就是你们中间，也必有人起来说悖谬的话，要引诱门徒跟从他们。"},
{volumn:        44,chapter:        20,versenumber:        31,verse:"所以你们应当警醒，记念我三年之久昼夜不住地流泪、劝戒你们各人。"},
{volumn:        44,chapter:        20,versenumber:        32,verse:"如今我把你们交托神和他恩惠的道；这道能建立你们，叫你们和一切成圣的人同得基业。"},
{volumn:        44,chapter:        20,versenumber:        33,verse:"我未曾贪图一个人的金、银、衣服。"},
{volumn:        44,chapter:        20,versenumber:        34,verse:"我这两只手常供给我和同人的需用，这是你们自己知道的。"},
{volumn:        44,chapter:        20,versenumber:        35,verse:"我凡事给你们作榜样，叫你们知道应当这样劳苦，扶助软弱的人，又当记念主耶稣的话，说：‘施比受更为有福。’”"},
{volumn:        44,chapter:        20,versenumber:        36,verse:"保罗说完了这话，就跪下同众人祷告。"},
{volumn:        44,chapter:        20,versenumber:        37,verse:"众人痛哭，抱着保罗的颈项，和他亲嘴。"},
{volumn:        44,chapter:        20,versenumber:        38,verse:"叫他们最伤心的，就是他说“以后不能再见我的面”那句话，于是送他上船去了。"},
{volumn:        44,chapter:        21,versenumber:         1,verse:"我们离别了众人，就开船一直行到哥士。第二天到了罗底，从那里到帕大喇，"},
{volumn:        44,chapter:        21,versenumber:         2,verse:"遇见一只船要往腓尼基去，就上船起行。"},
{volumn:        44,chapter:        21,versenumber:         3,verse:"望见塞浦路斯，就从南边行过，往叙利亚去，我们就在推罗上岸，因为船要在那里卸货。"},
{volumn:        44,chapter:        21,versenumber:         4,verse:"找着了门徒，就在那里住了七天。他们被圣灵感动，对保罗说：“不要上耶路撒冷去。”"},
{volumn:        44,chapter:        21,versenumber:         5,verse:"过了这几天，我们就起身前行。他们众人同妻子儿女，送我们到城外，我们都跪在岸上祷告，彼此辞别。"},
{volumn:        44,chapter:        21,versenumber:         6,verse:"我们上了船，他们就回家去了。"},
{volumn:        44,chapter:        21,versenumber:         7,verse:"我们从推罗行尽了水路，来到多利买，就问那里的弟兄安，和他们同住了一天。"},
{volumn:        44,chapter:        21,versenumber:         8,verse:"第二天，我们离开那里，来到凯撒利亚，就进了传福音的腓利家里，和他同住。他是那七个执事里的一个。"},
{volumn:        44,chapter:        21,versenumber:         9,verse:"他有四个女儿，都是处女，是说预言的。"},
{volumn:        44,chapter:        21,versenumber:        10,verse:"我们在那里多住了几天，有一个先知，名叫亚迦布，从犹太下来，"},
{volumn:        44,chapter:        21,versenumber:        11,verse:"到了我们这里，就拿保罗的腰带捆上自己的手脚，说：“圣灵说：犹太人在耶路撒冷，要如此捆绑这腰带的主人，把他交在外邦人手里。”"},
{volumn:        44,chapter:        21,versenumber:        12,verse:"我们和那本地的人听见这话，都苦劝保罗不要上耶路撒冷去。"},
{volumn:        44,chapter:        21,versenumber:        13,verse:"保罗说：“你们为什么这样痛哭，使我心碎呢？我为主耶稣的名，不但被人捆绑，就是死在耶路撒冷也是愿意的。”"},
{volumn:        44,chapter:        21,versenumber:        14,verse:"保罗既不听劝，我们便住了口，只说：“愿主的旨意成就”，便了。"},
{volumn:        44,chapter:        21,versenumber:        15,verse:"过了几日，我们收拾行李上耶路撒冷去。"},
{volumn:        44,chapter:        21,versenumber:        16,verse:"有凯撒利亚的几个门徒和我们同去，带我们到一个久为（久为：或译老）门徒的家里，叫我们与他同住；他名叫拿孙，是塞浦路斯人。"},
{volumn:        44,chapter:        21,versenumber:        17,verse:"到了耶路撒冷，弟兄们欢欢喜喜地接待我们。"},
{volumn:        44,chapter:        21,versenumber:        18,verse:"第二天，保罗同我们去见雅各；长老们也都在那里。"},
{volumn:        44,chapter:        21,versenumber:        19,verse:"保罗问了他们安，便将　神用他传教，在外邦人中间所行之事，一一地述说了。"},
{volumn:        44,chapter:        21,versenumber:        20,verse:"他们听见，就归荣耀与　神，对保罗说：“兄台，你看犹太人中信主的有多少万，并且都为律法热心。"},
{volumn:        44,chapter:        21,versenumber:        21,verse:"他们听见人说，你教训一切在外邦的犹太人离弃摩西，对他们说，不要给孩子行割礼，也不要遵行条规。"},
{volumn:        44,chapter:        21,versenumber:        22,verse:"众人必听见你来了，这可怎么办呢？"},
{volumn:        44,chapter:        21,versenumber:        23,verse:"你就照着我们的话行吧！我们这里有四个人，都有愿在身。"},
{volumn:        44,chapter:        21,versenumber:        24,verse:"你带他们去，与他们一同行洁净的礼，替他们拿出规费，叫他们得以剃头。这样，众人就可知道，先前所听见你的事都是虚的；并可知道，你自己为人，循规蹈矩，遵行律法。"},
{volumn:        44,chapter:        21,versenumber:        25,verse:"至于信主的外邦人，我们已经写信拟定，叫他们谨忌那祭偶像之物，和血，并勒死的牲畜，与奸淫。”"},
{volumn:        44,chapter:        21,versenumber:        26,verse:"于是保罗带着那四个人，第二天与他们一同行了洁净的礼，进了殿，报明洁净的日期满足，只等祭司为他们各人献祭。"},
{volumn:        44,chapter:        21,versenumber:        27,verse:"那七日将完，从亚细亚来的犹太人看见保罗在殿里，就耸动了众人，下手拿他，"},
{volumn:        44,chapter:        21,versenumber:        28,verse:"喊叫说：“以色列人来帮助，这就是在各处教训众人糟践我们百姓和律法，并这地方的。他又带着希腊人进殿，污秽了这圣地。”（"},
{volumn:        44,chapter:        21,versenumber:        29,verse:"这话是因他们曾看见以弗所人特罗非摩同保罗在城里，以为保罗带他进了殿。）"},
{volumn:        44,chapter:        21,versenumber:        30,verse:"合城都震动，百姓一齐跑来，拿住保罗，拉他出殿，殿门立刻都关了。"},
{volumn:        44,chapter:        21,versenumber:        31,verse:"他们正想要杀他，有人报信给营里的千夫长说：“耶路撒冷合城都乱了。”"},
{volumn:        44,chapter:        21,versenumber:        32,verse:"千夫长立时带着兵丁和几个百夫长，跑下去到他们那里。他们见了千夫长和兵丁，就止住不打保罗。"},
{volumn:        44,chapter:        21,versenumber:        33,verse:"于是千夫长上前拿住他，吩咐用两条铁链捆锁；又问他是什么人，做的是什么事。"},
{volumn:        44,chapter:        21,versenumber:        34,verse:"众人有喊叫这个的，有喊叫那个的；千夫长因为这样乱嚷，得不着实情，就吩咐人将保罗带进营楼去。"},
{volumn:        44,chapter:        21,versenumber:        35,verse:"到了台阶上，众人挤得凶猛，兵丁只得将保罗抬起来。"},
{volumn:        44,chapter:        21,versenumber:        36,verse:"众人跟在后面，喊着说：“除掉他！”"},
{volumn:        44,chapter:        21,versenumber:        37,verse:"将要带他进营楼，保罗对千夫长说：“我对你说句话可以不可以？”他说：“你懂得希腊话吗？"},
{volumn:        44,chapter:        21,versenumber:        38,verse:"你莫非是从前作乱、带领四千凶徒往旷野去的那埃及人吗？”"},
{volumn:        44,chapter:        21,versenumber:        39,verse:"保罗说：“我本是犹太人，生在基利家的大数，并不是无名小城的人。求你准我对百姓说话。”"},
{volumn:        44,chapter:        21,versenumber:        40,verse:"千夫长准了。保罗就站在台阶上，向百姓摆手，他们都静默无声，保罗便用希伯来话对他们说："},
{volumn:        44,chapter:        22,versenumber:         1,verse:"“诸位父兄请听，我现在对你们分诉。”"},
{volumn:        44,chapter:        22,versenumber:         2,verse:"众人听他说的是希伯来话，就更加安静了。"},
{volumn:        44,chapter:        22,versenumber:         3,verse:"保罗说：“我原是犹太人，生在基利家的大数，长在这城里，在迦玛列门下，按着我们祖宗严紧的律法受教，热心侍奉　神，像你们众人今日一样。"},
{volumn:        44,chapter:        22,versenumber:         4,verse:"我也曾逼迫奉这道的人，直到死地，无论男女都锁拿下监。"},
{volumn:        44,chapter:        22,versenumber:         5,verse:"这是大祭司和众长老都可以给我作见证的。我又领了他们达与弟兄的书信，往大马士革去，要把在那里奉这道的人锁拿，带到耶路撒冷受刑。”"},
{volumn:        44,chapter:        22,versenumber:         6,verse:"“我将到大马士革，正走的时候，约在晌午，忽然从天上发大光，四面照着我。"},
{volumn:        44,chapter:        22,versenumber:         7,verse:"我就仆倒在地，听见有声音对我说：‘扫罗！扫罗！你为什么逼迫我？’"},
{volumn:        44,chapter:        22,versenumber:         8,verse:"我回答说：‘主啊，你是谁？’他说：‘我就是你所逼迫的拿撒勒人耶稣。’"},
{volumn:        44,chapter:        22,versenumber:         9,verse:"与我同行的人看见了那光，却没有听明那位对我说话的声音。"},
{volumn:        44,chapter:        22,versenumber:        10,verse:"我说：‘主啊，我当做什么？’主说：‘起来，进大马士革去，在那里，要将所派你做的一切事告诉你。’"},
{volumn:        44,chapter:        22,versenumber:        11,verse:"我因那光的荣耀不能看见，同行的人就拉着我手进了大马士革。"},
{volumn:        44,chapter:        22,versenumber:        12,verse:"那里有一个人，名叫亚拿尼亚，按着律法是虔诚人，为一切住在那里的犹太人所称赞。"},
{volumn:        44,chapter:        22,versenumber:        13,verse:"他来见我，站在旁边，对我说：‘兄弟扫罗，你可以看见。’我当时往上一看，就看见了他。"},
{volumn:        44,chapter:        22,versenumber:        14,verse:"他又说：‘我们祖宗的　神拣选了你，叫你明白他的旨意，又得见那义者，听他口中所出的声音。"},
{volumn:        44,chapter:        22,versenumber:        15,verse:"因为你要将所看见的，所听见的，对着万人为他作见证。"},
{volumn:        44,chapter:        22,versenumber:        16,verse:"现在你为什么耽延呢？起来，求告他的名受洗，洗去你的罪。’”"},
{volumn:        44,chapter:        22,versenumber:        17,verse:"“后来，我回到耶路撒冷，在殿里祷告的时候，魂游象外，"},
{volumn:        44,chapter:        22,versenumber:        18,verse:"看见主向我说：‘你赶紧地离开耶路撒冷，不可迟延；因你为我作的见证，这里的人必不领受。’"},
{volumn:        44,chapter:        22,versenumber:        19,verse:"我就说：‘主啊，他们知道我从前把信你的人收在监里，又在各会堂里鞭打他们。"},
{volumn:        44,chapter:        22,versenumber:        20,verse:"并且你的见证人司提反被害流血的时候，我也站在旁边欢喜；又看守害死他之人的衣裳。’"},
{volumn:        44,chapter:        22,versenumber:        21,verse:"主向我说：‘你去吧！我要差你远远地往外邦人那里去。’”"},
{volumn:        44,chapter:        22,versenumber:        22,verse:"众人听他说到这句话，就高声说：“这样的人，从世上除掉他吧！他是不当活着的。”"},
{volumn:        44,chapter:        22,versenumber:        23,verse:"众人喧嚷，摔掉衣裳，把尘土向空中扬起来。"},
{volumn:        44,chapter:        22,versenumber:        24,verse:"千夫长就吩咐将保罗带进营楼去，叫人用鞭子拷问他，要知道他们向他这样喧嚷是为什么缘故。"},
{volumn:        44,chapter:        22,versenumber:        25,verse:"刚用皮条捆上，保罗对旁边站着的百夫长说：“人是罗马人，又没有定罪，你们就鞭打他，有这个例吗？”"},
{volumn:        44,chapter:        22,versenumber:        26,verse:"百夫长听见这话，就去见千夫长，告诉他说：“你要做什么？这人是罗马人。”"},
{volumn:        44,chapter:        22,versenumber:        27,verse:"千夫长就来问保罗说：“你告诉我，你是罗马人吗？”保罗说：“是。”"},
{volumn:        44,chapter:        22,versenumber:        28,verse:"千夫长说：“我用许多银子才入了罗马的民籍。”保罗说：“我生来就是。”"},
{volumn:        44,chapter:        22,versenumber:        29,verse:"于是那些要拷问保罗的人就离开他去了。千夫长既知道他是罗马人，又因为捆绑了他，也害怕了。"},
{volumn:        44,chapter:        22,versenumber:        30,verse:"第二天，千夫长为要知道犹太人控告保罗的实情，便解开他，吩咐祭司长和全公会的人都聚集，将保罗带下来，叫他站在他们面前。"},
{volumn:        44,chapter:        23,versenumber:         1,verse:"保罗定睛看着公会的人，说：“弟兄们，我在　神面前行事为人都是凭着良心，直到今日。”"},
{volumn:        44,chapter:        23,versenumber:         2,verse:"大祭司亚拿尼亚就吩咐旁边站着的人打他的嘴。"},
{volumn:        44,chapter:        23,versenumber:         3,verse:"保罗对他说：“你这粉饰的墙，　神要打你！你坐堂为的是按律法审问我，你竟违背律法，吩咐人打我吗？”"},
{volumn:        44,chapter:        23,versenumber:         4,verse:"站在旁边的人说：“你辱骂　神的大祭司吗？”"},
{volumn:        44,chapter:        23,versenumber:         5,verse:"保罗说：“弟兄们，我不晓得他是大祭司；经上记着说：‘不可毁谤你百姓的官长。’”"},
{volumn:        44,chapter:        23,versenumber:         6,verse:"保罗看出大众一半是撒都该人，一半是法利赛人，就在公会中大声说：“弟兄们，我是法利赛人，也是法利赛人的子孙。我现在受审问，是为盼望死人复活。”"},
{volumn:        44,chapter:        23,versenumber:         7,verse:"说了这话，法利赛人和撒都该人就争论起来，会众分为两党。"},
{volumn:        44,chapter:        23,versenumber:         8,verse:"因为撒都该人说，没有复活，也没有天使和鬼魂；法利赛人却说，两样都有。"},
{volumn:        44,chapter:        23,versenumber:         9,verse:"于是大大地喧嚷起来。有几个法利赛党的文士站起来争辩说：“我们看不出这人有什么恶处，倘若有鬼魂或是天使对他说过话，怎么样呢？”"},
{volumn:        44,chapter:        23,versenumber:        10,verse:"那时大起争吵，千夫长恐怕保罗被他们扯碎了，就吩咐兵丁下去，把他从众人当中抢出来，带进营楼去。"},
{volumn:        44,chapter:        23,versenumber:        11,verse:"当夜，主站在保罗旁边，说：“放心吧！你怎样在耶路撒冷为我作见证，也必怎样在罗马为我作见证。”"},
{volumn:        44,chapter:        23,versenumber:        12,verse:"到了天亮，犹太人同谋起誓，说：“若不先杀保罗就不吃不喝。”"},
{volumn:        44,chapter:        23,versenumber:        13,verse:"这样同心起誓的有四十多人。"},
{volumn:        44,chapter:        23,versenumber:        14,verse:"他们来见祭司长和长老，说：“我们已经起了一个大誓，若不先杀保罗就不吃什么。"},
{volumn:        44,chapter:        23,versenumber:        15,verse:"现在你们和公会要知会千夫长，叫他带下保罗到你们这里来，假作要详细察考他的事；我们已经预备好了，不等他来到跟前就杀他。”"},
{volumn:        44,chapter:        23,versenumber:        16,verse:"保罗的外甥听见他们设下埋伏，就来到营楼里告诉保罗。"},
{volumn:        44,chapter:        23,versenumber:        17,verse:"保罗请一个百夫长来，说：“你领这少年人去见千夫长，他有事告诉他。”"},
{volumn:        44,chapter:        23,versenumber:        18,verse:"于是把他领去见千夫长，说：“被囚的保罗请我到他那里，求我领这少年人来见你；他有事告诉你。”"},
{volumn:        44,chapter:        23,versenumber:        19,verse:"千夫长就拉着他的手，走到一旁，私下问他说：“你有什么事告诉我呢？”"},
{volumn:        44,chapter:        23,versenumber:        20,verse:"他说：“犹太人已经约定，要求你明天带下保罗到公会里去，假作要详细查问他的事。"},
{volumn:        44,chapter:        23,versenumber:        21,verse:"你切不要随从他们；因为他们有四十多人埋伏，已经起誓说，若不先杀保罗就不吃不喝。现在预备好了，只等你应允。”"},
{volumn:        44,chapter:        23,versenumber:        22,verse:"于是千夫长打发少年人走，嘱咐他说：“不要告诉人你将这事报给我了。”"},
{volumn:        44,chapter:        23,versenumber:        23,verse:"千夫长便叫了两个百夫长来，说：“预备步兵二百，马兵七十，长枪手二百，今夜亥初往凯撒利亚去；"},
{volumn:        44,chapter:        23,versenumber:        24,verse:"也要预备牲口叫保罗骑上，护送到巡抚腓力斯那里去。”"},
{volumn:        44,chapter:        23,versenumber:        25,verse:"千夫长又写了文书，"},
{volumn:        44,chapter:        23,versenumber:        26,verse:"大略说：“革老丢·吕西亚，请巡抚腓力斯大人安。"},
{volumn:        44,chapter:        23,versenumber:        27,verse:"这人被犹太人拿住，将要杀害，我得知他是罗马人，就带兵丁下去救他出来。"},
{volumn:        44,chapter:        23,versenumber:        28,verse:"因要知道他们告他的缘故，我就带他下到他们的公会去，"},
{volumn:        44,chapter:        23,versenumber:        29,verse:"便查知他被告是因他们律法的辩论，并没有什么该死该绑的罪名。"},
{volumn:        44,chapter:        23,versenumber:        30,verse:"后来有人把要害他的计谋告诉我，我就立时解他到你那里去，又吩咐告他的人在你面前告他。（有古卷加：愿你平安！）”"},
{volumn:        44,chapter:        23,versenumber:        31,verse:"于是，兵丁照所吩咐他们的，将保罗夜里带到安提帕底。"},
{volumn:        44,chapter:        23,versenumber:        32,verse:"第二天，让马兵护送，他们就回营楼去。"},
{volumn:        44,chapter:        23,versenumber:        33,verse:"马兵来到凯撒利亚，把文书呈给巡抚，便叫保罗站在他面前。"},
{volumn:        44,chapter:        23,versenumber:        34,verse:"巡抚看了文书，问保罗是哪省的人，既晓得他是基利家人，"},
{volumn:        44,chapter:        23,versenumber:        35,verse:"就说：“等告你的人来到，我要细听你的事”；便吩咐人把他看守在希律的衙门里。"},
{volumn:        44,chapter:        24,versenumber:         1,verse:"过了五天，大祭司亚拿尼亚同几个长老，和一个辩士帖土罗下来，向巡抚控告保罗。"},
{volumn:        44,chapter:        24,versenumber:         2,verse:"保罗被提了来，帖土罗就告他说："},
{volumn:        44,chapter:        24,versenumber:         3,verse:"“腓力斯大人，我们因你得以大享太平，并且这一国的弊病，因着你的先见得以更正了；我们随时随地满心感谢不尽。"},
{volumn:        44,chapter:        24,versenumber:         4,verse:"惟恐多说，你嫌烦絮，只求你宽容听我们说几句话。"},
{volumn:        44,chapter:        24,versenumber:         5,verse:"我们看这个人，如同瘟疫一般，是鼓动普天下众犹太人生乱的，又是拿撒勒教党里的一个头目，"},
{volumn:        44,chapter:        24,versenumber:         6,verse:"连圣殿他也想要污秽；我们把他捉住了。（有古卷加：要按我们的律法审问，7不料，千夫长吕西亚前来，甚是强横，从我们手中把他夺去，吩咐告他的人到你这里来。）"},
{volumn:        44,chapter:        24,versenumber:         7,verse:""},
{volumn:        44,chapter:        24,versenumber:         8,verse:"你自己究问他，就可以知道我们告他的一切事了。”"},
{volumn:        44,chapter:        24,versenumber:         9,verse:"众犹太人也随着告他说：“事情诚然是这样。”"},
{volumn:        44,chapter:        24,versenumber:        10,verse:"巡抚点头叫保罗说话。他就说：“我知道你在这国里断事多年，所以我乐意为自己分诉。"},
{volumn:        44,chapter:        24,versenumber:        11,verse:"你查问就可以知道，从我上耶路撒冷礼拜到今日不过有十二天。"},
{volumn:        44,chapter:        24,versenumber:        12,verse:"他们并没有看见我在殿里，或是在会堂里，或是在城里，和人辩论，耸动众人。"},
{volumn:        44,chapter:        24,versenumber:        13,verse:"他们现在所告我的事并不能对你证实了。"},
{volumn:        44,chapter:        24,versenumber:        14,verse:"但有一件事，我向你承认，就是他们所称为异端的道，我正按着那道侍奉我祖宗的　神，又信合乎律法的和先知书上一切所记载的，"},
{volumn:        44,chapter:        24,versenumber:        15,verse:"并且靠着　神，盼望死人，无论善恶，都要复活，就是他们自己也有这个盼望。"},
{volumn:        44,chapter:        24,versenumber:        16,verse:"我因此自己勉励，对　神对人，常存无亏的良心。"},
{volumn:        44,chapter:        24,versenumber:        17,verse:"过了几年，我带着周济本国的捐项和供献的物上去。"},
{volumn:        44,chapter:        24,versenumber:        18,verse:"正献的时候，他们看见我在殿里已经洁净了，并没有聚众，也没有吵嚷，惟有几个从亚细亚来的犹太人。"},
{volumn:        44,chapter:        24,versenumber:        19,verse:"他们若有告我的事，就应当到你面前来告我。"},
{volumn:        44,chapter:        24,versenumber:        20,verse:"即或不然，这些人若看出我站在公会前，有妄为的地方，他们自己也可以说明。"},
{volumn:        44,chapter:        24,versenumber:        21,verse:"纵然有，也不过一句话，就是我站在他们中间大声说：‘我今日在你们面前受审，是为死人复活的道理。’”"},
{volumn:        44,chapter:        24,versenumber:        22,verse:"腓力斯本是详细晓得这道，就支吾他们说：“且等千夫长吕西亚下来，我要审断你们的事。”"},
{volumn:        44,chapter:        24,versenumber:        23,verse:"于是吩咐百夫长看守保罗，并且宽待他，也不拦阻他的亲友来供给他。"},
{volumn:        44,chapter:        24,versenumber:        24,verse:"过了几天，腓力斯和他夫人、犹太的女子土西拉一同来到，就叫了保罗来，听他讲论信基督耶稣的道。"},
{volumn:        44,chapter:        24,versenumber:        25,verse:"保罗讲论公义、节制，和将来的审判。腓力斯甚觉恐惧，说：“你暂且去吧，等我得便再叫你来。”"},
{volumn:        44,chapter:        24,versenumber:        26,verse:"腓力斯又指望保罗送他银钱，所以屡次叫他来，和他谈论。"},
{volumn:        44,chapter:        24,versenumber:        27,verse:"过了两年，波求·非斯都接了腓力斯的任；腓力斯要讨犹太人的喜欢，就留保罗在监里。"},
{volumn:        44,chapter:        25,versenumber:         1,verse:"非斯都到了任，过了三天，就从凯撒利亚上耶路撒冷去。"},
{volumn:        44,chapter:        25,versenumber:         2,verse:"祭司长和犹太人的首领向他控告保罗，"},
{volumn:        44,chapter:        25,versenumber:         3,verse:"又央告他，求他的情，将保罗提到耶路撒冷来，他们要在路上埋伏杀害他。"},
{volumn:        44,chapter:        25,versenumber:         4,verse:"非斯都却回答说：“保罗押在凯撒利亚，我自己快要往那里去”；"},
{volumn:        44,chapter:        25,versenumber:         5,verse:"又说：“你们中间有权势的人与我一同下去，那人若有什么不是，就可以告他。”"},
{volumn:        44,chapter:        25,versenumber:         6,verse:"非斯都在他们那里住了不过十天八天，就下凯撒利亚去；第二天坐堂，吩咐将保罗提上来。"},
{volumn:        44,chapter:        25,versenumber:         7,verse:"保罗来了，那些从耶路撒冷下来的犹太人周围站着，将许多重大的事控告他，都是不能证实的。"},
{volumn:        44,chapter:        25,versenumber:         8,verse:"保罗分诉说：“无论犹太人的律法，或是圣殿，或是凯撒，我都没有干犯。”"},
{volumn:        44,chapter:        25,versenumber:         9,verse:"但非斯都要讨犹太人的喜欢，就问保罗说：“你愿意上耶路撒冷去，在那里听我审断这事吗？”"},
{volumn:        44,chapter:        25,versenumber:        10,verse:"保罗说：“我站在凯撒的堂前，这就是我应当受审的地方。我向犹太人并没有行过什么不义的事，这也是你明明知道的。"},
{volumn:        44,chapter:        25,versenumber:        11,verse:"我若行了不义的事，犯了什么该死的罪，就是死，我也不辞。他们所告我的事若都不实，就没有人可以把我交给他们。我要上告于凯撒。”"},
{volumn:        44,chapter:        25,versenumber:        12,verse:"非斯都和议会商量了，就说：“你既上告于凯撒，可以往凯撒那里去。”"},
{volumn:        44,chapter:        25,versenumber:        13,verse:"过了些日子，亚基帕王和百尼基氏来到凯撒利亚，问非斯都安。"},
{volumn:        44,chapter:        25,versenumber:        14,verse:"在那里住了多日，非斯都将保罗的事告诉王，说：“这里有一个人，是腓力斯留在监里的。"},
{volumn:        44,chapter:        25,versenumber:        15,verse:"我在耶路撒冷的时候，祭司长和犹太的长老将他的事禀报了我，求我定他的罪。"},
{volumn:        44,chapter:        25,versenumber:        16,verse:"我对他们说，无论什么人，被告还没有和原告对质，未得机会分诉所告他的事，就先定他的罪，这不是罗马人的条例。"},
{volumn:        44,chapter:        25,versenumber:        17,verse:"及至他们都来到这里，我就不耽延，第二天便坐堂，吩咐把那人提上来。"},
{volumn:        44,chapter:        25,versenumber:        18,verse:"告他的人站着告他；所告的，并没有我所逆料的那等恶事。"},
{volumn:        44,chapter:        25,versenumber:        19,verse:"不过是有几样辩论，为他们自己敬鬼神的事，又为一个人名叫耶稣，是已经死了，保罗却说他是活着的。"},
{volumn:        44,chapter:        25,versenumber:        20,verse:"这些事当怎样究问，我心里作难，所以问他说：‘你愿意上耶路撒冷去，在那里为这些事听审吗？’"},
{volumn:        44,chapter:        25,versenumber:        21,verse:"但保罗求我留下他，要听皇上审断，我就吩咐把他留下，等我解他到凯撒那里去。”"},
{volumn:        44,chapter:        25,versenumber:        22,verse:"亚基帕对非斯都说：“我自己也愿听这人辩论。”非斯都说：“明天你可以听。”"},
{volumn:        44,chapter:        25,versenumber:        23,verse:"第二天，亚基帕和百尼基大张威势而来，同着众千夫长和城里的尊贵人进了公厅。非斯都吩咐一声，就有人将保罗带进来。"},
{volumn:        44,chapter:        25,versenumber:        24,verse:"非斯都说：“亚基帕王和在这里的诸位啊，你们看这人，就是一切犹太人，在耶路撒冷和这里，曾向我恳求、呼叫说：‘不可容他再活着。’"},
{volumn:        44,chapter:        25,versenumber:        25,verse:"但我查明他没有犯什么该死的罪，并且他自己上告于皇帝，所以我定意把他解去。"},
{volumn:        44,chapter:        25,versenumber:        26,verse:"论到这人，我没有确实的事可以奏明主上。因此，我带他到你们面前，也特意带他到你亚基帕王面前，为要在查问之后有所陈奏。"},
{volumn:        44,chapter:        25,versenumber:        27,verse:"据我看来，解送囚犯，不指明他的罪案是不合理的。”"},
{volumn:        44,chapter:        26,versenumber:         1,verse:"亚基帕对保罗说：“准你为自己辩明。”于是保罗伸手分诉，说：“"},
{volumn:        44,chapter:        26,versenumber:         2,verse:"亚基帕王啊，犹太人所告我的一切事，今日得在你面前分诉，实为万幸；"},
{volumn:        44,chapter:        26,versenumber:         3,verse:"更可幸的，是你熟悉犹太人的规矩和他们的辩论；所以求你耐心听我。"},
{volumn:        44,chapter:        26,versenumber:         4,verse:"我从起初在本国的民中，并在耶路撒冷，自幼为人如何，犹太人都知道。"},
{volumn:        44,chapter:        26,versenumber:         5,verse:"他们若肯作见证就晓得，我从起初是按着我们教中最严紧的教门作了法利赛人。"},
{volumn:        44,chapter:        26,versenumber:         6,verse:"现在我站在这里受审，是因为指望　神向我们祖宗所应许的；"},
{volumn:        44,chapter:        26,versenumber:         7,verse:"这应许，我们十二个支派，昼夜切切地侍奉　神，都指望得着。王啊，我被犹太人控告，就是因这指望。"},
{volumn:        44,chapter:        26,versenumber:         8,verse:"神叫死人复活，你们为什么看作不可信的呢？"},
{volumn:        44,chapter:        26,versenumber:         9,verse:"从前我自己以为应当多方攻击拿撒勒人耶稣的名，"},
{volumn:        44,chapter:        26,versenumber:        10,verse:"我在耶路撒冷也曾这样行了。既从祭司长得了权柄，我就把许多圣徒囚在监里。他们被杀，我也出名定案。"},
{volumn:        44,chapter:        26,versenumber:        11,verse:"在各会堂，我屡次用刑强逼他们说亵渎的话，又分外恼恨他们，甚至追逼他们，直到外邦的城邑。”"},
{volumn:        44,chapter:        26,versenumber:        12,verse:"“那时，我领了祭司长的权柄和命令，往大马士革去。"},
{volumn:        44,chapter:        26,versenumber:        13,verse:"王啊，我在路上，晌午的时候，看见从天发光，比日头还亮，四面照着我并与我同行的人。"},
{volumn:        44,chapter:        26,versenumber:        14,verse:"我们都仆倒在地，我就听见有声音用希伯来话向我说：‘扫罗！扫罗！为什么逼迫我？你用脚踢刺是难的！’"},
{volumn:        44,chapter:        26,versenumber:        15,verse:"我说：‘主啊，你是谁？’主说：‘我就是你所逼迫的耶稣。"},
{volumn:        44,chapter:        26,versenumber:        16,verse:"你起来站着，我特意向你显现，要派你作执事，作见证，将你所看见的事和我将要指示你的事证明出来。"},
{volumn:        44,chapter:        26,versenumber:        17,verse:"我也要救你脱离百姓和外邦人的手。"},
{volumn:        44,chapter:        26,versenumber:        18,verse:"我差你到他们那里去，要叫他们的眼睛得开，从黑暗中归向光明，从撒但权下归向　神；又因信我，得蒙赦罪，和一切成圣的人同得基业。’”"},
{volumn:        44,chapter:        26,versenumber:        19,verse:"“亚基帕王啊，我故此没有违背那从天上来的异象；"},
{volumn:        44,chapter:        26,versenumber:        20,verse:"先在大马士革，后在耶路撒冷和犹太全地，以及外邦，劝勉他们应当悔改归向　神，行事与悔改的心相称。"},
{volumn:        44,chapter:        26,versenumber:        21,verse:"因此，犹太人在殿里拿住我，想要杀我。"},
{volumn:        44,chapter:        26,versenumber:        22,verse:"然而我蒙　神的帮助，直到今日还站得住，对着尊贵、卑贱、老幼作见证；所讲的并不外乎众先知和摩西所说将来必成的事，"},
{volumn:        44,chapter:        26,versenumber:        23,verse:"就是基督必须受害，并且因从死里复活，要首先把光明的道传给百姓和外邦人。”"},
{volumn:        44,chapter:        26,versenumber:        24,verse:"保罗这样分诉，非斯都大声说：“保罗，你癫狂了吧。你的学问太大，反叫你癫狂了！”"},
{volumn:        44,chapter:        26,versenumber:        25,verse:"保罗说：“非斯都大人，我不是癫狂，我说的乃是真实明白话。"},
{volumn:        44,chapter:        26,versenumber:        26,verse:"王也晓得这些事，所以我向王放胆直言，我深信这些事没有一件向王隐藏的，因都不是在背地里做的。"},
{volumn:        44,chapter:        26,versenumber:        27,verse:"亚基帕王啊，你信先知吗？我知道你是信的。”"},
{volumn:        44,chapter:        26,versenumber:        28,verse:"亚基帕对保罗说：“你想稍微一劝，便叫我作基督徒啊（或译：你这样劝我，几乎叫我作基督徒了）！”"},
{volumn:        44,chapter:        26,versenumber:        29,verse:"保罗说：“无论是少劝是多劝，我向　神所求的，不但你一个人，就是今天一切听我的，都要像我一样，只是不要像我有这些锁链。”"},
{volumn:        44,chapter:        26,versenumber:        30,verse:"于是，王和巡抚并百尼基与同坐的人都起来，"},
{volumn:        44,chapter:        26,versenumber:        31,verse:"退到里面，彼此谈论说：“这人并没有犯什么该死该绑的罪。”"},
{volumn:        44,chapter:        26,versenumber:        32,verse:"亚基帕又对非斯都说：“这人若没有上告于凯撒，就可以释放了。”"},
{volumn:        44,chapter:        27,versenumber:         1,verse:"非斯都既然定规了，叫我们坐船往意大利去，便将保罗和别的囚犯交给御营里的一个百夫长，名叫犹流。"},
{volumn:        44,chapter:        27,versenumber:         2,verse:"有一只亚大米田的船，要沿着亚细亚一带地方的海边走，我们就上了那船开行；有马其顿的帖撒罗尼迦人亚里达古和我们同去。"},
{volumn:        44,chapter:        27,versenumber:         3,verse:"第二天，到了西顿；犹流宽待保罗，准他往朋友那里去，受他们的照应。"},
{volumn:        44,chapter:        27,versenumber:         4,verse:"从那里又开船，因为风不顺，就贴着塞浦路斯背风岸行去。"},
{volumn:        44,chapter:        27,versenumber:         5,verse:"过了基利家、旁非利亚前面的海，就到了吕家的每拉。"},
{volumn:        44,chapter:        27,versenumber:         6,verse:"在那里，百夫长遇见一只亚历山大的船，要往意大利去，便叫我们上了那船。"},
{volumn:        44,chapter:        27,versenumber:         7,verse:"一连多日，船行得慢，仅仅来到革尼土的对面。因为被风拦阻，就贴着克里特背风岸，从撒摩尼对面行过。"},
{volumn:        44,chapter:        27,versenumber:         8,verse:"我们沿岸行走，仅仅来到一个地方，名叫佳澳；离那里不远，有拉西亚城。"},
{volumn:        44,chapter:        27,versenumber:         9,verse:"走的日子多了，已经过了禁食的节期，行船又危险，保罗就劝众人说："},
{volumn:        44,chapter:        27,versenumber:        10,verse:"“众位，我看这次行船，不但货物和船要受损伤，大遭破坏，连我们的性命也难保。”"},
{volumn:        44,chapter:        27,versenumber:        11,verse:"但百夫长信从掌船的和船主，不信从保罗所说的。"},
{volumn:        44,chapter:        27,versenumber:        12,verse:"且因在这海口过冬不便，船上的人就多半说，不如开船离开这地方，或者能到非尼基过冬。非尼基是克里特的一个海口，一面朝东北，一面朝东南。"},
{volumn:        44,chapter:        27,versenumber:        13,verse:"这时，微微起了南风，他们以为得意，就起了锚，贴近克里特行去。"},
{volumn:        44,chapter:        27,versenumber:        14,verse:"不多几时，狂风从岛上扑下来；那风名叫“友拉革罗”。"},
{volumn:        44,chapter:        27,versenumber:        15,verse:"船被风抓住，敌不住风，我们就任风刮去。"},
{volumn:        44,chapter:        27,versenumber:        16,verse:"贴着一个小岛的背风岸奔行，那岛名叫高大，在那里仅仅收住了小船。"},
{volumn:        44,chapter:        27,versenumber:        17,verse:"既然把小船拉上来，就用缆索捆绑船底，又恐怕在赛耳底沙滩上搁了浅，就落下篷来，任船飘去。"},
{volumn:        44,chapter:        27,versenumber:        18,verse:"我们被风浪逼得甚急，第二天众人就把货物抛在海里。"},
{volumn:        44,chapter:        27,versenumber:        19,verse:"到第三天，他们又亲手把船上的器具抛弃了。"},
{volumn:        44,chapter:        27,versenumber:        20,verse:"太阳和星辰多日不显露，又有狂风大浪催逼，我们得救的指望就都绝了。"},
{volumn:        44,chapter:        27,versenumber:        21,verse:"众人多日没有吃什么，保罗就出来站在他们中间，说：“众位，你们本该听我的话，不离开克里特，免得遭这样的伤损破坏。"},
{volumn:        44,chapter:        27,versenumber:        22,verse:"现在我还劝你们放心，你们的性命一个也不失丧，惟独失丧这船。"},
{volumn:        44,chapter:        27,versenumber:        23,verse:"因我所属所侍奉的　神，他的使者昨夜站在我旁边，说："},
{volumn:        44,chapter:        27,versenumber:        24,verse:"‘保罗，不要害怕，你必定站在凯撒面前，并且与你同船的人，　神都赐给你了。’"},
{volumn:        44,chapter:        27,versenumber:        25,verse:"所以众位可以放心，我信　神他怎样对我说，事情也要怎样成就。"},
{volumn:        44,chapter:        27,versenumber:        26,verse:"只是我们必要撞在一个岛上。”"},
{volumn:        44,chapter:        27,versenumber:        27,verse:"到了第十四天夜间，船在亚得里亚海飘来飘去。约到半夜，水手以为渐近旱地，"},
{volumn:        44,chapter:        27,versenumber:        28,verse:"就探深浅，探得有十二丈；稍往前行，又探深浅，探得有九丈。"},
{volumn:        44,chapter:        27,versenumber:        29,verse:"恐怕撞在石头上，就从船尾抛下四个锚，盼望天亮。"},
{volumn:        44,chapter:        27,versenumber:        30,verse:"水手想要逃出船去，把小船放在海里，假作要从船头抛锚的样子。"},
{volumn:        44,chapter:        27,versenumber:        31,verse:"保罗对百夫长和兵丁说：“这些人若不等在船上，你们必不能得救。”"},
{volumn:        44,chapter:        27,versenumber:        32,verse:"于是兵丁砍断小船的绳子，由它飘去。"},
{volumn:        44,chapter:        27,versenumber:        33,verse:"天渐亮的时候，保罗劝众人都吃饭，说：“你们悬望忍饿不吃什么，已经十四天了。"},
{volumn:        44,chapter:        27,versenumber:        34,verse:"所以我劝你们吃饭，这是关乎你们救命的事；因为你们各人连一根头发也不至于损坏。”"},
{volumn:        44,chapter:        27,versenumber:        35,verse:"保罗说了这话，就拿着饼，在众人面前祝谢了　神，擘开吃。"},
{volumn:        44,chapter:        27,versenumber:        36,verse:"于是他们都放下心，也就吃了。"},
{volumn:        44,chapter:        27,versenumber:        37,verse:"我们在船上的共有二百七十六个人。"},
{volumn:        44,chapter:        27,versenumber:        38,verse:"他们吃饱了，就把船上的麦子抛在海里，为要叫船轻一点。"},
{volumn:        44,chapter:        27,versenumber:        39,verse:"到了天亮，他们不认识那地方，但见一个海湾，有岸可登，就商议能把船拢进去不能。"},
{volumn:        44,chapter:        27,versenumber:        40,verse:"于是砍断缆索，弃锚在海里；同时也松开舵绳，拉起头篷，顺着风向岸行去。"},
{volumn:        44,chapter:        27,versenumber:        41,verse:"但遇着两水夹流的地方，就把船搁了浅；船头胶住不动，船尾被浪的猛力冲坏。"},
{volumn:        44,chapter:        27,versenumber:        42,verse:"兵丁的意思要把囚犯杀了，恐怕有洑水脱逃的。"},
{volumn:        44,chapter:        27,versenumber:        43,verse:"但百夫长要救保罗，不准他们任意而行，就吩咐会洑水的，跳下水去先上岸；"},
{volumn:        44,chapter:        27,versenumber:        44,verse:"其余的人可以用板子或船上的零碎东西上岸。这样，众人都得了救，上了岸。"},
{volumn:        44,chapter:        28,versenumber:         1,verse:"我们既已得救，才知道那岛名叫马耳他。"},
{volumn:        44,chapter:        28,versenumber:         2,verse:"土人看待我们，有非常的情分；因为当时下雨，天气又冷，就生火接待我们众人。"},
{volumn:        44,chapter:        28,versenumber:         3,verse:"那时，保罗拾起一捆柴，放在火上，有一条毒蛇，因为热了出来，咬住他的手。"},
{volumn:        44,chapter:        28,versenumber:         4,verse:"土人看见那毒蛇悬在他手上，就彼此说：“这人必是个凶手，虽然从海里救上来，天理还不容他活着。”"},
{volumn:        44,chapter:        28,versenumber:         5,verse:"保罗竟把那毒蛇甩在火里，并没有受伤。"},
{volumn:        44,chapter:        28,versenumber:         6,verse:"土人想他必要肿起来，或是忽然仆倒死了；看了多时，见他无害，就转念，说：“他是个神。”"},
{volumn:        44,chapter:        28,versenumber:         7,verse:"离那地方不远，有田产是岛长部百流的；他接纳我们，尽情款待三日。"},
{volumn:        44,chapter:        28,versenumber:         8,verse:"当时，部百流的父亲患热病和痢疾躺着。保罗进去，为他祷告，按手在他身上，治好了他。"},
{volumn:        44,chapter:        28,versenumber:         9,verse:"从此，岛上其余的病人也来，得了医治。"},
{volumn:        44,chapter:        28,versenumber:        10,verse:"他们又多方地尊敬我们；到了开船的时候，也把我们所需用的送到船上。"},
{volumn:        44,chapter:        28,versenumber:        11,verse:"过了三个月，我们上了亚历山大的船往前行；这船以“宙斯双子”为记，是在那海岛过了冬的。"},
{volumn:        44,chapter:        28,versenumber:        12,verse:"到了叙拉古，我们停泊三日；"},
{volumn:        44,chapter:        28,versenumber:        13,verse:"又从那里绕行，来到利基翁。过了一天，起了南风，第二天就来到部丢利。"},
{volumn:        44,chapter:        28,versenumber:        14,verse:"在那里遇见弟兄们，请我们与他们同住了七天。这样，我们来到罗马。"},
{volumn:        44,chapter:        28,versenumber:        15,verse:"那里的弟兄们一听见我们的信息就出来，到亚比乌市和三馆地方迎接我们。保罗见了他们，就感谢　神，放心壮胆。"},
{volumn:        44,chapter:        28,versenumber:        16,verse:"进了罗马城，（有古卷加：百夫长把众囚犯交给御营的统领，惟有）保罗蒙准和一个看守他的兵另住在一处。"},
{volumn:        44,chapter:        28,versenumber:        17,verse:"过了三天，保罗请犹太人的首领来。他们来了，就对他们说：“弟兄们，我虽没有做什么事干犯本国的百姓和我们祖宗的规条，却被锁绑，从耶路撒冷解在罗马人的手里。"},
{volumn:        44,chapter:        28,versenumber:        18,verse:"他们审问了我，就愿意释放我；因为在我身上，并没有该死的罪。"},
{volumn:        44,chapter:        28,versenumber:        19,verse:"无奈犹太人不服，我不得已，只好上告于凯撒，并非有什么事要控告我本国的百姓。"},
{volumn:        44,chapter:        28,versenumber:        20,verse:"因此，我请你们来见面说话，我原为以色列人所指望的，被这链子捆锁。”"},
{volumn:        44,chapter:        28,versenumber:        21,verse:"他们说：“我们并没有接着从犹太来论你的信，也没有弟兄到这里来报给我们说你有什么不好处。"},
{volumn:        44,chapter:        28,versenumber:        22,verse:"但我们愿意听你的意见如何；因为这教门，我们晓得是到处被毁谤的。”"},
{volumn:        44,chapter:        28,versenumber:        23,verse:"他们和保罗约定了日子，就有许多人到他的寓处来。保罗从早到晚，对他们讲论这事，证明　神国的道，引摩西的律法和先知的书，以耶稣的事劝勉他们。"},
{volumn:        44,chapter:        28,versenumber:        24,verse:"他所说的话，有信的，有不信的。"},
{volumn:        44,chapter:        28,versenumber:        25,verse:"他们彼此不合，就散了；未散以先，保罗说了一句话，说：“圣灵藉先知以赛亚向你们祖宗所说的话是不错的。"},
{volumn:        44,chapter:        28,versenumber:        26,verse:"他说：‘你去告诉这百姓说：你们听是要听见，却不明白；看是要看见，却不晓得；"},
{volumn:        44,chapter:        28,versenumber:        27,verse:"因为这百姓油蒙了心，耳朵发沉，眼睛闭着；恐怕眼睛看见，耳朵听见，心里明白，回转过来，我就医治他们。’"},
{volumn:        44,chapter:        28,versenumber:        28,verse:"所以你们当知道，　神这救恩，如今传给外邦人，他们也必听受。”（有古卷加：29保罗说了这话，犹太人议论纷纷地就走了。）"},
{volumn:        44,chapter:        28,versenumber:        29,verse:""},
{volumn:        44,chapter:        28,versenumber:        30,verse:"保罗在自己所租的房子里住了足足两年。凡来见他的人，他全都接待，"},
{volumn:        44,chapter:        28,versenumber:        31,verse:"放胆传讲　神国的道，将主耶稣基督的事教导人，并没有人禁止。"},
{volumn:        45,chapter:         1,versenumber:         1,verse:"耶稣基督的仆人保罗，奉召为使徒，特派传　神的福音。"},
{volumn:        45,chapter:         1,versenumber:         2,verse:"这福音是　神从前藉众先知在圣经上所应许的，"},
{volumn:        45,chapter:         1,versenumber:         3,verse:"论到他儿子我主耶稣基督，按肉体说，是从大卫后裔生的；"},
{volumn:        45,chapter:         1,versenumber:         4,verse:"按圣善的灵说，因从死里复活，以大能显明是　神的儿子。"},
{volumn:        45,chapter:         1,versenumber:         5,verse:"我们从他受了恩惠并使徒的职分，在万国之中叫人为他的名信服真道；"},
{volumn:        45,chapter:         1,versenumber:         6,verse:"其中也有你们这蒙召属耶稣基督的人。"},
{volumn:        45,chapter:         1,versenumber:         7,verse:"我写信给你们在罗马、为　神所爱、奉召作圣徒的众人。愿恩惠、平安从我们的父　神并主耶稣基督归与你们！"},
{volumn:        45,chapter:         1,versenumber:         8,verse:"第一，我靠着耶稣基督，为你们众人感谢我的　神，因你们的信德传遍了天下。"},
{volumn:        45,chapter:         1,versenumber:         9,verse:"我在他儿子福音上，用心灵所侍奉的　神可以见证，我怎样不住地提到你们；"},
{volumn:        45,chapter:         1,versenumber:        10,verse:"在祷告之间常常恳求，或者照　神的旨意，终能得平坦的道路往你们那里去。"},
{volumn:        45,chapter:         1,versenumber:        11,verse:"因为我切切地想见你们，要把些属灵的恩赐分给你们，使你们可以坚固；"},
{volumn:        45,chapter:         1,versenumber:        12,verse:"这样，我在你们中间，因你与我彼此的信心，就可以同得安慰。"},
{volumn:        45,chapter:         1,versenumber:        13,verse:"弟兄们，我不愿意你们不知道，我屡次定意往你们那里去，要在你们中间得些果子，如同在其余的外邦人中一样；只是到如今仍有阻隔。"},
{volumn:        45,chapter:         1,versenumber:        14,verse:"无论是希腊人、化外人、聪明人、愚拙人，我都欠他们的债，"},
{volumn:        45,chapter:         1,versenumber:        15,verse:"所以情愿尽我的力量，将福音也传给你们在罗马的人。"},
{volumn:        45,chapter:         1,versenumber:        16,verse:"我不以福音为耻；这福音本是　神的大能，要救一切相信的，先是犹太人，后是希腊人。"},
{volumn:        45,chapter:         1,versenumber:        17,verse:"因为　神的义正在这福音上显明出来；这义是本于信，以致于信。如经上所记：“义人必因信得生。”"},
{volumn:        45,chapter:         1,versenumber:        18,verse:"原来，　神的忿怒从天上显明在一切不虔不义的人身上，就是那些行不义阻挡真理的人。"},
{volumn:        45,chapter:         1,versenumber:        19,verse:"神的事情，人所能知道的，原显明在人心里，因为　神已经给他们显明。"},
{volumn:        45,chapter:         1,versenumber:        20,verse:"自从造天地以来，　神的永能和　神性是明明可知的，虽是眼不能见，但藉着所造之物就可以晓得，叫人无可推诿。"},
{volumn:        45,chapter:         1,versenumber:        21,verse:"因为，他们虽然知道　神，却不当作　神荣耀他，也不感谢他。他们的思念变为虚妄，无知的心就昏暗了。"},
{volumn:        45,chapter:         1,versenumber:        22,verse:"自称为聪明，反成了愚拙，"},
{volumn:        45,chapter:         1,versenumber:        23,verse:"将不能朽坏之　神的荣耀变为偶像，仿佛必朽坏的人和飞禽、走兽、昆虫的样式。"},
{volumn:        45,chapter:         1,versenumber:        24,verse:"所以，　神任凭他们逞着心里的情欲行污秽的事，以致彼此玷辱自己的身体。"},
{volumn:        45,chapter:         1,versenumber:        25,verse:"他们将　神的真实变为虚谎，去敬拜侍奉受造之物，不敬奉那造物的主。主乃是可称颂的，直到永远。阿们！"},
{volumn:        45,chapter:         1,versenumber:        26,verse:"因此，　神任凭他们放纵可羞耻的情欲。他们的女人把顺性的用处变为逆性的用处；"},
{volumn:        45,chapter:         1,versenumber:        27,verse:"男人也是如此，弃了女人顺性的用处，欲火攻心，彼此贪恋，男和男行可羞耻的事，就在自己身上受这妄为当得的报应。"},
{volumn:        45,chapter:         1,versenumber:        28,verse:"他们既然故意不认识　神，　神就任凭他们存邪僻的心，行那些不合理的事；"},
{volumn:        45,chapter:         1,versenumber:        29,verse:"装满了各样不义、邪恶、贪婪、恶毒（或译：阴毒）；满心是嫉妒、凶杀、争竞、诡诈、毒恨；"},
{volumn:        45,chapter:         1,versenumber:        30,verse:"又是谗毁的、背后说人的、怨恨　神的（或译：被　神所憎恶的）、侮慢人的、狂傲的、自夸的、捏造恶事的、违背父母的、"},
{volumn:        45,chapter:         1,versenumber:        31,verse:"无知的、背约的、无亲情的、不怜悯人的。"},
{volumn:        45,chapter:         1,versenumber:        32,verse:"他们虽知道　神判定行这样事的人是当死的，然而他们不但自己去行，还喜欢别人去行。"},
{volumn:        45,chapter:         2,versenumber:         1,verse:"你这论断人的，无论你是谁，也无可推诿。你在什么事上论断人，就在什么事上定自己的罪；因你这论断人的，自己所行却和别人一样。"},
{volumn:        45,chapter:         2,versenumber:         2,verse:"我们知道这样行的人，　神必照真理审判他。"},
{volumn:        45,chapter:         2,versenumber:         3,verse:"你这人哪，你论断行这样事的人，自己所行的却和别人一样，你以为能逃脱　神的审判吗？"},
{volumn:        45,chapter:         2,versenumber:         4,verse:"还是你藐视他丰富的恩慈、宽容、忍耐，不晓得他的恩慈是领你悔改呢？"},
{volumn:        45,chapter:         2,versenumber:         5,verse:"你竟任着你刚硬不悔改的心，为自己积蓄忿怒，以致　神震怒，显他公义审判的日子来到。"},
{volumn:        45,chapter:         2,versenumber:         6,verse:"他必照各人的行为报应各人。"},
{volumn:        45,chapter:         2,versenumber:         7,verse:"凡恒心行善、寻求荣耀、尊贵和不能朽坏之福的，就以永生报应他们；"},
{volumn:        45,chapter:         2,versenumber:         8,verse:"惟有结党、不顺从真理、反顺从不义的，就以忿怒、恼恨报应他们；"},
{volumn:        45,chapter:         2,versenumber:         9,verse:"将患难、困苦加给一切作恶的人，先是犹太人，后是希腊人，"},
{volumn:        45,chapter:         2,versenumber:        10,verse:"却将荣耀、尊贵、平安加给一切行善的人，先是犹太人，后是希腊人。"},
{volumn:        45,chapter:         2,versenumber:        11,verse:"因为　神不偏待人。"},
{volumn:        45,chapter:         2,versenumber:        12,verse:"凡没有律法犯了罪的，也必不按律法灭亡；凡在律法以下犯了罪的，也必按律法受审判。（"},
{volumn:        45,chapter:         2,versenumber:        13,verse:"原来在　神面前，不是听律法的为义，乃是行律法的称义。"},
{volumn:        45,chapter:         2,versenumber:        14,verse:"没有律法的外邦人若顺着本性行律法上的事，他们虽然没有律法，自己就是自己的律法。"},
{volumn:        45,chapter:         2,versenumber:        15,verse:"这是显出律法的功用刻在他们心里，他们是非之心同作见证，并且他们的思念互相较量，或以为是，或以为非。）"},
{volumn:        45,chapter:         2,versenumber:        16,verse:"就在　神藉耶稣基督审判人隐秘事的日子，照着我的福音所言。"},
{volumn:        45,chapter:         2,versenumber:        17,verse:"你称为犹太人，又倚靠律法，且指着　神夸口；"},
{volumn:        45,chapter:         2,versenumber:        18,verse:"既从律法中受了教训，就晓得　神的旨意，也能分别是非（或译：也喜爱那美好的事）；"},
{volumn:        45,chapter:         2,versenumber:        19,verse:"又深信自己是给瞎子领路的，是黑暗中人的光，"},
{volumn:        45,chapter:         2,versenumber:        20,verse:"是蠢笨人的师傅，是小孩子的先生，在律法上有知识和真理的模范。"},
{volumn:        45,chapter:         2,versenumber:        21,verse:"你既是教导别人，还不教导自己吗？你讲说人不可偷窃，自己还偷窃吗？"},
{volumn:        45,chapter:         2,versenumber:        22,verse:"你说人不可奸淫，自己还奸淫吗？你厌恶偶像，自己还偷窃庙中之物吗？"},
{volumn:        45,chapter:         2,versenumber:        23,verse:"你指着律法夸口，自己倒犯律法、玷辱　神吗？"},
{volumn:        45,chapter:         2,versenumber:        24,verse:"神的名在外邦人中，因你们受了亵渎，正如经上所记的。"},
{volumn:        45,chapter:         2,versenumber:        25,verse:"你若是行律法的，割礼固然于你有益；若是犯律法的，你的割礼就算不得割礼。"},
{volumn:        45,chapter:         2,versenumber:        26,verse:"所以那未受割礼的，若遵守律法的条例，他虽然未受割礼，岂不算是有割礼吗？"},
{volumn:        45,chapter:         2,versenumber:        27,verse:"而且那本来未受割礼的，若能全守律法，岂不是要审判你这有仪文和割礼竟犯律法的人吗？"},
{volumn:        45,chapter:         2,versenumber:        28,verse:"因为外面作犹太人的，不是真犹太人；外面肉身的割礼，也不是真割礼。"},
{volumn:        45,chapter:         2,versenumber:        29,verse:"惟有里面作的，才是真犹太人；真割礼也是心里的，在乎灵，不在乎仪文。这人的称赞不是从人来的，乃是从　神来的。"},
{volumn:        45,chapter:         3,versenumber:         1,verse:"这样说来，犹太人有什么长处？割礼有什么益处呢？"},
{volumn:        45,chapter:         3,versenumber:         2,verse:"凡事大有好处：第一是　神的圣言交托他们。"},
{volumn:        45,chapter:         3,versenumber:         3,verse:"即便有不信的，这有何妨呢？难道他们的不信就废掉　神的信吗？"},
{volumn:        45,chapter:         3,versenumber:         4,verse:"断乎不能！不如说，　神是真实的，人都是虚谎的。如经上所记：“你责备人的时候，显为公义；被人议论的时候，可以得胜。”"},
{volumn:        45,chapter:         3,versenumber:         5,verse:"我且照着人的常话说，我们的不义若显出　神的义来，我们可以怎么说呢？　神降怒，是他不义吗？"},
{volumn:        45,chapter:         3,versenumber:         6,verse:"断乎不是！若是这样，　神怎能审判世界呢？"},
{volumn:        45,chapter:         3,versenumber:         7,verse:"若　神的真实，因我的虚谎越发显出他的荣耀，为什么我还受审判，好像罪人呢？"},
{volumn:        45,chapter:         3,versenumber:         8,verse:"为什么不说，我们可以作恶以成善呢？这是毁谤我们的人说我们有这话。这等人定罪是该当的。"},
{volumn:        45,chapter:         3,versenumber:         9,verse:"这却怎么样呢？我们比他们强吗？决不是的！因我们已经证明：犹太人和希腊人都在罪恶之下。"},
{volumn:        45,chapter:         3,versenumber:        10,verse:"就如经上所记：“没有义人，连一个也没有。"},
{volumn:        45,chapter:         3,versenumber:        11,verse:"没有明白的；没有寻求　神的；"},
{volumn:        45,chapter:         3,versenumber:        12,verse:"都是偏离正路，一同变为无用。没有行善的，连一个也没有。"},
{volumn:        45,chapter:         3,versenumber:        13,verse:"他们的喉咙是敞开的坟墓；他们用舌头弄诡诈，嘴唇里有虺蛇的毒气，"},
{volumn:        45,chapter:         3,versenumber:        14,verse:"满口是咒骂苦毒。"},
{volumn:        45,chapter:         3,versenumber:        15,verse:"杀人流血，他们的脚飞跑，"},
{volumn:        45,chapter:         3,versenumber:        16,verse:"所经过的路便行残害暴虐的事。"},
{volumn:        45,chapter:         3,versenumber:        17,verse:"平安的路，他们未曾知道；"},
{volumn:        45,chapter:         3,versenumber:        18,verse:"他们眼中不怕　神。”"},
{volumn:        45,chapter:         3,versenumber:        19,verse:"我们晓得律法上的话都是对律法以下之人说的，好塞住各人的口，叫普世的人都伏在　神审判之下。"},
{volumn:        45,chapter:         3,versenumber:        20,verse:"所以凡有血气的，没有一个因行律法能在　神面前称义，因为律法本是叫人知罪。"},
{volumn:        45,chapter:         3,versenumber:        21,verse:"但如今，　神的义在律法以外已经显明出来，有律法和先知为证："},
{volumn:        45,chapter:         3,versenumber:        22,verse:"就是　神的义，因信耶稣基督加给一切相信的人，并没有分别。"},
{volumn:        45,chapter:         3,versenumber:        23,verse:"因为世人都犯了罪，亏缺了　神的荣耀；"},
{volumn:        45,chapter:         3,versenumber:        24,verse:"如今却蒙　神的恩典，因基督耶稣的救赎，就白白地称义。"},
{volumn:        45,chapter:         3,versenumber:        25,verse:"神设立耶稣作挽回祭，是凭着耶稣的血，藉着人的信，要显明　神的义；因为他用忍耐的心宽容人先时所犯的罪，"},
{volumn:        45,chapter:         3,versenumber:        26,verse:"好在今时显明他的义，使人知道他自己为义，也称信耶稣的人为义。"},
{volumn:        45,chapter:         3,versenumber:        27,verse:"既是这样，哪里能夸口呢？没有可夸的了。用何法没有的呢？是用立功之法吗？不是，乃用信主之法。"},
{volumn:        45,chapter:         3,versenumber:        28,verse:"所以（有古卷：因为）我们看定了：人称义是因着信，不在乎遵行律法。"},
{volumn:        45,chapter:         3,versenumber:        29,verse:"难道　神只作犹太人的　神吗？不也是作外邦人的　神吗？是的，也作外邦人的　神。"},
{volumn:        45,chapter:         3,versenumber:        30,verse:"神既是一位，他就要因信称那受割礼的为义，也要因信称那未受割礼的为义。"},
{volumn:        45,chapter:         3,versenumber:        31,verse:"这样，我们因信废了律法吗？断乎不是！更是坚固律法。"},
{volumn:        45,chapter:         4,versenumber:         1,verse:"如此说来，我们的祖宗亚伯拉罕凭着肉体得了什么呢？"},
{volumn:        45,chapter:         4,versenumber:         2,verse:"倘若亚伯拉罕是因行为称义，就有可夸的；只是在　神面前并无可夸。"},
{volumn:        45,chapter:         4,versenumber:         3,verse:"经上说什么呢？说：“亚伯拉罕信　神，这就算为他的义。”"},
{volumn:        45,chapter:         4,versenumber:         4,verse:"做工的得工价，不算恩典，乃是该得的；"},
{volumn:        45,chapter:         4,versenumber:         5,verse:"惟有不做工的，只信称罪人为义的　神，他的信就算为义。"},
{volumn:        45,chapter:         4,versenumber:         6,verse:"正如大卫称那在行为以外蒙　神算为义的人是有福的。"},
{volumn:        45,chapter:         4,versenumber:         7,verse:"他说：“得赦免其过、遮盖其罪的，这人是有福的。"},
{volumn:        45,chapter:         4,versenumber:         8,verse:"主不算为有罪的，这人是有福的。”"},
{volumn:        45,chapter:         4,versenumber:         9,verse:"如此看来，这福是单加给那受割礼的人吗？不也是加给那未受割礼的人吗？因我们所说，亚伯拉罕的信，就算为他的义，"},
{volumn:        45,chapter:         4,versenumber:        10,verse:"是怎么算的呢？是在他受割礼的时候呢？是在他未受割礼的时候呢？不是在受割礼的时候，乃是在未受割礼的时候。"},
{volumn:        45,chapter:         4,versenumber:        11,verse:"并且他受了割礼的记号，作他未受割礼的时候因信称义的印证，叫他作一切未受割礼而信之人的父，使他们也算为义；"},
{volumn:        45,chapter:         4,versenumber:        12,verse:"又作受割礼之人的父，就是那些不但受割礼，并且按我们的祖宗亚伯拉罕未受割礼而信之踪迹去行的人。"},
{volumn:        45,chapter:         4,versenumber:        13,verse:"因为　神应许亚伯拉罕和他后裔，必得承受世界，不是因律法，乃是因信而得的义。"},
{volumn:        45,chapter:         4,versenumber:        14,verse:"若是属乎律法的人才得为后嗣，信就归于虚空，应许也就废弃了。"},
{volumn:        45,chapter:         4,versenumber:        15,verse:"因为律法是惹动忿怒的（或译：叫人受刑的）；哪里没有律法，那里就没有过犯。"},
{volumn:        45,chapter:         4,versenumber:        16,verse:"所以人得为后嗣是本乎信，因此就属乎恩，叫应许定然归给一切后裔；不但归给那属乎律法的，也归给那效法亚伯拉罕之信的。"},
{volumn:        45,chapter:         4,versenumber:        17,verse:"亚伯拉罕所信的，是那叫死人复活、使无变为有的　神，他在主面前作我们世人的父。如经上所记：“我已经立你作多国的父。”"},
{volumn:        45,chapter:         4,versenumber:        18,verse:"他在无可指望的时候，因信仍有指望，就得以作多国的父，正如先前所说：“你的后裔将要如此。”"},
{volumn:        45,chapter:         4,versenumber:        19,verse:"他将近百岁的时候，虽然想到自己的身体如同已死，撒拉的生育已经断绝，他的信心还是不软弱；"},
{volumn:        45,chapter:         4,versenumber:        20,verse:"并且仰望　神的应许，总没有因不信，心里起疑惑，反倒因信，心里得坚固，将荣耀归给　神，"},
{volumn:        45,chapter:         4,versenumber:        21,verse:"且满心相信　神所应许的必能做成。"},
{volumn:        45,chapter:         4,versenumber:        22,verse:"所以，这就算为他的义。"},
{volumn:        45,chapter:         4,versenumber:        23,verse:"“算为他义”的这句话不是单为他写的，"},
{volumn:        45,chapter:         4,versenumber:        24,verse:"也是为我们将来得算为义之人写的，就是我们这信　神使我们的主耶稣从死里复活的人。"},
{volumn:        45,chapter:         4,versenumber:        25,verse:"耶稣被交给人，是为我们的过犯；复活，是为叫我们称义（或译：耶稣是为我们的过犯交付了，是为我们称义复活了）。"},
{volumn:        45,chapter:         5,versenumber:         1,verse:"我们既因信称义，就藉着我们的主耶稣基督得与　神相和。"},
{volumn:        45,chapter:         5,versenumber:         2,verse:"我们又藉着他，因信得进入现在所站的这恩典中，并且欢欢喜喜盼望　神的荣耀。"},
{volumn:        45,chapter:         5,versenumber:         3,verse:"不但如此，就是在患难中也是欢欢喜喜的；因为知道患难生忍耐，"},
{volumn:        45,chapter:         5,versenumber:         4,verse:"忍耐生老练，老练生盼望；"},
{volumn:        45,chapter:         5,versenumber:         5,verse:"盼望不至于羞耻，因为所赐给我们的圣灵将　神的爱浇灌在我们心里。"},
{volumn:        45,chapter:         5,versenumber:         6,verse:"因我们还软弱的时候，基督就按所定的日期为罪人死。"},
{volumn:        45,chapter:         5,versenumber:         7,verse:"为义人死，是少有的；为仁人死，或者有敢做的。"},
{volumn:        45,chapter:         5,versenumber:         8,verse:"惟有基督在我们还作罪人的时候为我们死，　神的爱就在此向我们显明了。"},
{volumn:        45,chapter:         5,versenumber:         9,verse:"现在我们既靠着他的血称义，就更要藉着他免去　神的忿怒。"},
{volumn:        45,chapter:         5,versenumber:        10,verse:"因为我们作仇敌的时候，且藉着神儿子的死，得与　神和好；既已和好，就更要因他的生得救了。"},
{volumn:        45,chapter:         5,versenumber:        11,verse:"不但如此，我们既藉着我主耶稣基督得与　神和好，也就藉着他以　神为乐。"},
{volumn:        45,chapter:         5,versenumber:        12,verse:"这就如罪是从一人入了世界，死又是从罪来的，于是死就临到众人，因为众人都犯了罪。"},
{volumn:        45,chapter:         5,versenumber:        13,verse:"没有律法之先，罪已经在世上；但没有律法，罪也不算罪。"},
{volumn:        45,chapter:         5,versenumber:        14,verse:"然而从亚当到摩西，死就作了王，连那些不与亚当犯一样罪过的，也在他的权下。亚当乃是那以后要来之人的预像。"},
{volumn:        45,chapter:         5,versenumber:        15,verse:"只是过犯不如恩赐，若因一人的过犯，众人都死了，何况　神的恩典，与那因耶稣基督一人恩典中的赏赐，岂不更加倍地临到众人吗？"},
{volumn:        45,chapter:         5,versenumber:        16,verse:"因一人犯罪就定罪，也不如恩赐，原来审判是由一人而定罪，恩赐乃是由许多过犯而称义。"},
{volumn:        45,chapter:         5,versenumber:        17,verse:"若因一人的过犯，死就因这一人作了王，何况那些受洪恩又蒙所赐之义的，岂不更要因耶稣基督一人在生命中作王吗？"},
{volumn:        45,chapter:         5,versenumber:        18,verse:"如此说来，因一次的过犯，众人都被定罪；照样，因一次的义行，众人也就被称义得生命了。"},
{volumn:        45,chapter:         5,versenumber:        19,verse:"因一人的悖逆，众人成为罪人；照样，因一人的顺从，众人也成为义了。"},
{volumn:        45,chapter:         5,versenumber:        20,verse:"律法本是外添的，叫过犯显多；只是罪在哪里显多，恩典就更显多了。"},
{volumn:        45,chapter:         5,versenumber:        21,verse:"就如罪作王叫人死；照样，恩典也藉着义作王，叫人因我们的主耶稣基督得永生。"},
{volumn:        45,chapter:         6,versenumber:         1,verse:"这样，怎么说呢？我们可以仍在罪中、叫恩典显多吗？"},
{volumn:        45,chapter:         6,versenumber:         2,verse:"断乎不可！我们在罪上死了的人岂可仍在罪中活着呢？"},
{volumn:        45,chapter:         6,versenumber:         3,verse:"岂不知我们这受洗归入基督耶稣的人是受洗归入他的死吗？"},
{volumn:        45,chapter:         6,versenumber:         4,verse:"所以，我们藉着洗礼归入死，和他一同埋葬，原是叫我们一举一动有新生的样式，像基督藉着父的荣耀从死里复活一样。"},
{volumn:        45,chapter:         6,versenumber:         5,verse:"我们若在他死的形状上与他联合，也要在他复活的形状上与他联合；"},
{volumn:        45,chapter:         6,versenumber:         6,verse:"因为知道我们的旧人和他同钉十字架，使罪身灭绝，叫我们不再作罪的奴仆；"},
{volumn:        45,chapter:         6,versenumber:         7,verse:"因为已死的人是脱离了罪。"},
{volumn:        45,chapter:         6,versenumber:         8,verse:"我们若是与基督同死，就信必与他同活。"},
{volumn:        45,chapter:         6,versenumber:         9,verse:"因为知道基督既从死里复活，就不再死，死也不再作他的主了。"},
{volumn:        45,chapter:         6,versenumber:        10,verse:"他死是向罪死了，只有一次；他活是向　神活着。"},
{volumn:        45,chapter:         6,versenumber:        11,verse:"这样，你们向罪也当看自己是死的；向　神在基督耶稣里，却当看自己是活的。"},
{volumn:        45,chapter:         6,versenumber:        12,verse:"所以，不要容罪在你们必死的身上作王，使你们顺从身子的私欲。"},
{volumn:        45,chapter:         6,versenumber:        13,verse:"也不要将你们的肢体献给罪作不义的器具；倒要像从死里复活的人，将自己献给　神，并将肢体作义的器具献给　神。"},
{volumn:        45,chapter:         6,versenumber:        14,verse:"罪必不能作你们的主；因你们不在律法之下，乃在恩典之下。"},
{volumn:        45,chapter:         6,versenumber:        15,verse:"这却怎么样呢？我们在恩典之下，不在律法之下，就可以犯罪吗？断乎不可！"},
{volumn:        45,chapter:         6,versenumber:        16,verse:"岂不晓得你们献上自己作奴仆，顺从谁，就作谁的奴仆吗？或作罪的奴仆，以至于死；或作顺命的奴仆，以至成义。"},
{volumn:        45,chapter:         6,versenumber:        17,verse:"感谢　神！因为你们从前虽然作罪的奴仆，现今却从心里顺服了所传给你们道理的模范。"},
{volumn:        45,chapter:         6,versenumber:        18,verse:"你们既从罪里得了释放，就作了义的奴仆。"},
{volumn:        45,chapter:         6,versenumber:        19,verse:"我因你们肉体的软弱，就照人的常话对你们说。你们从前怎样将肢体献给不洁不法作奴仆，以至于不法；现今也要照样将肢体献给义作奴仆，以至于成圣。"},
{volumn:        45,chapter:         6,versenumber:        20,verse:"因为你们作罪之奴仆的时候，就不被义约束了。"},
{volumn:        45,chapter:         6,versenumber:        21,verse:"你们现今所看为羞耻的事，当日有什么果子呢？那些事的结局就是死。"},
{volumn:        45,chapter:         6,versenumber:        22,verse:"但现今，你们既从罪里得了释放，作了　神的奴仆，就有成圣的果子，那结局就是永生。"},
{volumn:        45,chapter:         6,versenumber:        23,verse:"因为罪的工价乃是死；惟有　神的恩赐，在我们的主基督耶稣里，乃是永生。"},
{volumn:        45,chapter:         7,versenumber:         1,verse:"弟兄们，我现在对明白律法的人说，你们岂不晓得律法管人是在活着的时候吗？"},
{volumn:        45,chapter:         7,versenumber:         2,verse:"就如女人有了丈夫，丈夫还活着，就被律法约束；丈夫若死了，就脱离了丈夫的律法。"},
{volumn:        45,chapter:         7,versenumber:         3,verse:"所以丈夫活着，她若归于别人，便叫淫妇；丈夫若死了，她就脱离了丈夫的律法，虽然归于别人，也不是淫妇。"},
{volumn:        45,chapter:         7,versenumber:         4,verse:"我的弟兄们，这样说来，你们藉着基督的身体，在律法上也是死了，叫你们归于别人，就是归于那从死里复活的，叫我们结果子给　神。"},
{volumn:        45,chapter:         7,versenumber:         5,verse:"因为我们属肉体的时候，那因律法而生的恶欲就在我们肢体中发动，以致结成死亡的果子。"},
{volumn:        45,chapter:         7,versenumber:         6,verse:"但我们既然在捆我们的律法上死了，现今就脱离了律法，叫我们服侍主，要按着心灵（心灵：或译圣灵）的新样，不按着仪文的旧样。"},
{volumn:        45,chapter:         7,versenumber:         7,verse:"这样，我们可说什么呢？律法是罪吗？断乎不是！只是非因律法，我就不知何为罪。非律法说“不可起贪心”，我就不知何为贪心。"},
{volumn:        45,chapter:         7,versenumber:         8,verse:"然而，罪趁着机会，就藉着诫命叫诸般的贪心在我里头发动；因为没有律法，罪是死的。"},
{volumn:        45,chapter:         7,versenumber:         9,verse:"我以前没有律法是活着的；但是诫命来到，罪又活了，我就死了。"},
{volumn:        45,chapter:         7,versenumber:        10,verse:"那本来叫人活的诫命，反倒叫我死；"},
{volumn:        45,chapter:         7,versenumber:        11,verse:"因为罪趁着机会，就藉着诫命引诱我，并且杀了我。"},
{volumn:        45,chapter:         7,versenumber:        12,verse:"这样看来，律法是圣洁的，诫命也是圣洁、公义、良善的。"},
{volumn:        45,chapter:         7,versenumber:        13,verse:"既然如此，那良善的是叫我死吗？断乎不是！叫我死的乃是罪。但罪藉着那良善的叫我死，就显出真是罪，叫罪因着诫命更显出是恶极了。"},
{volumn:        45,chapter:         7,versenumber:        14,verse:"我们原晓得律法是属乎灵的，但我是属乎肉体的，是已经卖给罪了。"},
{volumn:        45,chapter:         7,versenumber:        15,verse:"因为我所做的，我自己不明白；我所愿意的，我并不做；我所恨恶的，我倒去做。"},
{volumn:        45,chapter:         7,versenumber:        16,verse:"若我所做的，是我所不愿意的，我就应承律法是善的。"},
{volumn:        45,chapter:         7,versenumber:        17,verse:"既是这样，就不是我做的，乃是住在我里头的罪做的。"},
{volumn:        45,chapter:         7,versenumber:        18,verse:"我也知道，在我里头，就是我肉体之中，没有良善。因为，立志为善由得我，只是行出来由不得我。"},
{volumn:        45,chapter:         7,versenumber:        19,verse:"故此，我所愿意的善，我反不做；我所不愿意的恶，我倒去做。"},
{volumn:        45,chapter:         7,versenumber:        20,verse:"若我去做所不愿意做的，就不是我做的，乃是住在我里头的罪做的。"},
{volumn:        45,chapter:         7,versenumber:        21,verse:"我觉得有个律，就是我愿意为善的时候，便有恶与我同在。"},
{volumn:        45,chapter:         7,versenumber:        22,verse:"因为按着我里面的意思（原文是人），我是喜欢　神的律；"},
{volumn:        45,chapter:         7,versenumber:        23,verse:"但我觉得肢体中另有个律和我心中的律交战，把我掳去，叫我附从那肢体中犯罪的律。"},
{volumn:        45,chapter:         7,versenumber:        24,verse:"我真是苦啊！谁能救我脱离这取死的身体呢？"},
{volumn:        45,chapter:         7,versenumber:        25,verse:"感谢　神，靠着我们的主耶稣基督就能脱离了。这样看来，我以内心顺服　神的律，我肉体却顺服罪的律了。"},
{volumn:        45,chapter:         8,versenumber:         1,verse:"如今，那些在基督耶稣里的就不定罪了。"},
{volumn:        45,chapter:         8,versenumber:         2,verse:"因为赐生命圣灵的律，在基督耶稣里释放了我，使我脱离罪和死的律了。"},
{volumn:        45,chapter:         8,versenumber:         3,verse:"律法既因肉体软弱，有所不能行的，　神就差遣自己的儿子，成为罪身的形状，作了赎罪祭，在肉体中定了罪案，"},
{volumn:        45,chapter:         8,versenumber:         4,verse:"使律法的义成就在我们这不随从肉体、只随从圣灵的人身上。"},
{volumn:        45,chapter:         8,versenumber:         5,verse:"因为，随从肉体的人体贴肉体的事；随从圣灵的人体贴圣灵的事。"},
{volumn:        45,chapter:         8,versenumber:         6,verse:"体贴肉体的，就是死；体贴圣灵的，乃是生命、平安。"},
{volumn:        45,chapter:         8,versenumber:         7,verse:"原来体贴肉体的，就是与　神为仇；因为不服　神的律法，也是不能服。"},
{volumn:        45,chapter:         8,versenumber:         8,verse:"而且属肉体的人不能得　神的喜欢。"},
{volumn:        45,chapter:         8,versenumber:         9,verse:"如果　神的灵住在你们心里，你们就不属肉体，乃属圣灵了。人若没有基督的灵，就不是属基督的。"},
{volumn:        45,chapter:         8,versenumber:        10,verse:"基督若在你们心里，身体就因罪而死，心灵却因义而活。"},
{volumn:        45,chapter:         8,versenumber:        11,verse:"然而，叫耶稣从死里复活者的灵若住在你们心里，那叫基督耶稣从死里复活的，也必藉着住在你们心里的圣灵，使你们必死的身体又活过来。"},
{volumn:        45,chapter:         8,versenumber:        12,verse:"弟兄们，这样看来，我们并不是欠肉体的债去顺从肉体活着。"},
{volumn:        45,chapter:         8,versenumber:        13,verse:"你们若顺从肉体活着，必要死；若靠着圣灵治死身体的恶行，必要活着。"},
{volumn:        45,chapter:         8,versenumber:        14,verse:"因为凡被　神的灵引导的，都是　神的儿子。"},
{volumn:        45,chapter:         8,versenumber:        15,verse:"你们所受的，不是奴仆的心，仍旧害怕；所受的，乃是儿子的心，因此我们呼叫：“阿爸！父！”"},
{volumn:        45,chapter:         8,versenumber:        16,verse:"圣灵与我们的心同证我们是　神的儿女；"},
{volumn:        45,chapter:         8,versenumber:        17,verse:"既是儿女，便是后嗣，就是　神的后嗣，和基督同作后嗣。如果我们和他一同受苦，也必和他一同得荣耀。"},
{volumn:        45,chapter:         8,versenumber:        18,verse:"我想，现在的苦楚若比起将来要显于我们的荣耀就不足介意了。"},
{volumn:        45,chapter:         8,versenumber:        19,verse:"受造之物切望等候　神的众子显出来。"},
{volumn:        45,chapter:         8,versenumber:        20,verse:"因为受造之物服在虚空之下，不是自己愿意，乃是因那叫他如此的。"},
{volumn:        45,chapter:         8,versenumber:        21,verse:"但受造之物仍然指望脱离败坏的辖制，得享（享：原文是入）　神儿女自由的荣耀。"},
{volumn:        45,chapter:         8,versenumber:        22,verse:"我们知道，一切受造之物一同叹息，劳苦，直到如今。"},
{volumn:        45,chapter:         8,versenumber:        23,verse:"不但如此，就是我们这有圣灵初结果子的，也是自己心里叹息，等候得着儿子的名分，乃是我们的身体得赎。"},
{volumn:        45,chapter:         8,versenumber:        24,verse:"我们得救是在乎盼望；只是所见的盼望不是盼望，谁还盼望他所见的呢（有古卷：人所看见的何必再盼望呢）？"},
{volumn:        45,chapter:         8,versenumber:        25,verse:"但我们若盼望那所不见的，就必忍耐等候。"},
{volumn:        45,chapter:         8,versenumber:        26,verse:"况且，我们的软弱有圣灵帮助；我们本不晓得当怎样祷告，只是圣灵亲自用说不出来的叹息替我们祷告。"},
{volumn:        45,chapter:         8,versenumber:        27,verse:"鉴察人心的，晓得圣灵的意思，因为圣灵照着　神的旨意替圣徒祈求。"},
{volumn:        45,chapter:         8,versenumber:        28,verse:"我们晓得万事都互相效力，叫爱　神的人得益处，就是按他旨意被召的人。"},
{volumn:        45,chapter:         8,versenumber:        29,verse:"因为他预先所知道的人，就预先定下效法他儿子的模样，使他儿子在许多弟兄中作长子。"},
{volumn:        45,chapter:         8,versenumber:        30,verse:"预先所定下的人又召他们来；所召来的人又称他们为义；所称为义的人又叫他们得荣耀。"},
{volumn:        45,chapter:         8,versenumber:        31,verse:"既是这样，还有什么说的呢？　神若帮助我们，谁能敌挡我们呢？"},
{volumn:        45,chapter:         8,versenumber:        32,verse:"神既不爱惜自己的儿子，为我们众人舍了，岂不也把万物和他一同白白地赐给我们吗？"},
{volumn:        45,chapter:         8,versenumber:        33,verse:"谁能控告　神所拣选的人呢？有　神称他们为义了（或译：是称他们为义的　神吗）。"},
{volumn:        45,chapter:         8,versenumber:        34,verse:"谁能定他们的罪呢？有基督耶稣已经死了，而且从死里复活，现今在　神的右边，也替我们祈求（有基督......或译：是已经死了，而且从死里复活，现今在　神的右边，也替我们祈求的基督耶稣吗）。"},
{volumn:        45,chapter:         8,versenumber:        35,verse:"谁能使我们与基督的爱隔绝呢？难道是患难吗？是困苦吗？是逼迫吗？是饥饿吗？是赤身露体吗？是危险吗？是刀剑吗？"},
{volumn:        45,chapter:         8,versenumber:        36,verse:"如经上所记：“我们为你的缘故终日被杀；人看我们如将宰的羊。”"},
{volumn:        45,chapter:         8,versenumber:        37,verse:"然而，靠着爱我们的主，在这一切的事上已经得胜有余了。"},
{volumn:        45,chapter:         8,versenumber:        38,verse:"因为我深信无论是死，是生，是天使，是掌权的，是有能的，是现在的事，是将来的事，"},
{volumn:        45,chapter:         8,versenumber:        39,verse:"是高处的，是低处的，是别的受造之物，都不能叫我们与　神的爱隔绝；这爱是在我们的主基督耶稣里的。"},
{volumn:        45,chapter:         9,versenumber:         1,verse:"我在基督里说真话，并不谎言，有我良心被圣灵感动，给我作见证："},
{volumn:        45,chapter:         9,versenumber:         2,verse:"我是大有忧愁，心里时常伤痛；"},
{volumn:        45,chapter:         9,versenumber:         3,verse:"为我弟兄，我骨肉之亲，就是自己被咒诅，与基督分离，我也愿意。"},
{volumn:        45,chapter:         9,versenumber:         4,verse:"他们是以色列人；那儿子的名分、荣耀、诸约、律法、礼仪、应许都是他们的。"},
{volumn:        45,chapter:         9,versenumber:         5,verse:"列祖就是他们的祖宗；按肉体说，基督也是从他们出来的。他是在万有之上，永远可称颂的　神。阿们！"},
{volumn:        45,chapter:         9,versenumber:         6,verse:"这不是说　神的话落了空。因为从以色列生的不都是以色列人，"},
{volumn:        45,chapter:         9,versenumber:         7,verse:"也不因为是亚伯拉罕的后裔就都作他的儿女；惟独“从以撒生的才要称为你的后裔。”"},
{volumn:        45,chapter:         9,versenumber:         8,verse:"这就是说，肉身所生的儿女不是　神的儿女，惟独那应许的儿女才算是后裔。"},
{volumn:        45,chapter:         9,versenumber:         9,verse:"因为所应许的话是这样说：“到明年这时候我要来，撒拉必生一个儿子。”"},
{volumn:        45,chapter:         9,versenumber:        10,verse:"不但如此，还有利百加，既从一个人，就是从我们的祖宗以撒怀了孕，（"},
{volumn:        45,chapter:         9,versenumber:        11,verse:"双子还没有生下来，善恶还没有做出来，只因要显明　神拣选人的旨意，不在乎人的行为，乃在乎召人的主。）"},
{volumn:        45,chapter:         9,versenumber:        12,verse:"神就对利百加说：“将来，大的要服侍小的。”"},
{volumn:        45,chapter:         9,versenumber:        13,verse:"正如经上所记：雅各是我所爱的；以扫是我所恶的。"},
{volumn:        45,chapter:         9,versenumber:        14,verse:"这样，我们可说什么呢？难道　神有什么不公平吗？断乎没有！"},
{volumn:        45,chapter:         9,versenumber:        15,verse:"因他对摩西说：“我要怜悯谁就怜悯谁，要恩待谁就恩待谁。”"},
{volumn:        45,chapter:         9,versenumber:        16,verse:"据此看来，这不在乎那定意的，也不在乎那奔跑的，只在乎发怜悯的　神。"},
{volumn:        45,chapter:         9,versenumber:        17,verse:"因为经上有话向法老说：“我将你兴起来，特要在你身上彰显我的权能，并要使我的名传遍天下。”"},
{volumn:        45,chapter:         9,versenumber:        18,verse:"如此看来，　神要怜悯谁就怜悯谁，要叫谁刚硬就叫谁刚硬。"},
{volumn:        45,chapter:         9,versenumber:        19,verse:"这样，你必对我说：“他为什么还指责人呢？有谁抗拒他的旨意呢？”"},
{volumn:        45,chapter:         9,versenumber:        20,verse:"你这个人哪，你是谁，竟敢向　神强嘴呢？受造之物岂能对造他的说：“你为什么这样造我呢？”"},
{volumn:        45,chapter:         9,versenumber:        21,verse:"窑匠难道没有权柄从一团泥里拿一块做成贵重的器皿，又拿一块做成卑贱的器皿吗？"},
{volumn:        45,chapter:         9,versenumber:        22,verse:"倘若　神要显明他的忿怒，彰显他的权能，就多多忍耐宽容那可怒、预备遭毁灭的器皿，"},
{volumn:        45,chapter:         9,versenumber:        23,verse:"又要将他丰盛的荣耀彰显在那蒙怜悯、早预备得荣耀的器皿上。"},
{volumn:        45,chapter:         9,versenumber:        24,verse:"这器皿就是我们被　神所召的，不但是从犹太人中，也是从外邦人中。这有什么不可呢？"},
{volumn:        45,chapter:         9,versenumber:        25,verse:"就像　神在何西阿书上说：“那本来不是我子民的，我要称为“我的子民”；本来不是蒙爱的，我要称为“蒙爱的”。"},
{volumn:        45,chapter:         9,versenumber:        26,verse:"从前在什么地方对他们说：你们不是我的子民，将来就在那里称他们为永生　神的儿子”。"},
{volumn:        45,chapter:         9,versenumber:        27,verse:"以赛亚指着以色列人喊着说：“以色列人虽多如海沙，得救的不过是剩下的余数；"},
{volumn:        45,chapter:         9,versenumber:        28,verse:"因为主要在世上施行他的话，叫他的话都成全，速速地完结。”"},
{volumn:        45,chapter:         9,versenumber:        29,verse:"又如以赛亚先前说过：“若不是万军之主给我们存留余种，我们早已像所多玛、蛾摩拉的样子了。”"},
{volumn:        45,chapter:         9,versenumber:        30,verse:"这样，我们可说什么呢？那本来不追求义的外邦人反得了义，就是因信而得的义。"},
{volumn:        45,chapter:         9,versenumber:        31,verse:"但以色列人追求律法的义，反得不着律法的义。"},
{volumn:        45,chapter:         9,versenumber:        32,verse:"这是什么缘故呢？是因为他们不凭着信心求，只凭着行为求；他们正跌在那绊脚石上。"},
{volumn:        45,chapter:         9,versenumber:        33,verse:"就如经上所记：“我在锡安放一块绊脚的石头，跌人的磐石；信靠他的人必不至于羞愧。”"},
{volumn:        45,chapter:        10,versenumber:         1,verse:"弟兄们，我心里所愿的，向　神所求的，是要以色列人得救。"},
{volumn:        45,chapter:        10,versenumber:         2,verse:"我可以证明，他们向　神有热心，但不是按着真知识；"},
{volumn:        45,chapter:        10,versenumber:         3,verse:"因为不知道　神的义，想要立自己的义，就不服　神的义了。"},
{volumn:        45,chapter:        10,versenumber:         4,verse:"律法的总结就是基督，使凡信他的都得着义。"},
{volumn:        45,chapter:        10,versenumber:         5,verse:"摩西写着说：“人若行那出于律法的义，就必因此活着。”"},
{volumn:        45,chapter:        10,versenumber:         6,verse:"惟有出于信心的义如此说：“你不要心里说：谁要升到天上去呢？（就是要领下基督来；）"},
{volumn:        45,chapter:        10,versenumber:         7,verse:"谁要下到阴间去呢？（就是要领基督从死里上来。）”"},
{volumn:        45,chapter:        10,versenumber:         8,verse:"他到底怎么说呢？他说：“这道离你不远，正在你口里，在你心里。”就是我们所传信主的道。"},
{volumn:        45,chapter:        10,versenumber:         9,verse:"你若口里认耶稣为主，心里信　神叫他从死里复活，就必得救。"},
{volumn:        45,chapter:        10,versenumber:        10,verse:"因为，人心里相信就可以称义，口里承认就可以得救。"},
{volumn:        45,chapter:        10,versenumber:        11,verse:"经上说：“凡信他的人必不至于羞愧。”"},
{volumn:        45,chapter:        10,versenumber:        12,verse:"犹太人和希腊人并没有分别，因为众人同有一位主；他也厚待一切求告他的人。"},
{volumn:        45,chapter:        10,versenumber:        13,verse:"因为“凡求告主名的就必得救”。"},
{volumn:        45,chapter:        10,versenumber:        14,verse:"然而，人未曾信他，怎能求他呢？未曾听见他，怎能信他呢？没有传道的，怎能听见呢？"},
{volumn:        45,chapter:        10,versenumber:        15,verse:"若没有奉差遣，怎能传道呢？如经上所记：“报福音、传喜信的人，他们的脚踪何等佳美！”"},
{volumn:        45,chapter:        10,versenumber:        16,verse:"只是人没有都听从福音，因为以赛亚说：“主啊，我们所传的有谁信呢？”"},
{volumn:        45,chapter:        10,versenumber:        17,verse:"可见，信道是从听道来的，听道是从基督的话来的。"},
{volumn:        45,chapter:        10,versenumber:        18,verse:"但我说，人没有听见吗？诚然听见了。“他们的声音传遍天下；他们的言语传到地极。”"},
{volumn:        45,chapter:        10,versenumber:        19,verse:"我再说，以色列人不知道吗？先有摩西说：“我要用那不成子民的，惹动你们的愤恨；我要用那无知的民，触动你们的怒气。”"},
{volumn:        45,chapter:        10,versenumber:        20,verse:"又有以赛亚放胆说：“没有寻找我的，我叫他们遇见；没有访问我的，我向他们显现。”"},
{volumn:        45,chapter:        10,versenumber:        21,verse:"至于以色列人，他说：“我整天伸手招呼那悖逆顶嘴的百姓。”"},
{volumn:        45,chapter:        11,versenumber:         1,verse:"我且说，　神弃绝了他的百姓吗？断乎没有！因为我也是以色列人，亚伯拉罕的后裔，属便雅悯支派的。"},
{volumn:        45,chapter:        11,versenumber:         2,verse:"神并没有弃绝他预先所知道的百姓。你们岂不晓得经上论到以利亚是怎么说的呢？他在　神面前怎样控告以色列人说："},
{volumn:        45,chapter:        11,versenumber:         3,verse:"“主啊，他们杀了你的先知，拆了你的祭坛，只剩下我一个人；他们还要寻索我的命。”"},
{volumn:        45,chapter:        11,versenumber:         4,verse:"神的回话是怎么说的呢？他说：“我为自己留下七千人，是未曾向巴力屈膝的。”"},
{volumn:        45,chapter:        11,versenumber:         5,verse:"如今也是这样，照着拣选的恩典，还有所留的余数。"},
{volumn:        45,chapter:        11,versenumber:         6,verse:"既是出于恩典，就不在乎行为；不然，恩典就不是恩典了。"},
{volumn:        45,chapter:        11,versenumber:         7,verse:"这是怎么样呢？以色列人所求的，他们没有得着。惟有蒙拣选的人得着了；其余的就成了顽梗不化的。"},
{volumn:        45,chapter:        11,versenumber:         8,verse:"如经上所记：“　神给他们昏迷的心，眼睛不能看见，耳朵不能听见，直到今日。”"},
{volumn:        45,chapter:        11,versenumber:         9,verse:"大卫也说：“愿他们的筵席变为网罗，变为机槛，变为绊脚石，作他们的报应。"},
{volumn:        45,chapter:        11,versenumber:        10,verse:"愿他们的眼睛昏蒙，不得看见；愿你时常弯下他们的腰。”"},
{volumn:        45,chapter:        11,versenumber:        11,verse:"我且说，他们失脚是要他们跌倒吗？断乎不是！反倒因他们的过失，救恩便临到外邦人，要激动他们发愤。"},
{volumn:        45,chapter:        11,versenumber:        12,verse:"若他们的过失为天下的富足，他们的缺乏为外邦人的富足，何况他们的丰满呢？"},
{volumn:        45,chapter:        11,versenumber:        13,verse:"我对你们外邦人说这话；因我是外邦人的使徒，所以敬重（原文是荣耀）我的职分，"},
{volumn:        45,chapter:        11,versenumber:        14,verse:"或者可以激动我骨肉之亲发愤，好救他们一些人。"},
{volumn:        45,chapter:        11,versenumber:        15,verse:"若他们被丢弃，天下就得与　神和好，他们被收纳，岂不是死而复生吗？"},
{volumn:        45,chapter:        11,versenumber:        16,verse:"所献的新面若是圣洁，全团也就圣洁了；树根若是圣洁，树枝也就圣洁了。"},
{volumn:        45,chapter:        11,versenumber:        17,verse:"若有几根枝子被折下来，你这野橄榄得接在其中，一同得着橄榄根的肥汁，"},
{volumn:        45,chapter:        11,versenumber:        18,verse:"你就不可向旧枝子夸口；若是夸口，当知道不是你托着根，乃是根托着你。"},
{volumn:        45,chapter:        11,versenumber:        19,verse:"你若说，那枝子被折下来是特为叫我接上。"},
{volumn:        45,chapter:        11,versenumber:        20,verse:"不错！他们因为不信，所以被折下来；你因为信，所以立得住；你不可自高，反要惧怕。"},
{volumn:        45,chapter:        11,versenumber:        21,verse:"神既不爱惜原来的枝子，也必不爱惜你。"},
{volumn:        45,chapter:        11,versenumber:        22,verse:"可见，　神的恩慈和严厉向那跌倒的人是严厉的，向你是有恩慈的，只要你长久在他的恩慈里；不然，你也要被砍下来。"},
{volumn:        45,chapter:        11,versenumber:        23,verse:"而且他们若不是长久不信，仍要被接上，因为　神能够把他们重新接上。"},
{volumn:        45,chapter:        11,versenumber:        24,verse:"你是从那天生的野橄榄上砍下来的，尚且逆着性得接在好橄榄上，何况这本树的枝子，要接在本树上呢！"},
{volumn:        45,chapter:        11,versenumber:        25,verse:"弟兄们，我不愿意你们不知道这奥秘（恐怕你们自以为聪明），就是以色列人有几分是硬心的，等到外邦人的数目添满了，"},
{volumn:        45,chapter:        11,versenumber:        26,verse:"于是以色列全家都要得救。如经上所记：“必有一位救主从锡安出来，要消除雅各家的一切罪恶。”"},
{volumn:        45,chapter:        11,versenumber:        27,verse:"又说：“我除去他们罪的时候，这就是我与他们所立的约。”"},
{volumn:        45,chapter:        11,versenumber:        28,verse:"就着福音说，他们为你们的缘故是仇敌；就着拣选说，他们为列祖的缘故是蒙爱的。"},
{volumn:        45,chapter:        11,versenumber:        29,verse:"因为　神的恩赐和选召是没有后悔的。"},
{volumn:        45,chapter:        11,versenumber:        30,verse:"你们从前不顺服　神，如今因他们的不顺服，你们倒蒙了怜恤。"},
{volumn:        45,chapter:        11,versenumber:        31,verse:"这样，他们也是不顺服，叫他们因着施给你们的怜恤，现在也就蒙怜恤。"},
{volumn:        45,chapter:        11,versenumber:        32,verse:"因为　神将众人都圈在不顺服之中，特意要怜恤众人。"},
{volumn:        45,chapter:        11,versenumber:        33,verse:"深哉，　神丰富的智慧和知识！他的判断何其难测！他的踪迹何其难寻！"},
{volumn:        45,chapter:        11,versenumber:        34,verse:"谁知道主的心？谁作过他的谋士呢？"},
{volumn:        45,chapter:        11,versenumber:        35,verse:"谁是先给了他，使他后来偿还呢？"},
{volumn:        45,chapter:        11,versenumber:        36,verse:"因为万有都是本于他，倚靠他，归于他。愿荣耀归给他，直到永远。阿们！"},
{volumn:        45,chapter:        12,versenumber:         1,verse:"所以，弟兄们，我以　神的慈悲劝你们，将身体献上，当作活祭，是圣洁的，是　神所喜悦的；你们如此侍奉乃是理所当然的。"},
{volumn:        45,chapter:        12,versenumber:         2,verse:"不要效法这个世界，只要心意更新而变化，叫你们察验何为　神的善良、纯全、可喜悦的旨意。"},
{volumn:        45,chapter:        12,versenumber:         3,verse:"我凭着所赐我的恩对你们各人说：不要看自己过于所当看的；要照着　神所分给各人信心的大小，看得合乎中道。"},
{volumn:        45,chapter:        12,versenumber:         4,verse:"正如我们一个身子上有好些肢体，肢体也不都是一样的用处。"},
{volumn:        45,chapter:        12,versenumber:         5,verse:"我们这许多人，在基督里成为一身，互相联络作肢体，也是如此。"},
{volumn:        45,chapter:        12,versenumber:         6,verse:"按我们所得的恩赐，各有不同。或说预言，就当照着信心的程度说预言；"},
{volumn:        45,chapter:        12,versenumber:         7,verse:"或作执事，就当专一执事；或作教导的，就当专一教导；"},
{volumn:        45,chapter:        12,versenumber:         8,verse:"或作劝化的，就当专一劝化；施舍的，就当诚实；治理的，就当殷勤；怜悯人的，就当甘心。"},
{volumn:        45,chapter:        12,versenumber:         9,verse:"爱人不可虚假。恶，要厌恶；善，要亲近。"},
{volumn:        45,chapter:        12,versenumber:        10,verse:"爱弟兄，要彼此亲热；恭敬人，要彼此推让。"},
{volumn:        45,chapter:        12,versenumber:        11,verse:"殷勤，不可懒惰；要心里火热，常常服侍主。"},
{volumn:        45,chapter:        12,versenumber:        12,verse:"在指望中要喜乐；在患难中要忍耐；祷告要恒切。"},
{volumn:        45,chapter:        12,versenumber:        13,verse:"圣徒缺乏，要帮补；客，要一味地款待。"},
{volumn:        45,chapter:        12,versenumber:        14,verse:"逼迫你们的，要给他们祝福；只要祝福，不可咒诅。"},
{volumn:        45,chapter:        12,versenumber:        15,verse:"与喜乐的人要同乐；与哀哭的人要同哭。"},
{volumn:        45,chapter:        12,versenumber:        16,verse:"要彼此同心；不要志气高大，倒要俯就卑微的人（人：或译事）。不要自以为聪明。"},
{volumn:        45,chapter:        12,versenumber:        17,verse:"不要以恶报恶；众人以为美的事要留心去做。"},
{volumn:        45,chapter:        12,versenumber:        18,verse:"若是能行，总要尽力与众人和睦。"},
{volumn:        45,chapter:        12,versenumber:        19,verse:"亲爱的弟兄，不要自己伸冤，宁可让步，听凭主怒（或译：让人发怒）；因为经上记着：“主说：‘伸冤在我，我必报应。’”"},
{volumn:        45,chapter:        12,versenumber:        20,verse:"所以，“你的仇敌若饿了，就给他吃，若渴了，就给他喝；因为你这样行就是把炭火堆在他的头上。”"},
{volumn:        45,chapter:        12,versenumber:        21,verse:"你不可为恶所胜，反要以善胜恶。"},
{volumn:        45,chapter:        13,versenumber:         1,verse:"在上有权柄的，人人当顺服他，因为没有权柄不是出于　神的。凡掌权的都是　神所命的。"},
{volumn:        45,chapter:        13,versenumber:         2,verse:"所以，抗拒掌权的就是抗拒　神的命；抗拒的必自取刑罚。"},
{volumn:        45,chapter:        13,versenumber:         3,verse:"作官的原不是叫行善的惧怕，乃是叫作恶的惧怕。你愿意不惧怕掌权的吗？你只要行善，就可得他的称赞；"},
{volumn:        45,chapter:        13,versenumber:         4,verse:"因为他是　神的用人，是与你有益的。你若作恶，却当惧怕，因为他不是空空地佩剑；他是　神的用人，是伸冤的，刑罚那作恶的。"},
{volumn:        45,chapter:        13,versenumber:         5,verse:"所以，你们必须顺服，不但是因为刑罚，也是因为良心。"},
{volumn:        45,chapter:        13,versenumber:         6,verse:"你们纳粮，也为这个缘故；因他们是　神的差役，常常特管这事。"},
{volumn:        45,chapter:        13,versenumber:         7,verse:"凡人所当得的，就给他。当得粮的，给他纳粮；当得税的，给他上税；当惧怕的，惧怕他；当恭敬的，恭敬他。"},
{volumn:        45,chapter:        13,versenumber:         8,verse:"凡事都不可亏欠人，惟有彼此相爱，要常以为亏欠，因为爱人的就完全了律法。"},
{volumn:        45,chapter:        13,versenumber:         9,verse:"像那不可奸淫，不可杀人，不可偷盗，不可贪婪，或有别的诫命，都包在爱人如己这一句话之内了。"},
{volumn:        45,chapter:        13,versenumber:        10,verse:"爱是不加害与人的，所以爱就完全了律法。"},
{volumn:        45,chapter:        13,versenumber:        11,verse:"再者，你们晓得，现今就是该趁早睡醒的时候；因为我们得救，现今比初信的时候更近了。"},
{volumn:        45,chapter:        13,versenumber:        12,verse:"黑夜已深，白昼将近。我们就当脱去暗昧的行为，带上光明的兵器。"},
{volumn:        45,chapter:        13,versenumber:        13,verse:"行事为人要端正，好像行在白昼。不可荒宴醉酒；不可好色邪荡；不可争竞嫉妒。"},
{volumn:        45,chapter:        13,versenumber:        14,verse:"总要披戴主耶稣基督，不要为肉体安排，去放纵私欲。"},
{volumn:        45,chapter:        14,versenumber:         1,verse:"信心软弱的，你们要接纳，但不要辩论所疑惑的事。"},
{volumn:        45,chapter:        14,versenumber:         2,verse:"有人信百物都可吃；但那软弱的，只吃蔬菜。"},
{volumn:        45,chapter:        14,versenumber:         3,verse:"吃的人不可轻看不吃的人；不吃的人不可论断吃的人；因为　神已经收纳他了。"},
{volumn:        45,chapter:        14,versenumber:         4,verse:"你是谁，竟论断别人的仆人呢？他或站住或跌倒，自有他的主人在；而且他也必要站住，因为主能使他站住。"},
{volumn:        45,chapter:        14,versenumber:         5,verse:"有人看这日比那日强；有人看日日都是一样。只是各人心里要意见坚定。"},
{volumn:        45,chapter:        14,versenumber:         6,verse:"守日的人是为主守的。吃的人是为主吃的，因他感谢　神；不吃的人是为主不吃的，也感谢　神。"},
{volumn:        45,chapter:        14,versenumber:         7,verse:"我们没有一个人为自己活，也没有一个人为自己死。"},
{volumn:        45,chapter:        14,versenumber:         8,verse:"我们若活着，是为主而活；若死了，是为主而死。所以，我们或活或死总是主的人。"},
{volumn:        45,chapter:        14,versenumber:         9,verse:"因此，基督死了，又活了，为要作死人并活人的主。"},
{volumn:        45,chapter:        14,versenumber:        10,verse:"你这个人，为什么论断弟兄呢？又为什么轻看弟兄呢？因我们都要站在　神的台前。"},
{volumn:        45,chapter:        14,versenumber:        11,verse:"经上写着：“主说：‘我凭着我的永生起誓：万膝必向我跪拜；万口必向我承认。’”"},
{volumn:        45,chapter:        14,versenumber:        12,verse:"这样看来，我们各人必要将自己的事在　神面前说明。"},
{volumn:        45,chapter:        14,versenumber:        13,verse:"所以，我们不可再彼此论断，宁可定意谁也不给弟兄放下绊脚跌人之物。"},
{volumn:        45,chapter:        14,versenumber:        14,verse:"我凭着主耶稣确知深信，凡物本来没有不洁净的；惟独人以为不洁净的，在他就不洁净了。"},
{volumn:        45,chapter:        14,versenumber:        15,verse:"你若因食物叫弟兄忧愁，就不是按着爱人的道理行。基督已经替他死，你不可因你的食物叫他败坏。"},
{volumn:        45,chapter:        14,versenumber:        16,verse:"不可叫你的善被人毁谤；"},
{volumn:        45,chapter:        14,versenumber:        17,verse:"因为　神的国不在乎吃喝，只在乎公义、和平，并圣灵中的喜乐。"},
{volumn:        45,chapter:        14,versenumber:        18,verse:"在这几样上服侍基督的，就为　神所喜悦，又为人所称许。"},
{volumn:        45,chapter:        14,versenumber:        19,verse:"所以，我们务要追求和睦的事与彼此建立德行的事。"},
{volumn:        45,chapter:        14,versenumber:        20,verse:"不可因食物毁坏　神的工程。凡物固然洁净，但有人因食物叫人跌倒，就是他的罪了。"},
{volumn:        45,chapter:        14,versenumber:        21,verse:"无论是吃肉是喝酒，是什么别的事，叫弟兄跌倒，一概不做才好。"},
{volumn:        45,chapter:        14,versenumber:        22,verse:"你有信心，就当在　神面前守着。人在自己以为可行的事上能不自责，就有福了。"},
{volumn:        45,chapter:        14,versenumber:        23,verse:"若有疑心而吃的，就必有罪，因为他吃不是出于信心。凡不出于信心的都是罪。"},
{volumn:        45,chapter:        15,versenumber:         1,verse:"我们坚固的人应该担代不坚固人的软弱，不求自己的喜悦。"},
{volumn:        45,chapter:        15,versenumber:         2,verse:"我们各人务要叫邻舍喜悦，使他得益处，建立德行。"},
{volumn:        45,chapter:        15,versenumber:         3,verse:"因为基督也不求自己的喜悦，如经上所记：“辱骂你人的辱骂都落在我身上。”"},
{volumn:        45,chapter:        15,versenumber:         4,verse:"从前所写的圣经都是为教训我们写的，叫我们因圣经所生的忍耐和安慰可以得着盼望。"},
{volumn:        45,chapter:        15,versenumber:         5,verse:"但愿赐忍耐安慰的　神叫你们彼此同心，效法基督耶稣，"},
{volumn:        45,chapter:        15,versenumber:         6,verse:"一心一口荣耀　神、我们主耶稣基督的父！"},
{volumn:        45,chapter:        15,versenumber:         7,verse:"所以，你们要彼此接纳，如同基督接纳你们一样，使荣耀归与　神。"},
{volumn:        45,chapter:        15,versenumber:         8,verse:"我说，基督是为　神真理作了受割礼人的执事，要证实所应许列祖的话，"},
{volumn:        45,chapter:        15,versenumber:         9,verse:"并叫外邦人因他的怜悯荣耀　神。如经上所记：“因此，我要在外邦中称赞你，歌颂你的名。”"},
{volumn:        45,chapter:        15,versenumber:        10,verse:"又说：“你们外邦人当与主的百姓一同欢乐。”"},
{volumn:        45,chapter:        15,versenumber:        11,verse:"又说：“外邦啊，你们当赞美主！万民哪，你们都当颂赞他！”"},
{volumn:        45,chapter:        15,versenumber:        12,verse:"又有以赛亚说：“将来有耶西的根，就是那兴起来要治理外邦的；外邦人要仰望他。”"},
{volumn:        45,chapter:        15,versenumber:        13,verse:"但愿使人有盼望的　神，因信将诸般的喜乐、平安充满你们的心，使你们藉着圣灵的能力大有盼望！"},
{volumn:        45,chapter:        15,versenumber:        14,verse:"弟兄们，我自己也深信你们是满有良善，充足了诸般的知识，也能彼此劝戒。"},
{volumn:        45,chapter:        15,versenumber:        15,verse:"但我稍微放胆写信给你们，是要提醒你们的记性，特因　神所给我的恩典，"},
{volumn:        45,chapter:        15,versenumber:        16,verse:"使我为外邦人作基督耶稣的仆役，作　神福音的祭司，叫所献上的外邦人，因着圣灵成为圣洁，可蒙悦纳。"},
{volumn:        45,chapter:        15,versenumber:        17,verse:"所以论到　神的事，我在基督耶稣里有可夸的。"},
{volumn:        45,chapter:        15,versenumber:        18,verse:"除了基督藉我做的那些事，我什么都不敢提，只提他藉我言语作为，用神迹奇事的能力，并圣灵的能力，使外邦人顺服；"},
{volumn:        45,chapter:        15,versenumber:        19,verse:"甚至我从耶路撒冷，直转到以利哩古，到处传了基督的福音。"},
{volumn:        45,chapter:        15,versenumber:        20,verse:"我立了志向，不在基督的名被称过的地方传福音，免得建造在别人的根基上。"},
{volumn:        45,chapter:        15,versenumber:        21,verse:"就如经上所记：“未曾闻知他信息的，将要看见；未曾听过的，将要明白。”"},
{volumn:        45,chapter:        15,versenumber:        22,verse:"我因多次被拦阻，总不得到你们那里去。"},
{volumn:        45,chapter:        15,versenumber:        23,verse:"但如今，在这里再没有可传的地方，而且这好几年，我切心想望到西班牙去的时候，可以到你们那里，"},
{volumn:        45,chapter:        15,versenumber:        24,verse:"盼望从你们那里经过，得见你们，先与你们彼此交往，心里稍微满足，然后蒙你们送行。"},
{volumn:        45,chapter:        15,versenumber:        25,verse:"但现在，我往耶路撒冷去供给圣徒。"},
{volumn:        45,chapter:        15,versenumber:        26,verse:"因为马其顿和亚该亚人乐意凑出捐项给耶路撒冷圣徒中的穷人。"},
{volumn:        45,chapter:        15,versenumber:        27,verse:"这固然是他们乐意的，其实也算是所欠的债；因外邦人既然在他们属灵的好处上有份，就当把养身之物供给他们。"},
{volumn:        45,chapter:        15,versenumber:        28,verse:"等我办完了这事，把这善果向他们交付明白，我就要路过你们那里，往西班牙去。"},
{volumn:        45,chapter:        15,versenumber:        29,verse:"我也晓得，去的时候必带着基督丰盛的恩典而去。"},
{volumn:        45,chapter:        15,versenumber:        30,verse:"弟兄们，我藉着我们主耶稣基督，又藉着圣灵的爱，劝你们与我一同竭力，为我祈求　神，"},
{volumn:        45,chapter:        15,versenumber:        31,verse:"叫我脱离在犹太不顺从的人，也叫我为耶路撒冷所办的捐项可蒙圣徒悦纳，"},
{volumn:        45,chapter:        15,versenumber:        32,verse:"并叫我顺着　神的旨意，欢欢喜喜地到你们那里，与你们同得安息。"},
{volumn:        45,chapter:        15,versenumber:        33,verse:"愿赐平安的　神常和你们众人同在。阿们！"},
{volumn:        45,chapter:        16,versenumber:         1,verse:"我对你们举荐我们的姐妹非比；她是坚革哩教会中的女执事。"},
{volumn:        45,chapter:        16,versenumber:         2,verse:"请你们为主接待她，合乎圣徒的体统。她在何事上要你们帮助，你们就帮助她；因她素来帮助许多人，也帮助了我。"},
{volumn:        45,chapter:        16,versenumber:         3,verse:"问百基拉和亚居拉安。他们在基督耶稣里与我同工，"},
{volumn:        45,chapter:        16,versenumber:         4,verse:"也为我的命将自己的颈项置之度外。不但我感谢他们，就是外邦的众教会也感谢他们。"},
{volumn:        45,chapter:        16,versenumber:         5,verse:"又问在他们家中的教会安。问我所亲爱的以拜尼土安；他在亚细亚是归基督初结的果子。"},
{volumn:        45,chapter:        16,versenumber:         6,verse:"又问马利亚安；她为你们多受劳苦。"},
{volumn:        45,chapter:        16,versenumber:         7,verse:"又问我亲属与我一同坐监的安多尼古和犹尼亚安；他们在使徒中是有名望的，也是比我先在基督里。"},
{volumn:        45,chapter:        16,versenumber:         8,verse:"又问我在主里面所亲爱的暗伯利安。"},
{volumn:        45,chapter:        16,versenumber:         9,verse:"又问在基督里与我们同工的耳巴奴，并我所亲爱的士大古安。"},
{volumn:        45,chapter:        16,versenumber:        10,verse:"又问在基督里经过试验的亚比利安。问亚利多布家里的人安。"},
{volumn:        45,chapter:        16,versenumber:        11,verse:"又问我亲属希罗天安。问拿其数家在主里的人安。"},
{volumn:        45,chapter:        16,versenumber:        12,verse:"又问为主劳苦的土非拿氏和土富撒氏安。问可亲爱为主多受劳苦的彼息氏安。"},
{volumn:        45,chapter:        16,versenumber:        13,verse:"又问在主蒙拣选的鲁孚和他母亲安；他的母亲就是我的母亲。"},
{volumn:        45,chapter:        16,versenumber:        14,verse:"又问亚逊其土、弗勒干、黑米、八罗巴、黑马，并与他们在一处的弟兄们安。"},
{volumn:        45,chapter:        16,versenumber:        15,verse:"又问非罗罗古和犹利亚，尼利亚和他姐妹，同阿林巴并与他们在一处的众圣徒安。"},
{volumn:        45,chapter:        16,versenumber:        16,verse:"你们亲嘴问安，彼此务要圣洁。基督的众教会都问你们安。"},
{volumn:        45,chapter:        16,versenumber:        17,verse:"弟兄们，那些离间你们、叫你们跌倒、背乎所学之道的人，我劝你们要留意躲避他们。"},
{volumn:        45,chapter:        16,versenumber:        18,verse:"因为这样的人不服侍我们的主基督，只服侍自己的肚腹，用花言巧语诱惑那些老实人的心。"},
{volumn:        45,chapter:        16,versenumber:        19,verse:"你们的顺服已经传于众人，所以我为你们欢喜；但我愿意你们在善上聪明，在恶上愚拙。"},
{volumn:        45,chapter:        16,versenumber:        20,verse:"赐平安的　神快要将撒但践踏在你们脚下。愿我主耶稣基督的恩常和你们同在！"},
{volumn:        45,chapter:        16,versenumber:        21,verse:"与我同工的提摩太，和我的亲属路求、耶孙、所西巴德，问你们安。"},
{volumn:        45,chapter:        16,versenumber:        22,verse:"我这代笔写信的德丢，在主里面问你们安。"},
{volumn:        45,chapter:        16,versenumber:        23,verse:"那接待我、也接待全教会的该犹问你们安。"},
{volumn:        45,chapter:        16,versenumber:        24,verse:"城内管银库的以拉都和兄弟括土问你们安。"},
{volumn:        45,chapter:        16,versenumber:        25,verse:"惟有　神能照我所传的福音和所讲的耶稣基督，并照永古隐藏不言的奥秘，坚固你们的心。"},
{volumn:        45,chapter:        16,versenumber:        26,verse:"这奥秘如今显明出来，而且按着永生　神的命，藉众先知的书指示万国的民，使他们信服真道。"},
{volumn:        45,chapter:        16,versenumber:        27,verse:"愿荣耀，因耶稣基督，归与独一全智的　神，直到永远。阿们！"},
{volumn:        46,chapter:         1,versenumber:         1,verse:"奉　神旨意，蒙召作耶稣基督使徒的保罗，同兄弟所提尼，"},
{volumn:        46,chapter:         1,versenumber:         2,verse:"写信给在哥林多　神的教会，就是在基督耶稣里成圣、蒙召作圣徒的，以及所有在各处求告我主耶稣基督之名的人。基督是他们的主，也是我们的主。"},
{volumn:        46,chapter:         1,versenumber:         3,verse:"愿恩惠、平安从　神我们的父并主耶稣基督归与你们。"},
{volumn:        46,chapter:         1,versenumber:         4,verse:"我常为你们感谢我的　神，因　神在基督耶稣里所赐给你们的恩惠；"},
{volumn:        46,chapter:         1,versenumber:         5,verse:"又因你们在他里面凡事富足，口才、知识都全备，"},
{volumn:        46,chapter:         1,versenumber:         6,verse:"正如我为基督作的见证，在你们心里得以坚固，"},
{volumn:        46,chapter:         1,versenumber:         7,verse:"以致你们在恩赐上没有一样不及人的，等候我们的主耶稣基督显现。"},
{volumn:        46,chapter:         1,versenumber:         8,verse:"他也必坚固你们到底，叫你们在我们主耶稣基督的日子无可责备。"},
{volumn:        46,chapter:         1,versenumber:         9,verse:"神是信实的，你们原是被他所召，好与他儿子我们的主耶稣基督一同得份。"},
{volumn:        46,chapter:         1,versenumber:        10,verse:"弟兄们，我藉我们主耶稣基督的名劝你们都说一样的话。你们中间也不可分党，只要一心一意，彼此相合。"},
{volumn:        46,chapter:         1,versenumber:        11,verse:"因为革来氏家里的人曾对我提起弟兄们来，说你们中间有纷争。"},
{volumn:        46,chapter:         1,versenumber:        12,verse:"我的意思就是你们各人说：“我是属保罗的”；“我是属亚波罗的”；“我是属矶法的”；“我是属基督的”。"},
{volumn:        46,chapter:         1,versenumber:        13,verse:"基督是分开的吗？保罗为你们钉了十字架吗？你们是奉保罗的名受了洗吗？"},
{volumn:        46,chapter:         1,versenumber:        14,verse:"我感谢　神，除了基利司布并该犹以外，我没有给你们一个人施洗，"},
{volumn:        46,chapter:         1,versenumber:        15,verse:"免得有人说，你们是奉我的名受洗。"},
{volumn:        46,chapter:         1,versenumber:        16,verse:"我也给司提反家施过洗，此外给别人施洗没有，我却记不清。"},
{volumn:        46,chapter:         1,versenumber:        17,verse:"基督差遣我，原不是为施洗，乃是为传福音，并不用智慧的言语，免得基督的十字架落了空。"},
{volumn:        46,chapter:         1,versenumber:        18,verse:"因为十字架的道理，在那灭亡的人为愚拙；在我们得救的人，却为　神的大能。"},
{volumn:        46,chapter:         1,versenumber:        19,verse:"就如经上所记：“我要灭绝智慧人的智慧，废弃聪明人的聪明。”"},
{volumn:        46,chapter:         1,versenumber:        20,verse:"智慧人在哪里？文士在哪里？这世上的辩士在哪里？　神岂不是叫这世上的智慧变成愚拙吗？"},
{volumn:        46,chapter:         1,versenumber:        21,verse:"世人凭自己的智慧，既不认识　神，　神就乐意用人所当作愚拙的道理拯救那些信的人；这就是　神的智慧了。"},
{volumn:        46,chapter:         1,versenumber:        22,verse:"犹太人是要神迹，希腊人是求智慧，"},
{volumn:        46,chapter:         1,versenumber:        23,verse:"我们却是传钉十字架的基督，在犹太人为绊脚石，在外邦人为愚拙；"},
{volumn:        46,chapter:         1,versenumber:        24,verse:"但在那蒙召的，无论是犹太人、希腊人，基督总为　神的能力，　神的智慧。"},
{volumn:        46,chapter:         1,versenumber:        25,verse:"因　神的愚拙总比人智慧，　神的软弱总比人强壮。"},
{volumn:        46,chapter:         1,versenumber:        26,verse:"弟兄们哪，可见你们蒙召的，按着肉体有智慧的不多，有能力的不多，有尊贵的也不多。"},
{volumn:        46,chapter:         1,versenumber:        27,verse:"神却拣选了世上愚拙的，叫有智慧的羞愧；又拣选了世上软弱的，叫那强壮的羞愧。"},
{volumn:        46,chapter:         1,versenumber:        28,verse:"神也拣选了世上卑贱的，被人厌恶的，以及那无有的，为要废掉那有的，"},
{volumn:        46,chapter:         1,versenumber:        29,verse:"使一切有血气的，在　神面前一个也不能自夸。"},
{volumn:        46,chapter:         1,versenumber:        30,verse:"但你们得在基督耶稣里是本乎　神，　神又使他成为我们的智慧、公义、圣洁、救赎。"},
{volumn:        46,chapter:         1,versenumber:        31,verse:"如经上所记：“夸口的，当指着主夸口。”"},
{volumn:        46,chapter:         2,versenumber:         1,verse:"弟兄们，从前我到你们那里去，并没有用高言大智对你们宣传　神的奥秘。"},
{volumn:        46,chapter:         2,versenumber:         2,verse:"因为我曾定了主意，在你们中间不知道别的，只知道耶稣基督并他钉十字架。"},
{volumn:        46,chapter:         2,versenumber:         3,verse:"我在你们那里，又软弱，又惧怕又甚战兢。"},
{volumn:        46,chapter:         2,versenumber:         4,verse:"我说的话、讲的道，不是用智慧委婉的言语，乃是用圣灵和大能的明证，"},
{volumn:        46,chapter:         2,versenumber:         5,verse:"叫你们的信不在乎人的智慧，只在乎　神的大能。"},
{volumn:        46,chapter:         2,versenumber:         6,verse:"然而，在完全的人中，我们也讲智慧。但不是这世上的智慧，也不是这世上有权有位、将要败亡之人的智慧。"},
{volumn:        46,chapter:         2,versenumber:         7,verse:"我们讲的，乃是从前所隐藏、　神奥秘的智慧，就是　神在万世以前预定使我们得荣耀的。"},
{volumn:        46,chapter:         2,versenumber:         8,verse:"这智慧世上有权有位的人没有一个知道的，他们若知道，就不把荣耀的主钉在十字架上了。"},
{volumn:        46,chapter:         2,versenumber:         9,verse:"如经上所记：“　神为爱他的人所预备的是眼睛未曾看见，耳朵未曾听见，人心也未曾想到的。”"},
{volumn:        46,chapter:         2,versenumber:        10,verse:"只有神藉着圣灵向我们显明了，因为圣灵参透万事，就是　神深奥的事也参透了。"},
{volumn:        46,chapter:         2,versenumber:        11,verse:"除了在人里头的灵，谁知道人的事？像这样，除了　神的灵，也没有人知道　神的事。"},
{volumn:        46,chapter:         2,versenumber:        12,verse:"我们所领受的，并不是世上的灵，乃是从　神来的灵，叫我们能知道　神开恩赐给我们的事。"},
{volumn:        46,chapter:         2,versenumber:        13,verse:"并且我们讲说这些事，不是用人智慧所指教的言语，乃是用圣灵所指教的言语，将属灵的话解释属灵的事（或译：将属灵的事讲与属灵的人）。"},
{volumn:        46,chapter:         2,versenumber:        14,verse:"然而，属血气的人不领会　神圣灵的事，反倒以为愚拙，并且不能知道，因为这些事惟有属灵的人才能看透。"},
{volumn:        46,chapter:         2,versenumber:        15,verse:"属灵的人能看透万事，却没有一人能看透了他。"},
{volumn:        46,chapter:         2,versenumber:        16,verse:"谁曾知道主的心去教导他呢？但我们是有基督的心了。"},
{volumn:        46,chapter:         3,versenumber:         1,verse:"弟兄们，我从前对你们说话，不能把你们当作属灵的，只得把你们当作属肉体，在基督里为婴孩的。"},
{volumn:        46,chapter:         3,versenumber:         2,verse:"我是用奶喂你们，没有用饭喂你们。那时你们不能吃，就是如今还是不能。"},
{volumn:        46,chapter:         3,versenumber:         3,verse:"你们仍是属肉体的，因为在你们中间有嫉妒、纷争，这岂不是属乎肉体、照着世人的样子行吗？"},
{volumn:        46,chapter:         3,versenumber:         4,verse:"有说：“我是属保罗的”；有说：“我是属亚波罗的。”这岂不是你们和世人一样吗？"},
{volumn:        46,chapter:         3,versenumber:         5,verse:"亚波罗算什么？保罗算什么？无非是执事，照主所赐给他们各人的，引导你们相信。"},
{volumn:        46,chapter:         3,versenumber:         6,verse:"我栽种了，亚波罗浇灌了，惟有　神叫他生长。"},
{volumn:        46,chapter:         3,versenumber:         7,verse:"可见栽种的，算不得什么，浇灌的，也算不得什么；只在那叫他生长的　神。"},
{volumn:        46,chapter:         3,versenumber:         8,verse:"栽种的和浇灌的，都是一样，但将来各人要照自己的工夫得自己的赏赐。"},
{volumn:        46,chapter:         3,versenumber:         9,verse:"因为我们是与　神同工的；你们是　神所耕种的田地，所建造的房屋。"},
{volumn:        46,chapter:         3,versenumber:        10,verse:"我照　神所给我的恩，好像一个聪明的工头，立好了根基，有别人在上面建造；只是各人要谨慎怎样在上面建造。"},
{volumn:        46,chapter:         3,versenumber:        11,verse:"因为那已经立好的根基就是耶稣基督，此外没有人能立别的根基。"},
{volumn:        46,chapter:         3,versenumber:        12,verse:"若有人用金、银、宝石、草木、禾秸在这根基上建造，"},
{volumn:        46,chapter:         3,versenumber:        13,verse:"各人的工程必然显露，因为那日子要将它表明出来，有火发现；这火要试验各人的工程怎样。"},
{volumn:        46,chapter:         3,versenumber:        14,verse:"人在那根基上所建造的工程若存得住，他就要得赏赐。"},
{volumn:        46,chapter:         3,versenumber:        15,verse:"人的工程若被烧了，他就要受亏损，自己却要得救；虽然得救，乃像从火里经过的一样。"},
{volumn:        46,chapter:         3,versenumber:        16,verse:"岂不知你们是　神的殿，　神的灵住在你们里头吗？"},
{volumn:        46,chapter:         3,versenumber:        17,verse:"若有人毁坏　神的殿，　神必要毁坏那人；因为　神的殿是圣的，这殿就是你们。"},
{volumn:        46,chapter:         3,versenumber:        18,verse:"人不可自欺。你们中间若有人在这世界自以为有智慧，倒不如变作愚拙，好成为有智慧的。"},
{volumn:        46,chapter:         3,versenumber:        19,verse:"因这世界的智慧，在　神看是愚拙。如经上记着说：“主叫有智慧的，中了自己的诡计”；"},
{volumn:        46,chapter:         3,versenumber:        20,verse:"又说：“主知道智慧人的意念是虚妄的。”"},
{volumn:        46,chapter:         3,versenumber:        21,verse:"所以无论谁，都不可拿人夸口，因为万有全是你们的。"},
{volumn:        46,chapter:         3,versenumber:        22,verse:"或保罗，或亚波罗，或矶法，或世界，或生，或死，或现今的事，或将来的事，全是你们的；"},
{volumn:        46,chapter:         3,versenumber:        23,verse:"并且你们是属基督的，基督又是属　神的。"},
{volumn:        46,chapter:         4,versenumber:         1,verse:"人应当以我们为基督的执事，为　神奥秘事的管家。"},
{volumn:        46,chapter:         4,versenumber:         2,verse:"所求于管家的，是要他有忠心。"},
{volumn:        46,chapter:         4,versenumber:         3,verse:"我被你们论断，或被别人论断，我都以为极小的事；连我自己也不论断自己。"},
{volumn:        46,chapter:         4,versenumber:         4,verse:"我虽不觉得自己有错，却也不能因此得以称义；但判断我的乃是主。"},
{volumn:        46,chapter:         4,versenumber:         5,verse:"所以，时候未到，什么都不要论断，只等主来，他要照出暗中的隐情，显明人心的意念。那时，各人要从　神那里得着称赞。"},
{volumn:        46,chapter:         4,versenumber:         6,verse:"弟兄们，我为你们的缘故，拿这些事转比自己和亚波罗，叫你们效法我们不可过于圣经所记，免得你们自高自大，贵重这个，轻看那个。"},
{volumn:        46,chapter:         4,versenumber:         7,verse:"使你与人不同的是谁呢？你有什么不是领受的呢？若是领受的，为何自夸，仿佛不是领受的呢？"},
{volumn:        46,chapter:         4,versenumber:         8,verse:"你们已经饱足了！已经丰富了！不用我们，自己就作王了！我愿意你们果真作王，叫我们也得与你们一同作王。"},
{volumn:        46,chapter:         4,versenumber:         9,verse:"我想　神把我们使徒明明列在末后，好像定死罪的囚犯；因为我们成了一台戏，给世人和天使观看。"},
{volumn:        46,chapter:         4,versenumber:        10,verse:"我们为基督的缘故算是愚拙的，你们在基督里倒是聪明的；我们软弱，你们倒强壮；你们有荣耀，我们倒被藐视。"},
{volumn:        46,chapter:         4,versenumber:        11,verse:"直到如今，我们还是又饥又渴，又赤身露体，又挨打，又没有一定的住处，"},
{volumn:        46,chapter:         4,versenumber:        12,verse:"并且劳苦，亲手做工。被人咒骂，我们就祝福；被人逼迫，我们就忍受；"},
{volumn:        46,chapter:         4,versenumber:        13,verse:"被人毁谤，我们就善劝。直到如今，人还把我们看作世界上的污秽，万物中的渣滓。"},
{volumn:        46,chapter:         4,versenumber:        14,verse:"我写这话，不是叫你们羞愧，乃是警戒你们，好像我所亲爱的儿女一样。"},
{volumn:        46,chapter:         4,versenumber:        15,verse:"你们学基督的，师傅虽有一万，为父的却是不多，因我在基督耶稣里用福音生了你们。"},
{volumn:        46,chapter:         4,versenumber:        16,verse:"所以，我求你们效法我。"},
{volumn:        46,chapter:         4,versenumber:        17,verse:"因此我已打发提摩太到你们那里去。他在主里面，是我所亲爱、有忠心的儿子。他必提醒你们，记念我在基督里怎样行事，在各处各教会中怎样教导人。"},
{volumn:        46,chapter:         4,versenumber:        18,verse:"有些人自高自大，以为我不到你们那里去；"},
{volumn:        46,chapter:         4,versenumber:        19,verse:"然而，主若许我，我必快到你们那里去，并且我所要知道的，不是那些自高自大之人的言语，乃是他们的权能。"},
{volumn:        46,chapter:         4,versenumber:        20,verse:"因为　神的国不在乎言语，乃在乎权能。"},
{volumn:        46,chapter:         4,versenumber:        21,verse:"你们愿意怎么样呢？是愿意我带着刑杖到你们那里去呢？还是要我存慈爱温柔的心呢？"},
{volumn:        46,chapter:         5,versenumber:         1,verse:"风闻在你们中间有淫乱的事。这样的淫乱连外邦人中也没有，就是有人收了他的继母。"},
{volumn:        46,chapter:         5,versenumber:         2,verse:"你们还是自高自大，并不哀痛，把行这事的人从你们中间赶出去。"},
{volumn:        46,chapter:         5,versenumber:         3,verse:"我身子虽不在你们那里，心却在你们那里，好像我亲自与你们同在，已经判断了行这事的人。"},
{volumn:        46,chapter:         5,versenumber:         4,verse:"就是你们聚会的时候，我的心也同在。奉我们主耶稣的名，并用我们主耶稣的权能，"},
{volumn:        46,chapter:         5,versenumber:         5,verse:"要把这样的人交给撒但，败坏他的肉体，使他的灵魂在主耶稣的日子可以得救。"},
{volumn:        46,chapter:         5,versenumber:         6,verse:"你们这自夸是不好的。岂不知一点面酵能使全团发起来吗？"},
{volumn:        46,chapter:         5,versenumber:         7,verse:"你们既是无酵的面，应当把旧酵除净，好使你们成为新团；因为我们逾越节的羔羊基督已经被杀献祭了。"},
{volumn:        46,chapter:         5,versenumber:         8,verse:"所以，我们守这节不可用旧酵，也不可用恶毒（或译：阴毒）、邪恶的酵，只用诚实真正的无酵饼。"},
{volumn:        46,chapter:         5,versenumber:         9,verse:"我先前写信给你们说，不可与淫乱的人相交。"},
{volumn:        46,chapter:         5,versenumber:        10,verse:"此话不是指这世上一概行淫乱的，或贪婪的，勒索的，或拜偶像的；若是这样，你们除非离开世界方可。"},
{volumn:        46,chapter:         5,versenumber:        11,verse:"但如今我写信给你们说，若有称为弟兄是行淫乱的，或贪婪的，或拜偶像的，或辱骂的，或醉酒的，或勒索的，这样的人不可与他相交，就是与他吃饭都不可。"},
{volumn:        46,chapter:         5,versenumber:        12,verse:"因为审判教外的人与我何干？教内的人岂不是你们审判的吗？"},
{volumn:        46,chapter:         5,versenumber:        13,verse:"至于外人有　神审判他们。你们应当把那恶人从你们中间赶出去。"},
{volumn:        46,chapter:         6,versenumber:         1,verse:"你们中间有彼此相争的事，怎敢在不义的人面前求审，不在圣徒面前求审呢？"},
{volumn:        46,chapter:         6,versenumber:         2,verse:"岂不知圣徒要审判世界吗？若世界为你们所审，难道你们不配审判这最小的事吗？"},
{volumn:        46,chapter:         6,versenumber:         3,verse:"岂不知我们要审判天使吗？何况今生的事呢？"},
{volumn:        46,chapter:         6,versenumber:         4,verse:"既是这样，你们若有今生的事当审判，是派教会所轻看的人审判吗？"},
{volumn:        46,chapter:         6,versenumber:         5,verse:"我说这话是要叫你们羞耻。难道你们中间没有一个智慧人能审断弟兄们的事吗？"},
{volumn:        46,chapter:         6,versenumber:         6,verse:"你们竟是弟兄与弟兄告状，而且告在不信主的人面前。"},
{volumn:        46,chapter:         6,versenumber:         7,verse:"你们彼此告状，这已经是你们的大错了。为什么不情愿受欺呢？为什么不情愿吃亏呢？"},
{volumn:        46,chapter:         6,versenumber:         8,verse:"你们倒是欺压人、亏负人，况且所欺压所亏负的就是弟兄。"},
{volumn:        46,chapter:         6,versenumber:         9,verse:"你们岂不知不义的人不能承受　神的国吗？不要自欺！无论是淫乱的、拜偶像的、奸淫的、作娈童的、亲男色的、"},
{volumn:        46,chapter:         6,versenumber:        10,verse:"偷窃的、贪婪的、醉酒的、辱骂的、勒索的，都不能承受　神的国。"},
{volumn:        46,chapter:         6,versenumber:        11,verse:"你们中间也有人从前是这样；但如今你们奉主耶稣基督的名，并藉着我们　神的灵，已经洗净，成圣，称义了。"},
{volumn:        46,chapter:         6,versenumber:        12,verse:"凡事我都可行，但不都有益处。凡事我都可行，但无论哪一件，我总不受它的辖制。"},
{volumn:        46,chapter:         6,versenumber:        13,verse:"食物是为肚腹，肚腹是为食物；但　神要叫这两样都废坏。身子不是为淫乱，乃是为主；主也是为身子。"},
{volumn:        46,chapter:         6,versenumber:        14,verse:"并且　神已经叫主复活，也要用自己的能力叫我们复活。"},
{volumn:        46,chapter:         6,versenumber:        15,verse:"岂不知你们的身子是基督的肢体吗？我可以将基督的肢体作为娼妓的肢体吗？断乎不可！"},
{volumn:        46,chapter:         6,versenumber:        16,verse:"岂不知与娼妓联合的，便是与她成为一体吗？因为主说：“二人要成为一体。”"},
{volumn:        46,chapter:         6,versenumber:        17,verse:"但与主联合的，便是与主成为一灵。"},
{volumn:        46,chapter:         6,versenumber:        18,verse:"你们要逃避淫行。人所犯的，无论什么罪，都在身子以外，惟有行淫的，是得罪自己的身子。"},
{volumn:        46,chapter:         6,versenumber:        19,verse:"岂不知你们的身子就是圣灵的殿吗？这圣灵是从　神而来，住在你们里头的；并且你们不是自己的人，"},
{volumn:        46,chapter:         6,versenumber:        20,verse:"因为你们是重价买来的。所以，要在你们的身子上荣耀　神。"},
{volumn:        46,chapter:         7,versenumber:         1,verse:"论到你们信上所提的事，我说男不近女倒好。"},
{volumn:        46,chapter:         7,versenumber:         2,verse:"但要免淫乱的事，男子当各有自己的妻子；女子也当各有自己的丈夫。"},
{volumn:        46,chapter:         7,versenumber:         3,verse:"丈夫当用合宜之份待妻子；妻子待丈夫也要如此。"},
{volumn:        46,chapter:         7,versenumber:         4,verse:"妻子没有权柄主张自己的身子，乃在丈夫；丈夫也没有权柄主张自己的身子，乃在妻子。"},
{volumn:        46,chapter:         7,versenumber:         5,verse:"夫妻不可彼此亏负，除非两相情愿，暂时分房，为要专心祷告方可；以后仍要同房，免得撒但趁着你们情不自禁，引诱你们。"},
{volumn:        46,chapter:         7,versenumber:         6,verse:"我说这话，原是准你们的，不是命你们的。"},
{volumn:        46,chapter:         7,versenumber:         7,verse:"我愿意众人像我一样；只是各人领受　神的恩赐，一个是这样，一个是那样。"},
{volumn:        46,chapter:         7,versenumber:         8,verse:"我对着没有嫁娶的和寡妇说，若他们常像我就好。"},
{volumn:        46,chapter:         7,versenumber:         9,verse:"倘若自己禁止不住，就可以嫁娶。与其欲火攻心，倒不如嫁娶为妙。"},
{volumn:        46,chapter:         7,versenumber:        10,verse:"至于那已经嫁娶的，我吩咐他们；其实不是我吩咐，乃是主吩咐说：妻子不可离开丈夫，"},
{volumn:        46,chapter:         7,versenumber:        11,verse:"若是离开了，不可再嫁，或是仍同丈夫和好。丈夫也不可离弃妻子。"},
{volumn:        46,chapter:         7,versenumber:        12,verse:"我对其余的人说（不是主说）：倘若某弟兄有不信的妻子，妻子也情愿和他同住，他就不要离弃妻子。"},
{volumn:        46,chapter:         7,versenumber:        13,verse:"妻子有不信的丈夫，丈夫也情愿和她同住，她就不要离弃丈夫。"},
{volumn:        46,chapter:         7,versenumber:        14,verse:"因为不信的丈夫就因着妻子成了圣洁，并且不信的妻子就因着丈夫（原文是弟兄）成了圣洁。不然，你们的儿女就不洁净，但如今他们是圣洁的了。"},
{volumn:        46,chapter:         7,versenumber:        15,verse:"倘若那不信的人要离去，就由他离去吧！无论是弟兄，是姐妹，遇着这样的事都不必拘束。　神召我们原是要我们和睦。"},
{volumn:        46,chapter:         7,versenumber:        16,verse:"你这作妻子的，怎么知道不能救你的丈夫呢？你这作丈夫的，怎么知道不能救你的妻子呢？"},
{volumn:        46,chapter:         7,versenumber:        17,verse:"只要照主所分给各人的，和　神所召各人的而行。我吩咐各教会都是这样。"},
{volumn:        46,chapter:         7,versenumber:        18,verse:"有人已受割礼蒙召呢，就不要废割礼；有人未受割礼蒙召呢，就不要受割礼。"},
{volumn:        46,chapter:         7,versenumber:        19,verse:"受割礼算不得什么，不受割礼也算不得什么，只要守　神的诫命就是了。"},
{volumn:        46,chapter:         7,versenumber:        20,verse:"各人蒙召的时候是什么身份，仍要守住这身份。"},
{volumn:        46,chapter:         7,versenumber:        21,verse:"你是作奴隶蒙召的吗？不要因此忧虑；若能以自由，就求自由更好。"},
{volumn:        46,chapter:         7,versenumber:        22,verse:"因为作奴仆蒙召于主的，就是主所释放的人；作自由之人蒙召的，就是基督的奴仆。"},
{volumn:        46,chapter:         7,versenumber:        23,verse:"你们是重价买来的，不要作人的奴仆。"},
{volumn:        46,chapter:         7,versenumber:        24,verse:"弟兄们，你们各人蒙召的时候是什么身份，仍要在　神面前守住这身份。"},
{volumn:        46,chapter:         7,versenumber:        25,verse:"论到童身的人，我没有主的命令，但我既蒙主怜恤能作忠心的人，就把自己的意见告诉你们。"},
{volumn:        46,chapter:         7,versenumber:        26,verse:"因现今的艰难，据我看来，人不如守素安常才好。"},
{volumn:        46,chapter:         7,versenumber:        27,verse:"你有妻子缠着呢，就不要求脱离；你没有妻子缠着呢，就不要求妻子。"},
{volumn:        46,chapter:         7,versenumber:        28,verse:"你若娶妻，并不是犯罪；处女若出嫁，也不是犯罪。然而这等人肉身必受苦难，我却愿意你们免这苦难。"},
{volumn:        46,chapter:         7,versenumber:        29,verse:"弟兄们，我对你们说：时候减少了。从此以后，那有妻子的，要像没有妻子；"},
{volumn:        46,chapter:         7,versenumber:        30,verse:"哀哭的，要像不哀哭；快乐的，要像不快乐；置买的，要像无有所得；"},
{volumn:        46,chapter:         7,versenumber:        31,verse:"用世物的，要像不用世物，因为这世界的样子将要过去了。"},
{volumn:        46,chapter:         7,versenumber:        32,verse:"我愿你们无所挂虑。没有娶妻的，是为主的事挂虑，想怎样叫主喜悦。"},
{volumn:        46,chapter:         7,versenumber:        33,verse:"娶了妻的，是为世上的事挂虑，想怎样叫妻子喜悦。"},
{volumn:        46,chapter:         7,versenumber:        34,verse:"妇人和处女也有分别。没有出嫁的，是为主的事挂虑，要身体、灵魂都圣洁；已经出嫁的，是为世上的事挂虑，想怎样叫丈夫喜悦。"},
{volumn:        46,chapter:         7,versenumber:        35,verse:"我说这话是为你们的益处，不是要牢笼你们，乃是要叫你们行合宜的事，得以殷勤服侍主，没有分心的事。"},
{volumn:        46,chapter:         7,versenumber:        36,verse:"若有人以为自己待他的女儿不合宜，女儿也过了年岁，事又当行，他就可随意办理，不算有罪，叫二人成亲就是了。"},
{volumn:        46,chapter:         7,versenumber:        37,verse:"倘若人心里坚定，没有不得已的事，并且由得自己作主，心里又决定了留下女儿不出嫁，如此行也好。"},
{volumn:        46,chapter:         7,versenumber:        38,verse:"这样看来，叫自己的女儿出嫁是好，不叫她出嫁更是好。"},
{volumn:        46,chapter:         7,versenumber:        39,verse:"丈夫活着的时候，妻子是被约束的；丈夫若死了，妻子就可以自由，随意再嫁，只是要嫁这在主里面的人。"},
{volumn:        46,chapter:         7,versenumber:        40,verse:"然而按我的意见，若常守节更有福气。我也想自己是被　神的灵感动了。"},
{volumn:        46,chapter:         8,versenumber:         1,verse:"论到祭偶像之物，我们晓得我们都有知识。但知识是叫人自高自大，惟有爱心能造就人。"},
{volumn:        46,chapter:         8,versenumber:         2,verse:"若有人以为自己知道什么，按他所当知道的，他仍是不知道。"},
{volumn:        46,chapter:         8,versenumber:         3,verse:"若有人爱　神，这人乃是　神所知道的。"},
{volumn:        46,chapter:         8,versenumber:         4,verse:"论到吃祭偶像之物，我们知道偶像在世上算不得什么，也知道神只有一位，再没有别的神。"},
{volumn:        46,chapter:         8,versenumber:         5,verse:"虽有称为神的，或在天，或在地，就如那许多的神，许多的主；"},
{volumn:        46,chapter:         8,versenumber:         6,verse:"然而我们只有一位　神，就是父，万物都本于他，我们也归于他；并有一位主，就是耶稣基督，万物都是藉着他有的；我们也是藉着他有的。"},
{volumn:        46,chapter:         8,versenumber:         7,verse:"但人不都有这等知识。有人到如今因拜惯了偶像，就以为所吃的是祭偶像之物。他们的良心既然软弱，也就污秽了。"},
{volumn:        46,chapter:         8,versenumber:         8,verse:"其实食物不能叫　神看中我们，因为我们不吃也无损，吃也无益。"},
{volumn:        46,chapter:         8,versenumber:         9,verse:"只是你们要谨慎，恐怕你们这自由竟成了那软弱人的绊脚石。"},
{volumn:        46,chapter:         8,versenumber:        10,verse:"若有人见你这有知识的，在偶像的庙里坐席，这人的良心若是软弱，岂不放胆去吃那祭偶像之物吗？"},
{volumn:        46,chapter:         8,versenumber:        11,verse:"因此，基督为他死的那软弱弟兄，也就因你的知识沉沦了。"},
{volumn:        46,chapter:         8,versenumber:        12,verse:"你们这样得罪弟兄们，伤了他们软弱的良心，就是得罪基督。"},
{volumn:        46,chapter:         8,versenumber:        13,verse:"所以，食物若叫我弟兄跌倒，我就永远不吃肉，免得叫我弟兄跌倒了。"},
{volumn:        46,chapter:         9,versenumber:         1,verse:"我不是自由的吗？我不是使徒吗？我不是见过我们的主耶稣吗？你们不是我在主里面所做之工吗？"},
{volumn:        46,chapter:         9,versenumber:         2,verse:"假若在别人，我不是使徒，在你们，我总是使徒，因为你们在主里正是我作使徒的印证。"},
{volumn:        46,chapter:         9,versenumber:         3,verse:"我对那盘问我的人就是这样分诉："},
{volumn:        46,chapter:         9,versenumber:         4,verse:"难道我们没有权柄靠福音吃喝吗？"},
{volumn:        46,chapter:         9,versenumber:         5,verse:"难道我们没有权柄娶信主的姐妹为妻，带着一同往来，仿佛其余的使徒和主的弟兄并矶法一样吗？"},
{volumn:        46,chapter:         9,versenumber:         6,verse:"独有我与巴拿巴没有权柄不做工吗？"},
{volumn:        46,chapter:         9,versenumber:         7,verse:"有谁当兵自备粮饷呢？有谁栽葡萄园不吃园里的果子呢？有谁牧养牛羊不吃牛羊的奶呢？"},
{volumn:        46,chapter:         9,versenumber:         8,verse:"我说这话，岂是照人的意见；律法不也是这样说吗？"},
{volumn:        46,chapter:         9,versenumber:         9,verse:"就如摩西的律法记着说：“牛在场上踹谷的时候，不可笼住它的嘴。”难道　神所挂念的是牛吗？"},
{volumn:        46,chapter:         9,versenumber:        10,verse:"不全是为我们说的吗？分明是为我们说的。因为耕种的当存着指望去耕种；打场的也当存得粮的指望去打场。"},
{volumn:        46,chapter:         9,versenumber:        11,verse:"我们若把属灵的种子撒在你们中间，就是从你们收割奉养肉身之物，这还算大事吗？"},
{volumn:        46,chapter:         9,versenumber:        12,verse:"若别人在你们身上有这权柄，何况我们呢？然而，我们没有用过这权柄，倒凡事忍受，免得基督的福音被阻隔。"},
{volumn:        46,chapter:         9,versenumber:        13,verse:"你们岂不知为圣事劳碌的就吃殿中的物吗？伺候祭坛的就分领坛上的物吗？"},
{volumn:        46,chapter:         9,versenumber:        14,verse:"主也是这样命定，叫传福音的靠着福音养生。"},
{volumn:        46,chapter:         9,versenumber:        15,verse:"但这权柄我全没有用过。我写这话，并非要你们这样待我，因为我宁可死也不叫人使我所夸的落了空。"},
{volumn:        46,chapter:         9,versenumber:        16,verse:"我传福音原没有可夸的，因为我是不得已的。若不传福音，我便有祸了。"},
{volumn:        46,chapter:         9,versenumber:        17,verse:"我若甘心做这事，就有赏赐；若不甘心，责任却已经托付我了。"},
{volumn:        46,chapter:         9,versenumber:        18,verse:"既是这样，我的赏赐是什么呢？就是我传福音的时候叫人不花钱得福音，免得用尽我传福音的权柄。"},
{volumn:        46,chapter:         9,versenumber:        19,verse:"我虽是自由的，无人辖管；然而我甘心作了众人的仆人，为要多得人。"},
{volumn:        46,chapter:         9,versenumber:        20,verse:"向犹太人，我就作犹太人，为要得犹太人；向律法以下的人，我虽不在律法以下，还是作律法以下的人，为要得律法以下的人。"},
{volumn:        46,chapter:         9,versenumber:        21,verse:"向没有律法的人，我就作没有律法的人，为要得没有律法的人；其实我在　神面前，不是没有律法；在基督面前，正在律法之下。"},
{volumn:        46,chapter:         9,versenumber:        22,verse:"向软弱的人，我就作软弱的人，为要得软弱的人。向什么样的人，我就作什么样的人。无论如何，总要救些人。"},
{volumn:        46,chapter:         9,versenumber:        23,verse:"凡我所行的，都是为福音的缘故，为要与人同得这福音的好处。"},
{volumn:        46,chapter:         9,versenumber:        24,verse:"岂不知在场上赛跑的都跑，但得奖赏的只有一人？你们也当这样跑，好叫你们得着奖赏。"},
{volumn:        46,chapter:         9,versenumber:        25,verse:"凡较力争胜的，诸事都有节制，他们不过是要得能坏的冠冕；我们却是要得不能坏的冠冕。"},
{volumn:        46,chapter:         9,versenumber:        26,verse:"所以，我奔跑不像无定向的；我斗拳不像打空气的。"},
{volumn:        46,chapter:         9,versenumber:        27,verse:"我是攻克己身，叫身服我，恐怕我传福音给别人，自己反被弃绝了。"},
{volumn:        46,chapter:        10,versenumber:         1,verse:"弟兄们，我不愿意你们不晓得，我们的祖宗从前都在云下，都从海中经过，"},
{volumn:        46,chapter:        10,versenumber:         2,verse:"都在云里、海里受洗归了摩西；"},
{volumn:        46,chapter:        10,versenumber:         3,verse:"并且都吃了一样的灵食，"},
{volumn:        46,chapter:        10,versenumber:         4,verse:"也都喝了一样的灵水。所喝的，是出于随着他们的灵磐石；那磐石就是基督。"},
{volumn:        46,chapter:        10,versenumber:         5,verse:"但他们中间多半是　神不喜欢的人，所以在旷野倒毙。"},
{volumn:        46,chapter:        10,versenumber:         6,verse:"这些事都是我们的鉴戒，叫我们不要贪恋恶事，像他们那样贪恋的；"},
{volumn:        46,chapter:        10,versenumber:         7,verse:"也不要拜偶像，像他们有人拜的。如经上所记：“百姓坐下吃喝，起来玩耍。”"},
{volumn:        46,chapter:        10,versenumber:         8,verse:"我们也不要行奸淫，像他们有人行的，一天就倒毙了二万三千人；"},
{volumn:        46,chapter:        10,versenumber:         9,verse:"也不要试探主（有古卷：基督），像他们有人试探的，就被蛇所灭。"},
{volumn:        46,chapter:        10,versenumber:        10,verse:"你们也不要发怨言，像他们有发怨言的，就被灭命的所灭。"},
{volumn:        46,chapter:        10,versenumber:        11,verse:"他们遭遇这些事都要作为鉴戒，并且写在经上，正是警戒我们这末世的人。"},
{volumn:        46,chapter:        10,versenumber:        12,verse:"所以，自己以为站得稳的，须要谨慎，免得跌倒。"},
{volumn:        46,chapter:        10,versenumber:        13,verse:"你们所遇见的试探，无非是人所能受的。　神是信实的，必不叫你们受试探过于所能受的；在受试探的时候，总要给你们开一条出路，叫你们能忍受得住。"},
{volumn:        46,chapter:        10,versenumber:        14,verse:"我所亲爱的弟兄啊，你们要逃避拜偶像的事。"},
{volumn:        46,chapter:        10,versenumber:        15,verse:"我好像对明白人说的，你们要审察我的话。"},
{volumn:        46,chapter:        10,versenumber:        16,verse:"我们所祝福的杯，岂不是同领基督的血吗？我们所擘开的饼，岂不是同领基督的身体吗？"},
{volumn:        46,chapter:        10,versenumber:        17,verse:"我们虽多，仍是一个饼，一个身体，因为我们都是分受这一个饼。"},
{volumn:        46,chapter:        10,versenumber:        18,verse:"你们看属肉体的以色列人，那吃祭物的岂不是在祭坛上有份吗？"},
{volumn:        46,chapter:        10,versenumber:        19,verse:"我是怎么说呢？岂是说祭偶像之物算得什么呢？或说偶像算得什么呢？"},
{volumn:        46,chapter:        10,versenumber:        20,verse:"我乃是说，外邦人所献的祭是祭鬼，不是祭　神。我不愿意你们与鬼相交。"},
{volumn:        46,chapter:        10,versenumber:        21,verse:"你们不能喝主的杯又喝鬼的杯，不能吃主的筵席又吃鬼的筵席。"},
{volumn:        46,chapter:        10,versenumber:        22,verse:"我们可惹主的愤恨吗？我们比他还有能力吗？"},
{volumn:        46,chapter:        10,versenumber:        23,verse:"凡事都可行，但不都有益处。凡事都可行，但不都造就人。"},
{volumn:        46,chapter:        10,versenumber:        24,verse:"无论何人，不要求自己的益处，乃要求别人的益处。"},
{volumn:        46,chapter:        10,versenumber:        25,verse:"凡市上所卖的，你们只管吃，不要为良心的缘故问什么话，"},
{volumn:        46,chapter:        10,versenumber:        26,verse:"因为地和其中所充满的都属乎主。"},
{volumn:        46,chapter:        10,versenumber:        27,verse:"倘有一个不信的人请你们赴席，你们若愿意去，凡摆在你们面前的，只管吃，不要为良心的缘故问什么话。"},
{volumn:        46,chapter:        10,versenumber:        28,verse:"若有人对你们说：“这是献过祭的物”，就要为那告诉你们的人，并为良心的缘故不吃。"},
{volumn:        46,chapter:        10,versenumber:        29,verse:"我说的良心不是你的，乃是他的。我这自由为什么被别人的良心论断呢？"},
{volumn:        46,chapter:        10,versenumber:        30,verse:"我若谢恩而吃，为什么因我谢恩的物被人毁谤呢？"},
{volumn:        46,chapter:        10,versenumber:        31,verse:"所以，你们或吃或喝，无论做什么，都要为荣耀　神而行。"},
{volumn:        46,chapter:        10,versenumber:        32,verse:"不拘是犹太人，是希腊人，是　神的教会，你们都不要使他跌倒；"},
{volumn:        46,chapter:        10,versenumber:        33,verse:"就好像我凡事都叫众人喜欢，不求自己的益处，只求众人的益处，叫他们得救。"},
{volumn:        46,chapter:        11,versenumber:         1,verse:"你们该效法我，像我效法基督一样。"},
{volumn:        46,chapter:        11,versenumber:         2,verse:"我称赞你们，因你们凡事记念我，又坚守我所传给你们的。"},
{volumn:        46,chapter:        11,versenumber:         3,verse:"我愿意你们知道，基督是各人的头；男人是女人的头；　神是基督的头。"},
{volumn:        46,chapter:        11,versenumber:         4,verse:"凡男人祷告或是讲道（或译：说预言；下同），若蒙着头，就羞辱自己的头。"},
{volumn:        46,chapter:        11,versenumber:         5,verse:"凡女人祷告或是讲道，若不蒙着头，就羞辱自己的头，因为这就如同剃了头发一样。"},
{volumn:        46,chapter:        11,versenumber:         6,verse:"女人若不蒙着头，就该剪了头发；女人若以剪发、剃发为羞愧，就该蒙着头。"},
{volumn:        46,chapter:        11,versenumber:         7,verse:"男人本不该蒙着头，因为他是　神的形像和荣耀；但女人是男人的荣耀。"},
{volumn:        46,chapter:        11,versenumber:         8,verse:"起初，男人不是由女人而出，女人乃是由男人而出；"},
{volumn:        46,chapter:        11,versenumber:         9,verse:"并且男人不是为女人造的，女人乃是为男人造的。"},
{volumn:        46,chapter:        11,versenumber:        10,verse:"因此，女人为天使的缘故，应当在头上有服权柄的记号。"},
{volumn:        46,chapter:        11,versenumber:        11,verse:"然而照主的安排，女也不是无男，男也不是无女。"},
{volumn:        46,chapter:        11,versenumber:        12,verse:"因为女人原是由男人而出，男人也是由女人而出；但万有都是出乎　神。"},
{volumn:        46,chapter:        11,versenumber:        13,verse:"你们自己审察，女人祷告　神，不蒙着头是合宜的吗？"},
{volumn:        46,chapter:        11,versenumber:        14,verse:"你们的本性不也指示你们，男人若有长头发，便是他的羞辱吗？"},
{volumn:        46,chapter:        11,versenumber:        15,verse:"但女人有长头发，乃是她的荣耀，因为这头发是给她作盖头的。"},
{volumn:        46,chapter:        11,versenumber:        16,verse:"若有人想要辩驳，我们却没有这样的规矩，　神的众教会也是没有的。"},
{volumn:        46,chapter:        11,versenumber:        17,verse:"我现今吩咐你们的话，不是称赞你们；因为你们聚会不是受益，乃是招损。"},
{volumn:        46,chapter:        11,versenumber:        18,verse:"第一，我听说，你们聚会的时候彼此分门别类，我也稍微地信这话。"},
{volumn:        46,chapter:        11,versenumber:        19,verse:"在你们中间不免有分门结党的事，好叫那些有经验的人显明出来。"},
{volumn:        46,chapter:        11,versenumber:        20,verse:"你们聚会的时候，算不得吃主的晚餐；"},
{volumn:        46,chapter:        11,versenumber:        21,verse:"因为吃的时候，各人先吃自己的饭，甚至这个饥饿，那个酒醉。"},
{volumn:        46,chapter:        11,versenumber:        22,verse:"你们要吃喝，难道没有家吗？还是藐视　神的教会，叫那没有的羞愧呢？我向你们可怎么说呢？可因此称赞你们吗？我不称赞！"},
{volumn:        46,chapter:        11,versenumber:        23,verse:"我当日传给你们的，原是从主领受的，就是主耶稣被卖的那一夜，拿起饼来，"},
{volumn:        46,chapter:        11,versenumber:        24,verse:"祝谢了，就擘开，说：“这是我的身体，为你们舍（有古卷：擘开）的，你们应当如此行，为的是记念我。”"},
{volumn:        46,chapter:        11,versenumber:        25,verse:"饭后，也照样拿起杯来，说：“这杯是用我的血所立的新约，你们每逢喝的时候，要如此行，为的是记念我。”"},
{volumn:        46,chapter:        11,versenumber:        26,verse:"你们每逢吃这饼，喝这杯，是表明主的死，直等到他来。"},
{volumn:        46,chapter:        11,versenumber:        27,verse:"所以，无论何人，不按理吃主的饼，喝主的杯，就是干犯主的身、主的血了。"},
{volumn:        46,chapter:        11,versenumber:        28,verse:"人应当自己省察，然后吃这饼、喝这杯。"},
{volumn:        46,chapter:        11,versenumber:        29,verse:"因为人吃喝，若不分辨是主的身体，就是吃喝自己的罪了。"},
{volumn:        46,chapter:        11,versenumber:        30,verse:"因此，在你们中间有好些软弱的与患病的，死（原文是睡）的也不少。"},
{volumn:        46,chapter:        11,versenumber:        31,verse:"我们若是先分辨自己，就不至于受审。"},
{volumn:        46,chapter:        11,versenumber:        32,verse:"我们受审的时候，乃是被主惩治，免得我们和世人一同定罪。"},
{volumn:        46,chapter:        11,versenumber:        33,verse:"所以我弟兄们，你们聚会吃的时候，要彼此等待。"},
{volumn:        46,chapter:        11,versenumber:        34,verse:"若有人饥饿，可以在家里先吃，免得你们聚会，自己取罪。其余的事，我来的时候再安排。"},
{volumn:        46,chapter:        12,versenumber:         1,verse:"弟兄们，论到属灵的恩赐，我不愿意你们不明白。"},
{volumn:        46,chapter:        12,versenumber:         2,verse:"你们作外邦人的时候，随事被牵引，受迷惑，去服侍那哑巴偶像，这是你们知道的。"},
{volumn:        46,chapter:        12,versenumber:         3,verse:"所以我告诉你们，被　神的灵感动的，没有说“耶稣是可咒诅”的；若不是被圣灵感动的，也没有能说“耶稣是主”的。"},
{volumn:        46,chapter:        12,versenumber:         4,verse:"恩赐原有分别，圣灵却是一位。"},
{volumn:        46,chapter:        12,versenumber:         5,verse:"职事也有分别，主却是一位。"},
{volumn:        46,chapter:        12,versenumber:         6,verse:"功用也有分别，　神却是一位，在众人里面运行一切的事。"},
{volumn:        46,chapter:        12,versenumber:         7,verse:"圣灵显在各人身上，是叫人得益处。"},
{volumn:        46,chapter:        12,versenumber:         8,verse:"这人蒙圣灵赐他智慧的言语，那人也蒙这位圣灵赐他知识的言语，"},
{volumn:        46,chapter:        12,versenumber:         9,verse:"又有一人蒙这位圣灵赐他信心，还有一人蒙这位圣灵赐他医病的恩赐，"},
{volumn:        46,chapter:        12,versenumber:        10,verse:"又叫一人能行异能，又叫一人能作先知，又叫一人能辨别诸灵，又叫一人能说方言，又叫一人能翻方言。"},
{volumn:        46,chapter:        12,versenumber:        11,verse:"这一切都是这位圣灵所运行、随己意分给各人的。"},
{volumn:        46,chapter:        12,versenumber:        12,verse:"就如身子是一个，却有许多肢体；而且肢体虽多，仍是一个身子；基督也是这样。"},
{volumn:        46,chapter:        12,versenumber:        13,verse:"我们不拘是犹太人，是希腊人，是为奴的，是自主的，都从一位圣灵受洗，成了一个身体，饮于一位圣灵。"},
{volumn:        46,chapter:        12,versenumber:        14,verse:"身子原不是一个肢体，乃是许多肢体。"},
{volumn:        46,chapter:        12,versenumber:        15,verse:"设若脚说：“我不是手，所以不属乎身子，”它不能因此就不属乎身子。"},
{volumn:        46,chapter:        12,versenumber:        16,verse:"设若耳说：“我不是眼，所以不属乎身子，”它也不能因此就不属乎身子。"},
{volumn:        46,chapter:        12,versenumber:        17,verse:"若全身是眼，从哪里听声呢？若全身是耳，从哪里闻味呢？"},
{volumn:        46,chapter:        12,versenumber:        18,verse:"但如今，　神随自己的意思把肢体俱各安排在身上了。"},
{volumn:        46,chapter:        12,versenumber:        19,verse:"若都是一个肢体，身子在哪里呢？"},
{volumn:        46,chapter:        12,versenumber:        20,verse:"但如今肢体是多的，身子却是一个。"},
{volumn:        46,chapter:        12,versenumber:        21,verse:"眼不能对手说：“我用不着你”；头也不能对脚说：“我用不着你。”"},
{volumn:        46,chapter:        12,versenumber:        22,verse:"不但如此，身上肢体人以为软弱的，更是不可少的。"},
{volumn:        46,chapter:        12,versenumber:        23,verse:"身上肢体，我们看为不体面的，越发给它加上体面；不俊美的，越发得着俊美。"},
{volumn:        46,chapter:        12,versenumber:        24,verse:"我们俊美的肢体，自然用不着装饰；但　神配搭这身子，把加倍的体面给那有缺欠的肢体，"},
{volumn:        46,chapter:        12,versenumber:        25,verse:"免得身上分门别类，总要肢体彼此相顾。"},
{volumn:        46,chapter:        12,versenumber:        26,verse:"若一个肢体受苦，所有的肢体就一同受苦；若一个肢体得荣耀，所有的肢体就一同快乐。"},
{volumn:        46,chapter:        12,versenumber:        27,verse:"你们就是基督的身子，并且各自作肢体。"},
{volumn:        46,chapter:        12,versenumber:        28,verse:"神在教会所设立的：第一是使徒，第二是先知，第三是教师，其次是行异能的，再次是得恩赐医病的，帮助人的，治理事的，说方言的。"},
{volumn:        46,chapter:        12,versenumber:        29,verse:"岂都是使徒吗？岂都是先知吗？岂都是教师吗？岂都是行异能的吗？"},
{volumn:        46,chapter:        12,versenumber:        30,verse:"岂都是得恩赐医病的吗？岂都是说方言的吗？岂都是翻方言的吗？"},
{volumn:        46,chapter:        12,versenumber:        31,verse:"你们要切切地求那更大的恩赐。我现今把最妙的道指示你们。"},
{volumn:        46,chapter:        13,versenumber:         1,verse:"我若能说万人的方言，并天使的话语，却没有爱，我就成了鸣的锣，响的钹一般。"},
{volumn:        46,chapter:        13,versenumber:         2,verse:"我若有先知讲道之能，也明白各样的奥秘，各样的知识，而且有全备的信，叫我能够移山，却没有爱，我就算不得什么。"},
{volumn:        46,chapter:        13,versenumber:         3,verse:"我若将所有的周济穷人，又舍己身叫人焚烧，却没有爱，仍然与我无益。"},
{volumn:        46,chapter:        13,versenumber:         4,verse:"爱是恒久忍耐，又有恩慈；爱是不嫉妒；爱是不自夸，不张狂，"},
{volumn:        46,chapter:        13,versenumber:         5,verse:"不做害羞的事，不求自己的益处，不轻易发怒，不计算人的恶，"},
{volumn:        46,chapter:        13,versenumber:         6,verse:"不喜欢不义，只喜欢真理；"},
{volumn:        46,chapter:        13,versenumber:         7,verse:"凡事包容，凡事相信，凡事盼望，凡事忍耐。"},
{volumn:        46,chapter:        13,versenumber:         8,verse:"爱是永不止息。先知讲道之能终必归于无有；说方言之能终必停止；知识也终必归于无有。"},
{volumn:        46,chapter:        13,versenumber:         9,verse:"我们现在所知道的有限，先知所讲的也有限，"},
{volumn:        46,chapter:        13,versenumber:        10,verse:"等那完全的来到，这有限的必归于无有了。"},
{volumn:        46,chapter:        13,versenumber:        11,verse:"我作孩子的时候，话语像孩子，心思像孩子，意念像孩子，既成了人，就把孩子的事丢弃了。"},
{volumn:        46,chapter:        13,versenumber:        12,verse:"我们如今仿佛对着镜子观看，模糊不清（原文是如同猜谜），到那时就要面对面了。我如今所知道的有限，到那时就全知道，如同主知道我一样。"},
{volumn:        46,chapter:        13,versenumber:        13,verse:"如今常存的有信，有望，有爱这三样，其中最大的是爱。"},
{volumn:        46,chapter:        14,versenumber:         1,verse:"你们要追求爱，也要切慕属灵的恩赐，其中更要羡慕的，是作先知讲道（原文作：是说预言；下同）。"},
{volumn:        46,chapter:        14,versenumber:         2,verse:"那说方言的，原不是对人说，乃是对　神说，因为没有人听出来。然而，他在心灵里却是讲说各样的奥秘。"},
{volumn:        46,chapter:        14,versenumber:         3,verse:"但作先知讲道的，是对人说，要造就、安慰、劝勉人。"},
{volumn:        46,chapter:        14,versenumber:         4,verse:"说方言的，是造就自己；作先知讲道的，乃是造就教会。"},
{volumn:        46,chapter:        14,versenumber:         5,verse:"我愿意你们都说方言，更愿意你们作先知讲道；因为说方言的，若不翻出来，使教会被造就，那作先知讲道的，就比他强了。"},
{volumn:        46,chapter:        14,versenumber:         6,verse:"弟兄们，我到你们那里去，若只说方言，不用启示，或知识，或预言，或教训，给你们讲解，我与你们有什么益处呢？"},
{volumn:        46,chapter:        14,versenumber:         7,verse:"就是那有声无气的物，或箫，或琴，若发出来的声音没有分别，怎能知道所吹所弹的是什么呢？"},
{volumn:        46,chapter:        14,versenumber:         8,verse:"若吹无定的号声，谁能预备打仗呢？"},
{volumn:        46,chapter:        14,versenumber:         9,verse:"你们也是如此。舌头若不说容易明白的话，怎能知道所说的是什么呢？这就是向空说话了。"},
{volumn:        46,chapter:        14,versenumber:        10,verse:"世上的声音，或者甚多，却没有一样是无意思的。"},
{volumn:        46,chapter:        14,versenumber:        11,verse:"我若不明白那声音的意思，这说话的人必以我为化外之人，我也以他为化外之人。"},
{volumn:        46,chapter:        14,versenumber:        12,verse:"你们也是如此，既是切慕属灵的恩赐，就当求多得造就教会的恩赐。"},
{volumn:        46,chapter:        14,versenumber:        13,verse:"所以那说方言的，就当求着能翻出来。"},
{volumn:        46,chapter:        14,versenumber:        14,verse:"我若用方言祷告，是我的灵祷告，但我的悟性没有果效。"},
{volumn:        46,chapter:        14,versenumber:        15,verse:"这却怎么样呢？我要用灵祷告，也要用悟性祷告；我要用灵歌唱，也要用悟性歌唱。"},
{volumn:        46,chapter:        14,versenumber:        16,verse:"不然，你用灵祝谢，那在座不通方言的人，既然不明白你的话，怎能在你感谢的时候说“阿们”呢？"},
{volumn:        46,chapter:        14,versenumber:        17,verse:"你感谢的固然是好，无奈不能造就别人。"},
{volumn:        46,chapter:        14,versenumber:        18,verse:"我感谢　神，我说方言比你们众人还多。"},
{volumn:        46,chapter:        14,versenumber:        19,verse:"但在教会中，宁可用悟性说五句教导人的话，强如说万句方言。"},
{volumn:        46,chapter:        14,versenumber:        20,verse:"弟兄们，在心志上不要作小孩子。然而，在恶事上要作婴孩，在心志上总要作大人。"},
{volumn:        46,chapter:        14,versenumber:        21,verse:"律法上记着：“主说：‘我要用外邦人的舌头和外邦人的嘴唇向这百姓说话；虽然如此，他们还是不听从我。’”"},
{volumn:        46,chapter:        14,versenumber:        22,verse:"这样看来，说方言不是为信的人作证据，乃是为不信的人；作先知讲道不是为不信的人作证据，乃是为信的人。"},
{volumn:        46,chapter:        14,versenumber:        23,verse:"所以，全教会聚在一处的时候，若都说方言，偶然有不通方言的，或是不信的人进来，岂不说你们癫狂了吗？"},
{volumn:        46,chapter:        14,versenumber:        24,verse:"若都作先知讲道，偶然有不信的，或是不通方言的人进来，就被众人劝醒，被众人审明，"},
{volumn:        46,chapter:        14,versenumber:        25,verse:"他心里的隐情显露出来，就必将脸伏地，敬拜　神，说：“神真是在你们中间了。”"},
{volumn:        46,chapter:        14,versenumber:        26,verse:"弟兄们，这却怎么样呢？你们聚会的时候，各人或有诗歌，或有教训，或有启示，或有方言，或有翻出来的话，凡事都当造就人。"},
{volumn:        46,chapter:        14,versenumber:        27,verse:"若有说方言的，只好两个人，至多三个人，且要轮流着说，也要一个人翻出来。"},
{volumn:        46,chapter:        14,versenumber:        28,verse:"若没有人翻，就当在会中闭口，只对自己和　神说就是了。"},
{volumn:        46,chapter:        14,versenumber:        29,verse:"至于作先知讲道的，只好两个人或是三个人，其余的就当慎思明辨。"},
{volumn:        46,chapter:        14,versenumber:        30,verse:"若旁边坐着的得了启示，那先说话的就当闭口不言。"},
{volumn:        46,chapter:        14,versenumber:        31,verse:"因为你们都可以一个一个地作先知讲道，叫众人学道理，叫众人得劝勉。"},
{volumn:        46,chapter:        14,versenumber:        32,verse:"先知的灵原是顺服先知的；"},
{volumn:        46,chapter:        14,versenumber:        33,verse:"因为　神不是叫人混乱，乃是叫人安静。"},
{volumn:        46,chapter:        14,versenumber:        34,verse:"妇女在会中要闭口不言，像在圣徒的众教会一样，因为不准她们说话。她们总要顺服，正如律法所说的。"},
{volumn:        46,chapter:        14,versenumber:        35,verse:"她们若要学什么，可以在家里问自己的丈夫，因为妇女在会中说话原是可耻的。"},
{volumn:        46,chapter:        14,versenumber:        36,verse:"神的道理岂是从你们出来吗？岂是单临到你们吗？"},
{volumn:        46,chapter:        14,versenumber:        37,verse:"若有人以为自己是先知，或是属灵的，就该知道，我所写给你们的是主的命令。"},
{volumn:        46,chapter:        14,versenumber:        38,verse:"若有不知道的，就由他不知道吧！"},
{volumn:        46,chapter:        14,versenumber:        39,verse:"所以我弟兄们，你们要切慕作先知讲道，也不要禁止说方言。"},
{volumn:        46,chapter:        14,versenumber:        40,verse:"凡事都要规规矩矩地按着次序行。"},
{volumn:        46,chapter:        15,versenumber:         1,verse:"弟兄们，我如今把先前所传给你们的福音告诉你们知道；这福音你们也领受了，又靠着站立得住，"},
{volumn:        46,chapter:        15,versenumber:         2,verse:"并且你们若不是徒然相信，能以持守我所传给你们的，就必因这福音得救。"},
{volumn:        46,chapter:        15,versenumber:         3,verse:"我当日所领受又传给你们的：第一，就是基督照圣经所说，为我们的罪死了，"},
{volumn:        46,chapter:        15,versenumber:         4,verse:"而且埋葬了；又照圣经所说，第三天复活了，"},
{volumn:        46,chapter:        15,versenumber:         5,verse:"并且显给矶法看，然后显给十二使徒看；"},
{volumn:        46,chapter:        15,versenumber:         6,verse:"后来一时显给五百多弟兄看，其中一大半到如今还在，却也有已经睡了的。"},
{volumn:        46,chapter:        15,versenumber:         7,verse:"以后显给雅各看，再显给众使徒看，"},
{volumn:        46,chapter:        15,versenumber:         8,verse:"末了也显给我看；我如同未到产期而生的人一般。"},
{volumn:        46,chapter:        15,versenumber:         9,verse:"我原是使徒中最小的，不配称为使徒，因为我从前逼迫　神的教会。"},
{volumn:        46,chapter:        15,versenumber:        10,verse:"然而，我今日成了何等人，是蒙　神的恩才成的，并且他所赐我的恩不是徒然的。我比众使徒格外劳苦；这原不是我，乃是　神的恩与我同在。"},
{volumn:        46,chapter:        15,versenumber:        11,verse:"不拘是我，是众使徒，我们如此传，你们也如此信了。"},
{volumn:        46,chapter:        15,versenumber:        12,verse:"既传基督是从死里复活了，怎么在你们中间有人说没有死人复活的事呢？"},
{volumn:        46,chapter:        15,versenumber:        13,verse:"若没有死人复活的事，基督也就没有复活了。"},
{volumn:        46,chapter:        15,versenumber:        14,verse:"若基督没有复活，我们所传的便是枉然，你们所信的也是枉然；"},
{volumn:        46,chapter:        15,versenumber:        15,verse:"并且明显我们是为　神妄作见证的，因我们见证　神是叫基督复活了。若死人真不复活，　神也就没有叫基督复活了。"},
{volumn:        46,chapter:        15,versenumber:        16,verse:"因为死人若不复活，基督也就没有复活了。"},
{volumn:        46,chapter:        15,versenumber:        17,verse:"基督若没有复活，你们的信便是徒然，你们仍在罪里。"},
{volumn:        46,chapter:        15,versenumber:        18,verse:"就是在基督里睡了的人也灭亡了。"},
{volumn:        46,chapter:        15,versenumber:        19,verse:"我们若靠基督只在今生有指望，就算比众人更可怜。"},
{volumn:        46,chapter:        15,versenumber:        20,verse:"但基督已经从死里复活，成为睡了之人初熟的果子。"},
{volumn:        46,chapter:        15,versenumber:        21,verse:"死既是因一人而来，死人复活也是因一人而来。"},
{volumn:        46,chapter:        15,versenumber:        22,verse:"在亚当里众人都死了；照样，在基督里众人也都要复活。"},
{volumn:        46,chapter:        15,versenumber:        23,verse:"但各人是按着自己的次序复活：初熟的果子是基督；以后，在他来的时候，是那些属基督的。"},
{volumn:        46,chapter:        15,versenumber:        24,verse:"再后，末期到了，那时基督既将一切执政的、掌权的、有能的都毁灭了，就把国交与父　神。"},
{volumn:        46,chapter:        15,versenumber:        25,verse:"因为基督必要作王，等　神把一切仇敌都放在他的脚下。"},
{volumn:        46,chapter:        15,versenumber:        26,verse:"尽末了所毁灭的仇敌就是死。"},
{volumn:        46,chapter:        15,versenumber:        27,verse:"因为经上说：“　神叫万物都服在他的脚下。”既说万物都服了他，明显那叫万物服他的，不在其内了。"},
{volumn:        46,chapter:        15,versenumber:        28,verse:"万物既服了他，那时子也要自己服那叫万物服他的，叫　神在万物之上，为万物之主。"},
{volumn:        46,chapter:        15,versenumber:        29,verse:"不然，那些为死人受洗的，将来怎样呢？若死人总不复活，因何为他们受洗呢？"},
{volumn:        46,chapter:        15,versenumber:        30,verse:"我们又因何时刻冒险呢？"},
{volumn:        46,chapter:        15,versenumber:        31,verse:"弟兄们，我在我主基督耶稣里，指着你们所夸的口极力地说，我是天天冒死。"},
{volumn:        46,chapter:        15,versenumber:        32,verse:"我若当日像寻常人，在以弗所同野兽战斗，那于我有什么益处呢？若死人不复活，我们就吃吃喝喝吧！因为明天要死了。"},
{volumn:        46,chapter:        15,versenumber:        33,verse:"你们不要自欺；滥交是败坏善行。"},
{volumn:        46,chapter:        15,versenumber:        34,verse:"你们要醒悟为善，不要犯罪，因为有人不认识　神。我说这话是要叫你们羞愧。"},
{volumn:        46,chapter:        15,versenumber:        35,verse:"或有人问：“死人怎样复活，带着什么身体来呢？”"},
{volumn:        46,chapter:        15,versenumber:        36,verse:"无知的人哪，你所种的，若不死就不能生。"},
{volumn:        46,chapter:        15,versenumber:        37,verse:"并且你所种的不是那将来的形体，不过是子粒，即如麦子，或是别样的谷。"},
{volumn:        46,chapter:        15,versenumber:        38,verse:"但　神随自己的意思给他一个形体，并叫各等子粒各有自己的形体。"},
{volumn:        46,chapter:        15,versenumber:        39,verse:"凡肉体各有不同：人是一样，兽又是一样，鸟又是一样，鱼又是一样。"},
{volumn:        46,chapter:        15,versenumber:        40,verse:"有天上的形体，也有地上的形体；但天上形体的荣光是一样，地上形体的荣光又是一样。"},
{volumn:        46,chapter:        15,versenumber:        41,verse:"日有日的荣光，月有月的荣光，星有星的荣光；这星和那星的荣光也有分别。"},
{volumn:        46,chapter:        15,versenumber:        42,verse:"死人复活也是这样：所种的是必朽坏的，复活的是不朽坏的；"},
{volumn:        46,chapter:        15,versenumber:        43,verse:"所种的是羞辱的，复活的是荣耀的；所种的是软弱的，复活的是强壮的；"},
{volumn:        46,chapter:        15,versenumber:        44,verse:"所种的是血气的身体，复活的是灵性的身体。若有血气的身体，也必有灵性的身体。"},
{volumn:        46,chapter:        15,versenumber:        45,verse:"经上也是这样记着说：“首先的人亚当成了有灵（灵：或译血气）的活人”；末后的亚当成了叫人活的灵。"},
{volumn:        46,chapter:        15,versenumber:        46,verse:"但属灵的不在先，属血气的在先，以后才有属灵的。"},
{volumn:        46,chapter:        15,versenumber:        47,verse:"头一个人是出于地，乃属土；第二个人是出于天。"},
{volumn:        46,chapter:        15,versenumber:        48,verse:"那属土的怎样，凡属土的也就怎样；属天的怎样，凡属天的也就怎样。"},
{volumn:        46,chapter:        15,versenumber:        49,verse:"我们既有属土的形状，将来也必有属天的形状。"},
{volumn:        46,chapter:        15,versenumber:        50,verse:"弟兄们，我告诉你们说，血肉之体不能承受　神的国，必朽坏的不能承受不朽坏的。"},
{volumn:        46,chapter:        15,versenumber:        51,verse:"我如今把一件奥秘的事告诉你们：我们不是都要睡觉，乃是都要改变，"},
{volumn:        46,chapter:        15,versenumber:        52,verse:"就在一霎时，眨眼之间，号筒末次吹响的时候。因号筒要响，死人要复活成为不朽坏的，我们也要改变。"},
{volumn:        46,chapter:        15,versenumber:        53,verse:"这必朽坏的总要变成（变成：原文是穿；下同）不朽坏的，这必死的总要变成不死的。"},
{volumn:        46,chapter:        15,versenumber:        54,verse:"这必朽坏的既变成不朽坏的，这必死的既变成不死的，那时经上所记“死被得胜吞灭”的话就应验了。"},
{volumn:        46,chapter:        15,versenumber:        55,verse:"死啊！你得胜的权势在哪里？死啊！你的毒钩在哪里？"},
{volumn:        46,chapter:        15,versenumber:        56,verse:"死的毒钩就是罪，罪的权势就是律法。"},
{volumn:        46,chapter:        15,versenumber:        57,verse:"感谢　神，使我们借着我们的主耶稣基督得胜。"},
{volumn:        46,chapter:        15,versenumber:        58,verse:"所以，我亲爱的弟兄们，你们务要坚固，不可摇动，常常竭力多做主工；因为知道，你们的劳苦在主里面不是徒然的。"},
{volumn:        46,chapter:        16,versenumber:         1,verse:"论到为圣徒捐钱，我从前怎样吩咐加拉太的众教会，你们也当怎样行。"},
{volumn:        46,chapter:        16,versenumber:         2,verse:"每逢七日的第一日，各人要照自己的进项抽出来留着，免得我来的时候现凑。"},
{volumn:        46,chapter:        16,versenumber:         3,verse:"及至我来到了，你们写信举荐谁，我就打发他们，把你们的捐资送到耶路撒冷去。"},
{volumn:        46,chapter:        16,versenumber:         4,verse:"若我也该去，他们可以和我同去。"},
{volumn:        46,chapter:        16,versenumber:         5,verse:"我要从马其顿经过；既经过了，就要到你们那里去，"},
{volumn:        46,chapter:        16,versenumber:         6,verse:"或者和你们同住几时，或者也过冬。无论我往哪里去，你们就可以给我送行。"},
{volumn:        46,chapter:        16,versenumber:         7,verse:"我如今不愿意路过见你们；主若许我，我就指望和你们同住几时。"},
{volumn:        46,chapter:        16,versenumber:         8,verse:"但我要仍旧住在以弗所，直等到五旬节；"},
{volumn:        46,chapter:        16,versenumber:         9,verse:"因为有宽大又有功效的门为我开了，并且反对的人也多。"},
{volumn:        46,chapter:        16,versenumber:        10,verse:"若是提摩太来到，你们要留心，叫他在你们那里无所惧怕；因为他劳力做主的工，像我一样。"},
{volumn:        46,chapter:        16,versenumber:        11,verse:"所以，无论谁都不可藐视他，只要送他平安前行，叫他到我这里来，因我指望他和弟兄们同来。"},
{volumn:        46,chapter:        16,versenumber:        12,verse:"至于兄弟亚波罗，我再三地劝他同弟兄们到你们那里去；但这时他决不愿意去，几时有了机会他必去。"},
{volumn:        46,chapter:        16,versenumber:        13,verse:"你们务要警醒，在真道上站立得稳，要作大丈夫，要刚强。"},
{volumn:        46,chapter:        16,versenumber:        14,verse:"凡你们所做的都要凭爱心而做。"},
{volumn:        46,chapter:        16,versenumber:        15,verse:"弟兄们，你们晓得司提反一家，是亚该亚初结的果子，并且他们专以服侍圣徒为念。"},
{volumn:        46,chapter:        16,versenumber:        16,verse:"我劝你们顺服这样的人，并一切同工同劳的人。"},
{volumn:        46,chapter:        16,versenumber:        17,verse:"司提反和福徒拿都，并亚该古到这里来，我很喜欢；因为你们待我有不及之处，他们补上了。"},
{volumn:        46,chapter:        16,versenumber:        18,verse:"他们叫我和你们心里都快活。这样的人，你们务要敬重。"},
{volumn:        46,chapter:        16,versenumber:        19,verse:"亚细亚的众教会问你们安。亚居拉和百基拉并在他们家里的教会，因主多多地问你们安。"},
{volumn:        46,chapter:        16,versenumber:        20,verse:"众弟兄都问你们安。你们要亲嘴问安，彼此务要圣洁。"},
{volumn:        46,chapter:        16,versenumber:        21,verse:"我保罗亲笔问安。"},
{volumn:        46,chapter:        16,versenumber:        22,verse:"若有人不爱主，这人可诅可咒。主必要来！"},
{volumn:        46,chapter:        16,versenumber:        23,verse:"愿主耶稣基督的恩常与你们众人同在！"},
{volumn:        46,chapter:        16,versenumber:        24,verse:"我在基督耶稣里的爱与你们众人同在。阿们！"},
{volumn:        47,chapter:         1,versenumber:         1,verse:"奉　神旨意作基督耶稣使徒的保罗和兄弟提摩太，写信给在哥林多　神的教会，并亚该亚遍处的众圣徒。"},
{volumn:        47,chapter:         1,versenumber:         2,verse:"愿恩惠、平安从　神我们的父和主耶稣基督归与你们！"},
{volumn:        47,chapter:         1,versenumber:         3,verse:"愿颂赞归与我们的主耶稣基督的父　神，就是发慈悲的父，赐各样安慰的　神。"},
{volumn:        47,chapter:         1,versenumber:         4,verse:"我们在一切患难中，他就安慰我们，叫我们能用　神所赐的安慰去安慰那遭各样患难的人。"},
{volumn:        47,chapter:         1,versenumber:         5,verse:"我们既多受基督的苦楚，就靠基督多得安慰。"},
{volumn:        47,chapter:         1,versenumber:         6,verse:"我们受患难呢，是为叫你们得安慰，得拯救；我们得安慰呢，也是为叫你们得安慰；这安慰能叫你们忍受我们所受的那样苦楚。"},
{volumn:        47,chapter:         1,versenumber:         7,verse:"我们为你们所存的盼望是确定的，因为知道你们既是同受苦楚，也必同得安慰。"},
{volumn:        47,chapter:         1,versenumber:         8,verse:"弟兄们，我们不要你们不晓得，我们从前在亚细亚遭遇苦难，被压太重，力不能胜，甚至连活命的指望都绝了；"},
{volumn:        47,chapter:         1,versenumber:         9,verse:"自己心里也断定是必死的，叫我们不靠自己，只靠叫死人复活的　神。"},
{volumn:        47,chapter:         1,versenumber:        10,verse:"他曾救我们脱离那极大的死亡，现在仍要救我们，并且我们指望他将来还要救我们。"},
{volumn:        47,chapter:         1,versenumber:        11,verse:"你们以祈祷帮助我们，好叫许多人为我们谢恩，就是为我们因许多人所得的恩。"},
{volumn:        47,chapter:         1,versenumber:        12,verse:"我们所夸的是自己的良心，见证我们凭着　神的圣洁和诚实；在世为人不靠人的聪明，乃靠　神的恩惠，向你们更是这样。"},
{volumn:        47,chapter:         1,versenumber:        13,verse:"我们现在写给你们的话，并不外乎你们所念的、所认识的，我也盼望你们到底还是要认识；"},
{volumn:        47,chapter:         1,versenumber:        14,verse:"正如你们已经有几分认识我们，以我们夸口，好像我们在我们主耶稣的日子以你们夸口一样。"},
{volumn:        47,chapter:         1,versenumber:        15,verse:"我既然这样深信，就早有意到你们那里去，叫你们再得益处；"},
{volumn:        47,chapter:         1,versenumber:        16,verse:"也要从你们那里经过，往马其顿去，再从马其顿回到你们那里，叫你们给我送行往犹太去。"},
{volumn:        47,chapter:         1,versenumber:        17,verse:"我有此意，岂是反复不定吗？我所起的意，岂是从情欲起的，叫我忽是忽非吗？"},
{volumn:        47,chapter:         1,versenumber:        18,verse:"我指着信实的　神说，我们向你们所传的道，并没有是而又非的。"},
{volumn:        47,chapter:         1,versenumber:        19,verse:"因为我和西拉并提摩太，在你们中间所传　神的儿子耶稣基督，总没有是而又非的，在他只有一是。"},
{volumn:        47,chapter:         1,versenumber:        20,verse:"神的应许，不论有多少，在基督都是是的。所以藉着他也都是实在（实在：原文是阿们）的，叫　神因我们得荣耀。"},
{volumn:        47,chapter:         1,versenumber:        21,verse:"那在基督里坚固我们和你们，并且膏我们的就是　神。"},
{volumn:        47,chapter:         1,versenumber:        22,verse:"他又用印印了我们，并赐圣灵在我们心里作凭据（原文是质）。"},
{volumn:        47,chapter:         1,versenumber:        23,verse:"我呼吁　神给我的心作见证，我没有往哥林多去是为要宽容你们。"},
{volumn:        47,chapter:         1,versenumber:        24,verse:"我们并不是辖管你们的信心，乃是帮助你们的快乐，因为你们凭信才站立得住。"},
{volumn:        47,chapter:         2,versenumber:         1,verse:"我自己定了主意再到你们那里去，必须大家没有忧愁。"},
{volumn:        47,chapter:         2,versenumber:         2,verse:"倘若我叫你们忧愁，除了我叫那忧愁的人以外，谁能叫我快乐呢？"},
{volumn:        47,chapter:         2,versenumber:         3,verse:"我曾把这事写给你们，恐怕我到的时候，应该叫我快乐的那些人，反倒叫我忧愁。我也深信，你们众人都以我的快乐为自己的快乐。"},
{volumn:        47,chapter:         2,versenumber:         4,verse:"我先前心里难过痛苦，多多地流泪，写信给你们，不是叫你们忧愁，乃是叫你们知道我格外地疼爱你们。"},
{volumn:        47,chapter:         2,versenumber:         5,verse:"若有叫人忧愁的，他不但叫我忧愁，也是叫你们众人有几分忧愁。我说几分，恐怕说得太重。"},
{volumn:        47,chapter:         2,versenumber:         6,verse:"这样的人受了众人的责罚也就够了，"},
{volumn:        47,chapter:         2,versenumber:         7,verse:"倒不如赦免他，安慰他，免得他忧愁太过，甚至沉沦了。"},
{volumn:        47,chapter:         2,versenumber:         8,verse:"所以我劝你们，要向他显出坚定不移的爱心来。"},
{volumn:        47,chapter:         2,versenumber:         9,verse:"为此我先前也写信给你们，要试验你们，看你们凡事顺从不顺从。"},
{volumn:        47,chapter:         2,versenumber:        10,verse:"你们赦免谁，我也赦免谁。我若有所赦免的，是在基督面前为你们赦免的，"},
{volumn:        47,chapter:         2,versenumber:        11,verse:"免得撒但趁着机会胜过我们，因我们并非不晓得他的诡计。"},
{volumn:        47,chapter:         2,versenumber:        12,verse:"我从前为基督的福音到了特罗亚，主也给我开了门。"},
{volumn:        47,chapter:         2,versenumber:        13,verse:"那时，因为没有遇见兄弟提多，我心里不安，便辞别那里的人往马其顿去了。"},
{volumn:        47,chapter:         2,versenumber:        14,verse:"感谢　神！常率领我们在基督里夸胜，并藉着我们在各处显扬那因认识基督而有的香气。"},
{volumn:        47,chapter:         2,versenumber:        15,verse:"因为我们在　神面前，无论在得救的人身上或灭亡的人身上，都有基督馨香之气。"},
{volumn:        47,chapter:         2,versenumber:        16,verse:"在这等人，就作了死的香气叫他死；在那等人，就作了活的香气叫他活。这事谁能当得起呢？"},
{volumn:        47,chapter:         2,versenumber:        17,verse:"我们不像那许多人，为利混乱　神的道；乃是由于诚实，由于　神，在　神面前凭着基督讲道。"},
{volumn:        47,chapter:         3,versenumber:         1,verse:"我们岂是又举荐自己吗？岂像别人用人的荐信给你们或用你们的荐信给人吗？"},
{volumn:        47,chapter:         3,versenumber:         2,verse:"你们就是我们的荐信，写在我们的心里，被众人所知道所念诵的。"},
{volumn:        47,chapter:         3,versenumber:         3,verse:"你们明显是基督的信，藉着我们修成的。不是用墨写的，乃是用永生　神的灵写的；不是写在石版上，乃是写在心版上。"},
{volumn:        47,chapter:         3,versenumber:         4,verse:"我们因基督，所以在　神面前才有这样的信心。"},
{volumn:        47,chapter:         3,versenumber:         5,verse:"并不是我们凭自己能承担什么事；我们所能承担的，乃是出于　神。"},
{volumn:        47,chapter:         3,versenumber:         6,verse:"他叫我们能承当这新约的执事，不是凭着字句，乃是凭着精意；因为那字句是叫人死，精意（或译：圣灵）是叫人活。"},
{volumn:        47,chapter:         3,versenumber:         7,verse:"那用字刻在石头上属死的职事尚且有荣光，甚至以色列人因摩西面上的荣光，不能定睛看他的脸；这荣光原是渐渐退去的，"},
{volumn:        47,chapter:         3,versenumber:         8,verse:"何况那属灵的职事岂不更有荣光吗？"},
{volumn:        47,chapter:         3,versenumber:         9,verse:"若是定罪的职事有荣光，那称义的职事荣光就越发大了。"},
{volumn:        47,chapter:         3,versenumber:        10,verse:"那从前有荣光的，因这极大的荣光就算不得有荣光了；"},
{volumn:        47,chapter:         3,versenumber:        11,verse:"若那废掉的有荣光，这长存的就更有荣光了。"},
{volumn:        47,chapter:         3,versenumber:        12,verse:"我们既有这样的盼望，就大胆讲说，"},
{volumn:        47,chapter:         3,versenumber:        13,verse:"不像摩西将帕子蒙在脸上，叫以色列人不能定睛看到那将废者的结局。"},
{volumn:        47,chapter:         3,versenumber:        14,verse:"但他们的心地刚硬，直到今日诵读旧约的时候，这帕子还没有揭去。这帕子在基督里已经废去了。"},
{volumn:        47,chapter:         3,versenumber:        15,verse:"然而直到今日，每逢诵读摩西书的时候，帕子还在他们心上。"},
{volumn:        47,chapter:         3,versenumber:        16,verse:"但他们的心几时归向主，帕子就几时除去了。"},
{volumn:        47,chapter:         3,versenumber:        17,verse:"主就是那灵；主的灵在哪里，那里就得以自由。"},
{volumn:        47,chapter:         3,versenumber:        18,verse:"我们众人既然敞着脸得以看见主的荣光，好像从镜子里返照，就变成主的形状，荣上加荣，如同从主的灵变成的。"},
{volumn:        47,chapter:         4,versenumber:         1,verse:"我们既然蒙怜悯，受了这职分，就不丧胆，"},
{volumn:        47,chapter:         4,versenumber:         2,verse:"乃将那些暗昧可耻的事弃绝了；不行诡诈，不谬讲　神的道理，只将真理表明出来，好在　神面前把自己荐与各人的良心。"},
{volumn:        47,chapter:         4,versenumber:         3,verse:"如果我们的福音蒙蔽，就是蒙蔽在灭亡的人身上。"},
{volumn:        47,chapter:         4,versenumber:         4,verse:"此等不信之人被这世界的神弄瞎了心眼，不叫基督荣耀福音的光照着他们。基督本是　神的像。"},
{volumn:        47,chapter:         4,versenumber:         5,verse:"我们原不是传自己，乃是传基督耶稣为主，并且自己因耶稣作你们的仆人。"},
{volumn:        47,chapter:         4,versenumber:         6,verse:"那吩咐光从黑暗里照出来的　神，已经照在我们心里，叫我们得知　神荣耀的光显在耶稣基督的面上。"},
{volumn:        47,chapter:         4,versenumber:         7,verse:"我们有这宝贝放在瓦器里，要显明这莫大的能力是出于　神，不是出于我们。"},
{volumn:        47,chapter:         4,versenumber:         8,verse:"我们四面受敌，却不被困住；心里作难，却不至失望；"},
{volumn:        47,chapter:         4,versenumber:         9,verse:"遭逼迫，却不被丢弃；打倒了，却不至死亡。"},
{volumn:        47,chapter:         4,versenumber:        10,verse:"身上常带着耶稣的死，使耶稣的生也显明在我们身上。"},
{volumn:        47,chapter:         4,versenumber:        11,verse:"因为我们这活着的人是常为耶稣被交于死地，使耶稣的生在我们这必死的身上显明出来。"},
{volumn:        47,chapter:         4,versenumber:        12,verse:"这样看来，死是在我们身上发动，生却在你们身上发动。"},
{volumn:        47,chapter:         4,versenumber:        13,verse:"但我们既有信心，正如经上记着说：“我因信，所以如此说话。”我们也信，所以也说话。"},
{volumn:        47,chapter:         4,versenumber:        14,verse:"自己知道那叫主耶稣复活的，也必叫我们与耶稣一同复活，并且叫我们与你们一同站在他面前。"},
{volumn:        47,chapter:         4,versenumber:        15,verse:"凡事都是为你们，好叫恩惠因人多越发加增，感谢格外显多，以致荣耀归与　神。"},
{volumn:        47,chapter:         4,versenumber:        16,verse:"所以，我们不丧胆。外体虽然毁坏，内心却一天新似一天。"},
{volumn:        47,chapter:         4,versenumber:        17,verse:"我们这至暂至轻的苦楚，要为我们成就极重无比、永远的荣耀。"},
{volumn:        47,chapter:         4,versenumber:        18,verse:"原来我们不是顾念所见的，乃是顾念所不见的；因为所见的是暂时的，所不见的是永远的。"},
{volumn:        47,chapter:         5,versenumber:         1,verse:"我们原知道，我们这地上的帐棚若拆毁了，必得　神所造，不是人手所造，在天上永存的房屋。"},
{volumn:        47,chapter:         5,versenumber:         2,verse:"我们在这帐棚里叹息，深想得那从天上来的房屋，好像穿上衣服；"},
{volumn:        47,chapter:         5,versenumber:         3,verse:"倘若穿上，被遇见的时候就不至于赤身了。"},
{volumn:        47,chapter:         5,versenumber:         4,verse:"我们在这帐棚里叹息劳苦，并非愿意脱下这个，乃是愿意穿上那个，好叫这必死的被生命吞灭了。"},
{volumn:        47,chapter:         5,versenumber:         5,verse:"为此，培植我们的就是　神，他又赐给我们圣灵作凭据（原文是质）。"},
{volumn:        47,chapter:         5,versenumber:         6,verse:"所以，我们时常坦然无惧，并且晓得我们住在身内，便与主相离。"},
{volumn:        47,chapter:         5,versenumber:         7,verse:"因我们行事为人是凭着信心，不是凭着眼见。"},
{volumn:        47,chapter:         5,versenumber:         8,verse:"我们坦然无惧，是更愿意离开身体与主同住。"},
{volumn:        47,chapter:         5,versenumber:         9,verse:"所以，无论是住在身内，离开身外，我们立了志向，要得主的喜悦。"},
{volumn:        47,chapter:         5,versenumber:        10,verse:"因为我们众人必要在基督台前显露出来，叫各人按着本身所行的，或善或恶受报。"},
{volumn:        47,chapter:         5,versenumber:        11,verse:"我们既知道主是可畏的，所以劝人。但我们在　神面前是显明的，盼望在你们的良心里也是显明的。"},
{volumn:        47,chapter:         5,versenumber:        12,verse:"我们不是向你们再举荐自己，乃是叫你们因我们有可夸之处，好对那凭外貌不凭内心夸口的人，有言可答。"},
{volumn:        47,chapter:         5,versenumber:        13,verse:"我们若果颠狂，是为　神；若果谨守，是为你们。"},
{volumn:        47,chapter:         5,versenumber:        14,verse:"原来基督的爱激励我们；因我们想，一人既替众人死，众人就都死了；"},
{volumn:        47,chapter:         5,versenumber:        15,verse:"并且他替众人死，是叫那些活着的人不再为自己活，乃为替他们死而复活的主活。"},
{volumn:        47,chapter:         5,versenumber:        16,verse:"所以，我们从今以后，不凭着外貌（原文是肉体；本节同）认人了。虽然凭着外貌认过基督，如今却不再这样认他了。"},
{volumn:        47,chapter:         5,versenumber:        17,verse:"若有人在基督里，他就是新造的人，旧事已过，都变成新的了。"},
{volumn:        47,chapter:         5,versenumber:        18,verse:"一切都是出于　神；他藉着基督使我们与他和好，又将劝人与他和好的职分赐给我们。"},
{volumn:        47,chapter:         5,versenumber:        19,verse:"这就是　神在基督里，叫世人与自己和好，不将他们的过犯归到他们身上，并且将这和好的道理托付了我们。"},
{volumn:        47,chapter:         5,versenumber:        20,verse:"所以，我们作基督的使者，就好像神藉我们劝你们一般。我们替基督求你们与　神和好。"},
{volumn:        47,chapter:         5,versenumber:        21,verse:"神使那无罪（无罪：原文是不知罪）的，替我们成为罪，好叫我们在他里面成为　神的义。"},
{volumn:        47,chapter:         6,versenumber:         1,verse:"我们与　神同工的，也劝你们不可徒受他的恩典。"},
{volumn:        47,chapter:         6,versenumber:         2,verse:"因为他说：“在悦纳的时候，我应允了你；在拯救的日子，我搭救了你。”看哪，现在正是悦纳的时候！现在正是拯救的日子；"},
{volumn:        47,chapter:         6,versenumber:         3,verse:"我们凡事都不叫人有妨碍，免得这职分被人毁谤；"},
{volumn:        47,chapter:         6,versenumber:         4,verse:"反倒在各样的事上表明自己是　神的用人，就如在许多的忍耐、患难、穷乏、困苦、"},
{volumn:        47,chapter:         6,versenumber:         5,verse:"鞭打、监禁、扰乱、勤劳、警醒、不食、"},
{volumn:        47,chapter:         6,versenumber:         6,verse:"廉洁、知识、恒忍、恩慈、圣灵的感化、无伪的爱心、"},
{volumn:        47,chapter:         6,versenumber:         7,verse:"真实的道理、　神的大能；仁义的兵器在左在右；"},
{volumn:        47,chapter:         6,versenumber:         8,verse:"荣耀、羞辱，恶名、美名；似乎是诱惑人的，却是诚实的；"},
{volumn:        47,chapter:         6,versenumber:         9,verse:"似乎不为人所知，却是人所共知的；似乎要死，却是活着的；似乎受责罚，却是不至丧命的；"},
{volumn:        47,chapter:         6,versenumber:        10,verse:"似乎忧愁，却是常常快乐的；似乎贫穷，却是叫许多人富足的；似乎一无所有，却是样样都有的。"},
{volumn:        47,chapter:         6,versenumber:        11,verse:"哥林多人哪，我们向你们，口是张开的，心是宽宏的。"},
{volumn:        47,chapter:         6,versenumber:        12,verse:"你们狭窄，原不在乎我们，是在乎自己的心肠狭窄。"},
{volumn:        47,chapter:         6,versenumber:        13,verse:"你们也要照样用宽宏的心报答我。我这话正像对自己的孩子说的。"},
{volumn:        47,chapter:         6,versenumber:        14,verse:"你们和不信的原不相配，不要同负一轭。义和不义有什么相交呢？光明和黑暗有什么相通呢？"},
{volumn:        47,chapter:         6,versenumber:        15,verse:"基督和彼列（彼列就是撒但的别名）有什么相和呢？信主的和不信主的有什么相干呢？"},
{volumn:        47,chapter:         6,versenumber:        16,verse:"神的殿和偶像有什么相同呢？因为我们是永生　神的殿，就如　神曾说：“我要在他们中间居住，在他们中间来往；我要作他们的　神；他们要作我的子民。”"},
{volumn:        47,chapter:         6,versenumber:        17,verse:"又说：“你们务要从他们中间出来，与他们分别；不要沾不洁净的物，我就收纳你们。"},
{volumn:        47,chapter:         6,versenumber:        18,verse:"我要作你们的父；你们要作我的儿女。”这是全能的主说的。"},
{volumn:        47,chapter:         7,versenumber:         1,verse:"亲爱的弟兄啊，我们既有这等应许，就当洁净自己，除去身体、灵魂一切的污秽，敬畏　神，得以成圣。"},
{volumn:        47,chapter:         7,versenumber:         2,verse:"你们要心地宽大收纳我们。我们未曾亏负谁，未曾败坏谁，未曾占谁的便宜。"},
{volumn:        47,chapter:         7,versenumber:         3,verse:"我说这话，不是要定你们的罪。我已经说过，你们常在我们心里，情愿与你们同生同死。"},
{volumn:        47,chapter:         7,versenumber:         4,verse:"我大大地放胆，向你们说话；我因你们多多夸口，满得安慰；我们在一切患难中分外的快乐。"},
{volumn:        47,chapter:         7,versenumber:         5,verse:"我们从前就是到了马其顿的时候，身体也不得安宁，周围遭患难，外有争战，内有惧怕。"},
{volumn:        47,chapter:         7,versenumber:         6,verse:"但那安慰丧气之人的　神藉着提多来安慰了我们；"},
{volumn:        47,chapter:         7,versenumber:         7,verse:"不但藉着他来，也藉着他从你们所得的安慰，安慰了我们；因他把你们的想念、哀恸，和向我的热心，都告诉了我，叫我更加欢喜。"},
{volumn:        47,chapter:         7,versenumber:         8,verse:"我先前写信叫你们忧愁，我后来虽然懊悔，如今却不懊悔；因我知道，那信叫你们忧愁不过是暂时的。"},
{volumn:        47,chapter:         7,versenumber:         9,verse:"如今我欢喜，不是因你们忧愁，是因你们从忧愁中生出懊悔来。你们依着　神的意思忧愁，凡事就不至于因我们受亏损了。"},
{volumn:        47,chapter:         7,versenumber:        10,verse:"因为依着　神的意思忧愁，就生出没有后悔的懊悔来，以致得救；但世俗的忧愁是叫人死。"},
{volumn:        47,chapter:         7,versenumber:        11,verse:"你看，你们依着　神的意思忧愁，从此就生出何等的殷勤、自诉、自恨、恐惧、想念、热心、责罚（或译：自责）。在这一切事上，你们都表明自己是洁净的。"},
{volumn:        47,chapter:         7,versenumber:        12,verse:"我虽然从前写信给你们，却不是为那亏负人的，也不是为那受人亏负的，乃要在　神面前把你们顾念我们的热心表明出来。"},
{volumn:        47,chapter:         7,versenumber:        13,verse:"故此，我们得了安慰。并且在安慰之中，因你们众人使提多心里畅快欢喜，我们就更加欢喜了。"},
{volumn:        47,chapter:         7,versenumber:        14,verse:"我若对提多夸奖了你们什么，也觉得没有惭愧；因我对提多夸奖你们的话成了真的，正如我对你们所说的话也都是真的。"},
{volumn:        47,chapter:         7,versenumber:        15,verse:"并且提多想起你们众人的顺服，是怎样恐惧战兢地接待他，他爱你们的心肠就越发热了。"},
{volumn:        47,chapter:         7,versenumber:        16,verse:"我如今欢喜，能在凡事上为你们放心。"},
{volumn:        47,chapter:         8,versenumber:         1,verse:"弟兄们，我把　神赐给马其顿众教会的恩告诉你们，"},
{volumn:        47,chapter:         8,versenumber:         2,verse:"就是他们在患难中受大试炼的时候，仍有满足的快乐，在极穷之间还格外显出他们乐捐的厚恩。"},
{volumn:        47,chapter:         8,versenumber:         3,verse:"我可以证明，他们是按着力量，而且也过了力量，自己甘心乐意地捐助，"},
{volumn:        47,chapter:         8,versenumber:         4,verse:"再三地求我们，准他们在这供给圣徒的恩情上有份；"},
{volumn:        47,chapter:         8,versenumber:         5,verse:"并且他们所做的，不但照我们所想望的，更照　神的旨意先把自己献给主，又归附了我们。"},
{volumn:        47,chapter:         8,versenumber:         6,verse:"因此我劝提多，既然在你们中间开办这慈惠的事，就当办成了。"},
{volumn:        47,chapter:         8,versenumber:         7,verse:"你们既然在信心、口才、知识、热心，和待我们的爱心上，都格外显出满足来，就当在这慈惠的事上也格外显出满足来。"},
{volumn:        47,chapter:         8,versenumber:         8,verse:"我说这话，不是吩咐你们，乃是藉着别人的热心试验你们爱心的实在。"},
{volumn:        47,chapter:         8,versenumber:         9,verse:"你们知道我们主耶稣基督的恩典：他本来富足，却为你们成了贫穷，叫你们因他的贫穷，可以成为富足。"},
{volumn:        47,chapter:         8,versenumber:        10,verse:"我在这事上把我的意见告诉你们，是与你们有益；因为你们下手办这事，而且起此心意，已经有一年了，"},
{volumn:        47,chapter:         8,versenumber:        11,verse:"如今就当办成这事。既有愿做的心，也当照你们所有的去办成。"},
{volumn:        47,chapter:         8,versenumber:        12,verse:"因为人若有愿做的心，必蒙悦纳，乃是照他所有的，并不是照他所无的。"},
{volumn:        47,chapter:         8,versenumber:        13,verse:"我原不是要别人轻省，你们受累，"},
{volumn:        47,chapter:         8,versenumber:        14,verse:"乃要均平，就是要你们的富余，现在可以补他们的不足，使他们的富余，将来也可以补你们的不足，这就均平了。"},
{volumn:        47,chapter:         8,versenumber:        15,verse:"如经上所记：“多收的也没有余；少收的也没有缺。”"},
{volumn:        47,chapter:         8,versenumber:        16,verse:"多谢　神，感动提多的心，叫他待你们殷勤，像我一样。"},
{volumn:        47,chapter:         8,versenumber:        17,verse:"他固然是听了我的劝，但自己更是热心，情愿往你们那里去。"},
{volumn:        47,chapter:         8,versenumber:        18,verse:"我们还打发一位兄弟和他同去，这人在福音上得了众教会的称赞。"},
{volumn:        47,chapter:         8,versenumber:        19,verse:"不但这样，他也被众教会挑选，和我们同行，把所托与我们的这捐资送到了，可以荣耀主，又表明我们乐意的心。"},
{volumn:        47,chapter:         8,versenumber:        20,verse:"这就免得有人因我们收的捐银很多，就挑我们的不是。"},
{volumn:        47,chapter:         8,versenumber:        21,verse:"我们留心行光明的事，不但在主面前，就在人面前也是这样。"},
{volumn:        47,chapter:         8,versenumber:        22,verse:"我们又打发一位兄弟同去；这人的热心，我们在许多事上屡次试验过。现在他因为深信你们，就更加热心了。"},
{volumn:        47,chapter:         8,versenumber:        23,verse:"论到提多，他是我的同伴，一同为你们劳碌的。论到那两位兄弟，他们是众教会的使者，是基督的荣耀。"},
{volumn:        47,chapter:         8,versenumber:        24,verse:"所以，你们务要在众教会面前显明你们爱心的凭据，并我所夸奖你们的凭据。"},
{volumn:        47,chapter:         9,versenumber:         1,verse:"论到供给圣徒的事，我不必写信给你们；"},
{volumn:        47,chapter:         9,versenumber:         2,verse:"因为我知道你们乐意的心，常对马其顿人夸奖你们，说亚该亚人预备好了，已经有一年了；并且你们的热心激动了许多人。"},
{volumn:        47,chapter:         9,versenumber:         3,verse:"但我打发那几位弟兄去，要叫你们照我的话预备妥当，免得我们在这事上夸奖你们的话落了空。"},
{volumn:        47,chapter:         9,versenumber:         4,verse:"万一有马其顿人与我同去，见你们没有预备，就叫我们所确信的，反成了羞愧；你们羞愧，更不用说了。"},
{volumn:        47,chapter:         9,versenumber:         5,verse:"因此，我想不得不求那几位弟兄先到你们那里去，把从前所应许的捐资预备妥当，就显出你们所捐的是出于乐意，不是出于勉强。"},
{volumn:        47,chapter:         9,versenumber:         6,verse:"“少种的少收，多种的多收”，这话是真的。"},
{volumn:        47,chapter:         9,versenumber:         7,verse:"各人要随本心所酌定的，不要作难，不要勉强，因为捐得乐意的人是　神所喜爱的。"},
{volumn:        47,chapter:         9,versenumber:         8,verse:"神能将各样的恩惠多多地加给你们，使你们凡事常常充足，能多行各样善事。"},
{volumn:        47,chapter:         9,versenumber:         9,verse:"如经上所记：“他施舍钱财，周济贫穷；他的仁义存到永远。”"},
{volumn:        47,chapter:         9,versenumber:        10,verse:"那赐种给撒种的，赐粮给人吃的，必多多加给你们种地的种子，又增添你们仁义的果子；"},
{volumn:        47,chapter:         9,versenumber:        11,verse:"叫你们凡事富足，可以多多施舍，就藉着我们使感谢归于　神。"},
{volumn:        47,chapter:         9,versenumber:        12,verse:"因为办这供给的事，不但补圣徒的缺乏，而且叫许多人越发感谢　神。"},
{volumn:        47,chapter:         9,versenumber:        13,verse:"他们从这供给的事上得了凭据，知道你们承认基督顺服他的福音，多多地捐钱给他们和众人，便将荣耀归与　神。"},
{volumn:        47,chapter:         9,versenumber:        14,verse:"他们也因　神极大的恩赐显在你们心里，就切切地想念你们，为你们祈祷。"},
{volumn:        47,chapter:         9,versenumber:        15,verse:"感谢　神，因他有说不尽的恩赐！"},
{volumn:        47,chapter:        10,versenumber:         1,verse:"我保罗，就是与你们见面的时候是谦卑的，不在你们那里的时候向你们是勇敢的，如今亲自藉着基督的温柔、和平劝你们。"},
{volumn:        47,chapter:        10,versenumber:         2,verse:"有人以为我是凭着血气行事，我也以为必须用勇敢待这等人；求你们不要叫我在你们那里的时候，有这样的勇敢。"},
{volumn:        47,chapter:        10,versenumber:         3,verse:"因为我们虽然在血气中行事，却不凭着血气争战。"},
{volumn:        47,chapter:        10,versenumber:         4,verse:"我们争战的兵器本不是属血气的，乃是在　神面前有能力，可以攻破坚固的营垒，"},
{volumn:        47,chapter:        10,versenumber:         5,verse:"将各样的计谋，各样拦阻人认识　神的那些自高之事，一概攻破了，又将人所有的心意夺回，使他都顺服基督。"},
{volumn:        47,chapter:        10,versenumber:         6,verse:"并且我已经预备好了，等你们十分顺服的时候，要责罚那一切不顺服的人。"},
{volumn:        47,chapter:        10,versenumber:         7,verse:"你们是看眼前的吗？倘若有人自信是属基督的，他要再想想，他如何属基督，我们也是如何属基督的。"},
{volumn:        47,chapter:        10,versenumber:         8,verse:"主赐给我们权柄，是要造就你们，并不是要败坏你们；我就是为这权柄稍微夸口，也不至于惭愧。"},
{volumn:        47,chapter:        10,versenumber:         9,verse:"我说这话，免得你们以为我写信是要威吓你们；"},
{volumn:        47,chapter:        10,versenumber:        10,verse:"因为有人说：“他的信又沉重又厉害，及至见面，却是气貌不扬，言语粗俗的。”"},
{volumn:        47,chapter:        10,versenumber:        11,verse:"这等人当想，我们不在那里的时候，信上的言语如何，见面的时候，行事也必如何。"},
{volumn:        47,chapter:        10,versenumber:        12,verse:"因为我们不敢将自己和那自荐的人同列相比。他们用自己度量自己，用自己比较自己，乃是不通达的。"},
{volumn:        47,chapter:        10,versenumber:        13,verse:"我们不愿意分外夸口，只要照　神所量给我们的界限够到你们那里。"},
{volumn:        47,chapter:        10,versenumber:        14,verse:"我们并非过了自己的界限，好像够不到你们那里；因为我们早到你们那里，传了基督的福音。"},
{volumn:        47,chapter:        10,versenumber:        15,verse:"我们不仗着别人所劳碌的，分外夸口；但指望你们信心增长的时候，所量给我们的界限，就可以因着你们更加开展，"},
{volumn:        47,chapter:        10,versenumber:        16,verse:"得以将福音传到你们以外的地方；并不是在别人界限之内，藉着他现成的事夸口。"},
{volumn:        47,chapter:        10,versenumber:        17,verse:"但夸口的，当指着主夸口。"},
{volumn:        47,chapter:        10,versenumber:        18,verse:"因为蒙悦纳的，不是自己称许的，乃是主所称许的。"},
{volumn:        47,chapter:        11,versenumber:         1,verse:"但愿你们宽容我这一点愚妄，其实你们原是宽容我的。"},
{volumn:        47,chapter:        11,versenumber:         2,verse:"我为你们起的愤恨，原是　神那样的愤恨。因为我曾把你们许配一个丈夫，要把你们如同贞洁的童女，献给基督。"},
{volumn:        47,chapter:        11,versenumber:         3,verse:"我只怕你们的心或偏于邪，失去那向基督所存纯一清洁的心，就像蛇用诡诈诱惑了夏娃一样。"},
{volumn:        47,chapter:        11,versenumber:         4,verse:"假如有人来另传一个耶稣，不是我们所传过的；或者你们另受一个灵，不是你们所受过的；或者另得一个福音，不是你们所得过的；你们容让他也就罢了。"},
{volumn:        47,chapter:        11,versenumber:         5,verse:"但我想，我一点不在那些最大的使徒以下。"},
{volumn:        47,chapter:        11,versenumber:         6,verse:"我的言语虽然粗俗，我的知识却不粗俗。这是我们在凡事上向你们众人显明出来的。"},
{volumn:        47,chapter:        11,versenumber:         7,verse:"我因为白白传　神的福音给你们，就自居卑微，叫你们高升，这算是我犯罪吗？"},
{volumn:        47,chapter:        11,versenumber:         8,verse:"我亏负了别的教会，向他们取了工价来给你们效力。"},
{volumn:        47,chapter:        11,versenumber:         9,verse:"我在你们那里缺乏的时候，并没有累着你们一个人；因我所缺乏的，那从马其顿来的弟兄们都补足了。我向来凡事谨守，后来也必谨守，总不至于累着你们。"},
{volumn:        47,chapter:        11,versenumber:        10,verse:"既有基督的诚实在我里面，就无人能在亚该亚一带地方阻挡我这自夸。"},
{volumn:        47,chapter:        11,versenumber:        11,verse:"为什么呢？是因我不爱你们吗？这有　神知道。"},
{volumn:        47,chapter:        11,versenumber:        12,verse:"我现在所做的，后来还要做，为要断绝那些寻机会人的机会，使他们在所夸的事上也不过与我们一样。"},
{volumn:        47,chapter:        11,versenumber:        13,verse:"那等人是假使徒，行事诡诈，装作基督使徒的模样。"},
{volumn:        47,chapter:        11,versenumber:        14,verse:"这也不足为怪，因为连撒但也装作光明的天使。"},
{volumn:        47,chapter:        11,versenumber:        15,verse:"所以他的差役，若装作仁义的差役，也不算希奇。他们的结局必然照着他们的行为。"},
{volumn:        47,chapter:        11,versenumber:        16,verse:"我再说，人不可把我看作愚妄的。纵然如此，也要把我当作愚妄人接纳，叫我可以略略自夸。"},
{volumn:        47,chapter:        11,versenumber:        17,verse:"我说的话不是奉主命说的，乃是像愚妄人放胆自夸；"},
{volumn:        47,chapter:        11,versenumber:        18,verse:"既有好些人凭着血气自夸，我也要自夸了。"},
{volumn:        47,chapter:        11,versenumber:        19,verse:"你们既是精明人，就能甘心忍耐愚妄人。"},
{volumn:        47,chapter:        11,versenumber:        20,verse:"假若有人强你们作奴仆，或侵吞你们，或掳掠你们，或侮慢你们，或打你们的脸，你们都能忍耐他。"},
{volumn:        47,chapter:        11,versenumber:        21,verse:"我说这话是羞辱自己，好像我们从前是软弱的。然而，人在何事上勇敢，（我说句愚妄话，）我也勇敢。"},
{volumn:        47,chapter:        11,versenumber:        22,verse:"他们是希伯来人吗？我也是。他们是以色列人吗？我也是。他们是亚伯拉罕的后裔吗？我也是。"},
{volumn:        47,chapter:        11,versenumber:        23,verse:"他们是基督的仆人吗？（我说句狂话，）我更是。我比他们多受劳苦，多下监牢，受鞭打是过重的，冒死是屡次有的。"},
{volumn:        47,chapter:        11,versenumber:        24,verse:"被犹太人鞭打五次，每次四十减去一下；"},
{volumn:        47,chapter:        11,versenumber:        25,verse:"被棍打了三次；被石头打了一次；遇着船坏三次，一昼一夜在深海里。"},
{volumn:        47,chapter:        11,versenumber:        26,verse:"又屡次行远路，遭江河的危险、盗贼的危险、同族的危险、外邦人的危险、城里的危险、旷野的危险、海中的危险、假弟兄的危险。"},
{volumn:        47,chapter:        11,versenumber:        27,verse:"受劳碌、受困苦，多次不得睡，又饥又渴，多次不得食，受寒冷，赤身露体。"},
{volumn:        47,chapter:        11,versenumber:        28,verse:"除了这外面的事，还有为众教会挂心的事，天天压在我身上。"},
{volumn:        47,chapter:        11,versenumber:        29,verse:"有谁软弱，我不软弱呢？有谁跌倒，我不焦急呢？"},
{volumn:        47,chapter:        11,versenumber:        30,verse:"我若必须自夸，就夸那关乎我软弱的事便了。"},
{volumn:        47,chapter:        11,versenumber:        31,verse:"那永远可称颂之主耶稣的父　神知道我不说谎。"},
{volumn:        47,chapter:        11,versenumber:        32,verse:"在大马士革的亚哩达王手下的提督把守大马士革城，要捉拿我，"},
{volumn:        47,chapter:        11,versenumber:        33,verse:"我就从窗户中，在筐子里，从城墙上被人缒下去，脱离了他的手。"},
{volumn:        47,chapter:        12,versenumber:         1,verse:"我自夸固然无益，但我是不得已的。如今我要说到主的显现和启示。"},
{volumn:        47,chapter:        12,versenumber:         2,verse:"我认得一个在基督里的人，他前十四年被提到第三层天上去；（或在身内，我不知道；或在身外，我也不知道；只有　神知道。）"},
{volumn:        47,chapter:        12,versenumber:         3,verse:"我认得这人；（或在身内，或在身外，我都不知道，只有　神知道。）"},
{volumn:        47,chapter:        12,versenumber:         4,verse:"他被提到乐园里，听见隐秘的言语，是人不可说的。"},
{volumn:        47,chapter:        12,versenumber:         5,verse:"为这人，我要夸口；但是为我自己，除了我的软弱以外，我并不夸口。"},
{volumn:        47,chapter:        12,versenumber:         6,verse:"我就是愿意夸口也不算狂，因为我必说实话；只是我禁止不说，恐怕有人把我看高了，过于他在我身上所看见所听见的。"},
{volumn:        47,chapter:        12,versenumber:         7,verse:"又恐怕我因所得的启示甚大，就过于自高，所以有一根刺加在我肉体上，就是撒但的差役要攻击我，免得我过于自高。"},
{volumn:        47,chapter:        12,versenumber:         8,verse:"为这事，我三次求过主，叫这刺离开我。"},
{volumn:        47,chapter:        12,versenumber:         9,verse:"他对我说：“我的恩典够你用的，因为我的能力是在人的软弱上显得完全。”所以，我更喜欢夸自己的软弱，好叫基督的能力覆庇我。"},
{volumn:        47,chapter:        12,versenumber:        10,verse:"我为基督的缘故，就以软弱、凌辱、急难、逼迫、困苦为可喜乐的；因我什么时候软弱，什么时候就刚强了。"},
{volumn:        47,chapter:        12,versenumber:        11,verse:"我成了愚妄人，是被你们强逼的。我本该被你们称许才是。我虽算不了什么，却没有一件事在那些最大的使徒以下。"},
{volumn:        47,chapter:        12,versenumber:        12,verse:"我在你们中间，用百般的忍耐，藉着神迹、奇事、异能显出使徒的凭据来。"},
{volumn:        47,chapter:        12,versenumber:        13,verse:"除了我不累着你们这一件事，你们还有什么事不及别的教会呢？这不公之处，求你们饶恕我吧。"},
{volumn:        47,chapter:        12,versenumber:        14,verse:"如今，我打算第三次到你们那里去，也必不累着你们；因我所求的是你们，不是你们的财物。儿女不该为父母积财，父母该为儿女积财。"},
{volumn:        47,chapter:        12,versenumber:        15,verse:"我也甘心乐意为你们的灵魂费财费力。难道我越发爱你们，就越发少得你们的爱吗？"},
{volumn:        47,chapter:        12,versenumber:        16,verse:"罢了，我自己并没有累着你们，你们却有人说，我是诡诈，用心计牢笼你们。"},
{volumn:        47,chapter:        12,versenumber:        17,verse:"我所差到你们那里去的人，我藉着他们一个人占过你们的便宜吗？"},
{volumn:        47,chapter:        12,versenumber:        18,verse:"我劝了提多到你们那里去，又差那位兄弟与他同去。提多占过你们的便宜吗？我们行事，不同是一个心灵（或译：圣灵）吗？不同是一个脚踪吗？"},
{volumn:        47,chapter:        12,versenumber:        19,verse:"你们到如今，还想我们是向你们分诉；我们本是在基督里当　神面前说话。亲爱的弟兄啊，一切的事都是为造就你们。"},
{volumn:        47,chapter:        12,versenumber:        20,verse:"我怕我再来的时候，见你们不合我所想望的，你们见我也不合你们所想望的；又怕有纷争、嫉妒、恼怒、结党、毁谤、谗言、狂傲、混乱的事。"},
{volumn:        47,chapter:        12,versenumber:        21,verse:"且怕我来的时候，我的　神叫我在你们面前惭愧，又因许多人从前犯罪，行污秽、奸淫、邪荡的事不肯悔改，我就忧愁。"},
{volumn:        47,chapter:        13,versenumber:         1,verse:"这是我第三次要到你们那里去。“凭两三个人的口作见证，句句都要定准。”"},
{volumn:        47,chapter:        13,versenumber:         2,verse:"我从前说过，如今不在你们那里又说，正如我第二次见你们的时候所说的一样，就是对那犯了罪的和其余的人说：“我若再来，必不宽容。”"},
{volumn:        47,chapter:        13,versenumber:         3,verse:"你们既然寻求基督在我里面说话的凭据，我必不宽容。因为，基督在你们身上不是软弱的，在你们里面是有大能的。"},
{volumn:        47,chapter:        13,versenumber:         4,verse:"他因软弱被钉在十字架上，却因　神的大能仍然活着。我们也是这样同他软弱，但因　神向你们所显的大能，也必与他同活。"},
{volumn:        47,chapter:        13,versenumber:         5,verse:"你们总要自己省察有信心没有，也要自己试验。岂不知你们若不是可弃绝的，就有耶稣基督在你们心里吗？"},
{volumn:        47,chapter:        13,versenumber:         6,verse:"我却盼望你们晓得，我们不是可弃绝的人。"},
{volumn:        47,chapter:        13,versenumber:         7,verse:"我们求　神，叫你们一件恶事都不做；这不是要显明我们是蒙悦纳的，是要你们行事端正，任凭人看我们是被弃绝的吧！"},
{volumn:        47,chapter:        13,versenumber:         8,verse:"我们凡事不能敌挡真理，只能扶助真理。"},
{volumn:        47,chapter:        13,versenumber:         9,verse:"即便我们软弱，你们刚强，我们也欢喜；并且我们所求的，就是你们作完全人。"},
{volumn:        47,chapter:        13,versenumber:        10,verse:"所以，我不在你们那里的时候，把这话写给你们，好叫我见你们的时候，不用照主所给我的权柄严厉地待你们。这权柄原是为造就人，并不是为败坏人。"},
{volumn:        47,chapter:        13,versenumber:        11,verse:"还有末了的话：愿弟兄们都喜乐。要作完全人；要受安慰；要同心合意；要彼此和睦。如此，仁爱和平的　神必常与你们同在。"},
{volumn:        47,chapter:        13,versenumber:        12,verse:"你们亲嘴问安，彼此务要圣洁。"},
{volumn:        47,chapter:        13,versenumber:        13,verse:"众圣徒都问你们安。"},
{volumn:        47,chapter:        13,versenumber:        14,verse:"愿主耶稣基督的恩惠、　神的慈爱、圣灵的感动常与你们众人同在！"},
{volumn:        48,chapter:         1,versenumber:         1,verse:"作使徒的保罗（不是由于人，也不是藉着人，乃是藉着耶稣基督，与叫他从死里复活的父　神）"},
{volumn:        48,chapter:         1,versenumber:         2,verse:"和一切与我同在的众弟兄，写信给加拉太的各教会。"},
{volumn:        48,chapter:         1,versenumber:         3,verse:"愿恩惠、平安从父　神与我们的主耶稣基督归与你们！"},
{volumn:        48,chapter:         1,versenumber:         4,verse:"基督照我们父　神的旨意，为我们的罪舍己，要救我们脱离这罪恶的世代。"},
{volumn:        48,chapter:         1,versenumber:         5,verse:"但愿荣耀归于　神，直到永永远远。阿们！"},
{volumn:        48,chapter:         1,versenumber:         6,verse:"我希奇你们这么快离开那藉着基督之恩召你们的，去从别的福音。"},
{volumn:        48,chapter:         1,versenumber:         7,verse:"那并不是福音，不过有些人搅扰你们，要把基督的福音更改了。"},
{volumn:        48,chapter:         1,versenumber:         8,verse:"但无论是我们，是天上来的使者，若传福音给你们，与我们所传给你们的不同，他就应当被咒诅。"},
{volumn:        48,chapter:         1,versenumber:         9,verse:"我们已经说了，现在又说，若有人传福音给你们，与你们所领受的不同，他就应当被咒诅。"},
{volumn:        48,chapter:         1,versenumber:        10,verse:"我现在是要得人的心呢？还是要得　神的心呢？我岂是讨人的喜欢吗？若仍旧讨人的喜欢，我就不是基督的仆人了。"},
{volumn:        48,chapter:         1,versenumber:        11,verse:"弟兄们，我告诉你们，我素来所传的福音不是出于人的意思。"},
{volumn:        48,chapter:         1,versenumber:        12,verse:"因为我不是从人领受的，也不是人教导我的，乃是从耶稣基督启示来的。"},
{volumn:        48,chapter:         1,versenumber:        13,verse:"你们听见我从前在犹太教中所行的事，怎样极力逼迫残害　神的教会。"},
{volumn:        48,chapter:         1,versenumber:        14,verse:"我又在犹太教中，比我本国许多同岁的人更有长进，为我祖宗的遗传更加热心。"},
{volumn:        48,chapter:         1,versenumber:        15,verse:"然而，那把我从母腹里分别出来、又施恩召我的　神，"},
{volumn:        48,chapter:         1,versenumber:        16,verse:"既然乐意将他儿子启示在我心里，叫我把他传在外邦人中，我就没有与属血气的人商量，"},
{volumn:        48,chapter:         1,versenumber:        17,verse:"也没有上耶路撒冷去见那些比我先作使徒的，惟独往阿拉伯去，后又回到大马士革。"},
{volumn:        48,chapter:         1,versenumber:        18,verse:"过了三年，才上耶路撒冷去见矶法，和他同住了十五天。"},
{volumn:        48,chapter:         1,versenumber:        19,verse:"至于别的使徒，除了主的兄弟雅各，我都没有看见。"},
{volumn:        48,chapter:         1,versenumber:        20,verse:"我写给你们的不是谎话，这是我在　神面前说的。"},
{volumn:        48,chapter:         1,versenumber:        21,verse:"以后我到了叙利亚和基利家境内。"},
{volumn:        48,chapter:         1,versenumber:        22,verse:"那时，犹太信基督的各教会都没有见过我的面。"},
{volumn:        48,chapter:         1,versenumber:        23,verse:"不过听说那从前逼迫我们的，现在传扬他原先所残害的真道。"},
{volumn:        48,chapter:         1,versenumber:        24,verse:"他们就为我的缘故，归荣耀给　神。"},
{volumn:        48,chapter:         2,versenumber:         1,verse:"过了十四年，我同巴拿巴又上耶路撒冷去，并带着提多同去。"},
{volumn:        48,chapter:         2,versenumber:         2,verse:"我是奉启示上去的，把我在外邦人中所传的福音对弟兄们陈说；却是背地里对那有名望之人说的，惟恐我现在，或是从前，徒然奔跑。"},
{volumn:        48,chapter:         2,versenumber:         3,verse:"但与我同去的提多，虽是希腊人，也没有勉强他受割礼；"},
{volumn:        48,chapter:         2,versenumber:         4,verse:"因为有偷着引进来的假弟兄，私下窥探我们在基督耶稣里的自由，要叫我们作奴仆。"},
{volumn:        48,chapter:         2,versenumber:         5,verse:"我们就是一刻的工夫也没有容让顺服他们，为要叫福音的真理仍存在你们中间。"},
{volumn:        48,chapter:         2,versenumber:         6,verse:"至于那些有名望的，不论他是何等人，都与我无干。　神不以外貌取人。那些有名望的，并没有加增我什么，"},
{volumn:        48,chapter:         2,versenumber:         7,verse:"反倒看见了主托我传福音给那未受割礼的人，正如托彼得传福音给那受割礼的人。（"},
{volumn:        48,chapter:         2,versenumber:         8,verse:"那感动彼得、叫他为受割礼之人作使徒的，也感动我，叫我为外邦人作使徒；）"},
{volumn:        48,chapter:         2,versenumber:         9,verse:"又知道所赐给我的恩典，那称为教会柱石的雅各、矶法、约翰，就向我和巴拿巴用右手行相交之礼，叫我们往外邦人那里去，他们往受割礼的人那里去。"},
{volumn:        48,chapter:         2,versenumber:        10,verse:"只是愿意我们记念穷人；这也是我本来热心去行的。"},
{volumn:        48,chapter:         2,versenumber:        11,verse:"后来，矶法到了安提阿；因他有可责之处，我就当面抵挡他。"},
{volumn:        48,chapter:         2,versenumber:        12,verse:"从雅各那里来的人未到以先，他和外邦人一同吃饭，及至他们来到，他因怕奉割礼的人，就退去与外邦人隔开了。"},
{volumn:        48,chapter:         2,versenumber:        13,verse:"其余的犹太人也都随着他装假，甚至连巴拿巴也随伙装假。"},
{volumn:        48,chapter:         2,versenumber:        14,verse:"但我一看见他们行的不正，与福音的真理不合，就在众人面前对矶法说：“你既是犹太人，若随外邦人行事，不随犹太人行事，怎么还勉强外邦人随犹太人呢？”"},
{volumn:        48,chapter:         2,versenumber:        15,verse:"我们这生来的犹太人，不是外邦的罪人；"},
{volumn:        48,chapter:         2,versenumber:        16,verse:"既知道人称义不是因行律法，乃是因信耶稣基督，连我们也信了基督耶稣，使我们因信基督称义，不因行律法称义；因为凡有血气的，没有一人因行律法称义。"},
{volumn:        48,chapter:         2,versenumber:        17,verse:"我们若求在基督里称义，却仍旧是罪人，难道基督是叫人犯罪的吗？断乎不是！"},
{volumn:        48,chapter:         2,versenumber:        18,verse:"我素来所拆毁的，若重新建造，这就证明自己是犯罪的人。"},
{volumn:        48,chapter:         2,versenumber:        19,verse:"我因律法，就向律法死了，叫我可以向　神活着。"},
{volumn:        48,chapter:         2,versenumber:        20,verse:"我已经与基督同钉十字架，现在活着的不再是我，乃是基督在我里面活着；并且我如今在肉身活着，是因信　神的儿子而活；他是爱我，为我舍己。"},
{volumn:        48,chapter:         2,versenumber:        21,verse:"我不废掉　神的恩；义若是藉着律法得的，基督就是徒然死了。"},
{volumn:        48,chapter:         3,versenumber:         1,verse:"无知的加拉太人哪，耶稣基督钉十字架，已经活画在你们眼前，谁又迷惑了你们呢？"},
{volumn:        48,chapter:         3,versenumber:         2,verse:"我只要问你们这一件：你们受了圣灵，是因行律法呢？是因听信福音呢？"},
{volumn:        48,chapter:         3,versenumber:         3,verse:"你们既靠圣灵入门，如今还靠肉身成全吗？你们是这样的无知吗？"},
{volumn:        48,chapter:         3,versenumber:         4,verse:"你们受苦如此之多，都是徒然的吗？难道果真是徒然的吗？"},
{volumn:        48,chapter:         3,versenumber:         5,verse:"那赐给你们圣灵，又在你们中间行异能的，是因你们行律法呢？是因你们听信福音呢？"},
{volumn:        48,chapter:         3,versenumber:         6,verse:"正如“亚伯拉罕信　神，这就算为他的义”。"},
{volumn:        48,chapter:         3,versenumber:         7,verse:"所以，你们要知道：那以信为本的人，就是亚伯拉罕的子孙。"},
{volumn:        48,chapter:         3,versenumber:         8,verse:"并且圣经既然预先看明，　神要叫外邦人因信称义，就早已传福音给亚伯拉罕，说：“万国都必因你得福。”"},
{volumn:        48,chapter:         3,versenumber:         9,verse:"可见那以信为本的人和有信心的亚伯拉罕一同得福。"},
{volumn:        48,chapter:         3,versenumber:        10,verse:"凡以行律法为本的，都是被咒诅的；因为经上记着：“凡不常照律法书上所记一切之事去行的，就被咒诅。”"},
{volumn:        48,chapter:         3,versenumber:        11,verse:"没有一个人靠着律法在　神面前称义，这是明显的；因为经上说：“义人必因信得生。”"},
{volumn:        48,chapter:         3,versenumber:        12,verse:"律法原不本乎信，只说：“行这些事的，就必因此活着。”"},
{volumn:        48,chapter:         3,versenumber:        13,verse:"基督既为我们受（原文是成）了咒诅，就赎出我们脱离律法的咒诅；因为经上记着：“凡挂在木头上都是被咒诅的。”"},
{volumn:        48,chapter:         3,versenumber:        14,verse:"这便叫亚伯拉罕的福，因基督耶稣可以临到外邦人，使我们因信得着所应许的圣灵。"},
{volumn:        48,chapter:         3,versenumber:        15,verse:"弟兄们，我且照着人的常话说：虽然是人的文约，若已经立定了，就没有能废弃或加增的。"},
{volumn:        48,chapter:         3,versenumber:        16,verse:"所应许的原是向亚伯拉罕和他子孙说的。　神并不是说“众子孙”，指着许多人，乃是说“你那一个子孙”，指着一个人，就是基督。"},
{volumn:        48,chapter:         3,versenumber:        17,verse:"我是这么说，　神预先所立的约，不能被那四百三十年以后的律法废掉，叫应许归于虚空。"},
{volumn:        48,chapter:         3,versenumber:        18,verse:"因为承受产业，若本乎律法，就不本乎应许；但　神是凭着应许，把产业赐给亚伯拉罕。"},
{volumn:        48,chapter:         3,versenumber:        19,verse:"这样说来，律法是为什么有的呢？原是为过犯添上的，等候那蒙应许的子孙来到，并且是藉天使经中保之手设立的。"},
{volumn:        48,chapter:         3,versenumber:        20,verse:"但中保本不是为一面作的；　神却是一位。"},
{volumn:        48,chapter:         3,versenumber:        21,verse:"这样，律法是与　神的应许反对吗？断乎不是！若曾传一个能叫人得生的律法，义就诚然本乎律法了。"},
{volumn:        48,chapter:         3,versenumber:        22,verse:"但圣经把众人都圈在罪里，使所应许的福因信耶稣基督，归给那信的人。"},
{volumn:        48,chapter:         3,versenumber:        23,verse:"但这因信得救的理还未来以先，我们被看守在律法之下，直圈到那将来的真道显明出来。"},
{volumn:        48,chapter:         3,versenumber:        24,verse:"这样，律法是我们训蒙的师傅，引我们到基督那里，使我们因信称义。"},
{volumn:        48,chapter:         3,versenumber:        25,verse:"但这因信得救的理既然来到，我们从此就不在师傅的手下了。"},
{volumn:        48,chapter:         3,versenumber:        26,verse:"所以，你们因信基督耶稣都是　神的儿子。"},
{volumn:        48,chapter:         3,versenumber:        27,verse:"你们受洗归入基督的都是披戴基督了。"},
{volumn:        48,chapter:         3,versenumber:        28,verse:"并不分犹太人、希腊人，自主的、为奴的，或男或女，因为你们在基督耶稣里都成为一了。"},
{volumn:        48,chapter:         3,versenumber:        29,verse:"你们既属乎基督，就是亚伯拉罕的后裔，是照着应许承受产业的了。"},
{volumn:        48,chapter:         4,versenumber:         1,verse:"我说那承受产业的，虽然是全业的主人，但为孩童的时候却与奴仆毫无分别，"},
{volumn:        48,chapter:         4,versenumber:         2,verse:"乃在师傅和管家的手下，直等他父亲预定的时候来到。"},
{volumn:        48,chapter:         4,versenumber:         3,verse:"我们为孩童的时候，受管于世俗小学之下，也是如此。"},
{volumn:        48,chapter:         4,versenumber:         4,verse:"及至时候满足，　神就差遣他的儿子，为女子所生，且生在律法以下，"},
{volumn:        48,chapter:         4,versenumber:         5,verse:"要把律法以下的人赎出来，叫我们得着儿子的名分。"},
{volumn:        48,chapter:         4,versenumber:         6,verse:"你们既为儿子，　神就差他儿子的灵进入你们（原文是我们）的心，呼叫：“阿爸！父！”"},
{volumn:        48,chapter:         4,versenumber:         7,verse:"可见，从此以后，你不是奴仆，乃是儿子了；既是儿子，就靠着　神为后嗣。"},
{volumn:        48,chapter:         4,versenumber:         8,verse:"但从前你们不认识　神的时候，是给那些本来不是　神的作奴仆；"},
{volumn:        48,chapter:         4,versenumber:         9,verse:"现在你们既然认识　神，更可说是被　神所认识的，怎么还要归回那懦弱无用的小学，情愿再给他作奴仆呢？"},
{volumn:        48,chapter:         4,versenumber:        10,verse:"你们谨守日子、月份、节期、年份，"},
{volumn:        48,chapter:         4,versenumber:        11,verse:"我为你们害怕，惟恐我在你们身上是枉费了工夫。"},
{volumn:        48,chapter:         4,versenumber:        12,verse:"弟兄们，我劝你们要像我一样，因为我也像你们一样。你们一点没有亏负我。"},
{volumn:        48,chapter:         4,versenumber:        13,verse:"你们知道我头一次传福音给你们，是因为身体有疾病。"},
{volumn:        48,chapter:         4,versenumber:        14,verse:"你们为我身体的缘故受试炼，没有轻看我，也没有厌弃我，反倒接待我，如同　神的使者，如同基督耶稣。"},
{volumn:        48,chapter:         4,versenumber:        15,verse:"你们当日所夸的福气在哪里呢？那时你们若能行，就是把自己的眼睛剜出来给我，也都情愿。这是我可以给你们作见证的。"},
{volumn:        48,chapter:         4,versenumber:        16,verse:"如今我将真理告诉你们，就成了你们的仇敌吗？"},
{volumn:        48,chapter:         4,versenumber:        17,verse:"那些人热心待你们，却不是好意，是要离间你们（原文是把你们关在外面），叫你们热心待他们。"},
{volumn:        48,chapter:         4,versenumber:        18,verse:"在善事上，常用热心待人，原是好的，却不单我与你们同在的时候才这样。"},
{volumn:        48,chapter:         4,versenumber:        19,verse:"我小子啊，我为你们再受生产之苦，直等到基督成形在你们心里。"},
{volumn:        48,chapter:         4,versenumber:        20,verse:"我巴不得现今在你们那里，改换口气，因我为你们心里作难。"},
{volumn:        48,chapter:         4,versenumber:        21,verse:"你们这愿意在律法以下的人，请告诉我，你们岂没有听见律法吗？"},
{volumn:        48,chapter:         4,versenumber:        22,verse:"因为律法上记着，亚伯拉罕有两个儿子，一个是使女生的，一个是自主之妇人生的。"},
{volumn:        48,chapter:         4,versenumber:        23,verse:"然而，那使女所生的是按着血气生的；那自主之妇人所生的，是凭着应许生的。"},
{volumn:        48,chapter:         4,versenumber:        24,verse:"这都是比方：那两个妇人就是两约。一约是出于西奈山，生子为奴，乃是夏甲。"},
{volumn:        48,chapter:         4,versenumber:        25,verse:"这夏甲二字是指着阿拉伯的西奈山，与现在的耶路撒冷同类，因耶路撒冷和她的儿女都是为奴的。"},
{volumn:        48,chapter:         4,versenumber:        26,verse:"但那在上的耶路撒冷是自主的，她是我们的母。"},
{volumn:        48,chapter:         4,versenumber:        27,verse:"因为经上记着：“不怀孕、不生养的，你要欢乐；未曾经过产难的，你要高声欢呼；因为没有丈夫的，比有丈夫的儿女更多。”"},
{volumn:        48,chapter:         4,versenumber:        28,verse:"弟兄们，我们是凭着应许作儿女，如同以撒一样。"},
{volumn:        48,chapter:         4,versenumber:        29,verse:"当时，那按着血气生的，逼迫了那按着圣灵生的，现在也是这样。"},
{volumn:        48,chapter:         4,versenumber:        30,verse:"然而经上是怎么说的呢？是说：“把使女和她儿子赶出去！因为使女的儿子不可与自主妇人的儿子一同承受产业。”"},
{volumn:        48,chapter:         4,versenumber:        31,verse:"弟兄们，这样看来，我们不是使女的儿女，乃是自主妇人的儿女了。"},
{volumn:        48,chapter:         5,versenumber:         1,verse:"基督释放了我们，叫我们得以自由。所以要站立得稳，不要再被奴仆的轭挟制。"},
{volumn:        48,chapter:         5,versenumber:         2,verse:"我保罗告诉你们，若受割礼，基督就与你们无益了。"},
{volumn:        48,chapter:         5,versenumber:         3,verse:"我再指着凡受割礼的人确实地说，他是欠着行全律法的债。"},
{volumn:        48,chapter:         5,versenumber:         4,verse:"你们这要靠律法称义的，是与基督隔绝，从恩典中坠落了。"},
{volumn:        48,chapter:         5,versenumber:         5,verse:"我们靠着圣灵，凭着信心，等候所盼望的义。"},
{volumn:        48,chapter:         5,versenumber:         6,verse:"原来在基督耶稣里，受割礼不受割礼全无功效，惟独使人生发仁爱的信心才有功效。"},
{volumn:        48,chapter:         5,versenumber:         7,verse:"你们向来跑得好，有谁拦阻你们，叫你们不顺从真理呢？"},
{volumn:        48,chapter:         5,versenumber:         8,verse:"这样的劝导不是出于那召你们的。"},
{volumn:        48,chapter:         5,versenumber:         9,verse:"一点面酵能使全团都发起来。"},
{volumn:        48,chapter:         5,versenumber:        10,verse:"我在主里很信你们必不怀别样的心；但搅扰你们的，无论是谁，必担当他的罪名。"},
{volumn:        48,chapter:         5,versenumber:        11,verse:"弟兄们，我若仍旧传割礼，为什么还受逼迫呢？若是这样，那十字架讨厌的地方就没有了。"},
{volumn:        48,chapter:         5,versenumber:        12,verse:"恨不得那搅乱你们的人把自己割绝了。"},
{volumn:        48,chapter:         5,versenumber:        13,verse:"弟兄们，你们蒙召是要得自由，只是不可将你们的自由当作放纵情欲的机会，总要用爱心互相服侍。"},
{volumn:        48,chapter:         5,versenumber:        14,verse:"因为全律法都包在“爱人如己”这一句话之内了。"},
{volumn:        48,chapter:         5,versenumber:        15,verse:"你们要谨慎，若相咬相吞，只怕要彼此消灭了。"},
{volumn:        48,chapter:         5,versenumber:        16,verse:"我说，你们当顺着圣灵而行，就不放纵肉体的情欲了。"},
{volumn:        48,chapter:         5,versenumber:        17,verse:"因为情欲和圣灵相争，圣灵和情欲相争，这两个是彼此相敌，使你们不能做所愿意做的。"},
{volumn:        48,chapter:         5,versenumber:        18,verse:"但你们若被圣灵引导，就不在律法以下。"},
{volumn:        48,chapter:         5,versenumber:        19,verse:"情欲的事都是显而易见的，就如奸淫、污秽、邪荡、"},
{volumn:        48,chapter:         5,versenumber:        20,verse:"拜偶像、邪术、仇恨、争竞、忌恨、恼怒、结党、纷争、异端、"},
{volumn:        48,chapter:         5,versenumber:        21,verse:"嫉妒（有古卷加：凶杀二字）、醉酒、荒宴等类。我从前告诉你们，现在又告诉你们，行这样事的人必不能承受　神的国。"},
{volumn:        48,chapter:         5,versenumber:        22,verse:"圣灵所结的果子，就是仁爱、喜乐、和平、忍耐、恩慈、良善、信实、"},
{volumn:        48,chapter:         5,versenumber:        23,verse:"温柔、节制。这样的事没有律法禁止。"},
{volumn:        48,chapter:         5,versenumber:        24,verse:"凡属基督耶稣的人，是已经把肉体连肉体的邪情私欲同钉在十字架上了。"},
{volumn:        48,chapter:         5,versenumber:        25,verse:"我们若是靠圣灵得生，就当靠圣灵行事。"},
{volumn:        48,chapter:         5,versenumber:        26,verse:"不要贪图虚名，彼此惹气，互相嫉妒。"},
{volumn:        48,chapter:         6,versenumber:         1,verse:"弟兄们，若有人偶然被过犯所胜，你们属灵的人就当用温柔的心把他挽回过来；又当自己小心，恐怕也被引诱。"},
{volumn:        48,chapter:         6,versenumber:         2,verse:"你们各人的重担要互相担当，如此，就完全了基督的律法。"},
{volumn:        48,chapter:         6,versenumber:         3,verse:"人若无有，自己还以为有，就是自欺了。"},
{volumn:        48,chapter:         6,versenumber:         4,verse:"各人应当察验自己的行为；这样，他所夸的就专在自己，不在别人了，"},
{volumn:        48,chapter:         6,versenumber:         5,verse:"因为各人必担当自己的担子。"},
{volumn:        48,chapter:         6,versenumber:         6,verse:"在道理上受教的，当把一切需用的供给施教的人。"},
{volumn:        48,chapter:         6,versenumber:         7,verse:"不要自欺，　神是轻慢不得的。人种的是什么，收的也是什么。"},
{volumn:        48,chapter:         6,versenumber:         8,verse:"顺着情欲撒种的，必从情欲收败坏；顺着圣灵撒种的，必从圣灵收永生。"},
{volumn:        48,chapter:         6,versenumber:         9,verse:"我们行善，不可丧志；若不灰心，到了时候就要收成。"},
{volumn:        48,chapter:         6,versenumber:        10,verse:"所以，有了机会就当向众人行善，向信徒一家的人更当这样。"},
{volumn:        48,chapter:         6,versenumber:        11,verse:"请看我亲手写给你们的字是何等的大呢！"},
{volumn:        48,chapter:         6,versenumber:        12,verse:"凡希图外貌体面的人都勉强你们受割礼，无非是怕自己为基督的十字架受逼迫。"},
{volumn:        48,chapter:         6,versenumber:        13,verse:"他们那些受割礼的，连自己也不守律法；他们愿意你们受割礼，不过要藉着你们的肉体夸口。"},
{volumn:        48,chapter:         6,versenumber:        14,verse:"但我断不以别的夸口，只夸我们主耶稣基督的十字架；因这十字架，就我而论，世界已经钉在十字架上；就世界而论，我已经钉在十字架上。"},
{volumn:        48,chapter:         6,versenumber:        15,verse:"受割礼不受割礼都无关紧要，要紧的就是作新造的人。"},
{volumn:        48,chapter:         6,versenumber:        16,verse:"凡照此理而行的，愿平安、怜悯加给他们，和　神的以色列民。"},
{volumn:        48,chapter:         6,versenumber:        17,verse:"从今以后，人都不要搅扰我，因为我身上带着耶稣的印记。"},
{volumn:        48,chapter:         6,versenumber:        18,verse:"弟兄们，愿我主耶稣基督的恩常在你们心里。阿们！"},
{volumn:        49,chapter:         1,versenumber:         1,verse:"奉　神旨意，作基督耶稣使徒的保罗，写信给在以弗所的圣徒，就是在基督耶稣里有忠心的人。"},
{volumn:        49,chapter:         1,versenumber:         2,verse:"愿恩惠、平安从　神我们的父和主耶稣基督归与你们！"},
{volumn:        49,chapter:         1,versenumber:         3,verse:"愿颂赞归与我们主耶稣基督的父　神！他在基督里曾赐给我们天上各样属灵的福气："},
{volumn:        49,chapter:         1,versenumber:         4,verse:"就如　神从创立世界以前，在基督里拣选了我们，使我们在他面前成为圣洁，无有瑕疵；"},
{volumn:        49,chapter:         1,versenumber:         5,verse:"又因爱我们，就按着自己的意旨所喜悦的，预定我们藉着耶稣基督得儿子的名分，"},
{volumn:        49,chapter:         1,versenumber:         6,verse:"使他荣耀的恩典得着称赞；这恩典是他在爱子里所赐给我们的。"},
{volumn:        49,chapter:         1,versenumber:         7,verse:"我们藉这爱子的血得蒙救赎，过犯得以赦免，乃是照他丰富的恩典。"},
{volumn:        49,chapter:         1,versenumber:         8,verse:"这恩典是　神用诸般智慧聪明，充充足足赏给我们的；"},
{volumn:        49,chapter:         1,versenumber:         9,verse:"都是照他自己所预定的美意，叫我们知道他旨意的奥秘，"},
{volumn:        49,chapter:         1,versenumber:        10,verse:"要照所安排的，在日期满足的时候，使天上、地上、一切所有的都在基督里面同归于一。"},
{volumn:        49,chapter:         1,versenumber:        11,verse:"我们也在他里面得（得：或译成）了基业；这原是那位随己意行、做万事的，照着他旨意所预定的，"},
{volumn:        49,chapter:         1,versenumber:        12,verse:"叫他的荣耀从我们这首先在基督里有盼望的人可以得着称赞。"},
{volumn:        49,chapter:         1,versenumber:        13,verse:"你们既听见真理的道，就是那叫你们得救的福音，也信了基督，既然信他，就受了所应许的圣灵为印记。"},
{volumn:        49,chapter:         1,versenumber:        14,verse:"这圣灵是我们得基业的凭据（原文是质），直等到　神之民（民：原文是产业）被赎，使他的荣耀得着称赞。"},
{volumn:        49,chapter:         1,versenumber:        15,verse:"因此，我既听见你们信从主耶稣，亲爱众圣徒，"},
{volumn:        49,chapter:         1,versenumber:        16,verse:"就为你们不住地感谢　神。祷告的时候，常提到你们，"},
{volumn:        49,chapter:         1,versenumber:        17,verse:"求我们主耶稣基督的　神，荣耀的父，将那赐人智慧和启示的灵赏给你们，使你们真知道他，"},
{volumn:        49,chapter:         1,versenumber:        18,verse:"并且照明你们心中的眼睛，使你们知道他的恩召有何等指望，他在圣徒中得的基业有何等丰盛的荣耀；"},
{volumn:        49,chapter:         1,versenumber:        19,verse:"并知道他向我们这信的人所显的能力是何等浩大，"},
{volumn:        49,chapter:         1,versenumber:        20,verse:"就是照他在基督身上所运行的大能大力，使他从死里复活，叫他在天上坐在自己的右边，"},
{volumn:        49,chapter:         1,versenumber:        21,verse:"远超过一切执政的、掌权的、有能的、主治的，和一切有名的；不但是今世的，连来世的也都超过了。"},
{volumn:        49,chapter:         1,versenumber:        22,verse:"又将万有服在他的脚下，使他为教会作万有之首。"},
{volumn:        49,chapter:         1,versenumber:        23,verse:"教会是他的身体，是那充满万有者所充满的。"},
{volumn:        49,chapter:         2,versenumber:         1,verse:"你们死在过犯罪恶之中，他叫你们活过来。"},
{volumn:        49,chapter:         2,versenumber:         2,verse:"那时，你们在其中行事为人，随从今世的风俗，顺服空中掌权者的首领，就是现今在悖逆之子心中运行的邪灵。"},
{volumn:        49,chapter:         2,versenumber:         3,verse:"我们从前也都在他们中间，放纵肉体的私欲，随着肉体和心中所喜好的去行，本为可怒之子，和别人一样。"},
{volumn:        49,chapter:         2,versenumber:         4,verse:"然而，　神既有丰富的怜悯，因他爱我们的大爱，"},
{volumn:        49,chapter:         2,versenumber:         5,verse:"当我们死在过犯中的时候，便叫我们与基督一同活过来（你们得救是本乎恩）。"},
{volumn:        49,chapter:         2,versenumber:         6,verse:"他又叫我们与基督耶稣一同复活，一同坐在天上，"},
{volumn:        49,chapter:         2,versenumber:         7,verse:"要将他极丰富的恩典，就是他在基督耶稣里向我们所施的恩慈，显明给后来的世代看。"},
{volumn:        49,chapter:         2,versenumber:         8,verse:"你们得救是本乎恩，也因着信；这并不是出于自己，乃是　神所赐的；"},
{volumn:        49,chapter:         2,versenumber:         9,verse:"也不是出于行为，免得有人自夸。"},
{volumn:        49,chapter:         2,versenumber:        10,verse:"我们原是他的工作，在基督耶稣里造成的，为要叫我们行善，就是　神所预备叫我们行的。"},
{volumn:        49,chapter:         2,versenumber:        11,verse:"所以你们应当记念：你们从前按肉体是外邦人，是称为没受割礼的；这名原是那些凭人手在肉身上称为受割礼之人所起的。"},
{volumn:        49,chapter:         2,versenumber:        12,verse:"那时，你们与基督无关，在以色列国民以外，在所应许的诸约上是局外人，并且活在世上没有指望，没有　神。"},
{volumn:        49,chapter:         2,versenumber:        13,verse:"你们从前远离　神的人，如今却在基督耶稣里，靠着他的血，已经得亲近了。"},
{volumn:        49,chapter:         2,versenumber:        14,verse:"因他使我们和睦（原文是因他是我们的和睦），将两下合而为一，拆毁了中间隔断的墙；"},
{volumn:        49,chapter:         2,versenumber:        15,verse:"而且以自己的身体废掉冤仇，就是那记在律法上的规条，为要将两下藉着自己造成一个新人，如此便成就了和睦。"},
{volumn:        49,chapter:         2,versenumber:        16,verse:"既在十字架上灭了冤仇，便藉这十字架使两下归为一体，与　神和好了，"},
{volumn:        49,chapter:         2,versenumber:        17,verse:"并且来传和平的福音给你们远处的人，也给那近处的人。"},
{volumn:        49,chapter:         2,versenumber:        18,verse:"因为我们两下藉着他被一个圣灵所感，得以进到父面前。"},
{volumn:        49,chapter:         2,versenumber:        19,verse:"这样，你们不再作外人和客旅，是与圣徒同国，是　神家里的人了；"},
{volumn:        49,chapter:         2,versenumber:        20,verse:"并且被建造在使徒和先知的根基上，有基督耶稣自己为房角石，"},
{volumn:        49,chapter:         2,versenumber:        21,verse:"各（或译：全）房靠他联络得合式，渐渐成为主的圣殿。"},
{volumn:        49,chapter:         2,versenumber:        22,verse:"你们也靠他同被建造，成为　神藉着圣灵居住的所在。"},
{volumn:        49,chapter:         3,versenumber:         1,verse:"因此，我保罗为你们外邦人作了基督耶稣被囚的，替你们祈祷（此句是照对十四节所加）。"},
{volumn:        49,chapter:         3,versenumber:         2,verse:"谅必你们曾听见　神赐恩给我，将关切你们的职分托付我，"},
{volumn:        49,chapter:         3,versenumber:         3,verse:"用启示使我知道福音的奥秘，正如我以前略略写过的。"},
{volumn:        49,chapter:         3,versenumber:         4,verse:"你们念了，就能晓得我深知基督的奥秘。"},
{volumn:        49,chapter:         3,versenumber:         5,verse:"这奥秘在以前的世代没有叫人知道，像如今藉着圣灵启示他的圣使徒和先知一样。"},
{volumn:        49,chapter:         3,versenumber:         6,verse:"这奥秘就是外邦人在基督耶稣里，藉着福音，得以同为后嗣，同为一体，同蒙应许。"},
{volumn:        49,chapter:         3,versenumber:         7,verse:"我作了这福音的执事，是照　神的恩赐，这恩赐是照他运行的大能赐给我的。"},
{volumn:        49,chapter:         3,versenumber:         8,verse:"我本来比众圣徒中最小的还小，然而他还赐我这恩典，叫我把基督那测不透的丰富传给外邦人，"},
{volumn:        49,chapter:         3,versenumber:         9,verse:"又使众人都明白，这历代以来隐藏在创造万物之　神里的奥秘，是如何安排的，"},
{volumn:        49,chapter:         3,versenumber:        10,verse:"为要藉着教会使天上执政的、掌权的，现在得知　神百般的智慧。"},
{volumn:        49,chapter:         3,versenumber:        11,verse:"这是照　神从万世以前，在我们主基督耶稣里所定的旨意。"},
{volumn:        49,chapter:         3,versenumber:        12,verse:"我们因信耶稣，就在他里面放胆无惧，笃信不疑地来到　神面前。"},
{volumn:        49,chapter:         3,versenumber:        13,verse:"所以，我求你们不要因我为你们所受的患难丧胆，这原是你们的荣耀。"},
{volumn:        49,chapter:         3,versenumber:        14,verse:"因此，我在父面前屈膝，（"},
{volumn:        49,chapter:         3,versenumber:        15,verse:"天上地上的各（或译：全）家，都是从他得名。）"},
{volumn:        49,chapter:         3,versenumber:        16,verse:"求他按着他丰盛的荣耀，藉着他的灵，叫你们心里的力量刚强起来，"},
{volumn:        49,chapter:         3,versenumber:        17,verse:"使基督因你们的信，住在你们心里，叫你们的爱心有根有基，"},
{volumn:        49,chapter:         3,versenumber:        18,verse:"能以和众圣徒一同明白基督的爱是何等长阔高深，"},
{volumn:        49,chapter:         3,versenumber:        19,verse:"并知道这爱是过于人所能测度的，便叫　神一切所充满的，充满了你们。"},
{volumn:        49,chapter:         3,versenumber:        20,verse:"神能照着运行在我们心里的大力充充足足地成就一切，超过我们所求所想的。"},
{volumn:        49,chapter:         3,versenumber:        21,verse:"但愿他在教会中，并在基督耶稣里，得着荣耀，直到世世代代，永永远远。阿们！"},
{volumn:        49,chapter:         4,versenumber:         1,verse:"我为主被囚的劝你们：既然蒙召，行事为人就当与蒙召的恩相称。"},
{volumn:        49,chapter:         4,versenumber:         2,verse:"凡事谦虚、温柔、忍耐，用爱心互相宽容，"},
{volumn:        49,chapter:         4,versenumber:         3,verse:"用和平彼此联络，竭力保守圣灵所赐合而为一的心。"},
{volumn:        49,chapter:         4,versenumber:         4,verse:"身体只有一个，圣灵只有一个，正如你们蒙召同有一个指望。"},
{volumn:        49,chapter:         4,versenumber:         5,verse:"一主，一信，一洗，"},
{volumn:        49,chapter:         4,versenumber:         6,verse:"一　神，就是众人的父，超乎众人之上，贯乎众人之中，也住在众人之内。"},
{volumn:        49,chapter:         4,versenumber:         7,verse:"我们各人蒙恩，都是照基督所量给各人的恩赐。"},
{volumn:        49,chapter:         4,versenumber:         8,verse:"所以经上说：“他升上高天的时候，掳掠了仇敌，将各样的恩赐赏给人。”"},
{volumn:        49,chapter:         4,versenumber:         9,verse:"既说升上，岂不是先降在地下吗？"},
{volumn:        49,chapter:         4,versenumber:        10,verse:"那降下的，就是远升诸天之上要充满万有的。"},
{volumn:        49,chapter:         4,versenumber:        11,verse:"他所赐的，有使徒，有先知，有传福音的，有牧师和教师，"},
{volumn:        49,chapter:         4,versenumber:        12,verse:"为要成全圣徒，各尽其职，建立基督的身体，"},
{volumn:        49,chapter:         4,versenumber:        13,verse:"直等到我们众人在真道上同归于一，认识　神的儿子，得以长大成人，满有基督长成的身量，"},
{volumn:        49,chapter:         4,versenumber:        14,verse:"使我们不再作小孩子，中了人的诡计和欺骗的法术，被一切异教之风摇动，飘来飘去，就随从各样的异端；"},
{volumn:        49,chapter:         4,versenumber:        15,verse:"惟用爱心说诚实话，凡事长进，连于元首基督，"},
{volumn:        49,chapter:         4,versenumber:        16,verse:"全身都靠他联络得合式，百节各按各职，照着各体的功用彼此相助，便叫身体渐渐增长，在爱中建立自己。"},
{volumn:        49,chapter:         4,versenumber:        17,verse:"所以我说，且在主里确实地说，你们行事不要再像外邦人存虚妄的心行事。"},
{volumn:        49,chapter:         4,versenumber:        18,verse:"他们心地昏昧，与　神所赐的生命隔绝了，都因自己无知，心里刚硬；"},
{volumn:        49,chapter:         4,versenumber:        19,verse:"良心既然丧尽，就放纵私欲，贪行种种的污秽。"},
{volumn:        49,chapter:         4,versenumber:        20,verse:"你们学了基督，却不是这样。"},
{volumn:        49,chapter:         4,versenumber:        21,verse:"如果你们听过他的道，领了他的教，学了他的真理，"},
{volumn:        49,chapter:         4,versenumber:        22,verse:"就要脱去你们从前行为上的旧人，这旧人是因私欲的迷惑渐渐变坏的；"},
{volumn:        49,chapter:         4,versenumber:        23,verse:"又要将你们的心志改换一新，"},
{volumn:        49,chapter:         4,versenumber:        24,verse:"并且穿上新人；这新人是照着　神的形像造的，有真理的仁义和圣洁。"},
{volumn:        49,chapter:         4,versenumber:        25,verse:"所以，你们要弃绝谎言，各人与邻舍说实话，因为我们是互相为肢体。"},
{volumn:        49,chapter:         4,versenumber:        26,verse:"生气却不要犯罪；不可含怒到日落，"},
{volumn:        49,chapter:         4,versenumber:        27,verse:"也不可给魔鬼留地步。"},
{volumn:        49,chapter:         4,versenumber:        28,verse:"从前偷窃的，不要再偷；总要劳力，亲手做正经事，就可有余分给那缺少的人。"},
{volumn:        49,chapter:         4,versenumber:        29,verse:"污秽的言语一句不可出口，只要随事说造就人的好话，叫听见的人得益处。"},
{volumn:        49,chapter:         4,versenumber:        30,verse:"不要叫　神的圣灵担忧；你们原是受了他的印记，等候得赎的日子来到。"},
{volumn:        49,chapter:         4,versenumber:        31,verse:"一切苦毒、恼恨、忿怒、嚷闹、毁谤，并一切的恶毒（或译：阴毒），都当从你们中间除掉；"},
{volumn:        49,chapter:         4,versenumber:        32,verse:"并要以恩慈相待，存怜悯的心，彼此饶恕，正如　神在基督里饶恕了你们一样。"},
{volumn:        49,chapter:         5,versenumber:         1,verse:"所以，你们该效法　神，好像蒙慈爱的儿女一样。"},
{volumn:        49,chapter:         5,versenumber:         2,verse:"也要凭爱心行事，正如基督爱我们，为我们舍了自己，当作馨香的供物和祭物，献与　神。"},
{volumn:        49,chapter:         5,versenumber:         3,verse:"至于淫乱并一切污秽，或是贪婪，在你们中间连提都不可，方合圣徒的体统。"},
{volumn:        49,chapter:         5,versenumber:         4,verse:"淫词、妄语，和戏笑的话都不相宜；总要说感谢的话。"},
{volumn:        49,chapter:         5,versenumber:         5,verse:"因为你们确实地知道，无论是淫乱的，是污秽的，是有贪心的，在基督和　神的国里都是无份的。有贪心的，就与拜偶像的一样。"},
{volumn:        49,chapter:         5,versenumber:         6,verse:"不要被人虚浮的话欺哄；因这些事，　神的忿怒必临到那悖逆之子。"},
{volumn:        49,chapter:         5,versenumber:         7,verse:"所以，你们不要与他们同伙。"},
{volumn:        49,chapter:         5,versenumber:         8,verse:"从前你们是暗昧的，但如今在主里面是光明的，行事为人就当像光明的子女。"},
{volumn:        49,chapter:         5,versenumber:         9,verse:"光明所结的果子就是一切良善、公义、诚实。"},
{volumn:        49,chapter:         5,versenumber:        10,verse:"总要察验何为主所喜悦的事。"},
{volumn:        49,chapter:         5,versenumber:        11,verse:"那暗昧无益的事，不要与人同行，倒要责备行这事的人；"},
{volumn:        49,chapter:         5,versenumber:        12,verse:"因为他们暗中所行的，就是提起来也是可耻的。"},
{volumn:        49,chapter:         5,versenumber:        13,verse:"凡事受了责备，就被光显明出来，因为一切能显明的就是光。"},
{volumn:        49,chapter:         5,versenumber:        14,verse:"所以主说：“你这睡着的人当醒过来，从死里复活！基督就要光照你了。”"},
{volumn:        49,chapter:         5,versenumber:        15,verse:"你们要谨慎行事，不要像愚昧人，当像智慧人。"},
{volumn:        49,chapter:         5,versenumber:        16,verse:"要爱惜光阴，因为现今的世代邪恶。"},
{volumn:        49,chapter:         5,versenumber:        17,verse:"不要作糊涂人，要明白主的旨意如何。"},
{volumn:        49,chapter:         5,versenumber:        18,verse:"不要醉酒，酒能使人放荡；乃要被圣灵充满。"},
{volumn:        49,chapter:         5,versenumber:        19,verse:"当用诗章、颂词、灵歌彼此对说，口唱心和地赞美主。"},
{volumn:        49,chapter:         5,versenumber:        20,verse:"凡事要奉我们主耶稣基督的名常常感谢父　神。"},
{volumn:        49,chapter:         5,versenumber:        21,verse:"又当存敬畏基督的心，彼此顺服。"},
{volumn:        49,chapter:         5,versenumber:        22,verse:"你们作妻子的，当顺服自己的丈夫，如同顺服主。"},
{volumn:        49,chapter:         5,versenumber:        23,verse:"因为丈夫是妻子的头，如同基督是教会的头；他又是教会全体的救主。"},
{volumn:        49,chapter:         5,versenumber:        24,verse:"教会怎样顺服基督，妻子也要怎样凡事顺服丈夫。"},
{volumn:        49,chapter:         5,versenumber:        25,verse:"你们作丈夫的，要爱你们的妻子，正如基督爱教会，为教会舍己。"},
{volumn:        49,chapter:         5,versenumber:        26,verse:"要用水藉着道把教会洗净，成为圣洁，"},
{volumn:        49,chapter:         5,versenumber:        27,verse:"可以献给自己，作个荣耀的教会，毫无玷污、皱纹等类的病，乃是圣洁没有瑕疵的。"},
{volumn:        49,chapter:         5,versenumber:        28,verse:"丈夫也当照样爱妻子，如同爱自己的身子；爱妻子便是爱自己了。"},
{volumn:        49,chapter:         5,versenumber:        29,verse:"从来没有人恨恶自己的身子，总是保养顾惜，正像基督待教会一样，"},
{volumn:        49,chapter:         5,versenumber:        30,verse:"因我们是他身上的肢体（有古卷加：就是他的骨他的肉）。"},
{volumn:        49,chapter:         5,versenumber:        31,verse:"为这个缘故，人要离开父母，与妻子连合，二人成为一体。"},
{volumn:        49,chapter:         5,versenumber:        32,verse:"这是极大的奥秘，但我是指着基督和教会说的。"},
{volumn:        49,chapter:         5,versenumber:        33,verse:"然而，你们各人都当爱妻子，如同爱自己一样。妻子也当敬重她的丈夫。"},
{volumn:        49,chapter:         6,versenumber:         1,verse:"你们作儿女的，要在主里听从父母，这是理所当然的。"},
{volumn:        49,chapter:         6,versenumber:         2,verse:"“要孝敬父母，使你得福，在世长寿。”这是第一条带应许的诫命。"},
{volumn:        49,chapter:         6,versenumber:         3,verse:""},
{volumn:        49,chapter:         6,versenumber:         4,verse:"你们作父亲的，不要惹儿女的气，只要照着主的教训和警戒养育他们。"},
{volumn:        49,chapter:         6,versenumber:         5,verse:"你们作仆人的，要惧怕战兢，用诚实的心听从你们肉身的主人，好像听从基督一般。"},
{volumn:        49,chapter:         6,versenumber:         6,verse:"不要只在眼前侍奉，像是讨人喜欢的，要像基督的仆人，从心里遵行　神的旨意。"},
{volumn:        49,chapter:         6,versenumber:         7,verse:"甘心侍奉，好像服侍主，不像服侍人。"},
{volumn:        49,chapter:         6,versenumber:         8,verse:"因为晓得各人所行的善事，不论是为奴的，是自主的，都必按所行的得主的赏赐。"},
{volumn:        49,chapter:         6,versenumber:         9,verse:"你们作主人的，待仆人也是一理，不要威吓他们。因为知道，他们和你们同有一位主在天上；他并不偏待人。"},
{volumn:        49,chapter:         6,versenumber:        10,verse:"我还有末了的话：你们要靠着主，倚赖他的大能大力作刚强的人。"},
{volumn:        49,chapter:         6,versenumber:        11,verse:"要穿戴　神所赐的全副军装，就能抵挡魔鬼的诡计。"},
{volumn:        49,chapter:         6,versenumber:        12,verse:"因我们并不是与属血气的争战（原文是摔跤；下同），乃是与那些执政的、掌权的、管辖这幽暗世界的，以及天空属灵气的恶魔争战。"},
{volumn:        49,chapter:         6,versenumber:        13,verse:"所以，要拿起　神所赐的全副军装，好在磨难的日子抵挡仇敌，并且成就了一切，还能站立得住。"},
{volumn:        49,chapter:         6,versenumber:        14,verse:"所以要站稳了，用真理当作带子束腰，用公义当作护心镜遮胸，"},
{volumn:        49,chapter:         6,versenumber:        15,verse:"又用平安的福音当作预备走路的鞋穿在脚上。"},
{volumn:        49,chapter:         6,versenumber:        16,verse:"此外，又拿着信德当作藤牌，可以灭尽那恶者一切的火箭；"},
{volumn:        49,chapter:         6,versenumber:        17,verse:"并戴上救恩的头盔，拿着圣灵的宝剑，就是　神的道；"},
{volumn:        49,chapter:         6,versenumber:        18,verse:"靠着圣灵，随时多方祷告祈求；并要在此警醒不倦，为众圣徒祈求，"},
{volumn:        49,chapter:         6,versenumber:        19,verse:"也为我祈求，使我得着口才，能以放胆开口讲明福音的奥秘，"},
{volumn:        49,chapter:         6,versenumber:        20,verse:"（我为这福音的奥秘作了带锁链的使者，）并使我照着当尽的本分放胆讲论。"},
{volumn:        49,chapter:         6,versenumber:        21,verse:"今有所亲爱、忠心侍奉主的兄弟推基古，他要把我的事情，并我的景况如何全告诉你们，叫你们知道。"},
{volumn:        49,chapter:         6,versenumber:        22,verse:"我特意打发他到你们那里去，好叫你们知道我们的光景，又叫他安慰你们的心。"},
{volumn:        49,chapter:         6,versenumber:        23,verse:"愿平安、仁爱、信心从父　神和主耶稣基督归与弟兄们！"},
{volumn:        49,chapter:         6,versenumber:        24,verse:"并愿所有诚心爱我们主耶稣基督的人都蒙恩惠！"},
{volumn:        50,chapter:         1,versenumber:         1,verse:"基督耶稣的仆人保罗和提摩太写信给凡住腓立比、在基督耶稣里的众圣徒，和诸位监督，诸位执事。"},
{volumn:        50,chapter:         1,versenumber:         2,verse:"愿恩惠、平安从　神我们的父并主耶稣基督归与你们！"},
{volumn:        50,chapter:         1,versenumber:         3,verse:"我每逢想念你们，就感谢我的　神；"},
{volumn:        50,chapter:         1,versenumber:         4,verse:"每逢为你们众人祈求的时候，常是欢欢喜喜地祈求。"},
{volumn:        50,chapter:         1,versenumber:         5,verse:"因为从头一天直到如今，你们是同心合意地兴旺福音。"},
{volumn:        50,chapter:         1,versenumber:         6,verse:"我深信那在你们心里动了善工的，必成全这工，直到耶稣基督的日子。"},
{volumn:        50,chapter:         1,versenumber:         7,verse:"我为你们众人有这样的意念，原是应当的；因你们常在我心里，无论我是在捆锁之中，是辩明证实福音的时候，你们都与我一同得恩。"},
{volumn:        50,chapter:         1,versenumber:         8,verse:"我体会基督耶稣的心肠，切切地想念你们众人；这是　神可以给我作见证的。"},
{volumn:        50,chapter:         1,versenumber:         9,verse:"我所祷告的，就是要你们的爱心在知识和各样见识上多而又多，"},
{volumn:        50,chapter:         1,versenumber:        10,verse:"使你们能分别是非（或译：喜爱那美好的事），作诚实无过的人，直到基督的日子；"},
{volumn:        50,chapter:         1,versenumber:        11,verse:"并靠着耶稣基督结满了仁义的果子，叫荣耀称赞归与　神。"},
{volumn:        50,chapter:         1,versenumber:        12,verse:"弟兄们，我愿意你们知道，我所遭遇的事更是叫福音兴旺，"},
{volumn:        50,chapter:         1,versenumber:        13,verse:"以致我受的捆锁在御营全军和其余的人中，已经显明是为基督的缘故。"},
{volumn:        50,chapter:         1,versenumber:        14,verse:"并且那在主里的弟兄多半因我受的捆锁就笃信不疑，越发放胆传　神的道，无所惧怕。"},
{volumn:        50,chapter:         1,versenumber:        15,verse:"有的传基督是出于嫉妒纷争，也有的是出于好意。"},
{volumn:        50,chapter:         1,versenumber:        16,verse:"这一等是出于爱心，知道我是为辩明福音设立的；"},
{volumn:        50,chapter:         1,versenumber:        17,verse:"那一等传基督是出于结党，并不诚实，意思要加增我捆锁的苦楚。"},
{volumn:        50,chapter:         1,versenumber:        18,verse:"这有何妨呢？或是假意，或是真心，无论怎样，基督究竟被传开了。为此，我就欢喜，并且还要欢喜；"},
{volumn:        50,chapter:         1,versenumber:        19,verse:"因为我知道，这事藉着你们的祈祷和耶稣基督之灵的帮助，终必叫我得救。"},
{volumn:        50,chapter:         1,versenumber:        20,verse:"照着我所切慕、所盼望的，没有一事叫我羞愧。只要凡事放胆，无论是生是死，总叫基督在我身上照常显大。"},
{volumn:        50,chapter:         1,versenumber:        21,verse:"因我活着就是基督，我死了就有益处。"},
{volumn:        50,chapter:         1,versenumber:        22,verse:"但我在肉身活着，若成就我工夫的果子，我就不知道该挑选什么。"},
{volumn:        50,chapter:         1,versenumber:        23,verse:"我正在两难之间，情愿离世与基督同在，因为这是好得无比的。"},
{volumn:        50,chapter:         1,versenumber:        24,verse:"然而，我在肉身活着，为你们更是要紧的。"},
{volumn:        50,chapter:         1,versenumber:        25,verse:"我既然这样深信，就知道仍要住在世间，且与你们众人同住，使你们在所信的道上又长进又喜乐，"},
{volumn:        50,chapter:         1,versenumber:        26,verse:"叫你们在基督耶稣里的欢乐，因我再到你们那里去，就越发加增。"},
{volumn:        50,chapter:         1,versenumber:        27,verse:"只要你们行事为人与基督的福音相称，叫我或来见你们，或不在你们那里，可以听见你们的景况，知道你们同有一个心志，站立得稳，为所信的福音齐心努力。"},
{volumn:        50,chapter:         1,versenumber:        28,verse:"凡事不怕敌人的惊吓，这是证明他们沉沦，你们得救都是出于　神。"},
{volumn:        50,chapter:         1,versenumber:        29,verse:"因为你们蒙恩，不但得以信服基督，并要为他受苦。"},
{volumn:        50,chapter:         1,versenumber:        30,verse:"你们的争战，就与你们在我身上从前所看见、现在所听见的一样。"},
{volumn:        50,chapter:         2,versenumber:         1,verse:"所以，在基督里若有什么劝勉，爱心有什么安慰，圣灵有什么交通，心中有什么慈悲怜悯，"},
{volumn:        50,chapter:         2,versenumber:         2,verse:"你们就要意念相同，爱心相同，有一样的心思，有一样的意念，使我的喜乐可以满足。"},
{volumn:        50,chapter:         2,versenumber:         3,verse:"凡事不可结党，不可贪图虚浮的荣耀；只要存心谦卑，各人看别人比自己强。"},
{volumn:        50,chapter:         2,versenumber:         4,verse:"各人不要单顾自己的事，也要顾别人的事。"},
{volumn:        50,chapter:         2,versenumber:         5,verse:"你们当以基督耶稣的心为心："},
{volumn:        50,chapter:         2,versenumber:         6,verse:"他本有　神的形像，不以自己与　神同等为强夺的；"},
{volumn:        50,chapter:         2,versenumber:         7,verse:"反倒虚己，取了奴仆的形像，成为人的样式；"},
{volumn:        50,chapter:         2,versenumber:         8,verse:"既有人的样子，就自己卑微，存心顺服，以至于死，且死在十字架上。"},
{volumn:        50,chapter:         2,versenumber:         9,verse:"所以，　神将他升为至高，又赐给他那超乎万名之上的名，"},
{volumn:        50,chapter:         2,versenumber:        10,verse:"叫一切在天上的、地上的，和地底下的，因耶稣的名无不屈膝，"},
{volumn:        50,chapter:         2,versenumber:        11,verse:"无不口称“耶稣基督为主”，使荣耀归与父　神。"},
{volumn:        50,chapter:         2,versenumber:        12,verse:"这样看来，我亲爱的弟兄，你们既是常顺服的，不但我在你们那里，就是我如今不在你们那里，更是顺服的，就当恐惧战兢做成你们得救的工夫。"},
{volumn:        50,chapter:         2,versenumber:        13,verse:"因为你们立志行事都是　神在你们心里运行，为要成就他的美意。"},
{volumn:        50,chapter:         2,versenumber:        14,verse:"凡所行的，都不要发怨言，起争论，"},
{volumn:        50,chapter:         2,versenumber:        15,verse:"使你们无可指摘，诚实无伪，在这弯曲悖谬的世代作　神无瑕疵的儿女。你们显在这世代中，好像明光照耀，"},
{volumn:        50,chapter:         2,versenumber:        16,verse:"将生命的道表明出来，叫我在基督的日子好夸我没有空跑，也没有徒劳。"},
{volumn:        50,chapter:         2,versenumber:        17,verse:"我以你们的信心为供献的祭物，我若被浇奠在其上，也是喜乐，并且与你们众人一同喜乐。"},
{volumn:        50,chapter:         2,versenumber:        18,verse:"你们也要照样喜乐，并且与我一同喜乐。"},
{volumn:        50,chapter:         2,versenumber:        19,verse:"我靠主耶稣指望快打发提摩太去见你们，叫我知道你们的事，心里就得着安慰。"},
{volumn:        50,chapter:         2,versenumber:        20,verse:"因为我没有别人与我同心，实在挂念你们的事。"},
{volumn:        50,chapter:         2,versenumber:        21,verse:"别人都求自己的事，并不求耶稣基督的事。"},
{volumn:        50,chapter:         2,versenumber:        22,verse:"但你们知道提摩太的明证；他兴旺福音，与我同劳，待我像儿子待父亲一样。"},
{volumn:        50,chapter:         2,versenumber:        23,verse:"所以，我一看出我的事要怎样了结，就盼望立刻打发他去；"},
{volumn:        50,chapter:         2,versenumber:        24,verse:"但我靠着主自信我也必快去。"},
{volumn:        50,chapter:         2,versenumber:        25,verse:"然而，我想必须打发以巴弗提到你们那里去。他是我的兄弟，与我一同做工，一同当兵，是你们所差遣的，也是供给我需用的。"},
{volumn:        50,chapter:         2,versenumber:        26,verse:"他很想念你们众人，并且极其难过，因为你们听见他病了。"},
{volumn:        50,chapter:         2,versenumber:        27,verse:"他实在是病了，几乎要死；然而　神怜恤他，不但怜恤他，也怜恤我，免得我忧上加忧。"},
{volumn:        50,chapter:         2,versenumber:        28,verse:"所以我越发急速打发他去，叫你们再见他，就可以喜乐，我也可以少些忧愁。"},
{volumn:        50,chapter:         2,versenumber:        29,verse:"故此，你们要在主里欢欢乐乐地接待他，而且要尊重这样的人；"},
{volumn:        50,chapter:         2,versenumber:        30,verse:"因他为做基督的工夫，几乎至死，不顾性命，要补足你们供给我的不及之处。"},
{volumn:        50,chapter:         3,versenumber:         1,verse:"弟兄们，我还有话说，你们要靠主喜乐。我把这话再写给你们，于我并不为难，于你们却是妥当。"},
{volumn:        50,chapter:         3,versenumber:         2,verse:"应当防备犬类，防备作恶的，防备妄自行割的。"},
{volumn:        50,chapter:         3,versenumber:         3,verse:"因为真受割礼的，乃是我们这以　神的灵敬拜、在基督耶稣里夸口、不靠着肉体的。"},
{volumn:        50,chapter:         3,versenumber:         4,verse:"其实，我也可以靠肉体；若是别人想他可以靠肉体，我更可以靠着了。"},
{volumn:        50,chapter:         3,versenumber:         5,verse:"我第八天受割礼；我是以色列族、便雅悯支派的人，是希伯来人所生的希伯来人。就律法说，我是法利赛人；"},
{volumn:        50,chapter:         3,versenumber:         6,verse:"就热心说，我是逼迫教会的；就律法上的义说，我是无可指摘的。"},
{volumn:        50,chapter:         3,versenumber:         7,verse:"只是我先前以为与我有益的，我现在因基督都当作有损的。"},
{volumn:        50,chapter:         3,versenumber:         8,verse:"不但如此，我也将万事当作有损的，因我以认识我主基督耶稣为至宝。我为他已经丢弃万事，看作粪土，为要得着基督；"},
{volumn:        50,chapter:         3,versenumber:         9,verse:"并且得以在他里面，不是有自己因律法而得的义，乃是有信基督的义，就是因信　神而来的义，"},
{volumn:        50,chapter:         3,versenumber:        10,verse:"使我认识基督，晓得他复活的大能，并且晓得和他一同受苦，效法他的死，"},
{volumn:        50,chapter:         3,versenumber:        11,verse:"或者我也得以从死里复活。"},
{volumn:        50,chapter:         3,versenumber:        12,verse:"这不是说我已经得着了，已经完全了；我乃是竭力追求，或者可以得着基督耶稣所以得着我的（所以得着我的：或译所要我得的）。"},
{volumn:        50,chapter:         3,versenumber:        13,verse:"弟兄们，我不是以为自己已经得着了；我只有一件事，就是忘记背后，努力面前的，"},
{volumn:        50,chapter:         3,versenumber:        14,verse:"向着标竿直跑，要得　神在基督耶稣里从上面召我来得的奖赏。"},
{volumn:        50,chapter:         3,versenumber:        15,verse:"所以我们中间，凡是完全人总要存这样的心；若在什么事上存别样的心，　神也必以此指示你们。"},
{volumn:        50,chapter:         3,versenumber:        16,verse:"然而，我们到了什么地步，就当照着什么地步行。"},
{volumn:        50,chapter:         3,versenumber:        17,verse:"弟兄们，你们要一同效法我，也当留意看那些照我们榜样行的人。"},
{volumn:        50,chapter:         3,versenumber:        18,verse:"因为有许多人行事是基督十字架的仇敌。我屡次告诉你们，现在又流泪地告诉你们："},
{volumn:        50,chapter:         3,versenumber:        19,verse:"他们的结局就是沉沦；他们的神就是自己的肚腹。他们以自己的羞辱为荣耀，专以地上的事为念。"},
{volumn:        50,chapter:         3,versenumber:        20,verse:"我们却是天上的国民，并且等候救主，就是主耶稣基督从天上降临。"},
{volumn:        50,chapter:         3,versenumber:        21,verse:"他要按着那能叫万有归服自己的大能，将我们这卑贱的身体改变形状，和他自己荣耀的身体相似。"},
{volumn:        50,chapter:         4,versenumber:         1,verse:"我所亲爱、所想念的弟兄们，你们就是我的喜乐，我的冠冕。我亲爱的弟兄，你们应当靠主站立得稳。"},
{volumn:        50,chapter:         4,versenumber:         2,verse:"我劝友阿爹和循都基，要在主里同心。"},
{volumn:        50,chapter:         4,versenumber:         3,verse:"我也求你这真实同负一轭的，帮助这两个女人，因为她们在福音上曾与我一同劳苦；还有革利免，并其余和我一同做工的，他们的名字都在生命册上。"},
{volumn:        50,chapter:         4,versenumber:         4,verse:"你们要靠主常常喜乐。我再说，你们要喜乐。"},
{volumn:        50,chapter:         4,versenumber:         5,verse:"当叫众人知道你们谦让的心。主已经近了。"},
{volumn:        50,chapter:         4,versenumber:         6,verse:"应当一无挂虑，只要凡事藉着祷告、祈求，和感谢，将你们所要的告诉　神。"},
{volumn:        50,chapter:         4,versenumber:         7,verse:"神所赐出人意外的平安，必在基督耶稣里保守你们的心怀意念。"},
{volumn:        50,chapter:         4,versenumber:         8,verse:"弟兄们，我还有未尽的话：凡是真实的、可敬的、公义的、清洁的、可爱的、有美名的，若有什么德行，若有什么称赞，这些事你们都要思念。"},
{volumn:        50,chapter:         4,versenumber:         9,verse:"你们在我身上所学习的，所领受的，所听见的，所看见的，这些事你们都要去行，赐平安的　神就必与你们同在。"},
{volumn:        50,chapter:         4,versenumber:        10,verse:"我靠主大大地喜乐，因为你们思念我的心如今又发生；你们向来就思念我，只是没得机会。"},
{volumn:        50,chapter:         4,versenumber:        11,verse:"我并不是因缺乏说这话；我无论在什么景况都可以知足，这是我已经学会了。"},
{volumn:        50,chapter:         4,versenumber:        12,verse:"我知道怎样处卑贱，也知道怎样处丰富；或饱足，或饥饿；或有余，或缺乏，随事随在，我都得了秘诀。"},
{volumn:        50,chapter:         4,versenumber:        13,verse:"我靠着那加给我力量的，凡事都能作。"},
{volumn:        50,chapter:         4,versenumber:        14,verse:"然而，你们和我同受患难原是美事。"},
{volumn:        50,chapter:         4,versenumber:        15,verse:"腓立比人哪，你们也知道我初传福音离了马其顿的时候，论到授受的事，除了你们以外，并没有别的教会供给我。"},
{volumn:        50,chapter:         4,versenumber:        16,verse:"就是我在帖撒罗尼迦，你们也一次两次地打发人供给我的需用。"},
{volumn:        50,chapter:         4,versenumber:        17,verse:"我并不求什么馈送，所求的就是你们的果子渐渐增多，归在你们的帐上。"},
{volumn:        50,chapter:         4,versenumber:        18,verse:"但我样样都有，并且有余。我已经充足，因我从以巴弗提受了你们的馈送，当作极美的香气，为　神所收纳、所喜悦的祭物。"},
{volumn:        50,chapter:         4,versenumber:        19,verse:"我的　神必照他荣耀的丰富，在基督耶稣里，使你们一切所需用的都充足。"},
{volumn:        50,chapter:         4,versenumber:        20,verse:"愿荣耀归给我们的父　神，直到永永远远。阿们！"},
{volumn:        50,chapter:         4,versenumber:        21,verse:"请问在基督耶稣里的各位圣徒安。在我这里的众弟兄都问你们安。"},
{volumn:        50,chapter:         4,versenumber:        22,verse:"众圣徒都问你们安。在凯撒家里的人特特地问你们安。"},
{volumn:        50,chapter:         4,versenumber:        23,verse:"愿主耶稣基督的恩常在你们心里！"},
{volumn:        51,chapter:         1,versenumber:         1,verse:"奉　神旨意，作基督耶稣使徒的保罗和兄弟提摩太"},
{volumn:        51,chapter:         1,versenumber:         2,verse:"写信给歌罗西的圣徒，在基督里有忠心的弟兄。愿恩惠、平安从　神我们的父归与你们！"},
{volumn:        51,chapter:         1,versenumber:         3,verse:"我们感谢　神我们主耶稣基督的父，常常为你们祷告；"},
{volumn:        51,chapter:         1,versenumber:         4,verse:"因听见你们在基督耶稣里的信心，并向众圣徒的爱心，"},
{volumn:        51,chapter:         1,versenumber:         5,verse:"是为那给你们存在天上的盼望；这盼望就是你们从前在福音真理的道上所听见的。"},
{volumn:        51,chapter:         1,versenumber:         6,verse:"这福音传到你们那里，也传到普天之下，并且结果，增长，如同在你们中间，自从你们听见福音，真知道　神恩惠的日子一样。"},
{volumn:        51,chapter:         1,versenumber:         7,verse:"正如你们从我们所亲爱、一同作仆人的以巴弗所学的。他为我们（有古卷：你们）作了基督忠心的执事，"},
{volumn:        51,chapter:         1,versenumber:         8,verse:"也把你们因圣灵所存的爱心告诉了我们。"},
{volumn:        51,chapter:         1,versenumber:         9,verse:"因此，我们自从听见的日子，也就为你们不住地祷告祈求，愿你们在一切属灵的智慧悟性上，满心知道　神的旨意；"},
{volumn:        51,chapter:         1,versenumber:        10,verse:"好叫你们行事为人对得起主，凡事蒙他喜悦，在一切善事上结果子，渐渐地多知道　神；"},
{volumn:        51,chapter:         1,versenumber:        11,verse:"照他荣耀的权能，得以在各样的力上加力，好叫你们凡事欢欢喜喜地忍耐宽容；"},
{volumn:        51,chapter:         1,versenumber:        12,verse:"又感谢父，叫我们能与众圣徒在光明中同得基业。"},
{volumn:        51,chapter:         1,versenumber:        13,verse:"他救了我们脱离黑暗的权势，把我们迁到他爱子的国里；"},
{volumn:        51,chapter:         1,versenumber:        14,verse:"我们在爱子里得蒙救赎，罪过得以赦免。"},
{volumn:        51,chapter:         1,versenumber:        15,verse:"爱子是那不能看见之　神的像，是首生的，在一切被造的以先。"},
{volumn:        51,chapter:         1,versenumber:        16,verse:"因为万有都是靠他造的，无论是天上的，地上的；能看见的，不能看见的；或是有位的，主治的，执政的，掌权的；一概都是藉着他造的，又是为他造的。"},
{volumn:        51,chapter:         1,versenumber:        17,verse:"他在万有之先；万有也靠他而立。"},
{volumn:        51,chapter:         1,versenumber:        18,verse:"他也是教会全体之首。他是元始，是从死里首先复生的，使他可以在凡事上居首位。"},
{volumn:        51,chapter:         1,versenumber:        19,verse:"因为父喜欢叫一切的丰盛在他里面居住。"},
{volumn:        51,chapter:         1,versenumber:        20,verse:"既然藉着他在十字架上所流的血成就了和平，便藉着他叫万有，无论是地上的、天上的，都与自己和好了。"},
{volumn:        51,chapter:         1,versenumber:        21,verse:"你们从前与　神隔绝，因着恶行，心里与他为敌。"},
{volumn:        51,chapter:         1,versenumber:        22,verse:"但如今他藉着基督的肉身受死，叫你们与自己和好，都成了圣洁，没有瑕疵，无可责备，把你们引到自己面前。"},
{volumn:        51,chapter:         1,versenumber:        23,verse:"只要你们在所信的道上恒心，根基稳固，坚定不移，不至被引动失去（原文是离开）福音的盼望。这福音就是你们所听过的，也是传与普天下万人听的（原文是凡受造的），我保罗也作了这福音的执事。"},
{volumn:        51,chapter:         1,versenumber:        24,verse:"现在我为你们受苦，倒觉欢乐；并且为基督的身体，就是为教会，要在我肉身上补满基督患难的缺欠。"},
{volumn:        51,chapter:         1,versenumber:        25,verse:"我照　神为你们所赐我的职分作了教会的执事，要把　神的道理传得全备，"},
{volumn:        51,chapter:         1,versenumber:        26,verse:"这道理就是历世历代所隐藏的奥秘；但如今向他的圣徒显明了。"},
{volumn:        51,chapter:         1,versenumber:        27,verse:"神愿意叫他们知道，这奥秘在外邦人中有何等丰盛的荣耀，就是基督在你们心里成了有荣耀的盼望。"},
{volumn:        51,chapter:         1,versenumber:        28,verse:"我们传扬他，是用诸般的智慧，劝戒各人，教导各人，要把各人在基督里完完全全地引到　神面前。"},
{volumn:        51,chapter:         1,versenumber:        29,verse:"我也为此劳苦，照着他在我里面运用的大能尽心竭力。"},
{volumn:        51,chapter:         2,versenumber:         1,verse:"我愿意你们晓得，我为你们和老底嘉人，并一切没有与我亲自见面的人，是何等地尽心竭力；"},
{volumn:        51,chapter:         2,versenumber:         2,verse:"要叫他们的心得安慰，因爱心互相联络，以致丰丰足足在悟性中有充足的信心，使他们真知　神的奥秘，就是基督；"},
{volumn:        51,chapter:         2,versenumber:         3,verse:"所积蓄的一切智慧知识，都在他里面藏着。"},
{volumn:        51,chapter:         2,versenumber:         4,verse:"我说这话，免得有人用花言巧语迷惑你们。"},
{volumn:        51,chapter:         2,versenumber:         5,verse:"我身子虽与你们相离，心却与你们同在，见你们循规蹈矩，信基督的心也坚固，我就欢喜了。"},
{volumn:        51,chapter:         2,versenumber:         6,verse:"你们既然接受了主基督耶稣，就当遵他而行，"},
{volumn:        51,chapter:         2,versenumber:         7,verse:"在他里面生根建造，信心坚固，正如你们所领的教训，感谢的心也更增长了。"},
{volumn:        51,chapter:         2,versenumber:         8,verse:"你们要谨慎，恐怕有人用他的理学和虚空的妄言，不照着基督，乃照人间的遗传和世上的小学就把你们掳去。"},
{volumn:        51,chapter:         2,versenumber:         9,verse:"因为　神本性一切的丰盛都有形有体地居住在基督里面，"},
{volumn:        51,chapter:         2,versenumber:        10,verse:"你们在他里面也得了丰盛。他是各样执政掌权者的元首。"},
{volumn:        51,chapter:         2,versenumber:        11,verse:"你们在他里面也受了不是人手所行的割礼，乃是基督使你们脱去肉体情欲的割礼。"},
{volumn:        51,chapter:         2,versenumber:        12,verse:"你们既受洗与他一同埋葬，也就在此与他一同复活，都因信那叫他从死里复活　神的功用。"},
{volumn:        51,chapter:         2,versenumber:        13,verse:"你们从前在过犯和未受割礼的肉体中死了，　神赦免了你们（或译：我们）一切过犯，便叫你们与基督一同活过来；"},
{volumn:        51,chapter:         2,versenumber:        14,verse:"又涂抹了在律例上所写、攻击我们、有碍于我们的字据，把它撤去，钉在十字架上。"},
{volumn:        51,chapter:         2,versenumber:        15,verse:"既将一切执政的、掌权的掳来，明显给众人看，就仗着十字架夸胜。"},
{volumn:        51,chapter:         2,versenumber:        16,verse:"所以，不拘在饮食上，或节期、月朔、安息日都不可让人论断你们。"},
{volumn:        51,chapter:         2,versenumber:        17,verse:"这些原是后事的影儿；那形体却是基督。"},
{volumn:        51,chapter:         2,versenumber:        18,verse:"不可让人因着故意谦虚和敬拜天使，就夺去你们的奖赏。这等人拘泥在所见过的（有古卷：这等人窥察所没有见过的），随着自己的欲心，无故地自高自大，"},
{volumn:        51,chapter:         2,versenumber:        19,verse:"不持定元首。全身既然靠着他，筋节得以相助联络，就因　神大得长进。"},
{volumn:        51,chapter:         2,versenumber:        20,verse:"你们若是与基督同死，脱离了世上的小学，为什么仍像在世俗中活着、服从那“不可拿、不可尝、不可摸”等类的规条呢？"},
{volumn:        51,chapter:         2,versenumber:        21,verse:""},
{volumn:        51,chapter:         2,versenumber:        22,verse:"这都是照人所吩咐、所教导的。说到这一切，正用的时候就都败坏了。"},
{volumn:        51,chapter:         2,versenumber:        23,verse:"这些规条使人徒有智慧之名，用私意崇拜，自表谦卑，苦待己身，其实在克制肉体的情欲上是毫无功效。"},
{volumn:        51,chapter:         3,versenumber:         1,verse:"所以，你们若真与基督一同复活，就当求在上面的事；那里有基督坐在　神的右边。"},
{volumn:        51,chapter:         3,versenumber:         2,verse:"你们要思念上面的事，不要思念地上的事。"},
{volumn:        51,chapter:         3,versenumber:         3,verse:"因为你们已经死了，你们的生命与基督一同藏在　神里面。"},
{volumn:        51,chapter:         3,versenumber:         4,verse:"基督是我们的生命，他显现的时候，你们也要与他一同显现在荣耀里。"},
{volumn:        51,chapter:         3,versenumber:         5,verse:"所以，要治死你们在地上的肢体，就如淫乱、污秽、邪情、恶欲，和贪婪（贪婪就与拜偶像一样）。"},
{volumn:        51,chapter:         3,versenumber:         6,verse:"因这些事，　神的忿怒必临到那悖逆之子。"},
{volumn:        51,chapter:         3,versenumber:         7,verse:"当你们在这些事中活着的时候，也曾这样行过。"},
{volumn:        51,chapter:         3,versenumber:         8,verse:"但现在你们要弃绝这一切的事，以及恼恨、忿怒、恶毒（或译：阴毒）、毁谤，并口中污秽的言语。"},
{volumn:        51,chapter:         3,versenumber:         9,verse:"不要彼此说谎；因你们已经脱去旧人和旧人的行为，"},
{volumn:        51,chapter:         3,versenumber:        10,verse:"穿上了新人。这新人在知识上渐渐更新，正如造他主的形像。"},
{volumn:        51,chapter:         3,versenumber:        11,verse:"在此并不分希腊人、犹太人，受割礼的、未受割礼的，化外人、西古提人，为奴的、自主的，惟有基督是包括一切，又住在各人之内。"},
{volumn:        51,chapter:         3,versenumber:        12,verse:"所以，你们既是　神的选民，圣洁蒙爱的人，就要存（原文是穿；下同）怜悯、恩慈、谦虚、温柔、忍耐的心。"},
{volumn:        51,chapter:         3,versenumber:        13,verse:"倘若这人与那人有嫌隙，总要彼此包容，彼此饶恕；主怎样饶恕了你们，你们也要怎样饶恕人。"},
{volumn:        51,chapter:         3,versenumber:        14,verse:"在这一切之外，要存着爱心，爱心就是联络全德的。"},
{volumn:        51,chapter:         3,versenumber:        15,verse:"又要叫基督的平安在你们心里作主；你们也为此蒙召，归为一体；且要存感谢的心。"},
{volumn:        51,chapter:         3,versenumber:        16,verse:"当用各样的智慧，把基督的道理丰丰富富地存在心里（或译：当把基督的道理丰丰富富地存在心里，以各样的智慧），用诗章、颂词、灵歌，彼此教导，互相劝戒，心被恩感，歌颂　神。"},
{volumn:        51,chapter:         3,versenumber:        17,verse:"无论做什么，或说话或行事，都要奉主耶稣的名，藉着他感谢父　神。"},
{volumn:        51,chapter:         3,versenumber:        18,verse:"你们作妻子的，当顺服自己的丈夫，这在主里面是相宜的。"},
{volumn:        51,chapter:         3,versenumber:        19,verse:"你们作丈夫的，要爱你们的妻子，不可苦待她们。"},
{volumn:        51,chapter:         3,versenumber:        20,verse:"你们作儿女的，要凡事听从父母，因为这是主所喜悦的。"},
{volumn:        51,chapter:         3,versenumber:        21,verse:"你们作父亲的，不要惹儿女的气，恐怕他们失了志气。"},
{volumn:        51,chapter:         3,versenumber:        22,verse:"你们作仆人的，要凡事听从你们肉身的主人，不要只在眼前侍奉，像是讨人喜欢的，总要存心诚实敬畏主。"},
{volumn:        51,chapter:         3,versenumber:        23,verse:"无论做什么，都要从心里做，像是给主做的，不是给人做的，"},
{volumn:        51,chapter:         3,versenumber:        24,verse:"因你们知道从主那里必得着基业为赏赐；你们所侍奉的乃是主基督。"},
{volumn:        51,chapter:         3,versenumber:        25,verse:"那行不义的必受不义的报应；主并不偏待人。"},
{volumn:        51,chapter:         4,versenumber:         1,verse:"你们作主人的，要公公平平地待仆人，因为知道你们也有一位主在天上。"},
{volumn:        51,chapter:         4,versenumber:         2,verse:"你们要恒切祷告，在此警醒感恩。"},
{volumn:        51,chapter:         4,versenumber:         3,verse:"也要为我们祷告，求　神给我们开传道的门，能以讲基督的奥秘（我为此被捆锁），"},
{volumn:        51,chapter:         4,versenumber:         4,verse:"叫我按着所该说的话将这奥秘发明出来。"},
{volumn:        51,chapter:         4,versenumber:         5,verse:"你们要爱惜光阴，用智慧与外人交往。"},
{volumn:        51,chapter:         4,versenumber:         6,verse:"你们的言语要常常带着和气，好像用盐调和，就可知道该怎样回答各人。"},
{volumn:        51,chapter:         4,versenumber:         7,verse:"有我亲爱的兄弟推基古要将我一切的事都告诉你们。他是忠心的执事，和我一同作主的仆人。"},
{volumn:        51,chapter:         4,versenumber:         8,verse:"我特意打发他到你们那里去，好叫你们知道我们的光景，又叫他安慰你们的心。"},
{volumn:        51,chapter:         4,versenumber:         9,verse:"我又打发一位亲爱忠心的兄弟阿尼西母同去；他也是你们那里的人。他们要把这里一切的事都告诉你们。"},
{volumn:        51,chapter:         4,versenumber:        10,verse:"与我一同坐监的亚里达古问你们安。巴拿巴的表弟马可也问你们安。（说到这马可，你们已经受了吩咐；他若到了你们那里，你们就接待他。）"},
{volumn:        51,chapter:         4,versenumber:        11,verse:"耶数又称为犹士都，也问你们安。奉割礼的人中，只有这三个人是为　神的国与我一同做工的，也是叫我心里得安慰的。"},
{volumn:        51,chapter:         4,versenumber:        12,verse:"有你们那里的人，作基督耶稣仆人的以巴弗问你们安。他在祷告之间，常为你们竭力地祈求，愿你们在　神一切的旨意上得以完全，信心充足，能站立得稳。"},
{volumn:        51,chapter:         4,versenumber:        13,verse:"他为你们和老底嘉并希拉坡里的弟兄多多地劳苦，这是我可以给他作见证的。"},
{volumn:        51,chapter:         4,versenumber:        14,verse:"所亲爱的医生路加和底马问你们安。"},
{volumn:        51,chapter:         4,versenumber:        15,verse:"请问老底嘉的弟兄和宁法，并她家里的教会安。"},
{volumn:        51,chapter:         4,versenumber:        16,verse:"你们念了这书信，便交给老底嘉的教会，叫他们也念；你们也要念从老底嘉来的书信。"},
{volumn:        51,chapter:         4,versenumber:        17,verse:"要对亚基布说：“务要谨慎，尽你从主所受的职分。”"},
{volumn:        51,chapter:         4,versenumber:        18,verse:"我保罗亲笔问你们安。你们要记念我的捆锁。愿恩惠常与你们同在！"},
{volumn:        52,chapter:         1,versenumber:         1,verse:"保罗、西拉、提摩太写信给帖撒罗尼迦在父　神和主耶稣基督里的教会。愿恩惠、平安归与你们！"},
{volumn:        52,chapter:         1,versenumber:         2,verse:"我们为你们众人常常感谢　神，祷告的时候提到你们，"},
{volumn:        52,chapter:         1,versenumber:         3,verse:"在　神我们的父面前，不住地记念你们因信心所做的工夫，因爱心所受的劳苦，因盼望我们主耶稣基督所存的忍耐。"},
{volumn:        52,chapter:         1,versenumber:         4,verse:"被　神所爱的弟兄啊，我知道你们是蒙拣选的；"},
{volumn:        52,chapter:         1,versenumber:         5,verse:"因为我们的福音传到你们那里，不独在乎言语，也在乎权能和圣灵，并充足的信心。正如你们知道，我们在你们那里，为你们的缘故是怎样为人。"},
{volumn:        52,chapter:         1,versenumber:         6,verse:"并且你们在大难之中，蒙了圣灵所赐的喜乐，领受真道就效法我们，也效法了主；"},
{volumn:        52,chapter:         1,versenumber:         7,verse:"甚至你们作了马其顿和亚该亚所有信主之人的榜样。"},
{volumn:        52,chapter:         1,versenumber:         8,verse:"因为主的道从你们那里已经传扬出来。你们向　神的信心不但在马其顿和亚该亚，就是在各处也都传开了，所以不用我们说什么话。"},
{volumn:        52,chapter:         1,versenumber:         9,verse:"因为他们自己已经报明我们是怎样进到你们那里，你们是怎样离弃偶像，归向　神，要服侍那又真又活的　神，"},
{volumn:        52,chapter:         1,versenumber:        10,verse:"等候他儿子从天降临，就是他从死里复活的、那位救我们脱离将来忿怒的耶稣。"},
{volumn:        52,chapter:         2,versenumber:         1,verse:"弟兄们，你们自己原晓得我们进到你们那里并不是徒然的。"},
{volumn:        52,chapter:         2,versenumber:         2,verse:"我们从前在腓立比被害受辱，这是你们知道的；然而还是靠我们的　神放开胆量，在大争战中把　神的福音传给你们。"},
{volumn:        52,chapter:         2,versenumber:         3,verse:"我们的劝勉不是出于错误，不是出于污秽，也不是用诡诈。"},
{volumn:        52,chapter:         2,versenumber:         4,verse:"但　神既然验中了我们，把福音托付我们，我们就照样讲，不是要讨人喜欢，乃是要讨那察验我们心的　神喜欢。"},
{volumn:        52,chapter:         2,versenumber:         5,verse:"因为我们从来没有用过谄媚的话，这是你们知道的；也没有藏着贪心，这是　神可以作见证的。"},
{volumn:        52,chapter:         2,versenumber:         6,verse:"我们作基督的使徒，虽然可以叫人尊重，却没有向你们或向别人求荣耀；"},
{volumn:        52,chapter:         2,versenumber:         7,verse:"只在你们中间存心温柔，如同母亲乳养自己的孩子。"},
{volumn:        52,chapter:         2,versenumber:         8,verse:"我们既是这样爱你们，不但愿意将　神的福音给你们，连自己的性命也愿意给你们，因你们是我们所疼爱的。"},
{volumn:        52,chapter:         2,versenumber:         9,verse:"弟兄们，你们记念我们的辛苦劳碌，昼夜做工，传　神的福音给你们，免得叫你们一人受累。"},
{volumn:        52,chapter:         2,versenumber:        10,verse:"我们向你们信主的人，是何等圣洁、公义、无可指摘，有你们作见证，也有　神作见证。"},
{volumn:        52,chapter:         2,versenumber:        11,verse:"你们也晓得，我们怎样劝勉你们，安慰你们，嘱咐你们各人，好像父亲待自己的儿女一样，"},
{volumn:        52,chapter:         2,versenumber:        12,verse:"要叫你们行事对得起那召你们进他国、得他荣耀的　神。"},
{volumn:        52,chapter:         2,versenumber:        13,verse:"为此，我们也不住地感谢　神，因你们听见我们所传　神的道就领受了；不以为是人的道，乃以为是　神的道。这道实在是　神的，并且运行在你们信主的人心中。"},
{volumn:        52,chapter:         2,versenumber:        14,verse:"弟兄们，你们曾效法犹太中在基督耶稣里　神的各教会；因为你们也受了本地人的苦害，像他们受了犹太人的苦害一样。"},
{volumn:        52,chapter:         2,versenumber:        15,verse:"这犹太人杀了主耶稣和先知，又把我们赶出去。他们不得　神的喜悦，且与众人为敌，"},
{volumn:        52,chapter:         2,versenumber:        16,verse:"不许我们传道给外邦人使外邦人得救，常常充满自己的罪恶。　神的忿怒临在他们身上已经到了极处。"},
{volumn:        52,chapter:         2,versenumber:        17,verse:"弟兄们，我们暂时与你们离别，是面目离别，心里却不离别；我们极力地想法子，很愿意见你们的面。"},
{volumn:        52,chapter:         2,versenumber:        18,verse:"所以我们有意到你们那里；我保罗有一两次要去，只是撒但阻挡了我们。"},
{volumn:        52,chapter:         2,versenumber:        19,verse:"我们的盼望和喜乐，并所夸的冠冕是什么呢？岂不是我们主耶稣来的时候、你们在他面前站立得住吗？"},
{volumn:        52,chapter:         2,versenumber:        20,verse:"因为你们就是我们的荣耀，我们的喜乐。"},
{volumn:        52,chapter:         3,versenumber:         1,verse:"我们既不能再忍，就愿意独自等在雅典，"},
{volumn:        52,chapter:         3,versenumber:         2,verse:"打发我们的兄弟在基督福音上作　神执事的（作神执事的：有古卷是与神同工的）提摩太前去，坚固你们，并在你们所信的道上劝慰你们，"},
{volumn:        52,chapter:         3,versenumber:         3,verse:"免得有人被诸般患难摇动。因为你们自己知道，我们受患难原是命定的。"},
{volumn:        52,chapter:         3,versenumber:         4,verse:"我们在你们那里的时候，预先告诉你们，我们必受患难，以后果然应验了，你们也知道。"},
{volumn:        52,chapter:         3,versenumber:         5,verse:"为此，我既不能再忍，就打发人去，要晓得你们的信心如何，恐怕那诱惑人的到底诱惑了你们，叫我们的劳苦归于徒然。"},
{volumn:        52,chapter:         3,versenumber:         6,verse:"但提摩太刚才从你们那里回来，将你们信心和爱心的好消息报给我们，又说你们常常记念我们，切切地想见我们，如同我们想见你们一样。"},
{volumn:        52,chapter:         3,versenumber:         7,verse:"所以，弟兄们，我们在一切困苦患难之中，因着你们的信心就得了安慰。"},
{volumn:        52,chapter:         3,versenumber:         8,verse:"你们若靠主站立得稳，我们就活了。"},
{volumn:        52,chapter:         3,versenumber:         9,verse:"我们在　神面前，因着你们甚是喜乐；为这一切喜乐，可用何等的感谢为你们报答　神呢？"},
{volumn:        52,chapter:         3,versenumber:        10,verse:"我们昼夜切切地祈求，要见你们的面，补满你们信心的不足。"},
{volumn:        52,chapter:         3,versenumber:        11,verse:"愿　神我们的父和我们的主耶稣一直引领我们到你们那里去。"},
{volumn:        52,chapter:         3,versenumber:        12,verse:"又愿主叫你们彼此相爱的心，并爱众人的心都能增长，充足，如同我们爱你们一样，"},
{volumn:        52,chapter:         3,versenumber:        13,verse:"好使你们当我们主耶稣同他众圣徒来的时候，在我们父　神面前，心里坚固，成为圣洁，无可责备。"},
{volumn:        52,chapter:         4,versenumber:         1,verse:"弟兄们，我还有话说：我们靠着主耶稣求你们，劝你们，你们既然受了我们的教训，知道该怎样行可以讨　神的喜悦，就要照你们现在所行的更加勉励。"},
{volumn:        52,chapter:         4,versenumber:         2,verse:"你们原晓得，我们凭主耶稣传给你们什么命令。"},
{volumn:        52,chapter:         4,versenumber:         3,verse:"神的旨意就是要你们成为圣洁，远避淫行；"},
{volumn:        52,chapter:         4,versenumber:         4,verse:"要你们各人晓得怎样用圣洁、尊贵守着自己的身体，"},
{volumn:        52,chapter:         4,versenumber:         5,verse:"不放纵私欲的邪情，像那不认识　神的外邦人。"},
{volumn:        52,chapter:         4,versenumber:         6,verse:"不要一个人在这事上越分，欺负他的弟兄；因为这一类的事，主必报应，正如我预先对你们说过、又切切嘱咐你们的。"},
{volumn:        52,chapter:         4,versenumber:         7,verse:"神召我们，本不是要我们沾染污秽，乃是要我们成为圣洁。"},
{volumn:        52,chapter:         4,versenumber:         8,verse:"所以，那弃绝的，不是弃绝人，乃是弃绝那赐圣灵给你们的　神。"},
{volumn:        52,chapter:         4,versenumber:         9,verse:"论到弟兄们相爱，不用人写信给你们；因为你们自己蒙了　神的教训，叫你们彼此相爱。"},
{volumn:        52,chapter:         4,versenumber:        10,verse:"你们向马其顿全地的众弟兄固然是这样行，但我劝弟兄们要更加勉励。"},
{volumn:        52,chapter:         4,versenumber:        11,verse:"又要立志作安静人，办自己的事，亲手做工，正如我们从前所吩咐你们的，"},
{volumn:        52,chapter:         4,versenumber:        12,verse:"叫你们可以向外人行事端正，自己也就没有什么缺乏了。"},
{volumn:        52,chapter:         4,versenumber:        13,verse:"论到睡了的人，我们不愿意弟兄们不知道，恐怕你们忧伤，像那些没有指望的人一样。"},
{volumn:        52,chapter:         4,versenumber:        14,verse:"我们若信耶稣死而复活了，那已经在耶稣里睡了的人，　神也必将他们与耶稣一同带来。"},
{volumn:        52,chapter:         4,versenumber:        15,verse:"我们现在照主的话告诉你们一件事：我们这活着还存留到主降临的人，断不能在那已经睡了的人之先。"},
{volumn:        52,chapter:         4,versenumber:        16,verse:"因为主必亲自从天降临，有呼叫的声音和天使长的声音，又有　神的号吹响；那在基督里死了的人必先复活。"},
{volumn:        52,chapter:         4,versenumber:        17,verse:"以后我们这活着还存留的人必和他们一同被提到云里，在空中与主相遇。这样，我们就要和主永远同在。"},
{volumn:        52,chapter:         4,versenumber:        18,verse:"所以，你们当用这些话彼此劝慰。"},
{volumn:        52,chapter:         5,versenumber:         1,verse:"弟兄们，论到时候、日期，不用写信给你们，"},
{volumn:        52,chapter:         5,versenumber:         2,verse:"因为你们自己明明晓得，主的日子来到，好像夜间的贼一样。"},
{volumn:        52,chapter:         5,versenumber:         3,verse:"人正说“平安稳妥”的时候，灾祸忽然临到他们，如同产难临到怀胎的妇人一样，他们绝不能逃脱。"},
{volumn:        52,chapter:         5,versenumber:         4,verse:"弟兄们，你们却不在黑暗里，叫那日子临到你们像贼一样。"},
{volumn:        52,chapter:         5,versenumber:         5,verse:"你们都是光明之子，都是白昼之子。我们不是属黑夜的，也不是属幽暗的。"},
{volumn:        52,chapter:         5,versenumber:         6,verse:"所以，我们不要睡觉像别人一样，总要警醒谨守。"},
{volumn:        52,chapter:         5,versenumber:         7,verse:"因为睡了的人是在夜间睡，醉了的人是在夜间醉。"},
{volumn:        52,chapter:         5,versenumber:         8,verse:"但我们既然属乎白昼，就应当谨守，把信和爱当作护心镜遮胸，把得救的盼望当作头盔戴上。"},
{volumn:        52,chapter:         5,versenumber:         9,verse:"因为　神不是预定我们受刑，乃是预定我们藉着我们主耶稣基督得救。"},
{volumn:        52,chapter:         5,versenumber:        10,verse:"他替我们死，叫我们无论醒着，睡着，都与他同活。"},
{volumn:        52,chapter:         5,versenumber:        11,verse:"所以，你们该彼此劝慰，互相建立，正如你们素常所行的。"},
{volumn:        52,chapter:         5,versenumber:        12,verse:"弟兄们，我们劝你们敬重那在你们中间劳苦的人，就是在主里面治理你们、劝戒你们的；"},
{volumn:        52,chapter:         5,versenumber:        13,verse:"又因他们所做的工，用爱心格外尊重他们。你们也要彼此和睦。"},
{volumn:        52,chapter:         5,versenumber:        14,verse:"我们又劝弟兄们，要警戒不守规矩的人，勉励灰心的人，扶助软弱的人，也要向众人忍耐。"},
{volumn:        52,chapter:         5,versenumber:        15,verse:"你们要谨慎，无论是谁都不可以恶报恶；或是彼此相待，或是待众人，常要追求良善。"},
{volumn:        52,chapter:         5,versenumber:        16,verse:"要常常喜乐，"},
{volumn:        52,chapter:         5,versenumber:        17,verse:"不住地祷告，"},
{volumn:        52,chapter:         5,versenumber:        18,verse:"凡事谢恩；因为这是　神在基督耶稣里向你们所定的旨意。"},
{volumn:        52,chapter:         5,versenumber:        19,verse:"不要消灭圣灵的感动；"},
{volumn:        52,chapter:         5,versenumber:        20,verse:"不要藐视先知的讲论。"},
{volumn:        52,chapter:         5,versenumber:        21,verse:"但要凡事察验；善美的要持守，"},
{volumn:        52,chapter:         5,versenumber:        22,verse:"各样的恶事要禁戒不做。"},
{volumn:        52,chapter:         5,versenumber:        23,verse:"愿赐平安的　神亲自使你们全然成圣！又愿你们的灵与魂与身子得蒙保守，在我们主耶稣基督降临的时候，完全无可指摘！"},
{volumn:        52,chapter:         5,versenumber:        24,verse:"那召你们的本是信实的，他必成就这事。"},
{volumn:        52,chapter:         5,versenumber:        25,verse:"请弟兄们为我们祷告。"},
{volumn:        52,chapter:         5,versenumber:        26,verse:"与众弟兄亲嘴问安，务要圣洁。"},
{volumn:        52,chapter:         5,versenumber:        27,verse:"我指着主嘱咐你们，要把这信念给众弟兄听。"},
{volumn:        52,chapter:         5,versenumber:        28,verse:"愿我主耶稣基督的恩常与你们同在！"},
{volumn:        53,chapter:         1,versenumber:         1,verse:"保罗、西拉、提摩太写信给帖撒罗尼迦、在　神我们的父与主耶稣基督里的教会。"},
{volumn:        53,chapter:         1,versenumber:         2,verse:"愿恩惠、平安从父　神和主耶稣基督归与你们！"},
{volumn:        53,chapter:         1,versenumber:         3,verse:"弟兄们，我们该为你们常常感谢　神，这本是合宜的；因你们的信心格外增长，并且你们众人彼此相爱的心也都充足。"},
{volumn:        53,chapter:         1,versenumber:         4,verse:"甚至我们在　神的各教会里为你们夸口，都因你们在所受的一切逼迫患难中，仍旧存忍耐和信心。"},
{volumn:        53,chapter:         1,versenumber:         5,verse:"这正是　神公义判断的明证，叫你们可算配得　神的国；你们就是为这国受苦。"},
{volumn:        53,chapter:         1,versenumber:         6,verse:"神既是公义的，就必将患难报应那加患难给你们的人，"},
{volumn:        53,chapter:         1,versenumber:         7,verse:"也必使你们这受患难的人与我们同得平安。那时，主耶稣同他有能力的天使从天上在火焰中显现，"},
{volumn:        53,chapter:         1,versenumber:         8,verse:"要报应那不认识　神和那不听从我主耶稣福音的人。"},
{volumn:        53,chapter:         1,versenumber:         9,verse:"他们要受刑罚，就是永远沉沦，离开主的面和他权能的荣光。"},
{volumn:        53,chapter:         1,versenumber:        10,verse:"这正是主降临、要在他圣徒的身上得荣耀、又在一切信的人身上显为希奇的那日子。我们对你们作的见证，你们也信了。"},
{volumn:        53,chapter:         1,versenumber:        11,verse:"因此，我们常为你们祷告，愿我们的　神看你们配得过所蒙的召，又用大能成就你们一切所羡慕的良善和一切因信心所做的工夫，"},
{volumn:        53,chapter:         1,versenumber:        12,verse:"叫我们主耶稣的名在你们身上得荣耀，你们也在他身上得荣耀，都照着我们的　神并主耶稣基督的恩。"},
{volumn:        53,chapter:         2,versenumber:         1,verse:"弟兄们，论到我们主耶稣基督降临和我们到他那里聚集，"},
{volumn:        53,chapter:         2,versenumber:         2,verse:"我劝你们：无论有灵、有言语、有冒我名的书信，说主的日子现在（现在：或译就）到了，不要轻易动心，也不要惊慌。"},
{volumn:        53,chapter:         2,versenumber:         3,verse:"人不拘用什么法子，你们总不要被他诱惑；因为那日子以前，必有离道反教的事，并有那大罪人，就是沉沦之子，显露出来。"},
{volumn:        53,chapter:         2,versenumber:         4,verse:"他是抵挡主，高抬自己，超过一切称为神的和一切受人敬拜的，甚至坐在　神的殿里，自称是　神。"},
{volumn:        53,chapter:         2,versenumber:         5,verse:"我还在你们那里的时候，曾把这些事告诉你们，你们不记得吗？"},
{volumn:        53,chapter:         2,versenumber:         6,verse:"现在你们也知道，那拦阻他的是什么，是叫他到了的时候才可以显露。"},
{volumn:        53,chapter:         2,versenumber:         7,verse:"因为那不法的隐意已经发动，只是现在有一个拦阻的，等到那拦阻的被除去，"},
{volumn:        53,chapter:         2,versenumber:         8,verse:"那时这不法的人必显露出来。主耶稣要用口中的气灭绝他，用降临的荣光废掉他。"},
{volumn:        53,chapter:         2,versenumber:         9,verse:"这不法的人来，是照撒但的运动，行各样的异能、神迹，和一切虚假的奇事，"},
{volumn:        53,chapter:         2,versenumber:        10,verse:"并且在那沉沦的人身上行各样出于不义的诡诈；因他们不领受爱真理的心，使他们得救。"},
{volumn:        53,chapter:         2,versenumber:        11,verse:"故此，　神就给他们一个生发错误的心，叫他们信从虚谎，"},
{volumn:        53,chapter:         2,versenumber:        12,verse:"使一切不信真理、倒喜爱不义的人都被定罪。"},
{volumn:        53,chapter:         2,versenumber:        13,verse:"主所爱的弟兄们哪，我们本该常为你们感谢　神；因为他从起初拣选了你们，叫你们因信真道，又被圣灵感动，成为圣洁，能以得救。"},
{volumn:        53,chapter:         2,versenumber:        14,verse:"神藉我们所传的福音召你们到这地步，好得着我们主耶稣基督的荣光。"},
{volumn:        53,chapter:         2,versenumber:        15,verse:"所以，弟兄们，你们要站立得稳，凡所领受的教训，不拘是我们口传的，是信上写的，都要坚守。"},
{volumn:        53,chapter:         2,versenumber:        16,verse:"但愿我们主耶稣基督和那爱我们、开恩将永远的安慰并美好的盼望赐给我们的父　神，"},
{volumn:        53,chapter:         2,versenumber:        17,verse:"安慰你们的心，并且在一切善行善言上坚固你们。"},
{volumn:        53,chapter:         3,versenumber:         1,verse:"弟兄们，我还有话说：请你们为我们祷告，好叫主的道理快快行开，得着荣耀，正如在你们中间一样，"},
{volumn:        53,chapter:         3,versenumber:         2,verse:"也叫我们脱离无理之恶人的手；因为人不都是有信心。"},
{volumn:        53,chapter:         3,versenumber:         3,verse:"但主是信实的，要坚固你们，保护你们脱离那恶者（或译：脱离凶恶）。"},
{volumn:        53,chapter:         3,versenumber:         4,verse:"我们靠主深信，你们现在是遵行我们所吩咐的，后来也必要遵行。"},
{volumn:        53,chapter:         3,versenumber:         5,verse:"愿主引导你们的心，叫你们爱　神，并学基督的忍耐！"},
{volumn:        53,chapter:         3,versenumber:         6,verse:"弟兄们，我们奉主耶稣基督的名吩咐你们，凡有弟兄不按规矩而行，不遵守从我们所受的教训，就当远离他。"},
{volumn:        53,chapter:         3,versenumber:         7,verse:"你们自己原知道应当怎样效法我们。因为我们在你们中间，未尝不按规矩而行，"},
{volumn:        53,chapter:         3,versenumber:         8,verse:"也未尝白吃人的饭，倒是辛苦劳碌，昼夜做工，免得叫你们一人受累。"},
{volumn:        53,chapter:         3,versenumber:         9,verse:"这并不是因我们没有权柄，乃是要给你们作榜样，叫你们效法我们。"},
{volumn:        53,chapter:         3,versenumber:        10,verse:"我们在你们那里的时候，曾吩咐你们说，若有人不肯做工，就不可吃饭。"},
{volumn:        53,chapter:         3,versenumber:        11,verse:"因我们听说，在你们中间有人不按规矩而行，什么工都不做，反倒专管闲事。"},
{volumn:        53,chapter:         3,versenumber:        12,verse:"我们靠主耶稣基督吩咐、劝戒这样的人，要安静做工，吃自己的饭。"},
{volumn:        53,chapter:         3,versenumber:        13,verse:"弟兄们，你们行善不可丧志。"},
{volumn:        53,chapter:         3,versenumber:        14,verse:"若有人不听从我们这信上的话，要记下他，不和他交往，叫他自觉羞愧。"},
{volumn:        53,chapter:         3,versenumber:        15,verse:"但不要以他为仇人，要劝他如弟兄。"},
{volumn:        53,chapter:         3,versenumber:        16,verse:"愿赐平安的主随时随事亲自给你们平安！愿主常与你们众人同在！"},
{volumn:        53,chapter:         3,versenumber:        17,verse:"我保罗亲笔问你们安。凡我的信都以此为记，我的笔迹就是这样。"},
{volumn:        53,chapter:         3,versenumber:        18,verse:"愿我们主耶稣基督的恩常与你们众人同在！"},
{volumn:        54,chapter:         1,versenumber:         1,verse:"奉我们救主　神和我们的盼望基督耶稣之命，作基督耶稣使徒的保罗"},
{volumn:        54,chapter:         1,versenumber:         2,verse:"写信给那因信主作我真儿子的提摩太。愿恩惠、怜悯、平安从父　神和我们主基督耶稣归与你！"},
{volumn:        54,chapter:         1,versenumber:         3,verse:"我往马其顿去的时候，曾劝你仍住在以弗所，好嘱咐那几个人不可传异教，"},
{volumn:        54,chapter:         1,versenumber:         4,verse:"也不可听从荒渺无凭的话语和无穷的家谱；这等事只生辩论，并不发明　神在信上所立的章程。"},
{volumn:        54,chapter:         1,versenumber:         5,verse:"但命令的总归就是爱；这爱是从清洁的心和无亏的良心，无伪的信心生出来的。"},
{volumn:        54,chapter:         1,versenumber:         6,verse:"有人偏离这些，反去讲虚浮的话，"},
{volumn:        54,chapter:         1,versenumber:         7,verse:"想要作教法师，却不明白自己所讲说的所论定的。"},
{volumn:        54,chapter:         1,versenumber:         8,verse:"我们知道律法原是好的，只要人用得合宜；"},
{volumn:        54,chapter:         1,versenumber:         9,verse:"因为律法不是为义人设立的，乃是为不法和不服的，不虔诚和犯罪的，不圣洁和恋世俗的，弑父母和杀人的，"},
{volumn:        54,chapter:         1,versenumber:        10,verse:"行淫和亲男色的，抢人口和说谎话的，并起假誓的，或是为别样敌正道的事设立的。"},
{volumn:        54,chapter:         1,versenumber:        11,verse:"这是照着可称颂之　神交托我荣耀福音说的。"},
{volumn:        54,chapter:         1,versenumber:        12,verse:"我感谢那给我力量的我们主基督耶稣，因他以我有忠心，派我服侍他。"},
{volumn:        54,chapter:         1,versenumber:        13,verse:"我从前是亵渎　神的，逼迫人的，侮慢人的；然而我还蒙了怜悯，因我是不信不明白的时候而做的。"},
{volumn:        54,chapter:         1,versenumber:        14,verse:"并且我主的恩是格外丰盛，使我在基督耶稣里有信心和爱心。"},
{volumn:        54,chapter:         1,versenumber:        15,verse:"“基督耶稣降世，为要拯救罪人。”这话是可信的，是十分可佩服的。在罪人中我是个罪魁。"},
{volumn:        54,chapter:         1,versenumber:        16,verse:"然而，我蒙了怜悯，是因耶稣基督要在我这罪魁身上显明他一切的忍耐，给后来信他得永生的人作榜样。"},
{volumn:        54,chapter:         1,versenumber:        17,verse:"但愿尊贵、荣耀归与那不能朽坏、不能看见、永世的君王、独一的　神，直到永永远远。阿们！"},
{volumn:        54,chapter:         1,versenumber:        18,verse:"我儿提摩太啊，我照从前指着你的预言，将这命令交托你，叫你因此可以打那美好的仗。"},
{volumn:        54,chapter:         1,versenumber:        19,verse:"常存信心和无亏的良心。有人丢弃良心，就在真道上如同船破坏了一般。"},
{volumn:        54,chapter:         1,versenumber:        20,verse:"其中有许米乃和亚历山大；我已经把他们交给撒但，使他们受责罚就不再谤渎了。"},
{volumn:        54,chapter:         2,versenumber:         1,verse:"我劝你第一要为万人恳求、祷告、代求、祝谢，"},
{volumn:        54,chapter:         2,versenumber:         2,verse:"为君王和一切在位的，也该如此，使我们可以敬虔、端正、平安无事地度日。"},
{volumn:        54,chapter:         2,versenumber:         3,verse:"这是好的，在　神我们救主面前可蒙悦纳。"},
{volumn:        54,chapter:         2,versenumber:         4,verse:"他愿意万人得救，明白真道。"},
{volumn:        54,chapter:         2,versenumber:         5,verse:"因为只有一位　神，在　神和人中间，只有一位中保，乃是降世为人的基督耶稣；"},
{volumn:        54,chapter:         2,versenumber:         6,verse:"他舍自己作万人的赎价，到了时候，这事必证明出来。"},
{volumn:        54,chapter:         2,versenumber:         7,verse:"我为此奉派作传道的，作使徒，作外邦人的师傅，教导他们相信，学习真道。我说的是真话，并不是谎言。"},
{volumn:        54,chapter:         2,versenumber:         8,verse:"我愿男人无忿怒，无争论（或译：疑惑），举起圣洁的手，随处祷告；"},
{volumn:        54,chapter:         2,versenumber:         9,verse:"又愿女人廉耻、自守，以正派衣裳为妆饰，不以编发、黄金、珍珠，和贵价的衣裳为妆饰，"},
{volumn:        54,chapter:         2,versenumber:        10,verse:"只要有善行，这才与自称是敬　神的女人相宜。"},
{volumn:        54,chapter:         2,versenumber:        11,verse:"女人要沉静学道，一味地顺服。"},
{volumn:        54,chapter:         2,versenumber:        12,verse:"我不许女人讲道，也不许她辖管男人，只要沉静。"},
{volumn:        54,chapter:         2,versenumber:        13,verse:"因为先造的是亚当，后造的是夏娃，"},
{volumn:        54,chapter:         2,versenumber:        14,verse:"且不是亚当被引诱，乃是女人被引诱，陷在罪里。"},
{volumn:        54,chapter:         2,versenumber:        15,verse:"然而，女人若常存信心、爱心，又圣洁自守，就必在生产上得救。"},
{volumn:        54,chapter:         3,versenumber:         1,verse:"“人若想要得监督的职分，就是羡慕善工。”这话是可信的。"},
{volumn:        54,chapter:         3,versenumber:         2,verse:"作监督的，必须无可指责，只作一个妇人的丈夫，有节制，自守，端正，乐意接待远人，善于教导；"},
{volumn:        54,chapter:         3,versenumber:         3,verse:"不因酒滋事，不打人，只要温和，不争竞，不贪财；"},
{volumn:        54,chapter:         3,versenumber:         4,verse:"好好管理自己的家，使儿女凡事端庄顺服（或译：端端庄庄地使儿女顺服）。"},
{volumn:        54,chapter:         3,versenumber:         5,verse:"人若不知道管理自己的家，焉能照管　神的教会呢？"},
{volumn:        54,chapter:         3,versenumber:         6,verse:"初入教的不可作监督，恐怕他自高自大，就落在魔鬼所受的刑罚里。"},
{volumn:        54,chapter:         3,versenumber:         7,verse:"监督也必须在教外有好名声，恐怕被人毁谤，落在魔鬼的网罗里。"},
{volumn:        54,chapter:         3,versenumber:         8,verse:"作执事的，也是如此：必须端庄，不一口两舌，不好喝酒，不贪不义之财；"},
{volumn:        54,chapter:         3,versenumber:         9,verse:"要存清洁的良心，固守真道的奥秘。"},
{volumn:        54,chapter:         3,versenumber:        10,verse:"这等人也要先受试验，若没有可责之处，然后叫他们作执事。"},
{volumn:        54,chapter:         3,versenumber:        11,verse:"女执事（原文是女人）也是如此：必须端庄，不说谗言，有节制，凡事忠心。"},
{volumn:        54,chapter:         3,versenumber:        12,verse:"执事只要作一个妇人的丈夫，好好管理儿女和自己的家。"},
{volumn:        54,chapter:         3,versenumber:        13,verse:"因为善作执事的，自己就得到美好的地步，并且在基督耶稣里的真道上大有胆量。"},
{volumn:        54,chapter:         3,versenumber:        14,verse:"我指望快到你那里去，所以先将这些事写给你。"},
{volumn:        54,chapter:         3,versenumber:        15,verse:"倘若我耽延日久，你也可以知道在　神的家中当怎样行。这家就是永生　神的教会，真理的柱石和根基。"},
{volumn:        54,chapter:         3,versenumber:        16,verse:"大哉，敬虔的奥秘，无人不以为然！就是　神在肉身显现，被圣灵称义（或译：在灵性称义），被天使看见，被传于外邦，被世人信服，被接在荣耀里。"},
{volumn:        54,chapter:         4,versenumber:         1,verse:"圣灵明说，在后来的时候，必有人离弃真道，听从那引诱人的邪灵和鬼魔的道理。"},
{volumn:        54,chapter:         4,versenumber:         2,verse:"这是因为说谎之人的假冒；这等人的良心如同被热铁烙惯了一般。"},
{volumn:        54,chapter:         4,versenumber:         3,verse:"他们禁止嫁娶，又禁戒食物（或译：又叫人戒荤），就是　神所造、叫那信而明白真道的人感谢着领受的。"},
{volumn:        54,chapter:         4,versenumber:         4,verse:"凡　神所造的物都是好的，若感谢着领受，就没有一样可弃的，"},
{volumn:        54,chapter:         4,versenumber:         5,verse:"都因　神的道和人的祈求成为圣洁了。"},
{volumn:        54,chapter:         4,versenumber:         6,verse:"你若将这些事提醒弟兄们，便是基督耶稣的好执事，在真道的话语和你向来所服从的善道上得了教育。"},
{volumn:        54,chapter:         4,versenumber:         7,verse:"只是要弃绝那世俗的言语和老妇荒渺的话，在敬虔上操练自己。"},
{volumn:        54,chapter:         4,versenumber:         8,verse:"“操练身体，益处还少；惟独敬虔，凡事都有益处，因有今生和来生的应许。”"},
{volumn:        54,chapter:         4,versenumber:         9,verse:"这话是可信的，是十分可佩服的。"},
{volumn:        54,chapter:         4,versenumber:        10,verse:"我们劳苦努力，正是为此，因我们的指望在乎永生的　神；他是万人的救主，更是信徒的救主。"},
{volumn:        54,chapter:         4,versenumber:        11,verse:"这些事，你要吩咐人，也要教导人。"},
{volumn:        54,chapter:         4,versenumber:        12,verse:"不可叫人小看你年轻，总要在言语、行为、爱心、信心、清洁上，都作信徒的榜样。"},
{volumn:        54,chapter:         4,versenumber:        13,verse:"你要以宣读、劝勉、教导为念，直等到我来。"},
{volumn:        54,chapter:         4,versenumber:        14,verse:"你不要轻忽所得的恩赐，就是从前藉着预言、在众长老按手的时候赐给你的。"},
{volumn:        54,chapter:         4,versenumber:        15,verse:"这些事你要殷勤去做，并要在此专心，使众人看出你的长进来。"},
{volumn:        54,chapter:         4,versenumber:        16,verse:"你要谨慎自己和自己的教训，要在这些事上恒心；因为这样行，又能救自己，又能救听你的人。"},
{volumn:        54,chapter:         5,versenumber:         1,verse:"不可严责老年人，只要劝他如同父亲；劝少年人如同弟兄；"},
{volumn:        54,chapter:         5,versenumber:         2,verse:"劝老年妇女如同母亲；劝少年妇女如同姐妹；总要清清洁洁的。"},
{volumn:        54,chapter:         5,versenumber:         3,verse:"要尊敬那真为寡妇的。"},
{volumn:        54,chapter:         5,versenumber:         4,verse:"若寡妇有儿女，或有孙子孙女，便叫他们先在自己家中学着行孝，报答亲恩，因为这在　神面前是可悦纳的。"},
{volumn:        54,chapter:         5,versenumber:         5,verse:"那独居无靠、真为寡妇的，是仰赖　神，昼夜不住地祈求祷告。"},
{volumn:        54,chapter:         5,versenumber:         6,verse:"但那好宴乐的寡妇正活着的时候也是死的。"},
{volumn:        54,chapter:         5,versenumber:         7,verse:"这些事你要嘱咐她们，叫她们无可指责。"},
{volumn:        54,chapter:         5,versenumber:         8,verse:"人若不看顾亲属，就是背了真道，比不信的人还不好，不看顾自己家里的人，更是如此。"},
{volumn:        54,chapter:         5,versenumber:         9,verse:"寡妇记在册子上，必须年纪到六十岁，从来只作一个丈夫的妻子，"},
{volumn:        54,chapter:         5,versenumber:        10,verse:"又有行善的名声，就如养育儿女，接待远人，洗圣徒的脚，救济遭难的人，竭力行各样善事。"},
{volumn:        54,chapter:         5,versenumber:        11,verse:"至于年轻的寡妇，就可以辞她；因为她们的情欲发动，违背基督的时候就想要嫁人。"},
{volumn:        54,chapter:         5,versenumber:        12,verse:"她们被定罪，是因废弃了当初所许的愿；"},
{volumn:        54,chapter:         5,versenumber:        13,verse:"并且她们又习惯懒惰，挨家闲游；不但是懒惰，又说长道短，好管闲事，说些不当说的话。"},
{volumn:        54,chapter:         5,versenumber:        14,verse:"所以我愿意年轻的寡妇嫁人，生养儿女，治理家务，不给敌人辱骂的把柄。"},
{volumn:        54,chapter:         5,versenumber:        15,verse:"因为已经有转去随从撒但的。"},
{volumn:        54,chapter:         5,versenumber:        16,verse:"信主的妇女，若家中有寡妇，自己就当救济她们，不可累着教会，好使教会能救济那真无倚靠的寡妇。"},
{volumn:        54,chapter:         5,versenumber:        17,verse:"那善于管理教会的长老，当以为配受加倍的敬奉；那劳苦传道教导人的，更当如此。"},
{volumn:        54,chapter:         5,versenumber:        18,verse:"因为经上说：“牛在场上踹谷的时候，不可笼住它的嘴”；又说：“工人得工价是应当的。”"},
{volumn:        54,chapter:         5,versenumber:        19,verse:"控告长老的呈子，非有两三个见证就不要收。"},
{volumn:        54,chapter:         5,versenumber:        20,verse:"犯罪的人，当在众人面前责备他，叫其余的人也可以惧怕。"},
{volumn:        54,chapter:         5,versenumber:        21,verse:"我在　神和基督耶稣并蒙拣选的天使面前嘱咐你：要遵守这些话，不可存成见，行事也不可有偏心。"},
{volumn:        54,chapter:         5,versenumber:        22,verse:"给人行按手的礼，不可急促；不要在别人的罪上有份，要保守自己清洁。"},
{volumn:        54,chapter:         5,versenumber:        23,verse:"因你胃口不清，屡次患病，再不要照常喝水，可以稍微用点酒。"},
{volumn:        54,chapter:         5,versenumber:        24,verse:"有些人的罪是明显的，如同先到审判案前；有些人的罪是随后跟了去的。"},
{volumn:        54,chapter:         5,versenumber:        25,verse:"这样，善行也有明显的，那不明显的也不能隐藏。"},
{volumn:        54,chapter:         6,versenumber:         1,verse:"凡在轭下作仆人的，当以自己主人配受十分的恭敬，免得　神的名和道理被人亵渎。"},
{volumn:        54,chapter:         6,versenumber:         2,verse:"仆人有信道的主人，不可因为与他是弟兄就轻看他；更要加意服侍他；因为得服侍之益处的，是信道蒙爱的。你要以此教训人，劝勉人。"},
{volumn:        54,chapter:         6,versenumber:         3,verse:"若有人传异教，不服从我们主耶稣基督纯正的话与那合乎敬虔的道理，"},
{volumn:        54,chapter:         6,versenumber:         4,verse:"他是自高自大，一无所知，专好问难，争辩言词，从此就生出嫉妒、纷争、毁谤、妄疑，"},
{volumn:        54,chapter:         6,versenumber:         5,verse:"并那坏了心术、失丧真理之人的争竞。他们以敬虔为得利的门路。"},
{volumn:        54,chapter:         6,versenumber:         6,verse:"然而，敬虔加上知足的心便是大利了；"},
{volumn:        54,chapter:         6,versenumber:         7,verse:"因为我们没有带什么到世上来，也不能带什么去。"},
{volumn:        54,chapter:         6,versenumber:         8,verse:"只要有衣有食，就当知足。"},
{volumn:        54,chapter:         6,versenumber:         9,verse:"但那些想要发财的人，就陷在迷惑、落在网罗和许多无知有害的私欲里，叫人沉在败坏和灭亡中。"},
{volumn:        54,chapter:         6,versenumber:        10,verse:"贪财是万恶之根。有人贪恋钱财，就被引诱离了真道，用许多愁苦把自己刺透了。"},
{volumn:        54,chapter:         6,versenumber:        11,verse:"但你这属　神的人要逃避这些事，追求公义、敬虔、信心、爱心、忍耐、温柔。"},
{volumn:        54,chapter:         6,versenumber:        12,verse:"你要为真道打那美好的仗，持定永生。你为此被召，也在许多见证人面前，已经作了那美好的见证。"},
{volumn:        54,chapter:         6,versenumber:        13,verse:"我在叫万物生活的　神面前，并在向本丢彼拉多作过那美好见证的基督耶稣面前嘱咐你："},
{volumn:        54,chapter:         6,versenumber:        14,verse:"要守这命令，毫不玷污，无可指责，直到我们的主耶稣基督显现。"},
{volumn:        54,chapter:         6,versenumber:        15,verse:"到了日期，那可称颂、独有权能的万王之王、万主之主，"},
{volumn:        54,chapter:         6,versenumber:        16,verse:"就是那独一不死、住在人不能靠近的光里，是人未曾看见、也是不能看见的，要将他显明出来。但愿尊贵和永远的权能都归给他。阿们！"},
{volumn:        54,chapter:         6,versenumber:        17,verse:"你要嘱咐那些今世富足的人，不要自高，也不要倚靠无定的钱财；只要倚靠那厚赐百物给我们享受的　神。"},
{volumn:        54,chapter:         6,versenumber:        18,verse:"又要嘱咐他们行善，在好事上富足，甘心施舍，乐意供给（或译：体贴）人，"},
{volumn:        54,chapter:         6,versenumber:        19,verse:"为自己积成美好的根基，预备将来，叫他们持定那真正的生命。"},
{volumn:        54,chapter:         6,versenumber:        20,verse:"提摩太啊，你要保守所托付你的，躲避世俗的虚谈和那敌真道、似是而非的学问。"},
{volumn:        54,chapter:         6,versenumber:        21,verse:"已经有人自称有这学问，就偏离了真道。愿恩惠常与你们同在！"},
{volumn:        55,chapter:         1,versenumber:         1,verse:"奉　神旨意，照着在基督耶稣里生命的应许，作基督耶稣使徒的保罗"},
{volumn:        55,chapter:         1,versenumber:         2,verse:"写信给我亲爱的儿子提摩太。愿恩惠、怜悯、平安从父　神和我们主基督耶稣归与你！"},
{volumn:        55,chapter:         1,versenumber:         3,verse:"我感谢　神，就是我接续祖先用清洁的良心所侍奉的　神。祈祷的时候，不住的想念你，"},
{volumn:        55,chapter:         1,versenumber:         4,verse:"记念你的眼泪，昼夜切切地想要见你，好叫我满心快乐。"},
{volumn:        55,chapter:         1,versenumber:         5,verse:"想到你心里无伪之信，这信是先在你外祖母罗以和你母亲友尼基心里的，我深信也在你的心里。"},
{volumn:        55,chapter:         1,versenumber:         6,verse:"为此我提醒你，使你将　神藉我按手所给你的恩赐再如火挑旺起来。"},
{volumn:        55,chapter:         1,versenumber:         7,verse:"因为　神赐给我们，不是胆怯的心，乃是刚强、仁爱、谨守的心。"},
{volumn:        55,chapter:         1,versenumber:         8,verse:"你不要以给我们的主作见证为耻，也不要以我这为主被囚的为耻；总要按　神的能力，与我为福音同受苦难。"},
{volumn:        55,chapter:         1,versenumber:         9,verse:"神救了我们，以圣召召我们，不是按我们的行为，乃是按他的旨意和恩典；这恩典是万古之先，在基督耶稣里赐给我们的，"},
{volumn:        55,chapter:         1,versenumber:        10,verse:"但如今藉着我们救主基督耶稣的显现才表明出来了。他已经把死废去，藉着福音，将不能坏的生命彰显出来。"},
{volumn:        55,chapter:         1,versenumber:        11,verse:"我为这福音奉派作传道的，作使徒，作师傅。"},
{volumn:        55,chapter:         1,versenumber:        12,verse:"为这缘故，我也受这些苦难。然而我不以为耻；因为知道我所信的是谁，也深信他能保全我所交付他的（或译：他所交托我的），直到那日。"},
{volumn:        55,chapter:         1,versenumber:        13,verse:"你从我听的那纯正话语的规模，要用在基督耶稣里的信心和爱心，常常守着。"},
{volumn:        55,chapter:         1,versenumber:        14,verse:"从前所交托你的善道，你要靠着那住在我们里面的圣灵牢牢地守着。"},
{volumn:        55,chapter:         1,versenumber:        15,verse:"凡在亚细亚的人都离弃我，这是你知道的，其中有腓吉路和黑摩其尼。"},
{volumn:        55,chapter:         1,versenumber:        16,verse:"愿主怜悯阿尼色弗一家的人；因他屡次使我畅快，不以我的锁链为耻，"},
{volumn:        55,chapter:         1,versenumber:        17,verse:"反倒在罗马的时候，殷勤地找我，并且找着了。"},
{volumn:        55,chapter:         1,versenumber:        18,verse:"愿主使他在那日得主的怜悯。他在以弗所怎样多多地服侍我，是你明明知道的。"},
{volumn:        55,chapter:         2,versenumber:         1,verse:"我儿啊，你要在基督耶稣的恩典上刚强起来。"},
{volumn:        55,chapter:         2,versenumber:         2,verse:"你在许多见证人面前听见我所教训的，也要交托那忠心能教导别人的人。"},
{volumn:        55,chapter:         2,versenumber:         3,verse:"你要和我同受苦难，好像基督耶稣的精兵。"},
{volumn:        55,chapter:         2,versenumber:         4,verse:"凡在军中当兵的，不将世务缠身，好叫那招他当兵的人喜悦。"},
{volumn:        55,chapter:         2,versenumber:         5,verse:"人若在场上比武，非按规矩，就不能得冠冕。"},
{volumn:        55,chapter:         2,versenumber:         6,verse:"劳力的农夫理当先得粮食。"},
{volumn:        55,chapter:         2,versenumber:         7,verse:"我所说的话，你要思想，因为凡事主必给你聪明。"},
{volumn:        55,chapter:         2,versenumber:         8,verse:"你要记念耶稣基督乃是大卫的后裔，他从死里复活，正合乎我所传的福音。"},
{volumn:        55,chapter:         2,versenumber:         9,verse:"我为这福音受苦难，甚至被捆绑，像犯人一样。然而　神的道却不被捆绑。"},
{volumn:        55,chapter:         2,versenumber:        10,verse:"所以，我为选民凡事忍耐，叫他们也可以得着那在基督耶稣里的救恩和永远的荣耀。"},
{volumn:        55,chapter:         2,versenumber:        11,verse:"有可信的话说：“我们若与基督同死，也必与他同活；"},
{volumn:        55,chapter:         2,versenumber:        12,verse:"我们若能忍耐，也必和他一同作王。我们若不认他，他也必不认我们；"},
{volumn:        55,chapter:         2,versenumber:        13,verse:"我们纵然失信，他仍是可信的，因为他不能背乎自己。”"},
{volumn:        55,chapter:         2,versenumber:        14,verse:"你要使众人回想这些事，在主面前嘱咐他们：不可为言语争辩；这是没有益处的，只能败坏听见的人。"},
{volumn:        55,chapter:         2,versenumber:        15,verse:"你当竭力在　神面前得蒙喜悦，作无愧的工人，按着正意分解真理的道。"},
{volumn:        55,chapter:         2,versenumber:        16,verse:"但要远避世俗的虚谈，因为这等人必进到更不敬虔的地步。"},
{volumn:        55,chapter:         2,versenumber:        17,verse:"他们的话如同毒疮，越烂越大；其中有许米乃和腓理徒，"},
{volumn:        55,chapter:         2,versenumber:        18,verse:"他们偏离了真道，说复活的事已过，就败坏好些人的信心。"},
{volumn:        55,chapter:         2,versenumber:        19,verse:"然而，　神坚固的根基立住了；上面有这印记说：“主认识谁是他的人”；又说：“凡称呼主名的人总要离开不义。”"},
{volumn:        55,chapter:         2,versenumber:        20,verse:"在大户人家，不但有金器银器，也有木器瓦器；有作为贵重的，有作为卑贱的。"},
{volumn:        55,chapter:         2,versenumber:        21,verse:"人若自洁，脱离卑贱的事，就必作贵重的器皿，成为圣洁，合乎主用，预备行各样的善事。"},
{volumn:        55,chapter:         2,versenumber:        22,verse:"你要逃避少年的私欲，同那清心祷告主的人追求公义、信德、仁爱、和平。"},
{volumn:        55,chapter:         2,versenumber:        23,verse:"惟有那愚拙无学问的辩论，总要弃绝，因为知道这等事是起争竞的。"},
{volumn:        55,chapter:         2,versenumber:        24,verse:"然而主的仆人不可争竞，只要温温和和地待众人，善于教导，存心忍耐，"},
{volumn:        55,chapter:         2,versenumber:        25,verse:"用温柔劝戒那抵挡的人；或者　神给他们悔改的心，可以明白真道，"},
{volumn:        55,chapter:         2,versenumber:        26,verse:"叫他们这已经被魔鬼任意掳去的，可以醒悟，脱离他的网罗。"},
{volumn:        55,chapter:         3,versenumber:         1,verse:"你该知道，末世必有危险的日子来到。"},
{volumn:        55,chapter:         3,versenumber:         2,verse:"因为那时，人要专顾自己，贪爱钱财，自夸，狂傲，谤讟，违背父母，忘恩负义，心不圣洁，"},
{volumn:        55,chapter:         3,versenumber:         3,verse:"无亲情，不解怨，好说谗言，不能自约，性情凶暴，不爱良善，"},
{volumn:        55,chapter:         3,versenumber:         4,verse:"卖主卖友，任意妄为，自高自大，爱宴乐，不爱　神，"},
{volumn:        55,chapter:         3,versenumber:         5,verse:"有敬虔的外貌，却背了敬虔的实意；这等人你要躲开。"},
{volumn:        55,chapter:         3,versenumber:         6,verse:"那偷进人家、牢笼无知妇女的，正是这等人。这些妇女担负罪恶，被各样的私欲引诱，"},
{volumn:        55,chapter:         3,versenumber:         7,verse:"常常学习，终久不能明白真道。"},
{volumn:        55,chapter:         3,versenumber:         8,verse:"从前雅尼和佯庇怎样敌挡摩西，这等人也怎样敌挡真道。他们的心地坏了，在真道上是可废弃的。"},
{volumn:        55,chapter:         3,versenumber:         9,verse:"然而他们不能再这样敌挡；因为他们的愚昧必在众人面前显露出来，像那二人一样。"},
{volumn:        55,chapter:         3,versenumber:        10,verse:"但你已经服从了我的教训、品行、志向、信心、宽容、爱心、忍耐，"},
{volumn:        55,chapter:         3,versenumber:        11,verse:"以及我在安提阿、以哥念、路司得所遭遇的逼迫、苦难。我所忍受是何等的逼迫；但从这一切苦难中，主都把我救出来了。"},
{volumn:        55,chapter:         3,versenumber:        12,verse:"不但如此，凡立志在基督耶稣里敬虔度日的也都要受逼迫。"},
{volumn:        55,chapter:         3,versenumber:        13,verse:"只是作恶的和迷惑人的，必越久越恶，他欺哄人，也被人欺哄。"},
{volumn:        55,chapter:         3,versenumber:        14,verse:"但你所学习的，所确信的，要存在心里；因为你知道是跟谁学的，"},
{volumn:        55,chapter:         3,versenumber:        15,verse:"并且知道你是从小明白圣经，这圣经能使你因信基督耶稣，有得救的智慧。"},
{volumn:        55,chapter:         3,versenumber:        16,verse:"圣经都是　神所默示的（或译：凡　神所默示的圣经），于教训、督责、使人归正、教导人学义都是有益的，"},
{volumn:        55,chapter:         3,versenumber:        17,verse:"叫属　神的人得以完全，预备行各样的善事。"},
{volumn:        55,chapter:         4,versenumber:         1,verse:"我在　神面前，并在将来审判活人死人的基督耶稣面前，凭着他的显现和他的国度嘱咐你："},
{volumn:        55,chapter:         4,versenumber:         2,verse:"务要传道，无论得时不得时总要专心，并用百般的忍耐、各样的教训责备人，警戒人，劝勉人。"},
{volumn:        55,chapter:         4,versenumber:         3,verse:"因为时候要到，人必厌烦纯正的道理，耳朵发痒，就随从自己的情欲，增添好些师傅，"},
{volumn:        55,chapter:         4,versenumber:         4,verse:"并且掩耳不听真道，偏向荒渺的言语。"},
{volumn:        55,chapter:         4,versenumber:         5,verse:"你却要凡事谨慎，忍受苦难，做传道的工夫，尽你的职分。"},
{volumn:        55,chapter:         4,versenumber:         6,verse:"我现在被浇奠，我离世的时候到了。"},
{volumn:        55,chapter:         4,versenumber:         7,verse:"那美好的仗我已经打过了，当跑的路我已经跑尽了，所信的道我已经守住了。"},
{volumn:        55,chapter:         4,versenumber:         8,verse:"从此以后，有公义的冠冕为我存留，就是按着公义审判的主到了那日要赐给我的；不但赐给我，也赐给凡爱慕他显现的人。"},
{volumn:        55,chapter:         4,versenumber:         9,verse:"你要赶紧地到我这里来。"},
{volumn:        55,chapter:         4,versenumber:        10,verse:"因为底马贪爱现今的世界，就离弃我往帖撒罗尼迦去了；革勒士往加拉太去；提多往挞马太去；"},
{volumn:        55,chapter:         4,versenumber:        11,verse:"独有路加在我这里。你来的时候，要把马可带来，因为他在传道（或译：服侍我）的事上于我有益处。"},
{volumn:        55,chapter:         4,versenumber:        12,verse:"我已经打发推基古往以弗所去。"},
{volumn:        55,chapter:         4,versenumber:        13,verse:"我在特罗亚留于加布的那件外衣，你来的时候可以带来，那些书也要带来，更要紧的是那些皮卷。"},
{volumn:        55,chapter:         4,versenumber:        14,verse:"铜匠亚历山大多多地害我；主必照他所行的报应他。"},
{volumn:        55,chapter:         4,versenumber:        15,verse:"你也要防备他，因为他极力敌挡了我们的话。"},
{volumn:        55,chapter:         4,versenumber:        16,verse:"我初次申诉，没有人前来帮助，竟都离弃我；但愿这罪不归与他们。"},
{volumn:        55,chapter:         4,versenumber:        17,verse:"惟有主站在我旁边，加给我力量，使福音被我尽都传明，叫外邦人都听见；我也从狮子口里被救出来。"},
{volumn:        55,chapter:         4,versenumber:        18,verse:"主必救我脱离诸般的凶恶，也必救我进他的天国。愿荣耀归给他，直到永永远远。阿们。"},
{volumn:        55,chapter:         4,versenumber:        19,verse:"问百基拉、亚居拉，和阿尼色弗一家的人安。"},
{volumn:        55,chapter:         4,versenumber:        20,verse:"以拉都在哥林多住下了。特罗非摩病了，我就留他在米利都。"},
{volumn:        55,chapter:         4,versenumber:        21,verse:"你要赶紧在冬天以前到我这里来。有友布罗、布田、利奴、革老底亚，和众弟兄都问你安。"},
{volumn:        55,chapter:         4,versenumber:        22,verse:"愿主与你的灵同在！愿恩惠常与你们同在！"},
{volumn:        56,chapter:         1,versenumber:         1,verse:"神的仆人，耶稣基督的使徒保罗，凭着　神选民的信心与敬虔真理的知识，"},
{volumn:        56,chapter:         1,versenumber:         2,verse:"盼望那无谎言的　神在万古之先所应许的永生，"},
{volumn:        56,chapter:         1,versenumber:         3,verse:"到了日期，藉着传扬的工夫把他的道显明了；这传扬的责任是按着　神我们救主的命令交托了我。"},
{volumn:        56,chapter:         1,versenumber:         4,verse:"现在写信给提多，就是照着我们共信之道作我真儿子的。愿恩惠、平安从父　神和我们的救主基督耶稣归与你！"},
{volumn:        56,chapter:         1,versenumber:         5,verse:"我从前留你在克里特，是要你将那没有办完的事都办整齐了，又照我所吩咐你的，在各城设立长老。"},
{volumn:        56,chapter:         1,versenumber:         6,verse:"若有无可指责的人，只作一个妇人的丈夫，儿女也是信主的，没有人告他们是放荡不服约束的，就可以设立。"},
{volumn:        56,chapter:         1,versenumber:         7,verse:"监督既是　神的管家，必须无可指责，不任性，不暴躁，不因酒滋事，不打人，不贪无义之财；"},
{volumn:        56,chapter:         1,versenumber:         8,verse:"乐意接待远人，好善，庄重，公平，圣洁自持；"},
{volumn:        56,chapter:         1,versenumber:         9,verse:"坚守所教真实的道理，就能将纯正的教训劝化人，又能把争辩的人驳倒了。"},
{volumn:        56,chapter:         1,versenumber:        10,verse:"因为有许多人不服约束，说虚空话欺哄人；那奉割礼的更是这样。"},
{volumn:        56,chapter:         1,versenumber:        11,verse:"这些人的口总要堵住。他们因贪不义之财，将不该教导的教导人，败坏人的全家。"},
{volumn:        56,chapter:         1,versenumber:        12,verse:"有克里特人中的一个本地先知说：“克里特人常说谎话，乃是恶兽，又馋又懒。”"},
{volumn:        56,chapter:         1,versenumber:        13,verse:"这个见证是真的。所以，你要严严地责备他们，使他们在真道上纯全无疵，"},
{volumn:        56,chapter:         1,versenumber:        14,verse:"不听犹太人荒谬的言语和离弃真道之人的诫命。"},
{volumn:        56,chapter:         1,versenumber:        15,verse:"在洁净的人，凡物都洁净；在污秽不信的人，什么都不洁净，连心地和天良也都污秽了。"},
{volumn:        56,chapter:         1,versenumber:        16,verse:"他们说是认识　神，行事却和他相背；本是可憎恶的，是悖逆的，在各样善事上是可废弃的。"},
{volumn:        56,chapter:         2,versenumber:         1,verse:"但你所讲的总要合乎那纯正的道理。"},
{volumn:        56,chapter:         2,versenumber:         2,verse:"劝老年人要有节制、端庄、自守，在信心、爱心、忍耐上都要纯全无疵。"},
{volumn:        56,chapter:         2,versenumber:         3,verse:"又劝老年妇人，举止行动要恭敬，不说谗言，不给酒作奴仆，用善道教训人，"},
{volumn:        56,chapter:         2,versenumber:         4,verse:"好指教少年妇人，爱丈夫，爱儿女，"},
{volumn:        56,chapter:         2,versenumber:         5,verse:"谨守，贞洁，料理家务，待人有恩，顺服自己的丈夫，免得　神的道理被毁谤。"},
{volumn:        56,chapter:         2,versenumber:         6,verse:"又劝少年人要谨守。"},
{volumn:        56,chapter:         2,versenumber:         7,verse:"你自己凡事要显出善行的榜样；在教训上要正直、端庄，"},
{volumn:        56,chapter:         2,versenumber:         8,verse:"言语纯全，无可指责，叫那反对的人，既无处可说我们的不是，便自觉羞愧。"},
{volumn:        56,chapter:         2,versenumber:         9,verse:"劝仆人要顺服自己的主人，凡事讨他的喜欢，不可顶撞他，"},
{volumn:        56,chapter:         2,versenumber:        10,verse:"不可私拿东西，要显为忠诚，以致凡事尊荣我们救主　神的道。"},
{volumn:        56,chapter:         2,versenumber:        11,verse:"因为　神救众人的恩典已经显明出来，"},
{volumn:        56,chapter:         2,versenumber:        12,verse:"教训我们除去不敬虔的心和世俗的情欲，在今世自守、公义、敬虔度日，"},
{volumn:        56,chapter:         2,versenumber:        13,verse:"等候所盼望的福，并等候至大的　神和（或无“和“字）我们救主耶稣基督的荣耀显现。"},
{volumn:        56,chapter:         2,versenumber:        14,verse:"他为我们舍了自己，要赎我们脱离一切罪恶，又洁净我们，特作自己的子民，热心为善。"},
{volumn:        56,chapter:         2,versenumber:        15,verse:"这些事你要讲明，劝戒人，用各等权柄责备人；不可叫人轻看你。"},
{volumn:        56,chapter:         3,versenumber:         1,verse:"你要提醒众人，叫他们顺服作官的、掌权的，遵他的命，预备行各样的善事。"},
{volumn:        56,chapter:         3,versenumber:         2,verse:"不要毁谤，不要争竞，总要和平，向众人大显温柔。"},
{volumn:        56,chapter:         3,versenumber:         3,verse:"我们从前也是无知、悖逆、受迷惑、服侍各样私欲，和宴乐，常存恶毒（或译：阴毒）嫉妒的心，是可恨的，又是彼此相恨。"},
{volumn:        56,chapter:         3,versenumber:         4,verse:"但到了　神我们救主的恩慈和他向人所施的慈爱显明的时候，"},
{volumn:        56,chapter:         3,versenumber:         5,verse:"他便救了我们；并不是因我们自己所行的义，乃是照他的怜悯，藉着重生的洗和圣灵的更新。"},
{volumn:        56,chapter:         3,versenumber:         6,verse:"圣灵就是　神藉着耶稣基督我们救主厚厚浇灌在我们身上的，"},
{volumn:        56,chapter:         3,versenumber:         7,verse:"好叫我们因他的恩得称为义，可以凭着永生的盼望成为后嗣（或译：可以凭着盼望承受永生）。"},
{volumn:        56,chapter:         3,versenumber:         8,verse:"这话是可信的。我也愿你把这些事切切实实地讲明，使那些已信　神的人留心做正经事业（或译：留心行善）。这都是美事，并且与人有益。"},
{volumn:        56,chapter:         3,versenumber:         9,verse:"要远避无知的辩论和家谱的空谈，以及纷争，并因律法而起的争竞，因为这都是虚妄无益的。"},
{volumn:        56,chapter:         3,versenumber:        10,verse:"分门结党的人，警戒过一两次，就要弃绝他。"},
{volumn:        56,chapter:         3,versenumber:        11,verse:"因为知道这等人已经背道，犯了罪，自己明知不是，还是去做。"},
{volumn:        56,chapter:         3,versenumber:        12,verse:"我打发亚提马或是推基古到你那里去的时候，你要赶紧往尼哥波立去见我，因为我已经定意在那里过冬。"},
{volumn:        56,chapter:         3,versenumber:        13,verse:"你要赶紧给律师西纳和亚波罗送行，叫他们没有缺乏。"},
{volumn:        56,chapter:         3,versenumber:        14,verse:"并且我们的人要学习正经事业（或译：要学习行善），预备所需用的，免得不结果子。"},
{volumn:        56,chapter:         3,versenumber:        15,verse:"同我在一处的人都问你安。请代问那些因有信心爱我们的人安。愿恩惠常与你们众人同在！"},
{volumn:        57,chapter:         1,versenumber:         1,verse:"为基督耶稣被囚的保罗，同兄弟提摩太写信给我们所亲爱的同工腓利门，"},
{volumn:        57,chapter:         1,versenumber:         2,verse:"和妹子亚腓亚并与我们同当兵的亚基布，以及在你家的教会。"},
{volumn:        57,chapter:         1,versenumber:         3,verse:"愿恩惠、平安从　神我们的父和主耶稣基督归与你们！"},
{volumn:        57,chapter:         1,versenumber:         4,verse:"我祷告的时候提到你，常为你感谢我的　神；"},
{volumn:        57,chapter:         1,versenumber:         5,verse:"因听说你的爱心并你向主耶稣和众圣徒的信心（或译：因听说你向主耶稣和众圣徒有爱心有信心）。"},
{volumn:        57,chapter:         1,versenumber:         6,verse:"愿你与人所同有的信心显出功效，使人知道你们各样善事都是为基督做的。"},
{volumn:        57,chapter:         1,versenumber:         7,verse:"兄弟啊，我为你的爱心，大有快乐，大得安慰，因众圣徒的心从你得了畅快。"},
{volumn:        57,chapter:         1,versenumber:         8,verse:"我虽然靠着基督能放胆吩咐你合宜的事，"},
{volumn:        57,chapter:         1,versenumber:         9,verse:"然而像我这有年纪的保罗，现在又是为基督耶稣被囚的，宁可凭着爱心求你，"},
{volumn:        57,chapter:         1,versenumber:        10,verse:"就是为我在捆锁中所生的儿子阿尼西母（就是有益处的意思）求你。"},
{volumn:        57,chapter:         1,versenumber:        11,verse:"他从前与你没有益处，但如今与你我都有益处。"},
{volumn:        57,chapter:         1,versenumber:        12,verse:"我现在打发他亲自回你那里去；他是我心上的人。"},
{volumn:        57,chapter:         1,versenumber:        13,verse:"我本来有意将他留下，在我为福音所受的捆锁中替你伺候我。"},
{volumn:        57,chapter:         1,versenumber:        14,verse:"但不知道你的意思，我就不愿意这样行，叫你的善行不是出于勉强，乃是出于甘心。"},
{volumn:        57,chapter:         1,versenumber:        15,verse:"他暂时离开你，或者是叫你永远得着他，"},
{volumn:        57,chapter:         1,versenumber:        16,verse:"不再是奴仆，乃是高过奴仆，是亲爱的兄弟。在我实在是如此，何况在你呢！这也不拘是按肉体说，是按主说。"},
{volumn:        57,chapter:         1,versenumber:        17,verse:"你若以我为同伴，就收纳他，如同收纳我一样。"},
{volumn:        57,chapter:         1,versenumber:        18,verse:"他若亏负你，或欠你什么，都归在我的帐上，"},
{volumn:        57,chapter:         1,versenumber:        19,verse:"我必偿还。这是我保罗亲笔写的。我并不用对你说，连你自己也是亏欠于我。"},
{volumn:        57,chapter:         1,versenumber:        20,verse:"兄弟啊，望你使我在主里因你得快乐（或译：益处），并望你使我的心在基督里得畅快。"},
{volumn:        57,chapter:         1,versenumber:        21,verse:"我写信给你，深信你必顺服，知道你所要行的必过于我所说的。"},
{volumn:        57,chapter:         1,versenumber:        22,verse:"此外你还要给我预备住处；因为我盼望藉着你们的祷告，必蒙恩到你们那里去。"},
{volumn:        57,chapter:         1,versenumber:        23,verse:"为基督耶稣与我同坐监的以巴弗问你安。"},
{volumn:        57,chapter:         1,versenumber:        24,verse:"与我同工的马可、亚里达古、底马、路加也都问你安。"},
{volumn:        57,chapter:         1,versenumber:        25,verse:"愿我们主耶稣基督的恩常在你的心里。阿们！"},
{volumn:        58,chapter:         1,versenumber:         1,verse:"神既在古时藉着众先知多次多方地晓谕列祖，"},
{volumn:        58,chapter:         1,versenumber:         2,verse:"就在这末世藉着他儿子晓谕我们；又早已立他为承受万有的，也曾藉着他创造诸世界。"},
{volumn:        58,chapter:         1,versenumber:         3,verse:"他是　神荣耀所发的光辉，是　神本体的真像，常用他权能的命令托住万有。他洗净了人的罪，就坐在高天至大者的右边。"},
{volumn:        58,chapter:         1,versenumber:         4,verse:"他所承受的名，既比天使的名更尊贵，就远超过天使。"},
{volumn:        58,chapter:         1,versenumber:         5,verse:"所有的天使，　神从来对哪一个说：“你是我的儿子，我今日生你”？又指着哪一个说：“我要作他的父，他要作我的子”？"},
{volumn:        58,chapter:         1,versenumber:         6,verse:"再者，　神使长子到世上来的时候（或译：神再使长子到世上来的时候），就说：“　神的使者都要拜他。”"},
{volumn:        58,chapter:         1,versenumber:         7,verse:"论到使者，又说：“　神以风为使者，以火焰为仆役。”"},
{volumn:        58,chapter:         1,versenumber:         8,verse:"论到子却说：“　神啊，你的宝座是永永远远的，你的国权是正直的。"},
{volumn:        58,chapter:         1,versenumber:         9,verse:"你喜爱公义，恨恶罪恶，所以　神，就是你的　神，用喜乐油膏你，胜过膏你的同伴。”"},
{volumn:        58,chapter:         1,versenumber:        10,verse:"又说：“主啊，你起初立了地的根基，天也是你手所造的。"},
{volumn:        58,chapter:         1,versenumber:        11,verse:"天地都要灭没，你却要长存；天地都要像衣服渐渐旧了，"},
{volumn:        58,chapter:         1,versenumber:        12,verse:"你要将天地卷起来，像一件外衣，天地就都改变了。惟有你永不改变，你的年数没有穷尽。”"},
{volumn:        58,chapter:         1,versenumber:        13,verse:"所有的天使，　神从来对哪一个说：“你坐在我的右边，等我使你仇敌作你的脚凳”？"},
{volumn:        58,chapter:         1,versenumber:        14,verse:"天使岂不都是服役的灵、奉差遣为那将要承受救恩的人效力吗？"},
{volumn:        58,chapter:         2,versenumber:         1,verse:"所以，我们当越发郑重所听见的道理，恐怕我们随流失去。"},
{volumn:        58,chapter:         2,versenumber:         2,verse:"那藉着天使所传的话既是确定的；凡干犯悖逆的都受了该受的报应。"},
{volumn:        58,chapter:         2,versenumber:         3,verse:"我们若忽略这么大的救恩，怎能逃罪呢？这救恩起先是主亲自讲的，后来是听见的人给我们证实了。"},
{volumn:        58,chapter:         2,versenumber:         4,verse:"神又按自己的旨意，用神迹、奇事和百般的异能，并圣灵的恩赐，同他们作见证。"},
{volumn:        58,chapter:         2,versenumber:         5,verse:"我们所说将来的世界，　神原没有交给天使管辖。"},
{volumn:        58,chapter:         2,versenumber:         6,verse:"但有人在经上某处证明说：“人算什么，你竟顾念他？世人算什么，你竟眷顾他？"},
{volumn:        58,chapter:         2,versenumber:         7,verse:"你叫他比天使微小一点（或译：你叫他暂时比天使小），赐他荣耀尊贵为冠冕，并将你手所造的都派他管理，"},
{volumn:        58,chapter:         2,versenumber:         8,verse:"叫万物都服在他的脚下。”既叫万物都服他，就没有剩下一样不服他的。只是如今我们还不见万物都服他。"},
{volumn:        58,chapter:         2,versenumber:         9,verse:"惟独见那成为比天使小一点的耶稣（或译：惟独见耶稣暂时比天使小）；因为受死的苦，就得了尊贵荣耀为冠冕，叫他因着　神的恩，为人人尝了死味。"},
{volumn:        58,chapter:         2,versenumber:        10,verse:"原来那为万物所属、为万物所本的，要领许多的儿子进荣耀里去，使救他们的元帅，因受苦难得以完全，本是合宜的。"},
{volumn:        58,chapter:         2,versenumber:        11,verse:"因那使人成圣的和那些得以成圣的，都是出于一。所以，他称他们为弟兄也不以为耻，"},
{volumn:        58,chapter:         2,versenumber:        12,verse:"说：“我要将你的名传与我的弟兄，在会中我要颂扬你。”"},
{volumn:        58,chapter:         2,versenumber:        13,verse:"又说：“我要倚赖他。”又说：“看哪，我与　神所给我的儿女。”"},
{volumn:        58,chapter:         2,versenumber:        14,verse:"儿女既同有血肉之体，他也照样亲自成了血肉之体，特要藉着死败坏那掌死权的，就是魔鬼，"},
{volumn:        58,chapter:         2,versenumber:        15,verse:"并要释放那些一生因怕死而为奴仆的人。"},
{volumn:        58,chapter:         2,versenumber:        16,verse:"他并不救拔天使，乃是救拔亚伯拉罕的后裔。"},
{volumn:        58,chapter:         2,versenumber:        17,verse:"所以，他凡事该与他的弟兄相同，为要在　神的事上成为慈悲忠信的大祭司，为百姓的罪献上挽回祭。"},
{volumn:        58,chapter:         2,versenumber:        18,verse:"他自己既然被试探而受苦，就能搭救被试探的人。"},
{volumn:        58,chapter:         3,versenumber:         1,verse:"同蒙天召的圣洁弟兄啊，你们应当思想我们所认为使者、为大祭司的耶稣。"},
{volumn:        58,chapter:         3,versenumber:         2,verse:"他为那设立他的尽忠，如同摩西在　神的全家尽忠一样。"},
{volumn:        58,chapter:         3,versenumber:         3,verse:"他比摩西算是更配多得荣耀，好像建造房屋的比房屋更尊荣；"},
{volumn:        58,chapter:         3,versenumber:         4,verse:"因为房屋都必有人建造，但建造万物的就是　神。"},
{volumn:        58,chapter:         3,versenumber:         5,verse:"摩西为仆人，在　神的全家诚然尽忠，为要证明将来必传说的事。"},
{volumn:        58,chapter:         3,versenumber:         6,verse:"但基督为儿子，治理　神的家；我们若将可夸的盼望和胆量坚持到底，便是他的家了。"},
{volumn:        58,chapter:         3,versenumber:         7,verse:"圣灵有话说：“你们今日若听他的话，"},
{volumn:        58,chapter:         3,versenumber:         8,verse:"就不可硬着心，像在旷野惹他发怒、试探他的时候一样。"},
{volumn:        58,chapter:         3,versenumber:         9,verse:"在那里，你们的祖宗试我探我，并且观看我的作为有四十年之久。"},
{volumn:        58,chapter:         3,versenumber:        10,verse:"所以，我厌烦那世代的人，说：‘他们心里常常迷糊，竟不晓得我的作为！’"},
{volumn:        58,chapter:         3,versenumber:        11,verse:"我就在怒中起誓说：‘他们断不可进入我的安息。’”"},
{volumn:        58,chapter:         3,versenumber:        12,verse:"弟兄们，你们要谨慎，免得你们中间或有人存着不信的恶心，把永生　神离弃了。"},
{volumn:        58,chapter:         3,versenumber:        13,verse:"总要趁着还有今日，天天彼此相劝，免得你们中间有人被罪迷惑，心里就刚硬了。"},
{volumn:        58,chapter:         3,versenumber:        14,verse:"我们若将起初确实的信心坚持到底，就在基督里有份了。"},
{volumn:        58,chapter:         3,versenumber:        15,verse:"经上说：“你们今日若听他的话，就不可硬着心，像惹他发怒的日子一样。”"},
{volumn:        58,chapter:         3,versenumber:        16,verse:"那时，听见他话惹他发怒的是谁呢？岂不是跟着摩西从埃及出来的众人吗？"},
{volumn:        58,chapter:         3,versenumber:        17,verse:"神四十年之久，又厌烦谁呢？岂不是那些犯罪、尸首倒在旷野的人吗？"},
{volumn:        58,chapter:         3,versenumber:        18,verse:"又向谁起誓，不容他们进入他的安息呢？岂不是向那些不信从的人吗？"},
{volumn:        58,chapter:         3,versenumber:        19,verse:"这样看来，他们不能进入安息是因为不信的缘故了。"},
{volumn:        58,chapter:         4,versenumber:         1,verse:"我们既蒙留下，有进入他安息的应许，就当畏惧，免得我们（原文是你们）中间或有人似乎是赶不上了。"},
{volumn:        58,chapter:         4,versenumber:         2,verse:"因为有福音传给我们，像传给他们一样；只是所听见的道与他们无益，因为他们没有信心与所听见的道调和。"},
{volumn:        58,chapter:         4,versenumber:         3,verse:"但我们已经相信的人得以进入那安息，正如　神所说：“我在怒中起誓说：‘他们断不可进入我的安息！’”其实造物之工，从创世以来已经成全了。"},
{volumn:        58,chapter:         4,versenumber:         4,verse:"论到第七日，有一处说：“到第七日，　神就歇了他一切的工。”"},
{volumn:        58,chapter:         4,versenumber:         5,verse:"又有一处说：“他们断不可进入我的安息！”"},
{volumn:        58,chapter:         4,versenumber:         6,verse:"既有必进安息的人，那先前听见福音的，因为不信从，不得进去。"},
{volumn:        58,chapter:         4,versenumber:         7,verse:"所以过了多年，就在大卫的书上，又限定一日，如以上所引的说：“你们今日若听他的话，就不可硬着心。”"},
{volumn:        58,chapter:         4,versenumber:         8,verse:"若是约书亚已叫他们享了安息，后来　神就不再提别的日子了。"},
{volumn:        58,chapter:         4,versenumber:         9,verse:"这样看来，必另有一安息日的安息为　神的子民存留。"},
{volumn:        58,chapter:         4,versenumber:        10,verse:"因为那进入安息的，乃是歇了自己的工，正如　神歇了他的工一样。"},
{volumn:        58,chapter:         4,versenumber:        11,verse:"所以，我们务必竭力进入那安息，免得有人学那不信从的样子跌倒了。"},
{volumn:        58,chapter:         4,versenumber:        12,verse:"神的道是活泼的，是有功效的，比一切两刃的剑更快，甚至魂与灵，骨节与骨髓，都能刺入、剖开，连心中的思念和主意都能辨明。"},
{volumn:        58,chapter:         4,versenumber:        13,verse:"并且被造的没有一样在他面前不显然的；原来万物在那与我们有关系的主眼前，都是赤露敞开的。"},
{volumn:        58,chapter:         4,versenumber:        14,verse:"我们既然有一位已经升入高天尊荣的大祭司，就是　神的儿子耶稣，便当持定所承认的道。"},
{volumn:        58,chapter:         4,versenumber:        15,verse:"因我们的大祭司并非不能体恤我们的软弱。他也曾凡事受过试探，与我们一样，只是他没有犯罪。"},
{volumn:        58,chapter:         4,versenumber:        16,verse:"所以，我们只管坦然无惧地来到施恩的宝座前，为要得怜恤，蒙恩惠，作随时的帮助。"},
{volumn:        58,chapter:         5,versenumber:         1,verse:"凡从人间挑选的大祭司，是奉派替人办理属　神的事，为要献上礼物和赎罪祭（或译：要为罪献上礼物和祭物）。"},
{volumn:        58,chapter:         5,versenumber:         2,verse:"他能体谅那愚蒙的和失迷的人，因为他自己也是被软弱所困。"},
{volumn:        58,chapter:         5,versenumber:         3,verse:"故此，他理当为百姓和自己献祭赎罪。"},
{volumn:        58,chapter:         5,versenumber:         4,verse:"这大祭司的尊荣，没有人自取。惟要蒙　神所召，像亚伦一样。"},
{volumn:        58,chapter:         5,versenumber:         5,verse:"如此，基督也不是自取荣耀作大祭司，乃是在乎向他说“你是我的儿子，我今日生你”的那一位；"},
{volumn:        58,chapter:         5,versenumber:         6,verse:"就如经上又有一处说：“你是照着麦基洗德的等次永远为祭司。”"},
{volumn:        58,chapter:         5,versenumber:         7,verse:"基督在肉体的时候，既大声哀哭，流泪祷告，恳求那能救他免死的主，就因他的虔诚蒙了应允。"},
{volumn:        58,chapter:         5,versenumber:         8,verse:"他虽然为儿子，还是因所受的苦难学了顺从。"},
{volumn:        58,chapter:         5,versenumber:         9,verse:"他既得以完全，就为凡顺从他的人成了永远得救的根源，"},
{volumn:        58,chapter:         5,versenumber:        10,verse:"并蒙　神照着麦基洗德的等次称他为大祭司。"},
{volumn:        58,chapter:         5,versenumber:        11,verse:"论到麦基洗德，我们有好些话，并且难以解明，因为你们听不进去。"},
{volumn:        58,chapter:         5,versenumber:        12,verse:"看你们学习的工夫，本该作师傅，谁知还得有人将　神圣言小学的开端另教导你们，并且成了那必须吃奶、不能吃干粮的人。"},
{volumn:        58,chapter:         5,versenumber:        13,verse:"凡只能吃奶的都不熟练仁义的道理，因为他是婴孩；"},
{volumn:        58,chapter:         5,versenumber:        14,verse:"惟独长大成人的才能吃干粮；他们的心窍习练得通达，就能分辨好歹了。"},
{volumn:        58,chapter:         6,versenumber:         1,verse:"所以，我们应当离开基督道理的开端，竭力进到完全的地步，不必再立根基，就如那懊悔死行、信靠　神、"},
{volumn:        58,chapter:         6,versenumber:         2,verse:"各样洗礼、按手之礼、死人复活，以及永远审判各等教训。"},
{volumn:        58,chapter:         6,versenumber:         3,verse:"神若许我们，我们必如此行。"},
{volumn:        58,chapter:         6,versenumber:         4,verse:"论到那些已经蒙了光照、尝过天恩的滋味、又于圣灵有份，"},
{volumn:        58,chapter:         6,versenumber:         5,verse:"并尝过　神善道的滋味、觉悟来世权能的人，"},
{volumn:        58,chapter:         6,versenumber:         6,verse:"若是离弃道理，就不能叫他们从新懊悔了。因为他们把　神的儿子重钉十字架，明明地羞辱他。"},
{volumn:        58,chapter:         6,versenumber:         7,verse:"就如一块田地，吃过屡次下的雨水，生长菜蔬，合乎耕种的人用，就从　神得福；"},
{volumn:        58,chapter:         6,versenumber:         8,verse:"若长荆棘和蒺藜，必被废弃，近于咒诅，结局就是焚烧。"},
{volumn:        58,chapter:         6,versenumber:         9,verse:"亲爱的弟兄们，我们虽是这样说，却深信你们的行为强过这些，而且近乎得救。"},
{volumn:        58,chapter:         6,versenumber:        10,verse:"因为　神并非不公义，竟忘记你们所做的工和你们为他名所显的爱心，就是先前伺候圣徒，如今还是伺候。"},
{volumn:        58,chapter:         6,versenumber:        11,verse:"我们愿你们各人都显出这样的殷勤，使你们有满足的指望，一直到底。"},
{volumn:        58,chapter:         6,versenumber:        12,verse:"并且不懈怠，总要效法那些凭信心和忍耐承受应许的人。"},
{volumn:        58,chapter:         6,versenumber:        13,verse:"当初　神应许亚伯拉罕的时候，因为没有比自己更大可以指着起誓的，就指着自己起誓，说："},
{volumn:        58,chapter:         6,versenumber:        14,verse:"“论福，我必赐大福给你；论子孙，我必叫你的子孙多起来。”"},
{volumn:        58,chapter:         6,versenumber:        15,verse:"这样，亚伯拉罕既恒久忍耐，就得了所应许的。"},
{volumn:        58,chapter:         6,versenumber:        16,verse:"人都是指着比自己大的起誓，并且以起誓为实据，了结各样的争论。"},
{volumn:        58,chapter:         6,versenumber:        17,verse:"照样，　神愿意为那承受应许的人格外显明他的旨意是不更改的，就起誓为证。"},
{volumn:        58,chapter:         6,versenumber:        18,verse:"藉这两件不更改的事，　神决不能说谎，好叫我们这逃往避难所、持定摆在我们前头指望的人可以大得勉励。"},
{volumn:        58,chapter:         6,versenumber:        19,verse:"我们有这指望，如同灵魂的锚，又坚固又牢靠，且通入幔内。"},
{volumn:        58,chapter:         6,versenumber:        20,verse:"作先锋的耶稣，既照着麦基洗德的等次成了永远的大祭司，就为我们进入幔内。"},
{volumn:        58,chapter:         7,versenumber:         1,verse:"这麦基洗德就是撒冷王，又是至高　神的祭司，本是长远为祭司的。他当亚伯拉罕杀败诸王回来的时候，就迎接他，给他祝福。"},
{volumn:        58,chapter:         7,versenumber:         2,verse:"亚伯拉罕也将自己所得来的，取十分之一给他。他头一个名翻出来就是仁义王，他又名撒冷王，就是平安王的意思。"},
{volumn:        58,chapter:         7,versenumber:         3,verse:"他无父，无母，无族谱，无生之始，无命之终，乃是与　神的儿子相似。"},
{volumn:        58,chapter:         7,versenumber:         4,verse:"你们想一想，先祖亚伯拉罕将自己所掳来上等之物取十分之一给他，这人是何等尊贵呢！"},
{volumn:        58,chapter:         7,versenumber:         5,verse:"那得祭司职任的利未子孙，领命照例向百姓取十分之一，这百姓是自己的弟兄，虽是从亚伯拉罕身（原文是腰）中生的，还是照例取十分之一。"},
{volumn:        58,chapter:         7,versenumber:         6,verse:"独有麦基洗德，不与他们同谱，倒收纳亚伯拉罕的十分之一，为那蒙应许的亚伯拉罕祝福。"},
{volumn:        58,chapter:         7,versenumber:         7,verse:"从来位分大的给位分小的祝福，这是驳不倒的理。"},
{volumn:        58,chapter:         7,versenumber:         8,verse:"在这里收十分之一的都是必死的人；但在那里收十分之一的，有为他作见证的说，他是活的；"},
{volumn:        58,chapter:         7,versenumber:         9,verse:"并且可说那受十分之一的利未，也是藉着亚伯拉罕纳了十分之一。"},
{volumn:        58,chapter:         7,versenumber:        10,verse:"因为麦基洗德迎接亚伯拉罕的时候，利未已经在他先祖的身（原文是腰）中。"},
{volumn:        58,chapter:         7,versenumber:        11,verse:"从前百姓在利未人祭司职任以下受律法，倘若藉这职任能得完全，又何用另外兴起一位祭司，照麦基洗德的等次，不照亚伦的等次呢？"},
{volumn:        58,chapter:         7,versenumber:        12,verse:"祭司的职任既已更改，律法也必须更改。"},
{volumn:        58,chapter:         7,versenumber:        13,verse:"因为这话所指的人本属别的支派，那支派里从来没有一人伺候祭坛。"},
{volumn:        58,chapter:         7,versenumber:        14,verse:"我们的主分明是从犹大出来的；但这支派，摩西并没有提到祭司。"},
{volumn:        58,chapter:         7,versenumber:        15,verse:"倘若照麦基洗德的样式，另外兴起一位祭司来，我的话更是显而易见的了。"},
{volumn:        58,chapter:         7,versenumber:        16,verse:"他成为祭司，并不是照属肉体的条例，乃是照无穷（原文是不能毁坏）之生命的大能。"},
{volumn:        58,chapter:         7,versenumber:        17,verse:"因为有给他作见证的说：“你是照着麦基洗德的等次永远为祭司。”"},
{volumn:        58,chapter:         7,versenumber:        18,verse:"先前的条例，因软弱无益，所以废掉了，"},
{volumn:        58,chapter:         7,versenumber:        19,verse:"（律法原来一无所成）就引进了更美的指望；靠这指望，我们便可以进到　神面前。"},
{volumn:        58,chapter:         7,versenumber:        20,verse:"再者，耶稣为祭司，并不是不起誓立的。"},
{volumn:        58,chapter:         7,versenumber:        21,verse:"至于那些祭司，原不是起誓立的，只有耶稣是起誓立的；因为那立他的对他说：“主起了誓，决不后悔，你是永远为祭司。”"},
{volumn:        58,chapter:         7,versenumber:        22,verse:"既是起誓立的，耶稣就作了更美之约的中保。"},
{volumn:        58,chapter:         7,versenumber:        23,verse:"那些成为祭司的，数目本来多，是因为有死阻隔，不能长久。"},
{volumn:        58,chapter:         7,versenumber:        24,verse:"这位既是永远常存的，他祭司的职任就长久不更换。"},
{volumn:        58,chapter:         7,versenumber:        25,verse:"凡靠着他进到　神面前的人，他都能拯救到底；因为他是长远活着，替他们祈求。"},
{volumn:        58,chapter:         7,versenumber:        26,verse:"像这样圣洁、无邪恶、无玷污、远离罪人、高过诸天的大祭司，原是与我们合宜的。"},
{volumn:        58,chapter:         7,versenumber:        27,verse:"他不像那些大祭司，每日必须先为自己的罪，后为百姓的罪献祭；因为他只一次将自己献上，就把这事成全了。"},
{volumn:        58,chapter:         7,versenumber:        28,verse:"律法本是立软弱的人为大祭司；但在律法以后起誓的话，是立儿子为大祭司，乃是成全到永远的。"},
{volumn:        58,chapter:         8,versenumber:         1,verse:"我们所讲的事，其中第一要紧的，就是我们有这样的大祭司，已经坐在天上至大者宝座的右边，"},
{volumn:        58,chapter:         8,versenumber:         2,verse:"在圣所，就是真帐幕里，作执事；这帐幕是主所支的，不是人所支的。"},
{volumn:        58,chapter:         8,versenumber:         3,verse:"凡大祭司都是为献礼物和祭物设立的，所以这位大祭司也必须有所献的。"},
{volumn:        58,chapter:         8,versenumber:         4,verse:"他若在地上，必不得为祭司，因为已经有照律法献礼物的祭司。"},
{volumn:        58,chapter:         8,versenumber:         5,verse:"他们供奉的事本是天上事的形状和影像，正如摩西将要造帐幕的时候，蒙　神警戒他，说：“你要谨慎，作各样的物件都要照着在山上指示你的样式。”"},
{volumn:        58,chapter:         8,versenumber:         6,verse:"如今耶稣所得的职任是更美的，正如他作更美之约的中保；这约原是凭更美之应许立的。"},
{volumn:        58,chapter:         8,versenumber:         7,verse:"那前约若没有瑕疵，就无处寻求后约了。"},
{volumn:        58,chapter:         8,versenumber:         8,verse:"所以主指责他的百姓说（或译：所以主指前约的缺欠说）：“日子将到，我要与以色列家和犹大家另立新约，"},
{volumn:        58,chapter:         8,versenumber:         9,verse:"不像我拉着他们祖宗的手，领他们出埃及的时候，与他们所立的约。因为他们不恒心守我的约，我也不理他们。这是主说的。”"},
{volumn:        58,chapter:         8,versenumber:        10,verse:"主又说：“那些日子以后，我与以色列家所立的约乃是这样：我要将我的律法放在他们里面，写在他们心上；我要作他们的　神；他们要作我的子民。"},
{volumn:        58,chapter:         8,versenumber:        11,verse:"他们不用各人教导自己的乡邻和自己的弟兄，说：‘你该认识主’；因为他们从最小的到至大的，都必认识我。"},
{volumn:        58,chapter:         8,versenumber:        12,verse:"我要宽恕他们的不义，不再记念他们的罪愆。”"},
{volumn:        58,chapter:         8,versenumber:        13,verse:"既说新约，就以前约为旧了；但那渐旧渐衰的，就必快归无有了。"},
{volumn:        58,chapter:         9,versenumber:         1,verse:"原来前约有礼拜的条例和属世界的圣幕。"},
{volumn:        58,chapter:         9,versenumber:         2,verse:"因为有预备的帐幕，头一层叫作圣所，里面有灯台、桌子，和陈设饼。"},
{volumn:        58,chapter:         9,versenumber:         3,verse:"第二幔子后又有一层帐幕，叫作至圣所，"},
{volumn:        58,chapter:         9,versenumber:         4,verse:"有金香炉（炉：或译坛），有包金的约柜，柜里有盛吗哪的金罐和亚伦发过芽的杖，并两块约版；"},
{volumn:        58,chapter:         9,versenumber:         5,verse:"柜上面有荣耀基路伯的影罩着施恩（施恩：原文是蔽罪）座。这几件我现在不能一一细说。"},
{volumn:        58,chapter:         9,versenumber:         6,verse:"这些物件既如此预备齐了，众祭司就常进头一层帐幕，行拜　神的礼。"},
{volumn:        58,chapter:         9,versenumber:         7,verse:"至于第二层帐幕，惟有大祭司一年一次独自进去，没有不带着血为自己和百姓的过错献上。"},
{volumn:        58,chapter:         9,versenumber:         8,verse:"圣灵用此指明，头一层帐幕仍存的时候，进入至圣所的路还未显明。"},
{volumn:        58,chapter:         9,versenumber:         9,verse:"那头一层帐幕作现今的一个表样，所献的礼物和祭物，就着良心说，都不能叫礼拜的人得以完全。"},
{volumn:        58,chapter:         9,versenumber:        10,verse:"这些事，连那饮食和诸般洗濯的规矩，都不过是属肉体的条例，命定到振兴的时候为止。"},
{volumn:        58,chapter:         9,versenumber:        11,verse:"但现在基督已经来到，作了将来美事的大祭司，经过那更大更全备的帐幕，不是人手所造，也不是属乎这世界的；"},
{volumn:        58,chapter:         9,versenumber:        12,verse:"并且不用山羊和牛犊的血，乃用自己的血，只一次进入圣所，成了永远赎罪的事。"},
{volumn:        58,chapter:         9,versenumber:        13,verse:"若山羊和公牛的血，并母牛犊的灰，洒在不洁的人身上，尚且叫人成圣，身体洁净，"},
{volumn:        58,chapter:         9,versenumber:        14,verse:"何况基督藉着永远的灵，将自己无瑕无疵献给　神，他的血岂不更能洗净你们的心（原文是良心），除去你们的死行，使你们侍奉那永生　神吗？"},
{volumn:        58,chapter:         9,versenumber:        15,verse:"为此，他作了新约的中保，既然受死赎了人在前约之时所犯的罪过，便叫蒙召之人得着所应许永远的产业。"},
{volumn:        58,chapter:         9,versenumber:        16,verse:"凡有遗命必须等到留遗命（遗命：原文与约字同）的人死了；"},
{volumn:        58,chapter:         9,versenumber:        17,verse:"因为人死了，遗命才有效力，若留遗命的尚在，那遗命还有用处吗？"},
{volumn:        58,chapter:         9,versenumber:        18,verse:"所以，前约也不是不用血立的；"},
{volumn:        58,chapter:         9,versenumber:        19,verse:"因为摩西当日照着律法将各样诫命传给众百姓，就拿朱红色绒和牛膝草，把牛犊山羊的血和水洒在书上，又洒在众百姓身上，说："},
{volumn:        58,chapter:         9,versenumber:        20,verse:"“这血就是　神与你们立约的凭据。”"},
{volumn:        58,chapter:         9,versenumber:        21,verse:"他又照样把血洒在帐幕和各样器皿上。"},
{volumn:        58,chapter:         9,versenumber:        22,verse:"按着律法，凡物差不多都是用血洁净的；若不流血，罪就不得赦免了。"},
{volumn:        58,chapter:         9,versenumber:        23,verse:"照着天上样式做的物件必须用这些祭物去洁净；但那天上的本物自然当用更美的祭物去洁净。"},
{volumn:        58,chapter:         9,versenumber:        24,verse:"因为基督并不是进了人手所造的圣所（这不过是真圣所的影相），乃是进了天堂，如今为我们显在　神面前；"},
{volumn:        58,chapter:         9,versenumber:        25,verse:"也不是多次将自己献上，像那大祭司每年带着牛羊的血（牛羊的血：原文作不是自己的血）进入圣所，"},
{volumn:        58,chapter:         9,versenumber:        26,verse:"如果这样，他从创世以来，就必多次受苦了。但如今在这末世显现一次，把自己献为祭，好除掉罪。"},
{volumn:        58,chapter:         9,versenumber:        27,verse:"按着定命，人人都有一死，死后且有审判。"},
{volumn:        58,chapter:         9,versenumber:        28,verse:"像这样，基督既然一次被献，担当了多人的罪，将来要向那等候他的人第二次显现，并与罪无关，乃是为拯救他们。"},
{volumn:        58,chapter:        10,versenumber:         1,verse:"律法既是将来美事的影儿，不是本物的真像，总不能藉着每年常献一样的祭物叫那近前来的人得以完全。"},
{volumn:        58,chapter:        10,versenumber:         2,verse:"若不然，献祭的事岂不早已止住了吗？因为礼拜的人，良心既被洁净，就不再觉得有罪了。"},
{volumn:        58,chapter:        10,versenumber:         3,verse:"但这些祭物是叫人每年想起罪来；"},
{volumn:        58,chapter:        10,versenumber:         4,verse:"因为公牛和山羊的血，断不能除罪。"},
{volumn:        58,chapter:        10,versenumber:         5,verse:"所以基督到世上来的时候，就说：“　神啊，祭物和礼物是你不愿意的，你曾给我预备了身体。"},
{volumn:        58,chapter:        10,versenumber:         6,verse:"燔祭和赎罪祭是你不喜欢的。"},
{volumn:        58,chapter:        10,versenumber:         7,verse:"那时我说：‘　神啊，我来了，为要照你的旨意行；我的事在经卷上已经记载了。’”"},
{volumn:        58,chapter:        10,versenumber:         8,verse:"以上说：“祭物和礼物，燔祭和赎罪祭，是你不愿意的，也是你不喜欢的（这都是按着律法献的）”；"},
{volumn:        58,chapter:        10,versenumber:         9,verse:"后又说：“我来了为要照你的旨意行”；可见他是除去在先的，为要立定在后的。"},
{volumn:        58,chapter:        10,versenumber:        10,verse:"我们凭这旨意，靠耶稣基督，只一次献上他的身体，就得以成圣。"},
{volumn:        58,chapter:        10,versenumber:        11,verse:"凡祭司天天站着侍奉　神，屡次献上一样的祭物，这祭物永不能除罪。"},
{volumn:        58,chapter:        10,versenumber:        12,verse:"但基督献了一次永远的赎罪祭，就在　神的右边坐下了。"},
{volumn:        58,chapter:        10,versenumber:        13,verse:"从此，等候他仇敌成了他的脚凳。"},
{volumn:        58,chapter:        10,versenumber:        14,verse:"因为他一次献祭，便叫那得以成圣的人永远完全。"},
{volumn:        58,chapter:        10,versenumber:        15,verse:"圣灵也对我们作见证；因为他既已说过："},
{volumn:        58,chapter:        10,versenumber:        16,verse:"“主说：那些日子以后，我与他们所立的约乃是这样：我要将我的律法写在他们心上，又要放在他们的里面”；"},
{volumn:        58,chapter:        10,versenumber:        17,verse:"以后就说：“我不再记念他们的罪愆和他们的过犯。”"},
{volumn:        58,chapter:        10,versenumber:        18,verse:"这些罪过既已赦免，就不用再为罪献祭了。"},
{volumn:        58,chapter:        10,versenumber:        19,verse:"弟兄们，我们既因耶稣的血得以坦然进入至圣所，"},
{volumn:        58,chapter:        10,versenumber:        20,verse:"是藉着他给我们开了一条又新又活的路，从幔子经过，这幔子就是他的身体。"},
{volumn:        58,chapter:        10,versenumber:        21,verse:"又有一位大祭司治理　神的家，"},
{volumn:        58,chapter:        10,versenumber:        22,verse:"并我们心中天良的亏欠已经洒去，身体用清水洗净了，就当存着诚心和充足的信心来到　神面前；"},
{volumn:        58,chapter:        10,versenumber:        23,verse:"也要坚守我们所承认的指望，不至摇动，因为那应许我们的是信实的。"},
{volumn:        58,chapter:        10,versenumber:        24,verse:"又要彼此相顾，激发爱心，勉励行善。"},
{volumn:        58,chapter:        10,versenumber:        25,verse:"你们不可停止聚会，好像那些停止惯了的人，倒要彼此劝勉，既知道（原文是看见）那日子临近，就更当如此。"},
{volumn:        58,chapter:        10,versenumber:        26,verse:"因为我们得知真道以后，若故意犯罪，赎罪的祭就再没有了；"},
{volumn:        58,chapter:        10,versenumber:        27,verse:"惟有战惧等候审判和那烧灭众敌人的烈火。"},
{volumn:        58,chapter:        10,versenumber:        28,verse:"人干犯摩西的律法，凭两三个见证人，尚且不得怜恤而死，"},
{volumn:        58,chapter:        10,versenumber:        29,verse:"何况人践踏　神的儿子，将那使他成圣之约的血当作平常，又亵慢施恩的圣灵，你们想，他要受的刑罚该怎样加重呢！"},
{volumn:        58,chapter:        10,versenumber:        30,verse:"因为我们知道谁说：“伸冤在我，我必报应”；又说：“主要审判他的百姓。”"},
{volumn:        58,chapter:        10,versenumber:        31,verse:"落在永生　神的手里，真是可怕的！"},
{volumn:        58,chapter:        10,versenumber:        32,verse:"你们要追念往日，蒙了光照以后所忍受大争战的各样苦难："},
{volumn:        58,chapter:        10,versenumber:        33,verse:"一面被毁谤，遭患难，成了戏景，叫众人观看；一面陪伴那些受这样苦难的人。"},
{volumn:        58,chapter:        10,versenumber:        34,verse:"因为你们体恤了那些被捆锁的人，并且你们的家业被人抢去，也甘心忍受，知道自己有更美长存的家业。"},
{volumn:        58,chapter:        10,versenumber:        35,verse:"所以，你们不可丢弃勇敢的心；存这样的心必得大赏赐。"},
{volumn:        58,chapter:        10,versenumber:        36,verse:"你们必须忍耐，使你们行完了　神的旨意，就可以得着所应许的。"},
{volumn:        58,chapter:        10,versenumber:        37,verse:"“因为还有一点点时候，那要来的就来，并不迟延。"},
{volumn:        58,chapter:        10,versenumber:        38,verse:"只是义人（有古卷：我的义人）必因信得生。他若退后，我心里就不喜欢他。”"},
{volumn:        58,chapter:        10,versenumber:        39,verse:"我们却不是退后入沉沦的那等人，乃是有信心以致灵魂得救的人。"},
{volumn:        58,chapter:        11,versenumber:         1,verse:"信就是所望之事的实底，是未见之事的确据。"},
{volumn:        58,chapter:        11,versenumber:         2,verse:"古人在这信上得了美好的证据。"},
{volumn:        58,chapter:        11,versenumber:         3,verse:"我们因着信，就知道诸世界是藉　神的话造成的；这样，所看见的，并不是从显然之物造出来的。"},
{volumn:        58,chapter:        11,versenumber:         4,verse:"亚伯因着信，献祭与　神，比该隐所献的更美，因此便得了称义的见证，就是　神指他礼物作的见证。他虽然死了，却因这信，仍旧说话。"},
{volumn:        58,chapter:        11,versenumber:         5,verse:"以诺因着信，被接去，不至于见死，人也找不着他，因为　神已经把他接去了；只是他被接去以先，已经得了　神喜悦他的明证。"},
{volumn:        58,chapter:        11,versenumber:         6,verse:"人非有信，就不能得　神的喜悦；因为到　神面前来的人必须信有　神，且信他赏赐那寻求他的人。"},
{volumn:        58,chapter:        11,versenumber:         7,verse:"挪亚因着信，既蒙　神指示他未见的事，动了敬畏的心，预备了一只方舟，使他全家得救。因此就定了那世代的罪，自己也承受了那从信而来的义。"},
{volumn:        58,chapter:        11,versenumber:         8,verse:"亚伯拉罕因着信，蒙召的时候就遵命出去，往将来要得为业的地方去；出去的时候，还不知往哪里去。"},
{volumn:        58,chapter:        11,versenumber:         9,verse:"他因着信，就在所应许之地作客，好像在异地居住帐棚，与那同蒙一个应许的以撒、雅各一样。"},
{volumn:        58,chapter:        11,versenumber:        10,verse:"因为他等候那座有根基的城，就是　神所经营所建造的。"},
{volumn:        58,chapter:        11,versenumber:        11,verse:"因着信，连撒拉自己，虽然过了生育的岁数，还能怀孕，因她以为那应许她的是可信的。"},
{volumn:        58,chapter:        11,versenumber:        12,verse:"所以从一个仿佛已死的人就生出子孙，如同天上的星那样众多，海边的沙那样无数。"},
{volumn:        58,chapter:        11,versenumber:        13,verse:"这些人都是存着信心死的，并没有得着所应许的；却从远处望见，且欢喜迎接，又承认自己在世上是客旅，是寄居的。"},
{volumn:        58,chapter:        11,versenumber:        14,verse:"说这样话的人是表明自己要找一个家乡。"},
{volumn:        58,chapter:        11,versenumber:        15,verse:"他们若想念所离开的家乡，还有可以回去的机会。"},
{volumn:        58,chapter:        11,versenumber:        16,verse:"他们却羡慕一个更美的家乡，就是在天上的。所以　神被称为他们的　神，并不以为耻，因为他已经给他们预备了一座城。"},
{volumn:        58,chapter:        11,versenumber:        17,verse:"亚伯拉罕因着信，被试验的时候，就把以撒献上；这便是那欢喜领受应许的，将自己独生的儿子献上。"},
{volumn:        58,chapter:        11,versenumber:        18,verse:"论到这儿子，曾有话说：“从以撒生的才要称为你的后裔。”"},
{volumn:        58,chapter:        11,versenumber:        19,verse:"他以为　神还能叫人从死里复活；他也仿佛从死中得回他的儿子来。"},
{volumn:        58,chapter:        11,versenumber:        20,verse:"以撒因着信，就指着将来的事给雅各、以扫祝福。"},
{volumn:        58,chapter:        11,versenumber:        21,verse:"雅各因着信，临死的时候，给约瑟的两个儿子各自祝福，扶着杖头敬拜　神。"},
{volumn:        58,chapter:        11,versenumber:        22,verse:"约瑟因着信，临终的时候，提到以色列族将来要出埃及，并为自己的骸骨留下遗命。"},
{volumn:        58,chapter:        11,versenumber:        23,verse:"摩西生下来，他的父母见他是个俊美的孩子，就因着信，把他藏了三个月，并不怕王命。"},
{volumn:        58,chapter:        11,versenumber:        24,verse:"摩西因着信，长大了就不肯称为法老女儿之子。"},
{volumn:        58,chapter:        11,versenumber:        25,verse:"他宁可和　神的百姓同受苦害，也不愿暂时享受罪中之乐。"},
{volumn:        58,chapter:        11,versenumber:        26,verse:"他看为基督受的凌辱比埃及的财物更宝贵，因他想望所要得的赏赐。"},
{volumn:        58,chapter:        11,versenumber:        27,verse:"他因着信，就离开埃及，不怕王怒；因为他恒心忍耐，如同看见那不能看见的主。"},
{volumn:        58,chapter:        11,versenumber:        28,verse:"他因着信，就守（或译：立）逾越节，行洒血的礼，免得那灭长子的临近以色列人。"},
{volumn:        58,chapter:        11,versenumber:        29,verse:"他们因着信，过红海如行干地；埃及人试着要过去，就被吞灭了。"},
{volumn:        58,chapter:        11,versenumber:        30,verse:"以色列人因着信，围绕耶利哥城七日，城墙就倒塌了。"},
{volumn:        58,chapter:        11,versenumber:        31,verse:"妓女喇合因着信，曾和和平平地接待探子，就不与那些不顺从的人一同灭亡。"},
{volumn:        58,chapter:        11,versenumber:        32,verse:"我又何必再说呢？若要一一细说，基甸、巴拉、参孙、耶弗他、大卫、撒母耳，和众先知的事，时候就不够了。"},
{volumn:        58,chapter:        11,versenumber:        33,verse:"他们因着信，制伏了敌国，行了公义，得了应许，堵了狮子的口，"},
{volumn:        58,chapter:        11,versenumber:        34,verse:"灭了烈火的猛势，脱了刀剑的锋刃；软弱变为刚强，争战显出勇敢，打退外邦的全军。"},
{volumn:        58,chapter:        11,versenumber:        35,verse:"有妇人得自己的死人复活。又有人忍受严刑，不肯苟且得释放（原文是赎），为要得着更美的复活。"},
{volumn:        58,chapter:        11,versenumber:        36,verse:"又有人忍受戏弄、鞭打、捆锁、监禁、各等的磨炼，"},
{volumn:        58,chapter:        11,versenumber:        37,verse:"被石头打死，被锯锯死，受试探，被刀杀，披着绵羊山羊的皮各处奔跑，受穷乏、患难、苦害，"},
{volumn:        58,chapter:        11,versenumber:        38,verse:"在旷野、山岭、山洞、地穴，飘流无定，本是世界不配有的人。"},
{volumn:        58,chapter:        11,versenumber:        39,verse:"这些人都是因信得了美好的证据，却仍未得着所应许的；"},
{volumn:        58,chapter:        11,versenumber:        40,verse:"因为　神给我们预备了更美的事，叫他们若不与我们同得，就不能完全。"},
{volumn:        58,chapter:        12,versenumber:         1,verse:"我们既有这许多的见证人，如同云彩围着我们，就当放下各样的重担，脱去容易缠累我们的罪，存心忍耐，奔那摆在我们前头的路程，"},
{volumn:        58,chapter:        12,versenumber:         2,verse:"仰望为我们信心创始成终的耶稣（或译：仰望那将真道创始成终的耶稣）。他因那摆在前面的喜乐，就轻看羞辱，忍受了十字架的苦难，便坐在　神宝座的右边。"},
{volumn:        58,chapter:        12,versenumber:         3,verse:"那忍受罪人这样顶撞的，你们要思想，免得疲倦灰心。"},
{volumn:        58,chapter:        12,versenumber:         4,verse:"你们与罪恶相争，还没有抵挡到流血的地步。"},
{volumn:        58,chapter:        12,versenumber:         5,verse:"你们又忘了那劝你们如同劝儿子的话，说：“我儿，你不可轻看主的管教，被他责备的时候也不可灰心。"},
{volumn:        58,chapter:        12,versenumber:         6,verse:"因为主所爱的，他必管教，又鞭打凡所收纳的儿子。”"},
{volumn:        58,chapter:        12,versenumber:         7,verse:"你们所忍受的，是　神管教你们，待你们如同待儿子。焉有儿子不被父亲管教的呢？"},
{volumn:        58,chapter:        12,versenumber:         8,verse:"管教原是众子所共受的。你们若不受管教，就是私子，不是儿子了。"},
{volumn:        58,chapter:        12,versenumber:         9,verse:"再者，我们曾有生身的父管教我们，我们尚且敬重他，何况万灵的父，我们岂不更当顺服他得生吗？"},
{volumn:        58,chapter:        12,versenumber:        10,verse:"生身的父都是暂随己意管教我们；惟有万灵的父管教我们，是要我们得益处，使我们在他的圣洁上有份。"},
{volumn:        58,chapter:        12,versenumber:        11,verse:"凡管教的事，当时不觉得快乐，反觉得愁苦；后来却为那经练过的人结出平安的果子，就是义。"},
{volumn:        58,chapter:        12,versenumber:        12,verse:"所以，你们要把下垂的手、发酸的腿挺起来；"},
{volumn:        58,chapter:        12,versenumber:        13,verse:"也要为自己的脚，把道路修直了，使瘸子不致歪脚（或译：差路），反得痊愈。"},
{volumn:        58,chapter:        12,versenumber:        14,verse:"你们要追求与众人和睦，并要追求圣洁；非圣洁没有人能见主。"},
{volumn:        58,chapter:        12,versenumber:        15,verse:"又要谨慎，恐怕有人失了　神的恩；恐怕有毒根生出来扰乱你们，因此叫众人沾染污秽；"},
{volumn:        58,chapter:        12,versenumber:        16,verse:"恐怕有淫乱的，有贪恋世俗如以扫的，他因一点食物把自己长子的名分卖了。"},
{volumn:        58,chapter:        12,versenumber:        17,verse:"后来想要承受父所祝的福，竟被弃绝，虽然号哭切求，却得不着门路使他父亲的心意回转。这是你们知道的。"},
{volumn:        58,chapter:        12,versenumber:        18,verse:"你们原不是来到那能摸的山；此山有火焰、密云、黑暗、暴风、"},
{volumn:        58,chapter:        12,versenumber:        19,verse:"角声与说话的声音。那些听见这声音的，都求不要再向他们说话；"},
{volumn:        58,chapter:        12,versenumber:        20,verse:"因为他们当不起所命他们的话，说：“靠近这山的，即便是走兽，也要用石头打死。”"},
{volumn:        58,chapter:        12,versenumber:        21,verse:"所见的极其可怕，甚至摩西说：“我甚是恐惧战兢。”"},
{volumn:        58,chapter:        12,versenumber:        22,verse:"你们乃是来到锡安山，永生　神的城邑，就是天上的耶路撒冷。那里有千万的天使，"},
{volumn:        58,chapter:        12,versenumber:        23,verse:"有名录在天上诸长子之会所共聚的总会，有审判众人的　神和被成全之义人的灵魂，"},
{volumn:        58,chapter:        12,versenumber:        24,verse:"并新约的中保耶稣，以及所洒的血；这血所说的比亚伯的血所说的更美。"},
{volumn:        58,chapter:        12,versenumber:        25,verse:"你们总要谨慎，不可弃绝那向你们说话的。因为，那些弃绝在地上警戒他们的尚且不能逃罪，何况我们违背那从天上警戒我们的呢？"},
{volumn:        58,chapter:        12,versenumber:        26,verse:"当时他的声音震动了地，但如今他应许说：“再一次我不单要震动地，还要震动天。”"},
{volumn:        58,chapter:        12,versenumber:        27,verse:"这再一次的话，是指明被震动的，就是受造之物都要挪去，使那不被震动的常存。"},
{volumn:        58,chapter:        12,versenumber:        28,verse:"所以我们既得了不能震动的国，就当感恩，照　神所喜悦的，用虔诚、敬畏的心侍奉　神。"},
{volumn:        58,chapter:        12,versenumber:        29,verse:"因为我们的　神乃是烈火。"},
{volumn:        58,chapter:        13,versenumber:         1,verse:"你们务要常存弟兄相爱的心。"},
{volumn:        58,chapter:        13,versenumber:         2,verse:"不可忘记用爱心接待客旅；因为曾有接待客旅的，不知不觉就接待了天使。"},
{volumn:        58,chapter:        13,versenumber:         3,verse:"你们要记念被捆绑的人，好像与他们同受捆绑；也要记念遭苦害的人，想到自己也在肉身之内。"},
{volumn:        58,chapter:        13,versenumber:         4,verse:"婚姻，人人都当尊重，床也不可污秽；因为苟合行淫的人，　神必要审判。"},
{volumn:        58,chapter:        13,versenumber:         5,verse:"你们存心不可贪爱钱财，要以自己所有的为足；因为主曾说：“我总不撇下你，也不丢弃你。”"},
{volumn:        58,chapter:        13,versenumber:         6,verse:"所以我们可以放胆说：“主是帮助我的，我必不惧怕；人能把我怎么样呢？”"},
{volumn:        58,chapter:        13,versenumber:         7,verse:"从前引导你们、传　神之道给你们的人，你们要想念他们，效法他们的信心，留心看他们为人的结局。"},
{volumn:        58,chapter:        13,versenumber:         8,verse:"耶稣基督昨日、今日、一直到永远，是一样的。"},
{volumn:        58,chapter:        13,versenumber:         9,verse:"你们不要被那诸般怪异的教训勾引了去；因为人心靠恩得坚固才是好的，并不是靠饮食。那在饮食上专心的从来没有得着益处。"},
{volumn:        58,chapter:        13,versenumber:        10,verse:"我们有一祭坛，上面的祭物是那些在帐幕中供职的人不可同吃的。"},
{volumn:        58,chapter:        13,versenumber:        11,verse:"原来牲畜的血被大祭司带入圣所作赎罪祭；牲畜的身子被烧在营外。"},
{volumn:        58,chapter:        13,versenumber:        12,verse:"所以，耶稣要用自己的血叫百姓成圣，也就在城门外受苦。"},
{volumn:        58,chapter:        13,versenumber:        13,verse:"这样，我们也当出到营外，就了他去，忍受他所受的凌辱。"},
{volumn:        58,chapter:        13,versenumber:        14,verse:"我们在这里本没有常存的城，乃是寻求那将来的城。"},
{volumn:        58,chapter:        13,versenumber:        15,verse:"我们应当靠着耶稣，常常以颂赞为祭献给　神，这就是那承认主名之人嘴唇的果子。"},
{volumn:        58,chapter:        13,versenumber:        16,verse:"只是不可忘记行善和捐输的事，因为这样的祭是　神所喜悦的。"},
{volumn:        58,chapter:        13,versenumber:        17,verse:"你们要依从那些引导你们的，且要顺服；因他们为你们的灵魂时刻警醒，好像那将来交帐的人。你们要使他们交的时候有快乐，不致忧愁；若忧愁就与你们无益了。"},
{volumn:        58,chapter:        13,versenumber:        18,verse:"请你们为我们祷告，因我们自觉良心无亏，愿意凡事按正道而行。"},
{volumn:        58,chapter:        13,versenumber:        19,verse:"我更求你们为我祷告，使我快些回到你们那里去。"},
{volumn:        58,chapter:        13,versenumber:        20,verse:"但愿赐平安的　神，就是那凭永约之血、使群羊的大牧人、我主耶稣从死里复活的　神，"},
{volumn:        58,chapter:        13,versenumber:        21,verse:"在各样善事上成全你们，叫你们遵行他的旨意；又藉着耶稣基督在你们心里行他所喜悦的事。愿荣耀归给他，直到永永远远。阿们！"},
{volumn:        58,chapter:        13,versenumber:        22,verse:"弟兄们，我略略写信给你们，望你们听我劝勉的话。"},
{volumn:        58,chapter:        13,versenumber:        23,verse:"你们该知道，我们的兄弟提摩太已经释放了；他若快来，我必同他去见你们。"},
{volumn:        58,chapter:        13,versenumber:        24,verse:"请你们问引导你们的诸位和众圣徒安。从意大利来的人也问你们安。"},
{volumn:        58,chapter:        13,versenumber:        25,verse:"愿恩惠常与你们众人同在。阿们！"},
{volumn:        59,chapter:         1,versenumber:         1,verse:"作　神和主耶稣基督仆人的雅各请散住十二个支派之人的安。"},
{volumn:        59,chapter:         1,versenumber:         2,verse:"我的弟兄们，你们落在百般试炼中，都要以为大喜乐；"},
{volumn:        59,chapter:         1,versenumber:         3,verse:"因为知道你们的信心经过试验，就生忍耐。"},
{volumn:        59,chapter:         1,versenumber:         4,verse:"但忍耐也当成功，使你们成全、完备，毫无缺欠。"},
{volumn:        59,chapter:         1,versenumber:         5,verse:"你们中间若有缺少智慧的，应当求那厚赐与众人、也不斥责人的　神，主就必赐给他。"},
{volumn:        59,chapter:         1,versenumber:         6,verse:"只要凭着信心求，一点不疑惑；因为那疑惑的人，就像海中的波浪，被风吹动翻腾。"},
{volumn:        59,chapter:         1,versenumber:         7,verse:"这样的人不要想从主那里得什么。"},
{volumn:        59,chapter:         1,versenumber:         8,verse:"心怀二意的人，在他一切所行的路上都没有定见。"},
{volumn:        59,chapter:         1,versenumber:         9,verse:"卑微的弟兄升高，就该喜乐；"},
{volumn:        59,chapter:         1,versenumber:        10,verse:"富足的降卑，也该如此；因为他必要过去，如同草上的花一样。"},
{volumn:        59,chapter:         1,versenumber:        11,verse:"太阳出来，热风刮起，草就枯干，花也凋谢，美容就消没了；那富足的人，在他所行的事上也要这样衰残。"},
{volumn:        59,chapter:         1,versenumber:        12,verse:"忍受试探的人是有福的，因为他经过试验以后，必得生命的冠冕；这是主应许给那些爱他之人的。"},
{volumn:        59,chapter:         1,versenumber:        13,verse:"人被试探，不可说：“我是被　神试探”；因为　神不能被恶试探，他也不试探人。"},
{volumn:        59,chapter:         1,versenumber:        14,verse:"但各人被试探，乃是被自己的私欲牵引诱惑的。"},
{volumn:        59,chapter:         1,versenumber:        15,verse:"私欲既怀了胎，就生出罪来；罪既长成，就生出死来。"},
{volumn:        59,chapter:         1,versenumber:        16,verse:"我亲爱的弟兄们，不要看错了。"},
{volumn:        59,chapter:         1,versenumber:        17,verse:"各样美善的恩赐和各样全备的赏赐都是从上头来的，从众光之父那里降下来的；在他并没有改变，也没有转动的影儿。"},
{volumn:        59,chapter:         1,versenumber:        18,verse:"他按自己的旨意，用真道生了我们，叫我们在他所造的万物中好像初熟的果子。"},
{volumn:        59,chapter:         1,versenumber:        19,verse:"我亲爱的弟兄们，这是你们所知道的。但你们各人要快快地听，慢慢地说，慢慢地动怒，"},
{volumn:        59,chapter:         1,versenumber:        20,verse:"因为人的怒气并不成就　神的义。"},
{volumn:        59,chapter:         1,versenumber:        21,verse:"所以，你们要脱去一切的污秽和盈余的邪恶，存温柔的心领受那所栽种的道，就是能救你们灵魂的道。"},
{volumn:        59,chapter:         1,versenumber:        22,verse:"只是你们要行道，不要单单听道，自己欺哄自己。"},
{volumn:        59,chapter:         1,versenumber:        23,verse:"因为听道而不行道的，就像人对着镜子看自己本来的面目，"},
{volumn:        59,chapter:         1,versenumber:        24,verse:"看见，走后，随即忘了他的相貌如何。"},
{volumn:        59,chapter:         1,versenumber:        25,verse:"惟有详细察看那全备、使人自由之律法的，并且时常如此，这人既不是听了就忘，乃是实在行出来，就在他所行的事上必然得福。"},
{volumn:        59,chapter:         1,versenumber:        26,verse:"若有人自以为虔诚，却不勒住他的舌头，反欺哄自己的心，这人的虔诚是虚的。"},
{volumn:        59,chapter:         1,versenumber:        27,verse:"在　神我们的父面前，那清洁没有玷污的虔诚，就是看顾在患难中的孤儿寡妇，并且保守自己不沾染世俗。"},
{volumn:        59,chapter:         2,versenumber:         1,verse:"我的弟兄们，你们信奉我们荣耀的主耶稣基督，便不可按着外貌待人。"},
{volumn:        59,chapter:         2,versenumber:         2,verse:"若有一个人带着金戒指，穿着华美衣服，进你们的会堂去；又有一个穷人穿着肮脏衣服也进去；"},
{volumn:        59,chapter:         2,versenumber:         3,verse:"你们就重看那穿华美衣服的人，说：“请坐在这好位上”；又对那穷人说：“你站在那里”，或“坐在我脚凳下边。”"},
{volumn:        59,chapter:         2,versenumber:         4,verse:"这岂不是你们偏心待人，用恶意断定人吗？"},
{volumn:        59,chapter:         2,versenumber:         5,verse:"我亲爱的弟兄们，请听，　神岂不是拣选了世上的贫穷人，叫他们在信上富足，并承受他所应许给那些爱他之人的国吗？"},
{volumn:        59,chapter:         2,versenumber:         6,verse:"你们反倒羞辱贫穷人。那富足人岂不是欺压你们、拉你们到公堂去吗？"},
{volumn:        59,chapter:         2,versenumber:         7,verse:"他们不是亵渎你们所敬奉（所敬奉：或译被称）的尊名吗？"},
{volumn:        59,chapter:         2,versenumber:         8,verse:"经上记着说：“要爱人如己。”你们若全守这至尊的律法才是好的。"},
{volumn:        59,chapter:         2,versenumber:         9,verse:"但你们若按外貌待人，便是犯罪，被律法定为犯法的。"},
{volumn:        59,chapter:         2,versenumber:        10,verse:"因为凡遵守全律法的，只在一条上跌倒，他就是犯了众条。"},
{volumn:        59,chapter:         2,versenumber:        11,verse:"原来那说“不可奸淫”的，也说“不可杀人”；你就是不奸淫，却杀人，仍是成了犯律法的。"},
{volumn:        59,chapter:         2,versenumber:        12,verse:"你们既然要按使人自由的律法受审判，就该照这律法说话行事。"},
{volumn:        59,chapter:         2,versenumber:        13,verse:"因为那不怜悯人的，也要受无怜悯的审判；怜悯原是向审判夸胜。"},
{volumn:        59,chapter:         2,versenumber:        14,verse:"我的弟兄们，若有人说自己有信心，却没有行为，有什么益处呢？这信心能救他吗？"},
{volumn:        59,chapter:         2,versenumber:        15,verse:"若是弟兄或是姐妹，赤身露体，又缺了日用的饮食；"},
{volumn:        59,chapter:         2,versenumber:        16,verse:"你们中间有人对他们说：“平平安安地去吧！愿你们穿得暖，吃得饱”，却不给他们身体所需用的，这有什么益处呢？"},
{volumn:        59,chapter:         2,versenumber:        17,verse:"这样，信心若没有行为就是死的。"},
{volumn:        59,chapter:         2,versenumber:        18,verse:"必有人说：“你有信心，我有行为。”你将你没有行为的信心指给我看，我便藉着我的行为，将我的信心指给你看。"},
{volumn:        59,chapter:         2,versenumber:        19,verse:"你信　神只有一位，你信的不错；鬼魔也信，却是战惊。"},
{volumn:        59,chapter:         2,versenumber:        20,verse:"虚浮的人哪，你愿意知道没有行为的信心是死的吗？"},
{volumn:        59,chapter:         2,versenumber:        21,verse:"我们的祖宗亚伯拉罕把他儿子以撒献在坛上，岂不是因行为称义吗？"},
{volumn:        59,chapter:         2,versenumber:        22,verse:"可见，信心是与他的行为并行，而且信心因着行为才得成全。"},
{volumn:        59,chapter:         2,versenumber:        23,verse:"这就应验经上所说：“亚伯拉罕信　神，这就算为他的义。”他又得称为　神的朋友。"},
{volumn:        59,chapter:         2,versenumber:        24,verse:"这样看来，人称义是因着行为，不是单因着信。"},
{volumn:        59,chapter:         2,versenumber:        25,verse:"妓女喇合接待使者，又放他们从别的路上出去，不也是一样因行为称义吗？"},
{volumn:        59,chapter:         2,versenumber:        26,verse:"身体没有灵魂是死的，信心没有行为也是死的。"},
{volumn:        59,chapter:         3,versenumber:         1,verse:"我的弟兄们，不要多人作师傅，因为晓得我们要受更重的判断。"},
{volumn:        59,chapter:         3,versenumber:         2,verse:"原来我们在许多事上都有过失；若有人在话语上没有过失，他就是完全人，也能勒住自己的全身。"},
{volumn:        59,chapter:         3,versenumber:         3,verse:"我们若把嚼环放在马嘴里，叫它顺服，就能调动它的全身。"},
{volumn:        59,chapter:         3,versenumber:         4,verse:"看哪，船只虽然甚大，又被大风催逼，只用小小的舵，就随着掌舵的意思转动。"},
{volumn:        59,chapter:         3,versenumber:         5,verse:"这样，舌头在百体里也是最小的，却能说大话。看哪，最小的火能点着最大的树林。"},
{volumn:        59,chapter:         3,versenumber:         6,verse:"舌头就是火，在我们百体中，舌头是个罪恶的世界，能污秽全身，也能把生命的轮子点起来，并且是从地狱里点着的。"},
{volumn:        59,chapter:         3,versenumber:         7,verse:"各类的走兽、飞禽、昆虫、水族，本来都可以制伏，也已经被人制伏了；"},
{volumn:        59,chapter:         3,versenumber:         8,verse:"惟独舌头没有人能制伏，是不止息的恶物，满了害死人的毒气。"},
{volumn:        59,chapter:         3,versenumber:         9,verse:"我们用舌头颂赞那为主、为父的，又用舌头咒诅那照着　神形像被造的人。"},
{volumn:        59,chapter:         3,versenumber:        10,verse:"颂赞和咒诅从一个口里出来！我的弟兄们，这是不应当的！"},
{volumn:        59,chapter:         3,versenumber:        11,verse:"泉源从一个眼里能发出甜苦两样的水吗？"},
{volumn:        59,chapter:         3,versenumber:        12,verse:"我的弟兄们，无花果树能生橄榄吗？葡萄树能结无花果吗？咸水里也不能发出甜水来。"},
{volumn:        59,chapter:         3,versenumber:        13,verse:"你们中间谁是有智慧有见识的呢？他就当在智慧的温柔上显出他的善行来。"},
{volumn:        59,chapter:         3,versenumber:        14,verse:"你们心里若怀着苦毒的嫉妒和纷争，就不可自夸，也不可说谎话抵挡真道。"},
{volumn:        59,chapter:         3,versenumber:        15,verse:"这样的智慧不是从上头来的，乃是属地的，属情欲的，属鬼魔的。"},
{volumn:        59,chapter:         3,versenumber:        16,verse:"在何处有嫉妒、纷争，就在何处有扰乱和各样的坏事。"},
{volumn:        59,chapter:         3,versenumber:        17,verse:"惟独从上头来的智慧，先是清洁，后是和平，温良柔顺，满有怜悯，多结善果，没有偏见，没有假冒。"},
{volumn:        59,chapter:         3,versenumber:        18,verse:"并且使人和平的，是用和平所栽种的义果。"},
{volumn:        59,chapter:         4,versenumber:         1,verse:"你们中间的争战斗殴是从哪里来的呢？不是从你们百体中战斗之私欲来的吗？"},
{volumn:        59,chapter:         4,versenumber:         2,verse:"你们贪恋，还是得不着；你们杀害嫉妒，又斗殴争战，也不能得。你们得不着，是因为你们不求。"},
{volumn:        59,chapter:         4,versenumber:         3,verse:"你们求也得不着，是因为你们妄求，要浪费在你们的宴乐中。"},
{volumn:        59,chapter:         4,versenumber:         4,verse:"你们这些淫乱的人（原文是淫妇）哪，岂不知与世俗为友就是与　神为敌吗？所以凡想要与世俗为友的，就是与　神为敌了。"},
{volumn:        59,chapter:         4,versenumber:         5,verse:"你们想经上所说是徒然的吗？　神所赐、住在我们里面的灵，是恋爱至于嫉妒吗？"},
{volumn:        59,chapter:         4,versenumber:         6,verse:"但他赐更多的恩典，所以经上说：“　神阻挡骄傲的人，赐恩给谦卑的人。”"},
{volumn:        59,chapter:         4,versenumber:         7,verse:"故此，你们要顺服　神。务要抵挡魔鬼，魔鬼就必离开你们逃跑了。"},
{volumn:        59,chapter:         4,versenumber:         8,verse:"你们亲近　神，　神就必亲近你们。有罪的人哪，要洁净你们的手！心怀二意的人哪，要清洁你们的心！"},
{volumn:        59,chapter:         4,versenumber:         9,verse:"你们要愁苦、悲哀、哭泣，将喜笑变作悲哀，欢乐变作愁闷。"},
{volumn:        59,chapter:         4,versenumber:        10,verse:"务要在主面前自卑，主就必叫你们升高。"},
{volumn:        59,chapter:         4,versenumber:        11,verse:"弟兄们，你们不可彼此批评。人若批评弟兄，论断弟兄，就是批评律法，论断律法。你若论断律法，就不是遵行律法，乃是判断人的。"},
{volumn:        59,chapter:         4,versenumber:        12,verse:"设立律法和判断人的，只有一位，就是那能救人也能灭人的。你是谁，竟敢论断别人呢？"},
{volumn:        59,chapter:         4,versenumber:        13,verse:"嗐！你们有话说：“今天明天我们要往某城里去，在那里住一年，做买卖得利。”"},
{volumn:        59,chapter:         4,versenumber:        14,verse:"其实明天如何，你们还不知道。你们的生命是什么呢？你们原来是一片云雾，出现少时就不见了。"},
{volumn:        59,chapter:         4,versenumber:        15,verse:"你们只当说：“主若愿意，我们就可以活着，也可以做这事，或做那事。”"},
{volumn:        59,chapter:         4,versenumber:        16,verse:"现今你们竟以张狂夸口；凡这样夸口都是恶的。"},
{volumn:        59,chapter:         4,versenumber:        17,verse:"人若知道行善，却不去行，这就是他的罪了。"},
{volumn:        59,chapter:         5,versenumber:         1,verse:"嗐！你们这些富足人哪，应当哭泣、号啕，因为将有苦难临到你们身上。"},
{volumn:        59,chapter:         5,versenumber:         2,verse:"你们的财物坏了，衣服也被虫子咬了。"},
{volumn:        59,chapter:         5,versenumber:         3,verse:"你们的金银都长了锈；那锈要证明你们的不是，又要吃你们的肉，如同火烧。你们在这末世只知积攒钱财。"},
{volumn:        59,chapter:         5,versenumber:         4,verse:"工人给你们收割庄稼，你们亏欠他们的工钱，这工钱有声音呼叫，并且那收割之人的冤声已经入了万军之主的耳了。"},
{volumn:        59,chapter:         5,versenumber:         5,verse:"你们在世上享美福，好宴乐，当宰杀的日子竟娇养你们的心。"},
{volumn:        59,chapter:         5,versenumber:         6,verse:"你们定了义人的罪，把他杀害，他也不抵挡你们。"},
{volumn:        59,chapter:         5,versenumber:         7,verse:"弟兄们哪，你们要忍耐，直到主来。看哪，农夫忍耐等候地里宝贵的出产，直到得了秋雨春雨。"},
{volumn:        59,chapter:         5,versenumber:         8,verse:"你们也当忍耐，坚固你们的心，因为主来的日子近了。"},
{volumn:        59,chapter:         5,versenumber:         9,verse:"弟兄们，你们不要彼此埋怨，免得受审判。看哪，审判的主站在门前了。"},
{volumn:        59,chapter:         5,versenumber:        10,verse:"弟兄们，你们要把那先前奉主名说话的众先知当作能受苦能忍耐的榜样。"},
{volumn:        59,chapter:         5,versenumber:        11,verse:"那先前忍耐的人，我们称他们是有福的。你们听见过约伯的忍耐，也知道主给他的结局，明显主是满心怜悯，大有慈悲。"},
{volumn:        59,chapter:         5,versenumber:        12,verse:"我的弟兄们，最要紧的是不可起誓；不可指着天起誓，也不可指着地起誓，无论何誓都不可起。你们说话，是，就说是；不是，就说不是，免得你们落在审判之下。"},
{volumn:        59,chapter:         5,versenumber:        13,verse:"你们中间有受苦的呢，他就该祷告；有喜乐的呢，他就该歌颂。"},
{volumn:        59,chapter:         5,versenumber:        14,verse:"你们中间有病了的呢，他就该请教会的长老来；他们可以奉主的名用油抹他，为他祷告。"},
{volumn:        59,chapter:         5,versenumber:        15,verse:"出于信心的祈祷要救那病人，主必叫他起来；他若犯了罪，也必蒙赦免。"},
{volumn:        59,chapter:         5,versenumber:        16,verse:"所以你们要彼此认罪，互相代求，使你们可以得医治。义人祈祷所发的力量是大有功效的。"},
{volumn:        59,chapter:         5,versenumber:        17,verse:"以利亚与我们是一样性情的人，他恳切祷告，求不要下雨，雨就三年零六个月不下在地上。"},
{volumn:        59,chapter:         5,versenumber:        18,verse:"他又祷告，天就降下雨来，地也生出土产。"},
{volumn:        59,chapter:         5,versenumber:        19,verse:"我的弟兄们，你们中间若有失迷真道的，有人使他回转，"},
{volumn:        59,chapter:         5,versenumber:        20,verse:"这人该知道：叫一个罪人从迷路上转回便是救一个灵魂不死，并且遮盖许多的罪。"},
{volumn:        60,chapter:         1,versenumber:         1,verse:"耶稣基督的使徒彼得写信给那分散在本都、加拉太、加帕多家、亚细亚、庇推尼寄居的，"},
{volumn:        60,chapter:         1,versenumber:         2,verse:"就是照父　神的先见被拣选，藉着圣灵得成圣洁，以致顺服耶稣基督，又蒙他血所洒的人。愿恩惠、平安多多地加给你们。"},
{volumn:        60,chapter:         1,versenumber:         3,verse:"愿颂赞归与我们主耶稣基督的父　神！他曾照自己的大怜悯，藉耶稣基督从死里复活，重生了我们，叫我们有活泼的盼望，"},
{volumn:        60,chapter:         1,versenumber:         4,verse:"可以得着不能朽坏、不能玷污、不能衰残、为你们存留在天上的基业。"},
{volumn:        60,chapter:         1,versenumber:         5,verse:"你们这因信蒙　神能力保守的人，必能得着所预备、到末世要显现的救恩。"},
{volumn:        60,chapter:         1,versenumber:         6,verse:"因此，你们是大有喜乐；但如今，在百般的试炼中暂时忧愁，"},
{volumn:        60,chapter:         1,versenumber:         7,verse:"叫你们的信心既被试验，就比那被火试验仍然能坏的金子更显宝贵，可以在耶稣基督显现的时候得着称赞、荣耀、尊贵。"},
{volumn:        60,chapter:         1,versenumber:         8,verse:"你们虽然没有见过他，却是爱他；如今虽不得看见，却因信他就有说不出来、满有荣光的大喜乐；"},
{volumn:        60,chapter:         1,versenumber:         9,verse:"并且得着你们信心的果效，就是灵魂的救恩。"},
{volumn:        60,chapter:         1,versenumber:        10,verse:"论到这救恩，那预先说你们要得恩典的众先知早已详细地寻求考察，"},
{volumn:        60,chapter:         1,versenumber:        11,verse:"就是考察在他们心里基督的灵，预先证明基督受苦难，后来得荣耀，是指着什么时候，并怎样的时候。"},
{volumn:        60,chapter:         1,versenumber:        12,verse:"他们得了启示，知道他们所传讲（原文是服侍）的一切事，不是为自己，乃是为你们。那靠着从天上差来的圣灵传福音给你们的人，现在将这些事报给你们；天使也愿意详细察看这些事。"},
{volumn:        60,chapter:         1,versenumber:        13,verse:"所以要约束你们的心（原文是束上你们心中的腰），谨慎自守，专心盼望耶稣基督显现的时候所带来给你们的恩。"},
{volumn:        60,chapter:         1,versenumber:        14,verse:"你们既作顺命的儿女，就不要效法从前蒙昧无知的时候那放纵私欲的样子。"},
{volumn:        60,chapter:         1,versenumber:        15,verse:"那召你们的既是圣洁，你们在一切所行的事上也要圣洁。"},
{volumn:        60,chapter:         1,versenumber:        16,verse:"因为经上记着说：“你们要圣洁，因为我是圣洁的。”"},
{volumn:        60,chapter:         1,versenumber:        17,verse:"你们既称那不偏待人、按各人行为审判人的主为父，就当存敬畏的心度你们在世寄居的日子，"},
{volumn:        60,chapter:         1,versenumber:        18,verse:"知道你们得赎，脱去你们祖宗所传流虚妄的行为，不是凭着能坏的金银等物，"},
{volumn:        60,chapter:         1,versenumber:        19,verse:"乃是凭着基督的宝血，如同无瑕疵、无玷污的羔羊之血。"},
{volumn:        60,chapter:         1,versenumber:        20,verse:"基督在创世以前是预先被　神知道的，却在这末世才为你们显现。"},
{volumn:        60,chapter:         1,versenumber:        21,verse:"你们也因着他，信那叫他从死里复活、又给他荣耀的　神，叫你们的信心和盼望都在于　神。"},
{volumn:        60,chapter:         1,versenumber:        22,verse:"你们既因顺从真理，洁净了自己的心，以致爱弟兄没有虚假，就当从心里（从心里：有古卷是从清洁的心）彼此切实相爱。"},
{volumn:        60,chapter:         1,versenumber:        23,verse:"你们蒙了重生，不是由于能坏的种子，乃是由于不能坏的种子，是藉着　神活泼常存的道。"},
{volumn:        60,chapter:         1,versenumber:        24,verse:"因为：“凡有血气的，尽都如草；他的美荣都像草上的花。草必枯干，花必凋谢；"},
{volumn:        60,chapter:         1,versenumber:        25,verse:"惟有主的道是永存的。”所传给你们的福音就是这道。"},
{volumn:        60,chapter:         2,versenumber:         1,verse:"所以，你们既除去一切的恶毒（或译：阴毒）、诡诈，并假善、嫉妒，和一切毁谤的话，"},
{volumn:        60,chapter:         2,versenumber:         2,verse:"就要爱慕那纯净的灵奶，像才生的婴孩爱慕奶一样，叫你们因此渐长，以致得救。"},
{volumn:        60,chapter:         2,versenumber:         3,verse:"你们若尝过主恩的滋味，就必如此。"},
{volumn:        60,chapter:         2,versenumber:         4,verse:"主乃活石，固然是被人所弃的，却是被　神所拣选、所宝贵的。"},
{volumn:        60,chapter:         2,versenumber:         5,verse:"你们来到主面前，也就像活石，被建造成为灵宫，作圣洁的祭司，藉着耶稣基督奉献　神所悦纳的灵祭。"},
{volumn:        60,chapter:         2,versenumber:         6,verse:"因为经上说：“看哪，我把所拣选、所宝贵的房角石安放在锡安；信靠他的人必不至于羞愧。”"},
{volumn:        60,chapter:         2,versenumber:         7,verse:"所以，他在你们信的人就为宝贵，在那不信的人有话说：“匠人所弃的石头已作了房角的头块石头。”"},
{volumn:        60,chapter:         2,versenumber:         8,verse:"又说：“作了绊脚的石头，跌人的磐石。”他们既不顺从，就在道理上绊跌（或译：他们绊跌都因不顺从道理）；他们这样绊跌也是预定的。"},
{volumn:        60,chapter:         2,versenumber:         9,verse:"惟有你们是被拣选的族类，是有君尊的祭司，是圣洁的国度，是属　神的子民，要叫你们宣扬那召你们出黑暗、入奇妙光明者的美德。"},
{volumn:        60,chapter:         2,versenumber:        10,verse:"你们从前算不得子民，现在却作了　神的子民；从前未曾蒙怜恤，现在却蒙了怜恤。"},
{volumn:        60,chapter:         2,versenumber:        11,verse:"亲爱的弟兄啊，你们是客旅，是寄居的。我劝你们要禁戒肉体的私欲；这私欲是与灵魂争战的。"},
{volumn:        60,chapter:         2,versenumber:        12,verse:"你们在外邦人中，应当品行端正，叫那些毁谤你们是作恶的，因看见你们的好行为，便在鉴察（或译：眷顾）的日子归荣耀给　神。"},
{volumn:        60,chapter:         2,versenumber:        13,verse:"你们为主的缘故，要顺服人的一切制度，或是在上的君王，"},
{volumn:        60,chapter:         2,versenumber:        14,verse:"或是君王所派罚恶赏善的臣宰。"},
{volumn:        60,chapter:         2,versenumber:        15,verse:"因为　神的旨意原是要你们行善，可以堵住那糊涂无知人的口。"},
{volumn:        60,chapter:         2,versenumber:        16,verse:"你们虽是自由的，却不可藉着自由遮盖恶毒（或译：阴毒），总要作　神的仆人。"},
{volumn:        60,chapter:         2,versenumber:        17,verse:"务要尊敬众人，亲爱教中的弟兄，敬畏　神，尊敬君王。"},
{volumn:        60,chapter:         2,versenumber:        18,verse:"你们作仆人的，凡事要存敬畏的心顺服主人；不但顺服那善良温和的，就是那乖僻的也要顺服。"},
{volumn:        60,chapter:         2,versenumber:        19,verse:"倘若人为叫良心对得住　神，就忍受冤屈的苦楚，这是可喜爱的。"},
{volumn:        60,chapter:         2,versenumber:        20,verse:"你们若因犯罪受责打，能忍耐，有什么可夸的呢？但你们若因行善受苦，能忍耐，这在　神看是可喜爱的。"},
{volumn:        60,chapter:         2,versenumber:        21,verse:"你们蒙召原是为此；因基督也为你们受过苦，给你们留下榜样，叫你们跟随他的脚踪行。"},
{volumn:        60,chapter:         2,versenumber:        22,verse:"他并没有犯罪，口里也没有诡诈。"},
{volumn:        60,chapter:         2,versenumber:        23,verse:"他被骂不还口；受害不说威吓的话，只将自己交托那按公义审判人的主。"},
{volumn:        60,chapter:         2,versenumber:        24,verse:"他被挂在木头上，亲身担当了我们的罪，使我们既然在罪上死，就得以在义上活。因他受的鞭伤，你们便得了医治。"},
{volumn:        60,chapter:         2,versenumber:        25,verse:"你们从前好像迷路的羊，如今却归到你们灵魂的牧人监督了。"},
{volumn:        60,chapter:         3,versenumber:         1,verse:"你们作妻子的要顺服自己的丈夫；这样，若有不信从道理的丈夫，他们虽然不听道，也可以因妻子的品行被感化过来；"},
{volumn:        60,chapter:         3,versenumber:         2,verse:"这正是因看见你们有贞洁的品行和敬畏的心。"},
{volumn:        60,chapter:         3,versenumber:         3,verse:"你们不要以外面的辫头发，戴金饰，穿美衣为妆饰，"},
{volumn:        60,chapter:         3,versenumber:         4,verse:"只要以里面存着长久温柔、安静的心为妆饰；这在　神面前是极宝贵的。"},
{volumn:        60,chapter:         3,versenumber:         5,verse:"因为古时仰赖　神的圣洁妇人正是以此为妆饰，顺服自己的丈夫，"},
{volumn:        60,chapter:         3,versenumber:         6,verse:"就如撒拉听从亚伯拉罕，称他为主。你们若行善，不因恐吓而害怕，便是撒拉的女儿了。"},
{volumn:        60,chapter:         3,versenumber:         7,verse:"你们作丈夫的也要按情理（原文是知识）和妻子同住；因她比你软弱（比你软弱：原文作是软弱的器皿），与你一同承受生命之恩的，所以要敬重她。这样，便叫你们的祷告没有阻碍。"},
{volumn:        60,chapter:         3,versenumber:         8,verse:"总而言之，你们都要同心，彼此体恤，相爱如弟兄，存慈怜谦卑的心。"},
{volumn:        60,chapter:         3,versenumber:         9,verse:"不以恶报恶，以辱骂还辱骂，倒要祝福；因你们是为此蒙召，好叫你们承受福气。"},
{volumn:        60,chapter:         3,versenumber:        10,verse:"因为经上说：“人若爱生命，愿享美福，须要禁止舌头不出恶言，嘴唇不说诡诈的话；"},
{volumn:        60,chapter:         3,versenumber:        11,verse:"也要离恶行善，寻求和睦，一心追赶。"},
{volumn:        60,chapter:         3,versenumber:        12,verse:"因为，主的眼看顾义人，主的耳听他们的祈祷；惟有行恶的人，主向他们变脸。”"},
{volumn:        60,chapter:         3,versenumber:        13,verse:"你们若是热心行善，有谁害你们呢？"},
{volumn:        60,chapter:         3,versenumber:        14,verse:"你们就是为义受苦，也是有福的。不要怕人的威吓（的威吓：或译所怕的），也不要惊慌；"},
{volumn:        60,chapter:         3,versenumber:        15,verse:"只要心里尊主基督为圣。有人问你们心中盼望的缘由，就要常作准备，以温柔、敬畏的心回答各人；"},
{volumn:        60,chapter:         3,versenumber:        16,verse:"存着无亏的良心，叫你们在何事上被毁谤，就在何事上可以叫那诬赖你们在基督里有好品行的人自觉羞愧。"},
{volumn:        60,chapter:         3,versenumber:        17,verse:"神的旨意若是叫你们因行善受苦，总强如因行恶受苦。"},
{volumn:        60,chapter:         3,versenumber:        18,verse:"因基督也曾一次为罪受苦（有古卷：受死），就是义的代替不义的，为要引我们到　神面前。按着肉体说，他被治死；按着灵性说，他复活了。"},
{volumn:        60,chapter:         3,versenumber:        19,verse:"他藉这灵曾去传道给那些在监狱里的灵听，"},
{volumn:        60,chapter:         3,versenumber:        20,verse:"就是那从前在挪亚预备方舟、　神容忍等待的时候，不信从的人。当时进入方舟，藉着水得救的不多，只有八个人。"},
{volumn:        60,chapter:         3,versenumber:        21,verse:"这水所表明的洗礼，现在藉着耶稣基督复活也拯救你们；这洗礼本不在乎除掉肉体的污秽，只求在　神面前有无亏的良心。"},
{volumn:        60,chapter:         3,versenumber:        22,verse:"耶稣已经进入天堂，在　神的右边；众天使和有权柄的，并有能力的，都服从了他。"},
{volumn:        60,chapter:         4,versenumber:         1,verse:"基督既在肉身受苦，你们也当将这样的心志作为兵器，因为在肉身受过苦的，就已经与罪断绝了。"},
{volumn:        60,chapter:         4,versenumber:         2,verse:"你们存这样的心，从今以后就可以不从人的情欲，只从　神的旨意在世度余下的光阴。"},
{volumn:        60,chapter:         4,versenumber:         3,verse:"因为往日随从外邦人的心意行邪淫、恶欲、醉酒、荒宴、群饮，并可恶拜偶像的事，时候已经够了。"},
{volumn:        60,chapter:         4,versenumber:         4,verse:"他们在这些事上，见你们不与他们同奔那放荡无度的路，就以为怪，毁谤你们。"},
{volumn:        60,chapter:         4,versenumber:         5,verse:"他们必在那将要审判活人死人的主面前交账。"},
{volumn:        60,chapter:         4,versenumber:         6,verse:"为此，就是死人也曾有福音传给他们，要叫他们的肉体按着人受审判，他们的灵性却靠　神活着。"},
{volumn:        60,chapter:         4,versenumber:         7,verse:"万物的结局近了。所以，你们要谨慎自守，警醒祷告。"},
{volumn:        60,chapter:         4,versenumber:         8,verse:"最要紧的是彼此切实相爱，因为爱能遮掩许多的罪。"},
{volumn:        60,chapter:         4,versenumber:         9,verse:"你们要互相款待，不发怨言。"},
{volumn:        60,chapter:         4,versenumber:        10,verse:"各人要照所得的恩赐彼此服侍，作　神百般恩赐的好管家。"},
{volumn:        60,chapter:         4,versenumber:        11,verse:"若有讲道的，要按着　神的圣言讲；若有服侍人的，要按着　神所赐的力量服侍，叫　神在凡事上因耶稣基督得荣耀。原来荣耀、权能都是他的，直到永永远远。阿们！"},
{volumn:        60,chapter:         4,versenumber:        12,verse:"亲爱的弟兄啊，有火炼的试验临到你们，不要以为奇怪（似乎是遭遇非常的事），"},
{volumn:        60,chapter:         4,versenumber:        13,verse:"倒要欢喜；因为你们是与基督一同受苦，使你们在他荣耀显现的时候，也可以欢喜快乐。"},
{volumn:        60,chapter:         4,versenumber:        14,verse:"你们若为基督的名受辱骂，便是有福的；因为　神荣耀的灵常住在你们身上。"},
{volumn:        60,chapter:         4,versenumber:        15,verse:"你们中间却不可有人因为杀人、偷窃、作恶、好管闲事而受苦。"},
{volumn:        60,chapter:         4,versenumber:        16,verse:"若为作基督徒受苦，却不要羞耻，倒要因这名归荣耀给　神。"},
{volumn:        60,chapter:         4,versenumber:        17,verse:"因为时候到了，审判要从　神的家起首。若是先从我们起首，那不信从　神福音的人将有何等的结局呢？"},
{volumn:        60,chapter:         4,versenumber:        18,verse:"若是义人仅仅得救，那不虔敬和犯罪的人将有何地可站呢？"},
{volumn:        60,chapter:         4,versenumber:        19,verse:"所以，那照　神旨意受苦的人要一心为善，将自己灵魂交与那信实的造化之主。"},
{volumn:        60,chapter:         5,versenumber:         1,verse:"我这作长老、作基督受苦的见证、同享后来所要显现之荣耀的，劝你们中间与我同作长老的人："},
{volumn:        60,chapter:         5,versenumber:         2,verse:"务要牧养在你们中间　神的群羊，按着　神旨意照管他们；不是出于勉强，乃是出于甘心；也不是因为贪财，乃是出于乐意；"},
{volumn:        60,chapter:         5,versenumber:         3,verse:"也不是辖制所托付你们的，乃是作群羊的榜样。"},
{volumn:        60,chapter:         5,versenumber:         4,verse:"到了牧长显现的时候，你们必得那永不衰残的荣耀冠冕。"},
{volumn:        60,chapter:         5,versenumber:         5,verse:"你们年幼的，也要顺服年长的。就是你们众人也都要以谦卑束腰，彼此顺服；因为　神阻挡骄傲的人，赐恩给谦卑的人。"},
{volumn:        60,chapter:         5,versenumber:         6,verse:"所以，你们要自卑，服在　神大能的手下，到了时候，他必叫你们升高。"},
{volumn:        60,chapter:         5,versenumber:         7,verse:"你们要将一切的忧虑卸给　神，因为他顾念你们。"},
{volumn:        60,chapter:         5,versenumber:         8,verse:"务要谨守，警醒。因为你们的仇敌魔鬼，如同吼叫的狮子，遍地游行，寻找可吞吃的人。"},
{volumn:        60,chapter:         5,versenumber:         9,verse:"你们要用坚固的信心抵挡他，因为知道你们在世上的众弟兄也是经历这样的苦难。"},
{volumn:        60,chapter:         5,versenumber:        10,verse:"那赐诸般恩典的　神曾在基督里召你们，得享他永远的荣耀，等你们暂受苦难之后，必要亲自成全你们，坚固你们，赐力量给你们。"},
{volumn:        60,chapter:         5,versenumber:        11,verse:"愿权能归给他，直到永永远远。阿们！"},
{volumn:        60,chapter:         5,versenumber:        12,verse:"我略略地写了这信，托我所看为忠心的兄弟西拉转交你们，劝勉你们，又证明这恩是　神的真恩。你们务要在这恩上站立得住。"},
{volumn:        60,chapter:         5,versenumber:        13,verse:"在巴比伦与你们同蒙拣选的教会问你们安。我儿子马可也问你们安。"},
{volumn:        60,chapter:         5,versenumber:        14,verse:"你们要用爱心彼此亲嘴问安。愿平安归与你们凡在基督里的人！"},
{volumn:        61,chapter:         1,versenumber:         1,verse:"作耶稣基督仆人和使徒的西门·彼得写信给那因我们的　神和（有古卷没有和字）救主耶稣基督之义、与我们同得一样宝贵信心的人。"},
{volumn:        61,chapter:         1,versenumber:         2,verse:"愿恩惠、平安，因你们认识　神和我们主耶稣，多多地加给你们。"},
{volumn:        61,chapter:         1,versenumber:         3,verse:"神的神能已将一切关乎生命和虔敬的事赐给我们，皆因我们认识那用自己荣耀和美德召我们的主。"},
{volumn:        61,chapter:         1,versenumber:         4,verse:"因此，他已将又宝贵又极大的应许赐给我们，叫我们既脱离世上从情欲来的败坏，就得与　神的性情有份。"},
{volumn:        61,chapter:         1,versenumber:         5,verse:"正因这缘故，你们要分外地殷勤；有了信心，又要加上德行；有了德行，又要加上知识；"},
{volumn:        61,chapter:         1,versenumber:         6,verse:"有了知识，又要加上节制；有了节制，又要加上忍耐；有了忍耐，又要加上虔敬；"},
{volumn:        61,chapter:         1,versenumber:         7,verse:"有了虔敬，又要加上爱弟兄的心；有了爱弟兄的心，又要加上爱众人的心。"},
{volumn:        61,chapter:         1,versenumber:         8,verse:"你们若充充足足地有这几样，就必使你们在认识我们的主耶稣基督上不至于闲懒不结果子了。"},
{volumn:        61,chapter:         1,versenumber:         9,verse:"人若没有这几样，就是眼瞎，只看见近处的，忘了他旧日的罪已经得了洁净。"},
{volumn:        61,chapter:         1,versenumber:        10,verse:"所以弟兄们，应当更加殷勤，使你们所蒙的恩召和拣选坚定不移。你们若行这几样，就永不失脚；"},
{volumn:        61,chapter:         1,versenumber:        11,verse:"这样，必叫你们丰丰富富地得以进入我们主救主耶稣基督永远的国。"},
{volumn:        61,chapter:         1,versenumber:        12,verse:"你们虽然晓得这些事，并且在你们已有的真道上坚固，我却要将这些事常常提醒你们。"},
{volumn:        61,chapter:         1,versenumber:        13,verse:"我以为应当趁我还在这帐棚的时候提醒你们，激发你们；"},
{volumn:        61,chapter:         1,versenumber:        14,verse:"因为知道我脱离这帐棚的时候快到了，正如我们主耶稣基督所指示我的。"},
{volumn:        61,chapter:         1,versenumber:        15,verse:"并且，我要尽心竭力，使你们在我去世以后时常记念这些事。"},
{volumn:        61,chapter:         1,versenumber:        16,verse:"我们从前将我们主耶稣基督的大能和他降临的事告诉你们，并不是随从乖巧捏造的虚言，乃是亲眼见过他的威荣。"},
{volumn:        61,chapter:         1,versenumber:        17,verse:"他从父　神得尊贵荣耀的时候，从极大荣光之中有声音出来，向他说：“这是我的爱子，我所喜悦的。”"},
{volumn:        61,chapter:         1,versenumber:        18,verse:"我们同他在圣山的时候，亲自听见这声音从天上出来。"},
{volumn:        61,chapter:         1,versenumber:        19,verse:"我们并有先知更确的预言，如同灯照在暗处。你们在这预言上留意，直等到天发亮，晨星在你们心里出现的时候，才是好的。"},
{volumn:        61,chapter:         1,versenumber:        20,verse:"第一要紧的，该知道经上所有的预言没有可随私意解说的；"},
{volumn:        61,chapter:         1,versenumber:        21,verse:"因为预言从来没有出于人意的，乃是人被圣灵感动，说出　神的话来。"},
{volumn:        61,chapter:         2,versenumber:         1,verse:"从前在百姓中有假先知起来，将来在你们中间也必有假师傅，私自引进陷害人的异端，连买他们的主他们也不承认，自取速速地灭亡。"},
{volumn:        61,chapter:         2,versenumber:         2,verse:"将有许多人随从他们邪淫的行为，便叫真道因他们的缘故被毁谤。"},
{volumn:        61,chapter:         2,versenumber:         3,verse:"他们因有贪心，要用捏造的言语在你们身上取利。他们的刑罚，自古以来并不迟延；他们的灭亡也必速速来到（原文是也不打盹）。"},
{volumn:        61,chapter:         2,versenumber:         4,verse:"就是天使犯了罪，　神也没有宽容，曾把他们丢在地狱，交在黑暗坑中，等候审判。"},
{volumn:        61,chapter:         2,versenumber:         5,verse:"神也没有宽容上古的世代，曾叫洪水临到那不敬虔的世代，却保护了传义道的挪亚一家八口。"},
{volumn:        61,chapter:         2,versenumber:         6,verse:"又判定所多玛、蛾摩拉，将二城倾覆，焚烧成灰，作为后世不敬虔人的鉴戒；"},
{volumn:        61,chapter:         2,versenumber:         7,verse:"只搭救了那常为恶人淫行忧伤的义人罗得。（"},
{volumn:        61,chapter:         2,versenumber:         8,verse:"因为那义人住在他们中间，看见听见他们不法的事，他的义心就天天伤痛。）"},
{volumn:        61,chapter:         2,versenumber:         9,verse:"主知道搭救敬虔的人脱离试探，把不义的人留在刑罚之下，等候审判的日子。"},
{volumn:        61,chapter:         2,versenumber:        10,verse:"那些随肉身、纵污秽的情欲、轻慢主治之人的，更是如此。他们胆大任性，毁谤在尊位的，也不知惧怕。"},
{volumn:        61,chapter:         2,versenumber:        11,verse:"就是天使，虽然力量权能更大，还不用毁谤的话在主面前告他们。"},
{volumn:        61,chapter:         2,versenumber:        12,verse:"但这些人好像没有灵性，生来就是畜类，以备捉拿宰杀的。他们毁谤所不晓得的事，正在败坏人的时候，自己必遭遇败坏。"},
{volumn:        61,chapter:         2,versenumber:        13,verse:"行的不义，就得了不义的工价。这些人喜爱白昼宴乐，他们已被玷污，又有瑕疵，正与你们一同坐席，就以自己的诡诈为快乐。"},
{volumn:        61,chapter:         2,versenumber:        14,verse:"他们满眼是淫色（原文是淫妇），止不住犯罪，引诱那心不坚固的人，心中习惯了贪婪，正是被咒诅的种类。"},
{volumn:        61,chapter:         2,versenumber:        15,verse:"他们离弃正路，就走差了，随从比珥之子巴兰的路。巴兰就是那贪爱不义之工价的先知，"},
{volumn:        61,chapter:         2,versenumber:        16,verse:"他却为自己的过犯受了责备；那不能说话的驴以人言拦阻先知的狂妄。"},
{volumn:        61,chapter:         2,versenumber:        17,verse:"这些人是无水的井，是狂风催逼的雾气，有墨黑的幽暗为他们存留。"},
{volumn:        61,chapter:         2,versenumber:        18,verse:"他们说虚妄矜夸的大话，用肉身的情欲和邪淫的事引诱那些刚才脱离妄行的人。"},
{volumn:        61,chapter:         2,versenumber:        19,verse:"他们应许人得以自由，自己却作败坏的奴仆，因为人被谁制伏就是谁的奴仆。"},
{volumn:        61,chapter:         2,versenumber:        20,verse:"倘若他们因认识主救主耶稣基督，得以脱离世上的污秽，后来又在其中被缠住、制伏，他们末后的景况就比先前更不好了。"},
{volumn:        61,chapter:         2,versenumber:        21,verse:"他们晓得义路，竟背弃了传给他们的圣命，倒不如不晓得为妙。"},
{volumn:        61,chapter:         2,versenumber:        22,verse:"俗语说得真不错：狗所吐的，它转过来又吃；猪洗净了又回到泥里去滚；这话在他们身上正合适。"},
{volumn:        61,chapter:         3,versenumber:         1,verse:"亲爱的弟兄啊，我现在写给你们的是第二封信。这两封都是提醒你们，激发你们诚实的心，"},
{volumn:        61,chapter:         3,versenumber:         2,verse:"叫你们记念圣先知预先所说的话和主救主的命令，就是使徒所传给你们的。"},
{volumn:        61,chapter:         3,versenumber:         3,verse:"第一要紧的，该知道在末世必有好讥诮的人随从自己的私欲出来讥诮说："},
{volumn:        61,chapter:         3,versenumber:         4,verse:"“主要降临的应许在哪里呢？因为从列祖睡了以来，万物与起初创造的时候仍是一样。”"},
{volumn:        61,chapter:         3,versenumber:         5,verse:"他们故意忘记，从太古，凭　神的命有了天，并从水而出、藉水而成的地。"},
{volumn:        61,chapter:         3,versenumber:         6,verse:"故此，当时的世界被水淹没就消灭了。"},
{volumn:        61,chapter:         3,versenumber:         7,verse:"但现在的天地还是凭着那命存留，直留到不敬虔之人受审判遭沉沦的日子，用火焚烧。"},
{volumn:        61,chapter:         3,versenumber:         8,verse:"亲爱的弟兄啊，有一件事你们不可忘记，就是主看一日如千年，千年如一日。"},
{volumn:        61,chapter:         3,versenumber:         9,verse:"主所应许的尚未成就，有人以为他是耽延，其实不是耽延，乃是宽容你们，不愿有一人沉沦，乃愿人人都悔改。"},
{volumn:        61,chapter:         3,versenumber:        10,verse:"但主的日子要像贼来到一样。那日，天必大有响声废去，有形质的都要被烈火销化，地和其上的物都要烧尽了。"},
{volumn:        61,chapter:         3,versenumber:        11,verse:"这一切既然都要如此销化，你们为人该当怎样圣洁，怎样敬虔，"},
{volumn:        61,chapter:         3,versenumber:        12,verse:"切切仰望　神的日子来到。在那日，天被火烧就销化了，有形质的都要被烈火熔化。"},
{volumn:        61,chapter:         3,versenumber:        13,verse:"但我们照他的应许，盼望新天新地，有义居在其中。"},
{volumn:        61,chapter:         3,versenumber:        14,verse:"亲爱的弟兄啊，你们既盼望这些事，就当殷勤，使自己没有玷污，无可指摘，安然见主；"},
{volumn:        61,chapter:         3,versenumber:        15,verse:"并且要以我主长久忍耐为得救的因由，就如我们所亲爱的兄弟保罗，照着所赐给他的智慧写了信给你们。"},
{volumn:        61,chapter:         3,versenumber:        16,verse:"他一切的信上也都是讲论这事。信中有些难明白的，那无学问、不坚固的人强解，如强解别的经书一样，就自取沉沦。"},
{volumn:        61,chapter:         3,versenumber:        17,verse:"亲爱的弟兄啊，你们既然预先知道这事，就当防备，恐怕被恶人的错谬诱惑，就从自己坚固的地步上坠落。"},
{volumn:        61,chapter:         3,versenumber:        18,verse:"你们却要在我们主救主耶稣基督的恩典和知识上有长进。愿荣耀归给他，从今直到永远。阿们！"},
{volumn:        62,chapter:         1,versenumber:         1,verse:"论到从起初原有的生命之道，就是我们所听见、所看见、亲眼看过、亲手摸过的。（"},
{volumn:        62,chapter:         1,versenumber:         2,verse:"这生命已经显现出来，我们也看见过，现在又作见证，将原与父同在、且显现与我们那永远的生命传给你们。）"},
{volumn:        62,chapter:         1,versenumber:         3,verse:"我们将所看见、所听见的传给你们，使你们与我们相交。我们乃是与父并他儿子耶稣基督相交的。"},
{volumn:        62,chapter:         1,versenumber:         4,verse:"我们将这些话写给你们，使你们（有古卷：我们）的喜乐充足。"},
{volumn:        62,chapter:         1,versenumber:         5,verse:"神就是光，在他毫无黑暗。这是我们从主所听见、又报给你们的信息。"},
{volumn:        62,chapter:         1,versenumber:         6,verse:"我们若说是与　神相交，却仍在黑暗里行，就是说谎话，不行真理了。"},
{volumn:        62,chapter:         1,versenumber:         7,verse:"我们若在光明中行，如同　神在光明中，就彼此相交，他儿子耶稣的血也洗净我们一切的罪。"},
{volumn:        62,chapter:         1,versenumber:         8,verse:"我们若说自己无罪，便是自欺，真理不在我们心里了。"},
{volumn:        62,chapter:         1,versenumber:         9,verse:"我们若认自己的罪，　神是信实的，是公义的，必要赦免我们的罪，洗净我们一切的不义。"},
{volumn:        62,chapter:         1,versenumber:        10,verse:"我们若说自己没有犯过罪，便是以　神为说谎的，他的道也不在我们心里了。"},
{volumn:        62,chapter:         2,versenumber:         1,verse:"我小子们哪，我将这些话写给你们，是要叫你们不犯罪。若有人犯罪，在父那里我们有一位中保，就是那义者耶稣基督。"},
{volumn:        62,chapter:         2,versenumber:         2,verse:"他为我们的罪作了挽回祭，不是单为我们的罪，也是为普天下人的罪。"},
{volumn:        62,chapter:         2,versenumber:         3,verse:"我们若遵守他的诫命，就晓得是认识他。"},
{volumn:        62,chapter:         2,versenumber:         4,verse:"人若说“我认识他”，却不遵守他的诫命，便是说谎话的，真理也不在他心里了。"},
{volumn:        62,chapter:         2,versenumber:         5,verse:"凡遵守主道的，爱　神的心在他里面实在是完全的。从此，我们知道我们是在主里面。"},
{volumn:        62,chapter:         2,versenumber:         6,verse:"人若说他住在主里面，就该自己照主所行的去行。"},
{volumn:        62,chapter:         2,versenumber:         7,verse:"亲爱的弟兄啊，我写给你们的，不是一条新命令，乃是你们从起初所受的旧命令；这旧命令就是你们所听见的道。"},
{volumn:        62,chapter:         2,versenumber:         8,verse:"再者，我写给你们的，是一条新命令，在主是真的，在你们也是真的；因为黑暗渐渐过去，真光已经照耀。"},
{volumn:        62,chapter:         2,versenumber:         9,verse:"人若说自己在光明中，却恨他的弟兄，他到如今还是在黑暗里。"},
{volumn:        62,chapter:         2,versenumber:        10,verse:"爱弟兄的，就是住在光明中，在他并没有绊跌的缘由。"},
{volumn:        62,chapter:         2,versenumber:        11,verse:"惟独恨弟兄的，是在黑暗里，且在黑暗里行，也不知道往哪里去，因为黑暗叫他眼睛瞎了。"},
{volumn:        62,chapter:         2,versenumber:        12,verse:"小子们哪，我写信给你们，因为你们的罪藉着主名得了赦免。"},
{volumn:        62,chapter:         2,versenumber:        13,verse:"父老啊，我写信给你们，因为你们认识那从起初原有的。少年人哪，我写信给你们，因为你们胜了那恶者。小子们哪，我曾写信给你们，因为你们认识父。"},
{volumn:        62,chapter:         2,versenumber:        14,verse:"父老啊，我曾写信给你们，因为你们认识那从起初原有的。少年人哪，我曾写信给你们；因为你们刚强，　神的道常存在你们心里；你们也胜了那恶者。"},
{volumn:        62,chapter:         2,versenumber:        15,verse:"不要爱世界和世界上的事。人若爱世界，爱父的心就不在他里面了。"},
{volumn:        62,chapter:         2,versenumber:        16,verse:"因为，凡世界上的事，就像肉体的情欲、眼目的情欲，并今生的骄傲，都不是从父来的，乃是从世界来的。"},
{volumn:        62,chapter:         2,versenumber:        17,verse:"这世界和其上的情欲都要过去，惟独遵行　神旨意的，是永远常存。"},
{volumn:        62,chapter:         2,versenumber:        18,verse:"小子们哪，如今是末时了。你们曾听见说，那敌基督的要来；现在已经有好些敌基督的出来了，从此我们就知道如今是末时了。"},
{volumn:        62,chapter:         2,versenumber:        19,verse:"他们从我们中间出去，却不是属我们的；若是属我们的，就必仍旧与我们同在；他们出去，显明都不是属我们的。"},
{volumn:        62,chapter:         2,versenumber:        20,verse:"你们从那圣者受了恩膏，并且知道这一切的事（或译：都有知识）。"},
{volumn:        62,chapter:         2,versenumber:        21,verse:"我写信给你们，不是因你们不知道真理，正是因你们知道，并且知道没有虚谎是从真理出来的。"},
{volumn:        62,chapter:         2,versenumber:        22,verse:"谁是说谎话的呢？不是那不认耶稣为基督的吗？不认父与子的，这就是敌基督的。"},
{volumn:        62,chapter:         2,versenumber:        23,verse:"凡不认子的，就没有父；认子的，连父也有了。"},
{volumn:        62,chapter:         2,versenumber:        24,verse:"论到你们，务要将那从起初所听见的，常存在心里。若将从起初所听见的存在心里，你们就必住在子里面，也必住在父里面。"},
{volumn:        62,chapter:         2,versenumber:        25,verse:"主所应许我们的就是永生。"},
{volumn:        62,chapter:         2,versenumber:        26,verse:"我将这些话写给你们，是指着那引诱你们的人说的。"},
{volumn:        62,chapter:         2,versenumber:        27,verse:"你们从主所受的恩膏常存在你们心里，并不用人教训你们，自有主的恩膏在凡事上教训你们。这恩膏是真的，不是假的；你们要按这恩膏的教训住在主里面。"},
{volumn:        62,chapter:         2,versenumber:        28,verse:"小子们哪，你们要住在主里面。这样，他若显现，我们就可以坦然无惧；当他来的时候，在他面前也不至于惭愧。"},
{volumn:        62,chapter:         2,versenumber:        29,verse:"你们若知道他是公义的，就知道凡行公义之人都是他所生的。"},
{volumn:        62,chapter:         3,versenumber:         1,verse:"你看父赐给我们是何等的慈爱，使我们得称为　神的儿女；我们也真是他的儿女。世人所以不认识我们，是因未曾认识他。"},
{volumn:        62,chapter:         3,versenumber:         2,verse:"亲爱的弟兄啊，我们现在是　神的儿女，将来如何，还未显明；但我们知道，主若显现，我们必要像他，因为必得见他的真体。"},
{volumn:        62,chapter:         3,versenumber:         3,verse:"凡向他有这指望的，就洁净自己，像他洁净一样。"},
{volumn:        62,chapter:         3,versenumber:         4,verse:"凡犯罪的，就是违背律法；违背律法就是罪。"},
{volumn:        62,chapter:         3,versenumber:         5,verse:"你们知道主曾显现，是要除掉人的罪，在他并没有罪。"},
{volumn:        62,chapter:         3,versenumber:         6,verse:"凡住在他里面的，就不犯罪；凡犯罪的，是未曾看见他，也未曾认识他。"},
{volumn:        62,chapter:         3,versenumber:         7,verse:"小子们哪，不要被人诱惑。行义的才是义人，正如主是义的一样。"},
{volumn:        62,chapter:         3,versenumber:         8,verse:"犯罪的是属魔鬼，因为魔鬼从起初就犯罪。　神的儿子显现出来，为要除灭魔鬼的作为。"},
{volumn:        62,chapter:         3,versenumber:         9,verse:"凡从　神生的，就不犯罪，因　神的道（原文是种）存在他心里；他也不能犯罪，因为他是由　神生的。"},
{volumn:        62,chapter:         3,versenumber:        10,verse:"从此就显出谁是　神的儿女，谁是魔鬼的儿女。凡不行义的就不属　神，不爱弟兄的也是如此。"},
{volumn:        62,chapter:         3,versenumber:        11,verse:"我们应当彼此相爱。这就是你们从起初所听见的命令。"},
{volumn:        62,chapter:         3,versenumber:        12,verse:"不可像该隐；他是属那恶者，杀了他的兄弟。为什么杀了他呢？因自己的行为是恶的，兄弟的行为是善的。"},
{volumn:        62,chapter:         3,versenumber:        13,verse:"弟兄们，世人若恨你们，不要以为希奇。"},
{volumn:        62,chapter:         3,versenumber:        14,verse:"我们因为爱弟兄，就晓得是已经出死入生了。没有爱心的，仍住在死中。"},
{volumn:        62,chapter:         3,versenumber:        15,verse:"凡恨他弟兄的，就是杀人的；你们晓得凡杀人的，没有永生存在他里面。"},
{volumn:        62,chapter:         3,versenumber:        16,verse:"主为我们舍命，我们从此就知道何为爱；我们也当为弟兄舍命。"},
{volumn:        62,chapter:         3,versenumber:        17,verse:"凡有世上财物的，看见弟兄穷乏，却塞住怜恤的心，爱　神的心怎能存在他里面呢？"},
{volumn:        62,chapter:         3,versenumber:        18,verse:"小子们哪，我们相爱，不要只在言语和舌头上，总要在行为和诚实上。"},
{volumn:        62,chapter:         3,versenumber:        19,verse:"从此就知道我们是属真理的，并且我们的心在　神面前可以安稳。"},
{volumn:        62,chapter:         3,versenumber:        20,verse:"我们的心若责备我们，　神比我们的心大，一切事没有不知道的。"},
{volumn:        62,chapter:         3,versenumber:        21,verse:"亲爱的弟兄啊，我们的心若不责备我们，就可以向　神坦然无惧了。"},
{volumn:        62,chapter:         3,versenumber:        22,verse:"并且我们一切所求的，就从他得着；因为我们遵守他的命令，行他所喜悦的事。"},
{volumn:        62,chapter:         3,versenumber:        23,verse:"神的命令就是叫我们信他儿子耶稣基督的名，且照他所赐给我们的命令彼此相爱。"},
{volumn:        62,chapter:         3,versenumber:        24,verse:"遵守　神命令的，就住在　神里面，神也住在他里面。我们所以知道　神住在我们里面是因他所赐给我们的圣灵。"},
{volumn:        62,chapter:         4,versenumber:         1,verse:"亲爱的弟兄啊，一切的灵，你们不可都信，总要试验那些灵是出于　神的不是，因为世上有许多假先知已经出来了。"},
{volumn:        62,chapter:         4,versenumber:         2,verse:"凡灵认耶稣基督是成了肉身来的，就是出于　神的；从此你们可以认出　神的灵来。"},
{volumn:        62,chapter:         4,versenumber:         3,verse:"凡灵不认耶稣，就不是出于　神，这是那敌基督者的灵。你们从前听见他要来，现在已经在世上了。"},
{volumn:        62,chapter:         4,versenumber:         4,verse:"小子们哪，你们是属　神的，并且胜了他们；因为那在你们里面的，比那在世界上的更大。"},
{volumn:        62,chapter:         4,versenumber:         5,verse:"他们是属世界的，所以论世界的事，世人也听从他们。"},
{volumn:        62,chapter:         4,versenumber:         6,verse:"我们是属　神的，认识　神的就听从我们；不属　神的就不听从我们。从此我们可以认出真理的灵和谬妄的灵来。"},
{volumn:        62,chapter:         4,versenumber:         7,verse:"亲爱的弟兄啊，我们应当彼此相爱，因为爱是从　神来的。凡有爱心的，都是由　神而生，并且认识　神。"},
{volumn:        62,chapter:         4,versenumber:         8,verse:"没有爱心的，就不认识　神，因为　神就是爱。"},
{volumn:        62,chapter:         4,versenumber:         9,verse:"神差他独生子到世间来，使我们藉着他得生，　神爱我们的心在此就显明了。"},
{volumn:        62,chapter:         4,versenumber:        10,verse:"不是我们爱　神，乃是　神爱我们，差他的儿子为我们的罪作了挽回祭，这就是爱了。"},
{volumn:        62,chapter:         4,versenumber:        11,verse:"亲爱的弟兄啊，　神既是这样爱我们，我们也当彼此相爱。"},
{volumn:        62,chapter:         4,versenumber:        12,verse:"从来没有人见过　神，我们若彼此相爱，　神就住在我们里面，爱他的心在我们里面得以完全了。"},
{volumn:        62,chapter:         4,versenumber:        13,verse:"神将他的灵赐给我们，从此就知道我们是住在他里面，他也住在我们里面。"},
{volumn:        62,chapter:         4,versenumber:        14,verse:"父差子作世人的救主；这是我们所看见且作见证的。"},
{volumn:        62,chapter:         4,versenumber:        15,verse:"凡认耶稣为　神儿子的，　神就住在他里面，他也住在　神里面。"},
{volumn:        62,chapter:         4,versenumber:        16,verse:"神爱我们的心，我们也知道也信。　神就是爱；住在爱里面的，就是住在　神里面，　神也住在他里面。"},
{volumn:        62,chapter:         4,versenumber:        17,verse:"这样，爱在我们里面得以完全，我们就可以在审判的日子坦然无惧。因为他如何，我们在这世上也如何。"},
{volumn:        62,chapter:         4,versenumber:        18,verse:"爱里没有惧怕；爱既完全，就把惧怕除去。因为惧怕里含着刑罚，惧怕的人在爱里未得完全。"},
{volumn:        62,chapter:         4,versenumber:        19,verse:"我们爱，因为　神先爱我们。"},
{volumn:        62,chapter:         4,versenumber:        20,verse:"人若说“我爱神”，却恨他的弟兄，就是说谎话的；不爱他所看见的弟兄，就不能爱没有看见的　神（有古卷：怎能爱没有看见的　神呢）。"},
{volumn:        62,chapter:         4,versenumber:        21,verse:"爱　神的，也当爱弟兄，这是我们从　神所受的命令。"},
{volumn:        62,chapter:         5,versenumber:         1,verse:"凡信耶稣是基督的，都是从　神而生，凡爱生他之　神的，也必爱从　神生的。"},
{volumn:        62,chapter:         5,versenumber:         2,verse:"我们若爱　神，又遵守他的诫命，从此就知道我们爱　神的儿女。"},
{volumn:        62,chapter:         5,versenumber:         3,verse:"我们遵守　神的诫命，这就是爱他了，并且他的诫命不是难守的。"},
{volumn:        62,chapter:         5,versenumber:         4,verse:"因为凡从　神生的，就胜过世界；使我们胜了世界的，就是我们的信心。"},
{volumn:        62,chapter:         5,versenumber:         5,verse:"胜过世界的是谁呢？不是那信耶稣是　神儿子的吗？"},
{volumn:        62,chapter:         5,versenumber:         6,verse:"这藉着水和血而来的，就是耶稣基督；不是单用水，乃是用水又用血，"},
{volumn:        62,chapter:         5,versenumber:         7,verse:"并且有圣灵作见证，因为圣灵就是真理。"},
{volumn:        62,chapter:         5,versenumber:         8,verse:"作见证的原来有三：就是圣灵、水，与血，这三样也都归于一。"},
{volumn:        62,chapter:         5,versenumber:         9,verse:"我们既领受人的见证，　神的见证更该领受（该领受：原文是大）了，因　神的见证是为他儿子作的。"},
{volumn:        62,chapter:         5,versenumber:        10,verse:"信　神儿子的，就有这见证在他心里；不信　神的，就是将　神当作说谎的，因不信　神为他儿子作的见证。"},
{volumn:        62,chapter:         5,versenumber:        11,verse:"这见证就是　神赐给我们永生；这永生也是在他儿子里面。"},
{volumn:        62,chapter:         5,versenumber:        12,verse:"人有了　神的儿子就有生命，没有　神的儿子就没有生命。"},
{volumn:        62,chapter:         5,versenumber:        13,verse:"我将这些话写给你们信奉　神儿子之名的人，要叫你们知道自己有永生。"},
{volumn:        62,chapter:         5,versenumber:        14,verse:"我们若照他的旨意求什么，他就听我们，这是我们向他所存坦然无惧的心。"},
{volumn:        62,chapter:         5,versenumber:        15,verse:"既然知道他听我们一切所求的，就知道我们所求于他的，无不得着。"},
{volumn:        62,chapter:         5,versenumber:        16,verse:"人若看见弟兄犯了不至于死的罪，就当为他祈求，　神必将生命赐给他；有至于死的罪，我不说当为这罪祈求。"},
{volumn:        62,chapter:         5,versenumber:        17,verse:"凡不义的事都是罪，也有不至于死的罪。"},
{volumn:        62,chapter:         5,versenumber:        18,verse:"我们知道凡从　神生的，必不犯罪，从　神生的，必保守自己（有古卷：那从　神生的必保护他），那恶者也就无法害他。"},
{volumn:        62,chapter:         5,versenumber:        19,verse:"我们知道，我们是属　神的，全世界都卧在那恶者手下。"},
{volumn:        62,chapter:         5,versenumber:        20,verse:"我们也知道，　神的儿子已经来到，且将智慧赐给我们，使我们认识那位真实的，我们也在那位真实的里面，就是在他儿子耶稣基督里面。这是真神，也是永生。"},
{volumn:        62,chapter:         5,versenumber:        21,verse:"小子们哪，你们要自守，远避偶像！"},
{volumn:        63,chapter:         1,versenumber:         1,verse:"作长老的写信给蒙拣选的太太（或译：教会；下同）和她的儿女，就是我诚心所爱的；不但我爱，也是一切知道真理之人所爱的。"},
{volumn:        63,chapter:         1,versenumber:         2,verse:"爱你们是为真理的缘故，这真理存在我们里面，也必永远与我们同在。"},
{volumn:        63,chapter:         1,versenumber:         3,verse:"恩惠、怜悯、平安从父　神和他儿子耶稣基督在真理和爱心上必常与我们同在！"},
{volumn:        63,chapter:         1,versenumber:         4,verse:"我见你的儿女，有照我们从父所受之命令遵行真理的，就甚欢喜。"},
{volumn:        63,chapter:         1,versenumber:         5,verse:"太太啊，我现在劝你，我们大家要彼此相爱。这并不是我写一条新命令给你，乃是我们从起初所受的命令。"},
{volumn:        63,chapter:         1,versenumber:         6,verse:"我们若照他的命令行，这就是爱。你们从起初所听见当行的，就是这命令。"},
{volumn:        63,chapter:         1,versenumber:         7,verse:"因为世上有许多迷惑人的出来，他们不认耶稣基督是成了肉身来的；这就是那迷惑人、敌基督的。"},
{volumn:        63,chapter:         1,versenumber:         8,verse:"你们要小心，不要失去你们（有古卷：我们）所做的工，乃要得着满足的赏赐。"},
{volumn:        63,chapter:         1,versenumber:         9,verse:"凡越过基督的教训不常守着的，就没有　神；常守这教训的，就有父又有子。"},
{volumn:        63,chapter:         1,versenumber:        10,verse:"若有人到你们那里，不是传这教训，不要接他到家里，也不要问他的安；"},
{volumn:        63,chapter:         1,versenumber:        11,verse:"因为问他安的，就在他的恶行上有份。"},
{volumn:        63,chapter:         1,versenumber:        12,verse:"我还有许多事要写给你们，却不愿意用纸墨写出来，但盼望到你们那里，与你们当面谈论，使你们的喜乐满足。"},
{volumn:        63,chapter:         1,versenumber:        13,verse:"你那蒙拣选之姐妹的儿女都问你安。"},
{volumn:        64,chapter:         1,versenumber:         1,verse:"作长老的写信给亲爱的该犹，就是我诚心所爱的。"},
{volumn:        64,chapter:         1,versenumber:         2,verse:"亲爱的兄弟啊，我愿你凡事兴盛，身体健壮，正如你的灵魂兴盛一样。"},
{volumn:        64,chapter:         1,versenumber:         3,verse:"有弟兄来证明你心里存的真理，正如你按真理而行，我就甚喜乐。"},
{volumn:        64,chapter:         1,versenumber:         4,verse:"我听见我的儿女们按真理而行，我的喜乐就没有比这个大的。"},
{volumn:        64,chapter:         1,versenumber:         5,verse:"亲爱的兄弟啊，凡你向作客旅之弟兄所行的都是忠心的。"},
{volumn:        64,chapter:         1,versenumber:         6,verse:"他们在教会面前证明了你的爱；你若配得过　神，帮助他们往前行，这就好了。"},
{volumn:        64,chapter:         1,versenumber:         7,verse:"因他们是为主的名（原文是那名）出外，对于外邦人一无所取。"},
{volumn:        64,chapter:         1,versenumber:         8,verse:"所以我们应该接待这样的人，叫我们与他们一同为真理做工。"},
{volumn:        64,chapter:         1,versenumber:         9,verse:"我曾略略地写信给教会，但那在教会中好为首的丢特腓不接待我们。"},
{volumn:        64,chapter:         1,versenumber:        10,verse:"所以我若去，必要提说他所行的事，就是他用恶言妄论我们。还不以此为足，他自己不接待弟兄，有人愿意接待，他也禁止，并且将接待弟兄的人赶出教会。"},
{volumn:        64,chapter:         1,versenumber:        11,verse:"亲爱的兄弟啊，不要效法恶，只要效法善。行善的属乎　神；行恶的未曾见过　神。"},
{volumn:        64,chapter:         1,versenumber:        12,verse:"低米丢行善，有众人给他作见证，又有真理给他作见证，就是我们也给他作见证。你也知道我们的见证是真的。"},
{volumn:        64,chapter:         1,versenumber:        13,verse:"我原有许多事要写给你，却不愿意用笔墨写给你，"},
{volumn:        64,chapter:         1,versenumber:        14,verse:"但盼望快快地见你，我们就当面谈论。"},
{volumn:        64,chapter:         1,versenumber:        15,verse:"愿你平安。众位朋友都问你安。请你替我按着姓名问众位朋友安。"},
{volumn:        65,chapter:         1,versenumber:         1,verse:"耶稣基督的仆人，雅各的弟兄犹大，写信给那被召、在父　神里蒙爱、为耶稣基督保守的人。"},
{volumn:        65,chapter:         1,versenumber:         2,verse:"愿怜恤、平安、慈爱多多地加给你们。"},
{volumn:        65,chapter:         1,versenumber:         3,verse:"亲爱的弟兄啊，我想尽心写信给你们，论我们同得救恩的时候，就不得不写信劝你们，要为从前一次交付圣徒的真道竭力地争辩。"},
{volumn:        65,chapter:         1,versenumber:         4,verse:"因为有些人偷着进来，就是自古被定受刑罚的，是不虔诚的，将我们　神的恩变作放纵情欲的机会，并且不认独一的主宰、我们（或译和我们）主耶稣基督。"},
{volumn:        65,chapter:         1,versenumber:         5,verse:"从前主救了他的百姓出埃及地，后来就把那些不信的灭绝了。这一切的事，你们虽然都知道，我却仍要提醒你们。"},
{volumn:        65,chapter:         1,versenumber:         6,verse:"又有不守本位、离开自己住处的天使，主用锁链把他们永远拘留在黑暗里，等候大日的审判。"},
{volumn:        65,chapter:         1,versenumber:         7,verse:"又如所多玛、蛾摩拉和周围城邑的人，也照他们一味地行淫，随从逆性的情欲，就受永火的刑罚，作为鉴戒。"},
{volumn:        65,chapter:         1,versenumber:         8,verse:"这些作梦的人也像他们污秽身体，轻慢主治的，毁谤在尊位的。"},
{volumn:        65,chapter:         1,versenumber:         9,verse:"天使长米迦勒为摩西的尸首与魔鬼争辩的时候，尚且不敢用毁谤的话罪责他，只说：“主责备你吧！”。"},
{volumn:        65,chapter:         1,versenumber:        10,verse:"但这些人毁谤他们所不知道的。他们本性所知道的事与那没有灵性的畜类一样，在这事上竟败坏了自己。"},
{volumn:        65,chapter:         1,versenumber:        11,verse:"他们有祸了！因为走了该隐的道路，又为利往巴兰的错谬里直奔，并在可拉的背叛中灭亡了。"},
{volumn:        65,chapter:         1,versenumber:        12,verse:"这样的人在你们的爱席上与你们同吃的时候，正是礁石（或译：玷污）。他们作牧人，只知喂养自己，无所惧怕；是没有雨的云彩，被风飘荡；是秋天没有果子的树，死而又死，连根被拔出来；"},
{volumn:        65,chapter:         1,versenumber:        13,verse:"是海里的狂浪，涌出自己可耻的沫子来；是流荡的星，有墨黑的幽暗为他们永远存留。"},
{volumn:        65,chapter:         1,versenumber:        14,verse:"亚当的七世孙以诺曾预言这些人说：“看哪，主带着他的千万圣者降临，"},
{volumn:        65,chapter:         1,versenumber:        15,verse:"要在众人身上行审判，证实那一切不敬虔的人所妄行一切不敬虔的事，又证实不敬虔之罪人所说顶撞他的刚愎话。”"},
{volumn:        65,chapter:         1,versenumber:        16,verse:"这些人是私下议论，常发怨言的，随从自己的情欲而行，口中说夸大的话，为得便宜谄媚人。"},
{volumn:        65,chapter:         1,versenumber:        17,verse:"亲爱的弟兄啊，你们要记念我们主耶稣基督之使徒从前所说的话。"},
{volumn:        65,chapter:         1,versenumber:        18,verse:"他们曾对你们说过，末世必有好讥诮的人随从自己不敬虔的私欲而行。"},
{volumn:        65,chapter:         1,versenumber:        19,verse:"这就是那些引人结党、属乎血气、没有圣灵的人。"},
{volumn:        65,chapter:         1,versenumber:        20,verse:"亲爱的弟兄啊，你们却要在至圣的真道上造就自己，在圣灵里祷告，"},
{volumn:        65,chapter:         1,versenumber:        21,verse:"保守自己常在　神的爱中，仰望我们主耶稣基督的怜悯，直到永生。"},
{volumn:        65,chapter:         1,versenumber:        22,verse:"有些人存疑心，你们要怜悯他们；"},
{volumn:        65,chapter:         1,versenumber:        23,verse:"有些人你们要从火中抢出来，搭救他们；有些人你们要存惧怕的心怜悯他们，连那被情欲沾染的衣服也当厌恶。"},
{volumn:        65,chapter:         1,versenumber:        24,verse:"那能保守你们不失脚、叫你们无瑕无疵、欢欢喜喜站在他荣耀之前的我们的救主独一的　神，"},
{volumn:        65,chapter:         1,versenumber:        25,verse:"愿荣耀、威严、能力、权柄，因我们的主耶稣基督归与他，从万古以前并现今，直到永永远远。阿们！"},
{volumn:        66,chapter:         1,versenumber:         1,verse:"耶稣基督的启示，就是　神赐给他，叫他将必要快成的事指示他的众仆人。他就差遣使者晓谕他的仆人约翰。"},
{volumn:        66,chapter:         1,versenumber:         2,verse:"约翰便将　神的道和耶稣基督的见证，凡自己所看见的都证明出来。"},
{volumn:        66,chapter:         1,versenumber:         3,verse:"念这书上预言的和那些听见又遵守其中所记载的，都是有福的，因为日期近了。"},
{volumn:        66,chapter:         1,versenumber:         4,verse:"约翰写信给亚细亚的七个教会。但愿从那昔在、今在、以后永在的　神，和他宝座前的七灵，"},
{volumn:        66,chapter:         1,versenumber:         5,verse:"并那诚实作见证的、从死里首先复活、为世上君王元首的耶稣基督，有恩惠、平安归与你们！他爱我们，用自己的血使我们脱离（有古卷：洗去）罪恶，"},
{volumn:        66,chapter:         1,versenumber:         6,verse:"又使我们成为国民，作他父　神的祭司。但愿荣耀、权能归给他，直到永永远远。阿们！"},
{volumn:        66,chapter:         1,versenumber:         7,verse:"看哪，他驾云降临！众目要看见他，连刺他的人也要看见他；地上的万族都要因他哀哭。这话是真实的。阿们！"},
{volumn:        66,chapter:         1,versenumber:         8,verse:"主　神说：“我是阿拉法，我是俄梅戛（阿拉法，俄梅戛：是希腊字母首末二字），是昔在、今在、以后永在的全能者。”"},
{volumn:        66,chapter:         1,versenumber:         9,verse:"我约翰就是你们的弟兄，和你们在耶稣的患难、国度、忍耐里一同有份，为　神的道，并为给耶稣作的见证，曾在那名叫拔摩的海岛上。"},
{volumn:        66,chapter:         1,versenumber:        10,verse:"当主日，我被圣灵感动，听见在我后面有大声音如吹号，说："},
{volumn:        66,chapter:         1,versenumber:        11,verse:"“你所看见的当写在书上，达与以弗所、士每拿、别迦摩、推雅推喇、撒狄、非拉铁非、老底嘉那七个教会。”"},
{volumn:        66,chapter:         1,versenumber:        12,verse:"我转过身来，要看是谁发声与我说话；既转过来，就看见七个金灯台。"},
{volumn:        66,chapter:         1,versenumber:        13,verse:"灯台中间有一位好像人子，身穿长衣，直垂到脚，胸间束着金带。"},
{volumn:        66,chapter:         1,versenumber:        14,verse:"他的头与发皆白，如白羊毛，如雪；眼目如同火焰；"},
{volumn:        66,chapter:         1,versenumber:        15,verse:"脚好像在炉中锻炼光明的铜；声音如同众水的声音。"},
{volumn:        66,chapter:         1,versenumber:        16,verse:"他右手拿着七星，从他口中出来一把两刃的利剑；面貌如同烈日放光。"},
{volumn:        66,chapter:         1,versenumber:        17,verse:"我一看见，就仆倒在他脚前，像死了一样。他用右手按着我，说：“不要惧怕！我是首先的，我是末后的，"},
{volumn:        66,chapter:         1,versenumber:        18,verse:"又是那存活的；我曾死过，现在又活了，直活到永永远远；并且拿着死亡和阴间的钥匙。"},
{volumn:        66,chapter:         1,versenumber:        19,verse:"所以你要把所看见的，和现在的事，并将来必成的事，都写出来。"},
{volumn:        66,chapter:         1,versenumber:        20,verse:"论到你所看见、在我右手中的七星和七个金灯台的奥秘，那七星就是七个教会的使者，七灯台就是七个教会。”"},
{volumn:        66,chapter:         2,versenumber:         1,verse:"“你要写信给以弗所教会的使者说，那右手拿着七星，在七个金灯台中间行走的，说："},
{volumn:        66,chapter:         2,versenumber:         2,verse:"我知道你的行为、劳碌、忍耐，也知道你不能容忍恶人。你也曾试验那自称为使徒却不是使徒的，看出他们是假的来。"},
{volumn:        66,chapter:         2,versenumber:         3,verse:"你也能忍耐，曾为我的名劳苦，并不乏倦。"},
{volumn:        66,chapter:         2,versenumber:         4,verse:"然而有一件事我要责备你，就是你把起初的爱心离弃了。"},
{volumn:        66,chapter:         2,versenumber:         5,verse:"所以，应当回想你是从哪里坠落的，并要悔改，行起初所行的事。你若不悔改，我就临到你那里，把你的灯台从原处挪去。"},
{volumn:        66,chapter:         2,versenumber:         6,verse:"然而你还有一件可取的事，就是你恨恶尼哥拉一党人的行为，这也是我所恨恶的。"},
{volumn:        66,chapter:         2,versenumber:         7,verse:"圣灵向众教会所说的话，凡有耳的，就应当听！得胜的，我必将　神乐园中生命树的果子赐给他吃。”"},
{volumn:        66,chapter:         2,versenumber:         8,verse:"“你要写信给士每拿教会的使者，说：‘那首先的、末后的、死过又活的，说："},
{volumn:        66,chapter:         2,versenumber:         9,verse:"我知道你的患难，你的贫穷（你却是富足的），也知道那自称是犹太人所说的毁谤话，其实他们不是犹太人，乃是撒但一会的人。"},
{volumn:        66,chapter:         2,versenumber:        10,verse:"你将要受的苦你不用怕。魔鬼要把你们中间几个人下在监里，叫你们被试炼，你们必受患难十日。你务要至死忠心，我就赐给你那生命的冠冕。"},
{volumn:        66,chapter:         2,versenumber:        11,verse:"圣灵向众教会所说的话，凡有耳的，就应当听！得胜的，必不受第二次死的害。”"},
{volumn:        66,chapter:         2,versenumber:        12,verse:"“你要写信给别迦摩教会的使者，说：‘那有两刃利剑的，说："},
{volumn:        66,chapter:         2,versenumber:        13,verse:"我知道你的居所，就是有撒但座位之处；当我忠心的见证人安提帕在你们中间、撒但所住的地方被杀之时，你还坚守我的名，没有弃绝我的道。"},
{volumn:        66,chapter:         2,versenumber:        14,verse:"然而，有几件事我要责备你：因为在你那里有人服从了巴兰的教训；这巴兰曾教导巴勒将绊脚石放在以色列人面前，叫他们吃祭偶像之物，行奸淫的事。"},
{volumn:        66,chapter:         2,versenumber:        15,verse:"你那里也有人照样服从了尼哥拉一党人的教训。"},
{volumn:        66,chapter:         2,versenumber:        16,verse:"所以，你当悔改；若不悔改，我就快临到你那里，用我口中的剑攻击他们。"},
{volumn:        66,chapter:         2,versenumber:        17,verse:"圣灵向众教会所说的话，凡有耳的，就应当听！得胜的，我必将那隐藏的吗哪赐给他，并赐他一块白石，石上写着新名；除了那领受的以外，没有人能认识。”"},
{volumn:        66,chapter:         2,versenumber:        18,verse:"“你要写信给推雅推喇教会的使者，说：‘那眼目如火焰、脚像光明铜的　神之子说："},
{volumn:        66,chapter:         2,versenumber:        19,verse:"我知道你的行为、爱心、信心、勤劳、忍耐，又知道你末后所行的善事，比起初所行的更多。"},
{volumn:        66,chapter:         2,versenumber:        20,verse:"然而，有一件事我要责备你，就是你容让那自称是先知的妇人耶洗别教导我的仆人，引诱他们行奸淫，吃祭偶像之物。"},
{volumn:        66,chapter:         2,versenumber:        21,verse:"我曾给她悔改的机会，她却不肯悔改她的淫行。"},
{volumn:        66,chapter:         2,versenumber:        22,verse:"看哪，我要叫她病卧在床。那些与她行淫的人，若不悔改所行的，我也要叫他们同受大患难。"},
{volumn:        66,chapter:         2,versenumber:        23,verse:"我又要杀死她的党类（原文是儿女），叫众教会知道，我是那察看人肺腑心肠的，并要照你们的行为报应你们各人。"},
{volumn:        66,chapter:         2,versenumber:        24,verse:"至于你们推雅推喇其余的人，就是一切不从那教训、不晓得他们素常所说撒但深奥之理的人，我告诉你们，我不将别的担子放在你们身上。"},
{volumn:        66,chapter:         2,versenumber:        25,verse:"但你们已经有的，总要持守，直等到我来。"},
{volumn:        66,chapter:         2,versenumber:        26,verse:"那得胜又遵守我命令到底的，我要赐给他权柄制伏列国；"},
{volumn:        66,chapter:         2,versenumber:        27,verse:"他必用铁杖辖管（辖管：原文是牧）他们，将他们如同窑户的瓦器打得粉碎，像我从我父领受的权柄一样。"},
{volumn:        66,chapter:         2,versenumber:        28,verse:"我又要把晨星赐给他。"},
{volumn:        66,chapter:         2,versenumber:        29,verse:"圣灵向众教会所说的话，凡有耳的，就应当听！”"},
{volumn:        66,chapter:         3,versenumber:         1,verse:"“你要写信给撒狄教会的使者，说：‘那有　神的七灵和七星的，说：我知道你的行为，按名你是活的，其实是死的。"},
{volumn:        66,chapter:         3,versenumber:         2,verse:"你要警醒，坚固那剩下将要衰微（原文是死）的；因我见你的行为，在我　神面前，没有一样是完全的。"},
{volumn:        66,chapter:         3,versenumber:         3,verse:"所以要回想你是怎样领受、怎样听见的，又要遵守，并要悔改。若不警醒，我必临到你那里，如同贼一样。我几时临到，你也决不能知道。"},
{volumn:        66,chapter:         3,versenumber:         4,verse:"然而在撒狄，你还有几名是未曾污秽自己衣服的，他们要穿白衣与我同行，因为他们是配得过的。"},
{volumn:        66,chapter:         3,versenumber:         5,verse:"凡得胜的必这样穿白衣，我也必不从生命册上涂抹他的名；且要在我父面前，和我父众使者面前，认他的名。"},
{volumn:        66,chapter:         3,versenumber:         6,verse:"圣灵向众教会所说的话，凡有耳的，就应当听。”"},
{volumn:        66,chapter:         3,versenumber:         7,verse:"“你要写信给非拉铁非教会的使者，说：‘那圣洁、真实、拿着大卫的钥匙、开了就没有人能关、关了就没有人能开的，说："},
{volumn:        66,chapter:         3,versenumber:         8,verse:"我知道你的行为，你略有一点力量，也曾遵守我的道，没有弃绝我的名。看哪，我在你面前给你一个敞开的门，是无人能关的。"},
{volumn:        66,chapter:         3,versenumber:         9,verse:"那撒但一会的，自称是犹太人，其实不是犹太人，乃是说谎话的，我要使他们来，在你脚前下拜，也使他们知道我是已经爱你了。"},
{volumn:        66,chapter:         3,versenumber:        10,verse:"你既遵守我忍耐的道，我必在普天下人受试炼的时候，保守你免去你的试炼。"},
{volumn:        66,chapter:         3,versenumber:        11,verse:"我必快来，你要持守你所有的，免得人夺去你的冠冕。"},
{volumn:        66,chapter:         3,versenumber:        12,verse:"得胜的，我要叫他在我　神殿中作柱子，他也必不再从那里出去。我又要将我　神的名和我　神城的名（这城就是从天上、从我　神那里降下来的新耶路撒冷），并我的新名，都写在他上面。"},
{volumn:        66,chapter:         3,versenumber:        13,verse:"圣灵向众教会所说的话，凡有耳的，就应当听！”"},
{volumn:        66,chapter:         3,versenumber:        14,verse:"“你要写信给老底嘉教会的使者，说：‘那为阿们的，为诚信真实见证的，在　神创造万物之上为元首的，说："},
{volumn:        66,chapter:         3,versenumber:        15,verse:"我知道你的行为，你也不冷也不热；我巴不得你或冷或热。"},
{volumn:        66,chapter:         3,versenumber:        16,verse:"你既如温水，也不冷也不热，所以我必从我口中把你吐出去。"},
{volumn:        66,chapter:         3,versenumber:        17,verse:"你说：我是富足，已经发了财，一样都不缺；却不知道你是那困苦、可怜、贫穷、瞎眼、赤身的。"},
{volumn:        66,chapter:         3,versenumber:        18,verse:"我劝你向我买火炼的金子，叫你富足；又买白衣穿上，叫你赤身的羞耻不露出来；又买眼药擦你的眼睛，使你能看见。"},
{volumn:        66,chapter:         3,versenumber:        19,verse:"凡我所疼爱的，我就责备管教他；所以你要发热心，也要悔改。"},
{volumn:        66,chapter:         3,versenumber:        20,verse:"看哪，我站在门外叩门，若有听见我声音就开门的，我要进到他那里去，我与他，他与我一同坐席。"},
{volumn:        66,chapter:         3,versenumber:        21,verse:"得胜的，我要赐他在我宝座上与我同坐，就如我得了胜，在我父的宝座上与他同坐一般。"},
{volumn:        66,chapter:         3,versenumber:        22,verse:"圣灵向众教会所说的话，凡有耳的，就应当听！”"},
{volumn:        66,chapter:         4,versenumber:         1,verse:"此后，我观看，见天上有门开了。我初次听见好像吹号的声音，对我说：“你上到这里来，我要将以后必成的事指示你。”"},
{volumn:        66,chapter:         4,versenumber:         2,verse:"我立刻被圣灵感动，见有一个宝座安置在天上，又有一位坐在宝座上。"},
{volumn:        66,chapter:         4,versenumber:         3,verse:"看那坐着的，好像碧玉和红宝石；又有虹围着宝座，好像绿宝石。"},
{volumn:        66,chapter:         4,versenumber:         4,verse:"宝座的周围又有二十四个座位；其上坐着二十四位长老，身穿白衣，头上戴着金冠冕。"},
{volumn:        66,chapter:         4,versenumber:         5,verse:"有闪电、声音、雷轰从宝座中发出；又有七盏火灯在宝座前点着；这七灯就是　神的七灵。"},
{volumn:        66,chapter:         4,versenumber:         6,verse:"宝座前好像一个玻璃海，如同水晶。宝座中和宝座周围有四个活物，前后遍体都满了眼睛。"},
{volumn:        66,chapter:         4,versenumber:         7,verse:"第一个活物像狮子，第二个像牛犊，第三个脸面像人，第四个像飞鹰。"},
{volumn:        66,chapter:         4,versenumber:         8,verse:"四活物各有六个翅膀，遍体内外都满了眼睛。他们昼夜不住地说：“圣哉！圣哉！圣哉！主　神是昔在、今在、以后永在的全能者！”"},
{volumn:        66,chapter:         4,versenumber:         9,verse:"每逢四活物将荣耀、尊贵、感谢归给那坐在宝座上、活到永永远远者的时候，"},
{volumn:        66,chapter:         4,versenumber:        10,verse:"那二十四位长老就俯伏在坐宝座的面前敬拜那活到永永远远的，又把他们的冠冕放在宝座前，说："},
{volumn:        66,chapter:         4,versenumber:        11,verse:"“我们的主，我们的　神，你是配得荣耀、尊贵、权柄的；因为你创造了万物，并且万物是因你的旨意被创造而有的。”"},
{volumn:        66,chapter:         5,versenumber:         1,verse:"我看见坐宝座的右手中有书卷，里外都写着字，用七印封严了。"},
{volumn:        66,chapter:         5,versenumber:         2,verse:"我又看见一位大力的天使大声宣传说：“有谁配展开那书卷，揭开那七印呢？”"},
{volumn:        66,chapter:         5,versenumber:         3,verse:"在天上、地上、地底下，没有能展开、能观看那书卷的。"},
{volumn:        66,chapter:         5,versenumber:         4,verse:"因为没有配展开、配观看那书卷的，我就大哭。"},
{volumn:        66,chapter:         5,versenumber:         5,verse:"长老中有一位对我说：“不要哭！看哪，犹大支派中的狮子，大卫的根，他已得胜，能以展开那书卷，揭开那七印。”"},
{volumn:        66,chapter:         5,versenumber:         6,verse:"我又看见宝座与四活物，并长老之中有羔羊站立，像是被杀过的，有七角七眼，就是　神的七灵，奉差遣往普天下去的。"},
{volumn:        66,chapter:         5,versenumber:         7,verse:"这羔羊前来，从坐宝座的右手里拿了书卷。"},
{volumn:        66,chapter:         5,versenumber:         8,verse:"他既拿了书卷，四活物和二十四位长老就俯伏在羔羊面前，各拿着琴和盛满了香的金炉；这香就是众圣徒的祈祷。"},
{volumn:        66,chapter:         5,versenumber:         9,verse:"他们唱新歌，说：“你配拿书卷，配揭开七印；因为你曾被杀，用自己的血从各族、各方、各民、各国中买了人来，叫他们归于　神，"},
{volumn:        66,chapter:         5,versenumber:        10,verse:"又叫他们成为国民，作祭司归于　神，在地上执掌王权。”"},
{volumn:        66,chapter:         5,versenumber:        11,verse:"我又看见且听见，宝座与活物并长老的周围有许多天使的声音；他们的数目有千千万万，"},
{volumn:        66,chapter:         5,versenumber:        12,verse:"大声说：“曾被杀的羔羊是配得权柄、丰富、智慧、能力、尊贵、荣耀、颂赞的。”"},
{volumn:        66,chapter:         5,versenumber:        13,verse:"我又听见在天上、地上、地底下、沧海里，和天地间一切所有被造之物，都说：“但愿颂赞、尊贵、荣耀、权势都归给坐宝座的和羔羊，直到永永远远！”"},
{volumn:        66,chapter:         5,versenumber:        14,verse:"四活物就说：“阿们！”众长老也俯伏敬拜。"},
{volumn:        66,chapter:         6,versenumber:         1,verse:"我看见羔羊揭开七印中第一印的时候，就听见四活物中的一个活物，声音如雷，说：“你来！”"},
{volumn:        66,chapter:         6,versenumber:         2,verse:"我就观看，见有一匹白马；骑在马上的，拿着弓，并有冠冕赐给他。他便出来，胜了又要胜。"},
{volumn:        66,chapter:         6,versenumber:         3,verse:"揭开第二印的时候，我听见第二个活物说：“你来！”"},
{volumn:        66,chapter:         6,versenumber:         4,verse:"就另有一匹马出来，是红的，有权柄给了那骑马的，可以从地上夺去太平，使人彼此相杀；又有一把大刀赐给他。"},
{volumn:        66,chapter:         6,versenumber:         5,verse:"揭开第三印的时候，我听见第三个活物说：“你来！”我就观看，见有一匹黑马；骑在马上的，手里拿着天平。"},
{volumn:        66,chapter:         6,versenumber:         6,verse:"我听见在四活物中似乎有声音说：“一钱银子买一升麦子，一钱银子买三升大麦；油和酒不可糟蹋。”"},
{volumn:        66,chapter:         6,versenumber:         7,verse:"揭开第四印的时候，我听见第四个活物说：“你来！”"},
{volumn:        66,chapter:         6,versenumber:         8,verse:"我就观看，见有一匹灰色马；骑在马上的，名字叫作死，阴府也随着他；有权柄赐给他们，可以用刀剑、饥荒、瘟疫（或译：死亡）、野兽，杀害地上四分之一的人。"},
{volumn:        66,chapter:         6,versenumber:         9,verse:"揭开第五印的时候，我看见在祭坛底下，有为　神的道、并为作见证被杀之人的灵魂，"},
{volumn:        66,chapter:         6,versenumber:        10,verse:"大声喊着说：“圣洁真实的主啊，你不审判住在地上的人，给我们伸流血的冤，要等到几时呢？”"},
{volumn:        66,chapter:         6,versenumber:        11,verse:"于是有白衣赐给他们各人；又有话对他们说，还要安息片时，等着一同作仆人的和他们的弟兄也像他们被杀，满足了数目。"},
{volumn:        66,chapter:         6,versenumber:        12,verse:"揭开第六印的时候，我又看见地大震动，日头变黑像毛布，满月变红像血，"},
{volumn:        66,chapter:         6,versenumber:        13,verse:"天上的星辰坠落于地，如同无花果树被大风摇动，落下未熟的果子一样。"},
{volumn:        66,chapter:         6,versenumber:        14,verse:"天就挪移，好像书卷被卷起来；山岭海岛都被挪移离开本位。"},
{volumn:        66,chapter:         6,versenumber:        15,verse:"地上的君王、臣宰、将军、富户、壮士，和一切为奴的、自主的，都藏在山洞和岩石穴里，"},
{volumn:        66,chapter:         6,versenumber:        16,verse:"向山和岩石说：“倒在我们身上吧！把我们藏起来，躲避坐宝座者的面目和羔羊的忿怒；"},
{volumn:        66,chapter:         6,versenumber:        17,verse:"因为他们忿怒的大日到了，谁能站得住呢？”"},
{volumn:        66,chapter:         7,versenumber:         1,verse:"此后，我看见四位天使站在地的四角，执掌地上四方的风，叫风不吹在地上、海上，和树上。"},
{volumn:        66,chapter:         7,versenumber:         2,verse:"我又看见另有一位天使，从日出之地上来，拿着永生　神的印。他就向那得着权柄能伤害地和海的四位天使大声喊着说："},
{volumn:        66,chapter:         7,versenumber:         3,verse:"“地与海并树木，你们不可伤害，等我们印了我们　神众仆人的额。”"},
{volumn:        66,chapter:         7,versenumber:         4,verse:"我听见以色列人各支派中受印的数目有十四万四千。"},
{volumn:        66,chapter:         7,versenumber:         5,verse:"犹大支派中受印的有一万二千；流便支派中有一万二千；迦得支派中有一万二千；"},
{volumn:        66,chapter:         7,versenumber:         6,verse:"亚设支派中有一万二千；拿弗他利支派中有一万二千；玛拿西支派中有一万二千；"},
{volumn:        66,chapter:         7,versenumber:         7,verse:"西缅支派中有一万二千；利未支派中有一万二千；以萨迦支派中有一万二千；"},
{volumn:        66,chapter:         7,versenumber:         8,verse:"西布伦支派中有一万二千；约瑟支派中有一万二千；便雅悯支派中受印的有一万二千。"},
{volumn:        66,chapter:         7,versenumber:         9,verse:"此后，我观看，见有许多的人，没有人能数过来，是从各国、各族、各民、各方来的，站在宝座和羔羊面前，身穿白衣，手拿棕树枝，"},
{volumn:        66,chapter:         7,versenumber:        10,verse:"大声喊着说：“愿救恩归与坐在宝座上我们的　神，也归与羔羊！”"},
{volumn:        66,chapter:         7,versenumber:        11,verse:"众天使都站在宝座和众长老并四活物的周围，在宝座前，面伏于地，敬拜　神，"},
{volumn:        66,chapter:         7,versenumber:        12,verse:"说：“阿们！颂赞、荣耀、智慧、感谢、尊贵、权柄、大力都归与我们的　神，直到永永远远。阿们！”"},
{volumn:        66,chapter:         7,versenumber:        13,verse:"长老中有一位问我说：“这些穿白衣的是谁？是从哪里来的？”"},
{volumn:        66,chapter:         7,versenumber:        14,verse:"我对他说：“我主，你知道。”他向我说：“这些人是从大患难中出来的，曾用羔羊的血把衣裳洗白净了。"},
{volumn:        66,chapter:         7,versenumber:        15,verse:"所以，他们在　神宝座前，昼夜在他殿中侍奉他。坐宝座的要用帐幕覆庇他们。"},
{volumn:        66,chapter:         7,versenumber:        16,verse:"他们不再饥，不再渴；日头和炎热也必不伤害他们。"},
{volumn:        66,chapter:         7,versenumber:        17,verse:"因为宝座中的羔羊必牧养他们，领他们到生命水的泉源；　神也必擦去他们一切的眼泪。”"},
{volumn:        66,chapter:         8,versenumber:         1,verse:"羔羊揭开第七印的时候，天上寂静约有二刻。"},
{volumn:        66,chapter:         8,versenumber:         2,verse:"我看见那站在　神面前的七位天使，有七枝号赐给他们。"},
{volumn:        66,chapter:         8,versenumber:         3,verse:"另有一位天使，拿着金香炉来，站在祭坛旁边。有许多香赐给他，要和众圣徒的祈祷一同献在宝座前的金坛上。"},
{volumn:        66,chapter:         8,versenumber:         4,verse:"那香的烟和众圣徒的祈祷从天使的手中一同升到　神面前。"},
{volumn:        66,chapter:         8,versenumber:         5,verse:"天使拿着香炉，盛满了坛上的火，倒在地上；随有雷轰、大声、闪电、地震。"},
{volumn:        66,chapter:         8,versenumber:         6,verse:"拿着七枝号的七位天使就预备要吹。"},
{volumn:        66,chapter:         8,versenumber:         7,verse:"第一位天使吹号，就有雹子与火搀着血丢在地上；地的三分之一和树的三分之一被烧了，一切的青草也被烧了。"},
{volumn:        66,chapter:         8,versenumber:         8,verse:"第二位天使吹号，就有仿佛火烧着的大山扔在海中；海的三分之一变成血，"},
{volumn:        66,chapter:         8,versenumber:         9,verse:"海中的活物死了三分之一，船只也坏了三分之一。"},
{volumn:        66,chapter:         8,versenumber:        10,verse:"第三位天使吹号，就有烧着的大星，好像火把从天上落下来，落在江河的三分之一和众水的泉源上。"},
{volumn:        66,chapter:         8,versenumber:        11,verse:"（这星名叫“茵陈”。）众水的三分之一变为茵陈，因水变苦，就死了许多人。"},
{volumn:        66,chapter:         8,versenumber:        12,verse:"第四位天使吹号，日头的三分之一、月亮的三分之一、星辰的三分之一都被击打，以致日月星的三分之一黑暗了，白昼的三分之一没有光，黑夜也是这样。"},
{volumn:        66,chapter:         8,versenumber:        13,verse:"我又看见一个鹰飞在空中，并听见它大声说：“三位天使要吹那其余的号。你们住在地上的民，祸哉！祸哉！祸哉！”"},
{volumn:        66,chapter:         9,versenumber:         1,verse:"第五位天使吹号，我就看见一个星从天落到地上，有无底坑的钥匙赐给它。"},
{volumn:        66,chapter:         9,versenumber:         2,verse:"它开了无底坑，便有烟从坑里往上冒，好像大火炉的烟；日头和天空都因这烟昏暗了。"},
{volumn:        66,chapter:         9,versenumber:         3,verse:"有蝗虫从烟中出来，飞到地上；有能力赐给它们，好像地上蝎子的能力一样，"},
{volumn:        66,chapter:         9,versenumber:         4,verse:"并且吩咐它们说，不可伤害地上的草和各样青物，并一切树木，惟独要伤害额上没有　神印记的人。"},
{volumn:        66,chapter:         9,versenumber:         5,verse:"但不许蝗虫害死他们，只叫他们受痛苦五个月。这痛苦就像蝎子螫人的痛苦一样。"},
{volumn:        66,chapter:         9,versenumber:         6,verse:"在那些日子，人要求死，决不得死；愿意死，死却远避他们。"},
{volumn:        66,chapter:         9,versenumber:         7,verse:"蝗虫的形状，好像预备出战的马一样，头上戴的好像金冠冕，脸面好像男人的脸面，"},
{volumn:        66,chapter:         9,versenumber:         8,verse:"头发像女人的头发，牙齿像狮子的牙齿。"},
{volumn:        66,chapter:         9,versenumber:         9,verse:"胸前有甲，好像铁甲。它们翅膀的声音，好像许多车马奔跑上阵的声音。"},
{volumn:        66,chapter:         9,versenumber:        10,verse:"有尾巴像蝎子，尾巴上的毒钩能伤人五个月。"},
{volumn:        66,chapter:         9,versenumber:        11,verse:"有无底坑的使者作它们的王，按着希伯来话，名叫亚巴顿，希腊话，名叫亚玻伦。"},
{volumn:        66,chapter:         9,versenumber:        12,verse:"第一样灾祸过去了，还有两样灾祸要来。"},
{volumn:        66,chapter:         9,versenumber:        13,verse:"第六位天使吹号，我就听见有声音从　神面前金坛的四角出来，"},
{volumn:        66,chapter:         9,versenumber:        14,verse:"吩咐那吹号的第六位天使，说：“把那捆绑在幼发拉底大河的四个使者释放了。”"},
{volumn:        66,chapter:         9,versenumber:        15,verse:"那四个使者就被释放；他们原是预备好了，到某年某月某日某时，要杀人的三分之一。"},
{volumn:        66,chapter:         9,versenumber:        16,verse:"马军有二万万；他们的数目我听见了。"},
{volumn:        66,chapter:         9,versenumber:        17,verse:"我在异象中看见那些马和骑马的，骑马的胸前有甲如火，与紫玛瑙并硫磺。马的头好像狮子头，有火、有烟、有硫磺从马的口中出来。"},
{volumn:        66,chapter:         9,versenumber:        18,verse:"口中所出来的火与烟并硫磺，这三样灾杀了人的三分之一。"},
{volumn:        66,chapter:         9,versenumber:        19,verse:"这马的能力是在口里和尾巴上；因这尾巴像蛇，并且有头用以害人。"},
{volumn:        66,chapter:         9,versenumber:        20,verse:"其余未曾被这些灾所杀的人仍旧不悔改自己手所做的，还是去拜鬼魔和那些不能看、不能听、不能走，金、银、铜、木、石的偶像，"},
{volumn:        66,chapter:         9,versenumber:        21,verse:"又不悔改他们那些凶杀、邪术、奸淫、偷窃的事。"},
{volumn:        66,chapter:        10,versenumber:         1,verse:"我又看见另有一位大力的天使从天降下，披着云彩，头上有虹，脸面像日头，两脚像火柱。"},
{volumn:        66,chapter:        10,versenumber:         2,verse:"他手里拿着小书卷，是展开的。他右脚踏海，左脚踏地，"},
{volumn:        66,chapter:        10,versenumber:         3,verse:"大声呼喊，好像狮子吼叫。呼喊完了，就有七雷发声。"},
{volumn:        66,chapter:        10,versenumber:         4,verse:"七雷发声之后，我正要写出来，就听见从天上有声音说：“七雷所说的，你要封上，不可写出来。”"},
{volumn:        66,chapter:        10,versenumber:         5,verse:"我所看见的那踏海踏地的天使向天举起右手来，"},
{volumn:        66,chapter:        10,versenumber:         6,verse:"指着那创造天和天上之物，地和地上之物，海和海中之物，直活到永永远远的，起誓说：“不再有时日了（或译：不再耽延了）。”"},
{volumn:        66,chapter:        10,versenumber:         7,verse:"但在第七位天使吹号发声的时候，　神的奥秘就成全了，正如　神所传给他仆人众先知的佳音。"},
{volumn:        66,chapter:        10,versenumber:         8,verse:"我先前从天上所听见的那声音又吩咐我说：“你去，把那踏海踏地之天使手中展开的小书卷取过来。”"},
{volumn:        66,chapter:        10,versenumber:         9,verse:"我就走到天使那里，对他说：“请你把小书卷给我。”他对我说：“你拿着吃尽了，便叫你肚子发苦，然而在你口中要甜如蜜。”"},
{volumn:        66,chapter:        10,versenumber:        10,verse:"我从天使手中把小书卷接过来，吃尽了，在我口中果然甜如蜜，吃了以后，肚子觉得发苦了。"},
{volumn:        66,chapter:        10,versenumber:        11,verse:"天使（原文是他们）对我说：“你必指着多民、多国、多方、多王再说预言。”"},
{volumn:        66,chapter:        11,versenumber:         1,verse:"有一根苇子赐给我，当作量度的杖；且有话说：“起来！将　神的殿和祭坛，并在殿中礼拜的人都量一量。"},
{volumn:        66,chapter:        11,versenumber:         2,verse:"只是殿外的院子要留下不用量，因为这是给了外邦人的；他们要践踏圣城四十二个月。"},
{volumn:        66,chapter:        11,versenumber:         3,verse:"我要使我那两个见证人，穿着毛衣，传道一千二百六十天。”"},
{volumn:        66,chapter:        11,versenumber:         4,verse:"他们就是那两棵橄榄树，两个灯台，立在世界之主面前的。"},
{volumn:        66,chapter:        11,versenumber:         5,verse:"若有人想要害他们，就有火从他们口中出来，烧灭仇敌。凡想要害他们的都必这样被杀。"},
{volumn:        66,chapter:        11,versenumber:         6,verse:"这二人有权柄，在他们传道的日子叫天闭塞不下雨；又有权柄叫水变为血，并且能随时随意用各样的灾殃攻击世界。"},
{volumn:        66,chapter:        11,versenumber:         7,verse:"他们作完见证的时候，那从无底坑里上来的兽必与他们交战，并且得胜，把他们杀了。"},
{volumn:        66,chapter:        11,versenumber:         8,verse:"他们的尸首就倒在大城里的街上；这城按着灵意叫所多玛，又叫埃及，就是他们的主钉十字架之处。"},
{volumn:        66,chapter:        11,versenumber:         9,verse:"从各民、各族、各方、各国中，有人观看他们的尸首三天半，又不许把尸首放在坟墓里。"},
{volumn:        66,chapter:        11,versenumber:        10,verse:"住在地上的人就为他们欢喜快乐，互相馈送礼物，因这两位先知曾叫住在地上的人受痛苦。"},
{volumn:        66,chapter:        11,versenumber:        11,verse:"过了这三天半，有生气从　神那里进入他们里面，他们就站起来；看见他们的人甚是害怕。"},
{volumn:        66,chapter:        11,versenumber:        12,verse:"两位先知听见有大声音从天上来，对他们说：“上到这里来。”他们就驾着云上了天，他们的仇敌也看见了。"},
{volumn:        66,chapter:        11,versenumber:        13,verse:"正在那时候，地大震动，城就倒塌了十分之一，因地震而死的有七千人；其余的都恐惧，归荣耀给天上的　神。"},
{volumn:        66,chapter:        11,versenumber:        14,verse:"第二样灾祸过去，第三样灾祸快到了。"},
{volumn:        66,chapter:        11,versenumber:        15,verse:"第七位天使吹号，天上就有大声音说：世上的国成了我主和主基督的国；他要作王，直到永永远远。"},
{volumn:        66,chapter:        11,versenumber:        16,verse:"在　神面前，坐在自己位上的二十四位长老，就面伏于地，敬拜　神，说："},
{volumn:        66,chapter:        11,versenumber:        17,verse:"“昔在、今在的主　神，全能者啊，我们感谢你！因你执掌大权作王了。"},
{volumn:        66,chapter:        11,versenumber:        18,verse:"外邦发怒，你的忿怒也临到了，审判死人的时候也到了；你的仆人众先知和众圣徒，凡敬畏你名的人，连大带小得赏赐的时候也到了；你败坏那些败坏世界之人的时候也就到了。”"},
{volumn:        66,chapter:        11,versenumber:        19,verse:"当时，　神天上的殿开了，在他殿中现出他的约柜。随后有闪电、声音、雷轰、地震、大雹。"},
{volumn:        66,chapter:        12,versenumber:         1,verse:"天上现出大异象来：有一个妇人身披日头，脚踏月亮，头戴十二星的冠冕。"},
{volumn:        66,chapter:        12,versenumber:         2,verse:"她怀了孕，在生产的艰难中疼痛呼叫。"},
{volumn:        66,chapter:        12,versenumber:         3,verse:"天上又现出异象来：有一条大红龙，七头十角；七头上戴着七个冠冕。"},
{volumn:        66,chapter:        12,versenumber:         4,verse:"它的尾巴拖拉着天上星辰的三分之一，摔在地上。龙就站在那将要生产的妇人面前，等她生产之后，要吞吃她的孩子。"},
{volumn:        66,chapter:        12,versenumber:         5,verse:"妇人生了一个男孩子，是将来要用铁杖辖管（辖管：原文是牧）万国的；她的孩子被提到　神宝座那里去了。"},
{volumn:        66,chapter:        12,versenumber:         6,verse:"妇人就逃到旷野，在那里有　神给她预备的地方，使她被养活一千二百六十天。"},
{volumn:        66,chapter:        12,versenumber:         7,verse:"在天上就有了争战。米迦勒同他的使者与龙争战，龙也同它的使者去争战，"},
{volumn:        66,chapter:        12,versenumber:         8,verse:"并没有得胜，天上再没有它们的地方。"},
{volumn:        66,chapter:        12,versenumber:         9,verse:"大龙就是那古蛇，名叫魔鬼，又叫撒但，是迷惑普天下的。它被摔在地上，它的使者也一同被摔下去。"},
{volumn:        66,chapter:        12,versenumber:        10,verse:"我听见在天上有大声音说：“我神的救恩、能力、国度，并他基督的权柄，现在都来到了！因为那在我们　神面前昼夜控告我们弟兄的，已经被摔下去了。"},
{volumn:        66,chapter:        12,versenumber:        11,verse:"弟兄胜过它，是因羔羊的血和自己所见证的道。他们虽至于死，也不爱惜性命。"},
{volumn:        66,chapter:        12,versenumber:        12,verse:"所以，诸天和住在其中的，你们都快乐吧！只是地与海有祸了！因为魔鬼知道自己的时候不多，就气忿忿地下到你们那里去了。”"},
{volumn:        66,chapter:        12,versenumber:        13,verse:"龙见自己被摔在地上，就逼迫那生男孩子的妇人。"},
{volumn:        66,chapter:        12,versenumber:        14,verse:"于是有大鹰的两个翅膀赐给妇人，叫她能飞到旷野，到自己的地方，躲避那蛇；她在那里被养活一载二载半载。"},
{volumn:        66,chapter:        12,versenumber:        15,verse:"蛇就在妇人身后，从口中吐出水来，像河一样，要将妇人冲去。"},
{volumn:        66,chapter:        12,versenumber:        16,verse:"地却帮助妇人，开口吞了从龙口吐出来的水（原文是河）。"},
{volumn:        66,chapter:        12,versenumber:        17,verse:"龙向妇人发怒，去与她其余的儿女争战，这儿女就是那守　神诫命、为耶稣作见证的。"},
{volumn:        66,chapter:        12,versenumber:        18,verse:"那时龙就站在海边的沙上。"},
{volumn:        66,chapter:        13,versenumber:         1,verse:"我又看见一个兽从海中上来，有十角七头，在十角上戴着十个冠冕，七头上有亵渎的名号。"},
{volumn:        66,chapter:        13,versenumber:         2,verse:"我所看见的兽，形状像豹，脚像熊的脚，口像狮子的口。那龙将自己的能力、座位，和大权柄都给了它。"},
{volumn:        66,chapter:        13,versenumber:         3,verse:"我看见兽的七头中，有一个似乎受了死伤，那死伤却医好了。全地的人都希奇跟从那兽，"},
{volumn:        66,chapter:        13,versenumber:         4,verse:"又拜那龙，因为它将自己的权柄给了兽，也拜兽，说：“谁能比这兽，谁能与它交战呢？”"},
{volumn:        66,chapter:        13,versenumber:         5,verse:"又赐给它说夸大亵渎话的口，又有权柄赐给它，可以任意而行四十二个月。"},
{volumn:        66,chapter:        13,versenumber:         6,verse:"兽就开口向　神说亵渎的话，亵渎　神的名并他的帐幕，以及那些住在天上的。"},
{volumn:        66,chapter:        13,versenumber:         7,verse:"又任凭它与圣徒争战，并且得胜；也把权柄赐给它，制伏各族、各民、各方、各国。"},
{volumn:        66,chapter:        13,versenumber:         8,verse:"凡住在地上、名字从创世以来没有记在被杀之羔羊生命册上的人，都要拜它。"},
{volumn:        66,chapter:        13,versenumber:         9,verse:"凡有耳的，就应当听！"},
{volumn:        66,chapter:        13,versenumber:        10,verse:"掳掠人的，必被掳掠；用刀杀人的，必被刀杀。圣徒的忍耐和信心就是在此。"},
{volumn:        66,chapter:        13,versenumber:        11,verse:"我又看见另有一个兽从地中上来，有两角如同羊羔，说话好像龙。"},
{volumn:        66,chapter:        13,versenumber:        12,verse:"它在头一个兽面前，施行头一个兽所有的权柄，并且叫地和住在地上的人拜那死伤医好的头一个兽。"},
{volumn:        66,chapter:        13,versenumber:        13,verse:"又行大奇事，甚至在人面前，叫火从天降在地上。"},
{volumn:        66,chapter:        13,versenumber:        14,verse:"它因赐给它权柄在兽面前能行奇事，就迷惑住在地上的人，说：“要给那受刀伤还活着的兽作个像。”"},
{volumn:        66,chapter:        13,versenumber:        15,verse:"又有权柄赐给它，叫兽像有生气，并且能说话，又叫所有不拜兽像的人都被杀害。"},
{volumn:        66,chapter:        13,versenumber:        16,verse:"它又叫众人，无论大小、贫富、自主的、为奴的，都在右手上或是在额上受一个印记。"},
{volumn:        66,chapter:        13,versenumber:        17,verse:"除了那受印记、有了兽名或有兽名数目的，都不得做买卖。"},
{volumn:        66,chapter:        13,versenumber:        18,verse:"在这里有智慧：凡有聪明的，可以算计兽的数目；因为这是人的数目，它的数目是六百六十六。"},
{volumn:        66,chapter:        14,versenumber:         1,verse:"我又观看，见羔羊站在锡安山，同他又有十四万四千人，都有他的名和他父的名写在额上。"},
{volumn:        66,chapter:        14,versenumber:         2,verse:"我听见从天上有声音，像众水的声音和大雷的声音，并且我所听见的好像弹琴的所弹的琴声。"},
{volumn:        66,chapter:        14,versenumber:         3,verse:"他们在宝座前，并在四活物和众长老前唱歌，仿佛是新歌；除了从地上买来的那十四万四千人以外，没有人能学这歌。"},
{volumn:        66,chapter:        14,versenumber:         4,verse:"这些人未曾沾染妇女，他们原是童身。羔羊无论往哪里去，他们都跟随他。他们是从人间买来的，作初熟的果子归与　神和羔羊。"},
{volumn:        66,chapter:        14,versenumber:         5,verse:"在他们口中察不出谎言来；他们是没有瑕疵的。"},
{volumn:        66,chapter:        14,versenumber:         6,verse:"我又看见另有一位天使飞在空中，有永远的福音要传给住在地上的人，就是各国、各族、各方、各民。"},
{volumn:        66,chapter:        14,versenumber:         7,verse:"他大声说：“应当敬畏　神，将荣耀归给他！因他施行审判的时候已经到了。应当敬拜那创造天地海和众水泉源的。”"},
{volumn:        66,chapter:        14,versenumber:         8,verse:"又有第二位天使接着说：“叫万民喝邪淫、大怒之酒的巴比伦大城倾倒了！倾倒了！”"},
{volumn:        66,chapter:        14,versenumber:         9,verse:"又有第三位天使接着他们，大声说：“若有人拜兽和兽像，在额上或在手上受了印记，"},
{volumn:        66,chapter:        14,versenumber:        10,verse:"这人也必喝　神大怒的酒；此酒斟在　神忿怒的杯中纯一不杂。他要在圣天使和羔羊面前，在火与硫磺之中受痛苦。"},
{volumn:        66,chapter:        14,versenumber:        11,verse:"他受痛苦的烟往上冒，直到永永远远。那些拜兽和兽像，受它名之印记的，昼夜不得安宁。”"},
{volumn:        66,chapter:        14,versenumber:        12,verse:"圣徒的忍耐就在此；他们是守　神诫命和耶稣真道的。"},
{volumn:        66,chapter:        14,versenumber:        13,verse:"我听见从天上有声音说：“你要写下：从今以后，在主里面而死的人有福了！”圣灵说：“是的，他们息了自己的劳苦，做工的果效也随着他们。”"},
{volumn:        66,chapter:        14,versenumber:        14,verse:"我又观看，见有一片白云，云上坐着一位好像人子，头上戴着金冠冕，手里拿着快镰刀。"},
{volumn:        66,chapter:        14,versenumber:        15,verse:"又有一位天使从殿中出来，向那坐在云上的大声喊着说：“伸出你的镰刀来收割；因为收割的时候已经到了，地上的庄稼已经熟透了。”"},
{volumn:        66,chapter:        14,versenumber:        16,verse:"那坐在云上的，就把镰刀扔在地上，地上的庄稼就被收割了。"},
{volumn:        66,chapter:        14,versenumber:        17,verse:"又有一位天使从天上的殿中出来，他也拿着快镰刀。"},
{volumn:        66,chapter:        14,versenumber:        18,verse:"又有一位天使从祭坛中出来，是有权柄管火的，向拿着快镰刀的大声喊着说：“伸出快镰刀来，收取地上葡萄树的果子，因为葡萄熟透了！”"},
{volumn:        66,chapter:        14,versenumber:        19,verse:"那天使就把镰刀扔在地上，收取了地上的葡萄，丢在　神忿怒的大酒榨中。"},
{volumn:        66,chapter:        14,versenumber:        20,verse:"那酒榨踹在城外，就有血从酒榨里流出来，高到马的嚼环，远有六百里。"},
{volumn:        66,chapter:        15,versenumber:         1,verse:"我又看见在天上有异象，大而且奇，就是七位天使掌管末了的七灾，因为　神的大怒在这七灾中发尽了。"},
{volumn:        66,chapter:        15,versenumber:         2,verse:"我看见仿佛有玻璃海，其中有火搀杂。又看见那些胜了兽和兽的像并它名字数目的人，都站在玻璃海上，拿着　神的琴，"},
{volumn:        66,chapter:        15,versenumber:         3,verse:"唱　神仆人摩西的歌和羔羊的歌，说：“主　神，全能者啊，你的作为大哉！奇哉！万世（或译：国）之王啊，你的道途义哉！诚哉！"},
{volumn:        66,chapter:        15,versenumber:         4,verse:"主啊，谁敢不敬畏你，不将荣耀归与你的名呢？因为独有你是圣的。万民都要来在你面前敬拜，因你公义的作为已经显出来了。”"},
{volumn:        66,chapter:        15,versenumber:         5,verse:"此后，我看见在天上那存法柜的殿开了。"},
{volumn:        66,chapter:        15,versenumber:         6,verse:"那掌管七灾的七位天使从殿中出来，穿着洁白光明的细麻衣（细麻衣：有古卷是宝石），胸间束着金带。"},
{volumn:        66,chapter:        15,versenumber:         7,verse:"四活物中有一个把盛满了活到永永远远之　神大怒的七个金碗给了那七位天使。"},
{volumn:        66,chapter:        15,versenumber:         8,verse:"因　神的荣耀和能力，殿中充满了烟。于是没有人能以进殿，直等到那七位天使所降的七灾完毕了。"},
{volumn:        66,chapter:        16,versenumber:         1,verse:"我听见有大声音从殿中出来，向那七位天使说：“你们去，把盛　神大怒的七碗倒在地上。”"},
{volumn:        66,chapter:        16,versenumber:         2,verse:"第一位天使便去，把碗倒在地上，就有恶而且毒的疮生在那些有兽印记、拜兽像的人身上。"},
{volumn:        66,chapter:        16,versenumber:         3,verse:"第二位天使把碗倒在海里，海就变成血，好像死人的血，海中的活物都死了。"},
{volumn:        66,chapter:        16,versenumber:         4,verse:"第三位天使把碗倒在江河与众水的泉源里，水就变成血了。"},
{volumn:        66,chapter:        16,versenumber:         5,verse:"我听见掌管众水的天使说：“昔在、今在的圣者啊，你这样判断是公义的；"},
{volumn:        66,chapter:        16,versenumber:         6,verse:"他们曾流圣徒与先知的血，现在你给他们血喝；这是他们所该受的。”"},
{volumn:        66,chapter:        16,versenumber:         7,verse:"我又听见祭坛中有声音说：“是的，主　神，全能者啊，你的判断义哉！诚哉！”"},
{volumn:        66,chapter:        16,versenumber:         8,verse:"第四位天使把碗倒在日头上，叫日头能用火烤人。"},
{volumn:        66,chapter:        16,versenumber:         9,verse:"人被大热所烤，就亵渎那有权掌管这些灾的　神之名，并不悔改将荣耀归给　神。"},
{volumn:        66,chapter:        16,versenumber:        10,verse:"第五位天使把碗倒在兽的座位上，兽的国就黑暗了。人因疼痛就咬自己的舌头；"},
{volumn:        66,chapter:        16,versenumber:        11,verse:"又因所受的疼痛，和生的疮，就亵渎天上的　神，并不悔改所行的。"},
{volumn:        66,chapter:        16,versenumber:        12,verse:"第六位天使把碗倒在幼发拉底大河上，河水就干了，要给那从日出之地所来的众王预备道路。"},
{volumn:        66,chapter:        16,versenumber:        13,verse:"我又看见三个污秽的灵，好像青蛙，从龙口、兽口并假先知的口中出来。"},
{volumn:        66,chapter:        16,versenumber:        14,verse:"他们本是鬼魔的灵，施行奇事，出去到普天下众王那里，叫他们在　神全能者的大日聚集争战。"},
{volumn:        66,chapter:        16,versenumber:        15,verse:"看哪，我来像贼一样。那警醒、看守衣服、免得赤身而行、叫人见他羞耻的有福了！"},
{volumn:        66,chapter:        16,versenumber:        16,verse:"那三个鬼魔便叫众王聚集在一处，希伯来话叫作哈米吉多顿。"},
{volumn:        66,chapter:        16,versenumber:        17,verse:"第七位天使把碗倒在空中，就有大声音从殿中的宝座上出来，说：“成了！”"},
{volumn:        66,chapter:        16,versenumber:        18,verse:"又有闪电、声音、雷轰、大地震，自从地上有人以来，没有这样大、这样厉害的地震。"},
{volumn:        66,chapter:        16,versenumber:        19,verse:"那大城裂为三段，列国的城也都倒塌了；　神也想起巴比伦大城来，要把那盛自己烈怒的酒杯递给他。"},
{volumn:        66,chapter:        16,versenumber:        20,verse:"各海岛都逃避了，众山也不见了。"},
{volumn:        66,chapter:        16,versenumber:        21,verse:"又有大雹子从天落在人身上，每一个约重一他连得（一他连得约有九十斤）。为这雹子的灾极大，人就亵渎　神。"},
{volumn:        66,chapter:        17,versenumber:         1,verse:"拿着七碗的七位天使中，有一位前来对我说：“你到这里来，我将坐在众水上的大淫妇所要受的刑罚指给你看。"},
{volumn:        66,chapter:        17,versenumber:         2,verse:"地上的君王与她行淫，住在地上的人喝醉了她淫乱的酒。”"},
{volumn:        66,chapter:        17,versenumber:         3,verse:"我被圣灵感动，天使带我到旷野去，我就看见一个女人骑在朱红色的兽上；那兽有七头十角，遍体有亵渎的名号。"},
{volumn:        66,chapter:        17,versenumber:         4,verse:"那女人穿着紫色和朱红色的衣服，用金子、宝石、珍珠为妆饰；手拿金杯，杯中盛满了可憎之物，就是她淫乱的污秽。"},
{volumn:        66,chapter:        17,versenumber:         5,verse:"在她额上有名写着说：“奥秘哉！大巴比伦，作世上的淫妇和一切可憎之物的母。”"},
{volumn:        66,chapter:        17,versenumber:         6,verse:"我又看见那女人喝醉了圣徒的血和为耶稣作见证之人的血。我看见她，就大大地希奇。"},
{volumn:        66,chapter:        17,versenumber:         7,verse:"天使对我说：“你为什么希奇呢？我要将这女人和驮着她的那七头十角兽的奥秘告诉你。"},
{volumn:        66,chapter:        17,versenumber:         8,verse:"你所看见的兽，先前有，如今没有，将要从无底坑里上来，又要归于沉沦。凡住在地上、名字从创世以来没有记在生命册上的，见先前有、如今没有、以后再有的兽，就必希奇。"},
{volumn:        66,chapter:        17,versenumber:         9,verse:"智慧的心在此可以思想。那七头就是女人所坐的七座山，"},
{volumn:        66,chapter:        17,versenumber:        10,verse:"又是七位王；五位已经倾倒了，一位还在，一位还没有来到；他来的时候，必须暂时存留。"},
{volumn:        66,chapter:        17,versenumber:        11,verse:"那先前有如今没有的兽，就是第八位；他也和那七位同列，并且归于沉沦。"},
{volumn:        66,chapter:        17,versenumber:        12,verse:"你所看见的那十角就是十王；他们还没有得国，但他们一时之间要和兽同得权柄，与王一样。"},
{volumn:        66,chapter:        17,versenumber:        13,verse:"他们同心合意将自己的能力、权柄给那兽。"},
{volumn:        66,chapter:        17,versenumber:        14,verse:"他们与羔羊争战，羔羊必胜过他们，因为羔羊是万主之主、万王之王。同着羔羊的，就是蒙召、被选、有忠心的，也必得胜。”"},
{volumn:        66,chapter:        17,versenumber:        15,verse:"天使又对我说：“你所看见那淫妇坐的众水，就是多民、多人、多国、多方。"},
{volumn:        66,chapter:        17,versenumber:        16,verse:"你所看见的那十角与兽必恨这淫妇，使她冷落赤身，又要吃她的肉，用火将她烧尽。"},
{volumn:        66,chapter:        17,versenumber:        17,verse:"因为　神使诸王同心合意，遵行他的旨意，把自己的国给那兽，直等到　神的话都应验了。"},
{volumn:        66,chapter:        17,versenumber:        18,verse:"你所看见的那女人就是管辖地上众王的大城。”"},
{volumn:        66,chapter:        18,versenumber:         1,verse:"此后，我看见另有一位有大权柄的天使从天降下，地就因他的荣耀发光。"},
{volumn:        66,chapter:        18,versenumber:         2,verse:"他大声喊着说：“巴比伦大城倾倒了！倾倒了！成了鬼魔的住处和各样污秽之灵的巢穴（或译：牢狱；下同），并各样污秽可憎之雀鸟的巢穴。"},
{volumn:        66,chapter:        18,versenumber:         3,verse:"因为列国都被她邪淫大怒的酒倾倒了。地上的君王与她行淫；地上的客商因她奢华太过就发了财。”"},
{volumn:        66,chapter:        18,versenumber:         4,verse:"我又听见从天上有声音说：“我的民哪，你们要从那城出来，免得与她一同有罪，受她所受的灾殃；"},
{volumn:        66,chapter:        18,versenumber:         5,verse:"因她的罪恶滔天；她的不义，　神已经想起来了。"},
{volumn:        66,chapter:        18,versenumber:         6,verse:"她怎样待人，也要怎样待她，按她所行的加倍地报应她；用她调酒的杯加倍地调给她喝。"},
{volumn:        66,chapter:        18,versenumber:         7,verse:"她怎样荣耀自己，怎样奢华，也当叫她照样痛苦悲哀，因她心里说：‘我坐了皇后的位，并不是寡妇，决不至于悲哀。’"},
{volumn:        66,chapter:        18,versenumber:         8,verse:"所以在一天之内，她的灾殃要一齐来到，就是死亡、悲哀、饥荒。她又要被火烧尽了，因为审判她的主　神大有能力。”"},
{volumn:        66,chapter:        18,versenumber:         9,verse:"地上的君王，素来与她行淫、一同奢华的，看见烧她的烟，就必为她哭泣哀号。"},
{volumn:        66,chapter:        18,versenumber:        10,verse:"因怕她的痛苦，就远远地站着说：“哀哉！哀哉！巴比伦大城，坚固的城啊，一时之间你的刑罚就来到了。”"},
{volumn:        66,chapter:        18,versenumber:        11,verse:"地上的客商也都为她哭泣悲哀，因为没有人再买他们的货物了；"},
{volumn:        66,chapter:        18,versenumber:        12,verse:"这货物就是金、银、宝石、珍珠、细麻布、紫色料、绸子、朱红色料、各样香木、各样象牙的器皿、各样极宝贵的木头，和铜、铁、汉白玉的器皿，"},
{volumn:        66,chapter:        18,versenumber:        13,verse:"并肉桂、豆蔻、香料、香膏、乳香、酒、油、细面、麦子、牛、羊、车、马，和奴仆、人口。"},
{volumn:        66,chapter:        18,versenumber:        14,verse:"巴比伦哪，你所贪爱的果子离开了你；你一切的珍馐美味和华美的物件也从你中间毁灭，决不能再见了。"},
{volumn:        66,chapter:        18,versenumber:        15,verse:"贩卖这些货物、藉着她发了财的客商，因怕她的痛苦，就远远地站着哭泣悲哀，"},
{volumn:        66,chapter:        18,versenumber:        16,verse:"说：“哀哉！哀哉！这大城啊！素常穿着细麻、紫色、朱红色的衣服，又用金子、宝石，和珍珠为妆饰。"},
{volumn:        66,chapter:        18,versenumber:        17,verse:"一时之间，这么大的富厚就归于无有了。”		凡船主和坐船往各处去的，并众水手，连所有靠海为业的，都远远地站着，"},
{volumn:        66,chapter:        18,versenumber:        18,verse:"看见烧她的烟，就喊着说：“有何城能比这大城呢？”"},
{volumn:        66,chapter:        18,versenumber:        19,verse:"他们又把尘土撒在头上，哭泣悲哀，喊着说：“哀哉！哀哉！这大城啊！”凡有船在海中的，都因她的珍宝成了富足，她在一时之间就成了荒场！"},
{volumn:        66,chapter:        18,versenumber:        20,verse:"天哪，众圣徒、众使徒、众先知啊，你们都要因她欢喜，因为　神已经在她身上伸了你们的冤。"},
{volumn:        66,chapter:        18,versenumber:        21,verse:"有一位大力的天使举起一块石头，好像大磨石，扔在海里，说：“巴比伦大城也必这样猛力地被扔下去，决不能再见了。"},
{volumn:        66,chapter:        18,versenumber:        22,verse:"弹琴、作乐、吹笛、吹号的声音，在你中间决不能再听见；各行手艺人在你中间决不能再遇见；推磨的声音在你中间决不能再听见；"},
{volumn:        66,chapter:        18,versenumber:        23,verse:"灯光在你中间决不能再照耀；新郎和新妇的声音，在你中间决不能再听见。你的客商原来是地上的尊贵人；万国也被你的邪术迷惑了。”"},
{volumn:        66,chapter:        18,versenumber:        24,verse:"先知和圣徒，并地上一切被杀之人的血，都在这城里看见了。"},
{volumn:        66,chapter:        19,versenumber:         1,verse:"此后，我听见好像群众在天上大声说：“哈利路亚（就是要赞美耶和华的意思）！救恩、荣耀、权能都属乎我们的　神！"},
{volumn:        66,chapter:        19,versenumber:         2,verse:"他的判断是真实公义的；因他判断了那用淫行败坏世界的大淫妇，并且向淫妇讨流仆人血的罪，给他们伸冤。”"},
{volumn:        66,chapter:        19,versenumber:         3,verse:"又说：“哈利路亚！烧淫妇的烟往上冒，直到永永远远。”"},
{volumn:        66,chapter:        19,versenumber:         4,verse:"那二十四位长老与四活物就俯伏敬拜坐宝座的　神，说：“阿们！哈利路亚！”"},
{volumn:        66,chapter:        19,versenumber:         5,verse:"有声音从宝座出来说：“　神的众仆人哪，凡敬畏他的，无论大小，都要赞美我们的　神！”"},
{volumn:        66,chapter:        19,versenumber:         6,verse:"我听见好像群众的声音，众水的声音，大雷的声音，说：“哈利路亚！因为主我们的　神、全能者作王了。”"},
{volumn:        66,chapter:        19,versenumber:         7,verse:"我们要欢喜快乐，将荣耀归给他。因为，羔羊婚娶的时候到了；新妇也自己预备好了，"},
{volumn:        66,chapter:        19,versenumber:         8,verse:"就蒙恩得穿光明洁白的细麻衣。（这细麻衣就是圣徒所行的义。）"},
{volumn:        66,chapter:        19,versenumber:         9,verse:"天使吩咐我说：“你要写上：凡被请赴羔羊之婚筵的有福了！”又对我说：“这是　神真实的话。”"},
{volumn:        66,chapter:        19,versenumber:        10,verse:"我就俯伏在他脚前要拜他。他说：“千万不可！我和你，并你那些为耶稣作见证的弟兄同是作仆人的，你要敬拜　神。”因为预言中的灵意乃是为耶稣作见证。"},
{volumn:        66,chapter:        19,versenumber:        11,verse:"我观看，见天开了。有一匹白马，骑在马上的称为诚信真实，他审判，争战，都按着公义。"},
{volumn:        66,chapter:        19,versenumber:        12,verse:"他的眼睛如火焰，他头上戴着许多冠冕；又有写着的名字，除了他自己没有人知道。"},
{volumn:        66,chapter:        19,versenumber:        13,verse:"他穿着溅了血的衣服；他的名称为　神之道。"},
{volumn:        66,chapter:        19,versenumber:        14,verse:"在天上的众军骑着白马，穿着细麻衣，又白又洁，跟随他。"},
{volumn:        66,chapter:        19,versenumber:        15,verse:"有利剑从他口中出来，可以击杀列国。他必用铁杖辖管（辖管：原文是牧）他们，并要踹全能　神烈怒的酒榨。"},
{volumn:        66,chapter:        19,versenumber:        16,verse:"在他衣服和大腿上有名写着说：“万王之王，万主之主。”"},
{volumn:        66,chapter:        19,versenumber:        17,verse:"我又看见一位天使站在日头中，向天空所飞的鸟大声喊着说：“你们聚集来赴　神的大筵席，"},
{volumn:        66,chapter:        19,versenumber:        18,verse:"可以吃君王与将军的肉，壮士与马和骑马者的肉，并一切自主的为奴的，以及大小人民的肉。”"},
{volumn:        66,chapter:        19,versenumber:        19,verse:"我看见那兽和地上的君王，并他们的众军都聚集，要与骑白马的并他的军兵争战。"},
{volumn:        66,chapter:        19,versenumber:        20,verse:"那兽被擒拿；那在兽面前曾行奇事、迷惑受兽印记和拜兽像之人的假先知，也与兽同被擒拿。他们两个就活活地被扔在烧着硫磺的火湖里；"},
{volumn:        66,chapter:        19,versenumber:        21,verse:"其余的被骑白马者口中出来的剑杀了；飞鸟都吃饱了他们的肉。"},
{volumn:        66,chapter:        20,versenumber:         1,verse:"我又看见一位天使从天降下，手里拿着无底坑的钥匙和一条大链子。"},
{volumn:        66,chapter:        20,versenumber:         2,verse:"他捉住那龙，就是古蛇，又叫魔鬼，也叫撒但，把它捆绑一千年，"},
{volumn:        66,chapter:        20,versenumber:         3,verse:"扔在无底坑里，将无底坑关闭，用印封上，使它不得再迷惑列国。等到那一千年完了，以后必须暂时释放它。"},
{volumn:        66,chapter:        20,versenumber:         4,verse:"我又看见几个宝座，也有坐在上面的，并有审判的权柄赐给他们。我又看见那些因为给耶稣作见证，并为　神之道被斩者的灵魂，和那没有拜过兽与兽像，也没有在额上和手上受过他印记之人的灵魂，他们都复活了，与基督一同作王一千年。"},
{volumn:        66,chapter:        20,versenumber:         5,verse:"这是头一次的复活。（其余的死人还没有复活，直等那一千年完了。）"},
{volumn:        66,chapter:        20,versenumber:         6,verse:"在头一次复活有份的有福了，圣洁了！第二次的死在他们身上没有权柄。他们必作　神和基督的祭司，并要与基督一同作王一千年。"},
{volumn:        66,chapter:        20,versenumber:         7,verse:"那一千年完了，撒但必从监牢里被释放，"},
{volumn:        66,chapter:        20,versenumber:         8,verse:"出来要迷惑地上四方（原文是角）的列国，就是歌革和玛各，叫他们聚集争战。他们的人数多如海沙。"},
{volumn:        66,chapter:        20,versenumber:         9,verse:"他们上来遍满了全地，围住圣徒的营与蒙爱的城，就有火从天降下，烧灭了他们。"},
{volumn:        66,chapter:        20,versenumber:        10,verse:"那迷惑他们的魔鬼被扔在硫磺的火湖里，就是兽和假先知所在的地方。他们必昼夜受痛苦，直到永永远远。"},
{volumn:        66,chapter:        20,versenumber:        11,verse:"我又看见一个白色的大宝座与坐在上面的；从他面前天地都逃避，再无可见之处了。"},
{volumn:        66,chapter:        20,versenumber:        12,verse:"我又看见死了的人，无论大小，都站在宝座前。案卷展开了，并且另有一卷展开，就是生命册。死了的人都凭着这些案卷所记载的，照他们所行的受审判。"},
{volumn:        66,chapter:        20,versenumber:        13,verse:"于是海交出其中的死人；死亡和阴间也交出其中的死人；他们都照各人所行的受审判。"},
{volumn:        66,chapter:        20,versenumber:        14,verse:"死亡和阴间也被扔在火湖里；这火湖就是第二次的死。"},
{volumn:        66,chapter:        20,versenumber:        15,verse:"若有人名字没记在生命册上，他就被扔在火湖里。"},
{volumn:        66,chapter:        21,versenumber:         1,verse:"我又看见一个新天新地；因为先前的天地已经过去了，海也不再有了。"},
{volumn:        66,chapter:        21,versenumber:         2,verse:"我又看见圣城新耶路撒冷由　神那里从天而降，预备好了，就如新妇妆饰整齐，等候丈夫。"},
{volumn:        66,chapter:        21,versenumber:         3,verse:"我听见有大声音从宝座出来说：“看哪，　神的帐幕在人间。他要与人同住，他们要作他的子民。　神要亲自与他们同在，作他们的　神。"},
{volumn:        66,chapter:        21,versenumber:         4,verse:"神要擦去他们一切的眼泪；不再有死亡，也不再有悲哀、哭号、疼痛，因为以前的事都过去了。”"},
{volumn:        66,chapter:        21,versenumber:         5,verse:"坐宝座的说：“看哪，我将一切都更新了！”又说：“你要写上；因这些话是可信的，是真实的。”"},
{volumn:        66,chapter:        21,versenumber:         6,verse:"他又对我说：“都成了！我是阿拉法，我是俄梅戛；我是初，我是终。我要将生命泉的水白白赐给那口渴的人喝。"},
{volumn:        66,chapter:        21,versenumber:         7,verse:"得胜的，必承受这些为业：我要作他的　神，他要作我的儿子。"},
{volumn:        66,chapter:        21,versenumber:         8,verse:"惟有胆怯的、不信的、可憎的、杀人的、淫乱的、行邪术的、拜偶像的，和一切说谎话的，他们的份就在烧着硫磺的火湖里；这是第二次的死。”"},
{volumn:        66,chapter:        21,versenumber:         9,verse:"拿着七个金碗、盛满末后七灾的七位天使中，有一位来对我说：“你到这里来，我要将新妇，就是羔羊的妻，指给你看。”"},
{volumn:        66,chapter:        21,versenumber:        10,verse:"我被圣灵感动，天使就带我到一座高大的山，将那由　神那里、从天而降的圣城耶路撒冷指示我。"},
{volumn:        66,chapter:        21,versenumber:        11,verse:"城中有　神的荣耀；城的光辉如同极贵的宝石，好像碧玉，明如水晶。"},
{volumn:        66,chapter:        21,versenumber:        12,verse:"有高大的墙，有十二个门，门上有十二位天使，门上又写着以色列十二个支派的名字。"},
{volumn:        66,chapter:        21,versenumber:        13,verse:"东边有三门，北边有三门，南边有三门，西边有三门。"},
{volumn:        66,chapter:        21,versenumber:        14,verse:"城墙有十二根基，根基上有羔羊十二使徒的名字。"},
{volumn:        66,chapter:        21,versenumber:        15,verse:"对我说话的，拿着金苇子当尺，要量那城和城门城墙。"},
{volumn:        66,chapter:        21,versenumber:        16,verse:"城是四方的，长宽一样。天使用苇子量那城，共有四千里，长、宽、高都是一样；"},
{volumn:        66,chapter:        21,versenumber:        17,verse:"又量了城墙，按着人的尺寸，就是天使的尺寸，共有一百四十四肘。"},
{volumn:        66,chapter:        21,versenumber:        18,verse:"墙是碧玉造的；城是精金的，如同明净的玻璃。"},
{volumn:        66,chapter:        21,versenumber:        19,verse:"城墙的根基是用各样宝石修饰的：第一根基是碧玉；第二是蓝宝石；第三是绿玛瑙；第四是绿宝石；"},
{volumn:        66,chapter:        21,versenumber:        20,verse:"第五是红玛瑙；第六是红宝石；第七是黄璧玺；第八是水苍玉；第九是红璧玺；第十是翡翠；第十一是紫玛瑙；第十二是紫晶。"},
{volumn:        66,chapter:        21,versenumber:        21,verse:"十二个门是十二颗珍珠，每门是一颗珍珠。城内的街道是精金，好像明透的玻璃。"},
{volumn:        66,chapter:        21,versenumber:        22,verse:"我未见城内有殿，因主　神全能者和羔羊为城的殿。"},
{volumn:        66,chapter:        21,versenumber:        23,verse:"那城内又不用日月光照；因有　神的荣耀光照，又有羔羊为城的灯。"},
{volumn:        66,chapter:        21,versenumber:        24,verse:"列国要在城的光里行走；地上的君王必将自己的荣耀归与那城。"},
{volumn:        66,chapter:        21,versenumber:        25,verse:"城门白昼总不关闭，在那里原没有黑夜。"},
{volumn:        66,chapter:        21,versenumber:        26,verse:"人必将列国的荣耀、尊贵归与那城。"},
{volumn:        66,chapter:        21,versenumber:        27,verse:"凡不洁净的，并那行可憎与虚谎之事的，总不得进那城；只有名字写在羔羊生命册上的才得进去。"},
{volumn:        66,chapter:        22,versenumber:         1,verse:"天使又指示我在城内街道当中一道生命水的河，明亮如水晶，从　神和羔羊的宝座流出来。"},
{volumn:        66,chapter:        22,versenumber:         2,verse:"在河这边与那边有生命树，结十二样（或译：回）果子，每月都结果子；树上的叶子乃为医治万民。"},
{volumn:        66,chapter:        22,versenumber:         3,verse:"以后再没有咒诅；在城里有　神和羔羊的宝座；他的仆人都要侍奉他，"},
{volumn:        66,chapter:        22,versenumber:         4,verse:"也要见他的面。他的名字必写在他们的额上。"},
{volumn:        66,chapter:        22,versenumber:         5,verse:"不再有黑夜；他们也不用灯光、日光，因为主　神要光照他们。他们要作王，直到永永远远。"},
{volumn:        66,chapter:        22,versenumber:         6,verse:"天使又对我说：“这些话是真实可信的。主就是众先知被感之灵的　神，差遣他的使者，将那必要快成的事指示他仆人。”"},
{volumn:        66,chapter:        22,versenumber:         7,verse:"“看哪，我必快来！凡遵守这书上预言的有福了！”"},
{volumn:        66,chapter:        22,versenumber:         8,verse:"这些事是我约翰所听见、所看见的；我既听见、看见了，就在指示我的天使脚前俯伏要拜他。"},
{volumn:        66,chapter:        22,versenumber:         9,verse:"他对我说：“千万不可！我与你和你的弟兄众先知，并那些守这书上言语的人，同是作仆人的。你要敬拜　神。”"},
{volumn:        66,chapter:        22,versenumber:        10,verse:"他又对我说：“不可封了这书上的预言，因为日期近了。"},
{volumn:        66,chapter:        22,versenumber:        11,verse:"不义的，叫他仍旧不义；污秽的，叫他仍旧污秽；为义的，叫他仍旧为义；圣洁的，叫他仍旧圣洁。”"},
{volumn:        66,chapter:        22,versenumber:        12,verse:"“看哪，我必快来！赏罚在我，要照各人所行的报应他。"},
{volumn:        66,chapter:        22,versenumber:        13,verse:"我是阿拉法，我是俄梅戛；我是首先的，我是末后的；我是初，我是终。”"},
{volumn:        66,chapter:        22,versenumber:        14,verse:"那些洗净自己衣服的有福了！可得权柄能到生命树那里，也能从门进城。"},
{volumn:        66,chapter:        22,versenumber:        15,verse:"城外有那些犬类、行邪术的、淫乱的、杀人的、拜偶像的，并一切喜好说谎言、编造虚谎的。"},
{volumn:        66,chapter:        22,versenumber:        16,verse:"“我耶稣差遣我的使者为众教会将这些事向你们证明。我是大卫的根，又是他的后裔。我是明亮的晨星。”"},
{volumn:        66,chapter:        22,versenumber:        17,verse:"圣灵和新妇都说：“来！”听见的人也该说：“来！”口渴的人也当来；愿意的，都可以白白取生命的水喝。"},
{volumn:        66,chapter:        22,versenumber:        18,verse:"我向一切听见这书上预言的作见证，若有人在这预言上加添什么，　神必将写在这书上的灾祸加在他身上；"},
{volumn:        66,chapter:        22,versenumber:        19,verse:"这书上的预言，若有人删去什么，　神必从这书上所写的生命树和圣城删去他的份。"},
{volumn:        66,chapter:        22,versenumber:        20,verse:"证明这事的说：“是了，我必快来！”阿们！主耶稣啊，我愿你来！"},
{volumn:        66,chapter:        22,versenumber:        21,verse:"愿主耶稣的恩惠常与众圣徒同在。阿们！"},
]