import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import "./w3.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import firebase from "firebase/app";
import { firebaseConfig } from "./utility/loginhelper";
import store from "./store";

firebase.initializeApp(firebaseConfig);

initializeIcons();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(<Provider store={ store }>
  <App />
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
