import { BlockBlobClient } from "@azure/storage-blob";
import { getApiFetch, postApiFetch } from "../httpUtilty";
import { Song } from "../../store/actions/songActions";

export class SheetMusicFileUtility {
  static createSheetMusiFileSASUrlForUpload = async (songTitle: string, fileName: string) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/CreateSheetMusiFileSASUrlForUpload?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&" +
      "songtitle=" + songTitle +
      "&filename=" + fileName;

    const response = await getApiFetch(url);
    return response.json();
  }

  static getSongSheetMusicFileSasUrls = async (songTitle: string) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetSongSheetMusicFileSasUrls?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&" +
      "songtitle=" + songTitle;

    try {
      const response = await getApiFetch(url);
      return await response.json();
    }
    catch {
      return "";
    }
  }

  static getSongsSheetMusicFileSasUrls = async (songs: Song[]) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetSongsSheetMusicFileSasUrls?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&";

    try {
      const songTitles = [];
      for (let index = 0; index < songs.length; index++) {
        songTitles.push(songs[index].id);
      }
      const response = await postApiFetch(url, JSON.stringify(songTitles));
      return await response.json();
    }
    catch {
      return [];
    }
  }

  static deleteSheetMusicFile = async (blobName: string) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/DeleteSheetMusicFile?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&" +
      "blobname=" + blobName;
    await getApiFetch(url);
  }

  static uploadSheetMusic = async (file: File, songTitle: string, onProgress: (progress: number) => void) => {
    const sasUrl = await SheetMusicFileUtility.createSheetMusiFileSASUrlForUpload(songTitle, file.name);
    const blockBlobClient = new BlockBlobClient(sasUrl);
    await blockBlobClient.uploadData(file, {
      onProgress: (event: any) => {
        onProgress(Math.round(event.loadedBytes / file.size * 100) / 100);
      }
    });
  }
}