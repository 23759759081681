export const SET_CURRENTUSER = "SET_CURRENTUSER";
export const SET_CURRENTUSERPROFILE = "SET_CURRENTUSERPROFILE";

export const setCurrentUser = (user: any) => {
  return {
    type: SET_CURRENTUSER,
    user: user
  }
}

export const setCurrentUserProfile = (userProfile: any) => {
  return {
    type: SET_CURRENTUSERPROFILE,
    userProfile: userProfile
  }
}
