import React, { useEffect, useState } from "react";
import { UserService } from "../../utility/userService";

const UserEditView = (props: {
    userProfile: any;
    onClose: () => void;
}) => {
    const [newRoles, setNewRoles] = useState(0);
    const [newDisplayName, setNewDisplayName] = useState("");

    useEffect(() => {
        setNewRoles(props.userProfile?.roles);
        setNewDisplayName(props.userProfile.displayName);
    }, props.userProfile);

    const saveUserProfile = async () => {
        const newUserProfile = {
            ...props.userProfile,
            roles: newRoles,
            displayName: newDisplayName
        };

        await UserService.upsertUserProfile(newUserProfile);
        props.onClose();
    }

    return (
        <div style={ { height: "100%" } }>
            { props.userProfile && <div>
                <span>{ props.userProfile.rowKey }</span>
                <input
                    style={ { marginRight: "10px", marginLeft: "10px" } }
                    size={ 25 }
                    value={ newDisplayName }
                    onChange={ event => {
                        setNewDisplayName(event.target.value);
                    } }
                ></input>
                <input
                    style={ { marginRight: "10px", marginLeft: "10px" } }
                    size={ 4 }
                    value={ newRoles }
                    onChange={ event => {
                        setNewRoles(parseInt(event.target.value));
                    } }
                ></input>
                <input
                    style={ { marginRight: "10px", marginLeft: "10px" } }
                    type="button"
                    value="Save"
                    onClick={ saveUserProfile }
                ></input>
                <input
                    type="button"
                    value="Cancel"
                    onClick={ () => { props.onClose(); } }
                ></input>
            </div>
            }
        </div>

    );
};

export default UserEditView;
