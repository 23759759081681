import { UserAgentApplication } from "../../node_modules/msal";
import { Contact, getApiFetch, postApiFetch } from "./httpUtilty";
import { getCookie } from "./cookieUtilty";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import { UserService } from "./userService";

export const firebaseConfig = {
  apiKey: "AIzaSyDQPPW5y8QPnlvXCQl64RcxcsugXLi9VfM",
  authDomain: "truelight-912f2.firebaseapp.com",
  databaseURL: "https://truelight-912f2.firebaseio.com",
  projectId: "truelight-912f2",
  storageBucket: "truelight-912f2.appspot.com",
  messagingSenderId: "66867768920",
  appId: "1:66867768920:web:c76b4fb73bbb884dfea42d",
  measurementId: "G-7D35KVZGN2"
};

export interface LoginSession {
  userName: string;
  contactId: number;
  userRoles: number;
  token: string;
}

export function login(path: string) {
  var msalConfig = {
    auth: {
      clientId: "1eb8b8bb-37a5-4125-a0ae-ccf8925914c7",
      authority: "https://login.microsoftonline.com/common"
    }
  };
  var myMSALObj = new UserAgentApplication(msalConfig);
  var requestObj = {
    scopes: ["user.read"]
  };

  myMSALObj.handleRedirectCallback((error, response) => {
    if (error) {
      alert(JSON.stringify(error, null, 2));
    } else {
      if (response) {
        ;
      } else {
        alert(
          "failed to login due to log in service error, please retry a few minutes later"
        );
      }
    }
  });
  myMSALObj.loginRedirect(requestObj);
}

export function hasPermission(permissions: number): boolean {
  const loginSession = getLoginSession();
  if (loginSession) {
    return (loginSession.userRoles & permissions) > 0 ? true : false;
  }

  return false;
}

export function signUpUser(
  emailAddress: string,
  firstName: string,
  lastName: string,
  nickName: string,
  password: string
): Promise<string> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/SignUp?code=3RPqzBd6OhrlqXUabhRkRDkZF1fqVIvEtME4RaekYbqo1F/GGCtTjQ==";

  let newUser = {
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    nickName: nickName,
    password: password
  };

  return fetch(url, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify(newUser)
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (response1: any) {
      return response1;
    })
    .catch(function (err) {
      console.log(err);
    });
}

export function loginUser(
  emailAddress: string,
  password: string
): Promise<Contact> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/Login?code=csrDTXl9S1BtEu6vKdWvC9kUN1yba7FmhiKfZPEJ2L6gv6XHmRqz/A==";

  let newUser = {
    emailAddress: emailAddress,
    password: password
  };

  return fetch(url, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify(newUser)
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (response1: any) {
      return response1;
    })
    .catch(function (err) {
      console.log(err);
    });
}

export function isEmailAddressTaken(emailAddress: string): Promise<number> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/IsEmailAddressTaken?code=xHek3kFxrfq0KEjCL4420omoqEQ5py8gp388ETi8Q28N6W8xjK7qEg==&emailaddress=" +
    emailAddress;

  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response1: any) {
      return response1;
    })
    .catch(function (err) {
      console.log(err);
    });
}

export function resetPassword(emailAddress: string): Promise<boolean> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/ResetPassword?code=7av5Sp5e7D5DqeWtPuDmOiFQ98jpxHI/N8zp58EIKCUaSvtaBAwD8A==&emailaddress=" +
    emailAddress;

  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      emailaddress: emailAddress,
    },
    method: "GET"
  })
    .then(function (response) {
      return true;
    })
    .catch(function (err) {
      return false;
    });
}

export function updatePassword(
  password: string
): Promise<boolean> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdatePassword?code=xT4ZSGAKMB3tA55TR4g/2GFvVsEV9p5YIKA7C3QD1CxIAu2wjzbfNA==";

  let newUser = {
    password: password
  };

  return postApiFetch(url, JSON.stringify(newUser))
    .then(function (response) {
      return true;
    })
    .catch(function (err) {
      return false;
    });
}

export function getLoginSession(): LoginSession | undefined {
  const loginSessionStr = getCookie("loginSession");
  if (loginSessionStr) {
    const loginSession = JSON.parse(loginSessionStr);

    return loginSession ? loginSession as any : undefined;
  }

  return undefined;
}

export class loginHelper {
  static loginUser = () => {
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
          return true;
        },
        uiShown: function () {
          var loader = document.getElementById('loader');
          if (loader) {
            loader.style.display = 'none';
          }
        }
      },
      signInFlow: 'popup',
      signInSuccessUrl: "/",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      tosUrl: "",
      privacyPolicyUrl: ""
    };

    ui.start('#firebaseui-auth-container', uiConfig);
  }

  static getLoggedInUser = () => {
    return firebase.auth().currentUser;
  }

  static getUserToken = async () => {
    const currentUser = firebase.auth().currentUser;
    const idToken = await currentUser?.getIdToken(true);
    return idToken;
  }

  static logOffUser = () => {
    return firebase.auth().signOut();
  }

  static getUserRoles = async (currentUser: any) => {
    if (currentUser) {
      const email = currentUser.email;

      if (email) {
        const userRoles = await UserService.getUserRoles(email);
        return userRoles;
      }
    }

    return undefined;
  }
}
