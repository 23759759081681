import React from "react";
import firebase from "firebase/app";
import { loginHelper } from "../../utility/loginhelper";

const UserLoginView = () => {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(
    () => { loginHelper.loginUser(); }
  );

  return <></>
}

export default UserLoginView;
