import React, { useState } from "react";
import { BibleVolumns } from "./bibleContent/bible_volumns";
import BibleChapterView from "./BibleChapterView";
import BibleVolumnsView from "./BibleVolumnsView";
import BibleNotesView from "./notes/BibleNotesView";

const BibleView = () => {
    const [selectedVolumnIndex, setSelectedVolumn] = useState(0);
    const [selectedChapterNumber, setSelectedChapter] = useState(1);

    const renderChapters = () => {
        const chapters = [];
        const totalChapters = (BibleVolumns[selectedVolumnIndex][3]) as any;

        for (let index = 0; index < totalChapters; index++) {
            chapters.push(
                <span
                    style={ { display: "inline-block", padding: "2px", color: "blue", cursor: "pointer" } }
                    key={ index }
                    onClick={ () => {
                        setSelectedChapter(index + 1)
                    } }
                >
                    { index + 1 }
                </span>
            );
        }

        return chapters;
    }

    const onBibleVolumnSelected = (volumn: number) => {
        setSelectedVolumn(volumn);
    };

    return (
        <div style={ { display: "flex" } }>
            <div style={ { width: "50%", display: "inline-block", padding: "5px" } }>
                <BibleVolumnsView volumnIndex={ selectedVolumnIndex } onBibleVolumnSelected={ onBibleVolumnSelected } />
                <div style={ { display: "inline-block" } }>
                    { renderChapters() }
                </div>
                <BibleChapterView startVerseNumber={ 1 } endVerseNumber={ 9999 } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber }></BibleChapterView>
            </div>
            <div style={ { width: "50%", display: "inline-block" } }>
                <BibleNotesView volumnName={ BibleVolumns[selectedVolumnIndex][2] as string } chapterNumber={ selectedChapterNumber } />
            </div>
        </div>
    );
}

export default BibleView