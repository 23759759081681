import React from "react";

const BibleVerseView = (props: {
    chapterNumber: number,
    verseNumber: number,
    verse: string,
    hideVerseNumber: boolean
}) => {
    return (
        <div>
            { !props.hideVerseNumber && <span style={ { width: "25px", display: "inline-block" } }>{ props.verseNumber }</span> }
            { props.verse }
        </div>
    );
};

export default BibleVerseView;
