import React, { Fragment, useEffect, useState } from "react";
import { SongUtilityV2 } from "../../../utility/v2/songUtility";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { Song } from "../../../store/actions/songActions";

declare interface SongForShareViewProp {
  songTitle: string;
  index: number;
}

const SongForShareView = (props: SongForShareViewProp) => {
  const [song, setSong] = useState(undefined as any);
  const songs = useSelector((state: ReduxAppState) => { return state.song.songs });

  useEffect(() => {
    if (songs && songs.length > 0) {
      const song = songs.filter((x: Song) => x.title1 === props.songTitle);
      if (song && song.length > 0) {
        setSong(song[0]);
      }
      return;
    }

    SongUtilityV2.getSong(props.songTitle).then((result: any) => {
      setSong(result);
    });
  }, [props.songTitle]);

  return (<Fragment>
    { song && song.title1 &&
      <div key={ props.index }>{ (props.index + 1) + '. ' + song.title1 + ' ' }
        { song && song.youtubeUrls && song.youtubeUrls.map((item: any, index: number) => {
          return <a key={ index } target='_blank' style={ { color: 'blue' } } href={ item }>{ item }</a>
        }) }
      </div> }
  </Fragment>
  );
};

export default SongForShareView;
