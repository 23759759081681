import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { CommandButton, DefaultButton, TextField } from "@fluentui/react";
import { PlaylistUtility } from "../../../utility/v2/playlistUtility";
import { Playlist } from "../../../store/actions/songActions";

declare interface AddSongToPlaylistProp {
    songTitle: string;
    onClose: () => void;
}

const AddNewSongPlaylist = (props: {
    onPlaylistAddeded: () => void,
    onCancel: () => void
}) => {
    const [playlistName, setPlaylistName] = useState(undefined as any);
    const currentUser = useSelector((state: ReduxAppState) => { return state.userAccount.currentUser });
    const changePlaylistName = (event: any, value?: string) => {
        setPlaylistName(value ? value : "");
    };

    const savePlaylist = () => {
        if (playlistName) {
            const newPlaylist = {
                rowKey: playlistName,
                songTitles: []
            } as any;

            PlaylistUtility.UpsertPlaylist(newPlaylist);
            setPlaylistName(undefined);
            props.onPlaylistAddeded();
        }
        else {
            alert("请填写歌单名");
        }
    };

    return <div style={ { padding: 10 } }>
        <TextField
            label="歌单名"
            value={ playlistName }
            onChange={ changePlaylistName }
        />
        <div>
            <DefaultButton
                text={ "加新歌单" }
                onClick={ savePlaylist }
            ></DefaultButton>
            <DefaultButton
                styles={ { root: { marginLeft: 10 } } }
                text={ "退出" }
                onClick={ props.onCancel }
            ></DefaultButton>
        </div>
    </div>
}

const AddSongToPlaylistView = (props: AddSongToPlaylistProp) => {
    const [playlists, setPlaylists] = useState([] as any);
    const [selectedPlaylists, setSelectedPlaylists] = useState([] as any);
    const [addNewPlaylistEnabled, setAddNewPlaylistEnabled] = useState(false);

    const userProfile = useSelector((state: ReduxAppState) => { return state.userAccount.userProfile });
    const currentUser = useSelector((state: ReduxAppState) => { return state.userAccount.currentUser });

    const loadUserPlaylists = async () => {
        if (currentUser && currentUser.email && userProfile?.roles > 0) {
            const playlists = await PlaylistUtility.getPlaylists();

            if (playlists && playlists.length > 0) {
                const sortedPlaylists = playlists.sort((a: Playlist, b: Playlist) => {
                    return a.rowKey > b.rowKey ? 1 : -1;
                });

                setPlaylists(sortedPlaylists);
            }
            else {
                setPlaylists(playlists);
            }
        }
    }

    useEffect(
        () => {
            if (userProfile?.roles > 0) {
                loadUserPlaylists();
            }
        },
        [props.songTitle]
    );

    const selectPlaylist = (event: any) => {
        if (event.target.checked) {
            selectedPlaylists.push(event.target.value);
            setSelectedPlaylists(selectedPlaylists);
        } else {
            const newSelectedPlaylists = selectedPlaylists.filter(
                (item: any) => { return item !== event.target.value });
            setSelectedPlaylists(newSelectedPlaylists);
        }
    };

    const renderPlaylists = () => {
        const renderedPlaylist = [] as any;
        if (playlists) {
            for (let index = 0; index < playlists.length; index++) {
                const data1 = playlists[index];
                renderedPlaylist.push(
                    <div key={ index }>
                        <input type="checkbox" value={ data1["rowKey"] } onChange={ selectPlaylist }></input>{ data1["rowKey"] }
                    </div>
                );
            }
        }

        return renderedPlaylist;
    }

    const addSongToList = async () => {
        if (selectedPlaylists && selectedPlaylists.length > 0) {
            for (let index = 0; index < selectedPlaylists.length; index++) {
                const playlist = playlists.filter((item: any) => { return item.rowKey === selectedPlaylists[index] });

                if (playlist) {
                    const songTitles = playlist[0] && playlist[0].songTitles ? playlist[0].songTitles : [];
                    songTitles.push(props.songTitle);

                    const playlistData = {
                        ...playlist[0],
                        songTitles: songTitles
                    };

                    await PlaylistUtility.UpsertPlaylist(playlistData);
                }
            }

            props.onClose();
        } else {
            alert("请选好歌单再加。");
        }
    };

    const onAddPlaylistClicked = () => {
        setAddNewPlaylistEnabled(true);
    }

    const playlistAddedCallback = () => {
        setAddNewPlaylistEnabled(false);
        loadUserPlaylists();
    }

    const cancelAddPlaylistCallback = () => {
        setAddNewPlaylistEnabled(false);
    }

    return (<>
        { addNewPlaylistEnabled && <AddNewSongPlaylist onPlaylistAddeded={ playlistAddedCallback } onCancel={ cancelAddPlaylistCallback }></AddNewSongPlaylist> }
        { !addNewPlaylistEnabled && <div style={ { padding: 10 } }>
            <div style={ { width: "100%", height: '36px' } }>
                <CommandButton
                    style={ { color: 'blue', float: 'right' } }
                    text={ "加新歌单" }
                    onClick={ onAddPlaylistClicked }
                ></CommandButton>
            </div>
            <div style={ { width: "100%", height: '300px', overflow: "auto" } }>
                { renderPlaylists() }
            </div>
            <div style={ { height: '36px' } }>
                <DefaultButton
                    text={ "加入歌单" }
                    onClick={ addSongToList }
                ></DefaultButton>
                <DefaultButton
                    styles={ { root: { marginLeft: 10 } } }
                    text={ "退出" }
                    onClick={ props.onClose }
                ></DefaultButton>
            </div>
        </div> }
    </>
    );
}

export default AddSongToPlaylistView;