import React, { useState } from "react";
import styles from "./App.module.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import UserLogin from "./components/Account/UserLogin";
import LogOutUser from "./components/Account/LogOutUser";
import { Provider } from "react-redux";
import store from "./store";
import Header from "./components/header/Header";
import SongsView from "./components/praise/SongsView";
import UserPlaylistsView from "./components/praise/playlist/UserPlaylistsView";
import UsersView from "./components/user/UsersView";
import BibleView from "./components/bible/BibleView";

const App: React.FC = () => {
  const [rightPanelClass, setRightPanelClass] = useState<string>(
    styles.nomenurightpanel
  );

  return (
    <Provider store={ store }>
      <div className={ styles.app }>
        <Header />
        <div className={ styles.content }>
          <div className={ rightPanelClass }>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={ <SongsView /> }></Route>
                <Route path="/praisesongs" element={ <SongsView /> }></Route>
                <Route path="/userplaylists" element={ <UserPlaylistsView /> }></Route>
                <Route path="/login" element={ <Login /> }></Route>
                <Route path="/logoutuser" element={ <LogOutUser /> }></Route>
                <Route path="/userlogin" element={ <UserLogin /> }></Route>
                <Route path="/useradmin" element={ <UsersView /> }></Route>
                <Route path="/bible" element={ <BibleView /> }></Route>
              </Routes>
            </BrowserRouter>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </Provider>);
};

export default App;
