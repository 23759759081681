import React, { useEffect, useState } from "react";

const YoutubePlayerArea = (props: {
    youtubeUrls: string[]
}) => {
    const [youtubeData, setYoutubeData] = useState([]);
    const getYouTubeThumbnail = (youTubeUrl: string) => {
        let indexOfId = 0;
        if (youTubeUrl) {
            indexOfId = youTubeUrl.lastIndexOf("v=");
            if (indexOfId < 0) {
                indexOfId = youTubeUrl.lastIndexOf("/");
            } else {
                indexOfId += 1;
            }
        }

        if (indexOfId > 0 && youTubeUrl.length - 1 > indexOfId) {
            const youTubeId = youTubeUrl.substring(indexOfId + 1);
            return "https://img.youtube.com/vi/" + youTubeId + "/1.jpg";
        }

        return undefined;
    }

    useEffect(() => {
        const youtubeInfo = [];

        if (props.youtubeUrls) {
            for (let index = 0; index < props.youtubeUrls.length; index++) {

                const thumbnailUrl = getYouTubeThumbnail(props.youtubeUrls[index]);
                if (thumbnailUrl) {
                    youtubeInfo.push({
                        url: props.youtubeUrls[index],
                        thumbnail: thumbnailUrl
                    });
                }

            }
        }

        setYoutubeData(youtubeInfo as any);
    }, [props.youtubeUrls]);

    return <div style={ { width: "120px", height: "90px" } }>
        { youtubeData && youtubeData.map((item: any, index: number) => {
            return <a
                key={ index }
                href={ item.url }
                target="_blank"
            >
                { item.thumbnail && <img style={ {
                    borderRadius: "5px"
                } } src={ item.thumbnail }></img> }
            </a>
        }) }
    </div>
}

export default YoutubePlayerArea;
