import React, { useEffect, useState } from "react";
import { BibleNote, BibleNotesUtility } from "../../../utility/v2/bibleNotesUtility";
import { IconButton, TextField } from "@fluentui/react";
import BibleVolumnsView from "../BibleVolumnsView";
import { BibleContentUtility } from "../bibleContent/bibleContentUtility";
import BibleChapterSelectView from "./BibleChapterSelectView";
import { BibleVolumns } from "../bibleContent/bible_volumns";
import BibleVerseSelectView from "./BibleVerseSelectView";
import BibleVerseRangesEditWrapperView from "../../shared/bibleVerse/bibleVerseRange/BibleVerseRangesEditWrapperView";
import { BibleVerseRange } from "../../../store/actions/songActions";

const BibleNoteEditView = (props: {
    bibleNote: BibleNote,
    onClose: (note: BibleNote | undefined) => void
}) => {
    const [noteToEdit, setNoteToEdit] = useState<BibleNote>();
    const [noteToReturn, setNoteToReturn] = useState<BibleNote>();
    const [selectedVolumnIndex, setSelectedVolumnIndex] = useState(0);
    const [selectedChapterNumber, setSelectedChapterNumber] = useState(0);
    const [selectedStartVerseNumber, setSelectedStartVerseNumber] = useState(0);
    const [selectedEndVerseNumber, setSelectedEndVerseNumber] = useState(0);
    const [subject, setSubject] = useState("");
    const [note, setNote] = useState("");
    const [otherVerses, setOtherVerses] = useState<BibleVerseRange[]>([]);

    const saveNote = async () => {
        const newBibleNote = {
            ...props.bibleNote,
            subject: subject,
            note: note,
            mainVerse: {
                volumn: BibleVolumns[selectedVolumnIndex][2] as any,
                chapter: selectedChapterNumber,
                startVerse: selectedStartVerseNumber,
                endVerse: selectedEndVerseNumber,
            },
            otherVerses: otherVerses
        }

        await BibleNotesUtility.updateBibleNote(newBibleNote);
        setNoteToReturn(newBibleNote);
        alert("内容已保存");
    };

    useEffect(() => {
        if (props.bibleNote) {
            setNoteToEdit(props.bibleNote);
            setSubject(props.bibleNote.subject);
            setNote(props.bibleNote.note);
            setSelectedVolumnIndex(BibleContentUtility.getBibleVolumnIndex(props.bibleNote.mainVerse.volumn));
            setSelectedChapterNumber(props.bibleNote.mainVerse.chapter);
            setSelectedStartVerseNumber(props.bibleNote.mainVerse.startVerse);
            setSelectedEndVerseNumber(props.bibleNote.mainVerse.endVerse);
            setOtherVerses(props.bibleNote.otherVerses);
        }
    }, [props]);

    const onStartChapterSelected = (chapter: number) => {
        setSelectedChapterNumber(chapter);
    }

    const onStartVerseSelected = (verse: number) => {
        setSelectedStartVerseNumber(verse);
    }

    const onEndVerseSelected = (verse: number) => {
        setSelectedEndVerseNumber(verse);
    }

    const onSubjectChange = (_event: any, newValue: string | undefined) => {
        setSubject(newValue ?? "");
    }

    const onNoteChange = (_event: any, newValue: string | undefined) => {
        setNote(newValue ?? "");
    }

    const onOtherVersesChanges = (bibleVerses: BibleVerseRange[]) => {
        setOtherVerses([...bibleVerses]);
    }
    return (
        <>
            { noteToEdit &&
                <div style={ { padding: "10px", width: "600px" } }>
                    <IconButton onClick={ saveNote } label="Save" text="Save" iconProps={ { iconName: "Save" } }></IconButton>
                    <IconButton onClick={ () => props.onClose(noteToReturn) } label="Cancel" text="Canel" iconProps={ { iconName: "Cancel" } }></IconButton>
                    <div>
                        <div style={ { display: "inline-block", width: "120px", marginRight: "5px" } }>
                            <BibleVolumnsView volumnIndex={ selectedVolumnIndex } onBibleVolumnSelected={
                                (volumnInddex: number) => {
                                    setSelectedVolumnIndex(volumnInddex);
                                }
                            } />
                        </div>
                        <div style={ { display: "inline-block", width: "80px", marginRight: "5px" } }>
                            <BibleChapterSelectView label={ "章节" } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber } onChapterSelected={ onStartChapterSelected }></BibleChapterSelectView>
                        </div>
                        <div style={ { display: "inline-block", width: "80px", marginRight: "5px" } }>
                            <BibleVerseSelectView label={ "开始经文" } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber } verseNumber={ selectedStartVerseNumber } onVerseSelected={ onStartVerseSelected } />
                        </div>
                        <div style={ { display: "inline-block", width: "80px" } }>
                            <BibleVerseSelectView label={ "结束经文" } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber } verseNumber={ selectedEndVerseNumber } onVerseSelected={ onEndVerseSelected } />
                        </div>
                    </div>
                    <TextField label="标题" placeholder="请输入标题" onChange={ onSubjectChange } value={ subject }></TextField>
                    <TextField label="内容" placeholder="内容" onChange={ onNoteChange } multiline={ true } rows={ 20 } value={ note }></TextField>
                    <BibleVerseRangesEditWrapperView onChange={ onOtherVersesChanges } bibleVerses={ otherVerses } />
                </div>
            }
        </>
    );
};

export default BibleNoteEditView;
