import { DefaultButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../../store/reducers";
import { UserRolesEnum, UserService } from "../../../../utility/userService";
import { SongUtilityV2 } from "../../../../utility/v2/songUtility";
import { BibleVerseRange, Song } from "../../../../store/actions/songActions";
import SongBibleVersesEditView from "./SongBibleVersesEditView";

const SongEditView = (props: {
  songId: string,
  onUpate: (song: Song) => void,
  onClose: () => void
}) => {
  const [tags, setNewTags] = useState("");
  const [youtubeUrl1, setYoutubeUrl1] = useState("");
  const [youtubeUrl2, setYoutubeUrl2] = useState("");
  const [song, setSong] = useState<Song>();
  const [bibleVerseRanges, setBibleVerseRanges] = useState<BibleVerseRange[]>([]);

  const userRoles = useSelector((state: ReduxAppState) => {
    return state.userAccount.userProfile?.roles;
  });

  const refreshSong = async () => {
    const songData = await SongUtilityV2.getSong(props.songId);

    setSong(songData);
    setNewTags(songData.tags);
    if (songData.verses) {
      setBibleVerseRanges(songData.verses);
    }
    const youtubeUrls = songData.youtubeUrls;

    if (youtubeUrls) {
      if (youtubeUrls.length > 0) {
        setYoutubeUrl1(youtubeUrls[0]);
      };
      if (youtubeUrls.length > 1) {
        setYoutubeUrl2(youtubeUrls[1]);
      };
    }
  }

  useEffect(() => {
    if (props.songId) {
      refreshSong();
    }
  }, [props.songId]);

  const saveSong = async () => {
    const youtubeUrls = [];
    if (youtubeUrl1) {
      youtubeUrls.push(youtubeUrl1);
    }
    if (youtubeUrl2) {
      youtubeUrls.push(youtubeUrl2);
    }

    if (song) {
      let newSong = {
        ...song,
        youtubeUrls: youtubeUrls ?? [],
        tags: tags ?? "",
        verses: bibleVerseRanges ?? []
      };

      await SongUtilityV2.updateSong(newSong);
      props.onUpate(newSong);
      alert("update succeed.");
    }
  };

  const onSongBibleVersesChanges = (bibleVerseRange: BibleVerseRange[]) => {
    setBibleVerseRanges([...bibleVerseRange]);
  }

  return (
    <div style={ { width: "100%" } }>
      <div style={ { display: "inline-block", paddingLeft: 10, paddingRight: "10px" } }>
        <div style={ { marginTop: 10, marginBottom: 10 } }>
          唱法:
          <span>{ song?.sequence ? song.sequence : "N/A" }</span>
        </div>
        { UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Write) && (
          <>
            <div
              style={ {
                marginTop: 10
              } }
            >
              关键词:
            </div>
            <div>赞美真神, 颂扬主恩, 回应主爱, 祈祷感恩            </div>
            <div>悔改认罪, 跟随顺服, 与主亲近, 为主而活            </div>
            <div>就近十架, 彼此相爱, 复活盼望            </div>
            <div>
              <input
                size={ 50 }
                value={ tags }
                onChange={ event => {
                  setNewTags(event.target.value);
                } }
              ></input>
            </div>
            <div style={ { marginTop: 10, marginBottom: 10 } }>
              Youtube Url / Thumbnail
            </div>
            <input
              size={ 50 }
              style={ { marginBottom: "10px" } }
              value={ youtubeUrl1 }
              onChange={ event => {
                setYoutubeUrl1(event.target.value);
              } }
            ></input>
            <div>
              <input
                size={ 50 }
                value={ youtubeUrl2 }
                onChange={ event => {
                  setYoutubeUrl2(event.target.value);
                } }
              ></input>
            </div>
            <SongBibleVersesEditView onChange={ onSongBibleVersesChanges } songBibleVerses={ bibleVerseRanges } />
          </>
        ) }

        { UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Write) && (
          <div style={ { marginTop: 10, marginBottom: 10 } }>
            <DefaultButton
              text={ "保存" }
              onClick={ saveSong }
              disabled={ userRoles !== 1 && userRoles !== 4 }
            ></DefaultButton>
            <DefaultButton
              text={ "取消" }
              style={ { marginLeft: "10px" } }
              onClick={ () => {
                const result = window.confirm('确认要退出吗');
                if (result) {
                  props.onClose();
                }
              } }
              disabled={ userRoles !== 1 && userRoles !== 4 }
            ></DefaultButton>
          </div>
        ) }
      </div>
    </div>
  );
};

export default SongEditView;
