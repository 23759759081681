import React, { Fragment, useEffect } from "react";
import { loginHelper } from "../../utility/loginhelper";
import styles from "./Header.module.css";
import firebase from "firebase/app";
import NavBar from "./NavBar";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAppState } from "../../store/reducers";
import { setCurrentUser, setCurrentUserProfile } from "../../store/actions/userAccountActions";
import { UserService } from "../../utility/userService";

const Header = () => {
  const currentUser = useSelector((state: ReduxAppState) => { return state.userAccount.currentUser });
  const userProfile = useSelector((state: ReduxAppState) => { return state.userAccount.userProfile });

  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      dispatch(setCurrentUser(user));
      UserService.getUserProfile().then(
        (userProfile: any) => {
          dispatch(setCurrentUserProfile(userProfile));
        }
      );
    });
  }, []
  );

  return (
    <header className={ styles.appheader }>
      <div className={ styles.titleline }>
        <span className={ styles.title }>
          <a style={ { marginLeft: "10px" } } href="\">真光基督教会</a>
        </span>
        <div className={ styles.userInfo }>
          <span className={ styles.userinfoitem }>
            { !currentUser && (
              <a className={ styles.logoutbutton } href="/userlogin">
                <span>登錄</span>
              </a>
            ) }
            { currentUser && (
              <Fragment>
                <span>{ userProfile && userProfile.displayName } | </span>
                <a className={ styles.logoutbutton } onClick={ () => { loginHelper.logOffUser() } }>
                  退出
                </a>
              </Fragment>
            ) }
          </span>
        </div>
      </div>
      <NavBar />
    </header>
  );
}

export default Header;
