import { BibleVerseRange } from "../../store/actions/songActions";
import { getApiFetch, postApiFetch } from "../httpUtilty";

export declare interface BibleNote {
  id: string,
  partitionKey: string,
  userEmailAddress: string,
  subject: string,
  note: string,
  lastModified: string,
  mainVerse: BibleVerseRange,
  otherVerses: BibleVerseRange[]
}
export class BibleNotesUtility {
  static searchNotes = async (
    volumn: string,
    startChapter: number,
    endChapter: number,
    startVerse: number,
    endVerse: number
  ) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetBibleNotes?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&" +
      "volumn=" + volumn +
      "&startchapter=" + startChapter +
      "&endchapter=" + endChapter +
      "&startverse=" + startVerse +
      "&endverse=" + endVerse;

    const result = await getApiFetch(url);
    return await result.json();
  }

  static updateBibleNote = async (note: BibleNote) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/UpsertBibleNote?code=2cAlInNdPgZYN45hkWpkMXIQ_xMktd-2Rgl6cYIoRY5xAzFuLQAI_Q%3D%3D";

    await postApiFetch(url, JSON.stringify(note));
  }
}