
import { EasyslidesSchedule } from "../store/types/easyslidesTypes";
import { getApiFetch } from "./httpUtilty";

export class EasySlidesScheduleUtilty {
  static async getchedules(): Promise<EasyslidesSchedule[]> {
    const url = "https://truelightfuncservice.azurewebsites.net/api/ListEasyslidesSchedules?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";
    const response = await getApiFetch(url);
    const responseData = await response.json() as any;

    const result = [] as any;
    for (let index = 0; index < responseData.length; index++) {
      const songTitleStrs = responseData[index].scheduleSongs.split("],");
      const songTitles = [];
      for (let index1 = 0; index1 < songTitleStrs.length; index1++) {
        const songTitle = songTitleStrs[index1].replace(/\[/g, "").replace(/PTLCC,/g, "");
        if (songTitle) {
          songTitles.push(songTitle);
        }
      }

      const playlist = {
        rowKey: responseData[index].rowKey,
        userAccount: "system",
        songTitles: songTitles,
        updateTime: responseData[index].updateTime
      }
      result.push(playlist);
    }

    return result;
  }

  static async refreshSchedule(scheduleName: string) {
    const url = "https://truelightfuncservice.azurewebsites.net/api/RefreshEasyslidesSchedule?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&" +
      "schedulename=" + scheduleName;

    const response = await getApiFetch(url);
  }

  static async refreshSchedules() {
    const url = "https://truelightfuncservice.azurewebsites.net/api/RefreshEasyslidesSchedules?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";

    const response = await getApiFetch(url);
  }
}