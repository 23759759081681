import { BibleVolumns } from "./bible_volumns";

export class BibleContentUtility {
  static getBibleVolumnIndex = (
    volumnName: string
  ) => {
    for (let index = 0; index < BibleVolumns.length; index++) {
      if (BibleVolumns[index][2] === volumnName) {
        return index;
      }
    }
    return -1;
  }
}