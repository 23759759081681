import React, { useEffect, useState } from "react";
import { BibleVerseRange } from "../../../../store/actions/songActions";
import { CommandButton } from "@fluentui/react";
import BibleVerseRangeSelectView from "../BibleVerseRangeSelectView";
import { BibleVolumns } from "../../../bible/bibleContent/bible_volumns";
import { BibleVerseRangesEditView } from "./BibleVerseRangesEditView";

const BibleVerseRangesEditWrapperView = (props: {
    bibleVerses: BibleVerseRange[],
    onChange: (bibleVerseRanges: BibleVerseRange[]) => void
}) => {
    const [bibleVerseRanges, setBibleVerseRanges] = useState<BibleVerseRange[]>([]);
    const [newVerseRange, setNewVerseRange] = useState<BibleVerseRange>({
        volumn: "创世纪",
        chapter: 1,
        startVerse: 1,
        endVerse: 1
    });

    useEffect(() => {
        if (props.bibleVerses) {
            setBibleVerseRanges(props.bibleVerses);
        }
    }, [props]);

    const onVersesChange = (
        volumnIndex: number,
        chapterNumber: number,
        startVerseNumber: number,
        endVerseNumber: number
    ) => {
        const newVerseRange = {
            volumn: BibleVolumns[volumnIndex][2] as string,
            chapter: chapterNumber,
            startVerse: startVerseNumber,
            endVerse: endVerseNumber
        };

        setNewVerseRange(newVerseRange);
    }

    const addVerseRange = () => {
        const newBibleVerseRanges = [...bibleVerseRanges];
        newBibleVerseRanges.push(newVerseRange);
        setBibleVerseRanges(newBibleVerseRanges);
        props.onChange(newBibleVerseRanges);
    }

    const onDelete = (index: number) => {
        bibleVerseRanges.splice(index, 1);
        const newBibleVerseRanges = [...bibleVerseRanges];
        setBibleVerseRanges(newBibleVerseRanges);
        props.onChange(newBibleVerseRanges);
    }

    return (
        <div style={ { width: "100%" } }>
            { bibleVerseRanges && <BibleVerseRangesEditView enableDelete={ true } onDelete={ onDelete } songBibleVerses={ bibleVerseRanges } /> }
            <BibleVerseRangeSelectView onChange={ onVersesChange } />
            <CommandButton onClick={ addVerseRange } text="加经文"></CommandButton>
        </div>
    );
};

export default BibleVerseRangesEditWrapperView;
