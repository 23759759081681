import React from "react";
import { login } from "../utility/loginhelper";
import { Navigate } from "react-router-dom";

class Login extends React.Component {
  componentDidMount() {
    if (sessionStorage["loggedIn"] !== "true") {
      login("/");
    }
  }

  public render() {
    return (
      <div>{ sessionStorage["loggedIn"] === "true" && <Navigate to="/" /> }</div>
    );
  }
}

export default Login;
