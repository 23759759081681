import { Playlist } from "../../store/actions/songActions";
import { getApiFetch, postApiFetch } from "../httpUtilty";

export class PlaylistUtility {
  static UpsertPlaylist = async (playlist: Playlist) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/UpsertPlaylist?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";

    let titleStr = "";
    if (playlist.songTitles) {
      for (let index = 0; index < playlist.songTitles.length; index++) {
        if (index > 0) {
          titleStr += "||,||";
        }

        titleStr += playlist.songTitles[index];
      }
    }
    const newPlaylistToUpdate = {
      ...playlist,
      songTitles: titleStr
    }
    await postApiFetch(url, JSON.stringify(newPlaylistToUpdate));
  }

  static getPlaylists = async () => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetPlaylists?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";

    const response = await getApiFetch(url);
    const playListFromReponse = await response.json() as any;

    if (playListFromReponse) {
      playListFromReponse.forEach((element: any) => {
        let parsedSongTitles = [];
        if (element.songTitles) {
          parsedSongTitles = element.songTitles.split("||,||");
        }
        element.songTitles = parsedSongTitles;
      });
    }

    return playListFromReponse;
  }

  static getSharedPlaylists = async () => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetSharedPlaylists?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";

    const response = await getApiFetch(url);
    const playListFromReponse = await response.json() as any;

    if (playListFromReponse) {
      playListFromReponse.forEach((element: any) => {
        let parsedSongTitles = [];
        if (element.songTitles) {
          parsedSongTitles = element.songTitles.split("||,||");
        }
        element.songTitles = parsedSongTitles;
      });
    }

    return playListFromReponse;
  }

  static deletePlaylist = async (playlist: Playlist) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/DeletePlaylist?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";
    await postApiFetch(url, JSON.stringify(playlist));
  }
}