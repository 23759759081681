import { SET_CURRENTUSER, SET_CURRENTUSERPROFILE } from "../actions/userAccountActions";

export declare interface UserAccountState {
  currentUser: any;
  userProfile: any;
}

export const initialState: UserAccountState = {
  currentUser: undefined,
  userProfile: {
    displayName: "",
    roles: 0
  }
};

export const userAccountReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENTUSER:
      return Object.assign({}, state, {
        currentUser: action.user
      });
    case SET_CURRENTUSERPROFILE:
      return Object.assign({}, state, {
        userProfile: action.userProfile
      });
    default:
      return state;
  }
};
