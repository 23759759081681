import React from "react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../store/reducers";
import styles from "./NavBar.module.css";
import { UserRolesEnum, UserService } from "../../utility/userService";

const NavBar = () => {
  const userProfile = useSelector((state: ReduxAppState) => { return state.userAccount.userProfile });

  return (
    <div className={ styles.content }>
      <a href={ "/praisesongs" } className={ styles.link }>
        { "敬拜诗歌" }
      </a>
      { userProfile && userProfile.roles > 0 &&
        <a href={ "/userplaylists" } className={ styles.link }>
          { "播放列表" }
        </a> }
      { userProfile && UserService.AuthorizeUserRole(userProfile.roles, UserRolesEnum.Read) &&
        <a href={ "/bible" } className={ styles.link }>
          { "圣经学习" }
        </a> }
      { userProfile && UserService.AuthorizeUserRole(userProfile.roles, UserRolesEnum.Admin) &&
        <a href={ "/useradmin" } className={ styles.link }>
          { "用户管理" }
        </a> }
    </div>
  );

}

export default NavBar;
