import React, { useEffect, useState } from "react";
import { UserService } from "../../utility/userService";
import { ReduxAppState } from "../../store/reducers";
import { useSelector } from "react-redux";
import UserEditView from "./UserEditView";
import NewUserView from "./NewUserView";
import { IconButton } from "@fluentui/react";

const UsersView = () => {
    const [userProfiles, setUserProfiles] = useState(undefined as any);
    const [userUnderEditing, setUserUnderEditing] = useState("");
    const currentUser = useSelector((state: ReduxAppState) => { return state.userAccount.currentUser });

    const getAllUserProfiles = async () => {
        const userProfiles = await UserService.getUserProfiles();
        setUserProfiles(userProfiles);
    };

    useEffect(() => {
        if (currentUser) {
            getAllUserProfiles();
        }
    }, [currentUser]);

    const onDelete = async (userProfile: any) => {
        if (window.confirm("Are you sure to delete this user?")) {
            await UserService.deleteUserProfile(userProfile);
            getAllUserProfiles();
        }
    }

    return (
        <div style={ { height: "100%", marginTop: "15px", marginLeft: "10px" } }>
            <div>Role: 1: admin (can manage users), 6: read + write</div>
            <div>
                <span style={ { width: "250px", display: "inline-block", marginRight: "10px" } }>Email address</span> |
                <span style={ { width: "200px", display: "inline-block", marginLeft: "10px", marginRight: "10px" } }>Display name</span> |
                <span style={ { width: "50px", display: "inline-block", marginLeft: "10px", marginRight: "10px" } }> Roles </span> |
            </div>
            { userProfiles && userProfiles.map((item: any, index: number) => {
                if (item.rowKey === userUnderEditing) {
                    return <UserEditView userProfile={ item } onClose={ () => { setUserUnderEditing(""); getAllUserProfiles(); } } />
                } else {
                    return <div key={ index }>
                        <span style={ { width: "250px", display: "inline-block", marginRight: "10px" } }>{ item.rowKey }</span> |
                        <span style={ { width: "200px", display: "inline-block", marginLeft: "10px", marginRight: "10px" } }>{ item.displayName }</span> |
                        <span style={ { width: "50px", display: "inline-block", marginLeft: "10px", marginRight: "10px" } }> { item.roles } </span> |
                        <IconButton onClick={ () => { setUserUnderEditing(item.rowKey); } } iconProps={ { iconName: "Edit" } }></IconButton>
                        <IconButton onClick={ () => { onDelete(item) } } iconProps={ { iconName: "Delete" } }></IconButton>
                    </div>
                }
            }) }
            <NewUserView onClose={ () => { getAllUserProfiles(); } } />
        </div>

    );
};

export default UsersView;
