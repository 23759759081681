import React, { Fragment } from "react";
import SongForShareView from "./SongForShareView";
import SongView from "./SongView";

declare interface UserPlaylistSongsViewProp {
  songTitles: string[];
  isCurrentUser?: boolean;
}

const UserPlaylistSongsView = (props: UserPlaylistSongsViewProp) => {
  const renderSongs = () => {
    let renderedSongs = [];

    for (var index in props.songTitles) {
      const songTitle = props.songTitles[index];
      renderedSongs.push(
        <SongView
          key={ index }
          songTitle={ songTitle }
          enableDelete={ props.isCurrentUser }
        ></SongView>
      );
    }

    return renderedSongs;
  };

  const renderSongsForSharingPurpose = () => {
    let result = [];

    if (props.songTitles && props.songTitles.length > 0) {
      for (let index = 0; index < props.songTitles.length; index++) {
        const songTitle = props.songTitles[index];
        result.push(
          <SongForShareView
            key={ index }
            songTitle={ songTitle }
            index={ index }
          ></SongForShareView>
        );
      }
    }
    return result;
  }

  return (
    <Fragment>
      <div style={ { height: "100%" } }>
        <div style={ { overflow: "auto", marginLeft: 15 } }>{ renderSongsForSharingPurpose() }</div>
        <div style={ { overflow: "auto" } }>{ renderSongs() }</div>
      </div>
    </Fragment>
  );
};

export default UserPlaylistSongsView;
