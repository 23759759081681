import { SET_ACTIVEUSERPLAYLIST, SET_ACTIVESONG, Song, SET_USERPLAYLISTS, Playlist, SET_SONGS, SET_SONGSEARCHRESULT } from "../actions/songActions";

export declare interface SongState {
  songs: Song[];
  activeSong: Song;
  activeUserPlaylist: Playlist;
  userPlaylists: Playlist[];
  songSearchResult: string[];
}

export const initialState: SongState = {
  songs: [] as any,
  activeSong: undefined as any,
  activeUserPlaylist: undefined as any,
  userPlaylists: [] as any,
  songSearchResult: [] as any
};

export const songsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ACTIVESONG:
      return Object.assign({}, state, {
        activeSong: action.song
      });
    case SET_SONGS:
      return Object.assign({}, state, {
        songs: action.songs
      });
    case SET_SONGSEARCHRESULT:
      return Object.assign({}, state, {
        songSearchResult: action.songSearchResult
      });
    case SET_ACTIVEUSERPLAYLIST:
      return Object.assign({}, state, {
        activeUserPlaylist: action.activeUserPlaylist
      });
    case SET_USERPLAYLISTS:
      return Object.assign({}, state, {
        userPlaylists: action.userPlaylists
      });
    default:
      return state;
  }
};
