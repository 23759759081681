import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

export const UserPlaylistCommandBarView = (props: { onRefresh: () => void, url: string, playlist: any, isCurrentUser: boolean, setShareStatus: (playlist: any) => void }) => {
  const _items: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        if (props.onRefresh) {
          props.onRefresh();
        }
      },
    },
    {
      key: 'play',
      text: 'Play',
      iconProps: { iconName: 'Play' },
      onClick: () => {
        window.open(props.url, '_blank');
      },
    },
    {
      key: 'share',
      text: props.playlist.shared === 1 ? 'Stop Share' : 'Share',
      disabled: !props.isCurrentUser,
      iconProps: { iconName: 'Share' },
      onClick: () => {
        props.setShareStatus(props.playlist);
      },
    },
  ];

  return (
    <CommandBar style={ { display: "inline-block" } }
      items={ _items }
    />
  );
};

