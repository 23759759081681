import React, { useState } from "react";
import { UserService } from "../../utility/userService";
import { DefaultButton, Label, TextField } from "@fluentui/react";

const NewUserView = (props: {
    onClose: () => void;
}) => {
    const [emailAddress, setEmailAddress] = useState("");
    const [newRoles, setNewRoles] = useState(0);
    const [newDisplayName, setNewDisplayName] = useState("");

    const saveUserProfile = async () => {
        const newUserProfile = {
            partitionKey: "default",
            rowKey: emailAddress,
            roles: newRoles,
            displayName: newDisplayName
        };

        await UserService.upsertUserProfile(newUserProfile);
        setEmailAddress("");
        setNewRoles(0);
        setNewDisplayName("");
        props.onClose();
    }

    const onEmailAddressChange =
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setEmailAddress(newValue || '');
        };
    const onDisplayNameChange =
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setNewDisplayName(newValue || '');
        };
    const onRolesChange =
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setNewRoles(parseInt(newValue ?? "0"));
        };
    return (
        <div style={ { height: "100%", marginTop: "15px", maxWidth: "200px" } }>
            <Label>Add new user</Label>
            <TextField label="Email address" onChange={ onEmailAddressChange }></TextField>
            <TextField label="Display name" onChange={ onDisplayNameChange }></TextField>
            <TextField label="Roles" onChange={ onRolesChange }></TextField>
            <div style={ { marginTop: "15px" } }>
                <DefaultButton text="Save" onClick={ saveUserProfile } />
            </div>
        </div>
    );
};

export default NewUserView;
