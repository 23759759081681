import React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { EnglishBible } from "../../bible/bibleContent/bible_english";

const BibleVerseSelectView = (props: {
    volumnIndex: number,
    chapterNumber: number,
    verseNumber: number,
    label: string,
    onVerseSelected: (chapterNumber: number) => void
}
) => {
    const totalVerses = EnglishBible.filter(x => x.volumn === props.volumnIndex + 1 && x.chapter === props.chapterNumber).length;

    const populateVerseOptions = (): IDropdownOption[] => {
        const options = [];
        for (let index = 0; index < totalVerses; index++) {
            options.push(
                {
                    key: index + 1,
                    text: (index + 1).toString()
                } as IDropdownOption
            );
        }

        return options;
    }

    const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        if (option) {
            props.onVerseSelected(option.key as number);
        }
    };

    return <Dropdown
        label={ props.label }
        selectedKey={ props.verseNumber }
        options={ populateVerseOptions() }
        onChange={ onChange }
    />;
}


export default BibleVerseSelectView;
