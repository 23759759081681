import React from "react";
import styles from "./Pagination.module.css";

interface PaginationProperty {
  top: number;
  skip: number;
  totalItems: number;
  selectPage: Function;
}

class Pagination extends React.Component<PaginationProperty> {
  selectPage(page: number) {
    const skip = this.props.top * (page - 1);
    this.props.selectPage(skip);
  }

  public render() {
    const totalPages = Math.ceil(this.props.totalItems / this.props.top);
    const currentPage = this.props.skip / this.props.top + 1;
    const startPage = currentPage - 3 > 0 ? currentPage - 3 : 1;
    const endPage = currentPage + 3 < totalPages ? currentPage + 3 : totalPages;
    const showFirst = startPage > 1 ? true : false;
    const showLast = endPage < totalPages ? true : false;

    const pageTags = [];
    if (totalPages > 1) {
      for (let index = startPage; index <= endPage; index++) {
        if (currentPage === index) {
          pageTags.push(
            <div key={ index }
              className={ styles.activepagetag }
              onClick={ () => this.selectPage(index) }
            >
              { index }
            </div>
          );
        } else {
          pageTags.push(
            <div key={ index }
              className={ styles.pagetag }
              onClick={ () => this.selectPage(index) }
            >
              { index }
            </div>
          );
        }
      }
    }

    return (
      <div>
        { showFirst && (
          <div onClick={ () => this.selectPage(1) } className={ styles.first }>
            { "1..." }
          </div>
        ) }
        { pageTags }
        { showLast && (
          <div
            onClick={ () => this.selectPage(totalPages) }
            className={ styles.last }
          >
            { "..." + totalPages }
          </div>
        ) }
      </div>
    );
  }
}

export default Pagination;
