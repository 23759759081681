import { getApiFetch } from "./httpUtilty";

export function searchSongs(query: string): Promise<string[]> {
  let url =
    "https://truelightindexservice.azurewebsites.net/api/SearchIndex?code=s6B07e8_oW654UqtEAeEUIgP9U_nYTtAqksHNaE2I6IRAzFuS1mELQ%3D%3D";

  if (query && query.length > 0) {
    url += "&q=" + query;
  }

  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response1: string[]) {
      return response1;
    });
}

export function refreshEasyslides() {
  const url = "https://truelightfuncservice.azurewebsites.net/api/CopyEasySlidesSongsToAzure?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";
  getApiFetch(url);
}
