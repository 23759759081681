import React, { useState } from "react";
import BibleVerseSelectView from "./BibleVerseSelectView";
import BibleVolumnsView from "./BibleVolumnsView";
import BibleChapterSelectView from "./BibleChapterSelectView";

const BibleVerseRangeSelectView = (props: {
    onChange: (
        volumnIndex: number,
        chapterNumber: number,
        startVerseNumber: number,
        endVerseNumber: number
    ) => void
}) => {
    const [selectedVolumnIndex, setSelectedVolumnIndex] = useState(0);
    const [selectedChapterNumber, setSelectedChapterNumber] = useState(1);
    const [selectedStartVerseNumber, setSelectedStartVerseNumber] = useState(1);
    const [selectedEndVerseNumber, setSelectedEndVerseNumber] = useState(1);

    const onVolumnSelected = (volumnIndex: number) => {
        setSelectedVolumnIndex(volumnIndex);
        props.onChange(volumnIndex, selectedChapterNumber, selectedStartVerseNumber, selectedEndVerseNumber);
    }

    const onChapterSelected = (chapter: number) => {
        setSelectedChapterNumber(chapter);
        props.onChange(selectedVolumnIndex, chapter, selectedStartVerseNumber, selectedEndVerseNumber);
    }

    const onStartVerseSelected = (verse: number) => {
        setSelectedStartVerseNumber(verse);
        props.onChange(selectedVolumnIndex, selectedChapterNumber, verse, selectedEndVerseNumber);
    }

    const onEndVerseSelected = (verse: number) => {
        setSelectedEndVerseNumber(verse);
        props.onChange(selectedVolumnIndex, selectedChapterNumber, selectedStartVerseNumber, verse);
    }

    return (
        <div style={ { padding: "10px", width: "600px" } }>
            <div style={ { display: "inline-block", width: "120px", marginRight: "5px" } }>
                <BibleVolumnsView volumnIndex={ selectedVolumnIndex } onBibleVolumnSelected={ onVolumnSelected } />
            </div>
            <div style={ { display: "inline-block", width: "80px", marginRight: "5px" } }>
                <BibleChapterSelectView label={ "章节" } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber } onChapterSelected={ onChapterSelected } />
            </div>
            <div style={ { display: "inline-block", width: "80px", marginRight: "5px" } }>
                <BibleVerseSelectView label={ "开始经文" } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber } verseNumber={ selectedStartVerseNumber } onVerseSelected={ onStartVerseSelected } />
            </div>
            <div style={ { display: "inline-block", width: "80px" } }>
                <BibleVerseSelectView label={ "结束经文" } volumnIndex={ selectedVolumnIndex } chapterNumber={ selectedChapterNumber } verseNumber={ selectedEndVerseNumber } onVerseSelected={ onEndVerseSelected } />
            </div>
        </div>
    );
};

export default BibleVerseRangeSelectView;
