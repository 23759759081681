import { loginHelper } from "./loginhelper";

export interface TruelightEvent {
  eventId: number;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  eventType: string;
  externalAudioUrls: string;
  externalVideoUrls: string;
  description: string;
  published: boolean;
  dateTimeCreated: string;
  createdBy: Number;
  createdByName: string;
  lastModifiedBy: Number;
  dateTimeLastModified: string;
  lastModifiedByName: string;
  topEventId: number;
  parentEventId: number;
  totalRows: number;
}

export interface Worship extends TruelightEvent {
  weeklyBibleVerseMemorization: string;
  sermonTitle: string;
  bibleVerseTitle: string;
  bibleVerse: string;
  agenda: string;
}

export interface EventDocument {
  eventId: number;
  url: string;
  description: string;
  fileName: string;
  eventDocumentTypeId: number;
}

export interface ChurchInfo {
  name: string;
  nameInChinese: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  url: string;
}

export interface FollowshipGroup {
  followshipGroupId: number;
  groupName: string;
  leaderName: string;
  phoneNumber: string;
}

export interface Contact {
  emailAddress: string;
  nickName: string;
  firstName: string;
  lastName: string;
  alternativeName: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  baptized: boolean;
  contactId: number;
  loginToken: string;
}

export interface CompactContact {
  nickName: string;
  contactId: number;
}

let idToken: string | undefined = "";
let idTokenCreateTime = new Date().getTime();
export const prepareDefaultApiHeader = async () => {
  const loginSession = loginHelper.getLoggedInUser();
  // cache token for one minute
  var currentTime = new Date().getTime();
  var diffMs = currentTime - idTokenCreateTime;
  if (!idToken || idToken.length === 0 || diffMs > 1000 * 60) {
    idToken = await loginHelper.getUserToken();
    idTokenCreateTime = new Date().getTime();
  }
  if (loginSession) {
    return {
      "Content-Type": "application/json",
      loginToken: idToken
    }
  } else {
    return {
      "Content-Type": "application/json",
    }
  }
}

export const getApiFetch = async (url: string) => {
  const headers = await prepareDefaultApiHeader() as any;
  return fetch(url, {
    headers: headers,
    method: "GET"
  })
}

export const postApiFetch = async (url: string, body: string) => {
  const headers = await prepareDefaultApiHeader() as any;
  return fetch(url, {
    headers: headers,
    method: "POST",
    body: body,
  })
}

export const deleteApiFetch = async (url: string, body: string) => {
  const headers = await prepareDefaultApiHeader() as any;
  return fetch(url, {
    headers: headers,
    method: "DELETE",
    body: body,
  })
}

export function searchEvents(
  eventType: string,
  published?: boolean,
  createdBy?: string,
  topEventId?: number,
  parentEventId?: number,
  top?: number,
  skip?: number
): Promise<TruelightEvent[]> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/SearchEvent?code=Qb9v1zCLauayfaul9eu4dh3xJ2Ix0hL4jEzILKl3BnkdK15bjpOnsQ==&eventType=" +
    eventType;

  if (published === true || published === false) {
    const publishedStr = published === true ? "true" : "false";
    url += "&published=" + publishedStr;
  }

  if (createdBy) {
    url += "&createdby=" + createdBy;
  }

  if (topEventId) {
    url += "&topeventid=" + topEventId;
  } else {
    url += "&topeventid=0";
  }

  if (parentEventId) {
    url += "&parentEventId=" + parentEventId;
  }

  if (top) {
    url += "&top=" + top;
  }

  if (skip) {
    url += "&skip=" + skip;
  }

  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response: TruelightEvent[]) {
      return response;
    });
}

export function getEvent(eventId: number): Promise<TruelightEvent> {
  const url =
    "https://truelightfunction.azurewebsites.net/api/GetEvent?code=Nk35ScXqZch8QbPVDwls9YzJgB5dSyLego1ZC4D/WwmGaJUqp4WXbQ==&eventid=" +
    eventId;

  return getApiFetch(url)
    .then(function (response: Response) {
      if (response) {
        return response.json();
      }
    })
    .then(function (response: TruelightEvent) {
      return response;
    });
}

export function addEvent(
  eventType: string,
  eventName: string,
  eventStartDate: string,
  eventEndDate: string,
  description: string,
  published: boolean,
  topEventId: number,
  parentEventId: number
): Promise<string> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/AddEvent?code=Wh8VvXCb40CadzAajkmfRxQAkpa8ZvLEgmPRG/SAxGErYYxj4QTRQQ==";

  let newEvent = {
    EventType: eventType,
    EventName: eventName,
    EventStartDate: eventStartDate,
    EventEndDate: eventEndDate,
    description: description,
    published: published,
    topEventId: topEventId,
    parentEventId: parentEventId
  };

  return postApiFetch(url, JSON.stringify(newEvent))
    .then(function (response) {
      return response.json();
    })
    .then(function (response1: any) {
      return response1;
    })
    .catch(function (err) {
      console.log(err);
    });
}

export function updateTruelightEvent(
  truelightEvent: TruelightEvent
): Promise<string> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdateEvent?code=EnmaEzA8mcGU6kmM7HvVnTJm2VdQ5skOXuCyRCr/c9IK4XtoI96OMg==";

  return postApiFetch(url, JSON.stringify(truelightEvent))
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      console.log(err);
    });
}

export function getWorship(eventId: number) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetWorship?code=jU4jmAS5nh7xFFRyyp9oIGavnUOa/DPHvnmQd2v901ZJFEaC8IQsPA==&eventid=" +
    eventId;
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      console.log("get worship " + err);
    });
}

export function updateWorship(worship: Worship) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdateWorship?code=9oF/KSraIbXGTP9F60SziDhIuc68LRPNF9G5sh4Ozq0tS0SpkT68iw==";
  return postApiFetch(url, JSON.stringify(worship)
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (response1: any) {
      return response1;
    })
    .catch(function (err) {
      console.log(err);
    });
}

export function searchEventDocuments(
  eventId: number
): Promise<EventDocument[]> {
  const url =
    "https://truelightfunction.azurewebsites.net/api/GetEventDocuments?code=EwuO5N3/T6lXnhlez1kRM9MrQ18EwvyYTmW02n2kz10DzugWnfdlQw==&eventId=" +
    eventId;

  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response: EventDocument[]) {
      return response;
    });
}

export function saveEventDocument(
  eventId: number,
  fileContent: string,
  fileName: string,
  description: string,
  eventDocumentTypeId: number
): Promise<any> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/AddEventDocument?code=KzEc95IRR8HmsBCwsY96iDW0czQdabk7xAqgsgzhem/y8NyBn2QHPQ==";

  const indexOfHeader = fileContent.lastIndexOf(",");
  if (indexOfHeader > 0) {
    fileContent = fileContent.substring(indexOfHeader + 1);
  }

  let eventDocument = {
    EventId: eventId,
    Description: description,
    DocumentOriginalFileName: fileName,
    DocumentContent: fileContent,
    EventDocumentTypeId: eventDocumentTypeId
  };

  return postApiFetch(url, JSON.stringify(eventDocument)
  ).catch(function (err) {
    console.log(err);
  });
}

export function deleteEventDocument(
  eventId: number,
  documentUrl: string
): Promise<any> {
  let url =
    "https://truelightfunction.azurewebsites.net/api/DeleteEventDocument?code=p0/YFlg2BnExM7Md2CB6DMez9sHuGj1/ifHWfTqeKXfI58mwR58ODA==";
  return deleteApiFetch(url, JSON.stringify({
    eventId: eventId,
    url: documentUrl
  })
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error && error.status === 401) {
        alert("Sorry, you don't have permission to delete this file");
      }
    });
}

export function getChurch() {
  let url =
    "https://truelightfunction.azurewebsites.net/api/Church?code=ED3CezYifDR2yHE8gOKnCXSbSB3Mc/vCaLLyvddtKjFacia0u1WA5w==";
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get church " + err);
    });
}

export function updateChurch(churchInfo: ChurchInfo) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdateChurch?code=HRHodysgtVjefjsp8UBId/auif2/oo0Ge/IqlAs3xpaMiCOBIAaiaA==";
  return postApiFetch(url, JSON.stringify(churchInfo)
  ).catch(function (err) {
    console.log(err);
  });
}

export function getFollowshipGroups() {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetFollowshipGroups?code=YjB2eRDig/3g8nrKNJVmQtNE9p4t3tLc13DKaUW1F3A1CWIZmCKjaQ==";
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get followship groups" + err);
    });
}

export function addFollowshipGroup(followshipGroup: FollowshipGroup) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/AddFollowshipGroup?code=Ru1WrPkEZkeCaIG7nbfEz1CJFHV91dfWI0AfwoDLxyFLbXsdoIJ1kg==";
  return postApiFetch(url, JSON.stringify(followshipGroup)
  )
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("add followship groups" + err);
    });
}

export function updateFollowshipGroup(followshipGroup: FollowshipGroup) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdateFollowshipGroup?code=GX/RL3O8IyBaN3Mase2XvcmthA4yktjXFXUrHUKmmagvFgJeQKBwuw==";
  return postApiFetch(url, JSON.stringify(followshipGroup)
  ).catch(function (err) {
    console.log(err);
  });
}

export function getLoggedInContact() {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetLoggedinContact?code=Oemm9njH0tsgUcLZ5uGyNuFo1dxzfiwXmkafHysXa7l9l/r7uc9PrQ==";
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get loggedin contact " + err);
    });
}

export function getLoggedInUserRoles() {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetUserRoles?code=QLsGLp1EhAfuPZTjocyPtx5vQ7zaKGUpI6Ih6jnD4DdsMtmBclakug==";
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get loggedin contact roles " + err);
    });
}

export function getContacts() {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetContacts?code=9DLJOjrSkOdDW9zw6OnczRpNWR//fqU0/EffkiXQmTsWaXvPaKK0yg==";
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get contacts" + err);
    });
}

export function getCompactContacts() {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetCompactContacts?code=aXjCCNk2cPasvAHFomDVb0eYRI6pvNCE/X7woVBVyzbDV0GBWFfYyg==";
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get compact contacts" + err);
    });
}

export function updateContact(contact: Contact) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdateContact?code=i25JDb89k4vgcEYgVtbsQSrfRraW9zyPPnl5woHoaybHacfpG0ovaA==";
  return postApiFetch(url, JSON.stringify(contact)
  ).catch(function (err) {
    console.log(err);
  });
}

export function addNewContact(contact: Contact) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/AddContact?code=s1aJpsxVx0JXUyZQeLlrlcYaOhTQPQpfSvFW5rF5KKKahhDorbtg4w==";
  return postApiFetch(url, JSON.stringify(contact)
  ).catch(function (err) {
    console.log(err);
  });
}

export function getContactRoles(contactId: number) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/GetContactRoles?code=eRAKWjnadL5q0FURKfmRScYTbE/LX71uVMrfFzeA191Xck1xFy7HTw==&contactid=" +
    contactId;
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("get contact roles" + err);
    });
}

export function updateContactRoles(contactId: number, roles: number) {
  let url =
    "https://truelightfunction.azurewebsites.net/api/UpdateContactRoles?code=NniNEaLVCJlWJW1KNogKtptjJHP4CL25LpOoHPXUokcZy84VkfJkNQ==&contactid=" +
    contactId +
    "&roles=" +
    roles;
  return getApiFetch(url)
    .then(function (response) {
      return response.json();
    })
    .catch(function (err) {
      alert("update contact roles" + err);
    });
}
