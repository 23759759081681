import React, { useEffect, useState } from "react";
import { BibleNote, BibleNotesUtility } from "../../../utility/v2/bibleNotesUtility";
import { Checkbox, IconButton, Modal } from "@fluentui/react";
import BibleNoteEditView from "./BibleNoteEditView";
import { v4 } from "uuid"
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import BibleChapterView from "../BibleChapterView";
import { BibleContentUtility } from "../bibleContent/bibleContentUtility";
import { SongBibleVersesView } from "../../praise/song/SongBibleVersesView";

const BibleNotesView = (props: {
    volumnName: string,
    chapterNumber: number
}) => {
    const [bibleNotes, setBibleNotes] = useState<BibleNote[]>([] as any);
    const [noteToEdit, setNoteToEdit] = useState<BibleNote>();
    const [noteToShowVerse, setNoteToShowVerse] = useState<BibleNote>();
    const [displayAllChecked, setDisplayAllChecked] = useState(false);
    const currentUser = useSelector((state: ReduxAppState) => {
        return state.userAccount.currentUser;
    });

    const refreshNotes = async () => {
        if (props.volumnName && props.chapterNumber > 0) {
            const notes = await BibleNotesUtility.searchNotes(
                props.volumnName, displayAllChecked ? 1 : props.chapterNumber,
                displayAllChecked ? 999999 : props.chapterNumber,
                1,
                9999);
            setBibleNotes(notes);
        }
    };

    useEffect(() => {
        if (currentUser) {
            refreshNotes();
        }
    }, [props, currentUser, displayAllChecked]);

    const createNewNote = () => {
        const uuid = v4();
        return {
            id: uuid,
            partitionKey: "",
            userEmailAddress: "",
            subject: "",
            note: "",
            lastModified: "",
            mainVerse: {
                volumn: "创世纪",
                chapter: 1,
                startVerse: 1,
                endVerse: 1
            },
            otherVerses: []
        }
    }

    const updateBibleNotes = (updatedNote: BibleNote | undefined) => {
        if (updatedNote && bibleNotes && bibleNotes.length >= 1) {
            for (let index = 0; index < bibleNotes.length; index++) {
                if (bibleNotes[index].id === updatedNote.id) {
                    bibleNotes[index] = updatedNote;
                    setBibleNotes([
                        ...bibleNotes
                    ]);
                    return;
                }
            }
            bibleNotes.push(updatedNote);
            setBibleNotes([
                ...bibleNotes
            ]);
        }
    }

    const onDisplayAllChecked = (_event: any, isChecked?: boolean) => {
        setDisplayAllChecked(isChecked ?? false);
    }
    return (
        <>
            <div>{ "圣经笔记" }
                <IconButton
                    onClick={ () => {
                        setNoteToEdit(createNewNote())
                    } }
                    label="New" text="New"
                    iconProps={ { iconName: "QuickNote" } } />

                <Checkbox onChange={ onDisplayAllChecked } label="显示本卷所有笔记" title="显示本卷所有笔记" />
            </div>
            { bibleNotes && bibleNotes.map((item, index: number) => {
                return <div style={ { marginTop: "5px" } } key={ index }>
                    <div style={ { float: "right" } }>
                        <span style={ { marginRight: "5px", color: "#0078d4", fontSize: "12px", cursor: "pointer" } }
                            onClick={ () => setNoteToShowVerse(item) }>
                            { item.mainVerse.chapter + ":" + item.mainVerse.startVerse + " - " + item.mainVerse.endVerse }
                        </span>
                        <span style={ { fontSize: "12px", color: "gray" } }>{ item.lastModified }</span>
                    </div>
                    <div style={ { display: "inline-block", width: "100%", fontSize: "16px" } }>
                        <span style={ { color: "#0078d4", cursor: "pointer" } } onClick={ () => { setNoteToEdit(item) } } >
                            { item.subject }
                        </span>
                    </div>
                    <pre style={ { margin: "5px 0" } }>{ item.note }</pre>
                    <div><SongBibleVersesView songBibleVerses={ item.otherVerses } /></div>
                </div>
            }) }
            { noteToEdit &&
                <Modal isOpen={ true }>
                    <BibleNoteEditView onClose={ (note: any) => { updateBibleNotes(note); setNoteToEdit(undefined) } } bibleNote={ noteToEdit }></BibleNoteEditView>
                </Modal> }

            { noteToShowVerse &&
                <Modal isOpen={ true } onDismiss={ () => { setNoteToShowVerse(undefined) } }>
                    <div style={ { padding: "15px" } }>
                        <BibleChapterView
                            volumnIndex={ BibleContentUtility.getBibleVolumnIndex(noteToShowVerse.mainVerse.volumn) }
                            chapterNumber={ noteToShowVerse.mainVerse.chapter }
                            startVerseNumber={ noteToShowVerse.mainVerse.startVerse }
                            endVerseNumber={ noteToShowVerse.mainVerse.endVerse } />
                    </div>
                </Modal> }
        </>
    );
};

export default BibleNotesView;
