import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { setCookie } from "../../utility/cookieUtilty";

class LogOutUser extends React.Component {

  public render() {
    setCookie('loginSession', '', 1);

    return (
      <Fragment>
        <Navigate to={ "/" }></Navigate>
      </Fragment>
    );
  }
}

export default LogOutUser;
