import { Song } from "../../store/actions/songActions";
import { getApiFetch, postApiFetch } from "../httpUtilty";

export class SongUtilityV2 {
  static getAllSongs = async () => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetAllSongs?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";

    return getApiFetch(url)
      .then(function (response: any) {
        return response.json();
      })
      .then(function (response: Song[]) {
        return response;
      });
  }

  static getSong = async (songTitle: string) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/GetSong?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D&songtitle=" + songTitle;
    const response = await getApiFetch(url);
    return response.json();
  }

  static searchSongs = async (query: string) => {
    let url = "https://truelightindexservice.azurewebsites.net/api/SearchIndex?code=s6B07e8_oW654UqtEAeEUIgP9U_nYTtAqksHNaE2I6IRAzFuS1mELQ%3D%3D" +
      "&q=" + query;

    const result = await getApiFetch(url);
    return await result.json();
  }

  static updateSong = async (song: Song) => {
    let url = "https://truelightfuncservice.azurewebsites.net/api/UpsertSong?code=wMcRTpKdJliOpTbeRKYvF3RjDqi8PYXtoDyKKcHUR3yJAzFuLgpuBA%3D%3D";

    await postApiFetch(url, JSON.stringify(song));
  }
}