export const BibleVolumns = [
    ["创", "Genesis", "创世纪", 50],
    ["出", "Exodus", "出埃及记", 40],
    ["利", "Leviticus", "利未记", 27],
    ["民", "Numbers", "民数记", 36],
    ["申", "Deuteronomy", "申命记", 34],
    ["书", "Joshua", "约书亚记", 24],
    ["士", "Judges", "士师记", 21],
    ["得", "Ruth", "路得记", 4],
    ["撒上", "1Samuel", "撒母耳记上", 31],
    ["撒下", "2Samuel", "撒母耳记下", 24],

    ["王上", "1Kings", "列王纪上", 22],
    ["王下", "2Kings", "列王纪下", 25],
    ["代上", "1Chronicles", "历代志上", 29],
    ["代下", "2Chronicles", "历代志下", 36],
    ["拉", "Ezra", "以斯拉记", 10],
    ["尼", "Nehemiah", "尼希米记", 13],
    ["斯", "Esther", "以斯帖记", 10],
    ["伯", "Job", "约伯记", 42],
    ["诗", "Paslm", "诗篇", 150],
    ["箴", "Proverbs", "箴言", 31],

    ["传", "Ecclesiastes", "传道书", 12],
    ["歌", "SongofSongs", "雅歌", 8],
    ["赛", "Isaiah", "以赛亚书", 66],
    ["耶", "Jeremiah", "耶利米书", 52],
    ["哀", "Lamentations", "耶利米哀歌", 5],
    ["结", "Ezekiel", "以西结书", 48],
    ["但", "Daniel", "但以理书", 12],
    ["何", "Hosea", "何西阿书", 14],
    ["珥", "Joel", "约珥书", 3],
    ["摩", "Amos", "阿摩司书", 9],

    ["俄", "Obadiah", "俄巴迪亚书", 1],
    ["拿", "Jonah", "约拿书", 4],
    ["弥", "Micah", "弥道书", 7],
    ["鸿", "Nahum", "那鸿书", 3],
    ["哈", "Habakkuk", "哈巴谷书", 3],
    ["番", "Zephanish", "西番雅书", 3],
    ["该", "Haggai", "哈该书", 2],
    ["亚", "Zecharish", "萨迦迪亚书", 14],
    ["玛", "Malachi", "玛拉基书", 4],
    ["太", "Matthew", "马太福音", 28],
    ["可", "Mark", "马可福音", 16],
    ["路", "Luke", "路加福音", 24],
    ["约", "John", "约翰福音", 21],
    ["徒", "Acts", "使徒行转", 28],
    ["罗", "Romans", "罗马书", 16],
    ["林前", "1Corinthians", "哥林多前书", 16],
    ["林后", "2Corinthians", "哥林多后书", 13],
    ["加", "Galatians", "加拉太书", 6],
    ["弗", "Ephesians", "以弗所书", 6],

    ["腓", "Philippians", "腓立比书", 4],
    ["西", "Colossians", "歌罗西书", 4],
    ["帖前", "1Thessalonians", "帖撒罗尼迦前书", 5],
    ["帖后", "2Thessalonians", "帖撒罗尼迦后书", 3],
    ["提前", "1Timonthy", "提摩太前书", 6],
    ["提后", "2Timonthy", "提摩太后书", 4],
    ["多", "Titus", "提多书", 3],
    ["门", "Philemon", "腓利门书", 1],
    ["来", "Hebrews", "希伯来书", 13],
    ["雅", "James", "雅各书", 5],

    ["彼前", "1Peter", "彼得前书", 5],
    ["彼后", "2Peter", "彼得后书", 3],
    ["约一", "1John", "约翰一书", 5],
    ["约二", "2John", "约翰二书", 1],
    ["约三", "3John", "约翰三书", 1],
    ["犹", "Jude", "犹太书", 1],
    ["启", "Revelation", "启示录", 22]
];