import React from "react";
import { BibleVerseRange } from "../../../../store/actions/songActions";
import { ChineseBible } from "../../../bible/bibleContent/bible_chinese";
import { EnglishBible } from "../../../bible/bibleContent/bible_english";
import { BibleContentUtility } from "../../../bible/bibleContent/bibleContentUtility";
import BibleVerseView from "./BibleVerseView";

const BibleVerseRangeView = (props: {
    bibleVerseRange: BibleVerseRange
}) => {
    const volumnIndex = BibleContentUtility.getBibleVolumnIndex(props.bibleVerseRange.volumn);
    const verses1 = ChineseBible.filter(x => x.volumn === volumnIndex + 1 && x.chapter === props.bibleVerseRange.chapter);
    const verses2 = EnglishBible.filter(x => x.volumn === volumnIndex + 1 && x.chapter === props.bibleVerseRange.chapter);

    return (
        <div style={ { padding: "10px" } }>
            { props.bibleVerseRange.volumn +
                " " +
                props.bibleVerseRange.chapter +
                "章" +
                " " +
                props.bibleVerseRange.startVerse +
                ":" +
                props.bibleVerseRange.endVerse }
            { verses1.map((item, index) => {
                if (index >= props.bibleVerseRange.startVerse - 1 && index <= props.bibleVerseRange.endVerse - 1) {
                    return <div style={ { marginBottom: "3px" } }>
                        <BibleVerseView
                            chapterNumber={ props.bibleVerseRange.chapter }
                            verseNumber={ index + 1 }
                            verse={ item.verse }
                            hideVerseNumber={ false } />
                        <div style={ { marginBottom: "3px" } } />
                        <BibleVerseView
                            chapterNumber={ props.bibleVerseRange.chapter }
                            verseNumber={ index + 1 }
                            verse={ verses2[index].verse }
                            hideVerseNumber={ true } />
                    </div>
                };
            }) }
        </div>
    );
};

export default BibleVerseRangeView;
